import { AvatarGroup } from "@resource/atlas/avatar/AvatarGroup";
import { useAvatarGroupItems } from "@resource/atlas/avatar/use-avatar-group-items";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPlus } from "@resource/atlas/icons";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { UserMembershipForExcludedInterviewCardFragment as Interviewer } from "generated/graphql-codegen/graphql";

import { listInterviewerNamesAsOr } from "../interviewer-slots/utils/misc";

gql(`
  fragment UserMembershipForExcludedInterviewCard on UserMembership {
    id
    imageUrl
    name
  }
`);

export type ExcludedInterviewCardProps = {
  excludedInterviewers: Interviewer[];
  onClick: () => void;
  className?: string;
};

export function ExcludedInterviewersCard({
  excludedInterviewers,
  onClick,
  className,
}: ExcludedInterviewCardProps) {
  const items = useAvatarGroupItems(
    (i) =>
      excludedInterviewers.map((interviewer) => {
        return i.avatar({
          key: interviewer.id,
          image: interviewer.imageUrl,
          name: interviewer.name,
        });
      }),
    [excludedInterviewers]
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onClick}
      onClick={onClick}
      className={clsx(
        "flex gap-2 py-3 pl-3 pr-[.375rem] border-gray-border border rounded-md items-center w-full overflow-hidden group hover:bg-light-gray-200 cursor-pointer",
        className
      )}
    >
      <AvatarGroup max={3} size="small" items={items} />
      <span className="truncate text-body-md grow opacity-50">
        {listInterviewerNamesAsOr(excludedInterviewers)}
      </span>
      <div className="w-8 flex items-center justify-center">
        <Icon content={atlasPlus} className="text-subtle" />
      </div>
    </div>
  );
}
