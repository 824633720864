import { useEffect } from "react";

const constructKey = (key: string, prefix: string) =>
  `__guide__${prefix}-${key}`;

export function useDefaultValueFromLocalStorage<T extends string = string>({
  key,
  prefix,
}: {
  key: T;
  prefix: string;
}) {
  return localStorage.getItem(constructKey(key, prefix)) ?? null;
}

export function useSyncValueWithLocalStorage<T extends string = string>({
  value,
  key,
  prefix,
}: {
  value: string;
  key: T;
  prefix: string;
}) {
  useEffect(() => {
    localStorage.setItem(constructKey(key, prefix), value);
  }, [value, key, prefix]);
}
