import { BookingLinkLoadBalancingValue } from "client/booking-links/BookingLinkLoadBalancingFormGroup";
import { gql } from "generated/graphql-codegen";
import {
  AvailableSlotFragment,
  FetchAvailableSlotsForBookingLinkSettingsCalendarQueryVariables,
  FetchAvailableSlotsForSettingsForSelfScheduleTimesCalendarQueryVariables,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { BookingLinkInterviewerLoadType } from "shared/booking-links/types";
import { SelfScheduleSchedulingSettingsInput } from "shared/self-schedule/types";
import useQuery from "utils/useQuery";

import { EditSelfScheduleInterviewSettingsState } from "../../__hooks/useEditSelfScheduleInterviewSettingsState";
import { EditSelfSchedulingSettingsState } from "../../__hooks/useEditSelfSchedulingSettingsState";
import { mapSelfSchedulingSettingsToGraphqlInput } from "../../__utils/mapping";
import { SelfScheduleTimesCalendarInput } from "../SelfScheduleTimesCalendar";

export const FETCH_AVAILABLE_SLOTS_FOR_SETTINGS = gql(`
  query FetchAvailableSlotsForSettingsForSelfScheduleTimesCalendar(
    $settings: SettingsForAvailableSlotsInput!
    $interviewerUserMembershipId: String!
    $poolId: ID = null
    $includePool: Boolean = false
  ) {
    availableSlotsForSettings(input: $settings) {
     ...AvailableSlot 
    }
    userMembershipById(id: $interviewerUserMembershipId) {
      ...UserMembershipForInterviewerSelfSchedule
    }
  }
`);

export type AvailableSlotsData = {
  availableSlots: AvailableSlotFragment[];
  userMembership: {
    organization: {
      id: string;
      blockedDates: {
        startDay: string;
        endDay: string;
      }[];
    };
  };
};

export function useAvailableSlotsForSettings({
  interviewerId,
  duration,
  schedulingSettings,
  actorId,
}: {
  interviewerId: string;
  duration: number;
  schedulingSettings: SelfScheduleSchedulingSettingsInput;
  actorId?: string;
}): {
  availableSlots: AvailableSlotFragment[];
  userMembership: {
    organization: {
      id: string;
      blockedDates: {
        startDay: string;
        endDay: string;
      }[];
    };
  } | null;
  loading: boolean;
} {
  const variables = useMemo(
    (): FetchAvailableSlotsForSettingsForSelfScheduleTimesCalendarQueryVariables => ({
      settings: {
        interviewerId,
        duration,
        selfSchedulingSettings: mapSelfSchedulingSettingsToGraphqlInput({
          selfSchedulingSettings: schedulingSettings,
        }),
        actorId,
      },
      interviewerUserMembershipId: interviewerId,
    }),
    [duration, interviewerId, schedulingSettings, actorId]
  );

  const { data, previousData, loading } = useQuery(
    FETCH_AVAILABLE_SLOTS_FOR_SETTINGS,
    {
      variables,
    }
  );

  const formattedData = data ?? previousData;

  return {
    availableSlots: formattedData?.availableSlotsForSettings ?? [],
    userMembership: formattedData?.userMembershipById ?? null,
    loading,
  };
}

export function useSelfScheduleTimesCalendarInput({
  interviewSettingsState,
  schedulingSettingsState,
}: {
  interviewSettingsState: EditSelfScheduleInterviewSettingsState;
  schedulingSettingsState: EditSelfSchedulingSettingsState;
}): SelfScheduleTimesCalendarInput {
  const duration = interviewSettingsState.form.watch("duration");
  const schedulingSettings = schedulingSettingsState.form.watch();
  const interviewerId = interviewSettingsState.interviewer.id;

  const variables = useMemo(
    (): FetchAvailableSlotsForSettingsForSelfScheduleTimesCalendarQueryVariables => ({
      settings: {
        interviewerId,
        duration,
        selfSchedulingSettings: mapSelfSchedulingSettingsToGraphqlInput({
          selfSchedulingSettings: schedulingSettings,
        }),
      },
      interviewerUserMembershipId: interviewerId,
    }),
    [duration, interviewerId, schedulingSettings]
  );

  const { data, previousData, loading, refetch } = useQuery(
    FETCH_AVAILABLE_SLOTS_FOR_SETTINGS,
    {
      variables,
    }
  );

  const formattedData = data ?? previousData;

  return useMemo(
    (): SelfScheduleTimesCalendarInput => ({
      interviewSettingsState,
      schedulingSettingsState,
      availableSlots: formattedData?.availableSlotsForSettings ?? [],
      userMembership: formattedData?.userMembershipById,
      availableSlotsQueryLoading: loading,
      refetchAvailableSlots: refetch,
    }),
    [
      formattedData?.availableSlotsForSettings,
      formattedData?.userMembershipById,
      interviewSettingsState,
      schedulingSettingsState,
      loading,
      refetch,
    ]
  );
}

export const FETCH_AVAILABLE_SLOTS_FOR_BOOKING_LINK_SETTINGS = gql(`
  query FetchAvailableSlotsForBookingLinkSettingsCalendar(
    $input: SettingsForBookingLinkAvailableSlotsInput!
    $interviewerUserMembershipId: String!
    $bookingLinkId: String!
    $includeBookingLink: Boolean!
    $poolId: ID = null
    $includePool: Boolean = false
  ) {
    availableSlotsForBookingLinkSettings(input: $input) {
      ...AvailableSlot
    }
    bookingLinkById(id: $bookingLinkId) @include(if: $includeBookingLink) {
      ...BookingLinkForSelfScheduleTimesCalendar
    }
    userMembershipById(id: $interviewerUserMembershipId) {
      ...UserMembershipForInterviewerSelfSchedule
    }
  }
`);

export function useBookingLinkTimesCalendarInput({
  interviewSettingsState,
  schedulingSettingsState,
  existingBookingLinkId,
  bookingLinkLoadForm,
}: {
  interviewSettingsState: EditSelfScheduleInterviewSettingsState;
  schedulingSettingsState: EditSelfSchedulingSettingsState;
  existingBookingLinkId?: string;
  bookingLinkLoadForm: BookingLinkLoadBalancingValue;
}): SelfScheduleTimesCalendarInput {
  const duration = interviewSettingsState.form.watch("duration");
  const schedulingSettings = schedulingSettingsState.form.watch();
  const interviewerLoadType = bookingLinkLoadForm?.loadType;
  const maxLoadPerDay = bookingLinkLoadForm?.maxInterviewLoadPerDay;
  const maxLoadPerWeek = bookingLinkLoadForm?.maxInterviewLoadPerWeek;
  const interviewerId = interviewSettingsState.interviewer.id;

  const variables = useMemo(
    (): FetchAvailableSlotsForBookingLinkSettingsCalendarQueryVariables => ({
      input: {
        interviewerId,
        duration,
        selfSchedulingSettings: mapSelfSchedulingSettingsToGraphqlInput({
          selfSchedulingSettings: schedulingSettings,
        }),
        interviewerLoadType,
        maxInterviewLoadPerDay: maxLoadPerDay,
        maxInterviewLoadPerWeek: maxLoadPerWeek,
        existingBookingLinkId,
      },
      interviewerUserMembershipId: interviewerId,
      bookingLinkId: existingBookingLinkId ?? "",
      includeBookingLink:
        interviewerLoadType === BookingLinkInterviewerLoadType.LINK_SPECIFIC &&
        !!existingBookingLinkId,
    }),
    [
      interviewerId,
      duration,
      schedulingSettings,
      interviewerLoadType,
      maxLoadPerDay,
      maxLoadPerWeek,
      existingBookingLinkId,
    ]
  );

  const { data, previousData, loading, refetch } = useQuery(
    FETCH_AVAILABLE_SLOTS_FOR_BOOKING_LINK_SETTINGS,
    {
      variables,
    }
  );

  const formattedData = data ?? previousData;

  return useMemo(
    (): SelfScheduleTimesCalendarInput => ({
      interviewSettingsState,
      schedulingSettingsState,
      availableSlots: formattedData?.availableSlotsForBookingLinkSettings ?? [],
      userMembership: formattedData?.userMembershipById,
      availableSlotsQueryLoading: loading,
      refetchAvailableSlots: refetch,
      bookingLink: formattedData?.bookingLinkById ?? undefined,
      bookingLinkLoadForm,
    }),
    [
      interviewSettingsState,
      schedulingSettingsState,
      formattedData?.availableSlotsForBookingLinkSettings,
      formattedData?.userMembershipById,
      formattedData?.bookingLinkById,
      loading,
      refetch,
      bookingLinkLoadForm,
    ]
  );
}
