/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";

import { useEvent } from "../../../../__utils/react";
import { BEFORE_TRIGGER_REGEXP, getTextUpToAnchor } from "./shared";

// use insert typeahead trigger
// ----------------------------

export function useInsertTypeaheadTrigger(trigger: string) {
  const [editor] = useLexicalComposerContext();

  return useEvent(() => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      selection.removeText();

      const previousText = getTextUpToAnchor(selection);

      const canTrigger =
        !previousText ||
        new RegExp(`(?:${BEFORE_TRIGGER_REGEXP})$`).test(previousText);

      selection.insertText(canTrigger ? trigger : ` ${trigger}`);
    });
  });
}
