import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasClose,
  atlasFilterPlus,
  atlasOperatorOr,
  atlasRingInfo,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { Toggle } from "client/app/__components/Toggle";
import { ColorizedSmartMatchIcon } from "client/components/interview-requirements/__components/ColorizedSmartMatchIcon";
import { TagFilterWithQuery } from "client/components/tag-filters/TagFilterWithQuery";
import {
  InterviewerRequirementForConfigurationFragment,
  TagFilterType,
} from "generated/graphql-codegen/graphql";
import { v4 } from "uuid";

import {
  InterviewerSlot,
  TraineeSelect,
} from "./InterviewerConfigPersonDisplay";

type UserMembership = {
  id: string;
  imageUrl: string | null;
  name: string;
};

type UpdateFn = (config: {
  id: string;
  syncedUserMemberships: UserMembership[];
  userMemberships: UserMembership[];
  shadowingUserMemberships: UserMembership[];
  includeShadower: boolean;
}) => void;

export type InterviewerSmartMatchSelectProps = {
  requirement: InterviewerRequirementForConfigurationFragment;
  onRemove: () => void;
  onUpdate?: UpdateFn;
  onTagFiltersChange: (
    tagFilters: InterviewerRequirementForConfigurationFragment["tagFilters"]
  ) => void;
  setIncludeShadower: (value: boolean) => void;
};

export function InterviewerSmartMatchSelect({
  requirement,
  onRemove,
  onUpdate,
  onTagFiltersChange,
  setIncludeShadower,
}: InterviewerSmartMatchSelectProps) {
  const hasEmptyFilter = requirement.tagFilters?.some(
    (filter) => filter.tags.length === 0
  );

  return (
    <div className="rounded-md group shadow-1">
      <div className="flex gap-2 p-3 items-center">
        <ColorizedSmartMatchIcon variant="large" />
        <div className="flex-grow">
          <div className="text-body-md">Smart Match</div>
          {/* <div className="text-subtle text-body-sm">
            {/* TODO: Add the number of interviewers */}
          {/* </div> */}
        </div>
        <ButtonGroup>
          <Button
            isGhost
            size="xs"
            icon={atlasFilterPlus}
            disabled={hasEmptyFilter}
            onClick={() => {
              onTagFiltersChange([
                ...(requirement.tagFilters || []),
                {
                  id: v4(),
                  type: TagFilterType.INCLUDE_ANY,
                  tags: [],
                },
              ]);
            }}
          />
          <Button isGhost size="xs" icon={atlasClose} onClick={onRemove} />
        </ButtonGroup>
      </div>
      <div className=" py-2 px-3 space-y-1">
        {requirement.tagFilters?.map((filter) => (
          <TagFilterWithQuery
            key={filter.id}
            selectedTags={filter.tags}
            defaultOpen={filter.tags.length === 0}
            onTagSelect={(tag) => {
              onTagFiltersChange(
                requirement.tagFilters?.map((f) => {
                  if (f.id === filter.id) {
                    const isTagSelected = f.tags.some((t) => t.id === tag.id);
                    return {
                      ...f,
                      tags: isTagSelected
                        ? f.tags.filter((t) => t.id !== tag.id)
                        : [...f.tags, tag],
                    };
                  }
                  return f;
                }) || []
              );
            }}
            onRemoveTag={(tag) => {
              onTagFiltersChange(
                requirement.tagFilters?.map((f) => {
                  if (f.id === filter.id) {
                    return {
                      ...f,
                      tags: f.tags.filter((t) => t.id !== tag.id),
                    };
                  }
                  return f;
                }) || []
              );
            }}
            onClearAll={() => {
              onTagFiltersChange(
                requirement.tagFilters?.filter((f) => f.id !== filter.id) || []
              );
            }}
            onFilterChange={(type) => {
              onTagFiltersChange(
                requirement.tagFilters?.map((f) => {
                  if (f.id === filter.id) {
                    return {
                      ...f,
                      type,
                    };
                  }
                  return f;
                }) || []
              );
            }}
            filterType={filter.type}
          />
        ))}
      </div>
      <div className="text-body-sm-heavy bg-light-gray-200 py-2 px-3 text-subtle flex items-center gap-2">
        <Toggle
          size="small"
          value={requirement.includeShadower}
          onChange={(value) => {
            setIncludeShadower(value);
          }}
        />
        <div className="flex-grow">Interviewer training</div>
        <Tooltip
          content="Optionally, a trainee should be scheduled to shadow."
          isInstant
        >
          <Icon content={atlasRingInfo} className="w-4 h-4" />
        </Tooltip>
      </div>
      {requirement.includeShadower && (
        <>
          <TraineeSelect interviewerConfig={requirement} onUpdate={onUpdate} />
          <div className="flex flex-col space-y-2">
            {requirement.shadowingUserMemberships.map((userMembership, idx) => {
              const isLast =
                idx === requirement.shadowingUserMemberships.length - 1;

              return (
                <div key={userMembership.id}>
                  <InterviewerSlot
                    interviewerConfig={requirement}
                    isLast={isLast}
                    userMembership={userMembership}
                    onRemove={onRemove}
                    onUpdate={onUpdate}
                    isShadow
                  />
                  {!isLast && (
                    <div className="flex items-center gap-1 h-[0.0625rem]">
                      <div className="w-full border-b-2 border-dashed border-light-gray-500" />
                      <Icon content={atlasOperatorOr} className="text-subtle" />
                      <div className="w-full border-b-2 border-dashed border-light-gray-500" />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
