import { DisplaySelectedTimes } from "client/components/display/times/DisplaySelectedTimes";
import clsx from "clsx";
import { Event } from "shared/guide-availability/types";

type CandidateAvailabilityProps = {
  events?: Pick<Event, "id" | "startTime" | "endTime">[];
  isCurrentSubmission: boolean;
  timezone?: string | null;
  allowCollapse?: boolean;
  className?: string;
  listItemClassName?: string;
};

export function CandidateAvailability({
  events = [],
  isCurrentSubmission,
  timezone,
  allowCollapse,
  className,
  listItemClassName,
}: CandidateAvailabilityProps) {
  return (
    <DisplaySelectedTimes
      times={events}
      timezone={timezone}
      listClassName={clsx(className, {
        "line-through text-subtle": !isCurrentSubmission,
      })}
      listItemClassName={listItemClassName}
      allowCollapse={allowCollapse}
    />
  );
}
