import { DayHeaderButtonPlugin } from "client/components/calendar/plugins/DayHeaderButtonPlugin";
import { mapCalendarEventInputToLuxonTimes } from "client/components/calendar/utils";
import { getTrimmedWorkingHourIntervals } from "client/utils/workingHours";
import { InterviewRequirementForInterviewRequirementsCardFragment } from "generated/graphql-codegen/graphql";
import { DateTime, Interval } from "luxon";
import { useCallback, useMemo } from "react";
import { Event } from "shared/guide-availability/types";
import { v4 } from "uuid";

import { AVAILABILITY_MIN_WINDOW_MINUTES } from "../utils";
import { CreateOrUpdateAvailabilityState } from "./CreateOrUpdateAvailabilityView";

export function AvailabilityDayHeaderPlugin({
  suggestions: inputSuggestions,
  taskRequirements,
  state,
}: {
  suggestions?: Event[];
  taskRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];

  state: CreateOrUpdateAvailabilityState;
}) {
  const {
    selections: inputSelections,
    setSelections,
    interviewers,
    interviewerCalendarEvents: inputInterviewerCalendarEvents,
  } = state;

  const suggestions = useMemo(() => {
    if (!inputSuggestions) {
      return [];
    }

    return inputSuggestions.map((i) => ({
      ...i,
      startTime: DateTime.fromISO(i.startTime),
      endTime: DateTime.fromISO(i.endTime),
    }));
  }, [inputSuggestions]);

  const selections = useMemo(() => {
    return inputSelections.map((i) => ({
      ...i,
      startTime: DateTime.fromISO(i.startTime),
      endTime: DateTime.fromISO(i.endTime),
    }));
  }, [inputSelections]);

  const interviewerCalendarEvents = useMemo(() => {
    return inputInterviewerCalendarEvents.map((i) => ({
      ...i,
      ...mapCalendarEventInputToLuxonTimes(i),
    }));
  }, [inputInterviewerCalendarEvents]);

  const getSuggestionsForDay = useCallback(
    ({ day }: { day: DateTime }) => {
      return suggestions.filter(
        (e) => e.startTime && e.startTime.hasSame(day, "day")
      );
    },
    [suggestions]
  );

  const getAvailabileTimesForDay = useCallback(
    ({ day }: { day: DateTime }) => {
      const dayStart = day.startOf("day");
      const dayEnd = day.endOf("day");

      // 96 * 15 mins = One full day of time slots.
      // Unfortunately, this component does not handle the 12am to 12am edge case,
      // so we suggest an absolute maximum of 12am to 11:45pm.
      const timeSlots = Array.from({ length: 95 }, (_, i) =>
        dayStart.plus({ minutes: AVAILABILITY_MIN_WINDOW_MINUTES * i })
      );

      // Remove all "full day" intervals from interviewers, because that means they
      // don't have a preference.
      const fullDayInterval = Interval.fromDateTimes(dayStart, dayEnd);
      const intervalSets: (Interval | null)[][] = interviewers
        .map((interviewer) =>
          getTrimmedWorkingHourIntervals({
            interviewer,
            interval: fullDayInterval,
          })
        )
        .map((intervals) => {
          return intervals.filter((interval) => interval !== fullDayInterval);
        })
        .filter((intervals) => intervals.length !== 0);

      // If we have NO intervals left, no one had a preference. Default 9-5.
      if (!intervalSets.length) {
        const nineToFive = Interval.fromDateTimes(
          dayStart.set({ hour: 9 }),
          dayEnd.set({ hour: 17, minute: 0 })
        );
        intervalSets.push([nineToFive]);
      }

      let allAvailableTimes;

      if (suggestions?.length && getSuggestionsForDay({ day }).length) {
        allAvailableTimes = getSuggestionsForDay({
          day,
        })
          .filter((s) => {
            return !selections.some((selection) =>
              Interval.fromDateTimes(
                selection.startTime,
                selection.endTime
              ).engulfs(Interval.fromDateTimes(s.startTime, s.endTime))
            );
          })
          .map((s) => {
            return {
              id: v4(),
              title: "Suggested time",
              startTime: s.startTime.toISO(),
              endTime: s.endTime.toISO(),
            };
          });
      } else {
        const allEvents = [...selections, ...interviewerCalendarEvents];

        // Don't suggest blocks which are shorter than the shortest interview.
        // We still need time slots to be 15 minutes, so that we can find eg a 1 hour block
        // starting at 7:00, 7:15, or 7:30. Overlapping time slots will be merged into
        // a single suggested time block during setSelections().
        const minDuration =
          taskRequirements && taskRequirements.length
            ? taskRequirements.reduce(
                (min, task) => (task.duration < min ? task.duration : min),
                taskRequirements[0].duration
              )
            : // Due to the legacy modal system, this component will first render without any
              // taskRequirements before quickly re-rendering. Use a placeholder minimum.
              15;

        allAvailableTimes = timeSlots
          .filter((timeSlot) => {
            const timeSlotInterval = Interval.fromDateTimes(
              timeSlot,
              timeSlot.plus({ minutes: minDuration })
            );
            const isEventDuringTimeSlot = allEvents.some((event) => {
              return timeSlotInterval.overlaps(
                Interval.fromDateTimes(event.startTime, event.endTime)
              );
            });
            const allInterviewersInWorkingHours = intervalSets.every(
              (intervals) =>
                intervals.some(
                  (interval) =>
                    interval !== null && interval.engulfs(timeSlotInterval)
                )
            );
            return !isEventDuringTimeSlot && allInterviewersInWorkingHours;
          })
          .map((timeSlot) => ({
            id: v4(),
            title: "Suggested time",
            startTime: timeSlot.toUTC().toISO(),
            endTime: timeSlot.plus({ minutes: minDuration }).toUTC().toISO(),
          }));
      }

      return allAvailableTimes;
    },
    [
      getSuggestionsForDay,
      interviewerCalendarEvents,
      selections,
      interviewers,
      suggestions?.length,
      taskRequirements,
    ]
  );

  const onAddAllAvailable = useCallback(
    ({ day }: { day: DateTime }) => {
      const allAvailableTimes = getAvailabileTimesForDay({ day });
      setSelections([...inputSelections, ...allAvailableTimes]);
    },
    [getAvailabileTimesForDay, inputSelections, setSelections]
  );

  const requestingButtonText = useMemo(() => {
    return interviewers?.length ? "Add all available" : "Add 9-5pm";
  }, [interviewers?.length]);

  const getButtonText = useCallback(
    ({ day }: { day: DateTime }) => {
      if (suggestions?.length) {
        if (getSuggestionsForDay({ day }).length) return "Add all suggested";

        return "Add 9-5pm";
      }

      return requestingButtonText;
    },
    [getSuggestionsForDay, requestingButtonText, suggestions?.length]
  );

  return (
    <DayHeaderButtonPlugin
      key="add-all-available-plugin"
      onClick={onAddAllAvailable}
      getButtonText={getButtonText}
      getAvailabileTimesForDay={getAvailabileTimesForDay}
    />
  );
}
