import { Tabs } from "@resource/atlas/tabs-v2";
import {
  descriptionForSchedulingRequestAssigneeFilter,
  displayNameForSchedulingRequestAssigneeFilter,
} from "client/app/(scheduling requests)/__utils";
import { SchedulingRequestsList } from "client/components/scheduling-tasks/SchedulingRequestsList";
import { useSchedulingRequestCounts } from "client/hooks/useSchedulingRequestCounts";
import { formatCount } from "client/utils/counts";
import { SchedulingRequestAssigneeFilter } from "generated/graphql-codegen/graphql";
import { useState } from "react";
import { formatEntity } from "shared/constants/entities";

type ExtensionSchedulingRequestsProps = {
  onClickSchedulingRequest: (args: { schedulingRequestId: string }) => void;
};

export function ExtensionSchedulingRequests({
  onClickSchedulingRequest,
}: ExtensionSchedulingRequestsProps) {
  const [selectedTab, setSelectedTab] = useState<string>(
    SchedulingRequestAssigneeFilter.MINE
  );
  const counts = useSchedulingRequestCounts({ skip: false });

  return (
    <Tabs.Root
      selectedId={selectedTab}
      onSelectedChange={setSelectedTab}
      className="flex flex-col h-full overflow-hidden w-full"
    >
      <>
        <div className="p-6">
          <h3 className="text-h3 h-6">
            {formatEntity("scheduling request", {
              plural: true,
              capitalize: true,
            })}
          </h3>
        </div>
        <Tabs.List className="border-b border-gray-border w-full overflow-x-scroll shrink-0">
          <Tabs.Tab
            id={SchedulingRequestAssigneeFilter.MINE}
            badge={formatCount(counts?.mine)}
          >
            {displayNameForSchedulingRequestAssigneeFilter(
              SchedulingRequestAssigneeFilter.MINE
            )}
          </Tabs.Tab>
          <Tabs.Tab
            id={SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER}
            badge={formatCount(counts?.assignedToUser)}
          >
            {displayNameForSchedulingRequestAssigneeFilter(
              SchedulingRequestAssigneeFilter.ASSIGNED_TO_USER
            )}
          </Tabs.Tab>
          <Tabs.Tab
            id={SchedulingRequestAssigneeFilter.UNASSIGNED}
            badge={formatCount(counts?.unassigned)}
          >
            {displayNameForSchedulingRequestAssigneeFilter(
              SchedulingRequestAssigneeFilter.UNASSIGNED
            )}
          </Tabs.Tab>
          <Tabs.Tab
            id={SchedulingRequestAssigneeFilter.ALL}
            badge={formatCount(counts?.all)}
          >
            {displayNameForSchedulingRequestAssigneeFilter(
              SchedulingRequestAssigneeFilter.ALL
            )}
          </Tabs.Tab>
        </Tabs.List>
      </>
      <Tabs.Panel
        tabId={selectedTab}
        className="space-y-2 overflow-y-scroll px-6 pt-4 pb-6"
      >
        <p className="text-body-md text-subtle">
          {descriptionForSchedulingRequestAssigneeFilter(
            selectedTab as SchedulingRequestAssigneeFilter
          )}
        </p>
        <SchedulingRequestsList
          onClickSchedulingRequest={onClickSchedulingRequest}
          assigneeFilter={selectedTab as SchedulingRequestAssigneeFilter}
        />
      </Tabs.Panel>
    </Tabs.Root>
  );
}
