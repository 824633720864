import TextArea from "@resource/atlas/textfield/TextArea";
import {
  FormGroup,
  FormGroupProps,
} from "client/components/generic/misc/FormGroup";

export type AdditionalTextInputProps = FormGroupProps & {
  text: string | null | undefined;
  onChangeText: (value: string) => void;
};

export function AdditionalTextInput({
  text,
  onChangeText,
  ...rest
}: AdditionalTextInputProps) {
  return (
    <FormGroup label="Additional context" {...rest}>
      <TextArea
        className="h-[80px] w-full"
        inputClassName="h-[80px]"
        placeholder="Add additional context..."
        value={text ?? ""}
        onChange={onChangeText}
      />
    </FormGroup>
  );
}
