import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRingInfo } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useMemo } from "react";

import { getDisplayTextFromService } from "../utils/display";

export function GenerationSlotAction({
  requirements,
  originalRequirements,
}: {
  requirements: { service: string } | null | undefined;
  originalRequirements: { service: string } | null | undefined;
}) {
  const text = useMemo(() => {
    if (requirements) {
      return `${getDisplayTextFromService(requirements.service)} is required.`;
    }

    if (originalRequirements) {
      return `${getDisplayTextFromService(
        originalRequirements.service
      )} was required, but has been ignored.`;
    }

    return null;
  }, [requirements, originalRequirements]);

  if (!text) {
    return null;
  }

  return (
    <Tooltip isInstant content={text}>
      <Icon content={atlasRingInfo} />
    </Tooltip>
  );
}
