import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { InterviewPanelLocation } from "shared/interview-requirements/types";

type SchedulingRequestLocationSettingsProps = {
  location: InterviewPanelLocation | null;
  setLocation: (value: InterviewPanelLocation | null) => unknown;
};

export function SchedulingRequestLocationSettings({
  location,
  setLocation,
}: SchedulingRequestLocationSettingsProps) {
  return (
    <div className="flex space-x-2">
      <Checkbox
        checked={location === InterviewPanelLocation.ONSITE}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setLocation(InterviewPanelLocation.ONSITE);
          } else {
            // We only save the location if it's explicitly marked as ONSITE.
            setLocation(null);
          }
        }}
      />
      <span className="text-body-md">Candidate will interview on-site</span>
    </div>
  );
}
