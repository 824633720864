import { OptionItem } from "@resource/atlas/option/OptionItem";

import {
  SelectedInterviewerDisplay,
  SelectedInterviewerDisplayProps,
} from "./SelectedInterviewerDisplay";

export type UserMembershipSelectOptionProps = Pick<
  SelectedInterviewerDisplayProps,
  "showDayCount" | "isShadower"
> & {
  userMembership: SelectedInterviewerDisplayProps["interviewer"];
  onClick: () => void;
};

export default function UserMembershipSelectOption({
  userMembership,
  onClick,
  ...rest
}: UserMembershipSelectOptionProps) {
  return (
    <OptionItem size="compact" onClick={onClick}>
      <SelectedInterviewerDisplay
        {...rest}
        hideShadowingLabel
        interviewer={userMembership}
        size="small"
      />
    </OptionItem>
  );
}
