import { useStoreState } from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import {
  Dialog,
  DialogProps,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { atom, createStore, getDefaultStore, useAtom, useSetAtom } from "jotai";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import useLeaveConfirmation from "react-hooks/useLeaveConfirmation";

const showDialogLeaveConfirmationAtom = atom<boolean>(false);

/*
 * useSyncDialogLeaveConfirmation works in tandem with useDialogLeaveConfirmation.
 * useSyncDialogLeaveConfirmation is only used to determine whether the confirmation dialog
 * should be shown: useDialogLeaveConfirmation handles the confirmation logic.
 * This allows us to decouple eg form dirty state from the actual component we're unmounting.
 * */
export function useSyncDialogLeaveConfirmation({
  showConfirmation,
  jotaiStore,
}: {
  showConfirmation: boolean;
  jotaiStore?: ReturnType<typeof createStore>;
}) {
  // Always use the default store so that if a dialog uses a provider then it will access the same atom regardless
  // If a custom store is provided, then we use that store instead of the default one
  const defaultStore = jotaiStore ?? getDefaultStore();
  const setShowConfirmationValue = useSetAtom(showDialogLeaveConfirmationAtom, {
    store: defaultStore,
  });

  useEffect(() => {
    setShowConfirmationValue(showConfirmation);
  }, [setShowConfirmationValue, showConfirmation]);
}

export function useDialogLeaveConfirmation({
  store,
  message = "Changes you made have not been saved. Are you sure you want to leave?",
  hasSubmittedRef,
  jotaiStore,
}: {
  store: DialogStore;
  message?: string;
  /** Ref to track submission so that leave confirmation isn't shown after submitting */
  hasSubmittedRef?: MutableRefObject<boolean>;
  jotaiStore?: ReturnType<typeof createStore>;
}): {
  dialogProps: Partial<DialogProps>;
  WarningDialog: () => JSX.Element;
} {
  // Always use the default store so that if a dialog uses a provider then it will access the same atom regardless
  // If a custom store is provided, then we use that store instead of the default one
  const defaultStore = jotaiStore ?? getDefaultStore();
  const [showConfirmationValue, setShowConfirmationValue] = useAtom(
    showDialogLeaveConfirmationAtom,
    {
      store: defaultStore,
    }
  );
  const dialogState = useStoreState(store);

  const showConfirmation = showConfirmationValue && dialogState.open;

  const dismissedRef = useRef<boolean>(false);
  const warningStore = useDialogStore();
  const onClose = useCallback(
    (e: Event) => {
      if (
        showConfirmation &&
        !dismissedRef.current &&
        !hasSubmittedRef?.current
      ) {
        e.preventDefault();
        warningStore.show();
      } else {
        store.hide();
        dismissedRef.current = false;
        setShowConfirmationValue(false);
      }
    },
    [
      hasSubmittedRef,
      setShowConfirmationValue,
      showConfirmation,
      store,
      warningStore,
    ]
  );

  useLeaveConfirmation(message, showConfirmation);

  return useMemo(
    () => ({
      dialogProps: {
        onClose,
      },
      WarningDialog: () => (
        <Dialog store={warningStore}>
          <View
            className="text-body-md"
            footer={{
              rightActions: (
                <Button
                  variant="primary"
                  onClick={() => {
                    dismissedRef.current = true;
                    warningStore.hide();
                    store.hide();
                  }}
                >
                  Leave
                </Button>
              ),
              leftActions: (
                <Button
                  isGhost
                  onClick={() => {
                    warningStore.hide();
                  }}
                >
                  Cancel
                </Button>
              ),
            }}
          >
            {message}
          </View>
        </Dialog>
      ),
    }),
    [message, onClose, store, warningStore]
  );
}
