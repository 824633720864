import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import { InterviewerResponse } from "shared/guide-scheduler/types";
import useMutation from "utils/useMutation";

const UPDATE_RSVP = gql(`
  mutation UpdateInterviewerRsvp($input: UpdateInterviewerRsvpInput!) {
    updateInterviewerRsvp(input: $input) {
      success
      message
      code
      interviewer {
        id
        responseStatus
        responseNote
      }
    }
  }
`);

export type UseUpdateRsvpOnSubmitProps = {
  interviewerId: string;
};

export type UpdateRsvpProps = {
  interviewerId: string;
  responseStatus: InterviewerResponse;
  responseNote?: string | null;
};

export function useUpdateRsvp() {
  const [updateRsvpMutation, { loading }] = useMutation(UPDATE_RSVP);

  const updateRsvp = useCallback(
    async ({
      interviewerId,
      responseStatus,
      responseNote,
    }: UpdateRsvpProps) => {
      await updateRsvpMutation({
        variables: {
          input: {
            interviewerId,
            responseStatus: responseStatus as InterviewerResponse,
            responseNote: responseNote || null,
          },
        },
      });
    },
    [updateRsvpMutation]
  );

  return { updateRsvp, loading };
}
