import { Button as AriaButton } from "@ariakit/react";
import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRefreshCw, atlasRingCheck } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { strings } from "@resource/common";
import { BookingLinkLoadBalancingValue } from "client/booking-links/BookingLinkLoadBalancingFormGroup";
import { Calendar } from "client/components/calendar-v2/components/Calendar";
import { useCalendarSettingsHydrationOptions } from "client/components/calendar-v2/components/CalendarSettingsProvider";
import { FloatingToolbar } from "client/components/calendar-v2/components/public/FloatingToolbar";
import {
  useCalendarTimezone,
  useSetCalendarTimezone,
} from "client/components/calendar-v2/hooks/settings";
import { useTimezone } from "client/timezones/useTimezone";
import clsx from "clsx";
import {
  AvailableSlotFragment,
  BookingLinkForSelfScheduleTimesCalendarFragment,
  UserMembershipForInterviewerSelfScheduleFragment,
} from "generated/graphql-codegen/graphql";
import { Provider } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { usePrevious } from "react-use";
import { getLastAvailableDateFromRollingDateRange } from "shared/self-schedule/utils";
import { displayTimezone } from "shared/utils/timezones";

import { EditSelfScheduleInterviewSettingsState } from "../__hooks/useEditSelfScheduleInterviewSettingsState";
import { EditSelfSchedulingSettingsState } from "../__hooks/useEditSelfSchedulingSettingsState";
import { useAvailableSlotCalendarEvents } from "./hooks/useAvailableSlotCalendarEvents";
import { useBlockedDatesEvents } from "./hooks/useBlockedDatesEvents";
import { useInterviewerEvents } from "./hooks/useInterviewerEvents";
import { useSelfScheduleLoadEvents } from "./hooks/useSelfScheduleLoadEvents";
import { SelfScheduleCalendarLayers } from "./utils/layers";

export type SelfScheduleTimesCalendarInput = {
  interviewSettingsState: EditSelfScheduleInterviewSettingsState;
  schedulingSettingsState: EditSelfSchedulingSettingsState;
  availableSlots: AvailableSlotFragment[];
  userMembership:
    | UserMembershipForInterviewerSelfScheduleFragment
    | undefined
    | null;
  availableSlotsQueryLoading: boolean;
  refetchAvailableSlots: () => void;
  bookingLink?: BookingLinkForSelfScheduleTimesCalendarFragment;
  bookingLinkLoadForm?: BookingLinkLoadBalancingValue;
};

export type SelfScheduleTimesCalendarProps = {
  input: SelfScheduleTimesCalendarInput;
};

export function SelfScheduleTimesCalendar(
  props: SelfScheduleTimesCalendarProps
) {
  return (
    <Provider>
      <InternalSelfScheduleTimesCalendar {...props} />
    </Provider>
  );
}

function useSyncCalendarTimezone(interviewerTimezone: string) {
  const prevInterviewerTimezone = usePrevious(interviewerTimezone);
  const setCalendarTimezone = useSetCalendarTimezone();

  useEffect(() => {
    if (
      prevInterviewerTimezone !== interviewerTimezone &&
      interviewerTimezone
    ) {
      setCalendarTimezone(interviewerTimezone);
    }
  }, [interviewerTimezone, prevInterviewerTimezone, setCalendarTimezone]);
}

function InternalSelfScheduleTimesCalendar({
  input,
}: SelfScheduleTimesCalendarProps) {
  const {
    interviewSettingsState,
    schedulingSettingsState,
    availableSlots,
    userMembership,
    availableSlotsQueryLoading,
    refetchAvailableSlots,
    bookingLink,
    bookingLinkLoadForm,
  } = input;
  const selectedInterviewer = useMemo(
    () => interviewSettingsState.interviewer,
    [interviewSettingsState.interviewer]
  );
  const interviewerTimezone = useMemo(() => {
    return selectedInterviewer.user.timezone ?? "UTC";
  }, [selectedInterviewer]);
  useSyncCalendarTimezone(interviewerTimezone);
  const calendarTimezone = useCalendarTimezone();
  const contextTimezone = useTimezone();
  const schedulingSettings = schedulingSettingsState.form.watch();
  const startTime = useMemo(() => {
    return DateTime.now().startOf("week").setZone(interviewerTimezone);
  }, [interviewerTimezone]);
  const endTime = useMemo(() => {
    return getLastAvailableDateFromRollingDateRange({
      rollingDateRange: schedulingSettings.rollingDateRange,
      timezone: interviewerTimezone,
    }).endOf("week");
  }, [schedulingSettings.rollingDateRange, interviewerTimezone]);

  const {
    events: mappedInterviewerEvents,
    loading: interviewerEventsLoading,
    refetch: refetchInterviewerEvents,
    calendarColors,
  } = useInterviewerEvents({
    selectedInterviewer,
    startTime,
    endTime,
  });
  const availableSlotEvents = useAvailableSlotCalendarEvents(availableSlots);
  const organizationBlockedEvents = useBlockedDatesEvents({
    userMembership,
    interviewerTimezone,
  });
  const loadEvents = useSelfScheduleLoadEvents({
    userMembership,
    startTime,
    endTime,
    bookingLink,
    bookingLinkLoadForm,
  });

  const refetch = useCallback(() => {
    refetchAvailableSlots();
    refetchInterviewerEvents();
  }, [refetchAvailableSlots, refetchInterviewerEvents]);
  const isLoading = useMemo(() => {
    return availableSlotsQueryLoading || interviewerEventsLoading;
  }, [availableSlotsQueryLoading, interviewerEventsLoading]);

  const calendarEvents = useMemo(() => {
    return [
      ...availableSlotEvents,
      ...mappedInterviewerEvents,
      ...loadEvents,
      ...organizationBlockedEvents,
    ];
  }, [
    availableSlotEvents,
    mappedInterviewerEvents,
    loadEvents,
    organizationBlockedEvents,
  ]);

  const plugins = useMemo(() => {
    return [
      <SelfScheduleFloatingToolbar
        key="self-schedule-floating-toolbar"
        availableSlotsCount={availableSlots.length}
        isLoading={isLoading}
        onRefresh={refetch}
      />,
    ];
  }, [availableSlots.length, isLoading, refetch]);

  const timezoneInfoBanner = useMemo(() => {
    if (
      interviewerTimezone === calendarTimezone &&
      calendarTimezone !== contextTimezone
    ) {
      // We'll sync the calendar timezone with the interviewers for ease
      // If the calendar's timezone is in sync with the interviewer's
      // AND it's different than the current user's, let them know
      return (
        <AlertBanner variant="info">
          You are currently viewing the calendar in the interviewer&apos;s
          timezone of {displayTimezone(interviewerTimezone, "abbreviation")}.
        </AlertBanner>
      );
    }

    return undefined;
  }, [calendarTimezone, contextTimezone, interviewerTimezone]);

  const calendarHydration = useCalendarSettingsHydrationOptions({
    defaultSettings: {
      timezone: interviewerTimezone,
    },
  });

  useHydrateAtoms(calendarHydration);

  return (
    <div className="overflow-hidden grow relative">
      <Calendar
        events={calendarEvents}
        plugins={plugins}
        calendarColors={calendarColors}
        options={{
          layerPriority: [
            SelfScheduleCalendarLayers.AVAILABILITY_AND_SLOTS,
            SelfScheduleCalendarLayers.BACKGROUND_BLOCKERS,
          ],
        }}
        headerProps={{
          alertBanner: timezoneInfoBanner,
        }}
      />
    </div>
  );
}

function SelfScheduleFloatingToolbar({
  availableSlotsCount,
  isLoading,
  onRefresh,
}: {
  availableSlotsCount: number;
  isLoading: boolean;
  onRefresh: () => void;
}) {
  return (
    // Hacking left distance in not sure why it's not centering within calendar view element
    <FloatingToolbar className="flex items-center space-x-2 left-[calc(50%+220px)]">
      {isLoading ? (
        <LoadingIndicator size="small" className="text-purple-500 w-5 h-5" />
      ) : (
        <Icon content={atlasRingCheck} className="text-green-500 w-5 h-5" />
      )}
      <span>{strings.pluralize("slot", availableSlotsCount)} available</span>
      <AriaButton disabled={isLoading} onClick={onRefresh}>
        <Icon
          content={atlasRefreshCw}
          className={clsx("text-white rounded-md hover:bg-indigo-300", {
            "opacity-50 cursor-not-allowed": isLoading,
          })}
        />
      </AriaButton>
    </FloatingToolbar>
  );
}
