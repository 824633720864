import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionGroupLabel } from "@resource/atlas/option/OptionGroupLabel";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { AtlasPopoverStateProps, Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { SelectLabel } from "@resource/atlas/select/utils/SelectLabel";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { UserForTagSelectionFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import useQuery from "utils/useQuery";

const USERS_FOR_TAG_SELECTION = gql(`
query UsersForTagSelection($searchTerm: String!, $limit: Int!, $adminsOnly: Boolean, $excludeInterviewerPoolId: String) {
  userMemberships(search: $searchTerm limit: $limit adminsOnly: $adminsOnly excludeInterviewerPoolId: $excludeInterviewerPoolId) {
    ...UserForTagSelection
  }
}
`);

type QueryFilters = Partial<
  Omit<
    typeof USERS_FOR_TAG_SELECTION extends TypedDocumentNode<unknown, infer V>
      ? V
      : never,
    "searchTerm" | "limit"
  >
>;

type User = UserForTagSelectionFragment;

interface InterviewSelectorProps {
  selected: User[];
  onSelectionChanged?: (selected: User[]) => unknown;
  className?: string;
  filters?: QueryFilters;
  popoverStateProps?: AtlasPopoverStateProps;
  placeholderText?: string;
}

export function InterviewerMultiSelector({
  selected,
  onSelectionChanged,
  className,
  filters,
  popoverStateProps,
  placeholderText = "Select interviewers",
}: InterviewSelectorProps) {
  const popoverState = usePopoverState(popoverStateProps);
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");

  const { data, loading } = useQuery(USERS_FOR_TAG_SELECTION, {
    variables: {
      searchTerm: debouncedTerm,
      limit: 20,
      adminsOnly: filters?.adminsOnly ?? null,
      excludeInterviewerPoolId: filters?.excludeInterviewerPoolId ?? null,
    },
    fetchPolicy: "cache-and-network",
  });

  const recipients = useMemo(() => {
    return data?.userMemberships ?? [];
  }, [data?.userMemberships]);

  const select = useCallback(
    (user: User) => {
      const isSelected = selected.some((s) => s.id === user.id);

      if (isSelected) {
        onSelectionChanged?.(selected.filter((s) => s.id !== user.id));
        return;
      }

      onSelectionChanged?.([...selected, user]);
    },
    [onSelectionChanged, selected]
  );

  const options = useMemo(() => {
    const selectedOptions = selected.map((user) => (
      <OptionItem
        key={user.id}
        isSelectable
        isSelected
        size="compact"
        onClick={() => select(user)}
      >
        <div className="flex gap-2 items-center">
          <Avatar size="xs" image={user.imageUrl} name={user.name} />
          <div>{user.name}</div>
        </div>
      </OptionItem>
    ));

    const unselectedOptions = recipients
      .filter((user) => !selected.some((s) => s.id === user.id))
      .map((user) => (
        <OptionItem
          key={user.id}
          isSelectable
          isSelected={false}
          size="compact"
          onClick={() => select(user)}
        >
          <div className="flex gap-2 items-center">
            <Avatar size="xs" image={user.imageUrl} name={user.name} />
            <div>{user.name}</div>
          </div>
        </OptionItem>
      ));

    return [...selectedOptions, ...unselectedOptions];
  }, [selected, recipients, select]);

  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger>
        <SelectTrigger
          className={clsx("flex-1 w-full bg-light-gray-500", className)}
          isPlaceholder={selected.length === 0}
        >
          {selected.length > 0 ? (
            <SelectLabel items={selected.map((s) => s.name)} />
          ) : (
            placeholderText
          )}
        </SelectTrigger>
      </Popover.Trigger>
      <Popover.Content hasPadding={false} className="sameWidth p-2" portal>
        <TextField
          className="p-1 w-full"
          size="small"
          value={searchTerm}
          icon={atlasSearch}
          placeholder="Search"
          aria-label="Search"
          onChange={setSearchTerm}
          isClearable
        />
        <div className="p-[.5rem] pt-0">
          <OptionGroupLabel>Add user</OptionGroupLabel>
          <OptionSeparator />
          {loading && (
            <>
              <div className="flex justify-center">
                <LoadingIndicator size="small" />
              </div>
            </>
          )}
          {!loading && options.length > 0 && <>{options}</>}
          {!loading && options.length === 0 && (
            <>
              <p className="px-5 py-2 text-body-md">No results found</p>
            </>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

InterviewerMultiSelector.storyData = {
  query: USERS_FOR_TAG_SELECTION,
};
