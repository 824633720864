import { zodResolver } from "@hookform/resolvers/zod";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import {
  ScheduledInterviewGroupSettings,
  ScheduledInterviewGroupSettingsSchema,
  StagedScheduledInterviewGroupSettingsSchema,
} from "../utils/types";

export type ScheduledInterviewGroupSettingsOnChange = (
  props: ScheduledInterviewGroupSettings
) => void;

type UseScheduledInterviewGroupSettingsFormStateProps = {
  scheduledInterviewGroupSettings: ScheduledInterviewGroupSettings;
  interviewerUserMembershipIds: string[];
  onChange?: ScheduledInterviewGroupSettingsOnChange;
};

export function useScheduledInterviewGroupSettingsFormState({
  scheduledInterviewGroupSettings: defaultScheduledInterviewGroupSettings,
  interviewerUserMembershipIds,
  onChange: passedOnChange,
}: UseScheduledInterviewGroupSettingsFormStateProps) {
  const form = useForm<ScheduledInterviewGroupSettings>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultScheduledInterviewGroupSettings,
    resolver: zodResolver(StagedScheduledInterviewGroupSettingsSchema),
  });
  const { formState } = form;

  useEffect(() => {
    // Deconstruct these values so that they are populated -- because of Proxy must do this for it work
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { errors, dirtyFields } = formState;
  }, [formState]);

  useEffect(() => {
    const subscription = form.watch((newValue) => {
      const parsedValue =
        ScheduledInterviewGroupSettingsSchema.safeParse(newValue);

      if (parsedValue.success) {
        const scheduledInterviewGroupSettings = parsedValue.data;

        passedOnChange?.(scheduledInterviewGroupSettings);
      }
    });

    return () => subscription.unsubscribe();
  }, [form, passedOnChange]);

  const disabledTooltipContent = useDisabledTooltipContentForForm(formState, {
    skipDirtyCheck: true,
  });

  /** Wrapper around onChange to properly handle dirtying and validation */
  const onChange = useCallback(
    (...params: Parameters<typeof form.setValue>) => {
      const [name, value, opts] = params;

      form.setValue(name, value, {
        shouldDirty: true,
        ...opts,
      });
      // Need to manually trigger validation because of a bug with zodResolver and superRefine
      // https://github.com/react-hook-form/resolvers/issues/661
      form.trigger();
    },
    [form]
  );

  const values = form.watch();
  // Not the most performant, but does the job for now
  const errors = useMemo(() => {
    const parsing =
      StagedScheduledInterviewGroupSettingsSchema.safeParse(values);

    if (parsing.success) {
      return null;
    }

    return parsing.error.issues.map((issue) => issue.message);
  }, [values]);

  const originalScheduledInterviewGroupSettings = useMemo(() => {
    return defaultScheduledInterviewGroupSettings;
  }, [defaultScheduledInterviewGroupSettings]);

  return useMemo(
    () => ({
      form,
      disabledTooltipContent,
      interviewerUserMembershipIds,
      onChange,
      originalScheduledInterviewGroupSettings,
      errors,
    }),
    [
      disabledTooltipContent,
      errors,
      form,
      interviewerUserMembershipIds,
      onChange,
      originalScheduledInterviewGroupSettings,
    ]
  );
}

export type ScheduledInterviewGroupSettingsFormState = ReturnType<
  typeof useScheduledInterviewGroupSettingsFormState
>;
