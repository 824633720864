import { yupResolver } from "@hookform/resolvers/yup";
import { UserMembershipForEditProfileFragment } from "generated/graphql-codegen/graphql";
import isUrl from "is-url";
import _ from "lodash";
import prependHttp from "prepend-http";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { validateVideoIntroductionUrl } from "utils/validation";
import * as yup from "yup";

import { ProfileFormData, WebsiteUrlObj } from "../types";

const schema = yup
  .object({
    name: yup.string().required("Name is required"),
    title: yup.string(),
    biography: yup.string(),
    pronoun: yup
      .string()
      .nullable()
      .test(
        "nullable-string-is-not-empty",
        () => "Custom pronoun cannot be empty",
        (value) => value !== ""
      ),
    websiteUrls: yup
      .array()
      .transform((val: [{ url: string }]) =>
        // treat list as empty when contains the default empty field
        val.length > 1 || _.get(val, "0.url") ? val : []
      )
      .of(
        yup.object({
          url: yup
            .string()
            .test(
              "isUrl",
              "Must be a valid url",
              (val: string | undefined) => !val || isUrl(prependHttp(val))
            ),
        })
      ),
    imageUrl: yup.string(),
    videoIntroductionUrl: yup
      .string()
      .test(
        "isUrl",
        "Must be a valid Youtube or Loom url",
        (val) => !val?.length || validateVideoIntroductionUrl(val)
      ),
  })
  .required();

const defaultValues = {
  name: "",
  title: "",
  biography: "",
  websiteUrls: [{ url: "" }],
  imageUrl: "",
  pronoun: "",
  videoIntroductionUrl: "",
};

export type UseEditProfileStateProps = {
  userMembership: UserMembershipForEditProfileFragment;
};

export function useEditProfileState({
  userMembership,
}: UseEditProfileStateProps) {
  const form = useForm<ProfileFormData>({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const { reset } = form;

  const resetForm = useCallback(() => {
    if (userMembership) {
      reset({
        name: userMembership.name,
        title: userMembership.title ?? "",
        pronoun: userMembership.pronoun,
        biography: userMembership.biography ?? "",
        websiteUrls:
          userMembership.websiteUrls.length > 0
            ? _.map(
                userMembership.websiteUrls,
                (url: string): WebsiteUrlObj => ({ url })
              )
            : [{ url: "" }],
        imageUrl: userMembership.imageUrl ?? "",
        videoIntroductionUrl: userMembership.videoIntroductionUrl ?? "",
      });
    }
  }, [userMembership, reset]);

  useEffect(resetForm, [resetForm]);

  return useMemo(() => {
    return {
      form,
      resetForm,
    };
  }, [form, resetForm]);
}

export type EditProfileState = ReturnType<typeof useEditProfileState>;
