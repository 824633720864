/**
 * Centralized sizing logic for the calendar.
 */

import { useEffect, useMemo, useState } from "react";

import {
  useCalendarContext,
  useOptionalCalendarContext,
} from "../utils/context";
import { CalendarsGrouping } from "../utils/types";
import { useCalendarSettings } from "./settings";

export type CalculatedEventsViewSize = {
  height: number;
  width: number;
};

export function useCalculatedEventsViewSize() {
  const { calculatedEventsViewSize } = useCalendarContext();

  return calculatedEventsViewSize;
}

export const convertRemToPx = (val: number) => val * 16;

const SMALL_SCREEN_WIDTH = 600;

type SizeType = {
  rem: number;
  px: number;
};

type CalendarSizes = {
  hourHeight: SizeType;
  leftOffsetWidth: SizeType;
  rightOffsetWidth: SizeType;
  allDayEventsViewHeight: SizeType;
  minColumnWidth: SizeType;
  columnsHeaderHeight: SizeType;
};

type RemCalendarSizes = {
  [key in keyof CalendarSizes]: number;
};

/** Height displaying just the day of the week */
const WEEK_VIEW_COLUMN_HEADER_HEIGHT_REM = 3;
/** Height for custom component for calendar grouping display */
const DAY_VIEW_COLUMN_HEADER_HEIGHT_REM = 3;
/** Height for custom component for secondary grouping display */
const DAY_VIEW_GROUPING_COLUMN_HEADER_HEIGHT_REM = 2;
/** Combine calendar and secondary grouping heights */
const DAY_VIEW_WITH_GROUPING_COLUMN_HEADER_HEIGHT_REM =
  DAY_VIEW_COLUMN_HEADER_HEIGHT_REM +
  DAY_VIEW_GROUPING_COLUMN_HEADER_HEIGHT_REM;

export const useCalendarSizes = ({
  calendarsGrouping: calendarsGroupingOverride,
}: {
  /** Pass when used before context is defined */
  calendarsGrouping?: CalendarsGrouping | null;
} = {}): CalendarSizes => {
  const { view, dayViewType } = useCalendarSettings();
  const calendarContext = useOptionalCalendarContext();
  const calendarsGrouping =
    calendarsGroupingOverride ?? calendarContext?.calendarsGrouping;
  const hasCalendarsGrouping = !!calendarsGrouping;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columnsHeaderHeight = useMemo(() => {
    if (view === "week") {
      return WEEK_VIEW_COLUMN_HEADER_HEIGHT_REM;
    }

    if (dayViewType === "calendar_and_grouping" && hasCalendarsGrouping) {
      return DAY_VIEW_WITH_GROUPING_COLUMN_HEADER_HEIGHT_REM;
    }

    return DAY_VIEW_COLUMN_HEADER_HEIGHT_REM;
  }, [dayViewType, hasCalendarsGrouping, view]);

  const baseValues = {
    hourHeight: 3,
    leftOffsetWidth: 3.5,
    allDayEventsViewHeight: 2,
    minColumnWidth: 10,
    columnsHeaderHeight,
  };

  let remValues: RemCalendarSizes;

  if (width <= SMALL_SCREEN_WIDTH) {
    remValues = {
      ...baseValues,
      rightOffsetWidth: 0,
    };
  } else {
    remValues = {
      ...baseValues,
      rightOffsetWidth: 2,
    };
  }

  return Object.entries(remValues).reduce((key, [keyName, remValue]) => {
    return {
      ...key,
      [keyName]: {
        rem: remValue,
        px: convertRemToPx(remValue),
      },
    };
  }, {} as CalendarSizes);
};
