import {
  SchedulingCompletionPromptsDisplay,
  SchedulingCompletionPromptsDisplayProps,
} from "client/components/scheduling-completion/display/SchedulingCompletionPromptsDisplay";
import { PostMessageViewProps } from "client/message-composer/__components/PostMessageView";
import { useCallback, useMemo } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";

import { useSchedulingPromptsNavigatorOrThrow } from "./SchedulingCompletionPromptsNavigator";

export type SchedulingCompletionPromptsViewProps = Omit<
  SchedulingCompletionPromptsDisplayProps,
  "onUpdateCandidate" | "onDismiss"
> & {
  guideId: string;
  schedulingRequestId?: string;
  thread?: PostMessageViewProps["thread"];
  isReschedule?: boolean;
  isCancellationConfirmation?: boolean;
};

/**
 * Wrapper around SchedulingCompletionPromptsDisplay
 *
 * Should ONLY be used within the schedulingn completion prompts navigator, or error will be thrown
 */
export function SchedulingCompletionPromptsView({
  guideId,
  schedulingRequestId,
  isReschedule,
  thread,
  isCancellationConfirmation,
  ...props
}: SchedulingCompletionPromptsViewProps) {
  const navigator = useSchedulingPromptsNavigatorOrThrow();

  const workflowType = useMemo(() => {
    if (isCancellationConfirmation) {
      return PostMessageWorkflow.SEND_MESSAGE;
    }

    return !isReschedule
      ? PostMessageWorkflow.CONFIRM_INTERVIEWS
      : PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS;
  }, [isCancellationConfirmation, isReschedule]);

  const onUpdateCandidate = useCallback(() => {
    navigator.navigate("postMessage", {
      guideId,
      schedulingRequestId,
      thread,
      workflowType,
    });
  }, [guideId, navigator, schedulingRequestId, thread, workflowType]);

  return (
    <SchedulingCompletionPromptsDisplay
      {...props}
      onUpdateCandidate={onUpdateCandidate}
      onDismiss={navigator.dismissNavigator}
    />
  );
}
