import { interviewerSlotsHaveChanged } from "client/components/interviewer-slots/utils/misc";
import { isEqual } from "lodash";
import {
  durationIsEqual,
  timeIsEqual,
} from "shared/guide-scheduler/field-changes/time";
import { ScheduledInterviewFieldChange } from "shared/guide-scheduler/field-changes/types";

import {
  ScheduledInterviewFieldChangeData,
  UpsertScheduledInterview,
} from "./types";

/** Note: This function must be kept in sync with getFieldChangesForScheduledInterviews */
export function computeChangesForScheduledInterviewForm({
  oldInterview,
  newInterview,
}: {
  oldInterview: UpsertScheduledInterview;
  newInterview: UpsertScheduledInterview;
}) {
  const changes: ScheduledInterviewFieldChangeData[] = [];

  if (oldInterview.isCancelled && !newInterview.isCancelled) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.RESTORED,
      oldValue: oldInterview.isCancelled,
      newValue: newInterview.isCancelled,
    });
  }

  if (!timeIsEqual(newInterview.startTime, oldInterview.startTime)) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.TIME,
      oldValue: {
        startTime: oldInterview.startTime,
        endTime: oldInterview.endTime,
      },
      newValue: {
        startTime: newInterview.startTime,
        endTime: newInterview.endTime,
      },
    });
  }

  if (
    !durationIsEqual({
      startTime1: oldInterview.startTime,
      startTime2: newInterview.startTime,
      endTime1: oldInterview.endTime,
      endTime2: newInterview.endTime,
    })
  ) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.DURATION,
      oldValue: {
        startTime: oldInterview.startTime,
        endTime: oldInterview.endTime,
      },
      newValue: {
        startTime: newInterview.startTime,
        endTime: newInterview.endTime,
      },
    });
  }

  if (
    interviewerSlotsHaveChanged(
      newInterview.interviewerSlots,
      oldInterview.interviewerSlots
    )
  ) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.INTERVIEWERS,
      oldValue: oldInterview.interviewerSlots,
      newValue: newInterview.interviewerSlots,
    });
  }

  if (
    newInterview.conferenceRoomSlot?.conferenceRoom?.id !==
    oldInterview.conferenceRoomSlot?.conferenceRoom?.id
  ) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.CONFERENCE_ROOM,
      oldValue: oldInterview.conferenceRoomSlot?.conferenceRoom,
      newValue: newInterview.conferenceRoomSlot?.conferenceRoom,
    });
  }

  if (
    !isEqual(oldInterview.conferencingSlot, newInterview.conferencingSlot) ||
    newInterview.conferencingSlot?.generationSettings?.regenerateLink
  ) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.VIDEO_CONFERENCING,
      oldValue: oldInterview.conferencingSlot,
      newValue: newInterview.conferencingSlot,
    });
  }

  if (
    !isEqual(
      oldInterview.collaborativeCodingSlot,
      newInterview.collaborativeCodingSlot
    )
  ) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.COLLABORATIVE_CODING,
      oldValue: oldInterview.collaborativeCodingSlot,
      newValue: newInterview.collaborativeCodingSlot,
    });
  }

  if (!isEqual(newInterview.title, oldInterview.title)) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.TITLE,
      oldValue: oldInterview.title,
      newValue: newInterview.title,
    });
  }

  if (newInterview.isPrivate !== oldInterview.isPrivate) {
    changes.push({
      fieldName: ScheduledInterviewFieldChange.PRIVACY,
      oldValue: oldInterview.isPrivate,
      newValue: newInterview.isPrivate,
    });
  }

  return changes;
}
