/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  COMMAND_PRIORITY_CRITICAL,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_TAB_COMMAND,
  LexicalCommand,
} from "lexical";
import { useEffect } from "react";

import { useEvent } from "../../../../__utils/react";

// register util
// -------------

function useRegister<P extends KeyboardEvent | null>(
  open: boolean,
  command: LexicalCommand<P>,
  handler?: () => void
) {
  const [editor] = useLexicalComposerContext();
  const listener = useEvent((event: P) => {
    if (open && handler) {
      event?.preventDefault();
      event?.stopImmediatePropagation();
      handler();
      return true;
    }
    return false;
  });
  return useEvent(() =>
    editor.registerCommand(command, listener, COMMAND_PRIORITY_CRITICAL)
  );
}

// typeahead control
// -----------------

type TypeaheadControlOptions = {
  open?: boolean;
  onArrowDown?: () => void;
  onArrowUp?: () => void;
  onEscape?: () => void;
  onTab?: () => void;
  onEnter?: () => void;
};

export function useTypeaheadControl({
  open = false,
  onArrowDown,
  onArrowUp,
  onEscape,
  onTab,
  onEnter,
}: TypeaheadControlOptions) {
  const rArrowDown = useRegister(open, KEY_ARROW_DOWN_COMMAND, onArrowDown);
  const rArrowUp = useRegister(open, KEY_ARROW_UP_COMMAND, onArrowUp);
  const rEscape = useRegister(open, KEY_ESCAPE_COMMAND, onEscape);
  const rTab = useRegister(open, KEY_TAB_COMMAND, onTab);
  const rEnter = useRegister(open, KEY_ENTER_COMMAND, onEnter);
  useEffect(
    () => mergeRegister(rArrowDown(), rArrowUp(), rEscape(), rTab(), rEnter()),
    [rArrowDown, rArrowUp, rEnter, rEscape, rTab]
  );
}
