import clsx from "clsx";

type EmptyStateWrapperProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export function EmptyStateWrapper({
  children,
  className,
  ...props
}: EmptyStateWrapperProps) {
  return (
    <div
      {...props}
      className={clsx(
        "bg-light-gray-200 h-full rounded-md flex p-6 text-dark justify-center items-center",
        className
      )}
    >
      <div className="flex flex-col items-center text-center">
        <p className="text-body-md text-subtle">{children}</p>
      </div>
    </div>
  );
}
