/* eslint-disable import/prefer-default-export */
import "./VideoPopover.sass";

import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";

import { createComponentUtils } from "../../../__utils/atlas";
import { useEvent } from "../../../__utils/react";
import { Button } from "../../../button/Button";
import TextField from "../../../textfield/TextField";
import {
  ContentEditorPopover,
  ContentEditorPopoverProps,
} from "../../__utils/content-editor-popover";
import { extractEmbedDataFromUrl } from "./embed";
import { EmbedData } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Video_useVideoPopover";

const { el } = createComponentUtils(COMPONENT_NAME);

// use video popover
// -----------------

type VideoPopoverProps = {
  PopoverComponent?: FunctionComponent<
    React.PropsWithChildren<ContentEditorPopoverProps>
  >;
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  onSubmit: (embedData: EmbedData) => void;
  submitLabel: string;
  getAnchorRect?: () => DOMRect | null;
};

export function VideoPopover({
  PopoverComponent = ContentEditorPopover,
  open,
  setOpen,
  title,
  onSubmit,
  submitLabel,
  getAnchorRect,
}: VideoPopoverProps) {
  const [urlInputValue, setUrlInputValue] = useState("");
  const embedData = useMemo(
    () => extractEmbedDataFromUrl(urlInputValue),
    [urlInputValue]
  );

  useEffect(() => {
    if (!open && urlInputValue !== "") setUrlInputValue("");
  }, [open, urlInputValue]);

  const inputRef = useRef<HTMLInputElement>(null);

  const submit = useEvent(() => {
    if (!embedData) return;
    onSubmit(embedData);
    setOpen(false);
  });

  const body = (
    <TextField
      label="Link"
      isRequired
      UNSAFE_inputProps={{
        onKeyDown: (e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            submit();
          }
        },
      }}
      inputRef={inputRef}
      placeholder="https://www.youtube.com/watch?v=VUeXSW4oROU"
      value={urlInputValue}
      onChange={setUrlInputValue}
    />
  );

  const footer = (
    <div className={el`footer`}>
      <Button
        size="small"
        variant="primary"
        disabled={!embedData}
        onClick={submit}
      >
        {submitLabel}
      </Button>
    </div>
  );

  return (
    <PopoverComponent
      open={open}
      setOpen={setOpen}
      title={title}
      description="Paste a link to embed a video from YouTube, Vimeo, or Loom."
      footer={footer}
      getAnchorRect={getAnchorRect}
      initialFocusRef={inputRef}
    >
      {body}
    </PopoverComponent>
  );
}
