import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { formatEntity } from "shared/constants/entities";

type CandidateEmailSelectTriggerProps = ComponentPropsWithoutRef<"button"> & {
  value: string;
};

const CandidateEmailSelectTrigger = forwardRef<
  HTMLButtonElement,
  CandidateEmailSelectTriggerProps
>(({ value, ...props }, ref) => (
  <SelectTrigger isGhost ref={ref} {...props}>
    {value}
  </SelectTrigger>
));

type CandidateEmailSelectProps = Omit<
  ComponentPropsWithoutRef<"button">,
  "value" | "onChange"
> & {
  value: string;
  emails: string[];
  onChange: (email: string) => unknown;
};

export default function CandidateEmailSelect({
  value,
  emails,
  onChange,
  ...props
}: CandidateEmailSelectProps) {
  const noEmailText = `Send to the ${formatEntity("guide")} only`;
  const items = useSelectItems(
    (i) => {
      const dynamicItems = emails.map((email) =>
        i.option({
          key: email,
          value: email,
          children: email,
          size: "compact",
        })
      );
      dynamicItems.push(i.separator({ key: "option-separator" }));
      dynamicItems.push(
        i.option({
          key: "",
          value: "",
          children: noEmailText,
          size: "compact",
        })
      );
      return dynamicItems;
    },
    [emails, noEmailText]
  );

  return (
    <Select.Root
      sameWidth
      value={value}
      setValue={(val) => onChange(val as string)}
    >
      <Select.Trigger isGhost>
        <CandidateEmailSelectTrigger
          {...props}
          value={value === "" ? noEmailText : value}
        />
      </Select.Trigger>
      <Select.Content items={items} />
    </Select.Root>
  );
}
