import { Avatar } from "@resource/atlas/avatar/Avatar";
import clsx from "clsx";
import { DateTime } from "luxon";
import {
  getNotificationAvatar,
  renderNotification,
} from "modules/notifications/renderers/app";
import React, { useMemo } from "react";
import { useNavigateToFrameHref } from "utils/navigation";

import { getNotificationDisplay } from "./display";
import { NotificationDisplayType } from "./utils/types";

export interface NotificationDisplayProps {
  notification: NotificationDisplayType;
  onClick: () => void;
}

export function NotificationDisplay({
  notification,
  onClick,
}: NotificationDisplayProps) {
  const navigateToFrameHref = useNavigateToFrameHref({});
  const { read, actor, href } = notification;
  const notificationRenderer = useMemo(() => {
    if (notification.type) {
      return renderNotification({
        type: notification.type,
        data: notification.data,
      });
    }

    const DisplayComponent = getNotificationDisplay(notification);

    return <DisplayComponent notification={notification} className="grow" />;
  }, [notification]);
  const avatar = useMemo(() => {
    if (notification.type) {
      return getNotificationAvatar({
        type: notification.type,
        data: notification.data,
      });
    }

    return {
      name: actor?.name || "Unknown User",
      avatarUrl: actor?.avatarUrl,
    };
  }, [notification, actor]);

  const getRelativeTime = (date: string) => {
    const minutesSinceCreation = DateTime.fromISO(date).diff(
      DateTime.now(),
      "minutes"
    ).minutes;

    if (minutesSinceCreation > -1) {
      return "just now";
    }
    return DateTime.fromISO(date).toRelative();
  };

  const handleNavigate = () => {
    navigateToFrameHref({ href });
    onClick();
  };

  return (
    <div
      className={clsx(
        "w-full flex items-start gap-3 p-3 rounded-lg relative",
        href ? "hover:bg-light-gray-200 cursor-pointer" : "cursor-default"
      )}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleNavigate();
        }
      }}
      onClick={handleNavigate}
    >
      <Avatar
        size="large"
        image={avatar?.avatarUrl}
        name={avatar?.name}
        icon={avatar?.icon}
        iconClass={avatar?.iconClass}
      />
      <div className="space-y-[0.125rem] grow">
        <div className="flex items-center gap-2">
          <div className="grow text-body-md">{notificationRenderer}</div>
          <div
            className={clsx("h-2 w-2 rounded-full shrink-0", {
              "bg-purple-500": !read,
            })}
          />
        </div>
        <p className="text-body-sm text-subtle">
          {getRelativeTime(notification.createdAt)}
        </p>
      </div>
    </div>
  );
}
