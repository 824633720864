import { useSelectedInterview } from "client/scheduler/core/hooks/interviews";
import { SchedulerEditableScheduledInterview } from "client/scheduler/utils/types";

/** Derive from selected interview whether we should show a placing interview */
export function usePlacingInterview(): {
  placingInterview: SchedulerEditableScheduledInterview | null;
  shouldShowPlacement: boolean;
} {
  const selectedInterview = useSelectedInterview();

  if (!selectedInterview) {
    return {
      placingInterview: null,
      shouldShowPlacement: false,
    };
  }

  if (!selectedInterview.startTime) {
    return {
      placingInterview: selectedInterview,
      shouldShowPlacement: true,
    };
  }

  return {
    placingInterview: null,
    shouldShowPlacement: false,
  };
}
