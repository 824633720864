import { Interval, WeekdayNumbers } from "luxon";
import { useMemo } from "react";

import { AvailabilityUserMembershipWithWorkingHours } from "../utils/types";

/** Compute data for displaying interviewer availability in the hovercard */
export function useInterviewerDisplayData({
  interviewer,
  dayOfWeek,
  timezone,
  currentInterval,
}: {
  interviewer: AvailabilityUserMembershipWithWorkingHours;
  dayOfWeek: WeekdayNumbers;
  timezone: string;
  currentInterval: Interval;
}) {
  return useMemo(() => {
    const startOfWeekInCalendarTimezone =
      currentInterval.start.setZone(timezone);
    const dayIndex = dayOfWeek - 1;
    const todayWorkingHours = interviewer.workingHours?.[dayIndex];
    const todayInCalendarTimezone = startOfWeekInCalendarTimezone;
    const todayInUserTimezone = todayInCalendarTimezone.setZone(
      interviewer.user.timezone
    );
    const startTime = todayInUserTimezone
      .startOf("day")
      .plus({
        days: dayIndex,
        hours: todayWorkingHours?.startTime?.hour,
        minutes: todayWorkingHours?.startTime?.minute,
      })
      .set({
        hour: todayWorkingHours?.startTime?.hour,
        minute: todayWorkingHours?.startTime?.minute,
      })
      .setZone(timezone);

    const endTime = todayInUserTimezone
      .startOf("day")
      .plus({
        days: dayIndex,
        hours: todayWorkingHours?.endTime?.hour,
        minutes: todayWorkingHours?.endTime?.minute,
      })
      .setZone(timezone);
    const workingThisDay = todayWorkingHours?.isWorkingDay;
    const displayedWorkingHours = todayWorkingHours
      ? `${startTime.toFormat("h:mm a")} - ${endTime.toFormat(
          "h:mm a"
        )} (${startTime.toFormat("ZZZZ")})`
      : null;

    return {
      todayInUserTimezone,
      workingThisDay,
      displayedWorkingHours,
    };
  }, [
    currentInterval,
    timezone,
    dayOfWeek,
    interviewer.workingHours,
    interviewer.user.timezone,
  ]);
}
