import { useAuthContext } from "auth/context";
import { INSERT_SELF_SCHEDULE_REQUEST_COMMAND } from "client/self-schedule/rich-blocks/self-schedule-request/rich-block";
import { LexicalEditor } from "lexical";
import { useCallback } from "react";
import { DEFAULT_DATA as SELF_SCHEDULE_REQUEST_DEFAULT_DATA } from "shared/self-schedule/rich-blocks/self-schedule-request";

export type UseInsertSelfScheduleRequestProps = {
  editor: LexicalEditor;
  selfScheduleInterviewId?: string;
};

export function useInsertSelfScheduleRequest({
  editor,
  selfScheduleInterviewId,
}: UseInsertSelfScheduleRequestProps) {
  const { user } = useAuthContext();
  return useCallback(() => {
    if (user?.currentUserMembership) {
      editor.focus();
      editor.dispatchCommand(
        INSERT_SELF_SCHEDULE_REQUEST_COMMAND,
        selfScheduleInterviewId
          ? {
              interviewId: selfScheduleInterviewId,
              needsConfiguration: true,
            }
          : SELF_SCHEDULE_REQUEST_DEFAULT_DATA
      );
    }
  }, [editor, selfScheduleInterviewId, user?.currentUserMembership]);
}
