/**
 * Utility callbacks to manage calendar state and interactions.
 */

import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

import { CalendarEvent, ScrollToTime } from "../utils/types";
import { isEventInView, MINUTES_IN_DAY, MINUTES_IN_HOUR } from "../utils/utils";
import { useCalendarOptions } from "./options";
import { useEventsViewRef, useWrapperRef } from "./refs";
import {
  useCalendarSetSelectedDay,
  useCalendarSettings,
  useCalendarTimezone,
  useCurrentInterval,
} from "./settings";
import { useCalculatedEventsViewSize } from "./sizing";
import { useColumns } from "./useColumns";

export function useCalculateScrollValueForTime() {
  const calculatedEventsViewSize = useCalculatedEventsViewSize();
  const calculateScrollValueForTime = useCallback(
    (time: DateTime) => {
      const minutesFromBeginningOfDay =
        time.hour * MINUTES_IN_HOUR + time.minute;

      const scrollValue =
        (calculatedEventsViewSize.height * minutesFromBeginningOfDay) /
        MINUTES_IN_DAY;

      return scrollValue;
    },
    [calculatedEventsViewSize.height]
  );

  return calculateScrollValueForTime;
}

export function useScrollToTime() {
  const setSelectedDay = useCalendarSetSelectedDay();
  const wrapperRef = useWrapperRef();
  const timezone = useCalendarTimezone();
  const calculateScrollValueForTime = useCalculateScrollValueForTime();

  const scrollToTime = useCallback<ScrollToTime>(
    ({
      time: inputTime,
      hoursPadding = 0,
    }: {
      time: DateTime | string;
      hoursPadding?: number;
    }) => {
      const time =
        typeof inputTime === "string"
          ? DateTime.fromISO(inputTime).setZone(timezone)
          : inputTime;

      const scrollValue = calculateScrollValueForTime(
        time.minus({
          hours: hoursPadding,
        })
      );

      const currentWeek = time.startOf("day");
      setSelectedDay(currentWeek);

      if (wrapperRef) {
        wrapperRef.scrollTo({
          top: scrollValue,
          behavior: "smooth",
        });
      }
    },
    [calculateScrollValueForTime, setSelectedDay, timezone, wrapperRef]
  );

  return scrollToTime;
}

export function useCalculateTimeForOffset() {
  const { view } = useCalendarSettings();
  const currentInterval = useCurrentInterval();
  const { minInterval } = useCalendarOptions();
  const { width: eventsViewWidth, height: eventsViewHeight } =
    useCalculatedEventsViewSize();
  const columns = useColumns();
  const columnsCount = useMemo(() => {
    return columns.columnIds.length;
  }, [columns.columnIds.length]);

  const columnWidth = useMemo(() => {
    return eventsViewWidth / columnsCount;
  }, [eventsViewWidth, columnsCount]);

  return useCallback(
    (offset: { x: number; y: number }) => {
      const safeDayWidth = columnWidth > 0 ? columnWidth : 1;
      const days =
        view === "week" ? Math.floor(offset.x / safeDayWidth) + 1 : 1;
      const safeEventsViewHeight = eventsViewHeight > 0 ? eventsViewHeight : 1;

      const minutesFromBeginningOfDayForOffset =
        (offset.y * MINUTES_IN_DAY) / safeEventsViewHeight;

      const hoursForOffset = Math.floor(
        minutesFromBeginningOfDayForOffset / MINUTES_IN_HOUR
      );
      const minutesForOffset = Math.floor(
        minutesFromBeginningOfDayForOffset % MINUTES_IN_HOUR
      );

      const calculatedDay = currentInterval.start.plus({ days: days - 1 });

      const exactTime = calculatedDay.set({
        hour: hoursForOffset,
        minute: minutesForOffset,
        second: 0,
        millisecond: 0,
      });

      const MIN_INTERVAL_BUFFER_PERCENTAGE = 0.2;
      const buffer = minInterval * MIN_INTERVAL_BUFFER_PERCENTAGE;
      const minutesWithBuffer = minutesForOffset + buffer;

      return exactTime.set({
        minute: Math.floor(minutesWithBuffer / minInterval) * minInterval,
      });
    },
    [columnWidth, view, eventsViewHeight, currentInterval.start, minInterval]
  );
}

export function useCalculateMinutesFromDifference() {
  const { minInterval } = useCalendarOptions();
  const { height: eventsViewHeight } = useCalculatedEventsViewSize();

  return useCallback(
    (y: number) => {
      const diff = (y * MINUTES_IN_DAY) / eventsViewHeight;
      return Math.floor(diff / minInterval) * minInterval;
    },
    [eventsViewHeight, minInterval]
  );
}

export function useCalculateOffsetFromEvent() {
  const eventsViewRef = useEventsViewRef();

  return useCallback(
    (e: { clientX: number; clientY: number }) => {
      if (!eventsViewRef) {
        console.warn("Events view ref not defined");
        return { x: 0, y: 0 };
      }

      const { x, y } = eventsViewRef.getBoundingClientRect();
      return { x: e.clientX - x, y: e.clientY - y };
    },
    [eventsViewRef]
  );
}

export function useIsEventInView() {
  const { includeWeekends } = useCalendarSettings();
  const currentInterval = useCurrentInterval();

  return useCallback(
    (event: Pick<CalendarEvent, "startTime" | "endTime" | "allDay">) => {
      return isEventInView({
        includeWeekends,
        currentInterval,
        event,
      });
    },
    [includeWeekends, currentInterval]
  );
}

/** Wrapper around useCalculateTimeForOffset to also indicate column index (when columns are calendars) */
export function useCalculateTimeAndColumnForOffset() {
  const calculateTimeForOffset = useCalculateTimeForOffset();
  const { columnIds } = useColumns();
  const { width: eventsViewWidth } = useCalculatedEventsViewSize();

  const columnWidth = useMemo(() => {
    return eventsViewWidth / columnIds.length;
  }, [eventsViewWidth, columnIds.length]);

  return useCallback(
    (offset: { x: number; y: number }) => {
      const time = calculateTimeForOffset(offset);
      const columnIndex = Math.floor(offset.x / columnWidth);

      return {
        time,
        columnIndex,
      };
    },
    [calculateTimeForOffset, columnWidth]
  );
}
