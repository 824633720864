import { zodResolver } from "@hookform/resolvers/zod";
import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { useOptionalContentEditor } from "@resource/atlas/content-editor/use-content-editor";
import { generateErrorMessage } from "client/utils/form";
import { $getRoot, $insertNodes, $parseSerializedNode } from "lexical";
import { useCallback, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { ConferencingGenerationSettings } from "shared/guide-scheduler/conferencing/types";
import {
  SelfScheduleInterviewSettingsForCreate,
  SelfScheduleInterviewSettingsForCreateSchema,
} from "shared/self-schedule/types";
import { getDefaultSelfScheduleInterviewSettings } from "shared/self-schedule/utils";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

export type EditSelfScheduleInterviewSettingsStateProps = {
  defaultInterviewSettings: SelfScheduleInterviewSettingsForCreate | null;
  skipDirtyCheck?: boolean;
};

export function useEditSelfScheduleInterviewSettingsState({
  defaultInterviewSettings,
  skipDirtyCheck,
}: EditSelfScheduleInterviewSettingsStateProps) {
  const form = useForm<SelfScheduleInterviewSettingsForCreate>({
    defaultValues:
      defaultInterviewSettings ?? getDefaultSelfScheduleInterviewSettings(),
    resolver: zodResolver(
      SelfScheduleInterviewSettingsForCreateSchema.refine(
        ({ title }) => {
          if (
            title.trim() === "" ||
            getTitleFromLexicalJson(title).trim() === ""
          ) {
            return false;
          }

          return true;
        },
        {
          message: "Title is required.",
          path: ["title"],
        }
      )
    ),
  });
  const { watch, setValue } = form;

  const interviewer = watch("interviewer");
  const locationSettings = watch("locationSettings");
  const isPrivate = watch("isPrivate");

  const { editor: titleEditor, contentEditorProps: titleContentEditorProps } =
    useOptionalContentEditor();
  const updateTitleEditorState = useCallback(
    (data: AtlasContentEditorSerializedState) => {
      setValue("title", JSON.stringify(data), {
        shouldValidate: true,
        shouldDirty: false,
      });

      if (!titleEditor) {
        return;
      }

      titleEditor.update(() => {
        $getRoot().clear();
        $insertNodes(
          data.root.children.map((n) => {
            return $parseSerializedNode(n);
          })
        );
      });
    },
    [titleEditor, setValue]
  );

  const onChange = useCallback(
    (...params: Parameters<typeof setValue>) => {
      setValue(params[0], params[1], {
        shouldDirty: true,
        shouldValidate: true,
        ...params[2],
      });
    },
    [setValue]
  );

  const titleRef = useRef(defaultInterviewSettings?.title);

  /**
   * Validating title is an expensive action and causing slowness if we do it on change
   * Instead, set up a debouncer to validate the title after 300ms of inactivity
   */
  const setTitleWithValidationDebounced = useCallback(
    (passedTitle: string) => {
      titleRef.current = passedTitle;
      setTimeout(() => {
        const currTitle = getTitleFromLexicalJson(
          titleRef.current ?? ""
        ).trim();
        const newTitle = getTitleFromLexicalJson(passedTitle).trim();

        if (newTitle === currTitle) {
          onChange("title", passedTitle);
        }
      }, 300);
    },
    [onChange]
  );

  const onChangeTitle = useCallback(
    (newTitle: string) => {
      setValue("title", newTitle, {
        shouldDirty: true,
      });
      setTitleWithValidationDebounced(newTitle);
    },
    [setTitleWithValidationDebounced, setValue]
  );

  const onChangeDuration = useCallback(
    (newDuration: number) => {
      onChange("duration", newDuration);
    },
    [onChange]
  );

  const onChangeInterviewer = useCallback(
    (newInterviewer: SelfScheduleInterviewSettingsForCreate["interviewer"]) => {
      onChange("interviewer", newInterviewer);
    },
    [onChange]
  );

  const onChangeLocationSettings = useCallback(
    (newLocationSettings?: ConferencingGenerationSettings) => {
      onChange("locationSettings", newLocationSettings);
    },
    [onChange]
  );

  const onChangeEventPrivacy = useCallback(
    (newEventPrivacy: boolean) => {
      onChange("isPrivate", newEventPrivacy);
    },
    [onChange]
  );

  const disabledTooltipContent = useMemo(() => {
    if (!form.formState.isDirty && !skipDirtyCheck) {
      return "No changes have been made.";
    }

    if (!form.formState.isValid) {
      return generateErrorMessage(form.formState.errors);
    }

    if (!interviewer.user.timezone) {
      return "Interviewer must have a timezone set. Please select one in their scheduling preferences.";
    }

    return undefined;
  }, [
    form.formState.isDirty,
    form.formState.isValid,
    form.formState.errors,
    skipDirtyCheck,
    interviewer.user.timezone,
  ]);

  return useMemo(
    () => ({
      form,
      onChangeTitle,
      onChangeDuration,
      onChangeInterviewer,
      onChangeLocationSettings,
      onChangeEventPrivacy,
      interviewer,
      locationSettings,
      isPrivate,
      interviewerUserMembershipIds: [interviewer.id],
      disabledTooltipContent,
      updateTitleEditorState,
      titleContentEditorProps,
    }),
    [
      disabledTooltipContent,
      form,
      interviewer,
      isPrivate,
      locationSettings,
      onChangeDuration,
      onChangeEventPrivacy,
      onChangeInterviewer,
      onChangeLocationSettings,
      onChangeTitle,
      updateTitleEditorState,
      titleContentEditorProps,
    ]
  );
}

export type EditSelfScheduleInterviewSettingsState = ReturnType<
  typeof useEditSelfScheduleInterviewSettingsState
>;
