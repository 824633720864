import { SchedulingRequestGuidePostType } from "generated/graphql-codegen/graphql";
import { PostMessageWorkflow } from "shared/message-composer/types";

export function useSchedulingRequestGuidePostType(
  workflowType?: PostMessageWorkflow
) {
  switch (workflowType) {
    case PostMessageWorkflow.REQUEST_AVAILABILITY:
      return SchedulingRequestGuidePostType.REQUEST_AVAILABILITY;
    case PostMessageWorkflow.CONFIRM_INTERVIEWS:
      return SchedulingRequestGuidePostType.INTERVIEW_CONFIRMATION;
    case PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS:
      return SchedulingRequestGuidePostType.RESCHEDULE_CONFIRMATION;
    case PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY:
      return SchedulingRequestGuidePostType.REQUEST_ADDITIONAL_AVAILABILITY;
    default:
      return null;
  }
}
