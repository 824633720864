import { gql } from "generated/graphql-codegen";
import useQuery from "utils/useQuery";

import { TagFilter } from "./TagFilter";
import { TagFilterTag, TagFilterTagGroup } from "./types";

const GET_TAGS_AND_GROUPS = gql(`
  query GetTagsAndGroupsForTagFilter {
    tags {
      ...TagForTagFilter
    }
    tagGroups {
      ...TagGroupForTagFilter
    }
  }
`);

type TagFilterWithQueryProps = Omit<
  React.ComponentProps<typeof TagFilter>,
  "tags" | "tagGroups"
>;

export function TagFilterWithQuery(props: TagFilterWithQueryProps) {
  const { data, loading } = useQuery(GET_TAGS_AND_GROUPS, {
    fetchPolicy: "cache-and-network",
  });

  if (loading || !data) {
    return null; // Or a loading state if preferred
  }

  const tags: TagFilterTag[] = data.tags.map((tag) => ({
    id: tag.id,
    name: tag.name,
    color: tag.color,
    tagGroup: tag.tagGroup
      ? {
          id: tag.tagGroup.id,
          label: tag.tagGroup.label,
          color: tag.tagGroup.color,
        }
      : null,
    userCount: tag.userCount,
  }));

  const tagGroups: TagFilterTagGroup[] = data.tagGroups.map((group) => ({
    id: group.id,
    label: group.label,
    color: group.color,
  }));

  return <TagFilter {...props} tags={tags} tagGroups={tagGroups} />;
}

TagFilterWithQuery.storyData = {
  query: GET_TAGS_AND_GROUPS,
};
