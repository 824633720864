import {
  SCHEDULER_EMAIL_ADDRESS,
  SCHEDULER_EMAIL_NAME,
} from "notifications/emails/sending/const";

import {
  InterviewInviteContentType,
  renderInterviewInviteContent,
  RenderInterviewInviteContentData,
  renderInterviewInviteTitle,
} from "../renderers/interview-invite-content";
import { emailIcons } from "../utils/icons";
import {
  NotificationEmailRenderingData,
  NotificationEmailRenderingStrategy,
} from "./NotificationEmailRenderingStrategy";

export type InterviewUpdateEmailType =
  | "confirmation"
  | "cancellation"
  | "update"
  | "interviewer_removed";

export type InterviewUpdateEmailRenderingStrategyProps = {
  data: RenderInterviewInviteContentData;
  interviewerCanManage?: boolean;
  recipient: "candidate" | "interviewer";
  to: string;
  timezone: string;
  type: InterviewUpdateEmailType;
  note?: string;
};

function getSenderName({
  recipient,
  data,
}: InterviewUpdateEmailRenderingStrategyProps) {
  return recipient === "candidate"
    ? data.organization.name
    : SCHEDULER_EMAIL_NAME;
}

function getBannerNoteSuffix({ note }: { note?: string }) {
  const hasNote = note && note.trim().length > 0;

  if (!hasNote) {
    return {
      Component: <>.</>,
      plainText: ".",
    };
  }

  return {
    Component: (
      <>
        {" "}
        with a note:
        <br />
        <br />
        <span>
          &quot;
          {note.split("\n").map((line, idx, arr) => (
            <>
              {line}
              {idx < arr.length - 1 ? <br /> : null}
            </>
          ))}
          &quot;
        </span>
      </>
    ),
    plainText: ` with a note: "${note}".`,
  };
}

function getBannerData({
  recipient,
  data: { scheduledInterview, actor },
  type,
  note,
}: InterviewUpdateEmailRenderingStrategyProps): NotificationEmailRenderingData["banner"] {
  const confirmationData = {
    className: "bg-green-50 text-green-900",
    icon: emailIcons.calendarCheck,
    iconAltText: "Calendar checked",
  };
  const updateData = {
    className: "bg-green-50 text-green-900",
    icon: emailIcons.calendarConflict,
    iconAltText: "Calendar conflict",
  };
  const cancellationData = {
    className: "bg-red-50 text-red-900",
    icon: emailIcons.calendarCross,
    iconAltText: "Calendar Cross",
  };

  const { Component: BannerNoteSuffix, plainText: bannerNotePlainText } =
    getBannerNoteSuffix({ note });
  const interviewTitle =
    recipient === "interviewer"
      ? scheduledInterview.internalTitle
      : scheduledInterview.candidateTitle;

  if (type === "confirmation") {
    return {
      ...confirmationData,
      Component: (
        <span className="text-body-md font-normal">
          A <strong>{interviewTitle}</strong> has been scheduled
          {actor ? ` by ${actor.name}` : ""}
          {BannerNoteSuffix}
        </span>
      ),
      plainText: `A ${interviewTitle} has been scheduled${bannerNotePlainText}`,
    };
  }

  if (type === "update") {
    return {
      ...updateData,
      Component: (
        <span className="text-body-md font-normal">
          The <strong>{interviewTitle}</strong> has been updated
          {actor ? ` by ${actor.name}` : ""}
          {BannerNoteSuffix}
        </span>
      ),
      plainText: `The ${interviewTitle} has been updated${bannerNotePlainText}`,
    };
  }

  if (type === "interviewer_removed") {
    return {
      ...cancellationData,
      Component: (
        <span className="text-body-md font-normal">
          You have been removed from the <strong>{interviewTitle}</strong>
          {actor ? ` by ${actor.name}` : ""}
          {BannerNoteSuffix}
        </span>
      ),
      plainText: `You have been removed from the ${interviewTitle}${bannerNotePlainText}`,
    };
  }

  return {
    ...cancellationData,
    Component: (
      <span className="text-body-md font-normal">
        The <strong>{interviewTitle}</strong> has been cancelled
        {actor ? ` by ${actor.name}` : ""}
        {BannerNoteSuffix}
      </span>
    ),
    plainText: `The ${interviewTitle} has been canceled${bannerNotePlainText}`,
  };
}

function getPreheader(_props: InterviewUpdateEmailRenderingStrategyProps) {
  return null;
}

function getSubject(props: InterviewUpdateEmailRenderingStrategyProps) {
  const { type, data } = props;

  let prefix = "";
  let subject = "";

  if (type === "update") {
    prefix = "Updated invitation: ";
  } else if (type === "cancellation") {
    prefix = "Canceled interview: ";
  } else if (type === "interviewer_removed") {
    prefix = "Removed from interview: ";
  }

  if (data.interviewerConfirmationTitle) {
    subject = data.interviewerConfirmationTitle;
  } else {
    subject = renderInterviewInviteTitle(props);
  }

  return `${prefix}${subject}`;
}

/**
 * Our email notification has 4 types, which includes interviewer_removed
 * Our interview invite content only has 3 types, as the content for the interviewer_removed should match the cancellation content
 * This function maps the email notification type to the interview invite content type
 */
function getInterviewInviteType(
  type: InterviewUpdateEmailType
): InterviewInviteContentType {
  if (type === "interviewer_removed") {
    return "cancellation";
  }

  return type;
}

function getBody(
  props: InterviewUpdateEmailRenderingStrategyProps
): NotificationEmailRenderingData["body"] {
  const renderedContent = renderInterviewInviteContent({
    data: props.data,
    recipient: props.recipient,
    variant: "email",
    timezone: props.timezone,
    type: getInterviewInviteType(props.type),
  });

  return {
    Component: renderedContent.html,
    plainText: renderedContent.plainText,
  };
}

function getAction(
  props: InterviewUpdateEmailRenderingStrategyProps
): NotificationEmailRenderingData["action"] {
  const {
    data: { scheduledInterview },
    recipient,
    interviewerCanManage,
  } = props;

  const canManageSelfScheduledInterview =
    recipient === "candidate" || interviewerCanManage;

  if (
    canManageSelfScheduledInterview &&
    scheduledInterview.isSelfSchedule &&
    scheduledInterview.managementUrl
  ) {
    return {
      buttonLabel: "Need to make changes?",
      label: "Reschedule or cancel",
      url: scheduledInterview.managementUrl,
    };
  }

  return null;
}

export class InterviewUpdateEmailRenderingStrategy extends NotificationEmailRenderingStrategy {
  constructor(props: InterviewUpdateEmailRenderingStrategyProps) {
    const {
      data: { organization },
      to,
      recipient,
    } = props;

    super({
      recipient: {
        address: to,
      },
      sender: {
        address: SCHEDULER_EMAIL_ADDRESS,
        replyTo: SCHEDULER_EMAIL_ADDRESS,
        name: getSenderName(props),
      },
      data: {
        banner: getBannerData(props),
        body: getBody(props),
        footer: {
          text:
            recipient === "candidate"
              ? `${organization.name} uses Guide to help you prepare for your interview and keep you informed of interview details, prep materials, communications and more all in one place.`
              : `${organization.name} uses Guide to improve the interview experience for candidates and interviewers, keeping everyone informed of interview details, prep materials, communications and more all in one place.`,
        },
        meta: {
          title: getSubject(props),
          subject: getSubject(props),
          preheader: getPreheader(props),
        },
        company: organization,
        action: getAction(props),
      },
    });
  }
}
