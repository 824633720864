import { Txt } from "@resource/atlas/content-editor/renderer";
import { theme } from "@resource/atlas/content-editor/theme";
import {
  createVariableConfigUIRenderer,
  createVariableRenderer,
} from "@resource/atlas/content-editor/variables";
import { createVariableSpec } from "@resource/atlas/content-editor/variables/__lib/variable-sets";
import { Select } from "@resource/atlas/select";
import { useSelectItems } from "@resource/atlas/select/use-select-items";

export type VideoConferencingDetails = {
  // string for backwards compatibility
  value:
    | string
    | {
        url: string;
        externalId?: string | null;
        passcode?: string | null;
      };
  config: { display?: "link" | "details" };
};

export const renderVideoConferencingDetails =
  createVariableRenderer<VideoConferencingDetails>(
    ({ value: rawValue, config }, flags) => {
      const value =
        typeof rawValue === "object" ? rawValue : { url: rawValue ?? "" };
      const display = config.display ?? "link";

      if (flags?.asText) {
        if (display === "link") {
          return <Txt.Text>{value.url}</Txt.Text>;
        }

        return (
          <Txt.Block>
            <Txt.Line>{value.url}</Txt.Line>
            {value.externalId && <Txt.Line>ID: {value.externalId}</Txt.Line>}
            {value.passcode && <Txt.Line>ID: {value.passcode}</Txt.Line>}
          </Txt.Block>
        );
      }

      if (display === "link") {
        return (
          <a
            onClick={(event) => {
              if (flags?.isEditing) {
                event.preventDefault();
              }
            }}
            tabIndex={-1}
            className={theme.link}
            href={value.url}
            target="_blank"
            rel="noreferrer"
          >
            {value.url}
          </a>
        );
      }

      return (
        <div className="w-full flex flex-col">
          <a
            onClick={(event) => {
              if (flags?.isEditing) {
                event.preventDefault();
              }
            }}
            tabIndex={-1}
            className={theme.link}
            href={value.url}
            target="_blank"
            rel="noreferrer"
          >
            {value.url}
          </a>
          {value.externalId && (
            <div className="text-subtle">ID: {value.externalId}</div>
          )}
          {value.passcode && (
            <div className="text-subtle">Passcode: {value.passcode}</div>
          )}
        </div>
      );
    }
  );

export const renderVideoConferencingDetailsForEmail =
  createVariableRenderer<VideoConferencingDetails>(
    ({ value: rawValue, config }) => {
      const value = typeof rawValue === "string" ? { url: rawValue } : rawValue;
      const display = config.display ?? "link";

      if (display === "link") {
        return (
          <a
            className={theme.link}
            href={value.url}
            target="_blank"
            rel="noreferrer"
          >
            {value.url}
          </a>
        );
      }

      return (
        <table
          className="w-full [border-collapse:separate] [mso-table-lspace:0pt] [mso-table-rspace:0pt] overflow-hidden mt-[12px] mb-[12px]"
          cellPadding="0"
          cellSpacing="0"
          role="presentation"
        >
          <tbody>
            <tr>
              <td className="text-left align-middle">
                <a
                  tabIndex={-1}
                  className={theme.link}
                  href={value.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {value.url}
                </a>
              </td>
            </tr>
            {value.externalId && (
              <tr>
                <td className="text-left align-middle text-subtle">
                  ID: {value.externalId}
                </td>
              </tr>
            )}
            {value.passcode && (
              <tr>
                <td className="text-left align-middle text-subtle">
                  Passcode: {value.passcode}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      );
    }
  );

const renderVideoConferencingDetailsConfigUI =
  createVariableConfigUIRenderer<VideoConferencingDetails>(
    ({ config, updateConfig }) => {
      const selectItems = useSelectItems(
        (i) => [
          i.option({
            children: "Full details",
            value: "details",
          }),
          i.option({
            children: "Link",
            value: "link",
          }),
        ],
        []
      );

      return {
        body: (
          <div>
            <p className="display-as">Display as</p>
            <Select.Root
              value={config.display}
              setValue={(newValue) =>
                updateConfig({ display: newValue as typeof config.display })
              }
            >
              <Select.Trigger size="small" className="bg-light-gray-500" />
              <Select.Content portal items={selectItems} />
            </Select.Root>
          </div>
        ),
      };
    }
  );

export function videoConferencingDetails() {
  const spec = createVariableSpec<VideoConferencingDetails>({
    renderVariable: renderVideoConferencingDetails,
    renderConfigUI: renderVideoConferencingDetailsConfigUI,
    defaultConfig: {
      display: "details",
    },
  });
  return spec;
}
