/* eslint-disable import/prefer-default-export */
import "./node-renderer.sass";

import { createComponentUtils } from "../../../../__utils/atlas";
import { createNodeRenderer } from "../../../renderer";
import { LinkCardsCollection } from "./LinkCardsCollection";
import type { SerializedLinkCardsCollectionNode } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-LinkCard_collection-node-renderer";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// node renderer
// -------------

export const renderLinkCardsCollectionNode =
  createNodeRenderer<SerializedLinkCardsCollectionNode>(({ data }) => (
    <LinkCardsCollection data={data} className={ROOT} />
  ));
