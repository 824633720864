import { useRef, useState } from "react";

import { useCalendarContext } from "../../context";
import { CalendarEvent } from "../../types";
import { AllDayEvent } from "./AllDayEvent";

export function AllDayEventGroup({
  eventGroup,
}: {
  eventGroup: CalendarEvent[];
}) {
  return (
    <>
      {eventGroup.map((e, idx) => {
        return (
          <AllDayEventGroupItem
            event={e}
            idx={idx}
            groupLength={eventGroup.length}
            key={`${e.id}-${e.startTime.toISO()}`}
          />
        );
      })}
    </>
  );
}

function AllDayEventGroupItem({
  event,
  groupLength,
  idx,
}: {
  event: CalendarEvent;
  groupLength: number;
  idx: number;
}) {
  const { focusedEventId } = useCalendarContext();
  const [isHovering, setIsHovering] = useState(false);
  const isFocused = event.id === focusedEventId;
  const hoverTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    hoverTimeoutId.current = setTimeout(() => {
      setIsHovering(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutId.current) {
      clearTimeout(hoverTimeoutId.current);
    }
    setIsHovering(false);
  };

  const expanded = isFocused || isHovering;

  return (
    <AllDayEvent
      key={event.id}
      event={event}
      wrapperProps={{
        style: {
          width: expanded ? "75%" : `${100 / groupLength}%`,
          left: expanded && idx !== 0 ? "25%" : `${(idx * 100) / groupLength}%`,
          ...(expanded && {
            zIndex: 50,
          }),
        },
      }}
      eventProps={{
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }}
    />
  );
}
