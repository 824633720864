import { InterviewerResponse } from "generated/graphql-codegen/graphql";

const RESERVED_CALENDAR_COLORS = [
  "primary",
  "conflict",
  "temporary",
  "cancelled",
] as const;

const INTERVIEWER_CALENDAR_COLORS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
] as const;

const CALENDAR_COLORS = [
  ...RESERVED_CALENDAR_COLORS,
  ...INTERVIEWER_CALENDAR_COLORS,
];

export type ReservedCalendarColors = typeof RESERVED_CALENDAR_COLORS;

export type InterviewerCalendarColors = typeof INTERVIEWER_CALENDAR_COLORS;

export type CalendarColors = typeof CALENDAR_COLORS;

export type CalendarColor = CalendarColors[number];

export type CalendarVariant = "active" | "faded";

const calendarColorsMap: Record<
  CalendarColor,
  Record<CalendarVariant, { background: string; text: string }>
> = {
  primary: {
    active: {
      background: "#5046E6",
      text: "white",
    },
    faded: {
      background: "#D3D1F9",
      text: "#6d44f2",
    },
  },
  conflict: {
    active: {
      background: "#5046E6",
      text: "white",
    },
    faded: {
      background: "#D3D1F9",
      text: "white",
    },
  },
  cancelled: {
    active: {
      background: "#F33E22",
      text: "white",
    },
    faded: {
      background: "#ff6d89",
      text: "white",
    },
  },
  temporary: {
    active: {
      background: "#CAD0D4",
      text: "#626E76",
    },
    // same as active, don't actually support faded for temporary
    faded: {
      background: "#CAD0D4",
      text: "#626E76",
    },
  },
  1: {
    active: {
      background: "#6CB592",
      text: "white",
    },
    faded: {
      background: "#D3E9DE",
      text: "#416C58",
    },
  },
  2: {
    active: {
      background: "#DE96FF",
      text: "white",
    },
    faded: {
      background: "#F5DFFF",
      text: "#594762",
    },
  },
  3: {
    active: {
      background: "#7692FF",
      text: "white",
    },
    faded: {
      background: "#D6DEFF",
      text: "#2F3A66",
    },
  },
  4: {
    active: {
      background: "#5BD9E1",
      text: "white",
    },
    faded: {
      background: "#CEF4F6",
      text: "#24575A",
    },
  },
  5: {
    active: {
      background: "#FFCC1D",
      text: "white",
    },
    faded: {
      background: "#FFF0BB",
      text: "#66520B",
    },
  },
  6: {
    active: {
      background: "#FC7A57",
      text: "white",
    },
    faded: {
      background: "#FED7CD",
      text: "#653123",
    },
  },
  7: {
    active: {
      background: "#E35CA5",
      text: "white",
    },
    faded: {
      background: "#F7CEE4",
      text: "#5B2542",
    },
  },
  8: {
    active: {
      background: "#C59349",
      text: "white",
    },
    faded: {
      background: "#EDDFC8",
      text: "#4F3B1D",
    },
  },
};

export const DEFAULT_CALENDAR_VARIANT: CalendarVariant = "active";

export type ColorConfig = {
  color: CalendarColor;
  variant?: CalendarVariant;
  border?: string;
};

export const getCalendarColor = (idx = 0) => {
  const index =
    idx < INTERVIEWER_CALENDAR_COLORS.length
      ? idx
      : Math.floor(Math.random() * INTERVIEWER_CALENDAR_COLORS.length);

  return INTERVIEWER_CALENDAR_COLORS[index];
};

export const getColorsFromConfig = (config: ColorConfig) => {
  const { color, variant = DEFAULT_CALENDAR_VARIANT } = config;

  return calendarColorsMap[color][variant];
};

export const getBackgroundColorFromConfig = (config: ColorConfig) => {
  return getColorsFromConfig(config).background;
};

export const getBackgroundStylingFromConfig = ({
  config,
  responseStatus,
}: {
  config: ColorConfig;
  responseStatus?: InterviewerResponse;
}) => {
  if (responseStatus === "NEEDS_ACTION") {
    return {
      border: `1px solid ${
        config.border ??
        getBackgroundColorFromConfig({
          ...config,
          variant: "active",
        })
      }`,
      backgroundColor: "white",
    };
  }

  return {
    border: `1px solid ${config.border ?? "white"}`,
    backgroundColor: getBackgroundColorFromConfig(config),
  };
};

export const getTextColorFromConfig = (
  config: ColorConfig,
  responseStatus?: InterviewerResponse
) => {
  if (responseStatus && responseStatus === "NEEDS_ACTION") {
    return getBackgroundColorFromConfig({
      ...config,
      variant: "active",
    });
  }

  return getColorsFromConfig(config).text;
};

export const getDefaultInterviewerCalendarColors = (
  calendarIds: string[],
  defaultVariant: CalendarVariant = DEFAULT_CALENDAR_VARIANT
): {
  [calendarId: string]: ColorConfig;
} => {
  return calendarIds.reduce((acc, id, idx) => {
    acc[id] = {
      color: getCalendarColor(idx),
      variant: defaultVariant,
    };

    return acc;
  }, {} as Record<string, ColorConfig>);
};
