import { Button as AriaButton } from "@ariakit/react";
import clsx from "clsx";
import { DateTime } from "luxon";

import { DayHeadersView } from "../DayHeadersView";
import { DayHeadersViewElement } from "../DayHeadersViewElement";

type DayHeaderButtonPluginProps = {
  onClick: (props: { day: DateTime }) => void;
  getButtonText: (props: { day: DateTime }) => string;
  getAvailabileTimesForDay?: (props: { day: DateTime }) => {
    id: string;
  }[];
};

export function DayHeaderButtonPlugin({
  onClick,
  getButtonText,
  getAvailabileTimesForDay,
}: DayHeaderButtonPluginProps) {
  return (
    <DayHeadersViewElement>
      <DayHeadersView
        className="h-8"
        getItem={({ day }) => {
          const availableTimes = getAvailabileTimesForDay
            ? getAvailabileTimesForDay({ day })
            : [];
          const isDisabled = availableTimes.length <= 0;

          return (
            <div
              className="flex w-full justify-center items-center"
              key={day.toISO()}
            >
              <AriaButton
                className={clsx(
                  "text-body-md",
                  isDisabled ? "text-subtle" : "text-purple-500"
                )}
                onClick={() => onClick({ day })}
                disabled={isDisabled}
              >
                {getButtonText({ day })}
              </AriaButton>
            </div>
          );
        }}
      />
    </DayHeadersViewElement>
  );
}
