import { Menu } from "@resource/atlas/menu-v2";
import { useLogEvent } from "analytics";
import { useMemo } from "react";
import { V2_HELP_CENTER_PAGE } from "utils/constants/href";
import useIntercom from "utils/useIntercom";

export function useHelpMenuItems() {
  const { callIntercom } = useIntercom();
  const logEvent = useLogEvent({ component: "HelpMenu" });

  return useMemo(
    () => [
      <Menu.Item
        key="contact-support"
        onClick={() => {
          logEvent("Help Menu Contact Support Clicked");
          callIntercom("show");
        }}
      >
        Contact support
      </Menu.Item>,
      <Menu.Item
        key="help-center"
        onClick={() => {
          logEvent("Account Menu Help Center Clicked");
          window.open(V2_HELP_CENTER_PAGE, "_blank");
        }}
      >
        Help center
      </Menu.Item>,
    ],
    [callIntercom, logEvent]
  );
}
