/* eslint-disable react/no-unused-prop-types */
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import {
  AtlasContentEditorProps,
  AtlasContentEditorSerializedState,
} from "@resource/atlas/content-editor";
import { useContentEditor } from "@resource/atlas/content-editor/use-content-editor";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import {
  Dialog,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasDescription,
  atlasEdit2,
  atlasMail,
  atlasUsers,
  atlasVideo,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { strings } from "@resource/common";
import { useTimezone } from "client/timezones/useTimezone";
import { getFormattedDateRange } from "client/utils/dates";
import clsx from "clsx";
import { PostTemplateLoader } from "components/MessageComposer/PostTemplateLoader";
import {
  PostTemplateForUseFragment,
  PostTemplateType,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import { LexicalEditor } from "lexical";
import { isEqual } from "lodash";
import {
  renderInterviewInviteContent,
  RenderInterviewInviteContentData,
  RenderInterviewInviteContentProps,
} from "notifications/emails/rendering/renderers/interview-invite-content";
import { InterviewUpdateEmailRenderingStrategy } from "notifications/emails/rendering/strategies/InterviewUpdateEmailRenderingStrategy";
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePrevious } from "react-use";
import { COLLABORATIVE_CODING_LABELS } from "shared/guide-scheduler/collaborative-coding/utils";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";
import {
  getVideoConferencingIcon,
  getVideoConferencingServiceDisplayName,
  getVideoConferencingServiceType,
} from "shared/utils/interview-services";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { FormGroup } from "../../generic/misc/FormGroup";
import { ScheduledInterviewDetailsLiteRenderer } from "../../scheduled-interview-editor/ScheduledInterviewDetailsLiteRenderer";
import { useEditInterviewTitleDialogProps } from "../__hooks__/useEditInterviewTitleDialogProps";
import { GoogleEventPreviewData } from "../utils/types";
import { CustomInstructionsEditor } from "./CustomInstructionsEditor";
import { EditInterviewTitleDialog } from "./EditInterviewTitleDialog";

export type GoogleEventPreviewProps = {
  data: GoogleEventPreviewData;
  optionalParticipants?: {
    id: string;
    name: string;
    imageUrl: string | null;
  }[];
  interviewerConfirmationTitle: AtlasContentEditorSerializedState;
  onChangeTitle: (title: AtlasContentEditorSerializedState) => void;
  interviewerCustomInstructions: AtlasContentEditorSerializedState | null;
  onChangeCustomInstructions: (
    instructions: AtlasContentEditorSerializedState
  ) => void;
  onSelectTemplate: (template: PostTemplateForUseFragment) => void;
  defaultTemplate?: PostTemplateForUseFragment | null;
  type: RenderInterviewInviteContentProps["type"];
  disableEmailPreview?: boolean;
  Badge?: JSX.Element;
};

function useValueSet({
  data: { candidate, organization, job, interview, scheduledInterview },
}: GoogleEventPreviewProps): ValueSet<ScheduledInterviewDetailsVariableSet> {
  return useMemo((): ValueSet<ScheduledInterviewDetailsVariableSet> => {
    return {
      candidateName: {
        first: candidate.firstName,
        last: candidate.lastName,
      },
      candidateEmail: candidate.email || undefined,
      candidateLinkedIn: undefined,
      candidatePhone: undefined,
      companyName: organization.name,
      jobTitle: job.internalName,
      interviewName: interview.title,
      interviewTime: {
        startTime: scheduledInterview.startTime,
        endTime: scheduledInterview.endTime,
      },
    };
  }, [
    candidate.email,
    candidate.firstName,
    candidate.lastName,
    interview.title,
    job.internalName,
    organization.name,
    scheduledInterview.endTime,
    scheduledInterview.startTime,
  ]);
}

export function GoogleEventPreview(props: GoogleEventPreviewProps) {
  const {
    interviewerCustomInstructions,
    interviewerConfirmationTitle,
    onSelectTemplate,
    onChangeCustomInstructions,
    defaultTemplate,
    onChangeTitle,
  } = props;
  const valueSet = useValueSet(props);
  const { props: editInterviewTitleDialogProps, onEdit } =
    useEditInterviewTitleDialogProps({
      interviewerConfirmationTitle,
      onChangeTitle,
      valueSet,
    });

  const previewEmailStore = useDialogStore();
  const defaultCustomInstructions = useRef(interviewerCustomInstructions);

  const {
    editor: customInstructionsEditor,
    contentEditorProps: customInstructionsContentEditorProps,
  } = useContentEditor();

  const [selectedTemplate, setSelectedTemplate] =
    useState<PostTemplateForUseFragment | null>(defaultTemplate ?? null);

  const loadTemplate = useCallback(
    (t: PostTemplateForUseFragment) => {
      onSelectTemplate(t);
      setSelectedTemplate(t);

      defaultCustomInstructions.current = t.data;
      onChangeCustomInstructions(t.data);
      onChangeTitle(t.titleData);
    },
    [onChangeCustomInstructions, onChangeTitle, onSelectTemplate]
  );

  const prevDefaultTemplate = usePrevious(defaultTemplate);

  useEffect(() => {
    if (!isEqual(defaultTemplate, prevDefaultTemplate) && defaultTemplate) {
      loadTemplate(defaultTemplate);
    }
  }, [defaultTemplate, loadTemplate, prevDefaultTemplate]);

  const onPreview = useCallback(() => {
    previewEmailStore.show();
  }, [previewEmailStore]);

  return (
    <>
      <div className="p-4 rounded-md border border-gray-border flex flex-col space-y-4">
        <FormGroup
          label="Template"
          subText="Choose a template to automatically populate the subject and custom instructions section."
        >
          <PostTemplateLoader
            onSelect={loadTemplate}
            onTemplateEditCompleted={loadTemplate}
            selectedTemplate={selectedTemplate}
            type={PostTemplateType.interviewer_interview_confirmation}
          />
        </FormGroup>
        <TitleAndTime
          {...props}
          onEdit={onEdit}
          valueSet={valueSet}
          onPreview={onPreview}
        />
        <Guests {...props} />
        <VideoConferencing {...props} />
        <InviteBody
          {...props}
          defaultCustomInstructions={defaultCustomInstructions.current}
          previewEmailStore={previewEmailStore}
          valueSet={valueSet}
          contentEditorProps={customInstructionsContentEditorProps}
          editor={customInstructionsEditor}
        />
      </div>
      <EditInterviewTitleDialog {...editInterviewTitleDialogProps} />
    </>
  );
}

function TitleAndTime(
  props: GoogleEventPreviewProps & {
    valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
    onEdit: () => void;
    onPreview: () => void;
  }
) {
  const {
    data: { scheduledInterview },
    interviewerConfirmationTitle,
    disableEmailPreview,
    Badge,
    valueSet,
    onEdit,
    onPreview,
  } = props;
  const timezone = useTimezone();

  return (
    <>
      <div className="space-y-2">
        <div className="w-full flex flex-row justify-between space-x-2 items-center">
          <span className="space-x-2 flex flex-row items-center">
            <ScheduledInterviewDetailsLiteRenderer
              content={interviewerConfirmationTitle}
              valueSet={valueSet}
              className="!text-h4"
            />
            {Badge}
          </span>
          <span className="flex flex-shrink-0 text-body-md text-purple-500">
            <Tooltip isInstant content="Edit title">
              <Button isGhost icon={atlasEdit2} size="small" onClick={onEdit} />
            </Tooltip>
            <Tooltip
              isInstant
              content={
                !disableEmailPreview
                  ? "Preview email notification"
                  : "Not sending email notification"
              }
            >
              <Button
                isGhost
                icon={atlasMail}
                size="small"
                disabled={disableEmailPreview}
                onClick={onPreview}
              />
            </Tooltip>
          </span>
        </div>
        <span className="text-body-md text-subtle">
          {
            getFormattedDateRange({
              startTime: scheduledInterview.startTime,
              endTime: scheduledInterview.endTime,
              timezone,
            }).formattedDateTime
          }
        </span>
      </div>
    </>
  );
}

type Guest = {
  id: string;
  name: string;
  imageUrl: string | null;
  isOptional?: boolean;
  isShadow?: boolean;
};

function Guests({
  data: { scheduledInterview },
  optionalParticipants,
}: GoogleEventPreviewProps) {
  const guests = useMemo(() => {
    return [
      ...scheduledInterview.interviewers.map(
        (interviewer): Guest => ({
          id: interviewer.id,
          name: interviewer.userMembership.name,
          imageUrl: interviewer.userMembership.imageUrl,
          isShadow: interviewer.isShadow,
        })
      ),
      ...(optionalParticipants || []).map(
        (participant): Guest => ({
          id: participant.id,
          name: participant.name,
          imageUrl: participant.imageUrl,
          isOptional: true,
        })
      ),
    ];
  }, [optionalParticipants, scheduledInterview.interviewers]);

  return (
    <div className="flex flex-row space-x-2 text-body-md">
      <Icon content={atlasUsers} className="text-subtle w-5 h-5" />
      <div className="space-y-3">
        <span>{strings.pluralize("guest", guests.length)}</span>
        {guests.map((guest) => (
          <div key={guest.id} className="flex flex-row space-x-2">
            <Avatar image={guest.imageUrl} name={guest.name} size="xs" />
            <span>
              {guest.name}
              {guest.isOptional && (
                <span className="text-subtle"> (optional)</span>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

function VideoConferencing({
  data: { scheduledInterview },
}: GoogleEventPreviewProps) {
  const { videoConferencing } = scheduledInterview;
  const videoConferencingType = useMemo(() => {
    if (videoConferencing?.type) {
      return videoConferencing.type;
    }

    if (videoConferencing?.url) {
      const inferredConferencingType = getVideoConferencingServiceType(
        videoConferencing.url
      );

      if (inferredConferencingType) {
        return inferredConferencingType;
      }
    }

    return null;
  }, [videoConferencing]);
  const icon = useMemo((): AtlasIconData => {
    if (videoConferencingType) {
      return (
        getVideoConferencingIcon({
          service: videoConferencingType,
        }) ?? atlasVideo
      );
    }

    return atlasVideo;
  }, [videoConferencingType]);

  if (!videoConferencingType) {
    return null;
  }

  return (
    <div className="flex flex-row space-x-2 text-body-md">
      <Icon content={icon} className="text-subtle w-5 h-5" />
      <div>
        <span className="text-subtle">
          {videoConferencing?.url ||
            `{{${getVideoConferencingServiceDisplayName(
              videoConferencingType
            )} URL will be generated}}`}
        </span>
      </div>
    </div>
  );
}

function InviteBody(
  props: GoogleEventPreviewProps & {
    previewEmailStore: DialogStore;
    valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
    editor: LexicalEditor | null;
    contentEditorProps: AtlasContentEditorProps;
    defaultCustomInstructions: AtlasContentEditorSerializedState | null;
  }
) {
  const {
    data: {
      candidate,
      application,
      scheduledInterview,
      interview,
      organization,
      job,
    },
    previewEmailStore,
    type,
    interviewerCustomInstructions,
    defaultCustomInstructions,
    onChangeCustomInstructions,
    valueSet,
    editor,
    contentEditorProps,
  } = props;
  const videoConferencingUrl = useMemo(() => {
    if (scheduledInterview.videoConferencing) {
      if (scheduledInterview.videoConferencing.url) {
        return scheduledInterview.videoConferencing.url;
      }

      if (scheduledInterview.videoConferencing.type) {
        return `{{${getVideoConferencingServiceDisplayName(
          scheduledInterview.videoConferencing.type
        )} URL will be generated}}`;
      }
    }

    return null;
  }, [scheduledInterview.videoConferencing]);
  const collaborativeCodingUrl = useMemo(() => {
    if (scheduledInterview.collaborativeCoding) {
      if (scheduledInterview.collaborativeCoding.url) {
        return scheduledInterview.collaborativeCoding.url;
      }

      if (scheduledInterview.collaborativeCoding.type) {
        return `{{${
          COLLABORATIVE_CODING_LABELS[
            scheduledInterview.collaborativeCoding.type
          ]
        } URL will be generated}}`;
      }
    }

    return null;
  }, [scheduledInterview.collaborativeCoding]);
  const data = useMemo((): RenderInterviewInviteContentData => {
    return {
      candidate,
      scheduledInterview: {
        ...scheduledInterview,
        candidateTitle: scheduledInterview.title,
        internalTitle: interview.title,
        interviewKit:
          scheduledInterview.interviewKit ?? "{{Interview kit will be added}}",
        interviewers: scheduledInterview.interviewers.map((interviewer) => ({
          id: interviewer.id,
          name: interviewer.userMembership.name,
          imageUrl: interviewer.userMembership.imageUrl,
          isShadow: interviewer.isShadow,
        })),
        isSelfSchedule: false,
        videoConferencing: scheduledInterview.videoConferencing
          ? {
              url: videoConferencingUrl ?? "",
              type:
                scheduledInterview.videoConferencing.type ??
                VideoConferencingServiceType.OTHER,
            }
          : null,
        collaborativeCodingUrl,
        collaborativeCodingServiceType:
          scheduledInterview.collaborativeCoding?.type,
        conferencePhone: null,
      },
      guide: {
        url: "{{ Guide URL will be added }}",
        candidateRoleName: job.displayName,
        internalRoleName: job.internalName,
      },
      hiringManager: job.hiringManager ?? undefined,
      recruiter: application.recruiter ?? undefined,
      job: {
        linkToJobDescription: job.linkToJobDescription,
        departments: job.departments ?? undefined,
        customFields: job.customFields ?? undefined,
      },
      organization,
    };
  }, [
    candidate,
    collaborativeCodingUrl,
    interview.title,
    application.recruiter,
    job.displayName,
    job.internalName,
    job.linkToJobDescription,
    job.departments,
    job.customFields,
    job.hiringManager,
    organization,
    scheduledInterview,
    videoConferencingUrl,
  ]);

  return (
    <>
      <div className="flex flex-row space-x-2 text-body-md">
        <Icon content={atlasDescription} className="text-subtle w-5 h-5" />
        <div className="flex flex-col space-y-2 w-full">
          <span className="space-y-1 w-full">
            <span className="text-body-md-heavy">Custom instructions</span>
          </span>
          <CustomInstructionsEditor
            editor={editor}
            contentEditorProps={contentEditorProps}
            valueSet={valueSet}
            defaultCustomInstructions={defaultCustomInstructions}
            customInstructions={interviewerCustomInstructions}
            onChange={onChangeCustomInstructions}
          />
          <span>
            {
              renderInterviewInviteContent({
                data,
                recipient: "interviewer",
                variant: "calendar",
                type,
                timezone: null,
              }).html
            }
          </span>
        </div>
      </div>
      {/** TODO: Render custom instructions in preview, see ENG-1161 */}
      <PreviewEmailDialog store={previewEmailStore} data={data} type={type} />
    </>
  );
}

function PreviewEmailDialog({
  data,
  store,
  type,
}: {
  data: RenderInterviewInviteContentData;
  store: DialogStore;
  type: RenderInterviewInviteContentProps["type"];
}) {
  const [renderedHtml, setRenderedHtml] = useState<string>("");

  useEffect(() => {
    const render = async () => {
      const html = await new InterviewUpdateEmailRenderingStrategy({
        data,
        recipient: "interviewer",
        timezone: getCurrentIanaTimezone(),
        to: "example@test.com",
        type: type ?? "confirmation",
      }).renderFullHTML();

      setRenderedHtml(html);
    };

    render();
  }, [data, type]);

  return (
    <Dialog store={store} size="medium">
      <View
        header={{
          title: "Preview email notification",
        }}
        content={{
          className: "text-body-md h-[min(90vh,_50rem)]",
        }}
      >
        <Iframe className="w-full h-full" html={renderedHtml} />
      </View>
    </Dialog>
  );
}

type iFrameProps = {
  html: string;
} & ComponentPropsWithoutRef<"iframe">;

function Iframe({ html, ...props }: iFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (!iframeRef.current?.contentDocument) {
      return;
    }

    const iframeDoc = iframeRef.current.contentDocument;
    iframeDoc.open();
    iframeDoc.write(html);
    iframeDoc.close();
  }, [iframeRef, html]);

  return (
    <iframe
      {...props}
      className={clsx(props.className, "overflow-y-hidden")}
      ref={iframeRef}
      title="iframe"
    />
  );
}
