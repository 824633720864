/* eslint-disable import/prefer-default-export */
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

import { createModule } from "../../../modules";

// import { createModule } from "../../../modules";

export const historyModule = createModule("history", {
  plugins: [<HistoryPlugin />],
});
