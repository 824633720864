import { SerializedRichBlockNode } from "../../__utils/rich-blocks";

// config
// ------

export const RICH_BLOCK_NAME = "video";

export const DEFAULT_DATA: VideoData = {
  embedData: { type: "youtube", id: "" },
};

// types
// -----

export type EmbedType = "youtube" | "loom" | "vimeo";

export type EmbedData = { type: EmbedType; id: string };

export type VideoName = typeof RICH_BLOCK_NAME;

export type VideoData = { embedData: EmbedData; caption?: string };

export type SerializedVideoNode = SerializedRichBlockNode<VideoName, VideoData>;
