/**
 * This file contains hooks and utilities to fetch and store interviewer fragment data in state, along with the data needed
 * to perform the load and conflict calculations; low-level components can then call useInterviewerSlotWithOptionalCalculations()
 * with just the slot and interview, because all required data is cached and kept in sync by the parent components.
 * Calculating load accurately requires the "interviews" and "originalInterviews" due to reschedules; and conflicts require
 * interviewer calendarEvents which have been filtered for all interviews currently being edited in the panel (if relevant).
 * The parents keep these in sync so that the low-level display components do not need to know about that data.
 */

import { LazyQueryResult, useLazyQuery } from "@apollo/client";
import { FetchedInterviewerCalendarEvents } from "client/calendar-events/utils/types";
import { getConflictDataForUserMembership } from "client/components/interviewer-slots/conflicts/utils/helpers";
import { getLoadDataForUserMembership } from "client/components/interviewer-slots/load/utils/helpers";
import type { UserMembershipForChanges } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/__hooks/useUpsertScheduledInterviewFormState";
import { gql } from "generated/graphql-codegen";
import {
  FetchUserMembershipsForSchedulingDataQuery,
  FetchUserMembershipsForSchedulingDataQueryVariables,
  InterviewerPoolForSchedulingDataFragment,
  UserMembershipForSchedulingDataFragment,
} from "generated/graphql-codegen/graphql";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { isEqual, uniqBy } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { usePrevious } from "react-use";

import { isInterviewForConflicts } from "../conflicts/utils/types";
import { mapUserMembershipToTrainingProgress } from "../training/utils/mapping";
import {
  FormDataForSlotCalculations,
  Interviewer,
  InterviewerSlot,
  InterviewerSlotWithSchedulingData,
  InterviewerToFetchForSlotCalculations,
  InterviewerWithSchedulingData,
  InterviewForSlotCalculations,
  UserMembershipForForm,
  UserMembershipWithSchedulingData,
} from "../utils/types";
import { useGroupedInterviewerData } from "./useGroupedInterviewerData";

const FETCH_USER_MEMBERSHIPS_FOR_SCHEDULING_DATA_QUERY = gql(`
  query FetchUserMembershipsForSchedulingData(
    $userMembershipIds: [String!]!,
    $poolId: ID!,
    $guideId: ID!,
    $includePool: Boolean!
  )
  {
    userMemberships(
      userMembershipIds: $userMembershipIds
    ) {
      ...UserMembershipForSchedulingData
    }
    currentOrganization {
      id
      interviewerPoolById(id: $poolId) @include(if: $includePool) {
        ...InterviewerPoolForSchedulingData
      }
    }
  }
`);

type UseSyncInterviewerSlotCalculationsDataProps = {
  interviewers: InterviewerToFetchForSlotCalculations[];
  formData: FormDataForSlotCalculations;
  calendarEvents: FetchedInterviewerCalendarEvents | null;
};

type FetchedInterviewerGroup = {
  pool: InterviewerPoolForSchedulingDataFragment | null;
  userMemberships: UserMembershipForSchedulingDataFragment[];
};

type FetchedInterviewerAndPool = {
  userMembership: UserMembershipForSchedulingDataFragment;
  pool: InterviewerPoolForSchedulingDataFragment | null;
};

export type FetchedInterviewerSlotSchedulingData = {
  interviewers: FetchedInterviewerAndPool[];
  loading: boolean;
  formData: FormDataForSlotCalculations;
  calendarEvents: FetchedInterviewerCalendarEvents | null;
};

/**
 * Handle getting the correct date from the form data for load calculations.
 * We try to use the current interview start time,
 * but fall back to a special "fallback date" if it exists, so we can still calculate weekly load.
 */
export function getLoadCalculationsDateFromFormData({
  formData,
  interview,
}: {
  formData: FormDataForSlotCalculations;
  interview: InterviewForSlotCalculations | null;
}) {
  const date =
    interview?.startTime ??
    formData?.fallbackLoadIntervalDateForWeekLoad ??
    null;
  const includeDayLoad = !!formData?.selectedInterview?.startTime;

  return {
    date,
    includeDayLoad,
  };
}

/** Raw atom with data for the interviewer groups */
const fetchedInterviewerGroupsAtom = atom<FetchedInterviewerGroup[]>([]);

/**
 * Handle updating the cache when new data is fetched
 */
function useOnFetchedInterviewerGroups() {
  const setFetchedInterviewerGroups = useSetAtom(fetchedInterviewerGroupsAtom);

  return useCallback(
    (
      queryResults: LazyQueryResult<
        FetchUserMembershipsForSchedulingDataQuery,
        FetchUserMembershipsForSchedulingDataQueryVariables
      >[]
    ) => {
      setFetchedInterviewerGroups((prev) => {
        // Merge the currently fetched group results with the previous results, using [...prev] to initialize the accumulator.
        const updatedGroups = queryResults.reduce(
          (acc, { data }) => {
            if (!data) return acc;

            const newGroup: FetchedInterviewerGroup = {
              pool: data.currentOrganization?.interviewerPoolById ?? null,
              userMemberships: data.userMemberships,
            };

            const existingGroupIndex = acc.findIndex(
              (group) => group.pool?.id === newGroup.pool?.id
            );

            if (existingGroupIndex !== -1) {
              // If the group exists, merge the new user memberships with existing ones
              acc[existingGroupIndex] = {
                ...acc[existingGroupIndex],
                userMemberships: uniqBy(
                  [
                    ...acc[existingGroupIndex].userMemberships,
                    ...newGroup.userMemberships,
                  ],
                  "id"
                ),
              };
            } else {
              // If the group doesn't exist, add it to the array
              acc.push(newGroup);
            }

            return acc;
          },
          [...prev]
        );

        return updatedGroups;
      });
    },
    [setFetchedInterviewerGroups]
  );
}

/** Full object with all data necessary for slot calculations */
const fetchedInterviewerSlotCalculationsDataAtom =
  atom<FetchedInterviewerSlotSchedulingData>({
    interviewers: [],
    loading: true,
    formData: {
      guideId: "",
      selectedInterview: null,
      interviews: [],
      originalInterviews: [],
    },
    calendarEvents: null,
  });

/** Sync the cached interviewer groups and query state with other params into the full atom object */
function useSyncInterviewerSlotCalculationsData({
  loading,
  formData,
  calendarEvents,
}: {
  loading: boolean;
  formData: UseSyncInterviewerSlotCalculationsDataProps["formData"];
  calendarEvents: UseSyncInterviewerSlotCalculationsDataProps["calendarEvents"];
}) {
  const fetchedInterviewerGroups = useAtomValue(fetchedInterviewerGroupsAtom);
  const setInterviewerSlotCalculationsFetchedData = useSetAtom(
    fetchedInterviewerSlotCalculationsDataAtom
  );

  useEffect(() => {
    setInterviewerSlotCalculationsFetchedData({
      interviewers: fetchedInterviewerGroups.flatMap((group) => {
        return group.userMemberships.map((userMembership) => {
          return {
            userMembership,
            pool: group.pool,
          };
        });
      }),
      loading,
      formData,
      calendarEvents,
    });
  }, [
    fetchedInterviewerGroups,
    loading,
    setInterviewerSlotCalculationsFetchedData,
    formData,
    calendarEvents,
  ]);
}

/**
 * Sync the fetched interviewer data which is needed for calculations. Fetches the interviewers
 * and saves the formData and calendarEvents in state for later calculations.
 */
export function useSyncFetchedDataForSlotCalculations({
  interviewers,
  formData,
  calendarEvents,
}: UseSyncInterviewerSlotCalculationsDataProps) {
  const onFetchedInterviewerGroups = useOnFetchedInterviewerGroups();

  const [fetchUserMemberships, { loading }] = useLazyQuery(
    FETCH_USER_MEMBERSHIPS_FOR_SCHEDULING_DATA_QUERY
  );

  const groupedInterviewerData = useGroupedInterviewerData({ interviewers });
  const prevGroupedInterviewerData = usePrevious(groupedInterviewerData);

  const fetchGroupedInterviewerData = useCallback(async () => {
    try {
      const results = await Promise.all(
        groupedInterviewerData.map((group) =>
          fetchUserMemberships({
            variables: {
              guideId: formData.guideId,
              userMembershipIds: group.userMembershipIds,
              poolId: group.poolId ?? "",
              includePool: !!group.poolId,
            },
          })
        )
      );
      onFetchedInterviewerGroups(results);
    } catch (e) {
      console.error("Failed to fetch user memberships and pool data");
    }
  }, [
    fetchUserMemberships,
    formData.guideId,
    groupedInterviewerData,
    onFetchedInterviewerGroups,
  ]);

  useEffect(() => {
    const dataChanged = !isEqual(
      prevGroupedInterviewerData,
      groupedInterviewerData
    );
    if (groupedInterviewerData.length > 0 && dataChanged) {
      fetchGroupedInterviewerData();
    }
  }, [
    fetchGroupedInterviewerData,
    groupedInterviewerData,
    prevGroupedInterviewerData,
  ]);

  useSyncInterviewerSlotCalculationsData({
    loading,
    formData,
    calendarEvents,
  });
}

export function useSyncUserMembershipChangesWithState() {
  const setFetchedInterviewerGroups = useSetAtom(fetchedInterviewerGroupsAtom);

  return useCallback(
    ({
      updatedUserMembership,
    }: {
      updatedUserMembership: UserMembershipForChanges;
    }) => {
      if (
        !updatedUserMembership ||
        !("id" in updatedUserMembership) ||
        !updatedUserMembership.id
      )
        return;

      setFetchedInterviewerGroups((prevGroups) => {
        if (!prevGroups) return prevGroups;

        return prevGroups.map((group) => ({
          ...group,
          userMemberships: group.userMemberships.map((userMembership) => {
            if (userMembership.id === updatedUserMembership.id) {
              return {
                ...userMembership,
                ...updatedUserMembership,
                user: {
                  ...userMembership.user,
                  ...updatedUserMembership.user,
                },
              };
            }
            return userMembership;
          }),
        }));
      });
    },
    [setFetchedInterviewerGroups]
  );
}

/** Get the fetched calculation data from cache */
export function useInterviewerSlotFetchedData() {
  return useAtomValue(fetchedInterviewerSlotCalculationsDataAtom);
}

type EnhanceInterviewerParams = {
  originalInterviewer: Interviewer | null;
  fetchedInterviewer: FetchedInterviewerAndPool | undefined;
  interview: InterviewForSlotCalculations;
  formData: FormDataForSlotCalculations;
  calendarEvents: FetchedInterviewerCalendarEvents | null;
  isShadower?: boolean;
};

/** Calculate and hydrate scheduling data for an interviewer's userMembership */
function enhanceInterviewerWithSchedulingData({
  originalInterviewer,
  fetchedInterviewer,
  interview,
  formData,
  calendarEvents,
  isShadower,
}: EnhanceInterviewerParams): InterviewerWithSchedulingData | null {
  if (!originalInterviewer) {
    return null;
  }

  if (!fetchedInterviewer) {
    return originalInterviewer;
  }

  const userMembership = enhanceUserMembershipWithSchedulingData({
    originalUserMembership: originalInterviewer.userMembership,
    fetchedInterviewer,
    interview,
    formData,
    calendarEvents,
    isShadower,
  });

  return {
    ...originalInterviewer,
    userMembership,
  };
}

type EnhanceUserMembershipParams = {
  originalUserMembership: UserMembershipForForm;
  fetchedInterviewer: FetchedInterviewerAndPool | undefined;
  interview: InterviewForSlotCalculations;
  formData: FormDataForSlotCalculations;
  calendarEvents: FetchedInterviewerCalendarEvents | null;
  isShadower?: boolean;
};

/** Calculate and hydrate scheduling data for a userMembership */
function enhanceUserMembershipWithSchedulingData({
  originalUserMembership,
  fetchedInterviewer,
  interview,
  formData,
  calendarEvents,
  isShadower,
}: EnhanceUserMembershipParams): UserMembershipWithSchedulingData {
  if (!fetchedInterviewer) {
    return originalUserMembership;
  }

  const conflictData =
    interview && isInterviewForConflicts(interview) && calendarEvents
      ? getConflictDataForUserMembership({
          userMembership: fetchedInterviewer.userMembership,
          selectedInterview: interview,
          calendarEvents: calendarEvents[fetchedInterviewer.userMembership.id],
        })
      : undefined;

  const { date } = getLoadCalculationsDateFromFormData({ formData, interview });
  const loadData = date
    ? getLoadDataForUserMembership({
        userMembership: fetchedInterviewer.userMembership,
        date,
        selectedInterview: interview,
        interviews: formData.interviews,
        originalInterviews: formData.originalInterviews,
        poolData: fetchedInterviewer.pool ?? undefined,
        includeSelectedInterview: true,
      })
    : undefined;
  const trainingData =
    isShadower && fetchedInterviewer.pool
      ? mapUserMembershipToTrainingProgress({
          interviewerPool: fetchedInterviewer.pool,
          userMembership: fetchedInterviewer.userMembership,
        })
      : undefined;

  return {
    ...originalUserMembership,
    loadData: loadData ?? undefined,
    conflictData: conflictData ?? undefined,
    trainingData,
  };
}

type InterviewerSlotWithSchedulingDataParams =
  FetchedInterviewerSlotSchedulingData & {
    interviewerSlot: InterviewerSlot;
    interview: InterviewForSlotCalculations;
  };

/**
 * For a specific interviewer slot, pull the cached calculated data.
 * Called by low-level display components which only have the
 * slot context vs all of the interviews.
 */
export function getInterviewerSlotWithSchedulingData({
  interviewerSlot,
  interview,
  interviewers: fetchedInterviewers,
  formData,
  calendarEvents,
}: InterviewerSlotWithSchedulingDataParams): InterviewerSlotWithSchedulingData {
  const poolId = interviewerSlot.interviewerPoolsSetting[0]?.id;

  const fetchedInterviewer = fetchedInterviewers.find(
    (i) =>
      i.userMembership.id === interviewerSlot.interviewer?.userMembership.id &&
      i.pool?.id === poolId
  );
  const fetchedShadowingInterviewer = fetchedInterviewers.find(
    (i) =>
      i.userMembership.id ===
        interviewerSlot.shadowingInterviewer?.userMembership.id &&
      i.pool?.id === poolId
  );

  const interviewer = enhanceInterviewerWithSchedulingData({
    originalInterviewer: interviewerSlot.interviewer,
    fetchedInterviewer,
    interview,
    formData,
    calendarEvents,
  });

  const shadowingInterviewer = enhanceInterviewerWithSchedulingData({
    originalInterviewer: interviewerSlot.shadowingInterviewer,
    fetchedInterviewer: fetchedShadowingInterviewer,
    interview,
    formData,
    calendarEvents,
    isShadower: true,
  });

  const userMembershipsSetting = interviewerSlot.userMembershipsSetting.map(
    (um) =>
      enhanceUserMembershipWithSchedulingData({
        originalUserMembership: um,
        fetchedInterviewer: fetchedInterviewers.find(
          (i) => i.userMembership.id === um.id
        ),
        interview,
        formData,
        calendarEvents,
        isShadower: false,
      })
  );

  const shadowingUserMembershipsSetting =
    interviewerSlot.shadowingUserMembershipsSetting.map((um) =>
      enhanceUserMembershipWithSchedulingData({
        originalUserMembership: um,
        fetchedInterviewer: fetchedInterviewers.find(
          (i) => i.userMembership.id === um.id
        ),
        interview,
        formData,
        calendarEvents,
        isShadower: true,
      })
    );

  return {
    ...interviewerSlot,
    interviewer,
    shadowingInterviewer,
    userMembershipsSetting,
    shadowingUserMembershipsSetting,
  };
}

type UseInterviewerSlotWithSchedulingDataParams = {
  interviewerSlot: InterviewerSlot;
  interview: InterviewForSlotCalculations;
};

export function useInterviewerSlotWithSchedulingData({
  interviewerSlot,
  interview,
}: UseInterviewerSlotWithSchedulingDataParams): InterviewerSlotWithSchedulingData {
  const slotCalculationsFetchedData = useInterviewerSlotFetchedData();

  return useMemo(
    () =>
      getInterviewerSlotWithSchedulingData({
        ...slotCalculationsFetchedData,
        interviewerSlot,
        interview,
      }),
    [interview, interviewerSlot, slotCalculationsFetchedData]
  );
}
