import type { SerializedRichBlockNode } from "../../__utils/rich-blocks";

// config
// ------

export const RICH_BLOCK_NAME = "file";

export const DEFAULT_DATA: FileData = {
  url: "",
  fileSize: 0,
  mimeType: "",
  name: "",
  uuid: "",
  displayAs: "card",
  displaySize: "small",
};

export type FileMetadata = {
  url: string;
  uuid: string;
  mimeType: string;
  name: string;
  fileSize: number;
};

// helpers
// -------

export const PDF_MIME_TYPES = ["application/pdf"];
export const WORD_MIME_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const EXCEL_MIME_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const POWERPOINT_MIME_TYPES = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];
export const CSV_MIME_TYPES = ["text/csv"];
export const SVG_MIME_TYPES = ["image/svg+xml"];
export const ZIP_MIME_TYPES = [
  "application/gzip",
  "application/zip",
  "application/x-7z-compressed",
];
export const IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/avif",
  "image/gif",
];

export function getLabelForMimeType(mimeType: string) {
  if (PDF_MIME_TYPES.includes(mimeType)) {
    return "PDF";
  }
  if (WORD_MIME_TYPES.includes(mimeType)) {
    return "Word Document";
  }
  if (EXCEL_MIME_TYPES.includes(mimeType)) {
    return "Excel Spreadsheet";
  }
  if (POWERPOINT_MIME_TYPES.includes(mimeType)) {
    return "PowerPoint Slideshow";
  }
  if (CSV_MIME_TYPES.includes(mimeType)) {
    return "Comma-Separated Values";
  }
  if (SVG_MIME_TYPES.includes(mimeType)) {
    return "SVG Graphic";
  }
  if (ZIP_MIME_TYPES.includes(mimeType)) {
    return "ZIP Archive";
  }

  return mimeType.split("/")[1];
}

export function humanReadableFileSize(size: number) {
  const fileSizes = ["B", "KB", "MB", "GB", "TB"];
  const log1024 = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** log1024).toFixed(1)}${fileSizes[log1024]}`;
}

export function getDefaultImageForMimeType(mimeType: string) {
  if (PDF_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/7a46f362-d1bb-43a7-af89-d065d242be48/";
  }
  if (WORD_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/f0466fac-1092-4270-bab5-659ba07dc202/";
  }
  if (EXCEL_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/1b3fb634-e2dc-4e55-bf7a-35aff3a1bd48/";
  }
  if (POWERPOINT_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/53b50027-03c2-4153-8fa1-e02f992ce310/";
  }
  if (CSV_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/87c97b4f-00ce-4126-83f3-337ad63c853c/";
  }
  if (SVG_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/07f80bb9-4e5f-4fbe-ae34-eef4220b3eea/";
  }
  if (ZIP_MIME_TYPES.includes(mimeType)) {
    return "https://ucarecdn.com/4154b26b-69dd-43b8-a6f3-355e6cf26f9d/";
  }

  return "https://ucarecdn.com/3c16e216-e43e-4f22-b880-5d4da45e1298/";
}

// types
// -----

export type FileName = typeof RICH_BLOCK_NAME;

export type FileData = FileMetadata & {
  imageUrl?: string;
  displayName?: string;
  displayAs: "card" | "link";
  displaySize: "small" | "large";
};

export type SerializedFileNode = SerializedRichBlockNode<FileName, FileData>;
