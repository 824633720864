import { filterInterviewRequirements } from "client/utils/interviewRequirements";
import {
  AlgorithmRunForAiScenariosSectionFragment,
  InterviewPanelRequirementForConfigurationFragment,
  PanelAlgorithmSettings,
} from "generated/graphql-codegen/graphql";
import { adjustOnlyExistingAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/simplify-scheduling-input";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";

import { InterviewPanelRequirement } from "./types";

export function mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
  algorithmSettingsFragment,
  interviewCount,
}: {
  algorithmSettingsFragment:
    | AlgorithmRunForAiScenariosSectionFragment["settings"]
    | PanelAlgorithmSettings;
  interviewCount: number;
}): UserConfigurableAlgorithmSettings {
  const algorithmSettings = {
    numberOfDays: algorithmSettingsFragment?.numberOfDays
      ? {
          min: algorithmSettingsFragment.numberOfDays.min,
          max: algorithmSettingsFragment.numberOfDays.max,
        }
      : undefined,
    interviewsPerDayLimit: algorithmSettingsFragment?.interviewsPerDayLimit
      ? {
          min: algorithmSettingsFragment.interviewsPerDayLimit.min,
          max: algorithmSettingsFragment.interviewsPerDayLimit.max,
        }
      : undefined,
  };

  return adjustOnlyExistingAlgorithmSettings({
    settings: algorithmSettings,
    interviewCount,
  });
}

export function mapInterviewPanelRequirementFragmentToInterviewPanelRequirement(
  interviewPanelRequirementFragment: InterviewPanelRequirementForConfigurationFragment
): InterviewPanelRequirement {
  const interviewRequirements =
    interviewPanelRequirementFragment.interviewRequirements.map(
      (interviewRequirement) => ({
        id: interviewRequirement.id,
        manuallyRemovedAt: interviewRequirement.manuallyRemovedAt,
        videoConferencingSettings:
          interviewRequirement.videoConferencingSettings
            ? {
                service: interviewRequirement.videoConferencingSettings.service,
              }
            : null,
        conferenceRoomSettings: interviewRequirement.conferenceRoomSettings,
        isPrivate: interviewRequirement.isPrivate,
      })
    );

  const { interviewRequirements: filteredRequirements } =
    filterInterviewRequirements({ interviewRequirements });
  const algorithmSettings =
    mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
      algorithmSettingsFragment:
        interviewPanelRequirementFragment.algorithmSettings,
      interviewCount: filteredRequirements.length,
    });

  return {
    reuseVideoConferencingLink:
      interviewPanelRequirementFragment.reuseVideoConferencingLink,
    algorithmSettings,
    location: interviewPanelRequirementFragment.location,
    interviewRequirements,
  };
}
