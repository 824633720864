import { DateTime } from "luxon";
import { v4 } from "uuid";

import {
  RollingDateRangeConfig,
  SchedulingHoursConfig,
  SelfScheduleInterviewSettingsForCreate,
  SelfScheduleSchedulingSettingsInput,
  TimeConfig,
} from "./types";

export const MAX_DATE_RANGE_CALENDAR_DAYS = 30;
export const MAX_DATE_RANGE_WEEKDAYS = 22;

/**
 * Ensure the rolling date range is within our valid range
 */
export const validateRollingDateRange = (
  rollingDateRange: RollingDateRangeConfig
): {
  rollingDateRange: RollingDateRangeConfig;
  isValid: boolean;
  invalidMessage?: string;
} => {
  if (
    typeof rollingDateRange.days !== "number" ||
    Number.isNaN(rollingDateRange.days)
  ) {
    return {
      rollingDateRange,
      isValid: false,
      invalidMessage: "Rolling date range must be a valid number.",
    };
  }

  if (rollingDateRange.days < 1) {
    return {
      rollingDateRange: {
        ...rollingDateRange,
        days: 1,
      },
      isValid: false,
      invalidMessage: "Rolling date range must be at least 1 day.",
    };
  }

  if (
    rollingDateRange.setting === "calendar_days" &&
    rollingDateRange.days > MAX_DATE_RANGE_CALENDAR_DAYS
  ) {
    return {
      rollingDateRange: {
        ...rollingDateRange,
        days: MAX_DATE_RANGE_CALENDAR_DAYS,
      },
      isValid: false,
      invalidMessage: `Rolling date range cannot be more than ${MAX_DATE_RANGE_CALENDAR_DAYS} calendar days.`,
    };
  }

  if (
    rollingDateRange.setting === "weekdays" &&
    rollingDateRange.days > MAX_DATE_RANGE_WEEKDAYS
  ) {
    return {
      rollingDateRange: {
        ...rollingDateRange,
        days: MAX_DATE_RANGE_WEEKDAYS,
      },
      isValid: false,
      invalidMessage: `Rolling date range cannot be more than ${MAX_DATE_RANGE_WEEKDAYS} weekdays.`,
    };
  }

  return {
    rollingDateRange,
    isValid: true,
  };
};

export function getDefaultSchedulingHours(): SchedulingHoursConfig {
  return [
    {
      isWorkingDay: true,
      startTime: { hour: 9, minute: 0 },
      endTime: { hour: 18, minute: 0 },
    },
    {
      isWorkingDay: true,
      startTime: { hour: 9, minute: 0 },
      endTime: { hour: 18, minute: 0 },
    },
    {
      isWorkingDay: true,
      startTime: { hour: 9, minute: 0 },
      endTime: { hour: 18, minute: 0 },
    },
    {
      isWorkingDay: true,
      startTime: { hour: 9, minute: 0 },
      endTime: { hour: 18, minute: 0 },
    },
    {
      isWorkingDay: true,
      startTime: { hour: 9, minute: 0 },
      endTime: { hour: 18, minute: 0 },
    },
    { isWorkingDay: false },
    { isWorkingDay: false },
  ];
}

export function getDefaultSelfSchedulingSettings(): SelfScheduleSchedulingSettingsInput {
  return {
    schedulingHours: getDefaultSchedulingHours(),
    preMeetingBuffer: { unit: "minutes", value: 0 },
    postMeetingBuffer: { unit: "minutes", value: 0 },
    minimumNotice: { unit: "hours", value: 2 },
    rollingDateRange: { days: 14, setting: "calendar_days" },
  };
}

// Shouldn't be necessary or used but need something to prevent crashing
export function getDefaultSelfScheduleInterviewSettings(): SelfScheduleInterviewSettingsForCreate {
  return {
    duration: 0,
    // For some reason import getLexicalJsonFromTitle is causing cypress component tests to crash
    title: `{"root": {"type": "root", "format": "", "indent": 0, "version": 1, "children": [{"type": "paragraph", "format": "", "indent": 0, "version": 1, "children": [{"mode": "normal", "text": "Interview", "type": "text", "style": "", "detail": 0, "format": 0, "version": 1}], "direction": "ltr"}], "direction": "ltr"}}`,
    interview: null,
    locationSettings: null,
    interviewer: {
      id: v4(),
      name: "Default Interviewer",
      imageUrl: null,
      email: null,
      user: {
        id: v4(),
        timezone: null,
      },
    },
    addToCalendarId: null,
    isPrivate: false,
  };
}

export function mapTimeConfigToMinutes(config: TimeConfig) {
  switch (config.unit) {
    case "hours":
      return config.value * 60;
    case "minutes":
      return config.value;
    case "seconds":
      return config.value / 60;
    default:
      return config.value;
  }
}

/**
 * Get the last available date based on rolling date range config
 */
export const getLastAvailableDateFromRollingDateRange = ({
  rollingDateRange: passedRollingDateRange,
  timezone = "UTC",
}: {
  rollingDateRange: RollingDateRangeConfig;
  timezone?: string;
}): DateTime => {
  let lastDateAvailable = DateTime.now().setZone(timezone);
  const { rollingDateRange, isValid } = validateRollingDateRange(
    passedRollingDateRange
  );

  if (!isValid) {
    return lastDateAvailable.plus({ days: 14 }).endOf("day");
  }

  if (rollingDateRange.setting === "calendar_days") {
    return lastDateAvailable.plus({ days: rollingDateRange.days }).endOf("day");
  }

  let weekdaysCount = 0;
  while (weekdaysCount < rollingDateRange.days) {
    if (lastDateAvailable.weekday < 6) {
      weekdaysCount += 1;
    }
    lastDateAvailable = lastDateAvailable.plus({ days: 1 });
  }

  return lastDateAvailable.endOf("day");
};

/**
 * Convert the rolling date range to minutes
 * If unit is in calendar days, return the number of days in minutes
 * If unit is in weekdays, return the number of weekdays in minutes
 */
export const getNoLaterThanFromRollingDateRange = ({
  rollingDateRange,
  timezone = "UTC",
}: {
  rollingDateRange: RollingDateRangeConfig;
  timezone?: string;
}): number => {
  const now = DateTime.now().setZone(timezone);
  const lastDateAvailable = getLastAvailableDateFromRollingDateRange({
    rollingDateRange,
    timezone,
  });

  return Math.floor(lastDateAvailable.diff(now, "minutes").minutes);
};
