import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";

import { usePostMessageQuery } from "../__hooks/usePostMessageQuery";
import { PostMessageView, PostMessageViewProps } from "./PostMessageView";

export type PostMessageDialogQueryProps = Omit<
  PostMessageViewProps,
  "guide"
> & {
  guideId: string;
};

export function PostMessageDialogQuery({
  guideId,
  ...rest
}: PostMessageDialogQueryProps) {
  const { workflowType, onCompleted, viewProps } = rest;
  const { data } = usePostMessageQuery({
    guideId,
    workflowType,
  });

  if (!data) {
    return <DialogLoading {...viewProps} />;
  }

  if (data.guideById === null) {
    return (
      <DialogError {...viewProps} errorCode="404" onRecover={onCompleted} />
    );
  }

  return <PostMessageView {...rest} guide={data.guideById} />;
}
