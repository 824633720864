import Badge from "@resource/atlas/badge/Badge";
import { AtlasBadgeProps } from "@resource/atlas/badge/types";

export type ScheduledStatus =
  | "created"
  | "updated"
  | "cancelled"
  | "needs_rescheduling";

export function ScheduledInterviewCardBadge({
  status,
  hideNewBadge,
  size = "small",
}: {
  status: ScheduledStatus | null;
  hideNewBadge?: boolean;
  size?: AtlasBadgeProps["size"];
}) {
  if (status === "updated") {
    return (
      <Badge
        variant="primary"
        size={size}
        styles="bold"
        className="flex-shrink-0"
      >
        Updated
      </Badge>
    );
  }

  if (status === "created" && !hideNewBadge) {
    return (
      <Badge
        variant="success"
        size={size}
        styles="bold"
        className="flex-shrink-0"
      >
        New
      </Badge>
    );
  }

  if (status === "cancelled") {
    return (
      <Badge
        variant="error"
        size={size}
        styles="bold"
        className="flex-shrink-0"
      >
        Cancelled
      </Badge>
    );
  }

  if (status === "needs_rescheduling") {
    return (
      <Badge
        variant="yellow"
        size={size}
        styles="bold"
        className="flex-shrink-0"
      >
        Needs rescheduling
      </Badge>
    );
  }

  return null;
}
