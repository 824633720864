import { Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Fragment } from "react";

type SidePanelPushInProps = {
  className?: string;
  isOpen: boolean;
  width?: string;
  placement?: "left" | "right";
  duration?: number;
  children: React.ReactNode;
  closedState?: {
    width: string;
    children: React.ReactNode;
  };
};

export function SidePanelPushIn({
  className,
  isOpen,
  width = "576px",
  duration = 300,
  placement = "left",
  children,
  closedState,
}: SidePanelPushInProps) {
  const getWidthClass = () => {
    if (isOpen) return `w-[${width}]`;
    if (closedState) return `w-[${closedState.width}]`;
    return "w-0";
  };

  const renderContent = () => {
    return (
      <Transition.Root show as="div">
        <div className="pointer-events-none flex">
          <Transition.Child
            as={Fragment}
            enter={`transform transition ease-in-out duration-[${duration}]`}
            enterFrom={
              placement === "left" ? "-translate-x-full" : "translate-x-full"
            }
            enterTo="translate-x-0"
            leave={`transform transition ease-in-out duration-[${duration}]`}
            leaveFrom="translate-x-0"
            leaveTo={
              placement === "left" ? "-translate-x-full" : "translate-x-full"
            }
          >
            <div className={clsx("pointer-events-auto", className)}>
              {isOpen ? children : closedState?.children}
            </div>
          </Transition.Child>
        </div>
      </Transition.Root>
    );
  };

  return (
    <div
      className={clsx(
        `h-full transition-width duration-[${duration}]`,
        getWidthClass()
      )}
    >
      {renderContent()}
    </div>
  );
}
