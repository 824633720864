import {
  createNameSet,
  createRendererSet,
  createSpecSet,
} from "@resource/atlas/content-editor/variables";

import {
  guideBaseNameSet,
  guideBaseRendererSet,
  guideBaseSpecSet,
  GuideBaseVariableSet,
} from "../base/variables";

// set type
// --------

export type GuideAboutVariableSet = GuideBaseVariableSet;

// renderer set
// ------------

export const guideAboutRendererSet = createRendererSet(guideBaseRendererSet);

// name set
// --------

export const guideAboutNameSet = createNameSet(guideBaseNameSet);

// spec set
// --------

export const guideAboutSpecSet = createSpecSet(guideBaseSpecSet, {
  rendererSet: guideAboutRendererSet,
  nameSet: guideAboutNameSet,
});
