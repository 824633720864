import { ValueSet } from "@resource/atlas/content-editor/variables";
import { ScheduledInterviewForGoogleEventViewFragment } from "generated/graphql-codegen/graphql";
import { join } from "lodash";
import { RenderInterviewInviteContentData } from "notifications/emails/rendering/renderers/interview-invite-content";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";
import { getCollaborativeCodingServiceType } from "shared/utils/interview-services";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

export function mapScheduledInterviewToInterviewInviteContentData(
  scheduledInterview: ScheduledInterviewForGoogleEventViewFragment
): RenderInterviewInviteContentData {
  return {
    candidate: {
      name: scheduledInterview.guide?.candidate.name ?? "",
      email: scheduledInterview.guide?.candidate.email ?? null,
      linkedInUrl: scheduledInterview.guide?.candidate.linkedInUrl ?? null,
    },
    scheduledInterview: {
      candidateTitle: getTitleFromLexicalJson(scheduledInterview.title),
      internalTitle: scheduledInterview.interview
        ? getTitleFromLexicalJson(scheduledInterview.interview.title)
        : getTitleFromLexicalJson(scheduledInterview.title),
      startTime: scheduledInterview.startTime,
      endTime: scheduledInterview.endTime,
      interviewKit: scheduledInterview.interviewKitUrl,
      videoConferencing: scheduledInterview.videoConferencing
        ? {
            type: scheduledInterview.videoConferencing.type,
            url: scheduledInterview.videoConferencing.url,
          }
        : null,
      conferencePhone: null, // Not provided in the fragment
      collaborativeCodingUrl:
        scheduledInterview.collaborativeCoding?.url ?? null,
      collaborativeCodingServiceType: scheduledInterview.collaborativeCoding
        ? getCollaborativeCodingServiceType(
            scheduledInterview.collaborativeCoding.type
          )
        : null,
      interviewers: scheduledInterview.interviewers.map((interviewer) => ({
        name: interviewer.userMembership.name,
        imageUrl: interviewer.userMembership.imageUrl,
        isShadow: interviewer.isShadow,
      })),
      isSelfSchedule: false, // Not provided in the fragment
      scheduler: scheduledInterview.scheduler
        ? {
            name: scheduledInterview.scheduler.name,
            email: scheduledInterview.scheduler.email,
            imageUrl: scheduledInterview.scheduler.imageUrl,
          }
        : null,
    },
    organization: {
      name: scheduledInterview.guide?.organization.name ?? "",
      logoUrl: scheduledInterview.guide?.organization.companyLogoUrl ?? "",
      url: "", // Not provided in the fragment
      hideCandidateEmailInInterviewerInvites:
        scheduledInterview.guide?.organization
          .defaultHideCandidateEmailInInterviewerInvites ?? false,
    },
    recruiter: scheduledInterview.guide?.recruiter
      ? {
          name: scheduledInterview.guide.recruiter.name,
          email: scheduledInterview.guide.recruiter.email,
          imageUrl: scheduledInterview.guide.recruiter.imageUrl,
        }
      : undefined,
    guide: scheduledInterview.guide
      ? {
          url: "", // Not provided in the fragment
          candidateRoleName: scheduledInterview.guide.roleName,
          internalRoleName: scheduledInterview.guide.job.internalName,
        }
      : undefined,
    hiringManager: scheduledInterview.guide?.job.hiringManager
      ? {
          name: scheduledInterview.guide.job.hiringManager.name,
          email: scheduledInterview.guide.job.hiringManager.email,
          imageUrl: scheduledInterview.guide.job.hiringManager.imageUrl,
        }
      : undefined,
    job: {
      linkToJobDescription:
        scheduledInterview.guide?.job.linkToJobDescription ?? null,
      departments: join(
        scheduledInterview.guide?.job.departments.map((d) => d.name),
        ", "
      ),
      customFields: scheduledInterview.guide?.job.customFields,
    },
    interviewerConfirmationTitle:
      typeof scheduledInterview.interviewerConfirmationTitle !== "string"
        ? JSON.stringify(scheduledInterview.interviewerConfirmationTitle)
        : scheduledInterview.interviewerConfirmationTitle,
    customInstructions:
      typeof scheduledInterview.interviewerCustomInstructions !== "string"
        ? JSON.stringify(scheduledInterview.interviewerCustomInstructions)
        : scheduledInterview.interviewerCustomInstructions,
  };
}

export function mapScheduledInterviewToValueSet(
  scheduledInterview: ScheduledInterviewForGoogleEventViewFragment
): ValueSet<ScheduledInterviewDetailsVariableSet> {
  return {
    candidateName: {
      first: scheduledInterview.guide?.candidate.firstName ?? "",
      last: scheduledInterview.guide?.candidate.lastName ?? "",
    },
    candidateEmail: scheduledInterview.guide?.candidate.email ?? undefined,
    candidateLinkedIn:
      scheduledInterview.guide?.candidate.linkedInUrl ?? undefined,
    companyName: scheduledInterview.guide?.organization.name ?? "",
    jobTitle: scheduledInterview.guide?.job.internalName ?? "",
    interviewName: getTitleFromLexicalJson(scheduledInterview.title),
    interviewTime: {
      startTime: scheduledInterview.startTime,
      endTime: scheduledInterview.endTime,
    },
  };
}
