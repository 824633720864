import { atlasCalendarEvents } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import {
  InterviewCalendarForSelect,
  InterviewCalendarSelect,
} from "client/app/(account settings)/company/__components/InterviewCalendarSelect";
import { FormGroup } from "client/components/generic/misc/FormGroup";

type Props = {
  calendars: InterviewCalendarForSelect[];
  onChange: (interviewCalendarId: string) => void;
  selectedInterviewCalendarId: string | null;
  isLoading?: boolean;
};

export function AddToCalendarSelect({
  calendars,
  selectedInterviewCalendarId,
  onChange,
  isLoading,
}: Props) {
  return (
    <FormGroup
      label="Add to calendar"
      Action={
        <Link
          href="/account/preferences"
          target="_blank"
          className="text-body-md"
        >
          Edit default calendar
        </Link>
      }
    >
      <InterviewCalendarSelect
        calendars={calendars}
        selectedInterviewCalendarId={selectedInterviewCalendarId}
        setInterviewCalendar={onChange}
        isLoading={isLoading}
        triggerProps={{
          icon: atlasCalendarEvents,
          iconClassName: "text-subtle",
        }}
      />
    </FormGroup>
  );
}
