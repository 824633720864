import { Button } from "@resource/atlas/button/Button";
import { Link } from "@resource/atlas/link/Link";
import { InterviewRequirementCard } from "client/components/interview-requirements/InterviewRequirementCard";
import { CypressData } from "client/cypress-data-keys";
import { gql } from "generated/graphql-codegen";
import {
  InterviewRequirementForConfigurationFragment,
  InterviewRequirementForInterviewRequirementsCardFragment,
  SchedulingRequestForUnscheduledInterviewsFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";

import { AIScenariosSection } from "../ai-scenarios/AIScenariosSection";

gql(`
  fragment SchedulingRequestForUnscheduledInterviews on SchedulingRequest {
    id
    ...SchedulingRequestForAIScenariosSection
    scheduledInterviews {
      id
      interviewRequirementId
    }
    interviewPanelRequirement {
      id
      interviewRequirements {
        ...InterviewRequirementForInterviewRequirementsCard
        interview {
          ...InterviewForExcludedInterviewCard
        }
      }
    }
  }
`);

function checkIsRequirementFulfilled({
  schedulingRequest,
  requirement,
}: {
  schedulingRequest: SchedulingRequestForUnscheduledInterviewsFragment;
  requirement: InterviewRequirementForInterviewRequirementsCardFragment;
}) {
  return schedulingRequest.scheduledInterviews.some(
    (scheduledInterview) =>
      scheduledInterview.interviewRequirementId === requirement.id
  );
}

export function InterviewsRequirementsSection({
  schedulingRequest,
  onScheduleAll,
  onEditTask,
  onEditRequirement,
  interviewRequirements = [],
  hasAvailability,
}: {
  schedulingRequest: SchedulingRequestForUnscheduledInterviewsFragment;
  onScheduleAll: () => void;
  onEditTask: () => void;
  onEditRequirement: (
    requirement: InterviewRequirementForConfigurationFragment
  ) => void;
  interviewRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];
  hasAvailability: boolean;
}) {
  const hasAnyUnfulfilledRequirements = useMemo(() => {
    return interviewRequirements.some((requirement) => {
      return !checkIsRequirementFulfilled({
        schedulingRequest,
        requirement,
      });
    });
  }, [interviewRequirements, schedulingRequest]);

  return (
    <div className="space-y-2">
      <div className="w-full flex justify-between">
        <span className="text-h4">Interview requirements</span>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link className="text-body-md" as="button" onClick={onEditTask}>
          Edit
        </Link>
      </div>
      <AIScenariosSection
        hide={!hasAnyUnfulfilledRequirements}
        schedulingRequest={schedulingRequest}
      />
      <div className="space-y-2">
        {interviewRequirements.map((requirement) => (
          <InterviewRequirementItem
            key={requirement.id}
            schedulingRequest={schedulingRequest}
            requirement={requirement}
            onEditRequirement={onEditRequirement}
          />
        ))}
      </div>
      <div className="w-full flex flex-col">
        {hasAnyUnfulfilledRequirements ? (
          <Button
            variant={hasAvailability ? "primary" : "default"}
            onClick={onScheduleAll}
            data-cy={
              CypressData.schedulingTasks.unscheduledInterviews
                .scheduleInterviews
            }
          >
            Schedule interviews
          </Button>
        ) : (
          <Button
            variant="default"
            onClick={onScheduleAll}
            data-cy={
              CypressData.schedulingTasks.unscheduledInterviews
                .scheduleInterviews
            }
          >
            Open the interview scheduler
          </Button>
        )}
      </div>
    </div>
  );
}

function InterviewRequirementItem({
  schedulingRequest,
  requirement,
  onEditRequirement,
}: {
  schedulingRequest: SchedulingRequestForUnscheduledInterviewsFragment;
  requirement: InterviewRequirementForInterviewRequirementsCardFragment;
  onEditRequirement: (
    requirement: InterviewRequirementForConfigurationFragment
  ) => void;
}) {
  const isRequirementFulfilled = useMemo(() => {
    return checkIsRequirementFulfilled({
      schedulingRequest,
      requirement,
    });
  }, [schedulingRequest, requirement]);

  const onEdit = useCallback(() => {
    onEditRequirement(requirement);
  }, [onEditRequirement, requirement]);

  return (
    <InterviewRequirementCard
      interviewRequirement={requirement}
      showFulfillment={{
        isFulfilled: isRequirementFulfilled,
      }}
      showActionMenu
      onEdit={onEdit}
      className="cursor-default"
    />
  );
}
