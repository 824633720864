import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { BuildingSelect } from "client/components/generic/select/BuildingSelect";
import { ConferenceRoomSettings } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

type SchedulingRequestConferenceRoomSettingsProps = {
  requireConferenceRoom: ConferenceRoomSettings | null;
  setRequireConferenceRoom: (value: ConferenceRoomSettings | null) => unknown;
};

export function SchedulingRequestConferenceRoomSettings({
  requireConferenceRoom,
  setRequireConferenceRoom,
}: SchedulingRequestConferenceRoomSettingsProps) {
  const selectedBuilding = useMemo(
    () =>
      requireConferenceRoom?.building
        ? {
            id: requireConferenceRoom.building.id,
            name: requireConferenceRoom.building.name,
          }
        : undefined,
    [requireConferenceRoom]
  );

  return (
    <>
      <div className="flex space-x-2">
        <Checkbox
          checked={!!requireConferenceRoom?.required}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setRequireConferenceRoom({
                required: true,
                building: null,
              });
            } else {
              setRequireConferenceRoom(null);
            }
          }}
        />
        <span className="text-body-md">
          Require conference room for all interviews
        </span>
      </div>
      {requireConferenceRoom?.required && (
        <div className="ml-[1.75rem] space-y-1">
          <span className="text-body-md-heavy">Optional</span>
          <BuildingSelect
            selected={selectedBuilding}
            onSelect={(building) => {
              setRequireConferenceRoom({
                required: true,
                building: {
                  id: building.id,
                  name: building.name,
                },
              });
            }}
          />
        </div>
      )}
    </>
  );
}
