import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { PostTemplateForUseFragment } from "generated/graphql-codegen/graphql";
import useQuery from "utils/useQuery";

import { EditTemplateForm } from "./EditTemplateForm";

type EditTemplateDialogProps = DialogProps & {
  postTemplateId: string;
  onUpdate?: (postTemplate: PostTemplateForUseFragment) => void;
};

const GET_TEMPLATE_FOR_EDIT_DIALOG = gql(`
  query GetTemplateForEditDialog($id: ID!) {
    postTemplateById(id: $id) {
      organization {
        id
        name
        defaultAvatarImageUrl
        companyLogoUrl
        enableICSFiles
      }
      ...PostTemplateForUse
    }
  }
`);

export function EditTemplateDialog({
  store,
  postTemplateId,
  onUpdate,
  ...dialogProps
}: EditTemplateDialogProps) {
  return (
    <Dialog
      {...dialogProps}
      store={store}
      size="medium"
      hideOnInteractOutside={false}
    >
      <EditTemplateDialogQuery
        postTemplateId={postTemplateId}
        onUpdate={onUpdate}
        store={store}
      />
    </Dialog>
  );
}

type EditTemplateDialogQueryProps = Pick<
  EditTemplateDialogProps,
  "postTemplateId" | "onUpdate" | "store"
>;

function EditTemplateDialogQuery({
  postTemplateId,
  onUpdate,
  store,
}: EditTemplateDialogQueryProps) {
  const { data, loading, error } = useQuery(GET_TEMPLATE_FOR_EDIT_DIALOG, {
    variables: { id: postTemplateId },
  });

  const postTemplate = data?.postTemplateById;

  if (loading) {
    return <DialogLoading />;
  }

  if (!postTemplate) {
    return (
      <DialogError
        error={error}
        errorCode={postTemplate === null ? "404" : "500"}
      />
    );
  }

  return (
    <EditTemplateForm
      onUpdate={(t) => {
        onUpdate?.(t);
        store.hide();
      }}
      postTemplate={postTemplate}
      organization={postTemplate.organization}
    />
  );
}
