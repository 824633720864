/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import { useModuleConfig } from "../../__utils/modules";
import type { ValueSet, VariableSpecSet } from "./types";

declare module "@resource/atlas" {
  interface ContentEditorModuleConfig {
    variables?: {
      specSet: VariableSpecSet<any>;
      valueSet?: ValueSet;
      /** @default "placeholder" */
      undefinedMode?: "placeholder" | "error";
      timezone?: string;
    };
  }
}

export function useVariablesConfig() {
  return useModuleConfig("variables", { required: true });
}
