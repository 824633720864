import { useAuthContext } from "auth/context";
import { mapOrganizationFragmentToOrganization } from "client/scheduler/utils/mapping";
import { gql } from "generated/graphql-codegen";
import useQuery from "utils/useQuery";

const FETCH_BLOCKED_DATES = gql(`
  query BlockedDates($organizationId: String!) {
    organizationById(organizationId: $organizationId) {
      ...OrganizationForSchedulerV3
    }
  }  
`);

/** Fetch blocked dates for the guide */
export function useSchedulerOrganization() {
  const { user } = useAuthContext();
  const { data } = useQuery(FETCH_BLOCKED_DATES, {
    variables: {
      organizationId: user?.currentOrganization?.id ?? "",
    },
    fetchPolicy: "cache-only",
    skip: !user?.currentOrganization?.id,
  });

  if (!data?.organizationById) {
    throw new Error("OrganizationForSchedulerV3 should be cached.");
  }

  return mapOrganizationFragmentToOrganization(data.organizationById);
}
