import { usePopoverStore } from "@ariakit/react";
import { Button } from "@resource/atlas/button-v2";
import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { atlasCopy } from "@resource/atlas/icons";
import { Popover } from "@resource/atlas/popover-v2";
import { EditableTime } from "client/components/display/times/EditableTime";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";

import { WorkingHoursConfig } from "./EditPreferences/types";

type DayProps = {
  day: string;
  selected?: boolean;
  onSelect?: () => void;
};

function Day({ day, selected, onSelect }: DayProps) {
  return (
    <Button
      onClick={onSelect}
      className={clsx(
        "rounded-full text-body-sm-heavy w-7 h-7 flex items-center justify-center",
        selected ? "bg-purple-500 text-white" : "bg-light-gray-200 text-subtle"
      )}
    >
      {day}
    </Button>
  );
}

const DAY_LABELS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export type HourErrors = {
  startTime?: { message: string };
  endTime?: { message: string };
};

type WorkingHoursProps = {
  value: WorkingHoursConfig;
  onChange: (value: WorkingHoursConfig) => void;
  label?: string;
  onInvalidTimeRange?: (id: string) => void;
  onValidTimeRange?: (id: string) => void;
  className?: string;
  subtext?: string;
};

function CopyTimeToDaysPopover({
  dayIndex,
  onCopyTimeToAll,
  onClose,
  config,
}: {
  dayIndex: number;
  onCopyTimeToAll: (dayIndex: number, daysToCopy: number[]) => void;
  onClose: () => void;
  config: WorkingHoursConfig;
}) {
  const [selectedDays, setSelectedDays] = useState<Record<number, boolean>>(
    DAY_LABELS.reduce((acc, _, i) => {
      acc[i] = i !== dayIndex && config[i].isWorkingDay;
      return acc;
    }, {} as Record<number, boolean>)
  );

  useEffect(() => {
    setSelectedDays(
      DAY_LABELS.reduce((acc, _, i) => {
        acc[i] = i !== dayIndex && config[i].isWorkingDay;
        return acc;
      }, {} as Record<number, boolean>)
    );
  }, [config, dayIndex]);

  const handleDayToggle = useCallback((day: number) => {
    setSelectedDays((prev) => ({
      ...prev,
      [day]: !prev[day],
    }));
  }, []);

  const handleApply = useCallback(() => {
    const daysToCopy = Object.entries(selectedDays)
      .filter(([_, isSelected]) => isSelected)
      .map(([day]) => parseInt(day, 10));

    onCopyTimeToAll(dayIndex, daysToCopy);
    onClose();
  }, [dayIndex, onCopyTimeToAll, onClose, selectedDays]);

  return (
    <div className="p-4 flex flex-col gap-2">
      <div className="text-body-sm font-medium mb-2">COPY TIMES TO...</div>
      {DAY_LABELS.map((day, i) => (
        <div key={day} className="flex items-center">
          <Checkbox
            id={`copy-day-${i}`}
            checked={selectedDays[i]}
            onChange={() => handleDayToggle(i)}
            disabled={i === dayIndex}
          />
          <label htmlFor={`copy-day-${i}`} className="ml-2 text-body-sm">
            {day}
          </label>
        </div>
      ))}
      <Button onClick={handleApply} className="mt-4 w-full" variant="primary">
        Apply
      </Button>
    </div>
  );
}

// New component for each day's time configuration
type DayTimeConfigProps = {
  dayConfig: WorkingHoursConfig[0];
  index: number;
  dayLabel: string;
  config: WorkingHoursConfig;
  setConfig: (config: WorkingHoursConfig) => void;
  onInvalidTimeRange?: (id: string) => void;
  onValidTimeRange?: (id: string) => void;
  onCopyTimeToAll?: (dayIndex: number, daysToCopy: number[]) => void;
};

function DayTimeConfig({
  dayConfig,
  index,
  dayLabel,
  config,
  setConfig,
  onInvalidTimeRange,
  onValidTimeRange,
  onCopyTimeToAll,
}: DayTimeConfigProps) {
  const popoverState = usePopoverStore();

  return (
    <div className="flex items-center gap-2">
      <div className="flex-grow shrink">
        <EditableTime
          label={dayLabel}
          time={{
            id: index.toString(),
            startTime: dayConfig.startTime || { hour: 9, minute: 0 },
            endTime: dayConfig.endTime || { hour: 17, minute: 0 },
          }}
          onEdit={(time) => {
            setConfig(
              config.map((internalDayConfig, i) =>
                index === i
                  ? {
                      ...internalDayConfig,
                      startTime: time.startTime,
                      endTime: time.endTime,
                    }
                  : internalDayConfig
              )
            );
          }}
          onInvalidTimeRange={onInvalidTimeRange}
          onValidTimeRange={onValidTimeRange}
        />
      </div>
      {onCopyTimeToAll && (
        <Popover.Root store={popoverState}>
          <Popover.Trigger>
            <Button icon={atlasCopy} isGhost negativeMargin="right" />
          </Popover.Trigger>
          <Popover.Content>
            <CopyTimeToDaysPopover
              dayIndex={index}
              onCopyTimeToAll={onCopyTimeToAll}
              onClose={popoverState.hide}
              config={config}
            />
          </Popover.Content>
        </Popover.Root>
      )}
    </div>
  );
}

export function WorkingHours({
  value: config,
  onChange: setConfig,
  label,
  onInvalidTimeRange,
  onValidTimeRange,
  subtext,
  className,
}: WorkingHoursProps) {
  const enableDays = useMemo(() => {
    return config.map((_, index) => {
      return () =>
        setConfig(
          config.map((dayConfig, i) =>
            index === i
              ? {
                  isWorkingDay: !dayConfig.isWorkingDay,
                  startTime: dayConfig.startTime ?? { hour: 9, minute: 0 },
                  endTime: dayConfig.endTime ?? { hour: 17, minute: 0 },
                }
              : dayConfig
          )
        );
    });
  }, [config, setConfig]);
  const handleCopyTimeToAll = useCallback(
    (dayIndex: number, daysToCopy: number[]) => {
      const sourceDayConfig = config[dayIndex];
      if (!sourceDayConfig.startTime || !sourceDayConfig.endTime) return;

      const updatedConfig = config.map((dayConfig, i) => {
        if (daysToCopy.includes(i)) {
          return {
            ...dayConfig,
            isWorkingDay: true,
            startTime: sourceDayConfig.startTime,
            endTime: sourceDayConfig.endTime,
          };
        }
        return dayConfig;
      });

      setConfig(updatedConfig);
    },
    [config, setConfig]
  );

  return (
    <FormGroup className={className} label={label ?? "Working hours"}>
      <div className="space-y-4">
        {subtext && <p className="text-body-sm text-dark">{subtext}</p>}
        <div className="flex gap-1">
          {config.map((dayConfig, index) => {
            return (
              <Day
                key={`days_${DAY_LABELS[index]}`}
                day={DAY_LABELS[index].substring(0, 1)}
                selected={dayConfig.isWorkingDay}
                onSelect={enableDays[index]}
              />
            );
          })}
        </div>
        <div className="space-y-2">
          {config.map((dayConfig, index) => {
            if (
              !dayConfig.isWorkingDay ||
              !dayConfig.startTime ||
              !dayConfig.endTime
            )
              return null;

            return (
              <DayTimeConfig
                key={`times_${DAY_LABELS[index]}`}
                dayConfig={dayConfig}
                index={index}
                dayLabel={DAY_LABELS[index]}
                config={config}
                setConfig={setConfig}
                onInvalidTimeRange={onInvalidTimeRange}
                onValidTimeRange={onValidTimeRange}
                onCopyTimeToAll={handleCopyTimeToAll}
              />
            );
          })}
        </div>
      </div>
    </FormGroup>
  );
}
