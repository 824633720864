import { CreateOrUpdateAvailabilityData } from "client/guide-availability/components/CreateOrUpdateAvailabilityView";
import { useSyncDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { useInterviewRequirementsConfigurationContext } from "client/scheduling-requirements-configuration/context";
import { useAlgorithmSettingsErrors } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/hooks";
import {
  filterInterviewerRequirements,
  useFilteredInterviewRequirements,
} from "client/utils/interviewRequirements";
import { ReschedulableInterviewFragment } from "generated/graphql-codegen/graphql";
import { isEqual, partition } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { usePrevious } from "react-use";
import { formatEntity } from "shared/constants/entities";
import { CancelInterviewSettings } from "shared/scheduling-requests/types";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

import { useAllowCancelInterviewsDuringReschedule } from "./cancel-interviews-during-reschedule";

export type RescheduleableInterview = ReschedulableInterviewFragment & {
  interviewRequirementId: string;
};

export type SchedulingRequestFormType =
  | "scheduling"
  | "rescheduling"
  | "select-task";

export type UseSchedulingRequestFormStateProps = {
  reschedulableInterviews: RescheduleableInterview[];
  defaultSelectedSchedulingRequestId?: string;
  defaultSelectedRescheduleIds?: string[];
  defaultCancelInterviewSettings?: CancelInterviewSettings;
  formType: SchedulingRequestFormType;
};

export function useSchedulingRequestFormState({
  reschedulableInterviews,
  defaultSelectedSchedulingRequestId,
  defaultSelectedRescheduleIds,
  defaultCancelInterviewSettings: passedDefaultCancelInterviewSettings,
  formType,
}: UseSchedulingRequestFormStateProps) {
  const [manualAvailabilityState, setManualAvailabilityState] =
    useState<CreateOrUpdateAvailabilityData>({
      notes: "",
      selections: [],
    });
  const [selectedSchedulingRequestId, setSelectedSchedulingRequestId] =
    useState<string | null>(defaultSelectedSchedulingRequestId ?? null);
  const reschedulableInterviewRequirementIds = useMemo(() => {
    return reschedulableInterviews.map((i) => i.interviewRequirementId);
  }, [reschedulableInterviews]);

  const [alwaysShownReschedulableInterviews, moreReschedulableInterviews] =
    useMemo(
      () =>
        partition(reschedulableInterviews, (interview) => {
          return DateTime.fromISO(interview.endTime).diffNow().as("hours") > -8;
        }),
      [reschedulableInterviews]
    );

  const defaultSelectedRescheduleInterviewRequirementIds = useMemo(() => {
    return (
      defaultSelectedRescheduleIds?.filter(
        (id) =>
          reschedulableInterviewRequirementIds.includes(id) &&
          alwaysShownReschedulableInterviews
            .map((i) => i.interviewRequirementId)
            .includes(id)
      ) ??
      alwaysShownReschedulableInterviews.map((i) => i.interviewRequirementId)
    );
  }, [
    alwaysShownReschedulableInterviews,
    defaultSelectedRescheduleIds,
    reschedulableInterviewRequirementIds,
  ]);
  const [
    selectedRescheduleInterviewRequirementIds,
    setSelectedRescheduleInterviewRequirementIds,
  ] = useState<string[]>(defaultSelectedRescheduleInterviewRequirementIds);

  const previousDefaults = usePrevious(
    defaultSelectedRescheduleInterviewRequirementIds
  );

  useEffect(() => {
    if (
      !isEqual(
        defaultSelectedRescheduleInterviewRequirementIds,
        previousDefaults
      )
    ) {
      setSelectedRescheduleInterviewRequirementIds(
        defaultSelectedRescheduleInterviewRequirementIds
      );
    }
  }, [
    defaultSelectedRescheduleIds,
    defaultSelectedRescheduleInterviewRequirementIds,
    previousDefaults,
    reschedulableInterviewRequirementIds,
    setSelectedRescheduleInterviewRequirementIds,
  ]);

  const toggleSelectedRescheduleInterviewRequirementId = useCallback(
    (id: string) => {
      setSelectedRescheduleInterviewRequirementIds((current) => {
        if (current.includes(id)) {
          return current.filter((i) => i !== id);
        }

        return [...current, id];
      });
    },
    []
  );

  const selectedRescheduleScheduledInterviews = useMemo(() => {
    return reschedulableInterviews.filter((i) =>
      selectedRescheduleInterviewRequirementIds.includes(
        i.interviewRequirementId
      )
    );
  }, [reschedulableInterviews, selectedRescheduleInterviewRequirementIds]);

  const [
    selectedRescheduleScheduledInterviewsV1,
    selectedRescheduleScheduledInterviewsV2,
  ] = partition(selectedRescheduleScheduledInterviews, (i) => !i.isV2);

  const selectedRescheduleInterviewRequirementIdsV1 = useMemo(() => {
    return selectedRescheduleScheduledInterviewsV1.map(
      (i) => i.interviewRequirementId
    );
  }, [selectedRescheduleScheduledInterviewsV1]);

  const selectedRescheduleScheduledInterviewIdsV2 = useMemo(() => {
    return selectedRescheduleScheduledInterviewsV2.map((i) => i.id);
  }, [selectedRescheduleScheduledInterviewsV2]);

  const {
    view,
    notes,
    setNotes,
    interviewRequirements,
    goBack,
    assignee,
    setAssignee,
    priority,
    setPriority,
    autoAssignOnAvailabilityReceiptConfig,
    setAutoAssignOnAvailabilityReceiptConfig,
    defaultInterviewPanelRequirement,
    requireVideoConferencing,
    setRequireVideoConferencing,
    algorithmSettings,
    setAlgorithmSettings,
    reuseVideoConferencingLink,
    setReuseVideoConferencingLink,
    requireConferenceRoom,
    setRequireConferenceRoom,
    updateInterviewRequirement,
    onRemoveInterviewerRequirement,
    selectedInterviewRequirement,
    defaultPrivate,
    setDefaultPrivate,
    location,
    setLocation,
    // TODO: This is weird, probably should just use its own state hook like this instead of a context so it doesn't need to be used
    // underneath a provider
  } = useInterviewRequirementsConfigurationContext();

  const { interviewRequirements: filteredRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements,
    });

  const algorithmSettingsErrors = useAlgorithmSettingsErrors({
    algorithmSettings,
    interviewRequirements: filteredRequirements,
  });

  const validationError = useMemo(() => {
    if (formType === "scheduling") {
      if (filteredRequirements.length === 0) {
        return "No interviews selected";
      }

      if (algorithmSettingsErrors) {
        return "Invalid scheduling preferences";
      }

      const interviewWithoutInterviewerRequirement = filteredRequirements.find(
        (interviewRequirement) => {
          const { interviewerRequirements: filteredInterviewerRequirements } =
            filterInterviewerRequirements(
              interviewRequirement.interviewerRequirements
            );

          return filteredInterviewerRequirements.length === 0;
        }
      );

      if (interviewWithoutInterviewerRequirement) {
        return `No ${formatEntity("interviewer slot", {
          plural: true,
        })} selected for "${getTitleFromLexicalJson(
          interviewWithoutInterviewerRequirement.interview.title
        )}"`;
      }

      return undefined;
    }

    if (formType === "rescheduling") {
      if (reschedulableInterviews.length === 0) {
        return "No interviews to reschedule";
      }

      if (selectedRescheduleInterviewRequirementIds.length === 0) {
        return "Please select at least one interview";
      }

      return undefined;
    }

    if (formType === "select-task") {
      if (!selectedSchedulingRequestId) {
        return "Please select a task";
      }

      return undefined;
    }

    return "Invalid task type";
  }, [
    formType,
    filteredRequirements,
    reschedulableInterviews.length,
    selectedRescheduleInterviewRequirementIds.length,
    selectedSchedulingRequestId,
    algorithmSettingsErrors,
  ]);

  const sharedState = useMemo(() => {
    return {
      view,
      notes,
      setNotes,
      goBack,
      assignee,
      setAssignee,
      priority,
      setPriority,
      manualAvailabilityState,
      setManualAvailabilityState,
      validationError,
      formType,
      autoAssignOnAvailabilityReceiptConfig,
      setAutoAssignOnAvailabilityReceiptConfig,
      requireVideoConferencing,
      setRequireVideoConferencing,
      algorithmSettings,
      setAlgorithmSettings,
      reuseVideoConferencingLink,
      setReuseVideoConferencingLink,
      requireConferenceRoom,
      setRequireConferenceRoom,
      updateInterviewRequirement,
      onRemoveInterviewerRequirement,
      selectedInterviewRequirement,
      defaultPrivate,
      setDefaultPrivate,
      location,
      setLocation,
    };
  }, [
    view,
    notes,
    setNotes,
    goBack,
    assignee,
    setAssignee,
    priority,
    setPriority,
    manualAvailabilityState,
    validationError,
    formType,
    autoAssignOnAvailabilityReceiptConfig,
    setAutoAssignOnAvailabilityReceiptConfig,
    requireVideoConferencing,
    setRequireVideoConferencing,
    algorithmSettings,
    setAlgorithmSettings,
    reuseVideoConferencingLink,
    setReuseVideoConferencingLink,
    requireConferenceRoom,
    setRequireConferenceRoom,
    updateInterviewRequirement,
    onRemoveInterviewerRequirement,
    selectedInterviewRequirement,
    defaultPrivate,
    setDefaultPrivate,
    location,
    setLocation,
  ]);

  const selectTaskState = useMemo(
    () => ({
      selectedSchedulingRequestId,
      setSelectedSchedulingRequestId,
    }),
    [selectedSchedulingRequestId, setSelectedSchedulingRequestId]
  );

  const schedulingRequestState = useMemo(
    () => ({
      interviewRequirements,
      defaultInterviewPanelRequirement,
    }),
    [defaultInterviewPanelRequirement, interviewRequirements]
  );

  const allowCancelInterviews = useAllowCancelInterviewsDuringReschedule({
    reschedulableInterviews,
    selectedRescheduleInterviewRequirementIds,
  });

  const defaultCancelInterviewSettings = useMemo(() => {
    const baseSettings = passedDefaultCancelInterviewSettings ?? {
      cancelInterviews: true,
      interviewerNotificationSettings: {
        sendNotifications: true,
        note: "",
      },
      candidateNotificationSettings: {
        sendNotifications: false,
        note: "",
      },
    };

    if (!allowCancelInterviews) {
      return {
        ...baseSettings,
        cancelInterviews: false,
      };
    }
    return baseSettings;
  }, [passedDefaultCancelInterviewSettings, allowCancelInterviews]);

  const [cancelInterviewSettings, setCancelInterviewSettings] =
    useState<CancelInterviewSettings>(defaultCancelInterviewSettings);

  const updateCancelInterviewSettings = useCallback(
    (updates: Partial<CancelInterviewSettings>) => {
      setCancelInterviewSettings((current) => ({
        ...current,
        ...updates,
      }));
    },
    []
  );

  const rescheduleRequestState = useMemo(
    () => ({
      selectedRescheduleInterviewRequirementIds,
      toggleSelectedRescheduleInterviewRequirementId,
      // Override this with the filtered list of v1 requirement IDs
      selectedRescheduleInterviewRequirementIdsV1,
      // V2 interviews will use scheduledInterviewId
      selectedRescheduleScheduledInterviewIdsV2,
      reschedulableInterviews,
      alwaysShownReschedulableInterviews,
      moreReschedulableInterviews,
      cancelInterviewSettings,
      updateCancelInterviewSettings,
    }),
    [
      selectedRescheduleInterviewRequirementIds,
      toggleSelectedRescheduleInterviewRequirementId,
      selectedRescheduleInterviewRequirementIdsV1,
      selectedRescheduleScheduledInterviewIdsV2,
      reschedulableInterviews,
      alwaysShownReschedulableInterviews,
      moreReschedulableInterviews,
      cancelInterviewSettings,
      updateCancelInterviewSettings,
    ]
  );

  type SharedState = typeof sharedState;
  type SelectTaskState = SharedState & {
    selectTaskState: typeof selectTaskState;
    formType: "select-task";
  };
  type SchedulingRequestState = SharedState & {
    schedulingRequestState: typeof schedulingRequestState;
    formType: "scheduling";
  };
  type RescheduleRequestState = SharedState & {
    rescheduleRequestState: typeof rescheduleRequestState;
    formType: "rescheduling";
  };
  type SchedulingRequestFormState =
    | SelectTaskState
    | SchedulingRequestState
    | RescheduleRequestState;

  const formValues = useMemo(
    () => ({
      notes,
      assignee,
      priority,
      manualAvailabilityState,
      autoAssignOnAvailabilityReceiptConfig,
      requireVideoConferencing,
      reuseVideoConferencingLink,
      algorithmSettings,
      requireConferenceRoom,
      defaultPrivate,
      location,
      interviewRequirements,
    }),
    [
      notes,
      assignee,
      priority,
      manualAvailabilityState,
      autoAssignOnAvailabilityReceiptConfig,
      requireVideoConferencing,
      reuseVideoConferencingLink,
      algorithmSettings,
      requireConferenceRoom,
      defaultPrivate,
      location,
      interviewRequirements,
    ]
  );

  const previousFormValuesRef = useRef(formValues);
  const hasChanges = useMemo(() => {
    return !isEqual(formValues, previousFormValuesRef.current);
  }, [formValues]);

  useSyncDialogLeaveConfirmation({
    showConfirmation: hasChanges,
  });

  return useMemo((): SchedulingRequestFormState => {
    if (formType === "select-task") {
      return {
        ...sharedState,
        formType: "select-task",
        selectTaskState,
      };
    }

    if (formType === "rescheduling") {
      return {
        ...sharedState,
        formType: "rescheduling",
        rescheduleRequestState,
      };
    }

    return {
      ...sharedState,
      formType: "scheduling",
      schedulingRequestState,
    };
  }, [
    formType,
    sharedState,
    schedulingRequestState,
    selectTaskState,
    rescheduleRequestState,
  ]);
}

export type SchedulingRequestFormState = ReturnType<
  typeof useSchedulingRequestFormState
>;

export type RescheduleRequestState = Extract<
  SchedulingRequestFormState,
  { formType: "rescheduling" }
>;
