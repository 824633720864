import type {
  AtlasContentEditorModule,
  LinkedOptions,
  ModuleFactory,
  ModuleLiteral,
} from "./types";

type CreateModuleFactoryOptions<Id extends string> = {
  /** A factory that creates linked options for modules. */
  createLinkedOptions?: () => LinkedOptions;
  /** A factory that creates a module and has access to linked options. */
  declareModule?: (
    linkedOptions: LinkedOptions
  ) => Omit<ModuleLiteral<Id>, "id">;
};

/** Creates a content editor module. */
export function createModule<Id extends string>(
  /** Module id. */
  id: Id,

  /** The module to create. */
  module: Omit<ModuleLiteral<Id>, "id">
): ModuleLiteral<Id>;

/** Creates a content editor module that declares and/or depends on linked options. */
export function createModule<Id extends string>(
  /** Module id. */
  id: Id,

  /** Module factory options. */
  moduleFactoryOptions: CreateModuleFactoryOptions<Id>
): ModuleFactory<Id>;

/**
 * Creates a content editor module.
 *
 * If the module depends on linked options, the optional `createLinkedOptions` and
 * `createModule` options can be passed to create linked options for other modules (or
 * itself), and to create a module that depends on linked options respectively.
 *
 * If the module does not depend on linked options, a literal can be passed directly.
 */
export function createModule<Id extends string>(
  id: Id,
  moduleOrModuleFactoryOptions:
    | CreateModuleFactoryOptions<Id>
    | Omit<ModuleLiteral<Id>, "id">
): AtlasContentEditorModule<Id> {
  if (
    "declareModule" in moduleOrModuleFactoryOptions ||
    "createLinkedOptions" in moduleOrModuleFactoryOptions
  ) {
    const { createLinkedOptions, declareModule } = moduleOrModuleFactoryOptions;
    const factory = ((linkPhase: boolean, linkedOptions: LinkedOptions) => {
      if (linkPhase) return createLinkedOptions?.() ?? {};
      return declareModule?.(linkedOptions) ?? {};
    }) as ModuleFactory<Id>;
    factory.id = id;
    return factory;
  }

  return { id, ...moduleOrModuleFactoryOptions } as ModuleLiteral<Id>;
}
