import { useAuthContext } from "auth/context";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";

type UseSyncHostWithPossibleUserMembershipIdsProps = {
  hostUserMembershipId?: string | null;
  interviewerUserMembershipIds?: string[];
  onSelect: (value: { id: string } | null) => void;
  disableAutoSwap?: boolean;
};

/** Update zoom host accordingly as possible zoom host user membership IDs change */
export function useSyncHostWithInterviewerUserMembershipIds({
  interviewerUserMembershipIds,
  hostUserMembershipId,
  onSelect,
  disableAutoSwap,
}: UseSyncHostWithPossibleUserMembershipIdsProps) {
  const prevUserMembershipIds = usePrevious(interviewerUserMembershipIds);
  const currentUserHostId = useCurrentUserHostId();

  const tryToFindAndSetValidHost = useSyncHostWithInterviewerChanges({
    interviewerUserMembershipIds,
    prevUserMembershipIds,
    hostUserMembershipId,
    onSelect,
  });

  /**
   * We will never auto-set the host as the current user unless they are an interviewer.
   * If they are the host then the user chose it so we will not touch it again.
   * We also check if the current user was swapped out as an interviewer.
   */
  const userWasManuallySetAsHost = useMemo(() => {
    const currentHostIsUser = hostUserMembershipId === currentUserHostId;
    const hostIsNotAnInterviewer =
      !interviewerUserMembershipIds?.includes(currentUserHostId);
    const hostWasSwappedOutAsInterviewer =
      prevUserMembershipIds?.includes(currentUserHostId);

    if (currentHostIsUser) {
      if (hostWasSwappedOutAsInterviewer) {
        // If we just swapped out the current user, then swap them
        return false;
      }

      if (hostIsNotAnInterviewer) {
        return true;
      }
    }

    return false;
  }, [
    currentUserHostId,
    hostUserMembershipId,
    interviewerUserMembershipIds,
    prevUserMembershipIds,
  ]);

  const [findHostTimeout, setFindHostTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  // Terrible workaround for race condition...
  const findHost = useCallback(() => {
    setFindHostTimeout(() => {
      return setTimeout(() => {
        tryToFindAndSetValidHost();
      }, 50);
    });
  }, [tryToFindAndSetValidHost]);

  useEffect(() => {
    if (disableAutoSwap) {
      if (findHostTimeout) {
        clearTimeout(findHostTimeout);
      }
      return;
    }

    if (userWasManuallySetAsHost) {
      if (findHostTimeout) {
        clearTimeout(findHostTimeout);
      }
      return;
    }

    if (!isEqual(interviewerUserMembershipIds, prevUserMembershipIds)) {
      findHost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disableAutoSwap,
    interviewerUserMembershipIds,
    prevUserMembershipIds,
    findHost,
    userWasManuallySetAsHost,
  ]);

  // Re-run sync when disableAutoSwap changes
  const prevDisableAutoSwap = usePrevious(disableAutoSwap);

  useEffect(() => {
    if (
      prevDisableAutoSwap !== disableAutoSwap &&
      !disableAutoSwap &&
      !userWasManuallySetAsHost
    ) {
      findHost();
    }
  }, [
    disableAutoSwap,
    userWasManuallySetAsHost,
    findHost,
    prevDisableAutoSwap,
  ]);
}

function useSyncHostWithInterviewerChanges({
  interviewerUserMembershipIds,
  prevUserMembershipIds,
  hostUserMembershipId,
  onSelect,
}: {
  interviewerUserMembershipIds: string[] | undefined;
  prevUserMembershipIds: string[] | undefined;
  hostUserMembershipId: string | null | undefined;
  onSelect: (value: { id: string } | null) => void;
}) {
  const findDefaultHostId = useFindDefaultHostId({
    interviewerUserMembershipIds,
    prevUserMembershipIds,
  });

  /**
   * We have logic higher up ensuring we won't run this if the user has chosen themselves.
   * The assumption can be made that we just want to find a valid interviewer and if there isn't one, unset the host.
   */
  const syncHostWithInterviewerChanges = useCallback(async () => {
    if (
      !interviewerUserMembershipIds ||
      interviewerUserMembershipIds.length === 0
    ) {
      onSelect(null);
      return;
    }

    let newHostId: string | null = hostUserMembershipId ?? null;

    const previousHostRemoved =
      hostUserMembershipId &&
      !interviewerUserMembershipIds.includes(hostUserMembershipId);
    const noHostSet = !hostUserMembershipId;

    if (previousHostRemoved || noHostSet) {
      newHostId = findDefaultHostId();
    }

    if (newHostId !== hostUserMembershipId) {
      if (newHostId) {
        onSelect({ id: newHostId });
      } else {
        onSelect(null);
      }
    }
  }, [
    interviewerUserMembershipIds,
    hostUserMembershipId,
    onSelect,
    findDefaultHostId,
  ]);

  return syncHostWithInterviewerChanges;
}

const useCurrentUserHostId = () => {
  const { user } = useAuthContext();
  return useMemo(() => user?.currentUserMembership?.id ?? "", [user]);
};

const useFindDefaultHostId = ({
  interviewerUserMembershipIds,
  prevUserMembershipIds,
}: {
  interviewerUserMembershipIds: string[] | undefined;
  prevUserMembershipIds: string[] | undefined;
}) => {
  /**
   * Find the first valid host from the list of possible hosts,
   * prioritizing newly added interviewers
   */
  return useCallback(() => {
    if (!interviewerUserMembershipIds || !interviewerUserMembershipIds.length) {
      return null;
    }

    // Find newly added interviewers
    const newInterviewers = interviewerUserMembershipIds.filter(
      (id) => !prevUserMembershipIds?.includes(id)
    );

    // Prioritize new interviewers, then other valid hosts
    const prioritizedHosts = [
      ...newInterviewers,
      ...interviewerUserMembershipIds.filter(
        (id) => !newInterviewers.includes(id)
      ),
    ];

    if (prioritizedHosts.length > 0) {
      return prioritizedHosts[0];
    }

    return null;
  }, [interviewerUserMembershipIds, prevUserMembershipIds]);
};
