import {
  Interviewer,
  InterviewerFlat,
  InterviewerPool,
  InterviewerSlot,
  InterviewerSlotTagFilter,
  StagedInterviewerSlot,
  UserMembershipForForm,
} from "client/components/interviewer-slots/utils/types";
import {
  InterviewerForInterviewerSlotMappingFragment,
  InterviewerPoolForInterviewerSlotMappingFragment,
  InterviewerRequirementForInterviewerSlotMappingFragment,
  InterviewerRequirementTagFilterForInterviewerSlotMappingFragment,
  InterviewerRequirementType,
  InterviewerSlotForInterviewerSlotMappingFragment,
  InterviewerSlotInput,
  InterviewerSlotTagFilterForInterviewerSlotMappingFragment,
  InterviewerSlotTagFilterInput,
  InterviewerSlotType,
  ScheduledInterviewInterviewerInput,
  TagFilterType,
  UserMembershipForInterviewerSlotMappingFragment,
} from "generated/graphql-codegen/graphql";
import { uniq } from "lodash";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";
import { v4 } from "uuid";

export function mapInterviewerRequirementTypeToInterviewerSlotType(
  type: InterviewerRequirementType
): InterviewerSlotType {
  switch (type) {
    case InterviewerRequirementType.PERSON:
      return InterviewerSlotType.PERSON;
    case InterviewerRequirementType.TAG:
      return InterviewerSlotType.POOL;
    case InterviewerRequirementType.SMART_MATCH:
      return InterviewerSlotType.SMART_MATCH;
    default:
      return InterviewerSlotType.PERSON;
  }
}

export function mapUserMembershipToSlot(
  userMembership: UserMembershipForForm
): InterviewerSlot {
  return {
    id: v4(),
    trainingEnabled: false,
    externalAlgorithmEnabled: false,
    type: InterviewerSlotType.PERSON,
    userMembershipsSetting: [userMembership],
    shadowingUserMembershipsSetting: [],
    interviewerPoolsSetting: [],
    interviewerRequirementId: null,
    interviewer: {
      id: v4(),
      responseStatus: null,
      responseNote: null,
      userMembership,
    },
    shadowingInterviewer: null,
  };
}

export function mapInterviewerPoolFragmentToInterviewerPool(
  pool: InterviewerPoolForInterviewerSlotMappingFragment
) {
  return {
    id: pool.id,
    name: pool.name,
    qualifiedUserCount: pool.qualifiedUserCount,
    traineeUserCount: pool.traineeUserCount,
  };
}

export function mapInterviewerPoolToSlot(
  pool: InterviewerPool
): InterviewerSlot {
  return {
    id: v4(),
    trainingEnabled: false,
    externalAlgorithmEnabled: false,
    type: InterviewerSlotType.POOL,
    userMembershipsSetting: [],
    shadowingUserMembershipsSetting: [],
    interviewerPoolsSetting: [pool],
    interviewerRequirementId: null,
    interviewer: null,
    shadowingInterviewer: null,
  };
}

export function mapSingleInterviewerToSlot(
  interviewer: Interviewer
): StagedInterviewerSlot {
  return {
    id: v4(),
    trainingEnabled: false,
    externalAlgorithmEnabled: false,
    type: InterviewerSlotType.PERSON,
    userMembershipsSetting: [interviewer.userMembership],
    shadowingUserMembershipsSetting: [],
    interviewerPoolsSetting: [],
    interviewer,
    shadowingInterviewer: null,
  };
}

export function getSmartMatchInterviewerSlot(): InterviewerSlot {
  return {
    id: v4(),
    trainingEnabled: false,
    externalAlgorithmEnabled: false,
    type: InterviewerSlotType.SMART_MATCH,
    userMembershipsSetting: [],
    shadowingUserMembershipsSetting: [],
    interviewerPoolsSetting: [],
    interviewer: null,
    shadowingInterviewer: null,
    tagFilters: [
      {
        id: v4(),
        type: TagFilterType.INCLUDE_ALL,
        tags: [],
      },
    ],
  };
}

export function mapUserMembershipToInterviewerSlotUserMembership(
  userMembership: UserMembershipForInterviewerSlotMappingFragment
): UserMembershipForForm {
  return {
    id: userMembership.id,
    firstName: userMembership.firstName,
    lastName: userMembership.lastName,
    name: userMembership.name,
    imageUrl: userMembership.imageUrl,
    email: userMembership.email,
    user: {
      id: userMembership.user.id,
      timezone: userMembership.user.timezone,
    },
  };
}

export function mapInterviewerFragmentToInterviewer(
  interviewer: InterviewerForInterviewerSlotMappingFragment
): Interviewer {
  return {
    id: interviewer.id,
    responseStatus: interviewer.responseStatus,
    responseNote: interviewer.responseNote,
    userMembership: mapUserMembershipToInterviewerSlotUserMembership(
      interviewer.userMembership
    ),
  };
}

export function mapInterviewerRequirementTagFilterToTagFilter(
  tagFilter: InterviewerRequirementTagFilterForInterviewerSlotMappingFragment
): InterviewerSlotTagFilter {
  return {
    id: tagFilter.id,
    type: tagFilter.type,
    tags: tagFilter.tags.map((t) => ({
      id: t.id,
      name: t.name,
      color: t.color,
      userCount: t.userCount,
      tagGroup: t.tagGroup,
    })),
  };
}

export function mapInterviewerRequirementFragmentToSlotSettings(
  requirement: InterviewerRequirementForInterviewerSlotMappingFragment
): InterviewerSlot {
  const interviewer: Interviewer | null =
    requirement.userMemberships.length === 1
      ? {
          id: v4(),
          responseStatus: null,
          responseNote: null,
          userMembership: requirement.userMemberships[0],
        }
      : null;
  const shadowingInterviewer: Interviewer | null =
    requirement.shadowingUserMemberships.length === 1
      ? {
          id: v4(),
          responseStatus: null,
          responseNote: null,
          userMembership: requirement.shadowingUserMemberships[0],
        }
      : null;

  return {
    id: requirement.id,
    trainingEnabled:
      requirement.shadowingUserMemberships.length > 0 ||
      requirement.includeShadower,
    externalAlgorithmEnabled: requirement.useExternalInterviewerAlgorithm,
    type: mapInterviewerRequirementTypeToInterviewerSlotType(requirement.type),
    userMembershipsSetting: requirement.userMemberships.map(
      mapUserMembershipToInterviewerSlotUserMembership
    ),
    shadowingUserMembershipsSetting: requirement.shadowingUserMemberships.map(
      mapUserMembershipToInterviewerSlotUserMembership
    ),
    interviewerPoolsSetting: requirement.interviewerPools.map((pool) => ({
      id: pool.id,
      name: pool.name,
      qualifiedUserCount: pool.qualifiedUserCount,
      traineeUserCount: pool.traineeUserCount,
    })),
    interviewerRequirementId: requirement.id,
    interviewer,
    shadowingInterviewer,
    tagFilters: requirement.tagFilters.map(
      mapInterviewerRequirementTagFilterToTagFilter
    ),
  };
}

export function mapInterviewersToSlots(
  interviewers: InterviewerForInterviewerSlotMappingFragment[]
): StagedInterviewerSlot[] {
  const interviewerRequirementIds = uniq(
    interviewers
      .map((i) => i.interviewerRequirement?.id)
      .filter(filterOutNullsAndUndefined)
  );
  const nonRequirementInterviewers = interviewers.filter(
    (i) => !i.interviewerRequirement
  );

  const interviewerSlots: StagedInterviewerSlot[] = interviewerRequirementIds
    .map((reqId) => {
      const interviewer = interviewers.find(
        (i) => i.interviewerRequirement?.id === reqId && !i.isShadow
      );
      const shadowers = interviewers.filter(
        (i) => i.interviewerRequirement?.id === reqId && i.isShadow
      );

      if (!interviewer || !interviewer.interviewerRequirement) {
        // We are looking at interviewer requirement IDs, this should always find one
        return null;
      }

      const { interviewerRequirement } = interviewer;

      return {
        ...mapInterviewerRequirementFragmentToSlotSettings(
          interviewerRequirement
        ),
        id: v4(),
        interviewer: mapInterviewerFragmentToInterviewer(interviewer),
        shadowingInterviewer: shadowers[0] ?? null,
      };
    })
    .filter(filterOutNullsAndUndefined);

  const additionalInterviewers = nonRequirementInterviewers.map(
    (i): StagedInterviewerSlot =>
      mapSingleInterviewerToSlot(mapInterviewerFragmentToInterviewer(i))
  );

  return [...interviewerSlots, ...additionalInterviewers];
}

export function mapInterviewerSlotTagFilterToTagFilter(
  interviewerSlotTagFilter: InterviewerSlotTagFilterForInterviewerSlotMappingFragment
): InterviewerSlotTagFilter {
  return {
    id: interviewerSlotTagFilter.id,
    type: interviewerSlotTagFilter.type,
    tags: interviewerSlotTagFilter.tags.map((t) => ({
      id: t.id,
      name: t.name,
      color: t.color,
      userCount: t.userCount,
      tagGroup: t.tagGroup,
    })),
  };
}

export function mapInterviewerSlotFragmentsToInterviewerSlots(
  slots: InterviewerSlotForInterviewerSlotMappingFragment[]
): StagedInterviewerSlot[] {
  // TODO: Handle interviewers without slots
  return slots.map((slot) => ({
    id: slot.id,
    type: slot.type,
    trainingEnabled: slot.trainingEnabled,
    externalAlgorithmEnabled: false,
    interviewer: slot.interviewer,
    shadowingInterviewer: slot.shadowingInterviewer,
    userMembershipsSetting: slot.userMembershipsSetting,
    shadowingUserMembershipsSetting: slot.shadowingUserMembershipsSetting,
    interviewerPoolsSetting: slot.interviewerPoolsSetting,
    tagFilters: slot.tagFilters.map(mapInterviewerSlotTagFilterToTagFilter),
  }));
}

export function mapInterviewerSlotsToScheduledInterviewInput(
  interviewerSlots: InterviewerSlot[]
): ScheduledInterviewInterviewerInput[] {
  return interviewerSlots.flatMap(
    (slot): ScheduledInterviewInterviewerInput[] => {
      return [
        ...(slot.interviewer
          ? [
              {
                userMembershipId: slot.interviewer.userMembership.id,
                interviewerRequirementId:
                  slot.interviewerRequirementId ?? undefined,
                isShadow: false,
              },
            ]
          : []),
        ...(slot.shadowingInterviewer
          ? [
              {
                userMembershipId: slot.shadowingInterviewer.userMembership.id,
                interviewerRequirementId:
                  slot.interviewerRequirementId ?? undefined,
                isShadow: true,
              },
            ]
          : []),
      ];
    }
  );
}

export const mapInterviewerSlotsToInterviewers = (
  slots: InterviewerSlot[]
): InterviewerFlat[] => {
  return slots
    .flatMap((slot): (InterviewerFlat | null)[] => [
      slot.interviewer
        ? {
            ...slot.interviewer,
            isShadow: false,
          }
        : null,
      slot.shadowingInterviewer
        ? {
            ...slot.shadowingInterviewer,
            isShadow: true,
          }
        : null,
    ])
    .filter(filterOutNullsAndUndefined);
};

/** Map the fields that we care about for diffs */
export function mapInterviewerSlotForDiff(slot: InterviewerSlot) {
  return {
    type: slot.type,
    trainingEnabled: slot.trainingEnabled,
    interviewer: slot.interviewer
      ? {
          userMembershipId: slot.interviewer.userMembership.id,
        }
      : null,
    shadowingInterviewer: slot.shadowingInterviewer
      ? {
          userMembershipId: slot.shadowingInterviewer.userMembership.id,
        }
      : null,
    userMembershipIdsSetting: slot.userMembershipsSetting.map((um) => um.id),
    shadowingUserMembershipIdsSetting: slot.shadowingUserMembershipsSetting.map(
      (um) => um.id
    ),
    interviewerPoolIdsSetting: slot.interviewerPoolsSetting.map(
      (pool) => pool.id
    ),
    createdFromInterviewerRequirementId: slot.interviewerRequirementId,
  };
}

export function mapInterviewerSlotTagFilterToInput(
  tagFilter: InterviewerSlotTagFilter
): InterviewerSlotTagFilterInput {
  return {
    id: tagFilter.id,
    type: tagFilter.type,
    tagIds: tagFilter.tags.map(({ id }) => id),
  };
}

export function mapInterviewerSlotToInput(
  slot: StagedInterviewerSlot
): InterviewerSlotInput {
  return {
    id: slot.id,
    type: slot.type,
    trainingEnabled: slot.trainingEnabled,
    interviewer: {
      userMembershipId: slot.interviewer.userMembership.id,
    },
    shadowingInterviewer: slot.shadowingInterviewer
      ? {
          userMembershipId: slot.shadowingInterviewer.userMembership.id,
        }
      : null,
    userMembershipIdsSetting: slot.userMembershipsSetting.map((um) => um.id),
    shadowingUserMembershipIdsSetting: slot.shadowingUserMembershipsSetting.map(
      (um) => um.id
    ),
    interviewerPoolIdsSetting: slot.interviewerPoolsSetting.map(
      (pool) => pool.id
    ),
    createdFromInterviewerRequirementId: slot.interviewerRequirementId,
    tagFilters: slot.tagFilters?.map(mapInterviewerSlotTagFilterToInput),
  };
}

export function mapInterviewerSlotsToInput(
  interviewerSlots: StagedInterviewerSlot[]
): InterviewerSlotInput[] {
  return interviewerSlots.map(mapInterviewerSlotToInput);
}
