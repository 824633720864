import { MultiStepView } from "@resource/atlas/multi-step-view/MultiStepView";
import { useCallback } from "react";
import { CancellationReason } from "shared/reporting/scheduled-interviews/types";

import { InterviewerMultiSelectItem } from "../ReportingReasons/__components/InterviewerMultiSelect";
import { ReportCancellationReasonView } from "../ReportingReasons/display/ReportCancellationReasonView";
import { useReportCancellationReasonState } from "../ReportingReasons/hooks/useReportCancellationReasonState";
import { useCancellationReasonDefaultValues } from "../ReportingReasons/hooks/useReportingReasonDefaultValues";
import { CancellationInterviewNotificationSettingsView } from "./components/CancellationInterviewNotificationSettingsView";
import {
  CancellationInterviewNotificationSettingsFormData,
  useCancellationInterviewNotificationSettingsState,
} from "./hooks/useCancellationInterviewNotificationSettingsState";

export type ScheduledInterviewCancellationOnCompleted = (data: {
  notificationSettings: CancellationInterviewNotificationSettingsFormData;
  reportingReason: CancellationReason;
}) => void;

export type ScheduledInterviewCancellationConfirmationViewProps = {
  onCancel: () => void;
  onCompleted: ScheduledInterviewCancellationOnCompleted;
  saveLoading?: boolean;
  interviewers: InterviewerMultiSelectItem[];
};

export function ScheduledInterviewCancellationConfirmationView({
  onCancel,
  onCompleted,
  saveLoading,
  interviewers,
}: ScheduledInterviewCancellationConfirmationViewProps) {
  const defaultValues = useCancellationReasonDefaultValues({
    originalInterviewers: interviewers,
  });
  const cancellationNotificationSettingsState =
    useCancellationInterviewNotificationSettingsState();
  const reportCancellationState =
    useReportCancellationReasonState(defaultValues);

  const onSave = useCallback(() => {
    onCompleted({
      notificationSettings:
        cancellationNotificationSettingsState.form.getValues(),
      reportingReason: reportCancellationState.form.getValues(),
    });
  }, [
    onCompleted,
    cancellationNotificationSettingsState.form,
    reportCancellationState.form,
  ]);

  return (
    <MultiStepView
      orderedViews={["notificationSettings", "reportingReason"]}
      onDismiss={onCancel}
      views={{
        notificationSettings: (viewProps) => (
          <CancellationInterviewNotificationSettingsView
            state={cancellationNotificationSettingsState}
            onCancel={onCancel}
            confirmationButton={{
              label: "Continue",
              variant: "default",
              onClick: viewProps.onContinue,
            }}
          />
        ),
        reportingReason: (viewProps) => {
          return (
            <ReportCancellationReasonView
              interviewers={interviewers}
              state={reportCancellationState}
              onCancel={onCancel}
              onBack={viewProps.onBack}
              confirmationButton={{
                label: "Save changes",
                variant: "primary",
                onClick: onSave,
                isLoading: saveLoading,
              }}
            />
          );
        },
      }}
    />
  );
}
