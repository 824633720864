import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { linkCardsCollectionModule as _linkCardsCollectionModule } from "@resource/atlas/content-editor/link-card";

import { useFetchUrlMetadata, useReplaceImageData } from "./utils";

export function useLinkCardsCollectionModule() {
  const { onReplaceImage, fileChooserInput } = useReplaceImageData();

  return withConfig(_linkCardsCollectionModule, {
    fetchUrlMetadata: useFetchUrlMetadata(),
    onReplaceImage,
    tmpRender: fileChooserInput,
  });
}
