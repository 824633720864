import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleCross, atlasPerson } from "@resource/atlas/icons";
import {
  useCalendarTimezone,
  useCurrentInterval,
} from "client/components/calendar-v2/hooks/settings";
import { Interval, WeekdayNumbers } from "luxon";
import { useMemo } from "react";

import { useInterviewerDisplayData } from "../hooks/useInterviewerDisplayData";
import {
  AvailabilityUserMembershipWithWorkingHours,
  UnavailableCalendar,
} from "../utils/types";

/** Display the interviewers and candidate's availability that are out of hours */
export function OutOfHoursHovercardContent({
  unavailableCalendars,
  dayOfWeek,
}: {
  unavailableCalendars: UnavailableCalendar[];
  dayOfWeek: WeekdayNumbers;
}) {
  const timezone = useCalendarTimezone();
  const currentInterval = useCurrentInterval();
  const includesCandidate = useMemo(
    () => unavailableCalendars.some((d) => "isCandidate" in d),
    [unavailableCalendars]
  );
  const interviewers = useMemo(
    () =>
      unavailableCalendars.filter(
        (d): d is AvailabilityUserMembershipWithWorkingHours =>
          !("isCandidate" in d)
      ),
    [unavailableCalendars]
  );

  const title = useMemo(() => {
    let titleText = "";

    if (interviewers.length) {
      titleText = `This time is outside of ${interviewers.length} interviewer's working hours`;
    }

    if (includesCandidate) {
      if (titleText) {
        titleText += " and the candidate's availability";
      } else {
        titleText = "This time is outside of the candidate's availability";
      }
    }

    return `${titleText}.`;
  }, [interviewers.length, includesCandidate]);

  return (
    <div>
      <div className="flex space-x-2">
        <Icon content={atlasCircleCross} className="w-5 h-5 text-red-500" />
        <p className="text-body-md-heavy">{title}</p>
      </div>
      <div className="mt-2 space-y-1">
        {interviewers.map((interviewer) => (
          <InterviewerDisplay
            key={interviewer.id}
            interviewer={interviewer}
            dayOfWeek={dayOfWeek}
            timezone={timezone}
            currentInterval={currentInterval}
          />
        ))}
        {includesCandidate && (
          <div className="flex space-x-2 items-center">
            <div className="w-8 h-8 rounded-full bg-light-gray-200 flex justify-center items-center">
              <Icon content={atlasPerson} className="w-5 h-5" />
            </div>
            <span className="text-body-md-heavy">
              Candidate&apos;s provided availability
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

/** Display the interviewer's availability in the hovercard */
function InterviewerDisplay({
  interviewer,
  dayOfWeek,
  timezone,
  currentInterval,
}: {
  interviewer: AvailabilityUserMembershipWithWorkingHours;
  dayOfWeek: WeekdayNumbers;
  timezone: string;
  currentInterval: Interval;
}) {
  const { todayInUserTimezone, workingThisDay, displayedWorkingHours } =
    useInterviewerDisplayData({
      interviewer,
      dayOfWeek,
      timezone,
      currentInterval,
    });

  return (
    <div className="flex space-x-2 items-start">
      <Avatar
        name={interviewer.name}
        image={interviewer.imageUrl}
        size="medium"
      />
      <div className="flex flex-col">
        <span className="text-body-md">
          {interviewer.name}{" "}
          <span className="text-subtle">
            ({todayInUserTimezone.toFormat("ZZZZ")})
          </span>
        </span>
        {displayedWorkingHours && workingThisDay ? (
          <span className="text-body-sm text-subtle">
            Working hours for this day: {displayedWorkingHours}
          </span>
        ) : (
          <span className="text-body-sm text-subtle">Not working this day</span>
        )}
      </div>
    </div>
  );
}
