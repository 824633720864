export function SectionMessage({
  children,
  leadingIcon,
}: {
  children: React.ReactNode;
  leadingIcon?: React.ReactNode;
}) {
  return (
    <div className="rounded-md py-3 px-4 bg-light-gray-500 text-body-md flex items-start gap-3">
      {leadingIcon}
      <div>{children}</div>
    </div>
  );
}
