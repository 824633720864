import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { serviceGoogle } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import TextField from "@resource/atlas/textfield/TextField";
import { View } from "@resource/atlas/view/View";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import LoginButton from "components/LoginForm/LoginButton";
import { CandidateForGuideAuthFragment } from "generated/graphql-codegen/graphql";
import { signIn } from "next-auth/react";
import { useState } from "react";

type GuideAuthDialogProps = {
  candidate: CandidateForGuideAuthFragment;
  dialog: DialogProps;
  onSendMagicLink: () => void;
};

export function GuideAuthDialog({
  candidate,
  dialog,
  onSendMagicLink,
}: GuideAuthDialogProps) {
  const [asCandidate, setAsCandidate] = useState(true);

  return asCandidate ? (
    <CandidateAuthDialog
      dialog={dialog}
      candidate={candidate}
      handleSwitch={() => setAsCandidate(!asCandidate)}
      onSendMagicLink={onSendMagicLink}
    />
  ) : (
    <OrgUserAuthDialog
      dialog={dialog}
      candidate={candidate}
      onClose={() => setAsCandidate(true)}
    />
  );
}

function CandidateAuthDialog({
  candidate,
  dialog,
  onSendMagicLink,
  handleSwitch,
}: GuideAuthDialogProps & { handleSwitch: () => void }) {
  const onConfirm = async () => {
    onSendMagicLink();
    dialog.store.hide();
  };

  return (
    <Dialog size="medium" store={dialog.store}>
      <View
        header={{ hideBorder: true }}
        content={{
          title: "Confirm your email to continue",
          subTitle:
            "Confirm your email address below and we'll send a magic link to your inbox.",
        }}
        footer={{
          hideBorder: true,
          rightActions: (
            <FormRightActions
              isLoading={false}
              cancel={{
                onClick: dialog.store.hide,
              }}
              save={{
                children: "Send magic link",
                onClick: onConfirm,
              }}
            />
          ),
        }}
      >
        <TextField
          className="pt-1"
          isDisabled
          label="Email"
          value={candidate.email ?? undefined}
        />

        <div className="text-subtle text-sm mt-2">
          Not the candidate?{" "}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link as="button" onClick={handleSwitch}>
            Sign in with your {candidate.organization.name} account
          </Link>
        </div>
      </View>
    </Dialog>
  );
}

function OrgUserAuthDialog({
  candidate,
  dialog,
  onClose,
}: {
  candidate: CandidateForGuideAuthFragment;
  dialog: DialogProps;
  onClose: () => void;
}) {
  const [loading, setLoading] = useState<string | null>(null);

  return (
    <Dialog size="medium" store={dialog.store} onClose={onClose}>
      <View
        header={{ hideBorder: true }}
        content={{
          title: `Sign in to ${candidate.organization.name}`,
          subTitle: `Welcome back! Sign in quickly and easily with your ${candidate.organization.name} account.`,
        }}
        CustomFooter={
          <div className="flex items-center px-[1.5rem] h-[5.5rem]">
            <LoginButton
              connection="google"
              onClick={() => {
                setLoading("google");
                signIn("google");
              }}
              label="Sign in with Google"
              loading={loading}
              icon={serviceGoogle}
            />
          </div>
        }
      />
    </Dialog>
  );
}
