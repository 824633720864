import { AddInterviewChangeMessageInput } from "client/components/scheduling-review/AddInterviewChangeMessageInput";
import { NotifyInterviewersCheckbox } from "client/components/scheduling-review/NotifyInterviewersCheckbox";

export type UpdateInterviewNotificationSettingsDisplayProps = {
  notifyInterviewers: boolean;
  message: string;
  onNotifyInterviewersChange: (value: boolean) => void;
  onMessageChange: (value: string) => void;
};

/**
 * Display component for configuring the notification settings when updating an interview
 */
export function UpdateInterviewNotificationSettingsDisplay({
  notifyInterviewers,
  message,
  onNotifyInterviewersChange,
  onMessageChange,
}: UpdateInterviewNotificationSettingsDisplayProps) {
  return (
    <>
      <NotifyInterviewersCheckbox
        notifyInterviewers={notifyInterviewers}
        onChange={onNotifyInterviewersChange}
      />
      {notifyInterviewers && (
        <AddInterviewChangeMessageInput
          value={message}
          onChange={onMessageChange}
        />
      )}
    </>
  );
}
