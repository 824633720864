import { useCalendarSettingsHydrationOptions } from "client/components/calendar-v2/components/CalendarSettingsProvider";
import { useDefaultDayViewType } from "client/components/calendar-v2/components/settings/__hooks/useDefaultDayViewType";
import { ScheduledInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { useTimezone } from "client/timezones/useTimezone";
import { Provider } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { DateTime } from "luxon";

import { SchedulerEditableScheduledInterview } from "../../../utils/types";
import {
  interviewsAtom,
  originalInterviewsAtom,
  scheduledInterviewGroupSettingsAtom,
  selectedInterviewIdAtom,
  viewFilterAtom,
} from "../../state";

export type SchedulerHydrationData = {
  originalInterviews: SchedulerEditableScheduledInterview[];
  interviews: SchedulerEditableScheduledInterview[];
  scheduledInterviewGroupSettings: ScheduledInterviewGroupSettings | null;
  selectedInterviewId?: string;
  guideId: string;
  scheduledInterviewGroupId: string | null;
  schedulingRequestId: string | null;
};

type ProviderProps = SchedulerHydrationData & {
  children: React.ReactNode;
};

function HydrateAtoms({
  originalInterviews,
  interviews,
  guideId,
  schedulingRequestId,
  scheduledInterviewGroupSettings,
  scheduledInterviewGroupId,
  selectedInterviewId,
  children,
}: ProviderProps) {
  const timezone = useTimezone();
  const dayViewType = useDefaultDayViewType();

  const includeWeekends = interviews.some((interview) => {
    if (interview.startTime) {
      const interviewDate = DateTime.fromISO(interview.startTime).setZone(
        timezone
      );
      return interviewDate.weekday >= 6;
    }
    return false;
  });

  const calendarHydration = useCalendarSettingsHydrationOptions({
    defaultSettings: {
      timezone,
      includeWeekends,
      dayViewType,
    },
  });

  useHydrateAtoms([
    ...calendarHydration,
    [originalInterviewsAtom, originalInterviews],
    [interviewsAtom, interviews],
    [scheduledInterviewGroupSettingsAtom, scheduledInterviewGroupSettings],
    [selectedInterviewIdAtom, selectedInterviewId ?? null],
    [
      viewFilterAtom,
      {
        guideId,
        schedulingRequestId,
        scheduledInterviewGroupId,
      },
    ],
  ]);

  return <>{children}</>;
}

export function SchedulerProvider(props: ProviderProps) {
  return (
    <Provider>
      <HydrateAtoms {...props} />
    </Provider>
  );
}
