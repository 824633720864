import { Button } from "@resource/atlas/button/Button";
import type { AtlasButtonProps } from "@resource/atlas/button/types";
import { useLogEvent } from "analytics";
import { formatEntity } from "shared/constants/entities";

type GuidePreviewButtonProps = Omit<
  AtlasButtonProps<"button">,
  "onClick" | "disabled"
> & {
  guide?: {
    publicUrl: string;
  };
};

function GuidePreviewButton({
  guide,
  children,
  ...buttonProps
}: GuidePreviewButtonProps) {
  const logEvent = useLogEvent({ component: "GuidePreviewButton" });

  return (
    <Button
      {...buttonProps}
      onClick={() => {
        if (!guide) {
          return;
        }
        const route = guide.publicUrl;
        logEvent("View Guide Clicked", {});
        window.open(route, "_blank");
      }}
    >
      View {formatEntity("guide")}
    </Button>
  );
}

export default GuidePreviewButton;
