import "@uploadcare/blocks/blocks/themes/lr-basic/index.css";

import TextArea from "@resource/atlas/textfield/TextArea";
import TextField from "@resource/atlas/textfield/TextField";
import { useToast } from "@resource/atlas/toast/use-toast";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View, ViewProps } from "@resource/atlas/view/View";
import { FileInfo } from "@uploadcare/react-widget";
import clsx from "clsx";
import PronounSelect from "components/PronounSelect";
import { gql } from "generated/graphql-codegen";
import { ReactElement } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { loadUploadcare } from "utils/loadUploadcare";

import { EditProfileState } from "./__hooks/useEditProfileState";
import EditProfileImage from "./EditProfileImage";
import EditWebsiteUrls from "./EditWebsiteUrls";
import { FormLabels } from "./types";

gql(`
  fragment UserMembershipForEditProfile on UserMembership {
    id
    email
    firstName
    lastName
    name
    imageUrl
    title
    biography
    pronoun
    websiteUrls
    videoIntroductionUrl
    userId
    membershipRoles {
      id
      name
    }
    onboardingComplete
    active
    createdAt
    user {
      id
      timezone
    }
  }
`);

loadUploadcare();

const defaultLabels: FormLabels = {
  name: "Full name",
  title: "Role",
  biography: "Bio",
  websiteUrls: "URL",
  imageUrl: "",
};

type EditProfileFormProps = {
  customLabels?: FormLabels;
  hideImageUploader?: boolean;
  hideVideoUrl?: boolean;
  viewProps?: ViewProps<"form">;
  state: EditProfileState;
};

export function EditProfileForm({
  customLabels = {},
  hideImageUploader = false,
  hideVideoUrl = false,
  viewProps,
  state,
}: EditProfileFormProps): ReactElement {
  const { sendToast } = useToast();
  const formLabels: FormLabels = {
    ...defaultLabels,
    ...customLabels,
  };
  const { form } = state;
  const { control } = form;

  const {
    fields: websiteUrlFields,
    insert: insertWebsiteUrl,
    remove: removeWebsiteUrl,
  } = useFieldArray({
    control,
    name: "websiteUrls",
  });

  return (
    <View as="form" {...viewProps}>
      <div className="flex">
        {!hideImageUploader && (
          <div>
            <Controller
              name="imageUrl"
              control={control}
              render={({ field }) => (
                <EditProfileImage
                  imageUrl={field.value}
                  label={formLabels.imageUrl}
                  onUpload={(file: FileInfo) => {
                    if (!file.cdnUrl) {
                      sendToast("Something went wrong uploading your image", {
                        variant: "error",
                      });
                    } else {
                      field.onChange(file.cdnUrl);
                    }
                  }}
                  onRemove={() => {
                    field.onChange("");
                  }}
                />
              )}
            />
          </div>
        )}
        <div
          className={clsx(
            "flex-grow",
            hideImageUploader ? "space-y-4" : "ml-6 space-y-6"
          )}
        >
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState: { invalid, error } }) => (
              <Tooltip content={error?.message} UNSAFE_mode="slot">
                <TextField
                  {...field}
                  label="Full name"
                  inputRef={field.ref}
                  validationState={invalid ? "invalid" : undefined}
                  errorMessage={error?.message}
                  isRequired
                />
              </Tooltip>
            )}
          />
          <div
            className={clsx(
              "md:flex md:gap-2 md:space-y-0",
              hideImageUploader ? "space-y-4" : "space-y-6"
            )}
          >
            <div className="md:w-1/2">
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={formLabels.title}
                    inputRef={field.ref}
                  />
                )}
              />
            </div>
            <div className="md:w-1/2">
              <Controller
                name="pronoun"
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <div className="space-y-1">
                    <span className="text-body-md-heavy">Pronouns</span>
                    <PronounSelect
                      {...field}
                      className="w-full"
                      value={field.value}
                      validationState={invalid ? "invalid" : undefined}
                      errorMessage={error?.message}
                      onChange={field.onChange}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <Controller
            name="biography"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                label={formLabels.biography}
                inputRef={field.ref}
              />
            )}
          />
          <EditWebsiteUrls
            control={control}
            label={formLabels.websiteUrls}
            websiteUrlFields={websiteUrlFields}
            insertWebsiteUrl={insertWebsiteUrl}
            removeWebsiteUrl={removeWebsiteUrl}
          />
          {!hideVideoUrl && (
            <Controller
              name="videoIntroductionUrl"
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <Tooltip content={error?.message} UNSAFE_mode="slot">
                  <TextField
                    {...field}
                    label="Video introduction"
                    placeholder="YouTube or Loom video link"
                    validationState={invalid ? "invalid" : undefined}
                    inputRef={field.ref}
                  />
                </Tooltip>
              )}
            />
          )}
        </div>
      </div>
    </View>
  );
}

export default EditProfileForm;
