import { atlasCircleBubble } from "@resource/atlas/icons";
import { NotificationType } from "modules/notifications/types";

import { createAppNotificationRenderer } from "./types";

export const commentMentionRenderer = createAppNotificationRenderer({
  type: NotificationType.CommentMention,
  render: ({ user, candidate }) => {
    return (
      <>
        {user.name} commented on{" "}
        <span className="text-body-md-heavy">
          {candidate.name}&apos;s scheduling task
        </span>
      </>
    );
  },
  avatar: ({ user }) => {
    return {
      name: user.name,
      avatarUrl: user.avatarUrl,
      icon: atlasCircleBubble,
      iconClass: "text-subtle bg-white",
    };
  },
});
