import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { atlasEllipsisHorizontal } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useAuthContext } from "auth/context";
import { getAvatarStatusFromInterviewerResponseStatus } from "client/utils/interviewer-response-status";
import clsx from "clsx";
import { useEditRsvpMenuItem } from "components/EditInterviewerRsvp/utils/hooks/useEditRsvpMenuItem";
import { useEditProfileMenuItem } from "components/EditPersonalProfile/__hooks/useEditProfileMenuItem";
import { useEditPreferencesMenuItem } from "components/EditPreferences/__hooks/useEditPreferencesMenuItem";
import { gql } from "generated/graphql-codegen";
import {
  InterviewerForInterviewersDisplayFragment,
  OrganizationFeaturesEnum,
} from "generated/graphql-codegen/graphql";

gql(`
  fragment InterviewerForInterviewersDisplay on Interviewer {
    id
    responseStatus
    responseNote
    userMembership {
      id
      name
      imageUrl
      title
    }
  }
`);

export function InterviewersDisplay({
  interviewers,
}: {
  interviewers: InterviewerForInterviewersDisplayFragment[];
}) {
  return (
    <div className="rounded-md shadow-1 divide-y divide-gray-border">
      {interviewers.map((interviewer) => {
        return (
          <InterviewerItemDisplay
            key={interviewer.id}
            interviewer={interviewer}
          />
        );
      })}
    </div>
  );
}

function InterviewerItemDisplay({
  interviewer,
}: {
  interviewer: InterviewerForInterviewersDisplayFragment;
}) {
  const { hasFeatureEnabled } = useAuthContext();
  const hasScheduling = hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING);
  const status = hasScheduling
    ? getAvatarStatusFromInterviewerResponseStatus(interviewer.responseStatus)
    : undefined;
  const editProfileMenuItem = useEditProfileMenuItem({
    userMembershipId: interviewer.userMembership.id,
  });
  const editPreferencesMenuItem = useEditPreferencesMenuItem({
    userMembershipId: interviewer.userMembership.id,
  });
  const editRsvpMenuItem = useEditRsvpMenuItem({
    interviewerId: interviewer.id,
  });
  const menuItems = useMenuItems(
    (i) => {
      return [
        editProfileMenuItem.createItem(i),
        hasScheduling ? editPreferencesMenuItem.createItem(i) : null,
        hasScheduling ? editRsvpMenuItem.createItem(i) : null,
      ];
    },
    [
      editPreferencesMenuItem,
      editProfileMenuItem,
      editRsvpMenuItem,
      hasScheduling,
    ]
  );

  return (
    <>
      <div
        className={clsx(
          "flex w-full justify-between p-4 items-center first:rounded-t-md last:rounded-b-md"
        )}
        key={interviewer.id}
      >
        <div className="flex space-x-2 items-top">
          <div>
            <Avatar
              image={interviewer.userMembership.imageUrl}
              name={interviewer.userMembership.name}
              status={status}
              statusNote={interviewer.responseNote}
            />
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className="flex text-body-md-heavy">
              {interviewer.userMembership.name}
            </div>
            {interviewer.userMembership.title && (
              <div className="text-body-md text-subtle flex">
                {interviewer.userMembership.title}
              </div>
            )}
            {interviewer.responseNote && (
              <div className="text-body-md text-subtle flex mt-1">
                &quot;{interviewer.responseNote}&quot;
              </div>
            )}
          </div>
        </div>
        <Menu.Root>
          <Menu.Trigger>
            <Button size="small" isGhost icon={atlasEllipsisHorizontal} />
          </Menu.Trigger>
          <Menu.Content items={menuItems} />
        </Menu.Root>
      </div>
      {editProfileMenuItem.render()}
      {editPreferencesMenuItem.render()}
      {editRsvpMenuItem.render()}
    </>
  );
}
