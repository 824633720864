/* eslint-disable jsx-a11y/anchor-has-content */
import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { atlasEllipsisHorizontal } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { AtlasMenuContentItem } from "@resource/atlas/menu/types";
import { useLogEvent } from "analytics";
import clsx from "clsx";
import { GuideSettingsDialog } from "components/Guide/GuideSettingsDialog";
import { gql } from "generated/graphql-codegen";
import { GuideManagerMenuGuideFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { formatEntity } from "shared/constants/entities";
import urljoin from "url-join";

import { ForcePropagateToGuideConfirmationDialog } from "./ForcePropagateToGuideConfirmationDialog";
import { ResyncApplicationDataConfirmationDialog } from "./ResyncApplicationDataConfirmationDialog";

gql(`
  fragment GuideManagerMenuGuide on Guide {
    id
    status
    publicUrl
    atssyncApplication {
      id
      atsUrl
    }
    candidate {
      id
      organization {
        id
      }
    }
    jobId
  }
`);

type GuideTopBarMenuProps = {
  guide: GuideManagerMenuGuideFragment;
  refetchGuide?: () => void;
  className?: string;
};

function GuideManagerMenu({
  guide,
  refetchGuide,
  className,
}: GuideTopBarMenuProps) {
  const guideSettingsDialog = useDialogStore();
  const resyncApplicationDialog = useDialogStore();
  const syncGuideTemplateDialog = useDialogStore();
  const logEvent = useLogEvent({ component: "GuideTopBarMenu" });

  const menuItems = useMemo(() => {
    const items: AtlasMenuContentItem[] = [];

    items.push({
      _type: "item",
      children: "Edit candidate settings",
      key: "settings",
      onSelect: () => {
        logEvent("Menu Settings Clicked", {
          project: ["Zeus", "Post an update to a guide"],
        });
        guideSettingsDialog.show();
      },
    });

    items.push({
      _type: "item",
      children: "Edit job settings",
      key: "edit-job-settings",
      onSelect: () => logEvent("Guide Top Bar Menu Edit Job Settings Clicked"),
      renderWrapper: (props) => (
        <a
          {...props}
          href={urljoin(
            process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT || "",
            `jobs/${guide.jobId}/stages`
          )}
          target="_blank"
          rel="noreferrer"
        />
      ),
    });

    items.push({
      _type: "item",
      children: `Sync updated ${formatEntity("guide")} settings`,
      key: "sync-guide-template",
      onSelect: () => {
        syncGuideTemplateDialog.show();
      },
    });

    if (guide.atssyncApplication) {
      items.push({
        _type: "item",
        children: "Sync updated Greenhouse settings",
        key: "resync-application",
        onSelect: () => {
          resyncApplicationDialog.show();
        },
      });
    }

    return items;
  }, [
    guide.atssyncApplication,
    guide.jobId,
    logEvent,
    guideSettingsDialog,
    syncGuideTemplateDialog,
    resyncApplicationDialog,
  ]);

  return (
    <>
      <Menu.Root placement="bottom-end">
        <Menu.Trigger>
          <Button
            icon={atlasEllipsisHorizontal}
            size="small"
            isGhost
            className={clsx(className)}
          />
        </Menu.Trigger>
        <Menu.Content defaultSize="compact" items={menuItems} />
      </Menu.Root>
      <GuideSettingsDialog store={guideSettingsDialog} guideId={guide.id} />
      <ForcePropagateToGuideConfirmationDialog
        store={syncGuideTemplateDialog}
        guideId={guide.id}
        onSubmit={refetchGuide}
      />
      <ResyncApplicationDataConfirmationDialog
        store={resyncApplicationDialog}
        applicationId={guide.atssyncApplication?.id ?? ""}
      />
    </>
  );
}

export default GuideManagerMenu;
