/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  NodeKey,
} from "lexical";
import { useMemo, useRef, useState } from "react";

import { useEvent } from "../../../../__utils/react";

export function useSelectionContext() {
  const [selectedUniqueNodeKey, setSelectedUniqueNodeKey] = useState<
    NodeKey | undefined
  >();

  const [isRangeSelection, setIsRangeSelection] = useState(false);
  const [isUncollapsedSelection, setIsUncollapsedSelection] = useState(false);

  const latestRangeSelectionRectRef = useRef<DOMRect | null>(null);
  const getLatestRangeSelectionRect = useEvent(
    () => latestRangeSelectionRectRef.current
  );

  const [editor] = useLexicalComposerContext();
  const updateListener = useEvent(() => {
    const selection = $getSelection();

    // update the unique selected node key
    if ($isNodeSelection(selection)) {
      const nodes = selection.getNodes();
      if (nodes.length === 1) setSelectedUniqueNodeKey(nodes[0].getKey());
      else setSelectedUniqueNodeKey(undefined);
    } else setSelectedUniqueNodeKey(undefined);

    // compute whether the selection is a range
    setIsRangeSelection($isRangeSelection(selection));

    // compute whether the selection is an uncollapsed range
    setIsUncollapsedSelection(
      $isRangeSelection(selection) && !selection.isCollapsed()
    );

    // update the selection rect
    const nativeSelection = window.getSelection();
    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect: DOMRect;

      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null)
          inner = inner.firstElementChild as HTMLElement;
        rect = inner.getBoundingClientRect();
      } else rect = domRange.getBoundingClientRect();

      latestRangeSelectionRectRef.current = rect;
    }
  });

  const value = useMemo(
    () => ({
      selectedUniqueNodeKey,
      isRangeSelection,
      isUncollapsedSelection,
      getLatestRangeSelectionRect,
    }),
    [
      getLatestRangeSelectionRect,
      isRangeSelection,
      isUncollapsedSelection,
      selectedUniqueNodeKey,
    ]
  );

  return [updateListener, value] as const;
}
