import { scheduledInterviewDetailsVariableKeys } from "modules/lexical/shared/utils/constants";
import { ScheduledInterviewDetailsVariableSet } from "modules/lexical/shared/utils/types";
import { createVariableSets } from "modules/lexical/shared/variables";
import { renderVideoConferencingDetailsForEmail } from "shared/guide-scheduler/scheduled-interview-details/video-conferencing-variable";

const {
  nameSet: scheduledInterviewDetailsNameSet,
  rendererSet: scheduledInterviewDetailsRendererSet,
  specSet: scheduledInterviewDetailsSpecSet,
} = createVariableSets(scheduledInterviewDetailsVariableKeys);

export {
  scheduledInterviewDetailsNameSet,
  scheduledInterviewDetailsSpecSet,
  scheduledInterviewDetailsRendererSet,
};

export const scheduledInterviewDetailsRendererSetForEmail = {
  ...scheduledInterviewDetailsRendererSet,
  videoConferencingUrl: renderVideoConferencingDetailsForEmail,
};

export type { ScheduledInterviewDetailsVariableSet };
