import {
  RadioItem,
  RadioItemProps,
} from "client/components/generic/inputs/RadioItem";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { useCallback } from "react";
import {
  ScheduledInterviewCancellationReason,
  ScheduledInterviewOtherReason,
} from "shared/reporting/scheduled-interviews/types";

import { AdditionalTextInput } from "./AdditionalTextInput";
import { OtherReasonCollectionAdditionalInfo } from "./OtherReasonCollectAdditionalInfo";

const cancellationReasonTextMap: Record<
  ScheduledInterviewCancellationReason,
  string
> = {
  [ScheduledInterviewCancellationReason.CANDIDATE_WITHDREW]:
    "Candidate withdrew",
  [ScheduledInterviewCancellationReason.CANDIDATE_PAUSED]: "Candidate paused",
  [ScheduledInterviewCancellationReason.REJECTING]: "Rejecting",
  [ScheduledInterviewCancellationReason.MISTAKE]: "Mistake",
  [ScheduledInterviewCancellationReason.OTHER]: "Other",
};

export type ReportCancellationReasonFormProps = {
  cancellationReason: ScheduledInterviewCancellationReason | null;
  onChangeCancellationReason: (
    reason: ScheduledInterviewCancellationReason
  ) => void;
  otherReason: ScheduledInterviewOtherReason | null;
  onChangeOtherReason: (otherReason: ScheduledInterviewOtherReason) => void;
  additionalText: string | null | undefined;
  onChangeAdditionalText: (value: string) => void;
};

/** Form for choosing cancellation reason when not rescheduling */
export function ReportCancellationReasonForm({
  cancellationReason,
  onChangeCancellationReason,
  otherReason,
  onChangeOtherReason,
  additionalText,
  onChangeAdditionalText,
}: ReportCancellationReasonFormProps) {
  const getRadioItemProps = useCallback(
    ({
      type,
    }: {
      type: ScheduledInterviewCancellationReason;
    }): RadioItemProps => ({
      label: cancellationReasonTextMap[type],
      isSelected: cancellationReason === type,
      onClick: () => onChangeCancellationReason(type),
    }),
    [cancellationReason, onChangeCancellationReason]
  );

  return (
    <FormGroup label="Cancel reason" isRequired>
      <div className="space-y-2">
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewCancellationReason.CANDIDATE_WITHDREW,
          })}
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewCancellationReason.CANDIDATE_PAUSED,
          })}
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewCancellationReason.REJECTING,
          })}
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewCancellationReason.MISTAKE,
          })}
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewCancellationReason.OTHER,
          })}
          AdditionalInfoInput={
            <OtherReasonCollectionAdditionalInfo
              reason={otherReason}
              onChangeReason={onChangeOtherReason}
            />
          }
        />
      </div>
      <AdditionalTextInput
        className="mt-4"
        text={additionalText}
        onChangeText={onChangeAdditionalText}
      />
    </FormGroup>
  );
}
