import Badge from "@resource/atlas/badge/Badge";
import { SelectV2 } from "@resource/atlas/select-v2";
import { displayNameForSchedulingRequestPriority } from "client/app/(scheduling requests)/__utils";
import { usePrioritySelectItems } from "client/components/generic/select/PrioritySelect";
import { SchedulingRequestPriority } from "generated/graphql-codegen/graphql";

type PriorityBadgeSelectProps = {
  value: SchedulingRequestPriority;
  onChange: (value: SchedulingRequestPriority) => void;
};

export function PriorityBadgeSelect({
  value,
  onChange,
}: PriorityBadgeSelectProps) {
  const items = usePrioritySelectItems();
  return (
    <SelectV2.Root
      value={value}
      setValue={(v) => onChange(v as unknown as SchedulingRequestPriority)}
    >
      <SelectV2.Trigger
        render={
          <Badge
            className="cursor-pointer"
            variant={
              value === SchedulingRequestPriority.HIGH ? "pink" : "default"
            }
          >
            {displayNameForSchedulingRequestPriority(value)}
          </Badge>
        }
      />
      <SelectV2.Content items={items} />
    </SelectV2.Root>
  );
}
