// eslint-disable-next-line import/no-restricted-paths
import { ColorConfig } from "client/components/calendar-v2/utils/colors";
import { EditProfileDialogProps } from "components/EditPersonalProfile/EditProfileDialog";
import { EditPreferencesDialogProps } from "components/EditPreferences/EditPreferencesDialog";
import { useCallback, useMemo } from "react";

import {
  InterviewerSlotTagFilter,
  InterviewerSlotWithSchedulingData,
  UserMembershipForForm,
  UserMembershipSelectWrapper,
} from "../utils/types";

export type UseEditInterviewerSlotDisplayStateProps = {
  onChangeTrainingEnabled: (trainingEnabled: boolean) => void;
  onChangeExternalAlgorithmEnabled: (externalAlgorithmEnabled: boolean) => void;
  onSelectUser: (props: {
    isShadower: boolean;
    userMembership: UserMembershipForForm;
  }) => void;
  onRemoveUser: (props: { isShadower: boolean }) => void;
  onRemoveInterviewerSlot: () => void;
  onInterviewerProfileChange?: EditProfileDialogProps["onCompleted"];
  onInterviewerSchedulingPreferencesChange?: EditPreferencesDialogProps["onCompleted"];
  interviewerSlot: InterviewerSlotWithSchedulingData;
  calendarColors?: {
    [userMembershipId: string]: ColorConfig;
  };
  allMembershipIdsFromInterviewSlots: string[];
  size?: "xs" | "small" | "medium";
  disableInterviewerTraining?: boolean;
  disableExternalInterviewerAlgorithm?: boolean;
  UserMembershipSelect: UserMembershipSelectWrapper;
  hideInterviewerDayLoad?: boolean;
  onTagFiltersChange: (tagFilters: InterviewerSlotTagFilter[]) => void;
};

export function useEditInterviewerSlotDisplayState({
  onChangeTrainingEnabled,
  onChangeExternalAlgorithmEnabled,
  onSelectUser,
  onRemoveUser,
  onRemoveInterviewerSlot,
  onInterviewerProfileChange,
  onInterviewerSchedulingPreferencesChange,
  interviewerSlot,
  calendarColors,
  allMembershipIdsFromInterviewSlots,
  size,
  disableExternalInterviewerAlgorithm,
  disableInterviewerTraining,
  UserMembershipSelect,
  hideInterviewerDayLoad,
  onTagFiltersChange,
}: UseEditInterviewerSlotDisplayStateProps) {
  const onSelectInterviewer = useCallback(
    (userMembership: UserMembershipForForm) =>
      onSelectUser({ userMembership, isShadower: false }),
    [onSelectUser]
  );
  const onRemoveInterviewer = useCallback(
    () => onRemoveUser({ isShadower: false }),
    [onRemoveUser]
  );
  const onSelectShadower = useCallback(
    (userMembership: UserMembershipForForm) =>
      onSelectUser({ userMembership, isShadower: true }),
    [onSelectUser]
  );
  const onRemoveShadower = useCallback(
    () => onRemoveUser({ isShadower: true }),
    [onRemoveUser]
  );
  /** Choose the correct interviewer and callbacks based on isShadower */
  const getInterviewerState = useCallback(
    ({ isShadower }: { isShadower?: boolean }) => {
      return isShadower
        ? {
            interviewer: interviewerSlot.shadowingInterviewer,
            onSelectUser: onSelectShadower,
            onRemoveUser: onRemoveShadower,
          }
        : {
            interviewer: interviewerSlot.interviewer,
            onSelectUser: onSelectInterviewer,
            onRemoveUser: onRemoveInterviewer,
          };
    },
    [
      onRemoveInterviewer,
      onRemoveShadower,
      onSelectInterviewer,
      onSelectShadower,
      interviewerSlot.interviewer,
      interviewerSlot.shadowingInterviewer,
    ]
  );

  return useMemo(
    () => ({
      onChangeTrainingEnabled,
      onChangeExternalAlgorithmEnabled,
      onInterviewerProfileChange,
      onInterviewerSchedulingPreferencesChange,
      interviewerSlot,
      calendarColors,
      allMembershipIdsFromInterviewSlots,
      size,
      disableExternalInterviewerAlgorithm,
      disableInterviewerTraining,
      UserMembershipSelect,
      hideInterviewerDayLoad,
      onSelectInterviewer,
      onRemoveInterviewer,
      onRemoveInterviewerSlot,
      onSelectShadower,
      onRemoveShadower,
      getInterviewerState,
      onTagFiltersChange,
    }),
    [
      onChangeTrainingEnabled,
      onChangeExternalAlgorithmEnabled,
      onInterviewerProfileChange,
      onInterviewerSchedulingPreferencesChange,
      interviewerSlot,
      calendarColors,
      allMembershipIdsFromInterviewSlots,
      size,
      disableExternalInterviewerAlgorithm,
      disableInterviewerTraining,
      UserMembershipSelect,
      hideInterviewerDayLoad,
      onSelectInterviewer,
      onRemoveInterviewer,
      onRemoveInterviewerSlot,
      onSelectShadower,
      onRemoveShadower,
      getInterviewerState,
      onTagFiltersChange,
    ]
  );
}
export type EditInterviewerSlotDisplayState = ReturnType<
  typeof useEditInterviewerSlotDisplayState
>;
