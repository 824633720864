import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { BlockError } from "client/components/generic/errors/BlockError";
import { useHomepageRedirect } from "client/components/guide/useHomePageRedirect";
import { useSelfScheduleRequestSubscription } from "client/self-schedule/components/__hooks/useSelfScheduleRequest";
import { CreateOrUpdateSelfScheduleSubmissionDialog } from "client/self-schedule/components/CreateOrUpdateSelfScheduleSubmissionDialog";
import { EditExistingSelfScheduleRequestDialog } from "client/self-schedule/components/EditExistingSelfScheduleRequestView";
import {
  SelfScheduleRequestBlock,
  SelfScheduleRequestBlockLoading,
} from "client/self-schedule/components/SelfScheduleRequestBlock";
import { SelfScheduleRequestPreviewNodeRenderer } from "client/self-schedule/components/SelfScheduleRequestPreviewBlock";
import { SerializedSelfScheduleRequestNode } from "shared/self-schedule/rich-blocks/self-schedule-request";

// node renderer
// -------------
function SelfScheduleRequestNodeRenderer({ id }: { id: string }) {
  const logEvent = useLogEvent({
    component: "SelfScheduleRequestNodeRenderer",
  });
  const { selfScheduleRequest, loading, error } =
    useSelfScheduleRequestSubscription(id);

  const { isOrgUser } = useGuideAuthContext();
  const isCandidate = !isOrgUser;
  const createSubmissionDialogStore = useDialogStore();
  const editDialogStore = useDialogStore();

  const redirectOnSubmit = useHomepageRedirect();

  if (loading) {
    return <SelfScheduleRequestBlockLoading />;
  }

  if (error) {
    return <BlockError label="Error loading self-schedule request" />;
  }

  if (!selfScheduleRequest) {
    return <BlockError label="Self-schedule request not found" />;
  }

  return (
    <>
      <SelfScheduleRequestBlock
        selfScheduleRequest={selfScheduleRequest}
        {...(isCandidate
          ? {
              isCandidate: true,
              onSchedule: createSubmissionDialogStore.show,
            }
          : {
              isCandidate: false,
              onEdit: () => {
                logEvent("Edit Self-schedule Request Clicked");
                editDialogStore.show();
              },
            })}
      />
      <CreateOrUpdateSelfScheduleSubmissionDialog
        store={createSubmissionDialogStore}
        selfScheduleRequestId={id}
        onComplete={redirectOnSubmit}
      />
      <EditExistingSelfScheduleRequestDialog
        store={editDialogStore}
        selfScheduleRequest={selfScheduleRequest}
      />
    </>
  );
}

export const renderSelfScheduleRequestNode =
  createNodeRenderer<SerializedSelfScheduleRequestNode>(({ data }) => {
    if ("preview" in data) {
      return <SelfScheduleRequestPreviewNodeRenderer data={data} />;
    }

    if (!("selfScheduleRequestId" in data)) {
      // if node rendering, we should have self scheduling request
      return <BlockError label="Can't render self-schedule request" />;
    }

    return <SelfScheduleRequestNodeRenderer id={data.selfScheduleRequestId} />;
  });
