import { AutomationStatus } from "@prisma/client";
import { HistoricalScheduledInterview } from "shared/historical-data/scheduled-interviews/types";
import { z } from "zod";

import { GroupSchedulingResult } from "./actions/schemas/ScheduleInterviewsActionSchema";

type NotificationSettings = { enabled: boolean };

export const AutomationErrorSchema = z.object({
  name: z.string(),
  message: z.string(),
  occurredAt: z.string(),
  errorData: z.object({}),
});

export type AutomationError = z.infer<typeof AutomationErrorSchema>;

/**
 * Specify deduplication parameters to prevent duplicate chat messages from being created.
 */
export type ChatMessageDeduplication = Record<string, unknown>;

export type ChatMessageMeta = {
  historicalScheduledInterviews?: HistoricalScheduledInterview[];
  schedulingResult?: GroupSchedulingResult;
  notificationSettings?: NotificationSettings;
  guidePostId?: string;
  deduplication?: ChatMessageDeduplication;
  createdSchedulingTaskId?: string;
};

export type BaseAutomationType = {
  id: string;
  isEnabled: boolean;
  status: AutomationStatus;
};

export type SchedulingRequestWithAutomation = {
  schedulingTaskAssistantAutomation: BaseAutomationType | null;
};
