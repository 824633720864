import { ActivityForFeedFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { SelectedInterviewerDisplay } from "../interviewer-slots/display/SelectedInterviewerDisplay";

export function MovedStageDiffDisplay({
  prevShadowsCompleted,
  prevReverseShadowsCompleted,
  shadowsCompleted,
  reverseShadowsCompleted,
  interviewerPool,
  userMembership,
}: {
  prevShadowsCompleted?: number;
  prevReverseShadowsCompleted?: number;
  shadowsCompleted?: number;
  reverseShadowsCompleted?: number;
  interviewerPool: ActivityForFeedFragment["interviewerPool"];
  userMembership: ActivityForFeedFragment["userMembership"];
}) {
  const mappedUserMembership = useMemo(() => {
    if (!userMembership) {
      return null;
    }

    return {
      ...userMembership,
      // Don't care about timezone here
      user: {
        id: userMembership?.id,
        timezone: null,
      },
    };
  }, [userMembership]);
  const previousInterviewer = useMemo(() => {
    if (
      !interviewerPool ||
      !mappedUserMembership ||
      prevShadowsCompleted === undefined ||
      prevReverseShadowsCompleted === undefined
    ) {
      return null;
    }

    return {
      ...mappedUserMembership,
      trainingData: {
        completedShadows: prevShadowsCompleted,
        completedReverseShadows: prevReverseShadowsCompleted,
        shadows: interviewerPool.shadowsRequiredForTrainees,
        reverseShadows: interviewerPool.reverseShadowsRequiredForTrainees,
      },
    };
  }, [
    interviewerPool,
    mappedUserMembership,
    prevReverseShadowsCompleted,
    prevShadowsCompleted,
  ]);
  const newInterviewer = useMemo(() => {
    if (
      !interviewerPool ||
      !mappedUserMembership ||
      shadowsCompleted === undefined ||
      reverseShadowsCompleted === undefined
    ) {
      return null;
    }

    return {
      ...mappedUserMembership,
      trainingData: {
        completedShadows: shadowsCompleted,
        completedReverseShadows: reverseShadowsCompleted,
        shadows: interviewerPool.shadowsRequiredForTrainees,
        reverseShadows: interviewerPool.reverseShadowsRequiredForTrainees,
      },
    };
  }, [
    interviewerPool,
    mappedUserMembership,
    shadowsCompleted,
    reverseShadowsCompleted,
  ]);

  if (!previousInterviewer || !newInterviewer) {
    return null;
  }

  return (
    <div className="flex items-center space-x-4 rounded-md shadow-1 p-2">
      <div className="w-[45%] flex justify-center">
        <SelectedInterviewerDisplay
          size="small"
          interviewer={previousInterviewer}
          hideAvailabilityIcon
          hideTimezone
        />
      </div>
      <div className="text-xl">→</div>
      <div className="w-[45%] flex justify-center">
        <SelectedInterviewerDisplay
          size="small"
          interviewer={newInterviewer}
          hideAvailabilityIcon
          hideTimezone
        />
      </div>
    </div>
  );
}
