/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  TextNode,
} from "lexical";
import { useEffect, useMemo, useRef, useState } from "react";
import * as React from "react";

import { useEvent } from "../../../../__utils/react";
import { getTextUpToAnchor } from "./shared";
import type { MatchFn, QueryMatch } from "./types";

// utils
// -----

function startTransition(callback: () => void) {
  if (React.startTransition) React.startTransition(callback);
  else callback();
}

function getQueryTextForSearch(editor: LexicalEditor): string | undefined {
  let text: string | undefined;
  editor.getEditorState().read(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) return;

    text = getTextUpToAnchor(selection);
  });
  return text;
}

function isSelectionOnEntityBoundary(
  editor: LexicalEditor,
  offset: number
): boolean {
  if (offset !== 0) return false;

  return editor.getEditorState().read(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const { anchor } = selection;
      const anchorNode = anchor.getNode();
      const prevSibling = anchorNode.getPreviousSibling();
      return $isTextNode(prevSibling) && prevSibling.isTextEntity();
    }
    return false;
  });
}

function tryToPositionRange(leadOffset: number, range: Range): boolean {
  const domSelection = window.getSelection();
  if (domSelection === null || !domSelection.isCollapsed) return false;

  const { anchorNode } = domSelection;
  const startOffset = leadOffset;
  const endOffset = domSelection.anchorOffset;

  if (anchorNode == null || endOffset == null) return false;

  try {
    range.setStart(anchorNode, startOffset);
    range.setEnd(anchorNode, endOffset);
  } catch (error) {
    return false;
  }

  return true;
}

function getFullMatchOffset(
  documentText: string,
  entryText: string,
  offset: number
): number {
  let triggerOffset = offset;
  for (let i = triggerOffset; i <= entryText.length; i += 1) {
    if (documentText.substr(-i) === entryText.substr(0, i)) triggerOffset = i;
  }
  return triggerOffset;
}

function splitNodeContainingQuery(match: QueryMatch): TextNode | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed())
    return undefined;

  const { anchor } = selection;
  if (anchor.type !== "text") return undefined;

  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) return undefined;

  const selectionOffset = anchor.offset;
  const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
  const characterOffset = match.replaceableString.length;
  const queryOffset = getFullMatchOffset(
    textContent,
    match.queryString,
    characterOffset
  );
  const startOffset = selectionOffset - queryOffset;
  if (startOffset < 0) return undefined;

  let newNode;
  if (startOffset === 0) {
    [newNode] = anchorNode.splitText(selectionOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
  }

  return newNode;
}

// use typeahead
// -------------

type UseTypeaheadInput = {
  matchFn: MatchFn;
};

type UseTypeaheadOutput = {
  open: boolean;
  hide: () => void;
  match: QueryMatch | undefined;
  $getMatchNode: () => TextNode | ElementNode;
  $replaceMatch: (node: LexicalNode) => void;
  $removeMatch: () => void;
  getAnchorRect: () => DOMRect | null;
};

export function useTypeahead({ matchFn }: UseTypeaheadInput) {
  const [editor] = useLexicalComposerContext();

  const [match, setMatch] = useState<QueryMatch>();
  const [cachedMatch, setCachedMatch] = useState<QueryMatch>();

  const anchorRangeRef = useRef<Range | undefined>();

  const closeTypeahead = useEvent(() => {
    setMatch(undefined);
    anchorRangeRef.current = undefined;
  });
  const openTypeahead = useEvent(
    (newMatch: QueryMatch, newAnchorRange: Range) => {
      setMatch(newMatch);
      setCachedMatch(newMatch);
      anchorRangeRef.current = newAnchorRange;
    }
  );

  useEffect(() => {
    let activeRange: Range | undefined = document.createRange();

    const updateListener = () => {
      editor.getEditorState().read(() => {
        const range = activeRange;
        const selection = $getSelection();
        const text = getQueryTextForSearch(editor);

        if (
          !$isRangeSelection(selection) ||
          !selection.isCollapsed() ||
          !text ||
          !range
        )
          return closeTypeahead();

        const newMatch = matchFn(text, editor);

        if (
          !newMatch ||
          isSelectionOnEntityBoundary(editor, newMatch.leadOffset)
        )
          return closeTypeahead();

        const isRangePositioned = tryToPositionRange(
          newMatch.leadOffset,
          range
        );

        if (!isRangePositioned) return closeTypeahead();

        return startTransition(() => openTypeahead(newMatch, range));
      });
    };

    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      activeRange = undefined;
      removeUpdateListener();
    };
  }, [closeTypeahead, editor, matchFn, openTypeahead]);

  // remember whether it was manually closed, and avoid opening it again
  // until there's a "match reset" (match -> no match -> match again)
  const [wasManuallyClosed, setWasManuallyClosed] = useState(false);
  useEffect(() => {
    if (wasManuallyClosed && !match) setWasManuallyClosed(false);
  }, [match, wasManuallyClosed]);

  const open = Boolean(match) && !wasManuallyClosed;
  const hide = useEvent(() => {
    if (!wasManuallyClosed) setWasManuallyClosed(true);
  });

  const cachedAnchorRectRef = useRef<DOMRect>();
  const getAnchorRect = useEvent(() => {
    const currentRect = anchorRangeRef.current?.getBoundingClientRect();
    if (currentRect) {
      cachedAnchorRectRef.current = currentRect;
      return currentRect;
    }
    return cachedAnchorRectRef.current ?? null;
  });

  const $getMatchNode = useEvent(() => {
    if (!match) throw new Error("No match found");
    const matchNode = splitNodeContainingQuery(match);
    if (!matchNode) throw new Error("No node found for match");
    return matchNode;
  });
  const $replaceMatch = useEvent((node: LexicalNode) =>
    $getMatchNode().replace(node)
  );
  const $removeMatch = useEvent(() => $getMatchNode().remove());

  const output = useMemo(
    (): UseTypeaheadOutput => ({
      open,
      hide,
      // return the cached match to prevent flickering while dependent UI is
      // closing with an animation
      match: cachedMatch,
      $getMatchNode,
      $replaceMatch,
      $removeMatch,
      getAnchorRect,
    }),
    [
      open,
      hide,
      cachedMatch,
      $getMatchNode,
      $replaceMatch,
      $removeMatch,
      getAnchorRect,
    ]
  );

  return output;
}

// TODO: only open when actual text is added, and not on selection change?
