import { Button as AriaButton } from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasEllipsisHorizontal,
  atlasOperatorOr,
  atlasRefreshCcw,
  atlasRingInfo,
  serviceGreenhouse,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { Toggle } from "client/app/__components/Toggle";
import { UsersSelect } from "client/components/generic/select/UserSelect";
import { useEditProfileMenuItem } from "components/EditPersonalProfile/__hooks/useEditProfileMenuItem";
import { useEditPreferencesMenuItem } from "components/EditPreferences/__hooks/useEditPreferencesMenuItem";
import { useCallback } from "react";
import { formatEntity } from "shared/constants/entities";
import { syncedInterviewerRequirementIsOverridden } from "shared/utils/interview-requirements";

type UserMembership = {
  id: string;
  imageUrl: string | null;
  name: string;
};

export type InterviewerConfig = {
  id: string;
  syncedUserMemberships: UserMembership[];
  userMemberships: UserMembership[];
  shadowingUserMemberships: UserMembership[];
  includeShadower: boolean;
  isSynced: boolean;
};

type UpdateFn = (config: {
  id: string;
  syncedUserMemberships: UserMembership[];
  userMemberships: UserMembership[];
  shadowingUserMemberships: UserMembership[];
  includeShadower: boolean;
}) => void;

type InterviewerConfigPersonDisplayProps = {
  interviewerConfig: InterviewerConfig;
  onRemove?: () => void;
  onUpdate?: UpdateFn;
  canShadow?: boolean;
  interviewAtsUrl?: string;
};

type TraineeSelectProps = {
  interviewerConfig: InterviewerConfig;
  onUpdate?: UpdateFn;
};

export function TraineeSelect({
  interviewerConfig,
  onUpdate,
}: TraineeSelectProps) {
  if (interviewerConfig.shadowingUserMemberships.length > 0) return null;

  return (
    <UsersSelect
      Trigger={
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link as="button" className="py-2 px-3 text-left text-body-md">
          Add a shadower
        </Link>
      }
      onSelect={(user) => {
        onUpdate?.({
          ...interviewerConfig,
          shadowingUserMemberships: [
            ...interviewerConfig.shadowingUserMemberships,
            {
              id: user.id,
              imageUrl: user.imageUrl,
              name: user.name,
            },
          ],
        });
      }}
    />
  );
}

export function InterviewerConfigPersonDisplay({
  interviewerConfig,
  onRemove,
  onUpdate,
  canShadow,
  interviewAtsUrl,
}: InterviewerConfigPersonDisplayProps) {
  return (
    <div className="flex justify-between rounded-md items-center group shadow-1">
      <div className="flex flex-col flex-grow">
        <div className="flex flex-col space-y-2">
          {interviewerConfig.isSynced &&
          interviewerConfig.syncedUserMemberships.length ? (
            <GreenhouseBannerInfo
              interviewerConfig={interviewerConfig}
              onUpdate={onUpdate}
              interviewAtsUrl={interviewAtsUrl}
            />
          ) : null}
          {interviewerConfig.userMemberships.map((userMembership, idx) => {
            const isLast = idx === interviewerConfig.userMemberships.length - 1;

            return (
              <div key={userMembership.id}>
                <InterviewerSlot
                  interviewerConfig={interviewerConfig}
                  isLast={isLast}
                  userMembership={userMembership}
                  onRemove={onRemove}
                  onUpdate={onUpdate}
                />
                {!isLast && (
                  <div className="flex items-center gap-1 h-[0.0625rem]">
                    <div className="w-full border-b-2 border-dashed border-light-gray-500" />
                    <Icon content={atlasOperatorOr} className="text-subtle" />
                    <div className="w-full border-b-2 border-dashed border-light-gray-500" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {canShadow && (
          <>
            <div className="text-body-sm-heavy bg-light-gray-200 py-2 px-3 text-subtle flex items-center gap-2">
              <Toggle
                size="small"
                value={interviewerConfig.includeShadower}
                onChange={(value) => {
                  onUpdate?.({
                    ...interviewerConfig,
                    shadowingUserMemberships: [],
                    includeShadower: value,
                  });
                }}
              />
              <div className="flex-grow">Interviewer training</div>
              <Tooltip
                content="Optionally, one of the selected interviewers should be scheduled to shadow."
                isInstant
              >
                <Icon content={atlasRingInfo} className="w-4 h-4" />
              </Tooltip>
            </div>
            {interviewerConfig.includeShadower && (
              <>
                <TraineeSelect
                  interviewerConfig={interviewerConfig}
                  onUpdate={onUpdate}
                />
                <div className="flex flex-col space-y-2">
                  {interviewerConfig.shadowingUserMemberships.map(
                    (userMembership, idx) => {
                      const isLast =
                        idx ===
                        interviewerConfig.shadowingUserMemberships.length - 1;

                      return (
                        <div key={userMembership.id}>
                          <InterviewerSlot
                            interviewerConfig={interviewerConfig}
                            isLast={isLast}
                            userMembership={userMembership}
                            onRemove={onRemove}
                            onUpdate={onUpdate}
                            isShadow
                          />
                          {!isLast && (
                            <div className="flex items-center gap-1 h-[0.0625rem]">
                              <div className="w-full border-b-2 border-dashed border-light-gray-500" />
                              <Icon
                                content={atlasOperatorOr}
                                className="text-subtle"
                              />
                              <div className="w-full border-b-2 border-dashed border-light-gray-500" />
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

type InterviewerRowProps = {
  isLast: boolean;
  interviewerConfig: InterviewerConfig;
  userMembership: UserMembership;
  onRemove?: () => void;
  onUpdate?: UpdateFn;
  isShadow?: boolean;
  isSynced?: boolean;
};

export function InterviewerSlot({
  isLast,
  interviewerConfig,
  onUpdate,
  onRemove,
  userMembership,
  isShadow,
}: InterviewerRowProps) {
  const editPreferencesMenuItem = useEditPreferencesMenuItem({
    userMembershipId: userMembership.id,
  });
  const editProfileMenuItem = useEditProfileMenuItem({
    userMembershipId: userMembership.id,
  });

  const menuItems = useMenuItems(
    (i) => [
      editProfileMenuItem.createItem(i),
      editPreferencesMenuItem.createItem(i),
      i.item({
        id: "remove",
        children: "Remove",
        onClick: () => {
          if (
            !interviewerConfig.isSynced &&
            !isShadow &&
            interviewerConfig.userMemberships.length === 1
          ) {
            onRemove?.();
          } else {
            onUpdate?.({
              ...interviewerConfig,
              ...(isShadow
                ? {
                    shadowingUserMemberships:
                      interviewerConfig.shadowingUserMemberships.filter(
                        ({ id }) => id !== userMembership.id
                      ),
                  }
                : {
                    userMemberships: interviewerConfig.userMemberships.filter(
                      ({ id }) => id !== userMembership.id
                    ),
                  }),
            });
          }
        },
      }),
    ],
    [
      editProfileMenuItem,
      editPreferencesMenuItem,
      isShadow,
      interviewerConfig,
      onRemove,
      onUpdate,
      userMembership.id,
    ]
  );

  return (
    <>
      <div className="flex gap-2 items-center p-3">
        <Avatar
          size="medium"
          image={userMembership.imageUrl}
          name={userMembership.name}
          disableTooltip
        />
        <div className="flex gap-2 grow">
          <span className="text-body-md truncate">{userMembership.name}</span>
        </div>

        <div className="flex-shrink-0">
          <ButtonGroup size="small">
            {isLast && (
              <UsersSelect
                Trigger={
                  <Tooltip
                    isInstant
                    side="left"
                    content="Add an alternate for this interviewer slot"
                  >
                    <Button variant="purple" className="text-p" isGhost>
                      Add alternate
                    </Button>
                  </Tooltip>
                }
                excludeUserIds={[
                  ...interviewerConfig.userMemberships.map((um) => um.id),
                  ...interviewerConfig.shadowingUserMemberships.map(
                    (um) => um.id
                  ),
                ]}
                onSelect={(user) => {
                  onUpdate?.({
                    ...interviewerConfig,
                    ...(isShadow
                      ? {
                          shadowingUserMemberships: [
                            ...interviewerConfig.shadowingUserMemberships,
                            {
                              id: user.id,
                              imageUrl: user.imageUrl,
                              name: user.name,
                            },
                          ],
                        }
                      : {
                          userMemberships: [
                            ...interviewerConfig.userMemberships,
                            {
                              id: user.id,
                              imageUrl: user.imageUrl,
                              name: user.name,
                            },
                          ],
                        }),
                  });
                }}
              />
            )}
            <UsersSelect
              Trigger={
                <Tooltip content="Select a different interviewer" isInstant>
                  <Button
                    icon={atlasRefreshCcw}
                    isGhost
                    className="!text-subtle"
                  />
                </Tooltip>
              }
              onSelect={(selectedUserMembership) => {
                onUpdate?.({
                  ...interviewerConfig,
                  ...(isShadow
                    ? {
                        shadowingUserMemberships: [selectedUserMembership],
                      }
                    : {
                        userMemberships: interviewerConfig.userMemberships.map(
                          (configMembership) => {
                            if (configMembership.id === userMembership.id) {
                              return selectedUserMembership;
                            }

                            return configMembership;
                          }
                        ),
                      }),
                });
              }}
            />
            <Menu.Root>
              <Menu.Trigger>
                <Button isGhost negativeMargin="right">
                  <Icon
                    content={atlasEllipsisHorizontal}
                    className="w-5 h-5 text-subtle"
                  />
                </Button>
              </Menu.Trigger>
              <Menu.Content items={menuItems} />
            </Menu.Root>
          </ButtonGroup>
        </div>
      </div>
      {editPreferencesMenuItem.render()}
      {editProfileMenuItem.render()}
    </>
  );
}

function GreenhouseBannerInfo({
  interviewerConfig,
  onUpdate,
  interviewAtsUrl,
}: {
  interviewerConfig: InterviewerConfig;
  onUpdate?: UpdateFn;
  interviewAtsUrl?: string;
}) {
  const onReset = useCallback(() => {
    onUpdate?.({
      ...interviewerConfig,
      userMemberships: interviewerConfig.syncedUserMemberships,
    });
  }, [interviewerConfig, onUpdate]);

  if (
    syncedInterviewerRequirementIsOverridden({
      interviewerRequirement: interviewerConfig,
    })
  ) {
    return (
      <div className="bg-yellow-50 text-yellow-900 p-2 text-body-sm flex space-x-2">
        <div className="flex flex-row space-x-2 flex-grow">
          <Icon content={serviceGreenhouse} className="w-5 h-5" />
          <div>
            {formatEntity("interviewer slot", {
              plural: false,
              capitalize: true,
            })}{" "}
            is out of sync with Greenhouse.
          </div>
        </div>
        <AriaButton className="text-yellow-500 flex-shrink-0" onClick={onReset}>
          Reset to Greenhouse
        </AriaButton>
      </div>
    );
  }

  return (
    <div className="bg-light-gray-200 text-subtle p-2 text-body-sm flex space-x-2">
      <div className="flex flex-row space-x-2 flex-grow">
        <Icon content={serviceGreenhouse} className="w-5 h-5" />
        <div>
          {formatEntity("interviewer slot", {
            plural: false,
            capitalize: true,
          })}{" "}
          is synced with Greenhouse.
        </div>
      </div>
      {interviewAtsUrl && (
        <Link href={interviewAtsUrl} className="flex-shrink-0" target="_blank">
          Edit in Greenhouse
        </Link>
      )}
    </div>
  );
}
