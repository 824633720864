import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPeople, atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import TextField from "@resource/atlas/textfield/TextField";
import { useAuthContext } from "auth/context";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingRequestAutoAssignOnAvailabilityReceiptType,
  SchedulingTaskAssigneeType,
  UserMembershipForAssigneeSelectionFragment,
} from "generated/graphql-codegen/graphql";
import { compact } from "lodash";
import { useEffect, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import useQuery from "utils/useQuery";

gql(`
fragment UserMembershipForAssigneeSelection on UserMembership {
  id
  firstName
  lastName
  name
  imageUrl
  email
}
`);

const FETCH_USERS_FOR_ASSIGNEE_SELECT_QUERY = gql(`
  query FetchUsersForAssigneeSelectQuery($search: String, $limit: Int) {
    userMemberships(search: $search, limit: $limit, hasGoogleCalendarPermissions: true) {
      ...UserMembershipForAssigneeSelection
    }
  }
`);

const FETCH_SUGGESTED_USERS_FOR_ASSIGNEE_SELECT_QUERY = gql(`
  query FetchSuggestedUsersForAssigneeSelectQuery($guideId: ID!, $interviewRequirementsToReschedule: [ID!]!) {
    suggestedUsersForAssigneeSelect(guideId: $guideId, interviewRequirementsToReschedule: $interviewRequirementsToReschedule) {
      coordinator { 
        ...UserMembershipForAssigneeSelection
        user {
          canAccessCalendar
        }
      }
      originalAssignees {
        ...UserMembershipForAssigneeSelection
        user {
          canAccessCalendar
        }
      }
    }
  }
`);

type AssigneeOptionProps = {
  userMembership: Pick<
    UserMembershipForAssigneeSelectionFragment,
    "id" | "imageUrl" | "name" | "email"
  >;
  labelSuffix?: string;
  isSelected: boolean;
  onSelect: () => void;
};

function AssigneeOption({
  userMembership,
  labelSuffix,
  isSelected,
  onSelect,
}: AssigneeOptionProps) {
  return (
    <OptionItem
      key={userMembership.id}
      isSelectable
      isSelected={isSelected}
      onClick={onSelect}
      size="compact"
    >
      <div className="flex gap-2 items-center">
        <Avatar
          size="xs"
          image={userMembership.imageUrl}
          name={userMembership.name || userMembership.email}
        />
        <div title={userMembership.name || userMembership.email}>
          {userMembership.name || userMembership.email}
          {labelSuffix && <span className="text-subtle">{labelSuffix}</span>}
        </div>
      </div>
    </OptionItem>
  );
}

export type AssigneeOption =
  | UserMembershipForAssigneeSelectionFragment
  | SchedulingRequestAutoAssignOnAvailabilityReceiptType
  | null;

type AssigneeSelectProps = {
  onSelect: (assignee: AssigneeOption, isAutoAssign?: boolean) => void;
  assignee?: AssigneeOption;
  updateLoading?: boolean;
  variant: "form" | "async";
  showSuggestedJobCoordinatorForGuideId?: string;
  showSuggestedOriginalAssigneesForInterviewRequirementIds?: string[];
  defaultToSuggestedUser?: SchedulingTaskAssigneeType;
  showSuggestedSelf?: boolean;
  className?: string;
};

export const getAssigneeId = (assignee: NonNullable<AssigneeOption>) => {
  if (typeof assignee === "object") {
    return assignee.id;
  }

  return assignee;
};

export function AssigneeSelect({
  onSelect,
  assignee,
  updateLoading,
  variant,
  showSuggestedJobCoordinatorForGuideId,
  showSuggestedOriginalAssigneesForInterviewRequirementIds,
  showSuggestedSelf = true,
  defaultToSuggestedUser,
  className,
}: AssigneeSelectProps) {
  const authContext = useAuthContext();
  const currentUserMembership = authContext.user?.currentUserMembership;
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");
  const popoverState = usePopoverState();

  const { data: suggestedUsersData } = useQuery(
    FETCH_SUGGESTED_USERS_FOR_ASSIGNEE_SELECT_QUERY,
    {
      variables: {
        guideId: showSuggestedJobCoordinatorForGuideId!, // Assuming `guide` is available in the component's scope
        interviewRequirementsToReschedule:
          showSuggestedOriginalAssigneesForInterviewRequirementIds ?? [], // Assuming `schedulingTaskIds` is an array of task IDs available in the component's scope
      },
      skip: !showSuggestedJobCoordinatorForGuideId,
    }
  );

  const jobCoordinator =
    suggestedUsersData?.suggestedUsersForAssigneeSelect?.coordinator;

  const originalTaskAssignees = useMemo(
    () =>
      suggestedUsersData?.suggestedUsersForAssigneeSelect?.originalAssignees ??
      [],
    [suggestedUsersData]
  );

  useEffect(() => {
    if (variant === "form" && defaultToSuggestedUser) {
      switch (defaultToSuggestedUser) {
        case SchedulingTaskAssigneeType.COORDINATION_QUEUE:
          onSelect(
            SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE,
            true
          );
          break;
        case SchedulingTaskAssigneeType.COORDINATOR:
          if (jobCoordinator) {
            onSelect(jobCoordinator, true);
          }
          break;
        case SchedulingTaskAssigneeType.LAST_ASSIGNEE:
          if (originalTaskAssignees.length > 0) {
            onSelect(originalTaskAssignees[0], true);
          }
          break;
        case SchedulingTaskAssigneeType.SELF:
          if (currentUserMembership?.id) {
            onSelect(currentUserMembership, true);
          }
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobCoordinator,
    originalTaskAssignees,
    currentUserMembership,
    defaultToSuggestedUser,
    variant,
  ]);

  const { data, loading } = useQuery(FETCH_USERS_FOR_ASSIGNEE_SELECT_QUERY, {
    variables: {
      search: debouncedTerm,
      limit: 25,
    },
  });

  const users = useMemo(
    () =>
      data?.userMemberships.filter((um) => {
        return um.id;
      }) ?? [],
    [data]
  );

  const options = useMemo(
    () => [
      <OptionItem
        key="send-to-queue"
        isSelectable
        isSelected={!assignee}
        onClick={() => {
          onSelect(
            SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE
          );
          popoverState.hide();
        }}
        size="compact"
      >
        <div className="flex gap-2 items-center">
          <Icon content={atlasPeople} className="text-subtle" />
          <div>Send to coordination queue</div>
        </div>
      </OptionItem>,
      currentUserMembership && showSuggestedSelf && (
        <AssigneeOption
          userMembership={currentUserMembership}
          labelSuffix=" (You)"
          isSelected={
            assignee
              ? currentUserMembership.id === getAssigneeId(assignee)
              : false
          }
          onSelect={() => {
            onSelect(currentUserMembership);
            popoverState.hide();
          }}
        />
      ),
      jobCoordinator && jobCoordinator.id !== currentUserMembership?.id && (
        <AssigneeOption
          userMembership={jobCoordinator}
          labelSuffix=" (Coordinator)"
          isSelected={
            assignee ? jobCoordinator.id === getAssigneeId(assignee) : false
          }
          onSelect={() => {
            onSelect(jobCoordinator);
            popoverState.hide();
          }}
        />
      ),
      ...originalTaskAssignees
        .filter((u) => u.id !== currentUserMembership?.id)
        .map((userMembership) => (
          <AssigneeOption
            key={userMembership.id}
            userMembership={userMembership}
            labelSuffix=" (Original assignee)"
            isSelected={
              assignee ? userMembership.id === getAssigneeId(assignee) : false
            }
            onSelect={() => {
              onSelect(userMembership);
              popoverState.hide();
            }}
          />
        )),
      ...users
        .filter((u) => {
          return !compact([
            currentUserMembership?.id,
            jobCoordinator?.id,
            ...originalTaskAssignees.map((um) => um.id),
          ]).includes(u.id);
        })
        .map((userMembership) => (
          <AssigneeOption
            key={userMembership.id}
            userMembership={userMembership}
            isSelected={
              assignee ? userMembership.id === getAssigneeId(assignee) : false
            }
            onSelect={() => {
              onSelect(userMembership);
              popoverState.hide();
            }}
          />
        )),
    ],
    [
      assignee,
      currentUserMembership,
      showSuggestedSelf,
      jobCoordinator,
      originalTaskAssignees,
      users,
      onSelect,
      popoverState,
    ]
  );

  // clear search term on popover state close
  useEffect(() => {
    if (!popoverState.open) {
      setSearchTerm("");
    }
  }, [popoverState.open, setSearchTerm]);

  const selectedUserLabelSuffix = useMemo(() => {
    if (
      assignee &&
      originalTaskAssignees.some((u) => u.id === getAssigneeId(assignee))
    ) {
      return " (Original assignee)";
    }
    if (assignee && jobCoordinator?.id === getAssigneeId(assignee)) {
      return " (Coordinator)";
    }
    if (assignee && currentUserMembership?.id === getAssigneeId(assignee)) {
      return " (You)";
    }

    return "";
  }, [
    assignee,
    originalTaskAssignees,
    jobCoordinator?.id,
    currentUserMembership?.id,
  ]);

  const renderedTrigger = useMemo(() => {
    return (
      <SelectTrigger
        size={variant === "async" ? "xs" : "medium"}
        className={clsx(
          variant === "async"
            ? "flex-1 text-body-sm bg-transparent w-fit flex-grow -ml-2.5 py-1"
            : "!bg-light-gray-500 w-full !-ml-0",
          className
        )}
      >
        {assignee && typeof assignee === "object" ? (
          <div className="flex gap-2 items-center">
            <Avatar size="xs" image={assignee.imageUrl} name={assignee.name} />
            <div title={assignee.name}>
              {assignee.name}
              {selectedUserLabelSuffix && (
                <span className="text-subtle">{selectedUserLabelSuffix}</span>
              )}
            </div>
          </div>
        ) : (
          <div className="flex gap-1.5 items-center mr-1">
            <Icon content={atlasPeople} className="text-subtle" />
            <p className="text-body-md">Coordination queue</p>
          </div>
        )}
      </SelectTrigger>
    );
  }, [variant, className, assignee, selectedUserLabelSuffix]);

  if (updateLoading) {
    return (
      <div className="h-full flex overflow-hidden py-1">
        <LoadingIndicator size="small" />
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      <Popover.Root state={popoverState}>
        <Popover.Trigger>{renderedTrigger}</Popover.Trigger>
        <Popover.Content className="w-[30rem]" hasPadding={false}>
          <TextField
            className="m-2"
            size="small"
            value={searchTerm}
            autoFocus
            icon={atlasSearch}
            placeholder="Search"
            aria-label="Search"
            onChange={setSearchTerm}
            isClearable
          />
          <div className="p-[.5rem] pt-0">
            {loading && (
              <>
                <OptionSeparator />
                <div className="flex justify-center">
                  <LoadingIndicator size="small" />
                </div>
              </>
            )}
            {!loading && options.length > 0 && <>{options}</>}
            {!loading && options.length === 0 && (
              <>
                <OptionSeparator />
                <p className="px-5 py-2 text-body-md">No results found</p>
              </>
            )}
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}
