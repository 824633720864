import { useEvent } from "@resource/atlas/__utils/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarTimesReceived,
  atlasCheck,
  atlasClock,
  atlasClose,
} from "@resource/atlas/icons";
import { strings } from "@resource/common";
import { useLogEvent } from "analytics";
import { GuideAuthRequiredButton } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__components/guide-auth/GuideAuthRequiredButton";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { useGuidePostId } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuidePostIdContext";
import { BlockError } from "client/components/generic/errors/BlockError";
import { Timestamp } from "client/components/generic/misc/Timestamp";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import {
  AvailabilityRequestForViewAvailabilityRequestFragment,
  GuideAvailabilityRequestStatus,
} from "generated/graphql-codegen/graphql";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

gql(`
fragment AvailabilityRequestForViewAvailabilityRequest on GuideAvailabilityRequest {
  id
  status
  suggestions {
    id
    startTime
    endTime
  }
  guidePost {
    id
    senderUser {
      id
      name
      imageUrl
    }
    createdAt
    href
  }
  createdBy {
    id
    name
    imageUrl
  }
  completedBySubmission {
    id
    createdAt
    createdBy {
      id
      name
      imageUrl
    }
  }
}
`);

// component
// ---------

type ViewAvailabilityRequestProps = {
  className?: string;
  availabilityRequest?: AvailabilityRequestForViewAvailabilityRequestFragment;
  onShareAvailabilityClick?: () => unknown;
  variant: "candidate" | "company";
  loading?: boolean;
  error?: boolean;
  openViewDialogOnLoad?: boolean;
};

export function ViewAvailabilityRequest({
  className,
  availabilityRequest,
  onShareAvailabilityClick,
  variant,
  loading,
  error,
  openViewDialogOnLoad,
}: ViewAvailabilityRequestProps) {
  const { isGuideCandidate } = useGuideAuthContext();
  const isOpen =
    availabilityRequest?.status === GuideAvailabilityRequestStatus.OPEN;
  const router = useRouter();
  const guidePostIdContext = useGuidePostId();

  const shouldOpenForPost = useMemo(() => {
    // NOTE: This doesn't handle if there are two of the same nested request in a thread where the root guide post is not the availability
    // request.
    // This is an edge case but will result in double opening
    // This solves the most common cases for now though

    const rootGuidePostId = router.query.messageId;
    const nestedGuidePostId = window.location.hash.replace("#", "");

    if (guidePostIdContext) {
      if (
        nestedGuidePostId &&
        rootGuidePostId !== availabilityRequest?.guidePost?.id &&
        nestedGuidePostId === guidePostIdContext
      ) {
        // If it's a nested request and the root post is not the post for this availability request
        // It means this is a separate request and can auto open (there should only be one)
        return true;
      }

      if (rootGuidePostId && guidePostIdContext === rootGuidePostId) {
        // If the root post is the post for this availability request
        // Only open it for the components that are part of the root post
        // This should get ignored for any follow-ups with the same requestId so that we don't double open
        return true;
      }
    }

    return false;
  }, [
    availabilityRequest?.guidePost?.id,
    guidePostIdContext,
    router.query.messageId,
  ]);

  const openSubmitAvailability = useEvent(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (
      !loading &&
      openViewDialogOnLoad &&
      isOpen &&
      urlParams.get("submitAvailability") === availabilityRequest?.id &&
      shouldOpenForPost
    ) {
      onShareAvailabilityClick?.();
    }
  });

  useEffect(() => {
    if (isGuideCandidate) {
      openSubmitAvailability();
    }
  }, [
    openSubmitAvailability,
    loading,
    availabilityRequest?.id,
    isGuideCandidate,
  ]);

  if (loading) {
    return (
      <div className="w-full shadow-1 rounded-md p-3 space-y-3">
        <div className="space-y-2">
          <div className="flex">
            <Skeleton type="circle" className="w-10 h-10" />
            <Skeleton
              type="circle"
              className="w-10 h-10 -ml-2 ring-2 ring-white"
            />
          </div>
          <div className="space-y-[.1875rem]">
            <Skeleton className="w-1/2 h-[20px]" type="text" />
            <Skeleton className="w-full h-[14px]" type="text" />
            <Skeleton className="w-4/5 h-[14px]" type="text" />
          </div>
          <Skeleton className="w-[10rem] h-[32px]" type="rect" />
        </div>
      </div>
    );
  }

  if (error || !availabilityRequest) {
    return (
      <BlockError
        label="Can't load availability request"
        showCta={variant === "company"}
      />
    );
  }

  const { createdBy } = availabilityRequest;

  return (
    <div
      className={clsx(
        "space-y-3 w-full shadow-1 rounded-md border-light-gray p-4",
        className
      )}
    >
      <div className="space-y-2">
        <div className="flex">
          <div className="w-10 h-10 bg-blue-50 flex items-center justify-center rounded-full">
            <Icon
              content={atlasCalendarTimesReceived}
              className="w-5 h-5 text-blue-500"
            />
          </div>
          <Avatar
            name={createdBy.name}
            image={createdBy.imageUrl}
            size="large"
            className="ring-2 ring-white"
            style={{
              marginLeft: "-.25rem",
            }}
          />
        </div>
        <div className="space-y-1">
          <p className="text-h4 text-dark">
            {createdBy.name} is asking to see your availability
          </p>
          <p className="text-body-sm text-subtle">
            Let {strings.splitName(createdBy.name).firstName} know when
            you&apos;re free to meet. This will help them find the best time for
            your upcoming interviews.
          </p>
        </div>
      </div>
      <ViewAvailabilityRequestButton
        variant={variant}
        status={availabilityRequest.status}
        onShareAvailabilityClick={onShareAvailabilityClick}
        completedBySubmission={availabilityRequest.completedBySubmission}
      />
    </div>
  );
}

function ViewAvailabilityRequestButton({
  variant,
  status,
  onShareAvailabilityClick,
  completedBySubmission,
}: {
  variant: "candidate" | "company";
  status: GuideAvailabilityRequestStatus;
  onShareAvailabilityClick?: () => void;
  completedBySubmission?: {
    createdAt: string;
    createdBy: {
      name: string;
    };
  } | null;
}) {
  const logEvent = useLogEvent({
    component: "ViewAvailabilityRequestButton",
  });
  const isOpen = status === GuideAvailabilityRequestStatus.OPEN;
  const isArchived = status === GuideAvailabilityRequestStatus.ARCHIVED;
  const isCompleted = status === GuideAvailabilityRequestStatus.COMPLETED;

  if (variant === "candidate") {
    if (!isOpen) {
      if (isArchived) {
        return (
          <Button variant="danger" size="small" disabled icon={atlasClose}>
            Cancelled
          </Button>
        );
      }

      return (
        <Button variant="success" size="small" disabled icon={atlasCheck}>
          Shared
        </Button>
      );
    }

    return (
      <GuideAuthRequiredButton
        variant="primary"
        size="small"
        onClick={() => {
          logEvent("Share Availability Clicked");
          onShareAvailabilityClick?.();
        }}
      >
        Share your availability
      </GuideAuthRequiredButton>
    );
  }

  if (isCompleted) {
    return (
      <div className="space-y-4">
        <Button
          variant="success"
          size="small"
          icon={atlasCheck}
          className="pointer-events-none"
        >
          Complete
        </Button>
        {completedBySubmission ? (
          <p className="text-body-sm text-subtle">
            Completed by {completedBySubmission.createdBy.name}{" "}
            <Timestamp
              timestamp={completedBySubmission.createdAt}
              format="short"
            />
          </p>
        ) : (
          <p className="text-body-sm text-subtle">
            Request manually marked as complete
          </p>
        )}
      </div>
    );
  }

  if (isArchived) {
    return (
      <Button variant="danger" size="small" icon={atlasClose} disabled>
        Cancelled
      </Button>
    );
  }

  return (
    <Button variant="default" size="small" icon={atlasClock} disabled>
      Waiting for response
    </Button>
  );
}
