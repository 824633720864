import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useTimezone } from "client/timezones/useTimezone";
import {
  formatExactTimestamp,
  formatTimestamp,
  FormatTimestampProps,
} from "client/utils/dates";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

export function Timestamp({
  timestamp,
  format,
  disableRelative,
  ...rest
}: ComponentPropsWithoutRef<"span"> &
  Pick<FormatTimestampProps, "format" | "disableRelative"> & {
    timestamp: string | undefined | null;
  }) {
  const timezone = useTimezone();

  if (!timestamp) {
    return <div>-</div>;
  }

  return (
    <Tooltip content={formatExactTimestamp({ date: timestamp, timezone })}>
      <span {...rest} className={clsx("truncate", rest.className)}>
        {formatTimestamp({
          date: timestamp,
          timezone,
          format,
          disableRelative,
        })}
      </span>
    </Tooltip>
  );
}
