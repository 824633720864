/* eslint-disable import/prefer-default-export */
import type { ContentEditorModuleConfig } from "@resource/atlas";

import type { AtlasContentEditorModuleWithConfig } from "./types";

export function withConfig<Id extends keyof ContentEditorModuleConfig>(
  module: AtlasContentEditorModuleWithConfig<Id>[0],
  config: AtlasContentEditorModuleWithConfig<Id>[1]
): AtlasContentEditorModuleWithConfig<Id> {
  return [module, config];
}
