import { useFilteredInterviewRequirements } from "client/utils/interviewRequirements";
import { useMemo } from "react";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";

import { AlgorithmSettingsErrors, InterviewRequirement } from "./types";

/**
 * Some legacy panel settings are not stored on the InterviewPanelRequirement, but on each interviewRequirement.
 * If all interviewRequirements have the same value for a given setting, we treat it as a panel requirement.
 *
 * - requireVideoConferencing
 * - requireConferenceRoom
 * - defaultPrivate
 */
export function useLegacyInterviewPanelRequirements({
  interviewRequirements,
}: {
  interviewRequirements: InterviewRequirement[];
}) {
  const requireVideoConferencing = useMemo(() => {
    if (interviewRequirements.length === 0) {
      return null;
    }

    const first = interviewRequirements[0].videoConferencingSettings?.service;

    if (!first) {
      return null;
    }

    if (
      interviewRequirements.every(
        (ir) => ir.videoConferencingSettings?.service === first
      )
    ) {
      return first;
    }

    return null;
  }, [interviewRequirements]);

  const requireConferenceRoom = useMemo(() => {
    if (interviewRequirements.length === 0) {
      return null;
    }
    if (!interviewRequirements[0].conferenceRoomSettings?.required) {
      return null;
    }

    const first = interviewRequirements[0].conferenceRoomSettings?.building?.id;
    if (
      interviewRequirements.every(
        (ir) => ir.conferenceRoomSettings?.building?.id === first
      )
    ) {
      return interviewRequirements[0].conferenceRoomSettings;
    }

    return null;
  }, [interviewRequirements]);

  const defaultPrivate = useMemo(() => {
    if (interviewRequirements.length === 0) {
      return false;
    }

    if (interviewRequirements.every((ir) => ir.isPrivate)) {
      return true;
    }

    return false;
  }, [interviewRequirements]);

  return useMemo(
    () => ({
      requireVideoConferencing,
      requireConferenceRoom,
      defaultPrivate,
    }),
    [requireVideoConferencing, requireConferenceRoom, defaultPrivate]
  );
}

/**
 * Find any problems with the algorithm settings. Does not require the minimum/maximum number of slots to match the interview count exactly,
 * because we want to allow some flexibility e.g. if we have 5 interviews on the job stage and their preferences are
 * "across 2 days with 3 interviews per day", we can schedule 2 interviews on the first day and 3 on the second.
 */
export function useAlgorithmSettingsErrors({
  algorithmSettings,
  interviewRequirements,
}: {
  algorithmSettings: UserConfigurableAlgorithmSettings;
  interviewRequirements: {
    id: string;
    manuallyRemovedAt: string | null;
  }[];
}): AlgorithmSettingsErrors | null {
  const { interviewRequirements: filteredInterviewRequirements } =
    useFilteredInterviewRequirements({ interviewRequirements });
  const interviewCount = filteredInterviewRequirements.length;

  const { numberOfDays, interviewsPerDayLimit } = algorithmSettings;

  const minNumberOfDaysError = useMemo(() => {
    if (!numberOfDays) {
      return null;
    }

    if (Number.isNaN(numberOfDays.min)) {
      return "Must be a valid number";
    }
    if (numberOfDays.min < 1) {
      return "Must be greater than 0";
    }
    if (numberOfDays.min > interviewCount) {
      return `Must be less than or equal to ${interviewCount}`;
    }
    if (numberOfDays.min > numberOfDays.max) {
      return "Must be less than or equal to maximum days";
    }

    return null;
  }, [numberOfDays, interviewCount]);

  const maxNumberOfDaysError = useMemo(() => {
    if (!numberOfDays) {
      return null;
    }

    if (Number.isNaN(numberOfDays.max)) {
      return "Must be a valid number";
    }
    if (numberOfDays.max < 1) {
      return "Must be greater than 0";
    }
    if (numberOfDays.max > interviewCount) {
      return `Must be less than or equal to ${interviewCount}`;
    }

    return null;
  }, [numberOfDays, interviewCount]);

  const minInterviewsPerDayError = useMemo(() => {
    if (!interviewsPerDayLimit) {
      return null;
    }

    if (Number.isNaN(interviewsPerDayLimit.min)) {
      return "Must be a valid number";
    }
    if (interviewsPerDayLimit.min < 1) {
      return "Must be greater than 0";
    }
    if (interviewsPerDayLimit.min > interviewCount) {
      return `Must be less than or equal to ${interviewCount}`;
    }
    if (interviewsPerDayLimit.min > interviewsPerDayLimit.max) {
      return "Must be less than or equal to maximum interviews per day";
    }

    return null;
  }, [interviewsPerDayLimit, interviewCount]);

  const maxInterviewsPerDayError = useMemo(() => {
    if (!interviewsPerDayLimit) {
      return null;
    }

    if (Number.isNaN(interviewsPerDayLimit.max)) {
      return "Must be a valid number";
    }
    if (interviewsPerDayLimit.max < 1) {
      return "Must be greater than 0";
    }
    if (interviewsPerDayLimit.max > interviewCount) {
      return `Must be less than or equal to ${interviewCount}`;
    }

    return null;
  }, [interviewsPerDayLimit, interviewCount]);

  if (
    // If only 1 interview, we don't need to check the algorithm settings.
    interviewCount < 2 ||
    (!minNumberOfDaysError &&
      !maxNumberOfDaysError &&
      !minInterviewsPerDayError &&
      !maxInterviewsPerDayError)
  ) {
    return null;
  }

  return {
    minNumberOfDays: minNumberOfDaysError,
    maxNumberOfDays: maxNumberOfDaysError,
    minInterviewsPerDay: minInterviewsPerDayError,
    maxInterviewsPerDay: maxInterviewsPerDayError,
  };
}
