import { useMenuStore } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasChevronDown,
  atlasClose,
  atlasFilter,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu-v2";
import { strings } from "@resource/common";
import clsx from "clsx";
import { TagFilterType } from "generated/graphql-codegen/graphql";
import { useEffect, useMemo } from "react";

import { TagFilterMenuButton } from "./TagFilterMenuButton";
import { TagPicker } from "./TagPicker";
import { TagFilterTag, TagFilterTagGroup } from "./types";
import { filterTypeToLabel } from "./utils/display";

type TagFilterProps = {
  tags: TagFilterTag[];
  tagGroups: TagFilterTagGroup[];
  selectedTags: TagFilterTag[];
  onRemoveTag: (tag: TagFilterTag) => void;
  onClearAll: () => void;
  onTagSelect: (tag: TagFilterTag) => void;
  onFilterChange: (type: TagFilterType) => void;
  filterType: TagFilterType;
  defaultOpen?: boolean;
};

export function TagFilter({
  tags,
  tagGroups,
  selectedTags,
  onTagSelect,
  onRemoveTag,
  onClearAll,
  onFilterChange,
  filterType,
  defaultOpen,
}: TagFilterProps) {
  const filterTypeStore = useMenuStore();
  const tagMenuStore = useMenuStore();

  useEffect(() => {
    if (defaultOpen) {
      tagMenuStore.show();
    }
  }, [defaultOpen, tagMenuStore]);

  const filterMenuItems = useMemo(
    () => [
      {
        key: TagFilterType.INCLUDE_ALL,
        children: filterTypeToLabel[TagFilterType.INCLUDE_ALL],
        isSelected: filterType === TagFilterType.INCLUDE_ALL,
        onClick: () => onFilterChange(TagFilterType.INCLUDE_ALL),
      },
      {
        key: TagFilterType.INCLUDE_ANY,
        children: filterTypeToLabel[TagFilterType.INCLUDE_ANY],
        isSelected: filterType === TagFilterType.INCLUDE_ANY,
        onClick: () => onFilterChange(TagFilterType.INCLUDE_ANY),
      },
      {
        key: TagFilterType.EXCLUDE_IF_ANY,
        children: filterTypeToLabel[TagFilterType.EXCLUDE_IF_ANY],
        isSelected: filterType === TagFilterType.EXCLUDE_IF_ANY,
        onClick: () => onFilterChange(TagFilterType.EXCLUDE_IF_ANY),
      },
      {
        key: TagFilterType.EXCLUDE_IF_ALL,
        children: filterTypeToLabel[TagFilterType.EXCLUDE_IF_ALL],
        isSelected: filterType === TagFilterType.EXCLUDE_IF_ALL,
        onClick: () => onFilterChange(TagFilterType.EXCLUDE_IF_ALL),
      },
    ],
    [filterType, onFilterChange]
  );

  const filterTypeLabel = `${filterTypeToLabel[filterType]}:`;

  const selectedTagColors = useMemo(() => {
    const colorMap = new Map<string, number>();
    selectedTags.forEach((tag) => {
      const color = tag.tagGroup?.color || tag.color;
      colorMap.set(color, (colorMap.get(color) || 0) + 1);
    });
    return Array.from(colorMap.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([color]) => color);
  }, [selectedTags]);

  return (
    <div className="flex">
      <div className="flex gap-x-0 rounded-md border border-gray-border overflow-hidden divide-x divide-gray-border">
        {/* Filter Type Selector */}
        <Menu.Root store={filterTypeStore}>
          <TagFilterMenuButton
            icon={atlasFilter}
            className="text-body-xs px-2 py-1 gap-2 group bg-white text-dark"
          >
            <div className="flex items-center gap-x-.5">
              {filterTypeLabel}
              <Icon content={atlasChevronDown} />
            </div>
          </TagFilterMenuButton>
          <Menu.Menu className="p-0">
            {filterMenuItems.map((item) => (
              <Menu.Item
                key={String(item.key)}
                isSelectable
                isSelected={item.isSelected}
                onClick={item.onClick}
              >
                {item.children}
              </Menu.Item>
            ))}
          </Menu.Menu>
        </Menu.Root>

        {/* Selected Tags */}
        <TagPicker
          tags={tags}
          tagGroups={tagGroups}
          selectedTags={selectedTags}
          onTagSelect={(tag) => {
            if (selectedTags.some((t) => t.id === tag.id)) {
              onRemoveTag(tag);
            } else {
              onTagSelect(tag);
            }
          }}
          menuStore={tagMenuStore}
          className="gap-x-1 px-2 py-1 bg-white"
        >
          <span className="flex ml-1 mr-1.5">
            {selectedTagColors.map((color) => (
              <div
                key={color}
                className="h-2 w-2 rounded-full border border-white -mr-[.1875rem]"
                style={{ backgroundColor: color }}
              />
            ))}
          </span>
          <span className="text-body-xs text-dark">
            {strings.pluralize("tag", selectedTags.length)}
          </span>
        </TagPicker>

        {/* Clear Button */}
        <button
          type="button"
          onClick={onClearAll}
          className={clsx(
            "flex items-center justify-center px-2 bg-white",
            "hover:bg-light-gray-200"
          )}
        >
          <Icon content={atlasClose} className="text-subtle w-4 h-4" />
        </button>
      </div>
    </div>
  );
}
