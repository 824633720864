import { without } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

import { MonthCalendarDisplay } from "./MonthCalendarDisplay";
import { DayWithMetadata } from "./utils/types";

type MonthCalendarBaseProps = {
  allowedDays?: DateTime[];
  allowPast?: boolean;
  leftActions?: React.ReactNode;
};

type MonthCalendarSingleProps = MonthCalendarBaseProps & {
  multiSelect?: false;
  selected: DateTime | null;
  onSelect: (selectedDay: DateTime) => void;
};

type MonthCalendarMultiSelectProps = MonthCalendarBaseProps & {
  multiSelect: true;
  selected: DateTime[];
  onSelect: (selectedDays: DateTime[]) => void;
};

type MonthCalendarProps =
  | MonthCalendarSingleProps
  | MonthCalendarMultiSelectProps;

export function MonthCalendar(props: MonthCalendarProps) {
  const {
    allowedDays,
    leftActions,
    allowPast,
    selected,
    multiSelect,
    onSelect,
  } = props;

  const defaultDate = useMemo(
    () => (Array.isArray(selected) ? selected[0] : selected ?? undefined),
    [selected]
  );

  const selectedDays = useMemo(() => {
    if (multiSelect) {
      return selected;
    }

    return selected ? [selected] : [];
  }, [multiSelect, selected]);
  const onClickDay = useCallback(
    (selectedDay: DateTime) => {
      const currIndex = selectedDays.findIndex((d) =>
        d.hasSame(selectedDay, "day")
      );

      if (multiSelect) {
        if (currIndex >= 0) {
          onSelect(without(selectedDays, selectedDays[currIndex]));
        } else {
          onSelect([...selectedDays, selectedDay]);
        }
      } else {
        onSelect(selectedDay);
      }
    },
    [selectedDays, multiSelect, onSelect]
  );

  const shouldHighlightDay = useCallback(
    (day: DayWithMetadata) => {
      return (
        !!allowedDays &&
        !!allowedDays.find((d) => d.hasSame(DateTime.fromISO(day.date), "day"))
      );
    },
    [allowedDays]
  );

  const shouldDisableDay = useCallback(
    (day: DayWithMetadata) => {
      return !allowPast && day.isInPast;
    },
    [allowPast]
  );

  const isSelectedDay = useCallback(
    (day: DayWithMetadata) => {
      return !!selectedDays.find((d) =>
        d.hasSame(DateTime.fromISO(day.date), "day")
      );
    },
    [selectedDays]
  );

  return (
    <MonthCalendarDisplay
      leftActions={leftActions}
      defaultDate={defaultDate}
      onClickDay={onClickDay}
      shouldHighlightDay={shouldHighlightDay}
      shouldDisableDay={shouldDisableDay}
      isSelectedDay={isSelectedDay}
    />
  );
}
