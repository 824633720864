// This file has been automatically generated.

export const txEmailStylesheet = `
<style>
.text-body-lg {
  font: 400 1rem/1.5rem 'Inter', sans-serif;
}
.text-body-md,.text-body-md-heavy {
  font: 400 .875rem/1.25rem 'Inter', sans-serif;
}
.text-body-md-heavy {
  font-weight: 500;
}
.text-body-sm {
  font: 400 .8125rem/1.125rem 'Inter', sans-serif;
}
.m-0 {
  margin: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-\\[12px\\] {
  margin-top: 12px;
  margin-bottom: 12px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 4px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mb-\\[12px\\] {
  margin-bottom: 12px;
}
.mb-\\[4px\\] {
  margin-bottom: 4px;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 4px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-\\[\\.5rem\\] {
  margin-top: .5rem;
}
.mt-\\[12px\\] {
  margin-top: 12px;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.hidden {
  display: none;
}
.h-12 {
  height: 48px;
}
.h-3 {
  height: 12px;
}
.h-4 {
  height: 16px;
}
.h-5 {
  height: 20px;
}
.h-8 {
  height: 32px;
}
.h-\\[2\\.5rem\\] {
  height: 2.5rem;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.w-150 {
  width: 600px;
}
.w-2 {
  width: 8px;
}
.w-3 {
  width: 12px;
}
.w-4 {
  width: 16px;
}
.w-5 {
  width: 20px;
}
.w-\\[2\\.5rem\\] {
  width: 2.5rem;
}
.w-\\[2\\.625rem\\] {
  width: 2.625rem;
}
.w-\\[4\\.875rem\\] {
  width: 4.875rem;
}
.w-\\[88px\\] {
  width: 88px;
}
.w-full {
  width: 100%;
}
.max-w-\\[384px\\] {
  max-width: 384px;
}
.max-w-full {
  max-width: 100%;
}
.max-w-xl {
  max-width: 288px;
}
.cursor-pointer {
  cursor: pointer;
}
.items-center {
  align-items: center;
}
.gap-2 {
  gap: 8px;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  margin-top: calc(8px * calc(1 - 0));
  margin-bottom: calc(8px * 0);
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.rounded {
  border-radius: 4px;
}
.rounded-\\[\\.25rem\\] {
  border-radius: .25rem;
}
.rounded-\\[8px\\] {
  border-radius: 8px;
}
.rounded-md {
  border-radius: 6px;
}
.rounded-b-md {
  border-bottom-right-radius: 6px;
}
.rounded-b-md,.rounded-l-md {
  border-bottom-left-radius: 6px;
}
.rounded-l-md,.rounded-t-md {
  border-top-left-radius: 6px;
}
.rounded-t-md {
  border-top-right-radius: 6px;
}
.border {
  border-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-light-gray-500 {
  border-color: #f0f3f5;
}
.bg-blue-500 {
  background-color: #006aff;
}
.bg-green-50 {
  background-color: #e5f7f2;
}
.bg-green-500 {
  background-color: #00b07b;
}
.bg-light-gray-100 {
  background-color: #f9fafb;
}
.bg-light-gray-200 {
  background-color: #f6f8f9;
}
.bg-light-gray-500 {
  background-color: #f0f3f5;
}
.bg-orange-500 {
  background-color: #ff6b27;
}
.bg-purple-50 {
  background-color: #edecfc;
}
.bg-purple-500 {
  background-color: #4f46e5;
}
.bg-red-50 {
  background-color: #feece9;
}
.bg-white {
  background-color: #ffffff;
}
.bg-yellow-50 {
  background-color: #fff8e5;
}
.bg-yellow-500 {
  background-color: #ffb800;
}
.p-0 {
  padding: 0;
}
.p-3 {
  padding: 12px;
}
.p-4 {
  padding: 16px;
}
.p-8 {
  padding: 32px;
}
.p-\\[12px\\] {
  padding: 12px;
}
.p-\\[16px\\] {
  padding: 16px;
}
.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-8 {
  padding-left: 32px;
  padding-right: 32px;
}
.px-\\[16px\\] {
  padding-left: 16px;
  padding-right: 16px;
}
.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-2\\.5 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.py-3\\.5 {
  padding-top: 14px;
  padding-bottom: 14px;
}
.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-\\[10px\\] {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pb-1 {
  padding-bottom: 4px;
}
.pb-12 {
  padding-bottom: 48px;
}
.pb-2 {
  padding-bottom: 8px;
}
.pb-3 {
  padding-bottom: 12px;
}
.pb-4 {
  padding-bottom: 16px;
}
.pb-6 {
  padding-bottom: 24px;
}
.pl-\\[12px\\] {
  padding-left: 12px;
}
.pl-\\[8px\\] {
  padding-left: 8px;
}
.pr-1 {
  padding-right: 4px;
}
.pr-1\\.5 {
  padding-right: 6px;
}
.pr-2 {
  padding-right: 8px;
}
.pr-3 {
  padding-right: 12px;
}
.pr-4 {
  padding-right: 16px;
}
.pr-\\[\\.125rem\\] {
  padding-right: .125rem;
}
.pt-1 {
  padding-top: 4px;
}
.pt-1\\.5 {
  padding-top: 6px;
}
.pt-2 {
  padding-top: 8px;
}
.pt-3 {
  padding-top: 12px;
}
.pt-4 {
  padding-top: 16px;
}
.pt-6 {
  padding-top: 24px;
}
.pt-\\[12px\\] {
  padding-top: 12px;
}
.pt-\\[4px\\] {
  padding-top: 4px;
}
.pt-\\[8px\\] {
  padding-top: 8px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-start {
  text-align: start;
}
.align-top {
  vertical-align: top;
}
.align-middle {
  vertical-align: middle;
}
.font-\\[Georgia\\2c \\'Bitstream_Charter\\'\\2c \\'Charis_SIL\\'\\2c Utopia\\2c \\'URW_Bookman_L\\'\\2c serif\\] {
  font-family: Georgia,'Bitstream Charter','Charis SIL',Utopia,'URW Bookman L',serif;
}
.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, "Segoe UI", sans-serif;
}
.text-\\[0\\.5625rem\\] {
  font-size: 0.5625rem;
}
.text-\\[0px\\] {
  font-size: 0px;
}
.text-\\[1\\.25rem\\] {
  font-size: 1.25rem;
}
.text-\\[16px\\] {
  font-size: 16px;
}
.text-\\[20px\\] {
  font-size: 20px;
}
.text-base {
  font-size: 14px;
}
.text-sm {
  font-size: 13px;
}
.text-xs {
  font-size: 11px;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.leading-0 {
  line-height: 0;
}
.leading-5 {
  line-height: 20px;
}
.leading-6 {
  line-height: 24px;
}
.leading-\\[0\\.6875rem\\] {
  line-height: 0.6875rem;
}
.leading-\\[100\\%\\] {
  line-height: 100%;
}
.leading-\\[14px\\] {
  line-height: 14px;
}
.leading-\\[24px\\] {
  line-height: 24px;
}
.leading-\\[30px\\] {
  line-height: 30px;
}
.leading-full {
  line-height: 100%;
}
.leading-none {
  line-height: 1;
}
.leading-normal {
  line-height: 1.5;
}
.tracking-\\[-0\\.03125rem\\] {
  letter-spacing: -0.03125rem;
}
.\\!text-\\[\\#004AE1\\] {
  color: #004AE1 !important;
}
.\\!text-\\[\\#008BFF\\] {
  color: #008BFF !important;
}
.\\!text-\\[\\#1A73E8\\] {
  color: #1A73E8 !important;
}
.\\!text-\\[\\#5C6BC0\\] {
  color: #5C6BC0 !important;
}
.\\!text-\\[\\#CC4E41\\] {
  color: #CC4E41 !important;
}
.\\!text-dark {
  color: #231f32 !important;
}
.text-accent {
  color: #ff2e57;
}
.text-dark,.text-dark-gray-500 {
  color: #231f32;
}
.text-gray-100 {
  color: #afb8bf;
}
.text-gray-500 {
  color: #7a8994;
}
.text-green-900 {
  color: #004631;
}
.text-purple-500 {
  color: #4f46e5;
}
.text-purple-900 {
  color: #201c5c;
}
.text-red-900 {
  color: #61190e;
}
.text-subtle {
  color: #7a8994;
}
.text-white {
  color: #ffffff;
}
.text-yellow-900 {
  color: #664a00;
}
.line-through {
  text-decoration-line: line-through;
}
.\\!no-underline {
  text-decoration-line: none !important;
}
.no-underline {
  text-decoration-line: none;
}
.filter {
  filter:                  ;
}
.font-atlas-mono {
  font-family: 'Roboto Mono', monospace;
}
.mso-text-raise-4 {
  mso-text-raise: 16px;
}
.\\[-webkit-font-smoothing\\:antialiased\\] {
  -webkit-font-smoothing: antialiased;
}
.\\[border-collapse\\:collapse\\] {
  border-collapse: collapse;
}
.\\[border-collapse\\:separate\\] {
  border-collapse: separate;
}
.\\[border\\:0\\] {
  border: 0;
}
.\\[mso-table-lspace\\:0pt\\] {
  mso-table-lspace: 0pt;
}
.\\[mso-table-rspace\\:0pt\\] {
  mso-table-rspace: 0pt;
}
.\\[mso-text-raise\\:8px\\] {
  mso-text-raise: 8px;
}
.\\[text-decoration\\:none\\] {
  text-decoration: none;
}
.\\[word-break\\:break-word\\] {
  word-break: break-word;
}
.atlas-ContentEditor-Theme {
  font: 400 .875rem/1.25rem 'Inter', sans-serif;
  overflow-wrap: break-word;
}
.atlas-ContentEditor-Theme ol,.atlas-ContentEditor-Theme ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.atlas-ContentEditor-Theme h1,.atlas-ContentEditor-Theme h2,.atlas-ContentEditor-Theme h3,.atlas-ContentEditor-Theme h4,.atlas-ContentEditor-Theme h5,.atlas-ContentEditor-Theme h6 {
  font-size: inherit;
  font-weight: inherit;
}
.atlas-ContentEditor-Theme blockquote,.atlas-ContentEditor-Theme dl,.atlas-ContentEditor-Theme dd,.atlas-ContentEditor-Theme h1,.atlas-ContentEditor-Theme h2,.atlas-ContentEditor-Theme h3,.atlas-ContentEditor-Theme h4,.atlas-ContentEditor-Theme h5,.atlas-ContentEditor-Theme h6,.atlas-ContentEditor-Theme hr,.atlas-ContentEditor-Theme figure,.atlas-ContentEditor-Theme p,.atlas-ContentEditor-Theme pre {
  margin: 0;
}
.atlas-ContentEditor-Theme a {
  text-decoration: none;
}
.atlas-ContentEditor-Theme .bold-format {
  font-weight: 600;
}
.atlas-ContentEditor-Theme .italic-format {
  font-style: italic;
}
.atlas-ContentEditor-Theme .underline-format {
  text-decoration-line: underline;
}
.atlas-ContentEditor-Theme .paragraph {
  font: 400 .875rem/1.25rem 'Inter', sans-serif;
}
.atlas-ContentEditor-Theme .h1 {
  font: 700 1.25rem/1.5rem 'Inter', sans-serif;
}
.atlas-ContentEditor-Theme .h2,.atlas-ContentEditor-Theme .h3 {
  font: 700 1rem/1.25rem 'Inter', sans-serif;
}
.atlas-ContentEditor-Theme .link {
  cursor: pointer;
  border-radius: .25rem;
  color: #4f46e5;
}
.atlas-ContentEditor-Theme .link:hover {
  text-decoration-line: underline;
}
.atlas-ContentEditor-Theme .link:focus-visible {
  text-decoration-line: underline;
}
.atlas-ContentEditor-Theme .link:focus-visible {
  outline: .125rem solid #4f46e5;
  outline-offset: .125rem;
}
.atlas-ContentEditor-Theme .list-item {
  margin-left: 1.375rem;
}
.atlas-ContentEditor-Theme .list-nested-item {
  list-style-type: none;
}
.atlas-ContentEditor-Theme .list-unordered {
  list-style-type: disc;
}
.atlas-ContentEditor-Theme .list-ordered {
  list-style-type: decimal;
}
.atlas-ContentEditor-Theme .list-ordered .list-ordered {
  list-style-type: lower-alpha;
}
.atlas-ContentEditor-Theme .list-ordered .list-ordered .list-ordered {
  list-style-type: decimal;
}
.atlas-ContentEditor-Theme hr {
  border-bottom-width: 0px;
  border-style: solid;
  border-color: #eeedef;
}
.atlas-ContentEditor-Theme hr.selected {
  border-color: #006aff;
}
.atlas-ContentEditor-Theme .paragraph,.atlas-ContentEditor-Theme .h1,.atlas-ContentEditor-Theme .h2,.atlas-ContentEditor-Theme .h3,.atlas-ContentEditor-Theme .list-unordered,.atlas-ContentEditor-Theme .list-ordered,.atlas-ContentEditor-Theme hr:not(.does-not-exist) {
  margin-top: .75rem;
  margin-bottom: .75rem;
}
.atlas-ContentEditor-Theme .h1 {
  margin-top: 1.25rem;
}
.atlas-ContentEditor-Theme .h2,.atlas-ContentEditor-Theme .h3 {
  margin-top: 1rem;
}
.atlas-ContentEditor-Theme .list-unordered,.atlas-ContentEditor-Theme .list-ordered {
  margin-top: .75rem;
  margin-bottom: .75rem;
}
.atlas-ContentEditor-Theme .list-unordered .list-unordered,.atlas-ContentEditor-Theme .list-unordered .list-ordered,.atlas-ContentEditor-Theme .list-ordered .list-unordered,.atlas-ContentEditor-Theme .list-ordered .list-ordered {
  margin-top: 0;
  margin-bottom: 0;
}
.atlas-ContentEditor-Theme .list-item {
  margin-top: .25rem;
  margin-bottom: .25rem;
}
.atlas-ContentEditor-Theme .paragraph:first-child,.atlas-ContentEditor-Theme .h1:first-child,.atlas-ContentEditor-Theme .h2:first-child,.atlas-ContentEditor-Theme .h3:first-child,.atlas-ContentEditor-Theme .list-unordered:first-child,.atlas-ContentEditor-Theme .list-ordered:first-child,.atlas-ContentEditor-Theme .list-item:first-child {
  margin-top: 0;
}
.atlas-ContentEditor-Theme .paragraph:last-child,.atlas-ContentEditor-Theme .h1:last-child,.atlas-ContentEditor-Theme .h2:last-child,.atlas-ContentEditor-Theme .h3:last-child,.atlas-ContentEditor-Theme .list-unordered:last-child,.atlas-ContentEditor-Theme .list-ordered:last-child,.atlas-ContentEditor-Theme .list-item:last-child {
  margin-bottom: 0;
}
.atlas-ContentEditor-Theme .block-cursor {
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.atlas-ContentEditor-Theme .block-cursor::after {
  position: absolute;
  top: -0.375rem;
  width: 100%;
  border-top-width: 2px;
  border-top-color: #006aff;
  content: "";
}
.atlas-ContentEditor-Theme .theme-chat .paragraph {
  font: 400 .8125rem/1.125rem 'Inter', sans-serif;
  overflow-wrap: break-word;
}
.desktop-only {
  display: none;
}

.gc-column-spacer-before {
  padding-left: 8px;
}

.gc-column-spacer-after {
  padding-right: 8px;
}
:root {
  color-scheme: light;
}
.hover\\:bg-blue-700:hover {
  background-color: #0060e6;
}
.hover\\:bg-light-gray-600:hover {
  background-color: #e8ebed;
}
.hover\\:bg-purple-600:hover {
  background-color: #4b42d9;
}
.hover\\:underline:hover {
  text-decoration-line: underline;
}
</style>
<style media="screen and (min-width: 480px)" type="text/css">
  
@media screen and (min-width: 480px) {
  .gc-column-per-50 {
    width: 50% !important;
    max-width: 50% !important;
  }
  .gc-column-spacer-before {
    padding-left: 8px !important;
  }
  .gc-column-spacer-after {
    padding-right: 8px !important;
  }
}
</style>
<style media="screen and (max-width: 480px)" type="text/css">
  
@media screen and (max-width: 480px) {
  .gc-column-per-50 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .gc-column-spacer-before {
    padding-left: 0px !important;
  }
  .gc-column-spacer-after {
    padding-right: 0px !important;
  }
}
</style>
<style media="screen and (min-width: 600px)" type="text/css">
  

@media screen and (min-width: 600px) {
  .desktop-only {
    display: block !important;
  }
  u + .body .gmail__p-0 {
    padding: 0 !important;
  }
  u + .body .gmail__px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  u + .body .gmail__py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
<style media="screen and (max-width: 600px)" type="text/css">
  
@media screen and (max-width: 600px) {
  u + .body .gmail__w-full {
    width: 100% !important;
    width: 100vw !important;
  }
  u + .body .gmail__sm-p-0 {
    padding: 0 !important;
  }
  u + .body .gmail__sm-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  u + .body .gmail__sm-py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
<style media="(max-width: 600px)" type="text/css">
  
@media (max-width: 600px) {
    .sm-block {
        display: block !important
    }
    .sm-h-0 {
        height: 0 !important
    }
    .sm-h-4 {
        height: 16px !important
    }
    .sm-h-8 {
        height: 32px !important
    }
    .sm-w-full {
        width: 100% !important
    }
    .sm-rounded-none {
        border-radius: 0px !important
    }
    .sm-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .sm-px-4 {
        padding-left: 16px !important;
        padding-right: 16px !important
    }
    .sm-py-4 {
        padding-top: 16px !important;
        padding-bottom: 16px !important
    }
    .sm-py-8 {
        padding-top: 32px !important;
        padding-bottom: 32px !important
    }
    .sm-pl-0 {
        padding-left: 0 !important
    }
    .sm-pr-0 {
        padding-right: 0 !important
    }
}
</style>
`;
