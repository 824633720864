/**
 * Calendar data is computed data that is mapped from the inputs and settings.
 * Mapping logic lives in `utils/mapping.ts` and is called in the
 * Context provider right now.
 * Everything should be accessed via hooks so that the internals can easily change.
 */
import { useMemo } from "react";

import { useCalendarContext } from "../utils/context";
import {
  CalendarColorsConfig,
  CalendarEvent,
  CalendarEventInput,
} from "../utils/types";

export type CalendarDataInput = {
  events: CalendarEventInput[];
  calendarColors?: CalendarColorsConfig;
};

export type CalendarData = {
  events: CalendarEvent[];
  backgroundEvents: CalendarEvent[];
  eventGroups: CalendarEvent[][];
  eventGroupsByCalendar: { [calendarId: string]: CalendarEvent[][] };
  allDayEventGroups: CalendarEvent[][];
  allDayEventGroupsByCalendar: { [calendarId: string]: CalendarEvent[][] };
  allDayEvents: CalendarEvent[];
  allCalendarIds: string[];
};

export function useCalendarData(): CalendarData {
  const {
    events,
    backgroundEvents,
    eventGroups,
    allDayEventGroups,
    eventGroupsByCalendar,
    allDayEvents,
    allDayEventGroupsByCalendar,
    allCalendarIds,
  } = useCalendarContext();

  return useMemo(
    () => ({
      events,
      backgroundEvents,
      eventGroups,
      allDayEventGroups,
      eventGroupsByCalendar,
      allDayEventGroupsByCalendar,
      allDayEvents,
      allCalendarIds,
    }),
    [
      events,
      backgroundEvents,
      eventGroups,
      allDayEventGroups,
      eventGroupsByCalendar,
      allDayEventGroupsByCalendar,
      allDayEvents,
      allCalendarIds,
    ]
  );
}
