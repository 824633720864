import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { AtlasMenuState, Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useMenuState } from "@resource/atlas/menu/use-menu-state";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { gql } from "generated/graphql-codegen";
import { InterviewerForSelectionFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import useQuery from "utils/useQuery";

gql(`
fragment SelectInterviewerMenuUserMembership on UserMembership {
  ...InterviewerForSelection
  tags {
    id
    name
  }
}
`);

const USERS_FOR_INTERVIEWER_SELECT_QUERY = gql(`
  query SelectInterviewerMenuQuery($search: String, $tags: [String!], $userMembershipIds: [String!], $isQualifiedForPools: Boolean, $limit: Int) {
    userMemberships(search: $search, tags: $tags, isQualifiedForPools: $isQualifiedForPools, userMembershipIds: $userMembershipIds, limit: $limit) {
      ...SelectInterviewerMenuUserMembership
    }
  }
`);

export function SelectInterviewerMenu({
  interviewerPoolIds,
  isQualifiedForPools,
  userMembershipIds,
  onSelect,
  children,
  menuState,
  setIsMenuOpen,
}: {
  interviewerPoolIds?: string[];
  userMembershipIds?: string[];
  isQualifiedForPools?: boolean;
  onSelect: (userMembership: InterviewerForSelectionFragment) => void;
  children?: React.ReactNode;
  menuState?: AtlasMenuState;
  setIsMenuOpen?: (isOpen: boolean) => void;
}) {
  const [search, setSearch] = useState("");
  const latestMenuState = useMenuState({
    ...menuState,
    searchable: true,
    sameWidth: true,
    comboboxProps: {
      setValue: setSearch,
    },
  });

  useEffect(() => {
    setIsMenuOpen?.(latestMenuState.open);
  }, [setIsMenuOpen, latestMenuState.open]);
  const { data, loading } = useQuery(USERS_FOR_INTERVIEWER_SELECT_QUERY, {
    variables: {
      search,
      isQualifiedForPools: isQualifiedForPools ?? null,
      userMembershipIds: userMembershipIds ?? null,
      tags: interviewerPoolIds ?? [],
      limit: 25,
    },
    skip: !latestMenuState.open,
    fetchPolicy: "cache-and-network",
  });

  const menuItems = useMenuItems(
    (i) =>
      data?.userMemberships.map((um) =>
        i.item({
          key: um.id,
          value: um.id,
          children: um.name,
          onClick: () => onSelect(um),
          render: (props) => {
            return (
              <OptionItem
                onClick={props.onClick}
                renderContent={() => (
                  <div className="flex gap-2 items-start">
                    <Avatar
                      size="xs"
                      image={um.imageUrl}
                      name={um.name}
                      className="shrink-0"
                    />
                    <div className="flex-grow text-body-md truncate">
                      <span className="truncate">{um.name}</span>{" "}
                      {um.user.timezone
                        ? `(${DateTime.now()
                            .setZone(um.user.timezone)
                            .toFormat("ZZZZ")}) `
                        : null}
                    </div>
                  </div>
                )}
              />
            );
          },
        })
      ) ?? [],
    [data, onSelect]
  );
  return (
    <Menu.Root state={latestMenuState}>
      <Menu.Trigger>
        {children ?? <Button>Select interviewer</Button>}
      </Menu.Trigger>
      <Menu.Content isLoading={loading} items={menuItems} />
    </Menu.Root>
  );
}
