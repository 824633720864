import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasCalendarRescheduleAlt, atlasTrash } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { GuideAuthRequiredButton } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__components/guide-auth/GuideAuthRequiredButton";

type SelfScheduleCardActionsProps = {
  onReschedule?: () => void;
  onCancel?: () => void;
  hideCancel?: boolean;
  hideReschedule?: boolean;
};

export function SelfScheduleCardActions({
  onReschedule,
  onCancel,
  hideCancel,
  hideReschedule,
}: SelfScheduleCardActionsProps) {
  return (
    <ButtonGroup>
      {onReschedule && !hideReschedule && (
        <Tooltip isInstant content="Reschedule interview">
          <GuideAuthRequiredButton
            icon={atlasCalendarRescheduleAlt}
            onClick={onReschedule}
            isGhost
            size="small"
          />
        </Tooltip>
      )}
      {onCancel && !hideCancel && (
        <Tooltip isInstant content="Cancel interview">
          <GuideAuthRequiredButton
            icon={atlasTrash}
            onClick={onCancel}
            isGhost
            size="small"
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );
}
