import { gql } from "generated/graphql-codegen";
import { SchedulingRequestForDetailsViewFragment } from "generated/graphql-codegen/graphql";
import { useEffect, useState } from "react";
import { AutomationStatus } from "shared/automation/automations/types";
import useMutation, { ToastDisplayCondition } from "utils/useMutation";

import { useIsAutomationEnabledForSchedulingRequest } from "./useIsAutomationEnabledForSchedulingRequest";

const RE_RUN_SCHEDULING_TASK_AUTOMATION_ORCHESTRATION = gql(`
  mutation ReRunSchedulingTaskAutomationOrchestration($input: RerunAutomationOrchestrationInput!) {
    rerunAutomationOrchestration(input: $input) {
      success
      code
      message
      automation {
        id
        lastRunAt
      }
    }
  }
`);
export function useReRunOrchestrationForTaskSchedulingAssistant({
  schedulingRequest,
}: {
  schedulingRequest: SchedulingRequestForDetailsViewFragment;
}) {
  const [hasRerun, setHasRerun] = useState(false);
  const [rerunAutomationOrchestration] = useMutation(
    RE_RUN_SCHEDULING_TASK_AUTOMATION_ORCHESTRATION,
    {
      displayToastsOn: ToastDisplayCondition.NONE,
      onCompleted: () => {
        setHasRerun(true);
      },
    }
  );

  const isAutomationEnabled = useIsAutomationEnabledForSchedulingRequest({
    schedulingRequest,
  });

  useEffect(() => {
    const automation = schedulingRequest?.schedulingTaskAssistantAutomation;
    const automationId = automation?.id;
    const lastRunAt = automation?.lastRunAt;

    if (
      !isAutomationEnabled ||
      !automationId ||
      !lastRunAt ||
      hasRerun ||
      // Don't bother re-orchestrating if the orchestrator won't run.
      automation.status !== AutomationStatus.RUNNING
    ) {
      return;
    }

    const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
    const lastRunDate = new Date(lastRunAt);

    if (lastRunDate < fiveMinutesAgo) {
      rerunAutomationOrchestration({
        variables: {
          input: {
            id: automationId,
          },
        },
      });
    }
  }, [
    schedulingRequest?.schedulingTaskAssistantAutomation,
    rerunAutomationOrchestration,
    hasRerun,
    isAutomationEnabled,
  ]);
}
