/* eslint-disable import/prefer-default-export */

import type { HTMLAttributes } from "../../__utils/html";
import { createOptionItemRenderFunction } from "../../option/utils";
import type { AtlasSelectOptionProps } from "../types";

/** Returns the props to be passed to the Ariakit `SelectItem` component. */
export function getOptionProps(
  p0: AtlasSelectOptionProps & { isSelected: boolean }
): HTMLAttributes<"div"> {
  const [children, props] = createOptionItemRenderFunction({
    ...p0,
    isSelectable: true,
    // TODO: maybe if we drop the "is" prefix convention this won't be necessary
    isDisabled: p0.disabled,
  });

  // @ts-expect-error - not properly typed in React 18
  return { ...props, children };
}
