import { Button as AriaButton } from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import Badge from "@resource/atlas/badge/Badge";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarEvent,
  atlasCircleChecked,
  atlasSchedulingTypeRescheduleColor,
  atlasSchedulingTypeScheduleColor,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import TextArea from "@resource/atlas/textfield/TextArea";
import { FooterProps, HeaderProps, View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { Toggle } from "client/app/__components/Toggle";
import { Checkbox } from "client/components/generic/inputs/Checkbox";
import { BackButton } from "client/components/generic/layout/BackButton";
import {
  ViewHeader,
  ViewHeaderProps,
} from "client/components/generic/layout/ViewHeader";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { AssigneeSelect } from "client/components/generic/select/AssigneeSelect";
import { PrioritySelect } from "client/components/generic/select/PrioritySelect";
import { CalendarIcon } from "client/components/guide/interviews/CalendarIcon";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { useFormattedDateTimesForInterviewItem } from "client/components/guide/interviews/utils";
import { ScheduledInterviewCardBadge } from "client/components/scheduled-interviews/__components/ScheduledInterviewCardBadge";
import { InterviewersSection } from "client/components/scheduled-interviews/ScheduledInterviewCard";
import { CurrentCandidateAvailability } from "client/guide-availability/components/CurrentCandidateAvailability";
import {
  InterviewRequirementsConfigurationContextData,
  InterviewRequirementsConfigurationView,
  useInterviewRequirementsConfigurationContext,
} from "client/scheduling-requirements-configuration/context";
import { EditIndividualInterviewRequirementForm } from "client/scheduling-requirements-configuration/EditIndividualInterviewRequirementForm";
import { SchedulingRequestAlgorithmSettings } from "client/scheduling-requirements-configuration/interviewPanelRequirement/SchedulingRequestAlgorithmSettings";
import { InterviewRequirementsList } from "client/scheduling-requirements-configuration/InterviewRequirementsList";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  GuideForCreateSchedulingRequestV2Fragment,
  ScheduledInterviewForScheduledInterviewCardFragment,
  SchedulingRequestAutoAssignOnAvailabilityReceiptType,
  SchedulingRequestForSelectingTaskFragment,
  SchedulingRequestPriority,
  SchedulingRequestStatus,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import { useEffect, useMemo, useState } from "react";
import { formatEntity } from "shared/constants/entities";
import { Event } from "shared/guide-availability/types";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { SchedulingRequestLocationSettings } from "../scheduling-requirements-configuration/interviewPanelRequirement/SchedulingRequestLocationSettings";
import { SchedulingRequestConferenceRoomSettings } from "./__components/SchedulingRequestConferenceRoomSettings";
import { SchedulingRequestConferencingSettings } from "./__components/SchedulingRequestConferencingSettings";
import { SchedulingRequestEventPrivacySettings } from "./__components/SchedulingRequestEventPrivacySettings";
import { useAllowCancelInterviewsDuringReschedule } from "./hooks/cancel-interviews-during-reschedule";
import {
  RescheduleRequestState,
  SchedulingRequestFormState,
} from "./hooks/useSchedulingRequestFormState";
import { WorkflowStateForGuide } from "./hooks/useWorkflowStateForGuide";
import { ManualCandidateAvailabilitySection } from "./ManualCandidateAvailabilitySection";

gql(`
  fragment SchedulingRequestForSelectingTask on SchedulingRequest {
    id
    status
    isReschedule
    assignedTo {
      id
      name
      imageUrl
    }
    interviewPanelRequirement {
      id
      interviewRequirements {
        id
        interview {
          id
          name
        }
      }
    }
  }
`);
gql(`
  fragment GuideForCreateSchedulingRequestV2 on Guide {
    id
    candidate {
      id
      name: internalName
      ...CandidateTimezoneSelectCandidate
    }
    atssyncApplication {
      id
      currentStage {
        id
        name
      }
    }
    job {
      id
      name: internalName
    }
    schedulingRequests {
      ...SchedulingRequestForSelectingTask
    }
    upcomingInterviews: internalUpcomingInterviews {
      id
      isV2
      interviewRequirementId
      schedulingRequestId
      ...ScheduledInterviewForScheduledInterviewCard
    }
    currentAvailabilitySubmission {
      id
      ...GuideAvailabilitySubmissionsSubmission
    }
    organization {
      id
      defaultSchedulingTaskAssignee 
      defaultSchedulingTaskAssigneeForReschedule
    }
  }
`);

export type CreateSchedulingRequestFormOnSubmitProps = {
  notes: InterviewRequirementsConfigurationContextData["notes"];
  manualAvailabilities: Event[];
  interviewRequirements: InterviewRequirementsConfigurationContextData["interviewRequirements"];
  autoAssignToQueue: false;
  autoAssignOnAvailabilityReceiptConfig: InterviewRequirementsConfigurationContextData["autoAssignOnAvailabilityReceiptConfig"];
  diffDescription?: string;
  assigneeId: string | null;
  priority: SchedulingRequestPriority;
  requireVideoConferencing?: VideoConferencingServiceType | null;
  reuseVideoConferencingLink?: boolean;
};

export type SchedulingRequestFormProps = {
  state: SchedulingRequestFormState;
  workflowState: WorkflowStateForGuide;
  guide: GuideForCreateSchedulingRequestV2Fragment;
  header: HeaderProps;
  viewHeader?: ViewHeaderProps;
  footer: FooterProps;
  assignmentOptionType?:
    | "pick-assignee"
    | "auto-assign-on-availability-received";
  isUpdating?: boolean;
  allowManualAvailability?: boolean;
  submitButtonText?: string;
};

export function SchedulingRequestForm({
  state,
  workflowState,
  guide,
  header,
  footer,
  assignmentOptionType = "pick-assignee",
  allowManualAvailability,
  viewHeader,
}: SchedulingRequestFormProps) {
  const { user } = useAuthContext();

  const {
    view,
    notes,
    setNotes,
    goBack,
    assignee,
    setAssignee,
    priority,
    setPriority,
    manualAvailabilityState,
    setManualAvailabilityState,
    formType,
    autoAssignOnAvailabilityReceiptConfig,
    setAutoAssignOnAvailabilityReceiptConfig,
    requireVideoConferencing,
    setRequireVideoConferencing,
    reuseVideoConferencingLink,
    setReuseVideoConferencingLink,
    location,
    setLocation,
    algorithmSettings,
    setAlgorithmSettings,
    requireConferenceRoom,
    setRequireConferenceRoom,
    updateInterviewRequirement,
    onRemoveInterviewerRequirement,
    selectedInterviewRequirement,
    defaultPrivate,
    setDefaultPrivate,
  } = state;

  const { interviewRequirements } =
    useInterviewRequirementsConfigurationContext();

  const autoAssignmentEnabled =
    autoAssignOnAvailabilityReceiptConfig.autoAssignType !==
    SchedulingRequestAutoAssignOnAvailabilityReceiptType.NO_REASSIGNMENT;

  const [manuallySelectedAssignee, setManuallySelectedAssignee] =
    useState(false);
  const [
    manuallySelectedAssigneeForAutoAssign,
    setManuallySelectedAssigneeForAutoAssign,
  ] = useState(false);

  const schedulingTasksForSelection = useMemo(() => {
    return guide.schedulingRequests.filter(
      (sr) => sr.status === SchedulingRequestStatus.IN_PROGRESS
    );
  }, [guide.schedulingRequests]);
  const currentAvailabilitySubmission = useMemo(() => {
    return guide.currentAvailabilitySubmission;
  }, [guide.currentAvailabilitySubmission]);

  const interviewRequirementsToReschedule = useMemo(() => {
    if (state.formType === "rescheduling") {
      return state.rescheduleRequestState
        .selectedRescheduleInterviewRequirementIds;
    }

    return [];
  }, [state]);

  const defaultAssigneeForTask = useMemo(() => {
    if (assignee || assignee === null) {
      // Never override an existing assignee.
      return undefined;
    }
    if (!workflowState.skipRequestingAvailability || manuallySelectedAssignee) {
      return undefined;
    }

    if (state.formType === "rescheduling") {
      return guide.organization.defaultSchedulingTaskAssigneeForReschedule;
    }

    return guide.organization.defaultSchedulingTaskAssignee;
  }, [
    assignee,
    guide.organization.defaultSchedulingTaskAssignee,
    guide.organization.defaultSchedulingTaskAssigneeForReschedule,
    manuallySelectedAssignee,
    state.formType,
    workflowState.skipRequestingAvailability,
  ]);

  const defaultAssigneeForTaskRequestAvailability = useMemo(() => {
    if (manuallySelectedAssigneeForAutoAssign) {
      return undefined;
    }

    if (state.formType === "rescheduling") {
      return guide.organization.defaultSchedulingTaskAssigneeForReschedule;
    }

    return guide.organization.defaultSchedulingTaskAssignee;
  }, [
    guide.organization.defaultSchedulingTaskAssignee,
    guide.organization.defaultSchedulingTaskAssigneeForReschedule,
    manuallySelectedAssigneeForAutoAssign,
    state.formType,
  ]);

  if (
    view !== InterviewRequirementsConfigurationView.LIST &&
    selectedInterviewRequirement
  ) {
    return (
      <View
        header={{
          leftActions: <BackButton onClick={goBack} />,
        }}
      >
        <EditIndividualInterviewRequirementForm
          interviewRequirement={selectedInterviewRequirement}
          onRemoveInterviewerRequirement={({ interviewerRequirementId }) => {
            onRemoveInterviewerRequirement({
              interviewerRequirementId,
              interviewRequirementId: selectedInterviewRequirement.id,
            });
          }}
          updateInterviewRequirement={(updatedInterview) =>
            updateInterviewRequirement(
              selectedInterviewRequirement.id,
              updatedInterview
            )
          }
        />
      </View>
    );
  }

  if (!user?.currentUserMembership) {
    return null;
  }

  return (
    <View header={header} footer={footer}>
      {viewHeader && <ViewHeader {...viewHeader} />}
      <div className="space-y-6">
        {state.formType === "select-task" ? (
          <div className="space-y-2">
            <p className="text-body-md-heavy">Select an open task</p>
            {schedulingTasksForSelection.map((schedulingRequest) => {
              const { selectTaskState } = state;
              const {
                setSelectedSchedulingRequestId,
                selectedSchedulingRequestId,
              } = selectTaskState;

              return (
                <SelectSchedulingTaskCard
                  key={schedulingRequest.id}
                  schedulingRequest={schedulingRequest}
                  onSelect={() =>
                    setSelectedSchedulingRequestId(schedulingRequest.id)
                  }
                  selected={
                    selectedSchedulingRequestId === schedulingRequest.id
                  }
                />
              );
            })}
          </div>
        ) : (
          <>
            {allowManualAvailability && (
              <div className="space-y-2">
                <div className="text-body-md-heavy">Candidate availability</div>
                <ManualCandidateAvailabilitySection
                  manualAvailabilityData={manualAvailabilityState}
                  setManualAvailabilityData={setManualAvailabilityState}
                  currentSubmission={currentAvailabilitySubmission}
                />
              </div>
            )}
            {!allowManualAvailability && currentAvailabilitySubmission && (
              <div className="space-y-2">
                <div className="text-body-md-heavy">Candidate availability</div>
                {currentAvailabilitySubmission && (
                  <CurrentCandidateAvailability
                    currentSubmission={currentAvailabilitySubmission}
                  />
                )}
              </div>
            )}
            {state.formType === "scheduling" && (
              <div className="space-y-3">
                <InterviewRequirementsList
                  jobId={guide.job.id}
                  atssyncJobStage={
                    guide.atssyncApplication?.currentStage ?? undefined
                  }
                  subtext="Accurate interview requirements allow Guide AI (and your coordinators)
          to find the best times for scheduling once availability is received."
                />
              </div>
            )}

            {formType === "scheduling" && (
              <SchedulingRequestAlgorithmSettings
                algorithmSettings={algorithmSettings}
                onAlgorithmSettingsChange={setAlgorithmSettings}
                interviewRequirements={interviewRequirements}
              />
            )}

            {formType === "scheduling" && (
              <div className="space-y-2">
                <p className="text-body-md-heavy">Settings</p>
                <SchedulingRequestConferencingSettings
                  requireVideoConferencing={requireVideoConferencing}
                  setRequireVideoConferencing={setRequireVideoConferencing}
                  reuseVideoConferencingLink={reuseVideoConferencingLink}
                  setReuseVideoConferencingLink={setReuseVideoConferencingLink}
                />
                <SchedulingRequestConferenceRoomSettings
                  requireConferenceRoom={requireConferenceRoom}
                  setRequireConferenceRoom={setRequireConferenceRoom}
                />
                <SchedulingRequestEventPrivacySettings
                  defaultPrivate={defaultPrivate}
                  setDefaultPrivate={setDefaultPrivate}
                />
                <SchedulingRequestLocationSettings
                  location={location}
                  setLocation={setLocation}
                />
              </div>
            )}

            {formType === "rescheduling" && (
              <ReschedulingFormFields state={state} />
            )}
            <div className="space-y-2">
              <div className="text-y-1">
                <p className="text-body-md-heavy">Assignee</p>
                {!workflowState.skipRequestingAvailability && (
                  <p className="text-body-sm text-subtle">
                    A scheduling task is created any time availability is
                    requested to track scheduling progress.
                  </p>
                )}
              </div>
              <AssigneeSelect
                assignee={assignee}
                onSelect={(newAssignee, isAutoAssign) => {
                  if (!isAutoAssign) {
                    setManuallySelectedAssignee(true);
                  }
                  setAssignee(
                    newAssignee ===
                      SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE
                      ? null
                      : newAssignee
                  );
                }}
                showSuggestedJobCoordinatorForGuideId={guide.id}
                showSuggestedOriginalAssigneesForInterviewRequirementIds={
                  interviewRequirementsToReschedule
                }
                defaultToSuggestedUser={defaultAssigneeForTask}
                variant="form"
              />
              {assignmentOptionType ===
                "auto-assign-on-availability-received" && (
                <div className="space-y-2">
                  <Checkbox
                    checked={autoAssignmentEnabled}
                    label="Automatically re-assign when availability is received"
                    onClick={() => {
                      if (!autoAssignmentEnabled) {
                        setAutoAssignOnAvailabilityReceiptConfig({
                          autoAssignType:
                            SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE,
                        });
                      } else {
                        setAutoAssignOnAvailabilityReceiptConfig({
                          autoAssignType:
                            SchedulingRequestAutoAssignOnAvailabilityReceiptType.NO_REASSIGNMENT,
                        });
                      }
                    }}
                  />
                  {autoAssignOnAvailabilityReceiptConfig.autoAssignType !==
                    SchedulingRequestAutoAssignOnAvailabilityReceiptType.NO_REASSIGNMENT && (
                    <div className="ml-6 space-y-2">
                      <p className="text-body-md-heavy">Re-assign to</p>
                      <AssigneeSelect
                        assignee={
                          autoAssignOnAvailabilityReceiptConfig.autoAssignType ===
                          SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE
                            ? undefined
                            : autoAssignOnAvailabilityReceiptConfig.assignToUserMembership ??
                              undefined
                        }
                        onSelect={(newAssignee, isAutoAssign) => {
                          if (!newAssignee) {
                            // Disabling auto-assignment should be done with the checkbox above
                            return;
                          }

                          if (!isAutoAssign) {
                            setManuallySelectedAssigneeForAutoAssign(true);
                          }

                          const assignToQueue =
                            newAssignee ===
                            SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE;
                          setAutoAssignOnAvailabilityReceiptConfig({
                            autoAssignType: assignToQueue
                              ? SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE
                              : SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_USER,
                            // eslint-disable-next-line no-nested-ternary
                            assignToUserMembership: assignToQueue
                              ? null
                              : typeof newAssignee === "object"
                              ? newAssignee
                              : null,
                          });
                        }}
                        showSuggestedJobCoordinatorForGuideId={guide.id}
                        showSuggestedOriginalAssigneesForInterviewRequirementIds={
                          interviewRequirementsToReschedule
                        }
                        defaultToSuggestedUser={
                          defaultAssigneeForTaskRequestAvailability
                        }
                        variant="form"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="space-y-2">
              <p className="text-body-md-heavy">Priority</p>
              <PrioritySelect value={priority} onChange={setPriority} />
            </div>

            <div className="space-y-2">
              <p className="text-body-md-heavy">Add scheduling notes</p>
              <TextArea
                placeholder="Add a note..."
                aria-label="Notes"
                value={notes}
                onChange={(newNotes) => setNotes(newNotes)}
              />
            </div>
          </>
        )}
      </div>
    </View>
  );
}

function ReschedulingFormFields({ state }: { state: RescheduleRequestState }) {
  const [showMore, setShowMore] = useState(false);

  const {
    rescheduleRequestState: {
      selectedRescheduleInterviewRequirementIds,
      toggleSelectedRescheduleInterviewRequirementId,
      reschedulableInterviews,
      alwaysShownReschedulableInterviews,
      moreReschedulableInterviews,
      cancelInterviewSettings,
      updateCancelInterviewSettings,
    },
  } = state;

  const allowCancelInterviews = useAllowCancelInterviewsDuringReschedule({
    reschedulableInterviews,
    selectedRescheduleInterviewRequirementIds,
  });

  useEffect(() => {
    if (!allowCancelInterviews) {
      updateCancelInterviewSettings({
        cancelInterviews: false,
      });
    }
  }, [allowCancelInterviews, updateCancelInterviewSettings]);

  // If the default list is empty, then we will show the more list by default
  const defaultReschedulableInterviews =
    alwaysShownReschedulableInterviews.length > 0
      ? alwaysShownReschedulableInterviews
      : moreReschedulableInterviews;
  const canShowMore =
    alwaysShownReschedulableInterviews.length > 0 &&
    moreReschedulableInterviews.length > 0;

  return (
    <>
      <div className="space-y-2">
        <div className="text-body-md-heavy">Needs rescheduling</div>
        {reschedulableInterviews.length === 0 ? (
          <EmptyStateWrapper>No interviews to reschedule</EmptyStateWrapper>
        ) : (
          <div className="space-y-2">
            {defaultReschedulableInterviews.map((scheduledInterview) => (
              <SelectScheduledInterviewCard
                key={scheduledInterview.id}
                scheduledInterview={scheduledInterview}
                selected={selectedRescheduleInterviewRequirementIds.includes(
                  scheduledInterview.interviewRequirementId
                )}
                toggleSelect={() => {
                  toggleSelectedRescheduleInterviewRequirementId(
                    scheduledInterview.interviewRequirementId
                  );
                }}
              />
            ))}
            {canShowMore && (
              <>
                {showMore &&
                  moreReschedulableInterviews.map((scheduledInterview) => (
                    <SelectScheduledInterviewCard
                      key={scheduledInterview.id}
                      scheduledInterview={scheduledInterview}
                      selected={selectedRescheduleInterviewRequirementIds.includes(
                        scheduledInterview.interviewRequirementId
                      )}
                      toggleSelect={() => {
                        toggleSelectedRescheduleInterviewRequirementId(
                          scheduledInterview.interviewRequirementId
                        );
                      }}
                    />
                  ))}
                {
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    as="button"
                    onClick={() => setShowMore((v) => !v)}
                    className="text-body-md"
                  >
                    {showMore ? "Hide past interviews" : "Show all interviews"}
                  </Link>
                }
              </>
            )}
          </div>
        )}
      </div>

      {allowCancelInterviews && (
        <div className="space-y-2">
          <div className="flex items-start space-x-2">
            <div className="shrink-0">
              <Toggle
                value={cancelInterviewSettings.cancelInterviews}
                onChange={() =>
                  updateCancelInterviewSettings({
                    cancelInterviews: !cancelInterviewSettings.cancelInterviews,
                  })
                }
              />
            </div>
            <div className="space-y-2">
              <div className="space-y-0.5">
                <p className="text-body-md-heavy">
                  Cancel interviews immediately in advance of rescheduling
                </p>
                <p className="text-body-sm text-subtle">
                  Any chosen interviews will be canceled and removed from
                  interviewer calendars.
                </p>
              </div>
              {cancelInterviewSettings.cancelInterviews && (
                <div className="space-y-2">
                  <Checkbox
                    checked={
                      cancelInterviewSettings.interviewerNotificationSettings
                        .sendNotifications
                    }
                    label="Send cancellation email to interviewers"
                    onClick={() =>
                      updateCancelInterviewSettings({
                        interviewerNotificationSettings: {
                          ...cancelInterviewSettings.interviewerNotificationSettings,
                          sendNotifications:
                            !cancelInterviewSettings
                              .interviewerNotificationSettings
                              .sendNotifications,
                        },
                      })
                    }
                    disabled={!cancelInterviewSettings.cancelInterviews}
                  />
                  {cancelInterviewSettings.interviewerNotificationSettings
                    .sendNotifications && (
                    <TextArea
                      placeholder="Add optional notes…"
                      className="ml-[1.75rem]"
                      value={
                        cancelInterviewSettings.interviewerNotificationSettings
                          .note
                      }
                      onChange={(note) =>
                        updateCancelInterviewSettings({
                          interviewerNotificationSettings: {
                            ...cancelInterviewSettings.interviewerNotificationSettings,
                            note,
                          },
                        })
                      }
                    />
                  )}
                  <Checkbox
                    checked={
                      cancelInterviewSettings.candidateNotificationSettings
                        .sendNotifications
                    }
                    label="Send cancellation email to candidate"
                    onClick={() =>
                      updateCancelInterviewSettings({
                        candidateNotificationSettings: {
                          ...cancelInterviewSettings.candidateNotificationSettings,
                          sendNotifications:
                            !cancelInterviewSettings
                              .candidateNotificationSettings.sendNotifications,
                        },
                      })
                    }
                  />
                  {cancelInterviewSettings.candidateNotificationSettings
                    .sendNotifications && (
                    <TextArea
                      className="ml-[1.75rem]"
                      placeholder="Add optional notes…"
                      value={
                        cancelInterviewSettings.candidateNotificationSettings
                          .note
                      }
                      onChange={(note) =>
                        updateCancelInterviewSettings({
                          candidateNotificationSettings: {
                            ...cancelInterviewSettings.candidateNotificationSettings,
                            note,
                          },
                        })
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SelectScheduledInterviewCard({
  scheduledInterview,
  selected,
  toggleSelect,
}: {
  scheduledInterview: ScheduledInterviewForScheduledInterviewCardFragment;
  selected: boolean;
  toggleSelect: () => void;
}) {
  const formattedDateTime = useFormattedDateTimesForInterviewItem({
    interview: scheduledInterview,
    hideStartTimeNotification: true,
    timezone: getCurrentIanaTimezone(),
  });

  return (
    <AriaButton
      className="flex shadow-1 rounded-md p-3 gap-3 items-center w-full text-left"
      onClick={toggleSelect}
    >
      <Checkbox checked={selected} />
      <CalendarIcon
        className="flex-shrink-0"
        isInPast={formattedDateTime.isInPast}
        day={formattedDateTime.day}
        dayName={formattedDateTime.dayName}
        size="sm"
      />
      <div className="flex flex-col grow justify-center overflow-hidden">
        <div className="flex items-center grow shrink w-full min-w-0 gap-1">
          <InterviewTitle
            className={clsx("text-body-md-heavy truncate", {
              "text-subtle": formattedDateTime.isInPast,
            })}
            interview={scheduledInterview}
          />
          {scheduledInterview.isCancelled && (
            <ScheduledInterviewCardBadge status="cancelled" />
          )}
        </div>
        <div className="flex gap-1">
          <p className="text-subtle text-body-sm">
            {formattedDateTime.formattedStartDate} •{" "}
            {formattedDateTime.formattedTime}
          </p>
        </div>
      </div>
      <InterviewersSection
        interviewers={scheduledInterview.interviewers}
        isInPast={false}
        showRsvpStatus
      />
    </AriaButton>
  );
}

function SelectSchedulingTaskCard({
  schedulingRequest,
  selected,
  onSelect,
}: {
  schedulingRequest: SchedulingRequestForSelectingTaskFragment;
  selected: boolean;
  onSelect: () => void;
}) {
  return (
    <AriaButton
      className={clsx(
        "flex rounded-md p-3 gap-3 items-center w-full text-left overflow-hidden",
        {
          "border p-[13px] border-gray-border": !selected,
          "border-2 border-purple-500": selected,
        }
      )}
      onClick={onSelect}
    >
      <div className="w-full space-y-3">
        <div className="w-full flex justify-between gap-2 items-start">
          <div className="flex items-start gap-2 overflow-hidden">
            <Icon
              content={
                schedulingRequest.isReschedule
                  ? atlasSchedulingTypeRescheduleColor
                  : atlasSchedulingTypeScheduleColor
              }
            />
            <div className="space-y-1 truncate">
              <span className="flex space-x-1 items-center">
                <p className="text-body-md-heavy">
                  {formatEntity(
                    schedulingRequest.isReschedule
                      ? "rescheduling request"
                      : "scheduling request",
                    { capitalize: true }
                  )}
                </p>
              </span>
              <div className="flex flex-wrap gap-1 mr-5">
                {schedulingRequest.interviewPanelRequirement.interviewRequirements.map(
                  (requirement) => (
                    <Badge key={requirement.id}>
                      {requirement.interview.name ?? "Interview"}
                    </Badge>
                  )
                )}
              </div>
            </div>
          </div>
          {selected && (
            <Icon content={atlasCircleChecked} className="text-purple-500" />
          )}
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="text-subtle flex gap-1 text-body-md items-center">
            <Icon content={atlasCalendarEvent} className="w-5 h5" />
            {
              schedulingRequest.interviewPanelRequirement.interviewRequirements
                .length
            }
          </div>
          <Avatar
            tooltip={!schedulingRequest.assignedTo ? "No assignee" : undefined}
            contentClassName={
              !schedulingRequest.assignedTo
                ? "border !border-dashed !bg-white"
                : undefined
            }
            size="xs"
            name={schedulingRequest.assignedTo?.name}
            image={schedulingRequest.assignedTo?.imageUrl}
          />
        </div>
      </div>
    </AriaButton>
  );
}
