export function formatPersonName(firstName = "", lastName = "") {
  return `${firstName} ${lastName}`.trim();
}

export function getPluralized({
  count,
  singular,
  plural,
}: {
  count: number;
  singular: string;
  plural: string;
}) {
  return count === 1 ? singular : plural;
}
