import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { InterviewerForEditRsvpDialogFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { InterviewerResponse } from "shared/guide-scheduler/types";
import { getTitleFromLexicalJson } from "shared/utils/lexical";
import useQuery from "utils/useQuery";

import EditRsvpForm from "./EditRsvpForm";
import { useEditRsvpState } from "./utils/hooks/useEditRsvpState";
import { useUpdateRsvp } from "./utils/hooks/useUpdateRsvpOnSubmit";

gql(`
  fragment InterviewerForEditRsvpDialog on Interviewer {
    id
    responseStatus
    responseNote
    userMembership {
      id
      name
    }
    scheduledInterview {
      id
      title
    }
  }
`);

const FETCH_INTERVIEWER = gql(`
  query GetInterviewerForEditRsvpDialog($id: String!) {
    currentOrganization {
      interviewerById(id: $id) {
        ...InterviewerForEditRsvpDialog
      }
    }
  }
`);

export type EditRsvpDialogProps = {
  interviewerId: string;
  dialogStore: DialogStore;
};

export function EditRsvpDialog({
  interviewerId,
  dialogStore,
}: EditRsvpDialogProps) {
  return (
    <Dialog size="medium" store={dialogStore}>
      <ErrorBoundary
        fallback={({ onRecover }) => <DialogError onRecover={onRecover} />}
      >
        <EditRsvpDialogQuery
          interviewerId={interviewerId}
          dialogStore={dialogStore}
        />
      </ErrorBoundary>
    </Dialog>
  );
}

function EditRsvpDialogQuery({
  interviewerId,
  dialogStore,
}: {
  interviewerId: string;
  dialogStore: DialogStore;
}) {
  const { data } = useQuery(FETCH_INTERVIEWER, {
    variables: {
      id: interviewerId,
    },
    throwOnError: false,
  });

  if (!data) {
    return <DialogLoading />;
  }

  if (!data.currentOrganization?.interviewerById) {
    return <DialogError errorCode="404" />;
  }

  return (
    <EditRsvpDialogDisplay
      dialogStore={dialogStore}
      interviewer={data.currentOrganization.interviewerById}
    />
  );
}

function EditRsvpDialogDisplay({
  interviewer,
  dialogStore,
}: {
  dialogStore: DialogStore;
  interviewer: InterviewerForEditRsvpDialogFragment;
}) {
  const state = useEditRsvpState({
    interviewerId: interviewer.id,
    responseStatus:
      interviewer.responseStatus ?? InterviewerResponse.NEEDS_ACTION,
    responseNote: interviewer.responseNote ?? undefined,
  });
  const { form, disabledTooltipContent } = state;
  const { updateRsvp, loading } = useUpdateRsvp();

  const values = form.getValues();

  const onSubmit = useCallback(async () => {
    // TODO: use form.handleSubmit?
    await updateRsvp(values);
    dialogStore.hide();
  }, [updateRsvp, dialogStore, values]);

  return (
    <View
      content={{
        title: `Edit Interviewer RSVP`,
        subTitle: `Update ${
          interviewer.userMembership.name
        }'s RSVP status for ${getTitleFromLexicalJson(
          interviewer.scheduledInterview.title
        )}`,
      }}
      footer={{
        rightActions: (
          <FormRightActions
            cancel={{
              onClick: () => dialogStore.hide(),
            }}
            save={{
              children: "Save changes",
              variant: "primary",
              onClick: onSubmit,
              isLoading: loading,
            }}
            disabledTooltipContent={disabledTooltipContent}
          />
        ),
      }}
    >
      <EditRsvpForm state={state} />
    </View>
  );
}
