import { Select } from "@resource/atlas/select";
import TextField from "@resource/atlas/textfield/TextField";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  useController,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import {
  CODERPAD_URL_REGEX,
  CODESIGNAL_URL_REGEX,
} from "shared/guide-scheduler/collaborative-coding/utils";

import { InterviewFormData } from "./EditScheduledInterviewForm";

export function EditCollaborativeCodingUrlInForm({
  control,
  setValue,
  getValues,
  coderpadEnabled,
}: {
  control: Control<InterviewFormData>;
  setValue: UseFormSetValue<InterviewFormData>;
  getValues: UseFormGetValues<InterviewFormData>;
  coderpadEnabled: boolean;
}) {
  const [option, setOption] = useState<"custom" | "coderpad">(
    coderpadEnabled && getValues("generateCoderpadUrl") ? "coderpad" : "custom"
  );
  const {
    field: { value },
  } = useController({
    name: "generateCoderpadUrl",
    control,
  });

  useEffect(() => {
    if (value && option === "custom") {
      setOption("coderpad");
    } else if (!value && option === "coderpad") {
      setOption("custom");
    }
  }, [option, value]);

  return (
    <div className="space-y-2">
      <div className="text-body-md-heavy">Collaborative Coding</div>
      {coderpadEnabled && (
        <Select.Root
          sameWidth
          value={option}
          setValue={(v) => {
            setOption(v as "custom" | "coderpad");
            setValue("generateCoderpadUrl", v === "coderpad", {
              shouldValidate: true,
            });
          }}
        >
          <Select.Trigger className="w-full mt-2 bg-light-gray-500" />
          <Select.Content>
            <Select.Option value="custom"> Custom</Select.Option>
            <Select.Option value="coderpad">Coderpad</Select.Option>
          </Select.Content>
        </Select.Root>
      )}
      {option === "custom" && (
        <Controller
          render={({ field, fieldState: { error } }) => (
            <div>
              <TextField
                {...field}
                placeholder="Add collaborative coding link"
                aria-label="Collaborative coding link"
                errorMessage={error?.message}
              />
              <div className="text-xs text-red-500">{error?.message}</div>
            </div>
          )}
          rules={{
            pattern: {
              value: CODERPAD_URL_REGEX || CODESIGNAL_URL_REGEX,
              message: "Please enter a valid collaborative coding link",
            },
          }}
          name="collaborativeCodingUrl"
          control={control}
        />
      )}
    </div>
  );
}
