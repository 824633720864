import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

const REVIEW_PAGE_QUERY = gql(`
  query SchedulerV2ReviewPageQuery(
    $guideId: String!
    $scheduledInterviewIds: [String!]!
    $interviewIds: [String!]!
    $includeGoogleCalendarsForCreate: Boolean!
  ) {
    currentUser {
      id
      currentUserMembership {
        ...CurrentUserMembershipForReviewPageSchedulingDefaults
      }
    }
    guideById(guideId: $guideId) {
      ...GuideForSchedulerReviewPage
    }
    scheduledInterviewsById(guideId: $guideId, interviewIds: $scheduledInterviewIds, includeCancelled: true) {
      ...ExistingScheduledInterviewForSchedulerReviewPage
    }
    interviewsById(ids: $interviewIds) {
      ...InterviewForSchedulerReviewPage
    }
  }
`);

export function useReviewPageQuery({
  guideId,
  existingScheduledInterviewIds,
  scheduledInterviewInterviewIds,
  isCreatingInterviews,
}: {
  guideId: string;
  existingScheduledInterviewIds: string[];
  scheduledInterviewInterviewIds: string[];
  isCreatingInterviews: boolean;
}) {
  const { data, ...queryResult } = useQuery(REVIEW_PAGE_QUERY, {
    // ensure scheduling data is up to date
    fetchPolicy: "network-only",
    variables: {
      guideId,
      scheduledInterviewIds: existingScheduledInterviewIds,
      includeGoogleCalendarsForCreate: isCreatingInterviews,
      interviewIds: scheduledInterviewInterviewIds,
    },
  });
  const guide = useMemo(() => data?.guideById, [data]);
  const scheduledInterviews = useMemo(
    () => data?.scheduledInterviewsById,
    [data]
  );
  const currentUserMembership = useMemo(
    () => data?.currentUser?.currentUserMembership,
    [data]
  );
  const interviews = useMemo(() => data?.interviewsById, [data]);

  return useMemo(
    () => ({
      ...queryResult,
      guide,
      scheduledInterviews,
      currentUserMembership,
      interviews,
    }),
    [queryResult, guide, scheduledInterviews, currentUserMembership, interviews]
  );
}
