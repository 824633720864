import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

import { atlasClock, atlasRingInfo, atlasWarningOutline } from "../../icons";
import { Icon } from "../icon/Icon";

export type AlertBannerProps = ComponentPropsWithoutRef<"div"> & {
  variant?: "primary" | "danger" | "warning" | "info";
  Icon?: JSX.Element;
  Action?: JSX.Element;
};

export default function AlertBanner({
  variant,
  children,
  Icon: PassedIcon,
  Action,
  ...props
}: AlertBannerProps) {
  let textClassName;
  let iconClassName;
  let bgClassName;
  let icon;

  switch (variant) {
    case "primary":
      textClassName = "text-purple-900";
      iconClassName = "text-purple-500";
      bgClassName = "bg-purple-50";
      icon = atlasRingInfo;
      break;
    case "danger":
      textClassName = "text-danger-900";
      iconClassName = "text-danger-500";
      bgClassName = "bg-danger-50";
      icon = atlasWarningOutline;
      break;
    case "warning":
      textClassName = "text-yellow-900";
      iconClassName = "text-yellow-500";
      bgClassName = "bg-yellow-50";
      icon = atlasWarningOutline;
      break;
    case "info":
      textClassName = "text-blue-900";
      iconClassName = "text-blue-500";
      bgClassName = "bg-blue-50";
      icon = atlasRingInfo;
      break;
    default:
      textClassName = "text-dark";
      iconClassName = "text-subtle";
      bgClassName = "bg-light-gray-200";
      icon = atlasClock;
      break;
  }

  return (
    <div
      {...props}
      className={clsx(
        bgClassName,
        textClassName,
        "flex justify-between items-start p-4 rounded-md gap-3 text-body-md",
        props.className
      )}
    >
      <span className={clsx(iconClassName, "flex-shrink-0")}>
        {PassedIcon ?? <Icon content={icon} />}
      </span>
      <span className="grow">{children}</span>
      {Action && <span className="flex-shrink-0 ml-3">{Action}</span>}
    </div>
  );
}
