/* eslint-disable import/prefer-default-export */
import "./VariableNode.sass";

import clsx from "clsx";
import { forwardRef } from "react";

import { createComponentUtils } from "../../../__utils/atlas";

const COMPONENT_NAME = "ContentEditor-Variable";

const { el } = createComponentUtils(COMPONENT_NAME);

type Props = {
  isUndefined?: boolean;
  isSelected?: boolean;
  /** @default "placeholder" */
  undefinedMode?: "placeholder" | "error";
  children?: React.ReactNode;
};

export const VariableToken = forwardRef<HTMLSpanElement, Props>(
  (
    { undefinedMode = "placeholder", isUndefined, isSelected, children }: Props,
    ref
  ) => (
    <span
      ref={ref}
      tabIndex={-1}
      className={clsx(`${el`node`} undefined-mode-${undefinedMode}`, {
        isSelected,
        isUndefined,
      })}
    >
      {children}
    </span>
  )
);
