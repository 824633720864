import { zodResolver } from "@hookform/resolvers/zod";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useForm } from "react-hook-form";
import { z } from "zod";

const UpdateInterviewNotificationSettingsSchema = z.object({
  notifyInterviewers: z.boolean(),
  message: z.string().optional(),
});

export type UpdateInterviewNotificationSettingsFormData = z.infer<
  typeof UpdateInterviewNotificationSettingsSchema
>;

export function useUpdateInterviewNotificationSettingsState() {
  const form = useForm<UpdateInterviewNotificationSettingsFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      notifyInterviewers: true,
    },
    resolver: zodResolver(UpdateInterviewNotificationSettingsSchema),
  });

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState,
    {
      skipDirtyCheck: true,
    }
  );

  return {
    form,
    disabledTooltipContent,
  };
}

export type UpdateInterviewNotificationSettingsState = ReturnType<
  typeof useUpdateInterviewNotificationSettingsState
>;
