import { FormGroup } from "client/components/generic/misc/FormGroup";
import { InterviewTitleEditor } from "client/components/interviews/InterviewTitleEditor";
import { EditorState } from "lexical";
import { useCallback, useRef } from "react";
import { Controller } from "react-hook-form";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewTitle({
  state: { form },
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const titleRef = useRef(form.getValues().scheduledInterview.title);
  const interviewId = form.watch("scheduledInterview.id");

  const setTitleWithValidationDebounced = useCallback(
    (passedTitle: string) => {
      titleRef.current = passedTitle;
      setTimeout(() => {
        const currTitle = getTitleFromLexicalJson(titleRef.current).trim();
        const newTitle = getTitleFromLexicalJson(passedTitle).trim();

        if (newTitle === currTitle) {
          form.setValue("scheduledInterview.title", passedTitle, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }, 300);
    },
    [form]
  );

  const handleChange = useCallback(
    (editorState: EditorState) => {
      const newTitle = JSON.stringify(editorState.toJSON());
      form.setValue("scheduledInterview.title", newTitle, {
        shouldDirty: true,
      });
      setTitleWithValidationDebounced(newTitle);
    },
    [form, setTitleWithValidationDebounced]
  );

  return (
    <Controller
      name="scheduledInterview.title"
      control={form.control}
      render={({ field, fieldState: { isTouched, error } }) => (
        <FormGroup
          label="Title"
          errorMessage={isTouched ? error?.message : undefined}
        >
          <InterviewTitleEditor
            {...field}
            key={interviewId}
            valueSet={{}}
            initialState={form.getValues().scheduledInterview.title}
            onChange={handleChange}
          />
        </FormGroup>
      )}
    />
  );
}
