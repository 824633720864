import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { AtlasSelectTriggerComponentProps } from "@resource/atlas/select/types";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

import { InterviewCalendarSelect } from "./InterviewCalendarSelect";

const FETCH_CALENDARS_FOR_SELECT_QUERY = gql(`
  query FetchCalendarsForSelectQuery($userMembershipId: String!) {
    getCalendars(userMembershipId: $userMembershipId) {
      id
      name
    }
  }
`);

export function InterviewCalendarDisplay({
  userMembershipId,
  selectedInterviewCalendarId,
  setInterviewCalendar: setInterviewCalendarId,
  triggerProps,
}: {
  userMembershipId: string;
  selectedInterviewCalendarId?: string | null;
  setInterviewCalendar: (calendarId: string) => void;
  triggerProps?: AtlasSelectTriggerComponentProps;
}) {
  const { data, loading } = useQuery(FETCH_CALENDARS_FOR_SELECT_QUERY, {
    variables: {
      userMembershipId,
    },
    throwOnError: false,
  });
  const calendars = useMemo(() => data?.getCalendars ?? [], [data]);

  if (loading) {
    return (
      <div className="flex items-center w-full justify-center h-[40px]">
        <LoadingIndicator size="small" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <InterviewCalendarSelect
        calendars={calendars}
        selectedInterviewCalendarId={selectedInterviewCalendarId}
        setInterviewCalendar={setInterviewCalendarId}
        triggerProps={triggerProps}
      />
    </div>
  );
}
