/* eslint-disable import/prefer-default-export */
import { createDynamicCollectionItemsHook } from "../__utils/collections";
import type {
  AtlasSelectContentDynamicItems,
  AtlasSelectContentItem,
} from "./types";

/**
 * Creates a memoized dynamic collection of select items. It wraps `useMemo` internally
 * and must be passed an array of dependencies.
 *
 * This hook also enforces proper types for the items.
 *
 * **The `i.<type>()` methods must be used to create the items:**
 *
 * @example
 * const selectItems = useSelectItems(
 *   (i) => [
 *     i.option({
 *       key: "my-item",
 *       children: "My item",
 *     }),
 *   ],
 *   []
 * );
 *
 * // complex use cases are also possible:
 * const selectItems = useSelectItems(
 *   (i) => {
 *     // calling `i` will return a typed mutable array, and you
 *     // can optionally pass initial items to it as arguments
 *     const items = i();
 *
 *     if (condition)
 *       items.push(
 *         i.option({
 *           key: "my-item",
 *           value: "my-item",
 *           children: "My item",
 *         })
 *       );
 *
 *     return items;
 *   },
 *   [condition]
 * );
 *
 * // you can also use methods like `.map()`:
 * const selectItems = useSelectItems(
 *   (i) =>
 *     myArray.map((value) =>
 *       i.option({
 *         key: value.key,
 *         value: value.key,
 *         children: value.label,
 *       })
 *     ),
 *   [myArray]
 * );
 */
export const useSelectItems = createDynamicCollectionItemsHook<
  AtlasSelectContentItem,
  AtlasSelectContentDynamicItems
>();
