import { ReportRescheduleReasonFormState } from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportRescheduleReasonState";
import { mapRescheduleInterviewRequirementFragmentToRescheduleReason } from "client/components/scheduled-interviews/ReportingReasons/utils/mapping";
import {
  InterviewerResponse,
  ScheduledInterviewRescheduleReason,
} from "generated/graphql-codegen/graphql";
import { useEffect } from "react";
import { usePrevious } from "react-use";

import { SchedulingRequestFormState } from "./useSchedulingRequestFormState";
import {
  useSelectedInterviewersForReschedule,
  useSelectedInterviewsForReschedule,
} from "./useSelectedInterviewersForReschedule";

/**
 * Watch for changes in the selected interviews for reschedule and set the default reporting reason
 * on the form state
 */
export const useSetDefaultReportingReasonFromSelectedInterviewsForReschedule =
  ({
    schedulingRequestFormState,
    reportRescheduleReasonState,
  }: {
    schedulingRequestFormState: SchedulingRequestFormState;
    reportRescheduleReasonState: ReportRescheduleReasonFormState;
  }) => {
    const interviews = useSelectedInterviewsForReschedule({
      schedulingRequestFormState,
    });

    const interviewers = useSelectedInterviewersForReschedule({
      interviews,
    });

    const prevInterviewIds = usePrevious(
      interviews.map((interview) => interview.id)
    );

    useEffect(() => {
      const interviewIdsChanged =
        !prevInterviewIds ||
        prevInterviewIds.length !== interviews.length ||
        !prevInterviewIds.every((id, index) => id === interviews[index].id);

      if (!interviewIdsChanged) {
        return;
      }

      const firstInterviewWithRescheduleReason = interviews.find(
        (i) => !!i.pendingRescheduleInterviewRequirement
      );

      const declinedInterviewers = interviewers.filter(
        (interviewer) =>
          interviewer.responseStatus === InterviewerResponse.DECLINED
      );

      // If there is a first interview with a reschedule reason, use it
      if (firstInterviewWithRescheduleReason) {
        const defaults =
          mapRescheduleInterviewRequirementFragmentToRescheduleReason(
            firstInterviewWithRescheduleReason.pendingRescheduleInterviewRequirement!
          );

        reportRescheduleReasonState.onChange("reason", defaults.reason);
        reportRescheduleReasonState.onChange(
          "interviewerNoShows",
          defaults.interviewerNoShows
        );

        // If defaults include declined interviewers, use them
        if (defaults.interviewerDeclines?.length) {
          reportRescheduleReasonState.onChange(
            "interviewerDeclines",
            defaults.interviewerDeclines
          );

          // Otherwise, fallback to the current declined interviewers
        } else if (declinedInterviewers.length) {
          reportRescheduleReasonState.onChange(
            "interviewerDeclines",
            declinedInterviewers.map((interviewer) => ({
              interviewerUserMembershipId: interviewer.userMembershipId,
            }))
          );
        }

        reportRescheduleReasonState.onChange(
          "otherReason",
          defaults.otherReason
        );
        reportRescheduleReasonState.onChange(
          "additionalText",
          defaults.additionalText
        );

        // Otherwise, set the default declined interviewers to the current declined interviewers
      } else if (declinedInterviewers.length) {
        reportRescheduleReasonState.onChange(
          "reason",
          ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED
        );
        reportRescheduleReasonState.onChange(
          "interviewerDeclines",
          declinedInterviewers.map((interviewer) => ({
            interviewerUserMembershipId: interviewer.userMembershipId,
          }))
        );
      }
    }, [
      interviews,
      interviewers,
      reportRescheduleReasonState,
      prevInterviewIds,
    ]);
  };
