import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";
import { InterviewListWithModal } from "client/components/guide/interviews/InterviewListWithModal";
import { useGuideIdContext } from "client/utils/guide-id-provider";
import { gql } from "generated/graphql-codegen";
import { first } from "lodash";
import {
  InterviewsData,
  SerializedInterviewsNode,
} from "shared/guide-content/rich-blocks/interviews";
import useQuery from "utils/useQuery";

import { PlaceholderInterviewCard } from "./component";

const GUIDE_INTERVIEWS_FOR_RICH_BLOCK_INTERVIEWS_RENDERER = gql(`
query GuideInterviewsForRichBlockInterviewsRenderer(
    $guideId: String!
    $interviewIds: [String!]!
    $includeCancelled: Boolean
  ) {
    scheduledInterviewsById(guideId: $guideId, interviewIds: $interviewIds, includeCancelled: $includeCancelled) {
      ...InterviewListInterview
      organization {
        ...OrganizationForScheduledInterviewCard
      }
    }
  }
`);

function InterviewsList({
  guideId,
  data,
}: {
  guideId: string;
  data: InterviewsData;
}) {
  const { data: fetchedData } = useQuery(
    GUIDE_INTERVIEWS_FOR_RICH_BLOCK_INTERVIEWS_RENDERER,
    {
      variables: {
        guideId,
        interviewIds: data.interviews?.map((interview) => interview.id) ?? [],
        includeCancelled: true,
      },
      skip: !guideId || !data.interviews?.length,
    }
  );

  const interviews = fetchedData?.scheduledInterviewsById;
  return (
    <InterviewListWithModal
      interviews={interviews}
      organization={first(interviews)?.organization}
      type="upcoming"
      hideInternalStatuses
      hideDayHeaders={(interviews?.length ?? 0) < 2}
    />
  );
}

function InterviewsComponent({ data }: { data: InterviewsData }) {
  const guideContext = useGuideIdContext();
  const guideId = guideContext?.guideId;
  if (!guideId) {
    return <PlaceholderInterviewCard />;
  }
  return <InterviewsList guideId={guideId} data={data} />;
}

export const renderInterviewsNode =
  createNodeRenderer<SerializedInterviewsNode>(({ data }) => (
    <InterviewsComponent data={data} />
  ));
