import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { useCallback } from "react";

export type PostMessageSendWarningsDialogProps = {
  dialog: DialogProps;
  onConfirm: () => void;
  onCancel: () => void;
  content: JSX.Element;
  submitButtonText: string;
};

export function PostMessageSendWarningsDialog({
  dialog,
  onConfirm,
  onCancel,
  content,
  submitButtonText,
}: PostMessageSendWarningsDialogProps) {
  const handleConfirm = useCallback(() => {
    onConfirm();
    dialog.store.hide();
  }, [onConfirm, dialog.store]);

  const handleCancel = useCallback(() => {
    onCancel();
    dialog.store.hide();
  }, [onCancel, dialog.store]);

  return (
    <Dialog size="small" {...dialog}>
      <View
        header={{
          hideBorder: true,
        }}
        footer={{
          hideBorder: true,
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleConfirm} variant="primary">
                {submitButtonText}
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        {content}
      </View>
    </Dialog>
  );
}
