import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { calendarTimezoneAtom } from "client/components/calendar/settings";
import { TimezoneSelectorV2 } from "components/TimezoneSelection/TimezoneSelectorV2";
import { gql } from "generated/graphql-codegen";
import { useAtom } from "jotai";
import { useEffect } from "react";
import useMutation, { ToastDisplayCondition } from "utils/useMutation";

const UPDATE_CANDIDATE_TIMEZONE = gql(`
mutation UpdateCandidateTimezoneForCandidateCurrentTimezone($input: SetTimezoneOfCandidateInput!) {
  setTimezoneOfCandidate(input: $input) {
    success
    message
    code
    candidate {
      id
      timezone
    }
  }
}
`);

export function CandidateCurrentTimezone() {
  const [selectedTimezone, setTimezone] = useAtom(calendarTimezoneAtom);
  const { isGuideCandidate, user, candidate } = useGuideAuthContext();
  const [updateTimezone] = useMutation(UPDATE_CANDIDATE_TIMEZONE, {
    displayToastsOn: ToastDisplayCondition.NONE,
  });

  useEffect(() => {
    // Only do this if authed as a candidate
    if (!isGuideCandidate || !user) {
      return;
    }

    if (
      selectedTimezone &&
      candidate &&
      selectedTimezone !== candidate.timezone
    ) {
      updateTimezone({
        variables: {
          input: {
            id: candidate.id,
            timezone: selectedTimezone,
          },
        },
      });
    }
  }, [candidate, isGuideCandidate, selectedTimezone, updateTimezone, user]);

  if (!isGuideCandidate) {
    return null;
  }

  return (
    <div className="space-y-2">
      <p className="text-body-md-heavy text-dark">My current timezone</p>
      <TimezoneSelectorV2
        onClick={setTimezone}
        value={selectedTimezone}
        contentProps={{ className: "w-[320px]" }}
      />
    </div>
  );
}
