import clsx from "clsx";

function PositionLockedImage({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      className={className}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0.75C12 0.335786 11.6642 0 11.25 0H0.75L0.648229 0.00684662C0.282154 0.056509 0 0.370304 0 0.75C0 1.16421 0.335786 1.5 0.75 1.5H11.25L11.3518 1.49315C11.7178 1.44349 12 1.1297 12 0.75ZM3.25 5C3.66421 5 4 5.33579 4 5.75C4 6.1297 3.71785 6.44349 3.35177 6.49315L3.25 6.5H0.75C0.335786 6.5 0 6.16421 0 5.75C0 5.3703 0.282154 5.05651 0.648229 5.00685L0.75 5H3.25ZM3.25 10C3.66421 10 4 10.3358 4 10.75C4 11.1297 3.71785 11.4435 3.35177 11.4932L3.25 11.5H0.75C0.335786 11.5 0 11.1642 0 10.75C0 10.3703 0.282154 10.0565 0.648229 10.0068L0.75 10H3.25Z"
      />
    </svg>
  );
}

export function PositionLockedIcon({
  number,
  variant,
}: {
  number: number;
  variant: "purple" | "default";
}) {
  return (
    <div className="relative w-5 h-5">
      <PositionLockedImage className="top-[.1875rem] left-[.125rem] absolute" />
      <div
        className={clsx(
          "w-[.8125rem] h-[.8125rem] text-white rounded-full flex items-center justify-center absolute right-0 bottom-[.0625rem] text-[0.625rem] font-bold",
          variant === "purple" ? "bg-purple-600" : "bg-dark-gray-500"
        )}
      >
        {number}
      </div>
    </div>
  );
}
