/* eslint-disable import/prefer-default-export */
import type { ReactElement } from "react";

import { useModuleConfig } from "../../__utils/modules";
import { RICH_BLOCK_NAME } from "./shared";

declare module "@resource/atlas" {
  interface ContentEditorModuleConfig {
    [RICH_BLOCK_NAME]?: {
      onReplaceImage: (setImageUrl: (imageUrl: string) => void) => void;
      // TODO: remove once the inline file uploader is implemented
      tmpRender?: ReactElement;
    };
  }
}

export function useImageConfig() {
  return useModuleConfig(RICH_BLOCK_NAME, { required: true });
}
