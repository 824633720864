import clsx from "clsx";
import { useMemo } from "react";

import { useCalendarSettings } from "../../hooks/settings";
import { getTextColorFromConfig } from "../../utils/colors";
import { CalendarEvent } from "../../utils/types";
import { useEventSize } from "./utils/sizing";

type EventTimeProps = {
  event: Pick<
    CalendarEvent,
    "startTime" | "endTime" | "colorConfig" | "responseStatus"
  >;
};

export function EventTime({ event }: EventTimeProps) {
  const { twentyFourHourFormat } = useCalendarSettings();
  const { startTime, endTime } = event;
  const eventSize = useEventSize(event);

  const formattedTime = useMemo(() => {
    const sameAmPm = startTime.toFormat("a") === endTime.toFormat("a");

    const startFormat = `${startTime.minute === 0 ? "h" : "h:mm"}${
      !sameAmPm ? "a" : ""
    }`;
    const endFormat = `${endTime.minute === 0 ? "h" : "h:mm"}a`;

    if (twentyFourHourFormat) {
      return `${startTime.toFormat("H:mm")} - ${endTime.toFormat("H:mm")}`;
    }

    return `${startTime.toFormat(startFormat).replace(" ", "")} - ${endTime
      .toFormat(endFormat)
      .replace(" ", "")}`.toLowerCase();
  }, [startTime, endTime, twentyFourHourFormat]);
  const { textSizeClassName, lineHeight } = useMemo(() => {
    switch (eventSize) {
      case "xs":
      case "small":
      case "medium":
        return {
          textSizeClassName: "text-[10px]",
          lineHeight: "10px",
          marginLeftClassName: "ml-4",
        };
      case "large":
        return {
          textSizeClassName: "text-[12px]",
          lineHeight: "12px",
          marginLeftClassName: "ml-5",
        };
      default:
        return {
          textSizeClassName: "text-[10px]",
          lineHeight: "10px",
          marginLeftClassName: "ml-5",
        };
    }
  }, [eventSize]);

  if (eventSize === "xs" || eventSize === "small") return null;

  return (
    <p
      className={clsx("text-dark whitespace-nowrap")}
      style={{
        color: getTextColorFromConfig(event.colorConfig, event.responseStatus),
        lineHeight,
      }}
    >
      <time className={clsx(textSizeClassName)} style={{ lineHeight }}>
        {formattedTime}
      </time>
    </p>
  );
}
