import { useDialogStore } from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown, atlasClock } from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import TextField from "@resource/atlas/textfield/TextField";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { useTimezone } from "client/timezones/useTimezone";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";

type TimeInputProps = {
  value: string | null;
  setValue: (v: string) => unknown;
  twentyFourHourFormat?: boolean;
  className?: string;
  timezone?: string;
};

export function TimeInput({
  value: isoValue,
  setValue,
  twentyFourHourFormat,
  className,
  timezone: passedTimezone,
}: TimeInputProps) {
  const timezone = useTimezone(passedTimezone);
  const customDialogStore = useDialogStore();

  const value = useMemo(() => {
    return isoValue ? DateTime.fromISO(isoValue, { zone: timezone }) : null;
  }, [isoValue, timezone]);

  const valueAsMinutes = useMemo(() => {
    if (!value) return "";

    return value.diff(value.startOf("day")).as("minutes").toString();
  }, [value]);

  const updateValue = useCallback(
    (minutes: number) => {
      const startingValue = value
        ? value.startOf("day")
        : DateTime.now().setZone(timezone).startOf("day");

      setValue(startingValue.plus({ minutes }).toISO());
    },
    [value, timezone, setValue]
  );

  const menuItems = useMenuItems(
    (i) => {
      return [
        i.item({
          key: "custom",
          children: "Custom",
          isSelectable: true,
          onClick: () => {
            customDialogStore.show();
          },
        }),
        ...Array.from({ length: 96 }, (_, ind) => {
          const time = DateTime.now()
            .setZone(timezone)
            .startOf("day")
            .plus({ minutes: 15 * ind });
          const formatted = twentyFourHourFormat
            ? time.toLocaleString(DateTime.TIME_24_SIMPLE)
            : time.toFormat("h:mm a");

          return i.item({
            key: formatted,
            children: formatted,
            isSelectable: true,
            isSelected: valueAsMinutes === (15 * ind).toString(),
            onClick: () => {
              updateValue(15 * ind);
            },
          });
        }),
      ];
    },
    [
      customDialogStore,
      timezone,
      twentyFourHourFormat,
      updateValue,
      valueAsMinutes,
    ]
  );

  return (
    <>
      <Menu.Root>
        <Menu.Trigger
          className={clsx("inline-block bg-light-gray-500", className)}
        >
          <ListItem isInteractive icon={atlasClock} isSubtleIcon>
            <div className="flex">
              {value ? (
                <span className="w-full">
                  {twentyFourHourFormat
                    ? value.toFormat("H:mm ZZZZ")
                    : value.toFormat("h:mm a ZZZZ")}
                </span>
              ) : (
                <span className="text-gray-500 w-full">Select time</span>
              )}
              <Icon content={atlasChevronDown} className="-mr-1" />
            </div>
          </ListItem>
        </Menu.Trigger>
        <Menu.Content portal items={menuItems} />
      </Menu.Root>
      <CustomTimeDialog
        store={customDialogStore}
        onSubmit={(time) => {
          updateValue(time.diff(time.startOf("day")).as("minutes"));
          customDialogStore.hide();
        }}
        currentTime={value}
        timezone={timezone}
      />
    </>
  );
}

function CustomTimeDialog({
  store,
  onSubmit,
  currentTime,
  timezone,
}: Omit<DialogProps, "onSubmit"> & {
  currentTime: DateTime | null;
  onSubmit: (time: DateTime) => void;
  timezone: string;
}) {
  const [time, setTime] = useState(
    currentTime ? currentTime.toLocaleString(DateTime.TIME_24_SIMPLE) : ""
  );

  const prevCurrentTime = usePrevious(currentTime);
  useEffect(() => {
    if (
      currentTime &&
      prevCurrentTime !== currentTime &&
      currentTime.toLocaleString(DateTime.TIME_24_SIMPLE) !== time
    ) {
      setTime(currentTime.toLocaleString(DateTime.TIME_24_SIMPLE));
    }
  }, [currentTime, prevCurrentTime, time]);

  const isValidTime = useMemo(() => {
    if (!time) return false;

    const timePattern = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
    return timePattern.test(time);
  }, [time]);

  const handleSelect = useCallback(() => {
    if (!time) return;

    const parsedTime = DateTime.fromFormat(time, "HH:mm", { zone: timezone });

    if (parsedTime.isValid) {
      onSubmit(parsedTime);
    } else {
      console.error("Invalid time format");
    }
  }, [onSubmit, time, timezone]);

  return (
    <Dialog store={store} size="small">
      <View
        header={{
          title: "Choose custom time",
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button onClick={store.hide}>Cancel</Button>
              <OptionalTooltip
                content={isValidTime ? undefined : "Invalid time."}
                isInstant
              >
                <Button
                  variant="primary"
                  onClick={handleSelect}
                  disabled={!isValidTime}
                >
                  Select
                </Button>
              </OptionalTooltip>
            </ButtonGroup>
          ),
        }}
      >
        <TextField
          type="time"
          value={time}
          onChange={(v) => {
            setTime(v);
          }}
        />
        <span className="text-subtle text-body-md">
          Select a custom time using 24 hour format.
        </span>
      </View>
    </Dialog>
  );
}
