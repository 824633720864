import type { SerializedRichBlockNode } from "../../../__utils/rich-blocks";
import { CardData, DEFAULT_CARD_DATA } from "../shared/misc";

// config
// ------

export const RICH_BLOCK_NAME = "link-cards-collection";

export const DEFAULT_DATA: LinkCardsCollectionData = {
  cards: [DEFAULT_CARD_DATA],
};

// types
// -----

export type LinkCardsCollectionName = typeof RICH_BLOCK_NAME;

export type LinkCardsCollectionData = { cards: CardData[] };

export type SerializedLinkCardsCollectionNode = SerializedRichBlockNode<
  LinkCardsCollectionName,
  LinkCardsCollectionData
>;
