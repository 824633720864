/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, EditorThemeClasses } from "lexical";
import type { ComponentPropsWithoutRef } from "react";

import { useStaticValue } from "../../../../__utils/react";
import { AtlasContentEditorOptions } from "../../../types";
import { ROOT } from "../../component-utils";
import { mergeThemes } from "./merge-themes";

export type LexicalConfig = ComponentPropsWithoutRef<
  typeof import("@lexical/react/LexicalComposer").LexicalComposer
>["initialConfig"];

type LexicalConfigOptions = {
  namespace?: NonNullable<LexicalConfig["namespace"]>;
  onError?: NonNullable<LexicalConfig["onError"]>;
  nodes?: NonNullable<LexicalConfig["nodes"]>;
  editorState?: AtlasContentEditorOptions["initialState"];
  themes?: EditorThemeClasses[];
};

function isEditorState(
  editorState: AtlasContentEditorOptions["initialState"]
): editorState is EditorState {
  return (editorState as any)?.constructor?.name === "EditorState";
}

// TODO: see https://discord.com/channels/953974421008293909/955972012541628456/1036693306941395084
function stringifyIfJSON(
  editorState: AtlasContentEditorOptions["initialState"]
) {
  if (
    !editorState ||
    isEditorState(editorState) ||
    typeof editorState === "string" ||
    typeof editorState === "function"
  )
    return editorState;
  return JSON.stringify(editorState);
}

export function useLexicalConfig({
  onError,
  nodes,
  themes,
  editorState,
}: LexicalConfigOptions) {
  return useStaticValue(
    (): LexicalConfig => ({
      namespace: ROOT,
      onError(error, editor) {
        /* eslint-disable no-console */
        console.error("ContentEditor had an error:");
        console.error(error);
        /* eslint-enable no-console */
        onError?.(error, editor);
      },
      nodes,
      editorState: stringifyIfJSON(editorState),
      theme: mergeThemes(themes),
    })
  );
}
