import type { CamelCase, PascalCase, SnakeCase } from "type-fest";

// text utils
// ----------

function camelCase<Name extends string>(name: Name): CamelCase<Name> {
  return name
    .split("-")
    .map((part, i) =>
      i === 0 ? part : part.charAt(0).toUpperCase() + part.slice(1)
    )
    .join("") as CamelCase<Name>;
}

function pascalCase<Name extends string>(name: Name): PascalCase<Name> {
  const camelCasedName = camelCase(name);
  return (camelCasedName.charAt(0).toUpperCase() +
    camelCasedName.slice(1)) as PascalCase<Name>;
}

function screamingSnakeCase<Name extends string>(
  name: Name
): Uppercase<SnakeCase<Name>> {
  return name
    .split("-")
    .map((part) => part.toUpperCase())
    .join("_") as Uppercase<SnakeCase<Name>>;
}

// names
// -----

export type NodeClassName<Name extends string> = `${PascalCase<Name>}Node`;
export function toNodeClassName<Name extends string>(
  name: Name
): NodeClassName<Name> {
  return `${pascalCase(name)}Node`;
}

export type ModuleName<Name extends string> = `${CamelCase<Name>}Module`;
export function toModuleName<Name extends string>(
  name: Name
): ModuleName<Name> {
  return `${camelCase(name)}Module`;
}

export type CreateNodeName<Name extends string> =
  `$create${PascalCase<Name>}Node`;
export function toCreateNodeName<Name extends string>(
  name: Name
): CreateNodeName<Name> {
  return `$create${pascalCase(name)}Node`;
}

export type IsNodeName<Name extends string> = `$is${PascalCase<Name>}Node`;
export function toIsNodeName<Name extends string>(
  name: Name
): IsNodeName<Name> {
  return `$is${pascalCase(name)}Node`;
}

export type InsertCommandName<Name extends string> = `INSERT_${Uppercase<
  SnakeCase<Name>
>}_COMMAND`;
export function toInsertCommandName<Name extends string>(
  name: Name
): InsertCommandName<Name> {
  return `INSERT_${screamingSnakeCase(name)}_COMMAND`;
}

export type PluginName<Name extends string> = `${PascalCase<Name>}Plugin`;
export function toPluginName<Name extends string>(
  name: Name
): PluginName<Name> {
  return `${pascalCase(name)}Plugin`;
}
