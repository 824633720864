import type { AtlasHeadlessContentEditorOptions } from "@resource/atlas/content-editor";
import { fileModule } from "@resource/atlas/content-editor/file";
import { createHeadlessContentEditor } from "@resource/atlas/content-editor/headless";
import { imageModule } from "@resource/atlas/content-editor/image";
import {
  linkCardModule,
  linkCardsCollectionModule,
} from "@resource/atlas/content-editor/link-card";
import { richQuoteModule } from "@resource/atlas/content-editor/rich-quote";
import { variablesModule } from "@resource/atlas/content-editor/variables";
import { unknownVariablesModule } from "@resource/atlas/content-editor/variables/__lib/module";
import { videoModule } from "@resource/atlas/content-editor/video";
// eslint-disable-next-line import/no-restricted-paths
import { guideAvailabilityRequestModule } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/rich-block";
// eslint-disable-next-line import/no-restricted-paths
import { guideAvailabilitySubmissionModule } from "client/guide-availability/rich-blocks/guide-availability-submission/__lib/rich-block";
/**
 * Any modules from the client side need to be created with dynamic imports
 * if they use any gql calls. Gql codegen happens after server code is built.
 */
// eslint-disable-next-line import/no-restricted-paths
import { gifModule } from "client/guide-content/rich-blocks/gif";
// eslint-disable-next-line import/no-restricted-paths
import { interviewsModule } from "client/guide-content/rich-blocks/interviews/__lib/rich-block";
// eslint-disable-next-line import/no-restricted-paths
import { selfScheduleRequestModule } from "client/self-schedule/rich-blocks/self-schedule-request/rich-block";

type GuideUpdateHeadlessEditorOptions = AtlasHeadlessContentEditorOptions;

// TODO: Move to shared
export function createGuideUpdateHeadlessEditor({
  modules = [],
  onError,
}: GuideUpdateHeadlessEditorOptions = {}) {
  return createHeadlessContentEditor({
    modules: [
      variablesModule,
      unknownVariablesModule,
      imageModule,
      videoModule,
      richQuoteModule,
      linkCardModule,
      linkCardsCollectionModule,
      interviewsModule,
      gifModule,
      fileModule,
      guideAvailabilityRequestModule,
      guideAvailabilitySubmissionModule,
      selfScheduleRequestModule,
      ...modules,
    ],
    onError,
  });
}
