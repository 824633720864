import "./rich-block.sass";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useMemo, useState } from "react";

import {
  atlasCard,
  atlasCardLarge,
  atlasEdit2,
  atlasLink,
  atlasPaperclip,
  atlasRefreshCw,
} from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { Button } from "../../../button/Button";
import { Icon } from "../../../icon/Icon";
import { useMenuItems } from "../../../menu/use-menu-items";
import TextField from "../../../textfield/TextField";
import { INSERT_TARGETS } from "../../__utils/insert-options-targets-order";
import { createRichBlock, RichBlockProps } from "../../__utils/rich-blocks";
import { INSERT_IMAGE_COMMAND } from "../../image";
import { useFileConfig } from "./config";
import { File } from "./File";
import {
  DEFAULT_DATA,
  FileData,
  IMAGE_MIME_TYPES,
  RICH_BLOCK_NAME,
} from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-File";

const { el } = createComponentUtils(COMPONENT_NAME);

// component
// ---------

function FileComponent({
  data,
  updateData,
  ConfigMenu,
  ConfigPopover,
}: RichBlockProps<FileData>) {
  const { onReplaceImage } = useFileConfig();

  const [renameOpen, setRenamePopover] = useState(false);
  const [newName, setNewName] = useState(data.name);

  const openRenameForm = useCallback(() => {
    setNewName(data.displayName ?? data.name);
    setRenamePopover(true);
  }, [setNewName, setRenamePopover, data.displayName, data.name]);

  const configItems = useMenuItems(
    (i) => [
      i.groupLabel({
        key: "display-as",
        children: "Display as",
      }),
      i.item({
        key: "display-as-card",
        children: "Card",
        isSelectable: true,
        isSelected: data.displayAs === "card",
        onClick: () => updateData({ displayAs: "card" }),
        leadingContent: <Icon content={atlasCard} />,
      }),
      i.item({
        key: "display-as-link",
        children: "Link",
        isSelectable: true,
        isSelected: data.displayAs === "link",
        onClick: () => updateData({ displayAs: "link" }),
        leadingContent: <Icon content={atlasLink} />,
      }),
      i.separator({
        key: "separator-1",
      }),
      i.groupLabel({
        key: "size",
        children: "Size",
      }),
      i.item({
        key: "size-small",
        children: "Small",
        isSelectable: true,
        isSelected: data.displayAs === "card" && data.displaySize === "small",
        onClick: () => updateData({ displaySize: "small" }),
        leadingContent: <Icon content={atlasCard} />,
        disabled: data.displayAs !== "card",
      }),
      i.item({
        key: "size-large",
        children: "Large",
        isSelectable: true,
        isSelected: data.displayAs === "card" && data.displaySize === "large",
        onClick: () => updateData({ displaySize: "large" }),
        leadingContent: <Icon content={atlasCardLarge} />,
        disabled: data.displayAs !== "card",
      }),
      i.separator({
        key: "separator-2",
      }),
      i.item({
        key: "rename",
        children: "Rename",
        onClick: () => openRenameForm(),
        leadingContent: <Icon content={atlasEdit2} />,
      }),
      i.item({
        key: "replace",
        children: "Replace Image",
        onClick: () =>
          onReplaceImage((imageUrl) =>
            updateData({
              imageUrl,
            })
          ),
        leadingContent: <Icon content={atlasRefreshCw} />,
      }),
    ],
    [
      data.displayAs,
      data.displaySize,
      onReplaceImage,
      openRenameForm,
      updateData,
    ]
  );

  return (
    <>
      <ConfigMenu items={configItems} />
      <ConfigPopover
        open={renameOpen}
        setOpen={setRenamePopover}
        title="Rename"
        footer={
          <div className={el`rename-footer`}>
            <Button
              size="small"
              variant="primary"
              onClick={() => {
                updateData({ displayName: newName || undefined });
                setRenamePopover(false);
              }}
            >
              Save changes
            </Button>
          </div>
        }
      >
        <div className={el`rename-body`}>
          <TextField label="Label" value={newName} onChange={setNewName} />
        </div>
      </ConfigPopover>
      <File {...data} />
    </>
  );
}

// block
// -----

export const {
  $createFileNode,
  $isFileNode,
  FileNode,
  FilePlugin,
  INSERT_FILE_COMMAND,
  fileModule,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => FileComponent,
  useInsertOption(_, { insert }) {
    const { onReplaceFile } = useFileConfig();

    const [editor] = useLexicalComposerContext();

    const onTrigger = useCallback(() => {
      onReplaceFile((metadata) => {
        if (IMAGE_MIME_TYPES.includes(metadata.mimeType)) {
          editor.update(() =>
            editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
              imageUrl: metadata.url,
            })
          );
        } else {
          insert({
            ...DEFAULT_DATA,
            ...metadata,
          });
        }
      });
    }, [editor, insert, onReplaceFile]);

    return useMemo(
      () => ({
        label: "File",
        icon: atlasPaperclip,
        targets: {
          insert: INSERT_TARGETS.FILE,
          fixedToolbar: { slot: "file" },
        },
        onTrigger,
      }),
      [onTrigger]
    );
  },
  useRender() {
    const { tmpRender } = useFileConfig();
    return tmpRender;
  },
});
