import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";

import { useCalendarSizes } from "../../hooks/sizing";
import { useColumns } from "../../hooks/useColumns";
import { EventsGridSizingWrapper } from "../events/EventsGridSizingWrapper";

/** The vertical lines in the event view */
export function VerticalLines({
  includeLeftOffsetMargin = true,
}: {
  includeLeftOffsetMargin?: boolean;
}) {
  const { columnIds } = useColumns();
  const { minColumnWidth } = useCalendarSizes();

  return (
    <EventsGridSizingWrapper
      className="divide-x divide-gray-border"
      includeLeftOffsetMargin={includeLeftOffsetMargin}
    >
      {columnIds.map((item, i) => {
        return (
          <div
            data-cy={CypressData.calendar.verticalLine}
            className={clsx("row-span-full")}
            style={{
              gridColumnStart: i + 1,
              minWidth: `${minColumnWidth.rem}rem`,
            }}
            key={item}
          />
        );
      })}
    </EventsGridSizingWrapper>
  );
}
