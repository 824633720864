import { ContentEditorLite } from "@resource/atlas/content-editor/lite-editor";
import type { AtlasContentEditorProps } from "@resource/atlas/content-editor/types";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import { InterviewVariableSet } from "shared/guide-content/interview-title/variables";

type InterviewTitleEditorProps = AtlasContentEditorProps & {
  valueSet: ValueSet<InterviewVariableSet>;
  /** @default "placeholder" */
  undefinedMode?: "placeholder" | "error";
};

export function InterviewTitleEditor({
  valueSet,
  undefinedMode,
  modules = [],
  placeholder = "Add a title",
  ...props
}: InterviewTitleEditorProps) {
  return (
    <ContentEditorLite
      {...props}
      placeholder={placeholder}
      isGhost={false}
      modules={modules}
    />
  );
}
