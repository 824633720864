import { useCalendarOptions } from "client/components/calendar-v2/hooks/options";
import { useWrapperRef } from "client/components/calendar-v2/hooks/refs";
import { useCalculatedEventsViewSize } from "client/components/calendar-v2/hooks/sizing";
import { useColumns } from "client/components/calendar-v2/hooks/useColumns";
import { useCalendarContext } from "client/components/calendar-v2/utils/context";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { usePrevious } from "react-use";

/**
 * Consumers can pass currentViewGroupingId to the calendar to provide context
 * When this grouping ID changes, we want to scroll horizontally to the corresponding column
 */
export function useSyncHorizontalScrollWithCurrentViewingGroupingId() {
  const { currentViewingGroupingId } = useCalendarContext();
  const prevGroupingId = usePrevious(currentViewingGroupingId);
  const wrapperRef = useWrapperRef();
  const columns = useColumns();
  const { calendarsGrouping } = useCalendarOptions();
  const { width: eventsViewWidth } = useCalculatedEventsViewSize();

  const scrollToGroupingId = useCallback(
    (groupingId: string) => {
      if (!wrapperRef || !calendarsGrouping) return;

      const calendarGroupingIds = calendarsGrouping.map(
        (grouping) => grouping.id
      );
      const calendarGroupIdx = calendarGroupingIds.indexOf(groupingId);

      if (calendarGroupIdx === -1) return;

      const countOfColumnsBeforeCurrentGroup = calendarsGrouping
        .slice(0, calendarGroupIdx)
        .reduce((acc, group) => acc + group.calendarIds.length, 0);
      const columnWidth = eventsViewWidth / columns.columnIds.length;
      const scrollLeft = countOfColumnsBeforeCurrentGroup * columnWidth;

      wrapperRef.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    },
    [wrapperRef, columns.columnIds, calendarsGrouping, eventsViewWidth]
  );

  const groupingOrder = useMemo(
    () => calendarsGrouping?.map((grouping) => grouping.id).join(","),
    [calendarsGrouping]
  );
  const prevGroupingOrder = usePrevious(groupingOrder);

  useEffect(() => {
    if (!currentViewingGroupingId || columns.columnType === "day") return;

    const hasGroupingIdChanged = currentViewingGroupingId !== prevGroupingId;
    const hasGroupingOrderChanged = !isEqual(prevGroupingOrder, groupingOrder);

    if (hasGroupingIdChanged || hasGroupingOrderChanged) {
      scrollToGroupingId(currentViewingGroupingId);
    }
  }, [
    currentViewingGroupingId,
    prevGroupingId,
    groupingOrder,
    prevGroupingOrder,
    scrollToGroupingId,
    columns.columnType,
  ]);
}
