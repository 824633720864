/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import { isString } from "lodash";
import React from "react";

import { atlasCheck } from "../../icons";
import { Icon } from "../icon/Icon";

export interface ComboboxProps extends Omit<Ariakit.ComboboxProps, "size"> {
  /** The size of the input */
  size?: "default" | "compact";
  /** Whether the combobox has de-emphasized (ghost) styles */
  isGhost?: boolean;
  /** The content to display as the label */
  label?: React.ReactNode;
  /** Extra help text to display above the input, inline with the label */
  helpText?: React.ReactNode;
  /** Whether the field is required */
  isRequired?: boolean;
  /** Whether the field is disabled */
  isDisabled?: boolean;
  /** The props for the label element */
  labelProps?: Omit<React.LabelHTMLAttributes<HTMLLabelElement>, "id">;
  /** The props for the input element */
  inputClassName?: string;
}

export const Combobox = React.forwardRef<HTMLInputElement, ComboboxProps>(
  (props, ref) => {
    const {
      label,
      helpText,
      labelProps,
      size = "default",
      isGhost,
      isRequired,
      isDisabled,
      className,
      inputClassName,
      ...rest
    } = props;

    return (
      <div
        className={clsx(
          "relative group",
          isGhost && "is-ghost",
          isRequired && "is-required",
          isDisabled && "is-disabled",
          className
        )}
      >
        {(label || helpText) && (
          <div className="flex items-center justify-between gap-x-1 pb-2">
            {label && (
              <label
                {...labelProps}
                className="flex items-center gap-x-1 text-body-md-heavy"
              >
                <span>{label}</span>
                {isRequired && (
                  <div className="text-red-500 data-[disabled]:hidden">*</div>
                )}
              </label>
            )}
            {helpText && (
              <span className="text-body-md text-subtle">{helpText}</span>
            )}
          </div>
        )}
        <div
          className={clsx(
            "atlas--input-wrapper",
            "flex items-center relative transition rounded-md",
            isGhost ? "bg-transparent -ml-2.5" : "bg-light-gray-500",
            {
              "h-10": size === "default",
              "h-8": size === "compact",
            },
            "group-focus-within:focus-ring",
            isDisabled && "bg-light-gray-500"
          )}
        >
          <Ariakit.Combobox
            ref={ref}
            autoSelect
            disabled={isDisabled}
            type="search"
            {...rest}
            className={clsx(
              "w-full h-full bg-transparent text-dark text-body-md",
              "placeholder:text-subtle placeholder:opacity-100",
              "focus:outline-none",
              "pl-2.5",
              "pr-[4.5rem]",
              isDisabled && "text-disabled",
              isGhost && isDisabled && "bg-transparent",
              inputClassName
            )}
          />
          <div className="absolute right-0 flex items-center pr-2">
            <Ariakit.ComboboxCancel
              hideWhenEmpty
              className={clsx(
                "h-5 w-5 text-subtle transition rounded-full",
                "focus:outline-none",
                "data-[focus-visible]:ring-2 data-[focus-visible]:ring-purple-500 data-[focus-visible]:ring-offset-2",
                "[.is-disabled_&]:text-disabled"
              )}
            />
            <Ariakit.ComboboxDisclosure
              className={clsx(
                "h-8 w-8 flex items-center justify-center",
                "text-subtle hover:text-dark focus:text-dark",
                "focus:outline-none",
                "data-[focus-visible]:ring-2 data-[focus-visible]:ring-purple-500 data-[focus-visible]:ring-offset-2",
                "[.is-disabled_&]:text-disabled"
              )}
            />
          </div>
        </div>
      </div>
    );
  }
);

Combobox.displayName = "Combobox";

// Item Component
export interface ComboboxItemProps
  extends Omit<Ariakit.ComboboxItemProps, "size"> {
  size?: "compact" | "default" | "open";
  isSelectable?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
  isDestructive?: boolean;
  disableInteractivity?: boolean;
  leadingContent?: React.ReactNode;
  trailingContent?: React.ReactNode;
  renderContent?: (
    options: {
      children: React.ReactNode;
      childrenWithHighlights: React.ReactNode;
    },
    itemOptions: Omit<ComboboxItemProps, "children" | "renderContent">
  ) => React.ReactNode;
  highlights?: number[];
}

export const ComboboxItem = React.forwardRef<HTMLDivElement, ComboboxItemProps>(
  (props, ref) => {
    const {
      children,
      size = "default",
      isSelectable,
      isSelected,
      isDisabled,
      isDestructive,
      disableInteractivity,
      leadingContent,
      trailingContent,
      renderContent,
      highlights,
      className,
      ...rest
    } = props;

    const childrenWithHighlights = React.useMemo(() => {
      if (highlights && typeof children === "string") {
        return (
          <span>
            {children.split("").map((char, index) => {
              const uniqueKey = `${char}-${index}-${children.slice(0, index)}`;
              return (
                <span
                  key={uniqueKey}
                  style={
                    highlights.includes(index) ? { fontWeight: 600 } : undefined
                  }
                >
                  {char}
                </span>
              );
            })}
          </span>
        );
      }
      return children;
    }, [children, highlights]);

    return (
      <Ariakit.ComboboxItem
        ref={ref}
        disabled={isDisabled}
        focusOnHover
        blurOnHoverEnd={false}
        {...rest}
        className={clsx(
          "w-full rounded-[.375rem] bg-white",
          "flex text-body-md text-dark scroll-my-2",
          {
            "py-1.5": size === "compact",
            "py-2.5": size === "default",
            "py-3": size === "open",
          },
          !disableInteractivity && [
            "cursor-pointer select-none",
            "hover:bg-light-gray-500 focus:bg-light-gray-500",
            "data-[active-item]:bg-light-gray-500",
            "active:hover:bg-light-gray-500",
          ],
          isDestructive && [
            "text-red-500",
            !disableInteractivity && [
              "hover:bg-red-50 focus:bg-red-50 data-[active-item]:bg-red-50",
              "active:bg-red-100 data-[active]:bg-red-100",
            ],
          ],
          isDisabled && "text-disabled pointer-events-none",
          "focus:outline-none",
          className
        )}
      >
        {isSelectable && (
          <div
            className={clsx(
              "px-2",
              !isDisabled && "text-red-500",
              !isSelected && "opacity-0"
            )}
          >
            <Icon content={atlasCheck} />
          </div>
        )}
        <div
          className={clsx(
            "flex-grow h-full flex items-center gap-2",
            isSelectable ? "pr-3" : "px-3"
          )}
        >
          {leadingContent && (
            <div className="flex-shrink-0">{leadingContent}</div>
          )}
          <div className="flex-grow">
            {renderContent ? (
              renderContent(
                { children, childrenWithHighlights },
                {
                  size,
                  isSelectable,
                  isSelected,
                  isDisabled,
                  isDestructive,
                  disableInteractivity,
                  leadingContent,
                  trailingContent,
                }
              )
            ) : (
              <p
                className={clsx(isString(childrenWithHighlights) && "truncate")}
              >
                {childrenWithHighlights}
              </p>
            )}
          </div>
          {trailingContent && (
            <div className="flex-shrink-0">{trailingContent}</div>
          )}
        </div>
      </Ariakit.ComboboxItem>
    );
  }
);

ComboboxItem.displayName = "ComboboxItem";
// Group Component
export type ComboboxGroupProps = Ariakit.ComboboxGroupProps & {
  isLastGroup?: boolean;
};

export const ComboboxGroup = React.forwardRef<
  HTMLDivElement,
  ComboboxGroupProps
>((props, ref) => {
  const { isLastGroup, ...rest } = props;
  const combobox = Ariakit.useComboboxContext();

  if (!combobox) {
    throw new Error("ComboboxSeparator must be wrapped in ComboboxRoot");
  }

  return (
    <Ariakit.ComboboxGroup
      ref={ref}
      store={combobox}
      {...rest}
      className={clsx("group", rest.className)}
    >
      {rest.children}
      {!isLastGroup && (
        <div className="group-last:hidden relative w-full h-[.0625rem] my-2">
          <div className="absolute bg-light-gray-500 top-0 bottom-0 left-[-.5rem] right-[-.5rem]" />
        </div>
      )}
    </Ariakit.ComboboxGroup>
  );
});

ComboboxGroup.displayName = "ComboboxGroup";

// Item Header Component
export type ComboboxGroupLabelProps = Ariakit.ComboboxGroupLabelProps;

export const ComboboxGroupLabel = React.forwardRef<
  HTMLDivElement,
  ComboboxGroupLabelProps
>((props, ref) => {
  const { children, className } = props;

  return (
    <Ariakit.ComboboxGroupLabel
      ref={ref}
      className={clsx("px-3 py-1.5 text-label2 text-subtle", className)}
    >
      {children}
    </Ariakit.ComboboxGroupLabel>
  );
});

ComboboxGroupLabel.displayName = "ComboboxItemHeader";

// List Component
export interface ComboboxListProps extends Ariakit.ComboboxListProps {
  className?: string;
}

export const ComboboxList = React.forwardRef<HTMLDivElement, ComboboxListProps>(
  (props, ref) => {
    const { className, children } = props;

    return (
      <Ariakit.ComboboxList ref={ref} className={clsx("px-2 pb-2", className)}>
        {children}
      </Ariakit.ComboboxList>
    );
  }
);

ComboboxList.displayName = "ComboboxPanel";

// Popover Component
export interface ComboboxPopoverProps extends Ariakit.ComboboxPopoverProps {
  className?: string;
}

export const ComboboxPopover = React.forwardRef<
  HTMLDivElement,
  ComboboxPopoverProps
>((props, ref) => {
  const { className, children, ...rest } = props;

  const combobox = Ariakit.useComboboxContext();
  const mounted = React.useDeferredValue(
    Ariakit.useStoreState(combobox, "mounted")
  );

  return (
    <Ariakit.ComboboxPopover
      ref={ref}
      portal
      gutter={4}
      shift={-4}
      unmountOnHide
      hidden={!mounted}
      {...rest}
      className={clsx(
        "rounded-md shadow-8",
        "z-[50] flex flex-col max-h-[300px] overflow-auto overscroll-contain",
        "bg-white text-dark",
        className
      )}
    >
      {mounted && children}
    </Ariakit.ComboboxPopover>
  );
});

ComboboxPopover.displayName = "ComboboxPopover";

// Provider Component
export type ComboboxProviderProps = Ariakit.ComboboxProviderProps;

export function ComboboxProvider(props: ComboboxProviderProps) {
  const { children, ...rest } = props;

  return (
    <Ariakit.ComboboxProvider {...rest}>{children}</Ariakit.ComboboxProvider>
  );
}

ComboboxProvider.displayName = "ComboboxProvider";
