import {
  AtlasContentEditorProps,
  AtlasContentEditorSerializedState,
} from "@resource/atlas/content-editor";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import { LexicalEditor } from "lexical";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";

import { ScheduledInterviewDetailsEditor } from "../../scheduled-interview-editor/ScheduledInterviewDetailsEditor";

export type CustomInstructionsEditorProps = {
  editor: LexicalEditor | null;
  contentEditorProps: AtlasContentEditorProps;
  valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
  defaultCustomInstructions: AtlasContentEditorSerializedState | null;
  customInstructions: AtlasContentEditorSerializedState | null;
  onChange: (customInstructions: AtlasContentEditorSerializedState) => void;
};

export function CustomInstructionsEditor({
  contentEditorProps,
  valueSet,
  defaultCustomInstructions,
  customInstructions,
  editor,
  onChange,
}: CustomInstructionsEditorProps) {
  const [localCustomInstructions, setLocalCustomInstructions] =
    useState<AtlasContentEditorSerializedState | null>(customInstructions);

  useEffect(() => {
    if (editor) {
      const editorState = editor.parseEditorState(
        defaultCustomInstructions ?? LEXICAL_EMPTY_STATE
      );
      editor.setEditorState(editorState);
    }
  }, [defaultCustomInstructions, editor]);

  useDebounce(
    () => {
      if (
        localCustomInstructions &&
        !isEqual(localCustomInstructions, customInstructions)
      ) {
        onChange(localCustomInstructions);
      }
    },
    300,
    [localCustomInstructions, customInstructions]
  );

  return (
    <ScheduledInterviewDetailsEditor
      {...contentEditorProps}
      className="min-h-[7.5rem] max-h-[16rem]"
      valueSet={valueSet}
      isGhost={false}
      initialState={localCustomInstructions ?? LEXICAL_EMPTY_STATE}
      onChange={(editorState) => {
        setLocalCustomInstructions(editorState.toJSON());
      }}
    />
  );
}
