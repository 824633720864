import { createContext, ReactNode, useContext, useMemo } from "react";

type PortalElementValue = {
  footer: HTMLDivElement | null;
  inputContainer: HTMLDivElement | null;
};

const PortalElementContext = createContext<PortalElementValue>({
  footer: null,
  inputContainer: null,
});

type PortalElementProviderProps = {
  children?: ReactNode;
  elements: PortalElementValue;
};

export function PortalElementProvider({
  children,
  elements: { footer, inputContainer },
}: PortalElementProviderProps) {
  const value = useMemo(
    () => ({ footer, inputContainer }),
    [footer, inputContainer]
  );

  return (
    <PortalElementContext.Provider value={value}>
      {children}
    </PortalElementContext.Provider>
  );
}

export function usePortalElement() {
  return useContext(PortalElementContext);
}
