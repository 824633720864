import { useSyncDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";

import { useSchedulerHasChanges } from "../hooks/interviews";

/** Sync the scheduler state with dialog leave confirmation atom */
export function useSyncSchedulerDialogLeaveConfirmation() {
  const hasChanges = useSchedulerHasChanges();

  useSyncDialogLeaveConfirmation({
    showConfirmation: hasChanges,
  });
}
