/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Code from stack overflow - useAsyncDebounce was being used from the `react-table` library,
 * which is outdated and no longer being updated. React 18 broke it.
 */

import { useCallback, useRef } from "react";

function useGetLatest<T>(obj: T): () => T {
  const ref = useRef<T>(obj);

  return useCallback(() => ref.current, []);
}

export function useAsyncDebounce<T extends (...args: any[]) => any>(
  defaultFn: T,
  defaultWait = 0
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  const debounceRef = useRef<{
    timeout?: NodeJS.Timeout;
    promise?: Promise<ReturnType<T>>;
    resolve?: (value: ReturnType<T>) => void;
    reject?: (reason?: any) => void;
  }>({});

  const getDefaultFn = useGetLatest(defaultFn);
  const getDefaultWait = useGetLatest(defaultWait);

  return useCallback(
    async (...args: Parameters<T>) => {
      if (!debounceRef.current.promise) {
        debounceRef.current.promise = new Promise((resolve, reject) => {
          debounceRef.current.resolve = resolve;
          debounceRef.current.reject = reject;
        });
      }

      if (debounceRef.current.timeout) {
        clearTimeout(debounceRef.current.timeout);
      }

      debounceRef.current.timeout = setTimeout(async () => {
        delete debounceRef.current.timeout;
        try {
          debounceRef.current.resolve?.(await getDefaultFn()(...args));
        } catch (err) {
          debounceRef.current.reject?.(err);
        } finally {
          delete debounceRef.current.promise;
        }
      }, getDefaultWait());

      return debounceRef.current.promise;
    },
    [getDefaultFn, getDefaultWait]
  );
}
