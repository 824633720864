import { useCallback, useMemo, useRef } from "react";

export function useScrollOnComment({
  scrollableBodyRef,
}: {
  scrollableBodyRef: React.RefObject<HTMLDivElement>;
}) {
  const activityFeedRef = useRef<HTMLDivElement>(null);

  const onComment = useCallback(() => {
    if (scrollableBodyRef.current && activityFeedRef.current) {
      const fullScrollHeight = scrollableBodyRef.current.scrollHeight;
      const activityFeedHeight = activityFeedRef.current.scrollHeight;
      const TOP_OF_ACTIVITY_FEED = fullScrollHeight - activityFeedHeight;
      const SCROLL_TO = TOP_OF_ACTIVITY_FEED;

      scrollableBodyRef.current.scrollTo({
        top: SCROLL_TO,
        behavior: "smooth",
      });
    }
  }, [scrollableBodyRef]);

  return useMemo(
    () => ({
      activityFeedRef,
      commentFooterProps: {
        onComment,
      },
    }),
    [onComment]
  );
}
