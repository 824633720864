import { z } from "zod";

export const UrlWithAlternateTextSchema = z.object({
  /** Text to display instead of the url */
  text: z.string().min(1),
  /** Url to open when the text is clicked */
  url: z.string().min(1),
});

export type UrlWithAlternateText = z.infer<typeof UrlWithAlternateTextSchema>;

/** Parse URL out of a string or UrlWithAlternateText */
export function parseUrl(url: string | UrlWithAlternateText | null) {
  if (!url) {
    return null;
  }

  if (typeof url === "string") {
    return url;
  }

  return url.url;
}

/** Parse text out of a string or UrlWithAlternateText */
export function parseText(url: string | UrlWithAlternateText | null) {
  if (!url) {
    return null;
  }

  if (typeof url === "string") {
    return url;
  }

  return url.text;
}

/** Type guard to check if a value is a UrlWithAlternateText */
export function isUrlWithAlternateText(
  value: unknown
): value is UrlWithAlternateText {
  return UrlWithAlternateTextSchema.safeParse(value).success;
}

/** Parse and validate a URL, ensuring it's non-empty */
export function parseAndValidateUrl(url: string | UrlWithAlternateText): {
  url: string;
  text: string;
} {
  if (typeof url === "string") {
    if (!url) {
      throw new Error("URL cannot be empty");
    }
    return { url, text: url };
  }

  const result = UrlWithAlternateTextSchema.safeParse(url);
  if (!result.success) {
    throw new Error("Invalid URL with alternate text");
  }
  return result.data;
}
