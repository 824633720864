import { Avatar } from "@resource/atlas/avatar/Avatar";
import { AvatarGroup } from "@resource/atlas/avatar/AvatarGroup";
import { useAvatarGroupItems } from "@resource/atlas/avatar/use-avatar-group-items";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBookClosed } from "@resource/atlas/icons";
import { SelectedTagFilters } from "client/components/SelectedTagFilters/SelectedTagFilters";
import { TagFilter } from "client/components/SelectedTagFilters/types";
import clsx from "clsx";
import { InterviewerSlotType } from "generated/graphql-codegen/graphql";
import React, { useMemo } from "react";

import { ColorizedPoolIcon } from "../../interview-requirements/__components/ColorizedPoolIcon";
import { ColorizedSmartMatchIcon } from "../../interview-requirements/__components/ColorizedSmartMatchIcon";
import { listInterviewerNamesAsOr } from "../utils/misc";
import { InterviewerSlot } from "../utils/types";

type InterviewerSlotsRequirementsListProps = {
  interviewerSlots: InterviewerSlot[];
  className?: string;
};

const useFilteredInterviewerSlots = (interviewerSlots: InterviewerSlot[]) => {
  return useMemo(() => {
    return interviewerSlots.filter((slot) => {
      return (
        slot.userMembershipsSetting.length > 0 ||
        (slot.tagFilters && slot.tagFilters.length > 0) ||
        slot.interviewerPoolsSetting?.length > 0
      );
    });
  }, [interviewerSlots]);
};

export function InterviewerSlotsRequirementsList({
  interviewerSlots,
  className,
}: InterviewerSlotsRequirementsListProps) {
  const filteredSlots = useFilteredInterviewerSlots(interviewerSlots);

  return (
    <div className={clsx("space-y-2", className)}>
      {filteredSlots.map((slot, index) => (
        <React.Fragment key={slot.id}>
          <div className="interviewer-slot">
            <Slot interviewerSlot={slot} itemIdx={index} />
          </div>
          {slot.trainingEnabled && (
            <div className="interviewer-slot">
              <ShadowerSlot interviewerSlot={slot} itemIdx={index} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function ItemBadge({
  itemIdx,
  isShadower,
}: {
  itemIdx: number;
  isShadower: boolean;
}) {
  if (isShadower) {
    return (
      <div className="w-5 h-5 flex items-center justify-center">
        <Icon className="text-subtle" content={atlasBookClosed} />
      </div>
    );
  }

  return (
    <div className="w-5 h-5 p-0.5">
      <div className="flex items-center justify-center w-full h-full rounded-sm bg-light-gray-500">
        <span className="text-subtle text-body-md-heavy font-semibold text-[10px]">
          {itemIdx + 1}
        </span>
      </div>
    </div>
  );
}

function Slot({
  interviewerSlot,
  itemIdx,
}: {
  itemIdx: number;
  interviewerSlot: InterviewerSlot;
}) {
  const SlotComponent = useMemo(() => {
    if (interviewerSlot.type === InterviewerSlotType.POOL) {
      return (
        <PoolSlot
          pool={interviewerSlot.interviewerPoolsSetting[0]}
          tagFilters={interviewerSlot.tagFilters ?? []}
        />
      );
    }

    if (interviewerSlot.type === InterviewerSlotType.SMART_MATCH) {
      return <SmartMatchSlot interviewerSlot={interviewerSlot} />;
    }

    return (
      <AlternatesSlot alternates={interviewerSlot.userMembershipsSetting} />
    );
  }, [interviewerSlot]);

  return (
    <div className="flex gap-3 items-center">
      <ItemBadge itemIdx={itemIdx} isShadower={false} />
      {SlotComponent}
    </div>
  );
}

function ShadowerSlot({
  interviewerSlot,
  itemIdx,
}: {
  itemIdx: number;
  interviewerSlot: InterviewerSlot;
}) {
  const SlotComponent = useMemo(() => {
    if (interviewerSlot.type === InterviewerSlotType.POOL) {
      return (
        <PoolShadowerSlot pool={interviewerSlot.interviewerPoolsSetting[0]} />
      );
    }

    if (interviewerSlot.type === InterviewerSlotType.SMART_MATCH) {
      return <SmartMatchShadowerSlot interviewerSlot={interviewerSlot} />;
    }

    const shadower = interviewerSlot.shadowingUserMembershipsSetting[0];

    if (!shadower) {
      return null;
    }

    return <AlternatesShadowerSlot shadower={shadower} />;
  }, [interviewerSlot]);

  return (
    <div className="flex gap-3 items-center">
      <ItemBadge itemIdx={itemIdx} isShadower />
      {SlotComponent}
    </div>
  );
}

type PoolSlotProps = {
  pool: InterviewerSlot["interviewerPoolsSetting"][0];
  tagFilters: TagFilter[];
};

function PoolSlot({ pool, tagFilters }: PoolSlotProps) {
  return (
    <div className="flex gap-2 items-center">
      <ColorizedPoolIcon />
      <div className="flex gap-1 items-center">
        <p className="text-body-md">{pool?.name}</p>
        <SelectedTagFilters tagFilters={tagFilters} />
      </div>
    </div>
  );
}

type PoolShadowerSlotProps = {
  pool: InterviewerSlot["interviewerPoolsSetting"][0];
};

function PoolShadowerSlot({ pool }: PoolShadowerSlotProps) {
  return (
    <div className="flex gap-2 items-center">
      <p className="text-body-md">
        {pool?.name}
        <span className="text-subtle"> (trainee)</span>
      </p>
    </div>
  );
}

function SmartMatchSlot({
  interviewerSlot,
}: {
  interviewerSlot: InterviewerSlot;
}) {
  const tagFilters = useMemo(
    () => interviewerSlot.tagFilters ?? [],
    [interviewerSlot.tagFilters]
  );

  return (
    <div className="flex gap-2 items-center">
      <ColorizedSmartMatchIcon />
      <div className="flex gap-1 items-center">
        <p className="text-body-md">Smart match</p>
        <SelectedTagFilters tagFilters={tagFilters} />
      </div>
    </div>
  );
}

function SmartMatchShadowerSlot({
  interviewerSlot,
}: {
  interviewerSlot: InterviewerSlot;
}) {
  const items = useAvatarGroupItems(
    (i) => {
      return interviewerSlot.shadowingUserMembershipsSetting.map((shadower) =>
        i.avatar({
          key: shadower.id,
          name: shadower.name,
          image: shadower.imageUrl,
        })
      );
    },
    [interviewerSlot.shadowingUserMembershipsSetting]
  );

  return (
    <div className="flex gap-2 items-center overflow-hidden text-body-md">
      <AvatarGroup max={3} size="xs" items={items} className="shrink-0" />
      <p
        className="truncate"
        title={listInterviewerNamesAsOr(
          interviewerSlot.shadowingUserMembershipsSetting
        )}
      >
        {listInterviewerNamesAsOr(
          interviewerSlot.shadowingUserMembershipsSetting
        )}
      </p>
      <span className="text-subtle shrink-0"> (trainee)</span>
    </div>
  );
}

type AlternatesSlotProps = {
  alternates: InterviewerSlot["userMembershipsSetting"];
};

function AlternatesSlot({ alternates }: AlternatesSlotProps) {
  const items = useAvatarGroupItems(
    (i) => {
      return alternates.map((alternate) =>
        i.avatar({
          key: alternate.id,
          name: alternate.name,
          image: alternate.imageUrl,
        })
      );
    },
    [alternates]
  );

  return (
    <div className="flex gap-2 items-center overflow-hidden text-body-md">
      <AvatarGroup max={3} size="xs" items={items} className="shrink-0" />
      <p className="truncate" title={listInterviewerNamesAsOr(alternates)}>
        {listInterviewerNamesAsOr(alternates)}
      </p>
    </div>
  );
}

type AlternatesShadowerSlotProps = {
  shadower: InterviewerSlot["shadowingUserMembershipsSetting"][0];
};

function AlternatesShadowerSlot({ shadower }: AlternatesShadowerSlotProps) {
  return (
    <div className="flex gap-2 items-center overflow-hidden text-body-md">
      <Avatar name={shadower.name} image={shadower.imageUrl} size="xs" />
      <p className="truncate" title={shadower.name}>
        {shadower.name} <span className="text-subtle"> (trainee)</span>
      </p>
    </div>
  );
}
