import { Toggle } from "client/app/__components/Toggle";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";

export function EnableInterviewerTrainingToggle({
  enableTraining,
  setTrainingEnabled,
  traineeCount,
}: {
  enableTraining: boolean;
  setTrainingEnabled: (enableTraining: boolean) => void;
  traineeCount?: number;
}) {
  return (
    <div
      className={clsx(
        "flex justify-start py-2 px-3 items-center gap-2",
        " text-subtle border-t border-gray-border",
        !enableTraining && "rounded-b-md",
        "text-body-sm"
      )}
    >
      <Toggle
        size="small"
        value={enableTraining}
        onChange={setTrainingEnabled}
        data-cy={CypressData.editInterviewerSlotDisplay.enableTrainingSwitch}
      />
      <span>
        Enable interviewer training
        {traineeCount ? ` (${traineeCount} available)` : ""}
      </span>
    </div>
  );
}
