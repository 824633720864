/* eslint-disable @typescript-eslint/no-explicit-any */
import "./template-node-renderer.sass";

import clsx from "clsx";

import { createComponentUtils } from "../../../__utils/atlas";
import Tooltip from "../../../tooltip/Tooltip";
import { createNodeRenderer } from "../../renderer";
import { VariableRenderer, VariableRendererSet } from "./types";
import { SerializedUnknownVariableNode } from "./UnknownVariableNode";
import { useVariableRendererContext } from "./variable-renderer-context";
import type { SerializedVariableNode } from "./VariableNode";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Variable_template-display";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// template renderer
// -----------------

function TemplateVariableNodeDisplay({
  id,
  value: nodeValue,
  config,
  renderVariable,
}: SerializedVariableNode & { renderVariable: VariableRenderer<any, any> }) {
  const { nameSet, valueSet = {} } = useVariableRendererContext(true);
  if (!nameSet?.[id]) {
    return (
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      <TemplateUnknownVariableNodeDisplay
        type="unknown-variable"
        value={{
          variableId: id,
          tooltipContent: "Unknown token.",
        }}
        version={1}
      />
    );
  }
  const { name, label } = nameSet[id];

  const contextValue = valueSet[id];
  const value = contextValue ?? nodeValue;

  const isUndefined = value === undefined || value === "";

  return (
    <Tooltip UNSAFE_mode="slot" content={label} isInstant>
      <span className={clsx(ROOT, { isUndefined })}>
        {!isUndefined ? renderVariable({ value, config }) : name}
      </span>
    </Tooltip>
  );
}

export const createTemplateVariableNodeRenderer = (
  rendererSet: VariableRendererSet<any>
) =>
  createNodeRenderer<SerializedVariableNode>((node) => (
    <TemplateVariableNodeDisplay
      {...node}
      renderVariable={rendererSet[node.id]}
    />
  ));

// unknown variable template renderer

function TemplateUnknownVariableNodeDisplay({
  value: { variableId, tooltipContent = "Unsupported token." },
}: SerializedUnknownVariableNode) {
  return (
    <Tooltip UNSAFE_mode="slot" content={tooltipContent} isInstant>
      <span className={clsx(ROOT, { isUnknown: true })}>{variableId}</span>
    </Tooltip>
  );
}

export const createTemplateUnknownVariableNodeRenderer = () =>
  createNodeRenderer<SerializedUnknownVariableNode>((node) => (
    <TemplateUnknownVariableNodeDisplay {...node} />
  ));
