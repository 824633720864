import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { RollingDateRangeConfig } from "shared/self-schedule/types";

type RollingDateRangeConfigSelectProps = {
  value: RollingDateRangeConfig;
  onChange: (value: RollingDateRangeConfig) => unknown;
};

export function RollingDateRangeConfigSelect({
  value,
  onChange,
}: RollingDateRangeConfigSelectProps) {
  return (
    <div className="flex flex-row gap-1">
      <div className="w-16">
        <TextField
          type="number"
          value={value.days.toString()}
          onChange={(newValue) => {
            onChange({
              ...value,
              days: parseInt(newValue, 10),
            });
          }}
        />
      </div>
      <div className="flex-grow">
        <RollingDateRangeConfigSettingSelect
          currentSetting={value.setting}
          onSelectSetting={(setting) => {
            onChange({
              ...value,
              setting,
            });
          }}
        />
      </div>
    </div>
  );
}

const settingToDisplay: {
  [key in RollingDateRangeConfig["setting"]]: string;
} = {
  calendar_days: "Calendar days",
  weekdays: "Weekdays",
};

const settingToSubtext: {
  [key in RollingDateRangeConfig["setting"]]: string;
} = {
  calendar_days:
    "Counts every day on the calendar, including days you're unavailable.",
  weekdays: "Excludes weekends and only counts Mon-Fri.",
};

type RollingDateRangeConfigSettingSelectProps = {
  currentSetting: RollingDateRangeConfig["setting"];
  onSelectSetting: (setting: RollingDateRangeConfig["setting"]) => unknown;
  className?: string;
};

function RollingDateRangeConfigSettingSelect({
  currentSetting,
  onSelectSetting,
  className,
}: RollingDateRangeConfigSettingSelectProps) {
  const settingMenuItems = useMenuItems(
    (i) => {
      return (
        Object.keys(settingToDisplay) as RollingDateRangeConfig["setting"][]
      ).map((setting) => {
        return i.item({
          children: settingToDisplay[setting],
          renderContent: ({ children }) => (
            <div className="flex flex-col">
              <span>{children}</span>
              <span className="text-subtle text-body-sm">
                {settingToSubtext[setting]}
              </span>
            </div>
          ),
          value: setting,
          onSelect: () => {
            onSelectSetting(setting);
          },
        });
      });
    },
    [onSelectSetting]
  );

  return (
    <Menu.Root sameWidth>
      <Menu.Trigger>
        <div
          className={clsx(
            "flex justify-between w-full bg-light-gray-500 rounded-md px-2 py-[10px] text-body-md cursor-pointer",
            className
          )}
        >
          <span>{settingToDisplay[currentSetting]}</span>
          <Icon content={atlasChevronDown} />
        </div>
      </Menu.Trigger>
      <Menu.Content items={settingMenuItems} />
    </Menu.Root>
  );
}
