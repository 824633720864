import { Icon } from "@resource/atlas/icon/Icon";
import { serviceGreenhouse } from "@resource/atlas/icons";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { gql } from "generated/graphql-codegen";
import { JobInterviewForSelfScheduleSelectionFragment } from "generated/graphql-codegen/graphql";

gql(`
  fragment JobInterviewForSelfScheduleSelection on Interview {
    id
    title
    name
    jobInterviewStage {
      id
      position
    }
  }
`);

export function useGroupedAndSortedSelfScheduleInterviews(
  selfScheduleInterviews: JobInterviewForSelfScheduleSelectionFragment[]
) {
  const groupedInterviews = selfScheduleInterviews.reduce<
    Record<string, JobInterviewForSelfScheduleSelectionFragment[]>
  >((grouped, interview) => {
    const stageId = interview.jobInterviewStage?.id ?? "No stage";

    if (!grouped[stageId]) {
      return {
        ...grouped,
        [stageId]: [interview],
      };
    }

    return {
      ...grouped,
      [stageId]: [...grouped[stageId], interview],
    };
  }, {});

  const sortedStageIds = Object.keys(groupedInterviews).sort((a, b) => {
    const stageA = groupedInterviews[a][0]?.jobInterviewStage ?? null;
    const stageB = groupedInterviews[b][0]?.jobInterviewStage ?? null;

    if (!stageA) {
      return 1;
    }

    if (!stageB) {
      return -1;
    }

    return stageA.position - stageB.position;
  });

  return sortedStageIds.map((stageId) => ({
    stageId,
    interviews: groupedInterviews[stageId].sort((a, b) =>
      (a.name ?? "").localeCompare(b.name ?? "")
    ),
  }));
}

export function GroupedSelfScheduleInterviewOptions({
  selfScheduleInterviews,
  onClick,
}: {
  selfScheduleInterviews: JobInterviewForSelfScheduleSelectionFragment[];
  onClick: (interview: JobInterviewForSelfScheduleSelectionFragment) => void;
}) {
  const groupedInterviews = useGroupedAndSortedSelfScheduleInterviews(
    selfScheduleInterviews
  );
  return (
    <div className="divide-y divide-gray-border">
      {groupedInterviews.map((group) => (
        <div key={group.stageId} className="p-2">
          {group.interviews.map((interview) => (
            <SelfScheduleInterviewItem
              key={interview.id}
              interview={interview}
              onClick={() => onClick(interview)}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export function SelfScheduleInterviewItem({
  interview,
  onClick,
}: {
  interview: JobInterviewForSelfScheduleSelectionFragment;
  onClick: () => void;
}) {
  return (
    <OptionItem onClick={onClick}>
      <span className="flex gap-2">
        <Icon content={serviceGreenhouse} />
        <InterviewTitle interview={interview} />
      </span>
    </OptionItem>
  );
}
