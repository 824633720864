import {
  createRendererSet,
  renderDate,
  renderInterviewerNames,
  renderLink,
  renderPersonName,
  renderPersonNames,
  renderPhone,
  renderText,
  VariableSet,
} from "@resource/atlas/content-editor/variables";
import { VariableKey } from "modules/lexical/shared/utils/types";
import { renderVideoConferencingDetails } from "shared/guide-scheduler/scheduled-interview-details/video-conferencing-variable";

/** Wrapper function for Atlas createRendererSet with improved type safety. */
export function createRendererSetFromKeys<T extends VariableSet>(
  keys: ReadonlyArray<keyof T & keyof typeof rendererMap>
) {
  return createRendererSet<T>(
    Object.fromEntries(keys.map((key) => [key, rendererMap[key]]))
  );
}

export const rendererMap: Record<
  VariableKey,
  | typeof renderText
  | typeof renderPersonName
  | typeof renderLink
  | typeof renderDate
  | typeof renderInterviewerNames
  | typeof renderPhone
  | typeof renderPersonNames
  | typeof renderVideoConferencingDetails
> = {
  atsCoordinatorJobTitle: renderText,
  atsCoordinatorName: renderPersonName,
  atsRecruiterJobTitle: renderText,
  atsRecruiterName: renderPersonName,
  authorName: renderPersonName,
  candidateEmail: renderText,
  candidateLinkedIn: renderLink,
  candidateName: renderPersonName,
  candidatePhone: renderPhone,
  collaborativeCodingUrl: renderLink,
  companyName: renderText,
  conferencePhone: renderText,
  firstInterview: renderDate,
  firstInterviewerJobTitle: renderText,
  firstInterviewerName: renderPersonName,
  firstInterviewFirstInterviewerName: renderPersonName,
  firstInterviewFirstInterviewerTitle: renderText,
  firstInterviewInterviewers: renderPersonNames,
  guideLink: renderLink,
  hiringManagerJobTitle: renderText,
  hiringManagerName: renderPersonName,
  interviewerNames: renderPersonNames,
  interviewersList: renderInterviewerNames,
  interviewKit: renderLink,
  interviewName: renderText,
  interviewsInterviewers: renderPersonNames,
  interviewsTimeRange: renderDate,
  interviewTime: renderDate,
  jobListingLink: renderLink,
  jobTitle: renderText,
  lastInterview: renderDate,
  lastInterviewFirstInterviewerName: renderPersonName,
  lastInterviewFirstInterviewerTitle: renderText,
  lastInterviewInterviewers: renderPersonNames,
  linkToJobDescription: renderLink,
  schedulerName: renderPersonName,
  videoConferencingUrl: renderVideoConferencingDetails,
};
