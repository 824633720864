import { VideoConferencingServiceType } from "@prisma/client";
import { displayPhoneNumber } from "@resource/common";
import { getFormattedDateRange } from "client/utils/dates";
import clsx from "clsx";
import React from "react";
import { formatEntity } from "shared/constants/entities";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import { COLLABORATIVE_CODING_LABELS } from "shared/guide-scheduler/collaborative-coding/utils";
import { isFakeCandidateEmail } from "shared/utils/emails";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";
import { getCollaborativeCodingServiceType } from "shared/utils/interview-services";
import {
  parseAndValidateUrl,
  parseText,
  parseUrl,
  UrlWithAlternateText,
} from "shared/utils/url-display";

import { AvatarInterviewer } from "../components/Avatar";
import { Image } from "../components/Image";
import { Table } from "../components/Table";
import { emailIcons } from "../utils/icons";

export type RenderInterviewInviteContentData = {
  actor?: {
    name: string;
  };
  candidate: {
    name: string;
    email?: string | null;
    linkedInUrl: string | null;
  };
  scheduledInterview: {
    candidateTitle: string;
    internalTitle: string;
    startTime: string;
    endTime: string;
    interviewKit: string | UrlWithAlternateText | null;
    videoConferencing: {
      type: VideoConferencingServiceType;
      url: string | UrlWithAlternateText;
      meetingId?: string | null;
      passcode?: string | null;
    } | null;
    conferencePhone: string | null;
    collaborativeCodingUrl: string | UrlWithAlternateText | null;
    collaborativeCodingServiceType?: string | null;
    interviewers: {
      name: string;
      imageUrl: string | null;
      isShadow: boolean;
    }[];
    isSelfSchedule: boolean;
    managementUrl?: string;
    href?: string | null;
    scheduler: {
      name: string;
      email: string;
      imageUrl: string | null;
    } | null;
  };
  organization: {
    name: string;
    logoUrl: string;
    url: string;
    hideCandidateEmailInInterviewerInvites: boolean;
  };
  recruiter?: {
    name: string;
    email: string | null;
    imageUrl: string | null;
  };
  guide?:
    | {
        url: string;
        candidateRoleName: string;
        internalRoleName: string;
      }
    | undefined;
  job: {
    linkToJobDescription: string | null;
    departments?: string;
    customFields?: Record<string, string>;
  };
  hiringManager?: {
    name: string;
    email: string | null;
    imageUrl: string | null;
  };
  interviewerConfirmationTitle?: string;
  customInstructions?: string;
};

export type InterviewInviteContentType =
  | "confirmation"
  | "cancellation"
  | "update";

export type RenderInterviewInviteContentProps = {
  /** All the data needed */
  data: RenderInterviewInviteContentData;
  /**
   * Who the email is being sent to
   * Determines what information is displayed and possible actions
   */
  recipient: "interviewer" | "candidate";
  /**
   * Type of email being sent
   */
  type?: InterviewInviteContentType;
  /**
   * Display variant
   * Email is a more full display with time included
   * Calendar is minimal HTML and excludes time display
   */
  variant: "email" | "calendar";
  /** Timezone for displaying times */
  timezone: string | null;
  /** If the interviewer can manage the interviewer */
  interviewerCanManage?: boolean;
};

type Section = {
  html: JSX.Element;
  plainText: string;
};

export function renderInterviewInviteContent(
  props: RenderInterviewInviteContentProps
) {
  const rawSections: (Section | null)[] = [
    renderCustomInstructionsSection(props),
    renderCandidateSection(props),
    renderJobSection(props),
    renderInterviewNameSection(props),
    renderInterviewKitSection(props),
    renderInterviewTimeSection(props),
    renderLocationSection(props),
    renderCollaborativeCodingUrlSection(props),
    renderInterviewersListSection(props),
    renderHiringTeamSection(props),
    renderYourInterviewGuideSection(props),
    renderEditActions(props),
  ];
  const sections = rawSections.filter(filterOutNullsAndUndefined);

  return {
    html:
      props.variant === "email" ? (
        <Table className="w-full">
          {sections.map((s, idx) => (
            <React.Fragment key={idx}>{s.html}</React.Fragment>
          ))}
        </Table>
      ) : (
        <div>
          {sections.map((s, idx) => (
            <React.Fragment key={idx}>{s.html}</React.Fragment>
          ))}
        </div>
      ),
    plainText: sections.map((s) => s.plainText).join("\n"),
  };
}

function renderYourInterviewGuideSection(
  props: RenderInterviewInviteContentProps
) {
  const { data, recipient } = props;

  if (recipient === "candidate" && data.guide && data.guide.url) {
    return renderInfoSection({
      label: `Your ${formatEntity("guide")}`,
      value: data.guide.url,
      isBottom: true,
    });
  }

  return null;
}

function renderCustomInstructionsSection(
  props: RenderInterviewInviteContentProps
) {
  const { data, recipient } = props;

  if (recipient === "interviewer" && data.customInstructions) {
    return renderInfoSection({
      label: "Custom Instructions",
      customValue: (
        <div dangerouslySetInnerHTML={{ __html: data.customInstructions }} />
      ),
    });
  }

  return null;
}

function renderCandidateSection(props: RenderInterviewInviteContentProps) {
  const { data, recipient, variant } = props;

  if (recipient === "interviewer") {
    let plainText = data.candidate.name;
    if (data.candidate.email) {
      plainText += `\n${data.candidate.email}`;
    }
    if (data.candidate.linkedInUrl) {
      plainText += `\nLinkedIn profile: ${data.candidate.linkedInUrl}\n`;
    }

    return {
      html: (
        <Table>
          <tr dir="ltr" data-testid="candidate-section">
            <td>
              <strong className="bold-format">Candidate</strong>
              <br />
              <span className="items-center">{data.candidate.name}</span>
              {!data.organization.hideCandidateEmailInInterviewerInvites &&
                data.candidate.email && (
                  <>
                    <br />
                    <a
                      href={`mailto:${data.candidate.email}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-purple-500 !no-underline items-center"
                    >
                      <span className="items-center">
                        {data.candidate.email}
                      </span>
                    </a>
                  </>
                )}
              {data.candidate.linkedInUrl && (
                <>
                  <br />
                  <a
                    href={data.candidate.linkedInUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-500 !no-underline items-center"
                  >
                    <span>LinkedIn profile</span>
                    {variant === "email" && (
                      <Image
                        src={emailIcons.openExternal}
                        alt="Go to LinkedIn profile"
                        className="w-3 h-3"
                        style={{
                          paddingLeft: "5px",
                        }}
                      />
                    )}
                  </a>
                </>
              )}
              <br />
              <br />
            </td>
          </tr>
        </Table>
      ),
      plainText,
    };
  }

  return null;
}

function renderInterviewNameSection(props: RenderInterviewInviteContentProps) {
  const { data, recipient } = props;

  return renderInfoSection({
    label: "Interview",
    value:
      recipient === "candidate"
        ? data.scheduledInterview.candidateTitle
        : data.scheduledInterview.internalTitle,
  });
}

function renderInterviewKitSection(props: RenderInterviewInviteContentProps) {
  const { data, recipient } = props;

  if (recipient !== "candidate" && data.scheduledInterview.interviewKit) {
    return renderInfoSection({
      label: "Interview kit",
      value: data.scheduledInterview.interviewKit,
    });
  }

  return null;
}

function renderJobSection(props: RenderInterviewInviteContentProps) {
  const { data, recipient, variant } = props;

  if (data.guide) {
    const jobName =
      recipient === "interviewer"
        ? data.guide.internalRoleName
        : data.guide.candidateRoleName;

    let plainText = jobName;
    if (recipient === "interviewer") {
      if (data.job.linkToJobDescription) {
        plainText += `\nJob description: ${data.job.linkToJobDescription}`;
      }
      if (data.job.departments) {
        plainText += `\nDepartments: ${data.job.departments}`;
      }
      if (data.job.customFields) {
        Object.entries(data.job.customFields).forEach(([key, value]) => {
          plainText += `\n${key}: ${value}`;
        });
      }
    }

    return {
      html: (
        <Table>
          <tr dir="ltr" data-testid="job-section">
            <td>
              <strong className="bold-format">Job</strong>
              <br />
              <span className="items-center">{jobName}</span>

              {recipient === "interviewer" && (
                <>
                  {data.job.departments && (
                    <>
                      <br />
                      <span className="items-center text-subtle">
                        Departments: {data.job.departments}
                      </span>
                    </>
                  )}

                  {data.job.customFields &&
                    Object.entries(data.job.customFields).map(
                      ([key, value]) => (
                        <React.Fragment key={key}>
                          <br />
                          <span className="items-center text-subtle">
                            {key}: {value}
                          </span>
                        </React.Fragment>
                      )
                    )}
                </>
              )}

              {data.job.linkToJobDescription && (
                <>
                  <br />
                  <a
                    href={data.job.linkToJobDescription}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-500 !no-underline items-center"
                  >
                    <span>Job description</span>
                    {variant === "email" && (
                      <Image
                        src={emailIcons.openExternal}
                        alt="Go to job description"
                        className="w-3 h-3"
                        style={{
                          paddingLeft: "5px",
                        }}
                      />
                    )}
                  </a>
                </>
              )}
              <br />
              <br />
            </td>
          </tr>
        </Table>
      ),
      plainText,
    };
  }

  return null;
}

function renderInterviewTimeSection(props: RenderInterviewInviteContentProps) {
  const { data, timezone, variant, type } = props;

  if (variant === "email") {
    return renderInfoSection({
      label: "When",
      value: getFormattedDateRange({
        ...data.scheduledInterview,
        timezone: timezone ?? undefined,
      }).formattedDateTime,
      className: type === "cancellation" ? "line-through" : undefined,
    });
  }

  return null;
}

function renderLocationSection(props: RenderInterviewInviteContentProps) {
  const { data, variant, recipient } = props;
  const { videoConferencing, conferencePhone } = data.scheduledInterview;

  if (videoConferencing?.url) {
    let emailIcon = null;

    if (variant === "email") {
      if (videoConferencing.type === VideoConferencingServiceType.ZOOM) {
        emailIcon = emailIcons.zoom;
      }

      if (videoConferencing.type === VideoConferencingServiceType.GOOGLE_MEET) {
        emailIcon = emailIcons.googleMeet;
      }
    }

    return renderVideoConferencingSection({
      icon: emailIcon,
      url: videoConferencing.url,
      passcode: videoConferencing.passcode,
      meetingId: videoConferencing.meetingId,
    });
  }

  if (conferencePhone) {
    const phone = displayPhoneNumber(conferencePhone);

    return renderInfoSection({
      label: "Where",
      value:
        recipient === "candidate"
          ? `Your interviewer will call you at ${phone}`
          : `Interviewer will call the candidate at ${phone}`,
    });
  }

  return null;
}

function renderCollaborativeCodingUrlSection(
  props: RenderInterviewInviteContentProps
) {
  const { data } = props;
  const { collaborativeCodingUrl, collaborativeCodingServiceType } =
    data.scheduledInterview;
  const service =
    collaborativeCodingServiceType ??
    getCollaborativeCodingServiceType(parseUrl(collaborativeCodingUrl));

  if (collaborativeCodingUrl) {
    const name = service
      ? COLLABORATIVE_CODING_LABELS[service as CollaborativeCodingServiceType]
      : "Collaborative coding";

    return renderInfoSection({
      label: `${name} link`,
      value: collaborativeCodingUrl,
    });
  }

  return null;
}

function mapInterviewerNameToPlainText({
  interviewer,
  recipient,
}: {
  interviewer: RenderInterviewInviteContentProps["data"]["scheduledInterview"]["interviewers"][number];
  recipient: RenderInterviewInviteContentProps["recipient"];
}) {
  if (recipient === "interviewer" && interviewer.isShadow) {
    return `${interviewer.name} (trainee)`;
  }

  return interviewer.name;
}

function renderHiringTeamSection(props: RenderInterviewInviteContentProps) {
  const { data, recipient, variant } = props;
  const { scheduler: passedScheduler } = data.scheduledInterview;
  const { recruiter, hiringManager, candidate } = data;

  const scheduledByCandidate =
    passedScheduler &&
    (candidate.email === passedScheduler.email ||
      isFakeCandidateEmail(passedScheduler.email));

  const scheduler = scheduledByCandidate ? null : passedScheduler;

  if (
    recipient === "candidate" ||
    (!scheduler && !recruiter && !hiringManager)
  ) {
    return null;
  }

  const renderTeamMember = (
    role: string,
    name?: string,
    email?: string | null,
    imageUrl?: string | null
  ) => {
    if (!name) return null;

    return (
      <tr key={name}>
        <td className="pr-2 pt-2 align-top">
          <AvatarInterviewer
            interviewer={{ name, imageUrl: imageUrl ?? null }}
          />
        </td>
        <td className="pt-1.5">
          <span>
            {name} ({role})
          </span>
          {email && !isFakeCandidateEmail(email) && (
            <>
              <br />
              <a
                href={`mailto:${email}`}
                className="text-purple-500 !no-underline"
              >
                {email}
              </a>
            </>
          )}
        </td>
      </tr>
    );
  };

  const plainText = [
    hiringManager &&
      `Hiring Manager: ${hiringManager.name}${
        hiringManager.email ? `\n${hiringManager.email}` : ""
      }`,
    scheduler &&
      `Scheduler: ${scheduler.name}${
        scheduler.email ? `\n${scheduler.email}` : ""
      }`,
    recruiter &&
      `Recruiter: ${recruiter.name}${
        recruiter.email ? `\n${recruiter.email}` : ""
      }`,
  ]
    .filter(Boolean)
    .join("\n");

  if (variant === "email") {
    return renderInfoSection({
      label: "Hiring Team",
      customValue: (
        <Table>
          <tr>
            <td>
              <div>
                {renderTeamMember(
                  "Scheduler",
                  scheduler?.name,
                  scheduler?.email,
                  scheduler?.imageUrl
                )}
                {renderTeamMember(
                  "Recruiter",
                  recruiter?.name,
                  recruiter?.email,
                  recruiter?.imageUrl
                )}
                {renderTeamMember(
                  "Hiring Manager",
                  hiringManager?.name,
                  hiringManager?.email,
                  hiringManager?.imageUrl
                )}
              </div>
            </td>
          </tr>
        </Table>
      ),
      customValuePlainText: plainText,
      isBottom: recipient === "interviewer",
    });
  }

  return {
    html: (
      <Table>
        <tr dir="ltr" data-testid="hiringteam-section">
          <td>
            <strong className="bold-format">Hiring team</strong>
            <br />
            {scheduler && (
              <>
                <span>{scheduler.name} (Scheduler)</span>
                <br />
              </>
            )}
            {recruiter && (
              <>
                <span>{recruiter.name} (Recruiter)</span>
                <br />
              </>
            )}
            {hiringManager && (
              <>
                <span>{hiringManager.name} (Hiring manager)</span>
              </>
            )}
          </td>
        </tr>
      </Table>
    ),
    plainText,
  };
}

function renderInterviewersListSection(
  props: RenderInterviewInviteContentProps
) {
  const { data, recipient, variant } = props;
  const { interviewers } = data.scheduledInterview;
  const label = "Interviewers";
  const plainText = interviewers
    .map((interviewer) =>
      mapInterviewerNameToPlainText({ interviewer, recipient })
    )
    .join(", ");

  if (variant === "email") {
    return renderInfoSection({
      label,
      customValue: (
        <Table>
          <tr>
            <td>
              <div>
                {interviewers.map((interviewer) => (
                  <tr key={interviewer.name}>
                    <td className="pr-2 pt-2">
                      <AvatarInterviewer interviewer={interviewer} />
                    </td>
                    <td className="pt-2">
                      <span>{interviewer.name}</span>
                      {recipient === "interviewer" && interviewer.isShadow && (
                        <span className="text-subtle"> (trainee)</span>
                      )}
                    </td>
                  </tr>
                ))}
              </div>
            </td>
          </tr>
        </Table>
      ),
      customValuePlainText: plainText,
      isBottom: false,
    });
  }

  return renderInfoSection({
    label,
    value: plainText,
    isBottom: false,
  });
}

function renderEditActions(props: RenderInterviewInviteContentProps) {
  const { variant, recipient, data, interviewerCanManage } = props;
  const { scheduledInterview } = data;
  const canManageSelfScheduledMeeting =
    recipient === "candidate" || interviewerCanManage;

  if (
    variant === "calendar" &&
    canManageSelfScheduledMeeting &&
    scheduledInterview.isSelfSchedule &&
    scheduledInterview.managementUrl
  ) {
    return {
      html: (
        <div data-testid="edit-actions">
          <br />
          <div className="pb-2">Need to make changes?</div>
          <a
            href={scheduledInterview.managementUrl}
            className="text-purple-500 !no-underline"
          >
            Reschedule or cancel
          </a>
        </div>
      ),
      plainText: `Need to make changes?\nReschedule or cancel: ${scheduledInterview.managementUrl}\n`,
    };
  }

  // If the interview cannot be managed with a booking flow, we provide a link to open
  // the interview in Guide for editing
  if (recipient === "interviewer" && scheduledInterview.href) {
    return {
      html: (
        <div data-testid="edit-actions">
          <br />
          <span className="text-subtle">
            Need to make changes?{" "}
            <a
              href={scheduledInterview.href}
              target="_blank"
              className="text-purple-500 !no-underline"
              rel="noreferrer"
            >
              Edit this invite
            </a>
          </span>
        </div>
      ),
      plainText: `Make changes to this invite: ${scheduledInterview.href}\n`,
    };
  }

  return null;
}

function parseUrlAndText(value: string | UrlWithAlternateText | null): {
  url: string | null;
  text: string | null;
} {
  if (!value) {
    return { url: null, text: null };
  }
  return {
    url: parseUrl(value),
    text: parseText(value),
  };
}

function renderInfoSection({
  label,
  value,
  customValue,
  customValuePlainText,
  icon,
  isBottom,
  className,
  variant,
}: {
  label: string;
  value?: string | UrlWithAlternateText | null;
  customValue?: React.ReactNode;
  customValuePlainText?: string;
  icon?: string | null;
  isBottom?: boolean;
  className?: string;
  variant?: RenderInterviewInviteContentProps["variant"];
}) {
  const { url: urlValue, text: textValue } = parseUrlAndText(value ?? null);
  const isLink = urlValue?.startsWith("http") ?? false;
  const isVariable =
    typeof value === "string"
      ? value?.startsWith("{{") && value?.endsWith("}}")
      : false;

  const renderContent = () => {
    if (!textValue) {
      return null;
    }

    const iconElement = icon && (
      <Image
        src={icon}
        alt="Conferencing"
        className="w-5 h-5 pr-2"
        style={{
          verticalAlign: "middle",
        }}
      />
    );

    if (isLink && urlValue) {
      return (
        <a
          href={urlValue}
          target="_blank"
          rel="noreferrer"
          className={clsx(
            "text-purple-500 !no-underline items-center",
            className
          )}
        >
          {iconElement}
          <span>{textValue}</span>
        </a>
      );
    }

    return (
      <span className="items-center">
        {iconElement}
        <span className={clsx(className, isVariable && "text-subtle")}>
          {textValue}
        </span>
      </span>
    );
  };

  const element = (
    <span data-testid={`${label.toLowerCase().replace(/\s/g, "")}-section`}>
      <strong className="bold-format">{label}</strong>
      <br />
      {renderContent()}
      {customValue}
      {!isBottom && (
        <>
          {!customValue && <br />}
          <br />
        </>
      )}
    </span>
  );

  const getPlainTextContent = () => {
    if (customValuePlainText) {
      return customValuePlainText;
    }

    if (textValue) {
      return isLink && urlValue && urlValue !== textValue
        ? `${textValue}: ${urlValue}`
        : textValue;
    }
    return "";
  };

  return {
    html:
      variant === "email" ? (
        <Table>
          <tr dir="ltr">
            <td>{element}</td>
          </tr>
        </Table>
      ) : (
        element
      ),
    plainText: `${label}\n${getPlainTextContent()}\n`,
  };
}

function renderVideoConferencingSection({
  url: passedUrl,
  icon,
  passcode,
  meetingId,
}: {
  url: string | UrlWithAlternateText;
  icon?: string | null;
  passcode?: string | null;
  meetingId?: string | null;
}) {
  const { url, text } = parseAndValidateUrl(passedUrl);
  const isLink = url.startsWith("http");

  return {
    html: (
      <Table>
        <tr dir="ltr" data-testid="where-section">
          <td>
            <strong className="bold-format">Where</strong>
            <br />
            {isLink ? (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="text-purple-500 !no-underline items-center"
              >
                {icon && (
                  <Image
                    src={icon}
                    alt="Conferencing"
                    className="w-5 h-5 pr-2"
                    style={{
                      verticalAlign: "middle",
                    }}
                  />
                )}
                <span>{text}</span>
              </a>
            ) : (
              <span className="items-center text-subtle">
                {icon && (
                  <Image
                    src={icon}
                    alt="Conferencing"
                    className="w-5 h-5 pr-2"
                    style={{
                      verticalAlign: "middle",
                    }}
                  />
                )}
                <span>{url}</span>
              </span>
            )}
            {meetingId && (
              <>
                <br />
                <span className="text-subtle">ID: {meetingId}</span>
              </>
            )}
            {passcode && (
              <>
                <br />
                <span className="text-subtle">Passcode: {passcode}</span>
              </>
            )}
            <br />
            <br />
          </td>
        </tr>
      </Table>
    ),
    plainText: `Where\n${url}\n${meetingId ? `ID: ${meetingId}\n` : ""}${
      passcode ? `Passcode: ${passcode}\n` : ""
    }`,
  };
}

export type RenderInterviewInviteTitleData = {
  candidate: {
    name: string;
  };
  scheduledInterview: {
    candidateTitle: string;
    internalTitle: string;
  };
  organization: {
    name: string;
  };
  guide?:
    | {
        internalRoleName: string | undefined;
      }
    | undefined;
};

export type RenderInterviewInviteTitleProps = {
  data: RenderInterviewInviteTitleData;
  recipient: "interviewer" | "candidate";
};

export function renderInterviewInviteTitle({
  data,
  recipient,
}: RenderInterviewInviteTitleProps) {
  const { scheduledInterview, candidate, organization, guide } = data;

  if (recipient === "candidate") {
    return `${scheduledInterview.candidateTitle} with ${organization.name}`;
  }

  if (!guide) {
    return `${scheduledInterview.internalTitle} with ${candidate.name}`;
  }

  return `${scheduledInterview.internalTitle} with ${candidate.name} for ${guide.internalRoleName}`;
}
