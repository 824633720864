/* eslint-disable import/prefer-default-export */
import { cloneElement, ReactElement, ReactNode } from "react";

type ProvidersProps = {
  children: ReactNode;
  providers: ReactElement[];
};

export function Providers({ children, providers }: ProvidersProps) {
  let tree = <>{children}</>;

  [...providers].reverse().forEach((provider) => {
    tree = cloneElement(provider, undefined, tree);
  });

  return tree;
}
