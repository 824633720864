import { gql } from "generated/graphql-codegen";
import Image from "next/image";
import useQuery from "utils/useQuery";

const GET_COMPANY = gql(`
  query GetCompanyForLookingForCandidateMessage {
    currentOrganization {
      name
      defaultAvatarImageUrl
      theme {
        primaryColor
      }
    }
  }
`);

export function LookingForCandidateMessage() {
  const { data } = useQuery(GET_COMPANY);
  const company = data?.currentOrganization
    ? {
        name: data.currentOrganization.name,
        imageUrl: data.currentOrganization.defaultAvatarImageUrl,
        primaryColor: data.currentOrganization.theme?.primaryColor ?? "",
      }
    : null;

  if (!company) return null;

  return (
    <div className="h-full flex flex-col justify-center items-center gap-y-4 px-8">
      <Image
        src="/images/no-candidate.svg"
        alt="No notifications"
        width={364}
        height={148}
      />
      <div className="space-y-2">
        <div className="text-h4 text-center">No candidate found</div>
        <div className="text-body-md text-subtle text-center">
          Navigate to a candidate&apos;s profile in Greenhouse, and we&apos;ll
          display their Guide profile here.
        </div>
      </div>
    </div>
  );
}
