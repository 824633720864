import _ from "lodash";
import { z } from "zod";

export const WorkingHoursConfigSchema = z.array(
  z
    .object({
      isWorkingDay: z.boolean(),
      startTime: z
        .object({
          hour: z.number(),
          minute: z.number(),
        })
        .optional()
        .nullable(),
      endTime: z
        .object({
          hour: z.number(),
          minute: z.number(),
        })
        .optional()
        .nullable(),
    })
    .strict()
);
export type WorkingHoursConfig = z.infer<typeof WorkingHoursConfigSchema>;

export function areWorkingHoursConfigsEqual(
  array1: WorkingHoursConfig,
  array2: WorkingHoursConfig
): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  const serializeConfig = (config: WorkingHoursConfig[0]) => {
    if (!config.isWorkingDay) {
      return "false";
    }

    return `${config.startTime?.hour ?? ""}:${config.startTime?.minute ?? ""}-${
      config.endTime?.hour ?? ""
    }:${config.endTime?.minute ?? ""}`;
  };

  return _.every(
    array1,
    (item, index) => serializeConfig(item) === serializeConfig(array2[index])
  );
}
