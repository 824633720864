import { useAuthContext } from "auth/context";
import { useUnreadNotificationCount } from "client/hooks/useUnreadNotificationCount";
import { RoleEnum } from "enums/role-enum";
import React, { useContext, useEffect } from "react";

import { useExtensionController } from "./ExtensionControllerProvider";

interface UserCountsContextValue {
  unreadNotificationCount: number;
}

const UserCountsContext = React.createContext<
  UserCountsContextValue | undefined
>(undefined);

export const useUserCounts = () => {
  const context = useContext(UserCountsContext);
  if (context === undefined) {
    throw new Error("UserCountsContext must be initialized first.");
  }
  return context;
};

export function UserCountsProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { sendMessageToParent } = useExtensionController();
  const { user, highestRole } = useAuthContext();
  const unreadNotificationCount = useUnreadNotificationCount({
    skip: !user || highestRole === RoleEnum.Candidate,
  });
  useEffect(() => {
    sendMessageToParent({
      command: "unread-notification-count",
      value: unreadNotificationCount,
    });
  }, [unreadNotificationCount, sendMessageToParent]);

  return (
    <UserCountsContext.Provider
      value={{
        unreadNotificationCount,
      }}
    >
      {children}
    </UserCountsContext.Provider>
  );
}
