import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import {
  mapStatusFilterToDisplay,
  SchedulingTaskStatusFilterSelect,
} from "client/app/(scheduling requests)/__components/SchedulingTaskStatusFilterSelect";
import { DEFAULT_STATUS_FILTER } from "client/app/(scheduling requests)/__utils";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { SchedulingRequestCard } from "client/components/scheduling-tasks/card/SchedulingRequestCard";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingRequestsListExtensionGuideFragment,
  SchedulingRequestStatus,
  SchedulingRequestStatusFilter,
} from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from "react";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

gql(`
fragment SchedulingRequestsListExtensionGuide on Guide {
  id
}
`);

const SCHEDULING_REQUESTS_FOR_GUIDE = gql(`
  query SchedulingRequestsForGuideExtension($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      schedulingRequests(includeArchived: true) {
        ...SchedulingRequestForSchedulingRequestCard
      }
    }
  }
`);

const SCHEDULING_REQUESTS_FOR_GUIDE_SUBSCRIPTION = gql(`
  subscription SchedulingRequestsForGuideExtensionUpdated($guideId: ID!) {
    schedulingRequestForGuideUpdated(guideId: $guideId) {
      ...SchedulingRequestForSchedulingRequestCard
    }
  }
`);

type SchedulingRequestSectionProps = Omit<
  ComponentPropsWithoutRef<"section">,
  "onClick"
> & {
  guide: SchedulingRequestsListExtensionGuideFragment;
  onClick: (props: { schedulingRequestId: string }) => void;
};

export function SchedulingRequestsSection({
  guide,
  onClick,
  ...props
}: SchedulingRequestSectionProps) {
  const [statusFilter, setStatusFilter] =
    useState<SchedulingRequestStatusFilter | null>(DEFAULT_STATUS_FILTER);
  const { data, loading, subscribeToMore } = useQuery(
    SCHEDULING_REQUESTS_FOR_GUIDE,
    {
      variables: {
        guideId: guide.id,
      },
    }
  );
  useEffect(() => {
    subscribeToMore({
      document: SCHEDULING_REQUESTS_FOR_GUIDE_SUBSCRIPTION,
      variables: {
        guideId: guide.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !prev.guideById?.schedulingRequests)
          return prev;
        const newItem = subscriptionData.data.schedulingRequestForGuideUpdated;

        let schedulingRequests = [...prev.guideById.schedulingRequests];
        const existing = schedulingRequests.find((v) => v.id === newItem.id);

        if (!existing) {
          schedulingRequests = [{ ...newItem }, ...schedulingRequests];
        }

        return {
          ...prev,
          guideById: {
            ...prev.guideById,
            schedulingRequests: schedulingRequests.filter(
              (s) =>
                !s.archivedAt && s.status !== SchedulingRequestStatus.COMPLETED
            ),
          },
        };
      },
    });
  }, [guide.id, subscribeToMore]);

  const schedulingRequests = useMemo(
    () =>
      _(data?.guideById?.schedulingRequests)
        .orderBy("createdAt", "desc")
        .filter((s) => {
          switch (statusFilter) {
            case SchedulingRequestStatusFilter.ARCHIVED:
              return s.archivedAt !== null;
            // OPEN and COMPLETED tasks should not include archived tasks: see statusFilterClause in SchedulingRequestService.
            case SchedulingRequestStatusFilter.OPEN:
              return (
                s.archivedAt === null &&
                [
                  SchedulingRequestStatus.IN_PROGRESS,
                  SchedulingRequestStatus.DRAFT,
                ].includes(s.status)
              );
            case SchedulingRequestStatusFilter.COMPLETED:
              return (
                s.archivedAt === null &&
                s.status === SchedulingRequestStatus.COMPLETED
              );
            default:
              return true;
          }
        })
        .value(),
    [data, statusFilter]
  );

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className="w-full h-[4.25rem] flex items-center justify-center">
          <LoadingIndicator size="small" />
        </div>
      );
    }

    return (
      <>
        {schedulingRequests.length === 0 && (
          <EmptyStateWrapper>
            No{" "}
            {statusFilter
              ? mapStatusFilterToDisplay[statusFilter].toLowerCase()
              : ""}{" "}
            {formatEntity("request", { plural: true })}
          </EmptyStateWrapper>
        )}
        {schedulingRequests.map((schedulingRequest) => (
          <SchedulingRequestCard
            key={schedulingRequest.id}
            variant="candidate-profile"
            schedulingRequest={schedulingRequest}
            onClick={() =>
              onClick({ schedulingRequestId: schedulingRequest.id })
            }
          />
        ))}
      </>
    );
  }, [loading, schedulingRequests, statusFilter, onClick]);

  return (
    <section {...props} className={clsx("space-y-3", props.className)}>
      <div className="flex justify-between items-center">
        <p className="text-h4">
          {formatEntity("scheduling request", {
            capitalize: true,
            plural: true,
          })}
        </p>
        <SchedulingTaskStatusFilterSelect
          value={statusFilter}
          onChange={setStatusFilter}
          size="small"
        />
      </div>
      <div className="space-y-2 flex flex-col">{content}</div>
    </section>
  );
}
