import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerComponentProps } from "@resource/atlas/select/types";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import clsx from "clsx";

export type InterviewCalendarForSelect = {
  id: string;
  name: string;
};

export function InterviewCalendarSelect({
  calendars,
  selectedInterviewCalendarId,
  setInterviewCalendar: setInterviewCalendarId,
  isLoading,
  triggerProps,
}: {
  calendars: InterviewCalendarForSelect[];
  selectedInterviewCalendarId?: string | null;
  setInterviewCalendar: (calendarId: string) => void;
  triggerProps?: AtlasSelectTriggerComponentProps;
  isLoading?: boolean;
}) {
  const items = useSelectItems(
    (i) => {
      return calendars.map((calendar) => {
        return i.option({
          key: calendar.id,
          value: calendar.id,
          children: calendar.name,
          size: "compact",
        });
      });
    },
    [calendars]
  );

  return (
    <Select.Root
      value={selectedInterviewCalendarId ?? ""}
      setValue={(calendarId) => setInterviewCalendarId(calendarId as string)}
      placement="top"
    >
      {isLoading ? (
        <SelectTrigger
          {...triggerProps}
          className={clsx("w-full bg-light-gray-500", triggerProps?.className)}
        >
          <div className="flex w-full justify-center">
            <LoadingIndicator size="small" />
          </div>
        </SelectTrigger>
      ) : (
        <Select.Trigger>
          <SelectTrigger
            {...triggerProps}
            className={clsx(
              "w-full bg-light-gray-500",
              triggerProps?.className
            )}
          />
        </Select.Trigger>
      )}
      <Select.Content items={items} />
    </Select.Root>
  );
}
