import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { Toggle } from "client/app/__components/Toggle";
import { useMemo } from "react";
import { isInterviewWhitelisted } from "shared/utils/interview-whitelist";

type AddToWhitelistSwitchDisplayProps = {
  organizationWhitelist?: string[];
  title: string;
  isWhitelisted: boolean;
  interviewer: {
    name: string;
  };
  onChange: (val: boolean) => void;
  loading?: boolean;
  hideText?: boolean;
};

export function AddToWhitelistSwitchDisplay({
  organizationWhitelist,
  interviewer,
  title,
  isWhitelisted,
  onChange,
  loading,
  hideText,
}: AddToWhitelistSwitchDisplayProps) {
  const isInOrgWhitelist = useMemo(
    () =>
      isInterviewWhitelisted({
        eventTitle: title,
        whitelist: organizationWhitelist ?? [],
      }),
    [organizationWhitelist, title]
  );
  const tooltipContent = useMemo(() => {
    if (!isWhitelisted) {
      return `Mark as flexible for ${interviewer.name} and allow interview scheduling without conflicts in the future.`;
    }

    if (isInOrgWhitelist) {
      return `This meeting is marked as "Flexible" by your organization's default settings.`;
    }

    return `Remove from flexible list for ${interviewer.name} and show conflicts in the future.`;
  }, [interviewer.name, isInOrgWhitelist, isWhitelisted]);

  return (
    <div className="flex flex-row flex-shrink-0 space-x-2 text-body-sm items-center">
      <Tooltip isInstant content={tooltipContent}>
        <Toggle
          disabled={isInOrgWhitelist}
          size="normal"
          value={isWhitelisted}
          onChange={onChange}
          loading={loading}
        />
      </Tooltip>
      {!hideText && <span>Flexible</span>}
    </div>
  );
}
