import { FormGroup } from "client/components/generic/misc/FormGroup";
import { TimeConfig } from "shared/self-schedule/types";

import { TimeConfigSelect } from "./TimeConfigSelect";

type EditMinimumNoticeProps = {
  minimumNotice: TimeConfig;
  onChange: (buffer: TimeConfig) => unknown;
  className?: string;
};

export function EditMinimumNotice({
  minimumNotice,
  onChange,
  className,
}: EditMinimumNoticeProps) {
  return (
    <FormGroup
      className={className}
      label="Minimum notice"
      subText="Set the minimum amount of notice that is required before an interview can be scheduled."
    >
      <TimeConfigSelect value={minimumNotice} onChange={onChange} />
    </FormGroup>
  );
}
