import { UserMembershipWithSchedulingData } from "client/components/interviewer-slots/utils/types";

export function useShowPoolLoad({
  userMembership,
  isQualified,
}: {
  userMembership: UserMembershipWithSchedulingData;
  isQualified: boolean;
}) {
  if (!userMembership.loadData) {
    return false;
  }

  // We never show pool load data for shadowers
  if (!isQualified) {
    return false;
  }

  const { loadLimitsForPool } = userMembership.loadData;

  const showPoolLoad =
    loadLimitsForPool &&
    (loadLimitsForPool.day ||
      loadLimitsForPool.week ||
      loadLimitsForPool.month ||
      loadLimitsForPool.overall);

  return !!showPoolLoad;
}
