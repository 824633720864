import type { SerializedRichBlockNode } from "../../__utils/rich-blocks";

// config
// ------

export const RICH_BLOCK_NAME = "image";

export const DEFAULT_DATA: ImageData = {
  imageUrl: "",
};

// types
// -----

export type ImageName = typeof RICH_BLOCK_NAME;

export type ImageData = {
  imageUrl: string;
  caption?: string;
};

export type SerializedImageNode = SerializedRichBlockNode<ImageName, ImageData>;
