import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { atlasEllipsisHorizontal } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { View } from "@resource/atlas/view/View";
import { strings } from "@resource/common";
import { useAuthContext } from "auth/context";
import { ActivityFeed } from "client/components/activity-feed/ActivityFeed";
import { BackButton } from "client/components/generic/layout/BackButton";
import { EditScheduledInterviewForm } from "components/EditScheduledInterview/EditScheduledInterviewForm";
import { ScheduleInterviewForInterviewDetailsFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";

import { ArchiveInterviewConfirmationDialog } from "../../__components/ArchiveInterviewConfirmation";
import { InterviewersDisplay } from "../../__components/InterviewersDisplay";
import { useScheduledInterviewActivity } from "../__hooks/useScheduledInterviewActivity";
import { AtssyncScheduledInterviewIntegrationStatus } from "./AtssyncScheduledInterviewIntegrationStatus";
import { InfoHeader } from "./InfoHeader";
import { ScheduledInterviewDetailsStatuses } from "./ScheduledInterviewDetailsStatuses";

type ScheduledInterviewDetailsLiteProps = {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
  onBack?: () => void;
};

/** Scheduled interview details page for interviews not created by Guide */
export function ScheduledInterviewDetailsLite({
  scheduledInterview,
  onBack,
}: ScheduledInterviewDetailsLiteProps) {
  const { activity, nextPage, loading } = useScheduledInterviewActivity(
    scheduledInterview.id
  );

  const [isEditing, setIsEditing] = useState(false);
  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const { user } = useAuthContext();
  const archiveInterviewDialogStore = useDialogStore();
  const menuItems = useMenuItems(
    (i) =>
      scheduledInterview
        ? [
            i.item({
              id: "edit",
              children: "Edit",
              onClick: onEdit,
              disabled: !scheduledInterview,
            }),
            scheduledInterview.atssyncScheduledInterview?.remoteWasDeleted
              ? i.item({
                  id: "delete",
                  children: "Delete interview",
                  isDestructive: true,
                  onClick: () => {
                    archiveInterviewDialogStore.show();
                  },
                })
              : null,
          ]
        : [],
    [archiveInterviewDialogStore, onEdit, scheduledInterview]
  );

  if (isEditing) {
    return (
      <EditScheduledInterviewForm
        interview={scheduledInterview}
        zoomEnabled={scheduledInterview.organization.isZoomEnabled}
        googleCalendarEnabled={
          scheduledInterview.organization.isGoogleCalendarEnabled
        }
        coderpadEnabled={
          user?.currentUserMembership?.isCoderpadEnabled ?? false
        }
        onSave={() => setIsEditing(false)}
        onCancel={() => setIsEditing(false)}
        onBack={() => setIsEditing(false)}
      />
    );
  }

  return (
    <View
      header={{
        leftActions: <BackButton onClick={onBack} />,
        title: "Interview details",
        rightActions: (
          <Menu.Root>
            <Menu.Trigger>
              <Button isGhost size="small" icon={atlasEllipsisHorizontal} />
            </Menu.Trigger>
            <Menu.Content items={menuItems} />
          </Menu.Root>
        ),
      }}
      className="bg-light-gray-200"
      content={{
        className: "px-0 py-0 text-body-md",
      }}
    >
      <div className="bg-white">
        <div className="p-6 flex flex-col space-y-6">
          <InfoHeader scheduledInterview={scheduledInterview} />
          <ScheduledInterviewDetailsStatuses
            scheduledInterview={scheduledInterview}
          />
          <AtssyncScheduledInterviewIntegrationStatus
            scheduledInterview={scheduledInterview}
          />
        </div>

        <div className="space-y-4 px-6 min-h-[20rem]">
          <span className="text-body-md-heavy">
            {strings.pluralize(
              "Participants",
              scheduledInterview.interviewers.length
            )}
          </span>
          <InterviewersDisplay interviewers={scheduledInterview.interviewers} />
        </div>
      </div>
      <ActivityFeed
        className="bg-light-gray-200"
        activity={activity}
        nextPage={nextPage}
        loading={loading}
        context="Interview"
        showHeading
      />
      <ArchiveInterviewConfirmationDialog
        store={archiveInterviewDialogStore}
        scheduledInterviewId={scheduledInterview.id}
        onCompleted={onBack}
      />
    </View>
  );
}
