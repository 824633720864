// TODO: Move out elsewhere as this is specific to Scheduling Requests
/**
 * A select component that allows you to select either a normal or high priority scheduling requests.
 */

import { atlasFlag } from "@resource/atlas/icons";
import { SelectV2, useSelectItems } from "@resource/atlas/select-v2";
import { displayNameForSchedulingRequestPriority } from "client/app/(scheduling requests)/__utils";
import { SchedulingRequestPriority } from "generated/graphql-codegen/graphql";

type PrioritySelectProps = {
  value: SchedulingRequestPriority;
  onChange: (value: SchedulingRequestPriority) => void;
};

export function usePrioritySelectItems() {
  return useSelectItems(
    (i) => [
      i.option({
        key: "normal",
        value: SchedulingRequestPriority.NORMAL,
        children: displayNameForSchedulingRequestPriority(
          SchedulingRequestPriority.NORMAL
        ),
      }),
      i.option({
        key: "high",
        value: SchedulingRequestPriority.HIGH,
        children: displayNameForSchedulingRequestPriority(
          SchedulingRequestPriority.HIGH
        ),
      }),
    ],
    []
  );
}

export function PrioritySelect({ value, onChange }: PrioritySelectProps) {
  const items = usePrioritySelectItems();
  return (
    <SelectV2.Root
      value={value}
      setValue={(v) => onChange(v as unknown as SchedulingRequestPriority)}
    >
      <SelectV2.Trigger className="w-full" icon={atlasFlag} />
      <SelectV2.Content sameWidth items={items} />
    </SelectV2.Root>
  );
}
