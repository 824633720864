import { Button } from "@resource/atlas/button/Button";
import {
  Dialog,
  DialogProps,
  DialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { ViewProps } from "@resource/atlas/view/View";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { UserMembershipForEditProfileFragment } from "generated/graphql-codegen/graphql";
import useQuery from "utils/useQuery";

import { useEditProfileState } from "./__hooks/useEditProfileState";
import {
  useUpdateProfileOnSubmit,
  UseUpdateProfileOnSubmitProps,
} from "./__hooks/useUpdateProfileOnSubmit";
import EditProfileForm from "./EditProfileForm";

const GET_USER_MEMBERSHIP = gql(`
  query GetUserMembershipForEditProfileDialog($id: String!) {
    userMembershipById(id: $id) {
      id
      ...UserMembershipForEditProfile
    }
  }
`);

export type EditProfileDialogProps = DialogProps & {
  userMembershipId: string;
  onCompleted?: UseUpdateProfileOnSubmitProps["onCompleted"];
};

const viewProps: ViewProps<"form"> = {
  header: {
    title: "Edit profile",
  },
};

export function EditProfileDialog({
  userMembershipId,
  store,
  onCompleted,
  ...dialogProps
}: EditProfileDialogProps) {
  return (
    <Dialog size="medium" {...dialogProps} store={store}>
      <ErrorBoundary
        fallback={({ onRecover }) => <DialogError onRecover={onRecover} />}
      >
        <EditProfileDialogQuery
          userMembershipId={userMembershipId}
          store={store}
          onCompleted={onCompleted}
        />
      </ErrorBoundary>
    </Dialog>
  );
}

function EditProfileDialogQuery({
  userMembershipId,
  store,
  onCompleted,
}: {
  userMembershipId: string;
  store: DialogStore;
  onCompleted?: UseUpdateProfileOnSubmitProps["onCompleted"];
}) {
  const { data } = useQuery(GET_USER_MEMBERSHIP, {
    variables: {
      id: userMembershipId,
    },
    throwOnError: false,
  });

  if (!data) {
    return <DialogLoading {...viewProps} />;
  }

  if (!data.userMembershipById) {
    return <DialogError {...viewProps} errorCode="404" />;
  }

  return (
    <EditProfileDialogDisplay
      userMembership={data.userMembershipById}
      store={store}
      onCompleted={onCompleted}
    />
  );
}

function EditProfileDialogDisplay({
  userMembership,
  store,
  onCompleted,
}: {
  userMembership: UserMembershipForEditProfileFragment;
  store: DialogStore;
  onCompleted?: UseUpdateProfileOnSubmitProps["onCompleted"];
}) {
  const state = useEditProfileState({
    userMembership,
  });
  const { form, resetForm } = state;
  const { formState, handleSubmit } = form;
  const [onSubmit, { loading: submitting }] = useUpdateProfileOnSubmit({
    userMembershipId: userMembership.id,
    onCompleted: (...props) => {
      resetForm();
      store.hide();
      onCompleted?.(...props);
    },
  });

  return (
    <EditProfileForm
      state={state}
      viewProps={{
        ...viewProps,
        onSubmit: handleSubmit(onSubmit),
        footer: {
          leftActions: (
            <Button
              onClick={() => {
                resetForm();
                store.hide();
              }}
            >
              Cancel
            </Button>
          ),
          rightActions: (
            <Button
              type="submit"
              variant="primary"
              disabled={!formState.isValid || !formState.isDirty}
              isLoading={submitting}
            >
              Save changes
            </Button>
          ),
        },
      }}
    />
  );
}
