import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { ScheduledInterviewOtherReason } from "shared/reporting/scheduled-interviews/types";

type OtherReasonSelectProps = {
  value: ScheduledInterviewOtherReason | null;
  onChange: (value: ScheduledInterviewOtherReason) => void;
};

/** Radio component for choosing OTHER reason */
export function OtherReasonSelect({ value, onChange }: OtherReasonSelectProps) {
  return (
    <div className="space-y-2">
      <RadioItem
        label="Candidate requested"
        isSelected={value === ScheduledInterviewOtherReason.CANDIDATE_REQUESTED}
        onClick={() =>
          onChange(ScheduledInterviewOtherReason.CANDIDATE_REQUESTED)
        }
      />
      <RadioItem
        label="Company initiated"
        isSelected={value === ScheduledInterviewOtherReason.COMPANY_INITIATED}
        onClick={() =>
          onChange(ScheduledInterviewOtherReason.COMPANY_INITIATED)
        }
      />
    </div>
  );
}
