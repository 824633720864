import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneFormSchema } from "client/utils/form";
import {
  useDefaultValueFromLocalStorage,
  useSyncValueWithLocalStorage,
} from "client/utils/useSyncValueWithLocalStorage";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { usePrevious } from "react-use";
import { z } from "zod";

import {
  DEFAULT_CREATE_MESSAGE,
  DEFAULT_RESCHEDULE_MESSAGE,
  getNewDefaultMessage,
} from "../__utils/default-messaging";
import { SelfScheduleRequestSelfScheduleFormProps } from "../SelfScheduleRequestSelfScheduleForm";
import { AvailableSlot } from "./useSelfScheduleSubmissionState";

export const baseSchema = z.object({
  message: z.string().optional(),
});

export type SelfScheduleRequestSelfScheduleFormData = z.infer<
  typeof baseSchema
> & {
  phone?: string;
};

export type UseSelfScheduleRequestSelfScheduleFormProps = {
  defaultPhone?: string;
  requirePhone?: boolean;
  selectedSlot?: AvailableSlot | null;
  selectedTimezone?: string;
  isRescheduling: boolean;
  interviewerName: string;
};

const LOCAL_STORAGE_PREFIX = "self-schedule";
type LocalStoreKeys = "phone";

export function useSelfScheduleRequestSelfScheduleForm({
  requirePhone,
  defaultPhone,
  selectedSlot,
  selectedTimezone,
  isRescheduling,
  interviewerName,
}: UseSelfScheduleRequestSelfScheduleFormProps): SelfScheduleRequestSelfScheduleFormProps {
  const localStoragePhone = useDefaultValueFromLocalStorage<LocalStoreKeys>({
    key: "phone",
    prefix: LOCAL_STORAGE_PREFIX,
  });

  const defaultMessage = useMemo(() => {
    if (!isRescheduling) {
      return DEFAULT_CREATE_MESSAGE;
    }

    return DEFAULT_RESCHEDULE_MESSAGE;
  }, [isRescheduling]);

  const schema = useMemo(() => {
    if (requirePhone) {
      return baseSchema.extend({
        phone: PhoneFormSchema,
      });
    }

    return baseSchema;
  }, [requirePhone]);

  const form = useForm<SelfScheduleRequestSelfScheduleFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      phone: defaultPhone ?? localStoragePhone ?? undefined,
      message: defaultMessage,
    },
    resolver: zodResolver(schema),
  });

  const { trigger, setValue } = form;
  const { phone, message } = form.watch();

  useSyncValueWithLocalStorage<LocalStoreKeys>({
    value: phone ?? "",
    key: "phone",
    prefix: LOCAL_STORAGE_PREFIX,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  /** If the default message hasn't been changed, we want to update the default based on selected slot */
  const updateDefaultMessageIfNecessary = useCallback(
    (slot: AvailableSlot | null, timezone: string) => {
      const newDefaultMessage = getNewDefaultMessage({
        slot,
        timezone,
        isRescheduling,
        currentMessage: message ?? "",
      });
      if (newDefaultMessage) {
        setValue("message", newDefaultMessage, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
    [isRescheduling, message, setValue]
  );

  const previousSelectedSlot = usePrevious(selectedSlot);
  const previousTimezone = usePrevious(selectedTimezone);

  useEffect(() => {
    if (
      selectedSlot &&
      (!isEqual(previousSelectedSlot, selectedSlot) ||
        previousTimezone !== selectedTimezone)
    ) {
      updateDefaultMessageIfNecessary(selectedSlot, selectedTimezone ?? "");
    }
  }, [
    previousSelectedSlot,
    previousTimezone,
    selectedSlot,
    selectedTimezone,
    updateDefaultMessageIfNecessary,
  ]);

  return { form, requirePhone, interviewerName };
}
