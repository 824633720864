import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { useSelectItems as useSelectItemsV2 } from "@resource/atlas/select-v2/use-select-items";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { momentTimezones, Timezone } from "shared/utils/moment-timezones";
import {
  displayTimezone,
  getReadableTimezone,
  getTimezoneOffset,
  ReadableTimezoneLabelType,
  timezonesForSearch,
} from "shared/utils/timezones";

export const useTimezoneSelectionItemsOriginal = (
  onClick: (timezone: string) => void,
  labelType: ReadableTimezoneLabelType = "default"
) => {
  const sortedItems = useMemo(
    () =>
      timezonesForSearch
        .map((timezone) => ({
          timezone,
          display: displayTimezone(timezone),
          offset: DateTime.local().setZone(timezone).offset,
          formattedOffset: getTimezoneOffset(timezone),
        }))
        .filter(
          (
            item
          ): item is {
            timezone: string;
            display: string;
            offset: number;
            formattedOffset: string;
          } => !!item.display
        )
        .sort((a, b) => {
          if (a.offset < b.offset) {
            return -1;
          }

          if (a.offset > b.offset) {
            return 1;
          }

          if (a.display < b.display) {
            return -1;
          }

          if (a.display > b.display) {
            return 1;
          }

          return 0;
        }),
    []
  );
  const items = useSelectItems(
    (i) =>
      sortedItems.map(({ timezone, display, formattedOffset }) => {
        return i.option({
          key: timezone,
          value: timezone,
          children: getReadableTimezone({
            timezone,
            labelType,
          }),
          onClick: () => {
            onClick(timezone);
          },
          renderContent: () => (
            <span className="flex items-center justify-between">
              <span
                className="truncate"
                title={typeof display === "string" ? display : undefined}
              >
                {display}
              </span>
              <span className="text-body-sm flex flex-shrink-0">
                {formattedOffset}
              </span>
            </span>
          ),
        });
      }),
    [onClick, sortedItems, labelType]
  );

  return items;
};

export const useTimezoneSelectionItems = (
  onClick: (timezone: string) => void
) => {
  const timezonesWithAbbrs = useMemo(
    () =>
      momentTimezones.map((tz: Timezone) => ({
        ...tz,
        name: `${tz.id} (${tz.currentAbbr})`,
      })),
    []
  );

  const items = useSelectItems(
    (i) =>
      timezonesWithAbbrs.map((tz) =>
        i.option({
          children: tz.name,
          key: tz.id,
          value: tz.id,
          onClick: () => {
            onClick(tz.id);
          },
        })
      ),
    [timezonesWithAbbrs, onClick]
  );

  return items;
};

export const useTimezoneSelectionItemsForV2Select = (
  onClick: (timezone: string) => void,
  labelType: ReadableTimezoneLabelType
) => {
  const timezonesWithFormattedName = useMemo(
    () =>
      momentTimezones.map((tz: Timezone) => ({
        ...tz,
        name: displayTimezone(tz.id, labelType, true) ?? "",
      })),
    [labelType]
  );

  const items = useSelectItemsV2(
    (i) =>
      timezonesWithFormattedName.map((tz) =>
        i.option({
          children: tz.name,
          key: tz.id,
          value: tz.id,
          onClick: () => {
            onClick(tz.id);
          },
        })
      ),
    [timezonesWithFormattedName, onClick]
  );

  return items;
};
