import { strings } from "@resource/common";
import { useMemo } from "react";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

interface UseLockedLabelArgs {
  interviewRequirement: {
    id: string;
    order?: number | null;
    orderLocked: boolean;
  };
  interviewRequirements?: {
    id: string;
    orderLocked: boolean;
    interview: {
      title: string;
    };
  }[];
}

export function useLockedLabel({
  interviewRequirement,
  interviewRequirements,
}: UseLockedLabelArgs) {
  return useMemo(() => {
    if (interviewRequirement.orderLocked) {
      return `Locked to ${strings.addSuffixToNumber(
        interviewRequirement.order ?? 0
      )} position`; // should never be null but cast to ensure no error
    }

    if (!interviewRequirements) {
      return "Ordered any position";
    }

    const thisIndex = interviewRequirements.findIndex(
      (req) => req.id === interviewRequirement.id
    );
    const interviewBefore = interviewRequirements.findLast(
      (req, index) => req.orderLocked && index < thisIndex
    );
    const interviewAfter = interviewRequirements.find(
      (req, index) => req.orderLocked && index > thisIndex
    );

    if (interviewBefore && interviewAfter) {
      return `Ordered any position between ${getTitleFromLexicalJson(
        interviewBefore.interview.title
      )} and ${getTitleFromLexicalJson(interviewAfter.interview.title)}`;
    }
    if (interviewBefore) {
      return `Ordered any position after ${getTitleFromLexicalJson(
        interviewBefore.interview.title
      )}`;
    }
    if (interviewAfter) {
      return `Ordered any position before ${getTitleFromLexicalJson(
        interviewAfter.interview.title
      )}`;
    }

    return "Ordered any position";
  }, [
    interviewRequirements,
    interviewRequirement.id,
    interviewRequirement.order,
    interviewRequirement.orderLocked,
  ]);
}
