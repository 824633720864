import { Button as AriaButton } from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import Badge from "@resource/atlas/badge/Badge";
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasEllipsisHorizontal,
  atlasSchedulingTypeRescheduleColor,
  atlasSchedulingTypeScheduleColor,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useLogEvent } from "analytics";
import { SchedulingTaskCommentsIcon } from "client/components/SchedulingTaskCommentsIcon";
import { SchedulingTaskNotesIcon } from "client/components/SchedulingTaskNotesIcon";
import StopPropagation from "components/StopPropagation";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingRequestForSchedulingRequestCardFragment,
  SchedulingRequestStatus,
} from "generated/graphql-codegen/graphql";
import { formatEntity } from "shared/constants/entities";

import { useReopenSchedulingRequest } from "../hooks/useReopenSchedulingRequest";
import { useSchedulingTaskMenuItems } from "../hooks/useSchedulingTaskMenuItems";
import { SchedulingRequestStatusBadge } from "../SchedulingRequestStatusBadge";

gql(`
  fragment SchedulingRequestForSchedulingRequestCard on SchedulingRequest {
    id
    status
    taskStatus
    guideId
    organizationId
    archivedAt
    isReschedule
    notes
    comments {
      ...SchedulingRequestTableComment
    }
    assignedTo {
      id
      name
      imageUrl
    }
    atssyncJobInterviewStage {
      id
      name
    }
    guide {
      id
      candidate {
        id
        name: internalName
      }
      job {
        id
        internalName
      }
    }
  }
`);

export function SchedulingRequestCard({
  schedulingRequest,
  variant = "default",
  onClick,
}: {
  schedulingRequest: SchedulingRequestForSchedulingRequestCardFragment;
  variant?: "candidate-profile" | "default";
  onClick: () => void;
}) {
  const [reopenSchedulingRequest] = useReopenSchedulingRequest();
  const isCompleted =
    schedulingRequest.status === SchedulingRequestStatus.COMPLETED;
  const isArchived = !!schedulingRequest.archivedAt;
  const { guide } = schedulingRequest;
  const isDraft = schedulingRequest.status === SchedulingRequestStatus.DRAFT;
  const logEvent = useLogEvent({
    component: "SchedulingRequestCard",
  });
  const menuItems = useSchedulingTaskMenuItems({
    schedulingRequestId: schedulingRequest.id,
    isCompleted,
    isArchived,
    analyticsComponent: "SchedulingRequestCard",
    onReopen: () => {
      reopenSchedulingRequest({
        variables: {
          input: {
            id: schedulingRequest.id,
          },
        },
      });
    },
  });

  const wrappedOnClick = () => {
    onClick();
    logEvent("Scheduling Request Clicked", {
      id: schedulingRequest.id,
      isDraft,
    });
  };

  const jobName = schedulingRequest.guide.job.internalName;
  const stageName = schedulingRequest.atssyncJobInterviewStage?.name;

  return (
    <AriaButton
      onClick={wrappedOnClick}
      className="rounded-md shadow-1 text-left w-full text-body-md overflow-hidden"
    >
      <div className="p-4 space-y-3">
        <div className="flex justify-between gap-2 items-start">
          <div className="flex items-start gap-3 overflow-hidden">
            <Icon
              className="w-5 h-5"
              content={
                schedulingRequest.isReschedule
                  ? atlasSchedulingTypeRescheduleColor
                  : atlasSchedulingTypeScheduleColor
              }
            />
            <div className="truncate">
              <span className="flex space-x-1 items-center">
                <p className="text-body-md-heavy truncate">
                  {variant === "candidate-profile" ? (
                    <>
                      {formatEntity(
                        schedulingRequest.isReschedule
                          ? "rescheduling request"
                          : "scheduling request",
                        { capitalize: true }
                      )}
                    </>
                  ) : (
                    guide.candidate.name
                  )}
                </p>
              </span>
              <p className="text-body-sm text-subtle truncate">
                {`${jobName}${stageName ? ` / ${stageName}` : ""}`}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-3">
            {schedulingRequest.status === SchedulingRequestStatus.DRAFT && (
              <Badge variant="default" size="small" styles="bold">
                Draft
              </Badge>
            )}
            <StopPropagation className="self-start">
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div onClick={(e) => e.preventDefault()}>
                <Menu.Root>
                  <Menu.Trigger>
                    <Button
                      negativeMargin="right"
                      icon={atlasEllipsisHorizontal}
                      isGhost
                      size="xs"
                      className="-mb-2"
                    />
                  </Menu.Trigger>
                  <Menu.Content
                    portal
                    defaultSize="compact"
                    items={menuItems}
                  />
                </Menu.Root>
              </div>
            </StopPropagation>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex gap-3">
            <Avatar
              tooltip={
                !schedulingRequest.assignedTo ? "No assignee" : undefined
              }
              contentClassName={
                !schedulingRequest.assignedTo
                  ? "border !border-dashed !bg-white"
                  : undefined
              }
              size="xs"
              name={schedulingRequest.assignedTo?.name}
              image={schedulingRequest.assignedTo?.imageUrl}
            />

            <SchedulingRequestStatusBadge
              schedulingRequest={schedulingRequest}
              showCompletedArchivedStatuses
            />
          </div>
          <div className="flex gap-2 items-center">
            {schedulingRequest.comments.length > 0 && (
              <SchedulingTaskCommentsIcon
                comments={schedulingRequest.comments}
              />
            )}
            {schedulingRequest.notes && (
              <SchedulingTaskNotesIcon notes={schedulingRequest.notes} />
            )}
          </div>
        </div>
      </div>
    </AriaButton>
  );
}
