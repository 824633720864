/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any, no-underscore-dangle */
import { mergeRegister } from "@lexical/utils";
import {
  $isDecoratorNode,
  $isElementNode,
  DecoratorNode,
  ElementNode,
  Klass,
  LexicalEditor,
  LexicalNode,
} from "lexical";
import type { Transform } from "lexical/LexicalEditor";

// target
// ------

type TargetMap = {
  any: LexicalNode;
  element: ElementNode;
  decorator: DecoratorNode<unknown>;
  block: ElementNode | DecoratorNode<unknown>;
  inline: ElementNode | DecoratorNode<unknown>;
  "block-element": ElementNode;
  "inline-element": ElementNode;
  "block-decorator": DecoratorNode<unknown>;
  "inline-decorator": DecoratorNode<unknown>;
};

type Target = Klass<LexicalNode> | keyof TargetMap;

type TargetInstance<T extends Target> = T extends Klass<LexicalNode>
  ? InstanceType<T>
  : T extends keyof TargetMap
  ? TargetMap[T]
  : never;

const editorNodesCache = new WeakMap<LexicalEditor, Set<Klass<LexicalNode>>>();

function getEditorNodes(editor: LexicalEditor) {
  const cacheEntry = editorNodesCache.get(editor);
  if (cacheEntry) return cacheEntry;

  const entry = new Set<Klass<LexicalNode>>();
  editorNodesCache.set(editor, entry);
  editor._nodes.forEach((value) => entry.add(value.klass));
  return entry;
}

function isString<T>(value: T | string): value is string {
  return typeof value === "string";
}

function resolveStringTarget<T extends keyof TargetMap>(
  nodes: Set<Klass<LexicalNode>>,
  target: T
) {
  const targetNodes = new Set(nodes);

  if (target === "any") nodes.forEach((node) => targetNodes.add(node));
  else {
    if (target.endsWith("element"))
      nodes.forEach(
        (node) => !$isElementNode(node.prototype) && targetNodes.delete(node)
      );

    if (target.endsWith("decorator"))
      nodes.forEach(
        (node) => !$isDecoratorNode(node.prototype) && targetNodes.delete(node)
      );

    if (target.startsWith("block"))
      nodes.forEach(
        (node) =>
          node.prototype.isInline?.() === true && targetNodes.delete(node)
      );

    if (target.startsWith("inline"))
      nodes.forEach(
        (node) =>
          node.prototype.isInline?.() === false && targetNodes.delete(node)
      );
  }

  return targetNodes;
}

function resolveTarget<T extends Target>(
  nodes: Set<Klass<LexicalNode>>,
  target: T | T[]
) {
  const targets = Array.isArray(target) ? target : [target];

  const targetNodes = new Set<Klass<LexicalNode>>();

  // node class targets
  targets.forEach((t) => !isString(t) && targetNodes.add(t));

  // string targets
  const stringTargets = targets.filter(isString);

  stringTargets.forEach((t) =>
    resolveStringTarget(nodes, t as keyof TargetMap).forEach((node) =>
      targetNodes.add(node)
    )
  );

  return [...targetNodes];
}

// register node transform
// -----------------------

export function registerNodeTransform<T extends Target>(
  editor: LexicalEditor,
  target: T | T[],
  listener: Transform<TargetInstance<T>>
) {
  const nodes = getEditorNodes(editor);
  return mergeRegister(
    ...resolveTarget(nodes, target).map((node) =>
      editor.registerNodeTransform(node, listener as any)
    )
  );
}
