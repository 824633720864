import { useEffect, useMemo } from "react";
import useDebouncedValue from "react-hooks/useDebouncedValue";
import { usePrevious } from "react-use";

/** Wraps useDebouncedValue to trigger an onChange with debouncing */
export function useDebouncedOnChange({
  defaultValue,
  onChange,
}: {
  defaultValue: string;
  onChange: (debouncedValue: string) => void;
}) {
  const { value, setValue, debouncedValue } = useDebouncedValue(
    defaultValue,
    300
  );

  const previousDebouncedValue = usePrevious(debouncedValue);

  useEffect(() => {
    if (previousDebouncedValue !== debouncedValue) {
      onChange(debouncedValue);
    }
  }, [debouncedValue, previousDebouncedValue, onChange]);

  return useMemo(
    () => ({
      value,
      setValue,
    }),
    [setValue, value]
  );
}
