import { getVideoConferencingTypeFromConferencingType } from "client/components/conferencing/utils/mapping";
import {
  ConferencingSlot,
  ConferencingType,
} from "client/components/conferencing/utils/types";
import {
  mapInterviewRequirementToUnscheduledInterviewForCard,
  mapScheduledInterviewToUnscheduledInterviewForCard,
} from "client/components/interview-requirements/UnscheduledInterviewCard/utils/mapping";
import {
  Interviewer,
  InterviewerSlot,
} from "client/components/interviewer-slots/utils/types";
import { ScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard";
import {
  mapInterviewRequirementToCreatingInterview,
  mapScheduledInterviewToEditingInterview,
} from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/mapping";
import {
  InterviewRequirementForSchedulerV3Fragment,
  OrganizationForSchedulerV3Fragment,
  ScheduledInterviewForSchedulerV3Fragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";

import {
  SchedulerEditableScheduledInterview,
  SchedulerOrganization,
  SchedulerStagedScheduledInterview,
} from "./types";

function mapConferencingSlotToScheduledInterviewForCard(
  conferencingSlot: ConferencingSlot | null | undefined
): Pick<
  ScheduledInterviewForCard,
  "videoConferencingService" | "videoConferencingUrl" | "conferencePhone"
> {
  if (!conferencingSlot) {
    return {
      videoConferencingService: null,
    };
  }

  if (
    conferencingSlot.service === ConferencingType.PHONE &&
    conferencingSlot.editingConferencing?.value
  ) {
    return {
      videoConferencingService: null,
      conferencePhone: conferencingSlot.editingConferencing.value,
    };
  }

  return {
    videoConferencingService: conferencingSlot.service
      ? getVideoConferencingTypeFromConferencingType(conferencingSlot.service)
      : null,
    videoConferencingUrl: conferencingSlot.editingConferencing?.value,
  };
}

function mapInterviewerForCard({
  interviewer,
  isShadow,
}: {
  interviewer: Interviewer;
  isShadow: boolean;
}): ScheduledInterviewForCard["interviewers"][number] {
  return {
    id: interviewer.id,
    isShadow,
    responseStatus: interviewer.responseStatus ?? null,
    userMembership: interviewer.userMembership,
  };
}

function mapInterviewerSlotsToScheduledInterviewForCard(
  interviewerSlots: InterviewerSlot[]
): ScheduledInterviewForCard["interviewers"] {
  return interviewerSlots.flatMap((slot) => {
    return [
      ...(slot.interviewer
        ? [
            mapInterviewerForCard({
              interviewer: slot.interviewer,
              isShadow: false,
            }),
          ]
        : []),
      ...(slot.shadowingInterviewer
        ? [
            mapInterviewerForCard({
              interviewer: slot.shadowingInterviewer,
              isShadow: true,
            }),
          ]
        : []),
    ];
  });
}

export const mapSchedulerInterviewToScheduledInterviewForCard = (
  scheduledInterview: SchedulerStagedScheduledInterview
): ScheduledInterviewForCard => {
  const { collaborativeCodingSlot } = scheduledInterview;
  return {
    id: scheduledInterview.id,
    title: scheduledInterview.title,
    startTime: DateTime.fromISO(scheduledInterview.startTime),
    endTime: DateTime.fromISO(scheduledInterview.endTime),
    collaborativeCodingService: collaborativeCodingSlot?.service ?? null,
    collaborativeCodingUrl:
      collaborativeCodingSlot?.editingCollaborativeCoding?.value ??
      collaborativeCodingSlot?.existingCollaborativeCoding?.value ??
      null,
    ...mapConferencingSlotToScheduledInterviewForCard(
      scheduledInterview.conferencingSlot
    ),
    interviewers: mapInterviewerSlotsToScheduledInterviewForCard(
      scheduledInterview.interviewerSlots
    ),
    isCancelled: scheduledInterview.isCancelled,
    createdByGuide: true,
    isPrivate: scheduledInterview.isPrivate,
    blacklisted: scheduledInterview.blacklisted,
    googleEvent: null,
    atssyncScheduledInterview: null,
    googleSyncStatus: null,
    greenhouseSyncStatus: null,
  };
};

export function mapScheduledInterviewFragmentToSchedulerInterview(
  scheduledInterview: ScheduledInterviewForSchedulerV3Fragment
  // Note: It should be possible to map this as StagedSchedulerEditableScheduledInterview,
  // but there's something with StagedConferenceRoom not allowing nullable properties.
): SchedulerEditableScheduledInterview {
  return {
    pendingRescheduleInterviewRequirement:
      scheduledInterview.pendingRescheduleInterviewRequirement,
    ...mapScheduledInterviewToUnscheduledInterviewForCard(scheduledInterview),
    ...mapScheduledInterviewToEditingInterview({ scheduledInterview }),
  };
}

export function mapInterviewRequirementFragmentToSchedulerInterview(
  interviewRequirement: InterviewRequirementForSchedulerV3Fragment
): SchedulerEditableScheduledInterview {
  return {
    pendingRescheduleInterviewRequirement: null,
    ...mapInterviewRequirementToUnscheduledInterviewForCard(
      interviewRequirement
    ),
    ...mapInterviewRequirementToCreatingInterview(interviewRequirement),
  };
}

export const mapOrganizationFragmentToOrganization = (
  organization: OrganizationForSchedulerV3Fragment
): SchedulerOrganization => {
  return {
    id: organization.id,
    blockedDates:
      organization.blockedDates.map((blockedDate) => ({
        startDay: DateTime.fromISO(blockedDate.startDay),
        endDay: DateTime.fromISO(blockedDate.endDay),
      })) ?? [],
  };
};
