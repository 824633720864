/* eslint-disable import/prefer-default-export */

import type { AtlasMenuState } from "../../../../menu";
import { useTypeaheadControl } from "./use-typeahead-control";

type TypeaheadMenuControlOptions = {
  open: boolean;
  hide: () => void;
};

export function useTypeaheadMenuControl(
  menu: AtlasMenuState,
  { open, hide }: TypeaheadMenuControlOptions
) {
  function setActiveIdAndScrollIntoView(id: string | null | undefined) {
    if (!id) return;
    menu.setActiveId(id);
    document.getElementById(id)?.scrollIntoView({ block: "nearest" });
  }

  function selectActiveItemAndHide() {
    if (menu.activeId) document.getElementById(menu.activeId)?.click();
    hide();
  }

  useTypeaheadControl({
    open,
    onEscape: hide,
    onEnter: selectActiveItemAndHide,
    onTab: selectActiveItemAndHide,
    onArrowDown: () => setActiveIdAndScrollIntoView(menu.down()),
    onArrowUp: () => setActiveIdAndScrollIntoView(menu.up()),
  });
}
