export const EXTENSION_DIMS = {
  popoverCloseButton: {
    width: 40,
    height: 40,
    padding: {
      right: 12,
      top: 12,
    },
    animation: {
      opacityTransitionMs: 80,
    },
  },
  floatingButton: {
    width: 56,
    height: 56,
    padding: {
      bottom: 16,
      right: 16,
    },
  },
  panel: {
    width: 420,
    padding: {
      right: 16,
      left: 16,
      bottom: 16,
      top: 16,
    },
    animations: {
      widthTransitionMs: 200,
      heightTransitionMs: 200,
      transformTransitionMs: 300,
      opacityTransitionMs: 83,
    },
  },
  floatingPopoverNavigator: {
    width: 237,
    closed: {
      width: 56,
      height: 56,
    },
    open: {
      width: {
        interviewer: 254,
        hiringTeam: 395,
        hiringTeamWithoutNotifications: 355,
      },
      height: 56,
    },
  },
};
