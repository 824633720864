import { useEffect } from "react";

import { InterviewConflictHovercardData } from "./useHoveringInterviewConflictData";
import { InterviewConflictHovercardState } from "./useInterviewConflictHovercardState";

export function useHideAndShowInterviewConflictHovercard({
  data: { hasConflicts, hasWhitelistedEventConflicts },
  state: { hovercardState },
}: {
  data: InterviewConflictHovercardData;
  state: InterviewConflictHovercardState;
}) {
  useEffect(() => {
    if (hasConflicts || hasWhitelistedEventConflicts) {
      hovercardState.show();
    } else {
      hovercardState.hide();
    }
  }, [hovercardState, hasConflicts, hasWhitelistedEventConflicts]);
}
