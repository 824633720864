import { InterviewerMultiSelectItem } from "client/components/scheduled-interviews/ReportingReasons/__components/InterviewerMultiSelect";
import { InterviewerResponse } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { useMemo } from "react";

import {
  RescheduleableInterview,
  SchedulingRequestFormState,
} from "./useSchedulingRequestFormState";

export const useSelectedInterviewsForReschedule = ({
  schedulingRequestFormState,
}: {
  schedulingRequestFormState: SchedulingRequestFormState;
}) => {
  return useMemo(() => {
    if (schedulingRequestFormState.formType !== "rescheduling") {
      return [];
    }

    const {
      rescheduleRequestState: {
        selectedRescheduleInterviewRequirementIdsV1,
        selectedRescheduleScheduledInterviewIdsV2,
        reschedulableInterviews,
      },
    } = schedulingRequestFormState;

    return reschedulableInterviews.filter((interview) => {
      if (selectedRescheduleInterviewRequirementIdsV1.length > 0) {
        return selectedRescheduleInterviewRequirementIdsV1.includes(
          interview.interviewRequirementId
        );
      }

      if (selectedRescheduleScheduledInterviewIdsV2.length > 0) {
        return selectedRescheduleScheduledInterviewIdsV2.includes(interview.id);
      }

      return false;
    });
  }, [schedulingRequestFormState]);
};

export const useSelectedInterviewersForReschedule = ({
  interviews,
}: {
  interviews: RescheduleableInterview[];
}): InterviewerMultiSelectItem[] => {
  return useMemo(() => {
    return (
      _(interviews)
        .flatMap("interviewers")
        .groupBy("userMembership.id")
        // Prioritize declined interviewers to the top, followed by those with a status
        .map((group) => {
          const prioritizedInterviewer = _.maxBy(group, (interviewer) => {
            if (interviewer.responseStatus === InterviewerResponse.NEEDS_ACTION)
              return 0;
            if (interviewer.responseStatus === InterviewerResponse.DECLINED)
              return 2;
            return 1;
          });
          return prioritizedInterviewer;
        })
        .map(({ userMembership, responseStatus }) => ({
          userMembershipId: userMembership.id,
          name: userMembership.name,
          imageUrl: userMembership.imageUrl,
          email: userMembership.email,
          responseStatus,
        }))
        .value()
    );
  }, [interviews]);
};
