import { MutationHookOptions } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import {
  CancelSchedulingRequestInput,
  CancelSchedulingRequestMutation,
  Exact,
} from "generated/graphql-codegen/graphql";
import useMutation from "utils/useMutation";

const CANCEL_SCHEDULING_REQUEST = gql(`
  mutation CancelSchedulingRequest($input: CancelSchedulingRequestInput!) {
    cancelSchedulingRequest(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        status
        taskStatus
        archivedAt
        guideId
        organizationId
      }
    }
  }
`);

export function useArchiveSchedulingRequest(
  options?: MutationHookOptions<
    CancelSchedulingRequestMutation,
    Exact<{
      input: CancelSchedulingRequestInput;
    }>
  >
) {
  return useMutation(CANCEL_SCHEDULING_REQUEST, {
    ...options,
  });
}
