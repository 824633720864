import { useContext } from "react";

import { TimezoneContext } from "./context";

/**
 * Get the timezone from the context or use the passed timezone if it exists
 * @param passedTimezone The timezone to use if it exists
 * @returns The timezone to use
 */
export function useTimezone(passedTimezone?: string | null) {
  const contextTimezone = useContext(TimezoneContext);

  return passedTimezone ?? contextTimezone;
}
