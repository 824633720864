import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCircleCheckedColor,
  atlasCircleCrossColor,
} from "@resource/atlas/icons";
import { ActivityVerb } from "generated/graphql-codegen/graphql";
import { get } from "lodash";
import { InterviewActivityVerbs } from "shared/activity";
import { ScheduledInterviewFieldChange } from "shared/guide-scheduler/field-changes/types";
import { actorFieldChangesText } from "shared/guide-scheduler/field-changes/utils";
import { HistoricalScheduledInterview } from "shared/historical-data/scheduled-interviews/types";

import { HistoricalScheduledInterviewCard } from "../scheduled-interviews/ScheduledInterviewCard/HistoricalScheduledInterviewCard";
import { ActorDisplayAvatar, ActorDisplayContent } from "./__utils/common";
import {
  ActivityFeedMapper,
  ActivityFunctionMap,
  ActivityGroupFunctionMap,
  FeedContext,
} from "./__utils/types";

function interviewLabel(context: FeedContext) {
  return context === "Interview" ? "this interview" : "an interview";
}

const renderHistoricalScheduledInterviewCard = (meta?: {
  historicalScheduledInterview?: HistoricalScheduledInterview | null;
}) => {
  const historicalScheduledInterview = get(
    meta,
    "historicalScheduledInterview"
  );

  if (!historicalScheduledInterview) return null;

  return (
    <HistoricalScheduledInterviewCard
      historicalScheduledInterview={
        historicalScheduledInterview as unknown as HistoricalScheduledInterview
      }
    />
  );
};

export class InterviewActivityFeed extends ActivityFeedMapper<InterviewActivityVerbs> {
  protected isGroupableVerb(_verb: ActivityVerb) {
    return false;
  }

  readonly activityGroupMap: ActivityGroupFunctionMap<InterviewActivityVerbs> =
    {
      InterviewConfirmed: null,
      InterviewerInvited: null,
      InterviewerRemoved: null,
      InterviewRestored: null,
      InterviewScheduled: null,
      InterviewerRsvp: null,
      InterviewCompleted: null,
      InterviewCancelled: null,
      InterviewRescheduled: null,
      InterviewUpdated: null,
      InterviewerNoShowed: null,
      InterviewInterviewerChanged: null,
      InterviewCompletedForInterviewer: null,
      InterviewNeedsReschedule: null,
      InterviewerPoolTrainingUserCompletedShadow: null,
      InterviewerPoolTrainingUserCompletedReverseShadow: null,
    };

  readonly activityMap: ActivityFunctionMap<InterviewActivityVerbs> = {
    // Needed for Reporting but not shown to the customer
    InterviewConfirmed: null,
    InterviewerInvited: null,
    InterviewerRemoved: null,
    InterviewRestored: null,
    InterviewCompletedForInterviewer: null,
    // TODO: ENG-3569 Bring this back once Collecting Reporting is fully shipped
    [ActivityVerb.InterviewNeedsReschedule]: null,
    InterviewScheduled: ({ actor, meta, ...rest }) => {
      return {
        content: (
          <ActorDisplayContent
            {...rest}
            actor={actor}
            actionText={`scheduled ${interviewLabel(rest.context)}`}
          />
        ),
        icon: <ActorDisplayAvatar actor={actor} />,
        extraContent: renderHistoricalScheduledInterviewCard(meta),
      };
    },
    InterviewerRsvp: ({ userMembership, meta, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={userMembership}
          actionText={
            <>
              {(meta.responseStatus ?? meta.response)?.toLowerCase()}{" "}
              {interviewLabel(rest.context)} invite
              {meta.responseNote && ` (Reason: ${meta.responseNote})`}
              {!meta.recordedByInterviewer && " (Updated by coordinator)"}
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={userMembership} />,
    }),
    InterviewCompleted: ({ meta }) => ({
      content: <span>Interview was completed.</span>,
      icon: (
        <Icon
          content={atlasCircleCheckedColor}
          className="text-green-500 w-6 h-6"
        />
      ),
      extraContent: renderHistoricalScheduledInterviewCard(meta),
    }),
    InterviewCancelled: ({ actor, meta, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`cancelled ${interviewLabel(rest.context)}.`}
        />
      ),
      icon: (
        <Icon
          content={atlasCircleCrossColor}
          className="text-red-500 w-6 h-6"
        />
      ),
      extraContent: renderHistoricalScheduledInterviewCard(meta),
    }),
    InterviewRescheduled: ({ actor, meta, ...rest }) => {
      const fieldChanges = get(meta, "fieldChanges");
      const nonRescheduleFieldChanges = fieldChanges?.filter(
        (fieldChange) => fieldChange !== ScheduledInterviewFieldChange.TIME
      );

      return {
        content: (
          <ActorDisplayContent
            {...rest}
            actor={actor}
            actionText={`${
              nonRescheduleFieldChanges?.length
                ? `${actorFieldChangesText({
                    fieldChanges: nonRescheduleFieldChanges,
                    isPrefix: true,
                  })} and `
                : ""
            }rescheduled ${interviewLabel(rest.context)}`}
          />
        ),
        icon: <ActorDisplayAvatar actor={actor} />,
        extraContent: renderHistoricalScheduledInterviewCard(meta),
      };
    },
    [ActivityVerb.InterviewInterviewerChanged]: ({ actor, meta, ...rest }) => {
      const fieldChanges = get(meta, "fieldChanges");
      const nonInterviewerSwapFieldChanges = fieldChanges?.filter(
        (fieldChange) =>
          fieldChange !== ScheduledInterviewFieldChange.INTERVIEWERS
      );

      return {
        content: (
          <ActorDisplayContent
            {...rest}
            actor={actor}
            actionText={`${
              nonInterviewerSwapFieldChanges?.length
                ? `${actorFieldChangesText({
                    fieldChanges: nonInterviewerSwapFieldChanges,
                    isPrefix: true,
                  })} and `
                : ""
            }changed the interviewers for ${interviewLabel(rest.context)}`}
          />
        ),
        extraContent: renderHistoricalScheduledInterviewCard(meta),
        icon: <ActorDisplayAvatar actor={actor} />,
      };
    },
    [ActivityVerb.InterviewUpdated]: ({ actor, meta, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            get(meta, "fieldChanges")
              ? `${actorFieldChangesText({
                  fieldChanges: meta.fieldChanges ?? [],
                })}${
                  // We already say `restored the interview` in this case
                  meta.fieldChanges?.includes(
                    ScheduledInterviewFieldChange.RESTORED
                  )
                    ? ""
                    : ` for ${interviewLabel(rest.context)}`
                }.`
              : `updated ${interviewLabel(rest.context)}`
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
      extraContent: renderHistoricalScheduledInterviewCard(meta),
    }),
    [ActivityVerb.InterviewerNoShowed]: ({
      actor,
      userMembership,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            <>
              marked{" "}
              <span className="text-dark text-body-md-heavy">
                {userMembership?.name}
              </span>{" "}
              as a no-show for {interviewLabel(rest.context)}
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),

    [ActivityVerb.InterviewerPoolTrainingUserCompletedShadow]: ({
      userMembership,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={userMembership}
          actionText={
            <>
              completed a shadow interview for {meta.poolName}
              {meta.isGraduation && " and graduated"}
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={userMembership} />,
      extraContent: renderHistoricalScheduledInterviewCard(meta),
    }),

    [ActivityVerb.InterviewerPoolTrainingUserCompletedReverseShadow]: ({
      userMembership,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={userMembership}
          actionText={
            <>
              completed a reverse shadow interview for {meta.poolName}
              {meta.isGraduation && " and graduated"}
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={userMembership} />,
      extraContent: renderHistoricalScheduledInterviewCard(meta),
    }),
  };
}
