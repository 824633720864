import {
  ActivityForFeedFragment,
  ActivityVerb,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { ReactNode } from "react";
import { ActivityRequiredFields } from "shared/activity";

export type FeedContext =
  | "Guide"
  | "SchedulingRequest"
  | "InterviewerPool"
  | "InterviewerPoolUser"
  | "Interview";

export type BaseProps = {
  guideUrl?: string;
  candidateName?: string;
  context: FeedContext;
};

export type ActivityFunctionMap<T extends keyof typeof ActivityVerb> = {
  [K in T]:
    | ((
        params: BaseProps &
          Omit<ActivityForFeedFragment, "meta"> & {
            meta: "meta" extends keyof ActivityRequiredFields<K>
              ? ActivityRequiredFields<K>["meta"]
              : never;
          }
      ) => { content: ReactNode; icon: ReactNode; extraContent?: ReactNode })
    | null;
};

export type ActivityGroupFunctionMap<T extends keyof typeof ActivityVerb> = {
  [K in T]:
    | ((
        params: BaseProps & {
          activities: (Omit<ActivityForFeedFragment, "meta"> & {
            meta: "meta" extends keyof ActivityRequiredFields<K>
              ? ActivityRequiredFields<K>["meta"]
              : never;
          })[];
        }
      ) => {
        content: ReactNode;
        icon: ReactNode;
        extraContent?: ReactNode;
      })
    | null;
};

export abstract class ActivityFeedMapper<T extends keyof typeof ActivityVerb> {
  abstract readonly activityMap: ActivityFunctionMap<T>;

  abstract readonly activityGroupMap: ActivityGroupFunctionMap<T>;

  protected abstract isGroupableVerb(verb: ActivityVerb): boolean;

  groupActivity(activity: ActivityForFeedFragment[]) {
    const sortedActivity = [...activity].sort(
      (a, b) =>
        DateTime.fromISO(b.eventTime).toMillis() -
        DateTime.fromISO(a.eventTime).toMillis()
    );

    // TODO: If there is an event that breaks up the grouping then we don't group when we probably still should. e.g. if the candidate
    // viewed their guide, read a post, viewed their guide again all within the time period we group for then we should group the two
    // guide viewed activities together, but they are currently kept separate because the event that breaks them up.
    return sortedActivity.reduce(
      (acc: ActivityForFeedFragment[][], curr, i, arr) => {
        const lastActivity = arr[i - 1] as ActivityForFeedFragment | undefined;

        if (
          i === 0 ||
          !this.isGroupableVerb(curr.verb) ||
          curr.verb !== lastActivity?.verb ||
          Math.abs(
            DateTime.fromISO(curr.eventTime).diff(
              DateTime.fromISO(lastActivity?.eventTime),
              "minutes"
            ).minutes
          ) > 1
        ) {
          acc.push([curr]);
        } else {
          acc[acc.length - 1].push(curr);
        }

        return acc;
      },
      []
    );
  }
}
