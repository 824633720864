import clsx from "clsx";

import { useCalendarData } from "../../hooks/data";
import { useCalendarOptions } from "../../hooks/options";
import { useCalendarSettings } from "../../hooks/settings";
import { useCalendarSizes } from "../../hooks/sizing";
import { useColumns } from "../../hooks/useColumns";
import { useMapEventGroupWithComputedOverrides } from "../../hooks/useMapEventGroupWithComputedOverrides";
import { CalendarEvent } from "../../utils/types";
import { Event } from "../events/Event";
import { EventsGridSizingWrapper } from "../events/EventsGridSizingWrapper";
import { EventGroup } from "../events/EventsGroup";
import { VerticalLines } from "./VerticalLines";

export function AllDayEventsView() {
  const { allDayEvents } = useCalendarData();
  const calendarSizes = useCalendarSizes();

  if (!allDayEvents.length) {
    return null;
  }

  return (
    <div
      className="w-full grid flex-auto grid-cols-1 grid-rows-1 bg-white"
      style={{
        height: `${calendarSizes.allDayEventsViewHeight.rem}rem`,
      }}
    >
      <VerticalLines includeLeftOffsetMargin={false} />
      <AllDayEvents />
    </div>
  );
}

function AllDayEvents() {
  const { columnType } = useColumns();

  return (
    <EventsGridSizingWrapper
      className={clsx("text-sm flex w-full", "border-b border-gray-border")}
    >
      {columnType === "day" ? (
        <DayGroupedAllDayEvents />
      ) : (
        <CalendarGroupedAllDayEvents />
      )}
    </EventsGridSizingWrapper>
  );
}

function DayGroupedAllDayEvents() {
  const { allDayEventGroups } = useCalendarData();
  const mapEventGroup = useMapEventGroupWithComputedOverrides();

  return (
    <>
      {allDayEventGroups.map((eventGroup) => {
        const mappedEventGroup = mapEventGroup({
          eventGroup,
        });

        return (
          <EventOrEventGroup
            eventGroup={mappedEventGroup}
            key={eventGroupKey(eventGroup)}
            hideEventTime
          />
        );
      })}
    </>
  );
}

function CalendarGroupedAllDayEvents() {
  const { allDayEventGroupsByCalendar } = useCalendarData();
  const { dayViewType } = useCalendarSettings();
  const { dayViewCalendarIds, calendarsGrouping } = useCalendarOptions();
  const mapEventGroup = useMapEventGroupWithComputedOverrides();

  if (dayViewType === "calendar_and_grouping" && calendarsGrouping) {
    return (
      <>
        {calendarsGrouping.map((grouping, calendarGroupIdx) => {
          return grouping.calendarIds.map((calendarId) => {
            return allDayEventGroupsByCalendar[calendarId]?.map(
              (eventGroup) => {
                const mappedEventGroup = mapEventGroup({
                  eventGroup,
                  groupingId: grouping.id,
                  calendarId,
                });

                return (
                  <EventOrEventGroup
                    eventGroup={mappedEventGroup}
                    key={eventGroupKey(eventGroup)}
                    calendarId={calendarId}
                    calendarGroupIdx={calendarGroupIdx}
                    hideEventTime
                  />
                );
              }
            );
          });
        })}
      </>
    );
  }

  return (
    <>
      {dayViewCalendarIds.map((calendarId) => {
        return allDayEventGroupsByCalendar[calendarId]?.map((eventGroup) => {
          const mappedEventGroup = mapEventGroup({
            eventGroup,
            calendarId,
          });

          return (
            <EventOrEventGroup
              eventGroup={mappedEventGroup}
              key={eventGroupKey(eventGroup)}
              calendarId={calendarId}
              hideEventTime
            />
          );
        });
      })}
    </>
  );
}

function eventGroupKey(eventGroup: CalendarEvent[]) {
  return `${eventGroup[0].id}-${
    eventGroup[0].calendarId
  }-${eventGroup[0].startTime.toISO()}`;
}

function EventOrEventGroup({
  eventGroup,
  calendarId,
  calendarGroupIdx,
  hideEventTime,
}: {
  eventGroup: CalendarEvent[];
  calendarId?: string;
  calendarGroupIdx?: number;
  hideEventTime?: boolean;
}) {
  if (eventGroup.length === 1) {
    return (
      <Event
        event={eventGroup[0]}
        hideEventTime={hideEventTime}
        calendarId={calendarId}
        calendarGroupIdx={calendarGroupIdx}
      />
    );
  }

  return (
    <EventGroup
      eventGroup={eventGroup}
      calendarId={calendarId}
      calendarGroupIdx={calendarGroupIdx}
      hideEventTime={hideEventTime}
    />
  );
}
