import type { SerializedRichBlockNode } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { InterviewsRichBlockInterviewFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
// config
// ------

export const RICH_BLOCK_NAME = "interviews";

export const DEFAULT_DATA: InterviewsData = {
  variant: "upcoming",
  lastFetchedAt: null,
  interviews: [],
  requesterTimezone: DateTime.local().zoneName,
};

// types
// -----

export type InterviewsName = typeof RICH_BLOCK_NAME;

export type InterviewData = Omit<
  InterviewsRichBlockInterviewFragment,
  "jobInterview"
>;

export type InterviewsData = {
  variant: "upcoming";
  lastFetchedAt: string | null;
  interviews: InterviewData[];
  requesterTimezone: string;
};

export type SerializedInterviewsNode = SerializedRichBlockNode<
  InterviewsName,
  InterviewsData
>;
