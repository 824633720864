import { Button } from "@resource/atlas/button/Button";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { atlasEllipsisHorizontal } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingRequestStatus,
  SchedulingTaskMenuSchedulingRequestFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";

import { useReopenSchedulingRequest } from "./hooks/useReopenSchedulingRequest";
import { useSchedulingTaskMenuItems } from "./hooks/useSchedulingTaskMenuItems";
import { ReopenTaskDialog } from "./ReopenTaskDialog";

gql(`fragment SchedulingTaskMenuSchedulingRequest on SchedulingRequest {
  id
  href
  archivedAt
  isReschedule
  status
  guide {
    id
    publicUrl
  }
  ...ReopenTaskDialogSchedulingRequest
}
`);

type SchedulingTaskMenuProps = {
  schedulingRequest: SchedulingTaskMenuSchedulingRequestFragment;
  onEdit: () => void;
  hideEditing?: boolean;
  className?: string;
};

export function SchedulingTaskMenu({
  schedulingRequest,
  onEdit,
  hideEditing,
  className,
}: SchedulingTaskMenuProps) {
  const [reopenSchedulingRequest] = useReopenSchedulingRequest();
  const reopenConfirmationDialogState = useDialogState();

  const menuItems = useSchedulingTaskMenuItems({
    schedulingRequestId: schedulingRequest.id,
    isCompleted: schedulingRequest.status === SchedulingRequestStatus.COMPLETED,
    isArchived: !!schedulingRequest.archivedAt,
    analyticsComponent: "SchedulingRequestPageHeader",
    hideEditing,
    onReopen: () => {
      reopenConfirmationDialogState.show();
    },
    onEdit,
  });

  const confirmReopen = useCallback(async () => {
    await reopenSchedulingRequest({
      variables: {
        input: {
          id: schedulingRequest.id,
        },
      },
    });
    reopenConfirmationDialogState.hide();
  }, [
    reopenSchedulingRequest,
    schedulingRequest.id,
    reopenConfirmationDialogState,
  ]);

  return (
    <>
      <Menu.Root>
        <Menu.Trigger>
          <Button
            isGhost
            icon={atlasEllipsisHorizontal}
            size="small"
            className={className}
          />
        </Menu.Trigger>
        <Menu.Content defaultSize="compact" items={menuItems} />
        <ReopenTaskDialog
          state={reopenConfirmationDialogState}
          onConfirm={confirmReopen}
          schedulingRequest={schedulingRequest}
        />
      </Menu.Root>
    </>
  );
}
