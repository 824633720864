/* eslint-disable import/prefer-default-export */
import clsx from "clsx";
import { useRef } from "react";

import { atlasChevronDown } from "../../icons/atlas";
import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps, useForkRef } from "../__utils/react";
import { Icon } from "../icon/Icon";
import type {
  AtlasSelectTriggerComponentComponent,
  AtlasSelectTriggerComponentProps,
} from "./types";
import { SelectLabelProvider } from "./utils/SelectLabel";

// config
// ------

const COMPONENT_NAME = "SelectTrigger";
const DEFAULT_PROPS = createDefaultProps<AtlasSelectTriggerComponentProps>()({
  size: "medium",
} as const);

const DROPDOWN_ICON = atlasChevronDown;

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// select trigger
// --------------

export const SelectTrigger =
  createComponent<AtlasSelectTriggerComponentComponent>(
    ({
      ref,
      size = DEFAULT_PROPS.size,
      isGhost,
      isPlaceholder,
      icon,
      iconClassName,
      children,
      ...props
    }) => {
      const parentRef = useRef<HTMLButtonElement>(null);
      const overflowRef = useRef<HTMLSpanElement>(null);
      return (
        // eslint-disable-next-line react/button-has-type
        <button
          ref={useForkRef(parentRef, ref)}
          {...props}
          className={clsx(
            `${ROOT} size-${size}`,
            { isGhost, isPlaceholder, hasIcon: icon },
            props.className
          )}
        >
          {icon && (
            <Icon
              className={clsx(el`icon`, iconClassName)}
              content={icon}
              size="custom"
            />
          )}
          <span ref={overflowRef} className={el`label`}>
            <SelectLabelProvider value={{ parentRef, overflowRef }}>
              {children}
            </SelectLabelProvider>
          </span>
          <Icon
            className={el`dropdown-icon`}
            content={DROPDOWN_ICON}
            size="custom"
          />
        </button>
      );
    }
  );
