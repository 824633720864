import StopPropagation from "components/StopPropagation";

export type EventDragInteractionProps = {
  dragHandleImage: React.RefObject<HTMLDivElement>;
  dragHandle: React.RefObject<HTMLDivElement>;
  dragBottomStart: (e: React.DragEvent) => void;
  dragBottom: (e: React.DragEvent) => void;
  dragEnd: (e: React.DragEvent) => void;
};

export function EventDragInteraction({
  dragHandleImage,
  dragHandle,
  dragBottomStart,
  dragBottom,
  dragEnd,
}: EventDragInteractionProps) {
  return (
    <>
      <div ref={dragHandleImage} className="opacity-0 w-1 h-1" />
      <StopPropagation>
        <div
          ref={dragHandle}
          className="absolute bottom-0 h-1 w-full cursor-ns-resize bg-transparent"
          draggable
          onDragStart={dragBottomStart}
          onDrag={dragBottom}
          onDragEnd={dragEnd}
        />
      </StopPropagation>
    </>
  );
}
