import { useLazyQuery } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";

const CHECK_ZOOM_MEMBERSHIP = gql(`
  query CheckZoomMembershipForZoomHostSelect($id: String!) {
    userMembershipById(id: $id) {
      id
      name
      email
      hasZoomAccount
    }
  }
`);

export function useCheckZoomMembership() {
  const [checkZoomMembership, { loading }] = useLazyQuery(
    CHECK_ZOOM_MEMBERSHIP
  );

  return useMemo(
    () => ({
      checkZoomMembership,
      loading,
    }),
    [checkZoomMembership, loading]
  );
}
