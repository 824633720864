import { Button as AriaButton } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCheck } from "@resource/atlas/icons";
import clsx from "clsx";

export function OptionSelect({
  className,
  onClick,
  isSelected,
  label,
}: {
  className?: string;
  isSelected?: boolean;
  onClick: () => void;
  label?: string;
}) {
  return (
    <AriaButton
      className={clsx(
        "w-full py-2.5 text-body-md rounded-md flex",
        "cursor-pointer select-none",
        "hover:bg-light-gray-200 active:bg-light-gray-500",
        className
      )}
      onClick={onClick}
    >
      <div
        className={clsx("px-[.5rem]", isSelected ? "opacity-100" : "opacity-0")}
      >
        <Icon className="w-5 h-5 text-purple-500" content={atlasCheck} />
      </div>
      <span>{label}</span>
    </AriaButton>
  );
}
