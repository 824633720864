import { createContext, ReactNode, Ref, useContext } from "react";

export type InputRef = Ref<HTMLDivElement>;

const InputRefContext = createContext<InputRef | undefined>(undefined);

type InputRefProviderProps = {
  children?: ReactNode;
  inputRef?: InputRef;
};

export function InputRefProvider({
  children,
  inputRef,
}: InputRefProviderProps) {
  return (
    <InputRefContext.Provider value={inputRef}>
      {children}
    </InputRefContext.Provider>
  );
}

export function useInputRef() {
  return useContext(InputRefContext);
}
