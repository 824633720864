import { atlasSearch } from "@resource/atlas/icons";
import TextField from "@resource/atlas/textfield/TextField";
import { SchedulingTaskStatusFilterSelect } from "client/app/(scheduling requests)/__components/SchedulingTaskStatusFilterSelect";
import { DEFAULT_STATUS_FILTER } from "client/app/(scheduling requests)/__utils";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingRequestAssigneeFilter,
  SchedulingRequestStatus,
  SchedulingRequestStatusFilter,
} from "generated/graphql-codegen/graphql";
import { partition } from "lodash";
import { useEffect, useState } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

import { EmptyStateWrapper } from "../generic/misc/EmptyStateWrapper";
import { SchedulingRequestCard } from "./card/SchedulingRequestCard";

const GET_SCHEDULING_REQUESTS_FOR_ORGANIZATION_LIST = gql(`
  query GetSchedulingRequestsForOrganization($where: SchedulingRequestWhereInput, $limit: Int) {
    currentOrganization {
      id
      schedulingRequests(where: $where, limit: $limit) {
        ...SchedulingRequestForSchedulingRequestCard
      }
    }
  }
`);

const SCHEDULING_REQUEST_UPDATED = gql(`
  subscription SchedulingRequestUpdated($where: SchedulingRequestWhereInput) {
    schedulingRequestWithFilterUpdated(where: $where) {
      shouldShow
      schedulingRequest {
        ...SchedulingRequestForSchedulingRequestCard
      }
    }
  }
`);

export function SchedulingRequestsList({
  onClickSchedulingRequest,
  assigneeFilter,
}: {
  onClickSchedulingRequest: (args: { schedulingRequestId: string }) => void;
  assigneeFilter?: SchedulingRequestAssigneeFilter;
}) {
  const {
    searchTerm: search,
    debouncedTerm,
    setSearchTerm: setSearch,
  } = useDebouncedSearch("");
  const [statusFilter, setStatusFilter] =
    useState<SchedulingRequestStatusFilter | null>(DEFAULT_STATUS_FILTER);
  const { data, previousData, subscribeToMore } = useQuery(
    GET_SCHEDULING_REQUESTS_FOR_ORGANIZATION_LIST,
    {
      variables: {
        where: {
          statusFilter,
          search: debouncedTerm,
          assigneeFilter,
        },
        limit: 50,
      },
    }
  );
  const queuedData = data ?? previousData;

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: SCHEDULING_REQUEST_UPDATED,
      variables: {
        where: {
          statusFilter,
          search: debouncedTerm,
          assigneeFilter,
        },
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !subscriptionData.data ||
          !prev.currentOrganization?.schedulingRequests
        ) {
          return prev;
        }
        const { schedulingRequest, shouldShow } =
          subscriptionData.data.schedulingRequestWithFilterUpdated;
        const { schedulingRequests } = prev.currentOrganization;

        if (shouldShow) {
          const existing = schedulingRequests.find(
            (v) => v.id === schedulingRequest.id
          );

          if (!existing) {
            return {
              ...prev,
              currentOrganization: {
                ...prev.currentOrganization,
                schedulingRequests: [schedulingRequest, ...schedulingRequests],
              },
            };
          }

          return {
            ...prev,
            currentOrganization: {
              ...prev.currentOrganization,
              schedulingRequests: schedulingRequests.map((v) => {
                if (v.id === schedulingRequest.id) {
                  return schedulingRequest;
                }

                return v;
              }),
            },
          };
        }

        return {
          ...prev,
          currentOrganization: {
            ...prev.currentOrganization,
            schedulingRequests: schedulingRequests.filter(
              (v) => v.id !== schedulingRequest.id
            ),
          },
        };
      },
    });

    return () => unsubscribe();
  }, [assigneeFilter, debouncedTerm, statusFilter, subscribeToMore]);

  // We need to filter by our status list here as we may have added some from the subscription. We track them intentionally so that we know
  // their version.
  const queuedSchedulingRequests =
    queuedData?.currentOrganization?.schedulingRequests ?? [];

  const [draftRequests, otherRequests] = partition(
    queuedSchedulingRequests,
    // eslint-disable-next-line lodash/matches-prop-shorthand
    (schedulingRequest) => {
      return schedulingRequest.status === SchedulingRequestStatus.DRAFT;
    }
  );

  if (!queuedData) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-between mb-5 w-full">
        <TextField
          icon={atlasSearch}
          placeholder="Search by candidate name"
          aria-label="Search ny candidate name"
          className="flex-shrink-0 w-60"
          value={search ?? ""}
          onChange={setSearch}
        />
        <div>
          <SchedulingTaskStatusFilterSelect
            value={statusFilter}
            onChange={setStatusFilter}
          />
        </div>
      </div>
      <div className="space-y-4">
        {!!draftRequests.length && (
          <div className="space-y-2 flex flex-col">
            <p className="text-body-md-heavy">Drafts</p>
            {draftRequests.map((schedulingRequest) => {
              return (
                <SchedulingRequestCard
                  key={schedulingRequest.id}
                  schedulingRequest={schedulingRequest}
                  onClick={() =>
                    onClickSchedulingRequest({
                      schedulingRequestId: schedulingRequest.id,
                    })
                  }
                />
              );
            })}
          </div>
        )}
        {!!draftRequests.length && !!otherRequests.length && (
          <div className="h-[0.0625rem] bg-[#231F32] bg-opacity-10 w-full" />
        )}
        <div className="space-y-2 flex flex-col">
          {otherRequests.length || draftRequests.length ? (
            otherRequests.map((schedulingRequest) => (
              <SchedulingRequestCard
                key={schedulingRequest.id}
                schedulingRequest={schedulingRequest}
                onClick={() =>
                  onClickSchedulingRequest({
                    schedulingRequestId: schedulingRequest.id,
                  })
                }
              />
            ))
          ) : (
            <EmptyStateWrapper>
              No{" "}
              {formatEntity("scheduling request", {
                plural: true,
              })}
            </EmptyStateWrapper>
          )}
        </div>
      </div>
    </div>
  );
}
