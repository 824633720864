import { eventsOverlap } from "client/utils/dates";

import {
  ConferenceRoomAvailability,
  ConferenceRoomSlot,
  FetchedConferenceRoom,
} from "./types";

export type CurrentInterviewForAvailability = {
  startTime: string | null;
  endTime: string | null;
};

export type OriginalInterviewForAvailability = {
  startTime: string | null;
  conferenceRoomSlot?: ConferenceRoomSlot | null;
};

// Hack around the fact that we're not getting the ids back;
// if this event starts at the original time + the room was booked
// then assume this was our event so ignore it.
function checkIfEventIsInterviewEvent({
  conferenceRoom,
  event,
  originalInterview,
}: {
  conferenceRoom: FetchedConferenceRoom;
  event: { startTime: string; endTime: string };
  originalInterview: OriginalInterviewForAvailability | null;
}): boolean {
  if (
    originalInterview &&
    originalInterview.startTime &&
    originalInterview.conferenceRoomSlot?.conferenceRoom?.id ===
      conferenceRoom.id &&
    new Date(event.startTime).getTime() ===
      new Date(originalInterview.startTime).getTime()
  ) {
    return true;
  }

  return false;
}

export function getConferenceRoomAvailability({
  conferenceRoom,
  currentInterview,
  originalInterview,
}: {
  conferenceRoom: FetchedConferenceRoom;
  currentInterview: CurrentInterviewForAvailability;
  originalInterview: OriginalInterviewForAvailability | null;
}): ConferenceRoomAvailability {
  if (!currentInterview.startTime || !currentInterview.endTime) {
    return "unknown";
  }

  const conflictingEvents = conferenceRoom.events.filter((event) => {
    if (!currentInterview) return false;

    if (
      checkIfEventIsInterviewEvent({
        conferenceRoom,
        event,
        originalInterview,
      })
    ) {
      return false;
    }

    return eventsOverlap(currentInterview, event);
  });
  const hasConflict = conflictingEvents.length > 0;

  if (hasConflict) {
    return "unavailable";
  }

  return "available";
}
