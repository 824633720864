import { ExtensionNotifications } from "./ExtensionNotifications";

/**
 * A wrapper around the ExtensionNotifications component that provides navigation via the global navigator
 *
 * Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorExtensionNotifications() {
  return <ExtensionNotifications />;
}
