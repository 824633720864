import { ValueSet } from "@resource/atlas/content-editor/variables";
import { strings } from "@resource/common";
import _, { first, last, sortBy } from "lodash";
import { GuideBaseVariableSet } from "shared/guide-content/base";

export type InterviewForVariables = {
  startTime: string;
  endTime: string;
  interviewers: {
    title: string | null;
    firstName: string;
    lastName: string | null;
    fullName: string;
  }[];
};

export function convertInterviewVariables({
  interviews,
}: {
  interviews: InterviewForVariables[];
}): Partial<
  Pick<
    ValueSet<GuideBaseVariableSet>,
    | "firstInterview"
    | "lastInterview"
    | "interviewsTimeRange"
    | "interviewsInterviewers"
    | "firstInterviewInterviewers"
    | "firstInterviewFirstInterviewerName"
    | "firstInterviewFirstInterviewerTitle"
    | "lastInterviewFirstInterviewerName"
    | "lastInterviewFirstInterviewerTitle"
    | "lastInterviewInterviewers"
  >
> {
  const sortedInterviews = sortBy(interviews, "startTime");
  const firstInterview = first(sortedInterviews);
  const lastInterview = last(sortedInterviews);
  const allInterviewers = _(sortedInterviews)
    .flatMap("interviewers")
    .uniqBy("fullName")
    .value();

  return {
    ...(firstInterview && lastInterview
      ? {
          interviewsTimeRange: {
            startTime: firstInterview.startTime,
            endTime: lastInterview.endTime,
          },
        }
      : {}),
    ...(firstInterview
      ? {
          firstInterview: {
            startTime: firstInterview.startTime,
            endTime: firstInterview.endTime,
          },
        }
      : {}),
    ...(lastInterview
      ? {
          lastInterview: {
            startTime: lastInterview.startTime,
            endTime: lastInterview.endTime,
          },
        }
      : {}),
    ...(allInterviewers && allInterviewers.length > 0
      ? {
          interviewsInterviewers: {
            firstNames: strings.joinWithFinalSeparator(
              allInterviewers.map(({ firstName }) => firstName),
              ", ",
              " & "
            ),
            fullNames: strings.joinWithFinalSeparator(
              allInterviewers.map(({ fullName }) => fullName),
              ", ",
              " & "
            ),
          },
        }
      : {}),
    ...(firstInterview?.interviewers && firstInterview.interviewers.length > 0
      ? {
          firstInterviewInterviewers: {
            firstNames: strings.joinWithFinalSeparator(
              firstInterview.interviewers.map(({ firstName }) => firstName),
              ", ",
              " & "
            ),
            fullNames: strings.joinWithFinalSeparator(
              firstInterview.interviewers.map(({ fullName }) => fullName),
              ", ",
              " & "
            ),
          },
          firstInterviewFirstInterviewerName: {
            first: firstInterview.interviewers[0].firstName ?? "",
            last: firstInterview.interviewers[0].lastName ?? "",
          },
          firstInterviewFirstInterviewerTitle:
            firstInterview.interviewers[0].title ?? "",
        }
      : {}),
    ...(lastInterview?.interviewers && lastInterview.interviewers.length > 0
      ? {
          lastInterviewInterviewers: {
            firstNames: strings.joinWithFinalSeparator(
              lastInterview.interviewers.map(({ firstName }) => firstName),
              "&"
            ),
            fullNames: strings.joinWithFinalSeparator(
              lastInterview.interviewers.map(({ fullName }) => fullName),
              "&"
            ),
          },
          lastInterviewFirstInterviewerName: {
            first: lastInterview.interviewers[0].firstName ?? "",
            last: lastInterview.interviewers[0].lastName ?? "",
          },
          lastInterviewFirstInterviewerTitle:
            lastInterview.interviewers[0].title ?? "",
        }
      : {}),
  };
}
