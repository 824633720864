interface CandidateInfoProps {
  candidateName: string;
  jobName: string;
  stageName?: string | null;
}

export function CandidateSectionForSchedulingRequest({
  candidateName,
  jobName,
  stageName,
}: CandidateInfoProps) {
  return (
    <div className="p-4 rounded-md flex flex-col shadow-1 text-body-md">
      <div>{candidateName}</div>
      <div className="flex flex-row text-body-sm text-subtle">
        {jobName}
        <div className="mx-2">•</div>
        {stageName ?? "No current stage"}
      </div>
    </div>
  );
}
