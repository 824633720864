import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasClock,
  atlasMail,
  atlasPerson,
  atlasPhone,
  atlasRingInfo,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { displayPhoneNumber } from "@resource/common";
import { useAuthContext } from "auth/context";
import { ActivityFeed } from "client/components/activity-feed/ActivityFeed";
import { CandidateName } from "client/components/CandidateName";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { CandidateTimezoneSelect } from "client/components/generic/select/CandidateTimezoneSelect";
import { RecruiterSelect } from "client/components/generic/select/RecruiterSelect";
import { ClearGuideInterviewPlanOverridesConfirmationDialog } from "client/components/interview-plan/ClearGuideInterviewPlanOverridesConfirmationDialog";
import { InterviewPlanAndHiringDecision } from "client/components/interview-plan/InterviewPlanAndHiringDecision";
import { GlobalCandidateAvailabilitySection } from "client/guide-availability/components/GlobalCandidateAvailabilitySection";
import { useGuidePostForGuideCreated } from "client/hooks/useGuidePostForGuideCreated";
import { useScheduledInterviewGroupForGuideCreated } from "client/hooks/useScheduledInterviewGroupForGuideCreated";
import { useSubscribeToStageChange } from "client/hooks/useSubscribeToStageChange";
import { useOpenSelfScheduleRequests } from "client/self-schedule/components/__hooks/useOpenSelfScheduleRequests";
import { OpenSelfScheduleRequestsSection } from "client/self-schedule/components/OpenSelfScheduleRequestsSection";
import clsx from "clsx";
import Loading from "components/Loading";
import RequestInfoRow from "components/RequestInfoRow/RequestInfoRow";
import { Skeleton } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import {
  GuideManagerGuideV2Fragment,
  OrganizationFeaturesEnum,
} from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

import { ExtensionActions } from "../__components/ExtensionActions";
import { LatestUpdateExtension } from "../__components/LatestUpdateExtension";
import { SchedulingRequestsSection } from "../__components/SchedulingActions";
import { UpcomingInterviews } from "../__components/UpcomingInterviews";
import { EditGuideInterviewPlanDialog } from "./__components/EditGuideInterviewPlanDialog";
import { ForcePropagateToGuideConfirmationDialog } from "./__components/ForcePropagateToGuideConfirmationDialog";
import { useGuideActivity } from "./__hooks/useGuideActivity";

const FETCH_V2_GUIDE_FOR_MANAGER_TAB = gql(`
query FetchV2GuideForManagerTab($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      job {
        id
        internalName
      }
      ...GuideManagerGuideV2
      ...LatestUpdateExtensionGuide
      ...SchedulingRequestsListExtensionGuide
      ...GuideManagerMenuGuide
      ...GuideManagerInterviewsGuide
      ...GuideForActivityFeed
      ...GuideMetricsGuide
    }
  }
`);

type HeaderProps = ComponentPropsWithoutRef<"header"> & {
  guide: GuideManagerGuideV2Fragment;
  refetchGuide: () => void;
};

function Header({ guide, refetchGuide, ...props }: HeaderProps) {
  const { hasFeatureEnabled } = useAuthContext();
  const showTimezone = hasFeatureEnabled([
    OrganizationFeaturesEnum.SCHEDULING,
    OrganizationFeaturesEnum.MESSAGING,
  ]);
  const organization = guide?.candidate.organization;
  const color = organization?.theme?.primaryColor || "#ff6b27";

  return (
    <header {...props} className={clsx("space-y-4", props.className)}>
      <div className="space-y-4">
        {organization ? (
          <div
            className="-mx-4 bg-cover pt-[25%] aspect-[1200/300]"
            style={{
              backgroundImage: `url(${guide.bannerImageUrl})`,
              backgroundColor: color,
            }}
          />
        ) : (
          <Skeleton className="h-[11.25rem] -mx-4 bg-cover" variant="dark" />
        )}
        <div className="flex flex-col space-y-4">
          <div className="space-y-0.5">
            {guide && (
              <div
                className="flex gap-3 items-center justify-between"
                data-id={guide.id}
              >
                <h3 className="text-h3 text-dark mt-[.1875rem] w-full truncate">
                  <CandidateName
                    candidate={guide.candidate}
                    isInternal={guide.isInternalATSApplication}
                  />
                </h3>
              </div>
            )}
            <p className="text-body-sm text-subtle">{guide.roleName}</p>
          </div>
          <div className="flex flex-col space-y-2">
            {/* <div className="flex items-center gap-2"> */}
            {guide?.candidate.email && (
              <RequestInfoRow
                icon={<Icon content={atlasMail} />}
                className="flex-1 truncate"
                label="Email"
              >
                <span className="text-body-sm truncate">
                  {guide?.candidate.email}
                </span>
              </RequestInfoRow>
            )}
            {guide?.candidate.phone && (
              <RequestInfoRow
                icon={<Icon content={atlasPhone} />}
                className="flex-1 truncate"
                label="Phone"
              >
                <span className="text-body-sm truncate">
                  {displayPhoneNumber(guide.candidate.phone)}
                </span>
              </RequestInfoRow>
            )}
            {/* </div> */}
            <RequestInfoRow
              icon={<Icon content={atlasPerson} />}
              label="Recruiter"
              className="!h-5"
            >
              <RecruiterSelect
                guideId={guide.id}
                recruiter={guide.recruiter ?? undefined}
              />
            </RequestInfoRow>
            {showTimezone && (
              <RequestInfoRow
                icon={<Icon content={atlasClock} />}
                label="Timezone"
              >
                <CandidateTimezoneSelect
                  candidate={guide.candidate}
                  labelType="default"
                  triggerProps={{
                    size: "xs",
                    isGhost: true,
                    className: "-ml-[.625rem]",
                  }}
                />
              </RequestInfoRow>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

type InterviewPlanProps = ComponentPropsWithoutRef<"section"> & {
  guide: GuideManagerGuideV2Fragment;
  refetchGuide: () => void;
};

function InterviewPlanSection({
  guide,
  refetchGuide,
  ...props
}: InterviewPlanProps) {
  const { candidate, interviewPlan } = guide;
  const clearOverridesStore = useDialogStore();
  const forcePropagateConfirmation = useDialogStore();
  const editInterviewPlanDialog = useDialogStore();

  return (
    <section {...props}>
      <div className="flex items-center justify-between text-dark mb-2">
        <div className="flex justify-between items-center w-full">
          <span className="text-h4">
            {formatEntity("interview plan", { capitalize: true })}
          </span>
          {interviewPlan && interviewPlan.items.length && (
            <Button
              variant="primary"
              isGhost
              size="xs"
              onClick={editInterviewPlanDialog.show}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      {interviewPlan && interviewPlan.items.length > 0 ? (
        <InterviewPlanAndHiringDecision
          currentInterviewPlanItemId={guide.currentInterviewPlanItem?.id}
          items={interviewPlan.items}
          candidate={{
            ...candidate,
            name: candidate.internalName,
          }}
          hiringDecision={guide.status}
          guide={guide}
        />
      ) : (
        <EmptyStateWrapper>
          No {formatEntity("interview plan")}
        </EmptyStateWrapper>
      )}
      {guide.interviewPlanIsOverridden && (
        <div className="text-subtle space-x-2 text-body-md mt-2">
          <span>Modified from job interview plan.</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={clearOverridesStore.show}>Restore</Link>
        </div>
      )}
      {!guide.interviewPlanIsOverridden &&
        !guide.interviewPlanInSyncWithJob && (
          <div className="flex flex-row text-subtle space-x-1 text-body-md mt-2">
            <Tooltip
              content="This candidate is active and their interview plan will no longer receive updates from the job settings."
              isInstant
            >
              <Icon
                content={atlasRingInfo}
                className="w-5 h-5 float-right ml-1"
              />
            </Tooltip>
            <div className="space-x-2">
              <span>Outdated from job interview plan.</span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={forcePropagateConfirmation.show}>Pull latest</Link>
            </div>
          </div>
        )}
      <ClearGuideInterviewPlanOverridesConfirmationDialog
        store={clearOverridesStore}
        guideId={guide.id}
      />
      <ForcePropagateToGuideConfirmationDialog
        store={forcePropagateConfirmation}
        onSubmit={() => {
          setTimeout(() => {
            refetchGuide();
          }, 2500);
        }}
        guideId={guide.id}
      />
      <EditGuideInterviewPlanDialog
        store={editInterviewPlanDialog}
        done={refetchGuide}
        guideId={guide.id}
      />
    </section>
  );
}

export type GuideManagerProps = {
  guideId: string;
  onClickSchedulingRequest: (props: { schedulingRequestId: string }) => void;
  onClickScheduledInterview: (props: { scheduledInterviewId: string }) => void;
  onViewAllInterviews: () => void;
  scrollToUpcomingInterviews?: boolean;
};

export function GuideManagerTab({
  guideId,
  onClickSchedulingRequest,
  onClickScheduledInterview,
  onViewAllInterviews,
  scrollToUpcomingInterviews,
}: GuideManagerProps) {
  const { data, refetch, loading } = useQuery(FETCH_V2_GUIDE_FOR_MANAGER_TAB, {
    variables: {
      guideId,
    },
  });
  const {
    activity,
    nextPage: activityNextPage,
    loading: activityLoading,
  } = useGuideActivity(guideId);

  const { loading: openSelfScheduleRequestsLoading, openSelfScheduleRequests } =
    useOpenSelfScheduleRequests(guideId);

  useGuidePostForGuideCreated({
    guideId,
    onSubscriptionData: () => {
      refetch();
    },
  });

  useScheduledInterviewGroupForGuideCreated({
    guideId,
    onSubscriptionData: () => {
      refetch();
    },
  });

  const { hasFeatureEnabled } = useAuthContext();
  const isSchedulingEnabled = useMemo(
    () => hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING),
    [hasFeatureEnabled]
  );
  const isMessagingEnabled = useMemo(
    () => hasFeatureEnabled(OrganizationFeaturesEnum.MESSAGING),
    [hasFeatureEnabled]
  );

  useSubscribeToStageChange({
    applicationId: data?.guideById?.atssyncApplication?.id,
    refetch,
  });

  const guide = data?.guideById;

  if (
    (loading && !guide) ||
    (openSelfScheduleRequestsLoading && !openSelfScheduleRequests)
  ) {
    return <Loading />;
  }

  if (!guide) {
    return null;
  }

  return (
    <>
      <div className="space-y-6 px-4 pb-8">
        <div className="space-y-6">
          <Header guide={guide} refetchGuide={refetch} />
          <ExtensionActions guide={guide} />
        </div>
        <InterviewPlanSection guide={guide} refetchGuide={refetch} />
        {isSchedulingEnabled && (
          <>
            <GlobalCandidateAvailabilitySection
              guideId={guideId}
              candidateName={guide.candidate.internalName}
              candidateTimezone={guide.candidate.timezone}
              jobName={guide.job.internalName}
            />
            <OpenSelfScheduleRequestsSection
              openSelfScheduleRequests={openSelfScheduleRequests}
            />
            <SchedulingRequestsSection
              guide={guide}
              onClick={onClickSchedulingRequest}
            />
          </>
        )}
        {isMessagingEnabled && <LatestUpdateExtension guide={guide} />}
        <UpcomingInterviews
          ref={(e) => {
            if (scrollToUpcomingInterviews && e) {
              e.scrollIntoView({ behavior: "smooth" });
            }
          }}
          guide={guide}
          onViewAll={onViewAllInterviews}
          onClickScheduledInterview={onClickScheduledInterview}
        />
      </div>
      <ActivityFeed
        className="bg-light-gray-200"
        showHeading
        guide={guide}
        activity={activity}
        nextPage={activityNextPage}
        loading={activityLoading}
        context="Guide"
      />
    </>
  );
}
