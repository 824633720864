import { LazyQueryResult, useLazyQuery } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import {
  GetConferenceRoomDataQuery,
  GetConferenceRoomDataQueryVariables,
} from "generated/graphql-codegen/graphql";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect } from "react";
import { usePrevious } from "react-use";

import { FetchedBuilding, FetchedConferenceRoom } from "../utils/types";

const GET_CONFERENCE_ROOM_DATA = gql(`
  query GetConferenceRoomData(
    $startTime: String
    $endTime: String
  ) {
    currentOrganization {
      id
      conferenceRooms(
        startTime: $startTime
        endTime: $endTime
      ) {
        id
        ...ConferenceRoomForSelect
      }
      buildings {
        ...BuildingForConferenceRoomSelect
      }
    }
  }
`);

export type UseFetchedConferenceRoomEventsProps = {
  startTime: string | null;
  endTime: string | null;
  skip?: boolean;
  /** Refetch events on open */
  popoverOpen?: boolean;
};

const conferenceRoomAtom = atom<FetchedConferenceRoom[]>([]);

const buildingAtom = atom<FetchedBuilding[]>([]);

function useOnFetched() {
  const setConferenceRooms = useSetAtom(conferenceRoomAtom);
  const setBuildings = useSetAtom(buildingAtom);

  return useCallback(
    (
      result: LazyQueryResult<
        GetConferenceRoomDataQuery,
        GetConferenceRoomDataQueryVariables
      >
    ) => {
      const newConferenceRooms =
        result.data?.currentOrganization?.conferenceRooms;
      const newBuildings = result.data?.currentOrganization?.buildings;

      if (newConferenceRooms) {
        setConferenceRooms(newConferenceRooms);
      }

      if (newBuildings) {
        setBuildings(newBuildings);
      }
    },
    [setConferenceRooms, setBuildings]
  );
}

export const useFetchedConferenceRooms = ({
  startTime,
  endTime,
  skip = false,
}: UseFetchedConferenceRoomEventsProps): {
  conferenceRooms: FetchedConferenceRoom[];
  buildings: FetchedBuilding[];
  loading: boolean;
  refetch: () => void;
} => {
  const conferenceRooms = useAtomValue(conferenceRoomAtom);
  const buildings = useAtomValue(buildingAtom);
  const [fetchConferenceRoomData, { loading }] = useLazyQuery(
    GET_CONFERENCE_ROOM_DATA,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const onFetched = useOnFetched();
  const fetchAndCacheConferenceRoomData = useCallback(async () => {
    try {
      const result = await fetchConferenceRoomData({
        variables: {
          startTime,
          endTime,
        },
      });

      onFetched(result);
    } catch (e) {
      console.error("Failed to fetch conference room calendars and events");
    }
  }, [endTime, fetchConferenceRoomData, onFetched, startTime]);

  const prevStartTime = usePrevious(startTime);

  useEffect(() => {
    const startTimeChanged = startTime
      ? !prevStartTime || new Date(startTime) !== new Date(prevStartTime)
      : !!prevStartTime;

    if (!skip && startTimeChanged) {
      fetchAndCacheConferenceRoomData();
    }
  }, [
    startTime,
    skip,
    fetchConferenceRoomData,
    prevStartTime,
    fetchAndCacheConferenceRoomData,
  ]);

  return {
    conferenceRooms,
    buildings,
    loading,
    refetch: fetchAndCacheConferenceRoomData,
  };
};
