import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

const UPSERT_ATSSYNC_SCHEDULED_INTERVIEW = gql(`
  mutation UpsertAtssyncScheduledInterview($input: UpsertAtssyncScheduledInterviewInput!) {
    upsertAtssyncScheduledInterview(input: $input) {
      code
      success
      message
    }
  }
`);

export type ResyncScheduledInterviewToGreenhouseConfirmationProps = {
  scheduledInterviewId: string;
  store: DialogStore;
  onCompleted?: () => void;
};

export function ResyncScheduledInterviewToGreenhouseConfirmation(
  props: ResyncScheduledInterviewToGreenhouseConfirmationProps
) {
  const { store, scheduledInterviewId, onCompleted } = props;
  const [upsertAtssyncScheduledInterview, { loading: submitLoading }] =
    useMutation(UPSERT_ATSSYNC_SCHEDULED_INTERVIEW);

  const onSubmit = useCallback(() => {
    upsertAtssyncScheduledInterview({
      variables: {
        input: {
          scheduledInterviewId,
        },
      },
      onCompleted: () => {
        store.hide();

        if (onCompleted) {
          onCompleted();
        }
      },
    });
  }, [
    upsertAtssyncScheduledInterview,
    scheduledInterviewId,
    store,
    onCompleted,
  ]);

  const onCancel = useCallback(() => {
    store.hide();
  }, [store]);

  return (
    <Dialog store={store}>
      <View
        header={{
          hideBorder: true,
        }}
        content={{
          title: "Resync to Greenhouse",
          subTitle:
            "Resync this interview from Guide to Greenhouse if you're not seeing the interview synced into Greenhouse as expected. Resyncing will not duplicate interviews, it will only ensure Greenhouse has the latest interview information",
        }}
        footer={{
          hideBorder: true,
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onSubmit}
                isLoading={submitLoading}
              >
                Resync
              </Button>
            </ButtonGroup>
          ),
        }}
      />
    </Dialog>
  );
}
