import {
  ScheduledInterviewForConferencingMappingFragment,
  ScheduledInterviewGroupForReusableConferencingMappingFragment,
} from "generated/graphql-codegen/graphql";

import { ConferencingType } from "./types";

export function isGenerationService(service: ConferencingType) {
  return [ConferencingType.ZOOM, ConferencingType.GOOGLE_MEET].includes(
    service
  );
}

export function getIsUsingGroupSettings({
  scheduledInterview,
  scheduledInterviewGroup,
}: {
  scheduledInterview: ScheduledInterviewForConferencingMappingFragment;
  scheduledInterviewGroup: ScheduledInterviewGroupForReusableConferencingMappingFragment;
}) {
  const groupSettingsHaveVideoConferencingGeneration =
    scheduledInterviewGroup.reuseVideoConferencingLink;
  const groupSettingsHaveNotYetBeenUsed =
    !scheduledInterviewGroup.reusedVideoConferencingLink;
  const interviewHasNoLinkButShouldGenerateOne =
    !scheduledInterview.videoConferencing &&
    !!scheduledInterview.conferencingGenerationSettings;

  if (
    groupSettingsHaveVideoConferencingGeneration &&
    groupSettingsHaveNotYetBeenUsed &&
    interviewHasNoLinkButShouldGenerateOne
  ) {
    return true;
  }

  return Boolean(
    scheduledInterview.videoConferencing &&
      scheduledInterviewGroup &&
      scheduledInterviewGroup.reuseVideoConferencingLink &&
      scheduledInterviewGroup.reusedVideoConferencingLink ===
        scheduledInterview.videoConferencing.url
  );
}
