import { Link } from "@resource/atlas/link/Link";
import { useTimezone } from "client/timezones/useTimezone";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import { useMemo, useState } from "react";
import { getFormattedEventTimeRangeWithDay } from "shared/guide-availability/utils";

type Props = {
  times: (
    | {
        start: string;
        end: string;
      }
    | {
        startTime: string;
        endTime: string;
      }
  )[];
  timezone?: string | null;
  children?: JSX.Element | null;
  listClassName?: string;
  listItemClassName?: string;
  allowCollapse?: boolean;
};

const MAX_RENDER_BEFORE_EXPAND = 3;

export function DisplaySelectedTimes({
  times: passedTimes,
  timezone: passedTimezone,
  children,
  listClassName,
  listItemClassName,
  allowCollapse = true,
}: Props) {
  const contextTimezone = useTimezone();
  const timezone = passedTimezone ?? contextTimezone;
  const times = useMemo(() => {
    return passedTimes.map((availability) => {
      if ("startTime" in availability) {
        return availability;
      }

      return {
        ...availability,
        startTime: availability.start,
        endTime: availability.end,
      };
    });
  }, [passedTimes]);
  const timesSorted = useMemo(
    () => times.sort((a, b) => a.startTime.localeCompare(b.startTime)),
    [times]
  );
  const [expanded, setExpanded] = useState(!allowCollapse);
  const canExpand = useMemo(
    () => times.length > MAX_RENDER_BEFORE_EXPAND,
    [times]
  );

  if (!timesSorted.length) {
    return null;
  }

  return (
    <>
      <ul
        className={clsx(
          "list-disc list-inside space-y-1 text-body-md",
          listClassName
        )}
      >
        {timesSorted
          .slice(
            0,
            expanded || !allowCollapse ? undefined : MAX_RENDER_BEFORE_EXPAND
          )
          .map((event) => (
            <li
              key={`${event.startTime}-${event.endTime}`}
              className={listItemClassName}
            >
              {getFormattedEventTimeRangeWithDay(event, timezone)}
            </li>
          ))}
      </ul>
      {children}
      {allowCollapse && canExpand && (
        <div>
          <StopPropagation>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              as="button"
              onClick={() => setExpanded((isExpanded) => !isExpanded)}
              className="text-body-md"
            >
              {expanded ? "Show less" : "Show more"}
            </Link>
          </StopPropagation>
        </div>
      )}
    </>
  );
}
