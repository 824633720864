import { useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";

const GUIDE_OPEN_AVAILABILITY_REQUEST_SUBSCRIPTION = gql(`
  subscription OpenAvailabilityRequestForGuide($guideId: ID!) {
    openAvailabilityRequestForGuide(guideId: $guideId) {
      id
      ...AvailabilityRequestForManageAvailabilityRequest
    }
  }
`);

export function useOpenAvailabilityRequest(guideId: string) {
  const { data, loading } = useSubscription(
    GUIDE_OPEN_AVAILABILITY_REQUEST_SUBSCRIPTION,
    {
      variables: {
        guideId,
      },
    }
  );

  return useMemo(
    () => ({
      availabilityRequest: data?.openAvailabilityRequestForGuide,
      loading,
    }),
    [data?.openAvailabilityRequestForGuide, loading]
  );
}
