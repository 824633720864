import { SerializedRichBlockNode } from "@resource/atlas/content-editor/__utils/rich-blocks";
import {
  AvailabilityRequestDataSchema,
  AvailabilityRequestInputSchema,
  AvailabilityRequestPreviewDataSchema,
} from "shared/guide-availability/types";
import { z } from "zod";

// config
// ------

export const RICH_BLOCK_NAME = "request-availability";

export const DEFAULT_DATA: GuideAvailabilityRequestData = {
  suggestions: [],
  notes: "",
};

// types
// -----

export type GuideAvailabilityRequestName = typeof RICH_BLOCK_NAME;

export const GuideAvailabilityRequestDataSchema = z.union([
  AvailabilityRequestDataSchema,
  AvailabilityRequestInputSchema,
]);

export type GuideAvailabilityRequestData =
  | z.infer<typeof GuideAvailabilityRequestDataSchema>
  | z.infer<typeof AvailabilityRequestInputSchema>
  | z.infer<typeof AvailabilityRequestPreviewDataSchema>;

export type SerializedGuideAvailabilityRequestNode = SerializedRichBlockNode<
  GuideAvailabilityRequestName,
  GuideAvailabilityRequestData
>;
