import { usePopoverStore } from "@resource/atlas/popover-v2";
import { DateTime } from "luxon";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import type { CalendarData, CalendarDataInput } from "../hooks/data";
import type { CalendarOptions, CalendarOptionsInput } from "../hooks/options";
import type {
  CalendarRefs,
  FocusedEventInfo,
  HoveringEventInfo,
} from "../hooks/refs";
import { type CalculatedEventsViewSize } from "../hooks/sizing";
import { useMappedCalendarData, useMappedCalendarOptions } from "./mapping";

export type CalendarContextInput = CalendarDataInput & {
  options?: CalendarOptionsInput;
  /**
   * Pass the current viewing time context to the calendar
   * The calendar will ensure we go to this day when switching to day view
   * And if current viewing time changes, we will set the current day to the correct time
   */
  currentViewingTime?: DateTime;
  /**
   * Pass the current viewing grouping ID context to the calendar
   * The calendar will scroll horizontally if needed to the correct column
   */
  currentViewingGroupingId?: string;
};

export type CalendarContext = CalendarData &
  CalendarOptions &
  CalendarRefs & {
    calculatedEventsViewSize: CalculatedEventsViewSize;
    currentViewingTime?: DateTime;
    currentViewingGroupingId?: string;
    hoveringEventInfo: HoveringEventInfo;
    setHoveringEventInfo: (info: HoveringEventInfo) => void;
    focusedEventInfo: FocusedEventInfo;
    setFocusedEventInfo: (info: FocusedEventInfo) => void;
  };

export const CalendarContext = createContext<CalendarContext | undefined>(
  undefined
);

function useCalendarRefs(): CalendarRefs {
  const [wrapper, setWrapper] = useState<CalendarRefs["wrapper"]>(null);
  const [eventsView, setEventsView] =
    useState<CalendarRefs["eventsView"]>(null);
  const [header, setHeader] = useState<CalendarRefs["header"]>(null);
  const [hoveringTriggerElement, setHoveringTriggerElement] =
    useState<CalendarRefs["hoveringTriggerElement"]>(null);
  const [
    eventDetailsPopoverTriggerElement,
    setEventDetailsPopoverTriggerElement,
  ] = useState<CalendarRefs["eventDetailsPopoverTriggerElement"]>(null);
  const [focusedEventInfo, setFocusedEventInfo] =
    useState<CalendarRefs["focusedEventInfo"]>(null);
  const [hoveringEventInfo, setHoveringEventInfo] =
    useState<CalendarRefs["hoveringEventInfo"]>(null);
  const eventDetailsPopoverStore = usePopoverStore({
    placement: "left",
  });

  return useMemo(
    () => ({
      wrapper,
      eventsView,
      header,
      hoveringTriggerElement,
      eventDetailsPopoverTriggerElement,
      focusedEventInfo,
      hoveringEventInfo,
      eventDetailsPopoverStore,
      setWrapper,
      setEventsView,
      setHeader,
      setHoveringTriggerElement,
      setEventDetailsPopoverTriggerElement,
      setFocusedEventInfo,
      setHoveringEventInfo,
    }),
    [
      eventDetailsPopoverStore,
      eventDetailsPopoverTriggerElement,
      eventsView,
      focusedEventInfo,
      header,
      hoveringEventInfo,
      hoveringTriggerElement,
      wrapper,
    ]
  );
}

function useCalculatedSizing({ refs }: { refs: CalendarRefs }) {
  const { eventsView: eventsViewRef } = refs;
  const [calculatedEventsViewSize, setCalculatedEventsViewSize] =
    useState<CalculatedEventsViewSize>({ width: 0, height: 0 });

  const calculateEventsViewSize = useCallback(() => {
    if (!eventsViewRef) {
      console.warn("Events view ref not defined");
      return;
    }

    setCalculatedEventsViewSize({
      height: eventsViewRef.scrollHeight,
      width: eventsViewRef.scrollWidth,
    });
  }, [eventsViewRef, setCalculatedEventsViewSize]);

  useEffect(() => {
    calculateEventsViewSize();

    const resizeObserver = new ResizeObserver(() => {
      calculateEventsViewSize();
    });

    if (eventsViewRef) {
      resizeObserver.observe(eventsViewRef);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateEventsViewSize, eventsViewRef]);

  return {
    calculatedEventsViewSize,
  };
}

function useCalendarContextValue(input: CalendarContextInput): CalendarContext {
  const calendarData = useMappedCalendarData({ input });
  const options = useMappedCalendarOptions({
    options: input.options ?? {},
    calendarData,
  });
  const refs = useCalendarRefs();
  const calculatedSizing = useCalculatedSizing({
    refs,
  });

  return useMemo(
    (): CalendarContext => ({
      ...calendarData,
      ...options,
      ...refs,
      ...calculatedSizing,
      currentViewingTime: input.currentViewingTime,
      currentViewingGroupingId: input.currentViewingGroupingId,
    }),
    [
      calculatedSizing,
      calendarData,
      input.currentViewingGroupingId,
      input.currentViewingTime,
      options,
      refs,
    ]
  );
}

export function CalendarProvider({
  children,
  input,
}: {
  children: React.ReactNode;
  input: CalendarContextInput;
}) {
  const value = useCalendarContextValue(input);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
}

export function useCalendarContext() {
  const context = useContext(CalendarContext);

  if (context === undefined) {
    throw new Error(
      "useCalendarContext must be used within a CalendarProvider"
    );
  }

  return context;
}

export function useOptionalCalendarContext() {
  return useContext(CalendarContext);
}
