import clsx from "clsx";
import { DateTime } from "luxon";

import { useDaysToRender } from "./useDaysToRender";
import { useCalendarSizes } from "./utils";

export function DayHeadersView({
  getItem,
  className,
}: {
  getItem: (props: { day: DateTime }) => React.ReactNode;
  className?: string;
}) {
  const days = useDaysToRender();
  const calendarSizes = useCalendarSizes();

  return (
    <div
      className={clsx(
        "h-14 -mr-px hidden divide-x divide-[#EEEDEF] border-r border-[#EEEDEF] text-sm leading-6 xs:grid",
        `grid-cols-${days.length}`,
        className
      )}
    >
      <div
        className="col-end-1"
        style={{
          width: `${calendarSizes.leftOffsetWidth.rem}rem`,
        }}
      />
      {days.map((day) => {
        return getItem({ day });
      })}
    </div>
  );
}
