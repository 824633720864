import { getDefaultInterviewerCalendarColors } from "client/components/calendar-v2/utils/colors";
import { CalendarColorsConfig } from "client/components/calendar-v2/utils/types";
import { atom, useAtom } from "jotai";
import { uniq } from "lodash";
import { useEffect, useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useInterviews } from "../../hooks/interviews";
import { CustomCalendarIds } from "../utils/calendar-ids";

const globalCalendarColors: CalendarColorsConfig = {
  // Default color for current panel (can be overriden by logic in `useSchedulingCalendarEvents`)
  [CustomCalendarIds.CURRENT_PANEL]: {
    color: "primary",
    eventVariant: "primary",
  },
};

/**
  Keep the local list of any interviewer calendars we've shown in sync.
  
  Necessary to make sure we preserve the order so the calendar colors never shift.
*/

const interviewersForCalendarColorsAtom = atom<string[]>([]);

export function useSchedulerCalendarColors() {
  const [interviewersForCalendarColors, setInterviewersForCalendarColors] =
    useAtom(interviewersForCalendarColorsAtom);
  const interviews = useInterviews();

  const interviewers = useMemo(() => {
    return interviews
      .flatMap((interview) => {
        return interview.interviewerSlots.flatMap((slot) => {
          const {
            interviewer,
            shadowingInterviewer,
            userMembershipsSetting,
            shadowingUserMembershipsSetting,
          } = slot;

          return [
            interviewer?.userMembership.id,
            shadowingInterviewer?.userMembership.id,
            ...(userMembershipsSetting.map((um) => um.id) ?? []),
            ...(shadowingUserMembershipsSetting.map((um) => um.id) ?? []),
          ];
        });
      })
      .filter(filterOutNullsAndUndefined);
  }, [interviews]);

  useEffect(() => {
    setInterviewersForCalendarColors((prev) => [
      ...prev,
      ...uniq(interviewers).filter((id) => !prev.includes(id)),
    ]);
  }, [interviewers, setInterviewersForCalendarColors]);

  const calendarColors: CalendarColorsConfig = useMemo(() => {
    return {
      ...getDefaultInterviewerCalendarColors(
        uniq(interviewersForCalendarColors)
      ),
      ...globalCalendarColors,
    };
  }, [interviewersForCalendarColors]);

  return calendarColors;
}
