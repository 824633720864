import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarEvents,
  atlasCandidatePortal,
  atlasEllipsisHorizontal,
  atlasPeople,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import clsx from "clsx";
import Image from "next/image";
import { ComponentPropsWithoutRef, useState } from "react";

type SignatureProps = ComponentPropsWithoutRef<"div"> & {
  href?: string;
  companyLogoUrl: string;
  companyName: string;
};

export function CollapsedSignature({
  href,
  companyLogoUrl,
  companyName,
  ...props
}: SignatureProps) {
  const [showSignature, setShowSignature] = useState(false);
  return (
    <div {...props}>
      {showSignature ? (
        <Signature
          href={href}
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
        />
      ) : (
        <Tooltip content="Show Guide signature" isInstant>
          <Button
            className="!h-4"
            size="xs"
            icon={atlasEllipsisHorizontal}
            onClick={() => setShowSignature(true)}
          />
        </Tooltip>
      )}
    </div>
  );
}

export default function Signature({
  href,
  companyLogoUrl,
  companyName,
  className,
  ...props
}: SignatureProps) {
  return (
    <div {...props} className={clsx(className, "space-y-6 mt-3")}>
      <div className="space-y-3">
        <p className="text-body-lg text-subtle">—</p>
        <p className="text-body-md-heavy">
          Visit the links below for real-time updates and information on your
          interview process.
        </p>
        <div className="flex flex-wrap gap-4 text-body-md">
          <div className="flex-shrink-0">
            <Link href={href || "#."} target={href ? "_blank" : "_self"}>
              <Icon
                className="inline-block mr-1"
                content={atlasCandidatePortal}
              />
              My candidate portal
            </Link>
          </div>
          <div className="flex-shrink-0">
            <Link
              href={href ? `${href}/interviews` : "#."}
              target={href ? "_blank" : "_self"}
            >
              <Icon
                className="inline-block mr-1"
                content={atlasCalendarEvents}
              />
              My interview schedule
            </Link>
          </div>
          <div className="flex-shrink-0">
            <Link
              href={href ? `${href}#hiring-team` : "#."}
              target={href ? "_blank" : "_self"}
            >
              <Icon className="inline-block mr-1" content={atlasPeople} />
              My interview team
            </Link>
          </div>
        </div>
      </div>
      <div className="relative h-8">
        <Image
          src={companyLogoUrl}
          alt={`${companyName} logo`}
          layout="fill"
          objectFit="contain"
          objectPosition="left"
        />
      </div>
    </div>
  );
}
