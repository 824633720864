import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { useCallback } from "react";

import { InternalGuideScheduledInterviews } from "./InternalGuideScheduledInterviews";

/**
 *  A wrapper around the InternalGuideScheduledInterviews component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorGuideScheduledInterviews({
  guideId,
}: {
  guideId: string;
}) {
  const navigator = useGlobalNavigatorOrThrow();
  const onSelectInterview = useCallback(
    (id: string) => {
      navigator.navigate("scheduledInterviewDetails", {
        guideId,
        scheduledInterviewId: id,
      });
    },
    [navigator, guideId]
  );
  return (
    <InternalGuideScheduledInterviews
      guideId={guideId}
      onSelectInterview={onSelectInterview}
      onBack={navigator.back}
      backIcon={navigator.backIcon}
    />
  );
}
