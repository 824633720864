/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import { createNodeRenderer } from "../../renderer";
import type {
  VariableNameEntry,
  VariableNameSet,
  VariableRenderer,
  VariableRendererSet,
} from "./types";
import { SerializedUnknownVariableNode } from "./UnknownVariableNode";
import { useVariableRendererContext } from "./variable-renderer-context";
import type { SerializedVariableNode } from "./VariableNode";
import { VariableToken } from "./VariableToken";

// node renderer
// -------------

type VariableProps = {
  renderVariable: VariableRenderer<any, any>;
  nameVariable?: VariableNameEntry;
  id: string;
  value: unknown;
  config: unknown;
  displayMissingVariables?: boolean;
};

function Variable({
  renderVariable,
  id,
  value: nodeValue,
  displayMissingVariables,
  config,
  nameVariable,
}: VariableProps) {
  const { valueSet = {} } = useVariableRendererContext() ?? {};
  const contextValue = valueSet[id];
  const value = contextValue ?? nodeValue;

  if (value === undefined || value === "") {
    if (displayMissingVariables && nameVariable) {
      return <VariableToken isUndefined>${nameVariable.name}</VariableToken>;
    }

    console.warn(`Missing value for variable with id "${id}"`);
    return null;
  }

  return <>{renderVariable({ value, config })}</>;
}

export class MissingVariableRendererError extends Error {
  constructor(id: string) {
    super(`Missing renderer for variable id: ${id}`);
    this.name = "MissingVariableRendererError";
  }
}

export const createVariableNodeRenderer = (
  rendererSet: VariableRendererSet<any>,
  {
    asText,
    displayMissingVariables,
    nameSet,
  }: {
    /** asText means we return text instead of jsx; bypasses the displayMissingVariables option. */
    asText?: boolean;
    /**
     * Display missing variables (meaning variables without value) iff nameSet is also defined. Will throw if missing a
     * renderer ie the variable type itself is unknown. */
    displayMissingVariables?: boolean;
    nameSet?: VariableNameSet<any>;
  } = {}
) =>
  createNodeRenderer<SerializedVariableNode>(({ value, config, id }) => {
    const renderVariable = rendererSet[id];
    const nameVariable = nameSet?.[id];

    if (!renderVariable) {
      throw new MissingVariableRendererError(id);
    }

    if (asText) return <>{renderVariable({ value, config }, { asText })}</>;

    return (
      <Variable
        renderVariable={renderVariable}
        displayMissingVariables={displayMissingVariables}
        id={id}
        value={value}
        config={config}
        nameVariable={nameVariable}
      />
    );
  });

export const createUnknownVariableNodeRenderer = () =>
  createNodeRenderer<SerializedUnknownVariableNode>(({ value }) => (
    <>{value.variableId}</>
  ));
