import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasChevronLeft, atlasChevronRight } from "@resource/atlas/icons";
import clsx from "clsx";
import { without } from "lodash";
import { DateTime, MonthNumbers } from "luxon";
import { useMemo, useState } from "react";

const generateCalendarGridDays = ({
  year,
  month,
}: {
  year: number;
  month: MonthNumbers;
}) => {
  const now = DateTime.local();

  // Offset week by 1 to start on Sunday
  let currentGridDate = DateTime.local(year, month)
    .startOf("week")
    .minus({ day: 1 });
  const endMonth = DateTime.local(year, month).endOf("month");
  const endMonthOffset = 6 - (endMonth.weekday % 7);
  const endMonthGrid = endMonth.plus({
    days: endMonthOffset === 0 ? 7 : endMonthOffset,
  });

  const days = [];

  while (currentGridDate <= endMonthGrid) {
    const isCurrentMonth = currentGridDate.month === month;
    const isInPast = currentGridDate < now.startOf("day");
    const isToday = currentGridDate.hasSame(now, "day");

    days.push({
      date: currentGridDate.toISODate(),
      isCurrentMonth,
      isInPast,
      isToday,
    });

    currentGridDate = currentGridDate.plus({ days: 1 });
  }

  return days;
};

export function MonthCalendar({
  selectedDays,
  setSelectedDays,
}: {
  selectedDays: DateTime[];
  setSelectedDays: (selectedDays: DateTime[]) => void;
}) {
  const now = DateTime.local();
  const [currentYear, setCurrentYear] = useState<number>(now.year);
  const [currentMonth, setCurrentMonth] = useState<MonthNumbers>(now.month);

  const days = useMemo(() => {
    return generateCalendarGridDays({ year: currentYear, month: currentMonth });
  }, [currentYear, currentMonth]);

  const daysWithSelections = useMemo(() => {
    return days.map((day) => ({
      ...day,
      isSelected: !!selectedDays.find((d) =>
        d.hasSame(DateTime.fromISO(day.date), "day")
      ),
    }));
  }, [selectedDays, days]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex text-sm font-semibold grow items-center">
          {DateTime.local(currentYear, currentMonth).toFormat("MMMM yyyy")}
        </div>
        <ButtonGroup size="small">
          <Button
            icon={atlasChevronLeft}
            isGhost
            onClick={() => {
              const dateForMonth = DateTime.local(currentYear, currentMonth);
              const nextMonth = dateForMonth.minus({ month: 1 });
              setCurrentYear(nextMonth.year);
              setCurrentMonth(nextMonth.month);
            }}
          />
          <Button
            icon={atlasChevronRight}
            isGhost
            onClick={() => {
              const dateForMonth = DateTime.local(currentYear, currentMonth);
              const nextMonth = dateForMonth.plus({ month: 1 });
              setCurrentYear(nextMonth.year);
              setCurrentMonth(nextMonth.month);
            }}
          />
        </ButtonGroup>
      </div>
      {now.year !== currentYear && (
        <AlertBanner variant="warning">
          You are currently viewing a different year. Please ensure dates are
          correct.
        </AlertBanner>
      )}
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div className="flex items-center justify-center">S</div>
        <div className="flex items-center justify-center">M </div>
        <div className="flex items-center justify-center">T</div>
        <div className="flex items-center justify-center">W</div>
        <div className="flex items-center justify-center">T</div>
        <div className="flex items-center justify-center">F</div>
        <div className="flex items-center justify-center">S</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-light-gray-500 text-sm shadow ring-1 ring-light-gray-500">
        {daysWithSelections.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            onClick={(e) => {
              if (day.isInPast) {
                e.preventDefault();
                return;
              }
              const luxonDate = DateTime.fromISO(day.date);
              const currIndex = selectedDays.findIndex((d) =>
                d.hasSame(luxonDate, "day")
              );
              if (currIndex >= 0) {
                setSelectedDays(without(selectedDays, selectedDays[currIndex]));
              } else {
                setSelectedDays([...selectedDays, luxonDate]);
              }
            }}
            className={clsx(
              "py-1.5 focus:z-10",
              day.isInPast && "cursor-not-allowed",
              !day.isInPast && "hover:bg-light-gray-200",
              day.isSelected && "text-white bg-purple-500 hover:bg-purple-400",
              !day.isSelected && day.isCurrentMonth && "bg-white",
              !day.isSelected && !day.isCurrentMonth && "bg-light-gray-100",
              {
                "rounded-tl-lg": dayIdx === 0,
                "rounded-tr-lg": dayIdx === 6,
                "rounded-bl-lg": dayIdx === days.length - 7,
                "rounded-br-lg": dayIdx === days.length - 1,
              }
            )}
          >
            <time
              dateTime={day.date}
              className={clsx(
                "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                ...(day.isSelected
                  ? [
                      day.isToday &&
                        "text-body-md-heavy text-purple-500 bg-white",
                      day.isInPast && "text-white",
                    ]
                  : [
                      day.isToday && "text-body-md-heavy text-purple-500",
                      day.isInPast && "text-disabled",
                      !day.isInPast && !day.isCurrentMonth && "text-subtle",
                    ])
              )}
            >
              {day.date.split("-").pop()?.replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
    </>
  );
}
