import { z } from "zod";

export const AutomationTypesSchema = z.enum([
  "CreateSelfScheduleRequest",
  "TaskSchedulingAssistant",
  "CreateSchedulingTask",
  "CreateReschedulingTask",
  "SendSlackReminders",
]);

export type AutomationTypes = z.infer<typeof AutomationTypesSchema>;

export const AutomationTypes = AutomationTypesSchema.enum;
