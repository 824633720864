import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog } from "@resource/atlas/dialog";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { atlasPlay } from "@resource/atlas/icons";
import { useLogEvent } from "analytics";
import clsx from "clsx";
import { ModalName, useModals } from "components/ModalProvider";
import InterviewerVideoIntro from "components/VideoIntro";
import { gql } from "generated/graphql-codegen";
import { InterviewerDetailsDialogInterviewerFragment } from "generated/graphql-codegen/graphql";
import prependHttp from "prepend-http";
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  labelForProfileUrl,
  monochromeIconForProfileUrl,
  typeForProfileUrl,
} from "utils/icon-for-profile-url";

const INTERVIEWER_FRAGMENT = gql(`
fragment InterviewerDetailsDialogInterviewer on UserMembership {
    id
    name
    firstName
    lastName
    imageUrl
    title
    biography
    websiteUrls
    videoIntroductionUrl
  }
`);

type InterviewerDetailsDialogContentProps = ComponentPropsWithoutRef<"div"> & {
  interviewer: InterviewerDetailsDialogInterviewerFragment;
};

/** @deprecated Use `interviewer-profile/InterviewerProfilePublicInformation.tsx` */
export function InterviewerDetailsDialogContent({
  interviewer,
  className,
  ...props
}: InterviewerDetailsDialogContentProps) {
  const websiteUrls = useMemo(() => {
    // Move linkedin url to start if it exists
    const websiteUrlsModified = [...interviewer.websiteUrls];
    const linkedinLinkIndex = websiteUrlsModified.findIndex(
      (url) => typeForProfileUrl(url) === "linkedin"
    );
    if (linkedinLinkIndex > 0) {
      const linkedinUrl = websiteUrlsModified.splice(linkedinLinkIndex, 1);
      websiteUrlsModified.unshift(linkedinUrl[0]);
    }
    return websiteUrlsModified;
  }, [interviewer.websiteUrls]);

  const videoIntroDialog = useDialogState();

  const logEvent = useLogEvent({
    component: "InterviewerDetailsDialogContent",
    project: ["Zeus", "Zeus Interviews"],
  });
  useEffect(() => {
    logEvent("Interviewer Details Seen", {
      interviewerId: interviewer.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewer]);

  return (
    <>
      <div className={clsx("flex flex-col gap-6", className)} {...props}>
        <div className="flex justify-between items-center">
          <Avatar
            name={interviewer.name}
            image={interviewer.imageUrl}
            size="3xl"
          />
          <div className="flex gap-4">
            {interviewer.videoIntroductionUrl && (
              <Button
                variant="primary"
                icon={atlasPlay}
                onClick={videoIntroDialog.show}
              >
                Video Intro
              </Button>
            )}
            <ButtonGroup>
              {websiteUrls.map((url) => (
                <Button
                  key={url}
                  as="a"
                  target="_blank"
                  aria-label={labelForProfileUrl(url)}
                  href={prependHttp(url)}
                  negativeMargin="left"
                  icon={monochromeIconForProfileUrl(url)}
                  className="rounded-full"
                />
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div>
          <h1 className="text-h1">{interviewer.name}</h1>
          <span className="text-body-large text-subtle">
            {interviewer.title}
          </span>
        </div>
        {interviewer.biography && (
          <div>
            <h4 className="text-h4 mb-2">Biography</h4>
            <p className="text-body-md">
              {interviewer.biography.split("\n").map((line, i) => (
                <span key={i}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
          </div>
        )}
      </div>
      <Dialog.Root state={videoIntroDialog}>
        <Dialog.Content size="medium">
          {interviewer.videoIntroductionUrl && (
            <InterviewerVideoIntro
              mediaLink={interviewer.videoIntroductionUrl}
            />
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

type InterviewerDetailsDialogProps = {
  id?: string;
  interviewer?: InterviewerDetailsDialogInterviewerFragment;
  onClose?: () => void;
};

export function InterviewerDetailsDialog({
  id,
  interviewer,
  onClose,
}: InterviewerDetailsDialogProps) {
  const modalManager = useModals();
  const handleClose = useCallback(() => {
    modalManager.close();
    onClose?.();
  }, [modalManager, onClose]);

  if (!interviewer) {
    return null;
  }

  return (
    <Dialog.Root
      open={
        modalManager.state.name === ModalName.INTERVIEWER_DETAILS &&
        (!id || id === modalManager.state.id)
      }
      setOpen={(value) => {
        if (!value) handleClose();
      }}
    >
      <Dialog.Content size="medium">
        <InterviewerDetailsDialogContent interviewer={interviewer} />
      </Dialog.Content>
    </Dialog.Root>
  );
}

InterviewerDetailsDialog.fragments = {
  interviewer: INTERVIEWER_FRAGMENT,
};
