import { OptionItem } from "@resource/atlas/option/OptionItem";
import { Popover } from "@resource/atlas/popover";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import clsx from "clsx";
import { useMemo } from "react";

export type FilterProps<T extends string | number | undefined | null> = {
  label: string;
  selectedPrefix?: string;
  allLabelOverride?: string;
  value: T | null;
  onChange: (value: T | null) => void;
  values: { value: T; label: string }[];
  size?: AtlasSelectTriggerProps["size"];
  allowAll?: boolean;
};
export function Filter<T extends string | number | undefined | null>({
  label,
  value,
  onChange,
  values,
  selectedPrefix,
  allowAll = true,
  allLabelOverride = "All",
  size,
}: FilterProps<T>) {
  const options = useMemo(() => {
    return values.map((v) => (
      <OptionItem
        key={v.value}
        isSelectable
        isSelected={value === v.value}
        onClick={() => onChange(v.value)}
      >
        {v.label}
      </OptionItem>
    ));
  }, [onChange, value, values]);

  return (
    <Popover.Root>
      <Popover.Trigger>
        <SelectTrigger
          className={clsx({
            "bg-purple-50 text-purple-500 hover:!bg-purple-100 active:!bg-purple-100 focus:!bg-purple-100":
              !!value,
          })}
          size={size}
        >
          {value
            ? `${selectedPrefix ?? ""}${
                values.find((v) => v.value === value)?.label
              }`
            : label}
        </SelectTrigger>
      </Popover.Trigger>
      <Popover.Content hasPadding={false} className="p-2">
        {allowAll && (
          <OptionItem
            key="all"
            isSelectable
            isSelected={!value}
            onClick={() => onChange(null)}
          >
            {allLabelOverride}
          </OptionItem>
        )}
        {options}
      </Popover.Content>
    </Popover.Root>
  );
}
