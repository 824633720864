import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { getConferencingTypeFromLocationType } from "client/components/conferencing/utils/mapping";
import { SelfScheduleRequestForBlockFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import {
  SchedulingHoursConfig,
  SelfScheduleRequestForCreate,
} from "shared/self-schedule/types";

import { useUpdateSelfScheduleRequest } from "./__hooks/useUpdateSelfScheduleRequest";
import { EditSelfScheduleRequestSettingsView } from "./EditSelfScheduleRequestSettingsView";

export function EditExistingSelfScheduleRequestDialog({
  store,
  selfScheduleRequest,
}: {
  store: DialogStore;
  selfScheduleRequest: SelfScheduleRequestForBlockFragment;
}) {
  const [updateSelfScheduleRequest, { loading: updateLoading }] =
    useUpdateSelfScheduleRequest({
      selfScheduleRequestId: selfScheduleRequest.id,
    });
  const onSave = useCallback(
    async (updatedData: SelfScheduleRequestForCreate) => {
      await updateSelfScheduleRequest({
        data: updatedData,
        onCompleted: () => {
          store.hide();
        },
      });
    },
    [store, updateSelfScheduleRequest]
  );
  return (
    <Dialog
      store={store}
      size="large"
      variant="fullscreen"
      hideOnEscape={false}
    >
      <EditSelfScheduleRequestSettingsView
        selfScheduleRequest={{
          ...selfScheduleRequest,
          interviewSettings: {
            ...selfScheduleRequest.interviewSettings,
            locationSettings: selfScheduleRequest.interviewSettings
              .locationSettings
              ? {
                  ...selfScheduleRequest.interviewSettings.locationSettings,
                  service: getConferencingTypeFromLocationType(
                    selfScheduleRequest.interviewSettings.locationSettings
                      .service
                  ),
                }
              : null,
          },
          schedulingSettings: {
            ...selfScheduleRequest.schedulingSettings,
            schedulingHours: selfScheduleRequest.schedulingSettings
              .schedulingHours as SchedulingHoursConfig,
          },
        }}
        onSave={onSave}
        saveLoading={updateLoading}
        onCancel={store.hide}
        atsInterviewName={selfScheduleRequest.interviewSettings.interview?.name}
      />
    </Dialog>
  );
}
