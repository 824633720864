import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCircleWarning,
  atlasCode,
  atlasEllipsisHorizontal,
  atlasPhone,
  atlasVideo,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { displayPhoneNumber, isPossibleNumber } from "@resource/common";
import { useAuthContext } from "auth/context";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { CalendarIcon } from "client/components/guide/interviews/CalendarIcon";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { useInternalScheduledInterviewMenuItems } from "client/components/scheduled-interviews/__hooks/useInternalScheduledInterviewMenuItems";
import { CypressData } from "client/cypress-data-keys";
import { useTimezone } from "client/timezones/useTimezone";
import { getFormattedDateRange } from "client/utils/dates";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import {
  AsyncProcessingStatus,
  CollaborativeCodingServiceType,
  OrganizationFeaturesEnum,
  ScheduledInterviewForScheduledInterviewCardFragment,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import { Provider } from "jotai";
import { uniqBy } from "lodash";
import { DateTime } from "luxon";
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getCollaborativeCodingIcon,
  getCollaborativeCodingServiceDisplayName,
  getVideoConferencingIcon,
  getVideoConferencingServiceDisplayName,
} from "shared/utils/interview-services";

import {
  CollaborativeCodingIcon,
  VideoConferencingIcon,
} from "./__components/Icons";
import { StartCountdown } from "./__components/StartCountdown";
import {
  useLiteIntegrationStatus,
  useSchedulerIntegrationStatus,
} from "./__hooks/useIntegrationStatus";
import { useStartsIn } from "./__hooks/useStartsIn";
import { Interviewer, InterviewersSection } from "./InterviewersSection";

export type ScheduledInterviewForCard = {
  id: string;
  title: string;
  startTime: DateTime;
  schedulingRequestId?: string | null;
  guideId?: string | null;
  endTime: DateTime;
  collaborativeCodingService: CollaborativeCodingServiceType | null;
  collaborativeCodingUrl?: string | null;
  videoConferencingService: VideoConferencingServiceType | null;
  videoConferencingUrl?: string | null;
  conferencePhone?: string | null;
  interviewers: Interviewer[];
  isCancelled: boolean;
  createdByGuide: boolean;
  isPrivate: boolean;
  blacklisted: boolean;
  greenhouseSyncStatus: AsyncProcessingStatus | null;
  googleSyncStatus: AsyncProcessingStatus | null;
  googleEvent:
    | ScheduledInterviewForScheduledInterviewCardFragment["googleEvent"]
    | null;
  atssyncScheduledInterview:
    | ScheduledInterviewForScheduledInterviewCardFragment["atssyncScheduledInterview"]
    | null;
};

export type ScheduledInterviewCardProps = ComponentPropsWithoutRef<"div"> & {
  scheduledInterview: ScheduledInterviewForCard;
  menuItemsConfig?:
    | ReturnType<typeof useInternalScheduledInterviewMenuItems>
    | ReturnType<typeof useMenuItems>;
  isActive?: boolean;
  timezone?: string;
  Badge?: JSX.Element | null;
  variant?: "default" | "compact" | "compact-xs" | "candidate";
  showAsUpcoming?: boolean;
  showAsPast?: boolean;
  hideInternalStatuses?: boolean;
  showShadowingStatus?: boolean;
  errorMessages?: string[];
  warningMessages?: {
    icon: string;
    message: string;
  }[];
  statuses?: {
    icon: string;
    tooltip: string;
  }[];
  cardActions?: JSX.Element;
};

export function ScheduledInterviewCard(props: ScheduledInterviewCardProps) {
  const {
    scheduledInterview,
    isActive = false,
    menuItemsConfig,
    onClick,
    timezone,
    Badge,
    variant = "default",
    showAsUpcoming,
    showAsPast,
    hideInternalStatuses,
    cardActions,
    ...rest
  } = props;
  const showSchedulerInternalStatuses = useShowSchedulerInternalStatuses(props);
  const integrationStatus = useSchedulerIntegrationStatus({
    scheduledInterview,
  });
  const showLiteInternalStatuses = useShowLiteInternalStatuses(props);
  const liteIntegrationStatus = useLiteIntegrationStatus({
    scheduledInterview,
  });
  const { ref, cardSize } = useCardSizing();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const tooltipContent = useMemo(() => {
    if (isMenuOpen) {
      return undefined;
    }

    if (showSchedulerInternalStatuses) {
      return integrationStatus.tooltipContent;
    }

    if (showLiteInternalStatuses) {
      return liteIntegrationStatus.tooltipContent;
    }

    return undefined;
  }, [
    integrationStatus.tooltipContent,
    isMenuOpen,
    liteIntegrationStatus.tooltipContent,
    showLiteInternalStatuses,
    showSchedulerInternalStatuses,
  ]);

  const isCompact = variant === "compact" || variant === "compact-xs";

  return (
    <Provider>
      <Tooltip
        content={tooltipContent}
        side="top"
        className="w-full relative"
        isInstant
      >
        <div
          {...rest}
          {...(onClick
            ? {
                role: "button",
                tabIndex: 0,
                onClick,
              }
            : {})}
          ref={ref}
          className={clsx(
            "flex flex-col rounded-md w-full bg-white overflow-hidden",
            !isCompact ? "gap-3" : "gap-2",
            !isCompact
              ? {
                  "p-[0.4375rem]": isActive,
                  "p-3": !isActive,
                }
              : {
                  "p-[0.4375rem]": isActive,
                  "p-2": !isActive,
                },
            isActive
              ? "border-purple-500 border-2" // remove one pixel padding when active to make up for extra pixel of border
              : "border-gray-border border",
            onClick
              ? "hover:bg-light-gray-200 cursor-pointer"
              : "cursor-default",
            rest.className
          )}
          data-id={scheduledInterview.id}
        >
          <ScheduledInterviewCardDisplay
            {...props}
            cardSize={cardSize}
            isMenuOpen={isMenuOpen}
            setMenuOpen={setMenuOpen}
          />
          {showSchedulerInternalStatuses && integrationStatus.icon && (
            <div className="absolute -top-2.5 -left-2.5">
              <Icon
                content={integrationStatus.icon}
                className={clsx("w-5 h-5", integrationStatus.iconClassName)}
              />
            </div>
          )}
          {showLiteInternalStatuses && liteIntegrationStatus.icon && (
            <div className="absolute -top-2.5 -left-2.5">
              <Icon
                content={liteIntegrationStatus.icon}
                className={clsx("w-5 h-5", liteIntegrationStatus.iconClassName)}
              />
            </div>
          )}
        </div>
      </Tooltip>
    </Provider>
  );
}

function ScheduledInterviewCardDisplay(
  props: ScheduledInterviewCardProps & {
    cardSize: CardSize;
    isMenuOpen: boolean;
    setMenuOpen: (open: boolean) => void;
  }
) {
  const {
    scheduledInterview,
    menuItemsConfig,
    Badge,
    variant = "default",
    cardSize,
    isMenuOpen,
    setMenuOpen,
    errorMessages,
    warningMessages,
    statuses,
    cardActions,
    showShadowingStatus,
  } = props;
  const interviewDates = useFormattedDateTimes(props);
  const showInternalStatuses = useShowSchedulerInternalStatuses(props);
  const isInPast = useIsInPast(props);
  const uniqInterviewers = useMemo(() => {
    return uniqBy(scheduledInterview.interviewers, "userMembership.id");
  }, [scheduledInterview.interviewers]);
  const { isStartingInMoreThan15Minutes, isHappeningNow } = useStartsIn(
    scheduledInterview.startTime,
    scheduledInterview.endTime
  );

  const showLinksAsButtons =
    variant === "candidate" &&
    ((!isStartingInMoreThan15Minutes && !isInPast) || isHappeningNow);

  const isCompact = variant === "compact" || variant === "compact-xs";
  const isCompactXs = variant === "compact-xs";

  return (
    <>
      <div
        className={clsx("flex flex-row w-full", isCompact ? "gap-2" : "gap-3")}
      >
        {isCompact ? (
          <div />
        ) : (
          <CalendarIcon
            className="shrink-0"
            isInPast={isInPast}
            day={interviewDates.formattedDay}
            dayName={interviewDates.formattedDayName}
            size="sm"
          />
        )}
        <div className="flex flex-col grow shrink min-w-0 justify-center gap-2">
          <div className={clsx("flex flex-col space-y-2")}>
            <div className="flex-grow min-w-0">
              <div className="flex justify-between">
                <div className="flex flex-col flex-grow min-w-0 mr-2">
                  <div className="flex flex-grow min-w-0 items-center gap-1">
                    <InterviewTitle
                      className={clsx(" truncate", {
                        "text-dark": !isInPast,
                        "text-subtle": isInPast,
                        "text-body-md-heavy": !isInPast && !isCompactXs,
                        "text-body-sm-heavy": !isInPast && isCompactXs,
                        "text-body-md": isInPast && !isCompactXs,
                        "text-body-sm": isInPast && isCompactXs,
                      })}
                      interview={scheduledInterview}
                      data-cy={CypressData.interviews.scheduledInterviewTitle}
                    />
                    {Badge}
                    {variant === "candidate" &&
                      !scheduledInterview.isCancelled && (
                        <StartCountdown {...scheduledInterview} />
                      )}
                  </div>
                  <div className="flex gap-1 items-center">
                    <p
                      className={clsx("text-subtle text-body-sm truncate", {
                        "line-through": scheduledInterview.isCancelled,
                      })}
                      title={interviewDates.formattedDateTime}
                      data-cy={CypressData.interviews.scheduledInterviewTime}
                    >
                      {interviewDates.formattedDateTime}
                    </p>
                  </div>
                </div>
                <div className={clsx("flex space-x-2")}>
                  {statuses?.map(({ icon, tooltip }) => (
                    <Tooltip
                      key={icon}
                      content={tooltip}
                      isInstant
                      // make it match XS button sizing
                      className="h-6 flex flex-col justify-center"
                    >
                      <Icon content={icon} className="w-5 h-5" />
                    </Tooltip>
                  ))}
                  {cardActions && (
                    <StopPropagation>
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                      <div onClick={(e) => e.preventDefault()}>
                        {cardActions}
                      </div>
                    </StopPropagation>
                  )}
                  <ScheduledInterviewCardMenu
                    menuItemsConfig={menuItemsConfig}
                    isMenuOpen={isMenuOpen}
                    setMenuOpen={setMenuOpen}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row space-x-2 items-center">
              <InterviewersSection
                size={variant === "compact-xs" ? "xs" : "small"}
                className={clsx({ "opacity-40": isInPast })}
                max={5}
                interviewers={uniqInterviewers}
                isInPast={false}
                showRsvpStatus={showInternalStatuses}
                showShadowingStatus={
                  showShadowingStatus || showInternalStatuses
                }
              />
              <div className="space-x-[.125rem] flex shrink-0">
                {scheduledInterview.videoConferencingService &&
                  !showLinksAsButtons && (
                    <Tooltip
                      isInstant
                      content={
                        scheduledInterview.videoConferencingUrl ?? undefined
                      }
                    >
                      <VideoConferencingIcon
                        service={scheduledInterview.videoConferencingService}
                        isInPast={!!isInPast}
                      />
                    </Tooltip>
                  )}
                {scheduledInterview.collaborativeCodingService &&
                  !showLinksAsButtons && (
                    <Tooltip
                      isInstant
                      content={
                        scheduledInterview.collaborativeCodingUrl ?? undefined
                      }
                    >
                      <CollaborativeCodingIcon
                        service={scheduledInterview.collaborativeCodingService}
                        isInPast={!!isInPast}
                      />
                    </Tooltip>
                  )}
                {scheduledInterview.conferencePhone && !showLinksAsButtons && (
                  <Tooltip
                    isInstant
                    content={`${
                      isPossibleNumber(scheduledInterview.conferencePhone)
                        ? displayPhoneNumber(scheduledInterview.conferencePhone)
                        : scheduledInterview.conferencePhone
                    }`}
                  >
                    <Icon
                      content={atlasPhone}
                      className="w-5 h-5 text-subtle"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {showLinksAsButtons && (
            <ButtonLinks
              scheduledInterview={scheduledInterview}
              cardSize={cardSize}
            />
          )}
        </div>
      </div>
      {errorMessages && errorMessages.length > 0 && (
        <div className="space-y-2">
          {errorMessages.map((message) => (
            <div
              className="bg-red-50 text-body-md text-red-500 rounded-md p-2 space-y-2"
              key={message}
            >
              <div className="flex flex-row space-x-2">
                <Icon
                  content={atlasCircleWarning}
                  className="text-red-500 w-5 h-5"
                />
                <span>{message}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {warningMessages && warningMessages.length > 0 && (
        <div className="space-y-2">
          {warningMessages.map(({ icon, message }) => (
            <div
              className="bg-yellow-50 text-body-md text-yellow-900 rounded-md p-2 space-y-2"
              key={message}
            >
              <div className="flex flex-row space-x-2">
                <Icon content={icon} className="text-yellow-500 w-5 h-5" />
                <span>{message}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function ButtonLinks({
  scheduledInterview,
  showAsPast,
  showAsUpcoming,
  cardSize: { isSmall },
}: Pick<
  ScheduledInterviewCardProps,
  "scheduledInterview" | "showAsPast" | "showAsUpcoming"
> & {
  cardSize: CardSize;
}) {
  const isInPast = useIsInPast({
    scheduledInterview,
    showAsPast,
    showAsUpcoming,
  });

  return (
    <div
      className={clsx("flex gap-2 w-full mt-2", {
        "flex-row": !isSmall,
        "flex-col": isSmall,
      })}
    >
      {scheduledInterview.conferencePhone && (
        <div
          className={clsx(
            "flex flex-row space-x-2 h-10 items-center text-body-sm",
            {
              "w-full": isSmall,
              "w-auto": !isSmall,
            }
          )}
        >
          <Icon content={atlasPhone} className="w-5 h-5 text-subtle" />
          <span>
            Your interviewer will call you at{" "}
            {isPossibleNumber(scheduledInterview.conferencePhone)
              ? displayPhoneNumber(scheduledInterview.conferencePhone)
              : scheduledInterview.conferencePhone}
          </span>
        </div>
      )}
      {scheduledInterview.videoConferencingService &&
        scheduledInterview.videoConferencingUrl && (
          <Button
            icon={
              getVideoConferencingIcon({
                service: scheduledInterview.videoConferencingService,
                isInPast,
              }) ?? atlasVideo
            }
            onClick={() => {
              if (scheduledInterview.videoConferencingUrl) {
                window.open(scheduledInterview.videoConferencingUrl, "_blank");
              }
            }}
            className={clsx({
              "w-full": isSmall,
            })}
            variant="white"
          >
            Join{" "}
            {getVideoConferencingServiceDisplayName(
              scheduledInterview.videoConferencingService
            )}{" "}
            meeting
          </Button>
        )}
      {scheduledInterview.collaborativeCodingService &&
        scheduledInterview.collaborativeCodingUrl && (
          <Button
            icon={
              getCollaborativeCodingIcon({
                service: scheduledInterview.collaborativeCodingService,
                isInPast,
              }) ?? atlasCode
            }
            onClick={() => {
              if (scheduledInterview.collaborativeCodingUrl) {
                window.open(
                  scheduledInterview.collaborativeCodingUrl,
                  "_blank"
                );
              }
            }}
            className={clsx({
              "w-full": isSmall,
            })}
            variant="white"
          >
            Open{" "}
            {getCollaborativeCodingServiceDisplayName(
              scheduledInterview.collaborativeCodingService
            )}
          </Button>
        )}
    </div>
  );
}

function ScheduledInterviewCardMenu({
  menuItemsConfig,
  isMenuOpen,
  setMenuOpen,
}: Pick<ScheduledInterviewCardProps, "menuItemsConfig"> & {
  isMenuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
}) {
  const { Dialog, menuItems } = useMemo(() => {
    if (menuItemsConfig && "menuItems" in menuItemsConfig) {
      return menuItemsConfig;
    }

    return {
      menuItems: menuItemsConfig,
      Dialog: <></>,
    };
  }, [menuItemsConfig]);

  if (!menuItems || !menuItems.length) {
    return null;
  }

  return (
    <>
      <StopPropagation>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div onClick={(e) => e.preventDefault()}>
          <Menu.Root setOpen={(open) => setMenuOpen(open)} placement="left">
            <Menu.Trigger>
              <Button
                icon={atlasEllipsisHorizontal}
                isGhost
                size="xs"
                isActive={isMenuOpen}
              />
            </Menu.Trigger>
            <Menu.Content portal items={menuItems} />
          </Menu.Root>
        </div>
      </StopPropagation>
      {Dialog}
    </>
  );
}

function useShowSchedulerInternalStatuses({
  hideInternalStatuses,
}: Pick<ScheduledInterviewCardProps, "hideInternalStatuses">) {
  const { isOrgUser } = useGuideAuthContext();
  const { hasFeatureEnabled } = useAuthContext();
  const isSchedulingEnabled = hasFeatureEnabled(
    OrganizationFeaturesEnum.SCHEDULING
  );
  return !hideInternalStatuses && isOrgUser && isSchedulingEnabled;
}

function useShowLiteInternalStatuses({
  hideInternalStatuses,
}: Pick<ScheduledInterviewCardProps, "hideInternalStatuses">) {
  const { isOrgUser } = useGuideAuthContext();
  const { hasFeatureEnabled } = useAuthContext();
  const isSchedulingEnabled = hasFeatureEnabled(
    OrganizationFeaturesEnum.SCHEDULING
  );
  return !hideInternalStatuses && isOrgUser && !isSchedulingEnabled;
}

function useIsInPast({
  showAsPast,
  showAsUpcoming,
  scheduledInterview,
}: Pick<
  ScheduledInterviewCardProps,
  "showAsPast" | "showAsUpcoming" | "scheduledInterview"
>) {
  const { isInPast } = useStartsIn(
    scheduledInterview.startTime,
    scheduledInterview.endTime
  );
  return (!showAsUpcoming && isInPast) || showAsPast;
}

function useFormattedDateTimes({
  scheduledInterview,
  timezone,
}: Pick<ScheduledInterviewCardProps, "scheduledInterview" | "timezone">) {
  const contextTimezone = useTimezone();

  return useMemo(() => {
    return getFormattedDateRange({
      startTime: scheduledInterview.startTime,
      endTime: scheduledInterview.endTime,
      timezone: timezone ?? contextTimezone,
    });
  }, [
    contextTimezone,
    scheduledInterview.endTime,
    scheduledInterview.startTime,
    timezone,
  ]);
}

type CardSize = {
  size: "small" | "large";
  isSmall: boolean;
  isLarge: boolean;
};

function useCardSizing() {
  const ref = useRef<HTMLDivElement>(null);
  const [cardSize, setCardSize] = useState<"small" | "large">("large");

  const updateCardSize = useCallback(() => {
    if (ref.current) {
      const { width } = ref.current.getBoundingClientRect();
      setCardSize(width < 450 ? "small" : "large");
    }
  }, [setCardSize]);

  useEffect(() => {
    if (ref.current) {
      updateCardSize();
    }
  }, [updateCardSize]);

  useEffect(() => {
    const handleResize = () => {
      updateCardSize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateCardSize]);

  return useMemo(() => {
    return {
      ref,
      cardSize: {
        size: cardSize,
        isSmall: cardSize === "small",
        isLarge: cardSize === "large",
      },
    };
  }, [cardSize]);
}
