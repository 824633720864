import "./FloatingToolbarPlugin.sass";

import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import clsx from "clsx";
import {
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  atlasCheck,
  atlasLink,
  atlasLinkExternal,
  atlasTrash,
} from "../../../../../../icons/atlas";
import { createComponentUtils } from "../../../../../__utils/atlas";
import {
  useEvent,
  useScheduleFocus,
  useStaticValue,
} from "../../../../../__utils/react";
import { Button } from "../../../../../button/Button";
import { ButtonGroup } from "../../../../../button/ButtonGroup";
import { Popover } from "../../../../../popover";
import { AtlasPopoverRootProps } from "../../../../../popover/types";
import { usePopoverState } from "../../../../../popover/use-popover-state";
import TextField from "../../../../../textfield/TextField";
import Tooltip from "../../../../../tooltip/Tooltip";
import { InsertOption, useParagraphStyleMenu } from "../../../insert-options";
import { useFormattingButtons } from "../../../misc";
import { useLink, useSelection } from "../../../selection-context";

// config
// ------

const COMPONENT_NAME = "ContentEditor-FloatingToolbar";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// mode
// ----

type Mode = "toolbar" | "link-editor";

// toolbar content
// ---------------

type ToolbarContentProps = {
  insertOptions: InsertOption[];
  showLinkEditor: () => void;
};

function ToolbarContent({
  insertOptions,
  showLinkEditor,
}: ToolbarContentProps) {
  const { isLink, toggleLink } = useLink();
  const { isUncollapsedSelection } = useSelection();

  const onLinkButtonClick = useEvent(() => {
    if (!isLink) toggleLink();
    showLinkEditor();
  });

  return (
    <ButtonGroup isGhost size="xs" variant="light">
      {useParagraphStyleMenu(
        insertOptions,
        useStaticValue({
          fullTarget: "floating-toolbar-paragraph-style",
          subTarget: "floating-toolbar",
        })
      )}
      {useFormattingButtons()}
      {/* TODO: replace with insert option slot */}
      <Tooltip isInstant content={isLink ? "Edit link" : "Insert link"}>
        <Button
          aria-label={isLink ? "edit link" : "insert link"}
          disabled={!isLink && !isUncollapsedSelection}
          icon={atlasLink}
          isActive={isLink}
          onClick={onLinkButtonClick}
        />
      </Tooltip>
    </ButtonGroup>
  );
}

// link editor
// -----------

type LinkEditorProps = {
  linkInputRef: RefObject<HTMLInputElement>;
  hideLinkEditor: () => void;
};

function LinkEditor({ linkInputRef, hideLinkEditor }: LinkEditorProps) {
  const { isLink, linkUrl } = useLink();

  // link input value
  const [linkValue, setLinkValue] = useState("");
  useEffect(() => {
    // sync with link url
    if (linkUrl) setLinkValue(linkUrl ?? "");
  }, [linkUrl]);
  // check whether the value has been modified without saving
  const inputDirty = linkValue !== linkUrl;

  // link methods
  const [editor] = useLexicalComposerContext();
  const updateLink = useEvent(() => {
    editor.dispatchCommand(
      TOGGLE_LINK_COMMAND,
      linkValue === "" ? null : linkValue
    );
    hideLinkEditor();
  });
  const removeLink = useEvent(() => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    hideLinkEditor();
  });

  const onLinkInputKeyDown = useEvent((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updateLink();
    }
    if (e.key === "Escape") {
      e.preventDefault();
      hideLinkEditor();
    }
  });

  return (
    <div className="flex p-[.5rem] gap-[.25rem]">
      <TextField
        inputRef={linkInputRef}
        aria-label="link URL"
        size="small"
        value={linkValue}
        onChange={setLinkValue}
        onKeyDown={onLinkInputKeyDown}
      />
      <ButtonGroup size="small" isGhost>
        {inputDirty && isLink && (
          <Button icon={atlasCheck} onClick={updateLink} />
        )}
        <Button icon={atlasTrash} onClick={removeLink} />
        <Button
          icon={atlasLinkExternal}
          as="a"
          href={linkUrl}
          target="_blank"
        />
      </ButtonGroup>
    </div>
  );
}

// selection popover
// -----------------

export type SelectionPopoverProps = {
  children: ReactNode;
  open?: boolean;
  placement?: AtlasPopoverRootProps["placement"];
  mode: Mode;
};

export function SelectionPopover({
  children,
  open,
  placement,
  mode,
}: SelectionPopoverProps) {
  const { getLatestRangeSelectionRect } = useSelection();

  // retain the popover position during the closing animation
  const previousAnchorRectRef = useRef<DOMRect | null>(null);

  // compute anchor rect
  const { getLatestLinkRect } = useLink();
  const getAnchorRect = useEvent(() => {
    const currentAnchorRect =
      mode === "link-editor"
        ? getLatestLinkRect()
        : getLatestRangeSelectionRect();
    return !open ? previousAnchorRectRef.current : currentAnchorRect;
  });

  const popover = usePopoverState({ open, placement, getAnchorRect });

  const [editor] = useLexicalComposerContext();
  const { render: renderPopover } = popover;
  useEffect(
    () =>
      editor.registerUpdateListener(() => {
        // cache previous anchor rect to retain position on close
        if (open) previousAnchorRectRef.current = getAnchorRect();
        // reposition popover on updates
        renderPopover();
      }),
    [editor, getAnchorRect, open, renderPopover]
  );

  return (
    <Popover.Root state={popover}>
      <Popover.Content
        className={clsx(ROOT, `mode-${mode}`)}
        hasPadding={false}
        autoFocusOnShow={false}
        portal
      >
        {children}
      </Popover.Content>
    </Popover.Root>
  );
}

// toolbar plugin
// --------------

type FloatingToolbarPluginProps = {
  insertOptions?: InsertOption[];
};

export function FloatingToolbarPlugin({
  insertOptions = [],
}: FloatingToolbarPluginProps) {
  const { isLink } = useLink();
  const { isUncollapsedSelection } = useSelection();

  // link editor
  const linkInputRef = useRef<HTMLInputElement>(null);
  const [linkEditorOpen, setLinkEditorOpen] = useState(false);
  const scheduleLinkInputFocus = useScheduleFocus(linkInputRef, {
    afterFocus: () => linkInputRef.current?.select(),
  });
  const showLinkEditor = useEvent(() => {
    setLinkEditorOpen(true);
    // TODO: add a `renderCount` option to `scheduleFocus`?
    setTimeout(() => scheduleLinkInputFocus(), 0);
  });
  const hideLinkEditor = useEvent(() => setLinkEditorOpen(false));

  // compute new state
  const open = isLink || isUncollapsedSelection;
  const newMode =
    isLink && (linkEditorOpen || !isUncollapsedSelection)
      ? "link-editor"
      : "toolbar";

  // retain the mode during the closing animation
  const [previousMode, setPreviousMode] = useState<Mode>();
  const mode = !open && previousMode ? previousMode : newMode;
  useEffect(() => setPreviousMode(mode), [mode]);

  // hide link editor on popover close
  useEffect(() => {
    if (!open && linkEditorOpen) setLinkEditorOpen(false);
  }, [linkEditorOpen, open]);

  return (
    <SelectionPopover open={open} placement="top" mode={mode}>
      {mode === "toolbar" ? (
        <ToolbarContent
          insertOptions={insertOptions}
          showLinkEditor={showLinkEditor}
        />
      ) : (
        <LinkEditor
          linkInputRef={linkInputRef}
          hideLinkEditor={hideLinkEditor}
        />
      )}
    </SelectionPopover>
  );
}

// TODO:
// - ability to focus in and out of it (maybe focus on escape?)
// - hide when editor is not focused
