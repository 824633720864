import {
  DAY_VIEW_TYPE_LOCAL_STORAGE_KEY,
  DayViewType,
} from "client/components/calendar-v2/hooks/settings";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";

export function useDefaultDayViewType(): DayViewType {
  const {
    [FeatureFlags.INTERVIEWER_CALENDAR_VIEW]: interviewerCalendarViewEnabled,
  } = useFlags();
  const localStorageValue = localStorage.getItem(
    DAY_VIEW_TYPE_LOCAL_STORAGE_KEY
  );

  if (localStorageValue && typeof localStorageValue === "string") {
    const parsedValue = JSON.parse(localStorageValue) as DayViewType;

    if (
      parsedValue === "day" ||
      parsedValue === "calendar" ||
      parsedValue === "calendar_and_grouping"
    ) {
      return parsedValue;
    }
  }

  return interviewerCalendarViewEnabled ? "calendar_and_grouping" : "day";
}
