import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";

import { SerializedShareAvailabilityNode } from "./rich-block";

// node renderer
// -------------

function ShareAvailiabilityNodeRenderer() {
  // If there is data in the node but it doesn't match the new block, render the old block and cast data
  return (
    <div className="bg-light-gray-200 h-full rounded-md flex p-6 mb-2 text-dark justify-center items-center">
      <div className="flex flex-col items-center text-center">
        <p className="text-body-md text-subtle mt-2">
          Unable to render this availability submission
        </p>
      </div>
    </div>
  );
}

export const renderShareAvailabilityNode =
  createNodeRenderer<SerializedShareAvailabilityNode>(() => (
    <ShareAvailiabilityNodeRenderer />
  ));
