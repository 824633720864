import { z } from "zod";

import { NotificationType } from "../types";
import { commentMentionDataSchema } from "./CommentMention";
import { importantAriaMessageDataSchema } from "./ImportantAriaMessage";
import { schedulingRequestAssignedDataSchema } from "./SchedulingRequestAssigned";

export const notificationTypeSchemaDefinitions = {
  [NotificationType.CommentMention]: commentMentionDataSchema,
  [NotificationType.SchedulingRequestAssigned]:
    schedulingRequestAssignedDataSchema,
  [NotificationType.ImportantAriaMessage]: importantAriaMessageDataSchema,
} as const;

export type NotificationTypeMetaFields<T extends NotificationType> = z.infer<
  (typeof notificationTypeSchemaDefinitions)[T]["metadata"]
>;

export type NotificationTypeResolvedFields<T extends NotificationType> =
  z.infer<(typeof notificationTypeSchemaDefinitions)[T]["resolved"]>;
