import { Link } from "@resource/atlas/link/Link";
import useIntercom from "utils/useIntercom";

export function BlockError({
  label,
  showCta = true,
}: {
  label: string;
  showCta?: boolean;
}) {
  const intercom = useIntercom();

  return (
    <div className="rounded-md text-body-sm p-4 shadow-1 flex items-center justify-center flex-col gap-1">
      <p className="text-subtle">{label}</p>
      {showCta && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          as="button"
          onClick={() => {
            intercom.callIntercom("show");
          }}
        >
          Report an issue
        </Link>
      )}
    </div>
  );
}
