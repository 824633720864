import { InterviewerSlotInputSchema } from "shared/interviewer-slots/types";
import {
  CandidateConfirmationCancellationInputSchema,
  InterviewerConfirmationCancellationInputSchema,
  InterviewerConfirmationCreateInputSchema,
  InterviewerConfirmationUpdateInputSchema,
} from "shared/notifications/types";
import {
  CancellationReasonSchema,
  UpdateReasonSchema,
} from "shared/reporting/scheduled-interviews/types";
import { z } from "zod";

import { CollaborativeCodingSlotInputSchema } from "./collaborative-coding/types";
import { ConferenceRoomSlotInputSchema } from "./conference-rooms/types";
import {
  ConferencingGenerationSettingsSchema,
  ConferencingSlotInputSchema,
  GroupConferencingSlotInputSchema,
} from "./conferencing/types";
import { ScheduledInterviewFieldChangeSchema } from "./field-changes/types";

export enum InterviewerResponse {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  TENTATIVE = "TENTATIVE",
  NEEDS_ACTION = "NEEDS_ACTION",
}

export const InterviewerResponseSchema = z.nativeEnum(InterviewerResponse);

export enum ActorType {
  CANDIDATE = "CANDIDATE",
  INTERVIEWER = "INTERVIEWER",
  COORDINATOR = "COORDINATOR",
}

export const ActorTypeSchema = z.nativeEnum(ActorType);

// Base types for interview operations
const BaseUpsertInputSchema = z.object({
  title: z.string().optional(),
  isPrivate: z.boolean().optional(),
  conferenceRoomSlot: ConferenceRoomSlotInputSchema.nullable().optional(),
  conferencingSlot: ConferencingSlotInputSchema.nullable().optional(),
  collaborativeCodingSlot:
    CollaborativeCodingSlotInputSchema.nullable().optional(),
});

export type BaseUpsertInput = z.infer<typeof BaseUpsertInputSchema>;

// Update types
export const UpdateInterviewInputSchema = BaseUpsertInputSchema.extend({
  id: z.string(),
  startTime: z.string().optional(),
  endTime: z.string().optional(),
  interviewerSlots: z.array(InterviewerSlotInputSchema).nullable().optional(),
  fieldChanges: z.array(ScheduledInterviewFieldChangeSchema).nullable(),
  existingVideoConferencingLink: z.any().nullable().optional(), // Replace with proper type if needed
  reportingReason: UpdateReasonSchema.nullable(),
  actorType: ActorTypeSchema,
});

export type UpdateInterviewInput = z.infer<typeof UpdateInterviewInputSchema>;

// Create types
export const CreateInterviewInputSchema = BaseUpsertInputSchema.extend({
  interviewId: z.string().nullable(),
  startTime: z.string(),
  endTime: z.string(),
  interviewerSlots: z.array(InterviewerSlotInputSchema),
  hidden: z.boolean().optional(),
  createdFromInterviewRequirementId: z.string().optional(),
});

export type CreateInterviewInput = z.infer<typeof CreateInterviewInputSchema>;

// Delete types
export const DeleteInterviewInputSchema = z.object({
  scheduledInterviewId: z.string(),
  actorType: ActorTypeSchema,
  reportingReason: CancellationReasonSchema.nullable(),
});

export type DeleteInterviewInput = z.infer<typeof DeleteInterviewInputSchema>;

// Swap types
export const SwapInterviewersInputSchema = z.object({
  scheduledInterviewId: z.string(),
  updatedInterviewerSlots: z.array(InterviewerSlotInputSchema),
  unavailableInterviewerUserMembershipIds: z.array(z.string()),
  newZoomHostUserMembershipId: z.string().optional(),
  schedulingRequestId: z.string().nullable(),
});

export type SwapInterviewersInput = z.infer<typeof SwapInterviewersInputSchema>;

// Create Props Types
export const ScheduleNewInterviewPropsSchema = z.object({
  actorId: z.string(),
  remoteActorId: z.string().optional(),
  input: CreateInterviewInputSchema,
  groupConferencingSlot: GroupConferencingSlotInputSchema.optional(),
  relations: z.object({
    organizationId: z.string(),
    scheduledInterviewGroupId: z.string().optional(),
    interviewId: z.string().optional(),
    guideId: z.string().optional(),
    schedulingRequestId: z.string().optional(),
    selfScheduleSubmissionId: z.string().optional(),
    bookingLinkSubmissionId: z.string().optional(),
  }),
  notifications: z.object({
    interviewerConfirmation: InterviewerConfirmationCreateInputSchema,
  }),
  meta: z.object({
    source: z.string().optional(),
    requestId: z.string().optional(),
  }),
});

export type ScheduleNewInterviewProps = z.infer<
  typeof ScheduleNewInterviewPropsSchema
>;

// Update Props Types
export const UpdateExistingInterviewPropsSchema = z.object({
  actorId: z.string(),
  remoteActorId: z.string().optional(),
  input: UpdateInterviewInputSchema,
  groupConferencingSlot: GroupConferencingSlotInputSchema.optional(),
  relations: z
    .object({
      schedulingRequestId: z.string().nullable().optional(),
    })
    .optional(),
  notifications: z.object({
    interviewerConfirmation: InterviewerConfirmationUpdateInputSchema,
  }),
  meta: z.object({
    requestId: z.string().optional(),
  }),
});

export type UpdateExistingInterviewProps = z.infer<
  typeof UpdateExistingInterviewPropsSchema
>;

// Cancel Props Types
export const CancelInterviewPropsSchema = z.object({
  actorId: z.string(),
  remoteActorId: z.string().optional(),
  input: DeleteInterviewInputSchema,
  relations: z
    .object({
      schedulingRequestId: z.string().optional(),
    })
    .optional(),
  notifications: z.object({
    interviewerConfirmation: InterviewerConfirmationCancellationInputSchema,
    candidateConfirmation:
      CandidateConfirmationCancellationInputSchema.optional(),
  }),
  meta: z.object({
    requestId: z.string().optional(),
  }),
});

export type CancelInterviewProps = z.infer<typeof CancelInterviewPropsSchema>;

// Group Props Types
export const UpsertScheduledInterviewGroupPropsSchema = z.object({
  id: z.string().optional(),
  actorId: z.string(),
  schedulingRequestId: z.string().optional(),
  guideId: z.string(),
  scheduledInterviewGroupSettings: z
    .object({
      reuseVideoConferencingLink: z.boolean().optional(),
      videoConferencingGenerationSettings:
        ConferencingGenerationSettingsSchema.optional(),
    })
    .optional(),
  createScheduledInterviews: z.array(ScheduleNewInterviewPropsSchema),
  updateScheduledInterviews: z.array(UpdateExistingInterviewPropsSchema),
  deleteScheduledInterviews: z.array(CancelInterviewPropsSchema),
});

export type UpsertScheduledInterviewGroupProps = z.infer<
  typeof UpsertScheduledInterviewGroupPropsSchema
>;
