import React, { ComponentPropsWithoutRef, ReactElement } from "react";

import { Footer } from "./Footer";
import { Table } from "./Table";

type EmailBodyProps = {
  page: {
    title?: string;
    language?: string;
  };
  preheader?: string | null;
  header?: ReactElement;
  footer?: ReactElement;
} & ComponentPropsWithoutRef<"body">;

export function EmailBody({
  children,
  preheader,
  header,
  footer,
  page,
  ...props
}: EmailBodyProps) {
  return (
    <body
      {...props}
      className="body m-0 p-0 w-full bg-white [word-break:break-word] [-webkit-font-smoothing:antialiased]"
    >
      {preheader && <div className="hidden">{preheader}</div>}
      <div
        role="article"
        aria-roledescription="email"
        aria-label={page.title || ""}
        lang={page.language || "en"}
      >
        <Table className="w-full font-sans text-dark-gray-500 gmail__w-full">
          <tr>
            <td align="center" className="bg-white">
              <Table
                className="w-150 sm:w-full"
                style={{
                  border: "1px solid #EEEDEF",
                  borderRadius: "6px",
                }}
              >
                <tr>
                  <td align="center">
                    <Table className="w-full text-base text-dark-gray-500 text-left leading-normal">
                      {header ?? (
                        <tr>
                          <td className="sm:h-0 h-12 text-center" />
                        </tr>
                      )}
                      {children}
                      {footer ?? <Footer />}
                    </Table>
                  </td>
                </tr>
              </Table>
            </td>
          </tr>
        </Table>
      </div>
    </body>
  );
}
