import { Button as AriaButton } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPersonPlus } from "@resource/atlas/icons";
import { CypressData } from "client/cypress-data-keys";
// eslint-disable-next-line import/no-restricted-paths
import { useInterviewerSlotSettingsOpen } from "client/scheduler/core/hooks/interviewer-slots";
import clsx from "clsx";
import { useEffect, useMemo } from "react";

import { EditInterviewerSlotDisplayState } from "../hooks/useEditInterviewerSlotDisplayState";
import { EditSelectedInterviewerRightActions } from "./EditSelectedInterviewerRightActions";
import { SelectedInterviewerDisplay } from "./SelectedInterviewerDisplay";

export type EditPersonConfigSelectedInterviewerDisplayProps = {
  state: EditInterviewerSlotDisplayState;
  isShadower?: boolean;
  paddingTopClassName?: string;
};

/**
 * Display a selected interviewer for a person config in edit mode.
 * If no interviewer is passed, it will display an empty state and allow selection.
 * Used for both interviewers and shadowers.
 * Handles selection of alternates.
 */
export function EditPersonConfigSelectedInterviewerDisplay({
  state,
  isShadower,
  paddingTopClassName: paddingTop = "pt-3",
}: EditPersonConfigSelectedInterviewerDisplayProps) {
  const {
    interviewerSlot,
    calendarColors,
    getInterviewerState,
    size,
    hideInterviewerDayLoad,
  } = state;

  const userMembershipsSetting = useMemo(() => {
    if (isShadower) {
      return interviewerSlot.shadowingUserMembershipsSetting;
    }

    return interviewerSlot.userMembershipsSetting;
  }, [interviewerSlot, isShadower]);
  const hasAlternates = useMemo(
    () => userMembershipsSetting.length > 1,
    [userMembershipsSetting]
  );
  const { interviewer, onSelectUser } = getInterviewerState({ isShadower });

  const defaultOpen = useMemo(() => {
    if (interviewer) {
      // Default closed if interviewer already selected
      return false;
    }

    // Otherwise, default open if there are multiple options
    return hasAlternates;
  }, [hasAlternates, interviewer]);

  // We shouldn't be using a scheduler-specific hook inside this component, but we're planning to kill this alternates workflow.
  const { isOpen, setIsOpen, toggleOpen } = useInterviewerSlotSettingsOpen(
    interviewerSlot.id,
    !!isShadower
  );

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [setIsOpen, defaultOpen]);

  const righActionsAlternatesOptions = useMemo(
    () => ({
      hasAlternates,
      isOpen,
      toggleOpen,
    }),
    [hasAlternates, isOpen, toggleOpen]
  );

  return (
    <div className={clsx("px-3 pb-3", paddingTop)}>
      <div className="flex items-center gap-2">
        {interviewer?.userMembership ? (
          <SelectedInterviewerDisplay
            interviewer={interviewer.userMembership}
            isShadower={isShadower}
            responseStatus={interviewer.responseStatus}
            calendarColor={
              calendarColors
                ? calendarColors[interviewer.userMembership.id]
                : undefined
            }
            size={size}
            showDayCount={!hideInterviewerDayLoad}
            RightActions={
              <EditSelectedInterviewerRightActions
                allowSwapping={!hasAlternates}
                isShadower={isShadower}
                state={state}
                alternatesOptions={righActionsAlternatesOptions}
              />
            }
          />
        ) : (
          <UnselectedInterviewer
            isShadower={isShadower}
            hasAlternates={hasAlternates}
            state={state}
            RightActions={
              <EditSelectedInterviewerRightActions
                state={state}
                alternatesOptions={righActionsAlternatesOptions}
                isShadower={isShadower}
                hideMenu={isShadower}
              />
            }
          />
        )}
      </div>
      {isOpen && hasAlternates && (
        <div className="space-y-2 mt-2">
          <span
            className={clsx(
              size === "small" ? "text-body-sm-heavy" : "text-body-md-heavy"
            )}
          >
            Select an interviewer
          </span>
          {userMembershipsSetting.map((userMembershipOption) => (
            <div
              key={userMembershipOption.id}
              className="flex gap-2 items-center"
            >
              <SelectedInterviewerDisplay
                interviewer={userMembershipOption}
                size={size}
                calendarColor={
                  calendarColors
                    ? calendarColors[userMembershipOption.id]
                    : undefined
                }
                showDayCount={!hideInterviewerDayLoad}
              />
              {userMembershipOption.id !== interviewer?.userMembership.id && (
                <AriaButton
                  className="text-link-default hover:underline flex flex-shrink-0 text-body-sm"
                  onClick={() => {
                    onSelectUser(userMembershipOption);
                    setIsOpen(false);
                  }}
                  data-cy={CypressData.editInterviewerSlotDisplay.selectInterviewerOptionButton(
                    userMembershipOption.id
                  )}
                >
                  Select
                </AriaButton>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function UnselectedInterviewer({
  RightActions,
  hasAlternates,
  isShadower,
  state: {
    allMembershipIdsFromInterviewSlots,
    UserMembershipSelect,
    size,
    getInterviewerState,
    interviewerSlot: { tagFilters },
  },
}: {
  state: EditInterviewerSlotDisplayState;
  isShadower?: boolean;
  hasAlternates: boolean;
  RightActions?: React.ReactNode;
}) {
  const { onSelectUser } = getInterviewerState({ isShadower });

  const wrapperClassName = clsx("flex space-x-2 items-center w-full");

  const icon = useMemo(() => {
    return (
      <div
        className={clsx(
          "border-[1.5px] rounded-full border-dashed p-[2px] border-purple-500",
          {
            "p-[2px]": size === "small",
            "p-4": size === "medium",
          }
        )}
      >
        <Icon content={atlasPersonPlus} className="w-4 h-4" />
      </div>
    );
  }, [size]);

  if (hasAlternates) {
    // If there are potential alternates, then just show a placeholder rather than a select
    return (
      <div className={clsx(wrapperClassName, "text-subtle")}>
        {icon}
        <div className="flex items-center flex-grow">
          <span className="text-body-md">
            {isShadower ? "Add trainee" : "Select interviewer"}
          </span>
        </div>
        {RightActions}
      </div>
    );
  }

  return (
    <div className="flex w-full">
      <UserMembershipSelect
        searchPlaceholderText="Search by name or email"
        onSelect={onSelectUser}
        excludeUserMembershipIds={allMembershipIdsFromInterviewSlots}
        tagFilters={tagFilters}
        Trigger={
          <AriaButton className={clsx(wrapperClassName, "text-purple-500")}>
            {icon}
            <div className="flex items-center flex-grow">
              <span className="text-body-md">
                {isShadower ? "Add trainee" : "Select interviewer"}
              </span>
            </div>
          </AriaButton>
        }
      />
      {RightActions}
    </div>
  );
}
