import { zodResolver } from "@hookform/resolvers/zod";
// eslint-disable-next-line import/no-restricted-paths
import { ColorConfig } from "client/components/calendar-v2/utils/colors";
import { useTimezone } from "client/timezones/useTimezone";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import {
  UpdatePreferencesMutation,
  UpdateProfileMutation,
} from "generated/graphql-codegen/graphql";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { usePrevious } from "react-use";

import { InterviewerMultiSelectItem } from "../../ReportingReasons/__components/InterviewerMultiSelect";
import { mapInterviewerGroupsToInterviewerMultiSelect } from "../../ReportingReasons/utils/mapping";
import { ScheduledInterviewGroupSettings } from "../../ScheduledInterviewGroupSettingsForm/utils/types";
import {
  StagedScheduledInterviewFormSchema,
  UpsertScheduledInterview,
  UpsertScheduledInterviewFormData,
  UpsertScheduledInterviewSchema,
} from "../utils/types";

export type UpsertScheduledInterviewOnChange = (props: {
  updatedInterview: UpsertScheduledInterview;
}) => void;

export type UserMembershipForChanges =
  | UpdatePreferencesMutation["updateUserMembershipSchedulingPreferences"]["userMembership"]
  | UpdateProfileMutation["updateUserMembershipDisplayInformation"]["userMembership"];

type UseUpsertScheduledInterviewFormStateProps = {
  scheduledInterview: UpsertScheduledInterview;
  /** Original scheduled interview for diffs, if different from default */
  originalInterview?: UpsertScheduledInterview | null;
  timezone?: string;
  onChange?: (props: { updatedInterview: UpsertScheduledInterview }) => void;
  onUserMembershipChange?: (props: {
    updatedUserMembership: UserMembershipForChanges;
  }) => void;
  calendarColors?: {
    [userMembershipId: string]: ColorConfig;
  };
  size?: "xs" | "small" | "medium";
  scheduledInterviewGroupSettings?: ScheduledInterviewGroupSettings;
  disabledFormFeaturesOverrides?: Partial<DisabledFormFeatures>;
  hasGroupSettingsBeenInitialized?: boolean;
};

export function useUpsertScheduledInterviewFormState({
  scheduledInterview: defaultScheduledInterview,
  originalInterview: passedOriginalInterview,
  timezone: passedTimezone,
  onChange: passedOnChange,
  onUserMembershipChange: passedOnUserMembershipChange,
  calendarColors,
  size,
  scheduledInterviewGroupSettings,
  disabledFormFeaturesOverrides,
  hasGroupSettingsBeenInitialized,
}: UseUpsertScheduledInterviewFormStateProps) {
  const timezone = useTimezone(passedTimezone);
  const form = useForm<UpsertScheduledInterviewFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      scheduledInterview: defaultScheduledInterview,
    },
    resolver: zodResolver(StagedScheduledInterviewFormSchema),
  });
  const { formState } = form;

  useEffect(() => {
    // Deconstruct these values so that they are populated -- because of Proxy must do this for it work
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { errors, dirtyFields } = formState;
  }, [formState]);

  useSyncFormStateWithDefaultInterviewChanges({
    form,
    defaultScheduledInterview,
  });

  useEffect(() => {
    const subscription = form.watch((newValue, { name }) => {
      const parsedValue = newValue.scheduledInterview
        ? UpsertScheduledInterviewSchema.safeParse(newValue.scheduledInterview)
        : null;

      if (name?.startsWith("scheduledInterview") && parsedValue?.success) {
        const updatedInterview = parsedValue.data;

        if (passedOnChange) {
          passedOnChange({
            updatedInterview,
          });
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [form, passedOnChange]);

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState
  );

  const originalInterview = useMemo(() => {
    // Return original interview for diffs. Can pass null to say there was no original
    if (passedOriginalInterview === undefined) {
      return defaultScheduledInterview;
    }

    return passedOriginalInterview;
  }, [defaultScheduledInterview, passedOriginalInterview]);
  const originalInterviewers = useMemo((): InterviewerMultiSelectItem[] => {
    return mapInterviewerGroupsToInterviewerMultiSelect(
      defaultScheduledInterview.interviewerSlots
    );
  }, [defaultScheduledInterview.interviewerSlots]);

  const {
    hideInterviewerPoolSelection,
    hideInterviewerTraining,
    hideExternalInterviewerAlgorithm,
    hideConferenceRoomSelection,
    hidePrivacyOptions,
    hidePhoneSelection,
    hideCollaborativeCodingSelection,
  } = useDisabledFormFeatures({
    interview: defaultScheduledInterview,
    overrides: disabledFormFeaturesOverrides,
  });

  /** Wrapper around onChange to properly handle dirtying and validation */
  const onChange = useCallback(
    (...params: Parameters<typeof form.setValue>) => {
      const [name, value, opts] = params;

      form.setValue(name, value, {
        shouldDirty: true,
        ...opts,
      });
      // Need to manually trigger validation because of a bug with zodResolver and superRefine
      // https://github.com/react-hook-form/resolvers/issues/661
      form.trigger();
    },
    [form]
  );

  const syncFormWithUserMembershipChanges =
    useSyncFormWithUserMembershipChanges({
      form,
      onUserMembershipChange: passedOnUserMembershipChange,
    });

  return useMemo(
    () => ({
      form,
      timezone,
      calendarColors,
      originalInterview,
      originalInterviewers,
      size,
      disabledTooltipContent,
      hideInterviewerPoolSelection,
      hideInterviewerTraining,
      hideExternalInterviewerAlgorithm,
      hideConferenceRoomSelection,
      hidePrivacyOptions,
      hidePhoneSelection,
      hideCollaborativeCodingSelection,
      scheduledInterviewGroupSettings,
      onChange,
      syncFormWithUserMembershipChanges,
      hasGroupSettingsBeenInitialized,
    }),
    [
      form,
      timezone,
      calendarColors,
      originalInterview,
      originalInterviewers,
      size,
      disabledTooltipContent,
      hideInterviewerPoolSelection,
      hideInterviewerTraining,
      hideExternalInterviewerAlgorithm,
      hideConferenceRoomSelection,
      hidePrivacyOptions,
      hidePhoneSelection,
      hideCollaborativeCodingSelection,
      scheduledInterviewGroupSettings,
      onChange,
      syncFormWithUserMembershipChanges,
      hasGroupSettingsBeenInitialized,
    ]
  );
}

export type UpsertScheduledInterviewFormState = ReturnType<
  typeof useUpsertScheduledInterviewFormState
>;

type DisabledFormFeatures = {
  hideInterviewerPoolSelection: boolean;
  hideInterviewerTraining: boolean;
  hideExternalInterviewerAlgorithm: boolean;
  hideConferenceRoomSelection: boolean;
  hidePrivacyOptions: boolean;
  hideCollaborativeCodingSelection: boolean;
  hidePhoneSelection: boolean;
};

function useDisabledFormFeatures({
  interview,
  overrides,
}: {
  interview: UpsertScheduledInterview;
  overrides?: Partial<DisabledFormFeatures>;
}): DisabledFormFeatures {
  return useMemo(
    () => ({
      hideInterviewerPoolSelection: !!interview.isSelfScheduled,
      hideInterviewerTraining: !!interview.isSelfScheduled,
      hideConferenceRoomSelection: !!interview.isSelfScheduled,
      hidePrivacyOptions: !!interview.isSelfScheduled,
      hideCollaborativeCodingSelection: !!interview.isSelfScheduled,
      // Only allow external interviewer algorithm when explicitly overridden
      hideExternalInterviewerAlgorithm: true,
      // only allow phone for self scheduled interviews
      hidePhoneSelection: !interview.isSelfScheduled,
      ...overrides,
    }),
    [interview, overrides]
  );
}

function useSyncFormStateWithDefaultInterviewChanges({
  form,
  defaultScheduledInterview,
}: {
  form: UseFormReturn<UpsertScheduledInterviewFormData>;
  defaultScheduledInterview: UpsertScheduledInterview;
}) {
  const previousId = usePrevious(defaultScheduledInterview.id);

  useEffect(() => {
    // reset form if ID changes
    if (previousId !== defaultScheduledInterview.id) {
      form.reset({
        scheduledInterview: defaultScheduledInterview,
      });
    }
  }, [
    defaultScheduledInterview,
    defaultScheduledInterview.id,
    form,
    previousId,
  ]);

  const prevStartTime = usePrevious(defaultScheduledInterview.startTime);
  const prevDuration = usePrevious(defaultScheduledInterview.duration);
  const prevConferencingSettings = usePrevious(
    defaultScheduledInterview.conferencingSlot
  );
  const prevInterviewerSlots = usePrevious(
    defaultScheduledInterview.interviewerSlots
  );

  useEffect(() => {
    if (prevStartTime !== defaultScheduledInterview.startTime) {
      form.setValue(
        "scheduledInterview.startTime",
        defaultScheduledInterview.startTime
      );

      const duration = form.getValues("scheduledInterview.duration");
      if (defaultScheduledInterview.startTime && duration) {
        const endTime = DateTime.fromISO(
          defaultScheduledInterview.startTime
        ).plus({
          minutes: duration,
        });
        form.setValue("scheduledInterview.endTime", endTime.toISO());
      }
    }
  }, [defaultScheduledInterview.startTime, form, prevStartTime]);

  useEffect(() => {
    if (prevDuration !== defaultScheduledInterview.duration) {
      form.setValue(
        "scheduledInterview.duration",
        defaultScheduledInterview.duration
      );

      const startTime = form.getValues("scheduledInterview.startTime");
      if (startTime && defaultScheduledInterview.duration) {
        const endTime = DateTime.fromISO(startTime).plus({
          minutes: defaultScheduledInterview.duration,
        });
        form.setValue("scheduledInterview.endTime", endTime.toISO());
      }
    }
  }, [defaultScheduledInterview.duration, form, prevDuration]);

  const prevIsCancelled = usePrevious(defaultScheduledInterview.isCancelled);

  useEffect(() => {
    if (prevIsCancelled !== defaultScheduledInterview.isCancelled) {
      form.setValue(
        "scheduledInterview.isCancelled",
        defaultScheduledInterview.isCancelled
      );
    }
  }, [defaultScheduledInterview.isCancelled, form, prevIsCancelled]);

  useEffect(() => {
    if (
      !isEqual(
        prevConferencingSettings,
        defaultScheduledInterview.conferencingSlot
      )
    ) {
      form.setValue(
        "scheduledInterview.conferencingSlot",
        defaultScheduledInterview.conferencingSlot
      );
    }
  }, [
    defaultScheduledInterview.conferencingSlot,
    form,
    prevConferencingSettings,
  ]);

  useEffect(() => {
    if (
      !isEqual(prevInterviewerSlots, defaultScheduledInterview.interviewerSlots)
    ) {
      form.setValue(
        "scheduledInterview.interviewerSlots",
        defaultScheduledInterview.interviewerSlots
      );
    }
  }, [defaultScheduledInterview.interviewerSlots, form, prevInterviewerSlots]);
}

/** Passed as callback for profile and scheduling preferences updates */
function useSyncFormWithUserMembershipChanges({
  form,
  onUserMembershipChange,
}: {
  form: UseFormReturn<UpsertScheduledInterviewFormData>;
  onUserMembershipChange?: (props: {
    updatedUserMembership: UserMembershipForChanges;
  }) => void;
}) {
  return useCallback(
    (updatedUserMembership: UserMembershipForChanges) => {
      if (
        !updatedUserMembership ||
        !("id" in updatedUserMembership) ||
        !updatedUserMembership.id
      )
        return;

      const currentInterviewerSlots = form.getValues(
        "scheduledInterview.interviewerSlots"
      );

      const updatedInterviewerSlots = currentInterviewerSlots.map((slot) => {
        const updatedSlot = { ...slot };

        if (slot.interviewer?.userMembership.id === updatedUserMembership.id) {
          updatedSlot.interviewer = {
            ...slot.interviewer,
            userMembership: {
              ...slot.interviewer.userMembership,
              ...updatedUserMembership,
              user: {
                ...slot.interviewer.userMembership.user,
                ...updatedUserMembership.user,
              },
            },
          };
        }

        if (
          slot.shadowingInterviewer?.userMembership.id ===
          updatedUserMembership.id
        ) {
          updatedSlot.shadowingInterviewer = {
            ...slot.shadowingInterviewer,
            userMembership: {
              ...slot.shadowingInterviewer.userMembership,
              ...updatedUserMembership,
              user: {
                ...slot.shadowingInterviewer.userMembership.user,
                ...updatedUserMembership.user,
              },
            },
          };
        }

        if (slot.userMembershipsSetting) {
          updatedSlot.userMembershipsSetting = slot.userMembershipsSetting.map(
            (um) =>
              um.id === updatedUserMembership.id
                ? {
                    ...um,
                    ...updatedUserMembership,
                    user: {
                      ...um.user,
                      ...updatedUserMembership.user,
                    },
                  }
                : um
          );
        }

        if (slot.shadowingUserMembershipsSetting) {
          updatedSlot.shadowingUserMembershipsSetting =
            slot.shadowingUserMembershipsSetting.map((um) =>
              um.id === updatedUserMembership.id
                ? {
                    ...um,
                    ...updatedUserMembership,
                    user: {
                      ...um.user,
                      ...updatedUserMembership.user,
                    },
                  }
                : um
            );
        }

        return updatedSlot;
      });

      onUserMembershipChange?.({
        updatedUserMembership,
      });

      form.setValue(
        "scheduledInterview.interviewerSlots",
        updatedInterviewerSlots
      );
    },
    [form, onUserMembershipChange]
  );
}
