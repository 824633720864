import { useLogEvent } from "analytics";
import { Events } from "analytics/types";
import { GlobalNavigatorRef } from "client/components/generic/misc/GlobalNavigator";
import config from "config";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import semver from "semver";
import inIframe from "utils/in-iframe";
import { useNavigateToFrameHref } from "utils/navigation";
import { useQueryStringValue } from "utils/next";
import useWindowMessageListener, {
  ExtensionFrameVariant,
  Message as WindowMessage,
  OutboundMessage as OutboundWindowMessage,
} from "utils/useWindowMessageListener";

export type ExtensionNavigationState = {
  view: "notifications" | "tasks" | "candidateProfile" | null;
  props?: Record<string, unknown>;
};

interface ExtensionControllerContextValue {
  panelOpenState: boolean;
  setPanelOpenState: (value: boolean) => void;
  sendMessageToParent: (message: OutboundWindowMessage) => void;
  updateAvailable?: string;
  extensionVersion?: string;
  parentHref: string | null;
  isModalFrame: boolean;
  isSidepanelFrame: boolean;
  hasExtensionBeenOpen: boolean;
  guideIdForCurrentlyViewingATSApplication: string | null;
  setGuideIdForCurrentlyViewingATSApplication: (value: string | null) => void;
  popoverWidthVariant: "default" | "wide" | "default-with-chat";
  frameVariant?: ExtensionFrameVariant;
  setPopoverWidthVariant: (
    value: "default" | "wide" | "default-with-chat"
  ) => void;
  isFakeExtensionFrame: boolean;
  setIsFakeExtensionFrame: (value: boolean) => void;
  navigatorRefs: {
    candidateProfile: React.RefObject<GlobalNavigatorRef>;
    tasks: React.RefObject<GlobalNavigatorRef>;
    notifications: React.RefObject<GlobalNavigatorRef>;
  };
  navigationState: ExtensionNavigationState;
  navigate: (state: ExtensionNavigationState) => void;
}

export const PopoverWidthVariants: Record<
  "default" | "wide" | "default-with-chat",
  460 | 576 | 920
> = {
  default: 460,
  "default-with-chat": 920,
  wide: 576,
};

// Needs to be above the user auth context so that user auth context gets access to panel open state
const ExtensionControllerContext = React.createContext<
  ExtensionControllerContextValue | undefined
>(undefined);

export const useExtensionController = () => {
  const context = useContext(ExtensionControllerContext);
  if (context === undefined) {
    throw new Error("ExtensionControllerContext must be initialized first.");
  }
  return context;
};

export const useOptionalExtensionController = () => {
  const context = useContext(ExtensionControllerContext);
  return context;
};

export const useExtensionIsClosed = () => {
  const context = useContext(ExtensionControllerContext);
  return (
    inIframe() && context && context.isSidepanelFrame && !context.panelOpenState
  );
};

export const useExtensionHasBeenOpened = () => {
  const context = useContext(ExtensionControllerContext);
  if (process.env.STORYBOOK || !inIframe() || context?.isModalFrame) {
    return true;
  }

  return !!context?.hasExtensionBeenOpen;
};

export const useInExtensionFrame = () => {
  const context = useContext(ExtensionControllerContext);
  return inIframe() && !!context?.extensionVersion;
};

export function ExtensionControllerProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const logEvent = useLogEvent({ component: "ExtensionControllerProvider" });
  const [popoverWidthVariant, setPopoverWidthVariant] = useState<
    "default" | "wide" | "default-with-chat"
  >("default");

  const isModalFrame = useRef(
    window.location.search.includes("sourceIframeId=guide-modals-v2") ||
      window.location.search.includes("sourceIframeId=guide-modals")
  );
  const isSidepanelFrame = useRef(
    window.location.search.includes("sourceIframeId=guide-sidepanel-v2") ||
      window.location.search.includes("sourceIframeId=guide-sidepanel")
  );
  const [currentParentHref, setCurrentParentHref] = useState<string | null>(
    null
  );

  const [
    guideIdForCurrentlyViewingATSApplication,
    setGuideIdForCurrentlyViewingATSApplication,
  ] = useState<string | null>(null);
  const [hasExtensionBeenOpen, setHasExtensionBeenOpen] = useState(false);
  const [panelIsOpenInExtension, setPanelIsOpenInExtension] = useState(false);
  const [isFakeExtensionFrame, setIsFakeExtensionFrame] = useState(false);
  const selectedTab = useQueryStringValue("guide_selected_tab");
  const applicationId = useQueryStringValue("applicationId");
  const candidateId = useQueryStringValue("candidateId");

  const [navigationState, setNavigationState] =
    useState<ExtensionNavigationState>(() => {
      // It the tab to use is specified in the url, use that
      if (selectedTab) {
        return {
          view: selectedTab === "candidate" ? "candidateProfile" : "tasks",
        };
      }

      // If the application and candidate ids are specified, we're on a candidate's profile
      // in Greenhouse and should default to the profile tab
      if (applicationId && candidateId) {
        return { view: "candidateProfile" };
      }

      // Otherwise, default to the tasks tab
      return {
        view: "tasks",
      };
    });

  useEffect(() => {
    if (panelIsOpenInExtension) {
      setHasExtensionBeenOpen(true);
    }
  }, [panelIsOpenInExtension]);

  const sendMessageToParent = useCallback((message: OutboundWindowMessage) => {
    window.parent.parent.postMessage(message, "*");
  }, []);

  const [version, setVersion] = useState("");

  const frameVariant = useMemo(() => {
    if (!version) {
      return "fullscreen";
    }

    return semver.gte(version, "3.1.13") ? "fullscreen" : "popover";
  }, [version]);

  const setPanelOpenState = useCallback(
    (newPanelOpenState: boolean) => {
      if (!inIframe()) {
        setPanelIsOpenInExtension(newPanelOpenState);
      } else {
        sendMessageToParent({
          command: "set-panel-open-state",
          value: newPanelOpenState,
        });
      }
    },
    [sendMessageToParent]
  );

  const candidateProfileNavigatorRef = useRef<GlobalNavigatorRef>(null);
  const schedulingTaskNavigatorRef = useRef<GlobalNavigatorRef>(null);
  const notificationsNavigatorRef = useRef<GlobalNavigatorRef>(null);

  const navigate = useCallback(
    (state: ExtensionNavigationState) => {
      setNavigationState(state);

      // Reset the view if navigating to the same view
      if (state.view === navigationState.view) {
        let ref = null;
        switch (state.view) {
          case "candidateProfile":
            ref = candidateProfileNavigatorRef.current;
            break;
          case "tasks":
            ref = schedulingTaskNavigatorRef.current;
            break;
          case "notifications":
            ref = notificationsNavigatorRef.current;
            break;
          default:
            break;
        }
        ref?.popToRoot();
      } else {
        setNavigationState(state);
      }
    },
    [navigationState.view]
  );

  const navigateToFrameHref = useNavigateToFrameHref({
    navigateInExtension: navigate,
  });

  useEffect(() => {
    // On mount, set the panel state
    const url = new URL(window.location.href);
    const guideOpenParam = url.searchParams.get("guide_open");
    if (guideOpenParam === "1") {
      setPanelOpenState(true);
    }
    // Also send legacy commands for old extension clients waiting
    // for permission to show buttons on the Greenhouse page
    sendMessageToParent("show-persistent-button");
    sendMessageToParent({
      command: "update-sidepanel-pointer-events",
      value: "none",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listener = (msg: WindowMessage) => {
    console.debug(`[Msg Received From Extension]`, msg);
    if (typeof msg.data === "object" && "command" in msg.data) {
      switch (msg.data.command) {
        case "version":
          setVersion(msg.data.value);
          break;
        case "panel-open-state":
          setPanelIsOpenInExtension(msg.data.value);
          break;
        case "parent-href":
          setCurrentParentHref(msg.data.value);
          break;
        case "navigate-to-frame-href":
          setPanelOpenState(true);
          navigateToFrameHref({ href: msg.data.value });
          break;
        case "track-event":
          logEvent(
            msg.data.value.eventName as Events,
            msg.data.value.eventProperties
          );
          break;
        default:
      }
    }
  };

  useWindowMessageListener(
    listener,
    config.EXTENSION_ID_ARRAY.map((id) => `chrome-extension://${id}`)
  );

  const internalToolingListener = (msg: WindowMessage) => {
    console.debug(`[Msg Received From Extension]`, msg);
    if (typeof msg.data === "object" && "command" in msg.data) {
      switch (msg.data.command) {
        case "co2-panel-open-state":
          setPanelIsOpenInExtension(msg.data.value);
          break;
        default:
      }
    }
  };

  useWindowMessageListener(internalToolingListener, "*");

  useEffect(() => {
    sendMessageToParent({ command: "request-version" });
    sendMessageToParent({ command: "request-panel-open-state" });
    sendMessageToParent({ type: "request-parent-href" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendMessageToParent({
      command: "set-panel-width",
      value: PopoverWidthVariants[popoverWidthVariant],
    });
  }, [sendMessageToParent, popoverWidthVariant]);

  useEffect(() => {
    sendMessageToParent({ command: "show-message-button" });
  }, [sendMessageToParent]);

  useEffect(() => {
    if (typeof window.FS !== "undefined" && inIframe() && isSidepanelFrame) {
      if (panelIsOpenInExtension) {
        console.debug("Starting fullstory...", {
          location: "ExtensionControllerProvider.tsx",
        });
        window.FS.restart();
      } else {
        console.debug("Shutting down fullstory...", {
          location: "ExtensionControllerProvider.tsx",
        });
        window.FS.shutdown();
      }
    }
  }, [panelIsOpenInExtension]);

  useEffect(() => {
    sendMessageToParent({ type: "request-parent-href" });
  }, [sendMessageToParent]);

  return (
    <ExtensionControllerContext.Provider
      value={{
        panelOpenState: panelIsOpenInExtension,
        setPanelOpenState,
        sendMessageToParent,
        extensionVersion: version,
        parentHref: currentParentHref,
        isModalFrame: isModalFrame.current,
        isSidepanelFrame: isSidepanelFrame.current,
        guideIdForCurrentlyViewingATSApplication,
        setGuideIdForCurrentlyViewingATSApplication,
        hasExtensionBeenOpen,
        frameVariant,
        isFakeExtensionFrame,
        setIsFakeExtensionFrame,
        popoverWidthVariant,
        setPopoverWidthVariant,
        navigatorRefs: {
          candidateProfile: candidateProfileNavigatorRef,
          tasks: schedulingTaskNavigatorRef,
          notifications: notificationsNavigatorRef,
        },
        navigationState,
        navigate,
      }}
    >
      {children}
    </ExtensionControllerContext.Provider>
  );
}
