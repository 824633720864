import { strings } from "@resource/common";
import { ExcludedInterviewCard } from "client/components/interview-requirements/ExcludedInterviewCard";
import clsx from "clsx";
import { InterviewRequirementForConfigurationFragment } from "generated/graphql-codegen/graphql";
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";

type ExcludedInterviewRequirementsListrops = {
  jobInterviewStage: {
    name: string;
  };
  interviewRequirements: InterviewRequirementForConfigurationFragment[];
  setInterviewRequirements: Dispatch<
    SetStateAction<InterviewRequirementForConfigurationFragment[]>
  >;
  onClick?: (
    interviewRequirement: InterviewRequirementForConfigurationFragment
  ) => unknown;
};

export function JobSettingsExcludedInterviewRequirementsList({
  jobInterviewStage,
  interviewRequirements,
  setInterviewRequirements,
  className,
  ...props
}: ExcludedInterviewRequirementsListrops & ComponentPropsWithoutRef<"div">) {
  const addInterviewRequirement = useCallback(
    (interviewRequirement: InterviewRequirementForConfigurationFragment) => {
      setInterviewRequirements((prevInterviewRequirements) =>
        prevInterviewRequirements.map((i) =>
          i.id === interviewRequirement.id
            ? {
                ...i,
                manuallyRemovedAt: null,
                order:
                  i.order ??
                  prevInterviewRequirements.filter((v) => !v.manuallyRemovedAt)
                    .length + 1,
              }
            : i
        )
      );
    },
    [setInterviewRequirements]
  );

  if (interviewRequirements.length === 0) {
    return null;
  }

  return (
    <div
      {...props}
      className={clsx("text-body-md space-y-2 text-left", className)}
    >
      <p className="truncate flex gap-1 items-center">
        <span className="shrink-0">
          {strings.pluralize("interview", interviewRequirements.length)} from{" "}
        </span>
        <span className="text-body-md-heavy truncate">
          {jobInterviewStage.name}
        </span>{" "}
        <span className="shrink-0">stage excluded</span>
      </p>
      {interviewRequirements.map((interviewRequirement) => (
        <ExcludedInterviewCard
          data-id={interviewRequirement.id}
          key={interviewRequirement.id}
          excludedInterview={interviewRequirement.interview}
          onClick={() => addInterviewRequirement(interviewRequirement)}
        />
      ))}
    </div>
  );
}
