/* eslint-disable import/prefer-default-export */
import "./node-renderer.sass";

import { createComponentUtils } from "../../../__utils/atlas";
import { createNodeRenderer } from "../../renderer";
import { Image } from "./Image";
import type { SerializedImageNode } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Image_node-renderer";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// node renderer
// -------------

export const renderImageNode = createNodeRenderer<SerializedImageNode>(
  ({ data: { imageUrl, caption } }) => (
    <div className={ROOT}>
      <a href={imageUrl} rel="noreferrer" target="_blank">
        <Image imageUrl={imageUrl} />
        {caption && <p className="caption">{caption}</p>}
      </a>
    </div>
  )
);
