import { Icon } from "@resource/atlas/icon/Icon";
import { atlasClock } from "@resource/atlas/icons";
import RequestInfoRow from "components/RequestInfoRow/RequestInfoRow";
import { CandidateTimezoneSelectCandidateFragment } from "generated/graphql-codegen/graphql";

import { CandidateTimezoneSelect } from "../../../generic/select/CandidateTimezoneSelect";

export function TimezoneRequestInfoRow({
  candidate,
}: {
  candidate: CandidateTimezoneSelectCandidateFragment;
}) {
  return (
    <>
      <RequestInfoRow icon={<Icon content={atlasClock} />} label="Timezone">
        <CandidateTimezoneSelect
          candidate={candidate}
          triggerProps={{
            size: "xs",
            isGhost: true,
            className: "-ml-2.5 text-body-md-sm",
          }}
        />
      </RequestInfoRow>
    </>
  );
}
