import { atlasCircleSwitch } from "@resource/atlas/icons";
import { NotificationType } from "modules/notifications/types";
import { formatEntity } from "shared/constants/entities";

import { createAppNotificationRenderer } from "./types";

export const schedulingRequestAssignedRenderer = createAppNotificationRenderer({
  type: NotificationType.SchedulingRequestAssigned,
  render: ({ actor, candidate }) => {
    return (
      <>
        {actor.name} assigned a {formatEntity("scheduling request")} for{" "}
        <span className="text-body-md-heavy">{candidate.name}</span> to you
      </>
    );
  },
  avatar: ({ actor }) => {
    return {
      name: actor.name,
      avatarUrl: actor.avatarUrl,
      icon: atlasCircleSwitch,
      iconClass: "text-subtle bg-white",
    };
  },
});
