import "./module.sass";

import { useEffect, useMemo, useRef, useState } from "react";

import {
  atlasCaption,
  atlasLinkExternal,
  atlasRefreshCw,
  atlasVideo,
} from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { useEvent, useScheduleFocus } from "../../../__utils/react";
import { Icon } from "../../../icon/Icon";
import { useMenuItems } from "../../../menu/use-menu-items";
import { INSERT_TARGETS } from "../../__utils/insert-options-targets-order";
import { createRichBlock, RichBlockProps } from "../../__utils/rich-blocks";
import { createOriginalUrl, EmbeddedVideo } from "./embed";
import { DEFAULT_DATA, RICH_BLOCK_NAME, VideoData } from "./shared";
import { VideoPopover } from "./VideoPopover";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Video";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// render component
// ----------------

type VideoProps = RichBlockProps<VideoData>;

function VideoComponent({
  data,
  updateData,
  ConfigMenu,
  ConfigPopover,
}: VideoProps) {
  const [caption, setCaption] = useState(data.caption);
  useEffect(() => {
    setCaption(data.caption);
  }, [data.caption]);

  const [showCaption, setShowCaption] = useState(false);

  const captionRef = useRef<HTMLTextAreaElement>(null);
  const scheduleFocus = useScheduleFocus(captionRef);

  const onAddCaption = useEvent(() => {
    scheduleFocus();
    setShowCaption(true);
  });

  const isCaptionVisible = showCaption || Boolean(caption);

  const [replaceOpen, setReplaceOpen] = useState(false);

  const configItems = useMenuItems(
    (i) => [
      i.item({
        key: "view-original",
        children: "View original",
        leadingContent: <Icon content={atlasLinkExternal} />,
        renderWrapper: (props) => (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            href={createOriginalUrl(data.embedData)}
            target="_blank"
            rel="noreferrer"
            {...props}
          />
        ),
      }),
      i.item({
        key: "replace",
        children: "Replace video",
        onClick: () => setReplaceOpen(true),
        leadingContent: <Icon content={atlasRefreshCw} />,
      }),
      !isCaptionVisible && i.separator({ key: "caption-separator" }),
      !isCaptionVisible &&
        i.item({
          key: "add-caption",
          children: "Add caption",
          leadingContent: <Icon content={atlasCaption} />,
          onClick: onAddCaption,
        }),
    ],
    [data.embedData, isCaptionVisible, onAddCaption]
  );

  return (
    <>
      <ConfigMenu items={configItems} />
      <VideoPopover
        PopoverComponent={ConfigPopover}
        title="Replace video"
        open={replaceOpen}
        setOpen={setReplaceOpen}
        onSubmit={(embedData) => updateData({ embedData })}
        submitLabel="Update"
      />
      <div className={ROOT}>
        <EmbeddedVideo embedData={data.embedData} />
        {(showCaption || caption) && (
          <div className="sizer caption" data-content={caption}>
            <textarea
              ref={captionRef}
              rows={1}
              className="textarea"
              value={caption ?? ""}
              onChange={(event) =>
                setCaption(event.currentTarget.value || undefined)
              }
              onBlur={() => {
                if (!caption) setShowCaption(false);
                updateData({ caption });
              }}
              placeholder="Add caption"
            />
          </div>
        )}
      </div>
    </>
  );
}

// block
// -----

export const {
  $createVideoNode,
  $isVideoNode,
  VideoNode,
  VideoPlugin,
  videoModule,
  INSERT_VIDEO_COMMAND,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => VideoComponent,
  useInsertOption: ({ getAnchorRect }, { insert }) => {
    const [addOpen, setAddOpen] = useState(false);

    const onSubmit = useEvent((embedData) => insert({ embedData }));

    const addPopover = useMemo(
      () => (
        <VideoPopover
          getAnchorRect={getAnchorRect}
          title="Add a video"
          open={addOpen}
          setOpen={setAddOpen}
          onSubmit={onSubmit}
          submitLabel="Add a video"
        />
      ),
      [addOpen, getAnchorRect, onSubmit]
    );

    const toggleAddPopover = useEvent(() => setAddOpen((current) => !current));

    return useMemo(
      () => ({
        label: "Video",
        icon: atlasVideo,
        active: addOpen,
        targets: {
          insert: INSERT_TARGETS.VIDEO,
          fixedToolbar: { slot: "video" },
        },
        render: addPopover,
        onTrigger: toggleAddPopover,
      }),
      [addOpen, addPopover, toggleAddPopover]
    );
  },
});

// TODO:
// - allow urls to not start with https://
