import "./theme.sass";

import type { EditorThemeClasses } from "lexical";

import { createComponentUtils } from "../../__utils/atlas";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Theme";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// container
// ---------

/**
 * CSS class that needs to be applied to ContentEditor content. This applies
 * to the editor itself as well as rendered content.
 */
export const themeContainerClass = ROOT;

// theme
// -----

export const theme: EditorThemeClasses = {
  text: {
    bold: "bold-format",
    italic: "italic-format",
    underline: "underline-format",
  },
  paragraph: "paragraph",
  heading: { h1: "h1", h2: "h2", h3: "h3" },
  quote: "quote",
  link: "link",
  list: {
    ul: "list-unordered",
    ol: "list-ordered",
    listitem: "list-item",
    nested: {
      listitem: "list-nested-item",
    },
  },
  blockCursor: "block-cursor",
};

export const chatTheme: EditorThemeClasses = {
  root: "theme-chat",
};
