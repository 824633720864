import { View } from "@resource/atlas/view/View";
import {
  RadioItem,
  RadioItemProps,
} from "client/components/generic/inputs/RadioItem";
import { BackButton } from "client/components/generic/layout/BackButton";
import { CloseButton } from "client/components/generic/layout/CloseButton";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useCallback, useEffect } from "react";
import {
  InterviewerDecline,
  ScheduledInterviewInterviewerSwapReason,
  ScheduledInterviewOtherReason,
} from "shared/reporting/scheduled-interviews/types";

import { AdditionalTextInput } from "../__components/AdditionalTextInput";
import { InterviewerDeclinedSelect } from "../__components/InterviewerDeclinedSelect";
import { InterviewerMultiSelectItem } from "../__components/InterviewerMultiSelect";
import { OtherReasonCollectionAdditionalInfo } from "../__components/OtherReasonCollectAdditionalInfo";
import { ReportInterviewerSwapReasonState } from "../hooks/useReportInterviewerSwapReasonState";

export type ReportInterviewerChangeReasonViewProps = {
  interviewers: InterviewerMultiSelectItem[];
  state: ReportInterviewerSwapReasonState;
  onCancel: () => void;
  onBack?: () => void;
  /**
   * Confirmation button should get passed from the caller
   * Based on whether it is a standalone view or part of a multi-step view
   */
  confirmationButton: {
    label: string;
    variant: "default" | "primary";
    onClick: () => void;
    isLoading?: boolean;
  };
};

const reasonTextMap: Record<ScheduledInterviewInterviewerSwapReason, string> = {
  [ScheduledInterviewInterviewerSwapReason.INTERVIEWER_DECLINED]:
    "Interviewer declined",
  [ScheduledInterviewInterviewerSwapReason.OTHER]: "Other",
};

/**
 * View for choosing reason for swapping interviewer
 * Can be used as a standalone view or as part of a multi-step view
 */
export function ReportInterviewerSwapReasonView({
  interviewers,
  state,
  confirmationButton,
  onBack,
  onCancel,
}: ReportInterviewerChangeReasonViewProps) {
  const { form } = state;

  // Trigger form validation on mount
  useEffect(() => {
    state.form.trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reason = form.watch("reason");
  const additionalText = form.watch("additionalText");
  const getRadioItemProps = useCallback(
    ({
      type,
    }: {
      type: ScheduledInterviewInterviewerSwapReason;
    }): RadioItemProps => ({
      label: reasonTextMap[type],
      isSelected: reason === type,
      onClick: () => state.onChange("reason", type),
    }),
    [state, reason]
  );
  const onChangeAdditionalText = useCallback(
    (value: string) => {
      state.onChange("additionalText", value);
    },
    [state]
  );

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState,
    {
      skipDirtyCheck: true,
    }
  );

  return (
    <View
      content={{
        title: "Report interviewer change reason",
        subTitle:
          "To ensure accurate reporting, understanding why you changed interviewers is important. Please select the reason for changing the interviewers:",
      }}
      header={{
        leftActions: onBack ? (
          <BackButton onClick={onBack} />
        ) : (
          <CloseButton onClick={onCancel} />
        ),
      }}
      footer={{
        rightActions: (
          <FormRightActions
            disabledTooltipContent={disabledTooltipContent}
            cancel={{
              children: onBack ? "Back to review" : "Cancel",
              onClick: onBack ?? onCancel,
            }}
            save={{
              ...confirmationButton,
              children: confirmationButton.label,
            }}
          />
        ),
      }}
    >
      <FormGroup label="Interviewer change reason" isRequired>
        <div className="space-y-2">
          <RadioItem
            {...getRadioItemProps({
              type: ScheduledInterviewInterviewerSwapReason.INTERVIEWER_DECLINED,
            })}
            AdditionalInfoInput={
              <InterviewerDeclinedAdditional
                state={state}
                interviewers={interviewers}
              />
            }
          />
          <RadioItem
            {...getRadioItemProps({
              type: ScheduledInterviewInterviewerSwapReason.OTHER,
            })}
            AdditionalInfoInput={<OtherReasonAdditional state={state} />}
          />
        </div>
      </FormGroup>
      <AdditionalTextInput
        className="mt-4"
        text={additionalText}
        onChangeText={onChangeAdditionalText}
      />
    </View>
  );
}

function InterviewerDeclinedAdditional({
  state,
  interviewers,
}: {
  state: ReportInterviewerSwapReasonState;
  interviewers: InterviewerMultiSelectItem[];
}) {
  const interviewerDeclines = state.form.watch("interviewerDeclines");
  const onChange = useCallback(
    (value: InterviewerDecline) => {
      state.onChange("interviewerDeclines", value);
    },
    [state]
  );

  return (
    <InterviewerDeclinedSelect
      value={interviewerDeclines ?? []}
      onChange={onChange}
      interviewers={interviewers}
    />
  );
}

function OtherReasonAdditional({
  state,
}: {
  state: ReportInterviewerSwapReasonState;
}) {
  const reason = state.form.watch("otherReason");
  const onChangeReason = useCallback(
    (r: ScheduledInterviewOtherReason) => {
      state.onChange("otherReason", r);
    },
    [state]
  );

  return (
    <OtherReasonCollectionAdditionalInfo
      reason={reason}
      onChangeReason={onChangeReason}
    />
  );
}
