import { InterviewerSlotType } from "generated/graphql-codegen/graphql";

import {
  useEditInterviewerSlotDisplayState,
  UseEditInterviewerSlotDisplayStateProps,
} from "../hooks/useEditInterviewerSlotDisplayState";
import { EditPersonConfigDisplay } from "./EditPersonConfigDisplay";
import { EditPoolConfigDisplay } from "./EditPoolConfigDisplay";
import { EditSmartMatchConfigDisplay } from "./EditSmartMatchConfigDisplay";

export type EditInterviewerSlotDisplayProps =
  UseEditInterviewerSlotDisplayStateProps;

/** Render the correct edit slot config based on type */
export function EditInterviewerSlotDisplay(
  props: EditInterviewerSlotDisplayProps
) {
  const { interviewerSlot } = props;
  const state = useEditInterviewerSlotDisplayState(props);

  if (interviewerSlot.type === InterviewerSlotType.PERSON) {
    return <EditPersonConfigDisplay state={state} />;
  }

  if (interviewerSlot.type === InterviewerSlotType.SMART_MATCH) {
    return <EditSmartMatchConfigDisplay state={state} />;
  }

  if (interviewerSlot.type === InterviewerSlotType.POOL) {
    return <EditPoolConfigDisplay state={state} />;
  }

  return <EditPoolConfigDisplay state={state} />;
}
