/* eslint-disable import/prefer-default-export */
import { createDynamicCollectionItemsHook } from "../__utils/collections";
import type {
  AtlasMenuContentDynamicItems,
  AtlasMenuContentItem,
} from "./types";

/**
 * Creates a memoized dynamic collection of menu items. It wraps `useMemo` internally
 * and must be passed an array of dependencies.
 *
 * This hook also enforces proper types for the items.
 *
 * **The `i.<type>()` methods must be used to create the items:**
 *
 * @example
 * const menuItems = useMenuItems(
 *   (i) => [
 *     i.item({
 *       key: "my-item",
 *       children: "My item",
 *     }),
 *   ],
 *   []
 * );
 *
 * // complex use cases are also possible:
 * const menuItems = useMenuItems(
 *   (i) => {
 *     // calling `i` will return a typed mutable array, and you
 *     // can optionally pass initial items to it as arguments
 *     const items = i();
 *
 *     if (condition)
 *       items.push(
 *         i.item({
 *           key: "my-item",
 *           children: "My item",
 *         })
 *       );
 *
 *     return items;
 *   },
 *   [condition]
 * );
 *
 * // you can also use methods like `.map()`:
 * const menuItems = useMenuItems(
 *   (i) =>
 *     myArray.map((value) =>
 *       i.item({
 *         key: value.key,
 *         children: value.label,
 *       })
 *     ),
 *   [myArray]
 * );
 */
export const useMenuItems = createDynamicCollectionItemsHook<
  AtlasMenuContentItem,
  AtlasMenuContentDynamicItems
>();
