import { Button } from "@resource/atlas/button/Button";
import type {
  AtlasButtonAsProp,
  AtlasButtonProps,
} from "@resource/atlas/button/types";
import { useLogEvent } from "analytics";
import { Events } from "analytics/types";
// TODO: drop once Tooltip is rebuilt
import { forwardRef, ReactElement, Ref } from "react";

export type TrackedButtonProps<As extends AtlasButtonAsProp = "button"> =
  AtlasButtonProps<As> & {
    tracking: {
      name: Events;
      properties?: Record<string, unknown>;
    };
  };

export type TrackedButtonComponent = <As extends AtlasButtonAsProp = "button">(
  props: TrackedButtonProps<As>
) => ReactElement | null;

const TrackedButton = forwardRef(
  <As extends AtlasButtonAsProp = "button">(
    _props: TrackedButtonProps<As>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: Ref<any>
  ): ReactElement => {
    const {
      tracking: { name, properties },
      onClick,
      ...buttonProps
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = _props as TrackedButtonProps<any>;
    const logEvent = useLogEvent({ component: "TrackedButton" });

    return (
      <Button
        ref={ref}
        {...buttonProps}
        onClick={(event) => {
          logEvent(name, properties);
          onClick?.(event);
        }}
      />
    );
  }
) as TrackedButtonComponent;

export default TrackedButton;
