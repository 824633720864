import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import { gql } from "generated/graphql-codegen";
import {
  CancelScheduledInterviewsV2Mutation,
  InterviewerForScheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import useMutation from "utils/useMutation";

import { mapInterviewersToInterviewerMultiSelect } from "../ReportingReasons/utils/mapping";
import {
  ScheduledInterviewCancellationConfirmationView,
  ScheduledInterviewCancellationOnCompleted,
} from "../ScheduledInterviewChangeConfirmation/ScheduledInterviewCancellationConfirmationView";

const CANCEL_SCHEDULED_INTERVIEWS = gql(`
  mutation CancelScheduledInterviewsV2($input: CancelScheduledInterviewsV2Input!) {
    cancelScheduledInterviewsV2(input: $input) {
      success
      message
      code
      scheduledInterviews {
        scheduledInterview {
          schedulingRequest {
            id
            rootPost {
              id
              recipientProfiles {
                id
                name
                email
                firstName
                lastName
                imageUrl
                isCandidate
              }
              removedRecipients
            }
          }
          ...ScheduledInterviewForScheduledInterviewCard
        }
      }
    }
  }
`);

export type CancelInterviewsConfirmationDialogProps = {
  scheduledInterviewIds: string[];
  interviewers: InterviewerForScheduledInterviewCardFragment[];
  store: DialogStore;
  onCompleted?: (res: CancelScheduledInterviewsV2Mutation) => void;
};

export function CancelInterviewsConfirmationDialog({
  scheduledInterviewIds,
  interviewers,
  store,
  onCompleted,
}: CancelInterviewsConfirmationDialogProps) {
  const logEvent = useLogEvent({
    component: "CancelInterviewConfirmationDialog",
  });
  const [cancelScheduledInterviews, { loading: submitLoading }] = useMutation(
    CANCEL_SCHEDULED_INTERVIEWS
  );
  const mappedInterviewers = useMemo(() => {
    return mapInterviewersToInterviewerMultiSelect(interviewers);
  }, [interviewers]);

  const handleCancel: ScheduledInterviewCancellationOnCompleted = useCallback(
    ({ notificationSettings, reportingReason }) => {
      cancelScheduledInterviews({
        variables: {
          input: {
            deleteScheduledInterviews: scheduledInterviewIds.map((id) => ({
              scheduledInterviewId: id,
              interviewerConfirmation: {
                sendNotifications: notificationSettings.notifyInterviewers,
                note: notificationSettings.message,
              },
              reportingReason: {
                needsRescheduled: reportingReason.needsRescheduled,
                ...(reportingReason.needsRescheduled
                  ? {
                      rescheduleData: reportingReason.rescheduleData,
                    }
                  : {
                      cancellationData: reportingReason.cancellationData,
                    }),
              },
            })),
          },
        },
        onCompleted: (res) => {
          res.cancelScheduledInterviewsV2.scheduledInterviews.forEach(() => {
            logEvent("Interview Cancelled");
          });
          store.hide();

          if (onCompleted) {
            onCompleted(res);
          }
        },
      });
    },
    [
      cancelScheduledInterviews,
      scheduledInterviewIds,
      logEvent,
      store,
      onCompleted,
    ]
  );

  return (
    <Dialog store={store} size="medium">
      <ScheduledInterviewCancellationConfirmationView
        onCancel={store.hide}
        onCompleted={handleCancel}
        saveLoading={submitLoading}
        interviewers={mappedInterviewers}
      />
    </Dialog>
  );
}
