import { MutationHookOptions } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import {
  Exact,
  UnarchiveSchedulingRequestInput,
  UnarchiveSchedulingRequestMutation,
} from "generated/graphql-codegen/graphql";
import useMutation from "utils/useMutation";

const UNARCHIVE_SCHEDULING_REQUEST = gql(`
  mutation UnarchiveSchedulingRequest($input: UnarchiveSchedulingRequestInput!) {
    unarchiveSchedulingRequest(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        status
        taskStatus
        archivedAt
      }
    }
  }
`);

export function useUnarchiveSchedulingRequest(
  options?: MutationHookOptions<
    UnarchiveSchedulingRequestMutation,
    Exact<{
      input: UnarchiveSchedulingRequestInput;
    }>
  >
) {
  return useMutation(UNARCHIVE_SCHEDULING_REQUEST, {
    ...options,
  });
}
