import {
  baseNodeRenderers,
  baseTextNodeRenderers,
  createRenderer,
  createTextRenderer,
} from "@resource/atlas/content-editor/renderer";
import {
  createUnknownVariableNodeRenderer,
  createVariableNodeRenderer,
} from "@resource/atlas/content-editor/variables";

import {
  scheduledInterviewDetailsNameSet,
  scheduledInterviewDetailsRendererSet,
  scheduledInterviewDetailsRendererSetForEmail,
} from "./variables";

export const renderScheduledInterviewDetails = createRenderer({
  nodeRenderers: {
    ...baseNodeRenderers,
    variable: createVariableNodeRenderer(scheduledInterviewDetailsRendererSet, {
      displayMissingVariables: true,
      nameSet: scheduledInterviewDetailsNameSet,
    }),
    "unknown-variable": createUnknownVariableNodeRenderer(),
  },
});

export const renderScheduledInterviewDetailsForEmail = createRenderer({
  nodeRenderers: {
    ...baseNodeRenderers,
    variable: createVariableNodeRenderer(
      scheduledInterviewDetailsRendererSetForEmail
    ),
    "unknown-variable": createUnknownVariableNodeRenderer(),
  },
});

export const renderScheduledInterviewDetailsText = createTextRenderer({
  nodeRenderers: {
    ...baseTextNodeRenderers,
    variable: createVariableNodeRenderer(scheduledInterviewDetailsRendererSet, {
      asText: true,
    }),
    "unknown-variable": createUnknownVariableNodeRenderer(),
  },
});
