import { gql } from "generated/graphql-codegen";
import { useCallback, useMemo } from "react";
import useQuery from "utils/useQuery";

const FETCH_GUIDE_ACTIVITY = gql(`
query FetchGuideActivity($guideId: String!, $cursor: String) {
    guideById(guideId: $guideId) {
      id
      activity (cursor: $cursor) {
        ...ActivityForFeed
      }
    }
  }
`);

export function useGuideActivity(guideId: string) {
  const { data, loading, fetchMore, ...queryResult } = useQuery(
    FETCH_GUIDE_ACTIVITY,
    {
      variables: {
        guideId,
        cursor: null,
      },
    }
  );

  const activity = useMemo(() => data?.guideById?.activity ?? [], [data]);

  const nextPage = useCallback(() => {
    if (activity.length && !loading) {
      fetchMore({
        variables: {
          guideId,
          cursor: activity[activity.length - 1].eventTime,
        },
      });
    }
  }, [activity, fetchMore, guideId, loading]);

  return {
    ...queryResult,
    activity,
    nextPage,
    loading,
  };
}
