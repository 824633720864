import { ScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/ScheduledInterviewCard";
import { ScheduledInterviewForScheduledInterviewCardFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import {
  getCollaborativeCodingServiceType,
  getVideoConferencingServiceType,
} from "shared/utils/interview-services";

function mapTime(time: string | Date | DateTime) {
  if (typeof time === "string") {
    return DateTime.fromISO(time);
  }

  if (time instanceof Date) {
    return DateTime.fromJSDate(time);
  }

  return time;
}

export const mapScheduledInterviewForCard = ({
  interview,
}: {
  interview: ScheduledInterviewForScheduledInterviewCardFragment;
}): ScheduledInterviewForCard => ({
  ...interview,
  startTime: mapTime(interview.startTime),
  endTime: mapTime(interview.endTime),
  videoConferencingService: getVideoConferencingServiceType(
    interview.videoConferencingUrl
  ),
  collaborativeCodingService: getCollaborativeCodingServiceType(
    interview.collaborativeCodingUrl
  ),
});
