import { Avatar } from "@resource/atlas/avatar/Avatar";
import { useAuthContext } from "auth/context";
import clsx from "clsx";
import { forwardRef } from "react";

import { CommentFooterState } from "./__hooks/useCommentState";
import { CommentEditor } from "./content-editor/CommentEditor";
import { SubmitCommentButton } from "./SubmitCommentButton";

const CommentFooter = forwardRef<HTMLDivElement, { state: CommentFooterState }>(
  ({ state }, ref) => {
    const { contentEditorProps, showCommentButton } = state;
    const { user } = useAuthContext();

    const name = user?.currentUserMembership?.name ?? user?.fullName;
    const imageUrl = user?.currentUserMembership?.imageUrl ?? user?.imageUrl;

    return (
      <div
        ref={ref}
        className={clsx(
          "sticky bottom-0 flex w-full bg-light-gray-200 px-4 space-x-3 border-t border-gray-border",
          {
            "py-6": showCommentButton,
            "py-3": !showCommentButton,
          }
        )}
      >
        <Avatar size="small" image={imageUrl} name={name} />
        <CommentEditor
          {...contentEditorProps}
          className={clsx({
            "min-h-[24px]": !showCommentButton,
            "min-h-[120px]": showCommentButton,
          })}
          footerSlot={<SubmitCommentButton state={state} />}
        />
      </div>
    );
  }
);

export { CommentFooter };
