import { AvailableSlotFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";

export function useTimesDisplayInfo({
  availableSlots,
}: {
  availableSlots: AvailableSlotFragment[];
}) {
  const totalTimesCount = availableSlots.length;

  const uniqueDaysCount = new Set(
    availableSlots.map((slot) =>
      DateTime.fromISO(slot.startTime).toFormat("yyyy-MM-dd")
    )
  ).size;

  return { totalTimesCount, uniqueDaysCount };
}
