import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { AlreadyOpenTaskDialog } from "client/components/scheduling-tasks/AlreadyOpenTaskDialog";
import { useSchedulingRequestNavigation } from "client/components/scheduling-tasks/hooks/useSchedulingRequestNavigation";
import {
  GuideForSchedulingTaskAndAvailabilityWorkflowsFragment,
  SchedulingRequestStatus,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo, useState } from "react";

import { ViewCallbackProps } from "../SchedulingTaskWorkflowViewWrapper";
import { WorkflowStateForGuide } from "./useWorkflowStateForGuide";

export function useAlreadyOpenTaskConfirmation({
  guide,
  workflowState,
  viewProps,
  onSubmit,
}: {
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
  workflowState: WorkflowStateForGuide;
  viewProps: ViewCallbackProps;
  onSubmit: () => Promise<void>;
}) {
  const [isContinuing, setIsContinuing] = useState(false);
  const alreadyOpenTaskDialogState = useDialogState();
  const openSchedulingRequest = useMemo(() => {
    return guide.schedulingRequests.find(
      (sr) =>
        sr.status === SchedulingRequestStatus.IN_PROGRESS && !sr.isReschedule
    );
  }, [guide.schedulingRequests]);
  const openReschedulingRequest = useMemo(() => {
    return guide.schedulingRequests.find(
      (sr) =>
        sr.status === SchedulingRequestStatus.IN_PROGRESS && sr.isReschedule
    );
  }, [guide.schedulingRequests]);
  const schedulingRequestToNavigateTo = useMemo(() => {
    return (
      (workflowState.schedulingRequestFormType === "scheduling" &&
        openSchedulingRequest) ||
      (workflowState.schedulingRequestFormType === "rescheduling" &&
        openReschedulingRequest) ||
      null
    );
  }, [openReschedulingRequest, openSchedulingRequest, workflowState]);
  const navigateToOpenSchedulingRequest = useSchedulingRequestNavigation({
    schedulingRequestId: schedulingRequestToNavigateTo?.id ?? "",
  });
  const shouldPromptForConfirmation = useMemo(() => {
    return (
      (openSchedulingRequest &&
        workflowState.schedulingRequestFormType === "scheduling") ||
      (openReschedulingRequest &&
        workflowState.schedulingRequestFormType === "rescheduling")
    );
  }, [openReschedulingRequest, openSchedulingRequest, workflowState]);
  const onSubmitWithConfirmation = useCallback(async () => {
    if (shouldPromptForConfirmation) {
      alreadyOpenTaskDialogState.show();
    } else {
      await onSubmit();
    }
  }, [shouldPromptForConfirmation, alreadyOpenTaskDialogState, onSubmit]);
  const onContinueWithConfirmation = useCallback(async () => {
    if (shouldPromptForConfirmation) {
      setIsContinuing(true);
      alreadyOpenTaskDialogState.show();
    } else {
      viewProps.onContinue();
    }
  }, [alreadyOpenTaskDialogState, shouldPromptForConfirmation, viewProps]);
  const onCreateNewTask = useCallback(async () => {
    alreadyOpenTaskDialogState.hide();

    if (isContinuing) {
      viewProps.onContinue();
    } else {
      await onSubmit();
    }
  }, [alreadyOpenTaskDialogState, isContinuing, onSubmit, viewProps]);
  const onTakeMeToOpenTask = useCallback(async () => {
    alreadyOpenTaskDialogState.hide();
    navigateToOpenSchedulingRequest();
  }, [alreadyOpenTaskDialogState, navigateToOpenSchedulingRequest]);

  return useMemo(() => {
    return {
      Component: (
        <AlreadyOpenTaskDialog
          state={alreadyOpenTaskDialogState}
          onCreateNewTask={onCreateNewTask}
          onTakeMeToOpenTask={onTakeMeToOpenTask}
        />
      ),
      onContinueWithConfirmation,
      onSubmitWithConfirmation,
    };
  }, [
    alreadyOpenTaskDialogState,
    onContinueWithConfirmation,
    onCreateNewTask,
    onSubmitWithConfirmation,
    onTakeMeToOpenTask,
  ]);
}
