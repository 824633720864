import { ComponentPropsWithoutRef } from "react";

export function SuccessIcon({
  viewBox,
  fill,
  xmlns,
  ...props
}: ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="64" cy="64" r="64" fill="#00B07B" />
      <path
        d="M27.8389 71.2254C24.1019 66.8867 30.1081 60.304 34.5436 63.8706L53.1513 79.2696C54.1459 80.1678 54.3033 80.175 54.9685 79.4032L96.3011 28.0011C98.7381 24.9709 102.833 27.6157 100.771 30.8171L58.8306 95.9565C56.4734 99.617 51.3438 99.4799 49.2509 96.0857L27.8389 71.2254Z"
        fill="white"
      />
    </svg>
  );
}
