import {
  RollingDateRangeSetting,
  TimeUnit,
  UpsertSelfSchedulingSettingsInput,
} from "generated/graphql-codegen/graphql";
import {
  SchedulingHoursConfig,
  SelfScheduleSchedulingSettingsInput,
} from "shared/self-schedule/types";

export function mapSchedulingHoursToGraphqlInput(
  schedulingHours: SchedulingHoursConfig
): UpsertSelfSchedulingSettingsInput["schedulingHours"] {
  return schedulingHours.map((s) => ({
    isWorkingDay: s.isWorkingDay,
    startTime: s.startTime
      ? {
          hour: s.startTime.hour,
          minute: s.startTime.minute,
        }
      : null,
    endTime: s.endTime
      ? {
          hour: s.endTime.hour,
          minute: s.endTime.minute,
        }
      : null,
  }));
}

export function mapSelfSchedulingSettingsToGraphqlInput({
  selfSchedulingSettings,
}: {
  selfSchedulingSettings: SelfScheduleSchedulingSettingsInput;
}): UpsertSelfSchedulingSettingsInput {
  return {
    schedulingHours: mapSchedulingHoursToGraphqlInput(
      selfSchedulingSettings.schedulingHours
    ),
    preMeetingBuffer: {
      unit: selfSchedulingSettings.preMeetingBuffer.unit as TimeUnit,
      value: selfSchedulingSettings.preMeetingBuffer.value,
    },
    postMeetingBuffer: {
      unit: selfSchedulingSettings.postMeetingBuffer.unit as TimeUnit,
      value: selfSchedulingSettings.postMeetingBuffer.value,
    },
    minimumNotice: {
      unit: selfSchedulingSettings.minimumNotice.unit as TimeUnit,
      value: selfSchedulingSettings.minimumNotice.value,
    },
    rollingDateRange: {
      days: selfSchedulingSettings.rollingDateRange.days,
      setting: selfSchedulingSettings.rollingDateRange
        .setting as RollingDateRangeSetting,
    },
  };
}
