import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { UpsertScheduledInterview } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { DialogError } from "components/Generic/DialogError";
import { useCallback, useRef } from "react";

import { useSchedulingCompletionPromptsProps } from "../core/components/completion-prompts/hooks/useSchedulingCompletionPromptsProps";
import { SchedulingCompletionPromptsDialog } from "../core/components/completion-prompts/SchedulingCompletionPromptsDialog";
import { SchedulerProps } from "../core/components/Scheduler";
import { SchedulerOnCompleted } from "../utils/types";
import { JustScheduleScheduler } from "./JustScheduleScheduler";
import { ScheduledInterviewGroupScheduler } from "./ScheduledInterviewGroupScheduler";
import { SchedulingRequestScheduler } from "./SchedulingRequestScheduler";

export type SchedulerDialogProps = DialogProps &
  Omit<SchedulerDialogWrapperSelectorProps, "onCompleted">;

export function SchedulerDialog(props: SchedulerDialogProps) {
  const {
    schedulingRequestId,
    scheduledInterviewGroupId,
    algorithmSuggestedScenarioId,
    defaultSelectedInterviewId,
    defaultInterviews,
    store,
    guideId,
    ...rest
  } = props;
  const hasSubmittedRef = useRef(false);

  const { WarningDialog, dialogProps } = useDialogLeaveConfirmation({
    store,
    hasSubmittedRef,
  });
  const {
    onCompleted: onCompletedSchedulingCompletionPrompts,
    props: schedulingCompletionPromptsProps,
  } = useSchedulingCompletionPromptsProps({ schedulingRequestId });
  const onCompleted: SchedulerOnCompleted = useCallback(
    (...onCompletedProps) => {
      hasSubmittedRef.current = true;
      store.hide();
      onCompletedSchedulingCompletionPrompts(...onCompletedProps);
    },
    [onCompletedSchedulingCompletionPrompts, store]
  );

  return (
    <>
      <Dialog
        {...dialogProps}
        size="large"
        variant="fullscreen"
        hideOnEscape={false}
        {...rest}
        store={store}
      >
        <SchedulerDialogWrapperSelector
          schedulingRequestId={schedulingRequestId}
          scheduledInterviewGroupId={scheduledInterviewGroupId}
          algorithmSuggestedScenarioId={algorithmSuggestedScenarioId}
          defaultSelectedInterviewId={defaultSelectedInterviewId}
          defaultInterviews={defaultInterviews}
          store={store}
          onCompleted={onCompleted}
          guideId={guideId}
        />
        <WarningDialog />
      </Dialog>
      <SchedulingCompletionPromptsDialog
        {...schedulingCompletionPromptsProps}
      />
    </>
  );
}

type SchedulerDialogWrapperSelectorProps = {
  schedulingRequestId?: string;
  algorithmSuggestedScenarioId?: string;
  scheduledInterviewGroupId?: string;
  defaultSelectedInterviewId?: string;
  defaultInterviews?: UpsertScheduledInterview[];
  store: DialogProps["store"];
  onCompleted: SchedulerProps["onCompleted"];
  guideId?: string;
};

/** Choose the correct scheduler wrapper based on parameters passed. */
function SchedulerDialogWrapperSelector(
  props: SchedulerDialogWrapperSelectorProps
) {
  const {
    schedulingRequestId,
    algorithmSuggestedScenarioId,
    scheduledInterviewGroupId,
    defaultSelectedInterviewId,
    defaultInterviews,
    store,
    onCompleted,
    guideId,
  } = props;

  if (schedulingRequestId) {
    return (
      <SchedulingRequestScheduler
        schedulingRequestId={schedulingRequestId}
        algorithmSuggestedScenarioId={algorithmSuggestedScenarioId}
        defaultSelectedInterviewId={defaultSelectedInterviewId}
        onCancel={store.hide}
        onCompleted={onCompleted}
      />
    );
  }

  if (scheduledInterviewGroupId) {
    return (
      <ScheduledInterviewGroupScheduler
        scheduledInterviewGroupId={scheduledInterviewGroupId}
        defaultSelectedInterviewId={defaultSelectedInterviewId}
        defaultInterviews={defaultInterviews}
        onCancel={store.hide}
        onCompleted={onCompleted}
      />
    );
  }

  if (guideId) {
    return (
      <JustScheduleScheduler
        guideId={guideId}
        defaultSelectedInterviewId={defaultSelectedInterviewId}
        onCancel={store.hide}
        onCompleted={onCompleted}
      />
    );
  }

  return <DialogError errorCode="500" />;
}
