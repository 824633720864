import { NotificationTypeMetaFields } from "../schemas";
import { NotificationType } from "../types";
import {
  AppNotificationHrefGeneration,
  createAppNotificationHrefGeneration,
} from "./types";

export const notificationHrefMap = {
  [NotificationType.CommentMention]: createAppNotificationHrefGeneration({
    type: NotificationType.CommentMention,
    hrefGenerator: ({ schedulingRequestId, commentId }) => {
      return `${
        process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? ""
      }/scheduling-requests?selectedSchedulingRequestId=${schedulingRequestId}&commentId=${commentId}`;
    },
  }),
  [NotificationType.SchedulingRequestAssigned]:
    createAppNotificationHrefGeneration({
      type: NotificationType.SchedulingRequestAssigned,
      hrefGenerator: ({ schedulingRequestId }) => {
        return `${
          process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? ""
        }/scheduling-requests?selectedSchedulingRequestId=${schedulingRequestId}`;
      },
    }),
  [NotificationType.ImportantAriaMessage]: createAppNotificationHrefGeneration({
    type: NotificationType.ImportantAriaMessage,
    hrefGenerator: ({ guideId, schedulingRequestId, chatMessageId }) => {
      if (schedulingRequestId) {
        return `${
          process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? ""
        }/scheduling-requests?selectedSchedulingRequestId=${schedulingRequestId}&chatMessageId=${chatMessageId}`;
      }

      return `${
        process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? ""
      }/candidates/?guideId=${guideId}&chatMessageId=${chatMessageId}`;
    },
  }),
};

export function getHrefForNotification<T extends NotificationType>({
  type,
  meta,
}: {
  type: T;
  meta: unknown;
}) {
  const generator = notificationHrefMap[
    type
  ] as AppNotificationHrefGeneration<T>;

  return generator(meta as NotificationTypeMetaFields<T>);
}
