import { Hovercard } from "@resource/atlas/hovercard";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPage } from "@resource/atlas/icons";

import { TextWithLinks } from "./generic/misc/TextWithLinks";

export function SchedulingTaskNotesIcon({ notes }: { notes: string }) {
  return (
    <Hovercard.Root>
      <Hovercard.Anchor>
        <Icon content={atlasPage} className="w-4 h-4 text-subtle" />
      </Hovercard.Anchor>
      <Hovercard.Content portal>
        <TextWithLinks text={notes} className="text-body-md" />
      </Hovercard.Content>
    </Hovercard.Root>
  );
}
