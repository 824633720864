import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

const CLEAR_GUIDE_INTERVIEW_PLAN = gql(`
  mutation ClearGuideInterviewPlan($input: ClearGuideInterviewPlanOverridesInput!) {
    clearGuideInterviewPlanOverrides(input: $input) {
      code
      success
      message
      guide {
        ...GuideManagerGuideV2
      }
    }
  }
`);

export function ClearGuideInterviewPlanOverridesConfirmationDialog({
  store,
  guideId,
}: {
  store: DialogStore;
  guideId: string;
}) {
  const [clearGuideInterviewPlan, { loading: submitLoading }] = useMutation(
    CLEAR_GUIDE_INTERVIEW_PLAN,
    {
      onCompleted: () => {
        store.hide();
      },
    }
  );

  const onSubmit = useCallback(() => {
    clearGuideInterviewPlan({
      variables: {
        input: {
          guideId,
        },
      },
    });
  }, [clearGuideInterviewPlan, guideId]);

  return (
    <Dialog store={store} size="small">
      <View
        header={{
          title: "Restore interview plan to job defaults",
        }}
        content={{
          className: "text-body-md",
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button
                isGhost
                onClick={() => {
                  store.hide();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSubmit}
                isLoading={submitLoading}
                variant="primary"
              >
                Restore
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        Your modifications to the interview plan will revert to the default
        settings of the job. Please note that this action is irreversible.
      </View>
    </Dialog>
  );
}
