import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useTimezone } from "client/timezones/useTimezone";
import { formatExactTimestamp, formatTimestamp } from "client/utils/dates";

export function ActivityTimestamp({ eventTime }: { eventTime: string }) {
  const timezone = useTimezone();

  return (
    <span>
      <span> · </span>
      <Tooltip content={formatExactTimestamp({ date: eventTime, timezone })}>
        <div className="flex-shrink-0 cursor-default text-body-sm">
          {formatTimestamp({ date: eventTime, timezone })}
        </div>
      </Tooltip>
    </span>
  );
}
