import { Button } from "@resource/atlas/button/Button";
import { View, ViewProps } from "@resource/atlas/view/View";
import { BackButton } from "client/components/generic/layout/BackButton";
import { RichBlockProviderOrganization } from "client/guide-content/rich-blocks/provider";
import { PostTemplateForm } from "components/PostTemplates/ManagementDialogs/PostTemplateContentDialogView";
import {
  PostTemplateContentDialogFormData,
  useEditPostTemplateContentForm,
} from "components/PostTemplates/ManagementDialogs/useForms";
import { useUpsertPostTemplate } from "components/PostTemplates/ManagementDialogs/useUpsertPostTemplate";
import { PostTemplateForUseFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";

export function EditTemplateForm({
  postTemplate,
  organization,
  onBack,
  onUpdate,
  ...viewProps
}: {
  postTemplate: PostTemplateForUseFragment;
  organization?: RichBlockProviderOrganization | null;
  onBack?: () => void;
  onUpdate: (postTemplate: PostTemplateForUseFragment) => void;
} & ViewProps) {
  const formState = useEditPostTemplateContentForm({
    postTemplate,
  });
  const { form } = formState;
  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  const [upsertPostTemplateLoading, setUpsertPostTemplateLoading] =
    useState(false);
  const [upsertPostTemplate] = useUpsertPostTemplate();

  const onSubmit = useCallback(
    (submittedSettingsData: PostTemplateContentDialogFormData) => {
      setUpsertPostTemplateLoading(true);
      upsertPostTemplate({
        variables: {
          input: {
            id: postTemplate.id,
            titleData: submittedSettingsData.titleData,
            data: submittedSettingsData.data,
          },
        },
        onCompleted: (result) => {
          setUpsertPostTemplateLoading(false);
          onUpdate(result.upsertPostTemplate.postTemplate);
        },
      });
    },
    [onUpdate, postTemplate.id, upsertPostTemplate]
  );

  return (
    <View
      {...viewProps}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      header={{
        title: `Edit ${postTemplate.name} message template`,
        leftActions: onBack ? (
          <BackButton
            onClick={() => {
              onBack();
            }}
          />
        ) : undefined,
        ...viewProps.header,
      }}
      footer={{
        rightActions: (
          <Button
            variant="purple"
            type="submit"
            disabled={!isDirty || !isValid}
            isLoading={upsertPostTemplateLoading}
          >
            Save
          </Button>
        ),
        ...viewProps.footer,
      }}
      content={{
        className: "py-4 px-0",
        ...viewProps.content,
      }}
    >
      <PostTemplateForm
        formState={formState}
        organization={organization}
        type={postTemplate.type}
        atssyncEmailTemplate={postTemplate.atssyncEmailTemplate}
        isOverridden={postTemplate.isOverridden}
        dataSynced={postTemplate.dataSynced}
      />
    </View>
  );
}
