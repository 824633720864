import { Button as AriaButton } from "@ariakit/react";
import clsx from "clsx";
import { ReactNode, useCallback, useMemo } from "react";

type PillButtonItem<T extends string> = {
  value: T;
  label: ReactNode | string;
};

type PillButtonProps<T extends string> = {
  items: PillButtonItem<T>[];
  selected: T;
  onSelect: (value: T) => void;
};

export function PillButton<T extends string>({
  items,
  selected,
  onSelect,
}: PillButtonProps<T>) {
  return (
    <div
      className={clsx(
        "items-center flex",
        "text-body-sm bg-light-gray-200",
        "rounded-md p-[2px] space-x-[2px] "
      )}
    >
      {items.map((item) => (
        <PillButtonItem
          key={item.value}
          item={item}
          selected={selected}
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}

type PillButtonItemProps<T extends string> = {
  item: PillButtonItem<T>;
  selected: T;
  onSelect: (value: T) => void;
};

function PillButtonItem<T extends string>({
  selected,
  item,
  onSelect,
}: PillButtonItemProps<T>) {
  const isSelected = useMemo(
    () => selected === item.value,
    [item.value, selected]
  );
  const onClick = useCallback(
    () => onSelect(item.value),
    [item.value, onSelect]
  );

  return (
    <AriaButton
      onClick={onClick}
      className={clsx("rounded-md px-2.5 py-1", {
        "bg-white shadow-1": isSelected,
        "bg-light-gray-200 text-body-subtle": !isSelected,
      })}
    >
      {item.label}
    </AriaButton>
  );
}
