import { useRef, useState } from "react";

import { useCalendarContext } from "./context";
import { Event } from "./Event";
import { CalendarEvent } from "./types";

export function EventGroup({ eventGroup }: { eventGroup: CalendarEvent[] }) {
  return (
    <>
      {eventGroup.map((e, idx) => {
        return (
          <EventGroupItem
            event={e}
            idx={idx}
            groupLength={eventGroup.length}
            key={`${e.id}-${e.calendarId}-${e.startTime.toISO()}`}
          />
        );
      })}
    </>
  );
}

function EventGroupItem({
  event,
  groupLength,
  idx,
}: {
  event: CalendarEvent;
  groupLength: number;
  idx: number;
}) {
  const { focusedEventId } = useCalendarContext();
  const [isHovering, setIsHovering] = useState(false);
  const isFocused = event.id === focusedEventId;
  const hoverTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    hoverTimeoutId.current = setTimeout(() => {
      setIsHovering(true);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (hoverTimeoutId.current) {
      clearTimeout(hoverTimeoutId.current);
    }
    setIsHovering(false);
  };

  const expanded = (isFocused || isHovering) && !event.outOfOffice;

  return (
    <Event
      key={`${event.id}-${event.calendarId}-${event.startTime.toISO()}`}
      event={event}
      wrapperProps={{
        style: {
          // We subtract 0.75rem from the overall width to create a right margin but keep all the events the same width so we
          // subtract 0.75rem / number of events in group from each event's width
          width: expanded
            ? "75%"
            : `calc(${100 / groupLength}% - ${0.75 / groupLength}rem)`,
          left:
            expanded && idx !== 0
              ? "25%"
              : `calc(${(idx * 100) / groupLength}% - ${
                  (idx / groupLength) * 0.75
                }rem)`,
          ...(expanded && {
            zIndex: 50,
          }),
        },
      }}
      eventProps={{
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }}
    />
  );
}
