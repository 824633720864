import { useSubscription } from "@apollo/client";
import { TimeRange } from "client/app/internal/(scheduling requests)/state";
import { gql } from "generated/graphql-codegen";
import {
  AlgorithmRunForAiScenariosSectionFragment,
  SchedulingRequestForAIScenariosSectionFragment,
  SchedulingRequestForAIScenariosSectionFragmentDoc,
} from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useState } from "react";
import useMutation from "utils/useMutation";

const ALGORITHM_RUN_SUBSCRIPTION = gql(`
  subscription AlgorithmRunSubscription($id: ID!) {
    schedulingAlgorithmRunUpdated(schedulingAlgorithmRunId: $id) {
      ...AlgorithmRunForAiScenariosSection
    }
  }
`);

const RUN_ALGORITHM = gql(`
  mutation RunFindTimesAlgorithmForSchedulingRequest($input: RunFindTimesForSchedulingRequestInput!) {
    runFindTimesForSchedulingRequest(input: $input) {
      code
      message
      schedulingAlgorithmRun {
        id
        createdAt
      }
    }
  }
`);

export type ReRunAlgorithmProps = {
  force?: boolean;
  splitAcrossDays?: {
    min: number;
    max: number;
  };
  limitInterviewsPerDay?: {
    min: number;
    max: number;
  };
};

export type UseAIScenariosProps = {
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  isInternal: boolean;
  runAs?: string | null;
  fakeAvailability?: TimeRange[] | null;
  fetchAllScenarios?: boolean;
};
export function useAIScenarioManager({
  schedulingRequest,
  isInternal,
  runAs,
  fakeAvailability,
}: UseAIScenariosProps) {
  const [currentAlgorithmRunId, setCurrentAlgorithmRunId] = useState<
    string | null
  >(null);
  const [algorithmRunLoading, setAlgorithmRunLoading] = useState(false);
  const [runAlgorithm] = useMutation(RUN_ALGORITHM, {
    allowInternal: true,
  });

  const queueAlgorithmRun = useCallback(
    async ({
      force = false,
      splitAcrossDays,
      limitInterviewsPerDay,
    }: ReRunAlgorithmProps = {}) => {
      setAlgorithmRunLoading(true);

      await runAlgorithm({
        variables: {
          input: {
            id: schedulingRequest.id,
            force,
            runAsId: runAs,
            splitAcrossDays,
            limitInterviewsPerDay,
            ...(isInternal && {
              fakeAvailability:
                fakeAvailability?.map((e) => ({
                  title: "Available",
                  startTime: e.start,
                  endTime: e.end,
                })) ?? [],
            }),
            verbose: isInternal,
          },
        },
        onCompleted: (result) => {
          if (result.runFindTimesForSchedulingRequest.schedulingAlgorithmRun) {
            setCurrentAlgorithmRunId(
              result.runFindTimesForSchedulingRequest.schedulingAlgorithmRun.id
            );
          } else {
            setAlgorithmRunLoading(false);
          }
        },
      });
    },
    [runAlgorithm, schedulingRequest.id, runAs, isInternal, fakeAvailability]
  );

  const { data: subscriptionData } = useSubscription(
    ALGORITHM_RUN_SUBSCRIPTION,
    {
      variables: {
        id: currentAlgorithmRunId ?? "",
      },
      skip: !currentAlgorithmRunId,
      onData: ({ data, client }) => {
        setAlgorithmRunLoading(false);

        // Update the cache so that if we lose the `currentAlgorithmRunId` local state our `mostRecentAlgorithmRun` field is accurate
        client.cache.updateFragment(
          {
            fragment: SchedulingRequestForAIScenariosSectionFragmentDoc,
            fragmentName: "SchedulingRequestForAIScenariosSection",
            id: `SchedulingRequest:${schedulingRequest.id}`,
          },
          (currentData) => {
            if (currentData) {
              return {
                ...currentData,
                mostRecentAlgorithmRun:
                  data?.data?.schedulingAlgorithmRunUpdated ?? null,
              };
            }

            return null;
          }
        );
      },
    }
  );

  const [algorithmRun, setAlgorithmRun] =
    useState<AlgorithmRunForAiScenariosSectionFragment | null>(null);
  useEffect(() => {
    const latest =
      subscriptionData?.schedulingAlgorithmRunUpdated ??
      schedulingRequest.mostRecentAlgorithmRun;
    setAlgorithmRun((current) => {
      if (!current || (latest && current.updatedAt <= latest.updatedAt)) {
        return latest;
      }

      return current;
    });
  }, [
    schedulingRequest.mostRecentAlgorithmRun,
    subscriptionData?.schedulingAlgorithmRunUpdated,
  ]);

  return {
    currentAlgorithmRunId,
    algorithmRunLoading,
    queueAlgorithmRun,
    algorithmRun,
  };
}
