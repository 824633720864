import { useLazyQuery } from "@apollo/client";
import { Button } from "@ariakit/react";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { Select } from "@resource/atlas/select";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import TextField from "@resource/atlas/textfield/TextField";
import { UsersSelect } from "client/components/generic/select/UserSelect";
import { gql } from "generated/graphql-codegen";
import { VideoConferencingServiceType } from "generated/graphql-codegen/graphql";
import { useEffect, useMemo, useState } from "react";
import {
  MEET_URL_REGEX,
  TEAMS_URL_REGEX,
  ZOOM_URL_REGEX,
} from "shared/utils/video-conferencing";

// Note: this is a slow field involving a query to zoom per user so we do this async instead of a filter
const CHECK_ZOOM_MEMBERSHIP = gql(`
  query CheckZoomMembership($id: String!) {
    userMembershipById(id: $id) {
      id
      hasZoomAccount
    }
  }
`);

export type VideoConferencingUrlSettings = {
  service: VideoConferencingServiceType | null;
  url?: string;
  hostUserMembershipId?: string;
};

export function EditConferencingURLInForm({
  onChange,
  value,
  zoomEnabled,
  googleCalendarEnabled,
}: {
  onChange: (value?: VideoConferencingUrlSettings) => unknown;
  value?: VideoConferencingUrlSettings;
  zoomEnabled: boolean;
  googleCalendarEnabled: boolean;
}) {
  const [checkZoomMembership, { loading }] = useLazyQuery(
    CHECK_ZOOM_MEMBERSHIP
  );

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (value?.service === VideoConferencingServiceType.ZOOM) {
      if (!value.hostUserMembershipId) {
        setError(null);
        return;
      }

      checkZoomMembership({
        variables: { id: value.hostUserMembershipId },
      }).then(({ data }) => {
        setError(
          data?.userMembershipById?.hasZoomAccount
            ? null
            : "User does not have a Zoom account"
        );
      });
      return;
    }

    if (!value?.service) {
      const isValid =
        !value?.url ||
        value.url.match(ZOOM_URL_REGEX) ||
        value.url.match(MEET_URL_REGEX) ||
        value.url.match(TEAMS_URL_REGEX);
      setError(isValid ? null : "Please enter a valid conferencing URL");
    }
  }, [checkZoomMembership, value]);

  const showMenu = useMemo(
    () => zoomEnabled || googleCalendarEnabled,
    [zoomEnabled, googleCalendarEnabled]
  );

  const items = useSelectItems(
    (i) => {
      const dynamicItems = [
        i.option({
          key: "custom",
          value: "custom",
          children: "Custom",
        }),
      ];
      if (zoomEnabled) {
        dynamicItems.push(
          i.option({
            key: "zoom",
            value: VideoConferencingServiceType.ZOOM,
            children: "Zoom",
          })
        );
      }
      if (googleCalendarEnabled) {
        dynamicItems.push(
          i.option({
            key: "google-meet",
            value: VideoConferencingServiceType.GOOGLE_MEET,
            children: "Google Meet",
          })
        );
      }

      return dynamicItems;
    },
    [googleCalendarEnabled, zoomEnabled]
  );

  return (
    <div className="space-y-2">
      <div className="text-body-md-heavy">Video conferencing</div>
      {showMenu && (
        <Select.Root
          sameWidth
          value={value?.service || "custom"}
          setValue={(v) => {
            if (v !== value?.service) {
              const service = v as VideoConferencingServiceType;
              switch (service) {
                case VideoConferencingServiceType.GOOGLE_MEET:
                  onChange({
                    service,
                  });
                  return;
                case VideoConferencingServiceType.ZOOM:
                  onChange({
                    service,
                  });
                  return;
                default:
                  onChange({
                    service: null,
                  });
              }
            }
          }}
        >
          <Select.Trigger className="w-full mt-2 bg-light-gray-500" />
          <Select.Content items={items} />
        </Select.Root>
      )}
      {value && !value?.service && (
        <TextField
          value={value.url}
          onChange={(v) =>
            onChange({
              ...value,
              url: v,
            })
          }
          placeholder="Add conferencing URL"
          aria-label="Conferencing URL"
        />
      )}

      {value?.service === VideoConferencingServiceType.ZOOM && (
        <div>
          <div className="flex gap-2">
            <UsersSelect
              placeholderText="Host"
              selectedId={value?.hostUserMembershipId}
              onSelect={(value) => {
                onChange({
                  service: VideoConferencingServiceType.ZOOM,
                  hostUserMembershipId: value.id,
                });
              }}
            />
            <Button
              className="text-body-md text-red-500 hover:underline"
              onClick={() =>
                onChange({
                  service: VideoConferencingServiceType.ZOOM,
                  hostUserMembershipId: undefined,
                })
              }
            >
              Clear
            </Button>
          </div>
          {loading && <LoadingIndicator size="small" />}
        </div>
      )}
      <div className="text-xs text-red-500">{error}</div>
    </div>
  );
}
