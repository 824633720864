import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBookClosed } from "@resource/atlas/icons";

type ColorizedPoolIconProps = {
  variant?: "default" | "large";
};

export function ColorizedPoolIcon({
  variant = "default",
}: ColorizedPoolIconProps) {
  if (variant === "large") {
    return (
      <div className="flex items-center justify-center bg-blue-50 rounded-full h-10 w-10">
        <Icon content={atlasBookClosed} className="text-blue-500 w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-5 h-5 rounded-full bg-blue-50">
      <Icon
        className="text-blue-500 w-[.875rem] h-[.875rem]"
        content={atlasBookClosed}
      />
    </div>
  );
}
