import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Button } from "@resource/atlas/button/Button";
import {
  atlasChevronLeft,
  atlasChevronRight,
  atlasClose,
  atlasGear,
} from "@resource/atlas/icons";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { useLogEvent } from "analytics";
// eslint-disable-next-line import/no-restricted-paths
import { MonthCalendar } from "client/components/calendar/MonthCalendar/MonthCalendarV2";
import { PillButton } from "client/components/generic/misc/PillButton";
import { CypressData } from "client/cypress-data-keys";
import { TimezoneSelectorV2 } from "components/TimezoneSelection/TimezoneSelectorV2";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { memo, useCallback, useMemo, useRef } from "react";

import {
  calendarSelectedDayAtom,
  useCalendarSetSelectedDay,
  useCalendarSettings,
  useCalendarTimezone,
  useCalendarView,
  useCurrentInterval,
  useDayViewType,
  useSetCalendarTimezone,
  useSetCalendarView,
} from "../../hooks/settings";
import { useCalendarContext } from "../../utils/context";
import { CalendarSettingsPopover } from "../settings/CalendarSettingsPopover";

export type CalendarHeaderProps = {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  alertBanner?: React.ReactNode;
};

/**
 * Header with date selection, view picker, and settings.
 */
export const CalendarHeader = memo(
  ({ leftActions, rightActions, alertBanner }: CalendarHeaderProps) => {
    const { view, timezone, selectedDay, includeWeekends } =
      useCalendarSettings();
    const setSelectedDay = useCalendarSetSelectedDay();

    const currentInterval = useCurrentInterval();
    const firstDay = currentInterval.start;

    const decrementSelectedDay = useCallback(() => {
      let days = 0;
      let weeks = 0;
      if (view === "week") {
        weeks = 1;
      } else if (view === "day") {
        days = 1;
        if (!includeWeekends && selectedDay.weekday === 1) {
          days = 3;
        }
      }
      setSelectedDay((day) =>
        day.minus({
          weeks,
          days,
        })
      );
    }, [setSelectedDay, selectedDay, view, includeWeekends]);

    const incrementSelectedDay = useCallback(() => {
      let days = 0;
      let weeks = 0;
      if (view === "week") {
        weeks = 1;
      } else if (view === "day") {
        days = 1;
        if (!includeWeekends && selectedDay.weekday === 5) {
          days = 3;
        }
      }
      setSelectedDay((day) =>
        day.plus({
          weeks,
          days,
        })
      );
    }, [setSelectedDay, selectedDay, view, includeWeekends]);

    const onClickToday = useCallback(() => {
      setSelectedDay(DateTime.local().setZone(timezone).startOf("day"));
    }, [setSelectedDay, timezone]);

    const now = DateTime.local().setZone(timezone);

    const popoverButton = useMemo(() => {
      return <Button icon={atlasGear} isGhost />;
    }, []);

    return (
      <div>
        <header
          className="flex items-center justify-between p-4 flex-wrap"
          data-cy={CypressData.calendar.header}
        >
          <div className="flex space-x-1 items-center grow">
            {leftActions}
            <div className="flex">
              <Button
                icon={atlasChevronLeft}
                isGhost
                onClick={decrementSelectedDay}
                size="small"
              />
              <Button onClick={onClickToday} isGhost size="small">
                Today
              </Button>
              <Button
                icon={atlasChevronRight}
                isGhost
                onClick={incrementSelectedDay}
                size="small"
              />
            </div>
            <HeaderDatePicker />
          </div>
          <div className="flex space-x-2 items-center ml-auto">
            <HeaderTimezonePicker />
            <HeaderViewPicker />
            <CalendarSettingsPopover>{popoverButton}</CalendarSettingsPopover>
            {rightActions}
          </div>
        </header>
        {now.year !== firstDay.year && (
          <AlertBanner variant="warning">
            You are currently viewing a different year. Please ensure dates are
            correct.
          </AlertBanner>
        )}
        {alertBanner}
      </div>
    );
  }
);

function HeaderTimezonePicker() {
  const timezone = useCalendarTimezone();
  const setTimezone = useSetCalendarTimezone();

  return (
    <TimezoneSelectorV2
      onClick={setTimezone}
      value={timezone ?? undefined}
      triggerLabelType="abbreviation"
      triggerProps={{
        isGhost: true,
        className: "bg-white",
        size: "small",
      }}
      rootProps={{
        placement: "bottom-start",
      }}
      contentProps={{
        className: "w-[320px]",
      }}
    />
  );
}

function HeaderViewPicker() {
  const { currentViewingTime } = useCalendarContext();
  const view = useCalendarView();
  const setView = useSetCalendarView({
    currentViewingTime,
  });
  const dayViewType = useDayViewType();

  const logEvent = useLogEvent({
    component: "CalendarHeader",
  });

  const handleViewChange = useCallback(
    (newView: typeof view) => {
      logEvent("Calendar View Changed", {
        view: newView,
        isDayViewBeta:
          newView === "day" && dayViewType === "calendar_and_grouping",
      });
      setView(newView);
    },
    [logEvent, setView, dayViewType]
  );

  return (
    <PillButton
      items={[
        {
          value: "day",
          label:
            dayViewType === "calendar_and_grouping" ? (
              <span>
                Day <span className="text-subtle">(beta)</span>
              </span>
            ) : (
              "Day"
            ),
        },
        {
          value: "week",
          label: "Week",
        },
      ]}
      selected={view}
      onSelect={handleViewChange}
    />
  );
}

function HeaderDatePicker() {
  const { view } = useCalendarSettings();
  const [selectedDay, setSelectedDay] = useAtom(calendarSelectedDayAtom);
  const currentInterval = useCurrentInterval();
  const firstDay = currentInterval.start;
  const lastDay = currentInterval.end;
  const headerTitle = useMemo(() => {
    let title =
      view === "day"
        ? firstDay.toFormat("EEEE, MMMM d, yyyy")
        : firstDay.toFormat("MMMM yyyy");

    if (firstDay.month !== lastDay.month) {
      title = `${firstDay.toFormat("MMM")} - ${lastDay.toFormat("MMM yyyy")}`;
    }

    return title;
  }, [view, firstDay, lastDay]);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const popoverState = usePopoverState({
    getAnchorRect: () => {
      return anchorRef.current?.getBoundingClientRect() ?? null;
    },
  });

  const handleChange = useCallback(
    (newDay: DateTime) => {
      const parsedDay = newDay.startOf("day");
      setSelectedDay(parsedDay);
      popoverState.hide();
    },
    [setSelectedDay, popoverState]
  );

  return (
    <>
      <Button
        isGhost
        size="small"
        onClick={popoverState.toggle}
        ref={anchorRef}
      >
        {headerTitle}
      </Button>
      <Popover.Root state={popoverState}>
        <Popover.Content>
          <MonthCalendar
            leftActions={
              <Button
                icon={atlasClose}
                onClick={popoverState.hide}
                isGhost
                size="small"
              />
            }
            selected={selectedDay}
            onSelect={handleChange}
          />
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
