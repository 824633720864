import { z } from "zod";

export enum SchedulingRequestGuidePostType {
  REQUEST_AVAILABILITY = "REQUEST_AVAILABILITY",
  REQUEST_ADDITIONAL_AVAILABILITY = "REQUEST_ADDITIONAL_AVAILABILITY",
  INTERVIEW_CONFIRMATION = "INTERVIEW_CONFIRMATION",
  RESCHEDULE_CONFIRMATION = "RESCHEDULE_CONFIRMATION",
}

export const RecipientSchema = z.object({
  isCandidate: z.boolean(),
  email: z.string().email(),
  userMembershipId: z.string().uuid().nullable().optional(),
});

export const CreateGuidePostSchema = z.object({
  guideId: z.string().uuid(),
  candidateTimezone: z.string().nullable().optional(),
  post: z.object({
    threadId: z.string().uuid().optional(),
    senderId: z.string().uuid().optional(),
    content: z.string(),
    to: z.string().email().optional(),
    title: z.string().optional(),
    recipients: z.array(RecipientSchema).optional(),
    postTemplateId: z.string().uuid().optional(),
    moveToInterviewPlanItemId: z.string().uuid().nullable().optional(),
  }),
  schedulingRequest: z
    .object({
      schedulingRequestId: z.string().uuid(),
      type: z.nativeEnum(SchedulingRequestGuidePostType),
      completeTask: z.boolean().optional(),
    })
    .optional(),
});

export type CreateGuidePostInput = z.infer<typeof CreateGuidePostSchema>;
