import { useSubscription } from "@apollo/client";
import { useAuthContext } from "auth/context";
import { useFlags } from "client/lib/launchdarkly";
import { RoleEnum } from "enums/role-enum";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import {
  AutomatedActionForToastFragment,
  AutomatedActionStatus,
} from "generated/graphql-codegen/graphql";
import React, { useEffect } from "react";
import { ActionTypes } from "shared/automation/actions";
import {
  CandidateMessageType,
  SendCandidateMessageActionDataSchema,
} from "shared/automation/actions/schemas/SendCandidateMessageActionSchema";
import { useQueryStringValue } from "utils/next";

import { useExtensionController } from "./ExtensionControllerProvider";

gql(`
  fragment AutomatedActionForToast on AutomatedAction {
    id
    type
    status
    data
    requireReview
    automation {
      id
      guide {
        id
        candidateId
        candidate {
          id
          internalName
        }
      }
    }
  }`);

const AUTOMATED_ACTION_COMPLETED_SUBSCRIPTION = gql(`
  subscription OnAutomatedActionCreatedOrCompleted($guideId: ID!) {
    automatedActionToast(guideId: $guideId) {
      ...AutomatedActionForToast
    }
  }
`);

const SUBSCRIBE_TO_GUIDE_BY_APPLICATION_ID = gql(`
  subscription SubscribeToGuideByApplicationIdForAutomationToasts($applicationId: String!) {
    guideCreatedForAtssyncApplicationRemoteId(atssyncApplicationRemoteId: $applicationId)
  }
`);

function getAutomationMessage(action: AutomatedActionForToastFragment): string {
  const candidateName = action.automation?.guide?.candidate?.internalName;
  const requiresReview = action.requireReview;
  const prefix = requiresReview ? "Suggesting" : "Starting";
  const messagePrefix = requiresReview ? "Suggesting" : "Sending";

  switch (action.type) {
    case ActionTypes.CreateSchedulingTask:
      return `${prefix} scheduling interviews for ${candidateName}...`;
    case ActionTypes.InterviewerSwap:
      return `${prefix} swapping interviewers for ${candidateName}...`;
    case ActionTypes.RescheduleInterviews:
      return `${prefix} rescheduling interviews for ${candidateName}...`;
    case ActionTypes.ScheduleInterviews:
      return `${prefix} scheduling interviews for ${candidateName}...`;
    case ActionTypes.SendCandidateMessage: {
      if (!action.data) return "";

      const parsedData = SendCandidateMessageActionDataSchema.safeParse(
        action.data
      );
      if (!parsedData.success) return "";

      switch (parsedData.data.messageType) {
        case CandidateMessageType.AVAILABILITY_REQUEST:
          return `${messagePrefix} an availability request to ${candidateName}...`;
        case CandidateMessageType.AVAILABILITY_REQUEST_FOLLOW_UP:
          return `${messagePrefix} an availability request follow-up to ${candidateName}...`;
        case CandidateMessageType.INTERVIEW_CONFIRMATION:
          return `${messagePrefix} an interview confirmation to ${candidateName}...`;
        case CandidateMessageType.RESCHEDULE_CONFIRMATION:
          return `${messagePrefix} a reschedule confirmation to ${candidateName}...`;
        case CandidateMessageType.SELF_SCHEDULE_REQUEST:
          return `${messagePrefix} a self-schedule request to ${candidateName}...`;
        default:
          return "";
      }
    }
    default:
      return "";
  }
}

export function AutomationToastProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const {
    guideIdForCurrentlyViewingATSApplication,
    setGuideIdForCurrentlyViewingATSApplication,
    sendMessageToParent,
    panelOpenState,
  } = useExtensionController();
  const {
    [FeatureFlags.AUTOMATED_ACTION_TOASTS]: automatedActionToastsEnabled,
  } = useFlags();
  const { user, highestRole } = useAuthContext();
  const applicationId = useQueryStringValue("applicationId");

  useSubscription(SUBSCRIBE_TO_GUIDE_BY_APPLICATION_ID, {
    variables: { applicationId },
    onSubscriptionData: ({ subscriptionData }) => {
      const guideId =
        subscriptionData.data?.guideCreatedForAtssyncApplicationRemoteId;
      if (guideId) {
        setGuideIdForCurrentlyViewingATSApplication(guideId);
      }
    },
    skip: !applicationId || !!guideIdForCurrentlyViewingATSApplication,
  });

  useEffect(() => {
    console.debug("[GUIDE] AutomationToastProvider debug:", {
      guideIdForCurrentlyViewingATSApplication,
      applicationId,
      panelOpenState,
      automatedActionToastsEnabled,
      highestRole,
    });
  }, [
    guideIdForCurrentlyViewingATSApplication,
    applicationId,
    panelOpenState,
    automatedActionToastsEnabled,
    highestRole,
  ]);

  useSubscription(AUTOMATED_ACTION_COMPLETED_SUBSCRIPTION, {
    variables: {
      guideId: guideIdForCurrentlyViewingATSApplication!,
    },
    skip:
      !guideIdForCurrentlyViewingATSApplication ||
      !user ||
      highestRole === RoleEnum.Candidate,
    onData: ({ data }) => {
      const automatedAction = data.data?.automatedActionToast;
      console.debug("[GUIDE] Automated action completed", automatedAction);
      if (!automatedAction || !automatedActionToastsEnabled) return;

      // We should ignore "created" actions unless they require review
      if (
        automatedAction.status !== AutomatedActionStatus.COMPLETED &&
        !automatedAction.requireReview
      ) {
        return;
      }

      if (
        guideIdForCurrentlyViewingATSApplication ===
        automatedAction.automation?.guide?.id
      ) {
        const message = getAutomationMessage(automatedAction);

        console.debug(
          "[GUIDE]: Automation toast receieved",
          message,
          panelOpenState
        );

        if (message && !panelOpenState) {
          sendMessageToParent({
            command: "send-toast",
            value: {
              message,
              options: {
                variant: "info",
                icon: {
                  name: "atlasBoltFill",
                  color: "#FFB800",
                },
                actions: [
                  {
                    label: automatedAction.requireReview
                      ? "Review suggestion"
                      : "View in Guide",
                    onClick: {
                      href: `${
                        process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? ""
                      }/candidates?guideId=${
                        automatedAction.automation?.guide?.id ?? ""
                      }&assistant_chat_open=true`,
                      target: "_guideIframe",
                    },
                    variant: "default",
                    isDismissAction: true,
                  },
                ],
              },
            },
          });
        }
      }
    },
  });

  return <>{children}</>;
}
