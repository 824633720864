import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";
import type { SerializedGifNode } from "shared/guide-content/rich-blocks/gif";

import { Gif } from "./Gif";

export const renderGifNode = createNodeRenderer<SerializedGifNode>(
  ({ data }) => (
    <Gif
      giphyId={data.giphyId}
      className="my-[.75rem] first:mt-[0] last:mb-[0]"
    />
  )
);
