import { gql } from "generated/graphql-codegen";
import { useCallback, useMemo } from "react";
import useMutation from "utils/useMutation";

import { UpdateInterviewFormData } from "./useUpdateInterviewState";

const UPDATE_INTERVIEW = gql(`
  mutation UpdateJobInterview($input: UpdateInterviewInput!) {
    updateInterview(input: $input) {
      success
      code
      message
      interview {
        ...InterviewForUpdateInterview
      }
    }
  }
`);

export function useUpdateInterview({ interviewId }: { interviewId: string }) {
  const [update, mutationResult] = useMutation(UPDATE_INTERVIEW);

  const wrappedUpdate = useCallback(
    async ({
      data,
      onCompleted,
    }: {
      data: UpdateInterviewFormData;
      onCompleted?: () => void;
    }) => {
      await update({
        variables: {
          input: {
            id: interviewId,
            ...data,
          },
        },
        onCompleted,
      });
    },
    [update, interviewId]
  );

  return useMemo(
    (): [typeof wrappedUpdate, typeof mutationResult] => [
      wrappedUpdate,
      mutationResult,
    ],
    [wrappedUpdate, mutationResult]
  );
}
