import { Interval } from "luxon";
import { useMemo } from "react";

import {
  ColorConfig,
  DEFAULT_CALENDAR_VARIANT,
  getCalendarColor,
} from "./colors";
import { useCalendarTimezone } from "./settings";
import {
  CalendarColorsConfig,
  CalendarEvent,
  CalendarEventInput,
} from "./types";
import { CalendarState } from "./useCalendarState";
import { mapCalendarEventInputToLuxonTimes } from "./utils";

type UseCalendarEventsProps = {
  events: CalendarEventInput[];
  calendarColors?: CalendarColorsConfig;
  calendarState: CalendarState;
};

/**
 * Handles mapping events for the calendar from inputs to the format the calendar expects
 * Receives state as it's used outside the calendar provider to pass the events into the provider
 * Some state values are necessary here though
 */
export function useCalendarEvents({
  events: inputEvents,
  calendarColors: inputCalendarColors = {},
  calendarState,
}: UseCalendarEventsProps) {
  const { isEventInView } = calendarState;
  const timezone = useCalendarTimezone();

  const allEvents = useMemo<CalendarEvent[]>(() => {
    // MAP EVENTS
    const mappedEvents = inputEvents.flatMap<CalendarEvent>(
      (e): CalendarEvent[] => {
        const calendarColors = inputCalendarColors;

        const defaultColorConfig: ColorConfig = {
          color: getCalendarColor(Object.keys(calendarColors).length),
          variant: DEFAULT_CALENDAR_VARIANT,
        };
        const colorConfig =
          e.colorConfig ?? calendarColors[e.calendarId] ?? defaultColorConfig;

        if (!calendarColors[e.calendarId]) {
          calendarColors[e.calendarId] = colorConfig;
        }

        const mappedEvent = mapCalendarEventInputToLuxonTimes(e, timezone);
        const eventStartTime = mappedEvent.startTime;
        const eventEndTime = mappedEvent.endTime;
        const eventInterval = Interval.fromDateTimes(
          eventStartTime,
          eventEndTime
        );

        const events: CalendarEvent[] = [];

        function recursivelyAddEvents(interval: Interval) {
          const [firstDay, remainingInterval] = interval.splitAt(
            interval.start.startOf("day").plus({ days: 1 })
          );

          if (firstDay) {
            const allDay =
              mappedEvent.allDay ??
              (firstDay.start.equals(firstDay.start.startOf("day")) &&
                firstDay.end.equals(
                  firstDay.start.startOf("day").plus({ days: 1 })
                ));

            events.push({
              ...e,
              startTime: firstDay.start,
              endTime: firstDay.end,
              colorConfig,
              allDay,
            });

            if (remainingInterval) {
              recursivelyAddEvents(remainingInterval);
            }
          }
        }

        recursivelyAddEvents(eventInterval);

        return events;
      }
    );
    // FILTER EVENTS
    const filteredEvents = mappedEvents.filter((e) => {
      return isEventInView(e);
    });

    // SORT EVENTS
    const sortedEvents = filteredEvents.sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1;
      }

      if (a.startTime > b.startTime) {
        return 1;
      }

      return 0;
    });

    return sortedEvents;
  }, [inputCalendarColors, inputEvents, isEventInView, timezone]);

  return useMemo<{
    allDayEvents: CalendarEvent[];
    events: CalendarEvent[];
  }>(() => {
    return allEvents.reduce(
      (acc, event) => {
        if (event.allDay) {
          return {
            ...acc,
            allDayEvents: [...acc.allDayEvents, event],
          };
        }
        return {
          ...acc,
          events: [...acc.events, event],
        };
      },
      {
        allDayEvents: [] as CalendarEvent[],
        events: [] as CalendarEvent[],
      }
    );
  }, [allEvents]);
}
