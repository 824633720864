import { InterviewerResponse } from "shared/guide-scheduler/types";
import { z } from "zod";

export const RsvpFormSchema = z.object({
  interviewerId: z.string(),
  responseStatus: z.nativeEnum(InterviewerResponse, {
    required_error: "RSVP status is required",
  }),
  responseNote: z.string().optional(),
});

export type RsvpFormData = z.infer<typeof RsvpFormSchema>;
