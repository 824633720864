import { View, ViewProps } from "@resource/atlas/view/View";
import {
  RadioItem,
  RadioItemProps,
} from "client/components/generic/inputs/RadioItem";
import { BackButton } from "client/components/generic/layout/BackButton";
import { CloseButton } from "client/components/generic/layout/CloseButton";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useCallback, useEffect } from "react";
import {
  InterviewerDecline,
  InterviewerNoShow,
  ScheduledInterviewCancellationReason,
  ScheduledInterviewOtherReason,
  ScheduledInterviewRescheduleReason,
} from "shared/reporting/scheduled-interviews/types";

import { InterviewerMultiSelectItem } from "../__components/InterviewerMultiSelect";
import { ReportCancellationReasonForm } from "../__components/ReportCancellationReasonForm";
import { ReportRescheduleReasonForm } from "../__components/ReportRescheduleReasonForm";
import { ReportCancellationReasonState } from "../hooks/useReportCancellationReasonState";

export type ReportCancellationReasonViewProps = ViewProps & {
  interviewers: InterviewerMultiSelectItem[];
  state: ReportCancellationReasonState;
  onCancel: () => void;
  onBack?: () => void;
  /**
   * Confirmation button should get passed from the caller
   * Based on whether it is a standalone view or part of a multi-step view
   */
  confirmationButton: {
    label: string;
    variant: "default" | "primary";
    onClick: () => void;
    isLoading?: boolean;
  };
};

/**
 * View for choosing whether or not needs rescheduling
 * Will show the cancellation reason form or rescheduling reason form based on `needsRescheduled`
 */
export function ReportCancellationReasonView({
  interviewers,
  state,
  onBack,
  onCancel,
  confirmationButton,
  ...viewProps
}: ReportCancellationReasonViewProps) {
  const { form } = state;

  // Trigger form validation on mount
  useEffect(() => {
    state.form.trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState,
    {
      skipDirtyCheck: true,
    }
  );

  const needsRescheduled = form.watch("needsRescheduled");

  const handleChangeNeedsRescheduled = useCallback(
    (newNeedsRescheduled: boolean) => {
      state.onChange("needsRescheduled", newNeedsRescheduled);
    },
    [state]
  );

  const getNeedsRescheduleRadioItemProps = useCallback(
    ({ type }: { type: boolean }): RadioItemProps => {
      return {
        label: type
          ? "Yes, this interview will need to be rescheduled."
          : "No, this interview will not need to be rescheduled.",
        isSelected: needsRescheduled === type,
        onClick: () => handleChangeNeedsRescheduled(type),
      };
    },
    [handleChangeNeedsRescheduled, needsRescheduled]
  );

  return (
    <View
      content={{
        title: "Report cancellation reason",
        subTitle:
          "To ensure accurate reporting, understanding why you’re cancelling is important. Please select the reason for cancelling the interview:",
        className: "space-y-4",
      }}
      header={{
        leftActions: onBack ? (
          <BackButton onClick={onBack} />
        ) : (
          <CloseButton onClick={onCancel} />
        ),
      }}
      footer={{
        ...viewProps.footer,
        rightActions: (
          <FormRightActions
            disabledTooltipContent={disabledTooltipContent}
            cancel={{
              children: onBack ? "Back to review" : "Cancel",
              onClick: onBack ?? onCancel,
            }}
            save={{
              ...confirmationButton,
              children: confirmationButton.label,
            }}
          />
        ),
      }}
    >
      <FormGroup label="Will this interview need to be rescheduled?">
        <RadioItem {...getNeedsRescheduleRadioItemProps({ type: true })} />
        <RadioItem {...getNeedsRescheduleRadioItemProps({ type: false })} />
      </FormGroup>
      {typeof needsRescheduled === "boolean" && (
        <>
          {needsRescheduled ? (
            <InternalRescheduleForm state={state} interviewers={interviewers} />
          ) : (
            <InternalCancellationForm state={state} />
          )}
        </>
      )}
    </View>
  );
}

type InternalRescheduleFormProps = {
  state: ReportCancellationReasonState;
  interviewers: InterviewerMultiSelectItem[];
};

function InternalRescheduleForm({
  state,
  interviewers,
}: InternalRescheduleFormProps) {
  const { form } = state;

  const rescheduleReason = form.watch("rescheduleData.reason");
  const interviewerNoShows = form.watch("rescheduleData.interviewerNoShows");
  const interviewerDeclines = form.watch("rescheduleData.interviewerDeclines");
  const otherReason = form.watch("rescheduleData.otherReason");
  const additionalText = form.watch("rescheduleData.additionalText");

  const handleChangeRescheduleReason = useCallback(
    (newReason: ScheduledInterviewRescheduleReason) =>
      state.onChange("rescheduleData.reason", newReason),
    [state]
  );

  const handleChangeInterviewerNoShows = useCallback(
    (newNoShows: InterviewerNoShow) =>
      state.onChange("rescheduleData.interviewerNoShows", newNoShows),
    [state]
  );

  const handleChangeInterviewerDeclines = useCallback(
    (newDeclines: InterviewerDecline) =>
      state.onChange("rescheduleData.interviewerDeclines", newDeclines),
    [state]
  );

  const handleChangeOtherReason = useCallback(
    (newOtherReason: ScheduledInterviewOtherReason) =>
      state.onChange("rescheduleData.otherReason", newOtherReason),
    [state]
  );

  const handleChangeAdditionalText = useCallback(
    (newAdditionalText: string) =>
      state.onChange("rescheduleData.additionalText", newAdditionalText),
    [state]
  );

  return (
    <ReportRescheduleReasonForm
      interviewers={interviewers}
      reason={rescheduleReason ?? null}
      onChangeReason={handleChangeRescheduleReason}
      interviewerNoShows={interviewerNoShows ?? null}
      onChangeInterviewerNoShows={handleChangeInterviewerNoShows}
      interviewerDeclines={interviewerDeclines ?? null}
      onChangeInterviewerDeclines={handleChangeInterviewerDeclines}
      otherReason={otherReason ?? null}
      onChangeOtherReason={handleChangeOtherReason}
      additionalText={additionalText ?? null}
      onChangeAdditionalText={handleChangeAdditionalText}
    />
  );
}

type InternalCancellationFormProps = {
  state: ReportCancellationReasonState;
};

function InternalCancellationForm({ state }: InternalCancellationFormProps) {
  const { form } = state;

  const cancellationReason = form.watch("cancellationData.reason");
  const otherReason = form.watch("cancellationData.otherReason");
  const additionalText = form.watch("cancellationData.additionalText");

  const handleChangeCancellationReason = useCallback(
    (newReason: ScheduledInterviewCancellationReason) =>
      state.onChange("cancellationData.reason", newReason),
    [state]
  );

  const handleChangeCancellationOtherReason = useCallback(
    (newOtherReason: ScheduledInterviewOtherReason) =>
      state.onChange("cancellationData.otherReason", newOtherReason),
    [state]
  );

  const handleChangeAdditionalText = useCallback(
    (newAdditionalText: string) =>
      state.onChange("cancellationData.additionalText", newAdditionalText),
    [state]
  );

  return (
    <ReportCancellationReasonForm
      cancellationReason={cancellationReason}
      onChangeCancellationReason={handleChangeCancellationReason}
      otherReason={otherReason ?? null}
      onChangeOtherReason={handleChangeCancellationOtherReason}
      additionalText={additionalText ?? null}
      onChangeAdditionalText={handleChangeAdditionalText}
    />
  );
}
