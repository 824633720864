import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { SchedulerOnCompleted } from "client/scheduler/utils/types";
import { useCallback, useMemo, useState } from "react";

import { SchedulingCompletionPromptsDialogProps } from "../SchedulingCompletionPromptsDialog";
import { mapSchedulingResponseToSchedulingInfo } from "../utils/mapping";

export function useSchedulingCompletionPromptsProps({
  schedulingRequestId,
}: {
  schedulingRequestId?: string;
} = {}): {
  props: SchedulingCompletionPromptsDialogProps;
  onCompleted: SchedulerOnCompleted;
} {
  const store = useDialogStore();
  const [props, setProps] = useState<
    Omit<SchedulingCompletionPromptsDialogProps, "store">
  >({
    guideId: "",
    schedulingRequestId: "",
    interviewsWithSchedulingInfo: [],
    invitesSent: false,
  });

  const onCompleted: SchedulerOnCompleted = useCallback(
    (res, { sendNotifications, isReschedule }) => {
      const scheduledInterviewGroup =
        res.upsertScheduledInterviewGroupForGuide?.scheduledInterviewGroup;

      if (!scheduledInterviewGroup) {
        return;
      }

      setProps({
        interviewsWithSchedulingInfo:
          mapSchedulingResponseToSchedulingInfo(res),
        invitesSent: sendNotifications,
        guideId: scheduledInterviewGroup.guide.id,
        schedulingRequestId,
        isReschedule,
      });
      store.show();
    },
    [schedulingRequestId, store]
  );

  return useMemo(
    () => ({
      props: {
        ...props,
        store,
      },
      onCompleted,
    }),
    [onCompleted, props, store]
  );
}
