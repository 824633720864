import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_TARGETS } from "@resource/atlas/content-editor/__utils/insert-options-targets-order";
import { createRichBlock } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { atlasCalendarEvent } from "@resource/atlas/icons";
import { useMemo } from "react";
import {
  DEFAULT_DATA,
  RICH_BLOCK_NAME,
  SelfScheduleRequestData,
  SelfScheduleRequestName,
} from "shared/self-schedule/rich-blocks/self-schedule-request";

export const {
  $createSelfScheduleRequestNode,
  $isSelfScheduleRequestNode,
  INSERT_SELF_SCHEDULE_REQUEST_COMMAND,
  SelfScheduleRequestNode,
  SelfScheduleRequestPlugin,
  selfScheduleRequestModule,
} = createRichBlock<SelfScheduleRequestName, SelfScheduleRequestData>({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => {
    const { SelfScheduleRequestComponent } = await import("./component");
    return SelfScheduleRequestComponent;
  },
  useInsertOption(_, { INSERT_COMMAND }) {
    const [editor] = useLexicalComposerContext();

    return useMemo(
      () => ({
        icon: atlasCalendarEvent,
        label: "Self-schedule request",
        targets: {
          insert: INSERT_TARGETS.SELF_SCHEDULE_REQUEST,
          fixedToolbar: {
            slot: "self-schedule-request",
          },
        },
        onTrigger: () => {
          return editor.dispatchCommand(INSERT_COMMAND, DEFAULT_DATA);
        },
      }),
      [editor, INSERT_COMMAND]
    );
  },
});
