import { useMemo } from "react";

import { useFilteredInterviewerCalendarEventsForScheduler } from "./interviewer-calendar-events";
import { useOrganizationBlockedEvents } from "./useOrganizationBlockedEvents";
import { useSchedulingCalendarEvents } from "./useSchedulingCalendarEvents";

export function useCalendarEvents() {
  const interviewerCalendarEvents =
    useFilteredInterviewerCalendarEventsForScheduler();
  const schedulingCalendarEvents = useSchedulingCalendarEvents();
  const organizationBlockedEvents = useOrganizationBlockedEvents();

  return useMemo(
    () => [
      ...interviewerCalendarEvents,
      ...schedulingCalendarEvents,
      ...organizationBlockedEvents,
    ],
    [
      interviewerCalendarEvents,
      schedulingCalendarEvents,
      organizationBlockedEvents,
    ]
  );
}
