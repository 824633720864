import { CalendarEvent } from "client/components/calendar-v2/utils/types";
import { useInterviewerSlotFetchedData } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { INTERVIEW_WHITELIST_ICON } from "client/utils/interview-whitelist";
import { useMemo } from "react";

import { SchedulerAddToWhitelistSwitch } from "../../components/misc/SchedulerAddToWhitelistSwitch";
import { useFilteredInterviewerCalendarEventsForScheduler } from "../hooks/interviewer-calendar-events";

export function EventDetailsWhitelistFooter({
  event,
}: {
  event: CalendarEvent;
}) {
  const { interviewers } = useInterviewerSlotFetchedData();
  const interviewerUserMemberships = useMemo(() => {
    return interviewers.map((interviewer) => interviewer.userMembership);
  }, [interviewers]);
  const userMembership = useMemo(() => {
    return interviewerUserMemberships.find((um) => um.id === event.calendarId);
  }, [event.calendarId, interviewerUserMemberships]);
  const allCalendarEvents = useFilteredInterviewerCalendarEventsForScheduler();
  const interviewerCalendarEvent = useMemo(() => {
    return allCalendarEvents.find(
      (e) => e.id === event.id && e.calendarId === event.calendarId
    );
  }, [allCalendarEvents, event.id, event.calendarId]);

  if (!userMembership || !interviewerCalendarEvent) {
    return null;
  }

  return (
    <div className="py-3 px-4 flex justify-between text-body-sm">
      <span>{INTERVIEW_WHITELIST_ICON} Mark as flexible</span>
      <SchedulerAddToWhitelistSwitch
        event={interviewerCalendarEvent}
        userMembership={userMembership}
        hideText
      />
    </div>
  );
}
