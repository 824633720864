import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import { serviceGreenhouse } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { SectionMessage } from "client/components/generic/misc/SectionMessage";
import { InterviewForUpdateInterviewFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";

import { useUpdateInterview } from "../__hooks/useUpdateInterview";
import {
  UpdateInterviewFormData,
  useUpdateInterviewState,
} from "../__hooks/useUpdateInterviewState";
import { EditInterviewTitle } from "./EditInterviewTitle";

type EditInterviewViewProps = {
  interview: InterviewForUpdateInterviewFragment;
  onSave: (data: UpdateInterviewFormData) => void | Promise<void>;
  saveLoading?: boolean;
  onCancel: () => void;
};

export function EditInterviewView({
  interview,
  onSave,
  saveLoading,
  onCancel,
}: EditInterviewViewProps) {
  const state = useUpdateInterviewState({
    defaultInterview: interview,
  });

  const { form, disableTooltipContent } = state;

  return (
    <View
      as="form"
      content={{
        className: "pb-12 space-y-4",
      }}
      onSubmit={form.handleSubmit(onSave)}
      header={{
        title: "Edit candidate-facing interview details",
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button isGhost onClick={onCancel}>
              Cancel
            </Button>
            <Tooltip isInstant content={disableTooltipContent}>
              <Button
                variant="primary"
                type="submit"
                disabled={!!disableTooltipContent}
                isLoading={saveLoading}
              >
                Save
              </Button>
            </Tooltip>
          </ButtonGroup>
        ),
      }}
    >
      {interview.atssyncInterview && interview.atssyncJobInterviewStage && (
        <>
          <AlertBanner variant="warning">
            This will update the candidate-facing title at the job level, and
            all candidates scheduled in the future will be affected.
          </AlertBanner>
          <SectionMessage leadingIcon={<Icon content={serviceGreenhouse} />}>
            Linked with{" "}
            <span className="text-body-md-heavy">
              {interview.atssyncInterview.name}
            </span>{" "}
            interview for the{" "}
            <span className="text-body-md-heavy">
              {interview.atssyncJobInterviewStage.name}
            </span>{" "}
            stage
          </SectionMessage>
        </>
      )}
      <EditInterviewTitle state={state} />
    </View>
  );
}

export function EditInterviewDialog({
  store,
  variant = "sheet",
  size,
  interview,
}: Pick<DialogProps, "store" | "variant" | "size"> &
  Omit<EditInterviewViewProps, "onSave" | "onCancel">) {
  const [updateInterview, { loading: updateLoading }] = useUpdateInterview({
    interviewId: interview.id,
  });
  const onSave = useCallback(
    async (updatedData: UpdateInterviewFormData) => {
      await updateInterview({
        data: updatedData,
        onCompleted: store.hide,
      });
    },
    [updateInterview, store]
  );

  return (
    <Dialog store={store} variant={variant} size={size}>
      {interview && (
        <EditInterviewView
          onSave={onSave}
          onCancel={store.hide}
          saveLoading={updateLoading}
          interview={interview}
        />
      )}
    </Dialog>
  );
}
