import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

const FETCH_SCHEDULING_REQUEST_FOR_COMPLETION_PROMPTS_QUERY = gql(`
  query FetchSchedulingRequestForCompletionPrompts($schedulingRequestId: ID!) {
    schedulingRequestById(id: $schedulingRequestId) {
      ...SchedulingRequestForSchedulingCompletionPrompts
    }
  }  
`);

export function useSchedulingRequestForCompletionPrompts({
  schedulingRequestId,
}: {
  schedulingRequestId?: string;
}) {
  const { data, loading } = useQuery(
    FETCH_SCHEDULING_REQUEST_FOR_COMPLETION_PROMPTS_QUERY,
    {
      // ensure scheduling data is up to date
      fetchPolicy: "network-only",
      variables: {
        schedulingRequestId: schedulingRequestId ?? "",
      },
      skip: !schedulingRequestId,
    }
  );
  const schedulingRequest = useMemo(() => data?.schedulingRequestById, [data]);

  return {
    schedulingRequest,
    loading,
  };
}
