/* eslint-disable import/prefer-default-export */
import { cloneElement } from "react";

import { isNotUndefined } from "../../misc";
import type { AtlasContentEditorModule, LinkedOptions } from "./types";

// utils
// -----

function collectLinkedOptions(modules: AtlasContentEditorModule[]) {
  return modules
    .map((module) => {
      if (typeof module === "function") return module(true);
      return undefined;
    })
    .filter(isNotUndefined);
}

function computeLinkedOptions(options: LinkedOptions[]): LinkedOptions {
  return {
    insertOptions: options
      .flatMap((module) => module.insertOptions)
      .filter(isNotUndefined),
  };
}

function resolveModules(
  modules: AtlasContentEditorModule[],
  linkedOptions: LinkedOptions
) {
  return modules.map((module) => {
    if (typeof module === "function") return module(false, linkedOptions);
    return module;
  });
}

function linkAndResolveModules(modules: AtlasContentEditorModule[]) {
  const collectedLinkedOptions = collectLinkedOptions(modules);
  const linkedOptions = computeLinkedOptions(collectedLinkedOptions);
  return resolveModules(modules, linkedOptions);
}

// process modules
// ---------------

/**
 * Resolves all modules and merges their components into flat lists imperatively.
 */
export function processModules(modules: Array<AtlasContentEditorModule>) {
  const resolvedModules = linkAndResolveModules(modules);

  return {
    nodes: resolvedModules
      .flatMap((module) => module.nodes)
      .filter(isNotUndefined),
    plugins: resolvedModules
      .flatMap((module) => module.plugins)
      .filter(isNotUndefined)
      .map((element, index) =>
        // add key to plugin elements to prevent React warnings
        // eslint-disable-next-line react/no-array-index-key
        cloneElement(element, { key: index })
      ),
    themes: resolvedModules
      .flatMap((module) => module.themes)
      .filter(isNotUndefined),
  };
}
