/* eslint-disable import/prefer-default-export */

import { RefObject, useCallback, useMemo, useRef } from "react";

import { useEvent } from "../../../../__utils/react";
import { Button } from "../../../../button/Button";
import Tooltip from "../../../../tooltip/Tooltip";
import type {
  InsertOption,
  InsertOptionContext,
  InsertOptionData,
} from "./types";
import { useInsertOptions } from "./use-insert-options";

type InsertOptionSlotProps = {
  insertOption: InsertOptionData;
  buttonRef: RefObject<HTMLButtonElement>;
};

function InsertOptionSlot({
  insertOption: { key, label, icon, disabled, onTrigger, active },
  buttonRef,
}: InsertOptionSlotProps) {
  return (
    <Tooltip isInstant content={`Insert ${label.toLowerCase()}`}>
      <Button
        ref={buttonRef}
        key={key}
        aria-label={`insert ${label.toLowerCase()}`}
        disabled={disabled}
        isActive={active}
        icon={icon}
        onClick={onTrigger}
      />
    </Tooltip>
  );
}

export function useRenderInsertOptionSlot(
  insertOptions: InsertOption[],
  context: Omit<InsertOptionContext, "target" | "getAnchorRect">,
  target: keyof InsertOptionData["targets"]
) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const cachedAnchorRectRef = useRef<DOMRect>();
  const getAnchorRect = useEvent(() => {
    const currentRect = buttonRef.current?.getBoundingClientRect();
    if (currentRect) {
      cachedAnchorRectRef.current = currentRect;
      return currentRect;
    }
    return cachedAnchorRectRef.current ?? null;
  });

  const slotOptions = useInsertOptions(
    insertOptions,
    useMemo(
      () => ({ ...context, target: "button", getAnchorRect }),
      [context, getAnchorRect]
    ),
    { target }
  );

  const render = slotOptions.map((option) => option.render);

  const renderInsertOptionSlot = useCallback(
    (
      slot: NonNullable<InsertOptionData["targets"]["fixedToolbar"]>["slot"]
    ) => {
      const option = slotOptions.find(
        (o) => o.targets.fixedToolbar?.slot === slot
      );

      if (!option) return undefined;

      return <InsertOptionSlot buttonRef={buttonRef} insertOption={option} />;
    },
    [slotOptions]
  );

  return [renderInsertOptionSlot, render] as const;
}
