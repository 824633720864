import { NotificationForDisplayFragment } from "generated/graphql-codegen/graphql";
import { getHrefForNotification } from "modules/notifications/links";
import { NotificationType } from "modules/notifications/types";
import { ActivityVerb } from "shared/activity/types";

import { NotificationDisplayType } from "./types";

export function mapNotificationFragmentToNotification({
  id,
  activity,
  readAt,
  eventTime,
  data,
  ...props
}: NotificationForDisplayFragment): NotificationDisplayType {
  if (!activity) {
    throw new Error("Notification activity is required");
  }

  const { verb, meta, href, actor, chatMessage } = activity;

  const type = props.type as NotificationType | null;

  return {
    id,
    verb: verb as ActivityVerb,
    meta,
    read: !!readAt,
    href: type ? getHrefForNotification({ type, meta: props.meta }) : href,
    createdAt: eventTime,
    type,
    data,
    actor: actor
      ? {
          id: actor.id,
          name: actor.name,
          avatarUrl: actor.imageUrl || undefined,
        }
      : undefined,
    chatMessage: chatMessage
      ? {
          id: chatMessage.id,
          automatedAction: chatMessage.automatedAction
            ? {
                id: chatMessage.automatedAction.id,
                requireReview: chatMessage.automatedAction.requireReview,
                scheduledAt: chatMessage.automatedAction.scheduledAt,
              }
            : undefined,
        }
      : undefined,
  };
}
