/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $setBlocksType } from "@lexical/selection";
import {
  $createParagraphNode,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
} from "lexical";
import { useMemo, useState } from "react";

import { useEvent } from "../../../../__utils/react";
import { $getSelectionTopNode } from "../lib/utils";

export function useParagraphContext() {
  const [editor] = useLexicalComposerContext();

  const [isParagraph, setIsParagraph] = useState(false);

  const updateListener = useEvent(() => {
    const [topNode] = $getSelectionTopNode(editor);
    if ($isParagraphNode(topNode)) setIsParagraph(true);
    else setIsParagraph(false);
  });

  const toggleParagraph = useEvent(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection))
        $setBlocksType(selection, $createParagraphNode);
    });
  });

  const value = useMemo(
    () => ({ isParagraph, toggleParagraph }),
    [isParagraph, toggleParagraph]
  );

  return [updateListener, value] as const;
}
