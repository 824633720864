import {
  useCalendarSettings,
  useCurrentInterval,
} from "client/components/calendar-v2/hooks/settings";
import { useColumns } from "client/components/calendar-v2/hooks/useColumns";
import { useInterviewerSlotFetchedData } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { useSchedulerGuide } from "client/scheduler/core/hooks/useSchedulerGuide";
import { gql } from "generated/graphql-codegen";
import { InterviewerWorkingHoursForConflictsFragment } from "generated/graphql-codegen/graphql";
import { DateTime, Interval } from "luxon";
import { useMemo } from "react";

import { useSelectedInterviewInterviewerUserMembershipIds } from "../../../hooks/useSelectedInterviewInterviewerUserMembershipIds";
import {
  AVAILABILITY_CANDIDATE_CALENDAR_ID,
  AVAILABILITY_MIN_WINDOW_MINUTES,
  splitEventsByDay,
} from "../utils/helpers";
import {
  AvailabilityInterviewerMap,
  AvailabilityUserMembershipWithWorkingHours,
  CalendarAvailability,
  UnavailableEvent,
} from "../utils/types";

gql(`
  fragment GuideForCandidateAvailability on Guide {
    id
    currentAvailabilitySubmission {
      id
      events {
        id
        startTime
        endTime
      }
    }
  }  
`);

export function useAvailability() {
  const selectedInterviewInterviewerUserMembershipIds =
    useSelectedInterviewInterviewerUserMembershipIds({
      filterToCurrentViewing: true,
    });
  const { timezone } = useCalendarSettings();
  const { columnType, columnIds } = useColumns();
  const currentInterval = useCurrentInterval();
  const guide = useSchedulerGuide();
  const candidateAvailability = useMemo(() => {
    if (!guide?.currentAvailabilitySubmission) {
      return null;
    }

    return guide.currentAvailabilitySubmission.events;
  }, [guide?.currentAvailabilitySubmission]);

  const { interviewers } = useInterviewerSlotFetchedData();
  const interviewerUserMemberships = useMemo(() => {
    return interviewers.map((interviewer) => interviewer.userMembership);
  }, [interviewers]);
  const relevantInterviewerUserMemberships = useMemo(() => {
    if (columnType === "calendar") {
      // For calendar view, we want to show all interviewers
      return interviewerUserMemberships.filter((interviewer) =>
        columnIds.includes(interviewer.id)
      );
    }

    return interviewerUserMemberships.filter((um) =>
      selectedInterviewInterviewerUserMembershipIds.includes(um.id)
    );
  }, [
    columnIds,
    columnType,
    interviewerUserMemberships,
    selectedInterviewInterviewerUserMembershipIds,
  ]);
  const interviewerUserMembershipsWithTimezone = useMemo(() => {
    return relevantInterviewerUserMemberships.filter(
      (
        interviewer
      ): interviewer is AvailabilityUserMembershipWithWorkingHours =>
        !!interviewer.user.timezone
    );
  }, [relevantInterviewerUserMemberships]);
  const candidateAvailabilityEvents = useMemo<CalendarAvailability>(() => {
    if (!candidateAvailability) {
      return null;
    }

    return candidateAvailability.map((e) => ({
      startTime: DateTime.fromISO(e.startTime).setZone("utc"),
      endTime: DateTime.fromISO(e.endTime).setZone("utc"),
    }));
  }, [candidateAvailability]);
  const interviewersMap = useMemo<AvailabilityInterviewerMap>(() => {
    if (!interviewerUserMembershipsWithTimezone) {
      return {};
    }

    return interviewerUserMembershipsWithTimezone.reduce(
      (acc, interviewer): AvailabilityInterviewerMap => {
        return {
          ...acc,
          [interviewer.id]: interviewer,
        };
      },
      {} as AvailabilityInterviewerMap
    );
  }, [interviewerUserMembershipsWithTimezone]);
  const inverseAvailabilityMap = useMemo(() => {
    return {
      [AVAILABILITY_CANDIDATE_CALENDAR_ID]: getInverseAvailability({
        passedAvailability: candidateAvailabilityEvents,
        timezone,
        currentInterval,
      }),
      ...interviewerUserMembershipsWithTimezone?.reduce(
        (acc, interviewer): InverseAvailabilityMap => {
          return {
            ...acc,
            [interviewer.id]: interviewer.workingHours
              ? getInverseAvailability({
                  passedAvailability: getWorkingHourEvents({
                    workingHours: interviewer.workingHours,
                    calendarTimezone: timezone,
                    userTimezone: interviewer.user.timezone,
                    currentInterval,
                  }),
                  currentInterval,
                  timezone,
                })
              : [],
          };
        },
        {} as InverseAvailabilityMap
      ),
    };
  }, [
    candidateAvailabilityEvents,
    currentInterval,
    interviewerUserMembershipsWithTimezone,
    timezone,
  ]);
  const unavailableEvents = useUnavailableEvents({
    inverseAvailabilityMap,
    currentInterval,
  });
  const unavailableEventsByCalendar = useMemo(() => {
    const result: Record<string, UnavailableEvent[]> = {};

    unavailableEvents.forEach((event) => {
      event.calendars.forEach((calendarId) => {
        if (!result[calendarId]) {
          result[calendarId] = [];
        }
        result[calendarId].push(event);
      });
    });

    return result;
  }, [unavailableEvents]);

  return useMemo(
    () => ({
      unavailableEvents,
      unavailableEventsByCalendar,
      interviewersMap,
    }),
    [unavailableEvents, unavailableEventsByCalendar, interviewersMap]
  );
}

/**
 * When converting working hours, there may be discrepancies in the day based on user timezone and viewing timezone
 * We need to add or subtract an offset to the day to account for this
 */
function getDayOffset({
  todayInCalendarTimezone,
  todayInUserTimezone,
}: {
  todayInCalendarTimezone: DateTime;
  todayInUserTimezone: DateTime;
}) {
  const userDayAheadOfCalendarDay =
    todayInCalendarTimezone.day < todayInUserTimezone.day;
  const calendarDayAheadOfUserDay =
    todayInCalendarTimezone.day > todayInUserTimezone.day;
  const calendarAndUserHaveSameMonth =
    todayInCalendarTimezone.month === todayInUserTimezone.month;
  const userMonthAheadOfCalendarMonth =
    todayInCalendarTimezone.month < todayInUserTimezone.month;
  const userMonthBehindCalendarMonth =
    todayInCalendarTimezone.month > todayInUserTimezone.month;

  const userTimezoneDayAheadOfCalendarTimezone = userDayAheadOfCalendarDay
    ? calendarAndUserHaveSameMonth
    : userMonthAheadOfCalendarMonth;

  if (userTimezoneDayAheadOfCalendarTimezone) {
    return -1;
  }

  const calendarTimezoneDayAheadOfUserTimezone = calendarDayAheadOfUserDay
    ? calendarAndUserHaveSameMonth
    : userMonthBehindCalendarMonth;

  if (calendarTimezoneDayAheadOfUserTimezone) {
    return 1;
  }

  return 0;
}

/** Convert interviewer working hours into availability events in the calendar's timezone */
function getWorkingHourEvents({
  workingHours,
  userTimezone,
  calendarTimezone,
  currentInterval,
}: {
  workingHours: InterviewerWorkingHoursForConflictsFragment[];
  userTimezone: string;
  calendarTimezone: string;
  currentInterval: Interval;
}) {
  const { start } = currentInterval;

  // need to also check next monday because of potential timezone offsets
  const workingHoursAndNextMonday = [...workingHours, workingHours[0]];
  const workingHourEvents = workingHoursAndNextMonday.flatMap(
    (workingHour, dayIndex) => {
      if (!workingHour.isWorkingDay) {
        return [];
      }

      const todayInCalendarTimezone = start
        .startOf("week")
        .startOf("day")
        .plus({ days: dayIndex });
      const todayInUserTimezone = todayInCalendarTimezone.setZone(userTimezone);
      const daySwitchingOffset = getDayOffset({
        todayInCalendarTimezone,
        todayInUserTimezone,
      });

      const startTime = todayInUserTimezone.set({
        hour: workingHour.startTime?.hour,
        minute: workingHour.startTime?.minute,
      });
      const endTime = todayInUserTimezone.set({
        hour: workingHour.endTime?.hour,
        minute: workingHour.endTime?.minute,
      });

      return {
        startTime: startTime
          .plus({ day: daySwitchingOffset })
          .setZone(calendarTimezone),
        endTime: endTime
          .plus({ day: daySwitchingOffset })
          .setZone(calendarTimezone),
      };
    }
  );

  const splitEvents = splitEventsByDay(workingHourEvents);

  return splitEvents;
}

/** Given when a person is available, return the intervals where they are unavailable */
function getInverseAvailability({
  passedAvailability,
  timezone,
  currentInterval,
}: {
  passedAvailability: CalendarAvailability;
  timezone: string;
  currentInterval: Interval;
}): Interval[] {
  const { start, end } = currentInterval;
  const intervals: Interval[] = [];

  if (!passedAvailability) {
    return intervals;
  }

  const sortedAvailabilities = [...passedAvailability]
    .sort((a, b) => a.startTime.toMillis() - b.startTime.toMillis())
    .map((a) => ({
      startTime: a.startTime.setZone(timezone),
      endTime: a.endTime.setZone(timezone),
    }));

  let previousEndTime = start;

  sortedAvailabilities.forEach((availability) => {
    if (previousEndTime < availability.startTime) {
      const gapInterval = Interval.fromDateTimes(
        previousEndTime,
        availability.startTime
      );

      // Split the interval by days
      let currentStart = gapInterval.start;
      while (currentStart < gapInterval.end) {
        const currentEnd = DateTime.min(
          currentStart.endOf("day"),
          gapInterval.end
        );
        if (currentStart < end) {
          intervals.push(Interval.fromDateTimes(currentStart, currentEnd));
        }
        currentStart = currentEnd.plus({ milliseconds: 1 }); // Start the next interval at the beginning of the next day
      }
    }

    previousEndTime =
      availability.endTime > previousEndTime
        ? availability.endTime
        : previousEndTime;
  });

  if (previousEndTime < end) {
    const remainingInterval = Interval.fromDateTimes(previousEndTime, end);

    // Split the remaining interval by days
    let currentStart = remainingInterval.start;
    while (currentStart < remainingInterval.end) {
      const currentEnd = DateTime.min(
        currentStart.endOf("day"),
        remainingInterval.end
      );
      intervals.push(Interval.fromDateTimes(currentStart, currentEnd));
      currentStart = currentEnd.plus({ milliseconds: 1 });
    }
  }

  return intervals;
}

type InverseAvailabilityMap = {
  [calendarId: string]: Interval[];
};

/**
 * Given keys of a calendarId and intervals of when that calendar is unavailable,
 * return events with times and the calendars that are unavailable in those intervals
 */
function useUnavailableEvents({
  inverseAvailabilityMap,
  currentInterval,
}: {
  inverseAvailabilityMap: InverseAvailabilityMap;
  currentInterval: Interval;
}) {
  const { includeWeekends, timezone } = useCalendarSettings();

  // find which calendars are unavailable for every 15 minutes in a week
  return useMemo(() => {
    // for every day of the week
    return [...Array(!includeWeekends ? 5 : 7).keys()].reduce(
      (acc, dayIndex) => {
        const today = currentInterval.start
          .setZone(timezone)
          .startOf("week")
          .startOf("day")
          .plus({ days: dayIndex });

        // Create an event for every 15 minute slot in calendar timezone
        const events = [...Array(96).keys()].map((i) => {
          const timeSlot = {
            startTime: today.plus({
              minutes: i * AVAILABILITY_MIN_WINDOW_MINUTES,
            }),
            endTime: today.plus({
              minutes: (i + 1) * AVAILABILITY_MIN_WINDOW_MINUTES,
            }),
          };

          // get list of calendars that are unavailable in this time slot
          const calendars = Object.keys(inverseAvailabilityMap).filter(
            (calendar) => {
              return inverseAvailabilityMap[calendar].some((interval) => {
                return interval.overlaps(
                  Interval.fromDateTimes(timeSlot.startTime, timeSlot.endTime)
                );
              });
            }
          );

          return {
            ...timeSlot,
            calendars,
          };
        });

        return [
          ...acc,
          // filter out events that have no calendars
          ...events.flatMap((event) => {
            if (event.calendars?.length === 0) {
              return [];
            }

            return {
              startTime: event.startTime.setZone(timezone),
              endTime: event.endTime.setZone(timezone),
              calendars: event.calendars,
            };
          }),
        ];
      },
      [] as UnavailableEvent[]
    );
  }, [
    currentInterval.start,
    includeWeekends,
    inverseAvailabilityMap,
    timezone,
  ]);
}
