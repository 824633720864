import Badge from "@resource/atlas/badge/Badge";
import {
  atlasCelebrate,
  atlasPersonCheck,
  atlasThumbsDown,
  atlasThumbsUp,
} from "@resource/atlas/icons";
import { GuideStatusEnum } from "generated/graphql-codegen/graphql";

interface HiringDecisionBadgeProps {
  hiringDecision: GuideStatusEnum;
}

function HiringDecisionBadge({ hiringDecision }: HiringDecisionBadgeProps) {
  switch (hiringDecision) {
    case GuideStatusEnum.ACTIVE:
      return (
        <Badge icon={atlasPersonCheck} styles="bold" size="small">
          Active
        </Badge>
      );
    case GuideStatusEnum.HIRED:
      return (
        <Badge
          icon={atlasThumbsUp}
          variant="success"
          styles="bold"
          size="small"
        >
          Hired
        </Badge>
      );
    case GuideStatusEnum.REJECTED:
      return (
        <Badge
          icon={atlasThumbsDown}
          variant="danger"
          styles="bold"
          size="small"
        >
          Rejected
        </Badge>
      );
    case GuideStatusEnum.OFFER:
      return (
        <Badge
          icon={atlasCelebrate}
          className="!bg-purple-500"
          styles="bold"
          size="small"
        >
          Offer
        </Badge>
      );
    default:
      return null;
  }
}

export default HiringDecisionBadge;
