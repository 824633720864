import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CalendarContextValue } from "./context";
import {
  calendarSelectedDayAtom,
  useCalendarSettings,
  useCurrentInterval,
} from "./settings";
import { CalendarEvent, ScrollToTime } from "./types";
import { MINUTES_IN_DAY, MINUTES_IN_HOUR, useCalendarSizes } from "./utils";

export type CalendarStateProps = {
  /** @default: 15 */
  minInterval?: number;
};

export type CalendarState = Omit<
  CalendarContextValue,
  "events" | "allDayEvents" | "interactive"
>;

export function useCalendarState(
  props: CalendarStateProps = {}
): CalendarState {
  const { minInterval = 15 } = props;

  const { timezone, includeWeekends, view } = useCalendarSettings();
  const currentInterval = useCurrentInterval();
  const [selectedDay, setSelectedDay] = useAtom(calendarSelectedDayAtom);

  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [eventsViewRef, setEventsViewRef] = useState<HTMLOListElement | null>(
    null
  );
  const [calculatedEventsViewSize, setCalculatedEventsViewSize] = useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);
  const [hoveringEventId, setHoveringEventId] = useState<string | null>(null);
  const [hoveringTriggerElement, setHoveringTriggerElement] =
    useState<HTMLDivElement | null>(null);
  const [focusedEventId, setFocusedEventId] = useState<string | null>(null);
  const [
    eventDetailsPopoverTriggerElement,
    setEventDetailsPopoverTriggerElement,
  ] = useState<HTMLDivElement | null>(null);
  const eventDetailsPopoverState = usePopoverState({
    getAnchorRect: () => {
      if (!eventDetailsPopoverTriggerElement) {
        return null;
      }

      return eventDetailsPopoverTriggerElement.getBoundingClientRect();
    },
    placement: "top-start",
  });
  const calendarSizes = useCalendarSizes();

  const calculateEventsViewSize = useCallback(() => {
    if (!eventsViewRef) {
      console.warn("Events view ref not defined");
      return;
    }

    setCalculatedEventsViewSize({
      height: eventsViewRef.scrollHeight,
      width: eventsViewRef.scrollWidth - calendarSizes.rightOffsetWidth.px,
    });
  }, [eventsViewRef, calendarSizes.rightOffsetWidth.px]);

  useEffect(() => {
    calculateEventsViewSize();

    const resizeObserver = new ResizeObserver(() => {
      calculateEventsViewSize();
    });

    if (eventsViewRef) {
      resizeObserver.observe(eventsViewRef);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateEventsViewSize, eventsViewRef]);

  const calculateScrollValueForTime = useCallback(
    (time: DateTime) => {
      const minutesFromBeginningOfDay =
        time.hour * MINUTES_IN_HOUR + time.minute;

      const scrollValue =
        (calculatedEventsViewSize.height * minutesFromBeginningOfDay) /
        MINUTES_IN_DAY;

      return scrollValue;
    },
    [calculatedEventsViewSize.height]
  );

  const dayCount = useMemo(() => {
    if (view === "week") {
      return includeWeekends ? 7 : 5;
    }

    return 1;
  }, [includeWeekends, view]);

  const dayWidth = useMemo(() => {
    return calculatedEventsViewSize.width / dayCount;
  }, [calculatedEventsViewSize.width, dayCount]);

  const calculateTimeForOffset = useCallback(
    (offset: { x: number; y: number }) => {
      const safeDayWidth = dayWidth > 0 ? dayWidth : 1;
      const days = Math.floor(offset.x / safeDayWidth) + 1;
      const safeEventsViewHeight =
        calculatedEventsViewSize.height > 0
          ? calculatedEventsViewSize.height
          : 1;
      const minutesFromBeginningOfDay =
        (offset.y * MINUTES_IN_DAY) / safeEventsViewHeight;

      const hours = Math.floor(minutesFromBeginningOfDay / 60);
      const minutes = Math.floor(minutesFromBeginningOfDay % 60);

      // Take the start of the period and add the number of days we're offset by
      const calculatedDay = selectedDay.startOf(view).plus({ days: days - 1 });
      const exactTime = calculatedDay.set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
      });

      // Use Math.floor to round down to the nearest interval
      // But give 15% of the minInterval as a buffer in case they go slightly higher
      const buffer = minInterval * 0.2;
      const minutesWithBuffer = minutes + buffer;

      const roundedToNearestInterval = exactTime.set({
        minute: Math.floor(minutesWithBuffer / minInterval) * minInterval,
      });

      return roundedToNearestInterval;
    },
    [dayWidth, calculatedEventsViewSize.height, selectedDay, view, minInterval]
  );

  const calculateMinutesFromDifference = useCallback(
    (y: number) => {
      const diff = (y * MINUTES_IN_DAY) / calculatedEventsViewSize.height;

      return Math.floor(diff / minInterval) * minInterval;
    },
    [calculatedEventsViewSize.height, minInterval]
  );

  const scrollToTime = useCallback<ScrollToTime>(
    ({
      time: inputTime,
      hoursPadding = 0,
    }: {
      time: DateTime | string;
      hoursPadding?: number;
    }) => {
      const time =
        typeof inputTime === "string"
          ? DateTime.fromISO(inputTime).setZone(timezone)
          : inputTime;

      const scrollValue = calculateScrollValueForTime(
        time.minus({
          hours: hoursPadding,
        })
      );

      const currentWeek = time.startOf("day");
      setSelectedDay(currentWeek);

      if (wrapperRef) {
        wrapperRef.scrollTo({
          top: scrollValue,
          behavior: "smooth",
        });
      }
    },
    [calculateScrollValueForTime, setSelectedDay, timezone, wrapperRef]
  );

  const calculateOffsetFromEvent = useCallback(
    (e: { clientX: number; clientY: number }) => {
      if (!eventsViewRef) {
        console.warn("Events view ref not defined");
        return {
          x: 0,
          y: 0,
        };
      }

      const { x, y } = eventsViewRef.getBoundingClientRect();

      return {
        x: e.clientX - x,
        y: e.clientY - y,
      };
    },
    [eventsViewRef]
  );

  const isEventInView = useCallback(
    (event: Pick<CalendarEvent, "startTime" | "endTime" | "allDay">) => {
      let hideEvent = false;

      if (!includeWeekends) {
        const dayOfWeek = event.startTime.weekday;

        if (dayOfWeek < 1 || dayOfWeek > 5) {
          hideEvent = true;
        }
      }

      if (
        !event.allDay &&
        !currentInterval.contains(event.startTime) &&
        !currentInterval.contains(event.endTime)
      ) {
        hideEvent = true;
      }

      if (event.allDay && !currentInterval.contains(event.startTime)) {
        hideEvent = true;
      }

      return !hideEvent;
    },
    [currentInterval, includeWeekends]
  );

  // make sure selected week timezone matches the current timezone
  useEffect(() => {
    if (selectedDay.zoneName !== timezone) {
      setSelectedDay(selectedDay.setZone(timezone));
    }
  }, [selectedDay, setSelectedDay, timezone]);

  const state = useMemo(
    (): CalendarState => ({
      isEventInView,
      calculateScrollValueForTime,
      wrapperRef,
      headerRef,
      setWrapperRef,
      setHeaderRef,
      scrollToTime,
      focusedEventId,
      setFocusedEventId,
      eventDetailsPopoverTriggerElement,
      setEventDetailsPopoverTriggerElement,
      eventDetailsPopoverState,
      calculateTimeForOffset,
      calculateMinutesFromDifference,
      dayWidth,
      eventsViewRef,
      setEventsViewRef,
      calculateOffsetFromEvent,
      hoveringEventId,
      setHoveringEventId,
      hoveringTriggerElement,
      setHoveringTriggerElement,
    }),
    [
      isEventInView,
      calculateScrollValueForTime,
      wrapperRef,
      headerRef,
      scrollToTime,
      focusedEventId,
      eventDetailsPopoverTriggerElement,
      eventDetailsPopoverState,
      calculateTimeForOffset,
      calculateMinutesFromDifference,
      dayWidth,
      eventsViewRef,
      calculateOffsetFromEvent,
      hoveringEventId,
      hoveringTriggerElement,
    ]
  );

  return state;
}
