import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { PostMessageWorkflow } from "shared/message-composer/types";

export const usePostMessageDialogForWorkflowProps = ({
  workflowType,
  guideId,
}: {
  workflowType?: PostMessageWorkflow | null;
  guideId: string;
}) => {
  const store = useDialogStore();

  return {
    props: {
      workflowType: workflowType ?? undefined,
      guideId,
    },
    store,
  };
};
