import { ScheduledInterviewCardBadge } from "client/components/scheduled-interviews/__components/ScheduledInterviewCardBadge";
import {
  ScheduledInterviewCard,
  ScheduledInterviewCardProps,
} from "client/components/scheduled-interviews/ScheduledInterviewCard/ScheduledInterviewCard";
import { mapHistoricalScheduledInterviewToScheduledInterviewCardData } from "client/historical-data/scheduled-interviews/mapping";
import { HistoricalScheduledInterview } from "shared/historical-data/scheduled-interviews/types";

/** Scheduled interview card for displaying historical interviews */
export function HistoricalScheduledInterviewCard({
  historicalScheduledInterview,
  ...rest
}: {
  historicalScheduledInterview: HistoricalScheduledInterview;
} & Pick<ScheduledInterviewCardProps, "Badge" | "showAsPast" | "variant">) {
  const mappedScheduledInterview =
    mapHistoricalScheduledInterviewToScheduledInterviewCardData(
      historicalScheduledInterview
    );

  return (
    <ScheduledInterviewCard
      {...rest}
      scheduledInterview={mappedScheduledInterview}
      Badge={
        mappedScheduledInterview.isCancelled ? (
          <ScheduledInterviewCardBadge status="cancelled" />
        ) : undefined
      }
      showAsPast={mappedScheduledInterview.isCancelled}
      hideInternalStatuses
      showShadowingStatus
    />
  );
}
