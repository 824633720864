import {
  CollaborativeCodingSlotSchema,
  StagedCollaborativeCodingSlotSchema,
} from "client/components/collaborative-coding/utils/types";
import {
  ConferenceRoomSlotSchema,
  StagedConferenceRoomSlotSchema,
} from "client/components/conference-rooms/utils/types";
import {
  ConferencingSlotSchema,
  StagedConferencingSlotSchema,
} from "client/components/conferencing/utils/types";
import {
  InterviewerSlotSchema,
  StagedInterviewerSlotSchema,
} from "client/components/interviewer-slots/utils/types";
import { ScheduledInterviewFieldChange } from "shared/guide-scheduler/field-changes/types";
import { GlobalUsers } from "shared/utils/global-users";
import { getTitleFromLexicalJson } from "shared/utils/lexical";
import { z } from "zod";

const UserValidationMessages = {
  titleRequired: "Title is required.",
  timeRequired: "Start and end time is required.",
  interviewerRequired: "At least one interviewer must be selected.",
  placeholderInterviewers: "Placeholder interviewers must be replaced.",
};

const CreatingFromInterviewSchema = z.object({
  id: z.string(),
  title: z.string(),
});

const CreatingFromInterviewRequirementSchema = z.object({
  id: z.string(),
});

const ScheduledInterviewFieldChangeDataSchema = z.object({
  fieldName: z.nativeEnum(ScheduledInterviewFieldChange),
  oldValue: z.any(),
  newValue: z.any(),
});

export type ScheduledInterviewFieldChangeData = z.infer<
  typeof ScheduledInterviewFieldChangeDataSchema
>;

export const UpsertScheduledInterviewSchema = z.object({
  id: z.string(),
  title: z.string().refine(
    (title) => {
      if (title.trim() === "" || getTitleFromLexicalJson(title).trim() === "") {
        return false;
      }

      return true;
    },
    {
      message: UserValidationMessages.titleRequired,
    }
  ),
  startTime: z.string().nullable(),
  endTime: z.string().nullable(),
  duration: z.number(),
  interviewerSlots: z.array(InterviewerSlotSchema),
  conferencingSlot: ConferencingSlotSchema.nullable(),
  conferenceRoomSlot: ConferenceRoomSlotSchema.nullable(),
  collaborativeCodingSlot: CollaborativeCodingSlotSchema.nullable(),
  interview: CreatingFromInterviewSchema.optional().nullable(),
  interviewRequirement:
    CreatingFromInterviewRequirementSchema.optional().nullable(),
  isSelfScheduled: z.boolean().optional().nullable(),
  isPrivate: z.boolean(),
  fieldChanges: ScheduledInterviewFieldChangeDataSchema.array().optional(),
  googleEventId: z.string().nullable(),
  isCancelled: z.boolean(),
  isSchedulable: z.boolean().optional(),
  unschedulableErrorMessage: z.string().optional().nullable(),
});

export type UpsertScheduledInterview = z.infer<
  typeof UpsertScheduledInterviewSchema
>;

export const StagedScheduledInterviewSchema = z.object({
  ...UpsertScheduledInterviewSchema.shape,
  startTime: z.string({
    required_error: UserValidationMessages.timeRequired,
  }),
  endTime: z.string({
    required_error: UserValidationMessages.timeRequired,
  }),
  interviewerSlots: z
    .array(StagedInterviewerSlotSchema)
    .min(1, { message: UserValidationMessages.interviewerRequired })
    .refine(
      (slots) =>
        !slots.some(
          (slot) =>
            slot.interviewer?.userMembership.user.id ===
              GlobalUsers.InterviewerPlaceholder.userId ||
            slot.shadowingInterviewer?.userMembership.user.id ===
              GlobalUsers.InterviewerPlaceholder.userId
        ),
      {
        message: UserValidationMessages.placeholderInterviewers,
      }
    ),
  conferenceRoomSlot: StagedConferenceRoomSlotSchema.nullable(),
  collaborativeCodingSlot: StagedCollaborativeCodingSlotSchema.nullable(),
  conferencingSlot: StagedConferencingSlotSchema.nullable(),
});

export type StagedScheduledInterview = z.infer<
  typeof StagedScheduledInterviewSchema
>;

export const UpsertScheduledInterviewFormSchema = z.object({
  scheduledInterview: UpsertScheduledInterviewSchema,
});

export type UpsertScheduledInterviewFormData = z.infer<
  typeof UpsertScheduledInterviewFormSchema
>;

export const StagedScheduledInterviewFormSchema = z.object({
  scheduledInterview: StagedScheduledInterviewSchema,
});

export type StagedScheduledInterviewFormData = z.infer<
  typeof StagedScheduledInterviewFormSchema
>;
