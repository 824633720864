import { GuideForComposeMessageFormFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

export const useDefaultRecipientsForGuide = ({
  guide,
}: {
  guide: GuideForComposeMessageFormFragment;
}) => {
  return useMemo(() => {
    return [
      ...(guide.recruiter?.receiveNotificationsWhenRecruiter
        ? [
            {
              ...guide.recruiter,
              isCandidate: false,
            },
          ]
        : []),
      ...(guide.coordinator?.receiveNotificationsWhenCoordinator
        ? [
            {
              ...guide.coordinator,
              isCandidate: false,
            },
          ]
        : []),
      ...(guide?.openTaskAssignees.map((assignee) => ({
        ...assignee,
        isCandidate: false,
      })) || []),
    ];
  }, [guide.recruiter, guide.coordinator, guide?.openTaskAssignees]);
};
