import { useTextField } from "@react-aria/textfield";
import {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactElement,
  useRef,
} from "react";
import mergeRefs from "react-merge-refs";

import { getPropsWithDefaults, useOptionalRef } from "../__utils/__deprecated";
import TextFieldBase from "./TextFieldBase";
import { AtlasTextAreaProps } from "./types";

// config
// ------

const DEFAULT_PROPS = {} as const;

// component
// ---------

function TextAreaComponent(
  { inputRef: inputRefProp, ...props }: AtlasTextAreaProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = getPropsWithDefaults(props, DEFAULT_PROPS);
  const domRef = useOptionalRef(ref);
  const inputRef = mergeRefs<HTMLTextAreaElement>([
    useRef<HTMLTextAreaElement>(null) as MutableRefObject<HTMLTextAreaElement>,
    // I hate ref types and I hate my life and I'll refactor later leave me alone
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRefProp as any,
  ]);

  // behavior
  // --------

  const { labelProps, inputProps } = useTextField(
    {
      ...p,
      inputElementType: "textarea",
      // TODO: there must be a better way...
      type: p.type === "button" ? "text" : p.type, // fix tooltip
    },
    // I hate ref types and I hate my life and I'll refactor later leave me alone
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRef as any
  );

  return (
    <TextFieldBase
      ref={domRef}
      {...p} // pass properties down to base element
      labelProps={labelProps}
      inputProps={inputProps}
      inputRef={inputRef}
      isMultiLine
    />
  );
}

/**
 * TextAreas are multiline text inputs, useful for cases where users have
 * a sizable amount of text to enter.
 */
const TextArea = forwardRef<HTMLDivElement, AtlasTextAreaProps>(
  TextAreaComponent
);

export default TextArea;
