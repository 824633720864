/* eslint-disable import/prefer-default-export */
import {
  CompositeStateProps,
  PopoverStateProps,
  SelectStateProps,
  useComboboxState as useAriakitComboboxState,
  useSelectState as useAriakitSelectState,
} from "ariakit";
import { useMemo } from "react";

import {
  SHARED_COMPOSITE_STATE_DEFAULTS,
  SHARED_POPOVER_STATE_DEFAULTS,
} from "../__utils/atlas";
import { createDefaultProps, useStaticValueError } from "../__utils/react";
import type { AtlasSelectState, AtlasSelectStateProps } from "./types";

const DEFAULT_PROPS = createDefaultProps<AtlasSelectStateProps>()({
  ...SHARED_POPOVER_STATE_DEFAULTS,
  ...SHARED_COMPOSITE_STATE_DEFAULTS,
} as const);

/**
 * Creates a select state.
 *
 * @example
 * const select = useSelectState();
 * return <Select.Root state={select}>...</Select.Root>;
 */
export function useSelectState({
  placement = DEFAULT_PROPS.placement,
  animated = DEFAULT_PROPS.animated,
  gutter = DEFAULT_PROPS.gutter,
  overflowPadding = DEFAULT_PROPS.overflowPadding,
  focusLoop = DEFAULT_PROPS.focusLoop,
  searchable = false,
  defaultValue,
  value,
  setValue,
  comboboxProps,
  ...props
}: AtlasSelectStateProps = {}) {
  useStaticValueError(
    searchable,
    'The value for "searchable" cannot change after the initial render of Select'
  );

  // passed to either Select or Combobox, depending on the value of searchable
  const baseStateProps: CompositeStateProps & PopoverStateProps = {
    placement,
    animated,
    gutter,
    overflowPadding,
    focusLoop,
    ...props,
  };

  const baseSelectStateProps: SelectStateProps = {
    defaultValue,
    value,
    setValue,
  };

  const combobox = useAriakitComboboxState(
    // the combobox state is not used unless searchable
    searchable
      ? { includesBaseElement: false, ...baseStateProps, ...comboboxProps }
      : undefined
  );

  // omit `value` and `setValue` since they are different things in select
  const { value: _, setValue: __, ...comboboxRest } = combobox;

  const ariakitSelect = useAriakitSelectState(
    // if searchable, we need to sync the select state with the combobox state
    { ...(searchable ? comboboxRest : baseStateProps), ...baseSelectStateProps }
  );

  const select: AtlasSelectState = useMemo(
    () => ({ ...ariakitSelect, value: value ?? "", searchable, combobox }),
    [ariakitSelect, combobox, searchable, value]
  );

  return select;
}
