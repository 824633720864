import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  NotificationTypeMetaFields,
  NotificationTypeResolvedFields,
} from "modules/notifications/schemas";
import { NotificationType } from "modules/notifications/types";

export type AppNotificationRenderer<T extends NotificationType> = {
  render: (
    props: NotificationTypeResolvedFields<T> & {
      meta: NotificationTypeMetaFields<T>;
    }
  ) => JSX.Element;
  avatar?: (
    props: NotificationTypeResolvedFields<T>
  ) => NotificationAvatar | null | undefined;
};

export type NotificationAvatar = {
  name?: string;
  avatarUrl?: string | null;
  icon?: AtlasIconData;
  iconClass?: string;
};

export function createAppNotificationRenderer<T extends NotificationType>({
  type,
  ...renderer
}: {
  type: T;
} & AppNotificationRenderer<T>) {
  return renderer;
}
