import { useContentEditor } from "@resource/atlas/content-editor/use-content-editor";
import { useAuthContext } from "auth/context";
import { useGuideAboutValueSet } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/content-editor/use-guide-about-value-set";
import { GuideAboutEditor } from "client/components/guide-content";
import { SerializedEditorState } from "lexical";
import isEqual from "lodash/isEqual";
import { useEffect } from "react";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";

import { InterviewPlanItem } from "./types";

type InterviewStageContentEditorProps = {
  content: { data: unknown } | null;
  updateStage: (newProps: Partial<InterviewPlanItem>) => unknown;
  currentViewingStageId: string | null;
};

export function InterviewStageContentEditor({
  content,
  updateStage,
  currentViewingStageId,
}: InterviewStageContentEditorProps) {
  const { user } = useAuthContext();
  const { editor, contentEditorProps } = useContentEditor();
  const defaultContentData =
    (content?.data as SerializedEditorState) ??
    (JSON.parse(LEXICAL_EMPTY_STATE) as SerializedEditorState);

  useEffect(() => {
    editor.setEditorState(editor.parseEditorState(defaultContentData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewingStageId]);

  const valueSet = useGuideAboutValueSet({
    valueSet: {
      companyName: user?.currentOrganization?.name,
    },
  });

  return (
    <div className="flex flex-col overflow-auto shadow-1 rounded-md p-4">
      <GuideAboutEditor
        className="grow flex flex-col overflow-auto min-h-[10rem] mx-auto w-full"
        valueSet={valueSet}
        {...contentEditorProps}
        onChange={(...editorProps) => {
          contentEditorProps?.onChange?.(...editorProps);

          if (!isEqual(editor.getEditorState().toJSON(), defaultContentData)) {
            updateStage({
              interviewStageContent: {
                // if we have changed the content, we set ID to null so we create a new content server side
                id: null,
                data: editor.getEditorState().toJSON(),
              },
            });
          }
        }}
      />
    </div>
  );
}
