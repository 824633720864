import { Button } from "@resource/atlas/button/Button";
import { Dialog } from "@resource/atlas/dialog";
import { AtlasDialogState } from "@resource/atlas/dialog/types";
import { formatEntity } from "shared/constants/entities";

type AlreadyOpenTaskDialogProps = {
  state: AtlasDialogState;
  onCreateNewTask: () => void;
  onTakeMeToOpenTask: () => void;
};

export function AlreadyOpenTaskDialog({
  state,
  onCreateNewTask,
  onTakeMeToOpenTask,
}: AlreadyOpenTaskDialogProps) {
  return (
    <Dialog.Root state={state}>
      <Dialog.Content
        size="small"
        footer={{
          rightActions: (
            <>
              <Button variant="default" isGhost onClick={onCreateNewTask}>
                Create a new {formatEntity("request")}
              </Button>
              <Button
                variant="primary"
                isGhost={false}
                onClick={onTakeMeToOpenTask}
              >
                Take me to open {formatEntity("request")}
              </Button>
            </>
          ),
        }}
      >
        <div className="space-y-3">
          <p className="text-body-md inline-block">
            There is already an open scheduling {formatEntity("request")} for
            this candidate. Would you like to create a new scheduling{" "}
            {formatEntity("request")} or go to that {formatEntity("request")}?
          </p>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
