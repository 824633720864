import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { useAuthContext } from "auth/context";
import { VideoConferencingSelect } from "client/components/generic/select/VideoConferencingSelect";
import { VideoConferencingServiceType } from "generated/graphql-codegen/graphql";
import { ChangeEvent, useCallback, useMemo } from "react";

type SchedulingRequestConferencingSettingsProps = {
  requireVideoConferencing: VideoConferencingServiceType | null;
  setRequireVideoConferencing: (
    value: VideoConferencingServiceType | null
  ) => unknown;
  reuseVideoConferencingLink: boolean;
  setReuseVideoConferencingLink: React.Dispatch<React.SetStateAction<boolean>>;
};

export function SchedulingRequestConferencingSettings({
  requireVideoConferencing,
  setRequireVideoConferencing,
  reuseVideoConferencingLink,
  setReuseVideoConferencingLink,
}: SchedulingRequestConferencingSettingsProps) {
  const { user } = useAuthContext();
  const zoomEnabled = user?.currentOrganization?.isZoomEnabled ?? false;
  const googleCalendarEnabled =
    user?.currentOrganization?.isGoogleCalendarEnabled ?? false;
  const googleMeetApiEnabled = user?.canAccessGoogleMeet ?? false;

  const defaultService = useMemo(() => {
    if (zoomEnabled) {
      return VideoConferencingServiceType.ZOOM;
    }
    if (googleCalendarEnabled) {
      return VideoConferencingServiceType.GOOGLE_MEET;
    }
    return null;
  }, [zoomEnabled, googleCalendarEnabled]);

  const canReuseLink = useCallback(
    (type: VideoConferencingServiceType) => {
      if (type === VideoConferencingServiceType.GOOGLE_MEET) {
        return googleMeetApiEnabled;
      }
      return type === VideoConferencingServiceType.ZOOM;
    },
    [googleMeetApiEnabled]
  );

  const onChangeVideoConferencingService = useCallback(
    (value: VideoConferencingServiceType) => {
      setRequireVideoConferencing(value);
      if (!canReuseLink(value)) {
        setReuseVideoConferencingLink(false);
      }
    },
    [canReuseLink, setRequireVideoConferencing, setReuseVideoConferencingLink]
  );

  const onToggleRequireVideoConferencing = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) {
        setRequireVideoConferencing(defaultService);
      } else {
        setRequireVideoConferencing(null);
      }
    },
    [setRequireVideoConferencing, defaultService]
  );

  if (!zoomEnabled && !googleCalendarEnabled) {
    return null;
  }

  return (
    <>
      <div className="flex space-x-2">
        <Checkbox
          checked={requireVideoConferencing !== null}
          onChange={onToggleRequireVideoConferencing}
        />
        <span className="text-body-md">
          Require video conferencing for all interviews
        </span>
      </div>
      {requireVideoConferencing !== null && (
        <div className="flex flex-col ml-7 gap-2">
          <VideoConferencingSelect
            value={requireVideoConferencing}
            setValue={onChangeVideoConferencingService}
          />
          {canReuseLink(requireVideoConferencing) && (
            <div className="flex space-x-2 items-center">
              <Checkbox
                checked={reuseVideoConferencingLink}
                onChange={(v) =>
                  setReuseVideoConferencingLink(v.currentTarget.checked)
                }
              />
              <div className="text-body-md">
                Generate the same link across all interviews.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
