import { SelectV2, useSelectItems } from "@resource/atlas/select-v2";
import { range } from "lodash";
import { DateTime, HourNumbers, MinuteNumbers } from "luxon";

export type Time = { hour: HourNumbers; minute: MinuteNumbers };

const selectValueForTime = ({
  hour,
  minute,
}: {
  hour: number;
  minute?: number;
}) => {
  return `${hour * 60 + (minute ?? 0)}`;
};

type TimeSelectProps = {
  placement?: "top" | "bottom";
  value: Time;
  onChange: (value: Time) => void;
  className?: string;
};

// Standard minute options to show in the dropdown
const STANDARD_MINUTE_OPTIONS = [0, 15, 30, 45];

/** Select a time from a dropdown */
export function TimeSelect({
  value,
  onChange,
  className,
  placement,
}: TimeSelectProps) {
  const items = useSelectItems(
    (i) => {
      const now = DateTime.now();

      // Check if the current value's minute is not in our standard options
      const currentMinute = value.minute;
      const isNonStandardMinute =
        !STANDARD_MINUTE_OPTIONS.includes(currentMinute);

      return range(0, 24)
        .map((hour) => {
          // For each hour, create the standard minute options
          let times = STANDARD_MINUTE_OPTIONS.map((minute) => ({
            hour: hour as HourNumbers,
            minute: minute as MinuteNumbers,
          }));

          // If the current hour matches the selected hour and the minute is non-standard,
          // include the current value in the options for this hour
          if (isNonStandardMinute && hour === value.hour) {
            times = [
              ...times,
              {
                hour: value.hour,
                minute: value.minute,
              },
            ];

            // Sort the times to ensure they appear in chronological order
            times.sort((a, b) => a.minute - b.minute);
          }

          return times.map((time) =>
            i.option({
              key: selectValueForTime(time),
              value: selectValueForTime(time),
              isSelectable: true,
              onSelect: () => {
                onChange(time);
              },
              children: now.set(time).toLocaleString(DateTime.TIME_SIMPLE),
            })
          );
        })
        .flat();
    },
    [onChange, value]
  );

  return (
    <SelectV2.Root
      placement={placement}
      value={selectValueForTime(value)}
      setValue={(val) => {
        // convert val which is a string of minutes into an object with hour and minutes
        const numMinutes = Number(val);
        const hour = Math.floor(numMinutes / 60);
        const minutes = numMinutes % 60;

        onChange({
          hour: hour as HourNumbers,
          minute: minutes as MinuteNumbers,
        });
      }}
    >
      <SelectV2.Trigger className={className} />
      <SelectV2.Content items={items} isVirtual className="w-[140px]" />
    </SelectV2.Root>
  );
}
