import { z } from "zod";

export type Event = {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
};

export const EventInputSchema = z.object({
  title: z.string(),
  startTime: z.string(),
  endTime: z.string(),
});

export type EventInput = z.infer<typeof EventInputSchema>;

export const AvailabilityRequestInputSchema = z.object({
  suggestions: z.array(
    z.object({
      title: z.string(),
      startTime: z.string(),
      endTime: z.string(),
    })
  ),
  notes: z.string().nullable().optional(),
});

export const AvailabilityRequestDataSchema = z.object({
  availabilityRequestId: z.string(),
  createdBy: z.object({
    id: z.string(),
    name: z.string(),
    imageUrl: z.string().nullable(),
  }),
  originalInputData: AvailabilityRequestInputSchema,
});

export type AvailabilityRequestInput = z.infer<
  typeof AvailabilityRequestInputSchema
>;

export const AvailabilitySubmissionInputSchema = z.object({
  availabilityRequestId: z.string().nullable().optional(),
  events: z.array(EventInputSchema),
  notes: z.string().nullable().optional(),
  schedulingPreference: z.enum(["FLEXIBLE", "SAME_DAY"]),
});

export type AvailabilitySubmissionInput = z.infer<
  typeof AvailabilitySubmissionInputSchema
>;

export const AvailabilityRequestPreviewDataSchema =
  AvailabilityRequestInputSchema.extend({
    preview: z.literal(true),
    createdBy: z.object({
      id: z.string(),
      name: z.string(),
      imageUrl: z.string().nullable(),
    }),
  });

export type AvailabilityRequestPreviewData = z.infer<
  typeof AvailabilityRequestPreviewDataSchema
>;
