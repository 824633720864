import { InterviewerSlot } from "client/components/interviewer-slots/utils/types";
import {
  CancellationReasonInput,
  CancellationReasonNoRescheduleDataInput,
  InterviewerDeclineItemInput,
  InterviewerNoShowItemInput,
  InterviewerResponse,
  InterviewerSwapReasonInput,
  RescheduleInterviewRequirementForMappingFragment,
  RescheduleReasonInput,
  UpdateReasonInput,
} from "generated/graphql-codegen/graphql";

import { InterviewerMultiSelectItem } from "../__components/InterviewerMultiSelect";
import {
  CancellationReason,
  CancellationReasonNoRescheduleData,
  InterviewerDecline,
  InterviewerDeclineSchema,
  InterviewerNoShow,
  InterviewerNoShowSchema,
  InterviewerSwapReason,
  RescheduleReason,
  ScheduledInterviewRescheduleReason,
  UpdateReason,
} from "./types";

export function mapInterviewerGroupsToInterviewerMultiSelect(
  interviewerSlots: InterviewerSlot[]
): InterviewerMultiSelectItem[] {
  return interviewerSlots.flatMap((slot) => {
    const items: InterviewerMultiSelectItem[] = [];

    if (slot.interviewer) {
      items.push({
        userMembershipId: slot.interviewer.userMembership.id,
        name: slot.interviewer.userMembership.name,
        imageUrl: slot.interviewer.userMembership.imageUrl,
        email: slot.interviewer.userMembership.email,
        responseStatus: slot.interviewer.responseStatus,
      });
    }

    if (slot.shadowingInterviewer) {
      items.push({
        userMembershipId: slot.shadowingInterviewer.userMembership.id,
        name: slot.shadowingInterviewer.userMembership.name,
        imageUrl: slot.shadowingInterviewer.userMembership.imageUrl,
        email: slot.shadowingInterviewer.userMembership.email,
        responseStatus: slot.shadowingInterviewer.responseStatus,
      });
    }

    return items;
  });
}

export function mapInterviewersToInterviewerMultiSelect(
  interviewers: {
    id: string;
    responseStatus?: InterviewerResponse | null;
    userMembership: {
      id: string;
      name: string;
      imageUrl: string | null;
      email: string;
    };
  }[]
): InterviewerMultiSelectItem[] {
  return interviewers.map((interviewer) => ({
    userMembershipId: interviewer.userMembership.id,
    name: interviewer.userMembership.name,
    imageUrl: interviewer.userMembership.imageUrl,
    email: interviewer.userMembership.email,
    responseStatus: interviewer.responseStatus,
  }));
}

export function mapRescheduleInterviewRequirementFragmentToRescheduleReason({
  reason,
  interviewerNoShows,
  interviewerDeclines,
  otherReason,
  additionalText,
}: RescheduleInterviewRequirementForMappingFragment): RescheduleReason {
  const mappedInterviewerNoShows = InterviewerNoShowSchema.safeParse(
    interviewerNoShows
  ).success
    ? InterviewerNoShowSchema.parse(interviewerNoShows)
    : [];
  const mappedInterviewerDeclines = InterviewerDeclineSchema.safeParse(
    interviewerDeclines
  ).success
    ? InterviewerDeclineSchema.parse(interviewerDeclines)
    : [];

  return {
    reason:
      reason ??
      ScheduledInterviewRescheduleReason.CANDIDATE_REQUESTED_RESCHEDULE,
    interviewerNoShows: mappedInterviewerNoShows,
    interviewerDeclines: mappedInterviewerDeclines,
    otherReason,
    additionalText,
  };
}

function mapInterviewerDeclinesToInput(
  interviewerDeclines: InterviewerDecline
): InterviewerDeclineItemInput[] {
  return interviewerDeclines.map(
    (interviewerDecline): InterviewerDeclineItemInput => ({
      interviewerUserMembershipId:
        interviewerDecline.interviewerUserMembershipId,
      declineReason: interviewerDecline.declineReason,
    })
  );
}

function mapInterviewerNoShowsToInput(
  interviewerNoShows: InterviewerNoShow
): InterviewerNoShowItemInput[] {
  return interviewerNoShows.map(
    (interviewerNoShow): InterviewerNoShowItemInput => ({
      interviewerUserMembershipId:
        interviewerNoShow.interviewerUserMembershipId,
    })
  );
}

function mapInterviewerSwapReasonToInput(
  interviewerSwapReason: InterviewerSwapReason
): InterviewerSwapReasonInput {
  return {
    reason: interviewerSwapReason.reason,
    additionalText: interviewerSwapReason.additionalText,
    interviewerDeclines: interviewerSwapReason.interviewerDeclines,
    otherReason: interviewerSwapReason.otherReason,
  };
}

function mapRescheduleReasonToInput(
  rescheduleReason: RescheduleReason
): RescheduleReasonInput {
  return {
    reason: rescheduleReason.reason,
    additionalText: rescheduleReason.additionalText,
    interviewerDeclines: rescheduleReason.interviewerDeclines
      ? mapInterviewerDeclinesToInput(rescheduleReason.interviewerDeclines)
      : null,
    interviewerNoShows: rescheduleReason.interviewerNoShows
      ? mapInterviewerNoShowsToInput(rescheduleReason.interviewerNoShows)
      : null,
  };
}

export function mapUpdateReasonToInput(
  updateReason: UpdateReason
): UpdateReasonInput {
  return {
    interviewerSwapReason: updateReason.interviewerSwapReason
      ? mapInterviewerSwapReasonToInput(updateReason.interviewerSwapReason)
      : null,
    rescheduleReason: updateReason.rescheduleReason
      ? mapRescheduleReasonToInput(updateReason.rescheduleReason)
      : null,
  };
}

function mapCancellationReasonNoRescheduleDataToInput(
  cancellationData: CancellationReasonNoRescheduleData
): CancellationReasonNoRescheduleDataInput {
  return {
    reason: cancellationData.reason,
    otherReason: cancellationData.otherReason,
    additionalText: cancellationData.additionalText,
  };
}

export function mapCancellationReasonToInput(
  cancellationReason: CancellationReason
): CancellationReasonInput {
  return {
    needsRescheduled: cancellationReason.needsRescheduled,
    rescheduleData: cancellationReason.needsRescheduled
      ? mapRescheduleReasonToInput(cancellationReason.rescheduleData)
      : null,
    cancellationData: !cancellationReason.needsRescheduled
      ? mapCancellationReasonNoRescheduleDataToInput(
          cancellationReason.cancellationData
        )
      : null,
  };
}
