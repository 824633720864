import { atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { AtlasPopoverState, Popover } from "@resource/atlas/popover";
import TextField from "@resource/atlas/textfield/TextField";
import { UserMembershipSelectBaseProps } from "client/components/interviewer-slots/utils/types";

type UserMembershipSelectDisplayProps = UserMembershipSelectBaseProps & {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  popoverState: AtlasPopoverState;
  loading: boolean;
  options: JSX.Element[];
};

// TODO: Require BaseData eg JobInterviewSelect.tsx
export function UserMembershipSelectDisplay({
  popoverState,
  loading,
  options,
  Trigger,
  searchPlaceholderText,
  searchTerm,
  setSearchTerm,
}: UserMembershipSelectDisplayProps) {
  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger>{Trigger}</Popover.Trigger>
      <Popover.Content
        className="w-[30rem]"
        hasPadding={false}
        header={
          <div className="space-y-3 p-0">
            <TextField
              size="small"
              value={searchTerm}
              autoFocus
              icon={atlasSearch}
              placeholder={searchPlaceholderText}
              aria-label={searchPlaceholderText}
              onChange={setSearchTerm}
              isClearable
            />
          </div>
        }
      >
        <div className="py-[.5rem]">
          {loading && (
            <div className="flex justify-center">
              <LoadingIndicator size="small" />
            </div>
          )}
          {options.length > 0 && <>{options}</>}
          {!loading && options.length === 0 && (
            <p className="px-5 py-2 text-body-md">No results found</p>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
