/* eslint-disable import/prefer-default-export, no-underscore-dangle */
import "./frame.sass";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { Portal } from "ariakit";
import clsx from "clsx";
import { $copyNode, $getNodeByKey, $getSelection, NodeKey } from "lexical";
import {
  createContext,
  forwardRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { atlasCopy, atlasGear, atlasTrash } from "../../../../../icons/atlas";
import { createComponentUtils } from "../../../../__utils/atlas";
import {
  mergePropsIntoSingleChild,
  useEvent,
  useId,
} from "../../../../__utils/react";
import { Button } from "../../../../button/Button";
import { Icon } from "../../../../icon/Icon";
import { Menu } from "../../../../menu";
import { useMenuItems } from "../../../../menu/use-menu-items";
import { ContentEditorPopover } from "../../content-editor-popover";
import type {
  ConfigAreaProps,
  ConfigMenuProps,
  ConfigPopoverProps,
  IsNode,
  RenderComponent,
  SetDataFn,
  UpdateDataFn,
} from "./types";

// config
// ------

const COMPONENT_NAME = "ContentEditor-RichBlockFrame";

const { ROOT, el } = createComponentUtils(COMPONENT_NAME);

// config area context
// -------------------

type ConfigAreaContextValue = {
  triggerElement: HTMLElement | null;
  setTriggerElement: (element: HTMLElement | null) => void;
  configAreaElement: HTMLElement | null;
  registerOpenedPopoverId: (id: string) => void;
  unregisterOpenedPopoverId: (id: string) => void;
};

const ConfigAreaContext = createContext<ConfigAreaContextValue>({
  triggerElement: null,
  setTriggerElement: () => {
    throw new Error("Unimplemented");
  },
  configAreaElement: null,
  registerOpenedPopoverId: () => {
    throw new Error("Unimplemented");
  },
  unregisterOpenedPopoverId: () => {
    throw new Error("Unimplemented");
  },
});

function ConfigAreaProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: Omit<ConfigAreaContextValue, "triggerElement" | "setTriggerElement">;
}) {
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );

  const resolvedValue = useMemo(
    () => ({
      triggerElement,
      setTriggerElement,
      ...value,
    }),
    [triggerElement, value]
  );

  return (
    <ConfigAreaContext.Provider value={resolvedValue}>
      {children}
    </ConfigAreaContext.Provider>
  );
}

function useConfigAreaContext() {
  const value = useContext(ConfigAreaContext);
  if (!value) throw new Error("Missing config area context");
  return value;
}

// config area
// -----------

type ConfigAreaBaseProps = ConfigAreaProps & {
  configAreaElement: HTMLElement | null;
};

function ConfigAreaBase({
  configAreaElement,
  children,
  forwardedRef,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
ConfigAreaBaseProps & { forwardedRef: any }) {
  return (
    <Portal portalElement={configAreaElement}>
      {mergePropsIntoSingleChild({ ...props, ref: forwardedRef }, children)}
    </Portal>
  );
}

// config menu
// -----------

type ConfigMenuBaseProps = ConfigMenuProps & {
  nodeKey: string;
};

function ConfigMenuBase({ nodeKey, items: inputItems }: ConfigMenuBaseProps) {
  const [editor] = useLexicalComposerContext();

  const configItems = useMenuItems(
    (i) => {
      const items = i(
        // @ts-expect-error This is fine, TypeScript is just not that smart.
        ...(inputItems?.map((item) => i[item._type](item)) ?? [])
      );

      if ((inputItems?.length ?? 0) > 0)
        items.push(i.separator({ key: "frame-separator" }));

      items.push(
        i.item({
          key: "frame-duplicate",
          children: "Duplicate",
          leadingContent: <Icon content={atlasCopy} />,
          onClick() {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if (!node) throw new Error("Node not found");
              const newNode = $copyNode(node);
              node.insertAfter(newNode);
            });
          },
        }),
        i.item({
          key: "frame-delete",
          children: "Delete",
          leadingContent: <Icon content={atlasTrash} />,
          onClick() {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              node?.remove();
            });
          },
        })
      );

      return items;
    },
    [editor, inputItems, nodeKey]
  );

  const { setTriggerElement, configAreaElement } = useConfigAreaContext();

  return (
    <Menu.Root>
      <Portal portalElement={configAreaElement}>
        <Menu.Trigger>
          <Button ref={setTriggerElement} icon={atlasGear} isGhost size="xs" />
        </Menu.Trigger>
      </Portal>
      <Menu.Content portal items={configItems} defaultSize="compact" />
    </Menu.Root>
  );
}

// config popover
// --------------

type ConfigPopoverBaseProps = ConfigPopoverProps;

function ConfigPopover({ ...props }: ConfigPopoverBaseProps) {
  const { triggerElement, registerOpenedPopoverId, unregisterOpenedPopoverId } =
    useConfigAreaContext();

  const id = useId();

  useEffect(() => {
    if (props.open) registerOpenedPopoverId(id);
    if (!props.open) unregisterOpenedPopoverId(id);
    return () => unregisterOpenedPopoverId(id);
  }, [id, props.open, registerOpenedPopoverId, unregisterOpenedPopoverId]);

  const getAnchorRect = useEvent(
    () => triggerElement?.getBoundingClientRect() ?? null
  );

  return <ContentEditorPopover {...props} getAnchorRect={getAnchorRect} />;
}

// rich block frame
// ----------------

type RichBlockFrameProps<Name extends string, Data> = {
  nodeKey: NodeKey;
  data: Data;
  $isNode: IsNode<Name, Data>;
  RenderComponent: () => Promise<RenderComponent<Data>>;
};

export function RichBlockFrame<Name extends string, Data>({
  nodeKey,
  data,
  $isNode,
  RenderComponent,
}: RichBlockFrameProps<Name, Data>) {
  const [editor] = useLexicalComposerContext();
  const [Component, SetComponent] = useState<null | RenderComponent<Data>>(
    null
  );

  useEffect(() => {
    const renderComponent = async () => {
      const component = await RenderComponent();
      SetComponent(() => component);
    };
    renderComponent();
  }, [RenderComponent]);

  const setData: SetDataFn<Data> = useEvent((valueOrFactory) =>
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isNode(node)) {
        const newData =
          typeof valueOrFactory === "function"
            ? // @ts-expect-error Constraining Data to exclude Function just to make TypeScript happy here seems pointless.
              (valueOrFactory(node.__data) as Data)
            : valueOrFactory;
        node.getWritable().__data = newData;
      }
    })
  );

  const updateData: UpdateDataFn<Data> = useEvent((valueOrFactory) =>
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isNode(node)) {
        const newData =
          typeof valueOrFactory === "function"
            ? (valueOrFactory(node.__data) as Data)
            : valueOrFactory;

        if (
          Object.entries(newData).every(
            ([key, value]) => value === node.__data[key as keyof Data]
          )
        )
          return;

        node.getWritable().__data = { ...node.__data, ...newData };
      }
    })
  );
  const [isSelected] = useLexicalNodeSelection(nodeKey);

  // create a wrapper component to pass the node key, memoized to
  // prevent re-mounting

  const [configAreaElement, setConfigAreaElement] =
    useState<HTMLDivElement | null>(null);

  const ConfigArea = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      forwardRef<ConfigAreaProps, any>((props, ref) => (
        <ConfigAreaBase
          configAreaElement={configAreaElement}
          {...props}
          forwardedRef={ref}
        />
      )),
    [configAreaElement]
  );

  const ConfigMenu = useCallback(
    // eslint-disable-next-line prefer-arrow-callback
    function ConfigMenu(props: ConfigMenuProps) {
      return <ConfigMenuBase nodeKey={nodeKey} {...props} />;
    },
    [nodeKey]
  );

  const renderComponentProps = {
    nodeKey,
    data,
    setData,
    updateData,
    isSelected,
    ConfigMenu,
    ConfigArea,
    ConfigPopover,
  };

  const [openedPopoverIds, setOpenedPopoverIds] = useState<string[]>([]);
  const registerOpenedPopoverId = useCallback(
    (id: string) =>
      setOpenedPopoverIds((ids) => (ids.includes(id) ? ids : [...ids, id])),
    []
  );
  const unregisterOpenedPopoverId = useCallback(
    (id: string) =>
      setOpenedPopoverIds((ids) =>
        ids.includes(id) ? ids.filter((i) => i !== id) : ids
      ),
    []
  );

  return (
    <ConfigAreaProvider
      value={{
        configAreaElement,
        registerOpenedPopoverId,
        unregisterOpenedPopoverId,
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        // discard the selection on focus to prevent issues
        onFocus={() => {
          const hasSelection = editor
            .getEditorState()
            .read(() => Boolean($getSelection()));
          if (hasSelection)
            editor.setEditorState(editor._editorState.clone(null));
        }}
        // prevent lexical from handling these events
        onKeyDown={(event) => event.stopPropagation()}
        onClick={(event) => event.stopPropagation()}
        className={clsx(ROOT, {
          isSelected,
          isPopoverOpen: openedPopoverIds.length > 0,
        })}
      >
        {Component && <Component {...renderComponentProps} />}
        <div ref={setConfigAreaElement} className={el`config-area`} />
      </div>
    </ConfigAreaProvider>
  );
}
