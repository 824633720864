import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRingInfo } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { FormGroup } from "client/components/generic/misc/FormGroup";

type EventPrivacyFormGroupProps = {
  isPrivate: boolean;
  onChange: (isPrivate: boolean) => void;
  label?: string;
  sublabel?: string;
};

export function EventPrivacyFormGroup({
  isPrivate,
  onChange,
  label,
  sublabel,
}: EventPrivacyFormGroupProps) {
  return (
    <FormGroup
      label={label ?? "Interviewer Google Calendar privacy"}
      subText={sublabel}
    >
      <div className="space-y-2">
        <span className="flex flex-row space-x-1">
          <RadioItem
            label="Default"
            isSelected={!isPrivate}
            onClick={() => onChange(false)}
          />
          <Tooltip
            content="We will use the default interviewer calendar privacy settings."
            isInstant
          >
            <Icon content={atlasRingInfo} className="h-3 w-3 text-subtle" />
          </Tooltip>
        </span>
        <RadioItem
          label="Private"
          isSelected={isPrivate}
          onClick={() => onChange(true)}
        />
      </div>
    </FormGroup>
  );
}
