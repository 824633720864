/* eslint-disable import/prefer-default-export */
import "./File.sass";

import clsx from "clsx";
import { useEffect } from "react";

import { atlasArrowDown } from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { useEvent } from "../../../__utils/react";
import { Button } from "../../../button/Button";
import { Dialog } from "../../../dialog";
import { useDialogState } from "../../../dialog/use-dialog-state";
import { Icon } from "../../../icon/Icon";
import { Link } from "../../../link/Link";
import { Card } from "../../__utils/misc";
import {
  getDefaultImageForMimeType,
  getLabelForMimeType,
  humanReadableFileSize,
} from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-File_file";

const { ROOT, el } = createComponentUtils(COMPONENT_NAME);

// file
// ----

type FileProps = {
  uuid: string;
  url: string;
  name: string;
  displayName?: string;
  fileSize: number;
  mimeType: string;
  imageUrl?: string;
  displayAs: "card" | "link";
  displaySize: "small" | "large";
  openDownloadDialogOnLoad?: boolean;
  className?: string;
};

export function File({
  uuid,
  url,
  name: originalName,
  displayName,
  fileSize,
  mimeType,
  imageUrl,
  displayAs,
  displaySize,
  className,
  openDownloadDialogOnLoad,
}: FileProps) {
  const description = `${getLabelForMimeType(
    mimeType
  )} (${humanReadableFileSize(fileSize)})`;

  const dialog = useDialogState();

  const computedImageUrl = getDefaultImageForMimeType(mimeType);

  const name = displayName ?? originalName;

  const cardData = {
    url: "",
    label: name,
    description,
    imageUrl: imageUrl ?? computedImageUrl,
  };

  const openDownloadDialog = useEvent(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (openDownloadDialogOnLoad && urlParams.get("downloadFile") === uuid) {
      dialog.show();
    }
  });

  useEffect(() => {
    openDownloadDialog();
  }, [openDownloadDialog]);

  return (
    <div className={clsx(ROOT, className)}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        className="link-wrapper"
        as="button"
        onClick={() => openDownloadDialogOnLoad && dialog.show()}
      >
        {displayAs === "link" && name}
        {displayAs === "card" && displaySize === "large" && (
          <Card {...cardData} showUrl={false} />
        )}
        {displayAs === "card" && displaySize === "small" && (
          <div className="small-card">
            <div className="image-wrapper">
              <img
                className="image"
                alt={cardData.label}
                src={cardData.imageUrl}
              />
            </div>
            <div>
              <div className="link-info-wrapper">
                <p className="link-info-label">{cardData.label}</p>
                <p className="link-info-description">{cardData.description}</p>
              </div>
            </div>
          </div>
        )}
      </Link>
      <Dialog.Root state={dialog}>
        <Dialog.Content>
          <div className={el`download-dialog-body`}>
            <div className="relative">
              <img className="image" src={cardData.imageUrl} alt={name} />
              <div className="icon">
                <Icon content={atlasArrowDown} />
              </div>
            </div>
            <div className="heading">Download file</div>
            <div className="details">
              <div className="name">{name}</div>
              <div className="description">{description}</div>
            </div>
            <Button
              as="a"
              href={url}
              download
              target="_blank"
              variant="primary"
            >
              Download file
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
}
