export function isCurrentShadowStage({
  index,
  completedShadows,
}: {
  index: number;
  completedShadows?: number;
}) {
  return completedShadows !== undefined && index === completedShadows;
}

export function isCompletedShadowStage({
  index,
  completedShadows,
}: {
  index: number;
  completedShadows?: number;
}) {
  return completedShadows !== undefined && completedShadows > index;
}

export function isCurrentReverseShadowStage({
  index,
  shadows,
  completedShadows,
  completedReverseShadows,
}: {
  index: number;
  shadows: number;
  completedShadows?: number;
  completedReverseShadows?: number;
}) {
  return (
    completedShadows !== undefined &&
    completedReverseShadows !== undefined &&
    index === completedReverseShadows &&
    completedShadows >= shadows
  );
}

export function isCompletedReverseShadowStage({
  index,
  completedShadows,
  completedReverseShadows,
}: {
  index: number;
  shadows: number;
  completedShadows?: number;
  completedReverseShadows?: number;
}) {
  return (
    completedShadows !== undefined &&
    completedReverseShadows !== undefined &&
    completedReverseShadows > index
  );
}
