import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Hovercard } from "@resource/atlas/hovercard";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarCross,
  atlasCircleInfo,
  atlasMoon,
  atlasRingCheck,
} from "@resource/atlas/icons";
import { EventTitle } from "client/components/calendar-v2/components/events/EventTitle";
import { useCalendarSettings } from "client/components/calendar-v2/hooks/settings";
import { getBackgroundStylingFromConfig } from "client/components/calendar-v2/utils/colors";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { SelectedInterviewerDisplay } from "client/components/interviewer-slots/display/SelectedInterviewerDisplay";
import { SchedulerAddToWhitelistSwitch } from "client/scheduler/core/components/misc/SchedulerAddToWhitelistSwitch";
import { INTERVIEW_WHITELIST_ICON } from "client/utils/interview-whitelist";
import clsx from "clsx";
import { DateTime } from "luxon";

import { useHideAndShowInterviewConflictHovercard } from "./hooks/useHideAndShowInterviewConflictHovercard";
import {
  InterviewConflictHovercardData,
  useHoveringInterviewConflictData,
} from "./hooks/useHoveringInterviewConflictData";
import { useInterviewConflictHovercardState } from "./hooks/useInterviewConflictHovercardState";

export function InterviewConflictHovercardPlugin() {
  const state = useInterviewConflictHovercardState();
  const data = useHoveringInterviewConflictData({
    state,
  });

  const { hasConflicts } = data;

  useHideAndShowInterviewConflictHovercard({
    state,
    data,
  });

  if (!state.isVisible) {
    return null;
  }

  return (
    <Hovercard.Root state={state.hovercardState}>
      <Hovercard.Content
        portal
        hasPadding={false}
        className="p-4"
        onMouseEnter={state.onMouseEnterHovercard}
        onMouseLeave={state.onMouseLeaveHovercard}
      >
        <div className="flex space-x-2">
          {hasConflicts ? (
            <Icon content={atlasCircleInfo} className="w-5 h-5 text-red-500" />
          ) : (
            <Icon content={atlasRingCheck} className="w-5 h-5 text-green-500" />
          )}
          <div className="space-y-3">
            <Header hasConflicts={hasConflicts} />
            <div className="space-y-4">
              <InterviewerOutsideWorkingHoursConflicts data={data} />
              <EventConflicts data={data} />
              <InterviewOverloadConflicts data={data} />
              <InterviewerAlreadyInterviewedConflicts data={data} />
              <BlockedDatesConflicts data={data} />
            </div>
          </div>
        </div>
      </Hovercard.Content>
    </Hovercard.Root>
  );
}

function Header({ hasConflicts }: { hasConflicts: boolean }) {
  return (
    <div className="space-y-1">
      <p className="text-body-md-heavy">
        {hasConflicts ? "Scheduling issues" : "No scheduling issues"}
      </p>
      <p className="text-body-md">
        {hasConflicts
          ? "This time slot has a conflict with one or more of the interviewers."
          : "This time slot has a conflict with one or more of the interviewers, but these meetings are marked as flexible."}
      </p>
    </div>
  );
}

function EventConflicts({ data }: { data: InterviewConflictHovercardData }) {
  const { eventConflicts: interviewConflicts } = data;

  if (!interviewConflicts.length) {
    return null;
  }

  return (
    <div>
      <div className="mt-2 space-y-2">
        {interviewConflicts?.map(({ userMembership, events }) => {
          return (
            <>
              {events.map((event) => {
                return (
                  <div
                    key={event.id}
                    className="rounded-md p-2 items-center flex justify-between"
                    style={getBackgroundStylingFromConfig({
                      config: event.colorConfig,
                      responseStatus: event.responseStatus,
                    })}
                  >
                    <EventTitle
                      event={{
                        ...event,
                        icon: event.isWhitelisted
                          ? {
                              text: INTERVIEW_WHITELIST_ICON,
                            }
                          : undefined,
                        colorConfig: event.colorConfig,
                      }}
                    />
                    <SchedulerAddToWhitelistSwitch
                      event={event}
                      userMembership={userMembership}
                    />
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
}

function InterviewOverloadConflicts({
  data,
}: {
  data: InterviewConflictHovercardData;
}) {
  const { overloadedInterviewers } = data;

  if (!overloadedInterviewers.length) {
    return null;
  }

  return (
    <div>
      <p className="text-body-md">Interviewer load exceeded</p>
      <div className="mt-2 space-y-2 text-body-md">
        {overloadedInterviewers?.map((userMembership) => {
          return (
            <SelectedInterviewerDisplay
              interviewer={userMembership}
              hideAvailabilityIcon
              key={userMembership.id}
            />
          );
        })}
      </div>
    </div>
  );
}

function InterviewerAlreadyInterviewedConflicts({
  data,
}: {
  data: InterviewConflictHovercardData;
}) {
  const { interviewersAlreadyInterviewed } = data;
  const { timezone } = useCalendarSettings();

  if (!interviewersAlreadyInterviewed.length) {
    return null;
  }

  return (
    <div>
      <p className="text-body-md">
        {interviewersAlreadyInterviewed.length > 1
          ? "Interviewers have"
          : "Interviewer has"}{" "}
        already interviewed candidate
      </p>
      <div className="mt-2 space-y-2">
        {interviewersAlreadyInterviewed?.map((userMembership) => {
          return (
            <div
              key={userMembership.id}
              className="flex gap-2 text-body-md items-start"
            >
              <Avatar
                size="small"
                image={userMembership.imageUrl}
                name={userMembership.name}
              />
              <div>
                <div>{userMembership.name}</div>
                {userMembership.previousScheduledInterviews?.map(
                  (prevInterview) => (
                    <div className="flex gap-3" key={prevInterview.id}>
                      <div
                        className={clsx(
                          "gap-1 flex items-center text-body-sm-heavy"
                        )}
                      >
                        <InterviewTitle interview={prevInterview} />
                        on{" "}
                        {DateTime.fromISO(prevInterview.startTime, {
                          zone: timezone,
                        }).toFormat("MMM d")}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function BlockedDatesConflicts({
  data,
}: {
  data: InterviewConflictHovercardData;
}) {
  const { blockedDateConflicts } = data;

  if (!blockedDateConflicts?.length) {
    return null;
  }

  return (
    <div>
      <p className="text-body-md">Date is blocked for scheduling</p>
      <div className="mt-2 space-y-2">
        {blockedDateConflicts.map((range) => (
          <div
            key={`${range.startDay}-${range.endDay}`}
            className="rounded-md text-body-sm p-2 bg-red-50 flex items-center gap-2"
          >
            <Icon content={atlasCalendarCross} className="text-red-500" />
            <span>
              {range.startDay.toFormat("LLL d")} -{" "}
              {range.endDay.toFormat("LLL d, yyyy")}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

function InterviewerOutsideWorkingHoursConflicts({
  data,
}: {
  data: InterviewConflictHovercardData;
}) {
  const { interviewersOutsideWorkingHours, hoveringSchedulingEvent } = data;

  if (!interviewersOutsideWorkingHours.length) {
    return null;
  }

  return (
    <div className="mt-2 space-y-2">
      {interviewersOutsideWorkingHours.map((userMembership) => {
        const formattedUserTimezone = userMembership.user.timezone
          ? DateTime.now()
              .setZone(userMembership.user.timezone)
              .toFormat("ZZZZ")
          : null;

        return (
          <div
            key={userMembership.id}
            className="flex gap-2 text-body-md items-start"
          >
            <Avatar
              size="xs"
              image={userMembership.imageUrl}
              name={userMembership.name}
            />
            <div>
              <div className="flex gap-1 items-center">
                <div className="text-body-md-heavy">{userMembership.name}</div>
                {formattedUserTimezone && (
                  <div className="text-subtle">({formattedUserTimezone})</div>
                )}
                <Icon content={atlasMoon} className="text-subtle" />
              </div>
              <div className="text-subtle">
                <div>Outside of working hours</div>
                {hoveringSchedulingEvent && (
                  <div>
                    {DateTime.fromISO(hoveringSchedulingEvent.startTime)
                      .setZone(userMembership.user.timezone ?? undefined)
                      .toLocaleString(DateTime.TIME_SIMPLE)}{" "}
                    local time
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
