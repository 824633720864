/**
 * Helper that will fuzzy match a string to a time. e.g. 3 = 3am, 3pm = 3pm, 30 = invalid, 300 = 3pm, 330 = 3:30pm, 3:30 = 3:30pm
 * @param value
 * @returns
 */
import { DateTime } from "luxon";

export function getTimeFromInputString(
  value: string
): { hour: number; minute: number } | null {
  const normalizedValue = value.toLowerCase().replace(/[^a-z0-9]/g, "");
  const matches = normalizedValue.match(/([0-9]{1,4})([a|p]m?)?/);

  if (matches) {
    const [, time, amPm] = matches;
    const afternoon = amPm?.charAt(0) === "p";
    if (time.length === 1) {
      // Treat as an hour
      return {
        hour: afternoon ? Number(time) + 12 : Number(time),
        minute: 0,
      };
    }

    if (time.length === 2) {
      // valid if within time range
      const hour = Number(time);
      if (hour <= 12) {
        return {
          hour: afternoon ? hour + 12 : hour,
          minute: 0,
        };
      }
      if (hour < 24) {
        return {
          hour,
          minute: 0,
        };
      }
    } else {
      // hours + minutes
      const normalizedTime = time.padStart(4, "0");
      const hour = Number(normalizedTime.substring(0, 2));
      const minute = Number(normalizedTime.substring(2));

      if (minute >= 60) {
        return null;
      }

      if (hour <= 12) {
        return {
          hour: afternoon ? hour + 12 : hour,
          minute,
        };
      }
      if (hour < 24) {
        return {
          hour,
          minute,
        };
      }
    }
  }

  return null;
}

export function isInFuture(e: { endTime: string }) {
  return DateTime.fromISO(e.endTime).diffNow().milliseconds > 0;
}
