import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { InterviewListInterviewFragment } from "generated/graphql-codegen/graphql";
import { useState } from "react";

import { InterviewDetailsDialog } from "./InterviewDetailsDialog";
import { InterviewList, InterviewListProps } from "./InterviewList";

type InterviewListWithModalProps = Omit<
  InterviewListProps,
  "onSelectInterview"
> & {
  onSelectInterview?: () => unknown;
};

export function InterviewListWithModal({
  interviews,
  onSelectInterview,
  ...props
}: InterviewListWithModalProps) {
  const [interview, setInterview] = useState<InterviewListInterviewFragment>();
  const dialogState = useDialogState();

  return (
    <>
      <InterviewList
        {...props}
        interviews={interviews}
        onSelectInterview={(interviewId) => {
          const interviewToShow = interviews?.find((i) => i.id === interviewId);
          setInterview(interviewToShow);

          if (interviewToShow) {
            dialogState.setOpen(true);
          }
          onSelectInterview?.();
        }}
        variant="candidate"
      />
      {interview && (
        <InterviewDetailsDialog interview={interview} {...dialogState} />
      )}
    </>
  );
}
