import { Button as AriaButton } from "@ariakit/react";
import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { useLogEvent } from "analytics";
import { ConferencingSettingSelect } from "client/components/conferencing/display/ConferencingSettingSelect";
import { ZoomHostSelect } from "client/components/conferencing/wrappers/ZoomHostSelect";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { GenerationSlotSubtext } from "client/components/link-generation/hooks/GenerationSlotSubtext";
import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";

import { ScheduledInterviewGroupSettingsFormState } from "../__hooks/useScheduledInterviewGroupSettingsFormState";

export function EditScheduledInterviewGroupConferencingSettings({
  state: {
    form,
    interviewerUserMembershipIds,
    onChange,
    originalScheduledInterviewGroupSettings,
    errors,
  },
}: {
  state: ScheduledInterviewGroupSettingsFormState;
}) {
  const logEvent = useLogEvent({
    component: "EditScheduledInterviewGroupConferencingSettings",
  });
  const reuseVideoConferencingLink = form.watch("reuseVideoConferencingLink");
  const generationSettings = form.watch("conferencingGenerationSettings");

  const hasExistingGeneratedURL = useMemo(() => {
    if (
      originalScheduledInterviewGroupSettings &&
      originalScheduledInterviewGroupSettings.existingConferencing
    ) {
      return true;
    }

    return false;
  }, [originalScheduledInterviewGroupSettings]);
  const onRegenerateLink = useCallback(() => {
    form.setValue("conferencingGenerationSettings.regenerateLink", true);
  }, [form]);

  return (
    <>
      <Controller
        name="reuseVideoConferencingLink"
        control={form.control}
        render={({ field: { value, ...field } }) => (
          <AriaButton
            className="flex gap-2 text-body-md"
            onClick={() => {
              onChange("reuseVideoConferencingLink", !value);
              logEvent(
                "Scheduler V2 Panel Settings Reuse Video Conferencing Changed",
                {
                  value: !value,
                }
              );
              onChange(
                "conferencingGenerationSettings.service",
                generationSettings?.service ?? ConferencingType.ZOOM
              );
            }}
          >
            <Checkbox {...field} checked={value} />
            <span className="text-left">
              Use the same conferencing details for all interviews.
            </span>
          </AriaButton>
        )}
      />
      {reuseVideoConferencingLink && (
        <div className="ml-6 space-y-2">
          <FormGroup
            label="Conferencing"
            subText={
              <GenerationSlotSubtext
                existingUrl={
                  originalScheduledInterviewGroupSettings?.existingConferencing
                    ?.value
                }
                generationSettings={generationSettings}
                hasExistingGeneratedURL={hasExistingGeneratedURL}
                onRegenerateLink={onRegenerateLink}
              />
            }
            // TODO: We probably should be more granular and better about location, but solves the problem for now
            errorMessage={errors?.[0]}
          >
            <ConferencingSettingSelect
              conferencingType={generationSettings?.service}
              phoneEnabled={false}
              customEnabled={false}
              allowNoSelection={false}
              requiresGoogleMeetApi
              onSelect={(newService) => {
                if (!newService) {
                  return;
                }

                const prevValue = form.getValues(
                  "conferencingGenerationSettings"
                );
                onChange("conferencingGenerationSettings", {
                  ...prevValue,
                  service: newService,
                });
              }}
            />
          </FormGroup>
          {generationSettings?.service === ConferencingType.ZOOM && (
            <Controller
              name="conferencingGenerationSettings.hostUserMembershipId"
              control={form.control}
              render={({ field }) => (
                <FormGroup label="Zoom host" isRequired>
                  <ZoomHostSelect
                    hostUserMembershipId={field.value}
                    onSelect={(val) => {
                      const prevValue = form.getValues(
                        "conferencingGenerationSettings"
                      );

                      onChange("conferencingGenerationSettings", {
                        ...prevValue,
                        service: ConferencingType.ZOOM,
                        hostUserMembershipId: val?.id ?? null,
                        regenerateLink: true,
                      });
                    }}
                    interviewerUserMembershipIds={interviewerUserMembershipIds}
                    portal
                  />
                </FormGroup>
              )}
            />
          )}
        </div>
      )}
    </>
  );
}
