import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarOoo } from "@resource/atlas/icons";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { useMemo } from "react";

import { InterviewTitle } from "../../../guide/interviews/InterviewTitle/InterviewTitle";
import {
  getBackgroundColorFromConfig,
  getTextColorFromConfig,
} from "../../utils/colors";
import { CalendarEvent } from "../../utils/types";
import { EventSize } from "./utils/sizing";

function isLexicalContent(title: string) {
  try {
    JSON.parse(title);
    return true;
  } catch (err) {
    return false;
  }
}

export function EventTitle({
  event,
  eventSize,
}: {
  event: Pick<
    CalendarEvent,
    "title" | "colorConfig" | "outOfOffice" | "icon" | "responseStatus"
  >;
  eventSize?: EventSize;
}) {
  const textColor = useMemo(() => {
    return getTextColorFromConfig(event.colorConfig, event.responseStatus);
  }, [event.colorConfig, event.responseStatus]);
  const iconColor = useMemo(() => {
    if (event.colorConfig.eventVariant === "background") {
      return getBackgroundColorFromConfig(event.colorConfig);
    }

    return getTextColorFromConfig(event.colorConfig, event.responseStatus);
  }, [event.colorConfig, event.responseStatus]);

  let textSizeClassName = "text-[10px]";
  let lineHeight = "10px";
  let iconSizeClassName = "w-3 h-3 -mt-[1px]";

  switch (eventSize) {
    case "xs":
    case "small":
    case "medium":
      break;
    case "large":
      textSizeClassName = "text-[12px]";
      lineHeight = "12px";
      iconSizeClassName = "w-4 h-4 -mt-[2px]";
      break;
    default:
  }

  const iconElement = useMemo(() => {
    const outOfOfficeIcon = event.outOfOffice ? atlasCalendarOoo : null;
    const icon = event.icon ?? outOfOfficeIcon ?? null;

    if (!icon) return null;

    if (typeof icon === "object") {
      // You can pass `text` as icon for emojis (i.e. whitelist icon)
      if ("text" in icon) {
        return (
          <span className="text-dark" style={{ lineHeight }}>
            {icon.text}
          </span>
        );
      }
    }

    return (
      <Icon
        content={icon}
        className={clsx("text-dark ", iconSizeClassName)}
        style={{ color: iconColor }}
      />
    );
  }, [event.outOfOffice, event.icon, iconSizeClassName, iconColor, lineHeight]);

  const titleElement = useMemo(() => {
    const isLexical = isLexicalContent(event.title);

    if (isLexical) {
      return (
        <InterviewTitle
          interview={event}
          className={clsx(
            "order-1 font-semibold whitespace-nowrap",
            "text-dark"
          )}
          style={{
            color: textColor,
            lineHeight,
          }}
        />
      );
    }

    return (
      <p
        className={clsx("order-1 font-semibold whitespace-nowrap", "text-dark")}
        style={{
          color: textColor,
          lineHeight,
        }}
      >
        {event.title}
      </p>
    );
  }, [event, textColor, lineHeight]);

  return (
    <span
      className={clsx("flex flex-row space-x-1", textSizeClassName)}
      data-cy={CypressData.calendar.eventTitle}
    >
      {iconElement}
      {titleElement}
    </span>
  );
}
