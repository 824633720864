import { InterviewWithSchedulingInfo } from "client/components/scheduled-interviews/ScheduleSummary/ScheduleSummary";
import { mapScheduledInterviewWithResult } from "client/components/scheduled-interviews/ScheduleSummary/utils/mapping";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { SchedulerSchedulingResult } from "client/scheduler/utils/types";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

export const mapSchedulingResponseToSchedulingInfo = (
  res: SchedulerSchedulingResult
): InterviewWithSchedulingInfo[] => {
  if (!res.upsertScheduledInterviewGroupForGuide) {
    return [];
  }

  const {
    scheduledInterviewGroup,
    cancelledInterviews,
    createdInterviews,
    updatedInterviews,
  } = res.upsertScheduledInterviewGroupForGuide;
  const scheduledInterviews =
    scheduledInterviewGroup?.guide.internalUpcomingInterviews ?? [];

  return scheduledInterviews
    .map((scheduledInterview): InterviewWithSchedulingInfo | null => {
      const createdInterview = createdInterviews.find(
        (i) => i.scheduledInterview.id === scheduledInterview.id
      );
      const updatedInterview = updatedInterviews.find(
        (i) => i.scheduledInterview.id === scheduledInterview.id
      );
      const cancelledInterview = cancelledInterviews.find(
        (i) => i.scheduledInterview.id === scheduledInterview.id
      );

      if (createdInterview) {
        return mapScheduledInterviewWithResult(
          scheduledInterview,
          createdInterview,
          ScheduledStatus.CREATED
        );
      }

      if (updatedInterview) {
        return mapScheduledInterviewWithResult(
          scheduledInterview,
          updatedInterview,
          ScheduledStatus.UPDATED
        );
      }

      if (cancelledInterview) {
        return mapScheduledInterviewWithResult(
          scheduledInterview,
          cancelledInterview,
          ScheduledStatus.CANCELLED
        );
      }

      return null;
    })
    .filter(filterOutNullsAndUndefined);
};
