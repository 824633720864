import type { InsertOptionData } from "./insert-options";

// insert options targets order
// ----------------------------

// paragraph style

type ParagraphStyleTargetOptions = NonNullable<
  InsertOptionData["targets"]["paragraphStyle"]
>;

function p(
  position: ParagraphStyleTargetOptions["position"]
): ParagraphStyleTargetOptions {
  return { position };
}

export const PARAGRAPH_STYLE_TARGETS = {
  TEXT: p(10),
  LARGE_HEADING: p(20),
  SMALL_HEADING: p(30),
  BULLETED_LIST: p(40),
  NUMBERED_LIST: p(50),
  LINE_SEPARATOR: p(60),
};

// insert

type InsertTargetOptions = NonNullable<InsertOptionData["targets"]["insert"]>;

function i(
  category: InsertTargetOptions["category"],
  position: InsertTargetOptions["position"]
): InsertTargetOptions {
  return { category, position };
}

export const INSERT_TARGETS = {
  // text & layout
  TEXT: i("text-and-layout", 10),
  LARGE_HEADING: i("text-and-layout", 20),
  SMALL_HEADING: i("text-and-layout", 30),
  BULLETED_LIST: i("text-and-layout", 40),
  NUMBERED_LIST: i("text-and-layout", 50),
  LINE_SEPARATOR: i("text-and-layout", 60),
  QUOTE: i("text-and-layout", 70),
  CODE_BLOCK: i("text-and-layout", 80),
  LINK: i("text-and-layout", 90),
  CARD_LINK: i("text-and-layout", 100),
  MENTION: i("text-and-layout", 110),
  EMOJI: i("text-and-layout", 120),
  VARIABLE: i("text-and-layout", 130),

  // media
  IMAGE: i("media", 10),
  VIDEO: i("media", 20),
  MAP: i("media", 30),
  FILE: i("media", 40),
  GIF: i("media", 50),

  // scheduling
  REQUEST_AVAILABILITY: i("scheduling", 10),
  SHARE_AVAILABILITY: i("scheduling", 20),
  SELF_SCHEDULE_REQUEST: i("scheduling", 30),

  // display
  INTERVIEW_SCHEDULE: i("display", 10),
  PERSON: i("display", 20),
  TEAM: i("display", 30),
  CARDS: i("display", 40),

  // integrations
  FIGMA: i("integrations", 10),
  GOOGLE_DOCS: i("integrations", 20),
  GOOGLE_SLIDES: i("integrations", 30),
  LOOM: i("integrations", 40),
  YOUTUBE: i("integrations", 50),
};
