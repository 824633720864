import { InterviewRequirementForInterviewRequirementsCardFragment } from "generated/graphql-codegen/graphql";
import { createContext, ReactNode, useContext, useMemo } from "react";

type TaskRequirementsContextValue = {
  taskRequirements?:
    | InterviewRequirementForInterviewRequirementsCardFragment[]
    | null;
};

const TaskRequirementsContext = createContext<
  TaskRequirementsContextValue | undefined
>(undefined);

export type TaskRequirementsProviderProps = {
  taskRequirements?:
    | InterviewRequirementForInterviewRequirementsCardFragment[]
    | null;
  children?: ReactNode;
};
export function TaskRequirementsProvider({
  taskRequirements,
  children,
}: TaskRequirementsProviderProps) {
  const value = useMemo(() => ({ taskRequirements }), [taskRequirements]);

  return (
    <TaskRequirementsContext.Provider value={value}>
      {children}
    </TaskRequirementsContext.Provider>
  );
}

export function useTaskRequirementsContext() {
  return useContext(TaskRequirementsContext);
}
