import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import clsx from "clsx";
import { ReactNode } from "react";

export type HeaderProps = {
  className?: string;
  title?: string;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
};

export function Header({
  title,
  leftActions,
  rightActions,
  className,
}: HeaderProps) {
  return (
    <header
      className={clsx(
        " bg-white relative flex-shrink-0 border-b border-gray-border text-body-md-heavy",
        className
      )}
    >
      <div className="flex items-center justify-center px-[1.5rem] h-[4rem]">
        <ButtonGroup
          className="min-w-[2rem] flex-1"
          isGhost
          size="small"
          negativeMargin="left"
        >
          {leftActions}
        </ButtonGroup>
        <div className="flex-2 text-center grow">{title}</div>
        <ButtonGroup
          className="min-w-[2rem] flex-1 justify-end"
          isGhost
          size="small"
          negativeMargin="right"
        >
          {rightActions}
        </ButtonGroup>
      </div>
    </header>
  );
}
