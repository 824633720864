import { useMenuStore, useStoreState } from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Combobox } from "@resource/atlas/combobox-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleCross } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu-v2";
import { useAuthContext } from "auth/context";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import _ from "lodash";
import { startTransition, useEffect, useMemo, useRef } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { useEventCallback } from "react-hooks/useEventCallback";
import useQuery from "utils/useQuery";

const GET_AVAILABLE_ORGANIZATIONS = gql(`
query AvailableOrganizations($organizationSearch: String, $userSearch: String) {
    currentUser {
      id
      availableOrganizations(search: $organizationSearch) {
        id
        name
        defaultAvatarImageUrl
        isActive
      }
    }
    userMemberships(search: $userSearch) {
      id
      name
      email
      imageUrl
      userId
    }
  }
`);

function OrganizationSwitcher({
  showOrgName = false,
}: {
  showOrgName?: boolean;
}) {
  const {
    debouncedTerm: orgDebouncedTerm,
    searchTerm: orgSearchTerm,
    setSearchTerm: setOrgSearchTerm,
  } = useDebouncedSearch("");
  const {
    debouncedTerm: userDebouncedTerm,
    searchTerm: userSearchTerm,
    setSearchTerm: setUserSearchTerm,
  } = useDebouncedSearch("");

  const orgMenuStore = useMenuStore();
  const orgMenuState = useStoreState(orgMenuStore);
  const userMenuStore = useMenuStore();
  const userMenuState = useStoreState(userMenuStore);

  const {
    user,
    organizationIdCookie,
    organizationIdCookieLoading,
    impersonationMembershipIdCookie,
    switchToOrg,
    impersonateUser,
  } = useAuthContext();

  const { data } = useQuery(GET_AVAILABLE_ORGANIZATIONS, {
    skip: !user || (!orgMenuState.open && !userMenuState.open),
    throwOnError: false,
    variables: {
      organizationSearch: orgDebouncedTerm,
      userSearch: userDebouncedTerm,
    },
  });

  const orgs = data?.currentUser?.availableOrganizations;
  const userMemberships = data?.userMemberships;

  const switchToOrgCb = useEventCallback((orgId: string) => {
    switchToOrg(orgId);
  });

  const impersonateUserCb = useEventCallback((membershipId: string) => {
    impersonateUser(membershipId);
  });

  const orgItems = useMemo(
    () =>
      orgs?.map((org) => {
        const disabled = !org.isActive;

        return {
          id: org.id,
          label: org.name,
          isSelected: organizationIdCookie === org.id,
          onClick: () => {
            if (disabled) return;

            switchToOrgCb(org.id);
          },
          renderContent: () => (
            <div
              className={clsx("flex items-center gap-2 truncate", {
                "opacity-50": disabled,
              })}
            >
              <Avatar
                image={org.defaultAvatarImageUrl}
                name={org.name}
                size="small"
              />
              {org.name}
            </div>
          ),
          disabled,
        };
      }) ?? [],
    [orgs, switchToOrgCb, organizationIdCookie]
  );

  const userItems = useMemo(() => {
    const options = _(userMemberships)
      .map((userMembership) => {
        return userMembership.id !== user?.currentUserMembership?.id
          ? {
              id: userMembership.userId,
              label: userMembership.name,
              isSelected: impersonationMembershipIdCookie === userMembership.id,
              onClick: () => impersonateUserCb(userMembership.id),
              renderContent: () => (
                <div className="flex items-center gap-2 truncate">
                  <Avatar
                    image={userMembership.imageUrl}
                    name={userMembership.name}
                    size="small"
                  />
                  <div className="flex flex-col">
                    <span className="truncate">{userMembership.name}</span>
                    {userMembership.email !== userMembership.name && (
                      <span className="text-subtle truncate">
                        {userMembership.email}
                      </span>
                    )}
                  </div>
                </div>
              ),
            }
          : null;
      })
      .compact()
      .value();

    if (impersonationMembershipIdCookie) {
      options.unshift({
        id: "impersonation-clear",
        label: "Clear impersonation user",
        onClick: () => impersonateUserCb(""),
        isSelected: false,
        renderContent: () => (
          <div className="flex items-center gap-1.5">
            <Icon content={atlasCircleCross} className="text-subtle w-4 h-4" />
            Clear impersonation user
          </div>
        ),
      });
    }

    return options;
  }, [
    userMemberships,
    impersonateUserCb,
    impersonationMembershipIdCookie,
    user,
  ]);

  const currentOrg = useMemo(() => user?.currentOrganization, [user]);
  const currentImpersonatedUser = useMemo(
    () =>
      impersonationMembershipIdCookie ? user?.currentUserMembership : null,
    [impersonationMembershipIdCookie, user?.currentUserMembership]
  );

  const orgCount = user?.availableOrganizationsCount ?? 0;

  const orgComboboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        orgMenuStore.setOpen(true);
        // Focus the input after the menu opens
        setTimeout(() => {
          orgComboboxRef.current?.focus();
        }, 0);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [orgMenuStore]);

  const orgName = currentOrg?.name ?? "";

  if (orgCount < 2 || organizationIdCookieLoading) {
    return null;
  }

  return (
    <div className="flex items-center">
      <Combobox.Root
        value={orgSearchTerm}
        setValue={(value) => {
          startTransition(() => {
            setOrgSearchTerm(value);
          });
        }}
      >
        <Menu.Root store={orgMenuStore} placement="top-start">
          <Menu.Button
            isGhost
            size="sm"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <Avatar
              key={currentOrg?.id}
              image={currentOrg?.defaultAvatarImageUrl}
              name={currentOrg?.name}
              size="xs"
            />
          </Menu.Button>
          <Menu.Menu className="!p-0 w-72" fitViewport>
            <div className="sticky top-0 bg-white z-10">
              <Combobox.Combobox
                ref={orgComboboxRef}
                className="p-2"
                placeholder="Search organizations..."
                size="compact"
              />
            </div>
            <div className="overflow-auto">
              <Combobox.List className="px-2 pb-2 max-h-[20rem]">
                {orgItems.map((item) => (
                  <Combobox.Item
                    key={item.id}
                    size="compact"
                    isSelectable
                    isSelected={item.isSelected}
                    onClick={item.onClick}
                    disabled={item.disabled}
                  >
                    {item.renderContent()}
                  </Combobox.Item>
                ))}
              </Combobox.List>
            </div>
          </Menu.Menu>
        </Menu.Root>
      </Combobox.Root>

      {user?.isSuperuser && (
        <Combobox.Root
          value={userSearchTerm}
          setValue={(value) => {
            startTransition(() => {
              setUserSearchTerm(value);
            });
          }}
        >
          <Menu.Root store={userMenuStore}>
            <Menu.Button
              isGhost
              size="sm"
              style={{
                backgroundColor: "transparent",
              }}
            >
              <Avatar
                key={currentImpersonatedUser?.id}
                image={currentImpersonatedUser?.imageUrl}
                name={currentImpersonatedUser?.name}
                className="-ml-6"
                size="xs"
              />
            </Menu.Button>
            <Menu.Menu className="!p-0 w-72" fitViewport>
              <div className="sticky top-0 bg-white z-10">
                <Combobox.Combobox
                  className="p-2"
                  placeholder="Search users..."
                  size="compact"
                />
              </div>
              <div className="overflow-auto">
                <Combobox.List className="px-2 pb-2">
                  {userItems.map((item) => (
                    <Combobox.Item
                      key={item.id}
                      size="compact"
                      isSelectable={item.id !== "impersonation-clear"}
                      isSelected={item.isSelected}
                      onClick={item.onClick}
                    >
                      {item.renderContent()}
                    </Combobox.Item>
                  ))}
                </Combobox.List>
              </div>
            </Menu.Menu>
          </Menu.Root>
        </Combobox.Root>
      )}
      {orgName && showOrgName && (
        <p
          className={clsx(
            "text-subtle text-body-md truncate",
            user?.isSuperuser && "-ml-2"
          )}
          title={orgName}
        >
          {orgName}
        </p>
      )}
    </div>
  );
}

export default OrganizationSwitcher;
