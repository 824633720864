import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { InterviewRequirementCard } from "client/components/interview-requirements/InterviewRequirementCard";
import { InterviewPanelRequirement } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/types";
import { JobSettingsInterviewPanelRequirementsDisplay } from "client/scheduling-requirements-configuration/JobSettingsInterviewPanelRequirementsDisplay";
import { mapInterviewRequirementV2InputFromInterviewRequirementFragment } from "client/scheduling-requirements-configuration/utils";
import { useFilteredInterviewRequirements } from "client/utils/interviewRequirements";
import { gql } from "generated/graphql-codegen";
import { InterviewRequirementForConfigurationFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

export type UpdateSchedulingSettingsConfirmationDialogProps = DialogProps & {
  interviewRequirements: InterviewRequirementForConfigurationFragment[];
  job: {
    id: string;
    name: string;
  };
  templateId: string;
  interviewPanelRequirement: InterviewPanelRequirement;
  jobInterviewPanelRequirementId: string;
  onCompleted: () => void;
};

const UPDATE_SCHEDULING_REQUEST_TEMPLATE_V2 = gql(`
  mutation UpdateSchedulingRequestTemplateV2FromConfirmation($input: UpdateSchedulingRequestTemplateV2Input!) {
    updateSchedulingRequestTemplateV2(input: $input) {
      success
      code
      message
      schedulingRequestTemplate {
        ...SchedulingRequestTemplateForSettings
      }
    }
  }
`);

export function UpdateSchedulingSettingsConfirmationDialog({
  templateId,
  interviewRequirements,
  job,
  interviewPanelRequirement,
  jobInterviewPanelRequirementId,
  onCompleted,
  store,
  ...rest
}: UpdateSchedulingSettingsConfirmationDialogProps) {
  const [update, { loading }] = useMutation(
    UPDATE_SCHEDULING_REQUEST_TEMPLATE_V2
  );

  const onCancel = useCallback(() => {
    store.hide();
    onCompleted();
  }, [onCompleted, store]);
  const save = useCallback(async () => {
    await update({
      variables: {
        input: {
          schedulingRequestTemplate: {
            id: templateId,
            interviewPanelRequirement: {
              id: jobInterviewPanelRequirementId,
              algorithmSettings: interviewPanelRequirement.algorithmSettings,
              reuseVideoConferencingLink:
                interviewPanelRequirement.reuseVideoConferencingLink,
              location: interviewPanelRequirement.location,
              // Legacy panel settings are already on the interviewRequirements
              interviewRequirements: interviewRequirements.map(
                mapInterviewRequirementV2InputFromInterviewRequirementFragment
              ),
            },
          },
        },
      },
    });
    store.hide();
    onCompleted();
  }, [
    update,
    templateId,
    jobInterviewPanelRequirementId,
    interviewPanelRequirement,
    interviewRequirements,
    store,
    onCompleted,
  ]);

  const { interviewRequirements: displayRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements,
    });

  return (
    <Dialog {...rest} store={store} size="medium">
      <View
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={onCancel}>
                <span className="text-body-md-heavy">No, thanks</span>
              </Button>
              <Button variant="primary" onClick={save} isLoading={loading}>
                Save changes
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        <p className="text-body-md">
          Changes were made to the default request for{" "}
          <span className="text-body-md-heavy">{job?.name}</span>. Would you
          like to save these changes to the job settings?
        </p>
        <div className="space-y-2 mt-4">
          {displayRequirements?.map((interviewRequirement) => (
            <InterviewRequirementCard
              key={interviewRequirement.id}
              interviewRequirement={interviewRequirement}
              allRequirements={interviewRequirements}
            />
          ))}
          <JobSettingsInterviewPanelRequirementsDisplay
            interviewPanelRequirement={interviewPanelRequirement}
          />
        </div>
      </View>
    </Dialog>
  );
}
