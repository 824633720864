/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { useMemo } from "react";

import {
  atlasDivider,
  atlasTextFormatHeadingLarge,
  atlasTextFormatHeadingMedium,
  atlasTextFormatListBullet,
  atlasTextFormatListNumber,
  atlasTextFormatParagraph,
} from "../../../../../../icons/atlas";
import { useEvent } from "../../../../../__utils/react";
import type { InsertOption } from "../../../insert-options";
import {
  INSERT_TARGETS,
  PARAGRAPH_STYLE_TARGETS,
} from "../../../insert-options-targets-order";
import { useHeading, useList, useParagraph } from "../../../selection-context";

export const insertOptions: InsertOption[] = [
  function useText() {
    const { isParagraph, toggleParagraph } = useParagraph();
    return useMemo(
      () => ({
        key: "text",
        label: "Text",
        icon: atlasTextFormatParagraph,
        onTrigger: toggleParagraph,
        active: isParagraph,
        targets: {
          paragraphStyle: PARAGRAPH_STYLE_TARGETS.TEXT,
          insert: INSERT_TARGETS.TEXT,
        },
      }),
      [isParagraph, toggleParagraph]
    );
  },

  function useLargeHeading() {
    const { headingType, toggleH1 } = useHeading();
    return useMemo(
      () => ({
        key: "large-heading",
        label: "Large heading",
        icon: atlasTextFormatHeadingLarge,
        onTrigger: toggleH1,
        active: headingType === "h1",
        targets: {
          paragraphStyle: PARAGRAPH_STYLE_TARGETS.LARGE_HEADING,
          insert: INSERT_TARGETS.LARGE_HEADING,
        },
      }),
      [headingType, toggleH1]
    );
  },

  function useSmallHeading() {
    const { headingType, toggleH2 } = useHeading();
    return useMemo(
      () => ({
        key: "small-heading",
        label: "Small heading",
        icon: atlasTextFormatHeadingMedium,
        onTrigger: toggleH2,
        active: headingType === "h2",
        targets: {
          paragraphStyle: PARAGRAPH_STYLE_TARGETS.SMALL_HEADING,
          insert: INSERT_TARGETS.SMALL_HEADING,
        },
      }),
      [headingType, toggleH2]
    );
  },

  function useBulletedList() {
    const { listType, toggleUnorderedList } = useList();
    return useMemo(
      () => ({
        key: "bulleted-list",
        label: "Bulleted list",
        icon: atlasTextFormatListBullet,
        onTrigger: toggleUnorderedList,
        active: listType === "unordered",
        targets: {
          paragraphStyle: PARAGRAPH_STYLE_TARGETS.BULLETED_LIST,
          insert: INSERT_TARGETS.BULLETED_LIST,
        },
      }),
      [listType, toggleUnorderedList]
    );
  },

  function useNumberedList() {
    const { listType, toggleOrderedList } = useList();
    return useMemo(
      () => ({
        key: "numbered-list",
        label: "Numbered list",
        icon: atlasTextFormatListNumber,
        onTrigger: toggleOrderedList,
        active: listType === "ordered",
        targets: {
          paragraphStyle: PARAGRAPH_STYLE_TARGETS.NUMBERED_LIST,
          insert: INSERT_TARGETS.NUMBERED_LIST,
        },
      }),
      [listType, toggleOrderedList]
    );
  },

  function useLineSeparator() {
    const [editor] = useLexicalComposerContext();
    const insertSeparator = useEvent(() =>
      editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
    );
    return useMemo(
      () => ({
        key: "line-separator",
        label: "Line separator",
        icon: atlasDivider,
        onTrigger: insertSeparator,
        targets: {
          insert: INSERT_TARGETS.LINE_SEPARATOR,
        },
      }),
      [insertSeparator]
    );
  },
];
