import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import React from "react";

import {
  atlasArrowDown,
  atlasArrowLeft,
  atlasArrowRight,
  atlasArrowUp,
  atlasRestore,
} from "../../icons";
import { Combobox } from "../combobox-v2";
import { Icon } from "../icon/Icon";
import { Tabs } from "../tabs-v2";

// Provider Component
export interface ComboboxWithTabsProviderProps
  extends Ariakit.ComboboxProviderProps {
  /** The selected tab ID */
  tabId?: Ariakit.TabProviderProps["selectedId"];
  /** Callback to set the selected tab ID */
  setTabId?: (id: string) => void;
  /** Default selected tab ID */
  defaultTabId?: Ariakit.TabProviderProps["defaultSelectedId"];
  /** Callback when search value changes */
  onSearch?: Ariakit.ComboboxProviderProps["setValue"];
  /** Callback when selected tab changes */
  onTabChange?: (id: string) => void;
}

export function ComboboxWithTabsProvider(props: ComboboxWithTabsProviderProps) {
  const {
    tabId,
    setTabId,
    defaultTabId,
    onSearch,
    onTabChange,
    children,
    ...rest
  } = props;

  return (
    <Ariakit.ComboboxProvider
      {...rest}
      setValue={(value) => {
        rest.setValue?.(value);
        React.startTransition(() => {
          onSearch?.(value);
        });
      }}
    >
      <Ariakit.TabProvider
        selectedId={tabId}
        defaultSelectedId={defaultTabId}
        setSelectedId={(id) => {
          if (!id) return;
          setTabId?.(id);
          React.startTransition(() => {
            onTabChange?.(id);
          });
        }}
      >
        {children}
      </Ariakit.TabProvider>
    </Ariakit.ComboboxProvider>
  );
}

export interface ComboboxPopoverWithShortcutsProps
  extends Ariakit.ComboboxPopoverProps {
  showKeyboardShortcuts?: boolean;
  showTabShortcuts?: boolean;
  className?: string;
}

export const ComboboxPopoverWithShortcuts = React.forwardRef<
  HTMLDivElement,
  ComboboxPopoverWithShortcutsProps
>((props, ref) => {
  const {
    showKeyboardShortcuts = true,
    showTabShortcuts = true,
    className,
    children,
    ...rest
  } = props;

  const combobox = Ariakit.useComboboxContext();
  const isInputActive = Ariakit.useStoreState(
    combobox,
    (state) => state?.activeId === null
  );

  return (
    <Combobox.Popover ref={ref} className={className} {...rest}>
      {children}
      {showKeyboardShortcuts && (
        <div className="sticky bottom-0 mt-auto hidden sm:flex flex-none gap-4 overflow-x-auto border-t border-gray-border p-2 text-body-xs bg-white text-subtle">
          <div className="flex gap-1 whitespace-nowrap">
            <kbd className="flex items-center py-0.5 rounded ">
              <Icon content={atlasArrowUp} className="w-3 h-3" />
            </kbd>
            <kbd className="flex items-center py-0.5 rounded ">
              <Icon content={atlasArrowDown} className="w-3 h-3" />
            </kbd>
            to navigate
          </div>
          {showTabShortcuts && !isInputActive && (
            <div className="flex gap-1 whitespace-nowrap">
              <kbd className="flex items-center py-0.5 rounded ">
                <Icon content={atlasArrowLeft} className="w-3 h-3" />
              </kbd>
              <kbd className="flex items-center py-0.5 rounded ">
                <Icon content={atlasArrowRight} className="w-3 h-3" />
              </kbd>
              to switch tabs
            </div>
          )}
          {!isInputActive && (
            <div className="flex gap-1 whitespace-nowrap">
              <kbd className="flex items-center py-0.5 rounded ">
                <Icon content={atlasRestore} className="w-3 h-3" />
              </kbd>
              to select
            </div>
          )}
        </div>
      )}
    </Combobox.Popover>
  );
});

ComboboxPopoverWithShortcuts.displayName = "ComboboxPopoverWithShortcuts";

// Panel Component
export interface ComboboxTabsPanelProps extends Ariakit.TabPanelProps {
  className?: string;
}

export const ComboboxTabsPanel = React.forwardRef<
  HTMLDivElement,
  ComboboxTabsPanelProps
>((props, ref) => {
  const { className, tabId, children } = props;

  return (
    <Tabs.Panel tabId={tabId} ref={ref}>
      <Combobox.List className={clsx("px-2 pb-2", className)}>
        {children}
      </Combobox.List>
    </Tabs.Panel>
  );
});

ComboboxTabsPanel.displayName = "ComboboxTabsPanel";
