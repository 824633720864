import { getBackgroundColorFromConfig } from "../../utils/colors";
import { CalendarEvent } from "../../utils/types";

export function EventSpine({
  event,
}: {
  event: Pick<CalendarEvent, "colorConfig">;
}) {
  const activeColor = getBackgroundColorFromConfig({
    color: event.colorConfig.color,
    variant: "active",
  });

  return (
    <div
      style={{
        height: "100%",
        minWidth: 4,
        width: 4,
        backgroundColor: activeColor,
      }}
    />
  );
}
