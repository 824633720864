import { ScheduledInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { SchedulerEditableScheduledInterview } from "client/scheduler/utils/types";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

type ViewFilter = {
  // guideId required for now
  guideId: string;
  // Explicitly force null to be passed for other filters, so we don't just forget to pass them
  scheduledInterviewGroupId: string | null;
  schedulingRequestId: string | null;
};

/** Give context to the current view of the scheduler for nested queries and mutations */
export const viewFilterAtom = atom<ViewFilter>({
  guideId: "",
  scheduledInterviewGroupId: null,
  schedulingRequestId: null,
});

type Navigation = {
  leftPanelOpen: boolean;
  rightPanelOpen: boolean;
};
export const navigationAtom = atom<Navigation>({
  leftPanelOpen: true,
  rightPanelOpen: true,
});

/** The original interviews at the time of hydration, to diff against. Do not write to this field outside of hydration. */
export const originalInterviewsAtom = atom<
  SchedulerEditableScheduledInterview[]
>([]);

/** All staged and un-staged interviews we are viewing within the scheduler */
export const interviewsAtom = atom<SchedulerEditableScheduledInterview[]>([]);

/** Hoist the group settings form up for access in the forms */
export const scheduledInterviewGroupSettingsAtom =
  atom<ScheduledInterviewGroupSettings | null>(null);

/** ID of the current viewing interview */
export const selectedInterviewIdAtom = atom<string | null>(null);

/** Derived: Look up the selected interview from state based on selected interview ID */
export const selectedInterviewAtom = atom((get) => {
  const id = get(selectedInterviewIdAtom);
  const interviews = get(interviewsAtom);

  if (!id) {
    return null;
  }

  return interviews.find((interview) => interview.id === id) ?? null;
});

/** Store the state of an interviewer slot having config open, as this impacts what interviewer calendar events we show */
export const interviewerSlotSettingsOpenAtomFamily = atomFamily(
  (_id: string) =>
    atom({ userMembershipsOpen: false, shadowingUserMembershipsOpen: false }),
  (a, b) => a === b
);
