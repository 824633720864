import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBlockParagraph } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import TextArea from "@resource/atlas/textfield/TextArea";
import { TextWithLinks } from "client/components/generic/misc/TextWithLinks";
import RequestInfoRow from "components/RequestInfoRow";
import { useCallback, useEffect, useState } from "react";

type SchedulingRequestNotesRowProps = {
  notes?: string | null;
  editNotes: (notes: string) => void;
  loading: boolean;
};

export function SchedulingRequestNotesRow({
  notes,
  editNotes,
  loading,
}: SchedulingRequestNotesRowProps) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(notes ?? "");

  const inputRef = useCallback((item: HTMLTextAreaElement | null) => {
    if (!item) {
      return;
    }

    item.focus();
  }, []);

  useEffect(() => {
    setValue(notes ?? "");
  }, [notes]);

  const save = useCallback(() => {
    editNotes(value);
    setEditing(false);
  }, [editNotes, value]);

  return (
    <RequestInfoRow
      icon={<Icon content={atlasBlockParagraph} />}
      label="Notes"
      multiline
    >
      {loading && <LoadingIndicator size="small" />}
      {editing && !loading && (
        <TextArea
          className="w-full text-body-sm"
          inputRef={inputRef}
          onBlur={save}
          value={value}
          onChange={setValue}
          onFocus={(e) => {
            // Focus end of text
            if (e.currentTarget instanceof HTMLTextAreaElement) {
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              );
            }
          }}
        />
      )}
      {!editing && !loading && (
        <div className="flex flex-col gap-2 w-full group items-start">
          {notes ? (
            <>
              <TextWithLinks
                text={notes}
                trailingContent={
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    as="button"
                    className="!text-subtle"
                    onClick={() => setEditing(true)}
                  >
                    Edit
                  </Link>
                }
              />
            </>
          ) : (
            <>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                as="button"
                className="!text-subtle"
                onClick={() => setEditing(true)}
              >
                Add notes...
              </Link>
            </>
          )}
        </div>
      )}
    </RequestInfoRow>
  );
}
