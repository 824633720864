import { Button } from "@resource/atlas/button/Button";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

export type ClearCandidateAvailabilityConfirmationModalProps = {
  store: DialogStore;
  guideId: string;
};

const CLEAR_CANDIDATE_AVAILABILITY = gql(`
  mutation ClearCandidateAvailability($input: ClearGuideCurrentAvailabilitySubmissionInput!) {
    clearGuideCurrentAvailabilitySubmission(input: $input) {
      success
      message
      code
      guide {
        id
        ...GuideForGlobalAvailabilityForCandidateProfile
      }
    }
  }
`);

export function ClearCandidateAvailabilityConfirmationDialog({
  store,
  guideId,
}: ClearCandidateAvailabilityConfirmationModalProps) {
  const logEvent = useLogEvent({
    component: "ClearCandidateAvailabilityConfirmationDialog",
  });
  const [clearCandidateAvailability, { loading: submitLoading }] = useMutation(
    CLEAR_CANDIDATE_AVAILABILITY
  );

  const handleClear = useCallback(() => {
    clearCandidateAvailability({
      variables: {
        input: {
          guideId,
        },
      },
      onCompleted: () => {
        logEvent("Candidate Availability Cleared");
        store.hide();
      },
    });
  }, [clearCandidateAvailability, guideId, logEvent, store]);

  return (
    <Dialog store={store}>
      <View
        header={{
          title: "Clear availability",
        }}
        footer={{
          leftActions: (
            <Button isGhost onClick={store.hide}>
              Cancel
            </Button>
          ),
          rightActions: (
            <Button
              variant="danger"
              onClick={handleClear}
              isLoading={submitLoading}
            >
              Clear
            </Button>
          ),
        }}
      >
        <div className="text-body-md space-y-3">
          <p>
            This will clear the candidate&apos;s current availability
            submission.
          </p>
          <p className="text-body-md-heavy">
            Are you sure you want to continue?
          </p>
        </div>
      </View>
    </Dialog>
  );
}
