import { EditConferenceRoomSlot } from "client/components/conference-rooms/display/EditConferenceRoomSlot";
import { mapConferenceRoomToConferenceRoomForForm } from "client/components/conference-rooms/utils/mapping";
import {
  ConferenceRoom,
  ConferenceRoomSettings,
} from "client/components/conference-rooms/utils/types";
import { useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";

import { useConferenceRoomsForForm } from "../__hooks/useConferenceRoomsForForm";
import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewConferenceRoom({
  state,
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const { form, originalInterview } = state;
  const onChangeConferenceRoomSlot = useCallback(
    ({
      room,
      settings,
    }: {
      room?: ConferenceRoom | null;
      settings?: ConferenceRoomSettings | null;
    }) => {
      const prevSlot = form.getValues("scheduledInterview.conferenceRoomSlot");
      const mappedUpdatedRoom = room
        ? mapConferenceRoomToConferenceRoomForForm(room)
        : null;
      // undefined means use existing, null means remove
      const updatedRoom =
        room !== undefined ? mappedUpdatedRoom : prevSlot?.conferenceRoom;
      const updatedSettings =
        settings !== undefined ? settings : prevSlot?.settings;

      if (!updatedRoom && !updatedSettings) {
        // if both are null, remove the slot
        form.setValue("scheduledInterview.conferenceRoomSlot", null, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        form.setValue(
          "scheduledInterview.conferenceRoomSlot",
          {
            conferenceRoom: updatedRoom ?? null,
            settings: updatedSettings ?? null,
          },
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
    },
    [form]
  );

  const onChangeConferenceRoom = useCallback(
    (rooms: ConferenceRoom[]) => {
      const selectedRoom = rooms.length > 0 ? rooms[0] : null;

      onChangeConferenceRoomSlot({
        room: selectedRoom,
      });
    },
    [onChangeConferenceRoomSlot]
  );
  const onChangeSettings = useCallback(
    (settings: ConferenceRoomSettings | null) => {
      onChangeConferenceRoomSlot({
        settings,
      });
    },
    [onChangeConferenceRoomSlot]
  );
  const { buildings, conferenceRooms, loading, refetch } =
    useConferenceRoomsForForm({
      state,
    });
  const showLoading = useMemo(() => {
    if (!buildings.length && !conferenceRooms.length && loading) {
      return true;
    }

    return false;
  }, [buildings, conferenceRooms, loading]);

  return (
    <Controller
      name="scheduledInterview.conferenceRoomSlot"
      control={form.control}
      render={({ field }) => (
        <EditConferenceRoomSlot
          onSelect={onChangeConferenceRoom}
          selectedIds={
            field.value?.conferenceRoom ? [field.value.conferenceRoom.id] : []
          }
          buildings={buildings}
          conferenceRooms={conferenceRooms}
          loading={showLoading}
          onOpen={refetch}
          settings={field.value?.settings}
          originalSettings={originalInterview?.conferenceRoomSlot?.settings}
          onChangeSettings={onChangeSettings}
        />
      )}
    />
  );
}
