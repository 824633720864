import {
  atlasGlobe,
  atlasLink,
  atlasLogoGithub,
  atlasLogoGitlab,
  atlasLogoLinkedin,
  atlasLogoStravaColor,
  atlasLogoStravaOrangeColor,
  atlasLogoTwitter,
  atlasLogoYoutube,
  serviceGithub,
  serviceLinkedin,
  serviceTwitter,
} from "@resource/atlas/icons";
import _ from "lodash";

type ProfileType =
  | "linkedin"
  | "github"
  | "gitlab"
  | "twitter"
  | "youtube"
  | "strava";

export const typeForProfileUrl = (url: string): ProfileType | undefined => {
  if (_.includes(url, "linkedin.com")) {
    return "linkedin";
  }
  if (_.includes(url, "github.com")) {
    return "github";
  }
  if (_.includes(url, "gitlab.com")) {
    return "gitlab";
  }
  if (_.includes(url, "twitter.com")) {
    return "twitter";
  }
  if (_.includes(url, "youtube.com") || _.includes(url, "youtu.be")) {
    return "youtube";
  }
  if (_.includes(url, "strava.com")) {
    return "strava";
  }
  return undefined;
};

export const iconForProfileUrl = (url: string) => {
  switch (typeForProfileUrl(url)) {
    case "linkedin":
      return serviceLinkedin;
    case "github":
      return serviceGithub;
    case "twitter":
      return serviceTwitter;
    case "strava":
      return atlasLogoStravaOrangeColor;
    case "youtube":
    case "gitlab":
    default:
      return atlasLink;
  }
};

export const monochromeIconForProfileUrl = (url: string) => {
  switch (typeForProfileUrl(url)) {
    case "linkedin":
      return atlasLogoLinkedin;
    case "github":
      return atlasLogoGithub;
    case "twitter":
      return atlasLogoTwitter;
    case "youtube":
      return atlasLogoYoutube;
    case "gitlab":
      return atlasLogoGitlab;
    case "strava":
      return atlasLogoStravaColor;
    default:
      return atlasGlobe;
  }
};

export const labelForProfileUrl = (url: string) => {
  switch (typeForProfileUrl(url)) {
    case "linkedin":
      return "LinkedIn";
    case "github":
      return "Github";
    case "gitlab":
      return "Gitlab";
    case "twitter":
      return "Twitter";
    case "youtube":
      return "YouTube";
    default:
      // replace http:// or http:s// with empty string in the url
      return url.replace(/^(http:\/\/|https:\/\/)/, "");
  }
};

export default iconForProfileUrl;
