import { View } from "@resource/atlas/view/View";
import { BackButton } from "client/components/generic/layout/BackButton";
import {
  CreateSchedulingRequestForm,
  CreateSchedulingRequestFormOnSubmitProps,
} from "client/components/scheduling-tasks/draft/CreateSchedulingRequestForm";
import { InterviewRequirementsConfigurationProvider } from "client/scheduling-requirements-configuration/context";
import { mapInterviewRequirementV2InputFromInterviewRequirementFragment } from "client/scheduling-requirements-configuration/utils";
import ErrorPage from "components/Generic/ErrorPage";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import {
  GuideForCreateSchedulingRequestFragment,
  SchedulingRequestForEditingFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { formatEntity } from "shared/constants/entities";
import { useQueryStringValue } from "utils/next";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

gql(`
fragment SchedulingRequestForEditing on SchedulingRequest {
    id
    priority
    assignedTo {
      id
      ...UserMembershipForAssigneeSelection
    }
    ...SchedulingRequestForSchedulingRequestCard
    schedulingRequestTemplate {
      ...SchedulingRequestTemplateForCreateSchedulingRequest
    }
    interviewPanelRequirement {
      ...InterviewPanelRequirementForConfiguration
    }
    guide {
      id
      ...GuideForCreateSchedulingRequest
      atssyncApplication {
        id
        atsUrl
      }
    }
    notes
}`);

const GET_SCHEDULING_REQUEST_FOR_EDIT_QUERY = gql(`
  query GetSchedulingRequest($schedulingRequestId: ID!) {
    schedulingRequestById(id: $schedulingRequestId) {
      ...SchedulingRequestForEditing
    }
  }
`);

const UPDATE_SCHEDULING_REQUEST_V2 = gql(`
  mutation UpdateSchedulingRequestV2($input: UpdateSchedulingRequestV2Input!) {
    updateSchedulingRequestV2(input: $input) {
      success
      message
      code
      schedulingRequest {
        ...SchedulingRequestForEditing
      }
    }
  }
`);

function EditSchedulingRequestDisplay({
  guide,
  schedulingRequest,
  onBack,
  onCompleted,
}: {
  guide: GuideForCreateSchedulingRequestFragment;
  schedulingRequest: SchedulingRequestForEditingFragment;
  onBack: () => void;
  onCompleted: () => void;
}) {
  const [updateSchedulingRequestV2] = useMutation(UPDATE_SCHEDULING_REQUEST_V2);
  const onUpdateSchedulingRequest = useCallback(
    async ({
      interviewRequirements,
      requireVideoConferencing,
      algorithmSettings,
      reuseVideoConferencingLink,
      assigneeId,
      notes,
      priority,
      location,
    }: CreateSchedulingRequestFormOnSubmitProps) => {
      await updateSchedulingRequestV2({
        variables: {
          input: {
            schedulingRequestId: schedulingRequest.id,
            data: {
              ...(notes && { notes }),
              ...(priority && { priority }),
              assigneeId,
              interviewPanelRequirement: {
                id: schedulingRequest.interviewPanelRequirement.id,
                interviewRequirements: interviewRequirements.map(
                  mapInterviewRequirementV2InputFromInterviewRequirementFragment
                ),
                requireVideoConferencing,
                algorithmSettings,
                reuseVideoConferencingLink,
                location,
              },
            },
          },
        },
        onCompleted: () => {
          onCompleted();
        },
      });
    },
    [
      schedulingRequest.id,
      schedulingRequest.interviewPanelRequirement.id,
      updateSchedulingRequestV2,
      onCompleted,
    ]
  );

  return (
    <CreateSchedulingRequestForm
      guide={guide}
      onSubmit={onUpdateSchedulingRequest}
      header={{
        title: `Edit ${formatEntity("request")} requirements`,
        leftActions: <BackButton onClick={onBack} />,
      }}
      assigmentOptionType="pick-assignee"
      workflowType="edit-task"
    />
  );
}

/**
 * View for editing a scheduling task
 */
export function EditSchedulingRequestPage({
  schedulingTaskId,
  onBack,
  onCompleted,
}: {
  schedulingTaskId?: string;
  onBack: () => void;
  onCompleted: () => void;
}) {
  const querySchedulingRequestId = useQueryStringValue("schedulingRequestId");
  const schedulingRequestId = schedulingTaskId ?? querySchedulingRequestId;

  const { data } = useQuery(GET_SCHEDULING_REQUEST_FOR_EDIT_QUERY, {
    variables: {
      schedulingRequestId: schedulingRequestId as string,
    },
    skip: !schedulingRequestId,
  });

  if (!schedulingRequestId) {
    return <div>Must include schedulingRequestId as query param</div>;
  }

  if (!data) {
    return (
      <View
        header={{
          leftActions: <BackButton onClick={onBack} />,
        }}
      >
        <Loading />
      </View>
    );
  }

  const request = data.schedulingRequestById;

  if (!request) {
    return (
      <View
        header={{
          leftActions: <BackButton onClick={onBack} />,
        }}
      >
        <ErrorPage errorCode="404" />
      </View>
    );
  }

  return (
    <InterviewRequirementsConfigurationProvider
      defaultInterviewPanelRequirement={request.interviewPanelRequirement}
      defaultNotes={request.notes}
      defaultPriority={request.priority}
      defaultAssignee={request.assignedTo}
    >
      <EditSchedulingRequestDisplay
        guide={request.guide}
        schedulingRequest={request}
        onBack={onBack}
        onCompleted={onCompleted}
      />
    </InterviewRequirementsConfigurationProvider>
  );
}
