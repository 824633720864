import { Button as AriaButton } from "@ariakit/react";
import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { ViewProps } from "@resource/atlas/view/View";
import { DatePicker } from "client/app/__components/DatePicker";
import { Toggle } from "client/app/__components/Toggle";
import { InterviewCalendarDisplay } from "client/app/(account settings)/company/__components/InterviewCalendarDisplay";
import { TimeSelect } from "client/components/display/times/TimeSelect";
import { GuideAutoResponseEditor } from "client/components/guide-content/GuideAutoResponseEditor";
import clsx from "clsx";
import { WorkingHours } from "components/WorkingHours";
import { RoleEnum } from "enums/role-enum";
import { gql } from "generated/graphql-codegen";
import { DateTime } from "luxon";
import { ReactElement, useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";
import { displayTimezone } from "shared/utils/timezones";

import { EditPreferencesState } from "./__hooks/useEditPreferencesState";
import { EditTimezoneForm } from "./EditTimezoneForm";
import { FormLabels } from "./types";

const MAX_WIDTH_CLASS = "max-w-[420px]";

gql(`
  fragment InterviewerWorkingHoursForAvailabilityDisplay on WorkingHoursConfig {
    isWorkingDay
    startTime {
      hour
      minute
    }
    endTime {
      hour
      minute
    }
  }
`);

gql(`fragment UserMembershipForPreferences on UserMembership {
  id
  name
  highestRole {
    id
    name
  }
  workingHours {
    ...InterviewerWorkingHoursForAvailabilityDisplay
  }
  maxInterviewLoadPerDay
  maxInterviewLoadPerWeek
  addAsOptionalParticipantForScheduling
  receiveNotificationsWhenRecruiter
  receiveNotificationsWhenCoordinator
  defaultCalendarId
  user {
    id
    timezone
  }
  autoResponse {
    id
    startAt
    endAt
    data
  }
}`);

type EditProfileFormProps = {
  customLabels?: FormLabels;
  viewProps?: ViewProps<"form">;
  state: EditPreferencesState;
};

const defaultLabels: FormLabels = {
  workingHours: "Working hours",
  timezone: "Timezone",
  addAsOptionalParticipantForScheduling: "Communications defaults",
  defaultCalendarId: "Default scheduling calendar",
};

export function EditPreferencesForm({
  customLabels = {},
  state,
}: EditProfileFormProps): ReactElement {
  const { form, isSelf, userMembership, onValidTimeRange, onInvalidTimeRange } =
    state;
  const timezone = form.watch("timezone");
  const onChangeTimezone = useCallback(
    (tz: string) => {
      form.setValue("timezone", tz, {
        shouldDirty: true,
      });
    },
    [form]
  );
  const { control, getValues, setValue } = form;
  const formLabels: FormLabels = {
    ...defaultLabels,
    ...customLabels,
  };

  const isInterviewer =
    isSelf && userMembership.highestRole?.id === RoleEnum.Interviewer;

  return (
    <div className="flex-col flex-grow space-y-6 pb-10">
      <EditTimezoneForm
        timezone={timezone}
        onChange={onChangeTimezone}
        className={MAX_WIDTH_CLASS}
      />
      {!isInterviewer && isSelf && (
        <>
          <div className="space-y-2">
            <div className="text-body-md-heavy">
              {formLabels.addAsOptionalParticipantForScheduling}
            </div>
            <Controller
              name="addAsOptionalParticipantForScheduling"
              control={control}
              render={({ field }) => (
                <AriaButton
                  className="flex items-center gap-2 text-body-md"
                  onClick={() => field.onChange(!field.value)}
                >
                  <Checkbox checked={field.value} />
                  {isSelf ? (
                    <span className="text-left">
                      Add me as an optional attendee to all Interviewer invites
                      for candidates I am the recruiter for
                    </span>
                  ) : (
                    <span className="text-left">
                      Add user as an optional attendee to all Interviewer
                      invites for candidates they are the recruiter for
                    </span>
                  )}
                </AriaButton>
              )}
            />
            <Controller
              name="receiveNotificationsWhenRecruiter"
              control={control}
              render={({ field }) => (
                <AriaButton
                  className="flex items-center gap-2 text-body-md"
                  onClick={() => field.onChange(!field.value)}
                >
                  <Checkbox checked={field.value} />
                  {isSelf ? (
                    <span className="text-left">
                      Add me as a follower on all message threads with
                      candidates I am the recruiter for
                    </span>
                  ) : (
                    <span className="text-left">
                      Add user as a follower on all message threads with
                      candidates they are the recruiter for
                    </span>
                  )}
                </AriaButton>
              )}
            />
            <Controller
              name="receiveNotificationsWhenCoordinator"
              control={control}
              render={({ field }) => (
                <AriaButton
                  className="flex items-center gap-2 text-body-md"
                  onClick={() => field.onChange(!field.value)}
                >
                  <Checkbox checked={field.value} />
                  {isSelf ? (
                    <span className="text-left">
                      Add me as a follower on all message threads with
                      candidates I am the coordinator for
                    </span>
                  ) : (
                    <span className="text-left">
                      Add user as a follower on all message threads with
                      candidates they are the coordinator for
                    </span>
                  )}
                </AriaButton>
              )}
            />
          </div>

          <Controller
            name="defaultCalendarId"
            control={control}
            render={({ field }) => (
              <div className={clsx("space-y-3 w-full", MAX_WIDTH_CLASS)}>
                <p className="text-body-md-heavy">
                  {formLabels.defaultCalendarId}
                </p>
                <InterviewCalendarDisplay
                  userMembershipId={userMembership.id}
                  selectedInterviewCalendarId={field.value}
                  setInterviewCalendar={(calendarId) => {
                    field.onChange(calendarId);
                  }}
                />
              </div>
            )}
          />
        </>
      )}

      <Controller
        name="workingHours"
        control={control}
        render={({ field }) => {
          return (
            <WorkingHours
              {...field}
              className={MAX_WIDTH_CLASS}
              label={formLabels.workingHours}
              onInvalidTimeRange={onInvalidTimeRange}
              onValidTimeRange={onValidTimeRange}
              subtext={`Working hours will be displayed in ${
                isSelf ? "your" : "the user's"
              } timezone (${displayTimezone(
                timezone ?? "UTC",
                "abbreviation"
              )}).`}
            />
          );
        }}
      />
      <Controller
        name="maxInterviewLoadPerDay"
        control={control}
        render={({ field }) => {
          return (
            <div className="space-y-2">
              <div className="text-body-md-heavy">Max interviews per day</div>
              <input
                {...field}
                placeholder="1"
                value={field.value ?? undefined}
                onChange={(e) =>
                  field.onChange(e.target.value ? Number(e.target.value) : null)
                }
                min="0"
                type="number"
                className="border-2 border-gray-300 rounded-md p-2"
              />
            </div>
          );
        }}
      />
      <Controller
        name="maxInterviewLoadPerWeek"
        control={control}
        render={({ field }) => {
          return (
            <div className="space-y-2">
              <div className="text-body-md-heavy">Max interviews per week</div>
              <input
                {...field}
                placeholder="5"
                value={field.value ?? undefined}
                onChange={(e) =>
                  field.onChange(e.target.value ? Number(e.target.value) : null)
                }
                type="number"
                min="0"
                className="border-2 border-gray-300 rounded-md p-2"
              />
            </div>
          );
        }}
      />
      {isSelf && (
        <div className="space-y-4 text-body-md">
          <div className="space-y-2">
            <div className="text-body-md-heavy">Out of office responder</div>
            <div className="flex gap-2 items-center">
              <Toggle
                value={!!getValues("outOfOfficeConfig")}
                onChange={(value) => {
                  if (value) {
                    setValue(
                      "outOfOfficeConfig",
                      {
                        startAt: DateTime.now().startOf("hour").toJSDate(),
                        endAt: DateTime.now()
                          .startOf("hour")
                          .plus({ day: 7 })
                          .toJSDate(),
                        data: JSON.parse(LEXICAL_EMPTY_STATE),
                      },
                      { shouldDirty: true, shouldValidate: true }
                    );
                    setTimeout(() => state.editor?.focus());
                  } else {
                    setValue("outOfOfficeConfig", null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
              />
              <span>Send an automated reply to incoming Guide messages.</span>
            </div>
          </div>
          {getValues("outOfOfficeConfig") && (
            <div className="space-y-4 ml-10">
              <div className="space-y-2">
                <div className="text-body-md-heavy">Message</div>
                <Controller
                  name="outOfOfficeConfig.data"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <GuideAutoResponseEditor
                        {...state.contentEditorProps}
                        className="h-[24rem] px-6 py-2 shadow-1 rounded-md"
                        initialState={field.value}
                        onChange={(editorState) => {
                          field.onChange(editorState.toJSON());
                        }}
                      />
                      {error && (
                        <div className="text-red-500">{error.message}</div>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="space-y-2">
                <div className="text-body-md-heavy">Start time</div>
                <div className="flex gap-2">
                  <Controller
                    name="outOfOfficeConfig.startAt"
                    control={control}
                    render={({ field }) => (
                      <DateTimeController
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <div className="text-body-md-heavy">End time</div>
                <div className="flex gap-2">
                  <Controller
                    name="outOfOfficeConfig.endAt"
                    control={control}
                    render={({ field }) => (
                      <DateTimeController
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function DateTimeController({
  value,
  onChange,
}: {
  value: Date;
  onChange: (value: Date) => void;
}) {
  const dt = useMemo(() => {
    return DateTime.fromJSDate(value);
  }, [value]);
  const time = useMemo(() => {
    return {
      hour: dt.hour,
      minute: dt.minute,
    };
  }, [dt]);

  const onDateChange = useCallback(
    (val: DateTime) => {
      return onChange(
        dt
          .set({
            year: val.year,
            day: val.day,
            month: val.month,
          })
          .toJSDate()
      );
    },
    [dt, onChange]
  );

  const onTimeChange = useCallback(
    (val: { hour: number; minute: number }) => {
      return onChange(dt.set(val).toJSDate());
    },
    [dt, onChange]
  );

  return (
    <>
      <DatePicker value={dt} onChange={onDateChange} />
      <TimeSelect
        placement="top"
        className="w-32"
        value={time}
        onChange={onTimeChange}
      />
    </>
  );
}

export default EditPreferencesForm;
