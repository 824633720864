import { Icon } from "@resource/atlas/icon/Icon";
import { ScheduleInterviewForInterviewDetailsFragment } from "generated/graphql-codegen/graphql";

import { useScheduledInterviewStatuses } from "../../__hooks/useStatuses";

/**
 * Show statuses for the interview
 */
export function ScheduledInterviewDetailsStatuses({
  scheduledInterview,
}: {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
}) {
  const statuses = useScheduledInterviewStatuses({
    scheduledInterview,
  });

  return (
    <>
      {statuses.map((status) => (
        <div className="flex w-full space-x-3" key={status.message}>
          <Icon content={status.icon} className="h-5 w-5" />
          <span className="truncate" title={status.message}>
            {status.message}
          </span>
        </div>
      ))}
    </>
  );
}
