import { Icon } from "@resource/atlas/icon/Icon";
import { Link } from "@resource/atlas/link/Link";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { InterviewDetailsLocationInterviewFragment } from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef } from "react";

import { getDisplayInfo, useExternalServiceLabelAndIcon } from "../utils";

const INTERVIEW_FRAGMENT = gql(`
fragment InterviewDetailsLocationInterview on ScheduledInterview {
    id
    videoConferencingUrl
    conferencePhone
    collaborativeCodingUrl
  }
`);

function ExternalServiceLink({ url }: { url?: string | null }) {
  const { icon, label } = useExternalServiceLabelAndIcon({
    url,
  });

  if (!url || !icon) {
    return null;
  }

  const { displayLabel, displayUrl, href } = getDisplayInfo({
    url,
    label,
  });

  return (
    <div className="flex gap-2">
      <Icon content={icon} className="w-5" />
      <Link
        as="a"
        href={href}
        target="_blank"
        className="group text-body-sm !text-dark !no-underline"
        onClick={(e) => e.stopPropagation()}
      >
        <p className="text-body-md text-dark group-hover:underline">
          {displayLabel}
        </p>
        {displayUrl && (
          <p className="text-subtle group-hover:underline">{displayUrl}</p>
        )}
      </Link>
    </div>
  );
}

type LocationSectionProps = ComponentPropsWithoutRef<"section"> & {
  interview?: InterviewDetailsLocationInterviewFragment;
};
export function ConferencingSection({
  interview,
  className,
  ...props
}: LocationSectionProps) {
  const shouldShowExternalServiceLink =
    !!interview?.videoConferencingUrl ||
    !!interview?.collaborativeCodingUrl ||
    !!interview?.conferencePhone;
  const shouldShowLocationSection = shouldShowExternalServiceLink;
  if (!shouldShowLocationSection) {
    return null;
  }

  return (
    <section
      className={clsx(
        "flex flex-col gap-3 bg-light-gray-100 rounded-md p-4",
        className
      )}
      {...props}
    >
      <ExternalServiceLink url={interview.videoConferencingUrl} />
      <ExternalServiceLink url={interview.collaborativeCodingUrl} />
      <ExternalServiceLink url={interview.conferencePhone} />
    </section>
  );
}

ConferencingSection.fragments = {
  interview: INTERVIEW_FRAGMENT,
};
