import { atlasCircleWarning } from "@resource/atlas/icons";
import { gql } from "generated/graphql-codegen";
import {
  AsyncProcessingStatus,
  ScheduledInterviewForIntegrationStatusFragment,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

gql(`
  fragment ScheduledInterviewForIntegrationStatus on ScheduledInterview {
    id
    isCancelled
    createdByGuide
    greenhouseSyncStatus
    googleSyncStatus
    googleEvent {
      id
      eventId
      status
      htmlLink
      isValid
      hasChanges
    }
    atssyncScheduledInterview {
      id
      remoteId
      name
      remoteWasDeleted
    }
  }
`);

type StatusReturn = {
  icon: string | null;
  iconClassName: string | null;
  integrationsConnected: boolean;
  tooltipContent: string | undefined;
};

const checkGoogleConnected = (
  scheduledInterview: ScheduledInterviewForIntegrationStatusFragment
) => {
  if (
    !scheduledInterview.googleEvent ||
    !scheduledInterview.googleEvent.isValid
  ) {
    return false;
  }

  return true;
};

function getErrorMessage({
  scheduledInterview,
}: {
  scheduledInterview: ScheduledInterviewForIntegrationStatusFragment;
}) {
  // TODO: Infer integration type from account. Cannot rely on atssyncScheduledInterview connection as it could be null
  const atsName = "Greenhouse";

  const googleIsConnected = checkGoogleConnected(scheduledInterview);
  const googleIsInSync =
    !googleIsConnected || !scheduledInterview.googleEvent?.hasChanges;

  const atsIsConnected =
    scheduledInterview.atssyncScheduledInterview &&
    !scheduledInterview.atssyncScheduledInterview.remoteWasDeleted;

  const showGoogleError =
    scheduledInterview.googleSyncStatus !== AsyncProcessingStatus.PENDING;
  const showAtsError =
    scheduledInterview.greenhouseSyncStatus !== AsyncProcessingStatus.PENDING;

  const integrationsSynced = !scheduledInterview.createdByGuide
    ? atsIsConnected
    : googleIsConnected && atsIsConnected && googleIsInSync;

  if (integrationsSynced) {
    return [];
  }

  if (!scheduledInterview.createdByGuide) {
    if (
      showAtsError &&
      (!scheduledInterview.atssyncScheduledInterview ||
        scheduledInterview.atssyncScheduledInterview?.remoteWasDeleted)
    ) {
      return [`${atsName} interview was deleted.`];
    }

    return [];
  }

  const messages = [];

  if (
    showGoogleError &&
    showAtsError &&
    !googleIsConnected &&
    !atsIsConnected
  ) {
    messages.push(
      `Unexpectedly lost connection to Google event and ${atsName}.`
    );
  } else {
    if (showGoogleError && !googleIsConnected) {
      messages.push("Unexpectedly lost connection to Google event.");
    }
    if (showAtsError && !atsIsConnected) {
      messages.push(`Unexpectedly lost connection to ${atsName}.`);
    }
  }

  if (showGoogleError && !googleIsInSync) {
    messages.push("The Google Calendar event is out of sync with Guide.");
  }

  return messages;
}

export function useSchedulerIntegrationStatus({
  scheduledInterview,
}: {
  scheduledInterview:
    | ScheduledInterviewForIntegrationStatusFragment
    | ScheduledInterviewForIntegrationStatusFragment[];
}): StatusReturn {
  const scheduledInterviewsList = useMemo(() => {
    return Array.isArray(scheduledInterview)
      ? scheduledInterview
      : [scheduledInterview];
  }, [scheduledInterview]);

  const errorMessages = useMemo(() => {
    const interviewsNotCancelled = scheduledInterviewsList.filter(
      (si) => !si.isCancelled
    );
    return interviewsNotCancelled
      .map((si) => getErrorMessage({ scheduledInterview: si }))
      .filter((error) => error.length > 0);
  }, [scheduledInterviewsList]);

  const icon = useMemo(() => {
    if (errorMessages.length === 0) {
      return null;
    }

    return atlasCircleWarning;
  }, [errorMessages]);

  const iconClassName = useMemo(() => {
    if (errorMessages.length === 0) {
      return null;
    }

    return "text-red-500";
  }, [errorMessages]);

  const tooltipContent = useMemo(() => {
    if (errorMessages.length === 0) {
      return [];
    }
    if (scheduledInterviewsList.length === 1 && errorMessages.length === 1) {
      return errorMessages[0];
    }

    return [`${errorMessages.length} interviews have issues.`];
  }, [scheduledInterviewsList, errorMessages]);

  return useMemo((): StatusReturn => {
    return {
      icon,
      iconClassName,
      integrationsConnected: tooltipContent.length === 0,
      tooltipContent: tooltipContent.length
        ? tooltipContent.join(" ")
        : undefined,
    };
  }, [icon, iconClassName, tooltipContent]);
}

export function useLiteIntegrationStatus({
  scheduledInterview,
}: {
  scheduledInterview: ScheduledInterviewForIntegrationStatusFragment;
}): StatusReturn {
  // TODO: Infer integration type from account. Cannot rely on atssyncScheduledInterview connection as it could be null
  const atsName = "Greenhouse";
  const atsIsConnected = useMemo(() => {
    if (
      scheduledInterview.greenhouseSyncStatus !==
        AsyncProcessingStatus.PENDING &&
      (!scheduledInterview.atssyncScheduledInterview ||
        scheduledInterview.atssyncScheduledInterview.remoteWasDeleted)
    ) {
      return false;
    }

    return true;
  }, [
    scheduledInterview.atssyncScheduledInterview,
    scheduledInterview.greenhouseSyncStatus,
  ]);

  if (!atsIsConnected) {
    return {
      icon: atlasCircleWarning,
      iconClassName: "text-red-500",
      integrationsConnected: false,
      tooltipContent: `${atsName} interview was deleted.`,
    };
  }

  return {
    icon: null,
    iconClassName: null,
    integrationsConnected: true,
    tooltipContent: undefined,
  };
}
