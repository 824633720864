import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { linkCardModule as _linkCardModule } from "@resource/atlas/content-editor/link-card";

import { useFetchUrlMetadata, useReplaceImageData } from "./utils";

export function useLinkCardModule() {
  const { onReplaceImage, fileChooserInput } = useReplaceImageData();

  return withConfig(_linkCardModule, {
    fetchUrlMetadata: useFetchUrlMetadata(),
    onReplaceImage,
    tmpRender: fileChooserInput,
  });
}
