import { CreateGuidePostSchema } from "shared/guide-post/types";
import { z } from "zod";

export const GuidePostUserSchema = z.object({
  id: z.string().uuid().nullable().optional(),
  name: z.string().nullable().optional(),
  firstName: z.string().nullable().optional(),
  lastName: z.string().nullable().optional(),
  email: z.string().email(),
  imageUrl: z.string().url().nullable().optional(),
  isCandidate: z.boolean(),
});

export enum CandidateMessageType {
  AVAILABILITY_REQUEST = "AVAILABILITY_REQUEST",
  AVAILABILITY_REQUEST_FOLLOW_UP = "AVAILABILITY_REQUEST_FOLLOW_UP",
  INVALID_AVAILABILITY_FOLLOW_UP = "INVALID_AVAILABILITY_FOLLOW_UP",
  ADDITIONAL_AVAILABILITY_REQUEST = "ADDITIONAL_AVAILABILITY_REQUEST",
  INTERVIEW_CONFIRMATION = "INTERVIEW_CONFIRMATION",
  RESCHEDULE_CONFIRMATION = "RESCHEDULE_CONFIRMATION",
  SELF_SCHEDULE_REQUEST = "SELF_SCHEDULE_REQUEST",
}

export const CandidateMessageTypeSchema = z.nativeEnum(CandidateMessageType);

export const SendCandidateMessageActionDataSchema = z.object({
  guideId: z.string().uuid(),
  schedulingRequestId: z.string().uuid().optional(),
  guidePostInput: CreateGuidePostSchema,
  messageType: CandidateMessageTypeSchema,
});

export const SendCandidateMessageActionResultSchema = z.object({
  success: z.boolean().optional(),
  guidePostId: z.string().uuid().optional(),
  availabilityRequestId: z.string().uuid().optional(),
  selfScheduleRequestId: z.string().uuid().optional(),
});

export type SendCandidateMessageActionData = z.infer<
  typeof SendCandidateMessageActionDataSchema
>;

export const SendCandidateMessageActionSchema = {
  dataSchema: SendCandidateMessageActionDataSchema,
  resultSchema: SendCandidateMessageActionResultSchema,
};
