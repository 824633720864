import { FormGroup } from "client/components/generic/misc/FormGroup";
import { useCallback, useMemo } from "react";
import {
  InterviewerNoShow,
  InterviewerNoShowItem,
} from "shared/reporting/scheduled-interviews/types";

import {
  InterviewerMultiSelect,
  InterviewerMultiSelectItem,
} from "./InterviewerMultiSelect";

type InterviewerNoShowedSelectProps = {
  value: InterviewerNoShow;
  onChange: (value: InterviewerNoShow) => void;
  interviewers: InterviewerMultiSelectItem[];
};

/** Wrapper around `InterviewerMultiSelect` to allow choosing interviewer no-shows */
export function InterviewerNoShowedSelect({
  value,
  onChange,
  interviewers,
}: InterviewerNoShowedSelectProps) {
  const selectedInterviewerUserMembershipIds = useMemo(
    () => value.map((v) => v.interviewerUserMembershipId),
    [value]
  );
  const onChangeInterviewerUserMembershipIds = useCallback(
    (ids: string[]) => {
      const updatedValue = ids.map((id): InterviewerNoShowItem => {
        const existing = value.find(
          (v) => v.interviewerUserMembershipId === id
        );

        return existing || { interviewerUserMembershipId: id };
      });

      onChange(updatedValue);
    },
    [onChange, value]
  );

  return (
    <FormGroup
      label="Which interviewers didn't show to their interview?"
      isRequired
    >
      <InterviewerMultiSelect
        selectedInterviewerUserMembershipIds={
          selectedInterviewerUserMembershipIds
        }
        interviewers={interviewers}
        onChange={onChangeInterviewerUserMembershipIds}
      />
    </FormGroup>
  );
}
