import { TypedDocumentNode } from "@apollo/client";
import { Button } from "@resource/atlas/button/Button";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { gql } from "generated/graphql-codegen";
import { JobInterviewSelectBaseFragment } from "generated/graphql-codegen/graphql";
import useQuery from "utils/useQuery";

import { InterviewTitle } from "../guide/interviews/InterviewTitle/InterviewTitle";
import { getUnschedulableErrorMessage } from "../scheduled-interviews/UpsertScheduledInterviewForm/utils/errors";

gql(`
  fragment JobInterviewSelectBase on Interview {
    id
    title
    jobInterviewStage {
      id
      name
      position
    }
    interviewRequirement {
      id
      schedulableValidation {
        isSchedulable
        validationErrorMessage
      }
    }
  }
`);

/** Base data that the parent query must include */
type BaseData = {
  fetchInterviewsForJob: JobInterviewSelectBaseFragment[];
};

/** Infer the interview based on the parent query */
type JobInterview<TData extends BaseData> =
  TData["fetchInterviewsForJob"][number];

/** Query variables for parent query */
type QueryVariables = {
  jobId: string;
};

/**
 * Infer the interview based on the parent query, but make the interviewRequirement non-nullable based on the internal logic to enforce
 * this.
 */
export type SelectedJobInterview<TData extends BaseData> = Omit<
  JobInterview<TData>,
  "interviewRequirement"
> & {
  interviewRequirement: NonNullable<
    JobInterview<TData>["interviewRequirement"]
  >;
};

/**
 * Type for callback that is triggered when an interview is selected. Infers data from parent query and receives interview with non-null
 * requirement.
 */
export type JobInterviewOnSelect<
  TData extends BaseData,
  TInterviewForSelect = SelectedJobInterview<TData>
> = (interview: TInterviewForSelect) => void;

export type JobInterviewSelectProps<TData extends BaseData> = {
  jobId: string;
  onSelect: JobInterviewOnSelect<TData>;
  excludeInterviewIds?: string[];
  query: TypedDocumentNode<TData, QueryVariables>;
  enforceSchedulable?: boolean;
};

/**
 * A select component for selecting a job interview.
 * The query must be passed to it so that the parent can select what data on the JobInterview to fetch.
 */
export function JobInterviewSelect<TData extends BaseData>({
  jobId,
  onSelect,
  excludeInterviewIds,
  query,
  enforceSchedulable,
}: JobInterviewSelectProps<TData>) {
  const { data } = useQuery(query, {
    variables: {
      jobId,
    },
  });

  const interviewList =
    data?.fetchInterviewsForJob.filter(
      (interview) => !excludeInterviewIds?.includes(interview.id)
    ) ?? [];

  // Sort interviews by atssyncJobInterviewStage.position
  const sortedInterviewList = interviewList.sort(
    (a, b) =>
      (a.jobInterviewStage?.position ?? 0) -
      (b.jobInterviewStage?.position ?? 0)
  );

  // Group interviews by stage name
  const groupedInterviews = sortedInterviewList.reduce((groups, interview) => {
    const stageName = interview.jobInterviewStage?.name ?? "No stage";

    if (!groups[stageName]) {
      // eslint-disable-next-line no-param-reassign
      groups[stageName] = [];
    }

    groups[stageName].push(interview);
    return groups;
  }, {} as { [stageName: string]: [] } as { [stageName: string]: JobInterview<TData>[] });

  const items = useMenuItems(
    (i) => {
      return Object.entries(groupedInterviews).flatMap(
        ([stageName, interviews], idx, arr) => {
          const interviewItems = interviews.map((interview) => {
            let isDisabled = false;
            let tooltipMessage: string | undefined;

            const unschedulableErrorMessage = interview.interviewRequirement
              ?.schedulableValidation
              ? getUnschedulableErrorMessage(
                  interview.interviewRequirement.schedulableValidation
                )
              : undefined;

            if (!interview.interviewRequirement) {
              tooltipMessage =
                "Interview must be configured in your job settings.";
              isDisabled = true;
            } else if (enforceSchedulable && unschedulableErrorMessage) {
              tooltipMessage = unschedulableErrorMessage;
              isDisabled = true;
            }

            return i.item({
              id: interview.id,
              children: interview.id,
              render: (props) => (
                <OptionalTooltip content={tooltipMessage} isInstant>
                  <OptionItem {...props} isDisabled={isDisabled}>
                    <InterviewTitle
                      interview={interview}
                      className="inline [&>*]:inline"
                    />
                  </OptionItem>
                </OptionalTooltip>
              ),
              onClick: () => {
                const { interviewRequirement } = interview;

                // For now, we require that only an interview with a requirement can be scheduled.
                // There will most likely be a scenario where we don't want to enforce this, in which case we can make this logic optional
                // based on a prop.
                if (!interviewRequirement) {
                  throw new Error("Cannot schedule unconfigured interview.");
                }

                onSelect({
                  ...interview,
                  interviewRequirement,
                });
              },
            });
          });

          return [
            i.groupLabel({
              children: stageName,
            }),
            ...interviewItems,
            ...(idx < arr.length - 1 ? [i.separator({ key: stageName })] : []),
          ];
        }
      );
    },
    [enforceSchedulable, groupedInterviews, onSelect]
  );

  return (
    <Menu.Root>
      <Menu.Trigger className="w-full">
        <Button>Add an interview</Button>
      </Menu.Trigger>
      <Menu.Content
        // Prevent menu from opening below the trigger
        className="min-h-[250px]"
        sameWidth
        items={items}
        emptyText="No more interviews for this job"
      />
    </Menu.Root>
  );
}
