/* eslint-disable import/prefer-default-export */
import { useModuleConfig } from "../../__utils/modules";
import { OnTypeMention } from "./types";

declare module "@resource/atlas" {
  interface ContentEditorModuleConfig {
    mentions?: {
      onTypeMention: OnTypeMention;
    };
  }
}

export function useMentionsConfig() {
  return useModuleConfig("mentions", { required: true });
}
