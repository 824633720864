import { createContext, ReactNode, useContext, useMemo } from "react";

// placeholder text config
// ---------------------

type TmpPlaceholderTextOverrideContextValue = {
  placeholder?: string;
};

export const TmpPlaceholderTextOverrideContext = createContext<
  TmpPlaceholderTextOverrideContextValue | undefined
>(undefined);

type ContentEditorTmpPlaceholderTextOverrideProviderProps = {
  placeholder?: string;
  children?: ReactNode;
};

export function TmpPlaceholderTextOverrideProvider({
  placeholder,
  children,
}: ContentEditorTmpPlaceholderTextOverrideProviderProps) {
  const value = useMemo(() => ({ placeholder }), [placeholder]);
  return (
    <TmpPlaceholderTextOverrideContext.Provider value={value}>
      {children}
    </TmpPlaceholderTextOverrideContext.Provider>
  );
}

export function useTmpPlaceholderTextOverrideContext() {
  const value = useContext(TmpPlaceholderTextOverrideContext);
  return value ?? {};
}
