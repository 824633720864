import {
  CollaborativeCodingGenerationSettingsForMappingFragment,
  CollaborativeCodingGenerationSettingsInput,
  CollaborativeCodingRequirementForMappingFragment,
  CollaborativeCodingRequirementInput,
  CollaborativeCodingServiceType as GraphQLCollaborativeCodingServiceType,
  CollaborativeCodingSlotInput,
  InterviewRequirementForCollaborativeCodingRequirementFragment,
  ScheduledInterviewForCollaborativeCodingMappingFragment,
  ScheduledInterviewForCollaborativeCodingSlotFragment,
} from "generated/graphql-codegen/graphql";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";

import {
  CollaborativeCoding,
  CollaborativeCodingGenerationSettings,
  CollaborativeCodingRequirement,
  CollaborativeCodingSlot,
} from "./types";

export function getCollaborativeCodingTypeFromGraphQLType(
  type: GraphQLCollaborativeCodingServiceType
): CollaborativeCodingServiceType {
  switch (type) {
    case GraphQLCollaborativeCodingServiceType.CODERPAD:
      return CollaborativeCodingServiceType.CODERPAD;
    case GraphQLCollaborativeCodingServiceType.CODESIGNAL:
      return CollaborativeCodingServiceType.CODESIGNAL;
    case GraphQLCollaborativeCodingServiceType.HACKERRANK:
      return CollaborativeCodingServiceType.HACKERRANK;
    case GraphQLCollaborativeCodingServiceType.CUSTOM:
      return CollaborativeCodingServiceType.CUSTOM;
    default:
      return CollaborativeCodingServiceType.CUSTOM;
  }
}

export function getGraphQLTypeFromCollaborativeCodingType(
  type: CollaborativeCodingServiceType
): GraphQLCollaborativeCodingServiceType {
  switch (type) {
    case CollaborativeCodingServiceType.CODERPAD:
      return GraphQLCollaborativeCodingServiceType.CODERPAD;
    case CollaborativeCodingServiceType.CODESIGNAL:
      return GraphQLCollaborativeCodingServiceType.CODESIGNAL;
    case CollaborativeCodingServiceType.HACKERRANK:
      return GraphQLCollaborativeCodingServiceType.HACKERRANK;
    case CollaborativeCodingServiceType.CUSTOM:
      return GraphQLCollaborativeCodingServiceType.CUSTOM;
    default:
      return GraphQLCollaborativeCodingServiceType.CUSTOM;
  }
}

export function mapCollaborativeCodingRequirementFragmentToCollaborativeCodingRequirement(
  requirement: CollaborativeCodingRequirementForMappingFragment
): CollaborativeCodingRequirement {
  return {
    service: getCollaborativeCodingTypeFromGraphQLType(requirement.service),
  };
}

export function mapCollaborativeCodingRequirementFragmentToCollaborativeCodingGenerationSettings(
  requirement: CollaborativeCodingRequirementForMappingFragment
): CollaborativeCodingGenerationSettings {
  return {
    service: getCollaborativeCodingTypeFromGraphQLType(requirement.service),
  };
}

export function mapCollaborativeCodingGenerationSettings(
  settings: CollaborativeCodingGenerationSettingsForMappingFragment
): CollaborativeCodingGenerationSettings {
  return {
    service: getCollaborativeCodingTypeFromGraphQLType(settings.service),
  };
}

export function mapScheduledInterviewToExistingCollaborativeCoding(
  scheduledInterview: ScheduledInterviewForCollaborativeCodingMappingFragment
): CollaborativeCoding | null {
  const { collaborativeCoding } = scheduledInterview;

  if (collaborativeCoding) {
    return {
      value: collaborativeCoding.url,
    };
  }

  return null;
}

export function mapInterviewRequirementToCollaborativeCodingRequirement(
  interviewRequirement: InterviewRequirementForCollaborativeCodingRequirementFragment
): CollaborativeCodingRequirement | null {
  const { collaborativeCodingRequirement } = interviewRequirement;

  if (collaborativeCodingRequirement) {
    return mapCollaborativeCodingRequirementFragmentToCollaborativeCodingRequirement(
      collaborativeCodingRequirement
    );
  }

  return null;
}

export function getCollaborativeCodingServiceForScheduledInterview(
  scheduledInterview: ScheduledInterviewForCollaborativeCodingSlotFragment
): CollaborativeCodingServiceType | null {
  if (scheduledInterview.collaborativeCoding) {
    return getCollaborativeCodingTypeFromGraphQLType(
      scheduledInterview.collaborativeCoding.type
    );
  }

  if (scheduledInterview.collaborativeCodingRequirement) {
    return getCollaborativeCodingTypeFromGraphQLType(
      scheduledInterview.collaborativeCodingRequirement.service
    );
  }

  return null;
}

export function mapScheduledInterviewToCollaborativeCodingSlot(
  scheduledInterview: ScheduledInterviewForCollaborativeCodingSlotFragment
): CollaborativeCodingSlot | null {
  const existingCollaborativeCoding =
    mapScheduledInterviewToExistingCollaborativeCoding(scheduledInterview);
  const service =
    getCollaborativeCodingServiceForScheduledInterview(scheduledInterview);
  const generationSettings =
    scheduledInterview.collaborativeCodingGenerationSettings
      ? mapCollaborativeCodingGenerationSettings(
          scheduledInterview.collaborativeCodingGenerationSettings
        )
      : null;
  const generationSettingsFromRequirement =
    scheduledInterview.collaborativeCodingRequirement
      ? mapCollaborativeCodingRequirementFragmentToCollaborativeCodingGenerationSettings(
          scheduledInterview.collaborativeCodingRequirement
        )
      : null;

  if (!service) {
    return null;
  }

  return {
    service,
    existingCollaborativeCoding,
    generationSettings: generationSettings ?? generationSettingsFromRequirement,
    requirements: scheduledInterview.collaborativeCodingRequirement
      ? mapCollaborativeCodingRequirementFragmentToCollaborativeCodingRequirement(
          scheduledInterview.collaborativeCodingRequirement
        )
      : null,
  };
}

export function mapInterviewRequirementToCollaborativeCodingSlot(
  interviewRequirement: InterviewRequirementForCollaborativeCodingRequirementFragment
): CollaborativeCodingSlot | null {
  const { collaborativeCodingRequirement } = interviewRequirement;

  if (collaborativeCodingRequirement) {
    return {
      service: getCollaborativeCodingTypeFromGraphQLType(
        collaborativeCodingRequirement.service
      ),
      generationSettings:
        mapCollaborativeCodingRequirementFragmentToCollaborativeCodingGenerationSettings(
          collaborativeCodingRequirement
        ),
      requirements:
        mapCollaborativeCodingRequirementFragmentToCollaborativeCodingRequirement(
          collaborativeCodingRequirement
        ),
    };
  }

  return null;
}

export function mapCollaborativeCodingGenerationSettingsToInput(
  generationSettings: CollaborativeCodingGenerationSettings
): CollaborativeCodingGenerationSettingsInput {
  return {
    service: getGraphQLTypeFromCollaborativeCodingType(
      generationSettings.service
    ),
    regenerateLink: generationSettings.regenerateLink,
  };
}

export function mapCollaborativeCodingRequirementToInput(
  collaborativeCodingRequirement: CollaborativeCodingRequirement
): CollaborativeCodingRequirementInput {
  return {
    service: getGraphQLTypeFromCollaborativeCodingType(
      collaborativeCodingRequirement.service
    ),
  };
}

export function mapCollaborativeCodingSlotToInput(
  stagedSlot: CollaborativeCodingSlot
): CollaborativeCodingSlotInput | null {
  const {
    service,
    editingCollaborativeCoding,
    generationSettings,
    requirements,
  } = stagedSlot;

  if (!service) {
    return null;
  }

  if (service === CollaborativeCodingServiceType.CUSTOM) {
    return {
      customUrl: editingCollaborativeCoding?.value,
    };
  }

  return {
    generationSettings: generationSettings
      ? mapCollaborativeCodingGenerationSettingsToInput(generationSettings)
      : undefined,
    requirement: requirements
      ? mapCollaborativeCodingRequirementToInput(requirements)
      : undefined,
  };
}
