import Badge from "@resource/atlas/badge/Badge";
import { AtlasBadgeProps } from "@resource/atlas/badge/types";
import {
  SchedulingRequestStatus,
  SchedulingRequestTaskStatus,
} from "generated/graphql-codegen/graphql";

import { getTaskStatusText } from "./utils";

export function SchedulingRequestStatusBadge({
  schedulingRequest,
  NoStatus,
  showCompletedArchivedStatuses = false,
}: {
  schedulingRequest: {
    status: SchedulingRequestStatus;
    taskStatus: SchedulingRequestTaskStatus | null;
    archivedAt: string | null;
  };
  NoStatus?: JSX.Element;
  showCompletedArchivedStatuses?: boolean;
}) {
  let variant: AtlasBadgeProps["variant"] | undefined;
  let text: string | undefined;

  if (showCompletedArchivedStatuses) {
    if (schedulingRequest.status === SchedulingRequestStatus.COMPLETED) {
      variant = "success";
      text = "Completed";
    } else if (schedulingRequest.archivedAt) {
      variant = "default";
      text = "Archived";
    }

    if (variant) {
      return (
        <Badge className="truncate max-w-full" variant={variant}>
          {text}
        </Badge>
      );
    }
  }

  const taskStatus = getTaskStatusText(schedulingRequest.taskStatus);
  if (!taskStatus) {
    return NoStatus || null;
  }

  text = taskStatus;
  switch (schedulingRequest.taskStatus) {
    case SchedulingRequestTaskStatus.WAITING_FOR_AVAILABILITY:
      break;
    case SchedulingRequestTaskStatus.NEEDS_AVAILABILITY:
      variant = "yellow";
      break;
    case SchedulingRequestTaskStatus.NEEDS_SCHEDULING:
      variant = "orange";
      break;
    case SchedulingRequestTaskStatus.NEEDS_CANDIDATE_CONFIRMATION:
      variant = "blue";
      break;
    default:
      return NoStatus || null;
  }

  return (
    <Badge className="truncate max-w-full" variant={variant}>
      {text}
    </Badge>
  );
}
