import { z } from "zod";

import { createNotificationTypeDataSchema } from "./types";

export const commentMentionDataSchema = createNotificationTypeDataSchema({
  metadata: z.object({
    userMembershipId: z.string(),
    schedulingRequestId: z.string(),
    commentId: z.string(),
  }),
  resolved: z.object({
    user: z.object({
      id: z.string(),
      name: z.string(),
      avatarUrl: z.string(),
      email: z.string(),
    }),
    candidate: z.object({
      name: z.string(),
    }),
    organization: z.object({
      name: z.string(),
    }),
    comment: z.object({
      message: z.unknown(),
      atsStageName: z.string().nullable().optional(),
    }),
  }),
});
