import {
  getServiceFromType,
  mapConferencingGenerationSettingsFragmentToConferencingGenerationSettings,
  mapConferencingGenerationSettingsToInput,
} from "client/components/conferencing/utils/mapping";
import { ConferencingSlot } from "client/components/conferencing/utils/types";
import {
  InterviewPanelRequirementForGroupSettingsFragment,
  ScheduledInterviewGroupForGroupSettingsFragment,
  ScheduledInterviewGroupSettingsInput,
} from "generated/graphql-codegen/graphql";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";
import { VideoConferencingServiceType } from "shared/guide-scheduler/video-conferencing/types";

import { ScheduledInterviewGroupSettings } from "./types";

export function mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings(
  scheduledInterviewGroup: ScheduledInterviewGroupForGroupSettingsFragment
): ScheduledInterviewGroupSettings {
  return {
    reuseVideoConferencingLink:
      scheduledInterviewGroup.reuseVideoConferencingLink,
    conferencingGenerationSettings:
      scheduledInterviewGroup.videoConferencingGenerationSettings
        ? mapConferencingGenerationSettingsFragmentToConferencingGenerationSettings(
            scheduledInterviewGroup.videoConferencingGenerationSettings
          )
        : null,
    existingConferencing: scheduledInterviewGroup.reusedVideoConferencingLink
      ? {
          value: scheduledInterviewGroup.reusedVideoConferencingLink,
        }
      : undefined,
  };
}

export function mapInterviewPanelRequirementToScheduledInterviewGroupSettings(
  requirement: InterviewPanelRequirementForGroupSettingsFragment
): ScheduledInterviewGroupSettings {
  let service: ConferencingType | null = null;
  if (requirement.reuseVideoConferencingLink) {
    // Ideally we'd store this on the parent object but this is a quick fix
    // We can assume that all interviews will have the same conferencing type
    const videoConferencingRequirement = requirement.interviewRequirements.find(
      (ir) => {
        return !!ir.videoConferencingSettings;
      }
    );
    service = getServiceFromType(
      videoConferencingRequirement?.videoConferencingSettings?.service ??
        VideoConferencingServiceType.ZOOM
    );
  }

  return {
    reuseVideoConferencingLink: requirement.reuseVideoConferencingLink,
    conferencingGenerationSettings: service
      ? {
          service,
        }
      : null,
  };
}

export function mapScheduledInterviewGroupSettingsToConferencingSlot({
  scheduledInterviewGroupSettings,
}: {
  scheduledInterviewGroupSettings: ScheduledInterviewGroupSettings;
}): ConferencingSlot | null {
  if (!scheduledInterviewGroupSettings.conferencingGenerationSettings) {
    return null;
  }

  return {
    service:
      scheduledInterviewGroupSettings.conferencingGenerationSettings?.service,
    generationSettings:
      scheduledInterviewGroupSettings.conferencingGenerationSettings,
  };
}

export function mapScheduledInterviewGroupSettingsToInput(
  scheduledInterviewGroupSettings: ScheduledInterviewGroupSettings
): ScheduledInterviewGroupSettingsInput {
  return {
    reuseVideoConferencingLink:
      scheduledInterviewGroupSettings.reuseVideoConferencingLink,
    videoConferencingGenerationSettings:
      scheduledInterviewGroupSettings.conferencingGenerationSettings
        ? mapConferencingGenerationSettingsToInput(
            scheduledInterviewGroupSettings.conferencingGenerationSettings
          )
        : undefined,
  };
}
