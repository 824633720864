import { FormGroup } from "client/components/generic/misc/FormGroup";
import { TimezoneSelector } from "components/TimezoneSelection/TimezoneSelector";

export function EditTimezoneForm({
  timezone,
  onChange,
  label = "Timezone",
  className,
}: {
  timezone: string;
  onChange: (tz: string) => void;
  label?: string;
  className?: string;
}) {
  return (
    <FormGroup label={label} className={className}>
      <TimezoneSelector
        onClick={(tz) => {
          onChange(tz);
        }}
        triggerProps={{
          label: timezone,
          className: "bg-light-gray-500 w-full max-w-md",
        }}
        rootProps={{
          value: timezone,
        }}
      />
    </FormGroup>
  );
}
