import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

export type FormGroupProps = ComponentPropsWithoutRef<"div"> & {
  label?: string;
  subLabel?: string | JSX.Element;
  /** SubText placed below input field */
  subText?: string | JSX.Element;
  /** Description placed above input field */
  description?: string | JSX.Element;
  Action?: React.ReactNode;
  errorMessage?: string;
  isRequired?: boolean;
  isOptional?: boolean;
};

/** Generic component providing a label and subtext to a form input with spacing */
export function FormGroup({
  label,
  subLabel,
  subText,
  description,
  children,
  Action,
  className,
  errorMessage,
  isRequired,
  isOptional,
  ...rest
}: FormGroupProps) {
  return (
    <div {...rest} className={clsx("space-y-2", className)}>
      <div className="flex justify-between items-center">
        {label && (
          <div className="text-body-md-heavy flex items-center gap-x-[.25rem]">
            <span>{label}</span>
            {isOptional === true && (
              <span className="text-subtle text-body-md">(optional)</span>
            )}
            {isRequired && <span className="text-red-500">*</span>}
          </div>
        )}

        {Action}
      </div>
      {subLabel && <div className="text-subtle">{subLabel}</div>}
      <div>
        {description && (
          <div className="text-body-sm text-subtle mb-2">{description}</div>
        )}
        {children}
        {errorMessage && (
          <span className="text-red-500 text-body-sm-heavy">
            {errorMessage}
          </span>
        )}
      </div>
      {subText && <div className="text-body-sm text-subtle">{subText}</div>}
    </div>
  );
}
