import { useSetAtom } from "jotai";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

import { calendarSelectedDayAtom, useCalendarTimezone } from "./settings";
import { CalendarState } from "./useCalendarState";

type UseCalendarInitialScrollProps = {
  initialScrollValue?: string | DateTime;
  calendarState: CalendarState;
};

export function useCalendarInitialScroll({
  initialScrollValue: inputInitialScrollValue,
  calendarState,
}: UseCalendarInitialScrollProps) {
  const { calculateScrollValueForTime, wrapperRef, eventsViewRef } =
    calendarState;
  const timezone = useCalendarTimezone();
  const setSelectedDay = useSetAtom(calendarSelectedDayAtom);

  const [hasScrolledToDay, setHasScrolledToDay] = useState(false);
  const [hasScrolledToTime, setHasScrolledToTime] = useState(false);

  const initialScrollValue = useMemo(() => {
    if (inputInitialScrollValue) {
      return typeof inputInitialScrollValue === "string"
        ? DateTime.fromISO(inputInitialScrollValue, {
            zone: timezone,
          })
        : inputInitialScrollValue;
    }

    return DateTime.now().minus({ hours: 2 });
  }, [inputInitialScrollValue, timezone]);

  useEffect(() => {
    if (!hasScrolledToDay) {
      setSelectedDay(initialScrollValue.startOf("day"));
      setHasScrolledToDay(true);
    }
  }, [hasScrolledToDay, initialScrollValue, setSelectedDay]);

  useEffect(() => {
    const scrollValue = calculateScrollValueForTime(initialScrollValue);

    if (wrapperRef && scrollValue && !hasScrolledToTime) {
      wrapperRef.scrollTop = scrollValue;
      setHasScrolledToTime(true);
    }
  }, [
    initialScrollValue,
    eventsViewRef,
    wrapperRef,
    timezone,
    calculateScrollValueForTime,
    hasScrolledToTime,
  ]);
}
