import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasHome } from "@resource/atlas/icons";
import { useAuthContext } from "auth/context";
import { GuideLogo } from "client/components/generic/misc/GuideLogo";
import AccountMenu from "components/AccountMenu";
import { HelpMenu } from "components/HelpMenu";
import OrganizationSwitcher from "components/OrganizationSwitcher";
import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";
import { Permission, StaffRoleEnum } from "shared/auth/permissions";

/**
 * Header in extension to provide logo, org switcher, and other menu items
 */
export function ExtensionHeader() {
  const { checkStaffRole, hasFeatureEnabled, checkRolePermissions } =
    useAuthContext();
  const hideV2Label = checkStaffRole(StaffRoleEnum.STAFF);

  return (
    <div className="flex-shrink-0 flex justify-between py-4 px-[1.125rem] items-center">
      <GuideLogo showV2Label={!hideV2Label} />
      <div className="flex-1 flex justify-end items-center gap-1">
        <OrganizationSwitcher />
        <ButtonGroup size="small">
          <HelpMenu />
          <Button
            as="a"
            href={
              hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) &&
              checkRolePermissions(Permission.SCHEDULING.WRITE)
                ? "/scheduling-requests"
                : "/"
            }
            target="_blank"
            icon={atlasHome}
            isGhost
            negativeMargin="left"
          />
        </ButtonGroup>
        <AccountMenu target="_blank" />
      </div>
    </div>
  );
}
