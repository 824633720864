import { atlasCircleWarning } from "@resource/atlas/icons";
import { ColorConfig } from "client/components/calendar-v2/utils/colors";
import {
  CalendarEventInput,
  GetEventOverridesProps,
} from "client/components/calendar-v2/utils/types";
import { SchedulerStagedScheduledInterview } from "client/scheduler/utils/types";
import { useCallback, useMemo } from "react";

import { useSchedulerInterviewsDispatch } from "../../dispatch";
import {
  useSelectedInterview,
  useSetSelectedInterviewId,
  useStagedInterviews,
} from "../../hooks/interviews";
import { useGetInterviewConflictData } from "../../hooks/useInterviewConflictData";
import { usePlacingInterview } from "../plugins/place-interview-calendar/hooks/usePlacingInterview";
import {
  CustomCalendarIds,
  getCalendarIdsForInterview,
} from "../utils/calendar-ids";
import { EventLayers } from "../utils/group-ids";

type SchedulingEventVariant = "panel" | "placeholder" | "cancelled";

const panelColorConfig: ColorConfig = {
  color: "primary",
  eventVariant: "primary",
};
const placeholderColorConfig: ColorConfig = {
  color: "placeholder",
  eventVariant: "primary",
  border: "#D8B4FE",
};

const cancelledColorConfig: ColorConfig = {
  color: "cancelled",
  eventVariant: "primary",
};

/**
 * Return color config for an interview event based on its variant.
 */
function getColorConfigForInterview(
  variant: SchedulingEventVariant
): ColorConfig {
  switch (variant) {
    case "panel":
      return panelColorConfig;
    case "placeholder":
      return placeholderColorConfig;
    case "cancelled":
      return cancelledColorConfig;
    default:
      return panelColorConfig;
  }
}

type GetEventVariantProps = GetEventOverridesProps & {
  interview: {
    id: string;
    isCancelled: boolean;
  };
};

/**
 * Get event variant for an interview, based on how it's being rendered on the calendar.
 * Handles which grouping it's being rendered in, cancelled state, and if it's currently selected
 */
function useGetEventVariantForInterview() {
  return useCallback(
    ({
      interview: { id: interviewId, isCancelled },
      eventInGroupId,
      columnType,
    }: GetEventVariantProps): SchedulingEventVariant => {
      if (columnType === "day") {
        if (isCancelled) {
          return "cancelled";
        }

        return "panel";
      }

      /** Is the interview being displayed in its own grouping on the calendar */
      const isInCorrectCalendarGrouping = interviewId === eventInGroupId;

      if (isCancelled && isInCorrectCalendarGrouping) {
        return "cancelled";
      }

      if (isInCorrectCalendarGrouping) {
        return "panel";
      }

      // If the interview is not in its own grouping, it's just there to indicate it can't be scheduled over
      return "placeholder";
    },
    []
  );
}

function useMapStagedInterviewToCalendarEvent() {
  const { placingInterview } = usePlacingInterview();
  const setSelectedInterviewId = useSetSelectedInterviewId();
  const dispatch = useSchedulerInterviewsDispatch();
  const getInterviewConflictData = useGetInterviewConflictData();
  const getEventVariant = useGetEventVariantForInterview();
  const selectedInterview = useSelectedInterview();

  return useMemo(() => {
    return (
      interview: SchedulerStagedScheduledInterview
    ): CalendarEventInput => {
      const interviewConflictData = getInterviewConflictData(interview);

      let icon: CalendarEventInput["icon"];

      if (!interview.isCancelled && interviewConflictData.hasConflicts) {
        icon = atlasCircleWarning;
      }

      const interactiveCallbacks: Pick<
        CalendarEventInput,
        "onClick" | "onEdit" | "onRemove"
      > = {
        onClick: () => setSelectedInterviewId(interview.id),
        onEdit: (updatedEvent) => {
          setSelectedInterviewId(interview.id);
          dispatch({
            type: "setInterviewTime",
            interviewId: interview.id,
            startTime: updatedEvent.startTime,
            endTime: updatedEvent.endTime,
          });
        },
        onRemove: () => {
          dispatch({
            type: "cancel",
            interviewId: interview.id,
          });
        },
      };

      return {
        id: interview.id,
        layer: EventLayers.PANEL,
        calendarId: CustomCalendarIds.CURRENT_PANEL,
        additionalCalendarIds: getCalendarIdsForInterview(interview),
        start: {
          dateTime: interview.startTime,
        },
        end: {
          dateTime: interview.endTime,
        },
        title: interview.title,
        disableDetailsPopover: true,
        icon,
        isSelected: selectedInterview?.id === interview.id,
        ...(!placingInterview ? interactiveCallbacks : {}),
        getOverrides: (props) => {
          const eventVariant = getEventVariant({
            ...props,
            interview,
          });

          return {
            colorConfig: getColorConfigForInterview(eventVariant),
            ...(eventVariant === "placeholder" && {
              // Placeholder events aren't interactive
              onClick: undefined,
              onEdit: undefined,
              onRemove: undefined,
              icon: undefined,
              isSelected: false,
            }),
            ...(eventVariant === "cancelled" && {
              // Cancelled events can't be removed
              onRemove: undefined,
            }),
            ...(eventVariant === "placeholder" &&
              interview.isCancelled && {
                // Cancelled placeholder events shouldn't be visible
                hideEvent: true,
              }),
          };
        },
      };
    };
  }, [
    dispatch,
    getEventVariant,
    getInterviewConflictData,
    placingInterview,
    selectedInterview?.id,
    setSelectedInterviewId,
  ]);
}

export function useSchedulingCalendarEvents(): CalendarEventInput[] {
  const stagedInterviews = useStagedInterviews();
  const mapStagedInterviewToCalendarEvent =
    useMapStagedInterviewToCalendarEvent();

  const calendarEvents = useMemo(() => {
    return stagedInterviews.map(mapStagedInterviewToCalendarEvent);
  }, [stagedInterviews, mapStagedInterviewToCalendarEvent]);

  return calendarEvents;
}
