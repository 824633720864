import { MutationHookOptions, MutationResult } from "@apollo/client";
import { strings } from "@resource/common";
import { gql } from "generated/graphql-codegen";
import {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from "generated/graphql-codegen/graphql";
import _ from "lodash";
import prependHttp from "prepend-http";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

import { ProfileFormData } from "../types";

const UPDATE_PROFILE = gql(`
mutation UpdateProfile(
    $input: UpdateUserMembershipDisplayInformationInput!
  ) {
    updateUserMembershipDisplayInformation(input: $input) {
      success
      message
      userMembership {
        ...UserMembershipForEditProfile
      }
    }
  }
`);

export type UseUpdateProfileOnSubmitProps = {
  userMembershipId: string;
} & MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;

export function useUpdateProfileOnSubmit({
  userMembershipId,
  ...hookOpts
}: UseUpdateProfileOnSubmitProps): [
  onSubmit: (data: ProfileFormData) => void,
  mutationResult: MutationResult<UpdateProfileMutation>
] {
  const [updatePersonalProfile, mutationResult] = useMutation(
    UPDATE_PROFILE,
    hookOpts
  );
  const onSubmit = useCallback(
    (data: ProfileFormData) => {
      const transformedWebsiteUrls = _(data.websiteUrls)
        .map("url")
        .compact()
        .map((url: string) => prependHttp(url.toLowerCase()))
        .uniq()
        .value();

      const { firstName, lastName } = strings.splitName(data.name);
      updatePersonalProfile({
        variables: {
          input: {
            firstName,
            lastName,
            userMembershipId,
            websiteUrls: transformedWebsiteUrls,
            videoIntroductionUrl: data.videoIntroductionUrl || null,
            biography: data.biography,
            title: data.title,
            imageUrl: data.imageUrl,
            pronoun: data.pronoun || null,
            onboardingComplete: true,
          },
        },
      });
    },
    [updatePersonalProfile, userMembershipId]
  );

  return [onSubmit, mutationResult];
}
