import { Hovercard } from "@resource/atlas/hovercard";
import { useHovercardState } from "@resource/atlas/hovercard/use-hovercard-state";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronRight } from "@resource/atlas/icons";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { JobInterviewForSelfScheduleSelectionFragment } from "generated/graphql-codegen/graphql";

import { GroupedSelfScheduleInterviewOptions } from "./GroupedSelfScheduleInterviewOptions";

export function SendSelfScheduleLinkOption({
  selfScheduleInterviews,
  onClick,
}: {
  selfScheduleInterviews: JobInterviewForSelfScheduleSelectionFragment[];
  onClick: (interview: JobInterviewForSelfScheduleSelectionFragment) => void;
}) {
  const hovercardState = useHovercardState({
    placement: "left",
    timeout: 0,
  });

  return (
    <>
      <Hovercard.Root state={hovercardState}>
        <Hovercard.Anchor>
          <OptionItem
            size="compact"
            trailingContent={
              <Icon content={atlasChevronRight} className="text-subtle" />
            }
          >
            Request self-schedule
          </OptionItem>
        </Hovercard.Anchor>
        <Hovercard.Content portal hasPadding={false}>
          <GroupedSelfScheduleInterviewOptions
            selfScheduleInterviews={selfScheduleInterviews}
            onClick={onClick}
          />
        </Hovercard.Content>
      </Hovercard.Root>
    </>
  );
}
