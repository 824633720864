import { Hovercard } from "@resource/atlas/hovercard";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBubble } from "@resource/atlas/icons";
import { CommentRenderer } from "client/comments/content-editor/CommentRenderer";
import { SchedulingRequestTableCommentFragment as Comment } from "generated/graphql-codegen/graphql";

import {
  ActorDisplayAvatar,
  ActorDisplayContent,
} from "./activity-feed/__utils/common";

export function SchedulingTaskCommentsIcon({
  comments,
}: {
  comments: Comment[];
}) {
  return comments.length > 0 ? (
    <Hovercard.Root>
      <Hovercard.Anchor>
        <Icon content={atlasBubble} className="w-5 h-5 text-subtle" />
      </Hovercard.Anchor>
      <Hovercard.Content portal hasPadding={false}>
        <div className="space-y-2 max-h-[16rem] overflow-y-auto">
          <div className="m-[1.25rem]">
            {comments.map((comment) => {
              return (
                <div key={comment.id} className="text-body-md space-y-1">
                  <div className="flex gap-2 text-body-md text-subtle items-start">
                    <div className="w-6">
                      <ActorDisplayAvatar actor={comment.createdBy} />
                    </div>
                    <div className="flex w-full">
                      <ActorDisplayContent
                        actor={comment.createdBy}
                        actionText="commented"
                        eventTime={comment.createdAt}
                        context="SchedulingRequest"
                        // We don't actually have an Activity object; we should really be using a different component here.
                        automatedActionId={null}
                      />
                    </div>
                  </div>
                  <div className="ml-8">
                    <CommentRenderer content={comment.message} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Hovercard.Content>
    </Hovercard.Root>
  ) : null;
}
