import TextField from "@resource/atlas/textfield/TextField";
import { Checkbox } from "client/components/generic/inputs/Checkbox";
import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { useCallback, useState } from "react";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";

import AlgorithmSettingsError from "./AlgorithmSettingsError";
import { AlgorithmSettingsErrors } from "./utils/types";

export default function NumberOfDaysSettings({
  numberOfDays,
  onNumberOfDaysChange,
  interviewCount,
  errors,
}: {
  numberOfDays: UserConfigurableAlgorithmSettings["numberOfDays"];
  onNumberOfDaysChange: (
    numberOfDays: UserConfigurableAlgorithmSettings["numberOfDays"]
  ) => void;
  interviewCount: number;
  errors: AlgorithmSettingsErrors | null;
}) {
  const [numberOfDaysMode, setNumberOfDaysMode] = useState(() => {
    if (!numberOfDays) {
      return null;
    }
    if (numberOfDays.min === numberOfDays.max) {
      return "exact";
    }
    if (numberOfDays.min === 1 && numberOfDays.max === interviewCount) {
      return "flexible";
    }
    return "range";
  });

  // Default to 2 days as a max unless there's only 1 interview. Also used as default for exact mode.
  const defaultMax = Math.min(interviewCount, 2);

  const onSetNumberOfDaysModeToExact = useCallback(() => {
    onNumberOfDaysChange({ min: defaultMax, max: defaultMax });
    setNumberOfDaysMode("exact");
  }, [onNumberOfDaysChange, defaultMax]);

  const onSetNumberOfDaysModeToRange = useCallback(() => {
    onNumberOfDaysChange({ min: 1, max: defaultMax });
    setNumberOfDaysMode("range");
  }, [onNumberOfDaysChange, defaultMax]);

  // If a user is flexible, allow spreading across any number of days up to the number of interviews.
  // This is almost like the default back-to-back behavior, except the algorithm will not penalize multi-day options: ENG-4387
  const onSetNumberOfDaysModeToFlexible = useCallback(() => {
    onNumberOfDaysChange({ min: 1, max: interviewCount });
    setNumberOfDaysMode("flexible");
  }, [onNumberOfDaysChange, interviewCount]);

  const toggleDaysMode = useCallback(() => {
    if (numberOfDaysMode) {
      onNumberOfDaysChange(undefined);
      setNumberOfDaysMode(null);
    } else {
      onSetNumberOfDaysModeToExact();
    }
  }, [numberOfDaysMode, onNumberOfDaysChange, onSetNumberOfDaysModeToExact]);

  return (
    <div className="space-y-2 text-body-md">
      <div className="flex items-center gap-2">
        <Checkbox
          label="Spread across days"
          checked={!!numberOfDaysMode}
          onChange={toggleDaysMode}
        />
      </div>
      {numberOfDays && numberOfDaysMode && (
        <div className="space-y-2 ml-4">
          <div className="space-y-2">
            <RadioItem
              label="Exact number of days"
              isSelected={numberOfDaysMode === "exact"}
              onClick={onSetNumberOfDaysModeToExact}
            />
            {numberOfDaysMode === "exact" && (
              <div className="ml-[1.75rem]">
                <div className="flex items-center gap-2">
                  <span>Spread across</span>
                  <TextField
                    type="number"
                    size="small"
                    className="w-12"
                    inputClassName="text-center"
                    value={numberOfDays.min.toString()}
                    onChange={(v) => {
                      const value = parseInt(v, 10);
                      onNumberOfDaysChange({
                        min: value,
                        max: value,
                      });
                    }}
                  />
                  <span>days</span>
                </div>
                {/*  Min error works for both min and max in exact mode */}
                <AlgorithmSettingsError
                  errorMessage={errors?.minNumberOfDays}
                />
              </div>
            )}
          </div>

          <RadioItem
            label="Range of days"
            isSelected={numberOfDaysMode === "range"}
            onClick={onSetNumberOfDaysModeToRange}
          />
          {numberOfDaysMode === "range" && (
            <div className="ml-[1.75rem]">
              <div className="flex items-center gap-2">
                <span>Spread between</span>
                <TextField
                  type="number"
                  size="small"
                  className="w-12"
                  inputClassName="text-center"
                  value={numberOfDays.min.toString()}
                  onChange={(v) =>
                    onNumberOfDaysChange({
                      min: parseInt(v, 10),
                      max: numberOfDays.max,
                    })
                  }
                />
                <span>and</span>
                <TextField
                  type="number"
                  size="small"
                  className="w-12"
                  inputClassName="text-center"
                  value={numberOfDays.max.toString()}
                  onChange={(v) =>
                    onNumberOfDaysChange({
                      min: numberOfDays.min,
                      max: parseInt(v, 10),
                    })
                  }
                />
                <span>days</span>
              </div>
              <AlgorithmSettingsError errorMessage={errors?.minNumberOfDays} />
              <AlgorithmSettingsError errorMessage={errors?.maxNumberOfDays} />
            </div>
          )}

          <RadioItem
            label="Flexible across any days"
            isSelected={numberOfDaysMode === "flexible"}
            onClick={onSetNumberOfDaysModeToFlexible}
          />
        </div>
      )}
    </div>
  );
}
