import { InterviewerCalendarEvent } from "client/calendar-events/utils/types";
import { useCalendarTimezone } from "client/components/calendar-v2/hooks/settings";
import { getUserMembershipsFromInterviewSlots } from "client/components/interviewer-slots/utils/misc";
import { UserMembershipForForm } from "client/components/interviewer-slots/utils/types";
import { eventsOverlap } from "client/utils/dates";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useAllInterviewerCalendarEventsForScheduler } from "../calendar/hooks/interviewer-calendar-events";
import { useStagedInterviews } from "./interviews";

type EventConflicts = {
  events: InterviewerCalendarEvent[];
  userMembership: UserMembershipForForm;
};

export type InterviewsWithEventConflictsMap = {
  [interviewId: string]: EventConflicts[];
};

/**
 * Get map of interviews with conflicting interviewer calendar events. Includes whitelisted events, but not transparent events.
 */
export function useInterviewsWithEventConflictsMap() {
  const interviews = useStagedInterviews();
  const calendarTimezone = useCalendarTimezone();
  const interviewerCalendarEvents =
    useAllInterviewerCalendarEventsForScheduler();

  return useMemo(() => {
    return interviews.reduce((acc, interview) => {
      const interviewerUserMemberships = getUserMembershipsFromInterviewSlots(
        interview.interviewerSlots
      );
      const userMembershipsWithEventConflicts = interviewerUserMemberships
        .map((userMembership): EventConflicts | null => {
          const eventConflictsForUserMemberships =
            interviewerCalendarEvents.filter(
              (event) =>
                event.calendarId === userMembership.id &&
                !event.isTransparent &&
                eventsOverlap(interview, event, calendarTimezone)
            );

          if (!eventConflictsForUserMemberships.length) {
            return null;
          }

          return {
            events: eventConflictsForUserMemberships,
            userMembership,
          };
        })
        .filter(filterOutNullsAndUndefined);

      return {
        ...acc,
        [interview.id]: userMembershipsWithEventConflicts,
      };
    }, {} as InterviewsWithEventConflictsMap);
  }, [interviews, interviewerCalendarEvents, calendarTimezone]);
}
