import deepDiff from "deep-diff";

import { InterviewPanelRequirement } from "./types";

/** This function compares two different interview panel requirements to see if the panel-level settings have changed. */
export function interviewPanelRequirementHasChanged({
  oldInterviewPanelRequirement,
  newInterviewPanelRequirement,
}: {
  oldInterviewPanelRequirement: InterviewPanelRequirement;
  newInterviewPanelRequirement: InterviewPanelRequirement;
}) {
  if (
    deepDiff(
      oldInterviewPanelRequirement.algorithmSettings,
      newInterviewPanelRequirement.algorithmSettings
    )
  ) {
    return true;
  }

  if (
    oldInterviewPanelRequirement.reuseVideoConferencingLink !==
    newInterviewPanelRequirement.reuseVideoConferencingLink
  ) {
    return true;
  }

  if (
    oldInterviewPanelRequirement.location !==
    newInterviewPanelRequirement.location
  ) {
    return true;
  }
  return false;
}
