import { View } from "@resource/atlas/view/View";
import { CloseButton } from "client/components/generic/layout/CloseButton";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { useCallback } from "react";

import { CancellationInterviewNotificationSettingsState } from "../hooks/useCancellationInterviewNotificationSettingsState";
import { CancellationInterviewNotificationSettingsDisplay } from "./CancellationInterviewNotificationSettingsDisplay";

export type CancellationInterviewNotificationSettingsViewProps = {
  state: CancellationInterviewNotificationSettingsState;
  onCancel: () => void;
  /**
   * Confirmation button should get passed from the caller
   * Based on whether it is a standalone view or part of a multi-step view
   */
  confirmationButton: {
    label: string;
    variant: "default" | "primary";
    onClick: () => void;
    isLoading?: boolean;
  };
};

/**
 * View for wrapping the CancellationInterviewNotificationSettingsDisplay component
 * Can be used as a standalone view or as part of a multi-step view
 */
export function CancellationInterviewNotificationSettingsView({
  state: { form, disabledTooltipContent },
  onCancel,
  confirmationButton,
}: CancellationInterviewNotificationSettingsViewProps) {
  const notifyInterviewers = form.watch("notifyInterviewers");
  const message = form.watch("message");

  const onNotifyInterviewersChange = useCallback(
    (value: boolean) => {
      form.setValue("notifyInterviewers", value);
    },
    [form]
  );

  const onMessageChange = useCallback(
    (value: string) => {
      form.setValue("message", value);
    },
    [form]
  );

  return (
    <View
      content={{
        title: "Cancel interview and notify",
        subTitle: "Would you like to notify interviewers of the cancellation?",
        className: "space-y-4",
      }}
      header={{
        leftActions: <CloseButton onClick={onCancel} />,
      }}
      footer={{
        rightActions: (
          <FormRightActions
            disabledTooltipContent={disabledTooltipContent}
            isLoading={confirmationButton.isLoading}
            cancel={{
              children: "Cancel",
              onClick: onCancel,
            }}
            save={{
              ...confirmationButton,
              children: confirmationButton.label,
            }}
          />
        ),
      }}
    >
      <CancellationInterviewNotificationSettingsDisplay
        notifyInterviewers={notifyInterviewers}
        message={message ?? ""}
        onNotifyInterviewersChange={onNotifyInterviewersChange}
        onMessageChange={onMessageChange}
      />
    </View>
  );
}
