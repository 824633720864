import {
  type CollaborativeCodingGenerationSettings,
  CollaborativeCodingGenerationSettingsSchema,
  type CollaborativeCodingRequirement,
  CollaborativeCodingRequirementSchema,
  CollaborativeCodingServiceType,
} from "shared/guide-scheduler/collaborative-coding/types";
import { isCollaborativeCodingUrl } from "shared/guide-scheduler/collaborative-coding/utils";
import { z } from "zod";

const UserValidationMessages = {
  requiredCustomUrl: "Must include a valid collaborative coding URL.",
  noCollaborativeCodingSelected:
    "A collaborative coding service must be selected.",
};

export const CollaborativeCodingSchema = z.object({
  value: z.string(),
});

export type CollaborativeCoding = z.infer<typeof CollaborativeCodingSchema>;

export const CollaborativeCodingSlotSchema = z.object({
  service: z.nativeEnum(CollaborativeCodingServiceType).nullable(),
  existingCollaborativeCoding: CollaborativeCodingSchema.optional().nullable(),
  editingCollaborativeCoding: CollaborativeCodingSchema.optional().nullable(),
  generationSettings:
    CollaborativeCodingGenerationSettingsSchema.optional().nullable(),
  requirements: CollaborativeCodingRequirementSchema.optional().nullable(),
});

export type CollaborativeCodingSlot = z.infer<
  typeof CollaborativeCodingSlotSchema
>;

export const StagedCollaborativeCodingSlotSchema =
  CollaborativeCodingSlotSchema.extend({})
    .refine(
      (slot) => {
        if (
          slot.service === CollaborativeCodingServiceType.CUSTOM &&
          slot.editingCollaborativeCoding !== undefined
        ) {
          return (
            slot.editingCollaborativeCoding !== null &&
            slot.editingCollaborativeCoding?.value !== "" &&
            isCollaborativeCodingUrl(slot.editingCollaborativeCoding.value)
          );
        }

        return true;
      },
      {
        message: UserValidationMessages.requiredCustomUrl,
      }
    )
    .refine(
      (slot) => {
        if (slot.requirements?.service && !slot.service) {
          return false;
        }

        return true;
      },
      {
        message: UserValidationMessages.noCollaborativeCodingSelected,
      }
    );

export {
  CollaborativeCodingGenerationSettings,
  CollaborativeCodingGenerationSettingsSchema,
  CollaborativeCodingRequirement,
  CollaborativeCodingRequirementSchema,
  CollaborativeCodingServiceType,
};
