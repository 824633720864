import { View } from "@resource/atlas/view/View";
import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { useSchedulingRequestDetailsQuery } from "client/components/scheduling-tasks/hooks/useSchedulingRequestDetailsQuery";
import { useScheduledInterviewGroupForGuideCreated } from "client/hooks/useScheduledInterviewGroupForGuideCreated";
import ErrorPage from "components/Generic/ErrorPage";
import Loading from "components/Loading";
import { InterviewRequirementForConfigurationFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";

import { useSchedulingTaskDetailsOnSchedule } from "./SchedulingTaskDetailsView/hooks/useSchedulingTaskDetailsOnSchedule";
import { SchedulingTaskDetailsView } from "./SchedulingTaskDetailsView/wrapper/SchedulingTaskDetailsView";
import { useSchedulingTaskHeaderOptions } from "./useSchedulingTaskHeaderOptions";

/**
 *  A wrapper around SchedulingTaskDetailsView component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorSchedulingTaskDetails({
  schedulingRequestId,
}: {
  schedulingRequestId: string;
}) {
  const navigator = useGlobalNavigatorOrThrow();
  const { data, refetch } = useSchedulingRequestDetailsQuery({
    schedulingRequestId,
  });
  const { onSchedule, SchedulerDialog } = useSchedulingTaskDetailsOnSchedule({
    schedulingRequestId,
  });

  useScheduledInterviewGroupForGuideCreated({
    guideId: data?.schedulingRequestById?.guideId,
    onSubscriptionData: () => {
      refetch();
    },
  });

  const onEditTask = useCallback(() => {
    if (!navigator) {
      return;
    }

    navigator.navigate("editSchedulingTask", {
      schedulingTaskId: schedulingRequestId,
    });
  }, [schedulingRequestId, navigator]);

  const onEditRequirement = useCallback(
    (requirement: InterviewRequirementForConfigurationFragment) => {
      if (!navigator) {
        return;
      }

      navigator.navigate("editIndividualInterviewRequirement", {
        interviewRequirement: requirement,
        schedulingRequestId,
      });
    },
    [schedulingRequestId, navigator]
  );

  const onNavigateToScheduledInterviewDetails = useCallback(
    ({ scheduledInterviewId }: { scheduledInterviewId: string }) => {
      if (data?.schedulingRequestById?.guideId) {
        navigator.navigate("scheduledInterviewDetails", {
          guideId: data.schedulingRequestById.guideId,
          scheduledInterviewId,
        });
      }
    },
    [data?.schedulingRequestById?.guideId, navigator]
  );

  const header = useSchedulingTaskHeaderOptions({
    schedulingRequest: data?.schedulingRequestById,
    onBack: navigator.back,
    onEdit: onEditTask,
    backIcon: navigator.backIcon,
  });

  if (!data) {
    return (
      <View>
        <Loading />
      </View>
    );
  }

  const schedulingRequest = data.schedulingRequestById;

  if (!schedulingRequest) {
    return (
      <View>
        <ErrorPage errorCode="404" />
      </View>
    );
  }

  return (
    <>
      <SchedulingTaskDetailsView
        schedulingRequest={schedulingRequest}
        onSchedule={onSchedule}
        onNavigateToScheduledInterviewDetails={
          onNavigateToScheduledInterviewDetails
        }
        onEditTask={onEditTask}
        onEditRequirement={onEditRequirement}
        header={header}
      />
      <SchedulerDialog />
    </>
  );
}
