import clsx from "clsx";
import NextLink from "next/link";
import { ComponentPropsWithoutRef, useCallback } from "react";

import styles from "./StackedCard.module.scss";

type StackedCardProps = ComponentPropsWithoutRef<"div"> & {
  stackCount: number;
  stackOverlay?: {
    text: string;
    href: string;
  };
  logEventOnHrefClicked?: () => void;
  cardClassName?: string;
  itemClassName?: string;
};

export function StackedCard({
  stackCount,
  className,
  cardClassName,
  itemClassName,
  children,
  stackOverlay,
  logEventOnHrefClicked,
}: StackedCardProps) {
  const StackWrapper = useCallback(
    ({ children: stackChildren }: { children: JSX.Element }) => {
      if (!stackOverlay) {
        return <div>{stackChildren}</div>;
      }

      return (
        <NextLink href={stackOverlay.href} onClick={logEventOnHrefClicked}>
          {stackChildren}
        </NextLink>
      );
    },
    [stackOverlay, logEventOnHrefClicked]
  );

  return (
    <div className={clsx({ "pb-4": stackCount > 1 })}>
      <div
        className={clsx(
          styles.cardWrapper,
          {
            [styles.stackOverlay]: !!stackOverlay,
          },
          className
        )}
      >
        <div
          className={clsx(styles.card, cardClassName, {
            [styles.cardNoStack]: stackCount === 1,
            [styles.cardWithStack]: stackCount > 1,
          })}
        >
          {children}
        </div>
        {stackCount > 1 && (
          <StackWrapper>
            <div className={styles.stack}>
              <div className={clsx(styles.stackItem, itemClassName)} />
              <div className={clsx(styles.stackItem, itemClassName)} />
              {stackOverlay && (
                <div className={styles.overlayTextWrapper}>
                  <p className={styles.overlayText}>{stackOverlay.text}</p>
                </div>
              )}
            </div>
          </StackWrapper>
        )}
      </div>
    </div>
  );
}
