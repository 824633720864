type InterviewerRequirement =
  | {
      userMembershipIds: string[];
      syncedUserMembershipIds: string[];
    }
  | {
      userMemberships: {
        id: string;
      }[];
      syncedUserMemberships: {
        id: string;
      }[];
    };

export function syncedInterviewerRequirementIsOverridden({
  interviewerRequirement,
}: {
  interviewerRequirement: InterviewerRequirement;
}) {
  if ("userMembershipIds" in interviewerRequirement) {
    if (
      interviewerRequirement.syncedUserMembershipIds.length !==
      interviewerRequirement.userMembershipIds.length
    ) {
      return true;
    }

    if (
      interviewerRequirement.syncedUserMembershipIds.some(
        (id) => !interviewerRequirement.userMembershipIds.includes(id)
      )
    ) {
      return true;
    }

    if (
      interviewerRequirement.userMembershipIds.some(
        (id) => !interviewerRequirement.syncedUserMembershipIds.includes(id)
      )
    ) {
      return true;
    }
  } else if ("userMemberships" in interviewerRequirement) {
    if (
      interviewerRequirement.syncedUserMemberships.length !==
      interviewerRequirement.userMemberships.length
    ) {
      return true;
    }

    if (
      interviewerRequirement.syncedUserMemberships.some(
        (id) =>
          !interviewerRequirement.userMemberships
            .map((um) => um.id)
            .includes(id.id)
      )
    ) {
      return true;
    }

    if (
      interviewerRequirement.userMemberships.some(
        (um) =>
          !interviewerRequirement.syncedUserMemberships
            .map((id) => id.id)
            .includes(um.id)
      )
    ) {
      return true;
    }
  }

  return false;
}
