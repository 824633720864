import { Button } from "@resource/atlas/button/Button";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { gql } from "generated/graphql-codegen";
import { formatEntity } from "shared/constants/entities";
import useMutation from "utils/useMutation";

const FORCE_PROPAGATE_MUTATION = gql(`
  mutation ForcePropagateToGuide($input: ForcePropagateToGuideInput!) {
    forcePropagateToGuide(input: $input) {
      success
      message
    }
  }
`);

type Props = {
  store: DialogStore;
  guideId: string;
  onSubmit?: () => void;
};

export function ForcePropagateToGuideConfirmationDialog({
  guideId,
  store,
  onSubmit,
}: Props) {
  const [forcePropagateToGuide, { loading }] = useMutation(
    FORCE_PROPAGATE_MUTATION,
    {
      variables: {
        input: {
          guideId,
        },
      },
      onCompleted: () => {
        store.hide();
        onSubmit?.();
      },
      refetchQueries: ["FetchV2GuideForManagerTab"],
    }
  );

  return (
    <Dialog store={store}>
      <View
        header={{
          title: "Pull latest guide settings",
        }}
        footer={{
          leftActions: <Button onClick={store.hide}>Cancel</Button>,
          rightActions: (
            <Button
              variant="primary"
              onClick={() => forcePropagateToGuide()}
              isLoading={loading}
            >
              Pull latest
            </Button>
          ),
        }}
      >
        <div className="space-y-2">
          <div className="text-body-md">
            Pulling the latest {formatEntity("guide")}
            settings from the job (cover image, overview content and interview
            plan) will update the candidate&apos;s active{" "}
            {formatEntity("guide")}.
          </div>
          <p className="text-body-md-heavy">
            Are you sure you want to proceed?
          </p>
        </div>
      </View>
    </Dialog>
  );
}
