/* eslint-disable @typescript-eslint/no-explicit-any */
import { AtlasIconData } from "@resource/atlas/icon/types";
import { atlasArrowLeft, atlasClose } from "@resource/atlas/icons";
import { View } from "@resource/atlas/view/View";
import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { usePrevious } from "react-use";

export class NavigationError extends Error {}

export type PageItem<T extends Record<string, unknown> = any> = {
  component: React.ComponentType<T>;
};

export type NavigatorViewsType = Record<string, PageItem<any>>;

export type NavigatorOptions<
  T extends NavigatorViewsType = NavigatorViewsType
> = {
  views: T;
};

export type ExtractNavigatorProps<T extends NavigatorOptions> = {
  [K in keyof T["views"]]: T["views"][K] extends PageItem<infer P> ? P : never;
};

export function createNavigator<V extends NavigatorViewsType>(
  navigator: NavigatorOptions<V>
): NavigatorOptions<V> {
  return navigator;
}

type NavigatorRouteProps = Record<string, unknown>;

export type NavigatorContextType<
  T extends NavigatorRouteProps = NavigatorRouteProps
> = {
  /** Navigate to a route within the navigator */
  navigate: <K extends keyof T>(view: K, props: T[K]) => void;
  /**
   * Replace the current view without adding to history
   */
  replace: <K extends keyof T>(view: K, props: T[K]) => void;
  /**
   * Navigate backwards in the history of the navigator
   *
   * If there is no history, will call `dismissNavigator`
   */
  back: () => void;
  /**
   * Icon to use for the back button.
   *
   * It will use a back icon if there is history, and fall back to `dismissNavigatorIcon` if there is no history
   */
  backIcon: AtlasIconData;
  /** Dismiss the navigator */
  dismissNavigator: () => void;
  /**
   * Icon to use for the back button when there is no history (i.e. when calling dismissNavigator)
   */
  dismissNavigatorIcon: AtlasIconData;
  currentView: { view: keyof T; props: T[keyof T] };
  views: Record<keyof T, PageItem>;
  history: Array<{ view: keyof T; props: T[keyof T] }>;
  canGoBack: boolean;
};

export const NavigatorContext = createContext<NavigatorContextType | null>(
  null
);

export function useNavigatorContext<
  V extends NavigatorOptions<any>
>(): NavigatorContextType<ExtractNavigatorProps<V>> | null {
  const context = useContext(NavigatorContext) as NavigatorContextType<
    ExtractNavigatorProps<V>
  > | null;

  // don't throw on error as these pages could be all be used outside of a navigator right now

  return context;
}

export function useNavigatorContextOrThrow<
  V extends NavigatorOptions<any>
>(): NavigatorContextType<ExtractNavigatorProps<V>> {
  const context = useContext(NavigatorContext) as NavigatorContextType<
    ExtractNavigatorProps<V>
  >;

  if (!context) {
    throw new Error("NavigatorContext not found");
  }

  return context;
}

export type ViewProps<
  T extends NavigatorOptions<any>,
  K extends keyof T["views"]
> = T["views"][K] extends PageItem<infer P> ? P : never;

export type InitialView<
  T extends NavigatorOptions<any>,
  K extends keyof T["views"]
> = {
  view: K;
  props: ViewProps<T, K>;
};

export type NavigatorState<T extends NavigatorOptions<any>> = {
  currentView: {
    view: keyof T["views"];
    props: ExtractNavigatorProps<T>[keyof T["views"]];
  };
  history: Array<{
    view: keyof T["views"];
    props: ExtractNavigatorProps<T>[keyof T["views"]];
  }>;
  navigate: NavigatorContextType<ExtractNavigatorProps<T>>["navigate"];
  replace: NavigatorContextType<ExtractNavigatorProps<T>>["replace"];
  back: () => void;
  canGoBack: boolean;
};

export type UseNavigatorStateProps<T extends NavigatorOptions<any>> = {
  navigator: T;
  initialView: InitialView<T, keyof T["views"]>;
  initialHistory?: Array<InitialView<T, keyof T["views"]>>;
  dismissNavigator: () => void;
  popToRoot?: () => void;
};

export type UseNavigatorStateReturn<T extends NavigatorOptions<any>> =
  NavigatorState<T> & UseNavigatorStateProps<T>;

export function useNavigatorState<T extends NavigatorOptions<any>>(
  props: UseNavigatorStateProps<T>
): UseNavigatorStateReturn<T> {
  const {
    navigator,
    initialView,
    initialHistory = [],
    dismissNavigator,
  } = props;
  const { views } = navigator;
  type ViewKey = keyof T["views"];
  type Props = ExtractNavigatorProps<T>[ViewKey];

  type HistoryItem = { view: ViewKey; props: Props };
  const [history, setHistory] = useState<Array<HistoryItem>>(initialHistory);

  type CurrentView = { view: ViewKey; props: Props };
  const [currentView, setCurrentView] = useState<CurrentView>(initialView);

  const prevInitialView = usePrevious(initialView);
  useEffect(() => {
    if (JSON.stringify(prevInitialView) !== JSON.stringify(initialView)) {
      setCurrentView(initialView);
    }
  }, [initialView, prevInitialView]);

  const prevInitialHistory = usePrevious(initialHistory);
  useEffect(() => {
    if (JSON.stringify(prevInitialHistory) !== JSON.stringify(initialHistory)) {
      setHistory(initialHistory);
    }
  }, [initialHistory, prevInitialHistory]);

  const navigate = useCallback<NavigatorContextType<T>["navigate"]>(
    (passedView, passedProps) => {
      if (!views[passedView]) {
        throw new NavigationError(
          `View "${passedView as string}" is not registered.`
        );
      }

      setHistory((prevHistory) => [...prevHistory, currentView]);
      setCurrentView({
        view: passedView,
        props: passedProps as ExtractNavigatorProps<T>[ViewKey],
      });
    },
    [views, currentView]
  );

  const replace = useCallback<NavigatorContextType<T>["replace"]>(
    (passedView, passedProps) => {
      if (!views[passedView]) {
        throw new NavigationError(
          `View "${passedView as string}" is not registered.`
        );
      }

      setCurrentView({
        view: passedView,
        props: passedProps as ExtractNavigatorProps<T>[ViewKey],
      });
    },
    [views]
  );

  const canGoBack = useMemo(() => {
    return history.length > 0;
  }, [history]);

  const popToRoot = useCallback(() => {
    if (!canGoBack) {
      return;
    }

    setCurrentView(history[0]);
    setHistory([]);
  }, [canGoBack, history]);

  const back = useCallback(() => {
    if (!canGoBack) {
      dismissNavigator();
      return;
    }

    setCurrentView(history[history.length - 1]);
    setHistory((prevHistory) => prevHistory.slice(0, prevHistory.length - 1));
  }, [canGoBack, dismissNavigator, history]);

  return useMemo(
    () => ({
      ...props,
      currentView,
      history,
      navigate,
      replace,
      back,
      popToRoot,
      canGoBack,
    }),
    [props, currentView, history, navigate, replace, back, canGoBack, popToRoot]
  );
}

export type NavigatorStateProps<
  T extends NavigatorOptions<any>,
  K extends keyof T["views"]
> = {
  navigator: T;
  initialView: InitialView<T, K>;
  // TODO: fix types here; currently props is that of the initialView.
  initialHistory?: Array<{
    view: keyof T["views"];
    props: ViewProps<T, keyof T["views"]>;
  }>;
  dismissNavigator: () => void;
};

export type NavigatorProviderProps<T extends NavigatorOptions<any>> = {
  navigatorState: UseNavigatorStateReturn<T>;
  backIcon?: AtlasIconData;
  dismissNavigatorIcon?: AtlasIconData;
  children: React.ReactNode;
};

export function NavigatorProvider<T extends NavigatorOptions<any>>({
  navigatorState,
  backIcon = atlasArrowLeft,
  dismissNavigatorIcon = atlasClose,
  children,
}: NavigatorProviderProps<T>) {
  const calculatedBackIcon = useMemo(() => {
    if (!navigatorState.canGoBack) {
      return dismissNavigatorIcon;
    }
    return backIcon;
  }, [backIcon, navigatorState.canGoBack, dismissNavigatorIcon]);

  const contextValue = useMemo<NavigatorContextType<any>>(
    () => ({
      navigate: navigatorState.navigate,
      replace: navigatorState.replace,
      back: navigatorState.back,
      currentView: navigatorState.currentView,
      views: navigatorState.navigator.views,
      history: navigatorState.history,
      canGoBack: navigatorState.canGoBack,
      dismissNavigator: navigatorState.dismissNavigator,
      backIcon: calculatedBackIcon,
      dismissNavigatorIcon,
    }),
    [navigatorState, calculatedBackIcon, dismissNavigatorIcon]
  );

  return (
    <NavigatorContext.Provider value={contextValue}>
      {children}
    </NavigatorContext.Provider>
  );
}

export type NavigatorProps<
  T extends NavigatorOptions<any>,
  K extends keyof T["views"]
> = {
  /**
   * Icon to use for the back button when there is history
   * @default atlasArrowLeft
   */
  backIcon?: AtlasIconData;
  /**
   * Icon to use for the back button when there is no history (i.e. when `dismissNavigator` is called)
   * @default atlasClose
   */
  dismissNavigatorIcon?: AtlasIconData;
  /**
   * If true, the Navigator will not create its own context provider
   * This is used if you want to specify the provider at a higher level yourself
   * @default false
   */
  isUsingManualProvider?: boolean;
  children?: React.ReactNode;
} & (
  | NavigatorStateProps<T, K>
  | {
      navigatorState: UseNavigatorStateReturn<T>;
    }
);

export interface NavigatorRef {
  popToRoot?: () => void;
}
export const Navigator = forwardRef<NavigatorRef, NavigatorProps<any, any>>(
  <T extends NavigatorOptions<any>, K extends keyof T["views"]>(
    {
      backIcon = atlasArrowLeft,
      dismissNavigatorIcon = atlasClose,
      isUsingManualProvider = false,
      children,
      ...props
    }: NavigatorProps<T, K>,
    ref: React.ForwardedRef<NavigatorRef>
  ) => {
    const useNavigatorProps =
      "navigatorState" in props ? props.navigatorState : props;
    const internalNavigatorState = useNavigatorState(useNavigatorProps);
    const navigatorState =
      "navigatorState" in props ? props.navigatorState : internalNavigatorState;

    useImperativeHandle(ref, () => ({
      popToRoot: navigatorState.popToRoot,
    }));

    const DisplayView =
      navigatorState.navigator.views[navigatorState.currentView.view]
        ?.component;

    if (!DisplayView) {
      return (
        <View>
          Invalid view: {JSON.stringify(navigatorState.currentView.view)}
        </View>
      );
    }

    const content = (
      <>
        <DisplayView {...(navigatorState.currentView.props as any)} />
        {children}
      </>
    );

    if (isUsingManualProvider) {
      return content;
    }

    return (
      <NavigatorProvider
        navigatorState={navigatorState}
        backIcon={backIcon}
        dismissNavigatorIcon={dismissNavigatorIcon}
      >
        {content}
      </NavigatorProvider>
    );
  }
);
