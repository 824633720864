import { OnSubscriptionDataOptions, useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { GuidePostsForGuideUpdatedSubscription } from "generated/graphql-codegen/graphql";

const GUIDE_POSTS_UPDATED_SUBSCRIPTION = gql(`
  subscription GuidePostsForGuideUpdated($guideId: ID!) {
    guidePostForGuideCreated(guideId: $guideId) {
      id
    }
  }
`);

export function useGuidePostForGuideCreated({
  guideId,
  onSubscriptionData,
}: {
  guideId: string;
  onSubscriptionData: (
    options: OnSubscriptionDataOptions<GuidePostsForGuideUpdatedSubscription>
  ) => void;
}) {
  return useSubscription(GUIDE_POSTS_UPDATED_SUBSCRIPTION, {
    variables: {
      guideId,
    },
    onSubscriptionData: (options) => {
      onSubscriptionData(options);
    },
  });
}
