import { ItemCreator } from "@resource/atlas/__utils/collections";
import { AtlasMenuContentItem } from "@resource/atlas/menu";
import { useCallback, useMemo } from "react";
import { formatEntity } from "shared/constants/entities";

export function useRemoveInterviewerMenuItem({
  removeSlotOnRemove,
  isShadower,
  onRemoveInterviewerSlot,
  onRemoveUser,
  userMembershipId,
}: {
  removeSlotOnRemove: boolean;
  isShadower?: boolean;
  onRemoveInterviewerSlot: () => void;
  onRemoveUser: () => void;
  userMembershipId?: string;
}) {
  const onRemove = useCallback(() => {
    if (removeSlotOnRemove) {
      onRemoveInterviewerSlot();
    } else {
      onRemoveUser();
    }
  }, [removeSlotOnRemove, onRemoveInterviewerSlot, onRemoveUser]);

  return useMemo(() => {
    if (!userMembershipId) {
      return {
        createItem: () => null,
      };
    }

    return {
      createItem: (i: ItemCreator<AtlasMenuContentItem>) => {
        return i.item({
          key: "remove",
          children: removeSlotOnRemove
            ? `Remove ${formatEntity("interviewer slot")}`
            : `Remove ${isShadower ? "trainee" : "interviewer"}`,
          isDestructive: removeSlotOnRemove,
          onClick: onRemove,
        });
      },
    };
  }, [onRemove, removeSlotOnRemove, isShadower, userMembershipId]);
}
