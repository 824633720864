import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRingInfo } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { useSchedulingTaskAndAvailabilityWorkflowsNavigation } from "client/app/(scheduling requests)/hooks/useSchedulingTaskAndAvailabilityWorkflowsNavigation";
import { SchedulingTaskAndAvailabilityWorkflowsDialog } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import clsx from "clsx";
import { SchedulingRequestForDetailsViewFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

export function SchedulingTaskCompletedWithInterviewsBanner({
  schedulingRequest,
}: {
  schedulingRequest: SchedulingRequestForDetailsViewFragment;
}) {
  const rescheduableInterviews = useMemo(() => {
    return schedulingRequest.scheduledInterviews.filter(
      (i) =>
        i.interviewRequirementId &&
        new Date(i.startTime).getTime() > Date.now() &&
        !i.isCancelled
    );
  }, [schedulingRequest.scheduledInterviews]);
  const canReschedule = useMemo(() => {
    return rescheduableInterviews.length > 0;
  }, [rescheduableInterviews]);

  const { onClick: onClickCreateTask, dialogProps: createTaskDialogProps } =
    useSchedulingTaskAndAvailabilityWorkflowsNavigation({
      params: {
        defaultSchedulingRequestFormType: canReschedule
          ? "rescheduling"
          : "scheduling",
        guideId: schedulingRequest.guide.id,
        defaultSelectedRescheduleIds: canReschedule
          ? rescheduableInterviews
              .map((i) => i.interviewRequirementId)
              .filter(filterOutNullsAndUndefined)
          : undefined,
        skipRequestingAvailability: true,
      },
      analytics: {
        component: "SchedulingTaskCompletedWithInterviewsBanner",
      },
    });
  const {
    onClick: onRequestAvailability,
    dialogProps: requestAvailabilityDialogProps,
  } = useSchedulingTaskAndAvailabilityWorkflowsNavigation({
    params: {
      defaultSchedulingRequestFormType: "rescheduling",
      guideId: schedulingRequest.guide.id,
    },
    analytics: {
      component: "SchedulingTaskCompletedWithInterviewsBanner",
    },
  });

  return (
    <>
      <div className="w-full bg-white pb-2">
        <div className="bg-blue-50 py-4 px-5 flex text-blue-500 items-center justify-between">
          <div className="flex space-x-2">
            <Icon content={atlasRingInfo} />
            <div className="space-y-2">
              <p className="text-body-md-heavy">Need to reschedule?</p>
              <p className="text-body-sm">
                This task has been completed. Reschedule these interviews by
                creating a new task.
              </p>
              <div className="flex gap-3">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  as="button"
                  variant="default"
                  onClick={onClickCreateTask}
                  className={clsx("!text-blue-500 text-body-sm")}
                >
                  {canReschedule
                    ? "Create new task to reschedule"
                    : "Create new task"}
                </Link>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  as="button"
                  variant="default"
                  onClick={onRequestAvailability}
                  className="!text-blue-500 text-body-sm"
                >
                  Request availability
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SchedulingTaskAndAvailabilityWorkflowsDialog
        {...createTaskDialogProps}
      />
      <SchedulingTaskAndAvailabilityWorkflowsDialog
        {...requestAvailabilityDialogProps}
      />
    </>
  );
}
