import { gql } from "generated/graphql-codegen";
import useMutation from "utils/useMutation";

const CREATE_GUIDE_AVAILABILITY_SUBMISSION = gql(`
  mutation CreateGuideAvailabilitySubmission($input: CreateGuideAvailabilitySubmissionInput!) {
    createGuideAvailabilitySubmission(input: $input) {
      success
      code
      message
      availabilitySubmission {
        id
        guideId
        guide {
          ...GuideForGlobalAvailabilityForCandidateProfile
        }
      }
    }
  }
`);

export function useCreateManualAvailabilitySubmission() {
  return useMutation(CREATE_GUIDE_AVAILABILITY_SUBMISSION);
}
