import { DateTime, Interval } from "luxon";
import { useCallback, useMemo } from "react";

import { useCalendarOptions } from "../hooks/options";
import { useCalendarSettings } from "../hooks/settings";
import { useColumns } from "../hooks/useColumns";
import { CalendarEvent } from "./types";

/** offset for starting rows, not entirely sure why this is necessary still */
export const ROWS_OFFSET = 2;
/** number of rows each hour is broken into */
export const ROWS_PER_HOUR = 12;
/** divide number of minutes since start of day by this to get which row to start at */
export const MINUTES_TO_ROWS_DIVISOR = 60 / ROWS_PER_HOUR;
/** number of minutes in an hour */
export const MINUTES_IN_HOUR = 60;
/** number of hours in a day */
export const HOURS_IN_DAY = 24;
/** number of minutes in a day */
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;
/** number of hours in a day multipled by number of rows per hour */
export const ROWS_IN_DAY = ROWS_PER_HOUR * HOURS_IN_DAY;
/** number of days in a week */
export const DAYS_IN_WEEK = 7;

/** Calculate how to properly place event in grid based on when it starts and how long it is */
export const calculateEventGridRow = ({
  minutesSinceStartOfDay,
  durationInMinutes,
}: {
  minutesSinceStartOfDay: number;
  durationInMinutes: number;
}) => {
  const roundedDuration = Math.ceil(durationInMinutes / 5) * 5;

  // return CSS for grid row value
  return `${
    minutesSinceStartOfDay / MINUTES_TO_ROWS_DIVISOR + ROWS_OFFSET
  } / span ${roundedDuration / MINUTES_TO_ROWS_DIVISOR}`;
};

/**
 * Given a grouping and calendar ID, calculate the correct column index.
 */
export function useGetColumnIndexForGroupingAndCalendarId() {
  const { calendarsGrouping } = useCalendarOptions();
  const { columnType } = useColumns();

  return useCallback(
    ({
      calendarGroupIdx,
      calendarId,
    }: {
      calendarGroupIdx: number;
      calendarId: string;
    }) => {
      if (columnType === "day") {
        return -1; // Column index is not applicable for day view
      }

      if (!calendarsGrouping) {
        return -1; // Return -1 if calendarsGrouping is null
      }

      let columnIndex = 0;
      for (let i = 0; i < calendarsGrouping.length; i += 1) {
        const group = calendarsGrouping[i];
        if (i === calendarGroupIdx) {
          const calendarIndex = group.calendarIds.indexOf(calendarId);
          if (calendarIndex !== -1) {
            return columnIndex + calendarIndex + 1; // Add 1 to fix the off-by-one error
          }
          break; // Exit loop if grouping is found but calendar is not in it
        }
        columnIndex += group.calendarIds.length;
      }

      return -1; // Return -1 if grouping or calendar is not found
    },
    [calendarsGrouping, columnType]
  );
}

/**
 * Given a column index, calculate the correct grouping and calendar ID.
 */
export const useGetGroupingAndCalendarIdForColumnIndex = () => {
  const { calendarsGrouping } = useCalendarOptions();

  return useCallback(
    ({ columnIndex }: { columnIndex: number }) => {
      if (!calendarsGrouping || columnIndex < 0) {
        return { calendarGroupIdx: -1, calendarId: "" };
      }

      let accumulatedColumns = 0;
      for (
        let calendarGroupIdx = 0;
        calendarGroupIdx < calendarsGrouping.length;
        calendarGroupIdx += 1
      ) {
        const group = calendarsGrouping[calendarGroupIdx];
        const groupColumnCount = group.calendarIds.length;

        if (columnIndex < accumulatedColumns + groupColumnCount) {
          const calendarIdIndex = columnIndex - accumulatedColumns;
          return {
            calendarGroupIdx,
            calendarId: group.calendarIds[calendarIdIndex],
          };
        }

        accumulatedColumns += groupColumnCount;
      }

      return { calendarGroupIdx: -1, calendarId: "" };
    },
    [calendarsGrouping]
  );
};

/**
 * Calculate the column the event should be in.
 * This is a hook that uses the calendar context, so can only be called underneath a calendar provider.
 */
export const useEventColumnStart = ({
  calendarId,
  dayOfWeek,
  calendarGroupIdx,
}: {
  dayOfWeek: number;
  /** Only necessary if columnType could be calendar */
  calendarId?: string;
  /** Only necessary if dayViewType could be calendar_and_grouping */
  calendarGroupIdx?: number;
}) => {
  const { view, dayViewType, includeWeekends } = useCalendarSettings();
  const { dayViewCalendarIds, calendarsGrouping } = useCalendarOptions();
  const { columnType } = useColumns();
  const getColumnIndexForGroupingAndCalendarId =
    useGetColumnIndexForGroupingAndCalendarId();

  return useMemo(() => {
    if (columnType === "calendar" && calendarId && dayViewCalendarIds) {
      if (
        dayViewType === "calendar_and_grouping" &&
        calendarsGrouping &&
        calendarsGrouping.length > 0 &&
        calendarGroupIdx !== undefined
      ) {
        return getColumnIndexForGroupingAndCalendarId({
          calendarGroupIdx,
          calendarId,
        });
      }

      return dayViewCalendarIds.indexOf(calendarId) + 1;
    }

    if (view === "day") {
      return 1;
    }

    if (includeWeekends) {
      // This logic is needed to correctly place events with weeks starting on Sunday. See calendarSelectedWeekAtom.
      return (dayOfWeek % 7) + 1;
    }

    return dayOfWeek;
  }, [
    columnType,
    calendarId,
    dayViewCalendarIds,
    view,
    includeWeekends,
    dayOfWeek,
    dayViewType,
    calendarsGrouping,
    calendarGroupIdx,
    getColumnIndexForGroupingAndCalendarId,
  ]);
};

type BaseEvent = {
  id: string;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
};

type CombineEventsEvent = BaseEvent & {
  subEvents: BaseEvent[];
};

export function combineEvents<T extends BaseEvent>(
  events: T[]
): CombineEventsEvent[] {
  // If any of the events are invalid, return the original events
  // and rely on errors being shown in the UI
  const anyEventsInvalid = events.some((event) => {
    return !!Interval.fromDateTimes(event.startTime, event.endTime)
      .invalidReason;
  });

  if (anyEventsInvalid) {
    return events.map((event) => ({
      ...event,
      subEvents: [event],
    }));
  }

  const sortedEvents = events.sort((a, b) => {
    return a.startTime.toMillis() - b.startTime.toMillis();
  });

  const combinedEvents: {
    id: string;
    title: string;
    interval: Interval;
    subEvents: BaseEvent[];
  }[] = [];

  sortedEvents.forEach((event) => {
    const currentEventInterval = {
      id: event.id,
      title: event.title,
      interval: Interval.fromDateTimes(event.startTime, event.endTime),
      subEvents: [event],
    };

    if (combinedEvents.length > 0) {
      const lastEvent = combinedEvents[combinedEvents.length - 1];

      if (
        lastEvent.interval.overlaps(currentEventInterval.interval) ||
        lastEvent.interval.abutsStart(currentEventInterval.interval)
      ) {
        // If the current event overlaps with the last combined event or is back to back, extend the end time of the last combined event
        lastEvent.interval = Interval.fromDateTimes(
          lastEvent.interval.start,
          DateTime.max(
            lastEvent.interval.end,
            currentEventInterval.interval.end
          )
        );
        // Add the current event to the list of sub-events
        lastEvent.subEvents.push(event);
      } else {
        // If the current event doesn't overlap or abut with the last combined event, add it to the combined events
        combinedEvents.push(currentEventInterval);
      }
    } else {
      combinedEvents.push(currentEventInterval);
    }
  });

  return combinedEvents.map((event) => ({
    id: event.id,
    title: event.title,
    startTime: event.interval.start,
    endTime: event.interval.end,
    subEvents: event.subEvents,
  }));
}

type IsEventInViewProps = {
  includeWeekends: boolean;
  currentInterval: Interval;
  event: Pick<CalendarEvent, "startTime" | "endTime" | "allDay">;
};

export function isEventInView({
  includeWeekends,
  currentInterval,
  event,
}: IsEventInViewProps): boolean {
  // Check if the event is on a weekend and should be hidden
  if (!includeWeekends && event.startTime.weekday > 5) {
    return false;
  }

  if (event.allDay) {
    return currentInterval.contains(event.startTime);
  }

  // If the event starts before the interval start but the end time is after the interval start, we want to show it
  const eventCrossesIntoInterval =
    event.startTime <= currentInterval.start &&
    event.endTime > currentInterval.start;
  const eventStartsDuringInterval = currentInterval.contains(event.startTime);

  const eventOverlapsInterval =
    eventCrossesIntoInterval || eventStartsDuringInterval;

  return eventOverlapsInterval;
}
