interface HighlightedTextProps {
  text: string;
  highlight?: string;
}

export function HighlightedText({ text, highlight }: HighlightedTextProps) {
  if (!highlight) return <p className="text-body-md">{text}</p>;

  const parts = text.split(new RegExp(`(${highlight})`, "gi"));

  return (
    <p className="text-body-md">
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} className="bg-yellow-100">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </p>
  );
}
