import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { gql } from "generated/graphql-codegen";
import { AvailabilityRequestForCreateOrUpdateAvailabilitySubmissionFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";

import { AddNotesProps } from "./AddNotes";
import {
  CreateOrUpdateAvailabilityData,
  useCreateOrUpdateAvailabilityState,
  UseCreateOrUpdateAvailabilityStateProps,
} from "./CreateOrUpdateAvailabilityView";
import { CreateOrUpdateAvailabilityViewDesktop } from "./CreateOrUpdateAvailabilityViewDesktop";
import { CreateOrUpdateAvailabilityViewMobile } from "./CreateOrUpdateAvailabilityViewMobile";
import { RequestNotesProps } from "./RequestNotes";

gql(`
  fragment AvailabilityRequestForCreateOrUpdateAvailabilitySubmission on GuideAvailabilityRequest {
    id
    notes
    suggestions {
      id
      startTime
      endTime
      title
    }
    createdBy {
      id
      name
      imageUrl
    }
  }
`);

export type CreateOrUpdateAvailabilitySubmissionViewProps = {
  onSubmit: (
    state: CreateOrUpdateAvailabilityData,
    opts: { closeOnSubmit?: boolean }
  ) => void | Promise<void>;
  onCancel: () => void;
  availabilityRequest?: AvailabilityRequestForCreateOrUpdateAvailabilitySubmissionFragment | null;
  stateProps?: UseCreateOrUpdateAvailabilityStateProps;
  isUpdating?: boolean;
};

export function CreateOrUpdateAvailabilitySubmissionView({
  onSubmit: onSubmitProp,
  onCancel,
  availabilityRequest,
  stateProps = {},
  isUpdating,
}: CreateOrUpdateAvailabilitySubmissionViewProps) {
  const { isOrgUser } = useGuideAuthContext();
  const [loading, setLoading] = useState(false);
  const state = useCreateOrUpdateAvailabilityState(stateProps);
  const defaultCandidateNotes = isUpdating
    ? "Please review my revised availability."
    : "I'm available at the following times.";

  const onSubmit = useCallback(async () => {
    setLoading(true);

    const defaultNotes = !isOrgUser ? defaultCandidateNotes : "";

    await onSubmitProp(
      {
        notes: state.notes || defaultNotes,
        selections: state.selections,
        schedulingPreference: state.schedulingPreference,
      },
      isMobile ? { closeOnSubmit: false } : { closeOnSubmit: true }
    );

    setLoading(false);
  }, [state, onSubmitProp, isOrgUser, defaultCandidateNotes]);

  const title = isOrgUser
    ? "Update candidate availability"
    : "Share availability";
  const { suggestions, notes, createdBy } = availabilityRequest ?? {};
  const requestNotes: RequestNotesProps | undefined =
    notes && createdBy
      ? {
          notes,
          authorName: createdBy.name,
          authorImageUrl: createdBy.imageUrl,
        }
      : undefined;

  const addNotes: Omit<AddNotesProps, "notes" | "setNotes"> = {
    title: isOrgUser ? "Add a comment" : "Message to recruiter",
    placeholder: isOrgUser
      ? "Eg. The candidate prefers to meet in the morning"
      : defaultCandidateNotes,
  };

  if (isMobile) {
    return (
      <CreateOrUpdateAvailabilityViewMobile
        state={state}
        title={title}
        suggestions={suggestions}
        addNotes={addNotes}
        requestNotes={requestNotes}
        close={onCancel}
        onSubmit={onSubmit}
        submitLoading={loading}
        submitLabel={isOrgUser ? "Update availability" : "Share availability"}
      />
    );
  }

  return (
    <CreateOrUpdateAvailabilityViewDesktop
      state={state}
      title={title}
      suggestions={suggestions}
      addNotes={addNotes}
      requestNotes={requestNotes}
      close={onCancel}
      onSubmit={onSubmit}
      submitLoading={loading}
      submitLabel={
        isOrgUser ? "Update candidate availability" : "Share availability"
      }
    />
  );
}
