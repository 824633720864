import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { gql } from "generated/graphql-codegen";
import useMutation from "utils/useMutation";

const ARCHIVE_GUIDE_AVAILABILITY_REQUEST = gql(`
  mutation ArchiveGuideAvailabilityRequest($input: ArchiveGuideAvailabilityRequestInput!) {
    archiveGuideAvailabilityRequest(input: $input) {
      success
      message
      availabilityRequest {
        id
        status
      }
    }
  }
`);

type Props = {
  store: DialogStore;
  availabilityRequestId: string;
  onSubmit?: () => void;
};

export function ArchiveAvailabilityRequestDialog({
  store,
  availabilityRequestId,
  onSubmit,
}: Props) {
  const [archiveRequest, { loading }] = useMutation(
    ARCHIVE_GUIDE_AVAILABILITY_REQUEST,
    {
      variables: {
        input: {
          id: availabilityRequestId,
        },
      },
      onCompleted: () => {
        store.hide();
        onSubmit?.();
      },
    }
  );

  return (
    <Dialog store={store}>
      <View
        header={{
          title: "Cancel availability request",
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button onClick={store.hide} isGhost>
                No, don&apos;t cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => archiveRequest()}
                isLoading={loading}
              >
                Cancel request
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        <div className="space-y-2">
          <div className="text-body-md">
            Are you sure you want to cancel this availability request?
          </div>
          <p className="text-body-md-heavy">
            The candidate will no longer be able to submit their availability
            using this request.
          </p>
        </div>
      </View>
    </Dialog>
  );
}
