import { Button } from "@resource/atlas/button/Button";
import TextArea from "@resource/atlas/textfield/TextArea";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { BackButton } from "client/components/generic/layout/BackButton";
import { Header, HeaderProps } from "client/components/generic/layout/Header";
import {
  AssigneeSelect,
  getAssigneeId,
} from "client/components/generic/select/AssigneeSelect";
import { PrioritySelect } from "client/components/generic/select/PrioritySelect";
import {
  InterviewRequirementsConfigurationContextData,
  InterviewRequirementsConfigurationView,
  useInterviewRequirementsConfigurationContext,
} from "client/scheduling-requirements-configuration/context";
import { EditIndividualInterviewRequirementForm } from "client/scheduling-requirements-configuration/EditIndividualInterviewRequirementForm";
import { SchedulingRequestAlgorithmSettings } from "client/scheduling-requirements-configuration/interviewPanelRequirement/SchedulingRequestAlgorithmSettings";
import { SchedulingRequestLocationSettings } from "client/scheduling-requirements-configuration/interviewPanelRequirement/SchedulingRequestLocationSettings";
import { useAlgorithmSettingsErrors } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/hooks";
import { InterviewRequirementsList } from "client/scheduling-requirements-configuration/InterviewRequirementsList";
import { SchedulingRequestConferenceRoomSettings } from "client/scheduling-task-workflows/__components/SchedulingRequestConferenceRoomSettings";
import { SchedulingRequestConferencingSettings } from "client/scheduling-task-workflows/__components/SchedulingRequestConferencingSettings";
import { SchedulingRequestEventPrivacySettings } from "client/scheduling-task-workflows/__components/SchedulingRequestEventPrivacySettings";
import { gql } from "generated/graphql-codegen";
import {
  GuideForCreateSchedulingRequestFragment,
  SchedulingRequestAutoAssignOnAvailabilityReceiptType,
  SchedulingRequestPriority,
  SchedulingTaskAssigneeType,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo, useState } from "react";
import { formatEntity } from "shared/constants/entities";
import { InterviewPanelLocation } from "shared/interview-requirements/types";

gql(`
  fragment GuideForCreateSchedulingRequest on Guide {
    id
    candidate {
      id
      name: internalName
      ...CandidateTimezoneSelectCandidate
    }
    atssyncApplication {
      id
      currentStage {
        id
        name
      }
    }
    job {
      id
      name: internalName
    }
  }
`);

export type CreateSchedulingRequestFormOnSubmitProps = {
  notes: InterviewRequirementsConfigurationContextData["notes"];
  manualAvailabilities: InterviewRequirementsConfigurationContextData["manualAvailabilities"];
  interviewRequirements: InterviewRequirementsConfigurationContextData["interviewRequirements"];
  autoAssignToQueue: false;
  autoAssignOnAvailabilityReceiptConfig: InterviewRequirementsConfigurationContextData["autoAssignOnAvailabilityReceiptConfig"];
  diffDescription?: string;
  assigneeId: string | null;
  priority: SchedulingRequestPriority;
  requireVideoConferencing?: VideoConferencingServiceType | null;
  algorithmSettings: InterviewRequirementsConfigurationContextData["algorithmSettings"];
  reuseVideoConferencingLink?: boolean;
  location: InterviewPanelLocation | null;
};

export type CreateSchedulingRequestFormProps = {
  guide: GuideForCreateSchedulingRequestFragment;
  onSubmit: (props: CreateSchedulingRequestFormOnSubmitProps) => Promise<void>;
  header: HeaderProps;
  assigmentOptionType?:
    | "pick-assignee"
    | "auto-assign-on-availability-received";
  workflowType: "create-task" | "edit-task";
};

export function CreateSchedulingRequestForm({
  guide,
  workflowType,
  onSubmit,
  header,
  assigmentOptionType,
}: CreateSchedulingRequestFormProps) {
  const { user } = useAuthContext();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [manuallySelectedAssignee, setManuallySelectedAssignee] =
    useState(false);

  const {
    view,
    notes,
    setNotes,
    manualAvailabilities,
    interviewRequirements,
    algorithmSettings,
    setAlgorithmSettings,
    goBack,
    diffDescription,
    assignee,
    setAssignee,
    autoAssignOnAvailabilityReceiptConfig,
    priority,
    setPriority,
    requireVideoConferencing,
    setRequireVideoConferencing,
    reuseVideoConferencingLink,
    setReuseVideoConferencingLink,
    requireConferenceRoom,
    setRequireConferenceRoom,
    updateInterviewRequirement,
    onRemoveInterviewerRequirement,
    selectedInterviewRequirement,
    defaultAssigneeType,
    defaultPrivate,
    setDefaultPrivate,
    location,
    setLocation,
  } = useInterviewRequirementsConfigurationContext();

  const algorithmSettingsErrors = useAlgorithmSettingsErrors({
    algorithmSettings,
    interviewRequirements,
  });

  const submit = useCallback(async () => {
    setSubmitLoading(true);

    try {
      await onSubmit({
        notes,
        interviewRequirements,
        diffDescription,
        manualAvailabilities,
        assigneeId: assignee ? getAssigneeId(assignee) : null,
        autoAssignToQueue: false,
        autoAssignOnAvailabilityReceiptConfig,
        priority,
        requireVideoConferencing,
        algorithmSettings,
        reuseVideoConferencingLink,
        location,
      });
    } finally {
      setSubmitLoading(false);
    }
  }, [
    onSubmit,
    notes,
    interviewRequirements,
    diffDescription,
    manualAvailabilities,
    assignee,
    autoAssignOnAvailabilityReceiptConfig,
    priority,
    requireVideoConferencing,
    algorithmSettings,
    reuseVideoConferencingLink,
    location,
  ]);

  const validationError = useMemo(() => {
    const filteredRequirements = interviewRequirements.filter(
      (interviewRequirement) => !interviewRequirement.manuallyRemovedAt
    );
    if (filteredRequirements.length === 0) {
      return "No interviews selected";
    }
    const hasEmptyInterviewerRequirements = filteredRequirements.some(
      (interviewRequirement) =>
        interviewRequirement.interviewerRequirements.length === 0
    );

    const hasEmptyTagFilters = filteredRequirements.some(
      (interviewRequirement) =>
        interviewRequirement.interviewerRequirements.some((ir) =>
          ir.tagFilters?.some((filter) => filter.tags.length === 0)
        )
    );

    if (hasEmptyInterviewerRequirements) {
      return "All interviews must have at least 1 interviewer requirement";
    }

    if (hasEmptyTagFilters) {
      return "All tag filters must have at least 1 tag selected";
    }

    if (algorithmSettingsErrors) {
      return "Invalid scheduling preferences";
    }

    return undefined;
  }, [interviewRequirements, algorithmSettingsErrors]);

  if (
    view !== InterviewRequirementsConfigurationView.LIST &&
    selectedInterviewRequirement
  ) {
    return (
      <View
        className="bg-white"
        header={{
          leftActions: <BackButton onClick={goBack} />,
        }}
        content={{
          className: "p-6",
        }}
      >
        <EditIndividualInterviewRequirementForm
          interviewRequirement={selectedInterviewRequirement}
          onRemoveInterviewerRequirement={({ interviewerRequirementId }) => {
            onRemoveInterviewerRequirement({
              interviewerRequirementId,
              interviewRequirementId: selectedInterviewRequirement.id,
            });
          }}
          updateInterviewRequirement={(updatedInterview) =>
            updateInterviewRequirement(
              selectedInterviewRequirement.id,
              updatedInterview
            )
          }
        />
      </View>
    );
  }

  return (
    <div className="flex flex-col h-full bg-white">
      <Header {...header} />
      <div className="p-6 h-full flex-grow overflow-auto">
        <div className="space-y-4">
          {user?.currentUserMembership &&
            assigmentOptionType === "pick-assignee" && (
              <div className="space-y-2">
                <p className="text-body-md-heavy">Assignee</p>
                <AssigneeSelect
                  assignee={assignee}
                  onSelect={(newAssignee, isAutoAssign) => {
                    if (!isAutoAssign) {
                      setManuallySelectedAssignee(true);
                    }

                    setAssignee(
                      newAssignee ===
                        SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE
                        ? null
                        : newAssignee
                    );
                  }}
                  variant="form"
                  defaultToSuggestedUser={
                    !manuallySelectedAssignee && workflowType === "create-task"
                      ? defaultAssigneeType ??
                        SchedulingTaskAssigneeType.COORDINATION_QUEUE
                      : undefined
                  }
                />
              </div>
            )}
          <div className="space-y-2">
            <p className="text-body-md-heavy">Priority</p>
            <PrioritySelect value={priority} onChange={setPriority} />
          </div>
          <div className="space-y-3">
            <InterviewRequirementsList
              jobId={guide.job.id}
              atssyncJobStage={
                guide.atssyncApplication?.currentStage ?? undefined
              }
              subtext="Accurate interview requirements allow Guide AI (and your coordinators)
        to find the best times for scheduling once availability is received."
            />
          </div>
          <div>
            <SchedulingRequestAlgorithmSettings
              algorithmSettings={algorithmSettings}
              onAlgorithmSettingsChange={setAlgorithmSettings}
              interviewRequirements={interviewRequirements}
            />
          </div>

          <div className="space-y-2">
            <p className="text-body-md-heavy">Settings</p>
            <SchedulingRequestConferencingSettings
              requireVideoConferencing={requireVideoConferencing}
              setRequireVideoConferencing={setRequireVideoConferencing}
              reuseVideoConferencingLink={reuseVideoConferencingLink}
              setReuseVideoConferencingLink={setReuseVideoConferencingLink}
            />
            <SchedulingRequestConferenceRoomSettings
              requireConferenceRoom={requireConferenceRoom}
              setRequireConferenceRoom={setRequireConferenceRoom}
            />
            <SchedulingRequestEventPrivacySettings
              defaultPrivate={defaultPrivate}
              setDefaultPrivate={setDefaultPrivate}
            />
            <SchedulingRequestLocationSettings
              location={location}
              setLocation={setLocation}
            />
          </div>
          <div className="space-y-2">
            <p className="text-body-md-heavy">Add scheduling notes</p>
            <TextArea
              placeholder="Add a note..."
              aria-label="Notes"
              value={notes}
              onChange={(newNotes) => setNotes(newNotes)}
            />
          </div>
        </div>
      </div>
      <div className="h-16 border-t border-[#EEEDEF] p-4 w-full flex justify-end">
        <Tooltip content={validationError} isInstant>
          <Button
            variant="purple"
            disabled={!!validationError}
            onClick={submit}
            isLoading={submitLoading}
          >
            {`${
              workflowType === "edit-task" ? "Update" : "Create"
            } ${formatEntity("request")}`}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
