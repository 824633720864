/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LineBreakNode } from "lexical";
import { useEffect } from "react";

import { registerNodeTransform } from "../../__utils/misc";

// normalization plugin
// --------------------

export function NewLinePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      registerNodeTransform(editor, LineBreakNode, (node) => {
        node.remove();
      }),
    [editor]
  );

  return null;
}
