import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import clsx from "clsx";
import { ReactNode } from "react";

interface CondensedRequestInfoRowProps {
  icon: AtlasIconData;
  children: ReactNode;
  action?: ReactNode;
  className?: string;
  childContainerClassName?: string;
  multiline?: boolean;
}

export function CondensedRequestInfoRow({
  icon,
  multiline = false,
  children: rawChildren,
  action,
  className,
  childContainerClassName,
}: CondensedRequestInfoRowProps) {
  const children =
    typeof rawChildren === "string" ? (
      <p className="text-body-sm">{rawChildren}</p>
    ) : (
      rawChildren
    );

  return (
    <div
      className={clsx(
        "text-body-sm flex justify-start text-dark gap-2",
        multiline ? "h-full items-start" : "items-center",
        className
      )}
    >
      <Icon
        content={icon}
        className={clsx(
          "flex text-subtle w-5 h-5",
          multiline ? "pt-[.125rem]" : "items-center"
        )}
      />
      <div
        className={clsx(
          "flex grow min-w-0",
          {
            "pt-[.125rem]": multiline,
          },
          childContainerClassName
        )}
      >
        {children}
      </div>
      {action}
    </div>
  );
}
