import { Button } from "@resource/atlas/button/Button";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { gql } from "generated/graphql-codegen";
import useMutation from "utils/useMutation";

const FORCE_RESYNC_ALL_APPLICATION_DATA_MUTATION = gql(`
  mutation ForceResyncAllApplicationData($input: ForceResyncAllApplicationDataInput!) {
    forceResyncAllApplicationData(input: $input) {
      success
      message
    }
  }
`);

type Props = {
  store: DialogStore;
  applicationId: string;
};

export function ResyncApplicationDataConfirmationDialog({
  applicationId,
  store,
}: Props) {
  const [forceResyncAllApplicationData, { loading }] = useMutation(
    FORCE_RESYNC_ALL_APPLICATION_DATA_MUTATION,
    {
      variables: {
        input: {
          applicationId,
        },
      },
      onCompleted: () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
    }
  );

  return (
    <Dialog store={store}>
      <View
        header={{
          title: "Force resync data from Greenhouse",
        }}
        footer={{
          leftActions: <Button onClick={store.hide}>Cancel</Button>,
          rightActions: (
            <Button
              variant="primary"
              onClick={() => forceResyncAllApplicationData()}
              isLoading={loading}
            >
              Force resync
            </Button>
          ),
        }}
      >
        <div className="text-body-md">
          Guide syncs data from Greenhouse in realtime where possible, but
          occasionally we have to sync on longer time intervals. Forcing resync
          ensures the candidate&apos;s application and scheduled interviews are
          fully up-to-date in Guide.
        </div>
      </View>
    </Dialog>
  );
}
