import { useLogEvent } from "analytics";
import { GuideAuthRequiredButton } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__components/guide-auth/GuideAuthRequiredButton";
import { BlockError } from "client/components/generic/errors/BlockError";
import { CandidateAvailability } from "client/guide-availability/components/CandidateAvailability";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import { GuideAvailabilitySubmissionForDisplayFragment } from "generated/graphql-codegen/graphql";
import React, { MouseEventHandler } from "react";

gql(`
  fragment GuideAvailabilitySubmissionForDisplay on GuideAvailabilitySubmission {
    id
    notes
    events {
      id
      title
      startTime
      endTime
    }
  }
`);

type AvailabilitySubmissionProps = {
  className?: string;
  availabilitySubmission?: {
    notes: GuideAvailabilitySubmissionForDisplayFragment["notes"];
    events: GuideAvailabilitySubmissionForDisplayFragment["events"];
  } | null;
  onUpdate?: MouseEventHandler<HTMLButtonElement>;
  isCurrentSubmission?: boolean;
  timezone?: string | null;
  variant: "candidate" | "company";
  loading?: boolean;
  error?: boolean;
};

export function AvailabilitySubmission({
  className,
  onUpdate,
  isCurrentSubmission = true,
  availabilitySubmission,
  timezone,
  variant,
  loading = false,
  error = false,
}: AvailabilitySubmissionProps) {
  const logEvent = useLogEvent({
    component: "AvailabilitySubmission",
  });

  if (loading) {
    return (
      <div className="w-full space-y-3 shadow-1 rounded-md p-3">
        <div className="space-y-[.1875rem]">
          <Skeleton className="w-2/3 h-[14px]" type="text" />
          <Skeleton className="w-2/3 h-[14px]" type="text" />
          <Skeleton className="w-2/3 h-[14px]" type="text" />
        </div>
        <div className="flex gap-2">
          <Skeleton className="w-1/5 h-[14px]" type="text" />
          <Skeleton className="w-1/5 h-[14px]" type="text" />
        </div>
      </div>
    );
  }

  if (error || !availabilitySubmission) {
    return (
      <BlockError
        label="Can't load availability submission"
        showCta={variant === "company"}
      />
    );
  }

  const { notes, events } = availabilitySubmission;
  const availabilitySubmitted = !!events?.length;

  return (
    <div className="space-y-4 w-full">
      {notes && (
        <p className="text-body-md text-dark">
          {notes.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <br />}
              {line}
            </React.Fragment>
          ))}
        </p>
      )}
      <div
        className={clsx(
          "p-4 shadow-1 rounded-md mt-2 space-y-4 text-body-md w-full",
          className
        )}
      >
        {availabilitySubmitted && (
          <CandidateAvailability
            events={events}
            isCurrentSubmission={isCurrentSubmission}
            timezone={timezone}
            allowCollapse={false}
          />
        )}
        {variant === "candidate" ? (
          <>
            {!!onUpdate && isCurrentSubmission && (
              <GuideAuthRequiredButton
                asLink
                onClick={(e) => {
                  logEvent("Update Your Availability Clicked");
                  onUpdate(e);
                }}
              >
                Update your availability
              </GuideAuthRequiredButton>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}
