import { atom, useSetAtom } from "jotai";
import { DateTime } from "luxon";

import { SchedulerEditableScheduledInterview } from "../utils/types";
import {
  interviewsAtom,
  originalInterviewsAtom,
  selectedInterviewIdAtom,
} from "./state";

type SetInterviewTime = {
  type: "setInterviewTime";
  interviewId: string;
  startTime: DateTime;
  endTime: DateTime;
};

type AddInterview = {
  type: "add";
  interview: SchedulerEditableScheduledInterview;
};

type CancelInterview = {
  type: "cancel";
  interviewId: string;
};

type RemoveInterview = {
  type: "remove";
  interviewId: string;
};

export type InterviewAction =
  | SetInterviewTime
  | AddInterview
  | CancelInterview
  | RemoveInterview;

export const interviewsDispatchAtom = atom(
  null,
  (get, set, action: InterviewAction): void => {
    if (action.type === "setInterviewTime") {
      set(interviewsAtom, (prev) =>
        prev.map((i): SchedulerEditableScheduledInterview => {
          if (i.id === action.interviewId) {
            const newDuration = action.endTime
              .diff(action.startTime)
              .as("minutes");

            return {
              ...i,
              startTime: action.startTime.toISO(),
              endTime: action.endTime.toISO(),
              duration: newDuration,
              isCancelled: false,
            };
          }

          return i;
        })
      );
    }

    if (action.type === "add") {
      set(interviewsAtom, (prev) => [...prev, action.interview]);
      set(selectedInterviewIdAtom, action.interview.id);
    }

    if (action.type === "cancel") {
      const originalInterviews = get(originalInterviewsAtom);
      const originalInterview = originalInterviews.find(
        (i) => i.id === action.interviewId
      );

      set(interviewsAtom, (prev) =>
        prev.map((i): SchedulerEditableScheduledInterview => {
          if (i.id === action.interviewId) {
            if (originalInterview && originalInterview.startTime) {
              // If we are cancelling an existing interview that was already scheduled,
              // revert back to default settings and mark as cancelled
              return {
                ...originalInterview,
                isCancelled: true,
              };
            }

            // Otherwise just unstage it

            return {
              ...i,
              startTime: null,
              endTime: null,
            };
          }

          return i;
        })
      );
    }

    if (action.type === "remove") {
      set(interviewsAtom, (prev) =>
        prev.filter((i) => i.id !== action.interviewId)
      );
    }
  }
);

export function useSchedulerInterviewsDispatch() {
  return useSetAtom(interviewsDispatchAtom);
}
