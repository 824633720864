import "./ContentEditorPopover.sass";

import { PopoverProps, PopoverStateProps } from "ariakit";
import { ReactNode } from "react";

import { atlasClose } from "../../../../../icons/atlas";
import { createComponentUtils } from "../../../../__utils/atlas";
import { Button } from "../../../../button/Button";
import { Popover } from "../../../../popover";
import { usePopoverState } from "../../../../popover/use-popover-state";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Popover";

const { el } = createComponentUtils(COMPONENT_NAME);

export type ContentEditorPopoverProps = Pick<
  PopoverStateProps,
  "open" | "setOpen" | "getAnchorRect"
> & {
  title: string;
  description?: string | ReactNode;
  footer?: ReactNode;
  trigger?: ReactNode;
  children?: ReactNode;
  initialFocusRef?: NonNullable<PopoverProps["initialFocusRef"]>;
  className?: string;
};

// content editor popover
// ----------------------

export function ContentEditorPopover({
  open,
  setOpen,
  getAnchorRect,
  className,
  title,
  description,
  footer,
  trigger,
  children,
  initialFocusRef,
}: ContentEditorPopoverProps) {
  const popover = usePopoverState({
    open,
    setOpen,
    getAnchorRect,
  });

  return (
    <Popover.Root state={popover}>
      {trigger && <Popover.Trigger>{trigger}</Popover.Trigger>}
      <Popover.Content
        className={className}
        portal
        initialFocusRef={initialFocusRef}
        header={
          <div className={el`header`}>
            <div className="top-row">
              <Popover.Heading className="title">{title}</Popover.Heading>
              <Button
                isGhost
                size="xs"
                icon={atlasClose}
                onClick={popover.hide}
              />
            </div>
            {description && (
              <Popover.Description className="description">
                {description}
              </Popover.Description>
            )}
          </div>
        }
        footer={footer}
      >
        {children}
      </Popover.Content>
    </Popover.Root>
  );
}
