import { VariableSet } from "@resource/atlas/content-editor/variables";
import { createNameSetFromKeys } from "modules/lexical/shared/name-sets";
import { createRendererSetFromKeys } from "modules/lexical/shared/renderer-sets";
import { createSpecSetFromKeys } from "modules/lexical/shared/spec-sets";
import { completeVariableKeys } from "modules/lexical/shared/utils/constants";
import { VariableKey } from "modules/lexical/shared/utils/types";

/**
 * A wrapper function that allows us to create the sets required for lexical rendering. Enforces the use of valid
 * VariableKeys and ensures we never have conflicting definitions for the same variable key e.g. atsCoordinatorTitle.
 */
export function createVariableSets<T extends VariableSet>(
  keys: ReadonlyArray<VariableKey>
) {
  const rendererSet = createRendererSetFromKeys<T>(keys);
  const nameSet = createNameSetFromKeys<T>(keys);
  const specSet = createSpecSetFromKeys<T>(keys, { rendererSet, nameSet });

  return { rendererSet, nameSet, specSet };
}

const {
  nameSet: completeNameSet,
  rendererSet: completeRendererSet,
  specSet: completeSpecSet,
} = createVariableSets(completeVariableKeys);

export { completeNameSet, completeRendererSet, completeSpecSet };
