import { AvatarGroup } from "@resource/atlas/avatar/AvatarGroup";
import { AtlasAvatarGroupProps } from "@resource/atlas/avatar/types";
import { useAvatarGroupItems } from "@resource/atlas/avatar/use-avatar-group-items";
import { getAvatarStatusFromInterviewerResponseStatus } from "client/utils/interviewer-response-status";
import clsx from "clsx";
import { InterviewerResponse } from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef } from "react";

export type Interviewer = {
  id: string;
  isShadow: boolean;
  userMembership: {
    id: string;
    name: string;
    imageUrl: string | null;
  };
  responseStatus: InterviewerResponse | null;
  responseNote?: string | null;
};

export function InterviewersSection({
  size = "small",
  max = 2,
  interviewers,
  isInPast,
  showRsvpStatus,
  className,
  showShadowingStatus,
  ...props
}: ComponentPropsWithoutRef<"section"> & {
  size?: AtlasAvatarGroupProps["size"];
  max?: AtlasAvatarGroupProps["max"];
  interviewers: Interviewer[];
  isInPast: boolean;
  showRsvpStatus?: boolean;
  showShadowingStatus?: boolean;
}) {
  const interviewerAvatars = useAvatarGroupItems(
    (i) =>
      interviewers.map((interviewer) => {
        const status = getAvatarStatusFromInterviewerResponseStatus(
          interviewer.responseStatus
        );

        return i.avatar({
          key: interviewer.id,
          name: showShadowingStatus
            ? `${interviewer.userMembership.name}${
                interviewer.isShadow ? " (Shadowing)" : ""
              }`
            : interviewer.userMembership.name,
          image: interviewer.userMembership.imageUrl,
          status: showRsvpStatus ? status : undefined,
          statusNote: showRsvpStatus ? interviewer.responseNote : undefined,
        });
      }),
    [showRsvpStatus, interviewers, showShadowingStatus]
  );

  return (
    <section
      {...props}
      className={clsx("flex gap-[0.375rem] items-center", className)}
      style={{
        ...(isInPast ? { filter: "saturate(20%) brightness(100%)" } : {}),
      }}
    >
      <AvatarGroup size={size} max={max} items={interviewerAvatars} />
    </section>
  );
}
