import clsx from "clsx";

import { type ButtonProps } from "./Button";

export interface BaseButtonStyleProps {
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  isGhost?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  negativeMargin?: ButtonProps["negativeMargin"];
}

export function getButtonStyles({
  variant = "default",
  size = "md",
  isGhost = false,
  isDisabled = false,
  negativeMargin,
}: BaseButtonStyleProps) {
  const baseStyles =
    "inline-flex items-center justify-center font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2";

  const variantStyles = {
    default: clsx(
      isGhost
        ? "bg-transparent hover:bg-light-gray-600 active:bg-light-gray-700 text-dark"
        : "bg-light-gray-500 text-dark hover:bg-light-gray-600 active:bg-light-gray-700",
      "focus:ring-white"
    ),
    primary: clsx(
      isGhost
        ? "bg-transparent hover:bg-purple-50 text-purple-600"
        : "bg-purple-600 text-white hover:bg-purple-700",
      "focus:ring-purple-500"
    ),
    secondary: clsx(
      isGhost
        ? "bg-transparent hover:bg-purple-50 text-purple-600"
        : "bg-purple-100 text-purple-700 hover:bg-purple-200",
      "focus:ring-purple-500"
    ),
    danger: clsx(
      isGhost
        ? "bg-transparent hover:bg-red-50 text-red-600"
        : "bg-red-600 text-white hover:bg-red-700",
      "focus:ring-red-500"
    ),
  };

  const sizeStyles = {
    sm: "px-[.625rem] py-1.5 text-body-xs",
    md: "px-[.625rem] py-2.5 text-body-sm",
    lg: "px-6 py-3 text-body-md",
  };

  const marginStyles = (() => {
    switch (negativeMargin) {
      case "left":
        return "-ml-2";
      case "right":
        return "-mr-2";
      case "both":
        return "-mx-2";
      default:
        return "";
    }
  })();

  return clsx(
    baseStyles,
    variantStyles[variant],
    sizeStyles[size],
    marginStyles,
    isDisabled && "opacity-50 cursor-not-allowed",
    "relative group"
  );
}

export function getIconStyles(size: ButtonProps["size"]) {
  return clsx(size === "sm" ? "h-4 w-4" : "h-5 w-5");
}
