import { MutationUpdaterFn } from "@apollo/client";
import { useToast } from "@resource/atlas/toast/use-toast";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { getAssigneeId } from "client/components/generic/select/AssigneeSelect";
import { ReportRescheduleReasonFormState } from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportRescheduleReasonState";
import { GuideComposeMessageState } from "client/message-composer/__hooks/useGuideComposeMessageState";
import { mapWorkflowTypeToGraphQLInput } from "client/message-composer/utils";
import { interviewPanelRequirementHasChanged } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/helpers";
import { mapInterviewPanelRequirementFragmentToInterviewPanelRequirement } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/mapping";
import { InterviewPanelRequirement } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/types";
import { mapInterviewRequirementV2InputFromInterviewRequirementFragment } from "client/scheduling-requirements-configuration/utils";
import deepDiff from "deep-diff";
import { gql } from "generated/graphql-codegen";
import {
  GuideForSchedulingTaskAndAvailabilityWorkflowsFragment,
  InterviewRequirementForConfigurationFragment,
  SchedulingRequestForSchedulingRequestCardFragment,
  SchedulingTaskAndAvailabilityWorkflowSubmitMutation,
  SchedulingTaskAndAvailabilityWorkflowSubmitMutationVariables,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";
import useMutation from "utils/useMutation";
import { contentEditorIsEmpty } from "utils/validation";
import { v4 } from "uuid";

import { SchedulingRequestFormState } from "./useSchedulingRequestFormState";
import { WorkflowStateForGuide } from "./useWorkflowStateForGuide";

const SUBMIT_WORKFLOW_MUTATION = gql(`
  mutation SchedulingTaskAndAvailabilityWorkflowSubmit($input: SchedulingTaskAndAvailabilityWorkflowsInput!) {
    schedulingTaskAndAvailabilityWorkflows(input: $input) {
      code
      success
      message
      schedulingRequest {
        id
        ...SchedulingRequestForSchedulingRequestCard
      }
      reschedulingRequests {
        id
        ...SchedulingRequestForSchedulingRequestCard
      }
      guidePost {
        id
      }
      availabilitySubmission {
        id
        guide {
          ...GuideForGlobalAvailabilityForCandidateProfile
        }
      }
      availabilityRequest {
        id
        guide {
          ...GuideForGlobalAvailabilityForCandidateProfile
        }
      }
    }
  }
`);

type AdditionalWorkflowSubmitCompletedData = {
  requirementsHaveChanged: boolean;
  jobInterviewPanelRequirementId: string;
  schedulingRequestTemplateId: string;
  newInterviewRequirements: InterviewRequirementForConfigurationFragment[];
  newInterviewPanelRequirement: InterviewPanelRequirement;
};

export type WorkflowSubmitOnCompleted = (
  result: SchedulingTaskAndAvailabilityWorkflowSubmitMutation,
  additional: AdditionalWorkflowSubmitCompletedData | undefined
) => void;

type UseOnWorkflowSubmitProps = {
  onCompleted?: WorkflowSubmitOnCompleted;
  schedulingRequestFormState: SchedulingRequestFormState;
  composeState: GuideComposeMessageState;
  schedulingRequestTemplate: {
    id: string;
    interviewPanelRequirementId: string;
  };
  workflowState: WorkflowStateForGuide;
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
  workflowType: PostMessageWorkflow;
  reportRescheduleReasonState: ReportRescheduleReasonFormState | null;
};

export function useOnWorkflowSubmit(props: UseOnWorkflowSubmitProps) {
  const logEvent = useLogEvent({
    component: "SchedulingTaskWorkflows",
  });
  const { sendToast } = useToast();
  const {
    guide,
    onCompleted,
    schedulingRequestFormState,
    composeState,
    schedulingRequestTemplate,
    workflowState,
    workflowType,
    reportRescheduleReasonState,
  } = props;
  const schedulingRequestTemplateId = schedulingRequestTemplate.id;
  const { shouldCreateSchedulingTask, shouldRequestAvailability } =
    workflowState;
  const { user } = useAuthContext();

  const [submitMutation, { loading: submitLoading }] = useMutation(
    SUBMIT_WORKFLOW_MUTATION
  );

  const submit = useCallback(async () => {
    const requestAvailabilityForm = composeState.form.getValues();

    let schedulingRequestV2Params: SchedulingTaskAndAvailabilityWorkflowSubmitMutationVariables["input"]["schedulingRequestV2"];
    let reschedulingRequestParams: SchedulingTaskAndAvailabilityWorkflowSubmitMutationVariables["input"]["reschedulingRequest"];
    let reschedulingRequestV2Params: SchedulingTaskAndAvailabilityWorkflowSubmitMutationVariables["input"]["reschedulingRequestV2"];
    let guidePostParams: SchedulingTaskAndAvailabilityWorkflowSubmitMutationVariables["input"]["guidePost"];
    let manualAvailabilitySubmissionParams: SchedulingTaskAndAvailabilityWorkflowSubmitMutationVariables["input"]["manualAvailabilitySubmission"];

    if (shouldCreateSchedulingTask) {
      if (schedulingRequestFormState.formType === "scheduling") {
        const {
          notes,
          schedulingRequestState,
          assignee,
          autoAssignOnAvailabilityReceiptConfig,
          priority,
          reuseVideoConferencingLink,
          algorithmSettings,
          location,
        } = schedulingRequestFormState;
        const { interviewRequirements } = schedulingRequestState;

        schedulingRequestV2Params = {
          ...(notes && { notes }),
          autoAssignOnAvailabilityReceiptConfig: {
            autoAssignType:
              autoAssignOnAvailabilityReceiptConfig.autoAssignType,
            assignToUserMembershipId:
              autoAssignOnAvailabilityReceiptConfig.assignToUserMembership?.id,
          },
          assigneeId: assignee ? getAssigneeId(assignee) : null,
          priority,
          interviewPanelRequirement: {
            id: schedulingRequestTemplate.interviewPanelRequirementId,
            interviewRequirements: interviewRequirements.map(
              mapInterviewRequirementV2InputFromInterviewRequirementFragment
            ),
            reuseVideoConferencingLink,
            algorithmSettings,
            location,
          },
          schedulingRequestTemplateId,
        };
      }

      if (schedulingRequestFormState.formType === "rescheduling") {
        const {
          notes,
          rescheduleRequestState,
          assignee,
          autoAssignOnAvailabilityReceiptConfig,
          priority,
        } = schedulingRequestFormState;
        const {
          selectedRescheduleInterviewRequirementIdsV1,
          selectedRescheduleScheduledInterviewIdsV2,
        } = rescheduleRequestState;

        const baseReschedulingRequestParams = {
          ...(notes && { notes }),
          autoAssignOnAvailabilityReceiptConfig: {
            autoAssignType:
              autoAssignOnAvailabilityReceiptConfig.autoAssignType,
            assignToUserMembershipId:
              autoAssignOnAvailabilityReceiptConfig.assignToUserMembership?.id,
          },
          assigneeId: shouldRequestAvailability
            ? user?.currentUserMembership?.id
            : (assignee && getAssigneeId(assignee)) ?? null,
          priority,
          reportingReason: reportRescheduleReasonState
            ? reportRescheduleReasonState?.form.getValues() ?? null
            : null,
        };

        // V1 interviews use selectedInterviewRequirementIds
        if (selectedRescheduleInterviewRequirementIdsV1.length > 0) {
          reschedulingRequestParams = {
            ...baseReschedulingRequestParams,
            rescheduleInterviewRequirementIds:
              selectedRescheduleInterviewRequirementIdsV1,
          };
        }

        // V2 interviews use selectedScheduledInterviewIds
        if (selectedRescheduleScheduledInterviewIdsV2.length > 0) {
          reschedulingRequestV2Params = {
            ...baseReschedulingRequestParams,
            rescheduleScheduledInterviewIds:
              selectedRescheduleScheduledInterviewIdsV2,
            // Only V2 interviews can be cancelled during reschedule task creation
            cancelInterviewSettings:
              rescheduleRequestState.cancelInterviewSettings,
          };
        }
      }

      const { manualAvailabilityState } = schedulingRequestFormState;

      if (
        manualAvailabilityState &&
        manualAvailabilityState.selections.length
      ) {
        manualAvailabilitySubmissionParams = {
          events: manualAvailabilityState.selections.map((s) => ({
            title: s.title,
            startTime: s.startTime,
            endTime: s.endTime,
          })),
          notes: manualAvailabilityState.notes,
          schedulingPreference: manualAvailabilityState.schedulingPreference,
        };
      }
    }

    if (shouldRequestAvailability) {
      const {
        content,
        recipients,
        subject,
        sender,
        to,
        threadId,
        moveToInterviewPlanItemId,
      } = requestAvailabilityForm;

      guidePostParams = {
        ...(!contentEditorIsEmpty(content) && { content }),
        title: !threadId ? JSON.stringify(subject) : null,
        senderId: sender.id,
        to,
        recipients: recipients.map((r) => ({
          userMembershipId: r.isCandidate ? null : r.id,
          email: r.email,
          isCandidate: r.isCandidate,
        })),
        postTemplateId: composeState?.template?.id,
        threadId,
        ...(schedulingRequestFormState.formType === "select-task" && {
          schedulingRequestId:
            schedulingRequestFormState.selectTaskState
              .selectedSchedulingRequestId,
        }),
        ...(moveToInterviewPlanItemId && { moveToInterviewPlanItemId }),
        workflowType: mapWorkflowTypeToGraphQLInput(workflowType),
      };
    }

    await submitMutation({
      variables: {
        input: {
          guideId: guide.id,
          schedulingRequestV2: schedulingRequestV2Params,
          reschedulingRequest: reschedulingRequestParams,
          reschedulingRequestV2: reschedulingRequestV2Params,
          guidePost: guidePostParams,
          manualAvailabilitySubmission: manualAvailabilitySubmissionParams,
        },
      },
      onCompleted: (result) => {
        const isReschedule =
          result.schedulingTaskAndAvailabilityWorkflows.schedulingRequest
            ?.isReschedule ?? false;
        if (result.schedulingTaskAndAvailabilityWorkflows.availabilityRequest) {
          logEvent("Message Sent", {
            workflowType: isReschedule
              ? PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY
              : PostMessageWorkflow.REQUEST_AVAILABILITY,
          });
        }

        logEvent("Scheduling Task Created", {
          availabilityRequested:
            !!result.schedulingTaskAndAvailabilityWorkflows.availabilityRequest,
          availabilityManuallyAdded:
            !!result.schedulingTaskAndAvailabilityWorkflows
              .availabilitySubmission,
          isReschedule,
        });

        let additionalWorkflowCompletedData:
          | AdditionalWorkflowSubmitCompletedData
          | undefined;

        if (schedulingRequestFormState.formType === "scheduling") {
          const {
            defaultInterviewPanelRequirement,
            interviewRequirements: newInterviewRequirements,
          } = schedulingRequestFormState.schedulingRequestState;

          const { algorithmSettings, reuseVideoConferencingLink, location } =
            schedulingRequestFormState;

          const interviewRequirementsHaveChangedFromDefault = !!deepDiff(
            defaultInterviewPanelRequirement?.interviewRequirements,
            newInterviewRequirements
          );

          const newInterviewPanelRequirement = {
            algorithmSettings,
            reuseVideoConferencingLink,
            location,
            interviewRequirements: newInterviewRequirements,
          };

          const interviewPanelRequirementHasChangedFromDefault =
            !!defaultInterviewPanelRequirement &&
            interviewPanelRequirementHasChanged({
              oldInterviewPanelRequirement:
                mapInterviewPanelRequirementFragmentToInterviewPanelRequirement(
                  defaultInterviewPanelRequirement
                ),
              newInterviewPanelRequirement,
            });

          const jobInterviewPanelRequirementId =
            defaultInterviewPanelRequirement?.id ?? v4();

          additionalWorkflowCompletedData = {
            requirementsHaveChanged:
              interviewRequirementsHaveChangedFromDefault ||
              interviewPanelRequirementHasChangedFromDefault,
            newInterviewRequirements,
            newInterviewPanelRequirement,
            jobInterviewPanelRequirementId,
            schedulingRequestTemplateId,
          };
        }

        if (!result.schedulingTaskAndAvailabilityWorkflows.success) {
          sendToast("Looks like something went wrong.", {
            description:
              "Please try again. If the problem persists, reach out to support.",
            variant: "error",
          });
        }

        if (
          onCompleted &&
          result.schedulingTaskAndAvailabilityWorkflows.success
        ) {
          onCompleted(result, additionalWorkflowCompletedData);
        }
      },
      update: schedulingTaskWorkflowUpdate,
    });
  }, [
    composeState.form,
    composeState?.template?.id,
    shouldCreateSchedulingTask,
    shouldRequestAvailability,
    submitMutation,
    guide.id,
    schedulingRequestFormState,
    schedulingRequestTemplate.interviewPanelRequirementId,
    schedulingRequestTemplateId,
    user?.currentUserMembership?.id,
    reportRescheduleReasonState,
    workflowType,
    logEvent,
    onCompleted,
    sendToast,
  ]);

  return useMemo(
    () =>
      [
        submit,
        {
          loading: submitLoading,
        },
      ] as const,
    [submit, submitLoading]
  );
}

const schedulingTaskWorkflowUpdate: MutationUpdaterFn<
  SchedulingTaskAndAvailabilityWorkflowSubmitMutation
> = (cache, { data }) => {
  let newSchedulingRequests: SchedulingRequestForSchedulingRequestCardFragment[] =
    [];

  if (data?.schedulingTaskAndAvailabilityWorkflows?.schedulingRequest) {
    const newRequest =
      data.schedulingTaskAndAvailabilityWorkflows.schedulingRequest;

    newSchedulingRequests = [newRequest];
  }

  if (data?.schedulingTaskAndAvailabilityWorkflows?.reschedulingRequests) {
    newSchedulingRequests = [
      ...newSchedulingRequests,
      ...data.schedulingTaskAndAvailabilityWorkflows.reschedulingRequests,
    ];
  }

  if (newSchedulingRequests.length) {
    const firstRequest = newSchedulingRequests[0];

    cache.modify({
      id: cache.identify({
        __typename: "Guide",
        id: firstRequest.guideId,
      }),
      fields: {
        schedulingRequests(existingSchedulingRequests = []) {
          return [...existingSchedulingRequests, ...newSchedulingRequests];
        },
      },
    });

    cache.modify({
      id: cache.identify({
        __typename: "Organization",
        id: firstRequest.organizationId,
      }),
      fields: {
        schedulingRequests(existingSchedulingRequests = []) {
          return [...existingSchedulingRequests, ...newSchedulingRequests];
        },
      },
    });
  }
};
