import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBriefcase } from "@resource/atlas/icons";
import clsx from "clsx";
import { ReactNode } from "react";

interface RequestInfoRowProps {
  icon?: ReactNode;
  label?: string;
  children: ReactNode;
  action?: ReactNode;
  className?: string;
  childContainerClassName?: string;
  multiline?: boolean;
}

function RequestInfoRow({
  // TODO: remove default icon - this should be required
  icon = <Icon content={atlasBriefcase} />,
  label,
  multiline = false,
  children: rawChildren,
  action,
  className,
  childContainerClassName,
}: RequestInfoRowProps) {
  const children =
    typeof rawChildren === "string" ? (
      <p className="text-body-sm">{rawChildren}</p>
    ) : (
      rawChildren
    );

  return (
    <div
      className={clsx(
        "text-body-sm flex justify-start text-dark gap-2",
        multiline ? "h-full items-start" : "items-center",
        className
      )}
    >
      <div
        className={clsx(
          "flex shrink-0 gap-1 items-center text-subtle",
          multiline ? "pt-[.125rem]" : "items-center",
          label ? "w-[6rem]" : "w-[1.5rem]"
        )}
      >
        {icon}
        {label ? ` ${label}` : ""}
      </div>
      <div
        className={clsx(
          "flex grow min-w-0",
          {
            "pt-[.125rem]": multiline,
          },
          childContainerClassName
        )}
      >
        {children}
      </div>
      {action}
    </div>
  );
}

export default RequestInfoRow;
