/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable class-methods-use-this, @typescript-eslint/no-use-before-define, max-classes-per-file, no-underscore-dangle */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $getPreviousSelection,
  $getRoot,
  $getSelection,
  $isNodeSelection,
  $isRootNode,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  createCommand,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  ElementFormatType,
  KEY_BACKSPACE_COMMAND,
  LexicalCommand,
  LexicalEditor,
  LexicalNode,
  NodeKey,
} from "lexical";
import { ReactElement, useEffect, useMemo } from "react";
import { ZodObject, ZodUnion } from "zod";

import { useEvent } from "../../../../__utils/react";
import type { PartialBy } from "../../../../__utils/types";
import type {
  InsertOptionData,
  InsertOptionFactory,
} from "../../insert-options";
import { $insertOrReplaceBlockAtRoot } from "../../misc";
import type { AtlasContentEditorModule } from "../../modules";
import { createModule } from "../../modules";
import { useSelection } from "../../selection-context";
import { RichBlockFrame } from "./frame";
import {
  CreateNodeName,
  InsertCommandName,
  IsNodeName,
  ModuleName,
  NodeClassName,
  PluginName,
  toCreateNodeName,
  toInsertCommandName,
  toIsNodeName,
  toModuleName,
  toNodeClassName,
  toPluginName,
} from "./naming-utils";
import type {
  CreateNode,
  IsNode,
  PluginComponent,
  RenderComponent,
  RichBlockNodeClass,
  SerializedRichBlockNode,
} from "./types";

// rich block node base
// --------------------

const DOM_TYPE_ATTRIBUTE = "data-rich-block-type";
const DOM_DATA_ATTRIBUTE = "data-rich-block-data";

export class RichBlockNodeBase<Data> extends DecoratorBlockNode {
  __data: Data;

  constructor(
    defaultData: Data,
    data?: Partial<Data>,
    format?: ElementFormatType,
    key?: NodeKey
  ) {
    super(format, key);
    this.__data = { ...defaultData, ...data };
  }

  updateDOM() {
    return false as const;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("div");
    element.setAttribute(DOM_TYPE_ATTRIBUTE, this.getType());
    element.setAttribute(DOM_DATA_ATTRIBUTE, JSON.stringify(this.__data));
    return { element };
  }

  isInline(): false {
    return false;
  }
}

// create content editor rich block
// --------------------------------

type InsertRichBlockOptionData = PartialBy<
  InsertOptionData,
  "key" | "onTrigger"
>;

type CreateContentEditorRichBlockInput<Name extends string, Data> = {
  name: Name;
  defaultData: Data;
  RenderComponent: () => Promise<RenderComponent<Data>>;
  getTextContent?: (data: Data) => string;
  insertOption?: InsertRichBlockOptionData;
  useInsertOption?: (
    context: Parameters<InsertOptionFactory>[0],
    richBlockUtils: {
      Node: RichBlockNodeClass<Name, Data>;
      $createNode: CreateNode<Name, Data>;
      $isNode: IsNode<Name, Data>;
      INSERT_COMMAND: LexicalCommand<Data>;
      insert: (data: Data) => void;
    }
  ) => InsertRichBlockOptionData;
  importDOM?: () => DOMConversionMap;
  // TODO: this probably shouldn't be necessary
  useRender?: () => ReactElement | undefined;
  zodSchema?: ZodObject<any> | ZodUnion<[ZodObject<any>, ZodObject<any>]>;
};

type CreateContentEditorRichBlockOutput<Name extends string, Data> = {
  // module
  [K in ModuleName<Name>]: AtlasContentEditorModule<Name>;
} & {
  // Node
  [K in NodeClassName<Name>]: RichBlockNodeClass<Name, Data>;
} & {
  // Plugin
  [K in PluginName<Name>]: PluginComponent;
} & {
  // $createNode
  [K in CreateNodeName<Name>]: CreateNode<Name, Data>;
} & {
  // $isNode
  [K in IsNodeName<Name>]: IsNode<Name, Data>;
} & {
  // INSERT_COMMAND
  [K in InsertCommandName<Name>]: LexicalCommand<Data>;
};

export function createRichBlock<Name extends string, Data>({
  name,
  defaultData,
  RenderComponent,
  getTextContent,
  insertOption,
  useInsertOption,
  useRender,
  importDOM,
  zodSchema,
}: CreateContentEditorRichBlockInput<
  Name,
  Data
>): CreateContentEditorRichBlockOutput<Name, Data> {
  // lexical helpers

  function $createNode(data?: Data) {
    return new Node(data);
  }

  function $isNode(node: Node | LexicalNode | null | undefined): node is Node {
    return node instanceof Node;
  }

  // lexical command

  const INSERT_COMMAND: LexicalCommand<Data | undefined> = createCommand(
    toInsertCommandName(name)
  );

  // lexical node

  const conversionMap = importDOM?.() || null;

  class Node extends RichBlockNodeBase<Data> {
    static getType() {
      return name;
    }

    static clone(node: Node): Node {
      return new Node(node.__data, node.__format, node.__key);
    }

    static importDOM(): DOMConversionMap {
      return {
        div: (domNode: HTMLElement) => ({
          conversion: (): null | DOMConversionOutput => {
            const type = domNode.getAttribute(DOM_TYPE_ATTRIBUTE);
            const data = domNode.getAttribute(DOM_DATA_ATTRIBUTE);
            if (!type || !data) return null;
            if (type !== name) return null;

            return { node: $createNode(JSON.parse(data)) };
          },
          priority: 0,
        }),
        ...conversionMap,
      };
    }

    constructor(data?: Data, format?: ElementFormatType, key?: NodeKey) {
      let parsedData: Data | undefined = data;

      if (zodSchema && data) {
        const zodResult = zodSchema.safeParse(data);

        if (!zodResult.success) {
          parsedData = undefined;
        }
      }

      super(defaultData, parsedData, format, key);
    }

    static importJSON(
      serializedNode: SerializedRichBlockNode<Name, Data>
    ): Node {
      const node = $createNode(serializedNode.data);
      node.setFormat(serializedNode.format);
      return node;
    }

    exportJSON(): SerializedRichBlockNode<Name, Data> {
      return {
        ...super.exportJSON(),
        type: name,
        version: 1,
        data: this.__data,
      };
    }

    getTextContent(
      _includeInert?: boolean | undefined,
      _includeDirectionless?: false | undefined
    ) {
      return getTextContent?.(this.__data) ?? "";
    }

    decorate(_editor: LexicalEditor) {
      return (
        <RichBlockFrame<Name, Data>
          data={this.__data}
          nodeKey={this.getKey()}
          $isNode={$isNode as any}
          RenderComponent={RenderComponent}
        />
      );
    }
  }
  Object.defineProperty(Node, "name", { value: toNodeClassName(name) });

  // lexical plugin

  function Plugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
      if (!editor.hasNodes([Node])) {
        throw new Error(
          `${toPluginName(name)}: ${toNodeClassName(
            name
          )} is not registered on the editor`
        );
      }

      return mergeRegister(
        editor.registerCommand(
          INSERT_COMMAND,
          (data) => {
            // TODO: this is a hack, but there's probably a Lexical bug worth reporting
            if (!$getSelection())
              $setSelection($getPreviousSelection()?.clone() ?? null);

            const node = $createNode(data);

            $insertOrReplaceBlockAtRoot(node);

            node.selectNext();

            return true;
          },
          COMMAND_PRIORITY_EDITOR
        ),
        // TODO: this shouldn't be necessary
        // ensure it's always top-level
        editor.registerNodeTransform(Node, (node) => {
          const parentNode = node.getParent();
          if (!$isRootNode(parentNode)) parentNode?.replace(node);

          const root = $getRoot();
          const children = root.getChildren();

          // hack around for this bug: https://github.com/facebook/lexical/issues/4139
          if (children[0] === node) {
            const paragraphNode = $createParagraphNode();
            node.insertBefore(paragraphNode);
          }

          if (children[children.length - 1] === node) {
            const paragraphNode = $createParagraphNode();
            node.insertAfter(paragraphNode);
          }
        }),
        editor.registerCommand(
          KEY_BACKSPACE_COMMAND,
          () => {
            const selection = $getSelection();

            if ($isNodeSelection(selection)) {
              const nodes = selection.getNodes();

              const firstNode = nodes[0];

              if (firstNode && firstNode.getType() === name) {
                const parent = firstNode.getParent();

                const allChildren = parent?.getChildren() ?? [];

                const index = allChildren.indexOf(firstNode);

                firstNode.remove();

                /*
                  I'm not sure why this is necessary to handle the selection
                  Tried firstNode.selectPrevious() prior to calling firstNode.remove()
                    - When there were two nodes in a row, it would delete both instead of deleting one and selecting the other
                  Tried firstNode.selectPrevious() after calling firstNode.remove()
                    - Fails because firstNode no longer has a parent
                  Solution here is to manually set the selection to previous after removing the node by persisting the index of previous
                */
                $setSelection(allChildren[index - 1]?.selectNext() ?? null);

                return true;
              }
            }

            return false;
          },
          COMMAND_PRIORITY_HIGH
        )
      );
    }, [editor]);

    const toRender = useRender?.();

    return toRender ? (
      <div style={{ position: "fixed" }}>{toRender}</div>
    ) : null;
  }

  // module

  const module = createModule(name, {
    createLinkedOptions() {
      const hasInsertOption = insertOption || useInsertOption;

      if (insertOption && useInsertOption)
        throw new Error('Cannot use both "insertOption" and "useInsertOption"');

      return {
        insertOptions: hasInsertOption
          ? [
              function useOption(context) {
                const [editor] = useLexicalComposerContext();
                const { isRangeSelection } = useSelection();

                const insert = useEvent((data: Data) => {
                  editor.dispatchCommand(INSERT_COMMAND, data);
                });

                const factoryResult = useInsertOption?.(context, {
                  Node,
                  $createNode,
                  $isNode,
                  INSERT_COMMAND,
                  insert,
                });

                return useMemo(() => {
                  const option = (insertOption ??
                    factoryResult) as InsertRichBlockOptionData;

                  return {
                    key: `${name}-rich-block`,
                    disabled: !isRangeSelection,
                    ...option,
                    onTrigger() {
                      if (option.onTrigger) option.onTrigger();
                      else editor.dispatchCommand(INSERT_COMMAND, defaultData);
                    },
                  };
                }, [editor, factoryResult, isRangeSelection]);
              },
            ]
          : undefined,
      };
    },
    declareModule() {
      return { nodes: [Node], plugins: [<Plugin />] };
    },
  });

  // output value

  return {
    [toModuleName(name)]: module,
    [toNodeClassName(name)]: Node,
    [toPluginName(name)]: Plugin,
    [toCreateNodeName(name)]: $createNode,
    [toIsNodeName(name)]: $isNode,
    [toInsertCommandName(name)]: INSERT_COMMAND,
  } as CreateContentEditorRichBlockOutput<Name, Data>;
}
