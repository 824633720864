import { zodResolver } from "@hookform/resolvers/zod";
import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import {
  PostTemplateForManagingFragment,
  PostTemplateType,
} from "generated/graphql-codegen/graphql";
import { SerializedEditorState } from "lexical";
import { LexicalSchema } from "modules/lexical/shared/utils/types";
import { useCallback, useEffect, useMemo } from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { getLexicalHasContent } from "shared/utils/lexical";
import { z } from "zod";

import { PostTemplateSettingsDialogFormData } from "./PostTemplateSettingsDialogView";

const UserValidationMessages = {
  contentRequired: "Content is required",
  titleRequired: "Title is required",
};

type UseContentFormProps = {
  postTemplate?: PostTemplateForManagingFragment;
  skipDirtyCheck?: boolean;
};

/** On mount title data may be optional */
export const PostTemplateContentDialogFormSchema = z.object({
  data: LexicalSchema,
  titleData: LexicalSchema.optional(),
});

/** We must have data to submit */
export const StagedPostTemplateContentDialogFormSchema =
  PostTemplateContentDialogFormSchema.extend({
    titleData: LexicalSchema,
  })
    .refine(
      (data) => {
        return getLexicalHasContent(JSON.stringify(data.data));
      },
      {
        message: UserValidationMessages.contentRequired,
      }
    )
    .refine(
      (data) => {
        return getLexicalHasContent(JSON.stringify(data.titleData));
      },
      {
        message: UserValidationMessages.titleRequired,
      }
    );

export type PostTemplateContentDialogFormData = {
  data: AtlasContentEditorSerializedState;
  titleData?: AtlasContentEditorSerializedState;
};

export function useEditPostTemplateContentForm({
  postTemplate,
  skipDirtyCheck,
}: UseContentFormProps = {}) {
  const defaultValues:
    | DefaultValues<PostTemplateContentDialogFormData>
    | undefined = useMemo(() => {
    if (postTemplate) {
      return {
        title: postTemplate.title || "",
        titleData: postTemplate.titleData,
        data: postTemplate.data as SerializedEditorState,
      };
    }

    return undefined;
  }, [postTemplate]);
  const form = useForm<PostTemplateContentDialogFormData>({
    defaultValues,
    resolver: zodResolver(StagedPostTemplateContentDialogFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  /** Update form values when selected template changes */
  useEffect(() => {
    if (postTemplate) {
      form.reset(defaultValues);
    }
  }, [postTemplate, defaultValues, form]);

  useEffect(() => {
    // Trigger validation on mount
    form.trigger();
  }, [form]);

  const { formState } = form;

  const disabledTooltipContent = useDisabledTooltipContentForForm(formState, {
    skipDirtyCheck,
  });

  /** Wrapper around onChange to properly handle dirtying and validation */
  const onChange = useCallback(
    (...params: Parameters<typeof form.setValue>) => {
      const [name, value, opts] = params;

      form.setValue(name, value, {
        shouldDirty: true,
        ...opts,
      });
      // Need to manually trigger validation because of a bug with zodResolver and superRefine
      // https://github.com/react-hook-form/resolvers/issues/661
      form.trigger();
    },
    [form]
  );

  return useMemo(
    () => ({
      form,
      showConfirmation: formState.isDirty && !formState.isSubmitted,
      disabledTooltipContent,
      onChange,
    }),
    [
      form,
      formState.isDirty,
      formState.isSubmitted,
      disabledTooltipContent,
      onChange,
    ]
  );
}

export type EditPostTemplateContentFormState = ReturnType<
  typeof useEditPostTemplateContentForm
>;

type UseSettingFormProps = {
  postTemplate?: PostTemplateForManagingFragment;
  isDuplicate?: boolean;
  defaultType?: PostTemplateType;
};

export function useSettingsForm({
  postTemplate,
  isDuplicate,
  defaultType,
}: UseSettingFormProps = {}) {
  const defaultValues:
    | DefaultValues<PostTemplateSettingsDialogFormData>
    | undefined = useMemo(() => {
    if (postTemplate) {
      return {
        departmentId: postTemplate.department?.id || "",
        name: `${postTemplate.name}${isDuplicate ? " (copy)" : ""}`,
        type: postTemplate.type,
      };
    }

    return {
      type: defaultType || PostTemplateType.candidate_messaging,
    };
  }, [postTemplate, isDuplicate, defaultType]);
  const useFormReturn = useForm<PostTemplateSettingsDialogFormData>({
    defaultValues,
    mode: "onChange",
  });

  /** Update form values when selected template changes */
  useEffect(() => {
    if (postTemplate) {
      useFormReturn.reset(defaultValues);
    }
  }, [postTemplate, useFormReturn, defaultValues]);
  useEffect(() => {
    if (defaultType) {
      useFormReturn.reset(defaultValues);
    }
  }, [defaultType, useFormReturn, defaultValues]);

  const { formState } = useFormReturn;

  return {
    useFormReturn,
    showConfirmation: formState.isDirty && !formState.isSubmitted,
  };
}
