import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { AtlasButtonProps } from "@resource/atlas/button/types";
import { atlasPersonPlus, atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { GroupLabel } from "@resource/atlas/menu/Menu";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { AtlasPopoverState, Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import TextField from "@resource/atlas/textfield/TextField";
import { ColorizedPoolIcon } from "client/components/interview-requirements/__components/ColorizedPoolIcon";
import { gql } from "generated/graphql-codegen";
import {
  InterviewerForUserTagSelectionFragment,
  InterviewerPoolForTagPickerFragment,
} from "generated/graphql-codegen/graphql";
import { useEffect, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

type User = InterviewerForUserTagSelectionFragment;

gql(`
  fragment InterviewerPoolForTagPicker on InterviewerPool {
    id
    name
    qualifiedUserCount
    traineeUserCount
    shadowsRequiredForTrainees
    reverseShadowsRequiredForTrainees
    loadLimits {
      day {
        enabled
        limit
      }
      month {
        enabled
        limit
      }
      week {
        enabled
        limit
      }
      overall {
        enabled
        limit
      }
    }
    ignoreAccountLoadLimits
  }
`);

gql(`
fragment InterviewerForUserTagSelection on UserMembership {
  id
  firstName
  lastName
  name
  imageUrl
  email
  googleCalendarId
  user {
    id
    timezone
  }
  upcomingInterviewsPerDay {
    date
    count
  }
  workingHours {
    startTime {
      hour
      minute
    }
    endTime {
      hour
      minute
    }
    isWorkingDay
  }
  maxInterviewLoadPerDay
  maxInterviewLoadPerWeek
  interviewWhitelist
  traineeInfo(singlePoolId: $singlePoolId) {
    id
    isQualified
    shadowsCompleted
    reverseShadowsCompleted
    interviewerPoolId
  }
  dailyInterviewLoadForPool(poolId: $singlePoolId) {
    count
    date
  }
}
`);

const FETCH_USERS_FOR_USER_AND_TAG_SELECT_QUERY = gql(`
  query FetchUsersForUserAndTagSelectQuery($search: String, $limit: Int, $poolLimit: Int, $singlePoolId: ID = null) {
    userMemberships(search: $search, limit: $limit) {
      ...InterviewerForUserTagSelection
    }
    currentOrganization {
      id
      interviewerPools(search: $search, limit: $poolLimit) {
        ...InterviewerPoolForTagPicker
      }
    }
  }
`);

export type UserOrPoolSelectProps = {
  onSelectUser: (user: User) => void;
  onSelectPool: (pool: InterviewerPoolForTagPickerFragment) => void;
  variant?: AtlasButtonProps["variant"];
  defaultOpen?: boolean;
  label?: string;
  excludeUserIds?: string[];
};

export function UserOrPoolSelect({
  onSelectUser,
  onSelectPool,
  label,
  defaultOpen = false,
  variant = "primary",
  excludeUserIds,
}: UserOrPoolSelectProps) {
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");

  const popoverState = usePopoverState({
    defaultOpen,
  });

  const { data, loading } = useQuery(
    FETCH_USERS_FOR_USER_AND_TAG_SELECT_QUERY,
    {
      variables: {
        search: debouncedTerm,
        limit: 25,
        poolLimit: 3,
      },
    }
  );

  const users = useMemo(() => {
    const allUsers = data?.userMemberships ?? [];
    return excludeUserIds
      ? allUsers.filter((user) => !excludeUserIds.includes(user.id))
      : allUsers;
  }, [data, excludeUserIds]);

  const pools = useMemo(
    () => data?.currentOrganization?.interviewerPools ?? [],
    [data]
  );

  return (
    <UserOrPoolSelectDisplay
      users={users}
      pools={pools}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onSelectUser={onSelectUser}
      onSelectPool={onSelectPool}
      label={label}
      popoverState={popoverState}
      variant={variant}
      UserSelectOptionComponent={DefaultUserSelectOption}
    />
  );
}

export type InterviewerWithConflictInfo =
  InterviewerForUserTagSelectionFragment & {
    interviewsThisWeek?: number;
    interviewsToday?: number;
    outsideWorkingHours?: boolean;
    hasConflict?: boolean;
    hasFlexibleConflict?: boolean;
    loadingCalendar?: boolean;
    trainingStatusForSelectedPool?:
      | InterviewerForUserTagSelectionFragment["traineeInfo"][0]
      | null;
    trainingRequirementsForSelectedPool?: Pick<
      InterviewerPoolForTagPickerFragment,
      "shadowsRequiredForTrainees" | "reverseShadowsRequiredForTrainees"
    > | null;
  };

export type UserSelectOptionProps = {
  user: InterviewerWithConflictInfo;
  onClick: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  pool?: InterviewerPoolForTagPickerFragment;
};

function DefaultUserSelectOption({ user, onClick }: UserSelectOptionProps) {
  return (
    <OptionItem onClick={onClick} size="compact">
      <div className="flex gap-2 items-center">
        <Avatar
          size="xs"
          image={user.imageUrl}
          name={user.name || user.email}
        />
        <div title={user.name || user.email}>{user.name || user.email}</div>
      </div>
    </OptionItem>
  );
}

export type UserOrPoolSelectDisplayProps = {
  users: User[];
  pools: InterviewerPoolForTagPickerFragment[];
  loading: boolean;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  onSelectUser: (user: User) => void;
  onSelectPool: (pool: InterviewerPoolForTagPickerFragment) => void;
  variant: AtlasButtonProps["variant"];
  popoverState: AtlasPopoverState;
  label?: string;
  pool?: InterviewerPoolForTagPickerFragment;
  UserSelectOptionComponent: React.ComponentType<UserSelectOptionProps>;
};
export function UserOrPoolSelectDisplay({
  users,
  pools,
  loading,
  searchTerm,
  setSearchTerm,
  onSelectUser,
  onSelectPool,
  variant,
  popoverState,
  label,
  pool,
  UserSelectOptionComponent,
}: UserOrPoolSelectDisplayProps) {
  const options = useMemo(() => {
    const optionsResult = [];

    if (pools.length > 0) {
      optionsResult.push(
        <GroupLabel key="pools">
          {formatEntity("training path", { plural: true, capitalize: true })}
        </GroupLabel>,
        ...pools.map((pool) => (
          <OptionItem
            key={pool.id}
            size="compact"
            onClick={() => {
              onSelectPool(pool);
              popoverState.hide();
            }}
          >
            <div className="flex gap-2 items-center">
              <ColorizedPoolIcon variant="large" />
              <div className="flex flex-col">
                <div>{pool.name}</div>
                {(pool.qualifiedUserCount > 0 || pool.traineeUserCount > 0) && (
                  <div className="text-subtle text-body-sm space-x-2">
                    <span>
                      {pool.qualifiedUserCount > 0
                        ? `${pool.qualifiedUserCount} interviewers`
                        : ""}
                    </span>
                    <span>
                      {pool.traineeUserCount > 0
                        ? `${pool.traineeUserCount} training`
                        : ""}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </OptionItem>
        ))
      );
    }

    if (users.length > 0) {
      optionsResult.push(
        <GroupLabel key="users">Interviewers</GroupLabel>,
        ...users.map((user) => (
          <UserSelectOptionComponent
            key={user.id}
            user={user}
            onClick={() => {
              onSelectUser(user);
              popoverState.hide();
            }}
            pool={pool}
          />
        ))
      );
    }

    return optionsResult;
  }, [
    pools,
    users,
    onSelectPool,
    popoverState,
    UserSelectOptionComponent,
    pool,
    onSelectUser,
  ]);

  // clear search term on popover state close
  useEffect(() => {
    if (!popoverState.open) {
      setSearchTerm("");
    }
  }, [popoverState.open, setSearchTerm]);

  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger>
        <Button
          variant={variant}
          icon={atlasPersonPlus}
          className="w-full"
          isGhost={false}
        >
          {label ?? `Add ${formatEntity("interviewer slot")}`}
        </Button>
      </Popover.Trigger>
      <Popover.Content hasPadding={false} className="min-w-[20rem]">
        <TextField
          className="m-2"
          size="small"
          value={searchTerm}
          autoFocus
          icon={atlasSearch}
          placeholder="Search"
          aria-label="Search"
          onChange={setSearchTerm}
          isClearable
        />
        <div className="p-[.5rem] pt-0">
          {loading && (
            <>
              <OptionSeparator />
              <div className="flex justify-center">
                <LoadingIndicator size="small" />
              </div>
            </>
          )}
          {!loading && options.length > 0 && <>{options}</>}
          {!loading && options.length === 0 && (
            <>
              <OptionSeparator />
              <p className="px-5 py-2 text-body-md">No results found</p>
            </>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
