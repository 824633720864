import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { BackButton } from "client/components/generic/layout/BackButton";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { UpdateOrCancelReportingReasonsViewData } from "client/components/scheduled-interviews/ReportingReasons/wrappers/UpdateOrCancelReportingReasonsView";
import { InterviewsPanelReviewFormData } from "client/components/scheduling-review/utils/types";
import { usePanelReviewPageFormState } from "client/scheduler/core/components/review/hooks/usePanelReviewPageFormState";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import {
  CurrentUserMembershipForReviewPageSchedulingDefaultsFragment,
  ExistingScheduledInterviewForSchedulerReviewPageFragment,
  GuideForSchedulerReviewPageFragment,
  InterviewForSchedulerReviewPageFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";

import { useSchedulerReportingReasonsState } from "../reporting-reasons/hooks/useSchedulerReportingReasonsState";
import { SchedulerReportingReasonsDialog } from "../reporting-reasons/SchedulerReportingReasons";
import { useSchedulerStateForReview } from "./hooks/useReviewPageData";
import { PanelScheduleSummaryDisplay } from "./PanelScheduleSummaryDisplay";
import { PanelSettingsAndEventDetails } from "./PanelSettingsAndEventDetails";
import { useReviewPageQuery } from "./useReviewPageQuery";

export type SchedulerReviewPageProps = {
  onBack: () => void;
  onCancel: () => void;
  onSave: (props: {
    interviewsPanelReviewFormData: InterviewsPanelReviewFormData;
    reportingData?: UpdateOrCancelReportingReasonsViewData;
  }) => void;
  saveLoading: boolean;
};

/** Review page for a panel of interviews */
export function SchedulerReviewPage(props: SchedulerReviewPageProps) {
  const {
    guideId,
    existingScheduledInterviewIds,
    isCreatingInterviews,
    scheduledInterviewInterviewIds,
  } = useSchedulerStateForReview();

  const {
    guide,
    scheduledInterviews,
    currentUserMembership,
    interviews,
    loading,
    error,
  } = useReviewPageQuery({
    guideId,
    existingScheduledInterviewIds,
    scheduledInterviewInterviewIds,
    isCreatingInterviews,
  });

  if (loading) {
    return <DialogLoading />;
  }

  if (!guide || !scheduledInterviews || !currentUserMembership || !interviews) {
    return <DialogError errorCode="500" error={error} />;
  }

  return (
    <SchedulerReviewPageDisplay
      {...props}
      guide={guide}
      scheduledInterviews={scheduledInterviews}
      currentUserMembership={currentUserMembership}
      interviews={interviews}
    />
  );
}

type SchedulerReviewPageDisplayProps = SchedulerReviewPageProps & {
  guide: GuideForSchedulerReviewPageFragment;
  scheduledInterviews: ExistingScheduledInterviewForSchedulerReviewPageFragment[];
  currentUserMembership: CurrentUserMembershipForReviewPageSchedulingDefaultsFragment;
  interviews: InterviewForSchedulerReviewPageFragment[];
};

/** Display for the review page after data has been fetched */
function SchedulerReviewPageDisplay({
  guide,
  scheduledInterviews,
  currentUserMembership,
  interviews,
  onBack,
  onCancel,
  onSave,
  saveLoading,
}: SchedulerReviewPageDisplayProps) {
  const state = usePanelReviewPageFormState({
    guide,
    scheduledInterviews,
    currentUserMembership,
    interviews,
  });
  const schedulerReportingReasonsState = useSchedulerReportingReasonsState();
  const schedulerReportingReasonsStore = useDialogStore();

  const { form, disabledTooltipContent } = state;
  const sendNotifications = form.watch("sendNotifications");

  const onCompleted = useCallback(
    (reportingData?: UpdateOrCancelReportingReasonsViewData) => {
      onSave({
        interviewsPanelReviewFormData: form.getValues(),
        reportingData,
      });
    },
    [form, onSave]
  );

  const onSubmit = useCallback(() => {
    if (schedulerReportingReasonsState.showReportingPrompt) {
      schedulerReportingReasonsStore.show();
    } else {
      onCompleted();
    }
  }, [
    onCompleted,
    schedulerReportingReasonsState.showReportingPrompt,
    schedulerReportingReasonsStore,
  ]);

  return (
    <>
      <View
        content={{
          className: "p-0",
        }}
        header={{
          title: "Send interviewer invites",
          leftActions: <BackButton onClick={onBack} />,
        }}
        footer={{
          rightActions: (
            <FormRightActions
              isLoading={saveLoading}
              cancel={{ onClick: onCancel }}
              save={{
                onClick: onSubmit,
                children: sendNotifications
                  ? "Send invites"
                  : "Schedule interviews",
              }}
              disabledTooltipContent={disabledTooltipContent}
            />
          ),
        }}
      >
        <div className="flex flex-row w-full justify-center h-full">
          <div className="w-1/3 flex justify-end overflow-y-scroll">
            <PanelScheduleSummaryDisplay />
          </div>
          <div className="w-2/3 overflow-y-scroll">
            <PanelSettingsAndEventDetails
              guide={guide}
              scheduledInterviews={scheduledInterviews}
              currentUserMembership={currentUserMembership}
              state={state}
            />
          </div>
        </div>
      </View>
      <SchedulerReportingReasonsDialog
        store={schedulerReportingReasonsStore}
        state={schedulerReportingReasonsState}
        scheduledInterviews={scheduledInterviews}
        saveLoading={saveLoading}
        onCompleted={onCompleted}
      />
    </>
  );
}
