/**
 * Options are passed parameters to the calendar that customizes its beahvior.
 * There's mapping logic to provide the defaults when not passed, which livesin `utils/mapping.ts`.
 * Mapping logic is called in the Context provider right now.
 * Everything should be accessed via hooks so that the internals can easily change.
 */
import { useMemo } from "react";

import { useCalendarContext } from "../utils/context";
import {
  CalendarsGrouping,
  GetCalendarDisplayInfo,
  GetEventDetailsFooter,
} from "../utils/types";

export type CalendarOptions = {
  /** @default: 15 */
  minInterval: number;
  /** @default: All calendar IDs */
  dayViewCalendarIds: string[];
  /** @default null */
  calendarsGrouping: CalendarsGrouping | null;
  /**
   * Display info for a calendar
   */
  getCalendarDisplayInfo?: GetCalendarDisplayInfo;
  /** Ordered list of group priority for Z index display on the calendar. Priority goes from left to right. */
  layerPriority?: string[];
  /** Pass footer for event details popover to provide additional functionality */
  getEventDetailsFooter?: GetEventDetailsFooter;
};

export type CalendarOptionsInput = Partial<CalendarOptions>;

export function useCalendarOptions() {
  const {
    minInterval,
    dayViewCalendarIds,
    calendarsGrouping,
    getCalendarDisplayInfo,
    layerPriority,
    getEventDetailsFooter,
  } = useCalendarContext();

  return useMemo(
    () => ({
      minInterval,
      dayViewCalendarIds,
      calendarsGrouping,
      getCalendarDisplayInfo,
      layerPriority,
      getEventDetailsFooter,
    }),
    [
      calendarsGrouping,
      dayViewCalendarIds,
      getCalendarDisplayInfo,
      getEventDetailsFooter,
      layerPriority,
      minInterval,
    ]
  );
}
