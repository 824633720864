export function OutlookConditional({ content }: { content: string }) {
  // Work around for inserting an outlook conditional without any wrapper. This will result in a div before and after the
  // html comment block that will be removed by post-processing.
  return (
    <div
      id="gc-html-comment"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `</div><!--[if mso | IE]>${content}<![endif]--><div id="gc-html-comment">`,
      }}
    />
  );
}
