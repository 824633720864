import { Button } from "@resource/atlas/button/Button";
import { View, ViewProps } from "@resource/atlas/view/View";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import clsx from "clsx";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { SelfScheduleRequestDetails } from "./SelfScheduleRequestDetails";

export function NoTimesAvailableView({
  state,
  isMobile,
  onCancel,
  ...props
}: {
  state: SelfScheduleSubmissionState;
  isMobile?: boolean;
  onCancel?: () => void;
} & ViewProps) {
  return (
    <View
      {...props}
      header={{
        title: "Select date and time",
        ...props.header,
      }}
      footer={{
        rightActions: onCancel ? (
          <Button variant="default" isGhost onClick={onCancel}>
            Cancel
          </Button>
        ) : undefined,
        ...props.footer,
      }}
    >
      <div
        className={clsx("flex w-full h-full divide-gray-border", {
          "flex-row divide-x": !isMobile,
          "flex-col divide-y": isMobile,
        })}
      >
        <div
          className={clsx("flex", {
            "w-1/3": !isMobile,
            "w-full": isMobile,
          })}
        >
          <SelfScheduleRequestDetails state={state} className="p-6" />
        </div>
        <div
          className={clsx("flex flex-col gap-4 p-6 h-full", {
            "w-2/3": !isMobile,
            "w-full": isMobile,
          })}
        >
          <div className="text-h4 shrink-0">Select date and time</div>
          <EmptyStateWrapper>No times available. </EmptyStateWrapper>
        </div>
      </div>
    </View>
  );
}
