import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useSchedulingCompletionPromptsProps } from "client/components/scheduling-completion/__deprecated/SchedulingCompletionPromptsDialog";
import { InterviewerForScheduledInterviewCardFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

export function useCancelInterviewsConfirmationDialogProps({
  interviews,
}: {
  interviews: {
    id: string;
    isCancelled: boolean;
    createdByGuide: boolean;
    schedulingRequestId?: string | null;
    interviewers: InterviewerForScheduledInterviewCardFragment[];
  }[];
}) {
  const dialogStore = useDialogStore();

  const schedulingCompletionPromptsProps = useSchedulingCompletionPromptsProps({
    schedulingRequestId: interviews[0]?.schedulingRequestId ?? "",
  });

  const cancellableInterviews = useMemo(() => {
    return interviews.filter((x) => !x.isCancelled && x.createdByGuide);
  }, [interviews]);

  return useMemo(
    () => ({
      dialogProps: {
        store: dialogStore,
        onCompleted: schedulingCompletionPromptsProps.onCancelCompleted,
        scheduledInterviewIds: cancellableInterviews.map((x) => x.id),
        interviewers: cancellableInterviews.flatMap((x) => x.interviewers),
      },
      cancellableInterviews,
      onClick: () =>
        cancellableInterviews.length > 0 ? dialogStore.show() : null,
      completionPromptsProps: {
        ...schedulingCompletionPromptsProps.props,
        isCancellationConfirmation: true,
      },
    }),
    [cancellableInterviews, dialogStore, schedulingCompletionPromptsProps]
  );
}
