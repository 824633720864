/* eslint-disable import/prefer-default-export */
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin as LexicalRichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import clsx from "clsx";
import { useEffect } from "react";

import { setRef, useId } from "../../../../../__utils/react";
import { themeContainerClass } from "../../../../theme";
import { el } from "../../../component-utils";
import { useInputRef } from "../../../misc";
import { useTmpPlaceholderTextOverrideContext } from "../../../tmp-placeholder-context";

export function RichTextPlugin() {
  const inputRef = useInputRef();

  const { placeholder } = useTmpPlaceholderTextOverrideContext();

  // TODO: workaround for the lack of `ref` prop in `ContentEditable`
  const inputId = `content-editor-input-${useId()}`;
  useEffect(() => {
    const inputElement =
      (document.getElementById(inputId) as HTMLDivElement) ?? null;
    setRef(inputRef, inputElement);
    return () => setRef(inputRef, null);
  }, [inputId, inputRef]);

  return (
    <>
      <LexicalRichTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={
          <ContentEditable
            id={inputId}
            className={clsx(el`input`, themeContainerClass)}
          />
        }
        placeholder={
          <div className={el`tmp-placeholder`}>
            <p>{placeholder ?? "Type / for commands"}</p>
          </div>
        }
      />
    </>
  );
}
