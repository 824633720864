import { ViewsPopover } from "client/components/display/popovers/views-popover";
import { gql } from "generated/graphql-codegen";
import { GuideMetricsGuideFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";

gql(`
  fragment GuideMetricsGuide on Guide {
    views {
      id
      eventTime
    }
  }
`);

interface Props {
  guide: GuideMetricsGuideFragment;
  className?: string;
}

function GuideMetricsButton({ guide, className }: Props) {
  const { views } = guide;
  const currentDate = DateTime.now();
  const oneYearAgo = currentDate.minus({ years: 1 });

  return views.length > 0 ? (
    <ViewsPopover
      title="Engagement metrics"
      startDate={oneYearAgo.toISO()}
      views={views.map(({ eventTime }) => eventTime)}
      variant="compact"
      className={className}
    />
  ) : null;
}

export default GuideMetricsButton;
