import { z } from "zod";

export const InterviewerSlotTypeSchema = z.enum([
  "PERSON",
  "POOL",
  "SMART_MATCH",
]);

export type InterviewerSlotType = z.infer<typeof InterviewerSlotTypeSchema>;

export enum TagFilterType {
  INCLUDE_ALL = "INCLUDE_ALL",
  INCLUDE_ANY = "INCLUDE_ANY",
  EXCLUDE_IF_ANY = "EXCLUDE_IF_ANY",
  EXCLUDE_IF_ALL = "EXCLUDE_IF_ALL",
}

export const TagFilterTypeSchema = z.nativeEnum(TagFilterType);

export const InterviewerSlotTagFilterInputSchema = z.object({
  id: z.string(),
  type: TagFilterTypeSchema,
  tagIds: z.array(z.string()),
});

export type InterviewerSlotTagFilterInput = z.infer<
  typeof InterviewerSlotTagFilterInputSchema
>;

export const InterviewerSlotInputSchema = z.object({
  id: z.string().optional(),
  interviewer: z.object({
    userMembershipId: z.string(),
  }),
  shadowingInterviewer: z
    .object({
      userMembershipId: z.string(),
    })
    .nullable()
    .optional(),
  createdFromInterviewerRequirementId: z.string().optional(),
  type: InterviewerSlotTypeSchema,
  userMembershipIdsSetting: z.array(z.string()).optional(),
  shadowingUserMembershipIdsSetting: z.array(z.string()).optional(),
  interviewerPoolIdsSetting: z.array(z.string()).optional(),
  trainingEnabled: z.boolean().optional(),
  tagFilters: z.array(InterviewerSlotTagFilterInputSchema).optional(),
});

export type InterviewerSlotInput = z.infer<typeof InterviewerSlotInputSchema>;

export const InterviewerSlotForDiffSchema = z.object({
  id: z.string(),
  type: InterviewerSlotTypeSchema,
  trainingEnabled: z.boolean(),
  interviewer: z
    .object({
      userMembershipId: z.string().nullable(),
    })
    .nullable(),
  shadowingInterviewer: z
    .object({
      userMembershipId: z.string().nullable(),
    })
    .nullable(),
  userMembershipIdsSetting: z.array(z.string()),
  shadowingUserMembershipIdsSetting: z.array(z.string()),
  interviewerPoolIdsSetting: z.array(z.string()),
  createdFromInterviewerRequirementId: z.string().nullable().optional(),
});

export type InterviewerSlotForDiff = z.infer<
  typeof InterviewerSlotForDiffSchema
>;
