/* eslint-disable @typescript-eslint/no-explicit-any, class-methods-use-this, @typescript-eslint/no-use-before-define, no-underscore-dangle */
import "./VariableNode.sass";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import clsx from "clsx";
import {
  $createNodeSelection,
  $getNodeByKey,
  $setSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
} from "lexical";
import { useEffect } from "react";
import { Spread } from "type-fest";

import { createComponentUtils } from "../../../__utils/atlas";
import { useEvent } from "../../../__utils/react";
import { Popover } from "../../../popover";
import OptionalTooltip from "../../../tooltip/OptionalTooltip";
import { useSelection } from "../../__utils/selection-context";
import { useVariablesConfig } from "./config";
import { SetConfigFn, UpdateConfigFn, VariableSpec } from "./types";
import { VariableToken } from "./VariableToken";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Variable";

const { el } = createComponentUtils(COMPONENT_NAME);

// lexical helpers
// ---------------

export function $createVariableNode(
  id: string,
  value: unknown,
  config: unknown
): VariableNode {
  return new VariableNode(id, value, config);
}

export function $isVariableNode(
  node: LexicalNode | null | undefined
): node is VariableNode {
  return node instanceof VariableNode;
}

// serialized type
// ---------------

export type SerializedVariableNode = Spread<
  {
    type: "variable";
    version: 1;
    id: string;
    value: unknown;
    config: unknown;
  },
  SerializedLexicalNode
>;

// variable component
// ------------------

type VariableProps = {
  nodeKey: string;
  id: string;
  value: unknown;
  config: any;
};

function Variable({ nodeKey, id, value, config }: VariableProps) {
  const [isSelected] = useLexicalNodeSelection(nodeKey);

  const {
    specSet,
    valueSet = {},
    undefinedMode = "placeholder",
    timezone,
  } = useVariablesConfig();
  const spec = specSet[id] as VariableSpec<any, true>;
  if (!spec) throw new Error(`Missing spec for variable with id '${id}'`);
  const {
    name,
    label,
    defaultConfig,
    presets,
    renderVariable,
    renderConfigUI: useRenderConfigUI,
    renderConfigAnnotation,
  } = spec;

  const { selectedUniqueNodeKey } = useSelection();
  const isUniqueSelected = selectedUniqueNodeKey === nodeKey;

  const [editor] = useLexicalComposerContext();
  const setConfig: SetConfigFn<unknown> = useEvent((newConfig) =>
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isVariableNode(node)) node.setConfig(newConfig);
    })
  );
  const updateConfig: UpdateConfigFn<unknown> = useEvent((newConfig) =>
    setConfig({ ...config, ...newConfig })
  );

  useEffect(() => {
    if (!(id in valueSet)) return;

    const newValue = valueSet[id];

    const hasChanged = editor.getEditorState().read(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isVariableNode(node) && node.__value !== newValue) return true;
      return false;
    });

    if (!hasChanged) return;

    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isVariableNode(node)) node.setValue(newValue);
    });
  }, [editor, id, nodeKey, valueSet]);

  useEffect(
    () =>
      editor.registerCommand(
        CLICK_COMMAND,
        (event) => {
          const clickedInsideNode = editor
            .getElementByKey(nodeKey)
            ?.contains(event.target as Node);
          if (clickedInsideNode) {
            editor.update(() => {
              if (isUniqueSelected) {
                $setSelection(null);
              } else {
                const selection = $createNodeSelection();
                selection.add(nodeKey);
                $setSelection(selection);
              }
            });
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
    [editor, isSelected, isUniqueSelected, nodeKey]
  );

  const partialRenderVariable = (configOverride?: any) =>
    renderVariable({ value, config: configOverride ?? config });

  const configAnnotation = renderConfigAnnotation?.({
    value,
    config: config ?? {},
    renderVariable: partialRenderVariable,
  });

  const isUndefined = value === undefined || value === "";

  const header = (
    <div className="header">
      <p className="name">${name}</p>
      <p className="label">{label}</p>
    </div>
  );

  const configUI = useRenderConfigUI?.({
    open: isUniqueSelected,
    value,
    config: config ?? {},
    defaultConfig,
    setConfig,
    updateConfig,
    presets,
    renderVariable: partialRenderVariable,
  });

  const footer = <div className="footer">{configUI?.footer}</div>;

  // Keep any variables with `timezone` in their config in sync
  useEffect(() => {
    if (config?.timezone !== timezone) {
      updateConfig({ timezone });
    }
  }, [config?.timezone, timezone, updateConfig]);

  return (
    <Popover.Root open={isUniqueSelected}>
      <OptionalTooltip
        isInstant
        content={
          !isUniqueSelected
            ? `Click or select for ${configUI ? "options" : "information"}`
            : undefined
        }
      >
        <Popover.Anchor>
          <VariableToken
            undefinedMode={undefinedMode}
            isUndefined={isUndefined}
            isSelected={isSelected}
          >
            {isUndefined
              ? `${name}${configAnnotation ? ` (${configAnnotation})` : ""}`
              : renderVariable(
                  { value, config: config ?? {} },
                  { isEditing: true }
                )}
          </VariableToken>
        </Popover.Anchor>
      </OptionalTooltip>
      <Popover.Content
        hasPadding={false}
        className={clsx(el`popover-content`, { hasConfigUI: configUI?.body })}
        portal
        autoFocusOnShow={false}
        autoFocusOnHide={false}
        header={configUI ? header : undefined}
        footer={configUI?.footer ? footer : undefined}
      >
        <div className="body">{configUI?.body ?? header}</div>
      </Popover.Content>
    </Popover.Root>
  );
}

// variable node
// -------------

export class VariableNode extends DecoratorNode<JSX.Element> {
  __id: string;

  getId() {
    return this.__id;
  }

  __value: unknown;

  getValue() {
    return this.__value;
  }

  setValue(value: unknown) {
    this.getWritable().__value = value;
  }

  __config?: unknown;

  setConfig(config: unknown) {
    this.getWritable().__config = config;
  }

  getConfig() {
    return this.__config;
  }

  static getType(): string {
    return "variable";
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(node.__id, node.__value, node.__config, node.__key);
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    return $createVariableNode(
      serializedNode.id,
      serializedNode.value,
      serializedNode.config
    );
  }

  constructor(id: string, value: unknown, config: unknown, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__value = value;
    this.__config = config;
  }

  exportJSON(): SerializedVariableNode {
    return {
      type: "variable",
      version: 1,
      id: this.__id,
      value: this.__value,
      config: this.__config,
    };
  }

  createDOM(): HTMLElement {
    return document.createElement("span");
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <Variable
        nodeKey={this.__key}
        id={this.__id}
        value={this.__value}
        config={this.__config}
      />
    );
  }
}
