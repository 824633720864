import { createRichBlock } from "@resource/atlas/content-editor/__utils/rich-blocks";
import {
  DEFAULT_DATA,
  GuideAvailabilitySubmissionData,
  GuideAvailabilitySubmissionName,
} from "shared/guide-availability/rich-blocks/guide-availability-submission";

export const {
  $createGuideAvailabilitySubmissionNode,
  $isGuideAvailabilitySubmissionNode,
  INSERT_GUIDE_AVAILABILITY_SUBMISSION_COMMAND,
  GuideAvailabilitySubmissionNode,
  GuideAvailabilitySubmissionPlugin,
  guideAvailabilitySubmissionModule,
} = createRichBlock<
  GuideAvailabilitySubmissionName,
  GuideAvailabilitySubmissionData
>({
  name: "guide-availability-submission",
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => {
    const { GuideAvailabilitySubmissionComponent } = await import(
      "./component"
    );
    return GuideAvailabilitySubmissionComponent;
  },
  /* Uncomment for debugging purposes. This block should never
   * be inserted manually (for now)
   */
  // useInsertOption(_, { INSERT_COMMAND }) {
  //   const [editor] = useLexicalComposerContext();

  //   return useMemo(
  //     () => ({
  //       icon: atlasCalendarQuestion,
  //       label: "Share availability",
  //       targets: {
  //         insert: INSERT_TARGETS.SHARE_AVAILABILITY,
  //         fixedToolbar: { slot: "request-availability" },
  //       },
  //       onTrigger: () => {
  //         return editor.dispatchCommand(INSERT_COMMAND, DEFAULT_DATA);
  //       },
  //     }),
  //     [editor, INSERT_COMMAND]
  //   );
  // },
});
