/* eslint-disable import/prefer-default-export */
import "./ContentEditor.sass";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import clsx from "clsx";
import { useState } from "react";

import { baseModules } from "./__utils/base";
import { createComponent, el, ROOT } from "./__utils/component-utils";
import {
  createSlot,
  InputRefProvider,
  Providers,
  useLexicalConfig,
} from "./__utils/misc";
import { ModuleConfigProvider, useModules } from "./__utils/modules";
import { PortalElementProvider } from "./__utils/portal-element-context";
import { SelectionContextProvider } from "./__utils/selection-context";
import { TmpPlaceholderTextOverrideProvider } from "./__utils/tmp-placeholder-context";
import type { AtlasContentEditorComponent } from "./types";
import { RegisterEditor } from "./use-content-editor";

export const ContentEditor = createComponent<AtlasContentEditorComponent>(
  ({
    modules = [],
    onError,
    namespace,
    initialState,
    onChange,
    inputRef,
    innerEditorWidth,
    placeholder,
    trailingContentSlot,
    attachmentSlot,
    footerSlot,
    // @ts-expect-error Private prop.
    __registerEditor,
    ...props
  }) => {
    // modules and configuration
    const [{ nodes, plugins, themes }, moduleConfig] = useModules([
      ...baseModules,
      ...modules,
    ]);
    const lexicalConfig = useLexicalConfig({
      namespace,
      onError,
      nodes,
      editorState: initialState,
      themes,
    });

    const { isGhost = true } = props;

    // portal elements
    const [footer, setFooter] = useState<HTMLDivElement | null>(null);
    const [inputContainer, setInputContainer] = useState<HTMLDivElement | null>(
      null
    );

    // providers
    const providers = [
      // @ts-expect-error "children" is passed with React.cloneElement
      <LexicalComposer initialConfig={lexicalConfig} />,
      <PortalElementProvider elements={{ footer, inputContainer }} />,
      <InputRefProvider inputRef={inputRef} />,
      <SelectionContextProvider />,
      <TmpPlaceholderTextOverrideProvider placeholder={placeholder} />,
      <ModuleConfigProvider value={moduleConfig} />,
    ];

    // plugins that enable some features
    const featurePlugins = (
      <>
        {/* useContentEditor hook */}
        <RegisterEditor registerEditor={__registerEditor} />
        {/* onChange prop */}
        {onChange && (
          <OnChangePlugin
            ignoreSelectionChange
            ignoreHistoryMergeTagChange
            onChange={onChange}
          />
        )}
      </>
    );

    return (
      <div
        {...props}
        className={clsx(
          ROOT,
          {
            hasAttachment: attachmentSlot,
            hasTrailingContent: trailingContentSlot,
            isGhost,
          },
          props.className
        )}
      >
        <Providers providers={providers}>
          <div className={el`scroll-container`}>
            <div
              className={el`input-container`}
              style={{ maxWidth: innerEditorWidth }}
              ref={setInputContainer}
            >
              {plugins}
            </div>
            {createSlot(trailingContentSlot, {
              className: el`trailing-content-slot`,
              style: { maxWidth: innerEditorWidth },
            })}
          </div>
          {createSlot(attachmentSlot, {
            className: el`attachment-slot`,
            style: { maxWidth: innerEditorWidth },
          })}
          <div
            className={el`footer`}
            style={{ maxWidth: innerEditorWidth }}
            ref={setFooter}
          >
            {createSlot(footerSlot, { className: el`footer-slot` })}
          </div>
          {featurePlugins}
        </Providers>
      </div>
    );
  }
);
