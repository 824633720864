import { Button } from "@resource/atlas/button/Button";
import { atlasPersonPlus } from "@resource/atlas/icons";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { EditInterviewerSlotDisplay } from "client/components/interviewer-slots/display/EditInterviewerSlotDisplay";
import { InterviewerSelectWithSchedulingData } from "client/components/interviewer-slots/display/InterviewerSelect";
import { useInterviewerSlotWithSchedulingData } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { getUserMembershipIdsFromInterviewSlots } from "client/components/interviewer-slots/utils/misc";
import {
  InterviewerSlot,
  UserMembershipSelectWrapper,
} from "client/components/interviewer-slots/utils/types";
import { useFlags } from "client/lib/launchdarkly";
// eslint-disable-next-line import/no-restricted-paths
import { useSyncSchedulerInterviewersForSlotCalculations } from "client/scheduler/core/hooks/interviewer-slots";
import { EditProfileDialogProps } from "components/EditPersonalProfile/EditProfileDialog";
import { EditPreferencesDialogProps } from "components/EditPreferences/EditPreferencesDialog";
import FeatureFlags from "generated/FeatureFlags";
import {
  UpdatePreferencesMutation,
  UpdateProfileMutation,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";

import {
  useManageIndividualInterviewerSlot,
  useManageInterviewerSlotsCollection,
} from "../__hooks/useManageInterviewerSlots";
import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export type EditScheduledInterviewInterviewersProps = {
  state: UpsertScheduledInterviewFormState;
  UserMembershipSelect: UserMembershipSelectWrapper;
};

export function EditScheduledInterviewInterviewers({
  state,
  UserMembershipSelect,
}: EditScheduledInterviewInterviewersProps) {
  const { form, hideInterviewerPoolSelection } = state;
  const interviewerSlots = form.watch("scheduledInterview.interviewerSlots");
  const { [FeatureFlags.INTERVIEWER_TAGS]: interviewerTagsEnabled } =
    useFlags();

  const allMembershipIdsFromInterviewSlots =
    getUserMembershipIdsFromInterviewSlots(interviewerSlots);

  const { onAddInterviewer, onAddPool, onAddSmartMatch } =
    useManageInterviewerSlotsCollection({
      state,
    });

  const { formDataForSlotCalculations } =
    useSyncSchedulerInterviewersForSlotCalculations();

  return (
    <FormGroup label="Interviewers">
      <div className="space-y-2">
        {interviewerTagsEnabled && (
          <InterviewerSelectWithSchedulingData
            formDataForScheduling={formDataForSlotCalculations}
            onSelectUser={onAddInterviewer}
            onSelectPool={onAddPool}
            onSelectSmartMatch={onAddSmartMatch}
          />
        )}
        {interviewerSlots.length > 0 ? (
          interviewerSlots.map((interviewerSlot) => (
            <EditInterviewerSlot
              key={interviewerSlot.id}
              interviewerSlot={interviewerSlot}
              state={state}
              UserMembershipSelect={UserMembershipSelect}
              allMembershipIdsFromInterviewSlots={
                allMembershipIdsFromInterviewSlots
              }
            />
          ))
        ) : (
          <EmptyStateWrapper>No interviewers added.</EmptyStateWrapper>
        )}

        {!interviewerTagsEnabled && (
          <AddInterviewersButton
            state={state}
            hideInterviewerPoolSelection={hideInterviewerPoolSelection}
            allMembershipIdsFromInterviewSlots={
              allMembershipIdsFromInterviewSlots
            }
            UserMembershipSelect={UserMembershipSelect}
          />
        )}
      </div>
    </FormGroup>
  );
}

function AddInterviewersButton({
  hideInterviewerPoolSelection,
  state,
  allMembershipIdsFromInterviewSlots,
  UserMembershipSelect,
}: {
  hideInterviewerPoolSelection: boolean;
  state: UpsertScheduledInterviewFormState;
  allMembershipIdsFromInterviewSlots: string[];
  UserMembershipSelect: UserMembershipSelectWrapper;
}) {
  const { onAddInterviewer, onAddPool } = useManageInterviewerSlotsCollection({
    state,
  });

  const commonProps = {
    searchPlaceholderText: "Search by name or email",
    onSelect: onAddInterviewer,
    excludeUserMembershipIds: allMembershipIdsFromInterviewSlots,
    Trigger: (
      <Button variant="default" icon={atlasPersonPlus} className="w-full">
        Add interviewers
      </Button>
    ),
  };

  return hideInterviewerPoolSelection ? (
    <UserMembershipSelect {...commonProps} />
  ) : (
    <UserMembershipSelect
      {...commonProps}
      onSelectPool={onAddPool}
      includePools
    />
  );
}

function EditInterviewerSlot({
  interviewerSlot,
  state,
  allMembershipIdsFromInterviewSlots,
  UserMembershipSelect,
}: {
  interviewerSlot: InterviewerSlot;
  state: UpsertScheduledInterviewFormState;
  allMembershipIdsFromInterviewSlots: string[];
  UserMembershipSelect: UserMembershipSelectWrapper;
}) {
  const managementCallbacks = useManageIndividualInterviewerSlot({
    state,
    interviewerSlotId: interviewerSlot.id,
  });
  const interview = state.form.watch("scheduledInterview");
  const interviewerSlotWithOptionalCalculations =
    useInterviewerSlotWithSchedulingData({
      interviewerSlot,
      interview,
    });
  const onInterviewerProfileChange: EditProfileDialogProps["onCompleted"] =
    useCallback(
      (data: UpdateProfileMutation) => {
        if (data.updateUserMembershipDisplayInformation?.userMembership) {
          state.syncFormWithUserMembershipChanges(
            data.updateUserMembershipDisplayInformation.userMembership
          );
        }
      },
      [state]
    );
  const onInterviewerSchedulingPreferencesChange: EditPreferencesDialogProps["onCompleted"] =
    useCallback(
      (data: UpdatePreferencesMutation) => {
        if (data.updateUserMembershipSchedulingPreferences?.userMembership) {
          state.syncFormWithUserMembershipChanges(
            data.updateUserMembershipSchedulingPreferences.userMembership
          );
        }
      },
      [state]
    );

  return (
    <EditInterviewerSlotDisplay
      {...managementCallbacks}
      calendarColors={state.calendarColors}
      interviewerSlot={interviewerSlotWithOptionalCalculations}
      allMembershipIdsFromInterviewSlots={allMembershipIdsFromInterviewSlots}
      size={state.size}
      disableInterviewerTraining={state.hideInterviewerTraining}
      disableExternalInterviewerAlgorithm={
        state.hideExternalInterviewerAlgorithm
      }
      UserMembershipSelect={UserMembershipSelect}
      onInterviewerProfileChange={onInterviewerProfileChange}
      onInterviewerSchedulingPreferencesChange={
        onInterviewerSchedulingPreferencesChange
      }
      hideInterviewerDayLoad={!interview.startTime}
    />
  );
}
