/* eslint-disable react/destructuring-assignment */
import { Button } from "@resource/atlas/button/Button";
import { AtlasIconData } from "@resource/atlas/icon/types";
import { atlasArrowLeft } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { BackButton } from "client/components/generic/layout/BackButton";
import { FooterProps } from "client/components/generic/layout/Footer";
import { HeaderProps } from "client/components/generic/layout/Header";
import { AssigneeOption } from "client/components/generic/select/AssigneeSelect";
import { ResyncJobWithGreenhouseButton } from "client/components/jobs/ResyncJobWithGreenhouseButton";
import { ReportRescheduleReasonView } from "client/components/scheduled-interviews/ReportingReasons/display/ReportRescheduleReasonView";
import { useReportRescheduleReasonState } from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportRescheduleReasonState";
import { CandidateSectionForSchedulingRequest } from "client/components/scheduling-tasks/CandidateSectionForSchedulingRequest";
import { useReschedulableInterviews } from "client/hooks/useReschedulableInterviews";
import {
  GuideComposeMessageForm,
  GuideComposeMessageFormProps,
} from "client/message-composer/__components/GuideComposeMessageForm";
import { PostMessageSendWarningsDialog } from "client/message-composer/__components/PostMessageSendWarningsDialog";
import {
  GuideComposeMessageState,
  useGuideComposeMessageState,
} from "client/message-composer/__hooks/useGuideComposeMessageState";
import { useValidationOnSend } from "client/message-composer/__hooks/useValidationOnSend";
import { InterviewRequirementsConfigurationProvider } from "client/scheduling-requirements-configuration/context";
import { GuideIdProvider } from "client/utils/guide-id-provider";
import ErrorPage from "components/Generic/ErrorPage";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import {
  GuideForSchedulingTaskAndAvailabilityWorkflowsFragment,
  SchedulingRequestAutoAssignOnAvailabilityReceiptType,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { formatEntity } from "shared/constants/entities";
import { CancelInterviewSettings } from "shared/scheduling-requests/types";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";
import useQuery from "utils/useQuery";

import { EditTemplateForm } from "./EditTemplateForm";
import { useAlreadyOpenTaskConfirmation } from "./hooks/useAlreadyOpenTaskConfirmation";
import { useMessagingWorkflowData } from "./hooks/useMessagingWorkflowData";
import {
  useOnWorkflowSubmit,
  WorkflowSubmitOnCompleted,
} from "./hooks/useOnWorkflowSubmit";
import {
  RescheduleableInterview,
  SchedulingRequestFormState,
  SchedulingRequestFormType,
  useSchedulingRequestFormState,
} from "./hooks/useSchedulingRequestFormState";
import {
  useSelectedInterviewersForReschedule,
  useSelectedInterviewsForReschedule,
} from "./hooks/useSelectedInterviewersForReschedule";
import { useSetDefaultReportingReasonFromSelectedInterviewsForReschedule } from "./hooks/useSetDefaultReportingReasonFromInterviews";
import {
  useWorkflowStateForGuide,
  WorkflowStateForGuide,
} from "./hooks/useWorkflowStateForGuide";
import {
  SchedulingRequestForm,
  SchedulingRequestFormProps,
} from "./SchedulingRequestForm";
import {
  SchedulingTaskWorkflowViews,
  SchedulingTaskWorkflowViewWrapper,
  ViewCallbackProps,
} from "./SchedulingTaskWorkflowViewWrapper";

const GET_GUIDE_FOR_SCHEDULING_REQUEST_QUERY = gql(`
  query GetGuideForSchedulingTaskAndAvailabilityWorkflows(
    $guideId: String!
    $isCandidateInterviewConfirmation: Boolean = false
    $isCandidateRescheduleInterviewConfirmation: Boolean = false
    $isRequestAvailability: Boolean = false
    $isRequestAdditionalAvailability: Boolean = false
    $isSelfScheduleRequest: Boolean = false
  ) {
    guideById(guideId: $guideId) {
      ...GuideForSchedulingTaskAndAvailabilityWorkflows
    }
  }
`);

export type SchedulingTaskAndAvailabilityWorkflowsProps = Omit<
  SchedulingTaskAndAvailabilityWorkflowsDisplayProps,
  "guide" | "schedulingRequestTemplate" | "reschedulableInterviews"
> & {
  guideId?: string | null;
  skipQuery?: boolean;
  defaultNotes?: string | null;
  defaultAssignee?: AssigneeOption;
};

export function SchedulingTaskAndAvailabilityWorkflows({
  guideId,
  skipQuery,
  defaultNotes,
  defaultAssignee: passedDefaultAssignee,
  ...rest
}: SchedulingTaskAndAvailabilityWorkflowsProps) {
  const isRequestAvailability = !rest.skipRequestingAvailability;
  const { user } = useAuthContext();
  const { data } = useQuery(GET_GUIDE_FOR_SCHEDULING_REQUEST_QUERY, {
    variables: {
      guideId: guideId!,
      // Logic to pick which template to use lives in the hooks lower down
      isRequestAvailability: true,
      isRequestAdditionalAvailability: true,
    },
    fetchPolicy: "cache-and-network",
    skip: skipQuery || !guideId,
  });
  const reschedulableInterviews = useReschedulableInterviews({
    guideId: guideId!,
  });

  const defaultAssignee = useMemo(() => {
    if (passedDefaultAssignee || passedDefaultAssignee === null) {
      return passedDefaultAssignee;
    }
    if (isRequestAvailability) {
      return user?.currentUserMembership ?? undefined;
    }
    return undefined;
  }, [passedDefaultAssignee, isRequestAvailability, user]);

  if (!data || !guideId || !reschedulableInterviews) {
    return (
      <View>
        <Loading />
      </View>
    );
  }

  const guide = data.guideById;

  if (!guide) {
    return (
      <View>
        <ErrorPage errorCode="404" />
      </View>
    );
  }

  if (!guide.atssyncApplication?.currentStage) {
    return (
      <View>
        <div>
          Application not on current stage, cannot start{" "}
          {formatEntity("scheduling request")}
        </div>
      </View>
    );
  }

  const template =
    guide.atssyncApplication.currentStage?.schedulingRequestTemplate;

  if (!template) {
    return (
      <View>
        <div>
          No {formatEntity("scheduling request template")} for current stage,
          cannot start {formatEntity("scheduling request")}
        </div>
        <ResyncJobWithGreenhouseButton jobId={guide.job.id} />
      </View>
    );
  }

  return (
    <InterviewRequirementsConfigurationProvider
      defaultInterviewPanelRequirement={template.interviewPanelRequirement}
      defaultNotes={defaultNotes ?? template.defaultNotes}
      defaultAssignee={defaultAssignee}
      defaultAutoAssignOnAvailabilityReceiptConfig={
        isRequestAvailability
          ? {
              autoAssignType:
                SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE,
              assignToUserMembership: null,
            }
          : undefined
      }
    >
      <GuideIdProvider guideId={guideId}>
        <SchedulingTaskWorkflowsDisplay
          {...rest}
          guide={guide}
          schedulingRequestTemplate={{
            ...template,
            interviewPanelRequirementId: template.interviewPanelRequirement.id,
          }}
          reschedulableInterviews={reschedulableInterviews}
        />
      </GuideIdProvider>
    </InterviewRequirementsConfigurationProvider>
  );
}

export type SchedulingTaskAndAvailabilityWorkflowsDisplayProps = {
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
  schedulingRequestTemplate: {
    id: string;
    interviewPanelRequirementId: string;
  };
  defaultSelectedRescheduleIds?: string[];
  defaultCancelInterviewSettings?: CancelInterviewSettings;
  schedulingRequestIdForAvailabilityRequest?: string;
  defaultSchedulingRequestFormType?: SchedulingRequestFormType;
  backIcon?: AtlasIconData;
  onBack: () => void;
  onCompleted?: WorkflowSubmitOnCompleted;
  skipRequestingAvailability?: boolean;
  reschedulableInterviews: RescheduleableInterview[];
};

function SchedulingTaskWorkflowsDisplay(
  props: SchedulingTaskAndAvailabilityWorkflowsDisplayProps
) {
  const {
    schedulingRequestTemplate,
    guide,
    onBack,
    backIcon,
    onCompleted,
    skipRequestingAvailability,
    defaultSelectedRescheduleIds,
    defaultCancelInterviewSettings,
    defaultSchedulingRequestFormType,
    schedulingRequestIdForAvailabilityRequest,
    reschedulableInterviews,
  } = props;

  const workflowState = useWorkflowStateForGuide({
    guide,
    onBack,
    backIcon,
    defaultSchedulingRequestFormType,
    skipRequestingAvailability,
  });

  const {
    schedulingRequestFormType,
    setSchedulingRequestFormType,
    hasOpenSchedulingRequests,
  } = workflowState;

  const schedulingRequestFormState = useSchedulingRequestFormState({
    reschedulableInterviews,
    formType: schedulingRequestFormType,
    defaultSelectedRescheduleIds,
    defaultSelectedSchedulingRequestId:
      schedulingRequestIdForAvailabilityRequest ??
      guide.schedulingRequests[0]?.id,
    defaultCancelInterviewSettings,
  });

  const {
    thread,
    allThreads: taskThreads,
    messagingWorkflowType,
    defaultTemplate,
    taskRequirements,
    defaultMoveToInterviewPlanItemId,
    defaultRecipients,
  } = useMessagingWorkflowData({
    schedulingRequestFormState,
    guide,
  });

  const composeState = useGuideComposeMessageState({
    guideId: guide.id,
    timezone: guide.candidate.timezone,
    candidateEmail: guide.candidate.email,
    currentInterviewItemId: guide.currentInterviewPlanItem?.id,
    workflowType: messagingWorkflowType,
    defaultTemplate,
    defaultRecipients,
    thread,
    taskThreads,
    allThreads: guide.posts,
    taskRequirements,
    disableLeaveConfirmation: true,
    defaultMoveToInterviewPlanItemId,
  });

  const reportRescheduleReasonState = useReportRescheduleReasonState();

  useSetDefaultReportingReasonFromSelectedInterviewsForReschedule({
    schedulingRequestFormState,
    reportRescheduleReasonState,
  });

  const selectedRescheduleInterviews = useSelectedInterviewsForReschedule({
    schedulingRequestFormState,
  });

  const originalInterviewersForSelectedRescheduleInterviews =
    useSelectedInterviewersForReschedule({
      interviews: selectedRescheduleInterviews,
    });

  const [onSubmit, { loading: submitLoading }] = useOnWorkflowSubmit({
    onCompleted,
    schedulingRequestFormState,
    composeState,
    schedulingRequestTemplate,
    workflowState,
    guide,
    workflowType: messagingWorkflowType,
    reportRescheduleReasonState,
  });

  const baseSharedProps = useMemo((): Omit<BaseSharedProps, "viewProps"> => {
    return {
      onSubmit,
      submitLoading,
      workflowState,
    };
  }, [onSubmit, submitLoading, workflowState]);
  const baseSchedulingRequestViewProps = useMemo((): Omit<
    SchedulingRequestFormViewProps,
    "viewProps"
  > => {
    return {
      ...baseSharedProps,
      guide,
      state: schedulingRequestFormState,
    };
  }, [baseSharedProps, guide, schedulingRequestFormState]);

  const baseRequestAvailabilityViewProps = useMemo((): Omit<
    RequestAvailabilityViewProps,
    "viewProps"
  > => {
    return {
      ...baseSharedProps,
      guide,
      composeState,
      onSubmit: composeState.form.handleSubmit(baseSharedProps.onSubmit),
      submitLabel: "Send message",
    };
  }, [baseSharedProps, guide, composeState]);
  const baseEditTemplateViewProps = useMemo((): Omit<
    EditTemplateViewProps,
    "viewProps"
  > => {
    return {
      guide,
      composeState,
    };
  }, [guide, composeState]);

  if (skipRequestingAvailability) {
    return (
      <SchedulingTaskWorkflowViewWrapper
        orderedViews={(
          [
            "scheduling-request",
            workflowState.schedulingRequestFormType === "rescheduling"
              ? "reschedule-reporting-reasons"
              : null,
          ] as (SchedulingTaskWorkflowViews | null)[]
        ).filter(filterOutNullsAndUndefined)}
        views={{
          "scheduling-request": (viewProps) => (
            <SchedulingRequestFormView
              {...baseSchedulingRequestViewProps}
              allowManualAvailability
              assignmentOptionType="pick-assignee"
              header={{
                title:
                  schedulingRequestFormType === "rescheduling"
                    ? `Create ${formatEntity("rescheduling request")}`
                    : `Create ${formatEntity("scheduling request")}`,
              }}
              viewProps={viewProps}
              viewHeader={{
                title: "Select interviews",
                subtitle:
                  schedulingRequestFormType === "rescheduling"
                    ? "What interviews should be rescheduled?"
                    : "What interviews should be scheduled?",
                Subcomponent: (
                  <div className="space-y-6">
                    <div className="space-y-2">
                      <span className="text-body-md-heavy">Candidate</span>
                      <CandidateSectionForSchedulingRequest
                        candidateName={guide.candidate.name}
                        jobName={guide.job.name}
                        stageName={
                          guide.atssyncApplication?.currentStage?.name ??
                          undefined
                        }
                      />
                    </div>
                  </div>
                ),
              }}
            />
          ),
          ...(workflowState.schedulingRequestFormType === "rescheduling"
            ? {
                "reschedule-reporting-reasons": (viewProps) => (
                  <ReportRescheduleReasonView
                    interviewers={
                      originalInterviewersForSelectedRescheduleInterviews
                    }
                    state={reportRescheduleReasonState}
                    onCancel={onBack}
                    onBack={viewProps.onBack}
                    confirmationButton={{
                      label: "Submit",
                      variant: "primary",
                      onClick: onSubmit,
                      isLoading: submitLoading,
                    }}
                  />
                ),
              }
            : {}),
        }}
      />
    );
  }

  if (schedulingRequestIdForAvailabilityRequest) {
    return (
      <SchedulingTaskWorkflowViewWrapper
        orderedViews={["request-availability"]}
        views={{
          "request-availability": (viewProps) => (
            <RequestAvailabilityView
              {...baseRequestAvailabilityViewProps}
              viewProps={viewProps}
            />
          ),
          "edit-template": (viewProps) => (
            <EditTemplateView
              {...baseEditTemplateViewProps}
              viewProps={viewProps}
            />
          ),
        }}
      />
    );
  }

  const hasInterviewsToReschedule = reschedulableInterviews.length > 0;
  const showSelectInterviews =
    hasOpenSchedulingRequests || hasInterviewsToReschedule;

  return (
    <SchedulingTaskWorkflowViewWrapper
      orderedViews={(
        [
          "scheduling-request",
          workflowState.schedulingRequestFormType === "rescheduling"
            ? "reschedule-reporting-reasons"
            : null,
          "request-availability",
        ] as (SchedulingTaskWorkflowViews | null)[]
      ).filter(filterOutNullsAndUndefined)}
      views={{
        "scheduling-request": (viewProps) => (
          <SchedulingRequestFormView
            {...baseSchedulingRequestViewProps}
            viewProps={viewProps}
            assignmentOptionType="auto-assign-on-availability-received"
            viewHeader={{
              title: "Select interviews",
              subtitle:
                "What interviews should be scheduled with the availability provided by the candidate?",
              Subcomponent: (
                <div className="space-y-6">
                  <div className="space-y-2">
                    <span className="text-body-md-heavy">Candidate</span>
                    <CandidateSectionForSchedulingRequest
                      candidateName={guide.candidate.name}
                      jobName={guide.job.name}
                      stageName={
                        guide.atssyncApplication?.currentStage?.name ??
                        undefined
                      }
                    />
                  </div>
                  {showSelectInterviews && (
                    <div className="space-y-2">
                      <span className="text-body-md-heavy">
                        What interviews are you requesting availability for?
                      </span>
                      <div className="space-y-3">
                        {hasOpenSchedulingRequests && (
                          <RadioItem
                            label="An open scheduling task"
                            isSelected={
                              schedulingRequestFormType === "select-task"
                            }
                            onClick={() =>
                              setSchedulingRequestFormType("select-task")
                            }
                          />
                        )}
                        <RadioItem
                          label="Schedule new interviews"
                          isSelected={
                            schedulingRequestFormType === "scheduling"
                          }
                          onClick={() =>
                            setSchedulingRequestFormType("scheduling")
                          }
                        />
                        {hasInterviewsToReschedule && (
                          <RadioItem
                            label="Reschedule existing interviews"
                            isSelected={
                              schedulingRequestFormType === "rescheduling"
                            }
                            onClick={() =>
                              setSchedulingRequestFormType("rescheduling")
                            }
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ),
            }}
          />
        ),
        "request-availability": (viewProps) => (
          <RequestAvailabilityView
            {...baseRequestAvailabilityViewProps}
            viewProps={viewProps}
          />
        ),
        ...(workflowState.schedulingRequestFormType === "rescheduling"
          ? {
              "reschedule-reporting-reasons": (viewProps) => (
                <ReportRescheduleReasonView
                  interviewers={
                    originalInterviewersForSelectedRescheduleInterviews
                  }
                  state={reportRescheduleReasonState}
                  onCancel={onBack}
                  onBack={viewProps.onBack}
                  confirmationButton={
                    viewProps.isLastView
                      ? {
                          label: "Submit",
                          variant: "primary",
                          onClick: onSubmit,
                          isLoading: submitLoading,
                        }
                      : {
                          label: "Continue",
                          variant: "default",
                          onClick: viewProps.onContinue,
                        }
                  }
                />
              ),
            }
          : {}),
        "edit-template": (viewProps) => (
          <EditTemplateView
            {...baseEditTemplateViewProps}
            viewProps={viewProps}
          />
        ),
      }}
    />
  );
}

type BaseSharedProps = {
  viewProps: ViewCallbackProps;
  workflowState: WorkflowStateForGuide;
  onSubmit: () => Promise<void>;
  submitLoading: boolean;
  submitLabel?: string;
};

const getDefaultHeaderProps = ({
  viewProps,
  workflowState,
}: BaseSharedProps): HeaderProps => {
  return {
    title: workflowState.workflowTitle,
    leftActions: viewProps.isFirstView ? (
      <Button
        onClick={workflowState.onBack}
        icon={workflowState.backIcon ?? atlasArrowLeft}
      />
    ) : (
      <BackButton onClick={viewProps.onBack} />
    ),
  };
};

const getDefaultFooterProps = ({
  viewProps,
  validationError,
  onSubmit,
  submitLabel,
  submitLoading,
  workflowState,
  LeftComponent,
}: BaseSharedProps & {
  validationError?: string;
  LeftComponent?: React.ReactNode;
}): FooterProps => {
  return {
    leftActions: viewProps.isFirstView ? (
      <div className="flex items-center space-x-2">
        <Button onClick={workflowState.onBack}>Cancel</Button>
        {LeftComponent}
      </div>
    ) : (
      <div className="flex items-center space-x-2">
        <Button onClick={viewProps.onBack}>Back</Button>
        {LeftComponent}
      </div>
    ),
    rightActions: (
      <Tooltip content={validationError} isInstant>
        <Button
          onClick={viewProps.isLastView ? onSubmit : viewProps.onContinue}
          disabled={!!validationError}
          variant={viewProps.isLastView ? "primary" : "default"}
          isLoading={submitLoading}
        >
          {viewProps.isLastView ? submitLabel || "Submit" : "Continue"}
        </Button>
      </Tooltip>
    ),
  };
};

type SchedulingRequestFormViewProps = {
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
  state: SchedulingRequestFormState;
} & Partial<SchedulingRequestFormProps> &
  BaseSharedProps;

function SchedulingRequestFormView({
  guide,
  state,
  viewProps,
  workflowState,
  onSubmit,
  submitLoading,
  ...rest
}: SchedulingRequestFormViewProps) {
  const {
    onContinueWithConfirmation,
    onSubmitWithConfirmation,
    Component: AlreadyOpenTaskDialogComponent,
  } = useAlreadyOpenTaskConfirmation({
    guide,
    workflowState,
    viewProps,
    onSubmit,
  });

  return (
    <>
      <SchedulingRequestForm
        guide={guide}
        state={state}
        workflowState={workflowState}
        {...rest}
        footer={{
          ...getDefaultFooterProps({
            viewProps: {
              ...viewProps,
              onContinue: onContinueWithConfirmation,
            },
            workflowState,
            validationError: state.validationError,
            onSubmit: onSubmitWithConfirmation,
            submitLoading,
          }),
          ...rest.footer,
        }}
        header={{
          ...getDefaultHeaderProps({
            viewProps,
            workflowState,
            onSubmit,
            submitLoading,
          }),
          ...rest.header,
        }}
      />
      {AlreadyOpenTaskDialogComponent}
    </>
  );
}

type RequestAvailabilityViewProps = {
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
  viewProps: ViewCallbackProps;
  composeState: GuideComposeMessageState;
} & BaseSharedProps &
  Partial<GuideComposeMessageFormProps>;

function RequestAvailabilityView(props: RequestAvailabilityViewProps) {
  const {
    guide,
    viewProps,
    composeState,
    onSubmit,
    submitLoading,
    workflowState,
    ...rest
  } = props;

  const { onSubmitWithWarnings, dialogProps } = useValidationOnSend<void>({
    guide,
    composeState,
    onSubmit,
  });

  return (
    <>
      <PostMessageSendWarningsDialog {...dialogProps} />
      <GuideComposeMessageForm
        state={composeState}
        guide={guide}
        autofocus
        onEditTemplate={() => {
          viewProps.setView("edit-template");
        }}
        {...rest}
        footer={{
          ...getDefaultFooterProps({
            viewProps,
            workflowState,
            validationError: composeState.validationData.message,
            onSubmit: onSubmitWithWarnings,
            submitLoading,
            submitLabel: "Send message",
            LeftComponent: composeState.NotifiedCountComponent,
          }),
          ...rest.footer,
        }}
        header={{
          ...getDefaultHeaderProps({
            viewProps,
            workflowState,
            onSubmit,
            submitLoading,
          }),
          ...rest.header,
        }}
      />
    </>
  );
}

type EditTemplateViewProps = {
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
  viewProps: ViewCallbackProps;
  composeState: GuideComposeMessageState;
};

function EditTemplateView(props: EditTemplateViewProps) {
  const { viewProps, composeState, guide } = props;

  if (!composeState.template) {
    return null;
  }

  return (
    <EditTemplateForm
      postTemplate={composeState.template}
      organization={guide.organization}
      onBack={() => viewProps.setView("request-availability")}
      onUpdate={(pt) => {
        composeState.loadTemplate(pt, {
          shouldDirty: false,
        });
        viewProps.setView("request-availability");
      }}
    />
  );
}
