import { gql } from "generated/graphql-codegen";
import { UseUnreadMessagesGuidePostMessageFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";

import { useGuideAuthContext } from "./GuideAuthContext";

type MessageReply =
  UseUnreadMessagesGuidePostMessageFragment["replies"][number];

const USE_UNREAD_MESSAGES_GUIDE_POST_REPLY = gql(`
fragment UseUnreadMessagesGuidePostReply on GuidePost {
    id
    readAt
    senderUser {
      id
      isCandidate
    }
    lastCandidateEmailEvent {
      type
      happenedAt
      email
    }
  }
`);

const USE_UNREAD_MESSAGES_GUIDE_POST_MESSAGE = gql(`
fragment UseUnreadMessagesGuidePostMessage on GuidePost {
    id
    replies {
      ...UseUnreadMessagesGuidePostReply
    }
    ...UseUnreadMessagesGuidePostReply
  }
`);

export function useUnreadMessages(
  messages: UseUnreadMessagesGuidePostMessageFragment[]
) {
  const { isGuideCandidate, isOrgUser, isPostOwner } = useGuideAuthContext();

  const shouldCountAsUnread = useCallback(
    (message: MessageReply) => {
      const currentUserIsOwner =
        message.senderUser?.id && isPostOwner(message.senderUser?.id);

      return (
        !message.readAt &&
        ((isOrgUser && currentUserIsOwner) ||
          (isGuideCandidate && !currentUserIsOwner))
      );
    },
    [isGuideCandidate, isOrgUser, isPostOwner]
  );

  const unreadIds = useMemo(
    () =>
      messages
        .flatMap((message) => [message, ...message.replies])
        .filter((message) => shouldCountAsUnread(message))
        .map((message) => message.id),
    [messages, shouldCountAsUnread]
  );
  return unreadIds;
}

useUnreadMessages.fragments = {
  guidePostReply: USE_UNREAD_MESSAGES_GUIDE_POST_REPLY,
  guidePostMessage: USE_UNREAD_MESSAGES_GUIDE_POST_MESSAGE,
};
