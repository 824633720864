/* eslint-disable react/no-unused-prop-types */
import { DialogStore, useDialogStore } from "@ariakit/react";
import { Dialog } from "@resource/atlas/dialog-v2/Dialog";
import {
  MultiStepView,
  ViewCallbackProps,
} from "@resource/atlas/multi-step-view/MultiStepView";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { AdvancedInterviewerTagsSchedulerTour } from "client/components/tours/tours/AdvancedInterviewerTagsSchedulerTour";
import clsx from "clsx";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";

import { SchedulerOnCompleted } from "../../utils/types";
import { SchedulerCalendar } from "../calendar";
import { useSchedulerUpsertScheduledInterviewGroup } from "../data/useSchedulerUpsertScheduledInterviewGroup";
import { useIsReschedule } from "../hooks/interviews";
import { navigationAtom } from "../state";
import { useSyncSchedulerDialogLeaveConfirmation } from "../utils/leave-confirmation";
import {
  useIsSchedulingInPast,
  useSchedulerDisabledTooltipContent,
} from "../utils/validation";
import {
  SchedulerHydrationData,
  SchedulerProvider,
} from "./hydration/SchedulerProvider";
import {
  SchedulerLeftSidePanel,
  ViewDetails,
} from "./navigation/SchedulerLeftSidePanel";
import { SchedulerRightSidePanel } from "./navigation/SchedulerRightSidePanel";
import {
  SchedulerReviewPage,
  SchedulerReviewPageProps,
} from "./review/SchedulerReviewPage";

export function Scheduler(props: SchedulerHydrationData & SchedulerProps) {
  const {
    interviews,
    originalInterviews,
    guideId,
    schedulingRequestId,
    selectedInterviewId,
    scheduledInterviewGroupId,
    scheduledInterviewGroupSettings,
    ...rest
  } = props;
  return (
    <ErrorBoundary
      fallback={({ onRecover }) => <DialogError onRecover={onRecover} />}
    >
      <SchedulerProvider
        originalInterviews={originalInterviews}
        interviews={interviews}
        guideId={guideId}
        schedulingRequestId={schedulingRequestId}
        selectedInterviewId={selectedInterviewId}
        scheduledInterviewGroupId={scheduledInterviewGroupId}
        scheduledInterviewGroupSettings={scheduledInterviewGroupSettings}
      >
        <SchedulerWithState {...rest} />
      </SchedulerProvider>
    </ErrorBoundary>
  );
}

export type SchedulerProps = {
  onCompleted?: SchedulerOnCompleted;
  onCancel: () => void;
  viewDetails: ViewDetails;
};

type SchedulerViews = "scheduler" | "review";

function SchedulerWithState(schedulerProps: SchedulerProps) {
  const [animateScheduler, setAnimateScheduler] = useState(true);

  useEffect(() => {
    setAnimateScheduler(false);
  }, []);

  useSyncSchedulerDialogLeaveConfirmation();

  const SchedulerStep = useCallback(
    (viewProps: ViewCallbackProps<SchedulerViews>) => (
      <SchedulerView
        {...viewProps}
        {...schedulerProps}
        animateScheduler={animateScheduler}
      />
    ),
    [animateScheduler, schedulerProps]
  );
  const ReviewStep = useCallback(
    (viewProps: ViewCallbackProps<SchedulerViews>) => (
      <ReviewPageView {...viewProps} {...schedulerProps} />
    ),
    [schedulerProps]
  );

  return (
    <MultiStepView
      orderedViews={["scheduler", "review"]}
      views={{
        scheduler: SchedulerStep,
        review: ReviewStep,
      }}
    />
  );
}

type ChildViewProps = ViewCallbackProps<SchedulerViews> & SchedulerProps;

const SHOULD_ANIMATE_ON_MOUNT = true;

function SchedulerView({
  onContinue,
  onCancel,
  viewDetails,
  animateScheduler,
}: ChildViewProps & { animateScheduler: boolean }) {
  const logEvent = useLogEvent({
    component: "SchedulerView",
  });
  const navigation = useAtomValue(navigationAtom);
  const disabledTooltipContent = useSchedulerDisabledTooltipContent();

  const calendarPlugins = useMemo(
    () => [
      // TODO: Bring this back when we're ready
      // <FloatingToolbar
      //   key="floating-toolbar"
      //   interviewsPlaced={changedInterviewsCount}
      //   onCancel={onCancel}
      //   onContinue={onContinue}
      //   disabledTooltipContent={disabledTooltipContent}
      // />,
    ],
    []
  );

  const shouldAnimate = SHOULD_ANIMATE_ON_MOUNT && animateScheduler;
  const [opacity, setOpacity] = useState(shouldAnimate ? 0 : 100);

  useEffect(() => {
    if (shouldAnimate) {
      const timer = setTimeout(() => setOpacity(100), 0);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [animateScheduler, shouldAnimate]);

  // Make sure it shows up on hot reload
  useEffect(() => {
    if (shouldAnimate && opacity === 0) {
      setOpacity(100);
    }
  }, [shouldAnimate, opacity]);

  const onSchedulerContinue = useCallback(() => {
    onContinue();
    logEvent("Scheduler V2 Continue Clicked");
  }, [onContinue, logEvent]);

  const schedulerContinueConfirmationDialogStore = useDialogStore();

  const isSchedulingInPast = useIsSchedulingInPast();

  const onConfirmOrContinue = useCallback(() => {
    if (isSchedulingInPast) {
      schedulerContinueConfirmationDialogStore.show();
    } else {
      onSchedulerContinue();
    }
  }, [
    onSchedulerContinue,
    schedulerContinueConfirmationDialogStore,
    isSchedulingInPast,
  ]);

  return (
    <View
      header={null}
      content={{
        className: clsx("p-0 flex flex-grow flex-row w-full overflow-hidden"),
      }}
      footer={{
        rightActions: (
          <FormRightActions
            disabledTooltipContent={disabledTooltipContent}
            cancel={{
              onClick: onCancel,
            }}
            save={{
              children: "Continue",
              onClick: onConfirmOrContinue,
            }}
          />
        ),
      }}
    >
      <div
        className="flex flex-grow w-full overflow-hidden transition-opacity duration-150"
        style={{ opacity: `${opacity}%` }}
      >
        <SchedulerLeftSidePanel
          onCancel={onCancel}
          isOpen={navigation.leftPanelOpen}
          viewDetails={viewDetails}
        />
        <SchedulerCalendar plugins={calendarPlugins} />
        <SchedulerRightSidePanel isOpen={navigation.rightPanelOpen} />
        <SchedulerContinueConfirmationDialog
          store={schedulerContinueConfirmationDialogStore}
          onContinue={onSchedulerContinue}
        />
        <AdvancedInterviewerTagsSchedulerTour />
      </div>
    </View>
  );
}

function ReviewPageView({ onBack, onCompleted, onCancel }: ChildViewProps) {
  const logEvent = useLogEvent({
    component: "ReviewPageView",
  });
  const { loading: saveLoading, upsertScheduledInterviewGroup } =
    useSchedulerUpsertScheduledInterviewGroup({
      refetchQueries: ["SchedulingRequestDetailsQuery"],
      awaitRefetchQueries: true,
    });
  const isReschedule = useIsReschedule();

  const onSave: SchedulerReviewPageProps["onSave"] = useCallback(
    async ({ interviewsPanelReviewFormData, reportingData }) => {
      logEvent("Scheduler V2 Review Page Submitted");
      const result = await upsertScheduledInterviewGroup({
        interviewsPanelReviewFormData,
        reportingData,
      });

      if (result.data?.upsertScheduledInterviewGroupForGuide) {
        onCompleted?.(result.data, {
          sendNotifications: interviewsPanelReviewFormData.sendNotifications,
          isReschedule,
        });
      }
    },
    [upsertScheduledInterviewGroup, onCompleted, isReschedule, logEvent]
  );

  return (
    <SchedulerReviewPage
      onBack={onBack}
      onSave={onSave}
      onCancel={onCancel}
      saveLoading={saveLoading}
    />
  );
}

function SchedulerContinueConfirmationDialog({
  store,
  onContinue,
}: {
  store: DialogStore;
  onContinue: () => void;
}) {
  return (
    <Dialog store={store}>
      <View
        header={{
          title: "Confirm scheduling in the past",
        }}
        footer={{
          rightActions: (
            <FormRightActions
              cancel={{
                onClick: store.hide,
              }}
              save={{
                children: "Schedule",
                onClick: onContinue,
              }}
            />
          ),
        }}
      >
        <p className="text-body-md">
          One or more interviews are being scheduled in the past.{" "}
          <span className="text-body-md-heavy">
            Are you sure you want to continue?
          </span>
        </p>
      </View>
    </Dialog>
  );
}
