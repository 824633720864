import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Link } from "@resource/atlas/link/Link";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { InternalScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/InternalScheduledInterviewCard";
import { CypressData } from "client/cypress-data-keys";
import { PostMessageDialog } from "client/message-composer/__components/PostMessageDialog";
import { gql } from "generated/graphql-codegen";
import {
  ScheduledInterviewForScheduledInterviewCardFragment,
  SchedulingRequestForScheduledInterviewsFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";

gql(`
  fragment SchedulingRequestForScheduledInterviews on SchedulingRequest {
    id
    isReschedule
    guide {
      id
      publicUrl
    }
    scheduledInterviews {
      id
      isV2
      ...ScheduledInterviewForScheduledInterviewCard
    }
    interviewConfirmationPost {
      id
    }
    rescheduleConfirmationPost {
      id
    }
    rootPost {
      id
      recipientProfiles {
        id
        name
        email
        firstName
        lastName
        imageUrl
        isCandidate
      }
      removedRecipients
    }
    interviewPanelRequirement {
      id
      interviewRequirements {
        id
        interviewId
        hasBeenRescheduled
        scheduledInterviewToRescheduleId
        scheduledInterviewToReschedule {
          id
          ...ScheduledInterviewForScheduledInterviewCard
          isCancelled
        }
      }
    }
    scheduledInterviews {
      ...ScheduledInterviewForScheduledInterviewCard
      isCancelled
    }
  }
`);

export function ScheduledInterviewsSection({
  schedulingRequest,
  onNavigateToScheduledInterviewDetails,
  scheduledInterviews = [],
}: {
  schedulingRequest: SchedulingRequestForScheduledInterviewsFragment;
  onNavigateToScheduledInterviewDetails: (props: {
    scheduledInterviewId: string;
  }) => void;
  scheduledInterviews?: ScheduledInterviewForScheduledInterviewCardFragment[];
}) {
  const confirmInterviewsDialogStore = useDialogStore();
  const confirmRescheduleDialogStore = useDialogStore();

  const onSendConfirmationMessageV2 = useCallback(() => {
    if (schedulingRequest.isReschedule) {
      confirmRescheduleDialogStore.show();
    } else {
      confirmInterviewsDialogStore.show();
    }
  }, [
    confirmInterviewsDialogStore,
    confirmRescheduleDialogStore,
    schedulingRequest.isReschedule,
  ]);

  function InterviewConfirmationLink() {
    const post =
      schedulingRequest.rescheduleConfirmationPost ??
      schedulingRequest.interviewConfirmationPost;
    const hasUnconfirmedInterviews = schedulingRequest.scheduledInterviews.some(
      (si) => si.hidden && !si.isCancelled
    );
    const buttonVariant = hasUnconfirmedInterviews ? "primary" : "default";

    return !post ? (
      <Button
        variant={buttonVariant}
        onClick={() => {
          onSendConfirmationMessageV2();
        }}
      >
        Send candidate confirmation
      </Button>
    ) : (
      <>
        <Button
          variant={buttonVariant}
          onClick={() => {
            onSendConfirmationMessageV2();
          }}
        >
          Re-send candidate confirmation
        </Button>
        <div className="text-body-md text-subtle mt-2">
          Candidate confirmation sent.{" "}
          <Link
            href={`${schedulingRequest.guide.publicUrl}/message/${post.id}`}
            target="_blank"
            className="!text-purple-500"
          >
            View
          </Link>
        </div>
      </>
    );
  }

  const onInternalInterviewClick = useCallback(
    (interviewId: string) => () => {
      onNavigateToScheduledInterviewDetails({
        scheduledInterviewId: interviewId,
      });
    },
    [onNavigateToScheduledInterviewDetails]
  );
  return (
    <>
      <div className="space-y-2">
        <div className="w-full flex justify-between">
          <h4 className="text-h4">Scheduled interviews</h4>
        </div>
        {scheduledInterviews.length ? (
          <>
            {[...scheduledInterviews]
              .sort((a, b) => {
                return (
                  new Date(a.startTime).getTime() -
                  new Date(b.startTime).getTime()
                );
              })
              .map((scheduledInterview) => {
                return (
                  <InternalScheduledInterviewCard
                    scheduledInterview={scheduledInterview}
                    key={scheduledInterview.id}
                    onClick={onInternalInterviewClick(scheduledInterview.id)}
                    data-cy={
                      CypressData.schedulingTasks.scheduledInterviews.card
                    }
                  />
                );
              })}
            <div className="flex flex-col w-full text-body-md">
              <InterviewConfirmationLink />
            </div>
          </>
        ) : (
          <>
            <EmptyStateWrapper>No upcoming interviews</EmptyStateWrapper>
          </>
        )}
      </div>
      <PostMessageDialog
        dialog={{
          store: confirmInterviewsDialogStore,
        }}
        schedulingRequestId={schedulingRequest.id}
        guideId={schedulingRequest.guide.id}
        thread={schedulingRequest.rootPost ?? null}
        workflowType={PostMessageWorkflow.CONFIRM_INTERVIEWS}
      />
      <PostMessageDialog
        dialog={{
          store: confirmRescheduleDialogStore,
        }}
        schedulingRequestId={schedulingRequest.id}
        guideId={schedulingRequest.guide.id}
        thread={schedulingRequest.rootPost ?? null}
        workflowType={PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS}
      />
    </>
  );
}
