import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import type { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor/types";
import { View } from "@resource/atlas/view/View";
import { BackButton } from "client/components/generic/layout/BackButton";
import { InterviewTitleEditor } from "client/components/interviews/InterviewTitleEditor";
import { gql } from "generated/graphql-codegen";
import {
  CollaborativeCodingServiceType,
  CollaborativeCodingSlotInput,
  ConferencingServiceType,
  ConferencingSlotInput,
  ScheduledInterviewForEditFormFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";
import useMutation from "utils/useMutation";

import { EditCollaborativeCodingUrlInForm } from "./EditCollaborativeCodingUrlInForm";
import {
  EditConferencingURLInForm,
  VideoConferencingUrlSettings,
} from "./EditVideoConferencingUrlInForm";

gql(`
  fragment ScheduledInterviewForEditForm on ScheduledInterview {
    id
    title
    description
    conferencePhone
    videoConferencingUrl
    collaborativeCodingUrl
  }
`);

const UPDATE_SCHEDULED_INTERVIEW = gql(`
  mutation UpdateScheduledInterview($input: UpdateScheduledInterviewV2Input!) {
    updateScheduledInterviewV2(input: $input) {
      success
      code
      message
      scheduledInterview {
        ...ScheduledInterviewForEditForm
      }
    }
  }
`);

export type Interview = ScheduledInterviewForEditFormFragment;

type EditScheduledInterviewFormProps = {
  interview: Interview;
  zoomEnabled: boolean;
  googleCalendarEnabled: boolean;
  coderpadEnabled: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onBack: () => void;
};

export type InterviewFormData = {
  id: string;
  title: AtlasContentEditorSerializedState | undefined;
  videoConferencingSettings?: VideoConferencingUrlSettings;
  collaborativeCodingUrl?: string;
  description: AtlasContentEditorSerializedState | undefined;
  generateCoderpadUrl?: boolean;
};

function parseSlateLiteOrLexical(value: string) {
  try {
    const data = JSON.parse(value);
    return data;
  } catch (err) {
    // This is not yet lexical
    return value;
  }
}
function parseSlateOrLexical(value: string) {
  try {
    const data = JSON.parse(value);
    return data;
  } catch (err) {
    // This is not yet lexical
    return value;
  }
}

/** @deprecated Use client/components/scheduled-interviews/EditScheduledInterviewForm */
export function EditScheduledInterviewForm({
  interview,
  onCancel,
  onSave,
  onBack,
  zoomEnabled,
  googleCalendarEnabled,
  coderpadEnabled,
}: EditScheduledInterviewFormProps) {
  const defaultDescription = interview.description
    ? parseSlateOrLexical(interview.description)
    : LEXICAL_EMPTY_STATE;
  const { control, formState, handleSubmit, getValues, setValue } =
    useForm<InterviewFormData>({
      defaultValues: {
        id: interview.id,
        title: parseSlateLiteOrLexical(interview.title),
        description: defaultDescription,
        videoConferencingSettings: {
          url: interview.videoConferencingUrl ?? undefined,
        },
        collaborativeCodingUrl: interview.collaborativeCodingUrl ?? undefined,
      },
      mode: "onChange",
    });

  const [updateScheduledInterview] = useMutation(UPDATE_SCHEDULED_INTERVIEW);

  const [submitting, setSubmitting] = useState(false);
  const save = useCallback(() => {
    handleSubmit(async (formData) => {
      setSubmitting(true);

      let collaborativeCodingSlot: CollaborativeCodingSlotInput | undefined;

      if (
        formData.collaborativeCodingUrl !== interview.collaborativeCodingUrl
      ) {
        collaborativeCodingSlot = {
          customUrl: formData.collaborativeCodingUrl,
        };
      }

      if (formData.generateCoderpadUrl) {
        collaborativeCodingSlot = {
          generationSettings: {
            service: CollaborativeCodingServiceType.CODERPAD,
          },
        };
      }

      let conferencingSlot: ConferencingSlotInput | undefined;

      if (
        formData.videoConferencingSettings?.url !==
        interview.videoConferencingUrl
      ) {
        conferencingSlot = {
          customUrl: formData.videoConferencingSettings?.url,
        };
      }

      if (formData.videoConferencingSettings?.service) {
        conferencingSlot = {
          generationSettings: {
            service: formData.videoConferencingSettings
              .service as unknown as ConferencingServiceType,
            hostUserMembershipId:
              formData.videoConferencingSettings?.hostUserMembershipId,
          },
        };
      }

      try {
        await updateScheduledInterview({
          variables: {
            input: {
              scheduledInterview: {
                id: formData.id,
                title:
                  JSON.stringify(formData.title) !== interview.title
                    ? JSON.stringify(formData.title)
                    : undefined,
                conferencingSlot,
                collaborativeCodingSlot,
                interviewerConfirmation: {
                  sendNotifications: false,
                },
              },
            },
          },
          onCompleted: () => {
            onSave?.();
          },
        });
      } finally {
        setSubmitting(false);
      }
    })();
  }, [
    handleSubmit,
    interview.collaborativeCodingUrl,
    interview.videoConferencingUrl,
    interview.title,
    updateScheduledInterview,
    onSave,
  ]);

  return (
    <View
      header={{
        leftActions: <BackButton onClick={onBack} />,
        title: "Edit interview details",
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            {onCancel && (
              <Button onClick={onCancel} isGhost>
                Cancel
              </Button>
            )}
            <Button
              onClick={save}
              variant="primary"
              isLoading={submitting}
              disabled={!formState.isValid}
            >
              Save changes
            </Button>
          </ButtonGroup>
        ),
      }}
    >
      <div className="space-y-2">
        <div className="text-body-md-heavy">Title</div>
        <InterviewTitleEditor
          valueSet={{}}
          onChange={(editorState) => {
            setValue("title", editorState.toJSON(), {
              shouldValidate: true,
            });
          }}
          initialState={getValues().title}
        />
      </div>
      <Controller
        name="videoConferencingSettings"
        control={control}
        render={({ field }) => (
          <EditConferencingURLInForm
            value={field.value}
            onChange={field.onChange}
            zoomEnabled={zoomEnabled}
            googleCalendarEnabled={googleCalendarEnabled}
          />
        )}
      />
      <EditCollaborativeCodingUrlInForm
        control={control}
        setValue={setValue}
        getValues={getValues}
        coderpadEnabled={coderpadEnabled}
      />
    </View>
  );
}
