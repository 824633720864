import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { InterviewRequirementForConfigurationFragment } from "generated/graphql-codegen/graphql";

import { EditIndividualInterviewRequirementView } from "./EditIndividualInterviewRequirementView";

/**
 *  A wrapper around EditIndividualInterviewRequirementView component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorEditIndividualInterviewRequirement({
  interviewRequirement,
  schedulingRequestId,
}: {
  interviewRequirement: InterviewRequirementForConfigurationFragment;
  schedulingRequestId: string;
}) {
  const navigator = useGlobalNavigatorOrThrow();

  return (
    <EditIndividualInterviewRequirementView
      interviewRequirement={interviewRequirement}
      schedulingRequestId={schedulingRequestId}
      onBack={navigator.back}
      onCompleted={navigator.back}
    />
  );
}
