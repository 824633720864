import { ZodType } from "zod";

type NotificationTypeDataSchema<T extends ZodType, U extends ZodType> = {
  metadata: T;
  resolved: U;
};

export function createNotificationTypeDataSchema<
  T extends ZodType,
  U extends ZodType
>(props: NotificationTypeDataSchema<T, U>) {
  return props;
}
