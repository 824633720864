import {
  UserConfigurableAlgorithmSettings,
  ValidatedUserConfigurableAlgorithmSettings,
} from "shared/guide-scheduler/algorithm/utils/types";

/**
 * Fill in user-specified algorithm settings with sane defaults and ensure they are not impossible given the number of interviews.
 * Used to ensure the algorithm can run and that users do not end up with invalid settings in the UI.
 * TODO: we can tweak the settings to be more efficient. For example if the min number of interviews per day is 2 and they have 2
 * interviews we infer that the day limit is 1.
 */
export function adjustAlgorithmSettings({
  settings,
  interviewCount,
}: {
  settings: UserConfigurableAlgorithmSettings;
  interviewCount: number;
}): ValidatedUserConfigurableAlgorithmSettings {
  const minInterviewsPerDay = Math.min(
    settings.interviewsPerDayLimit?.min ?? 1,
    interviewCount
  );

  const maxInterviewsPerDay = Math.max(
    // Ensure max <= interviewCount
    Math.min(
      settings.interviewsPerDayLimit?.max ?? interviewCount,
      interviewCount
    ),
    // Ensure max >= min
    minInterviewsPerDay
  );

  const minNumberOfDays = Math.min(
    settings.numberOfDays?.min ?? 1,
    interviewCount
  );

  const maxNumberOfDays = Math.max(
    // Ensure max <= interviewCount
    Math.min(settings.numberOfDays?.max ?? interviewCount, interviewCount),
    // Ensure max >= min
    minNumberOfDays
  );

  return {
    interviewsPerDayLimit: {
      min: minInterviewsPerDay,
      max: maxInterviewsPerDay,
    },
    numberOfDays: {
      min: minNumberOfDays,
      max: maxNumberOfDays,
    },
    preferBackToBackInterviews: settings.preferBackToBackInterviews ?? true,
  };
}

export function adjustOnlyExistingAlgorithmSettings({
  settings,
  interviewCount,
}: {
  settings: UserConfigurableAlgorithmSettings;
  interviewCount: number;
}): UserConfigurableAlgorithmSettings {
  const validatedAlgorithmSettings = adjustAlgorithmSettings({
    settings,
    interviewCount,
  });

  return {
    ...settings,
    // Only set these settings if they already existed
    numberOfDays: settings.numberOfDays
      ? {
          ...settings.numberOfDays,
          ...validatedAlgorithmSettings.numberOfDays,
        }
      : undefined,
    interviewsPerDayLimit: settings.interviewsPerDayLimit
      ? {
          ...settings.interviewsPerDayLimit,
          ...validatedAlgorithmSettings.interviewsPerDayLimit,
        }
      : undefined,
  };
}
