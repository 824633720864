import { Button } from "@resource/atlas/button/Button";
import {
  Dialog,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { InterviewerMultiSelector } from "client/app/(account settings)/tags/__components/InterviewerSelector";
import useLoadExternalInterviewers from "client/scheduler/core/components/external-interviewers-algorithm/hooks/useLoadExternalInterviewers";
import { isStagedScheduledInterview } from "client/scheduler/utils/types";
import { UserForTagSelectionFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo, useState } from "react";

import { useInterviews } from "../../hooks/interviews";

export default function SchedulerExternalInterviewerAlgorithmActions() {
  const { loadExternalInterviewers, loading } = useLoadExternalInterviewers();

  const dialogStore = useDialogStore();
  const handleExcludeAndLoad = useCallback(() => {
    dialogStore.show();
  }, [dialogStore]);

  const interviews = useInterviews();
  const hasSlotsWithExternalAlgorithmEnabled = useMemo(
    () =>
      interviews.some(
        (interview) =>
          isStagedScheduledInterview(interview) &&
          !interview.isCancelled &&
          interview.interviewerSlots?.some(
            (slot) => slot.externalAlgorithmEnabled
          )
      ),
    [interviews]
  );

  const disabledTooltipContent = !hasSlotsWithExternalAlgorithmEnabled
    ? "No staged interviews are configured to use interviewer selection"
    : undefined;

  return (
    <>
      <p className="text-body-md-heavy">Load placeholder interviewers</p>
      <div className="flex gap-1">
        <Tooltip content={disabledTooltipContent}>
          <Button
            disabled={!!disabledTooltipContent}
            isLoading={loading}
            size="small"
            className="w-1/2 rounded-l-md"
            onClick={() => loadExternalInterviewers()}
          >
            Load
          </Button>
        </Tooltip>
        <Tooltip content={disabledTooltipContent}>
          <Button
            disabled={!!disabledTooltipContent}
            isLoading={loading}
            size="small"
            className="w-1/2 rounded-r-md"
            onClick={handleExcludeAndLoad}
          >
            Exclude and load
          </Button>
        </Tooltip>
      </div>
      <ExcludeInterviewersDialog dialogStore={dialogStore} />
    </>
  );
}

function ExcludeInterviewersDialog({
  dialogStore,
}: {
  dialogStore: DialogStore;
}) {
  const { loadExternalInterviewers, loading } = useLoadExternalInterviewers();

  const [excludedInterviewers, setExcludedInterviewers] = useState<
    UserForTagSelectionFragment[]
  >([]);

  const interviewerExcludeEmails = useMemo(() => {
    return excludedInterviewers.map((interviewer) => interviewer.email);
  }, [excludedInterviewers]);

  const handleConfirm = useCallback(() => {
    loadExternalInterviewers({
      interviewerExcludeEmails,
    });
    dialogStore.hide();
  }, [interviewerExcludeEmails, loadExternalInterviewers, dialogStore]);

  return (
    <Dialog store={dialogStore} size="medium">
      <View
        content={{
          title: "Exclude and load placeholder interviewers",
          subTitle: "Select interviewers to exclude.",
        }}
        footer={{
          rightActions: (
            <>
              <Button isGhost onClick={() => dialogStore.hide()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleConfirm}
                isLoading={loading}
              >
                Load interviewers
              </Button>
            </>
          ),
        }}
      >
        <InterviewerMultiSelector
          selected={excludedInterviewers}
          onSelectionChanged={setExcludedInterviewers}
        />
      </View>
    </Dialog>
  );
}
