import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { SortableInterviewCard } from "client/components/interview-requirements/SortableInterviewCard";
import { ResyncJobWithGreenhouseButton } from "client/components/jobs/ResyncJobWithGreenhouseButton";
import {
  filterInterviewRequirements,
  useFilteredInterviewRequirements,
} from "client/utils/interviewRequirements";
import { InterviewRequirementForConfigurationFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";
import { v4 } from "uuid";

import { useInterviewRequirementsConfigurationContext } from "./context";
import { JobSettingsExcludedInterviewRequirementsList } from "./JobSettingsExcludedInterviewRequirementsList";
import {
  RequirementConfigurationInterviewSelect,
  SelectedJobInterviewForRequirementConfiguration,
} from "./RequirementConfigurationInterviewSelect";

type InterviewRequirementsProps = {
  jobId: string;
  atssyncJobStage?: {
    id: string;
    name: string;
  };
  subtext?: string;
};

export function InterviewRequirementsList({
  jobId,
  atssyncJobStage,
  subtext,
}: InterviewRequirementsProps) {
  const {
    interviewRequirements: allInterviewRequirements,
    setInterviewRequirements,
    onEditInterviewRequirement,
  } = useInterviewRequirementsConfigurationContext();

  const { interviewRequirements, removedInterviewRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements: allInterviewRequirements,
    });

  const [, setDraggingId] = useState<string | undefined>();

  const addInterviewAsRequirement = useCallback(
    (interview: SelectedJobInterviewForRequirementConfiguration) => {
      setInterviewRequirements((prevInterviewRequirements) => [
        ...prevInterviewRequirements,
        {
          ...interview.interviewRequirement,
          id: v4(),
          order:
            prevInterviewRequirements.filter((v) => !v.manuallyRemovedAt)
              .length + 1,
        },
      ]);
    },
    [setInterviewRequirements]
  );

  const removeInterviewRequirement = useCallback(
    (
      interviewRequirementToRemove: InterviewRequirementForConfigurationFragment
    ) => {
      setInterviewRequirements((prevInterviewRequirements) =>
        prevInterviewRequirements.map((interviewRequirement) =>
          interviewRequirement.id === interviewRequirementToRemove.id
            ? {
                ...interviewRequirement,
                manuallyRemovedAt: new Date().toISOString(),
              }
            : interviewRequirement
        )
      );
    },
    [setInterviewRequirements]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setDraggingId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setDraggingId(undefined);
      const { active, over } = event;
      if (over && active.id !== over?.id) {
        setInterviewRequirements((prevUnfilteredInterviewRequirements) => {
          const {
            interviewRequirements: prevInterviewRequirements,
            removedInterviewRequirements: prevRemovedInterviewRequirements,
          } = filterInterviewRequirements({
            interviewRequirements: prevUnfilteredInterviewRequirements,
          });
          const oldIndex = prevInterviewRequirements.findIndex(
            ({ id }) => active.id === id
          );
          const newIndex = prevInterviewRequirements.findIndex(
            ({ id }) => over.id === id
          );
          const reordered = arrayMove(
            prevInterviewRequirements,
            oldIndex,
            newIndex
          );

          return [
            ...reordered.map((item, index) => ({
              ...item,
              order: index + 1,
              ...(item.id === active.id ? { orderLocked: true } : {}),
            })),
            ...prevRemovedInterviewRequirements.map((item, index) => ({
              ...item,
              order: index + 1 + reordered.length,
            })),
          ];
        });
      }
    },
    [setInterviewRequirements]
  );

  const setLocked = useCallback(
    (id: string, orderLocked: boolean) => {
      setInterviewRequirements((prevUnfilteredInterviewRequirements) => {
        const {
          interviewRequirements: prevInterviewRequirements,
          removedInterviewRequirements: prevRemovedInterviewRequirements,
        } = filterInterviewRequirements({
          interviewRequirements: prevUnfilteredInterviewRequirements,
        });

        return [
          ...prevInterviewRequirements.map((req, index) => ({
            ...req,
            order: index + 1,
            ...(req.id === id
              ? {
                  orderLocked,
                }
              : {}),
          })),
          ...prevRemovedInterviewRequirements.map((item, index) => ({
            ...item,
            order: index + 1 + prevInterviewRequirements.length,
          })),
        ];
      });
    },
    [setInterviewRequirements]
  );
  return (
    <div className="space-y-3">
      <div className="space-y-[.25rem]">
        <div className="flex w-full justify-between items-center">
          <div className="text-body-md-heavy">Interview requirements</div>
          <ResyncJobWithGreenhouseButton
            jobId={jobId}
            label="Resync interviews"
            size="small"
            negativeMargin="right"
          />
        </div>
        <div className="text-body-md text-subtle">{subtext}</div>
      </div>
      {interviewRequirements.length === 0 && (
        <EmptyStateWrapper>No interviews selected</EmptyStateWrapper>
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={interviewRequirements}
          strategy={verticalListSortingStrategy}
        >
          {interviewRequirements.map((interviewRequirement) => (
            <SortableInterviewCard
              key={interviewRequirement.id}
              interviewRequirement={interviewRequirement}
              allRequirements={interviewRequirements}
              onRemove={() => removeInterviewRequirement(interviewRequirement)}
              onClick={() =>
                onEditInterviewRequirement(interviewRequirement.id)
              }
              onEdit={() => onEditInterviewRequirement(interviewRequirement.id)}
              setLocked={(orderLocked: boolean) =>
                setLocked(interviewRequirement.id, orderLocked)
              }
              showActionMenu
            />
          ))}
        </SortableContext>
      </DndContext>
      {atssyncJobStage && (
        // TODO: Make collapsible
        <JobSettingsExcludedInterviewRequirementsList
          jobInterviewStage={atssyncJobStage}
          interviewRequirements={removedInterviewRequirements}
          setInterviewRequirements={setInterviewRequirements}
        />
      )}
      <RequirementConfigurationInterviewSelect
        onSelect={addInterviewAsRequirement}
        jobId={jobId}
      />
    </div>
  );
}
