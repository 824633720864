import { Interval } from "luxon";
import {
  DragEvent as ReactDragEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  useCalculateOffsetFromEvent,
  useCalculateTimeForOffset,
} from "../../../hooks/callbacks";
import { useWrapperRef } from "../../../hooks/refs";
import { useCalendarSizes } from "../../../hooks/sizing";
import { CalendarEvent } from "../../../utils/types";
import { EventBackgroundProps } from "../EventBackground";
import { EventDragInteractionProps } from "../EventDragInteraction";

type EventInteractionProps = {
  eventProps: Omit<EventBackgroundProps, "event">;
  eventDragInteractionProps: EventDragInteractionProps;
  draggingEvent: CalendarEvent | null;
};

export function useInteractiveEventProps({
  event,
  eventProps = {},
}: {
  event: CalendarEvent;
  eventProps?: Omit<EventBackgroundProps, "event">;
}): EventInteractionProps {
  const calculateTimeForOffset = useCalculateTimeForOffset();
  const calculateOffsetFromEvent = useCalculateOffsetFromEvent();
  const wrapperRef = useWrapperRef();

  const calendarSizes = useCalendarSizes();
  const eventHeight = useMemo(() => {
    const durationInMinutes = event.endTime.diff(event.startTime).as("minutes");
    const heightInPx = (durationInMinutes / 60) * calendarSizes.hourHeight.px;
    return heightInPx;
  }, [calendarSizes.hourHeight.px, event.endTime, event.startTime]);
  // This is used to calculate the y offset of the mouse within the event when we start dragging
  // Without this, the top of the event will jump down to where the mouse is when we start dragging
  const [mouseDistanceFromTopOfEvent, setMouseDistanceFromTopOfEvent] =
    useState(0);
  const [dragStartPosition, setDragStartPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [draggingEvent, setDraggingEvent] = useState<CalendarEvent | null>(
    null
  );
  const dragHandle = useRef<HTMLDivElement | null>(null);
  const dragHandleImage = useRef<HTMLDivElement | null>(null);

  const handleAutoScroll = useCallback(
    (
      e: { clientY: number },
      opts: { topPadding: number; bottomPadding: number } = {
        topPadding: 0,
        bottomPadding: 0,
      }
    ) => {
      const MOUSE_SCROLL_THRESHOLD = 50;
      // no idea why this need to be different
      const TOP_SCROLL_SPEED = 1;
      const BOTTOM_SCROLL_SPEED = 1.5;

      if (wrapperRef) {
        // Automatically scroll as we drag
        if (
          e.clientY >
          wrapperRef.getBoundingClientRect().bottom -
            MOUSE_SCROLL_THRESHOLD -
            opts.bottomPadding
        ) {
          wrapperRef.scrollTop += BOTTOM_SCROLL_SPEED;
        }

        if (
          e.clientY <
          wrapperRef.getBoundingClientRect().top +
            MOUSE_SCROLL_THRESHOLD +
            calendarSizes.allDayEventsViewHeight.px +
            opts.topPadding
        ) {
          wrapperRef.scrollTop -= TOP_SCROLL_SPEED;
        }
      }
    },
    [calendarSizes.allDayEventsViewHeight.px, wrapperRef]
  );

  const dragBottomStart = useCallback(
    (e: ReactDragEvent) => {
      e.dataTransfer.setDragImage(dragHandleImage.current ?? new Image(), 0, 0);
      setDraggingEvent({ ...event });
    },
    [event]
  );

  const dragBottom = useCallback(
    (e: ReactDragEvent) => {
      const { clientX, clientY } = e;
      // This seems to happen right at end. Probably better way to detect this
      if (clientX === 0 && clientY === 0) {
        return;
      }

      const { x, y } = calculateOffsetFromEvent(e);

      const newEndTime = calculateTimeForOffset({
        x, // TODO: use current time instead
        y,
      });

      if (newEndTime) {
        // Don't allow dragging over multiple days
        const updatedEndTime = newEndTime.set({
          day: event.startTime.day,
          month: event.startTime.month,
          year: event.startTime.year,
        });

        setDraggingEvent((prev) => {
          if (!prev) {
            return null;
          }

          return {
            ...prev,
            endTime: updatedEndTime,
          };
        });
      }

      handleAutoScroll(e);
    },
    [
      calculateOffsetFromEvent,
      calculateTimeForOffset,
      event.startTime.day,
      event.startTime.month,
      event.startTime.year,
      handleAutoScroll,
    ]
  );

  const dragStart = useCallback(
    (e: ReactDragEvent) => {
      // Hack; we don't really want the dragHandle here, just any invisible element in order to hide the default drag image
      e.dataTransfer?.setDragImage(
        dragHandleImage.current ?? new Image(),
        0,
        0
      );

      setDragStartPosition({
        x: e.clientX,
        y: e.clientY,
      });
      setDraggingEvent({ ...event });
    },
    [event]
  );

  const dragEnd = useCallback(() => {
    if (draggingEvent && event?.onEdit) {
      if (!draggingEvent.startTime || !draggingEvent.endTime) {
        console.warn("Dragging event missing start or end time");
        return;
      }

      if (
        !Interval.fromDateTimes(draggingEvent.startTime, draggingEvent.endTime)
          .isValid
      ) {
        console.warn("Dragging event has invalid interval");
        return;
      }

      event.onEdit({
        startTime: draggingEvent.startTime,
        endTime: draggingEvent.endTime,
      });
    }

    setDragStartPosition(null);
    setDraggingEvent(null);
  }, [draggingEvent, event]);

  const drag = useCallback(
    (e: ReactDragEvent) => {
      if (!dragStartPosition) {
        return;
      }

      // Check for invalid coordinates that occur at drag end
      if (e.clientX <= 0 && e.clientY <= 0) {
        return;
      }

      const offset = calculateOffsetFromEvent(e);
      const offsetY = offset.y - mouseDistanceFromTopOfEvent;
      const draggedTime = calculateTimeForOffset({
        ...offset,
        y: offsetY > 0 ? offsetY : 0,
      });

      if (
        draggedTime &&
        draggedTime.startOf("day").equals(
          draggedTime
            .plus({
              minutes: event.endTime.diff(event.startTime).as("minutes"),
            })
            .startOf("day")
        )
      ) {
        setDraggingEvent((prev) => {
          if (!prev) {
            return null;
          }

          return {
            ...prev,
            startTime: draggedTime,
            endTime: draggedTime.plus({
              minutes: prev.endTime.diff(prev.startTime).as("minutes"),
            }),
          };
        });
      }

      handleAutoScroll(e, {
        topPadding: mouseDistanceFromTopOfEvent,
        bottomPadding: eventHeight - mouseDistanceFromTopOfEvent,
      });
    },
    [
      calculateOffsetFromEvent,
      calculateTimeForOffset,
      dragStartPosition,
      event.endTime,
      event.startTime,
      eventHeight,
      handleAutoScroll,
      mouseDistanceFromTopOfEvent,
    ]
  );

  const onMouseDown = useCallback(({ yOffset }: { yOffset: number }) => {
    setMouseDistanceFromTopOfEvent(yOffset);
  }, []);

  return useMemo(
    (): EventInteractionProps => ({
      eventProps: {
        ...eventProps,
        draggable: true,
        onDragEnd: dragEnd,
        onDragStart: dragStart,
        onDrag: drag,
        onMouseDown,
        style: {
          ...eventProps.style,
          ...(draggingEvent
            ? {
                marginRight: 0,
              }
            : {}),
        },
      },
      eventDragInteractionProps: {
        dragHandleImage,
        dragHandle,
        dragBottomStart,
        dragBottom,
        dragEnd,
      },
      draggingEvent,
    }),
    [
      eventProps,
      dragStart,
      dragEnd,
      drag,
      onMouseDown,
      draggingEvent,
      dragBottomStart,
      dragBottom,
    ]
  );
}
