import { useApolloClient, useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";

const SCHEDULED_INTERVIEW_UPDATED_SUBSCRIPTION = gql(`
  subscription ScheduledInterviewUpdated($scheduledInterviewId: ID!) {
    scheduledInterviewByIdUpdated(scheduledInterviewId: $scheduledInterviewId) {
      ...ScheduledInterviewForScheduledInterviewCard
    }
  }
`);

export function useSubscribeToScheduledInterviewCardChanges(
  scheduledInterviewId: string
) {
  const client = useApolloClient();

  useSubscription(SCHEDULED_INTERVIEW_UPDATED_SUBSCRIPTION, {
    variables: { scheduledInterviewId },
    skip: !scheduledInterviewId,
    onSubscriptionData: ({ subscriptionData }) => {
      const updatedInterview =
        subscriptionData.data?.scheduledInterviewByIdUpdated;

      if (updatedInterview && updatedInterview.id === scheduledInterviewId) {
        client.cache.modify({
          id: client.cache.identify({
            __typename: "ScheduledInterview",
            id: scheduledInterviewId,
          }),
          fields: {
            title: () => updatedInterview.title,
            startTime: () => updatedInterview.startTime,
            endTime: () => updatedInterview.endTime,
            videoConferencingUrl: () => updatedInterview.videoConferencingUrl,
            conferencePhone: () => updatedInterview.conferencePhone,
            collaborativeCodingUrl: () =>
              updatedInterview.collaborativeCodingUrl,
            googleEventId: () => updatedInterview.googleEventId,
            hidden: () => updatedInterview.hidden,
            isCancelled: () => updatedInterview.isCancelled,
            isPrivate: () => updatedInterview.isPrivate,
            blacklisted: () => updatedInterview.blacklisted,
            interviewRequirement: () => updatedInterview.interviewRequirement,
            interviewers: () => updatedInterview.interviewers,
            pendingRescheduleInterviewRequirement: () =>
              updatedInterview.pendingRescheduleInterviewRequirement,
            greenhouseSyncStatus: () => updatedInterview.greenhouseSyncStatus,
            googleSyncStatus: () => updatedInterview.googleSyncStatus,
            googleEvent: () => updatedInterview.googleEvent,
            atssyncScheduledInterview: () =>
              updatedInterview.atssyncScheduledInterview,
          },
        });
      }
    },
  });
}
