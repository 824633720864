import { useMemo } from "react";

import {
  ScheduledInterviewForStatuses,
  useScheduledInterviewStatuses,
} from "../../__hooks/useStatuses";
import { ScheduledInterviewCardProps } from "..";

export function useInternalScheduledInterviewCardStatuses({
  scheduledInterview,
}: {
  scheduledInterview: ScheduledInterviewForStatuses;
}) {
  const statuses = useScheduledInterviewStatuses({
    scheduledInterview,
  });

  return useMemo((): ScheduledInterviewCardProps["statuses"] => {
    return statuses.map(
      (
        status
      ): NonNullable<ScheduledInterviewCardProps["statuses"]>[number] => ({
        icon: status.icon,
        tooltip: status.message,
      })
    );
  }, [statuses]);
}
