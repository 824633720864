import { Button } from "@resource/atlas/button/Button";
import {
  atlasEllipsisHorizontal,
  atlasOperatorOr,
  atlasRefreshCcw,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { useEditProfileMenuItem } from "components/EditPersonalProfile/__hooks/useEditProfileMenuItem";
import { useEditPreferencesMenuItem } from "components/EditPreferences/__hooks/useEditPreferencesMenuItem";
import { useRemoveInterviewerMenuItem } from "components/EditPreferences/__hooks/useRemoveInterviewerMenuItem";
import { InterviewerSlotType } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { EditInterviewerSlotDisplayState } from "../hooks/useEditInterviewerSlotDisplayState";
import { InterviewerSlotTagFilter } from "../utils/types";

type EditSelectedInterviewerRightActionsProps = {
  state: EditInterviewerSlotDisplayState;
  isShadower?: boolean;
  allowSwapping?: boolean;
  hideMenu?: boolean;
  alternatesOptions?: {
    hasAlternates: boolean;
    isOpen: boolean;
    toggleOpen: () => void;
  };
  poolId?: string;
  tagFilters?: InterviewerSlotTagFilter[] | null;
};

/**
 * Display right actions for selected interviewers while editing.
 * Used for both interviewers and shadowers,
 * and for both person and pool configurations.
 */
export function EditSelectedInterviewerRightActions({
  state,
  isShadower,
  allowSwapping,
  hideMenu,
  alternatesOptions,
  poolId,
}: EditSelectedInterviewerRightActionsProps) {
  const {
    allMembershipIdsFromInterviewSlots,
    size,
    onInterviewerProfileChange,
    onInterviewerSchedulingPreferencesChange,
    UserMembershipSelect,
    getInterviewerState,
    onRemoveInterviewerSlot,
    interviewerSlot: { tagFilters, type },
  } = state;
  const { interviewer, onSelectUser, onRemoveUser } = getInterviewerState({
    isShadower,
  });
  const removeSlotOnRemove = useMemo(() => {
    return type === InterviewerSlotType.PERSON && !isShadower;
  }, [type, isShadower]);

  const editProfileMenuItem = useEditProfileMenuItem({
    userMembershipId: interviewer?.userMembership.id,
    onCompleted: onInterviewerProfileChange,
  });
  const editSchedulingSettingsMenuItem = useEditPreferencesMenuItem({
    userMembershipId: interviewer?.userMembership.id,
    onCompleted: onInterviewerSchedulingPreferencesChange,
  });
  const removeInterviewerMenuItem = useRemoveInterviewerMenuItem({
    removeSlotOnRemove,
    isShadower,
    onRemoveInterviewerSlot,
    onRemoveUser,
    userMembershipId: interviewer?.userMembership.id,
  });

  const menuItems = useMenuItems(
    (i) => {
      const items = [];

      const editProfileItem = editProfileMenuItem.createItem(i);
      const editSchedulingSettingsItem =
        editSchedulingSettingsMenuItem.createItem(i);
      const removeInterviewerItem = removeInterviewerMenuItem.createItem(i);

      if (editProfileItem) items.push(editProfileItem);
      if (editSchedulingSettingsItem) items.push(editSchedulingSettingsItem);
      if (removeInterviewerItem) {
        items.push(i.separator({ key: "separator" }));
        items.push(removeInterviewerItem);
      }

      return items;
    },
    [
      editProfileMenuItem,
      editSchedulingSettingsMenuItem,
      removeInterviewerMenuItem,
    ]
  );

  return (
    <div
      className={clsx("flex", {
        "space-x-[2px]": size === "small",
        "space-x-2": size === "medium",
      })}
    >
      {allowSwapping && poolId && (
        <UserMembershipSelect
          searchPlaceholderText="Search by name or email"
          onSelect={onSelectUser}
          isQualified={!isShadower}
          poolId={poolId}
          tagFilters={tagFilters}
          excludeUserMembershipIds={allMembershipIdsFromInterviewSlots}
          Trigger={
            <Button
              icon={atlasRefreshCcw}
              size={size === "small" ? "xs" : "small"}
              isGhost
              data-cy={CypressData.editInterviewerSlotDisplay.swapButton(
                interviewer?.userMembership.id
              )}
            />
          }
        />
      )}
      {allowSwapping && !poolId && (
        <UserMembershipSelect
          searchPlaceholderText="Search by name or email"
          onSelect={onSelectUser}
          excludeUserMembershipIds={allMembershipIdsFromInterviewSlots}
          tagFilters={tagFilters}
          Trigger={
            <Button
              icon={atlasRefreshCcw}
              size={size === "small" ? "xs" : "small"}
              isGhost
              data-cy={CypressData.editInterviewerSlotDisplay.swapButton(
                interviewer?.userMembership.id
              )}
            />
          }
        />
      )}
      {alternatesOptions?.hasAlternates && (
        <Button
          onClick={alternatesOptions.toggleOpen}
          isActive={alternatesOptions.isOpen}
          icon={atlasOperatorOr}
          size={size === "small" ? "xs" : "small"}
          isGhost
          data-cy={CypressData.editInterviewerSlotDisplay.alternateButton(
            interviewer?.userMembership.id
          )}
        />
      )}
      {!!menuItems.length && !hideMenu && (
        <Menu.Root>
          <Menu.Trigger>
            <Button
              icon={atlasEllipsisHorizontal}
              size={size === "small" ? "xs" : "small"}
              isGhost
              data-cy={CypressData.editInterviewerSlotDisplay.menuButton(
                interviewer?.userMembership?.id
              )}
            />
          </Menu.Trigger>
          <Menu.Content items={menuItems} />
        </Menu.Root>
      )}
      {editProfileMenuItem.render()}
      {editSchedulingSettingsMenuItem.render()}
    </div>
  );
}
