import { AtlasAvatarProps } from "@resource/atlas/avatar/types";
import { InterviewerResponse } from "generated/graphql-codegen/graphql";

export function getAvatarStatusFromInterviewerResponseStatus(
  responseStatus: InterviewerResponse | null
): AtlasAvatarProps["status"] {
  switch (responseStatus) {
    case "ACCEPTED":
      return "accepted";
    case "DECLINED":
      return "declined";
    case "TENTATIVE":
      return "maybe";
    case "NEEDS_ACTION":
      return "waiting";
    default:
      return undefined;
  }
}
