import {
  Dialog,
  DialogProps,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { atlasClose } from "@resource/atlas/icons";
import {
  UpdateSchedulingSettingsConfirmationDialog,
  UpdateSchedulingSettingsConfirmationDialogProps,
} from "client/app/extension/scheduling-request/create/__components__/UpdateSchedulingSettingsConfirmationDialog";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { SchedulingTaskAndAvailabilityWorkflowSubmitMutation } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";

import { WorkflowSubmitOnCompleted } from "./hooks/useOnWorkflowSubmit";
import {
  SchedulingTaskAndAvailabilityWorkflows,
  SchedulingTaskAndAvailabilityWorkflowsProps,
} from "./SchedulingTaskAndAvailabilityWorkflows";

export type SchedulingTaskAndAvailabilityWorkflowsDialogProps = {
  store: DialogStore;
} & Pick<DialogProps, "variant" | "size"> &
  Omit<SchedulingTaskAndAvailabilityWorkflowsProps, "onBack" | "onCompleted">;

export function SchedulingTaskAndAvailabilityWorkflowsDialog({
  store,
  variant,
  size,
  ...workflowProps
}: SchedulingTaskAndAvailabilityWorkflowsDialogProps) {
  const globalNavigator = useGlobalNavigator();
  const [
    updateSchedulingSettingsDialogProps,
    setUpdateSchedulingSettingsDialogProps,
  ] = useState<Pick<
    UpdateSchedulingSettingsConfirmationDialogProps,
    | "templateId"
    | "interviewRequirements"
    | "job"
    | "interviewPanelRequirement"
    | "jobInterviewPanelRequirementId"
  > | null>(null);
  const [mutationResult, setMutationResult] =
    useState<SchedulingTaskAndAvailabilityWorkflowSubmitMutation | null>(null);
  const updateSchedulingSettingsConfirmationDialogStore = useDialogStore();
  const onCompletedNavigation = useCallback(
    (result: SchedulingTaskAndAvailabilityWorkflowSubmitMutation) => {
      const createdSchedulingRequest =
        result.schedulingTaskAndAvailabilityWorkflows.schedulingRequest;
      const createdReschedulingRequests =
        result.schedulingTaskAndAvailabilityWorkflows.reschedulingRequests;
      const firstReschedulingRequest = createdReschedulingRequests?.[0];
      const schedulingRequestForNavigation =
        createdSchedulingRequest ?? firstReschedulingRequest;

      store.hide();

      if (schedulingRequestForNavigation) {
        if (globalNavigator) {
          globalNavigator.navigate("schedulingTaskDetails", {
            schedulingRequestId: schedulingRequestForNavigation.id,
          });
        } else {
          setTimeout(() => {
            window.open(
              `/scheduling-requests?selectedSchedulingRequestId=${schedulingRequestForNavigation.id}`,
              "_blank"
            );
          }, 500);
        }
      }
    },
    [globalNavigator, store]
  );
  const onCompleted: WorkflowSubmitOnCompleted = useCallback(
    (result, additionalWorkflowCompletedData) => {
      const createdSchedulingRequest =
        result.schedulingTaskAndAvailabilityWorkflows.schedulingRequest;

      if (
        additionalWorkflowCompletedData?.requirementsHaveChanged &&
        createdSchedulingRequest
      ) {
        const {
          jobInterviewPanelRequirementId,
          schedulingRequestTemplateId,
          newInterviewRequirements,
          newInterviewPanelRequirement,
        } = additionalWorkflowCompletedData;

        setUpdateSchedulingSettingsDialogProps({
          jobInterviewPanelRequirementId,
          interviewPanelRequirement: newInterviewPanelRequirement,
          interviewRequirements: newInterviewRequirements,
          templateId: schedulingRequestTemplateId,
          job: {
            id: createdSchedulingRequest.guide.job.id,
            name: createdSchedulingRequest.guide.job.internalName,
          },
        });
        setMutationResult(result);
        updateSchedulingSettingsConfirmationDialogStore.show();
      } else {
        onCompletedNavigation(result);
      }
    },
    [onCompletedNavigation, updateSchedulingSettingsConfirmationDialogStore]
  );
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
  });

  return (
    <>
      <Dialog {...dialogProps} store={store} variant={variant} size={size}>
        <SchedulingTaskAndAvailabilityWorkflows
          {...workflowProps}
          onBack={store.hide}
          onCompleted={onCompleted}
          backIcon={atlasClose}
        />
        <WarningDialog />
      </Dialog>
      {updateSchedulingSettingsDialogProps && mutationResult && (
        <UpdateSchedulingSettingsConfirmationDialog
          {...updateSchedulingSettingsDialogProps}
          onCompleted={() => onCompletedNavigation(mutationResult)}
          store={updateSchedulingSettingsConfirmationDialogStore}
        />
      )}
    </>
  );
}
