import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { getAtssyncInfo } from "client/utils/atssync";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { AtssyncAccountType } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";
import { getTitleFromLexicalJson } from "shared/utils/lexical";
import useQuery from "utils/useQuery";

import { InterviewTitle } from "../guide/interviews/InterviewTitle/InterviewTitle";
import { useForceResyncJobOnDialogOpen } from "../jobs/hooks/useForceResyncJobOnDialogOpen";

const GET_INTERVIEWS_FOR_JOB = gql(`
  query InterviewsForSelectJobInterviewDialog($jobId: String!) {
    fetchInterviewsForJob(jobId: $jobId, includeBlacklisted: true) {
      id
      title
      jobInterviewStage {
        id
        name
        position
      }
      interviewRequirement {
        id
      }
    }
  }
`);

type SelectJobInterviewDialogProps = {
  store: DialogProps["store"];
  jobId: string;
  onSubmit: (selectedInterviewId: string) => void | Promise<void>;
};

export function SelectJobInterviewDialog({
  jobId,
  onSubmit: passedOnSubmit,
  store,
  ...dialogProps
}: SelectJobInterviewDialogProps) {
  return (
    <Dialog {...dialogProps} store={store} size="medium">
      <SelectJobInterviewDialogQuery
        jobId={jobId}
        onSubmit={passedOnSubmit}
        store={store}
      />
    </Dialog>
  );
}

function SelectJobInterviewDialogQuery({
  jobId,
  onSubmit: passedOnSubmit,
  store,
}: SelectJobInterviewDialogProps) {
  // TODO: Infer integration type from account. Cannot rely on atssyncScheduledInterview connection as it could be null
  const atssyncAccountType = AtssyncAccountType.GREENHOUSE;
  const [submitLoading, setSubmitLoading] = useState(false);
  const atssyncInfo = getAtssyncInfo(atssyncAccountType);
  const {
    data,
    loading: queryLoading,
    error,
    refetch,
  } = useQuery(GET_INTERVIEWS_FOR_JOB, {
    variables: { jobId },
  });

  useForceResyncJobOnDialogOpen({
    jobId,
    refetch,
    store,
  });

  const [selectedInterviewId, setSelectedInterviewId] = useState<string | null>(
    null
  );

  const onSubmit = useCallback(async () => {
    if (selectedInterviewId) {
      setSubmitLoading(true);

      await passedOnSubmit(selectedInterviewId);

      setSubmitLoading(false);
    }
  }, [passedOnSubmit, selectedInterviewId]);

  const interviews = data?.fetchInterviewsForJob ?? [];

  if (queryLoading) {
    return <DialogLoading />;
  }

  if (error || interviews.length === 0) {
    return (
      <DialogError
        error={error}
        errorCode={interviews.length === 0 ? "404" : "500"}
      />
    );
  }

  // Sort interviews by jobInterviewStage.position
  const sortedInterviews = [...interviews].sort(
    (a, b) =>
      (a.jobInterviewStage?.position ?? 0) -
      (b.jobInterviewStage?.position ?? 0)
  );

  // Group interviews by stage name and sort alphabetically within each stage
  const groupedInterviews = sortedInterviews.reduce((groups, interview) => {
    const stageName = interview.jobInterviewStage?.name ?? "No stage";

    if (!groups[stageName]) {
      // eslint-disable-next-line no-param-reassign
      groups[stageName] = [];
    }

    groups[stageName].push(interview);

    // Sort interviews within each stage by title
    groups[stageName].sort((a, b) => {
      const titleA = getTitleFromLexicalJson(a.title);
      const titleB = getTitleFromLexicalJson(b.title);
      return titleA.localeCompare(titleB);
    });

    return groups;
  }, {} as { [stageName: string]: (typeof interviews)[0][] });

  return (
    <View
      className="space-y-2"
      content={{
        className: "text-body-md",
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button isGhost onClick={store.hide} disabled={submitLoading}>
              Cancel
            </Button>
            <OptionalTooltip
              content={
                !selectedInterviewId ? "Please select an interview." : undefined
              }
              isInstant
            >
              <Button
                onClick={onSubmit}
                disabled={!selectedInterviewId}
                variant="primary"
                isLoading={submitLoading}
              >
                Select interview
              </Button>
            </OptionalTooltip>
          </ButtonGroup>
        ),
      }}
    >
      <div className="space-y-4">
        <div className="space-y-2">
          <div className="text-h3">
            Link Guide interview to {atssyncInfo.name} interview
          </div>
          <div>
            Link to the appropriate {atssyncInfo.name} interview to enable
            accurate scorecard submission by interviewers.
          </div>
        </div>
        {Object.entries(groupedInterviews).map(
          ([stageName, stageInterviews]) => (
            <div key={stageName} className="space-y-2">
              <div className="text-body-md-heavy">{stageName}</div>
              {stageInterviews.map((interview) => (
                <OptionalTooltip
                  key={interview.id}
                  content={
                    !interview.interviewRequirement
                      ? "Interview must be configured in your job settings."
                      : undefined
                  }
                >
                  <RadioItem
                    label={<InterviewTitle interview={interview} />}
                    isSelected={selectedInterviewId === interview.id}
                    onClick={() => setSelectedInterviewId(interview.id)}
                    disabled={!interview.interviewRequirement}
                  />
                </OptionalTooltip>
              ))}
            </div>
          )
        )}
      </div>
    </View>
  );
}
