import { gql } from "generated/graphql-codegen";
import { useCallback, useMemo } from "react";
import useQuery from "utils/useQuery";

const FETCH_SCHEDULED_INTERVIEW_ACTIVITY = gql(`
query ScheduledInterviewActivity($id: String!, $cursor: String) {
  scheduledInterviewById(id: $id) {
    id
    activity (cursor: $cursor) {
      ...ActivityForFeed
    }
  }
}
`);
export function useScheduledInterviewActivity(id: string) {
  const { data, loading, fetchMore, ...queryResult } = useQuery(
    FETCH_SCHEDULED_INTERVIEW_ACTIVITY,
    {
      variables: {
        id,
        cursor: null,
      },
    }
  );

  const activity = useMemo(
    () => data?.scheduledInterviewById?.activity ?? [],
    [data]
  );

  const nextPage = useCallback(() => {
    if (activity.length && !loading) {
      fetchMore({
        variables: {
          id,
          cursor: activity[activity.length - 1].eventTime,
        },
      });
    }
  }, [activity, fetchMore, id, loading]);

  return {
    ...queryResult,
    activity,
    nextPage,
    loading,
  };
}
