import { Button } from "@resource/atlas/button/Button";
import { Link } from "@resource/atlas/link/Link";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { useSchedulingTaskAndAvailabilityWorkflowsNavigation } from "client/app/(scheduling requests)/hooks/useSchedulingTaskAndAvailabilityWorkflowsNavigation";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { CancelInterviewsConfirmationDialog } from "client/components/scheduled-interviews/__components/CancelInterviewsConfirmation";
import { useCancelInterviewsConfirmationDialogProps } from "client/components/scheduled-interviews/__hooks/useCancelInterviewsConfirmationDialogProps";
import { InternalScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/InternalScheduledInterviewCard";
import { SchedulingCompletionPromptsDialog } from "client/components/scheduling-completion/__deprecated/SchedulingCompletionPromptsDialog";
import { SchedulingTaskAndAvailabilityWorkflowsDialog } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  GuideManagerInterviewsGuideFragment,
  OrganizationFeaturesEnum,
} from "generated/graphql-codegen/graphql";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  LegacyRef,
  useCallback,
  useMemo,
} from "react";

type UpcomingInterviewProps = ComponentPropsWithoutRef<"section"> & {
  onViewAll: () => void;
  onClickScheduledInterview: (props: { scheduledInterviewId: string }) => void;
  guide: GuideManagerInterviewsGuideFragment;
};

gql(`
fragment GuideManagerInterviewsGuide on Guide {
    id
    internalUpcomingInterviews {
      id
      ...ScheduledInterviewForScheduledInterviewCard
    }
  }
`);

function UpcomingInterviewsWithoutRef({
  guide,
  onViewAll,
  onClickScheduledInterview,
  ...props
}: UpcomingInterviewProps) {
  const { hasFeatureEnabled } = useAuthContext();
  const isSchedulingEnabled = useMemo(
    () => hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING),
    [hasFeatureEnabled]
  );
  const logEvent = useLogEvent({
    component: "NextInterview",
    project: "Zeus",
  });

  const { onClick: onReschedule, dialogProps } =
    useSchedulingTaskAndAvailabilityWorkflowsNavigation({
      params: {
        guideId: guide.id,
        defaultSchedulingRequestFormType: "rescheduling",
        skipRequestingAvailability: true,
      },
      analytics: {
        component: "UpcomingInterviews",
      },
    });

  const { internalUpcomingInterviews } = guide;
  const reschedulableInternalUpcomingInterviews = useMemo(
    () => internalUpcomingInterviews.filter((x) => !!x.interviewRequirementId),
    [internalUpcomingInterviews]
  );

  const {
    onClick: onCancel,
    cancellableInterviews,
    dialogProps: cancelInterviewsDialogProps,
    completionPromptsProps,
  } = useCancelInterviewsConfirmationDialogProps({
    interviews: internalUpcomingInterviews,
  });

  const onInternalInterviewClick = useCallback(
    (interviewId: string) => () => {
      onClickScheduledInterview({
        scheduledInterviewId: interviewId,
      });
    },
    [onClickScheduledInterview]
  );

  return (
    <section {...props} className={clsx("space-y-3", props.className)}>
      <div className="flex justify-between mb-2 items-center">
        <p className="text-h4">Upcoming interviews</p>
        <Button
          className="cursor-pointer"
          as="a"
          variant="primary"
          isGhost
          size="xs"
          onClick={() => {
            onViewAll();
            logEvent("View All Interviews Clicked", {
              guideId: guide.id,
            });
          }}
        >
          View all
        </Button>
      </div>
      {internalUpcomingInterviews.length ? (
        internalUpcomingInterviews.map((interview) => (
          <div key={interview.id}>
            <InternalScheduledInterviewCard
              scheduledInterview={interview}
              onClick={onInternalInterviewClick(interview.id)}
            />
          </div>
        ))
      ) : (
        <EmptyStateWrapper>No upcoming interviews</EmptyStateWrapper>
      )}
      {isSchedulingEnabled &&
        (reschedulableInternalUpcomingInterviews.length > 0 ||
          cancellableInterviews.length > 0) && (
          <div className="space-x-2">
            {reschedulableInternalUpcomingInterviews.length > 0 && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                as="button"
                onClick={() => {
                  onReschedule();
                }}
                className="text-body-md"
              >
                Reschedule interviews
              </Link>
            )}
            {cancellableInterviews.length > 0 && onCancel && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link as="button" onClick={onCancel} className="text-body-md">
                Cancel all interviews
              </Link>
            )}
          </div>
        )}
      <SchedulingTaskAndAvailabilityWorkflowsDialog {...dialogProps} />
      <CancelInterviewsConfirmationDialog {...cancelInterviewsDialogProps} />
      <SchedulingCompletionPromptsDialog {...completionPromptsProps} />
    </section>
  );
}

export const UpcomingInterviews = forwardRef(
  (props: UpcomingInterviewProps, ref?: LegacyRef<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <UpcomingInterviewsWithoutRef {...props} />
      </div>
    );
  }
);
