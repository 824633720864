/* eslint-disable import/prefer-default-export */
import "./node-renderer.sass";

import clsx from "clsx";

import { createComponentUtils } from "../../../__utils/atlas";
import { createNodeRenderer } from "../../renderer";
import { CHARACTERS_THRESHOLD, SerializedRichQuoteNode } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-RichQuoteDisplay";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// node renderer
// -------------

export const renderRichQuoteNode = createNodeRenderer<SerializedRichQuoteNode>(
  ({ data: { text, attribution } }) => {
    const longText = text.length >= CHARACTERS_THRESHOLD;
    return (
      <div className={ROOT}>
        <p className={clsx("text", longText && "long")}>{text}</p>
        {attribution && <p className="attribution">{attribution}</p>}
      </div>
    );
  }
);
