import { useAuthContext } from "auth/context";
import { gql } from "generated/graphql-codegen";
import {
  ActivityForFeedFragment,
  ActivityVerb,
  FetchSchedulingRequestActivityDocument,
} from "generated/graphql-codegen/graphql";
import { uniqBy } from "lodash";
import { forwardRef, useCallback } from "react";
import useMutation from "utils/useMutation";
import { v4 } from "uuid";

import { CommentFormData, useCommentState } from "./__hooks/useCommentState";
import { CommentFooter } from "./CommentFooter";

const CREATE_COMMENT = gql(`
  mutation CreateCommentFromSchedulingTaskFooter($input: CreateCommentForSchedulingTaskInput!) {
    createCommentForSchedulingTask(input: $input) {
      comment {
        id
        message
        schedulingRequestId
      }
    }
  }
`);

export const SchedulingTaskCommentFooter = forwardRef<
  HTMLDivElement,
  {
    schedulingTaskId: string;
    onComment?: () => void;
  }
>(({ schedulingTaskId, onComment }, ref) => {
  const authContext = useAuthContext();
  const [createComment] = useMutation(CREATE_COMMENT, {
    onCompleted: () => {
      onComment?.();
    },
    update: (cache, { data }) => {
      cache.updateQuery(
        {
          query: FetchSchedulingRequestActivityDocument,
          variables: {
            schedulingRequestId: schedulingTaskId,
            cursor: null,
          },
        },
        (existingData) => {
          if (
            existingData &&
            existingData.schedulingRequestById &&
            authContext.user?.currentUserMembership
          ) {
            const newActivity: ActivityForFeedFragment = {
              __typename: "Activity",
              actor: {
                __typename: "UserMembership",
                ...authContext.user.currentUserMembership,
              },
              eventTime: new Date().toISOString(),
              id: v4(),
              verb: ActivityVerb.CommentCreated,
              schedulingRequestId: schedulingTaskId,
              scheduledInterviewId: null,
              userMembership: null,
              guidePostId: null,
              interviewerPool: null,
              commentId:
                data?.createCommentForSchedulingTask?.comment?.id ?? null,
              meta: {
                message: data?.createCommentForSchedulingTask?.comment?.message,
              },
              scheduledInterview: null,
              automatedActionId: null,
            };

            return {
              ...existingData,
              schedulingRequestById: {
                ...existingData.schedulingRequestById,
                activity: uniqBy(
                  [newActivity, ...existingData.schedulingRequestById.activity],
                  (a) => a.commentId ?? a.id
                ),
              },
            };
          }

          return null;
        }
      );
    },
  });
  const onSubmit = useCallback(
    async (data: CommentFormData) => {
      await createComment({
        variables: {
          input: {
            message: data.message,
            schedulingRequestId: schedulingTaskId,
          },
        },
      });
    },
    [createComment, schedulingTaskId]
  );
  const state = useCommentState({
    onSubmit,
  });

  return <CommentFooter state={state} ref={ref} />;
});
