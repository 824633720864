import {
  AtlasContentEditorProps,
  ContentEditorLite,
} from "@resource/atlas/content-editor";
import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import {
  ValueSet,
  variablesModule,
} from "@resource/atlas/content-editor/variables";
import { unknownVariablesModule } from "@resource/atlas/content-editor/variables/__lib/module";
import ErrorBoundary from "components/ErrorBoundary";
import {
  scheduledInterviewDetailsSpecSet,
  ScheduledInterviewDetailsVariableSet,
} from "shared/guide-scheduler/scheduled-interview-details/variables";

export type ScheduledInterviewDetailsLiteEditorProps =
  AtlasContentEditorProps & {
    valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
  };

export function ScheduledInterviewDetailsLiteEditor({
  valueSet,
  modules = [],
  ...props
}: ScheduledInterviewDetailsLiteEditorProps) {
  return (
    <ErrorBoundary>
      <ContentEditorLite
        {...props}
        placeholder="Add a subject"
        modules={[
          withConfig(variablesModule, {
            specSet: scheduledInterviewDetailsSpecSet,
            valueSet,
            undefinedMode: "placeholder",
          }),
          unknownVariablesModule,
          ...modules,
        ]}
      />
    </ErrorBoundary>
  );
}
