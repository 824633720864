import { ExtensionUpdateBanner } from "client/app/extension/__components/ExtensionUpdateBanner";
import { ViewWithAssistantToggle } from "client/components/generic/layout/ViewWithAssistantToggle";
import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { useCallback } from "react";

import { ExtensionSchedulingRequests } from "./ExtensionSchedulingRequests";

/**
 * A wrapper around the ExtensionSchedulingRequests component that provides navigation via the global navigator
 *
 * Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorExtensionSchedulingRequests() {
  const navigator = useGlobalNavigatorOrThrow();
  const onClickSchedulingRequest = useCallback(
    ({ schedulingRequestId }: { schedulingRequestId: string }) => {
      navigator.navigate("schedulingTaskDetails", {
        schedulingRequestId,
      });
    },
    [navigator]
  );

  // TODO: Move view and header into `ExtensionSchedulingRequests` component once FF is removed.
  // At the moment that component is still used within the tab-list though
  return (
    <ViewWithAssistantToggle
      CustomHeader={
        <div>
          <ExtensionUpdateBanner />
        </div>
      }
      content={{
        className: "p-0",
      }}
    >
      <ExtensionSchedulingRequests
        onClickSchedulingRequest={onClickSchedulingRequest}
      />
    </ViewWithAssistantToggle>
  );
}
