import { CalendarIcon } from "client/components/guide/interviews/CalendarIcon";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { getFormattedDateRange } from "client/utils/dates";
import clsx from "clsx";
import { ScheduleInterviewForInterviewDetailsFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { ScheduledInterviewCardBadge } from "../../__components/ScheduledInterviewCardBadge";

/** Scheduled interview details header information, similar to the scheduled interview card display of time and title */
export function InfoHeader({
  scheduledInterview,
}: {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
}) {
  const interviewDates = useMemo(() => {
    return getFormattedDateRange({
      startTime: scheduledInterview.startTime,
      endTime: scheduledInterview.endTime,
      timezone: getCurrentIanaTimezone(),
    });
  }, [scheduledInterview.startTime, scheduledInterview.endTime]);

  return (
    <div className="w-full flex space-x-4">
      <CalendarIcon
        isInPast={
          DateTime.fromISO(scheduledInterview.startTime) < DateTime.now() ||
          scheduledInterview.isCancelled
        }
        day={interviewDates.formattedDay}
        dayName={interviewDates.formattedDayName}
        size="lg"
      />
      <div className="flex flex-col min-w-0 justify-between">
        <div className="flex flex-row items-center space-x-2">
          <InterviewTitle
            className="text-h2 overflow-ellipsis overflow-hidden"
            interview={{ title: scheduledInterview.title }}
          />
          <div>
            {scheduledInterview.isCancelled && (
              <ScheduledInterviewCardBadge status="cancelled" size="medium" />
            )}
          </div>
        </div>
        <span
          className={clsx("flex items-center text-subtle truncate", {
            "line-through": scheduledInterview.isCancelled,
          })}
          title={interviewDates.formattedDateTime}
        >
          {interviewDates.formattedDateTime}
        </span>
      </div>
    </div>
  );
}
