/* eslint-disable import/prefer-default-export */
import type { ComponentPropsWithoutRef } from "react";

import { Card, CardProps } from "../../../__utils/misc";
import { CardData } from "./misc";

type LinkCardProps = {
  data: CardData;
} & Pick<CardProps, "menuSlot" | "showDescription"> &
  ComponentPropsWithoutRef<"div">;

export function LinkCard({ data, ...props }: LinkCardProps) {
  const label = data.label || data.fetchedLabel;
  const description = data.description || data.fetchedDescription;
  const imageUrl = data.imageUrl || data.fetchedImageUrl;

  return (
    <Card
      url={data.url}
      label={label ?? ""}
      description={description}
      imageUrl={imageUrl}
      showDescription={!data.hideDescription}
      {...props}
    />
  );
}
