/* eslint-disable import/prefer-default-export */
import { useModuleConfig } from "../../../__utils/modules";
import type { ModuleConfig } from "../shared/misc";
import { RICH_BLOCK_NAME } from "./shared";

declare module "@resource/atlas" {
  interface ContentEditorModuleConfig {
    [RICH_BLOCK_NAME]?: ModuleConfig;
  }
}

export function useLinkCardConfig() {
  return useModuleConfig(RICH_BLOCK_NAME, { required: true });
}
