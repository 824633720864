import { DateTime } from "luxon";
import { useMemo } from "react";

import { useCalculateScrollValueForTime } from "../../hooks/callbacks";
import { useCalendarSettings, useCurrentInterval } from "../../hooks/settings";
import { useColumns } from "../../hooks/useColumns";
import { CalendarEventsViewElement } from "../portals/CalendarEventsViewElement";

const INDICATOR_HEIGHT = 2;
const INDICATOR_BALL_SIZE = 12;

export function CurrentTimeIndicator() {
  const calculateScrollValueForTime = useCalculateScrollValueForTime();
  const { view, timezone } = useCalendarSettings();
  const interval = useCurrentInterval();
  const now = DateTime.now().setZone(timezone);
  const dayOfWeek = now.weekday;

  const currentTimeScrollValue = useMemo(() => {
    const scrollValue = calculateScrollValueForTime(now);

    return scrollValue;
  }, [calculateScrollValueForTime, now]);
  const { columnIds } = useColumns();
  const gridColumnStart = useMemo(() => {
    if (view === "day") {
      return 1;
    }

    return dayOfWeek;
  }, [dayOfWeek, view]);
  const gridColumnEnd = useMemo(() => {
    if (view === "day") {
      return columnIds.length + 1;
    }

    return dayOfWeek + 1;
  }, [columnIds.length, dayOfWeek, view]);

  if (!interval.contains(now)) {
    return null;
  }

  return (
    <CalendarEventsViewElement>
      <li
        className="relative mt-px flex w-full"
        style={{
          gridRow: 1,
          gridColumnStart,
          gridColumnEnd,
        }}
      >
        <div
          className="group w-full absolute z-[11] rounded-lg bg-red-500"
          style={{
            top: currentTimeScrollValue - INDICATOR_HEIGHT / 2,
            height: INDICATOR_HEIGHT,
          }}
        />
        <div
          className="group absolute z-[11] rounded-full bg-red-500"
          style={{
            width: INDICATOR_BALL_SIZE,
            height: INDICATOR_BALL_SIZE,
            top: currentTimeScrollValue - INDICATOR_BALL_SIZE / 2,
            left: -(INDICATOR_BALL_SIZE / 2),
          }}
        />
      </li>
    </CalendarEventsViewElement>
  );
}
