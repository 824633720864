import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarTimesReceived } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { useLogEvent } from "analytics";
import { LexicalEditor } from "lexical";
import { trackProperties } from "shared/analytics";

import { useInsertAvailabilityRequest } from "./use-insert-availability-request";

export function InsertAvailabilityRequestButton({
  editor,
}: {
  editor: LexicalEditor;
}) {
  const insertAvailabilityBlock = useInsertAvailabilityRequest({
    logEvent: () => {
      logEvent?.(
        "Insert Availability Request Clicked",
        trackProperties.isCustomerSuccess
      );
    },
    editor,
  });
  const logEvent = useLogEvent({
    component: "InsertAvailabilityButton",
  });

  return (
    //  eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      as="button"
      className="text-body-md px-1 h-full shrink"
      onClick={insertAvailabilityBlock}
    >
      <p className="flex items-center gap-1">
        <Icon content={atlasCalendarTimesReceived} className="w-4 h-4" />
        <span>Insert availability request</span>
      </p>
    </Link>
  );
}
