import { ValueSet } from "@resource/atlas/content-editor/variables";
import { Link } from "@resource/atlas/link/Link";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { View, ViewProps } from "@resource/atlas/view/View";
import { strings } from "@resource/common";
import { useLogEvent } from "analytics";
import {
  convertInterviewVariables,
  InterviewForVariables,
} from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/content-editor/interview-variables";
import { useGuideUpdateValueSet } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/content-editor/use-guide-update-value-set";
import {
  ViewHeader,
  ViewHeaderProps,
} from "client/components/generic/layout/ViewHeader";
import { GuideUpdateEditor } from "client/components/guide-content";
import { HiringDecisionMenu } from "client/components/interview-plan/HiringDecisionSelect";
import { SubjectSelector } from "client/message-composer/__components/SubjectSelector";
import { GuideIdProvider } from "client/utils/guide-id-provider";
import clsx from "clsx";
import { CollapsedSignature } from "components/CollapsedSignature";
import { InsertAvailabilityRequestButton } from "components/MessageComposer/InsertAvailabilityRequestButton";
import { InsertInterviewsButton } from "components/MessageComposer/InsertInterviewsButton";
import { InsertSelfScheduleRequestButton } from "components/MessageComposer/InsertSelfScheduleRequestButton";
import StageSelect from "components/MessageComposer/StageSelect";
import ThreadDetail from "components/MessageComposer/ThreadDetail";
import { RecipientsManager } from "components/RecipientsManager";
import { gql } from "generated/graphql-codegen";
import { GuideForComposeMessageFormFragment } from "generated/graphql-codegen/graphql";
import { useEffect, useMemo } from "react";
import { trackProperties } from "shared/analytics";
import { GuideUpdateVariableSet } from "shared/guide-content/update";
import { PostMessageWorkflow } from "shared/message-composer/types";

import CandidateEmailSelect from "../../../components/MessageComposer/CandidateEmailSelect";
import { FormFieldLabel } from "../../../components/MessageComposer/FormFieldLabel";
import Line from "../../../components/MessageComposer/Line";
import { MessageSender } from "../../../components/MessageComposer/MessageSender";
import { PostTemplateLoader } from "../../../components/MessageComposer/PostTemplateLoader";
import { GuideComposeMessageState } from "../__hooks/useGuideComposeMessageState";
import { filterRecipients } from "../recipients";

gql(`fragment UserMembershipForGuideMessageCompose on UserMembership {
  id
  name
  firstName
  lastName
  email
  imageUrl
  receiveNotificationsWhenRecruiter
  receiveNotificationsWhenCoordinator
}`);

gql(`
fragment GuideForComposeMessageForm on Guide {
    id
    status
    publicUrl
    job {
      id
      displayName
    }
    currentInterviewPlanItem {
      id
    }
    ...GuideBaseVariableValuesGuide
    interviewPlan {
      ...InterviewPlan
    }
    organization {
      id
      name
      defaultAvatarImageUrl
      companyLogoUrl
    }
    candidate {
      id
      firstName: displayFirstName
      lastName
      displayName
      email
      imageUrl
      allEmails {
        id
        value
      }
      timezone
      organization {
        id
        name
        companyLogoUrl
        defaultAvatarImageUrl
        enableICSFiles
      }
    }
    recruiter {
      id
      ...UserMembershipForGuideMessageCompose
    }
    coordinator {
      id
      ...UserMembershipForGuideMessageCompose
    }
    openTaskAssignees {
      id
      ...UserMembershipForGuideMessageCompose
    }
    posts {
      ...ThreadForDetail
    }
    defaultCandidateInterviewConfirmationTemplate: defaultTemplate(type: candidate_interview_confirmation) @include(if: $isCandidateInterviewConfirmation) {
      id
      ...PostTemplateForGuideComposeMessageDefaultTemplate
    }
    defaultCandidateRescheduleInterviewConfirmationTemplate: defaultTemplate(type: candidate_reschedule_interview_confirmation) @include(if: $isCandidateRescheduleInterviewConfirmation) {
      id
      ...PostTemplateForGuideComposeMessageDefaultTemplate
    }
    defaultRequestAvailabilityTemplate: defaultTemplate(type: request_availability) @include(if: $isRequestAvailability) {
      id
      ...PostTemplateForGuideComposeMessageDefaultTemplate
    }
    defaultRequestAdditionalAvailabilityTemplate: defaultTemplate(type: request_additional_availability) @include(if: $isRequestAdditionalAvailability) {
      id
      ...PostTemplateForGuideComposeMessageDefaultTemplate
    }
    defaultRequestSelfScheduleTemplate: defaultTemplate(type: self_schedule_request) @include(if: $isSelfScheduleRequest) {
      id
      ...PostTemplateForGuideComposeMessageDefaultTemplate
    }
  }
`);

export type GuideComposeMessageFormProps = ViewProps<"div"> & {
  viewHeader?: ViewHeaderProps;
  guide: GuideForComposeMessageFormFragment;
  className?: string;
  state: GuideComposeMessageState;
  onEditTemplate?: () => void;
  defaultTemplatePopoverOpen?: boolean;
  autofocus?: boolean;
};

export function GuideComposeMessageForm({
  header,
  footer,
  viewHeader,
  guide,
  state,
  onEditTemplate,
  autofocus,
  defaultTemplatePopoverOpen,
  ...viewProps
}: GuideComposeMessageFormProps) {
  const {
    form,
    editor,
    showNotify,
    setShowNotify,
    contentEditorProps,
    subjectContentEditorProps,
    template,
    postTemplateType,
    loadTemplate,
    workflowType,
    availabilityBlockMissing,
    interviewsBlockMissing,
    taskThreads,
    allThreads,
    taskRequirements,
    scheduledInterviewsBeingConfirmed,
    selfScheduleBlockMissing,
    selfScheduleInterviewId,
    setRecipients,
  } = state;
  const { watch, setValue } = form;
  const sender = watch("sender");
  const status = watch("status");
  const recipients = watch("recipients");
  const to = watch("to");
  const subject = watch("subject");
  const threadId = watch("threadId");
  const moveToInterviewPlanItemId = watch("moveToInterviewPlanItemId");
  const candidateEmails = useMemo(
    () => guide.candidate.allEmails.map((e) => e.value, []),
    [guide.candidate.allEmails]
  );
  const defaultTemplateOpen = defaultTemplatePopoverOpen ?? !template;
  const templatePopoverState = usePopoverState({
    defaultOpen: defaultTemplateOpen,
  });

  const logEvent = useLogEvent({
    component: "GuideComposeMessageForm",
  });

  const { candidate } = guide;

  const thread = useMemo(() => {
    if (threadId) {
      return allThreads.find((t) => t.id === threadId);
    }
    return null;
  }, [threadId, allThreads]);

  const mappedScheduledInterviews = useMemo(() => {
    return scheduledInterviewsBeingConfirmed.map(
      (interview): InterviewForVariables => ({
        ...interview,
        interviewers: interview.interviewers.map((interviewer) => ({
          ...interviewer.userMembership,
          fullName: interviewer.userMembership.name,
        })),
      })
    );
  }, [scheduledInterviewsBeingConfirmed]);

  const guideUpdateValueSet = useMemo((): ValueSet<GuideUpdateVariableSet> => {
    return {
      authorName: sender
        ? {
            first: sender.firstName,
            last: sender.lastName,
          }
        : undefined,
      ...(mappedScheduledInterviews.length > 0
        ? {
            ...convertInterviewVariables({
              interviews: mappedScheduledInterviews,
            }),
          }
        : {}),
    };
  }, [sender, mappedScheduledInterviews]);

  const valueSet = useGuideUpdateValueSet({
    guide,
    valueSet: guideUpdateValueSet,
  });

  useEffect(() => {
    if (!defaultTemplateOpen && autofocus) {
      setTimeout(() => {
        editor?.focus();
      }, 1);
    }
  }, [autofocus, defaultTemplateOpen, editor]);

  return (
    <GuideIdProvider guideId={guide.id}>
      <View
        {...viewProps}
        header={header}
        footer={footer}
        content={{
          ...viewProps.content,
          className: clsx(
            viewProps.content?.className,
            "py-4 px-0 flex flex-col"
          ),
        }}
      >
        {viewHeader && <ViewHeader {...viewHeader} className="px-6" />}
        {thread && (
          <div className="flex-shrink-0 px-4">
            <ThreadDetail thread={thread} />
          </div>
        )}
        <div className="flex-shrink-0 px-6">
          <div className="flex gap-2 items-center h-10">
            <FormFieldLabel>From</FormFieldLabel>
            <MessageSender
              value={sender}
              onChange={(v) => setValue("sender", v, { shouldValidate: true })}
              className="grow -mr-2.5"
            />
          </div>
          <Line />
          <div className="flex gap-2 items-center h-10">
            <FormFieldLabel>To</FormFieldLabel>
            <CandidateEmailSelect
              className={clsx("overflow-hidden grow !shrink", {
                "-mr-2.5": showNotify,
              })}
              value={to}
              emails={candidateEmails}
              onChange={(v) => setValue("to", v, { shouldValidate: true })}
            />
            {!showNotify && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                as="button"
                variant="subtle"
                className="text-body-md flex-shrink-0"
                onClick={() => {
                  logEvent(
                    "Notify others clicked",
                    trackProperties.isCustomerSuccess
                  );
                  setShowNotify(true);
                }}
              >
                CC{recipients.length > 0 && ` (${recipients.length})`}
              </Link>
            )}
          </div>
          {showNotify && (
            <>
              <Line />
              <div className="flex gap-2 items-center h-10">
                <FormFieldLabel>CC</FormFieldLabel>
                <RecipientsManager
                  className="-mr-2.5"
                  label="team member"
                  selected={recipients}
                  forMessageComposer
                  onSelectionChanged={(v) =>
                    setRecipients({
                      recipients: v,
                      isUser: true,
                    })
                  }
                  filterOptions={(recipientOptions) =>
                    filterRecipients({
                      recipients: recipientOptions,
                      senderId: sender?.id,
                    })
                  }
                />
              </div>
            </>
          )}
          <>
            <Line />
            <div className="flex gap-2 items-center">
              <FormFieldLabel>Stage</FormFieldLabel>
              <StageSelect
                value={moveToInterviewPlanItemId ?? ""}
                stages={guide.interviewPlan?.items ?? []}
                onChange={(v) =>
                  setValue("moveToInterviewPlanItemId", v, {
                    shouldValidate: true,
                  })
                }
                updatedHiringDecision={status}
                current={guide.currentInterviewPlanItem?.id ?? undefined}
                collapsed={false}
                expand={() => {}}
                className="-mr-2.5"
              />
              <HiringDecisionMenu
                isGhost
                className="-mr-3.5"
                currentHiringDecision={status ?? guide.status}
                onSelect={(decision) => {
                  setValue("status", decision.value, {
                    shouldValidate: true,
                  });
                }}
              />
            </div>
          </>
          <Line />
          <div className="flex gap-2 items-center">
            <div className="text-body-sm text-subtle w-14">Template</div>
            <PostTemplateLoader
              onSelect={(t) => {
                loadTemplate(t);
              }}
              triggerProps={{
                isGhost: true,
                className: template ? "" : "-mr-2.5",
              }}
              selectedTemplate={template}
              type={postTemplateType}
              popoverState={templatePopoverState}
            />
            {template && onEditTemplate && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                as="button"
                variant="subtle"
                className="text-body-md flex-shrink-0"
                onClick={() => onEditTemplate()}
              >
                Edit template…
              </Link>
            )}
          </div>
          <Line />
          <div className="flex gap-2 items-center">
            <FormFieldLabel>Subject</FormFieldLabel>
            <SubjectSelector
              thread={thread}
              subject={subject}
              setSubject={(editorState) =>
                setValue("subject", editorState.toJSON(), {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              setThreadId={(v) =>
                setValue("threadId", v, {
                  shouldValidate: true,
                })
              }
              taskThreads={taskThreads}
              allThreads={allThreads}
              valueSet={valueSet}
              candidateTimezone={candidate.timezone}
              {...subjectContentEditorProps}
            />
          </div>
          <Line />
          {availabilityBlockMissing && editor && (
            <div className="h-10 flex flex-col">
              <div className="h-full flex items-center">
                <InsertAvailabilityRequestButton editor={editor} />
              </div>
              <Line />
            </div>
          )}
          {interviewsBlockMissing && editor && (
            <div className="h-10 flex flex-col">
              <div className="h-full flex items-center">
                <InsertInterviewsButton
                  timezone={guide.candidate.timezone ?? undefined}
                  editor={editor}
                />
              </div>
              <Line />
            </div>
          )}
          {selfScheduleBlockMissing && editor && (
            <div className="h-10 flex flex-col">
              <div className="h-full flex items-center">
                <InsertSelfScheduleRequestButton
                  editor={editor}
                  selfScheduleInterviewId={selfScheduleInterviewId}
                />
              </div>
              <Line />
            </div>
          )}
        </div>
        <GuideUpdateEditor
          id="post-lexical-editor"
          className="grow mt-4 flex flex-col overflow-auto min-h-[16rem] px-6"
          placeholder={`Write a message to ${
            strings.splitName(candidate.displayName).firstName
          }…`}
          undefinedMode="error"
          valueSet={valueSet}
          initialState={form.getValues().content}
          onChange={(editorState) => {
            setValue("content", editorState.toJSON(), {
              shouldValidate: true,
              shouldDirty: true,
            });
          }}
          candidateEmail={form.getValues().to}
          candidateTimezone={candidate.timezone}
          onFocus={() => {
            if (
              workflowType &&
              [
                PostMessageWorkflow.REQUEST_AVAILABILITY,
                PostMessageWorkflow.CONFIRM_INTERVIEWS,
              ].includes(workflowType)
            ) {
              // no-op
            }
          }}
          trailingContentSlot={
            <CollapsedSignature
              className="mt-4 mb-6"
              href={guide.guideLexicalContext.href}
              companyLogoUrl={guide.candidate.organization.companyLogoUrl}
              companyName={guide.candidate.organization.name}
            />
          }
          organization={guide.candidate.organization}
          taskRequirements={taskRequirements}
          {...contentEditorProps}
        />
      </View>
    </GuideIdProvider>
  );
}
