import { useStoreState } from "@ariakit/react";
import { Popover } from "@resource/atlas/popover-v2";
import { useSyncCurrentDayWithCurrentViewingTime } from "client/scheduler/core/calendar/hooks/useSyncCurrentDayWithCurrentViewingTime";
import { useSyncHorizontalScrollWithCurrentViewingGroupingId } from "client/scheduler/core/calendar/hooks/useSyncHorizontalScrollWithCurrentViewingGroupingId";
import clsx from "clsx";
import { DateTime } from "luxon";
import { ComponentPropsWithoutRef, ReactNode, useMemo } from "react";

import { useEventDetailsPopoverStore, useSetWrapperRef } from "../hooks/refs";
import { useScrollToCalendarInitialValueOnMount } from "../hooks/useScrollToCalendarInitialValueOnMount";
import { CalendarContextInput, CalendarProvider } from "../utils/context";
import { EventDetailsPopover } from "./events/EventDetailsPopover";
import { EventsView } from "./events/EventsView";
import { CalendarHeader, CalendarHeaderProps } from "./generic/CalendarHeader";
import { ColumnsHeader } from "./generic/ColumnsHeader";
import { CurrentTimeIndicator } from "./generic/CurrentTimeIndicator";

export type CalendarProps = CalendarContextInput & CalendarWithStateProps;

export function Calendar(props: CalendarProps) {
  const {
    events,
    calendarColors,
    options,
    headerProps,
    initialScrollValue,
    plugins,
    currentViewingTime,
    currentViewingGroupingId,
    ...divProps
  } = props;
  const calendarProviderInput: CalendarContextInput = useMemo(
    () => ({
      events,
      calendarColors,
      options,
      currentViewingTime,
      currentViewingGroupingId,
    }),
    [
      events,
      calendarColors,
      options,
      currentViewingTime,
      currentViewingGroupingId,
    ]
  );

  const calendarWithStateProps: CalendarWithStateProps = useMemo(
    () => ({
      ...divProps,
      headerProps,
      initialScrollValue,
      plugins,
    }),
    [divProps, headerProps, initialScrollValue, plugins]
  );

  return (
    <CalendarProvider input={calendarProviderInput}>
      <CalendarWithState {...calendarWithStateProps} />
    </CalendarProvider>
  );
}

export type CalendarWithStateProps = Omit<
  ComponentPropsWithoutRef<"div">,
  "children"
> & {
  headerProps?: CalendarHeaderProps;
  initialScrollValue?: DateTime | string;
  plugins?: ReactNode[];
};

function CalendarWithState(props: CalendarWithStateProps) {
  const { headerProps, initialScrollValue, plugins, ...rest } = props;
  const setWrapperRef = useSetWrapperRef();
  const eventDetailsPopoverStore = useEventDetailsPopoverStore();
  const eventDetailsPopoverState = useStoreState(eventDetailsPopoverStore);

  useScrollToCalendarInitialValueOnMount({
    initialScrollValue,
  });
  useSyncCurrentDayWithCurrentViewingTime();
  useSyncHorizontalScrollWithCurrentViewingGroupingId();

  return (
    <>
      <div
        {...rest}
        className={clsx("flex flex-col grow w-full h-full", rest.className)}
      >
        <CalendarHeader {...headerProps} />
        <Popover.Root store={eventDetailsPopoverStore}>
          <div className="flex flex-col overflow-hidden pb-[0.125rem]">
            <div
              className={clsx(
                "isolate flex flex-auto flex-row bg-white",
                eventDetailsPopoverState.open
                  ? "overflow-hidden"
                  : "overflow-x-scroll"
              )}
              ref={setWrapperRef}
            >
              <div className="flex flex-col grow">
                <ColumnsHeader />
                <EventsView />
                <EventDetailsPopover />
              </div>
            </div>
          </div>
        </Popover.Root>
      </div>
      <CurrentTimeIndicator />
      {plugins}
    </>
  );
}
