import { z } from "zod";

export enum ConferencingType {
  ZOOM = "ZOOM",
  GOOGLE_MEET = "GOOGLE_MEET",
  PHONE = "PHONE",
  OTHER = "OTHER",
}

export const ConferencingTypeSchema = z.nativeEnum(ConferencingType);

export enum ConferencingHostSetting {
  ANY_INTERVIEWER = "ANY_INTERVIEWER",
  REQUEST_ASSIGNEE = "REQUEST_ASSIGNEE",
}

export const ConferencingHostSettingSchema = z.nativeEnum(
  ConferencingHostSetting
);

export const ConferencingRequirementSchema = z.object({
  service: ConferencingTypeSchema,
  hostSetting: ConferencingHostSettingSchema.nullable().optional(),
  hostUserMembershipId: z.string().optional().nullable(),
  value: z.string().optional().nullable(),
  regenerateLink: z.boolean().optional().nullable(),
});

export type ConferencingRequirement = z.infer<
  typeof ConferencingRequirementSchema
>;

export const ConferencingGenerationSettingsSchema = z.object({
  service: ConferencingTypeSchema,
  hostUserMembershipId: z.string().optional().nullable(),
  regenerateLink: z.boolean().optional().nullable(),
  hostSetting: ConferencingHostSettingSchema.optional().nullable(),
  value: z.string().optional().nullable(),
});

export type ConferencingGenerationSettings = z.infer<
  typeof ConferencingGenerationSettingsSchema
>;

export const ConferencingSlotInputSchema = z.object({
  customUrl: z.string().optional(),
  phone: z.string().optional(),
  generationSettings: ConferencingGenerationSettingsSchema.optional(),
  requirement: ConferencingRequirementSchema.optional(),
  useGroupSettings: z.boolean().optional(),
});

export type ConferencingSlotInput = z.infer<typeof ConferencingSlotInputSchema>;

export const StagedConferencingGenerationSettingsSchema =
  ConferencingGenerationSettingsSchema.refine(
    (data) => {
      if (data.service === ConferencingType.ZOOM) {
        return (
          data.hostUserMembershipId != null && data.hostUserMembershipId !== ""
        );
      }

      return true;
    },
    {
      message: "A valid zoom host is required.",
      path: ["hostUserMembershipId"],
    }
  );

export type StagedConferencingGenerationSettings = z.infer<
  typeof StagedConferencingGenerationSettingsSchema
>;

export const GroupConferencingSlotInputSchema = z.object({
  groupConferencingLinkId: z.string().optional(),
  generationSettings: ConferencingGenerationSettingsSchema.optional(),
});

export type GroupConferencingSlotInput = z.infer<
  typeof GroupConferencingSlotInputSchema
>;
