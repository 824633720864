import { MutationHookOptions } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import {
  CompleteSchedulingRequestInput,
  CompleteSchedulingRequestMutation,
  Exact,
} from "generated/graphql-codegen/graphql";
import useMutation from "utils/useMutation";

const COMPLETE_SCHEDULING_REQUEST = gql(`
  mutation CompleteSchedulingRequest($input: CompleteSchedulingRequestInput!) {
    completeSchedulingRequest(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        status
        taskStatus
        archivedAt
        guideId
        organizationId
      }
    }
  }
`);

export function useCompleteSchedulingRequest(
  options?: MutationHookOptions<
    CompleteSchedulingRequestMutation,
    Exact<{
      input: CompleteSchedulingRequestInput;
    }>
  >
) {
  return useMutation(COMPLETE_SCHEDULING_REQUEST, {
    ...options,
  });
}
