import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Divider } from "client/components/generic/misc/Divider";
import {
  InterviewRequirementForConfigurationFragment,
  ScheduledInterviewForScheduledInterviewCardFragment,
  SchedulingRequestForDetailsViewFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { isInFuture } from "utils/time";

import { useIsTaskV2 } from "./hooks/useIsTaskV2";
import { InterviewsRequirementsSection } from "./InterviewRequirementsSection";
import { ReschedulingInterviewsSection } from "./ReschedulingInterviewsSection";
import { ScheduledInterviewsSection } from "./ScheduledInterviewsSection";

type Interview = ScheduledInterviewForScheduledInterviewCardFragment;

type InterviewSectionsProps = {
  schedulingRequest: SchedulingRequestForDetailsViewFragment;
  onScheduleAll: () => void;
  onReschedule: () => void;
  onNavigateToScheduledInterviewDetails: (props: {
    scheduledInterviewId: string;
  }) => void;
  onEditTask: () => void;
  onEditRequirement: (
    requirement: InterviewRequirementForConfigurationFragment
  ) => void;
  hasAvailability: boolean;
};

export function InterviewSections({
  schedulingRequest,
  onReschedule,
  onScheduleAll,
  onNavigateToScheduledInterviewDetails,
  onEditTask,
  onEditRequirement,
  hasAvailability,
}: InterviewSectionsProps) {
  // We split our requirements into 3 sections;
  // 1. Unscheduled interviews
  // 2. Interviews to be rescheduled
  // 3. Scheduled interviews

  const isTaskV2 = useIsTaskV2({
    scheduledInterviewsForTask: schedulingRequest.scheduledInterviews,
  });

  const needsToBeRescheduled = useCallback(
    (interview: Interview) => {
      if (interview.isV2) {
        return !!interview.pendingRescheduleInterviewRequirement;
      }

      const requirement =
        schedulingRequest.interviewPanelRequirement.interviewRequirements.find(
          (ir) => {
            return (
              ir.id === interview.interviewRequirementId ||
              ir.scheduledInterviewToRescheduleId === interview.id
            );
          }
        );

      if (!requirement || !requirement.scheduledInterviewToRescheduleId) {
        return false;
      }

      return !requirement.hasBeenRescheduled;
    },
    [schedulingRequest.interviewPanelRequirement.interviewRequirements]
  );

  // For scheduled interviews we want to consider interviews that are upcoming OR related to this request
  const allScheduledInterviewsIncludingNeedingRescheduled = useMemo(
    () =>
      schedulingRequest.scheduledInterviews.filter((i) => {
        if (i.interviewRequirementId) {
          if (isInFuture(i) && !i.isCancelled) {
            return true;
          }

          if (
            schedulingRequest.scheduledInterviews
              .map((si) => si.id)
              .includes(i.id) ||
            schedulingRequest.interviewPanelRequirement.interviewRequirements
              .map((ir) => ir.scheduledInterviewToRescheduleId)
              .includes(i.id)
          ) {
            return true;
          }
        }

        return false;
      }),
    [
      schedulingRequest.interviewPanelRequirement.interviewRequirements,
      schedulingRequest.scheduledInterviews,
    ]
  );

  const scheduledInterviews =
    allScheduledInterviewsIncludingNeedingRescheduled.filter(
      (v) => !needsToBeRescheduled(v)
    );
  const interviewsToBeRescheduled =
    allScheduledInterviewsIncludingNeedingRescheduled.filter((v) =>
      needsToBeRescheduled(v)
    );

  if (!isTaskV2) {
    return (
      <AlertBanner variant="warning">
        This task is deprecated. Please create a new task to continue
        scheduling.
      </AlertBanner>
    );
  }

  return (
    <>
      {schedulingRequest.interviewPanelRequirement.interviewRequirements
        .length || !schedulingRequest.isReschedule ? (
        <>
          <div className="p-6">
            <InterviewsRequirementsSection
              schedulingRequest={schedulingRequest}
              onScheduleAll={onScheduleAll}
              interviewRequirements={
                schedulingRequest.interviewPanelRequirement
                  .interviewRequirements
              }
              onEditTask={onEditTask}
              onEditRequirement={onEditRequirement}
              hasAvailability={hasAvailability}
            />
          </div>
          <Divider />
        </>
      ) : null}
      {interviewsToBeRescheduled.length || schedulingRequest.isReschedule ? (
        <>
          <div className="p-6">
            <ReschedulingInterviewsSection
              scheduledInterviews={interviewsToBeRescheduled}
              onReschedule={onReschedule}
              onNavigateToScheduledInterviewDetails={
                onNavigateToScheduledInterviewDetails
              }
              hasAvailability={hasAvailability}
            />
          </div>
          <Divider />
        </>
      ) : null}

      <div className="p-6">
        <ScheduledInterviewsSection
          schedulingRequest={schedulingRequest}
          scheduledInterviews={scheduledInterviews}
          onNavigateToScheduledInterviewDetails={
            onNavigateToScheduledInterviewDetails
          }
        />
      </div>
    </>
  );
}
