import { useDialogStore } from "@ariakit/react";
import { View, ViewProps } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { UpdateScheduledInterviewOnCompleted } from "client/components/confirmations/__hooks__/useEditScheduledInterviewConfirmationOnSave";
import { EditScheduledInterviewConfirmationDialog } from "client/components/confirmations/EditScheduledInterviewConfirmationDialog";
import { BackButton } from "client/components/generic/layout/BackButton";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { UserMembershipSelectWrapperProps } from "client/components/interviewer-slots/utils/types";
import UserMembershipWithSchedulingDataSelect from "client/components/interviewer-slots/wrappers/UserMembershipWithSchedulingDataSelect";
// eslint-disable-next-line import/no-restricted-paths
import { FindTimeButton } from "client/scheduler/wrappers/FindTimeButton";
// eslint-disable-next-line import/no-restricted-paths
import { SchedulerDialog } from "client/scheduler/wrappers/SchedulerDialog";
import ErrorPage from "components/Generic/ErrorPage";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import { GetScheduleInterviewForEditScheduledInterviewQuery } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { trackProperties } from "shared/analytics";
import useQuery from "utils/useQuery";

import { mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings } from "../../ScheduledInterviewGroupSettingsForm/utils/mapping";
import { useSyncUpsertScheduledInterviewFormStateForSlotCalculations } from "../__hooks/interviewer-slots";
import { useUpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";
import { UpsertScheduledInterviewForm } from "../display/UpsertScheduledInterviewForm";
import { mapScheduledInterviewToEditingInterview } from "../utils/mapping";

const GET_SCHEDULED_INTERVIEW = gql(`
  query GetScheduleInterviewForEditScheduledInterview($scheduledInterviewId: String!) {
    scheduledInterviewById(id: $scheduledInterviewId) {
      ...ScheduledInterviewForEditScheduledInterview
      ...ScheduledInterviewForFindTimeButton
    }
  }
`);

type EditScheduledInterviewProps = ViewProps<"div"> & {
  scheduledInterviewId: string;
  onCancel: () => void;
  onCompleted: UpdateScheduledInterviewOnCompleted;
};

export function EditScheduledInterview({
  scheduledInterviewId,
  onCompleted,
  ...props
}: EditScheduledInterviewProps) {
  const { data, loading } = useQuery(GET_SCHEDULED_INTERVIEW, {
    // ensure the data is accurate for editing
    fetchPolicy: "network-only",
    variables: {
      scheduledInterviewId,
    },
  });
  const scheduledInterview = data?.scheduledInterviewById;

  if (loading) {
    return (
      <View {...props}>
        <Loading />
      </View>
    );
  }

  if (!scheduledInterview) {
    return (
      <View {...props}>
        <ErrorPage errorCode="404" />
      </View>
    );
  }

  if (!scheduledInterview.guideId) {
    // Don't support editing booking link interviews this way yet
    return (
      <View {...props}>
        <ErrorPage errorCode="500" />
      </View>
    );
  }

  return (
    <EditScheduledInterviewDisplay
      scheduledInterview={scheduledInterview}
      onCompleted={onCompleted}
      {...props}
    />
  );
}

type EditScheduledInterviewDisplayProps = Omit<
  EditScheduledInterviewProps,
  "scheduledInterviewId"
> & {
  scheduledInterview: NonNullable<
    GetScheduleInterviewForEditScheduledInterviewQuery["scheduledInterviewById"]
  >;
};

function EditScheduledInterviewDisplay({
  scheduledInterview: scheduledInterviewFragment,
  onCancel,
  onCompleted,
  ...props
}: EditScheduledInterviewDisplayProps) {
  const logEvent = useLogEvent({ component: "EditScheduledInterview" });
  const scheduledInterview = useMemo(() => {
    return mapScheduledInterviewToEditingInterview({
      scheduledInterview: scheduledInterviewFragment,
    });
  }, [scheduledInterviewFragment]);
  const scheduledInterviewGroupSettings = useMemo(() => {
    if (!scheduledInterviewFragment.scheduledInterviewGroup) {
      return undefined;
    }

    return mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings(
      scheduledInterviewFragment.scheduledInterviewGroup
    );
  }, [scheduledInterviewFragment.scheduledInterviewGroup]);
  const state = useUpsertScheduledInterviewFormState({
    scheduledInterview,
    scheduledInterviewGroupSettings,
  });
  const defaultScheduledInterviewForScheduler =
    state.form.watch("scheduledInterview");
  const defaultInterviewsForScheduler = useMemo(
    () => [defaultScheduledInterviewForScheduler],
    [defaultScheduledInterviewForScheduler]
  );

  const confirmationDialogStore = useDialogStore();

  const { formDataForSlotCalculations } =
    useSyncUpsertScheduledInterviewFormStateForSlotCalculations({
      state,
      // We check for guideId above, should exist by now
      guideId: scheduledInterviewFragment.guideId ?? "",
    });

  const UserMembershipSelect = useCallback(
    (selectProps: UserMembershipSelectWrapperProps) => {
      return (
        <UserMembershipWithSchedulingDataSelect
          {...selectProps}
          formDataForScheduling={formDataForSlotCalculations}
        />
      );
    },
    [formDataForSlotCalculations]
  );

  const onSave = useCallback(() => {
    confirmationDialogStore.show();
  }, [confirmationDialogStore]);

  const schedulerDialogStore = useDialogStore();
  const onFindTime = useCallback(() => {
    logEvent("Scheduler V2 Opened", trackProperties.isCustomerSuccess);
    schedulerDialogStore.show();
  }, [schedulerDialogStore, logEvent]);

  if (!scheduledInterviewFragment.guideId) {
    return <ErrorPage errorCode="404" />;
  }

  return (
    <>
      <View
        {...props}
        header={{
          leftActions: <BackButton onClick={onCancel} />,
          rightActions: (
            <FindTimeButton
              scheduledInterview={scheduledInterviewFragment}
              onFindTime={onFindTime}
            />
          ),
          title: "Edit interview details",
        }}
        footer={{
          ...props.footer,
          rightActions: (
            <FormRightActions
              isLoading={false}
              cancel={{ onClick: onCancel }}
              save={{ onClick: onSave }}
              disabledTooltipContent={state.disabledTooltipContent}
            />
          ),
        }}
      >
        <UpsertScheduledInterviewForm
          state={state}
          UserMembershipSelect={UserMembershipSelect}
        />
      </View>
      <EditScheduledInterviewConfirmationDialog
        guideId={scheduledInterviewFragment.guideId}
        store={confirmationDialogStore}
        form={state.form}
        originalInterviewers={state.originalInterviewers}
        onCompleted={onCompleted}
        originalInterview={state.originalInterview ?? undefined}
      />
      {scheduledInterviewFragment.scheduledInterviewGroupId && (
        <SchedulerDialog
          defaultSelectedInterviewId={scheduledInterviewFragment.id}
          defaultInterviews={defaultInterviewsForScheduler}
          store={schedulerDialogStore}
          scheduledInterviewGroupId={
            scheduledInterviewFragment.scheduledInterviewGroupId
          }
        />
      )}
    </>
  );
}
