import type {
  AtlasContentEditorProps,
  AtlasContentEditorSerializedState,
} from "@resource/atlas/content-editor/types";
import { useOptionalContentEditor } from "@resource/atlas/content-editor/use-content-editor";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { strings } from "@resource/common";
import { useAuthContext } from "auth/context";
import { GuideAvailabilityRequestNode } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/rich-block";
import { useBlockForWorkflow } from "client/guide-content/rich-blocks/use-block-for-workflow";
import { useSyncDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { SelfScheduleRequestNode } from "client/self-schedule/rich-blocks/self-schedule-request/rich-block";
import { Recipient } from "components/RecipientsManager";
import { gql } from "generated/graphql-codegen";
import {
  GuideStatusEnum,
  InterviewerForGuideComposeMessageFormFragment,
  InterviewRequirementForInterviewRequirementsCardFragment,
  PostTemplateForGuideComposeMessageDefaultTemplateFragment,
  PostTemplateType,
  ThreadForDetailFragment,
} from "generated/graphql-codegen/graphql";
import {
  $getRoot,
  $insertNodes,
  $parseSerializedNode,
  LexicalEditor,
} from "lexical";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { usePrevious } from "react-use";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";
import { GuideAvailabilityRequestDataSchema } from "shared/guide-availability/rich-blocks/guide-availability-request";
import { SerializedInterviewsNode } from "shared/guide-content/rich-blocks/interviews";
import { PostMessageWorkflow } from "shared/message-composer/types";
import { SelfScheduleRequestData } from "shared/self-schedule/rich-blocks/self-schedule-request";
import {
  getEditorStateFromText,
  getLexicalHasContent,
} from "shared/utils/lexical";
import {
  contentEditorHasInterviewsBlock,
  contentEditorHasInvalidInterviewsBlock,
  contentEditorHasInvalidSelfScheduleRequestBlock,
  contentEditorHasRequestAvailabilityBlock,
  contentEditorHasSelfScheduleRequestBlock,
  contentEditorHasUnfilledVariables,
  contentEditorHasUnknownVariables,
  contentEditorIsEmpty,
} from "utils/validation";

import {
  SetRecipientsProps,
  useDefaultRecipients,
  useRecipientsManagement,
} from "../recipients";

gql(`
  fragment InterviewerForGuideComposeMessageForm on UserMembership {
    id
    name
    firstName
    lastName
    email
    imageUrl
  }
`);

gql(`
  fragment PostTemplateForGuideComposeMessageDefaultTemplate on PostTemplate {
    id
    name
    type
    title
    data
    atssyncEmailTemplate {
      id
      name
      atsUrl
      account {
        id
        type
      }
    }
    ...PostTemplateForUse
  }
`);

export type GuideComposeMessageFormData = {
  threadId?: string;
  subject: AtlasContentEditorSerializedState;
  sender: InterviewerForGuideComposeMessageFormFragment;
  recipients: Recipient[];
  to: string;
  content?: AtlasContentEditorSerializedState;
  status?: GuideStatusEnum;
  moveToInterviewPlanItemId: string | null;
};

export const getDefaultGuideStatusForWorkflowType = (
  workflowType?: PostMessageWorkflow
) => {
  switch (workflowType) {
    case PostMessageWorkflow.NOTIFY_OF_REJECTION:
      return GuideStatusEnum.REJECTED;
    case PostMessageWorkflow.NOTIFY_OF_OFFER:
      return GuideStatusEnum.OFFER;
    case PostMessageWorkflow.NOTIFY_OF_HIRED:
      return GuideStatusEnum.HIRED;
    default:
      return undefined;
  }
};

export const getDefaultSender = (
  user: ReturnType<typeof useAuthContext>["user"]
) =>
  user?.currentUserMembership
    ? {
        __typename: "UserMembership" as const,
        id: user.currentUserMembership.id,
        name: user.currentUserMembership.name,
        firstName: user.currentUserMembership.firstName,
        lastName: user.currentUserMembership.lastName,
        imageUrl: user.currentUserMembership.imageUrl,
        email: user.currentUserMembership.email,
      }
    : null;

export type UseGuideComposeMessageStateProps = {
  workflowType?: PostMessageWorkflow;
  timezone: string | null;
  candidateEmail?: string | null;
  defaultTemplate?: PostTemplateForGuideComposeMessageDefaultTemplateFragment | null;
  defaultContent?: AtlasContentEditorSerializedState;
  defaultMoveToInterviewPlanItemId?: string | null;
  defaultRecipients?: Recipient[] | null;
  thread: {
    id: string;
    recipientProfiles: Recipient[];
    removedRecipients: string[];
  } | null;
  taskThreads?: ThreadForDetailFragment[];
  allThreads: ThreadForDetailFragment[];
  taskRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];
  disableLeaveConfirmation?: boolean;
  guideId: string;
  selfScheduleInterviewId?: string;
  currentInterviewItemId?: string;
};

type ValidationData = {
  isValid: boolean;
  message?: string;
};

export type GuideComposeMessageState = UseGuideComposeMessageStateProps & {
  form: UseFormReturn<GuideComposeMessageFormData>;
  templateHasChanged: boolean;
  setTemplateHasChanged: React.Dispatch<React.SetStateAction<boolean>>;
  originalSubject: AtlasContentEditorSerializedState | null;
  setOriginalSubject: React.Dispatch<
    React.SetStateAction<AtlasContentEditorSerializedState | null>
  >;
  originalContent: AtlasContentEditorSerializedState | undefined;
  setOriginalContent: React.Dispatch<
    React.SetStateAction<AtlasContentEditorSerializedState | undefined>
  >;
  template: PostTemplateForGuideComposeMessageDefaultTemplateFragment | null;
  setTemplate: React.Dispatch<
    React.SetStateAction<PostTemplateForGuideComposeMessageDefaultTemplateFragment | null>
  >;
  editor: LexicalEditor | null;
  subjectEditor: LexicalEditor | null;
  contentEditorProps: AtlasContentEditorProps;
  subjectContentEditorProps: AtlasContentEditorProps;
  showNotify: boolean;
  setShowNotify: React.Dispatch<React.SetStateAction<boolean>>;
  postTemplateType: PostTemplateType | null;
  clearEditor: () => void;
  loadTemplate: (
    t: PostTemplateForGuideComposeMessageDefaultTemplateFragment,
    opts?: {
      shouldDirty?: boolean;
    }
  ) => void;
  missingBlocks: {
    type: PostMessageWorkflow;
    disabledLabel: string;
  }[];
  validationData: ValidationData;
  interviewsBlockMissing: boolean;
  availabilityBlockMissing: boolean;
  selfScheduleBlockMissing: boolean;
  taskRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];
  NotifiedCountComponent: React.ReactNode;
  scheduledInterviewsBeingConfirmed: SerializedInterviewsNode["data"]["interviews"][number][];
  setRecipients: (recipients: SetRecipientsProps) => void;
};

export const mapPostMessageWorkFlowTypeToPostTemplateType = {
  [PostMessageWorkflow.REQUEST_AVAILABILITY]:
    PostTemplateType.request_availability,
  [PostMessageWorkflow.CONFIRM_INTERVIEWS]:
    PostTemplateType.candidate_interview_confirmation,
  [PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS]:
    PostTemplateType.candidate_reschedule_interview_confirmation,
  [PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY]:
    PostTemplateType.request_additional_availability,
  [PostMessageWorkflow.SELF_SCHEDULE_REQUEST]:
    PostTemplateType.self_schedule_request,
};

export function useGuideComposeMessageState(
  props: UseGuideComposeMessageStateProps
): GuideComposeMessageState {
  const {
    workflowType,
    timezone,
    thread,
    taskRequirements,
    disableLeaveConfirmation,
    guideId,
    selfScheduleInterviewId,
    currentInterviewItemId,
    defaultMoveToInterviewPlanItemId,
    defaultRecipients: passedDefaultRecipients,
    allThreads,
  } = props;
  const { user } = useAuthContext();

  const defaultRecipients = useDefaultRecipients({
    thread,
    defaultRecipients: passedDefaultRecipients,
  });

  const defaultContent = useMemo(() => {
    // If specified, defaultContent should override template content data.
    if (props.defaultContent) {
      return props.defaultContent;
    }
    if (props.defaultTemplate?.data) {
      return props.defaultTemplate.data as AtlasContentEditorSerializedState;
    }
    return undefined;
  }, [props.defaultTemplate, props.defaultContent]);

  const form = useForm<GuideComposeMessageFormData>({
    defaultValues: {
      to: props.candidateEmail ?? "",
      sender: getDefaultSender(user) ?? {},
      subject: props.defaultTemplate?.titleData ?? getEditorStateFromText(""),
      recipients: defaultRecipients,
      status: getDefaultGuideStatusForWorkflowType(workflowType),
      content: defaultContent,
      threadId: thread?.id,
      moveToInterviewPlanItemId: defaultMoveToInterviewPlanItemId,
    },
    mode: "onChange",
  });
  const { editor, contentEditorProps } = useOptionalContentEditor();
  const {
    editor: subjectEditor,
    contentEditorProps: subjectContentEditorProps,
  } = useOptionalContentEditor();
  const [template, setTemplate] =
    useState<PostTemplateForGuideComposeMessageDefaultTemplateFragment | null>(
      props.defaultTemplate ?? null
    );
  const [templateHasChanged, setTemplateHasChanged] = useState(false);
  const [originalSubject, setOriginalSubject] =
    useState<AtlasContentEditorSerializedState | null>(null);
  const [originalContent, setOriginalContent] =
    useState<AtlasContentEditorSerializedState>();

  const { setValue, watch, formState } = form;
  const to = watch("to");
  const sender = watch("sender");
  const content = watch("content");
  const subject = watch("subject");
  const threadId = watch("threadId");
  const moveToInterviewPlanItemId = watch("moveToInterviewPlanItemId");
  const recipients = watch("recipients");

  const { setRecipients } = useRecipientsManagement({
    form,
    allThreads,
    defaultRecipients,
  });

  const [showNotify, setShowNotify] = useState(recipients.length > 0);

  const { $insertBlock } = useBlockForWorkflow({
    workflowType,
    editor,
    timezone,
    selfScheduleInterviewId,
  });
  const postTemplateType =
    (workflowType &&
      mapPostMessageWorkFlowTypeToPostTemplateType[
        workflowType as keyof typeof mapPostMessageWorkFlowTypeToPostTemplateType
      ]) ??
    null;

  useEffect(() => {
    setValue("status", getDefaultGuideStatusForWorkflowType(workflowType));
  }, [workflowType, setValue]);

  const setEditorContent = useCallback(
    (data: AtlasContentEditorSerializedState) => {
      if (!editor) return;

      editor.update(() => {
        $getRoot().clear();
        $insertNodes(
          data.root.children.map((n) => {
            if (
              n.type === "request-availability" &&
              "data" in n &&
              !GuideAvailabilityRequestDataSchema.safeParse(n.data).success
            ) {
              return new GuideAvailabilityRequestNode();
            }

            if (n.type === "self-schedule-request" && "data" in n) {
              const selfScheduleNodeData = n.data as SelfScheduleRequestData;

              if (
                "needsInterviewSelection" in selfScheduleNodeData &&
                selfScheduleNodeData.needsInterviewSelection &&
                selfScheduleInterviewId
              ) {
                // If we have a selfScheduleInterview in the context,
                // then we know how to pre configure the self schedule request block
                // If we are loading a template, we should auto populate the block with this data
                return new SelfScheduleRequestNode({
                  interviewId: selfScheduleInterviewId,
                  needsConfiguration: true,
                });
              }
            }

            return $parseSerializedNode(n);
          })
        );
        $insertBlock();
      });

      setValue("content", data, {
        shouldValidate: true,
        shouldDirty: false,
      });
    },
    [$insertBlock, editor, selfScheduleInterviewId, setValue]
  );

  const setSubjectEditorContent = useCallback(
    (data: AtlasContentEditorSerializedState) => {
      if (!subjectEditor) return;

      subjectEditor.update(() => {
        $getRoot().clear();
        $insertNodes(
          data.root.children.map((n) => {
            return $parseSerializedNode(n);
          })
        );
        setValue("subject", data, {
          shouldValidate: true,
          shouldDirty: false,
        });
      });
    },
    [subjectEditor, setValue]
  );

  const clearEditor = useCallback(() => {
    const editorState = JSON.parse(
      LEXICAL_EMPTY_STATE
    ) as AtlasContentEditorSerializedState;
    setEditorContent(editorState);
  }, [setEditorContent]);

  const loadTemplate = useCallback(
    (
      t: PostTemplateForGuideComposeMessageDefaultTemplateFragment,
      opts: {
        shouldDirty?: boolean;
      } = {
        shouldDirty: true,
      }
    ) => {
      setTemplate(t);
      setEditorContent(t.data as AtlasContentEditorSerializedState);
      setSubjectEditorContent(t.titleData as AtlasContentEditorSerializedState);
      editor?.focus();

      if (opts.shouldDirty) {
        setTemplateHasChanged(true);
      }
    },
    [editor, setEditorContent, setSubjectEditorContent]
  );

  const prevTitleData = usePrevious(template?.titleData);

  useEffect(() => {
    if (
      template &&
      template.titleData &&
      !isEqual(template.titleData, prevTitleData)
    ) {
      setSubjectEditorContent(template.titleData);
      setOriginalSubject((prev) => prev ?? template.titleData);
    }
  }, [template, setOriginalSubject, setSubjectEditorContent, prevTitleData]);

  const prevData = usePrevious(template?.data);

  useEffect(() => {
    if (template && template.data && !isEqual(template.data, prevData)) {
      setEditorContent(template.data as AtlasContentEditorSerializedState);
      setOriginalContent((prev) => prev ?? template.data);
    }
  }, [template, setEditorContent, setOriginalContent, prevData]);

  useEffect(() => {
    setValue("threadId", thread?.id);
  }, [thread?.id, setValue, thread?.recipientProfiles]);

  const prevEditor = usePrevious(editor);

  // Once we mount the editor, load the template in case it was in state before we had an editor
  useEffect(() => {
    if (!prevEditor && editor && template) {
      loadTemplate(template);
    }
  }, [editor, loadTemplate, prevEditor, setValue, template]);

  const prevDefaultTemplate = usePrevious(props.defaultTemplate);

  // If the default template changes, load it (or clear if changed to null)
  useEffect(() => {
    if (!isEqual(props.defaultTemplate, prevDefaultTemplate)) {
      if (props.defaultTemplate) {
        loadTemplate(props.defaultTemplate);
      } else {
        clearEditor();
        setTemplate(null);
      }
    }
  }, [clearEditor, loadTemplate, prevDefaultTemplate, props.defaultTemplate]);

  const prevMoveToInterviewPlanItemId = usePrevious(
    defaultMoveToInterviewPlanItemId
  );

  // If the default move to interview plan item id changes, update the form value
  useEffect(() => {
    if (defaultMoveToInterviewPlanItemId !== prevMoveToInterviewPlanItemId) {
      setValue(
        "moveToInterviewPlanItemId",
        defaultMoveToInterviewPlanItemId ?? null
      );
    }
  }, [
    defaultMoveToInterviewPlanItemId,
    setValue,
    prevMoveToInterviewPlanItemId,
  ]);

  const hasRequestAvailabilityBlock = useMemo(() => {
    return contentEditorHasRequestAvailabilityBlock(content);
  }, [content]);

  const hasInterviewsBlock = useMemo(() => {
    return contentEditorHasInterviewsBlock(content);
  }, [content]);

  const workflowIsRequestingAvailability = useMemo(() => {
    return !!(
      workflowType &&
      [
        PostMessageWorkflow.REQUEST_AVAILABILITY,
        PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY,
      ].includes(workflowType)
    );
  }, [workflowType]);

  const workflowIsConfirmingInterviews = useMemo(() => {
    return !!(
      workflowType &&
      [
        PostMessageWorkflow.CONFIRM_INTERVIEWS,
        PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS,
      ].includes(workflowType)
    );
  }, [workflowType]);

  const availabilityBlockMissing = useMemo(() => {
    return workflowIsRequestingAvailability && !hasRequestAvailabilityBlock;
  }, [workflowIsRequestingAvailability, hasRequestAvailabilityBlock]);

  const interviewsBlockMissing = useMemo(() => {
    return workflowIsConfirmingInterviews && !hasInterviewsBlock;
  }, [workflowIsConfirmingInterviews, hasInterviewsBlock]);

  const selfScheduleBlockMissing = useMemo(() => {
    return (
      workflowType === PostMessageWorkflow.SELF_SCHEDULE_REQUEST &&
      !contentEditorHasSelfScheduleRequestBlock(content)
    );
  }, [content, workflowType]);

  const scheduledInterviewsBeingConfirmed =
    useScheduledInterviewsBeingConfirmed(content);

  const missingBlocks = useMemo(() => {
    return [
      ...(availabilityBlockMissing
        ? [
            {
              type: PostMessageWorkflow.REQUEST_AVAILABILITY,
              disabledLabel: "Insert an availability request block.",
            },
          ]
        : []),
      ...(interviewsBlockMissing
        ? [
            {
              type: PostMessageWorkflow.CONFIRM_INTERVIEWS,
              disabledLabel: "Insert interviews block.",
            },
          ]
        : []),
      ...(selfScheduleBlockMissing
        ? [
            {
              type: PostMessageWorkflow.SELF_SCHEDULE_REQUEST,
              disabledLabel: "Insert self-schedule request block.",
            },
          ]
        : []),
    ];
  }, [
    availabilityBlockMissing,
    interviewsBlockMissing,
    selfScheduleBlockMissing,
  ]);

  const validationData = useMemo((): ValidationData => {
    if (!content || contentEditorIsEmpty(content)) {
      return {
        isValid: false,
        message: "Please enter a message.",
      };
    }

    if (!moveToInterviewPlanItemId && !currentInterviewItemId) {
      return {
        isValid: false,
        message: "Please set a Stage for the candidate's Interview plan.",
      };
    }

    if (
      !threadId &&
      (!subject || !getLexicalHasContent(JSON.stringify(subject)))
    ) {
      return {
        isValid: false,
        message: "Please enter a subject.",
      };
    }

    if (contentEditorHasUnknownVariables(content)) {
      return {
        isValid: false,
        message:
          "Your message has an un-replaced Greenhouse variable. Please replace it with a value before sending.",
      };
    }

    if (contentEditorHasUnfilledVariables(content)) {
      return {
        isValid: false,
        message:
          "Your message has an invalid variable. Please replace it with a value before sending.",
      };
    }

    if (contentEditorHasInvalidSelfScheduleRequestBlock(content)) {
      return {
        isValid: false,
        message: "Please configure or remove self-schedule block.",
      };
    }

    if (contentEditorHasInvalidInterviewsBlock(content)) {
      return {
        isValid: false,
        message: "Interviews are loading...",
      };
    }

    if (!sender) {
      return {
        isValid: false,
        message: "Please select a sender.",
      };
    }

    if (missingBlocks.length) {
      return {
        isValid: false,
        message: missingBlocks[0].disabledLabel,
      };
    }

    return {
      isValid: true,
    };
  }, [
    content,
    missingBlocks,
    currentInterviewItemId,
    sender,
    moveToInterviewPlanItemId,
    subject,
    threadId,
  ]);

  const allRecipients = useMemo(() => {
    return [
      ...(to ? [`${to} (Candidate)`] : []),
      ...recipients.map((r) => r.email),
    ];
  }, [recipients, to]);

  const NotifiedCountComponent = useMemo(() => {
    return (
      <Tooltip
        isInstant
        content={
          allRecipients.length > 0 ? allRecipients.join(", ") : undefined
        }
      >
        <p className="text-subtle text-body-md cursor-default">
          <span>{strings.pluralize("person", allRecipients.length)}</span>
          <span> will be notified</span>
        </p>
      </Tooltip>
    );
  }, [allRecipients]);

  // Once the editor is mounted, call setEditorContent to auto-insert any required blocks for the workflow
  useEffect(() => {
    if (editor) {
      setEditorContent(form.getValues().content ?? getEditorStateFromText(""));
    }
  }, [editor, form, setEditorContent]);

  useSyncDialogLeaveConfirmation({
    showConfirmation: disableLeaveConfirmation
      ? false
      : formState.isDirty && !formState.isSubmitted,
  });

  return useMemo(
    (): GuideComposeMessageState => ({
      ...props,
      form,
      templateHasChanged,
      setTemplateHasChanged,
      originalSubject,
      setOriginalSubject,
      originalContent,
      setOriginalContent,
      template,
      setTemplate,
      editor,
      contentEditorProps,
      showNotify,
      setShowNotify,
      postTemplateType,
      clearEditor,
      loadTemplate,
      missingBlocks,
      validationData,
      interviewsBlockMissing,
      availabilityBlockMissing,
      selfScheduleBlockMissing,
      taskRequirements,
      NotifiedCountComponent,
      subjectEditor,
      subjectContentEditorProps,
      scheduledInterviewsBeingConfirmed,
      guideId,
      selfScheduleInterviewId,
      setRecipients,
    }),
    [
      props,
      form,
      templateHasChanged,
      originalSubject,
      originalContent,
      template,
      editor,
      contentEditorProps,
      showNotify,
      postTemplateType,
      clearEditor,
      loadTemplate,
      missingBlocks,
      validationData,
      interviewsBlockMissing,
      availabilityBlockMissing,
      selfScheduleBlockMissing,
      taskRequirements,
      NotifiedCountComponent,
      subjectEditor,
      subjectContentEditorProps,
      scheduledInterviewsBeingConfirmed,
      guideId,
      selfScheduleInterviewId,
      setRecipients,
    ]
  );
}

/**
 *
 *  We can't use a simple useMemo here because the result triggers a change in content, causing an infinite loop
 *  Instead, store the scheduledInterviewsBeingConfirmed in state and update it when the actual interviews changes
 */
function useScheduledInterviewsBeingConfirmed(
  content: AtlasContentEditorSerializedState | undefined
) {
  const [
    scheduledInterviewsBeingConfirmed,
    setScheduledInterviewsBeingConfirmed,
  ] = useState<SerializedInterviewsNode["data"]["interviews"][number][]>([]);
  const prevScheduledInterviewsInEditor = usePrevious(
    scheduledInterviewsBeingConfirmed
  );
  useEffect(() => {
    const interviewsNodes = content?.root.children.filter(
      (node): node is SerializedInterviewsNode => node.type === "interviews"
    );

    const scheduledInterviewsInEditor =
      interviewsNodes?.flatMap((node) => node.data.interviews) ?? [];

    if (scheduledInterviewsInEditor && !prevScheduledInterviewsInEditor) {
      setScheduledInterviewsBeingConfirmed(scheduledInterviewsInEditor);
    } else if (
      prevScheduledInterviewsInEditor &&
      scheduledInterviewsInEditor &&
      !isEqual(scheduledInterviewsInEditor, prevScheduledInterviewsInEditor)
    ) {
      setScheduledInterviewsBeingConfirmed(scheduledInterviewsInEditor);
    }
  }, [content?.root.children, prevScheduledInterviewsInEditor]);

  return useMemo(
    () => scheduledInterviewsBeingConfirmed,
    [scheduledInterviewsBeingConfirmed]
  );
}
