import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";
import { Dialog, useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { BlockError } from "client/components/generic/errors/BlockError";
import { useHomepageRedirect } from "client/components/guide/useHomePageRedirect";
import { CreateOrUpdateAvailabilitySubmissionContent } from "client/guide-availability/components/CreateOrUpdateAvailabilitySubmissionDialog";
import { CreateOrUpdateAvailabilityData } from "client/guide-availability/components/CreateOrUpdateAvailabilityView";
import { ViewAvailabilityRequest } from "client/guide-availability/components/ViewAvailabilityRequest";
import { ViewAvailabilityRequestPreview } from "client/guide-availability/components/ViewAvailabilityRequestPreview";
import { useCreateAvailabilitySubmission } from "client/guide-availability/hooks/useCreateAvailabilitySubmission";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { gql } from "generated/graphql-codegen";
import { useCallback, useRef } from "react";
import {
  GuideAvailabilityRequestDataSchema,
  SerializedGuideAvailabilityRequestNode,
} from "shared/guide-availability/rich-blocks/guide-availability-request";
import useQuery from "utils/useQuery";

// node renderer
// -------------

const AVAILABILITY_REQUEST_FOR_NODE_RENDERER = gql(`
  query AvailabilityRequestForNodeRenderer($availabilityRequestId: String!) {
    guideAvailabilityRequestById(id: $availabilityRequestId) {
      createdBy {
        id
        name
        imageUrl
      }
      ...AvailabilityRequestForViewAvailabilityRequest
      ...AvailabilityRequestForCreateAvailabilitySubmission
    }
  }
`);

function ViewAvailabilityRequestNodeRenderer({
  availabilityRequestId,
}: {
  availabilityRequestId: string;
}) {
  const { data, loading } = useQuery(AVAILABILITY_REQUEST_FOR_NODE_RENDERER, {
    variables: {
      availabilityRequestId,
    },
  });
  const createSubmission = useCreateAvailabilitySubmission();
  const createSubmissionDialogStore = useDialogStore();
  const hasSubmittedRef = useRef(false);
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store: createSubmissionDialogStore,
    hasSubmittedRef,
  });
  const { isOrgUser } = useGuideAuthContext();
  const isCandidate = !isOrgUser;

  const availabilityRequest = data?.guideAvailabilityRequestById;

  const redirectOnSubmit = useHomepageRedirect();

  const onSubmitSubmission = useCallback(
    async (
      state: CreateOrUpdateAvailabilityData,
      { closeOnSubmit = true }: { closeOnSubmit?: boolean }
    ) => {
      if (availabilityRequest) {
        await createSubmission({
          state,
          availabilityRequest,
        });
        hasSubmittedRef.current = true;

        if (closeOnSubmit) {
          createSubmissionDialogStore.hide();
          redirectOnSubmit();
        }
      }
    },
    [
      availabilityRequest,
      createSubmission,
      createSubmissionDialogStore,
      redirectOnSubmit,
    ]
  );
  const onCancelSubmission = useCallback(() => {
    createSubmissionDialogStore.hide();
  }, [createSubmissionDialogStore]);
  const onShareAvailabilityClick = useCallback(() => {
    createSubmissionDialogStore.show();
  }, [createSubmissionDialogStore]);

  return (
    <>
      <ViewAvailabilityRequest
        availabilityRequest={availabilityRequest ?? undefined}
        variant={isCandidate ? "candidate" : "company"}
        onShareAvailabilityClick={onShareAvailabilityClick}
        openViewDialogOnLoad
        loading={loading}
        error={!loading && !availabilityRequest}
      />
      <Dialog
        {...dialogProps}
        store={createSubmissionDialogStore}
        variant="fullscreen"
      >
        <CreateOrUpdateAvailabilitySubmissionContent
          availabilityRequestId={availabilityRequestId}
          onSubmit={onSubmitSubmission}
          onCancel={onCancelSubmission}
        />
      </Dialog>
      <WarningDialog />
    </>
  );
}

export const renderGuideAvailabilityRequestNode =
  createNodeRenderer<SerializedGuideAvailabilityRequestNode>(({ data }) => {
    if ("preview" in data) {
      return <ViewAvailabilityRequestPreview data={data} />;
    }

    if (!("availabilityRequestId" in data)) {
      // if node rendering, we should have an availability request now?
      return <BlockError label="Can't render availability request" />;
    }

    if (!GuideAvailabilityRequestDataSchema.safeParse(data).success) {
      // If there is data in the node but it doesn't match the new block, render the old block and cast data
      return (
        <div className="bg-light-gray-200 h-full rounded-md flex p-6 mb-2 text-dark justify-center items-center">
          <div className="flex flex-col items-center text-center">
            <p className="text-body-md text-subtle mt-2">
              Unable to render this availability request
            </p>
          </div>
        </div>
      );
    }

    return <ViewAvailabilityRequestNodeRenderer {...data} />;
  });
