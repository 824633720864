import {
  AtlasContentEditorProps,
  ContentEditor,
} from "@resource/atlas/content-editor";
import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { mentionsModule } from "@resource/atlas/content-editor/mentions";
import { useOnTypeMention } from "client/hooks/useOnTypeMention";
import clsx from "clsx";
import ErrorBoundary from "components/ErrorBoundary";

export function CommentEditor({
  modules = [],
  className,
  ...props
}: AtlasContentEditorProps) {
  const onTypeMention = useOnTypeMention();
  return (
    <ErrorBoundary>
      <ContentEditor
        placeholder="Type @ to mention a teammate..."
        {...props}
        isGhost={false}
        className={clsx(
          "!bg-white grow flex flex-col w-full h-full rounded-md text-body-sm shadow-1 !py-0 max-h-[460px]",
          className
        )}
        modules={[
          withConfig(mentionsModule, {
            onTypeMention,
          }),
          ...modules,
        ]}
      />
    </ErrorBoundary>
  );
}
