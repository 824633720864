/* eslint-disable import/prefer-default-export */
import { AutoLinkPlugin as LexicalAutoLinkPlugin } from "../../../../__utils/autolink";

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%._+~#?&/=]*)/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    // Ignore '.' at the end; whilst technically valid we want to assume that's a period.
    const fullMatch = match[0].endsWith(".")
      ? match[0].substring(0, match[0].length - 1)
      : match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
    };
  },
];

export function AutoLinkPlugin() {
  return <LexicalAutoLinkPlugin matchers={MATCHERS} />;
}
