import { getVideoConferencingTypeFromConferencingType } from "client/components/conferencing/utils/mapping";
import { GoogleEventPreviewData } from "client/components/google/utils/types";
import { mapInterviewerSlotsToInterviewers } from "client/components/interviewer-slots/utils/mapping";
import { ScheduledInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { StagedScheduledInterview } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import {
  ExistingScheduledInterviewForSchedulerReviewPageFragment,
  GuideForSchedulerReviewPageFragment,
  InterviewerConfirmationCreateInput,
  InterviewerConfirmationUpdateInput,
  InterviewForSchedulerReviewPageFragment,
  UserForAuthContextFragment,
  UserMembershipForOptionalParticipantReviewPageFragment,
} from "generated/graphql-codegen/graphql";
import { isEqual, join } from "lodash";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";
import { getDefaultInterviewerInviteContent } from "shared/guide-scheduler/default-invite-content";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

import {
  InterviewReviewData,
  OptionalAttendeeUserMembership,
  SingleInterviewReviewFormData,
} from "./types";

const mapScheduledInterviewToVideoConferencing = ({
  scheduledInterview,
  scheduledInterviewGroupSettings,
}: {
  scheduledInterview: StagedScheduledInterview;
  scheduledInterviewGroupSettings?: ScheduledInterviewGroupSettings;
}):
  | GoogleEventPreviewData["scheduledInterview"]["videoConferencing"]
  | null => {
  if (
    scheduledInterview.conferencingSlot?.service === ConferencingType.OTHER &&
    scheduledInterview.conferencingSlot?.editingConferencing
  ) {
    return {
      url: scheduledInterview.conferencingSlot.editingConferencing.value,
    };
  }

  if (
    scheduledInterviewGroupSettings &&
    scheduledInterview.conferencingSlot?.isUsingGroupSettings
  ) {
    if (scheduledInterviewGroupSettings.conferencingGenerationSettings) {
      if (
        !scheduledInterviewGroupSettings.conferencingGenerationSettings
          .regenerateLink &&
        scheduledInterviewGroupSettings.existingConferencing
      ) {
        return {
          url: scheduledInterviewGroupSettings.existingConferencing.value,
        };
      }

      return {
        type: getVideoConferencingTypeFromConferencingType(
          scheduledInterviewGroupSettings.conferencingGenerationSettings.service
        ),
      };
    }
  }

  if (
    scheduledInterview.conferencingSlot?.generationSettings &&
    scheduledInterview.conferencingSlot.service
  ) {
    if (
      !scheduledInterview.conferencingSlot?.generationSettings
        ?.regenerateLink &&
      scheduledInterview.conferencingSlot?.existingConferencing
    ) {
      return {
        url: scheduledInterview.conferencingSlot.existingConferencing.value,
      };
    }

    return {
      type: getVideoConferencingTypeFromConferencingType(
        scheduledInterview.conferencingSlot.service
      ),
    };
  }

  return null;
};

const mapScheduledInterviewCollaborativeCoding = ({
  scheduledInterview: { collaborativeCodingSlot },
}: {
  scheduledInterview: StagedScheduledInterview;
}): GoogleEventPreviewData["scheduledInterview"]["collaborativeCoding"] => {
  if (
    collaborativeCodingSlot?.service ===
      CollaborativeCodingServiceType.CUSTOM &&
    collaborativeCodingSlot.editingCollaborativeCoding
  ) {
    return {
      url: collaborativeCodingSlot.editingCollaborativeCoding.value,
      type: null,
    };
  }

  if (collaborativeCodingSlot?.generationSettings) {
    if (
      !collaborativeCodingSlot.generationSettings.regenerateLink &&
      collaborativeCodingSlot.existingCollaborativeCoding
    ) {
      return {
        type: collaborativeCodingSlot.service,
        url: collaborativeCodingSlot.existingCollaborativeCoding.value,
      };
    }

    return {
      type: collaborativeCodingSlot.service,
      url: null,
    };
  }

  if (collaborativeCodingSlot?.existingCollaborativeCoding) {
    return {
      type: collaborativeCodingSlot.service,
      url: collaborativeCodingSlot.existingCollaborativeCoding.value,
    };
  }

  return {
    url: null,
    type: null,
  };
};

export const mapGuideAndScheduledInterviewToGoogleEventPreviewData = ({
  guide,
  scheduledInterview,
  existingScheduledInterview,
  currentUser,
  scheduledInterviewGroupSettings,
}: {
  guide: GuideForSchedulerReviewPageFragment;
  scheduledInterview: StagedScheduledInterview;
  existingScheduledInterview: ExistingScheduledInterviewForSchedulerReviewPageFragment | null;
  currentUser: UserForAuthContextFragment | undefined;
  scheduledInterviewGroupSettings?: ScheduledInterviewGroupSettings;
}): GoogleEventPreviewData => {
  const currentUserAsScheduler = currentUser?.currentUserMembership
    ? {
        id: currentUser.currentUserMembership.id,
        name: currentUser.currentUserMembership.name,
        email: currentUser.currentUserMembership.email,
        imageUrl: currentUser.currentUserMembership.imageUrl,
      }
    : null;
  const scheduler =
    existingScheduledInterview?.scheduler ?? currentUserAsScheduler;

  return {
    scheduledInterview: {
      id: scheduledInterview.id,
      scheduler: scheduler
        ? {
            id: scheduler.id,
            name: scheduler.name,
            email: scheduler.email,
            imageUrl: scheduler.imageUrl,
          }
        : null,
      startTime: scheduledInterview.startTime,
      endTime: scheduledInterview.endTime,
      title: scheduledInterview.title,
      isPrivate: scheduledInterview.isPrivate,
      interviewers: mapInterviewerSlotsToInterviewers(
        scheduledInterview.interviewerSlots
      ),
      videoConferencing: mapScheduledInterviewToVideoConferencing({
        scheduledInterview,
        scheduledInterviewGroupSettings,
      }),
      collaborativeCoding: mapScheduledInterviewCollaborativeCoding({
        scheduledInterview,
      }),
      interviewKit: existingScheduledInterview?.atsInterviewKitUrl,
    },
    candidate: {
      name: guide.candidate.name,
      firstName: guide.candidate.firstName,
      lastName: guide.candidate.lastName,
      email: guide.candidate.email,
      linkedInUrl: guide.candidate.linkedInUrl,
    },
    application: {
      recruiter: guide.recruiter
        ? {
            name: guide.recruiter.name,
            email: guide.recruiter.email,
            imageUrl: guide.recruiter.imageUrl,
          }
        : null,
    },
    interview: {
      title: getTitleFromLexicalJson(
        scheduledInterview.interview
          ? scheduledInterview.interview.title
          : scheduledInterview.title
      ),
    },
    organization: {
      name: guide.organization.name,
      logoUrl: guide.organization.companyLogoUrl,
      url: "",
      hideCandidateEmailInInterviewerInvites:
        guide.organization.defaultHideCandidateEmailInInterviewerInvites,
    },
    job: {
      id: guide.job.id,
      internalName: guide.job.internalName,
      displayName: guide.job.displayName,
      departments: join(
        guide.job.departments.map((d) => d.name),
        ", "
      ),
      customFields: guide.job.customFields,
      hiringManager: guide.job.hiringManager
        ? {
            name: guide.job.hiringManager.name,
            email: guide.job.hiringManager.email,
            imageUrl: guide.job.hiringManager.imageUrl,
          }
        : null,
      linkToJobDescription: guide.job.linkToJobDescription,
    },
  };
};

/** Based on previous scheduled interview data, decide if we should provide a template default or not */
function getDefaultTemplateForInterview({
  status,
  existingScheduledInterview,
  interview,
}: {
  status: ScheduledStatus | null;
  existingScheduledInterview: ExistingScheduledInterviewForSchedulerReviewPageFragment | null;
  interview: InterviewForSchedulerReviewPageFragment | null;
}) {
  const lastUsedTemplate = interview?.lastUsedCustomInstructionsTemplate;
  const currTitle = existingScheduledInterview?.interviewerConfirmationTitle;
  const currInstructions =
    existingScheduledInterview?.interviewerCustomInstructions;

  if (lastUsedTemplate) {
    if (status === ScheduledStatus.CREATED) {
      // For new interviews, pull last template
      return lastUsedTemplate;
    }

    if (
      isEqual(lastUsedTemplate.titleData, currTitle) &&
      isEqual(lastUsedTemplate.data, currInstructions)
    ) {
      // If the title and instructions match the last template, use it
      return lastUsedTemplate;
    }

    // If they changed the title or instructions since the last template, we won't use it
    return undefined;
  }

  return undefined;
}

export const mapStagedInterviewToDefaultReviewData = ({
  scheduledInterview,
  status,
  existingScheduledInterview,
  interview,
}: {
  scheduledInterview: StagedScheduledInterview;
  status: ScheduledStatus | null;
  /** Existing scheduled interview for existing confirmation title and custom instructions */
  existingScheduledInterview: ExistingScheduledInterviewForSchedulerReviewPageFragment | null;
  /** Job interview to get last used template for confirmation details */
  interview: InterviewForSchedulerReviewPageFragment | null;
}): InterviewReviewData => {
  const defaultTemplate = getDefaultTemplateForInterview({
    status,
    existingScheduledInterview,
    interview,
  });

  return {
    id: scheduledInterview.id,
    status,
    interviewerConfirmationTemplate: defaultTemplate,
    interviewerConfirmationTitle:
      existingScheduledInterview?.interviewerConfirmationTitle ??
      defaultTemplate?.titleData ??
      getDefaultInterviewerInviteContent().title,
    interviewerCustomInstructions:
      existingScheduledInterview?.interviewerCustomInstructions ??
      defaultTemplate?.data,
  };
};

export function mapUserMembershipFragmentToOptionalParticipant(
  userMembership: UserMembershipForOptionalParticipantReviewPageFragment
): OptionalAttendeeUserMembership {
  return {
    id: userMembership.id,
    name: userMembership.name,
    firstName: userMembership.firstName,
    lastName: userMembership.lastName,
    imageUrl: userMembership.imageUrl,
    email: userMembership.email,
  };
}

function baseMapReviewFormDataToUpsertInterviewerConfirmationInput(
  reviewFormData: SingleInterviewReviewFormData
): Pick<
  InterviewerConfirmationCreateInput | InterviewerConfirmationUpdateInput,
  | "sendNotifications"
  | "customInstructions"
  | "templateId"
  | "optionalAttendeeUserMembershipIds"
> {
  return {
    sendNotifications: reviewFormData.sendNotifications,
    customInstructions: reviewFormData.interviewerCustomInstructions,
    templateId: reviewFormData.interviewerConfirmationTemplate?.id,
    optionalAttendeeUserMembershipIds:
      reviewFormData.optionalAttendeeUserMemberships?.map(
        (userMembership) => userMembership.id
      ),
  };
}

export function mapReviewFormDataToInterviewerConfirmationCreateInput(
  reviewFormData: SingleInterviewReviewFormData
): InterviewerConfirmationCreateInput {
  if (!reviewFormData.addToGoogleCalendarId) {
    // Zod schema should validate this so should never happen
    throw new Error("Google Calendar ID is required for creating an interview");
  }

  return {
    ...baseMapReviewFormDataToUpsertInterviewerConfirmationInput(
      reviewFormData
    ),
    addToGoogleCalendarId: reviewFormData.addToGoogleCalendarId,
    title: reviewFormData.interviewerConfirmationTitle,
    // Note: At the moment we don't want to include notes for created interviews during panel scheduling
    note: null,
  };
}
export function mapReviewFormDataToInterviewerConfirmationUpdateInput(
  reviewFormData: SingleInterviewReviewFormData
): InterviewerConfirmationUpdateInput {
  return {
    ...baseMapReviewFormDataToUpsertInterviewerConfirmationInput(
      reviewFormData
    ),
    addToGoogleCalendarId: reviewFormData.addToGoogleCalendarId,
    title: reviewFormData.interviewerConfirmationTitle,
    note: reviewFormData.note,
  };
}
