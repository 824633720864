import { Avatar } from "@resource/atlas/avatar/Avatar";
import { StackedCard } from "client/components/guide/StackedCard";
import clsx from "clsx";
import { DateTime } from "luxon";
import { ReactNode, useCallback, useMemo } from "react";

import { GuidePostForPreview } from "./utils/types";

export type GuidePostPreviewDisplayProps = {
  post: GuidePostForPreview;
  stackCount?: number;
  /** @default 2 */
  previewLines?: 1 | 2 | 3;
  // TODO: Implement component using new patterns and render directly instead of passing
  ViewedIndicator?: ReactNode;
};

export function GuidePostPreviewDisplay({
  post,
  stackCount = 1,
  previewLines = 2,
  ViewedIndicator,
}: GuidePostPreviewDisplayProps) {
  const { title, isReply, messagePreview, sender, createdAt, href } = post;
  const senderName = useMemo(() => {
    if (!sender.name) {
      return "Someone";
    }

    return sender.name;
  }, [sender.name]);

  const onClick = useCallback(() => {
    window.open(href, "_blank");
  }, [href]);

  return (
    <StackedCard
      stackCount={stackCount}
      onClick={onClick}
      className="w-full max-w-full"
    >
      <a href={href} target="_blank" rel="noreferrer">
        <div className="p-4 text-body-md flex items-center gap-3">
          <div className="space-y-2 flex-grow">
            <div className="flex justify-between items-center">
              {title && (
                <p className="text-body-md-heavy">
                  {isReply ? "Re: " : ""}
                  {title}
                </p>
              )}
            </div>
            {messagePreview && (
              <p
                className={clsx("text-body-sm text-subtle", {
                  "line-clamp-3": previewLines === 3,
                  "line-clamp-2": previewLines === 2,
                  "line-clamp-1": previewLines === 1,
                })}
              >
                {messagePreview}
              </p>
            )}
            <div className="flex space-x-2">
              <Avatar size="xs" name={senderName} image={sender.imageUrl} />
              <span className="truncate">{senderName}</span>
              <span className="text-subtle flex-shrink-0">
                {DateTime.fromJSDate(createdAt).toFormat("LLL d 'at' h:mm a")}
              </span>
            </div>
          </div>
          {ViewedIndicator}
        </div>
      </a>
    </StackedCard>
  );
}
