import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useLogEvent } from "analytics";
import { formatEntity } from "shared/constants/entities";

import { useArchiveSchedulingRequest } from "./useArchiveSchedulingRequest";
import { useCompleteSchedulingRequest } from "./useCompleteSchedulingRequest";
import { useReopenSchedulingRequest } from "./useReopenSchedulingRequest";
import { useUnarchiveSchedulingRequest } from "./useUnarchiveSchedulingRequest";

export function useSchedulingTaskMenuItems({
  schedulingRequestId,
  analyticsComponent,
  isCompleted,
  isArchived,
  hideEditing,
  onReopen,
  onEdit,
}: {
  schedulingRequestId: string;
  analyticsComponent: string;
  isCompleted: boolean;
  isArchived: boolean;
  hideEditing?: boolean;
  onReopen: () => void;
  onEdit?: () => void;
}) {
  const [archiveSchedulingRequest] = useArchiveSchedulingRequest();
  const [completeSchedulingRequest] = useCompleteSchedulingRequest();
  const [reopenSchedulingRequest] = useReopenSchedulingRequest();
  const [unarchiveSchedulingRequest] = useUnarchiveSchedulingRequest();
  const logEvent = useLogEvent({
    component: analyticsComponent,
  });

  const menuItems = useMenuItems(
    (i) => {
      const archivedItem = i.item({
        key: "archive",
        children: `Archive ${formatEntity("request")}`,
        isDestructive: true,
        onClick: () => {
          logEvent("Archive Scheduling Request Clicked", {
            id: schedulingRequestId,
          });
          archiveSchedulingRequest({
            variables: {
              input: {
                id: schedulingRequestId,
              },
            },
          });
        },
      });

      if (isArchived) {
        return [
          i.item({
            key: "unarchive",
            children: `Unarchive ${formatEntity("request")}`,
            onClick: () => {
              logEvent("Unarchive Scheduling Request Clicked", {
                id: schedulingRequestId,
              });
              unarchiveSchedulingRequest({
                variables: {
                  input: {
                    id: schedulingRequestId,
                  },
                },
              });
            },
          }),
        ];
      }

      if (isCompleted) {
        return [
          i.item({
            key: "reopen",
            children: `Reopen ${formatEntity("request")}`,
            onClick: () => {
              logEvent("Reopen Scheduling Request Clicked", {
                id: schedulingRequestId,
              });
              if (onReopen) {
                onReopen();
              } else {
                reopenSchedulingRequest({
                  variables: {
                    input: {
                      id: schedulingRequestId,
                    },
                  },
                });
              }
            },
          }),
          archivedItem,
        ];
      }

      return [
        i.item({
          key: "completed",
          children: `Complete ${formatEntity("request")}`,
          onClick: () => {
            logEvent("Complete Scheduling Request Clicked", {
              id: schedulingRequestId,
            });
            completeSchedulingRequest({
              variables: {
                input: {
                  id: schedulingRequestId,
                },
              },
            });
          },
        }),
        ...(onEdit && !hideEditing
          ? [
              i.item({
                key: "edit",
                children: `Edit ${formatEntity("request")} requirements`,
                onClick: async () => {
                  onEdit();
                },
              }),
            ]
          : []),
        archivedItem,
      ];
    },
    [
      isArchived,
      isCompleted,
      onEdit,
      hideEditing,
      logEvent,
      schedulingRequestId,
      archiveSchedulingRequest,
      unarchiveSchedulingRequest,
      onReopen,
      reopenSchedulingRequest,
      completeSchedulingRequest,
    ]
  );

  return menuItems;
}
