import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPossibleNumber = (possiblePhone: string | null): boolean => {
  if (!possiblePhone) {
    return false;
  }

  if (possiblePhone.includes("http")) {
    return false;
  }

  try {
    const parsedPhone = phoneUtil.parseAndKeepRawInput(possiblePhone);

    return phoneUtil.isPossibleNumber(parsedPhone);
  } catch (_error) {
    return false;
  }
};

export const isValidPhoneNumber = (phone: string): boolean => {
  try {
    const parsedPhone = phoneUtil.parseAndKeepRawInput(phone);

    return phoneUtil.isValidNumber(parsedPhone);
  } catch (_error) {
    return false;
  }
};

export const displayPhoneNumber = (phone: string): string => {
  try {
    const parsedPhone = phoneUtil.parseAndKeepRawInput(
      phone,
      phone.startsWith("+") ? undefined : "US"
    );

    return phoneUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error parsing phone number", e);

    return phone;
  }
};

export const formatPhoneNumberForDB = (phone: string, regionCode?: string) =>
  phoneUtil.format(
    phoneUtil.parseAndKeepRawInput(phone, regionCode),
    PhoneNumberFormat.E164
  );

export const getPhoneRegions = () => phoneUtil.getSupportedRegions();

export const getCountryCodeForRegion = (region: string) =>
  phoneUtil.getCountryCodeForRegion(region);

export const getRegionCodeForNumber = (phone: string) =>
  phoneUtil.getRegionCodeForNumber(phoneUtil.parseAndKeepRawInput(phone));
