import { useAuthContext } from "auth/context";
import { InterviewerForSelectionFragment } from "generated/graphql-codegen/graphql";
import { uniq } from "lodash";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useOrgZoomHosts } from "./useOrgZoomHosts";

export function getPossibleUserMembershipIdsForZoomHostSelection({
  interviewerUserMembershipIds,
  userMembershipId,
  orgUsers,
}: {
  interviewerUserMembershipIds: string[];
  userMembershipId?: string;
  orgUsers?: InterviewerForSelectionFragment[];
}) {
  return uniq(
    [
      ...(interviewerUserMembershipIds ?? []),
      userMembershipId,
      ...(orgUsers?.map(({ id }) => id) ?? []),
    ].filter(filterOutNullsAndUndefined)
  );
}

export function usePossibleZoomHostUserMembershipIds({
  interviewerUserMembershipIds,
}: {
  interviewerUserMembershipIds: string[];
}) {
  const { user } = useAuthContext();
  const { users: orgZoomUsers } = useOrgZoomHosts();

  return useMemo(
    () => ({
      possibleUserMembershipIdsForSelection:
        getPossibleUserMembershipIdsForZoomHostSelection({
          interviewerUserMembershipIds,
          userMembershipId: user?.currentUserMembership?.id,
          orgUsers: orgZoomUsers,
        }),
      orgZoomUsers,
    }),
    [
      interviewerUserMembershipIds,
      user?.currentUserMembership?.id,
      orgZoomUsers,
    ]
  );
}
