/* eslint-disable class-methods-use-this, @typescript-eslint/no-use-before-define, no-underscore-dangle */
import "./MentionNode.sass";

import clsx from "clsx";
import {
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
} from "lexical";
import { Spread } from "type-fest";

import { createComponentUtils } from "../../../__utils/atlas";
import { MentionValue } from "./types";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Mention";

const { el } = createComponentUtils(COMPONENT_NAME);

// lexical helpers
// ---------------

export function $createMentionNode(
  id: string,
  value: MentionValue
): MentionNode {
  return new MentionNode(id, value);
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode {
  return node instanceof MentionNode;
}

// serialized type
// ---------------

export type SerializedMentionNode = Spread<
  {
    type: "mention";
    version: 1;
    id: string;
    value: MentionValue;
  },
  SerializedLexicalNode
>;

// mention component
// ------------------

type MentionProps = {
  nodeKey: string;
  // eslint-disable-next-line react/no-unused-prop-types
  id: string;
  value: MentionValue;
};

function MentionToken({ nodeKey, value }: MentionProps) {
  return (
    <span className={clsx(el`node`)} data-node-key={nodeKey}>
      {value.name}
    </span>
  );
}

// mention node
// -------------

export class MentionNode extends DecoratorNode<JSX.Element> {
  __id: string;

  getId() {
    return this.__id;
  }

  __value: MentionValue;

  setValue(value: MentionValue) {
    this.getWritable().__value = value;
  }

  static getType(): string {
    return "mention";
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__id, node.__value, node.__key);
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(serializedNode.id, serializedNode.value);
  }

  constructor(id: string, value: MentionValue, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__value = value;
  }

  exportJSON(): SerializedMentionNode {
    return {
      type: "mention",
      version: 1,
      id: this.__id,
      value: this.__value,
    };
  }

  createDOM(): HTMLElement {
    return document.createElement("span");
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <MentionToken nodeKey={this.__key} id={this.__id} value={this.__value} />
    );
  }
}
