import { Button as AriaButton } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasArchive } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { useLogEvent } from "analytics";
import { useCallback } from "react";
import { formatEntity } from "shared/constants/entities";

import { useUnarchiveSchedulingRequest } from "./hooks/useUnarchiveSchedulingRequest";

export function SchedulingTaskArchivedBanner({
  schedulingRequestId,
}: {
  schedulingRequestId: string;
}) {
  const [unarchive, { loading }] = useUnarchiveSchedulingRequest();
  const logEvent = useLogEvent({
    component: "SchedulingTaskArchivedBanner",
  });
  const onClick = useCallback(() => {
    logEvent("Unarchive Scheduling Request Clicked", {
      id: schedulingRequestId,
    });
    unarchive({
      variables: {
        input: {
          id: schedulingRequestId,
        },
      },
    });
  }, [logEvent, schedulingRequestId, unarchive]);

  return (
    <div className="w-full bg-white pb-2">
      <div className="bg-red-50 py-2 px-5 flex text-red-500 items-center justify-between">
        <div className="flex space-x-2">
          <Icon content={atlasArchive} />
          <span className="text-body-md">
            This {formatEntity("request")} is archived.
          </span>
        </div>
        <AriaButton
          className="flex border border-white text-body-md bg-white rounded-md px-[10px] py-[6px] w-[100px] justify-center hover:bg-red-100 hover:border-red-500 transition-colors"
          onClick={onClick}
        >
          {!loading ? (
            "Unarchive"
          ) : (
            <LoadingIndicator size="small" className="text-red-500" />
          )}
        </AriaButton>
      </div>
    </div>
  );
}
