import { useSubscription } from "@apollo/client";
import { Button } from "@resource/atlas/button-v2";
import { serviceAria } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View, ViewProps } from "@resource/atlas/view/View";
import { useFlags } from "client/lib/launchdarkly";
import { useGuideIdContext } from "client/utils/guide-id-provider";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import { useState } from "react";
import { formatEntity } from "shared/constants/entities";

import { useAssistantContext } from "../context/AssistantContext";

type ViewWithAssistantToggleProps = ViewProps & {
  children: React.ReactNode;
};

export function ViewWithAssistantToggle({
  children,
  ...props
}: ViewWithAssistantToggleProps) {
  const assistantContext = useAssistantContext();
  const guideIdContext = useGuideIdContext();
  const { [FeatureFlags.ASSIST_WITH_ARIA_AI]: assistWithAriaAiEnabled } =
    useFlags();

  const unreadCount = useUnreadMessageCount({
    guideId: guideIdContext?.guideId,
  });

  return (
    <View
      {...props}
      fitContent
      header={{
        ...props.header,
        rightActions: assistWithAriaAiEnabled ? (
          <div className="flex items-center justify-center gap-2 relative">
            {props.header?.rightActions}
            <div className="ml-1.5 w-[.0625rem] bg-gray-border h-full" />
            <Tooltip
              content={
                assistantContext?.isOpen
                  ? `Hide ${formatEntity("automation", {
                      capitalize: true,
                    })}`
                  : `Show ${formatEntity("automation", {
                      capitalize: true,
                    })}`
              }
              isInstant
              side="bottom"
            >
              <Button
                icon={serviceAria}
                negativeMargin="right"
                isGhost
                className="w-8"
                onClick={() =>
                  assistantContext?.setIsOpen(!assistantContext.isOpen)
                }
                badge={unreadCount}
              />
            </Tooltip>
          </div>
        ) : (
          props.header?.rightActions
        ),
      }}
    >
      {children}
    </View>
  );
}

type UseUnreadMessageCountProps = {
  guideId?: string;
};

const UNREAD_MESSAGE_COUNT_SUBSCRIPTION = gql(`
  subscription UnreadMessageCountSubscription($guideId: ID!) {
    guideUnreadCount(guideId: $guideId)
  }
`);

export function useUnreadMessageCount({ guideId }: UseUnreadMessageCountProps) {
  const [unreadCount, setUnreadCount] = useState(0);

  useSubscription(UNREAD_MESSAGE_COUNT_SUBSCRIPTION, {
    variables: {
      guideId: guideId ?? "",
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const count = subscriptionData.data?.guideUnreadCount;
      if (typeof count === "number") {
        setUnreadCount(count);
      }
    },
    skip: !guideId,
  });

  return unreadCount;
}
