import { HTMLAttributeAnchorTarget, useCallback } from "react";

const useExtensionLink = () => {
  const generateHref = useCallback((href: string) => {
    const url = new URL(href);
    url.searchParams.append("guide_open", "1");
    return url.href;
  }, []);

  const navigate = useCallback(
    async (href: string, target?: HTMLAttributeAnchorTarget) => {
      const generatedHref = generateHref(href);
      const openHref = () => {
        window.focus();
        window.open(generatedHref, target ?? "_blank");
      };
      openHref();
    },
    [generateHref]
  );

  return { generateHref, navigate };
};

export default useExtensionLink;
