type Entity =
  | "survey"
  | "message"
  | "guide"
  | "guide template"
  | "interview plan"
  | "interview"
  | "interviewer"
  | "interview team"
  | "follower"
  | "recipient"
  | "job template"
  | "team member"
  | "cover image"
  | "stage default"
  | "overview content"
  | "action"
  | "scheduling request"
  | "request"
  | "rescheduling request"
  | "availability request"
  | "availability submission"
  | "scheduling request template"
  | "scheduling option"
  | "interviewer slot"
  | "self-schedule request"
  | "job"
  | "booking link"
  | "booking link meeting"
  | "training path"
  | "tag"
  | "tag group"
  | "automation"
  | "automation actor";

const mapEntityToReadableName = (entity: Entity, plural = false): string => {
  switch (entity) {
    case "guide":
      return plural ? "candidate portals" : "candidate portal";
    case "guide template":
      return plural
        ? "candidate portal templates"
        : "candidate portal template";
    case "overview content":
      return "overview";
    case "stage default":
      return plural ? "stage templates" : "stage template";
    case "action":
      return plural ? "requests" : "request";
    case "scheduling request":
      return plural ? "scheduling tasks" : "scheduling task";
    case "request":
      return plural ? "tasks" : "task";
    case "rescheduling request":
      return plural ? "reschedule tasks" : "reschedule task";
    case "availability request":
      return plural ? "availability requests" : "availability request";
    case "availability submission":
      return plural ? "availability submissions" : "availability submission";
    case "scheduling option":
      return plural ? "scheduling options" : "scheduling option";
    case "interviewer slot":
      return plural ? "required interviewers" : "required interviewer";
    case "self-schedule request":
      return plural ? "self-schedule requests" : "self-schedule request";
    case "booking link":
      return plural ? "booking links" : "booking link";
    case "booking link meeting":
      return plural ? "meetings" : "meeting";
    case "interviewer":
      return plural ? "interviewers" : "interviewer";
    case "training path":
      return plural ? "training paths" : "training path";
    case "tag":
      return plural ? "tags" : "tag";
    case "tag group":
      return plural ? "tag groups" : "tag group";
    case "automation":
      return "aria";
    case "automation actor":
      return plural ? "automation users" : "automation user";
    default:
      return plural ? `${entity}s` : entity;
  }
};

/**
 *
 * @param type the type of entity
 * @param options whether to pluralize and/or capitalize the first letter.
 *   Both default to false
 * @returns the formatted entity string
 */
export const formatEntity = (
  type: Entity,
  options?: { plural?: boolean; capitalize?: boolean; titleCase?: boolean }
): string => {
  const entity = mapEntityToReadableName(type, options?.plural);
  if (options?.capitalize) {
    return `${entity[0]?.toUpperCase() ?? ""}${entity.slice(1)}`;
  }
  if (options?.titleCase) {
    return entity
      .split(" ")
      .map((word) => `${word[0]?.toUpperCase() ?? ""}${word.slice(1)}`)
      .join(" ");
  }
  return entity;
};
