import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasChevronDown } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { MultiStepView } from "@resource/atlas/multi-step-view/MultiStepView";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { ViewProps } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { NavigationError } from "client/components/generic/misc/Navigator";
import { CandidateTimezoneSelect } from "client/components/generic/select/CandidateTimezoneSelect";
import {
  GuideComposeMessageForm,
  GuideComposeMessageFormProps,
} from "client/message-composer/__components/GuideComposeMessageForm";
import {
  useGuideComposeMessageState,
  UseGuideComposeMessageStateProps,
} from "client/message-composer/__hooks/useGuideComposeMessageState";
import { EditTemplateForm } from "client/scheduling-task-workflows/EditTemplateForm";
import { useSendPreviewEmailPopover } from "components/SendPreviewEmailPopover/useSendPreviewEmailPopover";
import { gql } from "generated/graphql-codegen";
import { PostMessageDialogGuideFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";
import { getEditorStateFromText } from "shared/utils/lexical";
import useMutation from "utils/useMutation";
import { contentEditorIsEmpty } from "utils/validation";

import { useDefaultRecipientsForGuide } from "../__hooks/useDefaultRecipientsForGuide";
import { useSchedulingRequestGuidePostType } from "../__hooks/useSchedulingRequestGuidePostType";
import { useValidationOnSend } from "../__hooks/useValidationOnSend";
import { mapWorkflowTypeToGraphQLInput } from "../utils";
import { PostMessageSendWarningsDialog } from "./PostMessageSendWarningsDialog";

const SEND_TEST_MESSAGE_EMAIL = gql(`
mutation SendTestMessageEmailForDialog($input: SendGuidePostTestEmailInput!) {
    sendGuidePostTestEmail(input: $input) {
      success
      code
      message
    }
  }
`);

const POST_MESSAGE = gql(`
mutation PostMessageForDialog($input: CreateGuidePostV2Input!) {
    createGuidePostV2(input: $input) {
      success
      code
      message
      guidePost {
        id
        guide {
          id
          status
          ...LatestUpdateExtensionGuide
          currentInterviewPlanItem {
            id
            title
          }
          openSelfScheduleRequests {
            ...SelfScheduleRequestForOpenRequestsSection
          }
        }
      }
      schedulingRequest {
        id
        status
        taskStatus
        archivedAt
        guideId
        organizationId
        requestAdditionalAvailabilityPost {
          id
        }
        requestAvailabilityPost {
          id
        }
        interviewConfirmationPost {
          id
        }
        rescheduleConfirmationPost {
          id
        }
      }
    }
  }
`);

export type PostMessageViewProps = {
  guide: PostMessageDialogGuideFragment;
  workflowType?: UseGuideComposeMessageStateProps["workflowType"];
  thread?: UseGuideComposeMessageStateProps["thread"];
  onCompleted: () => void;
  onDismiss: () => void;
  viewProps?: ViewProps<"div">;
  composeForm?: Partial<GuideComposeMessageFormProps>;
  schedulingRequestId?: string;
  selfScheduleInterviewId?: string;
};

export function PostMessageView({
  guide,
  onCompleted,
  onDismiss,
  workflowType,
  thread,
  viewProps,
  composeForm,
  schedulingRequestId,
  selfScheduleInterviewId,
}: PostMessageViewProps) {
  const logEvent = useLogEvent({
    component: "PostMessageDialog",
  });
  const defaultRecipients = useDefaultRecipientsForGuide({ guide });

  const composeState = useGuideComposeMessageState({
    workflowType,
    guideId: guide.id,
    allThreads: guide.posts,
    thread: thread ?? null,
    timezone: guide.candidate.timezone,
    candidateEmail: guide.candidate.email,
    currentInterviewItemId: guide.currentInterviewPlanItem?.id,
    defaultRecipients,
    defaultTemplate:
      guide.defaultCandidateInterviewConfirmationTemplate ??
      guide.defaultCandidateRescheduleInterviewConfirmationTemplate ??
      guide.defaultRequestAvailabilityTemplate ??
      guide.defaultRequestAdditionalAvailabilityTemplate ??
      guide.defaultRequestSelfScheduleTemplate,
    defaultMoveToInterviewPlanItemId:
      guide.defaultMoveToInterviewPlanItem?.id ??
      guide.currentInterviewPlanItem?.id ??
      guide.interviewPlan?.items[0]?.id,
    selfScheduleInterviewId,
  });

  const { validationData, form, NotifiedCountComponent } = composeState;
  const [postMessage, { loading: isSubmitting }] = useMutation(POST_MESSAGE, {
    onCompleted: () => {
      onCompleted();
    },
  });

  const [sendTestEmail] = useMutation(SEND_TEST_MESSAGE_EMAIL);
  const {
    popover: sendEmailPreviewPopover,
    popoverState: sendEmailPreviewPopoverState,
    setTriggerElement: setSendEmailPreviewPopoverTriggerElement,
  } = useSendPreviewEmailPopover({
    onSendPreview: async ({ to: previewTo }) => {
      const formValues = form.getValues();

      await sendTestEmail({
        variables: {
          input: {
            guideId: guide.id,
            title: JSON.stringify(formValues.subject),
            senderId: formValues.sender.id,
            to: previewTo,
            content: formValues.content,
            threadId: formValues.threadId,
          },
        },
      });
    },
  });
  const schedulingRequestGuidePostType =
    useSchedulingRequestGuidePostType(workflowType);

  const canCompleteSchedulingTask = useMemo(() => {
    return (
      schedulingRequestId &&
      (workflowType === PostMessageWorkflow.CONFIRM_INTERVIEWS ||
        workflowType === PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS)
    );
  }, [schedulingRequestId, workflowType]);

  const schedulingTaskNavigator = useGlobalNavigator();

  const onSubmit = useCallback(
    async ({ completeTask }: { completeTask?: boolean } = {}) => {
      const formValues = form.getValues();
      logEvent("Send Message Clicked", {
        workflowType,
        completeTask,
      });

      await postMessage({
        variables: {
          input: {
            guideId: guide.id,
            hiringDecision: formValues.status,
            post: {
              threadId: formValues.threadId,
              to: formValues.to ?? null,
              title: JSON.stringify(
                formValues.threadId
                  ? getEditorStateFromText("")
                  : formValues.subject
              ),
              senderId: formValues.sender.id,
              content: contentEditorIsEmpty(formValues.content)
                ? null
                : formValues.content,
              moveToInterviewPlanItemId: formValues.moveToInterviewPlanItemId,
              recipients: formValues.recipients.map((r) => ({
                isCandidate: r.isCandidate,
                email: r.email,
                userMembershipId: r.isCandidate ? null : r.id,
              })),
              postTemplateId: composeState.template?.id,
              workflowType: mapWorkflowTypeToGraphQLInput(workflowType),
            },
            ...(schedulingRequestGuidePostType &&
              schedulingRequestId && {
                schedulingRequest: {
                  schedulingRequestId,
                  type: schedulingRequestGuidePostType,
                  completeTask,
                },
              }),
            candidateTimezone: guide.candidate.timezone,
          },
        },
      });

      if (completeTask && schedulingTaskNavigator) {
        try {
          schedulingTaskNavigator.navigate("candidateProfile", {
            guideId: guide.id,
          });
        } catch (error) {
          // TODO: Remove after scheduler V2 is fully rolled out. This call fails when confirming candidates after scheduling
          // in V1 due to a navigator context conflict with SchedulingCompletionPromptsNavigator.
          if (error instanceof NavigationError) {
            console.error("Navigation error:", error.message);
            // Ensure we catch context problems in dev without breaking the app.
            if (process.env.NODE_ENV === "development") {
              throw error;
            }
          } else {
            throw error;
          }
        }
      }

      logEvent("Message Sent", {
        workflowType,
      });
    },
    [
      logEvent,
      workflowType,
      composeState.template?.id,
      form,
      guide.candidate.timezone,
      guide.id,
      postMessage,
      schedulingRequestGuidePostType,
      schedulingRequestId,
      schedulingTaskNavigator,
    ]
  );

  const { dialogProps: sendWarningsDialogProps, onSubmitWithWarnings } =
    useValidationOnSend<{
      completeTask: boolean;
    }>({
      guide,
      composeState,
      onSubmit,
    });

  const onSendOnly = useCallback(() => {
    onSubmitWithWarnings({ completeTask: false });
  }, [onSubmitWithWarnings]);

  const onSendAndComplete = useCallback(() => {
    onSubmitWithWarnings({ completeTask: true });
  }, [onSubmitWithWarnings]);

  return (
    <>
      <MultiStepView
        orderedViews={["compose", "edit-template"]}
        onDismiss={onDismiss}
        views={{
          compose: ({ setView }) => (
            <GuideComposeMessageForm
              {...composeForm}
              {...viewProps}
              onEditTemplate={() => setView("edit-template")}
              header={{
                rightActions: (
                  <CandidateTimezoneSelect
                    candidate={guide.candidate}
                    labelType="abbreviation"
                    rootProps={{
                      placement: "bottom",
                    }}
                  />
                ),
                ...viewProps?.header,
              }}
              state={composeState}
              guide={guide}
              footer={{
                leftActions: NotifiedCountComponent,
                rightActions: (
                  <ButtonGroup>
                    <OptionalTooltip content={validationData.message} isInstant>
                      <Button
                        isGhost
                        ref={setSendEmailPreviewPopoverTriggerElement}
                        onClick={() => {
                          logEvent("Preview Button Clicked");
                          sendEmailPreviewPopoverState.show();
                        }}
                        disabled={!validationData.isValid}
                      >
                        Preview
                      </Button>
                    </OptionalTooltip>
                    <OptionalTooltip content={validationData.message} isInstant>
                      {canCompleteSchedulingTask ? (
                        <SchedulingRequestSubmitWithCompleteButton
                          onSendOnly={form.handleSubmit(onSendOnly)}
                          onSendAndComplete={form.handleSubmit(
                            onSendAndComplete
                          )}
                          disabled={!validationData.isValid}
                          isLoading={isSubmitting}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          onClick={form.handleSubmit(onSendOnly)}
                          disabled={!validationData.isValid}
                          isLoading={isSubmitting}
                        >
                          Send message
                        </Button>
                      )}
                    </OptionalTooltip>
                  </ButtonGroup>
                ),
                ...viewProps?.footer,
              }}
            />
          ),
          "edit-template": ({ setView }) =>
            composeState.template ? (
              <EditTemplateForm
                postTemplate={composeState.template}
                organization={guide.organization}
                onBack={() => setView("compose")}
                onUpdate={(pt) => {
                  composeState.loadTemplate(pt, {
                    shouldDirty: false,
                  });
                  setView("compose");
                }}
              />
            ) : null,
        }}
      />
      {sendEmailPreviewPopover}
      <PostMessageSendWarningsDialog {...sendWarningsDialogProps} />
    </>
  );
}

type SchedulingRequestSubmitWithCompleteButtonProps = {
  onSendOnly: () => void;
  onSendAndComplete: () => void;
  disabled?: boolean;
  isLoading?: boolean;
};

function SchedulingRequestSubmitWithCompleteButton({
  onSendOnly,
  onSendAndComplete,
  disabled,
  isLoading,
}: SchedulingRequestSubmitWithCompleteButtonProps) {
  const menuItems = useMenuItems(
    (i) => {
      return [
        i.item({
          key: "send-only",
          children: "Send only",
          onClick: () => {
            onSendOnly();
          },
        }),
      ];
    },
    [onSendOnly]
  );

  return (
    <div className="flex flex-row space-x-[1px]">
      <Button
        variant="primary"
        onClick={onSendAndComplete}
        disabled={disabled}
        isLoading={isLoading}
        className="!rounded-r-none"
      >
        Send message and complete task
      </Button>
      <Menu.Root placement="top">
        <Menu.Trigger>
          <Button
            variant="primary"
            icon={atlasChevronDown}
            className="!rounded-l-none"
            disabled={disabled || isLoading}
          />
        </Menu.Trigger>
        <Menu.Content items={menuItems} />
      </Menu.Root>
    </div>
  );
}
