import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_EDITOR, SELECTION_CHANGE_COMMAND } from "lexical";
import {
  Children,
  cloneElement,
  ComponentType,
  createContext,
  isValidElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";

import { useEvent, useStaticValue } from "../../../../__utils/react";

// register update listeners
// -------------------------

type UpdateListener = () => void;

type RegisterUpdateListenersProps = {
  children: ReactNode;
  updateListeners: UpdateListener[];
};

/**
 * Registers all provided update listeners in a single listener for Lexical
 * state and selection updates.
 *
 * This is more efficient than registering each listener independently and
 * makes it possible to batch the resulting React state updates, preventing
 * bugs that would be caused by multiple re-renders with only partially updated
 * state data.
 */
function RegisterUpdateListeners({
  children,
  updateListeners,
}: RegisterUpdateListenersProps) {
  const [editor] = useLexicalComposerContext();

  const executeListeners = useEvent(() =>
    // TODO: drop `batchedUpdates` after upgrading to React 18
    batchedUpdates(() => updateListeners.forEach((listener) => listener()))
  );

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) =>
          editorState.read(executeListeners)
        ),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            executeListeners();
            return false;
          },
          COMMAND_PRIORITY_EDITOR
        )
      ),
    [executeListeners, editor]
  );
  return <>{children}</>;
}

// create sub-context
// ------------------

export type SubcontextHook<V> = () => Readonly<
  [updateListener: () => void, value: V]
>;

type SubproviderProps = {
  children: ReactNode;
  updateListeners: UpdateListener[];
};

/**
 * Creates a context provider and consumer hook pair from a hook that
 * returns an update listener and a context value.
 *
 * Each sub-context receives their parent's update listeners and adds
 * their own, so that the complete list of listeners can be passed to
 * RegisterUpdateListeners, which will then register all listeners at
 * once and batch their state updates.
 */
function createSubcontext<V>(useHook: SubcontextHook<V>, name: string) {
  const Context = createContext<V>(undefined as unknown as V);

  function Subprovider({
    children,
    updateListeners: parentUpdateListeners,
  }: SubproviderProps) {
    const [updateListener, value] = useHook();
    const updateListeners = useMemo(
      () => [...parentUpdateListeners, updateListener],
      [parentUpdateListeners, updateListener]
    );

    const child = Children.toArray(children)[0];
    if (!isValidElement(child)) throw new Error("Unexpected non-element child");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Fussy types with the cloneElement API.
    const childWithUpdateListeners = cloneElement(child, { updateListeners });
    return (
      <Context.Provider value={value}>
        {childWithUpdateListeners}
      </Context.Provider>
    );
  }

  function useSubcontext() {
    const value = useContext(Context);
    if (!value)
      throw new Error(
        `${name} can only be used inside a SelectionContextProvider`
      );
    return value;
  }

  return [Subprovider, useSubcontext] as const;
}

// create selection context
// ------------------------

type SelectionContextHookValue<V> = V extends SubcontextHook<infer Value>
  ? Value
  : never;
type ConsumerHooks<V, T extends Record<string, SubcontextHook<V>>> = {
  [K in keyof T]: () => SelectionContextHookValue<T[K]>;
};

/**
 * Creates a top-level provider with a tree of sub-context providers and
 * an object containing hooks for accessing the sub-context values, from a
 * map between sub-context names and hooks that return update listeners and
 * the sub-context values.
 *
 * Splitting the context into multiple sub-contexts prevents unnecessary
 * re-renders in consumer components when unrelated sub-context values change.
 */
export function createSelectionContext<
  V,
  T extends Record<string, SubcontextHook<V>>
>(hookMap: T) {
  // @ts-expect-error Initially empty, but is populated below.
  const consumerHooks: ConsumerHooks<V, T> = {};
  const subproviders: ComponentType<
    React.PropsWithChildren<SubproviderProps>
  >[] = [];

  Object.entries(hookMap).forEach(([name, hook]) => {
    const [SubProvider, useSubcontext] = createSubcontext(hook, name);
    subproviders.push(SubProvider);
    // @ts-expect-error TODO: not sure why this broke.
    consumerHooks[name as keyof T] = useSubcontext;
  });

  function Provider({ children }: { children?: ReactNode }) {
    const listeners = useStaticValue([]);
    let tree = (
      <RegisterUpdateListeners updateListeners={[]}>
        {children}
      </RegisterUpdateListeners>
    );
    subproviders.forEach((Subprovider) => {
      tree = <Subprovider updateListeners={listeners}>{tree}</Subprovider>;
    });
    return <>{tree}</>;
  }

  return [Provider, consumerHooks] as const;
}
