import clsx from "clsx";
import React, { ComponentProps, useCallback, useMemo, useRef } from "react";

import {
  useEventDetailsPopoverStore,
  useFocusedEventInfo,
  useSetEventDetailsPopoverTriggerElement,
  useSetFocusedEventInfo,
  useSetHoveringEventInfo,
  useSetHoveringTriggerElement,
} from "../../hooks/refs";
import { getBackgroundStylingFromConfig } from "../../utils/colors";
import { CalendarEvent } from "../../utils/types";
import { EventSpine } from "./EventSpine";
import { EventSize, useEventSize } from "./utils/sizing";

const HOVER_TIMEOUT = 500;

type EventBackgroundEvent = Pick<
  CalendarEvent,
  | "id"
  | "startTime"
  | "endTime"
  | "calendarId"
  | "onClick"
  | "colorConfig"
  | "disableDetailsPopover"
  | "outOfOffice"
  | "onMouseDown"
  | "onMouseEnter"
  | "onMouseLeave"
  | "onMouseDown"
  | "responseStatus"
>;

type EventBackgroundOnMouseDown = (props: {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  yOffset: number;
}) => void;

type EventBackgroundBaseProps = Omit<ComponentProps<"span">, "onMouseDown">;

export type EventBackgroundProps = EventBackgroundBaseProps & {
  event: EventBackgroundEvent;
  onMouseDown?: EventBackgroundOnMouseDown;
};

export function EventBackground({
  event,
  className,
  children,
  onMouseEnter: onMouseEnterProp,
  onMouseLeave: onMouseLeaveProp,
  ...props
}: EventBackgroundProps) {
  const size = useEventSize(event);
  const setEventDetailsPopoverTriggerElement =
    useSetEventDetailsPopoverTriggerElement();
  const eventDetailsPopoverState = useEventDetailsPopoverStore();
  const setFocusedEventInfo = useSetFocusedEventInfo();
  const setHoveringEventInfo = useSetHoveringEventInfo();
  const setHoveringTriggerElement = useSetHoveringTriggerElement();
  const eventRef = useRef<HTMLDivElement | null>(null);
  const hoverTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const focusedEventInfo = useFocusedEventInfo();

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      hoverTimeoutId.current = setTimeout(() => {
        setHoveringEventInfo({ id: event.id, calendarId: event.calendarId });
        setHoveringTriggerElement(eventRef.current);
      }, HOVER_TIMEOUT);
      event.onMouseEnter?.();
      onMouseEnterProp?.(e);
    },
    [event, setHoveringEventInfo, setHoveringTriggerElement, onMouseEnterProp]
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (hoverTimeoutId.current) {
        clearTimeout(hoverTimeoutId.current);
      }
      setHoveringEventInfo(null);
      setHoveringTriggerElement(null);
      event.onMouseLeave?.();
      onMouseLeaveProp?.(e);
    },
    [event, setHoveringEventInfo, setHoveringTriggerElement, onMouseLeaveProp]
  );

  const isFocused = useMemo(() => {
    return (
      focusedEventInfo?.id === event.id &&
      focusedEventInfo?.calendarId === event.calendarId
    );
  }, [focusedEventInfo, event]);

  const handleClick = useCallback(() => {
    if (!event.disableDetailsPopover) {
      setEventDetailsPopoverTriggerElement(eventRef.current);
      setFocusedEventInfo({ id: event.id, calendarId: event.calendarId });
      eventDetailsPopoverState.show();
    }

    event.onClick?.();
  }, [
    event,
    setEventDetailsPopoverTriggerElement,
    setFocusedEventInfo,
    eventDetailsPopoverState,
  ]);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const rect = eventRef.current?.getBoundingClientRect();
      const offset = e.clientY - (rect?.top || 0);

      props.onMouseDown?.({
        event: e,
        yOffset: offset,
      });
      event.onMouseDown?.();
    },
    [props, event]
  );

  return (
    <EventBackgroundDisplay
      {...props}
      className={className}
      size={size}
      event={event}
      ref={eventRef}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isFocused={isFocused}
    >
      {children}
    </EventBackgroundDisplay>
  );
}

export type EventBackgroundDisplayProps = {
  event: Pick<CalendarEvent, "colorConfig" | "responseStatus" | "outOfOffice">;
  size?: EventSize;
  className?: string;
  children?: React.ReactNode;
  isFocused?: boolean;
} & ComponentProps<"div">;

/** Display only component without any interactivity */
export const EventBackgroundDisplay = React.forwardRef<
  HTMLDivElement,
  EventBackgroundDisplayProps
>(({ event, className, children, size, isFocused, ...props }, ref) => {
  const backgroundStyling = useMemo(
    () =>
      getBackgroundStylingFromConfig({
        config: event.colorConfig,
        responseStatus: event.responseStatus,
      }),
    [event.colorConfig, event.responseStatus]
  );

  const divStyle = useMemo(
    () => ({
      ...backgroundStyling,
      ...props.style,
    }),
    [backgroundStyling, props.style]
  );

  return (
    <div
      {...props}
      className={clsx(
        "absolute flex flex-row overflow-hidden",
        "group rounded-lg text-xs leading-5 inset-0",
        "transition-shadow duration-300",
        {
          "shadow-4": isFocused,
        },
        className
      )}
      style={divStyle}
      ref={ref}
    >
      {event.colorConfig.eventVariant === "background" &&
        !event.outOfOffice && <EventSpine event={event} />}
      <div
        className={clsx("relative gap-1 pl-2 pr-1 py-[6px] w-full", {
          "!py-[2px]": size === "small" || size === "medium",
          "!py-0": size === "xs",
        })}
      >
        {children}
      </div>
    </div>
  );
});
