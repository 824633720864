/* eslint-disable import/prefer-default-export */
import { createHeadlessEditor } from "@lexical/headless";

import { mergeThemes } from "../__utils/misc";
import { processModules } from "../__utils/modules";
import { AtlasHeadlessContentEditorOptions } from "../types";
import { variablesModule } from "../variables";
import { plainTextModule } from "./plugins/PlainTextPlugin";

export function createHeadlessContentEditorLite({
  onError,
  modules = [],
}: AtlasHeadlessContentEditorOptions) {
  const { nodes, themes } = processModules([
    plainTextModule,
    variablesModule,
    ...modules,
  ]);

  return createHeadlessEditor({
    nodes,
    theme: mergeThemes(themes),
    onError(error) {
      /* eslint-disable no-console */
      console.error("ContentEditor had an error:");
      console.error(error);
      /* eslint-enable no-console */
      onError?.(error);
    },
  });
}
