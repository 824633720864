import { ContentEditor } from "@resource/atlas/content-editor";
import {
  AtlasContentEditorModule,
  AtlasContentEditorModuleWithConfig,
  withConfig,
} from "@resource/atlas/content-editor/__utils/modules";
import type { AtlasContentEditorProps } from "@resource/atlas/content-editor/types";
import {
  ValueSet,
  variablesModule,
} from "@resource/atlas/content-editor/variables";
import { unknownVariablesModule } from "@resource/atlas/content-editor/variables/__lib/module";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPaperclip } from "@resource/atlas/icons";
import { TaskRequirementsProviderProps } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/provider";
import { guideAvailabilityRequestModule } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/rich-block";
import { guideAvailabilitySubmissionModule } from "client/guide-availability/rich-blocks/guide-availability-submission/__lib/rich-block";
import { useAttachedFiles } from "client/guide-content/rich-blocks/interviews/__lib/attached-files-context";
import { interviewsModule } from "client/guide-content/rich-blocks/interviews/__lib/rich-block";
import { useLinkCardModule } from "client/guide-content/rich-blocks/link-card";
import { useLinkCardsCollectionModule } from "client/guide-content/rich-blocks/link-cards-collection";
import {
  RichBlockContextProvider,
  RichBlockProviderOrganization,
} from "client/guide-content/rich-blocks/provider";
import { selfScheduleRequestModule } from "client/self-schedule/rich-blocks/self-schedule-request/rich-block";
import { TimezoneProvider } from "client/timezones/TimezoneProvider";
import { useTimezone } from "client/timezones/useTimezone";
import { useGuideIdContext } from "client/utils/guide-id-provider";
import { map, uniqBy } from "lodash";
import { useMemo } from "react";
import {
  guideUpdateSpecSet,
  GuideUpdateVariableSet,
} from "shared/guide-content/update";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { ErrorBoundary } from "../generic/errors/ErrorBoundary";
import { useBaseModules } from "./base-modules";

type GuideUpdateEditorProps = AtlasContentEditorProps & {
  valueSet: ValueSet<GuideUpdateVariableSet>;
  /** @default "placeholder" */
  undefinedMode?: "placeholder" | "error";
  disableRichBlockModules?: boolean;
  disableGuideRichBlockModules?: boolean;
};

function AttachedFiles({
  files,
}: {
  files: { id: string; filename: string }[];
}) {
  return files.length > 0 ? (
    <div className="space-y-2">
      {map(files, ({ id, filename }) => (
        <div
          key={id}
          className="shadow-1 rounded bg-light-gray-200 flex p-3 items-center gap-2"
        >
          <Icon content={atlasPaperclip} className="text-subtle" />
          <p className="truncate text-body-md-heavy text-dark">{filename}</p>
        </div>
      ))}
    </div>
  ) : null;
}

function GuideUpdateEditorWithRequiredContext({
  valueSet,
  undefinedMode,
  modules = [],
  trailingContentSlot,
  disableRichBlockModules = false,
  disableGuideRichBlockModules = false,
  ...props
}: GuideUpdateEditorProps) {
  const { attachedFiles } = useAttachedFiles();
  const uniqueAttachedFiles = uniqBy(attachedFiles, "id");

  const specSet = guideUpdateSpecSet;

  const timezone = useTimezone();

  const interviewsModuleWithConfig = useMemo(() => {
    return withConfig(interviewsModule, {
      timezone: timezone ?? undefined,
    });
  }, [timezone]);

  const baseModules = useBaseModules();
  const linkCardModule = useLinkCardModule();
  const linkCardsCollectionModule = useLinkCardsCollectionModule();
  const allModules: (
    | AtlasContentEditorModule
    | AtlasContentEditorModuleWithConfig
  )[] = useMemo(() => {
    return [
      ...baseModules,
      withConfig(variablesModule, {
        specSet,
        valueSet,
        undefinedMode,
        timezone: timezone ?? undefined,
      }),
      unknownVariablesModule,
      ...(disableRichBlockModules
        ? []
        : [
            linkCardModule,
            linkCardsCollectionModule,
            ...(disableGuideRichBlockModules
              ? []
              : [
                  guideAvailabilityRequestModule,
                  selfScheduleRequestModule,
                  guideAvailabilitySubmissionModule,
                  interviewsModuleWithConfig,
                ]),
          ]),
      ...modules,
    ].filter(filterOutNullsAndUndefined);
  }, [
    disableRichBlockModules,
    disableGuideRichBlockModules,
    baseModules,
    interviewsModuleWithConfig,
    linkCardModule,
    linkCardsCollectionModule,
    modules,
    specSet,
    timezone,
    undefinedMode,
    valueSet,
  ]);

  return (
    <ErrorBoundary>
      <ContentEditor
        {...props}
        trailingContentSlot={
          trailingContentSlot || uniqueAttachedFiles.length > 0 ? (
            <div className="space-y-4">
              {trailingContentSlot}
              <AttachedFiles files={uniqueAttachedFiles} />
            </div>
          ) : undefined
        }
        modules={allModules}
      />
    </ErrorBoundary>
  );
}

export type GuideSimpleUpdateEditorProps = AtlasContentEditorProps;

export function GuideSimpleUpdateEditor({
  ...props
}: GuideSimpleUpdateEditorProps) {
  return (
    <ErrorBoundary>
      <ContentEditor {...props} modules={[]} />
    </ErrorBoundary>
  );
}

export function GuideUpdateEditor({
  candidateEmail,
  candidateTimezone,
  modules = [],
  organization,
  taskRequirements,
  ...props
}: GuideUpdateEditorProps & {
  candidateTimezone?: string | null;
  candidateEmail?: string;
  organization?: RichBlockProviderOrganization;
  taskRequirements?: TaskRequirementsProviderProps["taskRequirements"];
}) {
  const guideIdContext = useGuideIdContext();

  return (
    <RichBlockContextProvider
      guideId={guideIdContext?.guideId}
      candidateEmail={candidateEmail}
      organization={organization}
      taskRequirements={taskRequirements}
    >
      <TimezoneProvider timezone={candidateTimezone}>
        <GuideUpdateEditorWithRequiredContext {...props} modules={modules} />
      </TimezoneProvider>
    </RichBlockContextProvider>
  );
}

export function GuideUpdateTemplateEditor({
  organization,
  modules = [],
  ...props
}: GuideUpdateEditorProps & {
  organization?: RichBlockProviderOrganization | null;
}) {
  return (
    <RichBlockContextProvider organization={organization}>
      <GuideUpdateEditorWithRequiredContext {...props} modules={modules} />
    </RichBlockContextProvider>
  );
}
