/* eslint-disable no-underscore-dangle */
import * as Ariakit from "@ariakit/react";
import match from "autosuggest-highlight/match";
import clsx from "clsx";
import React, {
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { atlasCheck, atlasChevronDown, atlasSearch } from "../../icons";
import { createComponentUtils } from "../__utils/atlas";
import {
  CollectionItem,
  CollectionItemRenderers,
} from "../__utils/collections";
import { CompositeListRenderer } from "../__utils/CompositeListRendererV2";
import { Icon } from "../icon/Icon";
import { AtlasIconData } from "../icon/types";
import { OptionSelectProps, SelectItem } from "./types";

const COMPONENT_NAME = "SelectV2";
const DROPDOWN_ICON = atlasChevronDown;
const SELECTED_ICON = atlasCheck;

const { el } = createComponentUtils(COMPONENT_NAME);
const { el: elOptionItem, ROOT: OPTION_ITEM_ROOT } =
  createComponentUtils("SelectOptionItem");

export type RootProps = Ariakit.SelectProviderProps;
export function Root({ ...props }: RootProps) {
  return <Ariakit.SelectProvider {...props} />;
}

export type TriggerProps = Omit<Ariakit.SelectProps, "children"> & {
  size?: "small" | "medium" | "xs";
  icon?: AtlasIconData;
  children?: ReactNode;
  isGhost?: boolean;
};
export const Trigger = React.memo(
  ({
    className,
    icon,
    size = "medium",
    children,
    render,
    isGhost,
    ...props
  }: TriggerProps) => {
    const store = Ariakit.useSelectContext();
    const state = Ariakit.useStoreState(store);
    const value = state?.value;
    const items = state?.items;

    const labels = useMemo(() => {
      const values = Array.isArray(value) ? value : [value];

      if (!items) {
        return values;
      }

      return values.map((v) => {
        const item = items.find((i) => i.value === v);

        // This feels hacky but children returns the wrong value for some reason
        return item?.element?.innerText ?? v;
      });
    }, [value, items]);

    const labelChildren =
      labels.length > 1 ? labels.join(", ") : labels[0] ?? "No selection";

    return (
      <Ariakit.Select
        {...props}
        render={render}
        className={
          render
            ? className
            : clsx(el`trigger`, `size-${size}`, { isGhost }, className)
        }
      >
        {children ?? (
          <>
            {icon && (
              <Icon className={el`trigger-icon`} content={icon} size="custom" />
            )}
            <span className={el`trigger-label`}>{labelChildren}</span>
            <Icon
              className={el`trigger-dropdown-icon`}
              content={DROPDOWN_ICON}
              size="custom"
            />
          </>
        )}
      </Ariakit.Select>
    );
  }
);

type OptionProps = OptionSelectProps;
export const Option = React.memo(
  ({
    children,
    isSelectable: _isSelectable,
    size = "default",
    ...props
  }: OptionProps) => {
    const store = Ariakit.useSelectContext();
    const selectedValue = Ariakit.useStoreState(store, "value");
    const isSelectable = true;

    const isSelected = useMemo(() => {
      if (!selectedValue) {
        return false;
      }
      const arrValue = Array.isArray(selectedValue)
        ? selectedValue
        : [selectedValue];
      return props.value && arrValue.includes(props.value);
    }, [props.value, selectedValue]);

    return (
      <Ariakit.SelectItem
        className={clsx(
          OPTION_ITEM_ROOT,
          { isSelectable, isSelected },
          `size-${size}`,
          props.className
        )}
        focusable={false}
        {...props}
      >
        {isSelectable && (
          <div className={elOptionItem`selectable-container`}>
            <Icon content={SELECTED_ICON} className="w-5 h-5" />
          </div>
        )}
        {children}
      </Ariakit.SelectItem>
    );
  }
);

type SeparatorProps = Ariakit.SelectSeparatorProps;
export function Separator({ ...props }: SeparatorProps) {
  return (
    <Ariakit.Separator
      className={clsx(el`optionSeparator`, props.className)}
      {...props}
    />
  );
}

type GroupLabelProps = Ariakit.SelectGroupLabelProps;
export function Heading({ ...props }: GroupLabelProps) {
  return (
    <Ariakit.SelectGroupLabel
      className={clsx(el`heading`, props.className)}
      {...props}
    />
  );
}

// virtual composite list options

const OPTION_HEADING_SIZE = 40;
const OPTION_SEPARATOR_SIZE = 17;
const OPTION_ITEM_SIZE = 40;

function estimateItemSize(item: SelectItem) {
  if (item._type === "heading") return OPTION_HEADING_SIZE;
  if (item._type === "separator") return OPTION_SEPARATOR_SIZE;
  if (item._type === "option") return OPTION_ITEM_SIZE;
  throw new Error("Unknown item type");
}

function isDynamicallySizedItem(item: SelectItem) {
  if (item._type === "heading") return false;
  if (item._type === "separator") return false;
  if (item._type === "option") return !item.render;
  throw new Error("Unknown item type");
}

const COMPOSITE_ITEM_TYPES: SelectItem["_type"][] = ["option"];

const BASE_VIRTUAL_COMPOSITE_OPTIONS = {
  compositeItemTypes: COMPOSITE_ITEM_TYPES,
  estimateSize: estimateItemSize,
  isDynamicallySized: isDynamicallySizedItem,
};

type SelectContentItem = CollectionItem<{
  option: OptionProps;
  separator: SeparatorProps;
  heading: GroupLabelProps;
}>;

type ListProps = {
  items: SelectItem[];
  isVirtual?: boolean;
  listRef: RefObject<HTMLElement>;
};

function List({ items, isVirtual, listRef }: ListProps) {
  const store = Ariakit.useSelectContext();

  const select = Ariakit.useStoreState(store);

  const renderers: CollectionItemRenderers<SelectContentItem> = useMemo(
    () => ({
      separator: (props) => <Separator {...props} />,
      option: (props) => <Option {...props} />,
      heading: (props) => <Heading {...props} />,
    }),
    []
  );

  // Pin selected items when virtualizing the list, so that we can render
  // the label on the trigger correctly.
  // We use the full set of items because select.items only contains
  // the currently rendered rows, which may not include the selected value.
  const customPinnedIndexes = useMemo(() => {
    const indexes: number[] = [];

    if (!isVirtual || !select) {
      return indexes;
    }

    if (Array.isArray(select.value))
      select.value.forEach((selectedValue) => {
        const selectedIndex = items.findIndex(
          (item) => "value" in item && selectedValue === item.value
        );
        indexes.push(selectedIndex);
      });
    else {
      const selectedIndex = items.findIndex(
        (item) => "value" in item && select.value === item.value
      );
      indexes.push(selectedIndex);
    }
    return indexes;
  }, [isVirtual, select, items]);

  // This should never happen, because <List> is always rendered inside of
  // an ariakit select component, a hard requirement for SelectV2.
  if (!select) {
    return null;
  }

  return (
    <CompositeListRenderer
      className={el`list`}
      listRef={listRef}
      items={items}
      renderers={renderers}
      isVirtual={isVirtual}
      virtualCompositeOptions={{
        compositeState: select,
        customPinnedIndexes,
        ...BASE_VIRTUAL_COMPOSITE_OPTIONS,
      }}
    />
  );
}

function filterItems(value: string, items: SelectItem[]): SelectItem[] {
  const result: SelectItem[] = [];

  items.forEach((item) => {
    if (item._type !== "option") return;
    const matches = match(item.children, value, { requireMatchAll: true });
    if (matches.length > 0) {
      result.push(item);
    }
  });

  return result;
}

type ContentProps = Omit<Ariakit.SelectPopoverProps, "children"> & {
  items?: SelectItem[];
  children?: ReactNode;
  searchable?: boolean;
  isVirtual?: boolean;
  header?: ReactNode;
};
export const Content = React.memo(
  ({
    items,
    children,
    className,
    sameWidth,
    searchable,
    isVirtual,
    header,
    ...props
  }: ContentProps) => {
    const store = Ariakit.useSelectContext();
    const open = Ariakit.useStoreState(store, "open");

    const [searchStr, setSearchStr] = useState("");

    const filteredItems = useMemo(() => {
      return searchStr && items ? filterItems(searchStr, items) : items;
    }, [items, searchStr]);

    const focusOnOpen = useCallback(
      (e: HTMLInputElement | null) => {
        // Not sure why need timeout but otherwise the select hijacks focus; couldn't see how original select handles this
        setTimeout(() => {
          if (open) {
            e?.focus();
          }
        });
      },
      [open]
    );

    // listRef is applied to the parent div because the height of the <List> itself
    // is unconstrained; causing useVirtual to incorrectly determine the visible range
    // and render all of the items instead of a subset.
    const listRef = useRef<HTMLDivElement>(null);

    const isEmpty = filteredItems && filteredItems.length === 0;

    return (
      <Ariakit.SelectPopover
        {...props}
        focusable={false}
        focusOnMove={false}
        composite={false}
        autoFocus={false}
        className={clsx(el`content`, { sameWidth }, className)}
      >
        {header && <div className={el`header`}>{header}</div>}
        {searchable && (
          <>
            <div className={el`search`}>
              <Icon content={atlasSearch} />
              <input
                placeholder="Search"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                ref={focusOnOpen}
              />
            </div>
            <Separator />
          </>
        )}
        <div ref={listRef} className={el`content-items`}>
          {children}
          {isEmpty && <div className="">No results</div>}
          <List
            items={filteredItems ?? []}
            isVirtual={isVirtual}
            listRef={listRef}
          />
        </div>
      </Ariakit.SelectPopover>
    );
  }
);
