import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { Dialog } from "@resource/atlas/dialog";
import { AtlasDialogState } from "@resource/atlas/dialog/types";
import clsx from "clsx";
import { TimezoneSelector } from "components/TimezoneSelection/TimezoneSelector";
import { gql } from "generated/graphql-codegen";
import { useCallback, useState } from "react";
import { displayTimezone } from "shared/utils/timezones";
import useMutation from "utils/useMutation";

gql(`fragment CandidateTimezoneDialogCandidate on Candidate {
  id
  timezone
}`);

const UPDATE_TIMEZONE = gql(`
  mutation UpdateCandidateTimezoneForDialog($input: SetTimezoneOfCandidateInput!) {
    setTimezoneOfCandidate(input: $input) {
      success
      message
      code
      candidate {
        ...CandidateTimezoneDialogCandidate
      }
    }
  }
`);

type CandidateTimezoneDialogProps = {
  dialogState: AtlasDialogState;
  candidate: {
    id: string;
    name: string;
    imageUrl: string;
    timezone: string | null;
  };
};
export function CandidateTimezoneDialog({
  dialogState,
  candidate,
}: CandidateTimezoneDialogProps) {
  const userTimezone = candidate.timezone;
  const [timezone, setTimezone] = useState(userTimezone);
  const [updateCandidateTimezone] = useMutation(UPDATE_TIMEZONE);

  const updateTimezone = useCallback(async () => {
    await updateCandidateTimezone({
      variables: {
        input: {
          id: candidate.id,
          timezone: timezone!,
        },
      },
    });
    dialogState.hide();
  }, [dialogState, updateCandidateTimezone, timezone, candidate.id]);

  return (
    <Dialog.Root state={dialogState}>
      <Dialog.Content
        size="small"
        header={{
          title: `${userTimezone ? "Edit" : "Set"} candidate timezone`,
        }}
        footer={{
          rightActions: (
            <div className="flex justify-end gap-2">
              <Button isGhost onClick={() => dialogState.hide()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!timezone}
                onClick={updateTimezone}
              >
                Save
              </Button>
            </div>
          ),
        }}
      >
        <div className="space-y-4 my-1">
          <div className="flex items-center gap-2">
            <Avatar
              image={candidate.imageUrl}
              name={candidate.name}
              size="medium"
              disableTooltip
            />
            <p className="text-body-md">{candidate.name}</p>
          </div>
          <div className="space-y-2">
            <p className="text-body-md-heavy">Timezone</p>
            <TimezoneSelector
              onClick={(tz) => {
                setTimezone(tz);
              }}
              triggerProps={{
                label: timezone
                  ? displayTimezone(timezone, "default") ?? ""
                  : "Select",
                className: clsx("w-full my-1 bg-light-gray-500", {
                  "text-subtle": !timezone,
                }),
              }}
              rootProps={{
                value: timezone ?? undefined,
              }}
            />
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
