import { Toggle } from "client/app/__components/Toggle";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";

export function EnableExternalInterviewerAlgorithmToggle({
  externalAlgorithmEnabled,
  setExternalAlgorithmEnabled,
}: {
  externalAlgorithmEnabled: boolean;
  setExternalAlgorithmEnabled: (value: boolean) => void;
}) {
  return (
    <div
      className={clsx(
        "flex justify-start py-2 px-3 items-center gap-2",
        "text-subtle border-t border-gray-border",
        !externalAlgorithmEnabled && "rounded-b-md",
        externalAlgorithmEnabled ? "text-body-sm-heavy" : "text-body-sm"
      )}
    >
      <Toggle
        size="small"
        value={externalAlgorithmEnabled}
        onChange={setExternalAlgorithmEnabled}
        data-cy={
          CypressData.editInterviewerSlotDisplay.enableExternalAlgorithmSwitch
        }
      />
      <span>Use Duolingo interviewer selection</span>
    </div>
  );
}
