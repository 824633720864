import { useButton } from "@react-aria/button";
import { useFocusRing } from "@react-aria/focus";
import { useHover } from "@react-aria/interactions";
import { mergeProps } from "@react-aria/utils";
import { useControlledState } from "@react-stately/utils";
import { AriaButtonProps } from "@react-types/button";
import clsx from "clsx";
import React, { ForwardedRef, forwardRef, ReactElement, useRef } from "react";

import {
  atlasCircleCross,
  atlasRingCheck,
  atlasRingWarning,
} from "../../icons/atlas";
import { getPropsWithDefaults, useOptionalRef } from "../__utils/__deprecated";
import {
  makeElementClassNameFactory,
  makeRootClassName,
} from "../__utils/atlas";
import { Icon } from "../icon/Icon";
import { AtlasTextFieldBaseProps } from "./types";

// config
// ------

const ROOT = makeRootClassName("TextFieldBase");
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  isMultiLine: false,
  isDisabled: false,
  isRequired: false,
} as const;

const INVALID_ICON = atlasRingWarning;
const VALID_ICON = atlasRingCheck;
const CLEAR_ICON = atlasCircleCross;

// TODO: need to determine if we want uncontrolled
// validation -- removing for now.
// -----------------

// const VALIDATION_PROPS = [
//   "validationState",
//   "isRequired",
//   "pattern",
//   "type",
//   "maxLength",
//   "minLength",
// ];

// type ValidationType = "valid" | "invalid" | undefined;

// function validate(
//   value: string,
//   inputRef: TextFieldBaseProps["inputRef"],
//   validationState: TextFieldBaseProps["validationState"],
//   isRequired: boolean
// ): ValidationType {
//   if (validationState) {
//     return validationState;
//   }

//   if (!isRequired && value.length === 0) {
//     return undefined;
//   }

//   if (inputRef?.current) {
//     // use native browser validation for everything else
//     // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement
//     const isValid = inputRef.current.checkValidity();
//     return isValid ? "valid" : "invalid";
//   }

//   return undefined;
// }

// clear button component
// ----------------------

type ClearButtonProps = AriaButtonProps<"button"> &
  Pick<AtlasTextFieldBaseProps, "onClear" | "isDisabled">;

function ClearButton(p: ClearButtonProps) {
  const domRef = useRef<HTMLButtonElement>(null);

  const { buttonProps, isPressed } = useButton(
    {
      ...p,
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore undocumented, see 'useButton' source. */
      preventFocusOnPress: true,
    },
    domRef
  );
  const { hoverProps, isHovered } = useHover({ ...p });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(buttonProps, hoverProps, focusProps);

  return (
    <button
      type="button"
      {...behaviorProps}
      className={clsx(el`clear-button`, {
        "is-focus-visible": isFocusVisible,
        "is-hovered": isHovered,
        "is-pressed": isPressed,
      })}
    >
      <Icon
        className={el`clear-button-icon`}
        content={CLEAR_ICON}
        size="custom"
      />
    </button>
  );
}

// component
// ---------

const InputOrTextArea = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  { as?: "input" | "textarea" } & (
    | React.InputHTMLAttributes<HTMLInputElement>
    | React.TextareaHTMLAttributes<HTMLTextAreaElement>
  )
>(({ as: Comp = "input", ...props }, ref) =>
  Comp === "input" ? (
    <input
      {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
      ref={ref as React.Ref<HTMLInputElement>}
    />
  ) : (
    <textarea
      {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
      ref={ref as React.Ref<HTMLTextAreaElement>}
    />
  )
);

function TextFieldBaseComponent(
  props: AtlasTextFieldBaseProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = getPropsWithDefaults(props, DEFAULT_PROPS);
  const elementType = p.isMultiLine ? "textarea" : "input";
  const domRef = useOptionalRef(ref);

  // state
  // -----

  const [value, setValue] = useControlledState(
    p.value,
    p.defaultValue || "",
    p.onChange
  );

  const hasValidationProp = !!p.validationState;

  // TODO: need to determine if we want uncontrolled
  // validation -- removing for now.

  // const hasValidationProp = VALIDATION_PROPS.some(
  //   (prop) => p[prop] !== undefined
  // );

  // const [validation, setValidation] = useState<ValidationType>(undefined);

  // useEffect(() => {
  //   if (hasValidationProp) {
  //     const result = validate(
  //       value,
  //       p.inputRef,
  //       p.validationState,
  //       p.isRequired
  //     );
  //     setValidation(result);
  //   }
  // }, [hasValidationProp, p.inputRef, p.validationState, p.isRequired, value]);

  // behavior
  // --------

  const { focusProps, isFocused } = useFocusRing({
    isTextInput: true,
  });
  const { isHovered, hoverProps } = useHover({ isDisabled: p.isDisabled });
  const inputBehaviorProps = mergeProps(
    hoverProps,
    p.inputProps,
    {
      // TODO: determine whether uncontrolled validation is needed
      // required: p.isRequired, // makes required form validation work
      value, // use the value from state
      onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
        setValue(event.target.value), // use setValue from state
    },
    focusProps
  );

  return (
    <div
      ref={domRef}
      className={clsx(
        `${ROOT} size-${p.size}`,
        {
          "is-ghost": p.isGhost,
          "is-required": p.isRequired,
          "has-icon": p.icon,
          "is-valid": p.validationState === "valid",
          "is-invalid": p.validationState === "invalid",
          "is-disabled": p.isDisabled,
          "is-hovered": isHovered,
          "is-focused": isFocused,
          "is-clearable": p.isClearable,
          "is-multiline": p.isMultiLine,
        },
        p.className
      )}
    >
      {(p.label || p.helpText) && (
        <div className={el`input-header`}>
          {p.label && (
            <label {...p.labelProps} className={el`label`}>
              <span>{p.label}</span>
              <div className={el`label-required-indicator`}>*</div>
            </label>
          )}
          {p.helpText && <span className={el`help-text`}>{p.helpText}</span>}
        </div>
      )}
      <div className={clsx(p.inputWrapperClassName, el`input-wrapper`)}>
        <InputOrTextArea
          as={elementType}
          ref={p.inputRef}
          className={clsx(p.inputClassName, el`input`)}
          {...inputBehaviorProps}
          // TODO: clean this up with a JS is-empty class
          // currently, this forces the :placeholder-shown to work
          // see: https://stackoverflow.com/questions/3617020/matching-an-empty-input-box-using-css#comment75526560_35593489
          placeholder={inputBehaviorProps.placeholder || " "}
        />
        {p.icon && <Icon content={p.icon} className={el`icon`} />}
        {hasValidationProp && !p.isDisabled && (
          <Icon
            className={el`validation-icon`}
            content={p.validationState === "valid" ? VALID_ICON : INVALID_ICON}
          />
        )}
        {p.isClearable && !p.isDisabled && (
          <ClearButton
            isDisabled={p.isDisabled}
            onPress={() => {
              setValue("");
              if (p.onClear) p.onClear();
            }}
          />
        )}
      </div>
      {p.errorMessage && (
        <div className="mt-1">
          <p className={el`error-text`}>{p.errorMessage}</p>
        </div>
      )}
    </div>
  );
}

const TextFieldBase = forwardRef<HTMLDivElement, AtlasTextFieldBaseProps>(
  TextFieldBaseComponent
);

export default TextFieldBase;

// TODO:
// - textarea
// - search field
// - validation
// - forward ref
// - tooltip support
// - stories:
//   - input types
//   - native vs. controlled
//   - native vs controlled validation
//   - required styles/behavior
//   - usage in forms example?
