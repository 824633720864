import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import StopPropagation from "components/StopPropagation";
import { ScheduledInterviewForScheduledInterviewCardFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { ArchiveInterviewConfirmationDialog } from "../__components/ArchiveInterviewConfirmation";

/**
 * The menu items for managing a Scheduled Interview synced from Greenhouse
 * Used for lite customers
 */
export function useLiteInternalScheduledInterviewMenuItems(
  scheduledInterview:
    | ScheduledInterviewForScheduledInterviewCardFragment
    | null
    | undefined
) {
  const archiveDialogStore = useDialogStore();
  const allowDeleting =
    !scheduledInterview?.atssyncScheduledInterview ||
    scheduledInterview.atssyncScheduledInterview.remoteWasDeleted;
  const menuItems = useMenuItems(
    (i) =>
      allowDeleting
        ? [
            i.item({
              id: "delete",
              children: "Delete interview",
              isDestructive: true,
              onClick: () => {
                archiveDialogStore.show();
              },
            }),
          ]
        : [],
    [allowDeleting, archiveDialogStore]
  );

  return useMemo(() => {
    if (!allowDeleting) {
      return undefined;
    }

    return {
      Dialog: (
        <StopPropagation>
          <ArchiveInterviewConfirmationDialog
            store={archiveDialogStore}
            scheduledInterviewId={scheduledInterview?.id ?? ""}
          />
        </StopPropagation>
      ),
      menuItems,
    };
  }, [allowDeleting, archiveDialogStore, menuItems, scheduledInterview?.id]);
}
