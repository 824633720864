import { MultiStepView } from "@resource/atlas/multi-step-view/MultiStepView";
import { useCallback } from "react";
import { UpdateReason } from "shared/reporting/scheduled-interviews/types";

import { InterviewerMultiSelectItem } from "../ReportingReasons/__components/InterviewerMultiSelect";
import { ReportInterviewerSwapReasonView } from "../ReportingReasons/display/ReportInterviewerSwapReasonView";
import { ReportRescheduleReasonView } from "../ReportingReasons/display/ReportRescheduleReasonView";
import { useRescheduleReasonDefaultValues } from "../ReportingReasons/hooks/useReportingReasonDefaultValues";
import { useReportInterviewerSwapReasonState } from "../ReportingReasons/hooks/useReportInterviewerSwapReasonState";
import { useReportRescheduleReasonState } from "../ReportingReasons/hooks/useReportRescheduleReasonState";
import { UpdateInterviewNotificationSettingsView } from "./components/UpdateInterviewNotificationSettingsView";
import {
  UpdateInterviewNotificationSettingsFormData,
  useUpdateInterviewNotificationSettingsState,
} from "./hooks/useUpdateInterviewNotificationSettingsState";

export type ScheduledInterviewUpdateOnCompleted = (data: {
  notificationSettings: UpdateInterviewNotificationSettingsFormData;
  reportingReason?: UpdateReason;
}) => void;

export type ScheduledInterviewUpdateConfirmationViewProps = {
  onCancel: () => void;
  onCompleted: ScheduledInterviewUpdateOnCompleted;
  saveLoading?: boolean;
  originalInterviewers: InterviewerMultiSelectItem[];
  interviewersChanged?: boolean;
  timeChanged?: boolean;
};

function useReportingCollectionType({
  interviewersChanged,
  timeChanged,
}: Pick<
  ScheduledInterviewUpdateConfirmationViewProps,
  "interviewersChanged" | "timeChanged"
>) {
  if (!timeChanged && !interviewersChanged) {
    return null;
  }

  if (timeChanged) {
    return "reschedule";
  }

  return "interviewerSwap";
}

export function ScheduledInterviewUpdateConfirmationView({
  onCancel,
  onCompleted,
  saveLoading,
  originalInterviewers,
  interviewersChanged,
  timeChanged,
}: ScheduledInterviewUpdateConfirmationViewProps) {
  const updateNotificationSettingsState =
    useUpdateInterviewNotificationSettingsState();
  const reportInterviewerSwapReasonState =
    useReportInterviewerSwapReasonState();

  const defaultsForRescheduleReportingReason = useRescheduleReasonDefaultValues(
    {
      originalInterviewers,
    }
  );

  const reportRescheduleReasonState = useReportRescheduleReasonState(
    defaultsForRescheduleReportingReason
  );
  const reportingCollectionType = useReportingCollectionType({
    interviewersChanged,
    timeChanged,
  });

  const onSave = useCallback(() => {
    if (reportingCollectionType === "reschedule") {
      onCompleted({
        notificationSettings: updateNotificationSettingsState.form.getValues(),
        reportingReason: {
          rescheduleReason: reportRescheduleReasonState.form.getValues(),
        },
      });
    } else if (reportingCollectionType === "interviewerSwap") {
      onCompleted({
        notificationSettings: updateNotificationSettingsState.form.getValues(),
        reportingReason: {
          interviewerSwapReason:
            reportInterviewerSwapReasonState.form.getValues(),
        },
      });
    } else {
      onCompleted({
        notificationSettings: updateNotificationSettingsState.form.getValues(),
      });
    }
  }, [
    onCompleted,
    reportInterviewerSwapReasonState.form,
    reportRescheduleReasonState.form,
    reportingCollectionType,
    updateNotificationSettingsState.form,
  ]);

  if (reportingCollectionType === null) {
    return (
      <UpdateInterviewNotificationSettingsView
        state={updateNotificationSettingsState}
        onCancel={onCancel}
        confirmationButton={{
          label: "Save changes",
          variant: "primary",
          onClick: onSave,
          isLoading: saveLoading,
        }}
      />
    );
  }

  return (
    <MultiStepView
      orderedViews={["notificationSettings", "reportingReason"]}
      onDismiss={onCancel}
      views={{
        notificationSettings: (viewProps) => (
          <UpdateInterviewNotificationSettingsView
            state={updateNotificationSettingsState}
            onCancel={onCancel}
            confirmationButton={{
              label: "Continue",
              variant: "default",
              onClick: viewProps.onContinue,
            }}
          />
        ),
        reportingReason: (viewProps) => {
          if (reportingCollectionType === "reschedule") {
            return (
              <ReportRescheduleReasonView
                interviewers={originalInterviewers}
                state={reportRescheduleReasonState}
                onCancel={onCancel}
                onBack={viewProps.onBack}
                confirmationButton={{
                  label: "Save changes",
                  variant: "primary",
                  onClick: onSave,
                  isLoading: saveLoading,
                }}
              />
            );
          }

          return (
            <ReportInterviewerSwapReasonView
              interviewers={originalInterviewers}
              state={reportInterviewerSwapReasonState}
              onCancel={onCancel}
              onBack={viewProps.onBack}
              confirmationButton={{
                label: "Save changes",
                variant: "primary",
                onClick: onSave,
                isLoading: saveLoading,
              }}
            />
          );
        },
      }}
    />
  );
}
