import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useQueryStringValue } from "utils/next";

/**
 * Hook to redirect a candidate to their guide homepage
 */
export function useHomepageRedirect() {
  const { isOrgUser } = useGuideAuthContext();
  const isCandidate = !isOrgUser;
  const router = useRouter();
  const organizationSlug = useQueryStringValue("organizationSlug");
  const shortId = useQueryStringValue("shortId");

  const redirectFn = useCallback(() => {
    if (isCandidate && organizationSlug && shortId) {
      router.push(`/guide/${organizationSlug}/${shortId}`);
    }
  }, [isCandidate, organizationSlug, router, shortId]);

  return redirectFn;
}
