import { DateTime } from "luxon";

import { Event } from "./types";

export function getFormattedEventTimeRange(
  event: Pick<Event, "startTime" | "endTime">,
  timezone?: string
) {
  const resolvedTimezone = timezone || DateTime.local().zoneName;
  const startTime = DateTime.fromISO(event.startTime)
    .setZone(resolvedTimezone)
    .toFormat("h:mma");
  const endTime = DateTime.fromISO(event.endTime)
    .setZone(resolvedTimezone)
    .toFormat("h:mma");

  return `${startTime} - ${endTime}`;
}

export function getFormattedEventDay(
  event: Pick<Event, "startTime" | "endTime">,
  timezone?: string
) {
  const resolvedTimezone = timezone || DateTime.local().zoneName;
  const startTime = DateTime.fromISO(event.startTime).setZone(resolvedTimezone);
  const startDay = startTime.toFormat("EEEE, MMMM dd");

  return startDay;
}

export function getFormattedEventTimeRangeWithDay(
  event: Pick<Event, "startTime" | "endTime">,
  timezone?: string
) {
  const resolvedTimezone = timezone || DateTime.local().zoneName;
  const startTime = DateTime.fromISO(event.startTime).setZone(resolvedTimezone);
  const endTime = DateTime.fromISO(event.endTime).setZone(resolvedTimezone);

  const startTimeDisplay = startTime.toFormat("h:mma");
  const endTimeDisplay = endTime.toFormat("h:mma");

  const startDay = startTime.toFormat("EEE MMM dd");
  const endDay = endTime.toFormat("EEE MMM dd");
  const timezoneAbbreviation = endTime.toFormat("ZZZZ");

  return `${startDay}, ${startTimeDisplay} - ${
    endDay !== startDay ? `${endDay},` : ""
  } ${endTimeDisplay} ${timezoneAbbreviation}`;
}
