import { useStoreState } from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import {
  useDialogLeaveConfirmation,
  useSyncDialogLeaveConfirmation,
} from "client/hooks/useDialogLeaveConfirmation";
import Loading from "components/Loading";
import { createStore, Provider } from "jotai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

import { ReorderableStages } from "./InterviewPlan/ReorderableStages";
import { StageEditor } from "./InterviewPlan/StageEditor";
import type { InterviewPlanItem, Organization } from "./InterviewPlan/types";

// Custom hook to track previous value
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

type EditInterviewPlanDialogProps = {
  store: DialogStore;
  organizationForTemplateEditor?: Organization | null;
  defaultSelectedInterviewPlanId?: string | null;
  loading: boolean;
  saving: boolean;
  hideSaveChangesToParent?: boolean;
  initialInterviewPlanItems?: InterviewPlanItem[];
  onItemsChange?: () => void;
  onSubmit: (props: {
    items: InterviewPlanItem[];
    saveChangesToParent: boolean;
  }) => Promise<void>;
};

const jotaiStore = createStore();

export function EditInterviewPlanDialog({
  store,
  loading,
  saving,
  hideSaveChangesToParent,
  defaultSelectedInterviewPlanId,
  initialInterviewPlanItems,
  onSubmit,
  onItemsChange,
}: EditInterviewPlanDialogProps) {
  const logEvent = useLogEvent({
    component: "InterviewPlanDialog",
  });
  const { WarningDialog, dialogProps } = useDialogLeaveConfirmation({
    store,
    jotaiStore,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Provider store={jotaiStore}>
      <Dialog
        size="large"
        store={store}
        ref={containerRef}
        hideOnInteractOutside={false}
        {...dialogProps}
      >
        <InterviewPlanDialogContent
          store={store}
          loading={loading}
          saving={saving}
          hideSaveChangesToParent={hideSaveChangesToParent}
          defaultSelectedInterviewPlanId={defaultSelectedInterviewPlanId}
          initialInterviewPlanItems={initialInterviewPlanItems}
          onSubmit={onSubmit}
          onItemsChange={onItemsChange}
          containerRef={containerRef}
          logEvent={logEvent}
        />
      </Dialog>
      <WarningDialog />
    </Provider>
  );
}

type InterviewPlanDialogContentProps = {
  store: DialogStore;
  loading: boolean;
  saving: boolean;
  hideSaveChangesToParent?: boolean;
  defaultSelectedInterviewPlanId?: string | null;
  initialInterviewPlanItems?: InterviewPlanItem[];
  onItemsChange?: () => void;
  onSubmit: (props: {
    items: InterviewPlanItem[];
    saveChangesToParent: boolean;
  }) => Promise<void>;
  containerRef: React.RefObject<HTMLDivElement>;
  logEvent: ReturnType<typeof useLogEvent>;
};

function InterviewPlanDialogContent({
  store,
  loading,
  saving,
  hideSaveChangesToParent,
  defaultSelectedInterviewPlanId,
  initialInterviewPlanItems,
  onSubmit,
  onItemsChange,
  containerRef,
  logEvent,
}: InterviewPlanDialogContentProps) {
  const state = useStoreState(store);
  const [interviewPlanItems, setInterviewPlanItems] = useState<
    InterviewPlanItem[]
  >(initialInterviewPlanItems || []);
  const [isDirty, setIsDirty] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [saveChangesToParent, setSaveChangesToParent] = useState(false);
  const selectedItem = useMemo(
    () => interviewPlanItems.find((i) => i.id === selectedItemId) ?? null,
    [interviewPlanItems, selectedItemId]
  );
  const prevDefaultSelectedId = usePrevious(defaultSelectedInterviewPlanId);

  useEffect(() => {
    // Only update selection if defaultSelectedInterviewPlanId changes from previous value
    // or if we don't have a selection but have items
    if (
      defaultSelectedInterviewPlanId !== prevDefaultSelectedId &&
      defaultSelectedInterviewPlanId
    ) {
      setSelectedItemId(defaultSelectedInterviewPlanId);
    } else if (interviewPlanItems.length > 0 && !selectedItemId) {
      // If we have items but no selection, select first item
      setSelectedItemId(interviewPlanItems[0].id);
    } else if (interviewPlanItems.length === 0) {
      // If no items, clear selection
      setSelectedItemId(null);
    }
  }, [
    defaultSelectedInterviewPlanId,
    prevDefaultSelectedId,
    interviewPlanItems,
    selectedItemId,
  ]);

  useSyncDialogLeaveConfirmation({
    showConfirmation: isDirty,
    jotaiStore,
  });

  useEffect(() => {
    if (!state.open) {
      setIsDirty(false);
    }
  }, [state.open]);

  // Update items when initialInterviewPlanItems changes
  useEffect(() => {
    if (initialInterviewPlanItems) {
      setInterviewPlanItems(initialInterviewPlanItems);
    }
  }, [initialInterviewPlanItems]);

  const notifyItemsChange = useCallback(() => {
    setIsDirty(true);
    onItemsChange?.();
  }, [onItemsChange]);

  const removeItem = useCallback(
    (item: { id: string }) => {
      notifyItemsChange();
      setInterviewPlanItems((currentItems) =>
        currentItems?.filter((i) => item.id !== i.id)
      );
    },
    [notifyItemsChange]
  );

  const updateCurrentItem = useCallback(
    (item: Partial<InterviewPlanItem>) => {
      if (!selectedItem) {
        return;
      }
      notifyItemsChange();

      const newSelectedItem = {
        ...selectedItem,
        ...item,
      };

      setInterviewPlanItems((currentItems) =>
        currentItems?.map((i) => {
          if (i.id === selectedItem.id) {
            return newSelectedItem;
          }
          return i;
        })
      );
    },
    [selectedItem, notifyItemsChange]
  );

  const createStage = useCallback(
    (name: string) => {
      notifyItemsChange();
      const newStageId = uuid();
      setInterviewPlanItems((currentItems) => [
        ...(currentItems ?? []),
        {
          id: newStageId,
          title: name,
          description: null,
          position: currentItems?.length ?? 0,
          postTemplates: [],
          interviewStageContent: null,
        },
      ]);
      setSelectedItemId(newStageId);
    },
    [notifyItemsChange]
  );

  const errorMessage = useMemo(() => {
    if (!interviewPlanItems || interviewPlanItems.length === 0) {
      return "You must have at least one stage.";
    }

    const missingTitle = interviewPlanItems.findIndex(
      (item) => !item.title.trim()
    );
    if (missingTitle !== -1) {
      return `Stage ${missingTitle + 1} requires a title.`;
    }

    return undefined;
  }, [interviewPlanItems]);

  const onCancel = useCallback(() => {
    store.hide();
    logEvent("Interview plan closed");
  }, [store, logEvent]);

  const handleSubmit = useCallback(() => {
    setIsDirty(false); // Clear dirty state before submitting
    return onSubmit({
      items: interviewPlanItems ?? [],
      saveChangesToParent,
    });
  }, [interviewPlanItems, onSubmit, saveChangesToParent]);

  return (
    <View
      header={{
        title: `Edit interview plan`,
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button isGhost onClick={onCancel}>
              Cancel
            </Button>
            <Tooltip content={errorMessage} isInstant>
              <Button
                variant="primary"
                isLoading={saving}
                disabled={!!errorMessage}
                onClick={handleSubmit}
              >
                Save changes
              </Button>
            </Tooltip>
          </ButtonGroup>
        ),
        leftActions: !hideSaveChangesToParent ? (
          <div className="flex space-x-2">
            <Checkbox
              checked={saveChangesToParent}
              onChange={(e) => setSaveChangesToParent(e.currentTarget.checked)}
            />
            <span className="text-body-md">
              Save these changes for future candidates of this job.
            </span>
          </div>
        ) : undefined,
      }}
    >
      {loading && (
        <div className="flex flex-col justify-center items-center">
          <Loading />
        </div>
      )}
      {!loading && (
        <div className="-mx-6 flex min-h-[35rem]">
          <div className="p-6 w-80 flex-shrink-0 border-r border-light-gray-600">
            <div className="text-body-md-heavy">Stages</div>
            <ReorderableStages
              logEvent={logEvent}
              stages={interviewPlanItems}
              containerRef={containerRef}
              reorderStages={(stages: { id: string }[]) => {
                notifyItemsChange();
                setInterviewPlanItems(
                  stages
                    .map((stage) => {
                      const item = interviewPlanItems.find(
                        (i) => i.id === stage.id
                      );

                      if (!item) {
                        return null;
                      }
                      return item;
                    })
                    .filter((i): i is InterviewPlanItem => !!i)
                );
              }}
              removeStage={removeItem}
              selectedStage={selectedItem}
              setSelectedStage={(selected) => {
                setSelectedItemId(selected.id);
              }}
              createStage={createStage}
            />
          </div>
          <StageEditor
            logEvent={logEvent}
            className="grow p-6 text-body-md overflow-y-scroll"
            stage={selectedItem}
            updateStage={updateCurrentItem}
            removeStage={removeItem}
          />
        </div>
      )}
    </View>
  );
}

// For backward compatibility, export a function that returns the component
export function useInterviewPlanDialogContent(
  props: EditInterviewPlanDialogProps
) {
  return useMemo(
    () => ({
      Modal: <EditInterviewPlanDialog {...props} />,
      setInterviewPlanItems: () => {
        console.warn(
          "setInterviewPlanItems is deprecated. Use the InterviewPlanDialog component directly."
        );
      },
    }),
    [props]
  );
}
