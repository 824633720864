import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { BackButton } from "client/components/generic/layout/BackButton";
import ErrorPage from "components/Generic/ErrorPage";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";
import useQuery from "utils/useQuery";

import { ScheduledInterviewDetailsLite } from "./__components/ScheduledInterviewDetailsLite";
import { ScheduledInterviewDetailsScheduling } from "./__components/ScheduledInterviewDetailsScheduling";

gql(`
  fragment ScheduleInterviewForInterviewDetails on ScheduledInterview {
    id
    guideId
    startTime
    endTime
    interviewRequirementId
    createdByGuide
    isCancelled
    schedulingRequestId
    isPrivate
    blacklisted
    ...ScheduledInterviewForEditForm
    interviewers {
      id
      ...InterviewerForInterviewersDisplay
    }
    atssyncScheduledInterview {
      id
      name
      remoteWasDeleted
    }
    googleEvent {
      id
      eventId
      htmlLink
      isValid
      hasChanges
      status
      calendarId
      ...GoogleEventForIntegrationDifferences
    }
    organization {
      id
      isZoomEnabled
      isGoogleCalendarEnabled
    }
    guide {
      id
      jobId
    }
    ...ScheduledInterviewForScheduledInterviewCard
    videoConferencing {
      id
      ...VideoConferencingDataForScheduledInterviewCard
    }
    ...ScheduledInterviewForGoogleEventView
    ...ScheduledInterviewForIntegrationDifferences
  }
`);

const GET_SCHEDULED_INTERVIEW = gql(`
  query GetScheduleInterviewForInterviewDetails($guideId: String!, $interviewId: String!) {
    scheduledInterviewsById(guideId: $guideId, interviewIds: [$interviewId]) {
      ...ScheduleInterviewForInterviewDetails
    }
  }
`);

type ScheduledInterviewDetailsProps = {
  scheduledInterviewId: string;
  guideId: string;
  onBack?: () => void;
};

export function ScheduledInterviewDetails({
  scheduledInterviewId,
  guideId,
  onBack,
}: ScheduledInterviewDetailsProps) {
  const { data } = useQuery(GET_SCHEDULED_INTERVIEW, {
    variables: {
      guideId,
      interviewId: scheduledInterviewId,
    },
  });
  const { hasFeatureEnabled } = useAuthContext();
  const scheduledInterview = data?.scheduledInterviewsById[0];

  if (!data) {
    return (
      <View
        header={{
          leftActions: <BackButton onClick={onBack} />,
        }}
      >
        <Loading />
      </View>
    );
  }

  if (!scheduledInterview) {
    return <ErrorPage errorCode="404" />;
  }

  if (
    !scheduledInterview.createdByGuide ||
    !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING)
  ) {
    return (
      <ScheduledInterviewDetailsLite
        scheduledInterview={scheduledInterview}
        onBack={onBack}
      />
    );
  }

  return (
    <ScheduledInterviewDetailsScheduling
      scheduledInterview={scheduledInterview}
      onBack={onBack}
    />
  );
}
