import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPersonSparkle } from "@resource/atlas/icons";
import { TourRenderer } from "client/components/tours/TourRenderer";
import { TourConfig } from "client/components/tours/types";
import { useTourManager } from "client/components/tours/useTourManager";
import { useDomObserver } from "client/components/tours/utils/useDomObserver";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import { useEffect, useMemo } from "react";
import useQuery from "utils/useQuery";

const selectors = {
  interviewerSelectCombobox:
    ".tours__interviewer-tags__interviewer-select-combobox",
  interviewerSelectPopover:
    ".tours__interviewer-tags__interviewer-select-popover",
  smartMatchSection: ".tours__interviewer-tags__smart-match-option",
  trainingPathsSection: ".tours__interviewer-tags__training-paths-tab",
};

const FETCH_TAGS = gql(`
query FetchTagsForTagsSchedulerTour {
  tags {
    id
  }
}
`);

export function AdvancedInterviewerTagsSchedulerTour() {
  const { data: tagsData } = useQuery(FETCH_TAGS);
  const anyTagsExist = !!tagsData?.tags?.length;

  const selectCombobox = useDomObserver(selectors.interviewerSelectCombobox);

  const {
    [FeatureFlags.INTERVIEWER_TAGS]: interviewerTagsEnabled,
    [FeatureFlags.TAGS_PRODUCT_TOURS]: tagsProductToursEnabled,
  } = useFlags();

  const tour = useMemo(
    (): TourConfig => ({
      storageKey: "advanced-interviewer-tags-scheduler-tour",
      learnMoreUrl:
        "https://intercom.help/guide-v2-help-center/en/articles/10262620-using-interviewer-tags-and-smart-match-interviewer-selection",
      stages: [
        // Initial narrator announcement
        {
          instructions: {
            content: (
              <div className="space-y-2">
                <div className="flex items-start gap-2 text-body-md-heavy">
                  <Icon content={atlasPersonSparkle} /> Filter interviewers with
                  tags using &quot;Smart Match&quot;
                </div>
                <p className="mt-2">
                  Use tags for more fine-grained selection from a group of
                  interviewers in an automated way (Ie. &quot;interviewer must
                  be L5, L6 or L7 AND based in EMEA&quot;)
                </p>
                <p className="text-subtle">
                  Click &quot;Add interviewer…&quot; to continue.
                </p>
              </div>
            ),
            hideCTA: true,
            target: selectors.interviewerSelectCombobox,
            placement: "bottom",
          },
          advanceOn: {
            selector: selectors.interviewerSelectCombobox,
            event: "click",
          },
          highlights: [selectors.interviewerSelectCombobox],
        },
        // Smart Match section
        {
          instructions: {
            content: (
              <>
                <span className="text-body-md-heavy">Smart Match</span>
                <p className="mt-2">
                  Smart Match automatically suggests interviewers based on the
                  candidate&apos;s background and the role requirements,
                  ensuring the most relevant expertise for each interview.
                </p>
              </>
            ),
            target: selectors.interviewerSelectCombobox,
            placement: "left",
          },
          advanceOn: {
            selector: selectors.smartMatchSection,
            event: "click",
          },
          highlights: [
            selectors.interviewerSelectCombobox,
            selectors.interviewerSelectPopover,
          ],
        },
      ],
    }),
    []
  );

  const tourState = useTourManager(tour);

  useEffect(() => {
    if (
      !tourState.isStarted &&
      !tourState.isFinished &&
      selectCombobox &&
      anyTagsExist &&
      interviewerTagsEnabled &&
      tagsProductToursEnabled
    ) {
      tourState.start();
    }
  }, [
    selectCombobox,
    tourState,
    interviewerTagsEnabled,
    tagsProductToursEnabled,
    anyTagsExist,
  ]);

  return <TourRenderer tour={tourState} />;
}
