import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { GuideForComposeMessageFormFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo, useState } from "react";
import { formatEntity } from "shared/constants/entities";
import { findRegexInContentForGuideUpdate } from "shared/utils/lexical";

import { PostMessageSendWarningsDialogProps } from "../__components/PostMessageSendWarningsDialog";
import { GuideComposeMessageState } from "./useGuideComposeMessageState";

export function useValidationOnSend<T>({
  guide,
  composeState,
  onSubmit,
}: {
  guide: GuideForComposeMessageFormFragment;
  composeState: GuideComposeMessageState;
  onSubmit: (props: T) => Promise<void>;
}): {
  onSubmitWithWarnings: (props: T) => Promise<void>;
  dialogProps: PostMessageSendWarningsDialogProps;
} {
  const { email, firstName } = guide.candidate;
  const { publicUrl: guideLink } = guide;
  const store = useDialogStore();
  const [submitProps, setSubmitProps] = useState<T | null>(null);
  const [dialogContent, setDialogContent] = useState<
    PostMessageSendWarningsDialogProps["content"] | null
  >(null);
  const [dialogSubmitButtonText, setDialogSubmitButtonText] = useState<
    string | null
  >(null);

  const onConfirm = useCallback(async () => {
    store.hide();
    await onSubmit(submitProps ?? ({} as T));
  }, [onSubmit, store, submitProps]);
  const onCancel = useCallback(() => {
    store.hide();
  }, [store]);

  const getMissingCandidateEmail = useCallback(() => {
    return !email;
  }, [email]);

  const getSendingToCandidateGuideOnly = useCallback(() => {
    const { to } = composeState.form.getValues();
    return !to;
  }, [composeState.form]);

  const getWrongCandidateGuideLink = useCallback(() => {
    const { content } = composeState.form.getValues();
    const baseUrl = process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT;
    const guideLinkRegex = new RegExp(
      `${baseUrl}/guide/[^/\\.,\\s]+/[^/\\.,\\s]+`,
      "ig"
    );
    const candidateGuideLinks = findRegexInContentForGuideUpdate(
      JSON.stringify(content),
      guideLinkRegex
    );
    const wrongCandidateGuideLink = candidateGuideLinks
      ? candidateGuideLinks.find((link) => link !== guideLink)
      : null;

    return wrongCandidateGuideLink;
  }, [composeState.form, guideLink]);

  const runValidation = useCallback(() => {
    let shouldPrompt = false;

    if (getSendingToCandidateGuideOnly()) {
      const title = getMissingCandidateEmail()
        ? "The candidate does not have an email address."
        : "You have not selected an email recipient.";
      setDialogContent(
        <div className="space-y-3">
          <div className="space-y-1">
            <p className="text-body-md-heavy">{title}</p>
          </div>
          <p className="text-body-md">
            Are you sure you want to post a message to the
            {formatEntity("guide")} without sending a notification?
          </p>
        </div>
      );
      setDialogSubmitButtonText(`Send to ${formatEntity("guide")} only`);
      shouldPrompt = true;
    }

    const wrongCandidateGuideLink = getWrongCandidateGuideLink();

    if (wrongCandidateGuideLink) {
      setDialogContent(
        <div className="space-y-3">
          <div className="space-y-1">
            <p className="text-body-md-heavy">
              Your message contains a link to a different candidate&apos;s
              portal.
            </p>
            <p className="text-body-sm text-subtle">
              {`${firstName}'s ${formatEntity("guide")} link: ${guideLink}`}
            </p>
            <p className="text-body-sm text-subtle">
              Link in message: {wrongCandidateGuideLink}
            </p>
          </div>
          <p className="text-body-md">
            Are you sure you want to include this {formatEntity("guide")} link
            in your message?
          </p>
        </div>
      );
      setDialogSubmitButtonText("Send anyways");
      shouldPrompt = true;
    }

    return {
      shouldPrompt,
    };
  }, [
    getMissingCandidateEmail,
    getSendingToCandidateGuideOnly,
    getWrongCandidateGuideLink,
    firstName,
    guideLink,
  ]);

  const dialogProps = useMemo((): PostMessageSendWarningsDialogProps => {
    return {
      dialog: {
        store,
      },
      onConfirm,
      onCancel,
      content: dialogContent ?? (
        <div>
          <p>Something went wrong.</p>
          <p>Send message anyway?</p>
        </div>
      ),
      submitButtonText: dialogSubmitButtonText ?? "Send",
    };
  }, [store, onConfirm, onCancel, dialogContent, dialogSubmitButtonText]);

  const confirmSend = useCallback(
    async (props: T) => {
      setSubmitProps(props);
      store.show();
    },
    [store]
  );

  const onSubmitWithWarnings = useCallback(
    async (props: T) => {
      const { shouldPrompt } = runValidation();
      if (shouldPrompt) {
        confirmSend(props);
      } else {
        await onSubmit(props);
      }
    },
    [runValidation, confirmSend, onSubmit]
  );

  return {
    onSubmitWithWarnings,
    dialogProps,
  };
}
