import { Tab as AriakitTab, TabList, TabPanel, TabState } from "ariakit";
import clsx from "clsx";
import { forwardRef, memo, useMemo } from "react";

import {
  createChildRenderFunction,
  createComponentUtils,
  createRootContext,
  usePExtendedState,
} from "../__utils/atlas";
import {
  setCollectionMetadata,
  usePCollection,
  useRenderCollection,
} from "../__utils/collections";
import Badge from "../badge/Badge";
import { Icon } from "../icon/Icon";
import type {
  AtlasTabsListProps,
  AtlasTabsPanelProps,
  AtlasTabsRootProps,
  AtlasTabsTabProps,
} from "./types";
import { useTabsState } from "./use-tabs-state";

// config
// ------

const COMPONENT_NAME = "Tabs";

const { el, setTreeDisplayName } = createComponentUtils(COMPONENT_NAME);

// context
// -------

type TabsRootContext = { tabs: TabState };

const { RootProvider, useRootContext } =
  createRootContext<TabsRootContext>(COMPONENT_NAME);

// root
// ----

/**
 * The root of the tabs component. Expects tabs and optionally panels.
 *
 * @example
 * <Tabs.Root>
 *   <Tabs.TabList>
 *     <Tabs.Tab />
 *     <Tabs.Tab />
 *   </Tabs.TabList>
 *   <Tabs.Panel />
 *   <Tabs.Panel />
 * </Tabs.Root>
 */
export const Root = memo(
  ({ children, state, ...stateProps }: AtlasTabsRootProps) => {
    const tabs = useTabsState(stateProps);

    const contextValue = useMemo(
      () => ({ tabs: state ?? tabs }),
      [state, tabs]
    );

    return <RootProvider value={contextValue}>{children}</RootProvider>;
  }
);
setTreeDisplayName(Root, "Root");

// tab
// ---

/**
 * A tab. Must be a child of `<Tabs.List />`.
 *
 * @example
 * <Tabs.Tab />
 */
export const Tab = memo(
  forwardRef<HTMLButtonElement, AtlasTabsTabProps>(
    ({ renderWrapper, icon, badge, children, className, ...props }, ref) => {
      // if wrapped, the inner element shouldn't be a button
      const Element = renderWrapper ? "div" : "button";

      const renderTab = createChildRenderFunction({
        // @ts-expect-error - not properly typed in React 18
        props: { renderWrapper, children },
        name: "Tabs.Tab",
        originalElement: (
          <Element className={clsx(el`tab`, className)}>
            {icon && <Icon content={icon} />}
            {/* @ts-expect-error - not properly typed in React 18 */}
            <span>{children}</span>
            {badge && (
              <Badge size="small" styles="bold">
                {badge}
              </Badge>
            )}
          </Element>
        ),
      });

      return (
        <AriakitTab ref={ref} {...props}>
          {renderTab}
        </AriakitTab>
      );
    }
  )
);
setTreeDisplayName(Tab, "Tab");
setCollectionMetadata(Tab, { type: "tab" });

// tab list
// --------

/**
 * A list of tabs. Must be a child of `<Tabs.Root />`.
 *
 * @example
 * <Tabs.List>
 *   <Tabs.Tab />
 *   <Tabs.Tab />
 * </Tabs.List>
 */
export const List = memo(
  forwardRef<HTMLDivElement, AtlasTabsListProps>(
    ({ className, ...p0 }, ref) => {
      const [tabs, p1] = usePExtendedState(p0, useRootContext().tabs);
      const [collectionItems, props] = usePCollection(p1, "useTabsItems");

      const renderedItems = useRenderCollection(collectionItems, {
        tab: (tabProps) => <Tab {...tabProps} />,
      });

      return (
        <TabList
          state={tabs}
          ref={ref}
          className={clsx(el`list`, className)}
          {...props}
        >
          {renderedItems}
        </TabList>
      );
    }
  )
);
setTreeDisplayName(List, "List");

// panel
// -----

/**
 * A panel that shows when the corresponding tab is selected. Must be a
 * child of `<Tabs.Root />`.
 *
 * @example
 * <Tabs.Root>
 *   <Tabs.Panel />
 *   <Tabs.Panel />
 * </Tabs.Root>
 */
export const Panel = memo(
  forwardRef<HTMLDivElement, AtlasTabsPanelProps>((p0, ref) => {
    const [tabs, props] = usePExtendedState(p0, useRootContext().tabs);
    return <TabPanel state={tabs} ref={ref} {...props} />;
  })
);
setTreeDisplayName(Panel, "Panel");

// TODO:
// - overflow UI
// - bug?: disabled tabs can be focused
