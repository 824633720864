import {
  JobInterviewSelect,
  JobInterviewSelectProps,
  SelectedJobInterview,
} from "client/components/interviews/JobInterviewSelect";
import { gql } from "generated/graphql-codegen";
import { InterviewsForRequirementConfigurationInterviewSelectQuery } from "generated/graphql-codegen/graphql";

gql(`
  fragment InterviewForRequirementConfigurationSelect on Interview {
    id
    title
    jobInterviewStage {
      id
      name
      position
    }
    interviewRequirement {
      id
      ...InterviewRequirementForConfiguration
    }
  }
`);

const INTERVIEWS_FOR_JOB = gql(`
  query InterviewsForRequirementConfigurationInterviewSelect($jobId: String!) {
    fetchInterviewsForJob(jobId: $jobId, includeBlacklisted: true) {
      ...InterviewForRequirementConfigurationSelect
    }
  }
`);

export type SelectedJobInterviewForRequirementConfiguration =
  SelectedJobInterview<InterviewsForRequirementConfigurationInterviewSelectQuery>;

type RequirementConfigurationInterviewSelectProps = Omit<
  JobInterviewSelectProps<InterviewsForRequirementConfigurationInterviewSelectQuery>,
  "query"
>;

export function RequirementConfigurationInterviewSelect(
  props: RequirementConfigurationInterviewSelectProps
) {
  return (
    <JobInterviewSelect
      {...props}
      query={INTERVIEWS_FOR_JOB}
      enforceSchedulable
    />
  );
}
