import { ComponentPropsWithoutRef } from "react";
import ReactDOM from "react-dom";

export function DayHeadersViewElement({
  children,
}: {
  children: ComponentPropsWithoutRef<"div">["children"];
}) {
  const portalElement = document.getElementById("day-headers-view-portal");

  if (!portalElement) return null;

  return ReactDOM.createPortal(children, portalElement);
}
