import {
  getInterviewerSlotWithSchedulingData,
  useInterviewerSlotFetchedData,
} from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { UserMembershipWithSchedulingData } from "client/components/interviewer-slots/utils/types";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useStagedInterviews } from "./interviews";

export type InterviewsWithOverloadsMap = {
  [interviewId: string]: UserMembershipWithSchedulingData[];
};

export function useInterviewsOutsideWorkingHoursMap() {
  const interviews = useStagedInterviews();
  const slotFetchedData = useInterviewerSlotFetchedData();

  return useMemo(() => {
    return interviews.reduce<InterviewsWithOverloadsMap>((acc, interview) => {
      const slotsWithCalculations = interview.interviewerSlots.map(
        (interviewerSlot) => {
          return getInterviewerSlotWithSchedulingData({
            ...slotFetchedData,
            interviewerSlot,
            interview,
          });
        }
      );

      const allUserMembershipsWithCalculations = slotsWithCalculations.flatMap(
        (slot) =>
          [
            slot.interviewer?.userMembership,
            slot.shadowingInterviewer?.userMembership,
          ].filter(filterOutNullsAndUndefined)
      );
      const outsideWorkingHoursUserMemberships =
        allUserMembershipsWithCalculations.filter((userMembership) => {
          if (!userMembership.conflictData?.outsideWorkingHours) {
            return false;
          }

          return true;
        });

      return {
        ...acc,
        [interview.id]: outsideWorkingHoursUserMemberships,
      };
    }, {});
  }, [interviews, slotFetchedData]);
}
