/* eslint-disable import/prefer-default-export */
// This file has been automatically generated with _refresh-script.js
// DO NOT MODIFY MANUALLY

export const colors = {
  yellow: {
    50: "#fff8e5",
    100: "#ffedbf",
    200: "#ffe399",
    300: "#ffcd4c",
    400: "#ffc326",
    500: "#ffb800",
    600: "#f2af00",
    700: "#e6a600",
    800: "#bf8a00",
    900: "#664a00",
  },
  black: "#000000",
  red: {
    50: "#feece9",
    100: "#fccfc8",
    200: "#fab2a7",
    300: "#f77864",
    400: "#f55b43",
    500: "#f33e22",
    600: "#e73b20",
    700: "#db381f",
    800: "#b62e19",
    900: "#61190e",
  },
  orange: {
    50: "#fff0e9",
    100: "#ffdac9",
    200: "#ffc4a9",
    300: "#ff9768",
    400: "#ff8147",
    500: "#ff6b27",
    600: "#f26625",
    700: "#e66023",
    800: "#bf501d",
    900: "#662b10",
  },
  green: {
    50: "#e5f7f2",
    100: "#bfebde",
    200: "#99dfca",
    300: "#4cc8a3",
    400: "#26bc8f",
    500: "#00b07b",
    600: "#00a775",
    700: "#009f6f",
    800: "#00845c",
    900: "#004631",
  },
  "dark-blue": {
    50: "#e9e7ec",
    100: "#c7c4cf",
    200: "#a5a1b3",
    300: "#625a79",
    400: "#41375d",
    500: "#1f1440",
    600: "#1d133d",
    700: "#1c123a",
    800: "#191033",
    900: "#0f0a20",
  },
  indigo: {
    50: "#e9e7ec",
    100: "#c7c4cf",
    200: "#a5a1b3",
    300: "#625a79",
    400: "#41375d",
    500: "#1f1440",
    600: "#1f1440",
    700: "#1c123a",
    800: "#170f30",
    900: "#0c081a",
  },
  "dark-gray": {
    50: "#a7a5ad",
    100: "#7b7984",
    200: "#4f4c5b",
    300: "#393547",
    400: "#2e2a3c",
    500: "#231f32",
    600: "#211d2f",
    700: "#201c2d",
    800: "#1c1928",
    900: "#15131e",
  },
  "light-gray": {
    50: "#fcfdfd",
    100: "#f9fafb",
    200: "#f6f8f9",
    300: "#f3f5f7",
    400: "#f1f4f6",
    500: "#f0f3f5",
    600: "#e8ebed",
    700: "#dde0e2",
    800: "#c9ccce",
    900: "#b4b6b7",
  },
  gray: {
    50: "#cad0d4",
    100: "#afb8bf",
    200: "#95a1a9",
    300: "#87959f",
    400: "#818f99",
    500: "#7a8994",
    600: "#74828c",
    700: "#6e7c85",
    800: "#626e76",
    900: "#495259",
  },
  blue: {
    50: "#e5f0ff",
    100: "#bfdaff",
    200: "#99c3ff",
    300: "#4c97ff",
    400: "#2680ff",
    500: "#006aff",
    600: "#0065f2",
    700: "#0060e6",
    800: "#0055cc",
    900: "#002a66",
  },
  purple: {
    50: "#edecfc",
    100: "#d3d1f8",
    200: "#b9b5f5",
    300: "#847ded",
    400: "#6962e9",
    500: "#4f46e5",
    600: "#4b42d9",
    700: "#473fce",
    800: "#3b34ab",
    900: "#201c5c",
  },
  pink: {
    50: "#ffeaee",
    100: "#ffcbd5",
    200: "#ffabbc",
    300: "#ff6d89",
    400: "#ff4d70",
    500: "#ff2e57",
    600: "#f22c53",
    700: "#e6294e",
    800: "#bf2241",
    900: "#661223",
  },
  cyan: {
    50: "#ebfcff",
    100: "#cdf8ff",
    200: "#aff4ff",
    300: "#74ecff",
    400: "#56e8ff",
    500: "#38e4ff",
    600: "#35d8f2",
    700: "#32cee6",
    800: "#2aabbf",
    900: "#165b66",
  },
  transparent: "#ffffff00",
  white: "#ffffff",
};
