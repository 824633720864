import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { strings } from "@resource/common";

interface UseActionMenuItemsProps {
  onRemove?: () => void;
  onEdit?: () => void;
  setLocked?: (locked: boolean) => void;
  unscheduledInterview: {
    order?: number | null;
    orderLocked: boolean;
  };
}

export function useActionMenuItems({
  onEdit,
  onRemove,
  setLocked,
  unscheduledInterview,
}: UseActionMenuItemsProps) {
  return useMenuItems(
    (i) => {
      const lockItems = [];
      if (setLocked) {
        lockItems.push(
          unscheduledInterview.orderLocked
            ? i.item({
                id: "unlock",
                children: `Unlock from ${strings.addSuffixToNumber(
                  unscheduledInterview.order ?? 0
                )} position`,
                onClick: () => setLocked(false),
              })
            : i.item({
                id: "lock",
                children: `Lock to position`,
                onClick: () => setLocked(true),
              })
        );
      }

      return [
        ...(onEdit
          ? [
              i.item({
                id: "edit",
                children: "Edit",
                onClick: onEdit,
              }),
            ]
          : []),
        ...lockItems,
        ...(onRemove
          ? [
              i.item({
                id: "remove",
                children: "Remove",
                onClick: onRemove,
              }),
            ]
          : []),
      ];
    },
    [
      onEdit,
      onRemove,
      setLocked,
      unscheduledInterview.order,
      unscheduledInterview.orderLocked,
    ]
  );
}
