import { DateTime, Interval } from "luxon";
import { WorkingHoursConfig } from "shared/utils/working-hours";

interface getWorkingHourIntervalsParams {
  interviewer: {
    workingHours: WorkingHoursConfig;
    user: { timezone: string | null };
  };
  interval: Interval;
}

/* Return the intersection of an interviewer's working hours and an interval.
   If an interviewer has no preferences, return [interval] - the entire duration.
   If there's no overlap, return [null, null], ie no valid working hours.

   null is the default "invalid interval" in Luxon:
   https://moment.github.io/luxon/api-docs/index.html#intervalengulfs

   Can be used to eg bound working hours from 12am-12am in a particular
   time zone using a full day interval.
*/
export const getTrimmedWorkingHourIntervals = ({
  interviewer,
  interval,
}: getWorkingHourIntervalsParams) => {
  const workIntervals = getWorkingHourIntervals({ interviewer, interval });
  return workIntervals.map((workInterval) =>
    workInterval === null ? workInterval : interval.intersection(workInterval)
  );
};

/* Get working hour intervals in the user's timezone for a specific day. */
export const getWorkingHourIntervals = ({
  interviewer: {
    workingHours,
    user: { timezone: localTimezone },
  },
  interval,
}: getWorkingHourIntervalsParams): (Interval | null)[] => {
  // If they have no preferences, or no timezone, we don't know
  // when they are available. Assume full length.
  if (workingHours === null || localTimezone === null) {
    return [interval];
  }

  // If localTimezone and interval timezone are mismatched, we need to check
  // working hours for two days.
  // If localTimezone is behind, localStart will be a day behind.
  // If localTimezone is ahead, localEnd will be a day forward.
  const localStart = interval.start.setZone(localTimezone);
  const localEnd = interval.end.setZone(localTimezone);

  const getWorkingHourIntervalForOneDay = (localDay: DateTime) => {
    // Luxon weekday is 1-7 Monday-Sunday, convert to the workingHours indices 0-6 Monday-Sunday.
    const i = localDay.weekday - 1;
    if (!workingHours[i].isWorkingDay) {
      return null;
    }

    const workdayStart = localDay.set({
      hour: workingHours[i].startTime?.hour,
      minute: workingHours[i].startTime?.minute,
      // Reset second, millisecond from endOf("day")
      second: 0,
      millisecond: 0,
    });

    const workdayEnd = localDay.set({
      hour: workingHours[i].endTime?.hour,
      minute: workingHours[i].endTime?.minute,
      second: 0,
      millisecond: 0,
    });

    const workInterval = Interval.fromDateTimes(
      workdayStart.setZone(interval.start.zoneName),
      workdayEnd.setZone(interval.start.zoneName)
    );
    return workInterval;
  };

  const intervals: (Interval | null)[] = [
    getWorkingHourIntervalForOneDay(localStart),
    getWorkingHourIntervalForOneDay(localEnd),
  ];
  return intervals;
};

export const workingHoursEngulfInterval = ({
  interviewer,
  interval,
}: getWorkingHourIntervalsParams) => {
  const workingHourIntervals = getWorkingHourIntervals({
    interviewer,
    interval,
  });

  return workingHourIntervals.some(
    (workingInterval) =>
      workingInterval !== null && workingInterval.engulfs(interval)
  );
};
