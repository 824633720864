export enum PostMessageWorkflow {
  SEND_MESSAGE = "send_message",
  REQUEST_AVAILABILITY = "request_availability",
  CONFIRM_INTERVIEWS = "confirm_interviews",
  REQUEST_ADDITIONAL_AVAILABILITY = "request_additional_availability",
  CONFIRM_RESCHEDULE_INTERVIEWS = "confirm_reschedule_interviews",
  NOTIFY_OF_ACTIVE = "notify_of_active",
  NOTIFY_OF_OFFER = "notify_of_offer",
  NOTIFY_OF_REJECTION = "notify_of_rejection",
  NOTIFY_OF_HIRED = "notify_of_hired",
  SELF_SCHEDULE_REQUEST = "self_schedule_request",
}
