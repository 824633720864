import Loom from "components/Loom/Loom";
import YouTube from "components/YouTube/YouTube";
import getYoutubeID from "get-youtube-id";
import isUrl from "is-url";
import _ from "lodash";

type VideoIntroProps = {
  mediaLink: string;
};

function VideoIntro({ mediaLink }: VideoIntroProps) {
  // Only render this component if the url is valid
  if (!isUrl(mediaLink)) {
    return null;
  }

  const isLoom = _.includes(mediaLink, "loom.com");
  const videoId = getYoutubeID(mediaLink);

  return (
    <>
      {videoId && <YouTube videoId={videoId} />}
      {isLoom && <Loom videoUrl={mediaLink} autoplay />}
    </>
  );
}
export default VideoIntro;
