import { withCalendarScrollBuffer } from "client/components/calendar-v2/utils/scrolling";
import { DateTime } from "luxon";
import { useMemo } from "react";

import {
  useSelectedInterview,
  useStagedInterviews,
} from "../../hooks/interviews";
import { useSchedulerGuide } from "../../hooks/useSchedulerGuide";

export function useCalendarInitialScroll() {
  const selectedInterview = useSelectedInterview();
  const guide = useSchedulerGuide();
  const stagedInterviews = useStagedInterviews();
  const initialScrollTime = useMemo(() => {
    if (selectedInterview?.startTime) {
      return DateTime.fromISO(selectedInterview.startTime);
    }

    if (
      guide?.currentAvailabilitySubmission?.events &&
      guide?.currentAvailabilitySubmission?.events.length > 0
    ) {
      const futureAvailability =
        guide.currentAvailabilitySubmission.events.find(
          (event) =>
            DateTime.fromISO(event.startTime).diffNow().milliseconds > 0
        );

      if (futureAvailability) {
        return DateTime.fromISO(futureAvailability.startTime);
      }
    }

    const firstStagedInterview = stagedInterviews.sort(
      (a, b) =>
        DateTime.fromISO(a.startTime).toMillis() -
        DateTime.fromISO(b.startTime).toMillis()
    )[0];

    if (firstStagedInterview) {
      return DateTime.fromISO(firstStagedInterview.startTime);
    }

    return undefined;
  }, [selectedInterview, guide, stagedInterviews]);

  return useMemo(() => {
    if (!initialScrollTime) return undefined;

    return withCalendarScrollBuffer(initialScrollTime, { bufferHours: 2 });
  }, [initialScrollTime]);
}
