import { Icon } from "@resource/atlas/icon/Icon";
import { serviceGuide } from "@resource/atlas/icons";

interface FakeExtensionGuideButtonProps {
  onClick: () => void;
  unreadNotificationCount: number;
  isOpen?: boolean;
  style?: React.CSSProperties;
}

export function FakeExtensionGuideButton({
  onClick,
  unreadNotificationCount,
  isOpen = false,
  style,
}: FakeExtensionGuideButtonProps) {
  return (
    // Specifically styling it this way to match styles in the chrome extension where we don't
    // have Atlas or tailwind
    <div
      role="button"
      tabIndex={0}
      style={{
        zIndex: 2147483646,
        position: "absolute",
        bottom: 16,
        right: 16,
        width: 56,
        height: 56,
        transition: isOpen ? "all 300ms ease" : "none",
        opacity: isOpen ? 0 : 1,
        background: "#1F1440",
        boxShadow:
          "0px 8px 8px rgba(35, 31, 50, 0.1), 0px 0px 8px rgba(35, 31, 50, 0.1), 0px 0px 0px 1px rgba(35, 31, 50, 0.05)",
        borderRadius: 56,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      onClick={onClick}
      onKeyDown={(e) => e.key === "Enter" && onClick()}
    >
      <Icon content={serviceGuide} className="w-10 h-10" />
      {unreadNotificationCount > 0 && (
        <div className="absolute flex items-center justify-center min-w-4 h-4 text-[11px] rounded-full px-1.5 top-0 right-0 bg-purple-600 text-white [box-shadow:0_0_0_2px_#ffffff]">
          {unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}
        </div>
      )}
    </div>
  );
}
