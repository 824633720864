import { View, ViewProps } from "@resource/atlas/view/View";
import { BackButton } from "client/components/generic/layout/BackButton";
import { CloseButton } from "client/components/generic/layout/CloseButton";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useCallback, useEffect } from "react";
import {
  InterviewerDecline,
  InterviewerNoShow,
  ScheduledInterviewOtherReason,
  ScheduledInterviewRescheduleReason,
} from "shared/reporting/scheduled-interviews/types";

import { InterviewerMultiSelectItem } from "../__components/InterviewerMultiSelect";
import { ReportRescheduleReasonForm } from "../__components/ReportRescheduleReasonForm";
import { ReportRescheduleReasonFormState } from "../hooks/useReportRescheduleReasonState";

export type ReportRescheduleReasonViewProps = ViewProps & {
  interviewers: InterviewerMultiSelectItem[];
  state: ReportRescheduleReasonFormState;
  onCancel: () => void;
  onBack?: () => void;
  /**
   * Confirmation button should get passed from the caller
   * Based on whether it is a standalone view or part of a multi-step view
   */
  confirmationButton: {
    label: string;
    variant: "default" | "primary";
    onClick: () => void;
    isLoading?: boolean;
  };
};

/**
 * View for choosing reason for rescheduling an interview
 * Can be used as a standalone view or as part of a multi-step view
 */
export function ReportRescheduleReasonView({
  interviewers,
  state,
  onCancel,
  onBack,
  confirmationButton,
}: ReportRescheduleReasonViewProps) {
  const { form } = state;

  // Trigger form validation on mount
  useEffect(() => {
    state.form.trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState,
    {
      skipDirtyCheck: true,
    }
  );

  const reason = form.watch("reason");
  const interviewerNoShows = form.watch("interviewerNoShows");
  const interviewerDeclines = form.watch("interviewerDeclines");
  const otherReason = form.watch("otherReason");
  const additionalText = form.watch("additionalText");

  const handleChangeReason = useCallback(
    (newReason: ScheduledInterviewRescheduleReason) =>
      state.onChange("reason", newReason),
    [state]
  );
  const handleChangeInterviewerNoShows = useCallback(
    (newNoShows: InterviewerNoShow) =>
      state.onChange("interviewerNoShows", newNoShows),
    [state]
  );
  const handleChangeInterviewerDeclines = useCallback(
    (newDeclines: InterviewerDecline) =>
      state.onChange("interviewerDeclines", newDeclines),
    [state]
  );
  const handleChangeOtherReason = useCallback(
    (newOtherReason: ScheduledInterviewOtherReason) =>
      state.onChange("otherReason", newOtherReason),
    [state]
  );
  const handleChangeAdditionalText = useCallback(
    (newText: string) => state.onChange("additionalText", newText),
    [state]
  );

  return (
    <View
      content={{
        title: "Report rescheduling reason",
        subTitle:
          "To ensure accurate reporting, understanding why you’re rescheduling is important. Please select the reason for changing the interview time:",
      }}
      header={{
        leftActions: onBack ? (
          <BackButton onClick={onBack} />
        ) : (
          <CloseButton onClick={onCancel} />
        ),
      }}
      footer={{
        rightActions: (
          <FormRightActions
            disabledTooltipContent={disabledTooltipContent}
            cancel={{
              children: onBack ? "Back to review" : "Cancel",
              onClick: onBack ?? onCancel,
            }}
            save={{
              ...confirmationButton,
              children: confirmationButton.label,
            }}
          />
        ),
      }}
    >
      <ReportRescheduleReasonForm
        interviewers={interviewers}
        reason={reason}
        onChangeReason={handleChangeReason}
        interviewerNoShows={interviewerNoShows ?? null}
        onChangeInterviewerNoShows={handleChangeInterviewerNoShows}
        interviewerDeclines={interviewerDeclines ?? null}
        onChangeInterviewerDeclines={handleChangeInterviewerDeclines}
        otherReason={otherReason ?? null}
        onChangeOtherReason={handleChangeOtherReason}
        additionalText={additionalText ?? null}
        onChangeAdditionalText={handleChangeAdditionalText}
      />
    </View>
  );
}
