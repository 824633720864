import { defaultInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/constants";
import {
  mapInterviewPanelRequirementToScheduledInterviewGroupSettings,
  mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings,
} from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/mapping";
import {
  mapInterviewRequirementFragmentToSchedulerInterview,
  mapScheduledInterviewFragmentToSchedulerInterview,
} from "client/scheduler/utils/mapping";
import { SchedulerEditableScheduledInterview } from "client/scheduler/utils/types";
import { filterInterviewRequirements } from "client/utils/interviewRequirements";
import {
  AlgorithmScenarioForHydrationV3Fragment,
  InterviewPanelRequirementForHydrationV3Fragment,
  InterviewRequirementForHydrationV3Fragment,
  ScheduledInterviewForHydrationV3Fragment,
} from "generated/graphql-codegen/graphql";
import { captureSentryError } from "shared/errors/sentryError";

export function getScheduledInterviewGroupIdForSchedulingRequest({
  scheduledInterviews,
  schedulingRequestId,
}: {
  scheduledInterviews: ScheduledInterviewForHydrationV3Fragment[];
  schedulingRequestId: string;
}): string | null {
  const scheduledInterviewWithGroupId = scheduledInterviews.find(
    (si) => !!si.scheduledInterviewGroupId
  );
  const groupId =
    scheduledInterviewWithGroupId?.scheduledInterviewGroupId ?? null;

  const someInterviewHasDifferentGroupId = scheduledInterviews.some(
    (si) =>
      !!si.scheduledInterviewGroupId &&
      !!groupId &&
      si.scheduledInterviewGroupId !== groupId
  );

  if (someInterviewHasDifferentGroupId) {
    captureSentryError(
      new Error(
        "Scheduling request interviews after part of different scheduled interview groups"
      ),
      {
        title: "SchedulingRequestScheduler - ScheduledInterviewGroupIdMismatch",
        metadata: {
          schedulingRequestId,
        },
      }
    );
  }

  return groupId;
}

export function getFilteredInterviewRequirements({
  interviewPanelRequirement,
}: {
  interviewPanelRequirement:
    | InterviewPanelRequirementForHydrationV3Fragment
    | null
    | undefined;
}): InterviewPanelRequirementForHydrationV3Fragment["interviewRequirements"] {
  const filteredInterviewRequirements = filterInterviewRequirements({
    interviewRequirements:
      interviewPanelRequirement?.interviewRequirements ?? [],
  }).interviewRequirements;

  return filteredInterviewRequirements;
}

export function getUnfulfilledInterviewRequirements({
  interviewRequirements,
  scheduledInterviews,
}: {
  interviewRequirements: InterviewRequirementForHydrationV3Fragment[];
  scheduledInterviews: ScheduledInterviewForHydrationV3Fragment[];
}) {
  return interviewRequirements.filter((ir) => {
    return !scheduledInterviews.some(
      (si) => si.createdFromInterviewRequirementId === ir.id
    );
  });
}

export function mapScheduledInterviewsAndRequirementsToSchedulerInterviews({
  scheduledInterviews,
  interviewRequirements,
}: {
  scheduledInterviews: ScheduledInterviewForHydrationV3Fragment[];
  interviewRequirements: InterviewRequirementForHydrationV3Fragment[];
}): SchedulerEditableScheduledInterview[] {
  const mappedScheduledInterviews = scheduledInterviews.map((si) => {
    return mapScheduledInterviewFragmentToSchedulerInterview(si);
  });
  const mappedInterviewRequirements = interviewRequirements.map(
    mapInterviewRequirementFragmentToSchedulerInterview
  );

  return [...mappedScheduledInterviews, ...mappedInterviewRequirements];
}

export function combineAndMapScheduledInterviewsAndRequirementsToSchedulerInterviews({
  scheduledInterviews,
  interviewRequirements,
}: {
  scheduledInterviews: ScheduledInterviewForHydrationV3Fragment[];
  interviewRequirements: InterviewRequirementForHydrationV3Fragment[];
}) {
  const unfulfilledInterviewRequirements = getUnfulfilledInterviewRequirements({
    interviewRequirements,
    scheduledInterviews,
  });

  return mapScheduledInterviewsAndRequirementsToSchedulerInterviews({
    scheduledInterviews,
    interviewRequirements: unfulfilledInterviewRequirements,
  });
}

export function combineExistingScheduledInterviewsWithOptionalAlgorithmResults({
  scheduledInterviews,
  algorithmSuggestedScenario,
}: {
  scheduledInterviews: ScheduledInterviewForHydrationV3Fragment[];
  algorithmSuggestedScenario:
    | AlgorithmScenarioForHydrationV3Fragment
    | null
    | undefined;
}) {
  return algorithmSuggestedScenario
    ? [
        // filter out any interviews that are part of the algorithm results, as algorithm takes precedence
        ...scheduledInterviews.filter((si) => {
          return !algorithmSuggestedScenario.scheduledInterviews.some(
            (asi) => asi.id === si.id
          );
        }),
        ...algorithmSuggestedScenario.scheduledInterviews,
      ]
    : scheduledInterviews;
}

export function getDefaultScheduledInterviewGroupSettings({
  scheduledInterviews,
  interviewPanelRequirement,
}: {
  scheduledInterviews: ScheduledInterviewForHydrationV3Fragment[];
  interviewPanelRequirement:
    | InterviewPanelRequirementForHydrationV3Fragment
    | null
    | undefined;
}) {
  const firstScheduledInterviewWithGroup = scheduledInterviews.find(
    (si) => !!si.scheduledInterviewGroup
  );

  if (
    firstScheduledInterviewWithGroup &&
    firstScheduledInterviewWithGroup.scheduledInterviewGroup
  ) {
    return mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings(
      firstScheduledInterviewWithGroup.scheduledInterviewGroup
    );
  }

  if (interviewPanelRequirement) {
    return mapInterviewPanelRequirementToScheduledInterviewGroupSettings(
      interviewPanelRequirement
    );
  }

  return defaultInterviewGroupSettings;
}
