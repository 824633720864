import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { useInterviewGroupChanges } from "client/scheduler/core/hooks/interviews";
import { useViewFilter } from "client/scheduler/core/hooks/misc";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

/** Wrapper hook around scheduler state data to provide filtered values for review page components  */
export function useSchedulerStateForReview() {
  const viewFilter = useViewFilter();
  const interviewGroupChanges = useInterviewGroupChanges();
  const interviewsForReview = useMemo(
    () => [
      ...interviewGroupChanges.interviewsToCreate,
      ...interviewGroupChanges.interviewsToUpdate,
      ...interviewGroupChanges.interviewsToDelete,
    ],
    [interviewGroupChanges]
  );
  const existingScheduledInterviewIds = useMemo(() => {
    return interviewGroupChanges.interviewsToUpdate.map(
      (i: { id: string }) => i.id
    );
  }, [interviewGroupChanges]);
  const scheduledInterviewInterviewIds = useMemo(() => {
    return [
      ...interviewGroupChanges.interviewsToCreate,
      ...interviewGroupChanges.interviewsToUpdate,
    ]
      .map((i) => i.interview?.id)
      .filter(filterOutNullsAndUndefined);
  }, [interviewGroupChanges]);
  const isCreatingInterviews = useMemo(() => {
    return interviewGroupChanges.interviewsToCreate.length > 0;
  }, [interviewGroupChanges]);
  const isUpdatingInterviews = useMemo(() => {
    return interviewGroupChanges.interviewsToUpdate.length > 0;
  }, [interviewGroupChanges]);
  const isCancellingInterviews = useMemo(() => {
    return interviewGroupChanges.interviewsToDelete.length > 0;
  }, [interviewGroupChanges]);
  const nonCancelledInterviews = interviewsForReview.filter(
    (i) => i.status !== ScheduledStatus.CANCELLED
  );

  return useMemo(
    () => ({
      guideId: viewFilter.guideId,
      interviewsForReview,
      existingScheduledInterviewIds,
      scheduledInterviewInterviewIds,
      isCreatingInterviews,
      isUpdatingInterviews,
      isCancellingInterviews,
      nonCancelledInterviews,
    }),
    [
      viewFilter.guideId,
      interviewsForReview,
      existingScheduledInterviewIds,
      scheduledInterviewInterviewIds,
      isCreatingInterviews,
      isUpdatingInterviews,
      isCancellingInterviews,
      nonCancelledInterviews,
    ]
  );
}
