import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarEvents } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { useLogEvent } from "analytics";
import { useInsertInterviews } from "client/guide-content/rich-blocks/interviews/__lib/use-insert-interviews";
import { LexicalEditor } from "lexical";
import { trackProperties } from "shared/analytics";

export function InsertInterviewsButton({
  editor,
  timezone,
}: {
  editor: LexicalEditor;
  timezone?: string;
}) {
  const insertInterviewsBlock = useInsertInterviews({
    logEvent: () => {
      logEvent?.(
        "Insert Interview Schedule Clicked",
        trackProperties.isCustomerSuccess
      );
    },
    timezone,
    editor,
  });
  const logEvent = useLogEvent({
    component: "InsertInterviewsButton",
  });

  return (
    //  eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      as="button"
      className="text-body-md px-1 h-full shrink"
      onClick={insertInterviewsBlock}
    >
      <p className="flex items-center gap-1">
        <Icon content={atlasCalendarEvents} className="w-4 h-4" />
        <span>Insert interview schedule</span>
      </p>
    </Link>
  );
}
