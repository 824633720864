import { Button } from "@resource/atlas/button/Button";
import {
  Dialog,
  DialogProps,
  DialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { UserMembershipForPreferencesFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useQuery from "utils/useQuery";

import { useEditPreferencesState } from "./__hooks/useEditPreferencesState";
import {
  useUpdatePreferencesOnSubmit,
  UseUpdatePreferencesOnSubmitProps,
} from "./__hooks/useUpdatePreferencesOnSubmit";
import EditPreferencesForm from "./EditPreferencesForm";
import { PreferencesFormData } from "./types";

const GET_USER_MEMBERSHIP = gql(`
  query GetUserMembershipForEditPreferencesDialog($id: String!) {
    userMembershipById(id: $id) {
      id
      ...UserMembershipForPreferences
    }
  }
`);

export type EditPreferencesDialogProps = DialogProps & {
  userMembershipId: string;
  onCompleted?: UseUpdatePreferencesOnSubmitProps["onCompleted"];
};

export function EditPreferencesDialog({
  userMembershipId,
  onCompleted,
  store,
  ...dialogProps
}: EditPreferencesDialogProps) {
  return (
    <Dialog size="medium" {...dialogProps} store={store}>
      <ErrorBoundary
        fallback={({ onRecover }) => <DialogError onRecover={onRecover} />}
      >
        <EditPreferencesDialogQuery
          userMembershipId={userMembershipId}
          store={store}
          onCompleted={onCompleted}
        />
      </ErrorBoundary>
    </Dialog>
  );
}

function EditPreferencesDialogQuery({
  userMembershipId,
  store,
  onCompleted,
}: {
  userMembershipId: string;
  store: DialogStore;
  onCompleted?: UseUpdatePreferencesOnSubmitProps["onCompleted"];
}) {
  const { data } = useQuery(GET_USER_MEMBERSHIP, {
    variables: {
      id: userMembershipId,
    },
    throwOnError: false,
  });

  if (!data) {
    return <DialogLoading />;
  }

  if (!data.userMembershipById) {
    return <DialogError errorCode="404" />;
  }

  return (
    <EditPreferencesDialogDisplay
      userMembership={data.userMembershipById}
      store={store}
      onCompleted={onCompleted}
    />
  );
}

function EditPreferencesDialogDisplay({
  userMembership,
  store,
  onCompleted,
}: {
  userMembership: UserMembershipForPreferencesFragment;
  store: DialogStore;
  onCompleted?: UseUpdatePreferencesOnSubmitProps["onCompleted"];
}) {
  const state = useEditPreferencesState({
    userMembership,
    disableWithoutChanges: true,
  });
  const { form, resetForm } = state;
  const { formState, handleSubmit } = form;
  const [updatePreferencesOnSubmit, { loading: submitting }] =
    useUpdatePreferencesOnSubmit({
      userMembershipId: userMembership.id,
      onCompleted: (...props) => {
        resetForm();
        store.hide();
        onCompleted?.(...props);
      },
    });

  const onSubmit = useCallback(
    async (data: PreferencesFormData) => {
      updatePreferencesOnSubmit({
        ...data,
        maxInterviewLoadPerDay: formState.dirtyFields.maxInterviewLoadPerDay
          ? data.maxInterviewLoadPerDay
          : undefined,
        maxInterviewLoadPerWeek: formState.dirtyFields.maxInterviewLoadPerWeek
          ? data.maxInterviewLoadPerWeek
          : undefined,
        workingHours: formState.dirtyFields.workingHours
          ? data.workingHours
          : undefined,
      });
    },
    [
      formState.dirtyFields.maxInterviewLoadPerDay,
      formState.dirtyFields.maxInterviewLoadPerWeek,
      formState.dirtyFields.workingHours,
      updatePreferencesOnSubmit,
    ]
  );

  return (
    <View
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      header={{
        title: `Edit scheduling preferences for ${userMembership.name}`,
      }}
      footer={{
        leftActions: (
          <Button
            onClick={() => {
              resetForm();
              store.hide();
            }}
          >
            Cancel
          </Button>
        ),
        rightActions: (
          <Button
            type="submit"
            variant="primary"
            disabled={!formState.isValid || !formState.isDirty}
            isLoading={submitting}
          >
            Save changes
          </Button>
        ),
      }}
    >
      <EditPreferencesForm state={state} />
    </View>
  );
}
