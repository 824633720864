import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { ReviewInterviewDetails } from "client/components/scheduling-review/ReviewInterviewDetails";
import { PanelReviewPageFormState } from "client/scheduler/core/components/review/hooks/usePanelReviewPageFormState";
import { SchedulerStagedScheduledInterviewWithComputedFields } from "client/scheduler/utils/types";
import {
  ExistingScheduledInterviewForSchedulerReviewPageFragment,
  GuideForSchedulerReviewPageFragment,
  PostTemplateForUseFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";

import { useScheduledInterviewGroupSettings } from "../../hooks/scheduledInterviewGroupSettings";

type SchedulerReviewInterviewDetailsProps = {
  state: PanelReviewPageFormState;
  interview: SchedulerStagedScheduledInterviewWithComputedFields;
  guide: GuideForSchedulerReviewPageFragment;
  scheduledInterviews: ExistingScheduledInterviewForSchedulerReviewPageFragment[];
  index: number;
};

/** Wrapper around ReviewInterviewDetails that provides props for scheduler panel usage */
export function SchedulerReviewInterviewDetails({
  interview,
  state,
  guide,
  scheduledInterviews,
  index,
}: SchedulerReviewInterviewDetailsProps) {
  const scheduledInterviewGroupSettings = useScheduledInterviewGroupSettings();
  const { watch, setValue } = state.form;
  const existingScheduledInterview = useMemo(() => {
    return scheduledInterviews.find((si) => si.id === interview.id);
  }, [scheduledInterviews, interview.id]);

  const optionalAttendeeUserMemberships = watch(
    "optionalAttendeeUserMemberships"
  );
  const sendNotifications = watch("sendNotifications");

  const interviewerConfirmationTitle = watch(
    `interviews.${index}.interviewerConfirmationTitle`
  );
  const onChangeInterviewerConfirmationTitle = useCallback(
    (title: AtlasContentEditorSerializedState) => {
      setValue(`interviews.${index}.interviewerConfirmationTitle`, title, {
        shouldDirty: true,
      });
    },
    [setValue, index]
  );

  const formInstructions = watch(
    `interviews.${index}.interviewerCustomInstructions`
  );
  const interviewerCustomInstructions = useMemo(() => {
    return formInstructions ?? JSON.parse(LEXICAL_EMPTY_STATE);
  }, [formInstructions]);
  const onChangeInterviewerCustomInstructions = useCallback(
    (customInstructions: AtlasContentEditorSerializedState) => {
      setValue(
        `interviews.${index}.interviewerCustomInstructions`,
        customInstructions,
        {
          shouldDirty: true,
        }
      );
    },
    [setValue, index]
  );

  const defaultTemplate = watch(
    `interviews.${index}.interviewerConfirmationTemplate`
  );
  const onSelectTemplate = useCallback(
    (template: PostTemplateForUseFragment) => {
      setValue(
        `interviews.${index}.interviewerConfirmationTemplate`,
        template,
        {
          shouldDirty: true,
        }
      );
    },
    [index, setValue]
  );

  return (
    <ReviewInterviewDetails
      interview={interview}
      guide={guide}
      existingScheduledInterview={existingScheduledInterview ?? null}
      status={interview.status}
      optionalAttendeeUserMemberships={optionalAttendeeUserMemberships}
      sendNotifications={sendNotifications}
      interviewerConfirmationTitle={interviewerConfirmationTitle}
      onChangeInterviewerConfirmationTitle={
        onChangeInterviewerConfirmationTitle
      }
      interviewerCustomInstructions={interviewerCustomInstructions}
      onChangeInterviewerCustomInstructions={
        onChangeInterviewerCustomInstructions
      }
      defaultTemplate={defaultTemplate ?? null}
      onSelectTemplate={onSelectTemplate}
      scheduledInterviewGroupSettings={
        scheduledInterviewGroupSettings ?? undefined
      }
    />
  );
}
