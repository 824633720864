/* eslint-disable import/prefer-default-export */
import { createModule } from "../../../modules";
import { CommandsTypeaheadPlugin } from "./CommandsTypeaheadPlugin";

export const commandsModule = createModule("commands", {
  declareModule({ insertOptions }) {
    return {
      plugins: [<CommandsTypeaheadPlugin insertOptions={insertOptions} />],
    };
  },
});
