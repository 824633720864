// @deprecated
// Pulled over from v1 scheduler, currently only used for cancel all interviews confirmation

import {
  Dialog,
  DialogProps,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { CancelInterviewsConfirmationDialogProps } from "client/components/scheduled-interviews/__components/CancelInterviewsConfirmation";
import { mapScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/__utils/mapping";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { CancelScheduledInterviewsV2Mutation } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo, useState } from "react";

import { SchedulingCompletionPromptsNavigator } from "./SchedulingCompletionPromptsNavigator";
import { SchedulingCompletionPromptsViewProps } from "./SchedulingCompletionPromptsView";

export function useSchedulingCompletionPromptsProps(_props: {
  schedulingRequestId: string;
}): {
  props: SchedulingCompletionPromptsDialogProps;
  onCancelCompleted: CancelInterviewsConfirmationDialogProps["onCompleted"];
} {
  const store = useDialogStore();
  const [props, setProps] = useState<
    Omit<SchedulingCompletionPromptsDialogProps, "store">
  >({
    guideId: "",
    schedulingRequestId: "",
    interviewsWithSchedulingInfo: [],
    invitesSent: false,
  });

  const onCancelCompleted: CancelInterviewsConfirmationDialogProps["onCompleted"] =
    useCallback(
      (res: CancelScheduledInterviewsV2Mutation) => {
        const scheduledInterviews =
          res.cancelScheduledInterviewsV2?.scheduledInterviews?.map(
            (s) => s.scheduledInterview
          ) ?? [];
        if (!scheduledInterviews || scheduledInterviews.length === 0) {
          return;
        }

        const { guideId, schedulingRequest } = scheduledInterviews[0];
        if (!guideId || !schedulingRequest) {
          return;
        }

        setProps({
          interviewsWithSchedulingInfo: scheduledInterviews.map(
            (interview) => ({
              ...mapScheduledInterviewForCard({
                interview,
              }),
              status: ScheduledStatus.CANCELLED,
            })
          ),
          invitesSent: true,
          guideId,
          schedulingRequestId: schedulingRequest.id,
          thread: schedulingRequest.rootPost,
        });
        store.show();
      },
      [store]
    );

  return useMemo(
    () => ({
      props: {
        ...props,
        store,
      },
      onCancelCompleted,
    }),
    [props, store, onCancelCompleted]
  );
}

type SchedulingCompletionPromptsDialogProps = DialogProps & {
  interviewsWithSchedulingInfo: SchedulingCompletionPromptsViewProps["interviewsWithSchedulingInfo"];
  invitesSent: boolean;
  guideId: string;
  schedulingRequestId: string;
  isReschedule?: boolean;
  thread?: SchedulingCompletionPromptsViewProps["thread"];
  isCancellationConfirmation?: boolean;
};

export function SchedulingCompletionPromptsDialog({
  interviewsWithSchedulingInfo,
  store,
  invitesSent,
  guideId,
  schedulingRequestId,
  isReschedule,
  thread,
  isCancellationConfirmation,
}: SchedulingCompletionPromptsDialogProps) {
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
  });

  return (
    <Dialog {...dialogProps} size="medium" store={store}>
      <ErrorBoundary
        fallback={({ onRecover }) => (
          <DialogError
            className="!min-h-[min(90vh,_50rem)]"
            onRecover={onRecover}
          />
        )}
      >
        <SchedulingCompletionPromptsNavigator
          dismissNavigator={store.hide}
          initialView={{
            view: "schedulingCompletionPrompts",
            props: {
              interviewsWithSchedulingInfo,
              invitesSent,
              guideId,
              schedulingRequestId,
              isReschedule,
              isCancellationConfirmation,
              thread,
            },
          }}
        />
      </ErrorBoundary>
      <WarningDialog />
    </Dialog>
  );
}
