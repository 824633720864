import {
  CalendarGrouping,
  CalendarsGrouping,
} from "client/components/calendar-v2/utils/types";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { getDurationDisplayText } from "client/components/time/utils";
import { useMemo } from "react";

import { useInterviews, useSelectedInterview } from "../../hooks/interviews";
import { getCalendarIdsForInterview } from "../utils/calendar-ids";

export function useCalendarsGrouping() {
  const interviews = useInterviews();
  const selectedInterview = useSelectedInterview();

  const calendarsGrouping: CalendarsGrouping = useMemo(() => {
    return interviews.map((interview): CalendarGrouping => {
      return {
        id: interview.id,
        label: (
          <p className="text-body-xs px-3 flex space-x-2 items-center">
            <InterviewTitle
              className="text-body-xs-heavy truncate"
              interview={interview}
            />{" "}
            <span className="text-subtle">
              ({getDurationDisplayText(interview.duration)})
            </span>
          </p>
        ),
        calendarIds: getCalendarIdsForInterview(interview),
      };
    });
  }, [interviews]);

  return useMemo(
    () => ({
      calendarsGrouping,
      currentViewingGroupingId: selectedInterview?.id,
    }),
    [calendarsGrouping, selectedInterview?.id]
  );
}
