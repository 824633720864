import { NetworkStatus } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { AlgorithmRunForAiScenariosSectionFragment } from "generated/graphql-codegen/graphql";
import { uniqBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useQuery from "utils/useQuery";

const SCHEDULING_ALGORITHM_RUN_SCENARIOS = gql(`
  query SchedulingAlgorithmRunScenarios($id: ID!, $limit: Int!, $skip: Int!) {
    schedulingAlgorithmRunById(id: $id) {
      id
      scenarios(limit: $limit, skip: $skip) {
        id
        ...NewAIScenarioCardPanel
      }
    }
  }
`);

type UseAIScenariosProps = {
  algorithmRun?: AlgorithmRunForAiScenariosSectionFragment | null;
};

const PAGE_SIZE = 10;

export function useAIScenarios({ algorithmRun }: UseAIScenariosProps) {
  const [page, setPage] = useState(1);

  const { data, loading, refetch, fetchMore, networkStatus } = useQuery(
    SCHEDULING_ALGORITHM_RUN_SCENARIOS,
    {
      variables: {
        id: algorithmRun?.id ?? "",
        limit: PAGE_SIZE,
        skip: 0,
      },
      skip: !algorithmRun,
    }
  );

  useEffect(() => {
    refetch();
    setPage(1); // reset page; not ideal but hard to stream results atm as it's too slow
  }, [algorithmRun?.updatedAt, refetch]);

  const fetchNextPage = useCallback(() => {
    if (
      networkStatus !== NetworkStatus.fetchMore &&
      networkStatus !== NetworkStatus.refetch &&
      algorithmRun &&
      (algorithmRun.scenariosCount ?? 0) > page * PAGE_SIZE
    ) {
      fetchMore({
        variables: {
          limit: PAGE_SIZE,
          skip: page * PAGE_SIZE,
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {
          const oldScenarios =
            previousQueryResult.schedulingAlgorithmRunById?.scenarios ?? [];
          const newScenarios =
            fetchMoreResult?.schedulingAlgorithmRunById?.scenarios ?? [];

          return {
            ...previousQueryResult,
            ...(previousQueryResult.schedulingAlgorithmRunById && {
              schedulingAlgorithmRunById: {
                ...previousQueryResult.schedulingAlgorithmRunById,
                scenarios: uniqBy([...oldScenarios, ...newScenarios], "id"),
              },
            }),
          };
        },
      });
      setPage(page + 1);
    }
  }, [algorithmRun, fetchMore, networkStatus, page]);

  const scenarios = data?.schedulingAlgorithmRunById?.scenarios ?? [];

  return {
    scenarios,
    loading: loading || networkStatus === NetworkStatus.fetchMore,
    fetchNextPage,
  };
}
