import { useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { useState } from "react";
import useQuery from "utils/useQuery";

export const NOTIFICATION_COUNT_QUERY = gql(`
  query NotificationCount {
    currentUser {
      id
      currentUserMembership {
        id
        unreadNotificationCount
      }
    }
  }
`);

export const NOTIFICATION_COUNT_SUBSCRIPTION = gql(`
  subscription NotificationCountSubscription {
    currentNotificationCount
  }
`);

interface UseUnreadNotificationCountProps {
  skip?: boolean;
}

export function useUnreadNotificationCount({
  skip = false,
}: UseUnreadNotificationCountProps) {
  const [unreadCount, setUnreadCount] = useState(0);

  useQuery(NOTIFICATION_COUNT_QUERY, {
    onCompleted: (d) => {
      setUnreadCount(
        d?.currentUser?.currentUserMembership?.unreadNotificationCount ?? 0
      );
    },
    skip,
  });

  useSubscription(NOTIFICATION_COUNT_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const count = subscriptionData.data?.currentNotificationCount;
      if (typeof count === "number") {
        setUnreadCount(count);
      }
    },
    skip,
  });

  return unreadCount;
}
