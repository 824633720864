import { strings } from "@resource/common";
import { useCalendarTimezone } from "client/components/calendar-v2/hooks/settings";
import {
  StagedScheduledInterviewGroupSettings,
  StagedScheduledInterviewGroupSettingsSchema,
} from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { getUnschedulableErrorMessage } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/errors";
import { StagedScheduledInterviewSchema } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { SchedulerStagedScheduledInterviewWithComputedFields } from "client/scheduler/utils/types";
import { uniq } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";

import {
  useSchedulerHasChanges,
  useStagedInterviewsWithComputedFields,
} from "../hooks/interviews";
import { useScheduledInterviewGroupSettings } from "../hooks/scheduledInterviewGroupSettings";

type ValidationResult =
  | { isValid: true }
  | { isValid: false; messages: string[] };

export function validateStagedInterview(
  interview: SchedulerStagedScheduledInterviewWithComputedFields
): ValidationResult {
  const unschedulableErrorMessage = getUnschedulableErrorMessage(interview);

  if (unschedulableErrorMessage) {
    return {
      isValid: false,
      messages: [unschedulableErrorMessage],
    };
  }

  if (
    interview.conferencingSlot?.generationSettings?.service ===
      ConferencingType.ZOOM &&
    !interview.conferencingSlot?.isUsingGroupSettings
  ) {
    const selectedHostUserMembershipId =
      interview.conferencingSlot?.generationSettings?.hostUserMembershipId;
    const { possibleUserMembershipIdsForZoomHostSelection } = interview;

    if (
      !selectedHostUserMembershipId ||
      !possibleUserMembershipIdsForZoomHostSelection.includes(
        selectedHostUserMembershipId
      )
    ) {
      return {
        isValid: false,
        messages: [
          "The selected Zoom host is not a valid host for this interview.",
        ],
      };
    }
  }

  const parsing = StagedScheduledInterviewSchema.safeParse(interview);

  if (parsing.success) {
    return { isValid: true };
  }

  return {
    isValid: false,
    messages: uniq(parsing.error.issues.map((issue) => issue.message)),
  };
}

export function validatePanelSettings(
  settings: StagedScheduledInterviewGroupSettings
) {
  const parsing =
    StagedScheduledInterviewGroupSettingsSchema.safeParse(settings);

  if (parsing.success) {
    return { isValid: true };
  }

  return {
    isValid: false,
    messages: uniq(parsing.error.issues.map((issue) => issue.message)),
  };
}

export function useSchedulerDisabledTooltipContent() {
  const hasChanges = useSchedulerHasChanges();
  const stagedInterviews = useStagedInterviewsWithComputedFields();
  const scheduledInterviewGroupSettings = useScheduledInterviewGroupSettings();

  const interviewsValidation = useMemo(
    () =>
      stagedInterviews
        .map((interview) => validateStagedInterview(interview))
        .filter(
          (result): result is { isValid: false; messages: string[] } =>
            !result.isValid
        ),
    [stagedInterviews]
  );

  const groupValidation = useMemo(() => {
    if (
      !scheduledInterviewGroupSettings ||
      !scheduledInterviewGroupSettings.reuseVideoConferencingLink
    ) {
      return { isValid: true };
    }

    const panelSettings = validatePanelSettings(
      scheduledInterviewGroupSettings
    );

    if (!panelSettings.isValid) {
      return {
        isValid: false,
        messages: ["There are errors with the panel settings."],
      };
    }

    const allAvailableZoomHosts = uniq(
      stagedInterviews.flatMap(
        (interview) => interview.possibleUserMembershipIdsForZoomHostSelection
      )
    );
    const selectedZoomHostUserMembershipId =
      scheduledInterviewGroupSettings.conferencingGenerationSettings
        ?.hostUserMembershipId;

    if (
      scheduledInterviewGroupSettings.conferencingGenerationSettings
        ?.service === ConferencingType.ZOOM &&
      (!selectedZoomHostUserMembershipId ||
        !allAvailableZoomHosts.includes(selectedZoomHostUserMembershipId))
    ) {
      return {
        isValid: false,
        messages: [
          "The selected Zoom host is not a valid host for this panel.",
        ],
      };
    }

    return { isValid: true };
  }, [scheduledInterviewGroupSettings, stagedInterviews]);

  if (!hasChanges) {
    return "No changes have been made.";
  }

  if (interviewsValidation.length > 0) {
    return `There are errors with ${strings.pluralize(
      "interview",
      interviewsValidation.length
    )}.`;
  }

  if (!groupValidation.isValid) {
    return (
      groupValidation.messages?.[0] ??
      "There are errors with the panel settings."
    );
  }

  return undefined;
}

export function useIsSchedulingInPast() {
  const stagedInterviews = useStagedInterviewsWithComputedFields();
  const timezone = useCalendarTimezone();

  return useMemo(
    () =>
      stagedInterviews.some((interview) => {
        if (
          interview.status === ScheduledStatus.CREATED ||
          interview.status === ScheduledStatus.UPDATED
        ) {
          const endTime = DateTime.fromISO(interview.endTime, {
            zone: timezone,
          });
          if (endTime < DateTime.now()) {
            return true;
          }
        }
        return false;
      }),
    [stagedInterviews, timezone]
  );
}
