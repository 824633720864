// eslint-disable-next-line import/no-restricted-paths
import useExternalinterviewersAlgorithmVisible from "client/scheduler/core/components/external-interviewers-algorithm/hooks/useExternalInterviewersAlgorithmVisible";
// eslint-disable-next-line import/no-restricted-paths
import SchedulerExternalInterviewerAlgorithmActions from "client/scheduler/core/components/external-interviewers-algorithm/SchedulerExternalInterviewerAlgorithmActions";

import { EditScheduledInterviewGroupConferencingSettings } from "../__components/EditScheduledInterviewGroupConferencingSettings";
import useGroupConferencingSettingsVisible from "../__hooks/useGroupConferencingSettingsVisible";
import { ScheduledInterviewGroupSettingsFormState } from "../__hooks/useScheduledInterviewGroupSettingsFormState";

export type ScheduledInterviewGroupSettingsFormProps = {
  state: ScheduledInterviewGroupSettingsFormState;
};

export function ScheduledInterviewGroupSettingsForm({
  state,
}: ScheduledInterviewGroupSettingsFormProps) {
  const externalInterviewersAlgorithmVisible =
    useExternalinterviewersAlgorithmVisible();
  const groupConferencingSettingsVisible =
    useGroupConferencingSettingsVisible();

  return (
    <div className="space-y-4 w-full p-[2px]">
      {groupConferencingSettingsVisible && (
        <EditScheduledInterviewGroupConferencingSettings state={state} />
      )}
      {externalInterviewersAlgorithmVisible && (
        <SchedulerExternalInterviewerAlgorithmActions />
      )}
    </div>
  );
}
