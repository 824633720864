import { MenuButton, useMenuContext, useStoreState } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import clsx from "clsx";
import React from "react";

interface TagFilterMenuButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
  icon?: AtlasIconData;
  className?: string;
  children?: React.ReactNode;
}

export function TagFilterMenuButton({
  icon,
  className,
  children,
  ...props
}: TagFilterMenuButtonProps) {
  const menu = useMenuContext();
  const state = useStoreState(menu);

  if (!menu) {
    throw new Error("TagFilterMenuButton must be used within a Menu.Root");
  }

  return (
    <MenuButton
      store={menu}
      className={clsx(
        "inline-flex items-center justify-center",
        "active:bg-purple-50 active:text-purple-500",
        "hover:bg-light-gray-600",
        "focus:outline-none",
        state?.open &&
          "bg-purple-50 text-purple-500 hover:bg-purple-50 hover:text-purple-500",
        className
      )}
      {...props}
    >
      {icon && <Icon content={icon} />}
      {children}
    </MenuButton>
  );
}
