import { useMemo } from "react";
import type { A, U } from "ts-toolbelt";

import { BaseCollectionItem } from "./index";

// this is a trick to force the "error messages" to break into the next line
type Space =
  "                                                                                                                                         ";

// we use the opaque IDs as error messages to make errors easier to debug, since
// they'll show up in the TypeScript error message

type ItemsErrorMessage<Name extends string> =
  `IMPORTANT: use \`${Name}\` to create the item collection: ${Name}((i) => <items>, [<deps>])) - see docs for advanced usage.`;
type ItemErrorMessage =
  `IMPORTANT: use the i.<type>() methods to create items - see docs for advanced usage.`;

/** Opaque type used to distinguish between a normal collection and a collection created with
 * a dynamic collection hook. This is used to enforce the usage of the hook at the type-level. */
export type DynamicCollectionItems<Item, HookName extends string> = A.Type<
  Item[],
  `${Space}${ItemsErrorMessage<HookName>}`
>;

/** Opaque type used to distinguish between a normal item and an item created with `i.<type>()`
 * in a dynamic collection hook. This is used to enforce their usage at the type-level. */
export type DynamicCollectionItem<T> = A.Type<T, `${Space}${ItemErrorMessage}`>;

export type ItemCreator<
  Item extends BaseCollectionItem,
  CustomItemCreators = unknown
> = {
  // creates a typed array with optional initial items
  (...items: DynamicCollectionItem<Item>[]): DynamicCollectionItem<Item>[];
} & Omit<
  {
    // item creation methods
    [K in Item["_type"]]: (
      item: Omit<U.Select<Item, { _type: K }>, "_type">
    ) => DynamicCollectionItem<Item>;
  },
  keyof CustomItemCreators
> &
  CustomItemCreators;

function createItem<T, I>(type: T, item: I) {
  return { _type: type, ...item };
}

const itemCreator = new Proxy((...items: unknown[]) => items, {
  get(target, propertyKey, receiver) {
    if (propertyKey === "apply" || typeof propertyKey !== "string")
      return Reflect.get(target, propertyKey, receiver);
    return (item: unknown) => createItem(propertyKey, item);
  },
});

/** Creates a hook that wraps `useMemo` and is strongly typed to enforce returning
 * a specific dynamic collection of items. */
export function createDynamicCollectionItemsHook<
  Item extends BaseCollectionItem,
  ItemsOpaque extends Item[],
  CustomItemCreators = unknown
>() {
  type ItemsFactory = (
    i: ItemCreator<Item, CustomItemCreators>
  ) => (DynamicCollectionItem<Item> | false | null | undefined)[];

  return (fn: ItemsFactory, deps: unknown[]): ItemsOpaque =>
    useMemo(
      () =>
        fn(itemCreator as ItemCreator<Item, CustomItemCreators>).filter(
          Boolean
        ) as unknown as ItemsOpaque,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      deps
    );
}
