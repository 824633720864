import { GlobalNavigatorEditIndividualInterviewRequirement } from "client/app/(scheduling requests)/__components/GlobalNavigatorEditIndividualInterviewRequirement";
import { GlobalNavigatorEditSchedulingTask } from "client/app/(scheduling requests)/__components/GlobalNavigatorEditSchedulingTask";
import { GlobalNavigatorSchedulingTaskDetails } from "client/app/(scheduling requests)/__components/GlobalNavigatorSchedulingTaskDetails";
import { GlobalNavigatorCandidateProfile } from "client/app/extension/guide/[guideId]/__components/GlobalNavigatorCandidateProfile";
import { GlobalNavigatorExtensionCandidateProfile } from "client/app/extension/guide/[guideId]/__components/GlobalNavigatorExtensionCandidateProfile";
import { GlobalNavigatorExtensionNotifications } from "client/app/extension/guide/[guideId]/__components/GlobalNavigatorExtensionNotifications";
import { GlobalNavigatorExtensionSchedulingRequests } from "client/app/extension/guide/[guideId]/__components/GlobalNavigatorExtensionSchedulingRequests";
import { GlobalNavigatorGuideScheduledInterviews } from "client/app/extension/guide/[guideId]/interviews/__components/GlobalNavigatorGuideScheduledInterviews";
import { GlobalNavigatorScheduledInterviewDetails } from "client/app/extension/guide/[guideId]/interviews/__components/GlobalNavigatorScheduledInterviewDetails";
import {
  createNavigator,
  InitialView,
  Navigator,
  NavigatorStateProps,
  useNavigatorContext,
  useNavigatorContextOrThrow,
  useNavigatorState,
  UseNavigatorStateReturn,
} from "client/components/generic/misc/Navigator";
import { GlobalNavigatorEditScheduledInterview } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/wrappers/GlobalNavigatorEditScheduledInterview";
import React, { forwardRef } from "react";

const globalNavigator = createNavigator({
  views: {
    schedulingTaskDetails: {
      component: GlobalNavigatorSchedulingTaskDetails,
    },
    editSchedulingTask: {
      component: GlobalNavigatorEditSchedulingTask,
    },
    editIndividualInterviewRequirement: {
      component: GlobalNavigatorEditIndividualInterviewRequirement,
    },
    candidateProfile: {
      component: GlobalNavigatorCandidateProfile,
    },
    extensionCandidateProfile: {
      component: GlobalNavigatorExtensionCandidateProfile,
    },
    scheduledInterviewDetails: {
      component: GlobalNavigatorScheduledInterviewDetails,
    },
    viewAllGuideScheduledInterviews: {
      component: GlobalNavigatorGuideScheduledInterviews,
    },
    editScheduledInterview: {
      component: GlobalNavigatorEditScheduledInterview,
    },
    allSchedulingTasks: {
      component: GlobalNavigatorExtensionSchedulingRequests,
    },
    notifications: {
      component: GlobalNavigatorExtensionNotifications,
    },
  },
});

export type GlobalNavigatorType = typeof globalNavigator;

export function useGlobalNavigator() {
  return useNavigatorContext<GlobalNavigatorType>();
}

export function useGlobalNavigatorOrThrow() {
  return useNavigatorContextOrThrow<GlobalNavigatorType>();
}
/** Utility hook to hoist navigator state up. */
export function useGlobalNavigatorState<
  K extends keyof GlobalNavigatorType["views"]
>({
  initialView,
  initialHistory,
  dismissNavigator,
}: {
  initialView: InitialView<GlobalNavigatorType, K>;
  initialHistory?: Array<InitialView<GlobalNavigatorType, K>>;
  dismissNavigator: () => void;
}) {
  return useNavigatorState<GlobalNavigatorType>({
    navigator: globalNavigator,
    initialView,
    initialHistory,
    dismissNavigator,
  });
}

export interface GlobalNavigatorRef {
  popToRoot: () => void;
}

export const GlobalNavigator = forwardRef<
  GlobalNavigatorRef,
  | Omit<
      NavigatorStateProps<
        GlobalNavigatorType,
        keyof GlobalNavigatorType["views"]
      >,
      "navigator"
    >
  | {
      navigatorState: UseNavigatorStateReturn<GlobalNavigatorType>;
      isUsingManualProvider?: boolean;
    }
>((props, ref) => {
  if ("navigatorState" in props) {
    const { navigatorState, ...rest } = props;
    return <Navigator ref={ref} {...rest} navigatorState={navigatorState} />;
  }

  return <Navigator ref={ref} {...props} navigator={globalNavigator} />;
});
