import { useCurrentInterval } from "client/components/calendar-v2/hooks/settings";
import { useSyncFetchedDataForSlotCalculations } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import {
  FormDataForSlotCalculations,
  InterviewerToFetchForSlotCalculations,
} from "client/components/interviewer-slots/utils/types";
import {
  interviewerSlotSettingsOpenAtomFamily,
  interviewsAtom,
  selectedInterviewIdAtom,
} from "client/scheduler/core/state";
import { atom, useAtom, useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useFetchedInterviewerCalendarEventsForScheduler } from "../calendar/hooks/interviewer-calendar-events";
import {
  useInterviews,
  useOriginalInterviews,
  useSelectedInterview,
} from "./interviews";
import { useViewFilter } from "./misc";

/**
 * Returns the interviews required for load and conflict calculations, along with a fallback date from the current calendar interval
 * for weekly load calculations when the interview has not yet been placed.
 */
function useSchedulerFormDataForSlotCalculations(): FormDataForSlotCalculations {
  const { guideId } = useViewFilter();
  const selectedInterview = useSelectedInterview();
  const { start: intervalStart } = useCurrentInterval();
  const interviews = useInterviews();
  const originalInterviews = useOriginalInterviews();

  return useMemo(
    (): FormDataForSlotCalculations => ({
      guideId,
      selectedInterview,
      interviews,
      originalInterviews,
      fallbackLoadIntervalDateForWeekLoad: intervalStart.toISO(),
    }),
    [guideId, intervalStart, interviews, originalInterviews, selectedInterview]
  );
}

/**
 * Returns interviewer data for all selected interviewers and shadowers, plus userMemberships and/or shadowingUserMemberships
 * from settings if the interviewer slot settings are currently open.
 * This needs to be an atom so that we can get the atom family value within the flatMap callback. */
const relevantSlotInterviewersAtom = atom((get) => {
  const selectedInterviewId = get(selectedInterviewIdAtom);
  const interviews = get(interviewsAtom);

  return interviews.flatMap((interview) => {
    return interview.interviewerSlots.flatMap(
      (interviewerSlot): InterviewerToFetchForSlotCalculations[] => {
        const { userMembershipsOpen, shadowingUserMembershipsOpen } = get(
          interviewerSlotSettingsOpenAtomFamily(interviewerSlot.id)
        );

        return [
          interviewerSlot.interviewer
            ? {
                userMembershipId:
                  interviewerSlot.interviewer?.userMembership?.id,
                poolId: interviewerSlot.interviewerPoolsSetting[0]?.id,
                isQualified: true,
              }
            : null,
          interviewerSlot.shadowingInterviewer
            ? {
                userMembershipId:
                  interviewerSlot.shadowingInterviewer?.userMembership?.id,
                poolId: interviewerSlot.interviewerPoolsSetting[0]?.id,
                isQualified: false,
              }
            : null,
          ...(interview.id === selectedInterviewId &&
          userMembershipsOpen &&
          interviewerSlot.userMembershipsSetting
            ? interviewerSlot.userMembershipsSetting.map((um) => ({
                userMembershipId: um.id,
                poolId: interviewerSlot.interviewerPoolsSetting[0]?.id,
                isQualified: true,
              }))
            : []),
          ...(interview.id === selectedInterviewId &&
          shadowingUserMembershipsOpen &&
          interviewerSlot.shadowingUserMembershipsSetting
            ? interviewerSlot.shadowingUserMembershipsSetting.map((um) => ({
                userMembershipId: um.id,
                poolId: interviewerSlot.interviewerPoolsSetting[0]?.id,
                isQualified: false,
              }))
            : []),
        ].filter(filterOutNullsAndUndefined);
      }
    );
  });
});

/**
 * Returns the list of interviewers and shadowers with their pool IDs so we can fetch the relevant scheduling data.
 * Also includes alternates if the interviewer slot settings are open.
 */
function useSchedulerInterviewersToFetchForSlotCalculations(): InterviewerToFetchForSlotCalculations[] {
  return useAtomValue(relevantSlotInterviewersAtom);
}

/**
 * Fetch and sync the relevant data for slot calculations in the scheduler.
 */
export function useSyncSchedulerInterviewersForSlotCalculations() {
  const interviewers = useSchedulerInterviewersToFetchForSlotCalculations();
  const formData = useSchedulerFormDataForSlotCalculations();

  const userMembershipIds = useMemo(
    () => interviewers.map((interviewer) => interviewer.userMembershipId),
    [interviewers]
  );
  const calendarEventsResult =
    useFetchedInterviewerCalendarEventsForScheduler(userMembershipIds);

  useSyncFetchedDataForSlotCalculations({
    interviewers,
    formData,
    calendarEvents: calendarEventsResult.loading
      ? null
      : calendarEventsResult.calendarEvents,
  });

  return {
    formDataForSlotCalculations: formData,
  };
}

/**
 * Provides getter and setters for whether a specific interviewer slot userMembership settings field is open.
 */
export function useInterviewerSlotSettingsOpen(
  interviewerSlotId: string,
  isShadower: boolean
) {
  const [settings, setSettings] = useAtom(
    interviewerSlotSettingsOpenAtomFamily(interviewerSlotId)
  );

  const key = isShadower
    ? "shadowingUserMembershipsOpen"
    : "userMembershipsOpen";

  const isOpen = settings[key];

  const setIsOpen = useCallback(
    (newValue: boolean) =>
      setSettings((prevSettings) => ({
        ...prevSettings,
        [key]: newValue,
      })),
    [key, setSettings]
  );

  const toggleOpen = useCallback(
    () =>
      setSettings((prevSettings) => ({
        ...prevSettings,
        [key]: !prevSettings[key],
      })),
    [key, setSettings]
  );
  return useMemo(
    () => ({ isOpen, setIsOpen, toggleOpen }),
    [isOpen, setIsOpen, toggleOpen]
  );
}
