import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import TextField from "@resource/atlas/textfield/TextField";
import { AtlasTextFieldProps } from "@resource/atlas/textfield/types";
import { getCountryCodeForRegion, getPhoneRegions } from "@resource/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";

const LOCAL_STORAGE_KEY = "guide__phone_region";
const DEFAULT_REGION = "US";

const getCountryCodePrefix = (region: string) => {
  return `+${getCountryCodeForRegion(region)} `;
};

const getPhoneWithoutPrefix = ({
  phone,
  region,
}: {
  phone: string;
  region: string;
}) => {
  const trimmedPhone = phone.trim();
  const countryCodePrefix = getCountryCodePrefix(region);
  const phoneWithoutCountryCode = trimmedPhone.startsWith(
    countryCodePrefix.trim()
  )
    ? trimmedPhone.slice(countryCodePrefix.length - 1)
    : trimmedPhone;
  return phoneWithoutCountryCode.replace(/\D/g, "");
};

const getPhoneFormatting = ({
  phone,
  region,
}: {
  phone: string;
  region: string;
}) => {
  const phoneWithoutPrefix = getPhoneWithoutPrefix({ phone, region });

  let formattedPhoneWithoutCountryCode = phoneWithoutPrefix;

  if (region === "US") {
    formattedPhoneWithoutCountryCode = phoneWithoutPrefix.replace(
      /^(\d{3})(\d{1,3})(\d{1,4})$/,
      "($1) $2-$3"
    );
  } else if (region === "GB") {
    formattedPhoneWithoutCountryCode = phoneWithoutPrefix.replace(
      /^(\d{1,4})(\d{1,4})(\d{1,4})$/,
      "$1 $2 $3"
    );
  }

  const countryCodePrefix = getCountryCodePrefix(region);
  const formattedPhoneWithCountryCode = `${countryCodePrefix}${formattedPhoneWithoutCountryCode}`;
  const unformattedPhoneWithCountryCode = `${countryCodePrefix.trim()}${phoneWithoutPrefix.trim()}`;

  return {
    unformattedPhone: unformattedPhoneWithCountryCode,
    formattedPhone: formattedPhoneWithCountryCode,
  };
};

function usePossibleRegions() {
  return useMemo(() => getPhoneRegions(), []);
}

function useSelectedRegion() {
  const regions = usePossibleRegions();

  const [selectedRegion, setSelectedRegion] = useState<string>(() => {
    const storedRegion = localStorage.getItem(LOCAL_STORAGE_KEY);

    return storedRegion &&
      typeof storedRegion === "string" &&
      regions.includes(storedRegion)
      ? storedRegion
      : DEFAULT_REGION;
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, selectedRegion);
  }, [selectedRegion]);

  return [selectedRegion, setSelectedRegion] as const;
}

export const PhoneInput = React.forwardRef<
  HTMLInputElement,
  Omit<AtlasTextFieldProps, "onChange" | "value"> & {
    onChange: (val: string) => void;
    value: string;
    wrapperClassName?: string;
  }
>(
  (
    {
      onChange,
      value,
      label,
      isRequired,
      errorMessage,
      isDisabled,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const [selectedRegion, setSelectedRegion] = useSelectedRegion();
    const prevRegion = usePrevious(selectedRegion);
    const [displayValue, setDisplayValue] = useState(
      getPhoneFormatting({
        phone: value,
        region: selectedRegion,
      }).formattedPhone
    );

    const wrappedOnChange = useCallback(
      (passedValue: string) => {
        const phoneFormatting = getPhoneFormatting({
          phone: passedValue,
          region: selectedRegion,
        });

        onChange(phoneFormatting.unformattedPhone);
        setDisplayValue(phoneFormatting.formattedPhone);
      },
      [onChange, selectedRegion]
    );

    useEffect(() => {
      if (prevRegion !== selectedRegion) {
        // If changing region, we need to strip the existing country code before
        // formatting it with the new country code.
        const strippedValue = prevRegion
          ? getPhoneWithoutPrefix({ phone: value, region: prevRegion })
          : value;

        wrappedOnChange(strippedValue);
      }
    }, [
      onChange,
      prevRegion,
      selectedRegion,
      setSelectedRegion,
      value,
      wrappedOnChange,
    ]);

    return (
      <div className={wrapperClassName}>
        {label && (
          <div className="flex items-center gap-x-[.25rem] pb-[.5rem]">
            <span className="text-body-md-heavy">{label}</span>
            {isRequired && <div className="text-red-500">*</div>}
          </div>
        )}
        <div className="flex w-full">
          <RegionSelect
            value={selectedRegion}
            onChange={setSelectedRegion}
            isDisabled={isDisabled}
          />
          <TextField
            placeholder="Add phone number"
            value={displayValue}
            {...props}
            className="w-full"
            inputWrapperClassName="!rounded-l-none"
            type="tel"
            onChange={wrappedOnChange}
            ref={ref}
            isDisabled={isDisabled}
          />
        </div>
        {errorMessage && (
          <div className="mt-1">
            <p className="text-body-sm-heavy text-red-500">{errorMessage}</p>
          </div>
        )}
      </div>
    );
  }
);

type RegionSelectProps = {
  value: string;
  onChange: (region: string) => void;
  isDisabled?: boolean;
};

const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
const prioritizedRegions = ["US", "GB", "DE", "FR", "AU", "CN"];

function RenderedRegionOption({ region }: { region: string }) {
  return (
    <div className="flex max-w-full overflow-hidden justify-between w-[10rem]">
      <div className="truncate">{regionNames.of(region)}</div>
      <span className="flex-shrink-0">+{getCountryCodeForRegion(region)}</span>
    </div>
  );
}

function RegionSelect({ value, onChange, isDisabled }: RegionSelectProps) {
  const possibleRegions = usePossibleRegions();
  const remainingRegions = useMemo(
    () =>
      possibleRegions.filter((region) => !prioritizedRegions.includes(region)),
    [possibleRegions]
  );

  const selectItems = useSelectItems(
    (i) => [
      ...prioritizedRegions.map((region) =>
        i.option({
          key: region,
          value: region,
          renderContent: () => <RenderedRegionOption region={region} />,
          children: region,
        })
      ),
      i.separator({ key: "separator" }),
      ...remainingRegions.map((region) =>
        i.option({
          key: region,
          value: region,
          renderContent: () => <RenderedRegionOption region={region} />,
          children: region,
        })
      ),
    ],
    [remainingRegions]
  );

  return (
    <Select.Root value={value} setValue={(v) => onChange(v as string)}>
      <Select.Trigger>
        <SelectTrigger
          className="!rounded-r-none pr-1 bg-light-gray-500"
          disabled={isDisabled}
        />
      </Select.Trigger>
      <Select.Content items={selectItems} disabled={isDisabled} />
    </Select.Root>
  );
}
