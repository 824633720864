import { MutationHookOptions, MutationResult } from "@apollo/client";
import { mapSelfSchedulingSettingsToGraphqlInput } from "client/self-schedule/components/__utils/mapping";
import { gql } from "generated/graphql-codegen";
import {
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { SelfScheduleSchedulingSettingsInput } from "shared/self-schedule/types";
import useMutation from "utils/useMutation";

import { PreferencesFormData } from "../types";

const UPDATE_PREFERENCES = gql(`
mutation UpdatePreferences(
    $input: UpdateUserMembershipSchedulingPreferencesInput!
  ) {
    updateUserMembershipSchedulingPreferences(input: $input) {
      success
      message
      userMembership {
        id
        rawInterviewWhitelist
        excludeFromOrganizationInterviewWhitelist
        ...UserMembershipForPreferences
        ...UserMembershipForEditSelfScheduleSettings
      }
    }
  }
`);

export type UseUpdatePreferencesOnSubmitProps = {
  userMembershipId: string;
} & MutationHookOptions<
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables
>;

export function useUpdatePreferencesOnSubmit({
  userMembershipId,
  ...hookOpts
}: UseUpdatePreferencesOnSubmitProps): [
  onSubmit: (
    data: Partial<PreferencesFormData> & {
      defaultSelfSchedulingSettings?: SelfScheduleSchedulingSettingsInput;
    }
  ) => void,
  mutationResult: MutationResult<UpdatePreferencesMutation>
] {
  const [update, mutationResult] = useMutation(UPDATE_PREFERENCES, hookOpts);
  const onSubmit = useCallback(
    (
      data: Partial<PreferencesFormData> & {
        defaultSelfSchedulingSettings?: SelfScheduleSchedulingSettingsInput;
        interviewWhitelist?: string[];
        excludeFromOrganizationInterviewWhitelist?: string[];
      }
    ) => {
      const {
        defaultSelfSchedulingSettings,
        interviewWhitelist,
        excludeFromOrganizationInterviewWhitelist,
      } = data;

      update({
        variables: {
          input: {
            userMembershipId,
            timezone: data.timezone,
            workingHours: data.workingHours || null,
            maxInterviewLoadPerDay: data.maxInterviewLoadPerDay,
            maxInterviewLoadPerWeek: data.maxInterviewLoadPerWeek,
            addAsOptionalParticipantForScheduling:
              data.addAsOptionalParticipantForScheduling,
            receiveNotificationsWhenRecruiter:
              data.receiveNotificationsWhenRecruiter,
            receiveNotificationsWhenCoordinator:
              data.receiveNotificationsWhenCoordinator,
            defaultCalendarId: data.defaultCalendarId,
            ...(data.outOfOfficeConfig !== undefined && {
              autoResponseSetup: data.outOfOfficeConfig
                ? {
                    data: data.outOfOfficeConfig.data,
                    startAt: data.outOfOfficeConfig.startAt.toISOString(),
                    endAt: data.outOfOfficeConfig.endAt.toISOString(),
                  }
                : null,
            }),
            ...(defaultSelfSchedulingSettings && {
              defaultSelfSchedulingSettings:
                mapSelfSchedulingSettingsToGraphqlInput({
                  selfSchedulingSettings: defaultSelfSchedulingSettings,
                }),
            }),
            interviewWhitelist,
            excludeFromOrganizationInterviewWhitelist,
          },
        },
      });
    },
    [update, userMembershipId]
  );

  return [onSubmit, mutationResult];
}
