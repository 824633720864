import { Button as AriaButton } from "@ariakit/react";
import clsx from "clsx";

type ToggleProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  loading?: boolean;
  disabled?: boolean;
  size?: "small" | "normal";
};

export function Toggle({
  value,
  onChange,
  loading,
  disabled,
  size = "normal",
  ...rest
}: ToggleProps) {
  return (
    <AriaButton
      {...rest}
      className={clsx(
        "rounded-full transition-all flex items-center cursor-pointer border-2",
        {
          "w-8 h-5": size === "normal",
          "w-6 h-3.5": size === "small",
        },
        value ? "bg-purple-500 border-purple-500" : "bg-gray-50 border-gray-50",
        { "!cursor-not-allowed": disabled },
        { "opacity-50 ": loading || disabled }
      )}
      onClick={() => loading || disabled || onChange(!value)}
    >
      <div
        className={clsx(
          "transition-all bg-white rounded-full drop-shadow",
          {
            "w-4 h-4": size === "normal",
            "w-3 h-3": size === "small",
          },
          {
            "translate-x-0": !value,
            "translate-x-3": size === "normal" && value,
            "translate-x-2": size === "small" && value,
          }
        )}
      />
    </AriaButton>
  );
}
