import { useFlags } from "client/lib/launchdarkly";
import { useFilteredInterviewRequirements } from "client/utils/interviewRequirements";
import FeatureFlags from "generated/FeatureFlags";
import { useCallback } from "react";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";

import InterviewsPerDayLimitSettings from "./InterviewsPerDayLimitSettings";
import NumberOfDaysSettings from "./NumberOfDaysSettings";
import { useAlgorithmSettingsErrors } from "./utils/hooks";

type SchedulingRequestAlgorithmSettingsProps = {
  algorithmSettings: UserConfigurableAlgorithmSettings;
  onAlgorithmSettingsChange: (
    settings: UserConfigurableAlgorithmSettings
  ) => void;
  interviewRequirements: {
    id: string;
    manuallyRemovedAt: string | null;
  }[];
};

export function SchedulingRequestAlgorithmSettings({
  algorithmSettings,
  onAlgorithmSettingsChange,
  interviewRequirements,
}: SchedulingRequestAlgorithmSettingsProps) {
  const { interviewRequirements: filteredInterviewRequirements } =
    useFilteredInterviewRequirements({ interviewRequirements });

  const interviewCount = filteredInterviewRequirements.length;

  const errors = useAlgorithmSettingsErrors({
    algorithmSettings,
    interviewRequirements,
  });

  const updateSettings = useCallback(
    (updates: Partial<UserConfigurableAlgorithmSettings>) => {
      onAlgorithmSettingsChange({
        ...algorithmSettings,
        ...updates,
      });
    },
    [algorithmSettings, onAlgorithmSettingsChange]
  );

  const onNumberOfDaysChange = useCallback(
    (numberOfDays: UserConfigurableAlgorithmSettings["numberOfDays"]) => {
      updateSettings({ numberOfDays });
    },
    [updateSettings]
  );

  const onInterviewsPerDayLimitChange = useCallback(
    (
      interviewsPerDayLimit: UserConfigurableAlgorithmSettings["interviewsPerDayLimit"]
    ) => {
      updateSettings({ interviewsPerDayLimit });
    },
    [updateSettings]
  );

  const {
    [FeatureFlags.MULTI_DAY_ALGORITHM_SCHEDULING_PREFERENCES]:
      algorithmSettingsAvailable,
  } = useFlags();

  const algorithmSettingsEnabled =
    algorithmSettingsAvailable &&
    // If there's only one interview, the only option is 1 interview on 1 day.
    interviewCount > 1;

  if (!algorithmSettingsEnabled) {
    return null;
  }

  return (
    <div className="space-y-2">
      <div className="text-body-md-heavy">Scheduling preferences</div>

      <div className="space-y-4 text-body-md">
        <NumberOfDaysSettings
          numberOfDays={algorithmSettings.numberOfDays}
          onNumberOfDaysChange={onNumberOfDaysChange}
          interviewCount={interviewCount}
          errors={errors}
        />
        <InterviewsPerDayLimitSettings
          interviewsPerDayLimit={algorithmSettings.interviewsPerDayLimit}
          onInterviewsPerDayLimitChange={onInterviewsPerDayLimitChange}
          interviewCount={interviewCount}
          errors={errors}
        />
      </div>
    </div>
  );
}
