import { ComponentPropsWithoutRef } from "react";
import ReactDOM from "react-dom";

/** Portal element to place elements under the events view parent */
export function CalendarEventsViewElement({
  children,
}: {
  children: ComponentPropsWithoutRef<"div">["children"];
}) {
  const portalElement = document.getElementById("calendar-events-view-portal");

  if (!portalElement) {
    console.warn(`Portal calendar-events-view-portal not found.`);
    return null;
  }

  return ReactDOM.createPortal(children, portalElement);
}
