import { Avatar } from "@resource/atlas/avatar/Avatar";
import { atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { InterviewerForMessageSenderFragment } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { ComponentPropsWithoutRef, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import useQuery from "utils/useQuery";

export type Sender = Omit<InterviewerForMessageSenderFragment, "__typename">;

gql(`
  fragment InterviewerForMessageSender on UserMembership {
    id
    name
    firstName
    lastName
    email
    imageUrl
  }
`);

const INTERVIEWERS_FOR_MESSAGE_SENDER = gql(`
query InterviewersForMessageSender($searchTerm: String!, $limit: Int!) {
    senders: userMemberships(search: $searchTerm, limit: $limit) {
      ...InterviewerForMessageSender
    }
  }
`);

type MessageSenderProps = Omit<
  ComponentPropsWithoutRef<"button">,
  "value" | "onChange"
> & {
  value: Sender;
  onChange: (selected: Sender) => unknown;
};

export function MessageSender({
  value,
  onChange,
  ...props
}: MessageSenderProps) {
  const popoverState = usePopoverState();
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");

  const { data, loading } = useQuery(INTERVIEWERS_FOR_MESSAGE_SENDER, {
    variables: { searchTerm: debouncedTerm, limit: 20 },
  });

  const options = useMemo(() => {
    const senders = data?.senders ?? [];

    return _(senders)
      .sortBy("name")
      .map((sender) => (
        <OptionItem
          key={sender.id}
          isSelectable
          isSelected={sender.id === value.id}
          size="compact"
          onClick={() => {
            onChange(sender);
            popoverState.setOpen(false);
          }}
        >
          <div className="flex gap-2 items-center">
            <Avatar size="xs" image={sender.imageUrl} name={sender.name} />
            <div>
              {sender.name} ({sender.email})
            </div>
          </div>
        </OptionItem>
      ))
      .value();
  }, [value, onChange, data, popoverState]);

  return (
    <Popover.Root sameWidth state={popoverState}>
      <Popover.Trigger>
        <SelectTrigger isGhost className={clsx(props.className, "flex-1")}>
          {value.name} ({value.email})
        </SelectTrigger>
      </Popover.Trigger>
      <Popover.Content className="sameWidth" hasPadding={false}>
        <TextField
          className="m-2"
          size="small"
          value={searchTerm}
          icon={atlasSearch}
          placeholder="Search"
          aria-label="Search"
          onChange={setSearchTerm}
          isClearable
        />
        <div className="p-[.5rem] pt-0">
          <OptionSeparator />
          {loading && (
            <div className="flex justify-center">
              <LoadingIndicator size="small" />
            </div>
          )}
          {!loading && options.length > 0 && options}
          {!loading && options.length === 0 && (
            <div className="px-5 py-2 text-body-md">No results found</div>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
