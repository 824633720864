import { z } from "zod";

import { AutomationErrorSchema } from "../types";
import { AutomationTypes, AutomationTypesSchema } from ".";
import type {
  AutomationParams,
  AutomationRelations,
  AutomationResult,
  AutomationState,
} from "./schemas";

export const AutomationStatusSchema = z.enum([
  "RUNNING",
  "PAUSED",
  "CANCELLED",
  "COMPLETED",
  "COMPLETED_WITHOUT_ACTION",
  "ERROR",
]);

export type AutomationStatus = z.infer<typeof AutomationStatusSchema>;

export const AutomationStatus = AutomationStatusSchema.enum;

export const AutomationCreateInputSchema = z.object({
  parentAutomationId: z.string().uuid().optional(),
  triggeredByActivityId: z.string().uuid().optional(),
  status: AutomationStatusSchema.optional(),
  params: z.record(z.unknown()),
  state: z.record(z.unknown()).optional(),
  result: z.record(z.unknown()).optional(),
  config: z.record(z.unknown()).optional(),
});

export type AutomationCreateInput<T extends AutomationTypes = AutomationTypes> =
  Omit<
    z.infer<typeof AutomationCreateInputSchema>,
    "params" | "state" | "result" | "config"
  > & {
    params: AutomationParams<T>;
    state?: AutomationState<T>;
    result?: AutomationResult<T>;
  };

export type AutomationRelationsInput<
  T extends AutomationTypes = AutomationTypes
> = AutomationRelations<T>;

export const AutomationUpdateInputSchema = z.object({
  id: z.string().uuid(),
  parentAutomationId: z.string().uuid().nullable().optional(),
  triggeredByActivityId: z.string().uuid().nullable().optional(),
  status: AutomationStatusSchema.optional(),
  params: z.record(z.unknown()).optional(),
  state: z.record(z.unknown()).optional(),
  result: z.record(z.unknown()).optional(),
  nextRunAt: z.string().optional(),
  lastRunAt: z.string().optional(),
  error: AutomationErrorSchema.optional(),
});

export type AutomationUpdateInput<T extends AutomationTypes> = Omit<
  z.infer<typeof AutomationUpdateInputSchema>,
  "params" | "state" | "result"
> & {
  params?: AutomationParams<T>;
  state?: AutomationState<T>;
  result?: AutomationResult<T>;
};

export const SetAutomationAsFailedInputSchema = z.object({
  id: z.string().uuid(),
  error: AutomationErrorSchema,
});

export type SetAutomationAsFailedInput = z.infer<
  typeof SetAutomationAsFailedInputSchema
>;

export const AutomationFiltersSchema = z.object({
  id: z.string().uuid().optional(),
  organizationIds: z.array(z.string().uuid()).optional(),
  parentAutomationId: z.string().uuid().optional(),
  triggeredByActivityId: z.string().uuid().optional(),
  guideId: z.string().uuid().optional(),
  schedulingRequestId: z.string().uuid().optional(),
  type: z.array(AutomationTypesSchema).optional(),
  status: z.array(AutomationStatusSchema).optional(),
});

export type AutomationFilters = z.infer<typeof AutomationFiltersSchema>;
