import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPersonSparkle } from "@resource/atlas/icons";

type ColorizedSmartMatchIconProps = {
  variant?: "default" | "large";
};

export function ColorizedSmartMatchIcon({
  variant = "default",
}: ColorizedSmartMatchIconProps) {
  if (variant === "large") {
    return (
      <div className="flex items-center justify-center bg-purple-50 rounded-full h-10 w-10">
        <Icon
          content={atlasPersonSparkle}
          className="text-purple-500 w-6 h-6"
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-5 h-5 rounded-full bg-purple-50">
      <Icon
        className="text-purple-500 w-[.875rem] h-[.875rem]"
        content={atlasPersonSparkle}
      />
    </div>
  );
}
