import { Button } from "@resource/atlas/button/Button";
import type { AtlasIconData } from "@resource/atlas/icon/types";

interface LoginButtonProps {
  connection: string;
  label: string;
  loading: string | null;
  onClick(connection: string): void;
  icon?: AtlasIconData;
}

function LoginButton({
  connection,
  icon,
  label,
  loading,
  onClick,
}: LoginButtonProps) {
  const isLoading = loading === connection;
  return (
    <Button
      className="w-full"
      isLoading={isLoading}
      onClick={() => onClick(connection)}
      icon={icon}
    >
      {label}
    </Button>
  );
}

export default LoginButton;
