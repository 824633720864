import { getUserMembershipIdsFromInterviewSlots } from "client/components/interviewer-slots/utils/misc";
import { SchedulerEditableScheduledInterview } from "client/scheduler/utils/types";

/** Custom calendar IDs to link scheduling interviews to for proper calendar colors */
export enum CustomCalendarIds {
  CURRENT_PANEL = "currentPanel",
  BLOCKED_DATES = "blockedDates",
}

/**
 * For calendar columns (grouped by interview), we need to define a list of calendar IDs for the interview.
 * This should be the interviewer user membership IDs, but if there are none, we should just use the interview ID.
 * The interview ID will server as a placeholder column until an interviewer is assigned.
 */
export function getCalendarIdsForInterview(
  interview: Pick<
    SchedulerEditableScheduledInterview,
    "id" | "interviewerSlots"
  >
) {
  const userMembershipIds = getUserMembershipIdsFromInterviewSlots(
    interview.interviewerSlots
  );
  const calendarIds =
    userMembershipIds.length > 0 ? userMembershipIds : [interview.id];

  return calendarIds;
}
