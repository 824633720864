import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarEvents } from "@resource/atlas/icons";
import { CalendarEventInput } from "client/components/calendar-v2/utils/types";
import { combineEvents } from "client/components/calendar-v2/utils/utils";
import { AvailableSlotFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { SelfScheduleCalendarLayers } from "../utils/layers";

const AVAILABLE_SLOT_CALENDAR_ID = "self-schedule-available-slots";

const AVAILABLE_SLOT_CALENDAR_EVENT_COLOR_CONFIG = {
  color: "primary",
  eventVariant: "primary",
} as const;

/**
 * Hook to transform available time slots into calendar events that can be displayed
 * in the calendar view. Combines adjacent/overlapping slots and adds visual formatting.
 *
 * @param availableSlots - Array of available time slots from the API
 * @returns Array of formatted calendar events ready for display
 */
export function useAvailableSlotCalendarEvents(
  availableSlots: AvailableSlotFragment[]
): CalendarEventInput[] {
  const mappedSlotsForCombining = useMemo(() => {
    return availableSlots.map((slot) => ({
      ...slot,
      title: "Available time",
      startTime: DateTime.fromISO(slot.startTime),
      endTime: DateTime.fromISO(slot.endTime),
    }));
  }, [availableSlots]);

  const combinedSlots = useMemo(() => {
    return combineEvents(mappedSlotsForCombining);
  }, [mappedSlotsForCombining]);

  return useMemo(() => {
    return combinedSlots.map(
      (slot): CalendarEventInput => ({
        id: slot.id,
        calendarId: AVAILABLE_SLOT_CALENDAR_ID,
        start: {
          dateTime: slot.startTime.toISO(),
        },
        end: {
          dateTime: slot.endTime.toISO(),
        },
        title:
          slot.subEvents.length <= 1
            ? "Available time"
            : `Available times (${slot.subEvents.length} slots)`,
        colorConfig: AVAILABLE_SLOT_CALENDAR_EVENT_COLOR_CONFIG,
        additionalInfoForPopover: (
          <div className="flex flex-row space-x-2 text-body-sm">
            <Icon
              content={atlasCalendarEvents}
              className="text-subtle w-4 h-4"
            />
            <div className="text-subtle flex-1 space-y-1 min-w-0 ml-6">
              {slot.subEvents.map((e) => (
                <div key={e.id}>
                  {e.startTime.toFormat("h:mm a")} -{" "}
                  {e.endTime.toFormat("h:mm a")}
                </div>
              ))}
            </div>
          </div>
        ),
        layer: SelfScheduleCalendarLayers.AVAILABILITY_AND_SLOTS,
      })
    );
  }, [combinedSlots]);
}
