import { CandidateDetails } from "client/components/guide/candidate-details/CandidateDetails";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

import { ViewDetails } from "../core/components/navigation/SchedulerLeftSidePanel";
import { Scheduler, SchedulerProps } from "../core/components/Scheduler";
import { combineAndMapScheduledInterviewsAndRequirementsToSchedulerInterviews } from "./utils/helpers";

gql(`
  fragment JustScheduleSchedulerGuide on Guide {
    currentJobInterviewStage {
      id
      schedulingRequestTemplate {
        id
        interviewPanelRequirement {
          ...InterviewPanelRequirementForHydrationV3
        }
      }
    }
    organization {
      ...OrganizationForSchedulerV3
    }
    ...GuideForSchedulerV3
  }
`);

const SCHEDULER_QUERY = gql(`
  query JustScheduleSchedulerHydrationQuery($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      ...JustScheduleSchedulerGuide
    }
  }
`);

export type JustScheduleSchedulerProps = {
  guideId: string;
  defaultSelectedInterviewId?: string;
} & Omit<SchedulerProps, "viewDetails">;

export function JustScheduleScheduler({
  guideId,
  defaultSelectedInterviewId,
  ...rest
}: JustScheduleSchedulerProps) {
  const { guide, interviews, selectedInterviewId } =
    useJustScheduleSchedulerData({
      guideId,
      defaultSelectedInterviewId,
    });

  const viewDetails = useMemo((): ViewDetails => {
    return {
      label: "Candidate details",
      DisplayComponent: guide ? <CandidateDetails guide={guide} /> : null,
    };
  }, [guide]);

  if (!guide) {
    return <DialogLoading isFullscreen />;
  }

  return (
    <Scheduler
      {...rest}
      interviews={interviews}
      originalInterviews={interviews}
      guideId={guide.id}
      scheduledInterviewGroupId={null}
      selectedInterviewId={selectedInterviewId}
      schedulingRequestId={null}
      scheduledInterviewGroupSettings={null}
      viewDetails={viewDetails}
    />
  );
}

function useJustScheduleSchedulerData({
  guideId,
  defaultSelectedInterviewId,
}: {
  guideId: string;
  defaultSelectedInterviewId?: string;
}) {
  const { data } = useQuery(SCHEDULER_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      guideId,
    },
  });

  return useMemo(() => {
    const guide = data?.guideById;
    const interviewRequirements =
      guide?.currentJobInterviewStage?.schedulingRequestTemplate
        ?.interviewPanelRequirement?.interviewRequirements ?? [];

    const interviews =
      combineAndMapScheduledInterviewsAndRequirementsToSchedulerInterviews({
        scheduledInterviews: [],
        interviewRequirements,
      });

    const defaultSelectedInterview = interviews.find(
      (interview) => interview.id === defaultSelectedInterviewId
    );
    const selectedInterviewId =
      defaultSelectedInterview?.id ?? interviews[0]?.id ?? null;

    return {
      guide,
      interviews,
      selectedInterviewId,
    };
  }, [data?.guideById, defaultSelectedInterviewId]);
}
