import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { useAuthContext } from "auth/context";
import { useSchedulingTaskAndAvailabilityWorkflowsNavigation } from "client/app/(scheduling requests)/hooks/useSchedulingTaskAndAvailabilityWorkflowsNavigation";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { EditInterviewInviteAndNotifyDialog } from "client/components/google/components/EditInterviewInviteAndNotifyDialog";
import { useTransferGoogleEventMenuItem } from "client/components/scheduled-interviews/__components/TransferGoogleEvent";
import { SchedulingCompletionPromptsDialog } from "client/components/scheduling-completion/__deprecated/SchedulingCompletionPromptsDialog";
// eslint-disable-next-line import/no-restricted-paths
import { SchedulerDialog as SchedulerDialogV2 } from "client/scheduler/wrappers/SchedulerDialog";
import { SchedulingTaskAndAvailabilityWorkflowsDialog } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import StopPropagation from "components/StopPropagation";
import { gql } from "generated/graphql-codegen";
import { ScheduledInterviewForScheduledInterviewCardFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { ArchiveInterviewConfirmationDialog } from "../__components/ArchiveInterviewConfirmation";
import { CancelInterviewsConfirmationDialog } from "../__components/CancelInterviewsConfirmation";
import { useCancelInterviewsConfirmationDialogProps } from "./useCancelInterviewsConfirmationDialogProps";

gql(`
  fragment ScheduledInterviewForInternalMenuItems on ScheduledInterview {
    id
    guideId
    schedulingRequestId
    scheduledInterviewGroupId
    interviewRequirementId
    isCancelled
    isSelfScheduled
    createdByGuide
    isV2
  }
`);

/**
 * The menu items for managing a Scheduled Interview created by Guide
 * Used for scheduler customer
 */
export function useInternalScheduledInterviewMenuItems(
  scheduledInterview:
    | ScheduledInterviewForScheduledInterviewCardFragment
    | null
    | undefined
) {
  const { user } = useAuthContext();
  const { onClick: onReschedule, dialogProps } =
    useSchedulingTaskAndAvailabilityWorkflowsNavigation({
      params: {
        guideId: scheduledInterview?.guideId,
        defaultSchedulingRequestFormType: "rescheduling",
        defaultSelectedRescheduleIds: scheduledInterview?.interviewRequirementId
          ? [scheduledInterview.interviewRequirementId]
          : [],
        skipRequestingAvailability: true,
      },
      analytics: {
        component: "useInternalScheduledInterviewMenuItems",
      },
    });

  const archiveInterviewConfirmationStore = useDialogStore();

  const {
    menuItem: transferCalendarMenuItem,
    Component: TransferCalendarDialog,
  } = useTransferGoogleEventMenuItem({
    scheduledInterview,
  });

  const {
    onClick: onCancel,
    dialogProps: cancelInterviewsDialogProps,
    completionPromptsProps: cancelCompletionPromptsProps,
  } = useCancelInterviewsConfirmationDialogProps({
    interviews: scheduledInterview ? [scheduledInterview] : [],
  });

  const schedulerV2DialogStore = useDialogStore();

  const editInterviewInviteStore = useDialogStore();

  const NonSelfScheduledDialog = useMemo(() => {
    if (!scheduledInterview) {
      return null;
    }

    return (
      <StopPropagation>
        <SchedulingTaskAndAvailabilityWorkflowsDialog {...dialogProps} />
        <CancelInterviewsConfirmationDialog {...cancelInterviewsDialogProps} />
        {TransferCalendarDialog}
        <ArchiveInterviewConfirmationDialog
          store={archiveInterviewConfirmationStore}
          scheduledInterviewId={scheduledInterview.id}
        />
        <SchedulingCompletionPromptsDialog {...cancelCompletionPromptsProps} />
        <EditInterviewInviteAndNotifyDialog
          scheduledInterviewId={scheduledInterview.id}
          store={editInterviewInviteStore}
        />
        {scheduledInterview.scheduledInterviewGroupId && (
          <SchedulerDialogV2
            store={schedulerV2DialogStore}
            defaultSelectedInterviewId={scheduledInterview.id}
            scheduledInterviewGroupId={
              scheduledInterview.scheduledInterviewGroupId
            }
          />
        )}
      </StopPropagation>
    );
  }, [
    scheduledInterview,
    dialogProps,
    cancelInterviewsDialogProps,
    TransferCalendarDialog,
    archiveInterviewConfirmationStore,
    cancelCompletionPromptsProps,
    editInterviewInviteStore,
    schedulerV2DialogStore,
  ]);

  const SelfScheduleItemDialog = useMemo(() => {
    if (!scheduledInterview || !scheduledInterview.isSelfScheduled) {
      return null;
    }

    return (
      <StopPropagation>
        <CancelInterviewsConfirmationDialog {...cancelInterviewsDialogProps} />
        <ArchiveInterviewConfirmationDialog
          store={archiveInterviewConfirmationStore}
          scheduledInterviewId={scheduledInterview.id}
        />
        <SchedulingCompletionPromptsDialog {...cancelCompletionPromptsProps} />
      </StopPropagation>
    );
  }, [
    scheduledInterview,
    cancelInterviewsDialogProps,
    cancelCompletionPromptsProps,
    archiveInterviewConfirmationStore,
  ]);
  const globalNavigator = useGlobalNavigator();

  /** Return a callback if interview is editable */
  const scheduledInterviewOnEdit = useMemo(() => {
    if (scheduledInterview) {
      if (scheduledInterview.isSelfScheduled && globalNavigator) {
        return () => {
          globalNavigator.navigate("editScheduledInterview", {
            scheduledInterviewId: scheduledInterview.id,
          });
        };
      }

      if (
        scheduledInterview.isV2 &&
        scheduledInterview.scheduledInterviewGroupId
      ) {
        return () => {
          schedulerV2DialogStore.show();
        };
      }
    }

    return null;
  }, [scheduledInterview, globalNavigator, schedulerV2DialogStore]);

  const nonSelfScheduledMenuItems = useMenuItems(
    (i) =>
      scheduledInterview && scheduledInterview.isV2
        ? [
            ...(scheduledInterviewOnEdit
              ? [
                  i.item({
                    id: "edit",
                    children: "Edit interview",
                    onClick: scheduledInterviewOnEdit,
                  }),
                ]
              : []),
            i.item({
              id: "reschedule",
              children: "Request rescheduling via new task",
              disabled:
                !scheduledInterview.interviewRequirementId ||
                !scheduledInterview.schedulingRequestId,
              onClick: () => {
                if (
                  scheduledInterview.interviewRequirementId &&
                  scheduledInterview.schedulingRequestId &&
                  scheduledInterview.guideId
                ) {
                  onReschedule();
                }
              },
              render: (props) => {
                const isDisabled =
                  !scheduledInterview.interviewRequirementId ||
                  !scheduledInterview.schedulingRequestId;

                return (
                  <OptionalTooltip
                    content={
                      isDisabled
                        ? "Only interviews scheduled by a task can be rescheduled via a task."
                        : undefined
                    }
                    isInstant
                  >
                    <OptionItem {...props} isDisabled={isDisabled} />
                  </OptionalTooltip>
                );
              },
            }),
            i.separator({
              key: "separator-1",
            }),
            i.item({
              id: "edit-invite",
              children: "Edit interviewer invite",
              onClick: () => {
                editInterviewInviteStore.show();
              },
            }),
            transferCalendarMenuItem ? i.item(transferCalendarMenuItem) : null,
            ...(!scheduledInterview.isCancelled
              ? [
                  i.separator({
                    key: "separator-2",
                  }),
                  i.item({
                    id: "delete",
                    children: "Cancel interview",
                    isDestructive: true,
                    disabled: !scheduledInterview.createdByGuide,
                    onClick: onCancel,
                  }),
                ]
              : []),
            ...(user?.isStaff || user?.isSuperuser
              ? [
                  i.separator({
                    key: "separator-3",
                  }),
                  i.item({
                    id: "archive",
                    children: "Archive interview (INTERNAL)",
                    isDestructive: true,
                    onClick: () => {
                      archiveInterviewConfirmationStore.show();
                    },
                  }),
                ]
              : []),
          ]
        : [],
    [
      archiveInterviewConfirmationStore,
      editInterviewInviteStore,
      onCancel,
      onReschedule,
      scheduledInterview,
      scheduledInterviewOnEdit,
      transferCalendarMenuItem,
      user?.isStaff,
      user?.isSuperuser,
    ]
  );

  const selfScheduleMenuItems = useMenuItems(
    (i) =>
      scheduledInterview
        ? [
            ...(globalNavigator
              ? [
                  i.item({
                    id: "edit",
                    children: "Edit interview",
                    onClick: () => {
                      if (globalNavigator) {
                        globalNavigator.navigate("editScheduledInterview", {
                          scheduledInterviewId: scheduledInterview.id,
                        });
                      }
                    },
                  }),
                ]
              : []),
            ...(!scheduledInterview.isCancelled
              ? [
                  i.item({
                    id: "cancel",
                    isDestructive: true,
                    children: "Cancel interview",
                    onClick: onCancel,
                  }),
                ]
              : []),
            ...(user?.isStaff || user?.isSuperuser
              ? [
                  i.separator({
                    key: "separator",
                  }),
                  i.item({
                    id: "archive",
                    children: "Archive interview (INTERNAL)",
                    isDestructive: true,
                    onClick: () => {
                      archiveInterviewConfirmationStore.show();
                    },
                  }),
                ]
              : []),
          ]
        : [],
    [
      archiveInterviewConfirmationStore,
      globalNavigator,
      onCancel,
      scheduledInterview,
      user?.isStaff,
      user?.isSuperuser,
    ]
  );

  const ItemsDialog = useMemo(() => {
    if (!scheduledInterview || !scheduledInterview.guideId) {
      return null;
    }

    if (
      scheduledInterview.isV2 &&
      scheduledInterview.createdByGuide &&
      !scheduledInterview.isSelfScheduled
    ) {
      return NonSelfScheduledDialog;
    }

    if (scheduledInterview.isSelfScheduled) {
      return SelfScheduleItemDialog;
    }

    return null;
  }, [NonSelfScheduledDialog, SelfScheduleItemDialog, scheduledInterview]);

  const menuItems = useMemo(() => {
    if (!scheduledInterview || !scheduledInterview.guideId) {
      return [];
    }

    if (
      scheduledInterview.isV2 &&
      scheduledInterview.createdByGuide &&
      !scheduledInterview.isSelfScheduled
    ) {
      return nonSelfScheduledMenuItems;
    }

    if (scheduledInterview.isSelfScheduled) {
      return selfScheduleMenuItems;
    }

    return [];
  }, [scheduledInterview, nonSelfScheduledMenuItems, selfScheduleMenuItems]);

  return {
    Dialog: ItemsDialog,
    menuItems,
  };
}
