/* eslint-disable import/prefer-default-export */
import { useMemo } from "react";

import { Icon } from "../../../../icon/Icon";
import { useMenuItems } from "../../../../menu/use-menu-items";
import type { InsertOption, InsertOptionContext } from "./types";
import { useInsertOptions } from "./use-insert-options";

export function useParagraphStyleItems(
  insertOptions: InsertOption[],
  context: Omit<InsertOptionContext, "target" | "getAnchorRect">
) {
  const paragraphStyleOptions = useInsertOptions(
    insertOptions,
    useMemo(() => ({ ...context, target: "paragraph-style" }), [context]),
    { target: "paragraphStyle" }
  );

  return useMenuItems(
    (i) =>
      paragraphStyleOptions
        .sort(
          (a, b) =>
            (a.targets.paragraphStyle?.position ?? 0) -
            (b.targets.paragraphStyle?.position ?? 0)
        )
        .map(({ key, label, icon, onTrigger, active }) =>
          i.item({
            key,
            children: label,
            size: "compact",
            isSelectable: true,
            isSelected: active,
            leadingContent: <Icon content={icon} />,
            onClick: onTrigger,
          })
        ),
    [paragraphStyleOptions]
  );
}
