import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasReply } from "@resource/atlas/icons";
import { GuideUpdateRenderer } from "client/components/guide-content";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { ThreadForDetailFragment } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { ComponentPropsWithoutRef, useState } from "react";

const THREAD_FOR_DETAIL_FRAGMENT = gql(`
fragment ThreadForDetail on GuidePost {
    id
    title
    data
    replies {
      id
      title
      data
      createdAt
    }
    recipientProfiles {
      id
      firstName
      lastName
      name
      email
      imageUrl
      isCandidate
    }
    removedRecipients
  }
`);

type ThreadDetailProps = ComponentPropsWithoutRef<"div"> & {
  thread: ThreadForDetailFragment;
};

export default function ThreadDetail({ thread, ...props }: ThreadDetailProps) {
  const [active, setActive] = useState(false);
  const mostRecentReply = _(thread.replies).sortBy("createdAt").last();
  const postToDisplay = mostRecentReply ?? thread;

  return (
    <div
      {...props}
      className={clsx("bg-light-gray-200 p-4 rounded-md", props.className)}
    >
      <div className="flex items-center gap-3 justify-between">
        <div className="flex items-center gap-3">
          <Icon content={atlasReply} className="h-4 w-4 text-subtle" />
          <div className="text-body-md">
            <span>Replying to </span>
            <span className="text-body-md-heavy">{thread.title}</span>
          </div>
        </div>
        {postToDisplay.data && (
          <Button
            isGhost
            size="xs"
            negativeMargin
            variant="default"
            onClick={() => setActive((prev) => !prev)}
          >
            {active ? "Hide" : "Show"}
          </Button>
        )}
      </div>
      {active && postToDisplay.data && (
        <GuideUpdateRenderer
          className="pointer-events-none mt-3"
          content={postToDisplay.data}
        />
      )}
    </div>
  );
}

ThreadDetail.fragments = {
  thread: THREAD_FOR_DETAIL_FRAGMENT,
};
