/* eslint-disable import/prefer-default-export, no-param-reassign */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { Portal } from "ariakit";
import {
  $createParagraphNode,
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isElementNode,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  LexicalEditor,
  LexicalNode,
} from "lexical";
import {
  DragEvent as ReactDragEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  atlasArrowDown,
  atlasArrowUp,
  atlasCopy,
  atlasEllipsisVertical,
  atlasRingPlus,
  atlasTrash,
} from "../../../../icons/atlas";
import { useEvent } from "../../../__utils/react";
import { Button } from "../../../button/Button";
import { Icon } from "../../../icon/Icon";
import { Menu } from "../../../menu";
import { useMenuItems } from "../../../menu/use-menu-items";
import { useMenuState } from "../../../menu/use-menu-state";
import { $copyNodeRecursively, $isEmptyElementNode } from "../../__utils/misc";
import { usePortalElement } from "../../__utils/portal-element-context";

// -------
// "CLEAN"
// -------

const DROP_INDICATOR_HEIGHT = 1;
const LINE_X_OFFSET = 24;
const HANDLE_Y_OFFSET = -2;
const HANDLE_X_OFFSET = -24;

function isElement(x: unknown): x is HTMLElement {
  return x instanceof Element;
}

function getLineElementAtCoordinates(
  editor: LexicalEditor,
  coordinates: { x: number; y: number }
) {
  return editor.getEditorState().read(() => {
    const lineKeys = $getRoot().getChildrenKeys();

    for (let i = 0; i < lineKeys.length; i += 1) {
      const lineKey = lineKeys[i];
      const lineEl = editor.getElementByKey(lineKey);
      if (!lineEl) throw new Error(`Element not found for key: ${lineKey}`);

      const lineStyle = window.getComputedStyle(lineEl);
      const lineRect = lineEl.getBoundingClientRect();

      const TOP_OFFSET = parseFloat(lineStyle.marginTop);

      const nextEl = lineEl.nextElementSibling;
      const BOTTOM_OFFSET = nextEl
        ? // account for margin collapse
          Math.max(
            parseFloat(lineStyle.marginBottom),
            parseFloat(window.getComputedStyle(nextEl).marginTop)
          ) / 2
        : parseFloat(lineStyle.marginTop);

      const isInsideLine =
        coordinates.x > lineRect.left - LINE_X_OFFSET &&
        coordinates.x < lineRect.right + LINE_X_OFFSET &&
        coordinates.y > lineRect.top - TOP_OFFSET &&
        coordinates.y < lineRect.bottom + BOTTOM_OFFSET;

      if (isInsideLine) return lineEl;
    }

    return null;
  });
}

function updateHandle(handleEl: HTMLElement, lineEl: HTMLElement | null) {
  if (!lineEl) {
    handleEl.style.opacity = "0";
    handleEl.style.top = "-1000px";
    handleEl.style.left = "-1000px";
    return;
  }

  const top = lineEl.offsetTop + HANDLE_Y_OFFSET;

  handleEl.style.opacity = "1";
  handleEl.style.top = `${top}px`;
  handleEl.style.left = `${HANDLE_X_OFFSET}px`;
}

function updateDropIndicator(
  dropIndicatorEl: HTMLElement,
  lineEl: HTMLElement,
  mouseY: number
) {
  const targetStyle = window.getComputedStyle(lineEl);
  const { top, height: lineHeight } = lineEl.getBoundingClientRect();
  const lineOffset = top - lineEl.offsetTop;

  let lineTop = top;

  if (mouseY - top <= lineHeight / 2) {
    // drop target is before the line
    const prevEl = lineEl.previousElementSibling;
    if (!prevEl) {
      lineTop -= -parseFloat(targetStyle.marginTop);
    } else {
      // account for margin collapse
      const prevStyle = window.getComputedStyle(prevEl);
      const totalMargin = Math.max(
        parseFloat(prevStyle.marginBottom),
        parseFloat(targetStyle.marginTop)
      );
      lineTop -= totalMargin / 2;
    }
  } else {
    // drop target is after the line
    const nextEl = lineEl.nextElementSibling;
    if (!nextEl) {
      lineTop += lineHeight + parseFloat(targetStyle.marginBottom);
    } else {
      // account for margin collapse
      const nextStyle = window.getComputedStyle(nextEl);
      const totalMargin = Math.max(
        parseFloat(targetStyle.marginBottom),
        parseFloat(nextStyle.marginTop)
      );
      lineTop += lineHeight + totalMargin / 2;
    }
  }

  dropIndicatorEl.style.top = `${
    lineTop - lineOffset - DROP_INDICATOR_HEIGHT / 2
  }px`;
  dropIndicatorEl.style.opacity = "1";
}

function hideDropIndicator(dropIndicatorEl: HTMLElement | null) {
  if (dropIndicatorEl) dropIndicatorEl.style.opacity = "0";
}

// -------
// "DIRTY"
// -------

function setDragImage(
  dataTransfer: DataTransfer,
  draggableBlockElem: HTMLElement
) {
  const { transform } = draggableBlockElem.style;

  // Remove dragImage borders
  draggableBlockElem.style.transform = "translateZ(0)";
  dataTransfer.setDragImage(draggableBlockElem, 0, 0);

  setTimeout(() => {
    draggableBlockElem.style.transform = transform;
  });
}

const DRAG_DATA_FORMAT = "application/x-lexical-drag-block";

type LineActionsMenuInput = { targetLineNode: LexicalNode | null };

function useLineActionsMenuItems({ targetLineNode }: LineActionsMenuInput) {
  const [editor] = useLexicalComposerContext();

  const [moveUpAllowed, setMoveUpAllowed] = useState(false);
  const moveUp = useEvent(() => {
    if (!targetLineNode) return;
    editor.update(() => {
      targetLineNode.getPreviousSibling()?.insertBefore(targetLineNode);
      if ($isElementNode(targetLineNode)) targetLineNode.selectEnd();
    });
  });

  const [moveDownAllowed, setMoveDownAllowed] = useState(false);
  const moveDown = useEvent(() => {
    if (!targetLineNode) return;
    editor.update(() => {
      targetLineNode.getNextSibling()?.insertAfter(targetLineNode);
      if ($isElementNode(targetLineNode)) targetLineNode.selectEnd();
    });
  });

  useEffect(() => {
    editor.getEditorState().read(() => {
      setMoveUpAllowed(Boolean(targetLineNode?.getPreviousSibling()));
      setMoveDownAllowed(Boolean(targetLineNode?.getNextSibling()));
    });
  }, [editor, targetLineNode]);

  const insert = useEvent(() => {
    if (!targetLineNode) return;
    editor.update(() => {
      const paragraph = $createParagraphNode();

      if ($isEmptyElementNode(targetLineNode))
        targetLineNode.replace(paragraph);
      else targetLineNode.insertAfter(paragraph);

      paragraph.selectStart();
      $getSelection()?.insertText("/");
    });
  });

  const duplicate = useEvent(() => {
    if (!targetLineNode) return;
    editor.update(() => {
      const copiedNode = $copyNodeRecursively(targetLineNode.getLatest());
      targetLineNode.insertAfter(copiedNode);
      if ($isElementNode(copiedNode)) copiedNode.selectEnd();
    });
  });

  const remove = useEvent(() => {
    if (!targetLineNode) return;
    editor.update(() => {
      const nextSibling = targetLineNode.getNextSibling();
      if ($isElementNode(nextSibling)) nextSibling.selectEnd();
      targetLineNode.remove();
    });
  });

  return useMenuItems(
    (i) => {
      const items = i();

      if (moveUpAllowed)
        items.push(
          i.item({
            size: "compact",
            key: "move-line-up",
            children: "Move line up",
            leadingContent: <Icon content={atlasArrowUp} />,
            onClick: moveUp,
          })
        );
      if (moveDownAllowed)
        items.push(
          i.item({
            size: "compact",
            key: "move-line-down",
            children: "Move line down",
            leadingContent: <Icon content={atlasArrowDown} />,
            onClick: moveDown,
          })
        );

      items.push(
        i.item({
          size: "compact",
          key: "insert",
          children: "Insert",
          leadingContent: <Icon content={atlasRingPlus} />,
          onClick: insert,
        })
      );

      items.push(
        i.item({
          size: "compact",
          key: "duplicate",
          children: "Duplicate",
          leadingContent: <Icon content={atlasCopy} />,
          onClick: duplicate,
        })
      );
      items.push(
        i.item({
          size: "compact",
          key: "delete",
          children: "Delete",
          leadingContent: <Icon content={atlasTrash} />,
          onClick: remove,
        })
      );

      return items;
    },
    [
      duplicate,
      insert,
      moveDown,
      moveDownAllowed,
      moveUp,
      moveUpAllowed,
      remove,
    ]
  );
}

export function LineActionsPlugin() {
  // -------
  // "CLEAN"
  // -------

  const [editor] = useLexicalComposerContext();

  const menu = useMenuState({ placement: "bottom-end" });

  const [handleElement, setHandleElement] = useState<HTMLDivElement | null>(
    null
  );
  const [dropIndicatorElement, setDropIndicatorElement] =
    useState<HTMLDivElement | null>(null);
  const [targetLineElement, setTargetLineElement] =
    useState<HTMLElement | null>(null);
  const [targetLineNode, setTargetLineNode] = useState<LexicalNode | null>(
    null
  );

  const setTargetLine = useCallback(
    (element: HTMLElement | null) => {
      if (menu.mounted) return;

      setTargetLineElement(element);

      let node: LexicalNode | null = null;
      if (element)
        editor.update(() => {
          node = $getNearestNodeFromDOMNode(element);
        });
      setTargetLineNode(node);
    },
    [editor, menu.mounted]
  );

  // track target line element on global mousemove
  useEffect(() => {
    function onMouseMove(event: MouseEvent) {
      const { target } = event;

      if (!isElement(target)) {
        setTargetLine(null);
        return;
      }

      if (handleElement?.contains(target)) return;

      const lineEl = getLineElementAtCoordinates(editor, event);
      setTargetLine(lineEl);
    }

    window.addEventListener("mousemove", onMouseMove);
    return () => window.removeEventListener("mousemove", onMouseMove);
  }, [editor, handleElement, setTargetLine]);

  // update the handle whenever the line element changes
  useEffect(() => {
    if (!menu.mounted && handleElement)
      updateHandle(handleElement, targetLineElement);
  }, [handleElement, menu.mounted, targetLineElement]);

  // -------
  // "DIRTY"
  // -------

  // TODO: maybe use DOM dragover instead for better support?
  // update the drop indicator on dragover (lexical command)
  const onDragover = useEvent((event: DragEvent) => {
    const { pageY, target } = event;
    if (!isElement(target)) return false;

    const lineEl = getLineElementAtCoordinates(editor, event);
    if (!lineEl || !dropIndicatorElement) return false;

    updateDropIndicator(dropIndicatorElement, lineEl, pageY);
    // TODO: what does this mean?
    // prevent default event to be able to trigger onDrop events
    event.preventDefault();
    return true;
  });

  // TODO: maybe use DOM drop instead for better support?
  // execute the drop on drop (lexical command)
  const onDrop = useEvent((event: DragEvent) => {
    const { target, dataTransfer, pageY } = event;
    const dragData = dataTransfer?.getData(DRAG_DATA_FORMAT) || "";
    const draggedNode = $getNodeByKey(dragData);
    if (!draggedNode) {
      return false;
    }
    if (!isElement(target)) {
      return false;
    }
    const lineEl = getLineElementAtCoordinates(editor, event);
    if (!lineEl) {
      return false;
    }
    const targetNode = $getNearestNodeFromDOMNode(lineEl);
    if (!targetNode) {
      return false;
    }
    if (targetNode === draggedNode) {
      return true;
    }
    const { top, height } = lineEl.getBoundingClientRect();
    const shouldInsertAfter = pageY - top > height / 2;
    if (shouldInsertAfter) {
      targetNode.insertAfter(draggedNode);
    } else {
      targetNode.insertBefore(draggedNode);
    }
    setTargetLine(null);

    return true;
  });

  // register dragover and drop
  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          DRAGOVER_COMMAND,
          onDragover,
          COMMAND_PRIORITY_LOW
        ),
        editor.registerCommand(DROP_COMMAND, onDrop, COMMAND_PRIORITY_HIGH)
      ),
    [editor, onDragover, onDrop]
  );

  const onDragStart = useEvent((event: ReactDragEvent<HTMLButtonElement>) => {
    const { dataTransfer } = event;
    if (!dataTransfer || !targetLineElement) return;

    setDragImage(dataTransfer, targetLineElement);
    let nodeKey = "";
    editor.update(() => {
      const node = $getNearestNodeFromDOMNode(targetLineElement);
      if (node) nodeKey = node.getKey();
    });
    dataTransfer.setData(DRAG_DATA_FORMAT, nodeKey);
  });

  const onDragEnd = useEvent(() => hideDropIndicator(dropIndicatorElement));

  const lineActionsItems = useLineActionsMenuItems({ targetLineNode });

  return (
    <Portal portalElement={usePortalElement().inputContainer}>
      <div className="absolute opacity-0" ref={setHandleElement}>
        <Menu.Root state={menu}>
          <Menu.Trigger>
            <Button
              className="cursor-grab active:cursor-grabbing"
              size="xs"
              variant="subtle"
              isGhost
              icon={atlasEllipsisVertical}
              draggable
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
            />
          </Menu.Trigger>
          <Menu.Content
            portal
            portalElement={document.body}
            items={lineActionsItems}
          />
        </Menu.Root>
      </div>
      <div
        className="absolute h-[.125rem] w-full left-[0] pointer-events-none bg-blue-500 opacity-0 "
        ref={setDropIndicatorElement}
      />
    </Portal>
  );
}

// TODO
// > drag & drop
// - allow dragover through anywhere
// - allow drop from anywhere
// - correct cursor all the way (dragging)
// - ^ maybe only if drop indicator is visible
// - drag preview element
// - reduced opacity effect on original block
// - bug: random scroll on drop
// - bug: weird drop indicator for horizontal rule
// > menu
// - menu with "delete line"
