import { DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useEffect } from "react";
import { usePrevious } from "react-use";

import { useForceResyncJob } from "./useForceResyncJob";

type Props = {
  store: DialogStore;
  refetch: () => void;
  jobId: string;
};

export function useForceResyncJobOnDialogOpen({
  store,
  refetch,
  jobId,
}: Props) {
  const [forceResyncJob] = useForceResyncJob({
    onCompleted: () => {
      setTimeout(() => {
        refetch();
      }, 3000);
    },
  });

  const dialogState = store.useState();
  const prevOpen = usePrevious(dialogState.open);

  useEffect(() => {
    if (dialogState.open && !prevOpen) {
      forceResyncJob({
        variables: {
          input: {
            jobId,
          },
        },
      });
    }
  }, [dialogState.open, forceResyncJob, jobId, prevOpen, refetch]);
}
