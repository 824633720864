import { Link } from "@resource/atlas/link/Link";
import clsx from "clsx";

export type RequirementTextProps = {
  text: string;
  isError?: boolean;
  action?: {
    text: string;
    onClick: () => void;
  };
};

/** Display requirement settings in the upsert scheduled interview form */
export function RequirementText({
  text,
  isError,
  action,
}: RequirementTextProps) {
  return (
    <div className="flex w-full justify-between items-center text-body-sm">
      <span className={clsx(isError ? "text-red-500" : "text-subtle")}>
        {text}
      </span>
      {action && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link onClick={action.onClick}>{action.text}</Link>
      )}
    </div>
  );
}
