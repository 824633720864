import { UpdateScheduledInterviewOnCompleted } from "client/components/confirmations/__hooks__/useEditScheduledInterviewConfirmationOnSave";
import { BackButton } from "client/components/generic/layout/BackButton";
import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { Provider } from "jotai";
import { useCallback } from "react";

import { EditScheduledInterview } from "./EditScheduledInterview";

/**
 *  A wrapper around the EditScheduledInterviewView component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorEditScheduledInterview({
  scheduledInterviewId,
}: {
  scheduledInterviewId: string;
}) {
  const navigator = useGlobalNavigatorOrThrow();
  const onCompleted: UpdateScheduledInterviewOnCompleted = useCallback(() => {
    navigator.back();
  }, [navigator]);

  return (
    <Provider>
      <EditScheduledInterview
        scheduledInterviewId={scheduledInterviewId}
        header={{
          leftActions: <BackButton onClick={navigator.back} />,
        }}
        onCancel={navigator.back}
        onCompleted={onCompleted}
      />
    </Provider>
  );
}
