import { useLogEvent } from "analytics";
import {
  JobInterviewSelect,
  SelectedJobInterview,
} from "client/components/interviews/JobInterviewSelect";
import { mapInterviewRequirementFragmentToSchedulerInterview } from "client/scheduler/utils/mapping";
import { gql } from "generated/graphql-codegen";
import { SchedulerJobInterviewSelectQuery } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { v4 } from "uuid";

import { useSchedulerInterviewsDispatch } from "../../dispatch";
import { useSchedulerGuide } from "../../hooks/useSchedulerGuide";

gql(`
  fragment InterviewForSchedulerJobInterviewSelect on Interview {
    id
    ...JobInterviewSelectBase
    interviewRequirement {
      id
      ...InterviewRequirementForSchedulerV3
    }
  }
`);

const INTERVIEWS_FOR_JOB = gql(`
  query SchedulerJobInterviewSelect($jobId: String!) {
    fetchInterviewsForJob(jobId: $jobId, includeBlacklisted: true) {
      ...InterviewForSchedulerJobInterviewSelect
    }
  }
`);

gql(`
  fragment GuideForSchedulerJobInterviewSelect on Guide {
    id
    jobId
  }  
`);

export type SelectedJobInterviewForScheduling =
  SelectedJobInterview<SchedulerJobInterviewSelectQuery>;

export function SchedulerJobInterviewSelect() {
  const logEvent = useLogEvent({
    component: "SchedulerJobInterviewSelect",
  });
  const guide = useSchedulerGuide();
  const dispatch = useSchedulerInterviewsDispatch();
  const onSelect = useCallback(
    (interview: SelectedJobInterviewForScheduling) => {
      logEvent("Scheduler V2 Interview Added");
      dispatch({
        type: "add",
        interview: {
          ...mapInterviewRequirementFragmentToSchedulerInterview(
            interview.interviewRequirement
          ),
          id: v4(),
        },
      });
    },
    [dispatch, logEvent]
  );

  return (
    <JobInterviewSelect
      onSelect={onSelect}
      query={INTERVIEWS_FOR_JOB}
      jobId={guide.jobId}
      enforceSchedulable
    />
  );
}
