import {
  ConferenceRoomSettingsForMappingFragment,
  ConferenceRoomSlotForMappingFragment,
  ConferenceRoomSlotInput,
  ScheduledInterviewForConferenceRoomSlotMappingFragment,
} from "generated/graphql-codegen/graphql";

import {
  CurrentInterviewForAvailability,
  getConferenceRoomAvailability,
  OriginalInterviewForAvailability,
} from "./helpers";
import {
  ConferenceRoom,
  ConferenceRoomForForm,
  ConferenceRoomSettings,
  ConferenceRoomSlot,
  FetchedBuilding,
  FetchedConferenceRoom,
  StagedConferenceRoomSlot,
} from "./types";

export function mapFetchedConferenceRoomToConferenceRoom({
  conferenceRoom,
  buildings,
  currentInterview,
  originalInterview,
}: {
  conferenceRoom: FetchedConferenceRoom;
  buildings: FetchedBuilding[];
  currentInterview?: CurrentInterviewForAvailability;
  originalInterview?: OriginalInterviewForAvailability | null;
}): ConferenceRoom {
  const building = buildings.find((b) => b.id === conferenceRoom.buildingId);

  return {
    id: conferenceRoom.id,
    name: conferenceRoom.name,
    capacity: conferenceRoom.capacity,
    hasZoom: conferenceRoom.hasZoom,
    building: building ?? null,
    floorName: conferenceRoom.floorName,
    availability: currentInterview
      ? getConferenceRoomAvailability({
          conferenceRoom,
          currentInterview,
          originalInterview: originalInterview ?? null,
        })
      : undefined,
  };
}

export function mapConferenceRoomSettingsFragmentToConferenceRoomSettings(
  settings: ConferenceRoomSettingsForMappingFragment
): ConferenceRoomSettings {
  return {
    required: settings.required,
    building: settings.building
      ? {
          id: settings.building.id,
          name: settings.building.name,
        }
      : null,
  };
}

export function mapConferenceRoomSettingsToConferenceRoomSlot(
  conferenceRoomSettingsFragment: ConferenceRoomSettingsForMappingFragment
): ConferenceRoomSlot {
  return {
    conferenceRoom: null,
    settings: mapConferenceRoomSettingsFragmentToConferenceRoomSettings(
      conferenceRoomSettingsFragment
    ),
  };
}

export function mapConferenceRoomSlotFragmentToConferenceRoomSlot(
  conferenceRoomSlotFragment: ConferenceRoomSlotForMappingFragment
): ConferenceRoomSlot {
  return {
    conferenceRoom: conferenceRoomSlotFragment.conferenceRoom
      ? {
          id: conferenceRoomSlotFragment.conferenceRoom.id,
          name: conferenceRoomSlotFragment.conferenceRoom.name,
          // rest of the data will need to be fetched from Google directly
        }
      : null,
    settings: conferenceRoomSlotFragment.settings
      ? mapConferenceRoomSettingsFragmentToConferenceRoomSettings(
          conferenceRoomSlotFragment.settings
        )
      : null,
  };
}

export function mapScheduledInterviewToConferenceRoomSlot(
  scheduledInterview: ScheduledInterviewForConferenceRoomSlotMappingFragment
): ConferenceRoomSlot | null {
  if (scheduledInterview.conferenceRoomSlot) {
    return mapConferenceRoomSlotFragmentToConferenceRoomSlot(
      scheduledInterview.conferenceRoomSlot
    );
  }

  return null;
}

export function mapConferenceRoomToConferenceRoomForForm(
  conferenceRoom: ConferenceRoom
): ConferenceRoomForForm {
  return {
    id: conferenceRoom.id,
    name: conferenceRoom.name,
    capacity: conferenceRoom.capacity,
    hasZoom: conferenceRoom.hasZoom,
    buildingId: conferenceRoom.building?.id ?? null,
    floorName: conferenceRoom.floorName,
  };
}

export function mapConferenceRoomSlotToInput(
  conferenceRoomSlot: StagedConferenceRoomSlot
): ConferenceRoomSlotInput {
  const { conferenceRoom, settings } = conferenceRoomSlot;
  return {
    conferenceRoom: {
      id: conferenceRoom.id,
      name: conferenceRoom.name,
    },
    settings: settings
      ? {
          required: settings.required,
          building: settings.building
            ? {
                id: settings.building.id,
                name: settings.building.name,
              }
            : null,
        }
      : undefined,
  };
}
