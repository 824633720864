import { gql } from "generated/graphql-codegen";
import {
  AvailableSlotFragment,
  InterviewSettingsForSubmissionFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

gql(`
  fragment InterviewSettingsForSubmission on SelfScheduleInterviewSettings {
    id  
    title
    duration
    interviewId
    interview {
      id
      name
    }
    locationSettings {
      hostUserMembershipId
      service
    }
    isPrivate
    interviewerId
    interviewer {
      id
      name
      email
      imageUrl
      user {
        id
        timezone
      }
      organization {
        id
        name
        companyLogoUrl
      }
    }
  }
`);

export type AvailableSlot = {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
};

type UseSelfScheduleSubmissionStateProps = {
  scheduledInterviewId?: string;
  availableSlots: AvailableSlotFragment[];
  interviewSettings: InterviewSettingsForSubmissionFragment;
  defaultTimezone?: string;
  showCompanyBranding?: boolean;
};

/** Get default values for initial state */
function useDefaults({
  availableSlots: passedAvailableSlots,
  defaultTimezone: passedDefaultTimezone,
}: UseSelfScheduleSubmissionStateProps) {
  const localTimezone = DateTime.local().zoneName;
  const defaultTimezone = passedDefaultTimezone ?? localTimezone;
  const availableSlots = useMemo(() => {
    return passedAvailableSlots.map((slot) => ({
      id: slot.id,
      startTime: DateTime.fromISO(slot.startTime).setZone(defaultTimezone),
      endTime: DateTime.fromISO(slot.endTime).setZone(defaultTimezone),
    }));
  }, [defaultTimezone, passedAvailableSlots]);
  const availableDays = useMemo(() => {
    const days = availableSlots.map((slot) => slot.startTime.startOf("day"));
    return [...new Set(days)];
  }, [availableSlots]);

  const defaultDay = useMemo(() => {
    return availableDays[0] ?? null;
  }, [availableDays]);

  return {
    availableDays,
    availableSlots,
    defaultTimezone,
    defaultDay,
  };
}

/** State of self schedule submission */
export function useSelfScheduleSubmissionState(
  props: UseSelfScheduleSubmissionStateProps
) {
  const { scheduledInterviewId, interviewSettings, showCompanyBranding } =
    props;
  const {
    defaultTimezone,
    availableSlots: defaultSlots,
    availableDays,
    defaultDay,
  } = useDefaults(props);

  const [selectedTimezone, setSelectedTimezone] = useState(defaultTimezone);
  const [selectedDay, setSelectedDay] = useState<DateTime | null>(
    defaultDay ?? null
  );
  const [selectedSlot, setSelectedSlot] = useState<AvailableSlot | null>(null);

  const availableSlots = useMemo(() => {
    return defaultSlots.map((slot) => ({
      id: slot.id,
      startTime: slot.startTime.setZone(selectedTimezone),
      endTime: slot.endTime.setZone(selectedTimezone),
    }));
  }, [defaultSlots, selectedTimezone]);

  const visibleSlots = useMemo(() => {
    const visible = availableSlots.filter((slot) => {
      if (!selectedDay) {
        return false;
      }

      return slot.startTime.hasSame(selectedDay, "day");
    });

    return [...visible].sort((a, b) => {
      return a.startTime.valueOf() - b.startTime.valueOf();
    });
  }, [availableSlots, selectedDay]);
  const selectDateTooltipContent = useMemo(() => {
    if (!selectedDay) {
      return "Please select a date";
    }

    return undefined;
  }, [selectedDay]);
  const selectTimeTooltipContent = useMemo(() => {
    if (!selectedSlot) {
      return "Please select a time";
    }

    return undefined;
  }, [selectedSlot]);
  const reviewPageDisabledTooltipContent = useMemo(() => {
    if (!selectedSlot) {
      return "Please select a time";
    }

    return undefined;
  }, [selectedSlot]);

  useEffect(() => {
    if (
      selectedDay &&
      selectedSlot &&
      !selectedSlot.startTime.hasSame(selectedDay, "day")
    ) {
      setSelectedSlot(null);
    }
  }, [selectedDay, selectedSlot, setSelectedSlot]);

  return useMemo(
    () => ({
      selectedDay,
      setSelectedDay,
      selectedTimezone,
      setSelectedTimezone,
      selectedSlot,
      setSelectedSlot,
      visibleSlots,
      availableDays,
      reviewPageDisabledTooltipContent,
      selectDateTooltipContent,
      selectTimeTooltipContent,
      availableSlots,
      interviewSettings,
      scheduledInterviewId,
      showCompanyBranding,
      isReschedule: !!scheduledInterviewId,
    }),
    [
      availableDays,
      availableSlots,
      interviewSettings,
      reviewPageDisabledTooltipContent,
      scheduledInterviewId,
      selectDateTooltipContent,
      selectTimeTooltipContent,
      selectedDay,
      selectedSlot,
      selectedTimezone,
      setSelectedSlot,
      setSelectedTimezone,
      showCompanyBranding,
      visibleSlots,
    ]
  );
}

export type SelfScheduleSubmissionState = ReturnType<
  typeof useSelfScheduleSubmissionState
>;
