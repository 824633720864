import { Avatar } from "@resource/atlas/avatar/Avatar";
import { SelectedInterviewerDisplay } from "client/components/interviewer-slots/display/SelectedInterviewerDisplay";
import { DateTime } from "luxon";

import { useAllInterviewerUserMemberships } from "../../hooks/interviews";
import { useSchedulerCalendarColors } from "../hooks/useSchedulerCalendarColors";

export function DayViewInterviewerCalendarDisplay({
  calendarId,
}: {
  calendarId: string;
}) {
  const calendarColors = useSchedulerCalendarColors();
  const interviewerUserMemberships = useAllInterviewerUserMemberships();
  const interviewer = interviewerUserMemberships.find(
    (userMembership) => userMembership.id === calendarId
  );

  if (!interviewer) {
    return (
      <div className="flex space-x-2 items-center w-full text-subtle">
        <Avatar variant="interviewer-rule-people" size="small" />
        <div className="flex items-center flex-grow">
          <span className="text-body-xs">No interviewer</span>
        </div>
      </div>
    );
  }

  return (
    <SelectedInterviewerDisplay
      interviewer={interviewer}
      calendarColor={calendarColors[interviewer.id]}
      hideTimezone
      hideAvailabilityIcon
      size="xs"
      Subtext={
        <span className="text-body-xs text-subtle">
          {interviewer.user.timezone
            ? DateTime.now()
                .setZone(interviewer.user.timezone)
                .toFormat("h:mma ZZZZ")
            : "No timezone set"}
        </span>
      }
    />
  );
}
