import { createRenderer } from "@resource/atlas/content-editor/renderer";
import {
  createTemplateVariableNodeRenderer,
  createUnknownVariableNodeRenderer,
  createVariableNodeRenderer,
} from "@resource/atlas/content-editor/variables";
import { createTemplateUnknownVariableNodeRenderer } from "@resource/atlas/content-editor/variables/__lib/template-node-renderer";

import { guideBaseNodeRenderers } from "../base/node-renderers";
import { guideUpdateRendererSet } from "./variables";

export const renderGuideUpdate = createRenderer({
  nodeRenderers: {
    ...guideBaseNodeRenderers,
    variable: createVariableNodeRenderer(guideUpdateRendererSet),
    "unknown-variable": createUnknownVariableNodeRenderer(),
  },
});

export const renderGuideUpdateTemplate = createRenderer({
  nodeRenderers: {
    ...guideBaseNodeRenderers,
    variable: createTemplateVariableNodeRenderer(guideUpdateRendererSet),
    "unknown-variable": createTemplateUnknownVariableNodeRenderer(),
  },
});
