import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  Dialog,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
// eslint-disable-next-line import/no-restricted-paths
import { SchedulerDialog as SchedulerDialogV2 } from "client/scheduler/wrappers/SchedulerDialog";
import { gql } from "generated/graphql-codegen";
import {
  GoogleEventForIntegrationDifferencesFragment,
  ScheduleInterviewForInterviewDetailsFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";

import { InterviewDifferences } from "../../InterviewDifferences/InterviewDifferences";
import { useResync } from "../__hooks/useResyncGoogleCalendar";

gql(`
  fragment ScheduledInterviewForIntegrationDifferences on ScheduledInterview {
    id
    startTime
    endTime
    videoConferencingUrl
    interviewers {
      id
      userMembership {
        id
        email
        name
        imageUrl
      }
    }
    conferenceRoomData {
      id
      name
    }
  }
`);

gql(`
  fragment GoogleEventForIntegrationDifferences on GoogleEvent {
    id
    startAt
    endAt
    attendees {
      email
    }
    conferenceRooms {
      email
    }
    videoConferencing {
      url
      label
    }
  }
`);

type GoogleIntegrationDifferencesModalProps = {
  store: DialogStore;
  // Note: we need to include this full type because resync requires it. Ideally resync will have a fragment only requiring what it needs
  // but not a priority right now
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
  googleEvent: GoogleEventForIntegrationDifferencesFragment;
};

export function GoogleIntegrationDifferencesModal({
  store,
  scheduledInterview,
  googleEvent,
}: GoogleIntegrationDifferencesModalProps) {
  const schedulerV2DialogStore = useDialogStore();

  const onSchedule = useCallback(() => {
    schedulerV2DialogStore.show();
  }, [schedulerV2DialogStore]);

  const interview = useMemo(() => {
    return {
      startAt: new Date(scheduledInterview.startTime),
      endAt: new Date(scheduledInterview.endTime),
      interviewers: scheduledInterview.interviewers.map((interviewer) => ({
        id: interviewer.userMembership.id,
        name: interviewer.userMembership.name,
        imageUrl: interviewer.userMembership.imageUrl,
        email: interviewer.userMembership.email,
      })),
      videoConferencingUrl: scheduledInterview.videoConferencingUrl,
      conferenceRooms: scheduledInterview.conferenceRoomData,
    };
  }, [scheduledInterview]);

  const externalInterview = useMemo(() => {
    return {
      // Should never fall through to this case
      startAt: googleEvent.startAt ? new Date(googleEvent.startAt) : new Date(),
      endAt: googleEvent.endAt ? new Date(googleEvent.endAt) : new Date(),
      attendees: googleEvent.attendees,
      conferenceRooms: googleEvent.conferenceRooms,
      videoConferencing: googleEvent.videoConferencing,
    };
  }, [googleEvent]);

  const { resync, loading: resyncPending } = useResync({
    scheduledInterview,
  });

  const initiateResync = useCallback(async () => {
    await resync();
    store.hide();
  }, [resync, store]);

  return (
    <>
      <Dialog store={store} size="large">
        <View
          footer={{
            rightActions: (
              <ButtonGroup>
                <Button isGhost onClick={() => store.hide()}>
                  Close
                </Button>
                {scheduledInterview.isV2 && (
                  <Button isGhost onClick={onSchedule}>
                    Edit interview in Guide
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={initiateResync}
                  isLoading={resyncPending}
                >
                  Update Google to match Guide
                </Button>
              </ButtonGroup>
            ),
          }}
        >
          <div className="space-y-4">
            <div className="space-y-2">
              <h3 className="text-h3">
                Review Guide and Google Calendar differences
              </h3>
              <div className="text-body-md">
                Changes to interview details in Google Calendar won&apos;t
                automatically update in Guide, except for conferencing details.
                To keep information consistent, either update Google Calendar
                with the Guide interview details or manually update the
                interview in Guide.
              </div>
            </div>
            <InterviewDifferences
              interview={interview}
              externalInterview={externalInterview}
            />
          </div>
        </View>
      </Dialog>
      {scheduledInterview.scheduledInterviewGroupId && (
        <SchedulerDialogV2
          store={schedulerV2DialogStore}
          defaultSelectedInterviewId={scheduledInterview.id}
          scheduledInterviewGroupId={
            scheduledInterview.scheduledInterviewGroupId
          }
        />
      )}
    </>
  );
}
