import { TabPanel, TabStore } from "@ariakit/react";
import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { ExtensionHeader } from "client/app/extension/__components/ExtensionHeader";
import { ExtensionUpdateBanner } from "client/app/extension/__components/ExtensionUpdateBanner";
import {
  GuideForExtensionPageFragment,
  OrganizationFeaturesEnum,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import {
  ExtensionTabs,
  useExtensionTabStore,
} from "../__hooks/useExtensionTabStore";
import { ExtensionSchedulingRequests } from "./ExtensionSchedulingRequests";
import { TabHeader } from "./ExtensionTabHeader";
import { GuideExtensionTab } from "./GuideExtensionTab";

export type ExtensionCandidateProfileProps = {
  onCandidateProfile?: boolean;
  guide?: GuideForExtensionPageFragment | null;
  guideLoading?: boolean;
  defaultSelectedTab?: string | null;
  onClickSchedulingRequest: (args: { schedulingRequestId: string }) => void;
  onViewAllInterviews: () => void;
  onClickScheduledInterview: (args: { scheduledInterviewId: string }) => void;
};

/**
 * Display candidate profile within the extension
 * This will handle showing tabs or not based on whether scheduling is enabled for the org
 * It will also include the other extension-specific headers and banners
 */
export function ExtensionCandidateProfile({
  onCandidateProfile,
  guide,
  guideLoading,
  defaultSelectedTab,
  onClickScheduledInterview,
  onViewAllInterviews,
  onClickSchedulingRequest,
}: ExtensionCandidateProfileProps) {
  const { hasFeatureEnabled } = useAuthContext();
  const schedulingRequestsEnabled = useMemo(
    () => hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING),
    [hasFeatureEnabled]
  );
  const tabStore = useExtensionTabStore({
    defaultSelectedTab,
    onCandidateProfile,
  });

  return (
    <View
      CustomHeader={
        <>
          <ExtensionUpdateBanner />
          <ExtensionHeader />
          {schedulingRequestsEnabled && (
            <TabHeader tabStore={tabStore} guide={guide} />
          )}
        </>
      }
      content={{
        className: "p-0",
      }}
    >
      {schedulingRequestsEnabled ? (
        <ExtensionPageWithTabs
          tabStore={tabStore}
          onCandidateProfile={onCandidateProfile}
          guide={guide}
          guideLoading={guideLoading}
          onClickScheduledInterview={onClickScheduledInterview}
          onViewAllInterviews={onViewAllInterviews}
          onClickSchedulingRequest={onClickSchedulingRequest}
        />
      ) : (
        <div className="overflow-y-scroll">
          <GuideExtensionTab
            guide={guide}
            guideLoading={guideLoading}
            onCandidateProfile={onCandidateProfile}
            onClickScheduledInterview={onClickScheduledInterview}
            onViewAllInterviews={onViewAllInterviews}
            onClickSchedulingRequest={onClickSchedulingRequest}
          />
        </div>
      )}
    </View>
  );
}

/**
 * Show the extension page with tabs for scheduling requests and candidate guide
 */
function ExtensionPageWithTabs({
  tabStore,
  onCandidateProfile,
  guide,
  guideLoading,
  onClickScheduledInterview,
  onViewAllInterviews,
  onClickSchedulingRequest,
}: { tabStore: TabStore } & Pick<
  ExtensionCandidateProfileProps,
  | "guide"
  | "guideLoading"
  | "onCandidateProfile"
  | "onClickScheduledInterview"
  | "onViewAllInterviews"
  | "onClickSchedulingRequest"
>) {
  return (
    <div className="flex-grow flex flex-col overflow-hidden">
      <div className="flex-grow overflow-y-scroll">
        <TabPanel store={tabStore} tabId={ExtensionTabs.schedulingTasks}>
          <ExtensionSchedulingRequests
            onClickSchedulingRequest={onClickSchedulingRequest}
          />
        </TabPanel>
        <TabPanel store={tabStore} tabId={ExtensionTabs.candidate}>
          <GuideExtensionTab
            guide={guide}
            guideLoading={guideLoading}
            onCandidateProfile={onCandidateProfile}
            onClickScheduledInterview={onClickScheduledInterview}
            onViewAllInterviews={onViewAllInterviews}
            onClickSchedulingRequest={onClickSchedulingRequest}
          />
        </TabPanel>
      </div>
    </div>
  );
}
