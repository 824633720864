import {
  interviewerSlotSettingsOpenAtomFamily,
  selectedInterviewAtom,
} from "client/scheduler/core/state";
import { atom, useAtomValue } from "jotai";
import { uniq } from "lodash";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

/**
 * List of all user membership IDs for all interviewers as part of current selected interview.
 * Includes config options and shadowers for each slot.
 */
const allUserMembershipIdsAtom = atom((get) => {
  const selectedInterview = get(selectedInterviewAtom);

  if (selectedInterview) {
    return uniq(
      selectedInterview.interviewerSlots
        .flatMap((slot) => {
          return [
            slot.interviewer?.userMembership,
            slot.shadowingInterviewer?.userMembership,
            ...(slot.userMembershipsSetting ?? []),
            ...(slot.shadowingUserMembershipsSetting ?? []),
          ];
        })
        .filter(filterOutNullsAndUndefined)
        .map((um) => um.id)
        .sort((a, b) => a.localeCompare(b))
    );
  }

  return [];
});

// Need to use an atom to properly handle the `interviewerConfigOpenAtomFamily` dependency
// Otherwise could have done this all in-line in the hook

/**
 * List of user membership IDs for the current viewing interviewers as part of current selected interview.
 * Includes config options and shadowers for each slot. Also takes into account the open state of the config dropdown.
 */
const currentViewingUserMembershipIdsAtom = atom((get) => {
  const selectedInterview = get(selectedInterviewAtom);

  if (selectedInterview) {
    return uniq(
      selectedInterview.interviewerSlots
        .flatMap((slot) => {
          const {
            userMembershipsSetting,
            shadowingUserMembershipsSetting,
            interviewer,
          } = slot;
          const { userMembershipsOpen, shadowingUserMembershipsOpen } = get(
            interviewerSlotSettingsOpenAtomFamily(slot.id)
          );

          // handle multiple interviewers in dropdown config
          return [
            interviewer?.userMembership,
            slot.shadowingInterviewer?.userMembership,
            ...(userMembershipsOpen && userMembershipsSetting
              ? userMembershipsSetting
              : []),
            ...(shadowingUserMembershipsOpen && shadowingUserMembershipsSetting
              ? shadowingUserMembershipsSetting
              : []),
          ];
        })
        .filter(filterOutNullsAndUndefined)
        .map((um) => um.id)
        .sort((a, b) => a.localeCompare(b))
    );
  }

  return [];
});

/**
 * Get a list of interviewers for the selected interview
 * Optionally filter to the current viewing interviewers
 */
export function useSelectedInterviewInterviewerUserMembershipIds({
  filterToCurrentViewing,
}: {
  /**
   * Filters the current interviewers based on the state of the config dropdowns.
   * If false, returns all interviewers for the selected interview, regardless of dropdown state.
   * If true, returns only the interviewers that are currently being viewed in the config dropdowns.
   */
  filterToCurrentViewing?: boolean;
} = {}) {
  const allInterviewerUserMembershipIds = useAtomValue(
    allUserMembershipIdsAtom
  );
  const currentViewingUserMembershipIds = useAtomValue(
    currentViewingUserMembershipIdsAtom
  );

  if (filterToCurrentViewing) {
    return currentViewingUserMembershipIds;
  }

  return allInterviewerUserMembershipIds;
}
