/**
 * A paginated select component for selecting a single user. Can be provided with filters to limit the users that can be selected.
 */

import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { AtlasPopoverStateProps, Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { InterviewerForSelectionFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import useQuery from "utils/useQuery";

import { Divider } from "../misc/Divider";

type User = InterviewerForSelectionFragment;

gql(`
fragment InterviewerForSelection on UserMembership {
  id
  firstName
  lastName
  name
  imageUrl
  email
  googleCalendarId
  user {
    id
    timezone
  }
  workingHours {
    startTime {
      hour
      minute
    }
    endTime {
      hour
      minute
    }
    isWorkingDay
  }
  upcomingInterviewsPerDay {
    date
    count
  }
  maxInterviewLoadPerDay
  maxInterviewLoadPerWeek
}
`);

const FETCH_USERS_FOR_USER_SELECT_QUERY = gql(`
  query FetchUsersForUserSelectQuery($search: String, $limit: Int, $userMembershipIds: [String!], $tags: [String!], $isQualifiedForPools: Boolean) {
    userMemberships(
      search: $search, 
      limit: $limit, 
      userMembershipIds: $userMembershipIds, 
      tags: $tags,
      isQualifiedForPools: $isQualifiedForPools
    ) {
      ...InterviewerForSelection
    }
  }
`);

const FETCH_USER_FOR_USER_SELECTED_QUERY = gql(`
  query FetchUserForUserSelectedQuery($id: String!) {
    userMembershipById(id: $id) {
      ...InterviewerForSelection
    }
  }
`);

type QueryFilters = Partial<
  Omit<
    typeof FETCH_USERS_FOR_USER_SELECT_QUERY extends TypedDocumentNode<
      unknown,
      infer V
    >
      ? V
      : never,
    "search" | "limit"
  >
>;

export function UserSelectOptionItem({
  item,
  onClick,
  isSelected,
}: {
  item: User;
  onClick?: () => void;
  isSelected?: boolean;
}) {
  return (
    <OptionItem
      isSelectable
      size="compact"
      onClick={onClick}
      isSelected={isSelected}
    >
      <div className="flex gap-2 items-center">
        <Avatar
          size="xs"
          image={item.imageUrl}
          name={item.name || item.email}
        />
        <div title={item.name || item.email}>{item.name || item.email}</div>
      </div>
    </OptionItem>
  );
}

export type UsersSelectProps = {
  onSelect: (user: User) => void;
  selectedId?: string | null;
  excludeUserIds?: string[];
  filters?: QueryFilters;
  placeholderText?: string;
  Trigger?: JSX.Element;
  popoverProps?: AtlasPopoverStateProps;
  disabled?: boolean;
  triggerProps?: AtlasSelectTriggerProps;
  portal?: boolean;
  onClear?: () => void;
  searchPlaceholderText?: string;
  topUsers?: User[];
};

export function UsersSelect({
  onSelect,
  selectedId,
  placeholderText,
  filters,
  excludeUserIds,
  Trigger,
  popoverProps,
  triggerProps,
  disabled,
  portal,
  onClear,
  searchPlaceholderText = "Search by name or email",
  topUsers,
}: UsersSelectProps) {
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");
  const popoverState = usePopoverState(popoverProps);

  const { data, loading } = useQuery(FETCH_USERS_FOR_USER_SELECT_QUERY, {
    variables: {
      search: debouncedTerm,
      limit: 25,
      userMembershipIds: filters?.userMembershipIds ?? null,
      isQualifiedForPools: filters?.isQualifiedForPools ?? null,
      tags: filters?.tags ?? null,
    },
  });

  const { data: selectedData } = useQuery(FETCH_USER_FOR_USER_SELECTED_QUERY, {
    variables: {
      id: selectedId!,
    },
    skip: !selectedId,
  });

  const users = useMemo(() => data?.userMemberships ?? [], [data]);

  const renderUserOption = useCallback(
    (user: User) => (
      <UserSelectOptionItem
        key={user.id}
        item={user}
        onClick={() => {
          if (onClear && user.id === selectedId) {
            onClear();
          } else {
            onSelect(user);
          }
          popoverState.hide();
        }}
        isSelected={selectedId === user.id}
      />
    ),
    [onClear, onSelect, popoverState, selectedId]
  );

  const options = useMemo(
    () =>
      users
        .filter((user) => !excludeUserIds?.includes(user.id))
        .sort((a, b) => {
          if (selectedId === a.id) return -1;
          if (selectedId === b.id) return 1;
          return 0;
        })
        .map(renderUserOption),
    [users, excludeUserIds, selectedId, renderUserOption]
  );

  // clear search term on popover state close
  useEffect(() => {
    if (!popoverState.open) {
      setSearchTerm("");
    }
  }, [popoverState.open, setSearchTerm]);

  const renderedTrigger = useMemo(() => {
    if (Trigger) {
      return Trigger;
    }

    if (selectedId && selectedData?.userMembershipById) {
      return (
        <SelectTrigger
          {...triggerProps}
          className={clsx(
            "flex-1 bg-light-gray-500 w-full",
            { "opacity-40": disabled },
            triggerProps?.className
          )}
        >
          <div className="flex gap-2 items-center">
            <Avatar
              size="xs"
              image={selectedData.userMembershipById.imageUrl}
              name={
                selectedData.userMembershipById.name ||
                selectedData.userMembershipById.email
              }
            />
            <div
              title={
                selectedData.userMembershipById.name ||
                selectedData.userMembershipById.email
              }
            >
              {selectedData.userMembershipById.name ||
                selectedData.userMembershipById.email}
            </div>
          </div>
        </SelectTrigger>
      );
    }

    return (
      <SelectTrigger
        {...triggerProps}
        className={clsx(
          "flex-1 bg-light-gray-500 w-full text-subtle",
          triggerProps?.className
        )}
      >
        {placeholderText ?? "Select user"}
      </SelectTrigger>
    );
  }, [
    Trigger,
    disabled,
    placeholderText,
    selectedData?.userMembershipById,
    selectedId,
    triggerProps,
  ]);

  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger disabled={disabled}>{renderedTrigger}</Popover.Trigger>
      <Popover.Content
        className="w-[30rem]"
        hasPadding={false}
        portal={portal}
        header={
          <div className="space-y-3 p-1">
            <TextField
              size="small"
              value={searchTerm}
              autoFocus
              icon={atlasSearch}
              placeholder={searchPlaceholderText}
              aria-label={searchPlaceholderText}
              onChange={setSearchTerm}
              isClearable
            />
          </div>
        }
      >
        <div className="p-[.5rem]">
          {topUsers && topUsers.length > 0 && (
            <>
              {topUsers.map(renderUserOption)}
              <div className="py-2">
                <Divider />
              </div>
            </>
          )}
          {loading && (
            <div className="flex justify-center">
              <LoadingIndicator size="small" />
            </div>
          )}
          {!loading && options.length > 0 && <>{options}</>}
          {!loading && options.length === 0 && (
            <p className="px-5 py-2 text-body-md">No results found</p>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

UsersSelect.storyData = {
  fetchUsersQuery: FETCH_USERS_FOR_USER_SELECT_QUERY,
  fetchUserQuery: FETCH_USER_FOR_USER_SELECTED_QUERY,
};
