import * as FullStory from "@fullstory/browser";
import { RoleEnum } from "enums/role-enum";
import { gql } from "generated/graphql-codegen";
import { UserForFullstoryFragment } from "generated/graphql-codegen/graphql";
import React, { useEffect, useRef } from "react";
import inIframe from "utils/in-iframe";

gql(`
fragment UserForFullstory on User {
  id
  primaryEmail
  fullName
  createdAt
  currentUserMembership {
    highestRole {
      id
    }
  }
}
`);

function shouldRecordSession(user?: UserForFullstoryFragment | null) {
  // Always record non-candidate users.
  if (
    user &&
    user.currentUserMembership?.highestRole?.id !== RoleEnum.Candidate
  ) {
    return true;
  }

  // Otherwise, sample only 10% of sessions.
  // bearer:disable javascript_lang_insufficiently_random_values
  const sampleProbability = Math.random();
  const cutoff = 0.1;
  return sampleProbability < cutoff;
}

interface FullstoryWrapperProps {
  children?: React.ReactNode;
  user?: UserForFullstoryFragment | null;
}

export function FullstoryWrapper({ children, user }: FullstoryWrapperProps) {
  const userIdIdentified = useRef<string | undefined>();

  useEffect(() => {
    // Identify user via callback to avoid race conditions with initialization.
    const identifyUser = () => {
      // Ensure we only identify the user once.
      if (user && userIdIdentified.current !== user.id) {
        console.debug("Identifying user for fullstory...");
        userIdIdentified.current = user.id;
        FullStory.identify(user.id, {
          email: user.primaryEmail ?? undefined,
          displayName: user.fullName,
        });
      }
    };

    if (!FullStory.isInitialized()) {
      if (inIframe()) {
        // eslint-disable-next-line no-underscore-dangle
        window._fs_capture_on_startup = false;
        console.debug("Initializing fullstory...", {
          location: "FullstoryWrapper.tsx",
        });
        FullStory.init(
          {
            orgId: "X0B5",
            recordOnlyThisIFrame: true,
          },
          identifyUser
        );
        // Shut down immediately in extension; will restart when panel is open.
        window.FS?.shutdown();
      } else if (shouldRecordSession(user)) {
        console.debug("Initializing fullstory...", {
          location: "FullstoryWrapper.tsx",
        });
        FullStory.init(
          {
            orgId: "X0B5",
          },
          identifyUser
        );
      }
    } else if (user && FullStory.isInitialized()) {
      identifyUser();
    }
  }, [user]);

  return <>{children}</>;
}
