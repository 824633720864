/* eslint-disable import/prefer-default-export */
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getNearestNodeOfType } from "@lexical/utils";
import { LexicalCommand } from "lexical";
import { useMemo, useState } from "react";

import { useEvent } from "../../../../__utils/react";
import { $getSelectionTopNode } from "../lib/utils";

type ListType = "ordered" | "unordered";

export function useListContext() {
  const [editor] = useLexicalComposerContext();

  const [isList, setIsList] = useState(false);
  const [listType, setListType] = useState<ListType | undefined>();

  const updateListener = useEvent(() => {
    const [topNode, { anchorNode }] = $getSelectionTopNode(editor);
    if (anchorNode && $isListNode(topNode)) {
      const parentList = $getNearestNodeOfType(anchorNode, ListNode);
      const type = parentList ? parentList.getTag() : topNode.getTag();

      setIsList(true);
      setListType(type === "ol" ? "ordered" : "unordered");
    } else {
      setIsList(false);
      setListType(undefined);
    }
  });

  const toggleList = useEvent((type: ListType) => {
    let command: LexicalCommand<void>;

    if (type === "ordered")
      command =
        listType !== "ordered"
          ? INSERT_ORDERED_LIST_COMMAND
          : REMOVE_LIST_COMMAND;
    else
      command =
        listType !== "unordered"
          ? INSERT_UNORDERED_LIST_COMMAND
          : REMOVE_LIST_COMMAND;

    editor.dispatchCommand(command, undefined);
  });

  const toggleUnorderedList = useEvent(() => toggleList("unordered"));
  const toggleOrderedList = useEvent(() => toggleList("ordered"));

  const value = useMemo(
    () => ({
      isList,
      listType,
      toggleList,
      toggleUnorderedList,
      toggleOrderedList,
    }),
    [isList, listType, toggleList, toggleOrderedList, toggleUnorderedList]
  );

  return [updateListener, value] as const;
}
