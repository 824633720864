/* eslint-disable import/prefer-default-export */
import { createHeadlessEditor } from "@lexical/headless";

import { baseModules } from "./__utils/base";
import { mergeThemes } from "./__utils/misc";
import { processModules } from "./__utils/modules";
import type { AtlasHeadlessContentEditorOptions } from "./types";

export function createHeadlessContentEditor({
  modules = [],
  onError,
}: AtlasHeadlessContentEditorOptions) {
  const { nodes, themes } = processModules([...baseModules, ...modules]);

  return createHeadlessEditor({
    nodes,
    theme: mergeThemes(themes),
    onError(error) {
      /* eslint-disable no-console */
      console.error("ContentEditor had an error:");
      console.error(error);
      /* eslint-enable no-console */
      onError?.(error);
    },
  });
}
