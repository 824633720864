import { Button } from "@resource/atlas/button/Button";
import { AtlasButtonProps } from "@resource/atlas/button/types";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { atlasCalendarEvent } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useLogEvent } from "analytics";
import { useSchedulingTaskAndAvailabilityWorkflowsNavigation } from "client/app/(scheduling requests)/hooks/useSchedulingTaskAndAvailabilityWorkflowsNavigation";
import { useFlags } from "client/lib/launchdarkly";
import { SchedulerDialog } from "client/scheduler/wrappers/SchedulerDialog";
import { SchedulingTaskAndAvailabilityWorkflowsDialog } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import { SelfScheduleRequestFormDialog } from "client/self-schedule/components/SelfScheduleRequestFormDialog";
import { SendSelfScheduleLinkOption } from "client/self-schedule/components/SendSelfScheduleLinkOption";
import FeatureFlags from "generated/FeatureFlags";
import { JobInterviewForSelfScheduleSelectionFragment } from "generated/graphql-codegen/graphql";
import { useState } from "react";

import { JustScheduleInterviewsButton } from "./JustScheduleInterviewsButton";

export function SchedulingOmniButton({
  guideId,
  jobId,
  canReschedule,
  shouldReschedule,
  buttonProps,
  selfScheduleInterviews,
}: {
  guideId: string;
  jobId: string;
  canReschedule?: boolean;
  shouldReschedule?: boolean;
  buttonProps?: AtlasButtonProps;
  selfScheduleInterviews?: JobInterviewForSelfScheduleSelectionFragment[];
}) {
  const logEvent = useLogEvent({
    component: "SchedulingOmniButton",
  });
  const dialogStore = useDialogStore();
  const [selfScheduleInterviewId, setSelfScheduleInterviewId] = useState<
    string | undefined
  >();
  const {
    onClick: onRequestAvailability,
    dialogProps: requestAvailabilityDialogProps,
  } = useSchedulingTaskAndAvailabilityWorkflowsNavigation({
    params: {
      guideId,
      defaultSchedulingRequestFormType: shouldReschedule
        ? "rescheduling"
        : "select-task",
    },
    analytics: {
      component: "SchedulingOmniButton",
    },
  });
  const schedulerDialogStore = useDialogStore();

  const {
    [FeatureFlags.JUST_SCHEDULE_INTERVIEWS]: justScheduleInterviewsEnabled,
  } = useFlags();

  const {
    onClick: onCreateSchedulingTask,
    dialogProps: createSchedulingTaskDialogProps,
  } = useSchedulingTaskAndAvailabilityWorkflowsNavigation({
    params: {
      guideId,
      defaultSchedulingRequestFormType: "scheduling",
      skipRequestingAvailability: true,
    },
    analytics: {
      component: "SchedulingOmniButton",
    },
  });

  const {
    onClick: onCreateReschedulingTask,
    dialogProps: createReschedulingTaskDialogProps,
  } = useSchedulingTaskAndAvailabilityWorkflowsNavigation({
    params: {
      guideId,
      defaultSchedulingRequestFormType: "rescheduling",
      skipRequestingAvailability: true,
    },
    analytics: {
      component: "SchedulingOmniButton",
    },
  });

  const menuItems = useMenuItems(
    (i) => [
      selfScheduleInterviews
        ? i.item({
            key: "self-schedule",
            render: () => (
              <SendSelfScheduleLinkOption
                selfScheduleInterviews={selfScheduleInterviews}
                onClick={(interview) => {
                  logEvent("Send Self-schedule Request Clicked");
                  setSelfScheduleInterviewId(interview.id);
                  dialogStore.show();
                }}
              />
            ),
            children: `Request self-schedule`,
          })
        : null,
      i.item({
        key: "request-availability",
        children: "Request availability",
        onClick: () => {
          onRequestAvailability();
        },
      }),
      i.separator({
        key: "separator",
      }),
      i.item({
        key: "assign-scheduling-task",
        children: `Request scheduling`,
        onClick: () => {
          onCreateSchedulingTask();
        },
      }),
      i.item({
        key: "assign-rescheduling-task",
        children: `Request rescheduling`,
        disabled: !canReschedule,
        onClick: () => {
          onCreateReschedulingTask();
        },
      }),
      ...(justScheduleInterviewsEnabled
        ? [
            i.item({
              key: "just-schedule-interviews",
              children: "Schedule now",
              render: () => (
                <JustScheduleInterviewsButton
                  onClick={() => {
                    schedulerDialogStore.show();
                  }}
                />
              ),
            }),
          ]
        : []),
      i.separator({
        key: "separator-2",
      }),
      i.item({
        key: "edit-scheduling-defaults",
        children: "Edit scheduling template",
        onClick: () => {
          logEvent("Edit Scheduling Defaults Clicked");

          window.open(`/jobs/${jobId}/interview-plan`, "_blank");
        },
      }),
    ],
    [
      justScheduleInterviewsEnabled,
      selfScheduleInterviews,
      canReschedule,
      schedulerDialogStore,
      onRequestAvailability,
      logEvent,
      dialogStore,
      onCreateSchedulingTask,
      onCreateReschedulingTask,
      jobId,
    ]
  );

  return (
    <>
      <Menu.Root>
        <Menu.Trigger>
          <Button
            variant="primary"
            className="w-full"
            isDropdown
            icon={atlasCalendarEvent}
            {...buttonProps}
          >
            Scheduling
          </Button>
        </Menu.Trigger>
        <Menu.Content portal items={menuItems} />
      </Menu.Root>
      <SchedulingTaskAndAvailabilityWorkflowsDialog
        {...requestAvailabilityDialogProps}
      />
      <SchedulingTaskAndAvailabilityWorkflowsDialog
        {...createSchedulingTaskDialogProps}
      />
      <SchedulingTaskAndAvailabilityWorkflowsDialog
        {...createReschedulingTaskDialogProps}
      />
      <SelfScheduleRequestFormDialog
        store={dialogStore}
        selfScheduleInterviewId={selfScheduleInterviewId}
        guideId={guideId}
        variant="sheet"
      />
      <SchedulerDialog store={schedulerDialogStore} guideId={guideId} />
    </>
  );
}
