import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRing } from "@resource/atlas/icons";

export default function StagePositionIndicator({
  position,
}: {
  position: number;
}) {
  return (
    <div className="w-5 h-5 pr-5 relative text-subtle inline">
      <Icon className="absolute inset-0" content={atlasRing} />
      <span className="absolute inset-0 text-body-sm text-[.625rem] text-center w-5 leading-5">
        {position}
      </span>
    </div>
  );
}
