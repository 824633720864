import { useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { useEffect } from "react";
import { usePrevious } from "react-use";

const STAGE_CHANGE_SUBSCRIPTION = gql(`
  subscription StageChangeSubscription($applicationId: String!) {
    atssyncApplicationMovedStage(applicationId: $applicationId) {
      id
      currentStage {
        id
      }
    }
  }
`);

/** Refetch a query when the application's stage changes */
export function useSubscribeToStageChange({
  applicationId,
  refetch,
}: {
  applicationId: string | undefined;
  refetch: () => void;
}) {
  const { data: subscriptionData } = useSubscription(
    STAGE_CHANGE_SUBSCRIPTION,
    {
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        applicationId: applicationId!,
      },
      skip: !applicationId,
    }
  );
  const currentStageId =
    subscriptionData?.atssyncApplicationMovedStage?.currentStage?.id;
  const prevStageId = usePrevious(currentStageId);

  useEffect(() => {
    if (currentStageId !== prevStageId) {
      refetch();
    }
  }, [currentStageId, prevStageId, refetch, subscriptionData]);
}
