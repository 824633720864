import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

const UPDATE_INTERVIEWER_INVITE_INFO = gql(`
  mutation UpdateInterviewerInviteInfo($input: UpdateInterviewerInviteInfoInput!) {
    updateInterviewerInviteInfo(input: $input) {
      success
      code
      message
      scheduledInterview {
        id
        interviewerConfirmationTitle
        interviewerCustomInstructions
      }
    }
  }
`);

export function useUpdateInterviewerInviteInfo({
  onCompleted,
}: {
  onCompleted?: () => void;
}) {
  const [updateInterviewerInviteInfo, opts] = useMutation(
    UPDATE_INTERVIEWER_INVITE_INFO,
    {
      onCompleted,
    }
  );

  const wrappedUpdate = useCallback(
    async ({
      scheduledInterviewId,
      interviewerConfirmationTitle,
      interviewerCustomInstructions,
      shouldNotifyInterviewers,
      note,
    }: {
      scheduledInterviewId: string;
      interviewerConfirmationTitle: AtlasContentEditorSerializedState;
      interviewerCustomInstructions: AtlasContentEditorSerializedState;
      shouldNotifyInterviewers: boolean;
      note?: string;
    }) => {
      return updateInterviewerInviteInfo({
        variables: {
          input: {
            scheduledInterviewId,
            interviewerConfirmationTitle,
            interviewerCustomInstructions,
            shouldNotifyInterviewers,
            note,
          },
        },
      });
    },
    [updateInterviewerInviteInfo]
  );

  return [wrappedUpdate, opts] as const;
}
