/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import clsx from "clsx";
import { ReactElement, useMemo, useState } from "react";

import { atlasPerson } from "../../icons";
import { createComponentUtils } from "../__utils/atlas";
import { usePCollection } from "../__utils/collections";
import { createDefaultProps } from "../__utils/react";
import { Hovercard } from "../hovercard";
import { useHovercardState } from "../hovercard/use-hovercard-state";
import { Icon } from "../icon/Icon";
import { OptionItem } from "../option/OptionItem";
import OptionalTooltip from "../tooltip/OptionalTooltip";
import { Avatar } from "./Avatar";
import type {
  AtlasAvatarGroupComponent,
  AtlasAvatarGroupItem,
  AtlasAvatarGroupProps,
  AtlasAvatarProps,
} from "./types";

// config
// ------

const COMPONENT_NAME = "AvatarGroup";
const DEFAULT_PROPS = createDefaultProps<AtlasAvatarGroupProps>()({
  size: "medium",
  max: 4,
} as const);
const HIDE_TIMEOUT = 100;

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// overflow indicator
// ------------------

type OverflowIndicatorProps = {
  items: AtlasAvatarProps<"div" | "button" | "a">[];
  portalElement: HTMLDivElement | null;
};

function OverflowIndicator({
  items,
  portalElement,
}: OverflowIndicatorProps): ReactElement {
  const text = items.length < 10 ? `+${items.length}` : "9+";
  const hovercard = useHovercardState({
    showTimeout: 0,
    hideTimeout: HIDE_TIMEOUT,
  });

  const renderedOverflowItems = useMemo(
    () =>
      items.map((avatarItem) => {
        const {
          as,
          variant,
          initials,
          name,
          image,
          status,
          showBorder,
          disableTooltip,
          UNSAFE_disableFocus,
          size,
          showEditIcon,
          tooltip,
          ...props
        } = avatarItem;
        const passthroughAvatarProps = {
          variant,
          initials,
          name,
          image,
          status,
          showBorder,
          tooltip,
        };

        const isInteractive = as === "button" || as === "a";
        const RootComponent = (as ?? "div") as any;

        let statusText: string | undefined = status;
        if (status === "waiting") {
          statusText = "pending";
        }

        return (
          <RootComponent
            aria-label={name}
            data-atlas-wrapper="OptionItem"
            {...props}
          >
            <OptionItem size="compact" disableInteractivity={!isInteractive}>
              <div className={el`overflow-item-content-wrapper`}>
                <Avatar
                  {...passthroughAvatarProps}
                  disableTooltip
                  size="xs"
                  UNSAFE_disableFocus
                />
                <span className={el`overflow-item-label`}>
                  {tooltip ?? `${name}${statusText ? ` (${statusText})` : ""}`}
                </span>
              </div>
            </OptionItem>
          </RootComponent>
        );
      }),
    [items]
  );

  return (
    <Hovercard.Root state={hovercard}>
      <Hovercard.Anchor>
        <button
          aria-label={`${items.length} more ${
            items.length > 1 ? "items" : "item"
          }`}
          aria-expanded={hovercard.open ? "true" : "false"}
          aria-controls={hovercard.contentElement?.id}
          type="button"
          className={clsx(el`overflow-indicator`)}
          onClick={hovercard.toggle}
        >
          <span>{text}</span>
        </button>
      </Hovercard.Anchor>
      <Hovercard.Content
        portal
        portalElement={portalElement}
        hasPadding={false}
      >
        <div className={el`overflow-content`}>{renderedOverflowItems}</div>
      </Hovercard.Content>
    </Hovercard.Root>
  );
}

// avatar group
// ------------

/** Render visible avatars directly, and the rest in the overflow indicator. */
function useRenderItems({
  items,
  max,
  size,
  portalElement,
}: {
  items: AtlasAvatarGroupItem[];
  max: NonNullable<AtlasAvatarGroupProps["max"]>;
  size: NonNullable<AtlasAvatarGroupProps["size"]>;
  portalElement: HTMLDivElement | null;
}) {
  return useMemo(() => {
    const itemsCopy = [...items];
    const isOverflowing = itemsCopy.length > max;

    const visible = isOverflowing ? itemsCopy.slice(0, max - 1) : itemsCopy;
    const overflow = isOverflowing ? itemsCopy.slice(Math.max(0, max - 1)) : [];

    const visibleAvatars = visible.map((item) => {
      // eslint-disable-next-line no-underscore-dangle
      if (item._type === "avatar") {
        const { _type, contentClassName, ...avatarProps } = item;

        return (
          <Avatar
            {...avatarProps}
            size={avatarProps.size ?? size}
            className={clsx(el`avatar`, contentClassName)}
          />
        );
      }

      return (
        <OptionalTooltip content={item.label} isInstant UNSAFE_mode="slot">
          <div className="border border-dashed border-gray-500 w-6 h-6 rounded-full flex justify-center items-center text-gray-500">
            <Icon content={atlasPerson} className="w-4 h-4" />
          </div>
        </OptionalTooltip>
      );
    });

    const overflowIndicator = overflow.length ? (
      <OverflowIndicator items={overflow} portalElement={portalElement} />
    ) : null;

    return { visibleAvatars, overflowIndicator };
  }, [items, max, size, portalElement]);
}

/**
 * A group of horizontally stacked avatars. If there are too many of them (by default,
 * the limit is 4), it will show the "overflow indicator", which indicates how many extra
 * avatars are present in the group, and displays them in a popover when opened.
 */
export const AvatarGroup = createComponent<AtlasAvatarGroupComponent>((p0) => {
  const [items, p1] = usePCollection(p0, "useAvatarGroupItems");
  const { size = DEFAULT_PROPS.size, max = DEFAULT_PROPS.max, ...props } = p1;

  const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(
    null
  );

  const { visibleAvatars, overflowIndicator } = useRenderItems({
    items,
    max,
    size,
    portalElement,
  });

  return (
    <div {...props} className={clsx(`${ROOT} size-${size}`, props.className)}>
      <div className={el`wrapper`}>
        {visibleAvatars}
        {overflowIndicator}
      </div>
      <div ref={setPortalElement} />
    </div>
  );
});

// TODO: left/right arrow keys to move focus
