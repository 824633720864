import {
  InterviewerResponse,
  ScheduledInterviewInterviewerSwapReason,
  ScheduledInterviewRescheduleReason,
} from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { useMemo } from "react";

import { CancellationReasonFormData } from "./useReportCancellationReasonState";
import { ReportInterviewerSwapReasonFormData } from "./useReportInterviewerSwapReasonState";
import { RescheduleReasonFormData } from "./useReportRescheduleReasonState";

type Interviewer = {
  userMembershipId: string;
  responseStatus?: InterviewerResponse | null;
};

const getUniqueDeclinedInterviewers = (interviewers: Interviewer[]) => {
  return _(interviewers)
    .filter({ responseStatus: InterviewerResponse.DECLINED })
    .uniqBy("userMembershipId")
    .map((i) => ({
      interviewerUserMembershipId: i.userMembershipId,
    }))
    .value();
};

export const useCancellationReasonDefaultValues = ({
  originalInterviewers,
}: {
  originalInterviewers: Interviewer[];
}): CancellationReasonFormData => {
  return useMemo(
    () => ({
      needsRescheduled: true,
      rescheduleData: {
        reason: ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED,
        interviewerDeclines:
          getUniqueDeclinedInterviewers(originalInterviewers),
      },
    }),
    [originalInterviewers]
  );
};

export const useRescheduleReasonDefaultValues = ({
  originalInterviewers,
}: {
  originalInterviewers: Interviewer[];
}): RescheduleReasonFormData | undefined => {
  return useMemo(
    () => ({
      reason: ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED,
      interviewerDeclines: getUniqueDeclinedInterviewers(originalInterviewers),
    }),
    [originalInterviewers]
  );
};

export const useInterviewerSwapReasonDefaultValues = ({
  originalInterviewers,
}: {
  originalInterviewers: Interviewer[];
}): ReportInterviewerSwapReasonFormData => {
  return useMemo(
    () => ({
      reason: ScheduledInterviewInterviewerSwapReason.INTERVIEWER_DECLINED,
      interviewerDeclines: getUniqueDeclinedInterviewers(originalInterviewers),
    }),
    [originalInterviewers]
  );
};
