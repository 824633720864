import { Avatar } from "@resource/atlas/avatar/Avatar";
import Badge from "@resource/atlas/badge/Badge";
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleChecked, atlasRing } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  GuideManagerGuideV2Fragment,
  InterviewPlanItemFragment,
} from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { WithoutTypename } from "utils/types";
import useMutation from "utils/useMutation";

export type InterviewPlanItem = WithoutTypename<InterviewPlanItemFragment>;

type InterviewPlanItemState = "completed" | "current" | "locked";

type InterviewPlanSelectItemProps = ComponentPropsWithoutRef<"li"> & {
  interviewPlanItem: InterviewPlanItem;
  state: InterviewPlanItemState;
  candidate?: {
    name: string;
    imageUrl?: string;
  };
  position: number;
  hideInfoIcon?: boolean;
};

export function InterviewPlanSelectItem({
  interviewPlanItem,
  state,
  candidate,
  position,
  ...props
}: InterviewPlanSelectItemProps) {
  let indicator: ReactNode;
  // eslint-disable-next-line default-case
  switch (state) {
    case "completed":
      indicator = (
        <Icon content={atlasCircleChecked} className="text-success-500" />
      );
      break;
    case "current":
      indicator = (
        <span className="rounded-full ml-[-0.25rem]">
          <Avatar
            name={candidate?.name}
            image={candidate?.imageUrl}
            size="xs"
            className="border-2 border-white"
          />
        </span>
      );
      break;
    case "locked":
      indicator = (
        <div className="w-5 h-5 pr-5 relative text-subtle inline">
          <Icon className="absolute inset-0" content={atlasRing} />
          <span className="absolute inset-0 text-body-sm text-[.625rem] text-center w-5 leading-5">
            {position}
          </span>
        </div>
      );
      break;
  }

  return (
    <li
      role="presentation"
      key={interviewPlanItem.id}
      className={clsx(
        "flex items-center w-full px-2 py-1.5 transition-colors rounded-md group"
      )}
      {...props}
    >
      {indicator}{" "}
      <div
        className={clsx("truncate text-body-md", {
          "ml-2": state !== "current",
          "ml-[0.3125em]": state === "current",
        })}
      >
        {interviewPlanItem.title}
      </div>
      {state === "current" && (
        <Badge className="ml-1" variant="success" size="small">
          Current
        </Badge>
      )}
    </li>
  );
}

type InterviewPlanSelectProps = {
  items: InterviewPlanItem[];
  guide: GuideManagerGuideV2Fragment;
  currentStage?: InterviewPlanItem;
  isLoading?: boolean;
  candidate?: {
    name: string;
    imageUrl?: string;
  };
};

const MOVE_GUIDE_TO_STAGE_MUTATION = gql(`
  mutation MoveGuideToStage($input: MoveGuideToStageInput!) {
    moveGuideToStage(input: $input) {
      code
      message
      success
      guide {
        id
        currentInterviewPlanItem {
          id
          title
        }
      }
    }
  }
`);

export function InterviewPlanSelect({
  candidate,
  items,
  currentStage,
  isLoading,
  guide,
}: InterviewPlanSelectProps) {
  const currentInterviewPlanItemIndex = items.findIndex(
    (item) => item.id === currentStage?.id
  );
  const [moveGuideToStage] = useMutation(MOVE_GUIDE_TO_STAGE_MUTATION);

  const stageSelectOptions = useMenuItems(
    (i) =>
      items.map((interviewPlanItem, index) => {
        let state: InterviewPlanItemState;
        if (
          currentInterviewPlanItemIndex &&
          index < currentInterviewPlanItemIndex
        ) {
          state = "completed";
        } else if (index === currentInterviewPlanItemIndex) {
          state = "current";
        } else {
          state = "locked";
        }

        return i.item({
          key: interviewPlanItem.id,
          value: interviewPlanItem.id,
          children: interviewPlanItem.title,
          className: "flex items-center !p-0",
          onClick: async () => {
            await moveGuideToStage({
              variables: {
                input: {
                  guideId: guide.id,
                  interviewPlanItemId: interviewPlanItem.id,
                },
              },
            });
          },
          renderContent: () => {
            return (
              <InterviewPlanSelectItem
                key={interviewPlanItem.id}
                interviewPlanItem={interviewPlanItem}
                position={index + 1}
                state={state}
                candidate={candidate}
              />
            );
          },
        });
      }),
    [
      candidate,
      currentInterviewPlanItemIndex,
      guide.id,
      items,
      moveGuideToStage,
    ]
  );

  return (
    <>
      <Menu.Root>
        <Menu.Trigger className="flex-grow  !bg-light-gray-500">
          <Button isGhost isDropdown className="flex justify-center">
            {currentStage?.title
              ? `${currentStage.title} (${
                  currentInterviewPlanItemIndex + 1
                } of ${items.length})`
              : "No current stage"}
          </Button>
        </Menu.Trigger>
        <Menu.Content
          portal
          isLoading={isLoading}
          items={stageSelectOptions}
          sameWidth
        />
      </Menu.Root>
    </>
  );
}
