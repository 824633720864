import { ScheduledInterviewOtherReason } from "shared/reporting/scheduled-interviews/types";

import { OtherReasonSelect } from "./OtherReasonSelect";

type OtherReasonCollectionAdditionalInfoProps = {
  reason: ScheduledInterviewOtherReason | null | undefined;
  onChangeReason: (value: ScheduledInterviewOtherReason) => void;
};

/**
 * Additional info component when OTHER option is selected
 * Allows choosing other reason
 */
export function OtherReasonCollectionAdditionalInfo({
  reason,
  onChangeReason,
}: OtherReasonCollectionAdditionalInfoProps) {
  return (
    <div className="space-y-2">
      <OtherReasonSelect value={reason ?? null} onChange={onChangeReason} />
    </div>
  );
}
