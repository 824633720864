import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarTimesReceived } from "@resource/atlas/icons";
import { strings } from "@resource/common";
import clsx from "clsx";
import { AvailabilityRequestPreviewData } from "shared/guide-availability/types";

// component
// ---------

type ViewAvailabilityRequestProps = {
  className?: string;
  data: AvailabilityRequestPreviewData;
};

export function ViewAvailabilityRequestPreview({
  className,
  data,
}: ViewAvailabilityRequestProps) {
  const { createdBy } = data;

  return (
    <div
      className={clsx(
        "text-body-sm space-y-3 w-full shadow-1 rounded-md border-light-gray p-4",
        "opacity-70 pointer-events-none",
        className
      )}
    >
      <div className="space-y-2">
        <div className="flex">
          <div className="w-10 h-10 bg-blue-50 flex items-center justify-center rounded-full">
            <Icon
              content={atlasCalendarTimesReceived}
              className="w-5 h-5 text-blue-500"
            />
          </div>
          <Avatar
            name={createdBy.name}
            image={createdBy.imageUrl}
            size="medium"
            className="ring-2 ring-white"
            style={{
              marginLeft: "-.25rem",
            }}
          />
        </div>
        <div className="space-y-1">
          <p className="text-body-md-heavy text-dark">
            {createdBy.name} is asking to see your availability
          </p>
          <p className="text-body-sm text-subtle">
            Let {strings.splitName(createdBy.name).firstName} know when
            you&apos;re free to meet. This will help them find the best time for
            your upcoming interviews.
          </p>
        </div>
      </div>
      <Button variant="primary">Share your availability</Button>
    </div>
  );
}
