import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  Dialog,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { AtlasMenuItemProps } from "@resource/atlas/menu";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { InterviewCalendarDisplay } from "client/app/(account settings)/company/__components/InterviewCalendarDisplay";
// TOOD: Move these editors out of scheduler
import { gql } from "generated/graphql-codegen";
import { ScheduledInterviewForTransferGoogleEventFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

const TRANSFER_CALENDAR_EVENT = gql(`
  mutation TransferCalendarEvent($input: TransferScheduleInterviewCalendarInput!) {
    transferScheduleInterviewCalendar(input: $input) {
      code
      success
      message
      scheduledInterviews {
        id
      }
    }
  }
`);

gql(`
  fragment ScheduledInterviewForTransferGoogleEvent on ScheduledInterview {
    id
    googleEvent {
      id
      htmlLink
      calendar {
        calendarId
        name
      }
    }
  }
`);

const FETCH_DEFAULT_DATA = gql(`
  query FetchDataForTransferGoogleEventQuery($guideId: String! $scheduledInterviewId: String!) {
    scheduledInterviewsById(guideId: $guideId, interviewIds: [$scheduledInterviewId]) {
      ...ScheduledInterviewForTransferGoogleEvent
    }
  }
`);

export type TransferGoogleEventDialogProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  scheduledInterviewId: string;
  // eslint-disable-next-line react/no-unused-prop-types
  guideId: string;
  store: DialogStore;
  onCompleted?: () => void;
};

export function TransferGoogleEventDialog(
  props: TransferGoogleEventDialogProps
) {
  const { store } = props;

  return (
    <Dialog store={store}>
      <TransferGoogleEventDialogQuery {...props} />
    </Dialog>
  );
}

function TransferGoogleEventDialogQuery(props: TransferGoogleEventDialogProps) {
  const { guideId, scheduledInterviewId, store } = props;
  const dialogState = store.useState();
  const { data, previousData } = useQuery(FETCH_DEFAULT_DATA, {
    variables: {
      guideId,
      scheduledInterviewId,
    },
    skip: !dialogState.open,
  });
  const actualData = data ?? previousData;
  const scheduledInterview = actualData?.scheduledInterviewsById[0];

  if (!scheduledInterview) {
    return (
      <View content={{ className: "justify-center" }}>
        <div className="w-full h-full items-center justify-center flex">
          <LoadingIndicator />
        </div>
      </View>
    );
  }

  return (
    <TransferGoogleEventDialogDisplay
      {...props}
      scheduledInterview={scheduledInterview}
    />
  );
}

type GoogleEventFormData = {
  calendarId: string;
};

function TransferGoogleEventDialogDisplay({
  scheduledInterview,
  store,
  onCompleted,
}: TransferGoogleEventDialogProps & {
  scheduledInterview: ScheduledInterviewForTransferGoogleEventFragment;
}) {
  const { user } = useAuthContext();
  const [transferGoogleEvent, { loading: submitLoading }] = useMutation(
    TRANSFER_CALENDAR_EVENT
  );
  const form = useForm<GoogleEventFormData>({
    defaultValues: {
      calendarId: scheduledInterview.googleEvent?.calendar?.calendarId ?? "",
    },
  });
  const { formState, handleSubmit, watch, setValue } = form;
  const tooltipContent = useMemo(() => {
    if (!formState.isDirty) {
      return "Select a different calendar to transfer the event to.";
    }

    if (!formState.isValid) {
      return "Please select a calendar to transfer the event to.";
    }

    return undefined;
  }, [formState.isDirty, formState.isValid]);

  const onSubmit = useCallback<SubmitHandler<GoogleEventFormData>>(
    (data) => {
      transferGoogleEvent({
        variables: {
          input: {
            newCalendarId: data.calendarId,
            scheduledInterviewId: scheduledInterview.id,
          },
        },
        onCompleted: () => {
          store.hide();

          if (onCompleted) {
            onCompleted();
          }
        },
      });
    },
    [transferGoogleEvent, scheduledInterview.id, store, onCompleted]
  );
  const onCancel = useCallback(() => {
    store.hide();
  }, [store]);

  if (!user?.currentUserMembership) {
    return <div>Must be logged in...</div>;
  }

  return (
    <View
      header={{
        title: "Transfer Google event",
      }}
      content={{
        className: "space-y-2",
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button isGhost onClick={onCancel}>
              Cancel
            </Button>
            <OptionalTooltip content={tooltipContent} isInstant>
              <Button
                variant="primary"
                disabled={!!tooltipContent}
                onClick={handleSubmit(onSubmit)}
                isLoading={submitLoading}
              >
                Transfer
              </Button>
            </OptionalTooltip>
          </ButtonGroup>
        ),
      }}
    >
      <div className="text-body-md">
        <span>
          Current calendar:{" "}
          <span className="text-body-md-heavy">
            {scheduledInterview.googleEvent?.calendar?.name ?? "Unknown"}
          </span>
        </span>
      </div>
      <InterviewCalendarDisplay
        userMembershipId={user.currentUserMembership.id}
        selectedInterviewCalendarId={watch("calendarId")}
        setInterviewCalendar={(calendarId) =>
          setValue("calendarId", calendarId, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
      />
    </View>
  );
}

export function useTransferGoogleEventMenuItem({
  scheduledInterview,
}: {
  scheduledInterview:
    | {
        id: string;
        guideId: string | null;
        createdByGuide: boolean;
      }
    | undefined
    | null;
}) {
  const transferDialogStore = useDialogStore();

  return useMemo(
    (): {
      menuItem: AtlasMenuItemProps | null;
      Component: JSX.Element | null;
    } => ({
      menuItem: scheduledInterview
        ? {
            id: "transfer",
            children: "Transfer Google event to another calendar",
            disabled: !scheduledInterview.createdByGuide,
            onClick: () => {
              transferDialogStore.show();
            },
          }
        : null,
      Component:
        scheduledInterview && scheduledInterview.guideId ? (
          <TransferGoogleEventDialog
            scheduledInterviewId={scheduledInterview.id}
            guideId={scheduledInterview.guideId}
            store={transferDialogStore}
          />
        ) : null,
    }),
    [scheduledInterview, transferDialogStore]
  );
}
