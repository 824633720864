import { mapInterviewerPoolFragmentToInterviewerPool } from "client/components/interviewer-slots/utils/mapping";
import { InterviewerPool } from "client/components/interviewer-slots/utils/types";
import useStableTransformData from "client/hooks/useStableTransformData";
import { gql } from "generated/graphql-codegen";
import { InterviewerPoolForInterviewerSlotMappingFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useQuery from "utils/useQuery";

const FETCH_POOLS_FOR_SELECT_QUERY = gql(`
  query FetchPoolsForSelect(
    $search: String,
    $limit: Int!,
  )
  {
    currentOrganization {
      id
      interviewerPools(search: $search, limit: $limit) {
        ...InterviewerPoolForInterviewerSlotMapping
      }
    }
  }
`);

type UseSelectInterviewerPoolsParams = {
  search: string;
  skip?: boolean;
};

export default function useSelectInterviewerPools({
  search,
  skip = false,
}: UseSelectInterviewerPoolsParams) {
  const { data, loading } = useQuery(FETCH_POOLS_FOR_SELECT_QUERY, {
    variables: {
      search: search.trim() === "" ? null : search,
      limit: 3,
    },
    skip,
  });

  const { pools } = useMapPoolFragmentsForInterviewerSlot({
    poolFragments: data?.currentOrganization?.interviewerPools ?? [],
    loading,
  });

  return {
    pools,
    loading,
  };
}

type UseMapPoolFragmentsForInterviewerSlotParams = {
  poolFragments: InterviewerPoolForInterviewerSlotMappingFragment[];
  loading: boolean;
};
function useMapPoolFragmentsForInterviewerSlot({
  poolFragments,
  loading,
}: UseMapPoolFragmentsForInterviewerSlotParams) {
  const transformPools = useCallback(
    (
      p: InterviewerPoolForInterviewerSlotMappingFragment[]
    ): InterviewerPool[] => {
      return p.map((pool) => {
        return {
          ...mapInterviewerPoolFragmentToInterviewerPool(pool),
        };
      });
    },
    []
  );

  const { data: pools } = useStableTransformData({
    data: poolFragments,
    transform: transformPools,
    loading,
  });

  return { pools, loading };
}
