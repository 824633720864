/* eslint-disable no-underscore-dangle */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import clsx from "clsx";
import { $getNodeByKey, NodeKey } from "lexical";
import { useMemo, useState } from "react";

import {
  atlasCopy,
  atlasEllipsisHorizontal,
  atlasGrid,
  atlasRingPlus,
  atlasTrash,
} from "../../../../../icons/atlas";
import { useEvent, useStaticValue } from "../../../../__utils/react";
import { Button } from "../../../../button/Button";
import { Icon } from "../../../../icon/Icon";
import { Menu } from "../../../../menu";
import { useMenuItems } from "../../../../menu/use-menu-items";
import { ContentEditorPopover } from "../../../__utils/content-editor-popover";
import { INSERT_TARGETS } from "../../../__utils/insert-options-targets-order";
import { createRichBlock, RichBlockProps } from "../../../__utils/rich-blocks";
import { LinkCardPopover } from "../shared/LinkCardPopover";
import {
  CardData,
  useBaseLinkCardMenuItems,
  useLinkCardInsertOption,
} from "../shared/misc";
import { useLinkCardsCollectionConfig } from "./config";
import { LinkCardsCollection } from "./LinkCardsCollection";
import {
  DEFAULT_DATA,
  LinkCardsCollectionData,
  RICH_BLOCK_NAME,
} from "./shared";

// menu
// ----

// TODO: remove once this proposal lands: https://github.com/tc39/proposal-change-array-by-copy
function toSpliced<T>(
  array: T[],
  start: number,
  deleteCount: number,
  ...items: T[]
) {
  const copy = [...array];
  copy.splice(start, deleteCount, ...items);
  return copy;
}

type LinkCardMenuProps = {
  data: LinkCardsCollectionData;
  index: number;
  updateData: (data: LinkCardsCollectionData) => void;
  nodeKey: NodeKey;
};

function LinkCardMenu({
  data: { cards },
  index,
  updateData,
  nodeKey,
}: LinkCardMenuProps) {
  const { onReplaceImage, fetchUrlMetadata } = useLinkCardsCollectionConfig();

  const [editor] = useLexicalComposerContext();

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const getAnchorRect = useEvent(
    () => anchorElement?.getBoundingClientRect() ?? null
  );

  const [configOpen, setConfigOpen] = useState(false);

  const currentCard = cards[index];

  const baseItems = useBaseLinkCardMenuItems({
    onEdit: () => setConfigOpen(true),
    onReplaceImage: () =>
      onReplaceImage((imageUrl) =>
        updateData({
          cards: toSpliced(cards, index, 1, { ...currentCard, imageUrl }),
        })
      ),
  });

  const menuItems = useMenuItems(
    (i) => {
      // @ts-expect-error This is fine, TypeScript is just not that smart.
      const items = i(...baseItems.map((item) => i[item._type](item)));

      items.push(i.separator({ key: "separator" }));

      items.push(
        i.item({
          key: "duplicate",
          children: "Duplicate",
          leadingContent: <Icon content={atlasCopy} />,
          onClick() {
            updateData({
              cards: toSpliced(cards, index + 1, 0, { ...currentCard }),
            });
          },
        }),
        i.item({
          key: "delete",
          children: "Delete",
          leadingContent: <Icon content={atlasTrash} />,
          onClick() {
            const updatedCards = toSpliced(cards, index, 1);

            if (updatedCards.length < 1)
              // remove node if only card in collection is deleted
              editor.update(() => $getNodeByKey(nodeKey)?.remove());
            else updateData({ cards: updatedCards });
          },
        })
      );

      return items;
    },
    [baseItems, cards, currentCard, editor, index, nodeKey, updateData]
  );

  const onEdit = useEvent((updatedCard: CardData) => {
    updateData({
      cards: toSpliced(cards, index, 1, { ...currentCard, ...updatedCard }),
    });
    setConfigOpen(false);
  });

  const defaults = useMemo(
    () => ({ url: currentCard.url || "", label: currentCard.label }),
    [currentCard]
  );

  return (
    <>
      <Menu.Root>
        <Menu.Trigger>
          <Button
            className={clsx("cardDisplayMenuButton", {
              isPopoverOpen: configOpen,
            })}
            icon={atlasEllipsisHorizontal}
            size="xs"
            ref={setAnchorElement}
          />
        </Menu.Trigger>
        <Menu.Content portal items={menuItems} defaultSize="compact" />
      </Menu.Root>
      <LinkCardPopover
        open={configOpen}
        setOpen={setConfigOpen}
        getAnchorRect={getAnchorRect}
        fetchUrlMetadata={fetchUrlMetadata}
        defaults={defaults}
        onSubmit={onEdit}
        title="Edit link card"
        submitLabel="Save changes"
      />
    </>
  );
}

// rich block component
// --------------------

function LinkCardsCollectionComponent({
  data,
  updateData,
  ConfigArea,
  ConfigMenu,
  nodeKey,
}: RichBlockProps<LinkCardsCollectionData>) {
  const { fetchUrlMetadata } = useLinkCardsCollectionConfig();

  const configItems = useMenuItems(() => [], []);
  const [addCardOpen, setAddCardOpen] = useState(false);

  return (
    <>
      <ConfigMenu items={configItems} />
      <LinkCardPopover
        PopoverComponent={ContentEditorPopover}
        open={addCardOpen}
        setOpen={setAddCardOpen}
        trigger={
          <ConfigArea>
            <Button
              isGhost
              icon={atlasRingPlus}
              size="xs"
              onClick={() => setAddCardOpen(true)}
            />
          </ConfigArea>
        }
        fetchUrlMetadata={fetchUrlMetadata}
        onSubmit={(cardData) =>
          updateData({ cards: [...data.cards, cardData] })
        }
        title="Add a link card"
        submitLabel="Create"
      />
      <LinkCardsCollection
        data={data}
        renderMenu={({ cardIndex }) => (
          <LinkCardMenu
            data={data}
            index={cardIndex}
            updateData={updateData}
            nodeKey={nodeKey}
          />
        )}
      />
    </>
  );
}

// block
// -----

export const {
  $createLinkCardsCollectionNode,
  $isLinkCardsCollectionNode,
  INSERT_LINK_CARDS_COLLECTION_COMMAND,
  LinkCardsCollectionNode,
  LinkCardsCollectionPlugin,
  linkCardsCollectionModule,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => LinkCardsCollectionComponent,
  useInsertOption: ({ getAnchorRect }, { insert }) => {
    const { fetchUrlMetadata } = useLinkCardsCollectionConfig();
    return useLinkCardInsertOption({
      fetchUrlMetadata,
      getAnchorRect,
      onSubmit: useEvent((cardData) => insert({ cards: [cardData] })),
      label: "Cards",
      icon: atlasGrid,
      targets: useStaticValue({
        insert: INSERT_TARGETS.CARDS,
      }),
    });
  },
  useRender() {
    const { tmpRender } = useLinkCardsCollectionConfig();
    return tmpRender;
  },
});
