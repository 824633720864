import { Checkbox } from "client/components/generic/inputs/Checkbox";
import { useCallback } from "react";

export type NotifyInterviewersCheckboxProps = {
  notifyInterviewers: boolean;
  onChange: (notifyInterviewers: boolean) => void;
};

export function NotifyInterviewersCheckbox({
  notifyInterviewers,
  onChange,
}: NotifyInterviewersCheckboxProps) {
  const onClick = useCallback(() => {
    onChange(!notifyInterviewers);
  }, [onChange, notifyInterviewers]);
  return (
    <Checkbox
      checked={notifyInterviewers}
      onClick={onClick}
      label="Notify interviewers"
    />
  );
}
