export const CypressData = {
  candidateGuides: {
    candidateName: "candidate-name",
    jobName: "job-name",
    organizationName: "organization-name",
    home: {
      desktopHeader: "desktop-guide-header",
      mobileHeader: "mobile-guide-header",
      desktopHeaderInfo: "desktop-guide-header-info",
      mobileHeaderInfo: "mobile-guide-header-info",
    },
    messages: {
      title: "guide-message-title",
      content: "guide-message-content",
      desktopHeader: "desktop-guide-messages-header",
      mobileHeader: "mobile-guide-messages-header",
    },
    interviews: {
      desktopHeader: "desktop-guide-interviews-header",
      mobileHeader: "mobile-guide-interviews-header",
      scheduledInterviewCard: "candidate-guide-scheduled-interview-card",
    },
  },
  schedulingTasks: {
    header: "scheduling-tasks-header",
    table: {
      row: "scheduling-task-row",
    },
    unscheduledInterviews: {
      card: "scheduling-task-unscheduled-interview-card",
      scheduleInterviews: "scheduling-task-schedule-interviews-button",
    },
    scheduledInterviews: {
      card: "scheduling-task-scheduled-interview-card",
    },
  },
  postTemplates: {
    table: {
      row: "post-template-row",
    },
  },
  scheduler: {
    continueButton: "scheduler-continue-button",
    submitButton: "scheduler-submit-button",
    selectInterviewerOptionButton: "scheduler-select-interviewer-option-button",
  },
  schedulingCompletion: {
    headerTitle: "scheduling-completion-header-title",
    scheduledInterviewCard: "scheduling-completion-scheduled-interview-card",
  },
  calendar: {
    header: "calendar-header",
    dayHeader: "calendar-day-header",
    event: "calendar-event",
    eventTitle: "calendar-event-title",
    eventsView: "calendar-events-view",
    horizontalLine: "calendar-horizontal-line",
    verticalLine: "calendar-vertical-line",
    allDayEventsView: "calendar-all-day-events-view",
  },
  interviews: {
    scheduledInterviewTitle: "scheduled-interview-title",
    scheduledInterviewTime: "scheduled-interview-time",
  },
  editInterviewerSlotDisplay: {
    selectInterviewerOptionButton: (userMembershipId?: string) =>
      `edit-interviewer-slot-option-button${
        userMembershipId ? `-${userMembershipId}` : ""
      }`,
    swapButton: (userMembershipId?: string) =>
      `edit-interviewer-slot-swap-button${
        userMembershipId ? `-${userMembershipId}` : ""
      }`,
    alternateButton: (userMembershipId?: string) =>
      `edit-interviewer-slot-alternate-button${
        userMembershipId ? `-${userMembershipId}` : ""
      }`,
    menuButton: (userMembershipId?: string) =>
      `edit-interviewer-slot-menu-button${
        userMembershipId ? `-${userMembershipId}` : ""
      }`,
    enableTrainingSwitch: "edit-interviewer-slot-enable-training-switch",
    enableExternalAlgorithmSwitch:
      "edit-interviewer-slot-enable-external-algorithm-switch",
  },
} as const;
