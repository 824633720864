import { gql } from "generated/graphql-codegen";
import useMutation from "utils/useMutation";

const UPDATE_GUIDE_AVAILABILITY_REQUEST = gql(`
  mutation UpdateGuideAvailabilityRequest($input: UpdateGuideAvailabilityRequestInput!) {
    updateGuideAvailabilityRequest(input: $input) {
      success
      code
      message
      availabilityRequest {
        guideId
        guide {
          ...GuideForGlobalAvailabilityForCandidateProfile
        }
        ...AvailabilityRequestForManageAvailabilityRequest
      }
    }
  }
`);

export function useUpdateAvailabilityRequest() {
  return useMutation(UPDATE_GUIDE_AVAILABILITY_REQUEST);
}
