import { Dialog } from "@resource/atlas/dialog";
import type { AtlasDialogStateProps } from "@resource/atlas/dialog/types";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { useDialogViews } from "@resource/atlas/dialog/use-dialog-views";
import { gql } from "generated/graphql-codegen";
import {
  InterviewDetailsInterviewerFragment,
  InterviewDetailsInterviewFragment,
} from "generated/graphql-codegen/graphql";
import { useState } from "react";

import { CalendarIcon } from "../CalendarIcon";
import { InterviewerDetailsDialogContent } from "../InterviewerDetailsDialog";
import { InterviewTitle } from "../InterviewTitle/InterviewTitle";
import { useFormattedDateTimesForInterviewItem } from "../utils";
import { AttendeesSection } from "./AttendeesSection";
import { ConferencingSection } from "./ConferencingSection";

gql(`
fragment InterviewDetailsInterviewer on Interviewer {
  id
  ...InterviewDetailsAttendeesInterviewer
}
`);

const INTERVIEW_FRAGMENT = gql(`
fragment InterviewDetailsInterview on ScheduledInterview {
    id
    title
    startTime
    endTime
    interviewers {
      ...InterviewDetailsInterviewer
    }
    ...InterviewDetailsLocationInterview
  }
`);

type InterviewDetailsDialog = AtlasDialogStateProps & {
  interview: InterviewDetailsInterviewFragment;
};

export function InterviewDetailsDialog({
  interview,
  ...dialogProps
}: InterviewDetailsDialog) {
  const formattedDateTime = useFormattedDateTimesForInterviewItem({
    interview,
  });
  const [selectedInterviewer, setSelectedInterviewer] = useState<
    InterviewDetailsInterviewerFragment | undefined
  >();

  const dialogState = useDialogState({ ...dialogProps });

  const { dialogContentProps, setView } = useDialogViews(
    {
      interview: {
        view: () => (
          <Dialog.View
            header={{
              title: "",
            }}
          >
            <div className="space-y-6">
              <div className="flex items-center gap-4">
                <CalendarIcon
                  className="mt-px md:w-16 md:h-16 gap-0"
                  dayClassname="md:text-[1.5rem] leading-[1.875rem]"
                  dayNameClassname="md:text-[0.813rem] !leading-5"
                  day={formattedDateTime.day}
                  dayName={formattedDateTime.dayName}
                />
                <section>
                  <InterviewTitle interview={interview} className="text-h2" />
                  <p className="text-subtle text-body-md mt-1">
                    {formattedDateTime.formattedStartDate} •{" "}
                    {formattedDateTime.formattedTime}
                  </p>
                </section>
              </div>

              <ConferencingSection interview={interview} />
              {/* {interview.description && (
                <section>
                  <p className="text-body-md-heavy">Description</p>
                  <InterviewDescription
                    description={interview.description}
                    className="mt-2"
                  />
                </section>
              )} */}
              <AttendeesSection
                interviewers={interview.interviewers}
                onClickInterviewer={(interviewer) => {
                  setSelectedInterviewer(interviewer);
                  setView("interviewer");
                }}
              />
            </div>
          </Dialog.View>
        ),
      },
      interviewer: {
        backTarget: "interview",
        view: () => (
          <Dialog.View>
            {selectedInterviewer && (
              <InterviewerDetailsDialogContent
                interviewer={selectedInterviewer.userMembership}
              />
            )}
          </Dialog.View>
        ),
      },
    },
    { initialView: "interview" }
  );

  if (!interview) {
    return null;
  }
  return (
    <Dialog.Root state={dialogState}>
      <Dialog.Content size="medium" {...dialogContentProps} />
    </Dialog.Root>
  );
}

InterviewDetailsDialog.fragments = {
  interview: INTERVIEW_FRAGMENT,
};
