/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from "@resource/atlas/button/Button";
import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { Dialog } from "@resource/atlas/dialog";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { atlasGear } from "@resource/atlas/icons";
import clsx from "clsx";
import { useAtom } from "jotai";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  calendarIncludeWeekendsAtom,
  calendarTwentyFourHourFormatAtom,
} from "./settings";

type CalendarSettingsFormValues = {
  includeWeekends: boolean;
  twentyFourHourFormat: boolean;
};

export function CalendarSettings() {
  const state = useDialogState();
  const [includeWeekends, setIncludeWeekends] = useAtom(
    calendarIncludeWeekendsAtom
  );
  const [twentyFourHourFormat, setTwentyFourHourFormat] = useAtom(
    calendarTwentyFourHourFormatAtom
  );
  const { handleSubmit, reset, control } = useForm<CalendarSettingsFormValues>({
    defaultValues: {
      includeWeekends,
      twentyFourHourFormat,
    },
  });

  const onSubmit = useCallback(
    (data: CalendarSettingsFormValues) => {
      setIncludeWeekends(data.includeWeekends);
      setTwentyFourHourFormat(data.twentyFourHourFormat);
      state.hide();
    },
    [setIncludeWeekends, setTwentyFourHourFormat, state]
  );

  const onCancel = useCallback(() => {
    reset();
    state.hide();
  }, [reset, state]);

  return (
    <Dialog.Root state={state}>
      <Dialog.Trigger>
        <Button icon={atlasGear} isGhost />
      </Dialog.Trigger>
      <Dialog.Content
        header={{
          title: "Calendar Settings",
        }}
        footer={{
          rightActions: (
            <Button variant="primary" onClick={handleSubmit(onSubmit)}>
              Done
            </Button>
          ),
          leftActions: <Button onClick={onCancel}>Cancel</Button>,
        }}
      >
        <div className="space-y-4">
          <Controller
            render={({ field }) => (
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => field.onChange(!field.value)}
              >
                <Checkbox
                  name={field.name}
                  className="flex-shrink-0"
                  checked={field.value}
                />
                <p className={clsx("text-body-md")}>Show weekends</p>
              </div>
            )}
            control={control}
            name="includeWeekends"
          />
          <Controller
            render={({ field }) => (
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => field.onChange(!field.value)}
              >
                <Checkbox
                  name={field.name}
                  className="flex-shrink-0"
                  checked={field.value}
                />
                <p className={clsx("text-body-md")}>24 hour format</p>
              </div>
            )}
            control={control}
            name="twentyFourHourFormat"
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
