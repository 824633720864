import { InterviewTitleEditor } from "client/components/interviews/InterviewTitleEditor";

import { EditInterviewState } from "../__hooks/useUpdateInterviewState";

export function EditInterviewTitle({ state }: { state: EditInterviewState }) {
  const { onChangeTitle, title } = state;

  return (
    <div className="space-y-1">
      <span className="text-body-sm-heavy">Candidate-facing title</span>
      <InterviewTitleEditor
        valueSet={{}}
        placeholder="Reset to default value"
        initialState={title}
        onChange={(editorState) => {
          onChangeTitle(JSON.stringify(editorState.toJSON()));
        }}
      />
    </div>
  );
}
