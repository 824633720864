import { atlasLogoGuideColor } from "@resource/atlas/icons";
import { INTERVIEW_WHITELIST_ICON } from "client/utils/interview-whitelist";
import { InterviewerResponse } from "shared/guide-scheduler/types";

import {
  FetchedInterviewerCalendarEventWithCalendarId,
  InterviewerCalendarEvent,
} from "./types";

export const mapFetchedEventToInterviewerCalendarEvent = (
  event: FetchedInterviewerCalendarEventWithCalendarId,
  { layer }: { layer?: string } = {}
): InterviewerCalendarEvent => {
  let icon;

  if (event.guideScheduledInterview) {
    icon = atlasLogoGuideColor;
  } else if (event.isWhitelisted) {
    icon = { text: INTERVIEW_WHITELIST_ICON };
  }

  return {
    id: event.iCalUID,
    calendarId: event.calendarId,
    title: event.guideScheduledInterview?.title ?? event.title,
    icon,
    responseStatus: event.responseStatus,
    start: event.start,
    end: event.end,
    outOfOffice: event.outOfOffice,
    isWhitelisted: event.isWhitelisted,
    isTransparent: event.isTransparent,
    isPrivate: event.visibility === "private",
    layer,
    attendees: event.attendees?.map(
      (a): NonNullable<InterviewerCalendarEvent["attendees"]>[number] => ({
        name: a.name,
        email: a.email,
        // Google does not provide imageUrls
        imageUrl: null,
        organizer: a.organizer ?? false,
        optional: a.optional ?? false,
        resource: a.resource ?? false,
        responseStatus: a.responseStatus as InterviewerResponse,
      })
    ),
    creator: event.creator
      ? {
          name: event.creator.name ?? null,
          email: event.creator.email ?? null,
        }
      : undefined,
    organizer: event.organizer
      ? {
          name: event.organizer.name ?? null,
          email: event.organizer.email ?? null,
        }
      : undefined,
    guideScheduledInterview: event.guideScheduledInterview,
  };
};
