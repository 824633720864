import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { useToast } from "@resource/atlas/toast/use-toast";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { SchedulingOmniButton } from "client/components/SchedulingOmniButton";
import { useReschedulableInterviews } from "client/hooks/useReschedulableInterviews";
import { MessageOmniButton } from "client/message-composer/__components/MessageOmniButton";
import GuidePreviewButton from "components/GuidePreviewButton";
import TrackedButton from "components/TrackedButton";
import copy from "copy-to-clipboard";
import { gql } from "generated/graphql-codegen";
import {
  ExtensionActionsGuideFragment,
  OrganizationFeaturesEnum,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useMemo } from "react";
import useMutation from "utils/useMutation";

const TRACK_LINK_CLICKED_MUTATION = gql(`
  mutation UpdateGuideLastCopyLinkClickedAt($input: TrackCopyLinkClickedInput!) {
    trackCopyLinkClicked(input: $input) {
      success
      guide {
        id
        lastCopyLinkClickedAt
      }
    }
  }
`);

gql(`
fragment ExtensionActionsGuide on Guide {
    id
    status
    views {
      id
      eventTime
    }
    publicUrl
    candidate {
      id
      firstName: internalFirstName
      organization {
        id
        features
      }
    }
    job {
      id
      jobInterviews {
        ...JobInterviewForSelfScheduleSelection
      }
    }
    internalUpcomingInterviews {
      id
      interviewRequirementId
    }
  }
`);

interface GuideActionsProps {
  guide: ExtensionActionsGuideFragment;
}

export function ExtensionActions({ guide }: GuideActionsProps) {
  const logEvent = useLogEvent({
    component: "AresGuideActions",
  });
  const { sendToast } = useToast();
  const [trackClick] = useMutation(TRACK_LINK_CLICKED_MUTATION);

  const upcomingInterviews = useReschedulableInterviews({
    guideId: guide.id,
  });
  const { hasFeatureEnabled } = useAuthContext();
  const isSchedulingEnabled = useMemo(
    () => hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING),
    [hasFeatureEnabled]
  );

  let PrimaryButton = (
    <TrackedButton
      disabled={!guide}
      onClick={() => {
        logEvent("Guide URL Copied");
        sendToast("We've copied the Guide URL to your clipboard.", {
          variant: "success",
        });
        trackClick({
          variables: {
            input: {
              guideId: guide.id,
              copyLinkClickedAt: new Date().toISOString(),
            },
          },
        });
        copy(guide.publicUrl);
      }}
      tracking={{ name: "Copy Public Guide URL Clicked" }}
      variant="primary"
      className="w-full"
    >
      Copy portal link
    </TrackedButton>
  );

  if (isSchedulingEnabled) {
    PrimaryButton = (
      <SchedulingOmniButton
        guideId={guide.id}
        jobId={guide.job.id}
        canReschedule={upcomingInterviews.length > 0}
        shouldReschedule={
          upcomingInterviews.filter(
            (interview) =>
              +DateTime.fromISO(interview.startTime) > +DateTime.now()
          ).length > 0
        }
        selfScheduleInterviews={guide.job.jobInterviews}
      />
    );
  }

  return (
    <div className="my-4 space-y-4">
      <ButtonGroup className="w-full">
        <div className="flex-grow w-[50%]">{PrimaryButton}</div>
        <div className="flex-grow w-[50%]">
          {isSchedulingEnabled ? (
            <MessageOmniButton guideId={guide.id} />
          ) : (
            <GuidePreviewButton guide={guide} className="w-full" />
          )}
        </div>
      </ButtonGroup>
    </div>
  );
}
