import { Grid } from "@giphy/react-components";
import { ContentEditorPopover } from "@resource/atlas/content-editor/__utils/content-editor-popover";
import { INSERT_TARGETS } from "@resource/atlas/content-editor/__utils/insert-options-targets-order";
import {
  createRichBlock,
  RichBlockProps,
} from "@resource/atlas/content-editor/__utils/rich-blocks";
import { atlasGif, atlasSearch } from "@resource/atlas/icons";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import TextField from "@resource/atlas/textfield/TextField";
import { useAsyncDebounce } from "client/utils/useAsyncDebounce";
import { useMemo, useRef, useState } from "react";
import { useEventCallback } from "react-hooks/useEventCallback";
import {
  DEFAULT_DATA,
  GifData,
  giphyFetch,
  RICH_BLOCK_NAME,
} from "shared/guide-content/rich-blocks/gif";

import { Gif } from "./Gif";

type GifComponentProps = RichBlockProps<GifData>;
function GifComponent({ data, ConfigMenu }: GifComponentProps) {
  return (
    <>
      <ConfigMenu items={[]} />
      <Gif giphyId={data.giphyId} />
    </>
  );
}

export const {
  $createGifNode,
  $isGifNode,
  GifNode,
  GifPlugin,
  gifModule,
  INSERT_GIF_COMMAND,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => GifComponent,
  useInsertOption: ({ getAnchorRect }, { insert }) => {
    const [addOpen, setAddOpen] = useState(false);
    const [rawSearch, setRawSearch] = useState("");
    const [search, setSearch] = useState("");
    const setAsyncSearch = useAsyncDebounce(setSearch, 300);

    const inputRef = useRef<HTMLInputElement>(null);

    const popoverProps = usePopoverState();

    const addPopover = useMemo(() => {
      const fetchGifs = (offset: number) => {
        if (!search) {
          return giphyFetch.trending({ offset, limit: 10 });
        }

        return giphyFetch.search(search, { offset, limit: 10 });
      };

      return (
        <ContentEditorPopover
          {...popoverProps}
          open={addOpen}
          setOpen={setAddOpen}
          getAnchorRect={getAnchorRect}
          title="Add a GIF"
          initialFocusRef={inputRef}
          className="min-h-[18rem]"
          description={
            <TextField
              icon={atlasSearch}
              inputRef={inputRef}
              autoFocus
              className="mt-3 -mb-3 -mx-3"
              placeholder="Search GIPHY"
              aria-label="Search GIPHY"
              value={rawSearch}
              onChange={(value) => {
                setRawSearch(value);
                setAsyncSearch(value);
              }}
            />
          }
        >
          <div className="overflow-scroll max-h-72">
            <Grid
              key={search}
              fetchGifs={fetchGifs}
              onGifClick={(gif, e) => {
                e.preventDefault();
                insert({ giphyId: gif.id.toString(), embedUrl: gif.embed_url });
                setAddOpen(false);
              }}
              width={320}
              columns={2}
              gutter={6}
            />
          </div>
        </ContentEditorPopover>
      );
    }, [
      addOpen,
      getAnchorRect,
      insert,
      popoverProps,
      rawSearch,
      search,
      setAsyncSearch,
    ]);

    const toggleAddPopover = useEventCallback(() =>
      setAddOpen((current) => !current)
    );

    return useMemo(
      () => ({
        label: "GIF",
        icon: atlasGif,
        active: addOpen,
        targets: {
          insert: INSERT_TARGETS.GIF,
          fixedToolbar: { slot: "gif" },
        },
        render: addPopover,
        onTrigger: toggleAddPopover,
      }),
      [addOpen, addPopover, toggleAddPopover]
    );
  },
});
