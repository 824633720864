import { TabStoreState } from "@ariakit/react";
import { useTrackPage } from "analytics";
import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import { useEffect } from "react";
import { trackProperties } from "shared/analytics";

import { ExtensionTabs } from "./useExtensionTabStore";

export function useExtensionAnalyticsPaging({
  tabState,
}: {
  tabState: TabStoreState;
}) {
  const { panelOpenState } = useExtensionController();
  const logPage = useTrackPage();

  useEffect(() => {
    // Don't log page events if the extension is not open
    if (!panelOpenState) {
      return;
    }

    if (tabState.selectedId === ExtensionTabs.schedulingTasks) {
      logPage(
        "Extension Scheduling Requests Tab",
        trackProperties.isCustomerSuccess
      );
    } else {
      logPage(
        "Extension Candidate Management Tab",
        trackProperties.isCustomerSuccess
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelOpenState]);
}
