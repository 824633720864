import { z } from "zod";

export enum TimeAvailability {
  FREE = 1,
  WHITELISTED,
  BUSY,
}

export const SlotPropertiesSchema = z.object({
  timeAvailability: z.nativeEnum(TimeAvailability),
  duringWorkingHours: z.boolean(),
  outsideLunchHour: z.boolean(),
  meetingWithLessThanThree: z.boolean(),
  dailyInterviewerLoad: z.number(),
  weeklyInterviewerLoad: z.number(),
  interviewerLoadMet: z.boolean(),
  dailyInterviewerPoolLoad: z.number(),
  weeklyInterviewerPoolLoad: z.number(),
  monthlyInterviewerPoolLoad: z.number(),
  overallInterviewerPoolLoad: z.number(),
  interviewerPoolLoadMet: z.boolean(),
  ignoreAccountLoadLimits: z.boolean(),
});

export type SlotProperties = z.infer<typeof SlotPropertiesSchema>;

export const InterviewerWithScoreSchema = z.object({
  userMembershipId: z.string(),
  score: z.number(),
  properties: SlotPropertiesSchema.nullable(),
});

export type InterviewerWithScore = z.infer<typeof InterviewerWithScoreSchema>;

const InterviewRequirementIdWithInterviewerWithScoreSchema = z.object({
  slotId: z.string(),
  interviewer: InterviewerWithScoreSchema,
});

const InterviewRequirementIdWithInterviewerWithScoresListSchema = z.object({
  slotId: z.string(),
  interviewers: z.array(InterviewerWithScoreSchema),
});

export const ProposedInterviewSchema = z.object({
  id: z.string(),
  durationInMinutes: z.number(),
  time: z.string(),
  score: z.number(),
  interviewers: z.array(InterviewRequirementIdWithInterviewerWithScoreSchema),
  shadowers: z.array(InterviewRequirementIdWithInterviewerWithScoreSchema),
  alternateInterviewers: z.array(
    InterviewRequirementIdWithInterviewerWithScoresListSchema
  ),
  alternateShadowers: z.array(
    InterviewRequirementIdWithInterviewerWithScoresListSchema
  ),
});

export type ProposedInterview = z.infer<typeof ProposedInterviewSchema>;
export type AlgorithmProposal = ProposedInterview[];
