import { Button } from "@resource/atlas/button/Button";
import { atlasCopy, atlasPlus, atlasTrash } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { TimeSelect } from "client/components/display/times/TimeSelect";
import { Dictionary, groupBy } from "lodash";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import { TimeRange } from "shared/guide-availability/legacy/types";
import { v4 as uuid } from "uuid";

const DateGroupFormat = "EEEE, LLLL d, yyyy";

type TimeRangesPickerProps = {
  availabilities: TimeRange[];
  setAvailabilities: (availabilities: TimeRange[]) => void;
};

type DayHeaderProps = {
  dayFormatted: string;
  rangesGroupedByDay: Dictionary<
    {
      formattedDay: string;
      id: string;
      start: DateTime;
      end: DateTime;
      error?: string | null | undefined;
    }[]
  >;
  availabilities: TimeRange[];
  setAvailabilities: (availabilities: TimeRange[]) => void;
};

function DayHeader({
  dayFormatted,
  rangesGroupedByDay,
  availabilities,
  setAvailabilities,
}: DayHeaderProps) {
  const earliestStartDate = rangesGroupedByDay[dayFormatted]
    .map((a) => a.start)
    .reduce((latest, current) => (current < latest ? current : latest));
  const furthestEndDate = rangesGroupedByDay[dayFormatted]
    .map((r) => r.end)
    .reduce((latest, current) => (current > latest ? current : latest));

  const copyMenuItems = useMenuItems(
    (i) => [
      i.item({
        key: "copy-to-all-days",
        children: "Copy to all days",
        disabled: Object.keys(rangesGroupedByDay).length < 2,
        onClick: () => {
          const todaysTimes = rangesGroupedByDay[dayFormatted];
          const todayDate = DateTime.fromFormat(dayFormatted, DateGroupFormat);

          const newAvailabilities: TimeRange[] = Object.keys(
            rangesGroupedByDay
          ).flatMap((day) => {
            const date = DateTime.fromFormat(day, DateGroupFormat);
            const diff = date.diff(todayDate, "days").days;

            return todaysTimes.map((time) => {
              return {
                id: uuid(),
                formattedDay: day,
                start: time.start.plus({ days: diff }),
                end: time.end.plus({ days: diff }),
              };
            });
          });
          setAvailabilities(newAvailabilities);
        },
      }),
    ],
    [dayFormatted, rangesGroupedByDay, setAvailabilities]
  );

  return (
    <div className="flex justify-between items-center">
      <p className="text-body-md-heavy">{dayFormatted}</p>
      <div className="flex">
        <Menu.Root>
          <Menu.Trigger>
            <Button isGhost icon={atlasCopy} />
          </Menu.Trigger>
          <Menu.Content portal items={copyMenuItems} defaultSize="compact" />
        </Menu.Root>
        <Button
          isGhost
          icon={atlasPlus}
          size="small"
          onClick={() => {
            let newRange;
            if (furthestEndDate.hour < 23) {
              newRange = {
                id: uuid(),
                start: furthestEndDate.plus({ minutes: 30 }),
                end: furthestEndDate.plus({ minutes: 90 }),
              };
            } else if (earliestStartDate.hour > 1) {
              newRange = {
                id: uuid(),
                start: earliestStartDate.minus({ minutes: 90 }),
                end: earliestStartDate.minus({ minutes: 30 }),
              };
            } else {
              const startOfDay = DateTime.now()
                .set({
                  day: earliestStartDate.day,
                })
                .startOf("day");

              newRange = {
                id: uuid(),
                start: startOfDay.set({
                  hour: 9,
                }),
                end: startOfDay.set({
                  hour: 17,
                }),
              };
            }
            setAvailabilities([...availabilities, newRange]);
          }}
        />
      </div>
    </div>
  );
}

export function TimeRangesPicker({
  availabilities,
  setAvailabilities,
}: TimeRangesPickerProps) {
  const rangesGroupedByDay = useMemo(() => {
    const rangesWithDayFormatted = availabilities
      .sort((a, b) => a.start.toMillis() - b.start.toMillis())
      .map((range) => {
        const formattedDay = range.start.toFormat(DateGroupFormat);

        return {
          ...range,
          formattedDay,
        };
      });

    return groupBy(rangesWithDayFormatted, "formattedDay");
  }, [availabilities]);

  return (
    <div className="space-y-6 w-full">
      {Object.keys(rangesGroupedByDay).map((dayFormatted) => {
        return (
          <div key={dayFormatted} className="space-y-1 w-full">
            <DayHeader
              dayFormatted={dayFormatted}
              rangesGroupedByDay={rangesGroupedByDay}
              availabilities={availabilities}
              setAvailabilities={setAvailabilities}
            />

            {rangesGroupedByDay[dayFormatted].map((range) => {
              const timeValueForStart = {
                hour: range.start.hour,
                minute: range.start.minute,
              };
              const timeValueForEnd = {
                hour: range.end.hour,
                minute: range.end.minute,
              };

              return (
                <div key={`${range.start}-${range.end}`} className="space-y-1">
                  <div className="flex items-center gap-2 w-full">
                    <TimeSelect
                      className="flex-1 justify-start"
                      value={timeValueForStart}
                      onChange={(val) => {
                        setAvailabilities(
                          availabilities.map((a) => {
                            const isCurrValue = a.id === range.id;
                            return isCurrValue
                              ? {
                                  ...a,
                                  start: a.end.set({
                                    hour: val.hour,
                                    minute: val.minute,
                                  }),
                                }
                              : a;
                          })
                        );
                      }}
                    />
                    <span className="text-subtle text-body-md">to</span>
                    <TimeSelect
                      className="flex-1 justify-start"
                      value={timeValueForEnd}
                      onChange={(val) => {
                        setAvailabilities(
                          availabilities.map((a) => {
                            const isCurrValue = a.id === range.id;
                            return isCurrValue
                              ? {
                                  ...a,
                                  end: a.end.set({
                                    hour: val.hour,
                                    minute: val.minute,
                                  }),
                                }
                              : a;
                          })
                        );
                      }}
                    />
                    <Button
                      isGhost
                      icon={atlasTrash}
                      onClick={() => {
                        setAvailabilities(
                          availabilities.filter((a) => {
                            return !(
                              a.start === range.start && a.end === range.end
                            );
                          })
                        );
                      }}
                    />
                  </div>
                  {range.error && (
                    <p className="text-body-sm text-red-500">{range.error}</p>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
