export const emailIcons = {
  emailBounced: "https://ucarecdn.com/335899aa-7089-4b90-8e4e-93bf29524d95/",
  calendarCheck: "https://ucarecdn.com/2dffa63d-4b4a-427b-81bd-aefe16b8853a/",
  calendarCross: "https://ucarecdn.com/6a8a2961-6327-40b6-b5cf-24f6305f8f2c/",
  calendarConflict:
    "https://ucarecdn.com/19f9c70d-6fc7-4337-8a36-317f8b5485cf/",
  circleCheck: "https://ucarecdn.com/91bda0bd-13ae-4bc6-b55f-d557679be9ef/",
  circleCross: "https://ucarecdn.com/bf5a7c6e-f9a8-4528-a1a0-ec58cf5fb4b4/",
  circleQuestion: "https://ucarecdn.com/861e50c3-54bf-4211-9062-13eb6fdd2250/",
  openExternal: "https://ucarecdn.com/0b42e243-4dac-4ad1-bb66-13e955fc4af8/",
  googleMeet: "https://ucarecdn.com/0a3e25d3-3782-4357-9805-9759257fc86b/",
  zoom: "https://ucarecdn.com/a8ce6ddb-8a7f-4570-92b8-6a5aa1fda2fd/",
  phone: "https://ucarecdn.com/411c17b3-2d1c-4783-99c8-a0c70cdeb978/",
};
