import { Tab, TabList, TabPanel, TabRoot } from "./Tabs";
import {
  type TabListProps,
  type TabPanelProps,
  type TabProps,
  type TabRootProps,
} from "./Tabs";
import {
  VerticalTab,
  VerticalTabList,
  VerticalTabPanel,
  VerticalTabRoot,
} from "./VerticalTabs";
import {
  type VerticalTabListProps,
  type VerticalTabPanelProps,
  type VerticalTabProps,
  type VerticalTabRootProps,
} from "./VerticalTabs";

export const Tabs = {
  Root: TabRoot,
  List: TabList,
  Panel: TabPanel,
  Tab,
};

export type {
  TabListProps as AtlasTabListProps,
  TabPanelProps as AtlasTabPanelProps,
  TabProps as AtlasTabProps,
  TabRootProps as AtlasTabRootProps,
};

export const VerticalTabs = {
  Root: VerticalTabRoot,
  List: VerticalTabList,
  Panel: VerticalTabPanel,
  Tab: VerticalTab,
};

export type {
  VerticalTabRootProps as AtlasVerticalTabRootProps,
  VerticalTabListProps as AtlasVerticalTabListProps,
  VerticalTabPanelProps as AtlasVerticalTabPanelProps,
  VerticalTabProps as AtlasVerticalTabProps,
};
