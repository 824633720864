import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import TextArea from "@resource/atlas/textfield/TextArea";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { gql } from "generated/graphql-codegen";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import useMutation from "utils/useMutation";

import { getDefaultCancellationMessage } from "./__utils/default-messaging";

const CANCEL_SELF_SCHEDULE_INTERVIEW = gql(`
  mutation CancelSelfScheduleInterview($input: CancelInterviewForSelfScheduleRequestInput!) {
    cancelInterviewForSelfScheduleRequest(input: $input) {
      code
      message
      success
      scheduledInterview {
        id
        ...ScheduledInterviewForScheduledInterviewCard
      }
      guidePost {
        id
      }
      selfScheduleRequest {
        ...SelfScheduleRequestForCreateOrUpdateSubmission
      }
    }
  }
`);

/** Dialog for confirming the cancellation of a self-scheduled interview */
export function CancelSelfScheduleInterviewConfirmationDialog({
  store,
  scheduledInterviewId,
  selfScheduleRequestId,
  startTime,
  interviewerName,
}: {
  store: DialogStore;
  scheduledInterviewId: string;
  startTime: string;
  selfScheduleRequestId: string;
  interviewerName?: string;
}) {
  const logEvent = useLogEvent({
    component: "CancelSelfScheduleInterviewConfirmationDialog",
  });
  const [cancelSelfScheduleInterview, { loading: submitLoading }] = useMutation(
    CANCEL_SELF_SCHEDULE_INTERVIEW,
    {
      onCompleted: (res) => {
        if (res.cancelInterviewForSelfScheduleRequest.success) {
          store.hide();
        }
      },
    }
  );
  const [message, setMessage] = useState(
    getDefaultCancellationMessage(DateTime.fromISO(startTime))
  );

  const onSubmit = useCallback(async () => {
    await cancelSelfScheduleInterview({
      variables: {
        input: {
          scheduledInterviewId,
          selfScheduleRequestId,
          message,
        },
      },
    });
    logEvent("Interview Cancelled", {
      isSelfScheduled: true,
    });
  }, [
    cancelSelfScheduleInterview,
    logEvent,
    message,
    scheduledInterviewId,
    selfScheduleRequestId,
  ]);

  const submitValidationContent = useMemo(() => {
    if (!message) {
      return "Please include a message";
    }
    return undefined;
  }, [message]);

  return (
    <Dialog store={store} size="medium">
      <View
        content={{
          className: "space-y-4",
        }}
        header={{
          title: `Send a cancellation email to ${
            interviewerName ?? "the interviewer(s)"
          }`,
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={store.hide}>
                No, don&apos;t cancel
              </Button>
              <OptionalTooltip content={submitValidationContent} isInstant>
                <Button
                  variant="danger"
                  onClick={onSubmit}
                  isLoading={submitLoading}
                  disabled={!!submitValidationContent}
                >
                  Cancel interview
                </Button>
              </OptionalTooltip>
            </ButtonGroup>
          ),
        }}
      >
        <div className="space-y-2">
          <div className="text-body-md-heavy">Message</div>
          <TextArea
            value={message}
            onChange={setMessage}
            className="h-60"
            inputClassName="h-60"
            placeholder="Add a message for the interviewer"
          />
        </div>
      </View>
    </Dialog>
  );
}
