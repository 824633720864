import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { EventInput } from "generated/graphql-codegen/graphql";
import { useCallback, useRef } from "react";
import useQuery from "utils/useQuery";

import { useUpdateAvailabilityRequest } from "../hooks/useUpdateAvailabilityRequest";
import {
  CreateOrUpdateAvailabilityRequestView,
  CreateOrUpdateAvailabilityRequestViewProps,
} from "./CreateOrUpdateAvailabilityRequestView";
import { CreateOrUpdateAvailabilityData } from "./CreateOrUpdateAvailabilityView";

export type CreateOrUpdateAvailabilityRequestContentProps = {
  availabilityRequestId?: string;
} & Pick<
  CreateOrUpdateAvailabilityRequestViewProps,
  "onSubmit" | "onCancel" | "stateProps" | "taskRequirements"
>;

const AVAILABILITY_REQUEST_FOR_CREATE_OR_UPDATE_MODAL = gql(`
  query AvailabilityRequestForCreateOrUpdateModal($availabilityRequestId: String!) {
    guideAvailabilityRequestById(id: $availabilityRequestId) {
      id
      guidePost {
        id
        guide {
          id
        }
      }
      notes
      suggestions {
        id
        title
        startTime
        endTime
      }
    }
  }
`);

export function CreateOrUpdateAvailabilityRequestContent(
  props: CreateOrUpdateAvailabilityRequestContentProps
) {
  const { availabilityRequestId } = props;
  const { data } = useQuery(AVAILABILITY_REQUEST_FOR_CREATE_OR_UPDATE_MODAL, {
    variables: {
      availabilityRequestId: availabilityRequestId || "",
    },
    skip: !availabilityRequestId,
  });
  const availabilityRequest = data?.guideAvailabilityRequestById;

  if (availabilityRequestId) {
    if (!availabilityRequest) {
      return <DialogLoading />;
    }

    return (
      <CreateOrUpdateAvailabilityRequestView
        {...props}
        isEdit
        stateProps={{
          defaultNotes: availabilityRequest.notes ?? "",
          defaultSelections: availabilityRequest.suggestions,
        }}
      />
    );
  }

  return <CreateOrUpdateAvailabilityRequestView {...props} />;
}

export type CreateOrUpdateAvailabilityRequestDialogProps = {
  availabilityRequestId?: string;
  store: DialogStore;
} & Pick<
  CreateOrUpdateAvailabilityRequestViewProps,
  "stateProps" | "taskRequirements"
>;

export function CreateOrUpdateAvailabilityRequestDialog(
  props: CreateOrUpdateAvailabilityRequestDialogProps
) {
  const { availabilityRequestId, store } = props;
  const hasSubmittedRef = useRef(false);
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
    hasSubmittedRef,
  });
  const [updateRequest] = useUpdateAvailabilityRequest();
  const onSubmit = useCallback(
    async (state: CreateOrUpdateAvailabilityData) => {
      if (availabilityRequestId) {
        await updateRequest({
          variables: {
            input: {
              id: availabilityRequestId,
              data: {
                suggestions: state.selections.map(
                  (s): EventInput => ({
                    title: s.title,
                    startTime: s.startTime,
                    endTime: s.endTime,
                  })
                ),
                notes: state.notes,
              },
            },
          },
        });
        hasSubmittedRef.current = true;

        return store.hide();
      }

      return store.hide();
    },
    [availabilityRequestId, store, updateRequest]
  );
  const onCancel = useCallback(() => {
    store.hide();
  }, [store]);

  return (
    <>
      <Dialog {...dialogProps} store={store} variant="fullscreen">
        <CreateOrUpdateAvailabilityRequestContent
          {...props}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Dialog>
      <WarningDialog />
    </>
  );
}
