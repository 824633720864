import { z } from "zod";

const AlgorithmNumberRangeSchema = z.object({
  min: z.number(),
  max: z.number(),
});

const AlgorithmMultiDaySettingsSchema = z.object({
  /* The number of days the scheduler will allow to be scheduled over */
  numberOfDays: AlgorithmNumberRangeSchema.optional(),
  /* The number of interviews the scheduler will allow to be scheduled per day */
  interviewsPerDayLimit: AlgorithmNumberRangeSchema.optional(),
  /* If true, the scheduler will reduce scores for options with any gaps between interviews. */
  preferBackToBackInterviews: z.boolean().optional(),
});

export const UserConfigurableAlgorithmSettingsSchema =
  AlgorithmMultiDaySettingsSchema;

export type UserConfigurableAlgorithmSettings = z.infer<
  typeof UserConfigurableAlgorithmSettingsSchema
>;

export const ValidatedUserConfigurableAlgorithmSettingsSchema =
  UserConfigurableAlgorithmSettingsSchema.extend({
    numberOfDays: AlgorithmNumberRangeSchema,
    interviewsPerDayLimit: AlgorithmNumberRangeSchema,
    preferBackToBackInterviews: z.boolean(),
  });

export type ValidatedUserConfigurableAlgorithmSettings = z.infer<
  typeof ValidatedUserConfigurableAlgorithmSettingsSchema
>;
