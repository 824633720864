import clsx from "clsx";

/**
 * A component for rendering text where any links are converted to anchor tags.
 *
 * This is mostly use for rendering user inputted plain text. Generally this shouldn't be used moving forwards and instead we should just
 * use our lexical editor for user inputted text which will then handle this when rendering.
 */
export function TextWithLinks({
  text,
  trailingContent,
  className,
}: {
  text: string;
  trailingContent?: JSX.Element;
  className?: string;
}) {
  return (
    <>
      {text.split("\n").map((paragraph, pIndex) => (
        <p key={pIndex} className={clsx("w-full min-w-0", className)}>
          {paragraph
            .split(/(http:\/\/\S+|https:\/\/\S+)/g)
            .map((part, index) => {
              const isLink =
                part.startsWith("http://") || part.startsWith("https://");
              return isLink ? (
                <a
                  key={`${pIndex}-${index}`}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-link-default hover:underline break-words"
                >
                  {part}
                </a>
              ) : (
                part
              );
            })}
        </p>
      ))}
      {trailingContent ? <span className="ml-2">{trailingContent}</span> : ""}
    </>
  );
}
