import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { EventInput } from "generated/graphql-codegen/graphql";
import { useCallback, useRef } from "react";
import useQuery from "utils/useQuery";

import { useCreateManualAvailabilitySubmission } from "../hooks/useCreateManualAvailabilitySubmission";
import {
  CreateOrUpdateAvailabilitySubmissionView,
  CreateOrUpdateAvailabilitySubmissionViewProps,
} from "./CreateOrUpdateAvailabilitySubmissionView";
import { CreateOrUpdateAvailabilityData } from "./CreateOrUpdateAvailabilityView";

export type CreateOrUpdateAvailabilitySubmissionContentProps = {
  availabilitySubmissionId?: string;
  availabilityRequestId?: string | null;
} & Pick<
  CreateOrUpdateAvailabilitySubmissionViewProps,
  "onSubmit" | "onCancel" | "stateProps"
>;

const AVAILABILITY_SUBMISSION_FOR_CREATE_OR_UPDATE_MODAL = gql(`
  query FetchDataForCreateOrUpdateAvailabilitySubmission(
    $availabilityRequestId: String!, 
    $availabilitySubmissionId: String!, 
    $skipSubmissionRequest: Boolean!,
    $skipAvailabilityRequest: Boolean!
  ) {
    guideAvailabilityRequestById(id: $availabilityRequestId) @skip(if: $skipAvailabilityRequest) {
      ...AvailabilityRequestForCreateOrUpdateAvailabilitySubmission
    }
    guideAvailabilitySubmissionById(id: $availabilitySubmissionId) @skip(if: $skipSubmissionRequest) {
      id
      guidePost {
        id
        guide {
          id
        }
      }
      notes
      events {
        id
        title
        startTime
        endTime
      }
    }
  }
`);

export function CreateOrUpdateAvailabilitySubmissionContent(
  props: CreateOrUpdateAvailabilitySubmissionContentProps
) {
  const { isOrgUser } = useGuideAuthContext();
  const { availabilitySubmissionId, availabilityRequestId } = props;
  const { data, loading: queryLoading } = useQuery(
    AVAILABILITY_SUBMISSION_FOR_CREATE_OR_UPDATE_MODAL,
    {
      variables: {
        availabilityRequestId: availabilityRequestId || "",
        availabilitySubmissionId: availabilitySubmissionId || "",
        skipSubmissionRequest: !availabilitySubmissionId,
        skipAvailabilityRequest: !availabilityRequestId,
      },
      skip: !availabilityRequestId && !availabilitySubmissionId,
    }
  );
  const availabilityRequest = data?.guideAvailabilityRequestById;
  const availabilitySubmission = data?.guideAvailabilitySubmissionById;

  if (queryLoading) {
    return <DialogLoading />;
  }

  if (availabilitySubmission) {
    return (
      <CreateOrUpdateAvailabilitySubmissionView
        {...props}
        availabilityRequest={availabilityRequest}
        stateProps={{
          defaultNotes: isOrgUser ? availabilitySubmission.notes ?? "" : "",
          defaultSelections: availabilitySubmission.events,
        }}
        isUpdating
      />
    );
  }

  return (
    <CreateOrUpdateAvailabilitySubmissionView
      {...props}
      availabilityRequest={availabilityRequest}
    />
  );
}

export type CreateOrUpdateAvailabilitySubmissionDialogProps = {
  availabilityRequestId?: string | null;
  availabilitySubmissionId?: string;
  guideId: string;
  store: DialogStore;
} & Pick<CreateOrUpdateAvailabilitySubmissionViewProps, "stateProps">;

export function CreateOrUpdateAvailabilitySubmissionDialog(
  props: CreateOrUpdateAvailabilitySubmissionDialogProps
) {
  const { store, availabilityRequestId, guideId } = props;
  const hasSubmittedRef = useRef(false);
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
    hasSubmittedRef,
  });
  const [createNewSubmissionManually] = useCreateManualAvailabilitySubmission();
  const onSubmit = useCallback(
    async (
      state: CreateOrUpdateAvailabilityData,
      { closeOnSubmit = true }: { closeOnSubmit?: boolean }
    ) => {
      await createNewSubmissionManually({
        variables: {
          input: {
            guideId,
            data: {
              availabilityRequestId,
              events: state.selections.map(
                (s): EventInput => ({
                  title: s.title,
                  startTime: s.startTime,
                  endTime: s.endTime,
                })
              ),
              notes: state.notes,
              schedulingPreference: state.schedulingPreference,
            },
          },
        },
      });
      hasSubmittedRef.current = true;

      if (closeOnSubmit) {
        store.hide();
      }
    },
    [availabilityRequestId, createNewSubmissionManually, guideId, store]
  );
  const onCancel = useCallback(() => {
    store.hide();
  }, [store]);

  return (
    <>
      <Dialog {...dialogProps} variant="fullscreen" store={store}>
        <CreateOrUpdateAvailabilitySubmissionContent
          {...props}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Dialog>
      <WarningDialog />
    </>
  );
}
