import { useAuthContext } from "auth/context";
import { useMemo } from "react";

export default function useGroupConferencingSettingsVisible() {
  const { user } = useAuthContext();
  const zoomEnabled = useMemo(() => {
    return user?.currentOrganization?.isZoomEnabled;
  }, [user?.currentOrganization?.isZoomEnabled]);

  const googleMeetApiEnabled = useMemo(() => {
    return (
      user?.currentOrganization?.isGoogleCalendarEnabled &&
      user?.canAccessGoogleMeet
    );
  }, [
    user?.canAccessGoogleMeet,
    user?.currentOrganization?.isGoogleCalendarEnabled,
  ]);

  return zoomEnabled || googleMeetApiEnabled;
}
