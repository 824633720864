import "./rich-block.sass";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  atlasCaption,
  atlasImage,
  atlasRefreshCw,
} from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { useEvent, useScheduleFocus } from "../../../__utils/react";
import { Icon } from "../../../icon/Icon";
import { useMenuItems } from "../../../menu/use-menu-items";
import { INSERT_TARGETS } from "../../__utils/insert-options-targets-order";
import { createRichBlock, RichBlockProps } from "../../__utils/rich-blocks";
import { useImageConfig } from "./config";
import { Image } from "./Image";
import { DEFAULT_DATA, ImageData, RICH_BLOCK_NAME } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Image";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// component
// ---------

function ImageComponent({
  data,
  updateData,
  ConfigMenu,
}: RichBlockProps<ImageData>) {
  const { onReplaceImage } = useImageConfig();

  const [caption, setCaption] = useState(data.caption);
  useEffect(() => {
    setCaption(data.caption);
  }, [data.caption]);

  const [showCaption, setShowCaption] = useState(false);

  const captionRef = useRef<HTMLTextAreaElement>(null);
  const scheduleFocus = useScheduleFocus(captionRef);

  const onAddCaption = useEvent(() => {
    scheduleFocus();
    setShowCaption(true);
  });

  const isCaptionVisible = showCaption || Boolean(caption);

  const configItems = useMenuItems(
    (i) => [
      i.item({
        key: "replace",
        children: "Replace",
        leadingContent: <Icon content={atlasRefreshCw} />,
        onClick: () =>
          onReplaceImage((imageUrl) =>
            updateData({
              imageUrl,
            })
          ),
      }),
      !isCaptionVisible &&
        i.item({
          key: "add-caption",
          children: "Add caption",
          leadingContent: <Icon content={atlasCaption} />,
          onClick: onAddCaption,
        }),
    ],
    [isCaptionVisible, onAddCaption, onReplaceImage, updateData]
  );

  return (
    <>
      <ConfigMenu items={configItems} />
      <div className={ROOT}>
        <Image imageUrl={data.imageUrl} />
        {(showCaption || caption) && (
          <div className={clsx("caption", "sizer")} data-content={caption}>
            <textarea
              ref={captionRef}
              rows={1}
              className="textarea"
              value={caption ?? ""}
              onChange={(event) =>
                setCaption(event.currentTarget.value || undefined)
              }
              onBlur={() => {
                if (!caption) setShowCaption(false);
                updateData({ caption });
              }}
              placeholder="Add caption"
            />
          </div>
        )}
      </div>
    </>
  );
}

// block
// -----

export const {
  $createImageNode,
  $isImageNode,
  INSERT_IMAGE_COMMAND,
  ImageNode,
  ImagePlugin,
  imageModule,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => ImageComponent,
  useInsertOption(_, { INSERT_COMMAND }) {
    const { onReplaceImage } = useImageConfig();

    const [editor] = useLexicalComposerContext();

    const onTrigger = useCallback(() => {
      onReplaceImage((imageUrl) => {
        editor.dispatchCommand(INSERT_COMMAND, { imageUrl });
      });
    }, [INSERT_COMMAND, editor, onReplaceImage]);

    return useMemo(
      () => ({
        label: "Image",
        icon: atlasImage,
        targets: {
          insert: INSERT_TARGETS.IMAGE,
          fixedToolbar: { slot: "image" },
        },
        onTrigger,
      }),
      [onTrigger]
    );
  },
  useRender() {
    const { tmpRender } = useImageConfig();
    return tmpRender;
  },
});
