import "@uploadcare/blocks/blocks/themes/lr-basic/index.css";

import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { FileInfo } from "@uploadcare/react-widget";
import { usePollingIntervalConfig } from "client/utils/polling";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { useCallback, useEffect, useState } from "react";
import inIframe from "utils/in-iframe";
import { loadUploadcare } from "utils/loadUploadcare";
import useQuery from "utils/useQuery";
import { v4 as uuid } from "uuid";

import styles from "./EditProfileImage.module.scss";

loadUploadcare();

const GENERATE_UPLOADCARE_SIGNATURE = gql(`
query GenerateUploadcareSignatureForEditPersonalProfile {
    uploadcareSignature {
      signature
      expire
    }
  }
`);

type EditInterviewerImageProps = {
  label?: string;
  imageUrl: string | null;
  onUpload: (file: FileInfo) => void;
  onRemove: () => void;
};

function EditProfileImage({
  label = "Photo",
  imageUrl,
  onUpload,
  onRemove,
}: EditInterviewerImageProps) {
  // const widgetApi = useRef<WidgetAPI>(null);
  const { uploadCareSignature: pollInterval } = usePollingIntervalConfig();

  const { data } = useQuery(GENERATE_UPLOADCARE_SIGNATURE, {
    fetchPolicy: "network-only",
    pollInterval,
  });

  const [contextName] = useState(`uploader-${uuid()}`);
  const uploaderConfig = {
    "--ctx-name": `"${contextName}"`,
    "--cfg-pubkey": `"${process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY ?? ""}"`,
    "--cfg-secure-signature": `"${data?.uploadcareSignature?.signature || ""}"`,
    "--cfg-secure-expire": `"${data?.uploadcareSignature?.expire || ""}"`,
    "--cfg-multiple": 0,
    "--cfg-confirm-upload": 0,
    "--cfg-source-list": "'local, url'",
    "--cfg-accept": "'image/jpeg,image/png,image/gif'",
    "--l10n-drop-files-here": inIframe()
      ? `"Click to select an image"`
      : `"Drop file here"`,
  };

  const onFileUpload = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (e.detail.ctx === contextName && e.detail.data.length > 0) {
        const rawResponse = e.detail.data[0];

        onUpload(rawResponse as FileInfo);
      }
    },
    [contextName, onUpload]
  );

  useEffect(() => {
    window.addEventListener("LR_DATA_OUTPUT", onFileUpload);
    return () => window.removeEventListener("LR_DATA_OUTPUT", onFileUpload);
  }, [onFileUpload]);

  return (
    <>
      <fieldset className="flex flex-col items-center">
        <legend className="flex text-body-md-heavy pb-2">{label}</legend>
        <Avatar
          image={imageUrl ?? undefined}
          variant="user"
          size="4xl"
          className={`${styles.avatar} mb-4`}
          disableTooltip
        />
        <div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <lr-file-uploader-regular
            class="lr-wgt-common"
            style={uploaderConfig}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <lr-data-output ctx-name={contextName} />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
          </lr-file-uploader-regular>
        </div>
        <Button
          className={clsx({
            "text-disabled cursor-not-allowed": !imageUrl,
          })}
          onClick={onRemove}
          disabled={!imageUrl}
          size="small"
          isGhost
        >
          Remove image
        </Button>
      </fieldset>
      {/* <ImageUploader widgetApi={widgetApi} onUpload={onUpload} /> */}
    </>
  );
}

export default EditProfileImage;
