/* eslint-disable import/prefer-default-export */
import "./LinkCardPopover.sass";

import isUrl from "is-url";
import prependHttp from "prepend-http";
import {
  FunctionComponent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { createComponentUtils } from "../../../../__utils/atlas";
import { useEvent } from "../../../../__utils/react";
import { Button } from "../../../../button/Button";
import { Checkbox } from "../../../../checkbox/Checkbox";
import TextField from "../../../../textfield/TextField";
import Tooltip from "../../../../tooltip/Tooltip";
import {
  ContentEditorPopover,
  ContentEditorPopoverProps,
} from "../../../__utils/content-editor-popover";
import { CardData, FetchUrlMetadata } from "./misc";

// config
// ------

const COMPONENT_NAME = "ContentEditor-LinkCard_popover";

const { el } = createComponentUtils(COMPONENT_NAME);

// link card popover
// -----------------

type LinkCardPopoverProps = {
  PopoverComponent?: FunctionComponent<
    React.PropsWithChildren<ContentEditorPopoverProps>
  >;
  open: boolean;
  setOpen: (open: boolean) => void;
  fetchUrlMetadata: FetchUrlMetadata;
  defaults?: {
    url: string;
    label?: string;
  };
  onSubmit: (cardData: CardData) => void;
  title: string;
  getAnchorRect?: () => DOMRect | null;
  trigger?: ReactNode;
  submitLabel: string;
};

export function LinkCardPopover({
  PopoverComponent = ContentEditorPopover,
  open,
  setOpen,
  fetchUrlMetadata,
  defaults,
  onSubmit,
  title,
  getAnchorRect,
  trigger,
  submitLabel,
}: LinkCardPopoverProps) {
  // state

  const [inputUrl, setInputUrl] = useState(defaults?.url || "");
  const [label, setLabel] = useState(defaults?.label);
  const [hideDescription, setHideDescription] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleHideDescription = useCallback(
    () => setHideDescription((current) => !current),
    []
  );

  // url

  const url = useMemo(() => prependHttp(inputUrl), [inputUrl]);
  const isValidUrl = useMemo(() => isUrl(url), [url]);

  // clear on close, load defaults on open

  useEffect(() => {
    if (!open) {
      setInputUrl("");
      setLabel(undefined);
    }

    if (open && defaults) {
      setInputUrl(defaults.url);
      setLabel(defaults.label);
    }
  }, [defaults, open]);

  // submit

  const submit = useEvent(async () => {
    if (!isValidUrl) return;

    setLoading(true);

    const linkPreviewData = await fetchUrlMetadata(url);

    onSubmit({
      url,
      label: label || undefined,
      fetchedLabel: linkPreviewData.title,
      fetchedDescription: linkPreviewData.description || undefined,
      fetchedImageUrl: linkPreviewData.image ?? undefined,
      lastFetchedMetadataAt: new Date().getDate(),
      hideDescription,
    });

    setLoading(false);
    setOpen(false);
  });

  // body

  const submitOnEnter = useEvent((event: KeyboardEvent<"input">) => {
    if (event.key === "Enter") {
      event.preventDefault();
      submit();
    }
  });

  const inputRef = useRef<HTMLInputElement>(null);
  const body = (
    <div className={el`body`}>
      <TextField
        label="Label"
        placeholder="e.g. Read our latest press release"
        value={label ?? ""}
        onChange={setLabel}
        UNSAFE_inputProps={{ onKeyDown: submitOnEnter }}
      />
      <TextField
        label="Link"
        placeholder="Paste in or type a URL"
        inputRef={inputRef}
        isRequired
        value={inputUrl ?? ""}
        onChange={setInputUrl}
        UNSAFE_inputProps={{ onKeyDown: submitOnEnter }}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="cursor-pointer flex items-center gap-2 text-body-md"
        onClick={toggleHideDescription}
      >
        <Checkbox checked={hideDescription} />
        <p>Hide description</p>
      </div>
    </div>
  );

  // footer

  const footer = (
    <div className={el`footer`}>
      <Tooltip
        content={!isValidUrl ? "Enter a valid URL" : undefined}
        isInstant
      >
        <Button
          size="small"
          isLoading={loading}
          variant="primary"
          disabled={!isValidUrl}
          onClick={submit}
        >
          {submitLabel}
        </Button>
      </Tooltip>
    </div>
  );

  // popover

  return (
    <PopoverComponent
      open={open}
      setOpen={setOpen}
      title={title}
      footer={footer}
      initialFocusRef={inputRef}
      getAnchorRect={getAnchorRect}
      trigger={trigger}
      className="min-h-[20rem]"
    >
      {body}
    </PopoverComponent>
  );
}
