import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasCalendarCheck,
  atlasCalendarCross,
  atlasCalendarFlexible,
  atlasCalendarUnknown,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { strings } from "@resource/common";
import { UserMembershipWithSchedulingData } from "client/components/interviewer-slots/utils/types";
import clsx from "clsx";
import { useMemo } from "react";

import {
  getInterviewerOverloadedData,
  GetInterviewerOverloadedDataProps,
} from "../../load/utils/helpers";
import { LoadInterval } from "../../load/utils/types";

type ConflictIconData = {
  tooltip: string;
  icon: AtlasIconData;
  iconColor: string;
};

const DEFAULT_ICON_DATA: ConflictIconData = {
  tooltip: "Availability is unknown.",
  icon: atlasCalendarUnknown,
  iconColor: "text-subtle",
};

export default function UserMembershipConflictIcon({
  userMembership,
  hasBeenSelected,
}: {
  userMembership: UserMembershipWithSchedulingData;
  /**
   * If the user is currently selected, then being "at load" would be valid because their counts include the current interview.
   * If they have not been selected, then being "at load" would be invalid since selecting them would put them over load.
   */
  hasBeenSelected: GetInterviewerOverloadedDataProps["hasBeenSelected"];
}) {
  const loadData = useLoadIconData({ userMembership, hasBeenSelected });

  const conflictData = useConflictIconData({
    userMembership,
  });

  if (loadData) {
    return <ConflictIconDisplay data={loadData} />;
  }

  if (conflictData) {
    return <ConflictIconDisplay data={conflictData} />;
  }

  return <ConflictIconDisplay data={DEFAULT_ICON_DATA} />;
}

function ConflictIconDisplay({ data }: { data: ConflictIconData }) {
  return (
    <Tooltip content={data.tooltip} isInstant>
      <Icon className={clsx(data.iconColor)} content={data.icon} />
    </Tooltip>
  );
}

function displayOverloadsTextForTooltip(overloads: LoadInterval[]) {
  return strings.joinWithFinalSeparator(overloads);
}

function useLoadIconData({
  userMembership,
  hasBeenSelected,
}: {
  userMembership: UserMembershipWithSchedulingData;
  hasBeenSelected: GetInterviewerOverloadedDataProps["hasBeenSelected"];
}) {
  const result = useMemo(() => {
    if (!userMembership.loadData) {
      return null;
    }

    const { accountOverloads, poolOverloads } = getInterviewerOverloadedData({
      loadData: userMembership.loadData,
      hasBeenSelected,
    });

    if (accountOverloads.length > 0) {
      return {
        tooltip: `Interviewer is at their maximum load for ${displayOverloadsTextForTooltip(
          accountOverloads
        )}.`,
        icon: atlasCalendarCross,
        iconColor: "text-red-500",
      };
    }
    if (poolOverloads && poolOverloads.length > 0) {
      return {
        tooltip: `Interviewer is at their maximum load for this pool for ${displayOverloadsTextForTooltip(
          poolOverloads
        )}.`,
        icon: atlasCalendarCross,
        iconColor: "text-red-500",
      };
    }
    return null;
  }, [hasBeenSelected, userMembership.loadData]);

  return result;
}

function useConflictIconData({
  userMembership,
}: {
  userMembership: UserMembershipWithSchedulingData;
}) {
  const conflictIconData = useMemo(() => {
    // If there's no conflict data available for some reason, show unknown availability.
    if (!userMembership.conflictData) {
      return null;
    }

    const { outsideWorkingHours, hasConflict, hasFlexibleConflict } =
      userMembership.conflictData;

    let tooltip = "Available for scheduling at this time.";
    let icon = atlasCalendarCheck;
    let iconColor = "text-green-500";

    if (outsideWorkingHours) {
      tooltip = "Interviewer is outside of their working hours.";
      icon = atlasCalendarCross;
      iconColor = "text-red-500";
    } else if (hasConflict) {
      tooltip = "Interviewer has conflicts during this time.";
      icon = atlasCalendarCross;
      iconColor = "text-red-500";
    } else if (hasFlexibleConflict) {
      tooltip = "Interviewer has flexible conflicts during this time.";
      icon = atlasCalendarFlexible;
      iconColor = "text-yellow-500";
    }
    return { tooltip, icon, iconColor };
  }, [userMembership.conflictData]);

  return conflictIconData;
}
