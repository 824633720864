import { ComponentPropsWithoutRef, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

export function CalendarEventsViewElement({
  children,
}: {
  children: ComponentPropsWithoutRef<"div">["children"];
}) {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const portalRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const findOrCreatePortalElement = () => {
      let element = document.getElementById("calendar-events-view-portal");
      if (!element) {
        element = document.createElement("div");
        element.id = "calendar-events-view-portal";
        document.body.appendChild(element);
      }
      return element;
    };

    const element = findOrCreatePortalElement();
    if (element !== portalRef.current) {
      portalRef.current = element;
      setPortalElement(element);
    }

    return () => {
      if (portalRef.current && !portalRef.current.childNodes.length) {
        portalRef.current.remove();
      }
    };
  }, []);

  if (!portalElement) {
    return null;
  }

  // We need to ensure the portal always has a child to fix ENG-3980. Wrapping children in a span or div causes other problems.
  const emptyChildren =
    children === null ||
    children === false ||
    (Array.isArray(children) && children.length === 0);
  const portalChildren = emptyChildren ? <span /> : children;

  return ReactDOM.createPortal(portalChildren, portalElement);
}
