import TextArea from "@resource/atlas/textfield/TextArea";
import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { useCallback } from "react";
import { InterviewerResponse } from "shared/guide-scheduler/types";
import { rsvpToUserDisplayText } from "shared/utils/rsvps";

import { EditRsvpState } from "./utils/hooks/useEditRsvpState";

type EditRsvpFormProps = {
  state: EditRsvpState;
};
export default function EditRsvpForm({ state }: EditRsvpFormProps) {
  const { form, onChange } = state;
  const { watch } = form;
  const responseStatus = watch("responseStatus");
  const responseNote = watch("responseNote");

  const getRadioItemProps = useCallback(
    ({ type }: { type: InterviewerResponse }) => ({
      label: rsvpToUserDisplayText[type],
      isSelected: responseStatus === type,
      onClick: () => onChange("responseStatus", type),
    }),
    [responseStatus, onChange]
  );

  return (
    <div className="space-y-4">
      <FormGroup label="RSVP" isRequired>
        <div className="space-y-2">
          <RadioItem
            {...getRadioItemProps({
              type: InterviewerResponse.ACCEPTED,
            })}
          />
          <RadioItem
            {...getRadioItemProps({
              type: InterviewerResponse.DECLINED,
            })}
          />
          <RadioItem
            {...getRadioItemProps({
              type: InterviewerResponse.TENTATIVE,
            })}
          />
          <RadioItem
            {...getRadioItemProps({
              type: InterviewerResponse.NEEDS_ACTION,
            })}
          />
        </div>
      </FormGroup>
      <FormGroup label="Add a note">
        <div className="space-y-2">
          <TextArea
            placeholder="Add an optional note"
            value={responseNote}
            onChange={(e) => onChange("responseNote", e)}
          />
          <p className="text-subtle text-sm">
            Briefly explain the RSVP change (e.g., conflict, illness).
          </p>
        </div>
      </FormGroup>
    </div>
  );
}
