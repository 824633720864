import { useCallback } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { CalendarEvent } from "../utils/types";
import { useColumns } from "./useColumns";

export function useMapEventGroupWithComputedOverrides() {
  const { columnType } = useColumns();

  /**
   * Events can optionally pass a `getOverrides` callback in order to change styling, interaction, or visibility
   * based on where they are being rendered on the calendar. This is currently being used to handle different styles
   * when the columns are calendars and grouped by interviews, to change styling of an interview event based on whether
   * it is in its own grouping or not. The implementation is generic to support other use cases.
   *
   * Note: It has to be done this way because consumers pass in a single event, and the Calendar internal logic renders
   * it in the correct columns. Consumers won't have any way to have the information about where it's being rendered unless
   * we pass it back up via a callback.
   */
  return useCallback(
    ({
      eventGroup,
      groupingId,
      calendarId,
    }: {
      eventGroup: CalendarEvent[];
      groupingId?: string;
      calendarId?: string;
    }) => {
      return eventGroup
        .map((event) => {
          // Get calendar grouping for event based on calendarGroupIdx if it exists
          const overrides = event.getOverrides
            ? event.getOverrides({
                eventInCalendarId: calendarId,
                eventInGroupId: groupingId,
                columnType,
              })
            : {};

          if (overrides.hideEvent) {
            return null;
          }

          return {
            ...event,
            ...overrides,
          };
        })
        .filter(filterOutNullsAndUndefined);
    },
    [columnType]
  );
}
