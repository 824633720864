import { Button } from "@resource/atlas/button/Button";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { InternalScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/InternalScheduledInterviewCard";
import { ScheduledInterviewForScheduledInterviewCardFragment } from "generated/graphql-codegen/graphql";

export function ReschedulingInterviewsSection({
  onReschedule,
  onNavigateToScheduledInterviewDetails,
  scheduledInterviews = [],
  hasAvailability,
}: {
  onReschedule: () => void;
  onNavigateToScheduledInterviewDetails: (props: {
    scheduledInterviewId: string;
  }) => void;
  scheduledInterviews?: ScheduledInterviewForScheduledInterviewCardFragment[];
  hasAvailability: boolean;
}) {
  return (
    <>
      <div className="space-y-2">
        <div className="w-full flex justify-between">
          <h4 className="text-h4">Needs rescheduling</h4>
        </div>
        {scheduledInterviews.length ? (
          <>
            {scheduledInterviews.map((scheduledInterview) => {
              return (
                <InternalScheduledInterviewCard
                  scheduledInterview={scheduledInterview}
                  key={scheduledInterview.id}
                  onClick={() =>
                    onNavigateToScheduledInterviewDetails({
                      scheduledInterviewId: scheduledInterview.id,
                    })
                  }
                />
              );
            })}
            <div className="flex flex-col w-full">
              <Button
                variant={hasAvailability ? "primary" : "default"}
                onClick={() => onReschedule()}
              >
                Reschedule interviews
              </Button>
            </div>
          </>
        ) : (
          <>
            <EmptyStateWrapper>
              <div>All required interviews have been rescheduled.</div>
            </EmptyStateWrapper>
            <div className="flex flex-col w-full">
              <Button variant="default" onClick={() => onReschedule()}>
                Schedule additional interviews
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
