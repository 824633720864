import {
  createSpecSet,
  Date,
  date,
  InterviewerNames,
  interviewerNames,
  Link,
  link,
  PersonName,
  personName,
  PersonNames,
  personNames,
  phone,
  Text,
  text,
} from "@resource/atlas/content-editor/variables";
import {
  VariableNameSet,
  VariableRendererSet,
  VariableSet,
} from "@resource/atlas/content-editor/variables/__lib/types";
import { PartialVariableSpec } from "@resource/atlas/content-editor/variables/__lib/variable-sets";
import { VariableKey } from "modules/lexical/shared/utils/types";
import { formatEntity } from "shared/constants/entities";
import {
  VideoConferencingDetails,
  videoConferencingDetails,
} from "shared/guide-scheduler/scheduled-interview-details/video-conferencing-variable";

/** Wrapper function for Atlas createSpecSet with improved type safety. */
export function createSpecSetFromKeys<T extends VariableSet>(
  keys: ReadonlyArray<keyof T & keyof typeof specMap>,
  options: {
    rendererSet: VariableRendererSet<T>;
    nameSet: VariableNameSet<T>;
  }
) {
  return createSpecSet<T>(
    Object.fromEntries(keys.map((key) => [key, specMap[key]])),
    options
  );
}

export const specMap: Record<
  VariableKey,
  | PartialVariableSpec<Text>
  | PartialVariableSpec<PersonName>
  | PartialVariableSpec<PersonNames>
  | PartialVariableSpec<Link>
  | PartialVariableSpec<Date>
  | PartialVariableSpec<InterviewerNames>
  | PartialVariableSpec<VideoConferencingDetails>
> = {
  atsCoordinatorJobTitle: text(),
  atsCoordinatorName: personName(),
  atsRecruiterJobTitle: text(),
  atsRecruiterName: personName(),
  authorName: personName(),
  candidateEmail: text(),
  candidateLinkedIn: link(),
  candidateName: personName(),
  candidatePhone: phone(),
  collaborativeCodingUrl: link(),
  companyName: text(),
  conferencePhone: text(),
  firstInterview: date(),
  firstInterviewerJobTitle: text(),
  firstInterviewerName: personName(),
  firstInterviewFirstInterviewerName: personName(),
  firstInterviewFirstInterviewerTitle: text(),
  firstInterviewInterviewers: personNames(),
  guideLink: link(() => ({ defaultConfig: { label: formatEntity("guide") } })),
  hiringManagerJobTitle: text(),
  hiringManagerName: personName(),
  interviewersList: interviewerNames(),
  interviewerNames: personNames(),
  interviewKit: link(),
  interviewName: text(),
  interviewsInterviewers: personNames(),
  interviewsTimeRange: date(),
  interviewTime: date(),
  jobListingLink: link(),
  jobTitle: text(),
  lastInterview: date(),
  lastInterviewFirstInterviewerName: personName(),
  lastInterviewFirstInterviewerTitle: text(),
  lastInterviewInterviewers: personNames(),
  linkToJobDescription: link(),
  schedulerName: personName(),
  videoConferencingUrl: videoConferencingDetails(),
};
