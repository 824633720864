/* eslint-disable import/prefer-default-export */
import "./Image.sass";

import { atlasImage } from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { Icon } from "../../../icon/Icon";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Image_image";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// component
// ---------

type ImageProps = {
  imageUrl?: string | null;
  alt?: string;
};

export function Image({ imageUrl, alt }: ImageProps) {
  return (
    <div className={ROOT}>
      {!imageUrl || imageUrl === "" ? (
        <div className="fallback">
          <Icon content={atlasImage} className="fallback-icon" />
        </div>
      ) : (
        <img className="image" alt={alt} src={imageUrl} />
      )}
    </div>
  );
}
