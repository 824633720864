/* eslint-disable jsx-a11y/anchor-has-content */
import { Menu } from "@resource/atlas/menu-v2";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import NextLink from "next/link";
import { useMemo } from "react";
import useExtension from "react-hooks/useExtension";
import useExtensionLink from "react-hooks/useExtensionLink";
import {
  CHROME_EXTENSION_INSTALL_PAGE,
  CHROME_EXTENSION_TUTORIAL,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_SERVICE_PAGE,
} from "utils/constants/href";

type UseAppNavAccountMenuItemsProps = {
  target?: "self" | "_blank";
  hideDashboard?: boolean;
  logout: () => void;
};

export const useAppNavAccountMenuItems = ({
  target,
  hideDashboard,
  logout,
}: UseAppNavAccountMenuItemsProps) => {
  const logEvent = useLogEvent({ component: "AccountMenu" });
  const { user } = useAuthContext();
  const { installed: isExtensionInstalled } = useExtension();
  const extensionLink = useExtensionLink();

  const isCandidateUser =
    user?.currentUserMembership?.highestRole?.id === "candidate";

  return useMemo(() => {
    if (isCandidateUser) {
      return [
        <Menu.Item
          key="terms"
          size="compact"
          onClick={() => logEvent("Account Menu Terms Of Service Clicked")}
          render={(props) => (
            <a
              {...props}
              href={TERMS_OF_SERVICE_PAGE}
              target="_blank"
              rel="noreferrer"
            />
          )}
        >
          Terms of service
        </Menu.Item>,
        <Menu.Item
          key="privacy"
          size="compact"
          onClick={() => logEvent("Account Menu Privacy Policy Clicked")}
          render={(props) => (
            <a
              {...props}
              href={PRIVACY_POLICY_PAGE}
              target="_blank"
              rel="noreferrer"
            />
          )}
        >
          Privacy policy
        </Menu.Item>,
        <Menu.Separator key="separator-1" />,
        <Menu.Item key="sign-out" size="compact" onClick={logout}>
          Sign out
        </Menu.Item>,
      ];
    }

    return [
      // Dashboard
      !hideDashboard && (
        <Menu.Item
          key="dashboard"
          size="compact"
          onClick={() => logEvent("Guide Account Menu Dashboard Clicked")}
          render={(props) => <NextLink href="/" {...props} target={target} />}
        >
          Company dashboard
        </Menu.Item>
      ),
      // Account Settings
      <Menu.Item
        key="account"
        size="compact"
        onClick={() =>
          logEvent("Guide Account Menu Account & Preferences Clicked")
        }
        render={(props) => (
          <NextLink href="/account" {...props} target={target} />
        )}
      >
        Account settings
      </Menu.Item>,
      <Menu.Separator key="separator-1" />,
      // Chrome Extension
      <Menu.Item
        key="install-extension"
        size="compact"
        onClick={() => {
          logEvent("Menu Install Clicked", {
            project: "Check and prompt Extension install",
          });
          if (isExtensionInstalled) {
            window.open(CHROME_EXTENSION_INSTALL_PAGE, "_blank");
          } else {
            extensionLink.navigate(CHROME_EXTENSION_TUTORIAL);
          }
        }}
      >
        Install Chrome extension
      </Menu.Item>,
      <Menu.Separator key="separator-2" />,
      // Terms & Privacy
      <Menu.Item
        key="terms"
        size="compact"
        onClick={() => logEvent("Account Menu Terms Of Service Clicked")}
        render={(props) => (
          <a
            {...props}
            href={TERMS_OF_SERVICE_PAGE}
            target="_blank"
            rel="noreferrer"
          />
        )}
      >
        Terms of service
      </Menu.Item>,
      <Menu.Item
        key="privacy"
        size="compact"
        onClick={() => logEvent("Account Menu Privacy Policy Clicked")}
        render={(props) => (
          <a
            {...props}
            href={PRIVACY_POLICY_PAGE}
            target="_blank"
            rel="noreferrer"
          />
        )}
      >
        Privacy policy
      </Menu.Item>,
      <Menu.Separator key="separator-3" />,
      // Sign Out
      <Menu.Item key="sign-out" size="compact" onClick={logout}>
        Sign out
      </Menu.Item>,
    ].filter(Boolean);
  }, [
    isCandidateUser,
    hideDashboard,
    target,
    logout,
    logEvent,
    isExtensionInstalled,
    extensionLink,
  ]);
};
