/* eslint-disable import/prefer-default-export */
import { DateTime } from "luxon";

type Props = {
  startTime: string | DateTime;
  endTime: string | DateTime;
  timezone?: string | undefined;
};

export function getFormattedDateRange({
  startTime: passedStartTime,
  endTime: passedEndTime,
  timezone: passedTimezone,
}: Props) {
  const userTimezone = DateTime.local().zoneName;
  const timezone = passedTimezone || userTimezone;
  const startTime =
    typeof passedStartTime === "string"
      ? DateTime.fromISO(passedStartTime, { zone: timezone })
      : passedStartTime.setZone(timezone);
  const endTime =
    typeof passedEndTime === "string"
      ? DateTime.fromISO(passedEndTime, { zone: timezone })
      : passedEndTime.setZone(timezone);

  const isSameDay = startTime.hasSame(endTime, "day");

  let formattedDateTime;
  if (isSameDay) {
    formattedDateTime = `${startTime.toFormat(
      "ccc, LLL d"
    )} · ${startTime.toFormat("h:mm")}-${endTime.toFormat("h:mma ZZZZ")}`;
  } else {
    formattedDateTime = `${startTime.toFormat(
      "ccc LLL d h:mma"
    )} - ${endTime.toFormat("ccc LLL d h:mma ZZZZ")}`;
  }

  return {
    /** Ex: Thu, Mar 7 */
    formattedDate: startTime.toFormat("ccc, LLL d"),
    /** Ex: 11:00AM CST */
    formattedStartTime: startTime.toFormat("h:mma ZZZZ"),
    /** Ex: 11:00-12:00PM CST */
    formattedTimeRange: `${startTime.toFormat("h:mm")}-${endTime.toFormat(
      "h:mma ZZZZ"
    )}`,
    /** Ex: Thu, Mar 7 · 11:00-12:00PM CST or Mon Mar 7 12:30PM - Tue  March 8 10:00AM PDT */
    formattedDateTime,
    /** Ex: 7 */
    formattedDay: startTime.toFormat("d"),
    /** Ex: Thu */
    formattedDayName: startTime.toFormat("ccc"),
  };
}
