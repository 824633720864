import {
  ActivityForFeedFragment,
  ActivityVerb,
} from "generated/graphql-codegen/graphql";
import { GuideActivityVerbs } from "shared/activity";

import { ActorDisplayAvatar, ActorDisplayContent } from "./__utils/common";
import {
  ActivityFeedMapper,
  ActivityFunctionMap,
  ActivityGroupFunctionMap,
} from "./__utils/types";
import { InterviewActivityFeed } from "./InterviewFeed";
import { SchedulingRequestActivityFeed } from "./SchedulingRequestFeed";

export class GuideActivityFeed extends ActivityFeedMapper<GuideActivityVerbs> {
  private interviewMapper = new InterviewActivityFeed();

  private schedulingRequestMapper = new SchedulingRequestActivityFeed();

  protected isGroupableVerb(verb: ActivityVerb) {
    return [
      ActivityVerb.InterviewScheduled,
      ActivityVerb.InterviewRescheduled,
      ActivityVerb.InterviewCancelled,
      ActivityVerb.GuideViewed,
      ActivityVerb.GuidePostMarkedRead,
    ].includes(verb);
  }

  groupActivity(activity: ActivityForFeedFragment[]) {
    const baseGrouping = super.groupActivity(activity);

    // TODO: Add better grouping so we don't show both guide post and availability sent activity for the same guide post

    return baseGrouping;
  }

  readonly activityGroupMap: ActivityGroupFunctionMap<GuideActivityVerbs> = {
    ...this.interviewMapper.activityGroupMap,
    ...this.schedulingRequestMapper.activityGroupMap,

    GuideCreated: null,
    AtssyncApplicationMovedStage: null,
    GuideBannerUpdated: null,
    GuideChangedStatus: null,
    GuideInterviewPlanChanged: null,
    GuideInterviewPlanOverridesCleared: null,
    GuideMovedStage: null,
    GuideOverviewContentUpdated: null,
    GuidePostCreated: null,
    GuidePostDeleted: null,
    GuidePostEdited: null,
    GuidePostReplyCreated: null,
    GuidePostMarkedRead: ({ activities, ...rest }) => ({
      content:
        activities.length > 0 ? (
          <ActorDisplayContent
            {...rest}
            {...activities[0]}
            actor={activities[0].actor}
            isCandidate
            actionText={`read ${activities.length} posts on their guide`}
          />
        ) : null,
      icon: rest.candidateName ? (
        <ActorDisplayAvatar actor={{ name: rest.candidateName }} />
      ) : null,
    }),
    GuideViewed: ({ activities, ...rest }) => ({
      content:
        activities.length > 0 ? (
          <ActorDisplayContent
            {...rest}
            {...activities[0]}
            actor={activities[0].actor}
            isCandidate
            actionText={`viewed their guide ${activities.length} times`}
          />
        ) : null,
      icon: rest.candidateName ? (
        <ActorDisplayAvatar actor={{ name: rest.candidateName }} />
      ) : null,
    }),

    // Actions are deprecated so we do not want to render
    ActionChangedStatus: null,
    ActionCompleted: null,
    ActionCreated: null,
    ActionDismissed: null,
    ActionEnqueued: null,
    ActionMovedStage: null,
    ActionUpdated: null,

    // TODO
    [ActivityVerb.AvailabilityRequestCreated]: null,
    [ActivityVerb.AvailabilityRequestUpdated]: null,
    [ActivityVerb.AvailabilitySubmissionCreated]: null,
    [ActivityVerb.AvailabilitySubmissionUpdated]: null,
    [ActivityVerb.AvailabilitySubmissionCleared]: null,
    [ActivityVerb.AvailabilityRequestCompleted]: null,
    [ActivityVerb.SelfScheduleRequestCreated]: null,
    [ActivityVerb.SelfScheduleRequestUpdated]: null,
    [ActivityVerb.SelfScheduleSubmissionCreated]: null,
    [ActivityVerb.SelfScheduleSubmissionUpdated]: null,
    [ActivityVerb.SelfScheduleRequestCompleted]: null,
    [ActivityVerb.SelfScheduleRequestCancelled]: null,
  };

  readonly activityMap: ActivityFunctionMap<GuideActivityVerbs> = {
    ...this.interviewMapper.activityMap,
    ...this.schedulingRequestMapper.activityMap,
    [ActivityVerb.GuideCreated]: null,
    [ActivityVerb.AtssyncApplicationMovedStage]: ({
      actor,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="changed the candidate's stage in ATS"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuideBannerUpdated]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="updated the banner"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuideChangedStatus]: ({ actor, meta, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`changed status to ${meta.status}.`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuideInterviewPlanChanged]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="updated the interview plan"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuideInterviewPlanOverridesCleared]: ({
      actor,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="restored the interview plan"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuideMovedStage]: ({ actor, meta, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`moved ${
            meta.stage.name ? `to stage ${meta.stage.name}` : "the stage"
          }.`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuideOverviewContentUpdated]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="updated the guide overview"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuidePostCreated]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          isCandidate={!actor}
          actionText="created a post"
        />
      ),
      icon: <ActorDisplayAvatar {...rest} actor={actor} />,
    }),
    [ActivityVerb.GuidePostDeleted]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="deleted a post"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuidePostEdited]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="edited a post"
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.GuidePostMarkedRead]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          isCandidate
          actionText="read a post on their guide"
        />
      ),
      icon: rest.candidateName ? (
        <ActorDisplayAvatar actor={{ name: rest.candidateName }} />
      ) : null,
    }),
    // We should only show the GuidePost activity on a Guide, not replies
    [ActivityVerb.GuidePostReplyCreated]: null,
    [ActivityVerb.GuideViewed]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          isCandidate
          actionText="viewed their guide"
        />
      ),
      icon: rest.candidateName ? (
        <ActorDisplayAvatar actor={{ name: rest.candidateName }} />
      ) : null,
    }),

    // Actions are deprecated so we do not want to render
    ActionChangedStatus: null,
    ActionCompleted: null,
    ActionCreated: null,
    ActionDismissed: null,
    ActionEnqueued: null,
    ActionMovedStage: null,
    ActionUpdated: null,

    [ActivityVerb.AvailabilitySubmissionCreated]: ({
      actor,
      candidateName,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            candidateName ? (
              <>
                added{" "}
                <span className="text-body-md-heavy text-dark">
                  {candidateName}&apos;s
                </span>{" "}
                availability
              </>
            ) : (
              "added availability"
            )
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.AvailabilitySubmissionUpdated]: ({
      actor,
      candidateName,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            candidateName ? (
              <>
                updated{" "}
                <span className="text-body-md-heavy text-dark">
                  {candidateName}&apos;s
                </span>{" "}
                availability
              </>
            ) : (
              "updated availability"
            )
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    // TODO
    [ActivityVerb.AvailabilityRequestCreated]: null,
    [ActivityVerb.AvailabilityRequestUpdated]: null,
    [ActivityVerb.AvailabilitySubmissionCleared]: null,
    [ActivityVerb.AvailabilityRequestCompleted]: null,
    [ActivityVerb.SelfScheduleRequestCreated]: null,
    [ActivityVerb.SelfScheduleRequestUpdated]: null,
    [ActivityVerb.SelfScheduleSubmissionCreated]: null,
    [ActivityVerb.SelfScheduleSubmissionUpdated]: null,
    [ActivityVerb.SelfScheduleRequestCompleted]: null,
    [ActivityVerb.SelfScheduleRequestCancelled]: null,
  };
}
