import { ReactElement, useMemo, useState } from "react";

import { atlasEdit2, atlasRefreshCw } from "../../../../../icons/atlas";
import { useEvent } from "../../../../__utils/react";
import { Icon } from "../../../../icon/Icon";
import { AtlasIconData } from "../../../../icon/types";
import { useMenuItems } from "../../../../menu/use-menu-items";
import { InsertOptionData } from "../../../__utils/insert-options";
import { Txt } from "../../../renderer";
import { LinkCardPopover } from "./LinkCardPopover";

// card data
// ---------

export type CardData = {
  url: string;
  label?: string;
  fetchedLabel?: string;
  description?: string;
  fetchedDescription?: string;
  imageUrl?: string;
  fetchedImageUrl?: string;
  lastFetchedMetadataAt?: number;
  hideDescription?: boolean;
};

export const DEFAULT_CARD_DATA: CardData = { url: "" };

// base menu items
// ---------------

type UseBaseLinkCardMenuItemsProps = {
  onEdit: () => void;
  onReplaceImage: () => void;
};

export function useBaseLinkCardMenuItems({
  onEdit,
  onReplaceImage,
}: UseBaseLinkCardMenuItemsProps) {
  const menuItems = useMenuItems(
    (i) => [
      i.item({
        key: "edit",
        children: "Edit link",
        leadingContent: <Icon content={atlasEdit2} />,
        onClick: onEdit,
      }),
      i.item({
        key: "replace",
        children: "Replace image",
        leadingContent: <Icon content={atlasRefreshCw} />,
        onClick: onReplaceImage,
      }),
    ],
    [onEdit, onReplaceImage]
  );

  return menuItems;
}

// insert option
// -------------

type UseLinkCardInsertOptionProps = {
  getAnchorRect: () => DOMRect | null;
  onSubmit: (cardData: CardData) => void;
  fetchUrlMetadata: FetchUrlMetadata;
  label: string;
  icon: AtlasIconData;
  targets: InsertOptionData["targets"];
};

export function useLinkCardInsertOption({
  getAnchorRect,
  onSubmit,
  fetchUrlMetadata,
  label,
  icon,
  targets,
}: UseLinkCardInsertOptionProps) {
  const [addOpen, setAddOpen] = useState(false);

  const addPopover = useMemo(
    () => (
      <LinkCardPopover
        open={addOpen}
        setOpen={setAddOpen}
        getAnchorRect={getAnchorRect}
        fetchUrlMetadata={fetchUrlMetadata}
        title="Add a link card"
        submitLabel="Create"
        onSubmit={onSubmit}
      />
    ),
    [addOpen, fetchUrlMetadata, getAnchorRect, onSubmit]
  );

  const toggleAddPopover = useEvent(() => setAddOpen((current) => !current));

  return useMemo(
    () => ({
      label,
      icon,
      targets,
      active: addOpen,
      render: addPopover,
      onTrigger: toggleAddPopover,
    }),
    [addOpen, addPopover, icon, label, targets, toggleAddPopover]
  );
}

// module config
// -------------

export type FetchUrlMetadata = (url: string) => Promise<{
  title: string;
  image: string | null;
  description: string | null;
}>;

export type ModuleConfig = {
  onReplaceImage: (setImageUrl: (imageUrl: string) => void) => void;
  fetchUrlMetadata: FetchUrlMetadata;
  // TODO: remove once the inline file uploader is implemented
  tmpRender?: ReactElement;
};

// text node renderer
// ------------------

export function renderTextLinkCard({ label, fetchedLabel, url }: CardData) {
  return (
    <Txt.Block>
      {label || fetchedLabel}: {url}
    </Txt.Block>
  );
}
