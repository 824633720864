import { Avatar } from "@resource/atlas/avatar/Avatar";
import { AvatarGroup } from "@resource/atlas/avatar/AvatarGroup";
import type { AtlasAvatarGroupOptions } from "@resource/atlas/avatar/types";
import { useAvatarGroupItems } from "@resource/atlas/avatar/use-avatar-group-items";
import Badge from "@resource/atlas/badge/Badge";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasChevronDown,
  atlasPersonPlus,
  atlasPlus,
  atlasSearch,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionGroupLabel } from "@resource/atlas/option/OptionGroupLabel";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { AtlasPopoverState, Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { SelectLabel } from "@resource/atlas/select/utils/SelectLabel";
import TextField from "@resource/atlas/textfield/TextField";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { RecipientsManagerCandidateFragment } from "generated/graphql-codegen/graphql";
import { uniqBy } from "lodash";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
} from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { formatEntity } from "shared/constants/entities";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";
import { z } from "zod";

gql(`
fragment RecipientsManagerCandidate on Candidate {
    id
    email
    firstName: displayFirstName
    lastName
    name: displayName
    imageUrl
  }
`);

gql(`
fragment InterviewerForRecipientSelection on UserMembership {
  id
  firstName
  lastName
  name
  imageUrl
  email
}
`);

const INTERVIEWERS_FOR_RECIPIENT_MANAGER = gql(`
  query FetchUsersForRecipientsManagerQuery($search: String, $limit: Int) {
    userMemberships(search: $search, limit: $limit) {
      ...InterviewerForRecipientSelection
    }
  }
`);

const SET_GUIDE_POST_RECIPIENTS = gql(`
mutation SetGuidePostRecipients($input: SetGuidePostRecipientsInput!) {
    setGuidePostRecipients(input: $input) {
      success
      code
      guidePost {
        id
        recipientProfiles {
          id
          name
          firstName
          lastName
          imageUrl
          isCandidate
        }
        removedRecipients
      }
    }
  }
`);

export interface Recipient {
  id?: string | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  imageUrl?: string | null;
  isCandidate: boolean;
}

const getOptions = (
  items: Recipient[],
  checked: boolean,
  onSelectionChange: (recipient: Recipient, checked: boolean) => void
) =>
  items.map((recipient) => (
    <OptionItem
      key={recipient.email}
      isSelectable
      isSelected={checked}
      onClick={() => onSelectionChange(recipient, !checked)}
      size="compact"
    >
      <div className="flex gap-2 items-center">
        <Avatar
          size="xs"
          image={recipient.imageUrl}
          name={recipient.name || recipient.email}
          className="shrink-0"
        />
        <span>{recipient.name || recipient.email}</span>
        {recipient.name && recipient.email && (
          <span className="text-subtle truncate"> ({recipient.email})</span>
        )}
        {recipient.isCandidate && (
          <Badge size="small" className="shrink-0">
            Candidate
          </Badge>
        )}
      </div>
    </OptionItem>
  ));

type RecipientsPopoverTriggerProps = ComponentPropsWithoutRef<"button"> & {
  selected: Recipient[];
  size?: AtlasAvatarGroupOptions["size"];
  variant: "names" | "avatars";
  label: "recipient" | "follower" | "team member";
};

const CompactPopoverTrigger = forwardRef<
  HTMLButtonElement,
  RecipientsPopoverTriggerProps
>(({ size, selected, variant, label, className, ...props }, ref) => {
  const avatarItems = useAvatarGroupItems(
    (i) =>
      selected.map((item) =>
        i.avatar({
          key: item.email,
          name: item.name || item.email,
          image: item.imageUrl,
        })
      ),
    [selected]
  );
  const displayCount = 5;
  return (
    <button
      ref={ref}
      type="button"
      {...props}
      className={clsx(
        "flex items-center py-[0.375rem] rounded-md pl-[0.625rem] pr-[0.313rem]",
        "hover:bg-light-gray-600 active:bg-light-gray-700",
        "aria-expanded:bg-red-50 aria-expanded:hover:bg-[#FFE0E6] aria-expanded:active:bg-[#FFD5DD] aria-expanded:text-red-500",
        "focus:outline-none focus-visible:focus-ring-0",
        className,
        {
          "text-subtle": selected.length === 0,
          "text-body-md-heavy": variant === "names",
          "text-body-md": variant !== "names",
        }
      )}
    >
      <div className="flex gap-[6px] items-center">
        {selected.length === 0 ? (
          <>
            <Icon content={atlasPlus} />
            <div>
              {variant === "avatars"
                ? "Add"
                : `Add ${formatEntity(label, { plural: true })}`}
            </div>
          </>
        ) : (
          <>
            <AvatarGroup
              className="pointer-events-none"
              max={displayCount}
              size={size ?? "xs"}
              items={
                variant === "avatars"
                  ? avatarItems
                  : avatarItems.slice(0, displayCount)
              }
              UNSAFE_disableDynamicCollectionWarnings
            />
            {variant === "names" && (
              <div>
                <span>
                  {selected
                    .slice(0, displayCount)
                    .map((r) => {
                      if (r.firstName && r.lastName) {
                        return `${r.firstName} ${r.lastName.charAt(0)}`;
                      }

                      return r.email;
                    })
                    .join(", ")}
                </span>
                {selected.length > displayCount && (
                  <span>, +{selected.length - displayCount}</span>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Icon content={atlasChevronDown} />
    </button>
  );
});

interface RecipientsManagerProps {
  id?: string;
  selected: Recipient[];
  size?: AtlasAvatarGroupOptions["size"];
  label: RecipientsPopoverTriggerProps["label"];
  candidate?: RecipientsManagerCandidateFragment;
  onSelectionChanged?: (selected: Recipient[]) => unknown;
  triggerVariant?: RecipientsPopoverTriggerProps["variant"];
  forMessageComposer?: boolean;
  className?: string;
  placement?: AtlasPopoverState["placement"];
  filterOptions?: (recipients: Recipient[]) => Recipient[];
}

export function RecipientsManager({
  id,
  size,
  label,
  selected,
  candidate,
  onSelectionChanged,
  triggerVariant,
  forMessageComposer,
  className,
  placement,
  filterOptions,
}: RecipientsManagerProps) {
  const popoverState = usePopoverState({ placement });
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");

  const { data, loading } = useQuery(INTERVIEWERS_FOR_RECIPIENT_MANAGER, {
    variables: {
      search: debouncedTerm,
      limit: 25,
    },
  });

  const [setGuidePostRecipients] = useMutation(SET_GUIDE_POST_RECIPIENTS);

  const setRecipients = useCallback(
    (updatedRecipients: Recipient[]) => {
      if (onSelectionChanged) {
        onSelectionChanged(updatedRecipients);
      } else if (id) {
        setGuidePostRecipients({
          variables: {
            input: {
              id,
              recipients: updatedRecipients.map((item) => ({
                userMembershipId: item.id && !item.isCandidate ? item.id : null,
                email: item.email,
                isCandidate: item.isCandidate,
              })),
            },
          },
        });
      }
    },
    [id, onSelectionChanged, setGuidePostRecipients]
  );

  const onSelectionChange = useCallback(
    (recipient: Recipient, checked: boolean) => {
      const updatedRecipients = checked
        ? uniqBy([...selected, recipient], "email")
        : selected.filter((val) => val.email !== recipient.email);
      setRecipients(updatedRecipients);
    },
    [selected, setRecipients]
  );

  const onAddExternalCC = useCallback(
    (email: string) => {
      onSelectionChange(
        {
          email,
          isCandidate: false,
        },
        true
      );
      setSearchTerm("");
      popoverState.hide();
    },
    [onSelectionChange, popoverState, setSearchTerm]
  );

  const candidateSelected = useMemo(() => {
    return selected.some((item) => item.isCandidate);
  }, [selected]);

  const recipients = useMemo(() => {
    let items: Recipient[] =
      data?.userMemberships
        ?.map((i) => {
          // If there's an interviewer as internal candidate, prevent
          // them from being added as an interviewer follower.
          if (candidate && i.email === candidate.email) {
            return null;
          }
          return {
            ...i,
            isCandidate: false,
          };
        })
        .filter(filterOutNullsAndUndefined) ?? [];

    if (
      candidate &&
      !candidateSelected &&
      (!debouncedTerm ||
        candidate.name.toLowerCase().includes(debouncedTerm.toLowerCase()))
    ) {
      items.unshift({ ...candidate, email: "", isCandidate: true });
    }

    if (!debouncedTerm) {
      items = uniqBy([...items, ...selected], "email");
    }
    return items;
  }, [
    selected,
    candidate,
    candidateSelected,
    debouncedTerm,
    data?.userMemberships,
  ]);

  const selectedOptions = useMemo(
    () =>
      getOptions(
        recipients.filter((recipient) =>
          selected.some((item) => item.email === recipient.email)
        ),
        true,
        onSelectionChange
      ),
    [selected, onSelectionChange, recipients]
  );
  const unselectedOptions = useMemo(() => {
    const filteredRecipients = filterOptions
      ? filterOptions(recipients)
      : recipients;
    const unselectedRecipients = filteredRecipients.filter(
      (recipient) => !selected.some((item) => item.email === recipient.email)
    );

    return getOptions(unselectedRecipients, false, onSelectionChange);
  }, [filterOptions, recipients, onSelectionChange, selected]);

  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger>
        {forMessageComposer ? (
          <SelectTrigger
            isGhost
            className={clsx("flex-1", className)}
            isPlaceholder={selected.length === 0}
          >
            {selected.length > 0 ? (
              <SelectLabel items={selected.map((s) => s.name || s.email)} />
            ) : (
              "Select team members"
            )}
          </SelectTrigger>
        ) : (
          <CompactPopoverTrigger
            selected={selected}
            size={size}
            label={label}
            variant={triggerVariant ?? "names"}
          />
        )}
      </Popover.Trigger>
      <Popover.Content
        className={clsx({
          "w-[30rem]": !forMessageComposer,
          sameWidth: forMessageComposer,
        })}
        hasPadding={false}
      >
        <TextField
          className="m-2"
          size="small"
          value={searchTerm}
          icon={atlasSearch}
          placeholder="Search"
          aria-label="Search"
          onChange={setSearchTerm}
          isClearable
        />
        <div className="p-[.5rem] pt-0">
          {loading && (
            <>
              <OptionSeparator />
              <div className="flex justify-center">
                <LoadingIndicator size="small" />
              </div>
            </>
          )}
          {!loading && selectedOptions.length > 0 && (
            <>
              <OptionSeparator />
              <div className="flex justify-between items-center">
                <OptionGroupLabel>
                  {label === "recipient" && "Send To"}
                  {label === "team member" && "Notify"}
                  {!["recipient", "team member"].includes(label) &&
                    formatEntity(label, { plural: true })}
                </OptionGroupLabel>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  as="button"
                  variant="subtle"
                  className="text-body-md"
                  onClick={() => setRecipients([])}
                >
                  Clear all
                </Link>
              </div>
              {selectedOptions}
            </>
          )}
          {!loading && unselectedOptions.length > 0 && (
            <>
              <OptionSeparator />
              <OptionGroupLabel>
                Add {formatEntity(label, { plural: true })}
              </OptionGroupLabel>
              {unselectedOptions}
            </>
          )}
          {searchTerm && (
            <>
              <OptionSeparator />
              <AddExternalCC
                conflicts={selected}
                searchTerm={searchTerm}
                onClick={onAddExternalCC}
              />
            </>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}

const zodEmailSchema = z.string().email();

const isValidEmail = (email: string): boolean => {
  return zodEmailSchema.safeParse(email).success;
};

function AddExternalCC({
  searchTerm,
  onClick: passedOnClick,
  conflicts,
}: {
  searchTerm: string;
  onClick: (email: string) => void;
  conflicts: Recipient[];
}) {
  const invalidEmail = useMemo(() => !isValidEmail(searchTerm), [searchTerm]);
  const hasConflicts = useMemo(
    () => conflicts.some((i) => i.email === searchTerm),
    [conflicts, searchTerm]
  );

  const disabledTooltipContent = useMemo(() => {
    if (invalidEmail) {
      return "Include a valid email to manually add someone to CC.";
    }
    if (hasConflicts) {
      return "Cannot add the same follower twice.";
    }
    return undefined;
  }, [invalidEmail, hasConflicts]);

  const onClick = useCallback(() => {
    if (!disabledTooltipContent) {
      passedOnClick(searchTerm);
    }
  }, [disabledTooltipContent, passedOnClick, searchTerm]);

  return (
    <OptionalTooltip
      isInstant
      content={disabledTooltipContent}
      className="w-full"
    >
      <OptionItem
        isSelectable
        onClick={onClick}
        isDisabled={!!disabledTooltipContent}
        size="compact"
      >
        <div className="flex space-x-2">
          <div className="flex w-5 h-5 bg-light-gray-500 rounded-full p-[3px] text-gray-500 items-center justify-center">
            <Icon content={atlasPersonPlus} className="w-[14px] h-[14px]" />
          </div>
          <div className="flex gap-2 items-center">Add {searchTerm}</div>
        </div>
      </OptionItem>
    </OptionalTooltip>
  );
}
