import { useFetchedInterviewerCalendarEvents } from "client/calendar-events/hooks/useFetchedInterviewerCalendarEvents";
import { useSyncFetchedDataForSlotCalculations } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import {
  FormDataForSlotCalculations,
  InterviewerToFetchForSlotCalculations,
} from "client/components/interviewer-slots/utils/types";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { UpsertScheduledInterview } from "../utils/types";
import { UpsertScheduledInterviewFormState } from "./useUpsertScheduledInterviewFormState";

/** Get form data for slot calculcations from upsert form state */
export function useUpsertScheduledInterviewFormDataForScheduling({
  selectedInterview,
  originalInterview,
  guideId,
}: {
  selectedInterview: UpsertScheduledInterview;
  guideId: string;
  originalInterview: UpsertScheduledInterview | null;
}): FormDataForSlotCalculations {
  return useMemo(
    (): FormDataForSlotCalculations => ({
      guideId,
      selectedInterview,
      interviews: [selectedInterview],
      originalInterviews: originalInterview ? [originalInterview] : [],
    }),
    [guideId, selectedInterview, originalInterview]
  );
}

/** Subscribe to interviewer slots info and map to interviewer for fetching slot calculations */
function useInterviewerUserData(interview: UpsertScheduledInterview) {
  const allInterviewerUserData = useMemo(() => {
    return interview.interviewerSlots.flatMap(
      (interviewerSlot): InterviewerToFetchForSlotCalculations[] =>
        [
          interviewerSlot.interviewer
            ? {
                userMembershipId:
                  interviewerSlot.interviewer?.userMembership?.id,
                poolId: interviewerSlot.interviewerPoolsSetting[0]?.id,
                isQualified: true,
              }
            : null,
          interviewerSlot.shadowingInterviewer
            ? {
                userMembershipId:
                  interviewerSlot.shadowingInterviewer?.userMembership?.id,
                poolId: interviewerSlot.interviewerPoolsSetting[0]?.id,
                isQualified: false,
              }
            : null,
        ].filter(filterOutNullsAndUndefined)
    );
  }, [interview]);
  return allInterviewerUserData;
}

function useUpsertFormCalendarEventsForSlotConflictCalculations({
  selectedInterview,
  interviewers,
}: {
  selectedInterview: UpsertScheduledInterview;
  interviewers: InterviewerToFetchForSlotCalculations[];
}) {
  const userMembershipIds = useMemo(
    () => interviewers.map((i) => i.userMembershipId),
    [interviewers]
  );
  const { startTime, endTime } = useMemo(
    () => ({
      startTime: selectedInterview?.startTime
        ? DateTime.fromISO(selectedInterview.startTime)
        : null,
      endTime: selectedInterview?.endTime
        ? DateTime.fromISO(selectedInterview.endTime)
        : null,
    }),
    [selectedInterview?.startTime, selectedInterview?.endTime]
  );

  const result = useFetchedInterviewerCalendarEvents({
    userMembershipIds,
    startTime,
    endTime,
    skip: !selectedInterview.startTime,
    filterInterviews: [selectedInterview],
  });

  return result;
}

export function useSyncUpsertScheduledInterviewFormStateForSlotCalculations({
  state,
  guideId,
}: {
  state: UpsertScheduledInterviewFormState;
  guideId: string;
}) {
  const selectedInterview = useWatch({
    control: state.form.control,
    name: "scheduledInterview",
  });
  const interviewers = useInterviewerUserData(selectedInterview);
  const formData = useUpsertScheduledInterviewFormDataForScheduling({
    guideId,
    selectedInterview,
    originalInterview: state.originalInterview,
  });
  const calendarEventsResult =
    useUpsertFormCalendarEventsForSlotConflictCalculations({
      selectedInterview,
      interviewers,
    });

  useSyncFetchedDataForSlotCalculations({
    interviewers,
    formData,
    calendarEvents: calendarEventsResult.loading
      ? null
      : calendarEventsResult.calendarEvents,
  });

  return {
    formDataForSlotCalculations: formData,
  };
}
