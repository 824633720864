import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { getReadableTimezone } from "shared/utils/timezones";

import { useCalendarData } from "../../hooks/data";
import { useCalendarOptions } from "../../hooks/options";
import { useSetHeaderRef } from "../../hooks/refs";
import {
  useCalendarSetSelectedDay,
  useCalendarSettings,
  useCalendarTimezone,
  useCalendarView,
  useSetCalendarView,
} from "../../hooks/settings";
import { useCalendarSizes } from "../../hooks/sizing";
import { ColumnType, useColumns } from "../../hooks/useColumns";
import { EventsGridSizingWrapper } from "../events/EventsGridSizingWrapper";
import { AllDayEventsView } from "./AllDayEventsView";

/** Display the sticky column headers for both day and week view. Scrolls horizontally with overflow. */
export function ColumnsHeader() {
  const setHeaderRef = useSetHeaderRef();
  const { leftOffsetWidth, columnsHeaderHeight, allDayEventsViewHeight } =
    useCalendarSizes();
  const { allDayEvents } = useCalendarData();
  const timezone = useCalendarTimezone();
  const heightRem = useMemo(() => {
    if (!allDayEvents.length) {
      return columnsHeaderHeight.rem;
    }

    return columnsHeaderHeight.rem + allDayEventsViewHeight.rem;
  }, [allDayEvents.length, allDayEventsViewHeight, columnsHeaderHeight]);

  return (
    <div
      className={clsx("sticky top-0 flex bg-white")}
      ref={(r) => setHeaderRef(r)}
      id="day-headers-view-portal"
      style={{ zIndex: 31 }}
    >
      <div
        className="sticky flex h-full items-end left-0 top-0 bg-white border-r border-b border-gray-border p-1"
        style={{
          width: `${leftOffsetWidth.rem}rem`,
          minWidth: `${leftOffsetWidth.rem}rem`,
          height: `${heightRem}rem`,
          zIndex: 31,
        }}
      >
        <div className="text-subtle text-[11px]">
          {getReadableTimezone({
            timezone,
            labelType: "abbreviation-with-offset",
          })}
        </div>
      </div>
      <div className="flex flex-col w-full">
        <CalendarHeadersView />
        <AllDayEventsView />
      </div>
    </div>
  );
}

function CalendarHeadersView({ className }: { className?: string }) {
  const { columnIds, columnType } = useColumns();
  const { calendarsGrouping } = useCalendarOptions();
  const { minColumnWidth, columnsHeaderHeight } = useCalendarSizes();
  const { view, dayViewType } = useCalendarSettings();
  const { allDayEvents } = useCalendarData();

  const hasColumnGroupings = useMemo(() => {
    return (
      view === "day" &&
      dayViewType === "calendar_and_grouping" &&
      calendarsGrouping &&
      calendarsGrouping.length > 0
    );
  }, [calendarsGrouping, dayViewType, view]);

  return (
    <EventsGridSizingWrapper
      className={clsx(
        "text-sm flex w-full",
        "bg-white divide-x divide-gray-border",
        !allDayEvents.length && "border-b border-gray-border",
        className
      )}
      style={{
        gridTemplateRows: hasColumnGroupings ? "16px 64px" : "1fr",
        height: `${columnsHeaderHeight.rem}rem`,
      }}
    >
      {hasColumnGroupings && <ColumnsGroupingHeader />}
      {columnIds.map((columnId, idx) => (
        <li
          key={`${columnId}-${idx}`}
          style={{
            gridRow: hasColumnGroupings && view === "day" ? 2 : 1,
            gridColumnStart: idx + 1,
            minWidth: `${minColumnWidth.rem}rem`,
            // Hide the left border of the first column
            borderLeftWidth: idx === 0 ? 0 : undefined,
            overflow: "hidden",
          }}
        >
          <ColumnDisplay columnId={columnId} columnType={columnType} />
        </li>
      ))}
    </EventsGridSizingWrapper>
  );
}

function ColumnsGroupingHeader() {
  const { calendarsGrouping } = useCalendarOptions();
  const { dayViewType } = useCalendarSettings();
  const { minColumnWidth } = useCalendarSizes();

  if (dayViewType !== "calendar_and_grouping" || !calendarsGrouping) {
    return null;
  }

  return (
    <>
      {calendarsGrouping.map((grouping, calendarGroupIdx, allGroups) => {
        const previousColumnCount = allGroups
          .slice(0, calendarGroupIdx)
          .flatMap((g) => g.calendarIds).length;

        return (
          <li
            key={grouping.id}
            style={{
              gridRow: 1,
              gridColumnStart: previousColumnCount + 1,
              gridColumnEnd:
                previousColumnCount + grouping.calendarIds.length + 1,
              minWidth: `${minColumnWidth.rem}rem`,
            }}
          >
            {grouping.label}
          </li>
        );
      })}
    </>
  );
}

function ColumnDisplay({
  columnId,
  columnType,
}: {
  columnId: string;
  columnType: ColumnType;
}) {
  if (columnType === "day") {
    // If column type is day, then the columnId is an ISO date string
    const date = DateTime.fromISO(columnId, { setZone: true });
    const prefix = date.toFormat("EEE");
    const isToday = date.hasSame(DateTime.now(), "day");

    return (
      <DayDisplayHeader
        prefix={prefix}
        day={date.day}
        isToday={isToday}
        date={date}
      />
    );
  }

  return <CalendarDisplayHeader calendarId={columnId} />;
}

function DayDisplayHeader({
  prefix,
  day,
  isToday,
  date,
}: {
  prefix: string;
  day: number;
  isToday: boolean;
  date: DateTime;
}) {
  const view = useCalendarView();
  const setCurrentDay = useCalendarSetSelectedDay();
  const setView = useSetCalendarView();
  // When in week view, make day headers clickable to switch to day view on that day
  const wrapperProps =
    view === "day"
      ? {}
      : {
          className: "cursor-pointer hover:bg-purple-50",
          onClick: () => {
            setView("day");
            setCurrentDay(date);
          },
        };

  return (
    <div
      {...wrapperProps}
      className={clsx(
        "flex items-center justify-center mx-2 py-3 rounded-md overflow-hidden h-10",
        wrapperProps.className
      )}
      data-cy={CypressData.calendar.dayHeader}
    >
      <span
        className={clsx(
          "text-body-md flex items-baseline",
          isToday ? "text-purple-300" : "text-subtle"
        )}
      >
        {prefix}{" "}
        <span
          className={clsx(
            "ml-1.5 flex items-center justify-center text-body-md-heavy",
            isToday
              ? "h-8 w-8 rounded-full bg-purple-500 text-white"
              : "text-dark"
          )}
        >
          {day}
        </span>
      </span>
    </div>
  );
}

function CalendarDisplayHeader({ calendarId }: { calendarId: string }) {
  const { getCalendarDisplayInfo } = useCalendarOptions();

  const Display = getCalendarDisplayInfo?.(calendarId);

  return (
    <div className="relative flex items-center justify-start py-[.9375rem] px-3 w-full inset-[0.5px] overflow-hidden">
      {Display || calendarId}
    </div>
  );
}
