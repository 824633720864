import { useRender } from "@resource/atlas/content-editor/renderer";
import {
  ValueSet,
  VariableRendererProvider,
} from "@resource/atlas/content-editor/variables";
import { SerializedEditorState } from "lexical";
import { ComponentPropsWithoutRef } from "react";
import { renderScheduledInterviewDetails } from "shared/guide-scheduler/scheduled-interview-details/renderer";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";

type ScheduledInterviewDetailsLiteRendererProps =
  ComponentPropsWithoutRef<"div"> & {
    content: unknown;
    valueSet?: ValueSet<ScheduledInterviewDetailsVariableSet>;
  };

export function ScheduledInterviewDetailsLiteRenderer({
  content,
  valueSet,
  className,
  ...props
}: ScheduledInterviewDetailsLiteRendererProps) {
  const renderedContent = useRender(
    renderScheduledInterviewDetails,
    content as SerializedEditorState
  );

  if (!renderedContent) {
    return null;
  }

  return (
    <VariableRendererProvider valueSet={valueSet}>
      <div {...props} className={className}>
        {renderedContent}
      </div>
    </VariableRendererProvider>
  );
}
