import Router from "next/router";
import { useCallback, useEffect } from "react";
import { useBeforeUnload } from "react-use";

export type TriggerConfirmationFn = () => boolean;
export type ConfirmationHandler = {
  showConfirmation: boolean;
  triggerConfirmation: TriggerConfirmationFn;
};

const useLeaveConfirmation = (
  message: string,
  showConfirmation: boolean
): ConfirmationHandler => {
  useBeforeUnload(showConfirmation, message);
  const triggerConfirmation = useCallback(
    (): boolean => (showConfirmation ? window.confirm(message) : true),
    [showConfirmation, message]
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!showConfirmation) return;
      event.preventDefault();
    };
    const handleRouteChangeStart = () => {
      if (!showConfirmation) return;
      if (!triggerConfirmation()) {
        // This doesn't work with browser back/forward:
        // https://github.com/vercel/next.js/discussions/32231
        throw Error("Expected Error: Route Canceled (safe to ignore)");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    Router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      Router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [showConfirmation, message, triggerConfirmation]);

  return { showConfirmation, triggerConfirmation };
};

export default useLeaveConfirmation;
