import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { ScheduledInterviewCardBadge } from "client/components/scheduled-interviews/__components/ScheduledInterviewCardBadge";
import { useInternalScheduledInterviewMenuItems } from "client/components/scheduled-interviews/__hooks/useInternalScheduledInterviewMenuItems";
import { mapScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/__utils/mapping";
import { CandidateScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/CandidateScheduledInterviewCard";
import { ScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/ScheduledInterviewCard";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { useOptionalModals } from "components/ModalProvider";
import { Skeleton, SkeletonCollection } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import {
  OrganizationForScheduledInterviewCardFragment,
  ScheduledInterviewForScheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";
import { find, groupBy, random } from "lodash";
import { DateTime } from "luxon";
import { ComponentPropsWithoutRef, Fragment, useMemo } from "react";

import { InterviewDetailsDialog } from "./InterviewDetailsDialog";

const INTERVIEW_FRAGMENT = gql(`
fragment InterviewListInterview on ScheduledInterview {
    id
    ...ScheduledInterviewForScheduledInterviewCard
    ...InterviewDetailsInterview
  }
`);

function InterviewItemLoading() {
  return (
    <div className="p-3 shadow-1 rounded-md w-full flex items-center gap-3">
      <div className="w-[40px] h-[40px] shadow-1 rounded-md shrink-0" />
      <div className="space-y-3 grow">
        <Skeleton className="w-2/3 h-[14px]" type="text" />
        <div className="flex gap-2 items-center">
          <Skeleton className="w-1/2 h-[14px]" type="text" />
          <div className="flex gap-[.125rem]">
            <Skeleton className="w-5 h-5" type="rect" />
            <Skeleton className="w-5 h-5" type="rect" />
          </div>
        </div>
      </div>
      <SkeletonCollection
        className=" shrink-0 flex flex-row-reverse mr-[6px]"
        count={Math.floor(random(1, 3))}
      >
        {(i) => (
          <Skeleton
            className={clsx("w-8 h-8", {
              "ring-white ring-2 -mr-1": i !== 0,
            })}
            type="circle"
          />
        )}
      </SkeletonCollection>
    </div>
  );
}

type InterviewType = "upcoming" | "past";

const DateGroupFormat = "EEEE, MMM d";

function InterviewBreak({
  diffInHours,
  className,
}: {
  diffInHours: number;
  className?: string;
}) {
  if (diffInHours <= 0) {
    return null;
  }

  const hours = Math.floor(diffInHours);
  const minutes = Math.floor((diffInHours - hours) * 60);

  let breakLabel: string;
  if (hours < 1) {
    breakLabel = `${minutes}-MIN BREAK`;
  } else {
    const minutesStr = minutes !== 0 ? ` ${minutes}-MIN` : "";
    breakLabel = `${hours}-HOUR${minutesStr} BREAK`;
  }

  return (
    <div
      className={clsx(
        "w-full text-subtle font-semibold text-xs text-center uppercase relative",
        className
      )}
    >
      <div className="w-full h-[1px] bg-light-gray-500 absolute top-1/2" />
      <span className="bg-white relative px-2">{breakLabel}</span>
    </div>
  );
}

function InterviewItemWithBreak({
  interview,
  previousInterview,
  organization,
  onSelectInterview,
  menuItemsConfigFactory,
  disableInteraction,
  hideInternalStatuses,
  hideDayHeaders,
  timezone,
  variant,
}: {
  interview: ScheduledInterviewForScheduledInterviewCardFragment;
  previousInterview?: ScheduledInterviewForScheduledInterviewCardFragment;
  organization?: OrganizationForScheduledInterviewCardFragment;
  onSelectInterview?(id: string): void;
  menuItemsConfigFactory?: MenuItemsConfigFactory;
  disableInteraction?: boolean;
  hideInternalStatuses?: boolean;
  hideDayHeaders?: boolean;
  timezone?: string;
  variant?: "candidate" | "default";
}) {
  const menuItemsConfig = menuItemsConfigFactory?.(interview.id);
  const diffInHours = previousInterview?.endTime
    ? DateTime.fromJSDate(new Date(interview.startTime)).diff(
        DateTime.fromJSDate(new Date(previousInterview.endTime)),
        "hours"
      ).hours
    : 0;
  const { isGuideCandidate, user } = useGuideAuthContext();

  return (
    <Fragment key={interview.id}>
      <InterviewBreak className="my-3" diffInHours={diffInHours} />
      {isGuideCandidate || !user ? (
        <CandidateScheduledInterviewCard
          className={clsx({
            /**
             * We need a top margin if there is a previous interview and
             * if the interviews either have less than a 1 hour gap between
             * them or they're in reverse order and the diffInHours is negative.
             * The <InterviewBreak> only shows for a positive diffInHours.
             */
            "mt-4": previousInterview && diffInHours <= 0 && !hideDayHeaders,
            "pointer-events-none": disableInteraction,
          })}
          scheduledInterview={interview}
          organization={organization}
          data-cy={
            CypressData.candidateGuides.interviews.scheduledInterviewCard
          }
          {...(interview.isCancelled
            ? {
                Badge: <ScheduledInterviewCardBadge status="cancelled" />,
                showAsPast: true,
              }
            : {})}
          {...(onSelectInterview && !interview.isCancelled
            ? {
                onClick: () => onSelectInterview?.(interview.id),
              }
            : {})}
        />
      ) : (
        // TODO: Use internal scheduled interview card?
        <ScheduledInterviewCard
          className={clsx({
            /**
             * We need a top margin if there is a previous interview and
             * if the interviews either have less than a 1 hour gap between
             * them or they're in reverse order and the diffInHours is negative.
             * The <InterviewBreak> only shows for a positive diffInHours.
             */
            "mt-4": previousInterview && diffInHours <= 0 && !hideDayHeaders,
            "pointer-events-none": disableInteraction,
          })}
          scheduledInterview={mapScheduledInterviewForCard({ interview })}
          {...(interview.isCancelled
            ? {
                Badge: <ScheduledInterviewCardBadge status="cancelled" />,
                showAsPast: true,
              }
            : {})}
          {...(onSelectInterview && !interview.isCancelled
            ? {
                onClick: () => onSelectInterview?.(interview.id),
              }
            : {})}
          menuItemsConfig={menuItemsConfig}
          hideInternalStatuses={hideInternalStatuses}
          timezone={timezone}
          data-cy={
            CypressData.candidateGuides.interviews.scheduledInterviewCard
          }
          variant={variant}
        />
      )}
    </Fragment>
  );
}

function NoInterviewsMessage({
  type,
  className,
}: {
  type: InterviewType;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        "bg-light-gray-200 h-full rounded-md flex p-6 mb-2 text-dark justify-center items-center",
        className
      )}
    >
      <div className="flex flex-col items-center text-center">
        <p className="text-body-md text-subtle mt-2">
          No {type} interviews available
        </p>
      </div>
    </div>
  );
}

type ScheduledInteviewsMenuItemsFactory = (
  interviewId: string
) => ReturnType<typeof useInternalScheduledInterviewMenuItems>;
type MenuItemsFactory = (
  interviewId: string
) => ReturnType<typeof useMenuItems>;

type MenuItemsConfigFactory =
  | ScheduledInteviewsMenuItemsFactory
  | MenuItemsFactory;

export type InterviewListProps = ComponentPropsWithoutRef<"div"> & {
  interviews: ScheduledInterviewForScheduledInterviewCardFragment[] | undefined;
  onSelectInterview?(id: string): void;
  organization?: OrganizationForScheduledInterviewCardFragment;
  type: InterviewType;
  menuItemsConfigFactory?: MenuItemsConfigFactory;
  hideDayHeaders?: boolean;
  disableInteraction?: boolean;
  hideInternalStatuses?: boolean;
  timezone?: string;
  variant?: "default" | "candidate";
};
export function InterviewList({
  interviews,
  organization,
  type,
  onSelectInterview,
  menuItemsConfigFactory,
  disableInteraction,
  hideDayHeaders,
  hideInternalStatuses,
  timezone,
  variant,
  ...props
}: InterviewListProps) {
  const modalManager = useOptionalModals();
  const interviewsGrouped = useMemo(() => {
    const interviewsWithFormattedStartDate = (interviews || []).map(
      (interview) => {
        const startTimeWithTimezone = DateTime.fromISO(
          interview.startTime
        ).setZone(DateTime.local().zoneName);
        const formattedStartDate =
          startTimeWithTimezone.toFormat(DateGroupFormat);
        return {
          ...interview,
          formattedStartDate,
        };
      }
    );

    return groupBy(interviewsWithFormattedStartDate, "formattedStartDate");
  }, [interviews]);

  if (!interviews) {
    return (
      <SkeletonCollection className="flex flex-col space-y-2 w-full" count={2}>
        <InterviewItemLoading />
      </SkeletonCollection>
    );
  }

  const selectedInterview = find(interviews, { id: modalManager?.state.id });
  return (
    <>
      <div
        {...props}
        className={clsx(
          "flex flex-col w-full",
          {
            "gap-6": !hideDayHeaders,
            "gap-2": hideDayHeaders,
          },
          props.className
        )}
      >
        {interviews.length ? (
          Object.keys(interviewsGrouped).map((date) => {
            const isToday = DateTime.fromFormat(date, DateGroupFormat).hasSame(
              DateTime.now(),
              "day"
            );

            return (
              <section key={date} className="space-y-2">
                {!hideDayHeaders && (
                  <header
                    className={clsx(
                      "text-body-md-heavy",
                      type === "past" ? "text-subtle" : "text-dark"
                    )}
                  >
                    {isToday ? "Today" : date}
                  </header>
                )}
                <div className="flex flex-col group">
                  {interviewsGrouped[date].map((interview, interviewIndex) => {
                    return (
                      <InterviewItemWithBreak
                        key={interview.id}
                        interview={interview}
                        previousInterview={
                          interviewsGrouped[date][interviewIndex - 1]
                        }
                        organization={organization}
                        onSelectInterview={onSelectInterview}
                        menuItemsConfigFactory={menuItemsConfigFactory}
                        disableInteraction={disableInteraction}
                        hideInternalStatuses={hideInternalStatuses}
                        hideDayHeaders={hideDayHeaders}
                        timezone={timezone}
                        variant={variant}
                      />
                    );
                  })}
                </div>
              </section>
            );
          })
        ) : (
          <NoInterviewsMessage type={type} />
        )}
      </div>
      {selectedInterview && (
        <InterviewDetailsDialog interview={selectedInterview} />
      )}
    </>
  );
}

InterviewList.fragments = {
  interview: INTERVIEW_FRAGMENT,
};
