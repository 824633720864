import { yupResolver } from "@hookform/resolvers/yup";
import {
  AtlasContentEditorSerializedState,
  useContentEditor,
} from "@resource/atlas/content-editor";
import { useAuthContext } from "auth/context";
import { UserMembershipForPreferencesFragment } from "generated/graphql-codegen/graphql";
import { HourNumbers, MinuteNumbers } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { areWorkingHoursConfigsEqual } from "shared/utils/working-hours";
import * as yup from "yup";

import { PreferencesFormData } from "../types";

const defaultValues = {
  workingHours: [
    { isWorkingDay: false },
    { isWorkingDay: false },
    { isWorkingDay: false },
    { isWorkingDay: false },
    { isWorkingDay: false },
    { isWorkingDay: false },
    { isWorkingDay: false },
  ],
  timezone: "UTC",
  maxInterviewLoadPerDay: null,
  maxInterviewLoadPerWeek: null,
  addAsOptionalParticipantForScheduling: false,
  receiveNotificationsWhenRecruiter: false,
  receiveNotificationsWhenCoordinator: false,
  defaultCalendarId: null,
};

const schema = yup
  .object({
    timezone: yup.string(),
    workingHours: yup.array().of(
      yup.object({
        isWorkingDay: yup.boolean(),
        startTime: yup
          .object({
            hour: yup.number().min(0).max(23),
            minute: yup.number().min(0).max(59),
          })
          .when("isWorkingDay", {
            is: true,
            then: (startTimeSchema) => startTimeSchema.required(),
          })
          .strict(),
        endTime: yup
          .object({
            hour: yup.number().min(0).max(23),
            minute: yup.number().min(0).max(59),
          })
          .when("isWorkingDay", {
            is: true,
            then: (endTimeSchema) => endTimeSchema.required(),
          })
          .strict(),
      })
    ),
    maxInterviewLoadPerDay: yup.number().min(0).optional().nullable(),
    maxInterviewLoadPerWeek: yup.number().min(0).optional().nullable(),
    addAsOptionalParticipantForScheduling: yup.boolean(), // TODO: Add validation for addAsOptionalParticipantForScheduling
    receiveNotificationsWhenRecruiter: yup.boolean(),
    receiveNotificationsWhenCoordinator: yup.boolean(),
    defaultCalendarId: yup.string().nullable(),
    outOfOfficeConfig: yup
      .object({
        startAt: yup.date().required(),
        endAt: yup.date().required(),
        data: yup
          .object()
          .required()
          .test({
            name: "message-not-empty",
            test: function test(value, context) {
              const v = value as AtlasContentEditorSerializedState | null;
              const nonEmptyNodeAtRoot = v?.root.children.find(
                // @ts-expect-error children should exist for this to be considered not empty.
                (node) => !!node.children?.length
              );

              return !nonEmptyNodeAtRoot
                ? this.createError({
                    message: `Message is required`,
                    path: context.path,
                  })
                : true;
            },
          }),
      })
      .optional()
      .nullable(),
  })
  .required();

export type UseEditPreferencesStateProps = {
  userMembership: UserMembershipForPreferencesFragment;
  disableWithoutChanges?: boolean;
};

export function useEditPreferencesState({
  userMembership,
  disableWithoutChanges = true,
}: UseEditPreferencesStateProps) {
  const form = useForm<PreferencesFormData>({
    defaultValues: {
      ...defaultValues,
      timezone: userMembership.user.timezone ?? undefined,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const { user } = useAuthContext();
  const { reset, watch, setValue } = form;
  const watchedWorkingHours = watch("workingHours");

  useEffect(() => {
    const isWorkingHoursDirty = !areWorkingHoursConfigsEqual(
      userMembership.workingHours,
      watchedWorkingHours
    );
    if (isWorkingHoursDirty !== form.formState.isDirty) {
      setValue("workingHours", watchedWorkingHours, {
        shouldDirty: isWorkingHoursDirty,
      });
    }
  }, [watchedWorkingHours, userMembership, setValue, form.formState.isDirty]);

  const [
    workingHoursInvalidTimeRangesErrors,
    setWorkingHoursInvalidTimeRangesErrors,
  ] = useState<Record<string, boolean>>({});

  const { editor, contentEditorProps } = useContentEditor();

  const resetForm = useCallback(() => {
    if (userMembership) {
      // TODO: set show out of office conditionally
      reset({
        workingHours:
          userMembership.workingHours.map((config) => {
            return {
              isWorkingDay: config.isWorkingDay,
              startTime: config.startTime
                ? {
                    hour: config.startTime.hour as HourNumbers,
                    minute: config.startTime.minute as MinuteNumbers,
                  }
                : undefined,
              endTime: config.endTime
                ? {
                    hour: config.endTime.hour as HourNumbers,
                    minute: config.endTime.minute as MinuteNumbers,
                  }
                : undefined,
            };
          }) ?? defaultValues.workingHours,
        timezone: userMembership.user.timezone ?? "UTC",
        maxInterviewLoadPerDay:
          userMembership.maxInterviewLoadPerDay ??
          defaultValues.maxInterviewLoadPerDay,
        maxInterviewLoadPerWeek:
          userMembership.maxInterviewLoadPerWeek ??
          defaultValues.maxInterviewLoadPerWeek,
        addAsOptionalParticipantForScheduling:
          userMembership.addAsOptionalParticipantForScheduling ?? false,
        receiveNotificationsWhenRecruiter:
          userMembership.receiveNotificationsWhenRecruiter ?? false,
        receiveNotificationsWhenCoordinator:
          userMembership.receiveNotificationsWhenCoordinator ?? false,
        defaultCalendarId: userMembership.defaultCalendarId ?? null,
        outOfOfficeConfig: userMembership.autoResponse
          ? {
              data: userMembership.autoResponse.data,
              startAt: new Date(userMembership.autoResponse.startAt),
              endAt: new Date(userMembership.autoResponse.endAt),
            }
          : null,
      });
    }
  }, [reset, userMembership]);

  useEffect(resetForm, [resetForm]);

  const isSelf = useMemo(() => {
    return userMembership.user.id === user?.id;
  }, [user, userMembership.user.id]);

  const disabledTooltipContent = useMemo(() => {
    if (!form.formState.isDirty && disableWithoutChanges) {
      return "No changes have been made.";
    }

    if (Object.keys(workingHoursInvalidTimeRangesErrors).length) {
      return "Working hours has an invalid time range. Please make sure the start time is before the end time.";
    }

    const errorKeys = Object.keys(form.formState.errors);
    if (errorKeys.length) {
      return `Invalid ${errorKeys[0]}.`;
    }

    return undefined;
  }, [
    disableWithoutChanges,
    form.formState.errors,
    form.formState.isDirty,
    workingHoursInvalidTimeRangesErrors,
  ]);

  const onInvalidTimeRange = useCallback(
    (id: string) => {
      setWorkingHoursInvalidTimeRangesErrors((prev) => {
        return { ...prev, [id]: true };
      });
    },
    [setWorkingHoursInvalidTimeRangesErrors]
  );

  const onValidTimeRange = useCallback(
    (id: string) => {
      setWorkingHoursInvalidTimeRangesErrors((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    },
    [setWorkingHoursInvalidTimeRangesErrors]
  );

  return useMemo(() => {
    return {
      contentEditorProps,
      editor,
      form,
      resetForm,
      isSelf,
      userMembership,
      disabledTooltipContent,
      onInvalidTimeRange,
      onValidTimeRange,
    };
  }, [
    contentEditorProps,
    disabledTooltipContent,
    editor,
    form,
    isSelf,
    onInvalidTimeRange,
    onValidTimeRange,
    resetForm,
    userMembership,
  ]);
}

export type EditPreferencesState = ReturnType<typeof useEditPreferencesState>;
