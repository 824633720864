import { View, ViewProps } from "@resource/atlas/view/View";

import ErrorPage, { ErrorProps } from "./ErrorPage";

// TODO: Rename to `ViewError`
export function DialogError({
  onRecover,
  errorCode,
  error,
  ...props
}: ViewProps & ErrorProps) {
  return (
    <View {...props}>
      <ErrorPage onRecover={onRecover} errorCode={errorCode} error={error} />
    </View>
  );
}
