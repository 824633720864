import { useLazyQuery } from "@apollo/client";
import deepDiff from "deep-diff";
import { gql } from "generated/graphql-codegen";
import { useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import {
  SchedulingHoursConfig,
  SelfScheduleSchedulingSettingsInput,
} from "shared/self-schedule/types";
import { getDefaultSelfSchedulingSettings } from "shared/self-schedule/utils";

import { EditSelfScheduleInterviewSettingsState } from "./useEditSelfScheduleInterviewSettingsState";
import { EditSelfSchedulingSettingsState } from "./useEditSelfSchedulingSettingsState";

const FETCH_INTERVIEWER_DEFAULT_SETTINGS_FOR_SYNCING = gql(`
  query FetchInterviewerDefaultSettingsForSyncing($interviewerId: String!) {
    userMembershipById(id: $interviewerId) {
      ...UserMembershipForEditSelfScheduleSettings
    }
  }
`);

type UseSyncSettingsWithInterviewer = {
  editInterviewSettingsState: EditSelfScheduleInterviewSettingsState;
  editSchedulingSettingsState: EditSelfSchedulingSettingsState;
};

/** If we select a different interviewer, we should  */
export function useSyncSettingsWithInterviewer({
  editInterviewSettingsState,
  editSchedulingSettingsState,
}: UseSyncSettingsWithInterviewer) {
  const schedulingHours =
    editSchedulingSettingsState.form.watch("schedulingHours");
  const minimumNotice = editSchedulingSettingsState.form.watch("minimumNotice");
  const preMeetingBuffer =
    editSchedulingSettingsState.form.watch("preMeetingBuffer");
  const postMeetingBuffer =
    editSchedulingSettingsState.form.watch("postMeetingBuffer");
  const rollingDateRange =
    editSchedulingSettingsState.form.watch("rollingDateRange");
  const [fetchInterviewerSettings, { loading }] = useLazyQuery(
    FETCH_INTERVIEWER_DEFAULT_SETTINGS_FOR_SYNCING
  );
  const [currentInterviewerSettings, setCurrentInterviewerSettings] =
    useState<SelfScheduleSchedulingSettingsInput | null>(null);
  const prevInterviewerId = usePrevious(
    editInterviewSettingsState.interviewer.id
  );

  useEffect(() => {
    if (
      prevInterviewerId &&
      prevInterviewerId !== editInterviewSettingsState.interviewer.id
    ) {
      fetchInterviewerSettings({
        variables: {
          interviewerId: editInterviewSettingsState.interviewer.id,
        },
        onCompleted: (data) => {
          const newDefaults =
            data.userMembershipById?.defaultSelfSchedulingSettings ??
            getDefaultSelfSchedulingSettings();

          editSchedulingSettingsState.form.setValue(
            "schedulingHours",
            newDefaults.schedulingHours as SchedulingHoursConfig,
            {
              shouldDirty: true,
            }
          );
          editSchedulingSettingsState.form.setValue(
            "minimumNotice",
            newDefaults.minimumNotice,
            {
              shouldDirty: true,
            }
          );
          editSchedulingSettingsState.form.setValue(
            "preMeetingBuffer",
            newDefaults.preMeetingBuffer,
            {
              shouldDirty: true,
            }
          );
          editSchedulingSettingsState.form.setValue(
            "postMeetingBuffer",
            newDefaults.postMeetingBuffer,
            {
              shouldDirty: true,
            }
          );
          editSchedulingSettingsState.form.setValue(
            "rollingDateRange",
            newDefaults.rollingDateRange,
            {
              shouldDirty: true,
            }
          );

          setCurrentInterviewerSettings({
            ...newDefaults,
            schedulingHours:
              newDefaults.schedulingHours as SchedulingHoursConfig,
          });
        },
      });
    }
  }, [
    editInterviewSettingsState.interviewer,
    editInterviewSettingsState.interviewer.id,
    editSchedulingSettingsState.form,
    fetchInterviewerSettings,
    prevInterviewerId,
  ]);

  const interviewerSettingsHaveChanged = useMemo(() => {
    if (!editSchedulingSettingsState.form.formState.isDirty) {
      return false;
    }
    return deepDiff(
      {
        schedulingHours: currentInterviewerSettings?.schedulingHours,
        minimumNotice: currentInterviewerSettings?.minimumNotice,
        preMeetingBuffer: currentInterviewerSettings?.preMeetingBuffer,
        postMeetingBuffer: currentInterviewerSettings?.postMeetingBuffer,
        rollingDateRange: currentInterviewerSettings?.rollingDateRange,
      },
      {
        schedulingHours,
        minimumNotice,
        preMeetingBuffer,
        postMeetingBuffer,
        rollingDateRange,
      }
    );
  }, [
    currentInterviewerSettings?.minimumNotice,
    currentInterviewerSettings?.postMeetingBuffer,
    currentInterviewerSettings?.preMeetingBuffer,
    currentInterviewerSettings?.rollingDateRange,
    currentInterviewerSettings?.schedulingHours,
    editSchedulingSettingsState.form.formState.isDirty,
    minimumNotice,
    postMeetingBuffer,
    preMeetingBuffer,
    rollingDateRange,
    schedulingHours,
  ]);

  return useMemo(() => {
    return {
      loading,
      interviewerSettingsHaveChanged,
    };
  }, [interviewerSettingsHaveChanged, loading]);
}
