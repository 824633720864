import FeatureFlags from "generated/FeatureFlags";
import { useFlags } from "launchdarkly-react-client-sdk";

export default function useExternalInterviewersAlgorithmVisible() {
  const {
    [FeatureFlags.USE_EXTERNAL_INTERVIEWER_ALGORITHM_V_2]:
      allowExternalInterviewersAlgorithm,
  } = useFlags();
  return allowExternalInterviewersAlgorithm;
}
