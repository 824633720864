import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { LexicalEditor } from "lexical";
import { useLayoutEffect, useRef, useState } from "react";

import { useStaticValue } from "../__utils/react";
import type { AtlasContentEditorProps } from "./types";

// use content editor
// ------------------

/**
 * Obtains the `LexicalEditor` instance for a `ContentEditor`.
 *
 * To set it up, spread `contentEditorProps` onto the target `ContentEditor` component.
 *
 * @example
 * const { editor, contentEditorProps } = useContentEditor();
 * <ContentEditor {...contentEditorProps} />;
 */
export function useContentEditor() {
  const editorRef = useRef<LexicalEditor>();

  function getEditorInstance() {
    const { current: editorInstance } = editorRef;
    if (!editorInstance)
      throw new Error(
        "Editor instance not found, did you forget to pass 'contentEditorProps' to the ContentEditor component?"
      );
    return editorInstance;
  }

  // this "editor" is a pass-through proxy that lets us use the editor instance directly and in a
  // non-nullable way, instead of having to use the nullable and mutable ref
  const editor = useStaticValue(
    () =>
      new Proxy(
        {} as LexicalEditor,
        // class instance "passthrough" proxy handler (yes, using another proxy :D)
        new Proxy(
          {
            get(_, prop) {
              const editorInstance = getEditorInstance();
              const fnOrValue = editorInstance[prop as keyof LexicalEditor];

              // bind "this"
              if (fnOrValue instanceof Function)
                return fnOrValue.bind(editorInstance);

              return fnOrValue;
            },
          },
          {
            // reflect everything else and replace the target with the instance, which should
            // always work assuming that these proxy handler methods won't ever be called:
            // apply, construct, set (they would also require additional "this"/receiver binding)
            get(target, prop) {
              const editorInstance = getEditorInstance();
              const value = target[prop as keyof typeof target];
              if (value) return value;
              return (_: unknown, ...args: unknown[]) =>
                // @ts-expect-error It's fine.
                Reflect[prop as keyof typeof Reflect](editorInstance, ...args);
            },
          }
        )
      )
  );

  const contentEditorProps: AtlasContentEditorProps = {
    // @ts-expect-error Private prop.
    __registerEditor: (val: LexicalEditor) => {
      editorRef.current = val;
    },
  };

  return { editor, contentEditorProps };
}

export function useOptionalContentEditor() {
  const [rerenderEditor, setRerenderEditor] = useState(0);
  const editorRef = useRef<LexicalEditor>();

  const contentEditorProps: AtlasContentEditorProps = {
    // @ts-expect-error Private prop.
    __registerEditor: (val: LexicalEditor) => {
      editorRef.current = val;

      if (rerenderEditor === 0) {
        // We need to trigger a re-render once the editor gets registered
        setRerenderEditor(1);
      }
    },
  };

  return { editor: editorRef.current ?? null, contentEditorProps };
}

// register editor
// ---------------

type RegisterEditorProps = {
  registerEditor?: (editor: LexicalEditor) => void;
};

export function RegisterEditor({ registerEditor }: RegisterEditorProps) {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    registerEditor?.(editor);
  }, [editor, registerEditor]);

  return null;
}
