import { useEffect, useMemo, useState } from "react";
import { SchedulingRequestStatus } from "shared/scheduling-requests/types";

import type { SchedulingTaskAndAvailabilityWorkflowsDisplayProps } from "../SchedulingTaskAndAvailabilityWorkflows";
import type { SchedulingRequestFormType } from "./useSchedulingRequestFormState";

type UseWorkflowStateForGuideProps = Pick<
  SchedulingTaskAndAvailabilityWorkflowsDisplayProps,
  | "backIcon"
  | "onBack"
  | "guide"
  | "skipRequestingAvailability"
  | "defaultSchedulingRequestFormType"
>;

export function useWorkflowStateForGuide({
  guide,
  backIcon,
  onBack,
  skipRequestingAvailability,
  defaultSchedulingRequestFormType = "select-task",
}: UseWorkflowStateForGuideProps) {
  const { upcomingInterviews, schedulingRequests } = guide;
  const hasOpenSchedulingRequests =
    schedulingRequests.filter((task) =>
      (
        [
          SchedulingRequestStatus.DRAFT,
          SchedulingRequestStatus.IN_PROGRESS,
        ] as SchedulingRequestStatus[]
      ).includes(task.status)
    ).length > 0;
  const hasScheduledInterviews = useMemo(() => {
    return upcomingInterviews.length > 0;
  }, [upcomingInterviews]);
  const [schedulingRequestFormType, setSchedulingRequestFormType] =
    useState<SchedulingRequestFormType>(defaultSchedulingRequestFormType);

  useEffect(() => {
    if (
      defaultSchedulingRequestFormType === "select-task" &&
      !hasOpenSchedulingRequests
    ) {
      setSchedulingRequestFormType("scheduling");
    }
  }, [defaultSchedulingRequestFormType, hasOpenSchedulingRequests]);

  const shouldRequestAvailability = useMemo(() => {
    return !skipRequestingAvailability;
  }, [skipRequestingAvailability]);

  const shouldCreateSchedulingTask = useMemo(() => {
    return schedulingRequestFormType !== "select-task";
  }, [schedulingRequestFormType]);

  const workflowTitle = useMemo(() => {
    if (skipRequestingAvailability) {
      return "Create scheduling task";
    }

    return "Request availability";
  }, [skipRequestingAvailability]);

  // TODO: Implement this
  // const interviewersForReportingReason = useMemo(() => {
  //   const allGuideInterviews = guide.upcomingInterviews.flatMap((i) => i);
  //   return mapInterviewersToInterviewerMultiSelect();
  // }, []);

  return useMemo(
    () => ({
      hasScheduledInterviews,
      hasOpenSchedulingRequests,
      shouldRequestAvailability,
      shouldCreateSchedulingTask,
      workflowTitle,
      backIcon,
      onBack,
      schedulingRequestFormType,
      setSchedulingRequestFormType,
      skipRequestingAvailability,
    }),
    [
      hasScheduledInterviews,
      hasOpenSchedulingRequests,
      shouldRequestAvailability,
      shouldCreateSchedulingTask,
      workflowTitle,
      backIcon,
      onBack,
      schedulingRequestFormType,
      skipRequestingAvailability,
    ]
  );
}

export type WorkflowStateForGuide = ReturnType<typeof useWorkflowStateForGuide>;
