import { DateTime } from "luxon";
import { useMemo } from "react";

import { useStagedInterviews } from "./interviews";
import { useSchedulerOrganization } from "./useSchedulerOrganization";

type BlockedDateRange = {
  startDay: DateTime;
  endDay: DateTime;
};

export type BlockedDateConflicts = {
  blockedDates: BlockedDateRange[];
};

export type InterviewsWithBlockedDatesMap = {
  [interviewId: string]: BlockedDateConflicts;
};

/**
 * Get map of interviews with conflicting organization blocked dates
 */
export function useInterviewsWithBlockedDatesMap() {
  const interviews = useStagedInterviews();
  const { blockedDates } = useSchedulerOrganization();

  return useMemo(() => {
    return interviews.reduce((acc, interview) => {
      const blockedDateConflicts =
        interview?.startTime && interview.endTime
          ? blockedDates.filter((blockedDate) => {
              const interviewStart = DateTime.fromISO(interview.startTime!);
              const interviewEnd = DateTime.fromISO(interview.endTime!);
              const blockStart = blockedDate.startDay.startOf("day");
              const blockEnd = blockedDate.endDay.endOf("day");

              return (
                (interviewStart >= blockStart && interviewStart <= blockEnd) ||
                (interviewEnd >= blockStart && interviewEnd <= blockEnd)
              );
            })
          : [];

      if (blockedDateConflicts.length) {
        return {
          ...acc,
          [interview.id]: {
            blockedDates: blockedDateConflicts,
          },
        };
      }

      return acc;
    }, {} as InterviewsWithBlockedDatesMap);
  }, [interviews, blockedDates]);
}
