/* eslint-disable @typescript-eslint/no-explicit-any, import/prefer-default-export */
import "./VariablesPlugin.sass";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useMemo } from "react";

import { createComponentUtils } from "../../../__utils/atlas";
import { Menu } from "../../../menu";
import { useMenuItems } from "../../../menu/use-menu-items";
import {
  useTypeahead,
  useTypeaheadMatch,
  useTypeaheadMenu,
} from "../../__utils/typeahead";
import { useVariablesConfig } from "./config";
import { Preset } from "./types";
import { $createVariableNode } from "./VariableNode";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Variable_plugin";

const { el } = createComponentUtils(COMPONENT_NAME);

// variables plugin
// ----------------

export function VariablesPlugin(): JSX.Element | null {
  const matchFn = useTypeaheadMatch("$", { validChars: "[a-zA-Z0-9\\.]" });
  const typeahead = useTypeahead({ matchFn });

  const { specSet, valueSet = {}, timezone } = useVariablesConfig();

  const filteredEntries = useMemo(() => {
    const entries = Object.entries(specSet);
    const query = typeahead.match?.queryString;
    if (!query) return entries;

    const results: typeof entries = [];
    results.push(...entries.filter(([, { name }]) => name.startsWith(query)));
    results.push(
      ...entries.filter(([, { name }]) =>
        query
          .split(".")
          .every((part) => name.split(".").some((p) => p.startsWith(part)))
      )
    );
    return [...new Set(results)];
  }, [specSet, typeahead.match]);

  const entriesWithPresets = useMemo(() => {
    const entries: [
      string,
      (typeof filteredEntries)[number][1] & { preset?: Preset<any> }
    ][] = [];
    filteredEntries.forEach(([id, spec]) => {
      entries.push([id, spec]);
      const presets = (spec as any).presets as Preset<any>[] | undefined;
      if (presets?.length === 0) return;
      presets?.forEach((preset) => {
        entries.push([id, { ...spec, preset }]);
      });
    });
    return entries;
  }, [filteredEntries]);

  const [editor] = useLexicalComposerContext();
  const { $replaceMatch } = typeahead;
  const { menu, menuContentProps } = useTypeaheadMenu({
    typeahead,
    items: useMenuItems(
      (i) =>
        entriesWithPresets.map(([id, { name, preset, renderVariable }]) => {
          const baseConfig =
            preset?.defaultConfig ?? (specSet[id] as any).defaultConfig ?? {};
          const config = {
            ...baseConfig,
            ...{ timezone },
          };

          return i.item({
            key: `${id}${preset?.label ?? ""}`,
            children: name,
            renderContent: ({ children }) => {
              const value = valueSet[id];
              return (
                <div className={el`menu-item`}>
                  <div>
                    <span className="variable-name">${children}</span>
                    {preset && (
                      <span className="preset-badge">{preset.label}</span>
                    )}
                  </div>
                  <div className="spacer" />
                  {value !== undefined && value !== "" ? (
                    <span className="value-preview">
                      <span>“</span>
                      <span className="value-preview-content">
                        {renderVariable({
                          value,
                          config,
                        })}
                      </span>
                      <span>”</span>
                    </span>
                  ) : null}
                </div>
              );
            },
            onClick: () =>
              editor.update(() => {
                const variableNode = $createVariableNode(
                  id,
                  valueSet[id],
                  config
                );
                $replaceMatch(variableNode);
                variableNode.selectNext();
              }),
            size: "compact",
          });
        }),
      [entriesWithPresets, valueSet, specSet, timezone, editor, $replaceMatch]
    ),
  });

  return (
    <Menu.Root state={menu}>
      <Menu.Content
        {...menuContentProps}
        className="!max-w-[28rem] !w-[28rem]"
      />
    </Menu.Root>
  );
}
