import { ConferencingGenerationSettings } from "shared/guide-scheduler/conferencing/types";

import { FormGroup, FormGroupProps } from "../../generic/misc/FormGroup";
import { ZoomHostSelect } from "../wrappers/ZoomHostSelect";
import { ConferencingSettingSelect } from "./ConferencingSettingSelect";

export type EditConferencingGenerationSettingsProps = {
  conferencingGenerationSettings?: ConferencingGenerationSettings | null;
  onChangeConferencingGenerationSettings: (
    conferencingGenerationSettings?: ConferencingGenerationSettings
  ) => void;
  interviewerUserMembershipIds: string[];
  locationFormGroupProps?: FormGroupProps;
  zoomHostGroupProps?: FormGroupProps;
  otherEnabled?: boolean;
};

/**
 * Edit only the settings for how a link should be generated.
 * Cannot set a value for the link itself.
 * Phone enabled by default (can pass param through in the future if necessary).
 * Currently only used for self schedule settings.
 */
export function EditConferencingGenerationSettings({
  conferencingGenerationSettings,
  onChangeConferencingGenerationSettings,
  interviewerUserMembershipIds,
  locationFormGroupProps,
  zoomHostGroupProps,
}: EditConferencingGenerationSettingsProps) {
  return (
    <div className="space-y-3">
      <FormGroup label="Conferencing" {...locationFormGroupProps}>
        <ConferencingSettingSelect
          conferencingType={conferencingGenerationSettings?.service}
          phoneEnabled
          customEnabled={false}
          onSelect={(service) => {
            if (service) {
              onChangeConferencingGenerationSettings({
                service,
              });
            } else {
              onChangeConferencingGenerationSettings(undefined);
            }
          }}
        />
      </FormGroup>
      {conferencingGenerationSettings?.service === "ZOOM" && (
        <FormGroup label="Zoom host" isRequired {...zoomHostGroupProps}>
          <ZoomHostSelect
            hostUserMembershipId={
              conferencingGenerationSettings?.hostUserMembershipId
            }
            onSelect={(val) => {
              onChangeConferencingGenerationSettings({
                ...conferencingGenerationSettings,
                hostUserMembershipId: val?.id,
              });
            }}
            interviewerUserMembershipIds={interviewerUserMembershipIds}
          />
        </FormGroup>
      )}
    </div>
  );
}
