import TextField from "@resource/atlas/textfield/TextField";
import { Checkbox } from "client/components/generic/inputs/Checkbox";
import { RadioItem } from "client/components/generic/inputs/RadioItem";
import { useCallback, useState } from "react";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";

import AlgorithmSettingsError from "./AlgorithmSettingsError";
import { AlgorithmSettingsErrors } from "./utils/types";

export default function InterviewsPerDayLimitSettings({
  interviewsPerDayLimit,
  onInterviewsPerDayLimitChange,
  interviewCount,
  errors,
}: {
  interviewsPerDayLimit: UserConfigurableAlgorithmSettings["interviewsPerDayLimit"];
  onInterviewsPerDayLimitChange: (
    limit: UserConfigurableAlgorithmSettings["interviewsPerDayLimit"]
  ) => void;
  interviewCount: number;
  errors: AlgorithmSettingsErrors | null;
}) {
  const [limitMode, setLimitMode] = useState(() => {
    if (!interviewsPerDayLimit) {
      return null;
    }
    if (interviewsPerDayLimit.min === interviewsPerDayLimit.max) {
      return "exact";
    }
    return "minimum";
  });

  const onSetLimitModeToExact = useCallback(() => {
    onInterviewsPerDayLimitChange({
      // For exact mode, the min and max must match.
      min: 1,
      max: 1,
    });
    setLimitMode("exact");
  }, [onInterviewsPerDayLimitChange]);

  const onSetLimitModeToMinimum = useCallback(() => {
    onInterviewsPerDayLimitChange({
      min: 1,
      // We have to specify a max. Setting to the interview count allows scheduling options which include all interviews on a single day.
      max: interviewCount,
    });
    setLimitMode("minimum");
  }, [onInterviewsPerDayLimitChange, interviewCount]);

  const onToggleLimit = useCallback(() => {
    if (limitMode) {
      onInterviewsPerDayLimitChange(undefined);
      setLimitMode(null);
    } else {
      onSetLimitModeToExact();
    }
  }, [limitMode, onInterviewsPerDayLimitChange, onSetLimitModeToExact]);

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <Checkbox
          label="Limit per day"
          checked={!!limitMode}
          onChange={onToggleLimit}
        />
      </div>

      {interviewsPerDayLimit && limitMode && (
        <div className="space-y-2 ml-4">
          <RadioItem
            label="Exact number per day"
            isSelected={limitMode === "exact"}
            onClick={onSetLimitModeToExact}
          />
          {limitMode === "exact" && (
            <div className="ml-[1.75rem]">
              <div className="flex items-center gap-2">
                <span>Only</span>
                <TextField
                  type="number"
                  size="small"
                  className="w-12"
                  inputClassName="text-center"
                  value={interviewsPerDayLimit.min.toString()}
                  onChange={(v) => {
                    const value = parseInt(v, 10);
                    onInterviewsPerDayLimitChange({
                      min: value,
                      max: value,
                    });
                  }}
                />
                <span>per day</span>
              </div>
              {/*  Min error works for both min and max in exact mode */}
              <AlgorithmSettingsError
                errorMessage={errors?.minInterviewsPerDay}
              />
            </div>
          )}

          <RadioItem
            label="Minimum number per day"
            isSelected={limitMode === "minimum"}
            onClick={onSetLimitModeToMinimum}
          />
          {limitMode === "minimum" && (
            <div className="ml-[1.75rem]">
              <div className="flex items-center gap-1">
                <span>Minimum of</span>
                <TextField
                  type="number"
                  size="small"
                  className="w-12"
                  inputClassName="text-center"
                  value={interviewsPerDayLimit.min.toString()}
                  onChange={(v) => {
                    const value = parseInt(v, 10);
                    onInterviewsPerDayLimitChange({
                      min: value,
                      max: interviewCount,
                    });
                  }}
                />
                <span>per day</span>
              </div>
              {/*  Min error works for both min and max in minimum mode */}
              <AlgorithmSettingsError
                errorMessage={errors?.minInterviewsPerDay}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
