import React, { useCallback, useContext, useMemo, useReducer } from "react";

export const enum ModalName {
  COPY_JOB_SETTINGS = "CopyJobSettings",
  EDIT_POST_TEMPLATE = "EditPostTemplate",
  EDIT_POST_TEMPLATE_CONTENT = "EditPostTemplateContent",
  EDIT_STAGE = "EditStage",
  EMAIL_TEMPLATE_SELECTOR = "EmailTemplateSelector",
  GUIDE_TEMPLATE_SELECTOR = "GuideTemplateSelector",
  GUIDE_TEMPLATE_USAGE = "GuideTemplateUsage",
  HIDE_STAGE_CONFIRMATION = "HideStageConfirmation",
  HIRING_TEAM_MEMBER = "HiringTeamMember",
  INTERVIEWER_DETAILS = "InterviewerDetails",
  NONE = "NONE",
  POST_TEMPLATE_SELECTOR = "PostTemplateSelector",
  REMOVE_STAGE_CONFIRMATION = "RemoveStageConfirmation",
  SCHEDULER_SETTINGS_MANAGER = "SchedulerSettingsManager",
  SHOW_STAGE_CONFIRMATION = "ShowStageConfirmation",
  EDIT_GUIDE_POST = "EditGuidePost",
  DELETE_GUIDE_POST = "DeleteGuidePost",
  CANDIDATE_POST_MESSAGE = "CandidatePostMessage",
}

interface ModalContextValue {
  state: ModalState;
  open: (name: ModalName, id?: string) => void;
  close: () => void;
  isActive: (name: ModalName, id?: string) => boolean;
}

const ModalContext = React.createContext<ModalContextValue | undefined>(
  undefined
);

interface ModalProviderProps {
  children: React.ReactNode;
}

interface ModalState {
  id?: string;
  name: ModalName;
}

function ModalProvider({ children }: ModalProviderProps) {
  const [state, dispatch] = useReducer(
    (_state: ModalState, action: ModalState) => action,
    { name: ModalName.NONE }
  );

  const open = useCallback(
    (name: ModalName, id?: string) => dispatch({ name, id }),
    [dispatch]
  );
  const close = useCallback(
    () => dispatch({ name: ModalName.NONE, id: undefined }),
    []
  );
  const isActive = useCallback(
    (name: ModalName, id?: string) => name === state.name && id === state.id,
    [state]
  );

  const value: ModalContextValue = useMemo(
    () => ({ state, open, close, isActive }),
    [state, open, close, isActive]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export default ModalProvider;

export const useModals = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("ModalContext must be initialized first.");
  }
  return context;
};

export const useOptionalModals = () => {
  const context = useContext(ModalContext);

  return context;
};
