import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasPhone,
  serviceGoogleMeet,
  serviceZoom,
} from "@resource/atlas/icons";

import { ConferencingType } from "./types";

export const CONFERENCING_ICONS: Record<
  ConferencingType,
  AtlasIconData | null
> = {
  [ConferencingType.ZOOM]: serviceZoom,
  [ConferencingType.GOOGLE_MEET]: serviceGoogleMeet,
  [ConferencingType.PHONE]: atlasPhone,
  [ConferencingType.OTHER]: null,
};

export const CONFERENCING_LABELS: Record<ConferencingType, string> = {
  [ConferencingType.ZOOM]: "Zoom",
  [ConferencingType.GOOGLE_MEET]: "Google Meet",
  [ConferencingType.PHONE]: "Phone",
  [ConferencingType.OTHER]: "Custom",
};
