import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendar,
  atlasCalendarConflict,
  atlasClose,
  atlasLockClosed,
  atlasUsers,
} from "@resource/atlas/icons";
import { strings } from "@resource/common";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { getFormattedDateRange } from "client/utils/dates";
import { getAvatarStatusFromInterviewerResponseStatus } from "client/utils/interviewer-response-status";
import clsx from "clsx";
import { useMemo } from "react";

import { getBackgroundColorFromConfig } from "../../utils/colors";
import { CalendarEvent } from "../../utils/types";

export function EventDetailsPopoverDisplay({
  event,
  onClose,
  getEventDetailsFooter,
}: {
  event: CalendarEvent;
  onClose: () => void;
  getEventDetailsFooter?: (event: CalendarEvent) => React.ReactNode;
}) {
  return (
    <div className="flex flex-col w-[280px] max-h-96 overflow-y-auto">
      <div className="flex flex-col space-y-2 p-4 w-full">
        <div className="flex w-full justify-between flex-grow">
          <EventTitleAndTime event={event} />
          <CloseButton onClose={onClose} />
        </div>
        {event.outOfOffice && <OutOfOfficeIndicator />}
        <Attendees event={event} />
        {event.isPrivate && <PrivateIndicator />}
        <OrganizerDisplay event={event} />
        {event.additionalInfoForPopover}
      </div>
      {getEventDetailsFooter && (
        <Footer event={event} getEventDetailsFooter={getEventDetailsFooter} />
      )}
    </div>
  );
}

function isLexicalContent(title: string) {
  try {
    JSON.parse(title);
    return true;
  } catch (err) {
    return false;
  }
}

function EventTitleAndTime({ event }: { event: CalendarEvent }) {
  const titleElement = useMemo(() => {
    const isLexical = isLexicalContent(event.title);

    if (isLexical) {
      return (
        <InterviewTitle
          interview={event}
          className="text-h4 flex-wrap break-words"
          title={event.title}
        />
      );
    }

    return (
      <h4 className="text-h4 flex-wrap break-words" title={event.title}>
        {event.title}
      </h4>
    );
  }, [event]);

  return (
    <div className="flex items-start shrink space-x-2 overflow-hidden">
      <div className="flex items-center justify-center w-4 h-5">
        <CalendarColorIndicator event={event} className="shrink-0" />
      </div>
      <div className="flex flex-col space-y-1 overflow-hidden">
        {titleElement}
        <TimeDisplay event={event} />
      </div>
    </div>
  );
}

function CloseButton({ onClose }: { onClose: () => void }) {
  return (
    <Button
      negativeMargin="right"
      isGhost
      icon={atlasClose}
      onClick={onClose}
      size="xs"
    />
  );
}

function TimeDisplay({ event }: { event: CalendarEvent }) {
  const timeDisplay = useMemo(() => {
    const dateRange = getFormattedDateRange(event);

    return event.allDay ? dateRange.formattedDate : dateRange.formattedDateTime;
  }, [event]);

  return <span className="text-body-sm text-subtle">{timeDisplay}</span>;
}

function CalendarColorIndicator({
  event,
  className,
}: {
  event: CalendarEvent;
  className?: string;
}) {
  return (
    <div
      className={clsx("flex-shrink-0 w-2.5 h-2.5 rounded", className)}
      style={{
        backgroundColor: getBackgroundColorFromConfig({
          color: event.colorConfig.color,
          variant: "active",
        }),
      }}
    />
  );
}

function OutOfOfficeIndicator() {
  return (
    <div className="flex flex-row space-x-2 text-body-sm items-center">
      <Icon content={atlasCalendarConflict} className="text-subtle w-4 h-4" />
      <div className="text-subtle">Out of office</div>
    </div>
  );
}

function Attendees({ event }: { event: CalendarEvent }) {
  const attendees = useMemo(() => {
    if (!event.attendees || !event.attendees.length) {
      return null;
    }
    return event.attendees.map((a) => ({
      ...a,
      name: a.name ?? a.email ?? "Someone",
    }));
  }, [event.attendees]);

  if (!attendees) {
    return null;
  }

  return (
    <div
      className="text-body-sm overflow-hidden space-y-2"
      // Note: This prevents RSVP status getting cut off for bottom attendee
      style={{
        paddingBottom: 8,
        marginBottom: -8,
      }}
    >
      <div className="flex flex-row space-x-2 h-4 items-center">
        <Icon content={atlasUsers} className="text-subtle w-4 h-4" />
        <p className="text-subtle">
          {strings.pluralize("guest", attendees.length)}
        </p>
      </div>
      <div className="space-y-3 flex-1 min-w-0 ml-6">
        {attendees.map((attendee) => (
          <div
            key={attendee.name ?? attendee.email}
            className="flex flex-row space-x-2 items-center"
          >
            <Avatar
              image={attendee.imageUrl}
              name={attendee.name}
              size="xs"
              status={
                attendee.responseStatus
                  ? getAvatarStatusFromInterviewerResponseStatus(
                      attendee.responseStatus
                    )
                  : undefined
              }
            />
            <div className="flex-1 min-w-0">
              <span className="flex items-center">
                <span className="truncate" title={attendee.name}>
                  {attendee.name}
                </span>
                {attendee.optional && (
                  <span className="text-subtle flex-shrink-0">
                    &nbsp;(optional)
                  </span>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function PrivateIndicator() {
  return (
    <div className="flex flex-row space-x-2 text-body-sm items-center">
      <Icon content={atlasLockClosed} className="text-subtle w-4 h-4" />
      <div className="text-subtle">Private</div>
    </div>
  );
}

function OrganizerDisplay({ event }: { event: CalendarEvent }) {
  if (!event.organizer) {
    return null;
  }

  const organizerName =
    event.organizer.name || event.organizer.email || "Unknown";
  const creatorName = event.creator?.name || event.creator?.email;
  const showCreator = creatorName && creatorName !== organizerName;

  return (
    <div className="flex flex-row space-x-2 text-body-sm text-subtle items-center">
      <Icon
        content={atlasCalendar}
        className="w-4 h-4 self-start pt-[.0625rem]"
      />
      <div className="flex-1 min-w-0">
        <span>
          {" "}
          {event.organizer.name || event.organizer.email || "Unknown"}
        </span>
        {showCreator && (
          <div className=" text-body-xs">Created by: {creatorName}</div>
        )}
      </div>
    </div>
  );
}

function Footer({
  event,
  getEventDetailsFooter,
}: {
  event: CalendarEvent;
  getEventDetailsFooter: (event: CalendarEvent) => React.ReactNode;
}) {
  return (
    <div className="bg-light-gray-200 border-t border-gray-border">
      {getEventDetailsFooter(event)}
    </div>
  );
}
