import { getInterviewersFromInterviewSlots } from "client/components/interviewer-slots/utils/misc";
import { InterviewerSlot } from "client/components/interviewer-slots/utils/types";
import { mapInterviewersToInterviewerMultiSelect } from "client/components/scheduled-interviews/ReportingReasons/utils/mapping";
import { UpdateOrCancelReportingReasonsViewProps } from "client/components/scheduled-interviews/ReportingReasons/wrappers/UpdateOrCancelReportingReasonsView";
import _ from "lodash";
import { useMemo } from "react";
import { ScheduledInterviewFieldChange } from "shared/guide-scheduler/field-changes/types";
import { hasRescheduleRequirementBeenFulfilled } from "shared/guide-scheduler/field-changes/utils";
import { InterviewerResponse } from "shared/guide-scheduler/types";

import {
  useInterviewGroupChanges,
  useOriginalInterviews,
} from "../../../hooks/interviews";

function useUniqueOriginalInterviewers() {
  const originalInterviews = useOriginalInterviews();

  return useMemo(() => {
    return (
      _(originalInterviews)
        .flatMap((i) =>
          mapInterviewersToInterviewerMultiSelect(
            getInterviewersFromInterviewSlots(i.interviewerSlots)
          )
        )
        .groupBy("userMembershipId")
        // Prioritize declined interviewers to the top, followed by those with a status
        .map((group) => {
          const prioritizedInterviewer = _.maxBy(group, (interviewer) => {
            if (!interviewer.responseStatus) return 0;
            if (interviewer.responseStatus === InterviewerResponse.DECLINED)
              return 2;
            return 1;
          });
          return prioritizedInterviewer;
        })
        .compact()
        .value()
    );
  }, [originalInterviews]);
}

export function useSchedulerReportingReasonsState() {
  const interviewGroupChanges = useInterviewGroupChanges();
  const allUniqueOriginalInterviewers = useUniqueOriginalInterviewers();

  const timeOrInterviewersChangedInterviews = useMemo(() => {
    const allUpdatedInterviews = interviewGroupChanges.interviewsToUpdate;

    return allUpdatedInterviews.filter((i) => {
      return hasRescheduleRequirementBeenFulfilled({
        fieldChanges: i.fieldChanges.map((fc) => fc.fieldName),
      });
    });
  }, [interviewGroupChanges.interviewsToUpdate]);

  const updates =
    useMemo((): UpdateOrCancelReportingReasonsViewProps["updates"] => {
      if (timeOrInterviewersChangedInterviews.length === 0) {
        return null;
      }

      const originalInterviewers = timeOrInterviewersChangedInterviews.flatMap(
        (i) =>
          mapInterviewersToInterviewerMultiSelect(
            getInterviewersFromInterviewSlots(i.interviewerSlots)
          )
      );
      const interviewersChanged = timeOrInterviewersChangedInterviews.some(
        (i) => {
          const interviewerChange = i.fieldChanges.find(
            (fc) => fc.fieldName === ScheduledInterviewFieldChange.INTERVIEWERS
          );
          if (!interviewerChange) return false;

          const oldInterviewers = getInterviewersFromInterviewSlots(
            interviewerChange.oldValue as InterviewerSlot[]
          );
          const newInterviewers = getInterviewersFromInterviewSlots(
            interviewerChange.newValue as InterviewerSlot[]
          );

          const oldInterviewerUserMembershipIds = new Set(
            oldInterviewers.map((interviewer) => interviewer.userMembership.id)
          );
          const newInterviewerUserMembershipIds = new Set(
            newInterviewers.map((interviewer) => interviewer.userMembership.id)
          );

          const removedInterviewerUserMembershipId = Array.from(
            oldInterviewerUserMembershipIds
          ).find(
            (interviewerId) =>
              !newInterviewerUserMembershipIds.has(interviewerId)
          );

          return !!removedInterviewerUserMembershipId;
        }
      );
      const timeChanged = timeOrInterviewersChangedInterviews.some((i) => {
        const fieldChanges = i.fieldChanges.map((fc) => fc.fieldName);

        return fieldChanges.includes(ScheduledInterviewFieldChange.TIME);
      });

      return {
        originalInterviewers,
        interviewersChanged,
        timeChanged,
      };
    }, [timeOrInterviewersChangedInterviews]);

  const cancelledInterviews = useMemo(() => {
    return interviewGroupChanges.interviewsToDelete;
  }, [interviewGroupChanges.interviewsToDelete]);

  const cancellations =
    useMemo((): UpdateOrCancelReportingReasonsViewProps["cancellations"] => {
      if (cancelledInterviews.length === 0) {
        return null;
      }

      const interviewers = cancelledInterviews.flatMap((i) =>
        mapInterviewersToInterviewerMultiSelect(
          getInterviewersFromInterviewSlots(i.interviewerSlots)
        )
      );

      return {
        interviewers,
      };
    }, [cancelledInterviews]);

  const updatesForReporting = useMemo(() => {
    if (!updates) return null;

    if (!updates.interviewersChanged && !updates.timeChanged) {
      return null;
    }

    return {
      originalInterviewers: updates.originalInterviewers,
      interviewersChanged: updates.interviewersChanged,
      timeChanged: updates.timeChanged,
    };
  }, [updates]);

  return useMemo(() => {
    return {
      allOriginalInterviewers: allUniqueOriginalInterviewers,
      updates: updatesForReporting,
      cancellations,
      showReportingPrompt: !!updatesForReporting || !!cancellations,
    };
  }, [allUniqueOriginalInterviewers, updatesForReporting, cancellations]);
}

export type SchedulerReportingReasonsState = ReturnType<
  typeof useSchedulerReportingReasonsState
>;
