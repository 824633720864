import { Link } from "@resource/atlas/link/Link";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useLogEvent } from "analytics";
import { BlockError } from "client/components/generic/errors/BlockError";
import { Timestamp } from "client/components/generic/misc/Timestamp";
import { Skeleton } from "components/Skeleton";
import { GuideAvailabilitySubmissionsSubmissionFragment } from "generated/graphql-codegen/graphql";

import { CandidateAvailability } from "./CandidateAvailability";

type CurrentCandidateAvailabilityProps = {
  loading?: boolean;
  error?: boolean;
  currentSubmission: GuideAvailabilitySubmissionsSubmissionFragment;
  timezone?: string | null;
  onEdit?(): void;
  onClear?(): void;
};

export function CurrentCandidateAvailability({
  loading = false,
  error,
  currentSubmission,
  timezone,
  onEdit,
  onClear,
}: CurrentCandidateAvailabilityProps) {
  const logEvent = useLogEvent({
    component: "CurrentCandidateAvailability",
  });

  if (loading) {
    return (
      <div className="w-full space-y-2">
        <Skeleton className="w-2/3 h-[14px]" type="text" />
        <Skeleton className="w-2/3 h-[14px]" type="text" />
      </div>
    );
  }

  if (error) {
    return <BlockError label="Can't load candidate availability" />;
  }

  return (
    <div className="space-y-2">
      <CandidateAvailability
        events={currentSubmission.upcomingEvents}
        isCurrentSubmission
        timezone={timezone}
      />
      <div className="flex text-body-sm gap-1.5 text-subtle">
        {currentSubmission.guidePost ? (
          <Tooltip content="View original response">
            <Link
              variant="subtle"
              href={currentSubmission.guidePost.href}
              target="_blank"
            >
              Updated by {currentSubmission.createdBy.name}
            </Link>
          </Tooltip>
        ) : (
          <p className="">Updated by {currentSubmission.createdBy.name}</p>
        )}
        <Timestamp timestamp={currentSubmission.createdAt} format="short" />
        {onEdit && (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <Link
            as="button"
            variant="subtle"
            onClick={() => {
              logEvent("Edit Candidate Availability Clicked");
              onEdit();
            }}
          >
            Edit
          </Link>
        )}
        {onClear && (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
          <Link
            as="button"
            variant="subtle"
            onClick={() => {
              logEvent("Clear Candidate Availability Clicked");
              onClear();
            }}
          >
            Clear
          </Link>
        )}
      </div>
    </div>
  );
}
