import { AtlasPopoverState } from "@resource/atlas/popover";
import { DateTime } from "luxon";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from "react";

import { CalendarEvent, ScrollToTime } from "./types";

export type CalendarContextValue = {
  isEventInView: (
    event: Pick<CalendarEvent, "startTime" | "endTime" | "allDay">
  ) => boolean;
  events: CalendarEvent[];
  allDayEvents: CalendarEvent[];
  wrapperRef: HTMLDivElement | null;
  setWrapperRef: React.Dispatch<SetStateAction<HTMLDivElement | null>>;
  eventsViewRef: HTMLOListElement | null;
  setEventsViewRef: React.Dispatch<SetStateAction<HTMLOListElement | null>>;
  headerRef: HTMLDivElement | null;
  setHeaderRef: React.Dispatch<SetStateAction<HTMLDivElement | null>>;
  calculateScrollValueForTime: (time: DateTime) => number;
  calculateTimeForOffset: (offset: { x: number; y: number }) => DateTime | null;
  calculateMinutesFromDifference: (y: number) => number | null;
  calculateOffsetFromEvent: (e: { clientX: number; clientY: number }) => {
    x: number;
    y: number;
  };
  scrollToTime: ScrollToTime;
  focusedEventId: string | null;
  setFocusedEventId: Dispatch<SetStateAction<string | null>>;
  hoveringEventId: string | null;
  setHoveringEventId: Dispatch<SetStateAction<string | null>>;
  hoveringTriggerElement: HTMLDivElement | null;
  setHoveringTriggerElement: React.Dispatch<
    SetStateAction<HTMLDivElement | null>
  >;
  eventDetailsPopoverState: AtlasPopoverState;
  eventDetailsPopoverTriggerElement: HTMLDivElement | null;
  setEventDetailsPopoverTriggerElement: React.Dispatch<
    SetStateAction<HTMLDivElement | null>
  >;
  dayWidth: number;
};

export const CalendarContext = createContext<CalendarContextValue | undefined>(
  undefined
);

export const useCalendarContext = () => {
  const context = useContext(CalendarContext);

  if (context === undefined) {
    throw new Error(
      "useCalendarContext must be used within a CalendarContextProvider"
    );
  }

  return context;
};
