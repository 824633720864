import { atlasCalendarCross } from "@resource/atlas/icons";
import { ColorConfig } from "client/components/calendar-v2/utils/colors";
import { CalendarEventInput } from "client/components/calendar-v2/utils/types";
import { combineEvents } from "client/components/calendar-v2/utils/utils";
import { UserMembershipForInterviewerSelfScheduleFragment } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { SelfScheduleCalendarLayers } from "../utils/layers";

const BLOCKED_DATE_CALENDAR_ID = "blocked-dates";

const BLOCKED_DATE_CALENDAR_EVENT_COLOR_CONFIG: ColorConfig = {
  color: "blocked",
  eventVariant: "background",
};

/**
 * Hook to transform organization blocked dates into calendar events
 * Combines adjacent blocked dates into single events for cleaner display
 *
 * @param userMembership - The user membership containing organization blocked dates
 * @param interviewerTimezone - The timezone to display the events in
 * @returns Array of calendar events representing blocked dates
 */
export function useBlockedDatesEvents({
  userMembership,
  interviewerTimezone,
}: {
  userMembership:
    | UserMembershipForInterviewerSelfScheduleFragment
    | undefined
    | null;
  interviewerTimezone: string;
}): CalendarEventInput[] {
  const events = useMemo(() => {
    if (!userMembership?.organization?.blockedDates) {
      return [];
    }

    const blockedEvents: {
      id: string;
      title: string;
      startTime: DateTime;
      endTime: DateTime;
    }[] = [];

    userMembership.organization.blockedDates.forEach((blockedDate) => {
      const startDay = DateTime.fromISO(blockedDate.startDay, {
        zone: interviewerTimezone,
      }).setZone(interviewerTimezone);
      const endDay = DateTime.fromISO(blockedDate.endDay, {
        zone: interviewerTimezone,
      }).setZone(interviewerTimezone);

      let currentDate = startDay;
      while (currentDate <= endDay) {
        blockedEvents.push({
          id: `blocked-${currentDate.toISO()}`,
          title: "Blocked Date",
          startTime: currentDate.startOf("day"),
          endTime: currentDate.plus({ days: 1 }).startOf("day"),
        });
        currentDate = currentDate.plus({ days: 1 });
      }
    });

    return blockedEvents;
  }, [userMembership?.organization?.blockedDates, interviewerTimezone]);

  return useMemo(() => {
    const combinedEvents = combineEvents(events);

    return combinedEvents.map(
      (event): CalendarEventInput => ({
        id: event.id,
        calendarId: BLOCKED_DATE_CALENDAR_ID,
        icon: atlasCalendarCross,
        start: {
          dateTime: event.startTime.toISO(),
        },
        end: {
          dateTime: event.endTime.toISO(),
        },
        title: "Blocked Date",
        disableDetailsPopover: true,
        colorConfig: BLOCKED_DATE_CALENDAR_EVENT_COLOR_CONFIG,
        layer: SelfScheduleCalendarLayers.BACKGROUND_BLOCKERS,
      })
    );
  }, [events]);
}
