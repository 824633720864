import {
  useInterviews,
  useSelectedInterview,
} from "client/scheduler/core/hooks/interviews";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

const sequentialPlacementEnabledAtom = atomWithStorage<boolean>(
  "sequentialInterviewPlacementEnabled",
  true
);

export function useSequentialPlacementEnabled() {
  const [sequentialPlacementEnabled, setSequentialPlacementEnabled] = useAtom(
    sequentialPlacementEnabledAtom
  );

  return useMemo(
    () => ({
      sequentialPlacementEnabled,
      setSequentialPlacementEnabled,
    }),
    [sequentialPlacementEnabled, setSequentialPlacementEnabled]
  );
}

export function useSequentiallyPlacedInterviews(movingTime: DateTime | null) {
  const interviews = useInterviews();
  const { sequentialPlacementEnabled } = useSequentialPlacementEnabled();
  const selectedInterview = useSelectedInterview();

  return useMemo(() => {
    if (!movingTime || !sequentialPlacementEnabled) return [];

    const unstagedInterviews = interviews.filter(
      (interview) => !interview.startTime || !interview.endTime
    );

    // Move the selected interview to the front of the array
    const sortedInterviews = [
      selectedInterview,
      ...unstagedInterviews.filter(
        (interview) => interview.id !== selectedInterview?.id
      ),
    ].filter(filterOutNullsAndUndefined);

    let currentStartTime = movingTime;
    return sortedInterviews.map((interview) => {
      const startTime = currentStartTime;
      const endTime = startTime.plus({ minutes: interview.duration || 60 });
      currentStartTime = endTime;

      return { ...interview, startTime, endTime };
    });
  }, [interviews, movingTime, sequentialPlacementEnabled, selectedInterview]);
}
