import { isValidPhoneNumber } from "@resource/common";
import { useMemo } from "react";
import { FieldErrors, FieldValues, FormState } from "react-hook-form";
import { z } from "zod";

export function generateErrorMessage(errors: FieldErrors) {
  const errorMessages: string[] = [];

  const checkErrors = (internalErrors: FieldErrors, fieldName = "") => {
    Object.keys(internalErrors).forEach((key) => {
      const error = internalErrors[key];
      if ("type" in error || "message" in error) {
        switch (error.type) {
          case "required":
            errorMessages.push(`${fieldName} is required`);
            break;
          case "minLength":
            errorMessages.push(
              `${fieldName} must have at least ${error.minLength} characters`
            );
            break;
          case "maxLength":
            errorMessages.push(
              `${fieldName} must have less than ${error.maxLength} characters`
            );
            break;
          case "pattern":
            errorMessages.push(`Please enter a valid ${fieldName}`);
            break;
          case "custom":
            errorMessages.push(error.message);
            break;
          default:
            errorMessages.push("Unknown error");
        }
      } else {
        checkErrors(error, fieldName ? `${fieldName}.${key}` : key);
      }
    });
  };

  checkErrors(errors);

  return errorMessages.join(", ");
}

function extractMessages(errors: FieldErrors): string[] {
  return Object.values(errors).flatMap((error) => {
    if ("message" in error) {
      return [error.message];
    }

    return extractMessages(error as FieldErrors);
  });
}

export function useDisabledTooltipContentForForm<T extends FieldValues>(
  formState: FormState<T>,
  opts?: {
    skipDirtyCheck?: boolean;
  }
) {
  return useMemo(() => {
    if (!formState.isDirty && !opts?.skipDirtyCheck) {
      return "No changes have been made.";
    }

    if (Object.keys(formState.errors).length > 0) {
      return extractMessages(formState.errors).join(", ");
    }

    return undefined;
  }, [formState.errors, formState.isDirty, opts?.skipDirtyCheck]);
}

export const PhoneFormSchema = z
  .string({
    required_error: "Phone number is required",
  })
  .refine(
    (value) => {
      if (value) {
        return isValidPhoneNumber(value);
      }

      return true;
    },
    {
      message: "Invalid phone number",
    }
  );
