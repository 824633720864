import {
  useFetchedInterviewerCalendarEvents,
  useFetchedInterviewerCalendarEventsFlat,
  UseFetchedInterviewerCalendarEventsProps,
} from "client/calendar-events/hooks/useFetchedInterviewerCalendarEvents";
import { mapFetchedEventToInterviewerCalendarEvent } from "client/calendar-events/utils/mapping";
import { InterviewerCalendarEvent } from "client/calendar-events/utils/types";
import { useCalendarSettings } from "client/components/calendar-v2/hooks/settings";
import { useColumnType } from "client/components/calendar-v2/hooks/useColumns";
import { useMemo } from "react";

import {
  useAllInterviewerUserMemberships,
  useInterviews,
} from "../../hooks/interviews";
import { useViewFilter } from "../../hooks/misc";
import { EventLayers } from "../utils/group-ids";
import { useSelectedInterviewInterviewerUserMembershipIds } from "./useSelectedInterviewInterviewerUserMembershipIds";

function useFetchEventsInterval() {
  const { selectedWeek } = useCalendarSettings();

  return useMemo(() => {
    return {
      startTime: selectedWeek.start,
      /**
        We fetch 4 weeks at once because Google doesn't rate limit based on duration; may as well fetch more. 
        When skipping weeks in the UI, we still fetch 4 more weeks, but can render the next week instantly with the cached data.
       */
      endTime: selectedWeek.start.plus({ weeks: 4 }),
    };
  }, [selectedWeek]);
}

function useFetchCalendarEventsParams(): UseFetchedInterviewerCalendarEventsProps {
  const interviews = useInterviews();
  const userMemberships = useAllInterviewerUserMemberships();
  const userMembershipIds = useMemo(
    () => userMemberships.map((userMembership) => userMembership.id),
    [userMemberships]
  );
  const { startTime, endTime } = useFetchEventsInterval();
  const { schedulingRequestId } = useViewFilter();

  return useMemo((): UseFetchedInterviewerCalendarEventsProps => {
    return {
      userMembershipIds,
      startTime,
      endTime,
      schedulingRequestId,
      filterInterviews: interviews,
    };
  }, [endTime, interviews, schedulingRequestId, startTime, userMembershipIds]);
}

/**
 * Fetch unmapped calendar events from server, using scheduler params
 * Returned as object with key of userMembershipId
 */
export function useFetchedInterviewerCalendarEventsForScheduler(
  userMembershipIds: string[]
) {
  const params = useFetchCalendarEventsParams();

  return useFetchedInterviewerCalendarEvents({
    ...params,
    userMembershipIds,
  });
}

/** Fetch all calendar events from server, mapped to calendar event input type and returned as an array */
export function useAllInterviewerCalendarEventsForScheduler(): InterviewerCalendarEvent[] {
  const params = useFetchCalendarEventsParams();
  const { calendarEvents } = useFetchedInterviewerCalendarEventsFlat(params);
  const mappedCalendarEvents = useMemo(() => {
    return calendarEvents.map((e) =>
      mapFetchedEventToInterviewerCalendarEvent(e, {
        layer: EventLayers.INTERVIEWER,
      })
    );
  }, [calendarEvents]);

  return mappedCalendarEvents;
}

/**
 * Returns a flat array of mapped calendar events for all currently viewing interviewers.
 * Events are filtered based on calendar column type.
 */
export function useFilteredInterviewerCalendarEventsForScheduler(): InterviewerCalendarEvent[] {
  const columnType = useColumnType();
  const currentViewingUserMembershipIds =
    useSelectedInterviewInterviewerUserMembershipIds({
      filterToCurrentViewing: true,
    });
  const allInterviewerCalendarEvents =
    useAllInterviewerCalendarEventsForScheduler();

  return useMemo(() => {
    if (columnType === "calendar") {
      // When the columns are calendars, we want to show all events for all interviewers
      return allInterviewerCalendarEvents;
    }

    return allInterviewerCalendarEvents.filter((event) =>
      currentViewingUserMembershipIds.includes(event.calendarId)
    );
  }, [
    allInterviewerCalendarEvents,
    columnType,
    currentViewingUserMembershipIds,
  ]);
}
