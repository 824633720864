import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";

import { useDaysToRender } from "./useDaysToRender";
import { useCalendarSizes } from "./utils";

export function VerticalLines() {
  const days = useDaysToRender();
  const calendarSizes = useCalendarSizes();

  const gridClass = `grid-cols-${days.length} sm:grid-cols-${days.length}`;

  return (
    <div
      className={clsx(
        "col-start-1 col-end-2 row-start-1 hidden grid-rows-1 divide-x divide-[#EEEDEF] sm:grid",
        gridClass
      )}
    >
      {days.map((day, i) => {
        return (
          <div
            data-cy={CypressData.calendar.verticalLine}
            className={clsx("row-span-full", `col-start-${i + 1}`)}
            key={`${day.toISO()}-${i}`}
          />
        );
      })}
      <div
        data-cy={CypressData.calendar.verticalLine}
        className={clsx("row-span-full", `col-start-${days.length + 1}`)}
        style={{
          width: `${calendarSizes.rightOffsetWidth.rem}rem`,
        }}
      />
    </div>
  );
}
