import { useMemo } from "react";

import { useInterviews } from "../../hooks/interviews";
import { getCalendarIdsForInterview } from "../utils/calendar-ids";

export function useDayViewCalendarIds() {
  const interviews = useInterviews();

  const dayViewCalendarIds = useMemo(
    () =>
      interviews.flatMap((interview) => {
        return getCalendarIdsForInterview(interview);
      }),
    [interviews]
  );

  return dayViewCalendarIds;
}
