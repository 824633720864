import {
  InterviewerResponse,
  InterviewerSlotType,
  TagFilterType,
} from "generated/graphql-codegen/graphql";
import { z } from "zod";

import { InterviewConflictData } from "../conflicts/utils/types";
import { InterviewLoadData } from "../load/utils/types";
import { TrainingProgressData } from "../training/utils/types";

const UserValidationMessages = {
  noInterviewerSelected: "An interviewer must be assigned to each slot.",
};

export const UserSchema = z.object({
  id: z.string(),
  timezone: z.string().nullable(),
});
export type User = z.infer<typeof UserSchema>;

export const UserMembershipForFormSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  imageUrl: z.string().nullable(),
  email: z.string(),
  user: UserSchema,
});

export type UserMembershipForForm = z.infer<typeof UserMembershipForFormSchema>;

export type UserMembershipWithSchedulingData = UserMembershipForForm & {
  loadData?: InterviewLoadData;
  conflictData?: InterviewConflictData;
  trainingData?: TrainingProgressData;
};

export type UserMembershipSelectBaseProps = {
  Trigger: JSX.Element;
  searchPlaceholderText: string;
};

type SelectUserProps = {
  onSelect: (user: UserMembershipWithSchedulingData) => void;
  excludeUserMembershipIds?: string[];
};

/** When selecting pools, we are not selecting individual users from a pool. */
type SelectPoolProps = {
  includePools: true;
  onSelectPool: (pool: InterviewerPool) => void;
  poolId?: never;
  tagFilters?: never;
  isQualified?: never;
};

/** When selecting users from a pool, we are not selecting new pools. */
type SelectUserMembershipFromPoolProps = {
  poolId: string;
  tagFilters?: InterviewerSlotTagFilter[] | null;
  isQualified: boolean;
  includePools?: false;
  onSelectPool?: never;
};

/** When select users without a pool, we are not selecting new pools. */
type SelectUserMembershipWithoutPoolProps = {
  poolId?: never;
  tagFilters?: InterviewerSlotTagFilter[] | null;
  isQualified?: never;
  includePools?: false;
  onSelectPool?: never;
};

export type UserMembershipSelectWrapperProps = UserMembershipSelectBaseProps &
  SelectUserProps &
  (
    | SelectPoolProps
    | SelectUserMembershipFromPoolProps
    | SelectUserMembershipWithoutPoolProps
  );

export type UserMembershipSelectWrapper = (
  props: UserMembershipSelectWrapperProps
) => JSX.Element;

export const InterviewerPoolSchema = z.object({
  id: z.string(),
  name: z.string(),
  qualifiedUserCount: z.number(),
  traineeUserCount: z.number(),
});

export type InterviewerPool = z.infer<typeof InterviewerPoolSchema>;

export const InterviewerSchema = z.object(
  {
    id: z.string(),
    responseStatus: z.nativeEnum(InterviewerResponse).nullable().optional(),
    responseNote: z.string().nullable().optional(),
    userMembership: UserMembershipForFormSchema,
  },
  {
    required_error: UserValidationMessages.noInterviewerSelected,
    // Note: `null` for interviewer is technically an `invalid_type_error`
    invalid_type_error: UserValidationMessages.noInterviewerSelected,
  }
);

export type Interviewer = z.infer<typeof InterviewerSchema>;

export type InterviewerWithSchedulingData = Omit<
  Interviewer,
  "userMembership"
> & {
  userMembership: UserMembershipWithSchedulingData;
};

// A flat version of interviewer schema with shadow info for display
export const InterviewerFlatSchema = InterviewerSchema.extend({
  isShadow: z.boolean(),
});

export type InterviewerFlat = z.infer<typeof InterviewerFlatSchema>;

export const TagGroupSchema = z.object({
  id: z.string(),
  label: z.string(),
  color: z.string(),
});

export type TagGroup = z.infer<typeof TagGroupSchema>;

export const TagSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string(),
  userCount: z.number(),
  tagGroup: TagGroupSchema.nullable(),
});

export type Tag = z.infer<typeof TagSchema>;

export const InterviewerSlotTagFilterSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(TagFilterType),
  tags: z.array(TagSchema),
});

export type InterviewerSlotTagFilter = z.infer<
  typeof InterviewerSlotTagFilterSchema
>;

export const InterviewerSlotSchema = z.object({
  id: z.string(),
  trainingEnabled: z.boolean(),
  externalAlgorithmEnabled: z.boolean(),
  type: z.nativeEnum(InterviewerSlotType),
  shadowingInterviewer: InterviewerSchema.nullable(),
  userMembershipsSetting: z.array(UserMembershipForFormSchema),
  shadowingUserMembershipsSetting: z.array(UserMembershipForFormSchema),
  interviewerPoolsSetting: z.array(InterviewerPoolSchema),
  interviewerRequirementId: z.string().optional().nullable(),
  interviewer: InterviewerSchema.nullable(),
  tagFilters: z.array(InterviewerSlotTagFilterSchema).optional(),
});

export type InterviewerSlot = z.infer<typeof InterviewerSlotSchema>;
export type InterviewerSlotWithSchedulingData = Omit<
  InterviewerSlot,
  "interviewer" | "shadowingInterviewer"
> & {
  interviewer: InterviewerWithSchedulingData | null;
  shadowingInterviewer: InterviewerWithSchedulingData | null;
};

export const StagedInterviewerSlotSchema = InterviewerSlotSchema.extend({
  interviewer: InterviewerSchema,
});

export type StagedInterviewerSlot = z.infer<typeof StagedInterviewerSlotSchema>;

export function isStagedInterviewerSlot(
  slot: InterviewerSlot | StagedInterviewerSlot
): slot is StagedInterviewerSlot {
  return !!slot.interviewer;
}

export type FormDataForSlotCalculations = {
  guideId: string;
  /** Current interview, used to get startTime for load interval calculations */
  selectedInterview: InterviewForSlotCalculations | null;
  /** Current state of editing interviews */
  interviews: InterviewForSlotCalculations[];
  /** Original interviews prior to any changes being made */
  originalInterviews: InterviewForSlotCalculations[];
  /** If current viewing interview doesn't have a startTime, can choose a fallback date for calculating week load */
  fallbackLoadIntervalDateForWeekLoad?: string;
};

export type InterviewerToFetchForSlotCalculations = {
  userMembershipId: string;
  poolId?: string;
  isQualified?: boolean;
};

export type InterviewForSlotCalculations = {
  id: string;
  interviewerSlots: InterviewerSlot[];
  startTime: string | null;
  endTime: string | null;
  title: string;
};

export type SlotDataForUserMembershipSchedulingData = {
  userMembershipId: string;
  poolId?: string;
};
