import { AddInterviewChangeMessageInput } from "client/components/scheduling-review/AddInterviewChangeMessageInput";
import { NotifyInterviewersCheckbox } from "client/components/scheduling-review/NotifyInterviewersCheckbox";

export type CancellationInterviewNotificationSettingsDisplayProps = {
  notifyInterviewers: boolean;
  message: string;
  onNotifyInterviewersChange: (value: boolean) => void;
  onMessageChange: (value: string) => void;
};

/**
 * Display component for configuring the notification settings when cancelling an interview
 */
export function CancellationInterviewNotificationSettingsDisplay({
  notifyInterviewers,
  message,
  onNotifyInterviewersChange,
  onMessageChange,
}: CancellationInterviewNotificationSettingsDisplayProps) {
  return (
    <>
      <NotifyInterviewersCheckbox
        notifyInterviewers={notifyInterviewers}
        onChange={onNotifyInterviewersChange}
      />
      <AddInterviewChangeMessageInput
        value={message}
        onChange={onMessageChange}
      />
    </>
  );
}
