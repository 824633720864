import { Button as AriaButton } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPersonPlus } from "@resource/atlas/icons";
import clsx from "clsx";
import { useMemo } from "react";

import { EditInterviewerSlotDisplayState } from "../hooks/useEditInterviewerSlotDisplayState";
import { EditSelectedInterviewerRightActions } from "./EditSelectedInterviewerRightActions";
import { SelectedInterviewerDisplay } from "./SelectedInterviewerDisplay";

export type EditPoolConfigSelectedInterviewerDisplayProps = {
  state: EditInterviewerSlotDisplayState;
  isShadower?: boolean;
  paddingTopClassName?: string;
};

/**
 * Display a selected interviewer for a pool config in edit mode.
 * If no interviewer is passed, it will display an empty state and allow selection.
 * Used for both interviewers and shadowers.
 * Handles selection of potential interviewers for pool.
 */
export function EditPoolConfigSelectedInterviewerDisplay({
  state,
  isShadower,
  paddingTopClassName = "pt-3",
}: EditPoolConfigSelectedInterviewerDisplayProps) {
  const {
    interviewerSlot,
    calendarColors,
    getInterviewerState,
    size,
    hideInterviewerDayLoad,
  } = state;

  const { interviewerPoolsSetting } = interviewerSlot;

  const poolsForFilter = useMemo(
    () => interviewerPoolsSetting.map((p) => p.id),
    [interviewerPoolsSetting]
  );
  const poolId = poolsForFilter[0];

  const { interviewer: currentInterviewer } = getInterviewerState({
    isShadower,
  });

  return (
    <div className={clsx("px-3 pb-3", paddingTopClassName)}>
      <div className="flex items-center gap-2">
        {currentInterviewer?.userMembership ? (
          <>
            <SelectedInterviewerDisplay
              interviewer={currentInterviewer.userMembership}
              responseStatus={currentInterviewer.responseStatus}
              isShadower={isShadower}
              calendarColor={
                calendarColors
                  ? calendarColors[currentInterviewer.userMembership.id]
                  : undefined
              }
              size={size}
              showDayCount={!hideInterviewerDayLoad}
              RightActions={
                <EditSelectedInterviewerRightActions
                  state={state}
                  allowSwapping
                  poolId={poolId}
                  isShadower={isShadower}
                />
              }
            />
          </>
        ) : (
          <UnselectedInterviewer
            state={state}
            poolId={poolId}
            isShadower={isShadower}
            RightActions={
              <EditSelectedInterviewerRightActions
                state={state}
                poolId={poolId}
                isShadower={isShadower}
                hideMenu={isShadower}
              />
            }
          />
        )}
      </div>
    </div>
  );
}

function UnselectedInterviewer({
  state: {
    getInterviewerState,
    UserMembershipSelect,
    allMembershipIdsFromInterviewSlots,
    interviewerSlot: { tagFilters },
  },
  isShadower,
  poolId,
  RightActions,
}: {
  state: EditInterviewerSlotDisplayState;
  isShadower?: boolean;
  poolId: string;
  RightActions?: React.ReactNode;
}) {
  const { onSelectUser } = getInterviewerState({ isShadower });

  return (
    <div className="flex w-full">
      <UserMembershipSelect
        searchPlaceholderText="Search by name or email"
        onSelect={onSelectUser}
        poolId={poolId}
        tagFilters={tagFilters}
        isQualified={!isShadower}
        excludeUserMembershipIds={allMembershipIdsFromInterviewSlots}
        Trigger={
          <AriaButton className="flex space-x-2 text-purple-500 items-center w-full">
            <div className="border-[1.5px] rounded-full border-dashed border-purple-500 p-[2px]">
              <Icon content={atlasPersonPlus} className="w-4 h-4" />
            </div>
            <div className="flex items-center flex-grow">
              <span className="text-body-md">
                {isShadower ? "Add trainee" : "Select interviewer"}
              </span>
            </div>
          </AriaButton>
        }
      />
      {RightActions}
    </div>
  );
}
