/* eslint-disable import/prefer-default-export */
import { createModule } from "../../../modules";
import { FloatingToolbarPlugin } from "./FloatingToolbarPlugin";

export const floatingToolbarModule = createModule("floating-toolbar", {
  declareModule({ insertOptions }) {
    return {
      plugins: [<FloatingToolbarPlugin insertOptions={insertOptions} />],
    };
  },
});
