import {
  Button as AriaButton,
  Checkbox as AriakitCheckbox,
  CheckboxProps as AriakitCheckboxProps,
} from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasSmallCheck, atlasSmallMinus } from "@resource/atlas/icons";
import clsx from "clsx";

// TODO: Move into Atlas as v2 checkbox component
export function Checkbox({
  className,
  color,
  checkboxColor,
  disabled,
  label,
  ...props
}: AriakitCheckboxProps & {
  checkboxColor?: string;
  disabled?: boolean;
  label?: string;
}) {
  return (
    <AriakitCheckbox
      {...props}
      disabled={disabled}
      render={(checkboxProps) => {
        const checked = checkboxProps["aria-checked"];

        return (
          <AriaButton
            className={clsx("text-body-md flex flex-row space-x-2", {
              "opacity-50 pointer-events-none": disabled === true,
            })}
            {...checkboxProps}
          >
            <span
              className={clsx(
                "w-[1.25rem] h-[1.25rem] p-[.0625rem] focus:outline-none"
              )}
            >
              <div
                className={clsx(
                  "w-[1.125rem] h-[1.125rem] rounded border-[.0625rem]",
                  "flex items-center justify-center select-none text-white cursor-pointer",
                  {
                    "border-light-gray-700 bg-white": checked === false,
                    "border-transparent bg-purple-500": checked === true,
                  }
                )}
                style={{
                  backgroundColor: color,
                  borderColor: color,
                }}
              >
                <Icon
                  className={clsx("w-[.75rem] h-[.75rem]", {
                    "opacity-100": checked === true,
                    "opacity-0": checked === false,
                  })}
                  style={{
                    color: checkboxColor || "white",
                  }}
                  content={
                    checked === "mixed" ? atlasSmallMinus : atlasSmallCheck
                  }
                />
              </div>
            </span>
            {label && <span className="text-start">{label}</span>}
          </AriaButton>
        );
      }}
    />
  );
}
