import { useCallback, useEffect, useMemo, useState } from "react";

import { atlasArrowLeft, atlasClose } from "../../icons";
import { Button } from "../button/Button";
import { HeaderProps } from "../view/View";

// config
// ------

const BACK_BUTTON_ICON = atlasArrowLeft;

const DISMISS_ICON = atlasClose;

// types
// -----

export type ViewCallbackProps<K extends string> = {
  /**
   * Function to be called when the user wants to continue to the next view
   */
  onContinue: () => void;
  /**
   * Function to be called when the user wants to go back to the previous view
   */
  onBack: () => void;
  /**
   * Function to be called when the user wants to dismiss the whole view
   */
  onDismiss?: () => void;
  /**
   * Function to set the current view
   * @param view - The view to be set as the current view
   */
  setView: (view: K) => void;
  /**
   * The current view
   */
  currentView: K;
  /**
   * An ordered list of all views
   */
  orderedViews: K[];
  /**
   * A boolean indicating if the current view is the first view
   */
  isFirstView: boolean;
  /**
   * A boolean indicating if the current view is the last view
   */
  isLastView: boolean;
  /**
   * Icon to use for the back button when can go back
   * @default atlasArrowLeft
   */
  backIcon?: string;
  /**
   * Icon to use for the back button when isFirstView
   * @default atlasClose
   */
  dismissIcon?: string;
  /**
   * Default header props inferred from the state of the view
   */
  defaultHeaderProps: HeaderProps;
};

export type MultiStepViewProps<K extends string> = {
  /**
   * An ordered list of all views
   */
  orderedViews: K[];
  /**
   * An object where each key is a view and the value is a function that takes view callback props and returns a React node
   */
  views: {
    [key in K]?: (props: ViewCallbackProps<K>) => React.ReactNode;
  };
  /**
   * Function to be called when the user wants to dismiss the whole view
   */
  onDismiss?: () => void;
  /**
   * Icon to use for the back button when can go back
   * @default atlasArrowLeft
   */
  backIcon?: string;
  /**
   * Icon to use for the back button when isFirstView
   * @default atlasClose
   */
  dismissIcon?: string;
  /**
   * Titles configuration
   */
  titles?: {
    [key in K]?: string;
  };
};

// component
// ---------

export function MultiStepView<K extends string>({
  orderedViews,
  views,
  backIcon = BACK_BUTTON_ICON,
  dismissIcon = DISMISS_ICON,
  onDismiss,
  titles = {},
}: MultiStepViewProps<K>) {
  const [currentView, setCurrentView] = useState<K>(orderedViews[0] as K);
  const onContinue = useCallback(() => {
    const currentViewIndex = orderedViews.indexOf(currentView);
    setCurrentView(orderedViews[currentViewIndex + 1] as K);
  }, [currentView, orderedViews]);
  const onBack = useCallback(() => {
    const currentViewIndex = orderedViews.indexOf(currentView);
    setCurrentView(orderedViews[currentViewIndex - 1] as K);
  }, [currentView, orderedViews]);
  const isFirstView = currentView === orderedViews[0];

  useEffect(() => {
    if (!orderedViews.includes(currentView)) {
      setCurrentView(orderedViews[0] as K);
    }
  }, [currentView, orderedViews]);

  const defaultHeaderProps = useMemo((): HeaderProps => {
    return {
      title: titles[currentView],
      leftActions:
        !isFirstView || onDismiss ? (
          <Button
            isGhost
            size="small"
            icon={isFirstView ? dismissIcon : backIcon}
            onClick={isFirstView ? onDismiss : onBack}
          />
        ) : null,
    };
  }, [
    titles,
    currentView,
    isFirstView,
    dismissIcon,
    backIcon,
    onDismiss,
    onBack,
  ]);

  const viewProps = useMemo<ViewCallbackProps<K>>(
    () => ({
      onContinue,
      onBack,
      setView: setCurrentView,
      currentView,
      orderedViews,
      onDismiss,
      isFirstView,
      isLastView: currentView === orderedViews[orderedViews.length - 1],
      backIcon,
      dismissIcon,
      defaultHeaderProps,
    }),
    [
      onContinue,
      onBack,
      currentView,
      orderedViews,
      onDismiss,
      isFirstView,
      backIcon,
      dismissIcon,
      defaultHeaderProps,
    ]
  );

  return <>{views[currentView]?.(viewProps)}</>;
}
