import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasDragVertical,
  atlasRing,
  atlasTrash,
} from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import clsx from "clsx";
import { HTMLAttributes, RefCallback } from "react";

import { InterviewPlanItem } from "./types";

type Stage = Omit<InterviewPlanItem, "postTemplates">;

type StageItemProps = {
  isDraggable?: boolean;
  isDragOverlay?: boolean;
  dragListeners?: HTMLAttributes<HTMLElement>;
  dragAttributes?: HTMLAttributes<HTMLElement>;
  dragHandleRef: RefCallback<HTMLElement>;
  stage: Stage;
  isSelected: boolean;
  itemNumber: number;
  onClick: () => unknown;
  removeStage: (stage: { id: string }) => void;
};
function StageItem({
  stage,
  isSelected,
  itemNumber,
  onClick,
  isDraggable,
  dragHandleRef,
  dragListeners,
  dragAttributes,
  isDragOverlay,
  removeStage,
}: StageItemProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      data-atlas-wrapper="ListItem"
      className="w-full focus:outline-none text-left group"
    >
      <ListItem
        className={clsx(
          isDragOverlay && "shadow-24 translate-x-2 translate-y-2"
        )}
        isInteractive
        isSelected={isSelected}
        renderContent={({ children }) => (
          <div className="flex gap-2 items-center">
            <div>
              <div
                className={clsx(
                  "w-5 h-5 relative",
                  {
                    "group-hover:hidden group-focus-within:hidden": isDraggable,
                    hidden: isDraggable && isDragOverlay,
                  },
                  isSelected ? "text-blue-500" : "text-subtle"
                )}
              >
                <>
                  <Icon className="absolute inset-0" content={atlasRing} />
                  <span className="absolute inset-0 text-body-sm text-[.625rem] text-center w-5 leading-5">
                    {itemNumber}
                  </span>
                </>
              </div>
              {isDraggable && (
                <div
                  {...dragListeners}
                  {...dragAttributes}
                  role="button"
                  ref={dragHandleRef}
                  className={clsx(
                    "rounded focus-visible:focus-ring-0",
                    "group-focus-within:block group-hover:block text-subtle p-2 -mx-2 -my-2 cursor-grab",
                    { hidden: !isDragOverlay }
                  )}
                >
                  <Icon content={atlasDragVertical} />
                </div>
              )}
            </div>

            {children}
          </div>
        )}
        trailingContent={
          <div className="flex gap-2 items-center">
            <Button
              negativeMargin="left"
              className="opacity-0 group-hover:opacity-100 group-focus-within:opacity-100"
              size="xs"
              icon={atlasTrash}
              isGhost
              variant="dark"
              onClick={() => removeStage(stage)}
            />
          </div>
        }
      >
        <span>{stage.title}</span>
      </ListItem>
    </button>
  );
}

type SortableStageItemProps = {
  isDraggable?: boolean;
  isDragOverlay?: boolean;
  isDragging?: boolean;
  stage: Stage;
  stageIndex: number;
  selected: Stage | null;
  onSelect: (stage: Stage) => void;
  removeStage: (stage: { id: string }) => void;
};
export function SortableStageItem({
  isDraggable,
  isDragOverlay,
  isDragging,
  stage,
  stageIndex,
  selected,
  onSelect,
  removeStage,
}: SortableStageItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: stage.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  };

  return (
    <div ref={setNodeRef} style={style} className="focus:outline-none">
      {isDragging ? (
        <div className="h-[2.75rem] w-full rounded-md bg-light-gray-200" />
      ) : (
        <StageItem
          isDraggable={isDraggable}
          isDragOverlay={isDragOverlay}
          dragAttributes={attributes}
          dragListeners={listeners}
          dragHandleRef={setActivatorNodeRef}
          stage={stage}
          isSelected={stage.id === selected?.id}
          itemNumber={stageIndex + 1}
          onClick={() => {
            onSelect(stage);
          }}
          removeStage={removeStage}
        />
      )}
    </div>
  );
}
