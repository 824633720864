import { DialogProps, useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import { SchedulingTaskAndAvailabilityWorkflowsProps } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflows";
import { SchedulingTaskAndAvailabilityWorkflowsDialogProps } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import { useCallback, useMemo } from "react";

type UseSchedulingTaskAndAvailabilityWorkflowsNavigationProps = {
  onClick?: () => void;
  params: Omit<
    SchedulingTaskAndAvailabilityWorkflowsProps,
    "onBack" | "onCompleted"
  >;
  analytics?: Parameters<typeof useLogEvent>[0];
} & Pick<DialogProps, "variant" | "size">;

export function useSchedulingTaskAndAvailabilityWorkflowsNavigation({
  onClick: passedOnClick,
  params,
  analytics,
  variant = "sheet",
  size,
}: UseSchedulingTaskAndAvailabilityWorkflowsNavigationProps) {
  const logEvent = useLogEvent({
    component: "useSchedulingTaskAndAvailabilityWorkflowsNavigation",
    defaultSelectedRescheduleIds: params.defaultSelectedRescheduleIds,
    defaultSchedulingRequestFormType: params.defaultSchedulingRequestFormType,
    skipRequestingAvailability: params.skipRequestingAvailability,
    schedulingRequestIdForAvailabilityRequest:
      params.schedulingRequestIdForAvailabilityRequest,
    defaultNotes: params.defaultNotes,
    defaultAssignee: params.defaultAssignee,
    defaultCancelInterviewSettings: params.defaultCancelInterviewSettings,
    guideId: params.guideId,
    ...analytics,
  });
  const dialogStore = useDialogStore();

  const onClick = useCallback(() => {
    if (analytics) {
      logEvent("Scheduling Task And Availability Workflows Entered");
    }

    passedOnClick?.();

    dialogStore.show();
  }, [analytics, dialogStore, logEvent, passedOnClick]);

  return useMemo(
    (): {
      onClick: () => void;
      dialogProps: SchedulingTaskAndAvailabilityWorkflowsDialogProps;
    } => ({
      onClick,
      dialogProps: {
        ...params,
        variant,
        size,
        store: dialogStore,
      },
    }),
    [dialogStore, onClick, params, size, variant]
  );
}
