import { gql } from "generated/graphql-codegen";
import { PostMessageWorkflow } from "shared/message-composer/types";
import useQuery from "utils/useQuery";

import { mapWorkflowTypeToGraphQLInput } from "../utils";

gql(`
  fragment PostMessageDialogGuide on Guide {
    ...GuideForComposeMessageForm
    defaultMoveToInterviewPlanItem(workflowType: $workflowType) {
      id
    }
  }
`);
const POST_MESSAGE_QUERY = gql(`
  query PostMessageQuery(
    $guideId: String!
    $workflowType: GuidePostCreateWorkflowType
    $isCandidateInterviewConfirmation: Boolean = false
    $isCandidateRescheduleInterviewConfirmation: Boolean = false
    $isRequestAvailability: Boolean = false
    $isRequestAdditionalAvailability: Boolean = false
    $isSelfScheduleRequest: Boolean = false
  ) {
    guideById(guideId: $guideId) {
      ...PostMessageDialogGuide
    }
  }
`);

type UsePostMessageQueryProps = {
  guideId: string;
  workflowType: PostMessageWorkflow | undefined;
};

export function usePostMessageQuery({
  guideId,
  workflowType,
}: UsePostMessageQueryProps) {
  return useQuery(POST_MESSAGE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      guideId,
      workflowType: mapWorkflowTypeToGraphQLInput(workflowType),
      isCandidateInterviewConfirmation:
        workflowType === PostMessageWorkflow.CONFIRM_INTERVIEWS,
      isCandidateRescheduleInterviewConfirmation:
        workflowType === PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS,
      isRequestAvailability:
        workflowType === PostMessageWorkflow.REQUEST_AVAILABILITY,
      isRequestAdditionalAvailability:
        workflowType === PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY,
      isSelfScheduleRequest:
        workflowType === PostMessageWorkflow.SELF_SCHEDULE_REQUEST,
    },
  });
}
