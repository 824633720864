import {
  createNavigator,
  Navigator,
  NavigatorStateProps,
  useNavigatorContextOrThrow,
} from "client/components/generic/misc/Navigator";

import { SchedulingCompletionPromptsPostMessageView } from "./SchedulingCompletionPromptsPostMessageView";
import { SchedulingCompletionPromptsView } from "./SchedulingCompletionPromptsView";

const schedulingPromptsNavigator = createNavigator({
  views: {
    schedulingCompletionPrompts: {
      component: SchedulingCompletionPromptsView,
    },
    postMessage: {
      component: SchedulingCompletionPromptsPostMessageView,
    },
  },
});

type SchedulingPromptsNavigatorType = typeof schedulingPromptsNavigator;

export function useSchedulingPromptsNavigatorOrThrow() {
  return useNavigatorContextOrThrow<SchedulingPromptsNavigatorType>();
}

export function SchedulingCompletionPromptsNavigator<
  K extends keyof SchedulingPromptsNavigatorType["views"]
>(
  props: Omit<
    NavigatorStateProps<SchedulingPromptsNavigatorType, K>,
    "navigator"
  >
) {
  return <Navigator {...props} navigator={schedulingPromptsNavigator} />;
}
