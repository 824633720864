import { Button } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasClose } from "@resource/atlas/icons";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import { Interval } from "luxon";
import {
  ComponentProps,
  DragEvent as ReactDragEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { getBackgroundColorFromConfig, getTextColorFromConfig } from "./colors";
import { useCalendarContext } from "./context";
import { EventBackground, EventBackgroundProps } from "./EventBackground";
import { EventTitle } from "./EventTitle";
import { useCalendarSettings } from "./settings";
import { CalendarEvent } from "./types";
import {
  calculateEventGridRow,
  getColStartStyling,
  useCalendarSizes,
} from "./utils";

type EventBaseProps = {
  event: CalendarEvent;
  wrapperProps?: ComponentProps<"li">;
  eventProps?: Omit<EventBackgroundProps, "event">;
  hideEventTitle?: boolean;
  buttons?: ReactNode;
  children?: ReactNode;
};

function EventBase({
  event,
  wrapperProps,
  eventProps,
  hideEventTitle,
  buttons,
  children,
}: EventBaseProps) {
  const { twentyFourHourFormat, view } = useCalendarSettings();

  const { startTime, endTime } = event;
  const startOfDay = event.startTime.startOf("day");
  const minutesSinceStartOfDay = startTime.diff(startOfDay).as("minutes");
  const durationInMinutes = endTime.diff(startTime).as("minutes");
  const dayOfWeek = startTime.weekday;

  return (
    <li
      {...wrapperProps}
      data-cy={CypressData.calendar.event}
      // Note calendar-event class has no styling; it is used as identifier for use in listeners to see if a target is an event
      className={clsx(
        "calendar-event relative mt-px flex w-full transition-transform select-none group",
        getColStartStyling({
          view,
          dayOfWeek,
        }),
        {
          "z-0 pointer-events-none": event.isBackgroundEvent,
          "z-10": !event.isBackgroundEvent,
        },
        wrapperProps?.className
      )}
      style={{
        gridRow: calculateEventGridRow({
          minutesSinceStartOfDay,
          durationInMinutes,
        }),
        ...wrapperProps?.style,
      }}
    >
      <EventBackground
        event={event}
        {...eventProps}
        className={clsx(eventProps?.className, {
          "!py-[2px]": durationInMinutes < 60,
        })}
      >
        {!hideEventTitle && (
          <div className={clsx("flex flex-grow flex-col space-y-[2px]")}>
            <EventTitle event={event} />
            {durationInMinutes >= 60 && (
              <p
                className={clsx("text-dark", {
                  "ml-5": !!event.icon,
                })}
                style={{
                  color: getTextColorFromConfig(
                    event.colorConfig,
                    event.responseStatus
                  ),
                  lineHeight: "12px",
                }}
              >
                <time className="text-[12px]" style={{ lineHeight: "12px" }}>
                  {twentyFourHourFormat
                    ? startTime.toFormat("H:mm")
                    : startTime.toFormat("h:mm a")}
                  {" - "}
                  {twentyFourHourFormat
                    ? endTime.toFormat("H:mm")
                    : endTime.toFormat("h:mm a")}
                </time>
              </p>
            )}
          </div>
        )}

        <StopPropagation>{buttons}</StopPropagation>
      </EventBackground>
      {children}
    </li>
  );
}

function EventInteractive({
  event,
  wrapperProps,
  eventProps,
  hideEventTitle,
  ...props
}: EventBaseProps) {
  const { calculateTimeForOffset, calculateOffsetFromEvent, wrapperRef } =
    useCalendarContext();

  const calendarSizes = useCalendarSizes();
  const eventHeight = useMemo(() => {
    const durationInMinutes = event.endTime.diff(event.startTime).as("minutes");
    const heightInPx = (durationInMinutes / 60) * calendarSizes.hourHeight.px;
    return heightInPx;
  }, [calendarSizes.hourHeight.px, event.endTime, event.startTime]);
  // This is used to calculate the y offset of the mouse within the event when we start dragging
  // Without this, the top of the event will jump down to where the mouse is when we start dragging
  const [mouseDistanceFromTopOfEvent, setMouseDistanceFromTopOfEvent] =
    useState(0);
  const [dragStartPosition, setDragStartPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [draggingEvent, setDraggingEvent] = useState<CalendarEvent | null>(
    null
  );
  const dragHandle = useRef<HTMLDivElement | null>(null);
  const dragHandleImage = useRef<HTMLDivElement | null>(null);

  const handleAutoScroll = useCallback(
    (
      e: { clientY: number },
      opts: { topPadding: number; bottomPadding: number } = {
        topPadding: 0,
        bottomPadding: 0,
      }
    ) => {
      const MOUSE_SCROLL_THRESHOLD = 50;
      // no idea why this need to be different
      const TOP_SCROLL_SPEED = 1;
      const BOTTOM_SCROLL_SPEED = 1.5;

      if (wrapperRef) {
        // Automatically scroll as we drag
        if (
          e.clientY >
          wrapperRef.getBoundingClientRect().bottom -
            MOUSE_SCROLL_THRESHOLD -
            opts.bottomPadding
        ) {
          wrapperRef.scrollTop += BOTTOM_SCROLL_SPEED;
        }

        if (
          e.clientY <
          wrapperRef.getBoundingClientRect().top +
            MOUSE_SCROLL_THRESHOLD +
            calendarSizes.allDayEventsViewHeight.px +
            opts.topPadding
        ) {
          wrapperRef.scrollTop -= TOP_SCROLL_SPEED;
        }
      }
    },
    [calendarSizes.allDayEventsViewHeight.px, wrapperRef]
  );

  const dragBottomStart = useCallback(
    (e: ReactDragEvent) => {
      e.dataTransfer.setDragImage(dragHandleImage.current ?? new Image(), 0, 0);
      setDraggingEvent({ ...event });
    },
    [event]
  );

  const dragBottom = useCallback(
    (e: ReactDragEvent) => {
      const { clientX, clientY } = e;
      // This seems to happen right at end. Probably better way to detect this
      if (clientX === 0 && clientY === 0) {
        return;
      }

      const { x, y } = calculateOffsetFromEvent(e);

      const newEndTime = calculateTimeForOffset({
        x, // TODO: use current time instead
        y,
      });

      if (newEndTime) {
        // Don't allow dragging over multiple days
        const updatedEndTime = newEndTime.set({
          day: event.startTime.day,
          month: event.startTime.month,
          year: event.startTime.year,
        });

        setDraggingEvent((prev) => {
          if (!prev) {
            return null;
          }

          return {
            ...prev,
            endTime: updatedEndTime,
          };
        });
      }

      handleAutoScroll(e);
    },
    [
      calculateOffsetFromEvent,
      calculateTimeForOffset,
      event.startTime.day,
      event.startTime.month,
      event.startTime.year,
      handleAutoScroll,
    ]
  );

  const dragStart = useCallback(
    (e: ReactDragEvent) => {
      // Hack; we don't really want the dragHandle here, just any invisible element in order to hide the default drag image
      e.dataTransfer?.setDragImage(
        dragHandleImage.current ?? new Image(),
        0,
        0
      );

      setDragStartPosition({
        x: e.clientX,
        y: e.clientY,
      });
      setDraggingEvent({ ...event });
    },
    [event]
  );

  const dragEnd = useCallback(() => {
    if (draggingEvent && event?.onEdit) {
      if (!draggingEvent.startTime || !draggingEvent.endTime) {
        console.warn("Dragging event missing start or end time");
        return;
      }

      if (
        !Interval.fromDateTimes(draggingEvent.startTime, draggingEvent.endTime)
          .isValid
      ) {
        console.warn("Dragging event has invalid interval");
        return;
      }

      event.onEdit({
        startTime: draggingEvent.startTime,
        endTime: draggingEvent.endTime,
      });
    }

    setDragStartPosition(null);
    setDraggingEvent(null);
  }, [draggingEvent, event]);

  const drag = useCallback(
    (e: ReactDragEvent) => {
      if (!dragStartPosition) {
        return;
      }

      if (e.clientX === 0 && e.clientY === 0) {
        return;
      }

      const offset = calculateOffsetFromEvent(e);
      const offsetY = offset.y - mouseDistanceFromTopOfEvent;
      const draggedTime = calculateTimeForOffset({
        ...offset,
        y: offsetY > 0 ? offsetY : 0,
      });

      if (
        draggedTime &&
        draggedTime.startOf("day").equals(
          draggedTime
            .plus({
              minutes: event.endTime.diff(event.startTime).as("minutes"),
            })
            .startOf("day")
        )
      ) {
        setDraggingEvent((prev) => {
          if (!prev) {
            return null;
          }

          return {
            ...prev,
            startTime: draggedTime,
            endTime: draggedTime.plus({
              minutes: prev.endTime.diff(prev.startTime).as("minutes"),
            }),
          };
        });
      }

      handleAutoScroll(e, {
        topPadding: mouseDistanceFromTopOfEvent,
        bottomPadding: eventHeight - mouseDistanceFromTopOfEvent,
      });
    },
    [
      calculateOffsetFromEvent,
      calculateTimeForOffset,
      dragStartPosition,
      event.endTime,
      event.startTime,
      eventHeight,
      handleAutoScroll,
      mouseDistanceFromTopOfEvent,
    ]
  );

  return (
    <EventBase
      {...props}
      event={draggingEvent ?? event}
      wrapperProps={{
        ...wrapperProps,
        className: clsx(wrapperProps?.className, "calendar-event-interactive"),
        style: {
          ...wrapperProps?.style,
          ...(draggingEvent
            ? {
                left: 0,
                width: "100%",
                zIndex: 50,
              }
            : {}),
        },
      }}
      hideEventTitle={hideEventTitle}
      eventProps={{
        ...eventProps,
        className: clsx("cursor-pointer", eventProps?.className),
        draggable: true,
        onDragEnd: dragEnd,
        onDragStart: dragStart,
        onDrag: drag,
        onMouseDown: ({ yOffset }) => {
          setMouseDistanceFromTopOfEvent(yOffset);
        },
        style: {
          ...eventProps?.style,
          ...(draggingEvent
            ? {
                marginRight: 0,
              }
            : {}),
        },
      }}
    >
      <div ref={dragHandleImage} className="opacity-0 w-1 h-1" />
      <StopPropagation>
        <div
          ref={dragHandle}
          className="absolute bottom-0 h-2 w-full cursor-ns-resize bg-transparent"
          draggable
          onDragStart={dragBottomStart}
          onDrag={dragBottom}
          onDragEnd={dragEnd}
        />
      </StopPropagation>
    </EventBase>
  );
}

export function Event({
  ...props
}: {
  event: CalendarEvent;
  wrapperProps?: ComponentProps<"li">;
  eventProps?: Omit<EventBackgroundProps, "event">;
  hideEventTitle?: boolean;
}) {
  const { event } = props;

  const removeButtonProps = event.onRemove
    ? {
        buttons: (
          <Button
            className="hidden group-hover:flex flex-shrink-0 z-50 w-6 h-4 justify-center items-center absolute right-0"
            onClick={event.onRemove}
            style={{
              backgroundColor: getBackgroundColorFromConfig(event.colorConfig),
            }}
          >
            <Icon
              content={atlasClose}
              style={{
                color: getTextColorFromConfig(
                  event.colorConfig,
                  event.responseStatus
                ),
              }}
              className="h-4 w-4"
            />
          </Button>
        ),
      }
    : {};

  return event.onEdit ? (
    <EventInteractive {...removeButtonProps} {...props} />
  ) : (
    <EventBase {...removeButtonProps} {...props} />
  );
}
