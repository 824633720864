import { SerializedRichBlockNode } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { AvailabilitySubmissionInput } from "shared/guide-availability/types";

// config
// ------

export const RICH_BLOCK_NAME = "guide-availability-submission";

export const DEFAULT_DATA: GuideAvailabilitySubmissionData = {
  schedulingPreference: "FLEXIBLE",
  // Add values here for debugging purposes when adding manually
  notes: "",
  events: [],
  availabilityRequestId: "",
};

// types
// -----

export type GuideAvailabilitySubmissionName = typeof RICH_BLOCK_NAME;

export type GuideAvailabilitySubmissionData =
  | {
      availabilitySubmissionId: string;
      originalInputData: AvailabilitySubmissionInput;
    }
  | AvailabilitySubmissionInput;

export type SerializedGuideAvailabilitySubmissionNode = SerializedRichBlockNode<
  GuideAvailabilitySubmissionName,
  GuideAvailabilitySubmissionData
>;
