import { useMemo } from "react";

import { atlasVariable } from "../../../../icons/atlas";
import { INSERT_TARGETS } from "../../__utils/insert-options-targets-order";
import { createModule } from "../../__utils/modules";
import { useSelection } from "../../__utils/selection-context";
import { useInsertTypeaheadTrigger } from "../../__utils/typeahead";
import { UnknownVariableNode } from "./UnknownVariableNode";
import { VariableNode } from "./VariableNode";
import { VariablesPlugin } from "./VariablesPlugin";

export const variablesModule = createModule("variables", {
  createLinkedOptions() {
    return {
      insertOptions: [
        function useVariable() {
          const { isRangeSelection } = useSelection();
          const insert = useInsertTypeaheadTrigger("$");
          return useMemo(
            () => ({
              key: "variable",
              label: "Token",
              icon: atlasVariable,
              disabled: !isRangeSelection,
              onTrigger: insert,
              targets: {
                insert: INSERT_TARGETS.VARIABLE,
                fixedToolbar: { slot: "variable" },
              },
            }),
            [insert, isRangeSelection]
          );
        },
      ],
    };
  },
  declareModule() {
    return {
      nodes: [VariableNode],
      plugins: [<VariablesPlugin />],
    };
  },
});

export const unknownVariablesModule = createModule("unknown-variables", {
  declareModule() {
    return {
      nodes: [UnknownVariableNode],
    };
  },
});
