import { Link } from "@resource/atlas/link/Link";
import { CommentRenderer } from "client/comments/content-editor/CommentRenderer";
import {
  ActivityForFeedFragment,
  ActivityVerb,
} from "generated/graphql-codegen/graphql";
import { compact, get } from "lodash";
import {
  ActivityRequiredFields,
  SchedulingRequestActivityVerbs,
} from "shared/activity";
import { formatEntity } from "shared/constants/entities";
import { HistoricalScheduledInterview } from "shared/historical-data/scheduled-interviews/types";

import {
  ActorDisplayAvatar,
  ActorDisplayContent,
  ActorDisplayContentWithScheduledInterviews,
} from "./__utils/common";
import {
  ActivityFeedMapper,
  ActivityFunctionMap,
  ActivityGroupFunctionMap,
  BaseProps,
  FeedContext,
} from "./__utils/types";
import { InterviewActivityFeed } from "./InterviewFeed";

function schedulingRequestLabel(
  context: FeedContext,
  opts?: { isReschedule?: boolean }
) {
  return context === "SchedulingRequest"
    ? "this task"
    : `a ${opts?.isReschedule ? "rescheduling" : "scheduling"} task`;
}

function AssignedRequestDisplay({
  actor,
  user,
  context,
}: {
  actor: ActivityForFeedFragment["actor"];
  user: ActivityForFeedFragment["userMembership"];
  context: FeedContext;
}) {
  const actorName = actor?.name ?? "Someone";
  const userName = user?.name ?? "someone";

  if (!user) {
    return (
      <span>
        <span className="text-dark">{actorName}</span> unassigned{" "}
        {schedulingRequestLabel(context)}.
      </span>
    );
  }

  return (
    <span>
      <span className="text-dark">{actorName}</span> assigned{" "}
      {schedulingRequestLabel(context)} to{" "}
      <span className="text-dark">{userName}</span>.
    </span>
  );
}

function AssignedToQueueDisplay({
  actor,
  context,
}: {
  actor: ActivityForFeedFragment["actor"];
  context: FeedContext;
}) {
  const actorName = actor?.name ?? "Someone";

  return (
    <span>
      <span className="text-dark">{actorName}</span> assigned{" "}
      {schedulingRequestLabel(context)} to the coordination queue.
    </span>
  );
}

function SchedulingRequestCreatedDisplay({
  actor,
  ...rest
}: {
  actor: ActivityForFeedFragment["actor"];
  context: FeedContext;
  meta: ActivityRequiredFields<ActivityVerb.SchedulingRequestCreated>["meta"];
  eventTime: string;
  automatedActionId: string | null;
} & BaseProps) {
  if (!rest.meta) {
    // support legacy
    return (
      <ActorDisplayContent
        {...rest}
        actor={actor}
        actionText={`created ${schedulingRequestLabel(rest.context)}`}
      />
    );
  }

  const { meta } = rest;
  if (meta.isReschedule) {
    return (
      <ActorDisplayContent
        {...rest}
        actor={actor}
        actionText={
          <>
            created{" "}
            {schedulingRequestLabel(rest.context, { isReschedule: true })}
            {meta.originalSchedulingRequestId ? (
              <>
                as a reschedule of{" "}
                <Link
                  href={`/scheduling-requests?selectedSchedulingRequestId=${meta.originalSchedulingRequestId}`}
                  target="__blank"
                >
                  this task.
                </Link>
              </>
            ) : null}
          </>
        }
      />
    );
  }

  return (
    <ActorDisplayContent
      {...rest}
      actor={actor}
      actionText={`created ${schedulingRequestLabel(rest.context)} with ${
        meta.interviewsCount
      } interviews`}
    />
  );
}

export class SchedulingRequestActivityFeed extends ActivityFeedMapper<SchedulingRequestActivityVerbs> {
  private interviewMapper = new InterviewActivityFeed();

  isGroupableVerb(verb: ActivityVerb) {
    return [
      ActivityVerb.InterviewScheduled,
      ActivityVerb.InterviewRescheduled,
      ActivityVerb.InterviewCancelled,
    ].includes(verb);
  }

  readonly activityGroupMap: ActivityGroupFunctionMap<SchedulingRequestActivityVerbs> =
    {
      ...this.interviewMapper.activityGroupMap,

      SchedulingRequestCreated: null,
      SchedulingRequestUpdated: null,
      SchedulingRequestAvailabilitySent: null,
      SchedulingRequestAvailabilityReceived: null,
      SchedulingRequestAvailabilityManuallyAdded: null,
      SchedulingRequestAvailabilityManuallyUpdated: null,
      SchedulingRequestConfirmationSent: null,
      SchedulingRequestRescheduleConfirmationSent: null,
      SchedulingRequestCompleted: null,
      SchedulingRequestReopened: null,
      SchedulingRequestArchived: null,
      SchedulingRequestUnarchived: null,
      SchedulingRequestDrafted: null,
      SchedulingRequestAssigned: null,
      SchedulingRequestAssignedToQueue: null,
      SchedulingRequestPriorityChanged: null,
      SchedulingRequestAdditionalAvailabilitySent: null,
      CommentCreated: null,
      ChatMessagePosted: null,
      [ActivityVerb.InterviewScheduled]: ({ activities, context }) => ({
        content: (
          <ActorDisplayContentWithScheduledInterviews
            {...activities[0]}
            context={context}
            actor={activities[0].actor}
            actionText={`scheduled ${activities.length || 1} interview${
              activities.length && activities.length > 1 ? "s" : ""
            }`}
            historicalScheduledInterviews={compact(
              activities.map(
                (a) =>
                  get(
                    a.meta,
                    "historicalScheduledInterview"
                  ) as unknown as HistoricalScheduledInterview
              )
            )}
          />
        ),
        icon: <ActorDisplayAvatar actor={activities[0].actor} />,
      }),
      [ActivityVerb.InterviewRescheduled]: ({ activities, context }) => ({
        content: (
          <ActorDisplayContentWithScheduledInterviews
            {...activities[0]}
            context={context}
            actor={activities[0].actor}
            actionText={`rescheduled ${activities.length || 1} interview${
              activities.length && activities.length > 1 ? "s" : ""
            }`}
            historicalScheduledInterviews={compact(
              activities.map(
                (a) =>
                  get(
                    a.meta,
                    "historicalScheduledInterview"
                  ) as unknown as HistoricalScheduledInterview
              )
            )}
          />
        ),
        icon: <ActorDisplayAvatar actor={activities[0].actor} />,
      }),
      [ActivityVerb.InterviewRestored]: null, // Should always have a rescheduled event to show instead
      [ActivityVerb.InterviewCancelled]: ({ activities, context }) => ({
        content: (
          <ActorDisplayContentWithScheduledInterviews
            {...activities[0]}
            context={context}
            actor={activities[0].actor}
            actionText={`cancelled ${activities.length || 1} interview${
              activities.length && activities.length > 1 ? "s" : ""
            }`}
            historicalScheduledInterviews={compact(
              activities.map(
                (a) =>
                  get(
                    a.meta,
                    "historicalScheduledInterview"
                  ) as unknown as HistoricalScheduledInterview
              )
            )}
          />
        ),
        icon: <ActorDisplayAvatar actor={activities[0].actor} />,
      }),
    };

  readonly activityMap: ActivityFunctionMap<SchedulingRequestActivityVerbs> = {
    ...this.interviewMapper.activityMap,
    [ActivityVerb.ChatMessagePosted]: null,
    [ActivityVerb.SchedulingRequestDrafted]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`drafted ${schedulingRequestLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestCreated]: (props) => ({
      content: <SchedulingRequestCreatedDisplay {...props} />,
      icon: <ActorDisplayAvatar actor={props.actor} />,
    }),
    [ActivityVerb.SchedulingRequestUpdated]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`updated ${schedulingRequestLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestAvailabilitySent]: ({
      actor,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`sent ${formatEntity("availability request")}`}
          guidePostId={meta.guidePostId}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),

    [ActivityVerb.SchedulingRequestAdditionalAvailabilitySent]: ({
      actor,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`sent a request for additional ${formatEntity(
            "availability request"
          )}`}
          guidePostId={meta.guidePostId}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestAvailabilityReceived]: ({
      actor,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText="submitted availability"
          isCandidate
          guidePostId={meta.guidePostId}
        />
      ),
      icon: rest.candidateName ? (
        <ActorDisplayAvatar actor={{ name: rest.candidateName }} />
      ) : null,
    }),
    [ActivityVerb.SchedulingRequestAvailabilityManuallyAdded]: ({
      actor,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`manually added availability for ${schedulingRequestLabel(
            rest.context
          )}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestAvailabilityManuallyUpdated]: ({
      actor,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`manually updated availability for ${schedulingRequestLabel(
            rest.context
          )}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestConfirmationSent]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`sent a confirmation to ${rest.candidateName}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestRescheduleConfirmationSent]: ({
      actor,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`sent a reschedule confirmation to ${rest.candidateName}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestCompleted]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`completed ${schedulingRequestLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestReopened]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`reopened ${schedulingRequestLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestArchived]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`archived ${schedulingRequestLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestUnarchived]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`unarchived ${schedulingRequestLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestAssigned]: ({
      actor,
      userMembership,
      context,
    }) => ({
      content: (
        <AssignedRequestDisplay
          actor={actor}
          user={userMembership}
          context={context}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestAssignedToQueue]: ({ actor, context }) => ({
      content: <AssignedToQueueDisplay actor={actor} context={context} />,
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.SchedulingRequestPriorityChanged]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`changed priority of ${schedulingRequestLabel(
            rest.context
          )} to ${rest.meta.priority.toLowerCase()}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.CommentCreated]: ({ actor, meta: { message }, ...rest }) => ({
      content: (
        <div className="space-y-1" data-id={rest.commentId}>
          <ActorDisplayContent {...rest} actor={actor} actionText="commented" />
          <CommentRenderer
            content={message}
            className="text-body-md text-dark"
          />
        </div>
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
  };
}
