import clsx from "clsx";
import { ComponentPropsWithoutRef, useMemo } from "react";

type SectionProps = ComponentPropsWithoutRef<"div"> & {
  title: string;
  description?: string;
  rightActions?: React.ReactNode;
  size?: "small" | "default";
  action?: React.ReactNode;
};

/** Generic component providing a title and description to a section with spacing */
export function Section({
  title,
  description,
  children,
  rightActions,
  action,
  className,
  size = "default",
  ...rest
}: SectionProps) {
  const headingClassName = useMemo(() => {
    if (size === "small") {
      return clsx("text-h4");
    }

    return clsx("text-h3");
  }, [size]);

  return (
    <div {...rest} className={clsx("space-y-4", className)}>
      <div className="flex items-start justify-between gap-4">
        <div className="space-y-2">
          <h3 className={headingClassName}>{title}</h3>
          {description && (
            <div className="text-body-md text-subtle">{description}</div>
          )}
        </div>
        <div className="flex items-center gap-2">
          {action}
          {rightActions}
        </div>
      </div>
      {children}
    </div>
  );
}
