import { useFetchedConferenceRooms } from "client/components/conference-rooms/hooks/useFetchedConferenceRoomEvents";
import { mapFetchedConferenceRoomToConferenceRoom } from "client/components/conference-rooms/utils/mapping";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { UpsertScheduledInterviewFormState } from "./useUpsertScheduledInterviewFormState";

/** Based on upsert form state, fetch and cache conference rooms and map with availability */
export function useConferenceRoomsForForm({
  state,
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const { form, originalInterview } = state;
  const startTime = form.watch("scheduledInterview.startTime");
  const endTime = form.watch("scheduledInterview.endTime");
  const currentInterview = useMemo(
    () => ({
      startTime,
      endTime,
    }),
    [startTime, endTime]
  );

  const startDateTime = startTime ? DateTime.fromISO(startTime) : null;
  const {
    buildings,
    conferenceRooms: fetchedConferenceRooms,
    loading,
    refetch,
  } = useFetchedConferenceRooms({
    startTime: startDateTime?.startOf("day").toISO() ?? null,
    endTime: startDateTime?.plus({ days: 7 }).toISO() ?? null,
  });

  const mappedConferenceRooms = useMemo(() => {
    return fetchedConferenceRooms.map((conferenceRoom) =>
      mapFetchedConferenceRoomToConferenceRoom({
        conferenceRoom,
        buildings,
        currentInterview,
        originalInterview,
      })
    );
  }, [fetchedConferenceRooms, buildings, currentInterview, originalInterview]);

  return {
    buildings,
    conferenceRooms: mappedConferenceRooms,
    loading,
    refetch,
  };
}
