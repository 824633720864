import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

import { useViewFilter } from "./misc";

const FETCH_GUIDE_FOR_SCHEDULER = gql(`
  query GuideForScheduler($guideId: String!) {
    guideById(guideId: $guideId) {
      ...GuideForSchedulerV3
    }
  }  
`);

/** Fetch the scheduler Guide fragment out of the cache */
export function useSchedulerGuide() {
  const { guideId } = useViewFilter();
  const { data } = useQuery(FETCH_GUIDE_FOR_SCHEDULER, {
    fetchPolicy: "cache-only",
    variables: {
      guideId,
    },
  });
  const guide = useMemo(() => data?.guideById, [data]);

  if (!guide) {
    throw new Error("GuideForSchedulerV3 should be cached.");
  }

  return guide;
}
