import { NetworkStatus, useSubscription } from "@apollo/client";
import { atlasEllipsisHorizontal } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { Menu } from "@resource/atlas/menu-v2";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { NotificationForDisplayFragment } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

import { NoNotifications } from "./NoNotifications";
import { NotificationDisplay } from "./NotificationDisplay";
import { useNotificationMenuItems } from "./useNotificationMenuItems";
import { mapNotificationFragmentToNotification } from "./utils/mapping";

const NOTIFICATIONS_QUERY = gql(`
  query NotificationsForNotificationList($cursor: String, $take: Int) {
    currentUser {
      id
      currentUserMembership {
        id
        notifications(cursor: $cursor, take: $take) {
          ...NotificationForDisplay
        }
      }
    }
  }
`);

const NEW_NOTIFICATION_SUB = gql(`
  subscription NewNotification {
    notificationCreated {
      ...NotificationForDisplay
    }
  }
`);

const UPDATED_NOTIFICATION_SUB = gql(`
  subscription UpdatedNotification {
    notificationUpdated {
      ...NotificationForDisplay
    }
  }
`);

const PAGE_SIZE = 20;

const MARK_NOTIFICATIONS_AS_READ = gql(`
  mutation MarkNotificationsAsRead($ids: [String!]!) {
    markNotificationsAsRead(ids: $ids) {
      id
      readAt
    }
  }
`);

const MARK_ALL_NOTIFICATIONS_AS_READ = gql(`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      success
      code
      message
      notifications {
        id
        readAt
      }
    }
  }
`);

const DELETE_ALL_READ_NOTIFICATIONS = gql(`
  mutation DeleteAllReadNotifications {
    deleteAllReadNotifications {
      success
      code
      message
      deletedCount
    }
  }
`);

export function NotificationList({
  setOpen,
  className,
}: {
  setOpen?: (open: boolean) => void;
  className?: string;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { ref: loadMoreRef, inView } = useInView();

  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery(
    NOTIFICATIONS_QUERY,
    {
      variables: {
        cursor: null,
        take: PAGE_SIZE,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const notifications = useMemo(() => {
    return data?.currentUser?.currentUserMembership?.notifications ?? [];
  }, [data]);

  const [markNotificationsAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);
  const [markAllNotificationsAsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ
  );
  const [deleteAllReadNotifications] = useMutation(
    DELETE_ALL_READ_NOTIFICATIONS
  );

  const handleMarkAllAsRead = useCallback(async () => {
    try {
      await markAllNotificationsAsRead();
      await refetch();
    } catch (err) {
      console.error("Error marking all notifications as read:", err);
    }
  }, [markAllNotificationsAsRead, refetch]);

  const handleDeleteAllReadNotifications = useCallback(async () => {
    try {
      await deleteAllReadNotifications();
      await refetch();
    } catch (err) {
      console.error("Error deleting read notifications:", err);
    }
  }, [deleteAllReadNotifications, refetch]);

  const handleMarkAsRead = useCallback(
    async (notificationId: string) => {
      try {
        await markNotificationsAsRead({
          variables: {
            ids: [notificationId],
          },
        });
      } catch (err) {
        console.error("Error marking notification as read:", err);
      }
    },
    [markNotificationsAsRead]
  );

  const menuItems = useNotificationMenuItems({
    markAllAsRead: handleMarkAllAsRead,
    deleteAllReadNotifications: handleDeleteAllReadNotifications,
  });

  useSubscription(UPDATED_NOTIFICATION_SUB, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const updatedNotification = subscriptionData.data?.notificationUpdated;
      if (!updatedNotification) return;

      client.cache.modify({
        id: client.cache.identify(updatedNotification),
        fields: {
          readAt: () => updatedNotification.readAt,
          activity: () => updatedNotification.activity,
          userMembership: () => updatedNotification.userMembership,
          createdAt: () => updatedNotification.createdAt,
          eventTime: () => updatedNotification.eventTime,
        },
      });
    },
  });
  useSubscription(NEW_NOTIFICATION_SUB, {
    onSubscriptionData: ({ client, subscriptionData }) => {
      const notificationCreated = subscriptionData.data?.notificationCreated;
      if (!notificationCreated || !data?.currentUser?.currentUserMembership)
        return;

      // Use cache.modify instead of read/writeFragment
      client.cache.modify({
        id: `UserMembership:${data.currentUser.currentUserMembership.id}`,
        fields: {
          notifications(existingNotifications = []) {
            const notificationExists = existingNotifications.some(
              (n: NotificationForDisplayFragment) =>
                n.id === notificationCreated.id
            );
            if (notificationExists) return existingNotifications;

            // Add new notification to beginning of list
            return [notificationCreated, ...existingNotifications];
          },
        },
      });
      refetch();
    },
  });

  const loadNextPage = useCallback(async () => {
    if (loading || !notifications.length) return;

    const lastNotification = notifications[notifications.length - 1];

    try {
      await fetchMore({
        variables: {
          cursor: lastNotification.eventTime,
          take: PAGE_SIZE,
        },
      });
    } catch (err) {
      console.error("Error loading more notifications:", err);
    }
  }, [fetchMore, loading, notifications]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inView) {
        loadNextPage();
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [inView, loadNextPage]);

  const sortedNotifications = _(notifications)
    .uniqBy("id")
    .orderBy("activity.eventTime", "desc")
    .value();

  const renderContent = () => {
    if (loading && notifications.length === 0) {
      return (
        <div className="mt-12 flex items-center justify-center w-full">
          <LoadingIndicator size="small" />
        </div>
      );
    }

    if (error) {
      return <p>Error loading notifications.</p>;
    }

    if (sortedNotifications.length === 0) {
      return (
        <NoNotifications
          title={"You don't have any notifications"}
          subtitle={"No notifications at the moment. We'll keep you posted!"}
        />
      );
    }

    return (
      <>
        {sortedNotifications.map((notification) => (
          <NotificationDisplay
            key={notification.id}
            notification={mapNotificationFragmentToNotification(notification)}
            onClick={() => {
              handleMarkAsRead(notification.id);
              setOpen?.(false);
            }}
          />
        ))}
        {networkStatus === NetworkStatus.fetchMore && (
          <div className="mt-6 flex items-center justify-center w-full">
            <LoadingIndicator size="small" />
          </div>
        )}
        <div ref={loadMoreRef} className="h-8" />
      </>
    );
  };

  return (
    <div className={clsx("flex flex-col h-full", className)}>
      <div>
        <div className="flex justify-between items-center p-6">
          <div className="flex items-center justify-between w-full h-6">
            <h3 className="text-h3">Notifications</h3>

            <Menu.Root open={menuOpen} setOpen={setMenuOpen}>
              <Menu.Button
                icon={atlasEllipsisHorizontal}
                isGhost
                negativeMargin="right"
              />
              {menuOpen && <Menu.Menu>{menuItems}</Menu.Menu>}
            </Menu.Root>
          </div>
        </div>
      </div>
      <div className="overflow-y-scroll px-3 pb-6">{renderContent()}</div>
    </div>
  );
}

NotificationList.storyData = {
  query: NOTIFICATIONS_QUERY,
  newNotificationSub: NEW_NOTIFICATION_SUB,
  updatedNotificationSub: UPDATED_NOTIFICATION_SUB,
  markNotificationsAsRead: MARK_NOTIFICATIONS_AS_READ,
};
