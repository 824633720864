import { DateTime } from "luxon";
import { getReadableTimezone } from "shared/utils/timezones";

export const getFormattedMessageTime = (time: DateTime) => {
  return `${time.toFormat("EEE, MMM d 'at' h:mma")} (${getReadableTimezone({
    timezone: time.zoneName,
    labelType: "abbreviation",
  })})`;
};

export const getDefaultCancellationMessage = (startTime: DateTime) => {
  return `Quick heads up – I need to cancel our interview scheduled for ${getFormattedMessageTime(
    startTime
  )}. Apologies for any inconvenience. Let's touch base soon to reschedule.`;
};
