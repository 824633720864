import { createValueSet } from "@resource/atlas/content-editor/variables";
import { gql } from "generated/graphql-codegen";
import { GuideBaseVariableValuesGuideFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { GuideBaseVariableSet } from "shared/guide-content/base";

import { convertInterviewVariables } from "./interview-variables";

const GUIDE_LEXICAL_CONTEXT_FRAGMENT = gql(`
fragment GuideLexicalContext on GuideLexicalContext {
    id
    href
    recruiterName {
      firstName
      lastName
      fullName
    }
    candidateName {
      firstName
      lastName
      fullName
    }
    candidatePhone
    coordinatorName {
      firstName
      lastName
      fullName
    }
    hiringManagerName {
      firstName
      lastName
      fullName
    }
    recruiterJobTitle
    coordinatorJobTitle
    hiringManagerJobTitle
    jobTitle
    jobListingLink: jobListingUrl
    companyName
    upcomingInterviews {
      startTime
      endTime
      interviewers {
         title
         firstName
         lastName
         fullName
      }
    }
  }
`);

export const GUIDE_BASE_VARIABLE_VALUES_GUIDE_FRAGMENT = gql(`
fragment GuideBaseVariableValuesGuide on Guide {
    id
    guideLexicalContext {
      ...GuideLexicalContext
    }
  }
`);

export function useGuideBaseValueSet(
  guide?: GuideBaseVariableValuesGuideFragment
) {
  return useMemo(() => {
    if (!guide) return {};

    const {
      guideLexicalContext: {
        coordinatorName,
        recruiterName,
        candidateName,
        hiringManagerName,
        recruiterJobTitle,
        coordinatorJobTitle,
        hiringManagerJobTitle,
        candidatePhone,
        jobListingLink,
        jobTitle,
        href,
        companyName,
        upcomingInterviews,
      },
    } = guide;

    return createValueSet<GuideBaseVariableSet>({
      ...(recruiterName.firstName
        ? {
            atsRecruiterName: {
              first: recruiterName.firstName,
              last: recruiterName.lastName ?? "",
            },
          }
        : {}),
      ...(coordinatorName?.firstName
        ? {
            atsCoordinatorName: {
              first: coordinatorName?.firstName ?? "",
              last: coordinatorName?.lastName ?? "",
            },
          }
        : {}),
      ...(hiringManagerName?.firstName
        ? {
            hiringManagerName: {
              first: hiringManagerName?.firstName ?? "",
              last: hiringManagerName?.lastName ?? "",
            },
          }
        : {}),
      candidateName: {
        first: candidateName.firstName,
        last: candidateName.lastName ?? "",
      },
      candidatePhone: candidatePhone ?? "",
      jobTitle,
      jobListingLink: jobListingLink ?? "",
      guideLink: href ?? "",
      companyName,
      atsRecruiterJobTitle: recruiterJobTitle ?? "",
      atsCoordinatorJobTitle: coordinatorJobTitle ?? "",
      hiringManagerJobTitle: hiringManagerJobTitle ?? "",
      ...convertInterviewVariables({
        interviews: upcomingInterviews,
      }),
    });
  }, [guide]);
}

useGuideBaseValueSet.fragments = {
  guide: GUIDE_BASE_VARIABLE_VALUES_GUIDE_FRAGMENT,
  guideLexicalContext: GUIDE_LEXICAL_CONTEXT_FRAGMENT,
};
