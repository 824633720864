import { useState } from "react";

import { atlasCard } from "../../../../../icons/atlas";
import { useEvent, useStaticValue } from "../../../../__utils/react";
import { INSERT_TARGETS } from "../../../__utils/insert-options-targets-order";
import { createRichBlock, RichBlockProps } from "../../../__utils/rich-blocks";
import { LinkCard } from "../shared/LinkCard";
import { LinkCardPopover } from "../shared/LinkCardPopover";
import {
  useBaseLinkCardMenuItems,
  useLinkCardInsertOption,
} from "../shared/misc";
import { useLinkCardConfig } from "./config";
import { DEFAULT_DATA, LinkCardData, RICH_BLOCK_NAME } from "./shared";

// component
// ---------

function LinkCardComponent({
  data,
  updateData,
  ConfigMenu,
  ConfigPopover,
}: RichBlockProps<LinkCardData>) {
  const { fetchUrlMetadata, onReplaceImage } = useLinkCardConfig();

  const [editOpen, setEditOpen] = useState(false);

  const configMenuItems = useBaseLinkCardMenuItems({
    onEdit: () => setEditOpen(true),
    onReplaceImage: () =>
      onReplaceImage((imageUrl) => updateData({ imageUrl })),
  });

  return (
    <>
      <ConfigMenu items={configMenuItems} />
      <LinkCardPopover
        PopoverComponent={ConfigPopover}
        open={editOpen}
        setOpen={setEditOpen}
        fetchUrlMetadata={fetchUrlMetadata}
        defaults={data}
        onSubmit={(cardData) => updateData(cardData)}
        title="Edit link card"
        submitLabel="Save changes"
      />
      <LinkCard data={data} />
    </>
  );
}

// block
// -----

export const {
  $createLinkCardNode,
  $isLinkCardNode,
  INSERT_LINK_CARD_COMMAND,
  LinkCardNode,
  LinkCardPlugin,
  linkCardModule,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => LinkCardComponent,
  useInsertOption: ({ getAnchorRect }, { insert }) => {
    const { fetchUrlMetadata } = useLinkCardConfig();
    return useLinkCardInsertOption({
      fetchUrlMetadata,
      getAnchorRect,
      onSubmit: useEvent((cardData) => insert(cardData)),
      label: "Card link",
      icon: atlasCard,
      targets: useStaticValue({
        insert: INSERT_TARGETS.CARD_LINK,
      }),
    });
  },
  useRender() {
    const { tmpRender } = useLinkCardConfig();
    return tmpRender;
  },
});
