import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { AtlasButtonProps } from "@resource/atlas/button/types";
import {
  Dialog,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import type { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasCelebrate,
  atlasCheck,
  atlasMailError,
  atlasPersonCheck,
  atlasThumbsDown,
  atlasThumbsUp,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { PostMessageDialog } from "client/message-composer/__components/PostMessageDialog";
import { usePostMessageDialogForWorkflowProps } from "client/message-composer/__hooks/usePostMessageDialogProps";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  GuideStatusEnum,
  OrganizationFeaturesEnum,
} from "generated/graphql-codegen/graphql";
import { capitalize } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";
import {
  CHANGE_GUIDE_STATUS_SUPPORT_PAGE,
  MARK_AS_HIRED_SUPPORT_PAGE,
  MARK_AS_OFFER_SUPPORT_PAGE,
  MARK_AS_REJECTED_SUPPORT_PAGE,
} from "utils/constants/href";
import useMutation from "utils/useMutation";

export const hiringDecisionToDisplay: Record<
  GuideStatusEnum,
  {
    commonName: string;
    dialogTitle: string;
    icon: AtlasIconData;
    iconClassname: string;
    helperLinkUrl: string;
  }
> = {
  [GuideStatusEnum.NOT_SHARED]: {
    commonName: "Not shared",
    dialogTitle: "",
    icon: atlasMailError,
    iconClassname: "text-dark",
    helperLinkUrl: "",
  },
  [GuideStatusEnum.ACTIVE]: {
    commonName: "Active",
    dialogTitle: "",
    icon: atlasPersonCheck,
    iconClassname: "text-dark",
    helperLinkUrl: "",
  },
  [GuideStatusEnum.OFFER]: {
    commonName: "Offer",
    dialogTitle: "Notify of Offer",
    icon: atlasCelebrate,
    iconClassname: "text-purple-500",
    helperLinkUrl: MARK_AS_OFFER_SUPPORT_PAGE,
  },
  [GuideStatusEnum.HIRED]: {
    commonName: "Hired",
    dialogTitle: "Notify of Hired",
    icon: atlasThumbsUp,
    iconClassname: "text-success",
    helperLinkUrl: MARK_AS_HIRED_SUPPORT_PAGE,
  },
  [GuideStatusEnum.REJECTED]: {
    commonName: "Rejected",
    dialogTitle: "Notify of Rejected",
    icon: atlasThumbsDown,
    iconClassname: "text-danger",
    helperLinkUrl: MARK_AS_REJECTED_SUPPORT_PAGE,
  },
};

const hiringDecisionsForMenu: {
  type: "item";
  icon: AtlasIconData;
  label: string;
  value: GuideStatusEnum;
  color: string;
  canNotify: boolean;
}[] = [
  {
    type: "item",
    icon: hiringDecisionToDisplay[GuideStatusEnum.NOT_SHARED].icon,
    label: hiringDecisionToDisplay[GuideStatusEnum.NOT_SHARED].commonName,
    value: GuideStatusEnum.NOT_SHARED,
    color: "inherit",
    canNotify: false,
  },
  {
    type: "item",
    icon: hiringDecisionToDisplay[GuideStatusEnum.ACTIVE].icon,
    label: hiringDecisionToDisplay[GuideStatusEnum.ACTIVE].commonName,
    value: GuideStatusEnum.ACTIVE,
    color: "inherit",
    canNotify: false,
  },
  {
    type: "item",
    icon: hiringDecisionToDisplay[GuideStatusEnum.OFFER].icon,
    label: hiringDecisionToDisplay[GuideStatusEnum.OFFER].commonName,
    value: GuideStatusEnum.OFFER,
    color: "purple-500",
    canNotify: true,
  },
  {
    type: "item",
    icon: hiringDecisionToDisplay[GuideStatusEnum.HIRED].icon,
    label: hiringDecisionToDisplay[GuideStatusEnum.HIRED].commonName,
    value: GuideStatusEnum.HIRED,
    color: "success-500",
    canNotify: true,
  },
  {
    type: "item",
    icon: hiringDecisionToDisplay[GuideStatusEnum.REJECTED].icon,
    label: hiringDecisionToDisplay[GuideStatusEnum.REJECTED].commonName,
    value: GuideStatusEnum.REJECTED,
    color: "danger-500",
    canNotify: true,
  },
];

const UPDATE_HIRING_DECISION = gql(`
mutation UpdateGuideStatus($input: UpdateGuideStatusInput!) {
    updateGuideStatus(input: $input) {
      message
      success
      code
      guide {
        id
        status
      }
    }
  }
`);

type HiringDecisionMenuProps = Omit<AtlasButtonProps, "onSelect"> & {
  currentHiringDecision: GuideStatusEnum;
  onSelect: (decision: (typeof hiringDecisionsForMenu)[0]) => void;
};

export function HiringDecisionMenu({
  currentHiringDecision,
  onSelect,
  ...buttonProps
}: HiringDecisionMenuProps) {
  const hiringMenuOptions = useMenuItems(
    (i) => [
      i.item({
        key: "section-heading",
        children: "Change candidate's status in Guide",
        isSelectable: false,
        render: ({ children }) => {
          return (
            <div className=" px-3 py-2 space-y-3">
              <p className="text-body-md-heavy">{children}</p>
            </div>
          );
        },
      }),
      ...hiringDecisionsForMenu.map((decision) => {
        return i.item({
          key: decision.label,
          children: decision.label,
          className: `flex items-center gap-2 ${decision.color} !py-1.5`,
          onClick: () => {
            onSelect(decision);
          },
          leadingContent:
            decision.value === currentHiringDecision ? (
              <Icon content={atlasCheck} className="text-red-500" />
            ) : (
              <div className="w-5" />
            ),
          renderContent: () => {
            return (
              <div className="flex items-center gap-2">
                <Icon
                  content={decision.icon}
                  className={`text-${decision.color}`}
                />
                <span>{decision.label}</span>
              </div>
            );
          },
        });
      }),
      i.item({
        key: "section-footer",
        children: "Learn more",
        isSelectable: false,
        render: ({ children }) => {
          return (
            <div className="flex items-center justify-end px-3 py-2 space-y-3">
              <Link
                className="text-body-md"
                target="_blank"
                href={CHANGE_GUIDE_STATUS_SUPPORT_PAGE}
              >
                {children}
              </Link>
            </div>
          );
        },
      }),
    ],
    [currentHiringDecision, onSelect]
  );

  return (
    <Menu.Root>
      <Menu.Trigger>
        <Button
          icon={hiringDecisionToDisplay[currentHiringDecision].icon}
          isDropdown
          {...buttonProps}
        />
      </Menu.Trigger>
      <Menu.Content items={hiringMenuOptions} />
    </Menu.Root>
  );
}

type HiringDecisionSelectProps = {
  currentHiringDecision: GuideStatusEnum;
  guideId: string;
  candidateName?: string;
};
export function HiringDecisionSelect({
  currentHiringDecision,
  guideId,
  candidateName,
}: HiringDecisionSelectProps) {
  const logEvent = useLogEvent({
    component: "HiringDecisionSelect",
    project: "Change the candidate's hiring status",
  });

  const [newHiringDecision, setNewHiringDecision] = useState<GuideStatusEnum>();
  const store = useDialogStore();

  const { hasFeatureEnabled } = useAuthContext();
  const isMessagingEnabled = hasFeatureEnabled(
    OrganizationFeaturesEnum.MESSAGING
  );

  const [updateHiringDecision] = useMutation(UPDATE_HIRING_DECISION);

  const handleUpdate = useCallback(
    async (newHiringDecisionParam: GuideStatusEnum) => {
      logEvent("Change the candidate's hiring status without messaging", {
        newHiringDecision: newHiringDecisionParam,
        guideId,
      });

      await updateHiringDecision({
        variables: {
          input: {
            guideId,
            status: newHiringDecisionParam,
          },
        },
      });
    },
    [guideId, logEvent, updateHiringDecision]
  );

  return (
    <>
      <HiringDecisionMenu
        currentHiringDecision={currentHiringDecision}
        onSelect={(decision) => {
          if (decision.canNotify && isMessagingEnabled) {
            setNewHiringDecision(decision.value);
            store.show();
          } else {
            handleUpdate(decision.value);
          }
        }}
      />
      {newHiringDecision && (
        <HiringDecisionConfirmationDialog
          newHiringDecision={newHiringDecision}
          guideId={guideId}
          store={store}
          candidateName={candidateName}
        />
      )}
    </>
  );
}

export type HiringDecisionConfirmationViewProps = {
  newHiringDecision: GuideStatusEnum;
  guideId: string;
  candidateName?: string;
  store: DialogStore;
  messageDialogStore: DialogStore;
};

function HiringDecisionConfirmationView({
  newHiringDecision,
  guideId,
  candidateName,
  store,
  messageDialogStore,
}: HiringDecisionConfirmationViewProps) {
  const decision = hiringDecisionToDisplay[newHiringDecision];

  const initialFocusRef = useRef<HTMLButtonElement>(null);
  const logEvent = useLogEvent({
    component: "HiringDecisionSelect",
    project: "Change the candidate's hiring status",
  });

  const [updateHiringDecision, { loading }] = useMutation(
    UPDATE_HIRING_DECISION
  );

  const handleUpdate = useCallback(
    async (newHiringDecisionParam: GuideStatusEnum) => {
      logEvent("Change the candidate's hiring status without messaging", {
        newHiringDecision: newHiringDecisionParam,
        guideId,
      });

      await updateHiringDecision({
        variables: {
          input: {
            guideId,
            status: newHiringDecisionParam,
          },
        },
      });

      store.hide();
    },
    [guideId, logEvent, updateHiringDecision, store]
  );

  const handleUpdateAndMessage = useCallback(
    (newHiringDecisionParam: GuideStatusEnum) => {
      logEvent("Change the candidate's hiring status with messaging", {
        newHiringDecision: newHiringDecisionParam,
        guideId,
      });

      messageDialogStore.show();
    },
    [guideId, logEvent, messageDialogStore]
  );

  const display = hiringDecisionToDisplay[newHiringDecision];

  if (!display) {
    return null;
  }

  return (
    <View
      header={{
        hideBorder: true,
      }}
      footer={{
        hideBorder: true,
        rightActions: (
          <ButtonGroup>
            <Button
              isGhost
              onClick={() => handleUpdate(newHiringDecision)}
              isLoading={loading}
            >
              Update silently
            </Button>
            <Button
              variant="primary"
              ref={initialFocusRef}
              disabled={loading}
              onClick={() => handleUpdateAndMessage(newHiringDecision)}
            >
              Write a message
            </Button>
          </ButtonGroup>
        ),
      }}
    >
      <div className="flex flex-col items-center space-y-3 text-center">
        <Icon
          content={decision.icon}
          className={clsx(decision.iconClassname, "w-12 h-12")}
        />
        <p className="text-body-lg-heavy">
          {candidateName ? `${candidateName}'s` : "The candidate's"} portal has
          been updated to reflect their {capitalize(newHiringDecision)} status.
        </p>

        <p className="text-body-md inline">
          The next time they visit their portal it will visually indicate this
          status change. You can add an optional message to notify them of the
          change.{" "}
          <Link
            className="inline !ml-1"
            target="_blank"
            href={display.helperLinkUrl}
          >
            Learn more
          </Link>
        </p>
      </div>
    </View>
  );
}

export function HiringDecisionConfirmationDialog(
  props: Omit<HiringDecisionConfirmationViewProps, "messageDialogStore">
) {
  const { store, newHiringDecision, guideId } = props;
  const workflowType: PostMessageWorkflow | null = useMemo(() => {
    switch (newHiringDecision) {
      case GuideStatusEnum.OFFER:
        return PostMessageWorkflow.NOTIFY_OF_OFFER;
      case GuideStatusEnum.HIRED:
        return PostMessageWorkflow.NOTIFY_OF_HIRED;
      case GuideStatusEnum.REJECTED:
        return PostMessageWorkflow.NOTIFY_OF_REJECTION;
      default:
        return null;
    }
  }, [newHiringDecision]);
  const { props: messageDialogProps, store: messageDialogStore } =
    usePostMessageDialogForWorkflowProps({
      workflowType,
      guideId,
    });

  return (
    <Dialog store={store} size="small-plus">
      <HiringDecisionConfirmationView
        {...props}
        messageDialogStore={messageDialogStore}
      />
      <PostMessageDialog
        dialog={{
          store: messageDialogStore,
        }}
        onCompleted={() => {
          store.hide();
        }}
        {...messageDialogProps}
      />
    </Dialog>
  );
}
