import "./Card.sass";

import clsx from "clsx";
import type {
  ComponentPropsWithoutRef,
  HTMLAttributes,
  ReactElement,
} from "react";

import { atlasLink } from "../../../../../icons/atlas";
import { createComponentUtils } from "../../../../__utils/atlas";
import { Icon } from "../../../../icon/Icon";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Card";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// utils
// -----

type AnchorOrDivProps = HTMLAttributes<unknown> & { href?: string };

function LinkOrDiv({ href, ...props }: AnchorOrDivProps) {
  return href ? (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a href={href} target="__blank" rel="noreferrer" {...props} />
  ) : (
    <div {...props} />
  );
}

// link card
// ---------

export type CardProps = {
  label: string;
  url?: string;
  imageUrl?: string;
  description?: string;
  showUrl?: boolean;
  showDescription?: boolean;
  menuSlot?: ReactElement;
} & ComponentPropsWithoutRef<"div">;

export function Card({
  label,
  url,
  imageUrl,
  description,
  showUrl = true,
  showDescription = true,
  menuSlot,
  ...props
}: CardProps) {
  return (
    <div {...props} className={clsx(ROOT, props.className)}>
      {menuSlot}
      <LinkOrDiv href={url} className="imageWrapper">
        {imageUrl ? (
          <img className="image" alt={label} src={imageUrl} />
        ) : (
          <div className="fallback">
            <Icon content={atlasLink} className="fallbackIcon" />
          </div>
        )}
      </LinkOrDiv>
      <LinkOrDiv href={url}>
        <div className="linkInfoWrapper">
          <p className="linkInfoLabel">{label}</p>
          {showDescription && description && (
            <p className="linkInfoDescription">{description}</p>
          )}
          {showUrl && url && (
            <p className="linkInfoUrl">{new URL(url).hostname}</p>
          )}
        </div>
      </LinkOrDiv>
    </div>
  );
}
