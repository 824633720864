import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";

import { CalendarEventInputDateTime, CalendarView } from "./types";

/** offset for starting rows, not entirely sure why this is necessary still */
export const ROWS_OFFSET = 2;
/** number of rows each hour is broken into */
export const ROWS_PER_HOUR = 12;
/** divide number of minutes since start of day by this to get which row to start at */
export const MINUTES_TO_ROWS_DIVISOR = 60 / ROWS_PER_HOUR;
/** number of minutes in an hour */
export const MINUTES_IN_HOUR = 60;
/** number of hours in a day */
export const HOURS_IN_DAY = 24;
/** number of minutes in a day */
export const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;
/** number of hours in a day multipled by number of rows per hour */
export const ROWS_IN_DAY = ROWS_PER_HOUR * HOURS_IN_DAY;
/** number of days in a week */
export const DAYS_IN_WEEK = 7;

/** Calculate how to properly place event in grid based on when it starts and how long it is */
export const calculateEventGridRow = ({
  minutesSinceStartOfDay,
  durationInMinutes,
}: {
  minutesSinceStartOfDay: number;
  durationInMinutes: number;
}) => {
  const roundedDuration = Math.ceil(durationInMinutes / 5) * 5;

  return `${
    minutesSinceStartOfDay / MINUTES_TO_ROWS_DIVISOR + ROWS_OFFSET
  } / span ${roundedDuration / MINUTES_TO_ROWS_DIVISOR}`;
};

export const getColStartStyling = ({
  view,
  dayOfWeek,
}: {
  view: CalendarView;
  dayOfWeek: number;
}) => {
  return {
    "col-start-1": view === "day" || dayOfWeek === 1,
    "col-start-2": view === "week" && dayOfWeek === 2,
    "col-start-3": view === "week" && dayOfWeek === 3,
    "col-start-4": view === "week" && dayOfWeek === 4,
    "col-start-5": view === "week" && dayOfWeek === 5,
    "col-start-6": view === "week" && dayOfWeek === 6,
    "col-start-7": view === "week" && dayOfWeek === 7,
  };
};

export const convertRemToPx = (val: number) => val * 16;

const SMALL_SCREEN_WIDTH = 600;

type SizeType = {
  rem: number;
  px: number;
};

type CalendarSizes = {
  hourHeight: SizeType;
  leftOffsetWidth: SizeType;
  rightOffsetWidth: SizeType;
  allDayEventsViewHeight: SizeType;
};

type RemCalendarSizes = {
  [key in keyof CalendarSizes]: number;
};

// TODO: ideally could base off calendar width instead of screen width
export const useCalendarSizes = (): CalendarSizes => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const baseValues = {
    hourHeight: 3,
    leftOffsetWidth: 3.5,
    allDayEventsViewHeight: 3.5,
  };

  let remValues: RemCalendarSizes;

  if (width <= SMALL_SCREEN_WIDTH) {
    remValues = {
      ...baseValues,
      rightOffsetWidth: 0,
    };
  } else {
    remValues = {
      ...baseValues,
      rightOffsetWidth: 2,
    };
  }

  return Object.entries(remValues).reduce((key, [keyName, remValue]) => {
    return {
      ...key,
      [keyName]: {
        rem: remValue,
        px: convertRemToPx(remValue),
      },
    };
  }, {} as CalendarSizes);
};

type CombineEventsEvent = {
  id: string;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
};

export function combineEvents<T extends CombineEventsEvent>(
  events: T[]
): CombineEventsEvent[] {
  // If any of the events are invalid, return the original events
  // and rely on errors being shown in the UI
  const anyEventsInvalid = events.some((event) => {
    return !!Interval.fromDateTimes(event.startTime, event.endTime)
      .invalidReason;
  });

  if (anyEventsInvalid) {
    return events;
  }

  const sortedEvents = events.sort((a, b) => {
    return a.startTime.toMillis() - b.startTime.toMillis();
  });

  const combinedEvents: {
    id: string;
    title: string;
    interval: Interval;
  }[] = [];

  sortedEvents.forEach((event) => {
    const currentEventInterval = {
      id: event.id,
      title: event.title,
      interval: Interval.fromDateTimes(event.startTime, event.endTime),
    };

    if (combinedEvents.length > 0) {
      const lastEvent = combinedEvents[combinedEvents.length - 1];

      if (
        lastEvent.interval.overlaps(currentEventInterval.interval) ||
        lastEvent.interval.abutsStart(currentEventInterval.interval)
      ) {
        // If the current event overlaps with the last combined event or is back to back, extend the end time of the last combined event
        lastEvent.interval = Interval.fromDateTimes(
          lastEvent.interval.start,
          DateTime.max(
            lastEvent.interval.end,
            currentEventInterval.interval.end
          )
        );
      } else {
        // If the current event doesn't overlap or abut with the last combined event, add it to the combined events
        combinedEvents.push(currentEventInterval);
      }
    } else {
      combinedEvents.push(currentEventInterval);
    }
  });

  return combinedEvents.map((event) => ({
    id: event.id,
    title: event.title,
    startTime: event.interval.start,
    endTime: event.interval.end,
  }));
}

export function mapCalendarEventInputToLuxonTimes(
  {
    start,
    end,
  }: {
    start: CalendarEventInputDateTime;
    end: CalendarEventInputDateTime;
  },
  timezone?: string
) {
  let allDay: boolean | undefined;
  let startTime: DateTime;
  let endTime: DateTime;

  if (start.dateTime) {
    startTime = DateTime.fromISO(start.dateTime, {
      zone: timezone,
    });
  } else if (start.date) {
    startTime = DateTime.fromISO(start.date, {
      zone: timezone,
    }).startOf("day");
    allDay = true;
  } else {
    console.error("No start time provided");
    startTime = DateTime.local().startOf("day");
  }

  if (end.dateTime) {
    endTime = DateTime.fromISO(end.dateTime, {
      zone: timezone,
    });
  } else if (end.date) {
    endTime = DateTime.fromISO(end.date, {
      zone: timezone,
    }).startOf("day");
  } else {
    console.error("No end time provided");
    endTime = DateTime.local().endOf("day");
  }

  return {
    startTime,
    endTime,
    allDay,
  };
}
