import { Button as AriaButton } from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog } from "@resource/atlas/dialog";
import { DisplaySelectedTimes } from "client/components/display/times/DisplaySelectedTimes";
import { AvailabilityCalendar } from "client/components/input/legacy-availability-calendar";
import { useTimezone } from "client/timezones/useTimezone";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { fakeAvailabilityAtom, TimeRange, useIsInternal } from "./state";

export function FakeAvailabilitySection() {
  const isInternal = useIsInternal();
  const [open, setOpen] = useState(false);
  const timezone = useTimezone();
  const [fakeAvailability, setFakeAvailability] = useAtom(fakeAvailabilityAtom);
  const onCompleted = useCallback(
    (availabilities: TimeRange[]) => {
      setOpen(false);
      setFakeAvailability(availabilities);
    },
    [setFakeAvailability]
  );

  if (!isInternal) {
    return null;
  }

  return (
    <div className="space-y-3 bg-yellow-50 p-4 rounded-md">
      <div className="w-full flex justify-between">
        <span className="text-h4">Fake availability</span>
        <div className="flex space-x-2">
          <AriaButton
            onClick={() => setFakeAvailability(RESET)}
            className="!text-purple-500 text-body-md"
          >
            Clear
          </AriaButton>
          <AriaButton
            onClick={() => setOpen(true)}
            className="!text-purple-500 text-body-md"
          >
            {fakeAvailability?.length ? "Edit" : "Add"}
          </AriaButton>
        </div>
      </div>
      <DisplaySelectedTimes times={fakeAvailability} timezone={timezone} />
      <AddFakeAvailabilityModal
        open={open}
        setOpen={setOpen}
        timezone={timezone}
        onCompleted={onCompleted}
        defaultAvailabilities={fakeAvailability}
      />
    </div>
  );
}

type AddFakeAvailabilityModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  onCompleted: (availabilities: TimeRange[]) => void;
  timezone: string;
  defaultAvailabilities?: TimeRange[];
};

export function AddFakeAvailabilityModal({
  open,
  setOpen,
  timezone,
  onCompleted,
  defaultAvailabilities,
}: AddFakeAvailabilityModalProps) {
  const [availabilities, setAvailabilities] = useState<TimeRange[]>(
    defaultAvailabilities ?? []
  );

  useEffect(() => {
    setAvailabilities(defaultAvailabilities ?? []);
  }, [defaultAvailabilities]);

  return (
    <Dialog.Root open={open} setOpen={setOpen}>
      <Dialog.Content variant="fullscreen" size="large">
        <Dialog.View
          header={{
            title: "Add fake availability",
          }}
          footer={{
            rightActions: (
              <ButtonGroup>
                <Button
                  variant="purple"
                  onClick={() => onCompleted(availabilities)}
                  disabled={!availabilities.length}
                >
                  Done
                </Button>
              </ButtonGroup>
            ),
          }}
          bodyProps={{
            className: "flex mb-[0.125rem]",
          }}
        >
          <AvailabilityCalendar
            selections={availabilities}
            setSelections={(val) =>
              setAvailabilities(
                _(val)
                  .map((v) => {
                    if (!v.start || !v.end) {
                      return null;
                    }

                    return {
                      id: uuid(),
                      start: v.start,
                      end: v.end,
                    };
                  })
                  .compact()
                  .value()
              )
            }
            defaultEventTitle="Available"
            timezone={timezone}
            onTimezoneChange={() => {}}
            className="flex flex-col w-full"
          />
        </Dialog.View>
      </Dialog.Content>
    </Dialog.Root>
  );
}
