import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasArrowRight } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import { MonthCalendar } from "client/components/calendar/MonthCalendar";
import { useCalendarTimezone } from "client/components/calendar/settings";
import { DisplaySelectedTimes } from "client/components/display/times/DisplaySelectedTimes";
import { BackButton } from "client/components/generic/layout/BackButton";
import ErrorPage from "components/Generic/ErrorPage";
import _ from "lodash";
import { DateTime, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatEntity } from "shared/constants/entities";
import { Event } from "shared/guide-availability/types";
import { v4 } from "uuid";

import { AddNotes, AddNotesProps } from "./AddNotes";
import { AvailabilitySubmission } from "./AvailabilitySubmission";
import { CandidateCurrentTimezone } from "./CandidateCurrentTimezone";
import { CreateOrUpdateAvailabilityState } from "./CreateOrUpdateAvailabilityView";
import { RequestNotes, RequestNotesProps } from "./RequestNotes";
import { SuccessIcon } from "./success-icon";
import { TimeRangesPicker } from "./TimeRangePicker";

type TimeRange = {
  id: string;
  start: DateTime;
  end: DateTime;
  error?: string | null;
};

type CreateOrUpdateAvailabilityViewMobileProps = {
  state: CreateOrUpdateAvailabilityState;
  suggestions?: Event[];
  addNotes?: Omit<AddNotesProps, "notes" | "setNotes">;
  requestNotes?: RequestNotesProps;
  defaultEventTitle?: string;
  close: () => void;
  onSubmit: () => Promise<void>;
  submitLoading?: boolean;
  title?: string;
  submitLabel?: string;
};

type View = "days" | "times" | "review" | "success";

export function CreateOrUpdateAvailabilityViewMobile({
  state,
  title,
  requestNotes,
  addNotes,
  suggestions,
  close,
  onSubmit,
  submitLoading,
  defaultEventTitle = "New event",
  submitLabel,
}: CreateOrUpdateAvailabilityViewMobileProps) {
  const { selections, setSelections, notes, setNotes } = state;
  const timezone = useCalendarTimezone();
  const timeRanges = useMemo((): TimeRange[] => {
    return selections.map((s): TimeRange => {
      const startTime = DateTime.fromISO(s.startTime);
      const endTime = DateTime.fromISO(s.endTime);
      const interval = Interval.fromDateTimes(startTime, endTime);

      return {
        id: s.id,
        start: startTime.setZone(timezone),
        end: endTime.setZone(timezone),
        error: interval.invalidReason,
      };
    });
  }, [selections, timezone]);
  const [view, setView] = useState<View>("days");
  const [selectedDays, setSelectedDays] = useState<DateTime[]>([]);

  useEffect(() => {
    // Update days to match availabilities
    setSelectedDays(
      _(timeRanges)
        .map((a) => {
          return a.start.startOf("day");
        })
        .uniqBy((d) => d.toISODate())
        .value()
    );
  }, [timeRanges]);

  const updateAvailabilitiesForSelectedDays = useCallback(
    (newSelectedDays: DateTime[]) => {
      const newAvailabilities = newSelectedDays.flatMap((day): Event[] => {
        const existingAvailabilityOnDay = selections.filter((a) =>
          DateTime.fromISO(a.startTime).hasSame(day, "day")
        );

        if (existingAvailabilityOnDay.length) {
          return existingAvailabilityOnDay;
        }

        return [
          {
            id: v4(),
            title: defaultEventTitle,
            startTime: day
              .setZone(timezone)
              .set({ hour: 9, minute: 0 })
              .toISO(),
            endTime: day.setZone(timezone).set({ hour: 17, minute: 0 }).toISO(),
          },
        ];
      });
      setSelections(newAvailabilities);
    },
    [setSelections, selections, defaultEventTitle, timezone]
  );

  useEffect(() => {
    setSelections((current) =>
      current.map((s) => ({
        ...s,
        startTime: DateTime.fromISO(s.startTime).setZone(timezone).toISO(),
        endTime: DateTime.fromISO(s.endTime).setZone(timezone).toISO(),
      }))
    );
  }, [setSelections, timezone]);

  const onAddDay = () => {
    setView("days");
  };

  if (view === "days") {
    return (
      <View
        header={{
          title,
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={close}>
                Cancel
              </Button>
              <Tooltip
                content={
                  !selectedDays.length
                    ? "Please select some days to continue"
                    : ""
                }
              >
                <Button
                  variant="primary"
                  disabled={!selectedDays.length}
                  onClick={() => {
                    setView("times");
                  }}
                >
                  Continue
                </Button>
              </Tooltip>
            </ButtonGroup>
          ),
        }}
      >
        <div className="space-y-6">
          <div className="space-y-1">
            <h3 className="text-h3">Select the days you&apos;re available</h3>
            <p className="text-body-md text-subtle">
              Select &quot;continue&quot; to confirm days.
            </p>
          </div>
          {requestNotes && <RequestNotes {...requestNotes} />}
          <CandidateCurrentTimezone />
          {suggestions && suggestions.length > 0 && (
            <div className="space-y-2">
              <p className="text-body-md-heavy text-dark">Suggested times</p>
              <DisplaySelectedTimes
                times={suggestions.map((s) => ({
                  id: s.id,
                  start: s.startTime,
                  end: s.endTime,
                }))}
                timezone={timezone}
              />
            </div>
          )}
          <MonthCalendar
            selectedDays={selectedDays}
            setSelectedDays={updateAvailabilitiesForSelectedDays}
          />
        </div>
      </View>
    );
  }

  if (view === "times") {
    let submitTooltip;
    if (!selections.length) {
      submitTooltip = "Please make some selections to continue";
    } else if (timeRanges.some((a) => !!a.error)) {
      submitTooltip = "Please fix errors to continue";
    }
    return (
      <View
        header={{
          title,
          leftActions: <BackButton onClick={() => setView("days")} />,
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={close}>
                Cancel
              </Button>
              <Tooltip content={submitTooltip} isInstant>
                <Button
                  variant="primary"
                  disabled={!!submitTooltip}
                  onClick={() => {
                    setView("review");
                  }}
                >
                  Continue
                </Button>
              </Tooltip>
            </ButtonGroup>
          ),
        }}
      >
        <div className="space-y-6">
          <div className="space-y-1">
            <h3 className="text-h3">
              Add a few times you&apos;re available each day.
            </h3>
            <p className="text-body-md text-subtle">
              Select &quot;continue&quot; to confirm times.
            </p>
          </div>
          <CandidateCurrentTimezone />
          {suggestions && suggestions.length > 0 && (
            <div className="space-y-2">
              <p className="text-body-md-heavy text-dark">Suggested times</p>
              <DisplaySelectedTimes
                times={suggestions.map((s) => ({
                  id: s.id,
                  start: s.startTime,
                  end: s.endTime,
                }))}
                timezone={timezone}
              />
            </div>
          )}
          <TimeRangesPicker
            availabilities={timeRanges}
            setAvailabilities={(ranges) => {
              setSelections(
                ranges.map((r) => ({
                  id: r.id,
                  title: defaultEventTitle,
                  startTime: r.start.setZone(timezone).toISO(),
                  endTime: r.end.setZone(timezone).toISO(),
                }))
              );
            }}
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link as="button" onClick={onAddDay} className="!text-purple-500">
            + Add another day
          </Link>
        </div>
      </View>
    );
  }

  if (view === "review") {
    return (
      <View
        header={{
          title,
          leftActions: <BackButton onClick={() => setView("times")} />,
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={close}>
                Cancel
              </Button>
              <Tooltip
                content={
                  !selections.length
                    ? "Please make some selections to continue"
                    : undefined
                }
                isInstant
              >
                <Button
                  variant="purple"
                  onClick={async () => {
                    await onSubmit();
                    setView("success");
                  }}
                  disabled={submitLoading || !selections.length}
                  isLoading={submitLoading}
                >
                  {submitLabel || "Submit"}
                </Button>
              </Tooltip>
            </ButtonGroup>
          ),
        }}
      >
        <div className="space-y-6 text-body-md">
          <div className="space-y-2">
            <div className="text-h3">Add a comment</div>
            <div>Select &quot;submit&quot; to confirm availability.</div>
          </div>
          {addNotes && (
            <AddNotes {...addNotes} notes={notes} setNotes={setNotes} />
          )}
          <div className="space-y-2">
            <div className="text-body-md-heavy">Availability</div>
            <AvailabilitySubmission
              availabilitySubmission={{
                notes,
                events: selections,
              }}
              isCurrentSubmission
              onUpdate={() => {
                setView("times");
              }}
              timezone={timezone}
              variant="candidate"
            />
          </div>
        </div>
      </View>
    );
  }

  if (view === "success") {
    return (
      <View header={null}>
        <div className="space-y-6 flex flex-col items-center text-center justify-center h-full">
          <SuccessIcon />
          <div className="space-y-2">
            <h1 className="text-h1 text-dark leading-9 tracking-tight">
              Thanks for sharing your availability!
            </h1>
            <p className="text-body-large text-subtle">
              We&apos;ll be in touch soon to schedule your upcoming interviews
            </p>
          </div>
          <Button className="w-[19rem]" variant="primary" onClick={close}>
            <span className="flex items-center gap-2">
              Go to your {formatEntity("guide")}{" "}
              <Icon content={atlasArrowRight} className="text-white" />
            </span>
          </Button>
        </div>
      </View>
    );
  }
  return <ErrorPage />;
}
