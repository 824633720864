import { guideUpdateVariableKeys } from "modules/lexical/shared/utils/constants";
import { GuideUpdateVariableSet } from "modules/lexical/shared/utils/types";
import { createVariableSets } from "modules/lexical/shared/variables";

const {
  nameSet: guideUpdateNameSet,
  rendererSet: guideUpdateRendererSet,
  specSet: guideUpdateSpecSet,
} = createVariableSets<GuideUpdateVariableSet>(guideUpdateVariableKeys);

export { guideUpdateNameSet, guideUpdateRendererSet, guideUpdateSpecSet };

export type { GuideUpdateVariableSet };
