import { EventPrivacyFormGroup } from "client/components/scheduled-interviews/EventPrivacySelector/EventPrivacyFormGroup";
import { useCallback } from "react";
import { Controller } from "react-hook-form";

import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewPrivacy({
  state: { form },
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const onChangeIsPrivate = useCallback(
    (isPrivate: boolean) =>
      form.setValue("scheduledInterview.isPrivate", isPrivate, {
        shouldDirty: true,
        shouldValidate: true,
      }),
    [form]
  );

  return (
    <Controller
      name="scheduledInterview.isPrivate"
      control={form.control}
      render={({ field }) => (
        <EventPrivacyFormGroup
          isPrivate={field.value}
          onChange={onChangeIsPrivate}
        />
      )}
    />
  );
}
