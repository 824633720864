import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

export interface SkeletonCollectionProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  count: number;
}

export function SkeletonCollection({
  count,
  children,
  ...props
}: Omit<SkeletonCollectionProps, "children"> & {
  children: ReactNode | ((i: number) => ReactNode);
}) {
  return (
    <div {...props}>
      {Array.from(Array(count).keys()).map((i) => (
        <React.Fragment key={i}>
          {typeof children === "function" ? children(i) : children}
        </React.Fragment>
      ))}
    </div>
  );
}
