export function isInterviewWhitelisted({
  eventTitle,
  whitelist,
}: {
  eventTitle: string;
  whitelist: string[];
}) {
  let isWhitelisted = false;

  whitelist.forEach((whitelistedTitle) => {
    if (
      eventTitle
        .toLowerCase()
        .trim()
        .includes(whitelistedTitle.toLowerCase().trim())
    ) {
      isWhitelisted = true;
    }
  });

  return isWhitelisted;
}

export function removeDuplicatesFromWhitelist(whitelist: string[]) {
  return Array.from(new Set(whitelist));
}
