import { MutationHookOptions } from "@apollo/client";
import { UpdateOrCancelReportingReasonsViewData } from "client/components/scheduled-interviews/ReportingReasons/wrappers/UpdateOrCancelReportingReasonsView";
import {
  ScheduledInterviewForCancellation,
  StagedScheduledInterviewForUpsert,
  useUpsertScheduledInterviewGroup,
} from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/__hooks/useUpsertScheduledInterviewGroup";
import { InterviewsPanelReviewFormData } from "client/components/scheduling-review/utils/types";
import { SchedulerStagedScheduledInterviewWithComputedFields } from "client/scheduler/utils/types";
import {
  UpsertScheduledInterviewGroupForSchedulerMutation,
  UpsertScheduledInterviewGroupForSchedulerMutationVariables,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";

import { useInterviewGroupChanges } from "../hooks/interviews";
import { useViewFilter } from "../hooks/misc";
import { useScheduledInterviewGroupSettings } from "../hooks/scheduledInterviewGroupSettings";

function mapUpsertInterviewWithReviewData({
  interview,
  interviewsPanelReviewFormData,
  reportingData,
}: {
  interview: SchedulerStagedScheduledInterviewWithComputedFields;
  interviewsPanelReviewFormData: InterviewsPanelReviewFormData;
  reportingData?: UpdateOrCancelReportingReasonsViewData;
}): StagedScheduledInterviewForUpsert {
  const reviewFormInterviewData = interviewsPanelReviewFormData.interviews.find(
    (i) => i.id === interview.id
  );

  if (!reviewFormInterviewData) {
    // should never happen if we initialize our form correctly
    throw new Error(
      `Could not find review form data for interview with ID ${interview.id}`
    );
  }

  return {
    ...interview,
    reviewFormData: {
      sendNotifications: interviewsPanelReviewFormData.sendNotifications,
      addToGoogleCalendarId:
        interviewsPanelReviewFormData.addToGoogleCalendarId,
      optionalAttendeeUserMemberships:
        interviewsPanelReviewFormData.optionalAttendeeUserMemberships,
      note: interviewsPanelReviewFormData.note,
      ...reviewFormInterviewData,
    },
    reportingData: reportingData?.updateReportingReason,
  };
}

/*
 * TODO: Cancelling interviews should have a single review page that is different from update/create
 * There is a confirmation dialog that collects info outside the scheduler, we should collect that info in the scheduler too
 * Right now we just don't show any form for editing interviews, so all we care about is the panel level sendNotifications setting
 */
function mapCancellationInterviewWithReviewData({
  interview,
  interviewsPanelReviewFormData,
  reportingData,
}: {
  interview: SchedulerStagedScheduledInterviewWithComputedFields;
  interviewsPanelReviewFormData: InterviewsPanelReviewFormData;
  reportingData?: UpdateOrCancelReportingReasonsViewData;
}): ScheduledInterviewForCancellation {
  return {
    ...interview,
    reviewFormData: {
      sendNotifications: interviewsPanelReviewFormData.sendNotifications,
      note: interviewsPanelReviewFormData.note,
    },
    reportingData: reportingData?.cancellationReportingReason,
  };
}

/**
 * This hook is a wrapper around useUpsertScheduledInterviewGroup, specifically tailored for the scheduler.
 * It handles pulling the data out of state and providing it to the mutation.
 */
export function useSchedulerUpsertScheduledInterviewGroup(
  opts?: MutationHookOptions<
    UpsertScheduledInterviewGroupForSchedulerMutation,
    UpsertScheduledInterviewGroupForSchedulerMutationVariables
  >
) {
  const { guideId, schedulingRequestId, scheduledInterviewGroupId } =
    useViewFilter();
  const interviewGroupChanges = useInterviewGroupChanges();
  const scheduledInterviewGroupSettings = useScheduledInterviewGroupSettings();

  const {
    upsertScheduledInterviewGroup: wrappedUpsertScheduledInterviewGroup,
    ...rest
  } = useUpsertScheduledInterviewGroup(opts);

  const upsertScheduledInterviewGroup = useCallback(
    async ({
      interviewsPanelReviewFormData,
      reportingData,
    }: {
      interviewsPanelReviewFormData: InterviewsPanelReviewFormData;
      reportingData?: UpdateOrCancelReportingReasonsViewData;
    }) => {
      const mappedCreateInterviewsWithReviewData =
        interviewGroupChanges.interviewsToCreate.map((interview) =>
          mapUpsertInterviewWithReviewData({
            interview,
            interviewsPanelReviewFormData,
            reportingData,
          })
        );
      const mappedUpdateInterviewsWithReviewData =
        interviewGroupChanges.interviewsToUpdate.map((interview) =>
          mapUpsertInterviewWithReviewData({
            interview,
            interviewsPanelReviewFormData,
            reportingData,
          })
        );

      const mappedDeleteInterviewsWithReviewData =
        interviewGroupChanges.interviewsToDelete.map((interview) =>
          mapCancellationInterviewWithReviewData({
            interview,
            interviewsPanelReviewFormData,
            reportingData,
          })
        );

      return wrappedUpsertScheduledInterviewGroup({
        guideId,
        schedulingRequestId,
        scheduledInterviewGroupId,
        scheduledInterviewGroupSettings:
          scheduledInterviewGroupSettings ?? undefined,
        createScheduledInterviews: mappedCreateInterviewsWithReviewData,
        updateScheduledInterviews: mappedUpdateInterviewsWithReviewData,
        deleteScheduledInterviews: mappedDeleteInterviewsWithReviewData,
      });
    },
    [
      guideId,
      interviewGroupChanges.interviewsToCreate,
      interviewGroupChanges.interviewsToDelete,
      interviewGroupChanges.interviewsToUpdate,
      scheduledInterviewGroupId,
      scheduledInterviewGroupSettings,
      schedulingRequestId,
      wrappedUpsertScheduledInterviewGroup,
    ]
  );

  return {
    upsertScheduledInterviewGroup,
    ...rest,
  };
}
