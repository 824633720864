import type { SerializedRichBlockNode } from "../../../__utils/rich-blocks";
import { CardData, DEFAULT_CARD_DATA } from "../shared/misc";

// config
// ------

export const RICH_BLOCK_NAME = "link-card";

export const DEFAULT_DATA: LinkCardData = DEFAULT_CARD_DATA;

// types
// -----

export type LinkCardName = typeof RICH_BLOCK_NAME;

export type LinkCardData = CardData;

export type SerializedLinkCardNode = SerializedRichBlockNode<
  LinkCardName,
  CardData
>;
