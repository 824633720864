import { useDefaultRecipientsForGuide } from "client/message-composer/__hooks/useDefaultRecipientsForGuide";
import { Recipient } from "components/RecipientsManager";
import {
  GuideForSchedulingTaskAndAvailabilityWorkflowsFragment,
  InterviewRequirementForInterviewRequirementsCardFragment,
} from "generated/graphql-codegen/graphql";
import { uniq, uniqBy } from "lodash";
import { useMemo } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";

import { SchedulingRequestFormState } from "./useSchedulingRequestFormState";

export function useMessagingWorkflowData({
  schedulingRequestFormState,
  guide,
}: {
  schedulingRequestFormState: SchedulingRequestFormState;
  guide: GuideForSchedulingTaskAndAvailabilityWorkflowsFragment;
}) {
  const messagingWorkflowType = useMemo(() => {
    /*
      Select task (or skip because coming from task)
        If reschedule task:
          "Request additional availability"
        If scheduling task:
          If we are replying on an existing thread:
            "Request additional availability"
            Reason being, the only way a thread could exist is if we've already asked for availability OR we've already scheduled, and in
            both cases we can assume intent is to get more availability
        Otherwise:
          "Request availability"
      Create new interviews
        Always "Request availability"
      Reschedule interviews
        Always "Request additional availability"
    */

    if (schedulingRequestFormState.formType === "select-task") {
      const {
        selectTaskState: { selectedSchedulingRequestId },
      } = schedulingRequestFormState;

      const schedulingRequest = guide.schedulingRequests.find(
        (sr) => sr.id === selectedSchedulingRequestId
      );

      if (!schedulingRequest) {
        return PostMessageWorkflow.REQUEST_AVAILABILITY;
      }

      if (schedulingRequest.isReschedule) {
        return PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY;
      }

      const thread = schedulingRequest.rootPost ?? null;

      if (thread) {
        return PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY;
      }

      return PostMessageWorkflow.REQUEST_AVAILABILITY;
    }

    if (schedulingRequestFormState.formType === "scheduling") {
      return PostMessageWorkflow.REQUEST_AVAILABILITY;
    }

    if (schedulingRequestFormState.formType === "rescheduling") {
      return PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY;
    }

    return PostMessageWorkflow.REQUEST_AVAILABILITY;
  }, [guide.schedulingRequests, schedulingRequestFormState]);
  const defaultTemplate = useMemo(() => {
    if (messagingWorkflowType === PostMessageWorkflow.REQUEST_AVAILABILITY) {
      return guide.defaultRequestAvailabilityTemplate;
    }

    return guide.defaultRequestAdditionalAvailabilityTemplate;
  }, [
    guide.defaultRequestAdditionalAvailabilityTemplate,
    guide.defaultRequestAvailabilityTemplate,
    messagingWorkflowType,
  ]);
  const defaultMoveToInterviewPlanItemId = useMemo(() => {
    if (messagingWorkflowType === PostMessageWorkflow.REQUEST_AVAILABILITY) {
      return guide.defaultRequestAvailabilityMoveToInterviewPlanItem?.id;
    }

    return guide.defaultRequestAdditionalAvailabilityMoveToInterviewPlanItem
      ?.id;
  }, [
    guide.defaultRequestAdditionalAvailabilityMoveToInterviewPlanItem?.id,
    guide.defaultRequestAvailabilityMoveToInterviewPlanItem?.id,
    messagingWorkflowType,
  ]);
  const thread = useMemo(() => {
    if (schedulingRequestFormState.formType === "scheduling") {
      return null;
    }
    if (schedulingRequestFormState.formType === "rescheduling") {
      const {
        rescheduleRequestState: { selectedRescheduleInterviewRequirementIds },
      } = schedulingRequestFormState;
      const selectedInterviews = guide.upcomingInterviews.filter(
        (i) =>
          i.interviewRequirementId &&
          selectedRescheduleInterviewRequirementIds.includes(
            i.interviewRequirementId
          )
      );
      const schedulingRequestIds = uniq(
        selectedInterviews.map((i) => i.schedulingRequestId)
      );
      const schedulingRequests = guide.schedulingRequests
        .filter((sr) => schedulingRequestIds.includes(sr.id))
        .sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
      const schedulingRequest = schedulingRequests[0];

      return schedulingRequest?.rootPost ?? null;
    }

    const {
      selectTaskState: { selectedSchedulingRequestId },
    } = schedulingRequestFormState;
    const schedulingRequest = guide.schedulingRequests.find(
      (sr) => sr.id === selectedSchedulingRequestId
    );

    return (
      schedulingRequest?.originalSchedulingRequest?.rootPost ??
      schedulingRequest?.rootPost ??
      null
    );
  }, [
    schedulingRequestFormState,
    guide.schedulingRequests,
    guide.upcomingInterviews,
  ]);
  const allThreads = useMemo(() => {
    if (schedulingRequestFormState.formType === "scheduling") {
      return [];
    }

    if (schedulingRequestFormState.formType === "rescheduling") {
      const {
        rescheduleRequestState: { selectedRescheduleInterviewRequirementIds },
      } = schedulingRequestFormState;
      const selectedInterviews = guide.upcomingInterviews.filter(
        (i) =>
          i.interviewRequirementId &&
          selectedRescheduleInterviewRequirementIds.includes(
            i.interviewRequirementId
          )
      );

      const schedulingRequestIds = uniq(
        selectedInterviews.map((i) => i.schedulingRequestId)
      );

      return uniqBy(
        guide.schedulingRequests
          .filter((sr) => schedulingRequestIds.includes(sr.id))
          .flatMap((sr) => sr.threads),
        "id"
      );
    }

    const {
      selectTaskState: { selectedSchedulingRequestId },
    } = schedulingRequestFormState;
    const schedulingRequest = guide.schedulingRequests.find(
      (sr) => sr.id === selectedSchedulingRequestId
    );

    return schedulingRequest?.threads ?? [];
  }, [
    guide.schedulingRequests,
    guide.upcomingInterviews,
    schedulingRequestFormState,
  ]);
  const taskRequirements = useMemo(():
    | InterviewRequirementForInterviewRequirementsCardFragment[]
    | undefined => {
    if (schedulingRequestFormState.formType === "scheduling") {
      return (
        schedulingRequestFormState.schedulingRequestState
          ?.interviewRequirements ?? []
      );
    }

    if (schedulingRequestFormState.formType === "rescheduling") {
      return undefined;
    }

    const {
      selectTaskState: { selectedSchedulingRequestId },
    } = schedulingRequestFormState;
    const schedulingRequest = guide.schedulingRequests.find(
      (sr) => sr.id === selectedSchedulingRequestId
    );

    return (
      schedulingRequest?.interviewPanelRequirement?.interviewRequirements ?? []
    );
  }, [guide.schedulingRequests, schedulingRequestFormState]);
  const guideDefaultRecipients = useDefaultRecipientsForGuide({ guide });
  const defaultRecipients = useMemo((): Recipient[] => {
    if (
      schedulingRequestFormState.assignee &&
      typeof schedulingRequestFormState.assignee === "object"
    ) {
      return [
        ...guideDefaultRecipients,
        {
          ...schedulingRequestFormState.assignee,
          isCandidate: false,
        },
      ];
    }

    return guideDefaultRecipients;
  }, [guideDefaultRecipients, schedulingRequestFormState]);

  return useMemo(
    () => ({
      messagingWorkflowType,
      defaultTemplate,
      thread,
      allThreads,
      taskRequirements,
      defaultMoveToInterviewPlanItemId,
      defaultRecipients,
    }),
    [
      messagingWorkflowType,
      defaultTemplate,
      thread,
      allThreads,
      taskRequirements,
      defaultMoveToInterviewPlanItemId,
      defaultRecipients,
    ]
  );
}
