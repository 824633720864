import { ComboboxList, MenuStore } from "@ariakit/react";
import { Combobox } from "@resource/atlas/combobox-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasBookClosed,
  atlasColorDot,
  atlasColorDotGroup,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu-v2";
import { HighlightedText } from "client/components/HighlightedText";
import { matchSorter } from "match-sorter";
import React, { startTransition, useMemo, useState } from "react";

import { TagFilterMenuButton } from "./TagFilterMenuButton";

export const INTERVIEWER_POOL_TAG_GROUP_ID = "interviewer-pool";

interface ComboboxWithMenuProps {
  items: Array<{
    id: string;
    label: string;
    isSelected?: boolean;
    onClick?: () => void;
    color?: string;
    items?: {
      id: string;
      label: string;
      isSelected: boolean;
      onClick: () => void;
      color?: string;
    }[];
  }>;
  children: React.ReactNode;
  className?: string;
  menuStore: MenuStore;
}

export function TagMenu({
  items,
  children,
  className,
  menuStore,
}: ComboboxWithMenuProps) {
  const [searchValue, setSearchValue] = useState("");

  const filteredItems = useMemo(
    () =>
      items
        .map((item) => {
          if (item.items) {
            // Check if group name matches
            const groupMatches =
              matchSorter([item], searchValue, {
                keys: ["label"],
              }).length > 0;

            // If group matches, include all items
            if (groupMatches) {
              return {
                ...item,
                matchType: "group" as const,
                items: item.items,
              };
            }

            // Otherwise filter individual items
            const filteredGroupItems = matchSorter(item.items, searchValue, {
              keys: ["label"],
            });

            // Only include groups that have matching items
            if (filteredGroupItems.length === 0) {
              return undefined;
            }

            return {
              ...item,
              matchType: "items" as const,
              items: filteredGroupItems,
            };
          }

          // Filter top-level items
          const filteredItem = matchSorter([item], searchValue, {
            keys: ["label"],
          })[0];

          return filteredItem
            ? { ...filteredItem, matchType: "single" as const }
            : undefined;
        })
        .filter((item): item is NonNullable<typeof item> => item !== undefined),
    [items, searchValue]
  );

  const renderItem = (
    item: (typeof filteredItems)[number],
    position: number
  ) => {
    if (item.items) {
      return (
        <>
          {position !== 0 && <Menu.Separator />}
          <Menu.Group
            key={item.id}
            label={
              <HighlightedText
                text={item.label}
                highlight={item.matchType === "group" ? searchValue : ""}
              />
            }
            className="relative"
            leadingContent={
              <Icon
                content={
                  item.id === INTERVIEWER_POOL_TAG_GROUP_ID
                    ? atlasBookClosed
                    : atlasColorDotGroup
                }
                style={{
                  color: item.color,
                }}
              />
            }
          >
            {item.items.map((subItem) => (
              <Combobox.Item
                key={subItem.id}
                size="compact"
                isSelectable
                isSelected={subItem.isSelected}
                onClick={subItem.onClick}
                leadingContent={
                  <Icon
                    content={atlasColorDot}
                    style={{
                      color: subItem.color,
                    }}
                  />
                }
              >
                <HighlightedText
                  text={subItem.label}
                  highlight={item.matchType === "items" ? searchValue : ""}
                />
              </Combobox.Item>
            ))}
          </Menu.Group>
        </>
      );
    }

    return (
      <Combobox.Item
        key={item.id}
        size="compact"
        isSelectable
        isSelected={item.isSelected}
        onClick={item.onClick}
        leadingContent={
          <Icon
            content={atlasColorDot}
            style={{
              color: item.color,
            }}
          />
        }
      >
        <HighlightedText text={item.label} highlight={searchValue} />
      </Combobox.Item>
    );
  };

  return (
    <Combobox.Root
      value={searchValue}
      setValue={(value) => {
        startTransition(() => {
          setSearchValue(value);
        });
      }}
    >
      <Menu.Root store={menuStore}>
        <TagFilterMenuButton className={className}>
          {children}
        </TagFilterMenuButton>
        <Menu.Menu className="!p-0" fitViewport portal>
          <Combobox.Combobox
            className="p-2 sticky bg-white z-10"
            placeholder="Search..."
            size="compact"
          />
          <ComboboxList className="px-2 pb-2 overflow-y-scroll max-h-[20rem]">
            {filteredItems.length > 0 ? (
              filteredItems.map(renderItem)
            ) : (
              <div className="flex items-center justify-center py-4 text-body-md text-subtle">
                No tags found
              </div>
            )}
          </ComboboxList>
        </Menu.Menu>
      </Menu.Root>
    </Combobox.Root>
  );
}
