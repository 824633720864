import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { ComponentProps, useMemo } from "react";

import { CalendarEvent } from "../../utils/types";
import { EventBackground, EventBackgroundProps } from "./EventBackground";
import { EventDragInteraction } from "./EventDragInteraction";
import { EventRemoveButton } from "./EventRemoveButton";
import { EventTime } from "./EventTime";
import { EventTitle } from "./EventTitle";
import { useEventSize } from "./utils/sizing";
import { useWrapperStyle } from "./utils/styling";
import { useInteractiveEventProps } from "./utils/useInteractiveEventProps";

type EventProps = {
  event: CalendarEvent;
  wrapperProps?: ComponentProps<"li">;
  eventProps?: Omit<EventBackgroundProps, "event">;
  hideEventTime?: boolean;
  /** If we are displaying the event for a calendar, pass that so we can get the proper column */
  calendarId?: string;
  /**
   * If a calendar is in multiple calendar groups, an event must appear in multiple columns
   * In order to choose the right column, we must know which group this event is being rendered in
   */
  calendarGroupIdx?: number;
  /** Optional list of all event ids if this event is part of a group  */
  eventGroupIds?: string[];
  /** Index of this event in the group if it is one */
  eventGroupIdx?: number;
  /** Can pass isHovering directly to manage group hover state separately */
  isHovering?: boolean;
};

/** Note calendar-event class has no styling; it is used as identifier for use in listeners to see if a target is an event */
export const EVENT_TARGET_CLASS_NAME = "calendar-event";

export function Event({
  event: passedEvent,
  eventProps: passedEventProps,
  wrapperProps,
  hideEventTime,
  calendarId,
  calendarGroupIdx,
  eventGroupIds,
  eventGroupIdx,
  isHovering,
}: EventProps) {
  const isInteractive = useMemo(() => {
    return !!passedEvent.onEdit;
  }, [passedEvent]);
  const interactiveEventProps = useInteractiveEventProps({
    event: passedEvent,
    eventProps: passedEventProps,
  });
  const event = useMemo(() => {
    if (isInteractive) {
      return interactiveEventProps.draggingEvent ?? passedEvent;
    }

    return passedEvent;
  }, [interactiveEventProps.draggingEvent, isInteractive, passedEvent]);
  const eventProps = useMemo(() => {
    return isInteractive ? interactiveEventProps.eventProps : passedEventProps;
  }, [isInteractive, interactiveEventProps.eventProps, passedEventProps]);

  const eventSize = useEventSize(event);
  const style = useWrapperStyle({
    event,
    calendarId,
    calendarGroupIdx,
    eventGroupIds,
    eventGroupIdx,
    isHovering,
    isDragging: interactiveEventProps.draggingEvent !== null,
  });

  return (
    <li
      {...wrapperProps}
      data-cy={CypressData.calendar.event}
      className={clsx(
        EVENT_TARGET_CLASS_NAME,
        "relative m-px flex w-full select-none group",
        "transition-width duration-300",
        {
          "pointer-events-none": event.isBackgroundEvent,
          "cursor-pointer": !event.disableDetailsPopover || isInteractive,
          "ring-2 ring-offset-2 ring-purple-500 rounded-md": event.isSelected,
        },
        wrapperProps?.className
      )}
      style={{
        ...style,
        ...wrapperProps?.style,
      }}
    >
      <EventBackground event={event} {...eventProps}>
        <div className={clsx("flex flex-grow flex-col space-y-[2px] w-full")}>
          <EventTitle event={event} eventSize={eventSize} />
          {!hideEventTime && <EventTime event={event} />}
        </div>
        <EventRemoveButton event={event} />
      </EventBackground>
      {isInteractive && (
        <EventDragInteraction
          {...interactiveEventProps.eventDragInteractionProps}
        />
      )}
    </li>
  );
}
