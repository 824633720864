import {
  mapCollaborativeCodingRequirementFragmentToCollaborativeCodingRequirement,
  mapInterviewRequirementToCollaborativeCodingRequirement,
} from "client/components/collaborative-coding/utils/mapping";
import { mapConferenceRoomSettingsFragmentToConferenceRoomSettings } from "client/components/conference-rooms/utils/mapping";
import { mapConferencingRequirementFragmentToConferencingRequirement } from "client/components/conferencing/utils/mapping";
import {
  mapInterviewerRequirementFragmentToSlotSettings,
  mapInterviewersToSlots,
} from "client/components/interviewer-slots/utils/mapping";
import {
  InterviewRequirementForUnscheduledInterviewCardFragment,
  ScheduledInterviewForUnscheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";

import { UnscheduledInterviewForCard } from "./types";

export function mapInterviewRequirementToUnscheduledInterviewForCard(
  interviewRequirement: InterviewRequirementForUnscheduledInterviewCardFragment
): UnscheduledInterviewForCard {
  return {
    id: interviewRequirement.id,
    duration: interviewRequirement.duration,
    title: interviewRequirement.interview.title,
    interviewerSlots: interviewRequirement.interviewerRequirements.map(
      mapInterviewerRequirementFragmentToSlotSettings
    ),
    isPrivate: interviewRequirement.isPrivate,
    atssyncType: interviewRequirement.interview.atssyncInterview?.account.type,
    blacklisted: interviewRequirement.interview.blacklisted,
    isSchedulable: interviewRequirement.schedulableValidation?.isSchedulable,
    unschedulableErrorMessage:
      interviewRequirement.schedulableValidation?.validationErrorMessage,
    order: interviewRequirement.order,
    orderLocked: interviewRequirement.orderLocked,
    conferencingRequirement: interviewRequirement.conferencingRequirement
      ? mapConferencingRequirementFragmentToConferencingRequirement(
          interviewRequirement.conferencingRequirement
        )
      : null,
    conferenceRoomSettings: interviewRequirement.conferenceRoomSettings
      ? mapConferenceRoomSettingsFragmentToConferenceRoomSettings(
          interviewRequirement.conferenceRoomSettings
        )
      : null,
    collaborativeCodingRequirement:
      mapInterviewRequirementToCollaborativeCodingRequirement(
        interviewRequirement
      ),
  };
}

export function mapScheduledInterviewToUnscheduledInterviewForCard(
  scheduledInterview: ScheduledInterviewForUnscheduledInterviewCardFragment
): UnscheduledInterviewForCard {
  return {
    id: scheduledInterview.id,
    duration: DateTime.fromISO(scheduledInterview.endTime).diff(
      DateTime.fromISO(scheduledInterview.startTime),
      "minutes"
    ).minutes,
    order: scheduledInterview.suggestedOrder,
    orderLocked: scheduledInterview.suggestedOrderLocked ?? false,
    title: scheduledInterview.title,
    isPrivate: scheduledInterview.isPrivate,
    atssyncType: scheduledInterview.interview?.atssyncInterview?.account.type,
    blacklisted: scheduledInterview.blacklisted,
    interviewerSlots: mapInterviewersToSlots(scheduledInterview.interviewers),
    conferencingRequirement: scheduledInterview.conferencingRequirement
      ? mapConferencingRequirementFragmentToConferencingRequirement(
          scheduledInterview.conferencingRequirement
        )
      : null,
    conferenceRoomSettings: scheduledInterview.conferenceRoomSlot?.settings
      ? mapConferenceRoomSettingsFragmentToConferenceRoomSettings(
          scheduledInterview.conferenceRoomSlot.settings
        )
      : null,
    collaborativeCodingRequirement:
      scheduledInterview.collaborativeCodingRequirement
        ? mapCollaborativeCodingRequirementFragmentToCollaborativeCodingRequirement(
            scheduledInterview.collaborativeCodingRequirement
          )
        : null,
  };
}
