import { Button } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasClose } from "@resource/atlas/icons";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";

import {
  getBackgroundColorFromConfig,
  getTextColorFromConfig,
} from "../../utils/colors";
import { CalendarEvent } from "../../utils/types";
import { getEventSize } from "./utils/sizing";

/** Button to remove an event, if an `onRemove` handler is provided */
export function EventRemoveButton({ event }: { event: CalendarEvent }) {
  if (!event.onRemove) return null;

  const eventSize = getEventSize(event);
  const isSmall = eventSize === "xs";

  return (
    <StopPropagation className="absolute right-0 top-0">
      <Button
        className={clsx(
          "hidden group-hover:flex flex-shrink-0 z-50 justify-center items-center absolute right-0",
          { "w-5 h-5": !isSmall, "w-4 h-[10px] -mt-px": isSmall }
        )}
        onClick={event.onRemove}
        style={{
          backgroundColor: getBackgroundColorFromConfig(event.colorConfig),
        }}
      >
        <Icon
          content={atlasClose}
          style={{
            color: getTextColorFromConfig(
              event.colorConfig,
              event.responseStatus
            ),
          }}
          className={clsx({
            "h-[10px] w-3": isSmall,
            "h-4 w-4": !isSmall,
          })}
        />
      </Button>
    </StopPropagation>
  );
}
