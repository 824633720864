import clsx from "clsx";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { CalendarEventsViewElement } from "./CalendarEventsViewElement";
import { useCalendarContext } from "./context";
import { useCalendarSettings, useCurrentInterval } from "./settings";
import { getColStartStyling } from "./utils";

export function CurrentTimeIndicator() {
  const { calculateScrollValueForTime } = useCalendarContext();
  const { view, timezone } = useCalendarSettings();
  const interval = useCurrentInterval();
  const now = DateTime.now().setZone(timezone);
  const dayOfWeek = now.weekday;

  const currentTimeScrollValue = useMemo(() => {
    const scrollValue = calculateScrollValueForTime(now);

    return scrollValue;
  }, [calculateScrollValueForTime, now]);

  if (!interval.contains(now)) {
    return null;
  }

  const INDICATOR_HEIGHT = 4;
  const INDICATOR_BALL_HEIGHT = 8;

  return (
    <CalendarEventsViewElement>
      <li
        className={clsx(
          "relative mt-px flex w-full",
          getColStartStyling({
            view,
            dayOfWeek,
          })
        )}
      >
        <div
          className="group w-full absolute z-[11] rounded-lg bg-red-500"
          style={{
            top: currentTimeScrollValue - INDICATOR_HEIGHT / 2,
            height: INDICATOR_HEIGHT,
          }}
        />
        <div
          className="group w-2 absolute z-[11] rounded-full bg-red-500"
          style={{
            height: INDICATOR_BALL_HEIGHT,
            top: currentTimeScrollValue - INDICATOR_BALL_HEIGHT / 2,
            left: -2,
          }}
        />
      </li>
    </CalendarEventsViewElement>
  );
}
