import { Icon } from "@resource/atlas/icon/Icon";
import { atlasHiringPlan } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import TextArea from "@resource/atlas/textfield/TextArea";
import TextField from "@resource/atlas/textfield/TextField";
import { Events } from "analytics/types";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { HIRING_PLANS_TUTORIAL } from "utils/constants/href";

import { InterviewStageContentEditor } from "./InterviewStageContentEditor";
import { InterviewPlanItem } from "./types";

type StageEditorProps = ComponentPropsWithoutRef<"div"> & {
  stage: InterviewPlanItem | null;
  updateStage: (newProps: Partial<InterviewPlanItem>) => unknown;
  removeStage: (stage: InterviewPlanItem) => unknown;
  logEvent: (name: Events, data?: Record<string, unknown>) => void;
};

export function StageEditor({
  stage,
  updateStage,
  removeStage,
  logEvent,
  ...props
}: StageEditorProps) {
  if (!stage) {
    return (
      <div
        {...props}
        className={clsx(
          "flex flex-col gap-y-3 justify-center items-center",
          props.className
        )}
      >
        <Icon className="text-subtle w-10 h-10" content={atlasHiringPlan} />
        <div className="space-y-1 text-center">
          <div className="text-body-md-heavy">No stages</div>
          <div className="text-body-md text-subtle">
            Let candidates know what to expect by adding stages to the interview
            plan. Interview plans must have at least one stage.
          </div>
        </div>
        <Link
          href={HIRING_PLANS_TUTORIAL}
          onClick={() => logEvent("Interview plan tutorial clicked")}
        >
          Learn more about interview plans
        </Link>
      </div>
    );
  }

  return (
    <div {...props} className={clsx("space-y-6", props.className)}>
      <div className="space-y-4">
        <p className="text-h4">Details</p>
        <div className="space-y-2">
          <div className="text-body-md-heavy">Display title</div>
          <TextField
            value={stage?.title}
            aria-label="Display title"
            className="w-full"
            onChange={(v) => updateStage({ title: v })}
            onBlur={() => logEvent("Interview plan stage title changed")}
          />
        </div>
        <div className="space-y-2">
          <div className="space-y-1">
            <div className="text-body-md-heavy">Preview description</div>
            <div className="text-subtle">
              Write a short description of this interview plan stage. This
              description will be visible to candidates when this stage is
              locked.
            </div>
          </div>
          <TextArea
            className="w-full"
            aria-label="Description"
            value={stage.description || ""}
            onChange={(v) => updateStage({ description: v })}
            onBlur={() => logEvent("Interview plan stage description changed")}
          />
        </div>
      </div>
      <div className="space-y-4">
        <div className="space-y-1">
          <div className="text-h4">Interview prep</div>
          <div className="text-subtle">
            As candidates progress and unlock access to this stage, share
            information such as interview prep, compensation details, and
            information about the company and team.
          </div>
        </div>
        <InterviewStageContentEditor
          content={stage.interviewStageContent}
          updateStage={updateStage}
          currentViewingStageId={stage.id}
        />
      </div>
    </div>
  );
}
