/**
 * Settings are used to manage the calendar viewing state,
 * and are stored in jotai to allow for consumers
 * to easily access and listen to changes.
 * Everything should be accessed via hooks so that the internals can easily change.
 */
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { DateTime, Interval } from "luxon";
import { useCallback, useMemo } from "react";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { CalendarView, DayOfWeek } from "../utils/types";

const LOCAL_STORAGE_PREFIX = "__guide__calendar_";

export const getLocalStorageKey = (key: string) =>
  `${LOCAL_STORAGE_PREFIX}${key}`;

function isValidTimezone(tz: string) {
  return DateTime.local().setZone(tz).isValid;
}

const calendarTimezoneInternalAtom = atom(getCurrentIanaTimezone());

export function hydrateCalendarTimezone(timezone?: string) {
  return [
    calendarTimezoneInternalAtom,
    timezone ?? calendarTimezoneInternalAtom.init,
  ] as const;
}

// ensure calendar timezone is never invalid
const calendarTimezoneAtom = atom(
  (get) => {
    const timezone = get(calendarTimezoneInternalAtom);

    if (!isValidTimezone(timezone)) {
      return getCurrentIanaTimezone();
    }

    return timezone;
  },
  (get, set, tz: string) => {
    if (!isValidTimezone(tz)) {
      console.error("Invalid timezone", tz);
      return;
    }

    set(calendarTimezoneInternalAtom, tz);

    const newDay = get(selectedDayInternalAtom).setZone(tz);
    set(selectedDayInternalAtom, newDay);
  }
);

export function useCalendarTimezone() {
  return useAtomValue(calendarTimezoneAtom);
}

export function useSetCalendarTimezone() {
  return useSetAtom(calendarTimezoneAtom);
}

const DEFAULT_INCLUDE_WEEKENDS = false;

const calendarIncludeWeekendsAtom = atomWithStorage(
  getLocalStorageKey("include_weekends"),
  DEFAULT_INCLUDE_WEEKENDS
);

export function hydrateCalendarIncludeWeekends(includeWeekends?: boolean) {
  return [
    calendarIncludeWeekendsAtom,
    includeWeekends ?? DEFAULT_INCLUDE_WEEKENDS,
  ] as const;
}

export function useIncludeWeekends() {
  return useAtom(calendarIncludeWeekendsAtom);
}

const DEFAULT_TWENTY_FOUR_HOUR_FORMAT = false;

const calendarTwentyFourHourFormatAtom = atomWithStorage(
  getLocalStorageKey("twenty_four_hour_format"),
  DEFAULT_TWENTY_FOUR_HOUR_FORMAT
);

export function hydrateCalendarTwentyFourHourFormat(
  twentyFourHourFormat?: boolean
) {
  return [
    calendarTwentyFourHourFormatAtom,
    twentyFourHourFormat ?? DEFAULT_TWENTY_FOUR_HOUR_FORMAT,
  ] as const;
}

export function useCalendarTwentyForHourFormat() {
  return useAtom(calendarTwentyFourHourFormatAtom);
}

const DEFAULT_CALENDAR_VIEW: CalendarView = "week";

export const calendarViewAtom = atomWithStorage<CalendarView>(
  getLocalStorageKey("view_preference"),
  DEFAULT_CALENDAR_VIEW
);

export function hydrateCalendarView(view?: CalendarView) {
  return [calendarViewAtom, view ?? DEFAULT_CALENDAR_VIEW] as const;
}

export function useCalendarView() {
  return useAtomValue(calendarViewAtom);
}

export function useSetCalendarView({
  currentViewingTime,
}: {
  /**
   * Value to switch to whenever switching to day view.
   */
  currentViewingTime?: DateTime;
} = {}) {
  const setCalendarView = useSetAtom(calendarViewAtom);
  const setSelectedDay = useCalendarSetSelectedDay();

  const onChangeCalendarView = useCallback(
    (view: CalendarView) => {
      if (view === "day" && currentViewingTime) {
        setSelectedDay(currentViewingTime);
      }

      setCalendarView(view);
    },
    [currentViewingTime, setCalendarView, setSelectedDay]
  );

  return onChangeCalendarView;
}

const selectedDayInternalAtom = atom(DateTime.local());

export function hydrateSelectedDayInternal(selectedDay?: DateTime) {
  return [
    selectedDayInternalAtom,
    selectedDay ?? selectedDayInternalAtom.init,
  ] as const;
}

// make sure selected day is always visible and in correct timezone
export const calendarSelectedDayAtom = atom(
  (get) => {
    const view = get(calendarViewAtom);
    const selectedDayInternal = get(selectedDayInternalAtom);
    const includeWeekends = get(calendarIncludeWeekendsAtom);

    // Prevent automatically selecting weekend days when weekends are not shown
    if (!includeWeekends && view === "week") {
      if (selectedDayInternal.weekday === DayOfWeek.SATURDAY) {
        return selectedDayInternal.plus({ days: 2 });
      }

      if (selectedDayInternal.weekday === DayOfWeek.SUNDAY) {
        return selectedDayInternal.plus({ days: 1 });
      }
    }

    return selectedDayInternal;
  },
  (get, set, selectedDay: DateTime | ((prev: DateTime) => DateTime)) => {
    const calendarTimezone = get(calendarTimezoneAtom);
    const selectedDayInternal = get(selectedDayInternalAtom);

    if (typeof selectedDay === "function") {
      const newDay = selectedDay(selectedDayInternal).setZone(calendarTimezone);

      set(selectedDayInternalAtom, newDay);
    } else {
      const newDay = selectedDay.setZone(calendarTimezone);

      set(selectedDayInternalAtom, newDay);
    }
  }
);

/**
 * Returns the week interval for the selected day, Sunday through Saturday.
 * Luxon startOf("week") uses ISO weeks, which start on Monday and end on Sunday; we modify that interval here.
 * We could allow users to configure the startOf week by modifying this function along with useEventColumnStart().
 * Luxon supports locale-based weeks, but there are some issues with that; additionally, some users have preferences
 * that don't match their locale; so this should be an explicit setting in Guide.
 * https://moment.github.io/luxon/#/intl?id=locale-based-weeks
 * https://github.com/moment/luxon/issues/373
 * https://github.com/moment/luxon/issues/1570
 * https://github.com/moment/luxon/issues/1573
 */
const calendarSelectedWeekAtom = atom((get) => {
  const selectedDay = get(calendarSelectedDayAtom);
  // If the current day is Sunday, we don't want to go to the previous week!
  if (selectedDay.weekday === 7) {
    return Interval.fromDateTimes(
      selectedDay.startOf("week").plus({ weeks: 1 }).minus({ days: 1 }),
      selectedDay.endOf("week").plus({ weeks: 1 }).minus({ days: 1 })
    );
  }
  return Interval.fromDateTimes(
    selectedDay.startOf("week").minus({ days: 1 }),
    selectedDay.endOf("week").minus({ days: 1 })
  );
});

/** Returns week interval for the selected day, Sunday through Saturday. Use instead of selectedDay.startOf("week") */
export function useCalendarSelectedWeek() {
  return useAtomValue(calendarSelectedWeekAtom);
}

export function useCalendarSelectedDay() {
  return useAtomValue(calendarSelectedDayAtom);
}

export function useCalendarSetSelectedDay() {
  return useSetAtom(calendarSelectedDayAtom);
}

const calendarCurrentIntervalAtom = atom((get) => {
  const selectedDay = get(calendarSelectedDayAtom);
  const view = get(calendarViewAtom);
  const includeWeekends = get(calendarIncludeWeekendsAtom);

  if (view === "week") {
    const selectedWeek = get(calendarSelectedWeekAtom);
    // Sunday through Saturday
    if (includeWeekends) {
      return selectedWeek;
    }
    // Monday through Friday
    return selectedWeek.set({
      start: selectedWeek.start.plus({ days: 1 }),
      end: selectedWeek.end.minus({ days: 1 }),
    });
  }

  // Just the current day
  return Interval.fromDateTimes(
    selectedDay.startOf(view),
    selectedDay.endOf(view)
  );
});

/** The current calendar interval. Includes logic to handle weeks and weekends properly. */
export function useCurrentInterval() {
  return useAtomValue(calendarCurrentIntervalAtom);
}

export type DayViewType = "day" | "calendar" | "calendar_and_grouping";

const DEFAULT_DAY_VIEW_TYPE: DayViewType = "day";

export const DAY_VIEW_TYPE_LOCAL_STORAGE_KEY =
  getLocalStorageKey("day_view_type");

const dayViewTypeAtom = atomWithStorage<DayViewType>(
  DAY_VIEW_TYPE_LOCAL_STORAGE_KEY,
  DEFAULT_DAY_VIEW_TYPE
);

export function hydrateDayViewType(dayViewType?: DayViewType) {
  return [dayViewTypeAtom, dayViewType ?? DEFAULT_DAY_VIEW_TYPE] as const;
}

export function useDayViewType() {
  return useAtomValue(dayViewTypeAtom);
}

export function useSetDayViewType() {
  return useSetAtom(dayViewTypeAtom);
}

export type CalendarSettings = {
  timezone: string;
  includeWeekends: boolean;
  twentyFourHourFormat: boolean;
  selectedDay: DateTime;
  selectedWeek: Interval;
  view: CalendarView;
  dayViewType: DayViewType;
};

export function useCalendarSettings(): CalendarSettings {
  const timezone = useAtomValue(calendarTimezoneAtom);
  const includeWeekends = useAtomValue(calendarIncludeWeekendsAtom);
  const twentyFourHourFormat = useAtomValue(calendarTwentyFourHourFormatAtom);
  const selectedDay = useAtomValue(calendarSelectedDayAtom);
  const selectedWeek = useAtomValue(calendarSelectedWeekAtom);
  const view = useAtomValue(calendarViewAtom);
  const dayViewType = useAtomValue(dayViewTypeAtom);

  return useMemo(
    () => ({
      timezone,
      includeWeekends,
      twentyFourHourFormat,
      selectedDay,
      selectedWeek,
      view,
      dayViewType,
    }),
    [
      timezone,
      includeWeekends,
      twentyFourHourFormat,
      selectedDay,
      selectedWeek,
      view,
      dayViewType,
    ]
  );
}
