import { gql } from "generated/graphql-codegen";
import { useEffect, useMemo } from "react";
import useQuery from "utils/useQuery";

gql(`
  fragment SelfScheduleRequestForSubscription on SelfScheduleRequest {
    id
    requestHref
    ...SelfScheduleRequestForBlock
  }
`);

const SUBSCRIBE_TO_SELF_SCHEDULE_REQUEST_SUBSCRIPTION = gql(`
  subscription SubscribeToSelfScheduleRequestById($id: String!) {
    selfScheduleRequestByIdSubscription(id: $id) {
      ...SelfScheduleRequestForSubscription
    }
  }
`);

const FETCH_SELF_SCHEDULE_REQUEST_QUERY = gql(`
  query FetchSelfScheduleRequestById($id: String!) {
    selfScheduleRequestById(id: $id) {
      id
      ...SelfScheduleRequestForSubscription
    }
  }
`);

export function useSelfScheduleRequestSubscription(id: string) {
  const { data, loading, error, subscribeToMore } = useQuery(
    FETCH_SELF_SCHEDULE_REQUEST_QUERY,
    {
      variables: {
        id,
      },
    }
  );

  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIBE_TO_SELF_SCHEDULE_REQUEST_SUBSCRIPTION,
      variables: {
        id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return {
          selfScheduleRequestById:
            subscriptionData.data.selfScheduleRequestByIdSubscription,
        };
      },
    });
  }, [id, subscribeToMore]);

  return useMemo(
    () => ({
      selfScheduleRequest: data?.selfScheduleRequestById,
      loading,
      error,
    }),
    [data?.selfScheduleRequestById, error, loading]
  );
}
