export const enum EmailEventType {
  PROCESSED = "processed",
  BOUNCED = "bounce",
  DROPPED = "dropped",
  DELIVERED = "delivered",
  OPENED = "open",
  CLICKED = "click",
}

export enum EmailLinkTrackId {
  ABOUT = "signature-about",
  INTERVIEWS = "signature-interviews",
  TEAM = "signature-team",
  OTHER = "other",
}
