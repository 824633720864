import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPerson, atlasSparklesAlt3 } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Popover } from "@resource/atlas/popover";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { HistoricalScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/HistoricalScheduledInterviewCard";
import { ActivityForFeedFragment } from "generated/graphql-codegen/graphql";
import { ReactNode, useCallback } from "react";
import { HistoricalScheduledInterview } from "shared/historical-data/scheduled-interviews/types";

import { ActivityTimestamp } from "../ActivityTimestamp";
import { BaseProps, FeedContext } from "./types";

function calculateLinkToShow({
  guidePostId,
  schedulingRequestId,
  scheduledInterviewId,
  context,
}: {
  guidePostId?: string | null;
  schedulingRequestId?: string | null;
  scheduledInterviewId?: string | null;
  context: FeedContext;
}) {
  if (guidePostId) {
    return "GuidePost";
  }

  if (scheduledInterviewId) {
    return null;
  }

  if (context !== "SchedulingRequest" && schedulingRequestId) {
    return "SchedulingRequest";
  }

  return null;
}

export function ActorDisplayContent({
  actor,
  actionText,
  guidePostId,
  schedulingRequestId,
  scheduledInterviewId,
  context,
  guideUrl,
  candidateName,
  isCandidate,
  eventTime,
  automatedActionId,
}: {
  actor: ActivityForFeedFragment["actor"];
  actionText: string | ReactNode;
  guidePostId?: string | null;
  schedulingRequestId?: string | null;
  scheduledInterviewId?: string | null;
  isCandidate?: boolean;
  eventTime: string;
  automatedActionId: string | null;
} & BaseProps) {
  const actorName = actor?.name ?? "Someone";
  const globalNavigator = useGlobalNavigator();

  const onViewSchedulingRequest = useCallback(() => {
    if (schedulingRequestId) {
      globalNavigator?.navigate("schedulingTaskDetails", {
        schedulingRequestId,
      });
    }
  }, [globalNavigator, schedulingRequestId]);

  const linkToShow = calculateLinkToShow({
    guidePostId,
    schedulingRequestId,
    scheduledInterviewId,
    context,
  });

  return (
    <span className="flex flex-row justify-between w-full">
      <span>
        <span className="text-body-md-heavy text-dark">
          {isCandidate ? candidateName : actorName}
        </span>{" "}
        {actionText}
        {automatedActionId && (
          <>
            {" "}
            <Icon
              content={atlasSparklesAlt3}
              className="inline text-purple-500"
            />
          </>
        )}
        <ActivityTimestamp eventTime={eventTime} />
      </span>
      {linkToShow === "SchedulingRequest" && globalNavigator && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          as="button"
          onClick={onViewSchedulingRequest}
          className="!text-subtle"
        >
          View
        </Link>
      )}
      {linkToShow === "GuidePost" && (
        <Link
          href={`${guideUrl}/message/${guidePostId}`}
          className="!text-subtle"
          target="_blank"
        >
          View
        </Link>
      )}
    </span>
  );
}

export function ActorDisplayContentWithScheduledInterviews({
  actor,
  actionText,
  candidateName,
  isCandidate,
  eventTime,
  historicalScheduledInterviews,
}: {
  actor: ActivityForFeedFragment["actor"];
  actionText: string | ReactNode;
  isCandidate?: boolean;
  eventTime: string;
  historicalScheduledInterviews?: HistoricalScheduledInterview[];
} & BaseProps) {
  const actorName = actor?.name ?? "Someone";

  return (
    <span className="flex flex-row justify-between w-full">
      <span className="grow">
        <span className="text-body-md-heavy text-dark">
          {isCandidate ? candidateName : actorName}
        </span>{" "}
        {actionText}
        <ActivityTimestamp eventTime={eventTime} />
      </span>
      {historicalScheduledInterviews?.length ? (
        <Popover.Root placement="top-end">
          <Popover.Trigger>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link as="button" className="text-body-md !text-subtle">
              View
            </Link>
          </Popover.Trigger>
          <Popover.Content>
            <div className="space-y-2">
              {historicalScheduledInterviews?.map(
                (historicalScheduledInterview) => {
                  return historicalScheduledInterview ? (
                    <HistoricalScheduledInterviewCard
                      key={historicalScheduledInterview.id}
                      historicalScheduledInterview={
                        historicalScheduledInterview
                      }
                    />
                  ) : null;
                }
              )}
            </div>
          </Popover.Content>
        </Popover.Root>
      ) : null}
    </span>
  );
}

export function ActorDisplayAvatar({
  actor,
  candidateName,
}: {
  candidateName?: string | null;
  actor: {
    name: string;
    imageUrl?: string | null;
  } | null;
}) {
  if (!actor) {
    if (candidateName) {
      return <Avatar size="small" name={candidateName} />;
    }

    return <Icon content={atlasPerson} className="w-6 h-6" />;
  }

  return <Avatar size="small" name={actor.name} image={actor.imageUrl} />;
}
