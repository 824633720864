import { PanelAlgorithmSettings } from "generated/graphql-codegen/graphql";

export function mapAlgorithmSettingsFragmentToRunFindTimesInput(
  algorithmSettingsFragment: PanelAlgorithmSettings
) {
  return {
    splitAcrossDays: algorithmSettingsFragment?.numberOfDays
      ? {
          min: algorithmSettingsFragment.numberOfDays.min,
          max: algorithmSettingsFragment.numberOfDays.max,
        }
      : undefined,
    limitInterviewsPerDay: algorithmSettingsFragment?.interviewsPerDayLimit
      ? {
          min: algorithmSettingsFragment.interviewsPerDayLimit.min,
          max: algorithmSettingsFragment.interviewsPerDayLimit.max,
        }
      : undefined,
  };
}
