import clsx from "clsx";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export interface SkeletonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /** @default: light */
  variant?: "light" | "dark";
  /** @default: rect */
  type?: "text" | "circle" | "rect" | "button";
}

export function Skeleton({
  className,
  variant = "light",
  type = "rect",
  ...props
}: SkeletonProps & {
  children?: React.ReactNode;
}) {
  return (
    <div
      {...props}
      className={clsx(
        "animate-pulse",
        {
          "rounded-md": type === "rect",
          "rounded-3xl": type === "text",
          "rounded-full": type === "circle",
          "rounded-[40px]": type === "button",
        },
        {
          "bg-light-gray-200": variant === "light",
          "bg-light-gray-500": variant === "dark",
        },
        className
      )}
    />
  );
}
