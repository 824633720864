import { Button as AriaButton } from "@ariakit/react";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import clsx from "clsx";
import { DateTime } from "luxon";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";

function TimeSelectWrapper({
  children,
  selectedDay,
}: {
  children: React.ReactNode;
  selectedDay: DateTime;
}) {
  return (
    <div className="flex flex-col space-y-2 text-body-md h-full max-h-full w-full">
      <div className="text-body-md-heavy h-8 flex items-center">
        {selectedDay.toFormat("cccc, LLLL d")}
      </div>
      <div className="overflow-y-scroll gap-2 flex flex-row flex-wrap pb-6">
        {children}
      </div>
    </div>
  );
}

export function SelfScheduleTimeSelect({
  state,
}: {
  state: SelfScheduleSubmissionState;
}) {
  const { visibleSlots, selectedDay, selectedSlot, setSelectedSlot } = state;

  if (!selectedDay) {
    return null;
  }

  if (!visibleSlots.length) {
    return (
      <TimeSelectWrapper selectedDay={selectedDay}>
        <EmptyStateWrapper className={clsx("w-full")}>
          No available slots
        </EmptyStateWrapper>
      </TimeSelectWrapper>
    );
  }

  return (
    <TimeSelectWrapper selectedDay={selectedDay}>
      {visibleSlots.map((slot) => {
        const isSelected = selectedSlot?.id === slot.id;
        return (
          <AriaButton
            key={slot.startTime.toISO()}
            className={clsx(
              "flex justify-center items-center w-full rounded-md border border-purple-500 text-purple-500 py-2.5 h-10 transition-colors",
              {
                "bg-purple-500 text-white": isSelected,
                "hover:bg-purple-50": !isSelected,
              }
            )}
            onClick={() => setSelectedSlot(slot)}
          >
            {slot.startTime.toFormat("t").toLowerCase().replace(" ", "")}
          </AriaButton>
        );
      })}
    </TimeSelectWrapper>
  );
}
