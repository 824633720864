import { Button } from "@resource/atlas/button/Button";
import { Dialog } from "@resource/atlas/dialog";
import { AtlasDialogState } from "@resource/atlas/dialog/types";
import { useSchedulingTaskAndAvailabilityWorkflowsNavigation } from "client/app/(scheduling requests)/hooks/useSchedulingTaskAndAvailabilityWorkflowsNavigation";
import { SchedulingTaskAndAvailabilityWorkflowsDialog } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import { gql } from "generated/graphql-codegen";
import { ReopenTaskDialogSchedulingRequestFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

gql(`fragment ReopenTaskDialogSchedulingRequest on SchedulingRequest {
  id
  href
  archivedAt
  isReschedule
  guide {
    id
    publicUrl
  }
  scheduledInterviews {
    id
    isCancelled
    interviewRequirementId
    endTime
  }
}
`);

type ReopenTaskDialogProps = {
  state: AtlasDialogState;
  onConfirm: () => void;
  schedulingRequest: ReopenTaskDialogSchedulingRequestFragment;
};

export function ReopenTaskDialog({
  schedulingRequest,
  state,
  onConfirm,
}: ReopenTaskDialogProps) {
  const reschedulableInterviews = useMemo(() => {
    return schedulingRequest.scheduledInterviews
      .filter(({ isCancelled, endTime }) => {
        const currentTime = new Date();
        return !isCancelled && new Date(endTime) > currentTime;
      })
      .map((i) => i.interviewRequirementId)
      .filter(filterOutNullsAndUndefined);
  }, [schedulingRequest]);

  const canReschedule = reschedulableInterviews.length > 0;

  const { onClick: onReschedule, dialogProps } =
    useSchedulingTaskAndAvailabilityWorkflowsNavigation({
      params: {
        defaultSchedulingRequestFormType: "rescheduling",
        guideId: schedulingRequest.guide.id,
        defaultSelectedRescheduleIds: reschedulableInterviews,
        skipRequestingAvailability: true,
      },
      analytics: {
        component: "SchedulingTaskCompletedWithInterviewsBanner",
      },
    });

  return (
    <>
      <Dialog.Root state={state}>
        <Dialog.Content
          size="small"
          footer={{
            rightActions: (
              <>
                <Button variant="default" isGhost onClick={onReschedule}>
                  Create new {canReschedule ? "reschedule" : "scheduling"} task
                </Button>
                <Button variant="primary" isGhost={false} onClick={onConfirm}>
                  Re-open task
                </Button>
              </>
            ),
          }}
        >
          <div className="space-y-3">
            <p className="text-body-md inline-block">
              This task has been completed. If you are rescheduling interviews
              it is recommended to create a new scheduling task.
            </p>
            <p className="text-body-md-heavy">
              {" "}
              Are you sure you want to re-open this task?
            </p>
          </div>
        </Dialog.Content>
      </Dialog.Root>
      <SchedulingTaskAndAvailabilityWorkflowsDialog {...dialogProps} />
    </>
  );
}
