/* eslint-disable react/no-unused-prop-types */
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  atlasCalendarEvents,
  atlasEllipsisHorizontal,
  atlasRefreshCcw,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useMenuState } from "@resource/atlas/menu/use-menu-state";
import { useAuthContext } from "auth/context";
import { InterviewCalendarDisplay } from "client/app/(account settings)/company/__components/InterviewCalendarDisplay";
import { EditConferencingGenerationSettings } from "client/components/conferencing/display/EditConferencingGenerationSettings";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { InterviewTitleEditor } from "client/components/interviews/InterviewTitleEditor";
import { EventPrivacyFormGroup } from "client/components/scheduled-interviews/EventPrivacySelector/EventPrivacyFormGroup";
import { EditableDuration } from "client/components/time/EditableDuration";
import { SelectInterviewerMenu } from "client/self-schedule/components/SelectInterviewerMenu";
import { useEditProfileMenuItem } from "components/EditPersonalProfile/__hooks/useEditProfileMenuItem";
import { useUpdatePreferencesOnSubmit } from "components/EditPreferences/__hooks/useUpdatePreferencesOnSubmit";
import { TimezoneSelectorV2 } from "components/TimezoneSelection/TimezoneSelectorV2";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";

import { EditSelfScheduleInterviewSettingsState } from "./__hooks/useEditSelfScheduleInterviewSettingsState";

export type EditSelfScheduleInterviewSettingsFormProps = {
  state: EditSelfScheduleInterviewSettingsState;
  titleSubtext?: JSX.Element;
};

export function EditSelfScheduleInterviewSettingsForm(
  props: EditSelfScheduleInterviewSettingsFormProps
) {
  const { state } = props;

  return (
    <div className="space-y-4">
      <EditTitleAndDuration {...props} />
      <EditInterviewers {...props} />
      <EditConferencingGenerationSettings
        conferencingGenerationSettings={state.locationSettings}
        onChangeConferencingGenerationSettings={state.onChangeLocationSettings}
        interviewerUserMembershipIds={state.interviewerUserMembershipIds}
      />
      <EditAddToCalendar {...props} />
      <EditEventPrivacy {...props} />
    </div>
  );
}

function EditEventPrivacy({
  state,
}: EditSelfScheduleInterviewSettingsFormProps) {
  const { form, onChangeEventPrivacy } = state;
  const isPrivate = form.watch("isPrivate");

  return (
    <EventPrivacyFormGroup
      isPrivate={isPrivate}
      onChange={onChangeEventPrivacy}
    />
  );
}

function EditTitleAndDuration({
  state,
  titleSubtext,
}: EditSelfScheduleInterviewSettingsFormProps) {
  const { onChangeTitle, onChangeDuration, form, titleContentEditorProps } =
    state;
  const duration = form.watch("duration");

  return (
    <div className="space-y-2">
      <div className="flex flex-row space-x-2">
        <div className="flex flex-col space-y-2 grow">
          <FormGroup label="Title" subText={titleSubtext}>
            <InterviewTitleEditor
              {...form.register("title")}
              {...titleContentEditorProps}
              className="!py-2"
              valueSet={{}}
              initialState={form.getValues().title}
              onChange={(editorState) => {
                onChangeTitle(JSON.stringify(editorState.toJSON()));
              }}
            />
          </FormGroup>
        </div>
      </div>
      <div className="flex flex-col space-y-2 flex-shrink-0">
        <FormGroup label="Duration">
          <EditableDuration
            className="!py-2"
            currentDuration={duration}
            onSelectDuration={onChangeDuration}
          />
        </FormGroup>
      </div>
    </div>
  );
}

// TODO: Extract and share this component with scheduler pieces
function EditInterviewers({
  state,
}: EditSelfScheduleInterviewSettingsFormProps) {
  const { interviewer, onChangeInterviewer } = state;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuState = useMenuState();
  const swapInterviewerMenuState = useMenuState();
  const editProfileMenu = useEditProfileMenuItem({
    userMembershipId: interviewer.id,
  });
  const [updatePreferences] = useUpdatePreferencesOnSubmit({
    userMembershipId: interviewer.id,
    onCompleted: (data) => {
      onChangeInterviewer({
        ...interviewer,
        user: {
          ...interviewer.user,
          timezone:
            data.updateUserMembershipSchedulingPreferences?.userMembership?.user
              ?.timezone,
        },
      });
    },
  });

  const menuItems = useMenuItems(
    (i) => [
      editProfileMenu.createItem(i),
      i.item({
        id: "swap",
        children: "Select different interviewer",
        onClick: () => swapInterviewerMenuState.show(),
      }),
    ],
    [editProfileMenu, swapInterviewerMenuState]
  );

  const onClickTimezone = useCallback(
    (tz: string) => {
      updatePreferences({
        timezone: tz,
      });
    },
    [updatePreferences]
  );
  return (
    <div className="space-y-2">
      <FormGroup
        label="Interviewer"
        errorMessage={
          !interviewer.user.timezone
            ? "Please select a timezone for the interviewer."
            : undefined
        }
      >
        <div className="flex gap-2 rounded-md items-center group shadow-1 px-3 py-[.625rem]">
          <div className="flex gap-2 items-center truncate grow">
            <Avatar
              size="medium"
              image={interviewer.imageUrl}
              name={interviewer.name}
            />
            <div className="flex-grow">
              <div className="flex flex-col">
                <span className="space-x-1 text-body-md">
                  <span className="shrink truncate" title={interviewer.name}>
                    {interviewer.name}
                  </span>
                  <span>
                    {interviewer.user.timezone
                      ? `(${DateTime.now()
                          .setZone(interviewer.user.timezone)
                          .toFormat("ZZZZ")}) `
                      : null}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <>
              <div>
                <Menu.Root state={menuState}>
                  <ButtonGroup>
                    <Button
                      isGhost
                      size="small"
                      icon={atlasRefreshCcw}
                      isActive={isMenuOpen}
                      onClick={() => {
                        if (isMenuOpen) {
                          swapInterviewerMenuState.hide();
                        } else {
                          swapInterviewerMenuState.show();
                        }
                      }}
                    />
                    <Menu.Trigger>
                      <Button
                        isGhost
                        size="small"
                        icon={atlasEllipsisHorizontal}
                      />
                    </Menu.Trigger>
                  </ButtonGroup>
                  <Menu.Content items={menuItems} />
                </Menu.Root>
              </div>
              {editProfileMenu.render()}
            </>
          </div>
        </div>
        {!interviewer.user.timezone && (
          <div className="mt-2 flex">
            <TimezoneSelectorV2
              onClick={onClickTimezone}
              value={interviewer?.user.timezone ?? undefined}
              triggerProps={{
                className: "w-full",
              }}
              rootProps={{
                placement: "bottom-start",
              }}
              contentProps={{
                className: "w-full",
                sameWidth: true,
                portal: true,
              }}
            />
          </div>
        )}
        {/** Hack to make the menu aligned with the swap button */}
        <div className="w-full h-0 flex justify-end">
          <SelectInterviewerMenu
            menuState={swapInterviewerMenuState}
            setIsMenuOpen={setIsMenuOpen}
            onSelect={(newUserMembership) => {
              swapInterviewerMenuState.hide();
              onChangeInterviewer(newUserMembership);
            }}
          >
            <div className="w-[75%] h-0 -mt-4" />
          </SelectInterviewerMenu>
        </div>
      </FormGroup>
    </div>
  );
}

function EditAddToCalendar({
  state,
}: EditSelfScheduleInterviewSettingsFormProps) {
  const { user } = useAuthContext();
  const userMembershipId = useMemo(
    () => user?.currentUserMembership?.id ?? null,
    [user?.currentUserMembership?.id]
  );
  const selectedCalendarId =
    state.form.watch("addToCalendarId") ?? user?.primaryEmail;
  const onChange = useCallback(
    (newCalendarId: string) => {
      state.form.setValue("addToCalendarId", newCalendarId, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [state.form]
  );

  if (!userMembershipId) {
    return null;
  }

  return (
    <FormGroup label="Add to calendar">
      <InterviewCalendarDisplay
        userMembershipId={userMembershipId}
        selectedInterviewCalendarId={selectedCalendarId}
        setInterviewCalendar={onChange}
        triggerProps={{
          icon: atlasCalendarEvents,
          iconClassName: "text-subtle",
        }}
      />
    </FormGroup>
  );
}
