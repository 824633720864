import { gql } from "generated/graphql-codegen";
import { AvailabilityRequestForCreateAvailabilitySubmissionFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import {
  GuideAvailabilitySubmissionData,
  RICH_BLOCK_NAME as GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME,
} from "shared/guide-availability/rich-blocks/guide-availability-submission";
import useMutation from "utils/useMutation";

import { CreateOrUpdateAvailabilityData } from "../components/CreateOrUpdateAvailabilityView";

const CREATE_GUIDE_POST = gql(`
  mutation CreateGuidePostForAvailabilitySubmission($input: CreateGuidePostV2Input!) {
    createGuidePostV2(input: $input) {
      success
      message
      code
      guidePost {
        id
        title
        data
        parent {
          id
          replies {
            id
          }
        }
        guide {
          id
          latestPost {
            id
          }
          availabilityRequests {
            ...AvailabilityRequestForViewAvailabilityRequest
          }
        }
      }
    }
  }
`);

gql(`
  fragment AvailabilityRequestForCreateAvailabilitySubmission on GuideAvailabilityRequest {
    id
    guidePostId
    guidePost {
      id
      parent {
        id
      }
    }
    guideId
  }
`);

export function useCreateAvailabilitySubmission() {
  const [createPost] = useMutation(CREATE_GUIDE_POST);

  return useCallback(
    ({
      state,
      availabilityRequest,
    }: {
      state: CreateOrUpdateAvailabilityData;
      availabilityRequest: AvailabilityRequestForCreateAvailabilitySubmissionFragment;
    }) => {
      const { guidePost } = availabilityRequest;

      if (!guidePost) {
        // TODO: If for some reason the availability request wasn't tied to a post, we should look up the correct thread
        throw new Error("Availability request must be tied to a post");
      }

      const threadId = guidePost.parent ? guidePost.parent.id : guidePost.id;

      return createPost({
        variables: {
          input: {
            guideId: availabilityRequest.guideId,
            post: {
              threadId,
              content: getSubmissionContent({
                availabilityRequestId: availabilityRequest.id,
                events: state.selections,
                notes: state.notes,
                schedulingPreference: state.schedulingPreference ?? "FLEXIBLE",
              }),
              title: "Availability Submission",
            },
          },
        },
      });
    },
    [createPost]
  );
}

const getSubmissionContent = (data: GuideAvailabilitySubmissionData) => {
  return {
    root: {
      type: "root",
      format: "",
      indent: 0,
      version: 1,
      children: [
        {
          data,
          type: GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME,
          format: "",
          version: 1,
        },
      ],
      direction: "ltr",
    },
  };
};
