import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import { Filter } from "client/components/generic/table/Filter";
import { SchedulingRequestStatusFilter } from "generated/graphql-codegen/graphql";

type SchedulingTaskStatusFilterSelectProps = {
  value: SchedulingRequestStatusFilter | null;
  onChange: (value: SchedulingRequestStatusFilter | null) => void;
  size?: AtlasSelectTriggerProps["size"];
};

export const mapStatusFilterToDisplay = {
  [SchedulingRequestStatusFilter.OPEN]: "Open",
  [SchedulingRequestStatusFilter.ARCHIVED]: "Archived",
  [SchedulingRequestStatusFilter.COMPLETED]: "Completed",
};

const OPTIONS = [
  {
    value: SchedulingRequestStatusFilter.OPEN,
    label: "Open",
  },
  {
    value: SchedulingRequestStatusFilter.COMPLETED,
    label: "Completed",
  },
  {
    value: SchedulingRequestStatusFilter.ARCHIVED,
    label: "Archived",
  },
];

export function SchedulingTaskStatusFilterSelect({
  value,
  onChange,
  size,
}: SchedulingTaskStatusFilterSelectProps) {
  return (
    <Filter
      label="Status"
      value={value}
      onChange={onChange}
      values={OPTIONS}
      size={size}
      allowAll={false}
    />
  );
}
