import { createNodeRenderer } from "@resource/atlas/content-editor/renderer";
import { Dialog, useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { BlockError } from "client/components/generic/errors/BlockError";
import { AvailabilitySubmission } from "client/guide-availability/components/AvailabilitySubmission";
import { CreateOrUpdateAvailabilitySubmissionContent } from "client/guide-availability/components/CreateOrUpdateAvailabilitySubmissionDialog";
import { CreateOrUpdateAvailabilityData } from "client/guide-availability/components/CreateOrUpdateAvailabilityView";
import { useCreateAvailabilitySubmission } from "client/guide-availability/hooks/useCreateAvailabilitySubmission";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { gql } from "generated/graphql-codegen";
import { useCallback, useRef } from "react";
import { SerializedGuideAvailabilitySubmissionNode } from "shared/guide-availability/rich-blocks/guide-availability-submission";
import useQuery from "utils/useQuery";

// node renderer
// -------------

const AVAILABILITY_SUBMISSION_FOR_NODE_RENDERER = gql(`
  query AvailabilitySubmissionForNodeRenderer($availabilitySubmissionId: String!) {
    guideAvailabilitySubmissionById(id: $availabilitySubmissionId) {
      availabilityRequest {
        id
        ...AvailabilityRequestForCreateAvailabilitySubmission
      }
      guide {
        id
        currentAvailabilityId
      }
      ...GuideAvailabilitySubmissionForDisplay

    }
  }
`);

function ViewAvailabilitySubmissionNodeRenderer({
  availabilitySubmissionId,
}: {
  availabilitySubmissionId: string;
}) {
  const { data, loading } = useQuery(
    AVAILABILITY_SUBMISSION_FOR_NODE_RENDERER,
    {
      variables: {
        availabilitySubmissionId,
      },
    }
  );
  const { isOrgUser } = useGuideAuthContext();
  const isCandidate = !isOrgUser;
  const availabilitySubmission = data?.guideAvailabilitySubmissionById;
  const availabilityRequest = availabilitySubmission?.availabilityRequest;
  const updateAvailabilitySubmissionDialogStore = useDialogStore();
  const hasSubmittedRef = useRef(false);
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store: updateAvailabilitySubmissionDialogStore,
    hasSubmittedRef,
  });
  const createNewAvailabilitySubmission = useCreateAvailabilitySubmission();

  const onUpdate = useCallback(() => {
    if (availabilitySubmission) {
      updateAvailabilitySubmissionDialogStore.show();
    }
  }, [availabilitySubmission, updateAvailabilitySubmissionDialogStore]);
  const onSubmit = useCallback(
    async (
      state: CreateOrUpdateAvailabilityData,
      { closeOnSubmit }: { closeOnSubmit?: boolean }
    ) => {
      if (availabilitySubmissionId && availabilityRequest) {
        await createNewAvailabilitySubmission({
          state,
          availabilityRequest,
        });
        hasSubmittedRef.current = true;
      }

      if (closeOnSubmit) {
        updateAvailabilitySubmissionDialogStore.hide();
      }
    },
    [
      availabilityRequest,
      availabilitySubmissionId,
      createNewAvailabilitySubmission,
      updateAvailabilitySubmissionDialogStore,
    ]
  );
  const onCancel = useCallback(() => {
    updateAvailabilitySubmissionDialogStore.hide();
  }, [updateAvailabilitySubmissionDialogStore]);

  return (
    <>
      <AvailabilitySubmission
        loading={loading}
        error={!loading && !availabilitySubmission}
        variant={isCandidate ? "candidate" : "company"}
        availabilitySubmission={availabilitySubmission ?? undefined}
        isCurrentSubmission={
          !!availabilitySubmission &&
          availabilitySubmission?.id ===
            availabilitySubmission?.guide.currentAvailabilityId
        }
        onUpdate={onUpdate}
      />
      {availabilitySubmission && (
        <Dialog
          {...dialogProps}
          store={updateAvailabilitySubmissionDialogStore}
          variant="fullscreen"
        >
          <CreateOrUpdateAvailabilitySubmissionContent
            availabilityRequestId={
              availabilitySubmission.availabilityRequest?.id
            }
            availabilitySubmissionId={availabilitySubmission.id}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Dialog>
      )}
      <WarningDialog />
    </>
  );
}

export const renderGuideAvailabilitySubmissionNode =
  createNodeRenderer<SerializedGuideAvailabilitySubmissionNode>(({ data }) => {
    if (!("availabilitySubmissionId" in data)) {
      // if node rendering, we should have an availability submission now?
      return <BlockError label="Can't render availability submission" />;
    }

    return <ViewAvailabilitySubmissionNodeRenderer {...data} />;
  });
