import React from "react";

export const serviceZoomPast = {
  data: (
    <>
      <rect width="18" height="18" x="1" y="1" fill="#7a8994" rx="4" />
      <path
        fill="#fff"
        d="M10 7H5v4a2 2 0 0 0 2 2h5V9a2 2 0 0 0-2-2Zm5 0v6l-2-1.5v-3L15 7Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </>
  ),
  size: 20,
};

// 7a8994
export const serviceGoogleMeetPast = {
  data: (
    <>
      <rect width="18" height="18" x="1" y="1" fill="#fff" rx="4" />
      <path
        fill="#7a8994"
        d="m10.92 10 1.365 1.557 1.835 1.171.32-2.718-.32-2.657-1.87 1.028L10.92 10Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        fill="#7a8994"
        d="M3 12.476v2.316c0 .53.43.958.96.958h2.32L6.76 14l-.48-1.524-1.592-.48-1.688.48Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        fill="#7a8994"
        d="M6.28 4.25 3 7.524l1.688.48 1.592-.48.472-1.503L6.28 4.25Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        fill="#7a8994"
        d="M3 12.476h3.28V7.524H3v4.952Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        fill="#7a8994"
        d="M16.215 5.636 14.12 7.353v5.375l2.104 1.723a.48.48 0 0 0 .776-.378V6.007a.48.48 0 0 0-.785-.37Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        fill="#7a8994"
        d="M10.92 10v2.476H6.28v3.274h6.88c.53 0 .96-.429.96-.958v-2.064L10.92 10Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        fill="#7a8994"
        d="M13.16 4.25H6.28v3.274h4.64V10l3.2-2.647V5.208c0-.53-.43-.958-.96-.958Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </>
  ),
  size: 20,
};

export const serviceCoderpadPast = {
  data: (
    <>
      <rect width="18" height="18" x="1" y="1" fill="#7a8994" rx="4" />
      <path
        fill="#fff"
        d="m4.532 10.24 2.996 1.017v1.418L3 10.828V9.632l4.528-1.847v1.418L4.532 10.24ZM9.258 14h-1.13l2.818-8h1.13l-2.818 8Zm6.216-3.775-3.055-1.027V7.79L17 9.637v1.19l-4.582 1.852v-1.412l3.056-1.042Z"
      />
    </>
  ),
  size: 20,
};

export const serviceTeamsPast = {
  data: (
    <>
      <path
        fill="#7a8994"
        d="M15.805 6.15a1.17 1.17 0 0 0-.845.359 1.228 1.228 0 0 0-.35.866 1.25 1.25 0 0 0 .35.866 1.194 1.194 0 0 0 .845.359 1.17 1.17 0 0 0 .845-.359 1.228 1.228 0 0 0 .35-.866 1.253 1.253 0 0 0-.35-.866 1.194 1.194 0 0 0-.845-.359ZM3 15.6l7.854 1.4V3L3 4.4v11.2Zm5.805-8.305v.955l-1.363.063-.006 4.151-1.018-.031V8.352l-1.37.056v-.879l3.757-.234Zm3.756.605c.755 0 1.366-.626 1.366-1.4 0-.773-.611-1.4-1.366-1.4-.41 0-.775.186-1.024.476v1.848c.249.29.614.476 1.024.476Zm2.049 5.6V9.65H17v2.8h-.017c-.084.593-.576 1.05-1.178 1.05H14.61Zm-3.073 1.05V8.6h2.39v4.9h-.017c-.084.593-.576 1.05-1.178 1.05h-1.195Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </>
  ),
  size: 20,
};
