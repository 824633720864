import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import React from "react";

export interface HovercardContentProps extends Ariakit.HovercardProps {
  /** Custom className for the content */
  className?: string;
}

export interface HovercardAnchorProps extends Ariakit.HovercardAnchorProps {
  /** The children to render */
  children: React.ReactNode;
}

export interface HovercardRootProps extends Ariakit.HovercardProviderProps {
  /** The children to render */
  children: React.ReactNode;
  /** Custom className for the root element */
  className?: string;
}

export const Content = React.forwardRef<HTMLDivElement, HovercardContentProps>(
  (props, ref) => {
    const { children, className, ...rest } = props;

    return (
      <Ariakit.Hovercard
        ref={ref}
        portal
        gutter={4}
        shift={-4}
        unmountOnHide
        {...rest}
        className={clsx(
          "rounded-md shadow-8",
          "z-[50] overflow-auto overscroll-contain",
          "bg-white text-dark",
          "outline-none",
          className
        )}
      >
        {children}
      </Ariakit.Hovercard>
    );
  }
);

export const Anchor = React.forwardRef<HTMLAnchorElement, HovercardAnchorProps>(
  (props, ref) => {
    const { children, ...rest } = props;

    return (
      <Ariakit.HovercardAnchor ref={ref} {...rest}>
        {children}
      </Ariakit.HovercardAnchor>
    );
  }
);

export const Root = React.forwardRef<HTMLDivElement, HovercardRootProps>(
  (props, ref) => {
    const { children, className, ...rest } = props;

    return (
      <Ariakit.HovercardProvider {...rest}>
        <div ref={ref} className={className}>
          {children}
        </div>
      </Ariakit.HovercardProvider>
    );
  }
);

Content.displayName = "Content";
Anchor.displayName = "Anchor";
Root.displayName = "Root";
