/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleChecked, atlasCircleWarning } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { View } from "@resource/atlas/view/View";
import {
  InterviewWithSchedulingInfo,
  ScheduleSummary,
} from "client/components/scheduled-interviews/ScheduleSummary";
import { CypressData } from "client/cypress-data-keys";
import { getPluralized } from "client/utils/strings";
import useIntercom from "utils/useIntercom";

import { usePanelSchedulingStatuses } from "../../scheduled-interviews/__hooks/usePanelSchedulingStatuses";

export type SchedulingCompletionPromptsDisplayProps = {
  interviewsWithSchedulingInfo: InterviewWithSchedulingInfo[];
  invitesSent: boolean;
  onUpdateCandidate: () => void;
  onDismiss: () => void;
};

/**
 * View for post-scheduling completion status, outlining success states and errors and next steps
 */
export function SchedulingCompletionPromptsDisplay({
  interviewsWithSchedulingInfo,
  invitesSent,
  onUpdateCandidate,
  onDismiss,
}: SchedulingCompletionPromptsDisplayProps) {
  const schedulingStatus = usePanelSchedulingStatuses({
    interviewsWithSchedulingInfo,
  });

  return (
    <View
      header={{ hideBorder: true }}
      content={{
        className: "space-y-4",
      }}
    >
      <div className="flex justify-center">
        <HeaderIcon hasErrors={schedulingStatus.hasErrors} />
      </div>
      <StatusMessaging
        schedulingStatus={schedulingStatus}
        invitesSent={invitesSent}
      />
      <ScheduleSummary
        interviewsWithSchedulingInfo={interviewsWithSchedulingInfo}
        groupBy="errors"
        hideInternalStatuses
        data-cy={CypressData.schedulingCompletion.scheduledInterviewCard}
      />
      {!schedulingStatus.hasErrors &&
      !schedulingStatus.allInterviewsBlacklisted ? (
        <div className="flex flex-col space-y-1">
          <Button variant="primary" onClick={onUpdateCandidate}>
            Update the candidate
          </Button>
          <Button isGhost onClick={onDismiss}>
            I&apos;ll do this later
          </Button>
        </div>
      ) : (
        <div className="flex flex-col">
          <Button onClick={onDismiss}>Dismiss</Button>
        </div>
      )}
    </View>
  );
}

function HeaderIcon({ hasErrors }: { hasErrors: boolean }) {
  if (hasErrors) {
    return (
      <Icon className="w-16 h-16 text-red-500" content={atlasCircleWarning} />
    );
  }

  return (
    <Icon className="w-16 h-16 text-green-500" content={atlasCircleChecked} />
  );
}

function StatusMessaging({
  schedulingStatus,
  invitesSent,
}: {
  schedulingStatus: ReturnType<typeof usePanelSchedulingStatuses>;
  invitesSent: boolean;
}) {
  const intercom = useIntercom();

  if (schedulingStatus.hasErrors) {
    return (
      <div className="flex flex-col space-y-2 text-center">
        <h3 className="text-h3">There was an issue with scheduling</h3>
        <div className="text-body-md">
          Looks like there were a few hiccups with scheduling your interviews.
          Feel free to reach out to{" "}
          <Link
            onClick={() => {
              intercom.callIntercom("show");
            }}
          >
            support
          </Link>{" "}
          for assistance.
        </div>
      </div>
    );
  }

  let titleMessage = "Scheduling was completed successfully";
  let subtitleMessage = !schedulingStatus.allInterviewsBlacklisted
    ? "Don’t forget to update the candidate. Send an updated candidate confirmation with all the interview details."
    : `${
        schedulingStatus.totalCount > 1
          ? "These interviews are"
          : "This interview is"
      } not visible to the candidate.`;

  const interviewsPluralized = getPluralized({
    count: schedulingStatus.totalCount,
    plural: "Interviews",
    singular: "Interview",
  });

  if (schedulingStatus.allInterviewsCreated) {
    if (invitesSent) {
      titleMessage = "Calendar invites were sent to interviewers";
    } else {
      titleMessage = `${interviewsPluralized} created successfully`;
    }

    if (!schedulingStatus.allInterviewsBlacklisted) {
      subtitleMessage =
        "Now, let’s get the candidate involved. Send a candidate confirmation with all the interview details.";
    }
  } else if (schedulingStatus.allInterviewsUpdated) {
    if (invitesSent) {
      titleMessage = "Updated calendar invites were sent to interviewers";
    } else {
      titleMessage = `${interviewsPluralized} updated successfully`;
    }
  } else if (schedulingStatus.allInterviewsCancelled) {
    if (invitesSent) {
      titleMessage = "A cancellation notification was sent to interviewers";
    } else {
      titleMessage = `${interviewsPluralized} cancelled`;
    }
  } else if (schedulingStatus.interviewsCreatedAndUpdated) {
    if (invitesSent) {
      titleMessage =
        "Interviewers have been notified with updated calendar invites and new calendar invites";
    } else {
      titleMessage = "Interviews created and updated successfully";
    }
  } else if (schedulingStatus.interviewsCreatedAndCancelled) {
    if (invitesSent) {
      titleMessage =
        "Interviewers have been notified with new calendar invites and cancellation notifications";
    } else {
      titleMessage = "Interviews created and cancelled successfully";
    }
  } else if (schedulingStatus.interviewsUpdatedAndCancelled) {
    if (invitesSent) {
      titleMessage =
        "Interviewers have been notified with updated calendar invites and cancellation notifications";
    } else {
      titleMessage = "Interviews updated and cancelled successfully";
    }
  } else if (schedulingStatus.interviewsCreatedUpdatedAndCancelled) {
    if (invitesSent) {
      titleMessage =
        "Interviewers have been notified with updated calendar invites, new calendar invites, and cancellation notifications";
    } else {
      titleMessage = "Interviews created, updated and cancelled successfully";
    }
  }

  return (
    <div className="flex flex-col space-y-2 text-center">
      <h3
        className="text-h3"
        data-cy={CypressData.schedulingCompletion.headerTitle}
      >
        {titleMessage}
      </h3>
      <p className="text-body-md">{subtitleMessage}</p>
    </div>
  );
}
