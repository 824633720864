import { Avatar } from "@resource/atlas/avatar/Avatar";
import { TextWithLinks } from "client/components/generic/misc/TextWithLinks";

export type RequestNotesProps = {
  notes: string;
  authorName: string;
  authorImageUrl: string | null;
};

export function RequestNotes({
  notes,
  authorImageUrl,
  authorName,
}: RequestNotesProps) {
  return (
    <div className="space-y-2">
      <p className="text-body-md-heavy text-dark">Notes</p>
      <div className="shadow-1 p-3 rounded-md space-y-3 text-body-md">
        <TextWithLinks text={notes} />
        <div className="flex gap-2 items-center">
          <Avatar
            className="shrink-0"
            size="xs"
            image={authorImageUrl}
            name={authorName}
          />
          <p className="text-body-md truncate shrink">{authorName}</p>
        </div>
      </div>
    </div>
  );
}
