import { INSERT_GUIDE_AVAILABILITY_REQUEST_COMMAND } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/rich-block";
import { LexicalEditor } from "lexical";
import { useCallback } from "react";
import { DEFAULT_DATA as GUIDE_AVAILABILITY_REQUEST_DEFAULT_DATA } from "shared/guide-availability/rich-blocks/guide-availability-request";

type UseInsertAvailabilityRequestProps = {
  editor: LexicalEditor;
  logEvent(): void;
};

export function useInsertAvailabilityRequest({
  editor,
  logEvent,
}: UseInsertAvailabilityRequestProps) {
  return useCallback(() => {
    logEvent?.();
    editor.focus();
    editor.dispatchCommand(
      INSERT_GUIDE_AVAILABILITY_REQUEST_COMMAND,
      GUIDE_AVAILABILITY_REQUEST_DEFAULT_DATA
    );
  }, [editor, logEvent]);
}
