/** Compute if an interview should display an unschedulable error message */
export function getUnschedulableErrorMessage(interview: {
  isSchedulable?: boolean;
  unschedulableErrorMessage?: string | null;
}) {
  // Explicitly check for false
  // If `isSchedulable` is unmapped, so undefined, we should treat as schedulable
  if (interview.isSchedulable === false) {
    return (
      interview.unschedulableErrorMessage ??
      "This interview is not marked as schedulable."
    );
  }

  return undefined;
}
