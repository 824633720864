import { Icon } from "@resource/atlas/icon/Icon";
import { atlasArrowRight } from "@resource/atlas/icons";
import {
  ExternalInterviewForDiff,
  GuideInterviewForDiff,
} from "shared/scheduled-interviews/interview-diff";

import { InterviewDifferencesCard } from "./InterviewDifferencesCard";

type InterviewDifferencesProps = {
  interview: GuideInterviewForDiff;
  externalInterview?: ExternalInterviewForDiff;
};

export function InterviewDifferences({
  interview,
  externalInterview,
}: InterviewDifferencesProps) {
  return (
    <div className="flex gap-4">
      <InterviewDifferencesCard
        title="Guide"
        interview={interview}
        className="flex-1"
      />
      <Icon content={atlasArrowRight} className="self-center grow-0 shrink-0" />
      <InterviewDifferencesCard
        title="Google Calendar"
        interview={interview}
        externalInterview={externalInterview}
        className="flex-1"
      />
    </div>
  );
}
