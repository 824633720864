import { Button } from "@resource/atlas/button/Button";
import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasCandidatePortal,
  atlasLink,
  atlasLogoGreenhouseLight,
} from "@resource/atlas/icons";
import { useToast } from "@resource/atlas/toast/use-toast";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useAuthContext } from "auth/context";
import { useInExtensionFrame } from "client/app/extension/__components/ExtensionControllerProvider";
import { useDashboardLinkToCandidateProfile } from "client/components/candidates/CandidatesDashboard/useDashboardLinkToCandidateProfile";
import { ViewWithAssistantToggle } from "client/components/generic/layout/ViewWithAssistantToggle";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { InternalDashboardsMenu } from "client/components/internal/generic/InternalDashboardsMenu";
import copyToClipboard from "copy-to-clipboard";
import { gql } from "generated/graphql-codegen";
import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { formatEntity } from "shared/constants/entities";
import useQuery from "utils/useQuery";

import GuideManagerMenu from "../__tmp/__components/GuideManagerMenu";
import GuideMetricsButton from "../__tmp/__components/GuideMetricsButton";
import { GuideManagerTab } from "../__tmp/GuideManagerTab";

const GET_GUIDE = gql(`
  query GetGuideForCandidateProfile($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      publicUrl
      atssyncApplication {
        id
        atsUrl
      }
      ...GuideMetricsGuide
      ...GuideManagerMenuGuide
    }
  }
`);

type CandidateProfileProps = {
  guideId: string;
  backIcon: AtlasIconData;
  onBack: () => void;
  onClickSchedulingRequest: (props: { schedulingRequestId: string }) => void;
  onClickScheduledInterview: (props: { scheduledInterviewId: string }) => void;
  onViewAllInterviews: () => void;
  scrollToUpcomingInterviews?: boolean;
};

/**
 *  A wrapper around the `GuideManagerTab` component that gives it the layout and header
 *
 *  Should be used within the dashboard. The extension will populate this data differently
 *  and use a different layout/header, but render the same `GuideManagerTab` component
 */
export function CandidateProfile({
  guideId,
  backIcon,
  onBack,
  ...props
}: CandidateProfileProps) {
  const { hasFeatureEnabled } = useAuthContext();
  const isSchedulingEnabled = useMemo(
    () => hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING),
    [hasFeatureEnabled]
  );
  const navigator = useGlobalNavigator();
  const inExtensionFrame = useInExtensionFrame();
  const { data, loading } = useQuery(GET_GUIDE, {
    variables: {
      guideId,
    },
  });
  const { sendToast } = useToast();
  const dashboardLink = useDashboardLinkToCandidateProfile({
    guideId,
  });

  const rightActions = useMemo(() => {
    const guide = data?.guideById;
    const application = data?.guideById?.atssyncApplication;
    const disabledText = loading ? "Loading..." : "No ATS application linked";

    if (!guide) {
      return null;
    }

    return (
      <div className="flex gap-[.25rem] shrink-0 tours__candidate-profile-header-actions">
        <GuideMetricsButton guide={guide} className="min-w-[2rem]" />
        {isSchedulingEnabled && (
          <Tooltip content={`Open ${formatEntity("guide")}`} isInstant>
            <Button
              icon={atlasCandidatePortal}
              as="a"
              href={guide.publicUrl}
              target="_blank"
              isGhost
              size="small"
            />
          </Tooltip>
        )}
        {!inExtensionFrame && (
          <Tooltip
            content={
              application
                ? "Open candidate profile in Greenhouse"
                : disabledText
            }
            isInstant
          >
            <Button
              isGhost
              disabled={!application}
              as="a"
              icon={atlasLogoGreenhouseLight}
              href={application?.atsUrl ?? ""}
              target="_blank"
              size="small"
            />
          </Tooltip>
        )}
        <Tooltip content="Copy profile link" isInstant>
          <Button
            isGhost
            icon={atlasLink}
            size="small"
            onClick={() => {
              copyToClipboard(dashboardLink);
              sendToast("Copied link to clipboard", {
                variant: "success",
              });
            }}
          />
        </Tooltip>
        <InternalDashboardsMenu
          guidePublicUrl={guide?.publicUrl}
          guideId={guide?.id}
        />
        <GuideManagerMenu guide={guide} />
      </div>
    );
  }, [
    dashboardLink,
    data?.guideById,
    isSchedulingEnabled,
    loading,
    inExtensionFrame,
    sendToast,
  ]);

  return (
    <>
      <ViewWithAssistantToggle
        header={{
          leftActions:
            !inExtensionFrame || navigator?.canGoBack ? (
              <Button isGhost icon={backIcon} onClick={onBack} />
            ) : null,
          rightActions,
        }}
        content={{
          className: "p-0",
        }}
      >
        <GuideManagerTab {...props} guideId={guideId} />
      </ViewWithAssistantToggle>
    </>
  );
}
