import { ItemCreator } from "@resource/atlas/__utils/collections";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { AtlasMenuContentItem } from "@resource/atlas/menu";
import { useMemo } from "react";

import {
  EditProfileDialog,
  EditProfileDialogProps,
} from "../EditProfileDialog";

export function useEditProfileMenuItem({
  userMembershipId,
  onCompleted,
}: {
  userMembershipId?: string | null;
  onCompleted?: EditProfileDialogProps["onCompleted"];
}) {
  const dialogStore = useDialogStore();

  return useMemo(() => {
    return {
      dialogStore,
      render: () => {
        if (!userMembershipId) {
          return null;
        }

        return (
          <EditProfileDialog
            store={dialogStore}
            userMembershipId={userMembershipId}
            onCompleted={onCompleted}
          />
        );
      },
      createItem: (i: ItemCreator<AtlasMenuContentItem>) => {
        if (!userMembershipId) {
          return null;
        }

        return i.item({
          key: "edit-profile",
          children: "Edit profile",
          onClick: () => {
            dialogStore.show();
          },
        });
      },
    };
  }, [dialogStore, onCompleted, userMembershipId]);
}
