import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { Checkbox } from "client/components/generic/inputs/Checkbox";
import { Divider } from "client/components/generic/misc/Divider";
import { Section } from "client/components/generic/misc/Section";
import clsx from "clsx";
import { useUpdatePreferencesOnSubmit } from "components/EditPreferences/__hooks/useUpdatePreferencesOnSubmit";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { SubmitHandler } from "react-hook-form";
import {
  SelfScheduleInterviewSettingsForCreate,
  SelfScheduleRequestForCreate,
} from "shared/self-schedule/types";

import {
  EditSelfScheduleInterviewSettingsState,
  useEditSelfScheduleInterviewSettingsState,
} from "./__hooks/useEditSelfScheduleInterviewSettingsState";
import {
  EditSelfSchedulingSettingsState,
  useEditSelfSchedulingSettingsState,
} from "./__hooks/useEditSelfSchedulingSettingsState";
import { useSyncSettingsWithInterviewer } from "./__hooks/useSyncSettingsWithInterviewer";
import { useSelfScheduleTimesCalendarInput } from "./calendar-view/hooks/queries";
import { SelfScheduleTimesCalendar } from "./calendar-view/SelfScheduleTimesCalendar";
import { EditSelfScheduleInterviewSettingsForm } from "./EditSelfScheduleInterviewSettingsForm";
import { EditSelfSchedulingSettingsForm } from "./EditSelfSchedulingSettingsForm";

export function EditSelfScheduleRequestSettingsView({
  selfScheduleRequest,
  onSave,
  saveLoading,
  onCancel,
  atsInterviewName,
}: {
  selfScheduleRequest: SelfScheduleRequestForCreate;
  onSave: (data: SelfScheduleRequestForCreate) => void | Promise<void>;
  saveLoading?: boolean;
  onCancel: () => void;
  atsInterviewName?: string;
}) {
  const logEvent = useLogEvent({
    component: "EditSelfScheduleRequestSettingsView",
  });
  const [saveSchedulingSettingsToUser, setSaveSchedulingSettingsToUser] =
    useState(false);
  const editInterviewSettingsState = useEditSelfScheduleInterviewSettingsState({
    defaultInterviewSettings: selfScheduleRequest.interviewSettings,
    skipDirtyCheck: true,
  });
  const { form: interviewSettingsForm } = editInterviewSettingsState;

  const editSchedulingSettingsState = useEditSelfSchedulingSettingsState({
    defaultSelfSchedulingSettings: selfScheduleRequest.schedulingSettings,
    selectedInterviewer: editInterviewSettingsState.interviewer,
    skipDirtyCheck: true,
  });
  const { form: schedulingSettingsForm } = editSchedulingSettingsState;
  const disabledTooltipContent = useMemo(() => {
    if (
      !interviewSettingsForm.formState.isDirty &&
      !schedulingSettingsForm.formState.isDirty
    ) {
      return "No changes have been made.";
    }

    return (
      editInterviewSettingsState.disabledTooltipContent ??
      editSchedulingSettingsState.disabledTooltipContent ??
      undefined
    );
  }, [
    editInterviewSettingsState.disabledTooltipContent,
    editSchedulingSettingsState.disabledTooltipContent,
    interviewSettingsForm.formState.isDirty,
    schedulingSettingsForm.formState.isDirty,
  ]);

  const selectedInterviewer = useMemo(() => {
    return editInterviewSettingsState.interviewer;
  }, [editInterviewSettingsState.interviewer]);

  const [
    updatePreferencesOnSubmit,
    { loading: savingToUserPreferencesLoading },
  ] = useUpdatePreferencesOnSubmit({
    userMembershipId: selectedInterviewer.id,
  });

  const onSubmit: SubmitHandler<SelfScheduleInterviewSettingsForCreate> =
    useCallback(
      async (interviewSettings) => {
        if (saveSchedulingSettingsToUser) {
          await updatePreferencesOnSubmit({
            defaultSelfSchedulingSettings: schedulingSettingsForm.getValues(),
          });
        }

        logEvent("Save Self-schedule Request Settings Clicked");

        await onSave({
          interviewSettings,
          schedulingSettings: schedulingSettingsForm.getValues(),
        });
      },
      [
        logEvent,
        onSave,
        saveSchedulingSettingsToUser,
        schedulingSettingsForm,
        updatePreferencesOnSubmit,
      ]
    );

  const calendarInput = useSelfScheduleTimesCalendarInput({
    interviewSettingsState: editInterviewSettingsState,
    schedulingSettingsState: editSchedulingSettingsState,
  });

  return (
    <div className="flex flex-grow w-full overflow-hidden bg-white">
      <EditSettingsForm
        editInterviewSettingsState={editInterviewSettingsState}
        editSchedulingSettingsState={editSchedulingSettingsState}
        atsInterviewName={atsInterviewName}
        saveSchedulingSettingsToUser={saveSchedulingSettingsToUser}
        setSaveSchedulingSettingsToUser={setSaveSchedulingSettingsToUser}
        selectedInterviewer={selectedInterviewer}
        logEvent={logEvent}
        onCancel={onCancel}
        onSubmit={onSubmit}
        interviewSettingsForm={interviewSettingsForm}
        disabledTooltipContent={disabledTooltipContent}
        saveLoading={saveLoading}
        savingToUserPreferencesLoading={savingToUserPreferencesLoading}
      />
      <SelfScheduleTimesCalendar input={calendarInput} />
    </div>
  );
}

function EditSettingsForm({
  editInterviewSettingsState,
  editSchedulingSettingsState,
  atsInterviewName,
  saveSchedulingSettingsToUser,
  setSaveSchedulingSettingsToUser,
  selectedInterviewer,
  logEvent,
  onCancel,
  onSubmit,
  interviewSettingsForm,
  disabledTooltipContent,
  saveLoading,
  savingToUserPreferencesLoading,
}: {
  editInterviewSettingsState: EditSelfScheduleInterviewSettingsState;
  editSchedulingSettingsState: EditSelfSchedulingSettingsState;
  atsInterviewName?: string;
  saveSchedulingSettingsToUser: boolean;
  setSaveSchedulingSettingsToUser: Dispatch<SetStateAction<boolean>>;
  selectedInterviewer: {
    id: string;
    name: string;
  };
  logEvent: ReturnType<typeof useLogEvent>;
  onCancel: () => void;
  onSubmit: SubmitHandler<SelfScheduleInterviewSettingsForCreate>;
  interviewSettingsForm: EditSelfScheduleInterviewSettingsState["form"];
  disabledTooltipContent: string | undefined;
  saveLoading?: boolean;
  savingToUserPreferencesLoading: boolean;
}) {
  const {
    loading: syncSettingsWithInterviewerLoading,
    interviewerSettingsHaveChanged,
  } = useSyncSettingsWithInterviewer({
    editInterviewSettingsState,
    editSchedulingSettingsState,
  });

  return (
    <View
      className="border-r border-gray-border min-w-sm max-w-[24.5rem]"
      content={{
        className: clsx("space-y-6"),
      }}
      header={{
        hideBorder: true,
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button isGhost onClick={onCancel}>
              Cancel
            </Button>
            <OptionalTooltip content={disabledTooltipContent} isInstant>
              <Button
                variant="primary"
                type="submit"
                disabled={!!disabledTooltipContent}
                isLoading={saveLoading || savingToUserPreferencesLoading}
                onClick={interviewSettingsForm.handleSubmit(onSubmit)}
              >
                Save changes
              </Button>
            </OptionalTooltip>
          </ButtonGroup>
        ),
      }}
    >
      <h3 className="text-h3 mb-4">Edit self-scheduling request</h3>
      <Section title="Interview details" size="small">
        <EditSelfScheduleInterviewSettingsForm
          state={editInterviewSettingsState}
          titleSubtext={
            atsInterviewName ? (
              <div className="text-body-sm text-subtle flex shrink-0">
                <span>
                  Connected to{" "}
                  <span className="font-medium">{atsInterviewName}</span> in{" "}
                  Greenhouse
                </span>
              </div>
            ) : undefined
          }
        />
      </Section>
      <Divider />
      <Section title="Scheduling settings" size="small">
        {syncSettingsWithInterviewerLoading ? (
          <div className="w-full flex flex-row justify-center items-center h-8">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            <EditSelfSchedulingSettingsForm
              state={editSchedulingSettingsState}
            />
            <OptionalTooltip
              isInstant
              content={
                interviewerSettingsHaveChanged
                  ? undefined
                  : "The settings currently reflect the interviewer's defaults."
              }
            >
              <Checkbox
                checked={saveSchedulingSettingsToUser}
                onClick={() => {
                  logEvent(
                    "Save Self-schedule Request Settings To User Defaults Clicked"
                  );
                  setSaveSchedulingSettingsToUser((prev) => !prev);
                }}
                disabled={!interviewerSettingsHaveChanged}
                label={`Save these settings as ${selectedInterviewer.name}'s default self-scheduling settings`}
              />
            </OptionalTooltip>
          </>
        )}
      </Section>
    </View>
  );
}
