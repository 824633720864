import { Button as AriaButton } from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasArrowLeft,
  atlasCandidatePortal,
  atlasCheck,
  atlasLink,
  atlasLogoGreenhouseLight,
} from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { useToast } from "@resource/atlas/toast/use-toast";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { HeaderProps } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { useInExtensionFrame } from "client/app/extension/__components/ExtensionControllerProvider";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { useCompleteSchedulingRequest } from "client/components/scheduling-tasks/hooks/useCompleteSchedulingRequest";
import { useReopenSchedulingRequest } from "client/components/scheduling-tasks/hooks/useReopenSchedulingRequest";
import { ReopenTaskDialog } from "client/components/scheduling-tasks/ReopenTaskDialog";
import { SchedulingTaskMenu } from "client/components/scheduling-tasks/SchedulingTaskMenu";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import copyToClipboard from "copy-to-clipboard";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingRequestForTaskDetailsHeaderFragment,
  SchedulingRequestStatus,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { formatEntity } from "shared/constants/entities";

gql(`fragment SchedulingRequestForTaskDetailsHeader on SchedulingRequest {
  id
  href
  archivedAt
  isReschedule
  status
  guide {
    id
    publicUrl
    atssyncApplication {
      id
      atsUrl
    }
  }
  ...ReopenTaskDialogSchedulingRequest
}
`);

export function useSchedulingTaskHeaderOptions({
  schedulingRequest,
  onBack,
  onEdit,
  backIcon = atlasArrowLeft,
}: {
  schedulingRequest?: SchedulingRequestForTaskDetailsHeaderFragment | null;
  onBack?: () => void;
  onEdit: () => void;
  backIcon?: AtlasIconData;
}): HeaderProps {
  const { sendToast } = useToast();
  const isInExtensionFrame = useInExtensionFrame();
  const reopenConfirmationDialogState = useDialogState();
  const [reopenSchedulingRequest, { loading: reopenLoading }] =
    useReopenSchedulingRequest();

  const onConfirmReopen = useCallback(async () => {
    if (!schedulingRequest) return;

    await reopenSchedulingRequest({
      variables: {
        input: {
          id: schedulingRequest.id,
        },
      },
    });
    reopenConfirmationDialogState.hide();
  }, [
    reopenSchedulingRequest,
    reopenConfirmationDialogState,
    schedulingRequest,
  ]);

  return useMemo(
    () => ({
      leftActions: (
        <Button isGhost icon={backIcon} onClick={onBack} size="small" />
      ),
      rightActions: schedulingRequest ? (
        <div className="flex gap-[.25rem] shrink-0 tours__candidate-profile-header-actions">
          {!schedulingRequest.archivedAt && (
            <CompleteSchedulingRequestButton
              schedulingRequestId={schedulingRequest.id}
              guideId={schedulingRequest.guide.id}
              isCompleted={
                schedulingRequest.status === SchedulingRequestStatus.COMPLETED
              }
              reopenLoading={reopenLoading}
              onReopen={() => {
                reopenConfirmationDialogState.show();
              }}
            />
          )}
          <Tooltip content={`Open ${formatEntity("guide")}`} isInstant>
            <Button
              as="a"
              isGhost
              icon={atlasCandidatePortal}
              href={schedulingRequest.guide.publicUrl}
              target="_blank"
            />
          </Tooltip>
          {schedulingRequest.guide.atssyncApplication &&
            !isInExtensionFrame && (
              <Tooltip content="Open candidate profile in Greenhouse" isInstant>
                <Button
                  isGhost
                  as="a"
                  icon={atlasLogoGreenhouseLight}
                  href={schedulingRequest.guide.atssyncApplication.atsUrl}
                  target="_blank"
                />
              </Tooltip>
            )}
          <Tooltip content={`Copy ${formatEntity("request")} link`} isInstant>
            <Button
              isGhost
              icon={atlasLink}
              onClick={() => {
                copyToClipboard(schedulingRequest.href);
                sendToast("Copied link to clipboard", {
                  variant: "success",
                });
              }}
            />
          </Tooltip>
          <StopPropagation>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div onClick={(e) => e.preventDefault()}>
              <SchedulingTaskMenu
                schedulingRequest={schedulingRequest}
                onEdit={onEdit}
              />
            </div>
          </StopPropagation>
          <ReopenTaskDialog
            state={reopenConfirmationDialogState}
            onConfirm={onConfirmReopen}
            schedulingRequest={schedulingRequest}
          />
        </div>
      ) : null,
    }),
    [
      backIcon,
      onBack,
      schedulingRequest,
      reopenLoading,
      reopenConfirmationDialogState,
      onEdit,
      onConfirmReopen,
      sendToast,
      isInExtensionFrame,
    ]
  );
}

function CompleteSchedulingRequestButton({
  schedulingRequestId,
  guideId,
  isCompleted,
  reopenLoading,
  onReopen,
}: {
  schedulingRequestId: string;
  guideId: string;
  isCompleted?: boolean;
  reopenLoading: boolean;
  onReopen: () => void;
}) {
  const [completeSchedulingRequest, { loading: completeLoading }] =
    useCompleteSchedulingRequest();

  const loading = completeLoading || reopenLoading;
  const logEvent = useLogEvent({
    component: "CompleteSchedulingRequestButton",
  });
  const schedulingTaskNavigator = useGlobalNavigator();

  return (
    <AriaButton
      className={clsx(
        "shrink-0 flex space-x-1 rounded-md text-body-sm items-center px-[10px] py-[6px] w-[7.125rem] justify-center",
        "border transition-colors",
        {
          "border-gray-border hover:bg-green-50 hover:border-green-500 hover:text-green-500":
            !isCompleted,
          "border-green-500 bg-green-500 text-white hover:bg-green-700":
            isCompleted,
        }
      )}
      onClick={async () => {
        if (isCompleted) {
          logEvent("Reopen Scheduling Request Clicked", {
            id: schedulingRequestId,
          });
          onReopen();
        } else {
          logEvent("Complete Scheduling Request Clicked", {
            id: schedulingRequestId,
          });
          await completeSchedulingRequest({
            variables: {
              input: {
                id: schedulingRequestId,
              },
            },
          });
          if (schedulingTaskNavigator) {
            schedulingTaskNavigator.navigate("candidateProfile", {
              guideId,
            });
          }
        }
      }}
    >
      {!loading ? (
        <>
          <Icon content={atlasCheck} className="w-5 h-5" />
          <span>{isCompleted ? "Completed" : "Complete"}</span>
        </>
      ) : (
        <LoadingIndicator
          className={clsx(isCompleted ? "text-white" : "text-green-500")}
          size="small"
        />
      )}
    </AriaButton>
  );
}
