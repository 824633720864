import { Button } from "@resource/atlas/button/Button";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Select } from "@resource/atlas/select";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import TextField from "@resource/atlas/textfield/TextField";
import { View } from "@resource/atlas/view/View";
import { displayPhoneNumber } from "@resource/common";
import { UsersSelect } from "client/components/generic/select/UserSelect";
import { gql } from "generated/graphql-codegen";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { formatEntity } from "shared/constants/entities";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

gql(`
fragment GuideDetails on Guide {
  id
  guideLexicalContext {
    id
  }
  coordinator {
    ...InterviewerForSelection
  }
  recruiter {
    ...InterviewerForSelection
  }
  candidate {
    id
    internalFirstName
    displayFirstName
    lastName
    displayName
    preferredName
    email
    allEmails {
      id
      value
    }
    phone
    allPhones {
      id
      value
    }
  }
}
`);

const GUIDE_FOR_SETTINGS = gql(`
query GuideForSettings($guideId: String!) {
    guideById(guideId: $guideId) {
    ...GuideDetails
  }
}
`);

const UPDATE_GUIDE_AND_CANDIDATE_DETAILS = gql(`
mutation UpdateGuideAndCandidateDetails($input: UpdateGuideAndCandidateDetailsInput!) {
  updateGuideAndCandidateDetails(input: $input) {
    success
    code
    message
    guide {
      ...GuideDetails
    }
  }
}
`);

export type GuideSettingsViewProps = {
  guideId: string;
  store: DialogStore;
  onCompleted?: () => void;
};

function GuideSettingsView({
  guideId,
  store,
  onCompleted,
}: GuideSettingsViewProps) {
  const onCancel = useCallback(() => {
    store.hide();
  }, [store]);
  const state = store.getState();

  const { data, loading } = useQuery(GUIDE_FOR_SETTINGS, {
    variables: { guideId },
    skip: !state.open,
  });

  const originalValues = useMemo(() => {
    return {
      preferredName: data?.guideById?.candidate.preferredName,
      primaryEmail: data?.guideById?.candidate.email ?? null,
      primaryPhone: data?.guideById?.candidate.phone ?? null,
      coordinatorId: data?.guideById?.coordinator?.id,
      recruiterId: data?.guideById?.recruiter?.id,
    };
  }, [data?.guideById]);

  const { handleSubmit, reset, control, formState } = useForm({
    defaultValues: originalValues,
  });

  useEffect(() => {
    reset(originalValues);
  }, [reset, originalValues]);

  const [updateDetails, { loading: saving }] = useMutation(
    UPDATE_GUIDE_AND_CANDIDATE_DETAILS,
    {
      update: (cache, { data: update }) => {
        const guide = update?.updateGuideAndCandidateDetails.guide;
        if (!guide || !data?.guideById?.guideLexicalContext.id) {
          return;
        }

        const candidatePhone = guide.candidate.phone;
        const candidateEmail = guide.candidate.email;
        const { recruiter } = guide;
        const { coordinator } = guide;

        cache.modify({
          id: cache.identify({
            __typename: "GuideLexicalContext",
            id: data.guideById.guideLexicalContext.id,
          }),
          fields: {
            ...(candidatePhone
              ? {
                  candidatePhone() {
                    return candidatePhone;
                  },
                }
              : {}),
            ...(candidateEmail
              ? {
                  candidateEmail() {
                    return candidateEmail;
                  },
                }
              : {}),
            candidateName() {
              return {
                first: guide.candidate.displayFirstName,
                last: guide.candidate.lastName,
              };
            },
            ...(recruiter
              ? {
                  recruiterName() {
                    return {
                      first: recruiter.firstName,
                      last: recruiter.lastName,
                    };
                  },
                }
              : {}),
            ...(coordinator
              ? {
                  coordinatorName() {
                    return {
                      first: coordinator.firstName,
                      last: coordinator.lastName,
                    };
                  },
                }
              : {}),
          },
        });
      },
    }
  );

  const firstName = data?.guideById?.candidate.internalFirstName;
  const lastName = data?.guideById?.candidate.lastName;

  const emailItems = useSelectItems(
    (i) =>
      data?.guideById?.candidate.allEmails.map(({ value: email }) =>
        i.option({
          key: email,
          value: email,
          children: email,
        })
      ) ?? [],
    [data?.guideById?.candidate.allEmails]
  );

  const phoneItems = useSelectItems(
    (i) =>
      data?.guideById?.candidate.allPhones.map(({ value: phone }) =>
        i.option({
          key: phone,
          value: phone,
          /**
           * TODO: format phone number. Our `displayPhoneNumber` function mangles
           * international phone numbers, so we need to find a better solution.
           */
          children: displayPhoneNumber(phone),
        })
      ) ?? [],
    [data?.guideById?.candidate.allPhones]
  );

  const save: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formData) => {
      await updateDetails({
        variables: {
          input: {
            guideId,
            ...(formState.dirtyFields.preferredName
              ? { preferredName: formData.preferredName }
              : {}),
            ...(formState.dirtyFields.primaryEmail
              ? {
                  primaryEmail: formData.primaryEmail,
                }
              : {}),
            ...(formState.dirtyFields.primaryPhone
              ? {
                  primaryPhone: formData.primaryPhone,
                }
              : {}),
            ...(formState.dirtyFields.coordinatorId
              ? { coordinatorId: formData.coordinatorId }
              : {}),
            ...(formState.dirtyFields.recruiterId
              ? { recruiterId: formData.recruiterId }
              : {}),
          },
        },
      });

      store.hide();
      onCompleted?.();
    },
    [store, onCompleted, guideId, formState.dirtyFields, updateDetails]
  );

  return (
    <View
      header={{
        title: `${formatEntity("guide", { capitalize: true })} settings`,
      }}
      footer={{
        leftActions: (
          <Button type="button" onClick={() => onCancel()}>
            Cancel
          </Button>
        ),
        rightActions: (
          <Button
            type="button"
            onClick={handleSubmit(save)}
            isLoading={saving}
            variant="primary"
            disabled={!formState.isValid || loading || !formState.isDirty}
          >
            Save changes
          </Button>
        ),
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <TextField
            value={firstName}
            isDisabled
            label="First name"
            className="flex-1"
          />
          <TextField
            value={lastName}
            isDisabled
            label="Last name"
            className="flex-1"
          />
        </div>
        <Controller
          name="preferredName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Candidate preferred name"
              value={field.value ?? ""}
            />
          )}
        />
        <div>
          <div className="text-body-md-heavy">Candidate default email</div>
          <Controller
            name="primaryEmail"
            control={control}
            render={({ field }) => (
              <Select.Root
                value={field.value ?? undefined}
                setValue={(val) => {
                  field.onChange(val as string);
                }}
              >
                <Select.Trigger className="w-full mt-2 bg-light-gray-500" />
                <Select.Content
                  items={emailItems}
                  isLoading={loading}
                  UNSAFE_disableDynamicCollectionWarnings
                />
              </Select.Root>
            )}
          />

          <p className="text-body-sm text-subtle mt-2">
            This email will be used for all notifications shared with the
            candidate.
          </p>
        </div>
        {!!data?.guideById?.candidate.allPhones.length && (
          <div>
            <div className="text-body-md-heavy">Candidate default phone</div>
            <Controller
              name="primaryPhone"
              control={control}
              render={({ field }) => (
                <Select.Root
                  value={field.value ?? undefined}
                  setValue={(val) => {
                    field.onChange(val as string);
                  }}
                >
                  <Select.Trigger className="w-full mt-2 bg-light-gray-500" />
                  <Select.Content
                    items={phoneItems}
                    isLoading={loading}
                    UNSAFE_disableDynamicCollectionWarnings
                  />
                </Select.Root>
              )}
            />
          </div>
        )}
        <div className="flex gap-4">
          <Controller
            name="recruiterId"
            control={control}
            render={({ field }) => (
              <div className="space-y-2 flex-1">
                <p className="text-body-md-heavy">Recruiter</p>
                <UsersSelect
                  {...field}
                  portal
                  selectedId={field.value}
                  onSelect={(user) => {
                    field.onChange(user.id);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="coordinatorId"
            control={control}
            render={({ field }) => (
              <div className="space-y-2 flex-1">
                <p className="text-body-md-heavy">Recruiting Coordinator</p>
                <UsersSelect
                  {...field}
                  portal
                  selectedId={field.value}
                  onSelect={(user) => {
                    field.onChange(user.id);
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
    </View>
  );
}

export function GuideSettingsDialog(props: GuideSettingsViewProps) {
  const { store } = props;

  return (
    <Dialog store={store} size="medium-plus">
      <GuideSettingsView {...props} />
    </Dialog>
  );
}
