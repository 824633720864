import { PostTemplateLoader } from "components/MessageComposer/PostTemplateLoader";
import { PostTemplateType } from "generated/graphql-codegen/graphql";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";

import { FormGroup } from "../../generic/misc/FormGroup";
import { ScheduledInterviewDetailsEditor } from "../../scheduled-interview-editor/ScheduledInterviewDetailsEditor";
import { ScheduledInterviewDetailsLiteEditor } from "../../scheduled-interview-editor/ScheduledInterviewDetailsLiteEditor";
import { AddInterviewChangeMessageInput } from "../../scheduling-review/AddInterviewChangeMessageInput";
import { NotifyInterviewersCheckbox } from "../../scheduling-review/NotifyInterviewersCheckbox";
import { EditInterviewInviteAndNotifyState } from "../__hooks__/useEditInterviewInviteAndNotifyState";

export function EditInterviewInviteAndNotifyForm({
  state,
}: {
  state: EditInterviewInviteAndNotifyState;
}) {
  const {
    form,
    titleContentEditorProps,
    customInstructionsContentEditorProps,
    loadTemplate,
    valueSet,
  } = state;
  const selectedTemplate = form.watch("template");
  const shouldNotify = form.watch("shouldNotifyInterviewers");
  const message = form.watch("message");

  return (
    <div className="space-y-4 w-full">
      <FormGroup label="Template">
        <PostTemplateLoader
          onSelect={loadTemplate}
          onTemplateEditCompleted={loadTemplate}
          selectedTemplate={selectedTemplate}
          type={PostTemplateType.interviewer_interview_confirmation}
        />
      </FormGroup>
      <FormGroup label="Interviewer title">
        <ScheduledInterviewDetailsLiteEditor
          {...titleContentEditorProps}
          valueSet={valueSet}
          onChange={(editorState) => {
            form.setValue(
              "interviewerConfirmationTitle",
              editorState.toJSON(),
              {
                shouldDirty: true,
              }
            );
          }}
          initialState={form.getValues("interviewerConfirmationTitle")}
          isGhost={false}
        />
      </FormGroup>
      <FormGroup
        label="Custom instructions"
        subText={
          <span>
            Guide automatically adds the candidate name, job, interview,
            scorecard, and more to calendar invites. Use custom instructions for
            any additional information for interviewers.{" "}
            {/** TODO in follow-up */}
            {/* <Link>See preview</Link> */}
          </span>
        }
      >
        <ScheduledInterviewDetailsEditor
          {...customInstructionsContentEditorProps}
          className="min-h-[7.5rem] max-h-[16rem]"
          valueSet={valueSet}
          isGhost={false}
          initialState={
            form.getValues("interviewerCustomInstructions") ??
            LEXICAL_EMPTY_STATE
          }
          onChange={(editorState) => {
            form.setValue(
              "interviewerCustomInstructions",
              editorState.toJSON(),
              {
                shouldDirty: true,
              }
            );
          }}
        />
      </FormGroup>
      <NotifyInterviewersCheckbox
        notifyInterviewers={shouldNotify}
        onChange={(notify) => {
          form.setValue("shouldNotifyInterviewers", notify);
        }}
      />
      {shouldNotify && (
        <AddInterviewChangeMessageInput
          value={message ?? ""}
          onChange={(value) => {
            form.setValue("message", value);
          }}
        />
      )}
    </div>
  );
}
