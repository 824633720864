import { EditSchedulingRequestPage } from "client/app/extension/scheduling-request/[schedulingRequestId]/edit/page";
import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";

/**
 *  A wrapper around the EditSchedulingRequestPage component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorEditSchedulingTask({
  schedulingTaskId,
}: {
  schedulingTaskId: string;
}) {
  const navigator = useGlobalNavigatorOrThrow();
  return (
    <EditSchedulingRequestPage
      schedulingTaskId={schedulingTaskId}
      onBack={navigator.back}
      onCompleted={navigator.back}
    />
  );
}
