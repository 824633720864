import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";

export const useStartsIn = (startTime: DateTime, endTime: DateTime) => {
  const startsIn = Math.floor(startTime.diffNow("seconds").seconds);
  const [currentStartsInSeconds, setCurrentStartsInSeconds] =
    useState(startsIn);
  const interval = useRef<NodeJS.Timeout>();

  const clear = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }
  }, []);

  useEffect(() => {
    clear();
    interval.current = setInterval(() => {
      setCurrentStartsInSeconds((s) => {
        const newTime = s - 1;
        if (newTime < -1) {
          clear();
        }
        return newTime;
      });
    }, 1000);
    return () => clear();
  }, [clear]);

  const startsInMinutes = Math.floor(currentStartsInSeconds / 60);
  const isHappeningNow =
    startTime.diffNow("seconds").seconds <= 0 &&
    endTime.diffNow("seconds").seconds > 0;
  const isInPast = endTime.diffNow("seconds").seconds < 0;
  const isStartingInMoreThan15Minutes = startsInMinutes >= 15;

  return {
    startsInMinutes,
    isHappeningNow,
    isInPast,
    isStartingInMoreThan15Minutes,
  };
};
