import { useMemo } from "react";

import { TagFilterTag, TagFilterTagGroup } from "../types";

type GroupedTags = {
  ungrouped: TagFilterTag[];
  grouped: Record<
    string,
    {
      id: string;
      label: string;
      color: string;
      tags: TagFilterTag[];
    }
  >;
};

export function useGroupedTags(
  tags: TagFilterTag[],
  groups: TagFilterTagGroup[]
): GroupedTags {
  return useMemo(() => {
    // Initialize all groups, even empty ones
    const initialGroups = groups.reduce(
      (acc, group) => {
        acc.grouped[group.id] = {
          id: group.id,
          label: group.label,
          color: group.color,
          tags: [],
        };
        return acc;
      },
      { ungrouped: [], grouped: {} } as GroupedTags
    );

    // Add tags to their respective groups
    return tags.reduce((acc, tag) => {
      if (tag.tagGroup?.id) {
        // Group should exist from initialization, but check just in case
        if (!acc.grouped[tag.tagGroup.id]) {
          acc.grouped[tag.tagGroup.id] = {
            id: tag.tagGroup.id,
            label: tag.tagGroup.label,
            color: tag.tagGroup.color,
            tags: [],
          };
        }
        acc.grouped[tag.tagGroup.id].tags.push(tag);
      } else {
        acc.ungrouped.push(tag);
      }
      return acc;
    }, initialGroups);
  }, [tags, groups]);
}
