import { colors } from "@resource/atlas-tokens";
import { InterviewerResponse } from "generated/graphql-codegen/graphql";

const RESERVED_CALENDAR_COLORS = [
  "primary",
  "placeholder",
  "temporary",
  "cancelled",
  "blocked",
] as const;

const INTERVIEWER_CALENDAR_COLORS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
] as const;

export const CALENDAR_COLORS = [
  ...RESERVED_CALENDAR_COLORS,
  ...INTERVIEWER_CALENDAR_COLORS,
];

export type ReservedCalendarColors = typeof RESERVED_CALENDAR_COLORS;

export type InterviewerCalendarColors = typeof INTERVIEWER_CALENDAR_COLORS;

export type CalendarColors = typeof CALENDAR_COLORS;

export type CalendarColor = CalendarColors[number];

export type CalendarEventVariant = "primary" | "background";

export type CalendarColorVariant = "active" | "faded";

const calendarColorsMap: Record<
  CalendarColor,
  Record<CalendarColorVariant, { background: string; text: string }>
> = {
  primary: {
    active: {
      background: colors.purple[500],
      text: colors.white,
    },
    faded: {
      background: colors.purple[50],
      text: colors.purple[900],
    },
  },
  blocked: {
    active: {
      background: colors.red[500],
      text: colors.white,
    },
    faded: {
      background: colors.red[100],
      text: colors.red[900],
    },
  },
  placeholder: {
    active: {
      background: colors.purple[100],
      text: colors.purple[900],
    },
    faded: {
      background: colors.purple[50],
      text: colors.purple[900],
    },
  },
  cancelled: {
    active: {
      background: colors.red[500],
      text: colors.white,
    },
    faded: {
      background: colors.red[50],
      text: colors.red[900],
    },
  },
  temporary: {
    active: {
      background: colors.gray[50],
      text: colors.gray[900],
    },
    // same as active, don't actually support faded for temporary
    faded: {
      background: colors.gray[50],
      text: colors.gray[900],
    },
  },
  1: {
    active: {
      background: colors.pink[500],
      text: colors.white,
    },
    faded: {
      background: colors.pink[50],
      text: colors.pink[900],
    },
  },
  2: {
    active: {
      background: colors.orange[500],
      text: colors.white,
    },
    faded: {
      background: colors.orange[50],
      text: colors.orange[900],
    },
  },
  3: {
    active: {
      background: colors.green[500],
      text: colors.white,
    },
    faded: {
      background: colors.green[50],
      text: colors.green[900],
    },
  },
  4: {
    active: {
      background: colors.yellow[500],
      text: colors.white,
    },
    faded: {
      background: colors.yellow[50],
      text: colors.yellow[900],
    },
  },
  5: {
    active: {
      background: colors.blue[500],
      text: colors.white,
    },
    faded: {
      background: colors.blue[50],
      text: colors.blue[900],
    },
  },
  6: {
    active: {
      background: colors.cyan[500],
      text: colors.white,
    },
    faded: {
      background: colors.cyan[50],
      text: colors.cyan[900],
    },
  },
  7: {
    active: {
      background: colors.indigo[500],
      text: colors.white,
    },
    faded: {
      background: colors.indigo[50],
      text: colors.indigo[900],
    },
  },
  8: {
    active: {
      background: colors.red[500],
      text: colors.white,
    },
    faded: {
      background: colors.red[50],
      text: colors.red[900],
    },
  },
};

export const DEFAULT_COLOR_VARIANT: CalendarColorVariant = "active";

export type ColorConfig = {
  color: CalendarColor;
  eventVariant?: CalendarEventVariant;
  border?: string;
  ring?: string;
};

export const getCalendarColor = (idx = 0) => {
  const index = idx % INTERVIEWER_CALENDAR_COLORS.length;

  return INTERVIEWER_CALENDAR_COLORS[index];
};

type GetConfigColorProps = {
  color: CalendarColor;
  variant?: CalendarColorVariant;
};

export const getColorsFromConfig = (config: GetConfigColorProps) => {
  const { color, variant = DEFAULT_COLOR_VARIANT } = config;

  return calendarColorsMap[color][variant];
};

export const getBackgroundColorFromConfig = (config: GetConfigColorProps) => {
  return getColorsFromConfig(config).background;
};

export const getBackgroundStylingFromConfig = ({
  config,
  responseStatus,
}: {
  config: ColorConfig;
  responseStatus?: InterviewerResponse;
}) => {
  const activeColor = getBackgroundColorFromConfig({
    color: config.color,
    variant: "active",
  });
  const fadedColor = getBackgroundColorFromConfig({
    color: config.color,
    variant: "faded",
  });

  if (config.eventVariant === "background") {
    if (responseStatus === "NEEDS_ACTION") {
      return {
        border: `1px solid #EEEDEF`,
        backgroundColor: "white",
      };
    }
    return {
      border: `1px solid white`,
      backgroundColor: fadedColor,
    };
  }

  if (responseStatus === "NEEDS_ACTION") {
    return {
      border: `1px solid #EEEDEF`,
      backgroundColor: "white",
    };
  }

  return {
    border: `1px solid white`,
    backgroundColor: activeColor,
  };
};

export const getTextColorFromConfig = (
  config: ColorConfig,
  responseStatus?: InterviewerResponse
) => {
  if (responseStatus && responseStatus === "NEEDS_ACTION") {
    return getColorsFromConfig({
      color: config.color,
      variant: "faded",
    }).text;
  }

  return getColorsFromConfig({
    color: config.color,
    variant: config.eventVariant === "primary" ? "active" : "faded",
  }).text;
};

export const getDefaultInterviewerCalendarColors = (
  calendarIds: string[]
): {
  [calendarId: string]: ColorConfig;
} => {
  return calendarIds.reduce((acc, id, idx) => {
    acc[id] = {
      color: getCalendarColor(idx),
      eventVariant: "background",
    };

    return acc;
  }, {} as Record<string, ColorConfig>);
};
