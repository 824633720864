import { AssigneeOption } from "client/components/generic/select/AssigneeSelect";
import { useFilteredInterviewRequirements } from "client/utils/interviewRequirements";
import deepDiff from "deep-diff";
import {
  ConferenceRoomSettings,
  InterviewPanelRequirementForConfigurationFragment,
  InterviewRequirementForConfigurationFragment,
  SchedulingRequestAutoAssignOnAvailabilityReceiptType,
  SchedulingRequestPriority,
  SchedulingTaskAssigneeType,
  UserMembershipForAssigneeSelectionFragment,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePrevious } from "react-use";
import { PersistableCalendarEvent } from "shared/guide-content/rich-blocks/calendar-utils/types";
import { adjustOnlyExistingAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/simplify-scheduling-input";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";
import { InterviewPanelLocation } from "shared/interview-requirements/types";

import {
  useAlgorithmSettingsErrors,
  useLegacyInterviewPanelRequirements,
} from "./interviewPanelRequirement/utils/hooks";
import { mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings } from "./interviewPanelRequirement/utils/mapping";

export enum InterviewRequirementsConfigurationView {
  LIST = "LIST",
  EDIT_INTERVIEW = "EDIT_INTERVIEW",
  ACTION_PUSH_IN = "ACTION_PUSH_IN",
}

export type InterviewRequirementsConfigurationContextData = {
  interviewPanelRequirement: InterviewPanelRequirementForConfigurationFragment;
  interviewRequirements: InterviewRequirementForConfigurationFragment[];
  defaultInterviewPanelRequirement?: InterviewPanelRequirementForConfigurationFragment;
  setInterviewRequirements: React.Dispatch<
    React.SetStateAction<InterviewRequirementForConfigurationFragment[]>
  >;
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  assignee: AssigneeOption | undefined;
  setAssignee: React.Dispatch<React.SetStateAction<AssigneeOption | undefined>>;
  defaultPriority?: SchedulingRequestPriority;
  priority: SchedulingRequestPriority;
  setPriority: React.Dispatch<React.SetStateAction<SchedulingRequestPriority>>;
  manualAvailabilities: PersistableCalendarEvent[] | null;
  setManualAvailabilities: React.Dispatch<
    React.SetStateAction<PersistableCalendarEvent[] | null>
  >;
  updateInterviewRequirement: (
    id: string,
    updatedInterview: InterviewRequirementForConfigurationFragment
  ) => void;
  selectedInterviewRequirement: InterviewRequirementForConfigurationFragment | null;
  view: InterviewRequirementsConfigurationView;
  pushView: (view: InterviewRequirementsConfigurationView) => unknown;
  resetView: () => void;
  canGoBack: boolean;
  goBack: () => void;
  onEditInterviewRequirement: (id: string) => void;
  onRemoveInterviewerRequirement: (props: {
    interviewRequirementId: string;
    interviewerRequirementId: string;
  }) => void;
  diffDescription?: string;
  autoAssignOnAvailabilityReceiptConfig: AutoAssignOnAvailabilityReceiptConfig;
  setAutoAssignOnAvailabilityReceiptConfig: React.Dispatch<
    React.SetStateAction<AutoAssignOnAvailabilityReceiptConfig>
  >;
  requireVideoConferencing: VideoConferencingServiceType | null;
  setRequireVideoConferencing: (
    value: VideoConferencingServiceType | null
  ) => unknown;
  algorithmSettings: UserConfigurableAlgorithmSettings;
  setAlgorithmSettings: React.Dispatch<
    React.SetStateAction<UserConfigurableAlgorithmSettings>
  >;
  reuseVideoConferencingLink: boolean;
  setReuseVideoConferencingLink: React.Dispatch<React.SetStateAction<boolean>>;
  requireConferenceRoom: ConferenceRoomSettings | null;
  setRequireConferenceRoom: (value: ConferenceRoomSettings | null) => unknown;
  defaultPrivate: boolean;
  setDefaultPrivate: (value: boolean) => unknown;
  defaultAssigneeType?: SchedulingTaskAssigneeType;
  location: InterviewPanelLocation | null;
  setLocation: (value: InterviewPanelLocation | null) => unknown;
};

export const InterviewSelectionFormContext = createContext<
  InterviewRequirementsConfigurationContextData | undefined
>(undefined);

export const useInterviewRequirementsConfigurationContext = () => {
  const context = useContext(InterviewSelectionFormContext);
  if (!context) {
    throw new Error(
      "useInterviewSelectionFormContext must be used within a InterviewSelectionFormProvider"
    );
  }
  return context;
};

type AutoAssignOnAvailabilityReceiptConfig = {
  autoAssignType: SchedulingRequestAutoAssignOnAvailabilityReceiptType;
  assignToUserMembership?: UserMembershipForAssigneeSelectionFragment | null;
};

export function InterviewRequirementsConfigurationProvider({
  defaultInterviewPanelRequirement,
  defaultSelectedInterviewRequirementId,
  defaultPriority,
  defaultNotes,
  defaultAssignee,
  defaultAssigneeType,
  defaultAutoAssignOnAvailabilityReceiptConfig = {
    autoAssignType:
      SchedulingRequestAutoAssignOnAvailabilityReceiptType.NO_REASSIGNMENT,
    assignToUserMembership: null,
  },
  children,
}: {
  defaultInterviewPanelRequirement: InterviewPanelRequirementForConfigurationFragment;
  defaultSelectedInterviewRequirementId?: string;
  defaultPriority?: SchedulingRequestPriority | null;
  defaultNotes?: string | null;
  defaultAssignee?: AssigneeOption;
  defaultAssigneeType?: SchedulingTaskAssigneeType;
  defaultAutoAssignOnAvailabilityReceiptConfig?: AutoAssignOnAvailabilityReceiptConfig;
  children: React.ReactNode;
}) {
  const [viewStack, setViewStack] = useState<
    InterviewRequirementsConfigurationView[]
  >([InterviewRequirementsConfigurationView.LIST]);
  const [interviewRequirements, setInterviewRequirements] = useState<
    InterviewRequirementForConfigurationFragment[]
  >(defaultInterviewPanelRequirement?.interviewRequirements ?? []);

  const [notes, setNotes] = useState(defaultNotes ?? "");
  const [manualAvailabilities, setManualAvailabilities] = useState<
    PersistableCalendarEvent[] | null
  >(null);
  const [
    autoAssignOnAvailabilityReceiptConfig,
    setAutoAssignOnAvailabilityReceiptConfig,
  ] = useState<AutoAssignOnAvailabilityReceiptConfig>(
    defaultAutoAssignOnAvailabilityReceiptConfig
  );

  const [assignee, setAssignee] = useState<AssigneeOption | undefined>(
    defaultAssignee ?? undefined
  );
  const [priority, setPriority] = useState(
    defaultPriority ?? SchedulingRequestPriority.NORMAL
  );
  const [selectedInterviewRequirementId, setSelectedInterviewRequirementId] =
    useState<string | null>(defaultSelectedInterviewRequirementId ?? null);

  const { requireVideoConferencing, requireConferenceRoom, defaultPrivate } =
    useLegacyInterviewPanelRequirements({ interviewRequirements });

  const setRequireVideoConferencing = useCallback(
    (service: VideoConferencingServiceType | null) => {
      setInterviewRequirements((prevInterviewRequirements) => {
        return prevInterviewRequirements.map((interviewRequirement) => {
          return {
            ...interviewRequirement,
            videoConferencingSettings: service
              ? {
                  service,
                  host: null,
                }
              : null,
          };
        });
      });
    },
    []
  );

  const setRequireConferenceRoom = useCallback(
    (settings: ConferenceRoomSettings | null) => {
      setInterviewRequirements((prevInterviewRequirements) => {
        return prevInterviewRequirements.map((interviewRequirement) => {
          return {
            ...interviewRequirement,
            conferenceRoomSettings: settings ?? null,
          };
        });
      });
    },
    []
  );

  const setDefaultPrivate = useCallback((isPrivate: boolean) => {
    setInterviewRequirements((prevInterviewRequirements) => {
      return prevInterviewRequirements.map((interviewRequirement) => {
        return {
          ...interviewRequirement,
          isPrivate,
        };
      });
    });
  }, []);

  const [reuseVideoConferencingLink, setReuseVideoConferencingLink] = useState(
    defaultInterviewPanelRequirement.reuseVideoConferencingLink
  );

  const { interviewRequirements: filteredRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements,
    });
  const previousRequirements = usePrevious(filteredRequirements);

  const [algorithmSettings, setAlgorithmSettings] =
    useState<UserConfigurableAlgorithmSettings>(
      mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
        algorithmSettingsFragment:
          defaultInterviewPanelRequirement.algorithmSettings,
        interviewCount: filteredRequirements.length,
      })
    );

  const algorithmSettingsErrors = useAlgorithmSettingsErrors({
    algorithmSettings,
    interviewRequirements: filteredRequirements,
  });

  const [location, setLocation] = useState<InterviewPanelLocation | null>(
    defaultInterviewPanelRequirement.location
  );

  useEffect(() => {
    setSelectedInterviewRequirementId(
      defaultSelectedInterviewRequirementId ?? null
    );
    setViewStack([InterviewRequirementsConfigurationView.LIST]);
  }, [defaultSelectedInterviewRequirementId]);

  // Needs to be a separate hook so doesn't get sent back a page when changing interviewer profile/preferences
  useEffect(() => {
    setInterviewRequirements(
      defaultInterviewPanelRequirement?.interviewRequirements ?? []
    );
  }, [defaultInterviewPanelRequirement?.interviewRequirements]);

  const selectedInterviewRequirement = useMemo(() => {
    if (!selectedInterviewRequirementId) {
      return null;
    }

    return (
      interviewRequirements.find(
        (interviewRequirement) =>
          interviewRequirement.id === selectedInterviewRequirementId
      ) ?? null
    );
  }, [interviewRequirements, selectedInterviewRequirementId]);

  const updateInterviewRequirement = useCallback(
    (
      id: string,
      updatedInterview: InterviewRequirementForConfigurationFragment
    ) => {
      setInterviewRequirements((prevInterviewRequirements) => {
        return prevInterviewRequirements.map((interviewRequirement) => {
          if (interviewRequirement.id === id) {
            return updatedInterview;
          }

          return interviewRequirement;
        });
      });
    },
    []
  );

  const pushView = useCallback(
    (newView: InterviewRequirementsConfigurationView) => {
      setViewStack((prevViewStack) => {
        // Don't allow pushing the same view twice
        if (prevViewStack[prevViewStack.length - 1] === newView) {
          return prevViewStack;
        }
        return [...prevViewStack, newView];
      });
    },
    []
  );

  const resetView = useCallback(() => {
    setViewStack([InterviewRequirementsConfigurationView.LIST]);
  }, []);

  const goBack = useCallback(() => {
    if (viewStack.length > 1) {
      setViewStack((prevViewStack) => {
        if (prevViewStack.length === 1) {
          return prevViewStack;
        }
        const newStack = [...prevViewStack];
        newStack.pop();
        return newStack;
      });
    } else {
      resetView();
    }
  }, [resetView, viewStack.length]);

  const view = useMemo(() => {
    return viewStack[viewStack.length - 1];
  }, [viewStack]);

  const onEditInterviewRequirement = useCallback(
    (id: string) => {
      setSelectedInterviewRequirementId(id);
      pushView(InterviewRequirementsConfigurationView.EDIT_INTERVIEW);
    },
    [setSelectedInterviewRequirementId, pushView]
  );

  const onRemoveInterviewerRequirement = useCallback(
    ({
      interviewRequirementId,
      interviewerRequirementId,
    }: {
      interviewRequirementId: string;
      interviewerRequirementId: string;
    }) => {
      setInterviewRequirements((prevInterviewRequirements) => {
        return prevInterviewRequirements.map((interviewRequirement) => {
          if (interviewRequirement.id === interviewRequirementId) {
            return {
              ...interviewRequirement,
              interviewerRequirements:
                interviewRequirement.interviewerRequirements.filter(
                  (interviewerRequirement) =>
                    interviewerRequirement.id !== interviewerRequirementId
                ),
            };
          }

          return interviewRequirement;
        });
      });
    },
    []
  );

  const diffDescription = useMemo(() => {
    const original = {
      interviews: defaultInterviewPanelRequirement.interviewRequirements,
      notes: "",
    };
    const latest = {
      interviews: interviewRequirements,
      notes,
    };

    // Return the difference between the original and latest objects, with a deep object comparison. Suggest a new library if needed
    const diff = deepDiff(original, latest);
    return diff ? JSON.stringify(diff) : undefined;
  }, [defaultInterviewPanelRequirement, interviewRequirements, notes]);

  /**
   * Keep algorithm settings in sync with the number of interview requirements
   * This is a bit of a hack to ensure that the algorithm settings are always valid when adding/removing requirements
   * We have validation to ensure the max number of days is not greater than the number of interview requirements
   * when using `Flexible across any days` setting
   * We were seeing a bug that when removing a requirement, the max number of days was then too high and would fail validation
   * Also present in JobSettingsInterviewPanelRequirementsDialog.
   */
  useEffect(() => {
    if (
      // We only want to run this when the requirements change, not while the user is setting algorithm settings in the UI.
      previousRequirements &&
      previousRequirements.length !== filteredRequirements.length &&
      algorithmSettingsErrors
    ) {
      const updatedAlgorithmSettings = adjustOnlyExistingAlgorithmSettings({
        settings: algorithmSettings,
        interviewCount: filteredRequirements.length,
      });

      // Avoid infinite loops if validation errors can't be fixed with this logic.
      if (deepDiff(algorithmSettings, updatedAlgorithmSettings)) {
        setAlgorithmSettings(updatedAlgorithmSettings);
      }
    }
  }, [
    algorithmSettings,
    algorithmSettingsErrors,
    filteredRequirements.length,
    previousRequirements,
  ]);

  const value = useMemo((): InterviewRequirementsConfigurationContextData => {
    return {
      interviewPanelRequirement: {
        id: defaultInterviewPanelRequirement?.id,
        reuseVideoConferencingLink:
          defaultInterviewPanelRequirement?.reuseVideoConferencingLink,
        interviewRequirements,
        algorithmSettings: defaultInterviewPanelRequirement?.algorithmSettings,
        location: defaultInterviewPanelRequirement?.location,
      },
      defaultInterviewPanelRequirement,
      interviewRequirements,
      setInterviewRequirements,
      selectedInterviewRequirement,
      manualAvailabilities,
      setManualAvailabilities,
      view,
      pushView,
      goBack,
      resetView,
      canGoBack: viewStack.length > 1,
      updateInterviewRequirement,
      onEditInterviewRequirement,
      onRemoveInterviewerRequirement,
      notes,
      setNotes,
      assignee,
      setAssignee,
      priority,
      setPriority,
      diffDescription,
      autoAssignOnAvailabilityReceiptConfig,
      setAutoAssignOnAvailabilityReceiptConfig,
      requireVideoConferencing,
      setRequireVideoConferencing,
      algorithmSettings,
      setAlgorithmSettings,
      reuseVideoConferencingLink,
      setReuseVideoConferencingLink,
      requireConferenceRoom,
      setRequireConferenceRoom,
      defaultPrivate,
      setDefaultPrivate,
      location,
      setLocation,
      defaultAssigneeType,
    };
  }, [
    defaultInterviewPanelRequirement,
    interviewRequirements,
    selectedInterviewRequirement,
    manualAvailabilities,
    view,
    pushView,
    goBack,
    resetView,
    viewStack.length,
    updateInterviewRequirement,
    onEditInterviewRequirement,
    onRemoveInterviewerRequirement,
    notes,
    assignee,
    priority,
    diffDescription,
    autoAssignOnAvailabilityReceiptConfig,
    requireVideoConferencing,
    setRequireVideoConferencing,
    algorithmSettings,
    setAlgorithmSettings,
    reuseVideoConferencingLink,
    requireConferenceRoom,
    setRequireConferenceRoom,
    defaultPrivate,
    setDefaultPrivate,
    defaultAssigneeType,
    location,
    setLocation,
  ]);

  return (
    <InterviewSelectionFormContext.Provider value={value}>
      {children}
    </InterviewSelectionFormContext.Provider>
  );
}
