import clsx from "clsx";
import { source } from "common-tags";
import { SendEmailParams } from "notifications/emails/sending/EmailService";
import { OutlookConditional } from "server/guide-content/update/email/renderers/utils/OutlookConditional";

import { EmailBody } from "../components/EmailBody";
import { Footer } from "../components/Footer";
import { Header, NotificationCompanyData } from "../components/Header";
import { Image } from "../components/Image";
import { Table } from "../components/Table";
import { renderTxEmail } from "../utils/render-tx-email";

export type NotificationEmailRenderingData = {
  banner?: {
    icon: string;
    iconAltText: string;
    Component: React.ReactNode;
    plainText: string;
    className: string;
  };
  body: {
    Component: React.ReactNode;
    plainText: string;
  };
  action?: {
    url: string;
    label: string;
    buttonLabel?: string;
  } | null;
  contentFooter?: {
    Component: React.ReactNode;
  };
  footer?: {
    text: string;
  };
  meta: {
    title: string;
    subject: string;
    preheader?: string | null;
  };
  company?: NotificationCompanyData;
};

export class NotificationEmailRenderingStrategy {
  private readonly data: NotificationEmailRenderingData;

  private readonly recipient: SendEmailParams["recipient"];

  private readonly sender: SendEmailParams["sender"];

  constructor({
    data,
    recipient,
    sender,
  }: {
    data: NotificationEmailRenderingData;
    recipient: SendEmailParams["recipient"];
    sender: SendEmailParams["sender"];
  }) {
    this.data = data;
    this.recipient = recipient;
    this.sender = sender;
  }

  async renderFullHTML(): Promise<string> {
    return renderTxEmail(
      <EmailBody
        header={<Header company={this.data.company} />}
        page={{
          title: this.data.meta.title,
        }}
        footer={
          this.data.footer ? <Footer text={this.data.footer.text} /> : undefined
        }
        preheader={this.renderPreheader()}
      >
        {this.render()}
      </EmailBody>
    );
  }

  render() {
    const { banner, body, action, contentFooter } = this.data;
    return (
      <tr>
        <td className="px-8 pb-12 sm:px-4 sm:py-8 bg-white rounded-md sm:rounded-none gmail__sm-px-0">
          {banner && (
            <>
              <Table className="w-full">
                <tr>
                  <td className="w-full">
                    <div
                      className={clsx(
                        banner.className,
                        "leading-full w-full rounded-md"
                      )}
                    >
                      <div
                        className={clsx(
                          "text-left inline-block py-2.5 px-4 text-base font-semibold [text-decoration:none]"
                        )}
                      >
                        <OutlookConditional
                          content={`<i style="mso-text-raise: 24px; letter-spacing: 16px">&#8202;</i>`}
                        />
                        <Image
                          src={banner.icon}
                          alt={banner.iconAltText}
                          className="w-5 h-5 align-middle pr-2"
                        />
                        <OutlookConditional
                          content={`<i style="letter-spacing: 6px">&#8202;</i>`}
                        />
                        <span className="mso-text-raise-4 align-middle text-body-md">
                          {banner.Component}
                        </span>
                        <OutlookConditional
                          content={`<i style="letter-spacing: 16px">&#8202;</i>`}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </Table>
              <Table className="w-full">
                <tr role="separator">
                  <td className="h-4 sm:h-4">&nbsp;</td>
                </tr>
              </Table>
            </>
          )}

          <Table className="w-full">
            <tr>
              <td>{body.Component}</td>
            </tr>
          </Table>
          {action && (
            <>
              <Table>
                <tr role="separator">
                  <td className="h-8 sm:h-8">&nbsp;</td>
                </tr>
              </Table>
              <Table>
                {action.buttonLabel && (
                  <tr>
                    <td className="pb-2">{action.buttonLabel}</td>
                  </tr>
                )}
                <tr>
                  <td className="text-left">
                    <div className="leading-full text-left">
                      <a
                        href={action.url}
                        className="inline-block py-3.5 px-8 rounded-md text-base font-semibold text-center [text-decoration:none] text-white bg-purple-500 hover:bg-purple-600 sm:block"
                      >
                        <OutlookConditional
                          content={`<i style="mso-text-raise: 30px; letter-spacing: 24px">&#8202;</i>`}
                        />
                        <span className="mso-text-raise-4">{action.label}</span>
                        <OutlookConditional
                          content={`<i style="letter-spacing: 24px">&#8202;</i>`}
                        />
                      </a>
                    </div>
                  </td>
                </tr>
              </Table>
            </>
          )}
          {contentFooter && (
            <>
              <Table className="w-full">
                <tr>
                  <td>{contentFooter.Component}</td>
                </tr>
              </Table>
            </>
          )}
        </td>
      </tr>
    );
  }

  renderPlainText(): string {
    const { banner, body, action } = this.data;
    return source`
      ${banner && banner.plainText}

      ${body.plainText}

      ${action ? `${action.label}: ${action.url}` : ""}

      ${this.plainTextFooter}
    `;
  }

  renderSubject(): string {
    return this.data.meta.subject;
  }

  renderPreheader(): string | null {
    return this.data.meta.preheader ?? null;
  }

  get plainTextFooter(): string {
    const { footer } = this.data;
    const companyLine1 = "Guide | 2543A 2nd St, Santa Monica, CA 90405";
    const companyLine2 = "Copyright © 2024 IDK Labs, Inc. All Rights Reserved.";

    if (footer) {
      return source`
      ---

      Guide
      
      ${footer.text}

      ${companyLine1}
      ${companyLine2}
      `;
    }

    return source`
      ---

      Guide

      ${companyLine1}
      ${companyLine2}
      `;
  }

  async getSendEmailParams(): Promise<SendEmailParams> {
    return {
      recipient: this.recipient,
      sender: this.sender,
      subject: this.renderSubject(),
      html: await this.renderFullHTML(),
      text: this.renderPlainText(),
    };
  }
}
