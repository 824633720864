import { useLazyQuery, useSubscription } from "@apollo/client";
import { Button as AriaButton } from "@ariakit/react";
import Badge from "@resource/atlas/badge/Badge";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasBuilding,
  atlasPlus,
  atlasSearch,
  atlasTemplate,
} from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { GroupLabel, Separator } from "@resource/atlas/menu/Menu";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { AtlasPopoverState, Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import TextField from "@resource/atlas/textfield/TextField";
import { EditTemplateDialog } from "client/scheduling-task-workflows/EditTemplateDialog";
import { getAtssyncInfo } from "client/utils/atssync";
import { postTemplateTypeToDisplayText } from "client/utils/postTemplate";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import { gql } from "generated/graphql-codegen";
import {
  AtssyncAccountType,
  PostTemplateForSelectionFragment,
  PostTemplateForUseFragment,
  PostTemplateType,
} from "generated/graphql-codegen/graphql";
import { LexicalSchema } from "modules/lexical/shared/utils/types";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import useQuery from "utils/useQuery";
import { z } from "zod";

gql(`
fragment PostTemplateForSelection on PostTemplate {
  id
  name
  type
  atssyncEmailTemplate {
    id
    account {
      id
      type
    }
  }
}
`);

// Not ideal, but this whole component is coupled to the fragment right now.
// Need to redefine a zod schema for use as part of forms
export const PostTemplateForUseFragmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  title: z.string().nullable(),
  titleData: LexicalSchema,
  displaySubject: z.string(),
  data: LexicalSchema.nullable(),
  type: z.nativeEnum(PostTemplateType),
  dataSynced: LexicalSchema.nullable(),
  isOverridden: z.boolean().nullable(),
  atssyncEmailTemplate: z
    .object({
      id: z.string(),
      name: z.string(),
      atsUrl: z.string(),
      account: z.object({
        id: z.string(),
        type: z.nativeEnum(AtssyncAccountType),
      }),
    })
    .nullable(),
  updatedAt: z.string(),
  updatedBy: z.object({
    id: z.string(),
    user: z.object({
      id: z.string(),
      name: z.string(),
      imageUrl: z.string(),
    }),
  }),
  department: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
});

gql(`
fragment PostTemplateForUse on PostTemplate {
  id
  name
  title
  titleData
  displaySubject
  data
  type
  dataSynced
  isOverridden
  atssyncEmailTemplate {
    id
    name
    atsUrl
    account {
      id
      type
    }
  }
  updatedAt
  updatedBy {
    id
    user {
      id
      name: fullName
      imageUrl
    }
  }
  department {
    id
    name
  }
}`);

const MY_POST_TEMPLATE_SELECT_QUERY = gql(`
  query MyPostTemplatesForPostTemplateSelect(
    $types: [PostTemplateType!]
  ) {
    currentOrganization {
      id
      paginatedPostTemplates(
        where: {
          includeAll: false
          types: $types
        }
      ) {
        id
        ...PostTemplateForSelection
      }
    }
  }
`);

const RECENT_POST_TEMPLATE_SELECT_QUERY = gql(`
  query RecentPostTemplatesForPostTemplateSelect(
    $limit: Int
    $types: [PostTemplateType!]
  ) {
    currentUser {
      id
      currentUserMembership {
        id
        recentPostTemplates(
          limit: $limit
          types: $types
        ) {
          id
          ...PostTemplateForSelection
        }
      }
    }
  }
`);

const POST_TEMPLATE_META_SELECT_QUERY = gql(`
  query PostTemplatesMetaForPostTemplateSelect {
    currentOrganization {
      id
      name
      defaultAvatarImageUrl
      companyLogoUrl
      enableICSFiles
      schedulingPostTemplateDefaults {
        id
        candidateInterviewConfirmationPostTemplate {
          id
        }
        requestAdditionalAvailabilityPostTemplate {
          id
        }
        requestAvailabilityPostTemplate {
          id
        }
        interviewerInterviewConfirmationPostTemplate {
          id
        }
        candidateRescheduleInterviewConfirmationPostTemplate {
          id
        }
        interviewerRescheduleInterviewConfirmationPostTemplate {
          id
        }
      }
    }
  }
`);

const FETCH_TEMPLATES_FOR_POST_TEMPLATE_SELECT_QUERY = gql(`
  query PostTemplatesForPostTemplateSelect(
    $search: String
    $limit: Int
    $types: [PostTemplateType!]
  ) {
    currentOrganization {
      id
      postTemplates(
        where: {
          search: $search
          types: $types
          status: ACTIVE
        }
        limit: $limit
      ) {
        id
        ...PostTemplateForSelection
      }
      paginatedPostTemplatesCount(
        where: {
          types: $types
          status: ACTIVE
        }
      )
    }
  }
`);

const POST_TEMPLATE_FOR_SELECT_QUERY = gql(`
  query SelectedPostTemplate(
    $id: ID!
  ) {
    currentOrganization {
      id
      postTemplateById(id: $id) {
        id
        ...PostTemplateForUse
      }
    }
  }
`);

const TEMPLATE_CREATED_SUBSCRIPTION = gql(`
  subscription TemplateCreatedSubscription {
    postTemplateCreated {
      id
    }
  }
`);

const postTemplateTypeToDisplayTextOverrides: {
  [type in PostTemplateType]: string;
} = {
  ...postTemplateTypeToDisplayText,
  [PostTemplateType.request_additional_availability]:
    postTemplateTypeToDisplayText[PostTemplateType.request_availability],
  [PostTemplateType.candidate_reschedule_interview_confirmation]:
    postTemplateTypeToDisplayText[
      PostTemplateType.candidate_interview_confirmation
    ],
};

type TemplateSelectProps = {
  onSelect: (template: PostTemplateForUseFragment) => void;
  placeholderText?: string;
  Trigger?: JSX.Element;
  popoverState?: AtlasPopoverState;
  disabled?: boolean;
  triggerProps?: AtlasSelectTriggerProps;
  type?: PostTemplateType | null;
  selectedTemplate?: PostTemplateForSelectionFragment | null;
  onTemplateEditCompleted?: (postTemplate: PostTemplateForUseFragment) => void;
};

function SelectTemplateDisplay({
  template,
  isOrgDefault,
  onTemplateEditCompleted,
}: {
  template: PostTemplateForSelectionFragment;
  isOrgDefault: boolean;
  onTemplateEditCompleted?: (postTemplate: PostTemplateForUseFragment) => void;
}) {
  const editTemplateDialogStore = useDialogStore();

  return (
    <div className="flex items-center justify-between">
      <span
        className="flex-shrink truncate text-body-md text-dark"
        title={template.name}
      >
        {template.name}
        {isOrgDefault ? <Badge className="ml-2">Org default</Badge> : null}
      </span>
      {template.atssyncEmailTemplate && !onTemplateEditCompleted && (
        <Icon
          content={
            getAtssyncInfo(template.atssyncEmailTemplate.account.type).icon
          }
        />
      )}
      {onTemplateEditCompleted && (
        <>
          <StopPropagation>
            <AriaButton
              className="text-purple-500 text-body-md"
              onClick={editTemplateDialogStore.show}
            >
              Edit template
            </AriaButton>
            <EditTemplateDialog
              store={editTemplateDialogStore}
              postTemplateId={template.id}
              onUpdate={onTemplateEditCompleted}
            />
          </StopPropagation>
        </>
      )}
    </div>
  );
}

export function PostTemplateLoader({
  onSelect,
  placeholderText,
  Trigger,
  popoverState: popoverStateInput,
  triggerProps,
  disabled,
  type,
  selectedTemplate,
  onTemplateEditCompleted,
}: TemplateSelectProps) {
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");
  const popoverStateInternal = usePopoverState();
  const popoverState = useMemo(
    () => popoverStateInput ?? popoverStateInternal,
    [popoverStateInput, popoverStateInternal]
  );

  const { data: subscriptionData } = useSubscription(
    TEMPLATE_CREATED_SUBSCRIPTION
  );

  const types = useMemo(() => {
    if (!type) {
      return null;
    }

    const requestAvailabilityTypes = [
      PostTemplateType.request_availability,
      PostTemplateType.request_additional_availability,
    ];
    if (requestAvailabilityTypes.includes(type)) {
      return requestAvailabilityTypes;
    }

    const confirmationTypes = [
      PostTemplateType.candidate_interview_confirmation,
      PostTemplateType.candidate_reschedule_interview_confirmation,
    ];
    if (confirmationTypes.includes(type)) {
      return confirmationTypes;
    }

    return [type];
  }, [type]);

  const {
    data,
    loading: loadingAll,
    refetch,
  } = useQuery(FETCH_TEMPLATES_FOR_POST_TEMPLATE_SELECT_QUERY, {
    variables: {
      search: debouncedTerm,
      limit: 25,
      types,
    },
  });
  const { data: myTemplatesData, loading: loadingMine } = useQuery(
    MY_POST_TEMPLATE_SELECT_QUERY,
    {
      variables: {
        types,
      },
      skip: !types,
    }
  );
  const { data: recentData, loading: loadingRecent } = useQuery(
    RECENT_POST_TEMPLATE_SELECT_QUERY,
    {
      variables: {
        limit: 3,
        types,
      },
      skip: !types,
    }
  );
  const { data: orgDefaultData } = useQuery(POST_TEMPLATE_META_SELECT_QUERY);

  useEffect(() => {
    if (subscriptionData?.postTemplateCreated?.id) {
      refetch();
    }
  }, [subscriptionData, refetch]);

  const [fetchTemplate, { loading: loadingFetch }] = useLazyQuery(
    POST_TEMPLATE_FOR_SELECT_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );
  const templates = useMemo(
    () => data?.currentOrganization?.postTemplates ?? [],
    [data]
  );

  const loading = useMemo(
    () => loadingAll || loadingFetch || loadingRecent || loadingMine,
    [loadingAll, loadingFetch, loadingRecent, loadingMine]
  );

  const isOrgDefault = useCallback(
    (id: string) => {
      const defaults =
        orgDefaultData?.currentOrganization?.schedulingPostTemplateDefaults;
      if (!defaults) {
        return false;
      }

      const {
        id: _discardId,
        __typename: _discardTypename,
        ...rest
      } = defaults;
      return Object.values(rest).some((template) => {
        return template?.id === id;
      });
    },
    [orgDefaultData?.currentOrganization?.schedulingPostTemplateDefaults]
  );

  const totalTemplateCount = useMemo(() => {
    return data?.currentOrganization?.paginatedPostTemplatesCount ?? 0;
  }, [data?.currentOrganization?.paginatedPostTemplatesCount]);

  const groupedTemplates = useMemo(() => {
    return templates.reduce((groups, template) => {
      const displayType = postTemplateTypeToDisplayText[template.type];
      if (!groups[displayType]) {
        // eslint-disable-next-line no-param-reassign
        groups[displayType] = [];
      }
      groups[displayType].push(template);
      return groups;
    }, {} as { [type: string]: PostTemplateForSelectionFragment[] });
  }, [templates]);

  const options = useMemo(() => {
    const headerOptions = [
      <OptionItem
        key="create-new-template"
        leadingContent={<Icon className="text-subtle" content={atlasPlus} />}
        size="compact"
        onClick={() => {
          window.open(
            `/templates/messages?create=${type}`,
            "_blank",
            "noopener,noreferrer"
          );
        }}
      >
        Create a new {type ? postTemplateTypeToDisplayText[type] : ""} template
      </OptionItem>,
      <OptionItem
        key="view-templates"
        leadingContent={
          <Icon className="text-subtle" content={atlasBuilding} />
        }
        size="compact"
        onClick={() => {
          window.open("/templates/messages", "_blank", "noopener,noreferrer");
        }}
      >
        View and manage all message templates
      </OptionItem>,
      <OptionSeparator key="header-separator" />,
    ];
    const footerOptions = [
      <OptionItem disableInteractivity key="search-label" size="compact">
        <span className="text-subtle">
          Search all {totalTemplateCount}{" "}
          {type ? postTemplateTypeToDisplayTextOverrides[type] : ""} templates
          above...
        </span>
      </OptionItem>,
    ];

    const recent =
      recentData?.currentUser?.currentUserMembership?.recentPostTemplates;
    const mine = myTemplatesData?.currentOrganization?.paginatedPostTemplates;

    if (!searchTerm && (recent?.length || mine?.length)) {
      const templateOptions: ReactNode[] = [];
      if (recent?.length) {
        templateOptions.push(
          <GroupLabel key="recent-label">Frequently used</GroupLabel>,
          ...(recentData?.currentUser?.currentUserMembership?.recentPostTemplates?.map(
            (template) => {
              return (
                <OptionItem
                  key={`recent-${template.id}`}
                  onClick={async () => {
                    const { data: result } = await fetchTemplate({
                      variables: {
                        id: template.id,
                      },
                    });
                    if (result?.currentOrganization?.postTemplateById) {
                      onSelect(result?.currentOrganization?.postTemplateById);
                      popoverState.hide();
                    }
                  }}
                  size="compact"
                  leadingContent={
                    <Icon className="text-subtle" content={atlasTemplate} />
                  }
                >
                  <SelectTemplateDisplay
                    template={template}
                    isOrgDefault={isOrgDefault(template.id)}
                  />
                </OptionItem>
              );
            }
          ) ?? []),
          <OptionSeparator key="recent-separator" />
        );
      }
      if (mine?.length) {
        templateOptions.push(
          <GroupLabel key="mine-label">My templates</GroupLabel>,
          ...(myTemplatesData?.currentOrganization?.paginatedPostTemplates?.map(
            (template) => {
              return (
                <OptionItem
                  key={`mine-${template.id}`}
                  onClick={async () => {
                    const { data: result } = await fetchTemplate({
                      variables: {
                        id: template.id,
                      },
                    });
                    if (result?.currentOrganization?.postTemplateById) {
                      onSelect(result?.currentOrganization?.postTemplateById);
                      popoverState.hide();
                    }
                  }}
                  size="compact"
                  leadingContent={
                    <Icon className="text-subtle" content={atlasTemplate} />
                  }
                >
                  <SelectTemplateDisplay
                    template={template}
                    isOrgDefault={isOrgDefault(template.id)}
                  />
                </OptionItem>
              );
            }
          ) ?? []),
          <OptionSeparator key="mine-separator" />
        );
      }

      return [...headerOptions, ...templateOptions, ...footerOptions];
    }

    const groupedOptions = Object.entries(groupedTemplates).flatMap(
      ([displayType, templatesForGroup]) => {
        const templateItems = templatesForGroup.map((template) => {
          return (
            <OptionItem
              key={`grouped-${template.id}`}
              onClick={async () => {
                const { data: result } = await fetchTemplate({
                  variables: {
                    id: template.id,
                  },
                });
                if (result?.currentOrganization?.postTemplateById) {
                  onSelect(result?.currentOrganization?.postTemplateById);
                  popoverState.hide();
                }
              }}
              size="compact"
              leadingContent={
                <Icon className="text-subtle" content={atlasTemplate} />
              }
            >
              <SelectTemplateDisplay
                template={template}
                isOrgDefault={isOrgDefault(template.id)}
              />
            </OptionItem>
          );
        });

        return [
          <GroupLabel key={`${displayType}-label`}>{displayType}</GroupLabel>,
          ...(templateItems.length
            ? templateItems
            : [
                <>
                  <OptionSeparator />
                  <p className="px-5 py-2 text-body-md">No results found</p>
                </>,
              ]),
          <Separator key={displayType} />,
        ];
      }
    );

    return [...headerOptions, ...groupedOptions, ...footerOptions];
  }, [
    type,
    totalTemplateCount,
    recentData?.currentUser?.currentUserMembership?.recentPostTemplates,
    myTemplatesData?.currentOrganization?.paginatedPostTemplates,
    searchTerm,
    groupedTemplates,
    isOrgDefault,
    fetchTemplate,
    onSelect,
    popoverState,
  ]);

  // clear search term on popover state close
  useEffect(() => {
    if (!popoverState.open) {
      setSearchTerm("");
    }
  }, [popoverState.open, setSearchTerm]);

  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger disabled={disabled}>
        {Trigger ?? (
          <SelectTrigger
            {...triggerProps}
            className={clsx(
              "flex-1 bg-light-gray-500 text-subtle w-full",
              triggerProps?.className
            )}
            icon={atlasTemplate}
            iconClassName="text-subtle"
          >
            {selectedTemplate ? (
              <SelectTemplateDisplay
                template={selectedTemplate}
                isOrgDefault={false}
                onTemplateEditCompleted={onTemplateEditCompleted}
              />
            ) : (
              placeholderText ?? "Choose a template"
            )}
          </SelectTrigger>
        )}
      </Popover.Trigger>
      <Popover.Content className="min-w-[31.5rem]" hasPadding={false}>
        <TextField
          className="m-2"
          size="small"
          value={searchTerm}
          autoFocus
          icon={atlasSearch}
          placeholder={`Search all ${
            type ? postTemplateTypeToDisplayTextOverrides[type] : ""
          } templates`}
          aria-label="Search"
          onChange={setSearchTerm}
          isClearable
        />
        <div className="p-[.5rem] pt-0">
          {loading && (
            <>
              <OptionSeparator />
              <div className="flex justify-center">
                <LoadingIndicator size="small" />
              </div>
            </>
          )}
          {!loading && <>{options}</>}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
