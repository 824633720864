import { notificationTypeSchemaDefinitions } from "modules/notifications/schemas";
import { NotificationType } from "modules/notifications/types";

import { commentMentionRenderer } from "./CommentMention";
import { importantAriaMessageRenderer } from "./ImportantAriaMessage";
import { schedulingRequestAssignedRenderer } from "./SchedulingRequestAssigned";

export const NotificationAppRenderers = {
  [NotificationType.CommentMention]: commentMentionRenderer,
  [NotificationType.SchedulingRequestAssigned]:
    schedulingRequestAssignedRenderer,
  [NotificationType.ImportantAriaMessage]: importantAriaMessageRenderer,
} as const;

export function renderNotification<T extends NotificationType>({
  type,
  data: rawData,
}: {
  type: T;
  data: unknown;
}) {
  const renderer = NotificationAppRenderers[type];
  const data = notificationTypeSchemaDefinitions[type].resolved.parse(rawData);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return renderer.render(data);
}

export function getNotificationAvatar<T extends NotificationType>({
  type,
  data: rawData,
}: {
  type: T;
  data: unknown;
}) {
  const renderer = NotificationAppRenderers[type];
  const data = notificationTypeSchemaDefinitions[type].resolved.parse(rawData);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return renderer.avatar?.(data);
}
