import React from "react";
import { OutlookConditional } from "server/guide-content/update/email/renderers/utils/OutlookConditional";

import { Image } from "./Image";
import { Table } from "./Table";

export function Footer({ text }: { text?: string }) {
  return (
    <Table className="w-full text-left bg-light-gray-100 rounded-b-md">
      <tr>
        <td className="text-left text-gray-100 text-sm p-8 sm:px-4 sm:py-4 gmail__sm-px-0">
          <Table className="w-full">
            <tr>
              <td className="pb-6">
                <OutlookConditional
                  content={`<i style="mso-text-raise: 24px; letter-spacing: 16px">&#8202;</i>`}
                />
                <Image
                  src="https://ucarecdn.com/11d45043-cdc0-44ac-b6d5-29273dac3b22/"
                  alt="Guide"
                  className="w-[4.875rem]"
                />
                <OutlookConditional
                  content={`<i style="letter-spacing: 6px">&#8202;</i>`}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Table className="w-full">
                  {text && (
                    <tr>
                      <td className="text-body-sm pb-3">{text}</td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <p className="mt-0 mb-0 leading-5">
                        IDK Labs, Inc. d/b/a Guide | 2543A 2nd St, Santa Monica,
                        CA 90405
                        <br />
                        Copyright © 2024 IDK Labs, Inc. All Rights Reserved.
                      </p>
                    </td>
                  </tr>
                </Table>
              </td>
            </tr>
          </Table>
        </td>
      </tr>
    </Table>
  );
}
