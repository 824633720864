export const timeIsEqual = (time1: string | null, time2: string | null) => {
  if (time1 === null && time2 === null) {
    return true;
  }

  if (time1 === null || time2 === null) {
    return false;
  }

  return new Date(time1).getTime() === new Date(time2).getTime();
};

export const durationIsEqual = ({
  startTime1,
  startTime2,
  endTime1,
  endTime2,
}: {
  startTime1: string | null;
  startTime2: string | null;
  endTime1: string | null;
  endTime2: string | null;
}) => {
  if (
    startTime1 === null &&
    endTime1 === null &&
    startTime2 === null &&
    endTime2 === null
  ) {
    return true;
  }

  if (
    startTime1 === null ||
    startTime2 === null ||
    endTime1 === null ||
    endTime2 === null
  ) {
    return false;
  }

  return (
    new Date(startTime1).getTime() - new Date(endTime1).getTime() ===
    new Date(startTime2).getTime() - new Date(endTime2).getTime()
  );
};
