/* eslint-disable import/prefer-default-export */
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { NodeKey } from "lexical";
import { useMemo, useRef, useState } from "react";

import { useEvent } from "../../../../__utils/react";
import { $getSelectedNode } from "../lib/utils";

const DEFAULT_LINK_CONTENT = "https://";

export function useLinkContext() {
  const [editor] = useLexicalComposerContext();

  const [isLink, setIsLink] = useState(false);
  const [linkUrl, setLinkUrl] = useState<string | undefined>();
  const [linkNodeKey, setLinkNodeKey] = useState<NodeKey | undefined>();

  const linkElementRef = useRef<HTMLAnchorElement | null>(null);
  const cachedLatestLinkRectRef = useRef<DOMRect>();
  const getLatestLinkRect = useEvent(() => {
    const currentRect = linkElementRef.current?.getBoundingClientRect();
    if (currentRect) {
      cachedLatestLinkRectRef.current = currentRect;
      return currentRect;
    }
    return cachedLatestLinkRectRef.current ?? null;
  });

  function reset() {
    setIsLink(false);
    setLinkNodeKey(undefined);
    setLinkUrl(undefined);
  }

  const updateListener = useEvent(() => {
    const selectedNode = $getSelectedNode();

    if (!selectedNode) return reset();

    let linkNode: LinkNode | undefined;
    const parent = selectedNode.getParent();
    if ($isLinkNode(parent)) linkNode = parent;
    else if ($isLinkNode(selectedNode)) linkNode = selectedNode;

    if (!linkNode) return reset();

    setIsLink(true);
    setLinkNodeKey(linkNode.getKey());
    setLinkUrl(linkNode.getURL());

    linkElementRef.current = editor.getElementByKey(
      linkNode.getKey()
    ) as HTMLAnchorElement | null;

    return undefined;
  });

  const toggleLink = useEvent(() =>
    editor.dispatchCommand(
      TOGGLE_LINK_COMMAND,
      isLink ? null : DEFAULT_LINK_CONTENT
    )
  );

  const value = useMemo(
    () => ({
      isLink,
      linkNodeKey,
      linkUrl,
      getLatestLinkRect,
      toggleLink,
    }),
    [getLatestLinkRect, isLink, linkNodeKey, linkUrl, toggleLink]
  );

  return [updateListener, value] as const;
}
