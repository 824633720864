import { Button, MenuButton, useMenuStore } from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasBell,
  atlasChevronDown,
  atlasRingCheck,
  atlasRingPerson,
  atlasRingQuestion,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu-v2";
import { useAuthContext } from "auth/context";
import { GuideLogo } from "client/components/generic/misc/GuideLogo";
import { formatCount } from "client/utils/counts";
import clsx from "clsx";
import { useAppNavAccountMenuItems } from "components/hooks/useAppNavAccountMenuItems";
import { useHelpMenuItems } from "components/hooks/useHelpMenuItems";
import { RoleEnum } from "enums/role-enum";
import { signOut } from "next-auth/react";
import { useCallback, useMemo } from "react";

import { EXTENSION_DIMS } from "./dimensions";
import { useExtensionController } from "./ExtensionControllerProvider";
import { useUserCounts } from "./UserCountsProvider";

interface BadgeProps {
  count?: number;
  isActive: boolean;
}

function NavigatorBadge({ count = 0, isActive }: BadgeProps) {
  if (count <= 0) return null;

  return (
    <span
      className={clsx(
        "absolute group-hover:[box-shadow:0_0_0_1.5px_#625B79] flex items-center justify-center min-w-3 h-3 text-[8px] font-medium rounded-full px-1 top-[.3125rem] right-[.3125rem] bg-purple-600 text-white transition-[box-shadow]",
        isActive
          ? "[box-shadow:0_0_0_1.5px_#625B79]"
          : "[box-shadow:0_0_0_1.5px_#1F1440]"
      )}
    >
      {formatCount(count)}
    </span>
  );
}

export function FloatingPopoverNavigator({
  open = false,
  onToggleOpen,
}: {
  open: boolean;
  onToggleOpen: () => void;
}) {
  const { navigationState, navigate, sendMessageToParent } =
    useExtensionController();
  const activeTab = navigationState.view;
  const { user, onLogout, highestRole } = useAuthContext();

  const logout = useCallback(async () => {
    sendMessageToParent({
      command: "update-sidepanel-iframe-initialized",
      value: false,
    });
    await onLogout();

    signOut();
  }, [onLogout, sendMessageToParent]);

  const { unreadNotificationCount } = useUserCounts();
  const membership = user?.currentUserMembership;

  const width = useMemo(() => {
    if (highestRole === RoleEnum.Interviewer)
      return EXTENSION_DIMS.floatingPopoverNavigator.open.width.interviewer;
    return EXTENSION_DIMS.floatingPopoverNavigator.open.width.hiringTeam;
  }, [highestRole]);

  const accountMenuStore = useMenuStore({
    placement: "top-end",
  });
  const helpMenuStore = useMenuStore({
    placement: "top-end",
  });

  const menuItems = useAppNavAccountMenuItems({
    target: "_blank",
    hideDashboard: false,
    logout,
  });

  const helpMenuItems = useHelpMenuItems();

  const handleTabClick = useCallback(
    (view: "notifications" | "tasks" | "candidateProfile") => {
      navigate({
        view,
      });
    },
    [navigate]
  );

  return (
    <>
      <div
        style={{
          width: open
            ? width
            : EXTENSION_DIMS.floatingPopoverNavigator.closed.width,
          height: open
            ? EXTENSION_DIMS.floatingPopoverNavigator.open.height
            : EXTENSION_DIMS.floatingPopoverNavigator.closed.height,
          right: EXTENSION_DIMS.panel.padding.right,
          transition: "width 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        }}
        id="guide-extension-popover-navigator"
        className="rounded-full fixed overflow-hidden cursor-default tours__global-extension-navigator"
      >
        <div
          style={{
            width,
            height: EXTENSION_DIMS.floatingPopoverNavigator.open.height,
            position: "absolute",
            right: 0,
            transition: "transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
            transform: open
              ? "translateX(0)"
              : `translateX(${
                  width - EXTENSION_DIMS.floatingPopoverNavigator.closed.width
                }px)`,
          }}
          className="bg-indigo-500 flex items-center shadow-lg rounded-full"
        >
          <div className="flex items-center justify-end gap-1 px-2">
            {open && (
              <>
                <a
                  href={process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT}
                  target="_blank"
                  rel="noreferrer"
                  className="w-[6.75rem] flex items-center justify-center"
                >
                  <GuideLogo variant="light" />
                </a>
                {/* Only show tabs and divider for non-interviewers */}
                {highestRole !== RoleEnum.Interviewer && (
                  <>
                    {/* Notification Button */}
                    <Button
                      className={clsx(
                        "group w-10 h-10 rounded-full flex items-center justify-center relative",
                        activeTab === "notifications" && "bg-indigo-300",
                        "hover:bg-indigo-300 transition-colors"
                      )}
                      onClick={() => handleTabClick("notifications")}
                    >
                      <Icon
                        content={atlasBell}
                        className="text-white w-5 h-5"
                      />
                      <NavigatorBadge
                        count={unreadNotificationCount}
                        isActive={activeTab === "notifications"}
                      />
                    </Button>

                    {/* Scheduling Tasks Button */}
                    <Button
                      className={clsx(
                        "group w-10 h-10 rounded-full flex items-center justify-center relative",
                        activeTab === "tasks" && "bg-indigo-300",
                        "hover:bg-indigo-300 transition-colors"
                      )}
                      onClick={() => handleTabClick("tasks")}
                    >
                      <Icon
                        content={atlasRingCheck}
                        className="text-white w-5 h-5"
                      />
                      <NavigatorBadge isActive={activeTab === "tasks"} />
                    </Button>

                    {/* Candidate Profile Button */}
                    <Button
                      className={clsx(
                        "w-10 h-10 rounded-full flex items-center justify-center",
                        activeTab === "candidateProfile" && "bg-indigo-300",
                        "hover:bg-indigo-300 transition-colors"
                      )}
                      onClick={() => handleTabClick("candidateProfile")}
                    >
                      <Icon
                        content={atlasRingPerson}
                        className="text-white w-5 h-5"
                      />
                    </Button>

                    {/* Separator */}
                    <div className="w-[1px] h-8 bg-white opacity-20" />
                  </>
                )}

                {/* Help Menu */}
                <Menu.Root store={helpMenuStore}>
                  <MenuButton className="w-10 h-10 rounded-full flex items-center justify-center hover:bg-indigo-300 transition-colors">
                    <Icon
                      content={atlasRingQuestion}
                      className="text-white w-5 h-5"
                    />
                  </MenuButton>
                  <Menu.Menu fitViewport>{helpMenuItems}</Menu.Menu>
                </Menu.Root>

                {/* Account Menu */}
                {membership ? (
                  <Menu.Root store={accountMenuStore}>
                    <MenuButton className="w-10 h-10 flex items-center justify-center hover:bg-indigo-300 transition-colors rounded-full">
                      <Avatar
                        image={membership.imageUrl}
                        name={membership.name}
                        size="xs"
                        disableTooltip
                        aria-label="Open account menu"
                      />
                    </MenuButton>
                    <Menu.Menu fitViewport>
                      {/* Header */}
                      <div className="px-3 py-2 space-y-1">
                        <p className="text-body-md-heavy text-dark truncate">
                          {user?.fullName ?? ""}
                        </p>
                        <p className="text-body-sm text-subtle truncate">
                          {user?.primaryEmail ?? ""}
                        </p>
                      </div>
                      <Menu.Separator />
                      {menuItems}
                    </Menu.Menu>
                  </Menu.Root>
                ) : (
                  <div className="w-10 flex items-center justify-center">
                    <Avatar size="xs" variant="user" name="" />
                  </div>
                )}
              </>
            )}

            {/* Close Button */}
            <Button
              className="w-10 h-10 rounded-full flex items-center justify-center hover:bg-indigo-300 transition-colors"
              onClick={onToggleOpen}
            >
              <Icon content={atlasChevronDown} className="text-white w-5 h-5" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
