import { UseEditInterviewerSlotDisplayStateProps } from "client/components/interviewer-slots/hooks/useEditInterviewerSlotDisplayState";
import {
  getSmartMatchInterviewerSlot,
  mapInterviewerPoolToSlot,
  mapUserMembershipToSlot,
} from "client/components/interviewer-slots/utils/mapping";
import {
  InterviewerPool,
  InterviewerSlot,
  UserMembershipForForm,
} from "client/components/interviewer-slots/utils/types";
import { useCallback } from "react";
import { v4 } from "uuid";

import { UpsertScheduledInterviewFormState } from "./useUpsertScheduledInterviewFormState";

export function useManageInterviewerSlotsCollection({
  state,
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const { form } = state;
  const updateInterviewerSlotsCollection = useCallback(
    (
      interviewerSlotsOrUpdater:
        | InterviewerSlot[]
        | ((prev: InterviewerSlot[]) => InterviewerSlot[])
    ) => {
      if (typeof interviewerSlotsOrUpdater === "function") {
        const currInterviewerSlots = form.getValues(
          "scheduledInterview.interviewerSlots"
        );

        form.setValue(
          "scheduledInterview.interviewerSlots",
          interviewerSlotsOrUpdater(currInterviewerSlots),
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      } else {
        form.setValue(
          "scheduledInterview.interviewerSlots",
          interviewerSlotsOrUpdater,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
    },
    [form]
  );

  const onAddInterviewer = useCallback(
    (user: UserMembershipForForm) => {
      updateInterviewerSlotsCollection((prevSlots) => [
        ...prevSlots,
        mapUserMembershipToSlot(user),
      ]);
    },
    [updateInterviewerSlotsCollection]
  );

  const onAddPool = useCallback(
    (pool: InterviewerPool) => {
      updateInterviewerSlotsCollection((prevSlots) => [
        ...prevSlots,
        mapInterviewerPoolToSlot(pool),
      ]);
    },
    [updateInterviewerSlotsCollection]
  );

  const onAddSmartMatch = useCallback(() => {
    updateInterviewerSlotsCollection((prevSlots) => [
      ...prevSlots,
      getSmartMatchInterviewerSlot(),
    ]);
  }, [updateInterviewerSlotsCollection]);

  const onRemoveInterviewer = useCallback(
    ({
      interviewerSlotId,
      isShadower,
    }: {
      interviewerSlotId: string;
      isShadower: boolean;
    }) => {
      updateInterviewerSlotsCollection((prevSlots) => {
        const removingFromSlot = prevSlots.find(
          (slot) => slot.id === interviewerSlotId
        );

        if (!removingFromSlot) {
          return prevSlots;
        }

        const removingInterviewer = !isShadower;
        const removingShadower = isShadower;

        const slotAfterRemoval: InterviewerSlot = {
          ...removingFromSlot,
          interviewer: removingInterviewer
            ? null
            : removingFromSlot.interviewer,
          shadowingInterviewer: removingShadower
            ? null
            : removingFromSlot.shadowingInterviewer,
        };

        return prevSlots.map((slot) =>
          slot.id === interviewerSlotId ? slotAfterRemoval : slot
        );
      });
    },
    [updateInterviewerSlotsCollection]
  );

  const onRemoveInterviewerSlot = useCallback(
    (interviewerSlotId: string) => {
      updateInterviewerSlotsCollection((prevSlots) =>
        prevSlots.filter((slot) => slot.id !== interviewerSlotId)
      );
    },
    [updateInterviewerSlotsCollection]
  );

  return {
    onAddInterviewer,
    onAddPool,
    onRemoveInterviewer,
    onRemoveInterviewerSlot,
    onAddSmartMatch,
  };
}

export function useManageIndividualInterviewerSlot({
  state,
  interviewerSlotId,
}: {
  state: UpsertScheduledInterviewFormState;
  interviewerSlotId: string;
}): Pick<
  UseEditInterviewerSlotDisplayStateProps,
  | "onChangeTrainingEnabled"
  | "onChangeExternalAlgorithmEnabled"
  | "onSelectUser"
  | "onRemoveUser"
  | "onRemoveInterviewerSlot"
  | "onTagFiltersChange"
> {
  const { form } = state;
  const { onRemoveInterviewer, onRemoveInterviewerSlot: onRemoveSlot } =
    useManageInterviewerSlotsCollection({
      state,
    });
  const updateSingleInterviewerSlot = useCallback(
    (
      newSlotOrUpdater:
        | InterviewerSlot
        | ((prev: InterviewerSlot) => InterviewerSlot)
    ) => {
      const currInterviewerSlots = form.getValues(
        "scheduledInterview.interviewerSlots"
      );
      const slotIndex = currInterviewerSlots.findIndex(
        (slot) => slot.id === interviewerSlotId
      );

      if (slotIndex === -1) {
        return;
      }

      if (typeof newSlotOrUpdater === "function") {
        const prevSlot = currInterviewerSlots[slotIndex];

        form.setValue(
          `scheduledInterview.interviewerSlots.${slotIndex}`,
          newSlotOrUpdater(prevSlot),
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      } else {
        form.setValue(
          `scheduledInterview.interviewerSlots.${slotIndex}`,
          newSlotOrUpdater,
          {
            shouldDirty: true,
            shouldValidate: true,
          }
        );
      }
    },
    [form, interviewerSlotId]
  );

  const onSelectUser: UseEditInterviewerSlotDisplayStateProps["onSelectUser"] =
    useCallback(
      ({ userMembership, isShadower }) => {
        updateSingleInterviewerSlot(
          (prevSlot): InterviewerSlot => ({
            ...prevSlot,
            ...(!isShadower
              ? {
                  interviewer: {
                    id: v4(),
                    responseStatus: null,
                    userMembership,
                  },
                }
              : {
                  shadowingInterviewer: {
                    id: v4(),
                    responseStatus: null,
                    userMembership,
                  },
                }),
          })
        );
      },
      [updateSingleInterviewerSlot]
    );

  const onChangeTrainingEnabled: UseEditInterviewerSlotDisplayStateProps["onChangeTrainingEnabled"] =
    useCallback(
      (trainingEnabled: boolean) => {
        updateSingleInterviewerSlot(
          (prevSlot): InterviewerSlot => ({
            ...prevSlot,
            trainingEnabled,
            shadowingInterviewer: trainingEnabled
              ? prevSlot.shadowingInterviewer
              : null,
          })
        );
      },
      [updateSingleInterviewerSlot]
    );

  const onChangeExternalAlgorithmEnabled: UseEditInterviewerSlotDisplayStateProps["onChangeExternalAlgorithmEnabled"] =
    useCallback(
      (externalAlgorithmEnabled: boolean) => {
        updateSingleInterviewerSlot(
          (prevSlot): InterviewerSlot => ({
            ...prevSlot,
            externalAlgorithmEnabled,
          })
        );
      },
      [updateSingleInterviewerSlot]
    );

  const onRemoveUser: UseEditInterviewerSlotDisplayStateProps["onRemoveUser"] =
    useCallback(
      ({ isShadower }) => {
        onRemoveInterviewer({
          interviewerSlotId,
          isShadower,
        });
      },
      [interviewerSlotId, onRemoveInterviewer]
    );

  const onRemoveInterviewerSlot: UseEditInterviewerSlotDisplayStateProps["onRemoveInterviewerSlot"] =
    useCallback(
      () => onRemoveSlot(interviewerSlotId),
      [onRemoveSlot, interviewerSlotId]
    );

  const onTagFiltersChange: UseEditInterviewerSlotDisplayStateProps["onTagFiltersChange"] =
    useCallback(
      (tagFilters) => {
        updateSingleInterviewerSlot((prevSlot) => ({
          ...prevSlot,
          tagFilters,
        }));
      },
      [updateSingleInterviewerSlot]
    );

  return {
    onSelectUser,
    onChangeTrainingEnabled,
    onChangeExternalAlgorithmEnabled,
    onRemoveUser,
    onRemoveInterviewerSlot,
    onTagFiltersChange,
  };
}
