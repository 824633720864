import { InterviewerMultiSelector } from "client/app/(account settings)/tags/__components/InterviewerSelector";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { UserForTagSelectionFragment } from "generated/graphql-codegen/graphql";
import { formatEntity } from "shared/constants/entities";

type Props = {
  onChange: (value: UserForTagSelectionFragment[]) => void;
  selected: UserForTagSelectionFragment[];
  placement?: "top" | "bottom";
};
export function OptionalParticipantsSelect({
  onChange,
  selected,
  placement = "top",
}: Props) {
  return (
    <FormGroup
      label="Optional participants"
      subText={`Optional participants will not be shown on the ${formatEntity(
        "guide"
      )}.`}
    >
      <InterviewerMultiSelector
        selected={selected}
        onSelectionChanged={onChange}
        popoverStateProps={{
          placement,
        }}
        placeholderText="Select users"
      />
    </FormGroup>
  );
}
