/* eslint-disable import/prefer-default-export */
import type { ReactElement } from "react";

import type { HTMLAttributes } from "../../../../__utils/html";

export function createSlot(
  slotContent: ReactElement | undefined,
  props?: HTMLAttributes<"div">
) {
  if (!slotContent) return null;

  return <div {...props}>{slotContent}</div>;
}
