import { Button } from "@resource/atlas/button/Button";
import { atlasClose } from "@resource/atlas/icons";
import { Popover } from "@resource/atlas/popover";
import { useEffect, useMemo } from "react";

import { InterviewTitle } from "../guide/interviews/InterviewTitle/InterviewTitle";
import { useCalendarContext } from "./context";

function isLexicalContent(title: string) {
  try {
    JSON.parse(title);
    return true;
  } catch (err) {
    return false;
  }
}

export function EventDetailsPopover() {
  const {
    events,
    focusedEventId,
    eventDetailsPopoverState,
    setFocusedEventId,
  } = useCalendarContext();

  const event = useMemo(() => {
    return events.find((e) => e.id === focusedEventId);
  }, [events, focusedEventId]);

  useEffect(() => {
    if (!eventDetailsPopoverState.open) {
      setFocusedEventId(null);
    }
  }, [eventDetailsPopoverState.open, setFocusedEventId]);

  const description = useMemo(() => {
    if (!event) {
      return null;
    }

    return (
      <>
        {event.startTime.toFormat("EEEE, MMMM d")} ⋅{" "}
        {event.startTime.toFormat("h:mma")} – {event.endTime.toFormat("h:mma")}
      </>
    );
  }, [event]);

  const isLexical = event ? isLexicalContent(event.title) : false;

  if (!event) {
    return null;
  }

  return (
    <Popover.Content portal>
      <div className="flex justify-end items-center w-full px-3">
        <Button
          negativeMargin="right"
          isGhost
          icon={atlasClose}
          onClick={() => eventDetailsPopoverState.hide()}
          size="xs"
        />
      </div>
      <Popover.Heading className="text-body-lg-heavy">
        {isLexical ? <InterviewTitle interview={event} /> : event.title}
      </Popover.Heading>
      <Popover.Description className="text-body-md">
        {description}
      </Popover.Description>
    </Popover.Content>
  );
}
