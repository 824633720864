import { UnscheduledInterviewForCard } from "client/components/interview-requirements/UnscheduledInterviewCard/utils/types";
import {
  ScheduledInterviewFieldChangeData,
  StagedScheduledInterview,
  UpsertScheduledInterview,
} from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { UpsertScheduledInterviewGroupForSchedulerMutation } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";

/** Fields needed specific to scheduler, not needed by child components */
type SchedulerAdditionalFields = {
  pendingRescheduleInterviewRequirement: {
    id: string;
  } | null;
};

export type SchedulerEditableScheduledInterview = UpsertScheduledInterview &
  UnscheduledInterviewForCard &
  SchedulerAdditionalFields;

export type SchedulerStagedScheduledInterview = StagedScheduledInterview &
  UnscheduledInterviewForCard &
  SchedulerAdditionalFields;

export type SchedulerOrganization = {
  id: string;
  blockedDates: {
    startDay: DateTime;
    endDay: DateTime;
  }[];
};

/**
 * Computed fields for within the scheduler, not defined by service/mapping
 * These are computed in a custom hook in `hooks/interviews.ts`
 */
type SchedulerComputedFields = {
  status: ScheduledStatus | null;
  fieldChanges: ScheduledInterviewFieldChangeData[];
  originalInterview: SchedulerEditableScheduledInterview | undefined;
  possibleUserMembershipIdsForZoomHostSelection: string[];
};

export type SchedulerStagedScheduledInterviewWithComputedFields =
  SchedulerStagedScheduledInterview & SchedulerComputedFields;

export function isStagedScheduledInterview(
  i: SchedulerEditableScheduledInterview
): i is SchedulerStagedScheduledInterview {
  return !!i.startTime && !!i.endTime;
}

export type SchedulerSchedulingResult =
  UpsertScheduledInterviewGroupForSchedulerMutation;

export type SchedulerSchedulingState = {
  sendNotifications: boolean;
  isReschedule: boolean;
};

export type SchedulerOnCompleted = (
  result: SchedulerSchedulingResult,
  state: SchedulerSchedulingState
) => void;
