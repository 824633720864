import { MutationHookOptions } from "@apollo/client";
import { useLogEvent } from "analytics";
import { SingleInterviewReviewFormData } from "client/components/scheduling-review/utils/types";
import { gql } from "generated/graphql-codegen";
import {
  UpsertScheduledInterviewGroupForSchedulerMutation,
  UpsertScheduledInterviewGroupForSchedulerMutationVariables,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { ScheduledInterviewFieldChange } from "shared/guide-scheduler/field-changes/types";
import useMutation from "utils/useMutation";

import {
  CancellationReason,
  UpdateReason,
} from "../../ReportingReasons/utils/types";
import { mapScheduledInterviewGroupSettingsToInput } from "../../ScheduledInterviewGroupSettingsForm/utils/mapping";
import { ScheduledInterviewGroupSettings } from "../../ScheduledInterviewGroupSettingsForm/utils/types";
import {
  mapScheduledInterviewToCancellationInput,
  mapStagedInterviewToCreateInput,
  mapStagedInterviewToUpdateInput,
} from "../utils/mapping";
import {
  StagedScheduledInterview,
  UpsertScheduledInterview,
} from "../utils/types";

const UPSERT_SCHEDULED_INTERVIEW_GROUP = gql(`
  mutation UpsertScheduledInterviewGroupForScheduler($input: UpsertScheduledInterviewGroupForGuideInput!) {
    upsertScheduledInterviewGroupForGuide(input: $input) {
      scheduledInterviewGroup {
        id
        guide {
          id
          internalUpcomingInterviews {
            id
            ...ScheduledInterviewForScheduledInterviewCard
          }
        }
      }
      createdInterviews {
        errors {
          message
        }
        scheduledInterview {
          ...ScheduledInterviewForScheduledInterviewCard
        }
      }
      updatedInterviews {
        errors {
          message
        }
        scheduledInterview {
          ...ScheduledInterviewForScheduledInterviewCard
        }
      }
      cancelledInterviews {
        errors {
          message
        }
        scheduledInterview {
          ...ScheduledInterviewForScheduledInterviewCard
        }
      }
    }
  }  
`);

export type StagedScheduledInterviewForUpsert = StagedScheduledInterview & {
  reviewFormData: SingleInterviewReviewFormData;
  reportingData?: UpdateReason;
};

export type ScheduledInterviewForCancellation = UpsertScheduledInterview & {
  reviewFormData: {
    sendNotifications: boolean;
    note?: string;
  };
  reportingData?: CancellationReason;
};

type UpsertScheduledInterviewGroupProps = {
  guideId: string;
  schedulingRequestId?: string | null;
  scheduledInterviewGroupId?: string | null;
  scheduledInterviewGroupSettings?: ScheduledInterviewGroupSettings;
  createScheduledInterviews?: StagedScheduledInterviewForUpsert[];
  updateScheduledInterviews?: StagedScheduledInterviewForUpsert[];
  deleteScheduledInterviews?: ScheduledInterviewForCancellation[];
};

/** Base wrapper hook for upserting scheduled interview group mutation. Consumers should map data properly before passing.  */
export function useUpsertScheduledInterviewGroup(
  opts?: MutationHookOptions<
    UpsertScheduledInterviewGroupForSchedulerMutation,
    UpsertScheduledInterviewGroupForSchedulerMutationVariables
  >
) {
  const [upsertScheduledInterviewGroupMutation, returnValue] = useMutation(
    UPSERT_SCHEDULED_INTERVIEW_GROUP,
    opts
  );

  const logEvent = useLogEvent({
    component: "UpsertScheduledInterviewGroup",
  });
  const logInterviewEvents = useCallback(
    ({
      createScheduledInterviews,
      updateScheduledInterviews,
      deleteScheduledInterviews,
    }: Pick<
      UpsertScheduledInterviewGroupProps,
      | "createScheduledInterviews"
      | "updateScheduledInterviews"
      | "deleteScheduledInterviews"
    >) => {
      createScheduledInterviews?.forEach(() => {
        logEvent("Interview Scheduled");
      });
      updateScheduledInterviews?.forEach((interview) => {
        const hasInterviewerChanges = interview.fieldChanges?.some(
          (f) => f.fieldName === ScheduledInterviewFieldChange.INTERVIEWERS
        );
        const hasTimeChanges = interview.fieldChanges?.some(
          (f) => f.fieldName === ScheduledInterviewFieldChange.TIME
        );

        if (hasTimeChanges) {
          logEvent("Interview Rescheduled");
        } else if (hasInterviewerChanges) {
          logEvent("Interview Interviewers Changed");
        } else {
          logEvent("Interview Details Updated");
        }
      });

      deleteScheduledInterviews?.forEach(() => {
        logEvent("Interview Cancelled");
      });

      const allInterviewsCreated =
        (createScheduledInterviews?.length ?? 0) > 0 &&
        (updateScheduledInterviews?.length ?? 0) === 0 &&
        (deleteScheduledInterviews?.length ?? 0) === 0;

      if (allInterviewsCreated) {
        logEvent("Interview Panel Scheduled", {
          numCreatedInterviews: createScheduledInterviews?.length ?? 0,
        });
      } else if (
        createScheduledInterviews?.length ||
        updateScheduledInterviews?.length ||
        deleteScheduledInterviews?.length
      ) {
        logEvent("Interview Panel Rescheduled", {
          numCreatedInterviews: createScheduledInterviews?.length ?? 0,
          numUpdatedInterviews: updateScheduledInterviews?.length ?? 0,
          numDeletedInterviews: deleteScheduledInterviews?.length ?? 0,
        });
      }
    },
    [logEvent]
  );

  const upsertScheduledInterviewGroup = useCallback(
    async ({
      guideId,
      scheduledInterviewGroupId,
      schedulingRequestId,
      scheduledInterviewGroupSettings,
      createScheduledInterviews,
      updateScheduledInterviews,
      deleteScheduledInterviews,
    }: UpsertScheduledInterviewGroupProps) => {
      logInterviewEvents({
        createScheduledInterviews,
        updateScheduledInterviews,
        deleteScheduledInterviews,
      });

      return upsertScheduledInterviewGroupMutation({
        variables: {
          input: {
            scheduledInterviewGroupId,
            guideId,
            schedulingRequestId,
            scheduledInterviewGroupSettings: scheduledInterviewGroupSettings
              ? mapScheduledInterviewGroupSettingsToInput(
                  scheduledInterviewGroupSettings
                )
              : undefined,
            createScheduledInterviews: createScheduledInterviews?.map(
              (stagedScheduledInterview) =>
                mapStagedInterviewToCreateInput({
                  stagedScheduledInterview,
                  reviewFormData: stagedScheduledInterview.reviewFormData,
                  scheduledInterviewGroupSettings,
                })
            ),
            updateScheduledInterviews: updateScheduledInterviews?.map(
              (stagedScheduledInterview) =>
                mapStagedInterviewToUpdateInput({
                  stagedScheduledInterview,
                  reviewFormData: stagedScheduledInterview.reviewFormData,
                  reportingReason: stagedScheduledInterview.reportingData,
                  scheduledInterviewGroupSettings,
                })
            ),
            deleteScheduledInterviews: deleteScheduledInterviews?.map(
              (scheduledInterview) =>
                mapScheduledInterviewToCancellationInput({
                  scheduledInterview,
                  reviewFormData: scheduledInterview.reviewFormData,
                  reportingReason: scheduledInterview.reportingData,
                })
            ),
          },
        },
      });
    },
    [logInterviewEvents, upsertScheduledInterviewGroupMutation]
  );

  return { ...returnValue, upsertScheduledInterviewGroup };
}
