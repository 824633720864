import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import clsx from "clsx";

type LoadingProps = {
  className?: string;
  centerWithAppNav?: boolean;
};

function Loading({ className, centerWithAppNav }: LoadingProps) {
  return (
    <div
      className={clsx(
        "h-screen items-center w-full flex justify-center",
        centerWithAppNav && "-mt-28",
        className
      )}
    >
      <LoadingIndicator />
    </div>
  );
}

export default Loading;
