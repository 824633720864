import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasCopy, atlasRefreshCw } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { RequirementText } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/display/RequirementText";
import { useCopyToClipboard } from "client/hooks/useCopyToClipboard";
import { useCallback } from "react";

import { getDisplayTextFromService } from "../utils/display";

/**
 * Display the subtext for a generation slot,
 * handling existing links, regenerating links, and requirements
 */
export function GenerationSlotSubtext<
  TRequirementType extends { service: string }
>({
  existingUrl,
  generationSettings,
  hasExistingGeneratedURL,
  onRegenerateLink,
  slotHasSelection,
  requirements,
  originalRequirements,
  onChangeRequirements,
  hideActions,
}: {
  existingUrl?: string;
  generationSettings:
    | {
        regenerateLink?: boolean | null;
      }
    | null
    | undefined;
  hasExistingGeneratedURL: boolean;
  onRegenerateLink: () => void;
  slotHasSelection?: boolean;
  requirements?: TRequirementType | null;
  originalRequirements?: TRequirementType | null | undefined;
  onChangeRequirements?: (requirements: TRequirementType | null) => void;
  hideActions?: boolean;
}) {
  const copyToClipboard = useCopyToClipboard();
  const onCopy = useCallback(() => {
    if (existingUrl) {
      copyToClipboard(existingUrl);
    }
  }, [copyToClipboard, existingUrl]);

  if (hasExistingGeneratedURL && generationSettings?.regenerateLink) {
    return (
      <SubtextWrapper>A new link will be generated on save.</SubtextWrapper>
    );
  }

  if (hasExistingGeneratedURL && existingUrl) {
    return (
      <div className="flex flex-row justify-between items-center space-x-2">
        <span className="truncate" title={existingUrl}>
          {existingUrl}
        </span>
        {!hideActions && (
          <ButtonGroup isGhost size="xs">
            <Tooltip isInstant content="Copy link">
              <Button onClick={onCopy} icon={atlasCopy} />
            </Tooltip>
            <Tooltip isInstant content="Generate a new link">
              <Button onClick={onRegenerateLink} icon={atlasRefreshCw} />
            </Tooltip>
          </ButtonGroup>
        )}
      </div>
    );
  }

  if (!slotHasSelection && onChangeRequirements) {
    if (requirements?.service) {
      return (
        <RequirementText
          text={`${getDisplayTextFromService(
            requirements.service
          )} is required`}
          isError
          action={{
            text: "Ignore",
            onClick: () => {
              onChangeRequirements(null);
            },
          }}
        />
      );
    }

    if (originalRequirements?.service) {
      return (
        <RequirementText
          text={`Requirement for ${getDisplayTextFromService(
            originalRequirements.service
          )} has been ignored.`}
          action={{
            text: "Undo",
            onClick: () => {
              onChangeRequirements(originalRequirements);
            },
          }}
        />
      );
    }
  }

  return null;
}

/** Wrapper around plain text to ensure the same height as when there is buttons */
function SubtextWrapper({ children }: { children: React.ReactNode }) {
  return <div className="h-6 flex items-center">{children}</div>;
}
