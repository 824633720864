import { Button as AriaButton } from "@ariakit/react";
import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCopy,
  atlasEllipsisHorizontal,
  atlasPhone,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Menu } from "@resource/atlas/menu";
import { View } from "@resource/atlas/view/View";
import { strings } from "@resource/common";
import { ActivityFeed } from "client/components/activity-feed/ActivityFeed";
import { BackButton } from "client/components/generic/layout/BackButton";
import { Divider } from "client/components/generic/misc/Divider";
import { EditInterviewInviteAndNotifyDialog } from "client/components/google/components/EditInterviewInviteAndNotifyDialog";
import { GoogleEventView } from "client/components/google/components/GoogleEventView";
import {
  mapScheduledInterviewToInterviewInviteContentData,
  mapScheduledInterviewToValueSet,
} from "client/components/google/utils/mapping";
import { useCopyToClipboard } from "client/hooks/useCopyToClipboard";
import { ScheduleInterviewForInterviewDetailsFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import {
  COLLABORATIVE_CODING_ICONS,
  DEFAULT_COLLABORATIVE_CODING_ICON,
} from "shared/guide-scheduler/collaborative-coding/utils";

import { InterviewersDisplay } from "../../__components/InterviewersDisplay";
import { useInternalScheduledInterviewMenuItems } from "../../__hooks/useInternalScheduledInterviewMenuItems";
import { useScheduledInterviewActivity } from "../__hooks/useScheduledInterviewActivity";
import { AtssyncScheduledInterviewIntegrationStatus } from "./AtssyncScheduledInterviewIntegrationStatus";
import { GoogleIntegrationStatus } from "./GoogleIntegrationStatus";
import { InfoHeader } from "./InfoHeader";
import { ScheduledInterviewDetailsStatuses } from "./ScheduledInterviewDetailsStatuses";
import { VideoConferencingData } from "./VideoConferencingData";

type ScheduledInterviewDetailsSchedulingProps = {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
  onBack?: () => void;
};

export function ScheduledInterviewDetailsScheduling({
  scheduledInterview,
  onBack,
}: ScheduledInterviewDetailsSchedulingProps) {
  const { activity, nextPage, loading } = useScheduledInterviewActivity(
    scheduledInterview.id
  );

  const copyToClipboard = useCopyToClipboard();
  const { Dialog: MenuDialog, menuItems } =
    useInternalScheduledInterviewMenuItems(scheduledInterview);
  const mappedGoogleEventViewData = useMemo(
    () => mapScheduledInterviewToInterviewInviteContentData(scheduledInterview),
    [scheduledInterview]
  );
  const mappedValueSet = useMemo(
    () => mapScheduledInterviewToValueSet(scheduledInterview),
    [scheduledInterview]
  );
  const editGoogleEventStore = useDialogStore();

  return (
    <View
      header={{
        rightActions: menuItems.length ? (
          <Menu.Root>
            <Menu.Trigger>
              <Button isGhost size="small" icon={atlasEllipsisHorizontal} />
            </Menu.Trigger>
            <Menu.Content items={menuItems} />
          </Menu.Root>
        ) : null,
        leftActions: <BackButton onClick={onBack} />,
        title: "Interview details",
      }}
      className="bg-light-gray-200"
      content={{
        className: "px-0 py-0 text-body-md",
      }}
    >
      <div className="bg-white">
        <div className="p-6 flex flex-col space-y-3">
          {!scheduledInterview.isV2 && (
            <AlertBanner variant="warning">
              This interview is deprecated and can no longer be edited.
            </AlertBanner>
          )}
          <InfoHeader scheduledInterview={scheduledInterview} />
          <AtssyncScheduledInterviewIntegrationStatus
            scheduledInterview={scheduledInterview}
          />
          <ScheduledInterviewDetailsStatuses
            scheduledInterview={scheduledInterview}
          />
          <GoogleIntegrationStatus scheduledInterview={scheduledInterview} />
          {scheduledInterview.videoConferencing && (
            <VideoConferencingData
              data={scheduledInterview.videoConferencing}
            />
          )}
          {scheduledInterview.conferencePhone && (
            <div className="flex w-full space-x-3 items-center">
              <Icon content={atlasPhone} className="h-5 w-5" />
              <AriaButton
                onClick={() =>
                  copyToClipboard(scheduledInterview.conferencePhone)
                }
                className="group flex flex-row space-x-2 max-w-full overflow-hidden"
              >
                <span
                  className="truncate"
                  title={scheduledInterview.conferencePhone}
                >
                  {scheduledInterview.conferencePhone}
                </span>
                <Icon
                  content={atlasCopy}
                  className="opacity-0 group-hover:opacity-100"
                />
              </AriaButton>
            </div>
          )}
          {scheduledInterview.collaborativeCodingUrl && (
            <div className="flex w-full space-x-3 items-center">
              <Icon
                content={
                  COLLABORATIVE_CODING_ICONS[
                    scheduledInterview.collaborativeCoding
                      ?.type as CollaborativeCodingServiceType
                  ] ?? DEFAULT_COLLABORATIVE_CODING_ICON
                }
                className="h-5 w-5"
              />
              <AriaButton
                onClick={() =>
                  copyToClipboard(scheduledInterview.collaborativeCodingUrl)
                }
                className="group flex flex-row space-x-2 max-w-full overflow-hidden"
              >
                <span
                  className="truncate"
                  title={scheduledInterview.collaborativeCodingUrl}
                >
                  {scheduledInterview.collaborativeCodingUrl}
                </span>
                <Icon
                  content={atlasCopy}
                  className="opacity-0 group-hover:opacity-100 transition-opacity"
                />
              </AriaButton>
            </div>
          )}
        </div>

        <div className="space-y-4 px-6 pb-2">
          <span className="text-body-md-heavy">
            {strings.pluralize(
              "Participants",
              scheduledInterview.interviewers.length
            )}
          </span>
          <InterviewersDisplay interviewers={scheduledInterview.interviewers} />
        </div>
        {!scheduledInterview.isSelfScheduled && scheduledInterview.isV2 && (
          <>
            <div className="my-4">
              <Divider />
            </div>
            <div className="space-y-3 px-6">
              <div className="flex w-full justify-between">
                <h3 className="text-h4">Interviewer invite</h3>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link onClick={editGoogleEventStore.show}>Edit</Link>
              </div>
              <GoogleEventView
                data={mappedGoogleEventViewData}
                valueSet={mappedValueSet}
              />
            </div>
          </>
        )}
      </div>
      <div className="h-6 bg-white" />
      <ActivityFeed
        className="bg-light-gray-200"
        activity={activity}
        nextPage={nextPage}
        loading={loading}
        context="Interview"
        showHeading
      />
      {MenuDialog}
      <EditInterviewInviteAndNotifyDialog
        scheduledInterviewId={scheduledInterview.id}
        store={editGoogleEventStore}
      />
    </View>
  );
}
