import {
  InterviewConflictData,
  InterviewForConflicts,
} from "client/components/interviewer-slots/conflicts/utils/types";
import { eventsOverlap } from "client/utils/dates";
import { workingHoursEngulfInterval } from "client/utils/workingHours";
import {
  UserCalendarEventForConflictsFragment,
  UserMembershipForConflictsFragment,
  UserMembershipForInterviewerAlreadyInterviewedCandidateFragment,
} from "generated/graphql-codegen/graphql";
import { partition } from "lodash";
import { DateTime, Interval } from "luxon";
import { isInterviewWhitelisted } from "shared/utils/interview-whitelist";

type MapUserMembershipForConflictsFragmentToConflictDataParams = {
  userMembership: UserMembershipForConflictsFragment &
    UserMembershipForInterviewerAlreadyInterviewedCandidateFragment;
  selectedInterview: InterviewForConflicts;
  calendarEvents: UserCalendarEventForConflictsFragment[] | null;
};

/** Takes a UserMembershipForConflictsFragment and returns the calculated conflicts data */
export function getConflictDataForUserMembership({
  userMembership,
  selectedInterview,
  calendarEvents,
}: MapUserMembershipForConflictsFragmentToConflictDataParams): InterviewConflictData {
  const outsideWorkingHours = interviewOutsideWorkingHours({
    interview: selectedInterview,
    interviewer: userMembership,
  });

  const hasAlreadyInterviewed = !!(
    userMembership.previousScheduledInterviews &&
    userMembership.previousScheduledInterviews.length > 0
  );

  // If calendar events haven't loaded yet, return the working hours check.
  if (!calendarEvents) {
    return {
      outsideWorkingHours,
      hasConflict: false,
      hasFlexibleConflict: false,
      hasAlreadyInterviewed,
    };
  }

  const { hasConflict, hasFlexibleConflict } = getConflictsForInterview({
    interview: selectedInterview,
    events: calendarEvents,
    whitelist: userMembership.interviewWhitelist,
  });

  return {
    outsideWorkingHours,
    hasConflict,
    hasFlexibleConflict,
    hasAlreadyInterviewed,
  };
}

/** Returns whether the interview has a conflict or flexible conflict with any of the events. */
const getConflictsForInterview = ({
  interview,
  events,
  whitelist,
}: {
  interview: InterviewForConflicts;
  events: UserCalendarEventForConflictsFragment[];
  whitelist: string[];
}) => {
  const { conflicts, flexibleConflicts } = getConflictingEventIdsForInterview({
    interview,
    events,
    whitelist,
  });

  return {
    hasConflict: conflicts.length > 0,
    hasFlexibleConflict: flexibleConflicts.length > 0,
  };
};

/** Returns a list of conflicting and flexibly conflicting event ids. */
const getConflictingEventIdsForInterview = ({
  interview,
  events,
  whitelist,
}: {
  interview: InterviewForConflicts;
  events: UserCalendarEventForConflictsFragment[];
  whitelist: string[];
}) => {
  const allConflicts = events.filter(
    (event) => !event.isTransparent && eventsOverlap(interview, event)
  );

  const [flexibleConflicts, conflicts] = partition(allConflicts, (event) =>
    isInterviewWhitelisted({ eventTitle: event.title, whitelist })
  );

  const conflictIds = conflicts.map((event) => event.id);
  const flexibleConflictIds = flexibleConflicts.map((event) => event.id);

  return { conflicts: conflictIds, flexibleConflicts: flexibleConflictIds };
};

type InterviewOutsideWorkingHoursParams = {
  interview: InterviewForConflicts;
  interviewer: UserMembershipForConflictsFragment;
};

/** Checks if a specific interview is outside of an interviewer's working hours. */
const interviewOutsideWorkingHours = ({
  interview,
  interviewer,
}: InterviewOutsideWorkingHoursParams) => {
  if (!interview.startTime || !interview.endTime) {
    return false;
  }

  const interviewStart = DateTime.fromISO(interview.startTime);
  const interviewEnd = DateTime.fromISO(interview.endTime);

  return !workingHoursEngulfInterval({
    interviewer,
    interval: Interval.fromDateTimes(interviewStart, interviewEnd),
  });
};
