import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import React, { useMemo } from "react";

import { Icon } from "../icon/Icon";
import { AtlasIconData } from "../icon/types";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import { getButtonStyles, getIconStyles } from "./utils";

export interface ButtonProps extends Ariakit.ButtonProps {
  variant?: "default" | "primary" | "secondary" | "danger";
  size?: "sm" | "md" | "lg";
  icon?: AtlasIconData;
  iconPosition?: "left" | "right";
  isLoading?: boolean;
  isDisabled?: boolean;
  isGhost?: boolean;
  badge?: number;
  className?: string;
  children?: React.ReactNode;
  /** Reduces the margin on one or both sides */
  negativeMargin?: "left" | "right" | "both";
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      variant = "default",
      size = "md",
      icon,
      iconPosition = "left",
      isLoading = false,
      isDisabled = false,
      isGhost = false,
      badge,
      className,
      children,
      negativeMargin,
      ...rest
    } = props;

    const badgeStyles = useMemo(() => {
      const baseBadgeStyles =
        "absolute flex items-center justify-center min-w-[.75rem] h-3 text-[.5rem] font-medium rounded-full px-[.1875rem] top-1 right-1.5";
      const smallStyles = "min-w-3 h-3";

      if (isGhost) {
        return clsx(
          baseBadgeStyles,
          "text-white",
          "ring-white ring-1",
          "bg-purple-500",
          "group-hover:ring-light-gray-500",
          size === "sm" && smallStyles
        );
      }

      return clsx(
        baseBadgeStyles,
        "-top-2 -right-2",
        "text-white",
        "bg-purple-600",
        "border border-white",
        size === "sm" && smallStyles
      );
    }, [isGhost, size]);

    return (
      <Ariakit.Button
        ref={ref}
        disabled={isDisabled || isLoading}
        className={clsx(
          getButtonStyles({
            variant,
            size,
            isGhost,
            isDisabled,
            isLoading,
            negativeMargin,
          }),
          className
        )}
        {...rest}
      >
        {isLoading && (
          <LoadingIndicator
            size="small"
            className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        )}
        <span
          className={clsx("flex items-center gap-2", isLoading && "invisible")}
        >
          {icon && iconPosition === "left" && (
            <Icon className={getIconStyles(size)} content={icon} />
          )}
          {children}
          {icon && iconPosition === "right" && (
            <Icon className={getIconStyles(size)} content={icon} />
          )}
        </span>
        {typeof badge === "number" && badge > 0 && (
          <span className={badgeStyles}>{badge > 99 ? "99+" : badge}</span>
        )}
      </Ariakit.Button>
    );
  }
);

Button.displayName = "Button";
