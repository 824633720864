/* eslint-disable class-methods-use-this, @typescript-eslint/no-use-before-define, no-underscore-dangle */
import "./UnknownVariableNode.sass";

import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import clsx from "clsx";
import {
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
} from "lexical";
import { Spread } from "type-fest";

import { createComponentUtils } from "../../../__utils/atlas";
import OptionalTooltip from "../../../tooltip/OptionalTooltip";

// config
// ------

const COMPONENT_NAME = "ContentEditor-UnknownVariable";

const { el } = createComponentUtils(COMPONENT_NAME);

type UnknownVariableValue = {
  variableId: string;
  tooltipContent?: string;
};

// lexical helpers
// ---------------

export function $createUnknownVariableNode(
  value: UnknownVariableValue
): UnknownVariableNode {
  return new UnknownVariableNode(value);
}

export function $isUnknownVariableNode(
  node: LexicalNode | null | undefined
): node is UnknownVariableNode {
  return node instanceof UnknownVariableNode;
}

// serialized type
// ---------------

export type SerializedUnknownVariableNode = Spread<
  {
    type: "unknown-variable";
    version: 1;
    value: UnknownVariableValue;
  },
  SerializedLexicalNode
>;

// variable component
// ------------------

type UnknownVariableProps = {
  nodeKey: string;
  value: UnknownVariableValue;
};

function UnknownVariable({
  nodeKey,
  value: { variableId, tooltipContent = "Unsupported token." },
}: UnknownVariableProps) {
  const [isSelected] = useLexicalNodeSelection(nodeKey);

  return (
    <OptionalTooltip
      isInstant
      UNSAFE_mode="slot"
      UNSAFE_isFocusDisabled
      content={tooltipContent}
    >
      <span
        tabIndex={-1}
        className={clsx(`${el`node`} undefined-mode-error isUndefined`, {
          isSelected,
        })}
      >
        ${variableId}
      </span>
    </OptionalTooltip>
  );
}

// Unknown variable node
// -------------

export class UnknownVariableNode extends DecoratorNode<JSX.Element> {
  __value: UnknownVariableValue;

  setValue(value: UnknownVariableValue) {
    this.getWritable().__value = value;
  }

  static getType(): string {
    return "unknown-variable";
  }

  static clone(node: UnknownVariableNode): UnknownVariableNode {
    return new UnknownVariableNode(node.__value, node.__key);
  }

  static importJSON(
    serializedNode: SerializedUnknownVariableNode
  ): UnknownVariableNode {
    return $createUnknownVariableNode(serializedNode.value);
  }

  constructor(value: UnknownVariableValue, key?: NodeKey) {
    super(key);
    this.__value = value;
  }

  exportJSON(): SerializedUnknownVariableNode {
    return {
      type: "unknown-variable",
      version: 1,
      value: this.__value,
    };
  }

  createDOM(): HTMLElement {
    return document.createElement("span");
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return <UnknownVariable nodeKey={this.__key} value={this.__value} />;
  }
}
