import { useCalendarSettings, useCurrentInterval } from "./settings";

/**
 * Return the days to render based on the current view.
 */
export function useDaysToRender() {
  const { includeWeekends, view } = useCalendarSettings();
  const currentInterval = useCurrentInterval();

  if (view === "week") {
    return [...Array(includeWeekends ? 7 : 5).keys()].map((i) =>
      currentInterval.start.plus({ days: i })
    );
  }

  return [currentInterval.start];
}
