import { Section } from "client/components/generic/misc/Section";
import { AddInterviewChangeMessageInput } from "client/components/scheduling-review/AddInterviewChangeMessageInput";
import { AddToCalendarSelect } from "client/components/scheduling-review/AddToCalendarSelect";
import { NotifyInterviewersCheckbox } from "client/components/scheduling-review/NotifyInterviewersCheckbox";
import { OptionalParticipantsSelect } from "client/components/scheduling-review/OptionalParticipantsSelect";
import { PanelReviewPageFormState } from "client/scheduler/core/components/review/hooks/usePanelReviewPageFormState";
import {
  CurrentUserMembershipForReviewPageSchedulingDefaultsFragment,
  UserForTagSelectionFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";

import { useSchedulerStateForReview } from "./hooks/useReviewPageData";

type PanelNotificationSettings = {
  state: PanelReviewPageFormState;
  currentUserMembership: CurrentUserMembershipForReviewPageSchedulingDefaultsFragment;
  nonCancelledInterviewsCount: number;
};

/**
 * Settings section for scheduler review page
 * Allows managing the panel notification settings
 */
export function PanelNotificationSettings({
  state,
  currentUserMembership,
  nonCancelledInterviewsCount,
}: PanelNotificationSettings) {
  const { isUpdatingInterviews, isCancellingInterviews } =
    useSchedulerStateForReview();
  const { form, onChange } = state;
  const showOptionalParticipantsSelect = useMemo(
    () => nonCancelledInterviewsCount > 0,
    [nonCancelledInterviewsCount]
  );
  const optionalAttendeeUserMemberships = form.watch(
    "optionalAttendeeUserMemberships"
  );
  const onChangeOptionalParticipants = useCallback(
    (v: UserForTagSelectionFragment[]) => {
      onChange("optionalAttendeeUserMemberships", v);
    },
    [onChange]
  );

  const addToGoogleCalendarId = form.watch("addToGoogleCalendarId");
  const onChangeAddToCalendar = useCallback(
    (v: string | null) => {
      onChange("addToGoogleCalendarId", v ?? undefined);
    },
    [onChange]
  );

  const sendNotifications = form.watch("sendNotifications");
  const onChangeSendEmailInvitations = useCallback(
    (v: boolean) => {
      onChange("sendNotifications", v);
    },
    [onChange]
  );

  const note = form.watch("note");
  const onChangeNote = useCallback(
    (value: string) => {
      onChange("note", value);
    },
    [onChange]
  );

  const noteSubtext = useMemo(() => {
    if (isUpdatingInterviews) {
      return "This message will be included in the email notifications sent to interviewers for any updated interviews.";
    }

    if (isCancellingInterviews) {
      return "This message will be included in the email notifications sent to interviewers for any cancelled interviews.";
    }

    if (isUpdatingInterviews && isCancellingInterviews) {
      return "This message will be included in the email notifications sent to interviewers for any updated or cancelled interviews.";
    }

    return "This message will be included in the email notification sent to interviewers.";
  }, [isUpdatingInterviews, isCancellingInterviews]);

  return (
    <Section title="Settings">
      {showOptionalParticipantsSelect && (
        <OptionalParticipantsSelect
          onChange={onChangeOptionalParticipants}
          selected={optionalAttendeeUserMemberships ?? []}
          placement="bottom"
        />
      )}
      {currentUserMembership.googleCalendars && (
        <AddToCalendarSelect
          calendars={currentUserMembership.googleCalendars}
          selectedInterviewCalendarId={addToGoogleCalendarId ?? null}
          onChange={onChangeAddToCalendar}
        />
      )}
      <NotifyInterviewersCheckbox
        notifyInterviewers={sendNotifications}
        onChange={onChangeSendEmailInvitations}
      />
      {(isUpdatingInterviews || isCancellingInterviews) &&
        sendNotifications && (
          <AddInterviewChangeMessageInput
            value={note ?? ""}
            onChange={onChangeNote}
            subText={noteSubtext}
          />
        )}
    </Section>
  );
}
