import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarEvent } from "@resource/atlas/icons";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import clsx from "clsx";
import { SelfScheduleRequestPreviewData } from "shared/self-schedule/rich-blocks/self-schedule-request";

import { AvailableSlotsDisplayInfo } from "./AvailableSlotsDisplayInfo";
import { useAvailableSlotsForSettings } from "./calendar-view/hooks/queries";

export function SelfScheduleRequestPreviewNodeRenderer({
  data,
}: {
  data: SelfScheduleRequestPreviewData;
}) {
  const { interviewSettings, schedulingSettings, actorId } = data;

  const { availableSlots } = useAvailableSlotsForSettings({
    interviewerId: interviewSettings.interviewer.id,
    duration: interviewSettings.duration,
    schedulingSettings,
    actorId,
  });

  return (
    <div
      className={clsx(
        "flex flex-col w-full border rounded-md border-gray-border",
        "opacity-70 pointer-events-none"
      )}
    >
      <div className="p-3 space-y-4">
        <div className="flex flex-row gap-2">
          <div className="flex">
            <div className="w-10 h-10 bg-blue-50 flex items-center justify-center rounded-full">
              <Icon
                content={atlasCalendarEvent}
                className="w-5 h-5 text-blue-500"
              />
            </div>
            <Avatar
              name={interviewSettings.interviewer.name}
              image={interviewSettings.interviewer.imageUrl}
              size="large"
              className="ring-2 ring-white"
              style={{
                marginLeft: "-.25rem",
              }}
            />
          </div>
          <div className="grow">
            <InterviewTitle
              interview={interviewSettings}
              className="text-body-md-heavy text-dark"
            />
            <div className="text-body-sm">
              <div className="flex gap-2 items-center">
                <span className="text-body-sm text-subtle">
                  {interviewSettings.duration} minutes
                </span>
              </div>
            </div>
          </div>
        </div>
        <Button variant="primary" icon={atlasCalendarEvent}>
          Schedule your interview
        </Button>
      </div>
      <AvailableSlotsDisplayInfo availableSlots={availableSlots ?? []} />
    </div>
  );
}
