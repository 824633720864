import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCircleCheckedColor,
  atlasReverseShadower,
  atlasShadower,
} from "@resource/atlas/icons";
import clsx from "clsx";
import React, { useMemo } from "react";
import { v4 as uuid } from "uuid";

import {
  isCompletedReverseShadowStage,
  isCompletedShadowStage,
  isCurrentReverseShadowStage,
  isCurrentShadowStage,
} from "../utils/helpers";
import { TrainingProgressData } from "../utils/types";

type TrainingProgressProps = {
  data: TrainingProgressData;
};

export function TrainingProgress({
  data: { shadows, reverseShadows, completedShadows, completedReverseShadows },
}: TrainingProgressProps) {
  const shadowKeys = useMemo(
    () =>
      new Array(shadows).fill(null).map((_value, index) => ({
        id: uuid(),
        isCurrent: isCurrentShadowStage({ index, completedShadows }),
        isCompleted: isCompletedShadowStage({ index, completedShadows }),
      })),
    [shadows, completedShadows]
  );
  const reverseShadowKeys = useMemo(
    () =>
      new Array(reverseShadows).fill(null).map((_value, index) => ({
        id: uuid(),
        isCurrent: isCurrentReverseShadowStage({
          index,
          shadows,
          completedShadows,
          completedReverseShadows,
        }),
        isCompleted: isCompletedReverseShadowStage({
          index,
          shadows,
          completedShadows,
          completedReverseShadows,
        }),
      })),
    [reverseShadows, completedShadows, completedReverseShadows, shadows]
  );

  return (
    <div className="text-subtle flex items-center">
      {shadowKeys.map(({ id: key, isCurrent, isCompleted }, index) => (
        <React.Fragment key={key}>
          {index !== 0 && <div className="w-2 border-t-[2px] h-[0px]" />}
          {isCompleted ? (
            <Icon
              content={atlasCircleCheckedColor}
              className="text-green-500"
            />
          ) : (
            <Icon
              content={atlasShadower}
              className={clsx({ "text-purple-500": isCurrent })}
            />
          )}
        </React.Fragment>
      ))}
      {reverseShadowKeys.map(({ id: key, isCurrent, isCompleted }, index) => (
        <React.Fragment key={key}>
          {(shadowKeys.length > 0 || index !== 0) && (
            <div className="w-2 border-t-[2px] h-[0px]" />
          )}
          {isCompleted ? (
            <Icon
              content={atlasCircleCheckedColor}
              className="text-green-500"
            />
          ) : (
            <Icon
              content={atlasReverseShadower}
              className={clsx({ "text-purple-500": isCurrent })}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
