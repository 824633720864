import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import React, { useMemo } from "react";

import { useCalendarContext } from "../../context";
import { DayHeadersViewElement } from "../../DayHeadersViewElement";
import { CalendarEvent } from "../../types";
import { useDaysToRender } from "../../useDaysToRender";
import { ROWS_IN_DAY, useCalendarSizes } from "../../utils";
import { AllDayEvent } from "./AllDayEvent";
import { AllDayEventGroup } from "./AllDayEventGroup";

export function AllDayEventsViewPlugin() {
  const calendarSizes = useCalendarSizes();
  const { allDayEvents } = useCalendarContext();
  const hasAllDayEvent = useMemo(() => {
    return allDayEvents.length;
  }, [allDayEvents]);

  if (!hasAllDayEvent) {
    return null;
  }

  return (
    <DayHeadersViewElement>
      <div
        className="flex flex-auto"
        data-cy={CypressData.calendar.allDayEventsView}
      >
        <div
          className="sticky left-0 z-10 flex-none bg-white ring-1 ring-[#EEEDEF]"
          style={{
            width: `${calendarSizes.leftOffsetWidth.rem}rem`,
          }}
        />
        <div
          className="grid flex-auto grid-cols-1 grid-rows-1 -mr-px border-r border-[#EEEDEF]"
          style={{
            height: `${calendarSizes.allDayEventsViewHeight.rem}rem`,
          }}
        >
          <AllVerticalLines />
          <AllDayEvents />
        </div>
      </div>
    </DayHeadersViewElement>
  );
}

function AllDayEvents() {
  const { allDayEvents } = useCalendarContext();
  const days = useDaysToRender();

  const allDayEventGroups = useMemo(() => {
    const groups: CalendarEvent[][] = [];
    let eventGroup: CalendarEvent[] = [];

    for (let i = 0; i < allDayEvents.length; i += 1) {
      const currEvent = allDayEvents[i];

      if (eventGroup.length === 0) {
        // Start a new group with the current event
        eventGroup = [currEvent];
      } else {
        const lastEventInGroup = eventGroup[eventGroup.length - 1];

        if (currEvent.startTime.equals(lastEventInGroup.startTime)) {
          // The current event overlaps with the last event in the group, add it to the group
          eventGroup.push(currEvent);
        } else {
          groups.push(eventGroup);
          eventGroup = [currEvent];
        }
      }
    }

    if (eventGroup.length > 0) {
      groups.push(eventGroup);
    }

    return groups;
  }, [allDayEvents]);

  return (
    <ol
      className={clsx("col-start-1 col-end-2 row-start-1 grid grid-cols-1", {
        "grid-cols-1": days.length === 1,
        "grid-cols-2": days.length === 2,
        "grid-cols-3": days.length === 3,
        "grid-cols-4": days.length === 4,
        "grid-cols-5": days.length === 5,
        "grid-cols-6": days.length === 6,
        "grid-cols-7": days.length === 7,
      })}
      style={{
        gridTemplateRows: `0 repeat(${ROWS_IN_DAY}, minmax(0, 1fr)) auto`,
      }}
    >
      {allDayEventGroups.map((eventGroup) => {
        if (eventGroup.length === 1) {
          const event = eventGroup[0];
          return (
            <AllDayEvent
              event={event}
              key={`${event.id}-${event.startTime.toISO()}`}
            />
          );
        }

        return (
          <AllDayEventGroup
            eventGroup={eventGroup}
            key={`${eventGroup[0].id}-${eventGroup[0].startTime.toISO()}`}
          />
        );
      })}
    </ol>
  );
}

function AllVerticalLines() {
  const days = useDaysToRender();

  const gridClass = `grid-cols-${days.length} sm:grid-cols-${days.length}`;

  return (
    <div
      className={clsx(
        "col-start-1 col-end-2 row-start-1 hidden grid-rows-1 divide-x divide-[#EEEDEF] sm:grid",
        gridClass
      )}
    >
      {days.map((day, i) => {
        return (
          <div
            className={clsx("row-span-full", `col-start-${i + 1}`)}
            key={day.toISO()}
          />
        );
      })}
    </div>
  );
}
