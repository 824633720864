/* eslint-disable import/prefer-default-export */
import { createModule } from "../../__utils/modules";
import { LineActionsPlugin } from "./LineActionsPlugin";

export const lineActionsModule = createModule("line-actions", {
  declareModule() {
    return {
      plugins: [<LineActionsPlugin />],
    };
  },
});
