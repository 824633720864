import {
  createNameSet,
  VariableSet,
} from "@resource/atlas/content-editor/variables";
import { VariableNameEntry } from "@resource/atlas/content-editor/variables/__lib/types";
import { VariableKey } from "modules/lexical/shared/utils/types";

/** Wrapper function for Atlas createNameSet with improved type safety. */
export function createNameSetFromKeys<T extends VariableSet>(
  keys: ReadonlyArray<keyof T & keyof typeof nameMap>
) {
  return createNameSet<T>(
    Object.fromEntries(keys.map((key) => [key, nameMap[key]]))
  );
}

export const nameMap: Record<VariableKey, VariableNameEntry> = {
  authorName: {
    name: "author.name",
    label: "Message author's full name",
  },
  atsCoordinatorJobTitle: {
    name: "coordinator.title",
    label: "Coordinator's title",
  },
  atsCoordinatorName: {
    name: "coordinator.name",
    label: "Coordinator's full name",
  },
  atsRecruiterJobTitle: {
    name: "recruiter.title",
    label: "Recruiter's title",
  },
  atsRecruiterName: {
    name: "recruiter.name",
    label: "Recruiter's full name",
  },
  candidateEmail: {
    name: "candidate.email",
    label: "Candidate's email address",
  },
  candidateLinkedIn: {
    name: "candidate.linkedIn.url",
    label: "Candidate's LinkedIn profile link",
  },
  candidateName: {
    name: "candidate.name",
    label: "Candidate's full name",
  },
  candidatePhone: {
    name: "candidate.phone",
    label: "Candidate's phone number",
  },
  collaborativeCodingUrl: {
    name: "interview.collaborativeCoding.url",
    label: "Collaborative coding link",
  },
  companyName: {
    name: "company.name",
    label: "Company name",
  },
  conferencePhone: {
    name: "interview.conferencePhone",
    label: "Interview phone number",
  },
  firstInterview: {
    name: "interviews.first.date",
    label: "Upcoming interviews (first) date",
  },
  firstInterviewerName: {
    name: "interviewer.name",
    label: "First interviewer name",
  },
  firstInterviewerJobTitle: {
    name: "interviewer.title",
    label: "First interviewer job title",
  },
  firstInterviewFirstInterviewerName: {
    name: "interviews.first.interviewer.first.name",
    label: "First interviewer name for next interview",
  },
  firstInterviewFirstInterviewerTitle: {
    name: "interviews.first.interviewer.first.title",
    label: "First interviewer title for next interview",
  },
  firstInterviewInterviewers: {
    name: "interviews.first.interviewers",
    label: "Upcoming interviews (first) interviewers",
  },
  guideLink: {
    name: "portal.link",
    label: "Candidate portal link",
  },
  hiringManagerJobTitle: {
    name: "hiringManager.title",
    label: "Hiring manager's title",
  },
  hiringManagerName: {
    name: "hiringManager.name",
    label: "Hiring manager's full name",
  },
  interviewKit: {
    name: "interview.interviewKit.url",
    label: "Greenhouse interview kit link",
  },
  interviewName: {
    name: "interview.name",
    label: "Interview name",
  },
  interviewTime: {
    name: "interview.time",
    label: "Interview time",
  },
  interviewerNames: {
    name: "interviewers.name",
    label: "Interviewer name",
  },
  interviewersList: {
    name: "interview.interviewers",
    label: "Interviewers",
  },
  interviewsInterviewers: {
    name: "interviews.interviewers",
    label: "Upcoming interviews interviewers",
  },
  interviewsTimeRange: {
    name: "interviews.timerange",
    label: "Upcoming interviews time range",
  },
  jobListingLink: {
    name: "job.listing.link",
    label: "Job listing link",
  },
  jobTitle: {
    name: "job.title",
    label: "Job title",
  },
  lastInterview: {
    name: "interviews.last.date",
    label: "Upcoming interviews (last) date",
  },
  lastInterviewFirstInterviewerName: {
    name: "interviews.last.interviewer.first.name",
    label: "First interviewer name for last interview",
  },
  lastInterviewFirstInterviewerTitle: {
    name: "interviews.last.interviewer.first.title",
    label: "First interviewer title for last interview",
  },
  lastInterviewInterviewers: {
    name: "interviews.last.interviewers",
    label: "Upcoming interviews (last) interviewers",
  },
  linkToJobDescription: {
    name: "job.linkToJobDescription",
    label: "Link to job description",
  },
  schedulerName: {
    name: "interview.scheduler.name",
    label: "Scheduler's name",
  },
  videoConferencingUrl: {
    name: "interview.videoConferencing.url",
    label: "Video conferencing link",
  },
};
