import { useExtensionHasBeenOpened } from "client/app/extension/__components/ExtensionControllerProvider";

/** Stops the provider chain from further rendering children if
 * in the extension and it has never been opened. */
export function PreventRenderUntilExtensionOpened({
  children,
}: {
  children?: React.ReactNode;
}) {
  const hasExtensionBeenOpen = useExtensionHasBeenOpened();

  if (!hasExtensionBeenOpen) {
    return null;
  }

  return <>{children}</>;
}
