import { AtlasIconData } from "@resource/atlas/icon/types";
import { CalendarEventInputDateTime } from "client/utils/dates";
import { DateTime } from "luxon";
import { InterviewerResponse } from "shared/guide-scheduler/types";

import { ColumnType } from "../hooks/useColumns";
import { ColorConfig } from "./colors";

export type GetEventOverridesProps = {
  /** Calendar ID for the column the event is being rendered in */
  eventInCalendarId?: string;
  /** Group ID for the group the column belongs to that the event is being rendered in */
  eventInGroupId?: string;
  columnType: ColumnType;
};

export type EventOverrides = Partial<Omit<CalendarEvent, "getOverrides">> & {
  hideEvent?: boolean;
};

export type GetEventOverrides = (
  props: GetEventOverridesProps
) => EventOverrides;

export type CalendarEvent = {
  id: string;
  calendarId: string;
  /** If the event is on multiple calendars for day view */
  additionalCalendarIds?: string[];
  startTime: DateTime;
  endTime: DateTime;
  title: string;
  outOfOffice?: boolean;
  allDay?: boolean;
  colorConfig: ColorConfig;
  icon?: AtlasIconData | { text: string };
  isBackgroundEvent?: boolean;
  isSelected?: boolean;
  layer?: string;
  disableDetailsPopover?: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  // TODO: handle tentative responses
  responseStatus?: InterviewerResponse;
  onEdit?: (updatedEvent: { startTime: DateTime; endTime: DateTime }) => void;
  onRemove?: () => void;
  getOverrides?: GetEventOverrides;
  attendees?: {
    name?: string | null;
    email?: string | null;
    imageUrl?: string | null;
    organizer?: boolean;
    optional?: boolean;
    resource?: boolean;
    responseStatus?: InterviewerResponse;
  }[];
  creator?: {
    name?: string | null;
    email?: string | null;
  };
  organizer?: {
    name?: string | null;
    email?: string | null;
  };
  isPrivate?: boolean;
  additionalInfoForPopover?: React.ReactNode;
};

export type CalendarEventInput = Omit<
  CalendarEvent,
  "startTime" | "endTime" | "allDay" | "colorConfig"
> & {
  start: CalendarEventInputDateTime;
  end: CalendarEventInputDateTime;
  colorConfig?: ColorConfig;
};

export type CalendarView = "day" | "week";

export type CalendarColorsConfig = {
  [calendarId: string]: ColorConfig;
};

export type ScrollToTime = (props: {
  /** Time to scroll to */
  time: DateTime | string;
  /**
   * How many hours above the time to pad the scroll for a buffer
   * */
  hoursPadding?: number;
}) => void;

export type GetCalendarDisplayInfo = (calendarId: string) => React.ReactNode;

export type GetEventDetailsFooter = (event: CalendarEvent) => React.ReactNode;

export type CalendarGrouping = {
  id: string;
  label: React.ReactNode;
  calendarIds: string[];
};

export type CalendarsGrouping = CalendarGrouping[];

export enum DayOfWeek {
  SUNDAY = 7,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}
