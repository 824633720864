import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { useCalendarTimezone } from "client/components/calendar-v2/hooks/settings";
import { ScheduledInterviewCardBadge } from "client/components/scheduled-interviews/__components/ScheduledInterviewCardBadge";
import {
  ScheduledInterviewCard,
  ScheduledInterviewCardProps,
} from "client/components/scheduled-interviews/ScheduledInterviewCard";
import { useInternalScheduledInterviewCardStatuses } from "client/components/scheduled-interviews/ScheduledInterviewCard/__hooks/useInternalScheduledInterviewCardStatuses";
import { mapSchedulerInterviewToScheduledInterviewForCard } from "client/scheduler/utils/mapping";
import { SchedulerStagedScheduledInterview } from "client/scheduler/utils/types";
import { useMemo } from "react";
import { displayTextForScheduledInterviewFieldChange } from "shared/guide-scheduler/field-changes/utils";

import { useStagedInterviewsWithComputedFields } from "../../hooks/interviews";
import { validateStagedInterview } from "../../utils/validation";

type Props = {
  interview: SchedulerStagedScheduledInterview;
} & Omit<ScheduledInterviewCardProps, "scheduledInterview">;

export function SchedulerScheduledInterviewCard({
  interview,
  ...props
}: Props) {
  const scheduledInterview = useMemo(() => {
    return mapSchedulerInterviewToScheduledInterviewForCard(interview);
  }, [interview]);
  const interviewsWithSchedulerStatus = useStagedInterviewsWithComputedFields();
  const interviewWithComputedFields = useMemo(
    () =>
      interviewsWithSchedulerStatus.find((i) => i.id === interview.id) || null,
    [interviewsWithSchedulerStatus, interview]
  );
  const validation = useMemo(
    () =>
      interviewWithComputedFields
        ? validateStagedInterview(interviewWithComputedFields)
        : { isValid: false, messages: ["Something went wrong."] },
    [interviewWithComputedFields]
  );

  const timezone = useCalendarTimezone();

  const statuses = useInternalScheduledInterviewCardStatuses({
    scheduledInterview,
  });

  return (
    <ScheduledInterviewCard
      {...props}
      scheduledInterview={scheduledInterview}
      timezone={timezone}
      statuses={statuses}
      hideInternalStatuses
      Badge={
        <BadgeWithTooltip
          interview={interview}
          interviewsWithSchedulerStatus={interviewsWithSchedulerStatus}
        />
      }
      errorMessages={validation.isValid ? undefined : validation.messages}
    />
  );
}

/** Render scheduler status badge with tooltip outlining field changes */
function BadgeWithTooltip({
  interview,
  interviewsWithSchedulerStatus,
}: {
  interview: SchedulerStagedScheduledInterview;
  // Need to pass this in as the `ScheduledInterviewCard` has its own provider so
  // calling the hook in this component will not pull from the correct state
  interviewsWithSchedulerStatus: ReturnType<
    typeof useStagedInterviewsWithComputedFields
  >;
}) {
  const interviewWithStatus = useMemo(
    () =>
      interviewsWithSchedulerStatus.find((i) => i.id === interview.id) || null,
    [interviewsWithSchedulerStatus, interview]
  );
  const badgeTooltipContent = useMemo(() => {
    if (!interviewWithStatus || interviewWithStatus.status !== "updated") {
      return undefined;
    }

    if (interviewWithStatus.fieldChanges.length) {
      return `Changes: ${interviewWithStatus.fieldChanges
        .map((c) => displayTextForScheduledInterviewFieldChange(c.fieldName))
        .join(", ")}`;
    }

    return undefined;
  }, [interviewWithStatus]);

  return (
    <OptionalTooltip
      content={badgeTooltipContent}
      isInstant
      className="h-full flex flex-row items-center"
    >
      <ScheduledInterviewCardBadge
        status={interviewWithStatus?.status ?? null}
      />
    </OptionalTooltip>
  );
}
