import { gql } from "generated/graphql-codegen";
import { useCallback, useMemo } from "react";
import useMutation from "utils/useMutation";

const ADD_INTERVIEW_TO_WHITELIST = gql(`
  mutation AddInterviewToWhitelistForSwitch($input: AddTextToInterviewerWhitelistInput!) {
    addTextToInterviewerWhitelist(input: $input) {
      success
      code
      message
      userMembership {
        id
        interviewWhitelist
      }
    }
  }
`);

const REMOVE_INTERVIEW_FROM_WHITELIST = gql(`
  mutation RemoveInterviewFromWhitelistForSwitch($input: RemoveTextFromInterviewerWhitelistInput!) {
    removeTextFromInterviewerWhitelist(input: $input) {
      success
      code
      message
      userMembership {
        id
        interviewWhitelist
      }
    }
  }
`);

export function useWhitelistSwitchOnChange({
  title,
  userMembershipId,
  onCompleted,
}: {
  title: string;
  userMembershipId: string;
  onCompleted?: (val: boolean) => void;
}) {
  const [addInterviewToWhitelist, { loading: addLoading }] = useMutation(
    ADD_INTERVIEW_TO_WHITELIST,
    {
      onCompleted: (data) => {
        if (
          data.addTextToInterviewerWhitelist.success &&
          data.addTextToInterviewerWhitelist.userMembership?.interviewWhitelist.includes(
            title
          )
        ) {
          onCompleted?.(true);
        }
      },
    }
  );
  const [removeInterviewFromWhitelist, { loading: removeLoading }] =
    useMutation(REMOVE_INTERVIEW_FROM_WHITELIST, {
      onCompleted: (data) => {
        if (
          data.removeTextFromInterviewerWhitelist.success &&
          !data.removeTextFromInterviewerWhitelist.userMembership?.interviewWhitelist.includes(
            title
          )
        ) {
          onCompleted?.(false);
        }
      },
    });
  const onChange = useCallback(
    (val: boolean) => {
      if (val) {
        addInterviewToWhitelist({
          variables: {
            input: {
              text: title,
              userMembershipId,
            },
          },
        });
      } else {
        removeInterviewFromWhitelist({
          variables: {
            input: {
              text: title,
              userMembershipId,
            },
          },
        });
      }
    },
    [
      addInterviewToWhitelist,
      removeInterviewFromWhitelist,
      title,
      userMembershipId,
    ]
  );

  return useMemo(
    () => ({
      onChange,
      loading: addLoading || removeLoading,
    }),
    [addLoading, onChange, removeLoading]
  );
}
