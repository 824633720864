/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useState } from "react";

import { useEvent } from "../../../../__utils/react";
import {
  AtlasMenuContentDynamicItems,
  AtlasMenuStateProps,
} from "../../../../menu";
import { useMenuItems } from "../../../../menu/use-menu-items";
import { useMenuState } from "../../../../menu/use-menu-state";
import type { QueryMatch } from "./types";
import { useTypeaheadMenuControl } from "./use-typeahead-menu-control";

type TypeaheadMenuOptions = {
  typeahead: {
    open: boolean;
    hide: () => void;
    match: QueryMatch | undefined;
    getAnchorRect: () => DOMRect | null;
  };
  items: AtlasMenuContentDynamicItems;
  placement?: AtlasMenuStateProps["placement"];
  allowEmptyOpen?: boolean;
  isLoading?: boolean;
};

export function useTypeaheadMenu({
  typeahead,
  items: inputItems,
  placement,
  allowEmptyOpen,
  isLoading,
}: TypeaheadMenuOptions) {
  const { open, getAnchorRect, match } = typeahead;

  const resolvedOpen = open && (allowEmptyOpen ? inputItems.length > 0 : true);

  const menu = useMenuState({
    open: resolvedOpen,
    getAnchorRect,
    placement,
  });

  const [cachedItems, setCachedItems] = useState(inputItems);
  if (inputItems.length > 0 && cachedItems !== inputItems)
    setCachedItems(inputItems);

  const resolvedItems = resolvedOpen ? inputItems : cachedItems;

  useTypeaheadMenuControl(menu, typeahead);

  // set first item as active on query change (if opened)
  const setFirstItemAsActive = useEvent(() => {
    const firstItem = menu.baseRef.current?.querySelector('[role="menuitem"]');
    if (!firstItem) return;
    menu.setActiveId(firstItem.id);
  });
  const [renderCount, setRenderCount] = useState(0);
  const [done, setDone] = useState(false);
  const [prevQuery, setPrevQuery] = useState<string>();
  useEffect(() => {
    if (prevQuery !== match?.queryString) setDone(false);
    setPrevQuery(match?.queryString);
    // we need to schedule this action in one render to give ariakit time to collect the items
    if (renderCount < 1 && open && !done) setRenderCount(renderCount + 1);
    if (renderCount === 1) {
      setRenderCount(0);
      setDone(true);
      setFirstItemAsActive();
    }
    if (!open && done) setDone(false);
  }, [
    done,
    match?.queryString,
    open,
    prevQuery,
    renderCount,
    setFirstItemAsActive,
  ]);

  // recompute menu position on editor state update
  const [editor] = useLexicalComposerContext();
  const renderMenu = useEvent(menu.render);
  useEffect(
    () => editor.registerUpdateListener(renderMenu),
    [editor, renderMenu]
  );

  // add typeahead-specific behavior to menu items
  const { hide } = typeahead;
  const items = useMenuItems(
    (i) =>
      resolvedItems.map((item) => {
        // TODO: build a type-safe API to "copy" collection items
        // @ts-expect-error This is fine.
        if (item._type !== "item") return i[item._type](item);
        return i.item({
          ...item,
          focusOnHover: false,
          onClick: (event) =>
            editor.update(() => {
              item.onClick?.(event);
              if (!event.defaultPrevented) hide();
            }),
          size: "compact",
        });
      }),
    [editor, hide, resolvedItems]
  );

  // props for `Menu.Content`
  const menuContentProps = { items, portal: true, typeahead: false, isLoading };

  return { menu, menuContentProps };
}

// TODO: cover other cases where recomputing menu position is needed
// - some editor content changes size, e.g. a rich block that loads and renders remote data
// - editor/page scroll?
