import { useToast } from "@resource/atlas/toast/use-toast";
import { useAuthContext } from "auth/context";
import { gql } from "generated/graphql-codegen";
import { ScheduleInterviewForInterviewDetailsFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

const RESYNC_GOOGLE_CALENDAR = gql(`
  mutation resyncOutOfSyncGoogleCalendarEvent($scheduledInterviewId: String!) {
    updateGoogleEventForScheduledInterview(input: { scheduledInterviewId: $scheduledInterviewId }) {
      success
      message
      code
      scheduledInterview {
        ...ScheduleInterviewForInterviewDetails
      }
    }
  }
`);

const CREATE_GOOGLE_EVENT_MUTATION = gql(`
  mutation CreateGoogleEventForScheduledInterviewDetails($input: CreateGoogleEventFromScheduledInterviewInput!) {
    createGoogleEventFromScheduledInterview(input: $input) {
      code
      message
      success
      scheduledInterview {
        ...ScheduleInterviewForInterviewDetails
      }
    }
  }
`);

export function useResync({
  scheduledInterview,
}: {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
}) {
  const { user } = useAuthContext();
  const { sendToast } = useToast();
  const userDefaultCalendarId = user?.currentUserMembership?.defaultCalendarId;
  const [resyncGoogleCalendar, { loading: updateLoading }] = useMutation(
    RESYNC_GOOGLE_CALENDAR,
    {
      variables: { scheduledInterviewId: scheduledInterview.id },
    }
  );
  const [createGoogleEvent, { loading: createLoading }] = useMutation(
    CREATE_GOOGLE_EVENT_MUTATION
  );
  const isLoading = updateLoading || createLoading;

  const onResync = useCallback(async () => {
    if (isLoading) return;

    if (
      !scheduledInterview.googleEvent ||
      !scheduledInterview.googleEvent.isValid
    ) {
      const calendarId =
        scheduledInterview.googleEvent?.calendarId ?? userDefaultCalendarId;

      if (calendarId) {
        await createGoogleEvent({
          variables: {
            input: {
              scheduledInterviewId: scheduledInterview.id,
              addToCalendarId: calendarId,
            },
          },
        });
      } else {
        sendToast(
          "Please set a default scheduling calendar in your settings to recreate a google event",
          {
            variant: "error",
          }
        );
      }
    } else {
      await resyncGoogleCalendar();
    }
  }, [
    createGoogleEvent,
    isLoading,
    resyncGoogleCalendar,
    scheduledInterview.googleEvent,
    scheduledInterview.id,
    sendToast,
    userDefaultCalendarId,
  ]);

  return {
    resync: onResync,
    loading: isLoading,
  };
}
