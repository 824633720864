import { gql } from "generated/graphql-codegen";
import { useEffect, useMemo } from "react";
import useQuery from "utils/useQuery";

gql(`
  fragment SelfScheduleRequestForOpenRequestsSection on SelfScheduleRequest {
    id
    requestHref
    ...SelfScheduleRequestForBlock
  }
`);

const OPEN_SELF_SCHEDULE_REQUESTS_SECTION_SUBSCRIPTION = gql(`
  subscription OpenSelfScheduleRequestForGuide($guideId: ID!) {
    openSelfScheduleRequestsForGuide(guideId: $guideId) {
      ...SelfScheduleRequestForOpenRequestsSection
    }
  }
`);

const GUIDE_OPEN_REQUESTS_QUERY = gql(`
  query GuideOpenSelfScheduleRequests($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      openSelfScheduleRequests {
        ...SelfScheduleRequestForOpenRequestsSection
      }
    }
  }
`);

export function useOpenSelfScheduleRequests(
  guideId: string,
  {
    skip,
  }: {
    skip?: boolean;
  } = {}
) {
  const { data, loading, subscribeToMore } = useQuery(
    GUIDE_OPEN_REQUESTS_QUERY,
    {
      variables: {
        guideId,
      },
      skip,
    }
  );

  useEffect(() => {
    if (skip) return;

    subscribeToMore({
      document: OPEN_SELF_SCHEDULE_REQUESTS_SECTION_SUBSCRIPTION,
      variables: {
        guideId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;

        return {
          guideById: prev.guideById
            ? {
                ...prev.guideById,
                openSelfScheduleRequests:
                  subscriptionData.data.openSelfScheduleRequestsForGuide,
              }
            : null,
        };
      },
    });
  }, [guideId, skip, subscribeToMore]);

  return useMemo(
    () => ({
      openSelfScheduleRequests: data?.guideById?.openSelfScheduleRequests,
      loading,
    }),
    [data?.guideById?.openSelfScheduleRequests, loading]
  );
}
