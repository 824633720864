import { UserMembershipLoadDisplay } from "client/components/interviewer-slots/load/UserMembershipLoadDisplay";
import { useShowPoolLoad } from "client/components/interviewer-slots/load/utils/hooks";
import { UserMembershipWithSchedulingData } from "client/components/interviewer-slots/utils/types";

type InterviewLoadFooterProps = {
  userMembership: UserMembershipWithSchedulingData;
  isQualified: boolean;
  showDayCount: boolean;
  variant: "icon" | "header";
  size: "small" | "medium";
};

export default function InterviewLoadFooter({
  userMembership,
  isQualified,
  showDayCount,
  variant,
  size,
}: InterviewLoadFooterProps) {
  const showPoolLoad = useShowPoolLoad({ userMembership, isQualified });
  if (!userMembership.loadData) {
    return null;
  }

  const {
    loadLimits,
    interviewLoad,
    loadLimitsForPool,
    interviewLoadForPool,
    ignoreAccountLoadLimits,
  } = userMembership.loadData;

  // We only show icons and headers when pool load is also available.
  const showIcon = showPoolLoad && variant === "icon";
  const showHeader = showPoolLoad && variant === "header";

  return (
    <div>
      <UserMembershipLoadDisplay
        loadLimits={loadLimits}
        interviewLoad={interviewLoad}
        showDayCount={showDayCount}
        showIcon={showIcon}
        showHeader={showHeader}
        ignored={ignoreAccountLoadLimits}
        size={size}
      />
      {showPoolLoad && loadLimitsForPool && interviewLoadForPool && (
        <div className="mt-1">
          <UserMembershipLoadDisplay
            loadLimits={loadLimitsForPool}
            interviewLoad={interviewLoadForPool}
            showDayCount={showDayCount}
            showIcon={showIcon}
            showHeader={showHeader}
            isPoolLoad
            size={size}
          />
        </div>
      )}
    </div>
  );
}
