import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { useAuthContext } from "auth/context";
import {
  GoogleEventPreview,
  GoogleEventPreviewProps,
} from "client/components/google/components/GoogleEventPreview";
import { StagedScheduledInterview } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import {
  ExistingScheduledInterviewForSchedulerReviewPageFragment,
  GuideForSchedulerReviewPageFragment,
  PostTemplateForUseFragment,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";

import { ScheduledInterviewGroupSettings } from "../scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { ScheduledStatus } from "../scheduled-interviews/utils/types";
import { mapGuideAndScheduledInterviewToGoogleEventPreviewData } from "./utils/mapping";
import { OptionalAttendeeUserMembership } from "./utils/types";

export const getReviewInterviewElementId = (interviewId: string) =>
  `interview-${interviewId}`;

export type ReviewInterviewDetailsProps = {
  interview: StagedScheduledInterview;
  guide: GuideForSchedulerReviewPageFragment;
  existingScheduledInterview: ExistingScheduledInterviewForSchedulerReviewPageFragment | null;
  status: ScheduledStatus | null;
  optionalAttendeeUserMemberships: OptionalAttendeeUserMembership[] | undefined;
  sendNotifications: boolean;
  interviewerConfirmationTitle: AtlasContentEditorSerializedState | null;
  onChangeInterviewerConfirmationTitle: (
    title: AtlasContentEditorSerializedState
  ) => void;
  interviewerCustomInstructions: AtlasContentEditorSerializedState | null;
  defaultTemplate: PostTemplateForUseFragment | null;
  onChangeInterviewerCustomInstructions: (
    customInstructions: AtlasContentEditorSerializedState
  ) => void;
  onSelectTemplate: (t: PostTemplateForUseFragment) => void;
  scheduledInterviewGroupSettings?: ScheduledInterviewGroupSettings;
};

/**
 * Review and edit the notification details for a single interview during scheduling
 * Can be used during panel scheduling or single interview scheduling
 */
export function ReviewInterviewDetails({
  interview,
  status,
  guide,
  existingScheduledInterview,
  optionalAttendeeUserMemberships,
  sendNotifications,
  interviewerConfirmationTitle,
  interviewerCustomInstructions,
  defaultTemplate,
  onChangeInterviewerConfirmationTitle,
  onChangeInterviewerCustomInstructions,
  onSelectTemplate,
  scheduledInterviewGroupSettings,
}: ReviewInterviewDetailsProps) {
  const { user } = useAuthContext();
  const googlePreviewEventData = useMemo(
    () =>
      mapGuideAndScheduledInterviewToGoogleEventPreviewData({
        guide,
        scheduledInterview: interview,
        existingScheduledInterview,
        currentUser: user,
        scheduledInterviewGroupSettings,
      }),
    [
      guide,
      interview,
      existingScheduledInterview,
      user,
      scheduledInterviewGroupSettings,
    ]
  );

  const type = useMemo((): GoogleEventPreviewProps["type"] => {
    switch (status) {
      case "cancelled":
        return "cancellation";
      case "updated":
        return "update";
      case "created":
        return "confirmation";
      default:
        return "confirmation";
    }
  }, [status]);
  const interviewerConfirmationTitleWithFallback = useMemo(() => {
    return interviewerConfirmationTitle ?? JSON.parse(LEXICAL_EMPTY_STATE);
  }, [interviewerConfirmationTitle]);
  const interviewerCustomInstructionsWithFallback = useMemo(() => {
    return interviewerCustomInstructions ?? JSON.parse(LEXICAL_EMPTY_STATE);
  }, [interviewerCustomInstructions]);

  return (
    <div id={getReviewInterviewElementId(interview.id)} className="py-2">
      <GoogleEventPreview
        data={googlePreviewEventData}
        optionalParticipants={optionalAttendeeUserMemberships}
        type={type}
        interviewerConfirmationTitle={interviewerConfirmationTitleWithFallback}
        onChangeTitle={onChangeInterviewerConfirmationTitle}
        interviewerCustomInstructions={
          interviewerCustomInstructionsWithFallback
        }
        onChangeCustomInstructions={onChangeInterviewerCustomInstructions}
        onSelectTemplate={onSelectTemplate}
        defaultTemplate={defaultTemplate}
        disableEmailPreview={!sendNotifications}
      />
    </div>
  );
}
