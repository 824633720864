/* eslint-disable @typescript-eslint/ban-ts-comment */
import "@uploadcare/blocks/blocks/themes/lr-basic/index.css";

import { useApolloClient } from "@apollo/client";
import { FileMetadata } from "@resource/atlas/content-editor/file";
import { useUser } from "auth/useUser";
import { useGuideIdContext } from "client/utils/guide-id-provider";
import { usePollingIntervalConfig } from "client/utils/polling";
import { gql } from "generated/graphql-codegen";
import {
  GetLinkPreviewForRichBlockQuery,
  GetLinkPreviewForRichBlockQueryVariables,
} from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useRef, useState } from "react";
import { useEventCallback } from "react-hooks/useEventCallback";
import { loadUploadcare } from "utils/loadUploadcare";
import useQuery from "utils/useQuery";
import { v4 as uuidv4 } from "uuid";

const GENERATE_UPLOADCARE_SIGNATURE = gql(`
query GenerateUploadcareSignatureForImageDialog($guideId: ID) {
    uploadcareSignature(guideId: $guideId) {
      signature
      expire
    }
  }
`);

export const IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/avif",
  "image/gif",
];

loadUploadcare();

export function useReplaceFileData(mimeTypes: string[]) {
  const user = useUser();
  const [contextName] = useState(`uploader-${uuidv4()}`);
  const guideContext = useGuideIdContext();

  const setFileInfoRef = useRef<(metadata: FileMetadata) => void>();

  const { uploadCareSignature: pollInterval } = usePollingIntervalConfig();

  const { data } = useQuery(GENERATE_UPLOADCARE_SIGNATURE, {
    fetchPolicy: "network-only",
    pollInterval,
    variables: {
      guideId: guideContext?.guideId ?? null,
    },
    skip: !(guideContext?.guideId || user),
  });
  const uploaderRef = useRef<HTMLElement>(null);
  const dataRef = useRef<HTMLElement>(null);
  const onReplaceFile = useEventCallback(
    (setFileInfo: (metadata: FileMetadata) => void) => {
      if (!guideContext?.guideId && !user) {
        throw new Error("GuideId or user is required to use file upload");
      }
      setFileInfoRef.current = setFileInfo;
      uploaderRef.current?.querySelector("button")?.click();
    }
  );

  const onFileUploaded = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (e.detail.ctx === contextName && e.detail.data.length > 0) {
        const rawResponse = e.detail.data[0];

        const data = {
          mimeType: rawResponse.contentInfo.mime.mime,
          name: rawResponse.originalFilename,
          fileSize: rawResponse.size,
          url: rawResponse.cdnUrl,
          uuid: rawResponse.uuid,
        } as FileMetadata;

        setFileInfoRef.current?.(data);
        if (dataRef.current) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (dataRef.current as any).uploadCollection.clearAll(); // clear upload collection
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (dataRef.current as any).$["*modalActive"] = false; // hide modal
        }
      }
    },
    [contextName]
  );

  useEffect(() => {
    window.addEventListener("LR_DATA_OUTPUT", onFileUploaded);
    return () => window.removeEventListener("LR_DATA_OUTPUT", onFileUploaded);
  }, [onFileUploaded]);

  const uploaderConfig = {
    "--ctx-name": `"${contextName}"`,
    "--cfg-pubkey": `"${process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY ?? ""}"`,
    "--cfg-secure-signature": `"${data?.uploadcareSignature?.signature || ""}"`,
    "--cfg-secure-expire": `"${data?.uploadcareSignature?.expire || ""}"`,
    "--cfg-multiple": 0,
    "--cfg-confirm-upload": 0,
    "--cfg-source-list": "'local, url'",
    "--cfg-accept": `'${mimeTypes.join(",")}'`,
    "--l10n-drop-files-here": `"Drop file here"`,
  };

  const fileChooserInput = (
    <>
      {/* @ts-ignore */}
      <lr-file-uploader-regular
        ref={uploaderRef}
        class="lr-wgt-common [&>lr-simple-btn]:hidden"
        style={uploaderConfig}
      >
        {/* @ts-ignore */}
        <lr-data-output ref={dataRef} ctx-name={contextName} />
        {/* @ts-ignore */}
      </lr-file-uploader-regular>
    </>
  );

  return { onReplaceFile, fileChooserInput };
}

export function useReplaceImageData() {
  const { onReplaceFile, fileChooserInput } =
    useReplaceFileData(IMAGE_MIME_TYPES);

  const onReplaceImage = useEventCallback(
    (setImageUrl: (imageUrl: string) => void) => {
      onReplaceFile((metadata: FileMetadata) => setImageUrl(metadata.url));
    }
  );

  return { onReplaceImage, fileChooserInput };
}

const GET_LINK_PREVIEW = gql(`
query GetLinkPreviewForRichBlock($url: String!) {
    getLinkPreview(url: $url) {
      title
      image
      description
    }
  }
`);

export function useFetchUrlMetadata() {
  const apolloClient = useApolloClient();
  const fetchUrlMetadata = useCallback(
    async (url: string) => {
      const result = await apolloClient.query<
        GetLinkPreviewForRichBlockQuery,
        GetLinkPreviewForRichBlockQueryVariables
      >({
        query: GET_LINK_PREVIEW,
        variables: {
          url,
        },
      });

      return result.data.getLinkPreview;
    },
    [apolloClient]
  );

  return fetchUrlMetadata;
}
