import { Combobox } from "@resource/atlas/combobox-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasInterviewers } from "@resource/atlas/icons";
import { strings } from "@resource/common";

import { ColorizedPoolIcon } from "./interview-requirements/__components/ColorizedPoolIcon";

export type InterviewerPoolForPoolItem = {
  id: string;
  name: string;
  qualifiedUserCount: number;
  traineeUserCount: number;
};

interface PoolItemProps {
  pool: InterviewerPoolForPoolItem;
  onSelect?: () => void;
  value?: string;
}

export function PoolItem({ pool, onSelect, value }: PoolItemProps) {
  const hasStats = pool.qualifiedUserCount > 0 || pool.traineeUserCount > 0;

  return (
    <Combobox.Item
      value={value || pool.id}
      onClick={onSelect}
      setValueOnClick={false}
    >
      <div className="flex items-start gap-2">
        <ColorizedPoolIcon variant="default" />
        <div className="flex-grow">
          <p className="text-body-md text-dark">{pool.name}</p>
          {hasStats && (
            <div className="flex items-center gap-3 mt-1">
              <div className="flex items-center gap-1.5">
                <Icon
                  content={atlasInterviewers}
                  className="text-subtle w-4 h-4"
                />
                <span className="text-body-xs text-subtle">
                  {strings.pluralize("interviewer", pool.qualifiedUserCount)}
                </span>
              </div>
              <div className="flex items-center gap-1.5">
                <Icon
                  content={atlasInterviewers}
                  className="text-subtle w-4 h-4"
                />
                <span className="text-body-xs text-subtle">
                  {strings.pluralize("trainee", pool.traineeUserCount)}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Combobox.Item>
  );
}
