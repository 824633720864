import { Select } from "@resource/atlas/select";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { GuideAvailabilitySubmissionSchedulingPreference } from "generated/graphql-codegen/graphql";

type SchedulingPreferencesSelectProps = {
  value: GuideAvailabilitySubmissionSchedulingPreference;
  onChange: (value: GuideAvailabilitySubmissionSchedulingPreference) => void;
};

const mapSchedulingPreferenceToDisplay = {
  [GuideAvailabilitySubmissionSchedulingPreference.FLEXIBLE]: "Flexible",
  [GuideAvailabilitySubmissionSchedulingPreference.SAME_DAY]: "Same day",
};

export function SchedulingPreferencesSelect({
  value,
  onChange,
}: SchedulingPreferencesSelectProps) {
  const selectItems = useSelectItems((i) => {
    return Object.values(GuideAvailabilitySubmissionSchedulingPreference).map(
      (preference) => {
        return i.option({
          renderContent: ({ children }) => (
            <div className="flex">{children}</div>
          ),
          children: mapSchedulingPreferenceToDisplay[preference],
          value: preference,
        });
      }
    );
  }, []);

  return (
    <Select.Root
      value={value}
      setValue={(v) =>
        onChange(v as GuideAvailabilitySubmissionSchedulingPreference)
      }
    >
      <Select.Trigger className="w-full bg-light-gray-500" />
      <Select.Content items={selectItems} className="w-96" />
    </Select.Root>
  );
}
