import { createValueSet } from "@resource/atlas/content-editor/variables";
import { interviewVariableKeys } from "modules/lexical/shared/utils/constants";
import { InterviewVariableSet } from "modules/lexical/shared/utils/types";
import { createVariableSets } from "modules/lexical/shared/variables";
import { useMemo } from "react";

export type { InterviewVariableSet };
const {
  nameSet: interviewNameSet,
  rendererSet: interviewRendererSet,
  specSet: interviewSpecSet,
} = createVariableSets(interviewVariableKeys);

export { interviewNameSet, interviewRendererSet, interviewSpecSet };

export function useInterviewValueSet({
  replacementData,
}: {
  replacementData?: Record<string, unknown>;
}) {
  return useMemo(() => {
    if (!replacementData) return {};

    return createValueSet<InterviewVariableSet>({
      atsRecruiterName: {
        first: (replacementData["recruiter-first-name"] as string) ?? "",
        last: (replacementData["recruiter-last-name"] as string) ?? "",
      },
      atsCoordinatorName: {
        first: (replacementData["coordinator-first-name"] as string) ?? "",
        last: (replacementData["coordinator-last-name"] as string) ?? "",
      },
      candidateName: {
        first: (replacementData["candidate-first-name"] as string) ?? "",
        last: (replacementData["candidate-last-name"] as string) ?? "",
      },
      jobTitle: (replacementData["job-role-name"] as string) ?? "",
      jobListingLink: (replacementData["greenhouse-job-link"] as string) ?? "",
      companyName: (replacementData["company-name"] as string) ?? "",
      firstInterviewerName: {
        first:
          (replacementData["first-interviewer-first-name"] as string) ?? "",
        last: (replacementData["first-interviewer-last-name"] as string) ?? "",
      },
      firstInterviewerJobTitle: replacementData[
        "first-interviewer-title"
      ] as string,
      interviewerNames: {
        firstNames:
          (replacementData["all-interviewers-first-names"] as string) ?? "",
        fullNames:
          (replacementData["all-interviewers-full-names"] as string) ?? "",
      },
    });
  }, [replacementData]);
}
