import StopPropagation from "components/StopPropagation";
import { gql } from "generated/graphql-codegen";
import { InterviewDetailsAttendeesInterviewerFragment } from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef } from "react";

import { InterviewerCard } from "../InterviewerCard";

const INTERVIEWER_FRAGMENT = gql(`
fragment InterviewDetailsAttendeesInterviewer on Interviewer {
    id
    ...InterviewerCardInterviewer
    userMembership {
      ...InterviewerDetailsDialogInterviewer
    }
  }
`);

type AttendeesSectionProps = ComponentPropsWithoutRef<"section"> & {
  interviewers: InterviewDetailsAttendeesInterviewerFragment[];
  onClickInterviewer?: (
    interviewer: InterviewDetailsAttendeesInterviewerFragment
  ) => void;
};
export function AttendeesSection({
  interviewers,
  className,
  onClickInterviewer,
  ...props
}: AttendeesSectionProps) {
  return (
    <section className={className} {...props}>
      <p className="text-body-md-heavy">Attendees</p>
      <section className="mt-2">
        <StopPropagation>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            {interviewers.map((interviewer) => (
              <InterviewerCard
                key={interviewer.id}
                interviewer={interviewer}
                onClick={() => onClickInterviewer?.(interviewer)}
              />
            ))}
          </div>
        </StopPropagation>
      </section>
    </section>
  );
}

AttendeesSection.fragments = {
  interviewer: INTERVIEWER_FRAGMENT,
};
