import { Select } from "@resource/atlas/select";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import _ from "lodash";
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";

const CUSTOM_KEY = "custom";
const EMPTY_KEY = "";
const DEFAULT_CHOICES = ["He/Him/His", "She/Her/Hers", "They/Them/Theirs"];
const OPTIONS = [
  [EMPTY_KEY, "--"],
  ..._.map(DEFAULT_CHOICES, (result) => [result, result]),
  [CUSTOM_KEY, "Custom..."],
];

type PronounSelectProps = {
  isDisabled?: boolean;
  isGhost?: boolean;
  value?: string | null;
  className?: string;
  onChange(newValue: string | null): void;
  validationState?: "valid" | "invalid";
  errorMessage?: React.ReactNode;
};

const PronounSelect = forwardRef(
  (
    {
      isDisabled = false,
      isGhost = false,
      className = "",
      value,
      onChange,
      errorMessage,
      validationState,
    }: PronounSelectProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const isCustom = value && !DEFAULT_CHOICES.includes(value);

    const getSelectValue = useCallback(
      () => (isCustom ? CUSTOM_KEY : value || EMPTY_KEY),
      [isCustom, value]
    );

    const [selectValue, setSelectValue] = useState(getSelectValue());
    const showCustomText = selectValue === CUSTOM_KEY;

    const selectPronounChoice = (selectedKey: string) => {
      setSelectValue(selectedKey);
      if (selectedKey === EMPTY_KEY) {
        onChange(null);
      } else if (selectedKey === CUSTOM_KEY) {
        onChange("");
      } else {
        onChange(selectedKey);
      }
    };

    const items = useSelectItems(
      (i) =>
        OPTIONS.map(([choice, label]) =>
          i.option({
            key: choice,
            children: label,
            value: choice,
          })
        ),
      []
    );

    useEffect(() => {
      if (value !== null && value !== "") {
        setSelectValue(getSelectValue());
      }
    }, [getSelectValue, value]);

    return (
      <div className="flex flex-col" ref={ref}>
        <Select.Root
          value={selectValue}
          setValue={(key) => selectPronounChoice(key as string)}
        >
          <Select.Trigger className="w-full bg-light-gray-500" />
          <Select.Content
            className={clsx([isGhost && "-ml-3", className])}
            items={items}
          />
        </Select.Root>
        {showCustomText && (
          <TextField
            isGhost={isGhost}
            isDisabled={isDisabled}
            validationState={validationState}
            errorMessage={errorMessage}
            onChange={onChange}
            value={(isCustom && value) || ""}
            placeholder={value ? "Edit pronouns" : "Add pronouns"}
            aria-label="Pronouns"
          />
        )}
      </div>
    );
  }
);
export default PronounSelect;
