import {
  atlasLogoMicrosoftTeamsColor,
  atlasVideo,
  serviceCoderpad,
  serviceCodesignal,
  serviceGoogleMeet,
  serviceHackerrank,
  serviceZoom,
} from "@resource/atlas/icons";
import {
  serviceCoderpadPast,
  serviceGoogleMeetPast,
  serviceTeamsPast,
  serviceZoomPast,
} from "client/components/scheduled-interviews/PastInterviewIcons";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import { VideoConferencingServiceType } from "shared/guide-scheduler/video-conferencing/types";

import {
  isCoderpadCodingUrl,
  isCodeSignalCodingUrl,
  isHackerRankCodingUrl,
} from "../guide-scheduler/collaborative-coding/utils";
import {
  isGoogleMeetConferencingUrl,
  isTeamsConferencingUrl,
  isZoomConferencingUrl,
} from "./video-conferencing";

export const getCollaborativeCodingImageForService = (
  service: CollaborativeCodingServiceType
) => {
  switch (service) {
    case CollaborativeCodingServiceType.CODERPAD:
      return serviceCoderpad;
    case CollaborativeCodingServiceType.CODESIGNAL:
      return serviceCodesignal;
    case CollaborativeCodingServiceType.HACKERRANK:
      return serviceHackerrank;
    default:
      return null;
  }
};

export const getVideoConferencingImageForService = (
  service: VideoConferencingServiceType
) => {
  switch (service) {
    case VideoConferencingServiceType.ZOOM:
      return serviceZoom;
    case VideoConferencingServiceType.GOOGLE_MEET:
      return serviceGoogleMeet;
    case VideoConferencingServiceType.MICROSOFT_TEAMS:
      return atlasLogoMicrosoftTeamsColor;
    default:
      return atlasVideo;
  }
};

export const getCollaborativeCodingServiceType = (
  value?: string | null
): CollaborativeCodingServiceType | null => {
  if (isCoderpadCodingUrl(value ?? "")) {
    return CollaborativeCodingServiceType.CODERPAD;
  }

  if (isCodeSignalCodingUrl(value ?? "")) {
    return CollaborativeCodingServiceType.CODESIGNAL;
  }

  if (isHackerRankCodingUrl(value ?? "")) {
    return CollaborativeCodingServiceType.HACKERRANK;
  }
  return null;
};

export const getVideoConferencingServiceType = (value?: string | null) => {
  if (isZoomConferencingUrl(value ?? "")) {
    return VideoConferencingServiceType.ZOOM;
  }

  if (isGoogleMeetConferencingUrl(value ?? "")) {
    return VideoConferencingServiceType.GOOGLE_MEET;
  }

  if (isTeamsConferencingUrl(value ?? "")) {
    return VideoConferencingServiceType.MICROSOFT_TEAMS;
  }

  return null;
};

export function getVideoConferencingIcon({
  service,
  isInPast,
}: {
  service: VideoConferencingServiceType;
  isInPast?: boolean;
}) {
  switch (service) {
    case VideoConferencingServiceType.ZOOM:
      return isInPast ? serviceZoomPast : serviceZoom;
    case VideoConferencingServiceType.GOOGLE_MEET:
      return isInPast ? serviceGoogleMeetPast : serviceGoogleMeet;
    case VideoConferencingServiceType.MICROSOFT_TEAMS:
      return isInPast ? serviceTeamsPast : atlasLogoMicrosoftTeamsColor;
    default:
      return null;
  }
}

export function getCollaborativeCodingIcon({
  service,
  isInPast,
}: {
  service: CollaborativeCodingServiceType;
  isInPast?: boolean;
}) {
  switch (service) {
    case CollaborativeCodingServiceType.CODERPAD:
      return isInPast ? serviceCoderpadPast : serviceCoderpad;
    case CollaborativeCodingServiceType.CODESIGNAL:
      return isInPast ? serviceCodesignal : serviceCodesignal;
    case CollaborativeCodingServiceType.HACKERRANK:
      return isInPast ? serviceHackerrank : serviceHackerrank;
    default:
      return null;
  }
}

export function getVideoConferencingServiceDisplayName(
  service: VideoConferencingServiceType
): string {
  switch (service) {
    case VideoConferencingServiceType.ZOOM:
      return "Zoom";
    case VideoConferencingServiceType.GOOGLE_MEET:
      return "Google Meet";
    case VideoConferencingServiceType.MICROSOFT_TEAMS:
      return "Microsoft Teams";
    default:
      return "video";
  }
}

export function getCollaborativeCodingServiceDisplayName(
  service: CollaborativeCodingServiceType
): string {
  switch (service) {
    case CollaborativeCodingServiceType.CODERPAD:
      return "CoderPad";
    case CollaborativeCodingServiceType.CODESIGNAL:
      return "CodeSignal";
    case CollaborativeCodingServiceType.HACKERRANK:
      return "HackerRank";
    default:
      return "collaborative coding";
  }
}
