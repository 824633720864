/* eslint-disable import/prefer-default-export */

import { createComponentUtils } from "../../../__utils/atlas";
import { createNodeRenderer } from "../../renderer";
import { File } from "./File";
import type { SerializedFileNode } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-LinkCard_node-renderer";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// node renderer
// -------------

export const renderFileNode = createNodeRenderer<SerializedFileNode>(
  ({ data }) => <File {...data} openDownloadDialogOnLoad className={ROOT} />
);
