import { OptionSectionHeading } from "@resource/atlas/option/OptionSectionHeading";
import { groupBy, sortBy } from "lodash";
import { useMemo } from "react";

import { ConferenceRoomOption } from "../display/ConferenceRoomOption";
import { Building, ConferenceRoom } from "../utils/types";

type UseConferenceRoomSelectOptions = {
  buildings: Building[];
  conferenceRooms: ConferenceRoom[];
  searchTerm: string;
  selectedIds?: string[];
  onClick: (conferenceRoom: ConferenceRoom) => void;
};

export function useConferenceRoomSelectOptions({
  buildings,
  conferenceRooms,
  searchTerm,
  selectedIds,
  onClick,
}: UseConferenceRoomSelectOptions) {
  const options = useMemo(() => {
    const buildingsToShow = buildings.filter((v) =>
      v.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const roomsToShow = conferenceRooms
      .filter((v) => {
        // Show all rooms for a building that matches the search, otherwise filter by conference room name

        const buildingMatchesSearch = buildingsToShow.some(
          (b) => b.id === v.building?.id
        );
        if (buildingMatchesSearch) {
          return true;
        }

        return v.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .filter((v) => !selectedIds?.includes(v.id));

    const groupedRooms = groupBy(roomsToShow, "building.id");

    return Object.entries(groupedRooms)
      .sort(([a], [b]) => a.localeCompare(b))
      .flatMap(([buildingId, buildingRooms]) => {
        const building = buildings.find((b) => b.id === buildingId);

        const heading = (
          <OptionSectionHeading>
            {building?.name ?? "No building"}
          </OptionSectionHeading>
        );

        const sortedRooms = sortBy(buildingRooms, [
          (room) => room.availability !== "available",
          "name",
        ]);

        return [
          heading,
          ...sortedRooms.map((conferenceRoom) => (
            <ConferenceRoomOption
              key={conferenceRoom.id}
              conferenceRoom={conferenceRoom}
              onClick={() => {
                onClick(conferenceRoom);
              }}
            />
          )),
        ];
      });
  }, [buildings, conferenceRooms, onClick, searchTerm, selectedIds]);

  return options;
}
