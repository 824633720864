import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { TimeConfig } from "shared/self-schedule/types";

type TimeConfigSelectProps = {
  value: TimeConfig;
  onChange: (value: TimeConfig) => unknown;
};

export function TimeConfigSelect({ value, onChange }: TimeConfigSelectProps) {
  return (
    <div className="flex flex-row gap-1">
      <div className="w-16">
        <TextField
          type="number"
          value={value.value.toString()}
          onChange={(newValue) => {
            onChange({
              ...value,
              value: parseInt(newValue, 10),
            });
          }}
        />
      </div>
      <div className="flex-grow">
        <TimeConfigUnitSelect
          currentUnit={value.unit}
          onSelectUnit={(unit) => {
            onChange({
              ...value,
              unit,
            });
          }}
        />
      </div>
    </div>
  );
}

const unitToDisplay: {
  [key in TimeConfig["unit"]]: string;
} = {
  hours: "Hours",
  minutes: "Minutes",
  seconds: "Seconds",
};

type TimeConfigUnitSelectProps = {
  currentUnit: TimeConfig["unit"];
  onSelectUnit: (unit: TimeConfig["unit"]) => unknown;
  className?: string;
};

function TimeConfigUnitSelect({
  currentUnit,
  onSelectUnit,
  className,
}: TimeConfigUnitSelectProps) {
  const durationMenuItems = useMenuItems(
    (i) => {
      return (Object.keys(unitToDisplay) as TimeConfig["unit"][]).map(
        (unit) => {
          return i.item({
            children: unitToDisplay[unit],
            value: unit,
            onSelect: () => {
              onSelectUnit(unit);
            },
          });
        }
      );
    },
    [onSelectUnit]
  );

  return (
    <Menu.Root sameWidth>
      <Menu.Trigger>
        <div
          className={clsx(
            "flex justify-between w-full bg-light-gray-500 rounded-md px-2 py-[10px] text-body-md cursor-pointer",
            className
          )}
        >
          <span>{unitToDisplay[currentUnit]}</span>
          <Icon content={atlasChevronDown} />
        </div>
      </Menu.Trigger>
      <Menu.Content items={durationMenuItems} />
    </Menu.Root>
  );
}
