import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPeople, atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { RecruiterForSelectionFragment } from "generated/graphql-codegen/graphql";
import { useEffect, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

gql(`
fragment RecruiterForSelection on UserMembership {
  id
  name
  imageUrl
  email
}
`);

const FETCH_RECRUITERS_QUERY = gql(`
  query FetchRecruitersQuery($search: String, $limit: Int) {
    userMemberships(search: $search, limit: $limit) {
      ...RecruiterForSelection
    }
  }
`);

const UPDATE_GUIDE_RECRUITER = gql(`
mutation UpdateGuideRecruiter($input: UpdateGuideAndCandidateDetailsInput!) {
  updateGuideAndCandidateDetails(input: $input) {
    success
    code
    message
    guide {
      id
      recruiter {
        ...RecruiterForSelection
      }
    }
  }
}
`);

type RecruiterSelectProps = {
  guideId: string;
  recruiter?: RecruiterForSelectionFragment;
  onRecruiterUpdate?: () => void;
  className?: string;
};

export function RecruiterSelect({
  guideId,
  recruiter,
  onRecruiterUpdate,
  className,
}: RecruiterSelectProps) {
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");
  const popoverState = usePopoverState();

  const { data, loading } = useQuery(FETCH_RECRUITERS_QUERY, {
    variables: {
      search: debouncedTerm,
      limit: 25,
    },
  });

  const [updateRecruiter, { loading: updating }] = useMutation(
    UPDATE_GUIDE_RECRUITER,
    {
      onCompleted: () => {
        onRecruiterUpdate?.();
        popoverState.hide();
      },
    }
  );

  const recruiters = useMemo(() => data?.userMemberships ?? [], [data]);

  const options = useMemo(
    () =>
      recruiters.map((userMembership: RecruiterForSelectionFragment) => (
        <OptionItem
          key={userMembership.id}
          isSelectable
          isSelected={recruiter ? userMembership.id === recruiter.id : false}
          onClick={() => {
            updateRecruiter({
              variables: {
                input: {
                  guideId,
                  recruiterId: userMembership.id,
                },
              },
            });
          }}
          size="compact"
        >
          <div className="flex gap-2 items-center">
            <Avatar
              size="xs"
              image={userMembership.imageUrl}
              name={userMembership.name || userMembership.email}
            />
            <div title={userMembership.name || userMembership.email}>
              {userMembership.name || userMembership.email}
            </div>
          </div>
        </OptionItem>
      )),
    [recruiter, recruiters, guideId, updateRecruiter]
  );

  useEffect(() => {
    if (!popoverState.open) {
      setSearchTerm("");
    }
  }, [popoverState.open, setSearchTerm]);

  const renderedTrigger = useMemo(() => {
    return (
      <SelectTrigger
        size="xs"
        className={clsx(
          "flex-1 bg-transparent w-fit flex-grow -ml-2.5 py-1",
          className
        )}
      >
        {recruiter ? (
          <div className="flex gap-2 items-center">
            <Avatar
              size="xs"
              image={recruiter.imageUrl}
              name={recruiter.name}
            />
            <div title={recruiter.name} className="text-body-sm">
              {recruiter.name}
            </div>
          </div>
        ) : (
          <div className="flex gap-1.5 items-center mr-1">
            <Icon content={atlasPeople} className="text-subtle" />
            <p className="text-body-sm">No recruiter assigned</p>
          </div>
        )}
      </SelectTrigger>
    );
  }, [recruiter, className]);

  if (updating) {
    return (
      <div className="h-full flex overflow-hidden py-1">
        <LoadingIndicator size="small" />
      </div>
    );
  }

  return (
    <div className="flex gap-2">
      <Popover.Root state={popoverState}>
        <Popover.Trigger>{renderedTrigger}</Popover.Trigger>
        <Popover.Content className="w-[30rem]" hasPadding={false}>
          <TextField
            className="m-2"
            size="small"
            value={searchTerm}
            autoFocus
            icon={atlasSearch}
            placeholder="Search"
            aria-label="Search"
            onChange={setSearchTerm}
            isClearable
          />
          <div className="p-[.5rem] pt-0">
            {loading && (
              <>
                <OptionSeparator />
                <div className="flex justify-center">
                  <LoadingIndicator size="small" />
                </div>
              </>
            )}
            {!loading && options.length > 0 && <>{options}</>}
            {!loading && options.length === 0 && (
              <>
                <OptionSeparator />
                <p className="px-5 py-2 text-body-md">No results found</p>
              </>
            )}
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}
