import { Button as AriaButton } from "@ariakit/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCopy } from "@resource/atlas/icons";
import { useCopyToClipboard } from "client/hooks/useCopyToClipboard";
import { gql } from "generated/graphql-codegen/gql";
import { VideoConferencingDataForScheduledInterviewCardFragment } from "generated/graphql-codegen/graphql";
import { getVideoConferencingIcon } from "shared/utils/interview-services";

gql(`fragment VideoConferencingDataForScheduledInterviewCard on ScheduledInterviewDataVideoConferencing {
  id
  type
  url
  externalId
  passcode
}`);

type VideoConferencingDataProps = {
  data: VideoConferencingDataForScheduledInterviewCardFragment;
};

export function VideoConferencingData({
  data: { url, type, externalId, passcode },
}: VideoConferencingDataProps) {
  const copyToClipboard = useCopyToClipboard();

  const icon = getVideoConferencingIcon({ service: type });

  return (
    <div className="flex w-full space-x-3 items-start">
      {icon && <Icon content={icon} className="h-5 w-5" />}
      <div className="flex-grow flex flex-col truncate">
        <AriaButton
          onClick={() => copyToClipboard(url)}
          className="group flex flex-row space-x-2 max-w-full overflow-hidden"
        >
          <span className="truncate" title={url}>
            {url}
          </span>
          <Icon
            content={atlasCopy}
            className="opacity-0 group-hover:opacity-100"
          />
        </AriaButton>
        {!!externalId && <div className="text-subtle">ID: {externalId}</div>}
        {!!passcode && <div className="text-subtle">Passcode: {passcode}</div>}
      </div>
    </div>
  );
}
