import {
  Popover,
  PopoverDisclosure,
  PopoverProvider,
  usePopoverStore,
} from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import {
  atlasCalendarEvents,
  atlasChevronLeft,
  atlasChevronRight,
} from "@resource/atlas/icons";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { DateTime, MonthNumbers } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

const useCalendarGridDays = ({
  year,
  month,
}: {
  year: number;
  month: MonthNumbers;
}) => {
  return useMemo(() => {
    const now = DateTime.local();

    // Offset week by 1 to start on Sunday
    let currentGridDate = DateTime.local(year, month)
      .startOf("week")
      .minus({ day: 1 });
    const endMonth = DateTime.local(year, month).endOf("month");
    const endMonthOffset = 6 - (endMonth.weekday % 7);
    const endMonthGrid = endMonth.plus({
      days: endMonthOffset === 0 ? 7 : endMonthOffset,
    });

    const days = [];

    while (currentGridDate <= endMonthGrid) {
      const isCurrentMonth = currentGridDate.month === month;
      const isInPast = currentGridDate < now.startOf("day");
      const isToday = currentGridDate.hasSame(now, "day");

      days.push({
        date: currentGridDate,
        isCurrentMonth,
        isInPast,
        isToday,
      });

      currentGridDate = currentGridDate.plus({ days: 1 });
    }

    return days;
  }, [year, month]);
};

type DatePickerProps = {
  value?: DateTime;
  onChange?: (value: DateTime) => void;
  allowPastSelection?: boolean;
};

function DatePickerContent({
  value,
  onChange,
  allowPastSelection,
}: DatePickerProps) {
  const [displayDate, setDisplayDate] = useState(value ?? DateTime.local());
  const days = useCalendarGridDays({
    year: displayDate.year,
    month: displayDate.month,
  });

  const getIsDisabled = useCallback(
    (isInPast: boolean) => {
      return !allowPastSelection && isInPast;
    },
    [allowPastSelection]
  );

  const getIsSelected = useCallback(
    (date: DateTime) => {
      return value && +date.startOf("day") === +value.startOf("day");
    },
    [value]
  );

  // If the value changes, we want to show that date as the default
  useEffect(() => {
    setDisplayDate(value ?? DateTime.local());
  }, [value]);

  return (
    <div>
      <div className="flex items-center pt-2 px-2">
        <Button
          size="small"
          isGhost
          icon={atlasChevronLeft}
          onClick={() => setDisplayDate((d) => d.minus({ month: 1 }))}
        />
        <div className="flex-grow text-body-md-heavy text-center">
          {displayDate.toFormat("LLLL, yyyy")}
        </div>
        <Button
          size="small"
          isGhost
          icon={atlasChevronRight}
          onClick={() => setDisplayDate((d) => d.plus({ month: 1 }))}
        />
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-md bg-light-gray-500 text-sm shadow-1 ring-1 ring-light-gray-500">
        {days.map((day, dayIdx) => {
          const isDisabled = getIsDisabled(day.isInPast);
          const isSelected = getIsSelected(day.date);

          return (
            <button
              key={day.date.toISODate()}
              type="button"
              className={clsx(
                "py-1.5 w-10 focus:z-10",
                isDisabled && "cursor-not-allowed",
                !isDisabled && "hover:bg-light-gray-200",
                isSelected && "text-white bg-purple-500 hover:bg-purple-400",
                !isSelected && day.isCurrentMonth && "bg-white",
                !isSelected && !day.isCurrentMonth && "bg-light-gray-100",
                {
                  "rounded-bl-lg": dayIdx === days.length - 7,
                  "rounded-br-lg": dayIdx === days.length - 1,
                }
              )}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                onChange?.(day.date);
              }}
            >
              <time
                dateTime={day.date.toISODate()}
                className={clsx(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  ...(isSelected
                    ? [
                        day.isToday &&
                          "text-body-md-heavy text-purple-500 bg-white",
                        isDisabled && "text-white",
                      ]
                    : [
                        day.isToday && "text-body-md-heavy text-purple-500",
                        isDisabled && "text-disabled",
                        !isDisabled && !day.isCurrentMonth && "text-subtle",
                      ])
                )}
              >
                {day.date.day}
              </time>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export function DatePicker({ value, onChange, ...props }: DatePickerProps) {
  const store = usePopoverStore();

  const onChangeInner = useCallback(
    (day: DateTime) => {
      onChange?.(day);
      store.hide();
    },
    [onChange, store]
  );

  return (
    <>
      <PopoverProvider store={store}>
        <PopoverDisclosure>
          <TextField
            value={value?.toLocaleString() ?? "--/--/----"}
            aria-label="Date"
            icon={atlasCalendarEvents}
          />
        </PopoverDisclosure>
        <Popover className="z-50 flex shadow-1 rounded-md bg-white" gutter={8}>
          <DatePickerContent
            {...props}
            value={value}
            onChange={onChangeInner}
          />
        </Popover>
      </PopoverProvider>
    </>
  );
}
