import clsx from "clsx";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { SelfScheduleRequestDetails } from "./SelfScheduleRequestDetails";

export function SelfScheduleReview({
  state,
  variant = "medium",
  Form,
}: {
  state: SelfScheduleSubmissionState;
  variant?: "small" | "medium";
  Form: JSX.Element;
}) {
  return (
    <div
      className={clsx("flex w-full h-full divide-gray-border", {
        "flex-row divide-x": variant === "medium",
        "flex-col divide-y": variant === "small",
      })}
    >
      <div
        className={clsx("flex h-full", {
          "w-1/3": variant === "medium",
          "w-full": variant === "small",
        })}
      >
        <SelfScheduleRequestDetails
          className={clsx({
            "p-6": variant === "medium",
            "p-4": variant === "small",
          })}
          state={state}
          isReview
        />
      </div>
      <div
        className={clsx("flex flex-col space-y-4", {
          "w-2/3 p-6": variant === "medium",
          "w-full p-4": variant === "small",
        })}
      >
        {Form}
      </div>
    </div>
  );
}
