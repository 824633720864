import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import { SelfScheduleRequestForOpenRequestsSectionFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { EditExistingSelfScheduleRequestDialog } from "./EditExistingSelfScheduleRequestView";
import { SelfScheduleRequestBlock } from "./SelfScheduleRequestBlock";

export function OpenSelfScheduleRequestsSection({
  openSelfScheduleRequests,
}: {
  openSelfScheduleRequests:
    | SelfScheduleRequestForOpenRequestsSectionFragment[]
    | undefined;
}) {
  const filteredOpenSelfScheduleRequests = useMemo(() => {
    return (
      openSelfScheduleRequests?.filter(
        (selfScheduleRequest) => selfScheduleRequest.status === "OPEN"
      ) ?? []
    );
  }, [openSelfScheduleRequests]);

  if (!filteredOpenSelfScheduleRequests.length) {
    return null;
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <h4 className="text-h4">Self-schedule requests</h4>
        {filteredOpenSelfScheduleRequests.map((selfScheduleRequest) => (
          <SelfScheduleRequestItem
            key={selfScheduleRequest.id}
            selfScheduleRequest={selfScheduleRequest}
          />
        ))}
      </div>
    </div>
  );
}

function SelfScheduleRequestItem({
  selfScheduleRequest,
}: {
  selfScheduleRequest: SelfScheduleRequestForOpenRequestsSectionFragment;
}) {
  const logEvent = useLogEvent({
    component: "SelfScheduleRequestItem",
  });
  const editDialogStore = useDialogStore();

  return (
    <>
      <SelfScheduleRequestBlock
        selfScheduleRequest={selfScheduleRequest}
        requestHref={selfScheduleRequest.requestHref}
        onEdit={() => {
          logEvent("Edit Self-schedule Request Clicked");
          editDialogStore.show();
        }}
      />
      <EditExistingSelfScheduleRequestDialog
        store={editDialogStore}
        selfScheduleRequest={selfScheduleRequest}
      />
    </>
  );
}
