import { ActivityVerb } from "shared/activity/types";

import { NotificationDisplayType } from "../utils/types";
import { BaseNotificationDisplay } from "./BaseNotificationDisplay";
import { ChatMessageNotificationDisplay } from "./ChatMessageNotificationDisplay";
import { CommentNotificationDisplay } from "./CommentNotificationDisplay";

// Deprecated
export function getNotificationDisplay(notification: NotificationDisplayType) {
  const { verb } = notification;

  // Interview related notifications
  switch (verb) {
    case ActivityVerb.CommentCreated:
      return CommentNotificationDisplay;
    case ActivityVerb.ChatMessagePosted:
      return ChatMessageNotificationDisplay;
    default:
      return BaseNotificationDisplay;
  }
}
