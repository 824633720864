import { z } from "zod";

export enum ActionTypes {
  InterviewerSwap = "InterviewerSwap",
  CreateRescheduleTask = "CreateRescheduleTask",
  SendCandidateMessage = "SendCandidateMessage",
  CreateSchedulingTask = "CreateSchedulingTask",
  ScheduleInterviews = "ScheduleInterviews",
  RescheduleInterviews = "RescheduleInterviews",
  SendSlackReminder = "SendSlackReminder",
  SendSlackFollowUp = "SendSlackFollowUp",
}

export const ActionTypesSchema = z.nativeEnum(ActionTypes);
