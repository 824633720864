import {
  RadioItem,
  RadioItemProps,
} from "client/components/generic/inputs/RadioItem";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { useCallback } from "react";
import {
  InterviewerDecline,
  InterviewerNoShow,
  ScheduledInterviewOtherReason,
  ScheduledInterviewRescheduleReason,
} from "shared/reporting/scheduled-interviews/types";

import { AdditionalTextInput } from "./AdditionalTextInput";
import { InterviewerDeclinedSelect } from "./InterviewerDeclinedSelect";
import { InterviewerMultiSelectItem } from "./InterviewerMultiSelect";
import { InterviewerNoShowedSelect } from "./InterviewerNoShowedSelect";
import { OtherReasonCollectionAdditionalInfo } from "./OtherReasonCollectAdditionalInfo";

const reasonTextMap: Record<ScheduledInterviewRescheduleReason, string> = {
  [ScheduledInterviewRescheduleReason.CANDIDATE_NO_SHOWED]:
    "Candidate no showed",
  [ScheduledInterviewRescheduleReason.CANDIDATE_REQUESTED_RESCHEDULE]:
    "Candidate requested reschedule",
  [ScheduledInterviewRescheduleReason.INTERVIEWER_NO_SHOWED]:
    "Interviewer no showed",
  [ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED]:
    "Interviewer declined",
  [ScheduledInterviewRescheduleReason.OTHER]: "Other",
};

export type ReportRescheduleReasonFormProps = {
  interviewers: InterviewerMultiSelectItem[];
  reason: ScheduledInterviewRescheduleReason | null;
  onChangeReason: (reason: ScheduledInterviewRescheduleReason) => void;
  interviewerNoShows: InterviewerNoShow | null;
  onChangeInterviewerNoShows: (interviewerNoShows: InterviewerNoShow) => void;
  interviewerDeclines: InterviewerDecline | null;
  onChangeInterviewerDeclines: (
    interviewerDeclines: InterviewerDecline
  ) => void;
  otherReason: ScheduledInterviewOtherReason | null;
  onChangeOtherReason: (otherReason: ScheduledInterviewOtherReason) => void;
  additionalText: string | null;
  onChangeAdditionalText: (value: string) => void;
};

export function ReportRescheduleReasonForm({
  interviewers,
  reason,
  onChangeReason,
  interviewerNoShows,
  onChangeInterviewerNoShows,
  interviewerDeclines,
  onChangeInterviewerDeclines,
  otherReason,
  onChangeOtherReason,
  additionalText,
  onChangeAdditionalText,
}: ReportRescheduleReasonFormProps) {
  const getRadioItemProps = useCallback(
    ({
      type,
    }: {
      type: ScheduledInterviewRescheduleReason;
    }): RadioItemProps => ({
      label: reasonTextMap[type],
      isSelected: reason === type,
      onClick: () => onChangeReason(type),
    }),
    [reason, onChangeReason]
  );

  return (
    <FormGroup label="Reschedule reason" isRequired>
      <div className="space-y-2">
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewRescheduleReason.CANDIDATE_NO_SHOWED,
          })}
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewRescheduleReason.CANDIDATE_REQUESTED_RESCHEDULE,
          })}
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewRescheduleReason.INTERVIEWER_NO_SHOWED,
          })}
          AdditionalInfoInput={
            <InterviewerNoShowedSelect
              value={interviewerNoShows ?? []}
              onChange={onChangeInterviewerNoShows}
              interviewers={interviewers}
            />
          }
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED,
          })}
          AdditionalInfoInput={
            <InterviewerDeclinedSelect
              value={interviewerDeclines ?? []}
              onChange={onChangeInterviewerDeclines}
              interviewers={interviewers}
            />
          }
        />
        <RadioItem
          {...getRadioItemProps({
            type: ScheduledInterviewRescheduleReason.OTHER,
          })}
          AdditionalInfoInput={
            <OtherReasonCollectionAdditionalInfo
              reason={otherReason}
              onChangeReason={onChangeOtherReason}
            />
          }
        />
      </div>
      <AdditionalTextInput
        className="mt-4"
        text={additionalText}
        onChangeText={onChangeAdditionalText}
      />
    </FormGroup>
  );
}
