import { z } from "zod";

export const ConferenceRoomSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type ConferenceRoom = z.infer<typeof ConferenceRoomSchema>;

/** Settings for how to choose a conference room */
export const ConferenceRoomSettingsSchema = z
  .object({
    required: z.boolean(),
    building: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .nullable(),
  })
  .nullable();

export type ConferenceRoomSettings = z.infer<
  typeof ConferenceRoomSettingsSchema
>;

export const ConferenceRoomSlotInputSchema = z.object({
  conferenceRoom: ConferenceRoomSchema,
  settings: ConferenceRoomSettingsSchema,
});

export type ConferenceRoomSlotInput = z.infer<
  typeof ConferenceRoomSlotInputSchema
>;
