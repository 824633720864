import { Calendar } from "client/components/calendar/Calendar";
import { ColorConfig } from "client/components/calendar/colors";
import {
  AddEventPlugin,
  CreatedEvent,
} from "client/components/calendar/plugins/AddEventPlugin";
import { AllDayEventsViewPlugin } from "client/components/calendar/plugins/all-day-events/AllDayEventsViewPlugin";
import { DayHeaderButtonPlugin } from "client/components/calendar/plugins/DayHeaderButtonPlugin";
import { useCalendarTimezone } from "client/components/calendar/settings";
import { CalendarEventInput } from "client/components/calendar/types";
import { useCalendarState } from "client/components/calendar/useCalendarState";
import {
  combineEvents,
  mapCalendarEventInputToLuxonTimes,
} from "client/components/calendar/utils";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { InterviewerForSelectionFragment } from "generated/graphql-codegen/graphql";
import { compact } from "lodash";
import { DateTime, Interval } from "luxon";
import {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { PersistableCalendarEvent } from "shared/guide-content/rich-blocks/calendar-utils/types";
import useQuery from "utils/useQuery";
import { v4 as uuid } from "uuid";

type Interviewer = InterviewerForSelectionFragment;

type AvailabilityCalendarViewProps = ComponentPropsWithoutRef<"div"> & {
  interviewerCalendarColors?: Record<string, ColorConfig>;
  interviewers?: Interviewer[];
  defaultEventTitle: string;
  suggestions?: PersistableCalendarEvent[];
  selections?: PersistableCalendarEvent[];
  setSelections?: (selections: PersistableCalendarEvent[]) => void;
  timezone: string;
  onTimezoneChange: (timezone: string) => void;
};

gql(`
fragment SchedulerCalendarWithEvents on InterviewerCalendar {
  id
  name
  userMembershipId
  events {
    id
    iCalUID
    title
    start {
      date
      dateTime
    }
    end {
      date
      dateTime
    }
    outOfOffice
    responseStatus
    isWhitelisted
  }
}
`);

const GET_CALENDARS_AND_EVENTS_QUERY = gql(`
  query GetCalendarsAndEventsForAvailabilityCalendarQuery(
    $userMembershipIds: [String!]!
    $startTime: String
    $endTime: String
  ) {
    currentOrganization {
      id
      getInterviewerCalendarsAndEvents(
        userMembershipIds: $userMembershipIds
        startTime: $startTime
        endTime: $endTime
      ) {
        ...SchedulerCalendarWithEvents
      }
    }
  }
`);

export function AvailabilityCalendar({
  interviewerCalendarColors,
  interviewers,
  defaultEventTitle,
  setSelections: inputSetSelections,
  selections,
  suggestions,
  timezone,
  onTimezoneChange,
  ...props
}: AvailabilityCalendarViewProps) {
  const calendarState = useCalendarState({
    minInterval: 30,
  });
  const calendarTimezone = useCalendarTimezone();

  const setSelections = useCallback(
    (newSelections: PersistableCalendarEvent[]) => {
      return inputSetSelections?.(
        combineEvents(
          newSelections
            .filter(
              (
                e
              ): e is Omit<PersistableCalendarEvent, "start" | "end"> & {
                start: NonNullable<PersistableCalendarEvent["start"]>;
                end: NonNullable<PersistableCalendarEvent["end"]>;
                id: NonNullable<PersistableCalendarEvent["id"]>;
              } => Boolean(e.start && e.end && e.id)
            )
            .map((e) => ({
              id: e.id,
              title: defaultEventTitle,
              startTime: DateTime.fromISO(e.start),
              endTime: DateTime.fromISO(e.end),
            }))
        ).map(
          (e): PersistableCalendarEvent => ({
            id: e.id,
            title: defaultEventTitle,
            end: e.endTime.toISO(),
            start: e.startTime.toISO(),
          })
        )
      );
    },
    [defaultEventTitle, inputSetSelections]
  );

  // TODO: add better way to handle this
  useEffect(() => {
    if (calendarTimezone !== timezone) {
      onTimezoneChange(calendarTimezone);
    }
  }, [calendarState, calendarTimezone, onTimezoneChange, timezone]);

  const { data, previousData } = useQuery(GET_CALENDARS_AND_EVENTS_QUERY, {
    variables: {
      userMembershipIds:
        interviewers?.map((interviewer) => interviewer.id) ?? [],
      startTime: null,
      endTime: null,
    },
    skip: !interviewers?.length,
  });

  const events = useMemo<CalendarEventInput[]>((): CalendarEventInput[] => {
    const actualData = data ?? previousData;

    let calendarData: CalendarEventInput[] = [];
    if (actualData && actualData.currentOrganization) {
      calendarData =
        actualData.currentOrganization.getInterviewerCalendarsAndEvents.flatMap(
          (calendar) =>
            calendar.events.map(
              (event): CalendarEventInput => ({
                ...event,
                id: event.id,
                calendarId: calendar.id,
              })
            )
        );
    }

    const suggestionData = compact(
      suggestions?.map((suggestion): CalendarEventInput | null => {
        if (!suggestion.start || !suggestion.end) {
          return null;
        }

        const suggestionId = suggestion.id ?? uuid();

        return {
          id: suggestionId,
          title: suggestion.title ?? "Suggested time",
          start: {
            dateTime: suggestion.start,
          },
          end: {
            dateTime: suggestion.end,
          },
          outOfOffice: false,
          calendarId: "suggestions",
          isBackgroundEvent: true,
        };
      }) ?? []
    );

    const selectionEvents = compact(
      selections?.map((selection): CalendarEventInput | null => {
        if (!selection.start || !selection.end) {
          return null;
        }

        const selectionId = selection.id ?? uuid();

        return {
          id: selectionId,
          title: selection.title ?? "Available",
          start: {
            dateTime: selection.start,
          },
          end: {
            dateTime: selection.end,
          },
          outOfOffice: false,
          calendarId: "selections",
          onEdit: (updatedEvent) => {
            setSelections?.(
              selections?.map((event) =>
                event.id === selectionId
                  ? {
                      ...event,
                      start: updatedEvent.startTime.toUTC().toISO(),
                      end: updatedEvent.endTime.toUTC().toISO(),
                    }
                  : event
              ) ?? []
            );
          },
          onRemove: () => {
            setSelections?.(
              selections?.filter((event) => event.id !== selectionId) ?? []
            );
          },
        };
      }) ?? []
    );

    return [...calendarData, ...suggestionData, ...selectionEvents];
  }, [data, previousData, selections, setSelections, suggestions]);

  const onAddEvent = useCallback(
    ({ id, startTime, endTime, title }: CreatedEvent) => {
      // TODO: do we need to save ids?
      setSelections?.([
        ...(selections ?? []),
        {
          id,
          title,
          start: startTime.toUTC().toISO(),
          end: endTime.toUTC().toISO(),
        },
      ]);
    },
    [selections, setSelections]
  );

  const getSuggestionsForDay = useCallback(
    ({ day }: { day: DateTime }) => {
      return events.filter(
        (e) =>
          e.calendarId === "suggestions" &&
          e.start?.dateTime &&
          DateTime.fromISO(e.start?.dateTime).hasSame(day, "day")
      );
    },
    [events]
  );

  const getAvailabileTimesForDay = useCallback(
    ({ day }: { day: DateTime }) => {
      const dayStart = day.set({ hour: 9 });
      const dayEnd = day.set({ hour: 17 });

      const timeSlots = Array.from(
        { length: (dayEnd.hour - dayStart.hour) * 2 },
        (_, i) => dayStart.plus({ minutes: 30 * i })
      );

      let allAvailableTimes;

      if (suggestions?.length && getSuggestionsForDay({ day }).length) {
        allAvailableTimes = getSuggestionsForDay({
          day,
        }).map((s) => {
          const mappedEvent = mapCalendarEventInputToLuxonTimes(s);
          return {
            id: uuid(),
            title: "Available time",
            start: mappedEvent.startTime.toISO(),
            end: mappedEvent.endTime.toISO(),
          };
        });
      } else {
        allAvailableTimes = timeSlots
          .filter((timeSlot) => {
            const isEventDuringTimeSlot = events.some((event) => {
              const mappedEvent = mapCalendarEventInputToLuxonTimes(event);

              return (
                event.calendarId !== "suggestions" &&
                Interval.fromDateTimes(
                  timeSlot,
                  timeSlot.plus({ minutes: 30 })
                ).overlaps(
                  Interval.fromDateTimes(
                    mappedEvent.startTime,
                    mappedEvent.endTime
                  )
                )
              );
            });
            const isSelectionDuringTimeSlot = selections?.some(
              (selection) =>
                selection.start &&
                selection.end &&
                Interval.fromDateTimes(
                  timeSlot,
                  timeSlot.plus({ minutes: 30 })
                ).overlaps(
                  Interval.fromDateTimes(
                    DateTime.fromISO(selection.start),
                    DateTime.fromISO(selection.end)
                  )
                )
            );
            return !isEventDuringTimeSlot && !isSelectionDuringTimeSlot;
          })
          .map((timeSlot) => ({
            id: uuid(),
            title: "Available time",
            start: timeSlot.toUTC().toISO(),
            end: timeSlot.plus({ minutes: 30 }).toUTC().toISO(),
          }));
      }

      return allAvailableTimes;
    },
    [events, getSuggestionsForDay, selections, suggestions?.length]
  );

  const onAddAllAvailable = useCallback(
    ({ day }: { day: DateTime }) => {
      const allAvailableTimes = getAvailabileTimesForDay({ day });
      setSelections?.([...(selections ?? []), ...allAvailableTimes]);
    },
    [getAvailabileTimesForDay, selections, setSelections]
  );

  const requestingButtonText = useMemo(() => {
    return interviewers?.length ? "Add all available" : "Add 9-5pm";
  }, [interviewers?.length]);

  const getButtonText = useCallback(
    ({ day }: { day: DateTime }) => {
      if (suggestions?.length) {
        if (getSuggestionsForDay({ day }).length) return "Add all suggested";

        return "Add 9-5pm";
      }

      return requestingButtonText;
    },
    [getSuggestionsForDay, requestingButtonText, suggestions?.length]
  );

  const initialScrollValue = useMemo(() => {
    if (selections?.length && selections?.[0]?.start) {
      return DateTime.fromISO(selections[0].start)
        .startOf("day")
        .set({ hour: 7 });
    }

    if (suggestions?.length && suggestions?.[0]?.start) {
      return DateTime.fromISO(suggestions[0].start)
        .startOf("day")
        .set({ hour: 7 });
    }

    return DateTime.local().startOf("day").set({ hour: 7 });
  }, [suggestions, selections]);

  return (
    <Calendar
      events={events}
      calendarColors={{
        ...interviewerCalendarColors,
        selections: {
          color: "primary",
        },
        suggestions: {
          color: "primary",
          variant: "faded",
        },
      }}
      state={calendarState}
      initialScrollValue={initialScrollValue}
      {...props}
      className={clsx(props.className)}
      plugins={[
        <AllDayEventsViewPlugin key="all-day-events-view-plugin" />,
        <AddEventPlugin
          key="add-event-plugin"
          onAddEvent={onAddEvent}
          defaultTitle={defaultEventTitle}
          defaultDuration={60}
        />,
        <DayHeaderButtonPlugin
          key="add-all-available-plugin"
          onClick={onAddAllAvailable}
          getButtonText={getButtonText}
          getAvailabileTimesForDay={getAvailabileTimesForDay}
        />,
      ]}
    />
  );
}
