// note -- calling this contants.ts causes issues
export const DEFAULT_EMAIL_ADDRESS = `notifications@${
  process.env.MAIL_FROM_DOMAIN ?? "mail.guide.co"
}`;
export const NOREPLY_EMAIL_ADDRESS = `noreply@${
  process.env.MAIL_FROM_DOMAIN ?? "mail.guide.co"
}`;

export const SCHEDULER_EMAIL_ADDRESS = `notifications@${
  process.env.MAIL_FROM_DOMAIN ?? "mail.guide.co"
}`;

export const SCHEDULER_EMAIL_NAME = "Guide Scheduler";

export const DEFAULT_EMAIL_NAME = "Guide Notifications";
