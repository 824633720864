import { OptionItem } from "@resource/atlas/option/OptionItem";
import { ColorizedPoolIcon } from "client/components/interview-requirements/__components/ColorizedPoolIcon";
import { InterviewerPool } from "client/components/interviewer-slots/utils/types";

type InterviewerPoolSelectOptionProps = {
  pool: InterviewerPool;
  onClick: () => void;
};

export default function InterviewerPoolSelectOption({
  pool,
  onClick,
}: InterviewerPoolSelectOptionProps) {
  const poolHasUsers = pool.qualifiedUserCount > 0 || pool.traineeUserCount > 0;
  return (
    <OptionItem size="compact" onClick={onClick}>
      <div className="flex gap-2 items-center">
        <ColorizedPoolIcon variant="large" />
        <div className="flex flex-col">
          <div>{pool.name}</div>
          {poolHasUsers && (
            <div className="text-subtle text-body-sm space-x-2">
              <span>
                {pool.qualifiedUserCount > 0
                  ? `${pool.qualifiedUserCount} interviewers`
                  : ""}
              </span>
              <span>
                {pool.traineeUserCount > 0
                  ? `${pool.traineeUserCount} training`
                  : ""}
              </span>
            </div>
          )}
          {!poolHasUsers && (
            <div className="text-subtle text-body-sm">No interviewers</div>
          )}
        </div>
      </div>
    </OptionItem>
  );
}
