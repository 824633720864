import { Image } from "./Image";
import { Table } from "./Table";

const GUIDE_INFO = {
  name: "Guide",
  logoUrl:
    "https://guide-media-library.netlify.app/emails/guide-logo-color--md.png",
  url: "https://guide.co",
};

export type NotificationCompanyData = {
  name: string;
  logoUrl: string;
  url: string;
};

export type HeaderProps = {
  company?: NotificationCompanyData;
};

export function Header({ company: passedCompany }: HeaderProps) {
  const company = passedCompany || GUIDE_INFO;

  return (
    <tr>
      <td className="text-left text-sm p-8 sm:px-4 sm:py-4 gmail__sm-px-0">
        <Table className="w-full">
          <tr>
            <td className="text-left">
              <a href={company.url}>
                <span className="hidden">{company.name}</span>
                <Image
                  src={company.logoUrl}
                  height="32"
                  alt={company.name}
                  className="max-w-full align-middle [border:0]"
                />
              </a>
            </td>
          </tr>
        </Table>
      </td>
    </tr>
  );
}
