/* eslint-disable import/prefer-default-export */
import "./LinkCardsCollection.sass";

import clsx from "clsx";
import { ComponentPropsWithoutRef, ReactElement } from "react";

import { createComponentUtils } from "../../../../__utils/atlas";
import { LinkCard } from "../shared/LinkCard";
import type { LinkCardsCollectionData } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-LinkCard_collection";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// link cards collection
// ---------------------

type LinkCardsCollectionProps = {
  data: LinkCardsCollectionData;
  renderMenu?: (options: { cardIndex: number }) => ReactElement;
} & ComponentPropsWithoutRef<"div">;

export function LinkCardsCollection({
  data,
  renderMenu,
  ...props
}: LinkCardsCollectionProps) {
  return (
    <div {...props} className={clsx(ROOT, props.className)}>
      {data.cards.map((card, index) => (
        <LinkCard
          data={card}
          showDescription={false}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          menuSlot={renderMenu ? renderMenu({ cardIndex: index }) : undefined}
        />
      ))}
    </div>
  );
}
