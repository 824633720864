import { SchedulingTaskDetails } from "client/components/scheduling-tasks/task-details/SchedulingTaskDetails";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

import { ViewDetails } from "../core/components/navigation/SchedulerLeftSidePanel";
import { Scheduler, SchedulerProps } from "../core/components/Scheduler";
import {
  combineAndMapScheduledInterviewsAndRequirementsToSchedulerInterviews,
  combineExistingScheduledInterviewsWithOptionalAlgorithmResults,
  getDefaultScheduledInterviewGroupSettings,
  getFilteredInterviewRequirements,
  getScheduledInterviewGroupIdForSchedulingRequest,
} from "./utils/helpers";

const SCHEDULER_QUERY = gql(`
  query SchedulingRequestSchedulerHydrationQuery($schedulingRequestId: ID! $algorithmSuggestedScenarioId: ID) {
    schedulingRequestById(id: $schedulingRequestId) {
      ...SchedulingRequestForSchedulerHydrationV3
    }
  }
`);

export type SchedulingRequestSchedulerProps = {
  schedulingRequestId: string;
  algorithmSuggestedScenarioId?: string;
  defaultSelectedInterviewId?: string;
} & Omit<SchedulerProps, "viewDetails">;

export function SchedulingRequestScheduler({
  schedulingRequestId,
  algorithmSuggestedScenarioId,
  defaultSelectedInterviewId,
  ...rest
}: SchedulingRequestSchedulerProps) {
  const {
    schedulingRequest,
    interviews,
    originalInterviews,
    scheduledInterviewGroupId,
    selectedInterviewId,
    defaultScheduledInterviewGroupSettings,
  } = useSchedulingRequestSchedulerData({
    schedulingRequestId,
    algorithmSuggestedScenarioId,
    defaultSelectedInterviewId,
  });

  const viewDetails = useMemo((): ViewDetails => {
    return {
      label: "Task details",
      DisplayComponent: schedulingRequest ? (
        <SchedulingTaskDetails schedulingRequest={schedulingRequest} />
      ) : null,
    };
  }, [schedulingRequest]);

  if (!schedulingRequest) {
    return <DialogLoading isFullscreen />;
  }

  return (
    <Scheduler
      {...rest}
      originalInterviews={originalInterviews}
      interviews={interviews}
      guideId={schedulingRequest.guideId}
      schedulingRequestId={schedulingRequest.id}
      selectedInterviewId={selectedInterviewId}
      scheduledInterviewGroupId={scheduledInterviewGroupId}
      scheduledInterviewGroupSettings={defaultScheduledInterviewGroupSettings}
      viewDetails={viewDetails}
    />
  );
}

export function useSchedulingRequestSchedulerData({
  schedulingRequestId,
  algorithmSuggestedScenarioId,
  defaultSelectedInterviewId,
}: {
  schedulingRequestId: string;
  algorithmSuggestedScenarioId?: string;
  defaultSelectedInterviewId?: string;
}) {
  const { data } = useQuery(SCHEDULER_QUERY, {
    variables: {
      schedulingRequestId,
      algorithmSuggestedScenarioId: algorithmSuggestedScenarioId ?? null,
    },
    // ensure we always have fresh data for scheduler hydration
    fetchPolicy: "network-only",
  });

  return useMemo(() => {
    const schedulingRequest = data?.schedulingRequestById;
    const interviewPanelRequirement =
      schedulingRequest?.interviewPanelRequirement;
    const existingScheduledInterviews =
      schedulingRequest?.scheduledInterviews ?? [];
    const algorithmSuggestedScenario =
      schedulingRequest?.algorithmSuggestedScenarioById;

    const scheduledInterviewsWithOptionalAlgorithmResults =
      combineExistingScheduledInterviewsWithOptionalAlgorithmResults({
        scheduledInterviews: existingScheduledInterviews,
        algorithmSuggestedScenario,
      });

    const scheduledInterviewGroupId =
      getScheduledInterviewGroupIdForSchedulingRequest({
        scheduledInterviews: existingScheduledInterviews,
        schedulingRequestId,
      });
    const defaultScheduledInterviewGroupSettings =
      getDefaultScheduledInterviewGroupSettings({
        scheduledInterviews: existingScheduledInterviews,
        interviewPanelRequirement,
      });

    const interviewRequirements = getFilteredInterviewRequirements({
      interviewPanelRequirement,
    });

    /** Original interviews should not include algorithm results so that the scheduler is aware of changes the algorithm made */
    const originalInterviews =
      combineAndMapScheduledInterviewsAndRequirementsToSchedulerInterviews({
        scheduledInterviews: existingScheduledInterviews,
        interviewRequirements,
      });
    /** Interviews passed to scheduler should be hydrated with algorithm results if they exist */
    const interviews =
      combineAndMapScheduledInterviewsAndRequirementsToSchedulerInterviews({
        scheduledInterviews: scheduledInterviewsWithOptionalAlgorithmResults,
        interviewRequirements,
      });

    const defaultSelectedInterview = interviews.find(
      (interview) => interview.id === defaultSelectedInterviewId
    );
    const firstInterviewNeedsRescheduling = interviews.find(
      (i) => !!i.pendingRescheduleInterviewRequirement
    );
    const firstInterview = interviews[0];
    const selectedInterviewId =
      defaultSelectedInterview?.id ??
      firstInterviewNeedsRescheduling?.id ??
      firstInterview?.id ??
      null;

    return {
      schedulingRequest,
      interviews,
      originalInterviews,
      scheduledInterviewGroupId,
      selectedInterviewId,
      defaultScheduledInterviewGroupSettings,
    };
  }, [
    data?.schedulingRequestById,
    defaultSelectedInterviewId,
    schedulingRequestId,
  ]);
}
