import { fixedToolbarModule } from "@resource/atlas/content-editor/fixed-toolbar";
import { lineActionsModule } from "@resource/atlas/content-editor/line-actions";
import { richQuoteModule } from "@resource/atlas/content-editor/rich-quote";
import type { AtlasContentEditorOptions } from "@resource/atlas/content-editor/types";
import { videoModule } from "@resource/atlas/content-editor/video";
import { useFileModule } from "client/guide-content/rich-blocks/file";
import { gifModule } from "client/guide-content/rich-blocks/gif";
import { useImageModule } from "client/guide-content/rich-blocks/image";

export function useBaseModules() {
  const modules: AtlasContentEditorOptions["modules"] = [
    useImageModule(),
    videoModule,
    richQuoteModule,
    fixedToolbarModule,
    useFileModule(),
    lineActionsModule,
    gifModule,
  ];

  return modules;
}
