import { Button as AriaButton } from "@ariakit/react";
import { Transition } from "@headlessui/react";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown } from "@resource/atlas/icons";
import clsx from "clsx";
import { useCallback, useState } from "react";

/** Animated open and close section */
export function CollapsibleSection({
  label,
  children,
  className,
  Badge,
  LeftBadge,
  defaultCollapsed = false,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
  Badge?: React.ReactNode;
  LeftBadge?: React.ReactNode;
  defaultCollapsed?: boolean;
}) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const toggleCollapsed = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);
  const ANIMATION_DURATION = 400;

  return (
    <div className={clsx("w-full p-4", className)}>
      <AriaButton
        className="flex justify-between w-full sticky"
        onClick={toggleCollapsed}
      >
        <div className="flex items-center space-x-1">
          {LeftBadge}
          <p className="text-body-md-heavy">{label}</p>
          {Badge}
        </div>
        <Icon
          content={atlasChevronDown}
          className={`transition-transform duration-[${ANIMATION_DURATION}ms] ${
            isCollapsed ? "rotate-0" : "rotate-180"
          }`}
        />
      </AriaButton>
      <div className="overflow-hidden">
        <Transition
          show={!isCollapsed}
          enter={`transition-all duration-[${ANIMATION_DURATION}ms]`}
          enterFrom="opacity-0 max-h-0 -translate-y-full"
          // These max-h-[20000px] are a hack to enable animation.
          enterTo="opacity-100 max-h-[20000px] translate-y-0"
          leave={`transition-all duration-[${ANIMATION_DURATION}ms]`}
          leaveFrom="opacity-100 max-h-[20000px] translate-y-0"
          leaveTo="opacity-0 max-h-0 -translate-y-full"
        >
          <div className="overflow-hidden mt-4">{children}</div>
        </Transition>
      </div>
    </div>
  );
}
