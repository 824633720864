import { DialogDismiss } from "@ariakit/react";
import clsx from "clsx";
import React, { ReactNode, useEffect, useMemo } from "react";

import { atlasClose } from "../../icons";
import { Component, createComponentUtils, Props } from "../__utils/atlas";
import { Button } from "../button/Button";
import { ButtonGroup } from "../button/ButtonGroup";
import { useOptionalDialogContext } from "../dialog/DialogContext";

// config
// ------
const COMPONENT_NAME = "View";

const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

export type ViewProps<As extends keyof JSX.IntrinsicElements = "div" | "form"> =
  Props<As> & {
    as?: As;
    header?: HeaderProps | null;
    CustomHeader?: ReactNode;
    SuperHeader?: ReactNode;
    footer?: FooterProps | null;
    CustomFooter?: ReactNode;
    content?: ContentProps;
    rightPane?: {
      isOpen: boolean;
      content: ReactNode;
      width?: number;
    };
    fitContent?: boolean;
  };

export type RightPaneProps = Props<"div"> & {
  content: ReactNode;
  width?: number;
};

const RightPaneComponent = createComponent<Component<RightPaneProps>>(
  ({ content, width = 384, style, ...props }: RightPaneProps) => {
    return (
      <div
        {...props}
        className={clsx(el`right-pane`, props.className)}
        style={{
          ...style,
          width,
        }}
      >
        {content}
      </div>
    );
  },
  { treeName: "RightPane" }
);

export const RightPane = React.memo(RightPaneComponent);

export type ContentProps = Props<"div"> & {
  title?: string;
  subTitle?: string;
  hideHeaderBorder?: boolean;
  hideFooterBorder?: boolean;
  rightPane?: ViewProps["rightPane"];
  width?: number;
};

const ContentComponent = createComponent<Component<ContentProps>>(
  ({
    title,
    subTitle,
    children,
    hideHeaderBorder,
    hideFooterBorder,
    width,
    style,
    ...props
  }: ContentProps) => {
    return (
      <div
        {...props}
        className={clsx(
          el`content`,
          {
            "-mb-6": hideFooterBorder,
            "-mt-6": hideHeaderBorder,
          },
          props.className
        )}
        style={{
          ...style,
          width: width || "100%",
        }}
      >
        {!!(title || subTitle) && (
          <div
            className={clsx("title-container", {
              "has-children": !!children,
            })}
          >
            {title && <div className="title">{title}</div>}
            {subTitle && <div className="subtitle">{subTitle}</div>}
          </div>
        )}
        {children}
      </div>
    );
  },
  { treeName: "Content" }
);

export const Content = React.memo(ContentComponent);

export type HeaderProps = Props<"div"> & {
  title?: string;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  rightContent?: ReactNode;
  hideBorder?: boolean;
};

const HeaderComponent = createComponent<Component<HeaderProps>>(
  ({
    title,
    leftActions,
    rightActions,
    rightContent,
    hideBorder,
    ...props
  }: HeaderProps) => {
    const LeftActions = useMemo(() => {
      if (leftActions) {
        return leftActions;
      }

      if (leftActions === null) {
        return null;
      }

      return (
        <DialogDismiss>
          <Button
            isGhost
            size="small"
            icon={atlasClose}
            aria-label="Close dialog"
          />
        </DialogDismiss>
      );
    }, [leftActions]);

    return (
      <div
        {...props}
        className={clsx(el`header`, props.className, {
          hideBorder,
        })}
      >
        <ButtonGroup
          className="min-w-[2rem] flex-1"
          isGhost
          size="small"
          negativeMargin="left"
        >
          {LeftActions}
        </ButtonGroup>
        <div className="title">{title}</div>
        <div className="actions-container">
          {rightActions && (
            <ButtonGroup isGhost size="small" negativeMargin="right">
              {rightActions}
            </ButtonGroup>
          )}
          {rightContent}
        </div>
      </div>
    );
  },
  {
    treeName: "Header",
  }
);

export const Header = React.memo(HeaderComponent);

export type FooterProps = Props<"div"> & {
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  hideBorder?: boolean;
};

const FooterComponent = createComponent<Component<FooterProps>>(
  ({ leftActions, rightActions, hideBorder, ...props }: FooterProps) => {
    return (
      <div
        {...props}
        className={clsx(el`footer`, props.className, {
          hideBorder,
        })}
      >
        <div className="footer-container">
          <ButtonGroup isGhost negativeMargin="left" className="min-w-[2rem]">
            {leftActions}
          </ButtonGroup>
          <div className="spacer" />
          <ButtonGroup negativeMargin="right" className="min-w-[2rem]">
            {rightActions}
          </ButtonGroup>
        </div>
      </div>
    );
  },
  {
    treeName: "Footer",
  }
);

export const Footer = React.memo(FooterComponent);

const ViewComponent = createComponent<Component<ViewProps>>(
  ({
    as: ViewWrapper = "div",
    header,
    SuperHeader,
    CustomHeader,
    footer,
    CustomFooter,
    content,
    rightPane,
    children,
    ref,
    fitContent,
    ...props
  }) => {
    const dialogContext = useOptionalDialogContext();

    useEffect(() => {
      if (fitContent && dialogContext?.setIsSheetVariantFitContent) {
        dialogContext.setIsSheetVariantFitContent(true);
      }
      return () => {
        if (fitContent && dialogContext?.setIsSheetVariantFitContent) {
          dialogContext.setIsSheetVariantFitContent(false);
        }
      };
    }, [fitContent, dialogContext]);

    const contentWidth = content?.width || 512;
    const totalWidth = rightPane?.isOpen
      ? contentWidth + (rightPane.width || 384)
      : contentWidth;

    return (
      <ViewWrapper
        {...props}
        // @ts-expect-error ref can't be determined
        ref={ref}
        className={clsx(el`layout`, props.className)}
      >
        {SuperHeader}
        {header !== null && !CustomHeader && <Header {...header} />}
        {CustomHeader}
        <div
          className={el`main-body`}
          style={{ width: content?.width ? totalWidth : "100%" }}
        >
          <Content
            {...content}
            hideHeaderBorder={header?.hideBorder}
            hideFooterBorder={footer?.hideBorder}
            width={contentWidth}
          >
            {children}
          </Content>
          {rightPane?.isOpen && (
            <RightPane content={rightPane.content} width={rightPane.width} />
          )}
        </div>
        {footer && <Footer {...footer} />}
        {CustomFooter}
      </ViewWrapper>
    );
  },
  {
    treeName: "View",
  }
);

export const View = React.memo(ViewComponent);
