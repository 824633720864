import {
  AtlasContentEditorProps,
  ContentEditor,
} from "@resource/atlas/content-editor";
import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { fixedToolbarModule } from "@resource/atlas/content-editor/fixed-toolbar";
import {
  ValueSet,
  variablesModule,
} from "@resource/atlas/content-editor/variables";
import { unknownVariablesModule } from "@resource/atlas/content-editor/variables/__lib/module";
import ErrorBoundary from "components/ErrorBoundary";
import {
  scheduledInterviewDetailsSpecSet,
  ScheduledInterviewDetailsVariableSet,
} from "shared/guide-scheduler/scheduled-interview-details/variables";

export type ScheduledInterviewDetailsEditorProps = AtlasContentEditorProps & {
  valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
};

export function ScheduledInterviewDetailsEditor({
  valueSet,
  modules = [],
  ...props
}: ScheduledInterviewDetailsEditorProps) {
  return (
    <ErrorBoundary>
      <ContentEditor
        {...props}
        modules={[
          withConfig(variablesModule, {
            specSet: scheduledInterviewDetailsSpecSet,
            valueSet,
            undefinedMode: "placeholder",
          }),
          unknownVariablesModule,
          fixedToolbarModule,
          ...modules,
        ]}
      />
    </ErrorBoundary>
  );
}
