import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRefreshCcw, atlasSparklesColor } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useAuthContext } from "auth/context";
import { useSchedulingTaskDetailsOnSchedule } from "client/app/(scheduling requests)/__components/SchedulingTaskDetailsView/hooks/useSchedulingTaskDetailsOnSchedule";
import {
  useAvailabilityWithFake,
  useIsInternal,
  useMappedFakeAvailability,
} from "client/app/internal/(scheduling requests)/state";
import { Timestamp } from "client/components/generic/misc/Timestamp";
import { UsersSelect } from "client/components/generic/select/UserSelect";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import {
  SchedulingAlgorithmRunStatus,
  SchedulingRequestForAIScenariosSectionFragment,
} from "generated/graphql-codegen/graphql";
import { useEffect, useMemo, useState } from "react";
import { formatEntity } from "shared/constants/entities";

import { AIScenarioCard } from "./components/AIScenarioCard";
import { AIScenariosDialog } from "./components/AIScenariosDialog";
import { useAIScenarioManager } from "./utils/hooks/useAIScenarioManager";
import { mapAlgorithmSettingsFragmentToRunFindTimesInput } from "./utils/mapping";

gql(`
  fragment AlgorithmRunForAiScenariosSection on SchedulingAlgorithmRun {
    id
    scenariosCount
    scenarios (limit: 1) {
      ...NewAIScenarioCardPanel
    }
    createdAt
    updatedAt
    status
    settings {
      numberOfDays {
        min
        max
      }
      interviewsPerDayLimit {
        min
        max
      }
    }
  }
`);

gql(`
  fragment SchedulingRequestForAIScenariosSection on SchedulingRequest {
    id
    guide {
      id
      currentAvailabilitySubmission {
        id
        events {
          id
          startTime
          endTime
        }
      }
      ...GuideForSchedulingAlgorithmDialog
    }
    mostRecentAlgorithmRun {
      ...AlgorithmRunForAiScenariosSection
    }
    scheduledInterviews {
      id
      isV2
    }

    interviewPanelRequirement {
      id
      interviewRequirements {
        id
        manuallyRemovedAt
      }
      ...AlgorithmSettingsForInterviewPanelRequirement
    }

    schedulingRequestTemplate {
      id
      jobInterviewStage {
        id
        name
      }
      interviewPanelRequirement {
        id
        ...AlgorithmSettingsForInterviewPanelRequirement
      }
    }
  }
`);

export function AIScenariosSection({
  schedulingRequest,
  hide,
}: {
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  hide: boolean;
}) {
  const { user } = useAuthContext();

  const [runAs, setRunAs] = useState<string | null>(null);
  const isInternal = useIsInternal();
  const fakeAvailability = useMappedFakeAvailability();
  const eventsMapped = useMemo(() => {
    return (
      schedulingRequest.guide.currentAvailabilitySubmission?.events.map(
        (e) => ({
          ...e,
          start: e.startTime,
          end: e.endTime,
          title: "",
        })
      ) ?? []
    );
  }, [schedulingRequest.guide.currentAvailabilitySubmission?.events]);
  const candidateAvailabilities = useAvailabilityWithFake({
    availability: eventsMapped,
  });

  const { algorithmRun, algorithmRunLoading, queueAlgorithmRun } =
    useAIScenarioManager({
      schedulingRequest,
      isInternal,
      runAs,
      fakeAvailability,
    });

  const aiSchedulingScenarios = useMemo(() => {
    return algorithmRun?.scenarios ?? [];
  }, [algorithmRun?.scenarios]);
  const bestAlgorithmScenario = useMemo(() => {
    return aiSchedulingScenarios[0];
  }, [aiSchedulingScenarios]);

  const store = useDialogStore();

  const {
    [FeatureFlags.MULTI_DAY_ALGORITHM_SCHEDULING_PREFERENCES]:
      algorithmSettingsEnabled,
  } = useFlags();

  const algorithmSettings = useMemo(() => {
    if (
      !algorithmSettingsEnabled ||
      !schedulingRequest.interviewPanelRequirement.algorithmSettings
    ) {
      return undefined;
    }
    return mapAlgorithmSettingsFragmentToRunFindTimesInput(
      schedulingRequest.interviewPanelRequirement.algorithmSettings
    );
  }, [
    schedulingRequest.interviewPanelRequirement?.algorithmSettings,
    algorithmSettingsEnabled,
  ]);

  useEffect(() => {
    if (candidateAvailabilities.length > 0) {
      // This effect runs every time the user visits the page or changes algorithmSettings but with `force=false`
      // no unecessary work is created.
      queueAlgorithmRun({ ...algorithmSettings });
    }
  }, [candidateAvailabilities.length, queueAlgorithmRun, algorithmSettings]);

  const { onSchedule, SchedulerDialog } = useSchedulingTaskDetailsOnSchedule({
    schedulingRequestId: schedulingRequest.id,
  });

  if (!algorithmRun && !algorithmRunLoading) {
    return null;
  }

  const count = algorithmRun?.scenariosCount ?? 0;

  return (
    <>
      {!hide && (
        <div className="bg-purple-50 rounded-md p-3 space-y-4 w-full">
          <div className="w-full">
            <div className="flex space-x-2 items-center w-full">
              {algorithmRunLoading ||
              algorithmRun?.status ===
                SchedulingAlgorithmRunStatus.IN_PROGRESS ? (
                <LoadingIndicator className="text-purple-800" size="small" />
              ) : (
                <Icon content={atlasSparklesColor} className="w-5 h-5" />
              )}
              <div className="text-body-md flex-grow">
                <span className="font-bold text-purple-800">
                  {formatEntity("scheduling option", {
                    plural: count !== 1,
                    capitalize: true,
                  })}
                  {count > 0 ? ` (${count})` : ""}
                </span>
              </div>
              <Tooltip isInstant content="Refresh results">
                <Button
                  icon={atlasRefreshCcw}
                  isGhost
                  className="!text-purple-800"
                  disabled={algorithmRunLoading}
                  onClick={() =>
                    queueAlgorithmRun({
                      force: true,
                      ...algorithmSettings,
                    })
                  }
                />
              </Tooltip>
            </div>
            {user?.isStaff && (
              <UsersSelect
                onSelect={(v) => setRunAs(v.id)}
                selectedId={runAs ?? undefined}
              />
            )}
            {algorithmRun && (
              <div className="text-body-sm text-subtle">
                Updated{" "}
                <Timestamp timestamp={algorithmRun.updatedAt} format="short" />
              </div>
            )}
          </div>
          {bestAlgorithmScenario && (
            <>
              <AIScenarioCard
                onSchedule={onSchedule}
                scenario={bestAlgorithmScenario}
                label="Option 1"
                compact
              />
              <div className="flex w-full">
                <Button
                  variant="purple"
                  className="flex w-full"
                  onClick={() => {
                    if (algorithmRun) {
                      store.show();
                    }
                  }}
                >
                  View all options
                </Button>
              </div>
            </>
          )}
          {algorithmRun &&
            algorithmRun.scenariosCount === 0 &&
            algorithmRun.status === SchedulingAlgorithmRunStatus.COMPLETED && (
              <div className="text-body-md text-purple-500 flex w-full justify-center py-2">
                <p>
                  Guide couldn&apos;t find any available times in the next 4
                  weeks.
                </p>
              </div>
            )}
          {algorithmRun &&
            algorithmRun.status === SchedulingAlgorithmRunStatus.FAILED && (
              <div className="text-body-md text-red-500 flex w-full justify-center py-2">
                <p>Something went wrong.</p>
              </div>
            )}
          {!algorithmRun && (
            <div className="text-body-md text-purple-500 flex w-full justify-center py-2">
              <p>Searching for options...</p>
            </div>
          )}
        </div>
      )}
      <SchedulerDialog />
      <AIScenariosDialog
        store={store}
        schedulingRequest={schedulingRequest}
        onSchedule={onSchedule}
      />
    </>
  );
}
