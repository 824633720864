import { Button } from "@resource/atlas/button/Button";
import { atlasCalendarEvent } from "@resource/atlas/icons";
import { gql } from "generated/graphql-codegen";
import { ScheduledInterviewForFindTimeButtonFragment } from "generated/graphql-codegen/graphql";

gql(`
  fragment ScheduledInterviewForFindTimeButton on ScheduledInterview {
    id
    isV2
    scheduledInterviewGroupId
    isSelfScheduled
  }  
`);

export type FindTimeButtonProps = {
  scheduledInterview: ScheduledInterviewForFindTimeButtonFragment;
  onFindTime: () => void;
};

export function FindTimeButton({
  scheduledInterview,
  onFindTime,
}: FindTimeButtonProps) {
  if (
    !scheduledInterview.isV2 ||
    !scheduledInterview.scheduledInterviewGroupId ||
    scheduledInterview.isSelfScheduled
  ) {
    return null;
  }

  return (
    <>
      <Button
        size="small"
        icon={atlasCalendarEvent}
        variant="default"
        isGhost={false}
        onClick={onFindTime}
      >
        Find time
      </Button>
    </>
  );
}
