// Must specify a default for all possible values
const defaultPollingIntervals = {
  createGuideFlow: 5000,
  extensionGuideForATSCandidate: 5000,
  extensionSendData: 30000,
  extensionStageData: 30000,
  fetchGuideForGreenhouseCandidate: 2500,
  fetchGuideForMergeApplication: 5000,
  fetchStageOverviewForExtension: 5000,
  interviewScheduler: 2000,
  interviewSchedulerEvents: 3000,
  guides: 120000,
  schedulingIntegrationData: 10000,
  sendGuideDetails: 30000,
  syncCandidateApplicationInterviews: 10000,
  templatePicker: 10000,
  uploadCareSignature: 60000,
  zeusExtension: 120000,
  zeusGuides: 120000,
  zeusInterviews: 5000,
  candidateWasCreated: 2000,
};

export type PollingIntervalConfig = typeof defaultPollingIntervals;

export const usePollingIntervalConfig = (): PollingIntervalConfig => {
  return defaultPollingIntervals;
};
