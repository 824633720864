import { PanelReviewPageFormState } from "client/scheduler/core/components/review/hooks/usePanelReviewPageFormState";
import {
  CurrentUserMembershipForReviewPageSchedulingDefaultsFragment,
  ExistingScheduledInterviewForSchedulerReviewPageFragment,
  GuideForSchedulerReviewPageFragment,
} from "generated/graphql-codegen/graphql";

import { useSchedulerStateForReview } from "./hooks/useReviewPageData";
import { PanelNotificationSettings } from "./PanelNotificationSettings";
import { SchedulerReviewInterviewDetails } from "./SchedulerReviewInterviewDetails";

/**
 * Right side view of scheduler review page
 * Provides panel notification settings and
 * details for each interview
 */
export function PanelSettingsAndEventDetails({
  guide,
  scheduledInterviews,
  currentUserMembership,
  state,
}: {
  guide: GuideForSchedulerReviewPageFragment;
  state: PanelReviewPageFormState;
  scheduledInterviews: ExistingScheduledInterviewForSchedulerReviewPageFragment[];
  currentUserMembership: CurrentUserMembershipForReviewPageSchedulingDefaultsFragment;
}) {
  const { nonCancelledInterviews } = useSchedulerStateForReview();

  return (
    <div className="max-w-[42.25rem] flex flex-col w-full space-y-6 p-6">
      <PanelNotificationSettings
        state={state}
        currentUserMembership={currentUserMembership}
        nonCancelledInterviewsCount={nonCancelledInterviews.length}
      />
      {nonCancelledInterviews.map((interview, index) => (
        <SchedulerReviewInterviewDetails
          key={interview.id}
          interview={interview}
          state={state}
          guide={guide}
          scheduledInterviews={scheduledInterviews}
          index={index}
        />
      ))}
    </div>
  );
}
