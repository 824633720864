import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { useOptionalContentEditor } from "@resource/atlas/content-editor/use-content-editor";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { LexicalEditor } from "lexical";
import { useCallback } from "react";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";

import { EditInterviewTitleDialogProps } from "../components/EditInterviewTitleDialog";

type UseEditInterviewTitleDialogProps = {
  interviewerConfirmationTitle: AtlasContentEditorSerializedState;
  onChangeTitle: (title: AtlasContentEditorSerializedState) => void;
  valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
};

type UseEditInterviewTitleDialogReturn = {
  props: EditInterviewTitleDialogProps;
  onEdit: () => void;
  editor: LexicalEditor | null;
};

export function useEditInterviewTitleDialogProps({
  interviewerConfirmationTitle,
  onChangeTitle,
  valueSet,
}: UseEditInterviewTitleDialogProps): UseEditInterviewTitleDialogReturn {
  const store = useDialogStore();
  const { editor, contentEditorProps } = useOptionalContentEditor();

  const onEdit = useCallback(() => {
    store.show();
  }, [store]);
  const onCancel = useCallback(() => {
    editor?.setEditorState(
      editor?.parseEditorState(interviewerConfirmationTitle)
    );
    store.hide();
  }, [editor, interviewerConfirmationTitle, store]);
  const onSave = useCallback(
    (title: AtlasContentEditorSerializedState) => {
      onChangeTitle(title);
      store.hide();
    },
    [onChangeTitle, store]
  );

  return {
    props: {
      store,
      interviewTitle: interviewerConfirmationTitle,
      editor,
      contentEditorProps,
      onCancel,
      onSave,
      valueSet,
    },
    onEdit,
    editor,
  };
}
