import Badge from "@resource/atlas/badge/Badge";
import { formatPersonName } from "client/utils/strings";
import clsx from "clsx";
import { useMemo } from "react";

interface CandidateNameProps {
  candidate: {
    firstName: string;
    preferredName: string | null;
    lastName: string;
  };
  isInternal?: boolean;
}

export function CandidateName({ candidate, isInternal }: CandidateNameProps) {
  const name = useMemo(() => {
    return formatPersonName(
      `${candidate.firstName ?? ""}${
        candidate.preferredName ? ` (${candidate.preferredName})` : ""
      }`,
      candidate.lastName
    );
  }, [candidate]);

  return (
    <p className={clsx("flex items-center gap-1 w-full")}>
      <span className="truncate" title={name}>
        {name}
      </span>
      {isInternal && (
        <Badge size="medium" className="shrink-0">
          Internal
        </Badge>
      )}
    </p>
  );
}
