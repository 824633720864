import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import type {
  AtlasSelectContentProps,
  AtlasSelectRootProps,
  AtlasSelectTriggerProps,
} from "@resource/atlas/select/types";
import clsx from "clsx";
import { forwardRef, ReactNode } from "react";

import { useTimezoneSelectionItems } from "./useTimezoneSelectionItems";

export type TimezoneSelectTriggerProps = AtlasSelectTriggerProps & {
  label: ReactNode;
};

const TimezoneSelectTrigger = forwardRef<
  HTMLButtonElement,
  TimezoneSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref}>
      {props.label}
    </SelectTrigger>
  );
});

type Props = {
  onClick: (timezone: string) => void;
  rootProps?: Omit<AtlasSelectRootProps, "children">;
  contentProps?: Omit<AtlasSelectContentProps, "items">;
  triggerProps?: TimezoneSelectTriggerProps;
};

export function TimezoneSelector({
  onClick,
  rootProps = {},
  contentProps = {},
  triggerProps = {
    label: "Set candidate timezone",
  },
}: Props) {
  const items = useTimezoneSelectionItems(onClick);

  return (
    <Select.Root searchable {...rootProps}>
      <Select.Trigger>
        <TimezoneSelectTrigger {...triggerProps} />
      </Select.Trigger>
      <Select.Content
        {...contentProps}
        items={items}
        className={clsx("w-[320px]", contentProps.className)}
      />
    </Select.Root>
  );
}
