import { View } from "@resource/atlas/view/View";
import { ExtensionUpdateBanner } from "client/app/extension/__components/ExtensionUpdateBanner";
import { NotificationList } from "client/components/notifications/NotificationList";

export type ExtensionNotificationsProps = {
  setOpen?: (open: boolean) => void;
};

export function ExtensionNotifications({
  setOpen,
}: ExtensionNotificationsProps) {
  return (
    <View
      CustomHeader={<ExtensionUpdateBanner />}
      content={{
        className: "p-0",
      }}
    >
      <NotificationList setOpen={setOpen} />
    </View>
  );
}
