import clsx from "clsx";
import GuideLogoSVG from "components/GuideLogoSVG";

type GuideLogoProps = {
  showV2Label?: boolean;
  logoClassName?: string;
  textClassName?: string;
  variant?: "dark" | "light";
  className?: string;
};

export function GuideLogo({
  showV2Label = false,
  logoClassName,
  textClassName,
  variant = "dark",
  className,
}: GuideLogoProps) {
  return (
    <div className={clsx("flex gap-1.5 items-center", className)}>
      <GuideLogoSVG
        className={clsx("h-7 w-[4.875rem]", logoClassName)}
        textClassName={textClassName}
        variant={variant}
      />
      {showV2Label && (
        <div
          className={clsx(
            "flex justify-center items-center text-body-sm-heavy text-[.6875rem] px-[.3125rem] h-5 rounded-lg border-purple-500 border-[0.09375rem] text-purple-500",
            textClassName
          )}
        >
          2.0
        </div>
      )}
    </div>
  );
}
