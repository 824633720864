// TODO: Merge with modules/lexical
import type { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor/types";
import isUrl from "is-url";
import prependHttp from "prepend-http";
import { RICH_BLOCK_NAME as GUIDE_AVAILABILITY_REQUEST_RICH_BLOCK_NAME } from "shared/guide-availability/rich-blocks/guide-availability-request";
import {
  RICH_BLOCK_NAME as INTERVIEWS_RICH_BLOCK_NAME,
  SerializedInterviewsNode,
} from "shared/guide-content/rich-blocks/interviews";
import {
  RICH_BLOCK_NAME as SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME,
  SerializedSelfScheduleRequestNode,
} from "shared/self-schedule/rich-blocks/self-schedule-request";

export const validateVideoIntroductionUrl = (val?: string) =>
  !!(
    isUrl(prependHttp(val ?? "")) &&
    (val?.toLowerCase().includes("youtube.com") ||
      val?.toLowerCase().includes("youtu.be") ||
      val?.toLowerCase().includes("loom.com"))
  );

// TODO: Make this a part of Content Editor with better checks for each type of node.
// Ideally, The checks should be performed on the type of node and not on the children directly.
/** @deprecated use `getLexicalHasContent` in `shared/utils/lexical.ts` instead */
export const contentEditorIsEmpty = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  const nonEmptyNodeAtRoot = content?.root.children.some(
    // Node is not empty when
    // * either the children doesn't exist which is true in case of rich blocks
    // * or children exists and has a length more than 0
    // @ts-expect-error children should exist for this to be considered not empty.
    (node) => !node.children || node.children.length > 0
  );
  return !nonEmptyNodeAtRoot;
};

export const contentEditorHasUnknownVariables = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  const greenhouseVariable = content?.root.children.some((node) =>
    // @ts-expect-error These lexical nodes have children
    node.children?.some(
      (child: { type: string; text: string; value: unknown }) =>
        child.type === "unknown-variable"
    )
  );

  return greenhouseVariable;
};

export const contentEditorHasUnfilledVariables = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  const unfilledVariable = content?.root.children.some((node) =>
    // @ts-expect-error These lexical nodes have children
    node.children?.some(
      (child: { type: string; text: string; value: unknown }) =>
        child.type === "variable" &&
        (child.value === undefined || child.value === "")
    )
  );

  return unfilledVariable;
};

export const contentEditorHasRequestAvailabilityBlock = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  return content?.root.children.some(
    (node) => node.type === GUIDE_AVAILABILITY_REQUEST_RICH_BLOCK_NAME
  );
};

export const contentEditorHasShareAvailabilityBlock = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  return content?.root.children.some(
    (node) =>
      node.type === "share-availability" ||
      node.type === "guide-availability-submission"
  );
};

export const contentEditorHasSelfScheduleRequestBlock = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  return content?.root.children.some(
    (node) => node.type === SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME
  );
};

const selfScheduleBlockIsValid = (block: SerializedSelfScheduleRequestNode) => {
  return (
    "interviewSettings" in block.data || "selfScheduleRequestId" in block.data
  );
};

export const contentEditorHasInvalidSelfScheduleRequestBlock = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  const selfScheduleBlocks = content?.root.children.filter(
    (node): node is SerializedSelfScheduleRequestNode =>
      node.type === SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME
  );

  return (
    selfScheduleBlocks &&
    selfScheduleBlocks.length > 0 &&
    selfScheduleBlocks.some((block) => {
      return !selfScheduleBlockIsValid(block);
    })
  );
};

export const contentEditorHasInterviewsBlock = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  return content?.root.children.some((node) => node.type === "interviews");
};

const interviewsBlockIsValid = (block: SerializedInterviewsNode) => {
  return "interviews" in block.data && block.data.interviews.length > 0;
};

/**
 * The interviews block initializes with `interviews: []` so this generally means it's still loading the interviews,
 * but could also be true if there are no relevant interviews.
 */
export const contentEditorHasInvalidInterviewsBlock = (
  content: AtlasContentEditorSerializedState | undefined
) => {
  const interviewsBlocks = content?.root.children.filter(
    (node): node is SerializedInterviewsNode =>
      node.type === INTERVIEWS_RICH_BLOCK_NAME
  );

  return (
    interviewsBlocks &&
    interviewsBlocks.length > 0 &&
    interviewsBlocks.some((block) => {
      return !interviewsBlockIsValid(block);
    })
  );
};
