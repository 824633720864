import { Checkbox } from "@resource/atlas/checkbox/Checkbox";

type SchedulingRequestEventPrivacySettingsProps = {
  defaultPrivate: boolean;
  setDefaultPrivate: (value: boolean) => unknown;
};

export function SchedulingRequestEventPrivacySettings({
  defaultPrivate,
  setDefaultPrivate,
}: SchedulingRequestEventPrivacySettingsProps) {
  return (
    <div className="flex space-x-2">
      <Checkbox
        checked={defaultPrivate}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setDefaultPrivate(true);
          } else {
            setDefaultPrivate(false);
          }
        }}
      />
      <span className="text-body-md">
        Set interviewer Google Calendar visibility to private for all interviews
      </span>
    </div>
  );
}
