import { Icon } from "@resource/atlas/icon/Icon";
import {
  CollaborativeCodingServiceType,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import {
  getCollaborativeCodingIcon,
  getVideoConferencingIcon,
} from "shared/utils/interview-services";

export function VideoConferencingIcon({
  service,
  isInPast,
}: {
  service: VideoConferencingServiceType;
  isInPast: boolean;
}) {
  const iconContent = getVideoConferencingIcon({ service, isInPast });
  return iconContent ? (
    <Icon content={iconContent} className="w-5 h-5" />
  ) : null;
}

export function CollaborativeCodingIcon({
  service,
  isInPast,
}: {
  service: CollaborativeCodingServiceType;
  isInPast: boolean;
}) {
  const iconContent = getCollaborativeCodingIcon({ service, isInPast });
  return iconContent ? (
    <Icon content={iconContent} className="w-5 h-5" />
  ) : null;
}
