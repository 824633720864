import { MutationHookOptions } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import {
  Exact,
  ReopenSchedulingRequestInput,
  ReopenSchedulingRequestMutation,
} from "generated/graphql-codegen/graphql";
import useMutation from "utils/useMutation";

const REOPEN_SCHEDULING_REQUEST = gql(`
  mutation ReopenSchedulingRequest($input: ReopenSchedulingRequestInput!) {
    reopenSchedulingRequest(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        status
        taskStatus
        archivedAt
      }
    }
  }
`);

export function useReopenSchedulingRequest(
  options?: MutationHookOptions<
    ReopenSchedulingRequestMutation,
    Exact<{
      input: ReopenSchedulingRequestInput;
    }>
  >
) {
  return useMutation(REOPEN_SCHEDULING_REQUEST, {
    ...options,
  });
}
