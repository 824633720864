import { SchedulerEditableScheduledInterview } from "client/scheduler/utils/types";
import { partition, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";

import { useSchedulerCalendarColors } from "../calendar/hooks/useSchedulerCalendarColors";
import { useInterviewersAlreadyInterviewedMap } from "./useInterviewersAlreadyInterviewedMap";
import { useInterviewsOutsideWorkingHoursMap } from "./useInterviewsOutsideWorkingHoursMap";
import { useInterviewsWithBlockedDatesMap } from "./useInterviewsWithBlockedDatesMap";
import { useInterviewsWithEventConflictsMap } from "./useInterviewsWithEventConflictsMap";
import { useInterviewsWithOverloadsMap } from "./useInterviewsWithOverloadsMap";

export function useGetInterviewConflictData() {
  const interviewsWithEventConflictsMap = useInterviewsWithEventConflictsMap();
  const interviewsWithOverloadsMap = useInterviewsWithOverloadsMap();
  const interviewsWithInterviewersAlreadyInterviewedMap =
    useInterviewersAlreadyInterviewedMap();
  const interviewsWithBlockedDatesMap = useInterviewsWithBlockedDatesMap();
  const interviewsWithInterviewersOutsideWorkingHoursMap =
    useInterviewsOutsideWorkingHoursMap();

  const calendarColors = useSchedulerCalendarColors();

  return useCallback(
    (interview: SchedulerEditableScheduledInterview | undefined | null) => {
      const eventConflicts = interview
        ? interviewsWithEventConflictsMap[interview.id]?.map((conflict) => ({
            ...conflict,
            events: conflict.events.map((e) => ({
              ...e,
              colorConfig: calendarColors[e.calendarId],
            })),
          }))
        : [];

      const rawOverloadedInterviewers = interview
        ? interviewsWithOverloadsMap[interview.id] ?? []
        : [];
      const uniqueOverloadedInterviewers = uniqBy(
        rawOverloadedInterviewers,
        "id"
      );

      const rawInterviewersAlreadyInterviewed = interview
        ? interviewsWithInterviewersAlreadyInterviewedMap[interview.id] ?? []
        : [];
      const uniqueInterviewersAlreadyInterviewed = uniqBy(
        rawInterviewersAlreadyInterviewed,
        "id"
      );

      const rawInterviewersOutsideWorkingHours = interview
        ? interviewsWithInterviewersOutsideWorkingHoursMap[interview.id] ?? []
        : [];
      const uniqueInterviewersOutsideWorkingHours = uniqBy(
        rawInterviewersOutsideWorkingHours,
        "id"
      );

      const [allWhitelistedConflicts, allNonWhitelistedConflicts] = partition(
        eventConflicts.flatMap((e) => e.events),
        "isWhitelisted"
      );

      const blockedDateConflicts = interview
        ? interviewsWithBlockedDatesMap[interview.id]?.blockedDates ?? []
        : [];

      const hasConflicts =
        allNonWhitelistedConflicts.length > 0 ||
        uniqueOverloadedInterviewers.length > 0 ||
        uniqueInterviewersAlreadyInterviewed.length > 0 ||
        uniqueInterviewersOutsideWorkingHours.length > 0 ||
        blockedDateConflicts.length > 0;
      const hasWhitelistedEventConflicts = allWhitelistedConflicts.length > 0;

      return {
        hasWhitelistedEventConflicts,
        hasConflicts,
        eventConflicts,
        nonWhitelistedConflicts: allNonWhitelistedConflicts,
        overloadedInterviewers: uniqueOverloadedInterviewers,
        interviewersAlreadyInterviewed: uniqueInterviewersAlreadyInterviewed,
        interviewersOutsideWorkingHours: uniqueInterviewersOutsideWorkingHours,
        blockedDateConflicts,
      };
    },
    [
      interviewsWithEventConflictsMap,
      interviewsWithOverloadsMap,
      interviewsWithInterviewersAlreadyInterviewedMap,
      interviewsWithBlockedDatesMap,
      interviewsWithInterviewersOutsideWorkingHoursMap,
      calendarColors,
    ]
  );
}

export function useInterviewConflictData(
  interview: SchedulerEditableScheduledInterview | undefined | null
) {
  const getInterviewWithConflicts = useGetInterviewConflictData();

  return useMemo(
    () => getInterviewWithConflicts(interview),
    [getInterviewWithConflicts, interview]
  );
}
