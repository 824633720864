import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { useCallback } from "react";

export function useSchedulingRequestNavigation({
  schedulingRequestId,
}: {
  schedulingRequestId: string;
}) {
  const globalNavigator = useGlobalNavigator();

  return useCallback(() => {
    if (globalNavigator) {
      globalNavigator.navigate("schedulingTaskDetails", {
        schedulingRequestId,
      });
    }
  }, [globalNavigator, schedulingRequestId]);
}
