/* eslint-disable import/prefer-default-export */
import { Checkbox as AriakitCheckbox } from "ariakit";
import clsx from "clsx";

import { atlasSmallCheck, atlasSmallMinus } from "../../icons/atlas-small";
import { setTooltipConfig } from "../__utils/__deprecated";
import { createComponentUtils } from "../__utils/atlas";
import { Icon } from "../icon/Icon";
import type { AtlasCheckboxComponent } from "./types";

// config
// ------

const COMPONENT_NAME = "Checkbox";

const CHECK_ICON = atlasSmallCheck;
const MIXED_ICON = atlasSmallMinus;

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// checkbox
// --------

/**
 * A checkbox component. Supports the "mixed" state.
 *
 * @example
 * ```jsx
 * <Checkbox checked />
 * ```
 */
export const Checkbox = createComponent<AtlasCheckboxComponent>((props) => (
  <AriakitCheckbox {...props}>
    {(checkboxProps) => {
      const checked = checkboxProps["aria-checked"];
      const color = props.color || checkboxProps.color;

      return (
        <div {...checkboxProps} className={clsx(ROOT, checkboxProps.className)}>
          <div
            className={clsx(el`bg`, {
              [`!bg-[${color}]`]: !!color,
            })}
          >
            <Icon
              className={el`icon`}
              size="custom"
              content={checked === "mixed" ? MIXED_ICON : CHECK_ICON}
            />
          </div>
        </div>
      );
    }}
  </AriakitCheckbox>
));

// TODO: remove when "slot" becomes the default mode
setTooltipConfig(Checkbox, { mode: "slot" });
