import { EditInterviewerSlotDisplayState } from "../hooks/useEditInterviewerSlotDisplayState";
import { EditPersonConfigSelectedInterviewerDisplay } from "./EditPersonConfigSelectedInterviewerDisplay";
import { EnableInterviewerTrainingToggle } from "./EnableInterviewerTrainingToggle";

export type EditPersonConfigDisplayProps = {
  state: EditInterviewerSlotDisplayState;
};

/** Display an editable person config slot */
export function EditPersonConfigDisplay({
  state,
}: EditPersonConfigDisplayProps) {
  const {
    interviewerSlot,
    disableInterviewerTraining,
    onChangeTrainingEnabled,
  } = state;
  const { trainingEnabled } = interviewerSlot;

  return (
    <div className="rounded-md group shadow-1">
      <EditPersonConfigSelectedInterviewerDisplay state={state} />
      {!disableInterviewerTraining && (
        <EnableInterviewerTrainingToggle
          enableTraining={trainingEnabled}
          setTrainingEnabled={onChangeTrainingEnabled}
        />
      )}
      {trainingEnabled && (
        <EditPersonConfigSelectedInterviewerDisplay
          paddingTopClassName="pt-1"
          state={state}
          isShadower
        />
      )}
    </div>
  );
}
