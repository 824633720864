import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  AtlasContentEditorSerializedState,
  useContentEditor,
} from "@resource/atlas/content-editor";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import {
  Dialog,
  DialogProps,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { useGuideUpdateValueSet } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/content-editor/use-guide-update-value-set";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { GuideUpdateEditor } from "client/components/guide-content/GuideUpdateEditor";
import { useFlags } from "client/lib/launchdarkly";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { PostTemplateLoader } from "components/MessageComposer/PostTemplateLoader";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import {
  AvailabilityRequestForManageAvailabilityRequestFragment,
  PostTemplateForGuideComposeMessageDefaultTemplateFragment,
  PostTemplateType,
} from "generated/graphql-codegen/graphql";
import { $getRoot } from "lexical";
import { useCallback, useEffect, useMemo, useState } from "react";
// eslint-disable-next-line import/no-restricted-paths
import { createGuideUpdateHeadlessEditor } from "server/guide-content/update/headless-editor";
import { GuideUpdateVariableSet } from "shared/guide-content/update";
import useMutation from "utils/useMutation";

import { $createGuideAvailabilityRequestNode } from "../rich-blocks/guide-availability-request/__lib/rich-block";
import { CreateOrUpdateAvailabilityRequestDialog } from "./CreateOrUpdateAvailabilityRequestDialog";
import { ManageAvailabilityRequest } from "./ManageAvailabilityRequest";

const FOLLOW_UP_MESSAGE_KEY = "__guide__follow_up_message";
const DEFAULT_REMINDER =
  '{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"Hey, just a friendly nudge to submit availability for your upcoming interviews.","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}';

gql(`
  fragment GuideAvailabilityRequestForFollowUpReminder on GuideAvailabilityRequest {
    id
    notes
    suggestions {
      id
      title
      startTime
      endTime
    }
    guide {
      id
      candidate {
        id
        name
      }
      ...GuideBaseVariableValuesGuide
    }
    createdBy {
      id
      name
      imageUrl
    }
    guidePost {
      id
      senderUser {
        id
        firstName
        lastName
        imageUrl
      }
      recipientProfiles {
        id
        isCandidate
      }
      removedRecipients
    }
  }
`);

const SEND_FOLLOW_UP_MESSAGE = gql(`
  mutation SendFollowUpMessage ($input: CreateGuidePostV2Input!) {
    createGuidePostV2(input: $input) {
      success
      code
      message
    }
  }
`);

type FollowUpReminderDialogProps = DialogProps & {
  availabilityRequest?: AvailabilityRequestForManageAvailabilityRequestFragment | null;
};

export function FollowUpReminderDialog({
  store,
  availabilityRequest,
  ...props
}: FollowUpReminderDialogProps) {
  const editStore = useDialogStore();
  const {
    [FeatureFlags.USE_TEMPLATE_FOR_AVAILABILITY_REQUEST_FOLLOWUP]:
      useTemplateEnabled,
  } = useFlags();

  const onEditRequest = useCallback(() => {
    editStore.show();
  }, [editStore]);
  const state = store.getState();
  const { editor, contentEditorProps } = useContentEditor();
  const [template, setTemplate] =
    useState<PostTemplateForGuideComposeMessageDefaultTemplateFragment | null>();

  const sender = availabilityRequest?.guidePost?.senderUser;
  const guide = availabilityRequest?.guide;

  const guideUpdateValueSet = useMemo((): ValueSet<GuideUpdateVariableSet> => {
    return {
      authorName: sender
        ? {
            first: sender.firstName ?? "",
            last: sender.lastName ?? "",
          }
        : undefined,
    };
  }, [sender]);

  const valueSet = useGuideUpdateValueSet({
    guide,
    valueSet: guideUpdateValueSet,
  });

  const { user } = useAuthContext();

  const [sendMessageMutation, { loading: submitLoading }] = useMutation(
    SEND_FOLLOW_UP_MESSAGE
  );

  const candidateName = useMemo(
    () => availabilityRequest?.guide?.candidate?.name,
    [availabilityRequest]
  );

  useEffect(() => {
    if (state.open) {
      const message =
        window.localStorage.getItem(FOLLOW_UP_MESSAGE_KEY) || DEFAULT_REMINDER;
      editor.setEditorState(editor.parseEditorState(JSON.parse(message)));
      setTimeout(() => editor.focus());
    }
  }, [state.open, editor]);

  const loadTemplate = useCallback(
    (t: PostTemplateForGuideComposeMessageDefaultTemplateFragment) => {
      setTemplate(t);

      editor.setEditorState(
        editor.parseEditorState(t.data as AtlasContentEditorSerializedState)
      );
      editor?.focus();
    },
    [editor]
  );

  const sendMessage = useCallback(() => {
    if (availabilityRequest) {
      window.localStorage.setItem(
        FOLLOW_UP_MESSAGE_KEY,
        JSON.stringify(editor.getEditorState())
      );

      const headless = createGuideUpdateHeadlessEditor();
      headless.setEditorState(editor.getEditorState());
      headless.update(
        () => {
          $getRoot().append(
            $createGuideAvailabilityRequestNode({
              createdBy: availabilityRequest.createdBy,
              availabilityRequestId: availabilityRequest?.id,
              originalInputData: {
                suggestions: availabilityRequest.suggestions,
                notes: availabilityRequest.notes,
              },
            })
          );
        },
        {
          discrete: true,
        }
      );

      const content = JSON.stringify(headless.getEditorState().toJSON());

      sendMessageMutation({
        variables: {
          input: {
            guideId: availabilityRequest.guide.id,
            post: {
              content,
              threadId: availabilityRequest.guidePost?.id,
              senderId: user?.currentUserMembership?.id,
              recipients:
                availabilityRequest.guidePost?.recipientProfiles.map(
                  (item) => ({
                    userMembershipId: item.isCandidate ? null : item.id,
                    isCandidate: item.isCandidate,
                  })
                ) ?? [],
            },
          },
        },
        onCompleted: () => store.hide(),
      });
    }
  }, [
    availabilityRequest,
    editor,
    sendMessageMutation,
    store,
    user?.currentUserMembership?.id,
  ]);

  return (
    <>
      <Dialog size="medium" store={store} {...props}>
        <ErrorBoundary
          fallback={({ onRecover }) => (
            <DialogError
              className="!min-h-[min(90vh,_50rem)]"
              header={{
                title: "Message candidate",
              }}
              onRecover={onRecover}
            />
          )}
        >
          <View
            footer={{
              rightActions: (
                <ButtonGroup>
                  <Button isGhost onClick={() => store.hide()}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={sendMessage}
                    isLoading={submitLoading}
                  >
                    Send message
                  </Button>
                </ButtonGroup>
              ),
            }}
          >
            <div className="space-y-4 text-body-md">
              <div className="text-h4">
                Send availability reminder to {candidateName}
              </div>
              {useTemplateEnabled && (
                <FormGroup label="Template">
                  <PostTemplateLoader
                    onSelect={loadTemplate}
                    onTemplateEditCompleted={loadTemplate}
                    selectedTemplate={template}
                    type={PostTemplateType.request_availability}
                  />
                </FormGroup>
              )}
              <FormGroup label="Message">
                <GuideUpdateEditor
                  id="availability-request-reminder-lexical-editor"
                  isGhost={false}
                  className="grow flex flex-col min-h-[7.5rem]"
                  valueSet={valueSet}
                  // Users cannot include new availability requests etc in follow-up messages: See ENG-3975
                  disableGuideRichBlockModules
                  {...contentEditorProps}
                />
              </FormGroup>

              {availabilityRequest && (
                <div className="space-y-2">
                  <p className="text-body-sm text-subtle">
                    We&apos;ll include the original availability request in your
                    message.
                  </p>
                  <ManageAvailabilityRequest
                    guideId={availabilityRequest.guide.id}
                    onEdit={onEditRequest}
                    availabilityRequest={availabilityRequest ?? undefined}
                    isFollowUpReminder
                  />
                </div>
              )}
            </div>
          </View>
        </ErrorBoundary>
        <CreateOrUpdateAvailabilityRequestDialog
          store={editStore}
          availabilityRequestId={availabilityRequest?.id}
        />
      </Dialog>
    </>
  );
}
