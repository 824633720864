import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasCode,
  serviceCoderpad,
  serviceCodesignal,
  serviceHackerrank,
} from "@resource/atlas/icons";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";

export const CODERPAD_URL_PATTERN =
  "(https):\\/\\/(app\\.)?coderpad\\.io\\/[\\S]*";
export const CODERPAD_URL_REGEX = new RegExp(CODERPAD_URL_PATTERN);

export const CODESIGNAL_URL_PATTERN =
  "((https):\\/\\/)([^/]*\\.)?codesignal\\.com\\/(live-interview)\\/[^\\s,&]*";
export const CODESIGNAL_URL_REGEX = new RegExp(CODESIGNAL_URL_PATTERN);

export const HACKERRANK_URL_PATTERN =
  "(https):\\/\\/([^/]*\\.)?(hackerrank\\.io|hr\\.gs)\\/[\\S]*";
export const HACKERRANK_URL_REGEX = new RegExp(HACKERRANK_URL_PATTERN);

export const isCoderpadCodingUrl = (value: string) =>
  CODERPAD_URL_REGEX.test(value);

export const isHackerRankCodingUrl = (value: string) =>
  HACKERRANK_URL_REGEX.test(value);

export const isCodeSignalCodingUrl = (value: string) =>
  CODESIGNAL_URL_REGEX.test(value);

export const isCollaborativeCodingUrl = (value: string) => {
  return (
    CODERPAD_URL_REGEX.test(value) ||
    CODESIGNAL_URL_REGEX.test(value) ||
    HACKERRANK_URL_REGEX.test(value)
  );
};
export const extractCollaborativeCodingUrl = (text: string): string | null => {
  const coderpadMatch = text.match(CODERPAD_URL_REGEX);
  if (coderpadMatch) {
    return coderpadMatch[0];
  }

  const codesignalMatch = text.match(CODESIGNAL_URL_REGEX);
  if (codesignalMatch) {
    return codesignalMatch[0];
  }

  const hackerrankMatch = text.match(HACKERRANK_URL_REGEX);
  if (hackerrankMatch) {
    return hackerrankMatch[0];
  }

  return null;
};

export const DEFAULT_COLLABORATIVE_CODING_ICON = atlasCode;

export const COLLABORATIVE_CODING_ICONS: Record<
  CollaborativeCodingServiceType,
  AtlasIconData | null
> = {
  [CollaborativeCodingServiceType.CODERPAD]: serviceCoderpad,
  [CollaborativeCodingServiceType.CODESIGNAL]: serviceCodesignal,
  [CollaborativeCodingServiceType.HACKERRANK]: serviceHackerrank,
  [CollaborativeCodingServiceType.CUSTOM]: null,
};

export const getCollaborativeCodingName = (service: string) => {
  switch (service) {
    case CollaborativeCodingServiceType.CODERPAD:
      return "CoderPad";
    case CollaborativeCodingServiceType.CODESIGNAL:
      return "CodeSignal";
    case CollaborativeCodingServiceType.HACKERRANK:
      return "HackerRank";
    default:
      return "Live coding";
  }
};

export const COLLABORATIVE_CODING_LABELS: Record<
  CollaborativeCodingServiceType,
  string
> = {
  [CollaborativeCodingServiceType.CODERPAD]: "CoderPad",
  [CollaborativeCodingServiceType.CODESIGNAL]: "CodeSignal",
  [CollaborativeCodingServiceType.HACKERRANK]: "HackerRank",
  [CollaborativeCodingServiceType.CUSTOM]: "Custom",
};
