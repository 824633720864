import {
  InterviewerRequirementForConfigurationFragment,
  InterviewerRequirementTagFilterInput,
  InterviewerRequirementV2Input,
  InterviewRequirementForConfigurationFragment,
  InterviewRequirementV2Input,
} from "generated/graphql-codegen/graphql";
import { omit } from "lodash";

export const mapTagFilterInputFromTagFilterFragment = (
  tagFilter: InterviewerRequirementForConfigurationFragment["tagFilters"][number]
): InterviewerRequirementTagFilterInput => {
  return {
    id: tagFilter.id,
    type: tagFilter.type,
    tagIds: tagFilter.tags.map((t) => t.id),
  };
};

export const mapInterviewerRequirementV2InputFromInterviewerRequirementFragment =
  (
    interviewerRequirement: InterviewerRequirementForConfigurationFragment
  ): InterviewerRequirementV2Input => {
    return {
      id: interviewerRequirement.id,
      type: interviewerRequirement.type,
      shadowingUserMembershipIds:
        interviewerRequirement.shadowingUserMemberships.map((m) => m.id),
      isShadower: false,
      includeShadower: interviewerRequirement.includeShadower,
      useExternalInterviewerAlgorithm:
        interviewerRequirement.useExternalInterviewerAlgorithm,
      userMembershipIds: interviewerRequirement.userMemberships.map(
        (m) => m.id
      ),
      tagIds: [], // TODO: This field isn't used when interviewerPoolIds is set
      interviewerPoolIds: interviewerRequirement.interviewerPools.map(
        (t) => t.id
      ),
      tagFilters: interviewerRequirement.tagFilters.map(
        mapTagFilterInputFromTagFilterFragment
      ),
    };
  };

const mapVideoConferencingSettingsInputFromVideoConferencingSettings = (
  settings: InterviewRequirementForConfigurationFragment["videoConferencingSettings"]
) => {
  return settings?.service ? omit(settings, "__typename") : null;
};

const mapCollaborativeCodingSettingsInputFromCollaborativeCodingSettings = (
  settings: InterviewRequirementForConfigurationFragment["collaborativeCodingSettings"]
) => {
  return settings?.service ? omit(settings, "__typename") : null;
};

const mapConferenceRoomSettingsInputFromConferenceRoomSettings = (
  settings: InterviewRequirementForConfigurationFragment["conferenceRoomSettings"]
) => {
  return settings?.required
    ? {
        required: settings.required,
        building: settings?.building
          ? omit(settings?.building, "__typename")
          : null,
      }
    : null;
};

export const mapInterviewRequirementV2InputFromInterviewRequirementFragment = (
  interviewRequirement: InterviewRequirementForConfigurationFragment
): InterviewRequirementV2Input => {
  return {
    id: interviewRequirement.id,
    interviewId: interviewRequirement.interviewId,
    order: interviewRequirement.order,
    orderLocked: interviewRequirement.orderLocked,
    duration: interviewRequirement.duration,
    videoConferencingSettings:
      mapVideoConferencingSettingsInputFromVideoConferencingSettings(
        interviewRequirement.videoConferencingSettings
      ),
    collaborativeCodingSettings:
      mapCollaborativeCodingSettingsInputFromCollaborativeCodingSettings(
        interviewRequirement.collaborativeCodingSettings
      ),
    interviewerRequirements: interviewRequirement.interviewerRequirements.map(
      mapInterviewerRequirementV2InputFromInterviewerRequirementFragment
    ),
    conferenceRoomSettings:
      mapConferenceRoomSettingsInputFromConferenceRoomSettings(
        interviewRequirement.conferenceRoomSettings
      ),
    manuallyRemovedAt: interviewRequirement.manuallyRemovedAt,
    isPrivate: interviewRequirement.isPrivate,
  };
};
