/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useMemo, useState } from "react";

import { useEvent } from "../../../../__utils/react";

type Format = "bold" | "italic" | "underline";

export function useFormatContext() {
  const [editor] = useLexicalComposerContext();
  const [format, setFormat] = useState<Record<Format, boolean>>({
    bold: false,
    italic: false,
    underline: false,
  });

  const updateListener = useEvent(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setFormat({
        bold: selection.hasFormat("bold"),
        italic: selection.hasFormat("italic"),
        underline: selection.hasFormat("underline"),
      });
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const toggleFormat = useEvent((format: Format) =>
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, format)
  );

  const toggleBold = useEvent(() => toggleFormat("bold"));
  const toggleItalic = useEvent(() => toggleFormat("italic"));
  const toggleUnderline = useEvent(() => toggleFormat("underline"));

  const value = useMemo(
    () => ({ format, toggleFormat, toggleBold, toggleItalic, toggleUnderline }),
    [format, toggleBold, toggleFormat, toggleItalic, toggleUnderline]
  );

  return [updateListener, value] as const;
}
