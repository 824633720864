type UseIsTaskV2Props = {
  scheduledInterviewsForTask: {
    isV2: boolean;
  }[];
};

/**
 * If there are still V1 scheduled interviews on the task OR
 * if the feature flag is off, then we should use V1.
 * If there are V2 scheduled interviews on the task,
 * continue using V2.
 */
export function useIsTaskV2({ scheduledInterviewsForTask }: UseIsTaskV2Props) {
  const hasV1ScheduledInterviews = scheduledInterviewsForTask.some(
    (si) => !si.isV2
  );

  if (hasV1ScheduledInterviews) {
    return false;
  }

  return true;
}
