import { Button } from "@resource/atlas/button/Button";
import { atlasRingPlus, atlasTrash } from "@resource/atlas/icons";
import TextField from "@resource/atlas/textfield/TextField";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { ReactElement } from "react";
import { Control, Controller } from "react-hook-form";

import { ProfileFormData, WebsiteUrlField, WebsiteUrlObj } from "./types";

type WebsiteUrlsFormProps = {
  control: Control<ProfileFormData>;
  insertWebsiteUrl: (index: number, obj: WebsiteUrlObj) => void;
  removeWebsiteUrl: (index: number) => void;
};

type EditWebsiteUrlsProps = WebsiteUrlsFormProps & {
  label?: string;
  websiteUrlFields: WebsiteUrlField[];
};

type WebsiteUrlFieldRowProps = WebsiteUrlsFormProps & {
  index: number;
  disableRemove?: boolean;
};

const getNewUrl = (): WebsiteUrlObj => ({ url: "" });

function WebsiteUrlFieldRow({
  control,
  index,
  insertWebsiteUrl,
  removeWebsiteUrl,
  disableRemove,
}: WebsiteUrlFieldRowProps): ReactElement {
  const name: `websiteUrls.${number}.url` = `websiteUrls.${index}.url`;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { invalid, error } }) => (
        <div className="flex items-center mb-2">
          <Tooltip content={error?.message} UNSAFE_mode="slot">
            <TextField
              {...field}
              inputRef={field.ref}
              className="flex-grow mr-1"
              aria-label={`URL #${index + 1}`}
              onChange={(value: string) => {
                field.onChange({ target: { value } });
              }}
              validationState={invalid ? "invalid" : undefined}
              errorMessage={error?.message}
            />
          </Tooltip>
          <Button
            aria-label={`Add URL after #${index + 1}`}
            icon={atlasRingPlus}
            size="small"
            onClick={() => insertWebsiteUrl(index + 1, getNewUrl())}
            isGhost
          />
          <Button
            aria-label={`Remove URL #${index + 1}`}
            icon={atlasTrash}
            size="small"
            disabled={disableRemove}
            onClick={() => removeWebsiteUrl(index)}
            isGhost
          />
        </div>
      )}
    />
  );
}

function EditWebsiteUrls({
  control,
  label = "URL",
  websiteUrlFields,
  insertWebsiteUrl,
  removeWebsiteUrl,
}: EditWebsiteUrlsProps): ReactElement {
  return (
    <fieldset>
      <legend className="flex text-body-md-heavy pb-2">{label}</legend>
      {websiteUrlFields.map((field, index: number) => (
        <WebsiteUrlFieldRow
          key={field.id}
          control={control}
          index={index}
          insertWebsiteUrl={insertWebsiteUrl}
          removeWebsiteUrl={removeWebsiteUrl}
          disableRemove={websiteUrlFields.length === 1}
        />
      ))}
    </fieldset>
  );
}

export default EditWebsiteUrls;
