import { gql } from "generated/graphql-codegen";
import { DefaultSelfSchedulingSettingsForEditStateFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  SchedulingHoursConfig,
  SelfScheduleSchedulingSettingsInput,
} from "shared/self-schedule/types";
import {
  getDefaultSelfSchedulingSettings,
  validateRollingDateRange,
} from "shared/self-schedule/utils";

gql(`
  fragment DefaultSelfSchedulingSettingsForEditState on SelfSchedulingSettings {
    id
    schedulingHours {
      isWorkingDay
      startTime {
        hour
        minute
      }
      endTime {
        hour
        minute
      }
    }
    preMeetingBuffer {
      unit
      value
    }
    postMeetingBuffer {
      unit
      value
    }
    minimumNotice {
      unit
      value
    }
    rollingDateRange {
      days
      setting
    }
  }
`);

export type EditSelfSchedulingSettingsStateProps = {
  defaultSelfSchedulingSettings:
    | SelfScheduleSchedulingSettingsInput
    | DefaultSelfSchedulingSettingsForEditStateFragment
    | null;
  selectedInterviewer: {
    id: string;
    user: {
      id: string;
      timezone?: string | null;
    };
  } | null;
  skipDirtyCheck?: boolean;
};

export function useEditSelfSchedulingSettingsState({
  defaultSelfSchedulingSettings,
  selectedInterviewer,
  skipDirtyCheck,
}: EditSelfSchedulingSettingsStateProps) {
  const mappedDefault = useMemo(() => {
    if (!defaultSelfSchedulingSettings) {
      return getDefaultSelfSchedulingSettings();
    }

    return {
      ...defaultSelfSchedulingSettings,
      schedulingHours:
        defaultSelfSchedulingSettings.schedulingHours as SchedulingHoursConfig,
    };
  }, [defaultSelfSchedulingSettings]);
  const mappedSelectedInterviewer = useMemo(() => {
    return selectedInterviewer
      ? {
          userMembershipId: selectedInterviewer.id,
          timezone: selectedInterviewer.user.timezone,
        }
      : null;
  }, [selectedInterviewer]);
  const form = useForm<SelfScheduleSchedulingSettingsInput>({
    defaultValues: mappedDefault,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [
    schedulingHoursInvalidTimeRangesErrors,
    setSchedulingHoursInvalidTimeRangesErrors,
  ] = useState<Record<string, boolean>>({});
  const { setValue } = form;

  const onChange = useCallback(
    (...params: Parameters<typeof setValue>) => {
      setValue(params[0], params[1], {
        shouldDirty: true,
        ...params[2],
      });
    },
    [setValue]
  );

  const rollingDateRange = form.watch("rollingDateRange");

  const rollingDateRangeInvalidMessage = useMemo(() => {
    const { invalidMessage } = validateRollingDateRange(rollingDateRange);

    return invalidMessage;
  }, [rollingDateRange]);

  const disabledTooltipContent = useMemo(() => {
    if (!form.formState.isDirty && !skipDirtyCheck) {
      return "No changes made.";
    }

    if (Object.keys(schedulingHoursInvalidTimeRangesErrors).length) {
      return "Scheduling hours has an invalid time range. Please make sure the start time is before the end time.";
    }

    if (rollingDateRangeInvalidMessage) {
      return rollingDateRangeInvalidMessage;
    }

    return undefined;
  }, [
    skipDirtyCheck,
    form.formState.isDirty,
    rollingDateRangeInvalidMessage,
    schedulingHoursInvalidTimeRangesErrors,
  ]);

  const onInvalidTimeRange = useCallback(
    (id: string) => {
      setSchedulingHoursInvalidTimeRangesErrors((prev) => {
        return { ...prev, [id]: true };
      });
    },
    [setSchedulingHoursInvalidTimeRangesErrors]
  );

  const onValidTimeRange = useCallback(
    (id: string) => {
      setSchedulingHoursInvalidTimeRangesErrors((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    },
    [setSchedulingHoursInvalidTimeRangesErrors]
  );

  const onReset = useCallback(() => {
    form.reset(mappedDefault);
    setSchedulingHoursInvalidTimeRangesErrors({});
  }, [mappedDefault, form]);

  return useMemo(() => {
    return {
      form,
      selectedInterviewer: mappedSelectedInterviewer,
      onChange,
      disabledTooltipContent,
      onInvalidTimeRange,
      onValidTimeRange,
      onReset,
    };
  }, [
    disabledTooltipContent,
    form,
    onChange,
    onInvalidTimeRange,
    onReset,
    onValidTimeRange,
    mappedSelectedInterviewer,
  ]);
}

export type EditSelfSchedulingSettingsState = ReturnType<
  typeof useEditSelfSchedulingSettingsState
>;
