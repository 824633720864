import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasBuilding,
  atlasFlag,
  atlasGlobe,
  atlasLoader,
  atlasLogoGreenhouseDark,
  atlasMessage,
  atlasPerson,
  atlasRingCheck,
  atlasRingPerson,
  atlasRingQuestion,
  atlasSchedulingTypeRescheduleColor,
  atlasSchedulingTypeScheduleColor,
} from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { CandidateName } from "client/components/CandidateName";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import {
  AssigneeOption,
  AssigneeSelect,
} from "client/components/generic/select/AssigneeSelect";
import { TimezoneRequestInfoRow } from "client/components/scheduling-tasks/in-progress/__components/TimezoneRequestInfoRow";
import RequestInfoRow from "components/RequestInfoRow/RequestInfoRow";
import { gql } from "generated/graphql-codegen";
import {
  InterviewPanelLocation,
  SchedulingRequestAutoAssignOnAvailabilityReceiptType,
  SchedulingRequestForInProgressInfoFragment,
  SchedulingRequestPriority,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { formatEntity } from "shared/constants/entities";
import useMutation from "utils/useMutation";

import { useSchedulingTaskRescheduleReason } from "../hooks/useSchedulingTaskRescheduleReason";
import { SchedulingRequestStatusBadge } from "../SchedulingRequestStatusBadge";
import { getTaskStatusText } from "../utils";
import { PriorityBadgeSelect } from "./__components/PriorityBadgeSelect";
import { SchedulingRequestNotesRow } from "./__components/SchedulingRequestNotesRow";

gql(`
  fragment SchedulingRequestForInProgressInfo on SchedulingRequest {
    id
    status
    taskStatus
    isReschedule
    archivedAt
    mongoDBRegion
    rescheduleReason
    priority
    interviewPanelRequirement {
      id
      location
    }
    guide {
      id
      candidate {
        id
        name: internalName
        firstName: internalFirstName
        lastName
        preferredName
        timezone
      }
      isInternalATSApplication
      job {
        id
        internalName
      }
    }
    createdBy {
      id
      name
      imageUrl
    }
    assignedTo {
      id
      ...UserMembershipForAssigneeSelection
    }
    notes
    candidateNotes
    atssyncJobInterviewStage {
      id
      name
    }
    scheduledInterviews {
      id
      isV2
      pendingRescheduleInterviewRequirement {
        id
        reason
        otherReason
        additionalText
      }
    }
  }
`);

const ASSIGN_REQUEST_TO_USER = gql(`
  mutation AssignSchedulingRequestToUser($input: AssignSchedulingRequestInput!) {
    assignSchedulingRequest(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        ...SchedulingRequestForInProgressInfo
        organization {
          id

      schedulingRequestCounts {
        unassigned
        mine
        all
      }
        }
      }
    }
  }
`);

const CHANGE_REQUEST_PRIORITY = gql(`
  mutation ChangeSchedulingRequestPriority($input: ChangeSchedulingRequestPriorityInput!) {
    changeSchedulingRequestPriority(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        ...SchedulingRequestForInProgressInfo
      }
    }
  }
`);

const UPDATE_REQUEST_NOTES = gql(`
  mutation UpdateSchedulingRequestNote($input: EditSchedulingRequestNotesInput!) {
    editSchedulingRequestNotes(input: $input) {
      success
      message
      code
      schedulingRequest {
        id
        ...SchedulingRequestForInProgressInfo
      }
    }
  }
`);

export function SchedulingRequestInfo({
  schedulingRequest,
}: {
  schedulingRequest: SchedulingRequestForInProgressInfoFragment;
}) {
  const { guide, atssyncJobInterviewStage } = schedulingRequest;
  const { job, candidate } = guide;

  const rescheduleReason = useSchedulingTaskRescheduleReason({
    schedulingRequest,
  });

  const taskStatus = getTaskStatusText(schedulingRequest.taskStatus);

  const [assignRequestMutation, { loading }] = useMutation(
    ASSIGN_REQUEST_TO_USER
  );
  const [editNotesMutation, { loading: loadingEditNotes }] =
    useMutation(UPDATE_REQUEST_NOTES);

  const schedulingTaskNavigator = useGlobalNavigator();

  const onViewCandidateProfile = useCallback(() => {
    if (schedulingTaskNavigator) {
      schedulingTaskNavigator.navigate("candidateProfile", {
        guideId: guide.id,
      });
    }
  }, [guide.id, schedulingTaskNavigator]);

  const [changePriorityMutation, { loading: loadingPriorityChange }] =
    useMutation(CHANGE_REQUEST_PRIORITY);

  const assignRequest = useCallback(
    (newAssignee: AssigneeOption) => {
      if (typeof newAssignee === "string") {
        // The assigneeSelect technically could allow you to pick a string for an autoAssignOnAvailabilityReceiptType
        // if this were to happen, we'd have the select improperly configured
        // so only call the mutation if a user is selected
        return;
      }

      assignRequestMutation({
        variables: {
          input: {
            schedulingRequestId: schedulingRequest.id,
            userMembershipId: newAssignee?.id ?? null,
          },
        },
      });
    },
    [assignRequestMutation, schedulingRequest.id]
  );

  const changePriority = useCallback(
    (priority: SchedulingRequestPriority) => {
      changePriorityMutation({
        variables: {
          input: {
            schedulingRequestId: schedulingRequest.id,
            priority,
          },
        },
      });
    },
    [changePriorityMutation, schedulingRequest.id]
  );

  const editNotes = useCallback(
    (notes: string) => {
      editNotesMutation({
        variables: {
          input: {
            schedulingRequestId: schedulingRequest.id,
            notes,
          },
        },
      });
    },
    [editNotesMutation, schedulingRequest.id]
  );

  return (
    <div className="p-6 flex flex-col border-b border-gray-border space-y-4">
      <div className="space-y-2">
        <p
          className="text-body-sm-heavy text-subtle"
          data-id={schedulingRequest.id}
        >
          {formatEntity("scheduling request", { capitalize: true })}
        </p>
        <div className="space-y-[.375rem] flex flex-col">
          <div className="flex w-full items-center justify-between gap-2">
            <div
              className="text-h2 flex items-center grow w-full truncate"
              data-id={guide.id}
            >
              <CandidateName
                candidate={candidate}
                isInternal={guide.isInternalATSApplication}
              />
            </div>
            {schedulingTaskNavigator && (
              <Button
                icon={atlasRingPerson}
                negativeMargin="right"
                className="shrink-0"
                size="sm"
                onClick={onViewCandidateProfile}
              >
                Profile
              </Button>
            )}
          </div>
          <p className="text-body-md">
            {job.internalName}
            {atssyncJobInterviewStage ? (
              <>
                {" / "}
                <span>{atssyncJobInterviewStage?.name}</span>
              </>
            ) : null}
          </p>
        </div>
      </div>
      {schedulingRequest.interviewPanelRequirement?.location ===
        InterviewPanelLocation.ONSITE && (
        <AlertBanner Icon={<Icon content={atlasBuilding} />} variant="info">
          The candidate will be interviewing on-site.
        </AlertBanner>
      )}
      <div className="space-y-4">
        <RequestInfoRow
          icon={<Icon content={atlasPerson} />}
          label="Assignee"
          className="flex items-center gap-2"
        >
          <AssigneeSelect
            onSelect={(newAssignee) => {
              assignRequest(
                newAssignee ===
                  SchedulingRequestAutoAssignOnAvailabilityReceiptType.ASSIGN_TO_QUEUE
                  ? null
                  : newAssignee
              );
            }}
            assignee={schedulingRequest.assignedTo}
            updateLoading={loading}
            showSuggestedJobCoordinatorForGuideId={schedulingRequest.guide.id}
            variant="async"
          />
        </RequestInfoRow>
        <RequestInfoRow
          icon={<Icon content={atlasMessage} />}
          label="Created by"
        >
          <div className="flex items-center space-x-1">
            <Avatar
              size="xs"
              name={schedulingRequest.createdBy.name}
              image={schedulingRequest.createdBy.imageUrl}
            />
            <span className="text-body-sm">
              {schedulingRequest.createdBy.name}
            </span>
          </div>
        </RequestInfoRow>
        {schedulingRequest.atssyncJobInterviewStage?.name && (
          <RequestInfoRow
            icon={<Icon content={atlasLogoGreenhouseDark} />}
            label="Stage"
          >
            <span className="text-body-sm">
              {schedulingRequest.atssyncJobInterviewStage.name}
            </span>
          </RequestInfoRow>
        )}
        <RequestInfoRow icon={<Icon content={atlasRingCheck} />} label="Type">
          <div className="flex gap-2 items-center">
            {schedulingRequest.isReschedule ? (
              <>
                <Icon content={atlasSchedulingTypeRescheduleColor} />
                Rescheduling
              </>
            ) : (
              <>
                <Icon content={atlasSchedulingTypeScheduleColor} />
                Scheduling
              </>
            )}
          </div>
        </RequestInfoRow>
        {rescheduleReason && (
          <RequestInfoRow
            icon={<Icon content={atlasRingQuestion} />}
            label="Reason"
          >
            <p className="text-body-sm truncate" title={rescheduleReason}>
              {rescheduleReason}
            </p>
          </RequestInfoRow>
        )}
        <RequestInfoRow label="Priority" icon={<Icon content={atlasFlag} />}>
          {loadingPriorityChange ? (
            <LoadingIndicator size="small" />
          ) : (
            <PriorityBadgeSelect
              value={schedulingRequest.priority}
              onChange={changePriority}
            />
          )}
        </RequestInfoRow>
        {taskStatus && (
          <RequestInfoRow label="Status" icon={<Icon content={atlasLoader} />}>
            <SchedulingRequestStatusBadge
              schedulingRequest={schedulingRequest}
            />
          </RequestInfoRow>
        )}
        {schedulingRequest.mongoDBRegion && (
          <RequestInfoRow icon={<Icon content={atlasGlobe} />} label="Region">
            <p className="text-body-sm">{schedulingRequest.mongoDBRegion}</p>
          </RequestInfoRow>
        )}
        <TimezoneRequestInfoRow candidate={candidate} />
        <SchedulingRequestNotesRow
          notes={schedulingRequest.notes}
          editNotes={editNotes}
          loading={loadingEditNotes}
        />
      </div>
    </div>
  );
}
