import TextArea from "@resource/atlas/textfield/TextArea";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "react-use";

export type AddNotesProps = {
  title: string;
  placeholder: string;
  subtext?: string;
  notes: string;
  setNotes: (notes: string) => void;
  inputClassName?: string;
};

export function AddNotes({
  title,
  placeholder,
  subtext,
  notes,
  setNotes,
  inputClassName,
}: AddNotesProps) {
  const [localNotes, setLocalNotes] = useState(notes);

  useDebounce(
    () => {
      setNotes(localNotes);
    },
    300,
    [localNotes]
  );

  // If default notes change on re-open, restore local notes
  useEffect(() => {
    if (!localNotes && notes) {
      setLocalNotes(notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const handleNotesChange = useCallback((newNotes: string) => {
    setLocalNotes(newNotes);
  }, []);

  return (
    <div className="space-y-2">
      <p className="text-body-md-heavy text-dark">{title}</p>
      <TextArea
        placeholder={placeholder}
        aria-label="Notes"
        className="h-60"
        inputClassName={clsx(inputClassName, "h-60")}
        value={localNotes}
        onChange={handleNotesChange}
      />
      <p className="text-subtle text-body-sm">{subtext}</p>
    </div>
  );
}
