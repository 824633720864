import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View, ViewProps } from "@resource/atlas/view/View";
import { BackButton } from "client/components/generic/layout/BackButton";
import { useSyncDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { EditIndividualInterviewRequirementForm } from "client/scheduling-requirements-configuration/EditIndividualInterviewRequirementForm";
import { mapInterviewRequirementV2InputFromInterviewRequirementFragment } from "client/scheduling-requirements-configuration/utils";
import { gql } from "generated/graphql-codegen";
import { InterviewRequirementForConfigurationFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import useMutation from "utils/useMutation";

const UPDATE_INTERVIEW_REQUIREMENT = gql(`
  mutation UpsertInterviewRequirementForEditIndividualView($input: UpsertInterviewRequirementInput!) {
    upsertInterviewRequirement(input: $input) {
      code
      message
      success
      interviewRequirement {
        ...InterviewRequirementForConfiguration
      }
    }
  }
`);

type FormData = {
  interviewRequirement: InterviewRequirementForConfigurationFragment;
};

type BaseProps = {
  interviewRequirement: InterviewRequirementForConfigurationFragment;
  onBack: () => void;
  onCompleted?: () => void;
  /** If in a dialog, disable the confirmation for cancel click that is used within the global navigator */
  disableInternalBackConfirmation?: boolean;
} & ViewProps;

type Props = BaseProps &
  (
    | {
        schedulingRequestId: string;
      }
    | {
        schedulingRequestTemplateId: string;
      }
  );

export function EditIndividualInterviewRequirementView(props: Props) {
  const {
    interviewRequirement,
    onBack,
    onCompleted,
    header,
    footer,
    disableInternalBackConfirmation,
    ...rest
  } = props;
  const confirmationDialog = useDialogStore();
  const [saveInterviewRequirement, { loading }] = useMutation(
    UPDATE_INTERVIEW_REQUIREMENT
  );
  const { watch, handleSubmit, setValue, formState, reset } = useForm<FormData>(
    {
      defaultValues: {
        interviewRequirement,
      },
      mode: "onChange",
    }
  );
  const currentRequirement = watch("interviewRequirement");

  // TODO: Two different cancel confirmation implementations
  // One for global navigator, one for dialog
  // Ideally could be unified
  const onBackWithConfirmation = useCallback(() => {
    if (formState.isDirty && !disableInternalBackConfirmation) {
      confirmationDialog.show();
    } else {
      onBack();
    }
  }, [
    confirmationDialog,
    disableInternalBackConfirmation,
    formState.isDirty,
    onBack,
  ]);
  useSyncDialogLeaveConfirmation({
    showConfirmation: !formState.isSubmitted && formState.isDirty,
  });

  const updateInterviewRequirement = useCallback(
    (newRequirement: InterviewRequirementForConfigurationFragment) => {
      setValue("interviewRequirement", newRequirement, {
        shouldDirty: true,
      });
    },
    [setValue]
  );
  const onRemoveInterviewerRequirement = useCallback(
    ({ interviewerRequirementId }: { interviewerRequirementId: string }) => {
      updateInterviewRequirement({
        ...currentRequirement,
        interviewerRequirements:
          currentRequirement.interviewerRequirements.filter(
            (ir) => ir.id !== interviewerRequirementId
          ),
      });
    },
    [currentRequirement, updateInterviewRequirement]
  );

  const onSubmit = useCallback(
    (formData: FormData) => {
      saveInterviewRequirement({
        variables: {
          input: {
            interviewRequirement:
              mapInterviewRequirementV2InputFromInterviewRequirementFragment(
                formData.interviewRequirement
              ),
            ...("schedulingRequestId" in props
              ? {
                  // eslint-disable-next-line react/destructuring-assignment
                  schedulingRequestId: props.schedulingRequestId,
                }
              : {
                  schedulingRequestTemplateId:
                    // eslint-disable-next-line react/destructuring-assignment
                    props.schedulingRequestTemplateId,
                }),
          },
        },
        onCompleted: (data) => {
          if (data?.upsertInterviewRequirement?.success) {
            reset({
              interviewRequirement:
                data?.upsertInterviewRequirement.interviewRequirement,
            });
            onCompleted?.();
          }
        },
      });
    },
    [onCompleted, props, reset, saveInterviewRequirement]
  );

  return (
    <View
      as="form"
      {...rest}
      onSubmit={handleSubmit(onSubmit)}
      header={{
        title: "Edit interview requirement",
        leftActions: <BackButton onClick={onBackWithConfirmation} />,
        ...header,
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button isGhost onClick={onBackWithConfirmation} type="button">
              Cancel
            </Button>
            <OptionalTooltip
              isInstant
              content={
                formState.isDirty ? undefined : "No changes have been made."
              }
            >
              <Button
                type="submit"
                variant="primary"
                isLoading={loading}
                disabled={!formState.isDirty}
              >
                Save
              </Button>
            </OptionalTooltip>
          </ButtonGroup>
        ),
        ...footer,
      }}
    >
      <EditIndividualInterviewRequirementForm
        interviewRequirement={currentRequirement}
        updateInterviewRequirement={updateInterviewRequirement}
        onRemoveInterviewerRequirement={onRemoveInterviewerRequirement}
      />
      <Dialog store={confirmationDialog}>
        <View
          footer={{
            leftActions: (
              <Button
                onClick={() => {
                  confirmationDialog.hide();
                }}
              >
                Cancel
              </Button>
            ),
            rightActions: (
              <Button
                variant="primary"
                onClick={() => {
                  confirmationDialog.hide();
                  onBack();
                }}
              >
                Leave
              </Button>
            ),
          }}
        >
          <div className="text-body-md">
            You have unsaved changes. Are you sure you want to leave this page?
          </div>
        </View>
      </Dialog>
    </View>
  );
}
