import useGroupConferencingSettingsVisible from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/__hooks/useGroupConferencingSettingsVisible";
import { useAtomValue, useSetAtom } from "jotai";

import useExternalInterviewersAlgorithmVisible from "../components/external-interviewers-algorithm/hooks/useExternalInterviewersAlgorithmVisible";
import { scheduledInterviewGroupSettingsAtom } from "../state";

export function useScheduledInterviewGroupSettings() {
  return useAtomValue(scheduledInterviewGroupSettingsAtom);
}

export function useSetScheduledInterviewGroupSettings() {
  return useSetAtom(scheduledInterviewGroupSettingsAtom);
}

export function useScheduledInterviewGroupSettingsVisible() {
  const externalInterviewersAlgorithmVisible =
    useExternalInterviewersAlgorithmVisible();
  const groupConferencingSettingsVisible =
    useGroupConferencingSettingsVisible();
  return (
    externalInterviewersAlgorithmVisible || groupConferencingSettingsVisible
  );
}
