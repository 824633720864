import { Dialog, useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Link } from "@resource/atlas/link/Link";
import { CandidateAvailability } from "client/guide-availability/components/CandidateAvailability";
import { CreateOrUpdateAvailabilitySubmissionContent } from "client/guide-availability/components/CreateOrUpdateAvailabilitySubmissionDialog";
import { CreateOrUpdateAvailabilityData } from "client/guide-availability/components/CreateOrUpdateAvailabilityView";
import { CurrentCandidateAvailability } from "client/guide-availability/components/CurrentCandidateAvailability";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { GuideAvailabilitySubmissionsSubmissionFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useRef } from "react";

export function ManualCandidateAvailabilitySection({
  manualAvailabilityData,
  setManualAvailabilityData,
  currentSubmission,
}: {
  manualAvailabilityData: CreateOrUpdateAvailabilityData;
  setManualAvailabilityData: (data: CreateOrUpdateAvailabilityData) => void;
  currentSubmission?: GuideAvailabilitySubmissionsSubmissionFragment | null;
}) {
  const createOrUpdateAvailabilitySubmissionStore = useDialogStore();
  const hasSubmittedRef = useRef(false);
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store: createOrUpdateAvailabilitySubmissionStore,
    hasSubmittedRef,
  });
  const onAddManually = useCallback(() => {
    createOrUpdateAvailabilitySubmissionStore.show();
  }, [createOrUpdateAvailabilitySubmissionStore]);

  const onSubmit = useCallback(
    (state: CreateOrUpdateAvailabilityData) => {
      setManualAvailabilityData(state);
      hasSubmittedRef.current = true;
      createOrUpdateAvailabilitySubmissionStore.hide();
    },
    [createOrUpdateAvailabilitySubmissionStore, setManualAvailabilityData]
  );

  if (currentSubmission && currentSubmission.hasFutureAvailability) {
    return (
      <div className="space-y-3">
        <CurrentCandidateAvailability currentSubmission={currentSubmission} />
      </div>
    );
  }

  if (manualAvailabilityData.selections.length > 0) {
    return (
      <>
        <div className="space-y-2 text-body-md">
          <CandidateAvailability
            events={manualAvailabilityData.selections}
            isCurrentSubmission
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link as="button" variant="subtle" onClick={onAddManually}>
            Edit
          </Link>
        </div>
        <Dialog
          store={createOrUpdateAvailabilitySubmissionStore}
          variant="fullscreen"
        >
          <CreateOrUpdateAvailabilitySubmissionContent
            onSubmit={onSubmit}
            onCancel={createOrUpdateAvailabilitySubmissionStore.hide}
            stateProps={{
              defaultSelections: manualAvailabilityData.selections,
              defaultNotes: manualAvailabilityData.notes,
            }}
          />
        </Dialog>
      </>
    );
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link className="text-body-md" as="button" onClick={onAddManually}>
        Add availability manually
      </Link>
      <p className="text-body-sm text-subtle">
        <strong>Note: </strong> Task assignee will have the ability to request
        candidate availability after the task is created.
      </p>

      <Dialog
        {...dialogProps}
        store={createOrUpdateAvailabilitySubmissionStore}
        variant="fullscreen"
      >
        <CreateOrUpdateAvailabilitySubmissionContent
          onSubmit={onSubmit}
          onCancel={createOrUpdateAvailabilitySubmissionStore.hide}
          stateProps={{
            defaultSelections: manualAvailabilityData.selections,
            defaultNotes: manualAvailabilityData.notes,
          }}
        />
      </Dialog>
      <WarningDialog />
    </>
  );
}
