import {
  useDefaultValueFromLocalStorage,
  useSyncValueWithLocalStorage,
} from "client/utils/useSyncValueWithLocalStorage";
import React, { createContext, useContext, useMemo, useState } from "react";

interface AssistantContextValue {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AssistantContext = createContext<AssistantContextValue | undefined>(
  undefined
);

interface AssistantProviderProps {
  children?: React.ReactNode;
}

export function AssistantProvider({ children }: AssistantProviderProps) {
  const defaultIsOpen = useDefaultValueFromLocalStorage({
    key: "isOpen",
    prefix: "assistant",
  });

  const [isOpen, setIsOpen] = useState(defaultIsOpen === "true");

  useSyncValueWithLocalStorage({
    value: isOpen.toString(),
    key: "isOpen",
    prefix: "assistant",
  });

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
    }),
    [isOpen, setIsOpen]
  );

  return (
    <AssistantContext.Provider value={value}>
      {children}
    </AssistantContext.Provider>
  );
}

export function useAssistantContext() {
  return useContext(AssistantContext);
}

export function useAssistantContextOrThrow() {
  const context = useContext(AssistantContext);
  if (context === undefined) {
    throw new Error("useAssistant must be used within an AssistantProvider");
  }
  return context;
}
