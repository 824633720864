/* eslint-disable import/prefer-default-export */
import { createModule } from "../../../__utils/modules";
import { HeaderToolbarPlugin } from "./HeaderToolbarPlugin";

export const headerToolbarModule = createModule("fixed-toolbar", {
  declareModule({ insertOptions }) {
    return {
      plugins: [<HeaderToolbarPlugin insertOptions={insertOptions} />],
    };
  },
});
