import { mapScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/__utils/mapping";
import { InterviewWithSchedulingInfo } from "client/components/scheduled-interviews/ScheduleSummary/ScheduleSummary";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import {
  GroupSchedulingResultForCompletionPromptsFragment,
  ScheduledInterviewForScheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";

export const mapScheduledInterviewWithResult = (
  scheduledInterview: ScheduledInterviewForScheduledInterviewCardFragment,
  result: { errors: { message: string }[] } | undefined,
  status: ScheduledStatus | null
): InterviewWithSchedulingInfo => {
  return {
    ...mapScheduledInterviewForCard({
      interview: scheduledInterview,
    }),
    errors: result?.errors,
    status,
  };
};

export const mapSchedulingResults = (
  results: {
    scheduledInterview: ScheduledInterviewForScheduledInterviewCardFragment;
    errors: { message: string }[];
  }[],
  status: ScheduledStatus
): InterviewWithSchedulingInfo[] => {
  return results.map((result) =>
    mapScheduledInterviewWithResult(result.scheduledInterview, result, status)
  );
};

export const mapGroupSchedulingResultToSchedulingInfo = (
  schedulingResult: GroupSchedulingResultForCompletionPromptsFragment
): InterviewWithSchedulingInfo[] => {
  return [
    ...mapSchedulingResults(
      schedulingResult.createResults,
      ScheduledStatus.CREATED
    ),
    ...mapSchedulingResults(
      schedulingResult.updateResults,
      ScheduledStatus.UPDATED
    ),
    ...mapSchedulingResults(
      schedulingResult.deleteResults,
      ScheduledStatus.CANCELLED
    ),
  ];
};
