import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import {
  useInterviewerSwapReasonDefaultValues,
  useRescheduleReasonDefaultValues,
} from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportingReasonDefaultValues";
import {
  UpdateOrCancelReportingReasonsView,
  UpdateOrCancelReportingReasonsViewData,
} from "client/components/scheduled-interviews/ReportingReasons/wrappers/UpdateOrCancelReportingReasonsView";

import { useSchedulerDefaultReportingReason } from "./hooks/useSchedulerDefaultReportingReason";
import { SchedulerReportingReasonsState } from "./hooks/useSchedulerReportingReasonsState";
import { ScheduledInterviewForSchedulerReportingReasons } from "./utils/types";

export function SchedulerReportingReasonsDialog({
  store,
  state,
  scheduledInterviews,
  saveLoading,
  onCompleted,
}: {
  store: DialogStore;
  state: SchedulerReportingReasonsState;
  scheduledInterviews: ScheduledInterviewForSchedulerReportingReasons[];
  saveLoading: boolean;
  onCompleted: (reportingData: UpdateOrCancelReportingReasonsViewData) => void;
}) {
  const defaultReportingReason = useSchedulerDefaultReportingReason({
    scheduledInterviews,
  });
  const defaultsForRescheduleReportingReason = useRescheduleReasonDefaultValues(
    {
      originalInterviewers: state.allOriginalInterviewers,
    }
  );

  const defaultsForInterviewerSwapReportingReason =
    useInterviewerSwapReasonDefaultValues({
      originalInterviewers: state.allOriginalInterviewers,
    });

  return (
    <Dialog size="medium" store={store}>
      <UpdateOrCancelReportingReasonsView
        onCancel={store.hide}
        onCompleted={onCompleted}
        allOriginalInterviewers={state.allOriginalInterviewers}
        cancellations={state.cancellations}
        updates={state.updates}
        saveLoading={saveLoading}
        defaultInterviewerSwapData={defaultsForInterviewerSwapReportingReason}
        defaultRescheduleData={
          defaultReportingReason ?? defaultsForRescheduleReportingReason
        }
      />
    </Dialog>
  );
}
