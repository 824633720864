import { useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { ReschedulableInterviewFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NonNullableField } from "types/utility";
import useQuery from "utils/useQuery";

gql(`
  fragment ReschedulableInterview on ScheduledInterview {
    id
    interviewRequirementId
    isSelfScheduled
    endTime
    isCancelled
    pendingRescheduleInterviewRequirement {
      ...RescheduleInterviewRequirementForMapping
    }
    ...ScheduledInterviewForScheduledInterviewCard
  }
`);

const GUIDE_WITH_INTERVIEWS_FOR_RESCHEDULE_QUERY = gql(`
  query GuideWithInterviewsForRescheduleQuery($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      internalInterviews {
        ...ReschedulableInterview
      }
    }
  }
`);

const INTERNAL_INTERVIEWS_UPDATED_SUBSCRIPTION = gql(`
  subscription InternalInterviewsUpdated($guideId: ID!) {
    internalInterviewForGuideUpdated(guideId: $guideId) {
      ...ReschedulableInterview
    }
  }
`);

const INTERNAL_INTERVIEWS_DELETED_SUBSCRIPTION = gql(`
  subscription InterviewsDeleted($guideId: ID!) {
    interviewForGuideDeleted(guideId: $guideId) {
      id
    }
  }
`);

type InterviewForReschedule = NonNullableField<
  ReschedulableInterviewFragment,
  "interviewRequirementId"
>;

export function useReschedulableInterviews({ guideId }: { guideId: string }) {
  const [data, setData] = useState<ReschedulableInterviewFragment[]>([]);

  const validInterviewForReschedule = useCallback(
    (
      interview: ReschedulableInterviewFragment
    ): interview is InterviewForReschedule => {
      return (
        !!interview.interviewRequirementId && !!interview.schedulingRequestId
      );
    },
    []
  );

  const { data: queryData } = useQuery(
    GUIDE_WITH_INTERVIEWS_FOR_RESCHEDULE_QUERY,
    {
      variables: {
        guideId,
      },
      fetchPolicy: "network-only",
    }
  );
  const { data: subscriptionData } = useSubscription(
    INTERNAL_INTERVIEWS_UPDATED_SUBSCRIPTION,
    {
      variables: {
        guideId,
      },
    }
  );
  const { data: deletedData } = useSubscription(
    INTERNAL_INTERVIEWS_DELETED_SUBSCRIPTION,
    {
      variables: {
        guideId,
      },
    }
  );

  useEffect(() => {
    setData(queryData?.guideById?.internalInterviews ?? []);
  }, [queryData, validInterviewForReschedule]);

  // Updated or created interviews
  useEffect(() => {
    const updated = subscriptionData?.internalInterviewForGuideUpdated;
    if (!updated) {
      return;
    }

    setData((current) => {
      const index = current.findIndex(
        (interview) => interview.id === updated.id
      );

      if (index === -1) {
        return [...current, updated];
      }

      return [...current.slice(0, index), updated, ...current.slice(index + 1)];
    });
  }, [subscriptionData]);

  useEffect(() => {
    const deleted = deletedData?.interviewForGuideDeleted;
    if (!deleted) {
      return;
    }

    setData((current) =>
      current.filter((interview) => interview.id !== deleted.id)
    );
  }, [deletedData]);

  return useMemo(
    () => data.filter(validInterviewForReschedule),
    [data, validInterviewForReschedule]
  );
}
