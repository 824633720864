import { FooterProps, HeaderProps, View } from "@resource/atlas/view/View";
import { Calendar } from "client/components/calendar/Calendar";
import {
  ColorConfig,
  getCalendarColor,
} from "client/components/calendar/colors";
import {
  AddEventPlugin,
  CreatedEvent,
} from "client/components/calendar/plugins/AddEventPlugin";
import { AllDayEventsViewPlugin } from "client/components/calendar/plugins/all-day-events/AllDayEventsViewPlugin";
import {
  CalendarColorsConfig,
  CalendarEventInput,
} from "client/components/calendar/types";
import { combineEvents } from "client/components/calendar/utils";
import { useSyncDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import {
  GuideAvailabilitySubmissionSchedulingPreference,
  InterviewerForSelectionFragment,
  InterviewRequirementForInterviewRequirementsCardFragment,
} from "generated/graphql-codegen/graphql";
import { isEqual, uniq } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Event } from "shared/guide-availability/types";

import { AvailabilityDayHeaderPlugin } from "./AvailabilityDayHeaderPlugin";
import {
  AvailabilityLeftSidePanel,
  AvailabilityLeftSidePanelProps,
} from "./AvailabilityLeftSidePanel";
import { FetchInterviewerCalendarEventsPlugin } from "./FetchInterviewerCalendarEventsPlugin";

const mapCreatedEventToEvent = (event: CreatedEvent): Event => ({
  ...event,
  startTime: event.startTime.toISO(),
  endTime: event.endTime.toISO(),
});

const mapEventToCreatedEvent = (event: Event): CreatedEvent => ({
  ...event,
  startTime: DateTime.fromISO(event.startTime),
  endTime: DateTime.fromISO(event.endTime),
});

type SubmitButtonValidation =
  | {
      isValid: true;
      validationMessage?: undefined;
    }
  | {
      isValid: false;
      validationMessage: string;
    };

export type CreateOrUpdateAvailabilityState = {
  selections: Event[];
  setSelections: React.Dispatch<React.SetStateAction<Event[]>>;
  notes: string;
  setNotes: (notes: string) => void;
  schedulingPreference: GuideAvailabilitySubmissionSchedulingPreference;
  setSchedulingPreference: (
    schedulingPreference: GuideAvailabilitySubmissionSchedulingPreference
  ) => void;
  interviewers: InterviewerForSelectionFragment[];
  setInterviewers: React.Dispatch<
    React.SetStateAction<InterviewerForSelectionFragment[]>
  >;
  interviewerCalendarEvents: CalendarEventInput[];
  setInterviewerCalendarEvents: React.Dispatch<
    React.SetStateAction<CalendarEventInput[]>
  >;
  interviewerCalendarColors: CalendarColorsConfig;
  submitButtonValidation: SubmitButtonValidation;
  setSubmitButtonValidation: (validation: SubmitButtonValidation) => void;
};

export type CreateOrUpdateAvailabilityData = Pick<
  CreateOrUpdateAvailabilityState,
  "selections" | "notes"
> & {
  schedulingPreference?: GuideAvailabilitySubmissionSchedulingPreference;
};

export type UseCreateOrUpdateAvailabilityStateProps = {
  defaultSelections?: Event[];
  defaultNotes?: string | null;
  defaultSchedulingPreference?: GuideAvailabilitySubmissionSchedulingPreference;
  defaultInterviewers?: InterviewerForSelectionFragment[];
};

export function useCreateOrUpdateAvailabilityState({
  defaultSelections = [],
  defaultNotes,
  defaultSchedulingPreference,
  defaultInterviewers = [],
}: UseCreateOrUpdateAvailabilityStateProps): CreateOrUpdateAvailabilityState {
  const [notes, setNotes] = useState<string>(defaultNotes ?? "");
  const [schedulingPreference, setSchedulingPreference] =
    useState<GuideAvailabilitySubmissionSchedulingPreference>(
      defaultSchedulingPreference ??
        GuideAvailabilitySubmissionSchedulingPreference.FLEXIBLE
    );
  const [selections, setSelectionsRaw] = useState<Event[]>(defaultSelections);
  const setSelections = useCallback(
    (newEvents: Event[] | ((current: Event[]) => Event[])) => {
      setSelectionsRaw((current) => {
        return combineEvents(
          (typeof newEvents === "function"
            ? newEvents(current)
            : newEvents
          ).map(mapEventToCreatedEvent)
        ).map(mapCreatedEventToEvent);
      });
    },
    []
  );
  const [interviewers, setInterviewers] =
    useState<InterviewerForSelectionFragment[]>(defaultInterviewers);
  const [interviewerCalendarEvents, setInterviewerCalendarEvents] = useState<
    CalendarEventInput[]
  >([]);
  const [submitButtonValidation, setSubmitButtonValidation] =
    useState<SubmitButtonValidation>({
      isValid: true,
    });

  // Issue with modal manager -- selections is empty array on initial mount even when the props is passed because of how it manages props in
  // state
  const [hasInitializedWithDefaults, setHasInitializedWithDefaults] =
    useState(false);

  useEffect(() => {
    if (!hasInitializedWithDefaults) {
      if (defaultSelections.length) {
        setSelections(defaultSelections);
        setHasInitializedWithDefaults(true);
      }

      if (defaultNotes) {
        setNotes(defaultNotes);
        setHasInitializedWithDefaults(true);
      }

      if (defaultSchedulingPreference) {
        setSchedulingPreference(defaultSchedulingPreference);
        setHasInitializedWithDefaults(true);
      }

      if (defaultInterviewers.length) {
        setInterviewers(defaultInterviewers);
        setHasInitializedWithDefaults(true);
      }
    }
  }, [
    defaultSelections,
    selections,
    setSelections,
    defaultNotes,
    notes,
    hasInitializedWithDefaults,
    defaultSchedulingPreference,
    defaultInterviewers,
  ]);

  const filteredInterviewerEvents = useMemo(() => {
    const calendarIds = interviewers.map((i) => i.id);

    return interviewerCalendarEvents.filter((event) =>
      calendarIds.includes(event.calendarId)
    );
  }, [interviewerCalendarEvents, interviewers]);

  // use a consistent list of interviewers for calendar colors so colors don't change
  const [calendarIdsForCalendarColors, setCalendarIdsForCalendarColors] =
    useState<string[]>([]);

  useEffect(() => {
    setCalendarIdsForCalendarColors((prev) => [
      ...prev,
      ...uniq(interviewers.map((i) => i.id)).filter((id) => !prev.includes(id)),
    ]);
  }, [interviewers]);

  const interviewerCalendarColors = useMemo(() => {
    return calendarIdsForCalendarColors.reduce((acc, id, idx) => {
      acc[id] = {
        color: getCalendarColor(idx),
        variant: "faded",
      };

      return acc;
    }, {} as Record<string, ColorConfig>);
  }, [calendarIdsForCalendarColors]);

  const wrappedSubmitButtonValidation = useMemo((): SubmitButtonValidation => {
    const hasSelections = selections.length > 0;
    const hasFutureSelections = selections.some(
      (e) => DateTime.fromISO(e.endTime) > DateTime.now()
    );
    const hasValidSelections = hasSelections && hasFutureSelections;

    if (hasValidSelections || notes) {
      return submitButtonValidation;
    }
    if (!hasSelections) {
      return {
        isValid: false,
        validationMessage: "Please select at least one time slot",
      };
    }

    if (!hasFutureSelections) {
      return {
        isValid: false,
        validationMessage: "Please select at least one future time",
      };
    }

    return submitButtonValidation;
  }, [selections, notes, submitButtonValidation]);

  const selectionsHaveChanged = useMemo(() => {
    const currSelections = [...(selections ?? [])].map((s) => ({
      id: s.id,
      startTime: DateTime.fromISO(s.startTime).toMillis(),
      endTime: DateTime.fromISO(s.endTime).toMillis(),
    }));
    const sortedSelections = currSelections.sort(
      (a, b) => a.startTime - b.startTime
    );
    const prevSelections = [...(defaultSelections ?? [])].map((s) => ({
      id: s.id,
      startTime: DateTime.fromISO(s.startTime).toMillis(),
      endTime: DateTime.fromISO(s.endTime).toMillis(),
    }));
    const sortedPrevSelections = prevSelections.sort(
      (a, b) => a.startTime - b.startTime
    );

    return !isEqual(sortedSelections, sortedPrevSelections);
  }, [selections, defaultSelections]);

  useSyncDialogLeaveConfirmation({
    showConfirmation: selectionsHaveChanged,
  });

  return useMemo(
    () => ({
      selections,
      setSelections,
      notes,
      setNotes,
      schedulingPreference,
      setSchedulingPreference,
      interviewers,
      setInterviewers,
      interviewerCalendarColors,
      interviewerCalendarEvents: filteredInterviewerEvents,
      setInterviewerCalendarEvents,
      submitButtonValidation: wrappedSubmitButtonValidation,
      setSubmitButtonValidation,
    }),
    [
      selections,
      setSelections,
      notes,
      schedulingPreference,
      interviewers,
      interviewerCalendarColors,
      filteredInterviewerEvents,
      wrappedSubmitButtonValidation,
    ]
  );
}

const CUSTOM_CALENDAR_IDS = {
  SELECTIONS: "selections",
  SUGGESTIONS: "suggestions",
};

export type CreateOrUpdateAvailabilityViewProps = {
  header?: HeaderProps;
  footer?: FooterProps;
  className?: string;
  leftSidePanel?: AvailabilityLeftSidePanelProps;
  state: CreateOrUpdateAvailabilityState;
  defaultEventTitle?: string;
  suggestions?: Event[];
  taskRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];
};

export function CreateOrUpdateAvailabilityView(
  props: CreateOrUpdateAvailabilityViewProps
) {
  const {
    header,
    footer,
    leftSidePanel,
    className,
    state,
    defaultEventTitle,
    suggestions,
    taskRequirements,
  } = props;
  const {
    selections,
    setSelections,
    interviewerCalendarColors,
    interviewerCalendarEvents,
  } = state;

  const onAddEvent = useCallback(
    (newSelection: CreatedEvent) => {
      setSelections([...selections, mapCreatedEventToEvent(newSelection)]);
    },
    [selections, setSelections]
  );

  const onEditEvent = useCallback(
    (updatedEvent: Event) => {
      setSelections(
        selections.map((selection) =>
          selection.id === updatedEvent.id ? updatedEvent : selection
        )
      );
    },
    [selections, setSelections]
  );

  const onRemoveEvent = useCallback(
    (removedEventId: string) => {
      setSelections(
        selections.filter((selection) => selection.id !== removedEventId)
      );
    },
    [selections, setSelections]
  );

  const calendarEvents = useMemo(() => {
    const selectionEvents = selections.map((selection): CalendarEventInput => {
      return {
        id: selection.id,
        title: selection.title,
        start: {
          dateTime: selection.startTime,
        },
        end: {
          dateTime: selection.endTime,
        },
        calendarId: CUSTOM_CALENDAR_IDS.SELECTIONS,
        onEdit: (updatedEvent) => {
          onEditEvent({
            ...selection,
            startTime: updatedEvent.startTime.toISO(),
            endTime: updatedEvent.endTime.toISO(),
          });
        },
        onRemove: () => {
          onRemoveEvent(selection.id);
        },
      };
    });
    const suggestionsEvents = suggestions
      ? suggestions.map((suggestion): CalendarEventInput => {
          return {
            id: suggestion.id,
            title: suggestion.title,
            start: {
              dateTime: suggestion.startTime,
            },
            end: {
              dateTime: suggestion.endTime,
            },
            calendarId: CUSTOM_CALENDAR_IDS.SUGGESTIONS,
            isBackgroundEvent: true,
          };
        })
      : [];

    return [
      ...selectionEvents,
      ...suggestionsEvents,
      ...interviewerCalendarEvents,
    ];
  }, [
    interviewerCalendarEvents,
    onEditEvent,
    onRemoveEvent,
    selections,
    suggestions,
  ]);

  const initialScrollValue = useMemo(() => {
    if (selections.length) {
      return DateTime.fromISO(selections[0].startTime)
        .startOf("day")
        .set({ hour: 7 });
    }

    if (suggestions?.length) {
      return DateTime.fromISO(suggestions[0].startTime)
        .startOf("day")
        .set({ hour: 7 });
    }

    return DateTime.local().startOf("day").set({ hour: 7 });
  }, [suggestions, selections]);

  return (
    <View
      className={className}
      footer={footer}
      header={header}
      content={{
        className: "!p-0",
      }}
    >
      <div className="w-full h-full flex gap-5">
        {leftSidePanel && <AvailabilityLeftSidePanel {...leftSidePanel} />}
        <Calendar
          className="flex flex-col w-full"
          events={calendarEvents}
          initialScrollValue={initialScrollValue}
          calendarColors={{
            ...interviewerCalendarColors,
            [CUSTOM_CALENDAR_IDS.SELECTIONS]: {
              color: "primary",
            },
            [CUSTOM_CALENDAR_IDS.SUGGESTIONS]: {
              color: "primary",
              variant: "faded",
            },
          }}
          plugins={[
            <AllDayEventsViewPlugin key="all-day-events-view-plugin" />,
            <AddEventPlugin
              key="add-event-plugin"
              defaultDuration={60}
              defaultTitle={defaultEventTitle}
              onAddEvent={onAddEvent}
            />,
            <AvailabilityDayHeaderPlugin
              key="day-header-plugin"
              suggestions={suggestions}
              state={state}
              taskRequirements={taskRequirements}
            />,
            <FetchInterviewerCalendarEventsPlugin
              key="fetch-interviewer-calendar-events-plugin"
              state={state}
            />,
          ]}
          stateProps={{
            minInterval: 15,
          }}
        />
      </div>
    </View>
  );
}
