import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasBookClosed,
  atlasCalendarDay,
  atlasCalendarMonth,
  atlasCalendarOverall,
  atlasCalendarWeek,
  atlasRingPerson,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import {
  InterviewLoad,
  LoadInterval,
  LoadLimits,
} from "client/components/interviewer-slots/load/utils/types";
import clsx from "clsx";

function getTooltipColor(load: number, loadLimit: number, ignored: boolean) {
  if (ignored) {
    return "text-disabled";
  }

  if (!loadLimit) {
    return "text-subtle";
  }

  if (load === loadLimit) {
    return "text-yellow-500";
  }

  if (load > loadLimit) {
    return "text-red-500";
  }

  return "text-subtle";
}

const intervalToDisplayInterval = {
  overall: "overall",
  month: "monthly",
  week: "weekly",
  day: "daily",
};

const icons = {
  day: atlasCalendarDay,
  week: atlasCalendarWeek,
  month: atlasCalendarMonth,
  overall: atlasCalendarOverall,
};

type GetTooltipContentParams = {
  loadLimit: number | null;
  interval: keyof typeof intervalToDisplayInterval;
  isPoolLoad: boolean;
  ignored: boolean;
};

function getTooltipContent({
  loadLimit,
  interval,
  isPoolLoad,
  ignored,
}: GetTooltipContentParams) {
  if (ignored) {
    return "Account load limits are ignored for this pool";
  }

  const displayInterval = intervalToDisplayInterval[interval];
  const capitalizedDisplayInterval =
    displayInterval.charAt(0).toUpperCase() + displayInterval.slice(1);

  const prefix = `${capitalizedDisplayInterval}${
    isPoolLoad ? " pool" : ""
  } interviews scheduled`;
  const suffix = loadLimit
    ? ` / Max ${displayInterval}${isPoolLoad ? " pool" : ""} load`
    : "";

  return `${prefix}${suffix}`;
}

function LoadIcon({
  interval,
  load,
  loadLimit,
  ignored,
  isPoolLoad,
  size = "medium",
}: {
  interval: LoadInterval;
  load: number | null;
  loadLimit: number | null;
  ignored: boolean;
  isPoolLoad?: boolean;
  size: "small" | "medium";
}) {
  if (loadLimit === null || load === null) {
    return null;
  }

  return (
    <Tooltip
      content={getTooltipContent({
        loadLimit,
        interval,
        isPoolLoad: !!isPoolLoad,
        ignored: !!ignored,
      })}
    >
      <div
        className={clsx(
          "gap-1 flex items-center",
          getTooltipColor(load, loadLimit, !!ignored)
        )}
      >
        <Icon
          content={icons[interval]}
          className={clsx({
            "w-4 h-4": size === "small",
            "w-5 h-5": size === "medium",
          })}
        />
        <span>
          {load}
          {loadLimit ? `/${loadLimit}` : ""}
        </span>
      </div>
    </Tooltip>
  );
}

function LoadHeader({
  ignored,
  isPoolLoad,
}: {
  ignored?: boolean;
  isPoolLoad: boolean;
}) {
  const headerText = `${isPoolLoad ? "Pool" : "Account"} load limits${
    ignored ? " (Ignored)" : ""
  }`;

  return (
    <div
      className={clsx("text-body-sm-heavy text-[11px] uppercase", {
        "text-subtle": !ignored,
        "text-disabled": ignored,
      })}
    >
      {headerText}
    </div>
  );
}

type UserMembershipLoadDisplayProps = {
  loadLimits: LoadLimits;
  interviewLoad: InterviewLoad;
  showDayCount: boolean;
  showIcon?: boolean;
  showHeader?: boolean;
  ignored?: boolean;
  isPoolLoad?: boolean;
  size: "small" | "medium";
};

export function UserMembershipLoadDisplay({
  loadLimits,
  interviewLoad,
  showDayCount,
  showIcon = false,
  showHeader = false,
  ignored = false,
  isPoolLoad = false,
  size = "medium",
}: UserMembershipLoadDisplayProps) {
  const icon = isPoolLoad ? atlasBookClosed : atlasRingPerson;
  return (
    <>
      {showHeader && <LoadHeader ignored={ignored} isPoolLoad={isPoolLoad} />}
      <div
        className={clsx("flex gap-2 text-body-sm-heavy cursor-default", {
          "text-disabled": ignored,
          "text-body-xs": size === "small",
          "text-body-sm": size === "medium",
        })}
      >
        {showIcon && (
          <Icon
            content={icon}
            className={clsx({
              "text-subtle": !ignored,
              "text-disabled": ignored,
            })}
          />
        )}
        <LoadIcon
          interval="overall"
          load={interviewLoad.overall}
          loadLimit={loadLimits.overall}
          ignored={ignored}
          isPoolLoad={isPoolLoad}
          size={size}
        />
        <LoadIcon
          interval="month"
          load={interviewLoad.month}
          loadLimit={loadLimits.month}
          ignored={ignored}
          isPoolLoad={isPoolLoad}
          size={size}
        />
        <LoadIcon
          interval="week"
          load={interviewLoad.week}
          loadLimit={loadLimits.week}
          ignored={ignored}
          isPoolLoad={isPoolLoad}
          size={size}
        />
        {showDayCount && (
          // Days are a special use case.
          <LoadIcon
            interval="day"
            load={interviewLoad.day}
            loadLimit={loadLimits.day}
            ignored={ignored}
            isPoolLoad={isPoolLoad}
            size={size}
          />
        )}
      </div>
    </>
  );
}
