import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { fileModule } from "@resource/atlas/content-editor/file";

import {
  IMAGE_MIME_TYPES,
  useReplaceFileData,
  useReplaceImageData,
} from "./utils";

export const allowedFileTypes = [
  ...IMAGE_MIME_TYPES,
  // Generic binary file due to bug causing misclassification with uploadcare
  // https://uploadcare.com/community/t/incorrect-content-type-header-returned-by-uploadcare-converted-file/1884
  "application/octet-stream",
  // PDF
  "application/pdf",
  // Word
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // Excel
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // Powerpoint
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // CSV
  "text/csv",
  // SVG
  "image/svg+xml",
  // ZIP
  "application/gzip",
  "application/zip",
  "application/x-7z-compressed",
];

export function useFileModule() {
  const { onReplaceFile, fileChooserInput: fileFileChooserInput } =
    useReplaceFileData(allowedFileTypes);
  const { onReplaceImage, fileChooserInput: imageFileChooserInput } =
    useReplaceImageData();

  return withConfig(fileModule, {
    onReplaceFile,
    onReplaceImage,
    tmpRender: (
      <>
        {fileFileChooserInput}
        {imageFileChooserInput}
      </>
    ),
  });
}
