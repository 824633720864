import { Hovercard, HovercardAnchor } from "ariakit";
import clsx from "clsx";
import { useMemo } from "react";

import {
  createComponentUtils,
  createRootContext,
  usePExtendedState,
} from "../__utils/atlas";
import {
  createDefaultProps,
  mergePropsIntoSingleChild,
} from "../__utils/react";
import { FloatingCard } from "../floating-card/FloatingCard";
import type {
  AtlasHovercardAnchorComponent,
  AtlasHovercardContentComponent,
  AtlasHovercardContentProps,
  AtlasHovercardRootComponent,
  AtlasHovercardState,
} from "./types";
import { useHovercardState } from "./use-hovercard-state";

// config
// ------

const COMPONENT_NAME = "Hovercard";

export const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// context
// -------

export type MenuRootContext = { hovercard: AtlasHovercardState };

export const { RootContext, RootProvider, useRootContext } =
  createRootContext<MenuRootContext>(COMPONENT_NAME);

// root
// ----

/** The root of the hovercard component. Expects the trigger and the content.
 *
 * @example
 * <Hovercard.Root>
 *   <Hovercard.Anchor>
 *     <Button>Show hovercard</Button>
 *   </Hovercard.Anchor>
 *   <Hovercard.Content>
 *     <Hovercard.Heading>
 *       Heading
 *     </Hovercard.Heading>
 *     <Hovercard.Description>
 *       Description
 *     </Hovercard.Description>
 *     <div>
 *       Content
 *     </div>
 *   </Hovercard.Content>
 * </Hovercard.Root>
 */
export const Root = createComponent<AtlasHovercardRootComponent>(
  ({ children, state, ...stateProps }) => {
    const hovercard = useHovercardState(stateProps);

    const contextValue = useMemo(
      () => ({ hovercard: state ?? hovercard }),
      [hovercard, state]
    );

    return <RootProvider value={contextValue}>{children}</RootProvider>;
  },
  { forwardRef: false, treeName: "Root" }
);

// anchor
// ------

/** The anchor of a hovercard. Must be a child of `<Hovercard.Root />`. Expects the
 * element to be used as anchor, passed as a single child.
 *
 * @example
 * <Hovercard.Anchor>
 *   <a href="https://twitter.com/daniguardio_la">@daniguardio_la</a>
 * </Hovercard.Anchor>
 */
export const Anchor = createComponent<AtlasHovercardAnchorComponent>(
  ({ children, ...p0 }) => {
    const [hovercard, ...props] = usePExtendedState(
      p0,
      useRootContext().hovercard
    );

    return (
      <HovercardAnchor {...props} state={hovercard}>
        {(triggerProps) => mergePropsIntoSingleChild(triggerProps, children)}
      </HovercardAnchor>
    );
  },
  { treeName: "Anchor" }
);

// content
// -------

const DEFAULT_CONTENT_PROPS = createDefaultProps<AtlasHovercardContentProps>()({
  hasPadding: true,
} as const);

/** The content of a hovercard. Must be a child of `<Hovercard.Root />`.
 *
 * @example
 * <Hovercard.Content>My content</Hovercard.Content>
 */
export const Content = createComponent<AtlasHovercardContentComponent>(
  ({
    hasPadding = DEFAULT_CONTENT_PROPS.hasPadding,
    children,
    isLoading,
    ...p0
  }) => {
    const [hovercard, props] = usePExtendedState(
      p0,
      useRootContext().hovercard
    );
    return hovercard.mounted ? (
      <Hovercard
        state={hovercard}
        {...props}
        className={clsx(el`content`, props.className)}
      >
        {(hovercardProps) => (
          <FloatingCard
            data-placement={hovercard.currentPlacement}
            isLoading={isLoading}
            bodyProps={{
              className: clsx(el`content-body`, { isLoading, hasPadding }),
            }}
            {...hovercardProps}
          >
            {children}
          </FloatingCard>
        )}
      </Hovercard>
    ) : null;
  },
  { treeName: "Content" }
);

// heading and description
// -----------------------

export {
  HovercardDescription as Description,
  /** The heading of a hovercard. Must be a child of `<Hovercard.Content />`.
   *
   * @example
   * <Hovercard.Heading>My hovercard heading</Hovercard.Heading>
   */
  HovercardHeading as Heading,
} from "ariakit";
