/**
 * A specific wrapper around TimezoneSelect component tailored around selecting a candidate's timezone.
 */
import clsx from "clsx";
import {
  TimezoneSelectorV2,
  TimezoneSelectRootProps,
  TimezoneSelectTriggerProps,
} from "components/TimezoneSelection/TimezoneSelectorV2";
import { gql } from "generated/graphql-codegen";
import { CandidateTimezoneSelectCandidateFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";
import { displayTimezone } from "shared/utils/timezones";
import useMutation from "utils/useMutation";

gql(`fragment CandidateTimezoneSelectCandidate on Candidate {
  id
  timezone
}`);

const UPDATE_TIMEZONE = gql(`
  mutation UpdateCandidateTimezoneForSelect($input: SetTimezoneOfCandidateInput!) {
    setTimezoneOfCandidate(input: $input) {
      success
      message
      code
      candidate {
        ...CandidateTimezoneSelectCandidate
      }
    }
  }
`);

type CandidateTimezoneSelectProps = {
  candidate: CandidateTimezoneSelectCandidateFragment;
  labelType?: "abbreviation" | "abbreviation-with-offset" | "default";
  triggerProps?: TimezoneSelectTriggerProps;
  rootProps?: TimezoneSelectRootProps;
};
export function CandidateTimezoneSelect({
  candidate,
  labelType,
  triggerProps,
  rootProps,
}: CandidateTimezoneSelectProps) {
  const [timezone, setTimezone] = useState(candidate.timezone);
  const [updateCandidateTimezone] = useMutation(UPDATE_TIMEZONE);

  const handleClick = useCallback(
    (tz: string) => {
      setTimezone(tz);
      updateCandidateTimezone({
        variables: {
          input: {
            id: candidate.id,
            timezone: tz,
          },
        },
      });
    },
    [candidate.id, updateCandidateTimezone]
  );

  return (
    <div className="text-subtle">
      <TimezoneSelectorV2
        value={timezone ?? undefined}
        onClick={handleClick}
        rootProps={{
          ...rootProps,
        }}
        triggerProps={{
          isGhost: false,
          label: (
            <span
              className={clsx({
                "text-body-sm": triggerProps?.size === "xs",
              })}
            >
              {timezone
                ? displayTimezone(timezone, labelType, true) ?? ""
                : "Select timezone"}
            </span>
          ),
          ...triggerProps,
          className: clsx(triggerProps?.className, {
            "text-subtle": !timezone,
          }),
        }}
        contentProps={{
          className: "w-[320px] text-dark",
          header: (
            <div className="min-w-full w-0 px-3 pb-4 pt-2 space-y-1">
              <p className="text-body-md-heavy">Set candidate timezone</p>
              <p className="text-body-md text-subtle">
                This timezone will be used during scheduling and formatting
                dates and times in emails sent to the candidate.
              </p>
            </div>
          ),
        }}
      />
    </div>
  );
}
