import { CalendarEventsViewElement } from "client/components/calendar-v2/components/portals/CalendarEventsViewElement";
import { useCalendarOptions } from "client/components/calendar-v2/hooks/options";
import { useCalendarSettings } from "client/components/calendar-v2/hooks/settings";
import { useColumns } from "client/components/calendar-v2/hooks/useColumns";

import { UnavailableEvent } from "./components/UnavailableEvent";
import { useAvailability } from "./hooks/useAvailability";
import {
  AvailabilityInterviewerMap,
  UnavailableCalendar,
  UnavailableEvent as UnavailableEventType,
} from "./utils/types";

export function AvailabilityPlugin() {
  const { unavailableEvents, interviewersMap } = useAvailability();
  const { columnType } = useColumns();
  const { dayViewType } = useCalendarSettings();
  const { dayViewCalendarIds, calendarsGrouping } = useCalendarOptions();

  return (
    <CalendarEventsViewElement>
      {columnType === "day" ? (
        <DayGroupedUnavailableEvents
          unavailableEvents={unavailableEvents}
          interviewersMap={interviewersMap}
        />
      ) : (
        <CalendarGroupedUnavailableEvents
          unavailableEvents={unavailableEvents}
          interviewersMap={interviewersMap}
          dayViewType={dayViewType}
          dayViewCalendarIds={dayViewCalendarIds}
          calendarsGrouping={calendarsGrouping}
        />
      )}
    </CalendarEventsViewElement>
  );
}

/**
 * Retrieves unavailable calendars based on the provided calendar IDs and interviewers map.
 * It will map IDs to the correct unavailable calendar type, and filter out calendars that aren't in the map.
 */
function mapAndFilterUnavailableCalendars(
  calendarIds: string[],
  interviewersMap: AvailabilityInterviewerMap
): UnavailableCalendar[] {
  return calendarIds.reduce<UnavailableCalendar[]>((acc, calendarId) => {
    if (calendarId === "candidate") {
      acc.push({ isCandidate: true });
    } else if (interviewersMap[calendarId]) {
      acc.push(interviewersMap[calendarId]);
    }
    return acc;
  }, []);
}

/** Get candidate/interviewer available to show in the unavailable event */
function getAvailabilityFromCalendarsForUnavailableEvent(
  calendarIds: string[]
) {
  const candidateIsUnavailable = calendarIds.includes("candidate");
  /** since we are looking at an unavailable item, if the candidate is available we know it means an interviewer is unavailable */
  const interviewerIsUnavailable = !candidateIsUnavailable;

  return {
    candidateIsUnavailable,
    interviewerIsUnavailable,
  };
}

/** Render an unavailable event in the right spot with a list of all pertinent calendars */
function DayGroupedUnavailableEvents({
  unavailableEvents,
  interviewersMap,
}: {
  unavailableEvents: UnavailableEventType[];
  interviewersMap: AvailabilityInterviewerMap;
}) {
  return (
    <>
      {unavailableEvents.map((unavailableEvent) => {
        const unavailableCalendars = mapAndFilterUnavailableCalendars(
          unavailableEvent.calendars,
          interviewersMap
        );
        const { candidateIsUnavailable, interviewerIsUnavailable } =
          getAvailabilityFromCalendarsForUnavailableEvent(
            unavailableEvent.calendars
          );

        return (
          <UnavailableEvent
            key={`${unavailableEvent.startTime.toISO()}-${unavailableEvent.calendars.join(
              ","
            )}`}
            unavailableEvent={unavailableEvent}
            unavailableCalendars={unavailableCalendars}
            candidateIsUnavailable={candidateIsUnavailable}
            interviewerIsUnavailable={interviewerIsUnavailable}
          />
        );
      })}
    </>
  );
}

function CalendarGroupedUnavailableEvents({
  unavailableEvents,
  interviewersMap,
  dayViewType,
  dayViewCalendarIds,
  calendarsGrouping,
}: {
  unavailableEvents: UnavailableEventType[];
  interviewersMap: AvailabilityInterviewerMap;
  dayViewType: string;
  dayViewCalendarIds: string[];
  calendarsGrouping: { calendarIds: string[] }[] | null;
}) {
  if (dayViewType === "calendar_and_grouping" && calendarsGrouping) {
    return (
      <>
        {calendarsGrouping.map((grouping, calendarGroupIdx) =>
          grouping.calendarIds.map((calendarId) => (
            <CalendarUnavailableEvents
              key={`${calendarId}-${calendarGroupIdx}`}
              calendarId={calendarId}
              calendarGroupIdx={calendarGroupIdx}
              unavailableEvents={unavailableEvents}
              interviewersMap={interviewersMap}
            />
          ))
        )}
      </>
    );
  }

  return (
    <>
      {dayViewCalendarIds.map((calendarId) => (
        <CalendarUnavailableEvents
          key={calendarId}
          calendarId={calendarId}
          unavailableEvents={unavailableEvents}
          interviewersMap={interviewersMap}
        />
      ))}
    </>
  );
}

/** Render an unavailable event on all calendars when calendars are columns */
function CalendarUnavailableEvents({
  calendarId,
  calendarGroupIdx,
  unavailableEvents,
  interviewersMap,
}: {
  calendarId: string;
  calendarGroupIdx?: number;
  unavailableEvents: UnavailableEventType[];
  interviewersMap: AvailabilityInterviewerMap;
}) {
  const filteredEvents = unavailableEvents.filter(
    (event) =>
      event.calendars.includes(calendarId) ||
      event.calendars.includes("candidate")
  );

  return (
    <>
      {filteredEvents.map((unavailableEvent) => {
        /** check current calendarId and show candidate availability on every calendar */
        const unavailableCalendarsForEvent = [calendarId, "candidate"];
        const unavailableCalendars = mapAndFilterUnavailableCalendars(
          unavailableCalendarsForEvent,
          interviewersMap
        );
        const { candidateIsUnavailable, interviewerIsUnavailable } =
          getAvailabilityFromCalendarsForUnavailableEvent(
            unavailableEvent.calendars
          );

        if (unavailableCalendars.length === 0) {
          return null; // Don't render if there's no valid calendar
        }

        return (
          <UnavailableEvent
            key={`${unavailableEvent.startTime.toISO()}-${calendarId}-${unavailableEvent.calendars.includes(
              "candidate"
            )}`}
            unavailableEvent={unavailableEvent}
            unavailableCalendars={unavailableCalendars}
            candidateIsUnavailable={candidateIsUnavailable}
            interviewerIsUnavailable={interviewerIsUnavailable}
            calendarId={calendarId}
            calendarGroupIdx={calendarGroupIdx}
          />
        );
      })}
    </>
  );
}
