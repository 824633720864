import {
  mapCollaborativeCodingSlotToInput,
  mapInterviewRequirementToCollaborativeCodingSlot,
  mapScheduledInterviewToCollaborativeCodingSlot,
} from "client/components/collaborative-coding/utils/mapping";
import {
  mapConferenceRoomSettingsToConferenceRoomSlot,
  mapConferenceRoomSlotToInput,
  mapScheduledInterviewToConferenceRoomSlot,
} from "client/components/conference-rooms/utils/mapping";
import { isStagedConferenceRoomSlot } from "client/components/conference-rooms/utils/types";
import { getIsUsingGroupSettings } from "client/components/conferencing/utils/helpers";
import {
  mapConferencingSlotToInput,
  mapInterviewRequirementToConferencingSlot,
  mapScheduledInterviewToConferencingSlot,
} from "client/components/conferencing/utils/mapping";
import {
  mapInterviewerRequirementFragmentToSlotSettings,
  mapInterviewerSlotFragmentsToInterviewerSlots,
  mapInterviewerSlotsToInput,
  mapInterviewersToSlots,
} from "client/components/interviewer-slots/utils/mapping";
import { isStagedInterviewerSlot } from "client/components/interviewer-slots/utils/types";
import {
  mapReviewFormDataToInterviewerConfirmationCreateInput,
  mapReviewFormDataToInterviewerConfirmationUpdateInput,
} from "client/components/scheduling-review/utils/mapping";
import { SingleInterviewReviewFormData } from "client/components/scheduling-review/utils/types";
import { filterInterviewerRequirements } from "client/utils/interviewRequirements";
import {
  CreateScheduledInterviewInputV2,
  DeleteScheduledInterviewInputV2,
  InterviewRequirementForCreateScheduledInterviewFragment,
  ScheduledInterviewForEditScheduledInterviewFragment,
  UpdateScheduledInterviewInputV2,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import {
  CancellationReason,
  UpdateReason,
} from "shared/reporting/scheduled-interviews/types";

import {
  mapCancellationReasonToInput,
  mapUpdateReasonToInput,
} from "../../ReportingReasons/utils/mapping";
import { StagedScheduledInterview, UpsertScheduledInterview } from "./types";

export function mapScheduledInterviewToEditingInterview({
  scheduledInterview,
}: {
  scheduledInterview: ScheduledInterviewForEditScheduledInterviewFragment;
}): UpsertScheduledInterview {
  return {
    id: scheduledInterview.id,
    interviewRequirement: scheduledInterview.interviewRequirementId
      ? {
          id: scheduledInterview.interviewRequirementId,
        }
      : undefined,
    title: scheduledInterview.title,
    startTime: scheduledInterview.startTime,
    endTime: scheduledInterview.endTime,
    duration: DateTime.fromISO(scheduledInterview.endTime).diff(
      DateTime.fromISO(scheduledInterview.startTime),
      "minutes"
    ).minutes,
    interviewerSlots: scheduledInterview.isV2
      ? // If V2, then we can rely on the interviewerSlots field
        mapInterviewerSlotFragmentsToInterviewerSlots(
          scheduledInterview.interviewerSlots
        )
      : // If V1, transform interviewers to fake slots
        mapInterviewersToSlots(scheduledInterview.interviewers),
    conferencingSlot: mapScheduledInterviewToConferencingSlot({
      scheduledInterview,
      isUsingGroupSettings: scheduledInterview.scheduledInterviewGroup
        ? getIsUsingGroupSettings({
            scheduledInterview,
            scheduledInterviewGroup: scheduledInterview.scheduledInterviewGroup,
          })
        : false,
    }),
    collaborativeCodingSlot:
      mapScheduledInterviewToCollaborativeCodingSlot(scheduledInterview),
    conferenceRoomSlot:
      mapScheduledInterviewToConferenceRoomSlot(scheduledInterview),
    isSelfScheduled: scheduledInterview.isSelfScheduled,
    isPrivate: scheduledInterview.isPrivate,
    interview: scheduledInterview.interview
      ? {
          id: scheduledInterview.interview.id,
          title: scheduledInterview.interview.title,
        }
      : null,
    isCancelled: scheduledInterview.isCancelled,
    googleEventId: scheduledInterview.googleEventId,
    isSchedulable: true,
  };
}

export function mapInterviewRequirementToCreatingInterview(
  interviewRequirement: InterviewRequirementForCreateScheduledInterviewFragment
): UpsertScheduledInterview {
  const { interviewerRequirements } = filterInterviewerRequirements(
    interviewRequirement.interviewerRequirements
  );
  return {
    id: interviewRequirement.id,
    interview: {
      id: interviewRequirement.interview.id,
      title: interviewRequirement.interview.title,
    },
    interviewRequirement: {
      id: interviewRequirement.id,
    },
    title: interviewRequirement.interview.title,
    startTime: null,
    endTime: null,
    duration: interviewRequirement.duration,
    interviewerSlots: interviewerRequirements.map(
      mapInterviewerRequirementFragmentToSlotSettings
    ),
    isSelfScheduled: false,
    isPrivate: interviewRequirement.isPrivate,
    googleEventId: null,
    isCancelled: false,
    conferenceRoomSlot: interviewRequirement.conferenceRoomSettings
      ? mapConferenceRoomSettingsToConferenceRoomSlot(
          interviewRequirement.conferenceRoomSettings
        )
      : null,
    conferencingSlot:
      mapInterviewRequirementToConferencingSlot(interviewRequirement),
    collaborativeCodingSlot:
      mapInterviewRequirementToCollaborativeCodingSlot(interviewRequirement),
  };
}

function baseMapStagedInterviewToUpsertInput({
  stagedScheduledInterview,
  scheduledInterviewGroupSettings,
}: {
  stagedScheduledInterview: StagedScheduledInterview;
  scheduledInterviewGroupSettings?: {
    reuseVideoConferencingLink: boolean;
  };
}): Pick<
  CreateScheduledInterviewInputV2 | UpdateScheduledInterviewInputV2,
  | "title"
  | "isPrivate"
  | "conferenceRoomSlot"
  | "interviewerSlots"
  | "conferencingSlot"
  | "collaborativeCodingSlot"
> {
  const stagedInterviewerSlots =
    stagedScheduledInterview.interviewerSlots.filter(isStagedInterviewerSlot);
  return {
    title: stagedScheduledInterview.title,
    isPrivate: stagedScheduledInterview.isPrivate,
    interviewerSlots: mapInterviewerSlotsToInput(stagedInterviewerSlots),
    conferenceRoomSlot:
      stagedScheduledInterview.conferenceRoomSlot &&
      isStagedConferenceRoomSlot(stagedScheduledInterview.conferenceRoomSlot)
        ? mapConferenceRoomSlotToInput(
            stagedScheduledInterview.conferenceRoomSlot
          )
        : // Passing null explicitly removes the conference room
          null,
    conferencingSlot: stagedScheduledInterview.conferencingSlot
      ? mapConferencingSlotToInput({
          conferencingSlot: stagedScheduledInterview.conferencingSlot,
          groupHasReusableLink:
            scheduledInterviewGroupSettings?.reuseVideoConferencingLink,
        })
      : // Passing null explicitly removes the conferencing slot
        null,
    collaborativeCodingSlot: stagedScheduledInterview.collaborativeCodingSlot
      ? mapCollaborativeCodingSlotToInput(
          stagedScheduledInterview.collaborativeCodingSlot
        )
      : // Passing null explicitly removes the collaborative coding slot
        null,
  };
}

function getStagedInterviewerSlots(
  stagedScheduledInterview: StagedScheduledInterview
) {
  return mapInterviewerSlotsToInput(
    stagedScheduledInterview.interviewerSlots.filter(isStagedInterviewerSlot)
  );
}

export function mapStagedInterviewToCreateInput({
  stagedScheduledInterview,
  reviewFormData,
  scheduledInterviewGroupSettings,
}: {
  stagedScheduledInterview: StagedScheduledInterview;
  reviewFormData: SingleInterviewReviewFormData;
  scheduledInterviewGroupSettings?: {
    reuseVideoConferencingLink: boolean;
  };
}): CreateScheduledInterviewInputV2 {
  return {
    ...baseMapStagedInterviewToUpsertInput({
      stagedScheduledInterview,
      scheduledInterviewGroupSettings,
    }),
    createdFromInterviewRequirementId:
      stagedScheduledInterview.interviewRequirement?.id,
    startTime: stagedScheduledInterview.startTime,
    endTime: stagedScheduledInterview.endTime,
    interviewId: stagedScheduledInterview.interview?.id,
    interviewerSlots: getStagedInterviewerSlots(stagedScheduledInterview),
    interviewerConfirmation:
      mapReviewFormDataToInterviewerConfirmationCreateInput(reviewFormData),
  };
}

export function mapStagedInterviewToUpdateInput({
  stagedScheduledInterview,
  reviewFormData,
  reportingReason,
  scheduledInterviewGroupSettings,
}: {
  stagedScheduledInterview: StagedScheduledInterview;
  reviewFormData: SingleInterviewReviewFormData;
  reportingReason?: UpdateReason;
  scheduledInterviewGroupSettings?: {
    reuseVideoConferencingLink: boolean;
  };
}): UpdateScheduledInterviewInputV2 {
  return {
    ...baseMapStagedInterviewToUpsertInput({
      stagedScheduledInterview,
      scheduledInterviewGroupSettings,
    }),
    id: stagedScheduledInterview.id,
    fieldChanges: stagedScheduledInterview.fieldChanges?.map(
      (f) => f.fieldName
    ),
    interviewerSlots: getStagedInterviewerSlots(stagedScheduledInterview),
    startTime: stagedScheduledInterview.startTime,
    endTime: stagedScheduledInterview.endTime,
    interviewerConfirmation:
      mapReviewFormDataToInterviewerConfirmationUpdateInput(reviewFormData),
    title: stagedScheduledInterview.title,
    reportingReason: reportingReason
      ? mapUpdateReasonToInput(reportingReason)
      : null,
  };
}

export function mapScheduledInterviewToCancellationInput({
  scheduledInterview,
  reviewFormData,
  reportingReason,
}: {
  scheduledInterview: UpsertScheduledInterview;
  reviewFormData: {
    sendNotifications: boolean;
    note?: string;
  };
  reportingReason?: CancellationReason;
}): DeleteScheduledInterviewInputV2 {
  return {
    scheduledInterviewId: scheduledInterview.id,
    interviewerConfirmation: {
      sendNotifications: reviewFormData.sendNotifications,
      note: reviewFormData.note,
    },
    reportingReason: reportingReason
      ? mapCancellationReasonToInput(reportingReason)
      : null,
  };
}
