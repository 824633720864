import { gql } from "generated/graphql-codegen";
import { DateTime } from "luxon";
import { useEffect } from "react";
import useQuery from "utils/useQuery";

const SCHEDULING_REQUEST_DETAILS_QUERY = gql(`
  query SchedulingRequestDetailsQuery($schedulingRequestId: ID!) {
    schedulingRequestById(id: $schedulingRequestId) {
      id
      updatedAt
      guide {
        id
        atssyncApplication {
          id
          atsUrl
          currentStage {
            id
            name
          }
        }
      }
      ...SchedulingRequestForDetailsView
    }
  }
`);

const SCHEDULING_REQUEST_UPDATE = gql(`
  subscription SchedulingPageSchedulingRequestUpdated($id: ID!) {
    schedulingRequestByIdUpdated(schedulingRequestId: $id) {
      id
      updatedAt
      ...SchedulingRequestForDetailsView
    }
  }
`);

export function useSchedulingRequestDetailsQuery({
  schedulingRequestId,
}: {
  schedulingRequestId: string;
}) {
  const { data, refetch, subscribeToMore } = useQuery(
    SCHEDULING_REQUEST_DETAILS_QUERY,
    {
      variables: {
        schedulingRequestId,
      },
      fetchPolicy: "cache-and-network",
      skip: !schedulingRequestId,
    }
  );

  useEffect(() => {
    if (schedulingRequestId) {
      subscribeToMore({
        document: SCHEDULING_REQUEST_UPDATE,
        variables: {
          id: schedulingRequestId,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          const incomingData =
            subscriptionData.data.schedulingRequestByIdUpdated.updatedAt;
          const existingData = prev.schedulingRequestById?.updatedAt;

          // Compare updatedAt times; if it's not been updated ignore the update
          if (
            existingData &&
            +DateTime.fromISO(incomingData) <= +DateTime.fromISO(existingData)
          ) {
            return prev;
          }

          // Replace or merge the incoming data as necessary
          return {
            ...prev,
            schedulingRequestByIdUpdated:
              subscriptionData.data.schedulingRequestByIdUpdated,
          };
        },
      });
    }
  }, [subscribeToMore, schedulingRequestId]);

  return { data, refetch };
}
