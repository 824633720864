import {
  Combobox as ComboboxInput,
  ComboboxGroup,
  ComboboxGroupLabel,
  ComboboxGroupLabelProps,
  ComboboxGroupProps,
  ComboboxItem,
  ComboboxItemProps,
  ComboboxList,
  ComboboxListProps,
  ComboboxPopover,
  ComboboxPopoverProps,
  ComboboxProps,
  ComboboxProvider,
  ComboboxProviderProps,
} from "./Combobox";

export const Combobox = {
  Root: ComboboxProvider,
  Provider: ComboboxProvider,
  Popover: ComboboxPopover,
  List: ComboboxList,
  Item: ComboboxItem,
  Group: ComboboxGroup,
  ItemHeader: ComboboxGroupLabel,
  Combobox: ComboboxInput,
};

export type {
  ComboboxGroupProps,
  ComboboxGroupLabelProps as ComboboxItemHeaderProps,
  ComboboxItemProps,
  ComboboxListProps,
  ComboboxPopoverProps,
  ComboboxProps,
  ComboboxProviderProps,
};
