import type { SerializedRichBlockNode } from "../../__utils/rich-blocks";

// config
// ------

export const RICH_BLOCK_NAME = "rich-quote";

export const DEFAULT_DATA: RichQuoteData = { text: "" };

export const CHARACTERS_THRESHOLD = 140;

// types
// -----

export type RichQuoteName = typeof RICH_BLOCK_NAME;

export type RichQuoteData = { text: string; attribution?: string };

export type SerializedRichQuoteNode = SerializedRichBlockNode<
  RichQuoteName,
  RichQuoteData
>;
