import { GuidePostForPreviewMessageFragment } from "generated/graphql-codegen/graphql";

import { GuidePostForPreview } from "./types";

export function mapGuidePostPreviewFragment(
  fragment: GuidePostForPreviewMessageFragment
): GuidePostForPreview {
  return {
    id: fragment.id,
    href: fragment.href,
    title: fragment.title,
    isReply: !!fragment.threadId,
    messagePreview: fragment.messagePreview,
    createdAt: new Date(fragment.createdAt),
    sender: {
      id: fragment.senderUser.id,
      name: fragment.senderUser.name,
      imageUrl: fragment.senderUser.imageUrl,
    },
  };
}
