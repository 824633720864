import { Button } from "@resource/atlas/button/Button";
import { atlasTrash } from "@resource/atlas/icons";
import { useTimezone } from "client/timezones/useTimezone";
import clsx from "clsx";
import { useMemo } from "react";
import { getFormattedEventDay } from "shared/guide-availability/utils";

import { EditableTime } from "./EditableTime";
import { Time } from "./TimeSelect";

type Props = {
  times: (
    | {
        id: string;
        start: string;
        end: string;
      }
    | {
        id: string;
        startTime: string;
        endTime: string;
      }
  )[];
  timezone?: string | null;
  listClassName?: string;
  onEdit: (updatedTime: { id: string; startTime: Time; endTime: Time }) => void;
  onRemove: (id: string) => void;
  onInvalidTimeRange: (id: string) => void;
  onValidTimeRange: (id: string) => void;
};

/** A list of selected times that are editable and grouped. Includes validation around the time range when editing  */
export function EditableSelectedTimes({
  times: passedTimes,
  timezone: passedTimezone,
  listClassName,
  onEdit,
  onRemove,
  onInvalidTimeRange,
  onValidTimeRange,
}: Props) {
  const timezone = useTimezone(passedTimezone);
  const times = useMemo(() => {
    return passedTimes.map((availability) => {
      if ("startTime" in availability) {
        return availability;
      }

      return {
        ...availability,
        startTime: availability.start,
        endTime: availability.end,
      };
    });
  }, [passedTimes]);
  const timesSorted = useMemo(
    () => times.sort((a, b) => a.startTime.localeCompare(b.startTime)),
    [times]
  );

  if (!timesSorted.length) {
    return null;
  }

  const groupedTimes = timesSorted.reduce(
    (grouped: Record<string, typeof timesSorted>, event) => {
      const eventDay = getFormattedEventDay(event, timezone);
      if (!grouped[eventDay]) {
        // eslint-disable-next-line no-param-reassign
        grouped[eventDay] = [];
      }
      grouped[eventDay].push(event);
      return grouped;
    },
    {} as Record<string, typeof timesSorted>
  );

  return (
    <>
      {Object.entries(groupedTimes).map(([day, events]) => (
        <div key={day}>
          <p className="text-body-md">{day}</p>
          <ul
            className={clsx(
              "list-disc list-inside space-y-1 text-body-md",
              listClassName
            )}
          >
            {events.map((event) => (
              <div
                key={`${event.startTime}-${event.endTime}`}
                className="flex flex-row items-center w-full space-x-1"
              >
                <div className="space-y-1 flex flex-col w-full">
                  <EditableTime
                    time={event}
                    onEdit={onEdit}
                    onInvalidTimeRange={onInvalidTimeRange}
                    onValidTimeRange={onValidTimeRange}
                    timezone={timezone}
                  />
                </div>
                {onRemove && (
                  <Button
                    isGhost
                    icon={atlasTrash}
                    onClick={() => onRemove(event.id)}
                  />
                )}
              </div>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}
