import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasChevronLeft, atlasChevronRight } from "@resource/atlas/icons";
import { Select } from "@resource/atlas/select";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { CypressData } from "client/cypress-data-keys";
import { TimezoneSelectorV2 } from "components/TimezoneSelection/TimezoneSelectorV2";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import { useCallback, useLayoutEffect, useState } from "react";

import { CalendarSettings } from "./CalendarSettings";
import {
  calendarSelectedDayAtom,
  calendarTimezoneAtom,
  calendarViewAtom,
  useCalendarSettings,
} from "./settings";
import { CalendarView } from "./types";

export function CalendarHeader() {
  const { view } = useCalendarSettings();
  const [selectedDay, setSelectedDay] = useAtom(calendarSelectedDayAtom);
  const firstDay = selectedDay.startOf(view);
  const lastDay = selectedDay.endOf(view);

  let headerTitle =
    view === "day"
      ? firstDay.toFormat("dd MMMM yyyy")
      : firstDay.toFormat("MMMM yyyy");

  if (firstDay.month !== lastDay.month) {
    headerTitle = `${firstDay.toFormat("MMM")} - ${lastDay.toFormat(
      "MMM yyyy"
    )}`;
  }

  const decrementSelectedDay = useCallback(() => {
    setSelectedDay((day) =>
      day.minus({
        weeks: view === "week" ? 1 : 0,
        days: view === "day" ? 1 : 0,
      })
    );
  }, [setSelectedDay, view]);

  const incrementSelectedDay = useCallback(() => {
    setSelectedDay((day) =>
      day.plus({ weeks: view === "week" ? 1 : 0, days: view === "day" ? 1 : 0 })
    );
  }, [setSelectedDay, view]);

  const onClickToday = useCallback(() => {
    setSelectedDay(DateTime.local().startOf("day"));
  }, [setSelectedDay]);

  const now = DateTime.local();

  return (
    <div>
      <header
        className="flex items-center justify-between bg-light-gray-500 border-b border-[#EEEDEF] p-4"
        data-cy={CypressData.calendar.header}
      >
        <div className="flex space-x-4 items-center">
          <div className="flex">
            <Button
              icon={atlasChevronLeft}
              isGhost
              onClick={decrementSelectedDay}
            />
            <Button onClick={onClickToday} isGhost>
              Today
            </Button>
            <Button
              icon={atlasChevronRight}
              isGhost
              onClick={incrementSelectedDay}
            />
          </div>
          <h1 className="text-base font-semibold leading-6 text-body-lg-heavy">
            {headerTitle}
          </h1>
        </div>
        <div className="flex space-x-4 items-center">
          <ButtonGroup>
            <HeaderTimezonePicker />
            <HeaderViewPicker />
            <CalendarSettings />
          </ButtonGroup>
        </div>
      </header>
      {now.year !== firstDay.year && (
        <AlertBanner variant="warning">
          You are currently viewing a different year. Please ensure dates are
          correct.
        </AlertBanner>
      )}
    </div>
  );
}

function HeaderTimezonePicker() {
  const [timezone, setTimezone] = useAtom(calendarTimezoneAtom);

  return (
    <TimezoneSelectorV2
      onClick={setTimezone}
      value={timezone ?? undefined}
      rootProps={{
        placement: "bottom-start",
      }}
      contentProps={{
        className: "w-[320px]",
      }}
    />
  );
}

function HeaderViewPicker() {
  const [view, setView] = useAtom(calendarViewAtom);
  const [showViewPicker, setShowViewPicker] = useState(false);

  const onResize = useCallback(() => {
    if (window.innerWidth < 600) {
      setShowViewPicker(false);
      setView("day");
    } else {
      setShowViewPicker(true);
      setView("week");
    }
  }, [setView]);

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  const items = useSelectItems((i) => {
    return [
      i.option({
        key: "day",
        value: "day",
        children: "Day",
      }),
      i.option({
        key: "week",
        value: "week",
        children: "Week",
      }),
    ];
  }, []);

  if (!showViewPicker) {
    return <></>;
  }

  return (
    <Select.Root value={view} setValue={(val) => setView(val as CalendarView)}>
      <Select.Trigger>
        <Button size="medium" isGhost isDropdown />
      </Select.Trigger>
      <Select.Content items={items} />
    </Select.Root>
  );
}
