/* eslint-disable import/prefer-default-export */
import { usePopoverState as useAriakitPopoverState } from "ariakit";

import { SHARED_POPOVER_STATE_DEFAULTS } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import type { AtlasPopoverState, AtlasPopoverStateProps } from "./types";

const DEFAULT_PROPS = createDefaultProps<AtlasPopoverStateProps>()(
  SHARED_POPOVER_STATE_DEFAULTS
);

/**
 * Creates a popover state.
 *
 * @example
 * const popover = usePopoverState();
 * return <Popover.Root state={popover}>...</Popover.Root>;
 */
export function usePopoverState({
  placement = DEFAULT_PROPS.placement,
  animated = DEFAULT_PROPS.animated,
  gutter = DEFAULT_PROPS.gutter,
  overflowPadding = DEFAULT_PROPS.overflowPadding,
  ...props
}: AtlasPopoverStateProps = {}) {
  const popover: AtlasPopoverState = useAriakitPopoverState({
    placement,
    animated,
    gutter,
    overflowPadding,
    ...props,
  });
  return popover;
}
