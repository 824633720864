import { DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { gql } from "generated/graphql-codegen";
import { InterviewPlanItemWithPostTemplatesInput } from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useState } from "react";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

import { EditInterviewPlanDialog } from "./EditInterviewPlanDialog";
import type { InterviewPlanItem } from "./InterviewPlan/types";

/* SHARED */
export interface EditGuideInterviewPlanDialogProps {
  guideId: string;
}

/* V2 */
const GUIDE_V2 = gql(`
query GuideForEditGuideInterviewPlanModalQuery($guideId: String!) {
    guideById(guideId: $guideId) {
      id
      interviewPlan {
        ...InterviewPlan
      }
      candidate {
        id
        organization {
          id
          name
          defaultAvatarImageUrl
          companyLogoUrl
        }
      }
    }
  }
`);

const UPSERT_GUIDE_INTERVIEW_PLAN = gql(`
mutation UpsertGuideInterviewPlan($input: UpsertGuideInterviewPlanInput!) {
    upsertGuideInterviewPlan(input: $input) {
      success
      message
      code
      guide {
        id
        interviewPlan {
          ...InterviewPlan
        }
      }
    }
  }
`);

type Props = EditGuideInterviewPlanDialogProps & {
  store: DialogStore;
  done?: () => void;
};

export function EditGuideInterviewPlanDialog({ guideId, store, done }: Props) {
  const [interviewPlanItems, setInterviewPlanItems] = useState<
    InterviewPlanItem[]
  >([]);

  const { data, loading } = useQuery(GUIDE_V2, {
    variables: {
      guideId,
    },
    skip: !guideId,
  });
  const [upsertGuideInterviewPlan, { loading: saving }] = useMutation(
    UPSERT_GUIDE_INTERVIEW_PLAN,
    {
      onCompleted: () => {
        store.hide();
        done?.();
      },
    }
  );

  const guide = data?.guideById;
  const organizationForTemplateEditor = guide?.candidate.organization;

  // Load interview plan items when data is available
  useEffect(() => {
    if (guide?.interviewPlan) {
      const items = guide.interviewPlan.items.map((interviewPlanItem) => ({
        id: interviewPlanItem.id,
        title: interviewPlanItem.title,
        description: interviewPlanItem.description,
        postTemplates: [],
        interviewStageContent: interviewPlanItem.interviewStageContent,
      }));
      setInterviewPlanItems(items);
    }
  }, [guide]);

  const onSubmit = useCallback(
    async ({
      items,
      saveChangesToParent,
    }: {
      items: InterviewPlanItem[];
      saveChangesToParent: boolean;
    }) => {
      const itemsForCreate = items.map((item) => {
        const baseItem: Pick<
          InterviewPlanItemWithPostTemplatesInput,
          "title" | "description" | "interviewStageContent"
        > = {
          title: item.title,
          description: item.description,
          interviewStageContent: item.interviewStageContent
            ? {
                id: item.interviewStageContent.id ?? null,
                data: item.interviewStageContent.data,
              }
            : undefined,
        };

        const isNewItem = guide?.interviewPlan?.items.every(
          (interviewPlanItem) => interviewPlanItem.id !== item.id
        );

        if (isNewItem) {
          return baseItem;
        }

        return {
          ...baseItem,
          id: item.id,
        };
      });

      await upsertGuideInterviewPlan({
        variables: {
          input: {
            guideId,
            items: itemsForCreate,
            saveChangesToJob: saveChangesToParent,
          },
        },
      });
    },
    [guide?.interviewPlan?.items, guideId, upsertGuideInterviewPlan]
  );

  return (
    <EditInterviewPlanDialog
      store={store}
      loading={loading}
      saving={saving}
      onSubmit={onSubmit}
      initialInterviewPlanItems={interviewPlanItems}
      defaultSelectedInterviewPlanId={interviewPlanItems[0]?.id}
      organizationForTemplateEditor={organizationForTemplateEditor}
    />
  );
}
