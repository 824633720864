import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasAlt,
  atlasBookClosed,
  atlasCalendarCheck,
  atlasCalendarConflict,
  atlasPersonSparkle,
  atlasShadower,
} from "@resource/atlas/icons";
import { gql } from "generated/graphql-codegen";
import {
  AIScenarioCardInterviewerDisplayFragment,
  InterviewerRequirementType,
} from "generated/graphql-codegen/graphql";
import React, { useMemo } from "react";

gql(`
fragment AIScenarioCardInterviewerDisplay on Interviewer {
  id
  isShadow
  userMembership {
    id
    name
    imageUrl
  }
  interviewerRequirement {
    id
    type
  }
}
`);

export function AIScenarioInterviewerDisplay({
  interviewer,
  conflicts,
  compact,
}: {
  interviewer: AIScenarioCardInterviewerDisplayFragment;
  conflicts: string[];
  compact?: boolean;
}) {
  const isPool =
    interviewer.interviewerRequirement?.type === InterviewerRequirementType.TAG;
  const isSmartMatch =
    interviewer.interviewerRequirement?.type ===
    InterviewerRequirementType.SMART_MATCH;

  const icon = useMemo(() => {
    if (interviewer.isShadow) {
      return atlasShadower;
    }

    if (isSmartMatch) {
      return atlasPersonSparkle;
    }

    if (isPool) {
      return atlasBookClosed;
    }

    return atlasAlt;
  }, [interviewer.isShadow, isPool, isSmartMatch]);

  return (
    <div className="flex flex-col items-start gap-1">
      <div className="flex items-center gap-2">
        <Icon
          content={
            conflicts.length > 0 ? atlasCalendarConflict : atlasCalendarCheck
          }
          className={conflicts.length > 0 ? "text-red-500" : "text-green-500"}
        />
        <Avatar
          size="xs"
          name={interviewer.userMembership.name}
          image={interviewer.userMembership.imageUrl}
        />
        <div className="text-subtle text-body-sm truncate">
          {interviewer.userMembership.name}
        </div>
        <Icon content={icon} className="text-subtle" />
      </div>
      {!compact && conflicts.length > 0 && (
        <div className="ml-14 text-body-sm text-subtle flex flex-col items-start">
          {conflicts.map((label, i) => (
            <div key={i}>{label}</div>
          ))}
        </div>
      )}
    </div>
  );
}
