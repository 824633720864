import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { atlasCopy } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useLogEvent } from "analytics";
import { useSchedulingTaskAndAvailabilityWorkflowsNavigation } from "client/app/(scheduling requests)/hooks/useSchedulingTaskAndAvailabilityWorkflowsNavigation";
import { ManageAvailabilityRequest } from "client/guide-availability/components/ManageAvailabilityRequest";
import { SchedulingTaskAndAvailabilityWorkflowsDialog } from "client/scheduling-task-workflows/SchedulingTaskAndAvailabilityWorkflowsDialog";
import { gql } from "generated/graphql-codegen";
import {
  AvailabilityRequestForManageAvailabilityRequestFragment,
  GuideAvailabilitySubmissionsSubmissionFragment,
  SchedulingRequestStatus,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";

import { useCurrentAvailabilitySubmission } from "../hooks/useCurrentAvailabilitySubmission";
import { useOpenAvailabilityRequest } from "../hooks/useOpenAvailabilityRequest";
import { AvailabilityEmptyState } from "./AvailabilityEmptyState";
import { ClearCandidateAvailabilityConfirmationDialog } from "./ClearCandidateAvailabilityConfirmationModal";
import { CopyAvailabilityDialog } from "./CopyAvailabilityDialog";
import { CreateOrUpdateAvailabilityRequestDialog } from "./CreateOrUpdateAvailabilityRequestDialog";
import { CreateOrUpdateAvailabilitySubmissionDialog } from "./CreateOrUpdateAvailabilitySubmissionDialog";
import { CurrentCandidateAvailability } from "./CurrentCandidateAvailability";
import { FollowUpReminderDialog } from "./FollowUpReminderDialog";

gql(`
  fragment GuideAvailabilitySubmissionsSubmission on GuideAvailabilitySubmission {
    id
    hasFutureAvailability
    availabilityRequestId
    upcomingEvents {
      id
      title
      startTime
      endTime
    }
    createdBy {
      id
      name
      imageUrl
    }
    createdAt
    guidePost {
      id
      href
    }
  }
`);

gql(`
  fragment GuideForGlobalAvailabilityForCandidateProfile on Guide {
    id
    openAvailabilityRequest {
      ...AvailabilityRequestForManageAvailabilityRequest
    }
    currentAvailabilitySubmission {
      ...GuideAvailabilitySubmissionsSubmission
    }
  }
`);

type GlobalCandidateAvailabilitySectionProps = {
  guideId: string;
  candidateName: string;
  candidateTimezone: string | null;
  jobName: string;
  schedulingRequest?: {
    id: string;
    status: SchedulingRequestStatus;
  };
  showLargeActions?: boolean;
};

// If open task:
// on request availability:
// if open task has scheduled interviews, default to only request availability
// if open task does not have scheduled interviews, default to only request availability
// if already submission, use request additional availability

export function GlobalCandidateAvailabilitySection({
  guideId,
  candidateName,
  candidateTimezone,
  jobName,
  schedulingRequest,
  showLargeActions,
}: GlobalCandidateAvailabilitySectionProps) {
  const copyAvailabilityStore = useDialogStore();
  const editRequestStore = useDialogStore();
  const createOrEditSubmissionStore = useDialogStore();
  const clearCandidateAvailabilityStore = useDialogStore();
  const { availabilityRequest, loading: requestLoading } =
    useOpenAvailabilityRequest(guideId);
  const { currentSubmission, loading: submissionLoading } =
    useCurrentAvailabilitySubmission(guideId);
  const followupReminderDialogStore = useDialogStore();
  const onEditRequest = useCallback(() => {
    editRequestStore.show();
  }, [editRequestStore]);

  const onCreateOrEditSubmission = useCallback(() => {
    createOrEditSubmissionStore.show();
  }, [createOrEditSubmissionStore]);

  const onFollowUpReminder = useCallback(() => {
    followupReminderDialogStore.show();
  }, [followupReminderDialogStore]);
  const onClear = useCallback(() => {
    clearCandidateAvailabilityStore.show();
  }, [clearCandidateAvailabilityStore]);

  const { onClick: onRequestAvailability, dialogProps } =
    useSchedulingTaskAndAvailabilityWorkflowsNavigation({
      params: useMemo((): Parameters<
        typeof useSchedulingTaskAndAvailabilityWorkflowsNavigation
      >[0]["params"] => {
        if (schedulingRequest) {
          if (schedulingRequest.status === SchedulingRequestStatus.COMPLETED) {
            return {
              guideId,
              defaultSchedulingRequestFormType: "rescheduling",
            };
          }

          return {
            guideId,
            schedulingRequestIdForAvailabilityRequest: schedulingRequest.id,
          };
        }

        return {
          guideId,
          defaultSchedulingRequestFormType: "select-task",
        };
      }, [guideId, schedulingRequest]),
      analytics: {
        component: "GlobalCandidateAvailabilitySection",
      },
    });

  if (requestLoading || submissionLoading) {
    return (
      <div className="space-y-2">
        <h4 className="text-h4">Candidate availability</h4>
        <div className="flex flex-row justify-center items-center h-20">
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="space-y-2">
        <div className="flex flex-row justify-between items-center gap-2">
          <h4 className="text-h4">Candidate availability</h4>
          {currentSubmission && (
            <Tooltip content="Copy availability as text">
              <Button
                isGhost
                size="small"
                icon={atlasCopy}
                onClick={() => {
                  copyAvailabilityStore.show();
                }}
              />
            </Tooltip>
          )}
        </div>
        {currentSubmission ? (
          <div className="space-y-3">
            <CurrentCandidateAvailability
              currentSubmission={currentSubmission}
              onEdit={onCreateOrEditSubmission}
              onClear={onClear}
            />
            {!availabilityRequest && !showLargeActions && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                as="button"
                variant="default"
                onClick={onRequestAvailability}
                className="text-body-md"
              >
                Request availability
              </Link>
            )}
          </div>
        ) : (
          <>
            {!availabilityRequest && (
              <AvailabilityEmptyState
                onAddManually={onCreateOrEditSubmission}
                onRequestAvailability={onRequestAvailability}
                hideActions={showLargeActions}
              />
            )}
          </>
        )}
        {availabilityRequest && (
          <ManageAvailabilityRequest
            guideId={guideId}
            onEdit={onEditRequest}
            onAddManually={onCreateOrEditSubmission}
            availabilityRequest={availabilityRequest ?? undefined}
          />
        )}
        {showLargeActions && (
          <div className="flex flex-col w-full space-y-1">
            <LargeActionButtons
              availabilityRequest={availabilityRequest}
              currentSubmission={currentSubmission}
              onRequestAvailability={onRequestAvailability}
              onAddManually={onCreateOrEditSubmission}
              onFollowUpReminder={onFollowUpReminder}
            />
          </div>
        )}
        <FollowUpReminderDialog
          store={followupReminderDialogStore}
          availabilityRequest={availabilityRequest}
        />
        <SchedulingTaskAndAvailabilityWorkflowsDialog {...dialogProps} />
        {currentSubmission && (
          <CopyAvailabilityDialog
            candidateName={candidateName}
            jobName={jobName}
            timezone={candidateTimezone}
            store={copyAvailabilityStore}
            availability={currentSubmission.upcomingEvents}
          />
        )}
        <CreateOrUpdateAvailabilityRequestDialog
          store={editRequestStore}
          availabilityRequestId={availabilityRequest?.id}
        />
        <CreateOrUpdateAvailabilitySubmissionDialog
          store={createOrEditSubmissionStore}
          guideId={guideId}
          availabilityRequestId={availabilityRequest?.id}
          availabilitySubmissionId={currentSubmission?.id}
        />
        <ClearCandidateAvailabilityConfirmationDialog
          store={clearCandidateAvailabilityStore}
          guideId={guideId}
        />
      </div>
    </>
  );
}

type LargeActionButtonsProps = {
  availabilityRequest?: AvailabilityRequestForManageAvailabilityRequestFragment | null;
  currentSubmission?: GuideAvailabilitySubmissionsSubmissionFragment | null;
  onRequestAvailability: () => void;
  onAddManually: () => void;
  onFollowUpReminder: () => void;
};
function LargeActionButtons({
  availabilityRequest,
  currentSubmission,
  onRequestAvailability,
  onAddManually,
  onFollowUpReminder,
}: LargeActionButtonsProps) {
  const logEvent = useLogEvent({
    component: "GlobalCandidateAvailabilitySection",
  });
  if (!availabilityRequest && !currentSubmission) {
    return (
      <>
        <Button
          variant="primary"
          onClick={() => {
            logEvent("Request Availability Clicked");
            onRequestAvailability();
          }}
        >
          Request availability
        </Button>
        <Button
          variant="default"
          onClick={() => {
            logEvent("Add Availability Manually Clicked");
            onAddManually();
          }}
        >
          Add manually
        </Button>
      </>
    );
  }

  if (availabilityRequest && !currentSubmission) {
    return (
      <>
        <Button
          variant="default"
          onClick={() => {
            logEvent(
              "Send Follow-Up Reminder For Availability Request Clicked"
            );
            onFollowUpReminder();
          }}
        >
          Send follow-up reminder
        </Button>
        <Button
          variant="white"
          onClick={() => {
            logEvent("Add Availability Manually Clicked");
            onAddManually();
          }}
        >
          Add manually
        </Button>
      </>
    );
  }

  if (currentSubmission) {
    return (
      <>
        <Button variant="default" onClick={onRequestAvailability}>
          Request additional availability
        </Button>
      </>
    );
  }

  return null;
}
