import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasDoor,
  atlasEllipsisHorizontal,
  atlasUsers,
  atlasVideoCamera,
} from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { Skeleton } from "components/Skeleton";
import StopPropagation from "components/StopPropagation";
import { useState } from "react";

import { ConferenceRoom } from "../utils/types";
import { ConferenceRoomConflictIcon } from "./ConferenceRoomConflictIcon";

export function ConferenceRoomCard({
  conferenceRoom,
  onRemove,
}: {
  conferenceRoom: ConferenceRoom;
  onRemove: () => unknown;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = useMenuItems(
    (i) => {
      return [
        i.item({
          key: "remove",
          children: "Remove",
          onClick: onRemove,
        }),
      ];
    },
    [onRemove]
  );

  return (
    <div className="flex gap-2 items-center px-3 py-2 border-gray-border border rounded-md relative">
      {conferenceRoom.availability ? (
        <ConferenceRoomConflictIcon
          availability={conferenceRoom.availability}
        />
      ) : (
        <div className="bg-light-gray-500 shrink-0 rounded-full p-1">
          <Icon content={atlasDoor} className="text-subtle w-4 h-4" />
        </div>
      )}
      <div className="flex-grow min-w-0">
        {conferenceRoom.building && (
          <div className="text-body-md-heavy">
            {conferenceRoom.building.name}
          </div>
        )}
        <div className="flex gap-2 text-body-md items-center">
          <div className="truncate">{conferenceRoom.name}</div>
          <div className="shrink-0">floor {conferenceRoom.floorName}</div>
        </div>
        <div className="flex gap-2 text-body-md text-subtle">
          {conferenceRoom.capacity ? (
            <div className="flex gap-1 items-center">
              <Icon content={atlasUsers} className="w-4 h-4" />{" "}
              {conferenceRoom.capacity}
            </div>
          ) : null}
          {conferenceRoom.hasZoom && (
            <div className="flex gap-1 items-center">
              <Icon content={atlasVideoCamera} className="w-4 h-4" /> Zoom
            </div>
          )}
        </div>
      </div>
      <StopPropagation>
        <div className="h-full flex flex-col justify-center shrink-0">
          <Menu.Root setOpen={(open) => setIsMenuOpen(open)} placement="left">
            <Menu.Trigger>
              <Button
                icon={atlasEllipsisHorizontal}
                isGhost
                size="xs"
                isActive={isMenuOpen}
              />
            </Menu.Trigger>
            <Menu.Content portal items={menuItems} />
          </Menu.Root>
        </div>
      </StopPropagation>
    </div>
  );
}

export function ConferenceRoomCardSkeleton() {
  return (
    <div className="flex gap-2 items-center px-3 py-2.5 border-gray-border border rounded-md relative">
      <Skeleton className="h-6 w-6" type="circle" />
      <div className="flex-grow min-w-0">
        <Skeleton className="h-4 w-24 mb-1" type="text" />
        <Skeleton className="h-4 w-36 mb-1" type="text" />
        <div className="flex gap-2">
          <Skeleton className="h-4 w-16" type="text" />
          <Skeleton className="h-4 w-12" type="text" />
        </div>
      </div>
      <Skeleton className="h-6 w-6" type="circle" />
    </div>
  );
}
