import { DateTime, Interval } from "luxon";
import { displayTimezone } from "shared/utils/timezones";

export type DateFormattingType = "default" | "short";

type BaseProps = {
  date: string | DateTime;
  timezone?: string;
};

function getDate({ date, timezone }: BaseProps): DateTime {
  return typeof date === "string"
    ? DateTime.fromISO(date, {
        zone: timezone,
      })
    : date;
}

/**
 * Format to relative time.
 * Should only be used for timestamps that are less than 24 hours old.
 * Private function to be used by `formatTimestamp` which will properly switch to friendly absolute time.
 */
function formatRelativeTime({
  format = "default",
  ...props
}: BaseProps & {
  format?: DateFormattingType;
}): string {
  const date = getDate(props);

  const diffInMinutes = DateTime.now()
    .setZone(props.timezone)
    .diff(date, "minutes").minutes;

  if (diffInMinutes < 1) {
    return "Just now";
  }

  let style: "short" | "long" = "long";

  if (format === "short") {
    style = "short";
  }

  return (
    date.toRelative({
      style,
    }) ?? formatAbsoluteTime(props)
  );
}

/**
 * Format to friendly absolute time, (i.e. `May 28 at 10:10pm PST`)
 */
export function formatAbsoluteTime({
  format,
  ...props
}: BaseProps & {
  format?: DateFormattingType;
}): string {
  const date = getDate(props);

  if (format === "short") {
    return date.toFormat(`MMM d`);
  }

  return date.toFormat(`MMM d 'at' h:mma`);
}

export type FormatTimestampProps = BaseProps & {
  format?: DateFormattingType;
  disableRelative?: boolean;
};

/**
 * Format a user-facing timestamp.
 * Uses relative time when less than 24 hours, then switches to friendly absolute time
 */
export function formatTimestamp({
  format,
  disableRelative,
  ...props
}: FormatTimestampProps) {
  const date = getDate(props);

  if (!disableRelative) {
    if (Math.abs(DateTime.now().diff(date, "days").days) < 1) {
      return formatRelativeTime({
        ...props,
        format,
      });
    }
  }

  if (Math.abs(DateTime.now().diff(date, "minutes").minutes) < 1) {
    // Even if disableRelative is passed, we still want to show `Just now` if the timestamp is less than a minute old
    return "Just now";
  }

  return formatAbsoluteTime({
    ...props,
    format,
  });
}

/**
 * Format to exact time, including seconds (i.e. `October 14, 1983, 9:30:33 AM EDT`).
 * Should only be used for hover tooltips to show exact timestamp
 */
export function formatExactTimestamp(props: BaseProps): string {
  const date = getDate(props);

  return date.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
}

type GetFormattedDateRangeProps = {
  startTime: string | DateTime;
  endTime: string | DateTime;
  timezone?: string | undefined;
};

// Matches common/dates.ts but uses displayTimezone
export function getFormattedDateRange({
  startTime: passedStartTime,
  endTime: passedEndTime,
  timezone: passedTimezone,
}: GetFormattedDateRangeProps) {
  const userTimezone = DateTime.local().zoneName;
  const timezone = passedTimezone || userTimezone;
  const friendlyTimezone = displayTimezone(timezone, "abbreviation", true);
  const startTime =
    typeof passedStartTime === "string"
      ? DateTime.fromISO(passedStartTime, { zone: timezone })
      : passedStartTime.setZone(timezone);
  const endTime =
    typeof passedEndTime === "string"
      ? DateTime.fromISO(passedEndTime, { zone: timezone })
      : passedEndTime.setZone(timezone);

  const isSameDay = startTime.hasSame(endTime, "day");

  let formattedDateTime;
  if (isSameDay) {
    formattedDateTime = `${startTime.toFormat(
      "ccc, LLL d"
    )} · ${startTime.toFormat("h:mm")}-${endTime.toFormat(
      "h:mma"
    )} ${friendlyTimezone}`;
  } else {
    formattedDateTime = `${startTime.toFormat(
      "ccc LLL d h:mma"
    )} - ${endTime.toFormat("ccc LLL d h:mma")} ${friendlyTimezone}`;
  }

  return {
    /** Ex: Thu, Mar 7 */
    formattedDate: startTime.toFormat("ccc, LLL d"),
    /** Ex: 11:00AM CST */
    formattedStartTime: `${startTime.toFormat("h:mma")} ${friendlyTimezone}`,
    /** Ex: 11:00-12:00PM CST */
    formattedTimeRange: `${startTime.toFormat("h:mm")}-${endTime.toFormat(
      "h:mma"
    )} ${friendlyTimezone}`,
    /** Ex: Thu, Mar 7 · 11:00-12:00PM CST or Mon Mar 7 12:30PM - Tue  March 8 10:00AM PDT */
    formattedDateTime,
    /** Ex: 7 */
    formattedDay: startTime.toFormat("d"),
    /** Ex: Thu */
    formattedDayName: startTime.toFormat("ccc"),
  };
}

export type CalendarEventInputDateTime = {
  date?: string | null | undefined;
  dateTime?: string | null | undefined;
};

/** Convert string | DateTime to a DateTime */
const getDatetimeValue = (t: DateTime | string) =>
  typeof t === "string" ? DateTime.fromISO(t) : t;

/** Map times with potential date or datetime values to luxon objects */
export function mapCalendarEventInputToLuxonTimes(
  {
    start,
    end,
  }: {
    start: CalendarEventInputDateTime;
    end: CalendarEventInputDateTime;
  },
  timezone?: string
) {
  let allDay: boolean | undefined;
  let startTime: DateTime;
  let endTime: DateTime;

  if (start.dateTime) {
    startTime = DateTime.fromISO(start.dateTime, {
      zone: timezone,
    });
  } else if (start.date) {
    startTime = DateTime.fromISO(start.date, {
      zone: timezone,
    }).startOf("day");
    allDay = true;
  } else {
    console.error("No start time provided");
    startTime = DateTime.local().startOf("day");
  }

  if (end.dateTime) {
    endTime = DateTime.fromISO(end.dateTime, {
      zone: timezone,
    });
  } else if (end.date) {
    endTime = DateTime.fromISO(end.date, {
      zone: timezone,
    }).startOf("day");
  } else {
    console.error("No end time provided");
    endTime = DateTime.local().endOf("day");
  }

  return {
    startTime,
    endTime,
    allDay,
  };
}

/** Check if two events with different formatted start and end times overlap with each other */
export const eventsOverlap = (
  passedE1:
    | {
        start: CalendarEventInputDateTime;
        end: CalendarEventInputDateTime;
      }
    | {
        startTime: DateTime | string | null;
        endTime: DateTime | string | null;
      },
  passedE2:
    | {
        start: CalendarEventInputDateTime;
        end: CalendarEventInputDateTime;
      }
    | {
        startTime: DateTime | string | null;
        endTime: DateTime | string | null;
      },
  timezone?: string
) => {
  const e1 =
    "startTime" in passedE1
      ? {
          startTime: passedE1.startTime,
          endTime: passedE1.endTime,
          allDay: false,
        }
      : mapCalendarEventInputToLuxonTimes(passedE1, timezone);
  const e2 =
    "startTime" in passedE2
      ? {
          startTime: passedE2.startTime,
          endTime: passedE2.endTime,
          allDay: false,
        }
      : mapCalendarEventInputToLuxonTimes(passedE2, timezone);

  if (!e1.startTime || !e1.endTime || !e2.startTime || !e2.endTime) {
    return false;
  }

  const interval1 = Interval.fromDateTimes(
    getDatetimeValue(e1.startTime),
    getDatetimeValue(e1.endTime)
  );
  const interval2 = Interval.fromDateTimes(
    getDatetimeValue(e2.startTime),
    getDatetimeValue(e2.endTime)
  );

  return interval1.overlaps(interval2);
};
