import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_TARGETS } from "@resource/atlas/content-editor/__utils/insert-options-targets-order";
import { createRichBlock } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { atlasCalendarTimesReceived } from "@resource/atlas/icons";
import { useMemo } from "react";
import {
  DEFAULT_DATA,
  GuideAvailabilityRequestData,
  GuideAvailabilityRequestDataSchema,
  GuideAvailabilityRequestName,
  RICH_BLOCK_NAME,
} from "shared/guide-availability/rich-blocks/guide-availability-request";

export const {
  $createRequestAvailabilityNode: $createGuideAvailabilityRequestNode,
  $isRequestAvailabilityNode: $isGuideAvailabilityRequestNode,
  INSERT_REQUEST_AVAILABILITY_COMMAND:
    INSERT_GUIDE_AVAILABILITY_REQUEST_COMMAND,
  RequestAvailabilityNode: GuideAvailabilityRequestNode,
  RequestAvailabilityPlugin: GuideAvailabilityRequestPlugin,
  requestAvailabilityModule: guideAvailabilityRequestModule,
} = createRichBlock<GuideAvailabilityRequestName, GuideAvailabilityRequestData>(
  {
    name: RICH_BLOCK_NAME,
    defaultData: DEFAULT_DATA,
    RenderComponent: async () => {
      const { GuideAvailabilityRequestComponent } = await import("./component");
      return GuideAvailabilityRequestComponent;
    },
    useInsertOption(_, { INSERT_COMMAND }) {
      const [editor] = useLexicalComposerContext();

      return useMemo(
        () => ({
          icon: atlasCalendarTimesReceived,
          label: "Request availability",
          targets: {
            insert: INSERT_TARGETS.REQUEST_AVAILABILITY,
            fixedToolbar: { slot: "request-availability" },
          },
          onTrigger: () => {
            return editor.dispatchCommand(INSERT_COMMAND, DEFAULT_DATA);
          },
        }),
        [editor, INSERT_COMMAND]
      );
    },
    zodSchema: GuideAvailabilityRequestDataSchema,
  }
);
