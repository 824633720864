/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Avatar } from "../../../avatar/Avatar";
import { AtlasMenuState, Menu } from "../../../menu";
import { useMenuItems } from "../../../menu/use-menu-items";
import {
  useTypeahead,
  useTypeaheadMatch,
  useTypeaheadMenu,
} from "../../__utils/typeahead";
import { useMentionsConfig } from "./config";
import { $createMentionNode } from "./MentionNode";
import { MentionValue } from "./types";

export function MentionPlugin() {
  const { menu, menuContentProps } = useMentionMenu();

  return (
    <Menu.Root state={menu} placement="top">
      <Menu.Content
        {...menuContentProps}
        className="!max-w-[20rem] !min-w-[16rem]"
      />
    </Menu.Root>
  );
}

function useMentionMenu() {
  const matchFn = useTypeaheadMatch("@", { spaceAllowed: true });
  const typeahead = useTypeahead({ matchFn });
  const debouncedTerm = useDebouncedTerm({ typeahead });
  const { values, loading } = useMentionQuery({ debouncedTerm });

  const [editor] = useLexicalComposerContext();
  const { $replaceMatch } = typeahead;
  const mentionMenu = useTypeaheadMenu({
    isLoading: loading,
    placement: "top-start",
    typeahead,
    items: useMenuItems(
      (i) =>
        values.map((mention) => {
          return i.item({
            key: mention.id,
            children: mention.name,
            renderContent: () => {
              return (
                <div className="flex gap-2 items-center">
                  <Avatar
                    size="xs"
                    image={mention.imageUrl}
                    name={mention.name}
                  />
                  <div title={mention.name}>{mention.name}</div>
                </div>
              );
            },
            onClick: () =>
              editor.update(() => {
                const mentionNode = $createMentionNode(mention.id, {
                  id: mention.id,
                  name: mention.name,
                  imageUrl: mention.imageUrl,
                });
                $replaceMatch(mentionNode);
                mentionNode.selectNext();
              }),
            size: "compact",
          });
        }),
      [values, editor, $replaceMatch]
    ),
  });

  useFocusOnFirstItem({
    menu: mentionMenu.menu,
    typeahead,
    values,
  });

  return mentionMenu;
}

function useDebouncedTerm({
  typeahead,
}: {
  typeahead: ReturnType<typeof useTypeahead>;
}) {
  const searchTerm = typeahead.match?.queryString ?? "";
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedTerm(searchTerm.trim()), 200);
    return () => clearTimeout(timeout);
  }, [searchTerm]);

  return debouncedTerm;
}

function useMentionQuery({ debouncedTerm }: { debouncedTerm: string }) {
  const { onTypeMention } = useMentionsConfig();
  const [values, setValues] = useState<MentionValue[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await onTypeMention(debouncedTerm);
      setValues(result);
      setLoading(false);
    };

    fetchData();
  }, [debouncedTerm, onTypeMention]);

  return useMemo(
    () => ({
      values,
      loading,
    }),
    [loading, values]
  );
}

function useFocusOnFirstItem({
  menu,
  typeahead,
  values,
}: {
  menu: AtlasMenuState;
  typeahead: ReturnType<typeof useTypeahead>;
  values: ReturnType<typeof useMentionQuery>["values"];
}) {
  const setFirstItemAsActive = useCallback(() => {
    const firstItem = menu.baseRef.current?.querySelector('[role="menuitem"]');
    if (!firstItem) return;
    menu.setActiveId(firstItem.id);
  }, [menu]);

  useEffect(() => {
    const menuItemIds = menu.items.map((i) => i.id ?? "");

    if (
      typeahead.open &&
      values.length > 0 &&
      (!menu.activeId || !menuItemIds.includes(menu.activeId))
    )
      setFirstItemAsActive();
  }, [
    menu.activeId,
    menu.items,
    setFirstItemAsActive,
    typeahead.open,
    values.length,
  ]);
}
