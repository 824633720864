/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx";
import { ComponentProps, useCallback, useRef } from "react";

import { getBackgroundStylingFromConfig } from "./colors";
import { useCalendarContext } from "./context";
import { CalendarEvent } from "./types";

export type EventBackgroundProps = {
  event: Pick<
    CalendarEvent,
    | "id"
    | "onClick"
    | "colorConfig"
    | "disableDetailsPopover"
    | "onMouseDown"
    | "onMouseEnter"
    | "onMouseLeave"
    | "onMouseDown"
    | "responseStatus"
    | "outOfOffice"
  >;
  onMouseDown?: (props: {
    event: React.MouseEvent<HTMLDivElement, MouseEvent>;
    yOffset: number;
  }) => void;
} & Omit<ComponentProps<"span">, "onMouseDown">;

export function EventBackground({
  event,
  className,
  children,
  ...props
}: EventBackgroundProps) {
  const {
    setEventDetailsPopoverTriggerElement,
    eventDetailsPopoverState,
    setFocusedEventId,
    setHoveringEventId,
    setHoveringTriggerElement,
  } = useCalendarContext();
  const eventRef = useRef<HTMLDivElement | null>(null);
  const hoverTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = useCallback(() => {
    hoverTimeoutId.current = setTimeout(() => {
      setHoveringEventId(event.id);
      setHoveringTriggerElement(eventRef.current);
    }, 500);
    event.onMouseEnter?.();
  }, [event, setHoveringEventId, setHoveringTriggerElement]);

  const handleMouseLeave = useCallback(() => {
    if (hoverTimeoutId.current) {
      clearTimeout(hoverTimeoutId.current);
    }
    setHoveringEventId(null);
    setHoveringTriggerElement(null);
    event.onMouseLeave?.();
  }, [event, setHoveringEventId, setHoveringTriggerElement]);

  return (
    <div
      {...props}
      className={clsx(
        "group absolute inset-[0.5px] flex items-start justify-between gap-1 rounded-lg py-[6px] pl-2 pr-0 text-xs leading-5 overflow-hidden transition",
        className
      )}
      style={{
        ...getBackgroundStylingFromConfig({
          config: event.colorConfig,
          responseStatus: event.responseStatus,
        }),
        ...props.style,
      }}
      ref={eventRef}
      onClick={() => {
        if (!event.disableDetailsPopover) {
          setEventDetailsPopoverTriggerElement(eventRef.current);
          setFocusedEventId(event.id);
          eventDetailsPopoverState.show();
        }

        event.onClick?.();
      }}
      onMouseDown={(e) => {
        const rect = eventRef.current?.getBoundingClientRect();
        const offset = e.clientY - (rect?.top || 0);

        props.onMouseDown?.({
          event: e,
          yOffset: offset,
        });
        event.onMouseDown?.();
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
}
