import { Button } from "@resource/atlas/button/Button";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { gql } from "generated/graphql-codegen";
import { GuideManagerInterviewsGuideFragmentDoc } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

const ARCHIVE_SCHEDULED_INTERVIEW = gql(`
  mutation ArchiveScheduledInterview($input: ArchiveScheduledInterviewInput!) {
    archiveScheduledInterview(input: $input) {
      success
      message
      code
      scheduledInterview {
        id
        guideId
      }
    }
  }
`);

export type ArchiveInterviewConfirmationDialogProps = {
  scheduledInterviewId: string;
  store: DialogStore;
  onCompleted?: () => void;
};

export function ArchiveInterviewConfirmationDialog({
  scheduledInterviewId,
  store,
  onCompleted,
}: ArchiveInterviewConfirmationDialogProps) {
  const logEvent = useLogEvent({
    component: "ArchiveInterviewConfirmationDialog",
  });
  const [archiveScheduledInterview, { loading: submitLoading }] = useMutation(
    ARCHIVE_SCHEDULED_INTERVIEW
  );

  const handleArchive = useCallback(() => {
    archiveScheduledInterview({
      variables: {
        input: {
          id: scheduledInterviewId,
        },
      },
      update: (cache, { data }) => {
        const deletedInterview =
          data?.archiveScheduledInterview?.scheduledInterview;

        if (deletedInterview && deletedInterview.guideId) {
          cache.updateFragment(
            {
              fragment: GuideManagerInterviewsGuideFragmentDoc,
              fragmentName: "GuideManagerInterviewsGuide",
              id: `Guide:${deletedInterview.guideId}`,
            },
            (guideManagerGuide) => {
              if (guideManagerGuide) {
                return {
                  ...guideManagerGuide,
                  internalUpcomingInterviews:
                    guideManagerGuide.internalUpcomingInterviews.filter(
                      (interview) => interview.id !== deletedInterview.id
                    ),
                };
              }

              return guideManagerGuide;
            }
          );
        }
      },
      onCompleted: () => {
        logEvent("Interview Archived");
        store.hide();

        if (onCompleted) {
          onCompleted();
        }
      },
    });
  }, [
    archiveScheduledInterview,
    scheduledInterviewId,
    logEvent,
    store,
    onCompleted,
  ]);

  return (
    <Dialog store={store} size="small">
      <View
        className="text-body-md"
        header={{
          title: "Delete interview in Guide",
        }}
        footer={{
          leftActions: (
            <Button isGhost onClick={() => store.hide()}>
              No, don&apos;t delete
            </Button>
          ),
          rightActions: (
            <Button
              variant="danger"
              onClick={handleArchive}
              isLoading={submitLoading}
            >
              Delete
            </Button>
          ),
        }}
      >
        <div>
          Deleting the interview will remove it from the candidate&apos;s Guide,
          and will not delete any interviews in Greenhouse.
        </div>
        <div className="mt-1">
          <strong>Would you like to continue?</strong>
        </div>
      </View>
    </Dialog>
  );
}
