import { ContentEditor } from "@resource/atlas/content-editor";
import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import type { AtlasContentEditorProps } from "@resource/atlas/content-editor/types";
import {
  ValueSet,
  variablesModule,
} from "@resource/atlas/content-editor/variables";
import { useLinkCardModule } from "client/guide-content/rich-blocks/link-card";
import { useLinkCardsCollectionModule } from "client/guide-content/rich-blocks/link-cards-collection";
import {
  guideAboutSpecSet,
  GuideAboutVariableSet,
} from "shared/guide-content/about";

import { useBaseModules } from "./base-modules";

type GuideAboutEditorProps = AtlasContentEditorProps & {
  valueSet: ValueSet<GuideAboutVariableSet>;
  /** @default "placeholder" */
  undefinedMode?: "placeholder" | "error";
};

export function GuideAboutEditor({
  valueSet,
  undefinedMode,
  modules = [],
  ...props
}: GuideAboutEditorProps) {
  return (
    <ContentEditor
      {...props}
      modules={[
        ...useBaseModules(),
        withConfig(variablesModule, {
          specSet: guideAboutSpecSet,
          valueSet,
          undefinedMode,
        }),
        useLinkCardModule(),
        useLinkCardsCollectionModule(),
        ...modules,
      ]}
    />
  );
}
