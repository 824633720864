import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  AtlasButtonGroupProps,
  AtlasButtonProps,
} from "@resource/atlas/button/types";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";

export type FormRightActionsProps = AtlasButtonGroupProps & {
  isLoading?: boolean;
  disabledTooltipContent?: string;
  cancel: AtlasButtonProps;
  save: AtlasButtonProps;
};

export function FormRightActions({
  isLoading,
  disabledTooltipContent,
  cancel,
  save,
  ...props
}: FormRightActionsProps) {
  const { children: cancelButtonText, ...cancelButtonProps } = cancel;
  const { children: saveButtonText, ...saveButtonProps } = save;

  return (
    <ButtonGroup {...props}>
      <Button isGhost {...cancelButtonProps}>
        {cancelButtonText ?? "Cancel"}
      </Button>
      <OptionalTooltip isInstant content={disabledTooltipContent}>
        <Button
          variant="primary"
          disabled={!!disabledTooltipContent}
          isLoading={isLoading}
          {...saveButtonProps}
        >
          {saveButtonText ?? "Save changes"}
        </Button>
      </OptionalTooltip>
    </ButtonGroup>
  );
}
