/* eslint-disable import/prefer-default-export */
import { createDynamicCollectionItemsHook } from "../__utils/collections";
import type { AtlasTabsListDynamicItems, AtlasTabsListItem } from "./types";

/**
 * Creates a memoized dynamic collection of tab items. It wraps `useMemo` internally
 * and must be passed an array of dependencies.
 *
 * This hook also enforces proper types for the items.
 *
 * **The `i.<type>()` methods must be used to create the items:**
 *
 * @example
 * const tabsItems = useTabsItems(
 *   (i) => [
 *     i.tab({
 *       key: "my-tab",
 *       children: "My tab",
 *     }),
 *   ],
 *   []
 * );
 *
 * // complex use cases are also possible:
 * const tabsItems = useTabsItems(
 *   (i) => {
 *     // calling `i` will return a typed mutable array, and you
 *     // can optionally pass initial items to it as arguments
 *     const items = i();
 *
 *     if (condition)
 *       items.push(
 *         i.tab({
 *           key: "my-tab",
 *           children: "My tab",
 *         })
 *       );
 *
 *     return items;
 *   },
 *   [condition]
 * );
 *
 * // you can also use methods like `.map()`:
 * const tabsItems = useTabsItems(
 *   (i) =>
 *     myArray.map((value) =>
 *       i.tab({
 *         key: value.key,
 *         children: value.label,
 *       })
 *     ),
 *   [myArray]
 * );
 */
export const useTabsItems = createDynamicCollectionItemsHook<
  AtlasTabsListItem,
  AtlasTabsListDynamicItems
>();
