import clsx from "clsx";
import { ComponentProps } from "react";

import { EventBackground, EventBackgroundProps } from "../../EventBackground";
import { EventTitle } from "../../EventTitle";
import { useCalendarSettings } from "../../settings";
import { CalendarEvent } from "../../types";
import { useCalendarSizes } from "../../utils";

export function AllDayEvent({
  event,
  wrapperProps,
  eventProps,
}: {
  event: CalendarEvent;
  wrapperProps?: ComponentProps<"li">;
  eventProps?: Omit<EventBackgroundProps, "event">;
}) {
  const { view } = useCalendarSettings();
  const { startTime } = event;
  const dayOfWeek = startTime.weekday;
  const calendarSizes = useCalendarSizes();

  return (
    <li
      {...wrapperProps}
      className={clsx(
        `relative mt-px flex w-full`,
        {
          "col-start-1": view === "day" || dayOfWeek === 1,
          "col-start-2": view === "week" && dayOfWeek === 2,
          "col-start-3": view === "week" && dayOfWeek === 3,
          "col-start-4": view === "week" && dayOfWeek === 4,
          "col-start-5": view === "week" && dayOfWeek === 5,
          "col-start-6": view === "week" && dayOfWeek === 6,
          "col-start-7": view === "week" && dayOfWeek === 7,
        },
        wrapperProps?.className
      )}
      style={{
        height: `${calendarSizes.allDayEventsViewHeight.rem}rem`,
        ...wrapperProps?.style,
      }}
    >
      <EventBackground event={event} {...eventProps}>
        <EventTitle event={event} />
      </EventBackground>
    </li>
  );
}
