import { Button } from "@resource/atlas/button/Button";
import { atlasSidebarLeft, atlasSidebarRight } from "@resource/atlas/icons";
import { useLogEvent } from "analytics";
import {
  Calendar,
  CalendarProps,
} from "client/components/calendar-v2/components/Calendar";
import {
  GetCalendarDisplayInfo,
  GetEventDetailsFooter,
} from "client/components/calendar-v2/utils/types";
import { useSetAtom } from "jotai";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

import { useSelectedInterview } from "../hooks/interviews";
import { navigationAtom } from "../state";
import { DayViewInterviewerCalendarDisplay } from "./components/DayViewInterviewerCalendarDisplay";
import { EventDetailsWhitelistFooter } from "./components/EventDetailsWhitelistFooter";
import { useCalendarEvents } from "./hooks/useCalendarEvents";
import { useCalendarInitialScroll } from "./hooks/useCalendarInitialScroll";
import { useCalendarsGrouping } from "./hooks/useCalendarsGrouping";
import { useDayViewCalendarIds } from "./hooks/useDayViewCalendarIds";
import { useSchedulerCalendarColors } from "./hooks/useSchedulerCalendarColors";
import { AvailabilityPlugin } from "./plugins/availability/component";
import { InterviewConflictHovercardPlugin } from "./plugins/conflict-hovercard/component";
import { PlaceInterviewCalendarPlugin } from "./plugins/place-interview-calendar/component";
import { EventLayers } from "./utils/group-ids";

function usePlugins(): JSX.Element[] {
  const plugins = useMemo(
    () => [
      <PlaceInterviewCalendarPlugin key="place-interview-plugin" />,
      <AvailabilityPlugin key="availability-plugin" />,
      <InterviewConflictHovercardPlugin key="interview-conflict-hovercard-plugin" />,
    ],
    []
  );
  return plugins;
}

export function SchedulerCalendar({
  plugins: passedPlugins,
}: {
  plugins?: JSX.Element[];
}) {
  const events = useCalendarEvents();
  const defaultPlugins = usePlugins();
  const plugins = useMemo(() => {
    return [...defaultPlugins, ...(passedPlugins ?? [])];
  }, [defaultPlugins, passedPlugins]);
  const selectedInterview = useSelectedInterview();
  const calendarColors = useSchedulerCalendarColors();
  const getCalendarDisplayInfo: GetCalendarDisplayInfo = useCallback(
    (calendarId) => (
      <DayViewInterviewerCalendarDisplay calendarId={calendarId} />
    ),
    []
  );
  const getEventDetailsFooter: GetEventDetailsFooter = useCallback(
    (event) => <EventDetailsWhitelistFooter event={event} />,
    []
  );
  const dayViewCalendarIds = useDayViewCalendarIds();
  const { calendarsGrouping, currentViewingGroupingId } =
    useCalendarsGrouping();
  const headerProps = useMemo(
    (): CalendarProps["headerProps"] => ({
      leftActions: <CalendarPanelOpenAction side="left" />,
      rightActions: <CalendarPanelOpenAction side="right" />,
    }),
    []
  );
  const calendarOptions = useMemo(
    (): CalendarProps["options"] => ({
      dayViewCalendarIds,
      getCalendarDisplayInfo,
      calendarsGrouping,
      layerPriority: [EventLayers.PANEL, EventLayers.INTERVIEWER],
      getEventDetailsFooter,
    }),
    [
      calendarsGrouping,
      dayViewCalendarIds,
      getCalendarDisplayInfo,
      getEventDetailsFooter,
    ]
  );
  const currentViewingTime = useMemo(() => {
    return selectedInterview?.startTime
      ? DateTime.fromISO(selectedInterview.startTime)
      : undefined;
  }, [selectedInterview?.startTime]);
  const initialScrollValue = useCalendarInitialScroll();

  return (
    <div className="overflow-hidden grow">
      <Calendar
        calendarColors={calendarColors}
        initialScrollValue={initialScrollValue}
        events={events}
        plugins={plugins}
        headerProps={headerProps}
        options={calendarOptions}
        currentViewingTime={currentViewingTime}
        currentViewingGroupingId={currentViewingGroupingId}
      />
    </div>
  );
}

function CalendarPanelOpenAction({ side }: { side: "left" | "right" }) {
  const logEvent = useLogEvent({
    component: "SchedulerCalendar",
  });
  const setNavigation = useSetAtom(navigationAtom);
  const onClick = useCallback(() => {
    setNavigation((prev) => {
      if (side === "left") {
        logEvent("Scheduler V2 Left Panel Toggled");
        return { ...prev, leftPanelOpen: !prev.leftPanelOpen };
      }

      logEvent("Scheduler V2 Right Panel Toggled");
      return { ...prev, rightPanelOpen: !prev.rightPanelOpen };
    });
  }, [setNavigation, side, logEvent]);

  return (
    <Button
      icon={side === "left" ? atlasSidebarLeft : atlasSidebarRight}
      onClick={onClick}
      size="small"
      isGhost
    />
  );
}
