import {
  ComboboxPopoverWithShortcuts,
  ComboboxPopoverWithShortcutsProps,
  ComboboxTabsPanel,
  ComboboxTabsPanelProps,
  ComboboxWithTabsProvider,
  ComboboxWithTabsProviderProps,
} from "./ComboboxWithTabs";

// Export the component object
export const ComboboxWithTabs = {
  Root: ComboboxWithTabsProvider,
  Provider: ComboboxWithTabsProvider,
  Panel: ComboboxTabsPanel,
  Popover: ComboboxPopoverWithShortcuts,
};

export type {
  ComboboxPopoverWithShortcutsProps,
  ComboboxTabsPanelProps,
  ComboboxWithTabsProviderProps,
};
