import { useCalendarOptions } from "./options";
import { useCalendarSettings } from "./settings";
import { useDaysToRender } from "./useDaysToRender";

export type ColumnType = "day" | "calendar";

/**
 * Surface the column type to consumers based on view and dayViewType.
 * This only uses calendar settings (from Jotai), so consumers can access this above the calendar context.
 */
export function useColumnType(): ColumnType {
  const { view, dayViewType } = useCalendarSettings();

  if (view === "day" && dayViewType !== "day") {
    return "calendar";
  }

  return "day";
}

/**
 * Return the column ids and the number of columns to render.
 * If columnType is `day` then the columnId is an ISO date string.
 * If columnType is `calendar` then the columnId is a calendar id.
 */
export function useColumns(): {
  columnIds: string[];
  columnType: ColumnType;
} {
  const { calendarsGrouping, dayViewCalendarIds } = useCalendarOptions();
  const { view, dayViewType } = useCalendarSettings();
  const days = useDaysToRender();

  if (view === "week") {
    const columnIds = days.map((day) => day.toISO());

    return {
      columnIds,
      columnType: "day",
    };
  }

  if (dayViewType === "calendar_and_grouping" && calendarsGrouping) {
    const columnIds = calendarsGrouping.flatMap((g) => g.calendarIds);

    return {
      columnIds,
      columnType: "calendar",
    };
  }

  if (dayViewType === "calendar") {
    return {
      columnIds: dayViewCalendarIds,
      columnType: "calendar",
    };
  }

  return {
    columnIds: [days[0].toISO()],
    columnType: "day",
  };
}
