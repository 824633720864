import { useRouter } from "next/router";
import { useMemo } from "react";

/**
 * Generate a link to a candidate's profile given a guideId
 * Takes into account current router path to persist table filters if present
 */
export function useDashboardLinkToCandidateProfile({
  guideId,
}: {
  guideId: string;
}) {
  const router = useRouter();

  return useMemo(() => {
    const currentUrl = new URL(
      router.asPath,
      process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT
    );

    const baseUrl = currentUrl.pathname.split("?")[0];

    if (baseUrl === "/candidates") {
      const params = new URLSearchParams(currentUrl.search);

      params.set("guideId", guideId);

      return `${
        process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT
      }/candidates?${params.toString()}`;
    }

    return `${process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT}/candidates?guideId=${guideId}`;
  }, [router, guideId]);
}
