import { useLazyQuery } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { atom, useAtom, useSetAtom } from "jotai";
import { isEqual } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { usePrevious } from "react-use";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import {
  FetchedInterviewerCalendarEvent as Event,
  FetchedInterviewerCalendarEvent,
  FetchedInterviewerCalendarEvents,
  FetchedInterviewerCalendarEventWithCalendarId,
} from "../utils/types";

const GET_CALENDARS_AND_EVENTS_QUERY = gql(`
  query GetCalendarsAndEventsForInterviewerSelectV2Query(
    $userMembershipIds: [String!]!
    $startTime: String
    $endTime: String
    $schedulingRequestId: String
    $includeTransparentEvents: Boolean
  ) {
    currentOrganization {
      id
      getInterviewerCalendarsAndEvents(
        userMembershipIds: $userMembershipIds
        startTime: $startTime
        endTime: $endTime
        schedulingRequestId: $schedulingRequestId
        includeTransparentEvents: $includeTransparentEvents
      ) {
        ...CalendarForFetchedCalendarEvents
      }
    }
  }
`);

/** Combine old and new events, uniqued by eventId */
function combineEvents(oldEvents: Event[] | undefined, newEvents: Event[]) {
  if (!oldEvents) {
    return newEvents;
  }

  const map: Record<string, Event> = {};
  oldEvents.forEach((event) => {
    map[event.id] = event;
  });
  newEvents.forEach((event) => {
    map[event.id] = event;
  });

  return Object.values(map);
}

export type UseFetchedInterviewerCalendarEventsProps = {
  userMembershipIds: string[];
  startTime: DateTime | null;
  endTime: DateTime | null;
  schedulingRequestId?: string | null;
  skip?: boolean;
  /** List of interviews you are currently editing to filter out */
  filterInterviews?: {
    googleEventId: string | null;
  }[];
};

/** Filter out events that already exist */
function useFilterEvents({
  filterInterviews,
}: {
  filterInterviews?: UseFetchedInterviewerCalendarEventsProps["filterInterviews"];
}) {
  /** List of google events that we are currently scheduling */
  const alreadyScheduledInterviewGoogleCalIds = useMemo(() => {
    return (
      filterInterviews
        ?.map((i) => i.googleEventId)
        .filter(filterOutNullsAndUndefined) ?? []
    );
  }, [filterInterviews]);

  const filterEvents = useCallback(
    (event: FetchedInterviewerCalendarEvent) => {
      // Filter out google events for interviews that we are currently scheduling
      return !alreadyScheduledInterviewGoogleCalIds.includes(event.iCalUID);
    },
    [alreadyScheduledInterviewGoogleCalIds]
  );

  return filterEvents;
}

const interviewerCalendarEventsAtom = atom<FetchedInterviewerCalendarEvents>(
  {}
);

/**
 * Fetch and cache events for a list of userMembershipIds for a time frame
 * Returns the raw data keyed by userMembershipId, a loading state, and a refetch function
 */
export const useFetchedInterviewerCalendarEvents = ({
  userMembershipIds,
  startTime,
  endTime,
  schedulingRequestId,
  skip = false,
  filterInterviews,
}: UseFetchedInterviewerCalendarEventsProps): {
  calendarEvents: FetchedInterviewerCalendarEvents;
  loading: boolean;
  refetch: () => void;
} => {
  const [interviewerCalendarEvents, setInterviewerCalendarEvents] = useAtom(
    interviewerCalendarEventsAtom
  );
  const filterEvents = useFilterEvents({ filterInterviews });

  const [fetchCalendarEvents, { loading }] = useLazyQuery(
    GET_CALENDARS_AND_EVENTS_QUERY,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const events =
          data.currentOrganization?.getInterviewerCalendarsAndEvents;

        if (events) {
          setInterviewerCalendarEvents((prev) => ({
            ...prev,
            ...events.reduce((acc, calendar) => {
              return {
                ...acc,
                [calendar.userMembershipId]: combineEvents(
                  prev[calendar.userMembershipId],
                  calendar.events
                ).filter(filterEvents),
              };
            }, {}),
          }));
        }
      },
    }
  );

  const prevStartTime = usePrevious(startTime);
  const prevEndTime = usePrevious(endTime);
  const prevUserMembershipIds = usePrevious(userMembershipIds);

  const refetch = useCallback(() => {
    if (!skip && userMembershipIds.length > 0) {
      try {
        fetchCalendarEvents({
          variables: {
            userMembershipIds,
            startTime: startTime?.toISO() ?? null,
            endTime: endTime?.toISO() ?? null,
            schedulingRequestId: schedulingRequestId ?? null,
            includeTransparentEvents: true,
          },
        });
      } catch (e) {
        console.error("Failed to fetch calendars and events");
      }
    }
  }, [
    fetchCalendarEvents,
    userMembershipIds,
    startTime,
    endTime,
    schedulingRequestId,
    skip,
  ]);

  useEffect(() => {
    const startTimeChanged = startTime
      ? !prevStartTime || !startTime.equals(prevStartTime)
      : !!prevStartTime;
    const endTimeChanged = endTime
      ? !prevEndTime || !endTime.equals(prevEndTime)
      : !!prevEndTime;
    if (
      !skip &&
      userMembershipIds.length > 0 &&
      // need to check they have as calendarIds will usually have a dependency on `userCalendarEvents`
      // which will cause infinite loop of renders
      (!isEqual(prevUserMembershipIds, userMembershipIds) ||
        startTimeChanged ||
        endTimeChanged)
    ) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMembershipIds, startTime, endTime]);

  return useMemo(
    () => ({ calendarEvents: interviewerCalendarEvents, loading, refetch }),
    [interviewerCalendarEvents, loading, refetch]
  );
};

/**
 * Wrapper around useFetchedInterviewerCalendarEvents that converts it to an array
 * with calendarId set to useMembershipId
 */
export function useFetchedInterviewerCalendarEventsFlat(
  props: UseFetchedInterviewerCalendarEventsProps
): {
  calendarEvents: FetchedInterviewerCalendarEventWithCalendarId[];
  loading: boolean;
  refetch: () => void;
} {
  const { calendarEvents, loading, refetch } =
    useFetchedInterviewerCalendarEvents(props);
  const allUserMembershipIds = useMemo(() => {
    return Object.keys(calendarEvents);
  }, [calendarEvents]);
  const allFetchedInterviewerCalendarEventsWithCalendarId = useMemo(() => {
    return allUserMembershipIds.flatMap(
      (userMembershipId) =>
        calendarEvents[userMembershipId]?.map((e) => ({
          ...e,
          // Set calendarId to userMembershipId
          calendarId: userMembershipId,
        })) ?? []
    );
  }, [allUserMembershipIds, calendarEvents]);

  return useMemo(
    () => ({
      calendarEvents: allFetchedInterviewerCalendarEventsWithCalendarId,
      loading,
      refetch,
    }),
    [allFetchedInterviewerCalendarEventsWithCalendarId, loading, refetch]
  );
}

/** Update a single interviewer event in the cache */
export function useUpdateFetchedInterviewerEvent() {
  const setInterviewerCalendarEvents = useSetAtom(
    interviewerCalendarEventsAtom
  );

  return useCallback(
    (
      {
        eventId,
        userMembershipId,
      }: { eventId: string; userMembershipId: string },
      event: Partial<FetchedInterviewerCalendarEvent>
    ) => {
      setInterviewerCalendarEvents((prev) => {
        return {
          ...prev,
          [userMembershipId]: prev[userMembershipId].map((prevEvent) => {
            if (prevEvent.iCalUID === eventId) {
              return {
                ...prevEvent,
                ...event,
              };
            }

            return prevEvent;
          }),
        };
      });
    },
    [setInterviewerCalendarEvents]
  );
}
