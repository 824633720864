import { getLocationTypeFromConferencingType } from "client/components/conferencing/utils/mapping";
import { gql } from "generated/graphql-codegen";
import {
  RollingDateRangeSetting,
  TimeUnit,
} from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { SelfScheduleRequestForCreate } from "shared/self-schedule/types";
import useMutation from "utils/useMutation";

import { mapSchedulingHoursToGraphqlInput } from "../__utils/mapping";

const UPDATE_SELF_SCHEDULE_REQUEST = gql(`
  mutation UpdateSelfScheduleRequest($input: UpdateSelfScheduleRequestInput!) {
    updateSelfScheduleRequest(input: $input) {
      code
      success
      message
      selfScheduleRequest {
        id
        ...SelfScheduleRequestForBlock
      }
    }
  }
`);

export function useUpdateSelfScheduleRequest({
  selfScheduleRequestId,
}: {
  selfScheduleRequestId: string;
}) {
  const [update, mutationResult] = useMutation(UPDATE_SELF_SCHEDULE_REQUEST);

  const wrappedUpdate = useCallback(
    async ({
      data,
      onCompleted,
    }: {
      data: SelfScheduleRequestForCreate;
      onCompleted?: () => void;
    }) => {
      const { interviewSettings, schedulingSettings } = data;
      const { locationSettings } = interviewSettings;

      await update({
        variables: {
          input: {
            selfScheduleRequestId,
            interviewSettings: {
              duration: interviewSettings.duration,
              title: interviewSettings.title,
              interviewId: interviewSettings.interview?.id,
              interviewerId: interviewSettings.interviewer.id,
              ...(locationSettings && {
                locationSettings: {
                  hostUserMembershipId: locationSettings.hostUserMembershipId,
                  service: getLocationTypeFromConferencingType(
                    locationSettings.service
                  ),
                },
              }),
              isPrivate: interviewSettings.isPrivate,
              addToCalendarId: interviewSettings.addToCalendarId,
            },
            selfSchedulingSettings: {
              schedulingHours: mapSchedulingHoursToGraphqlInput(
                schedulingSettings.schedulingHours
              ),
              preMeetingBuffer: {
                unit: schedulingSettings.preMeetingBuffer.unit as TimeUnit,
                value: schedulingSettings.preMeetingBuffer.value,
              },
              postMeetingBuffer: {
                unit: schedulingSettings.postMeetingBuffer.unit as TimeUnit,
                value: schedulingSettings.postMeetingBuffer.value,
              },
              minimumNotice: {
                unit: schedulingSettings.minimumNotice.unit as TimeUnit,
                value: schedulingSettings.minimumNotice.value,
              },
              rollingDateRange: {
                days: schedulingSettings.rollingDateRange.days,
                setting: schedulingSettings.rollingDateRange
                  .setting as RollingDateRangeSetting,
              },
            },
          },
        },
        onCompleted,
      });
    },
    [selfScheduleRequestId, update]
  );

  return useMemo(
    (): [typeof wrappedUpdate, typeof mutationResult] => [
      wrappedUpdate,
      mutationResult,
    ],
    [wrappedUpdate, mutationResult]
  );
}
