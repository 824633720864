import {
  getInterviewerSlotWithSchedulingData,
  useInterviewerSlotFetchedData,
} from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { getInterviewerOverloadedData } from "client/components/interviewer-slots/load/utils/helpers";
import { UserMembershipWithSchedulingData } from "client/components/interviewer-slots/utils/types";
import { useMemo } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useStagedInterviews } from "./interviews";

export type InterviewsWithOverloadsMap = {
  [interviewId: string]: UserMembershipWithSchedulingData[];
};

export function useInterviewsWithOverloadsMap() {
  const interviews = useStagedInterviews();
  const slotFetchedData = useInterviewerSlotFetchedData();

  return useMemo(() => {
    return interviews.reduce<InterviewsWithOverloadsMap>((acc, interview) => {
      const slotsWithCalculations = interview.interviewerSlots.map(
        (interviewerSlot) => {
          return getInterviewerSlotWithSchedulingData({
            ...slotFetchedData,
            interviewerSlot,
            interview,
          });
        }
      );
      const allUserMembershipsWithCalculations = slotsWithCalculations.flatMap(
        (slot) =>
          [
            slot.interviewer?.userMembership,
            slot.shadowingInterviewer?.userMembership,
          ].filter(filterOutNullsAndUndefined)
      );
      const overloadedUserMemberships =
        allUserMembershipsWithCalculations.filter((userMembership) => {
          if (!userMembership.loadData) {
            return false;
          }

          const { accountOverloads, poolOverloads } =
            getInterviewerOverloadedData({
              loadData: userMembership.loadData,
              hasBeenSelected: true,
            });

          return (
            accountOverloads.length > 0 ||
            (poolOverloads && poolOverloads.length > 0)
          );
        });

      return {
        ...acc,
        [interview.id]: overloadedUserMemberships,
      };
    }, {});
  }, [interviews, slotFetchedData]);
}
