import "client/styles/main.sass";

import { ApolloProvider } from "@apollo/client";
import AtlasProvider from "@resource/atlas/provider/Provider";
import * as Sentry from "@sentry/nextjs";
import { useUser } from "auth/useUser";
import { AutomationToastProvider } from "client/app/extension/__components/AutomationToastProvider";
import { ExtensionAuthenticationProvider } from "client/app/extension/__components/ExtensionAuthenticationProvider";
import { ExtensionControllerProvider } from "client/app/extension/__components/ExtensionControllerProvider";
import { ExtensionLayout } from "client/app/extension/__components/ExtensionLayout";
import { PreventRenderUntilExtensionOpened } from "client/app/extension/__components/PreventRenderUntilExtensionOpened";
import { UserCountsProvider } from "client/app/extension/__components/UserCountsProvider";
import { AssistantProvider } from "client/components/generic/context/AssistantContext";
import { CustomUserProviders } from "client/components/providers/CustomUserProviders";
import { TOUR_Z_INDICES } from "client/components/tours/TourRenderer";
import { AdvancedInterviewerTagsIntroTour } from "client/components/tours/tours/AdvancedInterviewerTagsIntroTour";
import { withLDProvider } from "client/lib/launchdarkly";
import { trpc } from "client/trpc";
import { IdentifyUserWrapper } from "client/utils/IdentifyUserWrapper";
import { ConnectionErrorBanner } from "components/Generic/ConnectionErrorBanner";
import MultiProvider from "components/MultiProvider";
import { ShouldPeristCacheInitializer } from "components/ShouldPeristCacheInitializer";
import FeatureFlags from "generated/FeatureFlags";
import { useApollo } from "lib/apolloClient";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { LocationHistoryProvider } from "react-hooks/useLocationHistory";
import { TourContext } from "tours/TourContext";
import urljoin from "url-join";
import { withAppLayout } from "utils/next-layout";
import routeForPathname from "utils/routing";

declare global {
  interface Window {
    FS?: {
      shutdown(): void;
      restart(): void;
    };
    /** This variable does not appear to be supported in Fullstory V1 but keeping it anyways. */
    _fs_capture_on_startup: boolean;
  }
}

// adapted from https://stackoverflow.com/a/9039885
const iOS =
  typeof window !== "undefined" &&
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((!(window as any).MSStream &&
    /iPad|iPhone|iPod/.test(window.navigator.userAgent)) ||
    // iPad on iOS 13 detection
    (window.navigator.userAgent.includes("Mac") && "ontouchend" in document));

interface SentryWrapperProps {
  children?: React.ReactNode;
}

function SentryWrapper({ children }: SentryWrapperProps) {
  const user = useUser();
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user?.email || undefined,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return <>{children}</>;
}

function App({ Component, pageProps }: AppProps) {
  const { session } = pageProps;
  const { client: apolloClient, clearClientStore } = useApollo(pageProps);

  const router = useRouter();

  const isEphemeralEnvironment = process.env.APP_ENV !== "production";

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const description =
    "Personalize your interview experience for every candidate, no matter where they are.";

  const route = routeForPathname(router.pathname);

  // Wait for the apollo client to persist to local storage
  // (if that strategy is employed)
  if (!apolloClient) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{route?.title ?? route?.name ?? "Guide"}</title>
        <meta
          name="viewport"
          content={`initial-scale=1, width=device-width${
            iOS ? ", maximum-scale=1" : "" // prevent focus on zoom, see https://stackoverflow.com/q/2989263
          }`}
        />
        <meta key="ogSiteName" property="og:site_name" content="Guide" />
        <meta key="ogType" property="og:type" content="website" />
        <meta key="ogUrl" property="og:url" content="https://app3.guide.co" />
        <meta
          key="ogTitle"
          property="og:title"
          content="Guide - Automate interview scheduling"
        />
        <meta key="description" name="description" content={description} />
        <meta
          key="ogDescription"
          property="og:description"
          content={description}
        />
        <meta
          key="ogImage"
          property="og:image"
          content={urljoin(
            process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? "",
            "/images/meta/guide-win-hires-img.jpg"
          )}
        />
        <meta
          key="twitterCard"
          name="twitter:card"
          content={urljoin(
            process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT ?? "",
            "/images/meta/guide-win-hires-img.jpg"
          )}
        />
      </Head>
      <MultiProvider
        providers={[
          <ConnectionErrorBanner key="connection_error_banner" />,
          <LocationHistoryProvider key="location_history_provider" />,
          // Apollo's types are a bit too strict, so ignore
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <ApolloProvider client={apolloClient} key="apollo_provider" />,
          <AssistantProvider key="assistant_provider" />,
          <ExtensionControllerProvider key="extension_controller_provider" />,
          <CustomUserProviders
            onLogout={async () => {
              await clearClientStore();
            }}
            key="custom_user_provider"
            session={session}
          />,
          <AutomationToastProvider key="automation_toast_provider" />,
          <UserCountsProvider key="user_counts_provider" />,
          <IdentifyUserWrapper key="identify_user" />,
          <PreventRenderUntilExtensionOpened key="prevent_render_until_extension_opened" />,
          <SentryWrapper key="sentry_wrapper" />,
          <ExtensionAuthenticationProvider key="extension_authentication_provider" />,
          <AtlasProvider
            key="atlas_provider"
            snackbarProviderProps={{
              classes: {
                containerAnchorOriginBottomRight:
                  "guide-snackbar-anchor-bottom-right",
              },
            }}
          />,
          <ShouldPeristCacheInitializer key="should_persist_cache_initializer" />,
          <TourContext key="tour_context" />,
          <ExtensionLayout key="extension_layout" />,
        ]}
      >
        {withAppLayout(Component, pageProps)}
      </MultiProvider>
      <div
        id="tour-root"
        style={{
          position: "fixed",
          zIndex: TOUR_Z_INDICES.BACKDROP,
        }}
      />
      <AdvancedInterviewerTagsIntroTour />
      {isEphemeralEnvironment && process.env.QA_GIT_SHA && (
        <div className="text-body-lg fixed bottom-0 left-0 z-50 bg-white text-red-500 opacity-50 pointer-events-none">
          QA {process.env.QA_GIT_SHA}
        </div>
      )}
    </>
  );
}

export default trpc.withTRPC(
  withLDProvider({
    context: {
      kind: "user",
      key: "anonymous-user",
      anonymous: true,
    },
    clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_KEY || "",
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      bootstrap: "localStorage",
    },
    flags: {
      [FeatureFlags.REQUEST_SCHEDULING_PREFERENCES]: false,
      [FeatureFlags.REGIONS_ENABLED_V_2]: false, // Note: this flag is permanent as this is currently a feature only for Mongodb
      [FeatureFlags.SCHEDULING_ALGORITHM_VERSION_V_2]: false,
      [FeatureFlags.SCHEDULING_ALGORITHM_WEIGHTS_V_2]: false,
      [FeatureFlags.USE_EXTERNAL_INTERVIEWER_ALGORITHM_V_2]: false, // Note: this flag is permanent as this is currently a feature only for Duolingo
      [FeatureFlags.WATCH_GOOGLE_USER_DEACTIVATION]: false,
      [FeatureFlags.CANCEL_STAFF_SCHEDULED_INTERVIEWS_BY_CANDIDATE]: false,
      [FeatureFlags.USE_TEMPLATE_FOR_AVAILABILITY_REQUEST_FOLLOWUP]: false,
      [FeatureFlags.VIEW_GOOGLE_DIFFERENCES]: false,
      [FeatureFlags.NEW_SCHEDULER_TASK_DETAILS]: false,
      [FeatureFlags.INTERVIEWER_CALENDAR_VIEW]: false,
      [FeatureFlags.SHOW_REPORTING_LOAD_TAB]: false,
      [FeatureFlags.AUTOMATED_ACTIONS_V_1]: false,
      [FeatureFlags.ALLOW_KEY_DECRYPTION]: false,
      [FeatureFlags.INTERVIEWER_TAGS]: false,
      [FeatureFlags.TAGS_PRODUCT_TOURS]: false,
      [FeatureFlags.ALLOW_CANCEL_INTERVIEWS_DURING_RESCHEDULE_TASK_CREATION]:
        false,
      [FeatureFlags.DISABLE_RESCHEDULE_FOR_REJECTED_CANDIDATES]: false,
      [FeatureFlags.REQUIRE_GOOGLE_MEET_API]: false,
      [FeatureFlags.ASSIST_WITH_ARIA_AI]: false,
      [FeatureFlags.MULTI_DAY_ALGORITHM_SCHEDULING_PREFERENCES]: false,
      [FeatureFlags.MANUAL_INTERVIEWER_RSVP_UPDATES]: false,
      [FeatureFlags.ORGANIZATION_BLOCKED_DATES]: false,
      [FeatureFlags.REQUEST_SLACK_BOT_PERMISSIONS]: false,
      [FeatureFlags.SEND_INTERVIEWER_INVITES_VIA_SLACK]: false,
      [FeatureFlags.JUST_SCHEDULE_INTERVIEWS]: false,
      [FeatureFlags.AUTOMATED_ACTION_TOASTS]: false,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - latest LD typing doesn't like react 18 but this works fine
  })(App)
);
