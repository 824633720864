import { DateTime } from "luxon";
import { useMemo } from "react";
import { displayTimezone } from "shared/utils/timezones";

type Interview = {
  id: string;
  startTime: GraphQL_DateTime;
  endTime: GraphQL_DateTime;
};

export function useFormattedDateTimesForInterviewItem({
  interview,
  hideStartTimeNotification,
  timezone,
}: {
  interview: Interview;
  hideStartTimeNotification?: boolean;
  timezone?: string | null;
}) {
  const formattedDateTime = useMemo(() => {
    const tz = timezone ?? DateTime.local().zoneName;
    const startTimeWithTimezone = DateTime.fromISO(interview.startTime, {
      zone: tz,
    });
    const endTimeWithTimezone = DateTime.fromISO(interview.endTime, {
      zone: tz,
    });
    const formattedDate = startTimeWithTimezone.toFormat("ccc, LLL d");
    const day = startTimeWithTimezone.toFormat("d");
    const dayName = startTimeWithTimezone.toFormat("ccc");
    const friendlyTimezone = displayTimezone(tz, "abbreviation", true);
    const formattedTimeRange = `${startTimeWithTimezone.toFormat(
      "h:mm"
    )}-${endTimeWithTimezone.toFormat("h:mma")} ${friendlyTimezone}`;
    let startsIn = null;
    const isInPast = endTimeWithTimezone.diffNow().as("milliseconds") < 0;
    if (!hideStartTimeNotification) {
      const differenceInMinutes = Math.floor(
        startTimeWithTimezone.diffNow().as("minutes")
      );

      if (differenceInMinutes > 0 && differenceInMinutes < 60) {
        startsIn = differenceInMinutes;
      }
    }

    return {
      formattedStartDate: formattedDate,
      formattedTime: formattedTimeRange,
      day,
      dayName,
      startsIn,
      isInPast,
      rawStartTime: startTimeWithTimezone,
      rawEndTime: endTimeWithTimezone,
    };
  }, [
    interview.startTime,
    interview.endTime,
    timezone,
    hideStartTimeNotification,
  ]);

  return formattedDateTime;
}
