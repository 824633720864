/**
 * Use the panel review page form state
 *
 * There is some complexity here with a cache to manage the correct defaults
 * for when the user navigates back to the scheduler after already making changes.
 * Since the form mounts when the review page view mounts, navigation will
 * unmount the form and reset the form state. The cache will preserve any
 * changes they made to the settings prior to navigation.
 *
 * An alternative is to hoist the usage of the form up above the multi step view,
 * but the review page fetches data for defaults so we want that data to exist
 * when we mount the form.
 */
import { zodResolver } from "@hookform/resolvers/zod";
import {
  mapStagedInterviewToDefaultReviewData,
  mapUserMembershipFragmentToOptionalParticipant,
} from "client/components/scheduling-review/utils/mapping";
import {
  InterviewReviewData,
  InterviewsPanelReviewFormData,
  InterviewsPanelReviewFormSchema,
} from "client/components/scheduling-review/utils/types";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import {
  CurrentUserMembershipForReviewPageSchedulingDefaultsFragment,
  ExistingScheduledInterviewForSchedulerReviewPageFragment,
  GuideForSchedulerReviewPageFragment,
  InterviewForSchedulerReviewPageFragment,
} from "generated/graphql-codegen/graphql";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { useSchedulerStateForReview } from "./useReviewPageData";

type UsePanelReviewPageProps = {
  guide: GuideForSchedulerReviewPageFragment;
  scheduledInterviews: ExistingScheduledInterviewForSchedulerReviewPageFragment[];
  currentUserMembership: CurrentUserMembershipForReviewPageSchedulingDefaultsFragment;
  interviews: InterviewForSchedulerReviewPageFragment[];
};

/** Form state for reviewing changes to a panel of interviews */
export function usePanelReviewPageFormState(props: UsePanelReviewPageProps) {
  const defaultValues = useReviewFormDefaults(props);
  const form = useForm<InterviewsPanelReviewFormData>({
    defaultValues,
    resolver: zodResolver(InterviewsPanelReviewFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // Trigger validation on mount
    form.trigger();
  }, [form]);

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState,
    {
      skipDirtyCheck: true,
    }
  );

  /** Wrapper around onChange to properly handle dirtying and validation */
  const onChange = useCallback(
    (...params: Parameters<typeof form.setValue>) => {
      const [name, value, opts] = params;

      form.setValue(name, value, {
        shouldDirty: true,
        ...opts,
      });
      // Need to manually trigger validation because of a bug with zodResolver and superRefine
      // https://github.com/react-hook-form/resolvers/issues/661
      form.trigger();
    },
    [form]
  );

  useSyncFormChangesToCache({ form });

  return {
    form,
    onChange,
    disabledTooltipContent,
  };
}

export type PanelReviewPageFormState = ReturnType<
  typeof usePanelReviewPageFormState
>;

/** Cache the form data in case user navigates back to scheduler */
const cachedReviewFormDefaultsAtom = atom<InterviewsPanelReviewFormData | null>(
  null
);

/**
 * Get default values for review form based on
 * fetched data from review and interviews in state
 *
 * Also, look to the cache to see if the user started
 * to make changes and then navigated back to the scheduler
 */
function useReviewFormDefaults({
  guide,
  scheduledInterviews,
  interviews,
  currentUserMembership,
}: UsePanelReviewPageProps): InterviewsPanelReviewFormData {
  const { interviewsForReview } = useSchedulerStateForReview();
  const cachedReviewFormDefaults = useCachedReviewFormDefaults({
    scheduledInterviews,
    interviews,
  });

  // Ensure the user still has access to their default Google calendar. TODO: Handle this more holistically.
  const addToGoogleCalendarId = useMemo(() => {
    if (
      currentUserMembership.defaultCalendarId &&
      currentUserMembership.googleCalendars?.some(
        (calendar) => calendar.id === currentUserMembership.defaultCalendarId
      )
    ) {
      return currentUserMembership.defaultCalendarId;
    }
    return undefined;
  }, [
    currentUserMembership.defaultCalendarId,
    currentUserMembership.googleCalendars,
  ]);

  return useMemo((): InterviewsPanelReviewFormData => {
    if (cachedReviewFormDefaults) {
      return cachedReviewFormDefaults;
    }

    return {
      optionalAttendeeUserMemberships:
        guide.recruiter && guide.recruiter.addAsOptionalParticipantForScheduling
          ? [mapUserMembershipFragmentToOptionalParticipant(guide.recruiter)]
          : [],
      addToGoogleCalendarId,
      sendNotifications: true,
      interviews: interviewsForReview.map(
        (scheduledInterview): InterviewReviewData => {
          const existingScheduledInteview = scheduledInterviews.find(
            (si) => si.id === scheduledInterview.id
          );
          const interview = interviews.find(
            (i) => i.id === scheduledInterview.interview?.id
          );

          return mapStagedInterviewToDefaultReviewData({
            scheduledInterview,
            status: scheduledInterview.status,
            existingScheduledInterview: existingScheduledInteview ?? null,
            interview: interview ?? null,
          });
        }
      ),
    };
  }, [
    cachedReviewFormDefaults,
    guide.recruiter,
    addToGoogleCalendarId,
    interviewsForReview,
    scheduledInterviews,
    interviews,
  ]);
}

/**
 * Get review form defaults out of the cache in case the user started to work
 * then navigated back to the scheduler.
 * Properly manage interviews they might have added or removed
 * since they first landed on the review page.
 */
function useCachedReviewFormDefaults({
  scheduledInterviews,
  interviews,
}: Pick<UsePanelReviewPageProps, "scheduledInterviews" | "interviews">) {
  const cachedReviewFormDefaults = useAtomValue(cachedReviewFormDefaultsAtom);
  const { interviewsForReview } = useSchedulerStateForReview();

  return useMemo(() => {
    if (!cachedReviewFormDefaults) {
      return null;
    }

    // If we have cached form data, use that to preserve any changes made
    const defaultInterviewsCombinedWithCache = interviewsForReview.map(
      (scheduledInterview) => {
        const cachedInterview = cachedReviewFormDefaults.interviews.find(
          (ci) => ci.id === scheduledInterview.id
        );

        if (cachedInterview) {
          // If we already started managing an interview, it should be in the cache
          // Return that so that any changes already made are preserved
          return cachedInterview;
        }

        const existingScheduledInteview = scheduledInterviews.find(
          (si) => si.id === scheduledInterview.id
        );
        const interview = interviews.find(
          (i) => i.id === scheduledInterview.interview?.id
        );

        // If no cached interview, then we added a new one since last on the review page
        // Map it with the defaults as expected
        return mapStagedInterviewToDefaultReviewData({
          scheduledInterview,
          status: scheduledInterview.status,
          existingScheduledInterview: existingScheduledInteview ?? null,
          interview: interview ?? null,
        });
      }
    );

    return {
      ...cachedReviewFormDefaults,
      interviews: defaultInterviewsCombinedWithCache,
    };
  }, [
    cachedReviewFormDefaults,
    interviews,
    interviewsForReview,
    scheduledInterviews,
  ]);
}

/** Sync form changes to the cache to properly manage the user navigating back */
function useSyncFormChangesToCache({
  form,
}: {
  form: UseFormReturn<InterviewsPanelReviewFormData>;
}) {
  const setCachedReviewFormDefaults = useSetAtom(cachedReviewFormDefaultsAtom);

  useEffect(() => {
    const subscription = form.watch((newValue) => {
      const parsedFormData =
        InterviewsPanelReviewFormSchema.safeParse(newValue);

      if (parsedFormData.success) {
        setCachedReviewFormDefaults(parsedFormData.data);
      } else {
        console.error(
          "Review page subscription failed. Form data does not match schema."
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [form, setCachedReviewFormDefaults]);
}
