import { atlasRingCheck, atlasWriteNote } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Popover } from "@resource/atlas/popover";
import { strings } from "@resource/common";
import { TextWithLinks } from "client/components/generic/misc/TextWithLinks";
import { CandidateDetails } from "client/components/guide/candidate-details/CandidateDetails";
import { UnscheduledInterviewCard } from "client/components/interview-requirements/UnscheduledInterviewCard/display/UnscheduledInterviewCard";
import { mapInterviewRequirementToUnscheduledInterviewForCard } from "client/components/interview-requirements/UnscheduledInterviewCard/utils/mapping";
import {
  filterInterviewerRequirements,
  filterInterviewRequirements,
} from "client/utils/interviewRequirements";
import { CondensedRequestInfoRow } from "components/RequestInfoRow/CondensedRequestInfoRow";
import { gql } from "generated/graphql-codegen";
import { SchedulingRequestForTaskDetailsFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

gql(`
  fragment SchedulingRequestForTaskDetails on SchedulingRequest {
    id
    guide {
      ...GuideForCandidateDetails
    }
    notes
    interviewPanelRequirement {
      id
      interviewRequirements {
        ...InterviewRequirementForUnscheduledInterviewCard
        manuallyRemovedAt
      }
    }
  }
`);

export function SchedulingTaskDetails({
  schedulingRequest,
}: {
  schedulingRequest: SchedulingRequestForTaskDetailsFragment;
}) {
  return (
    <div className="space-y-2">
      <CandidateDetails guide={schedulingRequest.guide} />
      <RequirementsDisplay
        interviewRequirements={
          schedulingRequest.interviewPanelRequirement.interviewRequirements
        }
      />
      {schedulingRequest.notes && (
        <CondensedRequestInfoRow
          childContainerClassName="flex-col gap-2 group items-start"
          multiline
          icon={atlasWriteNote}
        >
          <TextWithLinks text={schedulingRequest.notes} />
        </CondensedRequestInfoRow>
      )}
    </div>
  );
}

function RequirementsDisplay({
  interviewRequirements,
}: {
  interviewRequirements: SchedulingRequestForTaskDetailsFragment["interviewPanelRequirement"]["interviewRequirements"];
}) {
  /**
   * client side filter of removed interview and interviewer requirements
   * we really should just have a param we can pass to the server to filter or not but that's a bigger refactor
   */
  const filteredRequirements = useMemo(() => {
    return filterInterviewRequirements({
      interviewRequirements: interviewRequirements.map((i) => ({
        ...i,
        interviewerRequirements: filterInterviewerRequirements(
          i.interviewerRequirements
        ).interviewerRequirements,
      })),
    }).interviewRequirements;
  }, [interviewRequirements]);
  const mappedInterviewRequirements = useMemo(() => {
    return filteredRequirements.map(
      mapInterviewRequirementToUnscheduledInterviewForCard
    );
  }, [filteredRequirements]);

  if (!mappedInterviewRequirements.length) {
    return null;
  }

  return (
    <CondensedRequestInfoRow
      icon={atlasRingCheck}
      action={
        <Popover.Root placement="right">
          <Popover.Trigger>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link as="button">View</Link>
          </Popover.Trigger>
          <Popover.Content portal>
            <div className="space-y-2 w-[21.5rem]">
              <div className="space-y-2">
                {mappedInterviewRequirements.map((i) => {
                  return (
                    <UnscheduledInterviewCard
                      key={i.id}
                      unscheduledInterview={i}
                    />
                  );
                })}
              </div>
            </div>
          </Popover.Content>
        </Popover.Root>
      }
    >
      Schedule{" "}
      {strings.pluralize("interview", mappedInterviewRequirements.length)}
    </CondensedRequestInfoRow>
  );
}
