import { SchedulingRequestRescheduleReason } from "generated/graphql-codegen/graphql";

export const RescheduleReasonDisplayInfo = {
  [SchedulingRequestRescheduleReason.CANDIDATE_CHANGES]:
    "Candidate changes (i.e. availability, sick, never confirmed)",
  [SchedulingRequestRescheduleReason.INTERVIEWER_AVAILABILITY]:
    "Interviewer availability change",
  [SchedulingRequestRescheduleReason.INTERVIEWER_DECLINED]:
    "Interviewer declined last minute",
  [SchedulingRequestRescheduleReason.INTERVIEWER_SWAP]: "Interviewer swap",
  [SchedulingRequestRescheduleReason.RECRUITER]:
    "Recruiter (wrong interviewer, wrong time, etc.)",
};
