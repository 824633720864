import { $insertNodeToNearestRoot } from "@lexical/utils";
import {
  $copyNode,
  $createNodeSelection,
  $createParagraphNode,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  $setSelection,
  LexicalNode,
} from "lexical";

export function $isEmptyElementNode<T extends LexicalNode>(node: T) {
  let isEmpty = false;

  if ($isElementNode(node)) {
    const children = node.getChildren();
    if (
      children.length === 0 ||
      (children.length === 1 &&
        $isTextNode(children[0]) &&
        children[0].getTextContent().trim() === "")
    )
      isEmpty = true;
  }

  return isEmpty;
}

export function $insertOrReplaceBlockAtRoot<T extends LexicalNode>(
  node: T,
  selectNode = false
): T {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) return node;
  const focusNode = selection.focus.getNode();

  const topLevelElement = focusNode.getTopLevelElement();
  if ($isElementNode(topLevelElement) && $isEmptyElementNode(topLevelElement)) {
    const wasLastElement =
      topLevelElement.getParentOrThrow().getLastChildOrThrow() ===
      topLevelElement;

    // insert a paragraph after if it was the last one
    if (wasLastElement) topLevelElement.insertAfter($createParagraphNode());
    // replace the element
    topLevelElement.replace(node);

    if (selectNode) {
      const nodeSelection = $createNodeSelection();
      nodeSelection.add(node.getKey());
      $setSelection(nodeSelection);
    }
    return node.getLatest();
  }

  // insert the element
  $insertNodeToNearestRoot(node);

  if (selectNode) {
    const nodeSelection = $createNodeSelection();
    nodeSelection.add(node.getKey());
    $setSelection(nodeSelection);
  }
  return node.getLatest();
}

export function $copyNodeRecursively<T extends LexicalNode>(node: T): T {
  const copiedNode = $copyNode(node);

  if ($isElementNode(node) && $isElementNode(copiedNode)) {
    const copiedChildren = node.getChildren().map($copyNodeRecursively);
    copiedNode.append(...copiedChildren);
  }

  return copiedNode;
}
