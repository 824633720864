import { guideBaseVariableKeys } from "modules/lexical/shared/utils/constants";
import { GuideBaseVariableSet } from "modules/lexical/shared/utils/types";
import { createVariableSets } from "modules/lexical/shared/variables";

const {
  nameSet: guideBaseNameSet,
  rendererSet: guideBaseRendererSet,
  specSet: guideBaseSpecSet,
} = createVariableSets(guideBaseVariableKeys);

export { guideBaseNameSet, guideBaseRendererSet, guideBaseSpecSet };

export type { GuideBaseVariableSet };
