import { zodResolver } from "@hookform/resolvers/zod";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { RsvpFormData, RsvpFormSchema } from "../types";

export function useEditRsvpState(defaultValues?: RsvpFormData) {
  const form = useForm<RsvpFormData>({
    defaultValues,
    resolver: zodResolver(RsvpFormSchema),
  });

  useEffect(() => {
    form.trigger();
  }, [form]);

  const onChange = useCallback(
    (...params: Parameters<typeof form.setValue>) => {
      const [name, value, opts] = params;

      form.setValue(name, value, {
        shouldDirty: true,
        ...opts,
      });
      // Need to manually trigger validation because of a bug with zodResolver and superRefine
      // https://github.com/react-hook-form/resolvers/issues/661
      form.trigger();
    },
    [form]
  );

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState
  );

  return useMemo(
    () => ({
      form,
      onChange,
      disabledTooltipContent,
    }),
    [form, onChange, disabledTooltipContent]
  );
}

export type EditRsvpState = ReturnType<typeof useEditRsvpState>;
