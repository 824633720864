type LoomProps = {
  videoUrl: string;
  autoplay?: boolean;
};

function Loom({ videoUrl, autoplay = false }: LoomProps) {
  return (
    <iframe
      src={`${videoUrl.replace(
        "share",
        "embed"
      )}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true&hide_controls=true${
        autoplay ? "&autoplay=1" : ""
      }`}
      title={videoUrl}
      frameBorder="0"
      allowFullScreen
      width="100%"
      height="315"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  );
}

export default Loom;
