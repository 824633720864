/* eslint-disable import/prefer-default-export */
import { useTabState as useAriakitTabState } from "ariakit";

import { createDefaultProps } from "../__utils/react";
import type { AtlasTabsState, AtlasTabsStateProps } from "./types";

const DEFAULT_PROPS = createDefaultProps<AtlasTabsStateProps>()({
  selectOnMove: false,
});

/**
 * Creates a tabs state.
 *
 * @example
 * const tabs = useTabsState();
 * return <Tabs.Root state={tabs}>...</Tabs.Root>;
 */
export function useTabsState({
  selectOnMove = DEFAULT_PROPS.selectOnMove,
  ...props
}: AtlasTabsStateProps = {}) {
  const tabs: AtlasTabsState = useAriakitTabState({
    selectOnMove,
    ...props,
  });
  return tabs;
}
