import { useLazyQuery } from "@apollo/client";
import { atlasLogoGuideColor } from "@resource/atlas/icons";
import { useCalendarSettings } from "client/components/calendar/settings";
import { CalendarEventInput } from "client/components/calendar/types";
import { gql } from "generated/graphql-codegen";
import { isEqual, uniqBy } from "lodash";
import { useEffect, useMemo } from "react";
import { usePrevious } from "react-use";

import { CreateOrUpdateAvailabilityState } from "./CreateOrUpdateAvailabilityView";

const GET_CALENDARS_AND_EVENTS_QUERY = gql(`
  query GetCalendarsAndEventsForInterviewerOverlayCalendarsQuery(
    $userMembershipIds: [String!]!
    $startTime: String
    $endTime: String
    $includeTransparentEvents: Boolean
  ) {
    currentOrganization {
      id
      getInterviewerCalendarsAndEvents(
        userMembershipIds: $userMembershipIds
        startTime: $startTime
        endTime: $endTime
        includeTransparentEvents: $includeTransparentEvents
      ) {
        id
        name
        userMembershipId
        events {
          id
          calendarId
          title
          start {
            date
            dateTime
          }
          end {
            date
            dateTime
          }
          responseStatus
          guideScheduledInterview {
            id
            title
          }
        }
      }
    }
  }
`);

export function useFetchInterviewerCalendarEvents({
  state,
}: {
  state: CreateOrUpdateAvailabilityState;
}) {
  const { setInterviewerCalendarEvents, interviewers } = state;
  const userMembershipIds = useMemo(() => {
    return interviewers.map((interviewer) => interviewer.id);
  }, [interviewers]);
  const { selectedDay } = useCalendarSettings();
  const startTime = useMemo(() => {
    return selectedDay.startOf("week");
  }, [selectedDay]);

  const [fetchCalendarEvents] = useLazyQuery(GET_CALENDARS_AND_EVENTS_QUERY, {
    onCompleted: (data) => {
      const events = data.currentOrganization?.getInterviewerCalendarsAndEvents;

      if (events) {
        setInterviewerCalendarEvents((prev) =>
          uniqBy(
            [
              ...prev,
              ...events.flatMap((calendar): CalendarEventInput[] => {
                return calendar.events.map(
                  (event): CalendarEventInput => ({
                    calendarId: calendar.userMembershipId,
                    id: event.id,
                    end: event.end,
                    start: event.start,
                    title: event?.guideScheduledInterview?.title ?? event.title,
                    icon: event?.guideScheduledInterview
                      ? atlasLogoGuideColor
                      : undefined,
                    responseStatus: event.responseStatus,
                  })
                );
              }),
            ],
            "id"
          )
        );
      }
    },
  });
  const prevStartTime = usePrevious(startTime);
  const prevUserMembershipIds = usePrevious(userMembershipIds);

  useEffect(() => {
    const startTimeChanged = startTime
      ? !prevStartTime || !startTime.equals(prevStartTime)
      : !!prevStartTime;
    if (
      userMembershipIds.length > 0 &&
      // need to check they have as calendarIds will usually have a dependency on `userCalendarEvents`
      // which will cause infinite loop of renders
      (!isEqual(prevUserMembershipIds, userMembershipIds) || startTimeChanged)
    ) {
      try {
        fetchCalendarEvents({
          variables: {
            userMembershipIds,
            startTime: startTime?.toISO() ?? null,
            endTime: startTime?.plus({ weeks: 4 }).toISO() ?? null,
            includeTransparentEvents: true,
          },
        });
      } catch (e) {
        console.error("Failed to fetch calendars and events");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMembershipIds, startTime]);
}

export function FetchInterviewerCalendarEventsPlugin({
  state,
}: {
  state: CreateOrUpdateAvailabilityState;
}) {
  useFetchInterviewerCalendarEvents({
    state,
  });

  return null;
}
