import { useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { useState } from "react";
import useQuery from "utils/useQuery";

const SCHEDULING_REQUEST_COUNTS = gql(`
  query SchedulingRequestCounts {
    currentOrganization {
      id
      schedulingRequestCounts {
        all
        unassigned
        mine
        assignedToUser
      }
    }
  }
`);

const SCHEDULING_REQUEST_COUNTS_SUBSCRIPTION = gql(`
  subscription SchedulingRequestsCountSub {
    schedulingRequestCounts {
      all
      unassigned
      mine
      assignedToUser
    }
  }
`);

export interface SchedulingRequestCounts {
  all: number;
  unassigned: number;
  mine: number;
  assignedToUser: number;
}

export function useSchedulingRequestCounts({ skip }: { skip?: boolean }) {
  const [counts, setCounts] = useState<SchedulingRequestCounts | null>(null);

  useQuery(SCHEDULING_REQUEST_COUNTS, {
    fetchPolicy: "network-only",
    skip,
    onCompleted: (data) => {
      if (data?.currentOrganization?.schedulingRequestCounts) {
        setCounts(data.currentOrganization.schedulingRequestCounts);
      }
    },
  });

  useSubscription(SCHEDULING_REQUEST_COUNTS_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const newCounts = subscriptionData.data?.schedulingRequestCounts;
      if (newCounts) {
        setCounts(newCounts);
      }
    },
    skip,
  });

  return counts;
}
