import { Button } from "@resource/atlas/button/Button";
import { atlasClose } from "@resource/atlas/icons";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import TextField from "@resource/atlas/textfield/TextField";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { trackProperties } from "shared/analytics";

type Props = {
  onSendPreview: (props: { to: string }) => Promise<void>;
};
export function useSendPreviewEmailPopover({ onSendPreview }: Props) {
  const { user } = useAuthContext();
  const [to, setTo] = useState(user?.primaryEmail || "");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(
    null
  );
  const getAnchorRect = useCallback(
    () => triggerElement?.getBoundingClientRect() ?? null,
    [triggerElement]
  );
  const logEvent = useLogEvent({
    component: "useSendPreviewEmailPopover",
    project: "Send myself a test of the new message notification email",
  });
  const popoverState = usePopoverState({
    open,
    setOpen,
    getAnchorRect,
    placement: "top-end",
  });
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const onSubmit = useCallback(async () => {
    logEvent("Send A Preview Email Clicked", trackProperties.isCustomerSuccess);

    setLoading(true);

    await onSendPreview({ to });

    setLoading(false);
    popoverState.hide();
  }, [onSendPreview, to, popoverState, logEvent]);

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  const value = useMemo(
    () => ({
      setTriggerElement,
      popoverState,
      popover: (
        <Popover.Root state={popoverState}>
          <Popover.Content
            header={
              <div className="flex justify-between items-center w-full px-3 py-[0.625rem]">
                <Popover.Heading className="text-body-md-heavy">
                  Send a preview email
                </Popover.Heading>
                <Button
                  negativeMargin="right"
                  isGhost
                  icon={atlasClose}
                  onClick={() => setOpen(false)}
                  size="xs"
                />
              </div>
            }
            // TODO: be able to add footer to the form (probably add Popover.View functionality similar to dialog)
            footer={
              <div className="flex justify-end px-3 py-[0.375rem]">
                <Button
                  variant="primary"
                  onClick={onSubmit}
                  isLoading={loading}
                  type="button"
                  size="small"
                >
                  Send preview
                </Button>
              </div>
            }
          >
            <p className="text-body-md">
              Curious what the candidate will receive? Send yourself a preview
              email.
            </p>
            <div className="flex flex-col mt-4 gap-2">
              <p className="text-body-md-heavy">Send to</p>
              <TextField
                onChange={(val) => setTo(val)}
                value={to}
                inputRef={setInputRef}
                aria-label="Send to"
              />
            </div>
          </Popover.Content>
        </Popover.Root>
      ),
    }),
    [popoverState, onSubmit, loading, to]
  );

  return value;
}
