import {
  AtlasContentEditorProps,
  ContentEditor,
} from "@resource/atlas/content-editor";
import { fixedToolbarModule } from "@resource/atlas/content-editor/fixed-toolbar";
import { lineActionsModule } from "@resource/atlas/content-editor/line-actions";
import { richQuoteModule } from "@resource/atlas/content-editor/rich-quote";
import { videoModule } from "@resource/atlas/content-editor/video";
import { gifModule } from "client/guide-content/rich-blocks/gif";
import { useImageModule } from "client/guide-content/rich-blocks/image";
import { useLinkCardModule } from "client/guide-content/rich-blocks/link-card";
import { useLinkCardsCollectionModule } from "client/guide-content/rich-blocks/link-cards-collection";

import { ErrorBoundary } from "../generic/errors/ErrorBoundary";

export function GuideAutoResponseEditor({
  modules = [],
  trailingContentSlot,
  ...props
}: AtlasContentEditorProps) {
  return (
    <ErrorBoundary>
      <ContentEditor
        {...props}
        modules={[
          useImageModule(),
          videoModule,
          richQuoteModule,
          fixedToolbarModule,
          lineActionsModule,
          gifModule,
          useLinkCardModule(),
          useLinkCardsCollectionModule(),
          ...modules,
        ]}
      />
    </ErrorBoundary>
  );
}
