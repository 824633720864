/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Hovercard } from "@resource/atlas/hovercard";
import { Icon } from "@resource/atlas/icon/Icon";
import { serviceCalendar } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import FeatureFlags from "generated/FeatureFlags";
import { ScheduleInterviewForInterviewDetailsFragment } from "generated/graphql-codegen/graphql";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import { CancelInterviewsConfirmationDialog } from "../../__components/CancelInterviewsConfirmation";
import { useResync } from "../__hooks/useResyncGoogleCalendar";
import { GoogleIntegrationDifferencesModal } from "./GoogleIntegrationDifferencesModal";

/** Show the status of the Google integration for Guide scheduler customers */
export function GoogleIntegrationStatus({
  scheduledInterview,
}: {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
}) {
  const { [FeatureFlags.VIEW_GOOGLE_DIFFERENCES]: useGoogleDifferencesModal } =
    useFlags();

  const deleteInterviewConfirmationDialogStore = useDialogStore();
  const router = useRouter();
  const { resync, loading: resyncPending } = useResync({
    scheduledInterview,
  });
  const differencesDialogStore = useDialogStore();

  if (
    scheduledInterview.isCancelled ||
    !scheduledInterview.createdByGuide ||
    !scheduledInterview.guideId
  ) {
    return null;
  }

  if (
    !scheduledInterview.googleEvent ||
    !scheduledInterview.googleEvent.isValid
  ) {
    return (
      <>
        <div className="flex w-full space-x-3">
          <Icon content={serviceCalendar} className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="text-[#F33E22]">
              Google Calendar event not found
            </span>
            <span className="space-x-3">
              <Link className="!text-dark" onClick={resync}>
                {resyncPending ? "Loading..." : "Resync in Google Calendar"}
              </Link>
              <Link
                className="!text-dark"
                onClick={deleteInterviewConfirmationDialogStore.show}
              >
                Cancel in Guide
              </Link>
            </span>
          </div>
        </div>
        <CancelInterviewsConfirmationDialog
          store={deleteInterviewConfirmationDialogStore}
          scheduledInterviewIds={[scheduledInterview.id]}
          interviewers={scheduledInterview.interviewers}
          onCompleted={() => {
            router.replace(`/extension/guide/${scheduledInterview.guideId}`);
          }}
        />
      </>
    );
  }

  // Out of sync case
  if (scheduledInterview.googleEvent.hasChanges) {
    return (
      <>
        <div className="flex w-full space-x-3">
          <Icon content={serviceCalendar} className="h-5 w-5" />
          <div className="flex flex-col gap-1 flex-grow min-w-0">
            <div>
              <div className="flex gap-2">
                <div className="text-[#F33E22]">
                  Google Calendar event doesn&apos;t match Guide
                </div>
                {useGoogleDifferencesModal && (
                  <Link
                    as="button"
                    onClick={() => differencesDialogStore.show()}
                  >
                    View differences
                  </Link>
                )}
              </div>
              {scheduledInterview.googleEvent.htmlLink && (
                <div
                  className="truncate"
                  title={scheduledInterview.googleEvent.htmlLink}
                >
                  <Link
                    href={scheduledInterview.googleEvent.htmlLink}
                    className="!text-subtle truncate"
                    target="_blank"
                  >
                    {scheduledInterview.googleEvent.htmlLink}
                  </Link>
                </div>
              )}
            </div>
            {!useGoogleDifferencesModal && (
              <span className="space-x-3">
                <Link
                  as="button"
                  className="!text-dark"
                  onClick={resync}
                  disabled={resyncPending}
                >
                  {resyncPending ? "Loading..." : "Update to match Guide"}
                </Link>
                <Hovercard.Root timeout={0}>
                  <Hovercard.Anchor>
                    <Link
                      as="button"
                      className="!text-dark"
                      onClick={deleteInterviewConfirmationDialogStore.show}
                    >
                      Learn more
                    </Link>
                  </Hovercard.Anchor>
                  <Hovercard.Content>
                    <Hovercard.Description className="text-body-md space-y-2">
                      <p>
                        Looks like some changes were made outside of Guide for
                        this event. Just a heads-up, only edits done in Guide
                        will show up in the candidate&apos;s portal.
                      </p>
                      <p>
                        To update Google with the changes made in Guide, use the
                        “Update to match Guide” option.
                      </p>
                    </Hovercard.Description>
                  </Hovercard.Content>
                </Hovercard.Root>
              </span>
            )}
          </div>
        </div>
        {useGoogleDifferencesModal && (
          <GoogleIntegrationDifferencesModal
            store={differencesDialogStore}
            scheduledInterview={scheduledInterview}
            googleEvent={scheduledInterview.googleEvent}
          />
        )}
      </>
    );
  }

  return (
    <div className="flex w-full space-x-3">
      <Icon content={serviceCalendar} className="h-5 w-5" />
      {scheduledInterview.googleEvent.htmlLink ? (
        <span
          className="truncate"
          title={scheduledInterview.googleEvent.htmlLink}
        >
          <Link
            href={scheduledInterview.googleEvent.htmlLink}
            className="!text-dark"
            target="_blank"
          >
            {scheduledInterview.googleEvent.htmlLink}
          </Link>
        </span>
      ) : (
        <span>Synced with Google</span>
      )}
    </div>
  );
}
