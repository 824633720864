import { atlasCalendarCross } from "@resource/atlas/icons";
import { ColorConfig } from "client/components/calendar-v2/utils/colors";
import { CalendarEventInput } from "client/components/calendar-v2/utils/types";
import _ from "lodash";
import { useMemo } from "react";

import { useInterviews } from "../../hooks/interviews";
import { useSchedulerOrganization } from "../../hooks/useSchedulerOrganization";
import {
  CustomCalendarIds,
  getCalendarIdsForInterview,
} from "../utils/calendar-ids";
import { EventLayers } from "../utils/group-ids";

const blockedEventColorConfig: ColorConfig = {
  color: "blocked",
  eventVariant: "background",
};

export function useOrganizationBlockedEvents(): CalendarEventInput[] {
  const organization = useSchedulerOrganization();
  const stagedInterviews = useInterviews();

  return useMemo(() => {
    if (!organization.blockedDates) {
      return [];
    }
    const additionalCalendarIds = _(stagedInterviews)
      .flatMap((interview) => getCalendarIdsForInterview(interview))
      .uniq()
      .value();

    return organization.blockedDates.map(
      (blockedDate): CalendarEventInput => ({
        id: `blocked-${blockedDate.startDay.toISODate()}-${blockedDate.endDay.toISODate()}`,
        layer: EventLayers.PANEL,
        calendarId: CustomCalendarIds.BLOCKED_DATES,
        icon: atlasCalendarCross,
        start: {
          date: blockedDate.startDay.toISODate(),
        },
        end: {
          date: blockedDate.endDay.plus({ days: 1 }).toISODate(),
        },
        additionalCalendarIds,
        title: "Blocked Date",
        disableDetailsPopover: true,
        colorConfig: blockedEventColorConfig,
      })
    );
  }, [organization.blockedDates, stagedInterviews]);
}
