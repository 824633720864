import { strings } from "@resource/common";
import { DateTime } from "luxon";

import { useStartsIn } from "../__hooks/useStartsIn";

export function StartCountdown({
  startTime,
  endTime,
}: {
  startTime: DateTime;
  endTime: DateTime;
}) {
  const {
    startsInMinutes,
    isHappeningNow,
    isInPast,
    isStartingInMoreThan15Minutes,
  } = useStartsIn(startTime, endTime);

  const startsInString =
    startsInMinutes > 0
      ? `Starting in ${strings.pluralize("min", startsInMinutes)}`
      : "Starting soon";

  if (isHappeningNow) {
    return (
      <p className="text-accent text-body-sm-heavy flex-shrink-0">
        Happening now
      </p>
    );
  }

  if (isInPast || isStartingInMoreThan15Minutes) {
    return null;
  }

  return (
    <p className="text-accent text-body-sm-heavy flex-shrink-0 ml-1">
      {startsInString}
    </p>
  );
}
