import { useInterviewerSlotFetchedData } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { getUserMembershipIdsFromInterviewSlots } from "client/components/interviewer-slots/utils/misc";
import { UserMembershipForSchedulingDataFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { useStagedInterviews } from "./interviews";

export type InterviewsWithInterviewersAlreadyInterviewedMap = {
  [interviewId: string]: UserMembershipForSchedulingDataFragment[];
};

export function useInterviewersAlreadyInterviewedMap() {
  const interviews = useStagedInterviews();
  const slotFetchedData = useInterviewerSlotFetchedData();

  return useMemo(() => {
    return interviews.reduce<InterviewsWithInterviewersAlreadyInterviewedMap>(
      (acc, interview) => {
        const interviewerUserMembershipIds =
          getUserMembershipIdsFromInterviewSlots(interview.interviewerSlots);
        const interviewerFetchedUserMemberships = slotFetchedData.interviewers
          .map((i) => i.userMembership)
          .filter((um) => interviewerUserMembershipIds.includes(um.id));
        const interviewersAlreadyInterviewedMemberships =
          interviewerFetchedUserMemberships.filter((userMembership) => {
            if (!userMembership.previousScheduledInterviews?.length) {
              return false;
            }

            return true;
          });

        return {
          ...acc,
          [interview.id]: interviewersAlreadyInterviewedMemberships,
        };
      },
      {}
    );
  }, [interviews, slotFetchedData]);
}
