import { isValidPhoneNumber } from "@resource/common";
import type {
  ConferencingGenerationSettings,
  ConferencingRequirement,
  StagedConferencingGenerationSettings,
} from "shared/guide-scheduler/conferencing/types";
import {
  ConferencingGenerationSettingsSchema,
  ConferencingRequirementSchema,
  ConferencingType,
  StagedConferencingGenerationSettingsSchema,
} from "shared/guide-scheduler/conferencing/types";
import { isVideoConferencingUrl } from "shared/utils/video-conferencing";
import { z } from "zod";

const UserValidationMessages = {
  requiredCustomUrl: "Must include a valid conferencing URL.",
  requiredPhoneNumber: "Must include a valid phone number.",
  noConferencingSelected: "A conferencing service must be selected.",
};

export type ConferencingSettingsFormVariant = "settings" | "form";

export const ConferencingSchema = z.object({
  value: z.string(),
  externalId: z.string().optional(),
  passcode: z.string().optional(),
});

export type Conferencing = z.infer<typeof ConferencingSchema>;

export const ConferencingSlotSchema = z.object({
  service: z.nativeEnum(ConferencingType).nullable(),
  existingConferencing: ConferencingSchema.optional().nullable(),
  editingConferencing: ConferencingSchema.optional().nullable(),
  generationSettings:
    ConferencingGenerationSettingsSchema.optional().nullable(),
  requirements: ConferencingRequirementSchema.optional().nullable(),
  isUsingGroupSettings: z.boolean().optional(),
});

export type ConferencingSlot = z.infer<typeof ConferencingSlotSchema>;

export const StagedConferencingSlotSchema = ConferencingSlotSchema.extend({
  generationSettings:
    StagedConferencingGenerationSettingsSchema.optional().nullable(),
})
  .refine(
    (slot) => {
      if (
        slot.service === ConferencingType.OTHER &&
        slot.editingConferencing !== undefined
      ) {
        return (
          slot.editingConferencing !== null &&
          slot.editingConferencing?.value !== "" &&
          isVideoConferencingUrl(slot.editingConferencing.value)
        );
      }

      return true;
    },
    { message: UserValidationMessages.requiredCustomUrl }
  )
  .refine(
    (slot) => {
      if (
        slot.service === ConferencingType.PHONE &&
        slot.editingConferencing !== undefined
      ) {
        return (
          slot.editingConferencing !== null &&
          slot.editingConferencing.value !== "" &&
          isValidPhoneNumber(slot.editingConferencing.value)
        );
      }

      return true;
    },
    { message: UserValidationMessages.requiredPhoneNumber }
  )
  .refine(
    (slot) => {
      if (slot.requirements?.service && !slot.service) {
        return false;
      }

      return true;
    },
    {
      message: UserValidationMessages.noConferencingSelected,
    }
  );

export type StagedConferencingSlot = z.infer<
  typeof StagedConferencingSlotSchema
>;

export {
  ConferencingGenerationSettings,
  ConferencingGenerationSettingsSchema,
  ConferencingRequirement,
  ConferencingRequirementSchema,
  ConferencingType,
  StagedConferencingGenerationSettingsSchema,
  StagedConferencingGenerationSettings as StagedConferencingSettings,
};
