import { ExtensionNavigationState } from "client/app/extension/__components/ExtensionControllerProvider";
import { useAssistantContext } from "client/components/generic/context/AssistantContext";
import { useGlobalNavigator } from "client/components/generic/misc/GlobalNavigator";
import { useRouter } from "next/router";
import { useCallback } from "react";

function getNavigatorProps(
  pathname: string,
  queryParams: Record<string, string>
) {
  // Remove leading slash and trailing slash if present
  const cleanPath = pathname.replace(/^\/|\/$/g, "");

  switch (cleanPath) {
    case "scheduling-requests":
      return {
        view: "schedulingTaskDetails" as const,
        props: {
          schedulingRequestId: queryParams.selectedSchedulingRequestId,
        },
        queryParams,
      };
    case "candidates":
      return {
        view: "candidateProfile" as const,
        props: {
          guideId: queryParams.guideId,
        },
        queryParams,
      };
    default:
      return null;
  }
}

function getExtensionNavigatorProps(
  pathname: string,
  queryParams: Record<string, string>
) {
  // Remove leading slash and trailing slash if present
  const cleanPath = pathname.replace(/^\/|\/$/g, "");

  switch (cleanPath) {
    case "scheduling-requests":
      return {
        view: "tasks" as const,
        props: {
          schedulingRequestId: queryParams.selectedSchedulingRequestId,
        },
        queryParams,
      };
    case "candidates":
      return {
        view: "candidateProfile" as const,
        props: {
          guideId: queryParams.guideId,
        },
        queryParams,
      };
    default:
      return null;
  }
}

export const useNavigateToFrameHref = ({
  navigateInExtension,
}: {
  navigateInExtension?: (props: ExtensionNavigationState) => void;
}) => {
  const navigator = useGlobalNavigator();
  const assistantContext = useAssistantContext();
  const router = useRouter();

  return useCallback(
    ({ href }: { href?: string | null }) => {
      if (!href) {
        return;
      }

      const { pathname: path, search: queryString } = new URL(
        href,
        window.location.origin
      );
      const queryParams = queryString
        ? Object.fromEntries(new URLSearchParams(queryString))
        : {};

      if (navigateInExtension) {
        const navigatorProps = getExtensionNavigatorProps(path, queryParams);
        if (navigatorProps) {
          navigateInExtension({
            view: navigatorProps.view,
            props: navigatorProps.props,
          });

          if (navigatorProps.queryParams.assistant_chat_open === "true") {
            assistantContext?.setIsOpen(true);
          }
        }
      } else if (navigator) {
        const navigatorProps = getNavigatorProps(path, queryParams);
        if (navigatorProps) {
          navigator.navigate(navigatorProps.view, navigatorProps.props);

          if (navigatorProps.queryParams.assistant_chat_open === "true") {
            assistantContext?.setIsOpen(true);
          }
        }
      } else if (router.pathname === path) {
        // If same path, just replace query params while preserving existing ones
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              ...queryParams,
            },
          },
          undefined,
          { shallow: true }
        );
      } else {
        // If different path, push full href
        router.push(href);
      }
    },
    [navigator, router, navigateInExtension, assistantContext]
  );
};
