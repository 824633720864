import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";

import {
  SelfScheduleRequestForm,
  SelfScheduleRequestFormProps,
} from "./SelfScheduleRequestForm";

export function SelfScheduleRequestFormDialog(
  props: Omit<SelfScheduleRequestFormProps, "onCompleted" | "onDismiss"> &
    Pick<DialogProps, "store" | "variant" | "size">
) {
  const { store, variant, size, ...rest } = props;

  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
  });

  return (
    <Dialog {...dialogProps} store={store} variant={variant} size={size}>
      <SelfScheduleRequestForm
        {...rest}
        onCompleted={store.hide}
        onDismiss={store.hide}
      />
      <WarningDialog />
    </Dialog>
  );
}
