import { useUpdateFetchedInterviewerEvent } from "client/calendar-events/hooks/useFetchedInterviewerCalendarEvents";
import { InterviewerCalendarEvent } from "client/calendar-events/utils/types";
import { UserMembershipForForm } from "client/components/interviewer-slots/utils/types";
import { AddToWhitelistSwitchDisplay } from "client/components/interviewer-whitelists/display/AddToWhitelistSwitchDisplay";
import { useWhitelistSwitchOnChange } from "client/components/interviewer-whitelists/hooks/useWhitelistSwitchOnChange";
import { gql } from "generated/graphql-codegen";

import { useSchedulerGuide } from "../../hooks/useSchedulerGuide";

gql(`
  fragment GuideForSchedulerAddToWhitelistSwitch on Guide {
    id
    organization {
      id
      interviewWhitelist
    }
  }  
`);

export type SchedulerAddToWhitelistSwitchProps = {
  event: Pick<InterviewerCalendarEvent, "id" | "title" | "isWhitelisted">;
  userMembership: Pick<UserMembershipForForm, "id" | "name">;
  hideText?: boolean;
};

export function SchedulerAddToWhitelistSwitch({
  event,
  userMembership,
  hideText,
}: SchedulerAddToWhitelistSwitchProps) {
  const guide = useSchedulerGuide();
  const updateFetchedInterviewerEvent = useUpdateFetchedInterviewerEvent();
  const { onChange, loading } = useWhitelistSwitchOnChange({
    title: event.title,
    userMembershipId: userMembership.id,
    onCompleted: (isWhitelisted) => {
      updateFetchedInterviewerEvent(
        {
          eventId: event.id,
          userMembershipId: userMembership.id,
        },
        {
          isWhitelisted,
        }
      );
    },
  });

  return (
    <AddToWhitelistSwitchDisplay
      organizationWhitelist={guide.organization.interviewWhitelist}
      title={event.title}
      isWhitelisted={event.isWhitelisted}
      interviewer={userMembership}
      onChange={onChange}
      loading={loading}
      hideText={hideText}
    />
  );
}
