import {
  ConferencingGenerationSettingsForMappingFragment,
  ConferencingGenerationSettingsInput,
  ConferencingHostSettingType,
  ConferencingRequirementForMappingFragment,
  ConferencingRequirementInput,
  ConferencingServiceType,
  ConferencingSlotInput,
  InterviewRequirementForConferencingRequirementFragment,
  LocationType,
  ScheduledInterviewForConferencingMappingFragment,
  ScheduledInterviewForConferencingSlotFragment,
  UpdateScheduledInterviewInput,
} from "generated/graphql-codegen/graphql";
import { ConferencingHostSetting } from "shared/guide-scheduler/conferencing/types";
import { VideoConferencingServiceType } from "shared/guide-scheduler/video-conferencing/types";

import { isGenerationService } from "./helpers";
import {
  Conferencing,
  ConferencingGenerationSettings,
  ConferencingRequirement,
  ConferencingSlot,
  ConferencingType,
  StagedConferencingSlot,
} from "./types";

export function getVideoConferencingTypeFromConferencingType(
  type: ConferencingType
): VideoConferencingServiceType | null {
  switch (type) {
    case ConferencingType.ZOOM:
      return VideoConferencingServiceType.ZOOM;
    case ConferencingType.GOOGLE_MEET:
      return VideoConferencingServiceType.GOOGLE_MEET;
    case ConferencingType.OTHER:
      return VideoConferencingServiceType.OTHER;
    default:
      return null;
  }
}

export function getServiceFromType(
  type: VideoConferencingServiceType
): ConferencingType {
  switch (type) {
    case VideoConferencingServiceType.ZOOM:
      return ConferencingType.ZOOM;
    case VideoConferencingServiceType.GOOGLE_MEET:
      return ConferencingType.GOOGLE_MEET;
    case VideoConferencingServiceType.MICROSOFT_TEAMS:
      return ConferencingType.OTHER;
    case VideoConferencingServiceType.SKYPE:
      return ConferencingType.OTHER;
    case VideoConferencingServiceType.OTHER:
      return ConferencingType.OTHER;
    default:
      return ConferencingType.OTHER;
  }
}

export function getServiceFromCodegenType(
  service: ConferencingServiceType
): ConferencingType {
  switch (service) {
    case ConferencingServiceType.PHONE:
      return ConferencingType.PHONE;
    case ConferencingServiceType.ZOOM:
      return ConferencingType.ZOOM;
    case ConferencingServiceType.GOOGLE_MEET:
      return ConferencingType.GOOGLE_MEET;
    case ConferencingServiceType.OTHER:
      return ConferencingType.OTHER;
    default: {
      throw new Error("Invalid conferencing service type");
    }
  }
}
export function getCodegenTypeFromService(
  service: ConferencingType
): ConferencingServiceType {
  switch (service) {
    case ConferencingType.PHONE:
      return ConferencingServiceType.PHONE;
    case ConferencingType.ZOOM:
      return ConferencingServiceType.ZOOM;
    case ConferencingType.GOOGLE_MEET:
      return ConferencingServiceType.GOOGLE_MEET;
    case ConferencingType.OTHER:
      return ConferencingServiceType.OTHER;
    default: {
      throw new Error("Invalid conferencing service type");
    }
  }
}

export function getLocationTypeFromConferencingType(
  type: ConferencingType
): LocationType {
  switch (type) {
    case ConferencingType.PHONE:
      return LocationType.PHONE;
    case ConferencingType.ZOOM:
      return LocationType.ZOOM;
    case ConferencingType.GOOGLE_MEET:
      return LocationType.GOOGLE_MEET;
    case ConferencingType.OTHER:
      return LocationType.OTHER;
    default:
      return LocationType.OTHER;
  }
}

export function getConferencingTypeFromLocationType(
  type: LocationType
): ConferencingType {
  switch (type) {
    case LocationType.PHONE:
      return ConferencingType.PHONE;
    case LocationType.ZOOM:
      return ConferencingType.ZOOM;
    case LocationType.GOOGLE_MEET:
      return ConferencingType.GOOGLE_MEET;
    case LocationType.OTHER:
      return ConferencingType.OTHER;
    default:
      return ConferencingType.OTHER;
  }
}

export function getHostSettingFromCodegenType(
  hostSetting: ConferencingHostSettingType
): ConferencingHostSetting | null {
  switch (hostSetting) {
    case ConferencingHostSettingType.ANY_INTERVIEWER:
      return ConferencingHostSetting.ANY_INTERVIEWER;
    case ConferencingHostSettingType.REQUEST_ASSIGNEE:
      return ConferencingHostSetting.REQUEST_ASSIGNEE;
    default:
      return null;
  }
}

export function getCodegenTypeFromHostSetting(
  hostSetting: ConferencingHostSetting
): ConferencingHostSettingType | null {
  switch (hostSetting) {
    case ConferencingHostSetting.ANY_INTERVIEWER:
      return ConferencingHostSettingType.ANY_INTERVIEWER;
    case ConferencingHostSetting.REQUEST_ASSIGNEE:
      return ConferencingHostSettingType.REQUEST_ASSIGNEE;
    default:
      return null;
  }
}

export function mapConferencingRequirementFragmentToConferencingRequirement(
  conferencingRequirement: ConferencingRequirementForMappingFragment
): ConferencingRequirement {
  return {
    service: getServiceFromCodegenType(conferencingRequirement.service),
    hostSetting: conferencingRequirement.hostSetting
      ? getHostSettingFromCodegenType(conferencingRequirement.hostSetting)
      : null,
  };
}

export function mapConferencingRequirementFragmentToGenerationSettings(
  generationSettings: ConferencingRequirementForMappingFragment
): ConferencingGenerationSettings | undefined {
  if (
    !isGenerationService(getServiceFromCodegenType(generationSettings.service))
  ) {
    return undefined;
  }

  return {
    service: getServiceFromCodegenType(generationSettings.service),
    hostUserMembershipId: null,
  };
}

export function mapConferencingGenerationSettingsFragmentToConferencingGenerationSettings(
  generationSettings: ConferencingGenerationSettingsForMappingFragment
): ConferencingGenerationSettings {
  return {
    service: getServiceFromCodegenType(generationSettings.service),
    hostUserMembershipId: generationSettings.hostUserMembershipId,
  };
}

export function mapScheduledInterviewForConferencingMappingToExistingConferencing(
  scheduledInterview: ScheduledInterviewForConferencingSlotFragment
): Conferencing | null {
  const { videoConferencing, conferencePhone } = scheduledInterview;

  if (videoConferencing) {
    return {
      value: videoConferencing.url,
      externalId: videoConferencing.externalId ?? undefined,
      passcode: videoConferencing.passcode ?? undefined,
    };
  }

  if (conferencePhone) {
    return {
      value: conferencePhone,
    };
  }

  return null;
}

export function getConferencingServiceForScheduledInterview(
  scheduledInterview: ScheduledInterviewForConferencingSlotFragment
): ConferencingType | null {
  if (scheduledInterview.videoConferencing) {
    return getServiceFromType(scheduledInterview.videoConferencing.type);
  }

  if (scheduledInterview.conferencePhone) {
    return ConferencingType.PHONE;
  }

  if (scheduledInterview.conferencingRequirement) {
    return getServiceFromCodegenType(
      scheduledInterview.conferencingRequirement.service
    );
  }

  return null;
}

/** For self schedule interviews, we don't persist full generation settings yet, so just pull them off the video conferencing data  */
function mapScheduledInterviewConferencingToGenerationSettings(
  scheduledInterview: ScheduledInterviewForConferencingMappingFragment
): ConferencingGenerationSettings | undefined {
  if (!scheduledInterview.videoConferencing) {
    return undefined;
  }

  return {
    service: getServiceFromType(scheduledInterview.videoConferencing?.type),
    hostUserMembershipId:
      scheduledInterview.videoConferencing?.hostUserMembershipId,
  };
}

export function mapScheduledInterviewToConferencingSlot({
  scheduledInterview,
  isUsingGroupSettings,
}: {
  scheduledInterview: ScheduledInterviewForConferencingSlotFragment;
  isUsingGroupSettings?: boolean;
}): ConferencingSlot | null {
  const existingConferencing =
    mapScheduledInterviewForConferencingMappingToExistingConferencing(
      scheduledInterview
    );
  const conferencingType =
    getConferencingServiceForScheduledInterview(scheduledInterview);
  const generationSettingsFromInterview =
    scheduledInterview.conferencingGenerationSettings
      ? mapConferencingGenerationSettingsFragmentToConferencingGenerationSettings(
          scheduledInterview.conferencingGenerationSettings
        )
      : mapScheduledInterviewConferencingToGenerationSettings(
          scheduledInterview
        );
  const generationSettingsFromRequirement =
    scheduledInterview.conferencingRequirement
      ? mapConferencingRequirementFragmentToGenerationSettings(
          scheduledInterview.conferencingRequirement
        )
      : null;

  const generationSettings =
    generationSettingsFromInterview ?? generationSettingsFromRequirement;

  if (generationSettings) {
    // If there's no existing conferencing but the interview was supposed to be created with conferencing, force regenerate link.
    // This can happen if the Zoom meeting failed to create for any reason; we still store the generation settings in that case.
    generationSettings.regenerateLink =
      generationSettings.regenerateLink ?? !existingConferencing;
  }

  if (conferencingType) {
    return {
      service: conferencingType,
      existingConferencing,
      generationSettings,
      requirements: scheduledInterview.conferencingRequirement
        ? mapConferencingRequirementFragmentToConferencingRequirement(
            scheduledInterview.conferencingRequirement
          )
        : null,
      isUsingGroupSettings,
    };
  }

  return null;
}

export function mapInterviewRequirementToConferencingSlot(
  interviewRequirement: InterviewRequirementForConferencingRequirementFragment
): ConferencingSlot | null {
  if (interviewRequirement.conferencingRequirement) {
    return {
      service: getServiceFromCodegenType(
        interviewRequirement.conferencingRequirement.service
      ),
      requirements: mapConferencingRequirementFragmentToConferencingRequirement(
        interviewRequirement.conferencingRequirement
      ),
      generationSettings:
        mapConferencingRequirementFragmentToGenerationSettings(
          interviewRequirement.conferencingRequirement
        ),
    };
  }

  return null;
}

export function mapConferencingSettingsToDeprecatedScheduledInterviewInput(
  conferencingSlot: ConferencingSlot | null
): Partial<UpdateScheduledInterviewInput> {
  if (!conferencingSlot) return {};

  if (conferencingSlot.service === ConferencingType.PHONE) {
    return {
      conferencePhone: conferencingSlot?.editingConferencing?.value,
    };
  }

  if (conferencingSlot.service === ConferencingType.ZOOM) {
    return {
      generateVideoConferencingSettings: {
        service: VideoConferencingServiceType.ZOOM,
        hostUserMembershipId:
          conferencingSlot.generationSettings?.hostUserMembershipId,
        regenerate: conferencingSlot.generationSettings?.regenerateLink,
      },
    };
  }

  if (conferencingSlot.service === ConferencingType.GOOGLE_MEET) {
    return {
      generateVideoConferencingSettings: {
        service: VideoConferencingServiceType.GOOGLE_MEET,
        regenerate: conferencingSlot.generationSettings?.regenerateLink,
      },
    };
  }

  return {
    videoConferencingUrl: conferencingSlot.editingConferencing?.value,
  };
}

export function mapConferencingGenerationSettingsToInput(
  generationSettings: ConferencingGenerationSettings
): ConferencingGenerationSettingsInput {
  return {
    service: getCodegenTypeFromService(generationSettings.service),
    hostUserMembershipId: generationSettings.hostUserMembershipId,
    regenerateLink: generationSettings.regenerateLink,
  };
}

export function mapConferencingRequirementToInput(
  conferencingRequirement: ConferencingRequirement
): ConferencingRequirementInput {
  return {
    service: getCodegenTypeFromService(conferencingRequirement.service),
    hostSetting: conferencingRequirement.hostSetting
      ? getCodegenTypeFromHostSetting(conferencingRequirement.hostSetting)
      : undefined,
  };
}

export function mapConferencingSlotToInput({
  conferencingSlot,
  groupHasReusableLink,
}: {
  conferencingSlot: StagedConferencingSlot;
  groupHasReusableLink?: boolean;
}): ConferencingSlotInput | null {
  const {
    service,
    editingConferencing,
    generationSettings,
    requirements,
    isUsingGroupSettings,
  } = conferencingSlot;

  if (groupHasReusableLink && isUsingGroupSettings) {
    return {
      useGroupSettings: true,
      requirement: requirements
        ? mapConferencingRequirementToInput(requirements)
        : undefined,
    };
  }

  if (!conferencingSlot.service) {
    return null;
  }

  if (service === ConferencingType.OTHER) {
    // Zod should enforce that if service is other then editingConferencing.value exists
    return {
      customUrl: editingConferencing?.value,
      requirement: requirements
        ? mapConferencingRequirementToInput(requirements)
        : undefined,
    };
  }

  if (service === ConferencingType.PHONE) {
    // Zod should enforce that if service is other then editingConferencing.value exists
    return {
      phone: editingConferencing?.value,
      requirement: requirements
        ? mapConferencingRequirementToInput(requirements)
        : undefined,
    };
  }

  return {
    generationSettings: generationSettings
      ? mapConferencingGenerationSettingsToInput(generationSettings)
      : undefined,
    requirement: requirements
      ? mapConferencingRequirementToInput(requirements)
      : undefined,
  };
}
