import { EditConferencingSlot } from "client/components/conferencing/display/EditConferencingSlot";
import {
  ConferencingSlot,
  ConferencingType,
} from "client/components/conferencing/utils/types";
import { getUserMembershipIdsFromInterviewSlots } from "client/components/interviewer-slots/utils/misc";
import { useCallback } from "react";
import { Controller } from "react-hook-form";

import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewConferencingSettings({
  state: {
    form,
    scheduledInterviewGroupSettings,
    hidePhoneSelection,
    onChange,
    originalInterview,
    hasGroupSettingsBeenInitialized,
  },
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const interviewerSlots = form.watch("scheduledInterview.interviewerSlots");
  const interviewerUserMembershipIds =
    getUserMembershipIdsFromInterviewSlots(interviewerSlots);
  const handleConferencingSlotChange = useCallback(
    (slot: ConferencingSlot | null) => {
      onChange("scheduledInterview.conferencingSlot", slot);
    },
    [onChange]
  );
  const handleZoomHostChange = useCallback(
    (hostUserMembershipId: string | null) => {
      onChange(
        "scheduledInterview.conferencingSlot.service",
        ConferencingType.ZOOM
      );
      onChange(
        "scheduledInterview.conferencingSlot.generationSettings.hostUserMembershipId",
        hostUserMembershipId
      );
      onChange(
        "scheduledInterview.conferencingSlot.generationSettings.service",
        ConferencingType.ZOOM
      );
      onChange(
        "scheduledInterview.conferencingSlot.generationSettings.regenerateLink",
        true
      );
    },
    [onChange]
  );

  return (
    <Controller
      name="scheduledInterview.conferencingSlot"
      control={form.control}
      render={({ field, fieldState: { isTouched, error } }) => {
        return (
          <EditConferencingSlot
            conferencingSlot={field.value}
            originalConferencingSlot={originalInterview?.conferencingSlot}
            onChangeConferencingSlot={handleConferencingSlotChange}
            handleZoomHostChange={handleZoomHostChange}
            interviewerUserMembershipIds={interviewerUserMembershipIds}
            locationFormGroupProps={{
              errorMessage: isTouched ? error?.message : undefined,
            }}
            phoneEnabled={!hidePhoneSelection}
            scheduledInterviewGroupSettings={scheduledInterviewGroupSettings}
            hasGroupSettingsBeenInitialized={hasGroupSettingsBeenInitialized}
          />
        );
      }}
    />
  );
}
