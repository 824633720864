import { mapCalendarEventInputToLuxonTimes } from "client/utils/dates";
import { Interval } from "luxon";
import { useMemo } from "react";

import type { CalendarData } from "../hooks/data";
import { CalendarOptions, CalendarOptionsInput } from "../hooks/options";
import { useCalendarSettings, useCurrentInterval } from "../hooks/settings";
import { ColorConfig, getCalendarColor } from "./colors";
import type { CalendarContextInput } from "./context";
import type { CalendarEvent, CalendarView } from "./types";
import { isEventInView } from "./utils";

export function useMappedCalendarOptions({
  options,
  calendarData,
}: {
  options: CalendarOptionsInput;
  calendarData: CalendarData;
}) {
  return useMemo(
    () => mapOptionsToDefaults({ options, calendarData }),
    [options, calendarData]
  );
}

export function mapOptionsToDefaults({
  options,
  calendarData,
}: {
  options: CalendarOptionsInput;
  calendarData: CalendarData;
}): CalendarOptions {
  return {
    minInterval: options.minInterval ?? 15,
    dayViewCalendarIds:
      options.dayViewCalendarIds ?? calendarData.allCalendarIds,
    calendarsGrouping: options.calendarsGrouping ?? null,
    getCalendarDisplayInfo: options.getCalendarDisplayInfo,
    layerPriority: options.layerPriority,
    getEventDetailsFooter: options.getEventDetailsFooter,
  };
}

export function useMappedCalendarData({
  input,
}: {
  input: CalendarContextInput;
}): CalendarData {
  const { includeWeekends, timezone, view } = useCalendarSettings();
  const currentInterval = useCurrentInterval();

  return useMemo(
    () =>
      mapEventInputsToCalendarData({
        ...input,
        includeWeekends,
        currentInterval,
        timezone,
        view,
      }),
    [currentInterval, includeWeekends, input, timezone, view]
  );
}

/**
 * Maps event inputs to calendar data.
 *
 * @param {Object} params - The parameters for mapping event inputs.
 * @param {CalendarEventInput[]} params.events - The input events to be mapped.
 * @param {Object} [params.calendarColors={}] - The color configurations for calendars.
 * @param {boolean} params.includeWeekends - Whether to include weekend events.
 * @param {Interval} params.currentInterval - The current time interval being viewed.
 * @param {string} params.timezone - The timezone to use for date calculations.
 * @param {CalendarView} params.view - The current calendar view (day or week).
 * @returns {CalendarData} The mapped calendar data.
 */
export function mapEventInputsToCalendarData({
  events: eventsInput,
  calendarColors: calendarColorsInput = {},
  includeWeekends,
  currentInterval,
  timezone,
  view,
}: CalendarContextInput & {
  includeWeekends: boolean;
  currentInterval: Interval;
  timezone: string;
  view: CalendarView;
}): CalendarData {
  // Map and filter events based on the current view and settings
  const mappedEvents = mapEvents({
    eventsInput,
    calendarColorsInput,
    timezone,
  });
  const filteredEvents = mappedEvents.filter((e) => {
    return isEventInView({
      includeWeekends,
      currentInterval,
      event: e,
    });
  });

  // Sort events by start time
  const sortedEvents = filteredEvents.sort((a, b) => {
    if (a.startTime < b.startTime) return -1;
    if (a.startTime > b.startTime) return 1;
    return 0;
  });

  // Separate all-day events from regular events
  const { allDayEvents, events } = sortedEvents.reduce(
    (acc, event) => {
      if (event.allDay) {
        return {
          ...acc,
          allDayEvents: [...acc.allDayEvents, event],
        };
      }
      return {
        ...acc,
        events: [...acc.events, event],
      };
    },
    {
      allDayEvents: [] as CalendarEvent[],
      events: [] as CalendarEvent[],
    }
  );

  // Get unique calendar IDs
  const allCalendarIds = Array.from(
    new Set(
      eventsInput.flatMap((event) => [
        event.calendarId,
        ...(event.additionalCalendarIds ?? []),
      ])
    )
  );

  // Separate background and foreground events
  const backgroundEvents: CalendarEvent[] = [];
  const foregroundEvents: CalendarEvent[] = [];

  events.forEach((event) => {
    if (event.isBackgroundEvent) {
      backgroundEvents.push(event);
    } else {
      foregroundEvents.push(event);
    }
  });

  // Create event groups by date for all events
  const eventGroups = createEventGroups(foregroundEvents);
  const allDayEventGroups = createEventGroups(allDayEvents);

  // Create event groups on a per calendar basis
  const eventGroupsByCalendar: { [calendarId: string]: CalendarEvent[][] } = {};
  const allDayEventGroupsByCalendar: {
    [calendarId: string]: CalendarEvent[][];
  } = {};

  allCalendarIds.forEach((calendarId) => {
    const calendarEvents =
      view === "day"
        ? foregroundEvents.filter(
            (event) =>
              event.calendarId === calendarId ||
              event.additionalCalendarIds?.includes(calendarId)
          )
        : foregroundEvents;

    eventGroupsByCalendar[calendarId] = createEventGroups(calendarEvents);

    const calendarAllDayEvents =
      view === "day"
        ? allDayEvents.filter(
            (event) =>
              event.calendarId === calendarId ||
              event.additionalCalendarIds?.includes(calendarId)
          )
        : allDayEvents;

    allDayEventGroupsByCalendar[calendarId] =
      createEventGroups(calendarAllDayEvents);
  });

  return {
    events,
    allDayEvents,
    allDayEventGroups,
    allCalendarIds,
    eventGroups,
    eventGroupsByCalendar,
    allDayEventGroupsByCalendar,
    backgroundEvents,
  };
}

/**
 * Maps calendar event inputs to CalendarEvent objects.
 *
 * @param {Object} params - The parameters for mapping events.
 * @param {CalendarEventInput[]} params.eventsInput - The input events to be mapped.
 * @param {Object} [params.calendarColorsInput={}] - The color configurations for calendars.
 * @param {string} params.timezone - The timezone to use for date calculations.
 * @returns {CalendarEvent[]} The mapped calendar events.
 */
function mapEvents({
  eventsInput,
  calendarColorsInput = {},
  timezone,
}: {
  eventsInput: CalendarContextInput["events"];
  calendarColorsInput: CalendarContextInput["calendarColors"];
  timezone: string;
}) {
  return eventsInput.flatMap<CalendarEvent>((e): CalendarEvent[] => {
    const calendarColors = calendarColorsInput;

    // Determine color configuration for the event
    const defaultColorConfig: ColorConfig = {
      color: getCalendarColor(Object.keys(calendarColors).length),
    };
    const colorConfig =
      e.colorConfig ?? calendarColors[e.calendarId] ?? defaultColorConfig;

    // Store color config for the calendar if not already present
    if (!calendarColors[e.calendarId]) {
      calendarColors[e.calendarId] = colorConfig;
    }

    // Convert event times to Luxon DateTime objects
    const mappedEvent = mapCalendarEventInputToLuxonTimes(e, timezone);
    const eventStartTime = mappedEvent.startTime;
    const eventEndTime = mappedEvent.endTime;
    const eventInterval = Interval.fromDateTimes(eventStartTime, eventEndTime);

    const events: CalendarEvent[] = [];

    // Recursively split multi-day events into separate day events
    function recursivelyAddEvents(interval: Interval) {
      const [firstDay, remainingInterval] = interval.splitAt(
        interval.start.startOf("day").plus({ days: 1 })
      );

      if (firstDay) {
        // Determine if the event is an all-day event
        const allDay =
          mappedEvent.allDay ??
          (firstDay.start.equals(firstDay.start.startOf("day")) &&
            firstDay.end.equals(
              firstDay.start.startOf("day").plus({ days: 1 })
            ));

        events.push({
          ...e,
          startTime: firstDay.start,
          endTime: firstDay.end,
          colorConfig,
          allDay,
        });

        // Process remaining interval if exists
        if (remainingInterval) {
          recursivelyAddEvents(remainingInterval);
        }
      }
    }

    recursivelyAddEvents(eventInterval);

    return events;
  });
}

/**
 * Creates groups of non-overlapping events with the same group key.
 *
 * @param {CalendarEvent[]} events - The events to be grouped.
 * @returns {CalendarEvent[][]} An array of event groups, where each group contains non-overlapping events with the same group key.
 */
function createEventGroups(events: CalendarEvent[]): CalendarEvent[][] {
  const groups: CalendarEvent[][] = [];
  const eventsByGroup: { [key: string]: CalendarEvent[] } = {};

  // Group events by their 'layer' key
  events.forEach((event) => {
    const groupKey = event.layer || "default";
    if (!eventsByGroup[groupKey]) {
      eventsByGroup[groupKey] = [];
    }
    eventsByGroup[groupKey].push(event);
  });

  // Process each group separately
  Object.values(eventsByGroup).forEach((groupEvents) => {
    let eventsCopy = [...groupEvents];

    // Continue grouping events until all events in this group have been processed
    while (eventsCopy.length > 0) {
      const leftoverEvents: CalendarEvent[] = [];
      let eventGroup: CalendarEvent[] = [];

      // Iterate through all events in the current copy
      for (let i = 0; i < eventsCopy.length; i += 1) {
        const currEvent = eventsCopy[i];

        if (eventGroup.length === 0) {
          // If the group is empty, start a new group with the current event
          eventGroup = [currEvent];
        } else {
          const lastEventInGroup = eventGroup[eventGroup.length - 1];

          // Check if the current event overlaps with the last event in the group
          if (currEvent.startTime < lastEventInGroup.endTime) {
            // If overlapped, add to the current group
            eventGroup.push(currEvent);
          } else {
            // If no overlap, save for next iteration
            leftoverEvents.push(currEvent);
          }
        }
      }

      // Update eventsCopy with leftover events for next iteration
      eventsCopy = [...leftoverEvents];
      // If we formed a group, add it to the groups array
      if (eventGroup.length) groups.push(eventGroup);
    }
  });

  // Return the array of event groups
  return groups;
}
