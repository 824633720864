import { Button } from "@resource/atlas/button/Button";
import { atlasArrowLeft } from "@resource/atlas/icons";
import { useRouter } from "next/router";

export function BackButton({ onClick }: { onClick?: () => void }) {
  const router = useRouter();

  return (
    <Button isGhost icon={atlasArrowLeft} onClick={onClick ?? router.back} />
  );
}
