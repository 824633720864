/* eslint-disable import/prefer-default-export */
import { commandsModule } from "./commands";
import { floatingToolbarModule } from "./floating-toolbar";
import { historyModule } from "./history";
import { richTextModule } from "./rich-text";

/** Modules that are part of the content editor base functionality. */
export const baseModules = [
  commandsModule,
  floatingToolbarModule,
  historyModule,
  richTextModule,
];
