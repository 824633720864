import { useSetAtom } from "jotai";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

import { useCalculateScrollValueForTime } from "./callbacks";
import { useEventsViewRef, useWrapperRef } from "./refs";
import { calendarSelectedDayAtom, useCalendarTimezone } from "./settings";

type UseCalendarInitialScrollProps = {
  initialScrollValue?: string | DateTime;
};

/**
 * Scroll to the correct initial time and day when the calendar is loaded.
 */
export function useScrollToCalendarInitialValueOnMount({
  initialScrollValue: inputInitialScrollValue,
}: UseCalendarInitialScrollProps) {
  const wrapperRef = useWrapperRef();
  const eventsViewRef = useEventsViewRef();
  const calculateScrollValueForTime = useCalculateScrollValueForTime();
  const timezone = useCalendarTimezone();
  const setSelectedDay = useSetAtom(calendarSelectedDayAtom);

  const [hasScrolledToDay, setHasScrolledToDay] = useState(false);
  const [hasScrolledToTime, setHasScrolledToTime] = useState(false);

  const initialScrollValue = useMemo(() => {
    if (inputInitialScrollValue) {
      return typeof inputInitialScrollValue === "string"
        ? DateTime.fromISO(inputInitialScrollValue, {
            zone: timezone,
          })
        : inputInitialScrollValue;
    }

    return DateTime.now().setZone(timezone).minus({ hours: 2 });
  }, [inputInitialScrollValue, timezone]);

  useEffect(() => {
    if (!hasScrolledToDay) {
      setSelectedDay(initialScrollValue.startOf("day"));
      setHasScrolledToDay(true);
    }
  }, [hasScrolledToDay, initialScrollValue, setSelectedDay]);

  useEffect(() => {
    const scrollValue = calculateScrollValueForTime(initialScrollValue);

    if (wrapperRef && scrollValue && !hasScrolledToTime) {
      wrapperRef.scrollTop = scrollValue;
      setHasScrolledToTime(true);
    }
  }, [
    initialScrollValue,
    eventsViewRef,
    wrapperRef,
    timezone,
    calculateScrollValueForTime,
    hasScrolledToTime,
  ]);
}
