import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";

import { EditInterviewInviteAndNotifyView } from "./EditInterviewInviteAndNotifyView";

export type EditInterviewInviteAndNotifyDialogProps = DialogProps & {
  scheduledInterviewId: string;
};
export function EditInterviewInviteAndNotifyDialog({
  scheduledInterviewId,
  store,
  ...props
}: EditInterviewInviteAndNotifyDialogProps) {
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
  });

  return (
    <Dialog size="medium" {...props} store={store} {...dialogProps}>
      <EditInterviewInviteAndNotifyView
        scheduledInterviewId={scheduledInterviewId}
        onCancel={store.hide}
        onCompleted={store.hide}
      />
      <WarningDialog />
    </Dialog>
  );
}
