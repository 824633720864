import { View } from "@resource/atlas/view/View";
import { PostMessageView } from "client/message-composer/__components/PostMessageView";
import ErrorPage from "components/Generic/ErrorPage";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import {
  GuideForSelfScheduleRequestFormFragment,
  GuidePostCreateWorkflowType,
} from "generated/graphql-codegen/graphql";
import { formatEntity } from "shared/constants/entities";
import { PostMessageWorkflow } from "shared/message-composer/types";
import useQuery from "utils/useQuery";

export type SelfScheduleRequestFormProps = {
  selfScheduleInterviewId: string | undefined;
  guideId: string;
  onCompleted: () => void;
  onDismiss: () => void;
};

gql(`
  fragment GuideForSelfScheduleRequestForm on Guide {
    id
    candidate {
      id
      timezone
    }
    posts {
      ...ThreadForDetail
    }
    ...PostMessageDialogGuide
  }
`);

const GET_GUIDE_FOR_SELF_SCHEDULE_REQUEST = gql(`
  query GuideForSelfScheduleRequestQuery(
    $guideId: String!
    $isCandidateInterviewConfirmation: Boolean = false
    $isCandidateRescheduleInterviewConfirmation: Boolean = false
    $isRequestAvailability: Boolean = false
    $isRequestAdditionalAvailability: Boolean = false
    $isSelfScheduleRequest: Boolean = false
    $workflowType: GuidePostCreateWorkflowType = self_schedule_request
  ) {
    guideById(guideId: $guideId) {
      ...GuideForSelfScheduleRequestForm
    }
  }
`);

export function SelfScheduleRequestForm({
  selfScheduleInterviewId,
  guideId,
  onCompleted,
  onDismiss,
}: SelfScheduleRequestFormProps) {
  const { data } = useQuery(GET_GUIDE_FOR_SELF_SCHEDULE_REQUEST, {
    variables: {
      workflowType: GuidePostCreateWorkflowType.self_schedule_request,
      guideId,
      isSelfScheduleRequest: true,
    },
  });

  if (!data) {
    return (
      <View>
        <Loading />
      </View>
    );
  }

  const guide = data.guideById;

  if (!guide) {
    return (
      <View>
        <ErrorPage errorCode="500" />
      </View>
    );
  }

  return (
    <SelfScheduleRequestFormView
      guide={guide}
      onCompleted={onCompleted}
      onDismiss={onDismiss}
      selfScheduleInterviewId={selfScheduleInterviewId}
    />
  );
}

type SelfScheduleRequestFormViewProps = Pick<
  SelfScheduleRequestFormProps,
  "selfScheduleInterviewId" | "onCompleted" | "onDismiss"
> & {
  guide: GuideForSelfScheduleRequestFormFragment;
};

function SelfScheduleRequestFormView({
  guide,
  selfScheduleInterviewId,
  onCompleted,
  onDismiss,
}: SelfScheduleRequestFormViewProps) {
  return (
    <PostMessageView
      guide={guide}
      viewProps={{
        header: {
          title: `Send ${formatEntity("self-schedule request")}`,
        },
      }}
      workflowType={PostMessageWorkflow.SELF_SCHEDULE_REQUEST}
      onCompleted={onCompleted}
      selfScheduleInterviewId={selfScheduleInterviewId}
      onDismiss={onDismiss}
    />
  );
}
