import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { MultiStepView } from "@resource/atlas/multi-step-view/MultiStepView";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { NoTimesAvailableView } from "./NoTimesAvailableView";
import { SelectDateAndTimeContent } from "./SelectDateAndTimeContent";
import { SelectDateContent } from "./SelectDateContent";
import { SelectTimeContent } from "./SelectTimeContent";
import { SelfScheduleReview } from "./SelfScheduleReview";

type Content = {
  reviewPageTitle: {
    new: string;
    reschedule: string;
  };
  reviewPageSubmitButton: {
    new: string;
    reschedule: string;
  };
};

export type CreateOrUpdateSelfScheduleSubmissionViewsProps<
  T extends FieldValues
> = {
  state: SelfScheduleSubmissionState;
  onSubmit: () => Promise<void>;
  submitLoading?: boolean;
  onCancel?: () => void;
  content: Content;
  reviewForm: UseFormReturn<T>;
  ReviewFormDisplay: JSX.Element;
};

export function CreateOrUpdateSelfScheduleSubmissionView<
  T extends FieldValues
>({
  state,
  onCancel,
  onSubmit,
  submitLoading,
  reviewForm,
  ReviewFormDisplay,
  content,
}: CreateOrUpdateSelfScheduleSubmissionViewsProps<T>) {
  const logEvent = useLogEvent({
    component: "CreateOrUpdateSelfScheduleSubmissionView",
  });
  const {
    selectDateTooltipContent,
    selectTimeTooltipContent,
    reviewPageDisabledTooltipContent:
      stateDefaultReviewPageDisabledTooltipContent,
    availableSlots,
    isReschedule,
  } = state;
  const reviewPageFormDisabledTooltipContent = useMemo(() => {
    if (Object.keys(reviewForm.formState.errors).length > 0) {
      return Object.values(reviewForm.formState.errors)
        .map((v) => v.message)
        .join(", ");
    }

    return undefined;
  }, [reviewForm.formState]);
  const reviewPageDisabledTooltipContent = useMemo(() => {
    if (stateDefaultReviewPageDisabledTooltipContent) {
      return stateDefaultReviewPageDisabledTooltipContent;
    }

    return reviewPageFormDisabledTooltipContent;
  }, [
    reviewPageFormDisabledTooltipContent,
    stateDefaultReviewPageDisabledTooltipContent,
  ]);
  const { contentSize } = useContentSizing();

  if (contentSize.isSmall) {
    if (!availableSlots.length) {
      return (
        <NoTimesAvailableView state={state} onCancel={onCancel} isMobile />
      );
    }

    return (
      <MultiStepView
        orderedViews={["selectDate", "selectTime", "review"]}
        onDismiss={onCancel}
        titles={{
          selectDate: "Select date",
          selectTime: "Select time",
          review: isReschedule
            ? content.reviewPageTitle.reschedule
            : content.reviewPageTitle.new,
        }}
        views={{
          selectDate: ({ onContinue, defaultHeaderProps }) => (
            <View
              content={{
                className: "p-0",
              }}
              header={defaultHeaderProps}
              footer={{
                rightActions: (
                  <ButtonGroup>
                    {onCancel && (
                      <Button variant="default" isGhost onClick={onCancel}>
                        Cancel
                      </Button>
                    )}
                    <OptionalTooltip
                      isInstant
                      content={selectDateTooltipContent}
                    >
                      <Button
                        disabled={!!selectDateTooltipContent}
                        onClick={() => {
                          logEvent("Select Date Clicked");
                          onContinue();
                        }}
                      >
                        Continue
                      </Button>
                    </OptionalTooltip>
                  </ButtonGroup>
                ),
              }}
            >
              <SelectDateContent state={state} />
            </View>
          ),
          selectTime: ({ onContinue, onBack, defaultHeaderProps }) => (
            <View
              content={{
                className: "p-0",
              }}
              header={defaultHeaderProps}
              footer={{
                leftActions: (
                  <Button onClick={onBack} isGhost={false}>
                    Back
                  </Button>
                ),
                rightActions: (
                  <ButtonGroup>
                    {onCancel && (
                      <Button variant="default" isGhost onClick={onCancel}>
                        Cancel
                      </Button>
                    )}
                    <OptionalTooltip
                      isInstant
                      content={selectTimeTooltipContent}
                    >
                      <Button
                        disabled={!!selectTimeTooltipContent}
                        onClick={() => {
                          logEvent("Select Time Clicked");
                          onContinue();
                        }}
                      >
                        Continue
                      </Button>
                    </OptionalTooltip>
                  </ButtonGroup>
                ),
              }}
            >
              <SelectTimeContent state={state} />
            </View>
          ),
          review: ({ defaultHeaderProps, onBack }) => (
            <View
              content={{
                className: "p-0",
              }}
              header={defaultHeaderProps}
              footer={{
                leftActions: (
                  <Button onClick={onBack} isGhost={false}>
                    Back
                  </Button>
                ),
                rightActions: (
                  <ButtonGroup>
                    <Button variant="default" isGhost onClick={onCancel}>
                      Cancel
                    </Button>
                    <OptionalTooltip
                      isInstant
                      content={reviewPageDisabledTooltipContent}
                    >
                      <Button
                        variant="primary"
                        disabled={!!reviewPageDisabledTooltipContent}
                        onClick={onSubmit}
                        isLoading={submitLoading}
                      >
                        {isReschedule
                          ? content.reviewPageSubmitButton.reschedule
                          : content.reviewPageSubmitButton.new}
                      </Button>
                    </OptionalTooltip>
                  </ButtonGroup>
                ),
              }}
            >
              <SelfScheduleReview
                state={state}
                variant="small"
                Form={ReviewFormDisplay}
              />
            </View>
          ),
        }}
      />
    );
  }

  const contentProps = {
    className: "p-0 !h-[min(90vh,40rem)]",
  };

  if (!availableSlots.length) {
    return (
      <NoTimesAvailableView
        content={contentProps}
        state={state}
        onCancel={onCancel}
      />
    );
  }

  return (
    <MultiStepView
      orderedViews={["selectDateAndTime", "review"]}
      onDismiss={onCancel}
      titles={{
        selectDateAndTime: "Select date and time",
        review: isReschedule
          ? content.reviewPageTitle.reschedule
          : content.reviewPageTitle.new,
      }}
      views={{
        selectDateAndTime: ({ onContinue, defaultHeaderProps }) => (
          <View
            content={contentProps}
            header={defaultHeaderProps}
            footer={{
              rightActions: (
                <ButtonGroup>
                  {onCancel && (
                    <Button variant="default" isGhost onClick={onCancel}>
                      Cancel
                    </Button>
                  )}
                  <OptionalTooltip isInstant content={selectTimeTooltipContent}>
                    <Button
                      disabled={!!selectTimeTooltipContent}
                      onClick={() => {
                        logEvent("Select Time Clicked");
                        onContinue();
                      }}
                    >
                      Continue
                    </Button>
                  </OptionalTooltip>
                </ButtonGroup>
              ),
            }}
          >
            <SelectDateAndTimeContent state={state} />
          </View>
        ),
        review: ({ defaultHeaderProps, onBack }) => (
          <View
            content={contentProps}
            header={defaultHeaderProps}
            footer={{
              leftActions: (
                <Button onClick={onBack} isGhost={false}>
                  Choose a different time
                </Button>
              ),
              rightActions: (
                <ButtonGroup>
                  {onCancel && (
                    <Button variant="default" isGhost onClick={onCancel}>
                      Cancel
                    </Button>
                  )}
                  <OptionalTooltip
                    isInstant
                    content={reviewPageDisabledTooltipContent}
                  >
                    <Button
                      variant="primary"
                      disabled={!!reviewPageDisabledTooltipContent}
                      onClick={() => {
                        if (isReschedule) {
                          logEvent(
                            "Reschedule Self-scheduled Interview Clicked"
                          );
                        } else {
                          logEvent("Schedule Self-schedule Interview Clicked");
                        }
                        onSubmit();
                      }}
                      isLoading={submitLoading}
                    >
                      {isReschedule
                        ? content.reviewPageSubmitButton.reschedule
                        : content.reviewPageSubmitButton.new}
                    </Button>
                  </OptionalTooltip>
                </ButtonGroup>
              ),
            }}
          >
            <SelfScheduleReview state={state} Form={ReviewFormDisplay} />
          </View>
        ),
      }}
    />
  );
}

function useContentSizing() {
  const [contentSize, setContentSize] = useState<"small" | "large">("large");

  const updateContentSize = useCallback(() => {
    const width = window.innerWidth;
    setContentSize(width < 850 ? "small" : "large");
  }, [setContentSize]);

  useEffect(() => {
    updateContentSize();
  }, [updateContentSize]);

  useEffect(() => {
    const handleResize = () => {
      updateContentSize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateContentSize]);

  return useMemo(() => {
    return {
      contentSize: {
        size: contentSize,
        isSmall: contentSize === "small",
        isLarge: contentSize === "large",
      },
    };
  }, [contentSize]);
}
