import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  AtlasContentEditorProps,
  AtlasContentEditorSerializedState,
} from "@resource/atlas/content-editor";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { LexicalEditor } from "lexical";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";

import { FormGroup } from "../../generic/misc/FormGroup";
import { ScheduledInterviewDetailsLiteEditor } from "../../scheduled-interview-editor/ScheduledInterviewDetailsLiteEditor";

export type EditInterviewTitleDialogProps = {
  store: DialogStore;
  interviewTitle: AtlasContentEditorSerializedState;
  contentEditorProps: AtlasContentEditorProps;
  editor: LexicalEditor | null;
  onCancel: () => void;
  onSave: (title: AtlasContentEditorSerializedState) => void;
  valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
};

export function EditInterviewTitleDialog({
  store,
  contentEditorProps,
  editor,
  interviewTitle,
  onCancel,
  onSave,
  valueSet,
}: EditInterviewTitleDialogProps) {
  const [localTitleContent, setLocalTitleContent] =
    useState<AtlasContentEditorSerializedState>(interviewTitle);

  const prevInterviewTitle = usePrevious(interviewTitle);

  useEffect(() => {
    if (!isEqual(prevInterviewTitle, interviewTitle)) {
      setLocalTitleContent(interviewTitle);
      editor?.setEditorState(editor.parseEditorState(interviewTitle));
    }
  }, [editor, interviewTitle, prevInterviewTitle]);

  return (
    <Dialog store={store} size="medium">
      <View
        header={{ title: "Interviewer event title" }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button
                isGhost
                onClick={() => {
                  onCancel();
                  editor?.setEditorState(
                    editor.parseEditorState(interviewTitle)
                  );
                  setLocalTitleContent(interviewTitle);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => onSave(localTitleContent)}
              >
                Save changes
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        <FormGroup
          label="Title"
          subText="Customize the title for your interviewer calendar event."
        >
          <ScheduledInterviewDetailsLiteEditor
            {...contentEditorProps}
            valueSet={valueSet}
            onChange={(editorState) => {
              setLocalTitleContent(editorState.toJSON());
            }}
            initialState={interviewTitle}
            isGhost={false}
          />
        </FormGroup>
      </View>
    </Dialog>
  );
}
