/* eslint-disable import/prefer-default-export */
import "./node-renderer.sass";

import { createComponentUtils } from "../../../__utils/atlas";
import { createNodeRenderer } from "../../renderer";
import { EmbeddedVideo } from "./embed";
import type { SerializedVideoNode } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-VideoDisplay";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// node renderer
// -------------

export const renderVideoNode = createNodeRenderer<SerializedVideoNode>(
  ({ data: { embedData, caption } }) => (
    <div className={ROOT}>
      <EmbeddedVideo embedData={embedData} />
      {caption && <p className="caption">{caption}</p>}
    </div>
  )
);
