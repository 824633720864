import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasBookClosed,
  atlasChevronDown,
  atlasChevronUp,
  atlasFilterPlus,
  atlasTrash,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { TagFilterWithQuery } from "client/components/tag-filters/TagFilterWithQuery";
import { useFlags } from "client/lib/launchdarkly";
// eslint-disable-next-line import/no-restricted-paths
import { EnableExternalInterviewerAlgorithmToggle } from "client/scheduler/core/components/external-interviewers-algorithm/EnableExternalInterviewerAlgorithmToggle";
import FeatureFlags from "generated/FeatureFlags";
import { TagFilterType } from "generated/graphql-codegen/graphql";
import { useState } from "react";
import { formatEntity } from "shared/constants/entities";
import { v4 } from "uuid";

import { EditInterviewerSlotDisplayState } from "../hooks/useEditInterviewerSlotDisplayState";
import { EditPoolConfigSelectedInterviewerDisplay } from "./EditPoolConfigSelectedInterviewerDisplay";
import { EnableInterviewerTrainingToggle } from "./EnableInterviewerTrainingToggle";

export type EditPoolConfigDisplayProps = {
  state: EditInterviewerSlotDisplayState;
};

/** Display an editable pool config slot */
export function EditPoolConfigDisplay({ state }: EditPoolConfigDisplayProps) {
  const { [FeatureFlags.INTERVIEWER_TAGS]: interviewerTagsEnabled } =
    useFlags();
  const {
    interviewerSlot,
    disableInterviewerTraining,
    disableExternalInterviewerAlgorithm,
    onChangeTrainingEnabled,
    onChangeExternalAlgorithmEnabled,
    onTagFiltersChange,
    onRemoveInterviewerSlot,
  } = state;
  const {
    trainingEnabled,
    externalAlgorithmEnabled,
    interviewerPoolsSetting,
    tagFilters,
  } = interviewerSlot;
  const interviewerPool = interviewerPoolsSetting[0];

  const [showTagFilters, setShowTagFilters] = useState(true);

  return (
    <div className="rounded-md group shadow-1 w-full overflow-hidden">
      <div className="bg-light-gray-200 py-1 px-3 text-subtle w-full space-y-2 border-b border-gray-border">
        <div className="flex items-center gap-2 w-full">
          <Icon content={atlasBookClosed} className="w-4 h-4" />
          <p className="text-body-xs grow">{interviewerPool.name}</p>
          {interviewerTagsEnabled && (
            <ButtonGroup size="xs" negativeMargin="right">
              <Button
                isGhost
                className="!text-subtle"
                onClick={() =>
                  onTagFiltersChange([
                    ...(tagFilters ?? []),
                    { id: v4(), type: TagFilterType.INCLUDE_ALL, tags: [] },
                  ])
                }
                icon={atlasFilterPlus}
              />
              <Tooltip content={`Remove ${formatEntity("interviewer slot")}`}>
                <Button
                  isGhost
                  className="!text-subtle"
                  onClick={onRemoveInterviewerSlot}
                  icon={atlasTrash}
                />
              </Tooltip>
              {!!tagFilters?.length && (
                <Button
                  isGhost
                  className="!text-subtle"
                  onClick={() => setShowTagFilters(!showTagFilters)}
                  icon={showTagFilters ? atlasChevronUp : atlasChevronDown}
                />
              )}
            </ButtonGroup>
          )}
        </div>
        {showTagFilters && !!tagFilters?.length && (
          <div className="space-y-1">
            {tagFilters?.map((filter) => (
              <TagFilterWithQuery
                key={filter.id}
                selectedTags={filter.tags}
                onRemoveTag={(tag) => {
                  onTagFiltersChange(
                    tagFilters.map((f) => {
                      if (f.id === filter.id) {
                        return {
                          ...f,
                          tags: f.tags.filter((t) => t.id !== tag.id),
                        };
                      }
                      return f;
                    })
                  );
                }}
                onClearAll={() => {
                  onTagFiltersChange(
                    tagFilters.filter((f) => f.id !== filter.id)
                  );
                }}
                onTagSelect={(tag) => {
                  onTagFiltersChange(
                    tagFilters.map((f) =>
                      f.id === filter.id ? { ...f, tags: [...f.tags, tag] } : f
                    )
                  );
                }}
                onFilterChange={(type) => {
                  onTagFiltersChange(
                    tagFilters.map((f) =>
                      f.id === filter.id ? { ...f, type } : f
                    )
                  );
                }}
                filterType={filter.type}
              />
            ))}
          </div>
        )}
      </div>
      <EditPoolConfigSelectedInterviewerDisplay state={state} />
      {!disableExternalInterviewerAlgorithm && (
        <EnableExternalInterviewerAlgorithmToggle
          externalAlgorithmEnabled={externalAlgorithmEnabled}
          setExternalAlgorithmEnabled={onChangeExternalAlgorithmEnabled}
        />
      )}
      {!disableInterviewerTraining && (
        <EnableInterviewerTrainingToggle
          enableTraining={trainingEnabled}
          setTrainingEnabled={onChangeTrainingEnabled}
          traineeCount={interviewerPool.traineeUserCount}
        />
      )}
      {trainingEnabled && (
        <EditPoolConfigSelectedInterviewerDisplay
          paddingTopClassName="pt-1"
          state={state}
          isShadower
        />
      )}
    </div>
  );
}
