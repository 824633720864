import { Popover, PopoverAnchor, PopoverDisclosure } from "ariakit";
import clsx from "clsx";
import { useMemo } from "react";

import {
  createComponentUtils,
  createRootContext,
  usePExtendedState,
} from "../__utils/atlas";
import {
  createDefaultProps,
  mergePropsIntoSingleChild,
} from "../__utils/react";
import { FloatingCard } from "../floating-card/FloatingCard";
import type {
  AtlasPopoverAnchorComponent,
  AtlasPopoverContentComponent,
  AtlasPopoverContentProps,
  AtlasPopoverRootComponent,
  AtlasPopoverState,
  AtlasPopoverTriggerComponent,
} from "./types";
import { usePopoverState } from "./use-popover-state";

// config
// ------

const COMPONENT_NAME = "Popover";

export const { el, createComponent } = createComponentUtils(COMPONENT_NAME);

// context
// -------

export type MenuRootContext = {
  popover: AtlasPopoverState;
};

export const { RootContext, RootProvider, useRootContext } =
  createRootContext<MenuRootContext>(COMPONENT_NAME);

// root
// ----

/** The root of the popover component. Expects the trigger and the content.
 *
 * @example
 * <Popover.Root>
 *   <Popover.Trigger>
 *     <Button>Show popover</Button>
 *   </Popover.Trigger>
 *   <Popover.Content>
 *     <Popover.Heading>
 *       Heading
 *     </Popover.Heading>
 *     <Popover.Description>
 *       Description
 *     </Popover.Description>
 *     <div>
 *       Content
 *     </div>
 *   </Popover.Content>
 * </Popover.Root>
 */
export const Root = createComponent<AtlasPopoverRootComponent>(
  ({ children, state, ...stateProps }) => {
    const popover = usePopoverState(stateProps);

    const contextValue = useMemo(
      () => ({ popover: state ?? popover }),
      [popover, state]
    );

    return <RootProvider value={contextValue}>{children}</RootProvider>;
  },
  { forwardRef: false, treeName: "Root" }
);

// trigger
// -------

/** The trigger of the popover. Must be a child of `<Popover.Root />`. Expects the
 * button to be used as trigger, passed as a single child.
 *
 * @example
 * <Popover.Trigger>
 *   <Button>Show popover</Button>
 * </Popover.Trigger>
 */
export const Trigger = createComponent<AtlasPopoverTriggerComponent>(
  ({ children, ...p0 }) => {
    const [popover, props] = usePExtendedState(p0, useRootContext().popover);

    return (
      <PopoverDisclosure {...props} state={popover}>
        {(triggerProps) => mergePropsIntoSingleChild(triggerProps, children)}
      </PopoverDisclosure>
    );
  },
  { treeName: "Trigger" }
);

// anchor
// ------

/** The anchor of the popover. Must be a child of `<Popover.Root />`. Expects the
 * element to be used as anchor, passed as a single child.
 *
 * @example
 * <Popover.Anchor>
 *   <div />
 * </Popover.Anchor>
 */
export const Anchor = createComponent<AtlasPopoverAnchorComponent>(
  ({ children, ...p0 }) => {
    const [popover, props] = usePExtendedState(p0, useRootContext().popover);

    return (
      <PopoverAnchor {...props} state={popover}>
        {(anchorProps) => mergePropsIntoSingleChild(anchorProps, children)}
      </PopoverAnchor>
    );
  },
  { treeName: "Anchor" }
);

// content
// -------

const DEFAULT_CONTENT_PROPS = createDefaultProps<AtlasPopoverContentProps>()({
  hasPadding: true,
} as const);

/** The content of a popover. Must be a child of `<Popover.Root />`.
 *
 * @example
 * <Popover.Content>My content</Popover.Content>
 */
export const Content = createComponent<AtlasPopoverContentComponent>(
  ({
    hasPadding = DEFAULT_CONTENT_PROPS.hasPadding,
    children,
    isLoading,
    header,
    footer,
    ...p0
  }) => {
    const [popover, props] = usePExtendedState(p0, useRootContext().popover);
    return popover.mounted ? (
      <Popover
        state={popover}
        {...props}
        className={clsx(el`content`, props.className)}
      >
        {(popoverProps) => (
          <FloatingCard
            header={header}
            footer={footer}
            data-placement={popover.currentPlacement}
            isLoading={isLoading}
            bodyProps={{
              className: clsx(el`content-body`, { isLoading, hasPadding }),
            }}
            {...popoverProps}
          >
            {children}
          </FloatingCard>
        )}
      </Popover>
    ) : null;
  },
  { treeName: "Content" }
);

// heading and description
// -----------------------

// TODO: wrap instead of re-exporting
export {
  /** The description of a popover. Must be a child of `<Popover.Content />`.
   *
   * @example
   * <Popover.Description>My popover description.</Popover.Description>
   */
  PopoverDescription as Description,
  /** The heading of a popover. Must be a child of `<Popover.Content />`.
   *
   * @example
   * <Popover.Heading>My popover heading</Popover.Heading>
   */
  PopoverHeading as Heading,
} from "ariakit";
