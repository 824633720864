import { AtssyncAccountType } from "@prisma/client";

export type GlobalUsersType =
  | "Guide"
  | "Atssync"
  | "InterviewerPlaceholder"
  | "GuideAI";

export const GlobalUsers: {
  [key in GlobalUsersType]: {
    userId: string;
    name: string;
    email: string;
    imageUrl: string | null;
  };
} = {
  Guide: {
    userId: "2b0afa64-219f-4f10-8ef0-f4fc14817df0",
    name: "Guide",
    email: "guide@global.com",
    imageUrl: "https://ucarecdn.com/be7f49f3-3b7a-4d34-bb68-92212cf307b5/",
  },
  Atssync: {
    userId: "521950f3-8566-444e-855c-8d20aa584d26",
    name: "ATS Sync",
    email: "atssync@global.com",
    imageUrl: null,
  },
  InterviewerPlaceholder: {
    userId: "d40bfa92-b352-4bb7-a64f-5c71e4536dea",
    name: "Interviewer Placeholder",
    email: "interviewer-placeholder@global.guide.co",
    imageUrl: null,
  },
  GuideAI: {
    userId: "0559b0c2-d41d-4a3e-9663-3714cac38a91",
    name: "Aria",
    email: "guide-ai@global.guide.co",
    imageUrl: "https://ucarecdn.com/21ca0c1c-880d-47df-98db-635f68b388ef/",
  },
};

export const AtssyncImages = {
  [AtssyncAccountType.GREENHOUSE]:
    "https://ucarecdn.com/f3bf0580-b6ec-41f4-93bf-0f24c55f0534/",
  [AtssyncAccountType.ASHBY]:
    "https://ucarecdn.com/f16159bc-eeeb-4ddc-b81c-8909cff56143/",
  [AtssyncAccountType.LEVER]: null,
};
