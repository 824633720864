import { FormGroup } from "client/components/generic/misc/FormGroup";
import { RollingDateRangeConfig } from "shared/self-schedule/types";

import { RollingDateRangeConfigSelect } from "./RollingDateRangeConfigSelect";

type EditRollingDateRangeProps = {
  rollingDateRange: RollingDateRangeConfig;
  onChange: (buffer: RollingDateRangeConfig) => unknown;
  className?: string;
};

export function EditRollingDateRange({
  rollingDateRange,
  onChange,
  className,
}: EditRollingDateRangeProps) {
  return (
    <FormGroup
      className={className}
      label="Rolling Date Range"
      subText="Set how many days into the future a candidate can see."
    >
      <RollingDateRangeConfigSelect
        value={rollingDateRange}
        onChange={onChange}
      />
    </FormGroup>
  );
}
