import { SerializedRichBlockNode } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { SelfScheduleRequestForCreate } from "shared/self-schedule/types";

// config
// ------

export const RICH_BLOCK_NAME = "self-schedule-request";

export const DEFAULT_DATA: SelfScheduleRequestData = {
  needsInterviewSelection: true,
};

// types
// -----

export type SelfScheduleRequestName = typeof RICH_BLOCK_NAME;

// Self schedule request is already created
export type CreatedSelfScheduleRequestData = {
  selfScheduleRequestId: string;
  originalInputData: SelfScheduleRequestForCreate;
};

export type SelfScheduleRequestPreviewData = SelfScheduleRequestForCreate & {
  preview: true;
  actorId: string;
};

// We know what interview this self schedule request is for, but it needs configuration
export type NeedsConfigurationSelfScheduleRequestData = {
  interviewId: string;
  needsConfiguration: true;
};

export type SelectInterviewForSelfScheduleRequestData = {
  needsInterviewSelection: true;
};

// There was an error somewhere in the self schedule request
export type InvalidSelfScheduleRequestData = {
  error: string;
};

export type SelfScheduleRequestData =
  | CreatedSelfScheduleRequestData
  | SelfScheduleRequestPreviewData
  | SelfScheduleRequestForCreate
  | NeedsConfigurationSelfScheduleRequestData
  | SelectInterviewForSelfScheduleRequestData
  | InvalidSelfScheduleRequestData;

export type SerializedSelfScheduleRequestNode = SerializedRichBlockNode<
  SelfScheduleRequestName,
  SelfScheduleRequestData
>;
