import { PostTemplateType } from "generated/graphql-codegen/graphql";

export const postTemplateTypeToDisplayText: {
  [type in PostTemplateType]: string;
} = {
  [PostTemplateType.candidate_messaging]: "Candidate messaging",
  [PostTemplateType.request_availability]: "Request availability",
  [PostTemplateType.candidate_interview_confirmation]: "Candidate confirmation",
  [PostTemplateType.interviewer_interview_confirmation]:
    "Interviewer confirmation",
  [PostTemplateType.request_additional_availability]:
    "Request additional availability",
  [PostTemplateType.candidate_reschedule_interview_confirmation]:
    "Updated candidate confirmation",
  [PostTemplateType.self_schedule_request]: "Self-schedule request",
};
