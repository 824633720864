import { IGif as GiphyGifData } from "@giphy/js-types";
import { Gif as GiphyGif } from "@giphy/react-components";
import clsx from "clsx";
import { ComponentPropsWithoutRef, useEffect, useState } from "react";
import { giphyFetch } from "shared/guide-content/rich-blocks/gif";
import { useResponsiveValue } from "utils/responsive";

const POWERED_BY_GIPHY_LOGO =
  "https://ucarecdn.com/9f3ca4c6-6fa5-40b1-82a9-42b22e974c6b/-/preview/";

export type GifProps = ComponentPropsWithoutRef<"div"> & { giphyId: string };

export function Gif({ giphyId, ...props }: GifProps) {
  const [gif, setGif] = useState<GiphyGifData | null>(null);

  const containerClass = useResponsiveValue({
    xs: "w-64",
    sm: "w-96",
  });
  const gifWidth = useResponsiveValue({
    xs: 256,
    sm: 384,
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await giphyFetch.gif(giphyId);
        setGif(data);
      } catch (err) {
        console.error("failed to fetch gif");
      }
    })();
  }, [giphyId]);

  return (
    gif && (
      <div {...props}>
        <div className={clsx("relative", containerClass)}>
          <GiphyGif
            gif={gif}
            width={gifWidth}
            onGifClick={(_, e) => e.preventDefault()}
            hideAttribution
          />
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            className="absolute opacity-50 w-40 bottom-[0.25rem] right-0"
            src={POWERED_BY_GIPHY_LOGO}
            alt="Powered by Giphy"
          />
        </div>
      </div>
    )
  );
}
