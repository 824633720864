/** TextField errorMessages get scrunched with inline text fields, so we have this component instead. */
export default function AlgorithmSettingsError({
  errorMessage,
}: {
  errorMessage?: string | null;
}) {
  if (!errorMessage) {
    return null;
  }

  return <div className="text-body-sm text-red-500 mt-1">{errorMessage}</div>;
}
