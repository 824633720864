import { Link } from "@resource/atlas/link/Link";
import TextField from "@resource/atlas/textfield/TextField";
import { GenerationSlotAction } from "client/components/link-generation/hooks/GenerationSlotAction";
import { GenerationSlotSubtext } from "client/components/link-generation/hooks/GenerationSlotSubtext";
import { ScheduledInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { useDebouncedOnChange } from "client/hooks/useDebouncedOnChange";
import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";

import { PhoneInput } from "../../generic/inputs/PhoneInput";
import { FormGroup, FormGroupProps } from "../../generic/misc/FormGroup";
import { isGenerationService } from "../utils/helpers";
import { ConferencingSlot } from "../utils/types";
import { ZoomHostSelect } from "../wrappers/ZoomHostSelect";
import { ConferencingSettingSelect } from "./ConferencingSettingSelect";

export type EditConferencingSlotProps = {
  conferencingSlot: ConferencingSlot | null;
  originalConferencingSlot?: ConferencingSlot | null;
  onChangeConferencingSlot: (conferencingSlot: ConferencingSlot | null) => void;
  /** Receive this separately to only set the fields and not use prev to avoid race condiiton issues */
  handleZoomHostChange: (hostUserMembershipId: string | null) => void;
  interviewerUserMembershipIds: string[];
  locationFormGroupProps?: FormGroupProps;
  zoomHostGroupProps?: FormGroupProps;
  phoneEnabled?: boolean;
  scheduledInterviewGroupSettings?: ScheduledInterviewGroupSettings;
  /** Hacky way of indicating that our logic for setting group settings on unscheduled interviews has run */
  hasGroupSettingsBeenInitialized?: boolean;
};

/**
 * Edit a conferencing slot with generation settings and requirements.
 * Could manage generation settings or set a value directly.
 * Currently only used during internal scheduling.
 */
export function EditConferencingSlot({
  conferencingSlot,
  originalConferencingSlot,
  onChangeConferencingSlot: passedOnChangeConferencingSlot,
  handleZoomHostChange,
  interviewerUserMembershipIds,
  locationFormGroupProps,
  zoomHostGroupProps,
  phoneEnabled = true,
  scheduledInterviewGroupSettings,
  hasGroupSettingsBeenInitialized,
}: EditConferencingSlotProps) {
  const {
    service,
    existingConferencing,
    editingConferencing,
    generationSettings,
    requirements,
  } = conferencingSlot || {};

  const groupHasReusableLink = useMemo(() => {
    return !!scheduledInterviewGroupSettings?.reuseVideoConferencingLink;
  }, [scheduledInterviewGroupSettings]);

  const isUsingGroupSettings = useMemo(() => {
    // only look at using group settings flag if the group has a reusable link
    return Boolean(
      groupHasReusableLink && conferencingSlot?.isUsingGroupSettings
    );
  }, [conferencingSlot?.isUsingGroupSettings, groupHasReusableLink]);

  const hasExistingGeneratedURL = useMemo(() => {
    if (conferencingSlot) {
      if (
        service &&
        isGenerationService(service) &&
        existingConferencing?.value
      ) {
        return true;
      }
    }

    return false;
  }, [conferencingSlot, service, existingConferencing]);
  const alreadyWasUnlinked = useMemo(() => {
    return !originalConferencingSlot?.isUsingGroupSettings;
  }, [originalConferencingSlot]);
  const isRelinking = useMemo(() => {
    return alreadyWasUnlinked && conferencingSlot?.isUsingGroupSettings;
  }, [alreadyWasUnlinked, conferencingSlot?.isUsingGroupSettings]);

  const existingUrl = useMemo(() => {
    if (!isRelinking) {
      return existingConferencing?.value;
    }

    // If we are relinking to the group, we want to indicate the existing link that this interview will have
    return scheduledInterviewGroupSettings?.existingConferencing?.value;
  }, [isRelinking, existingConferencing, scheduledInterviewGroupSettings]);

  const onChangeConferencingSlot = useCallback(
    (passedConferencingSettings: ConferencingSlot | null) => {
      const isChangingService = passedConferencingSettings?.service !== service;
      const isSettingBackToOriginalService =
        passedConferencingSettings?.service ===
        originalConferencingSlot?.service;

      if (!passedConferencingSettings) {
        if (originalConferencingSlot) {
          // if we came in with a slot, we want to preserve requirements and other data on the slot when unsetting
          passedOnChangeConferencingSlot({
            ...conferencingSlot,
            generationSettings: null,
            service: null,
          });
        } else {
          // if it was null, we should just set back to null so it doesn't show as changes
          passedOnChangeConferencingSlot(null);
        }

        return;
      }

      if (isChangingService && isSettingBackToOriginalService) {
        // If they have a link already and change away from the service and back,
        // restore the full slot back to the original settings
        passedOnChangeConferencingSlot(originalConferencingSlot ?? null);
      } else if (
        isChangingService &&
        passedConferencingSettings.service &&
        isGenerationService(passedConferencingSettings.service)
      ) {
        // If they change services to a new generation service, automatically regenerate the link
        passedOnChangeConferencingSlot({
          ...passedConferencingSettings,
          generationSettings: {
            service: passedConferencingSettings.service,
            ...passedConferencingSettings.generationSettings,
            regenerateLink: true,
          },
        });
      } else {
        passedOnChangeConferencingSlot(passedConferencingSettings);
      }
    },
    [
      conferencingSlot,
      passedOnChangeConferencingSlot,
      originalConferencingSlot,
      service,
    ]
  );

  const onRegenerateLink = useCallback(() => {
    if (conferencingSlot) {
      onChangeConferencingSlot({
        ...conferencingSlot,
        generationSettings: generationSettings
          ? {
              ...generationSettings,
              regenerateLink: true,
            }
          : null,
      });
    }
  }, [conferencingSlot, onChangeConferencingSlot, generationSettings]);

  const onChangeRequirements = useCallback(
    (newRequirements: ConferencingSlot["requirements"] | null) => {
      if (conferencingSlot) {
        onChangeConferencingSlot({
          ...conferencingSlot,
          requirements: newRequirements,
        });
      }
    },
    [conferencingSlot, onChangeConferencingSlot]
  );

  const toggleUsingGroupSettings = useCallback(() => {
    const toggledIsUsingGroupSettings = !conferencingSlot?.isUsingGroupSettings;
    const isUnlinking = !toggledIsUsingGroupSettings;

    if (isUnlinking) {
      if (alreadyWasUnlinked) {
        // If the slot was already unlinked and we unlink it again, we want to restore the original settings
        onChangeConferencingSlot(originalConferencingSlot ?? null);
      } else {
        onChangeConferencingSlot({
          ...conferencingSlot,
          isUsingGroupSettings: false,
          generationSettings: conferencingSlot.service
            ? {
                service: conferencingSlot.service,
                ...conferencingSlot.generationSettings,
                regenerateLink: true,
              }
            : undefined,
        });
      }
    } else {
      onChangeConferencingSlot({
        ...conferencingSlot,
        service:
          scheduledInterviewGroupSettings?.conferencingGenerationSettings
            ?.service ?? null,
        isUsingGroupSettings: true,
        generationSettings:
          scheduledInterviewGroupSettings?.conferencingGenerationSettings,
      });
    }
  }, [
    alreadyWasUnlinked,
    conferencingSlot,
    onChangeConferencingSlot,
    originalConferencingSlot,
    scheduledInterviewGroupSettings?.conferencingGenerationSettings,
  ]);

  const { value: customValue, setValue: onCustomValueChange } =
    useDebouncedOnChange({
      defaultValue:
        editingConferencing?.value ?? existingConferencing?.value ?? "",
      onChange: (value) => {
        if (conferencingSlot) {
          onChangeConferencingSlot({
            ...conferencingSlot,
            editingConferencing: {
              ...editingConferencing,
              value,
            },
          });
        }
      },
    });
  const disableAutoSwap = useMemo(() => {
    if (!hasGroupSettingsBeenInitialized) {
      // If the group settings have not been initialized, we want to disable the auto swap until we know for sure
      return true;
    }

    if (isUsingGroupSettings) {
      return true;
    }

    return false;
  }, [hasGroupSettingsBeenInitialized, isUsingGroupSettings]);

  return (
    <div
      className={clsx("space-y-3", {
        "p-4 shadow-1 rounded-md": groupHasReusableLink,
        "bg-light-gray-200": isUsingGroupSettings,
      })}
    >
      {groupHasReusableLink && (
        <GroupConferencingManagement
          isUsingGroupSettings={isUsingGroupSettings}
          toggleUsingGroupSettings={toggleUsingGroupSettings}
        />
      )}
      <FormGroup
        label="Conferencing"
        isRequired={!!requirements?.service}
        subText={
          <GenerationSlotSubtext
            existingUrl={existingUrl}
            generationSettings={generationSettings}
            slotHasSelection={!!service}
            hasExistingGeneratedURL={hasExistingGeneratedURL}
            onRegenerateLink={onRegenerateLink}
            requirements={requirements}
            originalRequirements={originalConferencingSlot?.requirements}
            onChangeRequirements={onChangeRequirements}
            hideActions={isUsingGroupSettings}
          />
        }
        Action={
          <GenerationSlotAction
            requirements={requirements}
            originalRequirements={originalConferencingSlot?.requirements}
          />
        }
        {...locationFormGroupProps}
      >
        <ConferencingSettingSelect
          conferencingType={service}
          phoneEnabled={phoneEnabled}
          customEnabled
          onSelect={(newService) => {
            if (newService) {
              onChangeConferencingSlot({
                ...conferencingSlot,
                service: newService,
                generationSettings: {
                  ...generationSettings,
                  service: newService,
                },
              });
            } else {
              onChangeConferencingSlot(null);
            }
          }}
          disabled={isUsingGroupSettings}
        />
        {service === "OTHER" && (
          <TextField
            className="w-full mt-2"
            value={customValue}
            onChange={onCustomValueChange}
            placeholder="Add video conferencing link"
            aria-label="Video conferencing link"
            isDisabled={isUsingGroupSettings}
          />
        )}
        {service === "PHONE" && (
          <PhoneInput
            className="w-full"
            wrapperClassName="mt-2"
            value={customValue}
            onChange={onCustomValueChange}
            placeholder="Add phone number"
            aria-label="Phone number"
            isDisabled={isUsingGroupSettings}
          />
        )}
      </FormGroup>
      {service === ConferencingType.ZOOM && (
        <FormGroup label="Zoom host" isRequired {...zoomHostGroupProps}>
          <ZoomHostSelect
            hostUserMembershipId={generationSettings?.hostUserMembershipId}
            onSelect={(val) => {
              handleZoomHostChange(val?.id ?? null);
            }}
            interviewerUserMembershipIds={interviewerUserMembershipIds}
            disabled={isUsingGroupSettings}
            disableAutoSwap={disableAutoSwap}
          />
        </FormGroup>
      )}
    </div>
  );
}

function GroupConferencingManagement({
  isUsingGroupSettings,
  toggleUsingGroupSettings,
}: {
  isUsingGroupSettings: boolean;
  toggleUsingGroupSettings: () => void;
}) {
  const text = useMemo(() => {
    if (isUsingGroupSettings) {
      return "Conferencing is managed for the entire panel.";
    }

    return "Unlinked from panel settings.";
  }, [isUsingGroupSettings]);
  const actionText = useMemo(() => {
    if (isUsingGroupSettings) {
      return "Unlink";
    }

    return "Re-link";
  }, [isUsingGroupSettings]);

  return (
    <div className="flex justify-between space-x-2 text-body-sm">
      <span className="text-gray-500">{text}</span>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onClick={toggleUsingGroupSettings}>{actionText}</Link>
    </div>
  );
}
