import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { useMemo } from "react";

import { useCalendarContext } from "./context";
import { Event } from "./Event";
import { EventGroup } from "./EventsGroup";
import { HorizontalLines } from "./HorizontalLines";
import { CalendarEvent } from "./types";
import { useDaysToRender } from "./useDaysToRender";
import { ROWS_IN_DAY, useCalendarSizes } from "./utils";
import { VerticalLines } from "./VerticalLines";

export function EventsView() {
  const calendarSizes = useCalendarSizes();

  return (
    <div className="flex flex-auto">
      <div
        className="sticky left-0 z-10 flex-none bg-white ring-1 ring-[#EEEDEF]"
        style={{
          width: `${calendarSizes.leftOffsetWidth.rem}rem`,
        }}
      />
      <div className="grid flex-auto grid-cols-1 grid-rows-1">
        <HorizontalLines />
        <VerticalLines />
        <Events />
      </div>
    </div>
  );
}

function Events() {
  const { events, setEventsViewRef } = useCalendarContext();
  const days = useDaysToRender();
  const calendarSizes = useCalendarSizes();

  const { backgroundEvents, foregroundEvents } = useMemo(() => {
    const internalBackgroundEvents: CalendarEvent[] = [];
    const internalForegroundEvents: CalendarEvent[] = [];

    events.forEach((event) => {
      if (event.isBackgroundEvent) {
        internalBackgroundEvents.push(event);
      } else {
        internalForegroundEvents.push(event);
      }
    });

    return {
      backgroundEvents: internalBackgroundEvents,
      foregroundEvents: internalForegroundEvents,
    };
  }, [events]);
  const eventGroups = useMemo(() => {
    const groups: CalendarEvent[][] = [];
    let eventsCopy = [...foregroundEvents];

    do {
      const leftoverEvents: CalendarEvent[] = [];
      let eventGroup: CalendarEvent[] = [];

      for (let i = 0; i < eventsCopy.length; i += 1) {
        const currEvent = eventsCopy[i];

        // Start a new group with the first event
        if (eventGroup.length === 0) {
          eventGroup = [currEvent];
        } else {
          const lastEventInGroup = eventGroup[eventGroup.length - 1];

          if (currEvent.startTime < lastEventInGroup.endTime) {
            if (
              lastEventInGroup.startTime < currEvent.startTime &&
              lastEventInGroup.endTime > currEvent.endTime
            ) {
              // The current event is engulfed by the last event in the group, add it to the leftover events
              leftoverEvents.push(currEvent);
            } else {
              // The current event overlaps with the last event in the group but is not engulfed by, add it to the group
              eventGroup.push(currEvent);
            }
          } else {
            // No overlap, add it to the leftover events
            leftoverEvents.push(currEvent);
          }
        }
      }

      eventsCopy = [...leftoverEvents];
      if (eventGroup.length) groups.push(eventGroup);
    } while (eventsCopy.length > 0);

    return groups;
  }, [foregroundEvents]);

  return (
    <ol
      className={clsx(
        "col-start-1 col-end-2 row-start-1 grid grid-cols-1 relative",
        {
          "grid-cols-1": days.length === 1,
          "grid-cols-2": days.length === 2,
          "grid-cols-3": days.length === 3,
          "grid-cols-4": days.length === 4,
          "grid-cols-5": days.length === 5,
          "grid-cols-6": days.length === 6,
          "grid-cols-7": days.length === 7,
        }
      )}
      id="calendar-events-view-portal"
      ref={(r) => setEventsViewRef(r)}
      style={{
        gridTemplateRows: `0 repeat(${ROWS_IN_DAY}, minmax(0, 1fr)) auto`,
        paddingRight: `${calendarSizes.rightOffsetWidth.rem}rem`,
      }}
      data-cy={CypressData.calendar.eventsView}
    >
      {eventGroups.map((eventGroup) => {
        if (eventGroup.length === 1) {
          return (
            <Event
              event={eventGroup[0]}
              key={`${eventGroup[0].id}-${
                eventGroup[0].calendarId
              }-${eventGroup[0].startTime.toISO()}`}
              eventProps={{
                style: {
                  marginRight: "0.75rem",
                },
              }}
            />
          );
        }

        return (
          <EventGroup
            eventGroup={eventGroup}
            key={`${eventGroup[0].id}-${
              eventGroup[0].calendarId
            }-${eventGroup[0].startTime.toISO()}`}
          />
        );
      })}
      {backgroundEvents.map((event) => {
        return (
          <Event
            event={event}
            key={`${event.id}-${event.calendarId}-${event.startTime.toISO()}`}
          />
        );
      })}
    </ol>
  );
}
