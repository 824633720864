import { MonthCalendar } from "client/components/calendar/MonthCalendar/MonthCalendarV2";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";

export function SelfScheduleDateSelect({
  state,
}: {
  state: SelfScheduleSubmissionState;
}) {
  const { selectedDay, setSelectedDay, availableDays } = state;

  return (
    <div>
      <MonthCalendar
        selected={selectedDay}
        onSelect={(day) => {
          setSelectedDay(day);
        }}
        allowedDays={availableDays}
      />
    </div>
  );
}
