import { useStagedInterviews } from "client/scheduler/core/hooks/interviews";
import { useInterviewConflictData } from "client/scheduler/core/hooks/useInterviewConflictData";
import { useMemo } from "react";

import type { InterviewConflictHovercardState } from "./useInterviewConflictHovercardState";

export function useHoveringInterviewConflictData({
  state: { hoveringEventId },
}: {
  state: InterviewConflictHovercardState;
}) {
  const interviews = useStagedInterviews();
  const hoveringSchedulingEvent = useMemo(
    () => interviews.find((e) => e.id === hoveringEventId),
    [interviews, hoveringEventId]
  );
  const interviewConflictData = useInterviewConflictData(
    hoveringSchedulingEvent
  );

  return useMemo(
    () => ({
      ...interviewConflictData,
      hoveringSchedulingEvent,
    }),
    [interviewConflictData, hoveringSchedulingEvent]
  );
}

export type InterviewConflictHovercardData = ReturnType<
  typeof useHoveringInterviewConflictData
>;
