import { useMemo, useRef } from "react";

type UseStableTransformDataParams<T, U> = {
  data?: T[];
  loading: boolean;
  transform: (data: T[]) => U[];
};

/* Create a stable data variable that won't get reset to null when the actual data is reloaded, to prevent UI flickers.
 * Also prevents extra work by skipping transform on duplicate data.
 */
export default function useStableTransformData<T, U>({
  data,
  loading,
  transform,
}: UseStableTransformDataParams<T, U>) {
  const prevDataRef = useRef<T[]>([]);
  const prevTransformedDataRef = useRef<U[]>([]);

  const transformedData = useMemo(() => {
    if (loading) {
      return prevTransformedDataRef.current;
    }
    if (data && data !== prevDataRef.current) {
      prevDataRef.current = data;
      prevTransformedDataRef.current = transform(data);
    }
    return prevTransformedDataRef.current;
  }, [data, loading, transform]);

  return { data: transformedData };
}
