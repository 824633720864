import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";
import { DateTime } from "luxon";

import { useCalendarContext } from "./context";
import { DayHeadersView } from "./DayHeadersView";
import { useCalendarSizes } from "./utils";

export function DayHeaders() {
  const { setHeaderRef } = useCalendarContext();
  const calendarSizes = useCalendarSizes();

  return (
    <div
      className="sticky top-0 z-30 flex-none bg-white shadow-md ring-1 ring-black ring-opacity-5"
      ref={(r) => setHeaderRef(r)}
      id="day-headers-view-portal"
      style={{
        paddingRight: `${calendarSizes.rightOffsetWidth.rem}rem`,
      }}
    >
      <DayHeadersView
        getItem={({ day }) => (
          <DayHeader
            key={day.toISODate()}
            prefix={day.toFormat("EEE")}
            day={day.day}
            isToday={day.hasSame(DateTime.now(), "day")}
          />
        )}
      />
    </div>
  );
}

function DayHeader({
  prefix,
  day,
  isToday,
}: {
  prefix: string;
  day: number;
  isToday: boolean;
}) {
  return (
    <div
      className="flex items-center justify-center py-3"
      data-cy={CypressData.calendar.dayHeader}
    >
      <span
        className={clsx(
          "text-body-md",
          isToday ? "flex items-baseline text-purple-300" : "text-subtle"
        )}
      >
        {prefix}{" "}
        <span
          className={clsx(
            "items-center justify-center text-body-md-heavy",
            isToday
              ? "ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-purple-500 text-white"
              : "text-dark"
          )}
        >
          {day}
        </span>
      </span>
    </div>
  );
}
