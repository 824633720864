/* eslint-disable import/prefer-default-export */
import { useEvent } from "../../../../__utils/react";
import { BEFORE_TRIGGER_REGEXP } from "./shared";
import type { MatchFn } from "./types";

// config
// ------

const DEFAULT_MIN_LENGTH = 0;
const DEFAULT_MAX_LENGTH = 75;
const DEFAULT_SPACE_ALLOWED = true;

const ESCAPED_INVALID_CHARS = ".+*?$@|()^-[]\\"
  .split("")
  .map((char) => `\\${char}`)
  .join("");
const INVALID_CHARS = `${ESCAPED_INVALID_CHARS}/,|#{}!%'"~=<>_:;`;

// options type
// ------------

type TypeaheadMatchOptions = {
  minLength?: number;
  maxLength?: number;
  spaceAllowed?: boolean;
  validChars?: string;
};

// match regexp
// ------------

function createMatchRegexp(
  trigger: string,
  { maxLength, spaceAllowed, validChars }: TypeaheadMatchOptions
) {
  const beforeTrigger = `(${BEFORE_TRIGGER_REGEXP})`;
  const spaceMatch = !spaceAllowed ? "\\s" : "";
  const valid = validChars ?? `[^${trigger}${INVALID_CHARS}${spaceMatch}]`;
  const queryStringMatch = `(?:${valid}){0,${maxLength}}`;
  const triggerAndQuery = `([${trigger}](${queryStringMatch}))$`;
  return new RegExp(`${beforeTrigger}${triggerAndQuery}`);
}

// use typeahead match
// -------------------

export function useTypeaheadMatch(
  trigger: string,
  options: TypeaheadMatchOptions = {}
): MatchFn {
  return useEvent((text: string) => {
    const opt = {
      minLength: DEFAULT_MIN_LENGTH,
      maxLength: DEFAULT_MAX_LENGTH,
      spaceAllowed: DEFAULT_SPACE_ALLOWED,
      ...options,
    };

    const matchRegexp = createMatchRegexp(trigger, opt);
    const match = matchRegexp.exec(text);

    // no match
    if (match === null) return undefined;

    const beforeTrigger = match[1];
    const replaceableString = match[2];
    const queryString = match[3];

    // space at the beginning
    if (/^\s/.test(queryString)) return undefined;
    // more than one space in a row
    if (/\s\s/.test(queryString)) return undefined;
    // minimum length not met
    if (queryString.length < opt.minLength) return undefined;

    const leadOffset = match.index + beforeTrigger.length;

    return { leadOffset, replaceableString, queryString };
  });
}
