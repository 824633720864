import { ItemCreator } from "@resource/atlas/__utils/collections";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { AtlasMenuContentItem } from "@resource/atlas/menu";
import { useAuthContext } from "auth/context";
import { OrganizationFeaturesEnum } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import {
  EditPreferencesDialog,
  EditPreferencesDialogProps,
} from "../EditPreferencesDialog";

export function useEditPreferencesMenuItem({
  userMembershipId,
  onCompleted,
}: {
  userMembershipId?: string | null;
  onCompleted?: EditPreferencesDialogProps["onCompleted"];
}) {
  const dialogStore = useDialogStore();
  const { hasFeatureEnabled } = useAuthContext();

  return useMemo(() => {
    if (!hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING)) {
      return {
        render: () => null,
        createItem: () => null,
      };
    }

    return {
      render: () => {
        if (!userMembershipId) {
          return null;
        }

        return (
          <EditPreferencesDialog
            store={dialogStore}
            onCompleted={onCompleted}
            userMembershipId={userMembershipId}
          />
        );
      },
      createItem: (i: ItemCreator<AtlasMenuContentItem>) => {
        if (!userMembershipId) {
          return null;
        }

        return i.item({
          key: "edit-preferences",
          children: "Edit scheduling preferences",
          onClick: () => {
            dialogStore.show();
          },
        });
      },
    };
  }, [dialogStore, hasFeatureEnabled, onCompleted, userMembershipId]);
}
