import { useHovercardState } from "@resource/atlas/hovercard/use-hovercard-state";
import { useCalendarContext } from "client/components/calendar-v2/utils/context";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useInterviewConflictHovercardState() {
  const { hoveringEventInfo, hoveringTriggerElement } = useCalendarContext();
  const hoveringEventId = useMemo(
    () => hoveringEventInfo?.id ?? null,
    [hoveringEventInfo]
  );
  const isHovering = useRef(false);
  const isHoveringEventRef = useRef(false);
  const [localHoveringEventId, setLocalHoveringEventId] =
    useState(hoveringEventId);
  const [localHoveringTriggerElement, setLocalHoveringTriggerElement] =
    useState(hoveringTriggerElement);
  const hovercardState = useHovercardState({
    getAnchorRect: () => {
      if (!localHoveringTriggerElement) {
        return null;
      }

      return localHoveringTriggerElement.getBoundingClientRect();
    },
    placement: "left",
  });

  // keep is hovering event ref in sync
  // need to use a ref here so the correct value is reflected within the closing setTimeout callback
  useEffect(() => {
    if (hoveringTriggerElement) {
      isHoveringEventRef.current = true;
    } else {
      isHoveringEventRef.current = false;
    }
  }, [hoveringTriggerElement]);

  // remove when no longer hovering on either
  useEffect(() => {
    if (!hoveringTriggerElement && !hoveringEventId && !isHovering.current) {
      setTimeout(() => {
        if (!isHovering.current && !isHoveringEventRef.current) {
          hovercardState.hide();
          setLocalHoveringEventId(null);
          setLocalHoveringTriggerElement(null);
        }
      }, 300);
    }
  }, [hovercardState, hoveringEventId, hoveringTriggerElement, isHovering]);

  // set local state when hovering
  useEffect(() => {
    if (hoveringEventId && hoveringTriggerElement) {
      setLocalHoveringEventId(hoveringEventId);
      setLocalHoveringTriggerElement(hoveringTriggerElement);
    }
  }, [hoveringEventId, hoveringTriggerElement]);

  const onMouseEnterHovercard = useCallback(() => {
    isHovering.current = true;
  }, []);

  const onMouseLeaveHovercard = useCallback(() => {
    isHovering.current = false;
  }, []);

  return useMemo(
    () => ({
      hovercardState,
      isVisible: !!localHoveringTriggerElement,
      hoveringEventId: localHoveringEventId,
      onMouseEnterHovercard,
      onMouseLeaveHovercard,
    }),
    [
      hovercardState,
      localHoveringEventId,
      localHoveringTriggerElement,
      onMouseEnterHovercard,
      onMouseLeaveHovercard,
    ]
  );
}

export type InterviewConflictHovercardState = ReturnType<
  typeof useInterviewConflictHovercardState
>;
