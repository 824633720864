import { zodResolver } from "@hookform/resolvers/zod";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { useForm } from "react-hook-form";
import { z } from "zod";

const CancellationInterviewNotificationSettingsSchema = z.object({
  notifyInterviewers: z.boolean(),
  message: z.string().optional(),
});

export type CancellationInterviewNotificationSettingsFormData = z.infer<
  typeof CancellationInterviewNotificationSettingsSchema
>;

export function useCancellationInterviewNotificationSettingsState() {
  const form = useForm<CancellationInterviewNotificationSettingsFormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      notifyInterviewers: true,
    },
    resolver: zodResolver(CancellationInterviewNotificationSettingsSchema),
  });

  const disabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState,
    {
      skipDirtyCheck: true,
    }
  );

  return {
    form,
    disabledTooltipContent,
  };
}

export type CancellationInterviewNotificationSettingsState = ReturnType<
  typeof useCancellationInterviewNotificationSettingsState
>;
