import { useToast } from "@resource/atlas/toast/use-toast";
import { overrideInterviewerSlotInterviewers } from "client/components/interviewer-slots/utils/misc";
import {
  filterAndMapSchedulerStagedScheduledInterviewsToExternalAlgorithmInput,
  mapExternalAlgorithmInterviewerToInterviewer,
} from "client/scheduler/core/components/external-interviewers-algorithm/utils/mapping";
import {
  useSetInterviews,
  useStagedInterviews,
} from "client/scheduler/core/hooks/interviews";
import { useSchedulerGuide } from "client/scheduler/core/hooks/useSchedulerGuide";
import { gql } from "generated/graphql-codegen";
import { LoadDuolingoInterviewersInSchedulerV2Mutation } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

const LOAD_DUOLINGO_INTERVIEWERS_V2 = gql(`
  mutation LoadDuolingoInterviewersInSchedulerV2($input: LoadDuolingoInterviewersInput!) {
    loadDuolingoInterviewers(input: $input) {
      code
      message
      success
      interviews {
        id
        interviewerRequirements {
          id
          interviewer {
            ...UserMembershipForInterviewerSlotMapping
          }
          shadower {
            ...UserMembershipForInterviewerSlotMapping
          }
        }
      }
    }
  }
`);

export default function useLoadExternalInterviewers() {
  const interviews = useStagedInterviews();
  const { candidate } = useSchedulerGuide();

  const [loadInterviewers, { loading }] = useMutation(
    LOAD_DUOLINGO_INTERVIEWERS_V2
  );

  const setInterviews = useSetInterviews();
  const { sendToast } = useToast();

  const onLoadExternalInterviewersCompleted = useCallback(
    (result: LoadDuolingoInterviewersInSchedulerV2Mutation) => {
      const info = result.loadDuolingoInterviewers;
      if (info.success) {
        setInterviews((prevInterviews) => {
          return prevInterviews.map((prevInterview) => {
            const matchingResultInterview = info.interviews.find(
              (resultInterview) =>
                resultInterview.id === prevInterview.interviewRequirement?.id
            );

            if (!matchingResultInterview) {
              return prevInterview;
            }

            return {
              ...prevInterview,
              interviewerSlots: prevInterview.interviewerSlots.map((slot) => {
                const matchingRequirement =
                  matchingResultInterview.interviewerRequirements.find(
                    (requirement) => requirement.id === slot.id
                  );

                if (!matchingRequirement) {
                  return slot;
                }

                return overrideInterviewerSlotInterviewers({
                  slot,
                  interviewer: matchingRequirement.interviewer
                    ? mapExternalAlgorithmInterviewerToInterviewer(
                        matchingRequirement.interviewer
                      )
                    : null,
                  shadowingInterviewer: matchingRequirement.shadower
                    ? mapExternalAlgorithmInterviewerToInterviewer(
                        matchingRequirement.shadower
                      )
                    : null,
                });
              }),
            };
          });
        });
        if (info.interviews.length > 0) {
          sendToast(
            `Interviewers were loaded for ${info.interviews.length} interviews.`,
            { variant: "success" }
          );
        }
      } else {
        console.warn("Failed to load external interviewers", info.message);
        alert(`Failed to load external interviewers: ${info.message}`);
      }
    },
    [setInterviews, sendToast]
  );

  const loadExternalInterviewers = useCallback(
    ({
      interviewerExcludeEmails,
    }: { interviewerExcludeEmails?: string[] } = {}) => {
      return loadInterviewers({
        variables: {
          input: {
            candidateName: candidate.name,
            interviewerExcludeEmails,
            interviews:
              filterAndMapSchedulerStagedScheduledInterviewsToExternalAlgorithmInput(
                interviews
              ),
          },
        },
        onCompleted: onLoadExternalInterviewersCompleted,
      });
    },
    [
      candidate.name,
      loadInterviewers,
      interviews,
      onLoadExternalInterviewersCompleted,
    ]
  );

  return { loadExternalInterviewers, loading };
}
