import {
  GuideManagerGuideV2Fragment,
  GuideStatusEnum,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { HiringDecisionSelect } from "./HiringDecisionSelect";
import { InterviewPlanItem, InterviewPlanSelect } from "./InterviewPlanSelect";

export type InterviewPlanAndHiringDecisionProps = {
  items: InterviewPlanItem[];
  currentInterviewPlanItemId?: string | null;
  isLoading?: boolean;
  candidate?: {
    name: string;
    imageUrl: string;
  };
  hiringDecision: GuideStatusEnum;
  guide: GuideManagerGuideV2Fragment;
};

export function InterviewPlanAndHiringDecision({
  items,
  currentInterviewPlanItemId,
  candidate,
  isLoading,
  guide,
  hiringDecision,
}: InterviewPlanAndHiringDecisionProps) {
  const currentInterviewItem = useMemo(
    () => items.find((item) => item.id === currentInterviewPlanItemId),
    [currentInterviewPlanItemId, items]
  );

  return (
    <div className="flex items-center justify-between gap-2">
      <InterviewPlanSelect
        items={items}
        guide={guide}
        candidate={candidate}
        currentStage={currentInterviewItem}
        isLoading={isLoading}
      />
      <HiringDecisionSelect
        guideId={guide.id}
        candidateName={candidate?.name}
        currentHiringDecision={hiringDecision}
      />
    </div>
  );
}
