import { Event } from "client/components/calendar-v2/components/events/Event";
import { CalendarEventsViewElement } from "client/components/calendar-v2/components/portals/CalendarEventsViewElement";
import { useIsEventInView } from "client/components/calendar-v2/hooks/callbacks";
import { useCalendarOptions } from "client/components/calendar-v2/hooks/options";

import { getCalendarIdsForInterview } from "../../utils/calendar-ids";
import { useCalendarEventHandlers } from "./hooks/useCalendarEventHandlers";
import { usePlacingInterview } from "./hooks/usePlacingInterview";

export function PlaceInterviewCalendarPlugin() {
  const { placingInterview, shouldShowPlacement } = usePlacingInterview();
  const { calendarsGrouping } = useCalendarOptions();

  const { onMouseMove, onMouseDown, movingEvents, movingTime } =
    useCalendarEventHandlers({ clickToPlaceAnywhere: !!placingInterview });

  const isEventInView = useIsEventInView();
  if (
    !placingInterview ||
    !shouldShowPlacement ||
    !onMouseDown ||
    !onMouseMove ||
    movingEvents.length === 0 ||
    !movingTime ||
    movingEvents.every((movingEvent) => !isEventInView(movingEvent))
  ) {
    return null;
  }

  return (
    <CalendarEventsViewElement>
      {movingEvents.map((movingEvent, index) => {
        const calendarIds = getCalendarIdsForInterview(movingEvent);

        return calendarIds.map((calendarId) => (
          <Event
            key={`${calendarId}-${index}`}
            calendarId={calendarId}
            event={{
              id: `placing-interview-${index}`,
              calendarId,
              startTime: movingEvent.startTime,
              endTime: movingEvent.endTime,
              title: movingEvent.title || "Interview",
              colorConfig: {
                color: "temporary",
              },
            }}
            eventProps={{
              className: "!opacity-70",
            }}
            wrapperProps={{
              style: {
                width: "100%",
              },
            }}
            calendarGroupIdx={
              calendarsGrouping?.findIndex((g) => g.id === movingEvent.id) ??
              undefined
            }
          />
        ));
      })}
    </CalendarEventsViewElement>
  );
}
