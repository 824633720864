/* eslint-disable import/prefer-default-export */
import "./node-renderer.sass";

import { createComponentUtils } from "../../../../__utils/atlas";
import { createNodeRenderer } from "../../../renderer";
import { LinkCard } from "../shared/LinkCard";
import type { SerializedLinkCardNode } from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-LinkCard_node-renderer";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// node renderer
// -------------

export const renderLinkCardNode = createNodeRenderer<SerializedLinkCardNode>(
  ({ data }) => <LinkCard data={data} className={ROOT} />
);
