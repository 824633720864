/* eslint-disable react/destructuring-assignment */
import { SSRProvider as ReactAriaProvider } from "@react-aria/ssr";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { ReactElement, ReactNode, useRef } from "react";

// toast provider
// --------------
import { DialogProvider } from "../dialog/DialogContext";
import { AtlasProviderProps } from "./types";

export const NotistackDomRootId = "Guide-NotistackDomRoot";

// config
// ------

const NOTISTACK_OPTIONS: Omit<SnackbarProviderProps, "children"> = {
  maxSnack: 2,
  anchorOrigin: {
    horizontal: "right",
    vertical: "bottom",
  },
};

function ToastProvider(p: SnackbarProviderProps & { children?: ReactNode }) {
  const { children, ...snackbarProviderProps } = p;
  const domRootRef = useRef(null);
  return (
    <div id={NotistackDomRootId} ref={domRootRef}>
      <SnackbarProvider
        {...NOTISTACK_OPTIONS}
        {...snackbarProviderProps}
        domRoot={domRootRef.current ?? undefined}
      >
        {children}
      </SnackbarProvider>
    </div>
  );
}

// design system provider
// ----------------------

function Provider(p: AtlasProviderProps): ReactElement {
  return (
    <ReactAriaProvider>
      <ToastProvider {...p.snackbarProviderProps}>
        <DialogProvider>{p.children}</DialogProvider>
      </ToastProvider>
    </ReactAriaProvider>
  );
}

export default Provider;
