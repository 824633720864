import { ConferencingSchema } from "client/components/conferencing/utils/types";
import {
  ConferencingGenerationSettingsSchema,
  StagedConferencingGenerationSettingsSchema,
} from "shared/guide-scheduler/conferencing/types";
import { z } from "zod";

export const ScheduledInterviewGroupSettingsSchema = z.object({
  reuseVideoConferencingLink: z.boolean(),
  conferencingGenerationSettings:
    ConferencingGenerationSettingsSchema.optional().nullable(),
  existingConferencing: ConferencingSchema.optional().nullable(),
});

export type ScheduledInterviewGroupSettings = z.infer<
  typeof ScheduledInterviewGroupSettingsSchema
>;

export const StagedScheduledInterviewGroupSettingsSchema =
  ScheduledInterviewGroupSettingsSchema.extend({
    conferencingGenerationSettings:
      StagedConferencingGenerationSettingsSchema.nullable(),
  });

export type StagedScheduledInterviewGroupSettings = z.infer<
  typeof ScheduledInterviewGroupSettingsSchema
>;
