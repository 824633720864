import { View } from "@resource/atlas/view/View";
import { OptionSelect } from "client/components/generic/inputs/OptionSelect";
import { Divider } from "client/components/generic/misc/Divider";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { useCallback, useMemo } from "react";

import { CalendarSettingsFormState } from "./__hooks/useCalendarSettingsFormState";

export type CalendarSettingsFormProps = {
  state: CalendarSettingsFormState;
};

export function CalendarSettingsForm({ state }: CalendarSettingsFormProps) {
  const {
    includeWeekends,
    toggleIncludeWeekends,
    twentyFourHourFormat,
    toggleTwentyFourHourFormat,
    dayViewType,
    setDayViewType,
  } = state;
  const betaDayViewEnabled = useMemo(() => {
    return dayViewType === "calendar_and_grouping";
  }, [dayViewType]);
  const onToggleBetaDayView = useCallback(() => {
    setDayViewType(betaDayViewEnabled ? "day" : "calendar_and_grouping");
  }, [betaDayViewEnabled, setDayViewType]);

  const {
    [FeatureFlags.INTERVIEWER_CALENDAR_VIEW]: interviewerCalendarViewEnabled,
  } = useFlags();

  return (
    <View
      as="form"
      header={null}
      content={{
        className: "!py-4 !px-0 w-[16rem]",
      }}
    >
      <Section label="View settings">
        <OptionSelect
          label="Show weekends"
          isSelected={includeWeekends}
          onClick={toggleIncludeWeekends}
        />
        <OptionSelect
          label="24 hour format"
          isSelected={twentyFourHourFormat}
          onClick={toggleTwentyFourHourFormat}
        />
      </Section>

      {interviewerCalendarViewEnabled && (
        <>
          <div className="my-2">
            <Divider />
          </div>

          <Section label="Beta settings">
            <OptionSelect
              label="Enable beta day view"
              isSelected={betaDayViewEnabled}
              onClick={onToggleBetaDayView}
            />
          </Section>
        </>
      )}

      {/**
       TODO: This is the preferred approach
       There's issues with the select in the popover it seems and everytime we open the selector it
       reloads the page for some reason. Need to debug.

        <div className="my-2">
          <Divider />
        </div>
        
        <Section label="Timezone">
          <TimezoneSelectorV2
            onClick={setTimezone}
            value={timezone ?? undefined}
            contentProps={{
              className: "w-[320px]",
            }}
          />
        </Section> 
      */}
    </View>
  );
}

function Section({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <div className="px-2">
      <h3 className="text-body-sm font-medium text-subtle ml-[.5rem] mb-2">
        {label}
      </h3>
      {children}
    </div>
  );
}
