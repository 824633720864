import { Button } from "@resource/atlas/button/Button";
import type { AtlasButtonProps } from "@resource/atlas/button/types";
import { Select } from "@resource/atlas/select";
import type {
  AtlasSelectContentProps,
  AtlasSelectRootProps,
} from "@resource/atlas/select/types";
import clsx from "clsx";

import { useTimezoneSelectionItems } from "./useTimezoneSelectionItems";

type Props = {
  onClick: (timezone: string) => void;
  rootProps?: Omit<AtlasSelectRootProps, "children">;
  contentProps?: Omit<AtlasSelectContentProps, "items">;
  triggerProps?: AtlasButtonProps;
  labelType?: "abbreviation" | "abbreviation-with-offset" | "default";
};

export function TimezoneSelector({
  onClick,
  rootProps = {},
  contentProps = {},
  triggerProps = {},
}: Props) {
  const items = useTimezoneSelectionItems(onClick);

  return (
    <Select.Root searchable {...rootProps}>
      <Select.Trigger>
        <Button {...triggerProps} />
      </Select.Trigger>
      <Select.Content
        {...contentProps}
        items={items}
        className={clsx("w-[320px]", contentProps.className)}
      />
    </Select.Root>
  );
}
