import AlertBanner from "@resource/atlas/alert-banner/AlertBanner";
import { Link } from "@resource/atlas/link/Link";
import { UserMembershipSelectWrapper } from "client/components/interviewer-slots/utils/types";
import { EditScheduledInterviewPrivacy } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/__components/EditScheduledInterviewPrivacy";
import clsx from "clsx";
import { useCallback, useMemo } from "react";

import { EditScheduledInterviewCollaborativeCoding } from "../__components/EditScheduledInterviewCollaborativeCoding";
import { EditScheduledInterviewConferenceRoom } from "../__components/EditScheduledInterviewConferenceRoom";
import { EditScheduledInterviewConferencingSettings } from "../__components/EditScheduledInterviewConferencing";
import { EditScheduledInterviewDate } from "../__components/EditScheduledInterviewDate";
import { EditScheduledInterviewInterviewers } from "../__components/EditScheduledInterviewInterviewers";
import { EditScheduledInterviewStartAndDuration } from "../__components/EditScheduledInterviewStartAndDuration";
import { EditScheduledInterviewTitle } from "../__components/EditScheduledInterviewTitle";
import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";
import { getUnschedulableErrorMessage } from "../utils/errors";

export type UpsertScheduledInterviewFormProps = {
  state: UpsertScheduledInterviewFormState;
  UserMembershipSelect: UserMembershipSelectWrapper;
};

export function UpsertScheduledInterviewForm({
  state,
  UserMembershipSelect,
}: UpsertScheduledInterviewFormProps) {
  const { originalInterview } = state;
  const unschedulableErrorMessage = useMemo(() => {
    return getUnschedulableErrorMessage(
      state.form.getValues("scheduledInterview")
    );
  }, [state.form]);
  const isCancelled = state.form.watch("scheduledInterview.isCancelled");
  const onRestoreInterview = useCallback(() => {
    state.form.setValue("scheduledInterview.isCancelled", false, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [state.form]);
  const isDisabled = useMemo(() => {
    if (isCancelled) {
      return true;
    }

    if (unschedulableErrorMessage) {
      return true;
    }

    return false;
  }, [isCancelled, unschedulableErrorMessage]);

  return (
    <div>
      {isCancelled && (
        <AlertBanner
          className="mb-4"
          variant="danger"
          Action={
            <>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={onRestoreInterview}>Restore and edit</Link>
            </>
          }
        >
          <span className="text-body-md-heavy">
            {originalInterview?.isCancelled
              ? "This interview has been cancelled."
              : "This interview will be cancelled."}
          </span>
        </AlertBanner>
      )}
      {!!unschedulableErrorMessage && (
        <AlertBanner variant="danger" className="mb-4">
          <span className="text-body-md-heavy">
            {unschedulableErrorMessage}
          </span>
        </AlertBanner>
      )}
      <div
        className={clsx(
          "space-y-4 w-full",
          isDisabled && "relative pointer-events-none"
        )}
      >
        <EditScheduledInterviewTitle state={state} />
        <EditScheduledInterviewDate state={state} />
        <EditScheduledInterviewStartAndDuration state={state} />
        <EditScheduledInterviewInterviewers
          state={state}
          UserMembershipSelect={UserMembershipSelect}
        />
        <EditScheduledInterviewConferencingSettings state={state} />
        {!state.hideConferenceRoomSelection && (
          <EditScheduledInterviewConferenceRoom state={state} />
        )}
        {!state.hideCollaborativeCodingSelection && (
          <EditScheduledInterviewCollaborativeCoding state={state} />
        )}
        {!state.hidePrivacyOptions && (
          <EditScheduledInterviewPrivacy state={state} />
        )}
        {isDisabled && (
          <div
            className="absolute inset-0 bg-white bg-opacity-50 z-10"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
}
