import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDebounce } from "react-use";
import { z } from "zod";

const UpdateInterviewInputSchema = z.object({
  title: z.string(),
});

export type UpdateInterviewFormData = z.infer<
  typeof UpdateInterviewInputSchema
>;

export function useUpdateInterviewState({
  defaultInterview,
}: {
  defaultInterview: UpdateInterviewFormData;
}) {
  const form = useForm<UpdateInterviewFormData>({
    defaultValues: {
      title: defaultInterview.title,
    },
    resolver: zodResolver(UpdateInterviewInputSchema),
  });

  const { watch, setValue } = form;

  const title = watch("title");

  const onChange = useCallback(
    (...params: Parameters<typeof setValue>) => {
      setValue(params[0], params[1], {
        shouldDirty: true,
        shouldValidate: true,
        ...params[2],
      });
    },
    [setValue]
  );

  const [localTitle, setLocalTitle] = useState(title);

  useDebounce(
    () => {
      onChange("title", localTitle);
    },
    300,
    [localTitle]
  );

  const onChangeTitle = useCallback((newTitle: string) => {
    setLocalTitle(newTitle);
  }, []);

  const disableTooltipContent =
    Object.keys(form.formState.dirtyFields).length === 0
      ? "No changes to save"
      : "";

  return {
    form,
    title,
    onChangeTitle,
    disableTooltipContent,
  };
}

export type EditInterviewState = ReturnType<typeof useUpdateInterviewState>;
