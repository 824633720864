import { Button } from "@resource/atlas/button/Button";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";

import { CommentFooterState } from "./__hooks/useCommentState";

export function SubmitCommentButton({ state }: { state: CommentFooterState }) {
  if (!state.showCommentButton) {
    return null;
  }

  return (
    <div className="flex justify-end p-2 w-full">
      <OptionalTooltip isInstant>
        <Button
          variant="primary"
          onClick={state.onSubmit}
          isLoading={state.submitLoading}
        >
          Comment
        </Button>
      </OptionalTooltip>
    </div>
  );
}
