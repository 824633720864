/* eslint-disable import/prefer-default-export */
import { atlasTextFormatParagraph } from "../../../../../icons/atlas";
import { Button } from "../../../../button/Button";
import { Menu } from "../../../../menu";
import Tooltip from "../../../../tooltip/Tooltip";
import { useSelection } from "../../selection-context";
import { InsertOption, InsertOptionContext } from "./types";
import { useParagraphStyleItems } from "./use-paragraph-style-items";

export function useParagraphStyleMenu(
  insertOptions: InsertOption[],
  context: Omit<InsertOptionContext, "target" | "getAnchorRect">
) {
  const { isRangeSelection } = useSelection();

  const paragraphStyleItems = useParagraphStyleItems(insertOptions, context);

  return (
    <Menu.Root>
      <Menu.Trigger disabled={!isRangeSelection}>
        <Tooltip isInstant content="Paragraph style">
          <Button
            aria-label="Change paragraph style"
            icon={atlasTextFormatParagraph}
            isDropdown
          />
        </Tooltip>
      </Menu.Trigger>
      <Menu.Content portal items={paragraphStyleItems} />
    </Menu.Root>
  );
}
