import { StagedConferencingGenerationSettingsSchema } from "shared/guide-scheduler/conferencing/types";
import { HourNumberSchema, MinuteNumberSchema } from "shared/utils/time";
import { z } from "zod";

export const SelfScheduleInterviewSettingsInputSchema = z.object({
  duration: z.number(),
  interviewId: z.string().optional().nullable(),
  title: z.string(),
  interviewerId: z.string(),
  locationSettings:
    StagedConferencingGenerationSettingsSchema.optional().nullable(),
  addToCalendarId: z.string().optional().nullable(),
  isPrivate: z.boolean(),
});

export type SelfScheduleInterviewSettingsInput = z.infer<
  typeof SelfScheduleInterviewSettingsInputSchema
>;

export const SelfScheduleInterviewSettingsForCreateSchema = z.object({
  duration: z.number(),
  title: z.string(),
  interview: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .optional()
    .nullable(),
  isPrivate: z.boolean(),
  locationSettings:
    StagedConferencingGenerationSettingsSchema.optional().nullable(),
  interviewer: z.object({
    id: z.string(),
    name: z.string(),
    imageUrl: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
    user: z.object({
      id: z.string(),
      timezone: z.string().optional().nullable(),
    }),
  }),
  addToCalendarId: z.string().optional().nullable(),
});

export type SelfScheduleInterviewSettingsForCreate = z.infer<
  typeof SelfScheduleInterviewSettingsForCreateSchema
>;

export const TimeSchema = z.object({
  hour: HourNumberSchema,
  minute: MinuteNumberSchema,
});

export const SchedulingHoursDayConfigSchema = z.object({
  isWorkingDay: z.boolean(),
  startTime: TimeSchema.optional().nullable(),
  endTime: TimeSchema.optional().nullable(),
});

export const SchedulingHoursConfigSchema = z.array(
  SchedulingHoursDayConfigSchema
);

export const TimeConfigSchema = z.object({
  unit: z.enum(["hours", "minutes", "seconds"]),
  value: z.number(),
});

export const RollingDateRangeConfigSchema = z.object({
  days: z.number(),
  setting: z.enum(["calendar_days", "weekdays"]),
});

export const SelfScheduleSchedulingSettingsInputSchema = z.object({
  schedulingHours: SchedulingHoursConfigSchema,
  preMeetingBuffer: TimeConfigSchema,
  postMeetingBuffer: TimeConfigSchema,
  minimumNotice: TimeConfigSchema,
  rollingDateRange: RollingDateRangeConfigSchema,
});

export const SelfScheduleRequestInputSchema = z.object({
  interviewSettings: SelfScheduleInterviewSettingsInputSchema,
  schedulingSettings: SelfScheduleSchedulingSettingsInputSchema,
  notes: z.string().optional().nullable(),
});

export const SelfScheduleRequestForCreateSchema = z.object({
  interviewSettings: SelfScheduleInterviewSettingsForCreateSchema,
  schedulingSettings: SelfScheduleSchedulingSettingsInputSchema,
  notes: z.string().optional().nullable(),
});

export const SelfScheduleSubmissionInputSchema = z.object({
  selfScheduleRequestId: z.string(),
  notes: z.string().optional().nullable(),
});

export type Time = z.infer<typeof TimeSchema>;
export type SchedulingHoursDayConfig = z.infer<
  typeof SchedulingHoursDayConfigSchema
>;
export type SchedulingHoursConfig = z.infer<typeof SchedulingHoursConfigSchema>;
export type TimeConfig = z.infer<typeof TimeConfigSchema>;
export type RollingDateRangeConfig = z.infer<
  typeof RollingDateRangeConfigSchema
>;
export type SelfScheduleSchedulingSettingsInput = z.infer<
  typeof SelfScheduleSchedulingSettingsInputSchema
>;
export type SelfScheduleRequestInput = z.infer<
  typeof SelfScheduleRequestInputSchema
>;
export type SelfScheduleRequestForCreate = z.infer<
  typeof SelfScheduleRequestForCreateSchema
>;
export type SelfScheduleSubmissionInput = z.infer<
  typeof SelfScheduleSubmissionInputSchema
>;
