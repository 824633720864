import { Tab, TabList, TabProps, TabStore } from "@ariakit/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasInbox, atlasRingPerson } from "@resource/atlas/icons";
import { useLogEvent, useTrackPage } from "analytics";
import clsx from "clsx";
import { trackProperties } from "shared/analytics";
import { formatEntity } from "shared/constants/entities";

import { useExtensionAnalyticsPaging } from "../__hooks/useExtensionAnalyticsPaging";
import { ExtensionTabs } from "../__hooks/useExtensionTabStore";
import { ExtensionCandidateProfileProps } from "./ExtensionCandidateProfile";

type ExtensionTabProps = TabProps & { isSelected: boolean };
function ExtensionTab({ isSelected, children, ...props }: ExtensionTabProps) {
  return (
    <Tab
      className={clsx(
        "flex-1 flex flex items-center justify-center gap-1.5 py-2 rounded-md",
        isSelected ? "text-purple-500 bg-purple-50" : "text-subtle"
      )}
      {...props}
    >
      {children}
    </Tab>
  );
}

export function TabHeader({
  tabStore,
  guide,
}: {
  tabStore: TabStore;
} & Pick<ExtensionCandidateProfileProps, "guide">) {
  const logPage = useTrackPage();
  const logEvent = useLogEvent({
    project: ["View Guide Extension on any Greenhouse page"],
    component: "GlobalExtensionPage",
  });
  const tabState = tabStore.useState();

  useExtensionAnalyticsPaging({
    tabState,
  });

  return (
    <TabList
      store={tabStore}
      className="flex-shrink-0 flex gap-1 mx-6 mb-4 text-body-md-heavy"
      aria-label="Extension navigation"
    >
      <ExtensionTab
        id={ExtensionTabs.schedulingTasks}
        isSelected={tabState.selectedId === ExtensionTabs.schedulingTasks}
        onClick={() => {
          logEvent(
            "Switch to Automation Tab Clicked",
            trackProperties.isCustomerSuccess
          );
          logPage("Extension Scheduling Requests Tab");
        }}
      >
        <Icon content={atlasInbox} />
        <span>
          {formatEntity("scheduling request", {
            capitalize: true,
            plural: true,
          })}
        </span>
      </ExtensionTab>
      <ExtensionTab
        id={ExtensionTabs.candidate}
        isSelected={tabState.selectedId === ExtensionTabs.candidate}
        onClick={() => {
          logEvent(
            "Switch to Candidate Guide Tab Clicked",
            trackProperties.isCustomerSuccess
          );
          logPage("Extension Candidate Management Tab");
        }}
      >
        {guide ? (
          <Avatar size="xs" name={guide.candidate.name} />
        ) : (
          <Icon content={atlasRingPerson} />
        )}
        <span>
          {guide ? `${guide.candidate.firstName}'s Guide` : "Candidate"}
        </span>
      </ExtensionTab>
    </TabList>
  );
}
