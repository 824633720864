import { Filters } from "../hooks/useAutomationFilters";
import { AutomationView } from "../hooks/useAutomationView";

export function constructAutomationDashboardPath({
  view,
  filters,
}: {
  view: AutomationView;
  filters?: Partial<Filters>;
}) {
  const baseUrl = new URL("/internal/automation", window.location.origin);

  baseUrl.searchParams.set("view", view);

  if (filters) {
    baseUrl.searchParams.set("filters", JSON.stringify(filters));
  }

  return baseUrl.toString();
}
