import FeatureFlags from "generated/FeatureFlags";
import { z } from "zod";

export enum NotificationChannel {
  Email = "EMAIL",
  InApp = "IN_APP",
  // SlackDm = "SLACK_DM",
  // Chrome = "CHROME",
}

export enum NotificationType {
  CommentMention = "COMMENT_MENTION",
  SchedulingRequestAssigned = "SCHEDULING_REQUEST_ASSIGNED",
  ImportantAriaMessage = "IMPORTANT_ARIA_MESSAGE",
}

export type NotificationTypeDetails = {
  label: string;
  description: string;
  defaults: { [key in NotificationChannel]: boolean };
  featureFlag?: FeatureFlags;
};

export type NotificationConfiguration = {
  id: NotificationType;
  configuration: { [key in NotificationChannel]: boolean };
};

export const NotificationPreferencesSchema = z.array(
  z.object({
    type: z.nativeEnum(NotificationType),
    channels: z.array(
      z.object({
        channel: z.nativeEnum(NotificationChannel),
        enabled: z.boolean(),
      })
    ),
  })
);

export type NotificationPreferences = z.infer<
  typeof NotificationPreferencesSchema
>;
