import { useModuleConfig } from "@resource/atlas/content-editor/__utils/modules";
import { Interview } from "components/EditScheduledInterview/EditScheduledInterviewForm";
import { RICH_BLOCK_NAME } from "shared/guide-content/rich-blocks/interviews";

declare module "@resource/atlas" {
  interface ContentEditorModuleConfig {
    [RICH_BLOCK_NAME]?: {
      onEdit?: (interview: Interview) => unknown;
      timezone?: string | undefined | null;
    };
  }
}

export function useInterviewsConfig() {
  return useModuleConfig(RICH_BLOCK_NAME, { required: false });
}
