import { LexicalEditor } from "lexical";
import { useCallback } from "react";
import { DEFAULT_DATA as INTERVIEWS_DEFAULT_DATA } from "shared/guide-content/rich-blocks/interviews";

import { INSERT_INTERVIEWS_COMMAND } from "./rich-block";

type UseRequestAvailabilityProps = {
  timezone?: string;
  editor: LexicalEditor;
  logEvent(): void;
};

export function useInsertInterviews({
  timezone,
  editor,
  logEvent,
}: UseRequestAvailabilityProps) {
  return useCallback(() => {
    logEvent?.();
    editor.focus();
    editor.dispatchCommand(INSERT_INTERVIEWS_COMMAND, {
      ...INTERVIEWS_DEFAULT_DATA,
      requesterTimezone:
        timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }, [editor, logEvent, timezone]);
}
