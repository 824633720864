import { Button } from "@resource/atlas/button/Button";
import { useLogEvent } from "analytics";
import { ViewedIndicator } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__components/ViewedIndicator";
import { GuidePostPreview } from "client/components/guide-post/GuidePostPreview/GuidePostPreview";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import { LatestUpdateExtensionGuideFragment } from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef } from "react";
import { formatEntity } from "shared/constants/entities";
import urljoin from "url-join";

gql(`
fragment LatestUpdateExtensionGuide on Guide {
    publicUrl
    latestPost {
      id
      ...GuidePostForPreviewMessage
      ...ViewedIndicatorGuidePost
    }
    postCount
  }
`);

type LatestUpdateExtensionProps = ComponentPropsWithoutRef<"section"> & {
  guide: LatestUpdateExtensionGuideFragment | undefined;
};

export function LatestUpdateExtension({
  guide,
  ...props
}: LatestUpdateExtensionProps) {
  const latestUpdate = guide?.latestPost;
  const logEvent = useLogEvent({
    component: "LatestUpdateExtension",
    project: "Zeus",
  });

  if (!guide) {
    return (
      <section {...props} className={clsx("space-y-3", props.className)}>
        <div className="flex justify-between items-center">
          <h4 className="text-h4">
            Latest {formatEntity("message", { plural: true })}
          </h4>
        </div>
        <div className="w-full rounded-md shadow-1 flex flex-col p-6 h-80">
          <Skeleton className="w-64 h-6" type="text" />
          <div className="flex flex-row mt-3 items-center">
            <Skeleton className="w-6 h-6 mr-2" type="circle" />
            <Skeleton className="w-32 h-4" type="text" />
          </div>
        </div>
      </section>
    );
  }

  if (!latestUpdate) {
    return null;
  }

  const viewAllHref = urljoin(guide?.publicUrl ?? "", "messages");

  return (
    <section {...props} className={clsx("space-y-3", props.className)}>
      <div className="flex justify-between">
        <h4 className="text-h4">Latest {formatEntity("message")}</h4>
        <Button
          as="a"
          href={viewAllHref}
          variant="primary"
          isGhost
          size="xs"
          onClick={() => logEvent("View All Messages Clicked")}
          target="_blank"
        >
          View all
        </Button>
      </div>
      <GuidePostPreview
        post={latestUpdate}
        stackCount={guide.postCount}
        ViewedIndicator={
          // Pass as prop because this component is using old patterns and a bit complicated
          <ViewedIndicator className="flex-shrink-0" update={latestUpdate} />
        }
      />
    </section>
  );
}
