import { useMemo } from "react";

import { useHoveringEventInfo } from "../../../hooks/refs";
import { useCalendarContext } from "../../../utils/context";
import { CalendarEvent } from "../../../utils/types";

/** Compute hover and expanded state for event and group */
export function useEventInteractionState({
  event,
  eventGroupIds,
}: {
  event: CalendarEvent;
  eventGroupIds?: string[];
}) {
  const { focusedEventInfo } = useCalendarContext();
  const { id: eventId, calendarId } = event;
  const hoveringEventInfo = useHoveringEventInfo();

  return useMemo(() => {
    const isExpanded =
      focusedEventInfo?.id === eventId &&
      focusedEventInfo?.calendarId === calendarId;

    const isExpandedGroup = eventGroupIds
      ? focusedEventInfo && eventGroupIds.includes(focusedEventInfo.id)
      : false;

    if (!hoveringEventInfo) {
      return {
        isHovering: false,
        isExpanded,
        isExpandedGroup,
      };
    }

    const isHovering =
      hoveringEventInfo?.id === eventId &&
      hoveringEventInfo?.calendarId === calendarId;

    return {
      isHovering,
      isExpanded,
      isExpandedGroup,
    };
  }, [focusedEventInfo, eventId, calendarId, eventGroupIds, hoveringEventInfo]);
}
