import type { AtlasIconData } from "@resource/atlas/icon/types";
import {
  serviceAshby,
  serviceGreenhouse,
  serviceGreenhouseDisabled,
  serviceLever,
} from "@resource/atlas/icons";
import { AtssyncAccountType } from "generated/graphql-codegen/graphql";

type AtssyncInfo = {
  name: string;
  icon: AtlasIconData;
  disabledIcon: AtlasIconData;
  /** In case extension path ends up being different per ATS */
  extensionPath: string;
};

export const mapAtssyncTypeToInfo: {
  [key in AtssyncAccountType]: AtssyncInfo;
} = {
  [AtssyncAccountType.GREENHOUSE]: {
    name: "Greenhouse",
    icon: serviceGreenhouse,
    disabledIcon: serviceGreenhouseDisabled,
    extensionPath: "/extension",
  },
  [AtssyncAccountType.ASHBY]: {
    name: "Ashby",
    icon: serviceAshby,
    disabledIcon: serviceAshby,
    extensionPath: "/extension",
  },
  [AtssyncAccountType.LEVER]: {
    name: "Lever",
    icon: serviceLever,
    disabledIcon: serviceLever,
    extensionPath: "/extension",
  },
};

export const getAtssyncInfo = (type: AtssyncAccountType): AtssyncInfo => {
  return mapAtssyncTypeToInfo[type];
};
