import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleChecked, atlasCircleWarning } from "@resource/atlas/icons";
import { usePanelSchedulingStatuses } from "client/components/scheduled-interviews/__hooks/usePanelSchedulingStatuses";
import { getPluralized } from "client/utils/strings";
import clsx from "clsx";

import { ScheduledInterviewCardBadge } from "../__components/ScheduledInterviewCardBadge";
import {
  ScheduledInterviewCard,
  ScheduledInterviewCardProps,
  ScheduledInterviewForCard,
} from "../ScheduledInterviewCard";
import { useInternalScheduledInterviewCardStatuses } from "../ScheduledInterviewCard/__hooks/useInternalScheduledInterviewCardStatuses";
import { ScheduledStatus } from "../utils/types";

export type InterviewWithSchedulingInfo = ScheduledInterviewForCard & {
  errors?: {
    message: string;
  }[];
  status: ScheduledStatus | null;
};

type ScheduleSummaryProps = {
  interviewsWithSchedulingInfo: InterviewWithSchedulingInfo[];
  groupBy?: "cancelled" | "errors";
  onClickInterview?: (interview: InterviewWithSchedulingInfo) => void;
  getClickDisabled?: (interview: InterviewWithSchedulingInfo) => boolean;
} & Pick<ScheduledInterviewCardProps, "hideInternalStatuses" | "variant">;

export function ScheduleSummary({
  interviewsWithSchedulingInfo: passedInterviews,
  groupBy,
  ...scheduledInterviewCardProps
}: ScheduleSummaryProps) {
  const interviewsWithSchedulingInfo = [...passedInterviews].sort(
    (a, b) => a.startTime.toMillis() - b.startTime.toMillis()
  );
  if (groupBy === "errors") {
    const successfulInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => !interview.errors?.length
    );
    const errorInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => !!interview.errors?.length
    );
    const showErrorsSection = !!errorInterviews.length;

    return (
      <div
        className={clsx({
          "space-y-2": !showErrorsSection,
          "space-y-6": showErrorsSection,
        })}
      >
        <InterviewsSection
          {...scheduledInterviewCardProps}
          header={
            showErrorsSection ? (
              <GroupByErrorsHeader
                variant="success"
                interviewsWithSchedulingInfo={successfulInterviews}
              />
            ) : null
          }
          interviews={successfulInterviews}
        />
        <InterviewsSection
          {...scheduledInterviewCardProps}
          header={
            <GroupByErrorsHeader
              variant="error"
              interviewsWithSchedulingInfo={errorInterviews}
            />
          }
          interviews={errorInterviews}
        />
      </div>
    );
  }

  if (groupBy === "cancelled") {
    const nonCancelledInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => interview.status !== "cancelled"
    );
    const cancelledInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => interview.status === "cancelled"
    );
    const showCancelledSection = !!cancelledInterviews.length;

    return (
      <div
        className={clsx({
          "space-y-2": !showCancelledSection,
          "space-y-6": showCancelledSection,
        })}
      >
        <InterviewsSection
          {...scheduledInterviewCardProps}
          header="Schedule summary"
          interviews={nonCancelledInterviews}
        />
        <InterviewsSection
          {...scheduledInterviewCardProps}
          header="Cancelled"
          interviews={cancelledInterviews}
        />
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <InterviewsSection
        {...scheduledInterviewCardProps}
        interviews={interviewsWithSchedulingInfo}
        header={null}
      />
    </div>
  );
}

type GroupByErrorsHeaderProps = {
  interviewsWithSchedulingInfo: InterviewWithSchedulingInfo[];
  variant: "error" | "success";
};

function GroupByErrorsHeader({
  interviewsWithSchedulingInfo,
  variant,
}: GroupByErrorsHeaderProps) {
  const schedulingStatus = usePanelSchedulingStatuses({
    interviewsWithSchedulingInfo,
  });

  let verb = "managed"; // default in case we do something dumb with our logic

  if (schedulingStatus.allInterviewsCreated) {
    verb = "created";
  } else if (schedulingStatus.allInterviewsUpdated) {
    verb = "updated";
  } else if (schedulingStatus.interviewsCreatedAndUpdated) {
    verb = "created and updated";
  } else if (schedulingStatus.interviewsCreatedAndCancelled) {
    verb = "created and cancelled";
  } else if (schedulingStatus.interviewsUpdatedAndCancelled) {
    verb = "updated and cancelled";
  } else if (schedulingStatus.interviewsCreatedUpdatedAndCancelled) {
    verb = "created, updated and cancelled";
  }

  return (
    <div className="flex flex-row space-x-2 text-body-md-heavy">
      {variant === "success" ? (
        <Icon content={atlasCircleChecked} className="text-green-500 w-5 h-5" />
      ) : (
        <Icon content={atlasCircleWarning} className="text-red-500 w-5 h-5" />
      )}
      <span>
        {interviewsWithSchedulingInfo.length}{" "}
        {getPluralized({
          count: interviewsWithSchedulingInfo.length,
          singular: "interview was",
          plural: "interviews were",
        })}{" "}
        {verb} {variant === "success" ? "successfully" : "with errors"}
      </span>
    </div>
  );
}

function InterviewsSection({
  header,
  interviews,
  onClickInterview,
  getClickDisabled,
  ...scheduledInterviewCardProps
}: {
  header: string | JSX.Element | null;
  interviews: InterviewWithSchedulingInfo[];
  onClickInterview?: (interview: InterviewWithSchedulingInfo) => void;
  getClickDisabled?: (interview: InterviewWithSchedulingInfo) => boolean;
} & Pick<ScheduledInterviewCardProps, "hideInternalStatuses" | "variant">) {
  if (!interviews.length) {
    return null;
  }

  return (
    <div className="space-y-2">
      {typeof header === "string" ? (
        <h4 className="text-h4">{header}</h4>
      ) : (
        header
      )}
      {interviews.map((scheduledInterview) => (
        <ScheduledInterviewCardForSummary
          {...scheduledInterviewCardProps}
          onClick={
            onClickInterview && !getClickDisabled?.(scheduledInterview)
              ? () => onClickInterview(scheduledInterview)
              : undefined
          }
          scheduledInterview={scheduledInterview}
          key={scheduledInterview.id}
        />
      ))}
    </div>
  );
}

function ScheduledInterviewCardForSummary({
  scheduledInterview,
  ...scheduledInterviewCardProps
}: {
  scheduledInterview: InterviewWithSchedulingInfo;
} & Pick<
  ScheduledInterviewCardProps,
  "hideInternalStatuses" | "variant" | "onClick"
>) {
  const statuses = useInternalScheduledInterviewCardStatuses({
    scheduledInterview,
  });

  return (
    <ScheduledInterviewCard
      {...scheduledInterviewCardProps}
      scheduledInterview={scheduledInterview}
      errorMessages={scheduledInterview.errors?.map((e) => e.message)}
      Badge={
        <ScheduledInterviewCardBadge
          status={scheduledInterview.status}
          hideNewBadge
        />
      }
      statuses={statuses}
      key={scheduledInterview.id}
    />
  );
}
