import {
  GuideAvailabilityRequestNode,
  INSERT_GUIDE_AVAILABILITY_REQUEST_COMMAND,
} from "client/guide-availability/rich-blocks/guide-availability-request/__lib/rich-block";
import {
  INSERT_INTERVIEWS_COMMAND,
  InterviewsNode,
} from "client/guide-content/rich-blocks/interviews/__lib/rich-block";
import {
  INSERT_SELF_SCHEDULE_REQUEST_COMMAND,
  SelfScheduleRequestNode,
} from "client/self-schedule/rich-blocks/self-schedule-request/rich-block";
import { $nodesOfType, Klass, LexicalEditor, LexicalNode } from "lexical";
import { useCallback } from "react";
import { DEFAULT_DATA as GUIDE_AVAILABILITY_REQUEST_DEFAULT_DATA } from "shared/guide-availability/rich-blocks/guide-availability-request";
import { DEFAULT_DATA as INTERVIEWS_DEFAULT_DATA } from "shared/guide-content/rich-blocks/interviews";
import { PostMessageWorkflow } from "shared/message-composer/types";
import { DEFAULT_DATA as SELF_SCHEDULE_REQUEST_DEFAULT_DATA } from "shared/self-schedule/rich-blocks/self-schedule-request";

type BlockForWorkflowProps = {
  workflowType: PostMessageWorkflow | undefined;
  editor: LexicalEditor | null;
  timezone: string | null;
  selfScheduleInterviewId?: string;
};

function getRichBlock(
  workflowType: PostMessageWorkflow | undefined
): Klass<LexicalNode> | null {
  switch (workflowType) {
    case PostMessageWorkflow.REQUEST_AVAILABILITY:
      return GuideAvailabilityRequestNode;
    case PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY:
      return GuideAvailabilityRequestNode;
    case PostMessageWorkflow.CONFIRM_INTERVIEWS:
      return InterviewsNode;
    case PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS:
      return InterviewsNode;
    case PostMessageWorkflow.SELF_SCHEDULE_REQUEST:
      return SelfScheduleRequestNode;
    default:
      return null;
  }
}

export function useBlockForWorkflow({
  workflowType,
  timezone,
  editor,
  selfScheduleInterviewId,
}: BlockForWorkflowProps) {
  const richBlock = getRichBlock(workflowType);

  const $insertBlock = useCallback(() => {
    if (editor) {
      if (richBlock) {
        // TODO: This should only check the direct children of root, see getRichBlocksInEditorContent().
        const nodesOfType = $nodesOfType(richBlock);
        if (nodesOfType.length === 0) {
          if (
            workflowType === PostMessageWorkflow.REQUEST_AVAILABILITY ||
            workflowType === PostMessageWorkflow.REQUEST_ADDITIONAL_AVAILABILITY
          ) {
            editor.focus();
            editor.dispatchCommand(INSERT_GUIDE_AVAILABILITY_REQUEST_COMMAND, {
              ...GUIDE_AVAILABILITY_REQUEST_DEFAULT_DATA,
            });
          } else if (
            workflowType === PostMessageWorkflow.CONFIRM_INTERVIEWS ||
            workflowType === PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS
          ) {
            editor.focus();
            editor.dispatchCommand(INSERT_INTERVIEWS_COMMAND, {
              ...INTERVIEWS_DEFAULT_DATA,
              requesterTimezone:
                timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
          } else if (
            workflowType === PostMessageWorkflow.SELF_SCHEDULE_REQUEST
          ) {
            editor.focus();
            editor.dispatchCommand(
              INSERT_SELF_SCHEDULE_REQUEST_COMMAND,
              selfScheduleInterviewId
                ? {
                    interviewId: selfScheduleInterviewId,
                    needsConfiguration: true,
                  }
                : SELF_SCHEDULE_REQUEST_DEFAULT_DATA
            );
          }
        }
      }
    }
  }, [editor, richBlock, workflowType, timezone, selfScheduleInterviewId]);

  return { $insertBlock };
}
