import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarCheck,
  atlasCalendarConflict,
} from "@resource/atlas/icons";
import { gql } from "generated/graphql-codegen";
import { NewAIScenarioCardPanelFragment } from "generated/graphql-codegen/graphql";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { TimeAvailability } from "shared/algorithm/public/types";
import { formatEntity } from "shared/constants/entities";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { ScenarioMetadata, SlotProperties } from "../utils/types";
import { AIScenarioInterviewCard } from "./AIScenarioInterviewCard";

function getInterviewerConflicts(properties: SlotProperties) {
  const labels = [];

  const timeAvailable =
    (properties?.timeAvailability &&
      properties.timeAvailability !== TimeAvailability.BUSY) ||
    properties?.timeAvailable;

  if (!timeAvailable) {
    labels.push("Conflict at time");
  }
  if (!properties.duringWorkingHours) {
    labels.push("Outside working hours");
  }
  if (properties.interviewerLoadMet && !properties.ignoreAccountLoadLimits) {
    labels.push("Interviewer load exceeded");
  }
  if (properties.interviewerPoolLoadMet) {
    labels.push(
      `${formatEntity("training path", { capitalize: true })} load exceeded`
    );
  }
  return labels;
}

gql(`
fragment NewAIScenarioCardPanel on FulfilledInterviewPanelRequirement {
  id
  data
  scheduledInterviews {
    id
    ...AIScenarioInterviewCard
    startTime
    interviewRequirementId
    interviewers {
      id
      userMembership {
        id
      }
    }
  }
}`);

type SchedulerDialogProps = {
  algorithmSuggestedScenarioId?: string;
};

export function AIScenarioCard({
  scenario,
  label,
  onSchedule,
  compact,
}: {
  scenario: NewAIScenarioCardPanelFragment;
  label: string;
  onSchedule: (props: SchedulerDialogProps) => void;
  compact?: boolean;
}) {
  const metadata = useMemo(
    () => scenario.data as ScenarioMetadata,
    [scenario.data]
  );

  const groupedProcessedInterviews = useMemo(() => {
    const interviews = scenario.scheduledInterviews;
    if (!interviews) {
      return {};
    }

    const interviewsWithFormattedStartDate = interviews.map((interview) => {
      const formattedStartDate = DateTime.fromISO(interview.startTime)
        .setZone(getCurrentIanaTimezone())
        .toFormat("cccc, LLLL d");

      const interviewProperties =
        metadata.interviewProperties[interview.interviewRequirementId ?? ""];
      const interviewerConflicts = interview.interviewers.reduce(
        (acc, interviewer) => {
          return {
            ...acc,
            [interviewer.id]: interviewProperties?.[
              interviewer.userMembership.id
            ]?.properties
              ? getInterviewerConflicts(
                  interviewProperties?.[interviewer.userMembership.id]
                    ?.properties
                )
              : [],
          };
        },
        {} as Record<string, string[]>
      );

      const numberOfConflicts = Object.values(interviewerConflicts).filter(
        (conflicts) => conflicts.length > 0
      ).length;

      return {
        ...interview,
        formattedStartDate,
        interviewerConflicts,
        numberOfConflicts,
      };
    });

    return groupBy(interviewsWithFormattedStartDate, "formattedStartDate");
  }, [metadata.interviewProperties, scenario.scheduledInterviews]);

  const totalConflicts = useMemo(() => {
    return Object.values(groupedProcessedInterviews).reduce(
      (acc, interviews) => {
        return (
          acc +
          interviews.reduce(
            (acc2, interview) => acc2 + interview.numberOfConflicts,
            0
          )
        );
      },
      0
    );
  }, [groupedProcessedInterviews]);

  return (
    <div className="border border-light-gray-700 rounded-md flex flex-col">
      <div className="rounded-t-md bg-light-gray-500 px-4 py-2 flex justify-between items-center">
        <div className="flex gap-4 items-center">
          <div className="flex gap-2">
            {totalConflicts === 0 ? (
              <Icon content={atlasCalendarCheck} className="text-green-500" />
            ) : (
              <Icon content={atlasCalendarConflict} className="text-red-500" />
            )}
            <div className="text-h4">{label}</div>
          </div>
          {totalConflicts > 0 && (
            <div className="text-body-sm text-subtle">
              {totalConflicts} conflict{totalConflicts !== 1 ? "s" : ""}
            </div>
          )}
        </div>
        <div>
          <Button
            variant="purple"
            isGhost
            onClick={() =>
              onSchedule({ algorithmSuggestedScenarioId: scenario.id })
            }
          >
            Use option
          </Button>
        </div>
      </div>
      <div className="p-4 space-y-3 bg-white rounded-b-md">
        {Object.entries(groupedProcessedInterviews).map(([day, interviews]) => (
          <div key={day} className="space-y-3">
            {scenario.scheduledInterviews.length > 1 && (
              <div className="text-body-md-heavy">{day}</div>
            )}
            {interviews.map((interview) => (
              <AIScenarioInterviewCard
                key={interview.id}
                interview={interview}
                conflicts={interview.interviewerConflicts}
                compact={compact}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
