import { useEffect, useState } from "react";

export default function useDebouncedValue<T>(
  defaultValue: T,
  delay: number
): {
  value: T;
  debouncedValue: T;
  setValue: (v: T) => void;
} {
  const [value, setValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);

  return { value, setValue, debouncedValue };
}
