import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";

import { useLegacyInterviewPanelRequirements } from "./interviewPanelRequirement/utils/hooks";
import { InterviewPanelRequirement } from "./interviewPanelRequirement/utils/types";
import JobSettingsAlgorithmSettingsDisplay from "./JobSettingsAlgorithmSettingsDisplay";

type JobSettingsInterviewPanelRequirementsDisplayProps = {
  interviewPanelRequirement: InterviewPanelRequirement;
};

export function JobSettingsInterviewPanelRequirementsDisplay({
  interviewPanelRequirement,
}: JobSettingsInterviewPanelRequirementsDisplayProps) {
  const { algorithmSettings, reuseVideoConferencingLink, location } =
    interviewPanelRequirement;

  const { requireVideoConferencing, requireConferenceRoom, defaultPrivate } =
    useLegacyInterviewPanelRequirements({
      interviewRequirements: interviewPanelRequirement.interviewRequirements,
    });

  const {
    [FeatureFlags.MULTI_DAY_ALGORITHM_SCHEDULING_PREFERENCES]:
      algorithmSettingsEnabled,
  } = useFlags();

  const showAlgorithmSettings =
    algorithmSettingsEnabled &&
    (algorithmSettings?.numberOfDays ||
      algorithmSettings?.interviewsPerDayLimit);

  const showOtherSettings =
    requireVideoConferencing ||
    requireConferenceRoom ||
    defaultPrivate ||
    location;

  return (
    <>
      {showAlgorithmSettings && (
        <JobSettingsAlgorithmSettingsDisplay
          algorithmSettings={algorithmSettings}
        />
      )}
      {showOtherSettings && (
        <div className="space-y-2">
          <div className="text-body-md-heavy">Settings</div>
          <ul className="text-body-md list-inside list-disc space-y-2">
            {requireVideoConferencing && <li>Video conferencing required</li>}
            {requireVideoConferencing && reuseVideoConferencingLink && (
              <li>Video conferencing link reused across all interviews</li>
            )}
            {requireConferenceRoom && <li>Conference room required</li>}
            {defaultPrivate && <li>Calendar visibility set to private</li>}
            {location && <li>Candidate will interview on-site</li>}
          </ul>
        </div>
      )}
    </>
  );
}
