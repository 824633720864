/* eslint-disable import/prefer-default-export */
import "./HeaderToolbarPlugin.sass";

import { Portal } from "ariakit";

import { createComponentUtils } from "../../../../__utils/atlas";
import { useStaticValue } from "../../../../__utils/react";
import { ButtonGroup } from "../../../../button/ButtonGroup";
import {
  InsertOption,
  useRenderInsertOptionSlot,
} from "../../../__utils/insert-options";
import { usePortalElement } from "../../../__utils/portal-element-context";

// config
// ------

const COMPONENT_NAME = "ContentEditor-HeaderToolbar";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// toolbar
// -------

type HeaderToolbarPluginProps = {
  insertOptions?: InsertOption[];
};

export function HeaderToolbarPlugin({
  insertOptions = [],
}: HeaderToolbarPluginProps) {
  const [renderSlotOption, render] = useRenderInsertOptionSlot(
    insertOptions,
    useStaticValue({
      fullTarget: "fixed-toolbar-button",
      subTarget: "fixed-toolbar",
    }),
    "fixedToolbar"
  );

  return (
    <>
      <Portal portalElement={usePortalElement().footer}>
        <div className={ROOT}>
          <ButtonGroup size="xs" isGhost variant="default">
            {renderSlotOption("variable")}
          </ButtonGroup>
        </div>
      </Portal>
      {render}
    </>
  );
}
