import { DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { ScheduledInterviewUpdateOnCompleted } from "client/components/scheduled-interviews/ScheduledInterviewChangeConfirmation/ScheduledInterviewUpdateConfirmationView";
import { useUpsertScheduledInterviewGroup } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/__hooks/useUpsertScheduledInterviewGroup";
import { computeChangesForScheduledInterviewForm } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/field-changes";
import {
  StagedScheduledInterviewFormSchema,
  UpsertScheduledInterview,
  UpsertScheduledInterviewFormData,
} from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { UpsertScheduledInterviewGroupForSchedulerMutation } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { UseFormReturn } from "react-hook-form";
import { getDefaultInterviewerInviteContent } from "shared/guide-scheduler/default-invite-content";

export type UpdateScheduledInterviewSchedulingResult =
  UpsertScheduledInterviewGroupForSchedulerMutation;

export type UpdateScheduledInterviewState = {
  sendNotifications: boolean;
};

export type UpdateScheduledInterviewOnCompleted = (
  result: UpdateScheduledInterviewSchedulingResult,
  state: UpdateScheduledInterviewState
) => void;

export const useEditScheduledInterviewConfirmationOnSave = ({
  form,
  guideId,
  originalInterview,
  store,
  onCompleted,
}: {
  form: UseFormReturn<UpsertScheduledInterviewFormData>;
  guideId: string;
  originalInterview?: UpsertScheduledInterview;
  store: DialogStore;
  onCompleted?: UpdateScheduledInterviewOnCompleted;
}) => {
  const { upsertScheduledInterviewGroup, loading: saveLoading } =
    useUpsertScheduledInterviewGroup();

  const onSave: ScheduledInterviewUpdateOnCompleted = useCallback(
    async ({ notificationSettings, reportingReason }) => {
      const rawValues = form.getValues();
      const validation =
        StagedScheduledInterviewFormSchema.safeParse(rawValues);

      if (!validation.success) {
        throw new Error("Invalid form values");
      }

      const { data } = validation;
      const newInterview = data.scheduledInterview;

      const result = await upsertScheduledInterviewGroup({
        guideId,
        updateScheduledInterviews: [
          {
            ...newInterview,
            fieldChanges: originalInterview
              ? computeChangesForScheduledInterviewForm({
                  newInterview,
                  oldInterview: originalInterview,
                })
              : undefined,
            reviewFormData: {
              id: newInterview.id,
              sendNotifications: notificationSettings.notifyInterviewers,
              status: ScheduledStatus.UPDATED,
              interviewerConfirmationTitle:
                getDefaultInterviewerInviteContent().title,
              note: notificationSettings.message,
            },
            reportingData: reportingReason,
          },
        ],
      });

      if (onCompleted && result.data) {
        store.hide();
        onCompleted(result.data, {
          sendNotifications: notificationSettings.notifyInterviewers,
        });
      }
    },
    [
      form,
      guideId,
      onCompleted,
      originalInterview,
      store,
      upsertScheduledInterviewGroup,
    ]
  );

  return {
    onSave,
    saveLoading,
  };
};
