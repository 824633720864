import { GuidePostForPreviewMessageFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import {
  GuidePostPreviewDisplay,
  GuidePostPreviewDisplayProps,
} from "./GuidePostPreviewDisplay";
import { mapGuidePostPreviewFragment } from "./utils/mapping";

export function GuidePostPreview({
  post,
  ...rest
}: Omit<GuidePostPreviewDisplayProps, "post"> & {
  post: GuidePostForPreviewMessageFragment;
}) {
  const mappedPost = useMemo(() => {
    return mapGuidePostPreviewFragment(post);
  }, [post]);

  return <GuidePostPreviewDisplay {...rest} post={mappedPost} />;
}
