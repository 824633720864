import type { ContentEditorModuleConfig } from "@resource/atlas";
import { createContext, ReactNode, useContext } from "react";

const ModuleConfigContext = createContext<ContentEditorModuleConfig>({});

type ModuleConfigProviderProps = {
  value: ContentEditorModuleConfig;
  children?: ReactNode;
};

export function ModuleConfigProvider({
  children,
  value,
}: ModuleConfigProviderProps) {
  return (
    <ModuleConfigContext.Provider value={value}>
      {children}
    </ModuleConfigContext.Provider>
  );
}

export function useModuleConfig<
  ModuleId extends keyof ContentEditorModuleConfig
>(
  moduleId: ModuleId,
  options?: { required?: false }
): ContentEditorModuleConfig[ModuleId];

export function useModuleConfig<
  ModuleId extends keyof ContentEditorModuleConfig
>(
  moduleId: ModuleId,
  options: { required: true }
): NonNullable<ContentEditorModuleConfig[ModuleId]>;

export function useModuleConfig<
  ModuleId extends keyof ContentEditorModuleConfig
>(
  moduleId: ModuleId,
  { required = false } = {}
): ContentEditorModuleConfig[ModuleId] {
  const config = useContext(ModuleConfigContext)[moduleId];
  if (required && config == null)
    throw new Error(`"${moduleId}" module: config not found`);
  return config;
}
