import { atlasEyeOff, atlasLockClosed } from "@resource/atlas/icons";
import { useMemo } from "react";

export type ScheduledInterviewForStatuses = {
  isPrivate: boolean;
  blacklisted: boolean;
  createdByGuide: boolean;
};

type ScheduledInterviewStatus = {
  icon: string;
  message: string;
};

export function useScheduledInterviewStatuses({
  scheduledInterview,
}: {
  scheduledInterview: ScheduledInterviewForStatuses;
}): ScheduledInterviewStatus[] {
  return useMemo((): ScheduledInterviewStatus[] => {
    const statuses: ScheduledInterviewStatus[] = [];

    if (scheduledInterview.createdByGuide) {
      if (scheduledInterview.isPrivate) {
        statuses.push({
          icon: atlasLockClosed,
          message: "Private Google Calendar event",
        });
      }
    }

    if (scheduledInterview.blacklisted) {
      statuses.push({
        icon: atlasEyeOff,
        message: "Not visible to candidate",
      });
    }

    return statuses;
  }, [
    scheduledInterview.blacklisted,
    scheduledInterview.createdByGuide,
    scheduledInterview.isPrivate,
  ]);
}
