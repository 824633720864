import clsx from "clsx";
import React from "react";

import { NotificationDisplayType } from "../utils/types";

export interface BaseNotificationDisplayProps {
  notification: NotificationDisplayType;
  className?: string;
}

export function BaseNotificationDisplay({
  notification,
  className,
}: BaseNotificationDisplayProps) {
  const { actor } = notification;

  const actorName = actor?.name || "Unknown User";

  return (
    <div className={clsx(className, "text-body-md text-dark")}>
      <span className="text-body-md-heavy">{actorName}</span> performed an
      action.
    </div>
  );
}
