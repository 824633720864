export enum VideoConferencingServiceType {
  GOOGLE_MEET = "GOOGLE_MEET",
  MICROSOFT_TEAMS = "MICROSOFT_TEAMS",
  OTHER = "OTHER",
  SKYPE = "SKYPE",
  ZOOM = "ZOOM",
}

export type GenerateVideoConferencingSettings = {
  hostUserMembershipId?: string | null | undefined;
  service: VideoConferencingServiceType;
};
