import { useAuthContext } from "auth/context";
import { Recipient } from "components/RecipientsManager";
import { ThreadForDetailFragment } from "generated/graphql-codegen/graphql";
import { uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { usePrevious } from "react-use";
import { isFakeCandidateEmail } from "shared/utils/emails";

import type {
  GuideComposeMessageFormData,
  UseGuideComposeMessageStateProps,
} from "./__hooks/useGuideComposeMessageState";

/**
 * Filter recipients to remove any that should not be CC'd.
 */
export function filterRecipients({
  recipients,
  senderId,
}: {
  recipients: Recipient[];
  senderId: string | undefined;
}) {
  return uniqBy(recipients, "email").filter((r) => {
    if (r.isCandidate) {
      // never CC candidates
      return false;
    }

    if (isFakeCandidateEmail(r.email)) {
      // Somehow candidates will get added as a recipient without isCandidate flag
      // Filter these out
      return false;
    }

    if (senderId && r.id === senderId) {
      // Sender cannot CC themselves
      return false;
    }

    return true;
  });
}

/**
 * Get the default CC for the message.
 * This will include a filtered list of the defaults passed to the hook and the current recipients on the current thread.
 */
export function useDefaultRecipients({
  defaultRecipients,
  thread,
}: Pick<UseGuideComposeMessageStateProps, "defaultRecipients" | "thread">) {
  const { user } = useAuthContext();

  return useMemo(() => {
    const defaultsWithExplicitlyRemovedFiltered = defaultRecipients?.filter(
      (recipient) => {
        if (!recipient.id || !thread?.removedRecipients) {
          return true;
        }

        return !thread.removedRecipients.includes(recipient.id);
      }
    );

    const combinedDefaultRecipients = [
      ...(defaultsWithExplicitlyRemovedFiltered ?? []),
      ...(thread?.recipientProfiles ?? []),
    ];
    const defaultSenderId = user?.currentUserMembership?.id;

    return filterRecipients({
      recipients: combinedDefaultRecipients,
      senderId: defaultSenderId,
    });
  }, [
    defaultRecipients,
    thread?.recipientProfiles,
    thread?.removedRecipients,
    user?.currentUserMembership?.id,
  ]);
}

export type SetRecipientsProps = {
  recipients: Recipient[];
  isUser: boolean;
};

/**
 * Hook to manage recipients in the message composer.
 */
export function useRecipientsManagement({
  form,
  allThreads,
  defaultRecipients,
}: {
  form: UseFormReturn<GuideComposeMessageFormData>;
} & Pick<
  UseGuideComposeMessageStateProps,
  "allThreads" | "defaultRecipients"
>) {
  const recipients = form.watch("recipients");
  const sender = form.watch("sender");
  const threadId = form.watch("threadId");
  const [manuallyAddedRecipients, setManuallyAddedRecipients] = useState<
    Recipient[]
  >([]);
  const [manuallyRemovedRecipients, setManuallyRemovedRecipients] = useState<
    Recipient[]
  >([]);

  /**
   * Set the recipients for the message properly.
   * This will automatically filter out invalid recipients, and remember which default recipients to add or remove based on user selections.
   */
  const setRecipients = useCallback(
    ({ recipients: passedRecipients, isUser }: SetRecipientsProps) => {
      let internalManualAddedRecipients = manuallyAddedRecipients;
      let internalManualRemovedRecipients = manuallyRemovedRecipients;

      if (isUser) {
        const passedRecipientIds = new Set(passedRecipients.map((r) => r.id));
        const currentRecipientIds = new Set(recipients.map((r) => r.id));

        const addedRecipients = passedRecipients.filter(
          (r) => !currentRecipientIds.has(r.id)
        );
        const removedRecipients = recipients.filter(
          (r) => !passedRecipientIds.has(r.id)
        );

        internalManualAddedRecipients = [
          ...internalManualAddedRecipients.filter(
            (r) => !removedRecipients.some((removed) => removed.id === r.id)
          ),
          ...addedRecipients.filter(
            (r) =>
              !manuallyRemovedRecipients.some((removed) => removed.id === r.id)
          ),
        ];

        setManuallyAddedRecipients(internalManualAddedRecipients);

        internalManualRemovedRecipients = [
          ...internalManualRemovedRecipients.filter(
            (r) => !addedRecipients.some((added) => added.id === r.id)
          ),
          ...removedRecipients.filter(
            (r) => !manuallyAddedRecipients.some((added) => added.id === r.id)
          ),
        ];

        setManuallyRemovedRecipients(internalManualRemovedRecipients);
      }

      const combinedRecipients = [
        ...(defaultRecipients ?? []),
        ...passedRecipients,
      ];

      const filteredRecipients = filterRecipients({
        recipients: combinedRecipients,
        senderId: sender.id,
      }).filter(
        (r) =>
          !internalManualRemovedRecipients.some(
            (removed) => removed.id === r.id
          )
      );

      const finalRecipients = [
        ...filteredRecipients,
        ...internalManualAddedRecipients.filter(
          (added) => !filteredRecipients.some((r) => r.id === added.id)
        ),
      ];

      form.setValue("recipients", finalRecipients, { shouldValidate: true });
    },
    [
      form,
      manuallyAddedRecipients,
      manuallyRemovedRecipients,
      recipients,
      sender.id,
      defaultRecipients,
    ]
  );

  useSyncRecipientsWithThread({
    threadId,
    allThreads,
    setRecipients,
  });

  useSyncRecipientsWithSender({
    senderId: sender.id,
    setRecipients,
    recipients,
  });

  return useMemo(
    () => ({
      setRecipients,
    }),
    [setRecipients]
  );
}

/**
 * Synchronize recipients with thread changes.
 * Will persist user selections for nonThreadRecipients but add and remove thread recipients as needed.
 */
function useSyncRecipientsWithThread({
  threadId,
  allThreads,
  setRecipients,
}: {
  threadId: string | undefined | null;
  allThreads: ThreadForDetailFragment[];
  setRecipients: (recipients: SetRecipientsProps) => void;
}) {
  const prevThreadId = usePrevious(threadId);

  // Keep recipients in sync with thread
  useEffect(() => {
    if (prevThreadId !== threadId) {
      if (threadId) {
        const lookupThread = allThreads.find((t) => t.id === threadId);

        if (lookupThread) {
          setRecipients({
            recipients: lookupThread.recipientProfiles,
            isUser: false,
          });

          return;
        }
      }

      setRecipients({
        recipients: [],
        isUser: false,
      });
    }
  }, [threadId, prevThreadId, allThreads, setRecipients]);
}

/**
 * If the sender changes, we want to ensure they aren't CC'ing themselves.
 */
function useSyncRecipientsWithSender({
  senderId,
  setRecipients,
  recipients: currRecipients,
}: {
  senderId: string;
  setRecipients: (recipients: SetRecipientsProps) => void;
  recipients: Recipient[];
}) {
  const prevSenderId = usePrevious(senderId);

  useEffect(() => {
    if (prevSenderId !== senderId) {
      setRecipients({
        recipients: currRecipients,
        isUser: false,
      });
    }
  }, [currRecipients, prevSenderId, senderId, setRecipients]);
}
