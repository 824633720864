import { useCalendarSettings } from "./settings";
import { useStartOfPeriod } from "./useStartOfPeriod";

export function useDaysToRender() {
  const { includeWeekends, view } = useCalendarSettings();
  const startOfPeriod = useStartOfPeriod();

  if (view === "week") {
    return [...Array(includeWeekends ? 7 : 5).keys()].map((i) =>
      startOfPeriod.plus({ days: i })
    );
  }

  return [startOfPeriod];
}
