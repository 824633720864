import { Popover } from "@resource/atlas/popover";
import clsx from "clsx";
import { DateTime } from "luxon";
import { ComponentProps, ReactNode, useMemo } from "react";

import { CalendarHeader } from "./CalendarHeader";
import { CalendarContext } from "./context";
import { CurrentTimeIndicator } from "./CurrentTimeIndicator";
import { DayHeaders } from "./DayHeaders";
import { EventDetailsPopover } from "./EventDetailsPopover";
import { EventsView } from "./EventsView";
import { CalendarColorsConfig, CalendarEventInput } from "./types";
import { useCalendarEvents } from "./useCalendarEvents";
import { useCalendarInitialScroll } from "./useCalendarInitialScroll";
import {
  CalendarState,
  CalendarStateProps,
  useCalendarState,
} from "./useCalendarState";

type BaseCalendarProps = ComponentProps<"div"> & {
  events: CalendarEventInput[];
  calendarColors?: CalendarColorsConfig;
  initialScrollValue?: DateTime | string;
  plugins?: ReactNode[];
};

export type CalendarProps = BaseCalendarProps &
  (
    | {
        state: CalendarState;
        stateProps?: never;
      }
    | {
        stateProps?: CalendarStateProps;
        state?: never;
      }
  );

export function Calendar({
  events,
  calendarColors,
  initialScrollValue,
  state: passedState,
  stateProps,
  children,
  plugins,
  ...props
}: CalendarProps) {
  const internalState = useCalendarState(stateProps);
  const calendarState = useMemo(() => {
    return passedState ?? internalState;
  }, [internalState, passedState]);

  const { setWrapperRef, eventDetailsPopoverState } = calendarState;

  const calendarEvents = useCalendarEvents({
    calendarState,
    events,
    calendarColors,
  });

  useCalendarInitialScroll({
    calendarState,
    initialScrollValue,
  });

  const value = useMemo(
    () => ({
      ...calendarState,
      events: calendarEvents.events,
      allDayEvents: calendarEvents.allDayEvents,
    }),
    [calendarEvents.allDayEvents, calendarEvents.events, calendarState]
  );

  return (
    <CalendarContext.Provider value={value}>
      <Popover.Root state={eventDetailsPopoverState}>
        <div
          {...props}
          className={clsx(props.className, "border-gray-border border-l")}
        >
          <CalendarHeader />
          <div className="flex flex-col overflow-hidden pb-[0.125rem]">
            <div
              className="isolate flex flex-auto flex-col overflow-auto bg-white"
              ref={(r) => setWrapperRef(r)}
            >
              <div className="flex flex-none flex-col max-w-none md:max-w-full">
                <DayHeaders />
                <EventsView />
                <EventDetailsPopover />
              </div>
            </div>
          </div>
        </div>
        <CurrentTimeIndicator />
        {plugins}
        {children}
      </Popover.Root>
    </CalendarContext.Provider>
  );
}
