import Badge from "@resource/atlas/badge/Badge";
import { useLogEvent } from "analytics";
import { Checkbox } from "client/components/generic/inputs/Checkbox";
import { CloseButton } from "client/components/generic/layout/CloseButton";
import { CollapsibleSection } from "client/components/generic/layout/CollapsibleSection";
import { SidePanelPushIn } from "client/components/generic/layout/SidePanelPushIn";
import { EmptyStateWrapper } from "client/components/generic/misc/EmptyStateWrapper";
import { UnscheduledInterviewCard } from "client/components/interview-requirements/UnscheduledInterviewCard/display/UnscheduledInterviewCard";
import {
  ScheduledInterviewGroupSettingsOnChange,
  useScheduledInterviewGroupSettingsFormState,
} from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/__hooks/useScheduledInterviewGroupSettingsFormState";
import { ScheduledInterviewGroupSettingsForm } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/display/ScheduledInterviewGroupSettingsForm";
import { mapScheduledInterviewGroupSettingsToConferencingSlot } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/mapping";
import { ScheduledInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/types";
import { getUnschedulableErrorMessage } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/errors";
import { useCallback, useMemo } from "react";
import { usePrevious } from "react-use";

import {
  isStagedScheduledInterview,
  SchedulerEditableScheduledInterview,
} from "../../../utils/types";
import { useSequentialPlacementEnabled } from "../../calendar/plugins/place-interview-calendar/hooks/useSequentialInterviewPlacement";
import { useSchedulerInterviewsDispatch } from "../../dispatch";
import {
  useAllInterviewerUserMemberships,
  useInterviews,
  useOriginalInterviews,
  useSelectedInterview,
  useSetInterviews,
  useSetSelectedInterviewId,
} from "../../hooks/interviews";
import {
  useScheduledInterviewGroupSettings,
  useScheduledInterviewGroupSettingsVisible,
  useSetScheduledInterviewGroupSettings,
} from "../../hooks/scheduledInterviewGroupSettings";
import { useSetGroupSettingsForUnscheduledInterviews } from "../../hooks/useSetGroupSettingsForUnscheduledInterviews";
import { SchedulerJobInterviewSelect } from "../misc/SchedulerJobInterviewSelect";
import { SchedulerScheduledInterviewCard } from "../misc/SchedulerScheduledInterviewCard";

export type ViewDetails = {
  label: string;
  DisplayComponent: React.ReactNode;
};

export function SchedulerLeftSidePanel({
  isOpen,
  viewDetails,
  onCancel,
}: {
  isOpen: boolean;
  viewDetails: ViewDetails;
  onCancel: () => void;
}) {
  const scheduledInterviewGroupSettings = useScheduledInterviewGroupSettings();

  return (
    <div className="flex flex-row shrink-0 z-10 overflow-y-auto border-r border-gray-border">
      <SidePanelPushIn
        className="relative pb-4 w-full"
        isOpen={isOpen}
        width="20rem"
        duration={300}
        closedState={{
          width: "3rem",
          children: (
            <div className="p-2">
              <CloseButton onClick={onCancel} />
            </div>
          ),
        }}
      >
        <div className="p-2 sticky top-0 bg-white shadow-1 w-full z-50">
          <CloseButton onClick={onCancel} />
        </div>
        <div className="w-full divide-y divide-gray-border">
          <CollapsibleSection label={viewDetails.label}>
            <div className="flex flex-col space-y-3 w-full">
              {viewDetails.DisplayComponent}
            </div>
          </CollapsibleSection>
          {scheduledInterviewGroupSettings && (
            <InterviewGroupSettings
              scheduledInterviewGroupSettings={scheduledInterviewGroupSettings}
            />
          )}
          <InterviewsSections />
        </div>
      </SidePanelPushIn>
    </div>
  );
}

function InterviewGroupSettings({
  scheduledInterviewGroupSettings,
}: {
  scheduledInterviewGroupSettings: ScheduledInterviewGroupSettings;
}) {
  const setInterviewGroupSettings = useSetScheduledInterviewGroupSettings();
  const interviewGroupSettings = useScheduledInterviewGroupSettings();
  const prevGroupSettings = usePrevious(interviewGroupSettings);
  const setInterviews = useSetInterviews();
  const originalInterviews = useOriginalInterviews();
  const allInterviewerUserMemberships = useAllInterviewerUserMemberships();
  const interviewerUserMembershipIds = useMemo(() => {
    return allInterviewerUserMemberships.map((m) => m.id);
  }, [allInterviewerUserMemberships]);

  useSetGroupSettingsForUnscheduledInterviews();

  const syncChangesWithState: ScheduledInterviewGroupSettingsOnChange =
    useCallback(
      (updatedScheduledInterviewGroupSettings) => {
        setInterviewGroupSettings(updatedScheduledInterviewGroupSettings);
        setInterviews((prevInterviews) => {
          return prevInterviews.map((interview) => {
            const isChecking =
              !prevGroupSettings?.reuseVideoConferencingLink &&
              updatedScheduledInterviewGroupSettings.reuseVideoConferencingLink;
            const interviewIsOverridden =
              !interview.conferencingSlot?.isUsingGroupSettings;

            if (
              updatedScheduledInterviewGroupSettings.reuseVideoConferencingLink &&
              (isChecking || !interviewIsOverridden)
            ) {
              const updatedConferencingSlot =
                mapScheduledInterviewGroupSettingsToConferencingSlot({
                  scheduledInterviewGroupSettings:
                    updatedScheduledInterviewGroupSettings,
                });
              if (updatedConferencingSlot) {
                return {
                  ...interview,
                  conferencingSlot: {
                    ...interview.conferencingSlot,
                    ...updatedConferencingSlot,
                    isUsingGroupSettings: true,
                  },
                };
              }

              return interview;
            }

            // when we uncheck re-use video conferencing link, reset conferencing back to defaults
            const originalInterview = originalInterviews.find(
              (i) => i.id === interview.id
            );

            return {
              ...interview,
              conferencingSlot: originalInterview?.conferencingSlot ?? null,
            };
          });
        });
      },
      [
        originalInterviews,
        prevGroupSettings?.reuseVideoConferencingLink,
        setInterviewGroupSettings,
        setInterviews,
      ]
    );

  const formState = useScheduledInterviewGroupSettingsFormState({
    scheduledInterviewGroupSettings,
    interviewerUserMembershipIds,
    onChange: syncChangesWithState,
  });

  const scheduledInterviewGroupSettingsVisible =
    useScheduledInterviewGroupSettingsVisible();

  if (!scheduledInterviewGroupSettingsVisible) {
    return null;
  }

  return (
    <CollapsibleSection label="Panel settings">
      <ScheduledInterviewGroupSettingsForm state={formState} />
    </CollapsibleSection>
  );
}

function InterviewsSections() {
  const interviews = useInterviews();
  const { unstaged, staged } = useMemo(() => {
    return interviews.reduce(
      (acc, interview) => {
        if (!isStagedScheduledInterview(interview)) {
          acc.unstaged.push(interview);
        } else {
          acc.staged.push(interview);
        }
        return acc;
      },
      { unstaged: [], staged: [] } as {
        unstaged: SchedulerEditableScheduledInterview[];
        staged: SchedulerEditableScheduledInterview[];
      }
    );
  }, [interviews]);

  return (
    <>
      <InterviewSection
        label="To be scheduled"
        interviews={unstaged}
        Footer={<SchedulerJobInterviewSelect />}
        SubHeader={<SequentialPlacementOption />}
      />
      <InterviewSection label="Scheduled" interviews={staged} />
    </>
  );
}

type InterviewSectionProps = {
  label: string;
  interviews: SchedulerEditableScheduledInterview[];
  Footer?: JSX.Element;
  SubHeader?: JSX.Element;
};

function InterviewSection({
  label,
  interviews,
  Footer,
  SubHeader,
}: InterviewSectionProps) {
  return (
    <CollapsibleSection
      label={label}
      Badge={
        <Badge variant="default" size="small">
          {interviews.length}
        </Badge>
      }
    >
      {SubHeader}
      {interviews.length ? (
        <div className="flex flex-col space-y-3 w-full">
          {interviews.map((i) => {
            return <DisplaySingleInterview interview={i} key={i.id} />;
          })}
        </div>
      ) : (
        <div>
          <EmptyStateWrapper>No interviews to display</EmptyStateWrapper>
        </div>
      )}
      <div className="mt-3">{Footer}</div>
    </CollapsibleSection>
  );
}

function SequentialPlacementOption() {
  const { sequentialPlacementEnabled, setSequentialPlacementEnabled } =
    useSequentialPlacementEnabled();

  const logEvent = useLogEvent({
    component: "SchedulerLeftSidePanel",
  });

  const interviews = useInterviews();
  const numUnstagedInterviews = useMemo(() => {
    return interviews.reduce((acc, interview) => {
      if (!isStagedScheduledInterview(interview)) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [interviews]);

  const onToggle = useCallback(() => {
    logEvent("Auto Place Panel Toggle Clicked", {
      enabled: !sequentialPlacementEnabled,
      numUnstagedInterviews,
    });
    setSequentialPlacementEnabled((prev) => !prev);
  }, [
    logEvent,
    sequentialPlacementEnabled,
    numUnstagedInterviews,
    setSequentialPlacementEnabled,
  ]);

  return (
    <div className="mb-2 flex items-center">
      <Checkbox
        id="sequentialPlacement"
        disabled={numUnstagedInterviews <= 1}
        checked={sequentialPlacementEnabled}
        onClick={onToggle}
        label="Auto-place entire panel"
      />
    </div>
  );
}

function DisplaySingleInterview({
  interview,
}: {
  interview: SchedulerEditableScheduledInterview;
}) {
  const selectedInterview = useSelectedInterview();
  const setSelectedInterviewId = useSetSelectedInterviewId();
  const isActive = selectedInterview?.id === interview.id;
  const onClick = useCallback(() => {
    setSelectedInterviewId(interview.id);
  }, [interview.id, setSelectedInterviewId]);
  const dispatch = useSchedulerInterviewsDispatch();
  const onRemoveUnscheduledInterview = useCallback(() => {
    dispatch({
      type: "remove",
      interviewId: interview.id,
    });
  }, [dispatch, interview.id]);
  const unschedulableErrorMessage = useMemo(() => {
    return getUnschedulableErrorMessage(interview);
  }, [interview]);

  if (!interview) {
    return null;
  }

  if (!isStagedScheduledInterview(interview)) {
    return (
      <UnscheduledInterviewCard
        onClick={onClick}
        unscheduledInterview={interview}
        isActive={isActive}
        onRemove={onRemoveUnscheduledInterview}
        disabled={!!unschedulableErrorMessage}
      />
    );
  }

  return (
    <SchedulerScheduledInterviewCard
      interview={interview}
      isActive={isActive}
      onClick={onClick}
      variant="compact"
      hideInternalStatuses
    />
  );
}
