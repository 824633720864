import "./premade-variables.sass";

import {
  displayPhoneNumber,
  getFormattedDateRange,
  strings,
} from "@resource/common";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";

import { createComponentUtils } from "../../../__utils/atlas";
import { Button } from "../../../button/Button";
import { Select } from "../../../select";
import { useSelectItems } from "../../../select/use-select-items";
import TextField from "../../../textfield/TextField";
import { theme } from "../../theme";
import type { VariableSetEntry } from "./types";
import {
  createVariableConfigAnnotationRenderer,
  createVariableConfigUIRenderer,
  createVariableRenderer,
  createVariableSpec,
  PartialVariableSpec,
} from "./variable-sets";

// config
// ------

const COMPONENT_NAME = "ContentEditor-Variable_premade-variables";

const { el } = createComponentUtils(COMPONENT_NAME);

// customization
// -------------

type CustomizeSpec<SetEntry extends VariableSetEntry<unknown, unknown>> = (
  current: PartialVariableSpec<SetEntry>
) => Partial<PartialVariableSpec<SetEntry>>;

function withCustomization<SetEntry extends VariableSetEntry<unknown, unknown>>(
  spec: PartialVariableSpec<SetEntry>,
  customize?: CustomizeSpec<SetEntry>
) {
  if (!customize) return spec;
  const customizations = customize(spec);
  return { ...spec, ...customizations };
}

// text
// ----

export type Text = { value: string };

export const renderText = createVariableRenderer<Text>(({ value }) => value);

export function text(customize?: CustomizeSpec<Text>) {
  const spec = createVariableSpec<Text>({ renderVariable: renderText });
  return withCustomization(spec, customize);
}

// phone
// ----

export type Phone = { value: string };

export const renderPhone = createVariableRenderer<Phone>(({ value }) =>
  displayPhoneNumber(value)
);

export function phone(customize?: CustomizeSpec<Phone>) {
  const spec = createVariableSpec<Phone>({ renderVariable: renderText });
  return withCustomization(spec, customize);
}

// person name
// -----------

export type PersonName = {
  value: { first: string; last: string };
  config: { display: "full-name" | "first-name" | "last-name" };
};

const defaultPersonNameConfig = { display: "full-name" } as const;

export const renderPersonName = createVariableRenderer<PersonName>(
  ({ value, config }) => {
    if (!value) {
      // unreplaced variable
      return "";
    }

    const { first, last } = value;

    if (config.display === "first-name") return first;
    if (config.display === "last-name") return last;
    if (config.display === "full-name") return `${first} ${last}`;
    throw new Error("Invalid variable config");
  }
);

const renderPersonNameConfigUI = createVariableConfigUIRenderer<PersonName>(
  ({ config, updateConfig, value, renderVariable }) => {
    const renderOptionLabel = useCallback(
      (name: string, display: typeof config.display) => {
        let label = <p>{name}</p>;
        if (value)
          label = (
            <div>
              <p>{name}</p>
              <p className="text-body-sm text-subtle">{`"${renderVariable({
                display,
              })}"`}</p>
            </div>
          );
        return label;
      },
      [config, renderVariable, value]
    );

    const selectItems = useSelectItems(
      (i) => [
        i.option({
          children: "Full name",
          renderContent: () => renderOptionLabel("Full name", "full-name"),
          value: "full-name",
        }),
        i.option({
          children: "First name",
          renderContent: () => renderOptionLabel("First name", "first-name"),
          value: "first-name",
        }),
        i.option({
          children: "Last name",
          renderContent: () => renderOptionLabel("Last name", "last-name"),
          value: "last-name",
        }),
      ],
      [renderOptionLabel]
    );

    return {
      body: (
        <div className={el`person-name`}>
          <p className="display-as">Display as</p>
          <Select.Root
            value={config.display}
            setValue={(newValue) =>
              updateConfig({ display: newValue as typeof config.display })
            }
          >
            {/* TODO: remove className once we fix the legacy styles issues in product */}
            <Select.Trigger size="small" className="bg-light-gray-500" />
            <Select.Content portal items={selectItems} />
          </Select.Root>
        </div>
      ),
    };
  }
);

const renderPersonNameConfigAnnotation =
  createVariableConfigAnnotationRenderer<PersonName>(
    ({ config: { display } }) => {
      switch (display) {
        case "first-name":
          return "first";
        case "last-name":
          return "last";
        default:
          return undefined;
      }
    }
  );

export function personName(customize?: CustomizeSpec<PersonName>) {
  const spec = createVariableSpec<PersonName>({
    renderVariable: renderPersonName,
    renderConfigUI: renderPersonNameConfigUI,
    renderConfigAnnotation: renderPersonNameConfigAnnotation,
    defaultConfig: defaultPersonNameConfig,
    presets: [
      { label: "First name", defaultConfig: { display: "first-name" } },
      { label: "Last name", defaultConfig: { display: "last-name" } },
    ],
  });
  return withCustomization(spec, customize);
}

// person names

export type PersonNames = {
  value: { firstNames: string; fullNames: string };
  config: { display: "full-names" | "first-names" };
};

const defaultPersonNamesConfig = { display: "full-names" } as const;

export const renderPersonNames = createVariableRenderer<PersonNames>(
  ({ value, config }) => {
    if (config.display === "first-names") return value.firstNames;
    if (config.display === "full-names") return value.fullNames;
    throw new Error("Invalid variable config");
  }
);

const renderPersonNamesConfigUI = createVariableConfigUIRenderer<PersonNames>(
  ({ config, updateConfig, value, renderVariable }) => {
    const renderOptionLabel = useCallback(
      (name: string, display: typeof config.display) => {
        let label = <p>{name}</p>;
        if (value)
          label = (
            <div>
              <p>{name}</p>
              <p className="text-subtle">{`"${renderVariable({
                display,
              })}"`}</p>
            </div>
          );
        return label;
      },
      [config, renderVariable, value]
    );

    const selectItems = useSelectItems(
      (i) => [
        i.option({
          children: "Full names",
          renderContent: () => renderOptionLabel("Full names", "full-names"),
          value: "full-names",
        }),
        i.option({
          children: "First names",
          renderContent: () => renderOptionLabel("First names", "first-names"),
          value: "first-names",
        }),
      ],
      [renderOptionLabel]
    );

    return {
      body: (
        <div className={el`person-name`}>
          <p className="display-as">Display as</p>
          <Select.Root
            value={config.display}
            setValue={(newValue) =>
              updateConfig({ display: newValue as typeof config.display })
            }
          >
            <Select.Trigger size="small" className="bg-light-gray-500" />
            <Select.Content portal items={selectItems} />
          </Select.Root>
        </div>
      ),
    };
  }
);

const renderPersonNamesConfigAnnotation =
  createVariableConfigAnnotationRenderer<PersonNames>(
    ({ config: { display } }) => {
      switch (display) {
        case "first-names":
          return "first";
        default:
          return undefined;
      }
    }
  );

export function personNames(customize?: CustomizeSpec<PersonNames>) {
  const spec = createVariableSpec<PersonNames>({
    renderVariable: renderPersonNames,
    renderConfigUI: renderPersonNamesConfigUI,
    renderConfigAnnotation: renderPersonNamesConfigAnnotation,
    defaultConfig: defaultPersonNamesConfig,
    presets: [
      { label: "First names", defaultConfig: { display: "first-names" } },
    ],
  });
  return withCustomization(spec, customize);
}

// link
// ----

export type Link = {
  value: string;
  config: { label?: string };
};

export const renderLink = createVariableRenderer<Link>(
  ({ value, config: { label } }, flags) =>
    // eslint-disable-next-line no-nested-ternary
    flags?.asText ? (
      label ? (
        `[${label}](${value})`
      ) : (
        `<${value}>`
      )
    ) : (
      <a
        onClick={(event) => {
          if (flags?.isEditing) {
            event.preventDefault();
          }
        }}
        tabIndex={-1}
        className={theme.link}
        href={value}
        target="_blank"
        rel="noreferrer"
      >
        {label ?? value}
      </a>
    )
);

const renderLinkConfigUI = createVariableConfigUIRenderer<Link>(
  ({ open, config, updateConfig }) => {
    const [inputValue, setInputValue] = useState(config.label ?? "");

    // reset input value when the config UI is closed
    useEffect(() => {
      if (!open) setInputValue(config.label ?? "");
    }, [open, config.label]);

    return {
      body: (
        <div className={el`link-body`}>
          <p className="label">Link label</p>
          <TextField
            size="small"
            aria-label="Link label"
            value={inputValue}
            onChange={setInputValue}
          />
        </div>
      ),
      footer: (
        <div className={el`link-footer`}>
          <Button
            size="small"
            variant="primary"
            disabled={inputValue === (config.label ?? "")}
            onClick={() => updateConfig({ label: inputValue || undefined })}
          >
            Save
          </Button>
        </div>
      ),
    };
  }
);

export function link(customize?: CustomizeSpec<Link>) {
  const spec = createVariableSpec<Link>({
    renderVariable: renderLink,
    renderConfigUI: renderLinkConfigUI,
  });
  return withCustomization(spec, customize);
}

// interviewer names

export type InterviewerNames = {
  value: {
    interviewers: {
      firstName: string;
      fullName: string;
      isShadow: boolean;
    }[];
  };
  config: { display: "full-names" | "first-names" };
};

const defaultInterviewerNamesConfig = { display: "full-names" } as const;

export const renderInterviewerNames = createVariableRenderer<InterviewerNames>(
  ({ value, config }) => {
    const names = value.interviewers.map((interviewer) => {
      if (config.display === "first-names") {
        return `${interviewer.firstName}${
          interviewer.isShadow ? " (Shadowing)" : ""
        }`;
      }

      return `${interviewer.fullName}${
        interviewer.isShadow ? " (Shadowing)" : ""
      }`;
    });

    return strings.joinWithFinalSeparator(names);
  }
);

const renderInterviewerNamesConfigUI =
  createVariableConfigUIRenderer<InterviewerNames>(
    ({ config, updateConfig, value, renderVariable }) => {
      const renderOptionLabel = useCallback(
        (name: string, display: typeof config.display) => {
          let label = <p>{name}</p>;
          if (value)
            label = (
              <div>
                <p>{name}</p>
                {name !== "Custom" && (
                  <p className="text-body-sm text-subtle">{`"${renderVariable({
                    display,
                  })}"`}</p>
                )}
              </div>
            );
          return label;
        },
        [config, renderVariable, value]
      );

      const selectItems = useSelectItems(
        (i) => [
          i.option({
            children: "Full names",
            renderContent: () => renderOptionLabel("Full names", "full-names"),
            value: "full-names",
          }),
          i.option({
            children: "First names",
            renderContent: () =>
              renderOptionLabel("First names", "first-names"),
            value: "first-names",
          }),
        ],
        [renderOptionLabel]
      );

      return {
        body: (
          <div className={el`person-name`}>
            <p className="display-as">Display as</p>
            <Select.Root
              value={config.display}
              setValue={(newValue) =>
                updateConfig({ display: newValue as typeof config.display })
              }
            >
              <Select.Trigger size="small" className="bg-light-gray-500" />
              <Select.Content portal items={selectItems} />
            </Select.Root>
          </div>
        ),
      };
    }
  );

const renderInterviewerNamesConfigAnnotation =
  createVariableConfigAnnotationRenderer<InterviewerNames>(
    ({ config: { display } }) => {
      switch (display) {
        case "first-names":
          return "first names";
        default:
          return undefined;
      }
    }
  );

export function interviewerNames(customize?: CustomizeSpec<InterviewerNames>) {
  const spec = createVariableSpec<InterviewerNames>({
    renderVariable: renderInterviewerNames,
    renderConfigUI: renderInterviewerNamesConfigUI,
    renderConfigAnnotation: renderInterviewerNamesConfigAnnotation,
    defaultConfig: defaultInterviewerNamesConfig,
    presets: [
      { label: "First names", defaultConfig: { display: "first-names" } },
    ],
  });
  return withCustomization(spec, customize);
}

// date range
// -----------

/**
 * Formats:
 *
 * Date (Mar 7, 2024)
 * Start time (12:00PM CST)
 * Start time and Date (12:00PM CST on Mar 7, 2024)
 * Time range (12:00 - 1:00PM CST)
 *    …across multiple days (12:00PM CST - 10:00AM CST)
 * Time range and Date (Thu, Mar 7 · 12:00 - 1:00PM CST)
 *    …across multiple days (Fri Mar 7 12:30PM - 3:00PM PDT and Tue  March 8 9:00AM - 10:00AM PDT)
 */

export type DateRange = {
  value: {
    startTime: string;
    endTime: string;
  };
  config: {
    display:
      | "date"
      | "start-time"
      | "start-time-date"
      | "time-range"
      | "time-range-date";
    timezone?: string | null;
  };
};

const defaultDateRangeConfig = {
  display: "time-range-date",
  timezone: null,
} as const;

export const renderDateRange = createVariableRenderer<DateRange>(
  ({ value, config }) => {
    if (!value) {
      // unreplaced variable
      return "";
    }

    const {
      formattedDate,
      formattedDateTime,
      formattedStartTime,
      formattedTimeRange,
    } = getFormattedDateRange({
      startTime: value.startTime,
      endTime: value.endTime,
      timezone: config.timezone ?? undefined,
    });
    switch (config.display) {
      case "date":
        return formattedDate;
      case "start-time":
        return formattedStartTime;
      case "start-time-date":
        return `${formattedStartTime} on ${formattedDate}`;
      case "time-range":
        return `${formattedTimeRange}`;
      case "time-range-date":
        return `${formattedDateTime}`;
      default:
        throw new Error("Invalid variable config");
    }
  }
);

const renderExampleDateRange = ({
  display,
  timezone,
}: {
  display: DateRange["config"]["display"];
  timezone: string | undefined;
}) => {
  const tzDisplay = DateTime.now().setZone(timezone).toFormat("ZZZZ");

  switch (display) {
    case "date":
      return `Jan 1, 2000 ${tzDisplay}`;
    case "start-time":
      return `12:00PM ${tzDisplay}`;
    case "start-time-date":
      return `12:00PM ${tzDisplay} on Jan 1, 2000`;
    case "time-range":
      return `12:00 - 1:00PM ${tzDisplay}`;
    case "time-range-date":
      return `Sat, Jan 1 · 12:00 - 1:00PM ${tzDisplay}`;
    default:
      return undefined;
  }
};

const renderDateRangeConfigUI = createVariableConfigUIRenderer<DateRange>(
  ({ config, updateConfig, renderVariable }) => {
    const renderOptionLabel = useCallback(
      (name: string, display: typeof config.display) => {
        const example = renderExampleDateRange({
          display,
          timezone: config.timezone ?? undefined,
        });

        return (
          <div>
            <p>{name}</p>
            {name !== "Custom" && (
              <p className="text-body-sm text-subtle">{`"${
                renderVariable({ display })
                  ? renderVariable({ display })
                  : example
              }"`}</p>
            )}
          </div>
        );
      },
      [config, renderVariable]
    );

    const selectItems = useSelectItems(
      (i) => [
        i.option({
          children: "Date",
          renderContent: () => renderOptionLabel("Date", "date"),
          value: "date",
        }),
        i.option({
          children: "Start Time",
          renderContent: () => renderOptionLabel("Start Time", "start-time"),
          value: "start-time",
        }),
        i.option({
          children: "Start Time and Date",
          renderContent: () =>
            renderOptionLabel("Start Time + Date", "start-time-date"),
          value: "start-time-date",
        }),
        i.option({
          children: "Time Range",
          renderContent: () => renderOptionLabel("Time Range", "time-range"),
          value: "time-range",
        }),
        i.option({
          children: "Time Range and Date",
          renderContent: () =>
            renderOptionLabel("Time Range + Date", "time-range-date"),
          value: "time-range-date",
        }),
      ],
      [renderOptionLabel]
    );

    return {
      body: (
        <div className={el`date-config`}>
          <p className="display-as">Display as</p>
          <Select.Root
            value={config.display}
            setValue={(newValue) =>
              updateConfig({ display: newValue as typeof config.display })
            }
          >
            <Select.Trigger size="small" className="bg-light-gray-500" />
            <Select.Content portal items={selectItems} />
          </Select.Root>
        </div>
      ),
    };
  }
);

const renderDateRangeConfigAnnotation =
  createVariableConfigAnnotationRenderer<DateRange>(
    ({ config: { display } }) => {
      switch (display) {
        case "date":
          return "date";
        case "start-time":
          return "start time";
        case "start-time-date":
          return "start time and date";
        case "time-range":
          return "time range";
        case "time-range-date":
          return "time range and date";
        default:
          return undefined;
      }
    }
  );

export function dateRange(customize?: CustomizeSpec<DateRange>) {
  const spec = createVariableSpec<DateRange>({
    renderVariable: renderDateRange,
    renderConfigUI: renderDateRangeConfigUI,
    renderConfigAnnotation: renderDateRangeConfigAnnotation,
    defaultConfig: defaultDateRangeConfig,
    presets: [
      {
        label: "Start Time",
        defaultConfig: {
          display: "start-time",
        },
      },
      {
        label: "Time Range",
        defaultConfig: {
          display: "time-range",
        },
      },
    ],
  });
  return withCustomization(spec, customize);
}
