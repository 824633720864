import { useToast } from "@resource/atlas/toast/use-toast";
import copy from "copy-to-clipboard";
import { useCallback } from "react";

export function useCopyToClipboard({ message }: { message?: string } = {}) {
  const toasts = useToast();
  const successMessage = message ?? "Copied link successfully";
  const copyToClipboard = useCallback(
    (url?: string | null) => {
      copy(url ?? "");
      toasts.sendToast(successMessage, {
        variant: "success",
      });
    },
    [successMessage, toasts]
  );

  return copyToClipboard;
}
