import { atlasMailWarning } from "@resource/atlas/icons";
import { useAuthContext } from "auth/context";
import { ScheduledInterviewCardBadge } from "client/components/scheduled-interviews/__components/ScheduledInterviewCardBadge";
import {
  ScheduledInterviewCard,
  ScheduledInterviewCardProps,
} from "client/components/scheduled-interviews/ScheduledInterviewCard/ScheduledInterviewCard";
import {
  OrganizationFeaturesEnum,
  ScheduledInterviewForScheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { useInternalScheduledInterviewMenuItems } from "../__hooks/useInternalScheduledInterviewMenuItems";
import { useLiteInternalScheduledInterviewMenuItems } from "../__hooks/useLiteScheduledInterviewMenuItems";
import { useInternalScheduledInterviewCardStatuses } from "./__hooks/useInternalScheduledInterviewCardStatuses";
import { useSubscribeToScheduledInterviewCardChanges } from "./__hooks/useSubscribeToScheduledInterviewCardChanges";
import { mapScheduledInterviewForCard } from "./__utils/mapping";

/** Scheduled interview card for internal usage, providing menu items and badges for internal users */
export function InternalScheduledInterviewCard({
  scheduledInterview,
  onClick,
  ...rest
}: {
  scheduledInterview: ScheduledInterviewForScheduledInterviewCardFragment;
  onClick?: () => void;
} & Pick<ScheduledInterviewCardProps, "Badge" | "showAsPast">) {
  const { hasFeatureEnabled } = useAuthContext();
  const menuItemsConfig =
    useInternalScheduledInterviewMenuItems(scheduledInterview);
  const liteMenuItemsConfig =
    useLiteInternalScheduledInterviewMenuItems(scheduledInterview);
  const warningMessages =
    useMemo((): ScheduledInterviewCardProps["warningMessages"] => {
      if (
        scheduledInterview.hidden &&
        !scheduledInterview.isCancelled &&
        !scheduledInterview.blacklisted
      ) {
        return [
          {
            icon: atlasMailWarning,
            message: "A candidate confirmation has not been sent.",
          },
        ];
      }
      return undefined;
    }, [
      scheduledInterview.blacklisted,
      scheduledInterview.hidden,
      scheduledInterview.isCancelled,
    ]);
  const statuses = useInternalScheduledInterviewCardStatuses({
    scheduledInterview,
  });
  const menuItemsConfigToUse = useMemo(() => {
    return scheduledInterview.createdByGuide &&
      hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING)
      ? menuItemsConfig
      : liteMenuItemsConfig;
  }, [
    hasFeatureEnabled,
    liteMenuItemsConfig,
    menuItemsConfig,
    scheduledInterview.createdByGuide,
  ]);

  useSubscribeToScheduledInterviewCardChanges(scheduledInterview.id);

  return (
    <ScheduledInterviewCard
      {...rest}
      scheduledInterview={mapScheduledInterviewForCard({
        interview: scheduledInterview,
      })}
      onClick={onClick}
      Badge={
        scheduledInterview.isCancelled ? (
          <ScheduledInterviewCardBadge status="cancelled" />
        ) : undefined
      }
      menuItemsConfig={menuItemsConfigToUse}
      showAsPast={scheduledInterview.isCancelled}
      warningMessages={warningMessages}
      statuses={statuses}
    />
  );
}
