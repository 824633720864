import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { useCallback } from "react";

import {
  ExtensionCandidateProfile,
  ExtensionCandidateProfileProps,
} from "./ExtensionCandidateProfile";

/**
 *  A wrapper around the ExtensionCandidateProfile component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorExtensionCandidateProfile(
  props: Omit<
    ExtensionCandidateProfileProps,
    | "onClickSchedulingRequest"
    | "onViewAllInterviews"
    | "onClickScheduledInterview"
  >
) {
  const { guide } = props;
  const navigator = useGlobalNavigatorOrThrow();
  const onClickSchedulingRequest = useCallback(
    ({ schedulingRequestId }: { schedulingRequestId: string }) => {
      navigator.navigate("schedulingTaskDetails", {
        schedulingRequestId,
      });
    },
    [navigator]
  );
  const onViewAllInterviews = useCallback(() => {
    if (guide) {
      navigator.navigate("viewAllGuideScheduledInterviews", {
        guideId: guide.id,
      });
    }
  }, [guide, navigator]);
  const onClickScheduledInterview = useCallback(
    ({ scheduledInterviewId }: { scheduledInterviewId: string }) => {
      if (guide) {
        navigator.navigate("scheduledInterviewDetails", {
          guideId: guide.id,
          scheduledInterviewId,
        });
      }
    },
    [guide, navigator]
  );

  return (
    <ExtensionCandidateProfile
      {...props}
      onClickScheduledInterview={onClickScheduledInterview}
      onViewAllInterviews={onViewAllInterviews}
      onClickSchedulingRequest={onClickSchedulingRequest}
    />
  );
}
