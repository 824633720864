import {
  AtlasContentEditorSerializedState,
  useContentEditor,
} from "@resource/atlas/content-editor";
import { ValueSet } from "@resource/atlas/content-editor/variables";
import { useSyncDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { useDisabledTooltipContentForForm } from "client/utils/form";
import { PostTemplateForUseFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";
import { contentEditorIsEmpty } from "utils/validation";

export type EditInterviewInviteAndNotifyFormData = {
  template: PostTemplateForUseFragment | null;
  interviewerConfirmationTitle: AtlasContentEditorSerializedState;
  interviewerCustomInstructions: AtlasContentEditorSerializedState;
  shouldNotifyInterviewers: boolean;
  message?: string;
};

export function useEditInterviewInviteAndNotifyState({
  defaultValues,
  valueSet = {},
}: {
  defaultValues?: EditInterviewInviteAndNotifyFormData;
  valueSet?: ValueSet<ScheduledInterviewDetailsVariableSet>;
}) {
  const form = useForm<EditInterviewInviteAndNotifyFormData>({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const title = form.watch("interviewerConfirmationTitle");

  const { contentEditorProps: titleContentEditorProps, editor: titleEditor } =
    useContentEditor();
  const {
    contentEditorProps: customInstructionsContentEditorProps,
    editor: customInstructionsEditor,
  } = useContentEditor();

  const loadTemplate = useCallback(
    (t: PostTemplateForUseFragment) => {
      form.setValue("template", t, { shouldDirty: true });
      form.setValue("interviewerCustomInstructions", t.data, {
        shouldDirty: true,
      });
      form.setValue("interviewerConfirmationTitle", t.titleData, {
        shouldDirty: true,
      });
      titleEditor.setEditorState(titleEditor.parseEditorState(t.titleData));
      customInstructionsEditor.setEditorState(
        customInstructionsEditor.parseEditorState(t.data)
      );
    },
    [customInstructionsEditor, form, titleEditor]
  );

  useSyncDialogLeaveConfirmation({
    showConfirmation:
      form.formState.isDirty &&
      !(form.formState.isSubmitted || form.formState.isSubmitting),
  });

  const defaultDisabledTooltipContent = useDisabledTooltipContentForForm(
    form.formState
  );

  // Not ideal but zod validation with react-hook-form is a little tricky and can be slow if not done properly
  // Doing it this way for now for simplicity
  const disabledTooltipContent = useMemo(() => {
    if (!title || contentEditorIsEmpty(title)) {
      return "Please include a title.";
    }

    if (defaultDisabledTooltipContent) {
      return defaultDisabledTooltipContent;
    }

    return undefined;
  }, [title, defaultDisabledTooltipContent]);

  return useMemo(
    () => ({
      form,
      titleContentEditorProps,
      customInstructionsContentEditorProps,
      titleEditor,
      customInstructionsEditor,
      loadTemplate,
      disabledTooltipContent,
      valueSet,
    }),
    [
      customInstructionsContentEditorProps,
      customInstructionsEditor,
      disabledTooltipContent,
      form,
      loadTemplate,
      titleContentEditorProps,
      titleEditor,
      valueSet,
    ]
  );
}

export type EditInterviewInviteAndNotifyState = ReturnType<
  typeof useEditInterviewInviteAndNotifyState
>;
