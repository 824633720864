import { Time, TimeSelect } from "client/components/display/times/TimeSelect";
import { useTimezone } from "client/timezones/useTimezone";
import { DateTime, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";

/** A time range that can be edited with a time selector. Includes validation around the time range when editing */
export function EditableTime({
  label,
  time,
  onEdit,
  onInvalidTimeRange,
  onValidTimeRange,
  timezone: passedTimezone,
}: {
  label?: string;
  time: {
    id: string;
    startTime: string | Time;
    endTime: string | Time;
  };
  onEdit: (updatedTime: { id: string; startTime: Time; endTime: Time }) => void;
  onInvalidTimeRange?: (id: string) => void;
  onValidTimeRange?: (id: string) => void;
  timezone?: string;
}) {
  const timezone = useTimezone(passedTimezone);
  const [isValid, setIsValid] = useState(true);
  const { defaultStartTime, defaultEndTime } = useMemo(() => {
    let start: Time;
    let end: Time;

    if (typeof time.startTime === "string") {
      const startDateTime = DateTime.fromISO(time.startTime).setZone(timezone);
      start = {
        hour: startDateTime.hour,
        minute: startDateTime.minute,
      };
    } else {
      start = time.startTime;
    }

    if (typeof time.endTime === "string") {
      const endDateTime = DateTime.fromISO(time.endTime).setZone(timezone);
      end = {
        hour: endDateTime.hour,
        minute: endDateTime.minute,
      };
    } else {
      end = time.endTime;
    }

    return { defaultStartTime: start, defaultEndTime: end };
  }, [time.endTime, time.startTime, timezone]);

  const [internalStartTime, setInternalStartTime] =
    useState<Time>(defaultStartTime);
  const [internalEndTime, setInternalEndTime] = useState<Time>(defaultEndTime);

  const tryToEditTime = useCallback(
    (updatedTime: { id: string; startTime: Time; endTime: Time }) => {
      const timeRange = Interval.fromDateTimes(
        DateTime.fromObject({
          hour: updatedTime.startTime.hour,
          minute: updatedTime.startTime.minute,
        }).setZone(timezone),
        DateTime.fromObject({
          hour: updatedTime.endTime.hour,
          minute: updatedTime.endTime.minute,
        }).setZone(timezone)
      );

      setInternalStartTime(updatedTime.startTime);
      setInternalEndTime(updatedTime.endTime);

      if (timeRange.isValid && timeRange.length("minutes") > 0) {
        onEdit({
          id: time.id,
          startTime: updatedTime.startTime,
          endTime: updatedTime.endTime,
        });
        setIsValid(true);
        if (onValidTimeRange) {
          onValidTimeRange(time.id);
        }
      } else {
        setIsValid(false);
        if (onInvalidTimeRange) {
          onInvalidTimeRange(time.id);
        }
      }
    },
    [onEdit, onInvalidTimeRange, onValidTimeRange, time.id, timezone]
  );

  const prevIsValid = usePrevious(isValid);

  useEffect(() => {
    if (!prevIsValid && isValid) {
      if (onValidTimeRange) {
        onValidTimeRange(time.id);
      }
    }
  }, [isValid, onValidTimeRange, prevIsValid, time.id]);

  useEffect(() => {
    setInternalStartTime(defaultStartTime);
    setInternalEndTime(defaultEndTime);
  }, [defaultStartTime, defaultEndTime]);

  return (
    <div>
      <div className="flex items-center gap-2 w-full">
        {label && <div className="text-body-md w-20 grow">{label}</div>}
        <div className="flex items-center gap-1">
          <TimeSelect
            className="justify-start text-body-md shrink-0 !w-[6.25rem]"
            value={{
              hour: internalStartTime.hour,
              minute: internalStartTime.minute,
            }}
            onChange={(val) => {
              tryToEditTime({
                id: time.id,
                startTime: val,
                endTime: internalEndTime,
              });
            }}
          />
          <span className="text-subtle text-body-md">-</span>
          <TimeSelect
            className="justify-start text-body-md shrink-0 !w-[6.25rem]"
            value={{
              hour: internalEndTime.hour,
              minute: internalEndTime.minute,
            }}
            onChange={(val) => {
              tryToEditTime({
                id: time.id,
                startTime: internalStartTime,
                endTime: val,
              });
            }}
          />
        </div>
      </div>
      {!isValid && (
        <div className="text-error text-body-sm text-red-500">
          Invalid time range
        </div>
      )}
    </div>
  );
}
