import { CandidateDetails } from "client/components/guide/candidate-details/CandidateDetails";
import { mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/mapping";
import { UpsertScheduledInterview } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

import { ViewDetails } from "../core/components/navigation/SchedulerLeftSidePanel";
import { Scheduler, SchedulerProps } from "../core/components/Scheduler";
import { mapScheduledInterviewFragmentToSchedulerInterview } from "../utils/mapping";

gql(`
  fragment ScheduledInterviewGroupForSchedulerHydrationV3 on ScheduledInterviewGroupDB {
    id
    guideId
    guide {
      ...GuideForSchedulerV3
      
      organization {
        ...OrganizationForSchedulerV3
      }
    }
    scheduledInterviews {
      id
      ...ScheduledInterviewForSchedulerV3
    }
    ...ScheduledInterviewGroupForGroupSettings
  }
`);

const SCHEDULER_QUERY = gql(`
  query ScheduledInterviewGroupSchedulerHydrationQuery($scheduledInterviewGroupId: ID!) {
    scheduledInterviewGroupById(id: $scheduledInterviewGroupId) {
      ...ScheduledInterviewGroupForSchedulerHydrationV3
    }
  }
`);

export type ScheduledInterviewGroupSchedulerProps = {
  scheduledInterviewGroupId: string;
  defaultSelectedInterviewId?: string;
  defaultInterviews?: UpsertScheduledInterview[];
} & Omit<SchedulerProps, "viewDetails">;

export function ScheduledInterviewGroupScheduler({
  scheduledInterviewGroupId,
  defaultSelectedInterviewId,
  defaultInterviews,
  ...rest
}: ScheduledInterviewGroupSchedulerProps) {
  const {
    guide,
    interviews,
    originalInterviews,
    selectedInterviewId,
    defaultScheduledInterviewGroupSettings,
  } = useScheduledInterviewGroupSchedulerData({
    scheduledInterviewGroupId,
    defaultSelectedInterviewId,
    defaultInterviews,
  });

  const viewDetails = useMemo((): ViewDetails => {
    return {
      label: "Candidate details",
      DisplayComponent: guide ? <CandidateDetails guide={guide} /> : null,
    };
  }, [guide]);

  if (!guide) {
    return <DialogLoading isFullscreen />;
  }

  return (
    <Scheduler
      {...rest}
      interviews={interviews}
      originalInterviews={originalInterviews}
      guideId={guide.id}
      scheduledInterviewGroupId={scheduledInterviewGroupId}
      selectedInterviewId={selectedInterviewId}
      schedulingRequestId={null}
      scheduledInterviewGroupSettings={defaultScheduledInterviewGroupSettings}
      viewDetails={viewDetails}
    />
  );
}

function useScheduledInterviewGroupSchedulerData({
  scheduledInterviewGroupId,
  defaultSelectedInterviewId,
  defaultInterviews,
}: {
  scheduledInterviewGroupId: string;
  defaultSelectedInterviewId?: string;
  defaultInterviews?: UpsertScheduledInterview[];
}) {
  const { data } = useQuery(SCHEDULER_QUERY, {
    // ensure scheduling data is up to date
    fetchPolicy: "network-only",
    variables: {
      scheduledInterviewGroupId,
    },
  });

  return useMemo(() => {
    const scheduledInterviews =
      data?.scheduledInterviewGroupById?.scheduledInterviews ?? [];
    const interviews = scheduledInterviews.map(
      mapScheduledInterviewFragmentToSchedulerInterview
    );
    const defaultSelectedInterview = interviews.find(
      (interview) => interview.id === defaultSelectedInterviewId
    );
    const selectedInterviewId =
      defaultSelectedInterview?.id ?? interviews[0]?.id ?? null;
    const scheduledInterviewGroup = data?.scheduledInterviewGroupById;
    const guide = scheduledInterviewGroup?.guide;
    const defaultScheduledInterviewGroupSettings = scheduledInterviewGroup
      ? mapScheduledInterviewGroupFragmentToScheduledInterviewGroupSettings(
          scheduledInterviewGroup
        )
      : null;
    const interviewsWithPassedDefaults = interviews.map((interview) => {
      const defaultedInterview = defaultInterviews?.find(
        (defaultInterview) => defaultInterview.id === interview.id
      );

      if (defaultedInterview) {
        return {
          ...interview,
          ...defaultedInterview,
        };
      }

      return interview;
    });

    return {
      guide,
      originalInterviews: interviews,
      interviews: interviewsWithPassedDefaults,
      selectedInterviewId,
      defaultScheduledInterviewGroupSettings,
    };
  }, [
    data?.scheduledInterviewGroupById,
    defaultInterviews,
    defaultSelectedInterviewId,
  ]);
}
