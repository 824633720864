import TextArea from "@resource/atlas/textfield/TextArea";
import { PhoneInput } from "client/components/generic/inputs/PhoneInput";
import { Controller, UseFormReturn } from "react-hook-form";

import { SelfScheduleRequestSelfScheduleFormData } from "./__hooks/useSelfScheduleRequestSelfScheduleForm";

export type SelfScheduleRequestSelfScheduleFormProps = {
  form: UseFormReturn<SelfScheduleRequestSelfScheduleFormData>;
  requirePhone?: boolean;
  interviewerName: string;
};

export function SelfScheduleRequestSelfScheduleForm({
  form,
  requirePhone,
  interviewerName,
}: SelfScheduleRequestSelfScheduleFormProps) {
  return (
    <>
      {requirePhone && (
        <Controller
          name="phone"
          control={form.control}
          render={({ field, fieldState: { isTouched, error } }) => {
            return (
              <PhoneInput
                {...field}
                isRequired
                label="Phone number"
                errorMessage={isTouched ? error?.message : undefined}
                value={field.value ?? ""}
              />
            );
          }}
        />
      )}
      <div className="space-y-1">
        <span className="text-body-md-heavy">
          Add a message for {interviewerName}
        </span>
        <Controller
          name="message"
          control={form.control}
          render={({ field }) => (
            <TextArea
              {...field}
              className="h-60"
              inputClassName="h-60"
              placeholder="Add a message for the interviewer (optional)"
            />
          )}
        />
      </div>
    </>
  );
}
