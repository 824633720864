import { HeaderProps } from "@resource/atlas/view/View";
import { FakeAvailabilitySection } from "client/app/internal/(scheduling requests)/FakeAvailabilitySection";
import { useIsInternal } from "client/app/internal/(scheduling requests)/state";
import { SchedulingTaskCommentFooter } from "client/comments/SchedulingTaskCommentFooter";
import { ActivityFeed } from "client/components/activity-feed/ActivityFeed";
import { ViewWithAssistantToggle } from "client/components/generic/layout/ViewWithAssistantToggle";
import { Divider } from "client/components/generic/misc/Divider";
import { InterviewSections } from "client/components/scheduling-tasks/in-progress/InterviewSections";
import { SchedulingRequestInfo } from "client/components/scheduling-tasks/in-progress/SchedulingRequestInfo";
import { SchedulingTaskArchivedBanner } from "client/components/scheduling-tasks/SchedulingTaskArchivedBanner";
import { SchedulingTaskCompletedWithInterviewsBanner } from "client/components/scheduling-tasks/SchedulingTaskCompletedWithInterviewsBanner";
import { GlobalCandidateAvailabilitySection } from "client/guide-availability/components/GlobalCandidateAvailabilitySection";
import { GuideIdProvider } from "client/utils/guide-id-provider";
import clsx from "clsx";
import {
  InterviewRequirementForConfigurationFragment,
  SchedulingRequestForDetailsViewFragment,
  SchedulingRequestStatus,
} from "generated/graphql-codegen/graphql";
import { useEffect, useMemo } from "react";
import { useQueryStringValue } from "utils/next";

import { useReRunOrchestrationForTaskSchedulingAssistant } from "../hooks/useReRunOrchestrationForTask";
import { useSchedulingRequestActivity } from "../hooks/useSchedulingRequestActivity";
import { useScrollOnComment } from "../hooks/useScrollOnComment";
import { useScrollToTopOnStatusChange } from "../hooks/useScrollToTopOnStatusChange";

/**
 * Display the details of a scheduling task
 */
export function SchedulingTaskDetailsView({
  schedulingRequest,
  onSchedule,
  onNavigateToScheduledInterviewDetails,
  onEditTask,
  onEditRequirement,
  header,
}: {
  schedulingRequest: SchedulingRequestForDetailsViewFragment;
  onSchedule: () => void;
  onNavigateToScheduledInterviewDetails: (props: {
    scheduledInterviewId: string;
  }) => void;
  onEditTask: () => void;
  onEditRequirement: (
    requirement: InterviewRequirementForConfigurationFragment
  ) => void;
  header: HeaderProps;
}) {
  const { scrollableBodyRef } = useScrollToTopOnStatusChange({
    schedulingRequest,
  });
  const { activityFeedRef, commentFooterProps } = useScrollOnComment({
    scrollableBodyRef,
  });

  const {
    activity,
    nextPage: activityNextPage,
    loading: activityLoading,
    refetch: refetchActivity,
  } = useSchedulingRequestActivity(schedulingRequest.id);

  const commentId = useQueryStringValue("commentId");

  const isInternal = useIsInternal();
  const shouldShowCompletedTaskWorkflow =
    schedulingRequest.status === SchedulingRequestStatus.COMPLETED;

  useReRunOrchestrationForTaskSchedulingAssistant({
    schedulingRequest,
  });

  const viewContent = useMemo(() => {
    return {
      className: "bg-light-gray-200 p-0 relative",
      ref: scrollableBodyRef,
    };
  }, [scrollableBodyRef]);

  // Force reloading activity if we have a comment id to ensure we have the comment id
  useEffect(() => {
    if (commentId && !activityLoading) {
      refetchActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId]);

  return (
    <GuideIdProvider guideId={schedulingRequest.guideId}>
      <ViewWithAssistantToggle header={header} content={viewContent}>
        <div className="bg-white pb-16">
          {schedulingRequest.archivedAt && (
            <SchedulingTaskArchivedBanner
              schedulingRequestId={schedulingRequest.id}
            />
          )}
          {shouldShowCompletedTaskWorkflow && (
            <SchedulingTaskCompletedWithInterviewsBanner
              schedulingRequest={schedulingRequest}
            />
          )}
          <SchedulingRequestInfo schedulingRequest={schedulingRequest} />
          <div
            className={clsx({
              "pointer-events-none opacity-50":
                !isInternal &&
                (shouldShowCompletedTaskWorkflow ||
                  schedulingRequest.archivedAt),
            })}
          >
            <div className="flex flex-col">
              <FakeAvailabilitySection />
              <div className="p-6">
                <GlobalCandidateAvailabilitySection
                  guideId={schedulingRequest.guideId}
                  candidateName={schedulingRequest.guide.candidate.name}
                  jobName={schedulingRequest.guide.job.internalName}
                  candidateTimezone={schedulingRequest.guide.candidate.timezone}
                  schedulingRequest={schedulingRequest}
                  showLargeActions
                />
              </div>

              <Divider />

              <InterviewSections
                schedulingRequest={schedulingRequest}
                onScheduleAll={onSchedule}
                onReschedule={onSchedule}
                onNavigateToScheduledInterviewDetails={
                  onNavigateToScheduledInterviewDetails
                }
                onEditTask={onEditTask}
                onEditRequirement={onEditRequirement}
                hasAvailability={
                  !!schedulingRequest.guide.currentAvailabilitySubmission
                }
              />
            </div>
          </div>
        </div>
        <div ref={activityFeedRef}>
          <ActivityFeed
            className="bg-light-gray-200"
            guide={schedulingRequest.guide}
            activity={activity}
            nextPage={activityNextPage}
            loading={activityLoading}
            context="SchedulingRequest"
            showHeading
          />
        </div>
        <SchedulingTaskCommentFooter
          {...commentFooterProps}
          schedulingTaskId={schedulingRequest.id}
        />
      </ViewWithAssistantToggle>
    </GuideIdProvider>
  );
}
