import { useRender } from "@resource/atlas/content-editor/renderer";
import { themeContainerClass } from "@resource/atlas/content-editor/theme";
import type { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor/types";
import {
  ValueSet,
  VariableRendererProvider,
} from "@resource/atlas/content-editor/variables";
import { RichBlockContextProvider } from "client/guide-content/rich-blocks/provider";
import { useGuideIdContext } from "client/utils/guide-id-provider";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import {
  GuideUpdateVariableSet,
  renderGuideUpdate,
} from "shared/guide-content/update";

type GuideUpdateRendererProps = ComponentPropsWithoutRef<"div"> & {
  content: unknown;
  valueSet?: ValueSet<GuideUpdateVariableSet>;
};

export const GuideUpdateRenderer = forwardRef<
  HTMLDivElement,
  GuideUpdateRendererProps
>(({ content, valueSet, ...props }, ref) => {
  const renderedContent = useRender(
    renderGuideUpdate,
    content as AtlasContentEditorSerializedState
  );
  const guideIdContext = useGuideIdContext();

  if (!renderedContent) {
    return (
      <>
        <div className="flex flex-col gap-[0.375rem] mt-[1.188rem]">
          <Skeleton className="h-[0.875rem] max-w-[34.75rem]" type="text" />
          <Skeleton
            className="h-[0.813rem] max-w-[31.25rem] mr-14"
            type="text"
          />
        </div>
        <Skeleton className="h-[17.5rem] w-full mt-[0.875rem]" type="rect" />
        <div className="flex flex-col gap-[0.375rem] mt-[0.938rem]">
          <Skeleton className="h-[0.875rem] max-w-[34.75rem]" type="text" />
          <Skeleton
            className="h-[0.813rem] max-w-[31.25rem] mr-14"
            type="text"
          />
        </div>
      </>
    );
  }

  return guideIdContext?.guideId ? (
    <RichBlockContextProvider guideId={guideIdContext.guideId}>
      <VariableRendererProvider valueSet={valueSet}>
        <div
          {...props}
          ref={ref}
          className={clsx(
            "scroll-mt-[8.5rem]",
            props.className,
            themeContainerClass
          )}
        >
          {renderedContent}
        </div>
      </VariableRendererProvider>
    </RichBlockContextProvider>
  ) : null;
});
