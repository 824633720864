import { z } from "zod";

import { createNotificationTypeDataSchema } from "./types";

export const schedulingRequestAssignedDataSchema =
  createNotificationTypeDataSchema({
    metadata: z.object({
      schedulingRequestId: z.string(),
      actorId: z.string(),
    }),
    resolved: z.object({
      actor: z.object({
        id: z.string(),
        name: z.string(),
        avatarUrl: z.string(),
        email: z.string(),
      }),
      candidate: z.object({
        name: z.string(),
      }),
      organization: z.object({
        name: z.string(),
      }),
    }),
  });
