import { CypressData } from "client/cypress-data-keys";

import { useCalendarData } from "../../hooks/data";
import { useCalendarOptions } from "../../hooks/options";
import { useSetEventsViewRef } from "../../hooks/refs";
import { useCalendarSettings } from "../../hooks/settings";
import { useColumns } from "../../hooks/useColumns";
import { useMapEventGroupWithComputedOverrides } from "../../hooks/useMapEventGroupWithComputedOverrides";
import { CalendarEvent } from "../../utils/types";
import { HorizontalLines } from "../generic/HorizontalLines";
import { LeftMarginHorizontalTimeIndicators } from "../generic/LeftMarginHorizontalTimeIndicators";
import { VerticalLines } from "../generic/VerticalLines";
import { Event } from "./Event";
import { EventsGridSizingWrapper } from "./EventsGridSizingWrapper";
import { EventGroup } from "./EventsGroup";

export function EventsView() {
  return (
    <div className="w-full grid flex-auto grid-cols-1 grid-rows-1">
      <HorizontalLines />
      <VerticalLines />
      <Events />
      <LeftMarginHorizontalTimeIndicators />
    </div>
  );
}

function Events() {
  const setEventsViewRef = useSetEventsViewRef();
  const { columnType } = useColumns();

  return (
    <EventsGridSizingWrapper
      id="calendar-events-view-portal"
      ref={(r) => setEventsViewRef(r)}
      data-cy={CypressData.calendar.eventsView}
      includeLeftOffsetMargin
    >
      {columnType === "day" ? <DayGroupedEvents /> : <CalendarGroupedEvents />}
      <BackgroundEvents />
      {/* Need to make sure the portal and the ref always has a child */}
      <span />
    </EventsGridSizingWrapper>
  );
}

function DayGroupedEvents() {
  const { eventGroups } = useCalendarData();
  const mapEventGroup = useMapEventGroupWithComputedOverrides();

  return (
    <>
      {eventGroups.map((eventGroup) => {
        const mappedEventGroup = mapEventGroup({
          eventGroup,
        });

        return (
          <EventOrEventGroup
            eventGroup={mappedEventGroup}
            key={eventGroupKey(eventGroup)}
          />
        );
      })}
    </>
  );
}

function CalendarGroupedEvents() {
  const { eventGroupsByCalendar } = useCalendarData();
  const { dayViewType } = useCalendarSettings();
  const { dayViewCalendarIds, calendarsGrouping } = useCalendarOptions();
  const mapEventGroup = useMapEventGroupWithComputedOverrides();

  if (dayViewType === "calendar_and_grouping" && calendarsGrouping) {
    return (
      <>
        {calendarsGrouping.map((grouping, calendarGroupIdx) => {
          return grouping.calendarIds.map((calendarId) => {
            return eventGroupsByCalendar[calendarId]?.map((eventGroup) => {
              const mappedEventGroup = mapEventGroup({
                eventGroup,
                groupingId: grouping.id,
                calendarId,
              });

              return (
                <EventOrEventGroup
                  eventGroup={mappedEventGroup}
                  key={eventGroupKey(eventGroup)}
                  calendarId={calendarId}
                  calendarGroupIdx={calendarGroupIdx}
                />
              );
            });
          });
        })}
      </>
    );
  }

  return (
    <>
      {dayViewCalendarIds.map((calendarId) => {
        return eventGroupsByCalendar[calendarId]?.map((eventGroup) => {
          const mappedEventGroup = mapEventGroup({
            eventGroup,
            calendarId,
          });

          return (
            <EventOrEventGroup
              eventGroup={mappedEventGroup}
              key={eventGroupKey(eventGroup)}
              calendarId={calendarId}
            />
          );
        });
      })}
    </>
  );
}

function eventGroupKey(eventGroup: CalendarEvent[]) {
  return `${eventGroup[0].id}-${
    eventGroup[0].calendarId
  }-${eventGroup[0].startTime.toISO()}`;
}

function EventOrEventGroup({
  eventGroup,
  calendarId,
  calendarGroupIdx,
}: {
  eventGroup: CalendarEvent[];
  calendarId?: string;
  calendarGroupIdx?: number;
}) {
  if (eventGroup.length === 1) {
    return (
      <Event
        event={eventGroup[0]}
        calendarId={calendarId}
        calendarGroupIdx={calendarGroupIdx}
      />
    );
  }

  return (
    <EventGroup
      eventGroup={eventGroup}
      calendarId={calendarId}
      calendarGroupIdx={calendarGroupIdx}
    />
  );
}

function BackgroundEvents() {
  const { backgroundEvents } = useCalendarData();

  return (
    <>
      {backgroundEvents.map((event) => (
        <Event
          event={event}
          key={`${event.id}-${event.calendarId}-${event.startTime.toISO()}`}
        />
      ))}
    </>
  );
}
