/* eslint-disable no-underscore-dangle */
// TODO: Merge with modules/lexical

// TODO: Something up with this file that importing server side sometimes causes cypress component tests to fail

import { LinkNode } from "@lexical/link";
import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { fileModule } from "@resource/atlas/content-editor/file";
import { createHeadlessContentEditor } from "@resource/atlas/content-editor/headless";
import { imageModule } from "@resource/atlas/content-editor/image";
import {
  linkCardModule,
  linkCardsCollectionModule,
} from "@resource/atlas/content-editor/link-card";
import { createHeadlessContentEditorLite } from "@resource/atlas/content-editor/lite-editor/headless";
import {
  mentionsModule,
  SerializedMentionNode,
} from "@resource/atlas/content-editor/mentions";
import { richQuoteModule } from "@resource/atlas/content-editor/rich-quote";
import {
  VariableNode,
  variablesModule,
} from "@resource/atlas/content-editor/variables";
import { unknownVariablesModule } from "@resource/atlas/content-editor/variables/__lib/module";
import { videoModule } from "@resource/atlas/content-editor/video";
import { GuideAvailabilityRequestNode } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/rich-block";
import { gifModule } from "client/guide-content/rich-blocks/gif";
import { interviewsModule } from "client/guide-content/rich-blocks/interviews/__lib/rich-block";
import { SelfScheduleRequestNode } from "client/self-schedule/rich-blocks/self-schedule-request/rich-block";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $nodesOfType,
  SerializedParagraphNode,
} from "lexical";
import { isEqual } from "lodash";
import { createGuideUpdateHeadlessEditor } from "server/guide-content/update/headless-editor";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";
import { v4 } from "uuid";

import { filterOutNullsAndUndefined } from "./filtering";

export const getLexicalJsonFromTitle = (title: string) => {
  const editor = createHeadlessContentEditorLite({});

  editor.update(
    () => {
      $getRoot().append($createParagraphNode().append($createTextNode(title)));
    },
    { discrete: true }
  );

  const parsedTitle = editor.getEditorState().toJSON();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return parsedTitle as unknown as any;
};

export const getEditorStateFromText = (text: string) => {
  const editor = createHeadlessContentEditorLite({});

  editor.update(
    () => {
      $getRoot().append($createParagraphNode().append($createTextNode(text)));
    },
    { discrete: true }
  );

  const parsedText = editor.getEditorState().toJSON();

  return parsedText;
};

export const convertTextMessageToLexicalJson = (text: string) => {
  const editor = createHeadlessContentEditorLite({});

  editor.update(
    () => {
      const splitText = text.split("\n");

      const textNodes = splitText
        .map((textItem) => {
          if (textItem) {
            return $createParagraphNode().append($createTextNode(textItem));
          }
          return null;
        })
        .filter(filterOutNullsAndUndefined);

      $getRoot().append(...textNodes);
    },
    { discrete: true }
  );

  const parsedText = editor.getEditorState().toJSON();

  return parsedText;
};

export const getTitleFromLexicalJson = (title: string) => {
  try {
    const editor = createHeadlessContentEditorLite({
      modules: [variablesModule],
    });

    const parsedEditorState = editor.parseEditorState(title);

    const editorStateTextString = parsedEditorState.read(() =>
      $getRoot().getTextContent()
    );

    return editorStateTextString;
  } catch (e) {
    return title;
  }
};

export const findRegexInContentForGuideUpdate = (
  content: string,
  regex: RegExp
) => {
  try {
    const editor = createGuideUpdateHeadlessEditor({
      modules: [
        variablesModule,
        unknownVariablesModule,
        imageModule,
        videoModule,
        richQuoteModule,
        linkCardModule,
        linkCardsCollectionModule,
        gifModule,
        fileModule,
      ],
    });

    const parsedEditorState = editor.parseEditorState(content);

    const textContent = parsedEditorState.read(() => {
      return $getRoot().getTextContent();
    });
    const allLinksText = parsedEditorState.read(() => {
      return $nodesOfType(LinkNode)
        .map((node) => node.getURL())
        .join(" ");
    });
    const textRegex = textContent.match(regex);
    const linkRegex = allLinksText.match(regex);

    return [...(textRegex || []), ...(linkRegex || [])];
  } catch (e) {
    return null;
  }
};

/**
 * Checks if the lexical string has text content. Note: variables that have not been filled yet do not count as text content.
 */
export const getLexicalHasContent = (content: string) => {
  try {
    const editor = createHeadlessContentEditor({
      modules: [
        variablesModule,
        unknownVariablesModule,
        imageModule,
        videoModule,
        richQuoteModule,
        linkCardModule,
        linkCardsCollectionModule,
        gifModule,
        fileModule,
        interviewsModule,
      ],
    });

    const parsedEditorState = editor.parseEditorState(content);

    const editorStateTextString = parsedEditorState.read(() =>
      $getRoot().getTextContent()
    );

    return editorStateTextString.trim().length > 0;
  } catch (e) {
    return content;
  }
};

export const getMentionNodes = (content: AtlasContentEditorSerializedState) => {
  const mentionNodes: SerializedMentionNode[] = [];

  const paragraphOrMentionNodes = content.root?.children?.filter(
    (node) => node.type === "paragraph" || node.type === "mention"
  );

  paragraphOrMentionNodes?.forEach((node) => {
    if (node.type === "paragraph") {
      const paragraphNode = node as SerializedParagraphNode;

      paragraphNode.children.forEach((child) => {
        if (child.type === "mention") {
          mentionNodes.push(child as SerializedMentionNode);
        }
      });
    } else {
      mentionNodes.push(node as SerializedMentionNode);
    }
  });

  return mentionNodes.filter((mn) => mn.type === "mention");
};

export const getCommentEditorHasContent = (
  content: AtlasContentEditorSerializedState
) => {
  try {
    const editor = createHeadlessContentEditor({
      modules: [mentionsModule],
    });
    const parsedEditorState = editor.parseEditorState(content);
    const mentionNodes = getMentionNodes(parsedEditorState.toJSON());
    const hasMentionNodes = mentionNodes.length > 0;
    const editorStateTextString = parsedEditorState.read(() =>
      $getRoot().getTextContent()
    );

    return editorStateTextString.trim().length > 0 || hasMentionNodes;
  } catch (e) {
    return content;
  }
};

export const isEmptyLexical = (value: string) => {
  return isEqual(value, JSON.stringify(LEXICAL_EMPTY_STATE));
};

export function $updateVariableTimezones({ timezone }: { timezone: string }) {
  const variableNodes = $nodesOfType(VariableNode);

  variableNodes.forEach((node) => {
    node.setConfig({
      ...(node.__config || {}),
      timezone,
    });
  });
}

type FakeSenderData = {
  name: string;
  imageUrl: string;
};

/**
 * There are blocks that aren't valid until the post has been actually been created
 * i.e. request availability needs an availabilityRequestId to be valid
 * For preview messages, we can fake this data so the email renderers think they're valid
 */
export function updateRichBlocksForPreview({
  data,
  messageSender,
  actorId,
}: {
  data: AtlasContentEditorSerializedState;
  messageSender: FakeSenderData;
  actorId: string;
}) {
  if (!data) return data;

  const headlessEditor = createGuideUpdateHeadlessEditor();
  headlessEditor.setEditorState(
    headlessEditor.parseEditorState(JSON.stringify(data))
  );

  headlessEditor.update(
    () => {
      const availabilityRequestNodes = $nodesOfType(
        GuideAvailabilityRequestNode
      );

      availabilityRequestNodes.forEach((node) => {
        if (!("availabilityRequestId" in node.__data)) {
          const replacementNode = new GuideAvailabilityRequestNode({
            ...node.__data,
            preview: true,
            createdBy: {
              id: v4(),
              name: messageSender.name,
              imageUrl: messageSender.imageUrl,
            },
          });

          node.replace(replacementNode);
        }
      });

      const selfScheduleRequestNodes = $nodesOfType(SelfScheduleRequestNode);

      selfScheduleRequestNodes.forEach((node) => {
        const replacementNode = new SelfScheduleRequestNode({
          ...node.__data,
          preview: true,
          actorId,
        });
        node.replace(replacementNode);
      });
    },
    { discrete: true }
  );

  return headlessEditor.getEditorState().toJSON();
}
