import {
  InterviewerPoolForTrainingProgressFragment,
  UserMembershipForTrainingProgressFragment,
} from "generated/graphql-codegen/graphql";

import { TrainingProgressData } from "./types";

export function mapUserMembershipToTrainingProgress({
  userMembership,
  interviewerPool,
}: {
  userMembership: UserMembershipForTrainingProgressFragment;
  interviewerPool: InterviewerPoolForTrainingProgressFragment;
}): TrainingProgressData {
  const traineeInfo = userMembership.traineeInfo?.find(
    (i) => i.interviewerPoolId === interviewerPool.id
  );
  return {
    reverseShadows: interviewerPool.reverseShadowsRequiredForTrainees,
    shadows: interviewerPool.shadowsRequiredForTrainees,
    completedReverseShadows: traineeInfo?.reverseShadowsCompleted,
    completedShadows: traineeInfo?.shadowsCompleted,
  };
}
