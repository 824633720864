import { Button } from "@resource/atlas/button/Button";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarEvents,
  atlasClock,
  atlasCopy,
  atlasJobs,
  atlasLinkExternal,
  atlasRingPerson,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { CandidateName } from "client/components/CandidateName";
import { CandidateTimezoneDialog } from "client/components/guide/candidate-details/CandidateTimezoneDialog";
import { CandidateAvailability } from "client/guide-availability/components/CandidateAvailability";
import { CopyAvailabilityDialog } from "client/guide-availability/components/CopyAvailabilityDialog";
import { CondensedRequestInfoRow } from "components/RequestInfoRow/CondensedRequestInfoRow";
import { gql } from "generated/graphql-codegen";
import { GuideForCandidateDetailsFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import { displayTimezone } from "shared/utils/timezones";

gql(`
  fragment GuideForCandidateDetails on Guide {
    id
    atssyncApplication {
      id
      atsUrl
    }
    isInternalATSApplication
    candidate {
      id
      name: displayName
      firstName: internalFirstName
      lastName
      preferredName
      timezone
      imageUrl
    }
    job {
      id
      internalName
    }
    currentAvailabilitySubmission {
      id
      notes
      upcomingEvents {
        id
        startTime
        endTime
      }
    }
  }  
`);

export function CandidateDetails({
  guide,
}: {
  guide: GuideForCandidateDetailsFragment;
}) {
  const candidateTimezoneDialogState = useDialogState();
  const copyAvailabilityStore = useDialogStore();
  const candidateAvailability = useMemo(() => {
    return (
      guide.currentAvailabilitySubmission?.upcomingEvents.map((event) => ({
        id: event.id,
        title: "",
        startTime: event.startTime,
        endTime: event.endTime,
      })) ?? []
    );
  }, [guide.currentAvailabilitySubmission?.upcomingEvents]);
  const candidateNotes = useMemo(() => {
    return guide.currentAvailabilitySubmission?.notes;
  }, [guide.currentAvailabilitySubmission?.notes]);
  const { candidateName, candidateTimezone, jobName, greenhouseProfileUrl } =
    useMemo(() => {
      return {
        candidateName: guide.candidate.name,
        candidateTimezone: guide.candidate.timezone,
        jobName: guide.job.internalName,
        greenhouseProfileUrl: guide.atssyncApplication?.atsUrl,
      };
    }, [
      guide.atssyncApplication?.atsUrl,
      guide.candidate.name,
      guide.candidate.timezone,
      guide.job.internalName,
    ]);

  return (
    <>
      <CondensedRequestInfoRow
        icon={atlasRingPerson}
        action={
          greenhouseProfileUrl ? (
            <Link
              href={greenhouseProfileUrl}
              target="_blank"
              className="shrink-0"
            >
              <div className="flex items-center space-x-1">
                <span>GH Profile</span>
                <Icon content={atlasLinkExternal} className="w-4 h-4" />
              </div>
            </Link>
          ) : null
        }
      >
        <CandidateName
          candidate={guide.candidate}
          isInternal={guide.isInternalATSApplication}
        />
      </CondensedRequestInfoRow>
      <CondensedRequestInfoRow icon={atlasJobs}>
        {jobName}
      </CondensedRequestInfoRow>
      <CondensedRequestInfoRow
        icon={atlasClock}
        action={
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link as="button" onClick={candidateTimezoneDialogState.show}>
            Edit
          </Link>
        }
      >
        {candidateTimezone
          ? displayTimezone(candidateTimezone, "default")
          : "No timezone"}
      </CondensedRequestInfoRow>
      <CondensedRequestInfoRow icon={atlasCalendarEvents} multiline>
        <div className="space-y-1 overflow-hidden w-full">
          {candidateAvailability.length ? (
            <div className="flex justify-between items-start">
              <span className="text-body-md-heavy">Candidate availability</span>
              <Tooltip content="Copy availability as text">
                <Button
                  isGhost
                  size="xs"
                  icon={atlasCopy}
                  onClick={() => {
                    copyAvailabilityStore.show();
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
          {candidateAvailability.length ? (
            <CandidateAvailability
              events={candidateAvailability}
              isCurrentSubmission
              timezone={candidateTimezone}
              listItemClassName="text-body-sm"
              allowCollapse
            />
          ) : (
            "No candidate availability"
          )}
          {candidateNotes && (
            <div className="text-body-sm text-subtle">
              &quot;{candidateNotes}&quot; - {candidateName}
            </div>
          )}
        </div>
        <CopyAvailabilityDialog
          candidateName={candidateName}
          jobName={jobName}
          timezone={candidateTimezone}
          store={copyAvailabilityStore}
          availability={candidateAvailability}
        />
      </CondensedRequestInfoRow>
      <CandidateTimezoneDialog
        dialogState={candidateTimezoneDialogState}
        candidate={guide.candidate}
      />
    </>
  );
}
