import { DateTime } from "luxon";
import { getReadableTimezone } from "shared/utils/timezones";

import { AvailableSlot } from "../__hooks/useSelfScheduleSubmissionState";

export const DEFAULT_CREATE_MESSAGE =
  "Thank you - I'm looking forward to speaking soon.";
// Once a new time is selected, we will update the message to include the new time if it is still the default
export const DEFAULT_RESCHEDULE_MESSAGE =
  "Quick heads up - I'm rescheduling my interview.";

export const FORMATTED_MESSAGE_TIME_REGEX =
  /([A-Za-z]{3}, [A-Za-z]{3} [0-9]{1,2} at [0-9]{1,2}:[0-9]{2}[A|P]M \(\w+\))/;

export const getFormattedMessageTime = (time: DateTime) => {
  return `${time.toFormat("EEE, MMM d 'at' h:mma")} (${getReadableTimezone({
    timezone: time.zoneName,
    labelType: "abbreviation",
  })})`;
};

export const getDefaultRescheduleMessageWithTime = (startTime: DateTime) => {
  return `Quick heads up - I'm rescheduling my interview for ${getFormattedMessageTime(
    startTime
  )}.`;
};

export const getDefaultCancellationMessage = (startTime: DateTime) => {
  return `Quick heads up – I need to cancel our interview scheduled for ${getFormattedMessageTime(
    startTime
  )}. Apologies for any inconvenience. Let's touch base soon to reschedule.`;
};

export function getNewDefaultMessage({
  slot,
  timezone,
  isRescheduling,
  currentMessage,
}: {
  slot: AvailableSlot | null;
  timezone: string;
  isRescheduling: boolean;
  currentMessage: string;
}) {
  if (slot) {
    if (isRescheduling) {
      if (currentMessage.includes(DEFAULT_RESCHEDULE_MESSAGE)) {
        // If it is the default reschedule message without time, include the time
        return getDefaultRescheduleMessageWithTime(
          slot.startTime.setZone(timezone)
        );
      }

      // If the message includes a date and time of the default format, update it with the new slot time
      const match = new RegExp(FORMATTED_MESSAGE_TIME_REGEX).test(
        currentMessage
      );

      if (match) {
        return currentMessage.replace(
          FORMATTED_MESSAGE_TIME_REGEX,
          getFormattedMessageTime(slot.startTime.setZone(timezone))
        );
      }
    }

    return currentMessage;
  }

  return null;
}
