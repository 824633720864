import { Avatar } from "@resource/atlas/avatar/Avatar";
import { AtlasAvatarProps } from "@resource/atlas/avatar/types";
import {
  ColorConfig,
  getColorsFromConfig,
  // eslint-disable-next-line import/no-restricted-paths
} from "client/components/calendar-v2/utils/colors";
import UserMembershipConflictIcon from "client/components/interviewer-slots/conflicts/display/UserMembershipConflictIcon";
import InterviewLoadFooter from "client/components/interviewer-slots/load/display/InterviewLoadFooter";
import { getAvatarStatusFromInterviewerResponseStatus } from "client/utils/interviewer-response-status";
import clsx from "clsx";
import { InterviewerResponse } from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import React from "react";

import { TrainingProgress } from "../training/display/TrainingProgress";
import { UserMembershipWithSchedulingData } from "../utils/types";

export type SelectedInterviewerDisplayProps = {
  interviewer: UserMembershipWithSchedulingData;
  responseStatus?: InterviewerResponse | null;
  isShadower?: boolean | null;
  calendarColor?: ColorConfig;
  RightActions?: React.ReactNode;
  hideTimezone?: boolean;
  hideAvailabilityIcon?: boolean;
  hideShadowingLabel?: boolean;
  hideTrainingData?: boolean;
  showDayCount?: boolean;
  Subtext?: React.ReactNode;
  size?: "medium" | "small" | "xs";
};

const avatarSizeMap: Record<string, AtlasAvatarProps["size"]> = {
  medium: "medium",
  small: "small",
  xs: "xs",
};

const textSizeMap = {
  medium: "text-body-md",
  small: "text-body-sm",
  xs: "text-body-xs",
};

/**
 * Display an interviewer details, optionally with calendar color avatar ring
 * Will also display load, conflict, and trainee data if passed
 */
export function SelectedInterviewerDisplay(
  props: SelectedInterviewerDisplayProps
) {
  const {
    interviewer,
    responseStatus,
    calendarColor,
    isShadower,
    size = "medium",
    hideTimezone,
    hideAvailabilityIcon,
    hideShadowingLabel,
    RightActions,
    showDayCount = true,
    hideTrainingData,
    Subtext,
  } = props;
  const status = responseStatus
    ? getAvatarStatusFromInterviewerResponseStatus(responseStatus)
    : undefined;
  const avatarSize = avatarSizeMap[size];
  const textSizeClass = textSizeMap[size];
  const colors = calendarColor
    ? getColorsFromConfig({
        ...calendarColor,
        variant: "active",
      })
    : undefined;
  const formattedUserTimezone = interviewer.user.timezone
    ? DateTime.now().setZone(interviewer.user.timezone).toFormat("ZZZZ")
    : null;
  const showShadowingLabel = isShadower && !hideShadowingLabel;

  return (
    <div className="flex w-full gap-2 items-start justify-start">
      {!hideAvailabilityIcon && (
        <div className="flex items-center justify-center pt-1">
          <UserMembershipConflictIcon
            userMembership={interviewer}
            hasBeenSelected
          />
        </div>
      )}
      <div
        className={clsx("flex justify-center items-center rounded-full p-0.5")}
        style={{
          margin: "-2px",
          border: colors
            ? `2px solid ${colors.background}`
            : // Create a white border if no colors for consistent sizing/spacing
              `2px solid white`,
        }}
      >
        <Avatar
          size={avatarSize}
          image={interviewer.imageUrl}
          name={interviewer.name}
          status={status}
        />
      </div>
      <div
        className={clsx("flex flex-col grow overflow-hidden gap-1", {
          // With shadowing label
          ...(showShadowingLabel && {
            "pt-[.0625rem]": size === "xs",
            "pt-[.125rem]": size === "small",
            "pt-[.1875rem]": size === "medium",
          }),
          // Without shadowing label
          ...(!showShadowingLabel && {
            "pt-1": size === "xs",
            "pt-[.3125rem]": size === "small",
            "pt-2": size === "medium",
          }),
        })}
      >
        <div className="flex gap-3">
          <div className="flex flex-col grow overflow-hidden">
            <p className={clsx("truncate", textSizeClass)}>
              <span className="shrink truncate" title={interviewer.name}>
                {interviewer.name}
              </span>
              {!hideTimezone && formattedUserTimezone && (
                <span className="text-subtle ml-1 whitespace-nowrap">
                  ({formattedUserTimezone})
                </span>
              )}
            </p>
            {showShadowingLabel && (
              <p className={clsx(textSizeClass, "text-subtle")}>Shadowing</p>
            )}
            {Subtext}
          </div>
          {RightActions && (
            <div className="flex items-center gap-2">{RightActions}</div>
          )}
        </div>
        <div className={clsx("flex space-x-2")}>
          <InterviewLoadFooter
            userMembership={interviewer}
            isQualified={!isShadower}
            showDayCount={showDayCount}
            size={["xs", "small"].includes(size) ? "small" : "medium"}
            variant="header"
          />
        </div>
        {interviewer.trainingData && !hideTrainingData && (
          <TrainingProgress data={interviewer.trainingData} />
        )}
      </div>
    </div>
  );
}
