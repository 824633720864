import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { View } from "@resource/atlas/view/View";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { useCalendarTimezone } from "client/components/calendar/settings";
import { BackButton } from "client/components/generic/layout/BackButton";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { useState } from "react";
import { Event } from "shared/guide-availability/types";

import { AddNotes, AddNotesProps } from "./AddNotes";
import { AvailabilitySubmission } from "./AvailabilitySubmission";
import {
  CreateOrUpdateAvailabilityState,
  CreateOrUpdateAvailabilityView,
} from "./CreateOrUpdateAvailabilityView";
import { RequestNotesProps } from "./RequestNotes";
import { SchedulingPreferencesSelect } from "./SchedulingPreferencesSelect";

type CreateOrUpdateAvailabilityViewDesktopProps = {
  state: CreateOrUpdateAvailabilityState;
  suggestions?: Event[];
  addNotes?: Omit<AddNotesProps, "notes" | "setNotes">;
  requestNotes?: RequestNotesProps;
  close: () => void;
  onSubmit: () => Promise<void>;
  submitLoading?: boolean;
  title: string;
  submitLabel?: string;
};

type View = "calendar" | "review";

export function CreateOrUpdateAvailabilityViewDesktop({
  state,
  title,
  requestNotes,
  addNotes,
  suggestions,
  close,
  onSubmit,
  submitLoading,
  submitLabel,
}: CreateOrUpdateAvailabilityViewDesktopProps) {
  const [view, setView] = useState<View>("calendar");
  const { submitButtonValidation } = state;

  const timezone = useCalendarTimezone();
  const { isOrgUser } = useGuideAuthContext();
  const {
    [FeatureFlags.REQUEST_SCHEDULING_PREFERENCES]:
      requestSchedulingPreferencesEnabled,
  } = useFlags();

  if (view === "review") {
    return (
      <View
        content={{
          className: "!p-0",
        }}
        header={{
          leftActions: <BackButton onClick={() => setView("calendar")} />,
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button onClick={close}>Cancel</Button>
              <Button
                variant="primary"
                onClick={onSubmit}
                isLoading={submitLoading}
              >
                {submitLabel || "Submit"}
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        <div className="w-[37rem] py-6 m-auto space-y-4 text-body-md">
          <div className="space-y-2">
            <div className="text-h3">Review</div>
          </div>
          {requestSchedulingPreferencesEnabled && (
            <div className="space-y-2">
              <p className="text-body-md-heavy text-dark">
                Scheduling preferences
              </p>
              <SchedulingPreferencesSelect
                value={state.schedulingPreference}
                onChange={state.setSchedulingPreference}
              />
            </div>
          )}
          <div className="space-y-2">
            {addNotes && (
              <AddNotes
                {...addNotes}
                notes={state.notes}
                setNotes={state.setNotes}
                inputClassName="h-[12rem]"
              />
            )}
          </div>
          <div className="space-y-2">
            <div className="text-body-md-heavy">Availability</div>
            <AvailabilitySubmission
              availabilitySubmission={{
                notes: null,
                events: state.selections,
              }}
              isCurrentSubmission
              onUpdate={() => {
                setView("calendar");
              }}
              timezone={timezone}
              variant="candidate"
            />
          </div>
        </div>
      </View>
    );
  }

  return (
    <CreateOrUpdateAvailabilityView
      leftSidePanel={{
        state,
        title,
        subText: isOrgUser
          ? "Click and drag on the calendar to add the candidate's availability."
          : "Click and drag on the calendar to add availability.",
        suggestions,
        requestNotes,
        addNotes: isOrgUser ? addNotes : undefined,
        selectionsTitle: isOrgUser
          ? "Candidate's availability"
          : "Your availability",
        showSchedulingPreferences: false,
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button onClick={close}>Cancel</Button>
            {isOrgUser ? (
              <OptionalTooltip
                isInstant
                content={submitButtonValidation.validationMessage}
              >
                <Button
                  variant="primary"
                  onClick={onSubmit}
                  isLoading={submitLoading}
                  disabled={!submitButtonValidation.isValid}
                >
                  {submitLabel || "Submit"}
                </Button>
              </OptionalTooltip>
            ) : (
              <OptionalTooltip
                isInstant
                content={submitButtonValidation.validationMessage}
              >
                <Button
                  variant="primary"
                  onClick={() => setView("review")}
                  disabled={!submitButtonValidation.isValid}
                >
                  Continue
                </Button>
              </OptionalTooltip>
            )}
          </ButtonGroup>
        ),
      }}
      state={state}
      defaultEventTitle="Available"
      suggestions={suggestions}
    />
  );
}
