import { $isAtNodeEnd } from "@lexical/selection";
import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  ElementNode,
  LexicalEditor,
  TextNode,
} from "lexical";

export function $getSelectedNode() {
  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const { anchor } = selection;
    const { focus } = selection;
    const anchorNode = anchor.getNode();
    const focusNode = focus.getNode();

    if (anchorNode === focusNode) return anchorNode;

    if (selection.isBackward())
      return $isAtNodeEnd(focus) ? anchorNode : focusNode;

    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }

  return undefined;
}

type Node = TextNode | ElementNode;
export function $getSelectionTopNode(
  editor: LexicalEditor
): [Node | undefined, { anchorNode?: Node }] {
  const selection = $getSelection();
  if ($isRangeSelection(selection)) {
    const anchorNode = selection.anchor.getNode();
    const node = $isRootNode(anchorNode)
      ? anchorNode
      : anchorNode.getTopLevelElementOrThrow();
    const key = node.getKey();
    const element = editor.getElementByKey(key);

    if (element !== null) return [node, { anchorNode }];

    return [undefined, { anchorNode }];
  }

  return [undefined, {}];
}
