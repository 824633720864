/* eslint-disable import/prefer-default-export */
import {
  atlasTextFormatBold,
  atlasTextFormatItalic,
  atlasTextFormatUnderline,
} from "../../../../../icons/atlas";
import { Button } from "../../../../button/Button";
import Tooltip from "../../../../tooltip/Tooltip";
import { useFormat, useSelection } from "../../selection-context";

// TODO: icon constants

export function useFormattingButtons() {
  const { isRangeSelection } = useSelection();
  const { format, toggleBold, toggleItalic, toggleUnderline } = useFormat();

  return (
    <>
      <Tooltip isInstant content="Bold">
        <Button
          aria-label="toggle bold formatting"
          disabled={!isRangeSelection}
          icon={atlasTextFormatBold}
          isActive={format.bold}
          onClick={toggleBold}
        />
      </Tooltip>
      <Tooltip isInstant content="Italic">
        <Button
          aria-label="toggle italic formatting"
          disabled={!isRangeSelection}
          icon={atlasTextFormatItalic}
          isActive={format.italic}
          onClick={toggleItalic}
        />
      </Tooltip>
      <Tooltip isInstant content="Underline">
        <Button
          aria-label="toggle underline formatting"
          disabled={!isRangeSelection}
          icon={atlasTextFormatUnderline}
          isActive={format.underline}
          onClick={toggleUnderline}
        />
      </Tooltip>
    </>
  );
}
