import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { imageModule } from "@resource/atlas/content-editor/image";

import { useReplaceImageData } from "./utils";

export function useImageModule() {
  const { onReplaceImage, fileChooserInput } = useReplaceImageData();

  return withConfig(imageModule, {
    onReplaceImage,
    tmpRender: fileChooserInput,
  });
}
