import juice from "juice";
import posthtml from "posthtml";
import safeClassNames from "posthtml-safe-class-names";
import { ReactElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { TxMainHtml } from "../styling/main.html";
import { txEmailStylesheet } from "./generated/tx-email-stylesheet";

export const renderTxEmail = async (body: ReactElement): Promise<string> => {
  // Work around to inject raw HTML without a wrapper in order to insert html comments required for Outlook
  const renderedHtmlString = TxMainHtml.replace(
    "{% BODY_BLOCK %}",
    renderToStaticMarkup(body)
  )
    .replace("{% CSS_BLOCK %}", txEmailStylesheet)
    .replace(/<div id="gc-html-comment"><\/div>/g, "");

  const htmlWithCssInlined = juice(renderedHtmlString, {
    removeStyleTags: false,
    preserveImportant: true,
  });

  return (await posthtml([safeClassNames()]).process(htmlWithCssInlined)).html;
};
