import { CalendarIcon } from "client/components/guide/interviews/CalendarIcon";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { useFormattedDateTimesForInterviewItem } from "client/components/guide/interviews/utils";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { AIScenarioInterviewCardFragment } from "generated/graphql-codegen/graphql";
import React from "react";
import { getCurrentIanaTimezone } from "shared/utils/timezones";

import { AIScenarioInterviewerDisplay } from "./AIScenarioInterviewerDisplay";

gql(`
fragment AIScenarioInterviewCard on ScheduledInterview {
  id
  title
  startTime
  endTime
  interviewers {
    id
    ...AIScenarioCardInterviewerDisplay
  }
}
`);

export function AIScenarioInterviewCard({
  interview,
  conflicts,
  compact,
}: {
  interview: AIScenarioInterviewCardFragment;
  conflicts: Record<string, string[]>;
  compact?: boolean;
}) {
  const formattedDateTime = useFormattedDateTimesForInterviewItem({
    interview,
    hideStartTimeNotification: true,
    timezone: getCurrentIanaTimezone(),
  });
  const formattedDateTimeStr = `${formattedDateTime.formattedStartDate} • ${formattedDateTime.formattedTime}`;

  return (
    <div className="flex rounded-md items-center w-full bg-white overflow-hidden p-4 border-gray-border border">
      <div
        className={clsx("flex w-full justify-between gap-6", {
          "flex-col": compact,
        })}
      >
        <div className="flex gap-2">
          <CalendarIcon
            className="shrink-0"
            day={formattedDateTime.day}
            dayName={formattedDateTime.dayName}
            size="md"
          />

          <div className="flex flex-col grow shrink min-w-0 justify-center gap-2">
            <div className="flex flex-col">
              <div className="flex items-center flex-grow flex-shrink min-w-0 gap-1">
                <InterviewTitle
                  className={clsx("text-body-md-heavy truncate")}
                  interview={interview}
                />
              </div>
              <div className="flex gap-1 items-center">
                <p
                  className="text-subtle text-body-sm truncate"
                  title={formattedDateTimeStr}
                >
                  {formattedDateTimeStr}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="shrink-0 w-64">
          {interview.interviewers.map((interviewer) => (
            <AIScenarioInterviewerDisplay
              key={interviewer.id}
              interviewer={interviewer}
              conflicts={conflicts[interviewer.id] ?? []}
              compact={compact}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
