import { Icon } from "@resource/atlas/icon/Icon";
import { atlasUsers, atlasVideoCamera } from "@resource/atlas/icons";
import { OptionItem } from "@resource/atlas/option/OptionItem";

import { ConferenceRoom } from "../utils/types";
import { ConferenceRoomConflictIcon } from "./ConferenceRoomConflictIcon";

export function ConferenceRoomOption({
  conferenceRoom,
  onClick,
}: {
  conferenceRoom: ConferenceRoom;
  onClick: () => void;
}) {
  return (
    <OptionItem
      key={conferenceRoom.id}
      isSelectable
      onClick={onClick}
      size="compact"
      leadingContent={
        conferenceRoom.availability ? (
          <ConferenceRoomConflictIcon
            availability={conferenceRoom.availability}
          />
        ) : null
      }
    >
      <div className="flex gap-2 text-body-md items-center">
        <div className="truncate">{conferenceRoom.name}</div>
        <div className="shrink-0">floor {conferenceRoom.floorName}</div>
      </div>
      <div className="flex gap-2 text-body-md text-subtle">
        {conferenceRoom.capacity ? (
          <div className="flex gap-1 items-center">
            <Icon content={atlasUsers} className="w-4 h-4" />{" "}
            {conferenceRoom.capacity}
          </div>
        ) : null}
        {conferenceRoom.hasZoom && (
          <div className="flex gap-1 items-center">
            <Icon content={atlasVideoCamera} className="w-4 h-4" /> Zoom
          </div>
        )}
      </div>
    </OptionItem>
  );
}
