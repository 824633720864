import { AvatarGroup } from "@resource/atlas/avatar/AvatarGroup";
import { useAvatarGroupItems } from "@resource/atlas/avatar/use-avatar-group-items";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBookClosed } from "@resource/atlas/icons";
import { listInterviewerNamesAsOr } from "client/components/interviewer-slots/utils/misc";
import { SelectedTagFilters } from "client/components/SelectedTagFilters/SelectedTagFilters";
import clsx from "clsx";
import {
  InterviewerRequirementForAvatarStackFragment,
  InterviewerRequirementType,
  TagFilterForRequirementsConfigFragment,
} from "generated/graphql-codegen/graphql";
import _ from "lodash";
import React, { useMemo } from "react";

import { ColorizedPoolIcon } from "./ColorizedPoolIcon";
import { ColorizedSmartMatchIcon } from "./ColorizedSmartMatchIcon";

type InterviewerRequirementsListProps = {
  interviewerRequirements: InterviewerRequirementForAvatarStackFragment[];
  className?: string;
};

export function InterviewerRequirementsList({
  interviewerRequirements,
  className,
}: InterviewerRequirementsListProps) {
  const translated = useMemo((): TranslatedRequirementType[] => {
    return _(interviewerRequirements)
      .flatMap((r) => {
        if (r.type === InterviewerRequirementType.SMART_MATCH) {
          const requirement: TranslatedRequirementType = {
            type: r.type,
            tagName: "Smart Match",
            tagFilters: r.tagFilters,
            isShadower: false,
          };

          return r.includeShadower
            ? [
                requirement,
                {
                  ...requirement,
                  userMemberships: r.shadowingUserMemberships,
                  isShadower: true,
                },
              ]
            : [requirement];
        }
        if (r.type === InterviewerRequirementType.TAG) {
          const pool = r.interviewerPools?.[0];

          const requirement: TranslatedRequirementType = {
            type: r.type,
            tagName: pool?.name ?? "Unknown",
            tagFilters: r.tagFilters,
            isShadower: false,
          };

          return r.includeShadower
            ? [
                requirement,
                {
                  ...requirement,
                  isShadower: true,
                },
              ]
            : [requirement];
        }

        const requirement: TranslatedRequirementType = {
          type: r.type,
          userMemberships: r.userMemberships,
          isShadower: false,
        };

        return r.shadowingUserMemberships.length > 0
          ? [
              requirement,
              {
                ...requirement,
                userMemberships: r.shadowingUserMemberships,
                isShadower: true,
              },
            ]
          : [requirement];
      })
      .sortBy((r) => (r.isShadower ? 1 : 0))
      .value();
  }, [interviewerRequirements]);

  return (
    <div className={clsx("space-y-2", className)}>
      {translated.map((requirement, index) => (
        <div key={index} className="interviewer-requirement">
          <Requirement interviewRequirement={requirement} itemIdx={index} />
        </div>
      ))}
    </div>
  );
}

function ItemBadge({ itemIdx }: { itemIdx: number }) {
  return (
    <div className="w-5 h-5 p-0.5">
      <div className="flex items-center justify-center w-full h-full rounded-sm bg-light-gray-500">
        <span className="text-subtle text-body-md-heavy font-semibold text-[10px]">
          {itemIdx + 1}
        </span>
      </div>
    </div>
  );
}

type TranslatedRequirementType = {
  type: InterviewerRequirementType;
  tagName?: string;
  userMemberships?: InterviewerRequirementForAvatarStackFragment["userMemberships"];
  tagFilters?: TagFilterForRequirementsConfigFragment[];
  isShadower: boolean;
};

function Requirement({
  interviewRequirement,
  itemIdx,
}: {
  itemIdx: number;
  interviewRequirement: TranslatedRequirementType;
}) {
  const RequirementComponent = useMemo(() => {
    if (interviewRequirement.type === InterviewerRequirementType.TAG) {
      return (
        <InterviewerPoolRequirement
          pool={{ name: interviewRequirement.tagName ?? "" }}
          isShadower={interviewRequirement.isShadower}
          tagFilters={interviewRequirement.tagFilters}
        />
      );
    }

    if (interviewRequirement.type === InterviewerRequirementType.SMART_MATCH) {
      return (
        <SmartMatchRequirement
          tagFilters={interviewRequirement.tagFilters ?? []}
          alternates={interviewRequirement.userMemberships ?? []}
          isShadower={interviewRequirement.isShadower}
        />
      );
    }

    return (
      <PersonRequirement
        alternates={interviewRequirement.userMemberships ?? []}
        isShadower={interviewRequirement.isShadower}
      />
    );
  }, [interviewRequirement]);

  return (
    <div className="flex gap-3 items-center">
      {interviewRequirement.isShadower ? (
        <Icon className="text-subtle" content={atlasBookClosed} />
      ) : (
        <ItemBadge itemIdx={itemIdx} />
      )}
      {RequirementComponent}
    </div>
  );
}

type InterviewerPoolRequirementProps = {
  pool: { name: string };
  isShadower: boolean;
  tagFilters?: TagFilterForRequirementsConfigFragment[];
};

function InterviewerPoolRequirement({
  pool,
  isShadower,
  tagFilters,
}: InterviewerPoolRequirementProps) {
  return (
    <div className="flex gap-2 items-center">
      <ColorizedPoolIcon />
      <p className="text-body-md">
        {pool.name}
        {isShadower && (
          <>
            {" "}
            <span className="text-subtle">(trainee)</span>
          </>
        )}
      </p>
      {tagFilters && tagFilters.length > 0 && !isShadower && (
        <SelectedTagFilters tagFilters={tagFilters} />
      )}
    </div>
  );
}

type PersonRequirementProps = {
  alternates: InterviewerRequirementForAvatarStackFragment["userMemberships"];
  isShadower: boolean;
};

function PersonRequirement({ alternates, isShadower }: PersonRequirementProps) {
  const items = useAvatarGroupItems(
    (i) => {
      return alternates.map((alternate) =>
        i.avatar({
          key: alternate.id,
          name: alternate.name,
          image: alternate.imageUrl,
        })
      );
    },
    [alternates]
  );

  return (
    <div className="flex gap-2 items-center overflow-hidden text-body-md">
      <AvatarGroup max={3} size="xs" items={items} className="shrink-0" />
      <p className="truncate" title={listInterviewerNamesAsOr(alternates)}>
        {listInterviewerNamesAsOr(alternates)}
      </p>
      {isShadower && (
        <>
          {" "}
          <p className="text-subtle shrink-0">(trainee)</p>
        </>
      )}
    </div>
  );
}

type SmartMatchRequirementProps = {
  tagFilters: TagFilterForRequirementsConfigFragment[];
  alternates: InterviewerRequirementForAvatarStackFragment["userMemberships"];
  isShadower: boolean;
};

function SmartMatchRequirement({
  tagFilters,
  isShadower,
  alternates,
}: SmartMatchRequirementProps) {
  const items = useAvatarGroupItems(
    (i) => {
      return alternates.map((alternate) =>
        i.avatar({
          key: alternate.id,
          name: alternate.name,
          image: alternate.imageUrl,
        })
      );
    },
    [alternates]
  );

  if (isShadower) {
    return (
      <div className="flex gap-2 items-center overflow-hidden text-body-md">
        <AvatarGroup max={3} size="xs" items={items} className="shrink-0" />
        <p className="truncate" title={listInterviewerNamesAsOr(alternates)}>
          {listInterviewerNamesAsOr(alternates)}
        </p>
        {isShadower && (
          <>
            {" "}
            <p className="text-subtle shrink-0">(trainee)</p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="flex gap-2 items-center">
      <ColorizedSmartMatchIcon />
      <p className="text-body-md">
        Smart Match
        {isShadower && (
          <>
            {" "}
            <span className="text-subtle">(trainee)</span>
          </>
        )}
      </p>
      {tagFilters.length > 0 && !isShadower && (
        <SelectedTagFilters tagFilters={tagFilters} />
      )}
    </div>
  );
}
