import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasChevronDown,
  atlasChevronUp,
  atlasFilterPlus,
  atlasPersonSparkle,
  atlasTrash,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { TagFilterWithQuery } from "client/components/tag-filters/TagFilterWithQuery";
import { TagFilterType } from "generated/graphql-codegen/graphql";
import { useState } from "react";
import { formatEntity } from "shared/constants/entities";
import { v4 } from "uuid";

import { EditInterviewerSlotDisplayState } from "../hooks/useEditInterviewerSlotDisplayState";
import { EditPersonConfigSelectedInterviewerDisplay } from "./EditPersonConfigSelectedInterviewerDisplay";
import { EnableInterviewerTrainingToggle } from "./EnableInterviewerTrainingToggle";

export type EditSmartMatchConfigDisplayProps = {
  state: EditInterviewerSlotDisplayState;
};

/** Display an editable smart match config slot */
export function EditSmartMatchConfigDisplay({
  state,
}: EditSmartMatchConfigDisplayProps) {
  const {
    interviewerSlot,
    disableInterviewerTraining,
    onChangeTrainingEnabled,
    onTagFiltersChange,
    onRemoveInterviewerSlot,
  } = state;
  const { trainingEnabled, tagFilters } = interviewerSlot;

  const [showTagFilters, setShowTagFilters] = useState(true);

  const hasEmptyFilter = tagFilters?.some((filter) => filter.tags.length === 0);

  return (
    <div className="rounded-md group shadow-1 w-full overflow-hidden">
      <div className="bg-light-gray-200 py-1 px-3 text-subtle w-full space-y-2 border-b border-gray-border">
        <div className="flex items-center gap-2 w-full">
          <Icon content={atlasPersonSparkle} className="w-4 h-4" />
          <p className="text-body-xs grow">Smart Match</p>
          <ButtonGroup size="xs" negativeMargin="right">
            <Button
              isGhost
              className="!text-subtle"
              onClick={() =>
                onTagFiltersChange([
                  ...(tagFilters ?? []),
                  { id: v4(), type: TagFilterType.INCLUDE_ALL, tags: [] },
                ])
              }
              icon={atlasFilterPlus}
              disabled={hasEmptyFilter}
            />
            <Tooltip content={`Remove ${formatEntity("interviewer slot")}`}>
              <Button
                isGhost
                className="!text-subtle"
                onClick={onRemoveInterviewerSlot}
                icon={atlasTrash}
              />
            </Tooltip>
            {!!tagFilters?.length && (
              <Button
                isGhost
                className="!text-subtle"
                onClick={() => setShowTagFilters(!showTagFilters)}
                icon={showTagFilters ? atlasChevronUp : atlasChevronDown}
              />
            )}
          </ButtonGroup>
        </div>
        {showTagFilters && !!tagFilters?.length && (
          <div className="space-y-1">
            {tagFilters?.map((filter) => (
              <TagFilterWithQuery
                key={filter.id}
                selectedTags={filter.tags ?? []}
                onRemoveTag={(tag) => {
                  onTagFiltersChange(
                    tagFilters.map((f) => {
                      if (f.id === filter.id) {
                        return {
                          ...f,
                          tags: f.tags.filter((t) => t.id !== tag.id),
                        };
                      }
                      return f;
                    })
                  );
                }}
                onClearAll={() => {
                  onTagFiltersChange(
                    tagFilters.filter((f) => f.id !== filter.id)
                  );
                }}
                onTagSelect={(tag) => {
                  onTagFiltersChange(
                    tagFilters.map((f) => {
                      if (f.id !== filter.id) return f;
                      const isTagSelected = f.tags.some((t) => t.id === tag.id);
                      return {
                        ...f,
                        tags: isTagSelected
                          ? f.tags.filter((t) => t.id !== tag.id)
                          : [...f.tags, tag],
                      };
                    })
                  );
                }}
                onFilterChange={(type) => {
                  onTagFiltersChange(
                    tagFilters.map((f) =>
                      f.id === filter.id ? { ...f, type } : f
                    )
                  );
                }}
                filterType={filter.type}
              />
            ))}
          </div>
        )}
      </div>

      <EditPersonConfigSelectedInterviewerDisplay state={state} />
      {!disableInterviewerTraining && (
        <EnableInterviewerTrainingToggle
          enableTraining={trainingEnabled}
          setTrainingEnabled={onChangeTrainingEnabled}
        />
      )}
      {trainingEnabled && (
        <EditPersonConfigSelectedInterviewerDisplay
          paddingTopClassName="pt-1"
          state={state}
          isShadower
        />
      )}
    </div>
  );
}
