import { useAuthContext } from "auth/context";
import { gql } from "generated/graphql-codegen";
import { useMemo } from "react";
import useQuery from "utils/useQuery";

gql(`
fragment OrganizationForZoomHostSelect on Organization {
  id
  defaultZoomHosts {
    ...InterviewerForSelection
  }
}
`);

const GET_ORG_ZOOM_USERS = gql(`
  query GetOrgZoomUsers($organizationId: String!) {
    organizationById(organizationId: $organizationId) {
      ...OrganizationForZoomHostSelect
    }
  }
`);

export function useOrgZoomHosts() {
  const { user } = useAuthContext();
  const organizationId = user?.currentOrganization?.id;
  const { data: orgData } = useQuery(GET_ORG_ZOOM_USERS, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      organizationId: organizationId!,
    },
    skip: !organizationId,
  });

  const users = useMemo(() => {
    if (!orgData?.organizationById?.defaultZoomHosts) {
      return [];
    }

    return orgData.organizationById.defaultZoomHosts;
  }, [orgData?.organizationById?.defaultZoomHosts]);

  return useMemo(
    () => ({
      users,
    }),
    [users]
  );
}
