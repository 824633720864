import { useCallback, useMemo, useState } from "react";

export type SchedulingTaskWorkflowViews =
  | "request-availability"
  | "scheduling-request"
  | "reschedule-reporting-reasons"
  | "edit-template";

export type ViewCallbackProps = {
  onContinue: () => void;
  onBack: () => void;
  setView: (view: SchedulingTaskWorkflowViews) => void;
  currentView: SchedulingTaskWorkflowViews;
  orderedViews: SchedulingTaskWorkflowViews[];
  isFirstView: boolean;
  isLastView: boolean;
};

export type SchedulingTaskWorkflowViewWrapperProps = {
  orderedViews: SchedulingTaskWorkflowViews[];
  views: {
    [key in SchedulingTaskWorkflowViews]?: (
      props: ViewCallbackProps
    ) => React.ReactNode;
  };
};

export function SchedulingTaskWorkflowViewWrapper({
  views,
  orderedViews,
}: SchedulingTaskWorkflowViewWrapperProps) {
  const [currentView, setCurrentView] = useState<SchedulingTaskWorkflowViews>(
    orderedViews[0] as SchedulingTaskWorkflowViews
  );
  const onContinue = useCallback(() => {
    const currentViewIndex = orderedViews.indexOf(currentView);
    setCurrentView(
      orderedViews[currentViewIndex + 1] as SchedulingTaskWorkflowViews
    );
  }, [currentView, orderedViews]);
  const onBack = useCallback(() => {
    const currentViewIndex = orderedViews.indexOf(currentView);
    setCurrentView(
      orderedViews[currentViewIndex - 1] as SchedulingTaskWorkflowViews
    );
  }, [currentView, orderedViews]);

  const viewProps = useMemo<ViewCallbackProps>(
    () => ({
      onContinue,
      onBack,
      setView: setCurrentView,
      currentView,
      orderedViews,
      isFirstView: currentView === orderedViews[0],
      isLastView: currentView === orderedViews[orderedViews.length - 1],
    }),
    [onContinue, onBack, currentView, orderedViews]
  );

  return <>{views[currentView]?.(viewProps)}</>;
}
