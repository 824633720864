/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import type { ContentEditorModuleConfig } from "@resource/atlas";

import { useStaticValue } from "../../../../__utils/react";
import { processModules } from "./process-modules";
import type {
  AtlasContentEditorModule,
  AtlasContentEditorModuleWithConfig,
} from "./types";

// use modules
// -----------

/**
 * Resolves all modules and merges their components into flat lists. Configuration is
 * also extracted from tuple-style entries and merged into a single object.
 */
export function useModules(
  modulesMaybeWithConfig: Array<
    AtlasContentEditorModule | AtlasContentEditorModuleWithConfig
  >
) {
  const moduleConfig: ContentEditorModuleConfig = {};

  const modules = modulesMaybeWithConfig.map((currentModule) => {
    if (Array.isArray(currentModule)) {
      const [mod, config] = currentModule;
      // extract config
      moduleConfig[mod.id] = config as any;
      return mod;
    }
    return currentModule;
  });

  const moduleComponents = useStaticValue(() => processModules(modules));

  return [moduleComponents, moduleConfig] as const;
}
