import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { RichBlockProps } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasRefreshCw, atlasTrash } from "@resource/atlas/icons";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useToast } from "@resource/atlas/toast/use-toast";
import { useLogEvent } from "analytics";
import { InterviewList } from "client/components/guide/interviews/InterviewList";
import {
  ScheduledInterviewCard,
  ScheduledInterviewForCard,
} from "client/components/scheduled-interviews/ScheduledInterviewCard";
import { useGuideIdContext } from "client/utils/guide-id-provider";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  AsyncProcessingStatus,
  GoogleEventStatus,
  InterviewerResponse,
  InterviewsRichBlockInterviewFragment as Interview,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import { $getNodeByKey } from "lexical";
import _, { map, reject } from "lodash";
import { DateTime } from "luxon";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { usePrevious } from "react-use";
import { InterviewsData } from "shared/guide-content/rich-blocks/interviews";
import useQuery from "utils/useQuery";

import { useAttachedFiles } from "./attached-files-context";
import { useInterviewsConfig } from "./config";

function NoCandidateEmailMessage({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        "bg-light-gray-200 h-full rounded-md flex p-6 mb-2 text-dark justify-center items-center",
        className
      )}
    >
      <div className="flex flex-col items-center text-center">
        <p className="text-body-md text-subtle mt-2">
          A candidate email is required to attach the interview schedule.
        </p>
      </div>
    </div>
  );
}

gql(`
fragment InterviewsRichBlockInterview on ScheduledInterview {
    ...InterviewListInterview
    isCancelled
    icsFile(candidateEmail: $candidateEmail) @skip(if: $skipICSFiles) {
      ...AttachedFilesForRichBlocks
    }
  }
`);

const GUIDE_UPCOMING_INTERVIEWS_FOR_RICH_BLOCK = gql(`
  query GuideUpcomingInterviewsForRichBlock(
    $guideId: String!
    $candidateEmail: String!
    $skipICSFiles: Boolean!
  ) {
    guideById(guideId: $guideId) {
      id
      upcomingInterviews(showHidden: true) {
        ...InterviewsRichBlockInterview
      }
    }
  }
`);

const GUIDE_INTERVIEWS_FROM_RICH_BLOCK_CONTEXT = gql(`
query GuideInterviewsFromRichBlockContext(
    $guideId: String!
    $interviewIds: [String!]!
    $candidateEmail: String!
    $skipICSFiles: Boolean!
  ) {
    scheduledInterviewsById(guideId: $guideId, interviewIds: $interviewIds) {
      ...InterviewsRichBlockInterview
    }
  }
`);

type UseCardItemsReturnType = {
  menuItems: ReturnType<typeof useMenuItems>;
  Dialog: ReactElement;
};

function useInterviewCardMenuItemsFactory(
  stateData: InterviewsData,
  updateData: (
    valueOrFactory:
      | Partial<InterviewsData>
      | ((data: InterviewsData) => Partial<InterviewsData>)
  ) => void,
  nodeKey: string
) {
  return function useInterviewCardMenuItems(
    interviewId: string
  ): UseCardItemsReturnType {
    const [editor] = useLexicalComposerContext();
    const logEvent = useLogEvent({
      component: "Interviews rich block",
      project: "Embed an interview schedule in a message",
    });
    return {
      menuItems: useMenuItems(
        (i) => [
          i.item({
            key: "remove",
            children: "Remove",
            leadingContent: <Icon content={atlasTrash} />,
            onClick: () => {
              logEvent("Remove a single interview from the rich block");
              const updatedInterviews = stateData.interviews.filter(
                (interview) => interview.id !== interviewId
              );
              if (updatedInterviews.length === 0) {
                // remove node if only interview is deleted
                editor.update(() => $getNodeByKey(nodeKey)?.remove());
              } else {
                updateData({
                  interviews: updatedInterviews,
                });
              }
            },
          }),
        ],
        [editor, interviewId, logEvent]
      ),
      Dialog: <></>,
    };
  };
}

export const useSyncICSFilesWithInterviews = ({
  interviews,
  nodeKey,
}: {
  interviews?: Interview[];
  nodeKey: string;
}) => {
  const { setAttachedFiles } = useAttachedFiles();
  useEffect(() => {
    const icsFiles = _(interviews).map("icsFile").compact().value();

    setAttachedFiles((prev) =>
      _(prev)
        .reject({ nodeKey })
        .concat(map(icsFiles, (icsFile) => ({ ...icsFile, nodeKey })))
        .sortBy("filename")
        .value()
    );

    // Remove all from this rich block on unmount
    return () => {
      setAttachedFiles((prev) => reject(prev, { nodeKey }));
    };
  }, [interviews, setAttachedFiles, nodeKey]);
};

export function PlaceholderInterviewCard() {
  const startTime = DateTime.now().plus({ weeks: 1 }).startOf("week").set({
    hour: 9,
  });
  const endTime = startTime.plus({
    hour: 1,
  });

  const placeholderInterview = useMemo(
    (): ScheduledInterviewForCard => ({
      id: "178eac8b-e1e2-4ffc-8431-e4f6bc580f4d",
      title: "Onsite interview",
      startTime,
      endTime,
      videoConferencingService: VideoConferencingServiceType.ZOOM,
      videoConferencingUrl: "https://zoom.us/j/123456789",
      isCancelled: false,
      isPrivate: false,
      blacklisted: false,
      collaborativeCodingService: null,
      atssyncScheduledInterview: {
        id: "cd6ac051-f242-464e-a82a-2745223b1092",
        remoteId: "123456789",
        name: "Onsite interview",
        remoteWasDeleted: false,
        __typename: "AtssyncScheduledInterview",
      },
      createdByGuide: true,
      googleEvent: {
        eventId: "123456789",
        htmlLink:
          "https://calendar.google.com/event?action=TEMPLATE&text=Onsite+interview&dates=20220119T150000Z/20220119T160000Z&details=Onsite+interview+with+Engineering&location=Zoom&add=1",
        id: "b179c0e1-343d-48d8-a6b4-2447106b5606",
        isValid: true,
        hasChanges: false,
        status: GoogleEventStatus.confirmed,
        __typename: "GoogleEvent",
      },
      googleSyncStatus: AsyncProcessingStatus.COMPLETED,
      greenhouseSyncStatus: AsyncProcessingStatus.COMPLETED,
      interviewers: [
        {
          id: "b9597b92-1e38-4c2d-b375-e302788e0f8b",
          isShadow: false,
          userMembership: {
            id: "16003fa6-e26d-4c66-9baf-33af60207804",
            name: "Jenny Lee",
            imageUrl:
              "https://ucarecdn.com/53c861b4-2d8a-41a9-959d-be98672e9d15/",
          },
          responseStatus: InterviewerResponse.ACCEPTED,
        },
        {
          id: "1a99d8a4-d6b9-476b-bf4c-0b53d594337f",
          isShadow: false,
          userMembership: {
            id: "f51eb2ab-d9c4-4bb3-a80b-8c322dd2bd14",
            name: "Milo Nguyen",
            imageUrl:
              "https://ucarecdn.com/930a1835-4845-46f6-8d52-784ad699023d/",
          },
          responseStatus: InterviewerResponse.ACCEPTED,
        },
        {
          id: "6cf0f18f-7e32-49b2-a921-769f16fb5146",
          isShadow: false,
          userMembership: {
            id: "08473e67-b1aa-43c2-aee3-d11242e48fca",
            name: "Jonathan Branch",
            imageUrl:
              "https://ucarecdn.com/7f50325b-7ee9-4574-bfff-21234d43d87e/",
          },
          responseStatus: InterviewerResponse.TENTATIVE,
        },
      ],
    }),
    [endTime, startTime]
  );

  return (
    <div className="border border-yellow-800 border-dashed rounded-md relative">
      <div className="absolute top-0 left-0 bg-yellow-50 border border-l-0 border-t-0 border-yellow-800 border-dashed rounded-[4px] px-1 flex items-center h-[18px]">
        <span className="text-yellow-900 font-bold font-mono text-[10px]">
          Example
        </span>
      </div>
      <ScheduledInterviewCard
        scheduledInterview={placeholderInterview}
        hideInternalStatuses
      />
    </div>
  );
}

function MessageInterviewList({
  updateData,
  data: stateData,
  ConfigMenu,
  nodeKey,
  guideId,
}: RichBlockProps<InterviewsData> & { guideId: string }) {
  const { sendToast } = useToast();
  const { candidateEmail, enableICSFiles } = useAttachedFiles();
  const { lastFetchedAt } = stateData;

  const logEvent = useLogEvent({
    component: "Interviews rich block",
    project: "Embed an interview schedule in a message",
  });

  useEffect(() => {
    logEvent("Create an interviews rich block");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: fromState } = useQuery(
    GUIDE_INTERVIEWS_FROM_RICH_BLOCK_CONTEXT,
    {
      variables: {
        guideId,
        interviewIds: stateData.interviews.map((interview) => interview.id),
        candidateEmail: candidateEmail ?? "",
        skipICSFiles: !enableICSFiles || !candidateEmail,
      },
      skip: !lastFetchedAt,
    }
  );

  const skip =
    (stateData.variant === "upcoming" && !!lastFetchedAt) ||
    !guideId ||
    !candidateEmail;

  const { refetch, loading } = useQuery(
    GUIDE_UPCOMING_INTERVIEWS_FOR_RICH_BLOCK,
    {
      variables: {
        guideId,
        candidateEmail: candidateEmail ?? "",
        skipICSFiles: !enableICSFiles || !candidateEmail,
      },
      onCompleted: (data) => {
        if (data.guideById) {
          // We temporarily filter out cancelled as it creates some other issues;
          // https://www.loom.com/share/f259c39347d840cd8b07fa0b747579ad
          const updatedInterviews =
            (data.guideById.upcomingInterviews ?? [])?.filter(
              (i) => !i.isCancelled
            ) ?? [];
          updateData({
            lastFetchedAt: new Date().toISOString(),
            interviews: updatedInterviews,
          });
        }
      },
      fetchPolicy: "network-only",
      skip,
      pollInterval: 120000,
    }
  );

  const refreshInterviews = useCallback(async () => {
    await refetch();

    sendToast("Upcoming interviews have been refreshed.", {
      variant: "success",
    });
  }, [sendToast, refetch]);

  const configMenuItems = useMenuItems(
    (i) => [
      i.item({
        key: "refresh",
        onClick: refreshInterviews,
        renderContent: ({ children }) => (
          <div className="flex gap-2 items-center">
            <Icon content={atlasRefreshCw} />
            <span>{children}</span>
          </div>
        ),
        children: "Refresh",
      }),
    ],
    [refreshInterviews]
  );

  const interviews = fromState?.scheduledInterviewsById;

  useSyncICSFilesWithInterviews({ interviews, nodeKey });

  const useInterviewCardMenuItems = useInterviewCardMenuItemsFactory(
    stateData,
    updateData,
    nodeKey
  );

  if (!candidateEmail) {
    return <NoCandidateEmailMessage />;
  }

  return (
    <>
      <ConfigMenu items={configMenuItems} />
      <div className="confirm-interviews-block">
        <InterviewList
          interviews={loading ? undefined : interviews}
          type="upcoming"
          menuItemsConfigFactory={useInterviewCardMenuItems}
          hideInternalStatuses
          timezone={stateData.requesterTimezone}
        />
      </div>
    </>
  );
}

export function InterviewsComponent(props: RichBlockProps<InterviewsData>) {
  const config = useInterviewsConfig();
  const guideContext = useGuideIdContext();
  const guideId = guideContext?.guideId;
  const { updateData } = props;
  const prevTimezone = usePrevious(config?.timezone);

  useEffect(() => {
    if (prevTimezone !== config?.timezone) {
      updateData({
        requesterTimezone: config?.timezone ?? undefined,
      });
    }
  }, [config, prevTimezone, updateData]);

  if (!guideId) return <PlaceholderInterviewCard />;

  return <MessageInterviewList {...props} guideId={guideId} />;
}
