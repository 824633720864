import {
  CandidateConfirmationCancellationInputSchema,
  InterviewerConfirmationCancellationInputSchema,
} from "server/notifications/types";
import { UpsertInterviewPanelRequirementInputSchema } from "shared/interview-requirements/types";
import { z } from "zod";

export const CancelInterviewSettingsSchema = z.object({
  cancelInterviews: z.boolean(),
  interviewerNotificationSettings:
    InterviewerConfirmationCancellationInputSchema,
  candidateNotificationSettings: CandidateConfirmationCancellationInputSchema,
});

export type CancelInterviewSettings = z.infer<
  typeof CancelInterviewSettingsSchema
>;

export const SchedulingRequestPrioritySchema = z.enum(["NORMAL", "HIGH"]);
export type SchedulingRequestPriority = z.infer<
  typeof SchedulingRequestPrioritySchema
>;
export const SchedulingRequestPriority = SchedulingRequestPrioritySchema.enum;

export const SchedulingRequestStatusSchema = z.enum([
  "DRAFT",
  "PENDING",
  "IN_PROGRESS",
  "COMPLETED",
  "CANCELLED",
]);
export type SchedulingRequestStatus = z.infer<
  typeof SchedulingRequestStatusSchema
>;
export const SchedulingRequestStatus = SchedulingRequestStatusSchema.enum;
export const SchedulingRequestAutoAssignOnAvailabilityReceiptTypeSchema =
  z.enum(["NO_REASSIGNMENT", "ASSIGN_TO_QUEUE", "ASSIGN_TO_USER"]);
export type SchedulingRequestAutoAssignOnAvailabilityReceiptType = z.infer<
  typeof SchedulingRequestAutoAssignOnAvailabilityReceiptTypeSchema
>;
export const SchedulingRequestAutoAssignOnAvailabilityReceiptType =
  SchedulingRequestAutoAssignOnAvailabilityReceiptTypeSchema.enum;

export const AutoAssignOnAvailabilityReceiptConfigSchema = z.object({
  autoAssignType: SchedulingRequestAutoAssignOnAvailabilityReceiptTypeSchema,
  assignToUserMembershipId: z.string().optional().nullable(),
});

export type AutoAssignOnAvailabilityReceiptConfig = z.infer<
  typeof AutoAssignOnAvailabilityReceiptConfigSchema
>;

export const CreateSchedulingRequestDataSchema = z.object({
  assigneeId: z.string().optional().nullable(),
  priority: SchedulingRequestPrioritySchema.optional().nullable(),
  autoAssignOnAvailabilityReceiptConfig:
    AutoAssignOnAvailabilityReceiptConfigSchema.optional().nullable(),
  notes: z.string().optional().nullable(),
  interviewPanelRequirement: UpsertInterviewPanelRequirementInputSchema,
});
export type CreateSchedulingRequestData = z.infer<
  typeof CreateSchedulingRequestDataSchema
>;

export const CreateSchedulingRequestInputSchema = z.object({
  userMembershipId: z.string(),
  guideId: z.string(),
  organizationId: z.string(),
  schedulingRequestTemplateId: z.string(),
  atssyncJobInterviewStageId: z.string(),
  data: CreateSchedulingRequestDataSchema,
});
export type CreateSchedulingRequestInput = z.infer<
  typeof CreateSchedulingRequestInputSchema
>;

export const UpdateSchedulingRequestDataSchema = z.object({
  assigneeId: z.string().optional().nullable(),
  autoAssignOnAvailabilityReceiptConfig:
    AutoAssignOnAvailabilityReceiptConfigSchema.optional().nullable(),
  interviewPanelRequirement: UpsertInterviewPanelRequirementInputSchema,
  notes: z.string().optional().nullable(),
  priority: SchedulingRequestPrioritySchema.optional().nullable(),
});
export type UpdateSchedulingRequestData = z.infer<
  typeof UpdateSchedulingRequestDataSchema
>;

export const UpdateSchedulingRequestInputSchema = z.object({
  userMembershipId: z.string(),
  schedulingRequestId: z.string(),
  data: UpdateSchedulingRequestDataSchema,
});
export type UpdateSchedulingRequestInput = z.infer<
  typeof UpdateSchedulingRequestInputSchema
>;
