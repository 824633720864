import { Hovercard } from "@resource/atlas/hovercard";
import { AtlasHovercardState } from "@resource/atlas/hovercard/types";
import { useHovercardState } from "@resource/atlas/hovercard/use-hovercard-state";
import {
  calculateEventGridRow,
  useEventColumnStart,
} from "client/components/calendar-v2/utils/utils";
import clsx from "clsx";
import { WeekdayNumbers } from "luxon";
import { useCallback, useRef } from "react";

import { usePlacingInterview } from "../../place-interview-calendar/hooks/usePlacingInterview";
import {
  UnavailableCalendar,
  UnavailableEvent as UnavailableEventType,
} from "../utils/types";
import { OutOfHoursHovercardContent } from "./OutOfHoursHovercardContent";

const STRIPE_BACKGROUND_CLASS_NAME = "stripe-background";
const STRIPE_BACKGROUND_STYLING = `
  .${STRIPE_BACKGROUND_CLASS_NAME} {
    background: repeating-linear-gradient(
      -45deg,
      #f4f4f480,
      #f4f4f480 4px,
      #e0e0e080 4px,
      #e0e0e080 8px
    );
  }
`;

type UnavailableEventProps = {
  unavailableEvent: UnavailableEventType;
  unavailableCalendars: UnavailableCalendar[];
  candidateIsUnavailable: boolean;
  interviewerIsUnavailable: boolean;
  calendarId?: string;
  calendarGroupIdx?: number;
};

export function UnavailableEvent({
  unavailableEvent,
  unavailableCalendars,
  candidateIsUnavailable,
  interviewerIsUnavailable,
  calendarId,
  calendarGroupIdx,
}: UnavailableEventProps) {
  const { placingInterview } = usePlacingInterview();
  const ref = useRef<HTMLLIElement>(null);
  const hovercardState = useHovercardState({
    getAnchorRect: () => {
      if (!ref.current) {
        return null;
      }
      return ref.current.getBoundingClientRect();
    },
    placement: "left",
  });

  const { startTime, endTime } = unavailableEvent;
  const dayOfWeek = startTime.weekday;
  const startOfDay = startTime.startOf("day");
  const minutesSinceStartOfDay = startTime.diff(startOfDay, "minutes").minutes;
  const durationInMinutes = Math.round(
    endTime.diff(startTime, "minutes").minutes
  );
  const hoverTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const handleMouseEnter = useCallback(() => {
    hoverTimeoutId.current = setTimeout(() => {
      hovercardState.show();
    }, 500);
  }, [hovercardState]);
  const handleMouseLeave = useCallback(() => {
    if (hoverTimeoutId.current) {
      clearTimeout(hoverTimeoutId.current);
    }
    hovercardState.hide();
  }, [hovercardState]);

  const columnStart = useEventColumnStart({
    dayOfWeek,
    calendarId,
    calendarGroupIdx,
  });

  return (
    <>
      <li
        ref={ref}
        className={clsx("relative flex w-full", {
          "bg-light-gray-700 opacity-50": candidateIsUnavailable,
          [STRIPE_BACKGROUND_CLASS_NAME]: interviewerIsUnavailable,
          "cursor-not-allowed": !placingInterview,
        })}
        style={{
          gridRow: calculateEventGridRow({
            minutesSinceStartOfDay,
            durationInMinutes,
          }),
          gridColumnStart: columnStart,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <style jsx>{STRIPE_BACKGROUND_STYLING}</style>
      <OutOfHoursHovercard
        unavailableCalendars={unavailableCalendars}
        dayOfWeek={dayOfWeek}
        hovercardState={hovercardState}
      />
    </>
  );
}

/** Provide hovercard for displaying out of hours data */
function OutOfHoursHovercard({
  unavailableCalendars,
  dayOfWeek,
  hovercardState,
}: {
  unavailableCalendars: UnavailableCalendar[];
  dayOfWeek: WeekdayNumbers;
  hovercardState: AtlasHovercardState;
}) {
  return (
    <Hovercard.Root state={hovercardState}>
      <Hovercard.Content portal>
        <OutOfHoursHovercardContent
          unavailableCalendars={unavailableCalendars}
          dayOfWeek={dayOfWeek}
        />
      </Hovercard.Content>
    </Hovercard.Root>
  );
}
