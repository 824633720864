import { Button } from "@resource/atlas/button/Button";
import { atlasClose } from "@resource/atlas/icons";
import { ColorConfig } from "client/components/calendar/colors";
import { ColorConfig as ColorConfigV2 } from "client/components/calendar-v2/utils/colors";
import { UsersSelect } from "client/components/generic/select/UserSelect";
import { SelectedInterviewerDisplay } from "client/components/interviewer-slots/display/SelectedInterviewerDisplay";
import { InterviewerForSelectionFragment } from "generated/graphql-codegen/graphql";

type Interviewer = InterviewerForSelectionFragment;

export function InterviewerOverlayCalendars({
  interviewers,
  interviewerCalendarColors,
  onAddInterviewer,
  onRemoveInterviewer,
}: {
  interviewers: Interviewer[];
  interviewerCalendarColors: Record<string, ColorConfig>;
  onAddInterviewer: (user: Interviewer) => void;
  onRemoveInterviewer: (userId: string) => void;
}) {
  return (
    <div className="space-y-2 w-full">
      <div className="text-body-md-heavy">View interviewer availability</div>
      <UsersSelect
        onSelect={onAddInterviewer}
        excludeUserIds={interviewers.map((interviewer) => interviewer.id)}
        placeholderText="Add an interviewer..."
      />
      <div className="space-y-2">
        {interviewers.map((interviewer) => {
          const calendarColor = interviewerCalendarColors[interviewer.id];

          return (
            <div
              key={interviewer.id}
              className="flex items-center gap-2 border border-gray-border rounded-md p-3"
            >
              <SelectedInterviewerDisplay
                isShadower={false}
                interviewer={interviewer}
                // Should be fine as V2 includes more than V1
                calendarColor={calendarColor as ColorConfigV2}
              />
              <Button
                icon={atlasClose}
                isGhost
                size="small"
                onClick={() => onRemoveInterviewer(interviewer.id)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
