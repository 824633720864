import { InterviewerRequirementType } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

/** Separate requirements into visible and removed requirements */
export function filterInterviewRequirements<
  T extends { id: string; manuallyRemovedAt: string | null }
>({
  interviewRequirements,
}: {
  interviewRequirements: T[];
}): {
  interviewRequirements: T[];
  removedInterviewRequirements: T[];
} {
  return interviewRequirements.reduce(
    (
      acc: {
        interviewRequirements: T[];
        removedInterviewRequirements: T[];
      },
      requirement
    ) => {
      if (!requirement.manuallyRemovedAt) {
        acc.interviewRequirements.push(requirement);
      } else {
        acc.removedInterviewRequirements.push(requirement);
      }
      return acc;
    },
    { interviewRequirements: [], removedInterviewRequirements: [] }
  );
}

/** Separate requirements into visible and removed requirements */
export function useFilteredInterviewRequirements<
  T extends { id: string; manuallyRemovedAt: string | null }
>({
  interviewRequirements,
}: {
  interviewRequirements: T[];
}): {
  interviewRequirements: T[];
  removedInterviewRequirements: T[];
} {
  return useMemo(() => {
    return filterInterviewRequirements({ interviewRequirements });
  }, [interviewRequirements]);
}

type BaseInterviewerRequirement = {
  id: string;
  type: InterviewerRequirementType;
  userMemberships: { id: string }[];
};

/** Separate interviewer requirements into visible and hidden requirements */
export function filterInterviewerRequirements<
  T extends BaseInterviewerRequirement
>(
  interviewerRequirements: T[]
): {
  interviewerRequirements: T[];
  hiddenInterviewerRequirements: T[];
} {
  return interviewerRequirements.reduce(
    (
      acc: {
        interviewerRequirements: T[];
        hiddenInterviewerRequirements: T[];
      },
      requirement
    ) => {
      if (
        requirement.type === InterviewerRequirementType.PERSON &&
        !requirement.userMemberships.length
      ) {
        acc.hiddenInterviewerRequirements.push(requirement);
      } else {
        acc.interviewerRequirements.push(requirement);
      }
      return acc;
    },
    { interviewerRequirements: [], hiddenInterviewerRequirements: [] }
  );
}

/** Use memoized version of filterInterviewerRequirements */
export function useFilteredInterviewerRequirements<
  T extends BaseInterviewerRequirement
>({
  interviewerRequirements,
}: {
  interviewerRequirements: T[];
}): {
  interviewerRequirements: T[];
  hiddenInterviewerRequirements: T[];
} {
  return useMemo(() => {
    return filterInterviewerRequirements(interviewerRequirements);
  }, [interviewerRequirements]);
}
