import { BackButton } from "client/components/generic/layout/BackButton";
import {
  PostMessageDialogQuery,
  PostMessageDialogQueryProps,
} from "client/message-composer/__components/PostMessageDialogQuery";

import { useSchedulingPromptsNavigatorOrThrow } from "./SchedulingCompletionPromptsNavigator";

/**
 * Wrapper around post message dialog query
 * Provides necessary pieces to function within the scheduling completion prompts dialog
 */
export function SchedulingCompletionPromptsPostMessageView(
  props: Omit<
    PostMessageDialogQueryProps,
    "onCompleted" | "onDismiss" | "viewProps"
  >
) {
  const navigator = useSchedulingPromptsNavigatorOrThrow();

  return (
    <PostMessageDialogQuery
      {...props}
      onDismiss={navigator.dismissNavigator}
      onCompleted={navigator.dismissNavigator}
      viewProps={{
        header: {
          leftActions: <BackButton onClick={navigator.back} />,
          title: "Send candidate confirmation",
        },
      }}
    />
  );
}
