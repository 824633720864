import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import { PostTemplateForUseFragmentSchema } from "components/MessageComposer/PostTemplateLoader";
import { LexicalSchema } from "modules/lexical/shared/utils/types";
import { z } from "zod";

const UserValidationMessages = {
  calendarRequired: "Must choose a calendar to add created interviews to.",
};

export const InterviewReviewSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ScheduledStatus).nullable(),
  interviewerConfirmationTitle: LexicalSchema,
  interviewerCustomInstructions: LexicalSchema.optional(),
  interviewerConfirmationTemplate: PostTemplateForUseFragmentSchema.optional(),
});

export type InterviewReviewData = z.infer<typeof InterviewReviewSchema>;

export const OptionalAttendeeUserMembershipSchema = z.object({
  id: z.string(),
  email: z.string(),
  name: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  imageUrl: z.string().nullable(),
});

export type OptionalAttendeeUserMembership = z.infer<
  typeof OptionalAttendeeUserMembershipSchema
>;

/** Shared fields across every review */
export const BaseInterviewReviewFormSchema = z.object({
  sendNotifications: z.boolean(),
  note: z.string().optional(),
  optionalAttendeeUserMemberships: z
    .array(OptionalAttendeeUserMembershipSchema)
    .optional(),
  addToGoogleCalendarId: z.string().optional(),
});

/** Reviewing a single interview schema */
export const SingleInterviewReviewFormSchema = z
  .object({
    ...BaseInterviewReviewFormSchema.shape,
    ...InterviewReviewSchema.shape,
  })
  .refine(
    (interview) => {
      if (
        interview.status === ScheduledStatus.CREATED &&
        !interview.addToGoogleCalendarId
      ) {
        return false;
      }

      return true;
    },
    {
      message: UserValidationMessages.calendarRequired,
    }
  );

export type SingleInterviewReviewFormData = z.infer<
  typeof SingleInterviewReviewFormSchema
>;

/** Reviewing a panel of interview schema */
export const InterviewsPanelReviewFormSchema = z
  .object({
    ...BaseInterviewReviewFormSchema.shape,
    interviews: z.array(InterviewReviewSchema),
  })
  .refine(
    (data) => {
      const hasCreatedInterview = data.interviews.some(
        (i) => i.status === ScheduledStatus.CREATED
      );

      if (hasCreatedInterview && !data.addToGoogleCalendarId) {
        return false;
      }

      return true;
    },
    {
      message: UserValidationMessages.calendarRequired,
    }
  );

export type InterviewsPanelReviewFormData = z.infer<
  typeof InterviewsPanelReviewFormSchema
>;
