import { Avatar } from "@resource/atlas/avatar/Avatar";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { InterviewerCardInterviewerFragment } from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef } from "react";

gql(`
fragment InterviewerCardInterviewer on Interviewer {
    id
    responseStatus
    userMembership {
      id
      name
      title
      imageUrl
    }
  }
`);

type InterviewerCardProps = ComponentPropsWithoutRef<"button"> & {
  interviewer: Omit<InterviewerCardInterviewerFragment, "__typename">;
  onClick?: () => void;
};
export function InterviewerCard({
  interviewer,
  onClick,
  className,
  ...props
}: InterviewerCardProps) {
  let status: "accepted" | "declined" | "pending" | undefined;
  switch (interviewer.responseStatus) {
    case "ACCEPTED":
      status = "accepted";
      break;
    case "DECLINED":
      status = "declined";
      break;
    default:
      status = undefined;
      break;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      key={interviewer.id}
      className={clsx(
        "w-full shadow-1 py-2.5 px-3 rounded-md flex items-center gap-2 focus:focus-ring-0 hover:bg-light-gray-200 active:bg-light-gray-500 active:outline-none",
        className
      )}
      {...props}
    >
      <Avatar
        size="large"
        name={interviewer.userMembership.name}
        image={interviewer.userMembership.imageUrl}
        status={status}
      />
      <div className="grow flex flex-col truncate">
        <span className="text-body-md text-left truncate w-full">
          {interviewer.userMembership.name}
        </span>
        {interviewer.userMembership.title && (
          <span className="text-body-sm text-left text-subtle truncate w-full">
            {interviewer.userMembership.title}
          </span>
        )}
      </div>
    </button>
  );
}
