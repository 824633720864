import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import {
  Dialog,
  DialogProps,
  DialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { useToast } from "@resource/atlas/toast/use-toast";
import { View } from "@resource/atlas/view/View";
import { CandidateAvailability } from "client/guide-availability/components/CandidateAvailability";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { TimezoneSelectorV2 } from "components/TimezoneSelection/TimezoneSelectorV2";
import copy from "copy-to-clipboard";
import { gql } from "generated/graphql-codegen";
import { CopyAvailabilityDialogSubmissionFragment } from "generated/graphql-codegen/graphql";
import { useState } from "react";
import { getFormattedEventTimeRangeWithDay } from "shared/guide-availability/utils";
import {
  displayTimezone,
  getCurrentIanaTimezone,
} from "shared/utils/timezones";

type AvailabilityEvent =
  CopyAvailabilityDialogSubmissionFragment["upcomingEvents"];

gql(`
  fragment CopyAvailabilityDialogSubmission on GuideAvailabilitySubmission {
    id
    upcomingEvents {
      id
      title
      startTime
      endTime
    }
  }
`);

export type CopyAvailabilityDialogProps = DialogProps & {
  availability: AvailabilityEvent;
  candidateName: string;
  jobName: string;
  timezone?: string | null;
  onCompleted?: () => void;
};

export function CopyAvailabilityDialog({
  availability,
  candidateName,
  jobName,
  timezone,
  store,
  onCompleted,
  ...dialogProps
}: CopyAvailabilityDialogProps) {
  return (
    <Dialog size="small" {...dialogProps} store={store}>
      <ErrorBoundary
        fallback={({ onRecover }) => <DialogError onRecover={onRecover} />}
      >
        <CopyAvailabilityDialogDisplay
          availability={availability}
          candidateName={candidateName}
          jobName={jobName}
          timezone={timezone}
          store={store}
          onCompleted={onCompleted}
        />
      </ErrorBoundary>
    </Dialog>
  );
}

function CopyAvailabilityDialogDisplay({
  availability,
  candidateName,
  jobName,
  timezone: defaultTimezone,
  store,
  onCompleted,
}: {
  availability: AvailabilityEvent;
  candidateName: string;
  jobName: string;
  timezone?: string | null;
  store: DialogStore;
  onCompleted?: () => void;
}) {
  const [timezone, setTimezone] = useState(
    defaultTimezone ?? getCurrentIanaTimezone()
  );
  const { sendToast } = useToast();

  const formatEvents = (events: typeof availability) => {
    const formattedEvents = events
      .map((event) => `• ${getFormattedEventTimeRangeWithDay(event, timezone)}`)
      .join("\n");
    return `Availability for ${candidateName} for ${jobName}:\n${formattedEvents}`;
  };

  return (
    <View
      content={{
        className: "space-y-4",
      }}
      header={{
        title: "Copy availability",
        hideBorder: true,
      }}
      footer={{
        hideBorder: true,
        rightActions: (
          <ButtonGroup>
            <Button
              onClick={() => {
                store.hide();
                onCompleted?.();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={() => {
                const formattedEvents = formatEvents(availability);
                copy(formattedEvents);
                sendToast(
                  `Availability copied to clipboard in ${displayTimezone(
                    timezone,
                    "abbreviation"
                  )}`,
                  {
                    variant: "success",
                  }
                );
                store.hide();
              }}
            >
              Copy in {displayTimezone(timezone, "abbreviation")}
            </Button>
          </ButtonGroup>
        ),
      }}
    >
      <div className="space-y-2">
        <p className="text-body-md-heavy text-dark">Timezone</p>
        <TimezoneSelectorV2
          triggerProps={{
            className: "w-full !bg-light-gray-500",
          }}
          contentProps={{
            sameWidth: true,
            portal: true,
          }}
          value={timezone}
          onClick={setTimezone}
        />
      </div>
      <div className="shadow-sm rounded-md p-4 space-y-2">
        <p className="text-body-md text-dark">{`Availability for ${candidateName} for ${jobName}:`}</p>
        <CandidateAvailability
          events={availability}
          timezone={timezone}
          isCurrentSubmission
        />
      </div>
    </View>
  );
}
