import { Icon } from "@resource/atlas/icon/Icon";
import {
  serviceCoderpad,
  serviceCodesignal,
  serviceHackerrank,
} from "@resource/atlas/icons";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { CollaborativeCodingServiceType } from "generated/graphql-codegen/graphql";
import { forwardRef, ReactNode, useMemo } from "react";

export const COLLABORATIVE_CODING_LABELS = {
  [CollaborativeCodingServiceType.CODERPAD]: "CoderPad",
  [CollaborativeCodingServiceType.CODESIGNAL]: "CodeSignal",
  [CollaborativeCodingServiceType.HACKERRANK]: "HackerRank",
  [CollaborativeCodingServiceType.CUSTOM]: "Custom",
};

export const COLLABORATIVE_CODING_ICONS = {
  [CollaborativeCodingServiceType.CODERPAD]: serviceCoderpad,
  [CollaborativeCodingServiceType.CODESIGNAL]: serviceCodesignal,
  [CollaborativeCodingServiceType.HACKERRANK]: serviceHackerrank,
  [CollaborativeCodingServiceType.CUSTOM]: null,
};

export type CollaborativeCodingSelectTriggerProps = AtlasSelectTriggerProps & {
  label: ReactNode;
};
const CollaborativeCodingTrigger = forwardRef<
  HTMLButtonElement,
  CollaborativeCodingSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref} className="bg-light-gray-500">
      {props.label}
    </SelectTrigger>
  );
});

type CollaborativeCodingSelectProps = {
  value?: CollaborativeCodingServiceType;
  onChange: (value: CollaborativeCodingServiceType | undefined) => void;
  integrations: {
    hackerRank: boolean;
    coderpad: boolean;
    codeSignal: boolean;
  };
};

export function CollaborativeCodingSelect({
  value,
  onChange,
  integrations,
}: CollaborativeCodingSelectProps) {
  const items = useSelectItems(
    (i) => {
      return [
        ...(integrations.coderpad ||
        value === CollaborativeCodingServiceType.CODERPAD
          ? [
              i.option({
                key: "coderpad",
                value: CollaborativeCodingServiceType.CODERPAD,
                children:
                  COLLABORATIVE_CODING_LABELS[
                    CollaborativeCodingServiceType.CODERPAD
                  ],
                renderContent: ({ children }) => (
                  <div className="flex items-center gap-2">
                    <Icon
                      content={
                        COLLABORATIVE_CODING_ICONS[
                          CollaborativeCodingServiceType.CODERPAD
                        ]
                      }
                    />
                    <span>{children}</span>
                  </div>
                ),
              }),
            ]
          : []),
        ...(integrations.hackerRank ||
        value === CollaborativeCodingServiceType.HACKERRANK
          ? [
              i.option({
                key: "hackerrank",
                value: CollaborativeCodingServiceType.HACKERRANK,
                children:
                  COLLABORATIVE_CODING_LABELS[
                    CollaborativeCodingServiceType.HACKERRANK
                  ],
                renderContent: ({ children }) => (
                  <div className="flex items-center gap-2">
                    <Icon
                      content={
                        COLLABORATIVE_CODING_ICONS[
                          CollaborativeCodingServiceType.HACKERRANK
                        ]
                      }
                    />
                    <span>{children}</span>
                  </div>
                ),
              }),
            ]
          : []),
        ...(integrations.codeSignal ||
        value === CollaborativeCodingServiceType.CODESIGNAL
          ? [
              i.option({
                key: "codeSignal",
                value: CollaborativeCodingServiceType.CODESIGNAL,
                children:
                  COLLABORATIVE_CODING_LABELS[
                    CollaborativeCodingServiceType.CODESIGNAL
                  ],
                renderContent: ({ children }) => (
                  <div className="flex items-center gap-2">
                    <Icon
                      content={
                        COLLABORATIVE_CODING_ICONS[
                          CollaborativeCodingServiceType.CODESIGNAL
                        ]
                      }
                    />
                    <span>{children}</span>
                  </div>
                ),
              }),
            ]
          : []),
      ];
    },
    [integrations, value]
  );

  const icon = useMemo(() => {
    const iconStr =
      value && COLLABORATIVE_CODING_ICONS[value]
        ? COLLABORATIVE_CODING_ICONS[value]
        : null;
    if (!iconStr) {
      return null;
    }

    return <Icon content={iconStr} />;
  }, [value]);

  return (
    <Select.Root
      sameWidth
      value={value}
      setValue={(v) => {
        onChange(v as CollaborativeCodingServiceType | undefined);
      }}
    >
      <Select.Trigger>
        <CollaborativeCodingTrigger
          label={
            <div className="flex items-center gap-2 w-full">
              {icon}
              <span>
                {value ? COLLABORATIVE_CODING_LABELS[value] : "No selection"}
              </span>
            </div>
          }
        />
      </Select.Trigger>
      <Select.Content items={items} />
    </Select.Root>
  );
}
