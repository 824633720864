import { VideoConferencingServiceType } from "@prisma/client";
import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasVideo,
  serviceGoogleMeet,
  serviceZoom,
} from "@resource/atlas/icons";

export const VIDEO_CONFERENCING_ICONS: Record<
  VideoConferencingServiceType,
  AtlasIconData | null
> = {
  [VideoConferencingServiceType.ZOOM]: serviceZoom,
  [VideoConferencingServiceType.GOOGLE_MEET]: serviceGoogleMeet,
  // Not supported yet
  [VideoConferencingServiceType.MICROSOFT_TEAMS]: null,
  [VideoConferencingServiceType.OTHER]: null,
  [VideoConferencingServiceType.SKYPE]: null,
};

export const VIDEO_CONFERENCING_LABELS: Record<
  VideoConferencingServiceType,
  string
> = {
  [VideoConferencingServiceType.ZOOM]: "Zoom",
  [VideoConferencingServiceType.GOOGLE_MEET]: "Google Meet",
  [VideoConferencingServiceType.MICROSOFT_TEAMS]: "Microsoft Teams",
  [VideoConferencingServiceType.OTHER]: "Custom",
  [VideoConferencingServiceType.SKYPE]: "Skype",
};

export const ZOOM_URL_PATTERN =
  "(https|zoom):\\/\\/([^/]*\\.)?(zoom|zoomgov)\\.(us|com)\\/(u|j|my)\\/[^\\s,&<>]*";
export const ZOOM_URL_REGEX = new RegExp(ZOOM_URL_PATTERN);

export const MEET_URL_PATTERN =
  "((https):\\/\\/)?meet\\.google\\.com\\/[^\\s,&]*";
export const MEET_URL_REGEX = new RegExp(MEET_URL_PATTERN);

const TEAMS_URL_PATTERN =
  "(https:\\/\\/)?teams\\.microsoft\\.com\\/l\\/meetup-join\\/[^\\s,&]*";
export const TEAMS_URL_REGEX = new RegExp(TEAMS_URL_PATTERN);

export const isZoomConferencingUrl = (value: string) => {
  return ZOOM_URL_REGEX.test(value);
};

export const isGoogleMeetConferencingUrl = (value: string) => {
  return MEET_URL_REGEX.test(value);
};

export const isTeamsConferencingUrl = (value: string) => {
  return TEAMS_URL_REGEX.test(value);
};

export const isVideoConferencingUrl = (value: string) => {
  return (
    ZOOM_URL_REGEX.test(value) ||
    MEET_URL_REGEX.test(value) ||
    TEAMS_URL_REGEX.test(value)
  );
};

export const extractVideoConferencingUrl = (value: string): string | null => {
  const zoomMatch = value.match(ZOOM_URL_REGEX);
  if (zoomMatch) return zoomMatch[0];

  const meetMatch = value.match(MEET_URL_REGEX);
  if (meetMatch) return meetMatch[0];

  const teamsMatch = value.match(TEAMS_URL_REGEX);
  if (teamsMatch) return teamsMatch[0];

  return null;
};

export const getVideoConferencingImage = (value: string) => {
  if (ZOOM_URL_REGEX.test(value)) {
    return serviceZoom;
  }

  if (MEET_URL_REGEX.test(value)) {
    return serviceGoogleMeet;
  }

  return atlasVideo;
};
