import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import { Link } from "@resource/atlas/link/Link";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useSelectState } from "@resource/atlas/select/use-select-state";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import {
  GuideUpdateSubjectEditor,
  GuideUpdateSubjectEditorProps,
} from "client/components/guide-content";
import clsx from "clsx";
import { ThreadForDetailFragment } from "generated/graphql-codegen/graphql";
import { EditorState } from "lexical";
import { useCallback, useRef } from "react";

type SubjectSelectorProps = {
  subject?: AtlasContentEditorSerializedState | string;
  setSubject: (subject: EditorState) => void;
  thread?: ThreadForDetailFragment | null;
  taskThreads?: ThreadForDetailFragment[];
  allThreads: ThreadForDetailFragment[];
  setThreadId: (threadId?: string) => void;
} & GuideUpdateSubjectEditorProps;

const NEW_THREAD_VALUE = "NEW_THREAD";

export function SubjectSelector({
  thread,
  subject,
  setSubject,
  taskThreads,
  allThreads,
  setThreadId,
  ...rest
}: SubjectSelectorProps) {
  const subjectFieldRef = useRef<HTMLInputElement | null>(null);
  const selectState = useSelectState({
    value: thread?.id ?? NEW_THREAD_VALUE,
    setValue: (v) => {
      const value = v?.toString();
      if (value === NEW_THREAD_VALUE) {
        setThreadId(undefined);
        setTimeout(() => subjectFieldRef.current?.focus());
        return;
      }
      setThreadId(value);
    },
  });

  const setValue = useCallback(
    (value: string) => () => {
      if (value === NEW_THREAD_VALUE) {
        setThreadId(undefined);
        setTimeout(() => subjectFieldRef.current?.focus());
        return;
      }
      setThreadId(value);
    },
    [setThreadId]
  );

  const options = useMenuItems(
    (i) => {
      const result = [];

      if (taskThreads && taskThreads.length > 0) {
        result.push(
          i.groupLabel({
            key: "task-threads-heading",
            children: "Messages from task",
          }),
          ...taskThreads.map((t) =>
            i.item({
              key: t.id,
              value: t.id,
              children: t.title,
              onClick: setValue(t.id),
            })
          ),
          i.separator({
            key: "task-threads-separator",
          })
        );
      }

      if (allThreads.length > 0) {
        result.push(
          i.groupLabel({
            key: "all-threads-heading",
            children: "All messages",
          }),
          ...allThreads
            .filter(
              (t) => !taskThreads || !taskThreads.find((t2) => t.id === t2.id)
            )
            .map((t) =>
              i.item({
                key: t.id,
                value: t.id,
                children: t.title,
                onClick: setValue(t.id),
              })
            ),
          i.separator({
            key: "all-threads-separator",
          })
        );
      }

      result.push(
        i.item({
          key: "new-thread",
          value: NEW_THREAD_VALUE,
          children: "Send as a new message",
          onClick: setValue(NEW_THREAD_VALUE),
        })
      );

      return result;
    },
    [taskThreads, allThreads, setValue]
  );

  return (
    <div className="w-full flex gap-2 items-center">
      <Tooltip
        content="The subject cannot be edited when replying on an existing thread!"
        isInstant
        className="truncate grow"
      >
        <p
          className={clsx(
            "text-body-md text-disabled m-[0.625rem] cursor-default w-full truncate flex-1",
            thread ? "block" : "hidden"
          )}
        >
          {`Re: ${thread?.title ?? ""}`}
        </p>
      </Tooltip>
      <GuideUpdateSubjectEditor
        undefinedMode="error"
        className={clsx("w-full", thread ? "!hidden" : "block")}
        onChange={setSubject}
        initialState={subject}
        {...rest}
      />
      <Menu.Root>
        <Menu.Trigger>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as="button"
            variant="subtle"
            className="text-body-md flex-shrink-0"
            onClick={() => selectState.toggle()}
          >
            Reply to…
          </Link>
        </Menu.Trigger>
        <Menu.Content defaultSize="compact" items={options} />
      </Menu.Root>
    </div>
  );
}
