import { CypressData } from "client/cypress-data-keys";
import { DateTime } from "luxon";
import { Fragment } from "react";

import { useCalendarSettings } from "./settings";
import { HOURS_IN_DAY, useCalendarSizes } from "./utils";

export function HorizontalLines() {
  const { twentyFourHourFormat } = useCalendarSettings();
  const calendarSizes = useCalendarSizes();

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid divide-y divide-[#EEEDEF]"
      style={{
        gridTemplateRows: `repeat(${HOURS_IN_DAY}, minmax(${calendarSizes.hourHeight.rem}rem, 1fr))`,
      }}
    >
      {[...Array(24).keys()].map((i) => {
        const time = DateTime.fromObject({ hour: i });
        const isNotMidnight = time.hour !== 0;

        return (
          <Fragment key={time.toISO()}>
            <div data-cy={CypressData.calendar.horizontalLine}>
              {isNotMidnight && (
                <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-disabled">
                  {twentyFourHourFormat
                    ? time.toFormat("H:00")
                    : time.toFormat("ha")}
                </div>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}
