import {
  getAvatarColorFromInitials,
  getAvatarInitialsFromName,
} from "@resource/atlas/avatar/initials";
import React, { useMemo } from "react";

import { Image } from "./Image";

type AvatarProps = {
  color?: string;
  url?: string | null;
  initials: string;
  name: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
};

export function Avatar({
  color,
  url,
  initials,
  name,
  size = "xs",
}: AvatarProps) {
  let width;
  let fontSize;
  let lineHeight;
  switch (size) {
    case "xs":
      width = "20px";
      fontSize = "8px";
      lineHeight = "20px";
      break;
    case "sm":
      width = "24px";
      fontSize = "10px";
      lineHeight = "24px";
      break;
    case "md":
      width = "32px";
      fontSize = "13px";
      lineHeight = "32px";
      break;
    case "lg":
      width = "40px";
      fontSize = "16px";
      lineHeight = "40px";
      break;
    case "xl":
      width = "48px";
      fontSize = "20px";
      lineHeight = "48px";
      break;
    case "2xl":
      width = "64px";
      fontSize = "24px";
      lineHeight = "64px";
      break;
    default:
  }
  const height = width;
  const qualifiedBgClassName = useMemo(() => {
    // Color classes borrowed from atlas Avatar component
    switch (color) {
      case "blue":
        return "bg-blue-500";
      case "green":
        return "bg-green-500";
      case "orange":
        return "bg-orange-500";
      case "purple":
        return "bg-purple-500";
      case "yellow":
        return "bg-yellow-500";
      case "gray":
        return "bg-light-gray-500";
      default:
        return undefined;
    }
  }, [color]);

  return (
    <span
      style={{
        width,
        height,
        display: "block",
        borderRadius: 9999,
        backgroundColor: qualifiedBgClassName ? undefined : color || "#F9FAFB",
      }}
      className={qualifiedBgClassName}
    >
      {url ? (
        <Image
          src={url}
          alt={name}
          width={width}
          height={height}
          style={{
            border: 0,
            display: "block",
            borderRadius: 9999,
            objectFit: "cover",
            verticalAlign: "middle",
          }}
        />
      ) : (
        <span
          style={{
            display: "block",
            width,
            height,
            lineHeight,
            textAlign: "center",
            color: !color ? "#231F32" : "#FFF",
            fontSize,
            fontWeight: 700,
          }}
        >
          {initials}
        </span>
      )}
    </span>
  );
}

export function AvatarInterviewer({
  interviewer: { name, imageUrl },
  ...rest
}: {
  interviewer: {
    name: string;
    imageUrl: string | null;
  };
} & Omit<AvatarProps, "name" | "url" | "color" | "initials">) {
  const computedInitials = getAvatarInitialsFromName(name) ?? "";
  const initialsColor = computedInitials
    ? getAvatarColorFromInitials(computedInitials)
    : "gray";

  return (
    <Avatar
      size="sm"
      {...rest}
      color={initialsColor}
      initials={computedInitials}
      name={name}
      url={imageUrl}
    />
  );
}
