import {
  Anchor,
  Content,
  Description,
  Heading,
  Root,
  Trigger,
} from "./Popover";

export const Popover = { Root, Trigger, Anchor, Content, Heading, Description };
export * from "./types";
