import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasClose,
  atlasFilterPlus,
  atlasRingInfo,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { Toggle } from "client/app/__components/Toggle";
import { ColorizedPoolIcon } from "client/components/interview-requirements/__components/ColorizedPoolIcon";
import { TagFilterWithQuery } from "client/components/tag-filters/TagFilterWithQuery";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import {
  InterviewerRequirementForConfigurationFragment,
  TagFilterType,
} from "generated/graphql-codegen/graphql";
import { v4 } from "uuid";

export type InterviewerPoolSelectProps = {
  requirement: InterviewerRequirementForConfigurationFragment;
  onRemove: () => void;
  setIncludeShadower: (value: boolean) => void;
  setUseExternalInterviewerAlgorithm: (value: boolean) => void;
  showShadowingToggle?: boolean;
  onTagFiltersChange: (
    tagFilters: InterviewerRequirementForConfigurationFragment["tagFilters"]
  ) => void;
};

export function InterviewerPoolSelect({
  requirement,
  onRemove,
  setIncludeShadower,
  setUseExternalInterviewerAlgorithm,
  showShadowingToggle = true,
  onTagFiltersChange,
}: InterviewerPoolSelectProps) {
  const {
    [FeatureFlags.USE_EXTERNAL_INTERVIEWER_ALGORITHM_V_2]: useDuolingoAlgorithm,
    [FeatureFlags.INTERVIEWER_TAGS]: interviewerTagsEnabled,
  } = useFlags();

  const pool = requirement.interviewerPools?.[0];
  if (!pool) {
    return null;
  }

  return (
    <div className="rounded-md group shadow-1">
      <div className="flex gap-2 px-3 py-[10px] items-center">
        <ColorizedPoolIcon variant="large" />
        <div className="flex-grow">
          <div className="text-body-md">{pool.name}</div>
          <div className="text-subtle text-body-sm">
            {pool.qualifiedUserCount} interviewers
          </div>
        </div>
        <ButtonGroup>
          {interviewerTagsEnabled && (
            <Button
              isGhost
              size="xs"
              icon={atlasFilterPlus}
              onClick={() => {
                onTagFiltersChange([
                  ...(requirement.tagFilters || []),
                  {
                    id: v4(),
                    type: TagFilterType.INCLUDE_ANY,
                    tags: [],
                  },
                ]);
              }}
            />
          )}
          <Button isGhost size="xs" icon={atlasClose} onClick={onRemove} />
        </ButtonGroup>
      </div>

      {useDuolingoAlgorithm && (
        <div className="text-body-sm-heavy bg-light-gray-200 py-2 px-3 text-subtle flex items-center gap-2">
          <Toggle
            size="small"
            value={requirement.useExternalInterviewerAlgorithm}
            onChange={(value) => {
              setUseExternalInterviewerAlgorithm(value);
            }}
          />
          <div className="flex-grow">Use Duolingo interviewer selection</div>
        </div>
      )}
      <div className="pb-2 px-3 space-y-1">
        {requirement.tagFilters?.map((filter) => (
          <TagFilterWithQuery
            key={filter.id}
            selectedTags={filter.tags}
            defaultOpen={filter.tags.length === 0}
            onTagSelect={(tag) => {
              onTagFiltersChange(
                requirement.tagFilters?.map((f) => {
                  if (f.id === filter.id) {
                    return {
                      ...f,
                      tags: [...f.tags, tag],
                    };
                  }
                  return f;
                }) || []
              );
            }}
            onRemoveTag={(tag) => {
              onTagFiltersChange(
                requirement.tagFilters?.map((f) => {
                  if (f.id === filter.id) {
                    return {
                      ...f,
                      tags: f.tags.filter((t) => t.id !== tag.id),
                    };
                  }
                  return f;
                }) || []
              );
            }}
            onClearAll={() => {
              onTagFiltersChange(
                requirement.tagFilters?.filter((f) => f.id !== filter.id) || []
              );
            }}
            onFilterChange={(type) => {
              onTagFiltersChange([
                ...(requirement.tagFilters?.filter((f) => f.id !== filter.id) ||
                  []),
                {
                  id: v4(),
                  type,
                  tags: filter.tags,
                },
              ]);
            }}
            filterType={filter.type}
          />
        ))}
      </div>
      {showShadowingToggle && (
        <div className="text-body-sm-heavy bg-light-gray-200 py-2 px-3 text-subtle flex items-center gap-2">
          <Toggle
            size="small"
            value={requirement.includeShadower}
            onChange={(value) => {
              setIncludeShadower(value);
            }}
          />
          <div className="flex-grow">
            Interviewer training ({pool.traineeUserCount} available)
          </div>
          <Tooltip
            content={`Optionally, a trainee from the ${pool.name} pool should be scheduled to shadow.`}
            isInstant
          >
            <Icon content={atlasRingInfo} className="w-4 h-4" />
          </Tooltip>
        </div>
      )}
    </div>
  );
}
