import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarConflict } from "@resource/atlas/icons";
import { CypressData } from "client/cypress-data-keys";
import clsx from "clsx";

import { InterviewTitle } from "../guide/interviews/InterviewTitle/InterviewTitle";
import { getTextColorFromConfig } from "./colors";
import { CalendarEvent } from "./types";

function isLexicalContent(title: string) {
  try {
    JSON.parse(title);
    return true;
  } catch (err) {
    return false;
  }
}

export function EventTitle({
  event,
}: {
  event: Pick<
    CalendarEvent,
    "title" | "colorConfig" | "outOfOffice" | "icon" | "responseStatus"
  >;
}) {
  const icon =
    event.icon ?? (event.outOfOffice ? atlasCalendarConflict : null) ?? null;
  const isLexical = isLexicalContent(event.title);

  return (
    <span
      className="flex flex-row space-x-1 text-[12px]"
      data-cy={CypressData.calendar.eventTitle}
    >
      {icon && !(typeof icon === "object" && "text" in icon) && (
        <Icon
          content={icon}
          className="text-dark w-4 h-4"
          style={{
            color: getTextColorFromConfig(
              event.colorConfig,
              event.responseStatus
            ),
          }}
        />
      )}
      {icon && typeof icon === "object" && "text" in icon && (
        <span className="text-dark" style={{ lineHeight: "12px" }}>
          {icon.text}
        </span>
      )}
      {isLexical ? (
        <InterviewTitle
          interview={event}
          className={clsx("order-1 font-semibold", "text-dark")}
          style={{
            color: event.colorConfig
              ? getTextColorFromConfig(event.colorConfig, event.responseStatus)
              : undefined,
            lineHeight: "12px",
          }}
        />
      ) : (
        <p
          className={clsx("order-1 font-semibold", "text-dark")}
          style={{
            color: event.colorConfig
              ? getTextColorFromConfig(event.colorConfig, event.responseStatus)
              : undefined,
            lineHeight: "12px",
          }}
        >
          {event.title}
        </p>
      )}
    </span>
  );
}
