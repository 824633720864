import { DateTime } from "luxon";
import { useMemo } from "react";

import { CalendarEvent } from "../../../utils/types";

export type EventSize = "xs" | "small" | "medium" | "large";

export function getDurationWithMin(durationInMinutes: number) {
  return Math.max(durationInMinutes, 15);
}

export function getEventSize({
  startTime,
  endTime,
}: {
  startTime: DateTime;
  endTime: DateTime;
}): EventSize {
  const durationInMinutes = endTime.diff(startTime).as("minutes");
  const duration = getDurationWithMin(durationInMinutes);

  if (duration < 20) return "xs";

  if (duration < 40) return "small";

  if (duration < 60) return "medium";

  return "large";
}

export function useEventSize(
  event: Pick<CalendarEvent, "startTime" | "endTime">
) {
  return useMemo(() => getEventSize(event), [event]);
}
