import { useRender } from "@resource/atlas/content-editor/renderer";
import { themeContainerClass } from "@resource/atlas/content-editor/theme";
import type { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor/types";
import {
  ValueSet,
  VariableRendererProvider,
} from "@resource/atlas/content-editor/variables";
import clsx from "clsx";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { renderScheduledInterviewDetails } from "shared/guide-scheduler/scheduled-interview-details/renderer";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";

type CustomInstructionsRendererProps = ComponentPropsWithoutRef<"div"> & {
  content: unknown;
  valueSet?: ValueSet<ScheduledInterviewDetailsVariableSet>;
};

export const CustomInstructionsRenderer = forwardRef<
  HTMLDivElement,
  CustomInstructionsRendererProps
>(({ content, valueSet, ...props }, ref) => {
  const renderedContent = useRender(
    renderScheduledInterviewDetails,
    content as AtlasContentEditorSerializedState
  );

  if (!renderedContent) {
    return null;
  }

  return (
    <VariableRendererProvider valueSet={valueSet}>
      <div
        {...props}
        ref={ref}
        className={clsx(props.className, themeContainerClass)}
      >
        {renderedContent}
      </div>
    </VariableRendererProvider>
  );
});
