import {
  Dialog,
  DialogProps,
  DialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import {
  LocationType,
  SelfScheduleRequestForCreateOrUpdateSubmissionFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useMutation from "utils/useMutation";
import useQuery from "utils/useQuery";

import { useSelfScheduleRequestSelfScheduleForm } from "./__hooks/useSelfScheduleRequestSelfScheduleForm";
import { useSelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { CreateOrUpdateSelfScheduleSubmissionView } from "./CreateOrUpdateSelfScheduleSubmissionView";
import { SelfScheduleRequestSelfScheduleForm } from "./SelfScheduleRequestSelfScheduleForm";

export type CreateOrUpdateSelfScheduleSubmissionDialogProps = DialogProps &
  Pick<
    CreateOrUpdateSelfScheduleSubmissionViewsProps,
    "scheduledInterviewId"
  > & {
    selfScheduleRequestId: string;
    onComplete?: () => void;
  };

const FETCH_SELF_SCHEDULE_REQUEST = gql(`
  query FetchSelfScheduleRequestForSubmissionDialog($selfScheduleRequestId: String!) {
    selfScheduleRequestById(id: $selfScheduleRequestId) {
      ...SelfScheduleRequestForCreateOrUpdateSubmission
    }
  }
`);

export function CreateOrUpdateSelfScheduleSubmissionDialog({
  selfScheduleRequestId,
  scheduledInterviewId,
  store,
  onComplete,
  ...dialogProps
}: CreateOrUpdateSelfScheduleSubmissionDialogProps) {
  return (
    <Dialog size="large" store={store} {...dialogProps}>
      <ErrorBoundary
        fallback={({ onRecover }) => <DialogError onRecover={onRecover} />}
      >
        <CreateOrUpdateSelfScheduleSubmissionQuery
          selfScheduleRequestId={selfScheduleRequestId}
          store={store}
          scheduledInterviewId={scheduledInterviewId}
          onComplete={onComplete}
        />
      </ErrorBoundary>
    </Dialog>
  );
}

const SCHEDULE_NEW_INTERVIEW = gql(`
  mutation ScheduleInterviewForSelfScheduleRequest($input: ScheduleInterviewForSelfScheduleRequestInput!) {
    scheduleInterviewForSelfScheduleRequest(input: $input) {
      code
      message
      success
      guidePost {
        id
      }
      scheduledInterview {
        id
      }
      selfScheduleRequest {
        id
        ...SelfScheduleRequestForCreateOrUpdateSubmission
      }
    }
  }
`);

const UPDATE_EXISTING_INTERVIEW = gql(`
  mutation RescheduleInterviewForSelfScheduleRequest($input: RescheduleInterviewForSelfScheduleRequestInput!) {
    rescheduleInterviewForSelfScheduleRequest(input: $input) {
      code
      message
      success
      guidePost {
        id
      }
      scheduledInterview {
        id
        ...ScheduledInterviewForScheduledInterviewCard
      }
      selfScheduleRequest {
        id
        ...SelfScheduleRequestForCreateOrUpdateSubmission
      }
    }
  }
`);

gql(`
  fragment SelfScheduleRequestForCreateOrUpdateSubmission on SelfScheduleRequest {
    id
    interviewSettings {
      id
      ...InterviewSettingsForSubmission
    }
    completedBySubmission {
      id
      scheduledInterview {
        id
        startTime
        endTime
      }
    }
    availableSlots {
      ...AvailableSlot
    }
    guide {
      id
      candidate {
        id
        phone
      }
    }
  }
`);

function CreateOrUpdateSelfScheduleSubmissionQuery({
  selfScheduleRequestId,
  store,
  scheduledInterviewId,
  onComplete,
}: {
  selfScheduleRequestId: string;
  store: DialogStore;
  scheduledInterviewId: CreateOrUpdateSelfScheduleSubmissionDialogProps["scheduledInterviewId"];
  onComplete?: () => void;
}) {
  const { data } = useQuery(FETCH_SELF_SCHEDULE_REQUEST, {
    variables: { selfScheduleRequestId },
    fetchPolicy: "network-only",
  });

  if (!data) {
    return <DialogLoading />;
  }

  if (data.selfScheduleRequestById === null) {
    return <DialogError errorCode="404" />;
  }

  return (
    <CreateOrUpdateSelfScheduleSubmission
      selfScheduleRequest={data.selfScheduleRequestById}
      scheduledInterviewId={scheduledInterviewId}
      onCompleted={() => {
        store.hide();
        onComplete?.();
      }}
      onCancel={store.hide}
    />
  );
}

type CreateOrUpdateSelfScheduleSubmissionViewsProps = {
  selfScheduleRequest: SelfScheduleRequestForCreateOrUpdateSubmissionFragment;
  scheduledInterviewId?: string;
  onCompleted: () => void;
  onCancel: () => void;
};

function CreateOrUpdateSelfScheduleSubmission({
  selfScheduleRequest,
  scheduledInterviewId,
  onCompleted,
  onCancel,
}: CreateOrUpdateSelfScheduleSubmissionViewsProps) {
  const logEvent = useLogEvent({
    component: "CreateOrUpdateSelfScheduleSubmission",
  });

  const [createSelfScheduleSubmission, { loading: createLoading }] =
    useMutation(SCHEDULE_NEW_INTERVIEW, {
      onCompleted: () => {
        onCompleted();
      },
    });
  const [updateSelfScheduleSubmission, { loading: updateLoading }] =
    useMutation(UPDATE_EXISTING_INTERVIEW, {
      onCompleted: () => {
        onCompleted();
      },
    });
  const submitLoading = createLoading || updateLoading;

  const state = useSelfScheduleSubmissionState({
    availableSlots: selfScheduleRequest.availableSlots,
    interviewSettings: selfScheduleRequest.interviewSettings,

    scheduledInterviewId,
  });
  const formProps = useSelfScheduleRequestSelfScheduleForm({
    defaultPhone: selfScheduleRequest.guide.candidate.phone ?? undefined,
    requirePhone:
      selfScheduleRequest.interviewSettings?.locationSettings?.service ===
      LocationType.PHONE,
    interviewerName:
      selfScheduleRequest.interviewSettings?.interviewer?.name ?? "",
    selectedSlot: state.selectedSlot,
    selectedTimezone: state.selectedTimezone,
    isRescheduling: !!scheduledInterviewId,
  });

  const { selectedSlot } = state;

  const { form } = formProps;

  const onSubmit = useCallback(async () => {
    if (!selectedSlot) {
      throw new Error("No selected slot");
    }

    const formData = form.getValues();

    if (scheduledInterviewId) {
      await updateSelfScheduleSubmission({
        variables: {
          input: {
            scheduledInterviewId,
            selfScheduleRequestId: selfScheduleRequest.id,
            message: formData.message ?? "",
            startTime: selectedSlot.startTime.toISO(),
            endTime: selectedSlot.endTime.toISO(),
          },
        },
      });
      logEvent("Interview Rescheduled", {
        isSelfScheduled: true,
      });
    } else {
      await createSelfScheduleSubmission({
        variables: {
          input: {
            selfScheduleRequestId: selfScheduleRequest.id,
            message: formData.message ?? "",
            startTime: selectedSlot.startTime.toISO(),
            endTime: selectedSlot.endTime.toISO(),
            phone: formData.phone,
          },
        },
      });
      logEvent("Interview Scheduled", {
        isSelfScheduled: true,
      });
    }
  }, [
    selectedSlot,
    form,
    scheduledInterviewId,
    updateSelfScheduleSubmission,
    selfScheduleRequest.id,
    logEvent,
    createSelfScheduleSubmission,
  ]);

  return (
    <CreateOrUpdateSelfScheduleSubmissionView
      state={state}
      submitLoading={submitLoading}
      onSubmit={form.handleSubmit(onSubmit)}
      onCancel={onCancel}
      content={{
        reviewPageTitle: {
          new: "Schedule interview",
          reschedule: "Reschedule interview",
        },
        reviewPageSubmitButton: {
          new: "Schedule interview",
          reschedule: "Reschedule interview",
        },
      }}
      reviewForm={formProps.form}
      ReviewFormDisplay={<SelfScheduleRequestSelfScheduleForm {...formProps} />}
    />
  );
}
