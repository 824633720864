import { useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";

const SCHEDULED_INTERVIEW_GROUP_FOR_GUIDE_CREATED_SUBSCRIPTION = gql(`
  subscription ScheduledInterviewGroupForGuideCreated($guideId: ID!) {
    scheduledInterviewGroupForGuideCreated(guideId: $guideId) {
      id
      guide {
        id
      }
    }
  }
`);

type UseScheduledInterviewGroupForGuideCreatedProps = {
  guideId?: string;
  onSubscriptionData?: () => void;
};

export function useScheduledInterviewGroupForGuideCreated({
  guideId,
  onSubscriptionData,
}: UseScheduledInterviewGroupForGuideCreatedProps) {
  useSubscription(SCHEDULED_INTERVIEW_GROUP_FOR_GUIDE_CREATED_SUBSCRIPTION, {
    variables: {
      guideId: guideId!,
    },
    skip: !guideId,
    onSubscriptionData: () => {
      onSubscriptionData?.();
    },
  });
}
