import "./module.sass";

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";

import { atlasCaption, atlasQuote } from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { useEvent, useScheduleFocus } from "../../../__utils/react";
import { Icon } from "../../../icon/Icon";
import { useMenuItems } from "../../../menu/use-menu-items";
import { INSERT_TARGETS } from "../../__utils/insert-options-targets-order";
import { createRichBlock, RichBlockProps } from "../../__utils/rich-blocks";
import {
  CHARACTERS_THRESHOLD,
  DEFAULT_DATA,
  RICH_BLOCK_NAME,
  RichQuoteData,
} from "./shared";

// config
// ------

const COMPONENT_NAME = "ContentEditor-RichQuote";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// render component
// ----------------

type RichQuoteProps = RichBlockProps<RichQuoteData>;

function RichQuoteComponent({ data, updateData, ConfigMenu }: RichQuoteProps) {
  const [text, setText] = useState(data.text);
  useEffect(() => {
    setText(data.text);
  }, [data.text]);

  const [attribution, setAttribution] = useState(data.attribution);
  useEffect(() => {
    setAttribution(data.attribution);
  }, [data.attribution]);

  const [showAttribution, setShowAttribution] = useState(false);

  const attributionRef = useRef<HTMLTextAreaElement>(null);
  const scheduleFocus = useScheduleFocus(attributionRef);

  const onAddAttribution = useEvent(() => {
    scheduleFocus();
    setShowAttribution(true);
  });

  const isAttributionVisible = showAttribution || Boolean(attribution);

  const configItems = useMenuItems(
    (i) => [
      !isAttributionVisible &&
        i.item({
          key: "add-attribution",
          children: "Add attribution",
          leadingContent: <Icon content={atlasCaption} />,
          onClick: onAddAttribution,
        }),
    ],
    [isAttributionVisible, onAddAttribution]
  );

  const longText = text.length >= CHARACTERS_THRESHOLD;

  return (
    <>
      <ConfigMenu items={configItems} />
      <div className={ROOT}>
        <div
          className={clsx("sizer text", longText && "long")}
          data-content={text}
        >
          <textarea
            rows={1}
            className="textarea"
            value={text}
            onChange={(event) => setText(event.currentTarget.value)}
            onBlur={() => updateData({ text })}
            placeholder="Paste or type a quote"
          />
        </div>
        {(showAttribution || attribution) && (
          <div className="sizer attribution" data-content={attribution}>
            <textarea
              ref={attributionRef}
              rows={1}
              className="textarea"
              value={attribution ?? ""}
              onChange={(event) =>
                setAttribution(event.currentTarget.value || undefined)
              }
              onBlur={() => {
                if (!attribution) setShowAttribution(false);
                updateData({ attribution });
              }}
              placeholder="Add attribution"
            />
          </div>
        )}
      </div>
    </>
  );
}

// block
// -----

export const {
  $createRichQuoteNode,
  $isRichQuoteNode,
  RichQuoteNode,
  RichQuotePlugin,
  richQuoteModule,
  INSERT_RICH_QUOTE_COMMAND,
} = createRichBlock({
  name: RICH_BLOCK_NAME,
  defaultData: DEFAULT_DATA,
  RenderComponent: async () => RichQuoteComponent,
  insertOption: {
    label: "Quote",
    icon: atlasQuote,
    targets: {
      insert: INSERT_TARGETS.QUOTE,
    },
  },
});
