import TextArea from "@resource/atlas/textfield/TextArea";
import { FormGroup } from "client/components/generic/misc/FormGroup";

export type AddInterviewChangeMessageInputProps = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  subText?: string;
};

export function AddInterviewChangeMessageInput({
  value,
  onChange,
  className,
  subText = "This message will be included in the email notification sent to interviewers.",
}: AddInterviewChangeMessageInputProps) {
  return (
    <FormGroup
      label="Add a message to interviewers"
      isOptional
      subText={subText}
      className={className}
    >
      <TextArea
        aria-label="Add a message to interviewers"
        value={value}
        onChange={onChange}
        className="h-60"
        inputClassName="h-60"
        placeholder="Add a message..."
      />
    </FormGroup>
  );
}
