/* eslint-disable import/prefer-default-export */
import { $isListItemNode, ListItemNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, HeadingNode, QuoteNode } from "@lexical/rich-text";
import { mergeRegister } from "@lexical/utils";
import { $createParagraphNode } from "lexical";
import { useEffect } from "react";

import { $createRichQuoteNode } from "../../../../rich-quote";
import { registerNodeTransform } from "../../../misc";

// config
// ------

const MAX_INDENT_DEPTH = 2;
const ALLOWED_HEADINGS = ["h1", "h2"];
const FALLBACK_HEADING = "h2";

// normalization plugin
// --------------------

export function NormalizationPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      mergeRegister(
        registerNodeTransform(editor, "block-element", (node) => {
          // indent only on list items
          if (!$isListItemNode(node) && node.getIndent() > 0) node.setIndent(0);
        }),
        registerNodeTransform(editor, ListItemNode, (node) => {
          // indent under the max depth
          const currentIndent = node.getIndent();
          if (currentIndent > MAX_INDENT_DEPTH)
            node.setIndent(MAX_INDENT_DEPTH);
        }),
        registerNodeTransform(
          editor,
          HeadingNode,
          (node) =>
            // only allowed headings
            !ALLOWED_HEADINGS.includes(node.getTag()) &&
            node.replace($createHeadingNode(FALLBACK_HEADING))
        ),
        registerNodeTransform(editor, QuoteNode, (node) => {
          // quotes should be rich quotes
          if (!node.getNextSibling()) node.insertAfter($createParagraphNode());
          const text = node.getTextContent();
          node.replace($createRichQuoteNode({ text }));
        })
      ),
    [editor]
  );

  return null;
}
