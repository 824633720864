import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { ScheduledInterviewDetails } from "client/components/scheduled-interviews/ScheduledInterviewDetails";

/**
 *  A wrapper around the ScheduledInterviewDetails component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorScheduledInterviewDetails({
  scheduledInterviewId,
  guideId,
}: {
  scheduledInterviewId: string;
  guideId: string;
}) {
  const navigator = useGlobalNavigatorOrThrow();

  return (
    <ScheduledInterviewDetails
      scheduledInterviewId={scheduledInterviewId}
      guideId={guideId}
      onBack={navigator.back}
    />
  );
}
