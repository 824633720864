import { atlasCalendarCross } from "@resource/atlas/icons";
import { BookingLinkLoadBalancingValue } from "client/booking-links/BookingLinkLoadBalancingFormGroup";
import { ColorConfig } from "client/components/calendar-v2/utils/colors";
import { CalendarEventInput } from "client/components/calendar-v2/utils/types";
import { combineEvents } from "client/components/calendar-v2/utils/utils";
import {
  getInterviewerOverloadedData,
  getLoadDataForUserMembership,
} from "client/components/interviewer-slots/load/utils/helpers";
import {
  BookingLinkForSelfScheduleTimesCalendarFragment,
  UserMembershipForInterviewerSelfScheduleFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { BookingLinkInterviewerLoadType } from "shared/booking-links/types";

import { SelfScheduleCalendarLayers } from "../utils/layers";

export const LOAD_CALENDAR_ID = "interviewer-load";

export const LOAD_EVENT_COLOR_CONFIG: ColorConfig = {
  color: "blocked",
  eventVariant: "background",
};

type LoadEvent = {
  id: string;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
};

type LoadLimits = {
  maxInterviewLoadPerDay: number | null;
  maxInterviewLoadPerWeek: number | null;
};

/**
 * Hook to combine and map load events to calendar format
 * Handles combining adjacent events and formatting for display
 */
function useLoadEventsMapping(events: LoadEvent[]): CalendarEventInput[] {
  return useMemo(() => {
    const combinedEvents = combineEvents(events);

    return combinedEvents.map(
      (event): CalendarEventInput => ({
        id: event.id,
        calendarId: LOAD_CALENDAR_ID,
        icon: atlasCalendarCross,
        start: {
          dateTime: event.startTime.toISO(),
        },
        end: {
          dateTime: event.endTime.toISO(),
        },
        title: event.title,
        colorConfig: LOAD_EVENT_COLOR_CONFIG,
        layer: SelfScheduleCalendarLayers.BACKGROUND_BLOCKERS,
      })
    );
  }, [events]);
}

/**
 * Hook to calculate and display interviewer account load events on the calendar
 * Shows periods where the interviewer is at their maximum load based on account settings
 */
function useAccountLoadEvents({
  userMembership,
  startTime,
  endTime,
}: {
  userMembership:
    | UserMembershipForInterviewerSelfScheduleFragment
    | undefined
    | null;
  startTime: DateTime;
  endTime: DateTime;
}): CalendarEventInput[] {
  const events = useMemo(() => {
    if (!userMembership) {
      return [];
    }

    const loadEvents: LoadEvent[] = [];
    let currentDate = startTime;

    while (currentDate < endTime) {
      const loadData = getLoadDataForUserMembership({
        userMembership,
        date: currentDate.toISO(),
        selectedInterview: null,
        interviews: [],
        originalInterviews: [],
        includeSelectedInterview: true,
      });

      const overloadedData = getInterviewerOverloadedData({
        loadData,
        hasBeenSelected: false,
      });

      if (overloadedData.accountOverloads.length > 0) {
        loadEvents.push({
          id: currentDate.toISO(),
          title: `At load for ${
            overloadedData.accountOverloads[
              overloadedData.accountOverloads.length - 1
            ]
          }`,
          startTime: currentDate.startOf("day"),
          endTime: currentDate.plus({ days: 1 }).startOf("day"),
        });
      }

      currentDate = currentDate.plus({ days: 1 });
    }

    return loadEvents;
  }, [userMembership, startTime, endTime]);

  return useLoadEventsMapping(events);
}

/**
 * Hook to calculate and display booking link load events on the calendar
 * Shows periods where the booking link is at its maximum load
 */
function useBookingLinkLoadEvents({
  interviews,
  startTime,
  endTime,
  loadLimits,
}: {
  interviews: { id: string; startAt: string }[];
  startTime: DateTime;
  endTime: DateTime;
  loadLimits: LoadLimits;
}): CalendarEventInput[] {
  const events = useMemo(() => {
    const loadEvents: LoadEvent[] = [];
    let currentDate = startTime;

    while (currentDate < endTime) {
      const dateStr = currentDate.startOf("day").toISODate();
      const weekStr = currentDate.startOf("week").toISODate();

      // Get interviews for this day
      const interviewsOnDay = interviews.filter((interview) => {
        const interviewDate = DateTime.fromISO(interview.startAt)
          .startOf("day")
          .toISODate();
        return interviewDate === dateStr;
      });

      // Get interviews for this week
      const interviewsInWeek = interviews.filter((interview) => {
        const interviewWeek = DateTime.fromISO(interview.startAt)
          .startOf("week")
          .toISODate();
        return interviewWeek === weekStr;
      });

      const atDayLimit =
        interviewsOnDay.length > 0 &&
        loadLimits.maxInterviewLoadPerDay &&
        interviewsOnDay.length >= loadLimits.maxInterviewLoadPerDay;

      const atWeekLimit =
        interviewsInWeek.length > 0 &&
        loadLimits.maxInterviewLoadPerWeek &&
        interviewsInWeek.length >= loadLimits.maxInterviewLoadPerWeek;

      if (atDayLimit || atWeekLimit) {
        let title = "";
        if (atDayLimit) {
          title = `At load for day`;
        }
        if (atWeekLimit) {
          title = `At load for week`;
        }

        loadEvents.push({
          id: currentDate.toISO(),
          title,
          startTime: currentDate.startOf("day"),
          endTime: currentDate.plus({ days: 1 }).startOf("day"),
        });
      }

      currentDate = currentDate.plus({ days: 1 });
    }

    return loadEvents;
  }, [interviews, startTime, endTime, loadLimits]);

  return useLoadEventsMapping(events);
}

/**
 * Hook to manage load events for self-scheduling
 * Delegates to appropriate load calculation based on settings
 */
export function useSelfScheduleLoadEvents({
  userMembership,
  startTime,
  endTime,
  bookingLink,
  bookingLinkLoadForm,
}: {
  userMembership:
    | UserMembershipForInterviewerSelfScheduleFragment
    | undefined
    | null;
  startTime: DateTime;
  endTime: DateTime;
  bookingLink?: BookingLinkForSelfScheduleTimesCalendarFragment;
  bookingLinkLoadForm?: BookingLinkLoadBalancingValue;
}): CalendarEventInput[] {
  const accountLoadEvents = useAccountLoadEvents({
    userMembership,
    startTime,
    endTime,
  });

  const bookingLinkLoadEvents = useBookingLinkLoadEvents({
    interviews: bookingLink?.bookingLinkInterviewsForLoadCalculation ?? [],
    startTime,
    endTime,
    loadLimits: {
      maxInterviewLoadPerDay:
        bookingLinkLoadForm?.maxInterviewLoadPerDay ?? null,
      maxInterviewLoadPerWeek:
        bookingLinkLoadForm?.maxInterviewLoadPerWeek ?? null,
    },
  });

  return useMemo(() => {
    if (!bookingLinkLoadForm?.loadType) {
      // If no interviewer load type, use account load events as this is a self schedule
      return accountLoadEvents;
    }

    switch (bookingLinkLoadForm?.loadType) {
      case BookingLinkInterviewerLoadType.INTERVIEWER_DEFAULTS:
        return accountLoadEvents;
      case BookingLinkInterviewerLoadType.LINK_SPECIFIC:
        return bookingLinkLoadEvents;
      default:
        return [];
    }
  }, [bookingLinkLoadForm?.loadType, accountLoadEvents, bookingLinkLoadEvents]);
}
