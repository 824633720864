import { Link } from "@resource/atlas/link/Link";
import {
  UserOrPoolSelect,
  UserOrPoolSelectProps,
} from "client/components/generic/select/UserOrPoolSelect";
import { formatEntity } from "shared/constants/entities";

import { LINK_TO_INTERVIEWER_REQUIREMENTS_SUPPORT_ARTICLE } from "./EditIndividualInterviewRequirementForm";

export function NoInterviewerRequirementsEmptyState(
  props: UserOrPoolSelectProps
) {
  return (
    <div className="text-body-md border-dashed border-light-gray-700 rounded-md border p-4 flex flex-col gap-3 items-center">
      <div className="flex flex-col gap-1 items-center">
        <p className="text-body-md-heavy text-dark">
          No {formatEntity("interviewer slot", { plural: true })}
        </p>
        <p className="text-subtle">
          Assign individual interviewers or an interview pool.{" "}
          <Link
            href={LINK_TO_INTERVIEWER_REQUIREMENTS_SUPPORT_ARTICLE}
            target="blank"
          >
            Learn more
          </Link>
        </p>
      </div>
      <UserOrPoolSelect {...props} />
    </div>
  );
}
