import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { View, ViewProps } from "@resource/atlas/view/View";
import clsx from "clsx";

// TODO: Rename to `ViewLoading`
export function DialogLoading({
  isFullscreen,
  ...props
}: ViewProps & {
  isFullscreen?: boolean;
}) {
  return (
    <View {...props}>
      <div
        className={clsx(
          "flex min-h-[12rem] w-full justify-center items-center",
          {
            "h-full": isFullscreen,
          }
        )}
      >
        <LoadingIndicator />
      </div>
    </View>
  );
}
