import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { getDurationDisplayText } from "client/components/time/utils";
import clsx from "clsx";
import { useMemo } from "react";
import { TimeConfig } from "shared/self-schedule/types";
import { mapTimeConfigToMinutes } from "shared/self-schedule/utils";

type EditBufferTimeProps = {
  postMeetingBuffer: TimeConfig;
  preMeetingBuffer: TimeConfig;
  onChangePreMeetingBuffer: (buffer: TimeConfig) => unknown;
  onChangePostMeetingBuffer: (buffer: TimeConfig) => unknown;
  className?: string;
};

export function EditBufferTime({
  postMeetingBuffer,
  preMeetingBuffer,
  onChangePreMeetingBuffer,
  onChangePostMeetingBuffer,
  className,
}: EditBufferTimeProps) {
  const preMeetingAsMinutes = useMemo(() => {
    return mapTimeConfigToMinutes(preMeetingBuffer);
  }, [preMeetingBuffer]);
  const postMeetingAsMinutes = useMemo(() => {
    return mapTimeConfigToMinutes(postMeetingBuffer);
  }, [postMeetingBuffer]);

  return (
    <FormGroup
      className={className}
      label="Buffer time"
      subText="Set a time before and after meetings."
    >
      <div className="flex flex-row gap-1">
        <div className="w-[50%]">
          <BufferTimeSelect
            label="Before"
            currentDuration={preMeetingAsMinutes}
            onSelectDuration={(duration) => {
              onChangePreMeetingBuffer({
                unit: "minutes",
                value: duration,
              });
            }}
          />
        </div>
        <div className="w-[50%]">
          <BufferTimeSelect
            label="After"
            currentDuration={postMeetingAsMinutes}
            onSelectDuration={(duration) => {
              onChangePostMeetingBuffer({
                unit: "minutes",
                value: duration,
              });
            }}
          />
        </div>
      </div>
    </FormGroup>
  );
}

const TIME_OPTIONS = [0, 5, 10, 15, 30, 45, 60];

type BufferTimeSelectProps = {
  currentDuration: number;
  onSelectDuration: (duration: number) => unknown;
  className?: string;
  label?: string;
};

function BufferTimeSelect({
  currentDuration,
  onSelectDuration,
  className,
  label,
}: BufferTimeSelectProps) {
  const durationMenuItems = useMenuItems(
    (i) => {
      return TIME_OPTIONS.map((duration) => {
        return i.item({
          children: getDurationDisplayText(duration),
          value: getDurationDisplayText(duration),
          onSelect: () => {
            onSelectDuration(duration);
          },
        });
      });
    },
    [onSelectDuration]
  );

  return (
    <Menu.Root sameWidth>
      <Menu.Trigger>
        <div
          className={clsx(
            "flex justify-between w-full bg-light-gray-500 rounded-md px-2 py-[10px] text-body-md cursor-pointer",
            className
          )}
        >
          <span>
            {label && <span className="text-subtle">{label}: </span>}
            {getDurationDisplayText(currentDuration)}
          </span>
          <Icon content={atlasChevronDown} />
        </div>
      </Menu.Trigger>
      <Menu.Content items={durationMenuItems} />
    </Menu.Root>
  );
}
