import { useCallback, useMemo, useRef, useState } from "react";

import { CalendarEvent } from "../../utils/types";
import { Event } from "./Event";

/**
 * Renders a group of calendar events that overlap in time.
 */
export function EventGroup({
  eventGroup,
  calendarId,
  calendarGroupIdx,
  hideEventTime,
}: {
  eventGroup: CalendarEvent[];
  calendarId?: string;
  calendarGroupIdx?: number;
  hideEventTime?: boolean;
}) {
  const eventGroupIds = useMemo(
    () => eventGroup.map((e) => e.id),
    [eventGroup]
  );
  const { isHoveringGroup, eventProps } = useGroupHover();

  return (
    <>
      {eventGroup.map((e, idx) => {
        return (
          <Event
            event={e}
            key={`${e.id}-${e.calendarId}-${e.startTime.toISO()}`}
            calendarId={calendarId}
            calendarGroupIdx={calendarGroupIdx}
            hideEventTime={hideEventTime}
            eventGroupIds={eventGroupIds}
            eventGroupIdx={idx}
            eventProps={eventProps}
            isHovering={isHoveringGroup}
          />
        );
      })}
    </>
  );
}

const DEBOUNCED_HOVER_TIMEOUT = 350;

/**
 * Custom hook to manage hovering state for a group of events.
 * We want to know when we are hovering an event in a group and when we move from one event in the group to another.
 * We set a debounced timeout to give some buffer to accidentally entering or leaving an event for a split second.
 */
function useGroupHover() {
  const [isHoveringGroup, setIsHoveringGroup] = useState(false);
  const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onMouseEnter = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHoveringGroup(true);
    }, DEBOUNCED_HOVER_TIMEOUT);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
    }
    hoverTimeoutRef.current = setTimeout(() => {
      setIsHoveringGroup(false);
    }, DEBOUNCED_HOVER_TIMEOUT);
  }, []);

  return useMemo(
    () => ({
      isHoveringGroup,
      eventProps: {
        onMouseEnter,
        onMouseLeave,
      },
    }),
    [isHoveringGroup, onMouseEnter, onMouseLeave]
  );
}
