import { Button } from "@resource/atlas/button/Button";
import {
  Dialog,
  DialogProps,
  DialogStore,
  useDialogStore,
} from "@resource/atlas/dialog-v2/Dialog";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { View } from "@resource/atlas/view/View";
import {
  useIsInternal,
  useMappedFakeAvailability,
} from "client/app/internal/(scheduling requests)/state";
import { CurrentCandidateAvailability } from "client/guide-availability/components/CurrentCandidateAvailability";
import { useFlags } from "client/lib/launchdarkly";
import { SchedulingRequestAlgorithmSettings } from "client/scheduling-requirements-configuration/interviewPanelRequirement/SchedulingRequestAlgorithmSettings";
import { useAlgorithmSettingsErrors } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/hooks";
import { mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings } from "client/scheduling-requirements-configuration/interviewPanelRequirement/utils/mapping";
import JobSettingsAlgorithmSettingsDisplay from "client/scheduling-requirements-configuration/JobSettingsAlgorithmSettingsDisplay";
import { useFilteredInterviewRequirements } from "client/utils/interviewRequirements";
import deepDiff from "deep-diff";
import FeatureFlags from "generated/FeatureFlags";
import { gql } from "generated/graphql-codegen";
import {
  AlgorithmRunForAiScenariosSectionFragment,
  GuideForSchedulingAlgorithmDialogFragment,
  SchedulingAlgorithmRunStatus,
  SchedulingRequestForAIScenariosSectionFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";
import useMutation from "utils/useMutation";

import {
  ReRunAlgorithmProps,
  useAIScenarioManager,
} from "../utils/hooks/useAIScenarioManager";
import { useAIScenarios } from "../utils/hooks/useAIScenarios";
import { AIScenarioCard } from "./AIScenarioCard";

gql(`
fragment GuideForSchedulingAlgorithmDialog on Guide {
  id
  currentAvailabilitySubmission {
    id
    ...GuideAvailabilitySubmissionsSubmission
  }
  job {
    id
    displayName
  }
}`);

const UPDATE_ALGORITHM_SETTINGS = gql(`
  mutation UpdateSchedulingRequestTemplateSchedulingPreferences($input: UpdateSchedulingRequestTemplateSchedulingPreferencesInput!) {
    updateSchedulingRequestTemplateSchedulingPreferences(input: $input) {
      success
      code
      message
      schedulingRequestTemplate {
        ...SchedulingRequestTemplateForSettings
      }
    }
  }
`);

type SchedulingRequestSchedulerDialogProps = DialogProps & {
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  onSchedule: (props: SchedulerDialogProps) => void;
};

type SidePanelProps = {
  guide: GuideForSchedulingAlgorithmDialogFragment;
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  algorithmRun: AlgorithmRunForAiScenariosSectionFragment | null;
  reRunAlgorithm: (props: ReRunAlgorithmProps) => void;
};
function SidePanel({
  guide,
  schedulingRequest,
  reRunAlgorithm,
  algorithmRun,
}: SidePanelProps) {
  const {
    [FeatureFlags.SCHEDULING_ALGORITHM_VERSION_V_2]: schedulingAlgorithmVersion,
  } = useFlags();

  const {
    [FeatureFlags.MULTI_DAY_ALGORITHM_SCHEDULING_PREFERENCES]:
      algorithmSettingsAvailable,
  } = useFlags();

  const { interviewRequirements: filteredInterviewRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements:
        schedulingRequest.interviewPanelRequirement.interviewRequirements,
    });

  const showConfig =
    algorithmSettingsAvailable &&
    // If there's only one interview, the only option is 1 interview on 1 day.
    filteredInterviewRequirements.length > 1 &&
    Number(schedulingAlgorithmVersion) >= 5;

  return (
    <div className="w-[24rem] shrink-0 space-y-4 px-4 py-6 overflow-y-auto h-full">
      <div className="space-y-4">
        <div className="space-y-2">
          <h2 className="text-h4">Scheduler AI settings</h2>
          <p className="text-body-sm text-subtle">
            If you&apos;re having trouble finding sufficient options, refine the
            scheduling results.
          </p>
        </div>
        {guide.currentAvailabilitySubmission && (
          <div className="space-y-2">
            <h3 className="text-body-md-heavy">Candidate availability</h3>
            <CurrentCandidateAvailability
              currentSubmission={guide.currentAvailabilitySubmission}
            />
          </div>
        )}
        {showConfig && (
          <div className="space-y-2">
            <AlgorithmConfig
              reRunAlgorithm={reRunAlgorithm}
              schedulingRequest={schedulingRequest}
              algorithmRun={algorithmRun}
            />
          </div>
        )}
      </div>
    </div>
  );
}

type AlgorithmConfigProps = {
  reRunAlgorithm: (props: ReRunAlgorithmProps) => void;
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  algorithmRun: AlgorithmRunForAiScenariosSectionFragment | null;
};

function AlgorithmConfig({
  reRunAlgorithm,
  schedulingRequest,
  algorithmRun,
}: AlgorithmConfigProps) {
  const { loading } = useAIScenarios({
    algorithmRun,
  });

  const { interviewRequirements: filteredRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements:
        schedulingRequest.interviewPanelRequirement.interviewRequirements,
    });

  const [algorithmSettings, setAlgorithmSettings] =
    useState<UserConfigurableAlgorithmSettings>(
      mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
        algorithmSettingsFragment: algorithmRun?.settings ?? null,
        interviewCount: filteredRequirements.length,
      })
    );

  const algorithmSettingsErrors = useAlgorithmSettingsErrors({
    algorithmSettings,
    interviewRequirements:
      schedulingRequest.interviewPanelRequirement.interviewRequirements,
  });

  const disabledTooltipContent = useMemo(() => {
    if (algorithmSettingsErrors) {
      return "Invalid scheduling preferences";
    }
    if (
      !deepDiff(
        algorithmSettings,
        mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
          algorithmSettingsFragment: algorithmRun?.settings ?? null,
          interviewCount: filteredRequirements.length,
        })
      )
    ) {
      return "Change scheduling preferences to see new results";
    }
    return undefined;
  }, [
    algorithmSettingsErrors,
    algorithmSettings,
    algorithmRun?.settings,
    filteredRequirements.length,
  ]);

  return (
    <div className="space-y-4">
      <SchedulingRequestAlgorithmSettings
        algorithmSettings={algorithmSettings}
        onAlgorithmSettingsChange={setAlgorithmSettings}
        interviewRequirements={
          schedulingRequest.interviewPanelRequirement.interviewRequirements
        }
      />
      <Tooltip content={disabledTooltipContent} isInstant>
        <Button
          variant="primary"
          disabled={!!disabledTooltipContent}
          isLoading={loading}
          className="w-full"
          onClick={() =>
            reRunAlgorithm({
              force: true,
              splitAcrossDays: algorithmSettings.numberOfDays,
              limitInterviewsPerDay: algorithmSettings.interviewsPerDayLimit,
            })
          }
        >
          Update results
        </Button>
      </Tooltip>
    </div>
  );
}

type SchedulerDialogProps = {
  algorithmSuggestedScenarioId?: string;
};

type MainPanelProps = {
  algorithmRun?: AlgorithmRunForAiScenariosSectionFragment | null;
  onSchedule: (props: SchedulerDialogProps) => void;
};
function MainPanel({ algorithmRun, onSchedule }: MainPanelProps) {
  const { loading, scenarios, fetchNextPage } = useAIScenarios({
    algorithmRun,
  });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
    // ensure we don't force a refetch when things change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  // TODO: handle this case
  if (!algorithmRun) {
    return <></>;
  }

  const inProgress =
    algorithmRun.status === SchedulingAlgorithmRunStatus.IN_PROGRESS;

  return (
    <div className="flex flex-col px-4 py-6 w-full space-y-4 border-l  border-light-gray-500 overflow-y-auto h-full">
      <div className="flex justify-between">
        <div className="space-y-1">
          <h3 className="text-h4">
            {algorithmRun.scenariosCount} scheduling options found{" "}
            {inProgress && "(in progress)"}
          </h3>
          <p className="text-body-sm text-subtle">
            Results are based on the provided settings.
          </p>
        </div>
        {/* <div>buttons</div> */}
      </div>

      <div className="space-y-6">
        {scenarios.map((scenario, index) => (
          <div
            key={scenario.id}
            ref={scenarios.length - 1 === index ? ref : null}
          >
            <AIScenarioCard
              label={`Option ${index + 1}`}
              scenario={scenario}
              onSchedule={onSchedule}
            />
          </div>
        ))}
        <div className="h-16 w-full flex items-center">
          {(loading || inProgress) && <LoadingIndicator />}
        </div>
      </div>
    </div>
  );
}

function useSaveConfirmationDialog({
  schedulingRequest,
  algorithmRun,
  onSchedule,
}: {
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  algorithmRun: AlgorithmRunForAiScenariosSectionFragment | null;
  onSchedule: (props: SchedulerDialogProps) => void;
}) {
  const saveConfirmationDialogStore = useDialogStore();

  const [schedulerProps, setSchedulerProps] =
    useState<SchedulerDialogProps | null>(null);

  const { interviewRequirements: filteredRequirements } =
    useFilteredInterviewRequirements({
      interviewRequirements:
        schedulingRequest.interviewPanelRequirement.interviewRequirements,
    });

  const templateAlgorithmSettings =
    mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
      algorithmSettingsFragment:
        schedulingRequest.schedulingRequestTemplate?.interviewPanelRequirement
          ?.algorithmSettings ?? null,
      interviewCount: filteredRequirements.length,
    });

  const currentRunAlgorithmSettings =
    mapAndAdjustAlgorithmSettingsFragmentToAlgorithmSettings({
      algorithmSettingsFragment: algorithmRun?.settings ?? null,
      interviewCount: filteredRequirements.length,
    });

  const currentRunAlgorithmSettingsErrors = useAlgorithmSettingsErrors({
    algorithmSettings: currentRunAlgorithmSettings,
    interviewRequirements:
      schedulingRequest.interviewPanelRequirement.interviewRequirements,
  });

  const allowUpdateSchedulingRequestTemplate =
    templateAlgorithmSettings &&
    !currentRunAlgorithmSettingsErrors &&
    !!deepDiff(templateAlgorithmSettings, currentRunAlgorithmSettings);

  const [updateAlgorithmSettingsForSchedulingRequestTemplate] = useMutation(
    UPDATE_ALGORITHM_SETTINGS
  );

  const onSaveTemplateAlgorithmSettingsAndSchedule = useCallback(() => {
    if (
      allowUpdateSchedulingRequestTemplate &&
      // Superfluous checks to satisfy typescript
      schedulerProps &&
      schedulingRequest.schedulingRequestTemplate?.id
    ) {
      updateAlgorithmSettingsForSchedulingRequestTemplate({
        variables: {
          input: {
            id: schedulingRequest.schedulingRequestTemplate.id,
            // Save the algorithm settings from the current run, which matches the option they've selected.
            // There's a slight chance of confusion if the user has updated settings in the sidebar without re-running the algorithm,
            // but choosing an option is a stronger signal of the desired settings.
            algorithmSettings: currentRunAlgorithmSettings,
          },
        },
      });
      onSchedule(schedulerProps);
    }
    saveConfirmationDialogStore.hide();
  }, [
    allowUpdateSchedulingRequestTemplate,
    updateAlgorithmSettingsForSchedulingRequestTemplate,
    schedulingRequest.schedulingRequestTemplate?.id,
    currentRunAlgorithmSettings,
    saveConfirmationDialogStore,
    onSchedule,
    schedulerProps,
  ]);

  const onScheduleWithoutSave = useCallback(() => {
    if (schedulerProps) {
      onSchedule(schedulerProps);
    }
    saveConfirmationDialogStore.hide();
  }, [onSchedule, schedulerProps, saveConfirmationDialogStore]);

  const onConfirmOrSchedule = useCallback(
    (props: SchedulerDialogProps) => {
      if (allowUpdateSchedulingRequestTemplate) {
        setSchedulerProps(props);
        saveConfirmationDialogStore.show();
      } else {
        onSchedule(props);
      }
    },
    [
      onSchedule,
      saveConfirmationDialogStore,
      allowUpdateSchedulingRequestTemplate,
    ]
  );

  const ConfirmationDialog = useMemo(() => {
    return (
      <SaveConfirmationDialog
        dialogStore={saveConfirmationDialogStore}
        onCancel={onScheduleWithoutSave}
        onConfirm={onSaveTemplateAlgorithmSettingsAndSchedule}
        schedulingRequest={schedulingRequest}
        algorithmSettings={currentRunAlgorithmSettings}
      />
    );
  }, [
    saveConfirmationDialogStore,
    onScheduleWithoutSave,
    onSaveTemplateAlgorithmSettingsAndSchedule,
    schedulingRequest,
    currentRunAlgorithmSettings,
  ]);

  return {
    onConfirmOrSchedule,
    ConfirmationDialog,
  };
}

function SaveConfirmationDialog({
  dialogStore,
  onCancel,
  onConfirm,
  schedulingRequest,
  algorithmSettings,
}: {
  dialogStore: DialogStore;
  onCancel: () => void;
  onConfirm: () => void;
  schedulingRequest: SchedulingRequestForAIScenariosSectionFragment;
  algorithmSettings: UserConfigurableAlgorithmSettings;
}) {
  // If no specific preferences are set, don't bother showing that to the user.
  const showAlgorithmSettings =
    algorithmSettings.numberOfDays || algorithmSettings.interviewsPerDayLimit;

  return (
    <Dialog store={dialogStore} size="medium">
      <View
        footer={{
          rightActions: (
            <>
              <Button isGhost onClick={onCancel}>
                <span className="text-body-md-heavy">No, thanks</span>
              </Button>
              <Button variant="primary" onClick={onConfirm}>
                Save changes
              </Button>
            </>
          ),
        }}
      >
        <div className="space-y-4 text-body-md">
          <div className="space-y-2">
            <p className="text-body-md">
              Changes were made to the default scheduling preferences for the{" "}
              <span className="text-body-md-heavy">
                {
                  schedulingRequest.schedulingRequestTemplate?.jobInterviewStage
                    ?.name
                }
              </span>{" "}
              stage of the{" "}
              <span className="text-body-md-heavy">
                {schedulingRequest.guide.job.displayName}
              </span>{" "}
              job.
            </p>
            <p>
              Would you like to save these changes to the job stage settings?
            </p>
          </div>
          {showAlgorithmSettings && (
            <JobSettingsAlgorithmSettingsDisplay
              algorithmSettings={algorithmSettings}
            />
          )}
        </div>
      </View>
    </Dialog>
  );
}

export function AIScenariosDialog({
  schedulingRequest,
  store,
  onSchedule,
}: SchedulingRequestSchedulerDialogProps) {
  const isInternal = useIsInternal();
  const fakeAvailability = useMappedFakeAvailability();

  const { algorithmRun, queueAlgorithmRun } = useAIScenarioManager({
    schedulingRequest,
    isInternal,
    fakeAvailability,
  });

  const { onConfirmOrSchedule, ConfirmationDialog } = useSaveConfirmationDialog(
    {
      schedulingRequest,
      algorithmRun,
      onSchedule,
    }
  );

  return (
    <>
      <Dialog size="large" variant="fullscreen" store={store}>
        <View
          header={{
            title: "Schedule interviews",
          }}
          content={{
            className: "p-0",
          }}
        >
          <div className="flex h-full">
            <SidePanel
              guide={schedulingRequest.guide}
              reRunAlgorithm={queueAlgorithmRun}
              schedulingRequest={schedulingRequest}
              algorithmRun={algorithmRun}
            />
            <MainPanel
              algorithmRun={algorithmRun}
              onSchedule={onConfirmOrSchedule}
            />
          </div>
        </View>
        {/* Confirmation dialog has to be rendered inside the AI scenarios dialog to render properly.  */}
        {ConfirmationDialog}
      </Dialog>
    </>
  );
}
