import { Button as AriaButton } from "@ariakit/react";
import clsx from "clsx";

export type RadioItemProps = {
  label: string | JSX.Element;
  isSelected: boolean;
  disabled?: boolean;
  onClick(): void;
  AdditionalInfoInput?: JSX.Element | null;
};

export function RadioItem({
  label,
  isSelected,
  disabled,
  onClick,
  AdditionalInfoInput,
}: RadioItemProps) {
  return (
    <div>
      <AriaButton
        onClick={onClick}
        className={clsx(
          "flex items-center gap-2 text-body-md",
          disabled && "opacity-50"
        )}
        disabled={disabled}
      >
        <div
          className={clsx(
            "w-5 h-5 rounded-full",
            isSelected
              ? "border-[5px] border-purple-500"
              : "border border-light-gray-700"
          )}
        />
        {label}
      </AriaButton>
      <div className="mt-2 ml-7">{isSelected ? AdditionalInfoInput : null}</div>
    </div>
  );
}
