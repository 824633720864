import { Button } from "@resource/atlas/button/Button";
import { AtlasButtonProps } from "@resource/atlas/button/types";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Link } from "@resource/atlas/link/Link";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { gql } from "apollo-server-core";
import { GuideAuthDialog } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__components/guide-auth/GuideAuthDialog";
import { useGuideAuthContext } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__utils/GuideAuthContext";
import { useCallback, useMemo } from "react";
import useMutation from "utils/useMutation";

const SEND_MAGIC_LINK_MUTATION = gql(`
  mutation SendMagicLinkForGuideMutation($input: SendMagicLinkForGuideInput!) {
    sendMagicLinkForGuide(input: $input) {
      success
      code
      message
    }
  }
`);

export type GuideAuthRequiredButtonProps = AtlasButtonProps & {
  asLink?: boolean;
};

/**
 * Button for candidate actions that require candidate authentication.
 * Will prompt for login if unauthed; but will NOT prompt for missing permissions.
 * This exists for two reasons:
 * so that unauthed candidates can see potential actions and be prompted to auth;
 * and so unauthed org/external users do not accidentally act as the candidate.
 * TODO: Convert to use MultiStepView
 * */
export function GuideAuthRequiredButton({
  onClick,
  asLink,
  children,
  ...buttonProps
}: GuideAuthRequiredButtonProps) {
  const guideAuthDialogStore = useDialogStore();
  const { candidate, isGuideCandidate, isOrgUser, guideId } =
    useGuideAuthContext();

  // This button is sometimes rendered outside of a Guide.
  // But we only care about candidate auth if we are in a Guide.
  const authRequired = guideId && !isGuideCandidate && !isOrgUser;

  const wrappedClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (authRequired) {
        guideAuthDialogStore.show();
      } else {
        onClick?.(e);
      }
    },
    [onClick, authRequired, guideAuthDialogStore]
  );

  const [sendMagicLink] = useMutation(SEND_MAGIC_LINK_MUTATION);
  const onSendMagicLink = async () =>
    guideId
      ? sendMagicLink({
          variables: {
            input: {
              guideId,
              email: candidate?.email ?? "",
            },
          },
        })
      : undefined;

  const disabledTooltipContent = useMemo(() => {
    // eg booking links are not part of a Guide context
    // so candidate will not be present.
    if (!guideId) {
      return undefined;
    }
    if (!candidate) {
      return "We could not find a candidate associated with this interview guide";
    }
    if (!candidate.email) {
      return "We could not find an email address associated with this interview guide";
    }
    return undefined;
  }, [guideId, candidate]);

  return (
    <OptionalTooltip content={disabledTooltipContent} isInstant>
      {asLink ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as="button"
            onClick={wrappedClick}
            disabled={!!disabledTooltipContent}
          >
            {children}
          </Link>
        </>
      ) : (
        <Button
          onClick={wrappedClick}
          disabled={!!disabledTooltipContent}
          {...buttonProps}
        >
          {children}
        </Button>
      )}

      {candidate && candidate.email && (
        <GuideAuthDialog
          candidate={{ ...candidate, email: candidate.email as string }}
          dialog={{
            store: guideAuthDialogStore,
          }}
          onSendMagicLink={onSendMagicLink}
        />
      )}
    </OptionalTooltip>
  );
}
