import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { SelfScheduleDateSelect } from "./SelfScheduleDateSelect";
import { SelfScheduleRequestDetails } from "./SelfScheduleRequestDetails";
import { SelfScheduleTimeSelect } from "./SelfScheduleTimeSelect";
import { SelfScheduleTimezoneSelect } from "./SelfScheduleTimezoneSelect";

export function SelectDateAndTimeContent({
  state,
}: {
  state: SelfScheduleSubmissionState;
}) {
  return (
    <div className="flex flex-row w-full h-full divide-x divide-gray-border">
      <div className="flex w-1/3">
        <SelfScheduleRequestDetails state={state} className="p-6" />
      </div>
      <div className="w-2/3 flex flex-col gap-4 p-6 h-full overflow-y-scroll">
        <div className="text-h4 shrink-0">Select date and time</div>
        <div className="flex flex-row space-x-6 w-full overflow-hidden min-h-[26rem]">
          <div className="min-w-[22.25rem] space-y-6">
            <SelfScheduleDateSelect state={state} />
            <div className="mx-1 -px-1">
              <SelfScheduleTimezoneSelect state={state} className="mb-6" />
            </div>
          </div>
          <SelfScheduleTimeSelect state={state} />
        </div>
      </div>
    </div>
  );
}
