import { Button } from "@resource/atlas/button/Button";
import { AtlasIconData } from "@resource/atlas/icon/types";
import { Tabs } from "@resource/atlas/tabs";
import { useTabsItems } from "@resource/atlas/tabs/use-tabs-items";
import { View } from "@resource/atlas/view/View";
import { useAuthContext } from "auth/context";
import { InterviewList } from "client/components/guide/interviews/InterviewList";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import {
  GuideForInterviewsFragment,
  OrganizationFeaturesEnum,
  ScheduledInterviewForScheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef, useMemo, useState } from "react";
import useQuery from "utils/useQuery";

import { useInternalScheduledInterviewMenuItems } from "../../../../../../components/scheduled-interviews/__hooks/useInternalScheduledInterviewMenuItems";

type Guide = GuideForInterviewsFragment;

type InterviewsHeaderProps = ComponentPropsWithoutRef<"div"> & {
  guide?: Guide;
};

function InterviewsHeader({ guide, ...props }: InterviewsHeaderProps) {
  return (
    <div {...props} className={clsx("space-y-1", props.className)}>
      <div className="text-body-md text-subtle">
        {guide ? (
          <>
            <span className="font-bold">{guide.candidate.name}</span> for{" "}
            <span className="font-bold">{guide.roleName}</span>
          </>
        ) : (
          <>
            <Skeleton className="w-full h-[14px] mb-1" type="text" />
            <Skeleton className="w-2/3 h-[14px]" type="text" />
          </>
        )}
      </div>
      <h1 className="text-h2 mt-2">Interviews</h1>
    </div>
  );
}
gql(`
fragment GuideForInterviews on Guide {
    id
    roleName
    candidate {
      id
      name: displayName
    }
    upcomingInterviews: internalUpcomingInterviews {
      id
      ...ScheduledInterviewForScheduledInterviewCard
    }
    pastInterviews: internalPastInterviews {
      id
      ...ScheduledInterviewForScheduledInterviewCard
    }
  }
`);

const GUIDE_QUERY = gql(`
query GuideForInterviewsQuery(
    $guideId: String!
  ) {
    guideById(guideId: $guideId) {
      ...GuideForInterviews
    }
  }
`);

type NavItemBase = {
  id: string;
  children: string;
  onClick: () => void;
};

function useMenuItemsFactory(
  interviews:
    | ScheduledInterviewForScheduledInterviewCardFragment[]
    | undefined
    | null
) {
  return function useInterviewCardMenuItems(interviewId: string) {
    const scheduledInterview = interviews?.find((i) => i.id === interviewId);

    return useInternalScheduledInterviewMenuItems(scheduledInterview);
  };
}

type InternalGuideScheduledInterviewsProps = {
  guideId: string;
  onSelectInterview: (id: string) => void;
  onBack: () => void;
  backIcon: AtlasIconData;
};
/**
 * Component to display all Guide scheduled interviews in a tab list (upcoming and past)
 * For internal use only (not candidate-facing)
 */
export function InternalGuideScheduledInterviews({
  guideId,
  onSelectInterview,
  onBack,
  backIcon,
}: InternalGuideScheduledInterviewsProps) {
  const [activeTab, setActiveTab] = useState<"upcoming" | "past">("upcoming");
  const { hasFeatureEnabled } = useAuthContext();
  const { data } = useQuery(GUIDE_QUERY, {
    variables: {
      guideId,
    },
  });

  const navItemsBase = useMemo(
    (): Record<string, NavItemBase> => ({
      upcoming: {
        id: "upcoming",
        children: "Upcoming",
        onClick: () => setActiveTab("upcoming"),
      },
      past: {
        id: "past",
        children: "Past",
        onClick: () => setActiveTab("past"),
      },
    }),
    []
  );

  const tabItems = useTabsItems(
    (i) => [
      i.tab({ ...navItemsBase.upcoming, key: navItemsBase.upcoming.id }),
      i.tab({ ...navItemsBase.past, key: navItemsBase.past.id }),
    ],
    [navItemsBase]
  );

  const interviews =
    activeTab === "upcoming"
      ? data?.guideById?.upcomingInterviews
      : data?.guideById?.pastInterviews;

  const menuItemsFactory = useMenuItemsFactory(interviews);

  return (
    <View
      header={{
        title: "Interviews",
        leftActions: (
          <Button isGhost size="small" onClick={onBack} icon={backIcon} />
        ),
      }}
      content={{
        className: "p-0",
      }}
    >
      <div className="flex flex-col h-full overflow-hidden">
        <div className="space-y-6">
          <InterviewsHeader
            guide={data?.guideById ?? undefined}
            className="mt-4 px-4"
          />
          <Tabs.Root selectedId={activeTab}>
            <Tabs.List items={tabItems} className="px-4" />
          </Tabs.Root>
        </div>
        <div className="grow overflow-hidden overflow-y-scroll px-4 py-6">
          <InterviewList
            type={activeTab}
            interviews={interviews}
            menuItemsConfigFactory={
              hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING)
                ? menuItemsFactory
                : undefined
            }
            onSelectInterview={onSelectInterview}
          />
        </div>
      </div>
    </View>
  );
}
