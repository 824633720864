import {
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ElementTransformer,
  HEADING,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  LINK,
  ORDERED_LIST,
  QUOTE,
  STRIKETHROUGH,
  UNORDERED_LIST,
} from "@lexical/markdown";
import {
  $createHorizontalRuleNode,
  $isHorizontalRuleNode,
  HorizontalRuleNode,
} from "@lexical/react/LexicalHorizontalRuleNode";
import type { LexicalNode } from "lexical";

export const HORIZONTAL_RULE: ElementTransformer = {
  dependencies: [HorizontalRuleNode],
  export: (node: LexicalNode) => ($isHorizontalRuleNode(node) ? "---" : null),
  regExp: /^(---|\*\*\*|___)\s?$/,
  replace: (elementNode) => {
    const line = $createHorizontalRuleNode();
    if (elementNode.getNextSibling() != null) {
      // there are sibling nodes present just after the current one
      // so we can simply replace the current node and move the focus to next
      elementNode.replace(line);
    } else {
      // there are no next siblings so we need to make sure that we insert the horizontal rule
      // before the current node and move the focus to current node again
      elementNode.insertBefore(line);
    }
    line.selectNext();
  },
  type: "element",
};

export const TRANSFORMERS = [
  HEADING,
  QUOTE,
  UNORDERED_LIST,
  ORDERED_LIST,
  HORIZONTAL_RULE,

  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  STRIKETHROUGH,

  LINK,
];
