import { NotificationTypeMetaFields } from "../schemas";
import { NotificationType } from "../types";

export type AppNotificationHrefGeneration<T extends NotificationType> = (
  props: NotificationTypeMetaFields<T>
) => string;

export function createAppNotificationHrefGeneration<
  T extends NotificationType
>({
  hrefGenerator,
}: {
  type: T;
  hrefGenerator: AppNotificationHrefGeneration<T>;
}) {
  return hrefGenerator;
}
