export type InterviewConflictData = {
  outsideWorkingHours: boolean;
  hasConflict: boolean;
  hasFlexibleConflict: boolean;
  hasAlreadyInterviewed: boolean;
};

export type InterviewForConflicts = {
  title: string;
  startTime: string;
  endTime: string;
};

/** Determines if an interview should be checked for conflicts: if there's no start or end time, there's no way for it to conflict. */
export function isInterviewForConflicts(i: {
  title: string;
  startTime: string | null;
  endTime: string | null;
}): i is InterviewForConflicts {
  return !!i.startTime && !!i.endTime;
}
