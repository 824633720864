import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { SchedulingRequestWithAutomation } from "shared/automation/types";

export function useIsAutomationEnabledForSchedulingRequest({
  schedulingRequest,
}: {
  schedulingRequest: SchedulingRequestWithAutomation;
}) {
  const { [FeatureFlags.ASSIST_WITH_ARIA_AI]: assistWithAriaAiEnabled } =
    useFlags();

  const automation = schedulingRequest?.schedulingTaskAssistantAutomation;

  return Boolean(
    assistWithAriaAiEnabled && automation?.id && automation.isEnabled
  );
}
