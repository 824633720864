import { useCallback, useMemo } from "react";

import {
  useCalendarTimezone,
  useCalendarTwentyForHourFormat,
  useDayViewType,
  useIncludeWeekends,
  useSetCalendarTimezone,
  useSetDayViewType,
} from "../../../hooks/settings";

export function useCalendarSettingsFormState() {
  const [includeWeekends, setIncludeWeekends] = useIncludeWeekends();
  const timezone = useCalendarTimezone();
  const setTimezone = useSetCalendarTimezone();
  const toggleIncludeWeekends = useCallback(() => {
    setIncludeWeekends((prev) => !prev);
  }, [setIncludeWeekends]);
  const [twentyFourHourFormat, setTwentyFourHourFormat] =
    useCalendarTwentyForHourFormat();
  const toggleTwentyFourHourFormat = useCallback(() => {
    setTwentyFourHourFormat((prev) => !prev);
  }, [setTwentyFourHourFormat]);
  const dayViewType = useDayViewType();
  const setDayViewType = useSetDayViewType();

  return useMemo(
    () => ({
      includeWeekends,
      setIncludeWeekends,
      toggleIncludeWeekends,
      twentyFourHourFormat,
      setTwentyFourHourFormat,
      toggleTwentyFourHourFormat,
      timezone,
      setTimezone,
      dayViewType,
      setDayViewType,
    }),
    [
      includeWeekends,
      setIncludeWeekends,
      toggleIncludeWeekends,
      twentyFourHourFormat,
      setTwentyFourHourFormat,
      toggleTwentyFourHourFormat,
      timezone,
      setTimezone,
      dayViewType,
      setDayViewType,
    ]
  );
}

export type CalendarSettingsFormState = ReturnType<
  typeof useCalendarSettingsFormState
>;
