import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import type {
  AtlasSelectContentProps,
  AtlasSelectRootProps,
  AtlasSelectTriggerProps,
} from "@resource/atlas/select/types";
import { SelectV2 as Select } from "@resource/atlas/select-v2";
import { forwardRef, ReactNode } from "react";
import {
  displayTimezone,
  ReadableTimezoneLabelType,
} from "shared/utils/timezones";

import { useTimezoneSelectionItemsForV2Select } from "./useTimezoneSelectionItems";

export type TimezoneSelectRootProps = Omit<AtlasSelectRootProps, "children">;

export type TimezoneSelectContentProps = Omit<
  AtlasSelectContentProps,
  "items"
> & {
  sameWidth?: boolean;
};

export type TimezoneSelectTriggerProps = AtlasSelectTriggerProps & {
  label?: ReactNode;
};

const TimezoneSelectTrigger = forwardRef<
  HTMLButtonElement,
  TimezoneSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref}>
      {props.label}
    </SelectTrigger>
  );
});

type Props = {
  onClick: (timezone: string) => void;
  value: string | undefined;
  rootProps?: TimezoneSelectRootProps;
  contentProps?: TimezoneSelectContentProps;
  triggerProps?: TimezoneSelectTriggerProps;
  labelType?: ReadableTimezoneLabelType;
  triggerLabelType?: ReadableTimezoneLabelType;
};

export function TimezoneSelectorV2({
  onClick,
  value,
  rootProps = {},
  contentProps = {},
  triggerProps = {},
  labelType = "default",
  triggerLabelType = "default",
}: Props) {
  const items = useTimezoneSelectionItemsForV2Select(onClick, labelType);

  const label = triggerProps.label ?? (
    <span>
      {value
        ? displayTimezone(value, triggerLabelType ?? labelType, true) ?? ""
        : "Select timezone"}
    </span>
  );

  return (
    <Select.Root value={value} placement={rootProps.placement}>
      <Select.Trigger
        className={triggerProps.className}
        isGhost={triggerProps.isGhost}
        size={triggerProps.size}
      >
        <TimezoneSelectTrigger {...triggerProps} label={label} />
      </Select.Trigger>
      <Select.Content
        items={items}
        searchable
        className={contentProps.className}
        sameWidth={contentProps.sameWidth}
        portal={contentProps.portal}
        header={contentProps.header}
        isVirtual
      />
    </Select.Root>
  );
}
