import {
  BuildingForConferenceRoomSelectFragment,
  ConferenceRoomForSelectFragment,
} from "generated/graphql-codegen/graphql";
import type { ConferenceRoomSettings } from "shared/guide-scheduler/conference-rooms/types";
import { ConferenceRoomSettingsSchema } from "shared/guide-scheduler/conference-rooms/types";
import { z } from "zod";

const UserValidationMessages = {
  noConferenceRoomSelected: "A conference room must be selected.",
};

export type FetchedBuilding = BuildingForConferenceRoomSelectFragment;

export type FetchedConferenceRoom = ConferenceRoomForSelectFragment;

export type FetchedConferenceRoomEvent =
  FetchedConferenceRoom["events"][number];

export type Building = {
  id: FetchedBuilding["id"];
  name: FetchedBuilding["name"];
};

export type ConferenceRoomAvailability =
  | "available"
  | "unavailable"
  | "unknown";

export type ConferenceRoom = {
  id: string;
  building: Building | null;
  capacity: number | null;
  floorName: string | null;
  hasZoom: boolean;
  name: string;
  availability?: ConferenceRoomAvailability;
};

export const ConferenceRoomForFormSchema = z.object(
  {
    id: z.string(),
    name: z.string(),
    // We only store ID and name in the DB, the rest comes from Google
    // We'll store/use this if we have it otherwise should be okay with just ID and name
    capacity: z.number().nullable().optional(),
    hasZoom: z.boolean().optional(),
    buildingId: z.string().nullable().optional(),
    floorName: z.string().nullable().optional(),
  },
  {
    required_error: UserValidationMessages.noConferenceRoomSelected,
    invalid_type_error: UserValidationMessages.noConferenceRoomSelected,
  }
);

export type ConferenceRoomForForm = z.infer<typeof ConferenceRoomForFormSchema>;

export const ConferenceRoomSlotSchema = z.object({
  conferenceRoom: ConferenceRoomForFormSchema.nullable(),
  settings: ConferenceRoomSettingsSchema.nullable(),
});

export type ConferenceRoomSlot = z.infer<typeof ConferenceRoomSlotSchema>;

export const StagedConferenceRoomSlotSchema = ConferenceRoomSlotSchema.extend({
  conferenceRoom: ConferenceRoomForFormSchema,
});

export type StagedConferenceRoomSlot = z.infer<
  typeof StagedConferenceRoomSlotSchema
>;

export { ConferenceRoomSettings, ConferenceRoomSettingsSchema };

export function isStagedConferenceRoomSlot(
  conferenceRoomSlot: ConferenceRoomSlot
): conferenceRoomSlot is StagedConferenceRoomSlot {
  return conferenceRoomSlot.conferenceRoom !== null;
}
