import { renderFileNode } from "@resource/atlas/content-editor/file";
import { renderImageNode } from "@resource/atlas/content-editor/image";
import {
  renderLinkCardNode,
  renderLinkCardsCollectionNode,
} from "@resource/atlas/content-editor/link-card";
import {
  baseNodeRenderers,
  createNodeRenderers,
} from "@resource/atlas/content-editor/renderer";
import { renderRichQuoteNode } from "@resource/atlas/content-editor/rich-quote";
import { renderVideoNode } from "@resource/atlas/content-editor/video";
import { renderGuideAvailabilityRequestNode } from "client/guide-availability/rich-blocks/guide-availability-request/__lib/node-renderer";
import { renderGuideAvailabilitySubmissionNode } from "client/guide-availability/rich-blocks/guide-availability-submission/__lib/node-renderer";
import { renderShareAvailabilityNode } from "client/guide-availability/rich-blocks/legacy-share-availability/node-renderer";
import { renderGifNode } from "client/guide-content/rich-blocks/gif";
import { renderInterviewsNode } from "client/guide-content/rich-blocks/interviews/__lib/node-renderer";
import { renderSelfScheduleRequestNode } from "client/self-schedule/rich-blocks/self-schedule-request/node-renderer";
import { RICH_BLOCK_NAME as GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME } from "shared/guide-availability/rich-blocks/guide-availability-submission";
import { RICH_BLOCK_NAME as SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME } from "shared/self-schedule/rich-blocks/self-schedule-request";

export const guideBaseNodeRenderers = createNodeRenderers({
  ...baseNodeRenderers,
  image: renderImageNode,
  file: renderFileNode,
  video: renderVideoNode,
  "rich-quote": renderRichQuoteNode,
  "link-card": renderLinkCardNode,
  "link-cards-collection": renderLinkCardsCollectionNode,
  "request-availability": renderGuideAvailabilityRequestNode,
  interviews: renderInterviewsNode,
  gif: renderGifNode,
  // Legacy renderer for share-availability block
  "share-availability": renderShareAvailabilityNode,
  [GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME]:
    renderGuideAvailabilitySubmissionNode,
  [SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME]: renderSelfScheduleRequestNode,
});
