import { useCalendarTimezone } from "client/components/calendar-v2/hooks/settings";
import { SidePanelPushIn } from "client/components/generic/layout/SidePanelPushIn";
import { useSyncUserMembershipChangesWithState } from "client/components/interviewer-slots/hooks/interviewer-scheduling-data";
import { UserMembershipSelectWrapperProps } from "client/components/interviewer-slots/utils/types";
import UserMembershipWithSchedulingDataSelect from "client/components/interviewer-slots/wrappers/UserMembershipWithSchedulingDataSelect";
import { UpsertScheduledInterviewForm } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm";
import {
  UpsertScheduledInterviewOnChange,
  useUpsertScheduledInterviewFormState,
} from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/__hooks/useUpsertScheduledInterviewFormState";
import FeatureFlags from "generated/FeatureFlags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback } from "react";

import { SchedulerEditableScheduledInterview } from "../../../utils/types";
import { useSchedulerCalendarColors } from "../../calendar/hooks/useSchedulerCalendarColors";
import { useSyncSchedulerInterviewersForSlotCalculations } from "../../hooks/interviewer-slots";
import {
  useOriginalInterview,
  useSelectedInterview,
  useSetInterviews,
} from "../../hooks/interviews";
import { useScheduledInterviewGroupSettings } from "../../hooks/scheduledInterviewGroupSettings";
import { useGroupSettingsInitialized } from "../../hooks/useSetGroupSettingsForUnscheduledInterviews";

export function SchedulerRightSidePanel({ isOpen }: { isOpen: boolean }) {
  const selectedInterview = useSelectedInterview();

  return (
    <div className="border-gray-border border-l py-4 px-3 z-10 overflow-y-auto shrink-0 b-10">
      <SidePanelPushIn
        className="space-y-2 w-full mb-10"
        isOpen={!!selectedInterview && isOpen}
        width="20rem"
        duration={300}
        placement="right"
        key={selectedInterview?.id}
      >
        {selectedInterview ? (
          <EditInterviewWithForm selectedInterview={selectedInterview} />
        ) : null}
      </SidePanelPushIn>
    </div>
  );
}

function EditInterviewWithForm({
  selectedInterview,
}: {
  selectedInterview: SchedulerEditableScheduledInterview;
}) {
  const scheduledInterviewGroupSettings = useScheduledInterviewGroupSettings();
  const originalInterview = useOriginalInterview(selectedInterview.id);
  const setInterviews = useSetInterviews();
  const calendarColors = useSchedulerCalendarColors();
  const timezone = useCalendarTimezone();
  const groupSettingsInitialized = useGroupSettingsInitialized();

  const syncChangesWithState: UpsertScheduledInterviewOnChange = useCallback(
    ({ updatedInterview }) => {
      setInterviews((prev) => {
        return prev.map((interview) => {
          if (interview.id === updatedInterview.id) {
            return {
              ...interview,
              ...updatedInterview,
            };
          }

          return interview;
        });
      });
    },
    [setInterviews]
  );

  const { formDataForSlotCalculations } =
    useSyncSchedulerInterviewersForSlotCalculations();
  const syncUserMembershipsWithState = useSyncUserMembershipChangesWithState();

  const {
    [FeatureFlags.USE_EXTERNAL_INTERVIEWER_ALGORITHM_V_2]:
      allowExternalInterviewerAlgorithm,
  } = useFlags();

  const formState = useUpsertScheduledInterviewFormState({
    scheduledInterview: selectedInterview,
    originalInterview,
    calendarColors,
    timezone,
    onChange: syncChangesWithState,
    size: "small",
    scheduledInterviewGroupSettings:
      scheduledInterviewGroupSettings ?? undefined,
    onUserMembershipChange: syncUserMembershipsWithState,
    hasGroupSettingsBeenInitialized: groupSettingsInitialized,
    disabledFormFeaturesOverrides: {
      hideExternalInterviewerAlgorithm: !allowExternalInterviewerAlgorithm,
    },
  });

  const UserMembershipSelect = useCallback(
    (selectProps: UserMembershipSelectWrapperProps) => (
      <UserMembershipWithSchedulingDataSelect
        {...selectProps}
        formDataForScheduling={formDataForSlotCalculations}
      />
    ),
    [formDataForSlotCalculations]
  );

  return (
    <UpsertScheduledInterviewForm
      state={formState}
      UserMembershipSelect={UserMembershipSelect}
    />
  );
}
