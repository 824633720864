/* eslint-disable import/prefer-default-export */
import { createElement, Fragment, useMemo } from "react";

import { useEvent } from "../../../../__utils/react";
import type { PartialBy } from "../../../../__utils/types";
import { InsertOption, InsertOptionContext, InsertOptionData } from "./types";

type UseInsertOptionsOptions = {
  target?: keyof InsertOptionData["targets"];
};

export function useInsertOptions(
  insertOptions: InsertOption[],
  context: PartialBy<InsertOptionContext, "getAnchorRect">,
  { target }: UseInsertOptionsOptions = {}
) {
  const getAnchorRect = useEvent(() => {
    if (!context.getAnchorRect)
      throw new Error(
        `No getAnchorRect provided for target "${context.fullTarget}"`
      );
    return context.getAnchorRect();
  });
  const resolvedContext = useMemo(
    () => ({ ...context, getAnchorRect }),
    [context, getAnchorRect]
  );

  const resolvedOptions = insertOptions.map((useInsertOption) =>
    typeof useInsertOption === "function"
      ? // the number of options is constant, so this is safe
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useInsertOption(resolvedContext)
      : useInsertOption
  );

  const filteredOptions = target
    ? resolvedOptions.filter(({ targets }) => target in targets)
    : resolvedOptions;

  const memoizedOptions = useMemo(
    () =>
      filteredOptions.map(({ render, ...option }) => ({
        ...option,
        render: render
          ? createElement(Fragment, { key: option.key }, render)
          : undefined,
      })),
    // the number of options is constant, so this is safe
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...filteredOptions]
  );

  return memoizedOptions;
}
