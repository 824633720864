import { useFetchedInterviewerCalendarEvents } from "client/calendar-events/hooks/useFetchedInterviewerCalendarEvents";
import useStableTransformData from "client/hooks/useStableTransformData";
import { gql } from "generated/graphql-codegen";
import {
  InterviewerPoolForSchedulingDataFragment,
  UserMembershipForSchedulingDataFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import useDebouncedValue from "react-hooks/useDebouncedValue";
import useQuery from "utils/useQuery";

import { getConflictDataForUserMembership } from "../conflicts/utils/helpers";
import { isInterviewForConflicts } from "../conflicts/utils/types";
import { getLoadDataForUserMembership } from "../load/utils/helpers";
import { mapUserMembershipToTrainingProgress } from "../training/utils/mapping";
import { mapUserMembershipToInterviewerSlotUserMembership } from "../utils/mapping";
import {
  InterviewerSlotTagFilter,
  InterviewForSlotCalculations,
  UserMembershipWithSchedulingData,
} from "../utils/types";

const FETCH_USER_MEMBERSHIPS_WITH_LOAD_AND_CONFLICTS_FOR_SELECT_QUERY = gql(`
  query FetchUserMembershipsWithLoadAndConflictForSelect(
    $excludeUserMembershipIds: [String!],
    $poolId: ID,
    $tagFilters: [TagFilterForUserMembershipSelectInput!],
    $guideId: ID!,
    $isQualified: Boolean,
    $search: String,
    $date: String!,
    $includeDayLoad: Boolean!,
    $includePool: Boolean!
  )
  {
    userMembershipsOrderedByLoadV2(
      excludeUserMembershipIds: $excludeUserMembershipIds
      poolId: $poolId,
      tagFilters: $tagFilters,
      isQualified: $isQualified,
      search: $search,
      date: $date,
      includeDayLoad: $includeDayLoad,
    ) {
      ...UserMembershipForSchedulingData
    }
  }
`);

const FETCH_POOL_LOAD_DATA_FOR_SELECT_QUERY = gql(`
  query FetchPoolLoadDataForSelect(
    $poolId: ID!,
  )
  {
    currentOrganization {
      id
      interviewerPoolById(id: $poolId) {
        ...InterviewerPoolForSchedulingData
      }
    }
  }
`);

type UseSelectUserMembershipsWithSchedulingDataParams = {
  excludeUserMembershipIds?: string[] | null;
  poolId?: string;
  tagFilters?: InterviewerSlotTagFilter[] | null;
  guideId: string;
  isQualified?: boolean;
  search: string;
  date: string | null;
  includeDayLoad: boolean;
  skip?: boolean;
  selectedInterview: InterviewForSlotCalculations | null;
  interviews: InterviewForSlotCalculations[];
  originalInterviews: InterviewForSlotCalculations[];
};

/**
 * Fetch user memberships sorted by load and conflict data for select menus.
 */
export function useSelectUserMembershipsWithSchedulingData({
  excludeUserMembershipIds,
  poolId,
  tagFilters,
  guideId,
  isQualified,
  search,
  date,
  includeDayLoad,
  skip = false,
  selectedInterview,
  interviews,
  originalInterviews,
}: UseSelectUserMembershipsWithSchedulingDataParams) {
  const parsedDateWithFallback = useMemo(() => {
    if (date) {
      return DateTime.fromISO(date).startOf("day").toISO();
    }

    // The only time this should ever happen right now is an interview with no startTime
    // outside of the scheduler. Should never happen since we don't support, but if
    // we do we'll want to adjust this logic
    return DateTime.now().startOf("day").toISO();
  }, [date]);

  const tagFiltersForQuery = useMemo(() => {
    return (
      tagFilters
        ?.map((t) => ({
          type: t.type,
          tagIds: t.tags.map((t) => t.id),
        }))
        ?.filter((t) => t.tagIds.length > 0) ?? []
    );
  }, [tagFilters]);

  const { data, loading: loadingUserMemberships } = useQuery(
    FETCH_USER_MEMBERSHIPS_WITH_LOAD_AND_CONFLICTS_FOR_SELECT_QUERY,
    {
      variables: {
        guideId,
        excludeUserMembershipIds: excludeUserMembershipIds ?? null,
        poolId: poolId ?? null,
        tagFilters: tagFiltersForQuery ?? null,
        isQualified: isQualified ?? null,
        search: search.trim() === "" ? null : search,
        date: parsedDateWithFallback,
        includeDayLoad,
        includePool: !!poolId,
      },
      onCompleted: ({ userMembershipsOrderedByLoadV2 }) => {
        setDebouncedUserIdsForCalendar(
          userMembershipsOrderedByLoadV2.map(
            (userMembership) => userMembership.id
          )
        );
      },
      skip,
    }
  );

  const {
    debouncedValue: debouncedUserIdsForCalendar,
    setValue: setDebouncedUserIdsForCalendar,
  } = useDebouncedValue(
    data?.userMembershipsOrderedByLoadV2.map(
      (userMembership) => userMembership.id
    ) ?? [],
    500
  );

  const { data: poolData, loading: loadingPoolData } = useQuery(
    FETCH_POOL_LOAD_DATA_FOR_SELECT_QUERY,
    {
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        poolId: poolId!,
      },
      skip: skip || !poolId,
    }
  );

  const loadingData = loadingUserMemberships || loadingPoolData;

  const { interviewers, loadingCalendar } =
    useMapUserMembershipFragmentsForScheduling({
      interviews,
      originalInterviews,
      selectedInterview,
      date,
      includeSelectedInterviewForLoad: false, // we never use the selectedInterview in dropdowns
      userMemberships: data?.userMembershipsOrderedByLoadV2,
      poolData: poolData?.currentOrganization?.interviewerPoolById,
      userMembershipIdsForCalendar: debouncedUserIdsForCalendar,
      loadingData,
      skip,
      isQualified,
    });

  return {
    interviewers,
    loadingData,
    loadingCalendar,
  };
}

type UseMapUserMembershipFragmentsForSchedulingParams = {
  interviews: InterviewForSlotCalculations[];
  originalInterviews: InterviewForSlotCalculations[];
  selectedInterview: InterviewForSlotCalculations | null;
  date: string | null;
  includeSelectedInterviewForLoad: boolean;
  userMemberships?: UserMembershipForSchedulingDataFragment[];
  poolData?: InterviewerPoolForSchedulingDataFragment;
  userMembershipIdsForCalendar: string[];
  loadingData: boolean;
  skip: boolean;
  isQualified?: boolean;
};

/**
 * Map fragment type to fully hydrated UserMemberships with loadData and conflictData. Fetches calendar events and calculates load.
 */
function useMapUserMembershipFragmentsForScheduling({
  interviews,
  originalInterviews,
  selectedInterview,
  date,
  includeSelectedInterviewForLoad,
  userMemberships,
  poolData,
  userMembershipIdsForCalendar,
  loadingData,
  skip = false,
  isQualified,
}: UseMapUserMembershipFragmentsForSchedulingParams) {
  const startOfDay = selectedInterview?.startTime
    ? DateTime.fromISO(selectedInterview.startTime).startOf("day")
    : null;
  const endOfDay = selectedInterview?.endTime
    ? DateTime.fromISO(selectedInterview.endTime).endOf("day")
    : null;

  // TODO: In the future, we may need a way to fetch calendar events in the caller. But fetching calendar events requires
  // userMembershipIds which are returned from this function.
  const { calendarEvents, loading: loadingCalendar } =
    useFetchedInterviewerCalendarEvents({
      userMembershipIds: userMembershipIdsForCalendar,
      startTime: startOfDay,
      endTime: endOfDay,
      // For select menus, don't fetch calendar events when the interview has not been placed!
      skip: skip || startOfDay === null || endOfDay === null,
    });

  const transformUserMemberships = useCallback(
    (
      um: UserMembershipForSchedulingDataFragment[]
    ): UserMembershipWithSchedulingData[] => {
      return um.map((userMembership) => {
        return {
          ...mapUserMembershipToInterviewerSlotUserMembership(userMembership),
          // TODO: Split these up so we don't need to recalculate load when calendarEvents finish loading.
          // If no date, we can't calculate load
          loadData: date
            ? getLoadDataForUserMembership({
                userMembership,
                date,
                selectedInterview,
                interviews,
                originalInterviews,
                poolData,
                includeSelectedInterview: includeSelectedInterviewForLoad,
              })
            : undefined,
          // If no selectedInterview, we can't calculate conflicts
          conflictData:
            selectedInterview && isInterviewForConflicts(selectedInterview)
              ? getConflictDataForUserMembership({
                  userMembership,
                  selectedInterview,
                  calendarEvents: calendarEvents[userMembership?.id],
                })
              : undefined,
          trainingData:
            !isQualified && poolData
              ? mapUserMembershipToTrainingProgress({
                  interviewerPool: poolData,
                  userMembership,
                })
              : undefined,
        };
      });
    },
    [
      date,
      selectedInterview,
      interviews,
      originalInterviews,
      poolData,
      includeSelectedInterviewForLoad,
      calendarEvents,
      isQualified,
    ]
  );

  const { data: interviewers } = useStableTransformData({
    data: userMemberships,
    transform: transformUserMemberships,
    loading: loadingData,
  });

  return { interviewers, loadingCalendar };
}
