import TextField from "@resource/atlas/textfield/TextField";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { GenerationSlotAction } from "client/components/link-generation/hooks/GenerationSlotAction";
import { GenerationSlotSubtext } from "client/components/link-generation/hooks/GenerationSlotSubtext";
import { useDebouncedOnChange } from "client/hooks/useDebouncedOnChange";
import { useCallback, useMemo } from "react";

import { isGenerationService } from "../utils/helpers";
import {
  CollaborativeCodingServiceType,
  CollaborativeCodingSlot,
} from "../utils/types";
import { CollaborativeCodingSelect } from "./CollaborativeCodingSelect";

export type EditCollaborativeCodingSlotProps = {
  collaborativeCodingSlot: CollaborativeCodingSlot | null;
  originalCollaborativeCodingSlot?: CollaborativeCodingSlot | null;
  onChangeCollaborativeCodingSlot: (
    collaborativeCodingSlot: CollaborativeCodingSlot | null
  ) => void;
};

/**
 * Edit a collaborative coding slot with generation settings and requirements.
 * Could manage generation settings or set a value directly.
 * Currently only used during internal scheduling.
 */
export function EditCollaborativeCodingSlot({
  collaborativeCodingSlot,
  originalCollaborativeCodingSlot,
  onChangeCollaborativeCodingSlot: passedOnChangeCollaborativeCodingSlot,
}: EditCollaborativeCodingSlotProps) {
  const {
    service,
    existingCollaborativeCoding,
    editingCollaborativeCoding,
    generationSettings,
    requirements,
  } = collaborativeCodingSlot || {};

  const hasExistingGeneratedURL = useMemo(() => {
    if (collaborativeCodingSlot) {
      if (
        service &&
        isGenerationService(service) &&
        existingCollaborativeCoding?.value
      ) {
        return true;
      }
    }

    return false;
  }, [collaborativeCodingSlot, service, existingCollaborativeCoding]);

  const onChangeCollaborativeCodingSlot = useCallback(
    (passedCollaborativeCoding: CollaborativeCodingSlot | null) => {
      const isChangingService = passedCollaborativeCoding?.service !== service;
      const isSettingBackToOriginalService =
        passedCollaborativeCoding?.service ===
        originalCollaborativeCodingSlot?.service;

      if (!passedCollaborativeCoding) {
        passedOnChangeCollaborativeCodingSlot({
          ...collaborativeCodingSlot,
          generationSettings: null,
          service: null,
        });
        return;
      }

      if (isChangingService && isSettingBackToOriginalService) {
        // If they have a link already and change away from the service and back,
        // restore the full slot back to the original settings
        passedOnChangeCollaborativeCodingSlot(
          originalCollaborativeCodingSlot ?? null
        );
      } else if (
        isChangingService &&
        passedCollaborativeCoding.service &&
        isGenerationService(passedCollaborativeCoding.service)
      ) {
        // If they change services to a new generation service, automatically regenerate the link
        passedOnChangeCollaborativeCodingSlot({
          ...passedCollaborativeCoding,
          generationSettings: {
            service: passedCollaborativeCoding.service,
            ...passedCollaborativeCoding.generationSettings,
            regenerateLink: true,
          },
        });
      } else {
        passedOnChangeCollaborativeCodingSlot(passedCollaborativeCoding);
      }
    },
    [
      collaborativeCodingSlot,
      passedOnChangeCollaborativeCodingSlot,
      originalCollaborativeCodingSlot,
      service,
    ]
  );

  const onRegenerateLink = useCallback(() => {
    if (collaborativeCodingSlot) {
      onChangeCollaborativeCodingSlot({
        ...collaborativeCodingSlot,
        generationSettings: generationSettings
          ? {
              ...generationSettings,
              regenerateLink: true,
            }
          : null,
      });
    }
  }, [
    collaborativeCodingSlot,
    onChangeCollaborativeCodingSlot,
    generationSettings,
  ]);

  const onChangeRequirements = useCallback(
    (newRequirements: CollaborativeCodingSlot["requirements"] | null) => {
      if (collaborativeCodingSlot) {
        onChangeCollaborativeCodingSlot({
          ...collaborativeCodingSlot,
          requirements: newRequirements,
        });
      }
    },
    [collaborativeCodingSlot, onChangeCollaborativeCodingSlot]
  );

  const { value: customValue, setValue: onCustomValueChange } =
    useDebouncedOnChange({
      defaultValue:
        editingCollaborativeCoding?.value ??
        existingCollaborativeCoding?.value ??
        "",
      onChange: (value) => {
        if (collaborativeCodingSlot) {
          onChangeCollaborativeCodingSlot({
            ...collaborativeCodingSlot,
            editingCollaborativeCoding: {
              ...editingCollaborativeCoding,
              value,
            },
          });
        }
      },
    });

  return (
    <FormGroup
      label="Collaborative coding"
      isRequired={!!requirements?.service}
      subText={
        <GenerationSlotSubtext
          existingUrl={existingCollaborativeCoding?.value}
          generationSettings={generationSettings}
          slotHasSelection={!!service}
          hasExistingGeneratedURL={hasExistingGeneratedURL}
          onRegenerateLink={onRegenerateLink}
          requirements={requirements}
          originalRequirements={originalCollaborativeCodingSlot?.requirements}
          onChangeRequirements={onChangeRequirements}
        />
      }
      Action={
        <GenerationSlotAction
          requirements={requirements}
          originalRequirements={originalCollaborativeCodingSlot?.requirements}
        />
      }
    >
      <CollaborativeCodingSelect
        service={service}
        onSelectService={(newService) => {
          if (newService) {
            onChangeCollaborativeCodingSlot({
              ...collaborativeCodingSlot,
              service: newService,
              generationSettings: {
                ...generationSettings,
                service: newService,
              },
            });
          } else {
            onChangeCollaborativeCodingSlot(null);
          }
        }}
      />
      {service === CollaborativeCodingServiceType.CUSTOM && (
        <TextField
          className="w-full mt-2"
          value={customValue}
          onChange={onCustomValueChange}
          placeholder="Add collaborative coding link"
          aria-label="Collaborative coding link"
        />
      )}
    </FormGroup>
  );
}
