import { CypressData } from "client/cypress-data-keys";
import { DateTime } from "luxon";
import { Fragment } from "react";

import { useCalendarSizes } from "../../hooks/sizing";
import { HOURS_IN_DAY } from "../../utils/utils";

/** The horizontal lines in the event view */
export function HorizontalLines() {
  const calendarSizes = useCalendarSizes();

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 grid divide-y divide-[#EEEDEF]"
      style={{
        gridTemplateRows: `repeat(${HOURS_IN_DAY}, minmax(${calendarSizes.hourHeight.rem}rem, 1fr))`,
        width: "100%",
      }}
    >
      {[...Array(HOURS_IN_DAY).keys()].map((i) => {
        const time = DateTime.fromObject({ hour: i });

        return (
          <Fragment key={time.toISO()}>
            <div
              className="col-span-full"
              data-cy={CypressData.calendar.horizontalLine}
            />
          </Fragment>
        );
      })}
    </div>
  );
}
