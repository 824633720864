import { ScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard";
import { DateTime } from "luxon";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import { VideoConferencingServiceType } from "shared/guide-scheduler/video-conferencing/types";
import {
  CollaborativeCodingData,
  HistoricalScheduledInterview,
  ScheduledInterviewData,
  VideoConferencingData,
} from "shared/historical-data/scheduled-interviews/types";

const getVideoConferencingService = (
  interviewData: ScheduledInterviewData[]
): VideoConferencingServiceType | null => {
  const videoConferencingData = interviewData.find(
    (data) => data.type === "videoConferencing"
  ) as VideoConferencingData;
  return videoConferencingData ? videoConferencingData.serviceType : null;
};

const getVideoConferencingUrl = (
  interviewData: ScheduledInterviewData[]
): string | null => {
  const videoConferencingData = interviewData.find(
    (data) => data.type === "videoConferencing"
  ) as VideoConferencingData;
  return videoConferencingData ? videoConferencingData.url : null;
};

const getCollaborativeCodingService = (
  interviewData: ScheduledInterviewData[]
): CollaborativeCodingServiceType | null => {
  const collaborativeCodingData = interviewData.find(
    (data) => data.type === "collaborativeCoding"
  ) as CollaborativeCodingData;
  return collaborativeCodingData ? collaborativeCodingData.serviceType : null;
};

const getCollaborativeCodingUrl = (
  interviewData: ScheduledInterviewData[]
): string | null => {
  const collaborativeCodingData = interviewData.find(
    (data) => data.type === "collaborativeCoding"
  ) as CollaborativeCodingData;
  return collaborativeCodingData ? collaborativeCodingData.url : null;
};

export const mapHistoricalScheduledInterviewToScheduledInterviewCardData = (
  historicalScheduledInterview: HistoricalScheduledInterview
): ScheduledInterviewForCard => {
  return {
    ...historicalScheduledInterview,
    startTime: DateTime.fromISO(historicalScheduledInterview.startTime),
    endTime: DateTime.fromISO(historicalScheduledInterview.endTime),
    createdByGuide: true,
    blacklisted: historicalScheduledInterview.blacklisted,
    atssyncScheduledInterview: null,
    googleEvent: null,
    isCancelled: historicalScheduledInterview.cancelledAt !== null,
    videoConferencingService: getVideoConferencingService(
      historicalScheduledInterview.interviewData
    ),
    videoConferencingUrl: getVideoConferencingUrl(
      historicalScheduledInterview.interviewData
    ),
    collaborativeCodingService: getCollaborativeCodingService(
      historicalScheduledInterview.interviewData
    ),
    collaborativeCodingUrl: getCollaborativeCodingUrl(
      historicalScheduledInterview.interviewData
    ),
    interviewers: historicalScheduledInterview.interviewers,
    greenhouseSyncStatus: null,
    googleSyncStatus: null,
  };
};
