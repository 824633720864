import { SelectStoreState } from "@ariakit/react";
import { ReactElement, RefObject, useCallback } from "react";
import { defaultRangeExtractor, Range, useVirtual } from "react-virtual";

import { SelectItem } from "../select-v2/types";
import {
  CollectionItemRenderers,
  renderCollectionItem,
  useRenderCollection,
} from "./collections";
import { useId } from "./react";

// shared types
// ------------

type BaseListRendererProps<Item extends SelectItem> = {
  items: Item[];
  renderers: CollectionItemRenderers<Item>;
};

// non virtual
// -----------

type NonVirtualListRendererProps<Item extends SelectItem> =
  BaseListRendererProps<Item>;

function NonVirtualListRenderer<Item extends SelectItem>({
  items,
  renderers,
}: NonVirtualListRendererProps<Item>) {
  return <>{useRenderCollection(items, renderers)}</>;
}

// virtual
// -------

export type VirtualCompositeOptions<Item extends SelectItem> = {
  compositeState: SelectStoreState<string | string[]>;
  compositeItemTypes: Item["_type"][];
  estimateSize?: (item: Item) => number;
  isDynamicallySized?: (item: Item) => boolean;
  customPinnedIndexes: number[];
};

type VirtualListRendererOwnProps<Item extends SelectItem> = {
  virtualCompositeOptions: VirtualCompositeOptions<Item>;
};

type VirtualListRendererProps<Item extends SelectItem> =
  BaseListRendererProps<Item> &
    VirtualListRendererOwnProps<Item> & {
      listRef: RefObject<HTMLElement>;
    };

function extractItemKey<Item extends SelectItem>(item: Item) {
  const { key } = item as { key?: string };
  if (key == null)
    throw new Error("All items in a virtualized list must have a key");
  return key;
}

function VirtualListRenderer<Item extends SelectItem>({
  items,
  renderers,
  listRef,
  virtualCompositeOptions: {
    estimateSize,
    isDynamicallySized,
    customPinnedIndexes,
  },
}: VirtualListRendererProps<Item>) {
  const estimateItemSize = useCallback(
    (i: number) => (estimateSize ? estimateSize(items[i]) : 0),
    [estimateSize, items]
  );
  const rowVirtualizer = useVirtual({
    size: items.length,
    parentRef: listRef,
    overscan: 10,
    estimateSize: estimateSize ? estimateItemSize : undefined,
    keyExtractor: useCallback((i: number) => extractItemKey(items[i]), [items]),
    rangeExtractor: useCallback(
      (range: Range) => {
        const defaultIndexes = defaultRangeExtractor(range);

        // Remove any duplicates to prevent duplicate items with the same key.
        const indexes = [
          ...new Set([...defaultIndexes, ...customPinnedIndexes]),
        ];
        return indexes.sort((a, b) => a - b);
      },
      [customPinnedIndexes]
    ),
  });

  const idPrefix = useId();

  return (
    <div
      role="presentation"
      style={{
        height: rowVirtualizer.totalSize,
        position: "relative",
      }}
    >
      {rowVirtualizer.virtualItems.map((row) => {
        // TODO: figure out why we're getting an empty item
        if (row.index === undefined) return undefined;

        const item = items[row.index];

        const htmlProps = {
          key: row.key,
          ref:
            !isDynamicallySized || isDynamicallySized(item)
              ? row.measureRef
              : undefined,
          id: `${idPrefix}-${row.index}`,
          style: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${row.start}px)`,
          },
        };

        return renderCollectionItem(
          [{ ...item, ...htmlProps }, row.index, items],
          renderers
        );
      })}
    </div>
  );
}

// list renderer
// -------------

type ListRendererProps<Item extends SelectItem> = BaseListRendererProps<Item> &
  VirtualListRendererOwnProps<Item> & {
    isVirtual?: boolean;
    className?: string;
    listRef: RefObject<HTMLElement>;
  };

export function CompositeListRenderer<Item extends SelectItem>({
  className,
  items,
  renderers,
  isVirtual,
  listRef,
  virtualCompositeOptions,
}: ListRendererProps<Item>): ReactElement {
  return (
    <div className={className} role="presentation">
      {isVirtual ? (
        <VirtualListRenderer
          items={items}
          renderers={renderers}
          listRef={listRef}
          virtualCompositeOptions={virtualCompositeOptions}
        />
      ) : (
        <NonVirtualListRenderer items={items} renderers={renderers} />
      )}
    </div>
  );
}
