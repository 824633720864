import { Avatar } from "@resource/atlas/avatar/Avatar";
import Badge from "@resource/atlas/badge/Badge";
import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasCalendarEvent,
  atlasDoor,
  atlasUsers,
  atlasVideoCamera,
} from "@resource/atlas/icons";
import { useTimezone } from "client/timezones/useTimezone";
import clsx from "clsx";
import { DateTime } from "luxon";
import { ComponentPropsWithoutRef, useMemo } from "react";
import {
  ExternalInterviewForDiff,
  getChangesBetweenInterviews,
  GuideInterviewForDiff,
  GuideInterviewForDiffInterviewer,
  InterviewChangeField,
} from "shared/scheduled-interviews/interview-diff";

type InterviewDifferencesCardProps = ComponentPropsWithoutRef<"div"> & {
  title: string;
  interview: GuideInterviewForDiff;
  externalInterview?: ExternalInterviewForDiff;
};

export function InterviewDifferencesCard({
  title,
  interview,
  externalInterview,
  ...props
}: InterviewDifferencesCardProps) {
  const timezone = useTimezone();

  const dateText = useMemo(
    () => formatDates(interview.startAt, interview.endAt, timezone),
    [interview.endAt, interview.startAt, timezone]
  );
  const externalDateText = useMemo(
    () =>
      externalInterview
        ? formatDates(
            externalInterview.startAt,
            externalInterview.endAt,
            timezone
          )
        : null,
    [externalInterview, timezone]
  );

  const {
    dateChanged,
    interviewersToAdd,
    interviewersToRemove,
    conferenceRoomsChanged,
    videoConferencingChanged,
  } = useMemo(() => {
    if (!externalInterview) {
      return {
        dateChanged: false,
        conferenceRoomsChanged: false,
        videoConferencingChanged: false,
        interviewersToAdd: [],
        interviewersToRemove: [],
      };
    }

    const changes = getChangesBetweenInterviews(interview, externalInterview);

    return {
      dateChanged: changes.some(
        (change) =>
          change.field === InterviewChangeField.START_TIME ||
          change.field === InterviewChangeField.END_TIME
      ),
      conferenceRoomsChanged: changes.some(
        (change) => change.field === InterviewChangeField.CONFERENCE_ROOMS
      ),
      videoConferencingChanged: changes.some(
        (change) => change.field === InterviewChangeField.VIDEO_CONFERENCING
      ),
      interviewersToAdd: interview.interviewers.filter(
        (interviewer) =>
          !externalInterview.attendees.some(
            (a) => a.email === interviewer.email
          )
      ),
      interviewersToRemove: externalInterview.attendees.filter(
        (attendee) =>
          !interview.interviewers.some((i) => i.email === attendee.email)
      ),
    };
  }, [externalInterview, interview]);

  return (
    <div
      {...props}
      className={clsx(
        "bg-light-gray-500 p-4 rounded-md flex flex-col gap-4",
        props.className
      )}
    >
      <h3 className="font-bold">{title}</h3>
      <div className="flex flex-col gap-3 text-body-md">
        <ValueDisplay icon={atlasCalendarEvent}>
          {dateChanged ? (
            <div className="text-subtle line-through">{externalDateText}</div>
          ) : null}
          <div>{dateText}</div>
        </ValueDisplay>
        <ValueDisplay icon={atlasDoor}>
          <ConferenceRoomDifferences
            hasChanges={conferenceRoomsChanged}
            conferenceRooms={interview.conferenceRooms}
            externalConferenceRooms={externalInterview?.conferenceRooms ?? []}
          />
        </ValueDisplay>
        <ValueDisplay icon={atlasVideoCamera}>
          <VideoConferencingDifferences
            hasChanges={videoConferencingChanged}
            videoConferencingUrl={interview.videoConferencingUrl}
            externalVideoConferencing={externalInterview?.videoConferencing}
          />
        </ValueDisplay>
        <ValueDisplay icon={atlasUsers}>
          <InterviewerDifferences
            interviewers={interview.interviewers}
            interviewersToAdd={interviewersToAdd}
            interviewersToRemove={interviewersToRemove}
          />
        </ValueDisplay>
      </div>
    </div>
  );
}

function formatDates(startAt: Date, endAt: Date, timezone: string) {
  const start = DateTime.fromJSDate(startAt).setZone(timezone);
  const end = DateTime.fromJSDate(endAt).setZone(timezone);

  return `${start.toFormat("DDD")} · ${start
    .toFormat("t")
    .toLowerCase()
    .replace(" ", "")} - ${end
    .toFormat("t")
    .toLowerCase()
    .replace(" ", "")} ${end.toFormat("ZZZZ")}`;
}

function ConferenceRoomDifferences({
  hasChanges,
  conferenceRooms,
  externalConferenceRooms,
}: {
  hasChanges: boolean;
  conferenceRooms: { id: string; name: string }[];
  externalConferenceRooms: { email: string }[];
}) {
  if (!hasChanges) {
    return (
      <>
        {conferenceRooms.length === 0 ? "No conference room" : null}
        {conferenceRooms.map((room) => (
          <div key={room.id}>{room.name}</div>
        ))}
      </>
    );
  }

  const roomsThatWillBeRemoved = externalConferenceRooms.filter(
    (room) => !conferenceRooms.some((r) => r.id === room.email)
  );
  return (
    <>
      {roomsThatWillBeRemoved.length === 0 ? (
        <div className="text-subtle line-through">No conference room</div>
      ) : null}
      {roomsThatWillBeRemoved.map((room) => (
        <div key={room.email} className="text-subtle line-through">
          {room.email}
        </div>
      ))}
      {conferenceRooms.length === 0 ? "No conference room" : null}
      {conferenceRooms.map((room) => (
        <div key={room.id}>{room.name}</div>
      ))}
    </>
  );
}

function VideoConferencingDifferences({
  hasChanges,
  videoConferencingUrl,
  externalVideoConferencing,
}: {
  hasChanges: boolean;
  videoConferencingUrl?: string | null;
  externalVideoConferencing?: { url: string }[] | null;
}) {
  if (!hasChanges || !externalVideoConferencing) {
    return <>{videoConferencingUrl || "No video conferencing"}</>;
  }

  const linksThatWillBeRemoved = externalVideoConferencing.filter(
    ({ url }) => url !== videoConferencingUrl
  );
  return (
    <>
      {linksThatWillBeRemoved.length === 0 ? (
        <div className="text-subtle line-through">No video conferencing</div>
      ) : null}
      {linksThatWillBeRemoved.map(({ url }) => (
        <div key={url} className="text-subtle line-through">
          {url}
        </div>
      ))}
      {videoConferencingUrl || "No video conferencing"}
    </>
  );
}

function InterviewerDifferences({
  interviewers,
  interviewersToAdd,
  interviewersToRemove,
}: {
  interviewers: GuideInterviewForDiffInterviewer[];
  interviewersToAdd: GuideInterviewForDiffInterviewer[];
  interviewersToRemove: { email: string }[];
}) {
  return (
    <>
      {interviewers.map((interviewer) => {
        const isAdded = interviewersToAdd.some(
          (i) => i.email === interviewer.email
        );
        return (
          <div key={interviewer.id} className="flex gap-2 items-center">
            <Avatar
              size="xs"
              name={interviewer.name}
              image={interviewer.imageUrl}
            />
            <div>{interviewer.name}</div>
            {isAdded ? (
              <Badge size="small" styles="bold">
                Added
              </Badge>
            ) : null}
          </div>
        );
      })}
      {interviewersToRemove.map((interviewer) => (
        <div key={interviewer.email} className="flex gap-2 items-center">
          <Avatar size="xs" name={interviewer.email} className="opacity-50" />
          {/* Note: we don't have the name or image for the interviewer as they may not be in the guide system */}
          <div className="text-subtle line-through">{interviewer.email}</div>
        </div>
      ))}
    </>
  );
}

function ValueDisplay({
  icon,
  children,
}: {
  icon: AtlasIconData;
  children: React.ReactNode;
}) {
  return (
    <div className="flex gap-3">
      <Icon content={icon} className="text-subtle" />
      <div className="flex flex-col gap-2">{children}</div>
    </div>
  );
}
