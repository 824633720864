import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo } from "react";

import {
  InterviewRequirementCard,
  InterviewRequirementCardProps,
} from "./InterviewRequirementCard";

type SortableInterviewCardProps = InterviewRequirementCardProps;

export function SortableInterviewCard({
  interviewRequirement,
  ...props
}: SortableInterviewCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id: interviewRequirement.id });

  const style = useMemo(() => {
    let transformString = CSS.Transform.toString(transform);
    if (isDragging) {
      transformString = transformString?.replace(
        /scaleY\(\d+\.?\d*\)/,
        "scaleY(1)"
      );
    }
    return {
      transform: transformString,
      transition,
      touchAction: "none",
      "&:hover": { backgroundColor: "white" },
    };
  }, [transform, transition, isDragging]);

  return (
    <div ref={setNodeRef} style={style} className="focus:outline-none">
      <InterviewRequirementCard
        {...props}
        interviewRequirement={interviewRequirement}
        isDraggable
        dragHandleRef={setActivatorNodeRef}
        dragAttributes={attributes}
        dragListeners={listeners}
      />
    </div>
  );
}
