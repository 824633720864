import { MultiStepView } from "@resource/atlas/multi-step-view/MultiStepView";
import { InterviewerMultiSelectItem } from "client/components/scheduled-interviews/ReportingReasons/__components/InterviewerMultiSelect";
import { ReportCancellationReasonView } from "client/components/scheduled-interviews/ReportingReasons/display/ReportCancellationReasonView";
import { ReportInterviewerSwapReasonView } from "client/components/scheduled-interviews/ReportingReasons/display/ReportInterviewerSwapReasonView";
import { ReportRescheduleReasonView } from "client/components/scheduled-interviews/ReportingReasons/display/ReportRescheduleReasonView";
import { useReportCancellationReasonState } from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportCancellationReasonState";
import {
  ReportInterviewerSwapReasonFormData,
  useReportInterviewerSwapReasonState,
} from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportInterviewerSwapReasonState";
import {
  RescheduleReasonFormData,
  useReportRescheduleReasonState,
} from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportRescheduleReasonState";
import { useCallback } from "react";
import {
  CancellationReason,
  UpdateReason,
} from "shared/reporting/scheduled-interviews/types";

export type UpdateOrCancelReportingReasonsViewData = {
  updateReportingReason?: UpdateReason;
  cancellationReportingReason?: CancellationReason;
};

export type UpdateOrCancelReportingReasonsOnCompleted = (
  data: UpdateOrCancelReportingReasonsViewData
) => void;

export type UpdateOrCancelReportingReasonsViewProps = {
  onCancel: () => void;
  onCompleted: UpdateOrCancelReportingReasonsOnCompleted;
  saveLoading?: boolean;
  allOriginalInterviewers: InterviewerMultiSelectItem[];
  cancellations: {
    interviewers: InterviewerMultiSelectItem[];
  } | null;
  updates: {
    originalInterviewers: InterviewerMultiSelectItem[];
    interviewersChanged?: boolean;
    timeChanged?: boolean;
  } | null;
  defaultRescheduleData?: RescheduleReasonFormData;
  defaultInterviewerSwapData?: ReportInterviewerSwapReasonFormData;
};

export function useReportingCollectionType(
  updates: UpdateOrCancelReportingReasonsViewProps["updates"]
) {
  if (!updates) {
    return null;
  }

  const { timeChanged, interviewersChanged } = updates;

  if (!timeChanged && !interviewersChanged) {
    return null;
  }

  if (timeChanged) {
    return "reschedule";
  }

  return "interviewerSwap";
}

export function UpdateOrCancelReportingReasonsView({
  allOriginalInterviewers,
  onCancel,
  onCompleted,
  saveLoading,
  cancellations,
  updates,
  defaultRescheduleData,
  defaultInterviewerSwapData,
}: UpdateOrCancelReportingReasonsViewProps) {
  const reportInterviewerSwapReasonState = useReportInterviewerSwapReasonState(
    defaultInterviewerSwapData
  );
  const reportRescheduleReasonState = useReportRescheduleReasonState(
    defaultRescheduleData
  );
  const reportCancellationReasonState = useReportCancellationReasonState();
  const updateReportingCollectionType = useReportingCollectionType(updates);

  const onSave = useCallback(() => {
    onCompleted({
      updateReportingReason:
        updateReportingCollectionType === "reschedule"
          ? {
              rescheduleReason: reportRescheduleReasonState.form.getValues(),
            }
          : {
              interviewerSwapReason:
                reportInterviewerSwapReasonState.form.getValues(),
            },
      ...(cancellations && {
        cancellationReportingReason:
          reportCancellationReasonState.form.getValues(),
      }),
    });
  }, [
    cancellations,
    onCompleted,
    reportCancellationReasonState.form,
    reportInterviewerSwapReasonState.form,
    reportRescheduleReasonState.form,
    updateReportingCollectionType,
  ]);

  if (!cancellations && !updates) {
    throw new Error("Must pass either cancellations or updates.");
  }

  if (cancellations && updates) {
    return (
      <MultiStepView
        orderedViews={["updateReportingReason", "cancellationReportingReason"]}
        onDismiss={onCancel}
        views={{
          updateReportingReason: (viewProps) => {
            if (updateReportingCollectionType === "reschedule") {
              return (
                <ReportRescheduleReasonView
                  interviewers={allOriginalInterviewers}
                  state={reportRescheduleReasonState}
                  onCancel={onCancel}
                  confirmationButton={{
                    label: "Continue",
                    variant: "default",
                    onClick: viewProps.onContinue,
                  }}
                />
              );
            }

            return (
              <ReportInterviewerSwapReasonView
                interviewers={allOriginalInterviewers}
                state={reportInterviewerSwapReasonState}
                onCancel={onCancel}
                confirmationButton={{
                  label: "Continue",
                  variant: "default",
                  onClick: viewProps.onContinue,
                }}
              />
            );
          },
          cancellationReportingReason: (viewProps) => (
            <ReportCancellationReasonView
              interviewers={allOriginalInterviewers}
              state={reportCancellationReasonState}
              onCancel={onCancel}
              onBack={viewProps.onBack}
              confirmationButton={{
                label: "Save changes",
                variant: "primary",
                onClick: onSave,
                isLoading: saveLoading,
              }}
            />
          ),
        }}
      />
    );
  }

  if (cancellations) {
    return (
      <ReportCancellationReasonView
        interviewers={allOriginalInterviewers}
        state={reportCancellationReasonState}
        onCancel={onCancel}
        confirmationButton={{
          label: "Save changes",
          variant: "primary",
          onClick: onSave,
          isLoading: saveLoading,
        }}
      />
    );
  }

  if (updates) {
    if (updateReportingCollectionType === "reschedule") {
      return (
        <ReportRescheduleReasonView
          interviewers={allOriginalInterviewers}
          state={reportRescheduleReasonState}
          onCancel={onCancel}
          confirmationButton={{
            label: "Save changes",
            variant: "primary",
            onClick: onSave,
            isLoading: saveLoading,
          }}
        />
      );
    }

    return (
      <ReportInterviewerSwapReasonView
        interviewers={allOriginalInterviewers}
        state={reportInterviewerSwapReasonState}
        onCancel={onCancel}
        confirmationButton={{
          label: "Save changes",
          variant: "primary",
          onClick: onSave,
          isLoading: saveLoading,
        }}
      />
    );
  }

  return null;
}
