import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { useAuthContext } from "auth/context";
import { forwardRef, ReactNode, useMemo } from "react";
import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import {
  COLLABORATIVE_CODING_ICONS,
  COLLABORATIVE_CODING_LABELS,
  DEFAULT_COLLABORATIVE_CODING_ICON,
} from "shared/guide-scheduler/collaborative-coding/utils";

const NO_COLLABORATIVE_CODING_KEY = "none";

export type CollaborativeCodingSelectTriggerProps = AtlasSelectTriggerProps & {
  label: ReactNode;
};

const CollaborativeCodingSelectTrigger = forwardRef<
  HTMLButtonElement,
  CollaborativeCodingSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref} className="bg-light-gray-500 w-full">
      {props.label}
    </SelectTrigger>
  );
});

function CollaborativeCodingSelectItem({
  service,
  emptyState,
}: {
  service: CollaborativeCodingServiceType | string;
  emptyState?: {
    icon: AtlasIconData | null;
    label: string;
  };
}) {
  const icon = useMemo(() => {
    if (service === NO_COLLABORATIVE_CODING_KEY) {
      return null;
    }

    const collaborativeCodingIcon =
      COLLABORATIVE_CODING_ICONS[service as CollaborativeCodingServiceType];

    if (collaborativeCodingIcon) {
      return collaborativeCodingIcon;
    }

    return collaborativeCodingIcon !== null
      ? DEFAULT_COLLABORATIVE_CODING_ICON
      : null;
  }, [service]);

  const label = useMemo(() => {
    if (service === NO_COLLABORATIVE_CODING_KEY) {
      return emptyState?.label ?? "No collaborative coding";
    }

    return (
      COLLABORATIVE_CODING_LABELS[service as CollaborativeCodingServiceType] ??
      "Unknown"
    );
  }, [emptyState?.label, service]);

  return (
    <div className="flex items-center gap-2">
      {icon && <Icon content={icon} />}
      <span>{label}</span>
    </div>
  );
}

type CollaborativeCodingSelectProps = {
  service?: CollaborativeCodingServiceType | null;
  onSelectService: (service: CollaborativeCodingServiceType | null) => void;
};

export function CollaborativeCodingSelect({
  service,
  onSelectService,
}: CollaborativeCodingSelectProps) {
  const { user } = useAuthContext();
  const {
    hasCodeSignalIntegration,
    hasCoderpadIntegration,
    hasHackerRankIntegration,
  } = useMemo(
    () => ({
      hasCodeSignalIntegration:
        user?.currentOrganization?.isCodeSignalEnabled ?? false,
      hasCoderpadIntegration:
        user?.currentOrganization?.isCoderpadEnabled ?? false,
      hasHackerRankIntegration:
        user?.currentOrganization?.isHackerRankEnabled ?? false,
    }),
    [user?.currentOrganization]
  );

  const collaborativeCodingSelectItems = useSelectItems(
    (i) => {
      return [
        ...(service
          ? [
              i.option({
                value: NO_COLLABORATIVE_CODING_KEY,
                children: "No collaborative coding",
                renderContent: () => (
                  <CollaborativeCodingSelectItem
                    service={NO_COLLABORATIVE_CODING_KEY}
                  />
                ),
              }),
              i.separator({
                key: "separator",
              }),
            ]
          : []),
        ...(hasCoderpadIntegration
          ? [
              i.option({
                value: CollaborativeCodingServiceType.CODERPAD,
                children: "Generate Coderpad link",
                renderContent: () => (
                  <CollaborativeCodingSelectItem
                    service={CollaborativeCodingServiceType.CODERPAD}
                  />
                ),
              }),
            ]
          : []),
        ...(hasHackerRankIntegration
          ? [
              i.option({
                value: CollaborativeCodingServiceType.HACKERRANK,
                children: "Generate HackerRank link",
                renderContent: () => (
                  <CollaborativeCodingSelectItem
                    service={CollaborativeCodingServiceType.HACKERRANK}
                  />
                ),
              }),
            ]
          : []),
        ...(hasCodeSignalIntegration
          ? [
              i.option({
                value: CollaborativeCodingServiceType.CODESIGNAL,
                children: "Generate CodeSignal link",
                renderContent: () => (
                  <CollaborativeCodingSelectItem
                    service={CollaborativeCodingServiceType.CODESIGNAL}
                  />
                ),
              }),
            ]
          : []),
        i.option({
          value: CollaborativeCodingServiceType.CUSTOM,
          children: "Custom",
          renderContent: () => (
            <CollaborativeCodingSelectItem
              service={CollaborativeCodingServiceType.CUSTOM}
            />
          ),
        }),
      ];
    },
    [
      hasCoderpadIntegration,
      hasHackerRankIntegration,
      hasCodeSignalIntegration,
      service,
    ]
  );

  return (
    <Select.Root
      sameWidth
      value={service ?? NO_COLLABORATIVE_CODING_KEY}
      setValue={(val) => {
        if (val !== service && typeof val === "string") {
          if (val === NO_COLLABORATIVE_CODING_KEY) {
            onSelectService(null);
          } else {
            onSelectService(val as CollaborativeCodingServiceType);
          }
        }
      }}
      defaultValue={NO_COLLABORATIVE_CODING_KEY}
    >
      <Select.Trigger>
        <CollaborativeCodingSelectTrigger
          label={
            <CollaborativeCodingSelectItem
              service={service ?? NO_COLLABORATIVE_CODING_KEY}
              emptyState={{
                icon: null,
                label: "Add collaborative coding",
              }}
            />
          }
        />
      </Select.Trigger>
      <Select.Content items={collaborativeCodingSelectItems} />
    </Select.Root>
  );
}
