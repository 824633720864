import { z } from "zod";

export enum CollaborativeCodingServiceType {
  CODERPAD = "CODERPAD",
  CODESIGNAL = "CODESIGNAL",
  HACKERRANK = "HACKERRANK",
  CUSTOM = "CUSTOM",
}

export const CollaborativeCodingServiceTypeSchema = z.nativeEnum(
  CollaborativeCodingServiceType
);

export const CollaborativeCodingRequirementSchema = z.object({
  service: CollaborativeCodingServiceTypeSchema,
});

export type CollaborativeCodingRequirement = z.infer<
  typeof CollaborativeCodingRequirementSchema
>;

export const CollaborativeCodingGenerationSettingsSchema = z.object({
  service: CollaborativeCodingServiceTypeSchema,
  regenerateLink: z.boolean().optional(),
});

export type CollaborativeCodingGenerationSettings = z.infer<
  typeof CollaborativeCodingGenerationSettingsSchema
>;

export const CollaborativeCodingSlotInputSchema = z.object({
  customUrl: z.string().optional(),
  generationSettings: CollaborativeCodingGenerationSettingsSchema.optional(),
  requirement: CollaborativeCodingRequirementSchema.optional(),
});

export type CollaborativeCodingSlotInput = z.infer<
  typeof CollaborativeCodingSlotInputSchema
>;
