import { Button as AriaButton } from "@ariakit/react";
import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor/types";
import { useContentEditor } from "@resource/atlas/content-editor/use-content-editor";
import { Icon } from "@resource/atlas/icon/Icon";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { BackButton } from "client/components/generic/layout/BackButton";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import {
  GuideUpdateSubjectEditor,
  GuideUpdateTemplateEditor,
} from "client/components/guide-content";
import { ScheduledInterviewDetailsEditor } from "client/components/scheduled-interview-editor/ScheduledInterviewDetailsEditor";
import { ScheduledInterviewDetailsLiteEditor } from "client/components/scheduled-interview-editor/ScheduledInterviewDetailsLiteEditor";
import { RichBlockProviderOrganization } from "client/guide-content/rich-blocks/provider";
import { getAtssyncInfo } from "client/utils/atssync";
import { CollapsedSignature } from "components/CollapsedSignature";
import Line from "components/MessageComposer/Line";
import {
  PostTemplateForManagingFragment,
  PostTemplateType,
} from "generated/graphql-codegen/graphql";
import { isEqual } from "lodash";
import { RefObject, useCallback, useMemo, useRef } from "react";
import { Controller } from "react-hook-form";

import {
  EditPostTemplateContentFormState,
  PostTemplateContentDialogFormData,
} from "./useForms";

export type PostTemplateFormProps = {
  formState: EditPostTemplateContentFormState;
  atssyncEmailTemplate?: PostTemplateForManagingFragment["atssyncEmailTemplate"];
  isOverridden?: boolean | null;
  /** Type passed for logging events */
  type: PostTemplateType | null | undefined;
  initialFocusRef?: RefObject<HTMLInputElement>;
  organization?: RichBlockProviderOrganization | null;
  dataSynced?: unknown;
};

export type PostTemplateContentDialogViewProps = PostTemplateFormProps & {
  title: string;
  submitLoading?: boolean;
  submitButtonText: string;
  onSubmit: (data: PostTemplateContentDialogFormData) => void;
  onBack?: () => void;
  onCancel?: () => void;
  analyticsType?: string;
};

export function PostTemplateContentDialogView(
  props: PostTemplateContentDialogViewProps
) {
  const {
    submitLoading,
    formState,
    onSubmit,
    onBack,
    onCancel,
    title,
    submitButtonText,
    analyticsType,
  } = props;
  const {
    form: { handleSubmit },
    disabledTooltipContent,
  } = formState;

  const logEvent = useLogEvent({
    component: "PostTemplateContentDialogView",
    project: "Post templates",
    ...(analyticsType && { type: analyticsType }),
  });

  return (
    <View
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      className="!min-h-[24rem]"
      header={{
        title,
        leftActions: onBack ? <BackButton onClick={onBack} /> : undefined,
      }}
      content={{
        className: "flex flex-col overflow-auto !px-0",
      }}
      footer={{
        rightActions: (
          <FormRightActions
            cancel={{
              onClick: () => {
                logEvent("Post Template Content Dialog Cancelled");
                onCancel?.();
              },
              children: "Cancel",
            }}
            save={{
              onClick: () => {
                logEvent("Post Template Content Dialog Submitted");
              },
              children: submitButtonText,
              isLoading: submitLoading,
              type: "submit",
            }}
            disabledTooltipContent={disabledTooltipContent}
          />
        ),
      }}
    >
      <PostTemplateForm {...props} />
    </View>
  );
}

export function PostTemplateForm(props: PostTemplateFormProps) {
  const {
    formState: {
      form: {
        control,
        watch,
        setValue,
        formState: { errors, isSubmitted },
      },
      onChange,
    },
    atssyncEmailTemplate,
    initialFocusRef,
    organization,
    isOverridden,
    type,
    dataSynced,
  } = props;

  const editorInputRef = useRef<HTMLInputElement>(null);

  const { contentEditorProps, editor } = useContentEditor();
  const editorState = watch("data");
  const canResetToSynced = useMemo(
    () => !isEqual(editorState, dataSynced),
    [editorState, dataSynced]
  );

  const onResetToGreenhouse = useCallback(() => {
    if (dataSynced) {
      editor.setEditorState(
        editor.parseEditorState(JSON.stringify(dataSynced))
      );
      setValue(
        "data",
        dataSynced as unknown as AtlasContentEditorSerializedState
      );
    }
  }, [dataSynced, editor, setValue]);

  return (
    <>
      {atssyncEmailTemplate && (
        <span className="flex space-x-2 flex-shrink-0 mx-6 p-4 text-subtle text-body-sm rounded-md bg-light-gray-200">
          <Icon
            content={getAtssyncInfo(atssyncEmailTemplate.account.type).icon}
          />
          <span className="flex flex-col space-y-2">
            <span>
              This template is synced from{" "}
              {getAtssyncInfo(atssyncEmailTemplate.account.type).name} email
              template &quot;{atssyncEmailTemplate.name}&quot;.
            </span>
            {isOverridden ? (
              <span className="flex flex-col space-y-2">
                <span>
                  It has been overridden in Guide so changes will no longer sync
                  from Greenhouse.
                </span>
                {canResetToSynced ? (
                  <span>
                    <AriaButton
                      className="text-purple-500"
                      onClick={onResetToGreenhouse}
                    >
                      Reset to Greenhouse
                    </AriaButton>
                  </span>
                ) : (
                  <span>Save changes to start syncing changes again.</span>
                )}
              </span>
            ) : (
              <span>
                Changes will continue syncing from Greenhouse.{" "}
                <AriaButton className="text-purple-500">
                  <a
                    href={atssyncEmailTemplate.atsUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Edit in Greenhouse
                  </a>
                </AriaButton>
              </span>
            )}
          </span>
        </span>
      )}
      <div className="flex-shrink-0 px-6">
        <div className="flex gap-2 items-center mt-[2px]">
          <div className="text-body-sm-heavy w-12">Subject</div>
          <Controller
            render={({ field }) => {
              if (
                type === PostTemplateType.interviewer_interview_confirmation
              ) {
                return (
                  <ScheduledInterviewDetailsLiteEditor
                    className="grow flex flex-col overflow-auto px-6"
                    ref={initialFocusRef}
                    valueSet={{}}
                    initialState={field.value}
                    onChange={(updated) =>
                      onChange("titleData", updated.toJSON())
                    }
                    {...contentEditorProps}
                  />
                );
              }

              return (
                <GuideUpdateSubjectEditor
                  className="grow flex flex-col overflow-auto px-6"
                  ref={initialFocusRef}
                  valueSet={{}}
                  initialState={field.value}
                  candidateTimezone={null}
                  onChange={(updated) =>
                    onChange("titleData", updated.toJSON())
                  }
                  {...contentEditorProps}
                />
              );
            }}
            name="titleData"
            control={control}
          />
        </div>
        <Line />
      </div>
      <Controller
        render={({ field }) => {
          if (type === PostTemplateType.interviewer_interview_confirmation) {
            return (
              <ScheduledInterviewDetailsEditor
                inputRef={editorInputRef}
                className="grow mt-4 flex flex-col overflow-auto px-6"
                valueSet={{}}
                initialState={field.value}
                onChange={(updated) => onChange("data", updated.toJSON())}
                {...contentEditorProps}
              />
            );
          }

          return (
            <GuideUpdateTemplateEditor
              inputRef={editorInputRef}
              className="grow mt-4 flex flex-col overflow-auto px-6"
              placeholder="Write a message…"
              valueSet={{}}
              initialState={field.value}
              onChange={(updated) => {
                onChange("data", updated.toJSON());
              }}
              organization={organization}
              trailingContentSlot={
                <CollapsedSignature
                  className="mt-4 mb-6"
                  companyLogoUrl={organization?.companyLogoUrl ?? ""}
                  companyName={organization?.name ?? "-"}
                />
              }
              {...contentEditorProps}
            />
          );
        }}
        name="data"
        control={control}
      />
      {errors?.data && isSubmitted && (
        <p className="text-body-sm text-accent px-6 mt-2">
          Content is required
        </p>
      )}
    </>
  );
}
