import { useMemo } from "react";

import { useCalendarOptions } from "../../../hooks/options";
import { useCalendarSizes } from "../../../hooks/sizing";
import { CalendarEvent } from "../../../utils/types";
import {
  calculateEventGridRow,
  useEventColumnStart,
} from "../../../utils/utils";
import { getDurationWithMin } from "./sizing";
import { useEventInteractionState } from "./useEventInteractionState";

/**
 * Hook to calculate z-index for event based on various states
 * Event indexes range from 20-30, so anything that should be beneath events should be <20 and on top of events >30
 */
export function useEventZIndex({
  event,
  eventGroupIds,
  isHovering: isHoveringPassed,
  isDragging,
}: {
  event: CalendarEvent;
  eventGroupIds?: string[];
  /** Can pass isHovering directly to manage group hover state separately */
  isHovering?: boolean;
  isDragging?: boolean;
}) {
  const isInEventGroup = !!eventGroupIds;
  const { layerPriority } = useCalendarOptions();
  const { isHovering, isExpanded, isExpandedGroup } = useEventInteractionState({
    event,
    eventGroupIds,
  });

  return useMemo(() => {
    const layerIndex =
      event.layer && layerPriority ? layerPriority.indexOf(event.layer) : 0;
    const layersCount = layerPriority?.length ?? 1;

    if (event.isBackgroundEvent) return 20;
    if (isExpanded || isDragging) return 30;
    if (isHovering || isHoveringPassed) return 29;
    if (isExpandedGroup) return 28;

    // Base group priority z-index
    let baseZIndex = 25;
    if (layersCount - layerIndex === 2) baseZIndex = 26;
    if (layersCount - layerIndex > 2) baseZIndex = 27;

    // If it's a group, increase the z-index slightly
    return isInEventGroup ? baseZIndex + 1 : baseZIndex;
  }, [
    event.layer,
    event.isBackgroundEvent,
    layerPriority,
    isExpanded,
    isHovering,
    isHoveringPassed,
    isDragging,
    isExpandedGroup,
    isInEventGroup,
  ]);
}

/** Calculate event width based on layers, groups, and interaction states */
function useEventWidth({
  eventGroupIds,
  layer,
  layerPriority,
  minColumnWidth,
  isExpanded,
  eventGroupIdx,
  isDragging,
}: {
  eventGroupIds?: string[];
  layer?: string;
  layerPriority?: string[];
  minColumnWidth: { rem: number };
  isExpanded: boolean;
  eventGroupIdx: number;
  isDragging?: boolean;
}) {
  return useMemo(() => {
    // For now, we can assume we only have 2 layers. We support multiple layers elsewhere, but
    // too complicated to support here without more though.
    // If we ever want more than 2, will have to rethink this.
    const UNDERLYING_LAYER_OFFSET_REM = 0.75;
    const TOP_LAYER_OFFSET_REM = 3;
    const isTopLayer = layer && layerPriority?.[0] === layer;
    const layerOffsetRem = isTopLayer
      ? TOP_LAYER_OFFSET_REM
      : UNDERLYING_LAYER_OFFSET_REM;
    const singleEventMinWidth = `${minColumnWidth.rem - layerOffsetRem}rem`;
    const singleEventWidth = `calc(100% - ${layerOffsetRem}rem)`;

    if (!eventGroupIds || isDragging) {
      return {
        minWidth: singleEventMinWidth,
        width: singleEventWidth,
        left: undefined,
      };
    }

    const groupLength = eventGroupIds.length;
    const groupLengthPercentage = 100 / groupLength;
    const layerOffsetRemGroup = layerOffsetRem / groupLength;

    const minWidthRemBase = minColumnWidth.rem / groupLength;
    const minWidthRem = minWidthRemBase - layerOffsetRem;

    const nonExpandedWidth = `calc(${groupLengthPercentage}% - ${layerOffsetRemGroup}rem)`;
    const expandedWidth = "75%";
    const width = isExpanded ? expandedWidth : nonExpandedWidth;

    const nonExpandedLeftPercentage = eventGroupIdx * groupLengthPercentage;
    const nonExpandedLeftOffset =
      (eventGroupIdx / groupLength) * layerOffsetRem;
    const nonExpandedLeft = `calc(${nonExpandedLeftPercentage}% - ${nonExpandedLeftOffset}rem)`;
    const expandedLeft = eventGroupIdx === 0 ? 0 : "25%";
    const left = isExpanded ? expandedLeft : nonExpandedLeft;

    return {
      width,
      minWidth: `${minWidthRem}rem`,
      left,
    };
  }, [
    layer,
    layerPriority,
    minColumnWidth.rem,
    eventGroupIds,
    isDragging,
    isExpanded,
    eventGroupIdx,
  ]);
}

/** Calculate override wrapper style for event */
export function useWrapperStyle({
  event,
  calendarId,
  calendarGroupIdx,
  eventGroupIds,
  eventGroupIdx = 0,
  isHovering,
  isDragging,
}: {
  event: CalendarEvent;
  calendarId?: string;
  calendarGroupIdx?: number;
  eventGroupIds?: string[];
  eventGroupIdx?: number;
  isHovering?: boolean;
  isDragging?: boolean;
}) {
  const { startTime, endTime, layer } = event;
  const { layerPriority } = useCalendarOptions();
  const { minColumnWidth } = useCalendarSizes();
  const { isExpanded } = useEventInteractionState({ event, eventGroupIds });
  const zIndex = useEventZIndex({
    event,
    eventGroupIds,
    isHovering,
    isDragging,
  });
  const gridColumnStart = useEventColumnStart({
    calendarId,
    dayOfWeek: startTime.weekday,
    calendarGroupIdx,
  });
  const startOfDay = event.startTime.startOf("day");
  const minutesSinceStartOfDay = startTime.diff(startOfDay).as("minutes");
  const actualDurationInMinutes = endTime.diff(startTime).as("minutes");
  const durationInMinutes = getDurationWithMin(actualDurationInMinutes);

  const { width, minWidth, left } = useEventWidth({
    eventGroupIds,
    layer,
    layerPriority,
    minColumnWidth,
    isExpanded,
    eventGroupIdx,
    isDragging,
  });

  return useMemo(() => {
    return {
      gridRow: calculateEventGridRow({
        minutesSinceStartOfDay,
        durationInMinutes,
      }),
      gridColumnStart,
      zIndex,
      width,
      minWidth,
      left,
    };
  }, [
    minutesSinceStartOfDay,
    durationInMinutes,
    gridColumnStart,
    width,
    minWidth,
    left,
    zIndex,
  ]);
}
