/* eslint-disable import/prefer-default-export */
import "./FixedToolbarPlugin.sass";

import { Portal } from "ariakit";
import { useCallback } from "react";

import { atlasRingPlus } from "../../../../icons/atlas";
import { createComponentUtils } from "../../../__utils/atlas";
import { useStaticValue } from "../../../__utils/react";
import { Button } from "../../../button/Button";
import { ButtonGroup } from "../../../button/ButtonGroup";
import Tooltip from "../../../tooltip/Tooltip";
import {
  InsertOption,
  useParagraphStyleMenu,
  useRenderInsertOptionSlot,
} from "../../__utils/insert-options";
import { useFormattingButtons } from "../../__utils/misc";
import { usePortalElement } from "../../__utils/portal-element-context";
import { useSelection } from "../../__utils/selection-context";
import { useInsertTypeaheadTrigger } from "../../__utils/typeahead";

// config
// ------

const COMPONENT_NAME = "ContentEditor-FixedToolbar";

const { ROOT } = createComponentUtils(COMPONENT_NAME);

// toolbar
// -------

type FixedToolbarPluginProps = {
  insertOptions?: InsertOption[];
};

export function FixedToolbarPlugin({
  insertOptions = [],
}: FixedToolbarPluginProps) {
  const { isRangeSelection } = useSelection();

  const insert = useInsertTypeaheadTrigger("/");

  const [renderSlotOption, render] = useRenderInsertOptionSlot(
    insertOptions,
    useStaticValue({
      fullTarget: "fixed-toolbar-button",
      subTarget: "fixed-toolbar",
    }),
    "fixedToolbar"
  );

  const middleButtonGroup = useCallback(() => {
    const group = (
      <ButtonGroup size="xs" isGhost variant="default">
        {renderSlotOption("link")}
        {renderSlotOption("mention")}
        {renderSlotOption("emoji")}
        {renderSlotOption("variable")}
      </ButtonGroup>
    );
    if (group.props.children.filter(Boolean).length) {
      return (
        <>
          <div className="separator" />
          {group}
        </>
      );
    }
    return null;
  }, [renderSlotOption]);

  return (
    <>
      <Portal portalElement={usePortalElement().footer}>
        <div className={ROOT}>
          <Tooltip isInstant content="Insert">
            <Button
              disabled={!isRangeSelection}
              size="xs"
              isGhost
              variant="default"
              aria-label="insert"
              icon={atlasRingPlus}
              onClick={insert}
            />
          </Tooltip>
          <div className="separator" />
          <ButtonGroup size="xs" isGhost variant="default">
            {useParagraphStyleMenu(
              insertOptions,
              useStaticValue({
                fullTarget: "fixed-toolbar-paragraph-style",
                subTarget: "fixed-toolbar",
              })
            )}
            {useFormattingButtons()}
          </ButtonGroup>
          {middleButtonGroup()}
          <div className="separator" />
          <ButtonGroup size="xs" isGhost variant="default">
            {renderSlotOption("file")}
            {renderSlotOption("image")}
            {renderSlotOption("video")}
            {renderSlotOption("gif")}
          </ButtonGroup>
          <div className="separator" />
          <ButtonGroup size="xs" isGhost variant="default">
            {renderSlotOption("request-availability")}
            {renderSlotOption("self-schedule-request")}
            {renderSlotOption("interviews")}
          </ButtonGroup>
        </div>
      </Portal>
      {render}
    </>
  );
}
