export type GuideInterviewForDiffInterviewer = {
  id: string;
  email: string;
  name?: string;
  imageUrl?: string | null;
};

export type GuideInterviewForDiff = {
  startAt: Date;
  endAt: Date;
  videoConferencingUrl?: string | null;
  interviewers: GuideInterviewForDiffInterviewer[];
  conferenceRooms: { id: string; name: string }[];
};

export type ExternalInterviewForDiff = {
  startAt: Date;
  endAt: Date;
  videoConferencing: { url: string }[];
  attendees: { email: string }[];
  conferenceRooms: { email: string }[];
};

export type InterviewChange = {
  field: string;
  guideData: Record<string, unknown>;
  externalData: Record<string, unknown>;
};

export enum InterviewChangeField {
  START_TIME = "startTime",
  END_TIME = "endTime",
  VIDEO_CONFERENCING = "videoConferencing",
  INTERVIEWERS = "interviewers",
  CONFERENCE_ROOMS = "conferenceRooms",
}

export function getChangesBetweenInterviews(
  guideInterview: GuideInterviewForDiff,
  externalInterview: ExternalInterviewForDiff
): InterviewChange[] {
  const changes: InterviewChange[] = [];

  if (
    externalInterview.startAt.getTime() !== guideInterview.startAt.getTime()
  ) {
    changes.push({
      field: InterviewChangeField.START_TIME,
      guideData: { startTime: guideInterview.startAt },
      externalData: { startTime: externalInterview.startAt },
    });
  }

  if (externalInterview.endAt.getTime() !== guideInterview.endAt.getTime()) {
    changes.push({
      field: InterviewChangeField.END_TIME,
      guideData: { endTime: guideInterview.endAt },
      externalData: { endTime: externalInterview.endAt },
    });
  }

  // we only care if there's a zoom link on our end
  // doesn't really matter to us if they have a zoom link on their end when we don't so not going to care about checking that
  if (guideInterview.videoConferencingUrl) {
    if (
      !externalInterview.videoConferencing.some(
        (v) => v.url === guideInterview.videoConferencingUrl
      )
    ) {
      changes.push({
        field: InterviewChangeField.VIDEO_CONFERENCING,
        guideData: {
          videoConferencingUrl: guideInterview.videoConferencingUrl,
        },
        externalData: {
          videoConferencing: externalInterview.videoConferencing,
        },
      });
    }
  }

  // Every attendee we have stored must be in Google, and every attendee Google has must be in our stored list. We can achieve this by
  // checking every + lengths match
  const interviewersHaveChanged =
    externalInterview.attendees.length !== guideInterview.interviewers.length ||
    externalInterview.attendees.some(
      (attendee) =>
        !guideInterview.interviewers.some(
          (interviewer) => interviewer.email === attendee.email
        )
    );

  if (interviewersHaveChanged) {
    changes.push({
      field: InterviewChangeField.INTERVIEWERS,
      guideData: {
        interviewers: guideInterview.interviewers.map((i) => i.email),
      },
      externalData: {
        interviewers: externalInterview.attendees.map((a) => a.email),
      },
    });
  }

  const guideConferenceRoomNotInGoogle = guideInterview.conferenceRooms.some(
    (cr) => {
      return !externalInterview.conferenceRooms.some(
        (gcr) => gcr.email === cr.id
      );
    }
  );

  if (guideConferenceRoomNotInGoogle) {
    changes.push({
      field: InterviewChangeField.CONFERENCE_ROOMS,
      guideData: {
        conferenceRooms: guideInterview.conferenceRooms.map((r) => r.id),
      },
      externalData: {
        conferenceRooms: externalInterview.conferenceRooms.map((r) => r.email),
      },
    });
  }

  return changes;
}
