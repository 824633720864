import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { InterviewRequirementForInterviewRequirementsCardFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useState } from "react";

import {
  CreateOrUpdateAvailabilityData,
  CreateOrUpdateAvailabilityView,
  useCreateOrUpdateAvailabilityState,
  UseCreateOrUpdateAvailabilityStateProps,
} from "./CreateOrUpdateAvailabilityView";

export type CreateOrUpdateAvailabilityRequestViewProps = {
  onSubmit: (state: CreateOrUpdateAvailabilityData) => void | Promise<void>;
  onCancel: () => void;
  taskRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];
  stateProps?: UseCreateOrUpdateAvailabilityStateProps;
  isEdit?: boolean;
};

export function CreateOrUpdateAvailabilityRequestView({
  onSubmit: onSubmitProp,
  onCancel,
  taskRequirements,
  stateProps = {},
  isEdit = false,
}: CreateOrUpdateAvailabilityRequestViewProps) {
  const [loading, setLoading] = useState(false);
  const state = useCreateOrUpdateAvailabilityState(stateProps);
  const { submitButtonValidation } = state;
  const onSubmit = useCallback(async () => {
    setLoading(true);

    await onSubmitProp({
      notes: state.notes,
      selections: state.selections,
    });

    setLoading(false);
  }, [state, onSubmitProp]);

  return (
    <CreateOrUpdateAvailabilityView
      leftSidePanel={{
        state,
        title: isEdit ? "Update suggested times" : "Add suggested times",
        subText:
          "Click and drag on the calendar to add suggested windows of availability for the candidate.",
        addNotes: {
          title: "Notes",
          placeholder: "Add a note for the candidate.",
          subtext:
            "Notes will be shown to the candidate while submitting availability.",
        },
        selectionsTitle: "Suggested times",
        showInterviewerSelection: true,
        taskRequirements,
      }}
      footer={{
        rightActions: (
          <ButtonGroup>
            <Button onClick={onCancel}>Cancel</Button>
            <OptionalTooltip
              isInstant
              content={submitButtonValidation.validationMessage}
            >
              <Button
                variant="primary"
                onClick={onSubmit}
                isLoading={loading}
                disabled={!submitButtonValidation.isValid}
              >
                {isEdit ? "Update suggested times" : "Add suggested times"}
              </Button>
            </OptionalTooltip>
          </ButtonGroup>
        ),
      }}
      state={state}
      defaultEventTitle="Suggested time"
      taskRequirements={taskRequirements}
    />
  );
}
