import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { UseFormReturn } from "react-hook-form";

import { InterviewerMultiSelectItem } from "../scheduled-interviews/ReportingReasons/__components/InterviewerMultiSelect";
import { ScheduledInterviewUpdateConfirmationView } from "../scheduled-interviews/ScheduledInterviewChangeConfirmation/ScheduledInterviewUpdateConfirmationView";
import {
  UpsertScheduledInterview,
  UpsertScheduledInterviewFormData,
} from "../scheduled-interviews/UpsertScheduledInterviewForm/utils/types";
import {
  UpdateScheduledInterviewOnCompleted,
  useEditScheduledInterviewConfirmationOnSave,
} from "./__hooks__/useEditScheduledInterviewConfirmationOnSave";

type EditScheduledInterviewConfirmationDialogProps = {
  store: DialogStore;
  form: UseFormReturn<UpsertScheduledInterviewFormData>;
  originalInterviewers: InterviewerMultiSelectItem[];
  onCompleted?: UpdateScheduledInterviewOnCompleted;
  guideId: string;
  originalInterview?: UpsertScheduledInterview;
};

export function EditScheduledInterviewConfirmationDialog({
  store,
  form,
  originalInterviewers,
  onCompleted,
  guideId,
  originalInterview,
}: EditScheduledInterviewConfirmationDialogProps) {
  const { onSave, saveLoading } = useEditScheduledInterviewConfirmationOnSave({
    form,
    guideId,
    originalInterview,
    store,
    onCompleted,
  });

  const { scheduledInterview: touchedInterviewFields } =
    form.formState.dirtyFields;

  return (
    <Dialog store={store} size="medium">
      <ScheduledInterviewUpdateConfirmationView
        onCancel={store.hide}
        onCompleted={onSave}
        saveLoading={saveLoading}
        originalInterviewers={originalInterviewers}
        interviewersChanged={!!touchedInterviewFields?.interviewerSlots}
        timeChanged={!!touchedInterviewFields?.startTime}
      />
    </Dialog>
  );
}
