import React, { createContext, ReactNode, useContext } from "react";

const GuidePostIdContext = createContext<string | null>(null);

export const useGuidePostId = () => {
  const context = useContext(GuidePostIdContext);

  return context;
};

type GuidePostIdProviderProps = {
  children: ReactNode;
  guidePostId: string;
};

export function GuidePostIdProvider({
  children,
  guidePostId,
}: GuidePostIdProviderProps) {
  return (
    <GuidePostIdContext.Provider value={guidePostId}>
      {children}
    </GuidePostIdContext.Provider>
  );
}
