import { gql } from "generated/graphql-codegen";
import { GuideInterviewsFromRichBlockContextQuery } from "generated/graphql-codegen/graphql";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

type AttachedFile =
  GuideInterviewsFromRichBlockContextQuery["scheduledInterviewsById"][number]["icsFile"];

const ATTACHED_FILES_FRAGMENT = gql(`
fragment AttachedFilesForRichBlocks on EmailAttachedFile {
    id
    filename
    content
  }
`);

type AttachedFilePreview = AttachedFile & {
  nodeKey: string;
};

export type AttachedFilesRichBlockContextValue = {
  attachedFiles: AttachedFilePreview[];
  setAttachedFiles: Dispatch<SetStateAction<AttachedFilePreview[]>>;
  candidateEmail?: string;
  enableICSFiles: boolean;
};
const AttachedFilesRichBlockContext = createContext<
  AttachedFilesRichBlockContextValue | undefined
>(undefined);

export function useAttachedFiles() {
  const contextValue = useContext(AttachedFilesRichBlockContext);

  if (!contextValue) {
    throw new Error(
      "useAttachedFiles can only be called inside a AttachedFilesRichBlockProvider"
    );
  }

  return contextValue;
}

export function AttachedFilesRichBlockProvider({
  children,
  candidateEmail,
  enableICSFiles,
}: {
  children: ReactNode;
  candidateEmail?: string;
  enableICSFiles: boolean;
}) {
  const [attachedFiles, setAttachedFiles] = useState<AttachedFilePreview[]>([]);

  return (
    <AttachedFilesRichBlockContext.Provider
      value={{
        attachedFiles,
        setAttachedFiles,
        candidateEmail,
        enableICSFiles,
      }}
    >
      {children}
    </AttachedFilesRichBlockContext.Provider>
  );
}

AttachedFilesRichBlockProvider.fragments = {
  file: ATTACHED_FILES_FRAGMENT,
};
