import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import { SchedulerDialog as SchedulerDialogComponent } from "client/scheduler/wrappers/SchedulerDialog";
import { useCallback, useMemo, useState } from "react";
import { trackProperties } from "shared/analytics";

export const useSchedulingTaskDetailsOnSchedule = ({
  schedulingRequestId,
}: {
  schedulingRequestId: string;
}) => {
  const logEvent = useLogEvent({ component: "SchedulingTaskDetailsView" });
  const [algorithmSuggestedScenarioId, setAlgorithmSuggestedScenarioId] =
    useState<string | undefined>();
  const schedulerDialogStore = useDialogStore();

  const onSchedule = useCallback(
    (props: { algorithmSuggestedScenarioId?: string } = {}) => {
      setAlgorithmSuggestedScenarioId(props.algorithmSuggestedScenarioId);
      logEvent("Scheduler V2 Opened", trackProperties.isCustomerSuccess);
      schedulerDialogStore.show();
    },
    [logEvent, schedulerDialogStore]
  );

  const SchedulerDialog = useCallback(() => {
    return (
      <SchedulerDialogComponent
        store={schedulerDialogStore}
        schedulingRequestId={schedulingRequestId}
        algorithmSuggestedScenarioId={algorithmSuggestedScenarioId}
      />
    );
  }, [algorithmSuggestedScenarioId, schedulerDialogStore, schedulingRequestId]);

  return useMemo(
    () => ({
      onSchedule,
      // Note: This is an anti-pattern but for some reason is what was making it work for AIScenariosSection on top of the AIScenariosDialog
      // If we render the scheduler v2 dialog directly in AIScenariosSection and pass props it has double dialogs
      // Should debug and be able to do this properly (return schedulerProps from the hook and render the component)
      // but not worth the time right now
      SchedulerDialog,
    }),
    [SchedulerDialog, onSchedule]
  );
};
