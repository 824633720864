import { useCalendarTimezone } from "client/components/calendar/settings";
import { DisplaySelectedTimes } from "client/components/display/times/DisplaySelectedTimes";
import { EditableSelectedTimes } from "client/components/display/times/EditableSelectedTimes";
import { Time } from "client/components/display/times/TimeSelect";
import { InterviewRequirementCard } from "client/components/interview-requirements/InterviewRequirementCard";
import {
  InterviewerForSelectionFragment,
  InterviewRequirementForInterviewRequirementsCardFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Event } from "shared/guide-availability/types";

import { AddNotes, AddNotesProps } from "./AddNotes";
import { CandidateCurrentTimezone } from "./CandidateCurrentTimezone";
import { CreateOrUpdateAvailabilityState } from "./CreateOrUpdateAvailabilityView";
import { InterviewerOverlayCalendars } from "./InterviewerOverlayCalendars";
import { RequestNotes, RequestNotesProps } from "./RequestNotes";
import { SchedulingPreferencesSelect } from "./SchedulingPreferencesSelect";

export type AvailabilityLeftSidePanelProps = {
  title: string;
  subText?: string;
  selectionsTitle?: string;
  addNotes?: Omit<AddNotesProps, "notes" | "setNotes">;
  showSchedulingPreferences?: boolean;
  showInterviewerSelection?: boolean;
  requestNotes?: RequestNotesProps;
  suggestions?: Event[];
  taskRequirements?: InterviewRequirementForInterviewRequirementsCardFragment[];
  state: CreateOrUpdateAvailabilityState;
};

export function AvailabilityLeftSidePanel({
  title,
  subText,
  state,
  addNotes,
  suggestions,
  requestNotes,
  showSchedulingPreferences,
  showInterviewerSelection,
  taskRequirements,
  selectionsTitle = "Selections",
}: AvailabilityLeftSidePanelProps) {
  const activeTaskRequirements = useMemo(() => {
    return taskRequirements?.filter((t) => !t.manuallyRemovedAt);
  }, [taskRequirements]);

  const {
    selections,
    notes,
    setNotes,
    schedulingPreference,
    setSchedulingPreference,
    interviewers,
    setInterviewers,
    interviewerCalendarColors,
    setSelections,
  } = state;
  const timezone = useCalendarTimezone();

  const onAddInterviewer = useCallback(
    (user: InterviewerForSelectionFragment) => {
      setInterviewers((existingInterviewers) => [
        ...existingInterviewers,
        user,
      ]);
    },
    [setInterviewers]
  );

  const onRemoveInterviewer = useCallback(
    (userId: string) => {
      setInterviewers((existingInterviewers) =>
        existingInterviewers.filter((interviewer) => interviewer.id !== userId)
      );
    },
    [setInterviewers]
  );

  const onEditEvent = useCallback(
    (updatedEvent: { id: string; startTime: Time; endTime: Time }) => {
      setSelections((prevSelections) =>
        prevSelections.map((selection) =>
          selection.id === updatedEvent.id
            ? {
                ...selection,
                startTime: DateTime.fromISO(selection.startTime)
                  .set({
                    hour: updatedEvent.startTime.hour,
                    minute: updatedEvent.startTime.minute,
                  })
                  .toISO(),
                endTime: DateTime.fromISO(selection.endTime)
                  .set({
                    hour: updatedEvent.endTime.hour,
                    minute: updatedEvent.endTime.minute,
                  })
                  .toISO(),
              }
            : selection
        )
      );
    },
    [setSelections]
  );

  const onRemoveEvent = useCallback(
    (id: string) => {
      state.setSelections((prevSelections) =>
        prevSelections.filter((selection) => selection.id !== id)
      );
    },
    [state]
  );

  const { onInvalidTimeRange, onValidTimeRange } = useManageInvalidTimeRanges({
    state,
  });

  return (
    <div className="w-[23.5rem] shrink-0 space-y-4 px-4 pt-4 pb-20 overflow-y-auto">
      <div className="space-y-2">
        <h2 className="text-h2">{title}</h2>
        {subText && <p className="text-body-md">{subText}</p>}
      </div>
      {suggestions && suggestions.length > 0 && (
        <div className="space-y-2">
          <p className="text-body-md-heavy text-dark">Suggested times</p>
          <DisplaySelectedTimes
            times={suggestions.map((s) => ({
              id: s.id,
              start: s.startTime,
              end: s.endTime,
            }))}
            timezone={timezone}
          />
        </div>
      )}
      {showInterviewerSelection && (
        <InterviewerOverlayCalendars
          interviewers={interviewers}
          interviewerCalendarColors={interviewerCalendarColors}
          onAddInterviewer={onAddInterviewer}
          onRemoveInterviewer={onRemoveInterviewer}
        />
      )}
      {activeTaskRequirements && activeTaskRequirements.length > 0 && (
        <div className="space-y-2">
          <p className="text-body-md-heavy text-dark">Task requirements</p>
          {activeTaskRequirements.map((i) => (
            <InterviewRequirementCard key={i.id} interviewRequirement={i} />
          ))}
        </div>
      )}
      {requestNotes && <RequestNotes {...requestNotes} />}
      <CandidateCurrentTimezone />
      {showSchedulingPreferences && (
        <div className="space-y-2">
          <p className="text-body-md-heavy text-dark">Scheduling preferences</p>
          <SchedulingPreferencesSelect
            value={schedulingPreference}
            onChange={setSchedulingPreference}
          />
        </div>
      )}
      {addNotes && <AddNotes {...addNotes} notes={notes} setNotes={setNotes} />}
      {!!selections.length && (
        <div className="space-y-2">
          <p className="text-body-md-heavy text-dark">{selectionsTitle}</p>
          <EditableSelectedTimes
            times={selections.map((s) => ({
              id: s.id,
              start: s.startTime,
              end: s.endTime,
            }))}
            timezone={timezone}
            onEdit={onEditEvent}
            onRemove={onRemoveEvent}
            onInvalidTimeRange={onInvalidTimeRange}
            onValidTimeRange={onValidTimeRange}
          />
        </div>
      )}
    </div>
  );
}

function useManageInvalidTimeRanges({
  state,
}: {
  state: CreateOrUpdateAvailabilityState;
}) {
  const { setSubmitButtonValidation } = state;
  const [invalidIds, setInvalidIds] = useState<string[]>([]);

  const onInvalidTimeRange = useCallback(
    (id: string) => {
      setInvalidIds((existingInvalidIds) => [...existingInvalidIds, id]);
    },
    [setInvalidIds]
  );

  const onValidTimeRange = useCallback(
    (id: string) => {
      setInvalidIds((existingInvalidIds) =>
        existingInvalidIds.filter((invalidId) => invalidId !== id)
      );
    },
    [setInvalidIds]
  );

  // Only look at invalid IDs that still exist in the current selections
  const existingIds = useMemo(() => {
    return invalidIds.filter((id) =>
      state.selections.map((s) => s.id).includes(id)
    );
  }, [invalidIds, state.selections]);

  useEffect(() => {
    if (existingIds.length > 0) {
      setSubmitButtonValidation({
        isValid: false,
        validationMessage:
          "Invalid time range. Please make sure the start time is before the end time.",
      });
    } else {
      setSubmitButtonValidation({
        isValid: true,
      });
    }
  }, [existingIds, setSubmitButtonValidation]);

  return {
    onInvalidTimeRange,
    onValidTimeRange,
  };
}
