import {
  TaskRequirementsProvider,
  TaskRequirementsProviderProps,
} from "client/guide-availability/rich-blocks/guide-availability-request/__lib/provider";
import { GuideIdProvider } from "client/utils/guide-id-provider";
import { ReactNode } from "react";

import { AttachedFilesRichBlockProvider } from "./interviews/__lib/attached-files-context";

export interface RichBlockProviderOrganization {
  name: string;
  defaultAvatarImageUrl: string;
  companyLogoUrl: string;
  enableICSFiles?: boolean;
}

// Aggregate contexts from different Rich blocks here
export function RichBlockContextProvider({
  children,
  guideId,
  organization,
  candidateEmail,
  taskRequirements,
}: {
  children: ReactNode;
  guideId?: string;
  organization?: RichBlockProviderOrganization | null;
  candidateEmail?: string;
} & Pick<TaskRequirementsProviderProps, "taskRequirements">) {
  return (
    <GuideIdProvider guideId={guideId}>
      <AttachedFilesRichBlockProvider
        candidateEmail={candidateEmail}
        enableICSFiles={organization?.enableICSFiles ?? false}
      >
        <TaskRequirementsProvider taskRequirements={taskRequirements}>
          {children}
        </TaskRequirementsProvider>
      </AttachedFilesRichBlockProvider>
    </GuideIdProvider>
  );
}
