import { Avatar } from "@resource/atlas/avatar/Avatar";
import { SelectV2, useSelectItems } from "@resource/atlas/select-v2";
import { getAvatarStatusFromInterviewerResponseStatus } from "client/utils/interviewer-response-status";
import { InterviewerResponse } from "generated/graphql-codegen/graphql";
import _ from "lodash";
import { useMemo } from "react";

export type InterviewerMultiSelectItem = {
  userMembershipId: string;
  name: string;
  imageUrl: string | null;
  email: string;
  responseStatus?: InterviewerResponse | null;
};

export type InterviewerMultiSelectProps = {
  interviewers: InterviewerMultiSelectItem[];
  selectedInterviewerUserMembershipIds: string[];
  onChange: (selectedInterviewerIds: string[]) => void;
};

/** Base multi-select component for allowing choosing from a fixed list of interviewers */
export function InterviewerMultiSelect({
  interviewers,
  selectedInterviewerUserMembershipIds,
  onChange,
}: InterviewerMultiSelectProps) {
  const items = useSelectItems(
    (i) =>
      _(interviewers)
        // We should provide unique interviewer UserMemberships to the select, but this ensures it
        .uniqBy("userMembershipId")
        .sortBy("name")
        .map((interviewer) =>
          i.option({
            key: interviewer.userMembershipId,
            value: interviewer.userMembershipId,
            // This works but TS doesn't like it because children is manually typed as string
            // @ts-expect-error Need to fix the select to support custom children vs just strings
            children: renderInterviewerItem({ interviewer }),
            isSelectable: true,
            size: "compact",
          })
        )
        .value(),
    [interviewers]
  );
  const selectedInterviewers = useMemo(() => {
    return interviewers.filter((interviewer) =>
      selectedInterviewerUserMembershipIds.includes(
        interviewer.userMembershipId
      )
    );
  }, [interviewers, selectedInterviewerUserMembershipIds]);

  return (
    <SelectV2.Root
      value={selectedInterviewerUserMembershipIds}
      setValue={(v) => {
        onChange(Array.isArray(v) ? v : [v]);
      }}
    >
      <SelectV2.Trigger size="medium" className="w-full">
        {selectedInterviewers.length > 0 ? (
          <span className="text-body-md">
            {selectedInterviewers
              .map((interviewer) => interviewer.name)
              .join(", ")}
          </span>
        ) : (
          <span className="text-body-md text-subtle h-5">
            Select interviewers
          </span>
        )}
      </SelectV2.Trigger>
      <SelectV2.Content items={items} sameWidth />
    </SelectV2.Root>
  );
}

function renderInterviewerItem({
  interviewer,
}: {
  interviewer: InterviewerMultiSelectItem;
}) {
  return (
    <div className="flex gap-2 items-center grow">
      <Avatar
        size="small"
        image={interviewer.imageUrl}
        name={interviewer.name}
        status={
          interviewer.responseStatus
            ? getAvatarStatusFromInterviewerResponseStatus(
                interviewer.responseStatus
              )
            : undefined
        }
      />
      <div className="flex-grow">
        <span className="shrink truncate" title={interviewer.name}>
          {interviewer.name}
        </span>
      </div>
    </div>
  );
}
