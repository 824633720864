import { useEffect } from "react";

export type ExtensionFrameVariant = "fullscreen" | "popover";

type BoundingRect = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

type DeprecatedParentHrefPayload = {
  type: "parent-href";
  href: string;
};

type DeprecatedPanelOpenStatusPayload = {
  panelOpen: boolean;
};

type VersionPayload = {
  command: "version";
  value: string;
};

type ParentHrefPayload = {
  command: "parent-href";
  value: string;
};

type NavigateToFrameHrefPayload = {
  command: "navigate-to-frame-href";
  value: string;
};

type PanelOpenStatePayload = {
  command: "panel-open-state";
  value: boolean;
};

type CO2PanelOpenStatePayload = {
  command: "co2-panel-open-state";
  value: boolean;
};

type TrackEventPayload = {
  command: "track-event";
  value: {
    eventName: string;
    eventProperties: Record<string, unknown>;
  };
};

export type Message = {
  data:
    | DeprecatedParentHrefPayload
    | ParentHrefPayload
    | DeprecatedPanelOpenStatusPayload
    | VersionPayload
    | NavigateToCandidatePayload
    | NavigateToFrameHrefPayload
    | TrackEventPayload
    | PanelOpenStatePayload
    | CO2PanelOpenStatePayload;
  origin?: string;
};

type SetPanelOpenStatePayload = {
  command: "set-panel-open-state";
  value: boolean;
};

type DeprecatedShowPersistentButtonPayload = "show-persistent-button";

type RequestVersionPayload = {
  command: "request-version";
};

type RequestPanelOpenStatePayload = {
  command: "request-panel-open-state";
};

type ReloadPayload = {
  command: "reload";
};

type RequestParentHrefPayload = {
  type: "request-parent-href";
};

type FillGuideUrlPayload = {
  type: "fill-guide-url";
  guideUrl: string;
};

type FlagsLoadedPayload = {
  command: "flags-updated";
  value: string;
};

type AuthenticationContextUpdatedPayload = {
  command: "authentication-context-updated";
  value: {
    isLoggedIn: boolean;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    imageUrl?: string;
    createdAt?: Date;
  };
};

type SidepanelIframeInitializedPayload = {
  command: "sidepanel-iframe-initialized";
};

type UpdateSidepanelIframeInitializedPayload = {
  command: "update-sidepanel-iframe-initialized";
  value: boolean;
};

type ModalIframeInitializedPayload = {
  command: "modal-iframe-initialized";
};
type DeprecatedReloadPayload = "reload";
type DeprecatedTogglePanelPayload = "toggle-panel";

type DeprecatedShowMessageButtonPayload = { command: "show-message-button" };

type NavigateToCandidatePayload = {
  command: "navigate-to-candidate";
  greenhouseProfileUrl: string;
};

type SetPanelWidthPayload = {
  command: "set-panel-width";
  value: 460 | 576 | 920;
};

type SetFrameVariantPayload = {
  command: "set-frame-variant";
  value: ExtensionFrameVariant;
};

type ToggleSidepanelPointerEventsPayload = {
  command: "update-sidepanel-pointer-events";
  value: "auto" | "none";
};

type UpdatePopoverBoundingRect = {
  command: "update-popover-bounding-rect";
  value: BoundingRect;
};

type UpdateInteractableBoundingRectsPayload = {
  command: "update-interactable-bounding-rects";
  value: BoundingRect[];
};

type NotificationCountPayload = {
  command: "unread-notification-count";
  value: number;
};

type SendToastPayload = {
  command: "send-toast";
  value: {
    message: string;
    options: {
      variant: "info" | "error" | "success";
      icon?: {
        name: string;
        color?: string;
      };
      description?: string;
      actions?: {
        label: string;
        onClick: {
          href: string;
          target?: "_blank" | "_guideIframe";
        };
        variant?: "default" | "primary";
        isDismissAction?: boolean;
      }[];
    };
  };
};

export type OutboundMessage =
  | SetPanelOpenStatePayload
  | DeprecatedShowPersistentButtonPayload
  | UpdateSidepanelIframeInitializedPayload
  | ToggleSidepanelPointerEventsPayload
  | UpdatePopoverBoundingRect
  | UpdateInteractableBoundingRectsPayload
  | RequestVersionPayload
  | ReloadPayload
  | DeprecatedReloadPayload
  | DeprecatedTogglePanelPayload
  | RequestParentHrefPayload
  | FillGuideUrlPayload
  | FlagsLoadedPayload
  | SidepanelIframeInitializedPayload
  | ModalIframeInitializedPayload
  | FlagsLoadedPayload
  | DeprecatedShowMessageButtonPayload
  | NavigateToCandidatePayload
  | RequestPanelOpenStatePayload
  | AuthenticationContextUpdatedPayload
  | SetFrameVariantPayload
  | SetPanelWidthPayload
  | NotificationCountPayload
  | SendToastPayload;

type Listener = (msg: Message) => void;

export default (listener: Listener, fromOrigin?: string | string[]): void => {
  useEffect(() => {
    const handle = (msg: Message) => {
      if (
        process.env.NODE_ENV !== "production" ||
        (msg.origin &&
          (!fromOrigin ||
            (Array.isArray(fromOrigin)
              ? fromOrigin.includes(msg.origin)
              : fromOrigin === msg.origin)))
      ) {
        listener(msg);
      }
    };

    window.addEventListener("message", handle);
    return () => {
      window.removeEventListener("message", handle);
    };
  }, [listener, fromOrigin]);
};
