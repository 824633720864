/* eslint-disable react/destructuring-assignment */
import { DynamicCollectionItem } from "@resource/atlas/__utils/collections";
import { useEvent } from "@resource/atlas/__utils/react";
import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { useDialogState } from "@resource/atlas/dialog/use-dialog-state";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarEvent,
  atlasCalendarRescheduleAlt,
  atlasCheck,
  atlasClock,
  atlasEdit5,
  atlasRingCross,
} from "@resource/atlas/icons";
import { AtlasMenuContentItem, Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import OptionalTooltip from "@resource/atlas/tooltip/OptionalTooltip";
import { useLogEvent } from "analytics";
import { GuideAuthRequiredButton } from "client/app/(candidate guides)/guide/[organizationSlug]/[shortId]/__components/guide-auth/GuideAuthRequiredButton";
import { InterviewDetailsDialog } from "client/components/guide/interviews/InterviewDetailsDialog";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { useHomepageRedirect } from "client/components/guide/useHomePageRedirect";
import { useFlags } from "client/lib/launchdarkly";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import FeatureFlags from "generated/FeatureFlags";
import {
  AvailableSlotFragment,
  GuideStatusEnum,
  SelfScheduleRequestForBlockFragment,
  SelfScheduleRequestStatus,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import NextLink from "next/link";
import { useEffect } from "react";
import { SelfScheduleRequestForCreate } from "shared/self-schedule/types";

import { AvailableSlotsDisplayInfo } from "./AvailableSlotsDisplayInfo";
import { CancelSelfScheduleRequestConfirmationDialog } from "./CancelSelfScheduleRequestConfirmationDialog";
import { CreateOrUpdateSelfScheduleSubmissionDialog } from "./CreateOrUpdateSelfScheduleSubmissionDialog";

export function SelfScheduleRequestBlockLoading() {
  return (
    <div className="w-full shadow-1 rounded-md p-3 gap-2 flex items-center">
      <Skeleton type="circle" className="w-10 h-10" />
      <div className="grow space-y-2 flex flex-col">
        <Skeleton className="w-4/5 h-[14px]" type="text" />
        <Skeleton className="w-4/5 h-[14px]" type="text" />
      </div>
      <Skeleton type="rect" className="h-6 w-6" />
    </div>
  );
}

type BaseProps = {
  className?: string;
  /** If passed will display a `View request` option in the dropdown */
  requestHref?: string | null;
  disabledTooltip?: string;
  warningMessages?: {
    icon: string;
    message: string;
  }[];
};

type SelfScheduleRequestBlockUncreatedProps = BaseProps & {
  interviewSettings: SelfScheduleRequestForCreate["interviewSettings"];
  availableSlots?: AvailableSlotFragment[] | null;
  availableSlotsLoading?: boolean;
};

type SelfScheduleRequestBlockCreatedProps = BaseProps & {
  selfScheduleRequest: SelfScheduleRequestForBlockFragment;
};

// Can either pass an input interview or an already created self schedule request
type SelfScheduleBlockBaseProps =
  | SelfScheduleRequestBlockUncreatedProps
  | SelfScheduleRequestBlockCreatedProps;

type SelfScheduleCandidateProps = SelfScheduleBlockBaseProps & {
  isCandidate: true;
  onSchedule: () => void;
};

type SelfScheduleGuideProps = SelfScheduleBlockBaseProps & {
  isCandidate?: false;
  onEdit?: () => void;
  hideAvailableSlotsDisplay?: boolean;
};

export type SelfScheduleRequestBlockProps =
  | SelfScheduleCandidateProps
  | SelfScheduleGuideProps;

function useInterviewSettings(props: SelfScheduleRequestBlockProps) {
  return "interviewSettings" in props
    ? props.interviewSettings
    : props.selfScheduleRequest.interviewSettings ?? null;
}

function useSelfScheduleRequest(props: SelfScheduleRequestBlockProps) {
  return "selfScheduleRequest" in props ? props.selfScheduleRequest : null;
}

function useIsCancelled(props: SelfScheduleRequestBlockProps) {
  const selfScheduleRequest = useSelfScheduleRequest(props);

  return selfScheduleRequest?.status === SelfScheduleRequestStatus.ARCHIVED;
}

function useDisableSelfSchedulingActions(props: SelfScheduleRequestBlockProps) {
  const selfScheduleRequest = useSelfScheduleRequest(props);
  const {
    [FeatureFlags.DISABLE_RESCHEDULE_FOR_REJECTED_CANDIDATES]:
      disableRescheduleForRejectedCandidates,
  } = useFlags();
  return (
    disableRescheduleForRejectedCandidates &&
    selfScheduleRequest?.guide?.status === GuideStatusEnum.REJECTED
  );
}

function useScheduleOnLoad(props: SelfScheduleRequestBlockProps) {
  const selfScheduleRequest = useSelfScheduleRequest(props);
  const disableSelfSchedulingActions = useDisableSelfSchedulingActions(props);

  const openSelfSchedule = useEvent(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (
      !disableSelfSchedulingActions &&
      urlParams.get("showSelfSchedule") === selfScheduleRequest?.id &&
      props.isCandidate &&
      selfScheduleRequest.status === SelfScheduleRequestStatus.OPEN
    ) {
      props.onSchedule?.();
    }
  });

  useEffect(() => {
    if (props.isCandidate) {
      openSelfSchedule();
    }
  }, [openSelfSchedule, selfScheduleRequest?.id, props.isCandidate]);
}

export function SelfScheduleRequestBlock(props: SelfScheduleRequestBlockProps) {
  const { className, disabledTooltip, warningMessages } = props;
  const interviewSettings = useInterviewSettings(props);
  const selfScheduleRequest = useSelfScheduleRequest(props);
  const isCancelled = useIsCancelled(props);
  useScheduleOnLoad(props);

  const disableSelfSchedulingActions = useDisableSelfSchedulingActions(props);

  return (
    <OptionalTooltip
      content={disabledTooltip}
      className="flex w-full"
      isInstant
    >
      <div
        className={clsx(
          "flex flex-col w-full border rounded-md border-gray-border",
          !!disabledTooltip && "opacity-70 pointer-events-none",
          className
        )}
        data-id={selfScheduleRequest?.id}
      >
        <div className="p-3 space-y-4">
          <div className="flex flex-row gap-2">
            <div className="flex">
              <div className="w-10 h-10 bg-blue-50 flex items-center justify-center rounded-full">
                <Icon
                  content={atlasCalendarEvent}
                  className="w-5 h-5 text-blue-500"
                />
              </div>
              <Avatar
                name={interviewSettings.interviewer.name}
                image={interviewSettings.interviewer.imageUrl}
                size="large"
                className="ring-2 ring-white"
                style={{
                  marginLeft: "-.25rem",
                }}
              />
            </div>
            <div
              className={clsx("grow", {
                "line-through": isCancelled,
              })}
            >
              <InterviewTitle
                interview={interviewSettings}
                className="text-body-md-heavy text-dark"
              />
              <div className="text-body-sm">
                <div className="flex gap-2 items-center">
                  <span className="text-body-sm text-subtle">
                    {interviewSettings.duration} minutes
                  </span>
                </div>
              </div>
            </div>
          </div>
          <ActionButtons {...props} disabled={disableSelfSchedulingActions} />
        </div>
        <AvailableSlotsDisplay {...props} />
        {warningMessages && warningMessages.length > 0 && (
          <div className="space-y-2">
            {warningMessages.map(({ icon, message }) => (
              <div
                className="bg-yellow-50 text-body-md text-yellow-900 p-2 space-y-2"
                key={message}
              >
                <div className="flex flex-row space-x-2">
                  <Icon content={icon} className="text-yellow-500 w-5 h-5" />
                  <span>{message}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </OptionalTooltip>
  );
}

function ActionButtons(
  props: SelfScheduleRequestBlockProps & { disabled?: boolean }
) {
  const logEvent = useLogEvent({
    component: "SelfScheduleRequestBlock",
  });
  const selfScheduleRequest = useSelfScheduleRequest(props);
  const { requestHref } = props;
  const cancelRequestConfirmationDialogStore = useDialogStore();
  const isCancelled = useIsCancelled(props);
  const interviewDetailsDialogState = useDialogState();
  const rescheduleDialogStore = useDialogStore();

  const redirectOnSubmit = useHomepageRedirect();

  const menuItems = useMenuItems(
    (i) => {
      const items: DynamicCollectionItem<AtlasMenuContentItem>[] = [];

      if (requestHref) {
        items.push(
          i.item({
            children: "View request",
            render: ({ children }) => (
              <NextLink href={requestHref} target="_blank">
                <OptionItem>{children}</OptionItem>
              </NextLink>
            ),
          })
        );
      }

      if (!props.isCandidate && props.onEdit) {
        items.push(
          i.item({
            children: "Edit request",
            onClick: props.onEdit,
          })
        );
      }

      if (selfScheduleRequest) {
        items.push(
          i.separator({
            key: "separator",
          })
        );
        items.push(
          i.item({
            children: "Cancel request",
            isDestructive: true,
            onClick: () => {
              logEvent("Cancel Self-schedule Request Clicked");
              cancelRequestConfirmationDialogStore.show();
            },
          })
        );
      }

      return items;
    },
    [
      logEvent,
      cancelRequestConfirmationDialogStore,
      props,
      requestHref,
      selfScheduleRequest,
    ]
  );

  if (isCancelled) {
    let text = "Cancelled";

    if (selfScheduleRequest?.archivedAt) {
      text += ` on ${DateTime.fromISO(selfScheduleRequest.archivedAt).toFormat(
        "MMM d"
      )}`;
    }

    if (selfScheduleRequest?.archivedBy) {
      text += ` by ${selfScheduleRequest.archivedBy.name}`;
    }

    return (
      <div className="text-red-500 flex flex-row space-x-2">
        <Icon content={atlasRingCross} className="w-5 h-5" />
        <span>{text}</span>
      </div>
    );
  }

  if (selfScheduleRequest?.completedBySubmission) {
    const { completedBySubmission } = selfScheduleRequest;
    const { scheduledInterview } = completedBySubmission;

    if (!scheduledInterview) {
      return null;
    }

    return (
      <div className="space-y-4">
        <ButtonGroup>
          <Button
            variant="default"
            icon={atlasCalendarEvent}
            onClick={interviewDetailsDialogState.show}
            disabled={props.disabled}
          >
            View interview
          </Button>
          {props.isCandidate && (
            <GuideAuthRequiredButton
              variant="white"
              icon={atlasCalendarRescheduleAlt}
              onClick={() => {
                logEvent("Reschedule Self-scheduled Interview Clicked");
                rescheduleDialogStore.show();
              }}
              disabled={props.disabled}
            >
              Reschedule
            </GuideAuthRequiredButton>
          )}
        </ButtonGroup>
        <div className="text-subtle flex flex-row space-x-2">
          <Icon content={atlasCheck} className="w-5 h-5" />
          <span>
            Scheduled on{" "}
            {DateTime.fromISO(completedBySubmission.createdAt).toFormat(
              "MMM d"
            )}{" "}
            by {completedBySubmission.createdBy.name}
          </span>
        </div>
        <InterviewDetailsDialog
          interview={scheduledInterview}
          {...interviewDetailsDialogState}
        />
        <CreateOrUpdateSelfScheduleSubmissionDialog
          store={rescheduleDialogStore}
          selfScheduleRequestId={selfScheduleRequest.id}
          scheduledInterviewId={scheduledInterview.id}
          onComplete={redirectOnSubmit}
        />
      </div>
    );
  }

  if (props.isCandidate) {
    return (
      <div>
        <GuideAuthRequiredButton
          variant="primary"
          icon={atlasCalendarEvent}
          onClick={() => {
            logEvent("Open Self-schedule Interview Workflow Clicked");
            props.onSchedule?.();
          }}
          disabled={props.disabled}
        >
          Schedule your interview
        </GuideAuthRequiredButton>
      </div>
    );
  }

  // If we have edit and we don't have a created scheduling request, show edit request as the primary action
  return props.onEdit && "interviewSettings" in props ? (
    <Button variant="default" icon={atlasEdit5} onClick={props.onEdit}>
      Edit request
    </Button>
  ) : (
    <div>
      <Menu.Root sameWidth>
        <Menu.Trigger>
          <Button variant="default" isDropdown icon={atlasClock}>
            Waiting for response
          </Button>
        </Menu.Trigger>
        <Menu.Content items={menuItems} />
      </Menu.Root>
      {selfScheduleRequest && (
        <CancelSelfScheduleRequestConfirmationDialog
          store={cancelRequestConfirmationDialogStore}
          selfScheduleRequestId={selfScheduleRequest.id}
          interviewTitle={selfScheduleRequest.interviewSettings.title}
        />
      )}
    </div>
  );
}

function useAvailableSlotData(props: SelfScheduleRequestBlockProps) {
  if ("interviewSettings" in props) {
    return {
      availableSlots: props.availableSlots,
      loading: props.availableSlotsLoading,
    };
  }

  return {
    availableSlots: props.selfScheduleRequest.availableSlots,
    loading: false,
  };
}

function AvailableSlotsDisplay(props: SelfScheduleRequestBlockProps) {
  const { isCandidate } = props;
  const selfScheduleRequest = useSelfScheduleRequest(props);
  const { availableSlots, loading } = useAvailableSlotData(props);

  if (
    isCandidate ||
    (selfScheduleRequest &&
      selfScheduleRequest.status !== SelfScheduleRequestStatus.OPEN) ||
    props.hideAvailableSlotsDisplay
  ) {
    return null;
  }

  return (
    <AvailableSlotsDisplayInfo
      availableSlots={availableSlots ?? []}
      loading={loading}
    />
  );
}
