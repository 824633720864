import { Button } from "@resource/atlas/button/Button";
import { atlasBarChart } from "@resource/atlas/icons";
import { AtlasMenuContentItem, Menu } from "@resource/atlas/menu";
import { AtlasPopoverState, Popover } from "@resource/atlas/popover";
import { strings } from "@resource/common";
import clsx from "clsx";
import { countBy, map, range } from "lodash";
import { DateTime } from "luxon";
import { MouseEvent, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  YAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";

const getTimeRanges = (startDate: string) => {
  const allTimeDays = Math.max(
    Math.ceil(DateTime.now().diff(DateTime.fromISO(startDate), "days").days),
    30
  );
  const timeRanges = [
    { id: "7_days", days: 7, label: "Last 7 days" },
    { id: "14_days", days: 14, label: "Last 14 days" },
    { id: "30_days", days: 30, label: "Last 30 days" },
    { id: "all_time", days: allTimeDays, label: "All time" },
  ];
  return timeRanges;
};

const getViewsByDate = (views: string[], days: number) => {
  const format = DateTime.DATE_MED;
  const viewsByDate = countBy(views, (openedAt) =>
    DateTime.fromISO(openedAt).toLocaleString(format)
  );
  // Including today in the range by using negative 1
  const now = DateTime.local();
  return map(range(days - 1, -1), (subDays) => {
    const date = now.minus({ days: subDays }).toLocaleString(format);
    return { date, views: viewsByDate[date] ?? 0 };
  });
};

function ViewsTooltip({ active, payload }: TooltipProps<string, string>) {
  if (!active) {
    return null;
  }
  const { date, views } = payload?.[0].payload;
  return (
    <div className="bg-dark-gray-500 p-2 rounded">
      <p className="text-body-sm text-subtle">{date}</p>
      <p className="text-body-sm-heavy text-white">{views} views</p>
    </div>
  );
}

type ViewsPopoverProps = {
  title: string;
  views: string[];
  startDate: string;
  popoverState?: AtlasPopoverState;
  variant?: "default" | "compact";
  className?: string;
};

export function ViewsPopover({
  title,
  views,
  startDate,
  popoverState,
  className,
  variant = "default",
}: ViewsPopoverProps) {
  const timeRanges = useMemo(() => getTimeRanges(startDate), [startDate]);
  const [timeRange, setTimeRange] = useState(timeRanges[2]);

  const [focusedIndex, setFocusedIndex] = useState<number>();
  const viewsByDate = useMemo(
    () => getViewsByDate(views, timeRange.days),
    [views, timeRange.days]
  );
  const stats = useMemo(
    () =>
      viewsByDate.reduce(
        (obj, item) => ({
          views: obj.views + item.views,
          max: Math.max(obj.max, item.views),
        }),
        { views: 0, max: 0 }
      ),
    [viewsByDate]
  );

  const timeOptions = useMemo(
    () =>
      timeRanges.map(
        (timeRangeItem): AtlasMenuContentItem => ({
          _type: "item",
          children: timeRangeItem.label,
          key: timeRangeItem.id,
          isSelectable: true,
          isSelected: timeRangeItem.id === timeRange.id,
          onSelect: () => setTimeRange(timeRangeItem),
        })
      ),
    [timeRanges, timeRange]
  );

  const onMouseEvent = (state: CategoricalChartState) => {
    setFocusedIndex(state.activeTooltipIndex);
  };

  // Using this to always keep the top grid line higher than bar height by 20%
  const maxPoint = Math.max(1.2 * stats.max, 1);

  return (
    <Popover.Root state={popoverState}>
      {!popoverState && (
        <Popover.Trigger>
          <Button
            icon={atlasBarChart}
            isGhost
            size="small"
            className={clsx("!font-normal", className)}
          >
            {variant === "compact"
              ? views.length
              : strings.pluralize("view", views.length)}
          </Button>
        </Popover.Trigger>
      )}
      <Popover.Content onClick={(e: MouseEvent) => e.stopPropagation()}>
        <Popover.Heading className="text-body-md-heavy flex justify-between items-center gap-10">
          <p className="text-body-lg">{title}</p>
          <Menu.Root>
            <Menu.Trigger>
              <Button isGhost isDropdown size="small">
                {timeRange.label}
              </Button>
            </Menu.Trigger>
            <Menu.Content defaultSize="compact" items={timeOptions} portal />
          </Menu.Root>
        </Popover.Heading>
        <div className="flex justify-between items-center" />
        <div className="flex mt-2 gap-4">
          <div className="w-full border border-light-gray-600 rounded-lg p-4">
            <h3 className="text-h3 text-dark">{stats.views}</h3>
            <p className="text-subtle text-body-sm-heavy">Views</p>
            <div className="mt-3">
              <ResponsiveContainer width="100%" height={48}>
                <BarChart
                  data={viewsByDate}
                  onMouseMove={onMouseEvent}
                  onMouseLeave={onMouseEvent}
                  barGap={timeRange.days > 30 ? "1px" : "2px"}
                  // Chart requires 1px top margin to prevent clipping the top most grid line
                  margin={{ top: 1, left: 0, right: 0, bottom: 0 }}
                >
                  <CartesianGrid
                    vertical={false}
                    strokeDasharray="3 3"
                    strokeWidth="1px"
                    stroke="#e8ebed"
                  />
                  <YAxis
                    hide
                    interval={0}
                    ticks={[maxPoint / 2]}
                    domain={["dataMin", maxPoint]}
                  />
                  <Tooltip
                    content={ViewsTooltip}
                    cursor={{ fill: "transparent" }}
                  />
                  <Bar dataKey="views" fill="#C7B6FF" minPointSize={1}>
                    {viewsByDate.map((entry, index) => (
                      <Cell
                        key={entry.date}
                        fill={focusedIndex === index ? "#7348FF" : "#C7B6FF"}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
