import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  Modifier,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { Button } from "@resource/atlas/button/Button";
import { atlasPlus } from "@resource/atlas/icons";
import TextField from "@resource/atlas/textfield/TextField";
import { Events } from "analytics/types";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { SortableStageItem } from "./SortableStage";
import { InterviewPlanItem } from "./types";

type ReorderableStagesProps = {
  // We need to offset the drag and drop by the container transform
  containerRef: React.MutableRefObject<HTMLDivElement | null>;

  stages?: InterviewPlanItem[];

  selectedStage: InterviewPlanItem | null;
  setSelectedStage: (stage: { id: string }) => unknown;

  createStage: (name: string) => unknown;
  reorderStages: (items: { id: string }[]) => unknown;
  removeStage: (stage: { id: string }) => unknown;

  logEvent: (name: Events, data?: Record<string, unknown>) => void;
};

export function ReorderableStages({
  containerRef,
  stages,
  createStage,
  reorderStages,
  selectedStage,
  setSelectedStage,
  removeStage,
  logEvent,
}: ReorderableStagesProps) {
  const [draggingId, setDraggingId] = useState<string>();
  const [editing, setEditing] = useState(false);
  const [newStageName, setNewStageName] = useState("");

  const editRef = useRef<HTMLInputElement | null>(null);

  const offsetModifier: Modifier = ({ transform }) => {
    const offset = containerRef.current?.getBoundingClientRect().top ?? 0;
    return {
      ...transform,
      y: transform.y - offset,
    };
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setDraggingId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      setDraggingId(undefined);
      const { active, over } = event;
      if (stages && over && active.id !== over?.id) {
        const oldIndex = stages.findIndex(({ id }) => active.id === id);
        const newIndex = stages.findIndex(({ id }) => over.id === id);
        const reorderedStages = arrayMove(stages, oldIndex, newIndex);
        logEvent("Interview plan stages reordered");
        reorderStages(reorderedStages);
      }
    },
    [logEvent, stages, reorderStages]
  );

  useEffect(() => {
    if (editing) {
      editRef.current?.focus();
    }
  }, [editing, editRef]);

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <div>
          <div className="mt-3 space-y-2" style={{ transform: "none" }}>
            <div>
              {stages && stages.length > 0 && (
                <SortableContext items={stages}>
                  {stages.map((stage, stageIndex) => (
                    <React.Fragment key={stage.id}>
                      <SortableStageItem
                        isDraggable
                        isDragging={stage.id === draggingId}
                        stage={stage}
                        stageIndex={stageIndex}
                        selected={selectedStage}
                        onSelect={(newSelectedStage) => {
                          logEvent("Interview plan stage selected");
                          setSelectedStage(newSelectedStage);
                        }}
                        removeStage={(stageToRemove) => {
                          logEvent("Interview plan stage removed");
                          removeStage(stageToRemove);
                        }}
                      />
                      {stage.id === draggingId && (
                        <DragOverlay
                          className="cursor-grabbing"
                          modifiers={[offsetModifier]}
                        >
                          <div className="pointer-events-none">
                            <SortableStageItem
                              isDraggable
                              isDragOverlay
                              key={stage.id}
                              stage={stage}
                              stageIndex={stageIndex}
                              selected={selectedStage}
                              onSelect={(newSelectedStage) => {
                                logEvent("Interview plan stage selected");
                                setSelectedStage(newSelectedStage);
                              }}
                              removeStage={(stageToRemove) => {
                                logEvent("Interview plan stage removed");
                                removeStage(stageToRemove);
                              }}
                            />
                          </div>
                        </DragOverlay>
                      )}
                    </React.Fragment>
                  ))}
                </SortableContext>
              )}
            </div>
          </div>
        </div>
      </DndContext>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          logEvent("Interview plan stage added");
          createStage(newStageName);
          setNewStageName("");
        }}
      >
        <TextField
          inputRef={editRef}
          className={clsx("mb-2", { hidden: !editing })}
          placeholder="e.g. Phone Screen, Assessment, etc."
          aria-label="Stage name"
          isRequired
          onBlur={() => setEditing(false)}
          value={newStageName}
          onChange={(e) => setNewStageName(e)}
        />
        <Button
          icon={atlasPlus}
          className="w-full"
          onClick={() => setEditing(true)}
        >
          Add stage
        </Button>
      </form>
    </>
  );
}
