import {
  useFormatContext,
  useHeadingContext,
  useLinkContext,
  useListContext,
  useParagraphContext,
  useSelectionContext,
} from "./contexts";
import { createSelectionContext } from "./lib/create-selection-context";

export const [
  SelectionContextProvider,
  { useFormat, useHeading, useLink, useList, useParagraph, useSelection },
] = createSelectionContext({
  useFormat: useFormatContext,
  useHeading: useHeadingContext,
  useLink: useLinkContext,
  useList: useListContext,
  useParagraph: useParagraphContext,
  useSelection: useSelectionContext,
});
