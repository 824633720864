import FeatureFlags from "generated/FeatureFlags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

export type RescheduleableInterview = {
  id: string;
  interviewRequirementId: string;
  isV2: boolean;
  scheduledInterviewGroupId: string | null;
  isCancelled: boolean;
  isSelfScheduled: boolean;
  guideId: string | null;
  schedulingRequestId: string | null;
  createdByGuide: boolean;
};

/**
 * Cancel during the reschedule task creation workflow is only allowed for v2 panel interviews.
 * If any other type of interview is present, we disallow cancelling anything during the workflow.
 * This avoids UI confusion and edge cases wrt what will be cancelled and what messages will be sent.
 * Also returns false if all of the selected interviews are already cancelled.
 */
export function useAllowCancelInterviewsDuringReschedule({
  reschedulableInterviews,
  selectedRescheduleInterviewRequirementIds,
}: {
  reschedulableInterviews: RescheduleableInterview[];
  selectedRescheduleInterviewRequirementIds: string[];
}) {
  const {
    [FeatureFlags.ALLOW_CANCEL_INTERVIEWS_DURING_RESCHEDULE_TASK_CREATION]:
      cancelDuringRescheduleEnabled,
  } = useFlags();

  //
  const hasDisallowedInterviews = useMemo(
    () =>
      reschedulableInterviews.some(
        (interview) => !interviewIsV2PanelInterview(interview)
      ),
    [reschedulableInterviews]
  );

  const interviewsToCancel = useInterviewsToCancel({
    reschedulableInterviews,
    selectedRescheduleInterviewRequirementIds,
  });

  const allowCancelInterviews =
    cancelDuringRescheduleEnabled &&
    !hasDisallowedInterviews &&
    interviewsToCancel.length > 0;

  return allowCancelInterviews;
}

/** Returns a list of selected interviews that are cancelable during the reschedule task creation workflow. */
export default function useInterviewsToCancel({
  reschedulableInterviews,
  selectedRescheduleInterviewRequirementIds,
}: {
  reschedulableInterviews: RescheduleableInterview[];
  selectedRescheduleInterviewRequirementIds: string[];
}) {
  return useMemo(() => {
    return selectedRescheduleInterviewRequirementIds
      .map((id) =>
        reschedulableInterviews.find(
          (interview) => interview.interviewRequirementId === id
        )
      )
      .filter((interview) => interview && interviewCanBeCancelled(interview));
  }, [selectedRescheduleInterviewRequirementIds, reschedulableInterviews]);
}

function interviewIsV2PanelInterview(interview: RescheduleableInterview) {
  return (
    interview.isV2 &&
    interview.scheduledInterviewGroupId &&
    interview.guideId &&
    interview.schedulingRequestId &&
    interview.createdByGuide &&
    !interview.isSelfScheduled
  );
}

function interviewCanBeCancelled(interview: RescheduleableInterview) {
  return interviewIsV2PanelInterview(interview) && !interview.isCancelled;
}
