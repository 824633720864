import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";

type CalendarIconProps = ComponentPropsWithoutRef<"div"> & {
  isInPast?: boolean;
  day: string;
  dayName: string;
  size?: "sm" | "md" | "lg";
  dayClassname?: string;
  dayNameClassname?: string;
};
export function CalendarIcon({
  isInPast,
  day,
  dayName,
  className,
  dayClassname,
  dayNameClassname,
  size = "md",
  ...props
}: CalendarIconProps) {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center uppercase bg-white shadow-1 shadow-light-gray-500 rounded-md",
        {
          "h-14 w-14 gap-1": size === "lg",
          "h-[2.875rem] w-[2.875rem] md:w-[3.25rem] md:h-[3.25rem] gap-1":
            size === "md",
          "w-[2.5rem] h-[2.5rem] pt-[.125rem]": size === "sm",
        },
        className
      )}
      {...props}
    >
      <p
        className={clsx(
          "font-bold leading-[0.563rem] text-[0.563rem]",
          {
            "md:text-[0.688rem]": size === "md",
            "leading-[0.6875rem] text-[0.6875rem]": size === "lg",
          },
          isInPast ? "text-disabled" : "text-accent",
          dayNameClassname
        )}
      >
        {dayName}
      </p>
      <p
        className={clsx(
          "font-bold leading-6 text-[1.25rem]",
          {
            "md:text-[1.375rem]": size === "md",
            "tracking-[-0.03125rem]": size === "sm",
            "text-[1.75rem]": size === "lg",
          },
          isInPast ? "text-disabled" : "text-dark",
          dayClassname
        )}
      >
        {day}
      </p>
    </div>
  );
}

export function CalendarIconV2({
  isInPast,
  day,
  dayName,
  className,
  dayClassname,
  dayNameClassname,
  ...props
}: CalendarIconProps) {
  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center gap-1 w-[2.875rem] h-[2.875rem] md:w-[3.25rem] md:h-[3.25rem] uppercase",
        className
      )}
      {...props}
    >
      <p
        className={clsx(
          "font-bold leading-5 text-[1.25rem] md:text-[1.375rem]",
          isInPast ? "text-disabled" : "text-dark",
          dayClassname
        )}
      >
        {day}
      </p>
      <p
        className={clsx(
          "font-bold leading-[0.563rem] text-[0.563rem] md:text-[0.688rem] ",
          isInPast ? "text-disabled" : "text-accent",
          dayNameClassname
        )}
      >
        {dayName}
      </p>
    </div>
  );
}
