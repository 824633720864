import { Icon } from "@resource/atlas/icon/Icon";
import { atlasDoor, atlasRingInfo, atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import TextField from "@resource/atlas/textfield/TextField";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { RequirementText } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/display/RequirementText";
import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { useConferenceRoomSelectOptions } from "../hooks/useConferenceRoomSelectOptions";
import {
  Building,
  ConferenceRoom,
  ConferenceRoomSettings,
} from "../utils/types";
import {
  ConferenceRoomCard,
  ConferenceRoomCardSkeleton,
} from "./ConferenceRoomCard";

type EditConferenceRoomSlotProps = {
  onSelect: (rooms: ConferenceRoom[]) => void;
  selectedIds?: string[];
  disabled?: boolean;
  buildings: Building[];
  conferenceRooms: ConferenceRoom[];
  loading?: boolean;
  onOpen?: () => void;
  settings?: ConferenceRoomSettings | null;
  originalSettings?: ConferenceRoomSettings | null;
  onChangeSettings?: (settings: ConferenceRoomSettings | null) => void;
};

export function EditConferenceRoomSlot({
  onSelect,
  selectedIds,
  disabled,
  buildings,
  conferenceRooms,
  loading,
  onOpen,
  settings,
  originalSettings,
  onChangeSettings,
}: EditConferenceRoomSlotProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const popoverState = usePopoverState({
    placement: "top-start",
    setOpen: (open) => {
      if (open) {
        onOpen?.();
      } else {
        // reset search term on closed
        setSearchTerm("");
      }
    },
  });

  const options = useConferenceRoomSelectOptions({
    buildings,
    conferenceRooms,
    searchTerm,
    selectedIds,
    onClick: (conferenceRoom) => {
      onSelect([conferenceRoom]);
      popoverState.hide();
    },
  });

  const selectedRooms = useMemo(() => {
    return (
      selectedIds
        ?.map((id): ConferenceRoom | null => {
          const conferenceRoom = conferenceRooms.find((v) => v.id === id);

          if (!conferenceRoom) {
            return null;
          }

          return conferenceRoom;
        })
        .filter(filterOutNullsAndUndefined) ?? []
    );
  }, [conferenceRooms, selectedIds]);

  const removeRoom = useCallback(
    (room: ConferenceRoom) => {
      onSelect(selectedRooms.filter((v) => v.id !== room.id));
    },
    [onSelect, selectedRooms]
  );

  return (
    <FormGroup
      label="Conference room"
      isRequired={!!settings?.required}
      subText={
        <ConferenceRoomRequirements
          settings={settings}
          originalSettings={originalSettings}
          buildings={buildings}
          onChangeSettings={onChangeSettings}
          selectedIds={selectedIds}
        />
      }
      Action={
        <ConferenceRoomRequirementsAction
          settings={settings}
          originalSettings={originalSettings}
          buildings={buildings}
        />
      }
    >
      <div className="flex flex-col gap-2">
        <div>
          <Popover.Root state={popoverState}>
            <Popover.Trigger disabled={disabled}>
              <SelectTrigger
                className={clsx("flex-1 w-full !bg-light-gray-500")}
              >
                <div className="flex gap-1.5 items-center mr-1">
                  <Icon content={atlasDoor} className="text-subtle" />
                  <p className="text-body-md text-subtle">Room</p>
                </div>
              </SelectTrigger>
            </Popover.Trigger>
            <Popover.Content
              className="w-[30rem]"
              hasPadding={false}
              portal
              header={
                <TextField
                  className="m-2"
                  size="small"
                  value={searchTerm}
                  autoFocus
                  icon={atlasSearch}
                  placeholder="Search"
                  aria-label="Search"
                  onChange={setSearchTerm}
                  isClearable
                />
              }
            >
              <div className="p-[.5rem] pt-0">
                {!loading && options.length > 0 && <>{options}</>}
                {!loading && options.length === 0 && (
                  <>
                    <OptionSeparator />
                    <p className="px-5 py-2 text-body-md">No results found</p>
                  </>
                )}
                {loading && (
                  <div className="flex w-full justify-center py-6">
                    <LoadingIndicator size="small" />
                  </div>
                )}
              </div>
            </Popover.Content>
          </Popover.Root>
        </div>

        <SelectedConferenceRooms
          selectedIds={selectedIds}
          loading={loading}
          selectedRooms={selectedRooms}
          removeRoom={removeRoom}
        />
      </div>
    </FormGroup>
  );
}

function ConferenceRoomRequirements({
  settings,
  originalSettings,
  buildings,
  onChangeSettings,
  selectedIds,
}: Pick<
  EditConferenceRoomSlotProps,
  "settings" | "originalSettings" | "onChangeSettings" | "buildings"
> & { selectedIds?: string[] }) {
  const requiredBuilding = useMemo(() => {
    if (settings?.building?.id) {
      return buildings.find((b) => b.id === settings?.building?.id);
    }

    return null;
  }, [buildings, settings?.building?.id]);
  const requiredBuildingText = useMemo(() => {
    return requiredBuilding ? ` in ${requiredBuilding.name}` : "";
  }, [requiredBuilding]);

  if (settings?.required && (!selectedIds || selectedIds.length === 0)) {
    return (
      <RequirementText
        text={`Conference room${requiredBuildingText} is required`}
        isError
        action={
          onChangeSettings
            ? {
                text: "Ignore",
                onClick: () => {
                  onChangeSettings(null);
                },
              }
            : undefined
        }
      />
    );
  }

  if (originalSettings?.required && !settings?.required) {
    return (
      <RequirementText
        text={`Requirement for conference room${requiredBuildingText} has been ignored.`}
        action={
          onChangeSettings
            ? {
                text: "Undo",
                onClick: () => {
                  onChangeSettings(originalSettings);
                },
              }
            : undefined
        }
      />
    );
  }

  return null;
}

export function ConferenceRoomRequirementsAction({
  settings,
  originalSettings,
  buildings,
}: Pick<
  EditConferenceRoomSlotProps,
  "settings" | "originalSettings" | "buildings"
>) {
  const text = useMemo(() => {
    if (settings?.required) {
      const requiredBuilding = buildings.find(
        (b) => b.id === settings.building?.id
      );
      const buildingText = requiredBuilding
        ? ` in ${requiredBuilding.name}`
        : "";
      return `Conference room${buildingText} is required.`;
    }

    if (originalSettings?.required && !settings?.required) {
      return "Conference room requirement has been ignored.";
    }

    return null;
  }, [settings, originalSettings, buildings]);

  if (!text) {
    return null;
  }

  return (
    <Tooltip isInstant content={text}>
      <Icon content={atlasRingInfo} />
    </Tooltip>
  );
}

function SelectedConferenceRooms({
  selectedIds,
  loading,
  selectedRooms,
  removeRoom,
}: {
  selectedIds?: string[];
  loading?: boolean;
  selectedRooms: ConferenceRoom[];
  removeRoom: (room: ConferenceRoom) => void;
}) {
  // Because we store a simplified form of the data in the DB, we have just an ID at first but
  // we don't have the full conference room data from Google. In that case, we should show
  // a skeleton loader to indicate we do have a selected conference room but we are fetching it

  const showLoading = useMemo(() => {
    return (
      selectedIds && selectedIds.length > 0 && loading && !selectedRooms.length
    );
  }, [selectedIds, loading, selectedRooms]);

  if (showLoading) {
    return <ConferenceRoomCardSkeleton />;
  }

  return (
    <>
      {selectedRooms.map((conferenceRoom) => (
        <ConferenceRoomCard
          key={conferenceRoom.id}
          conferenceRoom={conferenceRoom}
          onRemove={() => removeRoom(conferenceRoom)}
        />
      ))}
    </>
  );
}
