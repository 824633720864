import { ItemCreator } from "@resource/atlas/__utils/collections";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { AtlasMenuContentItem } from "@resource/atlas/menu";
import { useFlags } from "client/lib/launchdarkly";
import FeatureFlags from "generated/FeatureFlags";
import { useMemo } from "react";

import { EditRsvpDialog } from "../../EditRsvpDialog";

export function useEditRsvpMenuItem({
  interviewerId,
}: {
  interviewerId: string;
}) {
  const dialogStore = useDialogStore();

  const {
    [FeatureFlags.MANUAL_INTERVIEWER_RSVP_UPDATES]: allowManualRsvpUpdates,
  } = useFlags();

  return useMemo(() => {
    return {
      dialogStore,
      render: () => {
        return (
          <EditRsvpDialog
            dialogStore={dialogStore}
            interviewerId={interviewerId}
          />
        );
      },
      createItem: (i: ItemCreator<AtlasMenuContentItem>) => {
        if (!allowManualRsvpUpdates) {
          return null;
        }

        return i.item({
          key: "edit-rsvp",
          children: "Edit RSVP",
          onClick: () => {
            dialogStore.show();
          },
        });
      },
    };
  }, [allowManualRsvpUpdates, dialogStore, interviewerId]);
}
