import { Checkbox } from "@resource/atlas/checkbox/Checkbox";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import { serviceGoogleMeet, serviceZoom } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { useAuthContext } from "auth/context";
import { EditInterviewDialog } from "client/app/(job settings)/job-interviews/__components/EditInterviewDialog";
import { BuildingSelect } from "client/components/generic/select/BuildingSelect";
import { CollaborativeCodingSelect } from "client/components/generic/select/CollaborativeCodingSelect";
import { UserOrPoolSelect } from "client/components/generic/select/UserOrPoolSelect";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { ExcludedInterviewersCard } from "client/components/interview-requirements/ExcludedInterviewersCard";
import { InterviewerSelect } from "client/components/interviewer-slots/display/InterviewerSelect";
import {
  InterviewerPool,
  UserMembershipForForm,
} from "client/components/interviewer-slots/utils/types";
import { EventPrivacyFormGroup } from "client/components/scheduled-interviews/EventPrivacySelector/EventPrivacyFormGroup";
import { EditableDuration } from "client/components/time/EditableDuration";
import { AdvancedInterviewerTagsSchedulingDefaultsTour } from "client/components/tours/tours/AdvancedInterviewerTagsSchedulingDefaultsTour";
import { useFlags } from "client/lib/launchdarkly";
import { useFilteredInterviewerRequirements } from "client/utils/interviewRequirements";
import FeatureFlags from "generated/FeatureFlags";
import {
  CollaborativeCodingServiceType,
  InterviewerRequirementType,
  InterviewRequirementForConfigurationFragment,
  TagFilterType,
  VideoConferencingHostType,
  VideoConferencingServiceType,
} from "generated/graphql-codegen/graphql";
import { forwardRef, ReactNode, useCallback, useMemo } from "react";
import { formatEntity } from "shared/constants/entities";
import { v4 } from "uuid";

import { InterviewerConfigPersonDisplay } from "./InterviewerConfigPersonDisplay";
import { InterviewerPoolSelect } from "./InterviewerPoolSelect";
import { InterviewerSmartMatchSelect } from "./InterviewerSmartMatchSelect";
import { NoInterviewerRequirementsEmptyState } from "./NoInterviewerRequirementsEmptyState";

export const LINK_TO_INTERVIEWER_REQUIREMENTS_SUPPORT_ARTICLE =
  "https://support.guide.co/en/articles/8862502-adding-interviewer-requirements-for-scheduling";

export type VideoConferenceSelectTriggerProps = AtlasSelectTriggerProps & {
  label: ReactNode;
};

const VideoConferenceSelect = forwardRef<
  HTMLButtonElement,
  VideoConferenceSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref} className="bg-light-gray-500">
      {props.label}
    </SelectTrigger>
  );
});

type EditIndividualInterviewRequirementFormProps = {
  interviewRequirement: InterviewRequirementForConfigurationFragment | null;
  updateInterviewRequirement: (
    updatedInterview: InterviewRequirementForConfigurationFragment
  ) => void;
  onRemoveInterviewerRequirement: (props: {
    interviewerRequirementId: string;
  }) => void;
};

export function EditIndividualInterviewRequirementForm({
  interviewRequirement,
  updateInterviewRequirement,
  onRemoveInterviewerRequirement,
}: EditIndividualInterviewRequirementFormProps) {
  const { user } = useAuthContext();
  const editInterviewStore = useDialogStore();
  const { [FeatureFlags.INTERVIEWER_TAGS]: tagFiltersEnabled } = useFlags();

  const isZoomIntegrationInstalled = user?.currentOrganization?.isZoomEnabled;

  const isZoomRequired =
    interviewRequirement?.videoConferencingSettings?.service ===
    VideoConferencingServiceType.ZOOM;
  const isGoogleCalendarRequired =
    interviewRequirement?.videoConferencingSettings?.service ===
    VideoConferencingServiceType.GOOGLE_MEET;
  const videoConferencingRequired = isZoomRequired || isGoogleCalendarRequired;

  const hasCoderPad = !!user?.currentOrganization?.isCoderpadEnabled;
  const hasHackerRank = !!user?.currentOrganization?.isHackerRankEnabled;
  const hasCodeSignal = !!user?.currentOrganization?.isCodeSignalEnabled;
  const hasAnyCollaborativeCoding =
    hasCoderPad || hasHackerRank || hasCodeSignal;

  const defaultCollaborativeCodingService = useMemo(() => {
    if (hasHackerRank) {
      return CollaborativeCodingServiceType.HACKERRANK;
    }
    if (hasCodeSignal) {
      return CollaborativeCodingServiceType.CODESIGNAL;
    }

    return CollaborativeCodingServiceType.CODERPAD;
  }, [hasCodeSignal, hasHackerRank]);

  const isHackerRankRequired =
    interviewRequirement?.collaborativeCodingSettings?.service ===
    CollaborativeCodingServiceType.HACKERRANK;
  const isCoderPadRequired =
    interviewRequirement?.collaborativeCodingSettings?.service ===
    CollaborativeCodingServiceType.CODERPAD;
  const isCodeSignalRequired =
    interviewRequirement?.collaborativeCodingSettings?.service ===
    CollaborativeCodingServiceType.CODESIGNAL;
  const isCollaborativeCodingRequired =
    isHackerRankRequired || isCoderPadRequired || isCodeSignalRequired;

  const conferenceRoomRequired =
    !!interviewRequirement?.conferenceRoomSettings?.required;
  const selectedBuilding = useMemo(
    () =>
      interviewRequirement?.conferenceRoomSettings?.building
        ? {
            id: interviewRequirement.conferenceRoomSettings.building.id,
            name: interviewRequirement.conferenceRoomSettings.building.name,
          }
        : undefined,
    [interviewRequirement?.conferenceRoomSettings?.building]
  );

  const videoConferenceSelectItems = useSelectItems((i) => {
    return [
      i.option({
        value: "zoom",
        renderContent: ({ children }) => (
          <div className="flex items-center gap-2">
            <Icon content={serviceZoom} />
            <span>{children}</span>
          </div>
        ),
        children: "Zoom",
      }),
      i.option({
        value: "google-meet",
        renderContent: ({ children }) => (
          <div className="flex items-center gap-2">
            <Icon content={serviceGoogleMeet} />
            <span>{children}</span>
          </div>
        ),
        children: "Google Meet",
      }),
    ];
  }, []);

  const { interviewerRequirements, hiddenInterviewerRequirements } =
    useFilteredInterviewerRequirements({
      interviewerRequirements:
        interviewRequirement?.interviewerRequirements ?? [],
    });
  const excludedRequirement = useMemo(() => {
    return hiddenInterviewerRequirements.find((i) => i.isSynced);
  }, [hiddenInterviewerRequirements]);

  const shadowingEnabledForRequirement = interviewerRequirements.find(
    (i) => i.includeShadower
  );

  const onAddUser = useCallback(
    (newUser: UserMembershipForForm) => {
      if (!interviewRequirement) {
        throw new Error("Unable to add user to a null interview requirement");
      }
      updateInterviewRequirement({
        ...interviewRequirement,
        interviewerRequirements: [
          ...interviewRequirement.interviewerRequirements,
          {
            id: v4(),
            type: InterviewerRequirementType.PERSON,
            userMemberships: [
              {
                id: newUser.id,
                imageUrl: newUser.imageUrl,
                name: newUser.name,
              },
            ],
            includeShadower: false,
            isShadower: false,
            shadowingUserMemberships: [],
            interviewerPools: [],
            tagFilters: [],
            isSynced: false,
            syncedUserMemberships: [],
            useExternalInterviewerAlgorithm: false,
          },
        ],
      });
    },
    [interviewRequirement, updateInterviewRequirement]
  );

  const onAddSmartUser = useCallback(() => {
    if (!interviewRequirement) {
      throw new Error("Unable to add user to a null interview requirement");
    }
    updateInterviewRequirement({
      ...interviewRequirement,
      interviewerRequirements: [
        ...interviewRequirement.interviewerRequirements,
        {
          id: v4(),
          type: InterviewerRequirementType.SMART_MATCH,
          userMemberships: [],
          includeShadower: false,
          isShadower: false,
          shadowingUserMemberships: [],
          interviewerPools: [],
          tagFilters: [
            {
              id: v4(),
              type: TagFilterType.INCLUDE_ANY,
              tags: [],
            },
          ],
          isSynced: false,
          syncedUserMemberships: [],
          useExternalInterviewerAlgorithm: false,
        },
      ],
    });
  }, [interviewRequirement, updateInterviewRequirement]);

  const onAddPool = useCallback(
    (newInterviewerPool: InterviewerPool) => {
      if (!interviewRequirement) {
        throw new Error("Unable to add user to a null interview requirement");
      }
      updateInterviewRequirement({
        ...interviewRequirement,
        interviewerRequirements: [
          ...interviewRequirement.interviewerRequirements,
          {
            id: v4(),
            type: InterviewerRequirementType.TAG,
            userMemberships: [],
            shadowingUserMemberships: [],
            includeShadower: false,
            interviewerPools: [newInterviewerPool],
            tagFilters: [],
            isShadower: false,
            isSynced: false,
            useExternalInterviewerAlgorithm: false,
            syncedUserMemberships: [],
          },
        ],
      });
    },
    [interviewRequirement, updateInterviewRequirement]
  );

  const onChangePrivacy = useCallback(
    (isPrivate: boolean) => {
      if (!interviewRequirement) return;

      updateInterviewRequirement({
        ...interviewRequirement,
        isPrivate,
      });
    },
    [interviewRequirement, updateInterviewRequirement]
  );

  if (!interviewRequirement) {
    return (
      <div className="text-body-md text-red-500">No interview selected</div>
    );
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex gap-2">
        <div className="grow space-y-2">
          <div className="flex justify-between items-center">
            <p className="text-body-md-heavy">Candidate-facing title</p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              as="button"
              className="text-body-md"
              onClick={() => {
                editInterviewStore.show();
              }}
            >
              Edit
            </Link>
          </div>
          <div className="flex items-center justify-start px-2 bg-light-gray-500 truncate text-disabled rounded-md h-[2.5rem]">
            <InterviewTitle
              className="text-body-md"
              interview={interviewRequirement.interview}
            />
          </div>
        </div>
        <div className="w-[7.5rem] shrink-0 space-y-2">
          <p className="text-body-md-heavy">Duration</p>
          <EditableDuration
            currentDuration={interviewRequirement.duration}
            onSelectDuration={(newDuration) =>
              updateInterviewRequirement({
                ...interviewRequirement,
                duration: newDuration,
              })
            }
          />
        </div>
      </div>

      <div className="space-y-2">
        <div className="space-y-1 flex flex-col">
          <div className="text-body-md-heavy">Interviewers</div>
          {interviewerRequirements.length > 0 ? (
            <div className="text-subtle text-body-sm">
              {interviewerRequirements.length
                ? interviewerRequirements.length
                : "No"}{" "}
              {formatEntity("interviewer slot", {
                plural: interviewerRequirements.length !== 1,
              })}{" "}
              added.{" "}
              <Link
                href={LINK_TO_INTERVIEWER_REQUIREMENTS_SUPPORT_ARTICLE}
                target="blank"
              >
                Learn more
              </Link>
            </div>
          ) : null}
        </div>
        {interviewerRequirements.length === 0 ? (
          <>
            {tagFiltersEnabled ? (
              <InterviewerSelect
                onSelectUser={onAddUser}
                onSelectSmartMatch={onAddSmartUser}
                onSelectPool={onAddPool}
              />
            ) : (
              <NoInterviewerRequirementsEmptyState
                onSelectUser={onAddUser}
                onSelectPool={onAddPool}
              />
            )}
          </>
        ) : (
          <div className="space-y-2">
            {tagFiltersEnabled && (
              <InterviewerSelect
                onSelectUser={onAddUser}
                onSelectSmartMatch={onAddSmartUser}
                onSelectPool={onAddPool}
              />
            )}
            {interviewerRequirements.map((interviewerRequirement) => {
              const showShadowingToggle =
                !shadowingEnabledForRequirement ||
                shadowingEnabledForRequirement?.id ===
                  interviewerRequirement.id;
              if (
                interviewerRequirement.type ===
                InterviewerRequirementType.PERSON
              ) {
                return (
                  <InterviewerConfigPersonDisplay
                    key={interviewerRequirement.id}
                    interviewerConfig={interviewerRequirement}
                    interviewAtsUrl={
                      interviewRequirement.interview.atssyncInterview?.atsUrl
                    }
                    onRemove={() =>
                      onRemoveInterviewerRequirement({
                        interviewerRequirementId: interviewerRequirement.id,
                      })
                    }
                    onUpdate={(updatedConfig) => {
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (interviewerRequirement2) => {
                              if (
                                interviewerRequirement.id ===
                                interviewerRequirement2.id
                              ) {
                                return {
                                  ...updatedConfig,
                                  type: InterviewerRequirementType.PERSON,
                                  interviewerPools: [],
                                  tagFilters: [],
                                  isShadower: false,
                                  isSynced: interviewerRequirement2.isSynced,
                                  useExternalInterviewerAlgorithm: false,
                                };
                              }

                              return interviewerRequirement2;
                            }
                          ),
                      });
                    }}
                    canShadow={showShadowingToggle}
                  />
                );
              }

              if (
                interviewerRequirement.type ===
                InterviewerRequirementType.SMART_MATCH
              ) {
                return (
                  <InterviewerSmartMatchSelect
                    key={interviewerRequirement.id}
                    requirement={interviewerRequirement}
                    setIncludeShadower={(val) => {
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (ir) => {
                              if (ir.id === interviewerRequirement.id) {
                                return {
                                  ...ir,
                                  includeShadower: val,
                                };
                              }

                              return ir;
                            }
                          ),
                      });
                    }}
                    onTagFiltersChange={(tagFilters) => {
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (ir) => {
                              if (ir.id === interviewerRequirement.id) {
                                return {
                                  ...ir,
                                  tagFilters,
                                };
                              }

                              return ir;
                            }
                          ),
                      });
                    }}
                    onUpdate={(updatedConfig) => {
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (ir2) => {
                              if (interviewerRequirement.id === ir2.id) {
                                return {
                                  ...ir2,
                                  ...updatedConfig,
                                };
                              }

                              return ir2;
                            }
                          ),
                      });
                    }}
                    onRemove={() =>
                      onRemoveInterviewerRequirement({
                        interviewerRequirementId: interviewerRequirement.id,
                      })
                    }
                  />
                );
              }

              if (
                interviewerRequirement.type === InterviewerRequirementType.TAG
              ) {
                return (
                  <InterviewerPoolSelect
                    key={interviewerRequirement.id}
                    requirement={interviewerRequirement}
                    showShadowingToggle={showShadowingToggle}
                    onTagFiltersChange={(tagFilters) => {
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (ir) => {
                              if (interviewerRequirement.id === ir.id) {
                                return {
                                  ...ir,
                                  tagFilters,
                                };
                              }

                              return ir;
                            }
                          ),
                      });
                    }}
                    onRemove={() =>
                      onRemoveInterviewerRequirement({
                        interviewerRequirementId: interviewerRequirement.id,
                      })
                    }
                    setIncludeShadower={(value: boolean) =>
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (interviewerRequirement2) => {
                              if (
                                interviewerRequirement.id ===
                                interviewerRequirement2.id
                              ) {
                                return {
                                  ...interviewerRequirement2,
                                  includeShadower: value,
                                };
                              }

                              return interviewerRequirement2;
                            }
                          ),
                      })
                    }
                    setUseExternalInterviewerAlgorithm={(value: boolean) =>
                      updateInterviewRequirement({
                        ...interviewRequirement,
                        interviewerRequirements:
                          interviewRequirement.interviewerRequirements.map(
                            (interviewerRequirement2) => {
                              if (
                                interviewerRequirement.id ===
                                interviewerRequirement2.id
                              ) {
                                return {
                                  ...interviewerRequirement2,
                                  useExternalInterviewerAlgorithm: value,
                                };
                              }

                              return interviewerRequirement2;
                            }
                          ),
                      })
                    }
                  />
                );
              }

              return null;
            })}
            {!tagFiltersEnabled && (
              <UserOrPoolSelect
                onSelectUser={onAddUser}
                onSelectPool={onAddPool}
              />
            )}
          </div>
        )}

        {excludedRequirement &&
        excludedRequirement.syncedUserMemberships.length ? (
          <div className="flex flex-col text-body-sm space-y-2 mt-2">
            <span>
              1 {formatEntity("interviewer slot")} from Greenhouse excluded{" "}
            </span>
            <ExcludedInterviewersCard
              excludedInterviewers={excludedRequirement.syncedUserMemberships}
              onClick={() => {
                updateInterviewRequirement({
                  ...interviewRequirement,
                  interviewerRequirements:
                    interviewRequirement.interviewerRequirements.map((i) => {
                      if (i.id === excludedRequirement.id) {
                        return {
                          ...i,
                          userMemberships: i.syncedUserMemberships,
                        };
                      }

                      return i;
                    }),
                });
              }}
            />
          </div>
        ) : null}
      </div>
      <EventPrivacyFormGroup
        isPrivate={interviewRequirement.isPrivate}
        onChange={onChangePrivacy}
      />
      <div className="flex flex-col gap-2">
        <span className="text-body-md-heavy">Settings</span>
        <div className="flex gap-2">
          <Checkbox
            checked={videoConferencingRequired}
            onChange={(e) => {
              updateInterviewRequirement({
                ...interviewRequirement,
                videoConferencingSettings: e.currentTarget.checked
                  ? {
                      ...(isZoomIntegrationInstalled
                        ? {
                            service: VideoConferencingServiceType.ZOOM,
                            host: VideoConferencingHostType.ANY_INTERVIEWER,
                          }
                        : {
                            service: VideoConferencingServiceType.GOOGLE_MEET,
                            host: null,
                          }),
                    }
                  : null,
              });
            }}
          />
          <p className="text-body-md">Video conferencing required</p>
        </div>
        {videoConferencingRequired && (
          <Select.Root
            sameWidth
            value={isZoomRequired ? "zoom" : "google-meet"}
            setValue={(val) => {
              updateInterviewRequirement({
                ...interviewRequirement,
                videoConferencingSettings:
                  val === "zoom"
                    ? {
                        service: VideoConferencingServiceType.ZOOM,
                        host: VideoConferencingHostType.ANY_INTERVIEWER,
                      }
                    : {
                        service: VideoConferencingServiceType.GOOGLE_MEET,
                        host: null,
                      },
              });
            }}
          >
            <Select.Trigger>
              <VideoConferenceSelect
                label={
                  <div className="flex items-center gap-2">
                    <Icon
                      content={isZoomRequired ? serviceZoom : serviceGoogleMeet}
                    />
                    <span>{isZoomRequired ? "Zoom" : "Google Meet"}</span>
                  </div>
                }
              />
            </Select.Trigger>
            <Select.Content items={videoConferenceSelectItems} />
          </Select.Root>
        )}
        <div className="flex gap-2">
          <Checkbox
            checked={conferenceRoomRequired}
            onChange={(e) => {
              updateInterviewRequirement({
                ...interviewRequirement,
                conferenceRoomSettings: e.currentTarget.checked
                  ? {
                      required: true,
                      building: null,
                    }
                  : null,
              });
            }}
          />
          <p className="text-body-md">Conference room required</p>
        </div>
        {conferenceRoomRequired && (
          <div className="ml-[1.75rem] space-y-1">
            <span className="text-body-md-heavy">Optional</span>
            <BuildingSelect
              selected={selectedBuilding}
              onSelect={(building) => {
                updateInterviewRequirement({
                  ...interviewRequirement,
                  conferenceRoomSettings: {
                    required: true,
                    building: {
                      id: building.id,
                      name: building.name,
                    },
                  },
                });
              }}
            />
          </div>
        )}
        {hasAnyCollaborativeCoding && (
          <>
            <div className="flex gap-2">
              <Checkbox
                checked={isCollaborativeCodingRequired}
                onChange={(e) => {
                  updateInterviewRequirement({
                    ...interviewRequirement,
                    collaborativeCodingSettings: e.currentTarget.checked
                      ? {
                          service: defaultCollaborativeCodingService,
                        }
                      : null,
                  });
                }}
              />
              <p className="text-body-md">Collaborative coding link required</p>
            </div>
            {isCollaborativeCodingRequired && (
              <CollaborativeCodingSelect
                value={
                  interviewRequirement?.collaborativeCodingSettings?.service
                }
                onChange={(value) => {
                  updateInterviewRequirement({
                    ...interviewRequirement,
                    collaborativeCodingSettings: value
                      ? {
                          service: value,
                        }
                      : null,
                  });
                }}
                integrations={{
                  coderpad: hasCoderPad,
                  hackerRank: hasHackerRank,
                  codeSignal: hasCodeSignal,
                }}
              />
            )}
          </>
        )}
      </div>

      <EditInterviewDialog
        store={editInterviewStore}
        variant="default"
        size="small-plus"
        interview={interviewRequirement.interview}
      />
      <AdvancedInterviewerTagsSchedulingDefaultsTour />
    </div>
  );
}
