/* eslint-disable import/prefer-default-export */
import { useMemo } from "react";

import { useStaticValue } from "../../../../../__utils/react";
import { Icon } from "../../../../../icon/Icon";
import { Menu } from "../../../../../menu";
import { useMenuItems } from "../../../../../menu/use-menu-items";
import { InsertOption, useInsertOptions } from "../../../insert-options";
import {
  useTypeahead,
  useTypeaheadMatch,
  useTypeaheadMenu,
} from "../../../typeahead";

type CommandsTypeaheadPluginProps = {
  insertOptions?: InsertOption[];
};

export function CommandsTypeaheadPlugin({
  insertOptions = [],
}: CommandsTypeaheadPluginProps) {
  const matchFn = useTypeaheadMatch("/", { validChars: "[a-zA-Z0-9\\.]" });
  const typeahead = useTypeahead({ matchFn });
  const { $removeMatch, match, getAnchorRect } = typeahead;

  const options = useInsertOptions(
    insertOptions,
    useStaticValue({
      fullTarget: "commands-insert",
      subTarget: "commands",
      target: "insert",
      getAnchorRect,
    }),
    { target: "insert" }
  );

  const filteredOptions = useMemo(() => {
    const visibleOptions = options.filter((option) => !option.disabled);
    const query = match?.queryString;

    if (!query) return visibleOptions;

    return visibleOptions.filter((option) =>
      option.label.toLowerCase().includes(query.toLowerCase())
    );
  }, [options, match?.queryString]);

  const { menu, menuContentProps } = useTypeaheadMenu({
    typeahead,
    items: useMenuItems(
      (i) => {
        const items = i();

        function getByCategory(category: string) {
          return filteredOptions
            .filter((option) => option.targets.insert?.category === category)
            .sort(
              (a, b) =>
                (a.targets.insert?.position ?? 0) -
                (b.targets.insert?.position ?? 0)
            )
            .map((option) =>
              i.item({
                key: option.key,
                children: option.label,
                onClick() {
                  $removeMatch();
                  // if paragraph style is active, we don't need to trigger it
                  if (option.active) return;
                  option.onTrigger();
                },
                leadingContent: <Icon content={option.icon} />,
                size: "compact",
              })
            );
        }

        const categories = [
          ["Text & layout", getByCategory("text-and-layout")],
          ["Media", getByCategory("media")],
          ["Scheduling", getByCategory("scheduling")],
          ["Display", getByCategory("display")],
          ["Integrations", getByCategory("integrations")],
        ] as const;

        let previousSectionHadItems = false;

        categories.forEach(([category, opts], index) => {
          if (opts.length > 0) {
            if (previousSectionHadItems && index > 0)
              items.push(i.separator({ key: `separator-${category}` }));
            items.push(
              i.groupLabel({
                key: `group-${category}`,
                children: category,
              }),
              ...opts
            );
            previousSectionHadItems = true;
          }
        });

        return items;
      },
      [$removeMatch, filteredOptions]
    ),
  });

  return (
    <>
      <Menu.Root state={menu}>
        <Menu.Content {...menuContentProps} className="min-h-[15rem]" />
      </Menu.Root>
      {filteredOptions.map(({ render }) => render)}
    </>
  );
}
