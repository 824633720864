import type { ValueSet } from "@resource/atlas/content-editor/variables";
import type { GuideBaseVariableValuesGuideFragment } from "generated/graphql-codegen/graphql";
import { useMemo } from "react";
import type { GuideUpdateVariableSet } from "shared/guide-content/update";

import { useGuideBaseValueSet } from "./use-guide-base-value-set";

export function useGuideAboutValueSet({
  guide,
  valueSet,
}: {
  guide?: GuideBaseVariableValuesGuideFragment;
  valueSet?: ValueSet<GuideUpdateVariableSet>;
}) {
  const baseValueSet = useGuideBaseValueSet(guide);
  return useMemo(
    () => ({ ...baseValueSet, ...valueSet }),
    [baseValueSet, valueSet]
  );
}

useGuideAboutValueSet.fragments = {
  guide: useGuideBaseValueSet.fragments.guide,
  guideLexicalContext: useGuideBaseValueSet.fragments.guideLexicalContext,
};
