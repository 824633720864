import { computeChangesForScheduledInterviewForm } from "client/components/scheduled-interviews/UpsertScheduledInterviewForm/utils/field-changes";
import { ScheduledStatus } from "client/components/scheduled-interviews/utils/types";
import {
  isStagedScheduledInterview,
  SchedulerEditableScheduledInterview,
} from "client/scheduler/utils/types";
import { hasRescheduleRequirementBeenFulfilled } from "shared/guide-scheduler/field-changes/utils";

type ComputeSchedulerStatusForInterviewProps = {
  originalInterviews: SchedulerEditableScheduledInterview[];
  interview: SchedulerEditableScheduledInterview;
};

/** Compute scheduler status and field changes for interview given original interviews */
export function computeSchedulerStatusForInterview({
  originalInterviews,
  interview,
}: ComputeSchedulerStatusForInterviewProps) {
  const originalInterview = originalInterviews.find(
    (i) => i.id === interview.id
  );
  const fieldChanges = originalInterview
    ? computeChangesForScheduledInterviewForm({
        oldInterview: originalInterview,
        newInterview: interview,
      })
    : [];

  let status: ScheduledStatus | null = null;
  if (interview.isCancelled) {
    status = ScheduledStatus.CANCELLED;
  } else if (
    interview.pendingRescheduleInterviewRequirement &&
    // If the reschedule requirement has been fulfilled, then show an updated badge
    // If you only change conferencing or something unrelated, keep showing `NEEDS_RESCHEDULING`
    !hasRescheduleRequirementBeenFulfilled({
      fieldChanges: fieldChanges.map((fc) => fc.fieldName),
    })
  ) {
    status = ScheduledStatus.NEEDS_RESCHEDULING;
  } else if (originalInterview) {
    const newIsStaged = isStagedScheduledInterview(interview);
    const originalIsStaged = isStagedScheduledInterview(originalInterview);

    if (newIsStaged && !originalIsStaged) {
      status = ScheduledStatus.CREATED;
    } else if (fieldChanges.length) {
      status = ScheduledStatus.UPDATED;
    }
  } else {
    status = ScheduledStatus.CREATED;
  }

  return {
    status,
    fieldChanges,
    originalInterview,
  };
}
