import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { CalendarEventsViewElement } from "../CalendarEventsViewElement";
import { useCalendarContext } from "../context";
import { Event } from "../Event";
import { CalendarEvent } from "../types";

export type CreatedEvent = {
  id: string;
  title: string;
  startTime: DateTime;
  endTime: DateTime;
};

type Props = {
  /**
   * Callback when an event is added
   */
  onAddEvent: (props: CreatedEvent) => void;
  /**
   * Default duration for an event added via click in minutes
   * @default 30
   */
  defaultDuration?: number;
  /**
   * Default title for new events
   * @default "New Event"
   */
  defaultTitle?: string;
};

export function AddEventPlugin({
  onAddEvent,
  defaultDuration = 30,
  defaultTitle = "New Event",
}: Props) {
  const {
    calculateTimeForOffset,
    setFocusedEventId,
    eventDetailsPopoverState,
    eventsViewRef,
    calculateOffsetFromEvent,
  } = useCalendarContext();

  const [mousedownPosition, setMousedownPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [tempEvent, setTempEvent] = useState<CalendarEvent | null>(null);

  const addEvent = useCallback(
    (startTime: DateTime, endTime?: DateTime) => {
      const createdEvent = {
        id: uuid(),
        title: defaultTitle,
        startTime,
        endTime: endTime ?? startTime.plus({ minutes: defaultDuration }),
      };

      onAddEvent(createdEvent);

      return createdEvent;
    },
    [onAddEvent, defaultTitle, defaultDuration]
  );

  const updateTempEvent = useCallback(
    (startTime: DateTime, endTime?: DateTime) => {
      setTempEvent((prev): CalendarEvent => {
        return {
          calendarId: "temp",
          colorConfig: {
            color: "temporary",
            variant: "active",
          },
          id: prev?.id ?? uuid(),
          startTime,
          endTime: endTime ?? startTime.plus({ minutes: 30 }),
          title: defaultTitle,
          allDay: false,
        };
      });
    },
    [defaultTitle]
  );

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent) => {
      const target = e.target as HTMLElement | null;

      if (
        (eventsViewRef?.contains(e.target as Node) &&
          e.currentTarget === e.target) ||
        target?.classList.contains("calendar-event")
      ) {
        const offset = calculateOffsetFromEvent(e);
        setMousedownPosition(offset);
      }
    };

    const mouseUpListener = (e: MouseEvent) => {
      if (mousedownPosition) {
        const startTime = calculateTimeForOffset({
          x: mousedownPosition.x,
          y: mousedownPosition.y,
        });

        const offset = calculateOffsetFromEvent(e);
        const endTime = calculateTimeForOffset({
          x: mousedownPosition.x, // For now limit to same day, like google calendar
          y: offset.y,
        });

        if (startTime && endTime) {
          if (startTime.equals(endTime)) {
            addEvent(startTime);
          } else if (startTime < endTime) {
            addEvent(startTime, endTime);
          } else {
            addEvent(endTime, startTime);
          }
        }

        setMousedownPosition(null);
        setTempEvent(null);
      }
    };

    eventsViewRef?.addEventListener("mousedown", mouseDownListener, false);
    document.addEventListener("mouseup", mouseUpListener, false);

    return () => {
      eventsViewRef?.removeEventListener("mousedown", mouseDownListener, false);
      document.removeEventListener("mouseup", mouseUpListener, false);
    };
  }, [
    addEvent,
    calculateTimeForOffset,
    mousedownPosition,
    setTempEvent,
    setFocusedEventId,
    eventDetailsPopoverState,
    eventsViewRef,
    calculateOffsetFromEvent,
  ]);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (mousedownPosition) {
        const startTime = calculateTimeForOffset({
          x: mousedownPosition.x,
          y: mousedownPosition.y,
        });

        const offset = calculateOffsetFromEvent(e);
        const endTime = calculateTimeForOffset({
          x: mousedownPosition.x, // For now limit to same day, like google calendar
          y: offset.y,
        });

        if (startTime && endTime) {
          if (startTime.equals(endTime)) {
            updateTempEvent(startTime);
          } else if (startTime < endTime) {
            updateTempEvent(startTime, endTime);
          } else {
            updateTempEvent(endTime, startTime);
          }
        }
      }
    };
    document.addEventListener("mousemove", listener);

    return () => document.removeEventListener("mousemove", listener);
  }, [
    updateTempEvent,
    calculateTimeForOffset,
    mousedownPosition,
    calculateOffsetFromEvent,
  ]);

  return (
    <CalendarEventsViewElement>
      {tempEvent && <Event event={tempEvent} />}
    </CalendarEventsViewElement>
  );
}
