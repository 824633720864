import { MenuStore } from "@ariakit/react";
import { useMemo } from "react";

import { TagMenu } from "./TagMenu";
import { TagFilterTag, TagFilterTagGroup } from "./types";
import { useGroupedTags } from "./utils/useGroupedTags";

type TagPickerProps = {
  tags: TagFilterTag[];
  tagGroups: TagFilterTagGroup[];
  selectedTags: TagFilterTag[];
  onTagSelect: (tag: TagFilterTag) => void;
  menuStore: MenuStore;
  className?: string;
  children?: React.ReactNode;
};

export function TagPicker({
  tags,
  tagGroups,
  selectedTags,
  onTagSelect,
  className,
  menuStore,
  children,
}: TagPickerProps) {
  const { ungrouped, grouped } = useGroupedTags(tags, tagGroups);

  const menuItems = useMemo(() => {
    const ungroupedItems = ungrouped.map((tag) => ({
      id: tag.id,
      label: tag.name,
      isSelected: selectedTags.some((t) => t.id === tag.id),
      onClick: () => onTagSelect(tag),
      color: tag.color,
    }));

    const groupedItems = Object.values(grouped).map((group) => ({
      id: group.id,
      label: group.label,
      color: group.color,
      items: group.tags.map((tag) => ({
        id: tag.id,
        label: tag.name,
        isSelected: selectedTags.some((t) => t.id === tag.id),
        onClick: () => onTagSelect(tag),
        color: group.color || tag.color,
      })),
    }));

    return [...ungroupedItems, ...groupedItems];
  }, [ungrouped, grouped, selectedTags, onTagSelect]);

  return (
    <TagMenu items={menuItems} menuStore={menuStore} className={className}>
      {children ??
        (selectedTags.length > 0
          ? selectedTags.map((t) => t.name).join(", ")
          : "Select tags")}
    </TagMenu>
  );
}
