import { EditCollaborativeCodingSlot } from "client/components/collaborative-coding/display/EditCollaborativeCodingSlot";
import { CollaborativeCodingSlot } from "client/components/collaborative-coding/utils/types";
import { useCallback } from "react";
import { Controller } from "react-hook-form";

import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewCollaborativeCoding({
  state: { form, onChange, originalInterview },
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const handleCollaborativeCodingSlotChange = useCallback(
    (slot: CollaborativeCodingSlot | null) => {
      onChange("scheduledInterview.collaborativeCodingSlot", slot);
    },
    [onChange]
  );

  return (
    <Controller
      name="scheduledInterview.collaborativeCodingSlot"
      control={form.control}
      render={({ field }) => {
        return (
          <EditCollaborativeCodingSlot
            collaborativeCodingSlot={field.value}
            onChangeCollaborativeCodingSlot={
              handleCollaborativeCodingSlotChange
            }
            originalCollaborativeCodingSlot={
              originalInterview?.collaborativeCodingSlot
            }
          />
        );
      }}
    />
  );
}
