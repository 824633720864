import { ScheduleSummary } from "client/components/scheduled-interviews/ScheduleSummary";
import { getReviewInterviewElementId } from "client/components/scheduling-review/ReviewInterviewDetails";
import { mapSchedulerInterviewToScheduledInterviewForCard } from "client/scheduler/utils/mapping";
import { useCallback } from "react";

import { useSchedulerStateForReview } from "./hooks/useReviewPageData";

/**
 * Left side view of scheduler review page
 * Displays info about the changed interviews
 */
export function PanelScheduleSummaryDisplay() {
  const { interviewsForReview } = useSchedulerStateForReview();
  const onClickInterview = useCallback((i: { id: string }) => {
    const element = document.getElementById(getReviewInterviewElementId(i.id));
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="flex flex-col border-r border-gray-border h-full w-full max-w-[21.75rem] space-y-4 p-4">
      <ScheduleSummary
        interviewsWithSchedulingInfo={interviewsForReview.map((i) => ({
          ...mapSchedulerInterviewToScheduledInterviewForCard(i),
          status: i.status,
        }))}
        hideInternalStatuses
        variant="compact"
        groupBy="cancelled"
        onClickInterview={onClickInterview}
        getClickDisabled={(i) => i.status === "cancelled"}
      />
    </div>
  );
}
