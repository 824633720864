import { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor";
import {
  Date,
  InterviewerNames,
  Link,
  PersonName,
  PersonNames,
  Text,
  VariableSet,
} from "@resource/atlas/content-editor/variables";
import {
  GUIDE_AVAILABILITY_REQUEST_RICH_BLOCK_NAME,
  GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME,
  guideBaseVariableKeys,
  guideUpdateVariableKeys,
  INTERVIEWS_RICH_BLOCK_NAME,
  interviewVariableKeys,
  scheduledInterviewDetailsVariableKeys,
  SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME,
} from "modules/lexical/shared/utils/constants";
import { VideoConferencingDetails } from "shared/guide-scheduler/scheduled-interview-details/video-conferencing-variable";
import { z } from "zod";

export const LexicalSchema = z.object({
  root: z.object({
    children: z.array(z.any()),
    direction: z.union([z.literal("ltr"), z.literal("rtl"), z.null()]),
    format: z.string(),
    indent: z.number(),
    type: z.literal("root"),
    version: z.number(),
  }),
}) as z.ZodType<AtlasContentEditorSerializedState>;

/**
All variables found in post_template table as of 2025-03-12:

atsCoordinatorJobTitle
atsCoordinatorName
atsRecruiterJobTitle
atsRecruiterName
authorName
candidateName
candidatePhone
companyName
firstInterview
firstInterviewFirstInterviewerName
firstInterviewInterviewers
guideLink
hiringManagerJobTitle
hiringManagerName
interviewersList
interviewKit
interviewName
interviewsInterviewers
interviewsTimeRange
interviewTime
jobListingLink
jobTitle
lastInterview
lastInterviewFirstInterviewerTitle
lastInterviewInterviewers
videoConferencingUrl

These are all contained within either ScheduledInterviewDetailsVariableSet or GuideUpdateVariableSet.

SQL to find all variables used in post_template table:

WITH RECURSIVE json_tree AS (
  SELECT
    jsonb_array_elements(data->'root'->'children') AS node
  FROM post_template

  UNION ALL

  SELECT
    jsonb_array_elements(node->'children') AS node
  FROM json_tree
  WHERE node ? 'children' AND jsonb_typeof(node->'children') = 'array'
)

SELECT DISTINCT
  jsonb_build_object(
    'id', node->>'id',
    'type', node->>'type',

    -- do not include config as it introduces too much variance
    --'config', COALESCE(node->'config', '{}'::jsonb),

    'version', node->'version'
  ) AS variable_object

FROM json_tree
WHERE node->>'type' = 'variable';
*/

/**
 * A list of all valid variable keys, i.e. the "id" field in the lexical object: {id, type: "variable"} object.
 * Ensures we never try to process a variable that doesn't have specs/names/renderers
 */
export type VariableKey =
  | "atsCoordinatorJobTitle"
  | "atsCoordinatorName"
  | "atsRecruiterJobTitle"
  | "atsRecruiterName"
  | "authorName"
  | "candidateEmail"
  | "candidateLinkedIn"
  | "candidateName"
  | "candidatePhone"
  | "collaborativeCodingUrl"
  | "companyName"
  | "conferencePhone"
  | "firstInterview"
  | "firstInterviewerJobTitle"
  | "firstInterviewerName"
  | "firstInterviewFirstInterviewerName"
  | "firstInterviewFirstInterviewerTitle"
  | "firstInterviewInterviewers"
  | "guideLink"
  | "hiringManagerJobTitle"
  | "hiringManagerName"
  | "interviewerNames"
  | "interviewersList"
  | "interviewKit"
  | "interviewName"
  | "interviewsInterviewers"
  | "interviewsTimeRange"
  | "interviewTime"
  | "jobListingLink"
  | "jobTitle"
  | "lastInterview"
  | "lastInterviewFirstInterviewerName"
  | "lastInterviewFirstInterviewerTitle"
  | "lastInterviewInterviewers"
  | "linkToJobDescription"
  | "schedulerName"
  | "videoConferencingUrl";

/** Central definitions for VariableSets to ensure we never have conflicting definitions for the same variable key. */
export type CompleteVariableSet = VariableSet & {
  atsCoordinatorJobTitle: Text;
  atsCoordinatorName: PersonName;
  atsRecruiterJobTitle: Text;
  atsRecruiterName: PersonName;
  authorName: PersonName;
  candidateEmail: Text;
  candidateLinkedIn: Link;
  candidateName: PersonName;
  candidatePhone: Text;
  collaborativeCodingUrl: Link;
  companyName: Text;
  conferencePhone: Text;
  firstInterview: Date;
  firstInterviewerJobTitle: Text;
  firstInterviewerName: PersonName;
  firstInterviewFirstInterviewerName: PersonName;
  firstInterviewFirstInterviewerTitle: Text;
  firstInterviewInterviewers: PersonNames;
  guideLink: Link;
  hiringManagerJobTitle: Text;
  hiringManagerName: PersonName;
  interviewerNames: PersonNames;
  interviewersList: InterviewerNames;
  interviewKit: Link;
  interviewName: Text;
  interviewsInterviewers: PersonNames;
  interviewsTimeRange: Date;
  interviewTime: Date;
  jobListingLink: Link;
  jobTitle: Text;
  lastInterview: Date;
  lastInterviewFirstInterviewerName: PersonName;
  lastInterviewFirstInterviewerTitle: Text;
  lastInterviewInterviewers: PersonNames;
  linkToJobDescription: Link;
  schedulerName: PersonName;
  videoConferencingUrl: VideoConferencingDetails;
};

// We have to declare these types manually for type safety in the the various create functions.
type GuideBaseVariableKeys = (typeof guideBaseVariableKeys)[number];

/**
 * Used for replacing variables in guide content, should include only candidate-facing variables.
 */
export type GuideBaseVariableSet = Pick<
  CompleteVariableSet,
  GuideBaseVariableKeys
>;

/**
 * Used for replacing variables in guide post messages, should include only candidate-facing variables.
 */
type GuideUpdateVariableKeys = (typeof guideUpdateVariableKeys)[number];

export type GuideUpdateVariableSet = Pick<
  CompleteVariableSet,
  GuideUpdateVariableKeys
>;

type ScheduledInterviewDetailsKeys =
  (typeof scheduledInterviewDetailsVariableKeys)[number];

/**
 * Used for replacing variables in interviewer titles and custom instructions
 * Should use all internal values and not ever be used for candidate-facing content
 */
export type ScheduledInterviewDetailsVariableSet = Pick<
  CompleteVariableSet,
  ScheduledInterviewDetailsKeys
>;

type InterviewKeys = (typeof interviewVariableKeys)[number];

export type InterviewVariableSet = Pick<CompleteVariableSet, InterviewKeys>;

export type RichBlockName =
  | typeof INTERVIEWS_RICH_BLOCK_NAME
  | typeof SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME
  | typeof GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME
  | typeof GUIDE_AVAILABILITY_REQUEST_RICH_BLOCK_NAME;
