import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { AvailableSlotFragment } from "generated/graphql-codegen/graphql";

import { useTimesDisplayInfo } from "./__hooks/useTimesDisplayInfo";

interface AvailableSlotsDisplayInfoProps {
  availableSlots: AvailableSlotFragment[];
  loading?: boolean;
}

export function AvailableSlotsDisplayInfo({
  availableSlots,
  loading,
}: AvailableSlotsDisplayInfoProps) {
  const availableSlotsDisplayInfo = useTimesDisplayInfo({
    availableSlots: availableSlots ?? [],
  });

  return (
    <div className="w-full bg-light-gray-500 p-3 h-12 flex items-center">
      {loading ? (
        <span className="flex w-full h-full items-center justify-center">
          <LoadingIndicator size="small" />
        </span>
      ) : (
        <span className="text-body-sm">
          {availableSlotsDisplayInfo.totalTimesCount} times across{" "}
          {availableSlotsDisplayInfo.uniqueDaysCount} days{" "}
          <span className="text-subtle">(based on settings)</span>
        </span>
      )}
    </div>
  );
}
