import { useEffect, useMemo, useRef } from "react";
import { usePrevious } from "react-use";

export function useScrollToTopOnStatusChange({
  schedulingRequest,
}: {
  schedulingRequest: {
    status: string;
    archivedAt: string | null;
  };
}) {
  const scrollableBodyRef = useRef<HTMLDivElement>(null);
  const prevSchedulingRequestStatus = usePrevious(schedulingRequest.status);
  const prevSchedulingArchived = usePrevious(!!schedulingRequest.archivedAt);

  useEffect(() => {
    if (
      schedulingRequest.status !== prevSchedulingRequestStatus ||
      !!schedulingRequest.archivedAt !== prevSchedulingArchived
    ) {
      scrollableBodyRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [
    prevSchedulingArchived,
    prevSchedulingRequestStatus,
    schedulingRequest.archivedAt,
    schedulingRequest.status,
  ]);

  return useMemo(() => ({ scrollableBodyRef }), [scrollableBodyRef]);
}
