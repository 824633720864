import { isEqual } from "lodash";

import { mapInterviewerSlotForDiff } from "./mapping";
import { InterviewerSlot } from "./types";

export const listInterviewerNamesAsOr = (interviewers: { name: string }[]) => {
  return interviewers
    .map((i) => i.name)
    .join(", ")
    .replace(/,([^,]*)$/, " or$1");
};

export const getUserMembershipIdsFromInterviewSlots = (
  interviewerSlots: InterviewerSlot[]
) => {
  return getUserMembershipsFromInterviewSlots(interviewerSlots).map(
    (membership) => membership.id
  );
};

export const getUserMembershipsFromInterviewSlots = (
  interviewerSlots: InterviewerSlot[]
) => {
  return getInterviewersFromInterviewSlots(interviewerSlots).map(
    (interviewer) => interviewer.userMembership
  );
};

export const getInterviewersFromInterviewSlots = (
  interviewerSlots: InterviewerSlot[]
) => {
  return interviewerSlots.flatMap((slot) => {
    const interviewer = slot.interviewer ? [slot.interviewer] : [];
    const shadower = slot.shadowingInterviewer
      ? [slot.shadowingInterviewer]
      : [];
    return [...interviewer, ...shadower];
  });
};

export const interviewerSlotIsEqual = (
  slot1: InterviewerSlot,
  slot2: InterviewerSlot
) => {
  return isEqual(
    mapInterviewerSlotForDiff(slot1),
    mapInterviewerSlotForDiff(slot2)
  );
};

export const interviewerSlotsHaveChanged = (
  newSlots: InterviewerSlot[],
  oldSlots: InterviewerSlot[]
) => {
  if (newSlots.length !== oldSlots.length) {
    return true;
  }

  const newSlotsMap = new Map(newSlots.map((slot) => [slot.id, slot]));
  const oldSlotsMap = new Map(oldSlots.map((slot) => [slot.id, slot]));

  for (const [id] of newSlotsMap) {
    if (!oldSlotsMap.has(id)) {
      // Created a new slot
      return true;
    }
  }

  for (const [id] of oldSlotsMap) {
    if (!newSlotsMap.has(id)) {
      // Deleted an old slot
      return true;
    }
  }

  for (const [id, newSlot] of newSlotsMap) {
    const oldSlot = oldSlotsMap.get(id);
    if (oldSlot && !interviewerSlotIsEqual(newSlot, oldSlot)) {
      // Slot has changed
      return true;
    }
  }

  return false;
};

export function overrideInterviewerSlotInterviewers({
  slot,
  interviewer: passedInterviewer,
  shadowingInterviewer: passedShadowingInterviewer,
}: {
  slot: InterviewerSlot;
  interviewer?: InterviewerSlot["interviewer"];
  shadowingInterviewer?: InterviewerSlot["shadowingInterviewer"];
}): InterviewerSlot {
  if (
    passedInterviewer === undefined &&
    passedShadowingInterviewer === undefined
  ) {
    return slot;
  }

  return {
    ...slot,
    interviewer:
      passedInterviewer !== undefined ? passedInterviewer : slot.interviewer,
    shadowingInterviewer:
      passedShadowingInterviewer !== undefined
        ? passedShadowingInterviewer
        : slot.shadowingInterviewer,
  };
}
