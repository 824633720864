import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBuilding, atlasSearch } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { OptionItem } from "@resource/atlas/option/OptionItem";
import { OptionSeparator } from "@resource/atlas/option/OptionSeparator";
import { AtlasPopoverStateProps, Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import TextField from "@resource/atlas/textfield/TextField";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { BuildingForSelectionFragment } from "generated/graphql-codegen/graphql";
import { useMemo, useState } from "react";
import useQuery from "utils/useQuery";

gql(`
fragment BuildingForSelection on Building {
  id
  name
}
`);

const FETCH_BUILDINGS_FOR_BUILDING_SELECT_QUERY = gql(`
  query FetchBuildingsForBuildingSelectQuery {
    currentOrganization {
      id
      buildings {
        id
        ...BuildingForSelection
      }
    }
  }
`);

type BuildingSelectProps = {
  onSelect: (building: BuildingForSelectionFragment) => void;
  selected?: BuildingForSelectionFragment;
  Trigger?: JSX.Element;
  popoverProps?: AtlasPopoverStateProps;
  disabled?: boolean;
  triggerProps?: AtlasSelectTriggerProps;
  portal?: boolean;
};

export function BuildingSelect({
  onSelect,
  selected,
  Trigger,
  popoverProps,
  triggerProps,
  disabled,
  portal,
}: BuildingSelectProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const popoverState = usePopoverState(popoverProps);

  const { data, loading } = useQuery(FETCH_BUILDINGS_FOR_BUILDING_SELECT_QUERY);

  const buildings = useMemo(
    () => data?.currentOrganization?.buildings ?? [],
    [data]
  );

  const options = useMemo(
    () =>
      buildings
        .filter((v) => v.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map((building) => (
          <OptionItem
            key={building.id}
            isSelectable
            isSelected={selected?.id === building.id}
            onClick={() => {
              onSelect(building);
              popoverState.hide();
            }}
            size="compact"
          >
            {building.name}
          </OptionItem>
        )),
    [buildings, onSelect, popoverState, searchTerm, selected?.id]
  );

  const renderedTrigger = useMemo(() => {
    if (Trigger) {
      return Trigger;
    }

    if (selected) {
      return (
        <SelectTrigger
          {...triggerProps}
          className={clsx(
            "flex-1 !bg-light-gray-500 w-full",
            triggerProps?.className
          )}
        >
          <div className="flex gap-2 items-center">{selected.name}</div>
        </SelectTrigger>
      );
    }

    return (
      <SelectTrigger
        {...triggerProps}
        className={clsx(
          "flex-1 w-full !bg-light-gray-500",
          triggerProps?.className
        )}
      >
        <div className="flex gap-1.5 items-center mr-1">
          <Icon content={atlasBuilding} className="text-subtle" />
          <p className="text-body-md text-subtle">Select building</p>
        </div>
      </SelectTrigger>
    );
  }, [Trigger, selected, triggerProps]);

  return (
    <Popover.Root state={popoverState}>
      <Popover.Trigger disabled={disabled}>{renderedTrigger}</Popover.Trigger>
      <Popover.Content
        className="w-[30rem]"
        hasPadding={false}
        portal={portal}
        header={
          <TextField
            className="m-2"
            size="small"
            value={searchTerm}
            autoFocus
            icon={atlasSearch}
            placeholder="Search"
            aria-label="Search"
            onChange={setSearchTerm}
            isClearable
          />
        }
      >
        <div className="p-[.5rem] pt-0">
          {loading && (
            <>
              <OptionSeparator />
              <div className="flex justify-center">
                <LoadingIndicator size="small" />
              </div>
            </>
          )}
          {!loading && options.length > 0 && <>{options}</>}
          {!loading && options.length === 0 && (
            <>
              <OptionSeparator />
              <p className="px-5 py-2 text-body-md">No results found</p>
            </>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
}
