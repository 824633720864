import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarEvent } from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";

import {
  useInsertSelfScheduleRequest,
  UseInsertSelfScheduleRequestProps,
} from "./use-insert-self-schedule-request";

export function InsertSelfScheduleRequestButton(
  props: UseInsertSelfScheduleRequestProps
) {
  const insertAvailabilityBlock = useInsertSelfScheduleRequest(props);

  return (
    //  eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      as="button"
      className="text-body-md px-1 h-full shrink"
      onClick={insertAvailabilityBlock}
    >
      <p className="flex items-center gap-1">
        <Icon content={atlasCalendarEvent} className="w-4 h-4" />
        <span>Insert self-schedule request</span>
      </p>
    </Link>
  );
}
