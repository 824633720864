import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCalendarEvents, atlasMail } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import { useLogEvent } from "analytics";
import { hiringDecisionToDisplay } from "client/components/interview-plan/HiringDecisionSelect";
import { GuideStatusEnum } from "generated/graphql-codegen/graphql";
import { useState } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";

import { usePostMessageDialogForWorkflowProps } from "../__hooks/usePostMessageDialogProps";
import { PostMessageDialog } from "./PostMessageDialog";

export function MessageOmniButton({ guideId }: { guideId: string }) {
  const logEvent = useLogEvent({
    component: "MessageOmniButton",
  });
  const [workflowType, setWorkflowType] =
    useState<PostMessageWorkflow | null>();

  const { store, props } = usePostMessageDialogForWorkflowProps({
    workflowType,
    guideId,
  });

  const menuItems = useMenuItems(
    (i) => [
      i.item({
        key: "send-message",
        children: "Send a message",
        leadingContent: <Icon content={atlasMail} />,
        onClick: () => {
          logEvent("Send Message Clicked");
          setWorkflowType(null);
          store.setOpen(true);
        },
      }),
      i.item({
        key: "send-confirmation",
        children: "Send interview confirmation",
        leadingContent: <Icon content={atlasCalendarEvents} />,
        onClick: () => {
          logEvent("Send Interview Confirmation Clicked");
          setWorkflowType(PostMessageWorkflow.CONFIRM_INTERVIEWS);
          store.setOpen(true);
        },
      }),
      i.separator({
        key: "separator-1",
      }),
      i.item({
        key: "notify-of-rejection",
        leadingContent: (
          <Icon
            content={hiringDecisionToDisplay[GuideStatusEnum.REJECTED].icon}
            className="text-danger"
          />
        ),
        children: "Notify of rejection",
        onClick: () => {
          logEvent("Notify of Rejection Clicked");
          setWorkflowType(PostMessageWorkflow.NOTIFY_OF_REJECTION);
          store.setOpen(true);
        },
      }),

      i.item({
        key: "notify-of-offer",
        leadingContent: (
          <Icon
            content={hiringDecisionToDisplay[GuideStatusEnum.OFFER].icon}
            className="text-purple-500"
          />
        ),
        children: "Notify of offer",
        onClick: () => {
          logEvent("Notify of Offer Clicked");
          setWorkflowType(PostMessageWorkflow.NOTIFY_OF_OFFER);
          store.setOpen(true);
        },
      }),
      i.item({
        key: "notify-of-hire",
        leadingContent: (
          <Icon
            content={hiringDecisionToDisplay[GuideStatusEnum.HIRED].icon}
            className="text-success"
          />
        ),
        children: "Notify of hired",
        onClick: () => {
          logEvent("Notify of Hired Clicked");
          setWorkflowType(PostMessageWorkflow.NOTIFY_OF_HIRED);
          store.setOpen(true);
        },
      }),
    ],
    [store, setWorkflowType, logEvent]
  );

  return (
    <>
      <Menu.Root>
        <Menu.Trigger>
          <Button className="w-full" isDropdown icon={atlasMail}>
            Message
          </Button>
        </Menu.Trigger>
        <Menu.Content defaultSize="compact" portal items={menuItems} />
      </Menu.Root>
      <PostMessageDialog
        {...props}
        dialog={{
          store,
        }}
      />
    </>
  );
}
