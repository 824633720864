import { uniq } from "lodash";
import { useMemo } from "react";

import { InterviewerToFetchForSlotCalculations } from "../utils/types";

export type InterviewerGroup = {
  poolId: string | null;
  userMembershipIds: string[];
};

/** Group userMembershipIds by poolId */
export function useGroupedInterviewerData({
  interviewers,
}: {
  interviewers: InterviewerToFetchForSlotCalculations[];
}) {
  return useMemo(() => {
    return interviewers.reduce((acc, interviewer) => {
      const group = acc.find((g) => {
        if (interviewer.poolId) {
          // If there's a poolId, find a pool with the same id
          return g.poolId === interviewer.poolId;
        }

        // Otherwise, their "group" is the null pool group
        return g.poolId === null;
      });

      if (!group) {
        return [
          ...acc,
          {
            poolId: interviewer.poolId ?? null,
            userMembershipIds: [interviewer.userMembershipId],
          },
        ];
      }

      const restOfGroups = acc.filter((g) => g.poolId !== group.poolId);

      return [
        ...restOfGroups,
        {
          ...group,
          userMembershipIds: uniq([
            ...group.userMembershipIds,
            interviewer.userMembershipId,
          ]),
        },
      ];
    }, [] as InterviewerGroup[]);
  }, [interviewers]);
}
