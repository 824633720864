import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import {
  $createParagraphNode,
  $getSelection,
  $isLineBreakNode,
  $isParagraphNode,
  $isRangeSelection,
} from "lexical";
import { useMemo, useState } from "react";

import { useEvent } from "../../../../__utils/react";
import { $getSelectionTopNode } from "../lib/utils";

export type HeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
// we intentionally only support these heading types
export type SupportedHeadingType = "h1" | "h2";

export function useHeadingContext() {
  const [editor] = useLexicalComposerContext();

  const [isHeading, setIsHeading] = useState(false);
  const [headingType, setHeadingType] = useState<HeadingType>();

  const updateListener = useEvent(() => {
    const [topNode] = $getSelectionTopNode(editor);
    if ($isHeadingNode(topNode)) {
      setIsHeading(true);
      setHeadingType(topNode.getTag());
    } else {
      setIsHeading(false);
      setHeadingType(undefined);
    }
  });

  const toggleHeading = useEvent((targetType?: SupportedHeadingType) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const selectionNodes = selection.getNodes();

        // Bug with lexical if you have a linebreak node as one of the first children of a pargraph node when calling `$setBlocksType`
        // removes all linebreak nodes from the paragraph node until it finds a non-linebreak node
        selectionNodes.forEach((node) => {
          const parent = node.getParent();

          let hasFoundNonLinebreakNode = false;

          if ($isParagraphNode(parent)) {
            const children = parent.getChildren();

            children.forEach((child) => {
              if ($isLineBreakNode(child) && !hasFoundNonLinebreakNode) {
                child.remove();
              } else {
                hasFoundNonLinebreakNode = true;
              }
            });
          }
        });

        $setBlocksType(selection, () =>
          targetType && headingType !== targetType
            ? $createHeadingNode(targetType)
            : $createParagraphNode()
        );
      }
    });
  });

  const toggleH1 = useEvent(() => toggleHeading("h1"));
  const toggleH2 = useEvent(() => toggleHeading("h2"));

  const value = useMemo(
    () => ({
      isHeading,
      headingType,
      toggleHeading,
      toggleH1,
      toggleH2,
    }),
    [headingType, isHeading, toggleH1, toggleH2, toggleHeading]
  );

  return [updateListener, value] as const;
}
