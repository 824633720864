import Badge from "@resource/atlas/badge/Badge";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { Button as AriaButton } from "ariakit";
import clsx from "clsx";
import { GuideStatusEnum } from "generated/graphql-codegen/graphql";
import { ComponentPropsWithoutRef, forwardRef, useMemo } from "react";

import HiringDecisionBadge from "./HiringDecisionBadge";
import StagePositionIndicator from "./StagePositionIndicator";

interface Stage {
  id: string;
  title: string;
  position: number;
}

type StageSelectTriggerProps = ComponentPropsWithoutRef<"button"> & {
  stage?: Stage;
  current?: string;
  index?: number;
  maxPosition: number;
  hiringDecision?: GuideStatusEnum;
};

const StageSelectTrigger = forwardRef<
  HTMLButtonElement,
  StageSelectTriggerProps
>(({ stage, index, current, maxPosition, hiringDecision, ...props }, ref) => (
  <SelectTrigger isGhost ref={ref} {...props}>
    {stage ? (
      <div className="flex items-center gap-2">
        <span className="truncate">
          {(index ?? 0) + 1} of {maxPosition}: {stage.title}
        </span>
        {stage.id !== current && (
          <Badge
            variant="success"
            styles="bold"
            size="small"
            className="flex-shrink-0"
          >
            Changed
          </Badge>
        )}
        {hiringDecision && (
          <HiringDecisionBadge hiringDecision={hiringDecision} />
        )}
      </div>
    ) : (
      <div>{maxPosition === -1 ? "No stages" : "No current stage"}</div>
    )}
  </SelectTrigger>
));

interface StageSelectProps {
  value: string;
  stages: Stage[];
  current?: string;
  onChange: (stageId: string) => unknown;
  collapsed: boolean;
  expand: () => unknown;
  className?: string;
  updatedHiringDecision?: GuideStatusEnum;
}

export default function StageSelect({
  value,
  onChange,
  stages,
  current,
  collapsed,
  expand,
  className,
  updatedHiringDecision,
}: StageSelectProps) {
  const selectedStageIndex = useMemo(
    () => stages.findIndex((s) => s.id === value),
    [stages, value]
  );
  const selectedStage = stages[selectedStageIndex];

  const items = useSelectItems(
    (i) =>
      stages.map((stage, index) =>
        i.option({
          key: stage.id,
          value: stage.id,
          children: stage.title,
          renderContent: ({ children }) => (
            <div className="flex gap-2 items-center">
              <StagePositionIndicator position={index + 1} />
              <span>{children}</span>
              {stage.id === current && (
                <Badge variant="success" size="small">
                  Current
                </Badge>
              )}
            </div>
          ),
        })
      ),
    [stages, current]
  );

  if (collapsed) {
    if (selectedStage && current && selectedStage.id !== current) {
      return (
        <AriaButton
          onClick={expand}
          className="w-full text-left text-body-md px-3 py-2.5 rounded-md hover:bg-light-gray-200 focus-visible:focus-ring flex items-center gap-4"
        >
          <span className="mr-4">
            {selectedStageIndex + 1} of {stages.length}: {selectedStage.title}
          </span>
          <Badge variant="success" styles="bold" size="small">
            Changed
          </Badge>
          {updatedHiringDecision && (
            <HiringDecisionBadge hiringDecision={updatedHiringDecision} />
          )}
        </AriaButton>
      );
    }

    return null;
  }

  return (
    <Select.Root
      sameWidth
      value={value}
      setValue={(val) => onChange(val as string)}
    >
      <Select.Trigger className={clsx(className, "flex-1")}>
        <StageSelectTrigger
          stage={selectedStage}
          index={selectedStageIndex}
          current={current}
          maxPosition={stages.length}
          hiringDecision={updatedHiringDecision}
        />
      </Select.Trigger>
      <Select.Content items={items} />
    </Select.Root>
  );
}
