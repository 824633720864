import { useCalendarSettings } from "./settings";

export function useStartOfPeriod() {
  const { selectedDay, view } = useCalendarSettings();

  if (view === "week") {
    return selectedDay.startOf("week");
  }

  return selectedDay.startOf("day");
}
