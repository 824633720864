import { GroupLabel } from "@resource/atlas/menu/Menu";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import InterviewerPoolSelectOption from "client/components/interviewer-slots/display/InterviewerPoolSelectOption";
import { UserMembershipSelectDisplay } from "client/components/interviewer-slots/display/UserMembershipSelectDisplay";
import useSelectInterviewerPools from "client/components/interviewer-slots/hooks/useSelectPools";
import { useSelectUserMembershipsWithSchedulingData } from "client/components/interviewer-slots/hooks/useSelectUserMembershipsWithSchedulingData";
import {
  FormDataForSlotCalculations,
  InterviewerPool,
  UserMembershipForForm,
  UserMembershipSelectWrapperProps,
  UserMembershipWithSchedulingData,
} from "client/components/interviewer-slots/utils/types";
import { useEffect, useMemo } from "react";
import useDebouncedSearch from "react-hooks/useDebouncedSearch";
import { formatEntity } from "shared/constants/entities";

import UserMembershipSelectOption from "../display/UserMembershipSelectOption";
import { getLoadCalculationsDateFromFormData } from "../hooks/interviewer-scheduling-data";

type UserMembershipWithSchedulingDataSelectProps =
  UserMembershipSelectWrapperProps & {
    formDataForScheduling: FormDataForSlotCalculations;
  };

export default function UserMembershipWithSchedulingDataSelect({
  onSelect,
  onSelectPool,
  excludeUserMembershipIds,
  Trigger,
  searchPlaceholderText,
  poolId,
  tagFilters,
  isQualified,
  includePools,
  formDataForScheduling,
}: UserMembershipWithSchedulingDataSelectProps) {
  const { searchTerm, setSearchTerm, debouncedTerm } = useDebouncedSearch("");
  const popoverState = usePopoverState();
  const { selectedInterview, interviews, originalInterviews } =
    formDataForScheduling;

  const { date, includeDayLoad } = getLoadCalculationsDateFromFormData({
    formData: formDataForScheduling,
    interview: selectedInterview,
  });

  const { interviewers, loadingData, loadingCalendar } =
    useSelectUserMembershipsWithSchedulingData({
      guideId: formDataForScheduling.guideId,
      excludeUserMembershipIds,
      poolId,
      tagFilters,
      isQualified,
      search: debouncedTerm,
      interviews,
      originalInterviews,
      selectedInterview,
      date,
      includeDayLoad,
      skip: !popoverState.open,
    });

  const { pools, loading: loadingPools } = useSelectInterviewerPools({
    search: debouncedTerm,
    skip: !includePools || !popoverState.open,
  });

  const options = useSelectOptions({
    interviewers,
    pools,
    popoverState,
    onSelectPool,
    onSelect,
    includeDayLoad,
    includePools,
    isQualified,
    loadingCalendar,
  });

  // clear search term on popover state close
  useEffect(() => {
    if (!popoverState.open) {
      setSearchTerm("");
    }
  }, [popoverState.open, setSearchTerm]);

  return (
    <UserMembershipSelectDisplay
      Trigger={Trigger}
      popoverState={popoverState}
      searchPlaceholderText={searchPlaceholderText}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      loading={loadingData || loadingPools}
      options={options}
    />
  );
}

type UseSelectOptionsParams = {
  interviewers: UserMembershipWithSchedulingData[];
  pools: InterviewerPool[];
  popoverState: ReturnType<typeof usePopoverState>;
  onSelectPool?: (pool: InterviewerPool) => void;
  onSelect: (user: UserMembershipForForm) => void;
  includeDayLoad: boolean;
  includePools?: boolean;
  loadingCalendar: boolean;
  isQualified?: boolean;
};

// TODO: Break out to eg pool/user and have a wrapper that does both
function useSelectOptions({
  interviewers,
  pools,
  popoverState,
  onSelectPool,
  onSelect,
  includeDayLoad,
  isQualified = true,
}: UseSelectOptionsParams) {
  const options = useMemo(() => {
    const optionsResult = [];

    if (pools.length > 0 && !!onSelectPool) {
      if (interviewers.length > 0) {
        optionsResult.push(
          <GroupLabel key="pools">
            {formatEntity("training path", { plural: true, capitalize: true })}
          </GroupLabel>
        );
      }

      optionsResult.push(
        ...pools.map((pool: InterviewerPool) => (
          <InterviewerPoolSelectOption
            key={pool.id}
            pool={pool}
            onClick={() => {
              onSelectPool(pool);
              popoverState.hide();
            }}
          />
        ))
      );
    }

    if (interviewers.length > 0) {
      if (pools.length > 0) {
        optionsResult.push(
          <GroupLabel key="userMembships">Interviewers</GroupLabel>
        );
      }
      // We have to pass showDayCount because when includeDayLoad is false, because we still have daily load information
      // for an arbitrary day, eg startOfInterval, but we don't want to show it.
      optionsResult.push(
        ...interviewers.map((userMembership) => (
          <UserMembershipSelectOption
            key={userMembership.id}
            userMembership={userMembership}
            isShadower={!isQualified}
            showDayCount={includeDayLoad}
            onClick={() => {
              onSelect(userMembership);
              popoverState.hide();
            }}
          />
        ))
      );
    }

    return optionsResult;
  }, [
    interviewers,
    pools,
    onSelectPool,
    popoverState,
    onSelect,
    includeDayLoad,
    isQualified,
  ]);

  return options;
}
