import { DateSelector } from "client/components/generic/inputs/DateSelector";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { Controller } from "react-hook-form";

import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewDate({
  state: { form, timezone },
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const handleChange = useCallback(
    (date: string) => {
      const formStartTime = form.getValues("scheduledInterview.startTime");
      const duration = form.getValues("scheduledInterview.duration");

      const currentDateTime = formStartTime
        ? DateTime.fromISO(formStartTime, { zone: timezone })
        : DateTime.now().setZone(timezone).set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

      const newDateTime = DateTime.fromISO(date, { zone: timezone }).set({
        hour: currentDateTime.hour,
        minute: currentDateTime.minute,
        second: currentDateTime.second,
        millisecond: currentDateTime.millisecond,
      });

      const newEndDateTime = newDateTime.plus({ minutes: duration });

      form.setValue("scheduledInterview.startTime", newDateTime.toISO(), {
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue("scheduledInterview.endTime", newEndDateTime.toISO(), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [form, timezone]
  );

  return (
    <Controller
      name="scheduledInterview.startTime"
      control={form.control}
      render={({ field, fieldState: { isTouched, error } }) => (
        <FormGroup
          label="Date"
          errorMessage={isTouched ? error?.message : undefined}
        >
          <DateSelector
            {...field}
            time={field.value || null}
            onChangeDate={handleChange}
            timezone={timezone}
          />
        </FormGroup>
      )}
    />
  );
}
