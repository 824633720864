import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";
import { formatEntity } from "shared/constants/entities";
import useMutation from "utils/useMutation";

const CANCEL_SELF_SCHEDULE_REQUEST = gql(`
  mutation CancelSelfScheduleRequest($input: CancelSelfScheduleRequestInput!) {
    cancelSelfScheduleRequest(input: $input) {
      code
      message
      success
      selfScheduleRequest {
        id
        ...SelfScheduleRequestForBlock
      }
    }
  }
`);

/** Dialog for confirming the cancellation of a self-schedule request */
export function CancelSelfScheduleRequestConfirmationDialog({
  store,
  selfScheduleRequestId,
  interviewTitle,
}: {
  store: DialogStore;
  selfScheduleRequestId: string;
  interviewTitle: string;
}) {
  const logEvent = useLogEvent({
    component: "CancelSelfScheduleRequestConfirmationDialog",
  });
  const [cancelSelfScheduleRequest, { loading: submitLoading }] = useMutation(
    CANCEL_SELF_SCHEDULE_REQUEST,
    {
      onCompleted: (res) => {
        if (res.cancelSelfScheduleRequest.success) {
          logEvent("Self-schedule Request Cancelled");
          store.hide();
        }
      },
    }
  );

  const onSubmit = useCallback(() => {
    cancelSelfScheduleRequest({
      variables: {
        input: {
          selfScheduleRequestId,
        },
      },
    });
  }, [cancelSelfScheduleRequest, selfScheduleRequestId]);

  return (
    <Dialog store={store} size="small">
      <View
        content={{
          className: "space-y-3",
        }}
        header={{
          title: `Cancel ${formatEntity("self-schedule request")}`,
        }}
        footer={{
          rightActions: (
            <ButtonGroup>
              <Button isGhost onClick={store.hide}>
                Keep request
              </Button>
              <Button
                variant="danger"
                onClick={onSubmit}
                isLoading={submitLoading}
              >
                Cancel request
              </Button>
            </ButtonGroup>
          ),
        }}
      >
        <p className="text-body-md">
          You are about to cancel a {formatEntity("self-schedule request")} for
          the <InterviewTitle interview={{ title: interviewTitle }} />{" "}
          interview. The candidate will no longer be able to schedule their
          interview.
        </p>
        <p className="text-body-md">Are you sure you want to cancel?</p>
      </View>
    </Dialog>
  );
}
