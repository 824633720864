import { mapUserMembershipToInterviewerSlotUserMembership } from "client/components/interviewer-slots/utils/mapping";
import { Interviewer } from "client/components/interviewer-slots/utils/types";
import { SchedulerStagedScheduledInterview } from "client/scheduler/utils/types";
import { LoadDuolingoInterviewersInSchedulerV2Mutation } from "generated/graphql-codegen/graphql";
import { filterOutNullsAndUndefined } from "shared/utils/filtering";
import { v4 } from "uuid";

// We do mapping and filtering in the same function because typescript gets mad about missing ids otherwise
export function filterAndMapSchedulerStagedScheduledInterviewsToExternalAlgorithmInput(
  interviews: SchedulerStagedScheduledInterview[]
) {
  return interviews
    .map(filterAndMapSingleInterviewToExternalAlgorithmInput)
    .filter(filterOutNullsAndUndefined);
}

function filterAndMapSingleInterviewToExternalAlgorithmInput(
  interview: SchedulerStagedScheduledInterview
) {
  if (
    !interview.interview?.id ||
    !interview.interviewRequirement?.id ||
    !interview.interviewerSlots.some((slot) => slot.externalAlgorithmEnabled)
  ) {
    return null;
  }
  const interviewerRequirements = interview.interviewerSlots
    .filter((slot) => slot.externalAlgorithmEnabled)
    .map((slot) => ({
      interviewerRequirementId: slot.id,
      includeShadower: slot.trainingEnabled,
      interviewerPoolId: slot.interviewerPoolsSetting[0]?.id,
      // userEmails are not currently supported, but would come from slot.userMembershipsSetting.
      // userEmails: slot.userMembershipsSetting.map(
      //   (interviewer) => interviewer.email
      // ),
    }));

  return {
    interviewId: interview.interview.id,
    interviewRequirementId: interview.interviewRequirement.id,
    startTime: interview.startTime,
    endTime: interview.endTime,
    interviewerRequirements,
  };
}

export function mapExternalAlgorithmInterviewerToInterviewer(
  interviewer: NonNullable<
    LoadDuolingoInterviewersInSchedulerV2Mutation["loadDuolingoInterviewers"]["interviews"][number]["interviewerRequirements"][number]["interviewer"]
  >
): Interviewer {
  return {
    id: v4(),
    userMembership:
      mapUserMembershipToInterviewerSlotUserMembership(interviewer),
    responseStatus: null,
  };
}
