import clsx from "clsx";
import React, { forwardRef } from "react";

import { useCalendarSizes } from "../../hooks/sizing";
import { useColumns } from "../../hooks/useColumns";
import { ROWS_IN_DAY } from "../../utils/utils";

/** Provides the proper grid sizing with the right number of columns. */
export const EventsGridSizingWrapper = forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<"ol"> & {
    includeLeftOffsetMargin?: boolean;
  }
>((props, ref) => {
  const { columnIds } = useColumns();
  const calendarSizes = useCalendarSizes();

  return (
    <ol
      {...props}
      className={clsx(
        "col-start-1 col-end-2 row-start-1 grid relative",
        props.className
      )}
      ref={ref}
      style={{
        gridTemplateRows: `0 repeat(${ROWS_IN_DAY}, minmax(0, 1fr)) auto`,
        gridTemplateColumns: `repeat(${columnIds.length}, 1fr)`,
        marginLeft: props.includeLeftOffsetMargin
          ? `${calendarSizes.leftOffsetWidth.rem}rem`
          : undefined,
        ...props.style,
      }}
    />
  );
});
