import { Avatar } from "@resource/atlas/avatar/Avatar";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarEvent,
  atlasGlobe,
  atlasVideoCamera,
} from "@resource/atlas/icons";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { getFormattedDateRange } from "client/utils/dates";
import clsx from "clsx";
import { LocationType } from "generated/graphql-codegen/graphql";
import { getReadableTimezone } from "shared/utils/timezones";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";

export function SelfScheduleRequestDetails({
  state,
  className,
  isReview,
}: {
  state: SelfScheduleSubmissionState;
  className?: string;
  isReview?: boolean;
}) {
  const {
    interviewSettings,
    showCompanyBranding,
    selectedSlot,
    selectedTimezone,
  } = state;
  const { interviewer, locationSettings } = interviewSettings;
  const { organization } = interviewer;

  return (
    <div className={clsx("h-full w-full flex flex-col space-y-4", className)}>
      {showCompanyBranding && (
        <div className="flex items-center h-8">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={organization.companyLogoUrl}
            alt={organization.name}
            className="h-full max-w-full object-contain"
          />
        </div>
      )}
      <Avatar
        size="large"
        name={interviewer.name}
        image={interviewer.imageUrl}
      />
      <div className="flex flex-col space-y-1">
        <InterviewTitle interview={interviewSettings} className="text-h4" />
        <span className="text-body-md text-subtle">
          {interviewSettings.duration} minutes
        </span>
      </div>
      {isReview &&
        locationSettings &&
        locationSettings.service !== LocationType.PHONE && (
          <div className="flex flex-row space-x-2">
            <Icon content={atlasVideoCamera} className="w-5 h-5 text-subtle" />
            <span className="text-body-md">
              Video conferencing details provided upon confirmation.
            </span>
          </div>
        )}
      {isReview && selectedSlot && (
        <div className="flex flex-row space-x-2">
          <Icon content={atlasCalendarEvent} className="w-5 h-5 text-subtle" />
          <span className="text-body-md">
            {
              getFormattedDateRange({
                ...selectedSlot,
                timezone: selectedTimezone,
              }).formattedDateTime
            }
          </span>
        </div>
      )}
      {isReview && selectedTimezone && (
        <div className="flex flex-row space-x-2">
          <Icon content={atlasGlobe} className="w-5 h-5 text-subtle" />
          <span className="text-body-md">
            {getReadableTimezone({
              timezone: selectedTimezone,
              labelType: "default",
              useMomentCurrentAbbreviation: true,
            })}
          </span>
        </div>
      )}
    </div>
  );
}
