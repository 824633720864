import { Button } from "@resource/atlas/button/Button";
import { atlasCalendarEvent, atlasClose } from "@resource/atlas/icons";
import { Popover } from "@resource/atlas/popover";
import { usePopoverState } from "@resource/atlas/popover/use-popover-state";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { MonthCalendar } from "client/components/calendar/MonthCalendar/MonthCalendarV2";
import { useTimezone } from "client/timezones/useTimezone";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useCallback, useRef } from "react";

export type DateSelectorProps = {
  time: string | null;
  onChangeDate: (date: string) => void;
  timezone?: string;
  allowPast?: boolean;
};

export function DateSelector({
  time: timeISO,
  onChangeDate,
  timezone: passedTimezone,
  allowPast,
}: DateSelectorProps) {
  const timezone = useTimezone(passedTimezone);
  const time = timeISO ? DateTime.fromISO(timeISO, { zone: timezone }) : null;
  const anchorRef = useRef<HTMLButtonElement>(null);
  const popoverState = usePopoverState({
    getAnchorRect: () => {
      return anchorRef.current?.getBoundingClientRect() ?? null;
    },
  });
  const handleChange = useCallback(
    (selectedDay: DateTime) => {
      const currTime = time || DateTime.now().setZone(timezone);

      onChangeDate(
        currTime
          .set({
            year: selectedDay.year,
            month: selectedDay.month,
            day: selectedDay.day,
          })
          .toISO()
      );

      popoverState.hide();
    },
    [onChangeDate, popoverState, time, timezone]
  );

  return (
    <>
      <SelectTrigger
        icon={atlasCalendarEvent}
        iconClassName="text-subtle"
        className="w-full"
        onClick={popoverState.toggle}
        ref={anchorRef}
      >
        <span
          className={clsx({
            "text-subtle": !time,
          })}
        >
          {time ? time.toFormat("EEEE, MMMM d, yyyy") : "Select a date"}
        </span>
      </SelectTrigger>
      <Popover.Root state={popoverState}>
        <Popover.Content>
          <MonthCalendar
            leftActions={
              <Button
                icon={atlasClose}
                onClick={popoverState.hide}
                isGhost
                size="small"
              />
            }
            selected={time}
            onSelect={handleChange}
            allowPast={allowPast}
          />
        </Popover.Content>
      </Popover.Root>
    </>
  );
}
