import { CollaborativeCodingServiceType } from "./types";

export function isGenerationService(
  collaborativeCodingService: CollaborativeCodingServiceType
) {
  return [
    CollaborativeCodingServiceType.CODERPAD,
    CollaborativeCodingServiceType.CODESIGNAL,
    CollaborativeCodingServiceType.HACKERRANK,
  ].includes(collaborativeCodingService);
}
