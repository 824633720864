import { gql } from "generated/graphql-codegen";
import useMutation, { ToastDisplayCondition } from "utils/useMutation";

const RESYNC = gql(`
mutation ResyncJobWithGreenhouseMutation($input: ForceResyncJobInput!) {
  forceResyncJob(input: $input) {
    code
    success
    message
    atssyncJob {
      id
    }
  }
}
`);

export function useForceResyncJob({
  onCompleted,
}: {
  onCompleted?: () => void;
}) {
  return useMutation(RESYNC, {
    displayToastsOn: ToastDisplayCondition.ERROR,
    onCompleted,
  });
}
