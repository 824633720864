import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { atlasChevronLeft, atlasChevronRight } from "@resource/atlas/icons";
import clsx from "clsx";
import { DateTime, MonthNumbers } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { MonthCalendarDisplayProps } from "./utils/types";
import { generateCalendarGridDays } from "./utils/utils";

export function MonthCalendarDisplay({
  leftActions,
  defaultDate,
  shouldHighlightDay,
  shouldDisableDay,
  isSelectedDay,
  onClickDay,
  roundedIcons = true,
}: MonthCalendarDisplayProps) {
  const now = DateTime.local();
  const [currentYear, setCurrentYear] = useState<number>(
    defaultDate?.year ?? now.year
  );
  const [currentMonth, setCurrentMonth] = useState<MonthNumbers>(
    defaultDate?.month ?? now.month
  );

  const {
    ref,
    calendarSize: { isSmall, isMedium, isLarge },
  } = useCalendarSizing();

  const days = useMemo(() => {
    return generateCalendarGridDays({ year: currentYear, month: currentMonth });
  }, [currentYear, currentMonth]);

  const daysWithSelections = useMemo(() => {
    return days.map((day) => ({
      ...day,
      isSelected: isSelectedDay(day),
      isHighlighted: shouldHighlightDay?.(day) ?? false,
      isDisabled: shouldDisableDay(day),
    }));
  }, [days, shouldDisableDay, shouldHighlightDay, isSelectedDay]);

  return (
    <div ref={ref} className="w-full">
      <div className="flex justify-between items-center">
        <span className="mr-1">{leftActions}</span>
        <div className="flex text-sm font-semibold grow items-center">
          {DateTime.local(currentYear, currentMonth).toFormat("MMMM yyyy")}
        </div>
        <ButtonGroup size="small">
          <Button
            icon={atlasChevronLeft}
            isGhost
            onClick={() => {
              const dateForMonth = DateTime.local(currentYear, currentMonth);
              const nextMonth = dateForMonth.minus({ month: 1 });
              setCurrentYear(nextMonth.year);
              setCurrentMonth(nextMonth.month);
            }}
          />
          <Button
            icon={atlasChevronRight}
            isGhost
            onClick={() => {
              const dateForMonth = DateTime.local(currentYear, currentMonth);
              const nextMonth = dateForMonth.plus({ month: 1 });
              setCurrentYear(nextMonth.year);
              setCurrentMonth(nextMonth.month);
            }}
          />
        </ButtonGroup>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div className="flex items-center justify-center">Sun</div>
        <div className="flex items-center justify-center">Mon</div>
        <div className="flex items-center justify-center">Tue</div>
        <div className="flex items-center justify-center">Wed</div>
        <div className="flex items-center justify-center">Thu</div>
        <div className="flex items-center justify-center">Fri</div>
        <div className="flex items-center justify-center">Sat</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px text-sm">
        {daysWithSelections.map((day) => (
          <button
            key={day.date}
            type="button"
            onClick={(e) => {
              if (day.isDisabled) {
                e.preventDefault();
                return;
              }

              onClickDay(DateTime.fromISO(day.date));
            }}
            className={clsx("focus:z-10 text-body-md", {
              "py-1": !isSmall,
              "py-0.5": isSmall,
            })}
          >
            <time
              dateTime={day.date}
              className={clsx(
                "mx-auto flex items-center justify-center",
                roundedIcons && "rounded-full",
                day.isToday && "text-body-md-heavy text-purple-500",
                day.isDisabled && "cursor-not-allowed text-subtle",
                !day.isDisabled && "hover:bg-light-gray-200",
                day.isSelected &&
                  "text-white bg-purple-500 hover:bg-purple-400",
                day.isHighlighted &&
                  "text-purple-500 bg-purple-50 hover:bg-purple-100",
                {
                  "h-12 w-12": isLarge,
                  "h-10 w-10": isMedium,
                  "h-8 w-8": isSmall,
                }
              )}
            >
              {day.date.split("-").pop()?.replace(/^0/, "")}
            </time>
          </button>
        ))}
      </div>
    </div>
  );
}

function useCalendarSizing() {
  const ref = useRef<HTMLDivElement>(null);
  const [calendarSize, setCalendarSize] = useState<
    "small" | "medium" | "large"
  >("large");

  const updateCalendarSize = useCallback(() => {
    if (ref.current) {
      const { width } = ref.current.getBoundingClientRect();
      if (width < 320) {
        setCalendarSize("small");
      } else if (width < 400) {
        setCalendarSize("medium");
      } else {
        setCalendarSize("large");
      }
    }
  }, [setCalendarSize]);

  useEffect(() => {
    if (ref.current) {
      updateCalendarSize();
    }
  }, [updateCalendarSize]);

  useEffect(() => {
    const handleResize = () => {
      updateCalendarSize();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateCalendarSize]);

  return useMemo(() => {
    return {
      ref,
      calendarSize: {
        size: calendarSize,
        isSmall: calendarSize === "small",
        isMedium: calendarSize === "medium",
        isLarge: calendarSize === "large",
      },
    };
  }, [calendarSize]);
}
