import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasClose, atlasRingArrowUp } from "@resource/atlas/icons";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";

import { useOptionalExtensionController } from "./ExtensionControllerProvider";

/**
 * Show a banner if an update is available for the extension
 *
 * NOT CURRENTLY ACTIVE - `updateAvailable` is never set in the extension controller provider
 *
 * Leaving around for now in case we want to re-implement
 */
export function ExtensionUpdateBanner() {
  const extensionController = useOptionalExtensionController();
  const [updateDismissed, setUpdateDismissed] = useState(false);

  const handleDismissUpdate = useCallback(() => {
    if (!extensionController?.updateAvailable) {
      return;
    }
    try {
      setUpdateDismissed(true);
      localStorage.setItem(
        "dismissVersion",
        extensionController.updateAvailable
      );
    } catch (error) {
      console.warn("Unable to set dismissVersion in localStorage", error);
    }
  }, [extensionController?.updateAvailable, setUpdateDismissed]);

  useEffect(() => {
    try {
      const dismissVersion = localStorage.getItem("dismissVersion");
      if (
        extensionController &&
        dismissVersion === extensionController.updateAvailable
      ) {
        setUpdateDismissed(true);
      }
    } catch (error) {
      console.warn("Unable to get dismissVersion from localStorage", error);
    }
  }, [setUpdateDismissed, extensionController]);

  if (extensionController?.updateAvailable && !updateDismissed) {
    return (
      <div className="flex-shrink-0 py-2 px-4 bg-purple-500 flex items-center justify-between text-white mb-4">
        <div className="flex items-center gap-2">
          <Icon content={atlasRingArrowUp} />
          <span>A new version is available.</span>
        </div>
        <div className="flex items-center gap-2">
          <Link
            href="https://support.guide.co/en/articles/6797940-installing-the-guide-extension-for-google-chrome#h_e51dbad98d"
            className="text-body-lg-heavy !text-white hover:!bg-purple-700 !no-underline p-2"
            target="_blank"
          >
            Update now
          </Link>
          <Button
            size="small"
            className="!text-white hover:!bg-purple-700"
            isGhost
            onClick={handleDismissUpdate}
          >
            <Icon content={atlasClose} />
          </Button>
        </div>
      </div>
    );
  }

  return null;
}
