import { View } from "@resource/atlas/view/View";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { gql } from "generated/graphql-codegen";
import { ScheduledInterviewForEditInterviewInviteAndNotifyFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useMemo } from "react";
import useQuery from "utils/useQuery";

import { FormRightActions } from "../../generic/misc/FormRightActions";
import { useEditInterviewInviteAndNotifyState } from "../__hooks__/useEditInterviewInviteAndNotifyState";
import { useUpdateInterviewerInviteInfo } from "../__hooks__/useUpdateInterviewInviteInfo";
import { mapScheduledInterviewToValueSet } from "../utils/mapping";
import { EditInterviewInviteAndNotifyForm } from "./EditInterviewInviteAndNotifyForm";

gql(`
  fragment ScheduledInterviewForEditInterviewInviteAndNotify on ScheduledInterview {
    id
    ...ScheduledInterviewForGoogleEventView
  }  
`);

const GET_SCHEDULED_INTERVIEW = gql(`
  query GetScheduledInterviewForEditInterviewInviteAndNotify($scheduledInterviewId: String!) {
    scheduledInterviewById(id: $scheduledInterviewId) {
      ...ScheduledInterviewForEditInterviewInviteAndNotify
    }
  }  
`);

type EditInterviewInviteAndNotifyViewProps = {
  scheduledInterviewId: string;
  onCancel: () => void;
  onCompleted?: () => void;
};

export function EditInterviewInviteAndNotifyView({
  scheduledInterviewId,
  ...props
}: EditInterviewInviteAndNotifyViewProps) {
  const { data, loading } = useQuery(GET_SCHEDULED_INTERVIEW, {
    variables: {
      scheduledInterviewId,
    },
  });
  const scheduledInterview = data?.scheduledInterviewById;

  if (loading) {
    return <DialogLoading />;
  }

  if (!scheduledInterview) {
    return <DialogError errorCode="404" />;
  }

  return (
    <EditInterviewInviteAndNotifyDisplay
      scheduledInterview={scheduledInterview}
      {...props}
    />
  );
}

type EditInterviewInviteAndNotifyDisplayProps = Omit<
  EditInterviewInviteAndNotifyViewProps,
  "scheduledInterviewId"
> & {
  scheduledInterview: ScheduledInterviewForEditInterviewInviteAndNotifyFragment;
};

function EditInterviewInviteAndNotifyDisplay({
  scheduledInterview,
  onCancel,
  onCompleted,
}: EditInterviewInviteAndNotifyDisplayProps) {
  const mappedValueSet = useMemo(
    () => mapScheduledInterviewToValueSet(scheduledInterview),
    [scheduledInterview]
  );
  const state = useEditInterviewInviteAndNotifyState({
    defaultValues: {
      template: null,
      interviewerConfirmationTitle:
        scheduledInterview.interviewerConfirmationTitle,
      interviewerCustomInstructions:
        scheduledInterview.interviewerCustomInstructions,
      shouldNotifyInterviewers: true,
    },
    valueSet: mappedValueSet,
  });
  const shouldNotify = state.form.watch("shouldNotifyInterviewers");

  const [updateInterviewerInviteInfo, { loading: isUpdating }] =
    useUpdateInterviewerInviteInfo({
      onCompleted,
    });

  const handleSave = useCallback(async () => {
    const formValues = state.form.getValues();
    await updateInterviewerInviteInfo({
      scheduledInterviewId: scheduledInterview.id,
      interviewerConfirmationTitle: formValues.interviewerConfirmationTitle,
      interviewerCustomInstructions: formValues.interviewerCustomInstructions,
      shouldNotifyInterviewers: formValues.shouldNotifyInterviewers,
      note: formValues.message,
    });
  }, [state.form, updateInterviewerInviteInfo, scheduledInterview.id]);

  return (
    <View
      content={{
        title: "Edit interview invite and notify",
        subTitle:
          "Update the invitation details for interviewers and optionally notify interviewers of the changes.",
      }}
      footer={{
        rightActions: (
          <FormRightActions
            cancel={{
              onClick: onCancel,
            }}
            save={{
              onClick: state.form.handleSubmit(handleSave),
              children: shouldNotify ? "Send notification" : "Save changes",
              isLoading: isUpdating,
            }}
            disabledTooltipContent={state.disabledTooltipContent}
          />
        ),
      }}
    >
      <EditInterviewInviteAndNotifyForm state={state} />
    </View>
  );
}
