import { DateTime } from "luxon";

/**
 * Add a buffer to the scroll position so that the value we want visible isn't at the very edge of the calendar
 */
export function withCalendarScrollBuffer(
  dateTime: DateTime,
  { bufferHours }: { bufferHours: number }
) {
  const bufferedDateTime = dateTime.minus({ hours: bufferHours });

  // Check if the buffered time is on the same day as the original
  if (bufferedDateTime.hasSame(dateTime, "day")) {
    return bufferedDateTime;
  }

  // If not, return the start of the original day
  return dateTime.startOf("day");
}
