/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import { Link } from "@resource/atlas/link/Link";
import { SelectJobInterviewDialog } from "client/components/interviews/SelectJobInterviewDialog";
import { getAtssyncInfo } from "client/utils/atssync";
import { gql } from "generated/graphql-codegen";
import {
  AtssyncAccountType,
  ScheduleInterviewForInterviewDetailsFragment,
} from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import useMutation from "utils/useMutation";

import { ResyncScheduledInterviewToGreenhouseConfirmation } from "../../__components/ResyncScheduledInterviewToGreenhouseConfirmation";

const CHANGE_JOB_INTERVIEW = gql(`
  mutation ChangeJobInterviewForScheduledInterview(
    $input: ChangeJobInterviewForScheduledInterviewInput!
  ) {
    changeJobInterviewForScheduledInterview(input: $input) {
      success
      message
      code
      scheduledInterview {
        id
        ...ScheduleInterviewForInterviewDetails
      }
    }
  }
`);

/**
 * Show the status of the atssync scheduled interview
 * Used for both scheduling and lite customers
 */
export function AtssyncScheduledInterviewIntegrationStatus({
  scheduledInterview,
}: {
  scheduledInterview: ScheduleInterviewForInterviewDetailsFragment;
}) {
  // TODO: Infer integration type from account. Cannot rely on atssyncScheduledInterview connection as it could be null
  const atssyncAccountType = AtssyncAccountType.GREENHOUSE;
  const atssyncInfo = getAtssyncInfo(atssyncAccountType);
  const resyncStore = useDialogStore();
  const selectJobInterviewDialogStore = useDialogStore();
  const [changeJobInterview] = useMutation(CHANGE_JOB_INTERVIEW, {
    onCompleted: () => {
      selectJobInterviewDialogStore.hide();
    },
  });
  const onSubmitChange = useCallback(
    async (interviewId: string) => {
      await changeJobInterview({
        variables: {
          input: {
            scheduledInterviewId: scheduledInterview.id,
            interviewId,
          },
        },
      });
    },
    [changeJobInterview, scheduledInterview.id]
  );

  if (scheduledInterview.isCancelled) {
    return null;
  }

  if (
    (!scheduledInterview.atssyncScheduledInterview ||
      scheduledInterview.atssyncScheduledInterview.remoteWasDeleted) &&
    scheduledInterview.createdByGuide
  ) {
    return (
      <>
        <div className="flex w-full space-x-3">
          <Icon content={atssyncInfo.icon} className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="text-[#F33E22]">
              {scheduledInterview.atssyncScheduledInterview?.remoteWasDeleted
                ? `It looks like this interview has been removed in ${atssyncInfo.name}.`
                : `This interview is not linked to a ${atssyncInfo.name} interview.`}
            </span>
            <div className="flex space-x-2">
              {!scheduledInterview.atssyncScheduledInterview && (
                /* Show resync if the interview doesn't exist, but hide it if remoteWasDeleted since resync will do nothing */
                <Link
                  onClick={() => {
                    resyncStore.show();
                  }}
                >
                  Resync in {atssyncInfo.name}
                </Link>
              )}
              {scheduledInterview.guide && (
                <Link
                  onClick={() => {
                    selectJobInterviewDialogStore.show();
                  }}
                >
                  Link interview
                </Link>
              )}
            </div>
          </div>
        </div>
        <ResyncScheduledInterviewToGreenhouseConfirmation
          store={resyncStore}
          scheduledInterviewId={scheduledInterview.id}
        />
        {scheduledInterview.guide && (
          <SelectJobInterviewDialog
            jobId={scheduledInterview.guide.jobId}
            onSubmit={onSubmitChange}
            store={selectJobInterviewDialogStore}
          />
        )}
      </>
    );
  }

  return (
    <>
      <div className="flex w-full space-x-3">
        <Icon content={atssyncInfo.icon} className="h-5 w-5" />
        <div className="flex flex-col">
          <span>
            Linked to the{" "}
            <b>
              {scheduledInterview.atssyncScheduledInterview?.name ?? "Unknown"}
            </b>{" "}
            interview
          </span>
          {scheduledInterview.createdByGuide && scheduledInterview.guide && (
            <Link
              onClick={() => {
                selectJobInterviewDialogStore.show();
              }}
            >
              Change linked interview
            </Link>
          )}
        </div>
      </div>
      {scheduledInterview.guide && (
        <SelectJobInterviewDialog
          jobId={scheduledInterview.guide.jobId}
          onSubmit={onSubmitChange}
          store={selectJobInterviewDialogStore}
        />
      )}
    </>
  );
}
