import { filterOutNullsAndUndefined } from "shared/utils/filtering";

import { ScheduledInterviewFieldChange } from "./types";

export const displayTextForActorString = (
  fieldChange: ScheduledInterviewFieldChange
) => {
  switch (fieldChange) {
    case ScheduledInterviewFieldChange.TIME:
      return "the start time";
    case ScheduledInterviewFieldChange.DURATION:
      return "the duration";
    case ScheduledInterviewFieldChange.TITLE:
      return "the title";
    case ScheduledInterviewFieldChange.INTERVIEWERS:
      return "the interviewers";
    case ScheduledInterviewFieldChange.VIDEO_CONFERENCING:
      return "the video conferencing";
    case ScheduledInterviewFieldChange.COLLABORATIVE_CODING:
      return "the collaborative coding";
    case ScheduledInterviewFieldChange.CONFERENCE_ROOM:
      return "the conference room";
    case ScheduledInterviewFieldChange.PRIVACY:
      return "the privacy settings";
    default:
      return null;
  }
};

export const displayTextForScheduledInterviewFieldChange = (
  fieldChange: ScheduledInterviewFieldChange
) => {
  switch (fieldChange) {
    case ScheduledInterviewFieldChange.TIME:
      return "Start time";
    case ScheduledInterviewFieldChange.DURATION:
      return "Duration";
    case ScheduledInterviewFieldChange.TITLE:
      return "Title";
    case ScheduledInterviewFieldChange.INTERVIEWERS:
      return "Interviewers";
    case ScheduledInterviewFieldChange.VIDEO_CONFERENCING:
      return "Video conferencing";
    case ScheduledInterviewFieldChange.COLLABORATIVE_CODING:
      return "Collaborative coding";
    case ScheduledInterviewFieldChange.IS_CANCELLED:
      return "Was cancelled";
    case ScheduledInterviewFieldChange.RESTORED:
      return "Was restored";
    case ScheduledInterviewFieldChange.CONFERENCE_ROOM:
      return "Conference room";
    case ScheduledInterviewFieldChange.PRIVACY:
      return "Privacy";
    default:
      return "Unknown field change";
  }
};
export const actorFieldChangesText = ({
  fieldChanges,
  isPrefix,
}: {
  fieldChanges: ScheduledInterviewFieldChange[];
  isPrefix?: boolean;
}): string => {
  if (fieldChanges.length === 0) {
    return "updated no fields";
  }

  const wasRestored = fieldChanges.includes(
    ScheduledInterviewFieldChange.RESTORED
  );
  const otherChanges = fieldChanges.filter(
    (change) => change !== ScheduledInterviewFieldChange.RESTORED
  );

  if (wasRestored && otherChanges.length === 0) {
    return "restored the interview";
  }

  const changedFields = otherChanges
    .map(displayTextForActorString)
    .filter(filterOutNullsAndUndefined);

  if (wasRestored) {
    if (changedFields.length === 1) {
      return `restored the interview and updated ${changedFields[0]}`;
    }

    if (changedFields.length === 2) {
      if (isPrefix) {
        return `restored the interview, updated ${changedFields[0]} and ${changedFields[1]}`;
      }

      return `restored the interview and updated ${changedFields[0]} and ${changedFields[1]}`;
    }

    if (isPrefix) {
      return `restored the interview, updated ${changedFields.join(", ")}`;
    }

    const lastField = changedFields.pop();
    return `restored the interview and updated ${changedFields.join(
      ", "
    )}, and ${lastField}`;
  }

  if (changedFields.length === 1) {
    return `updated ${changedFields[0]}`;
  }

  if (changedFields.length === 2) {
    return `updated ${changedFields[0]} and ${changedFields[1]}`;
  }

  if (isPrefix) {
    return `updated ${changedFields.join(", ")}`;
  }

  const lastField = changedFields.pop();
  return `updated ${changedFields.join(", ")}, and ${lastField}`;
};

export function hasRescheduleRequirementBeenFulfilled({
  fieldChanges,
}: {
  fieldChanges: ScheduledInterviewFieldChange[] | null;
}) {
  if (!fieldChanges) {
    return false;
  }

  return true;
}
