import { Button } from "@resource/atlas/button/Button";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasClipboard, atlasRingInfo } from "@resource/atlas/icons";
import { useAuthContext } from "auth/context";
import { useCopyToClipboard } from "client/hooks/useCopyToClipboard";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  ActivityForFeedFragment,
  ActivityVerb,
  GuideForActivityFeedFragment,
} from "generated/graphql-codegen/graphql";
import React, {
  ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import { captureSentryError } from "shared/errors/sentryError";

import { ErrorBoundary } from "../generic/errors/ErrorBoundary";
import {
  ActivityFunctionMap,
  ActivityGroupFunctionMap,
  BaseProps,
  FeedContext,
} from "./__utils/types";
import { ActivityTimestamp } from "./ActivityTimestamp";
import { GuideActivityFeed } from "./GuideFeed";
import { InterviewerPoolActivityFeed } from "./InterviewerPoolFeed";
import { InterviewerPoolUserActivityFeed } from "./InterviewerPoolUserFeed";
import { InterviewActivityFeed } from "./InterviewFeed";
import { SchedulingRequestActivityFeed } from "./SchedulingRequestFeed";

gql(`fragment ActivityForFeed on Activity {
  id
  verb
  meta
  eventTime
  guidePostId
  schedulingRequestId
  scheduledInterviewId
  commentId
  automatedActionId
  actor {
    id
    name
    imageUrl
  }
  userMembership {
    id
    firstName
    lastName
    name
    imageUrl
    email
  }
  interviewerPool {
    id
    name
    shadowsRequiredForTrainees
    reverseShadowsRequiredForTrainees
  }
  scheduledInterview {
    id
    ...ScheduledInterviewForScheduledInterviewCard
    interviewers {
      id
      isShadow
      interviewerRequirement {
        id
        includeShadower
        tags {
          id
          name
        }
      }
    }
  }
}`);

gql(`
  fragment GuideForActivityFeed on Guide {
    id
    candidate {
      id
      name: internalName
    }
    publicUrl
  }
`);

gql(`
  fragment SchedulingRequestForActivityFeed on SchedulingRequest {
    id
    guide {
      id
      ...GuideForActivityFeed
    }
    activity {
      id
      ...ActivityForFeed
    }
  }
`);

function ErrorFallback({ activity }: { activity: ActivityForFeedFragment }) {
  return (
    <div className="flex gap-2 text-body-md text-subtle items-start">
      <div className="w-6">
        <Icon content={atlasRingInfo} className="w-6 h-6" />
      </div>
      <div className="flex w-full">
        <span className="flex flex-row justify-between w-full">
          <span>
            <span className="text-body-md-heavy text-dark">
              {activity.actor?.name ?? "Someone"}
            </span>{" "}
            performed the {activity.verb} action.
            <ActivityTimestamp eventTime={activity.eventTime} />
          </span>
        </span>
      </div>
    </div>
  );
}

const ActivityItem = React.memo(
  ({
    activity,
    count,
    activityMap,
    ...rest
  }: {
    activity: ActivityForFeedFragment;
    count?: number;
    activityMap: ActivityFunctionMap<ActivityVerb>;
  } & BaseProps) => {
    const itemContentRenderer = activityMap[activity.verb];
    if (!itemContentRenderer) {
      return <></>;
    }
    const content = itemContentRenderer({
      ...rest,
      ...activity,
      meta: activity.meta as never,
    });

    return (
      <ErrorBoundary fallback={() => <ErrorFallback activity={activity} />}>
        <>
          <div className="flex gap-2 text-body-md text-subtle items-start">
            <div className="w-6">{content.icon}</div>
            <div className="flex w-full">{content.content}</div>
          </div>
          {content.extraContent && (
            <div className="ml-8">{content.extraContent}</div>
          )}
        </>
      </ErrorBoundary>
    );
  }
);

const ActivityItemGroup = React.memo(
  ({
    activities,
    activityGroupMap,
    ...rest
  }: {
    activities: ActivityForFeedFragment[];
    activityGroupMap: ActivityGroupFunctionMap<ActivityVerb>;
  } & BaseProps) => {
    const itemContentRenderer = activityGroupMap[activities[0].verb];
    if (!itemContentRenderer) {
      return <></>;
    }

    const content = itemContentRenderer({
      ...rest,
      activities: activities.map((activity) => ({
        ...activity,
        meta: activity.meta as never,
      })),
    });

    return (
      <ErrorBoundary
        fallback={() => <ErrorFallback activity={activities[0]} />}
      >
        <div className="flex gap-2 text-body-md text-subtle items-start w-full">
          <div className="w-6">{content.icon}</div>
          <div className="flex w-full">{content.content}</div>
        </div>
        {content.extraContent && (
          <div className="ml-8">{content.extraContent}</div>
        )}
      </ErrorBoundary>
    );
  }
);

export const ActivityFeed = React.memo(
  ({
    activity,
    guide,
    context,
    showHeading,
    loading,
    nextPage,
    hideDebugOption,
    ...rest
  }: ComponentPropsWithoutRef<"div"> & {
    activity: ActivityForFeedFragment[];
    guide?: GuideForActivityFeedFragment;
    context: FeedContext;
    showHeading?: boolean;
    nextPage: () => void;
    loading: boolean;
    hideDebugOption?: boolean;
  }) => {
    const { user } = useAuthContext();
    const [debugMode, setDebugMode] = useState(false);
    const { ref: loadMoreRef, inView } = useInView();

    const loadNextPage = useCallback(() => {
      if (!loading && inView) {
        nextPage();
      }
    }, [loading, inView, nextPage]);

    useEffect(() => {
      // Adds a slightly delay to loading; looks better visually and ensure we don't double load pages by ensuring previous page has loaded
      // before the next one loads.
      const timeout = setTimeout(loadNextPage, 250);

      return () => clearTimeout(timeout);
    }, [loadNextPage, nextPage]);

    const map = useMemo(() => {
      switch (context) {
        case "InterviewerPool":
          return new InterviewerPoolActivityFeed();
        case "InterviewerPoolUser":
          return new InterviewerPoolUserActivityFeed();
        case "SchedulingRequest":
          return new SchedulingRequestActivityFeed();
        case "Interview":
          return new InterviewActivityFeed();
        case "Guide":
        default:
          return new GuideActivityFeed();
      }
    }, [context]);

    const groupedActivity = useMemo(() => {
      return map.groupActivity(activity);
    }, [activity, map]);
    const toggleDebugMode = useCallback(() => {
      setDebugMode((prev) => !prev);
    }, []);

    if (!groupedActivity.length) {
      return null;
    }

    return (
      <div {...rest} className={clsx(rest.className, "p-6 space-y-3")}>
        {showHeading && <h4 className="text-body-md-heavy">Activity</h4>}
        {groupedActivity.map((group: ActivityForFeedFragment[]) => {
          // If we've grouped the activities, we likely are expecting a renderer
          if (group.length > 1) {
            const activityGroupMap =
              map.activityGroupMap as unknown as ActivityGroupFunctionMap<ActivityVerb>;
            const itemContentRenderer = activityGroupMap[group[0].verb];

            if (!itemContentRenderer) {
              captureSentryError(
                new Error("No group map function for activity group")
              );

              return null;
            }
          }

          return group.length > 1 ? (
            <>
              <ActivityItemGroup
                key={group[0].id}
                activities={group}
                activityGroupMap={
                  map.activityGroupMap as unknown as ActivityGroupFunctionMap<ActivityVerb>
                }
                guideUrl={guide?.publicUrl}
                candidateName={guide?.candidate.name}
                context={context}
              />
              {debugMode && <CopyActivityContentButton data={group} />}
            </>
          ) : (
            <>
              <ActivityItem
                key={group[0].id}
                activity={group[0]}
                count={group.length}
                guideUrl={guide?.publicUrl}
                candidateName={guide?.candidate.name}
                context={context}
                // We need to cast because the map doesn't know the correct type but we can assume it is correct if we've passed in the
                // correct context.
                activityMap={
                  map.activityMap as unknown as ActivityFunctionMap<ActivityVerb>
                }
              />
              {debugMode && <CopyActivityContentButton data={group[0]} />}
            </>
          );
        })}
        <div ref={loadMoreRef} />
        {!hideDebugOption && (user?.isStaff || user?.isSuperuser) && (
          <Button variant="danger" size="small" onClick={toggleDebugMode}>
            {debugMode ? "Disable" : "Enable"} debug mode
          </Button>
        )}
      </div>
    );
  }
);

function CopyActivityContentButton({
  data,
}: {
  data: Record<string, unknown> | Record<string, unknown>[];
}) {
  const copy = useCopyToClipboard({
    message: "Activity data copied to clipboard.",
  });
  const onClick = useCallback(() => {
    copy(JSON.stringify(data));
  }, [copy, data]);

  return (
    <Button
      icon={atlasClipboard}
      onClick={onClick}
      size="xs"
      variant="primary"
    />
  );
}
