import { GiphyFetch } from "@giphy/js-fetch-api";
import type { SerializedRichBlockNode } from "@resource/atlas/content-editor/__utils/rich-blocks";

// config
// ------

export const RICH_BLOCK_NAME = "gif";

export const DEFAULT_DATA: GifData = {
  giphyId: "",
  embedUrl: "",
};

export const giphyFetch = new GiphyFetch(
  process.env.NEXT_PUBLIC_GIPHY_SDK_KEY || ""
);

// types
// -----

export type GifName = typeof RICH_BLOCK_NAME;

export type GifData = {
  giphyId: string;
  embedUrl: string;
};

export type SerializedGifNode = SerializedRichBlockNode<GifName, GifData>;
