/**
 * Component responsible for rendering an interview title. There are 3 possibilities for titles;
 * 1. Plain text
 * 2. Slate (HTML with variables to be replaced)
 * 3. Lexical state
 *
 * This component hides away the complexity of rendering these.
 */

import { useRender } from "@resource/atlas/content-editor/renderer";
import type { AtlasContentEditorSerializedState } from "@resource/atlas/content-editor/types";
import { VariableRendererProvider } from "@resource/atlas/content-editor/variables";
import { AllowedInterviewTemplateTitleTags } from "@resource/common";
import SafeHtml from "components/Generic/SafeHtml";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { renderInterviewTitle } from "shared/guide-content/interview-title/renderer";
import { useInterviewValueSet } from "shared/guide-content/interview-title/variables";
import { getTitleFromLexicalJson } from "shared/utils/lexical";

type Interview = {
  title: string;
};

export type InterviewTitleProps = ComponentPropsWithoutRef<"div"> & {
  interview: Interview;
};

/**
 * Legacy (slate) title renderer
 */
function LegacyTitle({
  interview,
  ...props
}: Omit<InterviewTitleProps, "valueSet">) {
  return (
    <SafeHtml
      html={interview.title}
      allowedTags={AllowedInterviewTemplateTitleTags}
      {...props}
    />
  );
}

/**
 * Lexical title renderer
 */
function LexicalTitle({ interview, ...props }: InterviewTitleProps) {
  const lexicalData = useMemo(() => {
    return JSON.parse(interview.title) as AtlasContentEditorSerializedState;
  }, [interview.title]);
  const textTitleWithoutVariables = useMemo(() => {
    return getTitleFromLexicalJson(interview.title);
  }, [interview.title]);

  const renderedContent = useRender(renderInterviewTitle, lexicalData);

  const valueSet = useInterviewValueSet({
    replacementData: {},
  });

  return (
    <VariableRendererProvider valueSet={valueSet}>
      <span {...props} title={textTitleWithoutVariables}>
        {renderedContent}
      </span>
    </VariableRendererProvider>
  );
}

function isLexicalContent(title: string) {
  try {
    JSON.parse(title);
    return true;
  } catch (err) {
    return false;
  }
}

export function InterviewTitle({ interview, ...props }: InterviewTitleProps) {
  const isLexical = isLexicalContent(interview.title);

  return isLexical ? (
    <LexicalTitle interview={interview} {...props} />
  ) : (
    <LegacyTitle interview={interview} {...props} />
  );
}
