import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { SelfScheduleRequestDetails } from "./SelfScheduleRequestDetails";
import { SelfScheduleTimeSelect } from "./SelfScheduleTimeSelect";
import { SelfScheduleTimezoneSelect } from "./SelfScheduleTimezoneSelect";

export function SelectTimeContent({
  state,
}: {
  state: SelfScheduleSubmissionState;
}) {
  return (
    <div className="flex flex-col w-full divide-y divide-gray-border">
      <div className="flex flex-col p-4 space-y-2">
        <SelfScheduleRequestDetails className="py-2" state={state} />
        <SelfScheduleTimezoneSelect state={state} />
      </div>
      <div className="flex flex-col space-y-4 p-4">
        <div className="text-h4">Select time</div>
        <div className="flex justify-center w-full">
          <SelfScheduleTimeSelect state={state} />
        </div>
      </div>
    </div>
  );
}
