import {
  atlasLogoMicrosoftTeamsColor,
  atlasPhone,
  serviceCoderpad,
  serviceCodesignal,
  serviceGoogleMeet,
  serviceHackerrank,
  serviceZoom,
} from "@resource/atlas/icons";
import { displayPhoneNumber, isPossibleNumber } from "@resource/common";
import { useMemo } from "react";
import {
  isCoderpadCodingUrl,
  isCodeSignalCodingUrl,
  isHackerRankCodingUrl,
} from "shared/guide-scheduler/collaborative-coding/utils";
import {
  isGoogleMeetConferencingUrl,
  isTeamsConferencingUrl,
  isZoomConferencingUrl,
} from "shared/utils/video-conferencing";

export type ServiceIconType = {
  data: JSX.Element;
  size: number;
};

export type ExternalServiceLabelAndIcon = {
  icon: ServiceIconType | string | null;
  label?: string | null;
  textColorClassname: string | null;
};

export function getExternalServiceLabelAndIcon({
  url,
  isInPast,
}: {
  url?: string | null;
  isInPast?: boolean;
}) {
  const data: ExternalServiceLabelAndIcon = {
    icon: null,
    label: null,
    textColorClassname: null,
  };

  if (!url) {
    return data;
  }

  if (isZoomConferencingUrl(url)) {
    data.label = "Zoom meeting";
    data.icon = isInPast ? serviceZoom : serviceZoom;
  } else if (isGoogleMeetConferencingUrl(url)) {
    data.label = "Google meet";
    data.icon = isInPast ? serviceGoogleMeet : serviceGoogleMeet;
  } else if (isTeamsConferencingUrl(url)) {
    data.label = "Teams meeting";
    data.icon = isInPast
      ? atlasLogoMicrosoftTeamsColor
      : atlasLogoMicrosoftTeamsColor;
  } else if (isCodeSignalCodingUrl(url)) {
    data.label = "Codesignal link";
    data.icon = isInPast ? serviceCodesignal : serviceCodesignal;
  } else if (isCoderpadCodingUrl(url)) {
    data.label = "Coderpad link";
    data.icon = isInPast ? serviceCoderpad : serviceCoderpad;
  } else if (isHackerRankCodingUrl(url)) {
    data.label = "HackerRank link";
    data.icon = isInPast ? serviceHackerrank : serviceHackerrank;
  } else if (isPossibleNumber(url)) {
    data.icon = isInPast ? atlasPhone : atlasPhone;
  }
  return data;
}

export function useExternalServiceLabelAndIcon({
  url,
  isInPast,
}: {
  url?: string | null;
  isInPast?: boolean;
}): ExternalServiceLabelAndIcon {
  const externalServiceLabelAndIcon = useMemo(
    () =>
      getExternalServiceLabelAndIcon({
        url,
        isInPast,
      }),
    [url, isInPast]
  );

  return externalServiceLabelAndIcon;
}

export function getDisplayInfo({
  url,
  label,
}: {
  url: string;
  label?: string | null;
}): {
  displayLabel: string;
  href: string;
  displayUrl?: string;
} {
  const isPhone = isPossibleNumber(url);

  if (isPhone) {
    return {
      displayLabel: displayPhoneNumber(url),
      href: `tel:${url}`,
    };
  }

  return {
    displayLabel: label ?? url ?? "",
    href: url,
  };
}
