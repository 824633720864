import { RescheduleReasonFormData } from "client/components/scheduled-interviews/ReportingReasons/hooks/useReportRescheduleReasonState";
import { mapRescheduleInterviewRequirementFragmentToRescheduleReason } from "client/components/scheduled-interviews/ReportingReasons/utils/mapping";
import { useMemo } from "react";

import { ScheduledInterviewForSchedulerReportingReasons } from "../utils/types";

export function useSchedulerDefaultReportingReason({
  scheduledInterviews,
}: {
  scheduledInterviews: ScheduledInterviewForSchedulerReportingReasons[];
}) {
  return useMemo((): RescheduleReasonFormData | undefined => {
    // All interviews for a task should have the same reason
    const interviewsWithPendingReschedule = scheduledInterviews.find(
      (i) => i.pendingRescheduleInterviewRequirement
    );

    if (
      interviewsWithPendingReschedule?.pendingRescheduleInterviewRequirement
    ) {
      return mapRescheduleInterviewRequirementFragmentToRescheduleReason(
        interviewsWithPendingReschedule?.pendingRescheduleInterviewRequirement
      );
    }

    return undefined;
  }, [scheduledInterviews]);
}
