import { NetworkStatus, useSubscription } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import { reverse, sortBy, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import useQuery from "utils/useQuery";

const FETCH_SCHEDULING_REQUEST_ACTIVITY = gql(`
query FetchSchedulingRequestActivity($schedulingRequestId: ID!, $cursor: String) {
    schedulingRequestById(id: $schedulingRequestId) {
      id
      activity (cursor: $cursor) {
        ...ActivityForFeed
      }
    }
  }
`);

const SCHEDULING_REQUEST_UPDATE = gql(`
  subscription SchedulingRequestDetailsUpdatedForActivity($id: ID!) {
    schedulingRequestByIdUpdated(schedulingRequestId: $id) {
      id
      updatedAt
    }
  }
`);

export function useSchedulingRequestActivity(schedulingRequestId: string) {
  const { data, networkStatus, fetchMore, refetch, ...queryResult } = useQuery(
    FETCH_SCHEDULING_REQUEST_ACTIVITY,
    {
      variables: {
        schedulingRequestId,
        cursor: null,
      },
    }
  );
  // Use network status so that it doesn't show loading when refetching
  const loading = networkStatus === NetworkStatus.loading;

  const activity = useMemo(() => {
    const items = data?.schedulingRequestById?.activity ?? [];

    // Ensure the order is correct and deduplicated given we append to the cache on certain actions
    return reverse(sortBy(uniqBy(items, "id"), "eventTime"));
  }, [data]);

  const nextPage = useCallback(() => {
    if (activity.length && !loading) {
      fetchMore({
        variables: {
          schedulingRequestId,
          cursor: activity[activity.length - 1].eventTime,
        },
      });
    }
  }, [activity, fetchMore, schedulingRequestId, loading]);

  useSubscription(SCHEDULING_REQUEST_UPDATE, {
    variables: {
      id: schedulingRequestId,
    },
    onSubscriptionData: () => {
      refetch();
    },
  });

  return {
    ...queryResult,
    activity,
    nextPage,
    loading,
    refetch,
  };
}
