import { TimeInput } from "client/components/generic/inputs/TimeInput";
import { FormGroup } from "client/components/generic/misc/FormGroup";
import { EditableDuration } from "client/components/time/EditableDuration";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { Controller } from "react-hook-form";

import { UpsertScheduledInterviewFormState } from "../__hooks/useUpsertScheduledInterviewFormState";

export function EditScheduledInterviewStartAndDuration({
  state: { form, timezone },
}: {
  state: UpsertScheduledInterviewFormState;
}) {
  const handleStartTimeChange = useCallback(
    (time: string) => {
      const newDateTime = DateTime.fromISO(time, { zone: timezone }).set({
        second: 0,
        millisecond: 0,
      });

      form.setValue("scheduledInterview.startTime", newDateTime.toISO(), {
        shouldDirty: true,
        shouldValidate: true,
      });

      const duration = form.getValues("scheduledInterview.duration");
      const endTime = newDateTime.plus({ minutes: duration });

      form.setValue("scheduledInterview.endTime", endTime.toISO(), {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [form, timezone]
  );

  const handleDurationChange = useCallback(
    (duration: number) => {
      form.setValue("scheduledInterview.duration", duration, {
        shouldDirty: true,
        shouldValidate: true,
      });

      const formStartTime = form.getValues("scheduledInterview.startTime");

      if (formStartTime) {
        const startTime = DateTime.fromISO(formStartTime, { zone: timezone });
        const endTime = startTime.plus({ minutes: duration });
        form.setValue("scheduledInterview.endTime", endTime.toISO(), {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    },
    [form, timezone]
  );

  return (
    <div className="flex space-x-2">
      <Controller
        name="scheduledInterview.startTime"
        control={form.control}
        render={({ field, fieldState: { isTouched, error } }) => (
          <FormGroup
            label="Time"
            errorMessage={isTouched ? error?.message : undefined}
            className="w-[50%]"
          >
            <TimeInput
              {...field}
              value={field.value || null}
              setValue={handleStartTimeChange}
              className="!bg-light-gray-500"
              timezone={timezone}
            />
          </FormGroup>
        )}
      />
      <Controller
        name="scheduledInterview.duration"
        control={form.control}
        render={({ field, fieldState: { isTouched, error } }) => (
          <FormGroup
            label="Duration"
            errorMessage={isTouched ? error?.message : undefined}
            className="w-[50%]"
          >
            <EditableDuration
              {...field}
              currentDuration={field.value}
              onSelectDuration={handleDurationChange}
            />
          </FormGroup>
        )}
      />
    </div>
  );
}
