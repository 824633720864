import { CypressData } from "client/cypress-data-keys";
import { DateTime } from "luxon";
import { Fragment } from "react";

import { useCalendarSettings } from "../../hooks/settings";
import { useCalendarSizes } from "../../hooks/sizing";
import { HOURS_IN_DAY } from "../../utils/utils";

/** Sticky time indicators on the left side. Scroll vertically with overflow. */
export function LeftMarginHorizontalTimeIndicators() {
  const { twentyFourHourFormat } = useCalendarSettings();
  const { leftOffsetWidth, hourHeight } = useCalendarSizes();

  return (
    <div
      className="col-start-1 col-end-2 row-start-1 flex flex-col sticky left-0 bg-white border-r border-gray-border"
      style={{
        width: `${leftOffsetWidth.rem}rem`,
        zIndex: 30,
      }}
    >
      <div
        className="col-start-1 col-end-2 row-start-1 grid h-full sticky left-0"
        style={{
          gridTemplateRows: `repeat(${HOURS_IN_DAY}, minmax(${hourHeight.rem}rem, 1fr))`,
          pointerEvents: "none",
        }}
      >
        {[...Array(HOURS_IN_DAY).keys()].map((i) => {
          const time = DateTime.fromObject({ hour: i });
          const isNotMidnight = time.hour !== 0;

          return (
            <Fragment key={time.toISO()}>
              <div
                className="relative"
                data-cy={CypressData.calendar.horizontalLine}
              >
                {isNotMidnight && (
                  <div className="absolute left-0 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-disabled">
                    {twentyFourHourFormat
                      ? time.toFormat("H:00")
                      : time.toFormat("ha")}
                  </div>
                )}
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
