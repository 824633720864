/* eslint-disable react/no-unused-prop-types */
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { InterviewerForSelectionFragment } from "generated/graphql-codegen/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";

import { UsersSelect } from "../../generic/select/UserSelect";
import { useCheckZoomMembership } from "../hooks/useCheckZoomMembership";
import { usePossibleZoomHostUserMembershipIds } from "../hooks/usePossibleZoomHostUserMembershipIds";
import { useSyncHostWithInterviewerUserMembershipIds } from "../hooks/useSyncHostWithInterviewerUserMembershipIds";

type ZoomHostSelectProps = {
  hostUserMembershipId?: string | null;
  interviewerUserMembershipIds?: string[];
  onSelect: (value: { id: string } | null) => void;
  disabled?: boolean;
  portal?: boolean;
  loading?: boolean;
  disableAutoSwap?: boolean;
};

export function ZoomHostSelect(props: ZoomHostSelectProps) {
  const {
    hostUserMembershipId,
    interviewerUserMembershipIds,
    disabled = false,
    portal,
    loading: passedLoading,
  } = props;

  const {
    error,
    loading: zoomHostValidationLoading,
    onSelect,
  } = useZoomHostValidation(props);

  const { possibleUserMembershipIdsForSelection, orgZoomUsers } =
    usePossibleZoomHostUserMembershipIds({
      interviewerUserMembershipIds: interviewerUserMembershipIds ?? [],
    });
  useSyncHostWithInterviewerUserMembershipIds({
    ...props,
    interviewerUserMembershipIds: possibleUserMembershipIdsForSelection,
    // override with the onSelect with validation
    onSelect,
  });
  const onSelectUser = useCallback(
    (u: InterviewerForSelectionFragment) => {
      onSelect({ id: u.id });
    },
    [onSelect]
  );
  const filters = useMemo(
    () => ({
      userMembershipIds: possibleUserMembershipIdsForSelection,
    }),
    [possibleUserMembershipIdsForSelection]
  );

  const loading = passedLoading || zoomHostValidationLoading;

  const Trigger = useMemo(() => {
    if (!loading) {
      return undefined;
    }

    return (
      <SelectTrigger className="flex-1 bg-light-gray-500 w-full">
        <div className="flex w-full justify-center">
          <LoadingIndicator size="small" />
        </div>
      </SelectTrigger>
    );
  }, [loading]);

  return (
    <div className="space-y-2">
      <div>
        <div className="flex gap-2 items-center">
          <UsersSelect
            portal={portal}
            placeholderText="Host"
            selectedId={hostUserMembershipId}
            disabled={disabled}
            onSelect={onSelectUser}
            filters={filters}
            topUsers={orgZoomUsers}
            Trigger={Trigger}
          />
        </div>
      </div>
      <div className="text-xs text-red-500">{error}</div>
    </div>
  );
}

function useZoomHostValidation({
  onSelect: passedOnSelect,
}: ZoomHostSelectProps) {
  const { loading, checkZoomMembership } = useCheckZoomMembership();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedId) {
      setError(null);
      return;
    }

    checkZoomMembership({
      variables: { id: selectedId },
    }).then(({ data }) => {
      const userMembership = data?.userMembershipById;

      const err = userMembership?.hasZoomAccount
        ? null
        : `${userMembership?.name} ${
            userMembership?.email ? `(${data?.userMembershipById?.email}) ` : ""
          }does not have a Zoom account`;

      if (!err) {
        passedOnSelect({ id: selectedId });
      } else {
        passedOnSelect(null);
      }
      setError(err);
    });

    // Adding passedOnSelect can lead to infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkZoomMembership, selectedId]);

  const onSelect = useCallback(
    (user: { id: string } | null) => {
      if (user) {
        setSelectedId(user.id);
      } else {
        setSelectedId(null);
        passedOnSelect(null);
      }
    },
    [passedOnSelect]
  );

  return {
    error,
    loading,
    onSelect,
  };
}
