import { UserConfigurableAlgorithmSettingsSchema } from "shared/guide-scheduler/algorithm/utils/types";
import { CollaborativeCodingRequirementSchema } from "shared/guide-scheduler/collaborative-coding/types";
import { ConferenceRoomSettingsSchema } from "shared/guide-scheduler/conference-rooms/types";
import { ConferencingRequirementSchema } from "shared/guide-scheduler/conferencing/types";
import { z } from "zod";

const InterviewerRequirementTypeSchema = z.enum([
  "PERSON",
  "TAG",
  "SMART_MATCH",
]);
export type InterviewerRequirementType = z.infer<
  typeof InterviewerRequirementTypeSchema
>;
export const InterviewerRequirementType = InterviewerRequirementTypeSchema.enum;

const TagFilterTypeSchema = z.enum([
  "INCLUDE_ALL",
  "INCLUDE_ANY",
  "EXCLUDE_IF_ANY",
  "EXCLUDE_IF_ALL",
]);
export type TagFilterType = z.infer<typeof TagFilterTypeSchema>;
export const TagFilterType = TagFilterTypeSchema.enum;

const SourceTypeSchema = z.enum(["user", "sync"]);
export type SourceType = z.infer<typeof SourceTypeSchema>;
export const SourceType = SourceTypeSchema.enum;

export const TagFilterInputSchema = z.object({
  id: z.string(),
  type: TagFilterTypeSchema,
  tagIds: z.array(z.string()),
});

export type TagFilterInput = z.infer<typeof TagFilterInputSchema>;

export const UpsertInterviewerRequirementInputSchema = z.object({
  id: z.string(),
  interviewRequirementId: z.string(),
  type: InterviewerRequirementTypeSchema,
  userMembershipIds: z.array(z.string()).optional(),
  shadowingUserMembershipIds: z.array(z.string()).optional(),
  includeShadower: z.boolean().optional(),
  interviewerPoolIds: z.array(z.string()).optional(),
  reschedulingFromInterviewerRequirementId: z.string().optional(),
  source: SourceTypeSchema.optional(),
  useExternalInterviewerAlgorithm: z.boolean().optional(),
  tagFilters: z.array(TagFilterInputSchema).optional(),
});
export type UpsertInterviewerRequirementInput = z.infer<
  typeof UpsertInterviewerRequirementInputSchema
>;

export const UpsertInterviewRequirementInputSchema = z.object({
  id: z.string(),
  interviewPanelRequirementId: z.string(),
  duration: z.number(),
  interviewId: z.string(),
  interviewerRequirements: z.array(
    UpsertInterviewerRequirementInputSchema.omit({
      interviewRequirementId: true,
    })
  ),
  source: SourceTypeSchema.optional(),
  order: z.number().optional(),
  orderLocked: z.boolean(),
  hasBeenRescheduled: z.boolean().optional(),
  scheduledInterviewToRescheduleId: z.string().optional(),
  reschedulingFromInterviewRequirementId: z.string().optional(),
  videoConferencingSettings:
    ConferencingRequirementSchema.nullable().optional(),
  collaborativeCodingSettings:
    CollaborativeCodingRequirementSchema.nullable().optional(),
  conferenceRoomSettings: ConferenceRoomSettingsSchema.nullable().optional(),
  manuallyRemovedAt: z.date().nullable().optional(),
  isPrivate: z.boolean().optional(),
});
export type UpsertInterviewRequirementInput = z.infer<
  typeof UpsertInterviewRequirementInputSchema
>;

export enum InterviewPanelLocation {
  ONSITE = "ONSITE",
  VIRTUAL = "VIRTUAL",
}

export const UpsertInterviewPanelRequirementInputSchema = z.object({
  id: z.string(),
  reuseVideoConferencingLink: z.boolean().optional(),
  algorithmSettings:
    UserConfigurableAlgorithmSettingsSchema.nullable().optional(),
  location: z.nativeEnum(InterviewPanelLocation).nullable().optional(),
  interviewRequirements: z.array(
    UpsertInterviewRequirementInputSchema.omit({
      interviewPanelRequirementId: true,
    })
  ),
  source: SourceTypeSchema.optional(),
});

export type UpsertInterviewPanelRequirementInput = z.infer<
  typeof UpsertInterviewPanelRequirementInputSchema
>;
