import { atlasCircleBubble } from "@resource/atlas/icons";
import { NotificationType } from "modules/notifications/types";

import { createAppNotificationRenderer } from "./types";

export const importantAriaMessageRenderer = createAppNotificationRenderer({
  type: NotificationType.ImportantAriaMessage,
  render: ({ message }) => {
    return (
      <>
        <span className="text-body-md-heavy">{message.author.name}</span> posted
        a message about the candidate{" "}
        <span className="text-body-md-heavy">{message.candidate.name}</span>
      </>
    );
  },
  avatar: ({ message }) => {
    return {
      name: message.author.name,
      avatarUrl: message.author.avatarUrl,
      icon: atlasCircleBubble,
      iconClass: "text-subtle bg-white",
    };
  },
});
