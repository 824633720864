import { LexicalSchema } from "modules/lexical/shared/utils/types";
import { z } from "zod";

// Base schema for interviewer confirmation
export const InterviewerConfirmationBaseInputSchema = z.object({
  sendNotifications: z.boolean(),
  note: z.string().optional(),
});

export type InterviewerConfirmationBaseInput = z.infer<
  typeof InterviewerConfirmationBaseInputSchema
>;

// Base schema for upsert operations
export const InterviewerUpsertConfirmationBaseInputSchema =
  InterviewerConfirmationBaseInputSchema.extend({
    optionalAttendeeUserMembershipIds: z.array(z.string()).optional(),
    customInstructions: LexicalSchema.nullable().optional(),
    templateId: z.string().optional(),
  });

export type InterviewerUpsertConfirmationBaseInput = z.infer<
  typeof InterviewerUpsertConfirmationBaseInputSchema
>;

// Update schema
export const InterviewerConfirmationUpdateInputSchema =
  InterviewerUpsertConfirmationBaseInputSchema.extend({
    addToGoogleCalendarId: z.string().nullable().optional(),
    title: LexicalSchema.nullable().optional(),
  });

export type InterviewerConfirmationUpdateInput = z.infer<
  typeof InterviewerConfirmationUpdateInputSchema
>;

// Create schema
export const InterviewerConfirmationCreateInputSchema =
  InterviewerUpsertConfirmationBaseInputSchema.extend({
    addToGoogleCalendarId: z.string(),
    title: LexicalSchema.nullable(),
  });

export type InterviewerConfirmationCreateInput = z.infer<
  typeof InterviewerConfirmationCreateInputSchema
>;

// Cancellation schemas
export const InterviewerConfirmationCancellationInputSchema =
  InterviewerConfirmationBaseInputSchema;

export type InterviewerConfirmationCancellationInput = z.infer<
  typeof InterviewerConfirmationCancellationInputSchema
>;

export const CandidateConfirmationCancellationInputSchema =
  InterviewerConfirmationBaseInputSchema;

export type CandidateConfirmationCancellationInput = z.infer<
  typeof CandidateConfirmationCancellationInputSchema
>;
