import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";
import { SelfScheduleDateSelect } from "./SelfScheduleDateSelect";
import { SelfScheduleRequestDetails } from "./SelfScheduleRequestDetails";
import { SelfScheduleTimezoneSelect } from "./SelfScheduleTimezoneSelect";

export function SelectDateContent({
  state,
}: {
  state: SelfScheduleSubmissionState;
}) {
  return (
    <div className="flex flex-col w-full divide-y divide-gray-border">
      <div className="flex p-4">
        <SelfScheduleRequestDetails className="py-2" state={state} />
      </div>
      <div className="flex flex-col space-y-4 py-4 px-2">
        <div className="text-h4 mx-2">Select date</div>
        <div className="flex justify-center w-full">
          <div className="w-full max-w-[32rem] space-y-6 mx-2">
            <SelfScheduleDateSelect state={state} />
            <SelfScheduleTimezoneSelect state={state} />
          </div>
        </div>
      </div>
    </div>
  );
}
