import { useStoreState } from "@ariakit/react";
import { Popover } from "@resource/atlas/popover-v2";
import { useCallback, useEffect, useMemo } from "react";

import { useCalendarData } from "../../hooks/data";
import { useCalendarOptions } from "../../hooks/options";
import {
  useEventDetailsPopoverStore,
  useFocusedEventInfo,
  useSetFocusedEventInfo,
} from "../../hooks/refs";
import { useCalendarContext } from "../../utils/context";
import { EventDetailsPopoverDisplay } from "./EventDetailsPopoverDisplay";

export function EventDetailsPopover() {
  const { events, allDayEvents } = useCalendarData();
  const { eventDetailsPopoverTriggerElement } = useCalendarContext();
  const focusedEventInfo = useFocusedEventInfo();
  const setFocusedEventInfo = useSetFocusedEventInfo();
  const eventDetailsPopoverStore = useEventDetailsPopoverStore();
  const eventDetailsPopoverState = useStoreState(eventDetailsPopoverStore);
  const { getEventDetailsFooter } = useCalendarOptions();
  const getAnchorRect = useCallback(() => {
    if (!eventDetailsPopoverTriggerElement) {
      return null;
    }

    return eventDetailsPopoverTriggerElement.getBoundingClientRect();
  }, [eventDetailsPopoverTriggerElement]);

  const event = useMemo(() => {
    if (!focusedEventInfo) return null;
    return (
      events.find(
        (e) =>
          e.id === focusedEventInfo.id &&
          e.calendarId === focusedEventInfo.calendarId
      ) ||
      allDayEvents.find(
        (e) =>
          e.id === focusedEventInfo.id &&
          e.calendarId === focusedEventInfo.calendarId
      )
    );
  }, [events, allDayEvents, focusedEventInfo]);

  useEffect(() => {
    if (!eventDetailsPopoverState.open) {
      setFocusedEventInfo(null);
    }
  }, [eventDetailsPopoverState.open, setFocusedEventInfo]);

  if (!event) {
    return null;
  }

  return (
    <Popover.Content
      portal
      getAnchorRect={getAnchorRect}
      autoFocusOnShow={false}
    >
      <EventDetailsPopoverDisplay
        event={event}
        onClose={eventDetailsPopoverStore.hide}
        getEventDetailsFooter={getEventDetailsFooter}
      />
    </Popover.Content>
  );
}
