import clsx from "clsx";

export type ViewHeaderProps = {
  title: string;
  subtitle?: string;
  Subcomponent?: React.ReactNode;
  className?: string;
};

export function ViewHeader({
  title,
  subtitle,
  className,
  Subcomponent,
}: ViewHeaderProps) {
  return (
    <div className={clsx(className, "space-y-2 mb-6")}>
      <h2 className="text-h2">{title}</h2>
      <span className="text-body-lg">{subtitle}</span>
      {Subcomponent}
    </div>
  );
}
