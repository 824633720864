import { z } from "zod";

const UserValidationMessages = {
  requiredInterviewersNoShow:
    "Please include at least one interviewer that did not show up.",
  requiredInterviewersDecline:
    "Please include at least one interviewer who declined.",
  requiredOtherReason: "Please include a reason for selecting other.",
  requiredRescheduleReason: "Please include a reason for rescheduling.",
  requiredSwapReason: "Please include a reason for swapping interviewers.",
  requiredCancellationReason: "Please include a reason for canceling.",
  requiredRescheduledNeeded: "Please indicate if rescheduling is needed.",
  updateReasonInvalid:
    "Must pass either rescheduleReason or interviewerSwapReason.",
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Validate data against schema and add an error message if it fails
 * Used to ensure that specific data is valid based on enum choice
 */
function validateDataForSchema({
  data,
  ctx,
  schema,
  path,
}: {
  data: any;
  ctx: z.RefinementCtx;
  schema: z.ZodObject<any>;
  path: string[];
}) {
  const validation = schema.safeParse(data);

  if (!validation.success) {
    const message = validation.error.issues[0]?.message;
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message,
      path,
    });
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/** Reasons for selecting OTHER  */
export enum ScheduledInterviewOtherReason {
  CANDIDATE_REQUESTED = "CANDIDATE_REQUESTED",
  COMPANY_INITIATED = "COMPANY_INITIATED",
}

/** Reasons for rescheduling */
export enum ScheduledInterviewRescheduleReason {
  CANDIDATE_NO_SHOWED = "CANDIDATE_NO_SHOWED",
  CANDIDATE_REQUESTED_RESCHEDULE = "CANDIDATE_REQUESTED_RESCHEDULE",
  INTERVIEWER_NO_SHOWED = "INTERVIEWER_NO_SHOWED",
  INTERVIEWER_DECLINED = "INTERVIEWER_DECLINED",
  OTHER = "OTHER",
}

/** A record in the array of interviewers who didn't show */
export const InterviewerNoShowItemSchema = z.object({
  interviewerUserMembershipId: z.string(),
});

export type InterviewerNoShowItem = z.infer<typeof InterviewerNoShowItemSchema>;

/** Array of interviewers who didn't show */
export const InterviewerNoShowSchema = z.array(InterviewerNoShowItemSchema, {
  required_error: UserValidationMessages.requiredInterviewersNoShow,
});

export type InterviewerNoShow = z.infer<typeof InterviewerNoShowSchema>;

/** A record in the array of interviewers who declined */
export const InterviewerDeclineItemSchema = z.object({
  interviewerUserMembershipId: z.string(),
  declineReason: z.string().optional().nullable(),
});

export type InterviewerDeclineItem = z.infer<
  typeof InterviewerDeclineItemSchema
>;

/** Array of interviewers who declined */
export const InterviewerDeclineSchema = z.array(InterviewerDeclineItemSchema, {
  required_error: UserValidationMessages.requiredInterviewersDecline,
});

export type InterviewerDecline = z.infer<typeof InterviewerDeclineSchema>;

/** Schema for validation when the selected reason is INTERVIEWER_NO_SHOWED */
export const InterviewerNoShowRescheduleReasonSchema = z.object({
  reason: z.literal("INTERVIEWER_NO_SHOWED"),
  interviewerNoShows: InterviewerNoShowSchema.min(
    1,
    UserValidationMessages.requiredInterviewersNoShow
  ),
});

/** Schema for validation when the selected reason is INTERVIEWER_DECLINED */
export const InterviewerDeclinedRescheduleReasonSchema = z.object({
  reason: z.literal("INTERVIEWER_DECLINED"),
  interviewerDeclines: InterviewerDeclineSchema.min(
    1,
    UserValidationMessages.requiredInterviewersDecline
  ),
});

/** Schema for validation when the selected reason is OTHER */
export const OtherRescheduleReasonSchema = z.object({
  reason: z.literal("OTHER"),
  otherReason: z.nativeEnum(ScheduledInterviewOtherReason, {
    required_error: UserValidationMessages.requiredOtherReason,
  }),
  otherReasonText: z.string().optional().nullable(),
});

/** General schema for rescheduling reasons, with refinement to ensure specific choices matches their schema */
export const RescheduleReasonSchema = z
  .object(
    {
      reason: z.nativeEnum(ScheduledInterviewRescheduleReason, {
        required_error: UserValidationMessages.requiredRescheduleReason,
      }),
      interviewerNoShows: InterviewerNoShowSchema.optional(),
      interviewerDeclines: InterviewerDeclineSchema.optional(),
      otherReason: z
        .nativeEnum(ScheduledInterviewOtherReason, {
          required_error: UserValidationMessages.requiredOtherReason,
        })
        .optional()
        .nullable(),
      additionalText: z.string().optional().nullable(),
    },
    {
      required_error: UserValidationMessages.requiredRescheduleReason,
    }
  )
  .superRefine((data, ctx) => {
    if (
      data.reason === ScheduledInterviewRescheduleReason.INTERVIEWER_NO_SHOWED
    ) {
      validateDataForSchema({
        data,
        ctx,
        schema: InterviewerNoShowRescheduleReasonSchema,
        path: ["interviewerNoShows"],
      });
    }

    if (
      data.reason === ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED
    ) {
      validateDataForSchema({
        data,
        ctx,
        schema: InterviewerDeclinedRescheduleReasonSchema,
        path: ["interviewerDeclines"],
      });
    }

    if (data.reason === ScheduledInterviewRescheduleReason.OTHER) {
      validateDataForSchema({
        data,
        ctx,
        schema: OtherRescheduleReasonSchema,
        path: ["otherReason", "otherReasonText"],
      });
    }

    return data;
  });

export type RescheduleReason = z.infer<typeof RescheduleReasonSchema>;

/** Reasons for swapping an interviewer */
export enum ScheduledInterviewInterviewerSwapReason {
  INTERVIEWER_DECLINED = "INTERVIEWER_DECLINED",
  OTHER = "OTHER",
}

/** General schema for interviewer swap reasons, with refinement to ensure specific choices matches their schema */
export const InterviewerSwapReasonSchema = z
  .object({
    reason: z.nativeEnum(ScheduledInterviewInterviewerSwapReason, {
      required_error: UserValidationMessages.requiredSwapReason,
    }),
    interviewerDeclines: InterviewerDeclineSchema.optional(),
    otherReason: z
      .nativeEnum(ScheduledInterviewOtherReason, {
        required_error: UserValidationMessages.requiredOtherReason,
      })
      .optional()
      .nullable(),
    additionalText: z.string().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (
      data.reason ===
      ScheduledInterviewInterviewerSwapReason.INTERVIEWER_DECLINED
    ) {
      validateDataForSchema({
        data,
        ctx,
        schema: InterviewerDeclinedRescheduleReasonSchema,
        path: ["interviewerDeclines"],
      });
    }

    if (data.reason === ScheduledInterviewInterviewerSwapReason.OTHER) {
      validateDataForSchema({
        data,
        ctx,
        schema: OtherRescheduleReasonSchema,
        path: ["otherReason", "otherReasonText"],
      });
    }

    return true;
  });

export type ReportInterviewerSwapReason = z.infer<
  typeof InterviewerSwapReasonSchema
>;

/** Schema for cancellation when needsReschedule is true */
export const CancellationReasonNeedsRescheduleSchema = z.object({
  needsRescheduled: z.literal(true),
  rescheduleData: RescheduleReasonSchema,
});

/** Reasons for cancelling and interview and not rescheduling */
export enum ScheduledInterviewCancellationReason {
  CANDIDATE_WITHDREW = "CANDIDATE_WITHDREW",
  CANDIDATE_PAUSED = "CANDIDATE_PAUSED",
  REJECTING = "REJECTING",
  MISTAKE = "MISTAKE",
  OTHER = "OTHER",
}

/** General schema for cancellation reasons when not rescheduling, with refinement to ensure specific choices match their schema */
export const CancellationReasonNoRescheduleDataSchema = z
  .object(
    {
      reason: z.nativeEnum(ScheduledInterviewCancellationReason, {
        required_error: UserValidationMessages.requiredCancellationReason,
      }),
      otherReason: z
        .nativeEnum(ScheduledInterviewOtherReason, {
          required_error: UserValidationMessages.requiredOtherReason,
        })
        .optional()
        .nullable(),
      additionalText: z.string().optional().nullable(),
    },
    {
      required_error: UserValidationMessages.requiredCancellationReason,
    }
  )
  .superRefine((data, ctx) => {
    if (data.reason === ScheduledInterviewCancellationReason.OTHER) {
      validateDataForSchema({
        data,
        ctx,
        schema: OtherRescheduleReasonSchema,
        path: ["otherReason", "otherReasonText"],
      });
    }

    return true;
  });

export type CancellationReasonNoRescheduleData = z.infer<
  typeof CancellationReasonNoRescheduleDataSchema
>;

/** Schema for cancellation when needsRescheduled is ffalse */
export const CancellationReasonNoRescheduleSchema = z.object({
  needsRescheduled: z.literal(false),
  cancellationData: CancellationReasonNoRescheduleDataSchema,
});

/** General schema for cancellation, using a discriminated union to choose the correct schema based on needsReschedule */
export const CancellationReasonSchema = z.discriminatedUnion(
  "needsRescheduled",
  [
    CancellationReasonNeedsRescheduleSchema,
    CancellationReasonNoRescheduleSchema,
  ],
  {
    errorMap: () => {
      return {
        message: UserValidationMessages.requiredRescheduledNeeded,
      };
    },
  }
);

export type CancellationReason = z.infer<typeof CancellationReasonSchema>;
export type InterviewerSwapReason = z.infer<typeof InterviewerSwapReasonSchema>;

// GraphQL doesn't support union input types
// Rather than trying to force it, just use a Zod schema for validating the input
export const UpdateReasonSchema = z
  .object({
    rescheduleReason: RescheduleReasonSchema.optional().nullable(),
    interviewerSwapReason: InterviewerSwapReasonSchema.optional().nullable(),
  })
  .refine(
    (data) => {
      // Must pass one of the two reasons
      if (!data.rescheduleReason && !data.interviewerSwapReason) {
        return false;
      }

      // Cannot pass both
      if (data.rescheduleReason && data.interviewerSwapReason) {
        return false;
      }

      return true;
    },
    {
      message: UserValidationMessages.updateReasonInvalid,
    }
  );

export type UpdateReason = z.infer<typeof UpdateReasonSchema>;
