import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_TARGETS } from "@resource/atlas/content-editor/__utils/insert-options-targets-order";
import { createRichBlock } from "@resource/atlas/content-editor/__utils/rich-blocks";
import { atlasCalendarEvents } from "@resource/atlas/icons";
import { useMemo } from "react";
import {
  DEFAULT_DATA,
  InterviewsData,
  InterviewsName,
} from "shared/guide-content/rich-blocks/interviews";

import { useInterviewsConfig } from "./config";

export const {
  $createInterviewsNode,
  $isInterviewsNode,
  INSERT_INTERVIEWS_COMMAND,
  InterviewsNode,
  InterviewsPlugin,
  interviewsModule,
} = createRichBlock<InterviewsName, InterviewsData>({
  name: "interviews",
  defaultData: DEFAULT_DATA,
  // dynamically import client component because it uses gql
  RenderComponent: async () => {
    const { InterviewsComponent } = await import("./component");
    return InterviewsComponent;
  },
  useInsertOption(_, { INSERT_COMMAND }) {
    const [editor] = useLexicalComposerContext();
    const config = useInterviewsConfig();

    return useMemo(
      () => ({
        icon: atlasCalendarEvents,
        label: "Interview schedule",
        targets: {
          insert: INSERT_TARGETS.INTERVIEW_SCHEDULE,
          fixedToolbar: { slot: "interviews" },
        },
        onTrigger: () => {
          return editor.dispatchCommand(INSERT_COMMAND, {
            ...DEFAULT_DATA,
            requesterTimezone:
              config?.timezone ?? DEFAULT_DATA.requesterTimezone,
          });
        },
      }),
      [editor, INSERT_COMMAND, config]
    );
  },
});
