import { RangeSelection } from "lexical";

// utils
// -----

export function getTextUpToAnchor(
  selection: RangeSelection
): string | undefined {
  const { anchor } = selection;
  if (anchor.type !== "text") return undefined;

  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) return undefined;

  const anchorOffset = anchor.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
}

// trigger
// -------

const VALID_BEFORE_TRIGGER = [
  "^", // at start
  "\\s", // whitespace
  "\\(", // open parenthesis
];
export const BEFORE_TRIGGER_REGEXP = VALID_BEFORE_TRIGGER.join("|");
