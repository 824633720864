import { ValueSet } from "@resource/atlas/content-editor/variables";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasDescription, serviceCalendar } from "@resource/atlas/icons";
import { useTimezone } from "client/timezones/useTimezone";
import {
  renderInterviewInviteContent,
  RenderInterviewInviteContentData,
} from "notifications/emails/rendering/renderers/interview-invite-content";
import { LEXICAL_EMPTY_STATE } from "shared/constants/lexical";
import { ScheduledInterviewDetailsVariableSet } from "shared/guide-scheduler/scheduled-interview-details/variables";
import { contentEditorIsEmpty } from "utils/validation";

import { ScheduledInterviewDetailsLiteRenderer } from "../../scheduled-interview-editor/ScheduledInterviewDetailsLiteRenderer";
import { CustomInstructionsRenderer } from "./CustomInstructionsRenderer";

export function GoogleEventView({
  data: passedData,
  valueSet,
}: {
  data: RenderInterviewInviteContentData;
  valueSet: ValueSet<ScheduledInterviewDetailsVariableSet>;
}) {
  // Custom instructions will get manually rendered
  const { customInstructions, ...data } = passedData;
  const timezone = useTimezone();
  const renderedContent = renderInterviewInviteContent({
    data,
    recipient: "interviewer",
    variant: "calendar",
    timezone,
  });

  return (
    <div className="space-y-3">
      <div className="flex items-start space-x-3">
        <Icon content={serviceCalendar} className="w-5 h-5 flex-shrink-0" />
        <ScheduledInterviewDetailsLiteRenderer
          content={
            data.interviewerConfirmationTitle
              ? JSON.parse(data.interviewerConfirmationTitle)
              : LEXICAL_EMPTY_STATE
          }
          valueSet={valueSet}
          className="text-body-md-heavy"
        />
      </div>
      <div className="flex items-start space-x-3">
        <Icon
          content={atlasDescription}
          className="w-5 h-5 flex-shrink-0 text-gray-500"
        />
        <div>
          {customInstructions &&
            !contentEditorIsEmpty(JSON.parse(customInstructions)) && (
              <div className="mb-3">
                <span className="text-body-md-heavy">Custom instructions</span>
                <CustomInstructionsRenderer
                  content={
                    data.interviewerConfirmationTitle
                      ? JSON.parse(customInstructions)
                      : LEXICAL_EMPTY_STATE
                  }
                  valueSet={valueSet}
                />
              </div>
            )}
          <div className="w-full max-w-[350px] overflow-hidden break-words">
            {renderedContent.html}
          </div>
        </div>
      </div>
    </div>
  );
}
