import { z } from "zod";

import { createNotificationTypeDataSchema } from "./types";

export const importantAriaMessageDataSchema = createNotificationTypeDataSchema({
  metadata: z.object({
    chatMessageId: z.string(),
    schedulingRequestId: z.string().nullable().optional(),
    guideId: z.string(),
  }),
  resolved: z.object({
    message: z.object({
      id: z.string(),
      candidate: z.object({
        id: z.string(),
        name: z.string(),
      }),
      content: z.unknown(),
      author: z.object({
        id: z.string(),
        name: z.string(),
        avatarUrl: z.string().nullable(),
      }),
    }),
  }),
});

export type ImportantAriaMessageMeta = z.infer<
  typeof importantAriaMessageDataSchema.metadata
>;
export type ImportantAriaMessageResolved = z.infer<
  typeof importantAriaMessageDataSchema.resolved
>;
