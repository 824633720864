import { withConfig } from "@resource/atlas/content-editor/__utils/modules";
import { ContentEditorLite } from "@resource/atlas/content-editor/lite-editor";
import type { AtlasContentEditorProps } from "@resource/atlas/content-editor/types";
import {
  ValueSet,
  variablesModule,
} from "@resource/atlas/content-editor/variables";
import { unknownVariablesModule } from "@resource/atlas/content-editor/variables/__lib/module";
import { TimezoneProvider } from "client/timezones/TimezoneProvider";
import { useTimezone } from "client/timezones/useTimezone";
import ErrorBoundary from "components/ErrorBoundary";
import {
  guideUpdateSpecSet,
  GuideUpdateVariableSet,
} from "shared/guide-content/update";

export type GuideUpdateSubjectEditorProps = AtlasContentEditorProps & {
  valueSet: ValueSet<GuideUpdateVariableSet>;
  /** @default "placeholder" */
  undefinedMode?: "placeholder" | "error";
  candidateTimezone: string | null;
};

function GuideUpdateSubjectEditorWithRequiredContext({
  valueSet,
  undefinedMode,
  modules = [],
  ...props
}: GuideUpdateSubjectEditorProps) {
  const timezone = useTimezone();

  return (
    <ErrorBoundary>
      <ContentEditorLite
        {...props}
        aria-label="Subject"
        placeholder="Add a subject"
        modules={[
          withConfig(variablesModule, {
            specSet: guideUpdateSpecSet,
            valueSet,
            undefinedMode,
            timezone: timezone ?? undefined,
          }),
          unknownVariablesModule,
          ...modules,
        ]}
      />
    </ErrorBoundary>
  );
}

export function GuideUpdateSubjectEditor(props: GuideUpdateSubjectEditorProps) {
  const { candidateTimezone } = props;

  return (
    <TimezoneProvider timezone={candidateTimezone}>
      <GuideUpdateSubjectEditorWithRequiredContext {...props} />
    </TimezoneProvider>
  );
}
