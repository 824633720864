import { CollaborativeCodingServiceType } from "shared/guide-scheduler/collaborative-coding/types";
import { COLLABORATIVE_CODING_LABELS } from "shared/guide-scheduler/collaborative-coding/utils";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";
import { CONFERENCING_LABELS } from "shared/guide-scheduler/conferencing/utils";

/**
 * Generically display a label for a service, could be either conferencing or collaborative coding
 * Will re-use the existing labels if exists otherwise fallback to sensible default
 */
export const getDisplayTextFromService = (service: string) => {
  const potentialConferencingLabel =
    CONFERENCING_LABELS[service as ConferencingType];

  if (potentialConferencingLabel) {
    return potentialConferencingLabel;
  }

  const potentialCollaborativeCodingLabel =
    COLLABORATIVE_CODING_LABELS[service as CollaborativeCodingServiceType];

  if (potentialCollaborativeCodingLabel) {
    return potentialCollaborativeCodingLabel;
  }

  const defaultServiceDisplay = `${service.slice(0, 1).toUpperCase()}${service
    .slice(1)
    .toLowerCase()}`;

  return defaultServiceDisplay;
};
