import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarTimesReceived,
  atlasClock,
  atlasClose,
  atlasEdit,
  atlasEdit2,
  atlasRingCheck,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { strings } from "@resource/common";
import { useLogEvent } from "analytics";
import { BlockError } from "client/components/generic/errors/BlockError";
import { Timestamp } from "client/components/generic/misc/Timestamp";
import clsx from "clsx";
import { Skeleton } from "components/Skeleton";
import { gql } from "generated/graphql-codegen";
import { AvailabilityRequestForManageAvailabilityRequestFragment } from "generated/graphql-codegen/graphql";
import { useCallback } from "react";
import { EventInput } from "shared/guide-availability/types";

import { ArchiveAvailabilityRequestDialog } from "./ArchiveAvailabilityRequestDialog";
import { CompleteAvailabilityRequestDialog } from "./CompleteAvailabilityRequestDialog";
import { FollowUpReminderDialog } from "./FollowUpReminderDialog";

gql(`
fragment AvailabilityRequestForManageAvailabilityRequest on GuideAvailabilityRequest {
  id
  ...GuideAvailabilityRequestForFollowUpReminder
  status
  suggestions {
    id
    startTime
    endTime
  }
  guidePost {
    id
    senderUser {
      id
      name
      imageUrl
    }
    recipientProfiles {
      id
      name
      email
      firstName
      lastName
      imageUrl
      isCandidate
    }
    removedRecipients
    createdAt
    href
  }
  completedBySubmission {
    id
    createdAt
    createdBy {
      id
      name
      imageUrl
    }
  }
}
`);

type ManageAvailabilityRequestProps = {
  className?: string;
  // If no guideId, we're in template mode
  guideId?: string;
  senderSuggestions?: EventInput[];
  notes?: string;
  availabilityRequest?: AvailabilityRequestForManageAvailabilityRequestFragment;
  onEdit?(): void;
  disabledTooltip?: string;
  onAddManually?({
    availabilityRequestId,
  }: {
    availabilityRequestId: string;
  }): void;
  loading?: boolean;
  error?: boolean;
  /** Prevent recursion of follow up reminder dialogs */
  isFollowUpReminder?: boolean;
};

export function ManageAvailabilityRequest({
  className,
  onEdit,
  senderSuggestions,
  notes,
  availabilityRequest,
  onAddManually,
  guideId,
  disabledTooltip,
  loading,
  error,
  isFollowUpReminder = false,
}: ManageAvailabilityRequestProps) {
  const followupReminderDialogStore = useDialogStore();
  const completeDialogStore = useDialogStore();
  const archiveDialogStore = useDialogStore();
  const isTemplateMode = !guideId;
  const onFollowUpReminder = useCallback(() => {
    if (!isFollowUpReminder) {
      followupReminderDialogStore.show();
    }
  }, [isFollowUpReminder, followupReminderDialogStore]);

  const logEvent = useLogEvent({
    component: "ManageAvailabilityRequest",
  });

  const isDraft = !availabilityRequest;
  const guidePost = availabilityRequest?.guidePost;

  const items = useMenuItems(
    (i) => {
      if (!availabilityRequest) {
        return [];
      }

      return [
        ...(guidePost
          ? [
              i.item({
                key: "view-request",
                children: "View request",
                leadingContent: <Icon content={atlasCalendarTimesReceived} />,
                onClick: () => {
                  logEvent("View Request Clicked");
                  window.open(guidePost.href, "_blank");
                },
              }),
              !isFollowUpReminder &&
                i.item({
                  key: "send-follow-up",
                  children: "Send follow-up reminder",
                  leadingContent: <Icon content={atlasClock} />,
                  onClick: () => {
                    logEvent("Send Follow-up Reminder Clicked");
                    onFollowUpReminder();
                  },
                }),
            ]
          : []),
        onEdit &&
          i.item({
            key: "edit-request",
            children: "Edit request",
            leadingContent: <Icon content={atlasEdit2} />,
            onClick: () => {
              logEvent("Edit Availability Request Clicked");
              onEdit();
            },
          }),
        onAddManually &&
          i.separator({
            key: "separator-0",
          }),
        onAddManually &&
          i.item({
            key: "add-manually",
            children: "Add manually",
            leadingContent: <Icon content={atlasEdit} />,
            onClick: () => {
              logEvent("Add Manually Clicked");
              onAddManually({ availabilityRequestId: availabilityRequest.id });
            },
          }),

        i.item({
          key: "mark-completed",
          children: "Mark as complete",
          leadingContent: <Icon content={atlasRingCheck} />,
          onClick: () => {
            logEvent("Mark Request Complete Clicked");
            completeDialogStore.show();
          },
        }),
        i.item({
          key: "cancel-request",
          children: "Cancel request",
          leadingContent: <Icon content={atlasClose} />,
          onClick: () => {
            logEvent("Cancel Request Clicked");
            archiveDialogStore.show();
          },
        }),
      ];
    },
    [
      availabilityRequest,
      onEdit,
      logEvent,
      onAddManually,
      guidePost,
      onFollowUpReminder,
      isFollowUpReminder,
      completeDialogStore,
      archiveDialogStore,
    ]
  );

  const hasSuggestions = (senderSuggestions?.length ?? 0) > 0;

  if (loading) {
    return (
      <div className="w-full shadow-1 rounded-md p-3 gap-2 flex items-center">
        <Skeleton type="circle" className="w-10 h-10" />
        <div className="grow space-y-2 flex flex-col">
          <Skeleton className="w-4/5 h-[14px]" type="text" />
          <Skeleton className="w-4/5 h-[14px]" type="text" />
        </div>
        <Skeleton type="rect" className="h-6 w-6" />
      </div>
    );
  }

  if (error) {
    return <BlockError label="Can't load availability request" />;
  }

  return (
    <>
      <Tooltip content={disabledTooltip} className="flex w-full">
        <div
          className={clsx(
            "flex gap-2 w-full shadow-1 rounded-md border-light-gray p-3 items-center",
            !!disabledTooltip && "opacity-70",
            className
          )}
        >
          <div className="w-10 h-10 bg-blue-50 flex items-center justify-center rounded-full shrink-0">
            <Icon
              content={atlasCalendarTimesReceived}
              className="w-5 h-5 text-blue-500"
            />
          </div>
          <div className="grow">
            <p className="text-body-md-heavy text-dark">
              Request for availability
            </p>
            {isDraft ? (
              <div className="text-body-sm">
                <div className="flex gap-2 items-center">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    as="button"
                    onClick={onEdit}
                    disabled={isTemplateMode || !!disabledTooltip}
                  >
                    {notes || hasSuggestions ? "Edit" : "Add"}{" "}
                    {hasSuggestions
                      ? strings.pluralize(
                          "suggested times",
                          senderSuggestions!.length
                        )
                      : "suggested times"}{" "}
                    and notes
                  </Link>
                </div>
              </div>
            ) : (
              <div className="text-body-sm">
                {guidePost && (
                  <p className="flex gap-1">
                    <Link
                      href={guidePost.href}
                      className="guide-post-href truncate"
                      target="_blank"
                      variant="monochrome"
                    >
                      Sent by {guidePost.senderUser.name}
                    </Link>{" "}
                    <Timestamp
                      className="text-subtle text-body-sm shrink-0"
                      timestamp={guidePost?.createdAt}
                      format="short"
                    />
                  </p>
                )}
              </div>
            )}
          </div>
          {!isDraft ? (
            <Menu.Root>
              <Menu.Trigger>
                <Button
                  variant="default"
                  isDropdown
                  icon={atlasClock}
                  size="small"
                  disabled={!!disabledTooltip}
                >
                  Waiting
                </Button>
              </Menu.Trigger>
              <Menu.Content defaultSize="compact" items={items} />
            </Menu.Root>
          ) : (
            <Button
              variant="default"
              onClick={onEdit}
              disabled={isTemplateMode || !!disabledTooltip}
              icon={atlasEdit2}
              size="small"
            />
          )}
        </div>
      </Tooltip>
      {!isFollowUpReminder && (
        <>
          <FollowUpReminderDialog
            store={followupReminderDialogStore}
            availabilityRequest={availabilityRequest}
          />
          {availabilityRequest && (
            <>
              <CompleteAvailabilityRequestDialog
                store={completeDialogStore}
                availabilityRequestId={availabilityRequest.id}
              />
              <ArchiveAvailabilityRequestDialog
                store={archiveDialogStore}
                availabilityRequestId={availabilityRequest.id}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
