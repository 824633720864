/**
 * A select component for choosing a video conferencing service (e.g. Google Meet, Zoom etc).
 */

import { Icon } from "@resource/atlas/icon/Icon";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { useAuthContext } from "auth/context";
import { VideoConferencingServiceType } from "generated/graphql-codegen/graphql";
import { forwardRef, ReactNode, useMemo } from "react";
import {
  VIDEO_CONFERENCING_ICONS,
  VIDEO_CONFERENCING_LABELS,
} from "shared/utils/video-conferencing";

export type VideoConferencingSelectProps = {
  value?: VideoConferencingServiceType | null;
  setValue: (value: VideoConferencingServiceType) => void;
  allowNoSelection?: boolean;
};

export type VideoConferenceSelectTriggerProps = AtlasSelectTriggerProps & {
  label: ReactNode;
};
const VideoConferenceTrigger = forwardRef<
  HTMLButtonElement,
  VideoConferenceSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref} className="bg-light-gray-500">
      {props.label}
    </SelectTrigger>
  );
});

/**
 * Video conferencing select for the V1 scheduler and Scheduling Task workflows;
 * @deprecated Use ConferencingSettingSelect instead.
 */
export function VideoConferencingSelect({
  value,
  setValue,
  allowNoSelection,
}: VideoConferencingSelectProps) {
  const { user } = useAuthContext();
  const zoomEnabled = user?.currentOrganization?.isZoomEnabled ?? false;
  const googleCalendarEnabled =
    user?.currentOrganization?.isGoogleCalendarEnabled ?? false;

  const videoConferenceSelectItems = useSelectItems(
    (i) => {
      return [
        ...(allowNoSelection
          ? [
              i.option({
                key: "other",
                value: VideoConferencingServiceType.OTHER,
                children:
                  VIDEO_CONFERENCING_LABELS[VideoConferencingServiceType.OTHER],
              }),
            ]
          : []),
        zoomEnabled &&
          i.option({
            key: "zoom",
            value: VideoConferencingServiceType.ZOOM,
            renderContent: ({ children }) => (
              <div className="flex items-center gap-2">
                <Icon
                  content={
                    VIDEO_CONFERENCING_ICONS[
                      VideoConferencingServiceType.ZOOM
                    ] ?? ""
                  }
                />
                <span>{children}</span>
              </div>
            ),
            children:
              VIDEO_CONFERENCING_LABELS[VideoConferencingServiceType.ZOOM],
          }),
        googleCalendarEnabled &&
          i.option({
            key: "google-meet",
            value: VideoConferencingServiceType.GOOGLE_MEET,
            renderContent: ({ children }) => (
              <div className="flex items-center gap-2">
                <Icon
                  content={
                    VIDEO_CONFERENCING_ICONS[
                      VideoConferencingServiceType.GOOGLE_MEET
                    ] ?? ""
                  }
                />
                <span>{children}</span>
              </div>
            ),
            children:
              VIDEO_CONFERENCING_LABELS[
                VideoConferencingServiceType.GOOGLE_MEET
              ],
          }),
      ];
    },
    [allowNoSelection, googleCalendarEnabled, zoomEnabled]
  );

  const icon = useMemo(() => {
    const iconStr =
      value && VIDEO_CONFERENCING_ICONS[value]
        ? VIDEO_CONFERENCING_ICONS[value]
        : null;
    if (!iconStr) {
      return null;
    }

    return <Icon content={iconStr} />;
  }, [value]);

  return (
    <Select.Root
      sameWidth
      value={value ?? undefined}
      setValue={(v) => setValue(v as unknown as VideoConferencingServiceType)}
    >
      <Select.Trigger>
        <VideoConferenceTrigger
          label={
            <div className="flex items-center gap-2 w-full">
              {icon}
              <span>
                {value ? VIDEO_CONFERENCING_LABELS[value] : "No selection"}
              </span>
            </div>
          }
        />
      </Select.Trigger>
      <Select.Content items={videoConferenceSelectItems} />
    </Select.Root>
  );
}
