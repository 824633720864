import { Button } from "@resource/atlas/button/Button";
import { AtlasButtonProps } from "@resource/atlas/button/types";
import { serviceGreenhouse } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { useForceResyncJob } from "./hooks/useForceResyncJob";

export function ResyncJobWithGreenhouseButton({
  jobId,
  label = "Resync from Greenhouse",
  ...props
}: { jobId: string; label?: string } & AtlasButtonProps) {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [resyncJobWithGreenhouse] = useForceResyncJob({
    onCompleted: () => {
      setTimeout(() => {
        setLoading(false);
        router.reload();
      }, 3000);
    },
  });

  const onClick = useCallback(async () => {
    setLoading(true);

    await resyncJobWithGreenhouse({
      variables: {
        input: {
          jobId,
        },
      },
    });
  }, [jobId, resyncJobWithGreenhouse]);

  return (
    <Tooltip content="Resync the latest interviews and interview names from Greenhouse">
      <Button
        icon={serviceGreenhouse}
        onClick={onClick}
        isLoading={loading}
        isGhost
        {...props}
      >
        {label}
      </Button>
    </Tooltip>
  );
}
