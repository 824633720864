import { DateTime, MonthNumbers } from "luxon";

import { DayWithMetadata } from "./types";

export const generateCalendarGridDays = ({
  year,
  month,
}: {
  year: number;
  month: MonthNumbers;
}): DayWithMetadata[] => {
  const now = DateTime.local();

  // Offset week by 1 to start on Sunday
  let currentGridDate = DateTime.local(year, month)
    .startOf("week")
    .minus({ day: 1 });
  const endMonth = DateTime.local(year, month).endOf("month");
  const endMonthOffset = 6 - (endMonth.weekday % 7);
  const endMonthGrid = endMonth.plus({
    days: endMonthOffset === 0 ? 7 : endMonthOffset,
  });

  const days = [];

  while (currentGridDate <= endMonthGrid) {
    const isCurrentMonth = currentGridDate.month === month;
    const isInPast = currentGridDate < now.startOf("day");
    const isToday = currentGridDate.hasSame(now, "day");

    days.push({
      date: currentGridDate.toISODate(),
      isCurrentMonth,
      isInPast,
      isToday,
    });

    currentGridDate = currentGridDate.plus({ days: 1 });
  }

  return days;
};
