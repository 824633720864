import { Popover } from "@resource/atlas/popover-v2";
import React from "react";

import { useCalendarSettingsFormState } from "./__hooks/useCalendarSettingsFormState";
import { CalendarSettingsForm } from "./CalendarSettingsForm";

export function CalendarSettingsPopover({
  children,
}: {
  children: React.ReactNode;
}) {
  const state = useCalendarSettingsFormState();

  return (
    <Popover.Root>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content>
        <CalendarSettingsForm state={state} />
      </Popover.Content>
    </Popover.Root>
  );
}
