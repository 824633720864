import { useStoreState } from "@ariakit/react";
import { EVENT_TARGET_CLASS_NAME } from "client/components/calendar-v2/components/events/Event";
import { FLOATING_TOOLBAR_ID } from "client/components/calendar-v2/components/public/FloatingToolbar";
import {
  useCalculateOffsetFromEvent,
  useCalculateTimeAndColumnForOffset,
} from "client/components/calendar-v2/hooks/callbacks";
import {
  useEventDetailsPopoverStore,
  useEventsViewRef,
} from "client/components/calendar-v2/hooks/refs";
import { useCalendarSizes } from "client/components/calendar-v2/hooks/sizing";
import { useColumns } from "client/components/calendar-v2/hooks/useColumns";
import { useGetGroupingAndCalendarIdForColumnIndex } from "client/components/calendar-v2/utils/utils";
import { useCalendarsGrouping } from "client/scheduler/core/calendar/hooks/useCalendarsGrouping";
import { useSchedulerInterviewsDispatch } from "client/scheduler/core/dispatch";
import {
  useInterviews,
  useSelectedInterview,
  useSetSelectedInterviewId,
} from "client/scheduler/core/hooks/interviews";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

import { usePlacingInterview } from "./usePlacingInterview";
import {
  useSequentiallyPlacedInterviews,
  useSequentialPlacementEnabled,
} from "./useSequentialInterviewPlacement";

export function useCalendarEventHandlers(
  {
    clickToPlaceAnywhere,
  }: {
    clickToPlaceAnywhere?: boolean;
  } = {
    clickToPlaceAnywhere: false,
  }
) {
  const selectedInterview = useSelectedInterview();
  const setSelectedInterviewId = useSetSelectedInterviewId();
  const interviews = useInterviews();
  const { columnType } = useColumns();
  const { calendarsGrouping } = useCalendarsGrouping();
  const getGroupingAndCalendarIdForColumnIndex =
    useGetGroupingAndCalendarIdForColumnIndex();
  const eventDetailsPopoverStore = useEventDetailsPopoverStore();
  const eventDetailsPopoverState = useStoreState(eventDetailsPopoverStore);

  const [movingTime, setMovingTime] = useState<DateTime | null>(null);

  const { sequentialPlacementEnabled } = useSequentialPlacementEnabled();
  const sequentiallyPlacedInterviews =
    useSequentiallyPlacedInterviews(movingTime);

  /** Create an event based on mouse position and duration of selectedInterview */
  const movingEvents = useMemo(() => {
    if (!movingTime || !selectedInterview) {
      return [];
    }

    if (sequentialPlacementEnabled) {
      return sequentiallyPlacedInterviews;
    }

    return [
      {
        ...selectedInterview,
        startTime: movingTime,
        endTime: movingTime.plus({
          minutes: selectedInterview?.duration ?? 60,
        }),
      },
    ];
  }, [
    movingTime,
    selectedInterview,
    sequentialPlacementEnabled,
    sequentiallyPlacedInterviews,
  ]);

  const eventsViewRef = useEventsViewRef();
  const calculateTimeAndColumnForOffset = useCalculateTimeAndColumnForOffset();
  const calculateOffsetFromEvent = useCalculateOffsetFromEvent();
  const { shouldShowPlacement } = usePlacingInterview();
  const dispatch = useSchedulerInterviewsDispatch();
  const calendarSizes = useCalendarSizes();
  const Y_PADDING = -(calendarSizes.hourHeight.px / 3);

  /**
   * As the mouse moves over the events view, calculate the offset and the current position of the mouse as a DateTime object and calculate
   * state.
   * If columns are calendars, ensure the mouse is over the same calendar as the selectedInterview.
   */
  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (e) {
        const offset = calculateOffsetFromEvent(e);
        const { time: tempMovingTime, columnIndex } =
          calculateTimeAndColumnForOffset({
            ...offset,
            y: offset.y + Y_PADDING,
          });

        if (columnType === "calendar") {
          const { calendarGroupIdx } = getGroupingAndCalendarIdForColumnIndex({
            columnIndex,
          });
          const hoveredCalendarGroup = calendarsGrouping[calendarGroupIdx];

          if (
            !hoveredCalendarGroup ||
            hoveredCalendarGroup.id !== selectedInterview?.id
          ) {
            return;
          }
        }

        setMovingTime(tempMovingTime);
      }
    },
    [
      calculateOffsetFromEvent,
      calculateTimeAndColumnForOffset,
      Y_PADDING,
      columnType,
      getGroupingAndCalendarIdForColumnIndex,
      calendarsGrouping,
      selectedInterview?.id,
    ]
  );

  /** On click of the events view, stage the interview based on mouse position */
  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (eventDetailsPopoverState.open) {
        eventDetailsPopoverStore.hide();
        return;
      }

      if (eventsViewRef && e) {
        const target = e.target as HTMLElement | null;
        const isEvent =
          target?.classList.contains(EVENT_TARGET_CLASS_NAME) ||
          !!target?.closest(`.${EVENT_TARGET_CLASS_NAME}`);
        const isFloatingToolbar = target?.closest(`#${FLOATING_TOOLBAR_ID}`);

        if ((isFloatingToolbar || isEvent) && !clickToPlaceAnywhere) {
          return;
        }

        const offset = calculateOffsetFromEvent(e);
        const { time: clickedTime, columnIndex } =
          calculateTimeAndColumnForOffset({
            ...offset,
            y: offset.y + Y_PADDING,
          });

        if (shouldShowPlacement) {
          movingEvents.forEach((movingEvent) => {
            dispatch({
              ...movingEvent,
              type: "setInterviewTime",
              interviewId: movingEvent.id,
            });
          });

          return;
        }

        if (columnType === "calendar") {
          const { calendarGroupIdx } = getGroupingAndCalendarIdForColumnIndex({
            columnIndex,
          });
          const clickedCalendarGroup = calendarsGrouping[calendarGroupIdx];
          const clickedInterviewGrouping = interviews.find(
            (interview) => interview.id === clickedCalendarGroup.id
          );

          if (clickedInterviewGrouping) {
            dispatch({
              type: "setInterviewTime",
              interviewId: clickedInterviewGrouping.id,
              startTime: clickedTime,
              endTime: clickedTime.plus({
                minutes: clickedInterviewGrouping.duration,
              }),
            });
            setSelectedInterviewId(clickedCalendarGroup.id);
          }
        } else if (selectedInterview) {
          dispatch({
            type: "setInterviewTime",
            interviewId: selectedInterview?.id,
            startTime: clickedTime,
            endTime: clickedTime.plus({
              minutes: selectedInterview.duration,
            }),
          });
        }

        setMovingTime(clickedTime);
      }
    },
    [
      eventDetailsPopoverState.open,
      eventsViewRef,
      eventDetailsPopoverStore,
      clickToPlaceAnywhere,
      calculateOffsetFromEvent,
      calculateTimeAndColumnForOffset,
      Y_PADDING,
      shouldShowPlacement,
      columnType,
      selectedInterview,
      movingEvents,
      dispatch,
      getGroupingAndCalendarIdForColumnIndex,
      calendarsGrouping,
      interviews,
      setSelectedInterviewId,
    ]
  );

  /** Add events listeners to keep state in sync for hovering interview and handle staging interview on click */
  useEffect(() => {
    eventsViewRef?.addEventListener("mousemove", onMouseMove);
    eventsViewRef?.addEventListener("mousedown", onMouseDown);

    return () => {
      eventsViewRef?.removeEventListener("mousemove", onMouseMove);
      eventsViewRef?.removeEventListener("mousedown", onMouseDown);
    };
  }, [eventsViewRef, onMouseMove, onMouseDown]);

  return useMemo(
    () => ({
      onMouseMove,
      onMouseDown,
      movingEvents,
      movingTime,
    }),
    [onMouseMove, onMouseDown, movingEvents, movingTime]
  );
}
