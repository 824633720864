import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { CancellationReasonSchema } from "shared/reporting/scheduled-interviews/types";
import { z } from "zod";

export type CancellationReasonFormData = z.infer<
  typeof CancellationReasonSchema
>;

/** State hook for ReportCancellationReasonView */
export function useReportCancellationReasonState(
  defaultValues?: CancellationReasonFormData
) {
  const form = useForm<CancellationReasonFormData>({
    defaultValues,
    resolver: zodResolver(CancellationReasonSchema),
  });

  useEffect(() => {
    form.trigger();
  }, [form]);

  /** Wrapper around onChange to properly handle dirtying and validation */
  const onChange = useCallback(
    (...params: Parameters<typeof form.setValue>) => {
      const [name, value, opts] = params;

      form.setValue(name, value, {
        shouldDirty: true,
        ...opts,
      });
      // Need to manually trigger validation because of a bug with zodResolver and superRefine
      // https://github.com/react-hook-form/resolvers/issues/661
      form.trigger();
    },
    [form]
  );

  return useMemo(
    () => ({
      form,
      onChange,
    }),
    [form, onChange]
  );
}

export type ReportCancellationReasonState = ReturnType<
  typeof useReportCancellationReasonState
>;
