import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCalendarCheck,
  atlasCalendarCross,
  atlasCalendarUnknown,
} from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";

import { ConferenceRoomAvailability } from "../utils/types";

export type ConferenceRoomConflictIconProps = {
  availability: ConferenceRoomAvailability;
};

export function ConferenceRoomConflictIcon({
  availability,
}: ConferenceRoomConflictIconProps) {
  if (availability === "available") {
    return (
      <Tooltip isInstant content="Conference room is available at this time.">
        <Icon content={atlasCalendarCheck} className="text-green-500" />
      </Tooltip>
    );
  }

  if (availability === "unavailable") {
    return (
      <Tooltip
        isInstant
        content="Conference room is not available at this time."
      >
        <Icon content={atlasCalendarCross} className="text-red-500" />
      </Tooltip>
    );
  }

  return (
    <Tooltip isInstant content="Conference room availability is unknown.">
      <Icon content={atlasCalendarUnknown} className="text-subtle" />
    </Tooltip>
  );
}
