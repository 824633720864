import { DateTime } from "luxon";

export const AVAILABILITY_CANDIDATE_CALENDAR_ID = "candidate";

export const AVAILABILITY_MIN_WINDOW_MINUTES = 15;

export function splitEventsByDay<
  T extends { startTime: DateTime; endTime: DateTime }
>(events: T[]) {
  return events.flatMap((event) => {
    if (event.startTime.day === event.endTime.day) {
      return [event];
    }

    const endOfStartDay = event.startTime.endOf("day").plus({ millisecond: 1 });
    const startOfEndDay = event.endTime.startOf("day");

    return [
      {
        ...event,
        endTime: endOfStartDay,
      },
      {
        ...event,
        startTime: startOfEndDay,
      },
    ];
  });
}
