import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPlus, serviceGreenhouse } from "@resource/atlas/icons";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { getAtssyncInfo } from "client/utils/atssync";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { InterviewForExcludedInterviewCardFragment as Interview } from "generated/graphql-codegen/graphql";

gql(`
  fragment InterviewForExcludedInterviewCard on Interview {
    id
    title
    atssyncInterview {
      account {
        type
      }
    }
  }
`);

export type ExcludedInterviewCardProps = {
  excludedInterview: Interview;
  onClick: () => void;
  className?: string;
};

export function ExcludedInterviewCard({
  excludedInterview: unscheduledInterview,
  onClick,
  className,
}: ExcludedInterviewCardProps) {
  const accountInfo = unscheduledInterview.atssyncInterview?.account.type
    ? getAtssyncInfo(unscheduledInterview.atssyncInterview.account.type)
    : null;
  const icon = accountInfo?.icon ?? serviceGreenhouse;

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onClick}
      onClick={onClick}
      className={clsx(
        "flex gap-2 py-3 pl-3 pr-[.375rem] border-gray-border border rounded-md items-center w-full overflow-hidden group hover:bg-light-gray-200 cursor-pointer",
        className
      )}
    >
      <Icon content={icon} className={clsx("shrink-0 w-5 h-5 opacity-50")} />
      <InterviewTitle
        className="truncate text-body-md grow opacity-50"
        interview={unscheduledInterview}
      />
      <div className="w-8 flex items-center justify-center">
        <Icon content={atlasPlus} className="text-subtle" />
      </div>
    </div>
  );
}
