import clsx from "clsx";
import { TimezoneSelector } from "components/TimezoneSelection/AvailabilityTimezoneSelector";

import { SelfScheduleSubmissionState } from "./__hooks/useSelfScheduleSubmissionState";

export function SelfScheduleTimezoneSelect({
  state,
  className,
}: {
  state: SelfScheduleSubmissionState;
  className?: string;
}) {
  const { selectedTimezone, setSelectedTimezone } = state;
  return (
    <div className={clsx("space-y-2 w-full", className)}>
      <div className="text-body-md-heavy">Timezone</div>
      <TimezoneSelector
        labelType="default"
        rootProps={{ value: selectedTimezone }}
        triggerProps={{
          isDropdown: true,
          className: "flex w-full",
        }}
        onClick={setSelectedTimezone}
      />
    </div>
  );
}
