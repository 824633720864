import { InterviewerSlotForDiff } from "shared/interviewer-slots/types";
import { z } from "zod";

export enum ScheduledInterviewFieldChange {
  TIME = "TIME",
  DURATION = "DURATION",
  TITLE = "TITLE",
  INTERVIEWERS = "INTERVIEWERS",
  VIDEO_CONFERENCING = "VIDEO_CONFERENCING",
  COLLABORATIVE_CODING = "COLLABORATIVE_CODING",
  IS_CANCELLED = "IS_CANCELLED",
  RESTORED = "RESTORED",
  CONFERENCE_ROOM = "CONFERENCE_ROOM",
  PRIVACY = "PRIVACY",
}

export const ScheduledInterviewFieldChangeSchema = z.nativeEnum(
  ScheduledInterviewFieldChange
);

export type ScheduledInterviewForFieldChanges = {
  startAt: Date;
  endAt: Date;
  interviewerSlots: InterviewerSlotForDiff[];
};
