import { useAuthContext } from "auth/context";
import { WorkingHours } from "components/WorkingHours";
import { gql } from "generated/graphql-codegen";
import { useCallback, useMemo } from "react";
import { useWatch } from "react-hook-form";
import {
  RollingDateRangeConfig,
  SchedulingHoursConfig,
  TimeConfig,
} from "shared/self-schedule/types";
import { displayTimezone } from "shared/utils/timezones";

import { EditSelfSchedulingSettingsState } from "./__hooks/useEditSelfSchedulingSettingsState";
import { EditBufferTime } from "./EditBufferTime";
import { EditMinimumNotice } from "./EditMinimumNotice";
import { EditRollingDateRange } from "./EditRollingDateRange";

const MAX_WIDTH_CLASS = "max-w-[420px]";

gql(`
  fragment UserMembershipForEditSelfScheduleSettings on UserMembership {
    id
    defaultSelfSchedulingSettings {
      ...DefaultSelfSchedulingSettingsForEditState
    }
  }
`);
export type EditSelfSchedulingSettingsFormProps = {
  state: EditSelfSchedulingSettingsState;
};

export function EditSelfSchedulingSettingsForm(
  props: EditSelfSchedulingSettingsFormProps
) {
  return (
    <div className="space-y-4">
      <EditSchedulingHours {...props} />
      <EditFormBufferTime {...props} />
      <EditFormMinimumNotice {...props} />
      <EditFormRollingDateRange {...props} />
    </div>
  );
}

function EditSchedulingHours(props: EditSelfSchedulingSettingsFormProps) {
  const { state } = props;
  const {
    onChange,
    onValidTimeRange,
    onInvalidTimeRange,
    form,
    selectedInterviewer,
  } = state;
  const onChangeSchedulingHours = useCallback(
    (schedulingHours: SchedulingHoursConfig) => {
      onChange("schedulingHours", schedulingHours);
    },
    [onChange]
  );
  const { user } = useAuthContext();
  const isSelf =
    selectedInterviewer?.userMembershipId === user?.currentUserMembership?.id;
  const schedulingHours = useWatch({
    control: form.control,
    name: "schedulingHours",
  });
  const mappedSchedulingHours = useMemo(() => {
    return schedulingHours.map((s) => ({
      ...s,
      startTime: s.startTime ?? undefined,
      endTime: s.endTime ?? undefined,
    }));
  }, [schedulingHours]);

  return (
    <WorkingHours
      className={MAX_WIDTH_CLASS}
      value={mappedSchedulingHours}
      onChange={onChangeSchedulingHours}
      onValidTimeRange={onValidTimeRange}
      onInvalidTimeRange={onInvalidTimeRange}
      label="Bookable hours"
      subtext={`Bookable hours will be displayed in ${
        isSelf ? "your" : "the user's"
      } timezone (${displayTimezone(
        selectedInterviewer?.timezone ?? "UTC",
        "abbreviation"
      )}).`}
    />
  );
}

function EditFormBufferTime(props: EditSelfSchedulingSettingsFormProps) {
  const { state } = props;
  const { onChange, form } = state;

  const onChangePreMeetingBuffer = useCallback(
    (buffer: TimeConfig) => {
      onChange("preMeetingBuffer", buffer);
    },
    [onChange]
  );

  const onChangePostMeetingBuffer = useCallback(
    (buffer: TimeConfig) => {
      onChange("postMeetingBuffer", buffer);
    },
    [onChange]
  );

  const preMeetingBuffer = useWatch({
    control: form.control,
    name: "preMeetingBuffer",
  });
  const postMeetingBuffer = useWatch({
    control: form.control,
    name: "postMeetingBuffer",
  });

  return (
    <EditBufferTime
      className={MAX_WIDTH_CLASS}
      preMeetingBuffer={preMeetingBuffer}
      postMeetingBuffer={postMeetingBuffer}
      onChangePreMeetingBuffer={onChangePreMeetingBuffer}
      onChangePostMeetingBuffer={onChangePostMeetingBuffer}
    />
  );
}

function EditFormMinimumNotice(props: EditSelfSchedulingSettingsFormProps) {
  const { state } = props;
  const { onChange, form } = state;

  const onChangeMinimumNotice = useCallback(
    (minNotice: TimeConfig) => {
      onChange("minimumNotice", minNotice);
    },
    [onChange]
  );

  const minimumNotice = useWatch({
    control: form.control,
    name: "minimumNotice",
  });

  return (
    <EditMinimumNotice
      className={MAX_WIDTH_CLASS}
      minimumNotice={minimumNotice}
      onChange={onChangeMinimumNotice}
    />
  );
}

function EditFormRollingDateRange(props: EditSelfSchedulingSettingsFormProps) {
  const { state } = props;
  const { onChange, form } = state;

  const onChangeRollingDateRange = useCallback(
    (range: RollingDateRangeConfig) => {
      onChange("rollingDateRange", range);
    },
    [onChange]
  );

  const rollingDateRange = useWatch({
    control: form.control,
    name: "rollingDateRange",
  });

  return (
    <EditRollingDateRange
      className={MAX_WIDTH_CLASS}
      rollingDateRange={rollingDateRange}
      onChange={onChangeRollingDateRange}
    />
  );
}
