/* eslint-disable import/prefer-default-export */
import { createModule } from "../../__utils/modules";
import { FixedToolbarPlugin } from "./FixedToolbarPlugin";

export const fixedToolbarModule = createModule("fixed-toolbar", {
  declareModule({ insertOptions }) {
    return {
      plugins: [<FixedToolbarPlugin insertOptions={insertOptions} />],
    };
  },
});
