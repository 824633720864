/* eslint-disable import/prefer-default-export */
import { useMemo } from "react";

import {
  HeadingSelectItem,
  OptionSelectItem,
  SelectItem,
  SeparatorSelectItem,
} from "./types";

type SelectItemCreator = {
  heading: (item: Omit<HeadingSelectItem, "_type">) => HeadingSelectItem;
  separator: (item: Omit<SeparatorSelectItem, "_type">) => SeparatorSelectItem;
  option: (item: Omit<OptionSelectItem, "_type">) => OptionSelectItem;
};

type SelectItemsFactory = (i: SelectItemCreator) => SelectItem[];

export function useSelectItems(fn: SelectItemsFactory, deps: unknown[]) {
  const createItem: SelectItemCreator = {
    separator: (item: Omit<SeparatorSelectItem, "_type">) => ({
      _type: "separator",
      ...item,
    }),
    option: (item: Omit<OptionSelectItem, "_type">) => ({
      _type: "option",
      ...item,
    }),
    heading: (item: Omit<HeadingSelectItem, "_type">) => ({
      _type: "heading",
      ...item,
    }),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => fn(createItem), deps);
}
