import { InterviewWithSchedulingInfo } from "client/components/scheduled-interviews/ScheduleSummary";
import { useMemo } from "react";

export type UsePanelSchedulingStatusesProps = {
  interviewsWithSchedulingInfo: InterviewWithSchedulingInfo[];
};

/**
 * A generic hook to look at a list of interviews with statuses and errors and return information about it
 * Used for assisting in deciding copy during panel scheduling
 */
export function usePanelSchedulingStatuses({
  interviewsWithSchedulingInfo,
}: UsePanelSchedulingStatusesProps) {
  return useMemo(() => {
    const hasErrors = interviewsWithSchedulingInfo.some(
      (interview) => interview.errors?.length
    );
    const createdInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => interview.status === "created"
    );
    const hasCreatedInterviews = createdInterviews.length > 0;

    const updatedInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => interview.status === "updated"
    );
    const hasUpdatedInterviews = updatedInterviews.length > 0;

    const cancelledInterviews = interviewsWithSchedulingInfo.filter(
      (interview) => interview.status === "cancelled"
    );
    const hasCancelledInterviews = cancelledInterviews.length > 0;

    const allInterviewsCreated =
      hasCreatedInterviews && !hasUpdatedInterviews && !hasCancelledInterviews;
    const allInterviewsUpdated =
      hasUpdatedInterviews && !hasCreatedInterviews && !hasCancelledInterviews;
    const allInterviewsCancelled =
      hasCancelledInterviews && !hasCreatedInterviews && !hasUpdatedInterviews;
    const interviewsCreatedAndUpdated =
      hasCreatedInterviews && hasUpdatedInterviews && !hasCancelledInterviews;
    const interviewsCreatedAndCancelled =
      hasCreatedInterviews && hasCancelledInterviews && !hasUpdatedInterviews;
    const interviewsUpdatedAndCancelled =
      hasUpdatedInterviews && hasCancelledInterviews && !hasCreatedInterviews;
    const interviewsCreatedUpdatedAndCancelled =
      hasCreatedInterviews && hasUpdatedInterviews && hasCancelledInterviews;
    const allInterviewsBlacklisted = interviewsWithSchedulingInfo.every(
      (interview) => interview.blacklisted
    );

    return {
      hasErrors,
      totalCount: interviewsWithSchedulingInfo.length,
      createdCount: createdInterviews.length,
      hasCreatedInterviews,
      updatedCount: updatedInterviews.length,
      hasUpdatedInterviews,
      cancelledCount: cancelledInterviews.length,
      hasCancelledInterviews,
      allInterviewsCreated,
      allInterviewsUpdated,
      allInterviewsCancelled,
      allInterviewsBlacklisted,
      interviewsCreatedAndUpdated,
      interviewsCreatedAndCancelled,
      interviewsUpdatedAndCancelled,
      interviewsCreatedUpdatedAndCancelled,
    };
  }, [interviewsWithSchedulingInfo]);
}
