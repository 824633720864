import { useGlobalNavigatorOrThrow } from "client/components/generic/misc/GlobalNavigator";
import { useCallback } from "react";

import { CandidateProfile } from "./CandidateProfile";

/**
 *  A wrapper around the CandidateProfile component that provides navigation via the global navigator
 *
 *  Should ONLY be used within the global navigator, or error will be thrown
 */
export function GlobalNavigatorCandidateProfile({
  guideId,
  scrollToUpcomingInterviews,
}: {
  guideId: string;
  scrollToUpcomingInterviews?: boolean;
}) {
  const navigator = useGlobalNavigatorOrThrow();
  const onClickSchedulingRequest = useCallback(
    ({ schedulingRequestId }: { schedulingRequestId: string }) => {
      navigator.navigate("schedulingTaskDetails", {
        schedulingRequestId,
      });
    },
    [navigator]
  );
  const onViewAllInterviews = useCallback(() => {
    navigator.navigate("viewAllGuideScheduledInterviews", {
      guideId,
    });
  }, [guideId, navigator]);
  const onClickScheduledInterview = useCallback(
    ({ scheduledInterviewId }: { scheduledInterviewId: string }) => {
      navigator.navigate("scheduledInterviewDetails", {
        guideId,
        scheduledInterviewId,
      });
    },
    [guideId, navigator]
  );

  if (!guideId) {
    return null;
  }

  return (
    <CandidateProfile
      guideId={guideId}
      backIcon={navigator.backIcon}
      onBack={navigator.back}
      onClickSchedulingRequest={onClickSchedulingRequest}
      onViewAllInterviews={onViewAllInterviews}
      onClickScheduledInterview={onClickScheduledInterview}
      scrollToUpcomingInterviews={scrollToUpcomingInterviews}
    />
  );
}
