import { Icon } from "@resource/atlas/icon/Icon";
import { AtlasIconData } from "@resource/atlas/icon/types";
import { atlasVideoCamera, atlasVideoCameraOff } from "@resource/atlas/icons";
import { Select } from "@resource/atlas/select";
import { SelectTrigger } from "@resource/atlas/select/SelectTrigger";
import { AtlasSelectTriggerProps } from "@resource/atlas/select/types";
import { useSelectItems } from "@resource/atlas/select/use-select-items";
import { useAuthContext } from "auth/context";
import clsx from "clsx";
import { forwardRef, ReactNode, useMemo } from "react";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";
import {
  CONFERENCING_ICONS,
  CONFERENCING_LABELS,
} from "shared/guide-scheduler/conferencing/utils";

export type ConferencingSelectTriggerProps = AtlasSelectTriggerProps & {
  label: ReactNode;
};

const NO_CONFERENCING_KEY = "none";

export const ConferencingSelect = forwardRef<
  HTMLButtonElement,
  ConferencingSelectTriggerProps
>((props, ref) => {
  return (
    <SelectTrigger {...props} ref={ref} className="bg-light-gray-500 w-full">
      {props.label}
    </SelectTrigger>
  );
});

export function ConferencingSelectItem({
  conferencingType,
  emptyState,
  disabled,
}: {
  conferencingType: ConferencingType | string;
  emptyState?: {
    icon: AtlasIconData | null;
    label: string;
  };
  disabled?: boolean;
}) {
  const icon = useMemo(() => {
    if (conferencingType === NO_CONFERENCING_KEY) {
      return emptyState?.icon === null ? null : atlasVideoCameraOff;
    }

    const conferencingIcon =
      CONFERENCING_ICONS[conferencingType as ConferencingType];

    if (conferencingIcon) {
      return conferencingIcon;
    }

    return conferencingIcon !== null ? atlasVideoCamera : null;
  }, [emptyState?.icon, conferencingType]);
  const label = useMemo(() => {
    if (conferencingType === NO_CONFERENCING_KEY) {
      return emptyState?.label ?? "No conferencing";
    }

    return (
      CONFERENCING_LABELS[conferencingType as ConferencingType] ?? "Unknown"
    );
  }, [emptyState?.label, conferencingType]);

  return (
    <div
      className={clsx("flex items-center gap-2", {
        "opacity-40": disabled,
      })}
    >
      {icon && <Icon content={icon} />}
      <span>{label}</span>
    </div>
  );
}

type ConferencingSettingSelectProps = {
  conferencingType?: ConferencingType | null;
  onSelect: (conferencingType: ConferencingType | null) => void;
  phoneEnabled?: boolean;
  customEnabled?: boolean;
  disabled?: boolean;
  allowNoSelection?: boolean;
  requiresGoogleMeetApi?: boolean;
};

export function ConferencingSettingSelect({
  conferencingType,
  onSelect,
  phoneEnabled = true,
  customEnabled = true,
  allowNoSelection = true,
  requiresGoogleMeetApi = false,
  disabled = false,
}: ConferencingSettingSelectProps) {
  const { user } = useAuthContext();
  const zoomEnabled = user?.currentOrganization?.isZoomEnabled ?? false;
  const googleCalendarIntegrationEnabled =
    user?.currentOrganization?.isGoogleCalendarEnabled ?? false;
  const googleMeetApiEnabled = user?.canAccessGoogleMeet ?? false;
  const googleCalendarEnabled =
    googleCalendarIntegrationEnabled &&
    (!requiresGoogleMeetApi || googleMeetApiEnabled);

  const conferencingSelectItems = useSelectItems(
    (i) => {
      return [
        ...(conferencingType && allowNoSelection
          ? [
              i.option({
                value: NO_CONFERENCING_KEY,
                children: "No conferencing",
                renderContent: () => (
                  <ConferencingSelectItem
                    conferencingType={NO_CONFERENCING_KEY}
                  />
                ),
              }),
              i.separator({
                key: "separator",
              }),
            ]
          : []),
        zoomEnabled
          ? i.option({
              value: ConferencingType.ZOOM,
              children: "Zoom",
              renderContent: () => (
                <ConferencingSelectItem
                  conferencingType={ConferencingType.ZOOM}
                />
              ),
            })
          : null,
        googleCalendarEnabled
          ? i.option({
              value: ConferencingType.GOOGLE_MEET,
              children: "Google Meet",
              renderContent: () => (
                <ConferencingSelectItem
                  conferencingType={ConferencingType.GOOGLE_MEET}
                />
              ),
            })
          : null,
        phoneEnabled
          ? i.option({
              value: ConferencingType.PHONE,
              children: "Phone",
              renderContent: () => (
                <ConferencingSelectItem
                  conferencingType={ConferencingType.PHONE}
                />
              ),
            })
          : null,
        customEnabled
          ? i.option({
              value: ConferencingType.OTHER,
              children: "Other",
              renderContent: () => (
                <ConferencingSelectItem
                  conferencingType={ConferencingType.OTHER}
                />
              ),
            })
          : null,
      ];
    },
    [
      conferencingType,
      allowNoSelection,
      zoomEnabled,
      googleCalendarEnabled,
      phoneEnabled,
      customEnabled,
    ]
  );

  return (
    <Select.Root
      sameWidth
      value={conferencingType ?? undefined}
      setValue={(val) => {
        if (val !== conferencingType && typeof val === "string") {
          if (val === NO_CONFERENCING_KEY) {
            onSelect(null);
          } else {
            onSelect(val as ConferencingType);
          }
        }
      }}
      defaultValue={NO_CONFERENCING_KEY}
    >
      <Select.Trigger disabled={disabled}>
        <ConferencingSelect
          label={
            <ConferencingSelectItem
              conferencingType={conferencingType ?? NO_CONFERENCING_KEY}
              emptyState={{
                icon: null,
                label: "Add video conferencing or phone call",
              }}
              disabled={disabled}
            />
          }
        />
      </Select.Trigger>
      <Select.Content items={conferencingSelectItems} />
    </Select.Root>
  );
}
