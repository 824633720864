import { Reference } from "@apollo/client";
import { gql } from "generated/graphql-codegen";
import useMutation from "utils/useMutation";

export const UPSERT_POST_TEMPLATE = gql(`
mutation UpsertPostTemplate($input: UpsertPostTemplateInput!) {
    upsertPostTemplate(input: $input) {
      message
      success
      postTemplate {
        ...PostTemplateForManaging
        organization {
          id
        }
      }
    }
  }
`);

export const useUpsertPostTemplate = () =>
  useMutation(UPSERT_POST_TEMPLATE, {
    update(cache, updatePostTemplateResult) {
      if (
        !updatePostTemplateResult.data?.upsertPostTemplate?.postTemplate
          ?.organization
      ) {
        return;
      }
      cache.modify({
        id: cache.identify({
          ...updatePostTemplateResult.data.upsertPostTemplate.postTemplate
            .organization,
        }),
        fields: {
          postTemplates(existingPostTemplates, { readField }) {
            const existingPostTemplate = existingPostTemplates.find(
              (t: Reference) =>
                readField("id", t) ===
                updatePostTemplateResult?.data?.upsertPostTemplate.postTemplate
                  .id
            );
            if (existingPostTemplate) return existingPostTemplates;

            return [
              ...existingPostTemplates,
              updatePostTemplateResult.data?.upsertPostTemplate.postTemplate,
            ];
          },
        },
      });
    },
  });
