import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleCheckedColor } from "@resource/atlas/icons";
import { mapScheduledInterviewForCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/__utils/mapping";
import { ScheduledInterviewCard } from "client/components/scheduled-interviews/ScheduledInterviewCard/ScheduledInterviewCard";
import { ActivityVerb } from "generated/graphql-codegen/graphql";
import { ReactNode } from "react";
import { InterviewerPoolActivityVerbs } from "shared/activity";
import { formatEntity } from "shared/constants/entities";

import { ActorDisplayAvatar, ActorDisplayContent } from "./__utils/common";
import {
  ActivityFeedMapper,
  ActivityFunctionMap,
  ActivityGroupFunctionMap,
  FeedContext,
} from "./__utils/types";

function interviewerPoolLabel(context: FeedContext) {
  return context === "InterviewerPool"
    ? `this ${formatEntity("training path")}`
    : `a ${formatEntity("training path")}`;
}

export class InterviewerPoolActivityFeed extends ActivityFeedMapper<InterviewerPoolActivityVerbs> {
  isGroupableVerb(_verb: ActivityVerb) {
    return false;
  }

  readonly activityGroupMap: ActivityGroupFunctionMap<InterviewerPoolActivityVerbs> =
    {
      InterviewerPoolTrainingUserCompletedShadow: null,
      InterviewerPoolTrainingUserCompletedReverseShadow: null,
      InterviewerPoolTrainingUserMovedStage: null,
      InterviewerPoolTrainingUserAdded: null,
      InterviewerPoolTrainingUserMarkedAsQualified: null,
      InterviewerPoolUserRemoved: null,
      InterviewerPoolSettingsUpdated: null,
      InterviewerPoolQualifiedUserAdded: null,
      InterviewerPoolCreated: null,
    };

  readonly activityMap: ActivityFunctionMap<InterviewerPoolActivityVerbs> = {
    [ActivityVerb.InterviewerPoolCreated]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`created ${interviewerPoolLabel(rest.context)}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.InterviewerPoolQualifiedUserAdded]: ({
      actor,
      userMembership,
      interviewerPool,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            <>
              added{" "}
              <span className="text-body-md-heavy text-dark">
                {userMembership?.name}
              </span>{" "}
              as a qualified interviewer for{" "}
              <span className="text-body-md-heavy text-dark">
                {interviewerPool?.name ?? "pool"}
              </span>
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.InterviewerPoolSettingsUpdated]: ({ actor, ...rest }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={`updated the settings of ${interviewerPoolLabel(
            rest.context
          )}`}
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.InterviewerPoolTrainingUserAdded]: ({
      actor,
      userMembership,
      interviewerPool,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            <>
              added{" "}
              <span className="text-body-md-heavy text-dark">
                {userMembership?.name}
              </span>{" "}
              as a trainee for{" "}
              <span className="text-body-md-heavy text-dark">
                {interviewerPool?.name ?? "pool"}
              </span>
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.InterviewerPoolTrainingUserMarkedAsQualified]: ({
      userMembership,
      interviewerPool,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={userMembership}
          actionText={
            <>
              completed training for{" "}
              <span className="text-body-md-heavy text-dark">
                {interviewerPool?.name}
              </span>{" "}
              and was marked as qualified
            </>
          }
        />
      ),
      icon: (
        <Icon
          content={atlasCircleCheckedColor}
          className="text-green-500 w-6 h-6"
        />
      ),
    }),
    [ActivityVerb.InterviewerPoolTrainingUserMovedStage]: ({
      actor,
      userMembership,
      interviewerPool,
      meta,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            <>
              manually updated{" "}
              <span className="text-body-md-heavy text-dark">
                {userMembership?.name}
              </span>{" "}
              to Stage{" "}
              {(meta.shadowsCompleted ?? 0) +
                (meta.reverseShadowsCompleted ?? 0) +
                1}{" "}
              for{" "}
              <span className="text-body-md-heavy text-dark">
                {interviewerPool?.name}
              </span>
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.InterviewerPoolUserRemoved]: ({
      actor,
      userMembership,
      interviewerPool,
      ...rest
    }) => ({
      content: (
        <ActorDisplayContent
          {...rest}
          actor={actor}
          actionText={
            <>
              removed{" "}
              <span className="text-body-md-heavy text-dark">
                {userMembership?.name}
              </span>{" "}
              from{" "}
              <span className="text-body-md-heavy text-dark">
                {interviewerPool?.name}
              </span>
            </>
          }
        />
      ),
      icon: <ActorDisplayAvatar actor={actor} />,
    }),
    [ActivityVerb.InterviewerPoolTrainingUserCompletedShadow]: ({
      userMembership,
      interviewerPool,
      scheduledInterview,
      ...rest
    }) => {
      const requirementId = scheduledInterview?.interviewers?.find(
        (i) => i.userMembership.id === userMembership?.id
      )?.interviewerRequirement?.id;
      let subText: ReactNode | null = null;
      if (requirementId) {
        const lead = scheduledInterview?.interviewers?.find(
          (i) => !i.isShadow && i.interviewerRequirement?.id === requirementId
        );

        subText = lead ? (
          <>
            {" "}
            with{" "}
            <span className="text-body-md-heavy text-dark">
              {lead.userMembership.name}
            </span>
          </>
        ) : (
          ""
        );
      }

      return {
        content: (
          <>
            <ActorDisplayContent
              {...rest}
              actor={userMembership}
              actionText={<>completed a shadow interview{subText}</>}
            />
          </>
        ),
        extraContent: scheduledInterview ? (
          <ScheduledInterviewCard
            scheduledInterview={mapScheduledInterviewForCard({
              interview: scheduledInterview,
            })}
            showAsUpcoming
            onClick={() => {}}
          />
        ) : undefined,
        icon: (
          <Icon
            content={atlasCircleCheckedColor}
            className="text-green-500 w-6 h-6"
          />
        ),
      };
    },
    [ActivityVerb.InterviewerPoolTrainingUserCompletedReverseShadow]: ({
      userMembership,
      interviewerPool,
      scheduledInterview,
      ...rest
    }) => {
      const requirementId = scheduledInterview?.interviewers?.find(
        (i) => i.userMembership.id === userMembership?.id
      )?.interviewerRequirement?.id;
      let subText: ReactNode | null = null;
      if (requirementId) {
        const lead = scheduledInterview?.interviewers?.find(
          (i) => !i.isShadow && i.interviewerRequirement?.id === requirementId
        );

        subText = lead ? (
          <>
            {" "}
            with{" "}
            <span className="text-body-md-heavy text-dark">
              {lead.userMembership.name}
            </span>
          </>
        ) : (
          ""
        );
      }

      return {
        content: (
          <>
            <ActorDisplayContent
              {...rest}
              actor={userMembership}
              actionText={<>completed a reverse shadow interview{subText}</>}
            />
          </>
        ),
        extraContent: scheduledInterview ? (
          <ScheduledInterviewCard
            scheduledInterview={mapScheduledInterviewForCard({
              interview: scheduledInterview,
            })}
            showAsUpcoming
            onClick={() => {}}
          />
        ) : undefined,
        icon: (
          <Icon
            content={atlasCircleCheckedColor}
            className="text-green-500 w-6 h-6"
          />
        ),
      };
    },
  };
}
