import { useAuthContext } from "auth/context";
import { useOrgZoomHosts } from "client/components/conferencing/hooks/useOrgZoomHosts";
import { getPossibleUserMembershipIdsForZoomHostSelection } from "client/components/conferencing/hooks/usePossibleZoomHostUserMembershipIds";
import {
  getUserMembershipIdsFromInterviewSlots,
  getUserMembershipsFromInterviewSlots,
} from "client/components/interviewer-slots/utils/misc";
import {
  isStagedScheduledInterview,
  SchedulerStagedScheduledInterview,
  SchedulerStagedScheduledInterviewWithComputedFields,
} from "client/scheduler/utils/types";
import { useAtomValue, useSetAtom } from "jotai";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { hasRescheduleRequirementBeenFulfilled } from "shared/guide-scheduler/field-changes/utils";

import {
  interviewsAtom,
  originalInterviewsAtom,
  selectedInterviewAtom,
  selectedInterviewIdAtom,
} from "../state";
import { computeSchedulerStatusForInterview } from "../utils/scheduler-status";

/** Get interviews in state for the scheduler */
export function useInterviews() {
  const interviews = useAtomValue(interviewsAtom);

  return useMemo(() => {
    return interviews.sort((a, b) => {
      // First, separate placed and unplaced interviews
      const aPlaced = isStagedScheduledInterview(a);
      const bPlaced = isStagedScheduledInterview(b);

      if (aPlaced && !bPlaced) return -1;
      if (!aPlaced && bPlaced) return 1;

      // For placed interviews, sort chronologically
      if (aPlaced && bPlaced) {
        return (
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
      }

      // For unplaced interviews, sort by order
      return (a.order ?? Infinity) - (b.order ?? Infinity);
    });
  }, [interviews]);
}

export function useSetInterviews() {
  return useSetAtom(interviewsAtom);
}

export function useAllInterviewerUserMemberships() {
  const interviews = useInterviews();

  return useMemo(
    () =>
      uniqBy(
        interviews.flatMap((interview) => {
          return getUserMembershipsFromInterviewSlots(
            interview.interviewerSlots
          );
        }),
        "id"
      ),
    [interviews]
  );
}

/** Get the originally hydrated interviews in state for the scheduler */
export function useOriginalInterviews() {
  return useAtomValue(originalInterviewsAtom);
}

/** Get the original interview by ID */
export function useOriginalInterview(interviewId: string) {
  const originalInterviews = useOriginalInterviews();

  return useMemo(() => {
    return (
      originalInterviews.find((interview) => interview.id === interviewId) ??
      null
    );
  }, [originalInterviews, interviewId]);
}

/** Get staged interviews (interviews with start and end time) in state for the scheduler */
export function useStagedInterviews(): SchedulerStagedScheduledInterview[] {
  const interviews = useInterviews();

  return useMemo(
    () => interviews.filter(isStagedScheduledInterview),
    [interviews]
  );
}

/** Get currently selected interview from state */
export function useSelectedInterview() {
  return useAtomValue(selectedInterviewAtom);
}

/** Set the ID of current selected interview */
export function useSetSelectedInterviewId() {
  return useSetAtom(selectedInterviewIdAtom);
}

/** Get interviews with computed statuses and field changes */
export function useStagedInterviewsWithComputedFields(): SchedulerStagedScheduledInterviewWithComputedFields[] {
  const interviews = useStagedInterviews();
  const originalInterviews = useOriginalInterviews();
  const { user } = useAuthContext();
  const { users: orgZoomUsers } = useOrgZoomHosts();

  return useMemo(() => {
    return interviews.map(
      (interview): SchedulerStagedScheduledInterviewWithComputedFields => ({
        ...interview,
        ...computeSchedulerStatusForInterview({
          originalInterviews,
          interview,
        }),
        possibleUserMembershipIdsForZoomHostSelection:
          getPossibleUserMembershipIdsForZoomHostSelection({
            interviewerUserMembershipIds:
              getUserMembershipIdsFromInterviewSlots(
                interview.interviewerSlots
              ),
            orgUsers: orgZoomUsers,
            userMembershipId: user?.currentUserMembership?.id,
          }),
      })
    );
  }, [
    interviews,
    orgZoomUsers,
    originalInterviews,
    user?.currentUserMembership?.id,
  ]);
}

/** Check if any interviews changed time or interviewers, meaning this is a reschedule */
export function useIsReschedule(): boolean {
  const interviewsWithComputedFields = useStagedInterviewsWithComputedFields();

  return useMemo(
    () =>
      interviewsWithComputedFields.some((i) => {
        return (
          i.fieldChanges.length > 0 &&
          hasRescheduleRequirementBeenFulfilled({
            fieldChanges: i.fieldChanges.map((fc) => fc.fieldName),
          })
        );
      }),
    [interviewsWithComputedFields]
  );
}

type InterviewGroupChanges = {
  interviewsToCreate: SchedulerStagedScheduledInterviewWithComputedFields[];
  interviewsToUpdate: SchedulerStagedScheduledInterviewWithComputedFields[];
  interviewsToDelete: SchedulerStagedScheduledInterviewWithComputedFields[];
};

const defaultInterviewGroupChanges: InterviewGroupChanges = {
  interviewsToCreate: [],
  interviewsToUpdate: [],
  interviewsToDelete: [],
};

/** Get all of the scheduler changes that will get sent back to the server (created, updated, deleted interviews) */
export function useInterviewGroupChanges(): InterviewGroupChanges {
  const stagedInterviews = useStagedInterviewsWithComputedFields();

  return useMemo(() => {
    return stagedInterviews.reduce((acc, interview) => {
      if (interview.status === "created") {
        return {
          ...acc,
          interviewsToCreate: [...acc.interviewsToCreate, interview],
        };
      }

      if (
        interview.status === "cancelled" &&
        !interview.originalInterview?.isCancelled
      ) {
        return {
          ...acc,
          interviewsToDelete: [...acc.interviewsToDelete, interview],
        };
      }

      if (
        interview.status === "updated" ||
        // Interview has been restored
        (!interview.isCancelled && interview.originalInterview?.isCancelled)
      ) {
        return {
          ...acc,
          interviewsToUpdate: [...acc.interviewsToUpdate, interview],
        };
      }

      return acc;
    }, defaultInterviewGroupChanges);
  }, [stagedInterviews]);
}

export function useSchedulerHasChanges(): boolean {
  const { interviewsToCreate, interviewsToUpdate, interviewsToDelete } =
    useInterviewGroupChanges();
  const hasChanges = useMemo(
    () =>
      interviewsToCreate.length > 0 ||
      interviewsToUpdate.length > 0 ||
      interviewsToDelete.length > 0,
    [
      interviewsToCreate.length,
      interviewsToDelete.length,
      interviewsToUpdate.length,
    ]
  );

  return hasChanges;
}
