import { Button } from "@resource/atlas/button/Button";
import { useDialogStore } from "@resource/atlas/dialog-v2/Dialog";
import { useLogEvent } from "analytics";
import { useHomepageRedirect } from "client/components/guide/useHomePageRedirect";
import { useFlags } from "client/lib/launchdarkly";
import { CancelScheduledInterviewByCandidateConfirmationDialog } from "client/scheduling-task-workflows/CancelScheduledInterviewByCandidateConfirmationDialog";
import { CancelSelfScheduleInterviewConfirmationDialog } from "client/self-schedule/components/CancelSelfScheduleInterviewConfirmationDialog";
import { CreateOrUpdateSelfScheduleSubmissionDialog } from "client/self-schedule/components/CreateOrUpdateSelfScheduleSubmissionDialog";
import FeatureFlags from "generated/FeatureFlags";
import {
  GuideStatusEnum,
  OrganizationForScheduledInterviewCardFragment,
  ScheduledInterviewForScheduledInterviewCardFragment,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

import { mapScheduledInterviewForCard } from "./__utils/mapping";
import {
  ScheduledInterviewCard,
  ScheduledInterviewCardProps,
} from "./ScheduledInterviewCard";
import { SelfScheduleCardActions } from "./SelfScheduleCardActions";

/** Scheduled interview card for candidate usage, providing necessary options when the interview was self scheduled */
export function CandidateScheduledInterviewCard({
  scheduledInterview,
  organization,
  cardActions: passedCardActions,
  ...rest
}: {
  scheduledInterview: ScheduledInterviewForScheduledInterviewCardFragment;
  organization?: OrganizationForScheduledInterviewCardFragment;
} & Pick<
  ScheduledInterviewCardProps,
  "timezone" | "Badge" | "showAsPast" | "onClick" | "className" | "cardActions"
>) {
  const logEvent = useLogEvent({
    component: "CandidateScheduledInterviewCard",
  });
  const rescheduleDialogStore = useDialogStore();
  const cancelSelfScheduleDialogStore = useDialogStore();
  const cancelStaffScheduleDialogStore = useDialogStore();
  const redirectOnSubmit = useHomepageRedirect();

  const {
    [FeatureFlags.DISABLE_RESCHEDULE_FOR_REJECTED_CANDIDATES]:
      disableRescheduleForRejectedCandidates,
  } = useFlags();

  const cardActions = useMemo(() => {
    if (passedCardActions) {
      return passedCardActions;
    }

    if (scheduledInterview.selfScheduleRequestId) {
      return (
        <SelfScheduleCardActions
          onReschedule={() => {
            logEvent("Reschedule Self-scheduled Interview Clicked");
            rescheduleDialogStore.show();
          }}
          onCancel={() => {
            logEvent("Cancel Self-scheduled Interview Clicked");
            cancelSelfScheduleDialogStore.show();
          }}
          hideCancel={
            scheduledInterview.isCancelled ||
            (disableRescheduleForRejectedCandidates &&
              scheduledInterview.guide?.status === GuideStatusEnum.REJECTED)
          }
          hideReschedule={
            disableRescheduleForRejectedCandidates &&
            scheduledInterview.guide?.status === GuideStatusEnum.REJECTED
          }
        />
      );
    }

    if (
      organization?.enableCandidateInterviewCancellation &&
      scheduledInterview.createdByGuide &&
      !scheduledInterview.isCancelled
    ) {
      return (
        <Button
          size="small"
          onClick={() => {
            logEvent("Cancel Staff-scheduled Interview Clicked");
            cancelStaffScheduleDialogStore.show();
          }}
        >
          Cancel
        </Button>
      );
    }

    return undefined;
  }, [
    passedCardActions,
    scheduledInterview.selfScheduleRequestId,
    scheduledInterview.createdByGuide,
    scheduledInterview.isCancelled,
    scheduledInterview.guide?.status,
    organization?.enableCandidateInterviewCancellation,
    disableRescheduleForRejectedCandidates,
    logEvent,
    rescheduleDialogStore,
    cancelSelfScheduleDialogStore,
    cancelStaffScheduleDialogStore,
  ]);

  return (
    <>
      <ScheduledInterviewCard
        {...rest}
        scheduledInterview={mapScheduledInterviewForCard({
          interview: scheduledInterview,
        })}
        cardActions={cardActions}
        hideInternalStatuses
        variant="candidate"
      />
      {scheduledInterview.selfScheduleRequestId && (
        <>
          <CreateOrUpdateSelfScheduleSubmissionDialog
            store={rescheduleDialogStore}
            selfScheduleRequestId={scheduledInterview.selfScheduleRequestId}
            scheduledInterviewId={scheduledInterview.id}
            onComplete={redirectOnSubmit}
          />
          <CancelSelfScheduleInterviewConfirmationDialog
            store={cancelSelfScheduleDialogStore}
            selfScheduleRequestId={scheduledInterview.selfScheduleRequestId}
            scheduledInterviewId={scheduledInterview.id}
            startTime={scheduledInterview.startTime}
            interviewerName={
              scheduledInterview.interviewers[0]?.userMembership.name
            }
          />
        </>
      )}
      {organization?.enableCandidateInterviewCancellation && (
        <CancelScheduledInterviewByCandidateConfirmationDialog
          store={cancelStaffScheduleDialogStore}
          scheduledInterviewId={scheduledInterview.id}
          startTime={scheduledInterview.startTime}
        />
      )}
    </>
  );
}
