import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import React from "react";

import Badge from "../badge/Badge";

export interface VerticalTabProps extends Omit<Ariakit.TabProps, "size"> {
  /** The icon component to display */
  icon?: React.ReactNode;
  /** Whether the tab is disabled */
  isDisabled?: boolean;
  /** Optional badge text to display */
  badge?: string;
  /** Whether this tab is a section header */
  isHeader?: boolean;
}

export interface VerticalTabListProps extends Ariakit.TabListProps {
  /** The selected tab ID */
  selectedId?: string;
  /** Callback when selected tab changes */
  onSelectedChange?: (id: string) => void;
  /** Custom className for the list */
  className?: string;
}

export interface VerticalTabPanelProps extends Ariakit.TabPanelProps {
  /** Custom className for the panel */
  className?: string;
}

export interface VerticalTabRootProps extends Ariakit.TabProviderProps {
  /** Callback when selected tab changes */
  onSelectedChange?: (id: string) => void;
  /** The children to render */
  children: React.ReactNode;
  /** Custom className for the root element */
  className?: string;
}

export const VerticalTab = React.forwardRef<
  HTMLButtonElement,
  VerticalTabProps
>((props, ref) => {
  const { children, icon, isDisabled, badge, isHeader, className, ...rest } =
    props;

  const tabs = Ariakit.useTabContext();
  const state = Ariakit.useStoreState(tabs);

  return (
    <Ariakit.Tab
      ref={ref}
      disabled={isDisabled}
      accessibleWhenDisabled={false}
      {...rest}
      className={clsx(
        "group w-full",
        "flex items-center gap-3 px-3 py-1.5",
        "rounded-md",
        {
          // Header styles
          "text-subtle text-body-sm-heavy": isHeader,
          // Regular tab styles
          "text-dark text-body-sm hover:bg-light-gray-200 hover:text-dark":
            !isHeader,
          "aria-selected:bg-light-gray-500": !isHeader,
          "active:bg-light-gray-500": !isHeader,
          "data-[active-item]:bg-light-gray-500 data-[active-item]:text-dark":
            !isHeader,
        },
        // Disabled state
        isDisabled && "opacity-50 cursor-not-allowed",
        className
      )}
    >
      {icon && (
        <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center text-subtle">
          {icon}
        </span>
      )}
      <span className="flex-grow text-left">{children}</span>
      {badge && (
        <Badge
          variant="purple"
          styles={state?.selectedId === rest.id ? "bold" : "subtle"}
          size="small"
        >
          {badge}
        </Badge>
      )}
    </Ariakit.Tab>
  );
});

export const VerticalTabList = React.forwardRef<
  HTMLDivElement,
  VerticalTabListProps
>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <Ariakit.TabList
      ref={ref}
      {...rest}
      className={clsx(
        "flex flex-col gap-1 p-3 min-w-[248px] bg-white",
        className
      )}
    >
      {children}
    </Ariakit.TabList>
  );
});

export const VerticalTabPanel = React.forwardRef<
  HTMLDivElement,
  VerticalTabPanelProps
>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <Ariakit.TabPanel
      ref={ref}
      {...rest}
      className={clsx("outline-none", className)}
    >
      {children}
    </Ariakit.TabPanel>
  );
});

export const VerticalTabRoot = React.forwardRef<
  HTMLDivElement,
  VerticalTabRootProps
>((props, ref) => {
  const { selectedId, store, onSelectedChange, children, className } = props;

  return (
    <Ariakit.TabProvider
      selectedId={selectedId}
      store={store}
      setSelectedId={(id) => id && onSelectedChange?.(id)}
    >
      <div ref={ref} className={clsx("flex", className)}>
        {children}
      </div>
    </Ariakit.TabProvider>
  );
});

// Add display names
VerticalTab.displayName = "VerticalTab";
VerticalTabList.displayName = "VerticalTabList";
VerticalTabPanel.displayName = "VerticalTabPanel";
VerticalTabRoot.displayName = "VerticalTabRoot";
