import {
  Tooltip as AriaTooltip,
  TooltipAnchor as AriaTooltipAnchor,
  TooltipArrow as AriaTooltipArrow,
  TooltipProvider as AriaTooltipProvider,
} from "@ariakit/react";
import React, {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  isValidElement,
  ReactNode,
  useMemo,
} from "react";

import { getTooltipConfig } from "../__utils/__deprecated";
import { createComponentUtils } from "../__utils/atlas";
import { mergePropsIntoSingleChild } from "../__utils/react";
import { AtlasTooltipProps } from "./types";

// config
// ------

const COMPONENT_NAME = "Tooltip";
const DEFAULT_PROPS = {
  side: "top",
} as const;

const DELAY = 700;

const { el } = createComponentUtils(COMPONENT_NAME);

// trigger
// -------

type TooltipTriggerProps = {
  children?: ReactNode;
  type?: unknown;
  // eslint-disable-next-line camelcase
  UNSAFE_isFocusDisabled?: boolean;
  "data-state"?: unknown;
};

function TooltipTriggerComponent(
  {
    children,
    type,
    UNSAFE_isFocusDisabled: isFocusDisabled,
    "data-state": _,
    ...props
  }: TooltipTriggerProps,
  ref: ForwardedRef<unknown>
) {
  const focusProps: HTMLAttributes<HTMLElement> = {};
  if (!isFocusDisabled) focusProps.tabIndex = 0;

  return mergePropsIntoSingleChild({ ...props, ...focusProps, ref }, children);
}

const TooltipTrigger = forwardRef<unknown, TooltipTriggerProps>(
  TooltipTriggerComponent
);

// component
// ---------

/**
 * A popup that displays information related to an element when the element
 * receives keyboard focus or the mouse hovers over it.
 */
const Tooltip = React.memo(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardRef<any, AtlasTooltipProps>(
    (
      {
        side = DEFAULT_PROPS.side,
        children,
        isInstant,
        content,
        UNSAFE_isFocusDisabled: isFocusDisabledProp,
        UNSAFE_mode: mode,
        ...htmlProps
      },
      ref
    ) => {
      const timeout = isInstant ? 0 : DELAY;

      const tooltipConfig = useMemo(() => {
        if (!isValidElement(children)) return null;
        return getTooltipConfig(children);
      }, [children]);

      const isSlot = (mode ?? tooltipConfig?.mode) === "slot";
      const isFocusDisabled =
        isFocusDisabledProp || tooltipConfig?.isFocusDisabled;

      const trigger = (
        <TooltipTrigger
          UNSAFE_isFocusDisabled={isFocusDisabled}
          {...htmlProps}
          ref={ref}
        >
          {isSlot ? (
            children
          ) : (
            <span
              className={el`wrapper`}
              style={{
                appearance: "button",
                MozAppearance: "button",
                WebkitAppearance: "button",
              }}
            >
              {children}
            </span>
          )}
        </TooltipTrigger>
      );

      if (!content) {
        return trigger;
      }

      return (
        <AriaTooltipProvider timeout={timeout} placement={side}>
          <AriaTooltipAnchor render={trigger} />
          <AriaTooltip gutter={8} className={el`content`}>
            <AriaTooltipArrow />
            {content}
          </AriaTooltip>
        </AriaTooltipProvider>
      );
    }
  )
);

export default Tooltip;
