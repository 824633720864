/* eslint-disable no-underscore-dangle, @typescript-eslint/no-explicit-any, import/prefer-default-export, no-param-reassign */
import type { ElementType } from "react";

/** Sets collection-related metadata in a collection component.
 * @deprecated Used the "metadata" options in `createComponent` instead. */
export function setCollectionMetadata<P>(
  component: ElementType<P>,
  metadata: { type?: string }
) {
  (component as any)._metadata = {
    ...(component as any)._metadata,
    collectionType: metadata.type,
  };
}
