import { Button } from "@resource/atlas/button/Button";
import { AtlasButtonProps } from "@resource/atlas/button/types";
import { atlasClose } from "@resource/atlas/icons";
import clsx from "clsx";

export function CloseButton({
  onClick,
  size = "small",
  className,
  ...props
}: {
  onClick?: () => void;
} & AtlasButtonProps) {
  return (
    <Button
      isGhost
      size={size}
      icon={atlasClose}
      onClick={onClick}
      className={clsx("shrink-0 min-w-[2rem]", className)}
      {...props}
    />
  );
}
