import { useLazyQuery } from "@apollo/client";
import {
  MentionValue,
  OnTypeMention,
} from "@resource/atlas/content-editor/mentions/__lib/types";
import { gql } from "generated/graphql-codegen";
import { useCallback } from "react";

const FETCH_USERS_FOR_CONTENT_EDITOR_MENTION = gql(`
  query FetchUsersForContentEditorMention($search: String, $limit: Int) {
    userMemberships(search: $search, limit: $limit) {
      id
      name
      email
      imageUrl
    }
  }
`);

export function useOnTypeMention(): OnTypeMention {
  const [fetchUsers] = useLazyQuery(FETCH_USERS_FOR_CONTENT_EDITOR_MENTION);

  return useCallback(
    async (term) => {
      const result = await fetchUsers({
        variables: {
          search: term,
          limit: 25,
        },
      });

      if (!result?.data) {
        return [];
      }

      return result.data.userMemberships.map(
        (userMembership): MentionValue => ({
          id: userMembership.id,
          name: userMembership.name || userMembership.email,
          imageUrl: userMembership.imageUrl,
        })
      );
    },
    [fetchUsers]
  );
}
