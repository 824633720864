import {
  useCalendarSetSelectedDay,
  useCalendarTimezone,
} from "client/components/calendar-v2/hooks/settings";
import { useCalendarContext } from "client/components/calendar-v2/utils/context";
import { useEffect } from "react";
import { usePrevious } from "react-use";

/**
 * Consumers can pass currentViewingTime to the calendar to provide context
 * When this time changes, we want to scroll to it with a 2-hour buffer
 * (currently used for the scheduler to auto scroll when a new interview is selected)
 */
export function useSyncCurrentDayWithCurrentViewingTime() {
  const { currentViewingTime } = useCalendarContext();
  const calendarTimezone = useCalendarTimezone();
  const prevViewingTime = usePrevious(currentViewingTime);
  const setSelectedDay = useCalendarSetSelectedDay();

  useEffect(() => {
    if (!currentViewingTime) return;

    if (
      !prevViewingTime ||
      !currentViewingTime.hasSame(prevViewingTime, "minute")
    ) {
      setSelectedDay(
        currentViewingTime.setZone(calendarTimezone).startOf("day")
      );
    }
  }, [calendarTimezone, currentViewingTime, prevViewingTime, setSelectedDay]);
}
