import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin as LexicalPlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import clsx from "clsx";
import { useEffect } from "react";

import { setRef, useId } from "../../../__utils/react";
import { insertOptions } from "../../__utils/base/rich-text/lib/insert-options";
import { useInputRef } from "../../__utils/misc";
import { createModule } from "../../__utils/modules";
import { useTmpPlaceholderTextOverrideContext } from "../../__utils/tmp-placeholder-context";
import { theme, themeContainerClass } from "../../theme";
import { el } from "../__utils/component-utils";
import { NewLinePlugin } from "./NewLinePlugin";

export function PlainTextPlugin() {
  const inputRef = useInputRef();

  const { placeholder } = useTmpPlaceholderTextOverrideContext();

  // TODO: workaround for the lack of `ref` prop in `ContentEditable`
  const inputId = `content-editor-input-${useId()}`;
  useEffect(() => {
    const inputElement =
      (document.getElementById(inputId) as HTMLDivElement) ?? null;
    setRef(inputRef, inputElement);
    return () => setRef(inputRef, null);
  }, [inputId, inputRef]);

  return (
    <>
      <LexicalPlainTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={
          <ContentEditable
            id={inputId}
            className={clsx(el`input`, themeContainerClass)}
          />
        }
        placeholder={
          <div className={el`tmp-placeholder`}>
            <h1>{placeholder ?? "Add some text"}</h1>
          </div>
        }
      />
    </>
  );
}

export const plainTextModule = createModule("rich-text", {
  createLinkedOptions() {
    return { insertOptions };
  },
  declareModule() {
    return {
      nodes: [],
      plugins: [<PlainTextPlugin />, <NewLinePlugin />],
      themes: [theme],
    };
  },
});
