import { RescheduleReasonDisplayInfo } from "client/utils/reschedule-reason-display-info";
import {
  ScheduledInterviewOtherReason,
  ScheduledInterviewRescheduleReason,
  SchedulingRequestForInProgressInfoFragment,
} from "generated/graphql-codegen/graphql";
import { useMemo } from "react";

export const useSchedulingTaskRescheduleReason = ({
  schedulingRequest,
}: {
  schedulingRequest: SchedulingRequestForInProgressInfoFragment;
}) => {
  const { scheduledInterviews } = schedulingRequest;

  return useMemo(() => {
    if (schedulingRequest.rescheduleReason) {
      return RescheduleReasonDisplayInfo[schedulingRequest.rescheduleReason];
    }

    const interviewWithReschedulRequirement = scheduledInterviews.find(
      (interview) => interview.pendingRescheduleInterviewRequirement
    );

    if (!interviewWithReschedulRequirement) return null;

    const { reason, otherReason, additionalText } =
      interviewWithReschedulRequirement.pendingRescheduleInterviewRequirement!;

    let reasonText = "";
    switch (reason) {
      case ScheduledInterviewRescheduleReason.CANDIDATE_NO_SHOWED:
        reasonText = "Candidate no-showed";
        break;
      case ScheduledInterviewRescheduleReason.CANDIDATE_REQUESTED_RESCHEDULE:
        reasonText = "Candidate requested reschedule";
        break;
      case ScheduledInterviewRescheduleReason.INTERVIEWER_DECLINED:
        reasonText = "Interviewer declined";
        break;
      case ScheduledInterviewRescheduleReason.INTERVIEWER_NO_SHOWED:
        reasonText = "Interviewer no-showed";
        break;
      case ScheduledInterviewRescheduleReason.OTHER:
        if (otherReason === ScheduledInterviewOtherReason.CANDIDATE_REQUESTED) {
          reasonText = `Other: Candidate initiated`;
        } else {
          reasonText = `Other: Company initiated`;
        }
        break;
      default:
        break;
    }

    return `${reasonText}${additionalText ? ` - ${additionalText}` : ""}`;
  }, [scheduledInterviews, schedulingRequest.rescheduleReason]);
};
