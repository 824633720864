import { Icon } from "@resource/atlas/icon/Icon";
import { atlasBell, atlasGear, atlasTrash } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu-v2";
import { useMemo } from "react";
import urljoin from "url-join";

type UseNotificationMenuItemsProps = {
  markAllAsRead: () => void;
  deleteAllReadNotifications: () => void;
};

export const useNotificationMenuItems = ({
  markAllAsRead,
  deleteAllReadNotifications,
}: UseNotificationMenuItemsProps) => {
  return useMemo(
    () => [
      <Menu.Item
        key="mark-all-read"
        size="compact"
        leadingContent={<Icon content={atlasBell} className="text-subtle" />}
        onClick={() => markAllAsRead()}
      >
        Mark all as read
      </Menu.Item>,
      <Menu.Item
        key="delete-all-read"
        size="compact"
        leadingContent={<Icon content={atlasTrash} className="text-subtle" />}
        onClick={() => deleteAllReadNotifications()}
      >
        Delete all read notifications
      </Menu.Item>,
      <Menu.Separator key="separator" />,
      <Menu.Item
        key="open-notification-settings"
        size="compact"
        leadingContent={<Icon content={atlasGear} className="text-subtle" />}
        onClick={() => {
          window.open(
            urljoin(window.location.origin, "/settings/user-notifications"),
            "_blank"
          );
        }}
      >
        Notification settings
      </Menu.Item>,
    ],
    [markAllAsRead, deleteAllReadNotifications]
  );
};
