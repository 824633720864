import { Link } from "@resource/atlas/link/Link";
import clsx from "clsx";

type AvailabilityEmptyStateProps = {
  onRequestAvailability: () => void;
  onAddManually: () => void;
  hideActions?: boolean;
};

export function AvailabilityEmptyState({
  onRequestAvailability,
  onAddManually,
  hideActions,
}: AvailabilityEmptyStateProps) {
  return (
    <div
      className={clsx(
        "flex flex-col space-y-1 justify-center items-center bg-light-gray-200 rounded-md text-body-md text-subtle",
        {
          "h-20": !hideActions,
          "h-16": hideActions,
        }
      )}
    >
      <span>No availability has been submitted.</span>
      {!hideActions && (
        <span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as="button"
            onClick={() => onRequestAvailability()}
            className="!text-purple-500 text-body-md"
          >
            Request availability
          </Link>{" "}
          or {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as="button"
            onClick={onAddManually}
            className="!text-purple-500 text-body-md"
          >
            add manually
          </Link>
        </span>
      )}
    </div>
  );
}
