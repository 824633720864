import { Hovercard } from "@resource/atlas/hovercard-v2";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasColorDot, atlasFilter } from "@resource/atlas/icons";
import React from "react";

import { filterTypeToLabel } from "../tag-filters/utils/display";
import { TagFilter } from "./types";

type SelectedTagFiltersProps = {
  tagFilters: TagFilter[];
};

export function SelectedTagFilters({ tagFilters }: SelectedTagFiltersProps) {
  return (
    <Hovercard.Root timeout={0} placement="top">
      <Hovercard.Anchor>
        <Icon className="text-subtle" content={atlasFilter} />
      </Hovercard.Anchor>
      <Hovercard.Content className="bg-white shadow-lg rounded-md p-2">
        <div className="flex flex-col gap-2">
          {tagFilters.map((tagFilter) => (
            <div key={tagFilter.id} className="flex flex-col gap-1">
              <div className="text-body-sm text-subtle">
                {filterTypeToLabel[tagFilter.type]}:
              </div>
              <div className="flex flex-wrap gap-1">
                {tagFilter.tags.map((tag) => (
                  <div
                    key={tag.id}
                    className="flex items-center gap-1 bg-light-gray-200 text-subtle text-body-sm px-2 py-1 rounded-md"
                  >
                    <Icon
                      content={atlasColorDot}
                      style={{
                        color: tag.tagGroup?.color ?? tag.color,
                      }}
                    />
                    <span>{tag.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Hovercard.Content>
    </Hovercard.Root>
  );
}
