import { SchedulingRequestTaskStatus } from "generated/graphql-codegen/graphql";

export function getTaskStatusText(
  taskStatus: SchedulingRequestTaskStatus | null
) {
  switch (taskStatus) {
    case SchedulingRequestTaskStatus.NEEDS_CANDIDATE_CONFIRMATION:
      return "Confirmation needed";
    case SchedulingRequestTaskStatus.NEEDS_SCHEDULING:
      return "Scheduling needed";
    case SchedulingRequestTaskStatus.NEEDS_AVAILABILITY:
      return "Availability needed";
    case SchedulingRequestTaskStatus.WAITING_FOR_AVAILABILITY:
      return "Waiting for availability";
    default:
      return null;
  }
}
