import { useTabStore } from "@ariakit/react";
import config from "config";
import { useEffect } from "react";
import useWindowMessageListener, {
  Message as WindowMessage,
} from "utils/useWindowMessageListener";

export const GREENHOUSE_CANDIDATE_PROFILE_PATHNAME_REGEX =
  /^\/people\/([0-9]+)/i;
export const CURRENT_TAB_LOCAL_STORAGE_KEY = "extension_selected_tab";
export const ExtensionTabs = {
  schedulingTasks: "scheduling_tasks",
  candidate: "candidate",
};

export function useExtensionTabStore({
  defaultSelectedTab,
  onCandidateProfile,
}: {
  defaultSelectedTab?: string | null;
  onCandidateProfile?: boolean;
}) {
  let defaultSelectedId = window?.localStorage.getItem(
    CURRENT_TAB_LOCAL_STORAGE_KEY
  );

  if (defaultSelectedTab) {
    defaultSelectedId = defaultSelectedTab;
  } else if (!defaultSelectedId) {
    defaultSelectedId = onCandidateProfile
      ? ExtensionTabs.candidate
      : ExtensionTabs.schedulingTasks;
  }

  const tabStore = useTabStore({ defaultSelectedId });
  const tabState = tabStore.useState();

  useEffect(() => {
    if (typeof window !== "undefined" && tabState.selectedId) {
      window.localStorage.setItem(
        CURRENT_TAB_LOCAL_STORAGE_KEY,
        tabState.selectedId
      );
    }
  }, [tabState.selectedId]);

  const listener = (msg: WindowMessage) => {
    if (
      typeof msg.data === "object" &&
      "type" in msg.data &&
      msg.data.type === "parent-href"
    ) {
      const greenhouseProfileUrl = new URL(msg.data.href);
      const onGreenhouseProfile = greenhouseProfileUrl.pathname.match(
        GREENHOUSE_CANDIDATE_PROFILE_PATHNAME_REGEX
      );
      // We want to default to the scheduling_tasks tab if we're not on a candidate profile,
      // UNLESS we have a selected tab from the url (which should take precedence)
      if (!onGreenhouseProfile && !defaultSelectedTab) {
        tabStore.setSelectedId(ExtensionTabs.schedulingTasks);
      }
    }
  };

  useWindowMessageListener(
    listener,
    config.EXTENSION_ID_ARRAY.map((id) => `chrome-extension://${id}`)
  );

  return tabStore;
}
