import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { hiringDecisionToDisplay } from "client/components/interview-plan/HiringDecisionSelect";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { useCallback } from "react";

import { getDefaultGuideStatusForWorkflowType } from "../__hooks/useGuideComposeMessageState";
import { PostMessageDialogQuery } from "./PostMessageDialogQuery";
import { PostMessageViewProps } from "./PostMessageView";

type PostMessageDialogProps = {
  dialog: DialogProps;
  guideId: string;
  onCompleted?: () => void;
  onDismiss?: () => void;
} & Omit<PostMessageViewProps, "guide" | "onCompleted" | "onDismiss">;

export function PostMessageDialog({
  dialog,
  guideId,
  onCompleted: onCompletedParent,
  onDismiss: onDismissParent,
  ...rest
}: PostMessageDialogProps) {
  const onCompleted = useCallback(() => {
    dialog.store.hide();
    onCompletedParent?.();
  }, [dialog.store, onCompletedParent]);
  const onDismiss = useCallback(() => {
    dialog.store.hide();
    onDismissParent?.();
  }, [dialog.store, onDismissParent]);
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store: dialog.store,
  });

  const { workflowType } = rest;
  const status = getDefaultGuideStatusForWorkflowType(workflowType);
  const decision = status ? hiringDecisionToDisplay[status] : null;

  return (
    <Dialog size="medium" {...dialogProps} {...dialog}>
      <ErrorBoundary
        fallback={({ onRecover }) => (
          <DialogError
            className="!min-h-[min(90vh,_50rem)]"
            header={{
              title: "Message candidate",
            }}
            onRecover={onRecover}
          />
        )}
      >
        <PostMessageDialogQuery
          {...rest}
          viewProps={{
            className: "!min-h-[min(90vh,_50rem)]",
            header: {
              title: decision?.dialogTitle || "Message candidate",
            },
            ...rest.viewProps,
          }}
          onDismiss={onDismiss}
          onCompleted={onCompleted}
          guideId={guideId}
        />
      </ErrorBoundary>
      <WarningDialog />
    </Dialog>
  );
}
