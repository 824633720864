import clsx from "clsx";
import React from "react";

import { CalendarEventsViewElement } from "../portals/CalendarEventsViewElement";

export const FLOATING_TOOLBAR_ID = "floating-toolbar";

export type FloatingToolbarProps = React.ComponentPropsWithoutRef<"div">;

/**
 * An easy wrapper to add your own floating toolbar to the calendar
 */
export function FloatingToolbar(props: FloatingToolbarProps) {
  const { className, style, ...rest } = props;

  return (
    <CalendarEventsViewElement>
      <div
        {...rest}
        id={FLOATING_TOOLBAR_ID}
        className={clsx(
          "px-4 py-1 rounded-md",
          "fixed bottom-8",
          "left-1/2 transform -translate-x-1/2",
          "bg-indigo-500 text-white",
          "text-body-sm",
          className
        )}
        style={{ zIndex: 9999, ...style }}
      />
    </CalendarEventsViewElement>
  );
}
