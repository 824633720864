import { useExtensionController } from "client/app/extension/__components/ExtensionControllerProvider";
import { GuideManagerTab } from "client/app/extension/guide/[guideId]/__tmp/GuideManagerTab";
import Loading from "components/Loading";
import { gql } from "generated/graphql-codegen";
import { GuideForExtensionPageFragment } from "generated/graphql-codegen/graphql";
import { useEffect } from "react";

import { LookingForCandidateMessage } from "./LookingForCandidateMessage";

gql(`
  fragment GuideForExtensionPage on Guide {
    id
    status
    candidate {
      id
      firstName: internalFirstName
      name: internalName
      organization {
        name
        features
        defaultAvatarImageUrl
        theme {
          primaryColor
          secondaryColor
        }
      }
    }
  }
`);

export function GuideExtensionTab({
  guide,
  onCandidateProfile,
  guideLoading,
  onClickScheduledInterview,
  onViewAllInterviews,
  onClickSchedulingRequest,
}: {
  guide?: GuideForExtensionPageFragment | null;
  guideLoading?: boolean;
  onCandidateProfile?: boolean;
  onClickSchedulingRequest: (args: { schedulingRequestId: string }) => void;
  onViewAllInterviews: () => void;
  onClickScheduledInterview: (args: { scheduledInterviewId: string }) => void;
}) {
  const { sendMessageToParent } = useExtensionController();

  useEffect(() => {
    sendMessageToParent({ type: "request-parent-href" });
  }, [sendMessageToParent]);

  if (!guide) {
    if (guideLoading) {
      return <Loading />;
    }

    if (!onCandidateProfile) {
      return (
        <div className="mt-52">
          <LookingForCandidateMessage />
        </div>
      );
    }

    // guide is null but we didn't throw an error, so it's not found
    // show loading state as we should be polling in the background
    // at some point would be ideal to check if staff users are in the wrong org
    return <Loading />;
  }

  return (
    <GuideManagerTab
      guideId={guide.id}
      onClickSchedulingRequest={onClickSchedulingRequest}
      onViewAllInterviews={onViewAllInterviews}
      onClickScheduledInterview={onClickScheduledInterview}
    />
  );
}
