/**
 * Provide defaults to calendar settings and hydrate them with the provided data.
 * This is not called automatically, but consumers can use directly to provide defaults.
 * It's broken out so that consumers don't have to deal with conflicting jotai providers.
 */
import { Provider } from "jotai";
import { useHydrateAtoms } from "jotai/utils";

import {
  CalendarSettings,
  hydrateCalendarIncludeWeekends,
  hydrateCalendarTimezone,
  hydrateCalendarTwentyFourHourFormat,
  hydrateCalendarView,
  hydrateDayViewType,
  hydrateSelectedDayInternal,
} from "../hooks/settings";

export type CalendarSettingsHydrationData = {
  defaultSettings?: Partial<CalendarSettings>;
};

type HydrateCalendarSettingsProps = CalendarSettingsHydrationData & {
  children: React.ReactNode;
};

export function useCalendarSettingsHydrationOptions({
  defaultSettings,
}: CalendarSettingsHydrationData) {
  return [
    ...(defaultSettings?.timezone !== undefined
      ? [hydrateCalendarTimezone(defaultSettings?.timezone)]
      : []),
    ...(defaultSettings?.includeWeekends !== undefined
      ? [hydrateCalendarIncludeWeekends(defaultSettings.includeWeekends)]
      : []),
    ...(defaultSettings?.twentyFourHourFormat !== undefined
      ? [
          hydrateCalendarTwentyFourHourFormat(
            defaultSettings.twentyFourHourFormat
          ),
        ]
      : []),
    ...(defaultSettings?.view !== undefined
      ? [hydrateCalendarView(defaultSettings.view)]
      : []),
    ...(defaultSettings?.dayViewType !== undefined
      ? [hydrateDayViewType(defaultSettings.dayViewType)]
      : []),
    // NOTE: The selectedDay hydration value will be overwritten by the initial scroll value.
    ...(defaultSettings?.selectedDay !== undefined
      ? [hydrateSelectedDayInternal(defaultSettings.selectedDay)]
      : []),
  ] as const;
}

export function useHydrateCalendarSettings({
  defaultSettings,
}: CalendarSettingsHydrationData) {
  const options = useCalendarSettingsHydrationOptions({ defaultSettings });

  useHydrateAtoms(options);
}

export function HydrateCalendarSettings({
  defaultSettings,
  children,
}: HydrateCalendarSettingsProps) {
  useHydrateCalendarSettings({ defaultSettings });

  return <>{children}</>;
}

export function CalendarSettingsProvider(props: HydrateCalendarSettingsProps) {
  return (
    <Provider>
      <HydrateCalendarSettings {...props} />
    </Provider>
  );
}
