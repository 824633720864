import { EmbedData } from "./shared";

// regexp
// ------

// youtube - https://regex101.com/r/xwrEvo, https://regex101.com/r/GZCLKK and https://regex101.com/r/mVU3Yd
const YOUTUBE_BASE_REGEX_STRING =
  "^https:\\/\\/(?:www\\.)?(?:m\\.)?youtube\\.com\\/";
const YOUTUBE_ID_REGEX_STRING = "[a-zA-Z0-9_-]+";
const YOUTUBE_WATCH_REGEX = new RegExp(
  `${YOUTUBE_BASE_REGEX_STRING}watch\\?v=(?<id>${YOUTUBE_ID_REGEX_STRING})`
);
const YOUTUBE_EMBED_REGEX = new RegExp(
  `${YOUTUBE_BASE_REGEX_STRING}embed\\/(?<id>${YOUTUBE_ID_REGEX_STRING})`
);
const YOUTUBE_SHORT_REGEX = new RegExp(
  `^https:\\/\\/(?:www\\.)?youtu\\.be\\/(?<id>${YOUTUBE_ID_REGEX_STRING})`
);

// loom - https://regex101.com/r/M15QcN and https://regex101.com/r/EP813L
const LOOM_BASE_REGEX_STRING = "^https:\\/\\/(?:www\\.)?loom\\.com\\/";
const LOOM_ID_REGEX_STRING = "[a-z0-9]+";
const LOOM_SHARE_REGEX = new RegExp(
  `${LOOM_BASE_REGEX_STRING}share\\/(?<id>${LOOM_ID_REGEX_STRING})`
);
const LOOM_EMBED_REGEX = new RegExp(
  `${LOOM_BASE_REGEX_STRING}embed\\/(?<id>${LOOM_ID_REGEX_STRING})`
);

// vimeo - https://regex101.com/r/RWTKEQ
const VIMEO_BASE_REGEX_STRING =
  "^https:\\/\\/(?:www\\.)?(?:player\\.)?vimeo\\.com\\/";
const VIMEO_ID_REGEX_STRING = "[0-9]+";
const VIMEO_CHANNEL_REGEX_STRING = `channels\\/\\S+\\/(?<idChannel>${VIMEO_ID_REGEX_STRING})`;
const VIMEO_GROUPS_REGEX_STRING = `groups\\/\\S+\\/(?<idGroup>${VIMEO_ID_REGEX_STRING})`;
const VIMEO_EMBED_REGEX_STRING = `video\\/(?<idEmbed>${VIMEO_ID_REGEX_STRING})`;
const VIMEO_SHORT_REGEX_STRING = `(?<idShort>${VIMEO_ID_REGEX_STRING})`;
const VIMEO_ALL_REGEX_STRING = `(?:${[
  VIMEO_CHANNEL_REGEX_STRING,
  VIMEO_GROUPS_REGEX_STRING,
  VIMEO_EMBED_REGEX_STRING,
  VIMEO_SHORT_REGEX_STRING,
].join("|")})`;
const VIMEO_REGEX = new RegExp(
  `${VIMEO_BASE_REGEX_STRING}${VIMEO_ALL_REGEX_STRING}`
);

// extract from url
// ----------------

export function extractEmbedDataFromUrl(url: string): EmbedData | undefined {
  // youtube
  {
    const id = url.match(YOUTUBE_WATCH_REGEX)?.groups?.id;
    if (id) return { type: "youtube", id };
  }
  {
    const id = url.match(YOUTUBE_EMBED_REGEX)?.groups?.id;
    if (id) return { type: "youtube", id };
  }
  {
    const id = url.match(YOUTUBE_SHORT_REGEX)?.groups?.id;
    if (id) return { type: "youtube", id };
  }

  // loom
  {
    const id = url.match(LOOM_SHARE_REGEX)?.groups?.id;
    if (id) return { type: "loom", id };
  }
  {
    const id = url.match(LOOM_EMBED_REGEX)?.groups?.id;
    if (id) return { type: "loom", id };
  }

  // vimeo
  {
    const { idChannel, idGroup, idEmbed, idShort } =
      url.match(VIMEO_REGEX)?.groups ?? {};
    const id = idChannel ?? idGroup ?? idEmbed ?? idShort;
    if (id) return { type: "vimeo", id };
  }

  return undefined;
}

// create url
// ----------

export function createEmbedUrl({ type, id }: EmbedData): string {
  switch (type) {
    case "youtube":
      return `https://www.youtube.com/embed/${id}`;
    case "loom":
      return `https://www.loom.com/embed/${id}`;
    case "vimeo":
      return `https://player.vimeo.com/video/${id}`;
    default:
      throw new Error("Unknown embed type");
  }
}

export function createOriginalUrl({ type, id }: EmbedData): string {
  switch (type) {
    case "youtube":
      return `https://www.youtube.com/watch?v=${id}`;
    case "loom":
      return `https://www.loom.com/share/${id}`;
    case "vimeo":
      return `https://vimeo.com/${id}`;
    default:
      throw new Error("Unknown embed type");
  }
}

// embedded video
// --------------

type EmbeddedVideoProps = {
  embedData: EmbedData;
};

export function EmbeddedVideo({ embedData }: EmbeddedVideoProps) {
  const embedUrl = createEmbedUrl(embedData);

  const sharedProps = {
    className: "embed",
    src: embedUrl,
    frameBorder: 0,
    allowFullScreen: true,
  };

  switch (embedData.type) {
    case "youtube":
      return (
        <iframe
          {...sharedProps}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      );
    case "loom":
      return <iframe {...sharedProps} title="Loom video player" />;
    case "vimeo":
      return (
        <iframe
          {...sharedProps}
          title="Vimeo video player"
          allow="autoplay; fullscreen; picture-in-picture"
        />
      );
    default:
      throw new Error("Unknown embed type");
  }
}
