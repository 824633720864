import {
  AtlasContentEditorProps,
  AtlasContentEditorSerializedState,
  useContentEditor,
} from "@resource/atlas/content-editor";
import { $getRoot } from "lexical";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { getCommentEditorHasContent } from "shared/utils/lexical";

export type CommentFormData = {
  message: AtlasContentEditorSerializedState;
};

type UseCommentStateProps = {
  onSubmit: (data: CommentFormData) => Promise<void>;
};

export function useCommentState({
  onSubmit: passedOnSubmit,
}: UseCommentStateProps) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showCommentButton, setShowCommentButton] = useState(false);
  const form = useForm<CommentFormData>();

  const { editor, contentEditorProps: defaultContentEditorProps } =
    useContentEditor();
  const contentEditorProps: AtlasContentEditorProps = useMemo(
    () => ({
      ...defaultContentEditorProps,
      onFocus: () => setShowCommentButton(true),
      onChange: (value) => {
        form.setValue("message", value.toJSON(), {
          shouldDirty: true,
          shouldValidate: true,
        });
      },
    }),
    [defaultContentEditorProps, form]
  );

  const resetEditorState = useCallback(() => {
    if (!editor) return;

    editor.update(() => {
      $getRoot().clear();
    });
  }, [editor]);

  const reset = useCallback(() => {
    form.reset();
    resetEditorState();
  }, [form, resetEditorState]);

  const onSubmit = useCallback(
    async (data: CommentFormData) => {
      if (!getCommentEditorHasContent(data.message)) return;

      setSubmitLoading(true);

      await passedOnSubmit(data);

      setSubmitLoading(false);
      reset();
    },
    [passedOnSubmit, reset]
  );

  return useMemo(
    () => ({
      editor,
      contentEditorProps,
      form,
      showCommentButton,
      submitLoading,
      onSubmit: form.handleSubmit(onSubmit),
    }),
    [
      editor,
      contentEditorProps,
      form,
      showCommentButton,
      submitLoading,
      onSubmit,
    ]
  );
}

export type CommentFooterState = ReturnType<typeof useCommentState>;
