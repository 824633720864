import { useComboboxStore } from "@ariakit/react";
import { Combobox } from "@resource/atlas/combobox-v2";
import { ComboboxWithTabs } from "@resource/atlas/combobox-with-tabs";
import { Icon } from "@resource/atlas/icon/Icon";
import { atlasPersonSparkle } from "@resource/atlas/icons";
import { LoadingIndicator } from "@resource/atlas/loading-indicator/LoadingIndicator";
import { Tabs } from "@resource/atlas/tabs-v2";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import { matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import useQuery from "utils/useQuery";

import { PoolItem } from "../../PoolItem";
import { getLoadCalculationsDateFromFormData } from "../hooks/interviewer-scheduling-data";
import { useSelectUserMembershipsWithSchedulingData } from "../hooks/useSelectUserMembershipsWithSchedulingData";
import {
  FormDataForSlotCalculations,
  InterviewerPool,
  UserMembershipForForm,
  UserMembershipWithSchedulingData,
} from "../utils/types";
import { SelectedInterviewerDisplay } from "./SelectedInterviewerDisplay";

type Interviewer = UserMembershipForForm;

export interface InterviewerSelectProps {
  onSelectUser?: (interviewer: Interviewer) => void;
  onSelectPool?: (interviewerPool: InterviewerPool) => void;
  onSelectSmartMatch?: () => void;
}

const FETCH_INTERVIEWERS = gql(`
  query FetchInterviewers($search: String, $limit: Int, $poolLimit: Int, $singlePoolId: ID = null) {
    userMemberships(search: $search, limit: $limit) {
      ...InterviewerForUserTagSelection
    }
    currentOrganization {
      id
      interviewerPools(search: $search, limit: $poolLimit, orderBy: [{ name: asc }]) {
        ...InterviewerPoolForTagPicker
      }
    }
  }
`);

function SmartMatchSection({ onClick }: { onClick: () => void }) {
  return (
    <Combobox.Item
      onClick={onClick}
      className="tours__interviewer-tags__smart-match-option"
      value="smart-match"
      size="compact"
      setValueOnClick={false}
    >
      <div className="flex items-start gap-2 overflow-hidden w-full">
        <div className="w-5 h-5 rounded-full bg-purple-50 flex items-center justify-center shrink-0">
          <Icon
            content={atlasPersonSparkle}
            className="w-3 h-3 text-purple-500"
          />
        </div>
        <div className="min-w-0">
          <div className="text-dark text-body-md">Smart Match</div>
          <p className="text-subtle text-body-sm whitespace-normal">
            Automatically match interviewers with certain tags
          </p>
        </div>
      </div>
    </Combobox.Item>
  );
}

interface InterviewerSelectDisplayProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  loading: boolean;
  matches: {
    interviewers: UserMembershipWithSchedulingData[];
    pools: InterviewerPool[];
  };
  onSelect?: (interviewer: UserMembershipWithSchedulingData) => void;
  onSelectPool?: (pool: InterviewerPool) => void;
  onSelectSmartMatch?: () => void;
  hideAvailability?: boolean;
  error?: Error;
}

function InterviewerSelectDisplay({
  searchValue,
  setSearchValue,
  selectedTab,
  setSelectedTab,
  loading,
  matches,
  onSelect,
  onSelectPool,
  onSelectSmartMatch,
  hideAvailability,
  error,
}: InterviewerSelectDisplayProps) {
  const store = useComboboxStore();

  const peopleContent = useMemo(
    () => (
      <Combobox.Group>
        {matches.interviewers.map((interviewer) => (
          <Combobox.Item
            key={interviewer.id}
            value={interviewer.id}
            setValueOnClick={false}
            size="compact"
            onClick={() => onSelect?.(interviewer)}
          >
            <SelectedInterviewerDisplay
              hideShadowingLabel
              hideAvailabilityIcon={hideAvailability}
              interviewer={interviewer}
              size="small"
            />
          </Combobox.Item>
        ))}
      </Combobox.Group>
    ),
    [matches.interviewers, onSelect, hideAvailability]
  );

  const smartMatchContent = useMemo(
    () =>
      !searchValue && (
        <Combobox.Group>
          <SmartMatchSection
            onClick={() => {
              onSelectSmartMatch?.();
            }}
          />
        </Combobox.Group>
      ),
    [onSelectSmartMatch, searchValue]
  );

  const poolsContent = useMemo(
    () => (
      <>
        {matches.pools.map((pool) => (
          <PoolItem
            key={pool.id}
            pool={pool}
            onSelect={() => onSelectPool?.(pool)}
            value={pool.id}
          />
        ))}
      </>
    ),
    [matches.pools, onSelectPool]
  );

  const noResults =
    !loading &&
    !error &&
    ((selectedTab === "people" && matches.interviewers.length === 0) ||
      (selectedTab === "training" && matches.pools.length === 0));

  return (
    <ComboboxWithTabs.Root
      store={store}
      value={searchValue}
      setValue={setSearchValue}
      defaultTabId={selectedTab}
      onTabChange={setSelectedTab}
      onSearch={setSearchValue}
    >
      <div className="relative w-full">
        <Combobox.Combobox
          placeholder="Add interviewer..."
          className="tours__interviewer-tags__interviewer-select-combobox"
        />

        <ComboboxWithTabs.Popover
          modal
          gutter={4}
          sameWidth
          fitViewport
          className={clsx(
            "h-[26rem] gap-2 tours__interviewer-tags__interviewer-select-popover"
          )}
        >
          <Tabs.List
            className="sticky shrink-0 top-0 z-10 flex-none bg-white"
            variant="pill"
          >
            <Tabs.Tab id="people">People</Tabs.Tab>
            <Tabs.Tab
              id="training"
              className="tours__interviewer-tags__training-paths-tab"
            >
              Training paths
            </Tabs.Tab>
          </Tabs.List>

          <ComboboxWithTabs.Panel tabId={selectedTab}>
            {selectedTab === "people" && smartMatchContent}
            {loading ? (
              <div className="flex justify-center p-4">
                <LoadingIndicator size="medium" />
              </div>
            ) : (
              <>
                {error ? (
                  <div className="flex flex-col items-center justify-center p-4 text-center">
                    <p className="text-body-md text-dark">
                      Something went wrong
                    </p>
                  </div>
                ) : (
                  <>
                    {noResults ? (
                      <div className="flex flex-col items-center justify-center p-4 text-center">
                        <p className="text-body-md text-dark">
                          No results found
                        </p>
                        <p className="text-body-sm text-subtle">
                          Try adjusting your search or filters
                        </p>
                      </div>
                    ) : (
                      <>
                        {selectedTab === "people"
                          ? peopleContent
                          : poolsContent}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </ComboboxWithTabs.Panel>
        </ComboboxWithTabs.Popover>
      </div>
    </ComboboxWithTabs.Root>
  );
}

export function InterviewerSelect({
  onSelectUser: onSelect,
  onSelectSmartMatch,
  onSelectPool,
}: InterviewerSelectProps) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedTab, setSelectedTab] = useState("people");

  const { data, loading, error } = useQuery(FETCH_INTERVIEWERS, {
    variables: {
      search: searchValue,
      limit: 25,
      poolLimit: 25,
      singlePoolId: null,
    },
  });

  const matches = useMemo(() => {
    if (!data) return { interviewers: [], pools: [] };

    const interviewers = data.userMemberships || [];
    const pools = data.currentOrganization?.interviewerPools || [];

    return {
      interviewers: searchValue
        ? matchSorter(interviewers, searchValue, {
            keys: ["name", "email"],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : interviewers,
      pools: searchValue
        ? matchSorter(pools, searchValue, {
            keys: ["name"],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : pools,
    };
  }, [data, searchValue]);

  return (
    <InterviewerSelectDisplay
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      loading={loading}
      error={error}
      matches={matches}
      onSelect={onSelect}
      onSelectPool={onSelectPool}
      onSelectSmartMatch={onSelectSmartMatch}
      hideAvailability
    />
  );
}

const FETCH_POOLS = gql(`
  query FetchPoolsForInterviewerSelect($search: String, $poolLimit: Int) {
    currentOrganization {
      id
      interviewerPools(search: $search, limit: $poolLimit, orderBy: [{ name: asc }]) {
        ...InterviewerPoolForTagPicker
      }
    }
  }
`);

export function InterviewerSelectWithSchedulingData({
  onSelectUser: onSelect,
  onSelectSmartMatch,
  onSelectPool,
  formDataForScheduling,
  excludeUserMembershipIds,
}: InterviewerSelectProps & {
  formDataForScheduling: FormDataForSlotCalculations;
  excludeUserMembershipIds?: string[];
}) {
  const [searchValue, setSearchValue] = useState("");
  const [selectedTab, setSelectedTab] = useState("people");

  const {
    data: poolData,
    loading: poolDataLoading,
    error: poolError,
  } = useQuery(FETCH_POOLS, {
    variables: {
      search: searchValue,
      poolLimit: 25,
    },
  });
  const pools = useMemo(
    () => poolData?.currentOrganization?.interviewerPools || [],
    [poolData]
  );

  const { selectedInterview, interviews, originalInterviews } =
    formDataForScheduling;

  const { date, includeDayLoad } = getLoadCalculationsDateFromFormData({
    formData: formDataForScheduling,
    interview: selectedInterview,
  });

  const { interviewers, loadingData } =
    useSelectUserMembershipsWithSchedulingData({
      guideId: formDataForScheduling.guideId,
      excludeUserMembershipIds,
      search: searchValue,
      interviews,
      originalInterviews,
      selectedInterview,
      date,
      includeDayLoad,
    });

  const matches = useMemo(() => {
    return {
      interviewers: searchValue
        ? matchSorter(interviewers, searchValue, {
            keys: ["name"],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : interviewers,
      pools: searchValue
        ? matchSorter(pools, searchValue, {
            keys: ["name"],
            threshold: matchSorter.rankings.CONTAINS,
          })
        : pools,
    };
  }, [interviewers, pools, searchValue]);

  return (
    <InterviewerSelectDisplay
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      loading={loadingData || poolDataLoading}
      error={poolError}
      matches={matches}
      onSelect={onSelect}
      onSelectPool={onSelectPool}
      onSelectSmartMatch={onSelectSmartMatch}
    />
  );
}

InterviewerSelect.storyData = {
  query: FETCH_INTERVIEWERS,
};
