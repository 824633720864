import React, { DetailedHTMLProps, TableHTMLAttributes } from "react";

export function Table(
  props: DetailedHTMLProps<
    TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >
) {
  return (
    <table cellPadding={0} cellSpacing={0} role="presentation" {...props} />
  );
}
