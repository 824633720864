import { useFetchedInterviewerCalendarEventsFlat } from "client/calendar-events/hooks/useFetchedInterviewerCalendarEvents";
import { mapFetchedEventToInterviewerCalendarEvent } from "client/calendar-events/utils/mapping";
import { getDefaultInterviewerCalendarColors } from "client/components/calendar-v2/utils/colors";
import { CalendarEventInput } from "client/components/calendar-v2/utils/types";
import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";

import { SelfScheduleCalendarLayers } from "../utils/layers";

const interviewersForCalendarColorsAtom = atom<string[]>([]);

/**
 * Hook to manage interviewer calendar events including fetching, filtering, and color assignment
 * Maintains a global list of interviewer IDs to ensure consistent color assignment
 *
 * @param selectedInterviewer - The currently selected interviewer
 * @param startTime - Start of the calendar range
 * @param endTime - End of the calendar range
 * @returns Object containing events, loading state, refetch function, and calendar colors
 */
export function useInterviewerEvents({
  selectedInterviewer,
  startTime,
  endTime,
}: {
  selectedInterviewer: {
    id: string;
    name: string;
  };
  startTime: DateTime;
  endTime: DateTime;
}) {
  const [interviewersForCalendarColors, setInterviewersForCalendarColors] =
    useAtom(interviewersForCalendarColorsAtom);

  // Add current interviewer to global list if not present
  useEffect(() => {
    setInterviewersForCalendarColors((prev) => {
      if (!prev.includes(selectedInterviewer.id)) {
        return [...prev, selectedInterviewer.id];
      }
      return prev;
    });
  }, [selectedInterviewer.id, setInterviewersForCalendarColors]);

  const {
    calendarEvents: interviewerEvents,
    loading: interviewerEventsLoading,
    refetch: refetchInterviewerEvents,
  } = useFetchedInterviewerCalendarEventsFlat({
    userMembershipIds: [selectedInterviewer.id],
    startTime,
    endTime,
  });

  // Filter events to only show current interviewer's events
  const filteredInterviewerEvents = useMemo(() => {
    return interviewerEvents.filter((e) => {
      return e.calendarId === selectedInterviewer.id;
    });
  }, [interviewerEvents, selectedInterviewer.id]);

  // Map events to calendar format with colors
  const mappedInterviewerEvents = useMemo(() => {
    return filteredInterviewerEvents.map(
      (e): CalendarEventInput => ({
        ...mapFetchedEventToInterviewerCalendarEvent(e, {
          layer: SelfScheduleCalendarLayers.AVAILABILITY_AND_SLOTS,
        }),
      })
    );
  }, [filteredInterviewerEvents]);

  // Generate calendar colors config
  const calendarColors = useMemo(() => {
    return getDefaultInterviewerCalendarColors(interviewersForCalendarColors);
  }, [interviewersForCalendarColors]);

  return {
    events: mappedInterviewerEvents,
    loading: interviewerEventsLoading,
    refetch: refetchInterviewerEvents,
    calendarColors,
  };
}
