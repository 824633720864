/**
 * Calendar refs that can be used to manage the calendar's elements.
 */
import { PopoverStore } from "@resource/atlas/popover-v2/Popover";

import { useCalendarContext } from "../utils/context";

export type HoveringEventInfo = {
  id: string;
  calendarId: string | undefined;
} | null;

export type FocusedEventInfo = {
  id: string;
  calendarId: string | undefined;
} | null;

export type CalendarRefs = {
  wrapper: HTMLDivElement | null;
  eventsView: HTMLOListElement | null;
  header: HTMLDivElement | null;
  hoveringTriggerElement: HTMLDivElement | null;
  eventDetailsPopoverTriggerElement: HTMLDivElement | null;
  eventDetailsPopoverStore: PopoverStore;
  hoveringEventInfo: HoveringEventInfo | null;
  focusedEventInfo: FocusedEventInfo;
  setWrapper: (ref: HTMLDivElement | null) => void;
  setEventsView: (ref: HTMLOListElement | null) => void;
  setHeader: (ref: HTMLDivElement | null) => void;
  setHoveringTriggerElement: (ref: HTMLDivElement | null) => void;
  setEventDetailsPopoverTriggerElement: (ref: HTMLDivElement | null) => void;
  setHoveringEventInfo: (info: HoveringEventInfo | null) => void;
  setFocusedEventInfo: (info: FocusedEventInfo) => void;
};

export function useWrapperRef() {
  const { wrapper } = useCalendarContext();
  return wrapper;
}

export function useSetWrapperRef() {
  const { setWrapper } = useCalendarContext();
  return setWrapper;
}

export function useEventsViewRef() {
  const { eventsView } = useCalendarContext();
  return eventsView;
}

export function useSetEventsViewRef() {
  const { setEventsView } = useCalendarContext();
  return setEventsView;
}

export function useHeaderRef() {
  const { header } = useCalendarContext();
  return header;
}

export function useSetHeaderRef() {
  const { setHeader } = useCalendarContext();
  return setHeader;
}

export function useHoveringTriggerElement() {
  const { hoveringTriggerElement } = useCalendarContext();
  return hoveringTriggerElement;
}

export function useSetHoveringTriggerElement() {
  const { setHoveringTriggerElement } = useCalendarContext();
  return setHoveringTriggerElement;
}

export function useSetEventDetailsPopoverTriggerElement() {
  const { setEventDetailsPopoverTriggerElement } = useCalendarContext();
  return setEventDetailsPopoverTriggerElement;
}

export function useEventDetailsPopoverStore() {
  const { eventDetailsPopoverStore } = useCalendarContext();

  return eventDetailsPopoverStore;
}

export function useHoveringEventInfo() {
  const { hoveringEventInfo } = useCalendarContext();
  return hoveringEventInfo;
}

export function useSetHoveringEventInfo() {
  const { setHoveringEventInfo } = useCalendarContext();
  return setHoveringEventInfo;
}

export function useFocusedEventInfo() {
  const { focusedEventInfo } = useCalendarContext();
  return focusedEventInfo;
}

export function useSetFocusedEventInfo() {
  const { setFocusedEventInfo } = useCalendarContext();
  return setFocusedEventInfo;
}
