import { uniq } from "lodash";
import { RICH_BLOCK_NAME as GUIDE_AVAILABILITY_REQUEST_RICH_BLOCK_NAME } from "shared/guide-availability/rich-blocks/guide-availability-request";
import { RICH_BLOCK_NAME as GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME } from "shared/guide-availability/rich-blocks/guide-availability-submission";
import { RICH_BLOCK_NAME as INTERVIEWS_RICH_BLOCK_NAME } from "shared/guide-content/rich-blocks/interviews";
import { RICH_BLOCK_NAME as SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME } from "shared/self-schedule/rich-blocks/self-schedule-request";

export {
  GUIDE_AVAILABILITY_REQUEST_RICH_BLOCK_NAME,
  GUIDE_AVAILABILITY_SUBMISSION_RICH_BLOCK_NAME,
  INTERVIEWS_RICH_BLOCK_NAME,
  SELF_SCHEDULE_REQUEST_RICH_BLOCK_NAME,
};

// VariableKey[] is used to build the sets required for lexical rendering. Adds type safety and makes it easy to create
// or extend variable sets. We cannot type these as VariableKey[] though because that will cause Typescript to think that
// all possible keys are required.
export const guideBaseVariableKeys = [
  "atsCoordinatorJobTitle",
  "atsCoordinatorName",
  "atsRecruiterJobTitle",
  "atsRecruiterName",
  "candidateName",
  "candidatePhone",
  "companyName",
  "firstInterview",
  "firstInterviewFirstInterviewerName",
  "firstInterviewFirstInterviewerTitle",
  "firstInterviewInterviewers",
  "guideLink",
  "hiringManagerJobTitle",
  "hiringManagerName",
  "interviewsInterviewers",
  "interviewsTimeRange",
  "jobListingLink",
  "jobTitle",
  "lastInterview",
  "lastInterviewFirstInterviewerName",
  "lastInterviewFirstInterviewerTitle",
  "lastInterviewInterviewers",
] as const;

export const guideUpdateVariableKeys = [
  ...guideBaseVariableKeys,
  "authorName",
] as const;

export const scheduledInterviewDetailsVariableKeys = [
  "atsCoordinatorJobTitle",
  "atsCoordinatorName",
  "atsRecruiterJobTitle",
  "atsRecruiterName",
  "candidateEmail",
  "candidateLinkedIn",
  "candidateName",
  "candidatePhone",
  "collaborativeCodingUrl",
  "companyName",
  "conferencePhone",
  "guideLink",
  "hiringManagerJobTitle",
  "hiringManagerName",
  "interviewersList",
  "interviewKit",
  "interviewName",
  "interviewTime",
  "jobTitle",
  "linkToJobDescription",
  "schedulerName",
  "videoConferencingUrl",
] as const;

export const interviewVariableKeys = [
  "atsCoordinatorJobTitle",
  "atsCoordinatorName",
  "atsRecruiterJobTitle",
  "atsRecruiterName",
  "candidateName",
  "companyName",
  "firstInterviewerJobTitle",
  "firstInterviewerName",
  "hiringManagerJobTitle",
  "hiringManagerName",
  "interviewerNames",
  "jobListingLink",
  "jobTitle",
] as const;

export const completeVariableKeys = uniq([
  ...guideUpdateVariableKeys,
  ...scheduledInterviewDetailsVariableKeys,
  ...interviewVariableKeys,
]);
