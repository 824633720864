import { UserConfigurableAlgorithmSettings } from "shared/guide-scheduler/algorithm/utils/types";

export default function JobSettingsAlgorithmSettingsDisplay({
  algorithmSettings,
}: {
  algorithmSettings: UserConfigurableAlgorithmSettings;
}) {
  return (
    <div className="space-y-2">
      <div className="text-body-md-heavy">Scheduling preferences</div>
      <ul className="text-body-md list-inside list-disc space-y-2">
        {algorithmSettings?.numberOfDays && (
          <li>
            Spread across{" "}
            {algorithmSettings.numberOfDays.min ===
            algorithmSettings.numberOfDays.max
              ? `${algorithmSettings.numberOfDays.min} ${
                  algorithmSettings.numberOfDays.min === 1 ? "day" : "days"
                }`
              : `${algorithmSettings.numberOfDays.min} \u2013 ${
                  algorithmSettings.numberOfDays.max
                } ${
                  algorithmSettings.numberOfDays.max === 1 ? "day" : "days"
                }`}{" "}
          </li>
        )}
        {algorithmSettings?.interviewsPerDayLimit && (
          <li>
            Limit per day:{" "}
            {algorithmSettings.interviewsPerDayLimit.min ===
            algorithmSettings.interviewsPerDayLimit.max
              ? `${algorithmSettings.interviewsPerDayLimit.min} ${
                  algorithmSettings.interviewsPerDayLimit.min === 1
                    ? "interview"
                    : "interviews"
                }`
              : `${algorithmSettings.interviewsPerDayLimit.min} \u2013 ${
                  algorithmSettings.interviewsPerDayLimit.max
                } ${
                  algorithmSettings.interviewsPerDayLimit.max === 1
                    ? "interview"
                    : "interviews"
                }`}
          </li>
        )}
      </ul>
    </div>
  );
}
