import { mapScheduledInterviewGroupSettingsToConferencingSlot } from "client/components/scheduled-interviews/ScheduledInterviewGroupSettingsForm/utils/mapping";
import { isStagedScheduledInterview } from "client/scheduler/utils/types";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { usePrevious } from "react-use";

import { useInterviews, useSetInterviews } from "./interviews";
import { useScheduledInterviewGroupSettings } from "./scheduledInterviewGroupSettings";

const groupSettingsInitializedAtom = atom(false);

export function useGroupSettingsInitialized() {
  return useAtomValue(groupSettingsInitializedAtom);
}

/**
 * Since we store the group settings on the interviews themselves,
 * we need to make sure that they are mapped with the group settings properly for display.
 * Already scheduled interviews should default to their existing settings, but unscheduled interviews
 * will not come from a group. If we come into the scheduler with existing group settings and unscheduled interviews,
 * we need to default the settings to be linked to the existing group. If we add a new unscheduled interview in the
 * scheduler, then we need to default the settings to be linked to the existing group.
 *
 * The "correct" solution would entail looking up at the group settings and only storing overrides on the model itself,
 * but for simplicity we are taking this approach for now.
 *
 * We also could properly map these from the group settings during hydration and when adding a new interview, but that
 * also is more technically complex and not sure if that's the right place to do it anyways as it adds logic to the mapping
 * layer.
 */
export function useSetGroupSettingsForUnscheduledInterviews() {
  const interviews = useInterviews();
  const setInterviews = useSetInterviews();
  const prevInterviewsForDiff = usePrevious(interviews);
  const groupSettings = useScheduledInterviewGroupSettings();
  const setGroupSettingsInitialized = useSetAtom(groupSettingsInitializedAtom);

  useEffect(() => {
    const currentInterviewIds = interviews.map((i) => i.id);
    const prevInterviewIds = prevInterviewsForDiff?.map((i) => i.id) ?? [];
    const newInterviewIds = currentInterviewIds.filter(
      (id) => !prevInterviewIds.includes(id)
    );

    if (
      groupSettings &&
      groupSettings?.reuseVideoConferencingLink &&
      newInterviewIds.length > 0
    ) {
      setInterviews((prevInterviews) => {
        return prevInterviews.map((interview) => {
          if (
            !isStagedScheduledInterview(interview) &&
            newInterviewIds.includes(interview.id)
          ) {
            const groupSettingsConferencingSlot =
              mapScheduledInterviewGroupSettingsToConferencingSlot({
                scheduledInterviewGroupSettings: groupSettings,
              });

            return {
              ...interview,
              conferencingSlot: {
                service: null, // default to null in case it's undefined
                isUsingGroupSettings: true,
                ...interview.conferencingSlot,
                ...groupSettingsConferencingSlot,
              },
            };
          }

          return interview;
        });
      });
    }

    setGroupSettingsInitialized(true);
  }, [
    groupSettings,
    interviews,
    prevInterviewsForDiff,
    setInterviews,
    setGroupSettingsInitialized,
  ]);
}
