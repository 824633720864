import { Icon } from "@resource/atlas/icon/Icon";
import { atlasCircleCross, atlasEye, atlasMail } from "@resource/atlas/icons";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import clsx from "clsx";
import { gql } from "generated/graphql-codegen";
import {
  GuideUpdatesCandidateFragment,
  ViewedIndicatorGuidePostFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { EmailEventType } from "types/emailEvent";

import { useUnreadMessages } from "../__utils/useUnreadMessages";

const VIEWED_INDICATOR_GUIDE_POST = gql(`
fragment ViewedIndicatorGuidePost on GuidePost {
    id
    isEmailTrackingEnabled
    lastCandidateEmailEvent {
      type
      happenedAt
      email
    }
    ...UseUnreadMessagesGuidePostMessage
  }
`);

type Meta = {
  label: string;
  tooltip: string;
  icon: string;
  textColor: string;
  iconColor: string;
} | null;

type ViewedIndicatorProps = ComponentPropsWithoutRef<"div"> & {
  update: ViewedIndicatorGuidePostFragment;
  candidate?: GuideUpdatesCandidateFragment;
  includeUnreadReplies?: boolean;
  showLabel?: boolean;
  iconSize?: "small" | "medium";
};

export function ViewedIndicator({
  update,
  candidate,
  includeUnreadReplies,
  showLabel = false,
  iconSize,
  ...props
}: ViewedIndicatorProps) {
  const unreadCount = useUnreadMessages(
    useMemo(() => [update], [update])
  ).length;

  const meta = useMemo(() => {
    let data: Meta = {
      label: `Sending to ${candidate?.name ?? "candidate"}`,
      tooltip: "Your message is queued to send",
      icon: atlasMail,
      textColor: "text-subtle",
      iconColor: "text-subtle",
    };
    const dateTimeFormat = "LLLL dd, yyyy 'at' h:mma";

    const { lastCandidateEmailEvent } =
      update.replies[update.replies.length - 1] ?? update;

    if (
      (includeUnreadReplies && !unreadCount) ||
      (!includeUnreadReplies && update.readAt)
    ) {
      let { readAt } = update;
      if (includeUnreadReplies) {
        readAt = update.replies.reduce((currentReadAt, reply) => {
          if (
            reply.readAt &&
            readAt &&
            DateTime.fromISO(reply.readAt).valueOf() >
              DateTime.fromISO(readAt).valueOf()
          ) {
            return reply.readAt;
          }
          return currentReadAt;
        }, readAt);
      }
      data.icon = atlasEye;
      data.iconColor = "text-blue-500";
      data.label = `Viewed by ${candidate?.name ?? "candidate"}`;
      data.tooltip = `Viewed ${
        showLabel ? "" : `by ${candidate?.name ?? "candidate"}`
      } on ${DateTime.fromISO(readAt!).toFormat(dateTimeFormat)}`;
    } else if (lastCandidateEmailEvent?.type === EmailEventType.BOUNCED) {
      data.label = `Bounced to ${candidate?.name ?? "candidate"}  (${
        lastCandidateEmailEvent.email
      })`;
      data.tooltip = `Bounced on ${DateTime.fromISO(
        lastCandidateEmailEvent.happenedAt
      ).toFormat(dateTimeFormat)}`;
      data.iconColor = "text-orange-500";
      data.icon = atlasCircleCross;
    } else if (lastCandidateEmailEvent?.type === EmailEventType.DROPPED) {
      data.label = `Dropped for ${candidate?.name ?? "candidate"} (${
        lastCandidateEmailEvent.email
      })`;
      data.tooltip = `Our email provider prevented send of this message on ${DateTime.fromISO(
        lastCandidateEmailEvent.happenedAt
      ).toFormat(dateTimeFormat)}`;
      data.iconColor = "text-orange-500";
      data.icon = atlasCircleCross;
    } else if (lastCandidateEmailEvent?.type === EmailEventType.DELIVERED) {
      data.label = `Delivered to ${candidate?.name ?? "candidate"}`;
      data.tooltip = `Unread. Delivered on ${DateTime.fromISO(
        lastCandidateEmailEvent.happenedAt
      ).toFormat(dateTimeFormat)}`;
    } else if (!update.isEmailTrackingEnabled) {
      data = null;
    }
    return data;
  }, [unreadCount, update, includeUnreadReplies, candidate, showLabel]);

  if (!meta) {
    return null;
  }

  return (
    <Tooltip isInstant content={meta.tooltip}>
      <div
        {...props}
        className={clsx(
          props.className,
          "flex justify-start items-center text-body-sm gap-1.5"
        )}
      >
        <Icon
          content={meta.icon}
          className={clsx(meta.iconColor, {
            "h-4 w-4": iconSize === "small",
            "h-5 w-5": iconSize !== "small",
          })}
        />
        {showLabel && (
          <span className={clsx(meta.textColor)}>{meta.label}</span>
        )}
      </div>
    </Tooltip>
  );
}

ViewedIndicator.fragments = {
  guidePost: VIEWED_INDICATOR_GUIDE_POST,
};
