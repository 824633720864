import { Dialog, DialogStore } from "@resource/atlas/dialog-v2/Dialog";
import TextArea from "@resource/atlas/textfield/TextArea";
import { View } from "@resource/atlas/view/View";
import { useLogEvent } from "analytics";
import { FormRightActions } from "client/components/generic/misc/FormRightActions";
import { gql } from "generated/graphql-codegen";
import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import useMutation from "utils/useMutation";

import { getDefaultCancellationMessage } from "./__utils/default-messaging";

const CANCEL_SELF_SCHEDULE_INTERVIEW = gql(`
  mutation CancelScheduledInterviewByCandidate($input: CancelScheduledInterviewsByCandidateV2Input!) {
    cancelScheduledInterviewsByCandidateV2(input: $input) {
      code
      message
      success
      scheduledInterviews {
        scheduledInterview {
          id
          ...ScheduledInterviewForScheduledInterviewCard
        }
      }
    }
  }
`);

/** Dialog for confirming the cancellation of a staff-scheduled interview */
export function CancelScheduledInterviewByCandidateConfirmationDialog({
  store,
  scheduledInterviewId,
  startTime,
}: {
  store: DialogStore;
  scheduledInterviewId: string;
  startTime: string;
}) {
  const logEvent = useLogEvent({
    component: "CancelScheduledInterviewByCandidateConfirmationDialog",
  });
  const [candidateCancelStaffScheduleInterview, { loading: submitLoading }] =
    useMutation(CANCEL_SELF_SCHEDULE_INTERVIEW, {
      onCompleted: (res) => {
        if (res.cancelScheduledInterviewsByCandidateV2.success) {
          store.hide();
        }
      },
    });
  const [message, setMessage] = useState(
    getDefaultCancellationMessage(DateTime.fromISO(startTime))
  );

  const onSubmit = useCallback(async () => {
    await candidateCancelStaffScheduleInterview({
      variables: {
        input: {
          deleteScheduledInterviews: [
            {
              scheduledInterviewId,
              interviewerConfirmation: {
                sendNotifications: true,
                note: message,
              },
            },
          ],
        },
      },
    });
    logEvent("Interview Cancelled", {
      isSelfScheduled: true,
    });
  }, [
    candidateCancelStaffScheduleInterview,
    logEvent,
    message,
    scheduledInterviewId,
  ]);

  const submitValidationContent = useMemo(() => {
    if (!message) {
      return "Please include a message";
    }
    return undefined;
  }, [message]);

  return (
    <Dialog store={store} size="small-plus">
      <View
        content={{
          className: "space-y-4",
          title: "Confirm interview cancellation",
          subTitle:
            "Are you certain you want to cancel your interview? Your interviewers and recruiter will be notified.",
        }}
        header={{
          hideBorder: true,
        }}
        footer={{
          hideBorder: true,
          rightActions: (
            <FormRightActions
              disabledTooltipContent={submitValidationContent}
              cancel={{
                children: "No, don't cancel",
                onClick: store.hide,
              }}
              save={{
                children: "Cancel interview",
                onClick: onSubmit,
                isLoading: submitLoading,
              }}
            />
          ),
        }}
      >
        <div className="space-y-2">
          <div className="text-body-md-heavy">Message</div>
          <TextArea
            value={message}
            onChange={setMessage}
            className="h-60"
            inputClassName="h-60"
            placeholder="Briefly explain your reason for cancelling."
          />
        </div>
      </View>
    </Dialog>
  );
}
