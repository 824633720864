/* eslint-disable import/prefer-default-export */
import { createModule } from "../../__utils/modules";
import { MentionNode } from "./MentionNode";
import { MentionPlugin } from "./MentionPlugin";

export const mentionsModule = createModule("mentions", {
  declareModule() {
    return {
      nodes: [MentionNode],
      plugins: [<MentionPlugin />],
    };
  },
});
