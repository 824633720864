import {
  InterviewerSlot,
  InterviewForSlotCalculations,
} from "client/components/interviewer-slots/utils/types";
import {
  InterviewerPoolForLoadFragment,
  UserMembershipForLoadFragment,
} from "generated/graphql-codegen/graphql";
import { DateTime } from "luxon";

import { LOAD_INTERVALS } from "./constants";
import {
  InterviewLoad,
  InterviewLoadData,
  LoadInterval,
  LoadLimits,
} from "./types";

type MapUserMembershipForLoadFragmentToLoadDataParams = {
  userMembership: UserMembershipForLoadFragment;
  date: string;
  interviews: InterviewForSlotCalculations[];
  originalInterviews: InterviewForSlotCalculations[];
  selectedInterview: InterviewForSlotCalculations | null;
  poolData?: InterviewerPoolForLoadFragment;
  includeSelectedInterview: boolean;
};

/** Takes a UserMembershipForLoadFragment and returns the calculated load data */
export function getLoadDataForUserMembership({
  userMembership,
  date: passedDate,
  interviews,
  originalInterviews,
  selectedInterview,
  poolData,
  includeSelectedInterview,
}: MapUserMembershipForLoadFragmentToLoadDataParams): InterviewLoadData {
  // The reference date should be in the scheduling time zone.
  const date = DateTime.fromISO(passedDate, { setZone: true });

  const interviewLoad = calculateInterviewLoad({
    date,
    userMembershipId: userMembership.id,
    interviewsForLoadCalculation:
      userMembership.interviewsForLoadCalculation.map(({ startAt }) => ({
        startTime: startAt,
      })),
    interviews,
    originalInterviews,
    selectedInterview,
    includeSelectedInterview,
  });

  const interviewLoadForPool =
    poolData?.id && userMembership.poolInterviewsForLoadCalculation
      ? calculateInterviewLoad({
          date,
          userMembershipId: userMembership.id,
          poolId: poolData.id,
          interviewsForLoadCalculation:
            userMembership.poolInterviewsForLoadCalculation.map(
              ({ startAt }) => ({
                startTime: startAt,
              })
            ),
          interviews,
          originalInterviews,
          selectedInterview,
          includeSelectedInterview,
        })
      : null;

  const loadLimitsForPool = poolData?.loadLimits
    ? {
        day: poolData.loadLimits.day.enabled
          ? poolData.loadLimits.day.limit
          : null,
        week: poolData.loadLimits.week.enabled
          ? poolData.loadLimits.week.limit
          : null,
        month: poolData.loadLimits.month.enabled
          ? poolData.loadLimits.month.limit
          : null,
        overall: poolData.loadLimits.overall.enabled
          ? poolData.loadLimits.overall.limit
          : null,
      }
    : null;

  const ignoreAccountLoadLimits = poolData?.ignoreAccountLoadLimits ?? false;

  return {
    loadLimits: {
      day: userMembership.maxInterviewLoadPerDay,
      week: userMembership.maxInterviewLoadPerWeek,
      month: null,
      overall: null,
    },
    interviewLoad,
    loadLimitsForPool,
    interviewLoadForPool,
    ignoreAccountLoadLimits,
  };
}

type GetInterviewerOverloadedIntervalsProps = {
  loadLimits: LoadLimits;
  interviewLoad: InterviewLoad;
  /**
   * If the user is currently selected, then being "at load" would be valid because their counts include the current interview.
   * If they have not been selected, then being "at load" would be invalid since selecting them would put them over load.
   */
  hasBeenSelected: boolean;
};

function getOverloadedIntervals({
  loadLimits,
  interviewLoad,
  hasBeenSelected,
}: GetInterviewerOverloadedIntervalsProps): LoadInterval[] {
  return LOAD_INTERVALS.filter((interval) => {
    if (!loadLimits[interval] || !interviewLoad[interval]) {
      return false;
    }

    if (hasBeenSelected) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return interviewLoad[interval] > loadLimits[interval]!;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return interviewLoad[interval] >= loadLimits[interval]!;
  });
}

type InterviewerOverloadedData = {
  accountOverloads: LoadInterval[];
  poolOverloads?: LoadInterval[];
};

export type GetInterviewerOverloadedDataProps = {
  loadData: InterviewLoadData;
  hasBeenSelected: GetInterviewerOverloadedIntervalsProps["hasBeenSelected"];
};

export function getInterviewerOverloadedData({
  loadData,
  hasBeenSelected,
}: GetInterviewerOverloadedDataProps): InterviewerOverloadedData {
  const {
    loadLimits,
    interviewLoad,
    loadLimitsForPool,
    interviewLoadForPool,
    ignoreAccountLoadLimits,
  } = loadData;

  const accountOverloadedIntervals = !ignoreAccountLoadLimits
    ? getOverloadedIntervals({
        loadLimits,
        interviewLoad,
        hasBeenSelected,
      })
    : [];
  const poolOverloadedIntervals =
    loadLimitsForPool && interviewLoadForPool
      ? getOverloadedIntervals({
          loadLimits: loadLimitsForPool,
          interviewLoad: interviewLoadForPool,
          hasBeenSelected,
        })
      : [];

  return {
    accountOverloads: accountOverloadedIntervals,
    poolOverloads: poolOverloadedIntervals,
  };
}

type ShouldInterviewCountTowardsLoadProps = {
  interviewerSlots: InterviewerSlot[];
  userMembershipId: string;
  poolId?: string;
};

function shouldInterviewCountTowardsLoad({
  interviewerSlots,
  userMembershipId,
  poolId,
}: ShouldInterviewCountTowardsLoadProps) {
  if (poolId) {
    // If checking for pool load, only check non-shadowers of that pool.
    const poolInterviewerSlot = interviewerSlots.find(
      (interviewerSlot) =>
        interviewerSlot.type === "POOL" &&
        interviewerSlot.interviewer?.userMembership.id === userMembershipId
    );
    const relevantForPool = !!(
      poolId &&
      poolInterviewerSlot?.interviewerPoolsSetting?.some(
        (pool) => pool.id === poolId
      )
    );
    return relevantForPool;
  }

  // If not checking for pool load, just check if they are on the interview at all.
  return interviewerSlots.some(
    (slot) =>
      slot.interviewer?.userMembership.id === userMembershipId ||
      slot.shadowingInterviewer?.userMembership.id === userMembershipId
  );
}

type CalculateInterviewLoadParams = {
  date: DateTime;
  userMembershipId: string;
  poolId?: string;
  interviewsForLoadCalculation: {
    startTime: string;
  }[];
  interviews: InterviewForSlotCalculations[];
  originalInterviews: InterviewForSlotCalculations[];
  selectedInterview: InterviewForSlotCalculations | null;
  includeSelectedInterview: boolean;
};

/**
 * Calculates the interviewLoad for a specific date and set of interviews. Starts with the database load counts, then adjusts
 * for any interviews currently being scheduled or rescheduled.
 */
export function calculateInterviewLoad({
  date,
  userMembershipId,
  poolId,
  /** Existing scheduled interviews from the database: startTimes in UTC */
  interviewsForLoadCalculation,
  /** Interviews currently being scheduled, including the selectedInterview: startTimes in scheduling tz */
  interviews,
  /** Unmodified interviews currently being scheduled, including the selectedInterview: startTimes in scheduling tz */
  originalInterviews,
  /** The currently selected interview: startTime in scheduling tz */
  selectedInterview,
  includeSelectedInterview,
}: CalculateInterviewLoadParams) {
  // We need to calculate the load per day/week/month in the scheduling time zone, not UTC.
  const startOfDay = date.startOf("day");
  const startOfWeek = date.startOf("week");
  const startOfMonth = date.startOf("month");

  const localZone = date.zone;

  let day = 0;
  let week = 0;
  let month = 0;
  let overall = 0;

  interviewsForLoadCalculation.forEach((existingInterview) => {
    // Existing interviews come back from the database in UTC time; convert to local time zone so we can match
    // the startOf day/week/month correctly. All other interviews use the local time zone.
    const time = DateTime.fromISO(existingInterview.startTime).setZone(
      localZone
    );

    if (+time.startOf("day") === +startOfDay) {
      day += 1;
    }
    if (+time.startOf("week") === +startOfWeek) {
      week += 1;
    }
    if (+time.startOf("month") === +startOfMonth) {
      month += 1;
    }
    overall += 1;
  });

  // If any interviews are being rescheduled, avoid double counting them.
  // Decrement any relevant originalScheduledInterviews for this date; we will add them back later if still relevant.
  originalInterviews.forEach((originalInterview) => {
    const shouldCount = shouldInterviewCountTowardsLoad({
      interviewerSlots: originalInterview.interviewerSlots,
      userMembershipId,
      poolId,
    });

    if (!shouldCount) {
      return;
    }

    const time = originalInterview.startTime
      ? DateTime.fromISO(originalInterview.startTime).setZone(localZone)
      : null;

    if (!time) {
      return;
    }

    if (+time.startOf("day") === +startOfDay) {
      day -= 1;
    }
    if (+time.startOf("week") === +startOfWeek) {
      week -= 1;
    }
    if (+time.startOf("month") === +startOfMonth) {
      month -= 1;
    }
    overall -= 1;
  });

  // Now add back any relevant interviews for this interviewer for the rescheduled time.
  interviews.forEach((interview) => {
    // We always add back the selectedInterview, as the context is we're seeing their availability to be added
    // to this interview for this date (optionally for this poolId).
    if (
      includeSelectedInterview &&
      selectedInterview?.startTime &&
      interview.id === selectedInterview?.id
    ) {
      day += 1;
      week += 1;
      month += 1;
      overall += 1;
      return;
    }

    const shouldCount = shouldInterviewCountTowardsLoad({
      interviewerSlots: interview.interviewerSlots,
      userMembershipId,
      poolId,
    });

    if (!shouldCount) {
      return;
    }

    const time = interview.startTime
      ? DateTime.fromISO(interview.startTime).setZone(localZone)
      : null;

    if (!time) {
      return;
    }

    if (+time.startOf("day") === +startOfDay) {
      day += 1;
    }
    if (+time.startOf("week") === +startOfWeek) {
      week += 1;
    }
    if (+time.startOf("month") === +startOfMonth) {
      month += 1;
    }
    overall += 1;
  });

  return {
    day,
    week,
    month,
    overall,
  };
}
