import { Icon } from "@resource/atlas/icon/Icon";
import { atlasChevronDown } from "@resource/atlas/icons";
import { Menu } from "@resource/atlas/menu";
import { useMenuItems } from "@resource/atlas/menu/use-menu-items";
import clsx from "clsx";

import { getDurationDisplayText } from "./utils";

type EditableDurationProps = {
  currentDuration: number;
  onSelectDuration: (duration: number) => unknown;
  className?: string;
};

const DURATION_OPTIONS = [
  10, 15, 20, 30, 40, 45, 50, 60, 70, 75, 90, 120, 180, 240,
];

export function EditableDuration({
  currentDuration,
  onSelectDuration,
  className,
}: EditableDurationProps) {
  const durationMenuItems = useMenuItems(
    (i) => {
      return DURATION_OPTIONS.map((duration) => {
        return i.item({
          children: getDurationDisplayText(duration),
          value: getDurationDisplayText(duration),
          onSelect: () => {
            onSelectDuration(duration);
          },
        });
      });
    },
    [onSelectDuration]
  );

  return (
    <Menu.Root>
      <Menu.Trigger>
        <div
          className={clsx(
            "flex justify-between w-full bg-light-gray-500 rounded-md px-2 py-[10px] text-body-md cursor-pointer",
            className
          )}
        >
          {getDurationDisplayText(currentDuration)}
          <Icon content={atlasChevronDown} />
        </div>
      </Menu.Trigger>
      <Menu.Content items={durationMenuItems} />
    </Menu.Root>
  );
}
