import { Button as AriaButton } from "@ariakit/react";
import { Button } from "@resource/atlas/button/Button";
import { ButtonGroup } from "@resource/atlas/button/ButtonGroup";
import { Icon } from "@resource/atlas/icon/Icon";
import {
  atlasCircleChecked,
  atlasDoor,
  atlasDragVertical,
  atlasEllipsisHorizontal,
  atlasEyeOff,
  atlasLockClosed,
  atlasOrderAny,
  atlasPersonPlus,
  atlasRingCheck,
  atlasRingInfo,
  atlasTrash,
  serviceCoderpad,
  serviceCodesignal,
  serviceGoogleMeet,
  serviceGreenhouse,
  serviceGreenhouseDisabled,
  serviceHackerrank,
  serviceZoom,
} from "@resource/atlas/icons";
import { Link } from "@resource/atlas/link/Link";
import { Menu } from "@resource/atlas/menu";
import Tooltip from "@resource/atlas/tooltip/Tooltip";
import { InterviewTitle } from "client/components/guide/interviews/InterviewTitle/InterviewTitle";
import { InterviewerSlotsRequirementsList } from "client/components/interviewer-slots/display/InterviewerSlotsRequirementsList";
import { getDurationDisplayText } from "client/components/time/utils";
import { CypressData } from "client/cypress-data-keys";
import { getAtssyncInfo } from "client/utils/atssync";
import clsx from "clsx";
import StopPropagation from "components/StopPropagation";
import { CollaborativeCodingServiceType } from "generated/graphql-codegen/graphql";
import { HTMLAttributes, RefCallback } from "react";
import { ConferencingType } from "shared/guide-scheduler/conferencing/types";

import { useActionMenuItems } from "../../__hooks/useActionMenuItems";
import { useLockedLabel } from "../../__hooks/useLockedLabel";
import { PositionLockedIcon } from "../../PositionLockedIcon";
import { UnscheduledInterviewForCard } from "../utils/types";

export type UnscheduledInterviewCardProps = {
  unscheduledInterview: UnscheduledInterviewForCard;
  onRemove?: () => void;
  onEdit?: () => void;
  onClick?: () => void;
  setLocked?: (locked: boolean) => void;
  variant?: "default" | "compact";
  isActive?: boolean;
  isDraggable?: boolean;
  isDragOverlay?: boolean;
  dragListeners?: HTMLAttributes<HTMLElement>;
  dragAttributes?: HTMLAttributes<HTMLElement>;
  dragHandleRef?: RefCallback<HTMLElement>;
  disabled?: boolean;
  className?: string;
  showActionMenu?: boolean;
  showFulfillment?: {
    isFulfilled: boolean;
  };
};

export function UnscheduledInterviewCard(props: UnscheduledInterviewCardProps) {
  const {
    onEdit,
    onClick,
    onRemove,
    setLocked,
    unscheduledInterview,
    isActive,
    dragAttributes,
    dragHandleRef,
    dragListeners,
    isDraggable,
    className,
    disabled,
    variant,
    showActionMenu,
    showFulfillment,
  } = props;

  const isFulfilled = showFulfillment?.isFulfilled;
  const textClassName = isFulfilled ? "text-subtle" : undefined;

  const { isSchedulable } = unscheduledInterview;

  const actionMenuItems = useActionMenuItems({
    onEdit,
    onRemove,
    setLocked,
    unscheduledInterview,
  });

  const lockedLabel = useLockedLabel({
    interviewRequirement: unscheduledInterview,
    interviewRequirements: [],
  });

  const isCompact = variant === "compact";
  let paddingClass;
  if (isCompact) {
    paddingClass = isActive ? "p-[0.4375rem]" : "p-2";
  } else {
    paddingClass = isActive ? "p-[0.6875rem]" : "p-3";
  }

  const accountInfo = unscheduledInterview.atssyncType
    ? getAtssyncInfo(unscheduledInterview.atssyncType)
    : null;
  let icon;

  if (showFulfillment) {
    if (showFulfillment.isFulfilled) {
      icon = atlasCircleChecked;
    } else {
      icon = atlasRingCheck;
    }
  } else {
    icon = disabled
      ? accountInfo?.disabledIcon ?? serviceGreenhouseDisabled
      : accountInfo?.icon ?? serviceGreenhouse;
  }

  const schedulingValidationError =
    !isFulfilled && !isSchedulable
      ? unscheduledInterview.unschedulableErrorMessage ||
        "This interview cannot be scheduled in Guide."
      : null;

  return (
    <div
      data-cy={CypressData.schedulingTasks.unscheduledInterviews.card}
      {...(onClick
        ? {
            role: "button",
            tabIndex: 0,
            onClick,
          }
        : {})}
      className={clsx(
        "rounded-md w-full space-y-3",
        paddingClass,
        isActive ? "border-purple-500 border-2 " : "border-gray-border border ",
        {
          "hover:bg-light-gray-200 bg-white cursor-pointer": !!onClick,
          "text-subtle": disabled,
        },
        className
      )}
      {...dragListeners}
      {...dragAttributes}
      ref={dragHandleRef}
      data-id={unscheduledInterview.id}
    >
      <div className="flex items-start w-full overflow-hidden group gap-3">
        {isDraggable && (
          <div
            role="button"
            className={clsx(
              "rounded focus-visible:focus-ring-0",
              "group-hover:block text-subtle p-2 -mx-2 -my-2 self-stretch cursor-grab",
              "hidden"
              // { hidden: !isDragOverlay }
            )}
          >
            <Icon content={atlasDragVertical} />
          </div>
        )}
        <div className={clsx({ "group-hover:hidden": isDraggable })}>
          <Icon
            content={icon}
            className={clsx("text-disabled shrink-0 w-5 h-5 self-start", {
              "text-green-500": showFulfillment?.isFulfilled,
            })}
          />
        </div>
        <div className="flex-grow flex flex-col gap-2 overflow-hidden">
          <div className="flex flex-col gap-1 grow shrink min-w-0 justify-center truncate">
            <InterviewTitle
              className={clsx("truncate text-body-md-heavy", textClassName)}
              interview={unscheduledInterview}
            />
            <div className="flex gap-2 items-center">
              <p className="text-body-sm text-subtle">
                {getDurationDisplayText(unscheduledInterview.duration)}
              </p>
              {!isCompact && (
                <div className="space-x-0.5 flex flex-row">
                  {unscheduledInterview.conferencingRequirement?.service ===
                    ConferencingType.ZOOM && (
                    <Tooltip
                      // NOTE: Does not indicate requirement hostSetting, see ENG-4051
                      content="Zoom link required"
                      isInstant
                    >
                      <Icon content={serviceZoom} className="w-5 h-5" />
                    </Tooltip>
                  )}
                  {unscheduledInterview.conferencingRequirement?.service ===
                    ConferencingType.GOOGLE_MEET && (
                    <Tooltip content="Google meet link required" isInstant>
                      <Icon content={serviceGoogleMeet} className="w-5 h-5" />
                    </Tooltip>
                  )}
                  {unscheduledInterview.collaborativeCodingRequirement
                    ?.service === CollaborativeCodingServiceType.CODERPAD && (
                    <Tooltip content="Coderpad link required" isInstant>
                      <Icon content={serviceCoderpad} className="w-5 h-5" />
                    </Tooltip>
                  )}
                  {unscheduledInterview.collaborativeCodingRequirement
                    ?.service === CollaborativeCodingServiceType.HACKERRANK && (
                    <Tooltip content="HackerRank link required" isInstant>
                      <Icon content={serviceHackerrank} className="w-5 h-5" />
                    </Tooltip>
                  )}
                  {unscheduledInterview.collaborativeCodingRequirement
                    ?.service === CollaborativeCodingServiceType.CODESIGNAL && (
                    <Tooltip content="CodeSignal link required" isInstant>
                      <Icon content={serviceCodesignal} className="w-5 h-5" />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <ButtonGroup size="xs">
          {unscheduledInterview.isPrivate && (
            <Tooltip content="Interview set to private" isInstant>
              <div className="flex items-center justify-center w-6 h-6">
                <Icon content={atlasLockClosed} className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
          {unscheduledInterview.blacklisted && (
            <Tooltip content="Interview not visible to candidate" isInstant>
              <div className="flex items-center justify-center w-6 h-6">
                <Icon content={atlasEyeOff} className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
          {isDraggable ? (
            <StopPropagation>
              {unscheduledInterview.orderLocked ? (
                <Tooltip content={lockedLabel} isInstant>
                  <AriaButton
                    className="bg-purple-50 text-purple-500 w-6 h-6 flex items-center justify-center rounded-md hover:bg-purple-100"
                    onClick={() => setLocked?.(false)}
                  >
                    <PositionLockedIcon
                      number={unscheduledInterview.order ?? 0}
                      variant="purple"
                    />
                  </AriaButton>
                </Tooltip>
              ) : (
                <Tooltip content={lockedLabel} isInstant>
                  <Button
                    onClick={() => setLocked?.(true)}
                    size="xs"
                    className="text-subtle"
                    icon={atlasOrderAny}
                    isGhost
                  />
                </Tooltip>
              )}
            </StopPropagation>
          ) : (
            !disabled && (
              <Tooltip content={lockedLabel} isInstant>
                <div className="flex items-center justify-center w-6 h-6">
                  {unscheduledInterview.orderLocked ? (
                    <PositionLockedIcon
                      number={unscheduledInterview.order ?? 0}
                      variant="default"
                    />
                  ) : (
                    <Icon content={atlasOrderAny} className="w-5 h-5" />
                  )}
                </div>
              </Tooltip>
            )
          )}
          {onRemove && (
            <StopPropagation>
              <Button
                icon={atlasTrash}
                isGhost
                size="xs"
                className="text-subtle"
                onClick={onRemove}
              />
            </StopPropagation>
          )}
          {showActionMenu && isSchedulable && (
            <StopPropagation>
              <Menu.Root placement="bottom-end">
                <Menu.Trigger>
                  <Button
                    icon={atlasEllipsisHorizontal}
                    isGhost
                    size="xs"
                    className="text-subtle"
                  />
                </Menu.Trigger>
                <Menu.Content items={actionMenuItems} />
              </Menu.Root>
            </StopPropagation>
          )}
        </ButtonGroup>
      </div>

      {!isCompact && (
        <>
          {unscheduledInterview.interviewerSlots.length > 0 ? (
            <div className="flex flex-col gap-2">
              <InterviewerSlotsRequirementsList
                interviewerSlots={unscheduledInterview.interviewerSlots}
                className={clsx({ "opacity-40": disabled }, textClassName)}
              />
            </div>
          ) : (
            <div className="flex text-subtle text-body-sm items-center gap-3">
              <div className="flex items-center justify-center w-5">
                <Icon
                  content={atlasPersonPlus}
                  className="w-4 h-4 mt-[.0625rem] p-.5"
                />
              </div>
              {onClick && !isFulfilled ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  as="button"
                  variant="monochrome"
                  className="!text-subtle"
                  onClick={() => {
                    onClick();
                  }}
                >
                  Add interviewers
                </Link>
              ) : (
                <span>Add interviewers</span>
              )}
            </div>
          )}
        </>
      )}

      {unscheduledInterview.conferenceRoomSettings?.required && (
        <div className="items-center shrink-0 flex gap-3">
          <div className="w-5 h-5">
            <div className="flex items-center justify-center bg-light-gray-500 w-full h-full rounded-full">
              <Icon content={atlasDoor} className="w-3.5 h-3.5 text-subtle" />
            </div>
          </div>
          <p className="text-body-md text-subtle">Conference room required</p>
        </div>
      )}
      {!!schedulingValidationError && (
        <div className="space-y-2">
          <div
            className="bg-red-50 text-body-sm rounded-md p-2"
            key={schedulingValidationError}
          >
            <p className="flex flex-row gap-2 items-center">
              <Icon content={atlasRingInfo} className="text-red-500 w-5 h-5" />
              <span className="text-red-900 ">{schedulingValidationError}</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
