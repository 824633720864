import { Dialog, DialogProps } from "@resource/atlas/dialog-v2/Dialog";
import { MultiStepView } from "@resource/atlas/multi-step-view/MultiStepView";
import { BackButton } from "client/components/generic/layout/BackButton";
import {
  SchedulingCompletionPromptsDisplay,
  SchedulingCompletionPromptsDisplayProps,
} from "client/components/scheduling-completion/display/SchedulingCompletionPromptsDisplay";
import { useDialogLeaveConfirmation } from "client/hooks/useDialogLeaveConfirmation";
import { PostMessageDialogQuery } from "client/message-composer/__components/PostMessageDialogQuery";
import ErrorBoundary from "components/ErrorBoundary";
import { DialogError } from "components/Generic/DialogError";
import { DialogLoading } from "components/Generic/DialogLoading";
import { useMemo } from "react";
import { PostMessageWorkflow } from "shared/message-composer/types";

import { useSchedulingRequestForCompletionPrompts } from "./hooks/useSchedulingRequestForCompletionPrompts";

export type SchedulingCompletionPromptsDialogProps = DialogProps & {
  interviewsWithSchedulingInfo: SchedulingCompletionPromptsDisplayProps["interviewsWithSchedulingInfo"];
  invitesSent: boolean;
  guideId: string;
  schedulingRequestId?: string;
  isReschedule?: boolean;
  isCancellationConfirmation?: boolean;
};

export function SchedulingCompletionPromptsDialog({
  interviewsWithSchedulingInfo,
  store,
  invitesSent,
  guideId,
  schedulingRequestId,
  isReschedule,
  isCancellationConfirmation,
}: SchedulingCompletionPromptsDialogProps) {
  const { dialogProps, WarningDialog } = useDialogLeaveConfirmation({
    store,
  });

  return (
    <Dialog {...dialogProps} size="medium" store={store}>
      <ErrorBoundary
        fallback={({ onRecover }) => (
          <DialogError
            className="!min-h-[min(90vh,_50rem)]"
            onRecover={onRecover}
          />
        )}
      >
        <SchedulingCompletionPromptsMultiStepView
          interviewsWithSchedulingInfo={interviewsWithSchedulingInfo}
          invitesSent={invitesSent}
          guideId={guideId}
          schedulingRequestId={schedulingRequestId}
          isReschedule={isReschedule}
          isCancellationConfirmation={isCancellationConfirmation}
          store={store}
        />
      </ErrorBoundary>
      <WarningDialog />
    </Dialog>
  );
}

type SchedulingCompletionPromptsMultiStepViewProps = Pick<
  SchedulingCompletionPromptsDialogProps,
  | "interviewsWithSchedulingInfo"
  | "invitesSent"
  | "guideId"
  | "schedulingRequestId"
  | "isReschedule"
  | "isCancellationConfirmation"
  | "store"
>;

function SchedulingCompletionPromptsMultiStepView({
  interviewsWithSchedulingInfo,
  invitesSent,
  guideId,
  schedulingRequestId,
  isReschedule,
  isCancellationConfirmation,
  store,
}: SchedulingCompletionPromptsMultiStepViewProps) {
  const { schedulingRequest, loading } =
    useSchedulingRequestForCompletionPrompts({
      schedulingRequestId,
    });
  const workflowType = useMemo(() => {
    if (isCancellationConfirmation) {
      return PostMessageWorkflow.SEND_MESSAGE;
    }

    return !isReschedule
      ? PostMessageWorkflow.CONFIRM_INTERVIEWS
      : PostMessageWorkflow.CONFIRM_RESCHEDULE_INTERVIEWS;
  }, [isCancellationConfirmation, isReschedule]);

  if (loading) {
    // it should be cached already from scheduler hydration, but if not...
    return <DialogLoading />;
  }

  return (
    <MultiStepView
      orderedViews={["completionPrompts", "postMessage"]}
      views={{
        completionPrompts: ({ onContinue }) => (
          <SchedulingCompletionPromptsDisplay
            interviewsWithSchedulingInfo={interviewsWithSchedulingInfo}
            invitesSent={invitesSent}
            onUpdateCandidate={onContinue}
            onDismiss={store.hide}
          />
        ),
        postMessage: ({ onBack }) => (
          <PostMessageDialogQuery
            guideId={guideId}
            schedulingRequestId={schedulingRequestId}
            workflowType={workflowType}
            thread={schedulingRequest?.rootPost}
            onDismiss={store.hide}
            onCompleted={store.hide}
            viewProps={{
              header: {
                leftActions: <BackButton onClick={onBack} />,
                title: "Send candidate confirmation",
              },
            }}
          />
        ),
      }}
    />
  );
}
