/* eslint-disable import/prefer-default-export */
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { theme } from "../../../../theme";
import { createModule } from "../../../modules";
import { AutoLinkPlugin } from "./AutoLinkPlugin";
import { HorizontalRulePlugin } from "./HorizontalRulePlugin";
import { insertOptions } from "./insert-options";
import { TRANSFORMERS } from "./markdown-transformers";
import { NormalizationPlugin } from "./NormalizationPlugin";
import { RichTextPlugin } from "./RichTextPlugin";

export const richTextModule = createModule("rich-text", {
  createLinkedOptions() {
    return { insertOptions };
  },
  declareModule() {
    return {
      nodes: [
        HeadingNode,
        QuoteNode,
        AutoLinkNode,
        LinkNode,
        ListNode,
        ListItemNode,
        HorizontalRuleNode,
      ],
      plugins: [
        <RichTextPlugin />,
        <LinkPlugin />,
        <ListPlugin />,
        <HorizontalRulePlugin />,
        <TabIndentationPlugin />,
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />,
        <NormalizationPlugin />,
        <AutoLinkPlugin />,
      ],
      themes: [theme],
    };
  },
});
